import React from 'react';

import {Grid} from '@material-ui/core';

import PortfolioCurrencyStructure from './PortfolioCurrencyStructure';
import PortfolioBruncheStructure from './PortfolioBruncheStructure';
import PortfolioAssetStructure from './PortfolioAssetStructure';
import PortfolioRegionStructure from './PortfolioRegionStructure';
import TotalAssetVolume from './TotalAssetVolume';
import SyntheticRiskRewardChart from './SyntheticRiskRewardChart';
import AssetAmountStructure from './AssetAmountStructure';
import PropTypes from "prop-types";
import {isSectionVisible} from "../../../../../../utils/utils";
import PortfolioMorningstarCategoryStructure from "./PortfolioMorningstarCategoryStructure";
import PortfolioAssetAllocationStructure from "./PortfolioAssetAllocationStructure";


const portfolioStructurePropsAreEquals = (prevProps, nextProps) => {
  return prevProps.dashboardData.timestamp === nextProps.dashboardData.timestamp
};


const PortfolioStructure = React.memo(({dashboardData,customTypeSettings, reportType }) => {
  const chartsToBuild = [
    {md: 4, sm: 6,  sectionKey: 'assetAllocationChart',     component: PortfolioAssetAllocationStructure},
    {md: 4, sm: 6,  sectionKey: 'currencyChart',            component: PortfolioCurrencyStructure},
    {md: 4, sm: 6,  sectionKey: 'productTypeChart',         component: PortfolioAssetStructure},
    {md: 4, sm: 6,  sectionKey: 'portfolioBruncheChart',    component: PortfolioBruncheStructure},
    {md: 4, sm: 6,  sectionKey: 'totalAssetVolumeChart',    component: TotalAssetVolume},
    {md: 4, sm: 6,  sectionKey: 'syntheticRiskRewardChart', component: SyntheticRiskRewardChart},
    {md: 12, sm: 12, sectionKey: 'portfolioRegionChart',    component: PortfolioRegionStructure},
    {md: 4, sm: 6,  sectionKey: 'assetCategoriesChart',     component: PortfolioMorningstarCategoryStructure},
    {md: 8, sm: 12, sectionKey: 'assetAmountChart',         component: AssetAmountStructure},
  ];

  return (
    <Grid container spacing={2}>
      {chartsToBuild.filter(chart => isSectionVisible(customTypeSettings, chart.sectionKey, reportType)).map(chart => {
        return(
          <Grid item xs={12} sm={chart.sm || 12} md={chart.md}>
            <chart.component
              dashboardData={dashboardData.data}
              loading={dashboardData.loading}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}, portfolioStructurePropsAreEquals);


PortfolioStructure.propTypes = {
  dashboardData: PropTypes.shape({
    data: PropTypes.object,
    loading: PropTypes.bool,
    errors: PropTypes.any,
    timestamp: PropTypes.number
  })
}


export default PortfolioStructure;