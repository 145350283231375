import React from 'react';
import clsx from 'clsx';

import useStyles from './styles';


export default () => {
  const classes = useStyles();

  return (
    <div className={classes.loadingContainer}>
      <div>
        <div className={clsx(classes.loadingAvatar, classes.loadingLine)}/>
      </div>
      <div>
        <div className={classes.loadingLine} />
        <div className={classes.loadingLine} />
      </div>
    </div>
  )
}