import React from 'react';
import PropTypes from "prop-types";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {Checkbox, CircularProgress, Grid} from "@material-ui/core";
import ResponsiveDialog from "../ResponsiveDialog/ResponsiveDialog";
import {
  EXTEND_COMPARISON_WITH_PRODUCTS_STORAGE_KEY, PROCESS_TYPES,
  PRODUCT_COMPARISON_STEP,
  PRODUCT_TYPE, PRODUCTS_TO_COMPARE_MAX_COUNT
} from "../../containers/ProductComparison/constants";
import useStyles from './styles';
import ComparisonsList from "../../containers/ProductComparison/components/TabsContent/ComparisonsList";
import DashboardTable from "../DashboardTable/DashboardTable";
import useInstrumentTableStyles from '../../containers/Trades/components/TradeStep/components/Portfolio/styles';
import PrimaryButton from "../Buttons/PrimaryButton";
import {useSelectedItems} from "../../hooks/useSelectedItems";
import {getTableStructureSwitch} from "../Charts/InstrumentsAllocationTable/table-data";
import {
  redirectToProductsComparison,
  redirectToProductsComparisonOverview
} from "../../containers/ProductComparison/utils";
import _ from "lodash";
import {setInStorage} from "../../utils/storage";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {getProductId} from "../../containers/ProductComparison/components/TabsContent/table-structure";
import {FavoriteListResouce, InvestmentStrategiesResource, ModelPortfolioResource} from "../../utils/api";
import {
  favoriteListDetailsTableStructure as genericTableStructure, musterPortfolioInstrumentsTableStructure
} from "../../containers/Modelportfolios/components/InstrumentsList/table-data";
import useGenericTableClasses from '../../containers/FavoriteList/Overview/styles'
import {FETCH_DATA_INITIAL_STATE} from "../../hooks/constants";
import {executeIfPathExist, getInvestmentDynamicPath} from "../../containers/InvestmentPlatform/utils";
import clsx from "clsx";
import {getInvestmentPlatformSelector} from "../../utils/redaxSelectors";
import {displayErrorSnackBar} from "../SnackbarProvider/actions";

export const AddProductsToComparisonContext = React.createContext();

export function useAddProductsToComparisonContext() {

  const context = React.useContext(AddProductsToComparisonContext)
  if (!context) {
    throw new Error('Extracting context without wrapping your component with context Provider!')
  }

  return context

}

/**
 * Prepare object with summary information for list of products.
 * @param {Array<{data: Object, productType: Number}>} products List of products
 * @returns {Object}
 */
const buildProductsSummary = (products) => {
  return products.map((product) => {

    const {productType} = product;
    const productId = getProductId(product);

    const productBody = {
      product_id: productId,
      type: productType
    }
    if (productType === PRODUCT_TYPE.CUSTOMER_PORTFOLIO) {
      productBody.configuration = {
        customer_id: _.get(product, `data.customer_id`),
        depot_id: [productId]
      }
    }

    return productBody

  }, {});
}

const mapStateToProps = (state) => ({
  investmentPlatform: getInvestmentPlatformSelector(state),
});

export const AddProductsToComparison = connect(mapStateToProps)(({children, investmentPlatform}) => {

  const history = useHistory();

  const [existingComparisonModalOpen, setExistingComparisonModalOpen] = React.useState(false);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [comparisonStartedTrigger, setComparisonStartedTrigger] = React.useState();

  const closeExistingComparisonModal = () => {
    setExistingComparisonModalOpen(false);
    setSelectedProducts([]);
  };

  const _openProductsComparison = (products, storageKey, params) => {
    const selectedProductsSummary = buildProductsSummary(products);
    setInStorage(storageKey, JSON.stringify(selectedProductsSummary));
    redirectToProductsComparison(history, investmentPlatform.routes, params, true);
    closeExistingComparisonModal();
    setComparisonStartedTrigger(+new Date());
  }

  const handleAddProductsToComparison = (products, isNewComparison=true) => {
    if (!isNewComparison) {
      setExistingComparisonModalOpen(true);
      setSelectedProducts(products);
      return;
    }
    _openProductsComparison(products, EXTEND_COMPARISON_WITH_PRODUCTS_STORAGE_KEY, {
      process_type: PROCESS_TYPES.EXTEND
    });
  }

  const handleSelectComparison = (comparison, processType) => {
    if (processType === PROCESS_TYPES.OPEN) {
      redirectToProductsComparisonOverview(investmentPlatform.routes, comparison.id);
    } else {
      _openProductsComparison(
        selectedProducts, `${EXTEND_COMPARISON_WITH_PRODUCTS_STORAGE_KEY}_${comparison.id}`, {
          tab_id: PRODUCT_COMPARISON_STEP.COMPARISON,
          process_type: PROCESS_TYPES.EXTEND,
          product_comparison_id: comparison.id,
        });
    }
  }

  return (
    <>
      <AddProductsToComparisonContext.Provider
        value={{onAddProductsToComparison: handleAddProductsToComparison, comparisonStartedTrigger}}
      >
        {children}
      </AddProductsToComparisonContext.Provider>
      <ExistingComparisonsModal
        onClose={closeExistingComparisonModal}
        open={existingComparisonModalOpen}
        onSelect={handleSelectComparison}
        productsToExtend={selectedProducts}
      />
    </>
  )
});

AddProductsToComparison.propTypes = {
  children: PropTypes.oneOf([PropTypes.element, PropTypes.arrayOf[PropTypes.element]])
}

export function ExistingComparisonsModal({open, onClose, onSelect, productsToExtend}) {
  const classes = useStyles();
  return (
    <ResponsiveDialog open={open} onClose={onClose}>
      <DialogTitle>
        <div className={classes.header}>
          <IconButton disableRipple aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }} style={{paddingBottom: 24}}>
        <ComparisonsList
          changeSelectedProcess={onSelect}
          availableActions={[PROCESS_TYPES.EXTEND]}
          tableOptions={{
            productsToExtend: productsToExtend || [],
            actionsStyle: {
              fontSize: '18px'
            }
          }}
        />
      </DialogContent>
    </ResponsiveDialog>
  )
}

ExistingComparisonsModal.propTypes = {
  /** Flag, that indicate if modal is opened */
  open: PropTypes.bool.isRequired,
  /** onClose event handler */
  onClose: PropTypes.func.isRequired,
  /** Select comparison event handler*/
  onSelect: PropTypes.func.isRequired,
  /** List of products, that should be added to the new comparison */
  productsToExtend: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.object
    }))
}


export const SelectableInstrumentsTable = connect(mapStateToProps)(({container, ContainerProps, instruments, InstrumentsProps, investmentPlatform}) => {

  const classes = useStyles();

  const handleContainerLinkClick = () => {
    executeIfPathExist(investmentPlatform.routes, ContainerProps.path, (dynamicPath) => {
      window.open('/' + getInvestmentDynamicPath() + dynamicPath.replace(':id', container.id), '_blank')
    }, ':id')
  }

  return (
    <div>
      {ContainerProps.enabled && (
        <div className={classes.sourceItemCheckBox}>
          {ContainerProps.selectable && (
            <Checkbox
              color="primary"
              className={clsx(classes.containerCheckbox, _.get(ContainerProps, 'classes.containerCheckbox'))}
              checked={ContainerProps.checked}
              onChange={ContainerProps.onChange}
            />
          )}
          <p
            onClick={ContainerProps.path && handleContainerLinkClick}
            className={clsx(
              ContainerProps.path && classes.link,
              !ContainerProps.selectable
                ? classes.containerName
                : classes.containerNameSelectable,
              !ContainerProps.selectable
                ? _.get(ContainerProps, 'classes.containerName')
                : _.get(ContainerProps, 'classes.containerNameSelectable'))}
          >
            {container.name}
          </p>
        </div>
      )}
      {InstrumentsProps.enabled && (
        <div style={{overflowX: 'auto', overflowY: "hidden"}}>
          <DashboardTable
            tableLayout={"auto"}
            structure={InstrumentsProps.tableStructure}
            dataSource={instruments || []}
            expanded={true}
            tableClasses={InstrumentsProps.tableClasses}
            withFooter={false}
            options={{
              onAllSelect: InstrumentsProps.handleSelectAll,
              totalInstrumentsCount: InstrumentsProps.totalInstrumentsCount || (instruments && instruments.length),
              onInstrumentSelect: InstrumentsProps.handleSelect,
              selected: InstrumentsProps.selected,
              ...(InstrumentsProps.tableOptions || {})
            }}
          />
        </div>
      )}
    </div>
  )
});


SelectableInstrumentsTable.defaultProps = {
  InstrumentsProps: {},
  ContainerProps: {}
}


export const SelectForProductsComparisonModal = connect(mapStateToProps)((
  {container, instruments, onClose, loading, investmentPlatform, dispatch}) => {

  const classes = useStyles();
  const instrumentTableClasses = useInstrumentTableStyles();
  const genericTableClasses = useGenericTableClasses();
  const {onAddProductsToComparison, comparisonStartedTrigger} = useAddProductsToComparisonContext();

  const {selectedItems, handleToggleItem, handleSelectMany, handleUnselectMany, cleanSelected} = useSelectedItems('data.id');

  const open = loading || !!container || !!instruments;
  const containerId = !!container && container.data.id;
  const selectedInstruments = Object
    .values(selectedItems)
    .filter((product) => product.productType === PRODUCT_TYPE.ASSET);

  React.useEffect(() => {
    if (!!comparisonStartedTrigger) {
      onClose();
      cleanSelected();
    };
  }, [comparisonStartedTrigger])

  const handleContainerCheckboxChange = () => {
    handleToggleItem(container, 'data.id');
  }

  const handleInstrumentSelect = (instrument) => {
    handleToggleItem({data: instrument, productType: PRODUCT_TYPE.ASSET}, 'data.isin');
  }

  const handleSelectAllInstruments = () => {

    const handler = instruments.length === selectedInstruments.length
      ? handleUnselectMany : handleSelectMany;

    handler(instruments.map((instrument) => ({
      data: instrument,
      productType: PRODUCT_TYPE.ASSET
    })), 'data.isin');
  }

  const handleAddProductsToComparison = (isNewComparison) => () => {

    const _selectedProducts = Object.values(selectedItems);
    if (_selectedProducts.length > PRODUCTS_TO_COMPARE_MAX_COUNT) {
      dispatch(displayErrorSnackBar('Maximale Anzahl von 10 Produkten erreicht.'));
      return;
    }

    onAddProductsToComparison(Object.values(selectedItems), isNewComparison)
  }

  if (!open) {
    return null
  }

  // This part should be extended with valid structure/classes after connecting to new product types.
  // For now it works correctly only for customer depots selection.
  const [tableStructure, tableClasses] = (() => {

    if (!container) {
      return [genericTableStructure, genericTableClasses]
    }

    return {
      [PRODUCT_TYPE.CUSTOMER_PORTFOLIO]: [getTableStructureSwitch(), instrumentTableClasses],
      [PRODUCT_TYPE.MODEL_PORTFOLIO]: [musterPortfolioInstrumentsTableStructure, {}],
      [PRODUCT_TYPE.PRIVATE_INVESTMENT]: [musterPortfolioInstrumentsTableStructure, {}]
    }[container.productType] || [genericTableStructure, genericTableClasses];
  })()

  const actionsDisabled = !Object.keys(selectedItems).length;


  return (
    <ResponsiveDialog open={open} onClose={onClose}>
      <DialogTitle>
        <div className={classes.header}>
          <IconButton disableRipple aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <SelectableInstrumentsTable
            instruments={instruments}
            container={container && container.data}
            ContainerProps={{
              enabled: !!container,
              selectable: container && container.selectable,
              path: container && container.path,
              checked: containerId in selectedItems,
              onChange: handleContainerCheckboxChange
            }}
            InstrumentsProps={{
              enabled: true,
              selected: selectedInstruments.map((product) => product.data),
              handleSelectAll: handleSelectAllInstruments,
              handleSelect: handleInstrumentSelect,
              tableStructure,
              tableClasses,
            }}
          />
        )}
      </DialogContent>
      {!loading && (
        <DialogActions classes={{root: classes.dialogActionsRoot}}>
          <Grid container spacing={2} alignItems={"center"} justify={"flex-end"}>
            <Grid xs={12} sm={true} item>
              <Grid container justify="flex-end" spacing={2}>
                <Grid xs={12} sm={"auto"} item>
                  <PrimaryButton
                    text='Zu Investmentvergleich hinzufügen'
                    disabled={actionsDisabled}
                    onButtonClick={handleAddProductsToComparison(false)}
                    style={{width: '100%'}}
                  />
                </Grid>
                <Grid xs={12} sm={"auto"} item>
                  <PrimaryButton
                    text='Neuer Investmentvergleich starten'
                    disabled={actionsDisabled}
                    onButtonClick={handleAddProductsToComparison(true)}
                    style={{width: '100%'}}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </ResponsiveDialog>
  )
})

SelectForProductsComparisonModal.propTypes = {
  /** Container for instruments. */
  container: PropTypes.shape({
    data: PropTypes.object,
    /** Is container selectable or not. */
    selectable: PropTypes.bool,
    /** In case container details should be opened in new tab -
     * use this field to provide path component name */
    path: PropTypes.string,
    productType: PropTypes.oneOf([
      PRODUCT_TYPE.CUSTOMER_PORTFOLIO,
      PRODUCT_TYPE.MODEL_PORTFOLIO,
      PRODUCT_TYPE.MUSTERDEPOT,
      PRODUCT_TYPE.PRIVATE_INVESTMENT
    ])
  }),
  /** List of instruments, that belongs to container or exists independently. */
  instruments: PropTypes.arrayOf(PropTypes.shape({
    isin: PropTypes.string.isRequired
  })),
  /** onClose event handler */
  onClose: PropTypes.func.isRequired,
  /**
   * Flag, that indicate if container data loading is in progress.
   * Use this flag in case you need to fetch some extra data
   * and you want to see modal with loading indicator.
   */
  loading: PropTypes.bool
}


export function SelectSourceForProductsComparisonModal({sourceId, onClose, fetchSourceData, parseSourceData}) {

  const [sourceData, setSourceData] = React.useState(FETCH_DATA_INITIAL_STATE);

  const [sourceContainer, sourceInstruments] = (() => {
    const data = _.get(sourceData, 'data');
    if (!data) {
      return [undefined, undefined];
    }

    return parseSourceData(data);
  })();

  React.useEffect(() => {
    if (!sourceId) {
      return
    }

    _fetchSourceData();
  }, [sourceId]);

  const _fetchSourceData = async () => {
    try {
      setSourceData({data: undefined, loading: true, errors: undefined, updatedAt: + new Date()});

      const response = await fetchSourceData(sourceId);

      setSourceData({data: response, loading: false, errors: undefined, updatedAt: + new Date()});
    } catch (error) {
      setSourceData({data: undefined, loading: false, errors: error, updatedAt: + new Date()})
    }
  }

  const handleClose = () => {
    setSourceData(FETCH_DATA_INITIAL_STATE);
    onClose();
  }

  return (
    <SelectForProductsComparisonModal
      onClose={handleClose}
      instruments={sourceInstruments}
      container={sourceContainer}
      loading={sourceData.loading}
    />
  )
}

SelectSourceForProductsComparisonModal.propTypes = {
  /** Source identifier. Used to fetch data via fetchSourceData */
  sourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** onClose event handler */
  onClose: PropTypes.func,
  /** Function, that implement main data fetching logic */
  fetchSourceData: PropTypes.func.isRequired,
  /** Function, that implement data parsing logic.
   * Should return array with two elements [container, instruments] */
  parseSourceData: PropTypes.func.isRequired
}


const fetchFavoriteListData = async (favoriteListId) => {
  return FavoriteListResouce.getFavoriteListDetails(favoriteListId);
}

const parseFavoriteListData = (data) => {
  return [{
    data,
    selectable: false,
    path: 'FAVORITE_LIST_OVERVIEW'
  }, _.get(data, 'assets')]
}

export function SelectFavoriteListForProductsComparisonModal({favoriteListId, onClose}) {
  return (
    <SelectSourceForProductsComparisonModal
      sourceId={favoriteListId}
      onClose={onClose}
      fetchSourceData={fetchFavoriteListData}
      parseSourceData={parseFavoriteListData}
    />
  )
}


const fetchMusterdepotData = async (musterdepotId) => {
  return ModelPortfolioResource.getModelPortfolioDetails(musterdepotId);
}

const parseMusterdepotData = (data) => {
  return [{
    data,
    selectable: true,
    productType: PRODUCT_TYPE.MUSTERDEPOT,
    path: 'MODELPORTFOLIO_OVERVIEW'
  }, _.get(data, 'assets')]
}

export function SelectMusterdepotForProductsComparisonModal({musterdepotId, onClose}) {
  return (
    <SelectSourceForProductsComparisonModal
      sourceId={musterdepotId}
      onClose={onClose}
      fetchSourceData={fetchMusterdepotData}
      parseSourceData={parseMusterdepotData}
    />
  )
}

const fetchInvestmentStrategyData = async (investmentStrategyId) => {
  return InvestmentStrategiesResource.at(`${investmentStrategyId}/`).get();
}

const parseInvestmentStrategyData = (data) => {
  return [{
    data,
    selectable: true,
    productType: PRODUCT_TYPE.PRIVATE_INVESTMENT,
    path: 'INVESTMENT_STRATEGIES_OVERVIEW'
  }, _.get(data, 'related_model_portfolio.assets')]
}

export function SelectInvestmentStrategyForProductsComparisonModal({investmentStrategyId, onClose}) {
  return (
    <SelectSourceForProductsComparisonModal
      sourceId={investmentStrategyId}
      onClose={onClose}
      fetchSourceData={fetchInvestmentStrategyData}
      parseSourceData={parseInvestmentStrategyData}
    />
  )
}