const styles = theme => ({
  rootContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dataWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  notesWrapper: {
    display: 'block',
  },
  note: {
    fontSize: 12,
    marginTop: 10,
    color: '#8D8D8D',
  },
  bigNumbers: {
    listStyle: 'none',
    padding: 0,
    width: 350,
    display: 'inline-block',
    verticalAlign: 'top',
    '& li': {
      width: 175,
      display: 'inline-block',
      verticalAlign: 'top',
      marginBottom: 45
    }
  },
  slidersWrapper: {
    display: 'inline-block',
    verticalAlign: 'top'
  },
  smallTitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold'
  },
  singleSliderWrapper: {
    marginBottom: 30
  },
  sliderTitle: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: 100,
    [theme.breakpoints.only("xs")]: {
      width: 'auto',
      marginBottom: '1em'
    }
  },
  hugeNumber: {
    fontSize: 34,
    fontFamily: 'Roboto-Bold',

    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
  recoveryDays: {
    color: '#8D8D8D'
  },
  portfolio: {
    color: '#0092E5',
  },
  benchmark: {
    color: '#23D374',
    width: '80%',
    float: 'right',
    paddingRight: 20,
    [theme.breakpoints.down('xs')]: {
      width: "100%"
    },
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  flexChild: {
    minWidth: 160,
    marginBottom: '1.32em',
  },
  emptyMessage: {
    margin: 0,
    fontSize: 22,
    color: '#b1b1b1',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  empty: {
    fontSize: 16
  }
})

export default styles;