import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  header: {
    fontFamily:'Roboto-Bold',
    fontSize: 20,
    color: '#202A38',
    margin: 0
  },
  sectionHeader: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#202A38',
    margin: 0
  },
  description: {
    color: '#202A38',
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    whiteSpace: 'pre-line'
  },
  sectionValue: {
    fontFamily: 'Roboto-Bold',
    fontSize: 20,
    color: '#4D4F5C',
    display: 'flex',
    alignItems: 'flex-end',

    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    }
  },
  customerProfileSectionValue: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    margin: 0,
  },
  leftContainer: {
    [theme.breakpoints.up("md")]: {
      margin: -theme.spacing(2),

      '& > .MuiGrid-item': {
        padding: theme.spacing(2)
      }
    },
    [theme.breakpoints.up("lg")]: {
      margin: -theme.spacing(3),

      '& > .MuiGrid-item': {
        padding: theme.spacing(3)
      }
    },
  },
  rightSideSpacing: {
    minHeight: 71  // style to align with left side
  },
  section: {
    '&:nth-child(1)': {
      borderRight: '1px solid #D8DCDF',

      [theme.breakpoints.down('sm')]: {
        border: 'none',
        paddingBottom: 20
      }
    },
    '&:last-child': {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '0!important'
      }
    },
    '&:nth-child(even)': {

      [theme.breakpoints.down('xs')]: {
        paddingTop: 20
      }
    }
  },
  sectionItemsContainer: {
    display: 'flex',
  },
  sectionTopHeader: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: '#202A38',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      marginBottom: 20,
      fontSize: 16,
    }
  },

  headerContainer: {
    display: 'flex',
    alignItems: 'center',

    '& > p': {
      marginRight: 10
    },

    '& > div': {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    }
  },

  subNavigationButton: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: 5,

    '&:hover': {
      cursor: 'pointer'
    },

    '& span:nth-child(1)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 28,
      width: 28,
      borderRadius: 20,
      position: 'relative',
      zIndex: 9,
      backgroundColor: '#CCE9FA',
    },
  },

})

export const useProfileDetailsStyles = makeStyles(theme => createStyles({
  header: {
    fontFamily: 'Roboto-Bold!important',
    fontSize: '14px!important',
    color: '#202A38!important',
    whiteSpace: 'nowrap',
    height: '100%',
    alignItems: 'initial!important'
  },

  value: {
    display: 'flex',
    alignItems: 'flex-end'
  },

  headerWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },

  headerContainer: {
    height: '100%'
  },

  headerContent: {
    display: "flex",
    alignItems: 'flex-end'
  },

  dataWrap: {
    [theme.breakpoints.up('md')]: {
      paddingTop: 17
    }
  },

  subContainer: {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column !important'
    }
  },

}))

export default makeStyles(styles)