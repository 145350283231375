import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItem from "@material-ui/core/ListItem";
import Card from "@material-ui/core/Card";
import {Skeleton} from '@material-ui/lab';
import {Grid} from '@material-ui/core';

import useStyles from './styles';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import AccordionList from '../../../../components/AccordionList/index';

import useOnClickOutside from "../../../../utils/useOnClickOutside";
import {SHARE_BUSINESS_CASE_MODAL_UID} from "../../../CustomerDashboard/components/PdfExportingModal/constants";
import Switch from "../../../../components/AssetModal/components/Switch";
import {isValidString} from "../../../../utils/utils";
import LabelFormElement from "../../../RiskProfiling/components/StepContent/components/formElement/LabelFormElement";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import RichTextEditor from "../../../GroupReporting/components/EditorStepContent/components/RichTextEditor";


const SelectContactEmailModal = props => {
  const {
    selectEmailModal,
    handleModalClose,
    handleShareBusinessCase,
    businessCaseDetails,
    validateSignatureFields
  } = props;

  const classes = useStyles();

  const [selectedContact, setSelectedContact] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [editorValue, setEditorValue] = React.useState(null);
  const [pureEditorValue, setPureEditorValue] = React.useState(null);
  const [editorEnabled, setEditorEnabled] = React.useState(true);
  const [numberOfSymbols, setNumberOfSymbols] = React.useState(0);
  const [symbolsLimitError, setSymbolsLimitError] = React.useState(false);
  const symbolsLimit = 1000;

  const node = React.useRef();
  useOnClickOutside(node, () => {
    setExpanded(false);
  });

  React.useEffect(() => {
    if(selectEmailModal.opened){
      setSelectedContact({})
    }
  }, [selectEmailModal.opened]);

  let preparedEmails = [{
    email: selectEmailModal.customer.bca_private_email,
    type: 'privat' // private
  }, {
    email: selectEmailModal.customer.business_email,
    type: 'geschäftlich' //// business
  }, {
    email: selectEmailModal.customer.preferred_email,
  }].filter(item => isValidString(item.email));

  preparedEmails = _.uniqBy(preparedEmails, 'email');

  const contactEmail = (contact=selectedContact) => {
    if(!contact.email){
      return '-'
    }

    const isPreferred = contact.email === selectEmailModal.customer.preferred_email;

    return (
      <div className={classes.selectedItem}>
        {contact.email}
        {isPreferred && <span className={classes.primaryEmail}>P</span>}
        {!!contact.type && <span className={classes.emailType}>({contact.type})</span>}
      </div>
    )
  };

  const isSelected = (item, selectedItem) => {
    return item.email === selectedItem.email;
  };

  const renderItemsList = () => {
    return preparedEmails.map((item, idx) => {
      return (
        <ListItem
          key={idx}
          className={clsx(classes.listItem, isSelected(item, selectedContact) && classes.listItemSelected)}
          onClick={() => {
            setSelectedContact(item);
            setExpanded(false);
          }}
        >
          <span>
            {contactEmail(item)}
          </span>
        </ListItem>
      );
    })
  };

  const validateSymbolsLimit = () => {
    const totalSymbols = pureEditorValue && pureEditorValue.trim().length;
    setNumberOfSymbols(totalSymbols)
    setSymbolsLimitError(totalSymbols > symbolsLimit)
  }

  React.useEffect(() => {
    validateSymbolsLimit();
  }, [pureEditorValue])

  React.useEffect(() => {
    if (editorEnabled) {
      validateSymbolsLimit();
    } else {
      setSymbolsLimitError(false);
    }
  }, [editorEnabled])

  const handleEditorValueChange = (value) => {
    if (value !== editorValue) {
      setEditorValue(value);
    }
  };

  return (
    <Dialog
      open={selectEmailModal.opened}
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='lg'
      classes={{
        root: classes.expansionPanelRoot,
      }}
      PaperProps={{
        style: {
          width: 700,
          minHeight: 350
        }
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        classes={{
          root: classes.modalTitle
        }}
      >
        An Kunden senden
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={clsx(classes.emailLabel, classes.sectionHeader)}>E-Mail</div>
        <div style={{position: 'relative'}} ref={node}>
          <AccordionList
            carrerIcon={<ExpandMoreIcon htmlColor="#0092E5"/>}
            renderSelectedItem={contactEmail}
            renderItemsList={renderItemsList}
            expanded={expanded}
            setExpanded={setExpanded}
            overrideClasses={classes}
          />
        </div>
        <div className={classes.documentsToSignBlock}>
          <div className={clsx(classes.label, classes.sectionHeader)}>Kundenunterlagen</div>
          {businessCaseDetails.loading ?
            <Skeleton height={60}/>
          : <>
            {(_.get(businessCaseDetails, 'data.documents') || []).map(document => {
              if(validateSignatureFields(document, selectEmailModal.userRole)){
                return (
                  <Grid item xs={12} key={document.id}>
                    <p className={classes.status} style={{marginLeft: 10}}>
                      { document.name }
                    </p>
                  </Grid>
                )
              }
            })}
          </>
        }
        </div>
        <div className={classes.headerWithToggle}>
          <LabelFormElement
            error={!!symbolsLimitError}
            label={"Anschreiben"}
            customClasses={classes}
          />
          <span className={classes.switch}>
            <Switch
              value={editorEnabled}
              handleValueChanged={(checked) => setEditorEnabled(checked)}
            />
          </span>
        </div>
        {editorEnabled && (
          <>
            <div className={classes.editorHelpText}>
              Achtung: Die förmliche Anrede (z.B. Guten Tag Max Mustermann,) ist fester Bestandteil der
              Einladungsmail und muss nicht erneut formuliert werden.
            </div>
            <Card className={classes.textEditorWrapper}>
              <RichTextEditor
                content={editorValue}
                handleChange={handleEditorValueChange}
                enabled={editorEnabled}
                uid={SHARE_BUSINESS_CASE_MODAL_UID}
                useForTextOnly
                setPureEditorValue={setPureEditorValue}
              />
            </Card>
            <div className={classes.editorFooter}>
              {!!symbolsLimitError && <FormControl error={true}><FormHelperText>Maximimale Anzahl an Zeichen überschritten!</FormHelperText></FormControl>}
              <FormControl error={symbolsLimitError} className={classes.counter}>
                <FormHelperText>{numberOfSymbols}/{symbolsLimit}</FormHelperText>
              </FormControl>
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <PrimaryButton
          text='Abbrechen'
          variant='text'
          onButtonClick={handleModalClose}
        />
        <PrimaryButton
          text='An Kunden senden'
          onButtonClick={() => handleShareBusinessCase(selectEmailModal.customer, selectEmailModal.userRole, selectedContact.email, editorEnabled ? pureEditorValue: null)}
          disabled={!selectedContact.email || symbolsLimitError}
        />
      </DialogActions>
    </Dialog>
  )
};

export default SelectContactEmailModal;
