import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles';
import clsx from "clsx";

function WidgetHeader(props) {

  const classes = useStyles();

  return (
    <div className={clsx(classes.headerContainer, props.classes)}>
      {props.children}
    </div>
  )
}

WidgetHeader.propTypes = {

}

export default WidgetHeader

