import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import clsx from "clsx";
import connect from "react-redux/lib/connect/connect";
import {CircularProgress, FormControl, withWidth} from '@material-ui/core';
import Switch from '../../../../components/AssetModal/components/Switch';
import {
  Card,
  CardContent,
  Grid,
  Checkbox
} from '@material-ui/core';
import ReportType from '../CustomersList/components/ReportType';
import { REPORT_TYPES } from '../CustomersList/components/ReportType/constants';
import ReportsAreasVisibility from '../CustomersList/components/ReportsAreasVisibility';
import TimeFramePicker from '../TimeSelector/index';
import useStyles from './styles';
import Snackbar from '../../../../components/Snackbar';
import SaveSettingsButton from '../SaveSettingsButton'
import SeriesReportingBenchmarkModal from '../../../GroupReporting/components/SeriesReportingBenchmark/benchmark';
import SeriesBenchmarkConfiguration from '../../../GroupReporting/components/SeriesReportingBenchmark/index';
import {DASHBOARD_SETTINGS_TYPE, DASHBOARD_TYPE} from '../../constants';
import {getDashboardType} from "../../utils";
import ConfirmationDialog from "../../../VirtualPortfolioManager/modals/ConfirmationDialog";
import FormLabel from "@material-ui/core/FormLabel";
import SeriesBenchmarksList
  from "../../../GroupReporting/components/SeriesReportingBenchmark/components/SeriesBenchmarksList";
import Button from "@material-ui/core/Button";
import {useCustomerPortfoliosList} from "../../../../hooks/useCustomerPortfoliosList";
import PortfolioList from "../../../../components/ProfileDetails/components/PortfolioList";
import {AGGREGATED_PORTFOLIO_ID, renderPortfolioDropdownName} from "../../../../utils/utils";
import portfolios
  from "../../../CustomerDashboard/components/PaymentPlanPortfoliosModal/components/Portfolios/Portfolios";
import {AGGREGATED_PORTFOLIO_NAME} from "../../../../utils/aggregated-portfolio";
import {
  CUSTOMER_RELATED_REPORTS_AREAS_LIST,
  REPORTS_AREAS
} from '../CustomersList/components/ReportsAreasVisibility/constants';


const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
  auth: state.get('auth').toJS(),
});

const aggregatedPortfolioSelected = (portfolios) => _.some(portfolios, (portfolio) => !portfolio.depotNumber);

const settingsAggregatedPortfolioSelected = (portfolios) => portfolios.includes(AGGREGATED_PORTFOLIO_ID);


export const convertSettingsToSelectedPortfolios = (portfolios, defaultSelectedPortfolios, settings, key='portfolios') => {

  const settingsPortfolios = _.get(settings, `${key}.value`);

  if (!settingsPortfolios) {
    return defaultSelectedPortfolios || [];
  }

  if (_.isEmpty(portfolios)) {
    return []
  }

  const aggregatedSelected = settingsAggregatedPortfolioSelected(settingsPortfolios);

  return _.filter(portfolios,
    (portfolio) => {

    return (aggregatedSelected || settingsPortfolios.includes(portfolio.depotNumber))
      && (settings.include_historical_portfolios || !portfolio.is_historical)
    })
}

export const filterOutPortfoliosToExclude = (portfolios, portfoliosToExclude, includeHistorical=undefined) => {

  let result = portfolios;

  if (!!portfoliosToExclude) {
    const excludePortfoliosIdentifiers = portfoliosToExclude.map((portfolio) => portfolio.depotNumber);
    result = portfolios.filter((portfolio) => !excludePortfoliosIdentifiers.includes(portfolio.depotNumber));
  }

  if (!_.isNil(includeHistorical) && !includeHistorical) {
    result = _.filter(result, (portfolio) => !portfolio.is_historical);
  }

  let notAggregatedPortfolios = _.filter(result, (portfolio) => !!portfolio.depotNumber)
  // In case only one real portfolios left - do not include Aggregated portfolio into the list
  if (notAggregatedPortfolios.length == 1) {
    return notAggregatedPortfolios;
  }

  return result;
}

const SELECTED_PORTFOLIOS_DROPDOWN_INFO_TEXT = 'Definieren Sie hier die in der Vermögensübersicht vorausgewählten Depots.';
const EXCLUDED_PORTFOLIOS_DROPDOWN_INFO_TEXT = 'Wählen Sie hier die Depots ab, welche nicht in der Vermögensübersicht angezeigt werden sollen.';

const DashboardSettingsWindow = props => {
  const classes = useStyles();
  const {
    auth,
    settings,
    dateRange,
    loading,
    handleSettingUpdate,
    handleDateRangeChanged,
    snackbarSettings,
    handleSnackbarVisibility,
    benchmarks,
    showBenchmark,
    updateBenchmarkSwitchState,
    handleBenchmarkUpdate,
    customerFullName,
    settingsType, // used in save
    defaultSettingsType, // used for default dashboard
    disableCopySettings,
    title,
    resetSettings,
    customer_id
  } = props;

  const [benchmarkConfiguration, setBenchmarkConfiguration] = React.useState(undefined);
  const [showDeleteSettingModal, setShowDeleteSettingModal] = React.useState(false);
  const {fetch, ...portfoliosData} = useCustomerPortfoliosList(
    customer_id, true, true, true);

  /**
   * Flag, that is used for useEffect dependency to check if
   * Historical checkbox was updated manually and not by component initialization.
   * @type {React.MutableRefObject<undefined>}
   */
  const isHistoricalCheckboxMounted = React.useRef();

  const onBenchmarkConfigured = (withChanges, benchmarks=[], enabled=false) => {
    setBenchmarkConfiguration({modal_opened: false});
    let hasBenchmarks = benchmarks && benchmarks.length > 0;
    if(!hasBenchmarks){ updateBenchmarkSwitchState(false) }
    if(withChanges){
      handleBenchmarkUpdate(benchmarks, hasBenchmarks && !!enabled);
    }
  };

  const handlePortfoliosDropdownExpanded = (expanded) => {
    if (!expanded || portfoliosData.loading || !_.isEmpty(portfoliosData.portfolios)) return;

    fetch();
  }

  const getGroupAreasVisibility = () => {
    let areasVisibility = [];

    if (settings.with_payment_plans) {
      areasVisibility.push(REPORTS_AREAS.PAYMENT_PLANS.value);
    }
    if (settings.with_profit_loss) {
      areasVisibility.push(REPORTS_AREAS.PROFIT_LOSS.value);
    }
    if (settings.include_historical_portfolios) {
      areasVisibility.push(REPORTS_AREAS.INCLUDE_HISTORICAL_PORTFOLIOS.value);
    }
    if (settings.with_transaction_saldo) {
      areasVisibility.push(REPORTS_AREAS.WITH_TRANSACTION_SALDO.value);
    }
    if (settings.with_other_assets) {
      areasVisibility.push(REPORTS_AREAS.OTHER_ASSETS.value);
    }
    if (settings.with_transactions_monitor) {
      areasVisibility.push(REPORTS_AREAS.TRANSACTIONS_MONITOR.value);
    }

    return areasVisibility;
  };

  const isCopySettingsDisabled = () => {
    return disableCopySettings ||
      settingsType === DASHBOARD_SETTINGS_TYPE.BROKER_GROUP ||
      settingsType === DASHBOARD_SETTINGS_TYPE.CUSTOMER_GROUP

  }

  const isDepotStatusReport = settings && settings.report_type == REPORT_TYPES.DEPOT_STATUS.value;
  const isBenchmarksUnavailable = settings &&
   (settings.report_type == REPORT_TYPES.DEPOT_STATUS.value || settings.report_type == REPORT_TYPES.BASIC.value);

  const getValueFromSettings = (settingName) => {
    return _.get(settings, settingName) && !isDepotStatusReport
  }

  const handleDeleteModalClose = () => {
    setShowDeleteSettingModal(false);
  }

  const handleResetSettings = () => {
    resetSettings(settingsType, settings.id, customer_id);
    handleDeleteModalClose();
  }

  const portfoliosForExcludeList = filterOutPortfoliosToExclude(
    portfoliosData.portfolios, undefined, settings.include_historical_portfolios);
  const excludedPortfolios = convertSettingsToSelectedPortfolios(
    portfoliosData.portfolios, portfoliosData.defaultExcluded, settings, 'excluded_portfolios');
  const portfoliosForSelectList = filterOutPortfoliosToExclude(
    portfoliosData.portfolios, excludedPortfolios, settings.include_historical_portfolios);
  const selectedPortfolios = convertSettingsToSelectedPortfolios(
    portfoliosForSelectList, [], settings);


  const _getDropdownPortfoliosValue = (_selectedPortfolios, _allPortfoliosList) => {

    if (aggregatedPortfolioSelected(_selectedPortfolios) || _selectedPortfolios.length == _allPortfoliosList.length) {
      return {
        value: [AGGREGATED_PORTFOLIO_ID],
        placeholder: AGGREGATED_PORTFOLIO_NAME
      }
    }

    return {
      value: _selectedPortfolios.map((portfolio) => portfolio.depotNumber),
      placeholder: renderPortfolioDropdownName(_selectedPortfolios, _allPortfoliosList)
    }

  }

  const handleSelectedPortfoliosChanged = (portfolios) => {
    handleSettingUpdate('portfolios')({
      portfolios: _getDropdownPortfoliosValue(portfolios, portfoliosForSelectList),
      excluded_portfolios: settings.excluded_portfolios
    })
  }

  const handleExcludedPortfoliosChanged = (portfolios) => {
    handleSettingUpdate('portfolios')({
      portfolios: _getDropdownPortfoliosValue(
        filterOutPortfoliosToExclude(selectedPortfolios, portfolios), filterOutPortfoliosToExclude(portfoliosForSelectList, portfolios)),
      excluded_portfolios: _getDropdownPortfoliosValue(portfolios, portfoliosData.portfolios)
    })
  };

  React.useEffect(() => {
    if (isHistoricalCheckboxMounted.current && customer_id && !_.isEmpty(portfoliosData.portfolios)) {
      handleExcludedPortfoliosChanged(excludedPortfolios)
    } else {
      isHistoricalCheckboxMounted.current = true;
    }
  }, [settings.include_historical_portfolios])

  return (
    <>
      <Grid className={classes.container} >
        {!_.isEmpty(settings) && !loading ?
        <CardContent style={{padding: 0}}>
          {title &&
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.title}>
                {title}
              </Grid>
            </Grid>
          }

          <Grid container>
            <Grid item lg={6} md={6} sm={12} xs={12} className={clsx(classes.dashboardSettingsSection,classes.firstSettingsSection)}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom: 8}}>
                  <div className={clsx(classes.periodSelector, loading && 'loading-line')} >
                    <TimeFramePicker
                      calculationDates={dateRange}
                      handleCalculationDatesChanged={handleDateRangeChanged}
                      loading={loading}
                      datePickerHeader={'Zeitraumeinstellung'}
                      selectedDefaultTimeType={settings.date_range}
                      showNoPriceOnWeekendsWarning
                    />
                  </div>
                </Grid>
                {customer_id && (
                  <>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom: 8}}>
                      <div className={clsx(loading && 'loading-line')} >
                        <span className={classes.benchmarkLabel}>Historische Depots</span>
                        <Switch
                          value={getValueFromSettings('include_historical_portfolios')}
                          handleValueChanged={(checked) => handleSettingUpdate('include_historical_portfolios')(checked)}
                        />
                      </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom: 8}}>
                      <div className={clsx(classes.periodSelector, loading && 'loading-line')} >
                        <PortfolioList
                          selectedPortfolios={excludedPortfolios}
                          handleSelectedPortfolioChanged={handleExcludedPortfoliosChanged}
                          portfolios={portfoliosForExcludeList}
                          portfoliosLoadingError={portfoliosData.errors}
                          portfoliosDataLoading={portfoliosData.loading}
                          alwaysScrollableList={true}
                          loading={loading}
                          isListAutoUpdate
                          infoText={EXCLUDED_PORTFOLIOS_DROPDOWN_INFO_TEXT}
                          title={'Depotabwahl'}
                          onExpanded={handlePortfoliosDropdownExpanded}
                          customClasses={{container: classes.portfoliosSelectorContainer}}
                          placeholder={_.get(settings, 'excluded_portfolios.placeholder')}
                        />
                      </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom: 8}}>
                      <div className={clsx(classes.periodSelector, loading && 'loading-line')} >
                        <PortfolioList
                          selectedPortfolios={selectedPortfolios}
                          handleSelectedPortfolioChanged={handleSelectedPortfoliosChanged}
                          portfolios={portfoliosForSelectList}
                          portfoliosLoadingError={portfoliosData.errors}
                          portfoliosDataLoading={portfoliosData.loading}
                          alwaysScrollableList={true}
                          loading={loading}
                          isListAutoUpdate
                          infoText={SELECTED_PORTFOLIOS_DROPDOWN_INFO_TEXT}
                          onExpanded={handlePortfoliosDropdownExpanded}
                          customClasses={{container: classes.portfoliosSelectorContainer}}
                          placeholder={_.get(settings, 'portfolios.placeholder')}
                        />
                      </div>
                    </Grid>
                  </>
                )}

                {/* historical chart settings */}
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.chartSettingsSectionWrapper} style={{marginBottom: 8}}>
                  <FormControl disabled={props.disabled} component="fieldset" style={{width: '100%'}}>
                    <FormLabel component="legend" classes={{root: classes.formLabel}}>Geldgewichtete Rendite-Chart</FormLabel>

                    <Grid container>
                      {/* invested capital switch */}
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <div className={clsx(loading && 'loading-line')} >
                          <span className={classes.benchmarkLabel}>Kapitaleinsatz</span>
                          <Switch
                            value={getValueFromSettings('with_invested_capital')}
                            handleValueChanged={(checked) => handleSettingUpdate('with_invested_capital')(checked)}
                            disabled={isDepotStatusReport}
                          />
                        </div>
                      </Grid>
                      {/* aggregate selected portfolios switch */}
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <span className={classes.benchmarkLabel}>Kumulierte Anzeige</span>
                        <Switch
                          value={getValueFromSettings('aggregate_historical_chart_portfolios')}
                          handleValueChanged={(checked) => handleSettingUpdate('aggregate_historical_chart_portfolios')(checked)}
                          disabled={isDepotStatusReport}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

                {/* Performance chart settings */}
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.chartSettingsSectionWrapper}>
                  <FormControl disabled={props.disabled} component="fieldset" style={{width: '100%'}}>
                    <FormLabel component="legend" classes={{root: classes.formLabel}}>Zeitgewichtete Rendite-Chart</FormLabel>

                    <Grid container>
                    {/* benchmark switch and configuration */}
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <SeriesBenchmarkConfiguration
                          benchmarks={benchmarks}
                          dispatch={setBenchmarkConfiguration}
                          showInvestment={showBenchmark}
                          updateBenchmarkSwitchState={updateBenchmarkSwitchState}
                          disabled={isBenchmarksUnavailable}
                        />
                      </Grid>
                      {/* aggregate selected portfolios switch */}
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <span className={classes.benchmarkLabel}>Kumulierte Anzeige</span>
                        <Switch
                          value={isBenchmarksUnavailable? false : getValueFromSettings('aggregate_performance_chart_portfolios')}
                          handleValueChanged={(checked) => handleSettingUpdate('aggregate_performance_chart_portfolios')(checked)}
                          disabled={isBenchmarksUnavailable}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SeriesBenchmarksList disabled={isBenchmarksUnavailable} benchmarks={benchmarks} />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>

              </Grid>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} className={classes.dashboardSettingsSection}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className={clsx(loading && 'loading-line')} >
                    <ReportType
                      defaultSettingsType={defaultSettingsType}
                      selected={settings && settings.report_type}
                      handleChanged={handleSettingUpdate('report_type')}
                      settingsType={settingsType}
                      customReportTypeSettings={settings && settings.custom_report_type_settings}
                      handleCustomReportSettingUpdate={handleSettingUpdate('custom_report_type_settings')}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className={clsx(loading && 'loading-line')} >
                      <ReportsAreasVisibility
                        options={customer_id && CUSTOMER_RELATED_REPORTS_AREAS_LIST}
                        isDepotStatus={isDepotStatusReport}
                        selected={getGroupAreasVisibility()}
                        handleChanged={handleSettingUpdate('areas_visibility')}
                      />
                    </div>
                </Grid>
              </Grid>
            </Grid>
            {settingsType != DASHBOARD_SETTINGS_TYPE.GENERAL &&
            <Grid container className={classes.reportSaveSection}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <p className={clsx(classes.header, classes.copySettingsHeader)}> Weitere Einstellungen</p>
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                  <div className={
                    clsx(loading && 'loading-line',
                      isCopySettingsDisabled() && classes.contentDisabled)
                  }>
                    <Checkbox
                      color="primary"
                      classes={{root: classes.radio}}
                      checked={settings.save_settings_to_related}
                      onChange={() => handleSettingUpdate('save_settings_to_related')(!settings.save_settings_to_related)}
                    />
                    Einstellung für
                    die {getDashboardType(settingsType) === DASHBOARD_TYPE.BROKER ? 'Kundenansicht' : 'Vermittleransicht'} von <span
                    className={classes.highlightedName}>{customerFullName}</span> übernehmen
                  </div>
                </Grid>
                {(settingsType == DASHBOARD_SETTINGS_TYPE.SINGLE_BROKER || settingsType == DASHBOARD_SETTINGS_TYPE.SINGLE_CUSTOMER) && (
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <div className={clsx(
                      classes.groupItemActions,
                        loading && 'loading-line',
                        _.isNil(settings.id) && classes.contentDisabled
                        )}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setShowDeleteSettingModal(true)}
                        disabled={_.isNil(settings.id)}
                        className={clsx(
                          classes.groupItemActions,
                          _.isNil(settings.id) && classes.contentDisabled
                          )}>
                        <i className="fa fa-trash-o" aria-hidden="true" />
                        <span>Auf Standardeinstellungen zurücksetzen</span>
                      </Button>
                    </div>
                  </Grid>
                  )}
              </Grid>
            }
          </Grid>
        </CardContent>
         :
         <CircularProgress/>
       }
      </Grid>
      <Snackbar open={snackbarSettings.open} variant={snackbarSettings.variant} message={snackbarSettings.message} handleClose={handleSnackbarVisibility}/>
      {benchmarkConfiguration && benchmarkConfiguration.modal_opened && (
        <SeriesReportingBenchmarkModal
          benchmarkConfiguration={benchmarkConfiguration}
          onBenchmarkConfigured={onBenchmarkConfigured}
          initBenchmarks={benchmarks}
          benchmarkEnabled={showBenchmark}
          updateBenchmarkSwitchState={updateBenchmarkSwitchState}
        />
      )}

      <ConfirmationDialog
          message={"Sind Sie sicher, dass Sie die Einstellungen für die Vermögensübersicht auf die Grundeinstellungen zurücksetzen möchten?"}
          open={showDeleteSettingModal}
          onClose={() => handleDeleteModalClose()}
          confirm={handleResetSettings}
        />

    </>
  )
};


export default connect(mapStateToProps)(withRouter(withWidth()(DashboardSettingsWindow)));
