const styles = theme => ({
  root: {
    minHeight: 150,
    width: '100%'
  },
  container: {
    position: 'relative',
    justifyContent: 'space-between',
  },
  description: {
    margin: 0,
    fontSize: 12,
    color: '#8D8D8D',
  },
  smallTitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: '#4D4F5C',
  },
  table: {
    width: '33%',
    paddingRight: 15,
    paddingLeft: 15,
    borderRight: '1px solid #D8DCDF',
    "&:first-child": {
      paddingLeft: 0
    },
    "&:last-child": {
      paddingRight: 0,
      borderRight: 'none'
    },
  },
  listContainer: {
    padding: '12px 0',
  },
  borderBottom: {
    borderBottom: '1px solid #D8DCDF',
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    color: '#4D4F5C',
  },
  subline: {
    marginTop: 'auto',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    padding: '2px 0',
  },
  itemShifted: {
    paddingLeft: 12,
  },
  title: {
    width: '50%',
    fontFamily: 'Roboto-Bold'
  },
  value: {
    width: '50%',
    textAlign: 'right',
  },
  error: {
    textAlign: 'center'
  },
  topPart: {
    display: "flex",

  },
  midPart: {
    height: 15,
    borderBottom: '1px solid #D8DCDF',
  },
  bottomPart: {
    display: "flex",
  },
  helpIcon: {
    verticalAlign: 'bottom',
    fontSize: '1.1rem'
  },
  esgCharsLeft: {
    width: '30%'
  },
  esgCharsRight: {
    width: '50%'
  },
  part: {
    width: '50%',
    "&:first-child": {
      paddingRight: 15,
      paddingLeft: 15
    },
    "&:last-child": {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  tooltip: {
    position: 'relative',
    padding: 20,
    backgroundColor: '#EFEFF2',
    color: '#4D4F5C',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.2)',
    whiteSpace: 'pre-line',
    maxWidth: 500
  }
})

export default styles;