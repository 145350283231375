import React from "react";
import {FETCH_DATA_INITIAL_STATE} from "../../../hooks/constants";
import _ from "lodash";
import {splitProducts} from "./useYearlyPerformanceData";
import {PRODUCT_TYPE} from "../constants";
import {PortfolioHandlerResource} from "../../../utils/api";
import moment from "moment";
import {getPortfolioProductPortfolioIds, getProductCustomerId, getProductIdsAsString} from "../utils";

function useCorrelationMatrixData(products, dateRange) {
  const [data, setData] = React.useState(FETCH_DATA_INITIAL_STATE);

  React.useEffect(() => {
    if (!_.isEmpty(products)) {
      fetchCorrelationMatrixData();
    }
  }, [getProductIdsAsString(products), dateRange.start.format('YYYY-MM-DD'), dateRange.end.format('YYYY-MM-DD')]);

  const fetchCorrelationMatrixData = async () => {
    setData({
      data: null,
      loading: true,
      errors: null,
      updatedAt: +new Date()
    })
    try {

      const response = await PortfolioHandlerResource.getProductsComparisonCorrelationMatrix(
        products.map((product) => {
          const structure = {
            product_id: product.product_id,
            type: product.type,
          }
          if (product.type === PRODUCT_TYPE.CUSTOMER_PORTFOLIO) {
            structure.portfolio_ids = getPortfolioProductPortfolioIds(product);
            structure.customer_id = getProductCustomerId(product)
          }

          return structure
        }),
        dateRange.start.format('YYYY-MM-DD'), dateRange.end.format('YYYY-MM-DD'));

      const data = _.get(response, 'correlation_matrix.data.0');
      const errors = _.get(response, 'correlation_matrix.errors');

      setData({
        data: data || {},
        loading: false,
        errors: !_.isEmpty(errors) ? errors : null,
        updatedAt: +new Date()
      })
    } catch (errors) {
      setData({
        data: null,
        loading: false,
        errors,
        updatedAt: +new Date()
      })
    }
  }

  return data;
}

export default useCorrelationMatrixData;