import { makeStyles } from '@material-ui/core';
import {RISK_FILTERING_TYPE} from "../../../../../../../../components/FilteringPanel/components/RiskFilter/constants";

// default sizes
const defaultBackgroundHeight = 3
const defaultFilledHeight = defaultBackgroundHeight + 2

export default makeStyles(theme => ({
  // class for the hole unfilled line
  background: {
    height:  props => props.backgroundHeight || defaultBackgroundHeight,
    borderRadius: props => props.borderRadius || 0,
    width: '100%',
    display: 'inline-block',
    backgroundColor: RISK_FILTERING_TYPE.RED.color,
    verticalAlign: 'middle',
    position: 'relative',
    color: '#4D4F5C',
    fontSize: 14,
    fontFamily: 'Roboto-Regular'
  },
  // classes to display labels on top left and right sides of chart
  topLeftLabel: {
    display: 'block',
    position: 'absolute',
    bottom: props => props.backgroundHeight + 2 || defaultFilledHeight, // offset for labels is the same as defaultFilledHeight not to overlaps
    left: 0
  },
  topRightLabel: {
    display: 'block',
    position: 'absolute',
    bottom: props => props.backgroundHeight + 2 || defaultFilledHeight,
    right: 0
  },
  // styles to display labels on bottom left and right sides of chart
  bottomLeftLabel: {
    display: 'block',
    position: 'absolute',
    top: props => props.backgroundHeight + 2 || defaultFilledHeight,
    left: 0
  },
  bottomRightLabel: {
    display: 'block',
    position: 'absolute',
    top: props => props.backgroundHeight + 2 || defaultFilledHeight,
    right: 0
  },
  // styles for line that represents filled part
  blueFill: {
    backgroundColor: props => props.fillColor || RISK_FILTERING_TYPE.GREEN.color,
    position: 'absolute',
    height: props => props.backgroundHeight || defaultFilledHeight,
    borderRadius: props => props.borderRadius + 2 || 0,
    width: props => `${props.filledPercentage || 0}%`,
    right: props => !!props.useInvertedLine ? 0 : 'auto',
    textAlign: 'center'
  },
  filledPercentage: {
    color: props => props.filledPercentageColor || 'white',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    fontSize: props => Math.ceil( (props.backgroundHeight || defaultBackgroundHeight) * 0.75) // font size is 75% from line background height
  },
  overweightIcon: {
    fontSize: props => props.backgroundHeight + 12 || 17,
    color: RISK_FILTERING_TYPE.GREEN.color,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
}))
