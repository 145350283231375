import React from "react";
import _ from 'lodash';

import { v4 as uuidv4 } from 'uuid';

import Grid from "@material-ui/core/Grid";
import CheckboxQuestion from "../../question/CheckboxQuestion";
import InputQuestion from "../../question/InputQuestion";
import RadioQuestion from "../../question/RadioQuestion";
import CountrySelectQuestion from "../../question/CountrySelectQuestion";
import LabelFormElement from "../../formElement/LabelFormElement";
import {
  getContractTypeConfiguration, getMemberFieldName,
  getUID,
  isRequiredQuestion,
} from "../../../../../utils";
import InputFormElement from "../../formElement/InputFormElement";
import {countriesChoices, CountryFormElement} from "../../question/CountrySelectQuestion/CountrySelectQuestion";
import {makeStyles} from "@material-ui/core";
import PlusBackgroundIcon from "../../../../../../../images/PlusBackgroundIcon";
import IconButton from '../../../../../../../components/Buttons/IconButton';
import {CountryISOMapping, DefaultCountry, OPTION_IS_NOT_AVAILABLE_FOR_DEPOT_MSG} from "../../../../../constants";
import {DeleteIcon} from "../../../../../../../images";
import MinusBackgroundIcon from "../../../../../../../images/MinusBackgroundIcon";
import {getSubSystemConfigItem} from "../../../../../../../utils/utils";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import {getMemberIndexFromStepUID} from "../../../../../services";


const TaxIdentifiersTable = ({question, onAnswerChange, maxRows}) => {

  const answer = question.answer || [];

  const cleanError = (taxItemId, fieldName) => {
    if (_.isEmpty(question.error)) {
      return;
    }

    if (_.isString(question.error) || _.isArray(question.error)) {
      question.error = null;
    } else {
      _.set(question.error, `${taxItemId}.${fieldName}`, null);
    }
  }

  const onChange = (value, key, index, taxIdItem) => {
    const answer = question.answer || [];

    _.set(answer, `${index}.${key}`, value);
    cleanError(taxIdItem.id, key);

    onAnswerChange(question.uid, answer);
  }

  const onCountryChange = (code, index, taxIdItem) => {
    const answer = question.answer || [];

    const country = countriesChoices.find((option) => option.id == code);

    _.set(answer, `${index}.country`, country);
    cleanError(taxIdItem.id, 'country');

    onAnswerChange(question.uid, answer);
  }

  const showAddButton = (() => {

    if (!answer || _.isEmpty(answer)) {
      return true;
    }

    const lastTaxId = answer[answer.length - 1];
    if (_.isEmpty(lastTaxId.tax_id) && _.isEmpty(lastTaxId.country)) {
      return false;
    }

    return answer.length < maxRows;
  })()

  const taxCountryInfo = (() => {
    const info = getSubSystemConfigItem('trading', 'tax_country_info');

    return info || {};
  })();

  const tableInfoText = (() => {
    return answer.map((item) => {

      const countryCode = _.get(item, 'country.id');

      if (taxCountryInfo.hasOwnProperty(countryCode)) {
        return (
          <p dangerouslySetInnerHTML={{__html: taxCountryInfo[countryCode]}} />
        )
      }
      return null
    })
  })();

  const getTableRows = () => {

    return answer.map((taxIdItem, index) => (
      <Grid item xs={8} key={taxIdItem.id}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <InputFormElement
              value={taxIdItem.tax_id || ''}
              onChange={(value) => onChange(value, 'tax_id', index, taxIdItem)}
              error={_.get(question, `error.${taxIdItem.id}.tax_id`)}
            />
          </Grid>
          <Grid item xs={5} style={{display: 'flex'}}>
            <CountryFormElement
              value={_.get(taxIdItem, 'country.id') || ''}
              required={true}
              onChange={(value) => onCountryChange(value, index, taxIdItem)}
              disabled={taxIdItem.country_disabled}
              error={_.get(question, `error.${taxIdItem.id}.country`)}
            />
          </Grid>
          {answer.length > 1 && !taxIdItem.country_disabled && (
            <Grid item xs={2}>
              <IconButton
                component={MinusBackgroundIcon}
                style={{paddingRight: 0, marginTop: 5}}
                onClick={() => removeTaxId(taxIdItem.id)}
                size={36}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    ))

  }

  const addNewTaxId = () => {
    const newAnswer = [...answer, {
      tax_id: null,
      country: null,
      id: uuidv4(),
    }]

    onAnswerChange(question.uid, newAnswer)
  }

  const removeTaxId = (id) => {
    const newAnswer = _.filter(answer, (item) => item.id != id);
    onAnswerChange(question.uid, newAnswer)
  }

  const isGeneralError = !_.isEmpty(question.error) && (_.isArray(question.error) || _.isString(question.error));

  return (
    <>
      <LabelFormElement
        label={question.question_text}
        required={isRequiredQuestion(question)}
        customClasses={question.custom_classes}
        error={isGeneralError}
      />
      <Grid container>
        {!_.isEmpty(tableInfoText) && (
          <Grid item xs={12}>
            {tableInfoText}
          </Grid>
        )}
        <Grid item xs={7}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LabelFormElement label="SteuerID (TIN)" customClasses={question.custom_classes} style={{paddingBottom: 5}}/>
            </Grid>
            <Grid item xs={6}>
              <LabelFormElement label="Land" customClasses={question.custom_classes} style={{paddingBottom: 5}}/>
            </Grid>
          </Grid>
        </Grid>
        {getTableRows()}
        {showAddButton && (
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={10} justify={"flex-end"} style={{display: 'flex'}}>
                <IconButton
                  component={PlusBackgroundIcon}
                  style={{paddingRight: 0}}
                  onClick={addNewTaxId}
                  size={36}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {isGeneralError && (
          <Grid item xs={8}>
            <FormControl error={true}>
              <FormHelperText>{ question.error }</FormHelperText>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </>
  )
}

// TODO: For now only 2 tax id is possible
const MAX_TAX_ID_COUNT = 2;

const CustomerTaxesStep = props => {
  const {
    onAnswerChange,
    questions: [
      steuerpflichtig,
      tax_identifiers
    ],
    dataService
  } = props;

  React.useEffect(() => {
    let maxTaxIdCount;
    if(!dataService.is_trading) {
      const contract = dataService.custodian.depot.contract_types.find(
        contract => contract.value == dataService.custodian.contract_type) || {};
      const maxTaxIdentifiersConfiguration = getContractTypeConfiguration(
        contract, 'onboarding_tax_identifier_max_options_count_by_customer_type', {});
      // We expect to see only 2 customer types inside config 'account_holder' or 'legal_guardian';
      const memberIndex = getMemberIndexFromStepUID(getUID(dataService._step.uid));
      const configurationKey = !_.isUndefined(memberIndex)
        ? getMemberFieldName(dataService.customer_type)
        : 'account_holder';
      maxTaxIdCount = maxTaxIdentifiersConfiguration[configurationKey]
    }
    tax_identifiers.max = maxTaxIdCount || MAX_TAX_ID_COUNT;

    // Dict to store available/disabled steuerpflichtig to use it later
    const steuerpflichtigOptions = {disabled: [], available: []};

    steuerpflichtig.config.choices.forEach((option) => {
      option.disabled = option.max && option.max > tax_identifiers.max;
      option.info_text = option.disabled ? OPTION_IS_NOT_AVAILABLE_FOR_DEPOT_MSG : undefined;
      option.disabled
        ? steuerpflichtigOptions.disabled.push(option.uid)
        : steuerpflichtigOptions.available.push(option.uid);
    });

    if(!steuerpflichtig.answer) {
      handleSteuerpflichtigChange(steuerpflichtig.uid, steuerpflichtigOptions.available[0]);
    } else {
      let steuerpflichtigAnswer = steuerpflichtig.answer;
      // In case steuerpflichtig has answer and it should be disabled disabled - use first available option.
      if (steuerpflichtigOptions.disabled.includes(steuerpflichtigAnswer)) {
        steuerpflichtigAnswer = steuerpflichtigOptions.available[0];
        handleSteuerpflichtigChange(steuerpflichtig.uid, steuerpflichtigAnswer);
      }

      if (_.isEmpty(tax_identifiers.answer) || tax_identifiers.answer.length > maxTaxIdCount) {
        handleTaxIdentifierChange(steuerpflichtigAnswer);
      }
    }
  }, []);

  /**
   * List of all customer's tax identifiers stored in CRM.
   * @type {[]|*}
   */
  const customerTaxInformation = (() => {
    if (!dataService._current_customer) {
      return [];
    }

    const memberIndex = getMemberIndexFromStepUID(getUID(dataService._step.uid))
    if(!_.isUndefined(memberIndex) && dataService.members[memberIndex]) {
      return dataService.members[memberIndex].taxInformation || [];
    }

    return dataService._current_customer.taxInformation || []
  })();

  const handleTaxIdentifierChange = (steuerpflichtig) => {
    tax_identifiers.error = null;

    let taxIdentifiersAnswer = tax_identifiers.answer || [];

    // Insert first option as Deutchland only in case first option is other that Deutchland
    if (['1', '2'].includes(steuerpflichtig) && _.get(taxIdentifiersAnswer, '0.country.id') != DefaultCountry) {
      const defaultCountry = countriesChoices.find((country) => country.id === DefaultCountry);
      const germanTaxIdentifier = _.find(customerTaxInformation, (item) => item.countryISO == 'DEU');
      taxIdentifiersAnswer.unshift({
        country: defaultCountry,
        id: uuidv4(),
        tax_id: _.get(germanTaxIdentifier, 'taxId') || '',
      });
    }

    if (steuerpflichtig === '1') {
      taxIdentifiersAnswer = [taxIdentifiersAnswer[0]];
    }

    //Prefill other options with other tax id if it exists (data from CRM);
    if (['2', '3'].includes(steuerpflichtig)) {
      const otherTaxIdentifiers = _.filter(customerTaxInformation, (item) => item.countryISO != 'DEU');
      if (otherTaxIdentifiers.length) {
        otherTaxIdentifiers.forEach((taxIdentifier) => {

          // In case answer already has tax id with country - do nothing;
          if (!!_.find(taxIdentifiersAnswer, (answerTaxId) => _.get(answerTaxId, 'country.id') == CountryISOMapping[taxIdentifier.countryISO])) {
            return
          }

          taxIdentifiersAnswer.push({
            id: uuidv4(),
            tax_id: taxIdentifier.taxId,
            country: countriesChoices.find((country) => country.id === CountryISOMapping[taxIdentifier.countryISO])
          });
        })
      }
    }

    // For 1 and 2 options first country is always Deutchland
    // and it is not possible to change country
    _.set(taxIdentifiersAnswer, '0.country_disabled', steuerpflichtig !== '3');

    if (taxIdentifiersAnswer.length > tax_identifiers.max) {
      taxIdentifiersAnswer.splice(tax_identifiers.max);
    }

    onAnswerChange(tax_identifiers.uid, taxIdentifiersAnswer);
  }

  const handleSteuerpflichtigChange = (question, answer) => {
    onAnswerChange(question, answer);
    handleTaxIdentifierChange(answer);
  };

  return (
    <Grid container spacing={6} direction={"column"}>
      <Grid item xs={12}>
        <RadioQuestion
          question={steuerpflichtig}
          onAnswerChange={handleSteuerpflichtigChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TaxIdentifiersTable
          question={tax_identifiers}
          onAnswerChange={onAnswerChange}
          maxRows={steuerpflichtig.answer === "1" ? 1 : tax_identifiers.max}
        />
      </Grid>
    </Grid>
  );
};

export default CustomerTaxesStep;
