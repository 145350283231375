import React from 'react';
import { withStyles } from "@material-ui/core";

import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import styles, { useStyles } from './styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const WeightSlider = withStyles(styles)(Slider);


const WeightSelector = (props) => {

  const {
    weight,
    disabled,
    handleWeightChanged,
    width,
    max
  } = props;

  const classes = useStyles();

  const maxValue = max + weight;
  const [value, setValue] = React.useState(weight || 0);

  const handleChange = (value) => {
    if (value <= maxValue) {
      setValue(value);
    }
  };

  const handleChangeCommitted = (sliderValue) => {

    const resultValue = sliderValue <= maxValue ? sliderValue : maxValue;
    setValue(resultValue);
    handleWeightChanged(resultValue);
  };

  return (
    <div style={{
      height: isWidthDown('sm', width) ? 35 : 45,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      pointerEvents: disabled ? 'none' : 'auto',
      opacity: disabled ? .7 : 1
    }}>
      <WeightSlider
        value={value}
        step={1}
        onChange={(event, value) => handleChange(+value)}
        onChangeCommitted={(event, value) => handleChangeCommitted(+value)}
      />
      <TextField
        type="number"
        inputProps={{
          min: 0
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">&#x25;</InputAdornment>
        }}
        value={value}
        onChange={(event) => {
          handleChangeCommitted(+event.target.value); // is called on change to set new value each time the arrow is clicked
        }}
        style={{
          width: 70,
          marginLeft: 15
        }}
      />
    </div>
  )

};

export default withWidth()(WeightSelector);