import React from "react";
import {SharedSettingsResource} from "../../../../../utils/api";
import {
    setEditorVariables,
  } from "../../../actions";
import EditorStepContent from "../../EditorStepContent";


const CoverLetter = props => {

  const {
    reportSettings,
    handleSettingUpdate,
    loading,
    dispatch
  } = props;

  const fetchVariables = async () => {
    try{
      const response = await SharedSettingsResource.at('editor-variables/').get();
      dispatch(setEditorVariables(response));
      window.editorVariables = response;
    } catch (e) {
      dispatch(setEditorVariables(undefined));
      window.editorVariables = undefined;
    }
  }

  React.useEffect(() => {
    fetchVariables();
  }, []);

  return (
    <EditorStepContent reportSettings={reportSettings} handleSettingUpdate={handleSettingUpdate} />
  );
};

export default CoverLetter;