export default (theme) => ({
  wrapper: {
    display: "flex",
    paddingBottom: 12,
    justifyContent: "space-between",
    flexDirection: "column",
    height: "70%",
  },
  labelText: {
    fontSize: 20,
    fontFamily: "Roboto-Regular",
    color: "#202A38",
    display: "flex",
    alignItems: "baseline",
  },
  ratingRoot: {
    "& > span:not(:last-child)": {
      marginRight: 10,
    },
  },
  helpIcon: {
    verticalAlign: "middle",
    fontSize: "1.1rem",
    color: "#202A38",
  },
  tooltip: {
    position: "relative",
    padding: 20,
    backgroundColor: "#EFEFF2",
    color: "#4D4F5C",
    fontFamily: "Roboto-Regular",
    fontSize: 14,
    boxShadow: "0px 2px 15px 0px rgba(0,0,0, 0.2)",
    whiteSpace: "pre-line",
    maxWidth: 500,
  },
});
