import React from 'react';
import BaseChart from '../../../../../../FactSheetsPage/components/BaseChart';

import {Grid} from '@material-ui/core'

import {
  hasResponseError,
  PortfolioUtils,
} from '../../../../../../../utils/utils';
import _ from 'lodash';
import useStyles from '../styles';

const PortfolioAssetAllocationStructure = (props) => {
  const {
    dashboardData,
    loading
  } = props;

  const classes = useStyles();

  const portfolioStructure = dashboardData && dashboardData.portfolio_structure;

  const dataError = _.isNil(portfolioStructure) ||  hasResponseError(portfolioStructure);

  let data = [];
  if (portfolioStructure) {
    data = PortfolioUtils.getPortfolioAssetAllocations(portfolioStructure)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <BaseChart
            chartType='bar'
            title='Assetallokation'
            seriesData={data}
            loading={loading}
            error={dataError}
            classes={{
              title: (!loading && !data.length) ? classes.titleWithoutData : classes.title,
            }}
          />
      </Grid>
    </Grid>
  );

};

export default PortfolioAssetAllocationStructure;
