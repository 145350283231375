import React from "react";
import { withStyles } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import { createStyles } from '@material-ui/core/styles';

const styles = createStyles({
  icon: {
    marginLeft: 0,
    marginRight: 0,
    fontSize: '1.7rem'
  },
})

function StartIcon({ processing, classes }) {
    return !processing && (
    <SvgIcon classes={{ root: classes.icon }} width="20" height="20" viewBox="0 0 20 20">
      <path
        d="M-1000,758.222c0,.978-.68,1.778-2.111,1.778h-16.111a1.778,1.778,0,0,1-1.778-1.778v-6.444a1.782,1.782,0,0,1,1.889-1.778h1.118a.994.994,0,0,1,.993.993v.014a.994.994,0,0,1-.993.993h-.014a.994.994,0,0,0-.993.993v4.014a.994.994,0,0,0,.993.993h14.014a.994.994,0,0,0,.993-.993v-4.014a.994.994,0,0,0-.993-.993h-.014a.994.994,0,0,1-.993-.993v-.014a.994.994,0,0,1,.993-.993h1.229a1.778,1.778,0,0,1,1.778,1.778v6.444Zm-5.726-11.549,0,0a.994.994,0,0,0-1.408-.05l-1.021.957a.5.5,0,0,1-.842-.365V741a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v6.2a.5.5,0,0,1-.845.362l-.974-.929a.993.993,0,0,0-1.41.04h0a.993.993,0,0,0,.043,1.4l2.848,2.689a2,2,0,0,0,2.753-.007l2.816-2.685A1,1,0,0,0-1005.726,746.673Z"
        transform="translate(1020 -740)" />
    </SvgIcon>
  );
}

export default withStyles(styles)(StartIcon);
