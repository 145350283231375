import {chartLegendSize, smChartLegendSize} from "../../../../../../utils/constants";

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  chartContainer: {
    width: '100%',
    marginBottom: 15
  },
  helpIcon: {
    color: '#202a38',
    width: 18,
    height: 18,
    marginLeft: 3,
    transform: 'rotate(180deg)',
    verticalAlign: 'top',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  legend: {
    style: 'none',
    listStyle: 'none',
    paddingLeft: '0px',
    margin: '10px',
    [theme.breakpoints.down("sm")]: {
      fontSize: smChartLegendSize,
      margin: 0
    }
  },
  legendItem: {
    display: 'block',
  },
  legendBottom: {
    fontFamily: 'Roboto-Regular',
    fontSize: chartLegendSize,
    letterSpacing: "0px",
    color: "#80858C",
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: smChartLegendSize
    }
  },
  bullet: {
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 8
  },
  infoLinkText: {
    width: '100%',
    padding: '5px',
    textAlign: 'right',
    textDecoration: 'underline',
    color: '#0092E5',
    cursor: 'pointer'
  },
  containerError: {
    height: 'calc(100% - 60px)',
  },
  contentWrapper: {
    paddingTop: '0!important',
    height: '100%'
  }
})

export default styles;
