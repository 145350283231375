import React from 'react';
import clsx from "clsx";
import connect from "react-redux/es/connect/connect";
import {Link, withRouter} from "react-router-dom";

import withStyles from '@material-ui/core/styles/withStyles';
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

import styles from './styles';
import {
  buildCurrentCustomerDashboardPath,
  buildCurrentCustomerDashboardVirtualPath,
  buildCurrentCustomerDocumentsPath,
  buildCurrentCustomerOtherAssetsListPath,
  buildCurrentCustomerOtherAssetsPath,
  ROUTES
} from "../../../../routes";
import {UserUtils} from "../../../../utils/utils";
import {buildCustomerAssetsRoute, buildCustomerDashboardRoute} from "../../../../containers/CustomerDashboard/utils";
import useOnClickOutside from "../../../../utils/useOnClickOutside";
import {brokerLogout, customerLogout} from "../../../../containers/Authentication/Auth/utils";

const SideMenu = props => {
  const {
    classes,
    sideMenu,
    dispatch,
    auth,
    guide,
    location: {pathname},
  } = props;

  const node = React.useRef();
  const [opened, setOpened] = React.useState(false);

  React.useEffect(() => {
    if (sideMenu.opened !== opened) {
      setOpened(sideMenu.opened)
    }
  }, [sideMenu])

  useOnClickOutside(node, () => {
    if (guide.open || guide.active) {
      return ;
    }
    if (opened) {
      setOpened(false);
    }
  });

  const handleSwitchMenuState = (event) => {
    setOpened(!opened);
  };

  const handleCustomerLogoutClick = () => {
    handleSwitchMenuState();
    brokerLogout();
  };

  const handleBrokerLogoutClick = () => {
    handleSwitchMenuState();
    customerLogout();
  };

  const activeClass = (path) => {
    return pathname.includes(path) ? classes.active : '';
  };

  const brokerItems = (
    <>
      <div className={classes.section} id='dashboard-and-assets-buttons-tour-element-side-menu'>
        <div className={clsx(classes.item, classes.sectionHeader)}>
          <span>Vermögensübersicht</span>
          <i className={clsx(classes.sectionHeaderIcon, 'chevron-icon fa fa-chevron-up')} />
        </div>
        <Link
          id="portfolio-dashboard-tour-element-side-menu"
          className={clsx(classes.item, classes.childItem, activeClass(ROUTES.BROKER.CUSTOMER_DASHBOARD.path.replace('/:customer_id', '')))}
          to={buildCustomerDashboardRoute()}
          onClick={handleSwitchMenuState}
        >
          {ROUTES.BROKER.CUSTOMER_DASHBOARD.name}
        </Link>
        <Link
          id="assets-tour-element-side-menu"
          className={clsx(classes.item, classes.childItem, activeClass(ROUTES.BROKER.OTHER_ASSETS.path.replace('/:customer_id', '')))}
          to={buildCustomerAssetsRoute()}
          onClick={handleSwitchMenuState}
        >
          {ROUTES.BROKER.OTHER_ASSETS.name}
        </Link>
        {/* <Link
          id="assets-tour-element-side-menu"
          className={clsx(classes.item, classes.childItem, activeClass(ROUTES.BROKER.BROKER_SETTINGS.path))}
          to={ROUTES.BROKER.BROKER_SETTINGS.path}
          onClick={handleSwitchMenuState}
        >
          {ROUTES.BROKER.BROKER_SETTINGS.name}
        </Link> */}
      </div>
      {/*<div className={classes.section}>*/}
        {/*<Link*/}
        {/*id="risk-dashboard-side-menu-tour-element"*/}
        {/*className={clsx(classes.item, activeClass(ROUTES.BROKER.RISK_DASHBOARD.path))}*/}
        {/*to={ROUTES.BROKER.RISK_DASHBOARD.path}*/}
        {/*onClick={handleSwitchMenuState}*/}
        {/*>*/}
        {/*{ROUTES.BROKER.RISK_DASHBOARD.name}*/}
        {/*</Link>*/}
      {/*</div>*/}
      <div className={classes.section} id="fourth-step-side-menu">
        <div className={clsx(classes.item, classes.sectionHeader)}>
          <span>Reporting</span>
          <i className={clsx(classes.sectionHeaderIcon, 'chevron-icon fa fa-chevron-up')} />
        </div>
        <Link
          id="report-settings-button-side-menu"
          className={clsx(classes.item, classes.childItem, activeClass(ROUTES.BROKER.REPORT_SETTINGS.path), activeClass(ROUTES.BROKER.GROUP.path))}
          to={ROUTES.BROKER.REPORT_SETTINGS.path}
          onClick={handleSwitchMenuState}
        >
          {ROUTES.BROKER.REPORT_SETTINGS.name}
        </Link>
        <Link
          id="series-reporting-button-side-menu"
          className={clsx(classes.item, classes.childItem, activeClass(ROUTES.BROKER.REPORTING.path))}
          to={ROUTES.BROKER.REPORTING.children.GROUPS.path}
          onClick={handleSwitchMenuState}
        >
          {ROUTES.BROKER.REPORTING.name}
        </Link>
      </div>
      <div className={clsx(classes.section, classes.logout)} id="logout-button-tour-element-side-menu">
        <a
          className={clsx(classes.item, classes.sectionHeader, classes.actionHeader)}
          href={null}
          onClick={handleBrokerLogoutClick}
        >
          <i className={clsx(classes.itemIcon, 'chevron-icon fa fa-chevron-left')} />
          <span>ZURÜCK ZUR DIVA</span>
        </a>
      </div>
    </>
  );

  const customerItems = (
    <>
      <div className={classes.section} id="portfolio-dashboard-tour-element-side-menu">
        <Link
          to={buildCurrentCustomerDashboardPath()}
          onClick={handleSwitchMenuState}
          className={clsx(classes.item, activeClass(buildCurrentCustomerDashboardPath()))}
        >
          {ROUTES.CUSTOMER.DASHBOARD.name}
        </Link>
      </div>

      {UserUtils.hasVirtualPortfolioAccess(auth) && <div
        id="virtual-portfolio-dashboard-tour-element-side-menu">
          <Link
            to={buildCurrentCustomerDashboardVirtualPath(auth.user.customer_id)}
            onClick={handleSwitchMenuState}
            className={clsx(classes.item, activeClass(buildCurrentCustomerDashboardVirtualPath(auth.user.customer_id)))}
          >
            {ROUTES.CUSTOMER.DASHBOARD_VIRTUAL.name}
          </Link>
      </div>}

      <div className={classes.section} id="assets-tour-element-side-menu">
        <Link
          to={buildCurrentCustomerOtherAssetsListPath()}
          onClick={handleSwitchMenuState}
          className={clsx(classes.item, activeClass(buildCurrentCustomerOtherAssetsPath()))}
        >
          {ROUTES.CUSTOMER.OTHER_ASSETS_LIST.name}
        </Link>
      </div>
      <div className={classes.section} id="documents-tour-element-side-menu">
        <Link
          to={buildCurrentCustomerDocumentsPath()}
          onClick={handleSwitchMenuState}
          className={clsx(classes.item, activeClass(buildCurrentCustomerDocumentsPath()))}
        >
          {ROUTES.CUSTOMER.DOCUMENTS.name}
        </Link>
      </div>
      <div className={clsx(classes.section, classes.logout)} id="logout-button-tour-element-side-menu">
        <a
          className={clsx(classes.item, classes.sectionHeader, classes.actionHeader)}
          href={null}
          onClick={handleCustomerLogoutClick}
        >
          <i className={clsx(classes.itemIcon, 'fa fa-sign-out text-muted-opacity')} />
          <span>Abmelden</span>
        </a>
      </div>
    </>
  );

  return (
    <div className={clsx(classes.container, opened && classes.opened)} ref={node}>
      <div className={classes.button} onClick={handleSwitchMenuState}>
        <span className={classes.buttonLabel}>MENU</span> {opened ? <CloseIcon /> : <MenuIcon />}
      </div>

      {opened && (
        <div className={classes.sideMenuItems}>
          {UserUtils.isBroker(auth) && brokerItems}
          {UserUtils.isCustomer(auth) && customerItems}
        </div>
      )}
    </div>
  )
};

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
  sideMenu: state.get('sideMenu').toJS(),
  guide: state.get('guide').toJS()
});

export default withStyles(styles)(connect(mapStateToProps)(withRouter(SideMenu)));