import React from 'react';
import { withRouter } from 'react-router-dom';

/* Material-UI Components */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

/* BCA Components */
import EquityForm from './components/EquityForm';
import InsuranceForm from './components/InsuranceForm';
import CashForm from './components/CashForm';
import ImmobilienForm from './components/ImmobilienForm';
import withNotification from '../NotificationProvider';

/* BCA modules */
import useStyles from './styles';
import { ASSET_MODAL_TYPE } from './constants';
import {PortfolioManagerResource} from "../../utils/api";


export default withNotification(withRouter((props) => {
  const classes = useStyles();

  const {
    asset,
    assetType,
    onModalClose,
  } = props;

  React.useEffect(() => {
    document.body.style.overflowY = asset ? 'hidden' : 'auto';
  }, [asset]);

  const handleModalClose = () => {
    if (onModalClose) {
      onModalClose();
    } 
  };

  const renderContent = () => {
    switch (assetType) {
      case ASSET_MODAL_TYPE.EQUITY: {
        return <EquityForm asset={asset} viewOnly/>
      }
      case ASSET_MODAL_TYPE.INSURANCE: {
        return <InsuranceForm asset={asset} viewOnly/>
      }
      case ASSET_MODAL_TYPE.CASH: {
        return <CashForm asset={asset} viewOnly/>
      }
      case ASSET_MODAL_TYPE.IMMOBILIEN: {
        return <ImmobilienForm asset={asset} viewOnly/>
      }
      default: {
        return null
      }
    }
  };

  return (
    <Dialog
      open={!!asset}
      onClose={() => handleModalClose()}
      fullWidth={true}
      scroll="body"
      maxWidth={'md'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.container}
      classes={
        {
          root: classes.root,
          paper: classes.paper
        }
      }
    >
      <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
        <span>Anzeigen</span>
        <IconButton disableRipple aria-label="close" className={classes.closeButton} onClick={() => handleModalClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {asset && assetType && renderContent()}
      </DialogContent>
      <DialogActions className={classes.modalAction}>
        <div>
          <Button onClick={() => handleModalClose()} color="primary" className={classes.discardButton}>
            Schließen
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}));