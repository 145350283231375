import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';

import { Grid } from '@material-ui/core';

import BaseDialog from '../../../../../../components/BaseDialog/BaseDialog';
import PrimaryButton from '../../../../../../components/Buttons/PrimaryButton';
import CustomerListItem from '../../../../../DashboardSettings/components/CustomersList/components/CustomerListItem';
import { DASHBOARD_SETTINGS_TYPE, DASHBOARD_TYPE } from '../../../../../DashboardSettings/constants';
import { connect } from 'react-redux';
import {
  getDashboardSettingsType,
  onCustomerSettingsChanged,
  resetDashboardSettings
} from '../../../../../DashboardSettings/utils';
import PromptDialog from '../../../../../../components/PromptDialog';
import useStyles from './styles';
import { CustomerReportSettingResource } from '../../../../../../utils/api';
import { displayErrorSnackBar, displaySuccessSnackBar } from '../../../../../../components/SnackbarProvider/actions';
import { getErrorMessage } from '../../../../../../utils/utils';
import ResetSettingsBtn
  from '../../../../../DashboardSettings/components/DasboardSettingWindowV2/components/ResetSettingsBtn';


export const DashboardSettingsModal = connect()((props) => {
  const {
    customerId,
    dashboardSettings,
    open,
    handleClose,
    dispatch,
    afterUpdate,
    customerFullName,
    customerHasDashboardGroups,
    brokerId,
    dashboardSettingsLoaded,
  } = props;

  const classes = useStyles();

  const getCustomer = () => ({
    customer_id: customerId,
    customer_full_name: customerFullName,
    // no customer_id -> default (standart) settings so remove it's id just to pre-fill values
    settings: {
      ..._.cloneDeep(dashboardSettings),
      id: !!dashboardSettings.customer_id ? dashboardSettings.id : undefined,
      broker_id: brokerId,
      customer_id: customerId,
    },
  });

  const [showSettingsSaveDialog, setShowSettingsSaveDialog] = React.useState(false);
  const [saveInProgress, setSaveInProgress] = React.useState(false);
  const [updatedCustomer, setUpdatedCustomer] = React.useState(undefined);
  const hasChanges = !_.isEmpty(updatedCustomer);

  const customer = React.useMemo(() => {
    return getCustomer();
  }, [customerId, dashboardSettingsLoaded]);

  const handleSettingsSave = async () => {
    setSaveInProgress(true);
    try {
      const res = await CustomerReportSettingResource.at('customer/dashboard-settings/create/')
        .post({
          data: [updatedCustomer],
          settings_type: DASHBOARD_SETTINGS_TYPE.SINGLE_BROKER
        });

      if (_.isEmpty(_.get(res, '0.errors'))){
        dispatch(displaySuccessSnackBar('Einstellungen erfolgreich geändert'))
      } else {
        dispatch(displayErrorSnackBar('Fehler beim Ändern der Einstellungen. Bitte versuchen Sie es erneut.'))
      }
    } catch (e) {
      dispatch(displayErrorSnackBar(getErrorMessage(e)))
    }
    afterSave()
  };

  // FIXME: does it makes sense to pass customer back as first param?
  const handleCustomerSettingsChanged = (customerData, field, value) => {
    // NOTE: we don't use deepCopy as we update only first lvl fields
    const currentSettings = updatedCustomer || {...customer.settings};
    const [updatedCustomerData, isOldEqualNew] = onCustomerSettingsChanged(currentSettings, customer.settings, field, value);

    setUpdatedCustomer(isOldEqualNew ? undefined : updatedCustomerData);
  };

  const resetSettings = async (type, settings_id, customer_id) => {
    setSaveInProgress(true);
    await resetDashboardSettings(type, settings_id, customer_id, dispatch);
    afterSave()
  };

  const afterSave = () => {
    afterUpdate && afterUpdate();
    handleClose();
    setUpdatedCustomer(undefined);
    setSaveInProgress(false);
  };

  const resetChangesAndClose = () => {
    setUpdatedCustomer(undefined);
    setShowSettingsSaveDialog(false);
    handleClose()
  };

  const onClose = () => {
    if(hasChanges){
      setShowSettingsSaveDialog(true);
    } else {
      handleClose();
    }
  };

  const dashboardType = DASHBOARD_TYPE.BROKER;

  return (
    <>
      <BaseDialog
        title={'Einstellungen'}
        open={open}
        handleClose={onClose}
        actions={
          <Grid container justify={'flex-end'} spacing={2}>
            <Grid item>
              <ResetSettingsBtn
                settingsId={(updatedCustomer || customer.settings).id}
                customer_id={customerId}
                resetSettings={resetSettings}
                settingsType={getDashboardSettingsType(dashboardType)}
              />
            </Grid>
            <Grid item>
              <PrimaryButton onButtonClick={handleSettingsSave} text={'Übernehmen'} disabled={!hasChanges}/>
            </Grid>
          </Grid>
        }
        maxWidth={'md'}
        modalLoading={saveInProgress}
      >
        {/* negative margin to align padding of DialogContent */}
        <div style={{margin: '0 -16px'}}>
          <CustomerListItem
            updatedCustomer={updatedCustomer}
            customer={customer}
            customerLoadingError={false}
            handleCustomerSettingsChanged={handleCustomerSettingsChanged}
            dashboardType={dashboardType}
            resetSettings={resetSettings}
            disableCopySettings={customerHasDashboardGroups}
            newDesign
            singleView
          />
        </div>
      </BaseDialog>
      <PromptDialog
        isOpen={showSettingsSaveDialog}
        title={<><i className={clsx('far fa-info-circle', classes.infoIcon)} /> Nicht gespeicherte Änderungen</>}
        message={'Wollen Sie das Menü wirklich schließen? Sie haben ungespeicherte Änderungen'}
        okBtnLabel={'Ja'}
        cancelBtnLabel={'Nein'}
        handleYesBtn={resetChangesAndClose}
        handleNoBtn={() => setShowSettingsSaveDialog(false)}
        maxWidth={'sm'}
        customClasses={{dialogTitle: classes.dialogTitle}}
      />
    </>
  )
});