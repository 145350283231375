import {ADD_ROUTES, SET_HEADER_INFO, SET_REFRESH_HEADER_TRIGGER, SET_NEW_ALERTING_EVENTS_COUNT, CLEAN_NEW_ALERTING_EVENTS_COUNT} from './constants'

export function addRoutes(routes) {
  return {
    type: ADD_ROUTES,
    routes
  }
}

export function setHeaderInfo(component) {
  return {
    type: SET_HEADER_INFO,
    component
  }
}

export const setRefreshHeaderTrigger = () => ({
  type: SET_REFRESH_HEADER_TRIGGER
})

export const setNewAlertingEventsCount = (new_alerting_events_count) => ({
  type: SET_NEW_ALERTING_EVENTS_COUNT,
  new_alerting_events_count
})

export const cleanNewAlertingEventsCount = () => ({
  type: CLEAN_NEW_ALERTING_EVENTS_COUNT
})