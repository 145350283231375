import React from 'react'

import useStyles from './styles'

const BanksHint = props => {
  const {
    bankName,
    isBankOnline,
    additionalBankText
  } = props;
  const classes = useStyles();


  const onlineBankHint = () => {
    return (
      <div className={classes.bankInfo}>
        <span>
          Bei Nutzung der elektronischen Unterschrift für die Aufträge an die {bankName} werden diese nach Unterzeichnung und Bestätigung durch die Kundin/den Kunden direkt an die Depotstelle übermittelt. Eine zusätzliche Übermittlung dieser Formulare per Post, E-Mail oder Fax ist dann nicht mehr erforderlich.
        </span>
        <br/>
        <span className={classes.italicText}>
          Alle zusätzlichen Formulare, aus dem Formularcenter, müssen vorerst auf gewohntem Wege an die jeweilige Depotstelle gesendet werden.
        </span>
      </div>
    )
  }
  const offlineBankHint = () => {
    return (
      <div className={classes.bankInfo}>
        <span>
          Bei Nutzung der elektronischen Unterschrift für die Aufträge an die {bankName} werden diese nach Unterzeichnung und Bestätigung durch die Kundin/den Kunden noch nicht an die Depotstelle übermittelt.
        </span>
        <br/>
        <span>
          Bitte beachten Sie, dass Sie alle Formulare, zur erfolgreichen Umsetzung per E-Mail, Post oder Fax an die Depotstelle weiterreichen müssen.
        </span>
      </div>
    )
  }

  const additionalBankHint = () => {
    return (
      <div className={classes.bankInfo}>
        <br/>
        <span>
          {additionalBankText}
        </span>
      </div>
    )
  }
  
  return (
    <>
      {isBankOnline ? onlineBankHint() : offlineBankHint()}
      {additionalBankText && additionalBankHint()}
    </>
  )
};
  
export default BanksHint;
  