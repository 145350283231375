import React from 'react';

import useStyles from './styles';

const CallMoneyIcon = () => {
  const classes = useStyles();

  return (
    <span className={classes.iconContainer}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path className="a" d="M14.25,13.55a1.689,1.689,0,0,1-1.31,1.67v.45a.665.665,0,1,1-1.33,0v-.46a1.67,1.67,0,0,1-1.31-1.66.665.665,0,1,1,1.33,0c0,.35.35.43.65.43s.64-.08.64-.43c0-.23,0-.25-.73-.61l-.42-.21c-.75-.37-1.47-.72-1.47-1.8a1.673,1.673,0,0,1,1.31-1.67V8.8a.665.665,0,1,1,1.33,0v.46a1.677,1.677,0,0,1,1.29,1.67.665.665,0,1,1-1.33,0c0-.29-.21-.44-.62-.44-.3,0-.65.08-.65.44,0,.23,0,.25.73.61l.42.2C13.53,12.12,14.25,12.47,14.25,13.55ZM.869,23.968,5.9,22.353l4.431.525a1.358,1.358,0,0,0,.654-.08l4.975-1.9a1.741,1.741,0,0,0-1.042-3.317L10.436,18.8l-1.312-.2a1.8,1.8,0,0,0-1.4-3.242l-3.677.855A1.36,1.36,0,0,0,3.6,16.4L.3,18.549a.665.665,0,1,0,.725,1.115l3.316-2.155,3.671-.854a.469.469,0,0,1,.3.883l-1.674.8a.665.665,0,0,0,.185,1.258l3.409.522a1.39,1.39,0,0,0,.574-.032l4.446-1.219a.411.411,0,0,1,.236.786l-5,1.908-4.43-.526a1.352,1.352,0,0,0-.588.057L.461,22.7a.665.665,0,0,0,.2,1.3A.658.658,0,0,0,.869,23.968ZM16.278,8.643l3.681-.856A1.374,1.374,0,0,0,20.4,7.6l3.3-2.147a.665.665,0,0,0-.725-1.115L19.656,6.492l-3.671.854a.468.468,0,0,1-.52-.263.474.474,0,0,1,.224-.621l1.674-.8a.665.665,0,0,0-.185-1.258l-3.413-.523a1.369,1.369,0,0,0-.57.032L8.749,5.136a.408.408,0,0,1-.488-.3.412.412,0,0,1,.252-.49l5-1.909,4.433.526a1.37,1.37,0,0,0,.585-.056L23.539,1.3A.665.665,0,1,0,23.131.033L18.1,1.648l-4.434-.526a1.369,1.369,0,0,0-.651.081L8.041,3.108A1.74,1.74,0,0,0,9.083,6.423L13.564,5.2l1.312.2a1.8,1.8,0,0,0,1.4,3.243Z" transform="translate(0 0)"/></svg>
    </span>
  );
};

export default CallMoneyIcon;