import React from "react";


import {useStyles} from "../styles";
import { TextField } from '@material-ui/core'
import {KeyboardTimePicker} from "@material-ui/pickers";
import clsx from "clsx";

const TimePickerFormElement = props => {
  const {
    label,
    required,
    value,
    disabled,
    onChange,
    custom_classes,
    disablePast,
    disableFuture,
    minDate,
    maxDate,
    error,
  } = props;

  const classes = useStyles();

  const handleChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <KeyboardTimePicker
      error={!!error}
      helperText={error}
      format="HH:mm"
      placeholder="-"
      label={label}
      value={value}
      disabled={disabled}
      required={required}
      onChange={handleChange}
      disablePast={Boolean(disablePast) || false}
      disableFuture={Boolean(disableFuture) || false}
      minDate={minDate}
      maxDate={maxDate}
      invalidDateMessage={'Falsches Zeitformat'}
      autoOk={true}
      classes={{
        root: custom_classes && custom_classes.textFieldRoot,
      }}
      InputProps={{
        classes: {
          root: clsx(classes.inputRoot, custom_classes && custom_classes.inputRoot),
          focused: classes.inputFocused,
        }
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: clsx(classes.labelRoot, custom_classes && custom_classes.labelRoot),
          focused: classes.labelFocused,
          asterisk: classes.labelAsterisk
        }
      }}
      KeyboardButtonProps={{
        classes: {
          root: custom_classes && custom_classes.timePickerButtonRoot
        }
      }}
    />
  )
};

export default TimePickerFormElement;