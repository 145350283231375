import moment from "moment";
import _ from "lodash";

import {
  calculatePortfolioInstrumentsAverageSrri,
  getAmount,
  getAvailableOrderingFields,
  getTransactionItemSRRI
} from "./utils";
import {FIELD_REQUIRED_MSG, OBJECT_TYPES, WEEKENDS_IS_NOT_AVAILABLE_MSG} from "../RiskProfiling/constants";
import {QTY_DECIMALS} from "../../utils/utils";
import {calculateInstrumentsAverageSRRI, splitEinzeltitelTradingInstruments} from "../RiskProfiling/utils";
import {toGermanFormat} from "../../utils/numberFormater";
import {TRADING_ACTION_SWITCH_PLAN} from "../../components/Charts/InstrumentsAllocationTable/constants";
import {ORDER_TYPES} from "../VirtualPortfolioManager/constants";

const MAX_AMOUNT = 9999999999.99 // based on CIOS amount_eur DecimalField(max_digits=9, decimal_places=2)

export const SWITCH_SALDO_POSITIVE = 'Sie haben für ein Instrument aus Ihrem Portfolio noch Anteile zur Verfügung, welche nicht verteilt worden sind.'
export const SWITCH_SALDO_NEGATIVE = 'Es stehen keine Anteile zur weiteren Verteilung mehr zur Verfügung.'
export const DATE_IN_PAST_ERROR = 'Das Datum kann nicht in der Vergangenheit liegen.';
export const END_DATE_SHOULD_BE_AFTER_START = 'Das Enddatum muss nach dem Startdatum liegen.';

export const isInPast = (date, minDate) => {

  return !date.isValid() || minDate.diff(date, 'days') > 0

}

export const getPaymentPlanMaxStartDate = (paymentPlan, portfolio, action) => {

  if (action === 'edit'
      && portfolio.payment_plan_till_month
      && isInPast(moment(_.get(paymentPlan, 'data.start_date')), moment().add(1, 'days'))) {

    return moment().add(1, 'M')
  }

}

export const TRANSACTION_VALUE_NOT_SELECTED = 'Bitte geben Sie einen Wert größer als 0 ein.'

export const isWeekend = (date) => {

  const weekday = date.format('dddd'); // Sonntag ... Samstag
  return !date.isValid() || ['Sonntag', 'Samstag'].includes(weekday)

}

export const isNotAvailable = (date, availableDays, maxDate=undefined) => {
  if(_.isEmpty(availableDays)) return false; // empty available days = all available

  const dayNumber = new Date(date).getDate()

  return !availableDays.includes(dayNumber) || (maxDate && date > maxDate)
}

const isLessThen = (date, dateToCheck) => {
  return moment(date) <= moment(dateToCheck)
}

export const nextAvailableDate = (date, portfolio, transactionType) => {
  date = !!date ? moment(date) : undefined;

  const minDate = moment().add(1, 'days');

  let availableDays = {
    [TRANSACTION_TYPE_VALUES.SAVINGS_PLAN]: portfolio.isModelportfolio
      ? portfolio.availableMPSavingsPlanExecutionDates
      : portfolio.availableSavingsPlanExecutionDates,
    [TRANSACTION_TYPE_VALUES.PAYOUT_PLAN]: portfolio.isModelportfolio
      ? portfolio.availableMPPayoutPlanExecutionDates
      : portfolio.availablePayoutPlanExecutionDates,
    [TRANSACTION_TYPE_VALUES.SWITCH_PLAN ]: portfolio.isModelportfolio
      ? portfolio.availableMPSwitchPlanExecutionDates
      : portfolio.availableSwitchPlanExecutionDates
  }[transactionType]
  availableDays = (availableDays || []).map(option => option.value);

  const keepDayEnabled = transactionType === TRANSACTION_TYPE_VALUES.SAVINGS_PLAN
    && portfolio.payment_plan_keep_day
    && !!date && availableDays.includes(date.date())

  if(!date || isInPast(date, minDate) || isNotAvailable(date, availableDays)){
    let firstAvailable = minDate;

    while (isNotAvailable(firstAvailable, availableDays) || (keepDayEnabled && firstAvailable.date() !== date.date())){
      firstAvailable = firstAvailable.add(1, 'days');
    }

    return firstAvailable;
  }
}

export const validateEinzeltitelLimit = (item, errors, getPriceCallback) => {
  if (item.limit) {
    let price = getPriceCallback(item)
    if (price) {
      price = _.round(price, 2)
      if (['stop buy', 'tailing stop buy'].includes(item.order_type) && item.limit <= price) {
        errors.limit = `Muss über dem Kurs liegen`
      } else if (['stop loss', 'tailing stop loss'].includes(item.order_type) && item.limit >= price) {
        errors.limit = `Muss unter dem Kurs liegen`
      }
    }
  }
}

export const validateDate = (date, validators) => {
  for (let {func, args, error} of validators) {
    let dateMoment = date ? moment(date) : undefined;
    if (func(dateMoment, ...args)) return error
  }
};

const FIELD_REQUIRED_MESSAGE = 'Die gewählte Auswahl ist nicht verfügbar.';

// TODO: move to trade_constants.js
export const getBankMinAmount = (portfolio, transaction) => {
  const getBankMinAmountKey = () => {
    switch (transaction) {
      case TRANSACTION_TYPE_VALUES.SWITCH_PLAN:
        return 'ordering_switch_plan_min_amount_payment';
      case TRANSACTION_TYPE_VALUES.SAVINGS_PLAN:
        return portfolio.isModelportfolio
          ? 'ordering_mp_savings_plan_min_amount_payment'
          : 'ordering_savings_plan_min_amount_payment';
      case TRANSACTION_TYPE_VALUES.PAYOUT_PLAN:
        return portfolio.isModelportfolio
          ? 'ordering_mp_payout_plan_min_amount_payment'
          : 'ordering_payout_plan_min_amount_payment';
      default:
        return 'ordering_min_amount_payment'
    }
  };

  return parseFloat(_.get(portfolio, getBankMinAmountKey(), 0));
};

export const getAssetMinOrderAmount = (item, transaction) => {
  const minAmountKey = {
    [TRANSACTION_TYPE_VALUES.SAVINGS_PLAN]: 'min_amount_savings_plan',
    [TRANSACTION_TYPE_VALUES.SWITCH_PLAN]: 'min_amount_savings_plan',
    [TRANSACTION_TYPE_VALUES.PAYOUT_PLAN]: 'min_amount_payout_plan',
  }[transaction] || 'min_order_amount';

  return item[minAmountKey] || 0;
};

export function validatePortfolioTransactions(portfolio, dataService) {

  const isAnlageberatung = dataService.serviceConcept == SERVICE_CONCEPTS.Anlageberatung
  const isItemRiskScoreConfirmed = (item) => {

    if ([TRANSACTION_TYPE_INTEGER.SELL, TRANSACTION_TYPE_INTEGER.SWITCH, TRADING_ACTION_SWITCH_PLAN].includes(item.tradingType) || ['delete', 'edit'].includes(item.action)) return true;

    const itemSRRI = getTransactionItemSRRI(item, item.data.is_model_portfolio, true)

    return !itemSRRI
      || (itemSRRI <= dataService.srri)
      || (itemSRRI > dataService.srri && !_.isEmpty(item.risk_score_explanation))
  }

  let hasAnyTransactions = false;
  let hasAnyErrors = false;

  const getPortfolioProcessErrorsKey = (process) => `${process}_errors`
  const getPortfolioProcessHasTransactionsKey = (process) => `has_${process}_transactions`

  Object.keys(TRADING_OBJECT_TYPE_TRANSACTIONS).forEach((process) => {

    let hasProcessTransactions = false;
    let hasProcessErrors = false;
    const processErrorKey = getPortfolioProcessErrorsKey(process)
    const portfolioHasProcessTransactionsKey = getPortfolioProcessHasTransactionsKey(process)
    portfolio[portfolioHasProcessTransactionsKey] = false

    portfolio[processErrorKey] = null; // clean errors
    let allRiscScoresConfirmed = true;

    const processHasDisabledInstruments = TRADING_OBJECT_TYPE_TRANSACTIONS[process].reduce((hasDisabled, transaction) => {

      if (hasDisabled) {
        return hasDisabled
      }

      if (!portfolio.transactions.hasOwnProperty(transaction)) {
        return false
      }

      return _.find(portfolio.transactions[transaction], (instrument) => !!_.get(instrument, 'data.disabledByFilter'))

    }, false)

    const validateTransactionAmount = (errors, amount, minAmount, errorKey='transaction_value') => {
      if (amount < minAmount) {
        errors[errorKey] = `Mindestbetrag: ${minAmount} €`;
      } else if (amount > MAX_AMOUNT) {
        errors[errorKey] = `Maximaler Betrag: ${MAX_AMOUNT} €`;
      }
    }

    TRADING_OBJECT_TYPE_TRANSACTIONS[process].forEach(transaction => {
      let portfolioTransactionValid = true, nonFunds = [], funds = [];

      const isPaymentPlan = [
        TRANSACTION_TYPE_VALUES.SAVINGS_PLAN,
        TRANSACTION_TYPE_VALUES.PAYOUT_PLAN,
        TRANSACTION_TYPE_VALUES.SWITCH_PLAN
      ].includes(transaction);

      // TODO: Workaround for old processes. Remove later
      if (!portfolio.transactions.hasOwnProperty(transaction)) {
        return
      }

    [nonFunds, funds] = splitEinzeltitelTradingInstruments(portfolio.transactions[transaction], portfolio.available_asset_classes, nonFunds, funds)

    if (funds.length) {
      funds.forEach(item => {
        hasProcessTransactions = true;
        portfolio[portfolioHasProcessTransactionsKey] = true
        hasAnyTransactions = true;
        const value = item.transaction_value || 0;
        item.errors = null;
        let errors = {};
        const availableOrderingFields = getAvailableOrderingFields(portfolio, transaction);
        // region Transaction Type validation. Check, if selected transaction_type is available for custodian

        const transactionTypeValid = availableOrderingFields.includes(
          TRANSACTION_INSTR_ORDERING_FIELD_NAMES[item.transaction_type]) || isPaymentPlan

        if (!transactionTypeValid) {
          errors.transaction_type = 'Die gewählte Auswahl ist nicht verfügbar.'
        }

        // endregion

        // region Sell all validation. Validate in case sell or switch transaction.
        const sellAllPossible = availableOrderingFields.includes(TRANSACTION_INSTR_ORDERING_FIELD_NAMES.is_all)
        const qtyAvailable = availableOrderingFields.includes(TRANSACTION_INSTR_ORDERING_FIELD_NAMES[TRANSACTION_INSTR.qty]);
        const sellAllInvalid = [TRANSACTION_TYPE_VALUES.SELL, TRANSACTION_TYPE_VALUES.SWITCH].includes(transaction) && item.sell_all && !sellAllPossible && !qtyAvailable;

        if (sellAllInvalid) {
          errors.sell_all = 'Die gewählte Auswahl ist nicht verfügbar.'
        }

        // endregion

        if (value <= 0) {
          errors.transaction_value = TRANSACTION_VALUE_NOT_SELECTED
        } else {
          if (transaction === 'sell') {
            if (item.transaction_type === TRANSACTION_INSTR.qty && item.data.quantity < value) {
              errors.transaction_value = `Maximale Menge: ${item.data.quantity}`;
            } else if (item.data.market_value < value) {
              errors.transaction_value = `Maximaler Betrag: ${item.data.market_value} €`;
            }
          } else if ([TRANSACTION_TYPE_VALUES.BUY, TRANSACTION_TYPE_VALUES.SAVINGS_PLAN, TRANSACTION_TYPE_VALUES.PAYOUT_PLAN].includes(transaction)) {
            if (!isPaymentPlan || item.action !== 'delete') { // do not validate amount for "delete" Saving plan
              const banksMinAmount = getBankMinAmount(portfolio, transaction);
              let minOrderAmount = getAssetMinOrderAmount(item, transaction);
              minOrderAmount = _.max([banksMinAmount, minOrderAmount]);
              validateTransactionAmount(errors,  getAmount(item, value), minOrderAmount);
            }
          } else if ([TRANSACTION_TYPE_VALUES.SWITCH, TRANSACTION_TYPE_VALUES.SWITCH_PLAN].includes(transaction)) {

            //  Validate available amount of quantities only for switch
            if (transaction === TRANSACTION_TYPE_VALUES.SWITCH && item.data.quantity < value) {
              errors.transaction_value = `Sie können nicht über einen grösseren Betrag verfügen als die Position ausweist.`;
            }

            if (transaction === TRANSACTION_TYPE_VALUES.SWITCH_PLAN) {
              const minSwitchOutOrderAmount = portfolio.ordering_switch_plan_out_min_amount_payment || 0;
              validateTransactionAmount(errors, getAmount(item, value), minSwitchOutOrderAmount);
            }

            const banksMinAmount = getBankMinAmount(portfolio, transaction);

            if (!isPaymentPlan || item.action !== 'delete') {
              item.buy.map(buy_item => {
                buy_item.errors = null; // clean errors

                let buy_errors = {};

                let minOrderAmount = getAssetMinOrderAmount(item, transaction);
                minOrderAmount = _.max([banksMinAmount, minOrderAmount]);

                const value = buy_item.transaction_value || 0;
                if (value <= 0) {
                  buy_errors.transaction_value = TRANSACTION_VALUE_NOT_SELECTED
                }

                // Validate min payment amount only for switch plans, as for switch plans we trade into EUR
                if (transaction === TRANSACTION_TYPE_VALUES.SWITCH_PLAN && !portfolio.ordering_switch_plan_in_skip_min_amount_payment_validation) {
                  let transactionValueEuro = _.get(buy_item, 'calculated.transaction_value_euro')
                  if (transactionValueEuro < minOrderAmount) {
                    buy_errors['transaction_value'] = `Mindestbetrag: ${minOrderAmount} €`;
                  }
                }

                if (isAnlageberatung && !isItemRiskScoreConfirmed(buy_item)) {
                  buy_errors.risk_score_explanation = true
                  allRiscScoresConfirmed = false
                }

                if (!_.isEmpty(buy_errors)) {
                  errors.buy_items = 'ERROR';
                  buy_item.errors = buy_errors
                }
              });

              const buyTransactionValue = _.round(
                item.buy.reduce((sum, i) => sum + (i.transaction_value || 0), 0),
                portfolio.maxQtyDecimals || QTY_DECIMALS
              )
              if (buyTransactionValue !== value) {
                errors.buy = SWITCH_SALDO_POSITIVE;
                if (buyTransactionValue > value) {
                  errors.buy = SWITCH_SALDO_NEGATIVE;
                }
              }
            }
          }
        }

        let generalDateValidators = [{
          func: (date) => !date,
          args: [],
          error: FIELD_REQUIRED_MSG
        }, {
          func: isInPast,
          args: [this.minDepositDate],
          error: DATE_IN_PAST_ERROR
        }];

        // validate deposit date
        if (item.deposit_from_type === TRANSACTION_START_DATE.date) {

          let depositFromDateValidators = [...generalDateValidators, {
            func: isWeekend,
            args: [],
            error: WEEKENDS_IS_NOT_AVAILABLE_MSG
          }];

          let errorMessage = validateDate(item.deposit_from_date, depositFromDateValidators)
          if (!!errorMessage) {
            errors.deposit_from_date = errorMessage
          }
        }

        if (isPaymentPlan) {

          let availableExecutionDaysKey = {
            [TRANSACTION_TYPE_VALUES.SAVINGS_PLAN]: portfolio.isModelportfolio
              ? 'availableMPSavingsPlanExecutionDates'
              : 'availableSavingsPlanExecutionDates',
            [TRANSACTION_TYPE_VALUES.PAYOUT_PLAN]: portfolio.isModelportfolio
              ? 'availableMPPayoutPlanExecutionDates'
              : 'availablePayoutPlanExecutionDates',
            [TRANSACTION_TYPE_VALUES.SWITCH_PLAN]: portfolio.isModelportfolio
              ? 'availableMPSwitchPlanExecutionDates'
              : 'availableSwitchPlanExecutionDates'
          }[transaction]

          const getPaymentPlanDateAvailableValidator = (withMaxDate=true) => ({
            func: isNotAvailable,
            args: [portfolio[availableExecutionDaysKey].map(option => option.value),
              withMaxDate && getPaymentPlanMaxStartDate(item, portfolio, item.action)],
            error: 'Bitte wählen Sie ein gültiges Datum für den Depotbank-Sparplan'
          })

          const dateAvailabilityValidationRequired = ['create', 'edit'].includes(item.action)
            && portfolio.hasOwnProperty(availableExecutionDaysKey)
            && !_.isEmpty(portfolio[availableExecutionDaysKey])

          // validate rotation
          if (item.action != 'delete' && !item.rotation) {
            errors.rotation = FIELD_REQUIRED_MSG
          }

          // validate from date
          let fromDateValidators = [...generalDateValidators]
          if (dateAvailabilityValidationRequired) {
            fromDateValidators.push(getPaymentPlanDateAvailableValidator())
          }

          let errorMessage = validateDate(item.from_date, fromDateValidators)
          if (!!errorMessage) {
            errors.from_date = errorMessage
          }

          if (item.till_date) {
            let tillDateValidators = [{
              func: isLessThen,
              args: [item.from_date],
              error: END_DATE_SHOULD_BE_AFTER_START
            }]
            if (dateAvailabilityValidationRequired) {
              tillDateValidators.push(getPaymentPlanDateAvailableValidator(false))
            }

            errorMessage = validateDate(item.till_date, tillDateValidators)
            if (!!errorMessage) {
              errors.till_date = errorMessage
            }
          }
          // if no errors but "edit" sparplan amount / rotation
          if (_.isEmpty(errors) && item.action === 'edit' && !item.is_changed) {
            errors.no_changes = 'Bitte ändern Sie einen Eintrag um weiter zu gehen';
          }
        }

        if (transaction !== TRANSACTION_TYPE_VALUES.PAYOUT_PLAN  && isAnlageberatung && !isItemRiskScoreConfirmed(item)) {
          errors.risk_score_explanation = true
          allRiscScoresConfirmed = false
        }

        if (!_.isEmpty(errors)) {
          hasProcessErrors = true;
          hasAnyErrors = true;
          portfolioTransactionValid = false;
          item.errors = {...item.errors, ...errors};
          if (isPaymentPlan) {
            // set "ERROR" to open collapse
            portfolio[processErrorKey] = {...portfolio[processErrorKey], [item.action]: 'Error'}
          }
        }
      });
    }

    if (nonFunds.length) {
      nonFunds.forEach(item => {
        hasProcessTransactions = true;
        portfolio[portfolioHasProcessTransactionsKey] = true
        hasAnyTransactions = true;
        const orderType = item.order_type || null;
        const sellAll = item.sell_all;
        const limit = item.limit || 0;
        const quantity = item.quantity || 0;
        const validUntil = item.valid_until || null;
        const stockExchange = item.stock_exchange || null;
        item.errors = null;
        let errors = {};

        if (!orderType) {
          errors.order_type = FIELD_REQUIRED_MESSAGE
        }

        if (item.order_type !== 'no order' && !limit) {
          errors.limit = FIELD_REQUIRED_MESSAGE
        }

        validateEinzeltitelLimit(item, errors,
          (i) => _.get(i, 'data.calculated.last_price_value'))

        if (!quantity && !sellAll) {
          errors.quantity = FIELD_REQUIRED_MESSAGE
        }

        if (!validUntil) {
          errors.valid_until = FIELD_REQUIRED_MESSAGE
        }

        if (!stockExchange) {
          errors.stock_exchange = FIELD_REQUIRED_MESSAGE
        }

        if (isAnlageberatung && !isItemRiskScoreConfirmed(item)) {
          errors.risk_score_explanation = true
          allRiscScoresConfirmed = false
        }

        if (!_.isEmpty(errors)) {
          hasProcessErrors = true;
          hasAnyErrors = true;
          portfolioTransactionValid = false;
          item.errors = {...item.errors, ...errors};
          if (isPaymentPlan) {
            // set "ERROR" to open collapse
            portfolio.errors = {...portfolio.errors, [item.action]: 'Error'}
          }
        }
      });
    }

    if(!portfolioTransactionValid){
      portfolio[processErrorKey] = {...portfolio[processErrorKey], [transaction]: 'Error'}
    }
  })

    const averageInstrumentsSRRI = calculatePortfolioInstrumentsAverageSrri(
      portfolio, portfolio.data.hasOwnProperty('base_components') ? 'base_components' : 'components')
    if (processHasDisabledInstruments){
      portfolio[processErrorKey] = {
        ...portfolio[processErrorKey],
        portfolio: 'Die gewählten Instrumente können nicht verwendet werden'
      }
    } else if(!hasProcessTransactions){
      portfolio[processErrorKey] = {
        ...portfolio[processErrorKey],
        portfolio: 'Bitte legen Sie mindestens eine Position an um weiter zu gehen',
      }
    } else if (!portfolio.depotType) {
      portfolio[processErrorKey] = {
        ...portfolio[processErrorKey],
        portfolio: 'Bitte wählen Sie eine Vertragsart'
      }
    } else if (portfolio.isModelportfolio && !portfolio.modelPortfolioTransactionType) {
      portfolio[processErrorKey] = {
        ...portfolio[processErrorKey],
        portfolio: 'Sie müssen eine Anlagevariante für das gewählte Portfolio angeben.'
      }
    } else if (process === 'trading' && isAnlageberatung && averageInstrumentsSRRI > dataService.maxRiskThreshold && !portfolio.data.isPrivateInvestment) {
      portfolio[processErrorKey] = {
        ...portfolio[processErrorKey],
        portfolio: `Dieses Ordervolumen ist nicht zulässig, da infolge das Portfoliorisiko über der zulässigen Kunden Risikoschwelle liegen würde. Bitte ändern sie den Betrag. Portfoliorisiko incl. Order: ${toGermanFormat(averageInstrumentsSRRI)}`
      }
    } else if (isAnlageberatung && !allRiscScoresConfirmed) {
      portfolio[processErrorKey] = {
        ...portfolio[processErrorKey],
        portfolio: portfolio.data.isPrivateInvestment
          ? 'Bitte erfassen Sie eine Begründung für die Anlage in einer Vermögensverwaltung mit einer höheren Risikoklasse als die des Kunden, damit Sie fortfahren können.'
          : 'Bitte erfassen Sie eine Begründung für die Order eines Produktes mit einer höheren Risikoklasse als die des Kunden, damit Sie fortfahren können.'
      }
    }
  })

  const portfoliosWithTransactionsValid = (process) => {
    // if there is no transactions inside portfolio - ignore errors
    if (!portfolio[getPortfolioProcessHasTransactionsKey(process)]) {
      portfolio[getPortfolioProcessErrorsKey(process)] = null
      return true
    }

    return !portfolio[getPortfolioProcessErrorsKey(process)]
  }

  return {
    isValid: _.every(Object.keys(TRADING_OBJECT_TYPE_TRANSACTIONS), portfoliosWithTransactionsValid) && hasAnyTransactions && !hasAnyErrors,
    hasTransactions: hasAnyTransactions
  }

}


// TODO: after all we need a mapping to TRANSACTIONS_TYPES
//  in bca_investing_platform_frontend/src/containers/TransactionsOverview/constants.js


export const TRANSACTION_TYPE_VALUES = {
  BUY: 'buy',
  SELL: 'sell',
  SWITCH: 'switch',
  SAVINGS_PLAN: 'savings_plan',
  PAYOUT_PLAN: 'payout_plan',
  SWITCH_PLAN: 'switch_plan'
}

/**
 * Describe what field name should be used
 * to retrieve list of available ordering field names
 * from portfolio for specific transaction type.
 *
 * @type {{[p: string]: string}}
 */
export const ORDERING_AVAILABLE_OPTIONS_FIELD_NAMES = {
  [TRANSACTION_TYPE_VALUES.BUY]: 'availableOrderingBuyFields',
  [TRANSACTION_TYPE_VALUES.SELL]: 'availableOrderingSellFields',
  [TRANSACTION_TYPE_VALUES.SWITCH]: 'availableOrderingSwitchFields',
  [TRANSACTION_TYPE_VALUES.SAVINGS_PLAN]: '' // TODO: Check configuration for savings plans
}

/**
 * Describe what field name should be used
 * to retrieve list of available ordering field names
 * from portfolio for specific transaction type for Model Portfolios.
 *
 * @type {{[p: string]: string}}
 */
export const ORDERING_MP_AVAILABLE_OPTIONS_FIELD_NAMES = {
  [TRANSACTION_TYPE_VALUES.BUY]: 'availableOrderingMPBuyFields',
  [TRANSACTION_TYPE_VALUES.SELL]: 'availableOrderingMPSellFields',
  [TRANSACTION_TYPE_VALUES.SWITCH]: 'availableOrderingMPSwitchFields',
  [TRANSACTION_TYPE_VALUES.SAVINGS_PLAN]: '' // TODO: Check configuration for savings plans
}

export const TRANSACTION_TYPE = {
  buy: 'Kaufen',
  sell: 'Verkaufen',
  switch: 'Tauschen',
  rebalancing: 'Rebalancing',
  portfolio_builder: 'Portfolio-Builder',
  savings_plan: 'Sparplan',
  payout_plan: 'Entnahmeplan',
  switch_plan: 'Tauschplan'
}

export const TRANSACTION_TYPE_INTEGER = {
  BUY: 1,
  SELL: 2,
  SWITCH: 3,
}

export const MODEL_PORTFOLIO_TRANSACTION_TYPE = {
  PORTFOLIO: 1,
  BASE_FUND: 2
}

export const MODEL_PORTFOLIO_TRANSACTION_TYPE_OPTIONS = [
  {
    id: MODEL_PORTFOLIO_TRANSACTION_TYPE.PORTFOLIO,
    value: MODEL_PORTFOLIO_TRANSACTION_TYPE.PORTFOLIO,
    label: 'Portfolio'
  }, {
    id: MODEL_PORTFOLIO_TRANSACTION_TYPE.BASE_FUND,
    value: MODEL_PORTFOLIO_TRANSACTION_TYPE.BASE_FUND,
    label: 'Basisfonds'
  }
]

const getModelPortfolioTransactionTypeLabel = (tradingType, label, withActionName=true) => {
  let labelWords = [];

  switch (tradingType) {
    case TRANSACTION_TYPE_VALUES.BUY:
    case TRANSACTION_TYPE_INTEGER.BUY:
      if (withActionName) labelWords.push('Kauf');
      labelWords.push('in');
      break;
    case TRANSACTION_TYPE_VALUES.SELL:
    case TRANSACTION_TYPE_INTEGER.SELL:
      if (withActionName) labelWords.push('Verkauf');
      labelWords.push('aus');
      break;
  }

  labelWords.push(label);

  return _.join(labelWords, ' ');
};

export const getModelPortfolioTransactionTypeOptions = (tradingType) => {
  return MODEL_PORTFOLIO_TRANSACTION_TYPE_OPTIONS.map((option) => ({
    ...option,
    label: getModelPortfolioTransactionTypeLabel(tradingType, option.label)
  }))
};

export const getModelPortfolioTransactionTypeName = (tradingType, mpTransactionType) => {
  const option = MODEL_PORTFOLIO_TRANSACTION_TYPE_OPTIONS.find(o => o.id === mpTransactionType);
  if(option){
    return getModelPortfolioTransactionTypeLabel(tradingType, option.label, false)
  }
};

export const TRANSACTION_INSTR = {
  amount: '1',  // radio values should be strings
  qty: '2'
};

export const TRANSACTION_INSTR_OPTIONS = [
  {id: TRANSACTION_INSTR.amount, value: TRANSACTION_INSTR.amount, label: 'EUR'},
  {id: TRANSACTION_INSTR.qty, value: TRANSACTION_INSTR.qty, label: 'Anteil'}
];

export const TRANSACTION_TYPE_SWITCH_OUT = 'switch_out';
export const TRANSACTION_TYPE_SWITCH_IN = 'switch_in';

export const TRANSACTION_TYPE_ORDER_TYPE_MAPPING = {
   [TRANSACTION_TYPE_VALUES.BUY]: {
       [TRANSACTION_INSTR.amount]: ORDER_TYPES.BUY_VALUE,
       [TRANSACTION_INSTR.qty]: ORDER_TYPES.BUY_QUANTITY
   },
  // savings plan has same order type as buy
  [TRANSACTION_TYPE_VALUES.SAVINGS_PLAN]: {
       [TRANSACTION_INSTR.amount]: ORDER_TYPES.BUY_VALUE,
       [TRANSACTION_INSTR.qty]: ORDER_TYPES.BUY_QUANTITY
   },
  [TRANSACTION_TYPE_VALUES.SELL]: {
       [TRANSACTION_INSTR.amount]: ORDER_TYPES.SELL_VALUE,
       [TRANSACTION_INSTR.qty]: ORDER_TYPES.SELL_QUANTITY
  },
  [TRANSACTION_TYPE_SWITCH_OUT]: {
    [TRANSACTION_INSTR.amount]: ORDER_TYPES.SWITCH_OUT,
    [TRANSACTION_INSTR.qty]: ORDER_TYPES.SWITCH_OUT
  },
  [TRANSACTION_TYPE_SWITCH_IN]: {
    [TRANSACTION_INSTR.amount]: ORDER_TYPES.SWITCH_IN,
    [TRANSACTION_INSTR.qty]: ORDER_TYPES.SWITCH_IN,
  }
};

/**
 * Describe the mapping between transaction type
 * and equivalent from CIOS response.
 *
 * @type {{[p: string]: string}}
 */
export const TRANSACTION_INSTR_ORDERING_FIELD_NAMES = {
  [TRANSACTION_INSTR.amount]: 'amount_eur',
  [TRANSACTION_INSTR.qty]: 'quantity',
  is_all: 'is_all'
}

export const SERVICE_CONCEPTS = {
  Anlageberatung: 1,
  Anlagevermittlung: 2,
  ExecutionOnly: 3
};

export const SERVICE_CONCEPTS_NAMES = {
  [SERVICE_CONCEPTS.Anlageberatung]: 'Anlageberatung',
  [SERVICE_CONCEPTS.Anlagevermittlung]: 'Anlagevermittlung',
  [SERVICE_CONCEPTS.ExecutionOnly]: 'Execution Only'
};

export const TRANSACTION_START_DATE = {
  rightAway: '1',  // radio values should be strings
  date: '2'
};

export const TRANSACTION_START_DATE_OPTIONS = [
  {id: TRANSACTION_START_DATE.rightAway, value: TRANSACTION_START_DATE.rightAway, label: 'Sofort'},
  {id: TRANSACTION_START_DATE.date, value: TRANSACTION_START_DATE.date, label: 'Am'}
];

// TODO: Replace values with German equivalents
export const SAVING_PLAN_TRANSACTION_TYPE = {
  create: 'Einrichten',
  edit: 'Ändern',
  delete: 'Beenden'
};

export const SAVING_PLAN_TRANSACTION_TYPE_NAMES = {
  create: "Sparplan hinzufügen",
  edit: "Sparplan ändern",
  delete: "Sparplan entfernen",
};

export const PAYOUT_PLAN_TRANSACTION_TYPE_NAMES = {
  create: "Entnahmeplan hinzufügen",
  edit: "Entnahmeplan ändern",
  delete: "Entnahmeplan entfernen",
};

export const SWITCH_PLAN_TRANSACTION_TYPE_NAMES = {
  create: "Tauschplan hinzufügen",
  edit: "Tauschplan ändern",
  delete: "Tauschplan entfernen",
};

export const DEPOT_TYPE_USAGE_AREA = {
  ONBOARDING: '0',
  TRADING: '1'
}

export const TRADING_OBJECT_TYPE_TITLES = {
  [OBJECT_TYPES.TRADING]: 'Kauf/ Verkauf/ Tausch',
  [OBJECT_TYPES.SAVINGS_PLAN]: 'Sparplan',
  [OBJECT_TYPES.PAYOUT_PLAN]: 'Entnahmmeplan',
  [OBJECT_TYPES.SWITCH_PLAN]: 'Tauschplan',
}

export const TRADING_OBJECT_TYPE_TRANSACTIONS = {
  trading: [TRANSACTION_TYPE_VALUES.BUY, TRANSACTION_TYPE_VALUES.SELL, TRANSACTION_TYPE_VALUES.SWITCH],
  savings_plan: [TRANSACTION_TYPE_VALUES.SAVINGS_PLAN],
  payout_plan: [TRANSACTION_TYPE_VALUES.PAYOUT_PLAN],
  switch_plan: [TRANSACTION_TYPE_VALUES.SWITCH_PLAN]
}
