const styles = (theme) => ({
  container: {
    background: 'white',
  },
  headerWrapper: {
    marginBottom: 4,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16
  },
  message: {

  }
});

export default styles;