import React from 'react';

/* BCA modules*/
import useStyles from './styles';
import connect from "react-redux/es/connect/connect";
import {getImprintLink, getPrivacyPolicyLink} from "../../utils/constants";

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
  customerThemeProvider: state.get('customerThemeProvider').toJS()
});

const Footer = (props) => {
  const classes  = useStyles();

  const {
    auth: {
      user
    },
    customerThemeProvider
  } = props;

  const is_broker = user.is_broker;
  const agency_id = user && user.agency && user.agency.agency_id;

  const renderAgencyInfo = () => {
    if (customerThemeProvider.agency_data) {
      const company = customerThemeProvider.agency_data.companyName;
      const broker = `${customerThemeProvider.agency_data.firstName || ''} ${customerThemeProvider.agency_data.lastNme || ''}`.trim();
      const phone = customerThemeProvider.agency_data.phoneNumber;
      const email = customerThemeProvider.agency_data.email;
      const agencyName = company || broker;

      return (
        <div className={classes.agencyInfo}>
          {[agencyName, phone, email].filter(item => item).map((item, index) => {
            return (
              <div className={classes.agencyInfoItem} key={index}>
                {item}
              </div>
            )
          })}
        </div>
      )
    }
  }

  return (
    <nav className={classes.footer}>
      <ul>
        <li>
          <a href={getImprintLink(is_broker, agency_id)} target="_blank">Impressum</a>
          <a href={getPrivacyPolicyLink(is_broker, agency_id)} target="_blank">Datenschutz</a>
        </li>
        <li className={classes.textMuted}>
          © {new Date().getFullYear()} - BCA AG
        </li>
      </ul>

      {!is_broker && renderAgencyInfo()}
    </nav>
  )
};

export default connect(mapStateToProps)(Footer);
