import React from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import { Grid } from '@material-ui/core'

import {
  Widget,
  WidgetActions,
  WidgetBody,
  WidgetHeader,
} from '../../../../Modelportfolios/components'
import DashboardTable from '../../../../../components/DashboardTable'

import useStyles from './styles'
import theme from '../../../../../themes/mainTheme'
import {favoriteListTableStructure} from '../../../../Modelportfolios/components/InstrumentsList/table-data'
import { ConfigIcon } from '../../../../../images'

function InstrumenstList({instruments, onRemoveAssetClick, modelPortfolio, loading}) {
  
  const classes = useStyles();

  const handleRemoveAssetClick = (asset) => {
    if (onRemoveAssetClick) {
      onRemoveAssetClick(asset)
    }
  }
  
  return (
    <Widget>
      <WidgetBody>
        <DashboardTable
          structure={favoriteListTableStructure}
          dataSource={instruments || []}
          expanded={!loading}
          loadingIndicator={loading}
          tableClasses={classes}
          withFooter={false}
          options={{
            onRemoveAssetClick: handleRemoveAssetClick,
            itemsLength: instruments && instruments.length,
            loading
          }}
        />
      </WidgetBody>
    </Widget>
  )
}

InstrumenstList.propTypes = {
  /** List of instruments */
  instruments: PropTypes.arrayOf(PropTypes.shape({})),

  /** Asset remove event handler */
  onRemoveAssetClick: PropTypes.func
}

export default InstrumenstList

