import React from "react";
import { Grid, withStyles } from "@material-ui/core";

import MonthlyPerformanceTable from "./items/MonthlyPerformanceTable";
import PerformanceChart from "../../components/PerformanceChart";
import PerformanceTable from "./items/PerformanceTable";
import PerformanceBarChart from "./items/PerformanceBarChart";
import styles from "./styles";
import DividendsBarChart from "./items/DividendsBarChart";
import {PORTFOLIO_LEVEL_DATA_ONLY_INFO_TEXT} from "../../../../components/WarningTooltip";
import ExplanationTooltip from "../../../../components/ExplanationTooltip";


class PerformanceTab extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return <Grid container spacing={2}>
      <Grid item xs={12}>
        <PerformanceChart
          assetInfo={this.props.data}
          startDateCallback={this.props.callback}
          useDividends
        />
      </Grid>
      <Grid item xs={12}>
        <DividendsBarChart
          data={this.props.data.dividend_history}
          currency={this.props.data.currency_code}
        />
      </Grid>
      <Grid item xs={4}>
        <PerformanceTable
          title={(
            <>
              <span>Wertentwicklung in %</span>
              <ExplanationTooltip
                tooltipKey={'performance_table'}
                customClasses={{helpIcon: this.props.classes.helpIconMiddle}}
              />
            </>
          )}
          data={this.props.data}
        />
      </Grid>
      <Grid item xs={8}>
        <PerformanceBarChart
          data={this.props.data.performance_history.years}
          endDate={this.props.data.performance_history.years_last_date}
          height={360}
          cardClass={this.props.classes.perfBarChartRoot}
        />
      </Grid>
      <Grid item xs={12}>
        <MonthlyPerformanceTable
          title={(
            <>
              <span>Wertentwicklung in %</span>
              <ExplanationTooltip
                tooltipKey={'performance_table'}
                customClasses={{helpIcon: this.props.classes.helpIconMiddle}}
              />
            </>
          )}
          data={this.props.data.performance_history.months}
          endDate={this.props.data.performance_history.months_last_date}
        />
      </Grid>
    </Grid>
  }
}

export default withStyles(styles)(PerformanceTab);
