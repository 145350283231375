const CLEARING_ACCOUNT_NUMBER_TYPE_ID = 3

export const getClearingAccountName = clearingAccount => {
  let name = clearingAccount.name

  let identifiers = clearingAccount.identifiers

  if (identifiers && identifiers.length) {
    for (let index = 0; index < identifiers.length; index++) {
      let type = identifiers[index].type

      if (type && type.type_id == CLEARING_ACCOUNT_NUMBER_TYPE_ID) {
        name = identifiers[index].code
        break
      } 

    }
  }

  return name
}