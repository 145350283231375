import React from 'react';

import useStyles from './styles';

function FieldTitle({children}) {
  const classes = useStyles();

  return (
    <p className={classes.title}>
      {children}
    </p>
  )
}

export default FieldTitle;