import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  portfolioListItem: {
    '& span': {
      fontFamily: 'Roboto-Regular',
      color: '#4D4F5C',
      fontSize: 16
    }
  },
  portfolioListCheckbox: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  portfolioListCheckboxChecked: {
    '& svg': {
      fill: `${theme.palette.primary.main} !important`
    }
  },
})

export default makeStyles(styles)