import React from 'react';
import {CircularProgress, Grid} from "@material-ui/core";
import {ChartSectionBordered} from "../ChartSection/ChartSection";
import FondsKenzahlenTable, {
  ProductNameColumn
} from "../FondsKenzahlenTable/FondsKenzahlenTable";
import {commonStyles} from "../../styles";
import clsx from "clsx";
import WarningTooltip from "../../../../components/WarningTooltip";
import useSustainabilityMetricsData from "../../hooks/useSustainabilityMetricsData";
import _ from 'lodash';
import withNotification from "../../../../components/NotificationProvider";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../CustomerDashboard/constants";
import {sustainabilityMetricsMainTableStyles, sustainabilityMetricsTableStyles} from "./styles";
import SustainabilityCellContent from './componenets/SustainabilityCellContent';


const getDynamicColumn = (title, columnKey, tableKey, fieldType) => {

  return {
    header: {
      content: title,
    },
    body: {
      content: (product) => (
        <SustainabilityCellContent
          product={product}
          columnKey={columnKey}
          tableKey={tableKey}
          fieldType={fieldType}
        />
      )
    },
  }
}

function SustainabilityMetricsTab(props) {

  const commonClasses = commonStyles();
  const customTableClasses = sustainabilityMetricsTableStyles();
  const mainTableClasses = sustainabilityMetricsMainTableStyles();

  const [expandedSections, setExpandedSections] = React.useState({...props.expandedSectionsRef.current.sustainabilityMetricsTab});
  React.useEffect(() => {
    setExpandedSections({...props.expandedSectionsRef.current.sustainabilityMetricsTab})
  },[props.expandedSectionsRef.current])

  const onExpanded = (sectionKey) => () => {
    setExpandedSections((current) => {
      let newState = !current[sectionKey]
      props.expandedSectionsRef.current.sustainabilityMetricsTab[sectionKey] = newState
      return {
        ...current,
        [sectionKey]: newState
      }
    })
  }

  const [sustainabilityMetricsData, tablesStructure] = useSustainabilityMetricsData(props.selectedProducts, props.displayNotification);

  const loading = Object.values(sustainabilityMetricsData).every(product => product.loading)

  const orderedTablesKeys = React.useMemo(() => {
    return _.sortBy(Object.keys(tablesStructure || {}), column => tablesStructure[column].order)
  }, [tablesStructure])

  return (
    <>
      {(loading || _.isEmpty(tablesStructure)) &&
        <div style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>
          {loading && <CircularProgress />}
          {!loading && _.isEmpty(tablesStructure) && DEFAULT_EMPTY_SECTION_MESSAGE}
        </div>
      }

      {orderedTablesKeys.map(key => {
        const columnStructure = tablesStructure[key];
        let orderedColumnsKeys = _.sortBy(Object.keys(columnStructure['subcategories']), subcategoryName => columnStructure['subcategories'][subcategoryName].order)

        // if there is a main category - set it as first column
        let mainCategory = _.get(columnStructure, 'main_category');
        let tableClasses = customTableClasses;
        if (mainCategory) {
          orderedColumnsKeys.unshift(key);
          tableClasses = mainTableClasses;
        }

        // depending on column - structure is build dynamically
        let dynamicTableStructure = {
          default: [
            {content: [ProductNameColumn]},
            ...orderedColumnsKeys.map(columnKey => {
              const pathToColumnStructure = columnKey === key ? 'main_category' : `subcategories.${columnKey}`;
              const title = _.get(columnStructure, `${pathToColumnStructure}.title`);
              const fieldType = _.get(columnStructure, `${pathToColumnStructure}.field_type`);

              return {
                content: [getDynamicColumn(title, columnKey, key, fieldType)],
                align: 'center'
              }
            })
          ]
        };

        return (
          <Grid item xs={12}>
            <ChartSectionBordered
              title={(
                <>
                  <b>{columnStructure.title}</b>
                  {columnStructure.tooltip &&
                    <WarningTooltip
                      // Since no historical data is available for the fund information, it always refers to the current day.
                      title={columnStructure.tooltip}
                      icon={<i className={clsx("far fa-info-circle", commonClasses.chartSectionHeaderInfoIcon)} />}
                      width={600}
                    />
                  }
                </>
              )}
              expanded={expandedSections[key]}
              onExpanded={onExpanded(key)}
              content={
                <>
                  <FondsKenzahlenTable
                    products={_.orderBy(Object.values(sustainabilityMetricsData)|| [], ['order'])}
                    productKeysToCheck={[key]}
                    tableStructure={dynamicTableStructure}
                    tableLayout={"auto"}
                    customClasses={tableClasses}
                    options={{
                      structure: tablesStructure
                    }}
                    showMissingDataInfoCustomCondition={
                      // display info icon if at least one sub category does not have data
                      (columnData) => Object.values(columnData).some(cellData => _.isNil(cellData))
                    }
                  />
                </>
              }
            />
        </Grid>
        )
      })}
    </>
  )
}

export default withNotification(SustainabilityMetricsTab);