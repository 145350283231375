import React, {useContext, useRef, useState} from "react";
import ConfirmationDialog from "../../containers/VirtualPortfolioManager/modals/ConfirmationDialog";

export const ConfirmationModalContext = React.createContext({});

const ConfirmationModalContextProvider = (props) => {
  const [message, setMessage] = useState(null);
  const [title, setTitle] = React.useState(undefined);
  const [confirmButtonText, setConfirmButtonText] = useState(undefined);
  const [rejectButtonText, setRejectButtonText] = useState(undefined)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const resolver = useRef();

  const handleShow = (message, confirmButtonText=undefined, rejectButtonText=undefined, title=undefined) => {
    setMessage(message);
    setConfirmButtonText(confirmButtonText)
    setTitle(title);
    setRejectButtonText(rejectButtonText)
    setShowConfirmationModal(true);

    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const onClose = () => {
    setMessage("");
    setConfirmButtonText(undefined)
    setRejectButtonText(undefined)
    setTitle(undefined);
    setShowConfirmationModal(false);
  };

  const handleOk = () => {
    resolver.current && resolver.current(true);
    setConfirmButtonText(undefined)
    setRejectButtonText(undefined)
    setTitle(undefined);
    onClose();
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    setConfirmButtonText(undefined)
    setRejectButtonText(undefined)
    setTitle(undefined);
    onClose();
  };

  return (
    <ConfirmationModalContext.Provider value={{confirm: handleShow}}>
      {props.children}

      <ConfirmationDialog
        message={message || "Sind Sie sicher?"}
        open={showConfirmationModal}
        onClose={handleCancel}
        confirm={handleOk}
        confirmButtonText={confirmButtonText}
        rejectButtonText={rejectButtonText}
        title={title}
      />

    </ConfirmationModalContext.Provider>
  )
};

export const useConfirmationModalContext = () => useContext(ConfirmationModalContext);
export default ConfirmationModalContextProvider;