/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { LOCATION_CHANGE } from 'react-router-redux';

import globalReducer from './containers/App/reducer';
import authReducer from './containers/Authentication/Auth/reducer';
import customerDashboardReducer, { benchmarkConfigurationReducer } from './containers/CustomerDashboard/reducer';
import {
  guideTourReducer,
  sideMenuReducer, 
  seriesDropdownReducer,
  analyseDropdownReducer
} from './components/Header/reducer';
import {reportingReducer} from "./containers/GroupReporting/reducer";
import {assetModalReducer} from "./components/AssetModal/reducer";
import {createGroupReducer} from "./containers/ReportGroupCreation/reducers";
import {reportSettingsReducer} from "./containers/DashboardSettings/reducers";
import customersDataReducer from './components/CustomersDataProvider/reducer';
import sharedSettingsReducer from './components/SharedSettingsProvider/reducer';
import investmentPlatformReducer from './containers/InvestmentPlatform/reducer';
import snackbarReducer from './components/SnackbarProvider/reducer';
import themeReducer from './components/CustomerThemeProvider/reducer';
import {guideTourSettingsReducer} from "./components/GuideTour/reducer";
import onboardingReducer from './containers/RiskProfiling/reducer';
import productsComparisonDataReducer from './containers/ProductComparison/reducer';
import combinedTradingReducer from './components/TradingStore/reducer'

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@5
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  location: null,
});

/**
 * Merge route into the global application state
 */
export function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        location: action.payload,
      });
    default:
      return state;
  }
}

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    route: routeReducer,
    global: globalReducer,
    auth: authReducer,
    customerDashboard: customerDashboardReducer,
    guide: guideTourReducer,
    sideMenu: sideMenuReducer,
    seriesDropdown: seriesDropdownReducer,
    analyseDropdown: analyseDropdownReducer,
    reporting: reportingReducer,
    benchmarkConfiguration: benchmarkConfigurationReducer,
    assetModal: assetModalReducer,
    createGroup: createGroupReducer,
    reportSettings: reportSettingsReducer,
    sharedSettings: sharedSettingsReducer,
    guideTourSettings: guideTourSettingsReducer,
    customersDataProvider: customersDataReducer,
    customerThemeProvider: themeReducer,
    investmentPlatform: investmentPlatformReducer,
    snackbar: snackbarReducer,
    onboarding: onboardingReducer,
    combinedTrading: combinedTradingReducer,
    productsComparison: productsComparisonDataReducer,
    ...injectedReducers,
  });
}
