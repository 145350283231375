import { makeStyles } from '@material-ui/core';


export default makeStyles(theme => ({
  benchmarkErrorMessage: {
    margin: 0,
    color: '#4d4f5c',
    fontSize: 12,
    fontFamily: 'Roboto-Regular'
  }
}));