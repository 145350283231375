export const isPortfolioImported = (portfolio) => {

  let isImported = true;
  let hasTypeImported = false;

  if (portfolio.hasOwnProperty('identifiers')) {

    portfolio['identifiers'].forEach((identifier) => {
      if (identifier['code'].indexOf('asset_container_id') >= 0) {
        isImported = false;
      }

      if (identifier['type']['type_id'] === 23) {
        hasTypeImported = true;
      }
    })
  }

  return isImported || hasTypeImported;
}


export const isAssetImported = (asset) => {

  let hasTypeImported = false;

  if (asset.hasOwnProperty('identifiers')) {

    asset['identifiers'].forEach((identifier) => {

      if (identifier['type']['type_id'] === 20) {
        hasTypeImported = true;
      }
    })
  }

  return hasTypeImported;
}
