import { makeStyles } from '@material-ui/core';

export const styles = (theme) => ({
  footerContainer: {
    borderTop: `10px solid ${theme.palette.primary.main}`,
    padding: 16,

    [theme.breakpoints.down('sm')]: {
      padding: 8,
    }
  },
  footerContainerContent: {
    margin: '0 auto',
    maxWidth: 1368,
  },
  footerText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#4D4F5C',
    padding: 0,
    margin: 0,

    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 12,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 9,
    }
  },
  footerLinkContainer: {
    justifyContent: 'center',
    '& a': {
      fontFamily: 'Roboto-Bold',
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  footerLinkItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    borderRight: '1px solid #707070',
    padding: '0 16px',

    '&:last-child': {
      borderRight: 'none'
    },

    [theme.breakpoints.down('sm')]: {
      padding: '0 8px',
    }
  }
});

export default makeStyles(styles);