/* Common React imports */
import React from 'react';
import {Link, withRouter} from "react-router-dom";
import styles from "./styles";
import clsx from "clsx";

/* Material UI Components*/
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

/* BCA Components */
import CommonAnalysisTab from "./Tabs/CommonAnalysis";
import PortfolioStructureTab from "./Tabs/PortfolioStructure";
import KeyFiguresTab from "./Tabs/KeyFigures";

/* BCA modules */
import {buildCurrentCustomerDashboardVirtualPath} from "../../routes";
import {VirtualPortfolioHandlerResource} from "../../utils/api";
import RiskAnalysisTab from "./Tabs/RiskAnalysis";
import _ from "lodash";
// TODO CAN BE DELETED if virtual depots layout updated
class VirtualPortfolioPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      portfolioId: props.match.params.portfolio_id,
      selectedTabId: 0,
      customerId: props.auth.user.customer_id,
      dataProvider: VirtualPortfolioHandlerResource,
      portfolioData: null,
      portfolioLoading: false,
      portfolioError: null
    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.fetchPortfolioData()
  }

  handleChange(event, newValue) {
    if (newValue !== undefined) {
      this.setState({
        selectedTabId: newValue
      })
    }
  }

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  fetchPortfolioData() {
    const {customerId, portfolioId} = this.state
    this.setState({
      portfolioLoading: true
    })
    this.state.dataProvider.getBasicPortfolioData(customerId, portfolioId).then(
      (data) => {
        this.setState({
          portfolioData: data,
          portfolioLoading: false
        })
      }
    ).catch(error => this.setState({
      portfolioError: error,
      portfolioLoading: false
    }))
  }

  renderLoading () {
    return <div className={this.props.classes.loadingLine}><p/></div>
  }

  renderError() {
    return <p>Error</p>
  }

  renderPortfolioName() {
    if (this.state.portfolioData) {
      return this.state.portfolioData['portfolio_data']['data'][0]['name']
    } else {
      return ''
    }
  }

  renderPortfolioFinancial() {
    return _.get(this.state.portfolioData, 'portfolio_data.data.0.financial_data.0.data', undefined)
  }

  render() {
    const { classes } = this.props;

    return (
    <React.Fragment>
      <div>
      <Container className={`app-page-container`}>

        <div className={classes.navigation}>
          <Link
            to={buildCurrentCustomerDashboardVirtualPath(this.state.customer_id)}
            className={clsx(classes.link, classes.backwardArrow)}>
            Zurück zur virtuellen Depotübersicht
          </Link>
          <Typography variant="h2">
            {this.state.portfolioLoading ? this.renderLoading() : this.portfolioError ? this.renderError() : this.renderPortfolioName()}
          </Typography>
        </div>
      </Container>
      </div>

      <div className={classes.whiteBox}>
        <Container className={`app-page-container`}>

          <AppBar position="static" className={classes.tabsHeader}>
            <Tabs value={this.state.selectedTabId} onChange={this.handleChange} aria-label="simple tabs example"
                  classes={{indicator: classes.indicator}}
            >
              <Tab label='Wertentwicklung' {...this.a11yProps(0)}
                   className={clsx(classes.tabTitle, this.state.selectedTabId === 0 && classes.tabTitleSelected)}
                   classes={{selected: classes.selected}}
                   disableRipple={true}
              />
              <Tab label='Portfoliostruktur' {...this.a11yProps(1)}
                   className={clsx(classes.tabTitle, this.state.selectedTabId === 0 && classes.tabTitleSelected)}
                   classes={{selected: classes.selected}}
                   disableRipple={true}
              />
              <Tab label='Kennzahlen' {...this.a11yProps(2)}
                   className={clsx(classes.tabTitle, this.state.selectedTabId === 0 &&classes.tabTitleSelected)}
                   classes={{selected: classes.selected}}
                   disableRipple={true}
              />
              <Tab label='Risikoanalyse' {...this.a11yProps(3)}
                   className={clsx(classes.tabTitle, this.state.selectedTabId === 0 && classes.tabTitleSelected)}
                   classes={{selected: classes.selected}}
                   disableRipple={true}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={this.state.selectedTabId} index={0} className={classes.tabContent}>
            <CommonAnalysisTab
                customerId={this.state.customerId}
                portfolioId={this.state.portfolioId}
            />
          </TabPanel>
          <TabPanel value={this.state.selectedTabId} index={1} className={classes.tabContent}>
            <PortfolioStructureTab
                customerId={this.state.customerId}
                portfolioId={this.state.portfolioId}
            />
          </TabPanel>
          <TabPanel value={this.state.selectedTabId} index={2} className={classes.tabContent}>
            <KeyFiguresTab
              customerId={this.state.customerId}
              portfolioId={this.state.portfolioId}
              portfolioName={this.renderPortfolioName()}
              financialData={this.renderPortfolioFinancial()}
            />
          </TabPanel>
          <TabPanel value={this.state.selectedTabId} index={3} className={classes.tabContent}>
            <RiskAnalysisTab
              customerId={this.state.customerId}
              portfolioId={this.state.portfolioId}
              portfolioData={this.state.portfolioData}
              portfolioLoading={this.state.portfolioLoading}
              portfolioError={this.state.portfolioError}
              portfolioName={this.renderPortfolioName()}
            />
          </TabPanel>
        </Container>
      </div>
    </React.Fragment>
  )
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default withStyles(styles)(withRouter(VirtualPortfolioPage));
