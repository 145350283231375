import { makeStyles } from '@material-ui/core';
import { SECTION_PADDING } from '../../../../../constants';

export default makeStyles((theme) => ({
  values: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'self-end',
    whiteSpace: 'nowrap'
  },
  root: {
    '& h2': {
      color: '#4D4F5C',
      overflow: 'hidden',
      fontSize: '20px',
      fontFamily: 'Roboto-Regular',
    }
  },
  item: {
    padding: `6px ${SECTION_PADDING}px`,
    color: '#4D4F5C',
    fontFamily: 'Roboto-Regular',
  },
  titleWithoutData: {
    marginBottom: 50,
  },
  chartSectionHeaderInfoIcon: {
    marginLeft: 15,
    color: theme.palette.primary.main
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    padding: `6px ${SECTION_PADDING}px`,
    '&:not(:last-child)': {
      borderBottom: 'solid 1px #d9dcdf',
    }
  },
  listValues: {
    width: '20%',
    textAlign: 'right'
  },
}));
