export const RISK_FILTERING_TYPE = {
  ALL: {
    value: 0,
    id: 0,
    description: 'Alle Risiko-Vergleiche anzeigen',
    name: 'Alle Risiko-Vergleiche anzeigen',
  },
  RED: {
    value: 3,
    id: 3,
    description: 'Risiko-Vergleiche anzeigen',
    name: 'Risiko-Vergleiche anzeigen',
    color: '#F44638'
  },
  YELLOW: {
    value: 2,
    id: 2,
    description: 'Risiko-Vergleiche anzeigen',
    name: 'Risiko-Vergleiche anzeigen',
    color: '#E3A600'
  },
  GREEN: {
    value: 1,
    id: 1,
    description: 'Risiko-Vergleiche anzeigen',
    name: 'Risiko-Vergleiche anzeigen',
    color: '#5EC14E'
  }
}