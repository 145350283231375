import {makeStyles} from "@material-ui/core";

const styles = (theme) => ({
  accordionRoot: {
    boxShadow: 'none',
    border: 'none',
    borderTop: 'none'
  },
  accordionSummaryExpanded: {
    borderBottom: 'none',
    backgroundColor: '#F7F7F7',
    height: '52px !important',
    minHeight: '52px !important',
    borderLeft: '10px solid #F7F7F7'
  },
  accordionDetailsSkipPadding: {
  },
  status: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0
  },
  documentLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderLeft: '5px solid white'
  },
  statusButton: {
    fontFamily: 'Roboto-Bold',
    border: '2px solid white',
    borderRadius: 5,
    display: 'inline-flex',
    height: 30,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    padding: 0
  },
  documentName: {
    display: 'flex',
    width: '75%',
    justifyContent: 'space-between'
  }
})

export default makeStyles(styles)