import React from "react";

const SelectListIcon = ({color}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31" viewBox="0 0 32 31">
    <g transform="translate(-736.779 -214.085)">
      <path fill={color || "#0092E5"}
            d="M2215.524,634.57a.935.935,0,0,0-.887,1,13.422,13.422,0,0,1-5.141,11.487,13.814,13.814,0,0,1-19.251-2.385,13.539,13.539,0,0,1,2.2-19.111,13.681,13.681,0,0,1,12.471-2.246.947.947,0,0,0,.725-.065.932.932,0,0,0,.46-.554.951.951,0,0,0-.632-1.181,15.576,15.576,0,0,0-14.2,2.56,15.411,15.411,0,0,0-2.506,21.757,15.726,15.726,0,0,0,21.917,2.712,15.288,15.288,0,0,0,5.856-13.082A.958.958,0,0,0,2215.524,634.57Z"
            transform="translate(-1448.599 -406.717)"/>
      <path fill={color || "#0092E5"}
            d="M2215.487,622.642a.964.964,0,0,0-1.359.13l-15.569,18.241-6.852-5.627a.944.944,0,0,0-.7-.235.966.966,0,0,0-.562,1.695l7.563,6.245.05.047a.966.966,0,0,0,1.36-.129l16.2-19.006a.965.965,0,0,0-.131-1.359Z"
            transform="translate(-1447.059 -406.174)"/>
    </g>
  </svg>
)

export default SelectListIcon