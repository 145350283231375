import React from "react";
import clsx from "clsx";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import {formatPercentage, toShortGermanFormat} from "../../utils";
import { processResponse } from "../../index";
import { FactSheetsHandlerResource } from "../../../../utils/api";
import Tooltip from "../../../../components/Tooltip";
import CommonAnalysisCard from "../../../VirtualPortfolioPage/components/AnalysisCard";
import TimeRangesButtonsPad from "../../../../components/TimeRangeButtonsPad";
import styles from "./styles";
import HighChartBase from "../../../../components/Charts/Base";
import _ from "lodash";
import { getInstrName } from '../../../Modelportfolios/utils';
import { DEFAULT_EMPTY_SECTION_MESSAGE } from '../../../CustomerDashboard/constants';

export const MISSING_FIELD = 'k.A.';

export const getFundVolume = (assetInfo) => {
  return assetInfo.fund_size_value !== null && assetInfo.fund_size_reported_currency !== null
    ? toShortGermanFormat(assetInfo.fund_size_value, '', ' ' + assetInfo.fund_size_reported_currency, 2, false)
    : MISSING_FIELD
}

export const getDayChangeValue = (performance, priceCurrency) => {
  return (performance.NAV_change_percentage || performance.NAV_change) ? (
    <>
      {formatPercentage(performance.NAV_change_percentage)} / {performance.NAV_change ? toShortGermanFormat(performance.NAV_change, '', ' ' + priceCurrency) : MISSING_FIELD}
    </>
  ) : MISSING_FIELD
}

class PerformanceChart extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dataProvider: FactSheetsHandlerResource,

      dateStart: undefined,
      dateEnd: undefined,
      periodTitle: undefined,

      performanceTimeseriesData: null,
      performanceTimeseriesLoading: true,
      performanceTimeseriesError: null,

      latestData: null,
      latestLoading: true,
      latestError: null,

      performanceBenchmarkData: null,
      performanceBenchmarkLoading: true,
      performanceBenchmarkError: null,

      currentDay: null, previousDay: null,
      daysDiffPerc: 0, daysDiffAmount: 0,

      dividendHist: {}
    }

    this.onRangeChange = this.onRangeChange.bind(this);
    this.fetchTimeseries = this.fetchTimeseries.bind(this);
    this.reloadData = this.reloadData.bind(this);
    this.renderCondition = this.renderCondition.bind(this);
    this.renderDetailsTable = this.renderDetailsTable.bind(this);

    this.defaultChartColor = '#0596CE'
    this.defaultBenchmarkColor = '#7ED2CF'

    this.inceptionDate = this.props.assetInfo.performance_inception_date || this.props.assetInfo.inception_date;
    this.useDividends = this.props.useDividends && this.props.assetInfo.dividend_history
  }

  fillDividends() {
    if(this.useDividends) {
      let dividendHist = {};
      let counter = 1;
      Object.values(this.props.assetInfo.dividend_history).forEach(yearlyData => {
        yearlyData.forEach(dividend => {
          let dateBetween = moment(dividend.excluding_date).isBetween(this.state.dateStart, this.state.dateEnd)
          if(dateBetween) {
            let newDividend = {values: [parseFloat(dividend.value)], index: counter}
            if(!dividendHist[dividend.excluding_date]){
                dividendHist[dividend.excluding_date] = newDividend;
                counter++;
            }
            else {
              dividendHist[dividend.excluding_date].values.push(parseFloat(dividend.value))
            }
          }
        })
      })
      this.setState({
        dividendHist: dividendHist
      });
    }
  }

  fetchTimeseries() {
    let investmentStrategyId = _.get(this.props.investmentStrategy, 'id');
    processResponse(
      this.state.dataProvider.getPerformanceData(this.props.assetInfo.isin, this.state.dateStart, this.state.dateEnd, investmentStrategyId),
      'performance_timeseries', 'performanceTimeseries', this
    )
    processResponse(
      this.state.dataProvider.getPerformanceBenchmarkData(this.props.assetInfo.isin, this.state.dateStart, this.state.dateEnd),
      'performance_benchmark', 'performanceBenchmark', this
    )
    this.fillDividends()
  }

  reloadData() {
    this.setState({
      performanceTimeseriesLoading: true,
      performanceBenchmarkLoading: true,
    }, this.fetchTimeseries)
  }

  onRangeChange(dateStart, dateEnd, title) {
    if(this.props.startDateCallback) this.props.startDateCallback({'date': dateStart, 'title': title})
    this.setState({
      dateStart: dateStart,
      dateEnd: dateEnd,
      periodTitle: title,
    }, this.reloadData)
  }

  renderBenchmarkToolTip() {
    return <ul style={{ margin: 0, paddingLeft: '1rem' }}>
      <li>
        <span><b>100%</b>&nbsp;Bencmark instrument name</span>
      </li>
    </ul>
  }

  timePeriods() {
    let dates = {
      MONTHS_3: {
        number: 3,
        unit: 'month',
        title: '3 Monate'
      },
      MONTHS_6: {
        number: 6,
        unit: 'month',
        title: '6 Monate'
      },
      YTD: {
        start: moment().startOf('year'),
        custom: true,
        title: 'YTD'
      },
      YEARS_1: {
        number: 1,
        unit: 'year',
        title: '1 Jahr'
      },
      YEARS_3: {
        number: 3,
        unit: 'year',
        title: '3 Jahre'
      },
      YEARS_5: {
        number: 5,
        unit: 'year',
        title: '5 Jahre'
      }
    }
    if(this.inceptionDate) {
      dates['INCEPTION'] = {
        start: moment(this.inceptionDate),
        custom: true,
        title: 'Max'
      }
    }
    return dates
  }

  renderDescription() {
    if(this.state.periodTitle !== 'INCEPTION') return '';
    let dateFormatted = moment(this.props.assetInfo.inception_date).format('DD.MM.YYYY')
    if(this.props.assetInfo.performance_inception_date)
      return <>Diese Anteilsklasse verfügt über Performancedaten, die vor dem Auflegungsdatum,
              dem {dateFormatted}, berechnet wurden. Dies basiert auf einer simulierten/erweiterten
              Erfolgsbilanz unter Verwendung der Erfolgsbilanz des Ursprungsfonds.</>

    return <>Daten ab: {dateFormatted}</>
  }

  renderDetailsTable() {

    const { classes, assetInfo } = this.props;

    const performance = assetInfo.performance || {};

    const priceDate = performance.price_date ?
        moment(performance.price_date).format('DD.MM.YYYY') : MISSING_FIELD;

    const priceCurrency = performance.price_currency;

    return <div className={classes.chartDataContainer}>
      <ul className={classes.list}>
        <li className={classes.item}>
          <span className={classes.title}>ISIN</span>
          <span className={classes.values}>{assetInfo.isin}</span>
        </li>
        <li className={classes.item}>
          <span className={classes.title}>WKN</span>
          <span className={classes.values}>{assetInfo.wkn || MISSING_FIELD}</span>
        </li>
        <li className={classes.item}>
          <span className={classes.title}>Fondswährung</span>
          <span className={classes.values}>{assetInfo.currency_code}</span>
        </li>
        <li className={classes.item}>
          <span className={classes.title}>Kurs (Stand vom {priceDate})</span>
          <span className={classes.values}>
            {performance.NAV ? toShortGermanFormat(performance.NAV, '', ' ' + priceCurrency) : MISSING_FIELD}
          </span>
        </li>
        <li className={classes.item}>
          <span className={classes.title}>Veränderung zum Vortag in %{priceCurrency && <>/{priceCurrency}</>}
          </span>
          <span className={classes.values}>
            {getDayChangeValue(performance, priceCurrency)}
          </span>
        </li>
        <li className={classes.item}>
          <span className={classes.title}>Fondsvolumen</span>
          <span className={classes.values}>
            { getFundVolume(assetInfo) }
          </span>
        </li>
      </ul>
    </div>
  }

  renderChart() {
    let self = this;

    const { classes, detailsTable, assetInfo } = self.props;

    const data = self.state.performanceTimeseriesData['timeseries'];
    const benchmarkData = self.state.performanceBenchmarkData['timeseries'] || [];

    const timeSeries = data.map(value => {

        let dividend = this.state.dividendHist[value.date];
        if(dividend) {
          return {
            y: (value.cum_ret_g || 0) * 100,
            x: Date.parse(value.date),
            dividend: {
              summary: dividend.values.reduce((s, a) => s + a, 0)
            },
            marker: {
              enabled: this.useDividends,
              radius: 4,
              // fillColor: '#FFFFFF',
              // lineWidth: 2,
              // lineColor: self.defaultChartColor
            },
            dataLabels: {
              // y: 11,
              // x: -1,
              enabled: this.useDividends,
              formatter: function () {
                return dividend.index
              }
            }
          }
        }
        return [Date.parse(value.date), (value.cum_ret_g || 0) * 100 ]
    });
    const benchmark = benchmarkData.map(value => ([Date.parse(value.date), (value.cum_ret_g || 0) * 100 ]));

    return <div className={classes.componentContainer}>
      <div className={classes.chartContainer}>
        <ul className={classes.legend}>
          <li className={classes.legendItem}>
            <div className={classes.bullet} style={{ background: self.defaultChartColor }} />
            <label>{getInstrName(self.props.assetInfo)}</label>
          </li>
          {
            benchmarkData.length > 0 && (
              <li className={clsx(classes.legendItem)}>
                <div className={classes.bullet} style={{
                  background: self.defaultBenchmarkColor
                }} />
                <label>Benchmark</label>
                <Tooltip
                  placement={'left'}
                  title={
                    <div>
                      {this.renderBenchmarkToolTip()}
                    </div>
                  }
                >
                  <ErrorIcon className={clsx(classes.helpIcon)} />
                </Tooltip>
              </li>
            )
          }
        </ul>
        <HighChartBase language={'DE'}
          options={ {
            series: [
              {
                type: 'line',
                name: 'Performance',
                color: self.defaultChartColor,
                data: timeSeries,
                threshold: null,
                tooltip: {
                  valueDecimals: 2
                }
              },
              {
                type: 'line',
                name: 'Benchmark',
                color: self.defaultBenchmarkColor,
                data: benchmark,
                threshold: null,
                tooltip: {
                  valueDecimals: 2
                }
              },
            ],
            title: {
              text: ''
            },
            credits: {
              enabled: false
            },
            chart: {
              type: 'line',
              height: 300,
              style: {
                fontFamily: "\"Roboto-Regular\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif",
                fontSize: 14
              },
              spacingBottom: 0,
            },
            rangeSelector: {
              enabled: false,
              allButtonsEnabled: false,
              inputEnabled: false,
              verticalAlign: 'top',
              buttons: null,
            },
            navigator: {
              enabled: true
            },
            legend: {
              enabled: false,
            },
            scrollbar: {
              enabled: false
            },
            plotOptions: {
              area: {
                fillColor: undefined
              },
              series: {
                animation: false,
                type: 'line',
                fillColor: undefined,
                compareBase: 0,
                showInNavigator: true,
                dataGrouping: {
                  enabled: false
                },
              }
            },
            tooltip: {
              dateTimeLabelFormats: {
                day: "%b %e, %Y"
              },
              pointFormatter: function () {
                  let suffix = '%';
                  let value = this.y;
                  if(this.dividend) {
                    value = this.dividend.summary;
                    suffix = assetInfo.currency_code;
                  }
                  return '<span><div style="display: inline-block; vertical-align: baseline; width: 10px; height: 10px; border-radius:50%; background-color: '+ this.series.color +';" />' +
                      '<div style="display: inline; font-size: 14px; color: #80858C; margin-left: 15px;">'+ toShortGermanFormat(value, '', ' ' + suffix, 2, false) +'</div></span><br/>'
              },
              valueDecimals: 2,
              split: false,
              shared: true,
              backgroundColor: 'white',
              shadow: {
                color: '#00000026',
                offsetX: 0,
                offsetY: 0,
                width: 10,
                opacity: 0.15
              },
              padding: 20,
              useHTML: true,
              shape: 'square',
              borderWidth: 0,
              borderRadius: 4,
              headerFormat: '<span style="font-size: 14px; font-family: Roboto-Bold;">{point.key}</span><br/><br/>',
              style: {
                fontFamily: '"Roboto-Regular"'
              }
            },
            yAxis: {
              opposite: false,
              align: 'right',
              title: {
                enabled: false
              },
              labels: {
                enabled: true,
                align: 'right',
                formatter: function () {
                  return toShortGermanFormat(this.value, '', ' %', 2, false);
                },
                style: {
                  fontSize: 14
                }
              }
            },
            xAxis: {
              type: 'datetime',
              dateTimeLabelFormats: {
                year: '%Y'
              },
              tickLength: 0,
              labels: {
                style: {
                  fontSize: 14
                }
              }
            },
            responsive: {
              rules: [{
                condition: {
                  maxWidth: 500
                },
                chartOptions: {
                  tooltip: {
                    positioner: function (labelWidth, labelHeight, point) {
                      return point.isHeader ? this.getPosition(labelWidth, labelHeight, point) : { x: 80, y: 30 };
                    }
                  }
                }
              }]
            }
          }}
        />
        <div className={classes.subline}>
          <p className={classes.description}>
            {self.renderDescription()}
          </p>
        </div>
      </div>
      {
        detailsTable && (
          self.renderDetailsTable()
        )
      }
      {
          (this.useDividends && !_.isEmpty(this.state.dividendHist)) && (
          <>
            <div style={{flexBasis: '100%', height: 0}}></div>
              <div className={classes.dividendsTableHeader}>
                Ereignisse
              </div>
              <div className={classes.dividendsTableColumns}>
                {this.renderDividendItems()}
              </div>
          </>
        )
      }
    </div>
  }

  renderDividendItems() {
    const { classes, assetInfo } = this.props;

    let tableSplitted = [];
    let tableArr = Object.entries(this.state.dividendHist);
    for (let i = 6; i > 0; i--) {
        tableSplitted.push(tableArr.splice(0, Math.ceil(tableArr.length / i)));
    }

    return <>{tableSplitted.map(items => (
        <ul className={classes.dividendsTable}>
            {items.map((item) => (
              <li className={classes.dividendsTableItem}>
                <div className={classes.dividendsTableItemColor}
                     style={{borderColor: this.defaultChartColor, fontSize: (item[1].index > 99 ? 10 : 14)}}>
                  {item[1].index}
                </div>
                <div className={classes.dividendsTableItemText}>
                  <span className={classes.dividendsTableItemDate}>{moment(item[0]).format('DD.MM.YYYY')}:&nbsp;</span>
                  <span className={classes.dividendsTableItemValues}>{item[1].values.map(val => (
                      <span style={{display: "block", height: 14}}>{toShortGermanFormat(val, '', ' ' + assetInfo.currency_code, 2)}</span>
                  ))}</span>
                </div>
              </li>
            ))}
        </ul>
    ))}</>
  }

  renderCondition() {
    const {
      performanceTimeseriesLoading,
      performanceBenchmarkLoading,
      performanceTimeseriesError,
      performanceBenchmarkError,
      performanceTimeseriesData,
      performanceBenchmarkData,
    } = this.state;
    const loading = performanceTimeseriesLoading || performanceBenchmarkLoading;

    if (loading) {
      return <CircularProgress />;
    }

    if (performanceTimeseriesError || performanceBenchmarkError) {
      return 'Fehler. Die Graphik "Performance" konnte nicht erzeugt werden.';
    }

    return performanceTimeseriesData && performanceBenchmarkData
      ? this.renderChart()
      : DEFAULT_EMPTY_SECTION_MESSAGE;
  }

  renderButtons() {
      return <TimeRangesButtonsPad
        onRangeChange={this.onRangeChange}
        disabled={this.state.performanceTimeseriesLoading}
        config={this.timePeriods()}
        selected={this.inceptionDate ? 'INCEPTION' : 'YEARS_5'}
      />
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
      title={'Wertentwicklung'}
      content={
        <>
          <div className={classes.controlsRow}>
            {this.renderButtons()}
          </div>
          <div className={classes.container}>
            {this.renderCondition()}
          </div>
        </>
      }
    />
  }
}

export default withStyles(styles)(PerformanceChart)