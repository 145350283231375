import {Dialog, DialogContent, withStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import styles from "./styles";
import clsx from "clsx";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckboxFormElement from "../StepContent/components/formElement/CheckboxFormElement";


class ConfirmationDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      skipConfirmation: false
    }
  }

  toggleSkipConfirmation = () => {
    this.setState((state) => ({skipConfirmation: !state.skipConfirmation}))
  }

  handleConfirm = () => {
    this.props.confirm(this.state.skipConfirmation)
    this.setState({skipConfirmation: false})
  }

  render() {

    const { classes } = this.props;
    return (
      <Dialog maxWidth='sm' fullWidth open={this.props.open} onClose={this.props.onClose}>
        <DialogTitle className={classes.title}>
          Bitte beachten Sie:
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <div className={classes.messageContent} dangerouslySetInnerHTML={{__html: this.props.message}}></div>
          <div className={classes.buttonsRow}>
            <PrimaryButton
              text={'Verstanden'}
              classes={{button: classes.button}}
              onButtonClick={this.handleConfirm}
            />
          </div>
          <div className={classes.buttonsRow}>
            <CheckboxFormElement
              label={"Zukünftig nicht mehr anzeigen"}
              value={this.state.skipConfirmation}
              onChange={this.toggleSkipConfirmation}
              custom_classes={{label: classes.confirmationCheckbox}}
            />
          </div>
        </DialogContent>
        {this.props.loading &&
          <div className={classes.loadWrapper}>
            <CircularProgress color={'primary'}/>
          </div>}
      </Dialog>
    )
  }
}

export default withStyles(styles)(ConfirmationDialog);
