import React from 'react';
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

const PageTitle = (props) => {
  const {
    classes,
    title,
    id,
    rightPanelItems,
  } = props;

  return (
    <Grid container alignItems="center" justify="space-between" spacing={2}>
      <Grid item xs={12} sm={'auto'}>
        <Typography variant="h1" className={classes.header} id={id}>
          {title}
        </Typography>
      </Grid>
      {!!rightPanelItems && (
        <Grid item xs={12} sm={'auto'}>
          <Grid container spacing={2}>
            {rightPanelItems}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
};

PageTitle.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  rightPanelItems: PropTypes.element,
};

export default withStyles(styles)(PageTitle);