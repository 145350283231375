import React from 'react';
import _ from 'lodash';

import withStyles from "@material-ui/core/styles/withStyles";
import {getBackToDivaCustomerLink} from "../../utils/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Link from "../Link";
import {executeIfPathExist, getInvestmentDynamicPath} from "../../containers/InvestmentPlatform/utils";
import styles from "./styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {connect} from "react-redux";
import {hasPortfolios} from "../CustomersSelectorProviderNew/utils";
import clsx from "clsx";
import { ClickAwayListener, Fade, Popper, Paper } from '@material-ui/core';

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
  auth: state.get('auth').toJS(),
});

const CustomerFullnameMenu = props => {

  const {
    classes,
    customer,
    customerFullname,
    additionalLinks,
    auth: {
      user
    }
  } = props;

  if (!customer || !_.get(user, 'is_broker')) {
    return customerFullname
  }

  const defaultLinks = [
    {title: 'Formularcenter', path: 'FORM_CENTER', icon: ['fa', 'file-invoice']},
    {title: 'Kundendaten', path: 'CRM', icon: ['far', 'user']},
    {title: 'Risikoklassenanalyse', path: 'RISK_INDICATOR_OVERVIEW', disabled: !_.get(customer, 'last_srri.srri'), icon: ['fa', 'user-chart']},
    {title: 'Transaktionsübersicht', path: 'TRANSACTIONS_OVERVIEW', icon: ['fa', 'exchange']},
    {title: 'Vermögensübersicht', path: 'DASHBOARD', disabled: !hasPortfolios(customer), icon: ['fa', 'analytics']}
  ]

  const [customerDropdownOpened, setCustomerDropdownOpened] = React.useState(false);
  const customerID = customer.customer_id;
  const customerRef = React.useRef();
  const [arrowRef, setArrowRef] = React.useState(null);

  const handleClick = () => {
    setCustomerDropdownOpened(!customerDropdownOpened)
  };

  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (customerDropdownOpened) {
      setCustomerDropdownOpened(false);
    }
  };

  const menuLink = (link, handleOnLinkClick) => (
    <Link
      key={link.path}
      className={clsx(classes.dropdownItem, link.disabled ? classes.dropdownItemDisabled : '')}
      title={link.title}
      disabled={link.disabled}
      icon={link.icon && <FontAwesomeIcon icon={link.icon} fixedWidth={true}/>}
      onClick={handleOnLinkClick}
    />
  )

  const buildLinks = () => _.sortBy([...defaultLinks, ...(additionalLinks || [])], 'title').map(link => {
    if (link.path === 'CRM') {
      return menuLink(link, () => window.open(getBackToDivaCustomerLink(customerID), '_blank'))
    }

    let menuLinkExist = null
    executeIfPathExist(props.investmentPlatform.routes, link.path, path => {
      let pathUpdated = path.replace(':customer_id', customerID)
      menuLinkExist = menuLink(link,() => window.open(`/${getInvestmentDynamicPath()}${pathUpdated}`, '_blank'))
    }, ':customer_id')

    return menuLinkExist
  })

  const preventPropagateClick = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      <div className={classes.dropdownMenuWrapper} ref={customerRef}>
        <Link
          title={
            <span className={classes.linkTitleContainer}>
              <span className={classes.linkContent}>
                {customerFullname}
                <span className={classes.linkIcon}>
                  <ExpandMoreIcon />
                </span>
              </span>
            </span>
          }
          onClick={handleClick}
        />
        <Popper
          open={customerDropdownOpened}
          anchorEl={customerRef.current}
          placement="bottom"
          transition
          className={classes.popper}
          modifiers={{
            offset: {
              enabled: true,
              offset: '0, 10'
            },
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          }}
          onClick={preventPropagateClick}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClose}>
              <Fade {...TransitionProps}>
                <Paper classes={{root: classes.dropdownMenu}}>
                  {buildLinks()}
                  <span className={classes.arrow} ref={setArrowRef} />
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(withStyles(styles)(CustomerFullnameMenu));
