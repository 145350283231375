export default () => ({
  calendarQuestionContainer: {},
  helpText: {
    marginTop: 8,
    marginLeft: 32,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38'
  },
  labelRoot: {
    padding: '17px 0 17px 31px',
    color: 'inherit',
  }
})