import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    position: 'relative',
    padding: 20,

    width: '100%'
  }
})

export default makeStyles(styles)