import { fromJS } from 'immutable';

import {
  ADD_ROUTES,
  SET_HEADER_INFO,
  SET_REFRESH_HEADER_TRIGGER,
  SET_NEW_ALERTING_EVENTS_COUNT,
  DECREASE_NEW_ALERTING_EVENTS_COUNT,
  CLEAN_NEW_ALERTING_EVENTS_COUNT
} from './constants'

const initialState = fromJS({
  routes: null,
  header_info: null,
  refresh_header_trigger: null,
  new_alerting_events_count: null
});

function appReducer(state = initialState, action) {
  switch(action.type) {
    case ADD_ROUTES:
      return state.set('routes', action.routes);
    case SET_HEADER_INFO:
      return state.set('header_info', action.component);
    case SET_REFRESH_HEADER_TRIGGER:
      return state.set('refresh_header_trigger', Date.now())
    case SET_NEW_ALERTING_EVENTS_COUNT:
      return state.set('new_alerting_events_count', action.new_alerting_events_count)
    case CLEAN_NEW_ALERTING_EVENTS_COUNT:
      return state.set('new_alerting_events_count', null)
    default:
      return state;
  }
}

export default appReducer;