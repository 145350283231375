import React from 'react';

const PlusBackgroundIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <rect style={{fill: props.disabled ? 'rgba(0, 0, 0, 0.12)' : '#cce9fa'}} width="36" height="36" rx="18"/>
      <g transform="translate(17.748 8) rotate(45)">
        <path style={{fill: props.disabled ? 'rgba(0, 0, 0, 0.26)' : '#0092e5'}}
              d="M6.893,8.147l-5.64,5.64L0,12.533l5.64-5.64L0,1.253,1.253,0l5.64,5.64L12.533,0l1.254,1.253-5.64,5.64,5.64,5.64-1.254,1.254Z"/>
      </g>
    </svg>
  );
};

export default PlusBackgroundIcon;