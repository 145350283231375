import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { withStyles } from "@material-ui/core";

import { styles } from './styles'
import Icon from './Icon/Icon'
import Content from './Content/Content'

class Button extends Component {

  handleButtonClick = (event) => {
    event.stopPropagation()
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render() {
    const { classes } = this.props
    return (
      <span className={classes.button} onClick={this.handleButtonClick}>
        { this.props.children }
      </span>
    );
  }
}

Button.propTypes = {
  onClick: PropTypes.func
};

Button.Icon = Icon
Button.Content = Content

export default withStyles(styles)(Button);
