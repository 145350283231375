import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 34,
    color: '#202A38',
    margin: 0
  },
  container: {
    backgroundColor: 'white',
    width: '100%',
    minHeight: 'calc(100% + 24px)',
    marginTop: -24,
    paddingTop: 24,
  },

  tableCell: {
    '&:first-child': {
      width: 300
    },

    '&:nth-child(2)': {
      width: 150
    },

    '&:nth-child(3)': {
      width: 250
    },

    '&:last-child': {
      width: 150
    },

    fontFamily: 'Roboto-Regular',
    color: '#202A38'
  },
  tableRow: {
    transition: 'all',
    transitionDuration: 250,
    '&:hover': {
      backgroundColor: '#EEF0F2'
    }
  },
  tableHead: {
    '& td': {
      fontFamily: 'Roboto-Bold',
      color: '#80858C'
    },
    '& td:last-child > div': {
      display: 'block !important'
    }
  },

  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',

    '& button >span > span': {
      fontSize: 14
    }
  },
  addNewFavoriteListButtonRoot: {
    height: 36
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 50
  },
  recordsOnPage: {
    fontSize: 16,
    fontWeight: 'Roboto-Regular',
    color: '#80858C'
  },
  paginationInputLabel: {
    fontSize: 14,
    color: '#202A38'
  },
  bottomModal: {
    display: "flex",
    width: '70%',
    height: 64,
    padding: '20px 50px',
    borderRadius: 3,
    backgroundColor: '#202A38',
    position: 'fixed',
    bottom: 52,
    left: 0,
    fontFamily: 'Roboto-Bold',
    color: 'white',
    fontSize: 16,
    margin: '0 15%',
  },
  itemName: {
    marginTop: 2,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  addButton: {
    float: 'right',
    flexGrow: 0,
    flexShrink: 0,
    marginLeft: 'auto',
    marginTop: '-5px',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontFamily: 'Roboto-Bold',
    textTransform: 'none',
    backgroundColor: '#0092E5',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#0092E5',
    }
  },
  successDialogInner: {
    '& ul': {
      listStyle: 'none',
      margin: '20px 0',
    },
    '& div:first-child': {
      fontFamily: 'Roboto-Bold',
    }
  }
})

export default makeStyles(styles)