import React from 'react';

import {
  Button,
  CircularProgress
} from '@material-ui/core';

import useStyles from './styles';
import ModalContainer from "../ModalContainer/ModalContainer";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const ConfirmModal = (props) => {
  const classes = useStyles();

  const default_cancel_button_text = 'Cancel';
  const default_confirm_button_text = 'Confirm';
  const default_title_text = 'Are you sure you want to continue?';

  const {
    open,
    handleClose,
    handleConfirm,
    loading,
    title,
    cancelBtnText,
    confirmBtnText,
  } = props;

  return (
    <ModalContainer open={open} handleClose={handleClose}>
      <p className={classes.modalTitle}>{title || default_title_text}</p>
      <div className={classes.modalActions}>
        <Button className={classes.actionButton} color={"primary"} onClick={handleClose}>
          {cancelBtnText || default_cancel_button_text}
        </Button>
        <Button
          style={{minWidth: 160}}
          disabled={loading}
          className={classes.actionButton}
          color={"primary"}
          variant="contained"
          onClick={handleConfirm}
        >
          {loading ? (
            <CircularProgress size={15}/>
          ) : (
            confirmBtnText || default_confirm_button_text
          )}
        </Button>
      </div>
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </ModalContainer>
  )
};

export default ConfirmModal;