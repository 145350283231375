import React from 'react';
import _ from "lodash";
import {Grid} from "@material-ui/core";

import useStyles from './styles';
import {PrimaryButton} from "../../../Buttons";
import WarningTooltip from "../../../WarningTooltip";
import {FieldTitle} from "../index";
import {useDownloadPdfDialogContext} from "../../DownloadPdfModal";
import Card from "@material-ui/core/Card";
import {
  PDF_EXPORTING_MODAL_QUESTION_UID
} from "../../../../containers/CustomerDashboard/components/PdfExportingModal/constants";
import Customers from '../Customers/Customers';
import PropTypes from "prop-types";
import RichTextEditor
  from "../../../../containers/GroupReporting/components/EditorStepContent/components/RichTextEditor";
import EditorSettingsSwitch
  from "../../../../containers/GroupReporting/components/EditorStepContent/components/EditorSettingsSwitch";
import CoverPageDocument
  from "../../../../containers/GroupReporting/components/PreviewStepContent/components/CoverPageDocument";


function CoverPage({compareContentForPreview, coverTitle, withCustomers}) {

  const classes = useStyles();

  const {state, handleStateValueChange} = useDownloadPdfDialogContext();
  const [coverPageData, setCoverPageData] = React.useState(undefined);

  const previewCustomer = _.get(state, 'customers.0');

  React.useEffect(() => {
    // update coverPageData ONLY when switch to preview and message changed
    if(state.editorEnabled && !state.showTextEditor && (!compareContentForPreview || _.get(coverPageData, 'cover_message_content') !== state.editorValue)){
      setCoverPageData({
        force_fetch_cover: true,
        customer_id: previewCustomer && previewCustomer.customer_id,
        cover_message_enabled: true,
        cover_message_content: state.editorValue,
      })
    }
  }, [state.showTextEditor, state.editorEnabled, _.get(previewCustomer, 'customer_id')]);

  const handleEditorEnabledChanged = (value) => {
    handleStateValueChange('editorEnabled', value);
  }

  const handleEditorValueChange = (value) => {
    if (value !== state.editorValue) {
      handleStateValueChange('editorValue', value);
    }
  };

  return (
    <Grid container className={classes.container} spacing={1}>
      <Grid item xs={12}>
        <FieldTitle>
          Anschreiben
          <WarningTooltip
            title="Wenn Sie ein persönliches Anschreiben hinzufügen möchten, setzen Sie den Schalter bitte auf “EIN”"
          />
        </FieldTitle>
        <EditorSettingsSwitch
          description={null}
          enabled={state.editorEnabled || false}
          label={null}
          handleValueChanged={handleEditorEnabledChanged}
          classes={{
            container: classes.editorSwitchContainer,
            headerWrapper: classes.headerWrapper,
            switch: classes.switch
          }}
        />
      </Grid>
      {!!state.editorEnabled && withCustomers && (
        <Grid item xs={12}>
          <Customers />
        </Grid>
      )}
      <Grid item xs={12} hidden={!state.editorEnabled} className={classes.itemContainer} style={{marginBottom: 10}}>
        <FieldTitle>
          Textfeld
          <WarningTooltip
            title="Definieren Sie hier den Text für Ihr Anschreiben. Nutzen Sie hierzu gerne unsere automatischen Seriendruckfelder."
          />
        </FieldTitle>
        <div hidden={!state.showTextEditor}>
          <Card className={classes.textEditorWrapper}>
            <RichTextEditor
              content={state.editorValue}
              addDefaultEmptyLines
              handleChange={handleEditorValueChange}
              enabled={state.editorEnabled}
              uid={PDF_EXPORTING_MODAL_QUESTION_UID}
            />
          </Card>
        </div>
        <div hidden={state.showTextEditor}>
          <CoverPageDocument
            customer={coverPageData}
            useDummyCustomer={!_.get(previewCustomer, 'customer_id')}
            coverTitle={coverTitle}
          />
        </div>
      </Grid>
    </Grid>
  )
}

CoverPageDocument.propTypes = {
  compareContentForPreview: PropTypes.bool,
  withCustomers: PropTypes.bool,
  coverTitle: PropTypes.string
}

CoverPageDocument.propDefs = {
  compareContentForPreview: {
    description: 'Flag that indicate if preview should be triggered only if editor value was updated.'
  },
  withCustomers: {
    description: 'Flag that indicate if customers selection should be included.'
  },
  coverTitle: {
    description: 'Custom cover page title.'
  }
}

CoverPageDocument.defaultProps = {
  compareContentForPreview: true,
  withCustomers: false
}

export default CoverPage;