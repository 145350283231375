import demo_diva_logo from "./logo_diva.svg";
import prod_diva_logo from "./logo_diva_prod.svg";
import default_user_logo from "./default_user_logo.svg";
import mail_icon from "./mail_icon.svg";
import phone_icon from "./phone_icon.svg";
import plus_icon from "./plus_icon.svg";
import down_arrow from "./down_arrow.svg";
import calendar_icon from "./calendar_icon.svg";
import calendar_icon_error from "./calendar_icon_error.svg";
import preloader from "./audio.svg";
import search_icon from "./search_icon.svg";
import search_icon_primary from "./search_icon_primary.svg";
import close_icon from "./close_icon.svg";
import clear_input_icon from "./clear_input.svg";
import close_tour_icon from "./tour_close_icon.svg";
import equity_icon from "./equity_icon.svg";
import equity_icon_white from "./equity_icon_white.svg";
import immobilien_icon from "./immobilien_icon.svg";
import save_cash_icon from "./save_cash_icon.svg";
import insurance_icon from "./insurance_icon.svg";
import external_icon from "./external_icon.svg";
import immobilien_icon_white from "./immobilien_icon_white.svg";
import external_icon_white from "./external_icon_white.svg";
import save_cash_icon_white from "./save_cash_icon_white.svg";
import insurance_icon_white from "./insurance_icon_white.svg";
import success_icon from "./success_icon.svg";
import select_list_icon from "./select_list_icon.svg";
import warning_icon from "./warning_icon.svg";
import series_reports from "./series_reports.svg";
import chart_defensiv from "./RiskProfiling/chart_defensiv.svg";
import chart_ausgeglichen from "./RiskProfiling/chart_ausgeglichen.svg";
import chart_offensiv from "./RiskProfiling/chart_offensiv.svg";
import slider_thumb_1 from "./RiskProfiling/slider_thumb_1.png";
import slider_thumb_2 from "./RiskProfiling/slider_thumb_2.png";
import megaphone from "./RiskProfiling/megaphone.svg";
import pie_chart_placeholder from './pie_chart_placeholder.svg'

import landing_icon_haftungsdach from "./Landing/icon_haftungsdach.svg";
import landing_icon_investment_anlegerin from "./Landing/icon_investment-anlegerin.svg";
import landing_icon_investment_operations from "./Landing/icon_investment-operations.svg";
import landing_icon_investment_research from "./Landing/icon_investment-research.svg";
import landing_icon_investmentsoftware from "./Landing/icon_investmentsoftware.svg";
import landing_icon_private_investing from "./Landing/icon_private-investing.svg";
import landing_icon_sachwerte from "./Landing/icon_sachwerte.svg";

import service_concept_icon_medal from "./RiskProfiling/icon_medal.svg";

import { BCA_PROD_LOGO_DOMAINS } from "../utils/constants";

let diva_logo;
if (BCA_PROD_LOGO_DOMAINS.includes(window.location.origin)) {
  diva_logo = prod_diva_logo;
} else {
  diva_logo = demo_diva_logo;
}

export default {
  diva_logo,
  default_user_logo,
  mail_icon,
  phone_icon,
  plus_icon,
  down_arrow,
  calendar_icon,
  calendar_icon_error,
  preloader,
  search_icon,
  search_icon_primary,
  close_icon,
  clear_input_icon,
  close_tour_icon,
  success_icon,
  select_list_icon,
  warning_icon,
  series_reports,
  asset_icons: {
    equity_icon,
    equity_icon_white,
    immobilien_icon,
    immobilien_icon_white,
    save_cash_icon,
    save_cash_icon_white,
    insurance_icon,
    insurance_icon_white,
    external_icon,
    external_icon_white,
  },
  risk_profiling: {
    chart_defensiv,
    chart_ausgeglichen,
    chart_offensiv,
    slider_thumb_1,
    slider_thumb_2,
    megaphone,
  },
  lamding: {
    landing_icon_haftungsdach,
    landing_icon_investment_anlegerin,
    landing_icon_investment_operations,
    landing_icon_investment_research,
    landing_icon_investmentsoftware,
    landing_icon_private_investing,
    landing_icon_sachwerte,
  },
  service_concept: {
    service_concept_icon_medal,
  },
  pie_chart_placeholder
};

export { default as InsuranceIcon } from "./InsuranceIcon";
export { default as CallMoneyIcon } from "./CallMoneyIcon";
export { default as EquityIcon } from "./EquityIcon";
export { default as ImmobilienIcon } from "./ImmobilienIcon";
export { default as FileInvoiceDollarIcon } from "./FileInvoiceDollarIcon";
export { default as CalendarIcon } from "./CalendarIcon";
export { default as PhoneIcon } from "./PhoneIcon";
export { default as MailIcon } from "./MailIcon";
export { default as ImportIcon } from "./ImportIcon";
export { default as FilterIcon } from "./FilterIcon";
export { default as ConfigIcon } from "./ConfigIcon";
export { default as OpenFolderIcon } from "./OpenFolderIcon";
export { default as OpenFolderSelectedIcon } from "./OpenFolderSelectedIcon";
export { default as CloseFolderIcon } from "./CloseFolderIcon";
export { default as CloseFolderSelectedIcon } from "./CloseFolderSelectedIcon";
export { default as CheckIcon } from "./CheckIcon";
export { default as DetailsIcon } from "./DetailsIcon";
export { default as PlusIcon } from "./PlusIcon";
export { default as DeleteIcon } from "./DeleteIcon";
export { default as ArrowIcon } from "./ArrowIcon";
export { default as SearchIcon } from "./SearchIcon";
export { default as CloseIcon } from "./CloseIcon";
export { default as SuccessIcon } from "./SuccessIcon";
export { default as AddIcon } from "./AddIcon";
export { default as BackIcon } from "./BackIcon";
export { default as EditIcon } from "./EditIcon";
export { default as InfoIcon } from "./InfoIcon";
export { default as DownloadIcon } from "./DownloadIcon";
export { default as SignatureIcon } from "./SignatureIcon";
export { default as PrintIcon } from "./PrintIcon";
export { default as SelectListIcon } from "./SelectListIcon";
export { default as One } from "./One";
export { default as OneDark } from "./OneDark";
export { default as Two } from "./Two";
export { default as TwoDark } from "./TwoDark";
export { default as Three } from "./Three";
export { default as ThreeDark } from "./ThreeDark";
export { default as Four } from "./Four";
export { default as FourDark } from "./FourDark";

export { default as OneAspect } from "./Aspects/OneAspect";

export { default as TwoAspect } from "./Aspects/TwoAspect";

export { default as ThreeAspect } from "./Aspects/ThreeAspect";

export { default as FourAspect } from "./Aspects/FourAspect";

export { default as FiveAspect } from "./Aspects/FiveAspect";

export { default as SixAspect } from "./Aspects/SixAspect";

export { default as SevenAspect } from "./Aspects/SevenAspect";

export { default as EightAspect } from "./Aspects/EightAspect";

export { default as NineAspect } from "./Aspects/NineAspect";

export { default as TenAspect } from "./Aspects/TenAspect";

export { default as ElevenAspect } from "./Aspects/ElevenAspect";

export { default as TwelveAspect } from "./Aspects/TwelveAspect";

export { default as ThirteenAspect } from "./Aspects/ThirteenAspect";

export { default as FourteenAspect } from "./Aspects/FourteenAspect";

export { default as OneAspectDark } from "./Aspects/OneAspectDark";

export { default as TwoAspectDark } from "./Aspects/TwoAspectDark";

export { default as ThreeAspectDark } from "./Aspects/ThreeAspectDark";

export { default as FourAspectDark } from "./Aspects/FourAspectDark";

export { default as FiveAspectDark } from "./Aspects/FiveAspectDark";

export { default as SixAspectDark } from "./Aspects/SixAspectDark";

export { default as SevenAspectDark } from "./Aspects/SevenAspectDark";

export { default as EightAspectDark } from "./Aspects/EightAspectDark";

export { default as NineAspectDark } from "./Aspects/NineAspectDark";

export { default as TenAspectDark } from "./Aspects/TenAspectDark";

export { default as ElevenAspectDark } from "./Aspects/ElevenAspectDark";

export { default as TwelveAspectDark } from "./Aspects/TwelveAspectDark";

export { default as ThirteenAspectDark } from "./Aspects/ThirteenAspectDark";

export { default as FourteenAspectDark } from "./Aspects/FourteenAspectDark";

export { default as FifteenAspect } from "./Aspects/FifteenAspect";

export { default as FifteenAspectDark } from "./Aspects/FifteenAspectDark";

export { default as Leaf } from "./FactsheetSustainabilityTab/Leaf";
export { default as SDGFirst } from "./FactsheetSustainabilityTab/SDGFirst";
export { default as SDGSecond } from "./FactsheetSustainabilityTab/SDGSecond";
export { default as SDGThird } from "./FactsheetSustainabilityTab/SDGThird";
export { default as SDGFourth } from "./FactsheetSustainabilityTab/SDGFourth";

export { default as FIrst1 } from "./FactsheetSustainabilityTab/Exclusion/First/FIrst1";
export { default as First2 } from "./FactsheetSustainabilityTab/Exclusion/First/First2";
export { default as First2Dark } from "./FactsheetSustainabilityTab/Exclusion/First/First2Dark";
export { default as First3 } from "./FactsheetSustainabilityTab/Exclusion/First/First3";
export { default as First3Dark } from "./FactsheetSustainabilityTab/Exclusion/First/First3Dark";
export { default as First4 } from "./FactsheetSustainabilityTab/Exclusion/First/First4";
export { default as First4Dark } from "./FactsheetSustainabilityTab/Exclusion/First/First4Dark";
export { default as Second1 } from "./FactsheetSustainabilityTab/Exclusion/First/Second1";
export { default as Second2 } from "./FactsheetSustainabilityTab/Exclusion/First/Second2";
export { default as Second2Dark } from "./FactsheetSustainabilityTab/Exclusion/First/Second2Dark";
export { default as Second3 } from "./FactsheetSustainabilityTab/Exclusion/First/Second3";
export { default as Second3Dark } from "./FactsheetSustainabilityTab/Exclusion/First/Second3Dark";
export { default as Third1 } from "./FactsheetSustainabilityTab/Exclusion/First/Third1";
export { default as Third2 } from "./FactsheetSustainabilityTab/Exclusion/First/Third2";
export { default as Third2Dark } from "./FactsheetSustainabilityTab/Exclusion/First/Third2Dark";
export { default as Third3 } from "./FactsheetSustainabilityTab/Exclusion/First/Third3";
export { default as Third3Dark } from "./FactsheetSustainabilityTab/Exclusion/First/Third3Dark";
export { default as Third4 } from "./FactsheetSustainabilityTab/Exclusion/First/Third4";
export { default as Third4Dark } from "./FactsheetSustainabilityTab/Exclusion/First/Third4Dark";
export { default as Fourth1 } from "./FactsheetSustainabilityTab/Exclusion/First/Fourth1";
export { default as Fourth2 } from "./FactsheetSustainabilityTab/Exclusion/First/Fourth2";
export { default as Fourth2Dark } from "./FactsheetSustainabilityTab/Exclusion/First/Fourth2Dark";
export { default as Fourth3 } from "./FactsheetSustainabilityTab/Exclusion/First/Fourth3";
export { default as Fourth3Dark } from "./FactsheetSustainabilityTab/Exclusion/First/Fourth3Dark";
export { default as Fourth4 } from "./FactsheetSustainabilityTab/Exclusion/First/Fourth4";
export { default as Fourth4Dark } from "./FactsheetSustainabilityTab/Exclusion/First/Fourth4Dark";
export { default as Fourth5 } from "./FactsheetSustainabilityTab/Exclusion/First/Fourth5";
export { default as Fourth5Dark } from "./FactsheetSustainabilityTab/Exclusion/First/Fourth5Dark";
export { default as Fourth6 } from "./FactsheetSustainabilityTab/Exclusion/First/Fourth6";
export { default as Fourth6Dark } from "./FactsheetSustainabilityTab/Exclusion/First/Fourth6Dark";
export { default as Fourth7 } from "./FactsheetSustainabilityTab/Exclusion/First/Fourth7";
export { default as Fourth7Dark } from "./FactsheetSustainabilityTab/Exclusion/First/Fourth7Dark";
export { default as Fourth8 } from "./FactsheetSustainabilityTab/Exclusion/First/Fourth8";
export { default as Fourth8Dark } from "./FactsheetSustainabilityTab/Exclusion/First/Fourth8Dark";

export { default as First1S } from "./FactsheetSustainabilityTab/Exclusion/Second/First1S";
export { default as First2S } from "./FactsheetSustainabilityTab/Exclusion/Second/First2S";
export { default as First3S } from "./FactsheetSustainabilityTab/Exclusion/Second/First3S";
export { default as First4S } from "./FactsheetSustainabilityTab/Exclusion/Second/First4S";
export { default as First5S } from "./FactsheetSustainabilityTab/Exclusion/Second/First5S";
export { default as Second1S } from "./FactsheetSustainabilityTab/Exclusion/Second/Second1S";
export { default as Second2S } from "./FactsheetSustainabilityTab/Exclusion/Second/Second2S";
export { default as Second3S } from "./FactsheetSustainabilityTab/Exclusion/Second/Second3S";
export { default as Second4S } from "./FactsheetSustainabilityTab/Exclusion/Second/Second4S";
export { default as Third1S } from "./FactsheetSustainabilityTab/Exclusion/Second/Third1S";
export { default as Third2S } from "./FactsheetSustainabilityTab/Exclusion/Second/Third2S";
export { default as Third3S } from "./FactsheetSustainabilityTab/Exclusion/Second/Third3S";
export { default as Third4S } from "./FactsheetSustainabilityTab/Exclusion/Second/Third4S";
export { default as Fourth1S } from "./FactsheetSustainabilityTab/Exclusion/Second/Fourth1S";
export { default as Fourth2S } from "./FactsheetSustainabilityTab/Exclusion/Second/Fourth2S";
export { default as Fourth3S } from "./FactsheetSustainabilityTab/Exclusion/Second/Fourth3S";
export { default as Fourth4S } from "./FactsheetSustainabilityTab/Exclusion/Second/Fourth4S";
export { default as Fourth5S } from "./FactsheetSustainabilityTab/Exclusion/Second/Fourth5S";
export { default as Fourth6S } from "./FactsheetSustainabilityTab/Exclusion/Second/Fourth6S";
export { default as Fourth7S } from "./FactsheetSustainabilityTab/Exclusion/Second/Fourth7S";
