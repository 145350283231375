import React from 'react';
import _ from 'lodash';

import { withStyles } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import useStyles, {sliderStyles} from './styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { toGermanFormat } from '../../../../utils/numberFormater';


const ESGSlider = withStyles(sliderStyles)(Slider);

const formatValue = (value) => toGermanFormat(value, '', ' %', 2, true);

const EsgSlide = ({title, categoryData, loading}) => {
  const classes = useStyles();

  const noProfile = _.isEmpty(categoryData);
  // get current value
  let currentValue, target, color;

  if (!loading && !noProfile){
    currentValue = categoryData.target_without_missing + categoryData.overweight;
    target = categoryData.target;
    color = currentValue >= target ? 'green' : 'red';
  }

  return (
    <Grid container>
      <Grid item xs={6}>{title}</Grid>
      <Grid item xs={6} className={classes.values}>
        {loading ? <Skeleton width={"50%"} style={{float: 'right'}} /> : noProfile ? 'nicht vorhanden' : `${formatValue(currentValue)} von ${formatValue(target)}`}
      </Grid>
      <Grid item xs={12}>
        {loading ?
          <Skeleton /> :
          <ESGSlider value={currentValue} max={target > 0 ? target : currentValue} disabled classes={{track: color, rail: color}}/>}
      </Grid>
    </Grid>
  )
};

export default EsgSlide;