import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  previewContainer: {
    width: 420,
    height: 464,
    backgroundColor: '#FBFBFD',
    boxShadow: '2px 2px 10px #0000001A',
  },
  navigationContainer: {
    overflow: 'hidden',  // workaround for animated collapse
    height: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconButton: {
    color: '#6693B4'
  },
  documentContainer: {
    overflowX: 'auto',
    overflowY: 'auto',
    width: '100%',
    height: '100%',
    boxShadow: '2px 2px 10px #0000001A',
    '&::-webkit-scrollbar': {
      width: 3,
      height: 3
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#C9C9C9',
    }
  },
  totalPagesCountSpan: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#6693B4'
  },
  activePageInput: {
    width: 35,
    border: 'none',
    borderBottom: '2px solid #6693B4',
    textAlign: 'center',
    background: 'transparent',
    fontSize: 14,
    color: '#707070',
    fontFamily: 'Roboto-Regular',
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&:focus': {
      border: 'none',
      borderBottom: '2px solid #6693B4',
      outline: 'none'
    }
  }
})

export default makeStyles(styles)