import React from 'react';

import {Grid} from '@material-ui/core'
import BaseChart from '../../../../../../FactSheetsPage/components/BaseChart';
import useStyles from '../styles';


const TotalAssetVolume = (props) => {
  const {
    dashboardData,
    loading
  } = props;

  const classes = useStyles();

  const data = dashboardData && dashboardData.portfolio_structure_volume && dashboardData.portfolio_structure_volume.total_assets;
  return (
    <Grid container>
      <Grid item xs={12}>
        <BaseChart
          chartType='bar'
          title={'Vermögensaufteilung'}
          seriesData={data || []}
          loading={loading}
          classes={{
            title: (!loading && !(data && data.length)) ? classes.titleWithoutData : classes.title,
          }}
        />
      </Grid>
    </Grid>
  )
};

export default TotalAssetVolume;
