import React from 'react';
import _ from 'lodash';

import { DialogContent, Grid } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';

import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import ResponsiveDialog from '../../../../components/ResponsiveDialog/ResponsiveDialog';

import useStyles from './styles';
import CustomersNew from '../../../../components/CustomersSelectorProviderNew/CustomersNew';
import CustomersList from '../../../../components/CustomersSelectorProviderNew/components/CustomersList';
import clsx from "clsx";

function CustomersSelectionModal({ open, onClose, onSelect, selectedCustomersIds }) {
  const classes = useStyles();

  const [selectedCustomers, setSelectedCustomers] = React.useState([]);

  React.useEffect(() => {
    setSelectedCustomers((current) =>
      _.filter(current, (customer) => selectedCustomersIds.includes(customer.customer_id)));

  }, [JSON.stringify(selectedCustomersIds || [])])

  const handleConfirm = () => {
    onSelect(selectedCustomers);
  };

  const addExtraColumns = [{
    content: [{
      header: '',
      body: {
        content: (customer) => {

          const classes = useStyles();

          const addCustomer = () => {
            setSelectedCustomers((customers) => ([...customers, customer]));
          };

          return (
            <PrimaryButton
              text={<i className={'far fa-plus'} />}
              onButtonClick={addCustomer}
              color={'success'}
              customClasses={{root: clsx(classes.buttonRoot, classes.buttonRootAdd)}}
            />
          )
        },
        className: 'extraButton',
      }
    }]
  }];

  const removeExtraColumns = [{
    content: [{
      header: '',
      body: {
        content: (customer) => {

          const classes = useStyles()
          const removeCustomer = () => {
            setSelectedCustomers((customers) => ([...customers].filter((c) => c.customer_id !== customer.customer_id)));
          };

          return (
            <PrimaryButton
              text={<i className={'far fa-minus'} />}
              onButtonClick={removeCustomer}
              color={'success'}
              customClasses={{root: clsx(classes.buttonRoot, classes.buttonRootRemove)}}
            />
          )
        },
        className: 'extraButton',
      }
    }]
  }];

  return (
    <ResponsiveDialog open={open} onClose={onClose}>
      <DialogTitle>
        <div className={classes.header}>
          <span>Kunden für das Anschreiben auswählen</span>
          <IconButton disableRipple aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <div>
          <CustomersList
            customers={selectedCustomers}
            extraColumns={removeExtraColumns}
          />
        </div>

        <CustomersNew
          extraColumns={addExtraColumns}
          withHeader={false}
          excludedCustomersIDs={selectedCustomers.map((c) => c.customer_id)}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Grid container spacing={2} direction={'column'} alignContent={'flex-end'}>
          <Grid item>
            <PrimaryButton
              text={_.isEmpty(selectedCustomers) ? 'Ohne Kunden fortfahren' : 'Speichern & fortfahren'}
              onButtonClick={handleConfirm}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </ResponsiveDialog>
  );
}

export default CustomersSelectionModal;
