export const SET_SHARED_SETTINGS = 'SET_SHARED_SETTINGS';

export const SHARED_SETTINGS_KEYS = {
  DASHBOARD_FOOTER_TEXT: 'DASHBOARD_FOOTER_TEXT',
  PI_INSTRUMENTS_LIST_FOOTER_TEXT: 'PI_INSTRUMENTS_LIST_FOOTER_TEXT'
}

export const HOST_RELATED_SHARED_SETTINGS_KEYS = {
  INSTRUMENT_TABLE_ERROR_MESSAGE_TEXT: 'INSTRUMENT_TABLE_ERROR_MESSAGE_TEXT',
  HISTORICAL_CHART_ORDER_ERROR_MESSAGE_TEXT: 'HISTORICAL_CHART_ORDER_ERROR_MESSAGE_TEXT',
  HISTORICAL_CHART_MARKET_VALUE_ERROR_MESSAGE_TEXT: 'HISTORICAL_CHART_MARKET_VALUE_ERROR_MESSAGE_TEXT',
  INSTRUMENT_TABLE_PA_ERROR_MESSAGE_TEXT: 'INSTRUMENT_TABLE_PA_ERROR_MESSAGE_TEXT',

  // shared settings that responsible for displaying extra info
  DASHBOARD_HISTORICAL_CHART_EXPLANATION: 'DASHBOARD_HISTORICAL_CHART_EXPLANATION',
  DASHBOARD_PERFORMANCE_CHART_EXPLANATION: 'DASHBOARD_PERFORMANCE_CHART_EXPLANATION',
  TRANS_SALDO_EXPLANATION: 'TRANS_SALDO_EXPLANATION',
  START_VALUE_EXPLANATION: 'START_VALUE_EXPLANATION',
  GuV_VALUE_EXPLANATION: 'GuV_VALUE_EXPLANATION',
  TRANS_SALDO_WITH_BOOKINGS_EXPLANATION: 'TRANS_SALDO_WITH_BOOKINGS_EXPLANATION',
  GUV_CUSTODIAN_SELL_SOME_QUANTITY_EXPLANATION: 'GUV_CUSTODIAN_SELL_SOME_QUANTITY_EXPLANATION',
  ASSETS_WITHOUT_TOP_HOLDINGS_TEXT: 'ASSETS_WITHOUT_TOP_HOLDINGS_TEXT',

  // disclaimer for transactions monitor
  TRANSACTION_RELIABILITY_DISCLAIMER: 'TRANSACTION_RELIABILITY_DISCLAIMER'

}

export const NOT_FOUND = 'NOT FOUND'