import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  reportingPageContainer: {
    overflowY: 'auto',
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingLeft: '0!important',
    paddingRight: '0!important',
    maxWidth: '100%',
  },
  pageLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '80vh'
  },
  header: {
    fontSize: 24,
    margin: '0 10px 0 40px',

    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    }
  },
  topContainer: {
    display: 'flex',
    height: 70,
    background: '#F5F6F7',
    color: '#80858C',
    fontFamily: 'Roboto-Bold',
    '& .menu-wrapper': {
      height: 70,
      paddingTop: 24,
    },
    '& .scroll-menu-arrow > div': {
      width: '2rem',
      lineHeight: '70px',
      verticalAlign: 'middle',
      textAlign: 'center',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.05)',
      }
    },
    '& .scroll-menu-arrow--disabled': {
      visibility: 'hidden',
    },
  },
  menuArrows: {
    width: 35
  },
  menuItem: {
    fontSize: 14,
    margin: '0 20px 0 0',
    paddingTop: 2,
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',

    '&.active': {
      color: '#0092E5',
      position: 'relative',
      '& .fa-angle-up': {
        color: '#0092E5',
        position: 'absolute',
        bottom: -18,
        fontSize: '1.7em',
        left: '50%',
        transform: 'translateX(-50%)',
      }
    },
    '&.done': {
      color: '#202A38',
      '& .fa-check-circle': {
        color: '#0092E5',
        fontSize: 20,
        verticalAlign: 'bottom',
        paddingRight: 5
      }
    },
  },
  buttonContainer: {
    marginLeft: 'auto',
    marginRight: 10,
    color: '#707070',
    fontFamily: 'Roboto',
    width: 200,
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
  },
  buttonCircle: {
    background: '#CCE9FA 0% 0% no-repeat padding-box',
    border: 'none',
    borderRadius: '20px',
    width: 32,
    height: 32,
    color: '#0092E5',
  },
  link: {
    margin: 0,
    position: 'relative',
    top: -10,

    '& > a': {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontFamily: 'Roboto-Bold',
      textDecoration: 'none',


      '& > span:first-child': {
        fontSize: 30,
        textDecoration: 'none',
        marginRight: 5,
        position: 'relative',
        top: 4
      },
      '& > span:last-child': {
        textDecoration: 'underline'
      }
    }
  },
  footerContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 28%, rgba(255,255,255,1) 100%)',
    padding: 35,
    minHeight: 140,
    zIndex: 2,
  },
  footerContent: {
    justifyContent: 'space-between',
    width: '100%',
    margin: '0 auto'
  },
  tabsContainerWrapper: {
    marginBottom: 140,

    [theme.breakpoints.down('sm')]: {
      marginBottom: 130
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 170
    }
  },
  tabsContainer: {
    // margin is not calculated in total width so width: 100% for grid doesn't work properly
    // use padding instead
    padding: 35,
  },
  customerInfo: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold'
  },
  customerId: {
    fontFamily: 'Roboto-Regular'
  },
  groupHeader: {
    marginTop: -8
  }
}));