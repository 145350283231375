import React from "react";
import clsx from "clsx";

import IconButton from "@material-ui/core/IconButton";
import withStyles from '@material-ui/core/styles/withStyles';

import styles from "./styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const MenuItem = props => {
  const {
    classes,
    item,
    active,
    onClick,
    bottomPlaced
  } = props;

  const handleClick = () => {
    return onClick(item);
  }

  const iconConfig = item.icon && item.icon.split(',');

  return (
    <div className={clsx(classes.container, active && classes.active, bottomPlaced && classes.bottomPlaced)}>
      <IconButton classes={{root: classes.iconButton}} onClick={handleClick}>
        <span className={classes.icon}>
          {iconConfig && <FontAwesomeIcon icon={iconConfig} />}
        </span>
      </IconButton>
    </div>
  )
};

export default withStyles(styles)(MenuItem);