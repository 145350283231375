import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  button: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,

    "&:hover": {
      cursor: 'pointer',
      backgroundColor: '#CCE9FA',
    },
  }
})

export default makeStyles(styles)