import React from "react";
import MusterDepot
  from "../../../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/components/MusterDepot";

function MusterDepotList(props){
  const {
    instrumentsHandler,
    selectedAssets,
    onInstrumentSelect,
    onSelectAll,
    activeSourceItem,
    handleDetailsClick,
  } = props;

  return <MusterDepot
    onDetailsClick={handleDetailsClick}
    instrumentsHandler={instrumentsHandler}
    onInstrumentSelect={onInstrumentSelect}
    onSelectAll={onSelectAll}
    selectedItem={activeSourceItem}
    selectedInstruments={selectedAssets}
  />
}

export default MusterDepotList;