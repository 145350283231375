const styles = theme => ({
  bgGrey: {
    backgroundColor: '#F5F6F7',
  },
  headerTitle: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    margin: '2rem 0',
    '& a': {
      fontSize: 'initial',
      margin: '0 1rem',
      fontFamily: 'Roboto-Regular'
    }
  },
  textFieldRoot: {
    marginTop: 2
  },
  labelRoot: {
    fontSize: 16,
  },
  stepInfoContainer: {
    '&:not(:last-child)':{
      borderRight: '1px solid #D8DCDF'
    }
  },
  stepInfoTitle: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    marginBottom: 16,
    '&:not(:first-of-type)': {
      marginTop: 16
    }
  },
  linkBottom: {
    marginTop: 'auto',
    color: '#0092E5',
    cursor: 'pointer',
  },
  '&.MuiContainer-root': {
    padding: '0 !important'
  },
  tableCell: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    '&:nth-of-type(1)': {
      width: 390,
    }
  },
  checkboRegularContainer: {
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Roboto-Regular !important'
    }
  },
  inputLabelRoot: {
    fontSize: 16,
    top: -7
  },
  protocolButtonContainer: {
    display: 'flex',
    marginBottom: 45,
    marginTop: 15,
    '& button': {
      marginRight: 16
    }
  }
});

export default styles;