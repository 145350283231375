import React from 'react';

import useStyles from './styles';

const MailIcon = (props) => {
  const classes = useStyles();

  return (
    <span className={classes.iconContainer}>
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10">
        <defs>
          <clipPath id="a"><rect className="a" width="13" height="10"/>
          </clipPath>
        </defs>
        <g className="b">
          <path className="c" d="M542.248,556.989q.246.165,1.487.981t1.9,1.257l.308.21q.236.162.392.261c.1.067.23.141.377.224a2.236,2.236,0,0,0,.417.186,1.21,1.21,0,0,0,.363.062h.014a1.21,1.21,0,0,0,.363-.062,2.274,2.274,0,0,0,.417-.186c.147-.083.273-.157.377-.224s.234-.153.391-.261l.309-.21,3.4-2.238a3.359,3.359,0,0,0,.885-.847A1.758,1.758,0,0,0,554,555.1a1.031,1.031,0,0,0-.345-.778,1.151,1.151,0,0,0-.816-.323H542.161a1.06,1.06,0,0,0-.86.358,1.344,1.344,0,0,0-.3.9,1.564,1.564,0,0,0,.4.94,3.514,3.514,0,0,0,.849.8Zm0,0" transform="translate(-541 -554)"/>
          <path className="c" d="M553.274,584.217q-2.379,1.6-3.613,2.486-.413.3-.671.472a4.084,4.084,0,0,1-.685.346,2.1,2.1,0,0,1-.8.177h-.014a2.1,2.1,0,0,1-.8-.177,4.112,4.112,0,0,1-.686-.346q-.257-.169-.671-.472-.98-.713-3.606-2.486a3.838,3.838,0,0,1-.733-.627v5.721a1.105,1.105,0,0,0,.341.814,1.121,1.121,0,0,0,.82.339h10.678a1.122,1.122,0,0,0,.82-.339,1.106,1.106,0,0,0,.341-.814V583.59a4.02,4.02,0,0,1-.726.627Zm0,0" transform="translate(-541 -580.464)"/>
        </g>
      </svg>
    </span>
  );
};

export default MailIcon;