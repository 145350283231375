import CommonAnalysisCard from "../../../../components/AnalysisCard";
import TimeRangesButtonsPad from "../../../../../../components/TimeRangeButtonsPad";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import {HistoricalValueChart} from "fincite-react-charts";
import {toShortGermanFormat} from "../../../../../../utils/numberFormater";
import moment from "moment";
import Switch from "../../../../../../components/AssetModal/components/Switch";
import { DEFAULT_EMPTY_SECTION_MESSAGE } from '../../../../../CustomerDashboard/constants';

class ChartPortfolioPerformance extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dateStart: undefined,
      dateEnd: undefined,
      showInvestments: false,
      performanceDataToShow: null,
      investmentDataToShow: null,
      loading: true
    }

    this.onRangeChange = this.onRangeChange.bind(this);
    this.cropData = this.cropData.bind(this);
    this.setShowInvestment = this.setShowInvestment.bind(this);
  }

  onRangeChange(dateStart, dateEnd, _) {
    this.setState({
      dateStart: dateStart,
      dateEnd: dateEnd,
    }, this.cropData)
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && this.props.data !== prevProps.data) {
      this.cropData()
    }
  }

  cropData() {

    const portfolio = this.props.data;

    if (portfolio) {

      const timeSeries = []
      const investments = []

      portfolio['historical']['portfolio'].map((value) => {
        if (this.state.dateStart && this.state.dateEnd) {
          if (moment(this.state.dateStart).isSameOrBefore(moment(value.date))) {
            timeSeries.push({
              date: value.date,
              value: value.value
            });
          }
        } else {
            timeSeries.push({
              date: value.date,
              value: value.value
            });
        }
      });

      if (this.state.showInvestments) {
        portfolio['investment_timeseries'].map((value) => {
          if (this.state.dateStart && this.state.dateEnd) {
            if (moment(this.state.dateStart).isSameOrBefore(moment(value['transaction_date']))) {
              investments.push({
                date: value['transaction_date'],
                value: value.invested_amount
              });
            }
          } else {
            investments.push({
              date: value['transaction_date'],
              value: value.invested_amount
            });
          }
        });
      }

      this.setState({
        performanceDataToShow: timeSeries,
        investmentDataToShow: investments,
        loading: false
      })
    }
  }

  setShowInvestment(checked) {
    this.setState({
      showInvestments: checked
    }, this.cropData)
  }

  renderChart() {

    const { classes } = this.props;

    const defaultChartColor = '#0596CE';
    const defaultBenchmarkColor = '#7ED2CF';
    const chartLegendStyle = {
      background: defaultChartColor
    };

    const portfolio = this.props.data;

    return <div className={classes.chartContainer}>
      <ul className={classes.legend}>
        <li className={classes.legendItem}>
          <div className={classes.bullet} style={chartLegendStyle} />
          <label>{portfolio.name}</label>
        </li>
      </ul>
      <HistoricalValueChart
        title={''}
        colors={[defaultChartColor, defaultBenchmarkColor]}
        data={[{
          name: portfolio['name'],
          color: defaultChartColor,
          portfolio: this.state.performanceDataToShow
        }, {
          name: '',
          color: defaultBenchmarkColor,
          portfolio: this.state.investmentDataToShow
        }]}
        chartOptions={{
          chart: {
            type: 'line',
            height: 300,
            style: {
              fontFamily: "\"Roboto-Regular\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif",
              fontSize: 14
            }
          },
          rangeSelector: {
            enabled: false,
            allButtonsEnabled: false,
            inputEnabled: false,
            verticalAlign: 'top',
            buttons: null,
          },
          navigator: {
            enabled: false
          },
          legend: {
            enabled: false,
          },
          scrollbar: {
            enabled: false
          },
          plotOptions: {
            area: {
              fillColor: undefined
            },
            series: {
              type: 'line',
              fillColor: undefined,
              compareBase: 0,
              showInNavigator: true,
              dataGrouping: {
                dateTimeLabelFormats: {
                  week: ["Woche vom %A, %b %e, %Y"],
                }
              }
            }
          },
          tooltip: {
            pointFormat: '<span><div style="display: inline-block; vertical-align: baseline; width: 10px; height: 10px; border-radius:50%; background-color: {series.color};" /><div style="display: inline; font-size: 14px; color: #80858C; margin-left: 15px;">{point.y} &euro;</div></span><br/>',
            valueDecimals: 2,
            split: false,
            shared: true,
            backgroundColor: 'white',
            shadow: {
              color: '#00000026',
              offsetX: 0,
              offsetY: 0,
              width: 10,
              opacity: 0.15
            },
            padding: 20,
            useHTML: true,
            shape: 'square',
            borderWidth: 0,
            borderRadius: 4,
            headerFormat: '<span style="font-size: 14px; font-family: Roboto-Bold;">{point.key}</span><br/><br/>',
            dateTimeLabelFormats: {
              day: "%b %e, %Y"
            },
            style: {
              fontFamily: '"Roboto-Regular"'
            }
          },
          yAxis: {
            opposite: false,
            align: 'right',
            labels: {
              enabled: true,
              align: 'right',
              formatter: function() {
                return toShortGermanFormat(this.value, '', '', 2, true);
              },
              style: {
                fontSize: 14
              }
            }
          },
          xAxis: {
            // min: Date.parse(portfolio['investment_period']['start']) || undefined,
            // max: Date.parse(portfolio['investment_period']['end'] ) || undefined,
            tickLength: 0,
            labels: {
              style: {
                fontSize: 14
              }
            }
          },
          responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                tooltip: {
                  positioner: function (labelWidth, labelHeight, point) {
                    return point.isHeader ? this.getPosition(labelWidth, labelHeight, point) : { x: 80, y: 30 };
                  }
                }
              }
            }]
          }
        }}
      />
    </div>
  }

  render() {
    const { classes } = this.props;
    return <CommonAnalysisCard
      title={'Historische Entwicklung und Kapitaleinsatz in Euro'}
      content={
        <>
          <div className={classes.controlsRow}>
            <TimeRangesButtonsPad
              onRangeChange={this.onRangeChange}
              disabled={this.state.loading}
            />
            <div className={classes.benchmarkWrap}>
              <div id="historical-chart-benchmark-checkbox" className={classes.switchWrapper}>
                <p className={classes.benchmarkLabel}>Kapitaleinsatz</p>
                <Switch
                    value={this.state.showInvestments}
                    classes={{
                      container: classes.switchContainer,
                      thumb: classes.thumb,
                      track: classes.track,
                      checked: classes.checked,
                      label: classes.labelSwitch
                    }}
                    handleValueChanged={(checked) => this.setShowInvestment(checked)}
                    disabled={this.state.loading}
                />
              </div>
            </div>
          </div>
          <div className={classes.container}>
            {this.state.loading ? <CircularProgress/> : this.props.error ? this.props.error : this.state.performanceDataToShow ? this.renderChart() : DEFAULT_EMPTY_SECTION_MESSAGE}
          </div>
        </>}
    />
  }
}

export default withStyles(styles)(ChartPortfolioPerformance)