import React from 'react';
import useStyles from './styles';
import {connect} from 'react-redux';
import {Container, Divider, Grid, Paper, Tab} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {PROCESS_TYPES, PRODUCT_COMPARISON_STEP} from "./constants";
import {TabContext} from "@material-ui/lab";
import Tabs from "@material-ui/core/Tabs";
import SavedComparisons from "./components/TabsContent/SavedComparisons";
import TabContent from "./components/TabsContent/TabContent";
import Comparison from "./components/TabsContent/Comparison";
import {useHistory} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateHistoryStateValue } from '../../utils/utils';
import {GroupResource} from "../../utils/api";
import {
  addShowReportingCustomersDataLoadingErrorToStore,
  addShowReportingCustomersToStore
} from "../../components/CustomersDataProvider/actions";


function ProductComparison(props) {

  const classes = useStyles();
  const history = useHistory();

  const [currentStep, setCurrentStep] = React.useState(
    // on redirect from ProductComparisonNew url contains tab_id to set proper tab
    (new URLSearchParams(props.location.search).get('tab_id') || PRODUCT_COMPARISON_STEP.PRODUCT_SELECTION)
  );

  React.useEffect(() => {
    (async () => {
      try {
        let customers = await GroupResource.at('customers/').get({all: true, show_reporting: true});

        props.addCustomersToStore(customers);
      } catch {
        props.addCustomersLoadingError();
      }
    })();
  }, [])

  React.useEffect(() => {
    if(currentStep == PRODUCT_COMPARISON_STEP.PRODUCT_SELECTION) {
      updateHistoryStateValue(history, 'title', 'Investmentvergleich - Neu');
    }
  }, [])

  const handleCurrentStepChange = (event, step) => {

    if(step == PRODUCT_COMPARISON_STEP.PRODUCT_SELECTION){
      // clears url params to open saved comparisons list instead of certain comparison
      history.replace({search: new URLSearchParams({}).toString()})
      updateHistoryStateValue(history, 'title', 'Investmentvergleich - Neu');
    }
    setCurrentStep(step)
  };

  let type = new URLSearchParams(props.location.search).get('process_type') || PROCESS_TYPES.LIST;
  const isOpen = type == PROCESS_TYPES.OPEN;
  const isComparison = currentStep === PRODUCT_COMPARISON_STEP.COMPARISON;

  // FIXME: find better approach for OPEN: now we render SavedComparisons -> Comparison to get all the data and put it into storage

  return (
    <Container className={`app-page-container`}>
      {!(isComparison && isOpen) && <Typography variant="h1" className={classes.header}>Investmentvergleich</Typography>}
      <TabContext value={currentStep}>
        <Paper square={true} classes={{ root: classes.paperRoot }} style={{margin: '24px 0', overflow: 'visible'}}>
          <Grid container className={classes.container}>
            {type == PROCESS_TYPES.LIST && (
              <Grid item xs={12}>
                <Tabs
                  value={currentStep}
                  classes={{ indicator: classes.activeTabIndicator }}
                  variant="scrollable"
                  onChange={handleCurrentStepChange}
                  TabIndicatorProps={{ children: <div /> }}
                >
                  <Tab
                    classes={{ root: classes.tab, selected: classes.tabSelected }}
                    label="Neuer Vergleich"
                    value={PRODUCT_COMPARISON_STEP.PRODUCT_SELECTION}
                  />
                  <Tab
                    classes={{ root: classes.tab, selected: classes.tabSelected }}
                    label="Gespeicherte Vergleiche"
                    value={PRODUCT_COMPARISON_STEP.COMPARISON}
                  />
                </Tabs>
                <Divider />
              </Grid>
            )}

            <>
              {currentStep === PRODUCT_COMPARISON_STEP.PRODUCT_SELECTION && (
                <TabContent title={'Neuer Investmentvergleich'}>
                  <Comparison processType={type}/>
                </TabContent>
              )}

              {isComparison && (
                <>
                  <Grid item xs={12} hidden={isOpen}>
                    <SavedComparisons />
                  </Grid>
                  {isOpen && (
                    <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100svh - 160px)'}}>
                      <CircularProgress/>
                    </Grid>
                  )}
                </>
              )}
            </>
          </Grid>
        </Paper>
      </TabContext>
    </Container>
  )
}

const mapDispatchToProps = dispatch => ({
  addCustomersToStore: customers => dispatch(addShowReportingCustomersToStore(customers)),
  addCustomersLoadingError: () => dispatch(addShowReportingCustomersDataLoadingErrorToStore())
});

export default connect(undefined, mapDispatchToProps)(ProductComparison);