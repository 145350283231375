import {makeStyles} from "@material-ui/core";

const styles = (theme) => ({

  listItemCard: {
    '&:hover': {
      boxShadow: '0 0 15px rgba(33,33,33,.5)',
      cursor: (props) => props.cursor || 'normal'
    },
    width: '100%',
    borderRadius: 2,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',
  },
  cardItem: {
    '&:first-child::after': {
      borderLeft: 'none',

      [theme.breakpoints.down('sm')]: {
        borderBottom: '1px solid #E1E3E9'
      }
    },

    '&:nth-of-type(2)::after': {
      [theme.breakpoints.down('sm')]: {
        borderLeft: 'none'
      }
    },
  },

  content: {
    '& > $item': {
      borderRight: '2px solid #E1E3E9',
      [theme.breakpoints.down('sm')]: {
        borderRight: 'none',
        borderBottom: '2px solid #E1E3E9'
      }
    },
    '& > $item:last-child': {
      borderRight: 'none',
      borderBottom: 'none'
    },
    minHeight: 147,
    minWidth: 262
  },
  item: {
    padding: '16px !important',
    display: 'flex',
    alignItems: 'top',
    '& > div': {
      width: '100%'
    }
  },
  cardContent: {
    padding: '0px !important'
  },
  contentError: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '& > p': {
      fontFamily: 'Roboto-Regular',
      fontSize: 16
    }
  },
  riskIndicator: {
    borderRight: props => `10px solid ${props.riskColor}!important`
  },
  esgIndicator: {
    borderRight: props => `10px solid ${props.esgColor}!important`
  }
})

export default makeStyles(styles)