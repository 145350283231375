import React from 'react'
import PropTypes from 'prop-types'

import {
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';

import useStyles from './styles'

function RadioButtonsSection(props) {

  const {
    options,
    value,
    flexDirection,
    onChange,
    customClasses,
  } = props;

  const classes = useStyles();

  const handleChange = (event) => {
    if (onChange) {
      onChange(+event.target.value)
    }
  }

  const disabledOptions = props.disabledOptions || [];
  
  return (
    <div className={classes.container}>
      {options && (
        <RadioGroup value={value} className={customClasses.radioGroup || classes.radioGroup} style={{flexDirection}} onChange={handleChange}>
          {options.map(option => (
            <FormControlLabel 
              key={option.value}
              value={option.value}
              label={option.name}
              disabled={disabledOptions.includes(option.value)}
              control={<Radio color="primary"/>}
              classes={{
                label: customClasses.formControlLabel || classes.formControlLabel,
                root: customClasses.formControlLabelRoot
              }}
            />
          ))}
        </RadioGroup>
      )}
    </div>
  )
}

RadioButtonsSection.propTypes = {
  /** Radio button section's options */
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  })),

  /** Radio group value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  flexDirection: PropTypes.string
}

RadioButtonsSection.defaultProps = {
  flexDirection: 'column',
  customClasses: {}
}

export default RadioButtonsSection

