import React from 'react';
import ChartSectionBordered, {
  ChartSectionPortfolioName,
  ChartSectionWrapper
} from "../ChartSectionBordered/ChartSectionBordered";
import Grid from "@material-ui/core/Grid";
import {getPortfolioTradings} from "../../../../components/TradingStore/utils";
import _ from "lodash";
import PortfolioPaymentPlans from "../../components/Widgets/components/PaymentPlans/components/PortfolioPaymentPlans";

import useStyles from './styles';
import { withEuroOrDash} from "../../../../utils/utils";
import ErrorMessage from '../ChartSectionBordered/components/ErrorMessage';
import { isExpanded } from '../../utils';

const TITLE = {
  "savings_plan": 'Sparpläne',
  "payout_plan": 'Entnahmepläne',
  "switch_plan": 'Tauschpläne'
}

const DepotSummaryNavigation = (props) => {
  const {
    depot,
    paymentPlanType
  } = props;

  const classes = useStyles();

  const paymentPlanTypeName = TITLE[paymentPlanType];

  return (
    <Grid container spacing={2} className={classes.headerValuesContainer}>
      <Grid item>
        <span className="title">Summe aller {paymentPlanTypeName}</span>
        <span className="value">{withEuroOrDash(depot.total)}</span>
      </Grid>
      <Grid item>
        <span className="title">davon aktiv</span>
        <span className="value">{withEuroOrDash(depot.total_active)}</span>
      </Grid>
    </Grid>
  )
}

const EXPANDED_KEY = {
  "savings_plan": 'paymentPlans',
  "payout_plan": 'payoutPlans',
  "switch_plan": 'switchPlans'
}

function PaymentPlanSection(props) {

  const {
    dataKey,
    data,
    dataLoading,
    dataError,
    expandedItems,
    getTradeOptions,
    expanded,
    onExpandedChange
  } = props;

  const _handleExpandedItemsChange = (id, expanded) => {
    const key = {
      "savings_plan": 'paymentPlansItems',
      "payout_plan": 'payoutPlansItems',
      "switch_plan": 'switchPlansItems'
    }[dataKey];


    onExpandedChange([key, id], expanded);
  };

  const getTradingOptions = (item) => {
    const tradingOptions = getTradeOptions && getTradeOptions(item);
    if(!!tradingOptions){

      const [dKey, sKey, onAddOption] = {
        "savings_plan": ['savingPlans', 'savingsPlanSession', 'onAddSavingPlanOption'],
        "payout_plan": ['payoutPlans', 'payoutPlanSession', 'onAddPayoutPlanOption'],
        "switch_plan": ['switchPlans', 'switchPlanSession', 'onAddSwitchPlanOption']
      }[dataKey];

      const tradeData = getPortfolioTradings(tradingOptions[dKey], item.id);
      return {
        paymentPlan: item,
        tradeAction: tradeData && tradeData.action,
        tradePlans: tradeData ? tradeData.instruments : [],
        tradeTransactions: _.get(tradingOptions[sKey], item.id),
        onAddSavingPlanOption: tradingOptions[onAddOption],
        disabled: !!tradingOptions[sKey],  // disabled in case there are Session transactions
      }
    }
  };

  return (
    <ChartSectionWrapper
      dataId={dataKey}
      title={(
        <b>{TITLE[dataKey]}</b>
      )}
      loading={dataLoading}
      error={dataError}
      displayError
      expanded={expanded}
      onExpanded={(newState) => onExpandedChange(EXPANDED_KEY[dataKey], newState)}
      content={(
        <Grid container spacing={2}>
          {(data && data.length > 0) ? data.map((portfolio) => (
            <Grid item xs={12}>
              <ChartSectionBordered
                dataId={`${dataKey}-${portfolio.id}`}
                title={(
                  <ChartSectionPortfolioName portfolio={portfolio} />
                )}
                titleControl={(
                  <DepotSummaryNavigation depot={portfolio} paymentPlanType={dataKey}/>
                )}
                showTitleControlFolded
                loading={dataLoading}
                error={dataError}
                displayError
                expanded={isExpanded(expandedItems, `${portfolio.id}`)}
                isPortfolioSection
                skipContentPadding
                borderLeftColor={portfolio.color}
                onExpanded={(newState) => _handleExpandedItemsChange(portfolio.id, newState)}
                content={(
                  <PortfolioPaymentPlans
                    key={portfolio.id}
                    data={portfolio}
                    tradingOptions={getTradingOptions(portfolio)}
                    paymentPlanType={dataKey}
                    skipMPGrouping={dataKey=='switch_plan'}
                  />
                )}
              />
            </Grid>
          )) : (
            <ErrorMessage error={'Kein Plan vorhanden.'} />
          )}
        </Grid>
      )}
    />
  )
}

PaymentPlanSection.propTypes = {}

PaymentPlanSection.propDefs = {}

PaymentPlanSection.defaultProps = {}

export default PaymentPlanSection;