import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles'

Content.propTypes = {
  text: PropTypes.string.isRequired
};

function Content(props) {

  const {
    text
  } = props

  const classes = useStyles()
  return (
    <span className={classes.text}>{ text }</span>
  );
}

export default Content;