import {makeStyles} from "@material-ui/core";

export default makeStyles(() => ({
  infoText: {
    borderLeft: '0.25rem solid #1a75b1',
    minHeight: 35,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 5,
    color: '#1a75b1',

    '& > p': {
      fontFamily: 'Roboto-Bold',
      fontStyle: 'italic',
      fontSize: 16,
      margin: 0
    },

    '& > span': {
      fontSize: 18,
      marginRight: 5,
    }
  }
}))