import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import _ from 'lodash'

import {
  Dialog,
  DialogContent,
  Grid,
  FormControlLabel,
  TextField,
  Radio,
  RadioGroup
} from '@material-ui/core'

import {KeyboardDatePicker} from "@material-ui/pickers";

import PrimaryButton from '../../../../components/Buttons/PrimaryButton'

import { CalendarIcon } from '../../../../images'

import useStyles from './styles'
import {
  MODEL_PORTFOLIO_INVESTMENT_GOAL_OPTIONS, MODEL_PORTFOLIO_KNOWLEDGE_OPTIONS,
  MODEL_PORTFOLIO_TIME_HORIZON_OPTIONS,
  MODEL_PORTFOLIO_TYPE
} from '../../constants'
import { UserUtils } from '../../../../utils/utils'
import { modelPortfolioAccessible } from '../../utils'
import SelectFormElement from "../../../RiskProfiling/components/StepContent/components/formElement/SelectFormElement";
import {isValidString} from "../../../../utils/utils";
import {ModalLoader} from "../../../VirtualPortfolioManager/modals/ModalInstruments";

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
});

function ModelPortfolioModal(props) {

  const {
    onModelPortfolioCreateClick,
    open,
    onClose,
    loading,
    errors,
    selectedModelPortfolio,
    isCreate,
  } = props;

  const classes = useStyles();

  const [startDate, setStartDate] = React.useState(props.defaultStartDate || null);
  const [modelPortfolioName, setModelPortfolioName] = React.useState(null);
  const [modelPortfolioTimeHorizon, setModelPortfolioTimeHorizon] = React.useState(null);
  const [modelPortfolioInvestmentGoal, setModelPortfolioInvestmentGoal] = React.useState(null);
  const [modelPortfolioKnowledgeAndExperience, setModelPortfolioKnowledgeAndExperience] = React.useState(null);
  const [modelPortfolioDescription, setModelPortfolioDescription] = React.useState(null);
  const [type, setType] = React.useState(MODEL_PORTFOLIO_TYPE.PERSONAL.value);

  React.useEffect(() => {
    setStartDate(props.defaultStartDate)
  }, [props.defaultStartDate]);


  React.useEffect(() => {
    if (selectedModelPortfolio) {
      setStartDate(moment(selectedModelPortfolio.tracking_start_date, 'YYYY-MM-DD'));
      setModelPortfolioName(selectedModelPortfolio.name);
      setModelPortfolioDescription(selectedModelPortfolio.description);
      setType(selectedModelPortfolio.agency && UserUtils.isChief(props.auth)
        ? MODEL_PORTFOLIO_TYPE.AGENCY.value
        : MODEL_PORTFOLIO_TYPE.PERSONAL.value);
      setModelPortfolioTimeHorizon(selectedModelPortfolio.time_horizon);
      setModelPortfolioInvestmentGoal(selectedModelPortfolio.investment_goal);
      setModelPortfolioKnowledgeAndExperience(selectedModelPortfolio.knowledge_and_experience);
    }
  }, [selectedModelPortfolio]);

  const isDataValid = () => {
    return startDate && isValidString(modelPortfolioName) && !loading
  };

  const handleModelPortfolioCreate = (update=false) => {
    let data = {
      name: modelPortfolioName,
      description: modelPortfolioDescription,
      time_horizon: modelPortfolioTimeHorizon,
      knowledge_and_experience: modelPortfolioKnowledgeAndExperience,
      investment_goal: modelPortfolioInvestmentGoal,
      tracking_start_date: startDate && startDate.format('YYYY-MM-DD'),
      is_private: type == MODEL_PORTFOLIO_TYPE.PERSONAL.value
    };

    if (onModelPortfolioCreateClick) {
      onModelPortfolioCreateClick(data, update)
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  };

  return (
    <Dialog maxWidth="md" scroll={"body"} disableBackdropClick={true} fullWidth open={open} onClose={handleClose}>
    {loading ? (
      <div style={{height: 200}}>
        <ModalLoader withMsg={true}/>
      </div>
    ) :
      <DialogContent>
        <p className={classes.modalTitle} style={{marginBottom: 20}}>{props.title}</p>
        {UserUtils.isChief(props.auth) && (
          <Grid item xs={12} style={{position: 'relative', marginBottom: 20}}>
            <Grid container>
              <Grid item>
                <p className={classes.typeLabel}>Zugriff:</p>
              </Grid>
              <Grid item style={{paddingLeft: 35}}>
                <RadioGroup value={type} onChange={(event) => setType(+event.target.value)}>
                  <FormControlLabel className={classes.radioLabel} label={MODEL_PORTFOLIO_TYPE.PERSONAL.label} value={MODEL_PORTFOLIO_TYPE.PERSONAL.value} control={<Radio color="primary" style={{padding: 5}}/>}/>
                  <FormControlLabel className={classes.radioLabel} label={MODEL_PORTFOLIO_TYPE.AGENCY.label} value={MODEL_PORTFOLIO_TYPE.AGENCY.value} control={<Radio color="primary" style={{padding: 5}}/>}/>
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.formContainer} spacing={2}>
          <Grid item sm={8} xs={12}>
            <TextField
              error={errors && !_.isString(errors) && 'name' in errors}
              value={modelPortfolioName}
              onChange={(event) => setModelPortfolioName(event.target.value)}
              fullWidth
              required
              label="Name"
              placeholder="Bitte geben Sie einen Namen für das Musterdepot ein"
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                  error: classes.inputError
                }
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                  asterisk: classes.labelAsterisk
                }
              }}
              helperText={errors && !_.isString(errors) && errors.name}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <KeyboardDatePicker
              error={errors && !_.isString(errors) && 'tracking_start_date' in errors}
              helperText={errors && !_.isString(errors) && errors.tracking_start_date}
              fullWidth
              required
              disableToolbar
              maxDate={moment()}
              variant="inline"
              format="DD.MM.YYYY"
              placeholder="TT.MM.JJJJ"
              label="Startdatum"
              value={startDate}
              disabled
              onChange={setStartDate}
              invalidDateMessage={'Falsches Datumsformat.'}
              keyboardIcon={<CalendarIcon />}
              autoOk={true}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                  error: classes.inputError
                }
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                  asterisk: classes.labelAsterisk
                }
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <SelectFormElement
              label="Kenntnisse & Erfahrungen"
              options={MODEL_PORTFOLIO_KNOWLEDGE_OPTIONS}
              value={modelPortfolioKnowledgeAndExperience || ''}
              onChange={(val) => setModelPortfolioKnowledgeAndExperience(val)}
              custom_classes={{labelRoot: 'bold'}}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <SelectFormElement
              label="Anlagehorizont"
              options={MODEL_PORTFOLIO_TIME_HORIZON_OPTIONS}
              value={modelPortfolioTimeHorizon || ''}
              onChange={(val) => setModelPortfolioTimeHorizon(val)}
              custom_classes={{labelRoot: 'bold'}}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <SelectFormElement
              label="Anlageziel"
              options={MODEL_PORTFOLIO_INVESTMENT_GOAL_OPTIONS}
              value={modelPortfolioInvestmentGoal || ''}
              onChange={(val) => setModelPortfolioInvestmentGoal(val)}
              custom_classes={{labelRoot: 'bold'}}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={errors && !_.isString(errors) && 'description' in errors}
              helperText={errors && !_.isString(errors) && errors.description}
              multiline
              fullWidth
              rows={5}
              rowsMax={5}
              value={modelPortfolioDescription}
              onChange={(event) => setModelPortfolioDescription(event.target.value)}
              label="Beschreibung"
              placeholder="Bitte geben Sie eine Beschreibung ein"
              InputProps={{
                classes: {
                  root: classes.inputTextAreaRoot,
                  focused: classes.inputFocused,
                  error: classes.inputError
                }
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                  asterisk: classes.labelAsterisk
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid className={classes.buttonContainer}>
          <PrimaryButton
            customClasses={{root: classes.stickyButtonRoot, disabled: classes.stickyButtonOutlinedDisabled}}
            text="Abbrechen"
            variant="outlined"
            onButtonClick={handleClose}
          />

          {!props.hideAsNew && (
            <PrimaryButton
              customClasses={{root: classes.stickyButtonRoot, disabled: classes.stickyButtonOutlinedDisabled}}
              text={isCreate ? "Speichern" : "Als neues Musterdepot speichern"}
              variant={isCreate ? "contained" : "outlined"}
              disabled={!isDataValid()}
              onButtonClick={() => handleModelPortfolioCreate(false)}
            />
          )}
          {!isCreate && selectedModelPortfolio && modelPortfolioAccessible(selectedModelPortfolio, props.auth) && (
            <PrimaryButton 
              customClasses={{root: classes.stickyButtonRoot, disabled: classes.stickyButtonDisabled}} 
              text={"Speichern"}
              disabled={!isDataValid() || !selectedModelPortfolio}
              onButtonClick={() => handleModelPortfolioCreate(true)}
            />
          )}
        </Grid>
      </DialogContent>
    }
    </Dialog>
  )
}

ModelPortfolioModal.propTypes = {
  /** onModelPortfolioCreateClick handler*/
  onModelPortfolioCreateClick: PropTypes.func,

  /** Flag, that indicate, is modal visible, or not */
  open: PropTypes.bool
}

ModelPortfolioModal.defaultProps = {
  title: 'Musterdepot speichern'
}

export default connect(mapStateToProps)(ModelPortfolioModal)

