import {fontSize, smFontSize} from "../../utils/constants";
import { FORM_FIELD_HEIGHT } from '../../containers/TransactionsMonitoring/components/Filters/components/common_styles';

export default (theme) => ({
  closeButton: {
    float: 'right',
    padding: 4
  },
  dialogFullWidth: {
    width: 'calc(100% - 48px)',
    margin: '0 !important'
  },
  dialogTitle: {
    padding: '8px 8px 0',
    '& span': {
      fontFamily: 'Roboto-Bold'
    }
  },
  dialogContent: {
    padding: 0,
    '& > li': {
      paddingLeft: 9
    }
  },
  expansionPannelContainer: {
    position: 'relative',
    height: 35,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      height: 35,
    },
  },
  expansionPanelRoot: {
    position: 'absolute',
    borderRadius: '2px !important',
    height: FORM_FIELD_HEIGHT,
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: 'none',
    zIndex: 2,
    backgroundColor: 'transparent !important',

    [theme.breakpoints.down('sm')]: {
      height: 35,
    },
  },
  expansionPanelExpanded: {
    height: 'auto',
    zIndex: 1000,
    transform: 'translate3d(0,0,1px)'
  },
  expansionPanelSummaryRoot: {
    border: `2px solid ${theme.palette.primary.main}`,
    minHeight: '45px !important',
    height: '45px !important',
    boxSizing: 'border-box',
    borderRadius: '2px !important',
    padding: 10,

    [theme.breakpoints.down('sm')]: {
      minHeight: '35px !important',
      height: '35px !important',
    },
  },
  expansionPanelSummaryExpanded: {
    minHeight: '45px !important',
    height: '45px !important',

    [theme.breakpoints.down('sm')]: {
      minHeight: '35px !important',
      height: '35px !important',
    },
  },
  expansionPanelSummaryContent: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
    fontSize: 18,
    padding: '0px',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    },
  },
  expansionPanelDetailsRoot: {
    border: `2px solid ${theme.palette.primary.main}`,
    boxSizing: 'border-box',
    borderRadius: '2px !important',
    padding: 0,
    backgroundColor: 'white',
    borderTop: 'none',
    flexDirection: 'column'
  },
  portfolioList: {
    padding: 0,
    width: '100%',

    '& $portfolioListItem': {
      transition: 'all',
      transitionDuration: '0.2s',
      borderBottom: '1px solid #E1E3E9',
      padding: '0'
    },
    '& $portfolioListItem:hover': {
      cursor: 'pointer',
      backgroundColor: '#EFEFF2'
    },
    '&::-webkit-scrollbar': {
      width: 3
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  portfolioListDialog: {
    '& li': {
      paddingLeft: '0 !important'
    },
  },
  alwaysScrollableList: {
    overflowY: 'auto',
    maxHeight: 'calc(80vh - 40px)',

    ['@media (max-height:900px)']: {
      maxHeight: 'calc(70vh - 40px)',
    },
  },
  scrollableList: {
  },
  portfolioListItem: {
    '& span': {
      fontFamily: 'Roboto-Regular',
      color: '#4D4F5C',
      fontSize: fontSize,

      [theme.breakpoints.down('md')]: {
        fontSize: smFontSize
      }
    },

    [theme.breakpoints.down('md')]: {
      padding: 0,
      paddingRight: 8
    },
  },
  portfolioListCheckbox: {
    '&:hover': {
      backgroundColor: 'transparent'
    },

    [theme.breakpoints.down('md')]: {
      padding: 5,
    }
  },
  portfolioListCheckboxChecked: {
    '& svg': {
      fill: `${theme.palette.primary.main} !important`
    }
  },
  itemPlaceholder: {
    width: '100%',
    height: '20px',
    backgroundColor: '#f6f7f8',
    backgroundImage: 'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: '$placeholderShimmer',
    animationTimingFunction: 'linear',
  },
  portfolioListItemPlaceholder: {
    borderBottom: '1px solid #E1E3E9',
    '&:nth-child(2n) $itemPlaceholder': {
      width: '75%'
    }
  },
  startButton: {
    fontFamily: 'Roboto-Bold',
    color: 'white',
    textTransform: 'none',
    padding: '9px 16px',
    boxShadow: 'none',
    borderRadius: 0,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      height: 35,
    },
  },
  '@keyframes placeholderShimmer': {
    from: {
      backgroundPosition: '-468px 0',
    },
    to: {
      backgroundPosition: '468px 0',
    }
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  productsComparisonListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > span:first-child': {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      '& > span:last-child': {
        display: 'block',
      }
    }
  },
  portfolioListItemAggregateSelectedCheckbox: {
    paddingLeft: 25,
    '&:hover': {
      cursor: 'auto',
      background: 'white'
    },
    '& > span': {
      color: '#4D4F5C',
      fontSize: 16,
      fontFamily: 'Roboto-Bold',
      marginLeft: 15
    }
  },
  aggregatePortfoliosSwitchContainer: {
    height: 30,
    width: 69
  },
  aggregatePortfoliosSwitchThumb: {
    height: 24,
    width: 24,
    top: 2,
    left: 2
  },
  aggregatePortfoliosSwitchLabel: {
    padding: '0 10px'
  },
  chartSectionHeaderInfoIcon: {
    marginLeft: 10,
    fontSize: 17,
    color: '#4D4F5C',
  },
  errorIcon: {
    color: '#ADB6BD',
    fontSize: 22
  }
});