import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import clsx from 'clsx';

import styles from "./styles";
import {NoDataTooltip} from "../../ExclusionCriteria/cards";
import WarningTooltip from "../../../../../../../components/WarningTooltip";


class SDGCard extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes, items, data, assetName } = this.props;
    return (
      <>
        <div className={classes.cardWrapper}>
          <div className={classes.contentWrapper}>
            {items.icon}
            <span className={classes.text}>{items.text}</span>
            <span className={classes.info}>
              <WarningTooltip title={items.info}/>
            </span>
          </div>
        </div>
        <Grid container style={{paddingTop: 28, paddingLeft: 20}} spacing={2}>
        {Object.keys(items.criterias).map((key) => (
          <Grid item>
            <NoDataTooltip
              value={data[key]}
              message={`Dieses Kriterium wird hinsichtlich ${items.text} für ${assetName} nicht explizit berücksichtigt.`}
            >
              <img alt={''} src={items.criterias[key]} className={clsx(classes.img, !data[key] && 'inactive')} />
            </NoDataTooltip>
          </Grid>
        ))}
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(SDGCard);
