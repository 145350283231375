import {Dialog, DialogContent, withStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import styles from "./styles";
import clsx from "clsx";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";


class ConfirmationDialog extends React.Component {

  render() {

    const { classes } = this.props;
    return (
      <Dialog maxWidth='sm' fullWidth open={this.props.open} onClose={this.props.onClose}>
        {this.props.title && (
          <DialogTitle>
            {this.props.title}
          </DialogTitle>
        )}
        <DialogContent className={classes.modalContent}>
          <div className={classes.messageContent}>
            {this.props.message}
          </div>
          <div className={classes.buttonsRow}>
            <Button
              className={clsx(classes.buttonCancel, classes.button)}
              disableRipple={true}
              onClick={this.props.onClose}
            >
              {this.props.rejectButtonText || 'Abbrechen'}
            </Button>
            <PrimaryButton
              text={this.props.confirmButtonText || 'Ja'}
              classes={{button: classes.button}}
              onButtonClick={this.props.confirm}
            />
          </div>
        </DialogContent>
        {this.props.loading &&
        <div className={classes.loadWrapper}>
          <CircularProgress color={'primary'}/>
        </div>}
      </Dialog>
    )
  }
}

export default withStyles(styles)(ConfirmationDialog);
