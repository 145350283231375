import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
  Checkbox,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import useStyles from './styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

function CheckboxItemsList(props) {

  const {
    items,
    loading,
    onItemSelect,
    allSelectedLabel,
    selectedItems
  } = props;

  const classes = useStyles();

  const handleItemSelect = (item, checked, all) => {
    onItemSelect && onItemSelect(item, checked, all)
  }

  const isItemSelected = (item) => {
    return !!_.find(selectedItems, i => i.id === item.id)
  }

  const renderItem = (key, value, checked, onChange, label) => (
    <MenuItem
      key={key}
      value={value}
      className={classes.menuItem}
      classes={{ selected: classes.menuItem }}
      onClick={onChange}
      disableRipple
      disableTouchRipple
    >
      <Checkbox
        color="primary"
        checked={checked}
        onChange={onChange}
        disableRipple
        disableTouchRipple
      />
      <ListItemText primary={label || allSelectedLabel}/>
    </MenuItem>
  )

  const renderItems = () => {

    if (loading) {
      return [...Array(5)].map(() => (
        <div className={classes.loadingItem}>
          <Skeleton style={{width: '100%', height: 40}}/>
        </div>
      ))
    }

    if (items) {
      return (
        <>
          {items.length > 1
            ? renderItem('all', 'all', selectedItems.length === items.length, () => handleItemSelect(null, selectedItems.length !== items.length, items))
            : null}
          {items.map(option => renderItem(option.id, option.id, isItemSelected(option), () => handleItemSelect(option, !isItemSelected(option)), option.label))}
        </>
      )
    }

    return null
  }

  return (
    <div className={classes.container}>
      { renderItems() }
    </div>
  )
}

CheckboxItemsList.propTypes = {
  /** List items */
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired
  })),

  /** Indicate, if content is loading*/
  loading: PropTypes.bool,

  onItemSelect: PropTypes.func,

  selectedItems: PropTypes.array
}

CheckboxItemsList.defaultProps = {
  selectedItems: [],
  allSelectedLabel: "Alle"
}

export default CheckboxItemsList

