import React from 'react';
import { GuideTour } from '../../components/GuideTour';
import {connect} from 'react-redux';

import {
  CircularProgress,
  Container,
  withWidth
} from '@material-ui/core';

import Navigation from './components/Navigation';
import CustomersList from './components/CustomersList';

import {
  CustomerReportSettingResource,
  CustomerResource,
  SharedSettingsResource,
} from '../../utils/api';
import {
  paginateArray,
  findMaxAggregattedValue,
  UserUtils,
} from '../../utils/utils';
import { getGuideSteps } from './guide';
import {ERROR_MODAL_MESSAGE} from '../../utils/constants';
import {RISK_FILTERING_TYPE} from '../../components/FilteringPanel/components/RiskFilter/constants';
import {
  SORTING_TYPES,
} from '../../components/FilteringPanel/components/SortingFilter/constants';
import {
  CLIENT_FILTERS,
  getSearchCustomerType,
  PERMANENT_SUITABILITY_FILTERS,
  SRI_FILTERS,
} from '../../components/FilteringPanel/components/ListSelector/constants';
import theme from '../../themes/mainTheme';
import ErrorModal from '../../components/ErrorModal';
import _ from "lodash";
import {filterCustomers, sortCustomers} from "./utils";
import {getAgencyFilterOptions} from "../../components/CustomersSelectorProviderNew/constants";
import InformClientsPdfModal from "./components/InformClientsPdfModal/InformClientsPdfModal";
import useFetchDocument from "../../hooks/useFetchDocument";
import {displayErrorSnackBar, displaySuccessSnackBar} from "../../components/SnackbarProvider/actions";
import useStyles from "./styles";
import Filters from '../TransactionsMonitoring/components/Filters/Filters';
import CircleIcon from '@material-ui/icons/Brightness1';
import {setEditorVariables} from "../GroupReporting/actions";

const mapStateToProps = (state) => ({
  guide: state.get('guide').toJS()
});


export default withWidth()(connect(mapStateToProps)((props) => {

  const {
    width,
    auth
  } = props;

  const classes = useStyles();

  const defaultClientTypeOption = getSearchCustomerType(auth);
  
  const [pdfDownloadingStatus, downloadPdf] = useFetchDocument(
    `/reports/report/risk-indicators/overview/`, 'post')
  const [customers, setCustomers] = React.useState(undefined);
  // Is required to store params, that were used to retrieve customers from end point.
  // So we could request data from end point only in case it is required
  const [customersParams, setCustomersParams] = React.useState({
    customerType: defaultClientTypeOption
  })
  const [filteredCustomers, setFilteredCustomers] = React.useState(undefined);

  const [loading, setLoading] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState(undefined);

  const [filters, setFilters] = React.useState({
    search: '',
    sortingType: SORTING_TYPES.RISK_TYPE_DESC,
    riskType: RISK_FILTERING_TYPE.ALL,
    customerType: defaultClientTypeOption,
    permanentSuitabilityType: PERMANENT_SUITABILITY_FILTERS.ALL,
    sriType: SRI_FILTERS.ENABLED,
    triggerFilters: false
  });

  const [isInformClientsModalOpen, setInformClientsModalOpen] = React.useState(false)

  React.useEffect(() => {
    handleFilterCustomers()
  }, [customers])

  React.useEffect(() => {
    fetchCustomers()
    fetchVariables()
  }, [])

  React.useEffect(() => {
    if (pdfDownloadingStatus.errors) {
      props.dispatch(displayErrorSnackBar('Fehler bei Erzeugung des PDF'))
    }
  }, [pdfDownloadingStatus.updatedAt])

  React.useEffect(() => {

    if (filters.triggerFilters) {
      if (filters.customerType.value != customersParams.customerType.value || filters.requestType != customersParams.requestType) {
        fetchCustomers()
      } else {
        handleFilterCustomers()
      }
      setFilters({...filters, triggerFilters: false })
    }

  }, [filters.triggerFilters])

  React.useEffect(() => {
    handleFilterCustomers()
  }, [filters.sortingType])

  const getFiltersQueryParams = () => {

    let _filters = {
      customer_type: _.get(filters, 'customerType.value')
    }

    if (filters.requestType == CLIENT_FILTERS.SUB_AGENCY) {
      _filters.sub_agency = filters.customerType.value
      _filters.customer_type = filters.requestType
    } else if (filters.requestType == CLIENT_FILTERS.SUB_BROKER) {
      _filters.sub_broker = filters.customerType.value
      _filters.customer_type = filters.requestType
    }

    return _filters
  };

  const fetchVariables = async () => {
    try {
      const response = await SharedSettingsResource.at('editor-variables/').get();
      props.dispatch(setEditorVariables(response));
      window.editorVariables = response;
    } catch (e) {
      props.dispatch(setEditorVariables(undefined));
      window.editorVariables = undefined;
    }
  }

  const fetchCustomers = async () => {

    setLoading(true)

    try {

      let queryParams = getFiltersQueryParams() || {};

      let response = await CustomerResource.at('risk-indicator/list/').get(queryParams);

      setCustomers([...paginateArray(response, 10)]);
      setCustomersParams({
        customerType: filters.customerType,
        requestType: filters.requestType
      })
      setFilters((filters) => ({...filters, triggerFilters: false}))
      setErrorMessage(undefined);
      setLoading(false)

    } catch (err) {
      setErrorMessage(ERROR_MODAL_MESSAGE);
      setLoading(false)
    }
  };

  const handleFilterCustomers = () => {
    if (Object.entries(filters).length !== 0) {
      let customersJoined = [].concat.apply([], customers);

      let filteredCustomers = [...filterCustomers(customersJoined, filters)];

      filteredCustomers = sortCustomers(filteredCustomers, filters.sortingType);

      setFilteredCustomers([...paginateArray(filteredCustomers, 10)]);
      setFilters((filters) => ({...filters, triggerFilters: false}))
    }
  };

  const handleErrorModalClose = () => {
    setErrorMessage(undefined);
  };

  const getMaxAggregatedValue = () => {
    let customersJoined = [].concat.apply([], customers);

    let customer = findMaxAggregattedValue(customersJoined);

    return customer ? Math.ceil(parseFloat(customer.aggregated_value)): 0;
  };

  const handleSortingTypeChange = (item) => {
    setFilters({ ...filters, sortingType: item })
  }

  const RESET_FILTERS_OPTIONS = {
    search: '',
    sortingType: SORTING_TYPES.RISK_TYPE_DESC,
    riskType: RISK_FILTERING_TYPE.ALL,
    customerType: defaultClientTypeOption,
    aggregatted: [0, getMaxAggregatedValue()],
    permanentSuitabilityType: PERMANENT_SUITABILITY_FILTERS.ALL,
    sriType: SRI_FILTERS.ENABLED
  }

  const handleApplyFilters = (updatedFilters) => {
    setFilters({ ...updatedFilters, triggerFilters: true });
  }

  const handleInformClientsButtonClick = () => {
    setInformClientsModalOpen(true)
  }

  const handleInformClientsPdfModalClose = () => {
    setInformClientsModalOpen(false)
  }

  /**
   * Trigger suitability check pdf generation.
   * @param {number} reportType Type of the report
   * @param {boolean} withAdHoc Flag, that indicate, if AdHoc report should be included
   * @param {string?} coverPageContent Content of the cover page
   * @returns {Promise<void>}
   */
  const handleInformClientsPdfGenerateClick = async (reportType, withAdHoc, coverPageContent) => {

    const requestBody = {
      report_type: reportType,
      with_ad_hoc: withAdHoc,
      cover_page_content: coverPageContent
    }

    try {

      await CustomerReportSettingResource
        .at('report/risk-indicators/suitability-check/')
        .post(requestBody)

      setInformClientsModalOpen(false)
      props.dispatch(displaySuccessSnackBar('Die Erstellung der Berichte für die Geeignetheitsprüfung wurde gestartet.'))

    } catch (errors) {
      console.log(errors)
    }

  }

  const customerWithSuitabilityCheck = React.useMemo(() => {

    if (!customers) {
      return undefined
    }

    return _.find([].concat.apply([], customers), (customer) => customer.permanent_suitability_check_enabled)

  }, [customers])

  const handleDownloadPdfClick = () => {

    const requestParams = {
      aggregated_value: [_.get(filters, 'aggregatted.0') || 0, _.get(filters, 'aggregatted.1') || getMaxAggregatedValue()],
      customer_type: _.get(filters, 'customerType.value'),
      risk_type: _.get(filters, 'riskType.value'),
      sorting_type: _.get(filters, 'sortingType.value'),
      search: filters.search,
      permanent_suitability_check_type: _.get(filters, 'permanentSuitabilityType.value'),
      sri_type: _.get(filters, 'sriType.value'),
    }

    if (filters.requestType == CLIENT_FILTERS.SUB_AGENCY) {
      requestParams.sub_agency = filters.customerType.value
      requestParams.customer_type = filters.requestType
    } else if (filters.requestType == CLIENT_FILTERS.SUB_BROKER) {
      requestParams.sub_broker = filters.customerType.value
      requestParams.customer_type = filters.requestType
    }

    downloadPdf(requestParams)
  }

  const customerTypeOptions = React.useMemo(() => {
    if (auth && auth.user) {
      return getAgencyFilterOptions(UserUtils.isChief(auth), auth.user.sub_agencies, auth.user.agency).map(a => ({
        ...a,
        id: a.value,
        name: a.description
      }));
    }
    return [];
  }, [auth])

  const renderRiskTypeContent = (option) => (
    <span className={classes.icons}>
      {option.value === RISK_FILTERING_TYPE.ALL.value ? (
        <>
          {
            [RISK_FILTERING_TYPE.RED, RISK_FILTERING_TYPE.YELLOW, RISK_FILTERING_TYPE.GREEN].map((o) => (
              <CircleIcon style={{ color: o.color, width: 13, height: 13 }}/>
            ))
          }
        </>
      ) : (
        <CircleIcon style={{ color: option.color, width: 13, height: 13 }}/>
      )}
    </span>
  );

  return (
    <React.Fragment>
       <GuideTour
          steps={getGuideSteps()}
          title="Risikoanalyse Info-Tour"
        />
      <Container className={'app-page-container'}>
        <Navigation
          onDownloadPdfClick={handleDownloadPdfClick}
          downloadButtonDisabled={pdfDownloadingStatus.loading || !(filteredCustomers || []).length}
          onInformClientsButtonClick={handleInformClientsButtonClick}
        />
        <Filters
          initValue={filters}
          defaultValue={_.cloneDeep(RESET_FILTERS_OPTIONS)}
          label="Risiko filtern"
          onSubmit={handleApplyFilters}
        >
          <Filters.InputField
            loading={loading}
            name="search"
            label="Kunde"
            placeholder="Kundenname, Kundennummer"
            onKeyDown={handleApplyFilters}
            GridProps={{
              sm: 6,
              xs: 12
            }}
          />
          <Filters.SimpleDropdown
            loading={loading}
            name="customerType"
            label="Kunden von"
            placeholder="Kunden von"
            options={customerTypeOptions}
            onChangeCallback={(setFilterFunction, item) => {
              setFilterFunction('requestType', item.type)
            }}
            GridProps={{
              sm: 6,
              xs: 12
            }}
          />
          <Filters.SimpleDropdown
            loading={loading}
            name="riskType"
            label="Risiko filtern"
            placeholder="Risiko filtern"
            options={RISK_FILTERING_TYPE}
            GridProps={{
              sm: 6,
              xs: 12
            }}
            postNameContent={(option) => renderRiskTypeContent(option)}
          />
          <Filters.SliderRangeSelect
            name="aggregatted"
            label="Anlagebetrag"
            loading={loading}
            max={getMaxAggregatedValue()}
            noValidation={true}
            GridProps={{
              sm: 6,
              xs: 12
            }}
          />
          <Filters.SimpleDropdown
            loading={loading}
            name="permanentSuitabilityType"
            label="Dauerhafte Geeignetheitsprüfung"
            placeholder="Dauerhafte Geeignetheitsprüfung"
            options={PERMANENT_SUITABILITY_FILTERS}
            GridProps={{
              md: 3,
              sm: 6,
              xs: 12
            }}
          />
          <Filters.SimpleDropdown
            loading={loading}
            name="sriType"
            label="Kunden mit Risikoprofil"
            placeholder="Kunden mit Risikoprofil"
            options={SRI_FILTERS}
            GridProps={{
              md: 3,
              sm: 6,
              xs: 12
            }}
          />
          <Filters.SubmitButton
            GridProps={{
              md: 6,
              sm: 12,
              xs: 12
            }}
            disabled={loading}
          />
        </Filters>
        {loading ? (
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400}}>
            <CircularProgress color={"primary"}/>
          </div>
        ) : (
          <>
            <Filters.SortingDropdown
              options={SORTING_TYPES}
              styles={{ marginTop: 25 }}
              value={(filters.sortingType || {}).value}
              onChange={handleSortingTypeChange}
            />
            <CustomersList customers={filteredCustomers || [] } accentColor={theme.palette.primary.main}/>
          </>
        )}

      </Container>
      <ErrorModal message={errorMessage} handleClose={handleErrorModalClose}/>
      <InformClientsPdfModal
        open={isInformClientsModalOpen}
        onClose={handleInformClientsPdfModalClose}
        customer={customerWithSuitabilityCheck}
        onPdfGenerateClick={handleInformClientsPdfGenerateClick}
      />
    </React.Fragment>
  )
}));