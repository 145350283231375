import React from 'react';
import {
  Container
} from '@material-ui/core';

import {DASHBOARD_TYPE} from './constants'
import DefaultDashboardSettings from "./DefaultDashboardSettings";
import {Prompt} from "react-router";
import PromptDialog from "../../components/PromptDialog";
import {withRouter} from "react-router-dom";


export default withRouter((props) => {

  const [redirectToPathName, setRedirectToPathName] = React.useState('');
  const [showSettingsSaveDialog, setShowSettingsSaveDialog] = React.useState(false);

  const [hasUnsavedBrokerSettings, setHasUnsavedBrokerSettings] = React.useState(false);
  const [hasUnsavedCustomerSettings, setHasUnsavedCustomerSettings] = React.useState(false);

  let leavePageConfirmation = false;
  let handleWarningModalState = (state) => {
    setRedirectToPathName(state.pathname);
    setShowSettingsSaveDialog(true);
    return leavePageConfirmation;
  };

  const handleClickNoSaveDialog = () => {
    setShowSettingsSaveDialog(false);
    leavePageConfirmation = true; // is set to true so that history push could redirect you

    if (redirectToPathName) {
      props.history.push(redirectToPathName);
    }
  };

  const hideSettingsSaveDialog = () => {
    setShowSettingsSaveDialog(false);
  };

  return (
    <React.Fragment>

      <Prompt
        when={hasUnsavedBrokerSettings || hasUnsavedCustomerSettings}
        message={handleWarningModalState}
      />

      <Container className={'app-page-container'}>
        <div style={{marginBottom: 10}}>
          <DefaultDashboardSettings
            title={'Vermittleransicht'}
            defaultSettingsType={DASHBOARD_TYPE.BROKER}
            setHasUnsavedSettings={setHasUnsavedBrokerSettings}
            newDesign={props.newDesign}
          />
        </div>

        <div>
          <DefaultDashboardSettings
            title={'Kundenansicht'}
            defaultSettingsType={DASHBOARD_TYPE.CUSTOMER}
            setHasUnsavedSettings={setHasUnsavedCustomerSettings}
            newDesign={props.newDesign}
          />
        </div>
      </Container>

      <PromptDialog
        isOpen={showSettingsSaveDialog}
        title={''}
        message={<><p>Sie haben Änderungen vorgenommen, diese aber nicht gespeichert. Wenn Sie die Seite vor Speicherung verlassen, gehen Ihre Änderungen verloren.</p><p>Sind Sie sicher, dass Sie die Seite verlassen möchten?</p></>}
        okBtnLabel={'Auf Seite bleiben'}
        cancelBtnLabel={'Seite verlassen'}
        handleYesBtn={hideSettingsSaveDialog}
        handleNoBtn={handleClickNoSaveDialog}
        handleCancel={hideSettingsSaveDialog}
      />

    </React.Fragment>
  )
});