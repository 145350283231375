import React from 'react';

import withStyles from "@material-ui/core/styles/withStyles";

import styles from './styles';
import _ from 'lodash';


const CustomerGroupInfo = (props) => {

  const {
    classes,
    entity,
  } = props;

  return (
    <div className={classes.details}>
      <h4 className={classes.name}>{entity.name}</h4>
      <p className={classes.countWrapper}>
        {entity.customers && (
          <>
            {_.isNil(entity.dynamic_filters)
              ? (<span><span className={classes.count}> {entity.customers.length} Kunde{entity.customers.length > 1 ? 'n' : ''} </span> in dieser Gruppe</span>)
              : (<span className={classes.count}>Kundengruppenselektionsart dynamisch</span>)
            }
          </>
        )}
      </p>
    </div>
  )
};

export default withStyles(styles)(CustomerGroupInfo);