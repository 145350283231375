import {
  ALL_DOCUMENT_TYPE,
  DEFAULT_DOCUMENTS_PER_PAGE,
  DOCUMENT_TYPE_BY_EXTENSION,
  OTHER_DOCUMENT_TYPE
} from "./constants";
import {getRegExSafeString, paginateArray} from "../../utils/utils";
import moment from "moment";
import {toGermanFormat} from "../../utils/numberFormater";
import _ from 'lodash';


export const getPaginatedList = (data=[], perPage=DEFAULT_DOCUMENTS_PER_PAGE) => {
  return [...paginateArray(data, perPage)]
};


export const filterBySearchString = (data = [], searchString = '') => {
  if (!searchString) {return data} // if search string is not set - do not filter

  let searchWords = searchString.split(' ')
  // fields of document to look in
  const fieldsToSearchIn = ['description', 'createdAt', 'function', 'category', 'name', 'additionalInformation.companyID']

  const isSearchWordInField = (field, searchWord) => {
    /** Returns true if searchWord is in field */

    let safeSearchWord = getRegExSafeString(searchWord)  // escapes all 'dangerous' characters in searchWord
    let regExp = RegExp(`(${safeSearchWord})`, 'i'); // i to ignore case
    return !!field && regExp.test(field);
  }

  const containsAllSearchWords = (document) => {
    /** Returns true if all search words were found in document's fields */

    for (let searchWord of searchWords){
      // if searchWord is not present in at least one of the fields -> document does not pass validation
      let searchWordIsPresent = fieldsToSearchIn.some(fieldName => {
        // set proper format for 'createdAt'
        let field = fieldName === 'createdAt' ? moment(document[fieldName]).format('DD.MM.YYYY') : _.get(document, fieldName)
        return isSearchWordInField(field, searchWord)
      })

      if(!searchWordIsPresent){ return false }
    }

    return true
  }

  return data.filter( doc => containsAllSearchWords(doc) )
}


export const filterByDocumentType = (data=[], docType='') => {
  return data.filter(doc => {
    if (docType && docType !== ALL_DOCUMENT_TYPE.value) {
      return doc.function === docType;
    }
    return true;
  })
};


export const getAllDocumentTypes = (data=[]) => {
  const prepared_data = data
  .map(doc => doc.function)
  .filter((value, index, self) => self.indexOf(value) === index) // get uniq types. indexOf returns index of first elem with value
  .sort()
  .map(value => {
    return {value, description: value}
  });

  return [ALL_DOCUMENT_TYPE, ...prepared_data]
};


export const getDocumentTypeByExtension = (extension='') => {
  if (DOCUMENT_TYPE_BY_EXTENSION[extension]) {
    return DOCUMENT_TYPE_BY_EXTENSION[extension];
  }
  return OTHER_DOCUMENT_TYPE;
};


export const formatDocumentDate = (date) => {
  if (date) {
    return moment(date).format('DD.MM.YYYY');
  }
  return '';
};


export const formatDocumentSize = (size) => {
  const bytes = +size || '';

  if (bytes) {
    const kb = bytes / 1024;
    const mb = bytes / 1024 / 1024;
    const gb = bytes / 1024 / 1024 / 1024;

    if (gb > 1) {
      return `${toGermanFormat(+gb.toFixed(2))} GB`
    }

    if (mb > 1) {
      return `${toGermanFormat(+mb.toFixed(2))} MB`
    }

    if (kb > 1) {
      return `${toGermanFormat(+kb.toFixed(2))} KB`
    }

    return `${bytes} B`;
  }
  return '';
};
