import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_7628"
            data-name="Rechteck 7628"
            width="30"
            height="30"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_7853" data-name="Gruppe 7853" transform="translate(0 0)">
        <g
          id="Gruppe_7852"
          data-name="Gruppe 7852"
          transform="translate(0 0)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Pfad_6680"
            data-name="Pfad 6680"
            d="M16.7,17.7H12.3V13.3A4.4,4.4,0,0,0,8.789,9V0H3.516V9A4.4,4.4,0,0,0,0,13.3V30H16.7a4.4,4.4,0,0,0,4.306-3.515H30V21.212H21.006A4.4,4.4,0,0,0,16.7,17.7M14.063,28.243H7.032V19.454h7.031ZM7.031,5.391H5.273V1.759H7.031ZM4.395,10.665h.879V7.149H7.031v3.515H7.91A2.64,2.64,0,0,1,10.547,13.3v.879H1.758V13.3a2.64,2.64,0,0,1,2.637-2.637M1.758,15.938h8.789V17.7H1.758Zm0,3.515H5.274v8.789H1.758Zm17.578,5.273v.879A2.64,2.64,0,0,1,16.7,28.243H15.82V19.454H16.7a2.64,2.64,0,0,1,2.637,2.637v.879h3.516v1.758Zm8.906,0H24.61V22.969h3.633Zm-17.7,1.758A2.637,2.637,0,1,0,7.91,23.848a2.64,2.64,0,0,0,2.637,2.637m0-3.516a.879.879,0,1,1-.879.879.88.88,0,0,1,.879-.879"
            transform="translate(0 0)"
            fill="#80858c"
          />
        </g>
      </g>
    </svg>
  );
};
