import React from 'react';

import useStyles from '../../tabs/styles';
import WarningTooltip from '../../../../components/WarningTooltip';


const DashboardInfoTooltip = ({title}) => {
  const classes = useStyles();

  return <WarningTooltip title={title} width={600} iconClassName={classes.chartSectionHeaderInfoIconSmall} />
};

export default DashboardInfoTooltip;