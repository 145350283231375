import React from 'react';

import {
  TextField,
  InputAdornment,
  Grid,
  Button,
  CircularProgress,
  FormHelperText
} from '@material-ui/core';

import ModalContainer from '../../../../components/ModalContainer';

import DistributionType from '../../../../containers/DashboardSettings/components/CustomersList/components/DistributionType';

import useStyles from './styles';
import ReportType from "../../../DashboardSettings/components/CustomersList/components/ReportType";
import ReportSendingDate from "../../../DashboardSettings/components/CustomersList/components/ReportSendingDate";

const SaveGroupModal = (props) => {
  const classes = useStyles();

  const {
    reportType,
    dateRange,
    distributionType,
    name,
    handleValueChanged,
    handleSaveClick,
    isSaving,
    errors
  } = props;

  return (
    <ModalContainer open={props.open} handleClose={props.handleClose} fullWidth maxWidth="md">
      <p className={classes.modalTitle}>Geben Sie einen Gruppenname und Reporting-Einstellungen für Ihre Gruppe ein:</p>
        <div style={{paddingLeft: 16, marginBottom: 15}} id="report-settings-create-group-name-tour-element">
          <TextField
            className={classes.textField}
            error={'name' in errors}
            value={name}
            label="Gruppenname"
            margin="normal"
            onChange={(event) => handleValueChanged('groupName', event.target.value)}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.inputLabelRoot,
                shrink: classes.inputLabelShrink,
                error: classes.inputLabelError
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment className={classes.inputAdornment} position="end">{name && name.length || 0}/25</InputAdornment>
            }}
            inputProps={{maxLength: 25}}
          />
          {(errors && "name" in errors) && (
            <FormHelperText error={true} className={classes.formHelperText}>
              Dieses Feld muss befüllt werden.
            </FormHelperText>
          )}
        </div>
        <Grid container>
          <Grid
            item lg={6}
            id="report-settings-create-group-period-tour-element"
            className={classes.controlSection}
          >
            <ReportSendingDate selected={dateRange} handleChanged={(value) => handleValueChanged('groupDateRange', value)}/>
          </Grid>
          <Grid
            item lg={3}
            id="report-settings-create-group-report_type-tour-element"
            className={classes.controlSection}
            style={{borderLeft: '1px solid #DADEE1'}}
          >
            <ReportType selected={reportType} handleChanged={(value) => handleValueChanged('groupReportType', value)}/>
          </Grid>
          <Grid
            item lg={3}
            id="report-settings-create-group-send-type-tour-element"
            className={classes.controlSection}
            style={{borderLeft: '1px solid #DADEE1'}}
          >
            <DistributionType
              selected={distributionType}
              disabled={true}
              handleChanged={(value) => handleValueChanged('groupDistributionType', value)}
            />
          </Grid>
        </Grid>
        <div className={classes.modalActions}>
          <Button className={classes.actionButton} color={"primary"} onClick={props.handleClose}>
            Abbrechen
          </Button>
          <Button
            id="report-settings-create-group-save-btn-modal-tour-element"
            style={{minWidth: 160}} 
            disabled={isSaving} 
            className={classes.actionButton} 
            color={"primary"} 
            variant="contained"
            onClick={handleSaveClick}
          >
            {isSaving ? (
              <CircularProgress size={15}/>
            ) : (
              "Gruppe erstellen"
            )}
          </Button>
        </div>
    </ModalContainer>
  )
};

export default SaveGroupModal;