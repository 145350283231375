import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { IconButton } from '@material-ui/core'
import { ArrowDropUp, ArrowDropDown, ZoomIn, ZoomOut } from '@material-ui/icons'
import useWindowSize from '../../../../components/WindowSizeHook'

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import useStyles from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = "/static/pdf.worker.js";


const disableElement = (tagName) => {

  let $container = document.getElementById('document-preview')

  if ($container) {

    let $inputs = $container.getElementsByTagName(tagName)
    if ($inputs) {
      for (let i = 0; i < $inputs.length; i++) {
        $inputs[i].disabled = true
      }
    }

  }
}


/**
 * Get input element, that belongs to specific annotation object.
 * Each annotation has "id" attribute.
 * This "id" value used to find input element.
 *
 * @param annotation Annotation object
 *
 * @return Input element
 */
const getAnnotationInput = (annotation) => {
  let { id } = annotation

  let $fieldSection = document.querySelector(`[data-annotation-id="${id}"]`)
  if (!$fieldSection) return

  return $fieldSection.querySelector('input')
}


/**
 * Set correct value for annotations, that are checkboxes.
 * Checkbox annotation has two values, that we need to use.
 * "fieldValue" is actually an active value.
 * "exportValue" is a value, that indicate with what value checkbox should be checked.
 * So checkbox checked if "fieldValue" == "exportValue"
 *
 * @param annotation Annotation object
 */
const handleCheckboxFieldValue = (annotation) => {

  let $fieldInput = getAnnotationInput(annotation)
  if (!$fieldInput) return

  $fieldInput.checked = annotation.fieldValue == annotation.exportValue
}

const updateAnnotationsValues = (annotations) => {
  let annotationsExists = Array.isArray(annotations) && annotations.length

  if (annotationsExists) {
    annotations.forEach(annotation => {
      if (annotation.checkBox) {
        handleCheckboxFieldValue(annotation)
      }
    })
  }
}


function ProtocolPreview(props) {

  const {
    content,
    width,
    height
  } = props;
  
  const classes = useStyles()

  const [MIN_PAGE_ZOOM, setMinPageZoom] = React.useState(415)
  const [contentPageCount, setContentPageCount] = React.useState(null);
  const [activePage, setActivePage] = React.useState(1);
  const [pageZoomSize, setPageZoom] = React.useState(MIN_PAGE_ZOOM)
  const [pageRendered, setPageRendered] = React.useState(false)
  const [pageAnnotation, setPageAnnotation] = React.useState(undefined)
  const windowSize = useWindowSize()

  React.useEffect(() => {
    if (pageRendered) {

      disableElement('input')
      disableElement('textarea')
      disableElement('select')
      
    }
  }, [pageRendered, windowSize])

  React.useEffect(() => {
    updateAnnotationsValues(pageAnnotation)
  }, [pageRendered, pageAnnotation, windowSize])

  React.useEffect(() => {
    let $documentPreviewContainer = document.getElementById('document-preview')
    if ($documentPreviewContainer && width) {
      setMinPageZoom($documentPreviewContainer.offsetWidth - 5)
      setPageZoom($documentPreviewContainer.offsetWidth - 5)
      setPageRendered(false)
    }
  }, [])
  React.useEffect(() => {
    let $documentPreviewContainer = document.getElementById('document-preview')
    if ($documentPreviewContainer && width) {
      setMinPageZoom($documentPreviewContainer.offsetWidth - 5)
      setPageZoom($documentPreviewContainer.offsetWidth - 5)
      setPageRendered(false)
    }
  }, [windowSize])

  const handleAnnotationSuccess = (annotations) => {
    setPageAnnotation(annotations)
  }

  const renderDocumentPages = () => {
    if (contentPageCount && activePage <= contentPageCount) {
      return (
        <div style={{marginBottom: 20, boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.15)'}}>
          <Page 
            key={activePage} 
            renderMode="canvas" 
            pageNumber={activePage} 
            renderTextLayer={false}
            width={pageZoomSize}
            renderAnnotationLayer={true}
            renderForms={true}
            onRenderSuccess={() => setPageRendered(true)}
            onGetAnnotationsSuccess={handleAnnotationSuccess}
          />
        </div>
      )
    }

    return [];
  };

  const handleActivePageChange = (newPage) => {
    if (newPage && newPage <= contentPageCount) {
      setActivePage(newPage)
      setPageRendered(false)
    }
  }

  const handlePageZoomSizeChange = (newSize) => {
    if (newSize >= MIN_PAGE_ZOOM) {
      setPageZoom(newSize)
      setPageRendered(false)
    }
  }


  const onDocumentLoadSuccess = ({ numPages }) => {
    setContentPageCount(numPages);
  };

  
  return (
    <div className={classes.previewContainer} style={{width: width || 420, height: height || 464}} id="document-preview">
      <div className={classes.navigationContainer}>
        <IconButton 
          className={classes.iconButton} 
          disableRipple 
          onClick={() => handleActivePageChange(activePage - 1)} 
          disabled={activePage == 1}
        ><ArrowDropUp /></IconButton>
        <IconButton 
          className={classes.iconButton} 
          disableRipple  
          onClick={() => handleActivePageChange(activePage + 1)} 
          disabled={activePage == contentPageCount}
        ><ArrowDropDown /></IconButton>
        <input 
          type="number"
          value={activePage}
          onChange={event => handleActivePageChange(+event.target.value)}
          className={classes.activePageInput}
        />
        <span className={classes.totalPagesCountSpan}>/ { contentPageCount }</span>
        <IconButton 
          className={classes.iconButton} 
          disableRipple
          onClick={() => handlePageZoomSizeChange(pageZoomSize - 100)}
          disabled={pageZoomSize == MIN_PAGE_ZOOM}
        ><ZoomOut /></IconButton>
        <IconButton
          className={classes.iconButton} 
          disableRipple 
          onClick={() => handlePageZoomSizeChange(pageZoomSize + 100)}
        ><ZoomIn /></IconButton>
      </div>
      <div className={classes.documentContainer}>
        <Document
          renderMode="svg"
          onLoadSuccess={onDocumentLoadSuccess}
          file={content}
          options={{
            cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
          }}
        >
          { renderDocumentPages() }
        </Document>
      </div>
    </div>
  )
}

ProtocolPreview.propTypes = {
  /** Protocol file content */
  content: PropTypes.any.isRequired
}

export default React.memo(ProtocolPreview)
