export default (theme) => ({
  wrapper: {
    display: 'flex',
    paddingBottom: 12,
    justifyContent: 'space-between',
  },
  labelText: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: '#4D4F5C',
  },
  helpIcon: {
    verticalAlign: 'bottom',
    fontSize: '1.1rem'
  },
  tooltip: {
    position: 'relative',
    padding: 20,
    backgroundColor: '#EFEFF2',
    color: '#4D4F5C',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.2)',
    whiteSpace: 'pre-line',
    maxWidth: 500
  }
});
