import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as ReactDOM from "react-dom";

class StickyNavigation extends Component {

  constructor(props) {
    super(props);
  }

  onWindowResize = () => {
    if (this.mainAppEl && this.el) {
      setTimeout(() => {
        this.el.style.width = `${this.mainAppEl.getBoundingClientRect().width}px`
      }, 0)
    }
  }

  componentDidMount() {
    this.el = document.getElementById('sticky_navigation_portal')
    this.mainAppEl = document.getElementById('app-main')

    if (this.mainAppEl && this.el) {
      this.el.style.width = `${this.mainAppEl.getBoundingClientRect().width}px`
    }

    this.el.style.display = 'flex'

    window.addEventListener('resize', this.onWindowResize)
  }

  componentWillUnmount() {
    if (this.el) {
      this.el.style.display = 'none'
    }

    window.removeEventListener('resize', this.onWindowResize)
  }

  render() {
    if (this.el) {
      return ReactDOM.createPortal(this.props.children, this.el)
    }

    return null
  }
}

StickyNavigation.propTypes = {};

export default StickyNavigation;
