/**
 * @typedef Instrument
 * @type {Object}
 * @property {String|Number} instrumentId
 * @property {String} tradingType
 * @property {Object} data
 */

/**
 * @typedef Portfolio
 * @type {Object}
 * @property {String|Number} portfolioId
 * @property {Object} data
 * @property {Boolean} isModelportfolio
 * @property {String} categoryName
 * @property {Boolean} isDisabled
 * @property {Array<Instrument>} instruments
 * @property {String|null} tradingType
 * @property {String|null} bankCode
 * @property {String|null} companyId
 */

/**
  * @typedef Customer
  * @type {Object}
  * @property {String} customerId
  * @property {Object} data
  * @property {Array<{Portfolio}>} portfolios
  * @property {String} tradingType
  */

import _ from 'lodash'

import {TRADING_TYPE} from "./constants";
import {
  TRADING_ACTION_PAYOUT_PLAN,
  TRADING_ACTION_SAVINGS_PLAN,
  TRADING_ACTION_SWITCH_PLAN
} from "../Charts/InstrumentsAllocationTable/constants";
import {isSubSystemEnabled} from "../../utils/utils";
import {
  getAssetInternalId,
  getAssetInternalIdForNewPaymentPlan,
  getKeyFieldValue
} from "../../containers/CustomerDashboard/utils";


const getStateCopy = (state) => {
  return [...state.get('customers').toJS()]
}

/**
 * Get tradings for customer
 *
 * @param {Array<Customer>} customers List of customers
 * @param {String} customerId Customer identifier
 * @returns
 */
export const getCustomerTradings = (customers, customerId) => {
  let customerData = _.find(customers, customer => customer.customerId == customerId)

  if (!!customerData) {
    return customerData.portfolios
  }

  return []
}

/**
 * Get trading type for customer
 *
 * @param {Array<Customer>} customers List of customers
 * @param {String} customerId Customer identifier
 * @returns {String} - Customer's trading type
 */
export const getCustomerTradingType = (customers, customerId) => {
  let customerData = _.find(customers, customer => customer.customerId == customerId)

  if (!!customerData) {
    return customerData.tradingType
  }
}

export const getPortfolioTradings = (portfolios, portfolioId) => {

  return _.find(portfolios, portfolio => portfolio.portfolioId == portfolioId)

}


/**
 * Convert customer data to format, that could be used in storage.
 *
 * @param {Object} customer Customer data
 *
 * @returns {Customer} Converted customer data
 */
const customerToStateFormat = (customer) => {
  return {
    customerId: customer.customer_id,
    // data: {...customer},
    portfolios: [],
    tradingType: TRADING_TYPE.NORMAL
  }
}

/**
 * Convert list of components to the instruments format, that could be used in redux store.
 *
 * @param {Array<Object>} components List of portfolio components
 *
 * @param tradingType - Buy / Sell / Switch / PaymentPlan / Payout plan
 * @param savingPlanAction - edit / delete
 * @return {Array<Instrument>} List of instruments in Redux storage format.
 * */
const portfolioComponentsToInstrumentsStateFormat = (components, tradingType, savingPlanAction=undefined) => {

  return _.filter(components, component => !!component.isin)
      .map(component => {

        let otherValues = {}
        if (isPaymentPlanType(tradingType)) {
          otherValues.action = savingPlanAction;
        }

        return instrumentToStateFormat(component, tradingType, otherValues)
      })
};


/**
 * Convert portfolio data to format, that could be used in storage.
 *
 * @param {Object} portfolio Portfolio data
 * @param {String|Number|null} tradingType Trading type
 *
 * @returns {Portfolio} Converted portfolio data
 */
const portfolioToStateFormat = (portfolio, tradingType=undefined, withInstruments=false, savingPlanAction=undefined) => ({
  portfolioId: portfolio.id,
  data: {...portfolio},
  isModelportfolio: portfolio.isModelportfolio,
  isETF: portfolio.isETF,
  isDisabled: portfolio.isDisabled,
  instruments: !withInstruments ? [] : portfolioComponentsToInstrumentsStateFormat(portfolio.components, tradingType, savingPlanAction),
  categoryName: portfolio.categoryName,
  bankCode: portfolio.bankCode,
  companyId: portfolio.companyId,
  tradingType
})


/**
 * Convert instrument data to format, that could be used in storage.
 *
 * @param {Object} instrument Instrument data
 * @param {String} tradingType Trading type
 *
 * @returns {Instrument} Converted instrument data
 */
export const instrumentToStateFormat = (instrument, tradingType, otherValues={}, instrumentIdKey='isin') => {

  return {
    instrumentId: getKeyFieldValue(instrument, instrumentIdKey),
    tradingType,
    ...otherValues,
    data: {...instrument}
  }
}

export const isPaymentPlanType = (tradingType) => {
  return [TRADING_ACTION_SAVINGS_PLAN, TRADING_ACTION_PAYOUT_PLAN, TRADING_ACTION_SWITCH_PLAN].includes(tradingType)
};


/**
 * Add instrument to trading options.
 *
 * @param {Object} state Redux storage state
 * @param {Object} customer Customer data
 * @param {Object} portfolio Portfolio data
 * @param {Object} instrument Instrument data
 * @param {String} tradingType Trading type
 */
export const addInstrumentToState = (state, customer, portfolio, instrument, tradingType, savingPlansAction) => {
  let stateCopy = getStateCopy(state);

  let customerTradingData = _.find(stateCopy, c => c.customerId == customer.customer_id);
  if (!customerTradingData) {
    customerTradingData = customerToStateFormat(customer);
    stateCopy.push(customerTradingData)
  }

  /** It is always normal trading, if instrument configured */
  customerTradingData.tradingType = TRADING_TYPE.NORMAL

  let portfolioTradingData = _.find(customerTradingData.portfolios, p => p.portfolioId == portfolio.id);
  if (!portfolioTradingData) {
    portfolioTradingData = portfolioToStateFormat(portfolio);
    customerTradingData.portfolios.push(portfolioTradingData)
  }

  const isPaymentPlan = isPaymentPlanType(tradingType);

  let instrumentKeyField = getAssetInternalId;
  if (isPaymentPlan) {
    instrumentKeyField = savingPlansAction == 'create'
      ? (_data) => getAssetInternalIdForNewPaymentPlan(_data, tradingType)
      : 'payment_id';
  }

  let instrumentsData = instrument;
  if (typeof instrument === 'object') {
    instrumentsData = [instrument]
  }

  instrumentsData.forEach((inst) => {
    let instrumentTradingData = _.find(portfolioTradingData.instruments, i => i.instrumentId == getKeyFieldValue(inst, instrumentKeyField));
    if (!instrumentTradingData) {
      let otherValues = {};
      if (isPaymentPlan) {
        otherValues.action = savingPlansAction;
      }

      if (tradingType == TRADING_ACTION_SWITCH_PLAN && !_.isEmpty(inst.components)) {
        inst.components = inst.components.map((component) => instrumentToStateFormat(component, undefined, otherValues, instrumentKeyField))
      }

      portfolioTradingData.instruments.push(instrumentToStateFormat(inst, tradingType, otherValues, instrumentKeyField))
    } else {
      instrumentTradingData.tradingType = tradingType;
      if (isPaymentPlan) {
        instrumentTradingData.action = savingPlansAction;
      }
    }
  })

  // if portfolio is empty remove it
  if (portfolioTradingData.instruments.length === 0) {
    customerTradingData.portfolios = _.filter(customerTradingData.portfolios, portfolio => portfolio.portfolioId != portfolioTradingData.portfolioId)
  }


  return stateCopy

};


/**
 * Add portfolio to trading options.
 *
 * @param {Object} state Redux storage state
 * @param {Object} customer Customer data
 * @param {Object|Array<Object>} portfolio Portfolio data
 * @param {String} tradingType Trading type
 */
 export const addPortfolioToState = (state, customer, portfolio, tradingType, savingPlanAction=undefined, removeOldPortfolios=false, updateCustomerTradingType=true) => {
  let stateCopy = getStateCopy(state);
  const newTrading = _.isNil(isPaymentPlanType(tradingType) ? savingPlanAction : tradingType);

  let customerTradingData = _.find(stateCopy, c => c.customerId == customer.customer_id);
  if (!customerTradingData) {
    customerTradingData = customerToStateFormat(customer);
    stateCopy.push(customerTradingData)
  }

  if (updateCustomerTradingType) {
    customerTradingData.tradingType = newTrading ? TRADING_TYPE.NEW_BUY : TRADING_TYPE.NORMAL
  }

  if (removeOldPortfolios) {
    customerTradingData.portfolios = []
  }

  let portfolioTradingData = _.find(customerTradingData.portfolios, p => p.portfolioId == portfolio.id);
  if (!portfolioTradingData) {
    let portfolioData = Array.isArray(portfolio) ? portfolio : [portfolio]
    portfolioData.forEach(p => {

      // TODO: Refactor this part in future.
      let portfolioDataExists = !!_.find(customerTradingData.portfolios, pt => pt.portfolioId == p.id);
      !portfolioDataExists && customerTradingData.portfolios.push(portfolioToStateFormat(p, tradingType, false, savingPlanAction))
    })
  } else {
    portfolioTradingData.tradingType = tradingType
  }

  return stateCopy

};


/**
 * Remove instrument from storage
 *
 * @param {Object} state Redux storage state
 * @param {String} customerId Customer identifier
 * @param {String} portfolioId Portfolio identifier
 * @param {String} instrumentId Instrument identifier
 */
export const removeInstrumentFromState = (state, customerId, portfolioId, instrumentId) => {

  let stateCopy = getStateCopy(state);

  for (let customerIndex = 0; customerIndex < stateCopy.length; customerIndex++) {
    if (stateCopy[customerIndex].customerId == customerId) {

      for (let portfolioIndex = 0; portfolioIndex < stateCopy[customerIndex].portfolios.length; portfolioIndex++) {
        if (stateCopy[customerIndex].portfolios[portfolioIndex].portfolioId == portfolioId) {

          stateCopy[customerIndex].portfolios[portfolioIndex].instruments = _.filter(
            stateCopy[customerIndex].portfolios[portfolioIndex].instruments,
            instrument => instrument.instrumentId != instrumentId);
        }
      }

      for (let portfolioIndex = 0; portfolioIndex < stateCopy[customerIndex].portfolios.length; portfolioIndex++) {
        // if portfolio is empty remove it
        if (stateCopy[customerIndex].portfolios[portfolioIndex].instruments.length === 0 && !stateCopy[customerIndex].portfolios[portfolioIndex].tradingType){
          stateCopy[customerIndex].portfolios.splice(portfolioIndex, 1);
        }

      }
    }
  }
  return stateCopy;
}

/**
 * Update instrument in storage
 *
 * @param {Object} state Redux storage state
 * @param {String} customerId Customer identifier
 * @param {String} portfolioId Portfolio identifier
 * @param {Object} tradingData Instrument trading data
 */
export const updatePortfolioInState = (state, customerId, portfolioId, tradingData, portfolioData) => {

  let stateCopy = getStateCopy(state);

  let customerTradingData = _.find(stateCopy, c => c.customerId == customerId);

  if (!!customerTradingData) {
    let portfolioTradingData = _.find(customerTradingData.portfolios, p => p.portfolioId == portfolioId);
    if(!!portfolioTradingData){
      Object.keys(tradingData).map(key => {
        portfolioTradingData[key] = tradingData[key];
      })
      if (!!portfolioData) {
        Object.keys(portfolioData).map(key => {
          portfolioTradingData.data[key] = portfolioData[key];
        })
      }
    }
  }

  return stateCopy
};

/**
 * Remove portfolio from storage
 *
 * @param {Object} state Redux storage state
 * @param {String} customerId Customer identifier
 * @param {String} portfolioId Portfolio identifier
 */
 export const removePortfolioFromState = (state, customerId, portfolioId) => {

  let stateCopy = getStateCopy(state)

  for (let customerIndex = 0; customerIndex < stateCopy.length; customerIndex++) {
    if (stateCopy[customerIndex].customerId == customerId) {
      const portfolioData = _.find(
        stateCopy[customerIndex].portfolios, (portfolio) => portfolio.portfolioId == portfolioId) || {};
      // in case MP does not have transactions inside instruments - delete whole portfolio;
      if (_.isEmpty(portfolioData.instruments)) {
        stateCopy[customerIndex].portfolios = _.filter(stateCopy[customerIndex].portfolios, portfolio => {
          if (portfolio.portfolioId != portfolioId) return true;
          return !_.isEmpty(portfolio.instruments);
        })
      } else {
        portfolioData.tradingType = undefined;
      }
    }
  }

  return stateCopy

}

/**
 * Remove all portfolios from storage
 *
  @param {Object} state Redux storage state
  @param {String} customerId Customer identifier
*/
export const removeAllPortfoliosFromState = (state, customerId) => {
  let stateCopy = getStateCopy(state)

  let customerTradingData = _.find(stateCopy, c => c.customerId == customerId);
  if (customerTradingData) {
    customerTradingData.portfolios = []
  }

  return stateCopy
}

export const isTradingEnabled = () => {
  return isSubSystemEnabled('trading')
};

export const isProfessionalViewAssetsEnabled = () => {
  return isSubSystemEnabled('professional_view_assets')
};

export const isSavingsPlansEnabled = () => {
  return isSubSystemEnabled('savings_plans')
};