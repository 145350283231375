import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container:{
    width:'100%',
    height:'100%',
    display:'flex',
    flexDirection:'column'
  },
  title:{
    fontWeight:700,
    marginBottom:30,
  },
  subTitle:{
    maxWidth:'650px',
    marginBottom:30,
  },
  cards:{
    display:'flex',
    gap:'16px',
    width:'100%',
    justifyContent:"center"
  },
  buttonGroup:{
    marginTop:'auto',
    marginBottom:'60px',
    width:'100%',
    display:'flex',
    justifyContent:'space-between',
    alignSelf:'flex-end'
  }
}))