import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="30"
      viewBox="0 0 26 30"
    >
      <g
        id="Gruppe_622"
        data-name="Gruppe 622"
        transform="translate(-496.754 -276.875)"
      >
        <path
          id="Pfad_1434"
          data-name="Pfad 1434"
          d="M566.151,349.611l-2.253,3.956a2.667,2.667,0,0,0-.347,1.317,2.6,2.6,0,1,0,5.2,0,2.667,2.667,0,0,0-.347-1.317Zm0,6.152a.882.882,0,0,1-.752-1.317l.752-1.32.752,1.32a.882.882,0,0,1-.752,1.317Z"
          transform="translate(-56.397 -61.251)"
          fill="#fff"
        />
        <path
          id="Pfad_1435"
          data-name="Pfad 1435"
          d="M496.754,278.633h1.733V291h-1.733v1.758h1.733v12.363h-1.733v1.758h26v-1.758h-1.733V292.754h1.733V291h-1.733V278.633h1.733v-1.758h-26Zm3.467,0h19.067v1.758H500.221Zm0,3.516h19.067V291h-2.819a6.911,6.911,0,0,0-13.428,0h-2.819Zm14.733,10.605a5.2,5.2,0,1,1-5.2-5.273A5.273,5.273,0,0,1,514.954,292.754Zm-14.733,0h2.6a6.935,6.935,0,1,0,13.867,0h2.6V301.6H500.221Zm0,10.605h19.067v1.758H500.221Z"
          transform="translate(0)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
