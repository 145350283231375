const key_figures_overview_1 = {
  selector: '#kfo-user-info-tour-element',
  key: 'other_assets_customer_info'
};
const key_figures_overview_2 = {
  selector: '#kfo-invested-amount-tour-element',
  key: 'other_assets_customer_invested_amount'
};
const key_figures_overview_3 = {
  selector: '#kfo-long-term-return-tour-element',
  key: 'other_assets_customer_return',
};
const key_figures_overview_4 = {
  selector: '#kfo-total-invested-amount-tour-element',
  key: 'other_assets_customer_total_invested_amount',
};

export const getKeyFiguresChapterSteps = () => {
  return [
    key_figures_overview_1,
    key_figures_overview_2,
    key_figures_overview_3,
    key_figures_overview_4
  ];
};
