import React from 'react';

export const PRODUCT_COMPARISON_STEP = {
  PRODUCT_SELECTION: '0',
  COMPARISON: '1'
}

export const PRODUCT_TYPE = {
  ASSET: 0,
  MUSTERDEPOT: 1,
  MODEL_PORTFOLIO: 2,
  PRIVATE_INVESTMENT: 3,
  CUSTOMER_PORTFOLIO: 4
}

export const PRODUCTS_TO_COMPARE_MAX_COUNT = 10;

export const EXTEND_COMPARISON_WITH_PRODUCTS_STORAGE_KEY = 'EXTEND_COMPARISON_WITH_PRODUCTS';

export const PERFORMANCE_CHART_INFO_ICON_TEXT = (
  <>
    <p>Für Instrumente mit einer unterlegten Ein- und Auszahlungsstruktur (z.B. reale Portfolios) wird zur Vergleichbarkeit von Anlagen innerhalb des Performance Charts die “Zeitgewichtete Rendite” verwendet. Dies ist die Standardmethode, um unabhängig von Zeitpunkt und Höhe von Einzahlungen und Auszahlungen die reine Wertentwicklung eines Portfolios für einen beliebigen Zeitraum mit Fonds, alternativen Anlagen oder Indizes zu vergleichen. Fonds hingegen werden mit der Geldgewichteten Renditeberechnung dargestellt.</p>
  </>
)

export const RISK_RETURN_CHART_INFO_ICON_TEXT = (
  <p>Gegenüberstellung von Rendite (annualisierte Performance) und Risiko (Volatilität) der ausgewählten Instrumente im gewählten Zeitraum. Instrumente, deren letzter Preis länger als 6 Monate zurückliegt, oder für welche nicht genug Preisdaten aus der gewählten Periode zur Verfügung stehen werden von den Berechnungen ausgeschlossen. Für Depots wird dabei auf die zeitgewichtetete Rendite zurückgegriffen.</p>
)

export const CORRELATION_MATRIX_CHART_INFO_TEXT = (
  <p>Die Bandbreite von Korrelationswerten liegt zwischen -1 und 1. Eine Korrelation von 1 bedeutet, dass sich zwei Instrumente in genau die gleiche Richtung bewegen, während eine Korrelation von -1 anzeigt, dass sich zwei Instrumente in exakt die entgegengesetzte Richtung bewegen. Eine Korrelation von 0 bedeutet, dass es keine Beziehung zwischen den Renditen dieser beiden Instrumente gibt. Je niedriger die Korrelation, desto höher der Diversifikationsvorteil.</p>
)

export const PERFORMANCE_CHART_MISSING_DATA_INFO_TEXT = 'Für dieses Instrument bzw. Depot sind Preisdaten nicht für den gesamten gewählten Zeitraum verfügbar.';

export const PRODUCT_SELECTION_TIME_SELECTOR_DATE_CHANGED = 'PRODUCT_SELECTION_TIME_SELECTOR_DATE_CHANGED';
export const PRODUCT_COMPARISON_SET_COMPARISON = 'PRODUCT_COMPARISON_SET_COMPARISON';

export const NEW_COMPARISON_STORAGE_KEY = 'newComparison';
export const EXISTING_COMPARISON_STORAGE_KEY = 'existingComparison';
export const COULD_NOT_GET_PRODUCT_DETAILS = 'Fehler beim Laden der Produktdetails'
export const PROCESS_TYPES = {
  LIST: 0,
  EDIT: 1,
  COPY: 2,
  OPEN: 3,
  EXTEND: 4,
  DELETE: 5
};
export const LIST_PROCESS = {
  type: PROCESS_TYPES.LIST,
  comparison: undefined
};