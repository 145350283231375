export const BUSINESS_CASE_TYPE = {
  INTERNAL: 1,
  ONBOARDING: 2
}

export const ONBOARDING_BUSINESS_CASE_STATUS = {
  0: 'Nur offline übertragbar',
  1: 'Gestartet',
  // 2: 'Warten auf das Hochladen Documents', commented in BCA-7246 because this status is not used by our app, only by Connect
  3: 'Warten auf Unterschrift',
  4: 'Warten auf Bank',
  5: 'Vorgang abgebrochen',
  6: 'Fehler beim Verarbeiten',
  7: 'Erfolgreich abgeschlossen',
  8: 'Der Prozess konnte nicht vollständig abgeschlossen werden',
  9: 'Dokumentenübermittlung an Depotbank'
}

export const BUSINESS_CASE_FINISHED_STATUS_VALUE = 3