import {
  closeSeriesDropdown,
  openMenu,
  openSeriesDropdown
} from "../../../components/Header/actions";
import {mdScreenStepsSizes} from "../../App/constants";

const navigation_1 = {
  selector: '#logout-button-tour-element',
  key: 'report_settings_navigation_logout',
  position: 'bottom',
};
const navigation_1_mobile = {
  ...navigation_1,
  selector: '#logout-button-tour-element-side-menu',
  dispatchFn: [openMenu]
};
const navigation_2 = {
  selector: '#portfolio-dashboard-tour-element',
  key: 'report_settings_navigation_dashboard',
  dispatchFn: [closeSeriesDropdown],
  position: 'bottom',
};
const navigation_2_mobile = {
  ...navigation_2,
  selector: '#dashboard-and-assets-buttons-tour-element-side-menu',
  dispatchFn: [openMenu]
};
// const navigation_3 = {
//   selector: '#risk-dashboard-tour-element',
//   key: 'report_settings_navigation_risk_dashboard',
//   dispatchFn: [closeSeriesDropdown],
//   position: 'bottom',
// };
// const navigation_3_mobile = {
//   ...navigation_3,
//   selector: '#risk-dashboard-side-menu-tour-element',
//   dispatchFn: [openMenu]
// };
const navigation_4 = {
  selector: '#series-reporting-button',
  key: 'report_settings_navigation_reporting',
  dispatchFn: [openSeriesDropdown],
  position: 'bottom',
};
const navigation_4_mobile = {
  ...navigation_4,
  selector: '#series-reporting-button-side-menu',
  dispatchFn: [openMenu]
};

export const getNavigationChapterSteps = (width) => {
  return mdScreenStepsSizes.includes(width) ? [
    navigation_1_mobile,
    navigation_2_mobile,
    // navigation_3_mobile,
    navigation_4_mobile
  ] : [
    navigation_1,
    navigation_2,
    // navigation_3,
    navigation_4
  ];
};