import React from "react";

export default () => {
  return (
    <svg
      id="Gruppe_8199"
      data-name="Gruppe 8199"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_8057"
            data-name="Rechteck 8057"
            width="30"
            height="30"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_8183" data-name="Gruppe 8183" clip-path="url(#clip-path)">
        <path
          id="Pfad_7826"
          data-name="Pfad 7826"
          d="M14.037,26.539h-2.8V22.6h1.888c0-.033,0-.066,0-.1a9.434,9.434,0,0,1,.118-1.487H9.653v3.9a1.621,1.621,0,0,1-3.242,0V1.585H20.094a1.622,1.622,0,0,1,1.62,1.621V13.16c.259-.022.521-.035.786-.035s.536.014.8.036V3.206A3.209,3.209,0,0,0,20.094,0H3.205A3.209,3.209,0,0,0,0,3.206V6.314H4.825V24.92a3.207,3.207,0,0,0,3.2,3.2H15a9.386,9.386,0,0,1-.96-1.584M4.825,4.728H1.586V3.206a1.62,1.62,0,1,1,3.24,0ZM22.5,15A7.5,7.5,0,1,0,30,22.5,7.5,7.5,0,0,0,22.5,15m0,13.125a5.616,5.616,0,0,1-4.58-8.879l7.834,7.834A5.587,5.587,0,0,1,22.5,28.125m4.579-2.371L19.246,17.92a5.616,5.616,0,0,1,7.834,7.834M9.382,11.28H7.9V5.107H9.382Zm2.542-1.145h2.591V11.28H10.437V5.107h1.488Zm8.3-1.814a3.644,3.644,0,0,1-.335,1.6,2.476,2.476,0,0,1-.952,1.071,2.651,2.651,0,0,1-1.406.375,2.689,2.689,0,0,1-1.4-.362,2.471,2.471,0,0,1-.952-1.037,3.536,3.536,0,0,1-.358-1.548V8.07a3.651,3.651,0,0,1,.333-1.6A2.466,2.466,0,0,1,16.111,5.4a2.67,2.67,0,0,1,1.414-.377,2.643,2.643,0,0,1,1.4.373,2.518,2.518,0,0,1,.956,1.062,3.548,3.548,0,0,1,.345,1.58Zm-1.513-.259a2.6,2.6,0,0,0-.308-1.4.985.985,0,0,0-.88-.477q-1.119,0-1.183,1.678l0,.454a2.671,2.671,0,0,0,.3,1.4.985.985,0,0,0,.895.487.973.973,0,0,0,.869-.479,2.6,2.6,0,0,0,.309-1.378Z"
          fill="#80858c"
        />
      </g>
    </svg>
  );
};
