import React from 'react'

import useStyles from './styles'
import {Dialog, DialogContent, Grid, RadioGroup} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import {PrimaryButton} from "../../../../components/Buttons";
import {INFORM_CLIENTS_RISK_PDF_TYPES} from "../../constants";
import {
  HIGH_RISK_INDICATORS_AD_HOC_PDF_EXPORTING_MODAL_UID,
  HIGH_RISK_INDICATORS_PDF_EXPORTING_MODAL_UID, LOW_RISK_INDICATORS_AD_HOC_PDF_EXPORTING_MODAL_UID,
  LOW_RISK_INDICATORS_PDF_EXPORTING_MODAL_UID
} from "../../../CustomerDashboard/components/PdfExportingModal/constants";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from "@material-ui/core/Card";
import _ from "lodash";
import DialogActions from "@material-ui/core/DialogActions";
import EditorSettingsSwitch from "../../../GroupReporting/components/EditorStepContent/components/EditorSettingsSwitch";
import RichTextEditor from "../../../GroupReporting/components/EditorStepContent/components/RichTextEditor";
import CoverPageDocument from "../../../GroupReporting/components/PreviewStepContent/components/CoverPageDocument";

function InformClientsPdfModal({open, onClose, customer, onPdfGenerateClick, editorVariables}) {

  const classes = useStyles()

  const [showTextEditor, setShowTextEditor] = React.useState(true);
  const [editorEnabled, setEditorEnabled] = React.useState(true);
  const [pdfType, setPdfType] = React.useState(INFORM_CLIENTS_RISK_PDF_TYPES[0].value.toString());
  const [includeAdHoc, setIncludeAdHoc] = React.useState(true);
  const [editorValue, setEditorValue] = React.useState(null);
  const [coverPageData, setCoverPageData] = React.useState(undefined);

  React.useEffect(() => {
    // update coverPageData ONLY when switch to preview and message changed
    if(editorEnabled && !showTextEditor && _.get(coverPageData, 'cover_message_content') !== editorValue){
      setCoverPageData({
        force_fetch_cover: true,
        customer_id: customer && customer.customer_id,
        cover_message_enabled: true,
        cover_message_content: editorValue
      })
    }
  }, [showTextEditor, editorEnabled]);


  const handleClose = () => {
    onClose && onClose()
  }

  const toggleEditorEnabled = () => {
    setEditorEnabled((enabled) => !enabled)
  }

  const toggleShowTextEditor = () => {
    setShowTextEditor((shown) => !shown)
  }

  const toggleIncludeAdHoc = () => {
    setIncludeAdHoc((adHocIncluded) => !adHocIncluded)
  }

  const handlePdfTypeChange = (_, newType) => {
    setPdfType(newType)
  }

  const handleEditorValueChange = (value) => {
    if (value !== editorValue) {
      setEditorValue(value);
    }
  };

  const handlePdfGenerateClick = () => {
    onPdfGenerateClick && onPdfGenerateClick(pdfType, includeAdHoc, editorValue)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.dialogRoot
      }}
      maxWidth="lg"
      PaperProps={{
        style: {
          width: 900
        }
      }}
    >
      <DialogTitle
        classes={{
          root: classes.dialogTitleRoot
        }}
      >
        Welche Kunden möchten Sie informieren?
      </DialogTitle>
      <DialogContent classes={{root: classes.dialogContentRoot}}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <RadioGroup value={pdfType} onChange={handlePdfTypeChange}>
              {INFORM_CLIENTS_RISK_PDF_TYPES.map((option) => (
                <FormControlLabel
                  key={option.value}
                  classes={{
                    label: classes.checkboxLabel,
                    root: classes.checkboxFormControlLabel
                  }}
                  value={option.value.toString()}
                  control={<Radio color="primary"/>}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={12} md={5}>
            <div className={classes.checkboxWrap}>
              <EditorSettingsSwitch
                enabled={includeAdHoc}
                label={'Vermögensübersicht'}
                description={'Blenden Sie diese Funktion aus, um der Bericht ohne Vermögensübersicht zu senden.'}
                handleValueChanged={toggleIncludeAdHoc}
                classes={{
                  container: classes.editorSwitchContainer,
                  headerWrapper: classes.headerWrapper,
                  switch: classes.switch
                }}
              />
            </div>
            {editorEnabled && (
              <PrimaryButton
                text={showTextEditor ? 'Vorschau' : 'Vorschau beenden'}
                onButtonClick={toggleShowTextEditor}
              />
            )}
          </Grid>
        </Grid>
        <div hidden={!editorEnabled} className={classes.itemContainer}>
          <div hidden={!showTextEditor}>
            <Card className={classes.textEditorWrapper}>
              <RichTextEditor
                content={editorValue}
                handleChange={handleEditorValueChange}
                enabled={editorEnabled}
                useLatestTemplate
                uid={!_.isNil(pdfType) && pdfType == INFORM_CLIENTS_RISK_PDF_TYPES[0].value
                  ? includeAdHoc
                    ? LOW_RISK_INDICATORS_AD_HOC_PDF_EXPORTING_MODAL_UID
                    : LOW_RISK_INDICATORS_PDF_EXPORTING_MODAL_UID
                  : includeAdHoc
                    ? HIGH_RISK_INDICATORS_AD_HOC_PDF_EXPORTING_MODAL_UID
                    : HIGH_RISK_INDICATORS_PDF_EXPORTING_MODAL_UID}
              />
            </Card>
          </div>
          <div hidden={showTextEditor}>
            <CoverPageDocument
              customer={coverPageData}
              reportType={13}
              useDummyCustomer={!customer}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          text='Abbrechen'
          variant='text'
          onButtonClick={handleClose}
        />
        <PrimaryButton
          text='PDF exportieren'
          onButtonClick={handlePdfGenerateClick}
        />
      </DialogActions>
    </Dialog>
  )

}

export default InformClientsPdfModal