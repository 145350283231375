import React from 'react'
import clsx from "clsx";

import moment from 'moment';

import {
  Grid
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab'

import useStyles, {useProfileDetailsStyles} from './styles';

import {
  Widget,
  WidgetHeader,
  WidgetBody
} from '../../../../Modelportfolios/components';
import {
  MODEL_PORTFOLIO_KNOWLEDGE,
  MODEL_PORTFOLIO_TIME_HORIZON,
  MODEL_PORTFOLIO_INVESTMENT_GOAL,
} from "../../../constants";
import {avgSRRI, maxSRRI} from "./utils";
import InvestmentAmount from "../../../../../components/InvestmentDetails/components/InvestmentAmount";
import withProfileDetailsData from "../../../../../components/ProfileDetailsDataProvider";
import {LongTermReturn} from "../../../../../components/InvestmentDetails/components/LongTermReturn";
import {TotalReturn} from "../../../../../components/InvestmentDetails/components/TotalReturn";
import {DeleteIcon, EditIcon} from "../../../../../images";
import Tooltip from "../../../../../components/Tooltip";

function ModelPortfolioInvestmentDetails(props) {
  const classes = useStyles();
  const customClasses = useProfileDetailsStyles();

  return (
    <Grid item xs={12}>
      <Grid container spacing={1} className={classes.leftContainer}>
        <Grid item xs={4} sm={5} md={"auto"}>
          <InvestmentAmount
            value={props.investmentAmountValue}
            loading={props.investmentDataLoading}
            withOpenTransactionValue
            openTransactionValue={props.openTransactionValue}
            sectionHeader={props.sectionHeader}
            sectionSubheader={props.sectionSubheader}
            customClasses={customClasses}
          />
        </Grid>

        <Grid item xs={12} md={"auto"}>
          <LongTermReturn
            data={props.investmentData}
            loading={props.investmentDataLoading}
            customClasses={customClasses}
          />
        </Grid>

        <Grid item xs={12} md={"auto"}>
          <TotalReturn
            data={props.investmentData}
            loading={props.investmentDataLoading}
            customClasses={customClasses}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

function ModelPortfolioDetails(props) {

  const {
    loading,
    modelPortfolio,
    isInvestmentStrategy,
    onEditButtonClick,
    onDeleteButtonClick,
    viewOnly,
  } = props;

  const classes = useStyles();

  const renderDescriptionContainer = () => {
    return (
      <Grid item xs={12} className={classes.section}>
        <Grid container spacing={1} className={classes.sectionItem}>
          <Grid item xs={12} className={classes.sectionHeader}>Beschreibung</Grid>
          <Grid item xs={12} className={classes.description}>
            {loading ?
              <>
                <Skeleton style={{width: '100%'}} />
                <Skeleton style={{width: '100%'}} />
                <Skeleton style={{width: '100%'}} />
              </>
              : modelPortfolio && modelPortfolio.description
            }
          </Grid>
        </Grid>
      </Grid>
    )
  };

  return (
    <Widget>
      <WidgetHeader>
        <div className={classes.headerContainer}>
          <p className={classes.header}>Basisinformationen</p>
          {!viewOnly && (
            <div>
              {onEditButtonClick && (
                <Tooltip title={"Basisinformationen bearbeiten"} placement={"top"}>
                  <span className={classes.subNavigationButton} onClick={onEditButtonClick}>
                    <span><EditIcon /></span>
                  </span>
                </Tooltip>
              )}
              {onDeleteButtonClick && (
                <Tooltip title={"Musterdepot löschen"} placement={"top"}>
                  <span className={classes.subNavigationButton} onClick={onDeleteButtonClick}>
                    <span><DeleteIcon /></span>
                  </span>
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </WidgetHeader>
      <WidgetBody>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6} xl={6} className={classes.section}>
            {loading ? (
              <>
              <div className={classes.sectionItemsContainer}>
                <div className={classes.sectionItem}>
                  <Skeleton style={{width: 130, height: 30}}/>
                  <Skeleton style={{width: 200}}/>
                </div>
              </div>
              <div className={classes.sectionItem} style={{marginTop: 15}}>
                <Skeleton style={{width: 130, height: 30}}/>
                <Skeleton style={{width: '95%'}}/>
                <Skeleton style={{width: '75%'}}/>
                <Skeleton style={{width: '90%'}}/>
                <Skeleton style={{width: '30%'}}/>
              </div>
              </>
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.sectionTopHeader}>
                  <div>Startdatum:&nbsp;
                    {modelPortfolio && modelPortfolio.tracking_start_date &&
                      moment(modelPortfolio.tracking_start_date).format('DD.MM.YYYY')
                    }
                  </div>
                </Grid>

                <ModelPortfolioInvestmentDetails {...props}/>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={6} className={classes.section}>
            {loading ? (
              <>
                <Skeleton style={{width: '50%', height: 40, marginBottom: 24}}/>
                <div className={classes.sectionItemsContainer} style={{justifyContent: 'space-between'}}>
                  <div className={classes.sectionItem} style={{width: '100%'}}>
                    <Skeleton style={{width: '50%', height: 30}}/>
                    <Skeleton style={{width: '70%'}}/>
                  </div>
                  <div className={classes.sectionItem} style={{width: '100%'}}>
                    <Skeleton style={{width: '50%', height: 30}}/>
                    <Skeleton style={{width: '70%'}}/>
                  </div>
                </div>
                <div className={classes.sectionItemsContainer} style={{justifyContent: 'space-between'}}>
                  <div className={classes.sectionItem} style={{width: '100%'}}>
                    <Skeleton style={{width: '50%', height: 30}}/>
                    <Skeleton style={{width: '70%'}}/>
                  </div>
                  <div className={classes.sectionItem} style={{width: '100%'}}>
                    <Skeleton style={{width: '50%', height: 30}}/>
                    <Skeleton style={{width: '70%'}}/>
                  </div>
                  <div className={classes.sectionItem} style={{width: '100%'}}>
                    <Skeleton style={{width: '50%', height: 30}}/>
                    <Skeleton style={{width: '70%'}}/>
                  </div>
                </div>
              </>
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={12} className={classes.sectionTopHeader}>
                  <div>Profil für Kunden</div>
                </Grid>

                <Grid item xs={12} className={clsx(classes.sectionItemsContainer, classes.rightSideSpacing)} style={{justifyContent: 'space-between'}}>
                  <Grid container spacing={1}>
                    <Grid item xs={8} sm={4} md={5} className={classes.sectionItem}>
                      <p className={classes.sectionHeader}>Kenntnisse & Erfahrungen</p>
                      <p className={classes.customerProfileSectionValue}>
                        {modelPortfolio ? (
                          <>
                            {(isInvestmentStrategy ? modelPortfolio.expertise : MODEL_PORTFOLIO_KNOWLEDGE[modelPortfolio.knowledge_and_experience]) || '-'}
                          </>
                        ) : '-'}
                      </p>
                    </Grid>
                    <Grid item xs={4} sm={4} md={3} className={classes.sectionItem}>
                      <p className={classes.sectionHeader}>Anlagehorizont</p>
                      <p className={classes.customerProfileSectionValue}>
                        {modelPortfolio ? (
                          <>
                            {(isInvestmentStrategy ? modelPortfolio.investment_horizon : MODEL_PORTFOLIO_TIME_HORIZON[modelPortfolio.time_horizon]) || '-'}
                          </>
                        ) : '-'}
                      </p>
                    </Grid>
                    <Grid item xs={8} sm={4} md={4} className={classes.sectionItem}>
                      <p className={classes.sectionHeader}>Anlageziel</p>
                      <p className={classes.customerProfileSectionValue}>
                        {modelPortfolio ? (
                          <>
                            {(isInvestmentStrategy ? modelPortfolio.investment_goal : MODEL_PORTFOLIO_INVESTMENT_GOAL[modelPortfolio.investment_goal]) || '-'}
                          </>
                        ) : '-'}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.sectionItemsContainer} style={{justifyContent: 'space-between'}}>
                  <Grid container spacing={1}>
                    <Grid item xs={8} sm={4} md={5} className={classes.sectionItem}>
                      <p className={classes.sectionHeader}>Durchschnitts-SRI</p>
                      <p className={classes.customerProfileSectionValue}>{avgSRRI(modelPortfolio.assets)}</p>
                    </Grid>
                    <Grid item xs={4} sm={4} md={3} className={classes.sectionItem}>
                      <p className={classes.sectionHeader}>Höchste SRI</p>
                      <p className={classes.customerProfileSectionValue}>{maxSRRI(modelPortfolio.assets)}</p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {renderDescriptionContainer()}
        </Grid>
      </WidgetBody>
    </Widget>
  )
}

ModelPortfolioDetails.propTypes = {

}

export default withProfileDetailsData(ModelPortfolioDetails);

