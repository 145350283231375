import {makeStyles} from "@material-ui/core/styles";
import {smFontSize, xsFontSize} from "../../../../../../utils/constants";
import { FORM_FIELD_HEIGHT } from '../../../../../TransactionsMonitoring/components/Filters/components/common_styles';

export const useStyles = makeStyles((theme) => ({
  textFieldRoot: {
    width: '100%',
    '& .Mui-disabled': {
      borderColor: 'rgba(0, 0, 0, 0.2)'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      whiteSpace: 'pre-line',
    },
  },
  fixedMaxWidthDateField: {
    maxWidth: 145,
  },

  inputRoot: {
    marginBottom: 10,
    border: '1px solid rgb(216, 220, 223)',
    borderRadius: 4,
    height: FORM_FIELD_HEIGHT,
    padding: 6,

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },

    '& input[inputmode="numeric"]': {
      textAlign: 'right',
    },

    '&.w-25': {
      width: '25%',
    },

    '&.w-50': {
      width: '50%',
    },

    '&.noMarginBottom': {
      marginBottom: 0
    },

    '&::before': {
      display: 'none'
    },

    '&::after': {
      display: 'none'
    },
    '&.Mui-disabled': {
      borderColor: 'rgba(0, 0, 0, 0.2)'
    },
  },

  inputMultilineRoot: {
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 'auto !important',
    },
  },
  inputFocused: {
    border: `1px solid ${theme.palette.primary.main}`
  },
  labelRoot: {
    top: 12,
    fontSize: 12,
    color: '#202A38 !important',
    transform: 'none',
    position: 'relative',
    '&.bold': {
      fontSize: 16,
      fontFamily: 'Roboto-Bold',
    },
    '&.regular': {
      fontSize: 16,
      fontFamily: 'Roboto-Regular',
    }
  },
  labelFocused: {},
  labelAsterisk: {
    color: `${theme.palette.primary.main} !important`
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    }
  },
  selectMenuPaper: {
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    border: 'none'
  },
  selectMenuItemRoot: {
    color: 'initial!important',
    fontFamily: 'Roboto-Regular!important',
    whiteSpace: 'normal',
    [theme.breakpoints.only('sm')]: {
      fontSize: smFontSize,
      minHeight: '2rem'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: xsFontSize,
      minHeight: '2rem'
    },
  },
  radioGroupRoot: {
    // height: 50,
    marginTop: 16,
    marginBottom: 10,
    '&.noMarginBottom': {
      marginBottom: 0,
    },
    '&.finalizeOnboardingConversationDetailsRadio': {
      height: 'auto !important'
    },
    '& .finalizeOnboardingFormControlLabelRoot': {
      alignItems: 'baseline',

      '& span:last-child': {
        position: 'relative',
        top: -5
      }
    },
    '& .MuiFormControlLabel-root.noMarginBottom': {
      marginBottom: 0,
    },
    '&.flex-direction-column': {
      flexDirection: 'column'
    }
  },
  colorIndicator: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    display: 'inline-block',
    margin: '0 10px -1px 0',
  },
  minimised: {
    '& .MuiInputBase-root': {
      minWidth: 110,
      '& .MuiButtonBase-root': {
        padding: '0 !important',
        '& svg': {
          height: 18,
          width: 18,
        }
      },
    }
  },
  minimisedSelect: {
    '& .MuiSelect-root': {
      paddingRight: '16px !important',
    },
    '& svg': {
      right: '0 !important'
    }
  }
}));
