import { makeStyles } from '@material-ui/core';

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      bottom: '100%',
      left: 0,
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      top: '100%',
      left: 0,
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      right: '100%',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      left: '100%',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}


export default makeStyles(theme => ({
  arrow: {
    position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  popper: {
    ...arrowGenerator('#EFEFF2'),
  },
  tooltip: {
    position: 'relative',
    padding: 20,
    backgroundColor: '#EFEFF2',
    color: '#4D4F5C',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.2)',
    whiteSpace: 'pre-line',
    maxWidth: props => props.width
  }
}));