import {makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    fontSize: props => props.fontSize || 16,
    padding: '0.375em',
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)',
    '& path': {
      fill: 'rgba(0, 0, 0, 0.26)'
    }
  }
}))