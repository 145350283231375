import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { withStyles } from "@material-ui/core";

import { styles } from './styles'

class Content extends Component {
  render() {
    const {
      classes
    } = this.props
    return (
      <div className={classes.container}>
        { this.props.children }
      </div>
    );
  }
}

Content.propTypes = {};

export default withStyles(styles)(Content);
