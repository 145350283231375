import React from "react";
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
import {getPieChartOptions} from "../../utils";

import EcinL from "../../../../../../images/FactsheetSustainabilityTab/Pai/EcinL";
import WaterEmissions from "../../../../../../images/FactsheetSustainabilityTab/Pai/WaterEmissions";
import ControWeapons from "../../../../../../images/FactsheetSustainabilityTab/Pai/ControWeapons";
import PercOcstsViolations from "../../../../../../images/FactsheetSustainabilityTab/Pai/PercOcstsViolations";
import Biodiversity from "../../../../../../images/FactsheetSustainabilityTab/Pai/Biodiversity";
import HazardousWasteRatio from "../../../../../../images/FactsheetSustainabilityTab/Pai/HazardousWasteRatio";
import EeiRealEstateAssets from "../../../../../../images/FactsheetSustainabilityTab/Pai/EeiRealEstateAssets";


const configuration = {
  ecin_l: {
    name: "Treibhausgas Emissionen",
    y: 16,
    icon: <EcinL />,
  },
  water_emissions: {
    name: "Wasser",
    y: 16,
    icon: <WaterEmissions />,
  },
  contro_weapons: {
    name:
      <>Soziale Themen und <br/>Arbeitnehmerbelange,<br/> Korruption und<br/> Menschenrechte</>,
    y: 16,
    icon: <ControWeapons />,
  },
  perc_ocsts_violations: {
    name: <>Indikatoren bei Investments<br/> in Immobilienvermögen</>,
    y: 16,
    icon: <PercOcstsViolations />,
  },
  biodiversity: {
    name: "Biodiversität",
    y: 16,
    icon: <Biodiversity />,
  },
  hazardous_waste_ratio: {
    name: "Abfall",
    y: 16,
    icon: <HazardousWasteRatio />,
  },
  eei_real_estate_assets: {
    name:
      <>Indikatoren bei Investments <br/>in staatliche und supra-<br/> nationale Emittenten</>,
    y: 16,
    icon: <EeiRealEstateAssets />,
  },
};

class Chart extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <PieChart
      highcharts={Highcharts}
      options={getPieChartOptions(
        this.props.data || {},
        configuration,
        {
          active: [
            [0.25, "#799EEF"],
            [0.75, "#466BD9"],
          ],
          inactive: [
            [0.25, "#EBF1FD"],
            [0.75, "#E3E9FA"],
          ]
        },
        "pai-chart")}
    />;
  }
}

export default Chart;
