import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    maxHeight: 400,
    overflowY: 'auto',
    overflowX: 'hidden',

    /* width */
    '&::-webkit-scrollbar': {
      width: 8
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#ADB6BD'
    },

    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#9ba2a8'
    }
  },
  instrumentItemName: {
    margin: 0,

    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  instrumentItemNameDisabled: {
    color: '#80858C !important'
  },
  instrumentItemContainer: {

    minHeight: 45,
    width: '100%',
    position: 'relative',

    padding: '12px 20px 9px',
    
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    transition: 'all',
    transitionDuration: 250,

    '&:hover': {
      cursor: 'pointer',
      background: '#EEF0F2'
    },

    '&:first-child': {
      borderRadius: '5px 5px 0 0'
    },

    '&:last-child': {
      borderRadius: '0 0 5px 5px',
      paddingBottom: 12,
      marginBottom: 60
    }
  },
  loadingIndicatorContainerItem: {
    padding: '12px 20px 9px',
  },
  disabledLabel: {
    border: '1px solid #80858C',
    color: '#80858C',
    borderRadius: 2,
    fontFamily: 'Roboto-Regular',
    fontSize: 10,
    textTransform: 'uppercase',
    padding: '4px 6px'
  },
  paginationInfo: {
    color: '#ABB6BE'
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '14px 0 10px',
    position: 'absolute',
    bottom: -56,
    background: 'white',
    borderRadius: '0 0 5px 5px',
    height: 60,
    boxShadow: '0px 10px 15px #00000033',
    zIndex: -1,
  },
  acceptanceCriteriasContainer: {
    position: 'absolute',
    background: 'white',
    right: 'calc(100% + 10px)',
    top: 0
  }
})

export default makeStyles(styles)