import { makeStyles } from '@material-ui/core';

const styles = theme => ({

  completedTasksNumber: {
    fontFamily: 'Roboto-Bold',
    fontSize: 20
  },
  detailsCard: {
    zIndex: 99999,
    height: 450,
    width: 450,
    position: 'absolute',
    top: '100%',
    right: 0,
    borderRadius: 5,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',
    padding: 5,
    boxSizing: 'border-box',

    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '100%',
      zIndex: 99999,
      bottom: 0,
      left: 0,
      top: 0,
      marginTop: 0,
      borderRadius: 0,
      position: 'fixed'
    }
  },
  detailsCardHeader: {
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #e8eaeb',
    display: 'none',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      paddingBottom: 20,
    }
  },
  detailsCardTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 18,
    margin: 0,
    padding: 0,
    color: '#323c42'
  },
  reportDetailsTabContainer: {
    width: '100%'
  },
  reportDetailsTabTitle: {
    position: 'relative',
    width: '100%',
    borderBottom: '1px solid rgb(216, 220, 223)',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > p': {
      color: '#63696f',
      fontFamily: 'Roboto-Regular',
      fontSize: 18,
      padding: 0,
      margin :0
    },
  },
  taskItemContainer: {
    width: '100%',
    padding: '10px 0',
    borderBottom: '1px solid #E1E3E9',
    '&:hover': {
      '& $reportItemTitle': {
        textOverflow: 'initial',
        overflow: 'initial',
        whiteSpace: 'initial',
        wordBreak: 'break-all'
      }
    }
  },
  reportItemTitle: {
    fontSize: 16,
    margin: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  progressBarContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  progressBarValue: {
    fontFamily: 'Roboto-Bold',
    fontSize: 12,
    marginLeft: 15,
    color: '#4D4F5C',

    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    }
  },
  progressBarRoot: {
    height: 10,
    width: 'calc(100% - 70px)',
    backgroundColor: '#e8eaeb',
    borderRadius: 10
  },
  progressBarBar: {
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
  },
  taskItemIconContainer: {
    height: 35,
    borderRadius: 5,
    marginRight: 10,
    display: 'flex',

    '& svg': {
      fontSize: 21
    }
  },
  taskItemAdditionalInfo: {
    position: 'relative',
    '& > span': {
      fontFamily: 'Roboto-Regular',
      fontSize: 14
    }
  },
  detailsCardContent: {
    height: '100%',
    padding: 10,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#DADEE1',
      borderRadius: 10
    }
  },
  additionalTaskActionButton: {
    position: 'absolute',
    right: 5,
    top: -2,
    fontSize: 16,
    color: 'rgb(115, 115, 115)',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  taskNotificationItem: {
    padding: 5,
    margin: 0,
    marginTop: 5,
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    borderRadius: 5
  },
  triggerStateButtonWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: 'calc(100% + 16px)',
    paddingLeft: 16,
    paddingRight: 16,
    top: -8,
  },
  opened: {
    backgroundColor: '#626970'
  },
  notificationItemButtonWrap: {
    height: 'auto',
    padding: '0',
    paddingRight: 'initial',
    top: 'auto',
    backgroundColor: 'transparent',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  triggerStateButton: {
    color: 'white',
    fontFamily: 'Roboto-Bold',
  },
  activeTriggerStateButton: {
    color: '#E3A600'
  },
  downloadTaskButtonContainer: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  downloadIcon: {
    position: 'absolute',
    fontSize: 20,
    top: 2,
    right: '100%',
    marginRight: 4,
    color: theme.palette.primary.main
  },
  counter: {
    width: 40,
    height: 40,
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    backgroundColor: '#E3A600',
    marginRight: 4
  },
  backdrop: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.3)'
  },
  iconButton: {
    color: 'white',
    padding: 8
  },
  badge: {
    backgroundColor: '#E3A600',
  },
  closeIcon: {
    color: '#b1b1b1',
    cursor: 'pointer'
  },
  disabled: {
    pointerEvents: 'none',

    '& $downloadIcon, & $downloadTaskButtonContainer': {
      color: 'rgb(115, 115, 115)',
    }
  },
});

export const makeStyle = makeStyles(styles);
export default styles;