import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.005"
      height="30.005"
      viewBox="0 0 30.005 30.005"
    >
      <path
        id="Pfad_1426"
        data-name="Pfad 1426"
        d="M1013.256,96.646,1012.57,96a11.865,11.865,0,0,0-5.982-2.783,13.445,13.445,0,0,0,2.774-6.741l.092-1.045-1.044.092a13.743,13.743,0,0,0-5.786,2.073c-.3-4.376-3.592-7.8-3.739-7.954L998.257,79l-.628.644c-.147.151-3.444,3.58-3.739,7.959a13.724,13.724,0,0,0-5.785-2.078l-1.044-.093.092,1.046a13.441,13.441,0,0,0,2.773,6.741A11.866,11.866,0,0,0,983.944,96l-.693.649.7.641a11.863,11.863,0,0,0,7.064,2.865,10.9,10.9,0,0,0-.86,2.724L990,103.919l1.262-.037a8.069,8.069,0,0,0,6.114-3.072v8.2h1.755v-8.2a8.069,8.069,0,0,0,6.114,3.072l1.262.037-.152-1.037a10.91,10.91,0,0,0-.86-2.725,11.647,11.647,0,0,0,7.072-2.871Zm-7.978,1.757a23.555,23.555,0,0,1-3.181-.385l1.124,1.658a9.234,9.234,0,0,1,1.122,2.351,6.325,6.325,0,0,1-5.208-6.259H997.38a6.324,6.324,0,0,1-5.208,6.259,9.23,9.23,0,0,1,1.126-2.358l1.152-1.695-2.02.333a9.2,9.2,0,0,1-6.448-1.668,9.536,9.536,0,0,1,5.254-1.75c.62,0,.8.05,3.811.323a37.173,37.173,0,0,1-3.3-2.595,10.808,10.808,0,0,1-2.645-5.136,10.74,10.74,0,0,1,5.12,2.652l1.888,1.891a29.619,29.619,0,0,1-.489-3.8,11.471,11.471,0,0,1,2.634-6.615,11.425,11.425,0,0,1,2.632,6.615,29.7,29.7,0,0,1-.483,3.778l1.883-1.882a10.781,10.781,0,0,1,5.119-2.641,10.807,10.807,0,0,1-2.645,5.134,36.825,36.825,0,0,1-3.3,2.595c3.025-.274,3.19-.323,3.811-.323a9.586,9.586,0,0,1,5.262,1.76A9.539,9.539,0,0,1,1005.278,98.4Z"
        transform="translate(-983.251 -79.002)"
        fill="#80858c"
      />
    </svg>
  );
};
