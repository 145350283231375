import React from "react";

import IconButton from "@material-ui/core/IconButton";
import withStyles from '@material-ui/core/styles/withStyles';
import Badge from "@material-ui/core/Badge";

import styles from "./styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const NotificationItem = props => {
  const {
    classes,
    faIcon,
    badgeContent,
    onClick
  } = props;

  const handleClick = (event) => {
    return onClick && onClick(event)
  }

  return (
    <IconButton classes={{root: classes.iconButton}} onClick={handleClick}>
      <Badge
        badgeContent={badgeContent}
        classes={{
          badge: classes.badge,
        }}
      >
        <FontAwesomeIcon icon={faIcon} fixedWidth={true} color={'#63696f'} fontSize={24} />
      </Badge>
    </IconButton>
  )
};

export default withStyles(styles)(NotificationItem);