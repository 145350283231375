const styles = (theme) => ({
  tableHead: {
    backgroundColor: 'rgb(249, 249, 249)',
    color: 'rgb(177, 177, 177)',
    textTransform: 'uppercase',
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    borderBottom: 'none',
    padding: 0,

    '& > th': {
      padding: '8px!important',
      outline: 'none',
      color: '#b1b1b1',
      fontFamily: 'Roboto-Bold',
      width: '20%',
      minWidth: 50,

      [theme.breakpoints.down('sm')]: {
        fontSize: 12
      },
    },

    '& > th:first-of-type': {
      width: '60%'
    },

    '& > th:last-of-type': {
      width: '15%'
    }
  },
  tableRow: {
    padding: 0,
    fontSize: 14,

    '& > td': {
      padding: '8px!important',

      [theme.breakpoints.down('sm')]: {
        fontSize: 12
      },
    },

    '&:hover': {
      backgroundColor: '#efeff2'
    }
  },
  bold: {
    fontFamily: 'Roboto-Bold'
  },
  name: {
    wordBreak: 'break-all'
  }
});

export default styles;