import React from "react";

import LinearProgress from "@material-ui/core/LinearProgress";
import {toGermanFormat} from "../../../../utils/numberFormater";

const ProgressBar = props => {
  const {
    dataService,
    value,
    category
  } = props;

  const minSpace = 5; // min padding left/right
  const spaceRight = 100 - value; // space from right

  if(dataService && dataService.hideProgress){
    return null;
  }

  return (
    <div style={{
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      height: 50,
      background: 'linear-gradient(0, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 28%, rgba(255,255,255,1) 100%)'
    }}>
      <LinearProgress variant="determinate" value={value} />
      <div style={{
        position: 'relative',
        width: '100%'
      }}>
        {typeof value === 'number' && (
          <div style={{
            position: 'absolute',
            top: 2,
            color: '#80858C',
            left: value < minSpace || value > 85 ? 'auto' : `${value}%`,
            right: value <= 85 ? 'auto' : spaceRight > minSpace ? `${spaceRight}%` : 0,
            transform: `translateX(${value < minSpace || (value > 85 && spaceRight > minSpace) ? 50 : -50}%)`,
            transition: '0.5s',
            textAlign: 'center',
          }}>
            <span style={{
              fontSize: 16,
              fontFamily: 'Roboto-Bold'
            }}>
              {/*{toGermanFormat(value, '', '', 1)}%*/}
              {dataService.currentStepNumber} von {dataService.stepsLength}
            </span>
            {category && (
              <div style={{fontSize: 10, textTransform: 'uppercase'}}>
                {category}
              </div>)}
          </div>
        )}
      </div>
    </div>

  );
}

export default ProgressBar;