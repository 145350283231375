import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  filterHeader: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#626970'
  },
  filterHeaderMessage: {
    fontFamily: 'Roboto-Regular'
  },
  filteringContainer: {
  },
  filtersColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  filteringWrapper: {
    backgroundColor: '#ABB6BE',
    width: '100%',
    marginTop: 14,
    padding: 16,
    borderRadius: 2,
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',

    [theme.breakpoints.down(350)]: {
      flexDirection: 'column'
    },

    '& > button': {
      marginLeft: 16,

      [theme.breakpoints.down(350)]: {
        width: '100%',
        marginLeft: 0,
        marginTop: 16,
        '&:first-child': {
          marginTop: 0
        }
      },

      '&:first-child': {
        marginLeft: 0
      }
    }
  },
  resetButtonRoot: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    }
  }
}))