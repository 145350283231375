import React from 'react';
import clsx from "clsx";

import useStyles from './styles';
import {getListDateFormat, getTypeNameByValue} from "../../utils";

export default (props) => {
  const classes = useStyles();

  const {
    date,
    type,
    hideLabel
  } = props;


  return (
    <div className={classes.container}>
      <div className={clsx(classes.label, hideLabel && classes.hideLabel)}>Zuletzt versendet</div>
      <div className={classes.value}>
        {type && (<span>{getTypeNameByValue(type)}<br/></span>)}
        {date && (<span>{getListDateFormat(date)}</span>)}

        {!date && !type && (<span>-</span>)}
      </div>
    </div>
  );
}