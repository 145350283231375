import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_8059"
            data-name="Rechteck 8059"
            width="30"
            height="30"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_8188" data-name="Gruppe 8188" transform="translate(0 0)">
        <g
          id="Gruppe_8187"
          data-name="Gruppe 8187"
          transform="translate(0 0)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Pfad_7829"
            data-name="Pfad 7829"
            d="M13.281,24.2a10.823,10.823,0,0,1-6.413,2.274L1.651,21.256a10.791,10.791,0,0,1,4.132-8.278L10.6,9.231l5.991,5.991a9.386,9.386,0,0,1,1.392-.938L13.176,9.475l2.913.583,1.978,1.978.4,2a9.342,9.342,0,0,1,1.565-.579L19.72,11.9l.493-.493,3.493,1.165,4.417-4.428L24.919,6.7l2.064-2.064-3.5-3.5L21.42,3.208,19.98,0,15.552,4.415l1.165,3.5-.493.493L10.461,7.251l-5.69,4.425A12.365,12.365,0,0,0,.075,22.8L.6,27.524l4.727.525A12.358,12.358,0,0,0,13.763,25.9a9.277,9.277,0,0,1-.481-1.7M25.3,8.673l-2.032,2.015-1.75-.583L23.67,7.95Zm-1.812-5.2,1.165,1.165-5.826,5.826L17.66,9.3Zm-4.035-.627.72,1.611L18.021,6.608,17.438,4.86ZM2.093,26.032l-.251-2.254,2.5,2.5Zm1.632-6.67.213.8a5.793,5.793,0,0,0,4.077,4.078l.8.215.89-3.321a2.445,2.445,0,0,0,.447-.076,2.5,2.5,0,0,0,.426-.157l2.431,2.431.147-.147c-.016-.224-.027-.45-.027-.679A9.334,9.334,0,0,1,15,16.873l-.133-.5-3.319.889a2.448,2.448,0,0,0-.64-.638l.889-3.318L11,13.094A5.763,5.763,0,0,0,5.43,14.588l-.58.582L7.28,17.6a2.468,2.468,0,0,0-.235.871Zm8.25-.5,1.646-.441a4.149,4.149,0,0,1-.677,2.518l-1.2-1.2a2.453,2.453,0,0,0,.235-.872M10.3,18.451A.824.824,0,1,1,9.3,17.868a.824.824,0,0,1,1.009.583M7.237,15.228a4.114,4.114,0,0,1,2.52-.676L9.316,16.2a2.451,2.451,0,0,0-.872.234Zm.234,4.836a2.484,2.484,0,0,0,.639.637l-.441,1.647A4.151,4.151,0,0,1,5.824,20.5ZM22.5,16.875A5.625,5.625,0,1,1,16.875,22.5,5.631,5.631,0,0,1,22.5,16.875M22.5,15A7.5,7.5,0,1,0,30,22.5,7.5,7.5,0,0,0,22.5,15m-.548,10.323a.933.933,0,0,1-.625-.239l-2.311-2.068a.938.938,0,0,1,1.25-1.4l1.616,1.446,2.781-3.078a.938.938,0,0,1,1.392,1.257l-3.406,3.771a.935.935,0,0,1-.7.309"
            transform="translate(0 0)"
            fill="#80858c"
          />
        </g>
      </g>
    </svg>
  );
};
