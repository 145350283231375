import Grid from "@material-ui/core/Grid";
import React from "react";
import moment from "moment";
import _ from "lodash";
import SelectFormElement from "../../../formElement/SelectFormElement";
import RadioFormElement from "../../../formElement/RadioFormElement";
import { isRequiredQuestion } from "../../../../../../utils";
import DisclaimerText from "../DisclaimerText";
import {toGermanFormat} from "../../../../../../../../utils/numberFormater";


const SelectProfile = (props) => {
  const {
    onAnswerChange,
    dataService,
    questions: [esgAdvisorQuestion, esgProfileRadioQuestion, esgProfileSelectQuestion],
  } = props;

  const esgProfiles = React.useMemo(() =>
    _.take(
      _.reverse(
        _.sortBy(dataService.esgProfiles, [
          (a) => moment(a.createdAt.split(" ")[0]),
        ])
      ).map((r) => {
        return {
          id: r.esgProfileGuid,
          value: r.esgProfileGuid,
          label: `ESG Profilierung vom ${moment(r.createdAt).format("DD.MM.YYYY")} - 
                  ÖKO ${toGermanFormat(r.targetEcoPct, undefined, '%', 2, true)} -
                  ESG ${toGermanFormat(r.targetEsgPct, undefined, '%', 2, true)} -
                  PAI ${toGermanFormat(r.targetPaiPct, undefined, '%', 2, true)}`
        };
      }),
      10
    )
  , [JSON.stringify(dataService.esgProfiles)]);

  React.useEffect(() => {
    if (!esgProfileRadioQuestion.answer) {
      if (_.size(esgProfiles) > 0) {
        handleRadioValueChange("select");
        onAnswerChange(esgProfileSelectQuestion.uid, getLatestESGProfile());
      } else {
        handleRadioValueChange("new");
      }
    }
    if (!esgAdvisorQuestion.answer) {
      handleAdvisorValueChange("yes");
    }

  }, []);

  if (!esgProfiles || esgProfiles.length === 0) {
      esgProfileRadioQuestion.options[2].disabled = true;
      esgProfileRadioQuestion.options[3].disabled = true;
  }

  const handleProfileChange = (value) => {
    onAnswerChange(esgProfileSelectQuestion.uid, value);
  };

  const handleRadioValueChange = (value) => {
    onAnswerChange(esgProfileRadioQuestion.uid, value);
  };

  const handleAdvisorValueChange = (value) => {
    onAnswerChange(esgAdvisorQuestion.uid, value);
    esgAdvisorQuestion.optional = value;
  };

  const getLatestESGProfile = () => {
    return _.get(esgProfiles, "0.value", "");
  };

  const esgNotNeeded = esgAdvisorQuestion.answer === "no";
  const isProfileSelectDisabled = esgNotNeeded || ["not-interested", "new"].includes(esgProfileRadioQuestion.answer);
  // in case it's disabled set it optional
  esgProfileSelectQuestion.optional = isProfileSelectDisabled;

  return (
    <>
      {!esgAdvisorQuestion.hidden && (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <RadioFormElement
              value={esgAdvisorQuestion.answer || ""}
              options={esgAdvisorQuestion.options}
              onChange={handleAdvisorValueChange}
              required={isRequiredQuestion(esgAdvisorQuestion)}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <RadioFormElement
            label={esgProfileRadioQuestion.question_text}
            value={esgProfileRadioQuestion.answer || ""}
            disabled={esgNotNeeded}
            options={esgProfileRadioQuestion.options}
            onChange={handleRadioValueChange}
            custom_classes={esgProfileRadioQuestion.custom_classes}
            required={isRequiredQuestion(esgProfileRadioQuestion)}
          />
          <SelectFormElement
            value={esgProfileSelectQuestion.answer || getLatestESGProfile()}
            options={esgProfiles || []}
            onChange={handleProfileChange}
            disabled={isProfileSelectDisabled}
          />
        </Grid>
      </Grid>
      <DisclaimerText />
    </>
  );
};

export default SelectProfile;