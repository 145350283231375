import React from 'react'

import { Grid } from '@material-ui/core'

import useStyles from './styles'

function ProtocolSummary() {

  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Grid item xs={12}>
        <p className={classes.text}>
        Sobald Ihr Kunde das Orderformular unterschrieben hat, wird diese Order zur Bearbeitung an die Depotbank gesendet. Die Dauer der Auftragsabwicklung hängt stark von der Depotbank ab. Sobald Ihre Bestellung bearbeitet wurde, erhalten Sie eine Benachrichtigung.
        </p>
      </Grid>
    </div>
  )
}

export default ProtocolSummary