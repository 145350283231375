import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  listContainer: {
    marginTop: 25,
    '& > div': {
      marginBottom: 30
    }
  },
  emptyMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 150,
    width: '100%',

    '& > p': {
      fontSize: 16,
      fontFamily: 'Roboto-Reqular'
    }
  }
}));