import React from 'react';
import _ from 'lodash';

import withTheme from '@material-ui/core/styles/withTheme';

import {PortfolioUtils} from '../../../../../../../utils/utils';
import ChartSectionBordered from "../../../../../components_v2/ChartSectionBordered/ChartSectionBordered";
import RegionChart from "../../../../../../FactSheetsPage/components/RegionChart";
import {
  CustomerDashboardNoDataPlaceholder
} from "../../../../../../FactSheetsPage/components/NoDataPlaceholder";
import {portfolioStructurePropsAreEquals} from '../utils';

const CHART_COLORS = [
  '#C8E8C7',
  '#1FBBD5',
  '#1971AF',
  '#151759',
  '#FFBFA4',
  '#FA528A'];

const PortfolioRegionStructure =  React.memo((props) => {
  const {
    dashboardData,
    expanded,
    onExpanded,
  } = props;

  const portfolioStructure = dashboardData && dashboardData.portfolio_structure;

  let data = [], byCountry = [];
  if (portfolioStructure) {
    data = portfolioStructure.by_region;
    byCountry = portfolioStructure.by_country;
  }

  const hasData = !_.isEmpty(data);

  return (
    <ChartSectionBordered
      title={<b>{data.length !== 1 ? 'Regionen' : 'Region'}</b>}
      expanded={expanded}
      onExpanded={onExpanded}
      skipPadding={hasData}
      content={(
        <>
          {hasData ? (
            <RegionChart
              data={PortfolioUtils.getPortfolioRegions({'by_region': data})}
              byCountry={byCountry}
              colors={CHART_COLORS}
              orientation='vertical'
              height={400}
              showLegend={true}
              language='DE'
            />
          ) : (
            <CustomerDashboardNoDataPlaceholder />
          )}
        </>
      )}
    />
  );

}, portfolioStructurePropsAreEquals);

export default withTheme(PortfolioRegionStructure);
