import React from "react";
import { withStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment";

import CommonAnalysisCard from "../../../../../VirtualPortfolioPage/components/AnalysisCard";
import { toShortGermanFormat } from "../../../../utils";
import { FactSheetsHandlerResource } from "../../../../../../utils/api";
import { processResponse } from "../../../../index";
import styles from "./styles";
import ExplanationTooltip from "../../../../../../components/ExplanationTooltip";


class KeyFiguresTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dataProvider: FactSheetsHandlerResource,
    };

    this.datePerios = [
      {
        header: <span>YTD</span>,
        start: moment().startOf('year'),
        id: 'Ytd',
        name: 'YTD',
      },
      {
        header: <span>Verlauf über<br />1 Jahr</span>,
        start: moment().subtract(1, 'year'),
        id: '1y',
        name: 'verlauf über 1 Jahr',
      },
      {
        header: <span>Verlauf über<br />3 Jahre</span>,
        start: moment().subtract(3, 'year'),
        id: '3y',
        name: 'verlauf über 3 Jahre',
      },
      {
        header: <span>Verlauf über<br />5 Jahre</span>,
        start: moment().subtract(5, 'year'),
        id: '5y',
        name: 'verlauf über 5 Jahre',
      },
      {
        header: <span>Verlauf über<br />10 Jahre</span>,
        start: moment().subtract(10, 'year'),
        id: '10y',
        name: 'verlauf über 10 Jahre',
      },
      {
        header: <span>Seit Auflage</span>,
        start: moment(this.props.data.inception_date),
        id: 'Si',
        name: 'Seit Auflage',
      },
    ];

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const { data: { isin } } = this.props;
    const endDate = moment().format('YYYY-MM-DD');

    this.datePerios.forEach((item) => {
      const startDate = item.start.format('YYYY-MM-DD');

      processResponse(
        this.state.dataProvider.getAnalyseV2PerformanceIndicatorsData(isin, startDate, endDate),
        'performance',
        'performance' + item.id,
        this
      );

      processResponse(
        this.state.dataProvider.getAnalyseRiskMetricsHistoricalData(isin, startDate, endDate),
        'historical',
        'historical' + item.id,
        this
      );
    });
  }

  renderValue(_type, period, name, format) {
    const prefix = _type + period;

    if (this.state[prefix + 'Loading'] === undefined) {
      return (
        <div className={this.props.classes.linearProgress}>
          <LinearProgress />
        </div>
      );
    } else if (this.state[prefix + 'Error']) {
      return 'k.A.';
    }

    let value = this.state[prefix + 'Data'][name];

    if (
      value &&
      (
        name === 'volatility_annual' ||
        name === 'var_hist_hp' ||
        name === 'maximum_drawdown'
      )
    ) {
      value *= 100;
    }

    if (value === undefined || value === null) {
      return 'k.A.';
    }
    if(format === 'date') return moment(value).format('DD.MM.YYYY');
    if(format === 'percent') return toShortGermanFormat(value, '', ' %', 2, false)
    return toShortGermanFormat(value, '', '', 2, false);
  }

  renderValues(dateLine) {
    const { classes } = this.props;

    return this.datePerios
      .map((item, index) => (
        <span className={classes.values} key={index}>
          {this.renderValue(dateLine.type, item.id, dateLine.value, dateLine.format)}
        </span>
      ));
  }

  renderDatePeriods() {
    const { classes } = this.props;

    return this.datePerios.map((item, index) => (
      <span className={classes.title} key={index}>
        {item.header}
      </span>
    ));
  }

  renderLines() {
    const { classes } = this.props;

    const data = [
      { name: 'Sharpe-Ratio', value: 'sharpe_ratio_a', type: 'performance', tooltip: 'sharpe_ratio' },
      { name: 'Volatilität in %', value: 'volatility_annual', type: 'historical', format: 'percent', tooltip: 'volatility' },
      { name: 'Value at Risk 99%', value: 'var_hist_hp', type: 'historical', format: 'percent', tooltip: 'value_at_risk' },
      { name: 'Maximaler Wertverlust', value: 'maximum_drawdown', type: 'historical', format: 'percent', tooltip: 'maximum_drawdown' },
      { name: 'Tiefpunkt', value: 'maximum_drawdown_date', type: 'historical', format: 'date' },
      { name: 'Verlustausgleichsdatum', value: 'recovery_date', type: 'historical', format: 'date' },
    ];

    return data.map((item, index) => (
      <li className={classes.item} key={index}>
        <span className={classes.title}>
          {item.name}
          <ExplanationTooltip tooltipKey={item.tooltip}/>
        </span>
        {this.renderValues(item)}
      </li>
    ));
  }

  renderData() {
    const { classes } = this.props;

    return (
      <ul className={classes.list}>
        <li className={[classes.item, classes.headline].join(' ')}>
          <span className={classes.title}></span>
          {this.renderDatePeriods()}
        </li>
        {this.renderLines()}
      </ul>
    )
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
      classes={{
        card: classes.root
      }}
      title={this.props.title}
      content={
        <div className={classes.container}>
          {this.renderData()}
        </div>
      }
    />
  }
}

export default withStyles(styles)(KeyFiguresTable)
