import {REPORT_TYPES} from "../../DashboardSettings/components/CustomersList/components/ReportType/constants";

const other_charts_1 = {
  selector: '#instruments-list-tour-element',
  key: 'customer_app_dashboard_instruments_list'
};
const other_charts_2 = {
  selector: '#performance-bar-chart',
  key: 'customer_app_dashboard_performance_bar_chart'
};
const other_charts_3 = {
  selector: '#mdd-chart',
  key: 'customer_app_dashboard_mdd_chart'
};
const other_charts_4 = {
  selector: '#volatility-chart',
  key: 'customer_app_dashboard_volatility_chart'
};
const other_charts_5 = {
  selector: '#portfolio-structure',
  key: 'customer_app_dashboard_portfolio_structure_charts'
};

export const getOtherChartsChapterSteps = (reportType) => {

  if (reportType === REPORT_TYPES.EXPERT.value || reportType === REPORT_TYPES.PRO.value) {
    return [
      other_charts_1,
      other_charts_2,
      other_charts_3,
      other_charts_4,
      other_charts_5
    ];
  }

  const chapterSteps = [
    other_charts_1
  ]

  if (reportType !== REPORT_TYPES.DEPOT_STATUS.value) {
    chapterSteps.push(other_charts_2)
  }

  return chapterSteps;
};
