import { fromJS } from 'immutable';

import {
  BENCHMARK_CONFIGURATION_MODAL_OPENED,
  BENCHMARK_CONFIGURATION_REQUIRED,
  BENCHMARK_CONFIGURATION_PORTFOLIO_ID,
  BENCHMARK_CONFIGURATION,
  ADD_PORTFOLIO_DATA,
  TIME_SELECTOR_DATE_CHANGED, REPORT_TYPE_CHANGED,
} from "./constants";
import {PROF_VIEW_REPORT_TYPE, setInStorage} from "../../utils/storage";

const initialState = fromJS({
  portfolio_data: undefined,
  time_selector_date_change: {},
  report_type: undefined,
});

function appReducer(state = initialState, action) {
  switch(action.type) {
    case ADD_PORTFOLIO_DATA:
      return state.set('portfolio_data', fromJS(action.portfolio_data));
    case TIME_SELECTOR_DATE_CHANGED:
      return state.set('time_selector_date_change', fromJS(action.change));
    case REPORT_TYPE_CHANGED:
      setInStorage(PROF_VIEW_REPORT_TYPE, action.report_type)
      return state.set('report_type', fromJS(action.report_type));
    default:
      return state;
  }
}

const benchmarkInitialState = fromJS({
  portfolio_id: undefined,
  modal_opened: false,
  configuration_required: false
});

export function benchmarkConfigurationReducer(state=benchmarkInitialState, action) {
  switch(action.type) {
    case BENCHMARK_CONFIGURATION_MODAL_OPENED:
      return state.set('modal_opened', action.modal_opened);
    case BENCHMARK_CONFIGURATION_REQUIRED:
      return state.set('configuration_required', action.configuration_required);
    case BENCHMARK_CONFIGURATION_PORTFOLIO_ID:
      return state.set('portfolio_id', action.portfolio_id);
    case BENCHMARK_CONFIGURATION:
      const configuration_required = typeof action.configuration_required === 'boolean' ?
        action.configuration_required : state.get('configuration_required')
      return fromJS({
        portfolio_id: action.portfolio_id,
        modal_opened: action.modal_opened,
        configuration_required
      });
    default:
      return state;
  }
}

export default appReducer;