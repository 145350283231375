import React from 'react';
import {
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import connect from "react-redux/es/connect/connect";

import Container from "@material-ui/core/Container";
import withWidth from "@material-ui/core/withWidth/withWidth";

import {
  Header,
  ProfileDetails
} from "./components";
import AssetsList from "./components/AssetsList";
import AssetCategoryDetails from "./components/AssetCategoryDetails";
import SuccessDialog from "../../components/SuccessDialog/SuccessDialog";
import { GuideTour } from '../../components/GuideTour';
import AssetModal from "../../components/AssetModal/CreateAssetModal";
import {closeAssetModal} from "../../components/AssetModal/actions";

import {
  ASSET_CATEGORIES_WITH_CLEARING_ACCOUNTS,
  ASSET_CATEGORIES_WITH_CLEARING_ACCOUNTS_AS_DICT
} from "./components/AssetsList/components/AssetsCategories/constants";
import {getGuideSteps} from "./guide";
import withOtherAssetsData from "./components/OtherAssetsDataProvider/OtherAssetsDataProvider";
import {ROUTES} from "../../routes";
import { executeIfPathExist, getInvestmentDynamicPath } from '../InvestmentPlatform/utils'
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import ComparisonsList from "../ProductComparison/components/TabsContent/ComparisonsList";
import {PROCESS_TYPES} from "../ProductComparison/constants";
import ResponsiveDialog from "../../components/ResponsiveDialog/ResponsiveDialog";
import {makeStyles} from "@material-ui/core";


const OtherAssets = props => {
  const {
    customerId,
    width,
    assetModal,
    dispatch,
    customer,
    assetsData,
    investmentData,
    onUpdate,
    unrealizedProfitAndLoss,
  } = props;

  const [dialog, setDialog] = React.useState({open: false, message: ''});

  const [otherAssetsListRoute, setOtherAssetsListRoute] = React.useState(null)
  const [otherAssetsGeneralRoute, setOtherAssetsGeneralRoute] = React.useState(null)
  const [otherAssetsCategoryRoute, setOtherAssetsCategoryRoute] = React.useState(null)

  React.useEffect(() => {
    if (props.investmentPlatform.routes) {
      let newPath = getInvestmentDynamicPath()

      if (!newPath.startsWith('/')) {
        newPath = '/' + newPath
      }

      executeIfPathExist(props.investmentPlatform.routes, 'OTHER_ASSETS', (path) => {
        setOtherAssetsGeneralRoute(newPath + path)
        setOtherAssetsListRoute(newPath + path + ROUTES.BROKER.OTHER_ASSETS_LIST.newDesignPath)
        setOtherAssetsCategoryRoute(newPath + path + ROUTES.BROKER.OTHER_ASSETS_CATEGORY.newDesignPath)
      }, ':customer_id')
    }
  }, [props.investmentPlatform.routes])

  const assetCreateHandler = (assetName) => {
    dispatch(closeAssetModal());
    setDialog({open: true, message: (<span><b>{assetName}</b> wird hinzugefügt.</span>)});
    onUpdate();
  };

  const otherAssetsList = () => {
    return (
      <AssetsList
        customerId={customerId}
        assetsData={assetsData}
      />
    )
  };

  const otherAssetsCategory = (_props) => {
    let category = ASSET_CATEGORIES_WITH_CLEARING_ACCOUNTS.find(category => category.url === _props.match.params.category_id);

    return (
      <AssetCategoryDetails
        customerId={customerId}
        dialogHandler={setDialog}
        onAssetsUpdate={onUpdate}
        assetsData={assetsData.data && assetsData.data.assets[category.id]}
        loading={assetsData.loading}
        category={category}
      />
    );
  };

  return (
    <Container className={`app-page-container`}>
      <GuideTour
        steps={getGuideSteps(width, assetsData.data, props.match)}
        title="Sonstige Vermögenswerte Info-Tour"
      />

      <Header/>
      <ProfileDetails
        customer={customer.data}
        customerLoading={customer.loading}
        customerLoadingError={customer.errors}
        investmentData={investmentData.data}
        investmentDataLoading={investmentData.loading}
        investmentDataLoadingError={investmentData.errors}
        unrealizedProfitAndLossData={unrealizedProfitAndLoss.data}
        unrealizedProfitAndLossDataLoading={unrealizedProfitAndLoss.loading}
        unrealizedProfitAndLossDataLoadingError={unrealizedProfitAndLoss.errors}
      />

      <Switch>
        {
          otherAssetsGeneralRoute && (
            <Route path={otherAssetsGeneralRoute} component={otherAssetsList} exact/>
          )
        }
        {
          otherAssetsListRoute && (
            <Route path={otherAssetsListRoute} component={otherAssetsList} exact/>
          )
        }
        {
          otherAssetsCategoryRoute && (
            <Route path={otherAssetsCategoryRoute} component={otherAssetsCategory}/>
          )
        }
        <Route path={ROUTES.BROKER.OTHER_ASSETS_LIST.path} component={otherAssetsList}/>
        <Route path={ROUTES.CUSTOMER.OTHER_ASSETS_LIST.path} component={otherAssetsList}/>
        <Route path={ROUTES.BROKER.OTHER_ASSETS_CATEGORY.path} component={otherAssetsCategory}/>
        <Route path={ROUTES.CUSTOMER.OTHER_ASSETS_CATEGORY.path} component={otherAssetsCategory}/>
      </Switch>


      <AssetModal
        open={assetModal.open}
        onModalClose={() => dispatch(closeAssetModal())}
        onAssetCreate={assetCreateHandler}
        customerId={customerId}
      />

      <SuccessDialog
        open={dialog.open}
        message={dialog.message}
        onClose={() => setDialog({...dialog, open: false})}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  assetModal: state.get('assetModal').toJS(),
  investmentPlatform: state.get('investmentPlatform').toJS()
});

export default withOtherAssetsData(connect(mapStateToProps)(withWidth()(withRouter(OtherAssets))));

const useModalStyles = makeStyles(() => ({
  dialogContentRoot: {
    '& .MuiContainer-root': {
      padding: 0
    },
    '& .MuiPaper-root': {
      boxShadow: 'none !important',
      '& > div': {
        padding: '0 !important'
      }
    }
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  },
}))

export const OtherAssetsModal = connect(mapStateToProps)(({assetModal, assetsData, customerId, dispatch, onUpdate, open, onClose}) => {

  const classes = useModalStyles();

  const [dialog, setDialog] = React.useState({open: false, message: ''});

  const [activeComponent, setActiveComponent] = React.useState('list')
  const [activeCategory, setActiveCategory] = React.useState(null)

  const assetCreateHandler = (assetName) => {
    dispatch(closeAssetModal());
    setDialog({open: true, message: (<span><b>{assetName}</b> wird hinzugefügt.</span>)});
    onUpdate();
  };

  const onAssetsUpdate = () => {
    dispatch(closeAssetModal());
    onClose();
    onUpdate();
  };

  const otherAssetsList = () => {
    return (
      <AssetsList
        customerId={customerId}
        assetsData={assetsData}
        setActiveCategory={(categoryId) => {
          setActiveComponent('category');
          setActiveCategory(categoryId);
        }}
      />
    )
  };

  const otherAssetsCategory = () => {

    const category = ASSET_CATEGORIES_WITH_CLEARING_ACCOUNTS_AS_DICT[activeCategory];

    return (
      <AssetCategoryDetails
        customerId={customerId}
        dialogHandler={setDialog}
        onAssetsUpdate={onAssetsUpdate}
        assetsData={assetsData.data && assetsData.data.assets[activeCategory]}
        loading={assetsData.loading}
        category={category}
        onGoBackClick={() => {
          setActiveComponent('list');
          setActiveCategory(null);
        }}
      />
    );
  };

  return (
    <>
      <ResponsiveDialog open={open} onClose={onClose}>
        <DialogTitle>
          <div className={classes.header}>
            <IconButton disableRipple aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContentRoot }} style={{paddingBottom: 24}}>
          {activeComponent == 'list' && otherAssetsList()}
          {activeComponent == 'category' && otherAssetsCategory()}
        </DialogContent>
      </ResponsiveDialog>

      <AssetModal
        open={assetModal.open}
        onModalClose={() => dispatch(closeAssetModal())}
        onAssetCreate={assetCreateHandler}
        customerId={customerId}
      />

      <SuccessDialog
        open={dialog.open}
        message={dialog.message}
        onClose={() => setDialog({...dialog, open: false})}
      />
    </>
  )

})