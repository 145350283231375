import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";

import Grid from "@material-ui/core/Grid";
import TabsFilter from "../../../InvestmentStrategy/List/components/TabsFilter";
import DashboardTable from "../../../../components/DashboardTable/DashboardTable";
import tableStructure from "./table-structure";
import ResponsiveDialog from "../../../../components/ResponsiveDialog/ResponsiveDialog";
import {DialogActions, DialogContent, IconButton} from "@material-ui/core";
import {CloseIcon} from "../../../../images";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import {savedComparisonsTableClasses, savedComparisonsTabsClasses} from "./styles";
import useStyles from "../../../FavoriteList/components/DeleteFavoriteListModal/styles";
import useFetchData from "../../../../hooks/useDataFetch";
import {PortfolioHandlerResource} from "../../../../utils/api";
import {getErrorMessage, paginateArray} from "../../../../utils/utils";
import DynamicFilters from "../../../InvestmentStrategy/List/components/DynamicFilters";
import {COMPARISON_TYPE_LABEL} from "../SaveComparisonModal/SaveComparisonModal";
import {commonStyles} from "../../styles";
import clsx from "clsx";
import withNotification from "../../../../components/NotificationProvider";
import {MODEL_PORTFOLIO_TYPE_LABEL, MODEL_PORTFOLIO_TYPES} from "../../../Modelportfolios/constants";

import {getAuthSelector} from "../../../../utils/redaxSelectors";
import {PROCESS_TYPES} from "../../constants";


const ALLE_TAB = { id: '0', label: 'Alle'};
const ALLE_TAB_ID= ALLE_TAB.id;

const PAGINATION_PAGE_SIZE = 15;

const mapStateToProps = (state) => ({
  auth: getAuthSelector(state),
});

const DEFAULT_AVAILABLE_ACTIONS =  [
  PROCESS_TYPES.COPY,
  PROCESS_TYPES.EDIT,
  PROCESS_TYPES.OPEN,
  PROCESS_TYPES.DELETE
];

export default connect(mapStateToProps)(withNotification((props) => {

  const availableActions = props.availableActions || DEFAULT_AVAILABLE_ACTIONS;
  const tableClasses = savedComparisonsTableClasses();
  const tabsClasses = savedComparisonsTabsClasses();
  const dialogClasses = useStyles();
  const commonClasses = commonStyles();

  const [selectedTabId, setSelectedTabId] = React.useState(ALLE_TAB_ID);
  const [currentFilterSettings, setCurrentFilterSettings] = React.useState({name: ''});

  const [dialogTarget, setDialogTarget] = React.useState();
  const closeDialog = () => {
    setDialogTarget(undefined)
  }

  // fetchComparisons - sends request; setComparisons - updates state without sending request
  const [comparisons, fetchComparisons, setComparisons] = useFetchData(`${PortfolioHandlerResource.resourceUrl}customer/product-comparison/list/`, 'get', undefined, false)

  /**
    * Trigger comparisons fetch after filter criteria changed.
  */
  React.useEffect(() => {
    let newFilterSettings = selectedTabId !== ALLE_TAB_ID ? {...currentFilterSettings, type: selectedTabId} : {...currentFilterSettings,  type: undefined};
    setCurrentFilterSettings(newFilterSettings);

    fetchComparisons(newFilterSettings)
  },[selectedTabId]);

  const TABS = React.useMemo(() => {
    const tabs = [ALLE_TAB];

    if(!!_.get(props.auth, 'user.agency.parent_agency_id')){
      tabs.push({
        id: MODEL_PORTFOLIO_TYPES.PARENT_AGENCY,
        label: `Öffentlich (${MODEL_PORTFOLIO_TYPE_LABEL[MODEL_PORTFOLIO_TYPES.PARENT_AGENCY]}) Vergleich`,
      });
    }

    tabs.push({
      id: MODEL_PORTFOLIO_TYPES.AGENCY,
      label: COMPARISON_TYPE_LABEL[MODEL_PORTFOLIO_TYPES.AGENCY]
    });

    if(!_.isEmpty(_.get(props.auth, 'user.sub_agencies'))){
      tabs.push({
        id: MODEL_PORTFOLIO_TYPES.SUB_AGENCY,
        label: `Öffentlich (${MODEL_PORTFOLIO_TYPE_LABEL[MODEL_PORTFOLIO_TYPES.SUB_AGENCY]}) Vergleich`,
      });
    }

    tabs.push({
      id: MODEL_PORTFOLIO_TYPES.PERSONAL,
      label: COMPARISON_TYPE_LABEL[MODEL_PORTFOLIO_TYPES.PERSONAL]
    });

    return tabs;
  }, [props.auth]);

  /**
   * Paginated list of comparisons.
   * @type {Object[][]}
  */
  const paginatedComparisons = React.useMemo(() => {

    if (comparisons.loading) {
      return new Array(PAGINATION_PAGE_SIZE).fill({})
    }

    if (comparisons.errors || !comparisons.data) {

      if (comparisons.errors) props.displayNotification('error', getErrorMessage(comparisons.errors));

      return [];
    }

    return [...paginateArray(_.get(comparisons, 'data', []), PAGINATION_PAGE_SIZE)]

  }, [comparisons.updatedAt]);

  const applyFilterClick = () => {
    fetchComparisons({...currentFilterSettings})
  }

  const renderFilters = () => {
    return (
      <DynamicFilters
        searchString={currentFilterSettings.name}
        onSearchFieldChange={(newValue) => setCurrentFilterSettings({...currentFilterSettings, name: newValue})}
        handleApplyFilterBtnClick={applyFilterClick}
        disabled={comparisons.loading}
      />
    )
  }

  // Action buttons handlers
  const handleComparisonDelete = async (comparisonToDelete) => {
    setDialogTarget(comparisonToDelete)
  }

  const deleteComparison = async () => {

    try {
      await PortfolioHandlerResource.at(`customer/product-comparison/${dialogTarget.id}/delete/`).delete()

      setComparisons(comparisons.data.filter(c => c.id !== dialogTarget.id))
      props.displayNotification('success', 'Vergleich gelöscht');
    } catch (error){
      props.displayNotification('error', 'Löschen fehlgeschlagen');
    } finally {
      closeDialog()
    }

  }

  return (
    <Grid container>
      <Grid item xs={12} className={commonClasses.section}>
        <TabsFilter
          customClasses={tabsClasses}
          selectedTab={selectedTabId}
          onSelectedTabChange={(_event, newSelectedTabId) => setSelectedTabId(newSelectedTabId)}
          tabs={TABS}
          renderFilters={renderFilters}
          disabled={comparisons.loading}
        />
      </Grid>

      <Grid item xs={12} className={clsx(commonClasses.section, tableClasses.container)}>
        <DashboardTable
          tableLayout={'auto'}
          structure={tableStructure}
          dataSource={paginatedComparisons}
          expanded={true}
          tableClasses={tableClasses}
          withFooter={false}
          paginationOptions={{
            paginationEnabled: true,
            pageSize: PAGINATION_PAGE_SIZE,
          }}
          options={_.merge({
            loading: comparisons.loading,
            availableActions,
            onDeleteClick: handleComparisonDelete,
            onOpenClick: function (comparisonToOpen) {props.changeSelectedProcess(comparisonToOpen, PROCESS_TYPES.OPEN)},
            onEditClick: function (comparisonToEdit) {props.changeSelectedProcess(comparisonToEdit, PROCESS_TYPES.EDIT)},
            onCopyClick: function (comparisonToCopy) {props.changeSelectedProcess(comparisonToCopy, PROCESS_TYPES.COPY)},
            onAddToComparisonClick: function (comparisonToExtend) {props.changeSelectedProcess(comparisonToExtend, PROCESS_TYPES.EXTEND)},
            auth: props.auth
          }, props.tableOptions || {})}
        />
      </Grid>

      {/* Modal */}
      <ResponsiveDialog maxWidth={'sm'} open={!_.isNil(dialogTarget)} onClose={closeDialog}>
        <DialogContent>
          <IconButton aria-label="close" className={dialogClasses.closeButton} onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
           <p className={dialogClasses.text}>Sind Sie sicher, dass Sie den Investmentvergleich <b>{ _.get(dialogTarget, 'name', '') }</b> löschen möchten?</p>
        </DialogContent>

        <DialogActions>
          <PrimaryButton
            text="Abbrechen"
            variant="outlined"
            customClasses={{root: dialogClasses.stickyButtonRoot}}
            onButtonClick={closeDialog}
          />
          <PrimaryButton
            text="Löschen"
            customClasses={{root: dialogClasses.stickyButtonRoot}}
            onButtonClick={deleteComparison}
          />
       </DialogActions>
      </ResponsiveDialog>
    </Grid>
  )
}));