import _ from "lodash";

export const getAssetsWithInactiveIds = (selectedAssets, inactiveAssets, isAllAssetsSelected, withEmptyAssets) => {
  if (!withEmptyAssets) {
    return selectedAssets;
  }

  if (isAllAssetsSelected) {
    return []
  }

  return _.concat(selectedAssets, (inactiveAssets || []).map((asset) => asset.id))

}