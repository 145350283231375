import {Dialog, DialogContent, IconButton, withStyles} from "@material-ui/core";
import React from "react";
import styles from "./styles";
import CloseIcon from "@material-ui/icons/Close";


class MessageDialog extends React.Component {

  render() {

    const { classes } = this.props;
    return (
      <Dialog maxWidth='sm' fullWidth open={this.props.open} onClose={this.props.onClose}>
        <IconButton aria-label="close" classes={{root: classes.closeButton}} onClick={this.props.onClose}>
          <CloseIcon/>
        </IconButton>
        <DialogContent className={classes.modalContent}>
            <div className={classes.iconWrapper}>
            {this.props.icon}
            </div>
            <div className={classes.messageWrapper}>
            {this.props.message}
            </div>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(MessageDialog);
