import React from 'react'
import _ from 'lodash'
import { Skeleton } from '@material-ui/lab'
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SelectFormElement from "../../../../formElement/SelectFormElement";
import {makeStyles} from "@material-ui/core";

import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from '../../../../../../../../../components/Tooltip';
import {SERVICE_CONCEPTS} from "../../../../../../../../Trades/constants";

const useCellStyles = makeStyles(() => ({
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
  },
  contractType: {
    marginLeft: 30,
    width: '80%'
  },
  helpIcon: {
    color: '#E1E3E9',
    width: 23,
    height: 23,
    transform: 'rotate(180deg)',
    marginTop: -7,
    verticalAlign: 'middle',

    '&:hover': {
      cursor: 'pointer'
    }
  },
  italicText: {
    fontStyle: 'italic'
  }
}));

const DEFAULT = '-';
const orDefault = (value) => value || DEFAULT;

let depotTypeSelection;

const onlineBankHelpText = (classes) => {
  return (
    <div>
      <span>
        Die Depoteröffnung und Antragsübermittlung zur Depotstelle erfolgt direkt online, wenn der Antrag mit der E-Unterschrift
        vom Kunden digital signiert wird. Damit ist keine weitere Übermittlung dieser Dokumente notwendig. Ansonsten kann der
        Antrag auch ausgedruckt werden.
      </span>
      <br/>
      <span className={classes.italicText}>
        Alle zusätzlichen Formulare, aus dem Formularcenter, müssen vorerst auf gewohntem Wege an die jeweilige Depotstelle gesendet werden.
      </span>
    </div>
  )
}

const DepotCell = {
  header: "Depot",
  body: {
    content: (item, options) => {

      const classes = useCellStyles();

      if (options.loading) {
        return <Skeleton />
      }

      const bankConfig = _.get(options.banksDataGrouped, item.bankCompanyId);
      const isSelected = options.selectedDepotId == item.id || false;
      const isDisabled = !_.get(bankConfig, 'onboarding_allowed', true);
      const isPIDisabled = item.is_private_investment && options.selectedServiceConcept === SERVICE_CONCEPTS.ExecutionOnly;

      if (options.selectedContract) {
        depotTypeSelection = item.contract_types.find(
          x => x.value === options.selectedContract);

      } else {
        depotTypeSelection = null;
      }

      const isOnline = _.has(depotTypeSelection, 'is_online') ? depotTypeSelection.is_online : item.is_online;

      let tooltipText = undefined;
      if (isDisabled){
        tooltipText = _.get(bankConfig, 'onboarding_disallowed_reason');
      } else if (isPIDisabled) {
        tooltipText = 'Sie können nur eine Vermögensverwaltung starten, wenn Sie eine Anlageberatung oder Anlagevermittlung durchführen.'
      } else if (isOnline) {
        tooltipText = onlineBankHelpText(classes)
      }

      return (
        <>
          <FormControlLabel
            value={item.id}
            control={<Radio value={item.id} checked={isSelected} color={'primary'} onChange={options.onDepotChange} />}
            label={item.depot}
            classes={{
              label: classes.title
            }}
            disabled={isDisabled || isPIDisabled}
          />
          {tooltipText && (
            <Tooltip 
              title={tooltipText}
              placement={"top"}
            >
                <ErrorIcon className={classes.helpIcon}/>
            </Tooltip>
          )}
          {isSelected && (
            <SelectFormElement
              value={options.selectedContract || ''}
              options={item.contract_types}
              onChange={options.onContractChange}
              custom_classes={{ textFieldRoot: classes.contractType }}
            />
          )}
        </>
      )
    }
  },
  key: 'DepotCell'
}

const DepositFeeCell = {

  header: "Depotkosten",
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }

      if (depotTypeSelection) {
        return orDefault(depotTypeSelection.depot_cost_display)
      } else {
        return DEFAULT
      }
    }
  },
  key: 'DepositFeeCell'
}

const TransactionFeeCell = {
  header: "Transaktionskosten",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      if (depotTypeSelection) {
        return orDefault(depotTypeSelection.transaction_cost_display)
      } else {
        return DEFAULT
      }

    }
  },
  key: 'TransactionFeeCell'
}

const TransactionFeeETFCell = {
  header: "Sonstige Kosten",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      if (depotTypeSelection) {
        return orDefault(depotTypeSelection.others_cost_display)
      } else {
        return DEFAULT
      }

    }
  },
  key: 'TransactionFeeETFCell'
}

export const depotsTableStructure = {
  default: [{
    content: [DepotCell]
  }, {
    content: [DepositFeeCell]
  }, {
    content: [TransactionFeeCell]
  }, {
    content: [TransactionFeeETFCell]
  }]
}