import React from 'react';
import { renderInfoIconWithLabel } from '../../../../../../utils/utils';

function getReportTypeTooltipMsg(listOfCharts, title) {
  return (
    <>
      {!!title && (<span style={{fontFamily: 'Roboto-Bold'}}>{title}</span>)}
      <ul style={{
        margin: 0,
      }}
      >
        {listOfCharts.map((chartName, index) => {
          return (<li key={index}>{chartName}</li>);
        })}
      </ul>
    </>
  );
}

export const REPORT_TYPES = {
  DEPOT_STATUS: {
    value: 0,
    description: 'Depotstatus',
    tooltipContent: <>
      {getReportTypeTooltipMsg(['Gesamtvermögen', 'Ausgewählte Depots', 'Einzeldepots'], 'Übersicht')}
      {getReportTypeTooltipMsg(['Produktname', 'ISIN/WKN', 'Morningstar-Kategorie', 'SRI', 'Stückzahl', 'Gewichtung',
        'Kurs', 'Standdatum', 'Haltedauer', 'Wert'], 'Instrumente')}
      {getReportTypeTooltipMsg(['Vermögensaufteilung'], 'Struktur')}
    </>
  },
  BASIC: {
    value: 1,
    description: 'Basis',
    tooltipContent: <>
      {getReportTypeTooltipMsg(['Gesamtvermögen', 'Ausgewählte Depots', 'Rendite im gewählten Zeitraum', 'Rendite seit Investmentbeginn', 'Einzeldepots'], 'Übersicht')}
      {getReportTypeTooltipMsg(['Historische Entwicklung & Kapitaleinsatz in €', 'Jährliche Wertentwicklung der Depots der letzten 5 Jahre in %'], 'Wertentwicklung')}
      {getReportTypeTooltipMsg(['Produktname', 'ISIN/WKN', 'Morningstar-Kategorie', 'SRI', 'Stückzahl', 'Gewichtung',
        'Kurs', 'Standdatum', 'Haltedauer', 'Wert', 'Kapitaleinsatz', 'Anfangsbestand', 'GuV (%)', 'GuV (€)', 'GuV p.a. (%)'], 'Instrumente')}
      {getReportTypeTooltipMsg(['Regionen', 'Vermögensaufteilung', 'Risikoverteilung'], 'Struktur')}
    </>
  },
  EXPERT: {
    value: 2,
    description: 'Experte',
    tooltipContent: <>
      {getReportTypeTooltipMsg(['Gesamtvermögen', 'Ausgewählte Depots', 'Rendite im gewählten Zeitraum', 'Rendite seit Investmentbeginn', 'Risikoprofil', 'Nachhaltigkeitsprofil', 'Einzeldepots'], 'Übersicht')}
      {getReportTypeTooltipMsg(['Historische Entwicklung & Kapitaleinsatz in €', 'Zeitgewichtete Rendite in %', 'Jährliche Wertentwicklung der Depots der letzten 5 Jahre in %'], 'Wertentwicklung')}
      {getReportTypeTooltipMsg(['Produktname', 'ISIN/WKN', 'Morningstar-Kategorie', 'SRI', 'Stückzahl', 'Gewichtung',
        'Kurs', 'Standdatum', 'Haltedauer', 'Wert', 'Kapitaleinsatz', 'Anfangsbestand', 'GuV (%)', 'GuV (€)', 'GuV p.a. (%)'], 'Instrumente')}
      {getReportTypeTooltipMsg(['Regionen', 'Vermögensaufteilung', 'Währungen', 'Produktgattungen', 'Branchen', 'Risikoverteilung', 'Anlageklassen', 'Assetallokation', 'Top 10 Instrumente'], 'Struktur')}
      {getReportTypeTooltipMsg(['Risikokennzahlen'], 'Risikoanalyse')}
      {getReportTypeTooltipMsg(['Morningstar Historischer Sustainability Score - Unternehmen', 'Morningstar Historischer Sustainability Score - Staaten', 'Nachhaltigkeitskennzahlen'], 'Nachhaltigkeit')}
    </>
  },
  PRO: {
    value: 3,
    description: 'Professionell',
    tooltipContent: <>
      {getReportTypeTooltipMsg(['Gesamtvermögen', 'Ausgewählte Depots', 'Rendite im gewählten Zeitraum', 'Rendite seit Investmentbeginn', 'Risikoprofil', 'Nachhaltigkeitsprofil', 'Einzeldepots'], 'Übersicht')}
      {getReportTypeTooltipMsg(['Historische Entwicklung & Kapitaleinsatz in €', 'Zeitgewichtete Rendite in %', 'Jährliche Wertentwicklung der Depots der letzten 5 Jahre in %', 'Mögliche Wertentwicklung 15 Jahre', 'Peergroup-Quintilsranking', 'Einzelperformance der Depotpositionen'], 'Wertentwicklung')}
      {getReportTypeTooltipMsg(['Produktname', 'ISIN/WKN', 'Morningstar-Kategorie', 'SRI', 'Stückzahl', 'Gewichtung',
        'Kurs', 'Standdatum', 'Haltedauer', 'Wert', 'Kapitaleinsatz', 'Anfangsbestand', 'GuV (%)', 'GuV (€)', 'GuV p.a. (%)'], 'Instrumente')}
      {getReportTypeTooltipMsg(['Regionen', 'Vermögensaufteilung', 'Währungen', 'Produktgattungen', 'Branchen', 'Risikoverteilung', 'Anlageklassen', 'Assetallokation', 'Top 10 Instrumente', 'Restlaufzeiten', 'Kreditqualitäten der Emittenten', 'Emittentenstruktur'], 'Struktur')}
      {getReportTypeTooltipMsg(['Kennzahlen', 'Rollierende 1-Jahres Volatilität-Entwicklung', 'Rollierende 1-Jahres Sharpe ratio'], 'Kennzahlen')}
      {getReportTypeTooltipMsg(['Risikokennzahlen', 'Rendite-Risiko-Diagramm', 'Historische Stresstests', 'Korrelationsmatrix'], 'Risikoanalyse')}
      {getReportTypeTooltipMsg(['Morningstar Historischer Sustainability Score - Unternehmen', 'Morningstar Historischer Sustainability Score - Staaten', 'Nachhaltigkeitskennzahlen', 'Ökologisch-nachhaltig', 'ESG-Übersicht', 'PAI-Nachhaltigkeitsfaktoren', 'SDG-Ziele für nachhaltige Entwicklung', 'Ausschlusskriterien'], 'Nachhaltigkeit')}
    </>
  },
  CUSTOM: {
    value: 4,
    description: 'Individuell',
    tooltipContent: 'Konfigurieren Sie selbst, welche Informationen in der Vermögensübersicht angezeigt werden sollen.'
  }
};

export const DEFAULT_REPORT_TYPE = REPORT_TYPES.BASIC;

export const REPORT_TYPE_OPTIONS = Object.values(REPORT_TYPES).map((reportType) => ({
  id: reportType.value,
  name: renderInfoIconWithLabel(
    <div style={{maxHeight: '400px', overflow: 'auto'}}>{reportType.tooltipContent}</div>,
    reportType.description,
    {},
    600
  )
}));


