import React from 'react';
import _ from 'lodash';
import { Skeleton } from '@material-ui/lab';
import ErrorIcon from '@material-ui/icons/Error';
import { toShortGermanFormat } from '../../../containers/FactSheetsPage/utils';

export const getRiskMetricsValue = (
  item,
  options,
  timeFrameKey,
  valueFieldKey = 'volatility_annual',
  asComponent = true,
  byComponents = true,
) => {
  const { portfolio, riskMetricsData } = options;
  const loading = _.get(
    riskMetricsData,
    `${timeFrameKey}.loading.portfolio_${portfolio.id}`,
  );
  const data = _.get(
    riskMetricsData,
    `${timeFrameKey}.data.portfolio_${portfolio.id}${
      byComponents ? '.by_components' : ''
    }`,
  );
  const errors = _.get(riskMetricsData, `1y.errors.portfolio_${portfolio.id}`);

  if (loading) {
    return asComponent ? <Skeleton /> : null;
  }

  if (errors) {
    return asComponent ? <ErrorIcon /> : null;
  }

  if (!data) {
    return asComponent ? 'k.A.' : null;
  }

  const instrumentData = byComponents
    ? _.find(data, (component) => component.code === item.isin)
    : data;

  if (!instrumentData) {
    return 'k.A.';
  }

  if (instrumentData.error) {
    if (errors) {
      return <ErrorIcon />;
    }
  }

  const value = _.get(instrumentData, `risk_metrics.${valueFieldKey}`);

  if (!asComponent) {
    return value;
  }

  return value ? toShortGermanFormat(value * 100, '', ' %', 2, false) : 'k.A.';
};
