import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  withWidth
} from '@material-ui/core';

import withCustomersData from '../../../components/CustomersDataProvider';
import {CustomerReportSettingResource, GroupResource} from '../../../utils/api';
import { REPORT_DISTRIBUTION_TYPE, REPORT_TYPES, SENDING_DATE, REPORT_GENERATION_TYPES} from '../constants';
import { ROUTES } from '../../../routes';
import SuccessDialog from '../../../components/SuccessDialog';
import { GuideTour } from '../../../components/GuideTour';

import {GroupCustomers, GroupDetails, GroupTypeSelector} from '../components';
import FiltersPanel from '../components/FiltersPanel';
import useStyles from './styles';

import {newDesignUsed} from '../../../utils/utils';
import { executeIfPathExist, getInvestmentDynamicPath } from '../../../containers/InvestmentPlatform/utils'
import {DASHBOARD_GROUP_TYPE_MENU_ITEM_MAPPING, DASHBOARD_SETTINGS_TYPE} from "../../DashboardSettings/constants";
import {displayErrorSnackBar} from "../../../components/SnackbarProvider/actions";
import {addFiltersToDynamicGroupBody, isGroupNameWithErrors} from '../utils'
import {getReportSettingsGuideSteps} from "../../DashboardSettings/guide";

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS()
});

const GroupCreatePage = (props) => {

  const classes = useStyles();

  //#region Component State

  const [selectedCustomers, setSelectedCustomers] = React.useState([]);
  const [groupSavingStatus, setGroupSavingStatus] = React.useState({
    loading: false,
    errors: undefined
  });

  const [successModalProperties, setSuccessModalProperties] = React.useState({
    open: false,
    message: ''
  });

  const [groupSettingsRoute, setGroupSettingsRoute] = React.useState(ROUTES.BROKER.REPORT_SETTINGS.path)
  const [investmentDynamicPath, setInvestmentDynamicPath] = React.useState(getInvestmentDynamicPath())

  React.useEffect(() => {
    if (newDesignUsed()) {
      let menu_item = !_.isNil(props.dashboardGroupType)
        ? DASHBOARD_GROUP_TYPE_MENU_ITEM_MAPPING[_.findKey(DASHBOARD_SETTINGS_TYPE, (value) => value===props.dashboardGroupType)]
        : 'SERIES_REPORTING'
      executeIfPathExist(props.investmentPlatform.routes, menu_item, (dynamicPath) => {
        let path = `/${investmentDynamicPath}` + dynamicPath + '/?is_from_group=true'
        setGroupSettingsRoute(path)
      })
    }
  }, [props.investmentPlatform])

  //#endregion

  /**
   * Update selected customers with new one.
   *
   * @param {Object[]} customers
   */
  const handleCustomersSelected = (customers) => {
    let selectedCustomersUpdated = [...selectedCustomers, ...customers];
    setSelectedCustomers(selectedCustomersUpdated);
  };

  /**
   * Remove provided customer from selected customers.
   *
   * @param {Object} customerToRemove
   */
  const handleCustomerDeselected = (customerToRemove) => {
    let selectedCustomersUpdated = _.filter(selectedCustomers, customer => customer.id != customerToRemove.id);
    setSelectedCustomers(selectedCustomersUpdated);
  }

  const handleSaveGroupClick = async (groupName) => {

    setGroupSavingStatus({
      loading: true,
      errors: undefined
    });

    let body = {
      report_type: REPORT_TYPES.BASIC.value,
      report_generation_type: REPORT_GENERATION_TYPES.SINGLE.value,
      distribution_type: REPORT_DISTRIBUTION_TYPE.PDF.value,
      date_range: SENDING_DATE.ONE_Y.value,
      name: groupName,
      customers: selectedCustomers.map(customer => customer.id),
      broker_id: props.auth.user.broker_id
    }

    // if dynamic group is saved - pass filters to apply
    addFiltersToDynamicGroupBody(body, isDynamicGroupProcess, props.currentFilters, props.filtersOptions)

    try {
      let err = isGroupNameWithErrors(groupName);
      if(err) throw {'data': err};

      // create dashboard or ordinary group
      !_.isNil(props.dashboardGroupType)
        ? await CustomerReportSettingResource.at('broker/create-broker-dashboard-groups-settings/').post(
          {...body, is_broker_group: props.dashboardGroupType==DASHBOARD_SETTINGS_TYPE.BROKER_GROUP})
        : await GroupResource.at('').post(body)

      props.setGroupToCustomers(selectedCustomers);

      setSuccessModalProperties({
        open: true,
        message: <p>Ihre neue Gruppe <b style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word',}}>{groupName}</b> wurde gespeichert.</p>
      })

    } catch (error) {
      if (_.isString(error)){
        props.dispatch(displayErrorSnackBar(error));
        setGroupSavingStatus({loading: false});
      } else{
        setGroupSavingStatus({
          loading: false,
          errors: error.data
        });
      }
    }
  }

  const handleSuccessModalClose = () => {
    setSuccessModalProperties({
      open: false,
      message: ''
    });
    props.history.push(groupSettingsRoute);
  };

  const [isDynamicGroupProcess, setIsDynamicGroupProcess] = React.useState(false)
  const onGroupProcessChange = (isNewTypeDynamic) => {
    // dynamic groups filter customers at runtime -> do not save selected customers, as each runtime they may differ
    if(isNewTypeDynamic){ setSelectedCustomers([]) }
    setIsDynamicGroupProcess(isNewTypeDynamic)
  }

  return (
    <>
      <GuideTour
        steps={getReportSettingsGuideSteps(props.history)}
        title="Einstellungen Info-Tour"
      />
      <Container
        className="app-page-container"
        style={{flexWrap: 'nowrap', height: '100%'}}
      >
        <SuccessDialog
          open={successModalProperties.open}
          message={successModalProperties.message}
          onClose={handleSuccessModalClose}
        />
        <Grid container style={{height: '100%'}}>
          <Grid item lg={3} xs={12}>
            <FiltersPanel
              loading={props.globalCustomers.loading}
              disabled={!_.isNil(props.globalCustomers.errors) || groupSavingStatus.loading}
              onFiltersUpdated={props.onFiltersUpdated}
              filtersOptions={props.filtersOptions}
              hasCustomers={!_.isEmpty(props.customers)}
              // if not dashboard group process -> render group type selector in filters
              withGroupTypeRadio={_.isNil(props.dashboardGroupType)}
              setIsDynamicGroupProcess={onGroupProcessChange}
            />
          </Grid>
          <Grid
            item
            lg={9} xs={12}
            className={classes.customerListSection}
          >
            <Link
              to={groupSettingsRoute}
              className={classes.backLink}
              id="report-settings-create-group-back-button"
            >
              <i className="chevron-icon fa fa-chevron-left" />
              Zurück zur Gruppenliste
            </Link>
            <Grid container style={{height: '100%', paddingTop: 4}}>
              <Grid item lg={8} md={8} xs={12}>
                <GroupCustomers
                  customersData={props.filteredCustomersData}
                  unavailableCustomers={selectedCustomers}
                  actionType={1}
                  onSelectCustomers={handleCustomersSelected}
                  dataLoadingFailed={props.globalCustomers.errors}
                  // dynamic groups filter customers at runtime -> manual selection is disabled
                  disabled={isDynamicGroupProcess}
                />
              </Grid>
              <Grid item lg={4} md={4} xs={12}>
                <GroupDetails
                  selectedCustomers={selectedCustomers}
                  onDeselectCustomer={handleCustomerDeselected}
                  onSaveGroupClick={handleSaveGroupClick}
                  // as dynamic groups do not store customers - save is disable only if no selectedCustomers in static groups
                  saveDisabled={!isDynamicGroupProcess && selectedCustomers.length === 0}
                  isSaving={groupSavingStatus.loading}
                  errors={groupSavingStatus.errors}
                  redirectMenuItem={!_.isNil(props.dashboardGroupType)
                    ? props.dashboardGroupType === DASHBOARD_SETTINGS_TYPE.BROKER_GROUP
                      ? DASHBOARD_GROUP_TYPE_MENU_ITEM_MAPPING.BROKER_GROUP
                      : DASHBOARD_GROUP_TYPE_MENU_ITEM_MAPPING.CUSTOMER_GROUP
                    : undefined
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default connect(mapStateToProps)(withWidth()(withCustomersData(GroupCreatePage)));