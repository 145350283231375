export default () => ({
  ratingWrapper: {
    top: '4px',
    '& > span:not(:last-child)': {
      marginRight: 4,
    }
  },
  label: {
    fontWeight: 'normal',
    fontSize: 14,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  labelPlaceholder: {
    color: '#80868e',
  }
});
