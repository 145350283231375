import { makeStyles } from "@material-ui/core";

const styles = (theme) => ({
  dialogRoot: {
    zIndex: '1000 !important',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',

    '&  > div:first-child': {
      backgroundColor: 'transparent'
    }
  },
  dialogTitleRoot: {
    paddingLeft: 24,
    paddingRight: 24,

    [theme.breakpoints.only('xs')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },

    '& > h2': {
      fontFamily: 'Roboto-Regular',
      color: '#313D44',
      [theme.breakpoints.only('sm')]: {
        fontSize: 18,
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: 16,
      }
    }
  },

  dragNDropContainer: {
    borderRadius: 5,
    width: '100%',
    padding: 50,
    border: '5px dashed #D8DCDF',
    height: 230,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all',
    transitionDuration: 500,

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',

      '& > svg': {
        fontSize: 80
      },

    },

    '& p': {
      fontSize: 18,
      margin: 0,
      marginRight: 10,
      textAlign: 'center'
    }
  },
  dragNDropContainerActive: {
    borderColor: theme.palette.primary.main
  }
})

export default makeStyles(styles)