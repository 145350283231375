import React from "react";

import TextField from "@material-ui/core/TextField";

import {useStyles} from "../styles";
import clsx from "clsx";
import {BaseNumberFormat} from "../../../../../../../components/Inputs";

export const DECIMAL_SCALE = 2;

export const CurrencyNumberFormat = (props) => {
  return (
    <BaseNumberFormat
      {...props}
      decimalScale={DECIMAL_SCALE}
      suffix={props.withSuffix ? " €" : undefined}
      placeholder={props.withPlaceholder ? props.placeholder ? props.placeholder : '0,00 €' : undefined}
    />
  );
};

CurrencyNumberFormat.defaultProps = {
  withSuffix: true,
  withPlaceholder: true
}

export const PercentageNumberFormat = (props) => {
  return (
    <BaseNumberFormat
      {...props}
      decimalScale={DECIMAL_SCALE}
      suffix=" %"
      placeholder={'0,00 %'}
    />
  );
};

const InputFormElement = props => {
  const {
    label,
    required,
    value,
    disabled,
    inputComponent,
    onChange,
    type,
    custom_classes,
    inputProps,
    error,
    multiline,
    placeholder,
    onKeyDown,
    inputComponentProps,
    onBlur
  } = props;

  const classes = useStyles();

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const handleBlur = () => onBlur && onBlur();

  const inputProperties = {
    ...inputProps,
    ...{
      inputComponent: inputComponent,
      classes: {
        root: clsx(classes.inputRoot, custom_classes && custom_classes.inputRoot),
        focused: classes.inputFocused,
      }
    }
  };

  return (
    type === 'hidden' ? <TextField value={value} type={type}/> :
      <TextField
        label={label}
        required={required}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        onClick={(event) => event.stopPropagation()}
        onBlur={handleBlur}
        type={type}
        multiline={multiline}
        error={!!error}
        helperText={error}
        placeholder={placeholder || ''}
        onKeyDown={onKeyDown}
        classes={{
          root: clsx(classes.textFieldRoot, custom_classes && custom_classes.textFieldRoot)
        }}
        inputProps={inputComponentProps}
        InputProps={inputProperties}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: clsx(classes.labelRoot, custom_classes && custom_classes.labelRoot),
            focused: classes.labelFocused,
            asterisk: classes.labelAsterisk
          }
        }}
        style={props.customStyle}
      />
  )
};

export default InputFormElement;