import React from "react";
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
import "./style.css";
import {getPieChartOptions} from "../../utils";

import ClimateProtection from "../../../../../../images/FactsheetSustainabilityTab/Eco/ClimateProtection";
import WaterAndMarineResources from "../../../../../../images/FactsheetSustainabilityTab/Eco/WaterAndMarineResources";
import ReductionAnvironmentalPollution
  from "../../../../../../images/FactsheetSustainabilityTab/Eco/ReductionAnvironmentalPollution";
import Economy from "../../../../../../images/FactsheetSustainabilityTab/Eco/Economy";
import Pollution from "../../../../../../images/FactsheetSustainabilityTab/Eco/Pollution";
import PrbEcosystem from "../../../../../../images/FactsheetSustainabilityTab/Eco/PrbEcosystem";

const configuration = {
  mitigation: {
    name: "Klimaschutz",
    y: 16,
    icon: <ClimateProtection />,
  },
  adaptation: {
    name: <>Nachhaltige Nutzung und <br/> Schutz von Wasser- und<br/> Meeresressourcen</>,
    y: 16,
    icon: <WaterAndMarineResources />,
  },
  pwm_resources: {
    name: <>Vermeidung und <br/>Verminderung der <br/>Umweltverschmutzung</>,
    y: 16,
    disabled: true,
    icon: <ReductionAnvironmentalPollution />,
  },
  economy: {
    name: <>Anpassung an den Klimawandel</>,
    y: 16,
    icon: <Economy />,
  },
  pollution: {
    name: <>Übergang zu einer<br/> Kreislaufwirtschaft</>,
    y: 16,
    icon: <Pollution />,
  },
  prb_ecosystems: {
    name: <>Schutz und Wiederher-<br/>stellung der Biodiversität <br/>und der Ökosysteme</>,
    y: 16,
    icon: <PrbEcosystem />,
  },
};

class Chart extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <PieChart
      highcharts={Highcharts}
      options={getPieChartOptions(
        this.props.data || {},
        configuration,
        {
          active: [
            [0.25, "#74CAA8"],
            [0.75, "#279470"],
          ],
          inactive: [
            [0.25, "#EBF8F2"],
            [0.75, "#E2F0EA"],
          ]
        },
        "eco-chart")}
    />;
  }
}

export default Chart;
