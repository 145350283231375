/* React imports*/
import React from 'react';
import { withRouter } from "react-router-dom";
import styles from "./styles";

/* Material UI Components*/
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";

/* BCA Components */
import ModalInstruments from "./modals/ModalInstruments";
import ConfirmationDialog from "./modals/ConfirmationDialog";
import SuccessDialog from "./modals/SuccessDialog";

/* BCA modules */
import {toGermanFormat} from "../../utils/numberFormater";
import {
  parseResponse,
  VirtualPortfolioHandlerResource
} from "../../utils/api";
import {
  buildCurrentCustomerVirtualPortfolioManager,
} from "../../routes";
import {isAssetImported} from "../../utils/virtualPortfoliosUtils";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "../../components/Tooltip";


class VirtualPortfolioActionsManager extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      portfolio: {},
      portfolioId: props.portfolio_id,
      customerId: props.customer_id,
      showModal: false,
      selectedAssetData: undefined,
      instrumentError: null,
      instrumentProcessing: false,
      showDeleteConfirmation: false,
      assetDeletingInProgress: false,
      instrumentToDeleteId: undefined,
      instrumentToDeleteName: undefined,
      showSuccessDeleteDialog: false,
      selectedAssetId: undefined,
    }

    this.closeModal = this.closeModal.bind(this);
    this.processInstrument = this.processInstrument.bind(this);
    this.closeInstrumentDeletingModal = this.closeInstrumentDeletingModal.bind(this);
    this.deleteInstrument = this.deleteInstrument.bind(this);
    this.closeSuccessDeleteDialog = this.closeSuccessDeleteDialog.bind(this);
    this.initInstrumentEditing = this.initInstrumentEditing.bind(this);
  }

  processInstrument(instrumentData, isUpdate, oneTimePaymentData=undefined, paymentPlanData=undefined) {

    this.setState({
      instrumentProcessing: true
    })

    let data = {
      instrument_data: instrumentData
    }

    if (paymentPlanData) {
      data['payment_plan'] = paymentPlanData;
    }

    if (oneTimePaymentData) {
      data['one_time_payment_data'] = oneTimePaymentData;
    }

    if (isUpdate) {
      this.updateInstrument(data, instrumentData['asset_id'])
    } else {
      this.createInstrument(data)
    }
  }

  createInstrument(data) {
    if (this.state.portfolioId && this.state.portfolioId !== 'new') {
      data['portfolio_id'] = this.state.portfolioId;
    } else {
      data['portfolio_name'] = this.state.portfolioName
    }

    VirtualPortfolioHandlerResource.createInstrument(this.state.customerId, [data])
      .then((response) => {
        parseResponse(response[0], 'instrument',
          (response) => {
            const portfolioId = response['group'][0]['id']
            window.history.pushState({}, null, buildCurrentCustomerVirtualPortfolioManager(portfolioId));
            this.setState({
              portfolioId: portfolioId,
              instrumentProcessing: false,
            }, () => this.fetchPortfolioData())
          },
          error => {
            this.processInstrumentError(error)
          }
        )
      })
      .catch(error => {
        this.processInstrumentError(error)
      })
  }

  updateInstrument(data, asset_id) {
    data['portfolio_id'] = this.state.portfolioId;

    VirtualPortfolioHandlerResource.updateInstrument(this.state.customerId, asset_id, data)
      .then((response) => {
        parseResponse(response, 'instrument',
          () => {
            this.setState({
              instrumentProcessing: false,
            }, () => this.props.refresh())
          },
          error => {
            this.processInstrumentError(error)
          }
        )
      })
      .catch(error => {
        this.processInstrumentError(error)
      })
  }

  deleteInstrument() {
    this.setState({
      assetDeletingInProgress: true
    });

    (this.props.resource || VirtualPortfolioHandlerResource).deleteInstrument(this.state.customerId, this.state.instrumentToDeleteId)
      .then((response) => {
        parseResponse(response, 'instrument',
          () => {
            this.setState({
                showSuccessDeleteDialog: true,
                showDeleteConfirmation: false,
                assetDeletingInProgress: false
              });
            this.props.refresh()
          },
          error => {
            console.log(error)
          this.processInstrumentDeletingError(error)
          }
        )
      })
      .catch(error => {
        console.log(error)
        this.processInstrumentDeletingError(error)
      })
  }

  formatNumber(number) {
    return number || number === 0 ? toGermanFormat(number, '', '', 2) : '-'
  }

  initInstrumentDeleting(instrumentId, instrumentName) {
    this.setState({
      showDeleteConfirmation: true,
      instrumentToDeleteId: instrumentId,
      instrumentToDeleteName: instrumentName,
    })
  }

  initInstrumentEditing(instrumentId) {
    this.setState({
      selectedAssetId: instrumentId
    }, () => this.setState({
      showModal: true,
    }))
  }

  processInstrumentDeletingError(error) {
    this.setState({
      showDeleteConfirmation: false,
      assetDeletingInProgress: false
    })
  }

  closeInstrumentDeletingModal() {
    this.setState({
      showDeleteConfirmation: false
    })
  }

  processInstrumentError(error) {
    this.setState({
      instrumentProcessing: false,
      instrumentError: error
    })
  }

  closeModal() {
    this.setState({
      showModal: false,
      selectedAssetId: undefined,
      instrumentError: false
    })
  }

  closeSuccessDeleteDialog() {
    if (this.props.numberOfAssets >= 1) {
      this.setState({
        showSuccessDeleteDialog: false
      })
    }
  }


  render() {
    const { classes, asset } = this.props;

    return (
      <React.Fragment>
        <>
        {/* if new virtual order is not enabled - use old logic */}
        {!this.props.tradingActionsComponent ?
          (
            <>
            {isAssetImported(asset) ?
                <div>
                    <Tooltip
                    placement={"bottom"}
                    title={'Die Orderhistorie der Wertpapiere wurde in der Vorgängerapplikation DepotStar4 erstellt. Depotstar5 unterstützt keine Mehrfachkäufe/-verkäufe. Aus diesem Grund ist die Bearbeitung für dieses Wertpapier deaktiviert. Bitte beachten Sie, dass die Analysen aufgrund der Datenverfügbarkeit abweichen können. Wir empfehlen Ihnen daher ein neues virtuelles Depot anzulegen.'}
                    >
                    <div>
                        <ErrorIcon className={classes.importedPortfolioIcon}/>
                        <span className={classes.disabledLink}> Bearbeiten </span>
                    </div>
                    </Tooltip>
                </div>
                :
                <div>
                <Button
                    className={classes.linkButton}
                    onClick={() => this.initInstrumentEditing(asset.id)}
                    disableRipple={true}
                >
                    Bearbeiten
                </Button>
                </div>
            }
          </>
          ) : (
            <>{this.props.tradingActionsComponent}</>
          )
        }
        {!asset.is_profit_loss && (
          <Button
            className={classes.linkButton}
            onClick={() => this.initInstrumentDeleting(asset.id, asset.name)}
            disableRipple
          >
            Löschen
          </Button>
        )}
        </>
        <ModalInstruments
          open={this.state.showModal}
          onClose={this.closeModal}
          assetData={this.state.selectedAssetData}
          assetId={this.state.selectedAssetId}
          processInstrument={this.processInstrument}
          instrumentCreating={this.state.instrumentProcessing}
          error={this.state.instrumentError}
          customerId={this.state.customerId}
        />
        <ConfirmationDialog
          message={
            this.props.numberOfAssets === 1
            ? <p>Das virtuelle Depot muss mindestens ein Instrument enthalten. Wenn Sie dieses Instrument löschen, wird das komplette virtuelle Depot gelöscht. Sind Sie sicher, dass Sie dieses Instrument löschen möchten?</p>
            : <p>Möchten Sie wirklich <b>{this.state.instrumentToDeleteName}</b> löschen?</p>
          }
          open={this.state.showDeleteConfirmation || this.state.assetDeletingInProgress}
          onClose={this.closeInstrumentDeletingModal}
          loading={this.state.assetDeletingInProgress}
          confirm={this.deleteInstrument}
        />
        <SuccessDialog
          open={this.state.showSuccessDeleteDialog}
          onClose={this.closeSuccessDeleteDialog}
          message={
            <p>
              <b>{this.state.instrumentToDeleteName}</b> wurde erfolgreich gelöscht.
            </p>
          }
        />
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(withRouter(VirtualPortfolioActionsManager));
