import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'

import {
  Widget,
  WidgetHeader,
  WidgetBody
} from '../index'
import PrimaryButton from '../../../../components/Buttons/PrimaryButton'
import DashboardTable from '../../../../components/DashboardTable'

import useStyles from './styles'
import tableStructure from './table-data'
import {ConfigIcon} from '../../../../images'
import Link from "../../../../components/Link";
import {DEFAULT_THEME_COLOR} from "../../../../themes/themeConfigurator";
import {toGermanFormat} from "../../../../utils/numberFormater";
import _ from "lodash";

export const renderBenchmarkConfigBtn = (onEditBenchmarkClick) => {
  return (
    <>
      {!!onEditBenchmarkClick && (
        <Grid item>
          <PrimaryButton
            text="Benchmark einstellen"
            variant="outlined"
            icon={<ConfigIcon />}
            onButtonClick={onEditBenchmarkClick}
          />
        </Grid>
      )}
    </>
  )
}

function  InstrumenstList(props) {
  const {
    instruments,
    loading,
    onRemoveAssetClick,
    modelPortfolio,
    onEditWeightsClick,
    tableData,
    onEditBenchmarkClick,
    modelPortfolioType,
    onDeleteModelPortfolioClick,
    onConfirmHighRiskScore,
    withModelPortfolioData,
    withPortfolioAverageSRRI,
    averageSrriValue,
    renderTables,
    infoText
  } = props;

  const classes = useStyles();

  const handleRemoveAssetClick = (asset) => {
    if (onRemoveAssetClick) {
      onRemoveAssetClick(asset)
    }
  }

  return (
    <Widget>
      {tableData.withHeader && (
        <WidgetHeader>
          <Grid className={classes.headerContainer} container justify="space-between">
            {withModelPortfolioData && (
              <Grid item lg={7}>
                <span style={{width: '50%'}}>
                Risikoprofil <br />
                -
                </span>
                  <span style={{width: '15%'}}>
                SRI <br />
                    {modelPortfolio && modelPortfolio.srri || '-'}
                </span>
                  <span style={{width: '35%'}}>
                Geladenes {modelPortfolioType} <br />
                    {modelPortfolio ? (
                      <span style={{fontWeight: 'normal', fontFamily: 'Roboto-Regular', fontSize: 16}}>{ modelPortfolio.name }</span>
                    ): "-"}
                </span>
              </Grid>
            )}
            {withPortfolioAverageSRRI && (!modelPortfolio || !modelPortfolio.srri) && (
              <Grid item>
                <span>Gewichtetes Portfoliorisiko <br />
                  {toGermanFormat(averageSrriValue || 0)}
                </span>
              </Grid>
            )}
            {!modelPortfolio && infoText && (
              <Grid item lg={8}>
                <span>{infoText}</span>
              </Grid>
            )}
            <Grid item>
              {!!onConfirmHighRiskScore && ['Vermögensverwaltung'].includes(modelPortfolioType) && (
                <Link
                  title="Begründung"
                  style={!!_.get(modelPortfolio, 'error.risk_score_explanation') ? {marginRight: 15, color: "red"} : {marginRight: 15, color: DEFAULT_THEME_COLOR}}
                  onClick={() => onConfirmHighRiskScore(modelPortfolio)}
                />
              )}
              {!!onDeleteModelPortfolioClick && ['Modellportfolio', 'Vermögensverwaltung'].includes(modelPortfolioType) && (
                <Link
                  title="Löschen"
                  style={{color: DEFAULT_THEME_COLOR}}
                  onClick={onDeleteModelPortfolioClick}
                />
              )}
            </Grid>
            {renderBenchmarkConfigBtn(onEditBenchmarkClick)}
          </Grid>
        </WidgetHeader>
      )}
      <WidgetBody style={tableData.tableScrollable ? {overflowX: 'auto'} : {}}>
        <>
          {renderTables ? renderTables() : (
            <DashboardTable
              structure={tableData.tableStructure || tableStructure}
              dataSource={instruments || []}
              expanded={!loading}
              loadingIndicator={loading}
              tableClasses={tableData.classes || classes}
              withFooter={tableData.withFooter || false}
              withInputs={tableData.withInputs}
              options={{
                onRemoveAssetClick: handleRemoveAssetClick,
                onEditWeightsClick: onEditWeightsClick,
                itemsLength: instruments && instruments.length,
                ...tableData.tableOptions
              }}
            />
          )}
          {tableData.extra}
        </>

      </WidgetBody>
    </Widget>
  )
}

InstrumenstList.propTypes = {
  /** List of instruments */
  instruments: PropTypes.arrayOf(PropTypes.shape({})),

  /** Flag, that indicate, if instruments data is loading at the moment, or not */
  loading: PropTypes.bool,

  /** Asset remove event handler */
  onRemoveAssetClick: PropTypes.func,

  /** Asset edit weights event handler */
  onEditWeightsClick: PropTypes.func,

  /** Benchmark edit event handler */
  onEditBenchmarkClick: PropTypes.func,

  tableData: PropTypes.shape({
    tableStructure: PropTypes.shape({}),
    tableOptions: PropTypes.shape({}),
    withInputs: PropTypes.bool,
    withHeader: PropTypes.bool,
    tableScrollable: PropTypes.bool,
    classes: PropTypes.shape({}),
  })
}


InstrumenstList.defaultProps = {
  tableData: {
    withHeader: true,
    withInputs: false,
    tableScrollable: true,
  },
  modelPortfolioType: "Musterdepot",
  loading: false,
  withModelPortfolioData: true,
  withPortfolioAverageSRRI: false
};

export default InstrumenstList

