import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="24.609"
      viewBox="0 0 30 24.609"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_7631"
            data-name="Rechteck 7631"
            width="30"
            height="24.609"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_7859" data-name="Gruppe 7859" transform="translate(0 0)">
        <g
          id="Gruppe_7858"
          data-name="Gruppe 7858"
          transform="translate(0 0)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Pfad_6684"
            data-name="Pfad 6684"
            d="M4.453,10.547H6.211v.529a6.831,6.831,0,0,0-1.758,4.594V16.7H6.211v-1.03a5.09,5.09,0,0,1,1.5-3.622l.257-.257v-3H2.7v2.637H4.453Zm9.668.879v-.879h1.758v.529a6.831,6.831,0,0,0-1.758,4.594V16.7h1.758v-1.03a5.09,5.09,0,0,1,1.5-3.622l.257-.257v-3H12.363v2.637Zm9.668,0v-.879h1.758v.529a6.829,6.829,0,0,0-1.758,4.594V16.7h1.758v-1.03a5.09,5.09,0,0,1,1.5-3.622l.258-.257v-3H22.031v2.637ZM0,24.609H30V0H0Zm28.242-5.273h-7.09V5.273h7.09Zm-8.848,0H10.605V5.273h8.789Zm-10.547,0H1.758V5.273h7.09Zm19.395,3.516H1.758V21.094H28.242Zm0-19.336H1.758V1.757H28.242Z"
            transform="translate(0 0)"
            fill="#80858c"
          />
        </g>
      </g>
    </svg>
  );
};
