import { makeStyles } from '@material-ui/core';

export const styles = (theme) => ({
  container: {
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',
    borderRadius: 2,
    margin: 0,
    marginTop: 15,
    padding: 0,
    transform: 'translateZ(1px)'
  },
  groupDetails: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#313D44',
    margin: 0
  },
  groupName: {
    fontFamily: 'Roboto-Bold',
    fontSize: 18,
    color: '#313D44',
    margin: 0,
  },
  groupDetailsContainer: {
    padding: theme.spacing(2),
    borderRight: '1px solid #DADEE1',

    [theme.breakpoints.down('md')]: {
      '&': {
        borderBottom: '1px solid #DADEE1' ,
        borderRight: 'none',
      }
    },

    [theme.breakpoints.down('sm')]: {
      '&': {
        borderBottom: 'none' 
      }
    },
  },
  groupItemActionsWrapper: {
    padding: theme.spacing(2),
    borderTop: '1px solid #DADEE1',
  },
  groupItemActions: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    '& > span': {
      color: theme.palette.primary.main,
      fontFamily: 'Roboto-Medium',
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      padding: 8,

      '&:hover': {
        '& > span': {
          cursor: 'pointer',
          textDecoration: 'underline'
        } 
      },

      '& > i': {
        marginRight: 5
      },
      '& > div': {
        marginRight: 5
      }
    }
  },
  groupDetailSection: {
    [theme.breakpoints.down('md')]: {
      '&': {
        display: 'flex',
        justifyContent: 'space-between'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&': {
        display: 'block',
      }
    }
  },
  reportSettingsSection: {
    '& > div': {
      borderRight: '1px solid #DADEE1',
      padding: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        '&': {
          borderBottom: '1px solid #DADEE1' 
        }
      },

      [theme.breakpoints.down('sm')]: {
        '&': {
          borderBottom: '1px solid #DADEE1' 
        },
        '&:last-child': {
          borderBottom: 'none' 
        }
      }
    },
    '& > div:last-child': {
      borderRight: 'none' 
    },

    [theme.breakpoints.down('sm')]: {
      borderTop: '1px solid #DADEE1' 
    }
  },
  expandButtonWrapper: {
    marginTop: 20
  },
  reportSettingsSectionAdditional: {
    padding: 0,
    margin: 0
  },
  nonPadding: {
    padding: '0px !important'
  },

  reportSettingsSectionAdditional: {
    '& > div': {
      padding: theme.spacing(2),
    },
  },

  reportSettingsSectionAdditionalOne: {
    '& > div:first-child': {
      [theme.breakpoints.down('xs')]: {
        '&': {
          borderBottom: '1px solid #DADEE1' 
        }
      },
    },
  },

  reportSettingsSectionAdditionalTwo: {
    '& > div:first-child': {
      [theme.breakpoints.down('md')]: {
        '&': {
          borderRight: '1px solid #DADEE1' 
        }
      },
      [theme.breakpoints.down('xs')]: {
        '&': {
          borderBottom: '1px solid #DADEE1' 
        }
      },
    },
  }
});

export default makeStyles(styles);