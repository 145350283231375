import {makeStyles} from "@material-ui/core";

const overflowEllipsis = {
  display: 'inline-block',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}

const styles = (theme) => ({
  tableHead: {
    '& $tableCell': {
      backgroundColor: 'rgb(249, 249, 249)',
      alignItems: 'flex-end',
      color: '#80858C',
      height: 40
    }
  },
  tableCell: {
    fontFamily: 'Roboto-Regular',
    verticalAlign: 'middle',
    backgroundColor: 'white',
    display: (props) => props.empty ? 'table-cell' : 'inline-flex',
    alignItems: 'center',
    height: 60,
    '&:first-child': {
      width: 50,
    },
    '&:nth-child(2)': {
      width: 'calc(100% - 150px)'
    },
    '&:last-child': {
      width: 100
    },
    '& > div': {
      width: '100%',
    }
  },
  container: {
    overflowX: 'auto'
  },
  overflowEllipsis,
});

export default makeStyles(styles);