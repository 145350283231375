import React from 'react';
import _ from 'lodash'
import {withStyles} from "@material-ui/core/styles";
import styles from "../PortfolioListDropdown/styles";
import {Slider} from "@material-ui/core";
import {DropdownInput} from "../../containers/Modelportfolios/components";


const SliderRangeSelect = (props) => {
  const { theme, disabled, onChangeRange, min, max} = props;

  const [value, setValue] = React.useState([min || 0, max || 100]);

  React.useEffect(() => {
      if(!min && !max) {
          setValue([0, 100])
      }
  }, [min, max]);

  const handleChangeSlider = (event, newValue) => {
    setValue(newValue);
    if(onChangeRange) onChangeRange(newValue)
  };

  const handleChangeInputMin = (inputValue) => {
    setValue([inputValue, value[1]]);
    if(onChangeRange) onChangeRange([inputValue, value[1]])
  };

  const handleChangeInputMax = (inputValue) => {
    setValue([value[0], inputValue]);
    if(onChangeRange) onChangeRange([value[0], inputValue])
  };

  return (
    <>
    <div style={{display: 'flex'}}>
        <span style={{width: '10%', margin: '10px 10px 10px 0', minWidth: 75}}>
          <DropdownInput
            disabled={disabled}
            value={value[0]}
            onChange={handleChangeInputMin}
            type="number"
            min={0} max={100}
          />
        </span>
        <span style={{width: '80%', margin: '25px 10px 10px 10px'}}>
            <Slider
              disabled={disabled}
              min={0}
              max={100}
              step={1}
              htmlColor={theme.palette.primary.main}
              value={value}
              onChange={handleChangeSlider}
            />
        </span>
        <span style={{width: '10%', margin: '10px 0 10px 10px', minWidth: 75}}>
          <DropdownInput
            disabled={disabled}
            value={value[1]}
            onChange={handleChangeInputMax}
            type="number"
            min={0} max={100}
          />
        </span>
    </div>
    </>
  )
}

export default withStyles(styles, {withTheme: true})(SliderRangeSelect)