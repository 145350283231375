import React from "react";
import clsx from "clsx";

import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";

import styles from "../../styles";
import withStyles from "@material-ui/core/styles/withStyles";
import DocumentDownloadLink from "../DocumentDownloadLink";
import {formatDocumentDate, formatDocumentSize} from "../../../../utils";


const DocumentsTableRow = ({classes, document}) => {

  return (
    <TableRow className={classes.tableRow}>
      <TableCell>
        <div>
          <span className={clsx(classes.bold, classes.name)}>{document.description}</span>
          <br/>
          <span>{formatDocumentDate(document.generatedAt)}</span>
        </div>
      </TableCell>
      <TableCell>
        <div>
          <span>{document.function}</span>
          <br/>
          <span>{document.category}</span>
        </div>
      </TableCell>
      <TableCell>
        <div>
          <span>
            <DocumentDownloadLink
              name={document.description}
              extension={document.extension}
              id={document.id}
            />
          </span>
          <br/>
          <span>{formatDocumentSize(document.size)}</span>
        </div>
      </TableCell>
    </TableRow>
  )
};

export default withStyles(styles)(DocumentsTableRow);