import React from 'react';
import connect from "react-redux/es/connect/connect";

import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import {Grid, TextField} from "@material-ui/core";
import SelectField from "../../../../../components/AssetModal/components/SelectField";
import _ from 'lodash';
import {AUTO_REPORT_PERIODICITY, AUTO_REPORT_PERIODICITY_OPTIONS} from '../../../constants'
import ListLinksDropdown from '../../../../../components/ListLinksDropdown';
import useListStyles from '../NavigationButtons/styles';
import clsx from 'clsx';

const mapStateToProps = (state) => ({
});

const PeriodSelection = props => {
  const {
    classes,
    reportSettings,
    handleSettingUpdate,
    withSettingsButton,
    allSettings,
    handleSelectOtherReportSettings,
    selectedPeriodicity,
    setSelectedPeriodicity
  } = props;

  const list_classes = useListStyles();

  const [delay, setDelay] = React.useState(3);

  React.useEffect(() => {
    if (!_.isEmpty(reportSettings)) {
      let defaultSelectedPeriodicity = _.find(AUTO_REPORT_PERIODICITY_OPTIONS, option => option.id == reportSettings.periodicity)
      let defaultDelay = reportSettings.delay

      setSelectedPeriodicity(defaultSelectedPeriodicity)
      setDelay(defaultDelay)
    }
  }, [reportSettings])

  const handlePeriodicityChange = (newPeriodicity) => {
    setSelectedPeriodicity(newPeriodicity)
    handleSettingUpdate('periodicity')(newPeriodicity.id)
  }

  const handleDelayChange = (event) => {
    let min = 1
    let max = 30

    let newDelay = _.toNumber(event.target.value) || min

    newDelay = newDelay >= min && newDelay <= max ? newDelay : delay

    setDelay(newDelay)
    handleSettingUpdate('delay')(newDelay)
  }

  return (
      <Grid container>
        <Grid item xs={12} className={clsx(classes.wrapper, classes.customLabel)}><h4 style={{fontSize: 'inherit'}}>Regelmäßiger Kundenreport</h4></Grid>

        <Grid item xs={12} className={classes.wrapper}>
          <Grid container style={{justifyContent: 'center'}}>
            <Grid item xs={3} sm={4} md={3} lg={2} style={{marginTop: 12}}>
              <TextField
                className={classes.selectLabel}
                variant="outlined"
                disabled
                defaultValue={'Periode'}
                color={"secondary"}
                style={{borderRadius: 0, width: '100%'}}  // width: '25%'
                InputProps={{ classes: { input: classes.textField } }}
              />
            </Grid>

            <Grid item xs={9} sm={4} md={3} lg={2} style={{marginTop: 12}}>
              <SelectField
                value={selectedPeriodicity}
                onChange={handlePeriodicityChange}
                items={AUTO_REPORT_PERIODICITY_OPTIONS}
                marginTop={0}
                marginBottom={0}
                zIndex={5}
                position={'relative'}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.wrapper}>
          <span className={clsx(classes.customLabel, selectedPeriodicity.id === AUTO_REPORT_PERIODICITY.NONE.id ? classes.disabled : '')}>
            Erzeuge Report nach x Tagen
          </span>
          <TextField
            inputProps={{min: 0, max: 30, style: { textAlign: 'center' }, maxLength: 2}}
            variant="standard"
            style = {{width: '10%'}}
            value={delay}
            onChange={handleDelayChange}
            disabled={selectedPeriodicity.id === AUTO_REPORT_PERIODICITY.NONE.id}
            className={classes.customLabel}
          />
        </Grid>

        {withSettingsButton &&
          <Grid container className={[classes.wrapper, classes.settingsMenu]} style={{justifyContent: 'center'}}>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <ListLinksDropdown
                groups={allSettings}
                onLinkSelect={handleSelectOtherReportSettings}
                selectedGroupName={'Einstellungen übernehmen'}
                classes={list_classes}
              />
            </Grid>
          </Grid>
         }
      </Grid>
  )
}

export default withStyles(styles)(connect(mapStateToProps)(PeriodSelection));