import React from 'react';

import useStyles from './styles'
import {useCustomerSummaryContext} from "../../CustomerSummaryCard";
import PortfolioList from "../../../../../../components/ProfileDetails/components/PortfolioList";
import _ from "lodash";
import {Skeleton} from "@material-ui/lab";

function DepotSelection(props) {

  const {customer, loading, error} = useCustomerSummaryContext();
  const classes = useStyles();

  const handleDepotChange = (depots) => {
    props.onPortfoliosChange && props.onPortfoliosChange(depots)
  }

  return (
    <div>
      {loading ? (
        <div className={classes.loadingContainer}>
          <Skeleton style={{width: '75%'}}/>
          <Skeleton style={{width: '100%', height: 60}}/>
        </div>
      ) : (
        <>
          {customer && (
            <PortfolioList
              handleSelectedPortfolioChanged={handleDepotChange}
              portfolios={_.get(customer, 'portfolios.portfolios') || []}
              portfoliosDataLoading={loading}
              loading={loading}
              customClasses={classes}
              disabled={props.disabled}
              selectedPortfolios={props.selectedPortfolios}
            />
          )}
        </>
      )}
    </div>
  )
}

export default DepotSelection;