const styles = (theme) => ({
  container: {
    height: '100%',
    border: '1px solid #ABB6BE',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    marginBottom: 16
  },
  headerWrap: {
    flex: 0
  },
  header: {
    fontSize: 12,
    fontFamily: 'Roboto-Bold',
    color: '#FFFFFF',
    backgroundColor: '#ABB6BE',
  },
  headerTitle: {
    padding: theme.spacing(1)
  },
  contentWrap: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 300,
    overflow: 'hidden'
  },
  scrollWrap: {

    position: 'relative',
    top: 'auto',
    bottom: 'auto',
    left: 'auto',
    right: 'auto',

    '&::-webkit-scrollbar': {
      width: 4,
      height: 4
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  page: {
    margin: '10px 0px',

    '&:first-child': {
      marginTop: 0
    }
  }
});

export default styles;