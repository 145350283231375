const historical_euro_chart_1 = {
  selector: '#historical-chart',
  key: 'customer_app_dashboard_historical_chart',
  position: 'center',
};
const historical_euro_chart_2 = {
  selector: '#historical-chart-benchmark-checkbox',
  key: 'customer_app_dashboard_invested_amount_switch'
};

export const getHistoricalEuroChartChapterSteps = () => {
  return [
    historical_euro_chart_1,
    historical_euro_chart_2
  ];
};
