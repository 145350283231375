import { makeStyles } from '@material-ui/core';

const itemBorder = '1px solid #E1E3E9';

export default makeStyles((theme) => ({
  profileCard: {
    borderRadius: 2,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)'
  },
  content: {
    padding: '0px !important',
  },
  item: {
    padding: 20,
    position: 'relative',
    minHeight: 116,
    display: 'flex',
    justifyContent: 'flex-start',

    '&:nth-of-type(1)': {
      width: '28%'
    },
    '&:nth-of-type(2)': {
      width: '22%'
    },
    '&:nth-of-type(3)': {
      width: '28%'
    },
    '&:nth-of-type(4)': {
      width: '22%'
    },

    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderLeft: itemBorder,
      pointerEvents: 'none'
    },

    '&:first-child::after': {
      borderLeft: 'none'
    },

    [theme.breakpoints.down('md')]: {
      width: '50%!important',

      '&:first-child::after': {
        borderBottom: itemBorder
      },
      '&:nth-of-type(2)::after': {
        borderBottom: itemBorder,
      },
      '&:nth-of-type(3)::after': {
        borderLeft: 'none'
      },
    },

    [theme.breakpoints.down('sm')]: {
      padding: 15,
    },

    [theme.breakpoints.only('xs')]: {
      width: '100%!important',

      '&:not(:last-child)::after': {
        borderBottom: itemBorder,
        borderLeft: 'none'
      }
    }
  },
}));