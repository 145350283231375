import React from "react";
import _ from "lodash";

import { withStyles } from "@material-ui/core/styles";

import styles from "./styles";
import {isRequiredQuestion, isValidValue} from "../../../../../utils";
import SliderFormElement from "../../formElement/SliderFormElement";

function isSliderQuestionValid(question, step, afterChange, service) {
  return isRequiredQuestion(question) ? isValidValue(question.answer) : true;
}

export const SliderQuestionMethods = {
  required: isRequiredQuestion,
  valid: isSliderQuestionValid
}

const SliderQuestion = props => {
  const {
    classes,
    question,
    onAnswerChange,
  } = props;

  const options = question.config.choices || [];
  const step = 100 / (options.length - 1);

  const marks = options.map((option, index) => {
    return {
      value: 100 / (options.length - 1) * index,
      label: option.text,
      uid: option.uid,
      info: option.info
    }
  });

  const getAnswer = (value) => {
    return marks && marks.find(item => item.value === value);
  }

  const getMark = (uid) => {
    return uid && marks && marks.find(item => item.uid === uid);
  }

  const renderInfo = (info) => {
    return _.isString(info) ? info : "";
  }

  const defaultMark = marks[0];
  const currMark = getMark(question.answer) || defaultMark;

  const handleChange = (newValue) => {
    onAnswerChange(question.uid, (getAnswer(newValue) || defaultMark).uid);
  };

  React.useEffect(() => {
    if (!question.answer) {
      onAnswerChange(question.uid, defaultMark.uid);
    }
  }, [])

  return (
    <div className={classes.sliderContainer}>
      <SliderFormElement
        value={currMark.value}
        step={step}
        marks={marks}
        onChange={handleChange}
      />
      {currMark.info && (
        <div className={classes.sliderHelpText}>
          {renderInfo(currMark.info)}
        </div>
      )}
    </div>
  )
};

export default withStyles(styles)(SliderQuestion);