import {AUTH_ERROR, LOGIN_SUCCESS, LOGOUT_SUCCESS} from './constants'

export function login(user) {
    return {
        type: LOGIN_SUCCESS,
        user: user
    }
}

export function logout() {
    return {
        type: LOGOUT_SUCCESS
    }
}

export function setAuthError(error) {
    return {
        type: AUTH_ERROR,
        error
    }
}