import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    overflowX: 'hidden'
  },
  paginationWrapper: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paginationItem: {
    padding: 4
  }
})

export default makeStyles(styles)