import React, { useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx'
import _ from 'lodash'

// region Third party components
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Grid from '@material-ui/core/Grid'
import {Skeleton} from "@material-ui/lab";
// endregion

import useStyles from './styles'
import useCommonStyles from '../common_styles'
import { useFiltersContext } from "../../Filters";
import { buildSelectedValue, buildSelectOptions } from "../../../../utils";
import { CheckboxItemsList } from '../../../../../Modelportfolios/components';


/**
 * Dropdown components for selecting values from list of possible options.
 */
const Dropdown = forwardRef(({ name, label, placeholder, options, GridProps, loading, allSelectedLabel, disabled, emptyErrorMessage, RelatedFilterProps }, ref) => {

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { filters, setFilterValue, filtersRefs } = useFiltersContext();

  const [validationError, setValidationError] = React.useState(undefined);
  const [_disabled, setDisabled] = React.useState(false);

  const isDisabled = disabled || _disabled;

  const selectOptions = React.useMemo(() => {
    return buildSelectOptions(options);
  }, [options]);

  const relatedFilterName = _.get(RelatedFilterProps, 'name');
  const hasRelatedFilter = !!relatedFilterName
    && filters.hasOwnProperty(relatedFilterName)
    && filtersRefs.hasOwnProperty(relatedFilterName);

  useImperativeHandle(ref, () => ({
    isValid: (setError=true, checkRelated=true) => {

      if (!filters[name]) return false;

      if (!emptyErrorMessage || isDisabled) {
        return true
      }

      let isValid = Array.isArray(filters[name]) && filters[name].length && !filters[name].some(value => !value);
      if (!isValid && hasRelatedFilter && checkRelated && !_.get(RelatedFilterProps, 'disableIfEmpty')) {
        isValid = filtersRefs[relatedFilterName].isValid(false, false);
      }
      setError && setValidationError(!isValid ? emptyErrorMessage : undefined)

      return isValid
    }
  }))

  React.useEffect(() => {
    setValidationError(undefined);

    if (_.get(RelatedFilterProps, 'disableIfEmpty')) {
      setDisabled(!filtersRefs[relatedFilterName].isValid(false, false));
    }

  }, [filters[relatedFilterName]]);

  const handleChange = (item, checked, all) => {
    let items = [...(filters[name] || []).map(i => ({ id: i }))]

    if (!item) {
      items = checked ? all : [];
    } else {
      if (checked) {
        items.push(item)
      } else {
        items = _.filter(items, i => i.id !== item.id)
      }
    }

    setFilterValue(name, items.map(i => i.id))
    setValidationError(undefined)
  }

  const buildSelectRenderValue = (values) => {

    if (Array.isArray(values) && values.length) {
      const isAllChecked = values.length === selectOptions.length;
      // the values include only ids
      return buildSelectedValue(isAllChecked, selectOptions.filter(o => values.includes(o.id)))
    }

    return placeholder ? (<span className={commonClasses.placeholder}>{ placeholder }</span>) : null

  }

  return (
    <Grid item {...GridProps}>
      <FormControl variant="outlined" fullWidth className={clsx(validationError ? commonClasses.formControlError : commonClasses.formControl)}>
        <p className={clsx(commonClasses.inputLabel, isDisabled && commonClasses.inputLabelDisabled)}>{ label }</p>
        { loading ? (
          <Skeleton className={commonClasses.skeleton}/>
        ) : (
          <Select
            className={classes.select}
            multiple
            onChange={() => null}
            value={filters[name] || []}
            renderValue={buildSelectRenderValue}
            displayEmpty={true}
            IconComponent={KeyboardArrowDownIcon}
            error={validationError}
            disabled={isDisabled}
            classes={{
              icon: classes.selectIcon,
              root: clsx(classes.selectRoot)
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null,
              classes: {
                paper: classes.menuPaper
              },
              disableAutoFocusItem: true
            }}
          >
            <CheckboxItemsList
              items={selectOptions}
              loading={loading}
              onItemSelect={handleChange}
              selectedItems={(filters[name] || []).map(i => ({ id: i }))}
            />
          </Select>
        )}
        {validationError && (
          <p className={commonClasses.inputLabelError}>{ validationError }</p>
        )}
      </FormControl>
    </Grid>
  );
})


Dropdown.propTypes = {
  /** Name of the field */
  name: PropTypes.string.isRequired,
  /** Label of the field */
  label: PropTypes.string.isRequired,
  /** Field placeholder */
  placeholder: PropTypes.string,
  /** List of possible options for the field */
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })),
  /** Grid options */
  GridProps: PropTypes.any,
  /** Flag, that indicate, if skeleton should be displayed */
  loading: PropTypes.bool,
  /** Flag, that indicate, is field disabled, or not */
  disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  allSelectedLabel: "Alle"
}

export default Dropdown;