import React from 'react';

import DashboardInfoTooltip from '../../../../../containers/CustomerDashboardV2/components_v2/DasboardInfoTooltip';
import { makeStyles } from '@material-ui/core';

const RETURN_TOOLTIP_TEXT = 'Berechnet nach geldgewichteter Methode bzw. nach Modified Dietz. Um eine Rendite p.a. darstellen zu können, muss der ausgewählte Zeitraum mindestens 365 Tage betragen.';

const useStyles = makeStyles(() => ({
  subHeader: {
    fontSize: 16,
    position: 'relative',
    top: -5
  },
}));

const ReturnHeader = (props) => {
  const {
    title,
    subTitle,
  } = props;

  const classes = useStyles();

  return (
    <div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <b>{title}</b>
        <DashboardInfoTooltip title={RETURN_TOOLTIP_TEXT}/>
      </div>

      <span className={classes.subHeader}>{subTitle}</span>
    </div>
  )
};

export default ReturnHeader;