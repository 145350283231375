import React from 'react';
import {Skeleton} from "@material-ui/lab";
import moment from "moment/moment";
import {COMPARISON_TYPE_LABEL} from "../SaveComparisonModal/SaveComparisonModal";
import _ from 'lodash';
import {Grid, makeStyles} from "@material-ui/core";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import {smFontSizeTable, fontSizeTable} from "../../../../utils/constants";
import Link from "../../../../components/Link";
import {getModelPortfolioOwner, modelPortfolioAccessible} from "../../../Modelportfolios/utils";
import {OwnerCell} from "../../../Modelportfolios/components/InstrumentsList/table-data";
import {PROCESS_TYPES, PRODUCT_TYPE, PRODUCTS_TO_COMPARE_MAX_COUNT} from "../../constants";
import {buildProductIdUniq, getProductsIdsUniqList} from "../../utils";
import { toLocalDateTime } from '../../../../utils/utils';

const NO_DATA_PLACE_HOLDER = '-';

const getType = (item) => {
  return _.get(COMPARISON_TYPE_LABEL, `${item.access_type}`, NO_DATA_PLACE_HOLDER)
};

export const NameCell = {
  header: "Name",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return <Link title={item.name} onClick={() => options.onOpenClick(item)}/>
    }
  },
  key: 'NameCell'
}

const TypeCell = {
  header: "Typ",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return getType(item);
    }
  },
  key: 'TypeCell'
}


const UpdatedAtCell = {
  header: "Zuletzt geändert",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.updated_at ? toLocalDateTime(item.updated_at).format('DD.MM.YYYY - HH:mm') : NO_DATA_PLACE_HOLDER
    }
  },
  key: 'UpdatedAtCell'
}

const ProductsCell = {
  header: {content: <>Anzahl Vergleichsinstrumente</>, justifyContent: 'center'},
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return (item.products || []).length || NO_DATA_PLACE_HOLDER
    }
  },
  key: 'ProductsCell'
}

const useActionBtnStyles = makeStyles((theme) => ({
  iconWrapper: {
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    '&:hover': {
      cursor: 'pointer'
    },
  },
  editIcon: {
    display: 'flex',
    width: fontSizeTable,
    [theme.breakpoints.down("sm")]: {
      width: smFontSizeTable,
    },
  },
  iconDisabled: {
    '&:hover': {
      cursor: 'auto'
    },
    color: '#bdbdbd'
  }
}));

export const getProductId = (product) => _.get(product, `data.${product.productType === PRODUCT_TYPE.ASSET ? 'isin' : 'id'}`)

const ActionsCell = {
  header: '',
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      const classes = useActionBtnStyles();

      const {availableActions} = options;

      const actions = [];
      if (availableActions.includes(PROCESS_TYPES.OPEN)) {
        actions.push({className: 'fal fa-external-link', onClickHandler: options.onOpenClick,   title: 'Vergleich öffnen'})
      }
      if (availableActions.includes(PROCESS_TYPES.COPY)) {
        actions.push({className: 'fal fa-copy',          onClickHandler: options.onCopyClick,   title: 'Vergleich kopieren'})
      }
      if(modelPortfolioAccessible(item, options.auth)){
        if (availableActions.includes(PROCESS_TYPES.EDIT)) {
          actions.splice(1, 0, {
            className: 'fal fa-pencil',        onClickHandler: options.onEditClick,   title: 'Vergleich bearbeiten'
          });
        }
        if (availableActions.includes(PROCESS_TYPES.DELETE)) {
          actions.push({
            className: 'fal fa-trash-alt',     onClickHandler: options.onDeleteClick, title: 'Vergleich löschen'
          })
        }
      }
      if (availableActions.includes(PROCESS_TYPES.EXTEND)) {

        let disabled = false;
        let title = 'Vergleich öffnen';
        if (!modelPortfolioAccessible(item, options.auth)) {
          disabled = true;
          title = 'Keine Berechtigung zur Bearbeitung.';
        } else if (options.productsToExtend) {
          const existingProducts = getProductsIdsUniqList(item.products);

          // Only new products should be
          const newProductsToAdd = options.productsToExtend
            ? options.productsToExtend.filter((product) => !existingProducts.includes(
              buildProductIdUniq(getProductId(product), product.productType)))
            : [];

          disabled = newProductsToAdd.length + item.products.length > PRODUCTS_TO_COMPARE_MAX_COUNT;
          if (disabled) {
            title = 'Maximale Anzahl von 10 Produkten überschritten.'
          }
        }
        actions.push({
          className: 'fal fa-plus-circle',     onClickHandler: options.onAddToComparisonClick, title, disabled
        })
      }

      return (
        <Grid container>
          {/* loop over objects to render icons */}
          {actions.map(icon => (
            <Grid className={classes.iconWrapper} item xs={3}>
              <Tooltip title={icon.title} placement={'top'}>
                <i style={_.get(options, 'actionsStyle') || {}} className={clsx(icon.className, classes.icon, icon.disabled && classes.iconDisabled)} onClick={() => !icon.disabled && icon.onClickHandler(item)} />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      )
    }
  },
  key: 'ActionsCell'
}

const tableStructure = {
  default: [
    {
      content: [NameCell],
      orderingFunction: (item) => (item.name || '').toLowerCase()
    },
    {
      content: [TypeCell],
      orderingFunction: (item) => getType(item)
    }, {
      content: [OwnerCell],
      orderingFunction: (item) => getModelPortfolioOwner(item)
    }, {
      content: [UpdatedAtCell],
      orderingFunction: (item) => moment(item.updated_at)
    },
    {
      content: [ProductsCell],
      orderingFunction: (item) => (item.products || []).length,
      align: 'center',
    },
    {
      content: [ActionsCell],
    }

  ]
}

export default tableStructure