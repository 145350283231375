import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Slider from "@material-ui/core/Slider";

import styles from "./styles";
import clsx from "clsx";


const SliderFormElement = props => {
  const {
    classes,
    value,
    step,
    marks,
    min,
    max,
    valueLabelDisplay,
    valueLabelFormat,
    label,
    required,
    options,
    onChange,
    disabled,
    style,
    isRiskStep
  } = props;


  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  const defaultLabelValueFormat = (value) => {
    return ''
  };

  return (
    <Slider
      value={value}
      step={step}
      disabled={disabled}
      valueLabelDisplay={valueLabelDisplay || "on"}
      valueLabelFormat={valueLabelFormat || defaultLabelValueFormat}
      marks={marks}
      min={min}
      max={max}
      onChange={handleChange}
      track={false}
      classes={{
        root: classes.root,
        rail: classes.rail,
        mark: classes.mark,
        markActive: classes.markActive,
        markLabel: classes.markLabel,
        markLabelActive: classes.markLabelActive,
        valueLabel: classes.valueLabel,
        thumb: isRiskStep ? clsx(classes.thumb, classes.thumb_risk) : classes.thumb,
      }}
      style={style || {}}
    />
  );
};

export default withStyles(styles)(SliderFormElement);