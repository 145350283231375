import {
  RESET_REPORTING_STORE,
  SET_REPORTING_GROUPS,
  SET_REPORTING_CUSTOMERS,
  SET_REPORTING_GROUP,
  SET_REPORTING_CUSTOMER,
  SET_REPORTING_CUSTOMER_IN_GROUP,
  SET_REPORTING_CUSTOMERS_LOADING,
  SET_EDITOR_VARIABLES
} from "./constants";


export const resetReportingStore = () => {
  return {
    type: RESET_REPORTING_STORE
  }
};

export const setReportingGroups = (groups) => ({
  type: SET_REPORTING_GROUPS,
  groups
});

export const setReportingCustomers = (customers) => ({
  type: SET_REPORTING_CUSTOMERS,
  customers
});

export const setReportingGroup = (group) => ({
  type: SET_REPORTING_GROUP,
  group
});

export const setReportingCustomer = (customer) => ({
  type: SET_REPORTING_CUSTOMER,
  customer
});

export const setReportingCustomerInGroup = (customer) => ({
  type: SET_REPORTING_CUSTOMER_IN_GROUP,
  customer
});

export const setReportingCustomersLoading = (loading) => ({
  type: SET_REPORTING_CUSTOMERS_LOADING,
  loading
});

export const setEditorVariables = (variables) => ({
  type: SET_EDITOR_VARIABLES,
  variables
});
