import React from "react";
import _ from "lodash";
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import {withEuroOrDash} from "../../../../../../utils/utils";
import {
  isHideTradingSection,
  portfolioTransactionAmount,
} from "../../../../utils";
import EditTable from "./components/EditTable";
import {
  getModelPortfolioTransactionTypeOptions,
  PAYOUT_PLAN_TRANSACTION_TYPE_NAMES,
  SERVICE_CONCEPTS, SWITCH_PLAN_TRANSACTION_TYPE_NAMES,
  TRANSACTION_TYPE_VALUES
} from "../../../../constants";
import SelectFormElement
  from "../../../../../RiskProfiling/components/StepContent/components/formElement/SelectFormElement";
import {toGermanFormat} from "../../../../../../utils/numberFormater";
import PaymentPlanTrade from "../PaymentPlanTrade/PaymentPlanTrade";


const SwitchPlanTrade = props => {
  const {
    portfolio,
    customer_id,
    defaults,
    updateDefaults,
    dataService,
    addPaymentPlanOption,
    removePortfolioPaymentPlanOption,
    removePaymentPlanOption
  } = props;

  return (
    <PaymentPlanTrade
      paymentPlanTransactionKey='switch_plan'
      {...props}
    >
      {
        (PaymentPlanTradeProps) => (
          <Grid container className={PaymentPlanTradeProps.classes.portfolioContainer}>
            <Accordion classes={{root: PaymentPlanTradeProps.classes.accordionPortfolioRoot}}
                       expanded={PaymentPlanTradeProps.expanded.includes('portfolio')}
                       onChange={PaymentPlanTradeProps.handleExpandedChange('portfolio')}>
              <AccordionSummary
                classes={{root: PaymentPlanTradeProps.classes.accordionPortfolioHeader}}
                expandIcon={<ExpandMoreIcon htmlColor={"#fff"} />}
              >
                <Grid container>
                  <Grid item xs={12}>
                    {portfolio.data.name}
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails classes={{root: PaymentPlanTradeProps.classes.accordionDetails}}>
                {Object.keys(PaymentPlanTradeProps.groupedTransactions).map(key => {
                  if (isHideTradingSection(key, PaymentPlanTradeProps.getComponents(key), PaymentPlanTradeProps.groupedTransactions[key])) {
                    return null
                  }

                  return (
                    <Accordion
                      key={key}
                      expanded={PaymentPlanTradeProps.expanded.includes(`transaction-${key}`)}
                      onChange={PaymentPlanTradeProps.handleExpandedChange(`transaction-${key}`)}
                      classes={{ root: PaymentPlanTradeProps.classes.accordionRoot }}>
                      <AccordionSummary
                        classes={{ root: PaymentPlanTradeProps.classes.accordionHeader }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <div>{SWITCH_PLAN_TRANSACTION_TYPE_NAMES[key]} ({`${PaymentPlanTradeProps.groupedTransactions[key].length} Instrument${PaymentPlanTradeProps.groupedTransactions[key].length === 1 ? '' : 'e'}`})</div>
                      </AccordionSummary>
                      <AccordionDetails classes={{ root: PaymentPlanTradeProps.classes.accordionDetails }}>
                        <EditTable
                          action={key}
                          customer_id={customer_id}
                          portfolio={portfolio}
                          portfolioComponents={PaymentPlanTradeProps.getComponents(key)}
                          groupInstruments={PaymentPlanTradeProps.groupedTransactions[key]}
                          allInstruments={PaymentPlanTradeProps.transactions}
                          onInstrumentsChange={PaymentPlanTradeProps.onInstrumentsChange}
                          defaults={defaults}
                          updateDefaults={updateDefaults}
                          dataService={dataService}
                          addPaymentPlanOption={addPaymentPlanOption}
                          removePortfolioPaymentPlanOption={removePortfolioPaymentPlanOption}
                          removePaymentPlanOption={removePaymentPlanOption}
                        />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </AccordionDetails>
            </Accordion>

            <Grid item container className={PaymentPlanTradeProps.classes.totalAmount} alignItems={"center"}>
              {portfolio.switch_plan_errors && portfolio.switch_plan_errors.portfolio && (
                <Grid item className={clsx(PaymentPlanTradeProps.classes.error, 'Mui-error')}>{portfolio.switch_plan_errors.portfolio}</Grid>
              )}

              {/* TODO this part is copypaste of PortfolioTrade, check if it can be made as separate component */}
              <Grid className={PaymentPlanTradeProps.classes.depotTypeSection} container alignItems={"center"}>
                <Grid item xs={12} md={8} container spacing={3} alignItems={"center"}>
                  <Grid md={3} className={PaymentPlanTradeProps.classes.depotSelection}>
                    <SelectFormElement
                      value={_.get(portfolio, 'depotType.value')}
                      options={portfolio.availableDepotTypes || []}
                      onChange={PaymentPlanTradeProps.handleDepotTypeChange}
                      placeholder='Vertragsart'
                      custom_classes={{
                        inputRoot: PaymentPlanTradeProps.classes.depotSelectionInput
                      }}
                    />
                  </Grid>
                  {portfolio.depotType ? (
                    <Grid item md={9}>
                      <p className={PaymentPlanTradeProps.classes.depotTypeDescriptionLine}>Depotkosten: {_.get(portfolio, 'depotType.depot_cost_display', '')}</p>
                      <p className={PaymentPlanTradeProps.classes.depotTypeDescriptionLine}>Transaktionskosten: {_.get(portfolio, 'depotType.transaction_cost_display', '')}</p>
                      <p className={PaymentPlanTradeProps.classes.depotTypeDescriptionLine}>Sonstige Kosten: {_.get(portfolio, 'depotType.others_cost_display', '')}</p>
                    </Grid>
                  ) : (null)}
                </Grid>
                <Grid item className={PaymentPlanTradeProps.classes.depotOverall} md={4} alignItems={"center"}>
                  {dataService.serviceConcept == SERVICE_CONCEPTS.Anlageberatung && (
                    <p>Gewichtetes Portfoliorisiko: {toGermanFormat(PaymentPlanTradeProps.averageSrriValue)}</p>
                  )}
                  <p>Gesamt: {withEuroOrDash(portfolioTransactionAmount(portfolio, 'switch_plan'))}</p>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        )
      }
    </PaymentPlanTrade>
  )
};

export default SwitchPlanTrade;