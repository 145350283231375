import React from "react";
import { Grid } from "@material-ui/core";
import BaseChart from '../../../FactSheetsPage/components/BaseChart';
import useStyles from "./styles";
import { OTHER_TYPE_TRANSLATION } from "../../../../utils/constants"
import _ from 'lodash';
import PortfolioAssetStructure from '../../../CustomerDashboard/components/Widgets/components/PortfolioStructure/PortfolioAssetStructure';
import SyntheticRiskRewardChart from '../../../CustomerDashboard/components/Widgets/components/PortfolioStructure/SyntheticRiskRewardChart';
import PortfolioRegionStructure from '../../../CustomerDashboard/components/Widgets/components/PortfolioStructure/PortfolioRegionStructure';
import AssetAmountStructure
  from "../../../CustomerDashboard/components/Widgets/components/PortfolioStructure/AssetAmountStructure";
import {sortByWeight} from "../../../FactSheetsPage/utils";
import PortfolioMorningstarCategoryStructure
  from "../../../CustomerDashboard/components/Widgets/components/PortfolioStructure/PortfolioMorningstarCategoryStructure";

const PortfolioStructureTab = props => {
    const {
      breakdownData,
      customSettings,
      handleShowChartSection
    } = props;

    const classes = useStyles();
    // TODO: duplicated function /src/containers/FactSheetsPage/Tabs/FundStructure/index.js:15
    const translateBarChartLabels = (dataArray, dictionary=OTHER_TYPE_TRANSLATION) => {
      if(_.isEmpty(dataArray)) { return []; }
      return dataArray.map(item => {
        item.name = item.name_de || dictionary[item.name] || item.name;
        return item;
      })
    }

    const getDataByKey = (key) => {
      let result = [];

      if (!breakdownData.loading && !_.isNil(breakdownData.data)) {
        result = breakdownData.data.portfolio_structure[key] || [];
      }
      return result;
    }

    return (
      <>
      <Grid container spacing={2} className={classes.titleContainer}>
        {handleShowChartSection(customSettings, 'assetAllocationChart') &&
          <Grid item xs={12} sm={6} md={4}>
            <BaseChart
              chartType='bar'
              title={'Assetallokation'}
              seriesData={translateBarChartLabels(
                  sortByWeight(getDataByKey('by_type'))
              )}
              loading={breakdownData.loading}
              error={breakdownData.errors}
            />
          </Grid>
        }
        {handleShowChartSection(customSettings, 'currencyChart') &&
          <Grid item xs={12} sm={6} md={4}>
            <BaseChart
              chartType='bar'
              title={'Währungen'}
              seriesData={translateBarChartLabels(
                  sortByWeight(getDataByKey('by_currency'))
              )}
              loading={breakdownData.loading}
              error={breakdownData.errors}
              allowFloatingMixMax
            />
          </Grid>
        }
        {/* 'Top 10 Instrumente' */}
        {handleShowChartSection(customSettings, 'assetAmountChart') &&
          <Grid item xs={12} md={4}>
            <AssetAmountStructure dashboardData={breakdownData.data} loading={breakdownData.loading} />
          </Grid>
        }
        {/* Region */}
        {handleShowChartSection(customSettings, 'portfolioRegionChart') &&
          <Grid item xs={12} md={8}>
            <PortfolioRegionStructure dashboardData={breakdownData.data} loading={breakdownData.loading} />
          </Grid>
        }
        {handleShowChartSection(customSettings, 'portfolioBruncheChart') &&
          <Grid item xs={12} sm={6} md={4}>
            <BaseChart
              chartType='bar'
              title={'Branchen'}
              seriesData={translateBarChartLabels(
                  sortByWeight(getDataByKey('by_sector'))
              )}
              loading={breakdownData.loading}
              error={breakdownData.errors}
            />
          </Grid>
        }
        {handleShowChartSection(customSettings, 'maturityChart') && getDataByKey('by_maturity').length > 0 && (
          <Grid item xs={12} sm={6} md={4}>
            <BaseChart
              chartType='bar'
              title={'Restlaufzeiten'}
              seriesData={translateBarChartLabels(
                  sortByWeight(getDataByKey('by_maturity'),true, true)
              )}
              loading={breakdownData.loading}
              error={breakdownData.errors}
            />
          </Grid>
        )}
        {handleShowChartSection(customSettings, 'creditQualityChart') && getDataByKey('by_credit_quality').length > 0 && (
          <Grid item xs={12} sm={6} md={4}>
            <BaseChart
              chartType='bar'
              title={'Kreditqualitäten der Emittenten'}
              seriesData={translateBarChartLabels(
                  sortByWeight(getDataByKey('by_credit_quality'),true, true)
              )}
              loading={breakdownData.loading}
              error={breakdownData.errors}
            />
          </Grid>
        )}
        {handleShowChartSection(customSettings, 'issuerStructureChart') && getDataByKey('by_fixed_income_sub_type').length > 0 && (
          <Grid item xs={12} sm={6} md={4}>
            <BaseChart
              chartType='bar'
              title={'Emittentenstruktur'}
              seriesData={translateBarChartLabels(sortByWeight(
                getDataByKey('by_fixed_income_sub_type')
              ))}
              loading={breakdownData.loading}
              error={breakdownData.errors}
            />
          </Grid>
        )}
        {/* Anlageklasse */}
        {handleShowChartSection(customSettings, 'assetCategoriesChart') &&
          <Grid item xs={12} sm={6} md={4}>
            <PortfolioMorningstarCategoryStructure dashboardData={breakdownData.data} loading={breakdownData.loading} />
          </Grid>
        }
        {/* Produktgattung */}
        {handleShowChartSection(customSettings, 'productTypeChart') &&
          <Grid item xs={12} sm={6} md={4}>
            <PortfolioAssetStructure dashboardData={breakdownData.data} loading={breakdownData.loading} />
          </Grid>
        }
          {/* Risikoverteilung */}
        {handleShowChartSection(customSettings, 'syntheticRiskRewardChart') &&
          <Grid item xs={12} sm={6} md={4}>
            <SyntheticRiskRewardChart dashboardData={breakdownData.data} loading={breakdownData.loading} />
          </Grid>
        }

      </Grid>
      </>
    )
}

export default PortfolioStructureTab;
