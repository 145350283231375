import React from "react";
import FavoriteListSelection
  from "../../../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/components/FavoriteListSelection/FavoriteListSelection";

function FavouriteList(props){
  const {
    instrumentsHandler,
    selectedAssets,
    onInstrumentSelect,
    onSelectAll,
    activeSourceItem,
    handleDetailsClick
  } = props;

  return <FavoriteListSelection
    onDetailsClick={handleDetailsClick}
    instrumentsHandler={instrumentsHandler}
    onInstrumentSelect={onInstrumentSelect}
    onSelectAll={onSelectAll}
    selectedItem={activeSourceItem}
    selectedInstruments={selectedAssets}
    instrumentsToFilterOut={props.instrumentsToFilterOut}
  />
}

export default FavouriteList;