const historical_euro_chart_1 = {
  selector: '#historical-chart',
  key: 'customer_dashboard_historical_chart',
  position: 'center',
};
const historical_euro_chart_2 = {
  selector: '#historical-chart-benchmark-checkbox',
  key: 'customer_dashboard_investment_switch'
};

export const getHistoricalEuroChartChapterSteps = () => {
  return [
    historical_euro_chart_1,
    historical_euro_chart_2
  ];
};
