import React from 'react'
import PropTypes from 'prop-types'

import {
  Grid
} from '@material-ui/core'

import {
  Widget,
  WidgetActions,
  WidgetBody,
  WidgetHeader,
  DropdownInput,
  InstrumentsFilterList
} from '../index'
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import SearchButton from '../../../../components/Buttons/SearchButton';
import { Switch } from '../../../../components/Inputs';

import useStyles from './styles'
import { FilterIcon, SearchIcon } from '../../../../images';
import ErrorsContainer from "../ErrorsContainer/ErrorsContainer";
import _ from "lodash";

const DEFAULT_INPUT_ICON_COLOR = 'white'

function InstrumentsFilter(props) {

  const { 
    instruments, 
    loading,
    onFiltersExtendedButtonClick,
    onSearchActivate,
    onAddAssetClick,
    instrumentsDisabled,
    errors,
    extraActions,
    title,
    disableDropdown,
    disabled,
    isCustomerApp,
    WidgetProps,
    useDisabledOnExtraActions
  } = props;

  const classes = useStyles();

  const [searchValue, setSearchValue] = React.useState();
  const [onlyFavorite, setOnlyFavorite] = React.useState(false)

  const handleSearchActivate = (value) => {
    if (onSearchActivate) {
      onSearchActivate(value, onlyFavorite)
    }
  }

  const handleSearchValueChange = (value) => {
    setSearchValue((value && value.replace(/ /g, "").length) ? value : '')
  }

  const handleValueApprove = (value) => {
    handleSearchActivate(typeof value === 'string' ? value.trim() : searchValue)
  }

  const hasDropdownOptions = () => {
    if (_.isPlainObject(instruments)){
      return errors || !_.isEmpty(instruments.accepted) || !_.isEmpty(instruments.excluded)
    }

    return errors || !_.isEmpty(instruments)
  }

  return (
    <Widget {...WidgetProps}>
      <WidgetHeader>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <h2>{title}</h2>
          </Grid>
          {/* Customer does not have favorite list -> do not display switch */}
          {!isCustomerApp &&
            <Grid item>
              <div className={classes.favouriteInstrumentsContainer}>
                <span className={classes.favouriteInstrumentsText}>Suche nur von Favoritenlisten</span>
                <Switch
                  checked={onlyFavorite}
                  onChange={setOnlyFavorite}
                  disabled={disabled}
                />
              </div>
            </Grid>
          }
        </Grid>
      </WidgetHeader>
      <WidgetBody>
        <DropdownInput
          value={searchValue}
          placeholder="Instrumentenname, ISIN, oder WKN suchen"
          icon={<SearchIcon color={DEFAULT_INPUT_ICON_COLOR} />}
          dropdownContent={(
            <>
              {errors ? (
                <ErrorsContainer errors={errors} />
              ) : (
                <InstrumentsFilterList 
                  instruments={instruments} 
                  pattern={searchValue} 
                  loading={loading && !disableDropdown}
                  onAddAssetClick={onAddAssetClick}
                  instrumentsDisabled={instrumentsDisabled}
                  disabled={disabled}
                />
              )}
            </>
          )}
          dropdownVisible={(instruments || loading) && !disableDropdown}
          isOptionsLoading={!!loading}
          onChange={handleSearchValueChange}
          onValueApprove={handleValueApprove}
          autoApprove={false}
          isSearchDisabled={loading || disabled}
          disabled={disabled}
          isAssetSearch
          inputAdornment={(props, classes) => {
            return (
              <SearchButton
                {...props}
                classes={classes}
                text="Suche starten"
                hasDropdownOptions={hasDropdownOptions()}
              >
              </SearchButton>
            )
          }}
        />
      </WidgetBody>
      <WidgetActions>
        <Grid container spacing={1}>
          {extraActions && extraActions.map(btn => (
            <Grid item className={classes.extraActionItem}>
              <PrimaryButton
                key={btn.text}
                text={btn.text}
                icon={btn.icon}
                variant={btn.variant}
                disabled={btn.disabled || (useDisabledOnExtraActions && disabled)}
                onButtonClick={btn.onButtonClick}
                tooltip={btn.tooltip}
              />
            </Grid>
          ))}
          {!isCustomerApp &&
            <Grid item style={!!extraActions ? { marginLeft: 'auto'} : {}} className={classes.extraActionItemContained}>
              <PrimaryButton
                text="Expertenfilter öffnen"
                icon={<FilterIcon color="white"/>}
                onButtonClick={onFiltersExtendedButtonClick}
                disabled={disabled}
              />
            </Grid>
          }
        </Grid>
      </WidgetActions>
    </Widget>
  )
}

InstrumentsFilter.propTypes = {
  /** List of instruments */
  instruments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,

  /** Flag, that indicate, if instruments data is loading at the moment, or not */
  loading: PropTypes.bool,

  /** Flag, for showing or not dropdown even if loading state */
  disableDropdown: PropTypes.bool,

  /** Handler for 'Expert Filters' button click event */
  onFiltersExtendedButtonClick: PropTypes.func.isRequired,

  /** Handler for search input approved */
  onSearchActivate: PropTypes.func.isRequired,
  
  /** Hanler for onAddAsset event */
  onAddAssetClick: PropTypes.func,

  /** Instruments, that could not be selected */
  instrumentsDisabled: PropTypes.arrayOf(PropTypes.shape({})),

  /** Extra Buttons, that could not be clicked */
  extraActions: PropTypes.arrayOf(PropTypes.shape({})),

  /** Title for the panel */
  title: PropTypes.string
}

InstrumentsFilter.defaultProps = {
  instruments: [],
  loading: false,
  disableDropdown: false,
  title: "Investment hinzufügen",
  WidgetProps: {},
  useDisabledOnExtraActions: false
}

export default InstrumentsFilter

