import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  totalReturnCard: {
    backgroundColor: 'white',
    width: '100%',
    padding: 20,
    position: 'relative',
    minHeight: 105,
    maxHeight: 105,
    margin: '10px 0px',
    boxSizing: 'border-box',
    borderRadius: 2,
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down(1362)]: {
      justifyContent: 'flex-start',
    },

    [theme.breakpoints.only('md')]: {
      padding: 20
    },

    [theme.breakpoints.down('sm')]: {
      padding: 15,
      margin: 0
    },
  },
  totalReturnContainer: {
    width: '22%',
    marginLeft: 9,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0
    },
  },
  investmentNavigationContainer: {
    width: 'calc(78% - 9px)',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }
}))