import React from "react";

import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import {renderQuestion} from "../../../../../utils";

const EmployerInformationStep = props => {
  const {
    onAnswerChange,
    dataService
  } = props;

  const isEmployerInformationMandatory = dataService._bank.is_employer_information_mandatory;
  const isEmployerInformationEmployeeIdMandatory = dataService._bank.is_employer_information_employee_id_mandatory;

  const handleAnswerChange = (question, answer) => {
    onAnswerChange(question, answer);
    if (!isEmployerInformationMandatory) {
      if (props.questions.some((q) => !_.isEmpty(q.answer))) {
        props.questions.forEach((q) => {
          q.optional = q.uid === "employer['employee_id']" && !isEmployerInformationEmployeeIdMandatory
        })
      } else {
        props.questions.forEach((q) => {
          q.error = null
          q.optional = true
        })
      }
    }
  };

  return (
    <Grid container spacing={2}>
      {props.questions.map(q => renderQuestion(q, handleAnswerChange, dataService))}
    </Grid>
  );
};

export default EmployerInformationStep;
