import React from 'react';
import clsx from 'clsx'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid'

// region Internal components
import Navigation from './components/Navigation/Navigation'
import List from './components/List/List'
import Pagination from './components/Pagination/Pagination'
// endregion

import useStyles from './styles'
import {EMPTY_FILTERS_MESSAGE} from "./constants";
import { CircularProgress, Paper } from '@material-ui/core';
import {TRANSACTIONS_DATA_LOADING_ERROR} from "../../constants";

const TransactionsContext = React.createContext()

export function useTransactionsContext() {

  const context = React.useContext(TransactionsContext)
  if (!context) {
    throw new Error('Extracting context without wrapping your component with context Provider!')
  }

  return context

}

function Transactions({ children, transactions, loading, errors, isInline }) {

  const classes = useStyles();

  return (
    <Paper className={clsx(classes.container, !!isInline && 'container-inline')}>
      <Grid container alignItems={"flex-start"} spacing={2} className={(!transactions || loading) && classes.warningMessageContainer}>
        { loading ? (
          <CircularProgress color="primary"/>
        ) : (
          <>
            { transactions ? (
              <TransactionsContext.Provider value={{ transactions }}>
                { children }
              </TransactionsContext.Provider>
            ) : (
              <p className={classes.warningMessage}>{ errors ? TRANSACTIONS_DATA_LOADING_ERROR : EMPTY_FILTERS_MESSAGE }</p>
            )}
          </>
        )}

      </Grid>
    </Paper>
  );
}

Transactions.Navigation = Navigation
Transactions.List = List
Transactions.Pagination = Pagination

export default Transactions;