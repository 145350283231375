const styles = theme => ({
  navigation: {
    '& h2': {
      fontFamily: 'Roboto-Bold',
      fontSize: 24,
      color: '#202A38',
      margin: '15px 0'
    }
  },
  whiteBox: {
    background: 'transparent linear-gradient(180deg, transparent 0%, transparent 54.5px, #FFF 54.5px, #FFF 100%) 0% 0% no-repeat padding-box'
  },
  link: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  backwardArrow: {
    '&:before': {
      content: "''",
      display: 'inline-block',
      width: '0.6em',
      height: '0.6em',
      border: `2px solid ${theme.palette.primary.main}`,
      borderBottom: 'none',
      borderRight: 'none',
      transform: 'rotate(-45deg)',
      marginRight: '0.3em'
    }
  },
  tabContent: {
    backgroundColor: 'white',
    minHeight: '100%',
    padding: '50px 0'
  },
  tabTitle: {
    // backgroundColor: 'white',
    background: 'white linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 74%, #F5F6F7 100%) 0% 0% no-repeat padding-box',
    opacity: 1,
    fontSize: 14,
    padding: 15,
    fontFamily: 'Roboto-Bold',
    color: '#202A38',
    marginLeft: 1,
    marginRight: 2,
    "&:first-child": {
      marginLeft: 0
    },
    "&:last-child": {
      marginRight: 0
    },
    '& .Mui-selected': {
      background: 'white'
    },
    '&:focus': {
      outline: 'none'
    }
  },
  selected: {
    background: 'white',
    color: theme.palette.primary.main,
    '&:after': {
      content: "''",
      width: 10,
      height: 10,
      backgroundColor: 'transparent',
      border: `2px solid ${theme.palette.primary.main}`,
      display: 'block',
      position: 'absolute',
      transform: 'rotate(45deg)',
      borderRight: 'none',
      borderBottom: 'none',
      bottom: -4
    }
  },
  indicator: {
    display: 'none',
  },
  tabsHeader: {
    background: 'none',
    boxShadow: 'none'
  },
  loadingLine: {
    width: '30%',
    fontSize: 24,
    lineHeight: '1.2em',
    backgroundColor: '#d5d6d7',
    backgroundImage: 'linear-gradient(to right, #d5d6d7 0%, #b8bcbd 20%, #d5d6d7 40%, #d5d6d7 100%)',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: '$placeholderShimmer',
    animationTimingFunction: 'linear',

    '& p': {
      display: 'inline-block',
      margin: 0
    },

    '&:nth-child(2n)': {
      width: '75%'
    }
  },

  '@keyframes placeholderShimmer': {
    from: {
      backgroundPosition: '-468px 0',
    },
    to: {
      backgroundPosition: '468px 0',
    }
  }
})

export default styles;