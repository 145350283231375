import {ASSET_CATEGORIES} from "../components/AssetsList/components/AssetsCategories/constants";
import {buildGuideTourStep} from "../../../components/GuideTour/utils";
import {getNavigationChapterSteps} from "./navigation_chapter";
import {getKeyFiguresChapterSteps} from "./key_figures_chapter";
import {getAddAssetsChapterSteps} from "./add_assets_chapter";
import {getEditAssetsChapterSteps} from "./edit_assets_chapter";
import {buildCurrentCustomerOtherAssetsCategoryPath, buildOtherAssetsCategoryLink} from "../../../routes";

const TOUR_STORAGE_KEY = 'otherAssetsGuideTour';
const NAVIGATION_CHAPTER_KEY = 'navigation';
const KEY_FIGURES_CHAPTER_KEY = 'keyFiguresOverview';
const ADD_ASSETS_CHAPTER_KEY = 'addAssetsChapter';
const EDIT_ASSETS_CHAPTER_KEY = 'editAssetsChapter';

const buildEditAssetsChapterStep = (data, match) => {
  const asset_group = data && Object.keys(data.assets).find(key => data.assets[key].total_value > 0);
  const category = ASSET_CATEGORIES.find(category => category.id === +asset_group);

  if (category && category.url) {
    const customer_id = match.params.customer_id;
    const pathname = customer_id ? buildOtherAssetsCategoryLink(customer_id, category.url) : buildCurrentCustomerOtherAssetsCategoryPath(category.url);
    return buildGuideTourStep(4, 'Übersicht über das Bearbeiten sowie das Löschen von sonstigen Vermögenswerten',
      getEditAssetsChapterSteps(pathname), TOUR_STORAGE_KEY, EDIT_ASSETS_CHAPTER_KEY);
  }

  return null;
};

export const getGuideSteps = (width, data, match) => {
  const isCustomerView = !match.params.customer_id;
  return [
    buildGuideTourStep(1, 'Navigationsmenü',
      getNavigationChapterSteps(width, isCustomerView), TOUR_STORAGE_KEY, NAVIGATION_CHAPTER_KEY),
    buildGuideTourStep(2, 'Übersicht Vermögenskennzahlen',
      getKeyFiguresChapterSteps(), TOUR_STORAGE_KEY, KEY_FIGURES_CHAPTER_KEY),
    buildGuideTourStep(3, 'Übersicht über das Hinzufügen von sonstigen Vermögenswerten',
      getAddAssetsChapterSteps(), TOUR_STORAGE_KEY, ADD_ASSETS_CHAPTER_KEY),
    buildEditAssetsChapterStep(data, match)
  ].filter(chapter => chapter);
};