import React from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import PDFButton from '../../../../CustomerDashboardV2/components_v2/ActionButtons/PDFButton';
import PageTitle from '../../../../../components/PageLayout/components/PageTitle';
import PropTypes from 'prop-types';

const Header = (props) => {
  const {
    name,
    handlePdfExportClick,
  } = props;

  return (
    <PageTitle
      title={name}
      rightPanelItems={
        <>
          {/*<Grid item>*/}
          {/*  <ComparisonButton*/}
          {/*    customClasses={{ root: classes.panelButton }}*/}
          {/*    onButtonClick={onAddToComparisonClick}*/}
          {/*    disabled={!dashboardSettingsLoaded}*/}
          {/*  />*/}
          {/*</Grid>*/}
          {_.isFunction(handlePdfExportClick) && (
            <Grid item>
              <PDFButton onButtonClick={handlePdfExportClick} />
            </Grid>
          )}
        </>
      }
    />
  )
};

Header.propTypes = {
  name: PropTypes.element,
  factsheetLink: PropTypes.string,
};

export default Header;