const styles = theme => ({
  card: {
    background: 'white',
    position: 'relative',
    boxShadow: '0px 3px 15px #00000033',
    borderRadius: 5,
    padding: 20,
    marginBottom: 20,
    '&:last-of-type': {
      marginBottom: 0
    },
    minHeight: 150,
    height: '100%',

    '&.collapsed': {
      height: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 15,
    },
  },
  headerWrapper: {
    padding: 0,
    overflow: 'hidden',
  },
  title: {
    fontFamily: "'Roboto-Regular'",
    fontSize: 22,
    color: '#4D4F5C',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: 4,
    '&:hover': {
      overflow: 'initial',
      wordBreak: 'break-all',
      whiteSpace: 'initial',
      textOverflow: 'initial'
    },

    '& sup': {
       fontSize: '10px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    }
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#8D8D8D',
    marginTop: 7
  },
  contentWrapper: {
    paddingTop: 30,
  },
  loaderWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    left: 0,
    top: 0,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default styles;