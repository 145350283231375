import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  footer: {
    boxSizing: 'border-box',
    minHeight: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 24px',
    marginTop: '3rem!important',
    backgroundColor: '#fff!important',
    fontSize: 14,
    lineHeight: '18px',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: '8px 16px',
      fontSize: 13,
      lineHeight: '17px',
    },

    [theme.breakpoints.only('xs')]: {
      fontSize: 12,
      lineHeight: '16px',
    },

    '& ul': {
      display: 'flex',
      flexWrap: 'wrap',
      paddingLeft: 0,
      marginBottom: 0,
      listStyle: 'none',
      marginTop: 0,
      marginRight: 16,

      '& li a': {
        textDecoration: 'none !important',
        fontFamily: 'Roboto-Medium',
        background: '0 0',
        color: '#63696f',
        paddingRight: '1rem!important',
        padding: '0!important',
      }
    }
  },
  textMuted: {
    margin: 0,
    color: '#777f86!important'
  },
  agencyInfo: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  agencyInfoItem: {
    color: '#63696f',
    paddingRight: 8,
    marginRight: 8,
    position: 'relative',

    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      width: 1,
      height: 14,
      backgroundColor: '#63696f',

      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },

    '&:last-of-type': {
      paddingRight: 0,
      marginRight: 0,
      '&::after': {
        content: 'none'
      }
    }
  }
}));