import React from 'react';
import _ from 'lodash';
import ChartSectionBordered from "../../../../../components_v2/ChartSectionBordered/ChartSectionBordered";
import {StructureTable} from "../../../../../components_v2/StructureTable/StructureTable";
import {
  CustomerDashboardNoDataPlaceholder
} from "../../../../../../FactSheetsPage/components/NoDataPlaceholder";
import {sortByWeight} from "../../../../../../FactSheetsPage/utils";
import {translateBarChartLabels} from "../../../../../../FactSheetsPage/Tabs/FundStructure";
import {portfolioStructurePropsAreEquals} from '../utils';

const PortfolioRemainingTermsStructure = React.memo((props) => {
  const {
    dashboardData,
    preferredColor,
    expanded,
    onExpanded,
  } = props;

  const portfolioStructure = dashboardData && dashboardData.portfolio_structure;

  let data = [];
  if (portfolioStructure) {
    data = translateBarChartLabels(
      sortByWeight(portfolioStructure.by_maturity || [],true, true));
  }
  const hasData = !_.isEmpty(data);

  return (
    <ChartSectionBordered
      title={<b>Restlaufzeiten</b>}
      expanded={expanded}
      onExpanded={onExpanded}
      skipContentPadding={hasData}
      content={(
        <>
          {hasData ? (
            <StructureTable
              series={data || []}
              preferredColor={preferredColor}
            />
          ) : (
            <CustomerDashboardNoDataPlaceholder />
          )}
        </>
      )}
    />
  )
}, portfolioStructurePropsAreEquals);

export default PortfolioRemainingTermsStructure;
