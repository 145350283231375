import React from "react";
import {Typography, withStyles} from "@material-ui/core";
import styles from "./styles";
import Chart from "./chart";
import WarningTooltip from "../../../../../../components/WarningTooltip";
import LoadingIndicator from "../../../../../../components/LoadingIndicator/LoadingIndicator";

class PaiChart extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes, data, loading } = this.props;

    return (
      <div className={classes.card}>
        <div className={classes.titleWrapper}>
          {this.props.title && (
            <Typography variant="h2" className={classes.title}>
              {this.props.title}
            </Typography>
          )}
          {this.props.tooltip && (
            <WarningTooltip title={this.props.tooltip} iconClassName={classes.helpIcon}/>
          )}
        </div>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <Chart data={data} />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(PaiChart);
