import React from "react";
import _ from "lodash";
import useFetchData from "./useDataFetch";
import {PortfolioHandlerResource} from "../utils/api";


export const useCustomerPortfoliosList = (customerId, withEmptyPortfolios = true, withAggregatedPortfolio=false, withHistoricalPortfolios=false) => {
  const [portfoliosData, fetchPortfoliosData] = useFetchData(
    `${PortfolioHandlerResource.resourceUrl}customer/${customerId}/customer-app-data/`, 'get');

  const fetch = async () => {
    await fetchPortfoliosData({
      with_shared_settings: false,
      with_instruments: false,
      with_companies: false,
      with_transaction_types: false,
      with_last_srri: false,
      with_portfolios_data: true,
      with_historical_portfolios: withHistoricalPortfolios
    })
  }

  const [portfolios, defaultSelected, defaultExcluded, errors] = React.useMemo(() => {
    if (portfoliosData.loading) {
      return [[], [], [], null]
    }

    const errors = portfoliosData.errors || _.get(portfoliosData.data, 'customer.errors');
    if (!_.isEmpty(errors)) {
      return [[], [], [], errors]
    }

    let portfolios = _.get(portfoliosData.data, 'customer.data.0.portfolios.portfolios');
    portfolios = _.filter(portfolios, (portfolio) => {
      _.set(portfolio, 'customer_id', customerId)
      return (withAggregatedPortfolio || !!portfolio.depotNumber) && (withEmptyPortfolios || !portfolio.is_empty);
    });

    let defaultSelected = _.get(
      portfoliosData.data, 'customer.data.0.portfolios.default_selected_portfolios');
    let defaultExcluded = _.get(
      portfoliosData.data, 'customer.data.0.portfolios.default_excluded_portfolios');

    return [portfolios, defaultSelected, defaultExcluded, null]
  }, [portfoliosData.updatedAt])

  return {portfolios, defaultExcluded, loading: portfoliosData.loading, errors, fetch}
}