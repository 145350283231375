export const ADD_ROUTES = 'ADD_ROUTES';
export const SET_HEADER_INFO = 'SET_HEADER_INFO';
export const SET_REFRESH_HEADER_TRIGGER = 'SET_REFRESH_HEADER_TRIGGER';
export const SET_NEW_ALERTING_EVENTS_COUNT = 'SET_NEW_ALERTING_EVENTS_COUNT'
export const DECREASE_NEW_ALERTING_EVENTS_COUNT = 'DECREASE_NEW_ALERTING_EVENTS_COUNT'
export const CLEAN_NEW_ALERTING_EVENTS_COUNT = 'CLEAN_NEW_ALERTING_EVENTS_COUNT'

export const DIVA_URL_TYPE = {
  GET: 0,
  POST: 1,
  POST_FORM: 2
}
