import CommonAnalysisCard from "../../../../../VirtualPortfolioPage/components/AnalysisCard";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import HighChartBase from "../../../../../../components/Charts/Base";
import {FactSheetsHandlerResource} from "../../../../../../utils/api";
import moment from "moment";
import {processResponse} from "../../../../index";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getInstrName } from '../../../../../Modelportfolios/utils';
import { isDateDifferenceEqualOrBiggerThanYear } from '../../../../utils';
import { DEFAULT_EMPTY_SECTION_MESSAGE } from '../../../../../CustomerDashboard/constants';

class RollingSharpeRatioChart extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dataProvider: FactSheetsHandlerResource,

      performanceData: undefined,
      performanceError: undefined,
      performanceLoading: true,
      inceptionDate: props.assetInfo.inception_date,
    }

    this.fetchRollingSharpeRatioData = this.fetchRollingSharpeRatioData.bind(this);
  }

  componentDidMount() {
    if (isDateDifferenceEqualOrBiggerThanYear(this.state.inceptionDate)) {
      this.fetchRollingSharpeRatioData()
    } else {
      this.setState({
        performanceLoading: false,
        performanceError: true,
      })
    }
  }

  fetchRollingSharpeRatioData() {
      let dateStart = moment().subtract(1, 'years').format('YYYY-MM-DD');
      let dateEnd = moment().format('YYYY-MM-DD');
      processResponse(
          this.state.dataProvider.getAnalysePerformanceRollingData(this.props.assetInfo.isin, dateStart, dateEnd),
          'rolling', 'performance', this
      )
  }

  renderChart() {
    const { classes } = this.props;
    const sharpeName = getInstrName(this.props.assetInfo);

    const defaultChartColor = '#0596CE';
    const defaultBenchmarkColor = '#7ED2CF';
    const chartLegendStyle = {
      background: defaultChartColor
    };

    let series_data = [];

    if (this.state.performanceData) {
      series_data.push({
        type: 'line',
        name: sharpeName,
        color: defaultChartColor,
        data: this.state.performanceData.map(value => ([Date.parse(value.date), (value.sharpe_ratio_a_rolling || 0)]))
      })
    }

    return <div className={classes.chartContainer}>
      <ul className={classes.legend}>
        <li className={classes.legendItem}>
          <div className={classes.bullet} style={chartLegendStyle} />
          <label>{sharpeName}</label>
        </li>

      </ul>
      <HighChartBase className={classes.chart} options={{
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          data: {
            dateFormat: "YYYY-mm-dd"
          },
          series: series_data,
          chart: {
            type: 'line',
            height: 300,
            style: {
              fontFamily: "\"Roboto-Regular\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif",
              fontSize: 14
            }
          },
          rangeSelector: {
            enabled: false,
            allButtonsEnabled: false,
            inputEnabled: false,
            verticalAlign: 'top',
            buttons: null,
          },
          navigator: {
            enabled: false
          },
          legend: {
            enabled: false,
          },
          scrollbar: {
            enabled: false
          },
          plotOptions: {
            area: {
              fillColor: undefined
            },
            series: {
              animation: false,
              dataGrouping: {
                dateTimeLabelFormats: {
                  week: ["Woche vom %A, %b %e, %Y"],
                }
              },
              type: 'line',
              fillColor: undefined,
              compareBase: 0,
              showInNavigator: true,
            }
          },
          tooltip: {
            dateTimeLabelFormats: {
              day: "%b %e, %Y"
            },
            pointFormat: '<span><div style="display: inline-block; vertical-align: baseline; width: 10px; height: 10px; border-radius:50%; background-color: {series.color};" /><div style="display: inline; font-size: 14px; color: #80858C; margin-left: 15px;">{point.y}</div></span><br/>',
            valueDecimals: 2,
            split: false,
            shared: true,
            backgroundColor: 'white',
            shadow: {
              color: '#00000026',
              offsetX: 0,
              offsetY: 0,
              width: 10,
              opacity: 0.15
            },
            padding: 20,
            useHTML: true,
            shape: 'square',
            borderWidth: 0,
            borderRadius: 4,
            headerFormat: '<span style="font-size: 14px; font-family: Roboto-Bold;">{point.key}</span><br/><br/>',
            style: {
              fontFamily: '"Roboto-Regular"'
            }
          },
          yAxis: {
            opposite: false,
            align: 'right',
            title: {
              enabled: false
            },
            labels: {
              enabled: true,
              align: 'right',
              style: {
                fontSize: 14
              }
            }
          },
          xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
              year: '%Y'
            },
            tickLength: 0,
            labels: {
              style: {
                fontSize: 14
              }
            }
          },
          responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                tooltip: {
                  positioner: function (labelWidth, labelHeight, point) {
                    return point.isHeader ? this.getPosition(labelWidth, labelHeight, point) : { x: 80, y: 30 };
                  }
                }
              }
            }]
          }
      }} language={'DE'}/>
    </div>
  }

  renderError() {
      return <div>{'Fehler. Die Graphik "Sharpe Ratio" konnte nicht geladen werden.'}</div>
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
        classes={{
          card: classes.root
        }}
        title={'Rollierende 1-Jahres Sharpe Ratio'}
        loading={this.state.performanceLoading}
        content={
          <div className={classes.container}>
            {this.state.performanceLoading ? <CircularProgress/> : this.state.performanceError ? this.renderError() : this.state.performanceData ? this.renderChart() : DEFAULT_EMPTY_SECTION_MESSAGE}
          </div>
      }
    />
  }
}

export default withStyles(styles)(RollingSharpeRatioChart)
