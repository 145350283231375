import React from "react";
import { connect } from 'react-redux'
import {getHostRelatedSetting} from "../../utils/sharedSettings"
import {HOST_RELATED_SHARED_SETTINGS_KEYS, NOT_FOUND} from "../SharedSettingsProvider/constants"
import WarningTooltip from "../WarningTooltip";
import useStyles from "./styles";


const mapStateToProps = (state) => ({
    sharedSettings: state.get('sharedSettings').toJS()
});

// TODO this component can be used to display footnotes on dashboard like period, portfolio... p.a. warnings
export default connect(mapStateToProps)((props) => {
  const {
    sharedSettingKey,
    sharedSettings
  } = props;

  const classes = useStyles();

  let explanation = getHostRelatedSetting(sharedSettings.data, sharedSettingKey) || NOT_FOUND

  if ([
    HOST_RELATED_SHARED_SETTINGS_KEYS.DASHBOARD_HISTORICAL_CHART_EXPLANATION,
    HOST_RELATED_SHARED_SETTINGS_KEYS.DASHBOARD_PERFORMANCE_CHART_EXPLANATION
  ].includes(sharedSettingKey)) {
    const explanations = explanation.split('<tooltip>');
    if (explanations.length > 1) {
      return (
        <span className={classes.text}>
          <span dangerouslySetInnerHTML={{__html: explanations[0]}}/>
          <WarningTooltip
            interactive
            title={
              <div style={{display: 'flex'}}>
                Eine Beispielrechnung finden Sie&nbsp;
                <a target="_blank" href="/static/reports/Zusammenfassung Renditeberechnung_neu.pdf">hier</a>
              </div>
            }
          />
          <span dangerouslySetInnerHTML={{__html: explanations[1]}}/>
        </span>
      )
    }
  }

  return (
    <span className={classes.text} dangerouslySetInnerHTML={{__html: explanation}}/>
  )
})
