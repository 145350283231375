import { makeStyles } from '@material-ui/core';

export const styles = (theme) => ({
  customerLoginContainer: {
    width: '80%',
    maxWidth: 400
  },
  forgotPasswordLink: {
    marginTop: 50, 
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',

    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
    },

    '& > a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontFamily: 'Roboto-Medium',
      fontSize: 14,

      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 50,

    '& > img': {
      height: 100,
      width: 'auto'
    }
  }
});

export default makeStyles(styles);