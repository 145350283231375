import {MODEL_PORTFOLIO_TYPES} from "../Modelportfolios/constants";

export const FAVORITE_LIST_DELETE_SUCCESS_MESSAGE = 'Favoritenliste wurde gelöscht.';
export const FAVORITE_LIST_DELETE_ERROR_MESSAGE = 'Favoritenliste kann nicht gelöscht werden.';
export const FAVORITE_LIST_CREATE_ERROR_MESSAGE = 'Favoritenliste konnte nicht gespeichert werden.';

export const FAVORITE_LIST_TYPE = {
  PERSONAL: {
    label: 'Persönliche Favoritenliste',
    value: 1
  },
  AGENCY: {
    label: 'Öffentliche Favoritenliste',
    value: 2
  }
};

export const FAVORITE_LIST_TYPE_LABEL = {
  [MODEL_PORTFOLIO_TYPES.PERSONAL]: 'Meine Liste',
  [MODEL_PORTFOLIO_TYPES.AGENCY]: 'Meine Agentur',
  [MODEL_PORTFOLIO_TYPES.PUBLIC]: 'Öffentlich',
  [MODEL_PORTFOLIO_TYPES.CUSTODIAN_AGENCY]: 'Custodian Agency Favorite List',
  [MODEL_PORTFOLIO_TYPES.PARENT_AGENCY]: 'Agentur',
  [MODEL_PORTFOLIO_TYPES.SUB_AGENCY]: 'Unteragentur'
};