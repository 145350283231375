import { makeStyles } from '@material-ui/core';
import { fontSizeTable, smFontSizeTable } from '../../../utils/constants';

export default makeStyles((theme) => ({
  negative: {
    backgroundColor: '#6bcddc',
    fontFamily: 'Roboto-Bold',
  },
  weak: {
    backgroundColor: '#78bf5d',
    fontFamily: 'Roboto-Bold',
  },
  middle: {
    backgroundColor: '#f1bc41',
    fontFamily: 'Roboto-Bold',
  },
  strong: {
    backgroundColor: '#d56e2a',
    fontFamily: 'Roboto-Bold',
  },
  correlationMatrix: {
    fontFamily: 'Roboto-Regular',
    borderCollapse: 'collapse',
    width: '100%',

    overflowX: 'scroll',
    position: 'relative',
    display: 'block',

    '& thead td': {
      backgroundColor: '#F5F6F7',
      paddingTop: 8,
      paddingBottom: 8,
      border: 'none',
      fontFamily: 'Roboto-Bold',
      color: '#80858C',
      fontSize: fontSizeTable,
      '&:first-child': {
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: smFontSizeTable,
      }
    },
    '& tbody td': {
      '&:first-child': {
        position: 'sticky',
        left: 0,
        zIndex: 1,
        border: 'none',
        padding: 0,
        height: 42,

        [theme.breakpoints.down("sm")]: {
          height: 30
        }
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: smFontSizeTable,
      }
    }
  },
  emptyValue: {
    backgroundColor: '#F0F0F0',
    color: 'inherit !important',
  },
  tableCell: {
    textAlign: 'center',
    minWidth: 32,
    [theme.breakpoints.up("sm")]: {
      minWidth: 65,
    },
    borderBottom: 'solid 1px #D8DCDF',
    color: 'white'
  },
  columnName: {
    textAlign: 'left important!',
    width: '100%'
  },
  columnNumber: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
      width: 50,
    },
    textAlign: 'center',
    paddingRight: 2,
    width: 24,
    display: 'inline-block'
  },
  assetName: {
    color: '#202A38',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    width: 'calc(100% - 135px)',
    verticalAlign: 'middle',
    [theme.breakpoints.only("xs")]: {
      width: 'calc(100% - 70px)',
    }
  },
  columnTitle: {
    borderBottom: 'solid 1px #D8DCDF',
    backgroundColor: 'white',
    maxWidth: "50svw" // 50% of visible window width
  },
  weightColumn: {
    width: 85,
    textAlign: 'right',
    display: 'inline-block',
    paddingRight: 10,
    paddingLeft: 5,
    [theme.breakpoints.only("xs")]: {
      width: 45,
      paddingRight: 2,
      paddingLeft: 2,
    }
  },

  nameHeader: {
    display: 'inline-block',
    // minWidth: 350,
    textAlign: 'left',
    paddingLeft: 50,
    width: 'calc(100% - 85px)',
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 0,
      width: 'calc(100% - 45px)',
    }
  },
  weightHeader: {
    display: 'inline-block',
    textAlign: 'right'
  },
  active: {
    backgroundColor: '#626262 !important',
    color: '#fff !important',

    '& *': {
      color: '#fff'
    }
  },
  highlighted: {
    backgroundColor: '#F0F7FD',
    fontFamily: 'Roboto-Bold',
  },
  highlightedValue: {
    fontFamily: 'Roboto-Bold',
  },
  highlightIcon: {
    color: theme.palette.primary.main,
    marginRight: 5,
  },
  titleWrapper: {
    height: '100%',
    display: 'block',
    borderBottom: 'solid 1px #D8DCDF',
    borderRight: 'solid 1px #D8DCDF',
    paddingTop: 10,
    paddingBottom: 10,

    [theme.breakpoints.only("xs")]: {
      paddingTop: 6,
      paddingBottom: 6,
    }
  }
}))