import { makeStyles } from '@material-ui/core'

const styles = (theme) => ({
  container: {
    overflowY: 'auto',
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingLeft: '0!important',
    paddingRight: '0!important',
    maxWidth: '100%',
    marginTop: 70, // margin for navigation
    [theme.breakpoints.down('sm')]: {
      marginTop: 100,
    }
  },
  loadingIndicator: {
    color: theme.palette.primary.main
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  multilineContentValidator: {
    width: 645,
    position: 'absolute',
    top: 0,
    left: 0,
    visibility: 'hidden',

    '& > div': {
      width: '100%',
      height: 'auto',
      margin: 0,
      padding: 0,
      fontFamily: 'Roboto-Regular',
      fontSize: 10,

      '& > p': {
        margin: 0,
        padding: 0,
      }
    }
  }
});

export default styles;