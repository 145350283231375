import {buildGuideTourStep} from "../../../components/GuideTour/utils";
import {getNavigationChapterSteps} from "./navigation_chapter";
import {getKeyFiguresChapterSteps} from "./key_figures_chapter";
import {getClientDetailsChapterSteps} from "./client_details_chapter";
import {getHistoricalEuroChartChapterSteps} from "./historical_euro_chart_chapter";
import {getHistoricalPerformanceChartChapterSteps} from "./historical_performance_chart_chapter";
import {getOtherChartsChapterSteps} from "./other_charts_chapter";
import {REPORT_TYPES} from "../../DashboardSettings/components/CustomersList/components/ReportType/constants";

const TOUR_STORAGE_KEY = 'portfolioDashboardGuideTour';
const NAVIGATION_CHAPTER_KEY = 'navigation';
const KEY_FIGURES_CHAPTER_KEY = 'keyFiguresOverview';
const CLIENT_DETAILS_CHAPTER_KEY = 'clientDetails';
const HISTORICAL_EURO_CHART_CHAPTER_KEY = 'historicalEuroChart';
const HISTORICAL_PERFORMANCE_CHART_CHAPTER_KEY = 'historicalPerformanceChart';
const OTHER_CHARTS_CHAPTER_KEY = 'otherCharts';

export const getGuideSteps = (width, reportType, withOtherAssets) => {

  const steps = [
    buildGuideTourStep(1, 'Navigationsmenü',
      getNavigationChapterSteps(width), TOUR_STORAGE_KEY, NAVIGATION_CHAPTER_KEY),
    buildGuideTourStep(2, 'Übersicht Vermögenskennzahlen',
      getKeyFiguresChapterSteps(reportType, withOtherAssets), TOUR_STORAGE_KEY, KEY_FIGURES_CHAPTER_KEY),
    buildGuideTourStep(3, 'Übersicht Vermögensübersicht',
      getClientDetailsChapterSteps(), TOUR_STORAGE_KEY, CLIENT_DETAILS_CHAPTER_KEY),
  ];

  if (reportType !== REPORT_TYPES.DEPOT_STATUS.value) {
    steps.push(buildGuideTourStep(4, 'Historische Entwicklung und Kapitaleinsatz in Euro',
      getHistoricalEuroChartChapterSteps(), TOUR_STORAGE_KEY, HISTORICAL_EURO_CHART_CHAPTER_KEY))
  }

  if (reportType === REPORT_TYPES.EXPERT.value || reportType === REPORT_TYPES.PRO.value) {
    steps.push(buildGuideTourStep(5, 'Historische Wertentwicklung & Benchmark',
      getHistoricalPerformanceChartChapterSteps(), TOUR_STORAGE_KEY, HISTORICAL_PERFORMANCE_CHART_CHAPTER_KEY),)
  }

  steps.push(buildGuideTourStep(6, 'Weitere Graphen und Depotstruktur',
    getOtherChartsChapterSteps(reportType), TOUR_STORAGE_KEY, OTHER_CHARTS_CHAPTER_KEY),)

  return steps
};