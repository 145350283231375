import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  infoIcon: {
    fontSize: 16,
    color: theme.palette.primary.main,
    marginLeft: 5,
    verticalAlign: 'middle !important'
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 60,
    backgroundColor: '#0092E5',
    zIndex: 2
  },
  footerContentContainer: {
    maxWidth: '1368px!important'
  },
  footerButton: {
    position: 'relative',
    fontFamily: 'Roboto-Medium!important',
    backgroundColor: 'white!important',
    color: `${theme.palette.primary.main}!important`,
    fontSize: '16px!important',
    textTransform: 'none!important',
    lineHeight: '21px!important',
    boxShadow: 'none!important',
    padding: '7px 15px!important',
    height: 35,
    marginLeft: 15,

    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26) !important',
      '& path': {
        fill: 'rgba(0, 0, 0, 0.26) !important'
      }
    },

    '& > span > span': {
      marginLeft: 8
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    }
  },
}))