import React from 'react'
import _ from "lodash";
import {connect} from "react-redux";

import { Grid } from '@material-ui/core'

import { PortfolioExAnte } from './components'
import {savingsPlansDeleteOnly, paymentPlansTransactions, totalTransactionAmount} from "../../utils";
import {displayErrorSnackBar} from "../../../../components/SnackbarProvider/actions";
import {getErrorMessage} from "../../../../utils/utils";
import {PortfolioItem, ProtocolSummary} from "../ProtocolStep/components";

import useStyles from './styles'
import PortfolioAccordion from "./components/PortfolioAccordion";
import {TRADING_OBJECT_TYPE_TITLES, TRANSACTION_TYPE_VALUES} from "../../constants";
import {OBJECT_TYPES} from "../../../RiskProfiling/constants";

export default connect() (function ExAnte(props) {

  const {
    dataService,
    questions: [
      exAnte
    ],
  } = props;

  const classes = useStyles();

  const isPaymentPlans = paymentPlansTransactions(dataService._tradings);
  const isSavingsPlan = isPaymentPlans && dataService._tradings[0].transactions.hasOwnProperty(TRANSACTION_TYPE_VALUES.SAVINGS_PLAN);
  const isSwitchPlan = isPaymentPlans && dataService._tradings[0].transactions.hasOwnProperty(TRANSACTION_TYPE_VALUES.SWITCH_PLAN);
  const isPayoutPlan = isPaymentPlans && dataService._tradings[0].transactions.hasOwnProperty(TRANSACTION_TYPE_VALUES.PAYOUT_PLAN);
  React.useEffect(() => {
    if(!!exAnte.error){
      props.dispatch(displayErrorSnackBar(getErrorMessage(exAnte.error)));
    }
  }, [exAnte.error]);

  const getPortfolioExAnte = (portfolio, objectType) => {

    if (!exAnte.answer || !exAnte.answer.hasOwnProperty(portfolio.portfolioId)) {
      return null
    }

    if (objectType && !exAnte.answer[portfolio.portfolioId].hasOwnProperty(objectType)) {
      return null
    }

    return objectType
      ? exAnte.answer[portfolio.portfolioId][objectType]
      : exAnte.answer[portfolio.portfolioId]

  };

  const getTotalAmount = (portfolio, transactionType, objectType) => {

    let { transactions } = portfolio;

    const transactionsToCheck = objectType === OBJECT_TYPES.TRADING
      ? [transactionType, 'switch'] : []

    return transactionsToCheck.reduce((total, key) => {
      return total + totalTransactionAmount(transactions[key]);
    }, 0);
  };

  const getOneYearSavingsPlan = (exAnteData, isSell) => {
    // the initial buy (which is 0 for savings plan) and the savings plan for 1 year
    return _.get(exAnteData, `${isSell ? '1.exit' : '5.initial'}.cost_base_value`, 0);
  };

  const getOneYearSavingsPlanTitle = (action, objectType) => {
    if (action == TRANSACTION_TYPE_VALUES.SELL) {
      return objectType === OBJECT_TYPES.PAYOUT_PLAN && 'Entnahmeplan'
    } else {
      if (objectType === OBJECT_TYPES.SWITCH_PLAN) {
        return 'Tauschplan'
      }

      return 'Sparplan'
    }
  };

  const renderPortfolio = (portfolio, objectType, skipEmpty=false) => {
    const exAnteData = getPortfolioExAnte(portfolio, objectType || dataService.objectType);

    const isPaymentPlan = [OBJECT_TYPES.PAYOUT_PLAN, OBJECT_TYPES.SWITCH_PLAN, OBJECT_TYPES.SAVINGS_PLAN]
      .includes(objectType)

    if (skipEmpty && (!exAnteData || _.every(Object.keys(exAnteData), (key) => _.isEmpty(exAnteData[key])))) {
      return null
    }

    return exAnteData && (
      <Grid item xs={12}>
        <PortfolioAccordion name={portfolio.data.name} category={portfolio.categoryName} expanded>
          {Object.keys(exAnteData).map(key => (
            <Grid item xs={12}>
              <PortfolioExAnte
                portfolioName={portfolio.data.name}
                portfolioCategory={portfolio.categoryName}
                totalValue={getTotalAmount(portfolio, key, objectType)}
                exAnteData={exAnteData[key]}
                transactionType={key}
                oneYearSavingsPlan={isPaymentPlan && ((key == 'sell' && objectType === OBJECT_TYPES.PAYOUT_PLAN) || (key == 'buy' && objectType !== OBJECT_TYPES.PAYOUT_PLAN)) && getOneYearSavingsPlan(exAnteData[key], objectType === OBJECT_TYPES.PAYOUT_PLAN)}
                oneYearSavingsPlanTitle={getOneYearSavingsPlanTitle(key, objectType)}
                isPrivateInvestment={_.get(portfolio, 'data.is_private_investment')}
                simulationYears={(key === 'sell' && ([OBJECT_TYPES.PAYOUT_PLAN, OBJECT_TYPES.SWITCH_PLAN].includes(objectType))) && 1 || 5}
              />
            </Grid>
          ))}
        </PortfolioAccordion>
      </Grid>
    )
  };

  const renderPortfolios = () => {
    let portfolios = [...dataService._tradings || []];

    return portfolios.map(renderPortfolio)
  };

  let title = '';
  if (isPaymentPlans){
    if (isSavingsPlan) {
      title = 'Sparplan'
    } else if (isSwitchPlan) {
      title = 'Tauschplan'
    } else {
      title = 'Entnahmeplan'
    }
  }

  const getRotationOptions = (portfolio) => {
    if (portfolio.isModelportfolio) {
      if (isSavingsPlan) return portfolio.availableMPSavingsPlanPeriodicPlans;
      if (isSwitchPlan) return portfolio.availableMPSwitchPlanPeriodicPlans;
      return portfolio.availableMPPayoutPlanPeriodicPlans
    }

    if (isSavingsPlan) return portfolio.availableSavingsPlanPeriodicPlans;
    if (isSwitchPlan) return portfolio.availableSwitchPlanPeriodicPlans;
    return portfolio.availablePayoutPlanPeriodicPlans
  };

  const _renderExAnte = () => {

    const portfolios = [...dataService._tradings || []];

    return [OBJECT_TYPES.TRADING, OBJECT_TYPES.SAVINGS_PLAN, OBJECT_TYPES.PAYOUT_PLAN, OBJECT_TYPES.SWITCH_PLAN]
      .map((_objectType) => {

        const portfoliosRendered = portfolios.map((portfolio) => renderPortfolio(portfolio, _objectType, true))

        if (_.some(_.flatten(portfoliosRendered), (element) => !!element)) {
          return (
            <>
              <Grid item xs={12} style={{paddingBottom: 0}}>
                <h2 className={classes.exAnteHeader}>{TRADING_OBJECT_TYPE_TITLES[_objectType]} Ex-Ante Kosten</h2>
              </Grid>
              {portfoliosRendered}
            </>
          )
        }

      })

  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ProtocolSummary />
      </Grid>

      {dataService._tradings && (
        dataService._tradings.map(trading => (
          <Grid item xs={12}>
            <PortfolioItem
              portfolio={trading}
              title={trading.data.name}
              category={trading.categoryName}
              transactions={trading.transactions || {}}
              splitTransactionsByTradingType={dataService.objectType === OBJECT_TYPES.COMBINED}
              rotationOptions={getRotationOptions(trading)}
              maxQtyDecimals={trading.maxQtyDecimals}
              isModelportfolio={trading.isModelportfolio}
              modelPortfolioTransactionType={trading.modelPortfolioTransactionType}
            />
          </Grid>
        ))
      )}
      {_renderExAnte()}
    </Grid>
  )
})
