import React from "react";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import {withEuroOrDash} from "../../../utils/utils";
import {AGGREGATED_PORTFOLIO_NAME} from "../../../utils/aggregated-portfolio";
import tableStructure from "./table-data";
import DashboardTable from "../../DashboardTable/DashboardTable";
import _ from 'lodash';

const UnrealizedProfitAndLossTable = (props) => {
  const {
    classes,
    data,
    expanded
  } = props;
  const isAggregatedTable = data.portfolios_transactions_data // if data contains portfolios_transactions_data -> we render single table for all portfolios
  const totalRows = Math.max(data.inflows.length, data.outflows.length);
  const dataSource = [...Array(totalRows).keys()].map((index) => {
    return {
      inflow: data.inflows[index],
      outflow: data.outflows[index],
    }
  })
  const renderSummaryRow = (portfolio) => {
    return (
      <div key={portfolio.portfolio_id} className={classes.summary}>
        <span className={clsx(classes.ellipsisXs, classes.blockXs)}>
          <span>
            Transaktionssaldo ({portfolio.name}) =
          </span>
        </span>
        <span className={classes.blockXs}>
          <span className={classes.hideXs}>&nbsp;</span>
          <span className={classes.noWrap}>
            Zuflüsse (<b>{withEuroOrDash(portfolio.inflows_total)}</b>)
          </span>
        </span>
        <span className={classes.blockXs}>
          <span className={classes.hideXs}>&nbsp;</span>
          <span className={classes.noWrap}>
            - Abflüsse (<b>{withEuroOrDash(portfolio.outflows_total * -1)}</b>)
          </span>
        </span>
        <span className={classes.blockXs}>
          <span className={classes.hideXs}>&nbsp;</span>
          <span className={classes.noWrap}>
            = <b>{withEuroOrDash(portfolio.transaction_balance)}</b>
          </span>
        </span>
      </div>
    )
  }
  const renderPortfoliosSummary = () => {

    return isAggregatedTable ? _.values(data.portfolios_transactions_data).map(renderSummaryRow) : renderSummaryRow(data)
  }
  const renderTotalSummary = () => {
    if (_.values(data.portfolios_transactions_data).length > 1) {
      return renderSummaryRow({
        portfolio_id: '0',
        name: AGGREGATED_PORTFOLIO_NAME,
        inflows_total: data.inflows_total,
        outflows_total: data.outflows_total,
        transaction_balance: data.transaction_balance
      });
    }
  }
  return (
    <>
      <DashboardTable
        structure={tableStructure}
        dataSource={dataSource}
        expanded={expanded}
        tableClasses={classes}
        options={{
          inflows_total: data.inflows_total,
          outflows_total: data.outflows_total
        }}
      />
      {isAggregatedTable && renderTotalSummary()}
      {renderPortfoliosSummary()}
    </>
  );
};
export default withStyles(styles)(UnrealizedProfitAndLossTable);