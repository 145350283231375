import {makeStyles} from "@material-ui/core";

export default makeStyles(theme => ({
  dialogSuccessContent: {
    padding: '30px !important',
  },
  dialogSuccessContainer: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38'
  },
  questionContainer: {
    position: "relative"
  },
  addBtnContainer: {
    position: "absolute",
    top: -35,
    right: 0
  },
  paper: {
    borderRadius: 4,
    overflowY: 'visible',
    padding: "16px 0"
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  acceptButton: {
    textTransform: 'none',
    fontFamily: 'Roboto-Bold',
    borderRadius: 8,
    fontSize: 16,
    padding: '11px 22px',
  },
  modalTitle: {
    color: '#0D2440',
    '& > h2': {
      fontFamily: 'Roboto-Bold',
      fontSize: 22,
      lineHeight: 1.2,
      [theme.breakpoints.only('sm')]: {
        fontSize: 18
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: 16
      },
    }
  },
  modalAction: {
    padding: "8px 24px"
  },
  buttonRoot: {},
  buttonRootDisabled: {
    '& rect': {
      fill: 'rgba(0, 0, 0, 0.1) !important',
    },

    '& path': {
      fill: 'rgba(0, 0, 0, 0.38) !important'
    }

  }
}))