import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  header: {
    minHeight: 56,
    backgroundColor: '#323c42',
    padding: 8,
    paddingRight: 0,
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: 'none',
    boxSizing: 'border-box',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',

    '@media print': {
      display: 'none',
    },

    '& > $pageNav': {
      [theme.breakpoints.down('sm')]: {
        width: 'auto'
      },

      '& > $nav': {
        display: 'flex',
        paddingLeft: 0,
        marginBottom: 0,
        listStyle: 'none',
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: 0,

        '& > $item:only-child': {
          border: 'none'
        },

        '& > $item:nth-child(n + 2)': {
          flex: '0 1 9.375rem'
        },

        '& > $item': {
          borderRight: '1px solid #6f777e',

          '&:hover': {
            cursor: 'pointer'
          },

          '& > div': {
            position: 'relative'
          },

          '& > div > $navItem': {
            touchAction: 'manipulation',
            color: 'white',
            textDecoration: 'none',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            boxSizing: 'border-box',
            padding: '0 1rem',
            whiteSpace: 'nowrap',

            '& > i': {
              margin: 0,
              padding: '1rem .5rem',
              fontSize: '1.1rem',
              lineHeight: '1rem',
              verticalAlign: -1
            }
          },

          '&  $navLink': {
            textTransform: 'uppercase',
            height: 40,
            lineHeight: '40px',
            textAlign: 'center',
            color: 'white',
            width: '100%',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Roboto-Bold',
            fontSize: 14,
            whiteSpace: 'nowrap',
            textDecoration: 'none',

            '& > i': {
              margin: 0,
              padding: '1rem .5rem',
              fontSize: '1.1rem',
              lineHeight: '1rem',
              verticalAlign: -1
            },

            '& .chevron-icon': {
              margin: 0,
              padding: '0 7px',
              fontSize: 12,
              lineHeight: 1,
              verticalAlign: 'initial'
            },
          }
        }
      }
    },
  },
  pageNav: {},
  logo: {},
  nav: {},
  item: {},
  navLink: {},
  headerItem: {
    '& $navLink': {
      padding: '0 25px',

      [theme.breakpoints.down(1150)]: {
        padding: '0 10px'
      }
    },

  },
  funcNav: {
    marginLeft: 25,
    flex: '1 1 50vw',
    boxSizing: 'border-box',
    marginTop: 0,

    '& > $nav': {
      justifyContent: 'space-between',
      flexDirection: 'row',
      display: 'flex',
      paddingLeft: 0,
      marginBottom: 0,
      listStyle: 'none',
      marginTop: 0,
      height: 40
    }
  },
  dropdownMenu: {
    position: 'absolute',
    marginTop: 0,
    top: 'calc(100% + .5rem)!important',
    left: '0px !important',
    float: 'none',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    borderRadius: 0,
    fontSize: '.8125rem',
    minWidth: '12rem',
    zIndex: '1000',
    margin: '.125rem 0 0',
    color: '#63696f',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid rgba(0,0,0,.15)',
    boxSizing: 'border-box',
    boxShadow: '0 0 0 1000vmax rgba(0,0,0,.1), 0 0 2rem 0 rgba(0,0,0,.5)',

    '&::before': {
      left: '1.0625rem',
      pointerEvents: 'none',
      content: '""',
      position: 'absolute',
      top: '-.375rem',
      zIndex: '1050',
      overflow: 'hidden',
      width: '.75rem',
      height: '.75rem',
      backgroundImage: 'linear-gradient(135deg,#fff 50%,transparent 50%)',
      border: '1px solid rgba(0,0,0,.15)',
      borderRight: 'none',
      borderBottom: 'none',
      transform: 'rotate(45deg)'
    }
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    padding: '.75rem .9375rem',
    transition: 'background .2s cubic-bezier(.165,.84,.44,1)',
    width: '100%',
    clear: 'both',
    fontFamily: 'Roboto-Regular',
    color: '#212529',
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    textDecoration: 'none',
    position: 'relative',

    '&:nth-child(n + 2)': {
      borderTop: '1px solid #eef0f2',
    },

    '&:nth-child(2n + 1)': {
      backgroundImage: 'linear-gradient(180deg,rgba(0,0,0,.04),transparent .5rem)'
    },


    '& > i': {
      fontSize: '.9rem',
      marginRight: 3
    }
  },
  hideOnSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnExtraSmall: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  dropdown: {},
  dropdownButton: {
    lineHeight: '120%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    border: 'none',
    padding: 0,
    height: 40,
    color: '#fff',
    width: '100%',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    verticalALign: 'middle',
    whiteSpace: 'nowrap',
    position: 'relative',

    '&:focus': {
        outline: 'none'
    },

    '& $dropdownMenu': {
        minWidth: 179
    },

    '& $dropdownMenu::before': {
        content: 'none'
    },

    '& $dropdownItem': {
        fontFamily: 'Roboto-Regular',
      fontSize: 16,
      lineHeight: '21px',
      paddingLeft: 25
    },

    '& .chevron-icon': {
        paddingRight: 0
    }
  },
  activeLink: {
    color: `${theme.palette.primary.main} !important`,

    '& $navLink': {
      color: `${theme.palette.primary.main} !important`
    }
  },
  buttonItem: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap'
  },
  fundsButton: {
    marginLeft: 30
  },
  downloadsButtonWrapper: {},
  tasksCount: {},
  downloadsButton: {},

  divaHeaderItem: {
    paddingLeft: '0!important',
    '& > $navLink': {
      paddingLeft: '0!important',
    },
  },

  divaHeaderLogo: {
    borderRight: 'none!important',
    flex: '0 1 auto',
    padding: '0 2rem 0 1rem',

    [theme.breakpoints.down(1150)]: {
      padding: '0 10px'
    },

    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingRight: 0
    },

    '& img': {
      width: 'auto',
      height: 24,
      verticalAlign: 'middle',
      borderStyle: 'none',
      boxSizing: 'border-box',

      [theme.breakpoints.down('xs')]: {
        height: 16
      },
    }
  },

  customerHeaderFirstItem: {
    borderRight: '1px solid #6f777e!important',
    borderLeft: '1px solid #6f777e!important',
    marginLeft: 24
  },
  customerLogoutHeaderItem: {
    lineHeight: '40px',
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    textTransform: 'uppercase',
    textDecoration: 'none',
    cursor: 'pointer'
  }
}));