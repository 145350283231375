import React, {useState} from 'react';
import clsx from 'clsx';
import useOnClickOutside from "../../../../utils/useOnClickOutside";
import ListItem from "@material-ui/core/ListItem";
import useStyles from "../InvestmentDetailsNavigation/BenchmarkSelector/styles";
import usePortfolioNavigationStyles from "./styles";
import AccordionList from '../../../../components/AccordionList/index';

export default ((props) => {

  const {
    selectedReportType,
    allReportTypes,
    handleSelectedReportTypeChanged,
  } = props;

  const classes = useStyles();
  const overrideClasses = usePortfolioNavigationStyles();

  const [expanded, setExpanded] = useState(false);
  const node = React.useRef();
  useOnClickOutside(node, () => {
    setExpanded(false);
  });

  const renderOptions = () => {
    return Object.keys(allReportTypes).map(key => {
      let option = allReportTypes[key]
      let isSelected = option.value === selectedReportType.value
      return (
        <ListItem
          key={option.value}
          className={clsx(classes.portfolioListItem, isSelected && classes.portfolioListItemSelected)}
          onClick={() => {
            handleSelectedReportTypeChanged(option);
            setExpanded(false);
          }}
        >
          <span>
            {option.description}
          </span>
        </ListItem>
      )
    })
  };

  const renderSelectedItem = () => {
    return (
      selectedReportType && selectedReportType.description
    )
  }

  return (
    <div className={classes.container} ref={node}>
      <div className={overrideClasses.expansionPanelContainer}>
        <span className={overrideClasses.label}>Depotansicht:</span>
          <AccordionList
            renderSelectedItem={renderSelectedItem}
            renderItemsList={renderOptions}
            expanded={expanded}
            setExpanded={setExpanded}
            overrideClasses={overrideClasses}
          />
      </div>
    </div>
  )
})
