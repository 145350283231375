import React from 'react'
import CircleIcon from "@material-ui/icons/Brightness1";
// export const SORTING_TYPES = {
//   TO_HIGHEST: {
//     value: ['-color_category', '-risk_category'],
//     description: 'Sortiere nach'
//   },
//   TO_LOWEST: {
//     value: ['color_category', '-risk_category'],
//     description: 'Sortiere nach'
//   },
// };


const getResponsiveDescription = (firstIconColor, secondIconColor) => {

  const renderCircleIcon = (iconColor) => {
    return <CircleIcon style={{color: iconColor, width: 13, height: 13}}/>
  }

  return (
    <>
      Risiko-Vergleiche <span style={{whiteSpace: 'nowrap'}}>({renderCircleIcon(firstIconColor)} bis {renderCircleIcon(secondIconColor)})</span>
    </>
  )
}

export const SORTING_TYPES = {
  DEPOT_VOLUME_ASC: {
    value: 1,
    id: 1,
    name: 'Kunden AuM (aufsteigend)'
  },
  DEPOT_VOLUME_DESC: {
    value: 2,
    id: 2,
    name: 'Kunden AuM (absteigend)'
  },
  RISK_TYPE_DESC: {
    value: 3,
    id: 3,
    name: getResponsiveDescription('#F44638', '#5EC14E')
  },
  RISK_TYPE_ASC: {
    value: 4,
    id: 4,
    name: getResponsiveDescription('#5EC14E', '#F44638')
  },
  NAME_ASC: {
    value: 5,
    id: 5,
    name: 'Name (A bis Z)'
  },
  NAME_DESC: {
    value: 6,
    id: 6,
    name: 'Name (Z bis A)'
  }
}