import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  loadingContainer: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
    },

    '& > div:last-child': {
      flex: 1,
    }
  },

  loadingLine: {
    width: '100%',
    height: '20px',
    marginBottom: 10,
    backgroundColor: '#f6f7f8',
    backgroundImage: 'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: '$placeholderShimmer',
    animationTimingFunction: 'linear',

    '&:nth-child(2n)': {
      width: '75%'
    }
  },

  loadingAvatar: {
    width: 70,
    height: 70,
    marginRight: 10,
  },

  '@keyframes placeholderShimmer': {
    from: {
      backgroundPosition: '-468px 0',
    },
    to: {
      backgroundPosition: '468px 0',
    }
  }
}));