import React from "react";
import clsx from "clsx";
import LanguageIcon from "@material-ui/icons/Language";

import useStyles from "./styles"


function GlobeMark({ empty, fontSize=28, withBackground=true }) {
  const classes = useStyles({fontSize: fontSize, withBackground: withBackground});
  const wrapperClassName = clsx([classes.iconWrapper, empty && classes.iconWrapperEmpty]);
  const iconClassName = clsx([classes.icon, empty && classes.iconEmpty]);

  return (
    <span className={wrapperClassName}>
      <LanguageIcon classes={{ root: iconClassName }} />
    </span>
  );
}

export default GlobeMark;
