const styles = (theme) => ({
  card: {
    padding: 20,
    position: "relative",
    background: "white",
    boxShadow: "0px 3px 15px #00000033",
    borderRadius: 5,
  },

  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
  },
  textWrapper: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    gap: "30px",
    width: '37%'
  },
  textTitle: {
    marginBottom: 5,
    fontSize: 14,
    fontFamily: "Roboto-Bold",
    textTransform: "uppercase",
  },
  textDescription: {
    color: "#666666",
    fontFamily: "Roboto-regular",
    fontSize: "14px",
  },
  chart: {
    width: "30%",
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  icon2: {
    position: "absolute",
    top: "174px",
    left: "40.8%",
    zIndex: "999999999",
    background:
      "transparent linear-gradient(340deg, #BFE77E 0%, #A5D35B 100%) 0% 0% no-repeat padding-box",
    width: "62px",
    height: "63px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: "#202A38",
    fontSize: 20,
    fontFamily: "Roboto-Regular",
  },
  titleWrapper: {
    display: "flex",
    gap: "8px",
    alignItems: 'baseline'
  },
  tooltip: {
    position: "relative",
    padding: 20,
    backgroundColor: "#EFEFF2",
    color: "#4D4F5C",
    fontFamily: "Roboto-Regular",
    fontSize: 14,
    boxShadow: "0px 2px 15px 0px rgba(0,0,0, 0.2)",
    whiteSpace: "pre-line",
    maxWidth: 500,
  },
  helpIcon: {
    verticalAlign: "middle",
    fontSize: "1.1rem",
    color: "#202A38",
  },
});

export default styles;
