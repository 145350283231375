import { UserUtils } from '../../../../utils/utils';
import _ from "lodash";

export const getBrokerClientTypes = (isChief=false) => {
    if (isChief) {
        return CLIENT_TYPES
    }
    return {
      MY: {...CLIENT_TYPES.MY},
    }
};

export const CLIENT_TYPES = {
    MY: {
        value: 3,
        description: 'Meine Kunden'
    },
    AGENCY: {
        value: 1,
        description: 'Agentur'
    },
    SUB_AGENCY: {
        value: 2,
        description: 'Unteragentur'
    },
};

export const SORTING_TYPES = {
    // AUM_DESC: {
    //     value: 1,
    //     description: 'Kunden Investiertes Vermögen (absteigend)'
    // },
    // AUM_ASC: {
    //     value: 2,
    //     description: 'Kunden Investiertes Vermögen (aufsteigend)'
    // },
    NAME_ASC: {
        value: 3,
        description: 'Name (A bis Z)'
    },
    NAME_DESC: {
        value: 4,
        description: 'Name (Z bis A)'
    }
};

export const CLIENT_FILTERS = {
    SUB_AGENCY: 4,
    SUB_BROKER: 5
}

export const PERMANENT_SUITABILITY_FILTERS= {
    ALL: {
        value: 0,
        description: 'Alle'
    },
    ENABLED: {
        value: 1,
        description: 'Ja'
    },
    DISABLED: {
        value: 2,
        description: 'Nein'
    }
}

export const processBrokerClientTypes = (types, agency) => {
    let processedData = [];
    Object.values(types).map((i, idx) => {
        processedData.push({id: idx, description: i.description, name: i.description, label: i.description, value: i.value, depth: 1});
        if (i.value === CLIENT_TYPES.AGENCY.value) {
            processedData.push(...agency.sub_brokers.map(subBroker => ({
                id: subBroker.broker_id,
                description: subBroker.broker_full_name,
                name: subBroker.broker_full_name,
                label: subBroker.broker_full_name,
                value: subBroker.broker_id,
                type: CLIENT_FILTERS.SUB_BROKER,
                depth: 2
            })));
        }
    });

    return processedData;
};

export const processBrokerSubAgencies = (agencies) => {
    let processedData = [];
    agencies.map(agency => {
        processedData.push({
            id: agency.agency_id,
            description: agency.name,
            label: agency.name,
            value: agency.agency_id,
            type: CLIENT_FILTERS.SUB_AGENCY,
            depth: 2
        });
        if (!_.isEmpty(agency.sub_brokers)) {
            processedData.push(...agency.sub_brokers.map(subBroker => ({
                id: subBroker.broker_id,
                description: subBroker.broker_full_name,
                label: subBroker.broker_full_name,
                value: subBroker.broker_id,
                type: CLIENT_FILTERS.SUB_BROKER,
                depth: 3
            })));
        }
    });

    return processedData;
};

export const getAgencyFilterOptions = (auth) => {
    let types = processBrokerClientTypes(getBrokerClientTypes(UserUtils.isChief(auth)), auth.user.agency);
    let sub_agencies = processBrokerSubAgencies(auth.user.sub_agencies);

    return [...types, ...sub_agencies];
};
