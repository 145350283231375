import {
  closeAssetModal,
  openAssetModal,
  setAssetModalContentTypeForTour,
  setAssetModalMenuTypeForTour
} from "../../../components/AssetModal/actions";

const add_assets_chapter_1 = {
  selector: '#add-other-assets-button-tour-element',
  key: 'other_assets_add_button',
  dispatchFn: [closeAssetModal]
};
const add_assets_chapter_2 = {
  selector: '#asset-menu-modal-tour-element',
  key: 'other_assets_menu_modal',
  dispatchFn: [openAssetModal, setAssetModalMenuTypeForTour]
};
const add_assets_chapter_3 = {
  selector: '#asset-form-modal-tour-element',
  key: 'other_assets_form_modal',
  dispatchFn: [openAssetModal, setAssetModalContentTypeForTour]
};

export const getAddAssetsChapterSteps = () => {
  return [
    add_assets_chapter_1,
    add_assets_chapter_2,
    add_assets_chapter_3
  ];
};
