import React from 'react'

export default function Card(props) {

  const {
    cardHeader,
    cardBody,
    cardFooter
  } = props

  return (
    <div class="card">
      <div class="card-header">
        { cardHeader }
      </div>
      <div class="card-body">
        { cardBody }
      </div>
      <div class="card-footer">
        { cardFooter }
      </div>
    </div>
  )
}

