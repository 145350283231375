import React from 'react';
import useStyles from './styles';
import Dialog from "@material-ui/core/Dialog/Dialog";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";

const InfoDialog = (props) => {
  const classes = useStyles();

  const {
    open,
    title,
    message,
    onClose,
    disableEscapeKeyDown
  } = props;

  return (
    <Dialog
      open={open}
      disableBackdropClick={false}
      disableEscapeKeyDown={disableEscapeKeyDown || false}
      onClose={onClose}
      classes={{
        paper: classes.dialogPaper
      }}
    >
      {title && <DialogTitle className={classes.title}>{title}</DialogTitle>}
      <div className={classes.dialogContent}>
        <div className={classes.message}>
          {message}
        </div>
      </div>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Dialog>
  )
};

export default InfoDialog
