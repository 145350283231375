import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: 'space-between'
  },
  sectionHeader: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#4D4F5C',

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  sectionConclusion: {
    margin: '10px 0',
    padding: '11px 0',
    borderTop: '1px solid #E1E3E9',
    // textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
  },
  totalWeight: {
    width: 70,
    marginLeft: 15,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: 'rgb(94, 193, 78)',

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  warnTotal: {
    color: 'rgb(255, 0, 0)'
  },
  benchmarksContainer: {
    '& > div': {
      marginTop: 10
    }
  },
  section: {
    '&:nth-child(n+2)': {
    }
  },
  sectionDisabled: {
    filter: 'grayscale(1)',
    pointerEvents: 'none',
    cursor: 'initial'
  },
  modalPaper: {
    overflow: 'visible'
  },
  acceptButton: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    borderRadius: 8,
    boxShadow: '3px 6px 10px #00000033',
    textTransform: 'none',
    width: 110,
    height: 50,

    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      height: 'auto'
    }
  },
  closeButton: {
    position: 'absolute',
    top: 1,
    right: 1
  },
  dialogTitleRoot: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px'
    },
    '& > h2': {
      fontFamily: 'Roboto-Bold',
      [theme.breakpoints.only('sm')]: {
        fontSize: 18
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: 16
      },
    },
  },
  dialogContentRoot: {
    overflow: 'visible',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px'
    }
  },
  dialogActionsRoot: {
    padding: '24px'
  },
  benchmarkSelector: {
    display: 'flex',
    alignItems: 'center'
  },
  deleteBenchmark: {
    marginLeft: 8,
    marginRight: 14,
    color: theme.palette.primary.main,
    width: 22,
    height: 22,
    cursor: 'pointer'
  }
}));