import React from "react";
import ModelPortfolioSelection
  from "../../../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/components/ModelPortfolioSelection";

function ModelPortfolioList(props){
  const {
    instrumentsHandler,
    selectedAssets,
    onInstrumentSelect,
    onSelectAll,
    activeSourceItem,
    handleDetailsClick,
    action,
    dataService
  } = props;

  return <ModelPortfolioSelection
    title={"Bitte wählen Sie ein Musterdepot für das Rebalancing aus"}
    onDetailsClick={handleDetailsClick}
    dataService={dataService}
    action={action}
    depot={{}}
    isModelPortfolio={false}
    instrumentsHandler={instrumentsHandler}
    onInstrumentSelect={onInstrumentSelect}
    onSelectAll={onSelectAll}
    selectedItem={activeSourceItem}
    selectedInstruments={selectedAssets}
  />
}

export default ModelPortfolioList;