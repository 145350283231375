import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import tableStructure from "./table-data";
import DashboardTable from "../../DashboardTable/DashboardTable";

const UnrealizedProfitAndLossTable = (props) => {
  const {
    classes,
    data,
    expanded
  } = props;

  const totalRows = Math.max(data.inflows.length, data.outflows.length);
  const dataSource = [...Array(totalRows).keys()].map((index) => {
    return {
      inflow: data.inflows[index],
      outflow: data.outflows[index],
    }
  })

  return (
    <DashboardTable
      structure={tableStructure}
      dataSource={dataSource}
      expanded={expanded}
      tableClasses={classes}
      options={{
        inflows_total: data.inflows_total,
        outflows_total: data.outflows_total
      }}
    />
  );
};
export default withStyles(styles)(UnrealizedProfitAndLossTable);