import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'

import {
  Container,
  Grid,
  withWidth,
  CircularProgress
} from '@material-ui/core';

import withCustomersData from '../../../components/CustomersDataProvider';
import {CustomerReportSettingResource, GroupResource} from '../../../utils/api';
import { ROUTES } from '../../../routes';
import SuccessDialog from '../../../components/SuccessDialog';
import { GuideTour } from '../../../components/GuideTour';


import {GroupCustomers, GroupDetails, GroupTypeSelector} from '../components';
import FiltersPanel from '../components/FiltersPanel';
import useStyles from './styles';


import { newDesignUsed } from '../../../utils/utils';
import { executeIfPathExist, getInvestmentDynamicPath } from '../../../containers/InvestmentPlatform/utils'

import { MuiThemeProvider } from '@material-ui/core/styles';
import {createDefaultTheme} from '../../../themes/themeConfigurator';
import {DASHBOARD_GROUP_TYPE_MENU_ITEM_MAPPING, DASHBOARD_SETTINGS_TYPE} from "../../DashboardSettings/constants";
import withNotification from "../../../components/NotificationProvider";
import {addFiltersToDynamicGroupBody} from "../utils";
import {getReportSettingsGuideSteps} from "../../DashboardSettings/guide";

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS()
});

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1180,
    lg: 1492,
    xl: 1920
  }
}
const theme = createDefaultTheme({'breakpoints': breakpoints})

const GroupEditPage = (props) => {

  const classes = useStyles();

  //#region Component State

  const [selectedCustomers, setSelectedCustomers] = React.useState([]);
  const [preselectedCustomers, setPreselectedCustomers] = React.useState([]);
  const [groupSavingStatus, setGroupSavingStatus] = React.useState({
    loading: false,
    errors: undefined
  });
  const [groupData, setGroupData] = React.useState({
    data: undefined,
    loading: true,
    errors: undefined
  });

  const [successModalProperties, setSuccessModalProperties] = React.useState({
    open: false,
    message: ''
  });

  const [groupSettingsRoute, setGroupSettingsRoute] = React.useState(ROUTES.BROKER.REPORT_SETTINGS.path)
  const [investmentDynamicPath, setInvestmentDynamicPath] = React.useState(getInvestmentDynamicPath())
  //#endregion

  //#region Component Effects

  React.useEffect(() => {
    fetchGroupData();
  }, []);

  React.useEffect(() => {
    if (newDesignUsed()) {
      let menu_item = !_.isNil(props.dashboardGroupType)
        ? DASHBOARD_GROUP_TYPE_MENU_ITEM_MAPPING[_.findKey(DASHBOARD_SETTINGS_TYPE, (value) => value===props.dashboardGroupType)]
        : 'SERIES_REPORTING'
      executeIfPathExist(props.investmentPlatform.routes, menu_item, (dynamicPath) => {
        let path = `/${investmentDynamicPath}` + dynamicPath + '/?is_from_group=true'
        setGroupSettingsRoute(path)
      })
    }
  }, [props.investmentPlatform, props.dashboardGroupType])

  //#endregion

  /**
   * Update selected customers with new one.
   *
   * @param {Object[]} customers
   */
  const handleCustomersSelected = (customers) => {
    let selectedCustomersUpdated = [...selectedCustomers, ...customers];
    setSelectedCustomers(selectedCustomersUpdated);
  };

  /**
   * Remove provided customer from selected customers.
   *
   * @param {Object} customerToRemove
   */
  const handleCustomerDeselected = (customerToRemove) => {
    if (!props.globalCustomers.loading) {
      let selectedCustomersUpdated = _.filter(selectedCustomers, customer => customer.id != customerToRemove.id);
      setSelectedCustomers(selectedCustomersUpdated);

      if (props.onCustomerUpdate) {
        props.onCustomerUpdate(customerToRemove);
      }
    }
  }

  /**
   * Retrieve group data.
   */
  const fetchGroupData = async () => {
    const { group_id } = props.computedMatch.params;

    if (!_.isNil(group_id)) {
      try {
        let response = !_.isNil(props.dashboardGroupType)
          ? await  CustomerReportSettingResource.at(`broker/${group_id}/get-broker-dashboard-group-settings/`).get()
          : await GroupResource.at(`${group_id}/`).get();
        setGroupData({
          data: response,
          loading: false,
          errors: undefined
        });

        setSelectedCustomers(response.customers);
        setPreselectedCustomers(response.customers)

      } catch (errors) {
        setGroupData({
          data: undefined,
          loading: false,
          errors
        });
      }
    }
  }

  const handleSaveGroupClick = async (groupName) => {

    const { group_id } = props.computedMatch.params;

    if (!_.isNil(group_id)) {

      setGroupSavingStatus({
        loading: true,
        errors: undefined
      });

      let body = {
        name: groupName,
        customers: selectedCustomers.map(customer => customer.id)
      }

      // if dynamic group is saved - pass filters to apply
      addFiltersToDynamicGroupBody(body, isDynamicGroupProcess, props.currentFilters, props.filtersOptions)

      try {
        !_.isNil(props.dashboardGroupType)
          ? await CustomerReportSettingResource.at('broker/save-dashboard-settings/').post(
            {settings_type: DASHBOARD_SETTINGS_TYPE.BROKER_GROUP, data: [{...body, id: group_id, broker_id: props.auth.user.broker_id}]})
          : await GroupResource.at(`${group_id}/customers/`).patch(body);

        props.setGroupToCustomers(selectedCustomers, preselectedCustomers);

        setSuccessModalProperties({
          open: true,
          message: <p>Ihre neue Gruppe <b>{groupName}</b> wurde gespeichert.</p>
        })

      } catch (error) {
        let errorMsg = undefined
        if (_.isString(error)){
          props.displayNotification('error', error);
        }else{
          errorMsg = error
        }
        setGroupSavingStatus({
          loading: false,
          errors: errorMsg
        });
      }
    }
  }

  const handleSuccessModalClose = () => {
    setSuccessModalProperties({
      open: false,
      message: ''
    });
    props.history.push(groupSettingsRoute);
  }


  const [isDynamicGroupProcess, setIsDynamicGroupProcess] = React.useState(false)
  const onGroupProcessChange = (isNewTypeDynamic) => {
    // dynamic groups filter customers at runtime -> do not save selected customers, as each runtime they may differ
    if(isNewTypeDynamic){ setSelectedCustomers([]) }
    setIsDynamicGroupProcess(isNewTypeDynamic)
  }

  return (
    <MuiThemeProvider theme={theme}>
      <GuideTour
        steps={getReportSettingsGuideSteps(props.history)}
        title="Einstellungen Info-Tour"
      />
      <Container
        className="app-page-container"
        style={{flexWrap: 'nowrap', height: '100%'}}
      >
        {groupData && !groupData.loading && groupData.data && (
          <>
            <SuccessDialog
              open={successModalProperties.open}
              message={successModalProperties.message}
              onClose={handleSuccessModalClose}
            />
            <Grid container style={{height: '100%'}}>
              <Grid item lg={3} xs={12}>
                <FiltersPanel
                  groupData={groupData}
                  loading={props.globalCustomers.loading}
                  disabled={!_.isNil(props.globalCustomers.errors) || groupSavingStatus.loading}
                  onFiltersUpdated={props.onFiltersUpdated}
                  filtersOptions={props.filtersOptions}
                  hasCustomers={!_.isEmpty(props.customers)}
                  // if not dashboard group process -> render group type selector in filters
                  withGroupTypeRadio={_.isNil(props.dashboardGroupType)}
                  setIsDynamicGroupProcess={onGroupProcessChange}
                />
              </Grid>
              <Grid
                item
                lg={9} xs={12}
                className={classes.customerListSection}
                id="report-settings-create-group-client-list-tour-element"
              >
                <Link
                  to={groupSettingsRoute}
                  className={classes.backLink}
                >
                  <i className="chevron-icon fa fa-chevron-left" />
                  Zurück zur Gruppenliste
                </Link>
                <Grid container style={{height: '100%', paddingTop: 20}}>
                  <Grid item lg={8} md={8} xs={12}>
                    <GroupCustomers
                      customersData={props.filteredCustomersData}
                      unavailableCustomers={selectedCustomers}
                      actionType={1}
                      onSelectCustomers={handleCustomersSelected}
                      dataLoadingFailed={props.globalCustomers.errors}
                      // dynamic groups filter customers at runtime -> manual selection is disabled
                      disabled={isDynamicGroupProcess}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <GroupDetails
                      groupName={groupData.data && groupData.data.name}
                      selectedCustomers={selectedCustomers}
                      onDeselectCustomer={handleCustomerDeselected}
                      onSaveGroupClick={handleSaveGroupClick}
                      // as dynamic groups do not store customers - save is disable only if no selectedCustomers in static groups
                      saveDisabled={!isDynamicGroupProcess && (selectedCustomers.length == 0 || props.globalCustomers.errors)}
                      isSaving={groupSavingStatus.loading}
                      errors={groupSavingStatus.errors}
                      editMode
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {groupData && groupData.loading && (
          <div className={classes.loadingContainer}>
            <CircularProgress color="primary"/>
          </div>
        )}
        {groupData && groupData.errors && (
          <div className={classes.notFoundContainer}>
            <h1 className={classes.notFoundStatusCode}>404</h1>
            <p className={classes.notFoundHelperText}>Seite nicht gefunden</p>
          </div>
        )}
      </Container>
    </MuiThemeProvider>
  );
};

export default connect(mapStateToProps)(withWidth()(withNotification(withCustomersData(GroupEditPage))));