const styles = theme => ({
  header: {
    height: 16,
  },
  closeButton: {
    position: 'absolute',
    top: 3,
    right: 10
  },
  dialogActions: {
    padding: 24
  },
  deleteBtn: {
    marginRight: 'auto'
  }
});

export default styles;