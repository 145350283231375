import React from 'react';

/* Material-UI Components */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

/* BCA modules*/
import useStyles from './styles';
import images from '../../../../images';


export default ({title='Risiko- / Nachhaltigkeitsanalyse', leftNavigationContent, onDownloadPdfClick, downloadButtonDisabled, onInformClientsButtonClick, informClientsButtonDisabled}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container className={classes.topContainer} alignItems={'center'}>
        <Grid item xs={12} sm={7}>
          <Typography id="risk-dashboard-header-tour-element" variant={"h1"} className={classes.header}>{title}</Typography>
          {leftNavigationContent || null}
        </Grid>
        <Grid item container xs={12} sm={5} className={classes.buttonContainer}>
          {onInformClientsButtonClick && (
            <Button color={"primary"} variant={"contained"} disabled={informClientsButtonDisabled} className={classes.button} onClick={onInformClientsButtonClick}>
              Kunden informieren
            </Button>
          )}
          {onDownloadPdfClick && (
            <Button disabled={downloadButtonDisabled} onClick={onDownloadPdfClick} color={"primary"} variant={"contained"} className={classes.button} id="risk-dashboard-download-pdf-tour-element">
              <img src={images.down_arrow} alt="Download"/>
              Als PDF exportieren
            </Button>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}