import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  label: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#4D505B',
    display: 'inline-block',
    width: '100%',
  },
  alignRight: {
    textAlign: 'right'
  },
  textBold: {
    fontFamily: 'Roboto-Bold'
  },
  subTitle: {
    marginLeft: 20,
  },
  tooltip: {
    fontSize: 12,
  }
})

export default makeStyles(styles)