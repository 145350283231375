import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash';
import clsx from "clsx";

import {Grid} from "@material-ui/core";

/* BCA Components*/
import LoadingContainer from '../LoadingContainer';

/* BCA modules */
import {withEuroOrDash, withPercentOrDash} from "../../../../utils/utils";

import WarningTooltip from "../../../WarningTooltip"
import useStyles from '../styles';
import {getSharedSettingsWarning} from "../../../../containers/CustomerDashboardV2/utils";


const mapStateToProps = (state) => ({
  sharedSettings: state.get('sharedSettings').toJS()
});

export const LongTermReturn = (props) => {
  const classes = useStyles();

  const {
    data,
    loading,
    isVirtual,
    sharedSettings
  } = props;

  const getColor = (value) => value < 0 ? '#f00' : '#5EC14E';

  const customClasses = props.customClasses || {};
  const renderGeneralReturn = () => {
    return (
      <div className={classes.subContent}>
        <p className={classes.subHeader}>
          Gesamtrendite seit {moment(data.start_date).format('DD.MM.YYYY')}
        </p>

        <div className={classes.dataWrap}>
          <p
            className={clsx(classes.value, customClasses.value)}
            style={{
              color: getColor(data.ysb.percentage),
              marginRight: 15
            }}
          >
            {withPercentOrDash(data.ysb.percentage || 0, true)}
          </p>
          <p
            className={clsx(classes.value, customClasses.value)}
            style={{
              color: getColor(data.ysb.value)
            }}
          >
            {withEuroOrDash(data.ysb.value || 0)}
          </p>
        </div>
      </div>
    )
  };

  const renderPAReturn = () => {
    const percentage_pa = _.get(data, 'ysb.percentage_pa', 0);
    const warning = sharedSettings && getSharedSettingsWarning(_.get(data, 'ysb.percentage_pa_warning', sharedSettings));
    return (
      <div className={classes.subContent}>
        <p className={classes.subHeader}>
          Rendite p.a.
        </p>

        <div className={classes.dataWrap} style={{height: '28px'}}>
          <p
            className={clsx(classes.value, customClasses.value)}
            style={{
              color: getColor(percentage_pa),
            }}
          >
            {!!warning
              ? <WarningTooltip title={warning} size={20} />
              : withPercentOrDash(percentage_pa, true)
            }
          </p>
        </div>
      </div>
    )
  };

  return (
    <div className={classes.container}>
      {loading ? (
        <LoadingContainer/>
      ) : (
        <React.Fragment>
          {data && (
            <React.Fragment>
              <Grid container className={customClasses.headerWrapper}>
                <Grid item xs={12} className={clsx(classes.header, customClasses.header)}>
                  {isVirtual ? 'Rendite seit Anlage' : 'Rendite seit Investmentbeginn'}
                  <WarningTooltip
                    className={classes.benchmarkIcon}
                    placement={'left'}
                    title={"Berechnet nach geldgewichteter Methode bzw. nach Modified Dietz."}
                    iconClassName={classes.helpIcon}
                    size={16}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} className={clsx(classes.subContainer, customClasses.subContainer)}>
                    <Grid item xs={8} sm={7} md={"auto"}><Grid container><Grid item xs={'auto'}>{renderGeneralReturn()}</Grid></Grid></Grid>
                    <Grid item xs={4} sm={5} md={"auto"}>{renderPAReturn()}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  )
};

export default connect(mapStateToProps)(LongTermReturn)