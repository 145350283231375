import React from 'react';

export const TRADING_ACTION_BUY = 1;
export const TRADING_ACTION_SELL = 2;
export const TRADING_ACTION_SWITCH = 3;
export const TRADING_ACTION_SAVINGS_PLAN = 4;
export const TRADING_ACTION_PAYOUT_PLAN = 5;
export const TRADING_ACTION_SWITCH_PLAN = 6;

export const TRADING_ACTION_DEFAULT = {
  value: 'default'
};

export const TRADING_ACTIONS = [
  {
    value: TRADING_ACTION_BUY,
    code: 'buy',
    text: 'Kaufen',
    isTrading: true
  }, {
    value: TRADING_ACTION_SELL,
    code: 'sell',
    text: 'Verkaufen',
    isTrading: true,
    isWholeDepotOrder: true
  }, {
    value: TRADING_ACTION_SWITCH,
    code: 'switch',
    text: 'Tauschen',
    isTrading: true
  }, {
    value: TRADING_ACTION_SAVINGS_PLAN,
    code: 'savings_plan',
    text: 'Sparplan'
  }, {
    value: TRADING_ACTION_PAYOUT_PLAN,
    code: 'payout_plan',
    text: 'Entnahmeplan'
  }, {
    value: TRADING_ACTION_SWITCH_PLAN,
    code: 'switch_plan',
    text: 'Tauschplan'
  }
];

export const SAVING_PLANS_ACTIONS = [
  {
    value: 'edit',
    code: 'edit',
    text: 'Ändern',
    actionButtonText: <i className="fa fa-pencil"></i>,
    actionButtonTooltip: 'Plan ändern',
  }, {
    value: 'delete',
    code: 'delete',
    text: 'Löschen',
    actionButtonText: <i className="fa fa-minus"></i>,
    actionButtonTooltip: 'Plan löschen',
  }
];

export const GROUP_ACTION_DEPOT = 'instruments';
export const GROUP_ACTION_PRODUCT = 'by_products';
export const GROUP_ACTION_RISK_CLASS = 'by_risk_indicators';
export const GROUP_ACTION_MS_CATEGORY = 'by_ms_category';

export const GROUP_ACTIONS = [
  {
    value: GROUP_ACTION_DEPOT,
    code: GROUP_ACTION_DEPOT,
    text: 'Depot'
  }, {
    value: GROUP_ACTION_PRODUCT,
    code: GROUP_ACTION_PRODUCT,
    text: 'Produkt'
  }, {
    value: GROUP_ACTION_RISK_CLASS,
    code: GROUP_ACTION_RISK_CLASS,
    text: 'Risikoklasse'
  }, {
    value: GROUP_ACTION_MS_CATEGORY,
    code: GROUP_ACTION_MS_CATEGORY,
    text: 'Morningstar-Kategorie'
  }
];