/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOAD_REPOS = 'boilerplate/App/LOAD_REPOS';
export const LOAD_REPOS_SUCCESS = 'boilerplate/App/LOAD_REPOS_SUCCESS';
export const LOAD_REPOS_ERROR = 'boilerplate/App/LOAD_REPOS_ERROR';

export const TAB_TITLES = {
  Default: 'DIVA - Die Serviceplattform',
  DefaultBroker: 'DIVA - Die Serviceplattform für Vermittler',
  DefaultCustomer: 'DIVA - Die Serviceplattform für unsere Kunden',
  CustomerDashboard: 'Vermögensübersicht | DIVA - Die Serviceplattform für Vermittler',
  Reporting: 'Serienreporting | DIVA - Die Serviceplattform für Vermittler',
  ReportSettings: 'Einstellungen | DIVA - Die Serviceplattform für Vermittler',
  RiskDashboard: 'Risikoanalyse | DIVA - Die Serviceplattform für Vermittler',
};

export const CUSTOMER_TAB_TITLES = {
  Dashboard: 'Vermögensübersicht',
  VirtualDepots: 'Virtuelle Depots',
  OtherAssets: 'Sonstige Vermögenswerte',
  Postbox: 'Postfach',
  ProView: 'Professionelle Depotansicht'
};

export const mdScreenStepsSizes = ['xs', 'sm',];
