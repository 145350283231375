import React from "react";
import { connect } from 'react-redux'

import {ModelportfolioList, ModelportfolioOverview} from "../../../../../../../../../../Modelportfolios";
import {
  displayErrorSnackBar,
  displayWarningSnackBar
} from "../../../../../../../../../../../components/SnackbarProvider/actions";
import {ModelPortfolioResource} from "../../../../../../../../../../../utils/api";
import {CircularProgress} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../styles";
import {getErrorMessage} from "../../../../../../../../../../../utils/utils";
import {DEFAULT_SELECT_PRODUCT_TABLE_ACTIONS_NAMES} from "../../../../../../../../../../../utils/constants";


const MusterDepot = props => {
  const {
    classes,
    selectedItem,
    onDetailsClick,
    onInstrumentSelect,
    selectedInstruments,
    instrumentsHandler,
    onSelectAll,
    depot,
    directAddingPossible,
    onSourceSelect,
    sourceSelected
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [skipDetails, setSkipDetails] = React.useState(false); // state to force ModelportfolioList render when Hinzufügen was clicked


  const handleDetailsClick = async (item, skipDetails) => {
    setLoading(true);
    setSkipDetails(skipDetails);
    try {
      let portfolio = await ModelPortfolioResource.getModelPortfolioDetails(item.id);
      let items = portfolio.assets;

      if (instrumentsHandler) {
        [items,] = await instrumentsHandler.getCustodianAndFilterInstruments(items, true);
      }

      if (items.length) {
        // skipDetails indicates that assets are add from ModelportfolioList directly to list of selected instruments -> only filtered assets should be passed
        const source = skipDetails ? {...portfolio, assets: items} : portfolio;
        // if some assets were filtered out - set flag to display warning
        let hasWarning = skipDetails && items.length !== portfolio.assets.length;

        onDetailsClick(source, skipDetails, hasWarning);
      } else {
        props.dispatch(displayWarningSnackBar('Das ausgewählte Musterdepot enthält keine Instrumente, die dem Risikoprofil des Kunden entsprechen, oder ist verfügbar bei der gewählten Depotbank.'))
      }
    } catch (error) {
      props.dispatch(displayErrorSnackBar(getErrorMessage(error)))
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loadingIndicator}/>
        </div>
      )}
      {!loading && (
        <>
          {selectedItem && !skipDetails && (
            <ModelportfolioOverview
              viewOnly
              selectedInstruments={selectedInstruments}
              portfolio={selectedItem}
              onSelectAll={onSelectAll}
              onInstrumentSelect={onInstrumentSelect}
              onSourceSelect={onSourceSelect}
              sourceSelected={sourceSelected}
              isInstrumentsSelection
            />
          )}
          {(!selectedItem || skipDetails) && <ModelportfolioList
            viewOnly
            handleEditClick={handleDetailsClick}
            directAddingPossible={directAddingPossible}
            actionsNames={DEFAULT_SELECT_PRODUCT_TABLE_ACTIONS_NAMES}
          />}
        </>
      )}

    </>
  )
};

MusterDepot.defaultProps = {
  directAddingPossible: true
}

export default connect() (withStyles(styles)(MusterDepot));