import React, {useContext, useRef, useState} from "react";
import ConfirmationDialog from "./ConfirmationDialog";

const EinzeltitelProcessConfirmationModalContext = React.createContext({});

const EinzeltitelProcessConfirmationModalContextProvider = (props) => {
  const [message, setMessage] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const resolver = useRef();

  const handleShow = (message) => {
    setMessage(message);
    setShowConfirmationModal(true);

    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const onClose = () => {
    setMessage("");
    setShowConfirmationModal(false);
  };

  const handleOk = (skipNextTimeConfirmation) => {
    resolver.current && resolver.current(skipNextTimeConfirmation);
    onClose();
  };

  return (
    <EinzeltitelProcessConfirmationModalContext.Provider value={{confirm: handleShow}}>
      {props.children}

      <ConfirmationDialog
        message={message || "Sind Sie sicher?"}
        open={showConfirmationModal}
        confirm={handleOk}
      />

    </EinzeltitelProcessConfirmationModalContext.Provider>
  )
};

export const useEinzeltitelProcessConfirmationModalContext = () => useContext(EinzeltitelProcessConfirmationModalContext);
export default EinzeltitelProcessConfirmationModalContextProvider;