import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {createSelector} from "reselect";
import { Grid } from '@material-ui/core';
import CustomerThemeProvider from '../../../../../../components/CustomerThemeProvider/CustomerThemeProvider';
import {
  InstrumentList,
} from '../../../../../CustomerDashboardV2/components/Widgets/components';
import { isInstrumentsDataExist, getErrorMessage } from '../../../../../../utils/utils';
import { isTradingEnabled } from '../../../../../../components/TradingStore/utils';
import VirtualOrderModal from '../../../../../VirtualPortfolioManager/modals/VirtualOrderModal';
import { ModelPortfolioResource, parseResponse } from '../../../../../../utils/api';
import { SHARED_SETTINGS_KEYS } from '../../../../../../components/SharedSettingsProvider/constants';
import { displayErrorSnackBar } from '../../../../../../components/SnackbarProvider/actions';
import { setEarliestPriceDates } from '../../../../../Trades/utils';
import { ChartSectionWrapper } from '../../../../../CustomerDashboardV2/components_v2/ChartSectionBordered/ChartSectionBordered';
import { GROUP_ACTION_DEPOT } from '../../../../../../components/ChartsV2/InstrumentsAllocationTable/constants';


const getSharedSettings = (state) => state.get('sharedSettings');

const getSharedSettingsSelector = createSelector(
  [getSharedSettings],
  (sharedSettings) => sharedSettings.toJS()
);

const mapStateToProps = (state) => ({
  sharedSettings: getSharedSettingsSelector(state)
});

class InstrumentsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderModalOpened: false,
      assetLoaded: false,
      outsideTrigger: !!this.getSearchIsin(),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.portfoliosTransactions != this.props.portfoliosTransactions && !_.isEmpty(this.props.portfoliosTransactions) && !this.state.assetLoaded) {
      this.fillTransactionWithAssetData();
    }
  }

  getSearchIsin = () => {
    const searchParams = _.get(this.props, 'location.search');
    const queryParams = new URLSearchParams(searchParams);
    return queryParams.get('isin') || undefined;
  }

  fillTransactionWithAssetData = () => {
    const isin = this.getSearchIsin();

    if (this.getTradingEnabled() && isin) {
      this.getAssetData(isin);
      this.setState({ orderModalOpened: true });
      this.props.history && this.props.history.replace({
        search: '',
      });
    }
  }

  searchAssets = async (filters, resolve, errors) => {
    let response = {};

    try {
      response = await ModelPortfolioResource.searchAssets(filters);
    } catch (errors) {
      this.props.dispatch(displayErrorSnackBar(getErrorMessage(errors)));
    }
    parseResponse(response, 'assets', resolve, errors);
  }

  getAssetData = async (isin) => {
    await this.searchAssets({ name: isin }, async (data) => {
      await this.updateTransactionsWithSelectedAssets(data);
      this.setState({ assetLoaded: true });
    }, (e) => {
      this.setState({ assetLoaded: true });
      this.props.dispatch(displayErrorSnackBar('Fehler beim Hinzufügen des Vermögenswert.'));
    });
  }

  updateTransactionsWithSelectedAssets = async (selectedAssets) => {
    const updatedTransactions = _.cloneDeep(this.props.portfoliosTransactions);
    await setEarliestPriceDates(selectedAssets, this.props.dispatch, 'isin');

    updatedTransactions[0].transactions.buy = selectedAssets.map((a) => {
      return {
        data: {
          ...a,
          price_eur: a.calculated.last_price_value,
        },
        earliestPrice: a.earliestPrice,
      };
    });

    this.props.setPortfoliosTransactions(updatedTransactions);
  }

  initPortfoliosTransactions = () => {
    this.props.initPortfoliosTransactions && this.props.initPortfoliosTransactions();
  };

  getTradingEnabled = () => {
    const isNotPI = !this.props.investmentStrategyId;
    // disable trading for PI/ViewOnly
    return !this.props.viewOnly && isNotPI && isTradingEnabled();
  }

  isDataExists = () => {
    return this.state.outsideTrigger ? this.state.assetLoaded : !_.isEmpty(this.props.portfoliosTransactions);
  }

  getInstrumentsFooterText = () => {
    const message = _.get(this.props.sharedSettings, `data.${SHARED_SETTINGS_KEYS.PI_INSTRUMENTS_LIST_FOOTER_TEXT}`);
    return <div dangerouslySetInnerHTML={{ __html: message }} />
  }

  render() {
    const {
      modelPortfolio,
      instrumentList,
      unrealizedProfitAndLossData,
      profitAndLoss,
      portfoliosTransactions,
      setPortfoliosTransactions,
    } = this.props;

    const isNotPI = !this.props.investmentStrategyId;
    const tradingEnabled = this.getTradingEnabled();

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomerThemeProvider>
            <ChartSectionWrapper
              dataId={"instrument-table"}
              title={null}
              loading={instrumentList.loading}
              error={instrumentList.errors}
              displayError
              expanded
              empty={!isInstrumentsDataExist(instrumentList)}
              content={
                <InstrumentList
                  data={instrumentList.data}
                  expandedItems={this.props.expandedItems.instrumentsTab}
                  onExpandedItemsChange={this.props.onExpandedItemsChange}
                  showCell={() => true}
                  unrealizedProfitAndLossData={unrealizedProfitAndLossData}
                  withTransactionSaldo={true}
                  portfolioNameRender={(portfolio) => `${portfolio.name}`}
                  showSubSectionTitle={false}
                  footerText={!isNotPI && this.getInstrumentsFooterText()}
                  instrumentsGroupBy={GROUP_ACTION_DEPOT}
                  // trading props
                  isVirtualOrderEnabled={tradingEnabled}
                  isVirtual={tradingEnabled}
                  resource={ModelPortfolioResource}
                  tradings={this.props.combinedTradings}
                  onAddTradingOption={this.props.handleAddTradingOption}
                  refresh={this.props.postVirtualOrdersCreated}
                  withCompletelySoldAssets={isNotPI}
                  completelySoldAssetsData={profitAndLoss.data}
                />
              }
            />
          </CustomerThemeProvider>
        </Grid>

        {this.isDataExists() && (
          <VirtualOrderModal
            open={this.state.orderModalOpened}
            onClose={() => {this.setState({orderModalOpened: false})}}

            portfoliosTransactions={portfoliosTransactions}
            setPortfoliosTransactions={setPortfoliosTransactions}
            initPortfoliosTransactions={this.initPortfoliosTransactions}

            portfolioOwnerId={_.get(modelPortfolio, 'data.id')}
            refresh={this.props.postVirtualOrdersCreated}
            dispatch={this.props.dispatch}
            resource={ModelPortfolioResource}

            useUpdate={this.props.useUpdate}
            handleEmptyMpUpdated={this.props.handleEmptyMpUpdated}
          />
        )}
      </Grid>
    )
  }
}

export default connect(mapStateToProps)(InstrumentsTab);