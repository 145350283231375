const styles = () => ({
  stepName: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    lineHeight: '32px',
    textAlign: 'left',
    marginBottom: 0,
  },
  stepHelpText: {
    fontSize: 16,
    marginBottom: 16,
    // textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  questionsContent: {
    paddingTop: 24,
  },
  total: {
    fontSize: 34
  },
  stepRequired: {
    marginBottom: 16,
    fontSize: 16,
  },
  checkIcon: {
    marginLeft: -26,
    marginRight: 5,
    fontSize: 21,
    verticalAlign: 'middle',
  },
  checkIconContainer: {
    marginLeft: 25,
    marginBottom: 2
  }
});

export default styles;