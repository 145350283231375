import {fromJS} from "immutable";
import {
  groupForGuide,
  ADD_GROUP_FOR_GUIDE_KEY,
  REMOVE_GROUP_FOR_GUIDE_KEY,
  SET_GROUPS_KEY,
  SET_LOADING_KEY,
  SET_ERRORS_KEY
} from "./constants";

const reportSettingsInitialState = fromJS({
  groups: [],
  loading: false,
  errors: undefined
});

export function reportSettingsReducer(state = reportSettingsInitialState, action) {
  switch(action.type) {
    case ADD_GROUP_FOR_GUIDE_KEY:
      return addGroupForGuide(state);
    case REMOVE_GROUP_FOR_GUIDE_KEY:
      return removeGroupForGuide(state);
    case SET_GROUPS_KEY:
      return setGroups(state, action.groups);
    case SET_LOADING_KEY:
      return state.set('loading', action.loading);
    case SET_ERRORS_KEY:
      return state.set('errors', fromJS(action.errors));
    default:
      return state;
  }
}

function setGroups(state, groups=[]) {
  // this condition added to fix issue with guide tour
  // groupForGuide will be added to list of groups when guide will be opened
  // but groups data will be fetched and list of groups will be updated when open report settings page
  // groupForGuide should be in list
  const currentGroups = state.get('groups').toJS();

  if (currentGroups[0] && currentGroups[0].id === groupForGuide.id) {
    groups.unshift(groupForGuide);
  }

  return state.set('groups', fromJS(groups));
}

function addGroupForGuide(state) {
  let currentGroups = state.get('groups').toJS();

  if (currentGroups[0] && currentGroups[0].id !== groupForGuide.id) {
    currentGroups.unshift(groupForGuide);
    return state.set('groups', fromJS(currentGroups));
  }

  return state;
}

function removeGroupForGuide(state) {
  let groups = state.get('groups').toJS();

  if (groups[0] && groups[0].id === groupForGuide.id) {
    groups.shift();
    return state.set('groups', fromJS(groups));
  }

  return state;




}
