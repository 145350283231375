import {TRANSACTION_TYPE_VALUES} from "../Trades/constants";
import moment from "moment";
import momentBusinessDays from "moment-business-days";

export const MIN_DATE_ERROR_MSG = "Gewähltes Produkt zu diesem Datum nicht verfügbar";
export const MIN_SAVING_PLAN_DATE_ERROR_MSG = "Gewähltes Produkt zu diesem Datum nicht verfügbar";

export const getInstrumentMinDate = (item, transactionType) => {
  return [TRANSACTION_TYPE_VALUES.SELL, TRANSACTION_TYPE_VALUES.SWITCH].includes(transactionType) ?
      moment(item.data.tracking_start_date) : item.earliestPrice
};

export const getInstrumentMinSavingPlanDate = (item) => momentBusinessDays(item.earliestPrice).nextBusinessDay();

export const getMaxBookingDate = () => moment();