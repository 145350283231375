const styles = (theme) => ({
  customerDataContainer: {
    fontSize: 16,
    padding: '5px 32px 0',
  },
  dropdownMenu: {
    borderRadius: 0,
    fontSize: '.8125rem',
    minWidth: '15rem',
    color: '#323C42',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    boxShadow: '0px 0px 6px #00000029',
    border: '1px solid #DFE1E3',
    overflow: 'visible',
    padding: '8px 0',

    ['@media (min-width: 700px)']: {
      '&::before': {
        left: '1.0625rem',
        pointerEvents: 'none',
        content: '""',
        position: 'absolute',
        top: '-.375rem',
        overflow: 'hidden',
        width: '.75rem',
        height: '.75rem',
        backgroundImage: 'linear-gradient(135deg,#fff 50%,transparent 50%)',
        border: '1px solid rgba(0,0,0,.15)',
        borderRight: 'none',
        borderBottom: 'none',
        transform: 'rotate(45deg)'
      }
    }
  },
  userLogoWrapper: {
    padding: '0 !important',
  },
  userLogo: {
    width: 48,
    height: 48,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',

    margin: '0 4px',

    [theme.breakpoints.only("xs")]: {
      margin: '0 2px',
      width: 46,
      height: 46,
    }
  },
});

export default styles;