import CommonAnalysisCard from "../../../../components/AnalysisCard";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import {formatPercentage} from "../../index";
import _ from 'lodash';

import PortfolioBreakdownChart from "../../components/PortfolioBreakdownChart";
import { DEFAULT_EMPTY_SECTION_MESSAGE } from '../../../../../CustomerDashboard/constants';


class ChartAssetCategoryBreakdown extends React.Component {

  renderTemplate = (name, weight) => {
    return `<div class="legend-custom">
      <span class="legend-custom-name">${name}</span>
      <span class="legend-custom-value">${formatPercentage(weight, 100)}</span>
    </div> `
  }

  renderChart() {

    let data = this.props.data.portfolio_structure;

    if(!_.isEmpty(data)) {
        let series = data.by_asset_categories.map(item => ({name: this.renderTemplate(item.name, item.weight), data:[item.weight] }));
        return <PortfolioBreakdownChart series={series}></PortfolioBreakdownChart>
    } else return <div>{DEFAULT_EMPTY_SECTION_MESSAGE}</div>
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
        classes={{
          card: classes.root
        }}
        title={'Anlageklasse'}
        loading={this.props.loading}
        content={
          <div className={classes.container}>
            {this.props.error ? this.props.error : this.props.data ? this.renderChart() : DEFAULT_EMPTY_SECTION_MESSAGE}
          </div>
      }
    />
  }
}

export default withStyles(styles)(ChartAssetCategoryBreakdown)