import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import { FactSheetsHandlerResource } from "../../../../utils/api";
import StartIcon from "../GetPdfButton/StartIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import {displayErrorSnackBar} from "../../../../components/SnackbarProvider/actions";
import {connect} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Tooltip from "../../../../components/Tooltip";

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
  auth: state.get('auth').toJS(),
});

class GetPdfButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataProvider: FactSheetsHandlerResource,
      getPdfLoading: false,
      open: false,
      anchorEl: null,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.download2pages = this.download2pages.bind(this);
    this.download4pages = this.download4pages.bind(this);
  }

  open(e) {
    this.setState({open: !this.state.open, anchorEl: e.currentTarget});
  }

  downloadBase64File(contentType, base64Data, fileName) {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  downloadPdf(pdfType) {
     this.setState({getPdfLoading: true, open: false, anchorEl: null})
     this.state.dataProvider.getPdf(this.props.isin, this.props.date.date, this.props.date.title, pdfType).then((data) => {
         if(!data.error) {
            this.downloadBase64File('application/pdf', data.data, data.filename)
         } else this.props.dispatch(displayErrorSnackBar('Fehler beim Herunterladen des Dokuments.'))
         this.setState({getPdfLoading: false})
    }).catch((error) => {
        this.props.dispatch(displayErrorSnackBar('Fehler beim Herunterladen des Dokuments.'))
        this.setState({getPdfLoading: false})
    })
  }

  download2pages() {
    this.downloadPdf('index_short')
  }

  download4pages() {
    this.downloadPdf('index')
  }

  close(e) {
    this.setState({open: false, anchorEl: null});
  }

  renderButtons() {
    const { classes } = this.props;
    let buttons = <div className={classes.dropdownMenuWrapper}>
        <IconButton onClick={this.open} color="primary" disabled={this.state.getPdfLoading || this.props.disabled} >
          {this.state.getPdfLoading ?
              <CircularProgress classes={{ root: classes.icon }} value={0} size={22} color={'inherit'}/> :
              <StartIcon processing={this.state.getPdfLoading} />}
        </IconButton>
        <Popover open={this.state.open} anchorEl={this.state.anchorEl}
                 onClose={this.close}
                 classes={{paper: classes.dropdownMenu}}
                 anchorOrigin={{vertical: 55, horizontal: 'right'}}
                 transformOrigin={{vertical: 'top', horizontal: 'right'}}
        >
          <div className={classes.dropdownItem} onClick={this.download2pages}>
            <span> PDF herunterladen (Kurzversion) </span>
          </div>
          <div className={classes.dropdownItem} onClick={this.download4pages}>
            <span> PDF herunterladen (Langversion) </span>
          </div>
        </Popover>
      </div>

      if(this.props.disabled) {
        buttons = <Tooltip title={'Durch aktuell fehlende Fondsdaten kann dieser Bereich nicht angezeigt werden.'} placement={"bottom"}>
          <span>{buttons}</span>
        </Tooltip>
      }

      return buttons;
  }

  render() {
    const { classes } = this.props;

    return <>
      {this.renderButtons()}
    </>
  }
}

export default connect(mapStateToProps)(withStyles(styles)(GetPdfButton))