import React from 'react';
import {connect} from 'react-redux';
import {createSelector} from "reselect";
import _ from "lodash";

/** Material-UI Components */
import {Container, Grid, withStyles, withWidth} from '@material-ui/core';

/** BCA Modules */
import styles from './styles';
import {
  CUSTOMER_DOES_NOT_EXIST_ERROR_MODAL_MESSAGE,
  PERMISSION_ERROR_MODAL_MESSAGE,
  SELECTED_LONGER_TIMEFRAME
} from '../../utils/constants';
import {getFromStorage, SHARED_SETTINGS_KEY} from '../../utils/storage';
import setSticky from '../../utils/sticky';
import {getGuideSteps} from "./guide";

/** BCA Components */
import Navigation from './components/PortfolioNavigation';
import ProfileDetails from './ProfileDetails';
import InvestmentDetailsNavigation, {
  DashboardPortfoliosSelectorSharedState,
  StickyNavigation
} from './components/InvestmentDetailsNavigation';
import Widgets from './components/Widgets';
import ErrorModal from '../../components/ErrorModal';
import TotalReturn from '../../components/InvestmentDetails/components/TotalReturn';
import {GuideTour} from '../../components/GuideTour';
import withCustomersSelectorNew from "../../components/CustomersSelectorProviderNew";

import withDashboardData from '../../components/DashboardDataProvider';
import withTradingsData from '../../components/TradingsDataProvider';

import {CustomerReportSettingResource, QuestionnairesHandlerResource, SharedSettingsResource} from '../../utils/api';
import {hasPortfolios} from "../../components/CustomersSelectorProviderNew/utils";
import {getBanksCompanyIdToCodeMapping} from "../Trades/utils";
import {displayWarningSnackBar} from '../../components/SnackbarProvider/actions'
import {
  extendPortfoliosWithDataForTrading,
  OnboardingButtonAttributesHelper,
  preparePortfoliosForCombinedTrading,
  tradingPossible,
} from "./utils";
import {OBJECT_TYPES} from "../RiskProfiling/constants";
import {getErrorMessage, getReportGenerationSettings, onboardingOnCustomersChange, UserUtils} from "../../utils/utils"
import withNotification from "../../components/NotificationProvider";
import {getAuthSelector} from "../../utils/redaxSelectors";
import DownloadPdfDialog from "../../components/DownloadPdfModal/DownloadPdfModal";
import {setEditorVariables} from "../GroupReporting/actions";

const getGuide = (state) => state.get('guide')

export const getGuideSelector = createSelector(
  [getGuide],
  (guide) => guide.toJS()
)


const mapStateToProps = (state) => ({
  guide: getGuideSelector(state),
  auth: getAuthSelector(state),
  combinedTrading: state.get('combinedTrading').toJS()
});

export const getUniqErrorMsgFromErrors = (errors) => {
  if(_.isArray(_.get(errors, 'data'))){
    // get uniq error messages and concat them to single string
    errors = Object.values(errors.data); // get list of each file errors objects
    errors =errors.map(e => _.get(e, 'file', _.isString(e) ? e : '')); // getting only error messages
    errors = _.flatten(errors); // making one plain list with all errors
    errors = [...new Set(errors)].join('\n') // joining uniq messages in one error message
  }

  return getErrorMessage(errors)
}


class CustomerDashboard extends React.Component {
  /** Component initialisation */
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: undefined,
      pdfOpen: false,
      expandedItems: {
        historicalChart: true,
        performanceLineChart: true,
        performanceBarChart: true,
        riskChart: true,
        structureChart: true,
        unrealizedProfitAndLossItems: [],
        profitAndLossItems: [],
        profitAndLossSubItems: {},
        paymentPlansItems: [],
        payoutPlansItems: [],
        switchPlansItems: [],
        instrumentsSubItems: {},
        instrumentsItems: [],
        cumulativeReturnChart: true,

        //to include charts from pro view
        forecastChart: true,
        performanceTable: true,
        singlePerformance: true,

        // charts KeyFiguresTab
        keyIndicatorsChart: true,
        rollingVolatilityChart: true,
        rollingSharpeRatioChart: true,
        esgScoreChart: true,
        // charts from RiskAnalysisTab
        riskReturnChart: true,
        stressTestChart: true,
        correlationMatrix: true
      },
      paymentPlanModalVisible: false,
      pdfExportBtnDisabled: false,
    }
  }

  componentDidMount() {
    setSticky('app-main', 'sticky-nav-stoper', 'investment-navigation-component-sticky', 'sticky-trigger');
    this.fetchVariables()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.auth.error) {
      if (this.props.investmentData.errors !== prevProps.investmentData.errors) {

        if (this.props.investmentData.errors) {

          this.setState({
            errorMessage: this.props.investmentData.errors
          })
        }
      }

      if (this.props.customer.errors && this.props.customer.errors !== prevProps.customer.errors) {
        if (this.props.customer.errors.status && this.props.customer.errors.status === 401) {
          return;
        }
        if (this.props.customer.errors.status && this.props.customer.errors.status === 404) {
          this.setState({
            errorMessage: CUSTOMER_DOES_NOT_EXIST_ERROR_MODAL_MESSAGE
          })
        } else if (this.props.customer.errors.status && this.props.customer.errors.status === 403) {
          this.setState({
            errorMessage: PERMISSION_ERROR_MODAL_MESSAGE
          });
        } else {
          this.setState({
            errorMessage: this.props.customer.errors
          });
        }
      }

      if (this.props.selectedRange.id && this.props.selectedRange.id !== prevProps.selectedRange.id) {
        if (getFromStorage(SHARED_SETTINGS_KEY)) {
          let appSettings = getFromStorage(SHARED_SETTINGS_KEY).app_settings || {};
          if (this.props.selectedRange.isBig && appSettings.calculation_warning_enabled) {
            this.props.dispatch(displayWarningSnackBar(SELECTED_LONGER_TIMEFRAME))
          }
        }
      }

    }
  }


  /** Call end point to download pdf file */
  handlePdfExportClick = () => {

    this.setState({
      pdfOpen: true
    })
  };

  fetchPdfFile = async (skip_expanded, skip_sub_depot_expanded, cover_text_enabled, cover_message_content, extra_files) => {
    this.setState({
      pdfExportBtnDisabled: true // disable pdf export btn when fetch starts
    });
    const {
      computedMatch: {
        params: {
          customer_id
        }
      },
    } = this.props;

    try{
      let formData = getReportGenerationSettings(customer_id, this.props.selectedPortfolios, this.props.portfolios,
        this.props.reportType, this.props.selectedDates, skip_expanded, skip_sub_depot_expanded, this.state.expandedItems, cover_text_enabled, cover_message_content,
         this.props.includeHistoricalPortfolios, extra_files, this.props.chartsSettings, this.props.instrumentsGroupBy, this.props.excludedPortfolios);

      await CustomerReportSettingResource.at(`report/ad-hoc/`).post(formData);

      this.setState({
        pdfOpen: false // close pdf export modal on success
      });
    } catch (errors) {
      this.setState({
        errorMessage: getUniqErrorMsgFromErrors(errors)
      })
    }

    this.setState({
      pdfExportBtnDisabled: false // enable pdf export btn when fetch starts
    });
  };

  handleErrorModalClose = () => {
    this.setState({
      errorMessage: undefined
    })
  };

  handleExpandedItemsChange = (field, data) => {
    const updatedExpandedItems = {
      ...this.state.expandedItems
    };
    updatedExpandedItems[field] = data;
    this.setState({
      expandedItems: updatedExpandedItems
    })
  };

  getCustomerData = (customer) => {
    if(!_.isEmpty(customer)){
      return {
        report_settings: {
          ...customer.user.settings,
        },
        customer_id: customer.customer_id
      }
    }
  };

  fetchVariables = async () => {
    try {
      const response = await SharedSettingsResource.at('editor-variables/').get();
      this.props.dispatch(setEditorVariables(response));
      window.editorVariables = response;
    } catch (e) {
      this.props.dispatch(setEditorVariables(undefined));
      window.editorVariables = undefined;
    }
  };

  /** Component rendering */
  render() {
    const {
      classes,
      customer,
      portfolios,
      investmentData,
      dashboardData,
      instrumentList,
      historicalData,
      profitAndLoss,
      paymentPlans,
      payoutPlansData,
      switchPlansData,
      updateHistoricalData,
      updateRiskMetricsBenchmark,
      unrealizedProfitAndLoss,
      breakdownData,
      timeWeightedReturnData,
      riskData,
      dataLoading,
      customerDashboard,
      includeHistoricalPortfolios,
      setIncludeHistoricalPortfolios,
      reportType,
      setReportType,
      selectedPortfolios,
      selectedDatesType
    } = this.props;

    const portfoliosToValidate = _.flatten([instrumentList.data || [], profitAndLoss.data || []]);
    const customerHasTradings = tradingPossible(portfoliosToValidate,this.props.banksMappingData || {});
    const withOtherAssets = _.get(customer, 'data.dashboard_settings.with_other_assets', true);

    return (
      <React.Fragment>
        <GuideTour
          steps={getGuideSteps(UserUtils.getCustomerReportType(customer.data), withOtherAssets)}
          title="Vermögensübersicht Info-Tour"
        />
        <Container className={`app-page-container ${classes.container}`}>
          <Navigation
            handlePdfExportClick={this.handlePdfExportClick}
            customerId={customer.data && customer.data.customer_id}
            onChartSettingsChange={this.props.handleChartSettingsChange}
            reportType={reportType}
            setReportType={setReportType}
            pdfExportDisabled={_.isEmpty(selectedPortfolios)}
          />
          <ProfileDetails
            customer={customer.data}
            customerLoadingError={customer.errors}
            customerDataLoading={customer.loading}
            investmentData={investmentData.data}
            investmentDataLoading={investmentData.loading}
            investmentDataLoadingError={investmentData.errors}
            unrealizedProfitAndLossData={unrealizedProfitAndLoss.data}
            unrealizedProfitAndLossDataLoading={unrealizedProfitAndLoss.loading}
            unrealizedProfitAndLossDataLoadingError={unrealizedProfitAndLoss.errors}
            withOtherAssets={withOtherAssets}
            reportType={reportType}
          />
          <DashboardPortfoliosSelectorSharedState>
            <StickyNavigation
              includeHistoricalPortfolios={includeHistoricalPortfolios}
              setIncludeHistoricalPortfolios={setIncludeHistoricalPortfolios}
              calculationDates={this.props.selectedDates}
              handleCalculationDatesChanged={this.props.onSelectedDatesChanged}
              updateHistoricalData={updateHistoricalData}
              portfolios={portfolios.data}
              portfoliosLoadingError={portfolios.errors}
              portfoliosDataLoading={portfolios.loading}
              handleSelectedPortfolioChanged={this.props.onSelectedPortfoliosChanged}
              selectedPortfolios={this.props.selectedPortfolios}
              calculationDatesType={this.props.selectedDatesType}
              investmentData={investmentData.data}
              dataLoading={dataLoading}
              onGoToTradeDetails={this.props.goToTradeDetails}
              instrumentListDataLoading={instrumentList.loading}
              customerHasTradings={customerHasTradings}
              tradingSession={this.props.combinedTradingSession.data}
              dispatch={this.props.dispatch}
              customerDashboard={customerDashboard.time_selector_date_change}
            />
            <Grid container style={{marginBottom: 20}}>
              <Grid item className={classes.investmentNavigationContainer} id="sticky-trigger">
                <InvestmentDetailsNavigation
                  includeHistoricalPortfolios={includeHistoricalPortfolios}
                  setIncludeHistoricalPortfolios={setIncludeHistoricalPortfolios}
                  calculationDates={this.props.selectedDates}
                  calculationDatesType={this.props.selectedDatesType}
                  handleCalculationDatesChanged={this.props.onSelectedDatesChanged}
                  updateHistoricalData={updateHistoricalData}
                  portfolios={portfolios.data}
                  portfoliosLoadingError={portfolios.errors}
                  portfoliosDataLoading={portfolios.loading}
                  handleSelectedPortfolioChanged={this.props.onSelectedPortfoliosChanged}
                  selectedPortfolios={this.props.selectedPortfolios}
                  investmentData={investmentData.data}
                  dataLoading={dataLoading}
                  dispatch={this.props.dispatch}
                  customerDashboard={customerDashboard.time_selector_date_change}
                />
              </Grid>
              <Grid item className={classes.totalReturnContainer}>
                <div className={classes.totalReturnCard}>
                  <TotalReturn
                    data={investmentData.data}
                    loading={investmentData.loading || investmentData.loadingPerformanceOnly}
                    styles={{
                      header: {
                        fontSize: 18
                      }
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </DashboardPortfoliosSelectorSharedState>
          <Widgets
            dashboardSettings={_.get(customer, 'data.dashboard_settings', {})}
            selectedPortfolios={this.props.selectedPortfolios}
            calculationDates={this.props.selectedDates}
            dashboardData={dashboardData.data}
            dashboardDataLoading={dashboardData.loading}
            dashboardDataLoadingError={dashboardData.errors}
            instrumentListData={instrumentList.data}
            instrumentListDataLoading={instrumentList.loading}
            instrumentListDataLoadingError={instrumentList.errors}
            historicalData={historicalData}
            updateHistoricalData={updateHistoricalData}
            profitAndLossData={profitAndLoss}
            paymentPlansData={paymentPlans.data}
            paymentPlansLoading={paymentPlans.loading}
            paymentPlansLoadingError={paymentPlans.errors}
            payoutPlansData={payoutPlansData.data}
            payoutPlansLoading={payoutPlansData.loading}
            payoutPlansLoadingError={payoutPlansData.errors}
            switchPlansData={switchPlansData.data}
            switchPlansLoading={switchPlansData.loading}
            switchPlansLoadingError={switchPlansData.errors}
            unrealizedProfitAndLossData={unrealizedProfitAndLoss.data}
            unrealizedProfitAndLossDataLoading={unrealizedProfitAndLoss.loading}
            unrealizedProfitAndLossDataLoadingError={unrealizedProfitAndLoss.errors}
            updateRiskMetricsBenchmark={updateRiskMetricsBenchmark}
            breakdownData={breakdownData}
            timeWeightedReturnData={timeWeightedReturnData}
            riskData={riskData}
            benchmarkConfigurationEnabled
            expandedItems={this.state.expandedItems}
            chartsSettings={this.props.chartsSettings}
            combinedTradings={this.props.combinedTradings}
            onExpandedItemsChange={this.handleExpandedItemsChange}
            onChartSettingsChange={this.props.handleChartSettingsChange}
            onAddTradingOption={this.props.handleAddTradingOption}
            onAddPortfolioTradingOption={this.props.handleAddPortfolioTradingOption}
            onAddSavingPlanOption={this.props.handleAddSavingPlanOption}
            onAddPayoutPlanOption={this.props.handleAddPayoutPlanOption}
            onAddSwitchPlanOption={this.props.handleAddSwitchPlanOption}
            onAddPortfolioSavingPlanOption={this.props.handleAddPortfolioSavingPlanOption}
            onAddPortfolioPayoutPlanOption={this.props.handleAddPortfolioPayoutPlanOption}
            onAddPortfolioSwitchPlanOption={this.props.handleAddPortfolioSwitchPlanOption}
            onGoToTradeDetails={this.props.goToTradeDetails}
            banksMapping={this.props.banksMappingData}
            banksData={this.props.banksData}
            combinedTradingSession={this.props.combinedTradingSession.data}
            virtualInstrumentLink={customer.data && customer.data.user.agency.virtual_instrument_buy_template}
            customerData={customer.data}
            reportType={reportType}
            instrumentsGroupBy={this.props.instrumentsGroupBy}
            onInstrumentsGroupByChange={this.props.handleInstrumentsGroupByChange}
          />
        </Container>
        <ErrorModal
          message={this.state.errorMessage}
          handleClose={this.handleErrorModalClose}
        />
        <DownloadPdfDialog
          open={this.state.pdfOpen}
          onClose={() => {this.setState({pdfOpen: false})}}
          onExport={this.fetchPdfFile}
          exportBtnDisabled={!this.props.selectedPortfolios || this.state.pdfExportBtnDisabled}
          customer={this.getCustomerData(customer.data)}
        >
          <DownloadPdfDialog.CoverPage />
          <DownloadPdfDialog.Documents />
          <DownloadPdfDialog.Expanded />
        </DownloadPdfDialog>
      </React.Fragment>
    )
  }
}

const getButtonAttributes = (customer) => {
  return {
    disabled: !hasPortfolios(customer)
  }
};

let combinedTradings = {};
let banksMappingData = {};
let banksData = [];


const onCustomersChange = async (customers) => {
  if (_.isEmpty(banksMappingData)) {banksMappingData = await getBanksCompanyIdToCodeMapping();}
  if (_.isEmpty(banksData)) {
    const banksCIOSData = await QuestionnairesHandlerResource.getBanksData();
    banksData = banksCIOSData.response
  }
  combinedTradings = await onboardingOnCustomersChange(customers, OBJECT_TYPES.COMBINED);
};

// TODO: Remove not necessary code after combined trading approved
const getCombinedTradingButtonAttributes = (customer) => {
  const lastTrading = combinedTradings[customer.id];
  let lastTradingStatus = OnboardingButtonAttributesHelper.isOnboardingProcessStarted(lastTrading);
  // set button attribures
  let buttonAttrs =  OnboardingButtonAttributesHelper.getDefaultAttributes(lastTradingStatus, customer);

  // set custom click handler if order is new
  if (!buttonAttrs.disabled){
    if(!lastTradingStatus){
      buttonAttrs.customOnClickHandler = async (props) => {
        // start "new Buy" process
        let tradingsValidationData = await OnboardingButtonAttributesHelper.getCombinedTradingPortfolioValidationData(
          customer.customer_id);

        let portfolios = preparePortfoliosForCombinedTrading({
          instrumentListPortfolios: tradingsValidationData.portfolios,
          profitAndLossPortfolios: tradingsValidationData.profitAndLoss,
          savingPlansPortfolios: tradingsValidationData.payment_plans,
          payoutPlansPortfolios: tradingsValidationData.payout_plans,
          switchPlansPortfolios: tradingsValidationData.switch_plans,
          banksMappingData: banksMappingData || {},
          banksData: banksData || []
        })

        extendPortfoliosWithDataForTrading(portfolios,  banksMappingData || {}, banksData || []);

        let customerData = {customer_id: customer.customer_id};
        // dispatch signal if valid portfolios exist
        await OnboardingButtonAttributesHelper.dispatchIfPropperPortfolios(props, portfolios, customerData);
      }
    } else {
      // set custom click handler for delete btn
      buttonAttrs.customOnDeleteClickHandler = async (props) => {
        await QuestionnairesHandlerResource.deleteQuestionnaire(lastTrading.session_id);  // session_id is the same as onboarding_uid
        delete combinedTradings[customer.id];

        await OnboardingButtonAttributesHelper.dispatchRemoveTradings(props, customer.customer_id);
      }
    }
  }
  return buttonAttrs;
}

export default withCustomersSelectorNew('DASHBOARD_V2', [getButtonAttributes, getCombinedTradingButtonAttributes], onCustomersChange)(withNotification(withStyles(styles)(connect(mapStateToProps)(withDashboardData(withTradingsData(withWidth()(CustomerDashboard)))))));