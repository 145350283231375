const styles = theme => ({
  button: {
    backgroundColor: '#eef0f2',
    fontSize: 14,
    padding: '10px 15px',
    border: `1px solid #D9DCDF`,
    color: '#4D4F5C',
    borderRadius: 5,
    textTransform: 'none',
    lineHeight: '1em',
    marginRight: 5,
    '&:last-of-type': {
      marginRight: 0
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    },
    '&:disabled': {
      color: 'lightgrey',
      borderColor: 'lightgrey'
    },
    [theme.breakpoints.only("xs")]: {
      marginBottom: 5,
    }
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:disabled': {
      color: 'white',
      backgroundColor: 'lightgrey',
    }
  },
  wrapper: {
    display: 'inline-block'
  }
})

export default styles;