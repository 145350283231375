const styles = theme => ({
  helpIcon: {
    color: '#E1E3E9',
    width: 23,
    height: 23,
    transform: 'rotate(180deg)',
    marginLeft: 5,
    verticalAlign: 'bottom',

    '&:hover': {
      cursor: 'pointer'
    }
  },
  bgGrey: {
    backgroundColor: '#F5F6F7',
    '&.editMode': {
      '& .MuiTableCell-root': {
        '&:nth-of-type(5), &:nth-of-type(8)': {
          backgroundColor: '#0092E50D'
        }
      }
    }
  },
  mb_0: {
    marginBottom: 0
  },
  mb_0_vl: {
    marginBottom: 0,
    height: '48px !important'
  },
  headerTitle: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    margin: '2rem 0',
    '& a': {
      fontSize: 'initial',
      margin: '0 1rem',
      fontFamily: 'Roboto-Regular'
    }
  },
  firstHeaderTitle: {
    marginTop: 0,
  },
  textFieldRoot: {
    marginTop: 2
  },
  labelRoot: {
    fontSize: 16,
  },
  vlLabelRoot: {
    fontSize: 16,
    top: '14px',
    marginBottom: '14px !important'
  },
  stepInfoContainer: {
    '&:not(:last-child)':{
      borderRight: '1px solid #D8DCDF'
    },
    '&:not(:first-child)':{
      paddingLeft: '1rem',
    }
  },
  stepInfoTitle: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    marginBottom: 16,
    '&:not(:first-of-type)': {
      marginTop: 16
    }
  },
  linkBottom: {
    marginTop: 'auto',
    color: '#0092E5',
    cursor: 'pointer',
    fontWeight: 'normal',
    '& a': {
      fontWeight: 'inherit'
    }
  },
  '&.MuiContainer-root': {
    padding: '0 !important'
  },
  tableBlue: {
    '& tbody td': {
      backgroundColor: '#0092E50D',
    }
  },
  errorContainer: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    marginBottom: '0.5rem'
  },
  smallSelect: {
    width: 82,
    height: 32,
    marginBottom: 0,
    marginTop: '0 !important',
    padding: 0,
    textAlign: 'right',
    backgroundColor: '#fff',
    border: '1px solid #ADB6BD',
  },
  smallInput: {
    width: 82,
    height: 32,
    border: '1px solid #ADB6BD',
    marginBottom: 0,
  },
  smallLabel: {
    fontSize: 10,
    color: '#0092E5',
    top: 0,
    marginBottom: 2,
  },
  overallDiscountContainer: {
    height: 38,
    position: 'relative'
  },
  blueLabel: {
    color: '#0092E5',
    fontFamily: 'Roboto-Bold',
  },
  blueInputs: {
    width: 114,
    backgroundColor: '#0092E50D',
    padding: '5px 1rem',
  },
  bgBlue: {
    width: 114,
    backgroundColor: '#0092E50D',
    padding: '5px 1rem',
    position: 'absolute',
    right: 0,
    marginBottom: '-1rem'
  },
  disabled: {
    opacity: 0.5,
  },
  loadingIndicator: {
    color: theme.palette.primary.main
  },
  loadingContainer: {
    width: '100%',
    height: 'calc(100vh - 420px)', // 420px for header and footer
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  serviceInputContainer:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  vlRoot: {
    height: '124px'
  },
  serviceFeeQuestionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputArrows: {
    marginRight: -18,
  },
  infoIcon: {
    position: 'relative',
    marginLeft: 15,

    '& > svg': {
      position: 'absolute'
    }
  },
  infoText: {
    borderLeft: '0.25rem solid #1a75b1',
    margin: '18px 0',
    paddingLeft: 10,

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      height: 35,

      '& > p': {
        fontFamily: 'Roboto-Bold',
        fontStyle: 'italic',
        fontSize: 16,
        color: '#1a75b1',
        margin: 0
      },

      '& > svg': {
        height: 15,
        marginRight: 5,

        '& > path': {
          fill: '#1a75b1',
        }
      }
    },
  },
  asterisk: {
    color: theme.palette.primary.main
  },
});

export default styles;
