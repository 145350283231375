const styles = (theme) => ({
  filterSubSectionTitleContainer: {
    display: "flex",
    alignItems: "baseline",
    width: "100%",
    flexWrap: "wrap",

    "& > p": {
      marginRight: 15,
    },
  },
  inputsContainer: {
    display: "flex",
    alignItems: "flex-start",

    "& > div": {
      marginLeft: 12,
    },
    "& > div:first-child": {
      marginLeft: 0,
    },
  },
  sustainabilitySectionTitle: {
    color: "#0D2440",
    fontSize: "13px",
  },
  acceptButton: {
    fontSize: 14,
    fontFamily: "Roboto-Bold",
    textTransform: "none",
    color: "white",
    boxShadow: "none",
    marginLeft: '10px',
    borderRadius: 5,
    backgroundColor: "#0092E5",
    "&:hover": {
      backgroundColor: "#0092E5",
    }
  },
  saveFiltersButtonDisabled: {
    opacity: 0.5,
    "&:hover": {
      opacity: 0.5,
    }
  },
  acceptButtonDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    color: 'rgba(0, 0, 0, 0.26)',
    "&:hover": {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
      color: 'rgba(0, 0, 0, 0.26)',
    }
  },
  switchItem: {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
  },
  helpIcon: {
    verticalAlign: "bottom",
    fontSize: "1.25rem",
  },
  helpIconActive: {
    color: "white",
  },
  helpIconActiveInverted: {
    color: "black",
  },
  tooltip: {
    position: "relative",
    padding: 20,
    backgroundColor: "#EFEFF2",
    color: "#4D4F5C",
    fontFamily: "Roboto-Regular",
    fontSize: 14,
    boxShadow: "0px 2px 15px 0px rgba(0,0,0, 0.2)",
    whiteSpace: "pre-line",
    maxWidth: 500,
  },
  boxesWrapper: {
    paddingTop: "50px",
  },
  boxesMainTitle: {
    color: "#202A38",
    fontSize: "14px",
    fontFamily: "Roboto-Bold",
  },
  boxContainer: {
    rowGap: "10px",
    columnGap: "20px",
  },
  box: {
    maxWidth: "calc(25% - 20px)",
    flexBasis: "calc(25% - 20px)",
    cursor: "pointer",
    border: "1px solid #D8DCDF",
  },
  boxWrapper: {
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      width: "80%",
      color: "#4D4F5C",
      fontFamily: "Roboto-Bold",
      margin: 0,
      textAlign: "center",
      fontSize: "12px",
      userSelect: "none",
    },
  },
  boxActive: {
    backgroundColor: "#0092E5",
    "& p": {
      color: "white",
    },
  },
  boxActiveInverted: {
    backgroundColor: "#f37f41",
    color: "black !important"
  },
});

export default styles;
