import React from "react";
import {Grid} from "@material-ui/core";
import useStyles from "../../styles";
import {ASSET_MODAL_TYPE} from "../../constants";
import {CallMoneyIcon, EquityIcon, ImmobilienIcon, InsuranceIcon, FileInvoiceDollarIcon} from "../../../../images";

const AssetModalMenu = (props) => {
  const classes = useStyles();

  const {
    handleMenuItemClick
  } = props;

  return (
    <Grid container id='asset-menu-modal-tour-element'>
      <Grid item className={classes.assetMenu}>
        <p>Vermögenswert hinzufügen</p>
        <div>
          <div className={classes.assetMenuItem} onClick={() => handleMenuItemClick(ASSET_MODAL_TYPE.EQUITY)}>
            <EquityIcon />
            <p>Beteiligung / Firmenanteile</p>
          </div>
          <div className={classes.assetMenuItem} onClick={() => handleMenuItemClick(ASSET_MODAL_TYPE.INSURANCE)}>
            <InsuranceIcon />
            <p>Versicherungen</p>
          </div>
          <div className={classes.assetMenuItem} onClick={() => handleMenuItemClick(ASSET_MODAL_TYPE.CASH)}>
            <CallMoneyIcon />
            <p>Fest- und Tagesgeld</p>
          </div>
          <div className={classes.assetMenuItem} onClick={() => handleMenuItemClick(ASSET_MODAL_TYPE.IMMOBILIEN)}>
            <ImmobilienIcon />
            <p>Immobilien</p>
          </div>
          <div className={classes.assetMenuItem} onClick={() => handleMenuItemClick(ASSET_MODAL_TYPE.EXTERNAL)}>
            <FileInvoiceDollarIcon />
            <p>Wertpapiere</p>
          </div>
        </div>
      </Grid>
    </Grid>
  )
};

export default AssetModalMenu;