import React, { Component } from 'react';
import _, { toInteger } from 'lodash';
import Tabs from '@material-ui/core/Tabs';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Container,
  Grid,
  withStyles,
  CircularProgress,
  Paper,
  Tab,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core';
import {
  TabPanel,
  TabContext
} from '@material-ui/lab';

import {
  DocumentNavigation,
  DocumentEditor,
  DocumentsFilter,
  DocumentEditorTabs,
  CollectionNavigation,
  ModalLoading
} from './components';

import {
  FormCenterHandlerResource,
  CustomerResource, PortfolioHandlerResource
} from '../../utils/api';
import { AGGREGATED_PORTFOLIO_ID, downloadPdf, downloadZip, toLocalDateTime } from '../../utils/utils';
import { styles } from './styles';
import {
  handleDocumentFields,
  annotationForEach,
  getDocumentFieldValues,
  handleRadioButtons,
  filterCollections
} from './utils';
import Snackbar from '../../components/Snackbar';
import withCustomersSelectorNew from "../../components/CustomersSelectorProviderNew";
import Collections from './Collections'
import {
  displayErrorSnackBar,
  displaySuccessSnackBar,
  displayWarningSnackBar
} from '../../components/SnackbarProvider/actions'
import {
  PAGE_COUNT,
  ACTIVE_PAGE,
  SCALE,
  OPEN_MAPPEN,
  CLOSED_MAPPEN,
  EXPIRED_MAPPEN
} from './constants'


const defaultDataParameters = {
  data: undefined,
  loading: false,
  errors: undefined,
  updated: undefined
};

let latestCollections = {};

const onCustomersChange = async (customers) => {
  if(customers){
    latestCollections = await FormCenterHandlerResource.getLatestCollections(customers.map(c => c.id));
  }
};

const getButtonAttributes = (customer) => {
  const data = latestCollections[customer.id];
  return {
    title: data && data.is_open ? 'Offene Mappen' : 'Formularcenter',
    openInNewTab: true
  }
}

const extraColumns = [{
  content: [{
    body: {
      content: (customer) => {
        const data = latestCollections[customer.id];
        if(data){
          const updatedAt = toLocalDateTime(data.updated_at);
          return (
            <>
              <span>{updatedAt.format('DD.MM.YYYY')}</span><br/>
              <span>{updatedAt.format('HH:mm')}</span>
            </>
          )
        } else {
         return ' - ';
        }
      }
    },
    header: {
      content: () => (
        <>
          <span>Zuletzt</span><br/>
          <span>bearbeitet</span>
        </>
      )
    }
  }],
}];

const COLLECTIONS_FILTER_TABS = [OPEN_MAPPEN, CLOSED_MAPPEN, EXPIRED_MAPPEN]

/** 
 * Document center container.
 * Contains logic related to pdf documents retrieving, editing.
 **/
export default withCustomersSelectorNew(
  'FORM_CENTER', getButtonAttributes, onCustomersChange, extraColumns)(withStyles(styles)(class DocumentCenter extends Component {

  state = {
    customerData: { ...defaultDataParameters },
    banksData: { ...defaultDataParameters },

    activeDocument: undefined,
    selectedActiveDocuments: [],
    activeDocuments: [],
    selectedDocuments: {
      data: [],
      loading: false,
      updated: undefined,
      draft: false
    },
    selectedDocumentsContent: { ...defaultDataParameters },
    selectedDocumentsAnnotations: {},
    documentsMeta: {},

    collections: {...defaultDataParameters},
    activeCollection: {
      id: undefined,
      loading: false,
      sign_possible: false,
      documents: [],
      edited: false,
      signing: false,
    },
    collectionsCounts: {},

    renderedDocumentsCount: 0,
    snackbar: {
      message: null,
      variant: 'error',
      open: false
    },
    activeTab: "1",
    firstTabLabel: 'Neue Mappe anlegen',
    inputRestoreValues: {},

    editor: {
      documentId: null
    },
    navigationAction: false,
    disableActions: false,
    annotationLoaded: false,
    downloadClick: false,
    saveClick: false,
    signClick: false,
    getPageAnnotationsProgress: 0,
    editorTabLoading: false,
    documentsFilterOpened: true,

    portfolios: [],
    selectedPortfolio: null
  }

  componentDidMount() {
    this.fetchCustomer().then(() => {
      this.fetchFolderData();
      this.fetchCollections()
    });
    window.addEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    setTimeout(() => {
      // wait until window is align to new size
      this.restoreDocumentPage()
    }, 500)
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.customerData.updated != this.state.customerData.updated &&
      this.state.customerData.data
    ) {
      this.handleSelectedCollection();
    }

    if (
      prevState.selectedDocuments.updated != this.state.selectedDocuments.updated &&
      this.state.selectedDocuments.data &&
      this.state.selectedDocuments.data.length > 0
    ) {
      this.fetchDocumentContent();
    }

    if (this.state.navigationAction) {
      this.restoreDocumentPage()
      this.setState({navigationAction: false})
    }

    if (prevState.selectedDocuments.draft != this.state.selectedDocuments.draft) {
      this.setState({
        firstTabLabel: this.state.selectedDocuments.draft ? 'Mappe bearbeiten' : 'Neue Mappe anlegen'
      })
    }

    if (prevState.activeTab != this.state.activeTab) {
      if (this.state.activeTab == 1) {
        setTimeout(() => {
          this.setState({
            editorTabLoading: false
          })
        }, 500)
      }
    }

    if (this.state.downloadClick && this.state.annotationLoaded) {
      this.DownloadDocumentButtonClick()
      this.restoreDocumentPage()
    }

    if (this.state.saveClick && this.state.annotationLoaded) {
      await this.SaveCollectionButtonClick()
      this.restoreDocumentPage()
    }

    if (!this.state.activeCollection.edited && this.state.signClick) {
      this.setState({signClick: false})
      await this.signCollection()
    }
  }

  /**
   * Fetch banks data to display it as a documents dropdown menu.
   */
  fetchFolderData = async () => {

    this.setState({
      banksData: {
        data: undefined,
        loading: true,
        errors: undefined
      }
    });

    try {

      let response = await FormCenterHandlerResource.getFolders();

      this.setState({
        banksData: {
          data: response,
          loading: false,
          errors: undefined,
          updated: Date.now()
        }
      });

    } catch (errors) {

      this.setState({
        banksData: {
          data: undefined,
          loading: false,
          updated: Date.now(),
          errors
        }
      });

    }

  };

  handleSelectedCollection = async () => {

    if (this.state.customerData.data) {
      let queryParams = this.props.location.search
      queryParams = new URLSearchParams(queryParams)
      let collectionId = queryParams.get('collection_id')

      if (collectionId) {
        this.setState({
          activeDocuments: [],
          selectedDocuments: {...this.state.selectedDocuments,
            data: [],
            loading: true,
          },
          activeCollection: {
            id: undefined,
            loading: true,
            sign_possible: false,
            documents: [],
            signing: false
          },
          documentsMeta: {},
          selectedDocumentsContent: {...this.state.selectedDocumentsContent, data: []},
          inputRestoreValues: {}
        });

        try {

          let response = await FormCenterHandlerResource.getSingleCollectionData(this.state.customerData.data.customer_id, collectionId)
          let documents = response.documents.map(doc => Object.defineProperties(doc, {'draft': {value: true}}))

          this.setState({
            activeCollection: {
              id: collectionId,
              loading: false,
              sign_possible: _.some(documents, 'sign_possible'),
              documents,
              signing: false
            },
            selectedDocuments: {
              data: documents,
              updated: Date.now(),
              draft: true,
              loading: false
            },
            activeDocuments: [...documents],
            selectedActiveDocuments: [...documents]
          });

        } catch (errors) {
          this.setState({
            selectedDocuments: {
              loading: false
            }
          });
          let currentPath = window.location.pathname
          let targetPath = `${currentPath}`
          this.props.history.push(targetPath)

          this.props.dispatch(displayErrorSnackBar('Der Abbruf der Datenzusammenstellung ist nicht möglich.'))
        }
      }
    }
  }

  handleCollectionChanges = (collections) => {
    if (collections.data) {
      this.setState(prevState => ({
        collections: {...prevState.collections, data: collections.data},
        collectionsCounts: filterCollections(collections.data)
      }))
    }
  }

  signCollection = async () => {
    FormCenterHandlerResource.signCollectionsDocument(
    _.get(this.state.customerData.data, 'customer_id'), this.state.activeCollection.id)
    .then(response => {
      if (response.status == 200) {
        this.props.dispatch(displayWarningSnackBar('Bitte begeben Sie sich in den Bereich “E-Unterschrift Status”, um Ihre Dokumente zu unterschreiben.'))
      } else if (response.status == 201) {
        this.props.dispatch(displaySuccessSnackBar('Der digitale Signierungsprozess wurde erfolgreich erstellt.'))
      }
      this.setState(prevState => ({
        activeCollection: {...prevState.activeCollection, signing: false}
      }))
    })
    .catch(e => {
      this.props.dispatch(displayErrorSnackBar('Beim Erstellen des digitaler Signierungsprozess ist ein Fehler aufgetreten.'))
      this.setState(prevState => ({
        activeCollection: {...prevState.activeCollection, signing: false}
      }))
      console.log(e)
    })
  }

  /**
   * Fetch selected customer data.
   */
  fetchCustomer = async () => {

    this.setState({
      customerData: {
        loading: true,
        data: undefined,
        errors: undefined
      }
    });

    try {

      const {
        computedMatch: {
          params: {
            customer_id
          }
        },
      } = this.props;

      // get customer data to get portfolios list
      let response = await PortfolioHandlerResource.getCustomerAppData(customer_id, false, false, false, false, false, undefined, true)
      let portfolios = _.get(response, 'customer.data.0.portfolios.portfolios', []).filter(
        p => p.depotNumber != AGGREGATED_PORTFOLIO_ID)

      this.setState({
        customerData: {
          data: {customer_id},
          loading: false,
          errors: undefined,
          updated: Date.now()
        },

        portfolios: portfolios
      })

    } catch (errors) {

      this.setState({
        loading: false,
        data: undefined,
        updated: Date.now(),
        errors
      });

    }

  };

  /**
   * Retrieve content for selected document.
   */
  fetchDocumentContent = async () => {
    this.setState(prevState => ({
      selectedDocumentsContent: {
        ...prevState.selectedDocumentsContent,
        loading: true
      },
      renderedDocumentsCount: prevState.renderedDocumentsCount || 0
    }));

    let {
      documentsMeta,
      activeCollection
    } = {...this.state}

    try {

      let queryParams = this.props.location.search
      queryParams = new URLSearchParams(queryParams)
      let collectionId = queryParams.get('collection_id')

      // filter out IDs of already open documents to avoid re-request
      let documentsIDsToFetch = _.differenceBy(this.state.selectedActiveDocuments, this.state.selectedDocumentsContent.data, 'id').map(doc => doc.id)

      await FormCenterHandlerResource.getDocumentsFormFields(documentsIDsToFetch, this.state.customerData.data.customer_id)
        .then(
          response => {
            response.data.map(document => {
              documentsMeta[document.id] = {...documentsMeta[document.id], ...document}
            })
            if (this.state.activeCollection.id) {
              activeCollection.sign_possible = _.some(documentsMeta, 'sign_possible')
            }
          }, err => {
            this.setState(prevState => ({
              selectedDocumentsContent: {
                ...prevState.selectedDocumentsContent,
                loading: false,
                updated: Date.now()
              }
            }));
            this.props.dispatch(displayErrorSnackBar('Fehler bei der Dokumentenerzeugung beim Abruf der Formularfelder.'))
          })

      if (Object.keys(documentsMeta).length == this.state.selectedDocuments.data.length) {

        let requests = this.state.selectedDocuments.data.map(selectedDocument => {
          if (documentsIDsToFetch.includes(selectedDocument.id)) {
            if (collectionId && selectedDocument.draft) {
              return FormCenterHandlerResource.getCollectionContent(this.state.customerData.data.customer_id, collectionId, selectedDocument.id)
                .then(
                  response => ({
                    id: selectedDocument.id,
                    name: selectedDocument.name,
                    content: response
                  }));
            } else {
              let params = this.state.selectedPortfolio
                ? {
                    portfolio_id: this.state.selectedPortfolio.number,
                    model_portfolio_id: this.state.selectedPortfolio.model_portfolio_id,
                    model_portfolio_name: this.state.selectedPortfolio.model_portfolio_name
                  }
                : {}
              return FormCenterHandlerResource.getDocumentsContent([selectedDocument], this.state.customerData.data.customer_id, params)
                .then(
                  response => ({
                    id: selectedDocument.id,
                    name: selectedDocument.name,
                    content: response
                  }));
            }
          }
        }).filter(item => item !== undefined)

        Promise.all(requests).then(values => {
          values.map((document) => {
            let docMeta = documentsMeta[document.id] || {}
            documentsMeta[document.id] = {
              activePage: docMeta.activePage || 1,
              contentPageCount: docMeta.contentPageCount || undefined,
              scale: docMeta.scale || 1.0,
              ...documentsMeta[document.id],
            }
          })
          let activeDocumentId = this.state.editor.documentId || _.get([...this.state.activeDocuments, ...values], '[0].id')
          this.setState({
            activeCollection,
            selectedDocumentsContent: {
              loading: false,
              errors: undefined,
              data: [..._.flatten(this.state.selectedDocumentsContent.data),  ...values],
              updated: Date.now()
            },
            annotationLoaded: false,
            activeDocuments: _.uniqBy([...this.state.activeDocuments, ...values], 'id'),
            selectedActiveDocuments: _.uniqBy([...this.state.activeDocuments, ...values], 'id'),
            editor: {
              documentId: activeDocumentId || null
            },
            documentsMeta,
            activeDocument: activeDocumentId || undefined,
            navigationAction: true
          });
        })
        .catch(errors => {
          this.setState(prevState => ({
            selectedDocumentsContent: {
              data: prevState.selectedDocumentsContent.data || [],
              loading: false,
              updated: Date.now(),
              errors
            }
          }));
          this.props.dispatch(displayErrorSnackBar('Fehler beim Abruf des Dokumenten Inhaltes.'))
        })
      }
    } catch (errors) {
      this.setState(prevState => ({
        selectedDocumentsContent: {
          data: prevState.selectedDocumentsContent.data || [],
          loading: false,
          updated: Date.now(),
          errors
        }
      }));
      this.props.dispatch(displayErrorSnackBar('Fehler beim Abruf des Dokumenten Inhaltes.'))
    }
  }

  fetchCollections = async () => {
    this.setState(prevState => ({
      collections: {...prevState.collections, loading: true},
      activeCollection: {...prevState.activeCollection, loading: true}
    }))

    try {
      let queryParams = this.props.location.search
      queryParams = new URLSearchParams(queryParams)
      let collectionId = queryParams.get('collection_id') || undefined

      let response = await FormCenterHandlerResource.getDocumentsCollections(this.state.customerData.data.customer_id)

      this.setState(prevState => ({
        activeCollection: {...prevState.activeCollection, loading: false},
        collections: {
          data: response,
          loading: false,
          updated: Date.now()
        },
        collectionsCounts: filterCollections(response)
      }))

    } catch (errors) {
      console.log('Collections collections errors: ', errors)
      this.setState(prevState => ({
        collections: {
          ...prevState.collections,
          loading: false,
          errors: errors
        },
        activeCollection: {
          id: undefined,
          loading: false,
          sign_possible: false,
          documents: [],
          signing: false
        }
      }))
      this.props.dispatch(displayErrorSnackBar('Fehler beim Abruf der Zusammenstellung'))
    }
  }

  /**
   * Event handler for document click event.
   */
  handleDocumentsSelect = (documents) => {
    if (!documents.length && this.state.activeDocuments.length) {
      this.setState({
        selectedActiveDocuments: [...this.state.activeDocuments]
      });
    } else {
      this.setState({
        selectedActiveDocuments: [...documents]
      });
    }
  }

  /**
   * Event handler for selected document open click event.
   */
  handleDocumentsContentLoad = () => {
    const prevActiveDocuments = this.state.activeDocuments

    let newSelectedDocuments = this.state.selectedActiveDocuments.filter(obj => !prevActiveDocuments.includes(obj));

    if (newSelectedDocuments.length) {
      let activeDocuments = [...this.state.activeDocuments, ...newSelectedDocuments];
      let isDraft = this.state.selectedDocuments.draft
  
      this.setState(prevState => ({
        selectedDocuments: {
          data: activeDocuments,
          updated: Date.now(),
          draft: isDraft
        },
        selectedActiveDocuments: activeDocuments,
        selectedDocumentsAnnotations: {...prevState.selectedDocumentsAnnotations},
        selectedDocumentsContent: {...prevState.selectedDocumentsContent}
      }));
    }

    if (this.state.activeCollection.id && newSelectedDocuments.length) {
      this.setState(prevState => ({
        activeCollection: {
          ...prevState.activeCollection,
          edited: true
        }
      }))
    }
  }

   /**
   * Function for saving annotation from pdf document file to state
   */
  handleAnnotationsLoaded = (documentId, annotations, pageNumber, documentsRendered) => {
    if (this.state.selectedDocumentsAnnotations.hasOwnProperty(documentId)) {
      this.setState({
        selectedDocumentsAnnotations: {
          ...this.state.selectedDocumentsAnnotations,
          [documentId]: {
            ...this.state.selectedDocumentsAnnotations[documentId],
            [pageNumber]: annotations
          }
        }
      });
    } else {
      this.setState({
        selectedDocumentsAnnotations: {
          ...this.state.selectedDocumentsAnnotations,
          [documentId]: {
            [pageNumber]: annotations
          }
        }
      });
    }

    if (documentId && pageNumber) {
      setTimeout(() => {
        this.fillFields(documentId, pageNumber)
      }, 10)
    }

    if (this.state.saveClick || this.state.downloadClick) {
      // calculate the value of the completed process
      // as a percentage when receiving annotations
      // for the preloader counter during downloading/saving collections
      let diff = 100 / Object.values(this.state.documentsMeta).map(obj => obj.contentPageCount).reduce((a, b) => {return a + b}, 0)
      this.setState(prevState => ({
        getPageAnnotationsProgress: prevState.getPageAnnotationsProgress + diff
      }))
    }

    if (documentsRendered && Object.values(documentsRendered).every(isRendered => isRendered)) {
      // if all open documents have already been rendered,
      // they do not need to redraw.
      // this allows to increase the speed of
      // downloading/saving collections process.
      this.setState({
        annotationLoaded: true
      })
    }

  }

  handlePageRenderSuccess = (documentId, pageNumber, documentPageCounter, redneredDocumentsCount) => {
    let {
      documentsMeta,
    } = {...this.state}
    for (const [documentId, pagesCount] of Object.entries(documentPageCounter)) {
      if (documentsMeta[documentId]) {
        documentsMeta[documentId].contentPageCount = pagesCount
      }
    }

    this.setState({
      renderedDocumentsCount: redneredDocumentsCount,
      documentsMeta
    })
  }

  handleSaveCollectionButtonClick() {
    this.setState({
      saveClick: true,
      disableActions: true
    })
  }

  SaveCollectionButtonClick = async () => {
    const {
      activeDocuments,
      inputRestoreValues,
      selectedDocumentsAnnotations,
      documentsMeta
    } = {...this.state}

    let fields = getDocumentFieldValues(
      activeDocuments,
      selectedDocumentsAnnotations,
      inputRestoreValues,
      documentsMeta
    )

    let queryParams = this.props.location.search
    queryParams = new URLSearchParams(queryParams)
    let collectionId = queryParams.get('collection_id')

    this.saveCollection(fields, collectionId, activeDocuments)

    this.setState(prevState => ({
      saveClick: false,
      activeCollection: {
        ...prevState.activeCollection,
        edited: false
      },
      disableActions: false,
      getPageAnnotationsProgress: 0

    }))
  }

  handleDownloadDocumentButtonClick() {
    this.setState({
      downloadClick: true,
      disableActions: true
    })
  }

  DownloadDocumentButtonClick = () => {
    const {
      activeDocuments,
      inputRestoreValues,
      selectedDocumentsAnnotations,
      documentsMeta
    } = {...this.state}

    let fields = getDocumentFieldValues(
      activeDocuments,
      selectedDocumentsAnnotations,
      inputRestoreValues,
      documentsMeta
    )

    let hasErrors = false;

    // TODO: Implement fields validation

    if (!hasErrors) {

      let postParams = {
        fields,
        customer_id: this.state.customerData.data.customer_id
      };
      let errors;

      downloadZip('form-center/documents/download/', undefined, 'POST', undefined, postParams)
        this.setState({
          downloadClick: false,
          disableActions: false,
          getPageAnnotationsProgress: 0,
      })
    } else {
      this.setState({
        downloadClick: false,
        disableActions: false,
        getPageAnnotationsProgress: 0,
        snackbar: {
          variant: 'error',
          open: true,
          message: 'Nicht alle Pflichtfelder wurden befüllt.'
        }
      })
    }

  }

  renderBanksExternalContent = () => {
    const {
      classes
    } = this.props;

    if (this.state.banksData.loading) {
      return (
        <div className={classes.externalContentContainer}>
          <CircularProgress color="primary" />
        </div>
      );
    } else if (this.state.banksData.errors) {
      return (
        <div className={classes.externalContentContainer}>
          <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
        </div>
      );
    }
  }

  handleCollectionItemClick = () => {
    this.setState({
      activeTab: "1"
    }, () => {
      if (this.state.activeDocuments.length) {
        // close all opened documents in editor before load collection
        this.onEditorTabClose(this.state.activeDocuments.map(item => item.id), false)
      }
      this.handleSelectedCollection()
    })
  }

  saveInputValues = (inputName=null) => {
    let {
      activeDocument,
      inputRestoreValues,
      documentsMeta,
      selectedDocumentsAnnotations
    } = {...this.state}

    let hasErrors

    let activePage = this.getDocumentMeta(ACTIVE_PAGE)

    if (_.get(selectedDocumentsAnnotations, `${activeDocument}.${activePage}`)) {

      let $documentContainer = document.getElementById(activeDocument);
      if (!$documentContainer) {
        return
      }

      let pageAnnotations = selectedDocumentsAnnotations[activeDocument][activePage]
      let formFields = documentsMeta[activeDocument].form_fields

      if (inputName) {
        pageAnnotations = [pageAnnotations.find(obj => obj.fieldName == inputName)]
      }

      annotationForEach($documentContainer, pageAnnotations, activeDocument, (input, annotation) => {
        if (!inputRestoreValues.hasOwnProperty(activeDocument)) {
          inputRestoreValues[activeDocument] = {}
          inputRestoreValues[activeDocument][activePage] = {}
        } else if (!inputRestoreValues[activeDocument].hasOwnProperty(activePage)) {
          inputRestoreValues[activeDocument][activePage] = {}
        }

        let radioSelect = _.filter(pageAnnotations, a => a.fieldName == annotation.fieldName);
        let pageFieldsStorage = inputRestoreValues[activeDocument][activePage]
        let defaultNull = formFields[annotation.fieldName].value
        defaultNull = defaultNull ? defaultNull.replace('/', '') : defaultNull
        if (input.type == 'checkbox' || input.type == 'radio') {
          if (input.checked) {
            if(radioSelect && radioSelect.length > 1) {
              pageFieldsStorage[annotation.fieldName] = annotation.exportValue || annotation.buttonValue
            } else {
              pageFieldsStorage[annotation.fieldName] = annotation.buttonValue || annotation.exportValue || formFields[annotation.fieldName].button_value[0]
            }
          } else {
            if (radioSelect && radioSelect.length > 1) {
              if (radioSelect.every(a => !a.fieldValue)) {
                pageFieldsStorage[annotation.fieldName] = defaultNull
              }
            } else {
              pageFieldsStorage[annotation.fieldName] = defaultNull
            }
          }
        } else if (input.type == 'select-one') {
          pageFieldsStorage[annotation.fieldName] = input.value
        } else {
          pageFieldsStorage[annotation.fieldName] = input.value || '';
        }
      });
      this.setState(prevState => ({
        inputRestoreValues,
        activeCollection: {
          ...prevState.activeCollection,
          edited: true
        }
      }))
    }
  }

  fillFields = (documentId, pageNumber) => {
    const {
      activeDocuments,
      inputRestoreValues,
      selectedDocumentsAnnotations,
      documentsMeta
    } = {...this.state}

    if (_.get(selectedDocumentsAnnotations, `${documentId}.${pageNumber}`)) {

      let $documentContainer = document.getElementById(documentId);

      if (!$documentContainer) {
        setTimeout(() => {
          $documentContainer = document.getElementById(documentId);
        }, 500)
      }
      if (!$documentContainer) {
        return
      }

      let pageAnnotations = selectedDocumentsAnnotations[documentId][pageNumber]

      let fields = getDocumentFieldValues(
        activeDocuments,
        selectedDocumentsAnnotations,
        inputRestoreValues,
        documentsMeta
      )[documentId]

      let formFields = documentsMeta[documentId].form_fields

      annotationForEach($documentContainer, pageAnnotations, documentId, (input, annotation) => {
        if (fields.hasOwnProperty(annotation.fieldName)) {
          try {
            if (input.type == 'checkbox' || input.type == 'radio') {
              let radioSelect = _.filter(pageAnnotations, a => a.fieldName == annotation.fieldName);
              if (radioSelect && radioSelect.length > 1) {
                handleRadioButtons($documentContainer, annotation, pageAnnotations, this.saveInputValues, fields)
              } else {
                if (annotation.hasOwnProperty('buttonValue')) {
                  input.checked = fields[annotation.fieldName] == annotation.buttonValue
                } else if (annotation.hasOwnProperty('exportValue')) {
                  input.checked = fields[annotation.fieldName] == annotation.exportValue
                } else {
                  input.checked = fields[annotation.fieldName] == formFields[annotation.fieldName].button_value[0]
                }
                input.addEventListener('change', event => {
                  this.saveInputValues(annotation.fieldName)
                })
              }
            } else if (input.type == 'select-one') {
              input.value = fields[annotation.fieldName]
              input.addEventListener('change', event => {
                this.saveInputValues(annotation.fieldName)
              })
            } else {
              input.value = fields[annotation.fieldName]
              input.addEventListener('input', event => {
                this.saveInputValues(annotation.fieldName)
              })
            }
          } catch (error) {
            console.error(error)
          }
        }
      })
    }
    this.setState({disableActions: false})
  }

  handleCloseCollectionButtonClick() {
    this.onEditorTabClose(this.state.activeCollection.documents.map(item => item.id))
  }

  async handleSignCollectionButtonClick() {
    if (this.state.activeCollection.edited) {
      this.handleSaveCollectionButtonClick()
    }
    this.setState(prevState => ({
      signClick: true,
      activeCollection: {...prevState.activeCollection, signing: true}
    }))
  }

  async saveCollection(fields, collectionId, activeDocuments) {
    let postParams = {
      fields: fields,
      customer_id: this.state.customerData.data.customer_id
    };

    if (collectionId) {
      postParams.collection_id = collectionId
    }

    try {
      let response = await FormCenterHandlerResource.saveCollection(this.state.customerData.data.customer_id, postParams)
      let currentPath = window.location.pathname
      let targetPath = `${currentPath}?collection_id=${response}`
      this.props.history.push(targetPath)
      this.setState(prevState => ({
        activeCollection: {
          ...prevState.activeCollection,
          id: response,
          sign_possible: _.some(this.state.documentsMeta, 'sign_possible'),
          documents: activeDocuments
        },
        selectedDocuments: {...prevState.selectedDocuments, draft: true}
      }))
      this.props.dispatch(displaySuccessSnackBar('Entwurf wurde gespeichert'))
      await this.fetchCollections()
    } catch (errors) {
      this.props.dispatch(displayErrorSnackBar('Entwurf konnte nicht gespeichert werden'))
    }
  }

  handleActiveTabChanged = (event, index) => {
    if (this.state.activeTab != index) {
      this.setState({
        activeTab: index,
        editorTabLoading: index == 1
      })
    }
  }

  switchEditorTab(documentId) {
    let editor = {'documentId': null}
    let activeDocument = undefined

    if (this.state.selectedDocuments.data.filter(obj => obj.id == documentId).length) {
      editor.documentId = documentId
      activeDocument = documentId
    } else if (this.state.selectedDocuments.data.length) {
      editor.documentId = this.state.selectedDocuments.data[0].id
      activeDocument = this.state.selectedDocuments.data[0].id
    }

    this.setState({
      editor: editor,
      activeDocument: activeDocument
    });
  }

  getDocumentMeta(META_TYPE) {
    const {
      documentsMeta,
      activeDocument
    } = this.state
    if (documentsMeta && documentsMeta[activeDocument]) {
      return documentsMeta[activeDocument][META_TYPE]
    }
  }

  restoreDocumentPage() {
    this.setState({disableActions: true})
    setTimeout(() => {
      this.fillFields(this.state.activeDocument, this.getDocumentMeta(ACTIVE_PAGE))
    }, 10)
  }

  onNextPage() {
    let { documentsMeta, activeDocument } = {...this.state}
    if (documentsMeta[activeDocument].activePage < documentsMeta[activeDocument].contentPageCount) {
      documentsMeta[activeDocument].activePage++
      this.setState({documentsMeta})
    }
  }

  onPrevPage() {
    let { documentsMeta, activeDocument } = {...this.state}
    if (documentsMeta[activeDocument].activePage > 1) {
      documentsMeta[activeDocument].activePage--
      this.setState({documentsMeta})
    }
  }

  switchToPage(page) {
    let { documentsMeta, activeDocument } = {...this.state}
    if (0 < page && page <= this.getDocumentMeta(PAGE_COUNT)) {
      documentsMeta[activeDocument].activePage = toInteger(page)
      this.setState({documentsMeta})
    }
  }

  onPageScaleUp() {
    let { documentsMeta, activeDocument } = {...this.state}
    documentsMeta[activeDocument].scale += 0.1
    this.setState({documentsMeta})
  }

  onPageScaleDown() {
    let { documentsMeta, activeDocument } = {...this.state}
    documentsMeta[activeDocument].scale -= 0.1
    this.setState({documentsMeta})
  }

  onEditorTabClose(documentID, clearPath=true) {
    let {
      activeDocument,
      activeDocuments,
      selectedDocuments,
      selectedDocumentsAnnotations,
      selectedDocumentsContent,
      documentsMeta,
      renderedDocumentsCount,
      inputRestoreValues,
      firstTabLabel,
      editor,
      selectedActiveDocuments,
      activeCollection
    } = this.state

    if (!Array.isArray(documentID)) {
      documentID = [documentID]
    }

    documentID.forEach(function (docID, index) {
      delete selectedDocumentsAnnotations[docID]
      delete documentsMeta[docID]
      delete inputRestoreValues[docID]
      activeDocuments = activeDocuments.filter(obj => obj.id != docID)
      selectedActiveDocuments = selectedActiveDocuments.filter(obj => obj.id != docID)
      selectedDocuments.data = selectedDocuments.data.filter(obj => obj.id != docID)
      selectedDocumentsContent.data = selectedDocumentsContent.data.filter(obj => obj.id != docID)

      activeDocument = activeDocuments.length ? activeDocuments[0].id : undefined
      editor.documentId = activeDocument ? activeDocument : null
      renderedDocumentsCount -= 1 ? renderedDocumentsCount > 0 : 0
    });

    if (
      clearPath &&
      activeCollection.id
    ) {
      activeCollection.edited = true
      // if editor contain documents from collection
      // and they all was closed by own close icon
      // or by close collection button =>
      // will necessary to clear path and activeCollection data
      if (activeCollection.documents.every(doc => !_.find(selectedDocuments.data, {'id': doc.id}))) {
        this.props.history.push(window.location.pathname)
        firstTabLabel = 'Neue Mappe anlegen'
        selectedDocuments.draft = false
        activeCollection = {
          id: undefined,
          loading: false,
          sign_possible: false,
          documents: [],
          edited: true
        }
      }
    }

    this.setState({
      activeCollection,
      activeDocument,
      documentsMeta,
      inputRestoreValues,
      activeDocuments,
      selectedDocuments,
      selectedActiveDocuments,
      selectedDocumentsContent,
      selectedDocumentsAnnotations,
      editor,
      renderedDocumentsCount,
      firstTabLabel
    })
  }

  getCollectionCount(tab) {
    if (this.state.collectionsCounts[tab]) {
      return _.flatten(this.state.collectionsCounts[tab]).length
    } else {
      return 0
    }
  }

  renderLabel(tab) {
    return (
      <>
        <div style={{display: 'flex'}}>
          {tab}
          <div style={{display: 'flex', alignItems: 'center', marginLeft: '5px'}}>
            {this.state.collections.loading ? <CircularProgress size={22}/> : `(${this.getCollectionCount(tab)})`}
          </div>
        </div>
      </>
    )
  }

  renderTabListItems() {
    const { classes } = this.props;
    let items = [<Tab classes={{ root: classes.tab, selected: classes.tabSelected }} label={this.state.firstTabLabel} value="1" />]
    COLLECTIONS_FILTER_TABS.map(tab =>
      items.push(<Tab
      key={tab}
      id={`simple-tab-${tab}`}
      aria-controls={`simple-tabpanel-${tab}`}
      classes={{ root: classes.tab, selected: classes.tabSelected }}
      label={this.renderLabel(tab)}
      value={tab}
      />)
    )
    return items
  }

  handleFiltersToggle(event, expanded) {
    this.setState(prevState => ({documentsFilterOpened: !prevState.documentsFilterOpened}));
  };

  render() {
    const { classes } = this.props;

    return (
      <Container className={`app-page-container ${classes.container}`}>
        {this.state.customerData.loading ? (
          <Grid container style={{ height: 300 }}>
            <Grid item className={classes.pageLoader}>
              <CircularProgress size={66} className={classes.loader} />
            </Grid>
          </Grid>
        ) : (
          <>
            <TabContext value={this.state.activeTab}>
              <Grid container style={{ marginBottom: '24px' }}>
                <Grid item xs={12}>
                  <Paper square={true} classes={{ root: classes.paperRoot }}>
                    <Accordion
                      expanded={this.state.documentsFilterOpened}
                      onChange={undefined}
                      classes={{ root: classes.accordionRoot, expanded: classes.accordionExpanded}}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        IconButtonProps={{
                          classes: {edgeEnd: classes.accButtonIconEdgeEnd},
                          onClick: this.handleFiltersToggle.bind(this)
                        }}
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        classes={{
                          root: classes.accordionSummaryRoot,
                          expanded: classes.accordionSummaryExpanded,
                          content: classes.accordionSummaryContent,
                        }}
                      >
                        <Tabs
                          value={this.state.activeTab}
                          classes={{ indicator: classes.activeTabIndicator }}
                          variant="scrollable"
                          onChange={this.handleActiveTabChanged}
                          TabIndicatorProps={{ children: <div /> }}
                          children={this.renderTabListItems()}
                        />
                      </AccordionSummary>
                      <Divider />
                      <AccordionDetails
                        classes={{ root: classes.accordionDetailsRoot }}
                      >
                        <DocumentsFilter
                          banksData={this.state.banksData}
                          activeDocuments={this.state.activeDocuments}
                          selectedDocuments={this.state.selectedActiveDocuments}
                          handleDocumentClick={this.handleDocumentsSelect}
                          handleDocumentsLoadClick={this.handleDocumentsContentLoad}
                          portfolios={this.state.portfolios}
                          selectedPortfolio={this.state.selectedPortfolio}
                          onSelectedPortfolioChange={(newPortfolio) => this.setState({selectedPortfolio: newPortfolio})}
                          portfoliosLoading={this.state.customerData.loading}
                          disabled={this.state.selectedDocumentsContent.loading}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Paper square={true} classes={{ root: classes.collectionWrapper }}>
                    <TabPanel classes={{ root: classes.collectionNavRoot }} value="1">
                      <CollectionNavigation
                        activePage={this.getDocumentMeta(ACTIVE_PAGE)}
                        collections={this.state.collections}
                        activeCollection={this.state.activeCollection}
                        disabled={this.state.selectedDocumentsContent.loading || this.state.disableActions}
                        onDownloadCollectionButtonClick={this.handleDownloadDocumentButtonClick.bind(this)}
                        onSaveCollectionButtonClick={this.handleSaveCollectionButtonClick.bind(this)}
                        onCloseCollectionButtonClick={this.handleCloseCollectionButtonClick.bind(this)}
                        onSignCollectionButtonClick={this.handleSignCollectionButtonClick.bind(this)}
                      />
                    </TabPanel>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container style={{height: 700}}>
                <Grid item xs={12}>
                  <Paper square={true} classes={{ root: classes.editorWrapper }}>
                      <>
                        <TabPanel classes={{ root: classes.tabPanel }} value="1">
                          <DocumentEditorTabs
                            activeDocumentId={this.state.editor.documentId}
                            selectedDocuments={this.state.selectedDocuments.data}
                            downloadedDocuments={this.state.selectedDocumentsContent}
                            pageRestoring={this.state.disableActions}
                            handleTabChange={this.switchEditorTab.bind(this)}
                            handleTabClose={this.onEditorTabClose.bind(this)}
                          />
                          <Divider style={{ backgroundColor: '#D8DCDF' }} />
                          <DocumentNavigation
                            pageRestoring={this.state.disableActions}
                            activePage={this.getDocumentMeta(ACTIVE_PAGE)}
                            contentPageCount={this.getDocumentMeta(PAGE_COUNT)}
                            prevPage={this.onPrevPage.bind(this)}
                            nextPage={this.onNextPage.bind(this)}
                            switchToPage={this.switchToPage.bind(this)}
                            scale={this.getDocumentMeta(SCALE)}
                            scalePlus={this.onPageScaleUp.bind(this)}
                            scaleMinus={this.onPageScaleDown.bind(this)}
                          />
                          <div style={{ position: 'relative' }}>
                            {
                              !this.state.editorTabLoading &&
                              _.get(this.state.selectedDocumentsContent, 'data', []).length > 0
                                ? (
                                  <DocumentEditor
                                    saveDownloadAction={this.state.saveClick || this.state.downloadClick}
                                    scale={this.getDocumentMeta(SCALE)}
                                    onAnnotationsLoaded={this.handleAnnotationsLoaded}
                                    onRenderSuccess={this.handlePageRenderSuccess}
                                    activePage={this.getDocumentMeta(ACTIVE_PAGE)}
                                    activeDocument={this.state.activeDocument}
                                    activeDocuments={this.state.activeDocuments}
                                    documentsData={this.state.selectedDocumentsContent}
                                  />
                                )
                                : (
                                    this.state.editorTabLoading ||
                                    this.state.selectedDocumentsContent.loading ||
                                    this.state.selectedDocuments.loading
                                  ) ?
                                (
                                  <Grid container style={{ height: 700 }}>
                                    <Grid item className={classes.pageLoader}>
                                      <CircularProgress size={66} className={classes.loader} />
                                    </Grid>
                                  </Grid>
                                ) :
                                (
                                  <div className={classes.editorPlaceholder}>
                                    <p>Bitte wählen Sie ein Dokument aus</p>
                                  </div>
                                )
                            }
                          </div>
                        </TabPanel>
                      </>
                    {COLLECTIONS_FILTER_TABS.includes(this.state.activeTab) && <TabPanel value={this.state.activeTab}>
                      <Collections
                        collections={this.state.collections}
                        customerId={_.get(this.state.customerData.data, 'customer_id')}
                        onDetailsClick={this.handleCollectionItemClick}
                        onCollectionChange={this.handleCollectionChanges}
                        activeTab={this.state.activeTab}
                        activeCollection={this.state.activeCollection}
                      />
                    </TabPanel>}
                  </Paper>
                </Grid>
              </Grid>
            </TabContext>
          </>
        )}
        <Snackbar
          open={this.state.snackbar.open}
          variant={this.state.snackbar.variant}
          message={this.state.snackbar.message}
          handleClose={() => this.setState({ snackbar: { variant: 'error', message: null, open: false } })}
        />
        <ModalLoading
          text={'Bitte warten Sie, bis der digitale Signierungsprozess erstellt wurde'}
          sign={this.state.activeCollection.signing}
          open={this.state.saveClick || this.state.downloadClick || this.state.activeCollection.signing}
          value={this.state.getPageAnnotationsProgress}
          annotationloaded={this.state.annotationLoaded ? 1 : undefined}
          size={66}
        />
      </Container>
    )
  }

}));

