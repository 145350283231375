import React from "react";
import _ from "lodash";
import {PRODUCT_TYPE} from "../constants";
import {PortfolioHandlerResource} from "../../../utils/api";
import useFetchData from "../../../hooks/useDataFetch";

export default function useAssetsPeerGroupRankingData(products) {

  const [data, fetchData] = useFetchData(
    `${PortfolioHandlerResource.resourceUrl}customer/asset/extended-asset-data-by-isin/`, 'post',
    (response) => {
      return _.get(response, 'extended_asset_data.data');
    }, true)

  const [productsToUse, setProductsToUse] = React.useState([]);

  React.useEffect(() => {
    if (!_.isEmpty(products)) {
      setProductsToUse(products.filter((product) => product.type === PRODUCT_TYPE.ASSET && _.get(product, 'data.is_fund')));
    }
  }, [products])

  React.useEffect(() => {
    if (!_.isEmpty(productsToUse)) {
      fetchData({
        isins: productsToUse.map((product) => product.data.isin)
      });
    }
  }, [productsToUse]);

  return [data, productsToUse.length > 0];

}