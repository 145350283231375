const styles = theme => ({
  root: {
    minHeight: 150
  },
  container: {
    marginTop: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  chartContainer: {
    width: '100%',
    marginBottom: 15
  },
  helpIcon: {
    color: '#202a38',
    width: 18,
    height: 18,
    marginLeft: 3,
    transform: 'rotate(180deg)',
    verticalAlign: 'top',

    '&:hover': {
      cursor: 'pointer'
    }
  },
  legend: {
    style: 'none',
    float: 'right',
    listStyle: 'none',
    marginTop: 0
  },
  legendItem: {
    display: 'inline-block',
    marginRight: 30,
    '&:last-of-type': {
      marginRight: 0
    }
  },
  bullet: {
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 8
  },
  disabled: {
    opacity: 0.5
  },
  disabledIcon: {
    '&:hover': {
      cursor: 'default'
    }
  }
})

export default styles;