import { makeStyles } from "@material-ui/core";

const styles = (theme) => ({
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    color: '#202A38',
    margin: 0,
    padding: 0
  },
  loadingContainer: {
    width: '100%',
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableCell: {
    '&:nth-of-type(1)': {
      width: 50,
    },
    '&:nth-of-type(2)': {
      width: 100,
    },
    '&:nth-of-type(3)': {
      width: 'auto',
    },
    '&:nth-of-type(4)': {
      width: 'auto',
    },
    '&:nth-of-type(5)': {
      width: 100,
    },
    '&:nth-of-type(6)': {
      width: 140,
    },
  },
  container: {
    padding: '20px !important',
    background: 'white',
    borderRadius: 4,
    '& > div:first-child': {
      padding: '0px !important'
    }
  },
})

export default makeStyles(styles)