import NumberFormat from "react-number-format";
import React from "react";

export default (props) => {
  const {inputRef, onChange, value, ...other} = props;

  const handleValueChange = (values, sourceInfo) => {
    // In case event was triggered not by input - do nothing
    if (sourceInfo.source != 'event') {
      return
    }

    const value = values.floatValue
    if (value !== props.value) {
      onChange({
        target: {
          value
        }
      })
    }
  }

  return (
    <NumberFormat
      {...other}
      value={!!value ? value : ''}
      getInputRef={inputRef}
      onValueChange={handleValueChange}
      thousandSeparator='.'
      decimalSeparator=','
    />
  );
};