import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.919"
      height="38.919"
      viewBox="0 0 38.919 38.919"
    >
      <g id="Gruppe_8217" data-name="Gruppe 8217" transform="translate(0)">
        <path
          id="Pfad_1597"
          data-name="Pfad 1597"
          d="M1314.782,1279.837l-9.157-4.571a1.145,1.145,0,0,0-1.657,1.022v2.721c-8.123-4.054-7.627-3.864-8.013-3.864a1.146,1.146,0,0,0-1.14,1.043c-.007.082,0-.258,0,5.813h-.2l-2.419-12.781a1.143,1.143,0,0,0-1.125-.931h-3.129v-2.278h2.671a6.488,6.488,0,0,0,6.487-6.475v-3.047a1.909,1.909,0,0,0-1.908-1.9h-3.052a6.485,6.485,0,0,1-10.684,0H1278.4a1.909,1.909,0,0,0-1.908,1.9v3.047a6.488,6.488,0,0,0,6.487,6.475h2.671v2.278h-2.976a1.143,1.143,0,0,0-1.122.918L1278.992,1282h-1.351a1.144,1.144,0,0,0-1.145,1.142v9.218a1.144,1.144,0,0,0,1.145,1.142h36.63a1.144,1.144,0,0,0,1.145-1.142v-11.5A1.143,1.143,0,0,0,1314.782,1279.837Zm-22.642-22.966h2.671v2.666a4.2,4.2,0,0,1-4.2,4.19h-1.052l2.624-2.62a1.144,1.144,0,0,0-1.619-1.616l-2.625,2.62v-1.05A4.2,4.2,0,0,1,1292.14,1256.87Zm-13.354,2.666v-2.666h2.671a4.2,4.2,0,0,1,4.2,4.19v1.05l-2.624-2.62a1.144,1.144,0,0,0-1.619,1.616l2.624,2.62h-1.052A4.2,4.2,0,0,1,1278.785,1259.537Zm4.83,11.038h6.507l2.162,11.427h-10.959Zm-4.83,13.712h16.026v6.932h-16.026Zm18.315,6.932v-13.082l6.868,3.428v9.654Zm16.026,0h-6.868v-13.082l6.868,3.428Z"
          transform="translate(-1276.495 -1254.585)"
          fill="#fff"
        />
        <path
          id="Pfad_1598"
          data-name="Pfad 1598"
          d="M1388.969,1388.43a1.143,1.143,0,0,0-1.145,1.142v2.286a1.145,1.145,0,0,0,2.289,0v-2.286A1.143,1.143,0,0,0,1388.969,1388.43Z"
          transform="translate(-1364.93 -1361.014)"
          fill="#fff"
        />
        <path
          id="Pfad_1599"
          data-name="Pfad 1599"
          d="M1433.5,1393a1.143,1.143,0,0,0,1.144-1.143v-2.286a1.145,1.145,0,0,0-2.289,0v2.286A1.144,1.144,0,0,0,1433.5,1393Z"
          transform="translate(-1400.303 -1361.014)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
