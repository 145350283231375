import { makeStyles } from '@material-ui/core';

const getFontSize = (props) => props.fontSize || 28;

const getSize = (props) => {
  const size = getFontSize(props);
  return props.withBackground ? size + 4 : size;
};

export default makeStyles(theme => ({
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: getSize,
    height: getSize,
    backgroundColor: props => props.withBackground && '#CCE9FA',
    borderRadius: '50%',
  },
  iconWrapperEmpty: {
    backgroundColor: '#F5F6F7',
  },
  icon: {
    fontSize: getFontSize,
    color: '#0092E5'
  },
  iconEmpty: {
    color: '#80858C',
  }
}));
