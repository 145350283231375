import {buildGuideTourStep} from "../../../components/GuideTour/utils";
import {getFilterPanelChapterSteps} from "./filter_panel_chapter";
import {getRiskAnalyseChapterSteps} from "./riskanalyse_chapter";

const TOUR_STORAGE_KEY = 'riskDashboardGuideTour';
const FILTER_PANEL_CHAPTER_KEY = 'filterPanel';
const RISK_ANALYSE_CHAPTER_KEY = 'riskAnalyse';

export const getGuideSteps = () => {
  return [
    buildGuideTourStep(2, 'Übersicht Filterelemente',
      getFilterPanelChapterSteps(), TOUR_STORAGE_KEY, FILTER_PANEL_CHAPTER_KEY),
    buildGuideTourStep(3, 'Übersicht Risikoanalyse',
      getRiskAnalyseChapterSteps(), TOUR_STORAGE_KEY, RISK_ANALYSE_CHAPTER_KEY)
  ];
};