import React from 'react';
import { connect } from 'react-redux'
import _ from 'lodash';
import useStyles from '../../Modelportfolios/List/styles';
import useFetchData from "../../../hooks/useDataFetch";
import {InvestmentStrategiesResource} from "../../../utils/api";
import {getErrorMessage, paginateArray} from "../../../utils/utils";
import {Container} from "@material-ui/core";
import DashboardTable from "../../../components/DashboardTable/DashboardTable";
import tableStructure from "../List/table-structure";
import {ALL_STRATEGIES_TAB, INVESTMENT_STRATEGY_TABS, INVESTMENT_STRATEGY_TYPES} from "../constants";
import {executeIfPathExist, getInvestmentDynamicPath} from "../../InvestmentPlatform/utils";
import withNotification from "../../../components/NotificationProvider";
import TabsFilter from "./components/TabsFilter";
import DynamicFilters from "./components/DynamicFilters";
import {
  investmentGoal, investmentHorizon,
  mainFocusFilterFields, srriFilterFields
} from "./components/DynamicFilters/constants";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  AddProductsToComparison, SelectInvestmentStrategyForProductsComparisonModal,
  SelectMusterdepotForProductsComparisonModal
} from "../../../components/SelectForProductsComparisonModal/SelectForProductsComparisonModal";

// list of all filters' structures
const investmentStrategyFilters = [srriFilterFields, mainFocusFilterFields, investmentGoal, investmentHorizon];

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
});

const DEFAULT_FILTERS = {name: ''};

function List(props) {

  const classes = useStyles({actionTableCellWidth: props.viewOnly ? 165 : 200, nameCellWidth: 200});

  // fetchInvestmentStrategies - sends request; setInvestmentStrategies - updates state of investmentStrategies.data without sending request
  const [investmentStrategies, fetchInvestmentStrategies, setInvestmentStrategies] = useFetchData(
    InvestmentStrategiesResource.resourceUrl, 'get');

  // state to get proper list of strategies when one of them is added/removed from 'Meine Strategien'(my strategies)
  const [currentFilterSettings, setCurrentFilterSettings] = React.useState({...DEFAULT_FILTERS});

  const [extendComparisonWithId, setExtendComparisonWithId] = React.useState();

  const updateCurrentFilter = (fieldName, selectedOptions) => {
    // selectedOptions is array of objects like {'name': value} => we map over them

    // check if it options not updated
    setCurrentFilterSettings( (prevState) => {
      return {...prevState, [fieldName]: _.isArray(selectedOptions) ? selectedOptions.map(o => o.name) : selectedOptions}
    })
  };

  const [filtersStructureList, setFiltersStructureList] = React.useState([]);

  const initializeFilter = (investmentStrategies) => {
    /** Function to initialize Filters inside the tabs */

    function getPreparedFilter (filterFields) {
      /** Returns filterFields with proper option and onSelectedOptionsChange */

      // copy filter fields
      let preparedFilter = {...filterFields};
      // get sorted options uniq by field which are not nil
      let options = _.uniq(_.map(investmentStrategies, filterFields.field)).filter(option => !_.isNil(option)).sort();
      // set proper format for options
      preparedFilter.options = options.map(option => ({name: _.isString(option) ? option : option.toString() }));
      // set onSelectedOptionsChange to update currentFilterSettings with new Value
      preparedFilter.onSelectedOptionsChange = (newValue) => {updateCurrentFilter(filterFields.field, newValue)};
      // make dropdown options be wrapped in checkbox
      preparedFilter.renderOption = (option, selected) => {
        return (
          <>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon htmlColor="#ADB6BD" />}
              checkedIcon={<CheckBoxIcon htmlColor="#0092E5" />}
              checked={selected}
              disableRipple
              disableTouchRipple
            />
            <div>
              <span>{option.name}</span>
            </div>
          </>
        )
      };
      // override tags not to display them
      preparedFilter.renderTags = (tagValue, getTagProps) => {};

      return preparedFilter
    }

    setFiltersStructureList(investmentStrategyFilters.map(getPreparedFilter))
  };

  //
  const [keepCurrentPageOnDataSourceChange, setKeepCurrentPageOnDataSourceChange] = React.useState(false);

  const isFavoriteSwitchEnabled = (item) => {
    // Returns true if strategy type is public. Is used to display switch (that adds strategy to favorite) only for public strategies

    return item.is_global
  };

  /**
   * Paginated list of Investment Strategies.
   * @type {Object[][]}
   */
  const investmentStrategiesPrepared = React.useMemo(() => {

    if (investmentStrategies.loading) {
      return new Array(5).fill({})
    }

    if(investmentStrategies.errors){
      props.displayNotification('error', getErrorMessage(investmentStrategies.errors));
    }

    if (investmentStrategies.errors || !investmentStrategies.data) {
      return [];
    }

    // Covers case when in 'my strategies tab' broker deselects strategy that used to be favorite
    let strategiesToAccept = _.get(currentFilterSettings, 'type', []).includes(INVESTMENT_STRATEGY_TYPES.PERSONAL)
      ? investmentStrategies.data.filter(strat => strat.is_favorite || !isFavoriteSwitchEnabled(strat))
      : investmentStrategies.data;

    // on first render filtersStructureList is empty -> initialize it with available options
    if(_.isEmpty(filtersStructureList)){ initializeFilter(investmentStrategies.data) }

    let strategiesToExclude = [];
    if (props.strategiesHandler) {
      [strategiesToAccept, strategiesToExclude] = props.strategiesHandler.filterInstruments(strategiesToAccept)
    }

    return [...paginateArray([...strategiesToAccept, ...strategiesToExclude], 15)]

  }, [investmentStrategies.updatedAt]);

  /**
   * Open Investment strategy details screen.
   * @param investmentStrategyId Investment Strategy Identifier
   */
  const handleDetailsClick = React.useCallback((investmentStrategyId, skipPreview) => {

    if(props.handleEditClick){
      return props.handleEditClick(investmentStrategyId, skipPreview);
    }

    executeIfPathExist(props.investmentPlatform.routes, props.detailsComponentId || 'INVESTMENT_STRATEGIES_OVERVIEW', path => {
      let pathUpdated = path.replace(':id', investmentStrategyId);

      props.history.push(`/${getInvestmentDynamicPath()}${pathUpdated}`)
    })
  }, [props.investmentPlatform.routes, props.history]);

  const addToMyStrategies = (updatedInvestmentStrategy) => {
    /** Function to add/remove strategy from my strategies 'Meine Strategien' */

    // if active was added/removed from 'my strategies' - do not reset pagination to first page
    setKeepCurrentPageOnDataSourceChange(true);

    let updatedInvestmentStrategies = [...investmentStrategies.data];
    // getting single strategy from list of all strategies that must be updated
    let _strategyToUpdate = _.find(updatedInvestmentStrategies, strategy => strategy.id === updatedInvestmentStrategy.id);
    _strategyToUpdate.loading = true;
    setInvestmentStrategies(updatedInvestmentStrategies);

    // send request to add/remove strategy from favorite
    InvestmentStrategiesResource.at(`update-investment-strategy/`).patch(updatedInvestmentStrategy)
      .then(response => {
        let msgToShow = updatedInvestmentStrategy.is_favorite
          ? 'Die Strategie wurde zu Ihren Strategien hinzugefügt.'
          : 'Die Strategie wurde von Ihren Strategien entfernt.';

        props.displayNotification('success', msgToShow);
        _strategyToUpdate.is_favorite = updatedInvestmentStrategy.is_favorite // setting flag to add strategy to favorite

      })
      .catch(error => {
        props.displayNotification('error', getErrorMessage(error));
      })
      .finally(() => {
        _strategyToUpdate.loading = false;  // regardless of request result stop strategy loading
        setInvestmentStrategies(updatedInvestmentStrategies)
      });
  };

  const [selectedTab, setSelectedTab] = React.useState(ALL_STRATEGIES_TAB);

  /**
    * Trigger investment strategies data fetching after filter criteria changed.
    * @param investmentStrategyTypes Investment strategy types identifiers
  */
  React.useEffect(() => {
    let params = selectedTab !== ALL_STRATEGIES_TAB ? {...currentFilterSettings, type: selectedTab} : {...currentFilterSettings,  type: undefined};
    let newFilterSettings = {
      ...params,
      ...(props.extraParams || {})
    };
    setCurrentFilterSettings(newFilterSettings);
    setKeepCurrentPageOnDataSourceChange(false); // if tab changed -> reset page to 0
    fetchInvestmentStrategies(newFilterSettings)
  },[selectedTab]);

  const onSelectedTabChange = (event, index) => {
    setSelectedTab(index)
  };

  const applyFilterClick = () => {
    setKeepCurrentPageOnDataSourceChange(false); // if type of active or other filter is changed -> reset page to 0
    fetchInvestmentStrategies({...currentFilterSettings})
  };

  const handleExtendClick = (musterdepot) => {
    setExtendComparisonWithId(musterdepot.id);
  }

  const handleExtendClose = () => {
    setExtendComparisonWithId(undefined);
  }

  const renderFilters = () => {

    if(_.isEmpty(filtersStructureList)) { return (<>Loading</>) }

    return (
      <DynamicFilters
        customClasses={classes}
        filtersStructureList={filtersStructureList}
        searchString={currentFilterSettings.name}
        currentFilterSettings={currentFilterSettings}
        onSearchFieldChange={(newValue) => updateCurrentFilter('name', newValue)}
        handleApplyFilterBtnClick={applyFilterClick}
        disabled={investmentStrategies.loading}
      />
    )
  };

  return (
    <div className={classes.container} style={{overflowX: 'hidden'}}>
      <Container>
        <div className={classes.navigationContainer} style={{marginBottom: 20}}>
          <h1 className={classes.header}>Vermögensverwaltung</h1>
        </div>

        <TabsFilter
          selectedTab={selectedTab}
          onSelectedTabChange={onSelectedTabChange}
          tabs={INVESTMENT_STRATEGY_TABS}
          loading={_.isEmpty(filtersStructureList) && investmentStrategies.loading} // filtersStructureList empty on first render -> loading only for first render
          renderFilters={renderFilters}
          disabled={investmentStrategies.loading} // disable tabs and its content while they are loading
        />

        <DashboardTable
          tableLayout={'auto'}
          structure={tableStructure}
          dataSource={investmentStrategiesPrepared}
          expanded={true}
          tableClasses={classes}
          withFooter={false}
          paginationOptions={{
            paginationEnabled: true,
            pageSize: 15,
            keepCurrentPageOnDataSourceChange: keepCurrentPageOnDataSourceChange,
          }}
          options={{
            loading: investmentStrategies.loading,
            onDetailsClick: handleDetailsClick,
            onExtend: handleExtendClick,
            viewOnly: props.viewOnly,
            onAddToMyStrategies: addToMyStrategies,
            isFavoriteSwitchEnabled: isFavoriteSwitchEnabled,
            directAddingPossible: props.directAddingPossible,
            actionsNames: props.actionsNames
          }}
        />
      </Container>
      {extendComparisonWithId && (
        <AddProductsToComparison>
          <SelectInvestmentStrategyForProductsComparisonModal
            investmentStrategyId={extendComparisonWithId}
            onClose={handleExtendClose}
          />
        </AddProductsToComparison>
      )}
    </div>
  )
}

List.defaultProps = {
  directAddingPossible: true
}

export default connect(mapStateToProps)(withNotification(List));