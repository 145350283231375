import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import CommonAnalysisCard from "../../../../components/AnalysisCard";
import {toGermanFormat} from "../../../../../../utils/numberFormater";
import moment from "moment";

import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import {getComparator} from "../../index";
import {stableSort} from "../../index";
import {getErrorMessage, openMorningStartIsin} from "../../../../../../utils/utils";
import Tooltip from "../../../../../../components/Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import clsx from "clsx";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../../CustomerDashboard/constants";
import WarningTooltip from "../../../../../../components/WarningTooltip";
import _ from "lodash";


class SinglePerformanceTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            order: this.props.order,
            orderBy: this.props.orderBy
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data && this.props.data !== prevProps.data) {
            this.renderTable()
        }
    }

    formatPercentage(number, multiplier) {
        multiplier = multiplier || 1;
        return number || number === 0 ? toGermanFormat(number * multiplier, '', '', 2) + '%' : '-'
    }

    formatDate(date, placeholder) {
        placeholder = placeholder === undefined ? '-' : placeholder
        return date ? moment(date).format('DD.MM.YYYY') : placeholder
    }

    renderTableRows() {
        const { classes } = this.props;

        const rows = [];

        let data = this.props.data || [];

        if (data && data.length > 0) {

            stableSort(data, getComparator(this.state.order, this.state.orderBy)).forEach((asset, index) => {

              let link = undefined;

              if (asset.hasOwnProperty('link')) {
                link = asset['link']
              }

                rows.push(
                    <tr key={asset.isin + '_' + index}>

                      <td className={classes.leftAligned}>

                        {link ?
                          <a href={link} target={'_blank'} className={classes.assetName}>
                            {asset.name}
                          </a>
                          :
                          <a onClick={() => {openMorningStartIsin(asset.isin, null)}} className={classes.assetName}>
                            {asset.name}
                          </a>
                        }
                        <br />
                        {asset.isin || '-'} / {asset.wkn || '-'}
                      </td>

                        <td>
                            {this.formatPercentage(asset.weight, 100)}
                        </td>
                        <td>
                            {asset.fund_star_rating_overall ? <Rating
                              name="fund_star_rating_overall"
                              classes={{iconFilled: classes.ratingFilled}}
                              defaultValue={asset.fund_star_rating_overall}
                              emptyIcon={<StarBorderIcon fontSize="inherit" htmlColor={this.props.theme.palette.primary.main}/>}
                              size="small"
                              readOnly
                            /> : '-'}
                        </td>
                        <td>
                            {this.formatPercentage(asset.return_3m)}
                        </td>
                        <td>
                            {this.formatPercentage(asset.return_6m)}
                        </td>
                        <td>
                            {this.formatPercentage(asset.return_ytd)}
                        </td>
                        <td>
                            {this.formatPercentage(asset.return_1y)}
                        </td>
                        <td>
                            {this.formatPercentage(asset.return_3y)}
                        </td>
                        <td>
                            {this.formatPercentage(asset.return_5y)}
                        </td>
                        <td>
                            {this.formatPercentage(asset.return_inception)}
                            <div className="sub">{this.formatDate(asset.inception_date, '')}</div>
                        </td>
                    </tr>
                )
            })
        }
        return rows;
    }

    onRequestSort(event, property) {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            orderBy: property,
            order: isAsc ? 'desc' : 'asc'
        })
    }

    renderTableHeader() {
        const { classes } = this.props;

        const createSortHandler = (property) => (event) => {
            this.onRequestSort(event, property);
        };

        const headlines = [
            { id: 'name', label: 'Instrumentenname', sublabel: 'ISIN/WKN' },
            { id: 'weight', label: 'Anteile'},
            { id: 'fund_star_rating_overall', label: 'Morningstar Rating'},
            { id: 'return_3m', label: 'Rendite 3 Monate'},
            { id: 'return_6m', label: 'Rendite 6 Monate'},
            { id: 'return_ytd', label: 'Rendite lfd. Jahr'},
            { id: 'return_1y', label: 'Rendite 1J'},
            { id: 'return_3y', label: 'Rendite 3J'},
            { id: 'return_5y', label: 'Rendite 5J'},
            { id: 'return_inception', label: 'Rendite seit Auflage'},
        ];

        return <tr>
            {headlines.map((headline) => (
                <TableCell
                    key={headline.id}
                    align={headline.id === 'name' ? 'left' : 'right'}
                    sortDirection={this.state.orderBy === headline.id ? this.state.order : false}
                >
                    <TableSortLabel
                      active={this.state.orderBy === headline.id}
                      direction={this.state.orderBy === headline.id ? this.state.order : 'asc'}
                      onClick={createSortHandler(headline.id)}
                    >
                        <div>
                            <div>{headline.label}</div>
                            {headline.sublabel && <div className="sub">{headline.sublabel}</div>}
                        </div>
                    </TableSortLabel>
                </TableCell>
            ))}
        </tr>
    }

    renderTable() {
        const { classes, order, orderBy } = this.props;

        return <table className={classes.tableAll} aria-label="simple table">
            <thead className={classes.tableHeader}>
                {this.renderTableHeader()}
            </thead>
            <tbody>
                {this.renderTableRows()}
            </tbody>
          </table>;
      }

    renderErrorMessage(error) {
      // todo find other usages and make a separate error message component for them
      const { classes } = this.props;
      return <p className={classes.emptyMessage}>{getErrorMessage(error)}</p>
    }

    render() {

      const { classes } = this.props;

      return <CommonAnalysisCard
        classes={{
          card: classes.root,
          contentWrapper: classes.contentWrapper
        }}
        title={
          <span>
            {'Einzelperformance der Depotpositionen'}
            <WarningTooltip
              className={classes.benchmarkIcon}
              placement={'left'}
              title={
                this.props.infoDescription
              }
              iconClassName={classes.helpIcon}
            />
          </span>
        }
        loading={this.props.loading}
        content={
          <div className={clsx(classes.container, this.props.error ? classes.containerError : this.props.data ? '' : classes.containerError)}>
            {this.props.error
              ? this.renderErrorMessage(this.props.error)
              : !_.isEmpty(this.props.data)
                ? this.renderTable()
                : this.renderErrorMessage(DEFAULT_EMPTY_SECTION_MESSAGE)}
          </div>
        }
        expanded={this.props.expanded}
        onExpandedClick={this.props.onExpanded}
      />
    }
}

SinglePerformanceTable.defaultProps = {
  infoDescription: 'Die folgende Tabelle betrachtet die Instrumentenperformance der einzelnen Titel unabhängig von der Haltedauer im virtuellen Portfolio.'
}

export default withStyles(styles, { withTheme: true })(SinglePerformanceTable)