import { makeStyles } from '@material-ui/core';
import {smFontSize, xsFontSize, fontSize} from "../../../../../utils/constants";

export const styles = theme => ({
  container:{
    padding: '0 !important'
  },

  paperRoot: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    boxShadow: '0px 3px 15px #39435233',
    overflow: 'hidden',
  },

  accordionRoot: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },

  accordionDetailsRoot: {
    padding: '0 !important'
  },

  accButtonIconEdgeEnd: {
    marginRight: '0 !important'
  },

  accordionSummaryRoot: {
    backgroundColor: 'white',
    minHeight: 48,
    padding: '0 0 0 0 !important',

    [theme.breakpoints.only("xs")]: {
      '& .MuiTabs-scrollButtonsDesktop:not(.Mui-disabled)': {
        display: 'inline-flex !important',
        width: 30
      }
    }
  },
  accordionSummaryContent: {
    margin: 0,

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 48px)',  // 48px is for collapse btn
    }
  },
  accordionSummaryExpanded: {
    minHeight: '48px !important',
    margin: '0 0 0 0 !important',
  },

  tab: {
    paddingLeft: '30px !important',
    paddingRight: '30px !important',
    fontSize: `${fontSize} !important`,
    color: '#202A38 !important',
    borderRight: '1px solid #D8DCDF !important',
    textTransform: 'none !important',
    background: 'transparent linear-gradient(180deg, #FFFFFF 0%, #F5F6F7 100%) 0% 0% no-repeat !important',
    opacity: 1,

    [theme.breakpoints.only("sm")]: {
      fontSize: `${smFontSize} !important`,
      paddingLeft: '20px !important',
      paddingRight: '20px !important',
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: `${xsFontSize} !important`,
      paddingLeft: '10px !important',
      paddingRight: '10px !important',
    }
  },
  tabSelected: {
    textShadow: '.5px 0 #202A38 !important',
    background: '#ffffff !important',
  },
  activeTabIndicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent !important",
    bottom: '7px !important',
    "& > div": {
      maxWidth: 30,
      width: "100%",
      backgroundColor: "#0092E5"
    }
  },
  preloaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: (props) => props.preloaderHeight || 280,
  },
  loadingIndicator: {
    color: theme.palette.primary.main
  },
});

export default makeStyles(styles);