import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid'

import useStyles from './styles'
import {useTransactionsContext} from "../../Transactions";
import DashboardTable from "../../../../../../components/DashboardTable/DashboardTable";
import tableStructure from './table-data'
import {useTransactionsMonitoringContext} from "../../../../TransactionsMonitoring";
import {PAGE_SIZES} from "../../../../../../utils/constants"
import _ from "lodash";

List.propTypes = {

};

function List(props) {

  const { transactions } = useTransactionsContext()
  const { onOrderingChange, initOrdering } = useTransactionsMonitoringContext()
  
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <DashboardTable
        structure={props.tableStructure || tableStructure}
        dataSource={transactions || []}
        expanded={true}
        options={props.tableOptions || {}}
        tableClasses={props.tableClasses || classes}
        withFooter={false}
        onOrderingChange={onOrderingChange}
        paginationOptions={{
          paginationEnabled: true,
          pageSizeSelectEnabled: true,
          pageSize: PAGE_SIZES['10']
        }}
        initOrdering={initOrdering}
        tableLayout="auto"
        useInternalCollapse={props.isGrouped}
        internalCollapseColumnIndex={1}
      />
    </Grid>
  );
}

export default List;