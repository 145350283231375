import React from 'react'
import _ from "lodash"

import {Skeleton} from "@material-ui/lab";
import InputFormElement, {
  CurrencyNumberFormat,
} from "../../../../../../formElement/InputFormElement";
import Link from "../../../../../../../../../../../components/Link";
import {PercentageNumberFormat} from "../../../../../../formElement/InputFormElement/InputFormElement";
import {makeStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {
  InstrumentNameCell, PriceColumn,
  SRRIColumn
} from "../../../../../../../../../../Modelportfolios/components/InstrumentsList/table-data";
import {acceptanceCriteriasExists, getInstrName} from "../../../../../../../../../../Modelportfolios/utils";
import {InfoIcon} from "../../../../../../../../../../../images";
import {FilteringAcceptanceCriteriasModal} from "../../../../../../../../../../Modelportfolios/components";
import { instrCustodianData } from '../../../../../../../../../../Trades/utils';
import {useRiskScoreConfirmationModalContext} from "../../InvestmentRecommendationStep";
import {withEuroOrDash} from "../../../../../../../../../../../utils/utils";
import WarningTooltip from "../../../../../../../../../../../components/WarningTooltip";
import {SustainabilityCell} from "../../../../../../../../../../../utils/commonTableColumns";

const useCellStyles = makeStyles(() => ({
  inputRoot: {
    margin: 0,
    height: 30,
    fontSize: 14
  },
  actionLink: {
    margin: '0 0.6rem',
    '&:first-child': {
      marginLeft: 0
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  tooltip: {
    position: 'relative',
    top: -10
  },
  disabledLabel: {
    border: '1px solid #80858C',
    color: '#80858C',
    borderRadius: 2,
    fontFamily: 'Roboto-Regular',
    fontSize: 10,
    textTransform: 'uppercase',
    padding: '4px 6px'
  }
}));

const StateCol = {
  header: [() => ''],
  body: [({item, options}) => {
    if(options.readOnly){
      return null;
    }

    // We do not validate instruments for open only, so we can skip disabledByFilter validation
    if (options.isOpenOnly) {

      if (item.infoText) {
        return (<WarningTooltip title={item.infoText} icon={<i><InfoIcon color="#80858c" /></i>}/>)
      }

      return null
    }

    const classes = useCellStyles();

    const [acceptanceVisible, setAcceptanceVisibility] = React.useState(false);

    const handleShowAcceptanceCriteriasClick = () => {
      setAcceptanceVisibility(true)
    };

    const handleCloseAcceptanceCriterias = () => {
      setAcceptanceVisibility(false)
    };

    if (!item.disabledByFilter) return (
      <>
        {!item.dirty && <span className={classes.disabledLabel}>NEU</span>}
      </>
    );

    if (acceptanceCriteriasExists(item)) return (
      <>
        <InfoIcon color="#80858c" onClick={handleShowAcceptanceCriteriasClick}/>
        <FilteringAcceptanceCriteriasModal
          open={acceptanceVisible}
          onClose={handleCloseAcceptanceCriterias}
          instrumentName={getInstrName(item) || item.isin}
          criterias={item.disabledByAcceptanceCriterias} />
      </>
    );
  }]
}

// NOTE: Component should return null if nothing to render
const NameCol = {
  header: [({item, options}) => InstrumentNameCell.header.content(item, options) || null],
  body: [({item, options}) => InstrumentNameCell.body.content(item, options) || null],
  footer: [({options}) => {
    return <b>
      {options.error && (<span style={{color: 'red'}}>{options.error}</span>)}
    </b>
  }],
  colSpan: {footer: 6},
}

const SRRICol = {
  header: [() => SRRIColumn.header || null],
  body: [({item, options}) => SRRIColumn.body.content(item, options) || null],
  align: 'right'
}

const PriceCol = {
  header: [({item, options}) => PriceColumn.header.content(item, options) || null],
  body: [({item, options}) => PriceColumn.body.content(item, options) || null],
  align: 'right'
}

const WeightColumn = {
  header: [() => <span>Gewichtung<br/>Einmalanlage</span>],
  body: [({item, options}) => {
    const classes = useCellStyles();

    const weightError = item.error && item.error.weight || '';

    // in case value has more then 2 digits - input will rescale and run onChange with infinite loop (amount <-> weight)
    // to prevent this convert value to 2 digits
    let value = +item.weight;
    value = _.isNaN(value) ? undefined : +value.toFixed(2);

    return (
      <>
        <InputFormElement
          value={value}
          onChange={value => options.handleWeightChange && options.handleWeightChange(item, value)}
          inputComponent={PercentageNumberFormat}
          inputProps={{inputProps: {allowNegative: false, fixedDecimalScale: true}}}
          type={'text'}
          custom_classes={classes}
          disabled={true}
        />
        <Tooltip open={Boolean(weightError)} title={weightError} arrow={true}>
          <div className={classes.tooltip}/>
        </Tooltip>
      </>
    )
  }],
  align: 'right'
}

const ActionsColumn = {
  header: [() => ''],
  body: [({item, options}) => {
    if(options.readOnly && !options.clientSRRI){
      return null;
    }

    const classes = useCellStyles();

    const displayTextFieldButton = !options.isOpenOnly && options.clientSRRI && item.sri && item.sri > options.clientSRRI

    const { onSelectAsset } = useRiskScoreConfirmationModalContext()

    return (
      <>
        {displayTextFieldButton && (
          <span className={classes.actionLink} style={{margin: 0}}>
            <Link style={!!_.get(item, 'error.risk_score_explanation') ? {color: "red"} : {}} title="Begründung" fontWeight="normal"
                  onClick={() => onSelectAsset && onSelectAsset(item)}/>
          </span>
        )}
        {!options.readOnly && !options.isOpenOnly && (
          <span className={classes.actionLink} style={{margin: 0}}>
            <Link title="Löschen" fontWeight="normal"
                  onClick={() => options.onRemoveAssetClick && options.onRemoveAssetClick(item)}/>
          </span>
        )}
      </>
    )
  }],
  footer: [() => {
    return <>
      <b style={{color: 'black'}}>Verteilt</b><br/>
      <b style={{color: 'black'}}>Saldo</b>
    </>
  }]
};

const AmountColumn = {
  header: [() => (
    <>
      <span>Einmalanlage</span><br />
      <span>Betrag</span>
    </>
  )],
  body: [({item, options}) => {
    const classes = useCellStyles();

    const error = item.error && item.error.amount || '';

    // in case value has more then 2 digits - input will rescale and run onChange with infinite loop (amount <-> weight)
    // to prevent this convert value to 2 digits
    let value = +item.amount;
    value = _.isNaN(value) ? undefined : +value.toFixed(2);

    const singlePaymentImpossible = !instrCustodianData(item, options.bankCompanyId).c_single_payment_possible;
    const inputProps = {inputProps: {allowNegative: false, fixedDecimalScale: true}};

    if(singlePaymentImpossible) {
      inputProps.startAdornment = (
        <Tooltip arrow placement={"top"} title={"Einmalanlage nicht verfügbar"}>
          <i className='far fa-info-circle' />
        </Tooltip>
      )
    }

    return (
      <>
        <InputFormElement
          disabled={options.readOnly || singlePaymentImpossible}
          value={value}
          onChange={value => options.handleAmountChange && options.handleAmountChange(item, value)}
          inputComponent={CurrencyNumberFormat}
          inputProps={inputProps}
          type={'text'}
          custom_classes={classes}
        />
        <Tooltip open={Boolean(error)} title={error} arrow={true}>
          <div className={classes.tooltip}/>
        </Tooltip>
      </>
    )
  }],
  footer: [({options}) => {
    const {singleBuyAmount, acceptedAmount} = options;

    return <div style={{color: acceptedAmount === singleBuyAmount ? '#23D374' : 'red', paddingRight: 6, whiteSpace: 'nowrap'}}>
      {withEuroOrDash(acceptedAmount)}<br/>
      {withEuroOrDash(acceptedAmount - singleBuyAmount)}
    </div>
  }],
  key: 'AmountColumn',
  align: 'right'
}

const SavingsPlanColumn = {
  header: [() => <span>Sparplan<br/>Betrag</span>],
  body: [({item, options}) => {
    if (options.loading) {
      return <Skeleton />
    }
    const classes = useCellStyles();

    const error = item.error && item.error.savingsPlanAmount || '';

    // in case value has more then 2 digits - input will rescale and run onChange with infinite loop (amount <-> weight)
    // to prevent this convert value to 2 digits
    let value = +item.savingsPlanAmount;
    value = _.isNaN(value) ? undefined : +value.toFixed(2);

    const savingsPlanImpossible = !instrCustodianData(item, options.bankCompanyId).c_savings_plan_possible;

    const inputProps = {inputProps: {allowNegative: false, fixedDecimalScale: true}};

    if(savingsPlanImpossible) {
      inputProps.startAdornment = (
        <Tooltip arrow placement={"top"} title={"Sparplan nicht verfügbar"}>
          <i className='far fa-info-circle' />
        </Tooltip>
      )
    }

    return (
      <>
        <InputFormElement
          value={value}
          disabled={options.readOnly || savingsPlanImpossible}
          onChange={value => options.handleSavingsPlanChange && options.handleSavingsPlanChange(item, value)}
          inputComponent={CurrencyNumberFormat}
          inputProps={inputProps}
          type={'text'}
          custom_classes={classes}
        />
        <Tooltip open={Boolean(error)} title={error} arrow={true}>
          <div className={classes.tooltip}/>
        </Tooltip>
      </>
    )
  }],
  footer: [({options}) => {
    const {savingsPlanAmount, savingsPlan} = options;

    return <div style={{color: savingsPlanAmount === savingsPlan ? '#23D374' : 'red', paddingRight: 6, whiteSpace: 'nowrap'}}>
      {withEuroOrDash(savingsPlanAmount)}<br/>
      {withEuroOrDash(savingsPlanAmount - savingsPlan)}
    </div>
  }],
  key: 'SavingsPlanColumn',
  align: 'right'
}

const SustainabilityCol = {
  header: [SustainabilityCell.header.content],
  body: [({item, options}) => SustainabilityCell.body.content(item, options)]
}

export const tableStructureWithSavingsPlan = [
  StateCol,
  NameCol,
  SustainabilityCol,
  SRRICol,
  PriceCol,
  WeightColumn,
  AmountColumn,
  SavingsPlanColumn,
  ActionsColumn
];
