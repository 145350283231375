import {
    getBrokerClientTypes, OTHER_AGENCIES_CLIENT_TYPE_DROPDOWN_OPTION,
    processBrokerClientTypes,
    processBrokerSubAgencies
} from "../FilteringPanel/components/ListSelector/constants";
import {CUSTOMER_TYPES} from '../../containers/RiskProfiling/constants';

export const getAgencyFilterOptions = (isChief, sub_agencies_list, agency, other_agencies_list=undefined, client_types_options) => {
    let result = processBrokerClientTypes(getBrokerClientTypes(isChief, client_types_options), agency, sub_agencies_list);

    if (other_agencies_list) {
        let other_agencies = processBrokerSubAgencies(other_agencies_list);
        result = [...result, OTHER_AGENCIES_CLIENT_TYPE_DROPDOWN_OPTION, ...other_agencies]
    }

    return result
}

export const CUSTOMER_ICON = {
    [CUSTOMER_TYPES.COMPANY]: "industry",
    [CUSTOMER_TYPES.COUPLE]: "user-friends" 
}
