import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import styles from './styles';
import IconButton from "@material-ui/core/IconButton/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

import {DropdownFilter, SearchFilter} from "../../../../components/Filters";

const FilterPanel = (props) => {
  const {
    classes,
    page,
    perPage,
    total,
    documentType,
    documentTypes,
    onDocumentTypeChange,
    onSearchClick,
    onRefresh,
    loading
  } = props;

  const[searchString, setSearchString] = React.useState('')

  const getFrom = () => {
    return page * perPage + 1;
  };

  const getTo = () => {
    const lastPossible = page * perPage + perPage;
    return total >= lastPossible ? lastPossible : total
  };

  const getTitle = () => {
    if (total) {
      return `DOKUMENTE (${getFrom()}-${getTo()} VON ${total})`
    }
    return 'DOKUMENTE (0)'
  };

  return (
    <div className={classes.container}>
      <div className={classes.infoPanel}>
        {getTitle()}
      </div>

      <div>
        <IconButton onClick={onRefresh}>
          {!loading && <RefreshIcon className={classes.refreshButtonIcon}/>}
          {loading && <CircularProgress size={24} className={classes.loading}/>}
        </IconButton>
      </div>

      <div className={classes.documentTypeFilter} id="documents-filter-type-tour-element">
        <DropdownFilter
          selected={documentType}
          options={documentTypes}
          handleSelectedChanged={onDocumentTypeChange}
          disabled={loading}
        />
      </div>

      <div className={classes.documentTypeFilter}>
        <SearchFilter
          title={'Suchbegriff'}
          value={searchString}
          handleValueChanged={(newValue) => setSearchString(newValue)}
          handleSearchClick={() => {onSearchClick(searchString)}}
          handleClearClick={() => {setSearchString(''); onSearchClick('')}}
          disabled={loading}
          withoutSearchHelperText
        />
      </div>
    </div>
  )
};

export default withStyles(styles)(FilterPanel);