import React from 'react';
import {Prompt} from 'react-router';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";

import { withStyles } from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";

import { GroupSettingsItem } from '../../components/Group';
import withCustomersData from '../../components/CustomersDataProvider';
import EditGroupNameModal from './components/EditGroupNameModal';
import Footer from './components/Footer';
import withNotification from '../../components/NotificationProvider';
import PromptDialog from '../../components/PromptDialog';
import {customerWithoutGroupMessage} from "../ReportGroupCreation/constants";
import SuccessDialog from '../../components/SuccessDialog';
import { addCustomersToStore, addCustomersWithoutGroupToStore } from '../../components/CustomersDataProvider/actions';
import {PrimaryButton} from "../../components/Buttons";

import {styles} from './styles';
import { GroupResource, CustomerReportSettingResource } from '../../utils/api';
import { newDesignUsed } from '../../utils/utils';
import { executeIfPathExist, getInvestmentDynamicPath } from '../../containers/InvestmentPlatform/utils'
import {
  setGroupsData,
  setGroupsLoading,
  setGroupsLoadingErrors
} from "./actions";
import {ROUTES} from "../../routes";
import DashboardGroupSettings from './components/DasboardGroupSetting';
import {DASHBOARD_GROUP_TYPE_MENU_ITEM_MAPPING, DASHBOARD_SETTINGS_TYPE, DASHBOARD_TYPE} from "./constants";
import {isBenchmarksSettingsEqual} from './utils';

import CustomerFilteringPanel from "../../components/CustomersSelectorProviderNew/components/CustomerFilteringPanel";
import {getSearchCustomerType} from "../../components/FilteringPanel/components/ListSelector/constants";
import {SORTING_TYPES} from "../../components/FilteringPanel/components/SortingFilter/constants";
import {
  UserUtils,
  hasFilterResult
} from '../../utils/utils';
import {filterGroups, sortGroups} from './utils';
import FilterHeader from './components/FilterHeader';
import { REPORTS_AREAS } from './components/CustomersList/components/ReportsAreasVisibility/constants';


class GroupSettings extends React.Component {
  constructor(props) {
    // clean data
    props.setGroupsData([]);

    super(props);
    this.state = {
      updatedGroups: [],
      updateGroupsLoading: false,
      updateGroupsSuccess: undefined,

      editNameGroup: undefined,
      editNameModalVisible: false,

      promptRedirectPath: undefined,
      promptIsVisible: false,
      promptIsReirectAloved: false,

      successDialogSettings: {
        open: false,
        message: ''
      },
      filters: {
        search: '',
        first_letter: '',
        sortingType: SORTING_TYPES.NAME_ASC,
        triggerFilter: false
      },
      filterResult: {
        count: undefined,
        word: undefined
      },
      customerType: getSearchCustomerType(this.props.auth).value,

      allGroups: [], //CONTAIN ALL BROKER GROUPS
      groupType: this.props.dashboardType === DASHBOARD_TYPE.BROKER ? DASHBOARD_SETTINGS_TYPE.BROKER_GROUP : DASHBOARD_SETTINGS_TYPE.CUSTOMER_GROUP,
      customersWithoutGroup: undefined
    };

    this.fetchGroups = this.fetchGroups.bind(this);
    this.handleSaveGroupUpdates = this.handleSaveGroupUpdates.bind(this);
    this.syncGroupsWithUpdated = this.syncGroupsWithUpdated.bind(this);
    this.handleEditNameClicked = this.handleEditNameClicked.bind(this);
    this.handleEditNameClose = this.handleEditNameClose.bind(this);
    this.handleEditNameSave = this.handleEditNameSave.bind(this);
    this.updateGroups = this.updateGroups.bind(this);
    this.handleLeavePage = this.handleLeavePage.bind(this);
    this.handlePromptClose = this.handlePromptClose.bind(this);
    this.handlePromptAccept = this.handlePromptAccept.bind(this);
    this.handleSuccessModalClose = this.handleSuccessModalClose.bind(this);
    this.updateStorageCustomers = this.updateStorageCustomers.bind(this);
    this.getCustomersNumber = this.getCustomersNumber.bind(this);
    this.handleSearchClicked = this.handleSearchClicked.bind(this);
    this.handleSearchByLetter = this.handleSearchByLetter.bind(this);
    this.handleFilterGroups = this.handleFilterGroups.bind(this);
  }

  componentDidMount() {
    this.fetchGroups();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.filters.triggerFilter) {
      this.handleFilterGroups();
    }
    if(prevProps.customers !== this.props.customers || prevProps.globalCustomers !== this.props.globalCustomers) {
      this.setState({
        customersWithoutGroup: this.getCustomersNumber()
      })
    }
  }

  componentWillUnmount() {
    return true;
  }

  async fetchGroups() {
    this.props.setGroupsLoadingErrors(undefined);
    this.props.setGroupsLoading(true);
    try {
      let response = await CustomerReportSettingResource.at('broker/get-dashboard-groups-settings/').get({
          broker_id: this.props.auth.user.broker_id,
          show_broker_groups: this.props.dashboardGroupType===DASHBOARD_SETTINGS_TYPE.BROKER_GROUP
      });

      this.setState({allGroups: response.groups});
      this.setState(prevState => ({filters: {...prevState.filters, triggerFilter: true }})); // trigger filter
      this.props.setGroupsLoading(false);

    } catch (errors) {
      this.props.setGroupsLoading(false);
      this.props.setGroupsLoadingErrors(errors)
    }
  }

  handleLeavePage(state) {
    this.setState({
      promptIsVisible: true,
      promptRedirectPath: state.pathname
    });

    return this.state.promptIsReirectAloved;
  }

  handlePromptClose() {
    this.setState({
      promptIsVisible: false
    });
  }

  handlePromptAccept() {
    this.setState({
      promptIsVisible: false,
      promptIsReirectAloved: true,
    });

    setTimeout(() => this.props.history.push(this.state.promptRedirectPath), 250);

  }

  updateStorageCustomers(customers) {
    const storeCustomers = this.props.customers;
    const storeCustomersErrors = this.props.errors;

    if (!storeCustomersErrors && storeCustomers) {

      for (let index = 0; index < storeCustomers.length; index++) {
        customers.forEach(customerData => {
          if (storeCustomers[index].id == customerData.id) {
            if(!_.isNil(this.props.dashboardGroupType)){
              // when dashboard group is deleted, remove it from dashboard groups of customer
              let deleteBrokerGroups = this.props.dashboardGroupType == DASHBOARD_SETTINGS_TYPE.BROKER_GROUP
              storeCustomers[index].dashboard_groups = storeCustomers[index].dashboard_groups.filter(g => g.is_broker_group != deleteBrokerGroups)
            } else{
              storeCustomers[index].group = undefined;
            }

          }
        });
      }

      this.props.addCustomersToStore(storeCustomers);

    } else {
      this.props.addCustomersWithoutGroupToStore(customers);
    }
  }

  handleGroupDeleted(groupObject) {
    return () => {
      let groups = _.filter(this.props.reportSettings.groups, group => group.id !== groupObject.id);

      this.props.setGroupsData(groups);

      this.updateStorageCustomers(groupObject.customers)

      this.props.displayNotification('success', `Gruppe "${groupObject.name}" wurde erfolgreich gelöscht.`);
    };
  }

  syncGroupsWithUpdated() {
    let groupsCopy = [...this.props.reportSettings.groups];

    for (let i = 0; i < this.state.updatedGroups.length; i++) {
      for (let j = 0; j < groupsCopy.length; j++) {
        if (groupsCopy[j].id === this.state.updatedGroups[i].id) {
          groupsCopy[j] = this.state.updatedGroups[i];
          break;
        }
      }
    }

    this.props.setGroupsData(groupsCopy);
    this.setState({
      updatedGroups: []
    });
  }

  handleGroupUpdated(group) {
    return (fieldname) => (value) => {
      // TODO refactor this logic
      let updatedGroupData = _.find(this.state.updatedGroups, data => data.id === group.id);

      if (!_.isNil(updatedGroupData)) {
        if (fieldname === 'areas_visibility') {
          updatedGroupData = {
            ...updatedGroupData,
            with_payment_plans: value.includes(REPORTS_AREAS.PAYMENT_PLANS.value),
            with_profit_loss: value.includes(REPORTS_AREAS.PROFIT_LOSS.value),
            include_historical_portfolios: value.includes(REPORTS_AREAS.INCLUDE_HISTORICAL_PORTFOLIOS.value),
            with_transaction_saldo: value.includes(REPORTS_AREAS.WITH_TRANSACTION_SALDO.value),
            with_transactions_monitor: value.includes(REPORTS_AREAS.TRANSACTIONS_MONITOR.value),
            with_other_assets: value.includes(REPORTS_AREAS.OTHER_ASSETS.value)
          }
        }
        else if(fieldname === 'date_updates'){
          updatedGroupData = {
            ...updatedGroupData,
            date_range: value.date_range,
            date_range_start_date: value.date_range_start_date,
            date_range_end_date: value.date_range_end_date,
          }
        } else if(fieldname === 'benchmark_updates') {
            updatedGroupData = {
              ...updatedGroupData,
              benchmark_enabled: value.benchmark_enabled,
              benchmarks: value.benchmarks,
            }
        } else {
          updatedGroupData = {
            ...updatedGroupData,
            [fieldname]: value
          };
        }

        let isOldEqualNew = updatedGroupData.report_type === group.report_type &&
          updatedGroupData.report_generation_type === group.report_generation_type &&
          updatedGroupData.distribution_type === group.distribution_type &&
          updatedGroupData.date_range === group.date_range &&
          updatedGroupData.with_cost === group.with_cost &&
          updatedGroupData.with_transactions === group.with_transactions &&
          updatedGroupData.with_payment_plans == group.with_payment_plans &&
          updatedGroupData.with_profit_loss == group.with_profit_loss &&
          updatedGroupData.include_historical_portfolios === group.include_historical_portfolios &&
          !!updatedGroupData.save_settings_to_related === !!group.save_settings_to_related &&
          updatedGroupData.with_invested_capital === group.with_invested_capital &&
          isBenchmarksSettingsEqual(updatedGroupData.benchmarks, group.benchmarks) &&
          updatedGroupData.benchmark_enabled === group.benchmark_enabled &&
          updatedGroupData.date_range === group.date_range &&
          updatedGroupData.date_range_start_date == group.date_range_start_date &&
          updatedGroupData.date_range_end_date == group.date_range_end_date &&
          updatedGroupData.aggregate_historical_chart_portfolios === group.aggregate_historical_chart_portfolios &&
          updatedGroupData.aggregate_performance_chart_portfolios === group.aggregate_performance_chart_portfolios &&
          updatedGroupData.with_transaction_saldo === group.with_transaction_saldo &&
          updatedGroupData.with_transactions_monitor === group.with_transactions_monitor &&
          updatedGroupData.with_other_assets === group.with_other_assets;

        let updatedGroups = _.filter(this.state.updatedGroups, data => data.id !== group.id);
        if (!isOldEqualNew) {
          updatedGroups.push(updatedGroupData);
        }

        this.setState({
          updatedGroups
        });
      } else {
        if (fieldname === 'areas_visibility') {
          this.setState({
            updatedGroups: [...this.state.updatedGroups, {
              ...group,
              with_payment_plans: value.includes(REPORTS_AREAS.PAYMENT_PLANS.value),
              with_profit_loss: value.includes(REPORTS_AREAS.PROFIT_LOSS.value),
              include_historical_portfolios: value.includes(REPORTS_AREAS.INCLUDE_HISTORICAL_PORTFOLIOS.value),
              with_transaction_saldo: value.includes(REPORTS_AREAS.WITH_TRANSACTION_SALDO.value),
              with_transactions_monitor: value.includes(REPORTS_AREAS.TRANSACTIONS_MONITOR.value),
              with_other_assets: value.includes(REPORTS_AREAS.OTHER_ASSETS.value)
            }]
          });
        } else if(fieldname === 'date_updates'){
          this.setState({
            updatedGroups: [...this.state.updatedGroups, {
              ...group,
              date_range: value.date_range,
              date_range_start_date: value.date_range_start_date,
              date_range_end_date: value.date_range_end_date,
            }]
          });
        } else if(fieldname === 'benchmark_updates') {
          this.setState(prevState => {
            return {
              updatedGroups: [...prevState.updatedGroups, {
                  ...group,
                  benchmark_enabled: value.benchmark_enabled,
                  benchmarks: value.benchmarks,
                }]
            }
          });
        }  else {
          this.setState({
            updatedGroups: [...this.state.updatedGroups, {
              ...group,
              [fieldname]: value
            }]
          });
        }
      }
    };
  }

  async handleSaveGroupUpdates() {

    this.setState({
      updateGroupsLoading: true,
      updateGroupsSuccess: undefined
    })
    let body = {};
    body['data'] = this.state.updatedGroups.map((group) => ({
      id: group.id,
      report_type: group.report_type,
      date_range: group.date_range.toString(),
      date_range_start_date: group.date_range_start_date,
      date_range_end_date: group.date_range_end_date,
      with_payment_plans: group.with_payment_plans,
      with_profit_loss: group.with_profit_loss,
      include_historical_portfolios: group.include_historical_portfolios,
      with_invested_capital: group.with_invested_capital,
      with_transaction_saldo: group.with_transaction_saldo,
      with_transactions_monitor: group.with_transactions_monitor,
      with_other_assets: group.with_other_assets,
      broker_id: this.props.auth.user.broker_id,
      dashboard_group_id: group.dashboard_group_id,
      benchmark_enabled: group.benchmark_enabled,
      benchmarks: group.benchmarks,
      aggregate_historical_chart_portfolios: group.aggregate_historical_chart_portfolios,
      aggregate_performance_chart_portfolios: group.aggregate_performance_chart_portfolios,
      custom_report_type_settings: group.custom_report_type_settings,
      individual_settings: group.individual_settings,
    }));

    body['settings_type'] = this.props.dashboardGroupType;

    try {
      await CustomerReportSettingResource.at('broker/save-dashboard-settings/').post(body)
      this.setState({
        updateGroupsLoading: false,
        updateGroupsSuccess: true
      });

      this.syncGroupsWithUpdated();

      setTimeout(() => {
        this.setState({
          updateGroupsSuccess: undefined
        });
      }, 2000);
    } catch (error) {
      this.props.displayNotification('error', error);
      this.setState({
        updateGroupsLoading: false,
        updateGroupsSuccess: false
      })
    }
  }

  renderGroup(group) {
    return (
      <div key={group.id}>
        <DashboardGroupSettings
          group={group}
          onEditNameClick={this.handleEditNameClicked}
          onGroupDeleted={this.handleGroupDeleted(group)}
          dashboardGroupType={this.props.dashboardGroupType}
          updatedGroup={_.find(this.state.updatedGroups, groupData => groupData.id === group.id)}
          handleGroupUpdated={this.handleGroupUpdated(group)}
          dashboardType={this.props.dashboardType}
          settingsType={this.state.groupType}
          newDesign={this.props.newDesign}
        />
      </div>
    )
  }

  renderGroups() {
    if (_.isArray(this.props.guideTourGroups) && this.props.guideTourGroups.length) {
      return this.props.guideTourGroups.map(group => this.renderGroup(group));
    }
    if (_.isArray(this.props.reportSettings.groups)) {
      return this.props.reportSettings.groups.map(group => this.renderGroup(group));
    }
  }

  handleEditNameClicked(group) {
    this.setState({
      editNameGroup: group,
      editNameModalVisible: true
    });
  }

  handleEditNameClose() {
    this.setState({
      editNameGroup: undefined,
      editNameModalVisible: false
    });
  }

  updateGroups(groupUpdated) {
    let groups = [...this.props.reportSettings.groups];

    for (let i = 0; i < groups.length; i++) {
      if (groups[i].id === groupUpdated.id) {
        groups[i].name = groupUpdated.name; // For the moment update only name
        break;
      }
    }

    this.props.setGroupsData(groups);
  }

  async handleEditNameSave(group, name) {
    try {
      !_.isNil(this.props.dashboardGroupType)
        ? await CustomerReportSettingResource.at('broker/save-dashboard-settings/').post({ data:
          [{id:group.id, name: name, broker_id: this.props.auth.user.broker_id}], settings_type: this.props.dashboardGroupType})
        : await GroupResource.at(`${group.id}/`).patch({name});

      this.setState({
        editNameGroup: undefined,
        editNameModalVisible: false,
        successDialogSettings: {
          open: true,
          message: <p>Ihre Gruppe <b>{name}</b> wurde gespeichert.</p>
        }
      });
      group.name = name;
      this.updateGroups(group);

    } catch (error) {
      let errorMessage = _.isString(error)? error : 'Error!';
      if (_.isObject(error) && 'name' in error) {
        errorMessage = error.name[0];
      }

      this.props.displayNotification('error', errorMessage);
    }
  }

  renderLoadingContainer() {
    return (
      <>
        <GroupSettingsItem group={{}} loading={true} />
        <GroupSettingsItem group={{}} loading={true} />
        <GroupSettingsItem group={{}} loading={true} />
      </>
    )
  }

  handleSuccessModalClose() {
    this.setState({
      successDialogSettings: {
        open: false,
        message: ''
      }
    });
  }

  getCustomersNumber() {
    // Returns number of customers that were not added to dashboard groups

    function customerHasGroupIdentifiers(customer){
      // Returns true if customer has group_identifiers
      return !_.isNil(customer.group_identifiers) && Object.keys(customer.group_identifiers).length > 0
    }

    try {
      let filteredCustomers = _.filter(this.props.customers, customer => {
        if(customerHasGroupIdentifiers(customer)){
          return _.isNil(customer.dashboard_groups) || customer.dashboard_groups.some(e => e.is_broker_group !== !!this.state.groupType)
        }
      });
      return this.props.customers.filter(c => customerHasGroupIdentifiers(c)).length - filteredCustomers.length;
    } catch {
      return 0;
    }

  }

  onNewGroupButtonClick = () => {
    let path = ROUTES.BROKER.DASHBOARD_GROUP_CREATE.path
    if (newDesignUsed()) {
      let menu_item = !_.isNil(this.props.dashboardGroupType)
        ? DASHBOARD_GROUP_TYPE_MENU_ITEM_MAPPING[_.findKey(DASHBOARD_SETTINGS_TYPE, (value) => value===this.props.dashboardGroupType)]
        : 'REPORT_SETTINGS'

      executeIfPathExist(this.props.investmentPlatform.routes, menu_item, (dynamicPath) => {
        path = '/' + getInvestmentDynamicPath() + dynamicPath + ROUTES.BROKER.DASHBOARD_GROUP_CREATE.newDesignPath
      })
    }

    this.props.history.push(path)
  }

  handleSearchByLetter = (letter) => {
    let searchValue = letter ? letter.trim() : ''

    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        first_letter: searchValue,
        search: '',
        triggerFilter: true
      }
    }));
  };

  handleSearchClicked = (value) => {
    let searchValue = value ? value.trim() : ''

    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        first_letter: '',
        search: searchValue,
        triggerFilter: true
      }
    }));
  };

  handleFilterGroups = () => {
    if (Object.entries(this.state.filters).length !== 0) {
      let groupsJoined = [].concat.apply([], this.state.allGroups || []);

      let filtered = [...filterGroups(groupsJoined, this.state.filters)];

      filtered = sortGroups(filtered, this.state.filters.sortingType);

      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          triggerFilter: false
        }
      }));
      let searchText = this.state.filters.first_letter ? `Anfangszeichen ${this.state.filters.first_letter}` : this.state.filters.search;

      this.setState({
        filterResult: {
          word: searchText,
          count: filtered.length
        }
      });

      this.props.setGroupsData(filtered);
    }
  };

  render() {
    const {classes} = this.props;

    return (
      <>

      <SuccessDialog
        open={this.state.successDialogSettings.open}
        message={this.state.successDialogSettings.message}
        onClose={this.handleSuccessModalClose}
      />

      <Prompt
        when={this.state.updatedGroups.length > 0}
        message={this.handleLeavePage}
      />
      <PromptDialog
        isOpen={this.state.promptIsVisible}
        title={""}
        message={<>
          <p>
            Sie haben Änderungen vorgenommen, diese aber nicht gespeichert.
            Wenn Sie die Seite vor Speicherung verlassen, gehen Ihre Änderungen verloren.</p>
          <p>Sind Sie sicher, dass Sie die Seite verlassen möchten?</p>
        </>}
        okBtnLabel={'Auf Seite bleiben'}
        cancelBtnLabel={'Seite verlassen'}
        handleYesBtn={this.handlePromptClose}
        handleNoBtn={this.handlePromptAccept}
        handleCancel={this.handlePromptClose}
      />
      <div>
        <div className={classes.groupHeader}>
          <p>IHRE KUNDENGRUPPEN</p>
          {!this.props.globalCustomers.loading && (
            <p id="report-settings-customers-without-group-tour-element">
              {customerWithoutGroupMessage(this.state.customersWithoutGroup)}
            </p>
          )}
        </div>
        <div className={classes.groupContent}>
          <div className={classes.addGroupSection}>
            {(this.props.reportSettings.groups && this.props.reportSettings.groups.length === 0 && !(this.props.guideTourGroups && this.props.guideTourGroups.length)) && (
              <p>Sie haben noch keine Kundengruppe erstellt.</p>
            )}
            <PrimaryButton
              id='report-settings-add-group-btn-tour-element'
              text='Kunden selektieren'
              onButtonClick={this.onNewGroupButtonClick}
              icon={<AddIcon />}
            />
          </div>
          <CustomerFilteringPanel
            customerType={this.state.customerType}
            defaultSearchValue={''}
            handleSearchByLetter={this.handleSearchByLetter}
            handleSearchClicked={this.handleSearchClicked}
            isChief={UserUtils.isChief(this.props.auth)}
            user={this.props.auth.user}
            useGroupSearchTooltip
            loading={this.props.reportSettings.loading}
          />
          {hasFilterResult(this.state.filterResult) &&
            <FilterHeader filterResult={this.state.filterResult}/>
          }
          {this.props.reportSettings.loading ? this.renderLoadingContainer() : this.renderGroups()}
        </div>
      </div>
      <EditGroupNameModal
        group={this.state.editNameGroup}
        open={this.state.editNameModalVisible}
        onSaveClick={this.handleEditNameSave}
        onModalClose={this.handleEditNameClose}
      />
      <Footer
        isVisible={this.state.updatedGroups.length > 0 || this.state.updateGroupsSuccess}
        isLoading={this.state.updateGroupsLoading}
        isSettingsSuccessUpload={this.state.updateGroupsSuccess}
        handleSaveClicked={this.handleSaveGroupUpdates}
      />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  reportSettings: state.get('reportSettings').toJS(),
  customers: state.get('customersDataProvider').toJS().customers,
  errors: state.get('customersDataProvider').toJS().errors,
  auth: state.get('auth').toJS(),
  guideTourGroups: state.get('reporting').toJS().guideTourGroups,
  investmentPlatform: state.get('investmentPlatform').toJS()
});
const mapDispatchToProps = dispatch => ({
  setGroupsData: (state) => dispatch(setGroupsData(state)),
  setGroupsLoading: (loading) => dispatch(setGroupsLoading(loading)),
  setGroupsLoadingErrors: (errors) => dispatch(setGroupsLoadingErrors(errors)),
  addCustomersToStore: customers => dispatch(addCustomersToStore(customers)),
  addCustomersWithoutGroupToStore: customers => dispatch(addCustomersWithoutGroupToStore(customers))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNotification(withRouter(withStyles(styles)(withCustomersData(GroupSettings)))));