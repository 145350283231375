import React from 'react';
import clsx from 'clsx';

import {
  LinearProgress,
  Button
} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';

import useStyles from './styles';
import { withEuroOrDash } from '../../../../../../utils/utils';

const AssetItem = props => {
  const classes = useStyles();
  const { 
    asset,
    weight
  } = props;

  return (
    <div id="asset-details-tour-element" className={classes.assetItemContainer}>
      <p className={classes.assetItemName}>{asset.name}</p>
      <p className={classes.assetItemTotalValue}>{withEuroOrDash(parseFloat(asset.asset_value))}</p>
      <div className={classes.navigationPanel}>
        <Hidden smDown>
          <LinearProgress
            variant="determinate"
            value={weight}
            classes={
              {
                root: classes.progressRoot,
                bar: classes.progressBar
              }
            }
          />
        </Hidden>
        <div style={{display: 'flex'}}>
          {asset.imported ? (
            <>
              <Button
                className={clsx(classes.assetItemActionButton, classes.assetItemActionButtonOutlined)}
                variant="outlined"
                color="primary"
                onClick={() => props.viewBtnHandler(asset)}
              >
                <span><i className={`fa fa-eye`} aria-hidden="true"/><span>Anzeigen</span></span>
              </Button>
            </>
            ) : (
            <>
              <Button
                id="edit-asset-button-tour-element"
                className={clsx(classes.assetItemActionButton, classes.assetItemActionButtonOutlined)}
                variant="outlined"
                color="primary"
                onClick={() => props.editBtnHandler(asset)}
              >
                <span><i className={`fa fa-pencil`} aria-hidden="true"/><span>Bearbeiten</span></span>
              </Button>
              <Button
                id="delete-asset-button-tour-element"
                className={classes.assetItemActionButton}
                color="primary"
                onClick={() => props.deleteBtnHandler(asset)}
              >
                <span><i className={`fa fa-trash`} aria-hidden="true"/><span>Löschen</span></span>
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
};

export default AssetItem;