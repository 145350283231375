import { makeStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    height: 8,
    flex: 1,
    marginLeft: 10,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.primary.main,
    border: '2px solid white',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
    backgroundImage: 'linear-gradient(to right, #bfe6b8, #5ec14e)'
  },
  rail: {
    height: 8,
    backgroundColor: '#bfe6b8',
    borderRadius: 4,
  }
});

export default styles;

export const useStyles = makeStyles(styles);