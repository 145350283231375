import React from 'react';
import PropTypes from "prop-types";

import useStyles from './styles';
import UploadAdditionalFiles from "../../../../containers/GroupReporting/components/UploadAdditionalFiles";
import WarningTooltip from "../../../WarningTooltip";
import {FieldTitle} from "../index";
import {useDownloadPdfDialogContext} from "../../DownloadPdfModal";


function Documents() {
  const classes = useStyles();
  const {state, handleStateValueChange} = useDownloadPdfDialogContext();

  const addExtraFile = (formData, added_file) => {
    /** Adds file to extraFiles state */
    const extraFiles = state.extraFiles || [];
    handleStateValueChange('extraFiles', [...extraFiles, added_file]);
  }

  const removeExtraFile = (fileToRemove) => {
    // files in ad-hoc reporting are not saved in db => they don't have id, so use lastModified as identifier
    const extraFiles = state.extraFiles || [];
    let extraFilesWithoutDeleted = [...extraFiles].filter(file => file.lastModified !== fileToRemove.id);
    handleStateValueChange('extraFiles', extraFilesWithoutDeleted);
  }

  return (
    <div className={classes.container}>
      <FieldTitle>
        Zusätzliches Dokument hochladen
        <WarningTooltip
          title="Hier haben Sie die Möglichkeit weitere Dokumente Ihrem Report hinzuzufügen."
        />
      </FieldTitle>
      <UploadAdditionalFiles
        onExtraFileDelete={removeExtraFile}
        onExtraFileUpload={addExtraFile}
        withExplanationText={false}
        title={null}
      />
    </div>
  )
}

export default Documents;