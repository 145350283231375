import React from 'react'

import useFetchData from "./useDataFetch";

/**
 * Custom hook to load assets info static data.
 * @return {*}
 */
function useAssetInfoStatic() {

  const [assetsData, makeRequest] = useFetchData('assets/general/info/static/', 'get')

  React.useEffect(() => {
    makeRequest()
  }, [])

  return assetsData

}

export default useAssetInfoStatic