import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  iconContainer: {
    '& > svg': {
      fill: theme.palette.primary.main
    }
  }
});

export default makeStyles(styles);