import React from 'react';

function HazardousWasteRatio({}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.852" height="30" viewBox="0 0 22.852 30">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rechteck_7675" data-name="Rechteck 7675" width="22.852" height="30" fill="#fff"/>
        </clipPath>
      </defs>
      <g id="Gruppe_8240" data-name="Gruppe 8240" transform="translate(0 0)">
        <g id="Gruppe_7972" data-name="Gruppe 7972" transform="translate(0 0.001)" clipPath="url(#clip-path)">
          <path id="Pfad_6745" data-name="Pfad 6745" d="M22.852,8.848V3.574h-2.09L19,0H3.847L2.089,3.574H0V8.848H1.974l.146,1.758H0v1.758H2.266l.906,10.908a2.639,2.639,0,0,0-1.414,2.335v1.758a2.637,2.637,0,0,0,5.123.879h9.091a2.637,2.637,0,0,0,5.123-.879V25.605a2.638,2.638,0,0,0-1.414-2.335l.906-10.908h2.266V10.605H20.731l.147-1.758ZM4.942,1.758H17.91L18.8,3.574H4.048ZM1.758,5.332H21.094V7.09H1.758ZM5.274,27.363a.879.879,0,1,1-1.758,0V25.605a.879.879,0,1,1,1.758,0Zm14.062,0a.879.879,0,1,1-1.758,0V25.605a.879.879,0,1,1,1.758,0Zm-1.4-4.343a2.641,2.641,0,0,0-2.116,2.585v.879H7.031v-.879a2.641,2.641,0,0,0-2.116-2.585L3.738,8.848H19.113Z" transform="translate(0 0)" fill="#fff"/>
        </g>
      </g>
    </svg>
  )
}

HazardousWasteRatio.propTypes = {}

HazardousWasteRatio.propDefs = {}

HazardousWasteRatio.defaultProps = {}

export default HazardousWasteRatio;