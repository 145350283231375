import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  icon: {
    display: 'inline-flex',
    height: 28,
    width: 28,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20
  }
})

export default makeStyles(styles)