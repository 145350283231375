import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Collapse, FormControlLabel } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import useStyles from './styles';
import clsx from 'clsx'

function CollapsableConfigWrapper(props) {
  const {
    opened,
    children,
    item,
    level,
    onChange,
  } = props;
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(opened);

  return (
    <div className={classes.collapseWrapper}>
      <div className={clsx(classes.collapseHeader, classes.adaptableFontSize)} onClick={() => setCollapsed(!collapsed)}>
        <div onClick={(e) => e.stopPropagation()}>
          <FormControlLabel
            label={item.name}
            disabled={item.disabled}
            classes={{
              root: classes.collapseLabelRoot,
              label: clsx(level === 1 ? classes.collapseLabelItem : classes.collapseLabelItemMidLevel, classes.adaptableFontSize)
            }}
            control={
              <Checkbox
                color="primary"
                classes={{root: classes.checkbox}}
                checked={item.checked}
                onChange={onChange}
              />
            }
          />
        </div>
        {collapsed ? (
          <ExpandLessIcon className={classes.collapseIcon} />
        ) : (
          <ExpandMoreIcon className={classes.collapseIcon} />
        )}
      </div>
      <Collapse in={collapsed} classes={{ wrapperInner: classes.wrapperInner }}>
        {children}
      </Collapse>
    </div>
  )
}

CollapsableConfigWrapper.propTypes = {
  opened: PropTypes.bool,
  item: PropTypes.shape({
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    name: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
}

CollapsableConfigWrapper.defaultProps = {
  opened: true,
};

export default CollapsableConfigWrapper;
