import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    '& #date-picker-dropdown': {
      height: '45px !important',
      minHeight: '45px !important',
    }
  },
  disclaimer: {
    marginTop: '1em'
  }
}))