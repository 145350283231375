import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { DropdownInput } from '../../../Modelportfolios/components'
import { SearchIcon } from '../../../../images'

import useStyles from './styles';

var arrayToTree = require('array-to-tree');
const INACTIVE_INPUT_COLOR = '#202A38'

function BanksListMenu(props) {
  
  const classes = useStyles();

  const {
    banks,
    activeDocuments,
    onDocumentClick,
    onSelectDocumentButtonClick,
    externalContent
  } = props;

  const [searchValue, setSearchValue] = React.useState('');

  const handleDocumentClick = (document) => {
    if (onDocumentClick) {
      onDocumentClick(document);
    }
  }

  const handleSelectDocumentButtonClick = () => {
    if (onSelectDocumentButtonClick) {
      onSelectDocumentButtonClick();
    }
  }

  const filterFolders = (tree) => {
    if (!searchValue) {
      return tree
    }

    let treeCopy = [...tree]
    let result = []

    for(let index = 0; index < treeCopy.length; index++) {
      let isValid = filterDocuments(treeCopy[index]) || treeCopy[index].name.includes(searchValue)

      if (isValid) {
        result.push(treeCopy[index])
      }
    }

    return result
  }

  const filterDocuments = (item) => {
    if (item.documents && item.documents.length && !item.name.includes(searchValue)) {
      item.documents = item.documents.filter(document => document.name.includes(searchValue))
    }

    if (item.children) {

      let childrenCopy = []

      for (let index = 0; index < item.children.length; index++) {
        let isValid = filterDocuments(item.children[index]) || item.children[index].name.includes(searchValue)

        if (isValid) {
          childrenCopy.push(item.children[index])
        }
      }

      item.children = childrenCopy
    }

    if ((item.documents && item.documents.length) || (item.children && item.children.length)) {
      return true
    }
    return false
  }

  const prepareBankFolders = (bank) => {
    if (bank.folders) {
      return filterFolders(arrayToTree(bank.folders, {
        parentProperty: 'parent',
        customID: 'id'
      }));
    }

    return [];
  };

  const getDocumentsCount = (folder) => {

    let folderCopy = {...folder}
    if (searchValue) {
      filterDocuments(folderCopy)
    }
    let count = 0;

    if (folderCopy.documents) {
      count += folderCopy.documents.length;
    }

    if (folderCopy.children) {
      folderCopy.children.forEach(c => {
        count += getDocumentsCount(c)
      });
    }

    if (folderCopy.folders) {
      folderCopy.folders.forEach(c => {
        count += getDocumentsCount(c)
      });
    }

    return count;
  }

  const renderDocument = (document, documentPositionIndex) => (
    <Accordion 
      key={document.id}
      expanded
      square 
      disabled={!document.document_exist}
      classes={{root: classes.accordionRoot, expanded: classes.accordionExpanded}} 
      className={clsx(classes.documentAccordion, (activeDocuments && !!_.find(activeDocuments, d => d.id == document.id)) ? classes.documentAccordionActive: '')}
      onClick={() => document.document_exist ? handleDocumentClick(document) : undefined}
    >
      <AccordionSummary
        style={{paddingLeft: documentPositionIndex ? documentPositionIndex == 1 ? 2 * 16 : (documentPositionIndex + 1) * 16 : 'auto', position: 'relative'}}
      >
        <Typography className={classes.accordionHeading}>
          <i className="fa fa-file-text-o" aria-hidden="true" style={{marginRight: 20}}></i>
          {document.name}
        </Typography>
        {!document.document_exist && (
          <div className={classes.documentDoesNotExist}>
            <i class="fa fa-ban" aria-hidden="true"></i>
            <span>Document does't exists</span>
          </div>
        )}
      </AccordionSummary>
    </Accordion>
  )

  const renderFolders = (folder, folderPositionIndex) => {

    let subfolders = [];

    if (folder.children) {
      subfolders = folder.children.map(subfolder => renderFolders(subfolder, folderPositionIndex + 1));
    }

    let documents = [];

    if (folder.documents) {
      documents = folder.documents.map(document => renderDocument(document, folderPositionIndex + 1));
    }

    return (
      <Accordion key={folder.id} square classes={{root: classes.accordionRoot, expanded: classes.accordionExpanded}} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{paddingLeft: folderPositionIndex ? folderPositionIndex == 1 ? 2 * 16 : (folderPositionIndex + 1) * 16 : 'auto'}}
        >
          <Typography className={classes.accordionHeading}>
            <i className="fa fa-folder-o" aria-hidden="true" style={{marginRight: 20}}></i>
            {folder.name} <span className={classes.documentsNumber}>{getDocumentsCount(folder)}</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails classes={{root: classes.accordionDetails}}>
          {subfolders}
          {documents}
        </AccordionDetails>
      </Accordion>
    )
  };

  return (
    <>
    <div className={classes.searchInputContainer}>
      <DropdownInput
        icon={<SearchIcon color={INACTIVE_INPUT_COLOR} />}
        placeholder="Dokumenten/Ordnername"
        value={searchValue}
        onChange={(value) => setSearchValue(value)}
      />
    </div>
    <div className={classes.container}>
      {externalContent && (
        <>
         {externalContent}
        </>
      )}

      {!externalContent && banks && (
        <>
          {banks.map(bank => (
            <>
              {(!searchValue || (searchValue && (prepareBankFolders(bank).length))) ? (
                <Accordion key={bank.id} square classes={{root: classes.accordionRoot, expanded: classes.accordionExpanded}}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography className={classes.accordionHeading}>
                      <i className="fa fa-folder" aria-hidden="true" style={{marginRight: 20}}></i>
                      {bank.name} <span className={classes.documentsNumber}>{getDocumentsCount(bank)}</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails classes={{root: classes.accordionDetails}}>
                    {
                      prepareBankFolders(bank).map(folder => renderFolders(folder, 0))
                    }
                  </AccordionDetails>
                </Accordion>
              ) : <></>}
            </>
          ))}
        </>
      )}
      <PrimaryButton
        text="Dokumente hinzufügen"
        disabled={!activeDocuments.length}
        onButtonClick={handleSelectDocumentButtonClick}
      />
    </div>
    </>
  );

};

BanksListMenu.propTypes = {
  banks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    folders: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      parent: PropTypes.number,
      order: PropTypes.number,
      documents: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        order: PropTypes.number,
        fields_mappings: PropTypes.arrayOf(PropTypes.shape({
          document_field_name: PropTypes.string.isRequired,
          required: PropTypes.bool,
          object_field_name: PropTypes.string
        }))
      }))
    }))
  })),
  onDocumentClick: PropTypes.func,
  onSelectDocumentButtonClick: PropTypes.func,
  activeDocument: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    order: PropTypes.number,
    fields_mappings: PropTypes.arrayOf(PropTypes.shape({
      document_field_name: PropTypes.string.isRequired,
      required: PropTypes.bool,
      object_field_name: PropTypes.string
    }))}),
  externalContent: PropTypes.element
};

export default BanksListMenu;

  


