import { makeStyles } from '@material-ui/core';


export default makeStyles(theme => ({
  formContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  formBody: {
    width: '100%',
    maxWidth: 512
  },
  formHeader: {
    display: 'flex',
    alignItems: 'center',

    '& > div': {
      height: 70,
      width: 70,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      [theme.breakpoints.only('xs')]: {
        height: 40,
        width: 40,
        minWidth: 40,
      },
    },

    '& > p': {
      fontFamily: 'Roboto-Regular',
      fontSize: 22,
      color: theme.palette.primary.main,
      marginLeft: 15,

      [theme.breakpoints.only('xs')]: {
        fontSize: 16,
        marginLeft: 10,
      },
    }
  },
  textField: {
    width: '100%',
    fontFamily: 'Roboto-Regular',
    color: '#4D4F5C',
    fontSize: 18,
    marginTop: 16,
    marginBottom: 8,
  },
  labelRoot: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    color: '#B1B1B1'
  },
  labelFocused: {
    color: `${theme.palette.primary.main}`
  },
  textFieldRoot: {
    
  },
  textFieldUnderline: {
    '&::before': {
      borderBottom: '2px solid #B1B1B1',
    },

    '&:hover::before': {
      borderBottom: `2px solid ${theme.palette.primary.main} !important`,
    },

    '&::after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`
    }
  },
  textFieldDisabled:{
    '&::before': {
      borderBottom: '2px solid #B1B1B1 !important',
    },
    '&:hover::before': {
      borderBottom: '2px solid #B1B1B1 !important',
    },
  },
  dateFieldIcon: {
    position: 'absolute',
    bottom: 15,
    right: 0
  },
  nonFieldErrors: {
    listStyle: 'none',
    paddingLeft: 0,
    marginBottom: 0
  },
  disabledFormField: {
    pointerEvents: 'none',

    '& $dateFieldIcon svg': {
      fill: '#B1B1B1'
    },

    '& $textField': {
      color: '#B1B1B1'
    }
  },
  labelButton: {
    marginBottom: 2,
    '& .fa': {
      fontSize: 14,
      paddingBottom: 6
    },
    '&.Mui-disabled .fa': {
      color: '#B1B1B1'
    }
  }
}))