import { SNACKBAR_OPEN, SNACKBAR_CLOSE } from './constants'

import {fromJS} from "immutable";


const initialState = fromJS({
  message: null,
  open: false,
  variant: null
});


export const createReducer = (state=initialState, action) => {
  switch (action.type) {
    case SNACKBAR_CLOSE:
      return state.set('open', false);
    case SNACKBAR_OPEN:
      return state.set('message', action.message).set('variant', action.variant).set('duration', action.duration).set('open', true);
    default:
      return state;
  }
};

export default createReducer;