const styles = (theme => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    background: 'transparent'
  },
  disabledButton: {
    cursor: 'auto',

    '& > svg': {
      fill: 'red !important'
    }
  },
  label: {
    marginLeft: 8,
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: theme.palette.primary.main
  },
  disabledLabel: {
    color: 'rgba(0, 0, 0, 0.38)'
  },
  iconDisabled: {
    fill: 'red'
  }
}));

export default styles;