import React from "react";

import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";

import styles from "../../styles";
import withStyles from "@material-ui/core/styles/withStyles";


const DocumentsTableHeaderRow = ({classes}) => {

  return (
    <TableRow className={classes.tableHead}>
      <TableCell>
        <div id="documents-list-tour-element">
          <span>Betreff</span>
          <br/>
          <span>Erstellt am</span>
        </div>
      </TableCell>
      <TableCell>
        <div id="documents-type-tour-element">
          <span>Dokumententyp</span>
          <br/>
          <span>Kategorie</span>
        </div>
      </TableCell>
      <TableCell>
        <div id="download-documents-tour-element">
          <span>Download</span>
          <br/>
          <span>Größe</span>
        </div>
      </TableCell>
    </TableRow>
  )
};

export default withStyles(styles)(DocumentsTableHeaderRow);