import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  bodyCell: {
    justifyContent: 'center',
  },
  productStructureTable: {
    '& .thead': {
      fontFamily: 'Roboto-Bold',
      '& .td': {
        justifyContent: 'center',
      }
    },
    '& .tr .td': {
      minHeight: 50,
    }
  },
  mainCategory: {
    fontFamily: 'Roboto-Bold',
    backgroundColor: '#EEF0F2 !important',
  },
  firstColumn: {
    '& .thead .td': {
      justifyContent: 'start',
    },

    '& .tr .td': {
      [theme.breakpoints.up("lg")]: {
        width: 300
      },
    }
  }
}))