import React from 'react';
import _ from 'lodash';

import { Button } from '@material-ui/core'; 

import useStyles from './styles';
import images from '../../images';


const SearchFilter = ({title, value, handleValueChanged, handleSearchClick, handleClearClick, disabled, withoutSearchHelperText}) => {
  const classes = useStyles();

  const [isActive, setIsActive] = React.useState(false);

  const onChangeHandler = (event) => {
    handleValueChanged(event.target.value);
    if (isActive) {
      setIsActive(false);
    }
  };

  const handleSearch = () => {
    setIsActive(true);
    handleSearchClick(value);
  }

  const onKeyDownHandler = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleButtonClick = () => {
    if (!isActive) {
      handleSearch();
    } else {
      setIsActive(false);
      handleClearClick();
    }
  }

  return (
    <div className={classes.searchContainer} id="search-filter-tour-element">
      <input 
        className={classes.searchInput} 
        placeholder={title} 
        value={value} 
        onChange={onChangeHandler} 
        onKeyDown={onKeyDownHandler} 
        disabled={disabled}
      />
      <Button 
        className={classes.searchButton} 
        classes={{
          disabled: classes.searchButtonDisabled
        }}
        variant="contained" 
        color="primary" 
        onClick={handleButtonClick}
        disabled={disabled}
      >
        { !isActive ? (
          <>
            <img src={images.search_icon_primary} alt='Suchen' style={{marginRight: 5}}/>
            {
              !withoutSearchHelperText && !_.isNil(value) && value.length > 0 && ('SUCHEN')
            }
          </>
        ) : (
          <img src={images.clear_input_icon} alt="Clear" className={classes.clearIcon}/>
        )}
      </Button>
    </div>
  )
}

export default SearchFilter;