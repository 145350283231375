import {
  ADD_PORTFOLIOS,
  REMOVE_PORTFOLIOS,
  BENCHMARK_CONFIGURATION,
  ADD_PORTFOLIO_DATA,
  TIME_SELECTOR_DATE_CHANGED, REPORT_TYPE_CHANGED,
} from "./constants";

export const addPortfolios = (portfolios) => {
  return {
    type: ADD_PORTFOLIOS,
    portfolios
  }
};

export const setBenchmarkConfiguration = (portfolio_id, modal_opened, configuration_required, forceShow) => ({
  type: BENCHMARK_CONFIGURATION,
  portfolio_id,
  modal_opened,
  configuration_required,
  forceShow, //used for move toggle on or not after config saved
});

export const removePortfolios = () => {
  return {
    type: REMOVE_PORTFOLIOS
  }
};

export const setTimeSelectorDateChange = (change) => ({
  type: TIME_SELECTOR_DATE_CHANGED,
  change
});

export const reportTypeChanged = (report_type) => {
  return {
    type: REPORT_TYPE_CHANGED,
    report_type
  }
};

export const addPortfolioData = (portfolio_data) => {
  return {
    type: ADD_PORTFOLIO_DATA,
    portfolio_data
  }
};