import { FORM_FIELD_HEIGHT } from '../../../../TransactionsMonitoring/components/Filters/components/common_styles';

const styles = (theme) => ({
  selectLabel: {
    backgroundColor: '#EDEDED'
  },
  disabled: {
    color: 'grey'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textField: {
    height: FORM_FIELD_HEIGHT,
    padding: 0,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      minHeight: '35px !important',
      height: '35px !important',
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    },

  },
  settingsMenu: {
    marginTop: 15
  },
  customLabel: {
    fontSize: 18,

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    },
  }
});

export default styles;