const styles = theme => ({
  root: {
    minHeight: 150,
    width: '100%'
  },
  chart: {
    width: '100%',
    height: 350
  },
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& > div': {
      width: '100%'
    },
  }
})

export default styles;