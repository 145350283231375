import React from 'react';
import _ from 'lodash';

import useStyles from './styles';
import DashboardTable from "../../../../components/DashboardTable/DashboardTable";
import ExplanationTooltip from "../../../../components/ExplanationTooltip";
import {withPercentOrDash} from "../../../../utils/utils";
import {toShortGermanFormat} from "../../../FactSheetsPage/utils";
import {InstrumentNameCell} from "../../../Modelportfolios/components/InstrumentsList/table-data";
import {PRODUCT_TYPE} from "../../constants";
import {calculateInstrumentsAverageSRRI} from "../../../RiskProfiling/utils";
import {PortfolioNameColumn} from "../../../Trades/components/TradeStep/components/PortfolioTrade/table-data";
import clsx from "clsx";
import {getProductName} from "../../utils";
import {MISSING_FIELD} from "../../../FactSheetsPage/components/PerformanceChart";
import {NO_SECTION_MARK, noBenchmarkValue} from "../../../../utils/constants";

const missingBenchmarkMark = '**';


export const ProductNameColumn = {
  header: {
    content: null
  },
  body: {
    content: (product, options) => {
      const classes = useStyles()

      let productName = '';
      if (product.type === PRODUCT_TYPE.ASSET) {
        productName = InstrumentNameCell.body.content(product.data, options)
      } else if ([PRODUCT_TYPE.MODEL_PORTFOLIO, PRODUCT_TYPE.PRIVATE_INVESTMENT].includes(product.type)) {
        productName = PortfolioNameColumn.body[0]({item: product, options});
      } else {
        const name = getProductName(product);
        productName = <b title={name}>{name}</b>;
      }

      return (
        <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
          {product.configuration.highlighted && (
            <i className={clsx("fa fa-star fa-lg", classes.highlightIcon)}/>
          )}
          {productName}
        </div>
      )
    }
  },
}

export function handleValue(product, section, field, formatter, noFieldDataMsg=MISSING_FIELD) {
  if (!product.hasOwnProperty(section) || product[section].error) {
    return NO_SECTION_MARK
  }

  const value = product[section][field];
  if (value === undefined || value === null) {
    return noFieldDataMsg;
  }

  return formatter ? formatter(value) : value
}

export const getColumnHeader = (title, tooltipKey, additionalText) => () => {
  const classes = useStyles();
  return (
    <>
      {title}
      {(tooltipKey || additionalText) && (
        <ExplanationTooltip tooltipKey={tooltipKey} additionalText={additionalText} classes={{helpIcon: classes.helpIcon}}/>
      )}
    </>
  )
}

const SharpeRatio = {
  header: {
    content: getColumnHeader('Sharp Ratio', 'sharpe_ratio')
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'performance', 'sharpe_ratio_a',
        (value) => toShortGermanFormat(value, '', '', 2, false))
    }
  }
}

const TimeWeightedReturn = {
  header: {
    content: getColumnHeader('Rendite (zeitgewichtet)', 'time_weighted_return')
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'time_weighted_return', 'return',
        (value) => withPercentOrDash(value, true))
    }
  }
}

const TimeWeightedReturnPA = {
  header: {
    content: getColumnHeader('Rendite p.a. (zeitgewichtet)', 'time_weighted_return_pa')
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'time_weighted_return', 'return_pa',
        (value) => withPercentOrDash(value, true))
    }
  }
}

const Return = {
  header: {
    content: getColumnHeader('Rendite (geldgewichtet)', 'return')
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'return', 'return',
        (value) => withPercentOrDash(value, true))
    }
  }
}

const ReturnPA = {
  header: {
    content: getColumnHeader('Rendite p.a. (geldgewichtet)', 'return_pa')
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'return', 'return_pa',
        (value) => withPercentOrDash(value, true))
    }
  }
}

const TrackingError = {
  header: {
    content: getColumnHeader('Tracking Error', 'tracking_error')
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'performance', 'tracking_error',
        (value) => withPercentOrDash(value, true), missingBenchmarkMark)
    }
  }
}

const Alpha = {
  header: {
    content: getColumnHeader('Alpha', 'alpha')
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'performance', 'alpha',
        (value) => toShortGermanFormat(value, '', '', 2, false), missingBenchmarkMark)
    }
  }
}

const Beta = {
  header: {
    content: getColumnHeader('Beta', 'beta')
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'performance', 'beta',
        (value) => toShortGermanFormat(value, '', '', 2, false), missingBenchmarkMark)
    }
  }
}

const InformationRatio = {
  header: {
    content: getColumnHeader('Information Ratio', 'information_ratio')
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'performance', 'information_ratio',
        (value) => toShortGermanFormat(value, '', '', 2, false), missingBenchmarkMark)
    }
  }
}

const MaximumDrawdown = {
  header: {
    content: getColumnHeader('Max. Wertverlust', 'maximum_drawdown')
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'historical', 'maximum_drawdown',
        (value) => withPercentOrDash(value, true))
    }
  }
}

const HistoricValueAtRisk = {
  header: {
    content: getColumnHeader('Hist. Value at Risk', 'value_at_risk')
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'historical', 'var_hist_hp',
        (value) => withPercentOrDash(value, true))
    }
  }
}

const Volatility = {
  header: {
    content: getColumnHeader('Schwankungs-breite', 'volatility')
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'historical', 'volatility_annual',
        (value) => withPercentOrDash(value, true))
    }
  }
}

const ExpectedShortfall = {
  header: {
    content: getColumnHeader('Expected Shortfall', 'expected_shortfall')
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'historical', 'cvar_hist_hp',
        (value) => withPercentOrDash(Math.abs(value), true))
    }
  }
}

function getAssetProductSri(product) {
  return product.data.hasOwnProperty('sri') && product.data.sri;
}

function getInvestmentStrategySri(product) {
  return product.data.srri
}

function getMusterdepotSri(product) {
  const assets = _.get(product, 'data.assets') || [];
  if (_.isEmpty(assets)) {
    return undefined
  }

  return calculateInstrumentsAverageSRRI(assets, (instrument) => parseFloat(instrument.weight))
}

function getPortfolioSri(product) {
  const data = _.isArray(product.data) ? product.data : [product.data];

  const portfoliosSri = data.map((portfolio) => portfolio.sri);

  if (_.every(portfoliosSri, (sri) => _.isNil(sri))) {
    return undefined
  }

  return _.max(portfoliosSri)
}

export const getProductSri = (product) => {
  const sri = {
    [PRODUCT_TYPE.ASSET]: getAssetProductSri,
    [PRODUCT_TYPE.PRIVATE_INVESTMENT]: getInvestmentStrategySri,
    [PRODUCT_TYPE.MUSTERDEPOT]: getMusterdepotSri,
    [PRODUCT_TYPE.MODEL_PORTFOLIO]: getInvestmentStrategySri,
    [PRODUCT_TYPE.CUSTOMER_PORTFOLIO]: getPortfolioSri
  }[product.type](product)

  return parseFloat(sri)
}

const RiskClass = {
  header: {
    content: () => {

      const classes = useStyles()

      return (
        <>
          Durchschn. Risikoklasse
        </>
      )
    }
  },
  body: {
    content: (product, options) => {
      const sri = getProductSri(product);
      if (!sri) {
        return MISSING_FIELD
      }
      return toShortGermanFormat(sri, '', '', 2, false);
    }
  }
}


const KenzahlenTableStructure = {
  default: [
    {
      content: [ProductNameColumn]
    },{
      content: [Return],
      align: 'right'
    },  {
      content: [ReturnPA],
      align: 'right'
    }, {
      content: [TimeWeightedReturn],
      align: 'right'
    },  {
      content: [TimeWeightedReturnPA],
      align: 'right'
    }, {
      content: [HistoricValueAtRisk],
      align: 'right'
    }, {
      content: [MaximumDrawdown],
      align: 'right'
    }, {
      content: [Volatility],
      align: 'right'
    }, {
      content: [ExpectedShortfall],
      align: 'right'
    }, {
      content: [SharpeRatio],
      align: 'right'
    }, {
      content: [RiskClass],
      align: 'right'
    }, {
      content: [TrackingError],
      align: 'right'
    }, {
      content: [Alpha],
      align: 'right'
    }, {
      content: [Beta],
      align: 'right'
    }, {
      content: [InformationRatio],
      align: 'right'
    }
  ]
}


function FondsKenzahlenTable({products, productKeysToCheck=['performance', 'historical', 'return', 'time_weighted_return'], tableStructure=KenzahlenTableStructure, tableLayout, customClasses, options, showMissingDataInfoCustomCondition}) {
  const classes = useStyles();

  const showMissingDataInfo = (() => {
    if (!products) {
      return false
    }

    for (let key of productKeysToCheck) {
      for (let product of products) {
        if (!product.hasOwnProperty(key) || product[key].error || showMissingDataInfoCustomCondition && showMissingDataInfoCustomCondition(product[key])) {
          return true
        }
      }
    }
  })();

  const showMissingBenchmark = _.has(options, 'hasBenchmarks') && !options.hasBenchmarks;

  return (
    <div>
      <div style={{position: 'relative', overflowX: 'auto'}}>
        <DashboardTable
          structure={tableStructure}
          dataSource={products || []}
          expanded={true}
          tableClasses={customClasses || classes}
          withFooter={false}
          options={options || {}}
          tableLayout={tableLayout}
        />

      </div>
      {(showMissingDataInfo || showMissingBenchmark) && (
        <div style={{marginTop: '1.5em'}}>
          {showMissingDataInfo && <div>*Für dieses Instrument steht diese Statistik nicht zu Verfügung.</div>}
          {showMissingBenchmark && <div>{missingBenchmarkMark}{noBenchmarkValue}</div>}
        </div>
      )}
    </div>
  )
}

export default FondsKenzahlenTable;