import React from 'react'
import {connect} from "react-redux";
import PropTypes from 'prop-types'
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';

import useStyles from './styles'
import { OpenFolderIcon, CloseFolderIcon } from '../../../../images';
import { buildTree } from './utils'
import ExpansionPanel from "../../../Modelportfolios/components/ExpansionPanel";

import {getAuthSelector} from "../../../../utils/redaxSelectors";

const mapStateToProps = (state) => ({
  auth: getAuthSelector(state),
});
// NOTE: used for both model portfolio and favorite lists
function ListTree(props) {

  const {
    auth,
    tree_items,
    loading,
    onSelect,
    selected,
    title,
    itemsTypes,
  } = props;

  const classes = useStyles();

  const renderExpansionPanel = (title, content, level=0) => (
    <ExpansionPanel
      expansionPanelTitle={title}
      expansionPanelContent={content}
      level={level}
      expansionPanelIcon={<OpenFolderIcon />}
      expansionPanelIconBack={<CloseFolderIcon />}
    />
  );

  const handleItemClick = (item) => () => {
    if (onSelect) {
      onSelect(item)
    }
  } 

  const renderLeaf = (item, level=0) => (
    <p 
      className={clsx([classes.itemName, selected && selected.id == item.id ? classes.active : ''])}
      style={{paddingLeft: level * 35}} 
      onClick={handleItemClick(item)}
    >{item.name}</p>
  )

  const renderFolder = (folder, level=0) => {
    let children = [];
    let items = [];

    if (folder.items) {
      items = folder.items.map((m) => renderLeaf(m, level + 1));
    }

    if (folder.children) {
      children = folder.children.map((c) => renderFolder(c, level + 1));
    }

  return renderExpansionPanel((
    <>
      <span>{folder.name}</span> 
      <span className={classes.itemCountLabel}>{getItemsCount(folder)}</span>
    </>
  ), (
      <>
        {children}
        {items}
      </>
    ), level)
  }

  const getItemsCount = (folder) => {
    let count = 0;

    if (folder.items) {
      count = folder.items.length;
    }

    if (folder.children) {
      folder.children.forEach(c => {
        count += getItemsCount(c)
      });
    }

    return count;
  }

  const renderLoadingIndicator = () => {
    return (
      <>
        <div className={classes.loading}><Skeleton /></div>
        <div className={classes.loading} style={{paddingLeft: 50}}><Skeleton /></div>
        <div className={classes.loading} style={{paddingLeft: 100}}><Skeleton /></div>
        <div className={classes.loading}><Skeleton /></div>
        <div className={classes.loading} style={{paddingLeft: 50}}><Skeleton /></div>
        <div className={classes.loading} style={{paddingLeft: 100}}><Skeleton /></div>
        <div className={classes.loading}><Skeleton /></div>
        <div className={classes.loading} style={{paddingLeft: 50}}><Skeleton /></div>
        <div className={classes.loading} style={{paddingLeft: 100}}><Skeleton /></div>
      </>
    )
  }

  const renderTree = () => {
    if (loading) {
      return renderLoadingIndicator();
    }

    if (tree_items) {

      let tree = buildTree(tree_items, title, itemsTypes, auth);

      return tree.map(m => renderFolder(m))
    }
  }
  
  return (
    <div className={classes.container}>
      {renderTree()}
    </div>
  )
}

ListTree.propTypes = {
  tree_items: PropTypes.arrayOf(PropTypes.shape({

  })),

  /** Flag, that indicate, if model portfolios data is loading, or not */
  loading: PropTypes.bool,
  title: PropTypes.string,
  itemsTypes: PropTypes.object,
}

export default connect(mapStateToProps)(ListTree);

