import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    display: 'flex',
    alignItems: 'baseline',
    margin: '27px 0',
  },
  title: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#202A38',
    marginRight: 15
  },
  itemTitle: {
    display: 'inline-block',
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    padding: '5px 16px',
    margin: '0 4px',
    whiteSpace: 'nowrap',

    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 20,

    transition: 'all',
    transitionDuration: 200,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(204, 233, 250, 0.3)'
    },

    '& > svg': {
      marginRight: 5
    }
  },

  itemActive: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontFamily: 'Roboto-Bold',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.main
    }
  },
  itemDisabled : {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  itemHolder: {
    display: 'flex',
    alignItems: 'center',
    margin: '7px 15px 7px 0',
    whiteSpace: 'nowrap'
  },
  
  infoIcon: {
    '& svg path': {
      transition: 'all',
      transitionDuration: 500
    },
    '&:hover svg path': {
      fill: '#202A38 !important'
    }
  },
  tooltipPopper: {
    padding: 20,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    boxShadow: '0px 3px 15px #00000033'
  }, 
  tooltipArrow: {
  }
})

export default makeStyles(styles)