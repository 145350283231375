import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path
        id="Pfad_6677"
        data-name="Pfad 6677"
        d="M-830,36.473l-8.032,1.758-3.652,3.689h-4.97l-3.363,3.33,2.485,2.486-1.606,1.63h-4.969L-860,55.261l4.507,1.919,4.726,4.729,1.917,4.559,5.881-5.939v-4.97l1.641-1.607,2.485,2.486,3.375-3.365V48.1l3.637-3.65Zm-3.125,5.788-2.71-2.711,3.505-.767Zm-.74,1.746-1.243,1.243-3.727-3.729,1.243-1.243Zm-13.667,1.243,1.606-1.584h2.485l-2.849,2.827Zm-5.848,5.851h2.485l-4.294,4.3-1.746-.739Zm8.685,8.7-3.543,3.578-.741-1.768,4.284-4.3Zm-5.322.362-1.242-1.243,3.727-3.729-1.242-1.243L-852.5,57.68l-1.242-1.243,13.667-13.673,3.727,3.729Zm12.811-7.822-1.63,1.607-1.242-1.243,2.872-2.85Zm0,0M-858.76,66.473-860,65.23l3.727-3.729,1.242,1.243Zm0,0M-860,60.258l2.485-2.486,1.242,1.243L-858.76,61.5Zm0,0,6.212,6.215-1.243-1.243,2.485-2.486,1.242,1.243Zm0,0,11.225-18.738,2.485-2.486,1.242,1.243-2.485,2.486Zm0,0"
        transform="translate(860.002 -36.473)"
        fill="#fff"
      />
    </svg>
  );
};
