import React from 'react'
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {CircularProgress,} from '@material-ui/core'

import styles from '../styles'
import {FavoriteList, FavoriteListOverview} from "../../../../../../../../../../FavoriteList";
import {FavoriteListResouce} from "../../../../../../../../../../../utils/api";
import {
  displayErrorSnackBar,
  displayWarningSnackBar
} from "../../../../../../../../../../../components/SnackbarProvider/actions";
import {getErrorMessage} from "../../../../../../../../../../../utils/utils";
import {
  SelectableInstrumentsTable
} from "../../../../../../../../../../../components/SelectForProductsComparisonModal/SelectForProductsComparisonModal";
import {DEFAULT_SELECT_PRODUCT_TABLE_ACTIONS_NAMES} from "../../../../../../../../../../../utils/constants";


function FavoriteListSelection(props) {

  const {
    classes,
    selectedItem,
    onDetailsClick,
    onInstrumentSelect,
    selectedInstruments,
    onSelectAll,
    instrumentsHandler,
    actionTitle,
    depot,
  } = props;

  const [loading, setLoading] = React.useState(false);

  const handleDetailsClick = async (item) => {
    setLoading(true);
    try {
      item = await FavoriteListResouce.getFavoriteListDetails(item.id);
      let items = item.assets;

      if(instrumentsHandler){
        [items,] = await instrumentsHandler.getCustodianAndFilterInstruments(items, true);
      }

      if (items.length) {
        onDetailsClick(item);
      } else {
        props.dispatch(displayWarningSnackBar('Das ausgewählte Favoritenlisten enthält keine Instrumente, die dem Risikoprofil des Kunden entsprechen, oder ist verfügbar bei der gewählten Depotbank.'))
      }
    } catch (error) {
      props.dispatch(displayErrorSnackBar(getErrorMessage(error)))
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loadingIndicator}/>
        </div>
      )}
      {!loading && (
        <>
          {selectedItem && (
            <FavoriteListOverview
              viewOnly
              selectedInstruments={selectedInstruments}
              favoriteList={selectedItem}
              onSelectAll={onSelectAll}
              onInstrumentSelect={onInstrumentSelect}
              instrumentsToFilterOut={props.instrumentsToFilterOut}
              isInstrumentsSelection
            />
          )}
          {!selectedItem && <FavoriteList
            viewOnly
            handleEditClick={handleDetailsClick}
            instrumentsToFilterOut={props.instrumentsToFilterOut}
            actionsNames={DEFAULT_SELECT_PRODUCT_TABLE_ACTIONS_NAMES}
          />}
        </>
      )}

    </>
  )
}

export default  connect() (withStyles(styles)(FavoriteListSelection));

