import React from 'react'
import { connect } from 'react-redux'
import withCustomersSelectorNew from "../../components/CustomersSelectorProviderNew";
import {hasPortfolios} from "../../components/CustomersSelectorProviderNew/utils";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useCustomerAppData} from "./hooks/useCustomerAppData";
import {useSelectedPortfolios} from "./hooks/useSelectedPortfolios";
import {usePortfoliosData} from "./hooks/usePortfoliosData";
import {executeIfPathExist, getInvestmentDynamicPath} from "../InvestmentPlatform/utils";
import Link from "../../components/Link";
import Navigation from "./components/Navigation";
import {CardHeader, CircularProgress, Container, makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CustomerSummaryCard from "./components/CustomerSummaryCard/CustomerSummaryCard";
import _ from "lodash";
import {isAggregatedPortfolioRiskMetricsLoading, isIndividualPortfolioRiskMetricsLoading} from "./utils";
import {useInstrumentsTableData} from "./hooks/useInstrumentsTableData";
import {DashboardSection} from "../CustomerDashboard/components/Widgets/components";
import InstrumentByListIndicatorList
  from "../CustomerDashboard/components/Widgets/components/InstrumentByRiskIndicatorList/InstrumentByListIndicatorList";
import AlertConfigurationWrapper from "./components/AlertConfigurationModal/AlertConfigurationModal";
import {useIndividualPortfoliosRiskMetricsData} from "./hooks/useIndividualPortfoliosRiskMetricsData";

import {CustomersAlertsList, onCustomersChange} from "./components/CustomersAlertsOverviewList";
import SelectFormElement from "../RiskProfiling/components/StepContent/components/formElement/SelectFormElement";
import {getInvestmentPlatformSelector} from "../../utils/redaxSelectors";

const useStyles = makeStyles((theme) => ({
  item: {
    borderRight: '2px solid #E1E3E9 !important',
    borderBottom: 'none !important',
    '&:last-child': {
      borderRight: 'none !important'
    },

    [theme.breakpoints.down('xs')]: {
      borderRight: 'none !important',
      borderBottom: '2px solid #E1E3E9 !important'
    }
  }
}));

function RiskDashboardAllCustomersDetails(props) {

  const routeMatch = useRouteMatch()

  const customerData = useCustomerAppData(routeMatch.params.customer_id, {
    with_last_srri: true,
    with_historical_portfolios: false,
  })
  const [selectedPortfolios, setSelectedPortfolios] = useSelectedPortfolios(customerData)
  const [portfoliosData, setPortfoliosData] = usePortfoliosData(
    routeMatch.params.customer_id, customerData, selectedPortfolios)
  const {instrumentsTableData, handleAlertConfigurationSaved} = useInstrumentsTableData(
    portfoliosData, setPortfoliosData, false)
  const [individualPortfoliosRiskMetricsData] = useIndividualPortfoliosRiskMetricsData(
    routeMatch.params.customer_id, customerData, selectedPortfolios)

  const [expandedItems, setExpandedItems] = React.useState({
    instrumentByListIndicator: [],
  });

  const classes = useStyles();

  const handleExpandedItemsChange = (expandedItem, value) => {
    setExpandedItems(prevState => ({
      ...prevState,
      [expandedItem]: value
    }));
  };

  const LeftNavigationContent = React.useCallback(() => {

    const history = useHistory();

    const handleBackLinkClick = () => {
      executeIfPathExist(props.investmentPlatform.routes, 'RISK_INDICATOR_ALL_CUSTOMERS_OVERVIEW', path => {
        history.push(`/${getInvestmentDynamicPath()}${path}`)
      })
    }

    return (
      <Link
        title="Zurück zu Kundenliste"
        icon={<i class="fa fa-chevron-left" aria-hidden="true"></i>}
        underline
        onClick={handleBackLinkClick}
      />
    )

  }, [props.investmentPlatform]);

  return (
    <AlertConfigurationWrapper
      customer_id={routeMatch.params.customer_id}
      onAlertConfigurationSaved={handleAlertConfigurationSaved}
      instrumentsRiskMetricsData={individualPortfoliosRiskMetricsData}
    >
      <Container className={'app-page-container'}>
        <Navigation
          title={"Alarme einstellen"}
          leftNavigationContent={<LeftNavigationContent />}
        />
        <Grid container spacing={3} style={{marginTop: 20}}>
          <Grid item xs={12}>
            <CustomerSummaryCard
              customer={_.get(customerData, 'data')}
              errors={_.get(customerData, 'errors')}
              loading={customerData.loading}
              isOverview
              classes={{
                item: classes.item
              }}
            >
              <CustomerSummaryCard.Contacts
                GridProps={{xs: 12, sm:6, md: 8}}
                withSuitabilityCheck={false}
              />
              <CustomerSummaryCard.DepotsSelection
                GridProps={{xs: 12, sm:6, md: 4, style: {alignItems: 'center'}}}
                selectedPortfolios={selectedPortfolios}
                onPortfoliosChange={setSelectedPortfolios}
              />
            </CustomerSummaryCard>
          </Grid>
          <Grid item xs={12}>
            <DashboardSection
              title='Einzeldepots'
              loading={customerData.loading || portfoliosData.loading}
              error={customerData.errors || portfoliosData.errors || instrumentsTableData[1]}
              content={(
                <InstrumentByListIndicatorList
                  data={instrumentsTableData[0] || []}
                  splitByRisk={false}
                  expandedItemKey={'instrumentByListIndicator'}
                  expandedItems={expandedItems}
                  handleExpandedItemsChange={handleExpandedItemsChange}
                  riskMetricsData={individualPortfoliosRiskMetricsData}
                />
              )}
            />
          </Grid>
        </Grid>
      </Container>
    </AlertConfigurationWrapper>
  )
}

function getButtonAttributes(customer) {
  return {
    disabled: !hasPortfolios(customer),
    title: 'Alarme einstellen'
  }
}

const YES_NO_SELECT_VALUES = {
  ALL: 1,
  YES: 2,
  NO: 3
}

const customersFilteringYesNoSelectOptions = [{
  value: YES_NO_SELECT_VALUES.ALL,
  label: 'Alle',
}, {
  value: YES_NO_SELECT_VALUES.YES,
  label: 'Ja',
}, {
  value: YES_NO_SELECT_VALUES.NO,
  label: 'Nein'
}]

const additionalFiltersRender = ({filters, onChange, classes}) => (
  <>
    <span style={{display: 'inline-block', width: '100%'}} />
    <Grid item xs={12} md={4} className={classes.inputContainer} style={{flexBasis: '100%'}}>
      <SelectFormElement
        label='Mit Investmentdepots'
        onChange={onChange('portfolios_exists')}
        value={filters.portfolios_exists || onChange('portfolios_exists')(YES_NO_SELECT_VALUES.YES) || null}
        options={customersFilteringYesNoSelectOptions}
        onChangeGetEvent={false}
      />
    </Grid>
    <Grid item xs={12} md={4} className={classes.inputContainer}>
      <SelectFormElement
        label='Mit Alarme'
        onChange={onChange('alerts_exists')}
        value={filters.alerts_exists || onChange('alerts_exists')(YES_NO_SELECT_VALUES.ALL) || null}
        options={customersFilteringYesNoSelectOptions}
        onChangeGetEvent={false}
      />
    </Grid>
  </>
)

export default withCustomersSelectorNew(
  'RISK_INDICATOR_ALL_CUSTOMERS_OVERVIEW', getButtonAttributes, onCustomersChange, undefined, CustomersAlertsList, additionalFiltersRender)(RiskDashboardAllCustomersDetails)