import React from "react";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import AspectCard from "../AspectCard";
import clsx from "clsx";
import _ from "lodash";
import FormHelperText from "@material-ui/core/FormHelperText";

const SelectAspects = ({onAnswerChange, question}) => {
    const classes = useStyles();

    const options = question.config.choices;

    const answers = question.answer;

    React.useEffect(() => {
        if (answers.length === 0) {
            options.forEach(option => {
                answers.push(option.uid);
            })
        }
    }, []);

    const handleChange = (option) => {
        const answer = !answers.includes(option.uid) ? [...answers, option.uid] : _.filter(answers, (i) => i !== option.uid);
        onAnswerChange(question.uid, answer);
    };

    return (
        <Card className={classes.wrapper}>
            <CardContent className={classes.container}>
                <Typography className={classes.title}>
                    {question.question_text}
                </Typography>
                {question.error && (
                    <FormHelperText
                        error={true}
                        classes={{
                            error: classes.errorContainer
                        }}>{question.error}</FormHelperText>
                )}
                <Typography className={classes.description}>
                    {question.question_info}
                </Typography>
                <div className={classes.aspects}>
                    {options.map((option) => {
                        return (
                            <AspectCard
                                key={option.uid}
                                text={option.text}
                                icon={answers.includes(option.uid) ? option.icon_dark : option.icon}
                                classNames={clsx(
                                    classes.item,
                                    answers.includes(option.uid) &&
                                    classes.selected
                                )}
                                onClick={() => {
                                    question.error = null; // clean error
                                    handleChange(option)
                                }}
                            />
                        );
                    })}
                </div>
            </CardContent>
        </Card>
    );
};


export default SelectAspects;
