import React from "react";
import _ from "lodash";

import {connect} from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./styles"
import Grid from "@material-ui/core/Grid";
import PortfolioTrade from "./components/PortfolioTrade";
import {
  buildPortfolioTradeTransactions,
  portfolioTransactionAmount, setEarliestPriceDates, setPortfoliosConfig, validatePortfolio,
} from "../../utils";
import {withEuroOrDash} from "../../../../utils/utils";
import {
  addCombinedPaymentPlanTradingOption,
  removeCombinedTradingOption,
  removePortfolioCombinedTradingOption,
  updatePortfolioCombinedTradingOption,
} from "../../../../components/TradingStore/actions";
import {TRADING_TYPE} from "../../../../components/TradingStore/constants";
import {prepareComponentsWithContractNumber} from "../../../CustomerDashboard/utils";
import SavingsPlanTrade from "./components/SavingsPlansTrade";
import Typography from "@material-ui/core/Typography";
import PayoutPlanTrade from "./components/PayoutPlansTrade";
import SwitchPlanTrade from "./components/SwitchPlansTrade";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {TRADING_OBJECT_TYPE_TRANSACTIONS, TRANSACTION_TYPE_VALUES} from "../../constants";
import {CircularProgress} from "@material-ui/core";
import {ModelPortfolioResource, QuestionnairesHandlerResource} from "../../../../utils/api";
import {displayErrorSnackBar} from "../../../../components/SnackbarProvider/actions";

const NORMAL_TRADING_ACCORDION_ID = 'transaction-trading'
const SAVINGS_PLANS_ACCORDION_ID = 'transaction-savings-plan'
const PAYOUT_PLANS_ACCORDION_ID = 'transaction-payout-plan'
const SWITCH_PLANS_ACCORDION_ID = 'transaction-switch-plan'

const TRADING_OBJECT_TYPE_ACCORDION_ID = {
  trading: NORMAL_TRADING_ACCORDION_ID,
  savings_plan: SAVINGS_PLANS_ACCORDION_ID,
  payout_plan: PAYOUT_PLANS_ACCORDION_ID,
  switch_plan: SWITCH_PLANS_ACCORDION_ID
}

const extendInstrumentsWithAssetInfo = async (instruments) => {

  let res = await ModelPortfolioResource.infoAssets(instruments.filter(i => i.isin).map(i => i.isin));

  instruments.map((pInstrument) => {
    const instrumentData = res.find((e) => e.isin === pInstrument.isin) || {};
    delete instrumentData.category;

    Object.assign(pInstrument, {...instrumentData});
  });

}

export const getPortfolioInstrumentsData = (transactions, clearDisabledByFilter=false) => {
  return (transactions || [])
    .map((instrument) => {
      const data = instrument.hasOwnProperty('data')
        ? instrument.data
        : instrument
      if (clearDisabledByFilter) {
        delete data.disabledByFilter;
        delete data.disabledByAcceptanceCriterias;
      }
      return data
    })
}

const ProcessWrapper = (withStyles(styles)(({classes, children, withProcessWrapper, title, extraBtns, ...wrapperProps}) => {
  if (!withProcessWrapper) {
    return children;
  }

  return (
    <Accordion
      classes={{root: classes.accordionRoot}}
      {...wrapperProps}
    >
      <AccordionSummary
        classes={{root: classes.accordionHeader}}
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container justify={'space-between'}>
          <Grid item><Typography className={classes.sectionHeader}>{title}</Typography></Grid>
          {extraBtns}
        </Grid>
      </AccordionSummary>
      <AccordionDetails classes={{root: classes.accordionDetails}}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}))

export const portfolioHasTransactions = (_portfolio, transactionsKeys) => {
  return _.some(transactionsKeys, (key) => !_.isEmpty(_portfolio.transactions[key]))
}

const portfoliosHasTransactions = (_portfolios, transactionsKeys) => {
  return _.some(_portfolios, (portfolio) => portfolioHasTransactions(portfolio, transactionsKeys))
}

const CombinedTradeStep = props => {
  const {
    classes,
    onAnswerChange,
    questions: [
      transactionsQuestion
    ],
    dataService,
    isVirtual,
    isNewVirtual,
    withProcessWrapper,
    showSectionsWithoutTransactions,
    showTotal,
    sectionsCollapsable,
    showTradingActions,
    isTransactionsEditing// flag to update logic to reuse component for virtual portfolio trade
  } = props;

  const [defaults, setDefaults] = React.useState({});
  const [expanded, setExpanded] = React.useState([]);
  // loading until init data
  const [loading, setLoading] = React.useState(true);

  const validatePortfolioTransactions = async (portfolio) => {

    for (let transactionType in portfolio.transactions) {

      if (['rebalancing', 'portfolio_builder'].includes(transactionType)) {
        continue
      }

      try {

        if (!portfolio.transactions || _.isEmpty(portfolio.transactions[transactionType])) {
          continue
        }

        const instruments = getPortfolioInstrumentsData(portfolio.transactions[transactionType])
        await extendInstrumentsWithAssetInfo(instruments)

      } catch (error) {
        props.dispatch(displayErrorSnackBar(error))
        console.error('Error during instruments validation.')
        console.error(error)
      }
    }

  }

  React.useEffect(() => {
    (async () => {
      if(!_.isEmpty(_.get(transactionsQuestion, 'portfolios', [])) && !transactionsQuestion.isTransactionsBuilt){
        portfolios = buildPortfolioTradeTransactions(
          transactionsQuestion,
          ['buy', 'sell', 'switch', 'savings_plan', 'payout_plan', 'switch_plan', 'rebalancing', 'portfolio_builder']);

        // should be used ONLY 1 time to init
        delete transactionsQuestion.portfolios;

        handlePortfolioChange();
      }

      if(!isVirtual) {
        // updates portfolio with bank data
        groupPortfolioInSPforModelPortfolio()
        portfolios.forEach((portfolio) => validatePortfolio(portfolio, ['switch']))
        setPortfoliosConfig(portfolios, transactionsQuestion.banksData, dataService.banksDataDepotTypes);
        for (let portfolio of portfolios) {
          if (portfolio.isModelportfolio) {
            continue
          }
          await validatePortfolioTransactions(portfolio)
        }
        onAnswerChange(transactionsQuestion.uid, portfolios);

        const res = await QuestionnairesHandlerResource.getCombinedTradingDefaults(dataService.customer_id)
        setDefaults(res);
      } else {
        // set earliest prices for instruments
        let instruments = [];

        portfolios.map(portfolio => {
          for (let transactionType of [TRANSACTION_TYPE_VALUES.BUY, TRANSACTION_TYPE_VALUES.SAVINGS_PLAN]) {

            if (!portfolio.transactions || _.isEmpty(portfolio.transactions[transactionType])) {
              continue
            }
            instruments = instruments.concat(_.filter(portfolio.transactions[transactionType], i => !i.earliestPrice));
          }
        });

        if (!_.isEmpty(instruments)) {
          await setEarliestPriceDates(instruments, props.dispatch);
        }
      }
      setLoading(false);

      setExpanded(() => {
        let expandedItems = [];
        Object.entries(TRADING_OBJECT_TYPE_TRANSACTIONS)
          .forEach(([process, transactions]) => {
            portfolios.forEach((portfolio) => {
              transactions.forEach((transaction) => {
                if (portfolio.transactions.hasOwnProperty(transaction) && !_.isEmpty(portfolio.transactions[transaction]) && !expandedItems.includes(TRADING_OBJECT_TYPE_ACCORDION_ID[process])) {
                  expandedItems.push(TRADING_OBJECT_TYPE_ACCORDION_ID[process])
                }
              })
            })
          });
        if(_.isEmpty(expandedItems)){
          expandedItems = [NORMAL_TRADING_ACCORDION_ID];

          if (!_.isEmpty(props.defaultExpandedSavingPlans)){
            expandedItems.push(SAVINGS_PLANS_ACCORDION_ID)
          }
        }

        setExpanded(expandedItems)
      })

    })()
  }, [props.triggerAccordionExpansionCheck]);

  let portfolios = _.get(transactionsQuestion, 'answer', []);

  const handlePortfolioChange = () => {
    portfolios.map(portfolio => {
      portfolio.errors = null; // clean error
      props.dispatch(updatePortfolioCombinedTradingOption(dataService.customer_id, portfolio.portfolioId, {
        transactions: portfolio.transactions,
        depot_fee: portfolio.depot_fee,
        discount: portfolio.discount
      }));
    });

    onAnswerChange(transactionsQuestion.uid, portfolios);
  };

  const updateDefaults = (data, key) => {
    setDefaults((_defaults) => ({
      ..._defaults,
      [key]: data
    }));

    // update all "NEW" items empty values with defaults
    portfolios.map(portfolio => {
      const transactions = portfolio.transactions[key] || [];
      transactions.map(item => {
        if(item.action === 'create'){
          Object.assign(item, {...data, ...item});
        }
      });
    });

    handlePortfolioChange();
  };

  const updateSavingsPlanDefaults = (data) => updateDefaults(data, 'savings_plan')
  const updatePayoutPlanDefaults = (data) => updateDefaults(data, 'payout_plan')
  const updateSwitchPlanDefaults = (data) => updateDefaults(data, 'switch_plan')

  const groupPortfolioInSPforModelPortfolio = () => {
    portfolios.forEach(portfolio => {
      if (portfolio.isModelportfolio) {
        ['savings_plan', 'payout_plan',].forEach((transactionKey) => {
          const result = prepareComponentsWithContractNumber(portfolio.data, false, transactionKey)
          if (!_.isEmpty(result)) {
            portfolio.data[transactionKey] = _.flatten(_.flatten(
              Object.values(result).map((mpSavingPlan) => [mpSavingPlan])))
          }
        })
      }
    })
  }

  const totalAmount = () => {
    const totalAmount = portfolios.reduce((accumulator, portfolio) => {
      const ptfAmount = ['buy', 'sell', 'savings_plan', 'payout_plan'].reduce((total, key) => {
        return total + portfolioTransactionAmount(portfolio, key);
      }, 0);

      return ptfAmount + accumulator;
    }, 0);

    return withEuroOrDash(totalAmount);
  };

  const isPortfolioExpanded = (index) => {

    if (!sectionsCollapsable) {
      return true
    }

    if (dataService.tradingType == TRADING_TYPE.NEW_BUY) {
      return index === 0
    }
    return true
  }

  const handleExpandedChange = (panel) => (event, isExpanded) => {
    setExpanded(prevState => {
      return isExpanded ? [...prevState, panel] : prevState.filter(i => i !== panel);
    });
  };

  const normalTradingPortfolios = portfolios.filter((portfolio) => _.get(portfolio, 'data.trading_enabled'))
  const savingPlansPortfolios = portfolios.filter((portfolio) => _.get(portfolio, 'data.saving_plan_trading_enabled'))
  const payoutPlansPortfolios = portfolios.filter((portfolio) => _.get(portfolio, 'data.payout_plan_trading_enabled'))
  const switchPlansPortfolios = portfolios.filter((portfolio) => _.get(portfolio, 'data.switch_plan_trading_enabled'))


  const showProcessSection = React.useCallback((_portfolios, transactionsKeys) => {
    return showSectionsWithoutTransactions || portfoliosHasTransactions(_portfolios, transactionsKeys)
  }, [showSectionsWithoutTransactions])


  if(loading) {
    return <div className={classes.loadingContainer}>
      <CircularProgress className={classes.loadingIndicator}/>
    </div>
  }

  return (
    <Grid container className="container-max-width-large">
      {normalTradingPortfolios.length > 0 && showProcessSection(normalTradingPortfolios, [TRANSACTION_TYPE_VALUES.BUY, TRANSACTION_TYPE_VALUES.SELL, TRANSACTION_TYPE_VALUES.SWITCH]) && (
        <ProcessWrapper
          key={'trading'}
          expanded={(expanded || []).includes(NORMAL_TRADING_ACCORDION_ID)}
          onChange={handleExpandedChange(NORMAL_TRADING_ACCORDION_ID)}
          classes={{root: classes.accordionRoot}}
          extraBtns={props.extraBtns}
          title={"Handeln Details"}
          withProcessWrapper={withProcessWrapper}
        >
          {normalTradingPortfolios.map((portfolio, index) => (
            <PortfolioTrade
              key={portfolio.portfolioId || portfolio.id}
              portfolio={portfolio}
              handlePortfolioChange={handlePortfolioChange}
              minDepositDate={transactionsQuestion.minDepositDate}
              customer_id={dataService.customer_id}
              dataService={dataService}
              expanded={isPortfolioExpanded(index)}
              defaultExpanded={props.defaultExpandedTrades}
              isVirtual={isVirtual}
              isNewVirtual={isNewVirtual}
              isCustomerApp={props.isCustomerApp}
              allowedTradeOptions={props.allowedPortfolioTradeOptions}
              triggerAccordionExpansionCheck={props.triggerAccordionExpansionCheck}
              resource={props.resource}
              showSectionsWithoutTransactions={showSectionsWithoutTransactions}
              showTotal={showTotal}
              sectionsCollapsable={sectionsCollapsable}
              showTradingActions={showTradingActions}
            />
          ))}
        </ProcessWrapper>
      )}

      {savingPlansPortfolios.length > 0 && showProcessSection(savingPlansPortfolios, [TRANSACTION_TYPE_VALUES.SAVINGS_PLAN]) && (
        <ProcessWrapper
          key={'savings-plan'}
          expanded={(expanded || []).includes(SAVINGS_PLANS_ACCORDION_ID)}
          onChange={handleExpandedChange(SAVINGS_PLANS_ACCORDION_ID)}
          title={"Sparplan Details"}
          withProcessWrapper={withProcessWrapper}
        >
          {savingPlansPortfolios.map((portfolio, index) => (
            <SavingsPlanTrade
              key={portfolio.portfolioId}
              portfolio={portfolio}
              handlePortfolioChange={handlePortfolioChange}
              customer_id={dataService.customer_id}
              defaults={defaults.savings_plan || {}}
              updateDefaults={updateSavingsPlanDefaults}
              dataService={dataService}
              addPaymentPlanOption={addCombinedPaymentPlanTradingOption}
              removePortfolioPaymentPlanOption={removePortfolioCombinedTradingOption}
              removePaymentPlanOption={removeCombinedTradingOption}
              isVirtual={isVirtual}
              isCustomerApp={props.isCustomerApp}
              defaultExpanded={props.defaultExpandedSavingPlans}
            />
          ))}
        </ProcessWrapper>
      )}
      {!isVirtual &&
      <>
        {payoutPlansPortfolios.length > 0 && showProcessSection(payoutPlansPortfolios, [TRANSACTION_TYPE_VALUES.PAYOUT_PLAN]) && (
          <ProcessWrapper
            key={'payout-plan'}
            expanded={expanded.includes(PAYOUT_PLANS_ACCORDION_ID)}
            onChange={handleExpandedChange(PAYOUT_PLANS_ACCORDION_ID)}
            title={"Entnahmeplan Details"}
            withProcessWrapper={withProcessWrapper}
          >
            {payoutPlansPortfolios.map((portfolio, index) => (
              <PayoutPlanTrade
                key={portfolio.portfolioId}
                portfolio={portfolio}
                handlePortfolioChange={handlePortfolioChange}
                customer_id={dataService.customer_id}
                defaults={defaults.payout_plan || {}}
                updateDefaults={updatePayoutPlanDefaults}
                dataService={dataService}
                addPaymentPlanOption={addCombinedPaymentPlanTradingOption}
                removePortfolioPaymentPlanOption={removePortfolioCombinedTradingOption}
                removePaymentPlanOption={removeCombinedTradingOption}
              />
            ))}
          </ProcessWrapper>
        )}
        {switchPlansPortfolios.length > 0 && showProcessSection(switchPlansPortfolios, [TRANSACTION_TYPE_VALUES.SWITCH_PLAN]) && (
          <ProcessWrapper
            key={'switch-plan'}
            expanded={expanded.includes(SWITCH_PLANS_ACCORDION_ID)}
            onChange={handleExpandedChange(SWITCH_PLANS_ACCORDION_ID)}
            title={"Tauschplan Details"}
            withProcessWrapper={withProcessWrapper}
          >
            {switchPlansPortfolios.map((portfolio, index) => (
              <SwitchPlanTrade
                key={portfolio.portfolioId}
                portfolio={portfolio}
                handlePortfolioChange={handlePortfolioChange}
                customer_id={dataService.customer_id}
                defaults={defaults.switch_plan || {}}
                updateDefaults={updateSwitchPlanDefaults}
                dataService={dataService}
                addPaymentPlanOption={addCombinedPaymentPlanTradingOption}
                removePortfolioPaymentPlanOption={removePortfolioCombinedTradingOption}
                removePaymentPlanOption={removeCombinedTradingOption}
              />
            ))}
          </ProcessWrapper>
        )}
      </>
      }
      {showTotal && (
        <Grid item container className={classes.totalAmount}>
          <Grid item>Gesamtbetrag</Grid>
          <Grid item>{totalAmount()}</Grid>
        </Grid>
      )}
    </Grid>
  )
};

CombinedTradeStep.defaultProps = {
  /**
   * Flag to show/hide accordion with process type details (Normal Trading/ Savings Plans / etc.)
   */
  withProcessWrapper: true,

  /**
   * Flag to hide sections without active transactions.
   * Note: In case transactions list is empty and this flag = false - all sections will be hidden
   */
  showSectionsWithoutTransactions: true,
  showTotal: true,
  sectionsCollapsable: true,
  showTradingActions: true
}

export default connect()(withStyles(styles)(CombinedTradeStep));