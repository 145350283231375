import { makeStyles } from '@material-ui/core';

const styles = theme => ({
  tableCell: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    '&:nth-of-type(1)': {
      width: 350,
    },
    '&:nth-of-type(2)': {
      width: 83,
    },
    '&:nth-of-type(3)': {
      width: 34,
    },
    '&:nth-of-type(4), &:nth-of-type(7)': {
      width: 115,
    },
    '&:nth-of-type(5), &:nth-of-type(8)': {
      width: 125,
    },
    '&:nth-of-type(6), &:nth-of-type(9)': {
      padding: '0 1rem',
      width: 114,
      verticalAlign: 'middle',
      '& .MuiInput-root': {
        width: 82,
        height: 32,
        marginBottom: 0,
        padding: 0,
        textAlign: 'right',
        backgroundColor: '#fff',
        border: '1px solid #ADB6BD',
      },
    },
    [theme.breakpoints.down('md')]: {
     '&:nth-of-type(1)':{
        width: 300,
     }
    }
  },
});

export default makeStyles(styles)
