import React from 'react';
import _ from 'lodash';
import {
  Tabs,
  Tab,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

function DocumentEditorTabs(props) {
  const classes = useStyles();
  const {
    activeDocumentId,
    selectedDocuments,
    downloadedDocuments,
    pageRestoring,
    handleTabChange,
    handleTabClose
  } = props;

  const handleChange = (event, newValue) => {
    handleTabChange(newValue);
  };

  return (
      <Tabs
        value={activeDocumentId}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="editor tabs"
      >
        {
          selectedDocuments.map((document) => (
            <Tab
              disabled={pageRestoring}
              key={document.id}
              value={document.id}
              component='div'
              label={
                <div className={classes.tabLabel}>
                  <Tooltip
                    enterDelay={1000}
                    enterTouchDelay={1000}
                    enterNextDelay={1000}
                    arrow={true}
                    title={document.name.length > 25 ? document.name : ''}
                    placement="top"
                    classes={{ tooltip: classes.deleteHelperTitle }}
                  >
                    <Typography
                      noWrap
                      classes={{ root: classes.labelText }}
                      display="inline"
                    >
                      {document.name}
                    </Typography>
                  </Tooltip>
                  {downloadedDocuments.loading && !_.find(downloadedDocuments.data, {'id': document.id}) ? <CircularProgress size={22}/> :
                    <IconButton onClick={() => handleTabClose(document.id)} edge="end" size="small">
                      <CloseIcon />
                    </IconButton>
                  }
                </div>
              }
            />
          ))
        }
      </Tabs>
  );
}

DocumentEditorTabs.defaultProps = {
  selectedDocuments: []
};

export default DocumentEditorTabs;
