import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import { getInstrName } from '../../../../Modelportfolios/utils';
import styles from './styles';
import { DEFAULT_EMPTY_SECTION_MESSAGE } from '../../../../CustomerDashboardV2/constants';
import ErrorMessage from '../../ChartSectionBordered/components/ErrorMessage';
import CorrelationTableLegend
  from '../../../../../components/ChartsV2/CorrelationTable/components/CorrelationTableLegend';
import CorrelationTable from '../../../../../components/ChartsV2/CorrelationTable';

class CorrelationMatrix extends React.Component {

  getAssetData() {
    try {
      let assets =  this.props.portfolioData['portfolio_data']['data'][0]['components'];
      if (!_.isEmpty(this.props.selectedAssets)) {
        assets = assets.filter((asset) => this.props.selectedAssets.includes(asset.id))
      }
      return assets
    } catch (e) {
      return []
    }
  }

  renderChart() {

    const { classes } = this.props;

    let assetsData = this.getAssetData();

    if (assetsData.length <= 1) {
      return <div className={classes.container}>
        Keine Daten verfügbar.
      </div>
    }

    let mapping = {};

    assetsData.forEach((item) => {
      const assetInfo = item['financial_information']['asset_info_data'];

      const name = getInstrName(assetInfo);

      mapping[assetInfo['isin']] = {
        name: name ? name : assetInfo['isin'],
        weight: item['weight']
      };
    });

    return <div>
      <CorrelationTable correlationData={this.props.data} productsData={mapping} />
      <CorrelationTableLegend />
    </div>
  }

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.container}>
        {this.props.error ? <ErrorMessage error={this.props.error}/> : (this.props.data && this.props.portfolioData) ? this.renderChart() : <ErrorMessage error={DEFAULT_EMPTY_SECTION_MESSAGE}/>}
      </div>
    )
  }
}

export default withStyles(styles)(CorrelationMatrix)