import React from 'react';
import { connect } from 'react-redux';

/* BCA Components */
import LoadingContainer from '../LoadingContainer';

/* BCA modules */
import useStyles from '../styles';
import {withPercentOrDash} from "../../../../utils/utils";


import { getHostRelatedSetting } from "../../../../utils/sharedSettings"
import {NOT_FOUND} from "../../../../components/SharedSettingsProvider/constants"
import WarningTooltip from "../../../WarningTooltip"
import {getSharedSettingsSelector} from "../../../DashboardDataProvider/DashboardDataProvider";
import _ from 'lodash';
import clsx from "clsx";
import {Grid} from "@material-ui/core";


const mapStateToProps = (state) => ({
  sharedSettings: getSharedSettingsSelector(state)
});

export const TotalReturn = props => {
  const classes = useStyles();

  const {
    data,
    loading,
    styles,
    sharedSettings
  } = props;

  const customClasses = props.customClasses || {}

  const renderGeneralReturn = () => {
    return (
      <div className={classes.subContent}>
        <p className={classes.subHeader}>
          Gesamtrendite
        </p>

        <div className={classes.dataWrap}>
          <p
            className={clsx(classes.value, customClasses.value)}
            style={{
              color: data.ydt.percentage < 0 ? '#f00' : '#5EC14E'
            }}
          >
            {withPercentOrDash(data.ydt.percentage || 0, true)}
          </p>
        </div>
      </div>
    )
  }

  const renderPAReturn = () => {
    const percentage_pa = _.get(data, 'ydt.percentage_pa', 0);
    return (
      <div className={classes.subContent}>
        <p className={classes.subHeader}>
          Rendite p.a.
        </p>

        <div className={classes.dataWrap} style={{height: '28px'}}>
          <p
            className={clsx(classes.value, customClasses.value)}
            style={{
              color: percentage_pa < 0 ? '#f00' : '#5EC14E',
            }}
          >
            {_.get(data, 'period_pa_warning') && sharedSettings
              ? <WarningTooltip title={getHostRelatedSetting(sharedSettings.data, data.period_pa_warning) || NOT_FOUND} size={20} />
              : withPercentOrDash(percentage_pa, true)
            }
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      { loading ? (
        <LoadingContainer/>
      ) : (
        <React.Fragment>
          {data && (
            <React.Fragment>
              <Grid container className={customClasses.headerWrapper}>
                <Grid item xs={12}
                  className={clsx(classes.header, customClasses.header)}
                  style={styles && styles.header ? styles.header : {}}
                >
                  Rendite Zeitraum
                  <WarningTooltip
                    placement={'left'}
                    title={"Berechnet nach geldgewichteter Methode bzw. nach Modified Dietz."}
                    iconClassName={classes.helpIcon}
                    size={16}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} className={clsx(classes.subContainer, customClasses.subContainer)}>
                    <Grid item xs={8} sm={7} md={"auto"}><Grid container><Grid item xs={'auto'}>{renderGeneralReturn()}</Grid></Grid></Grid>
                    <Grid item xs={4} sm={5} md={"auto"}>{renderPAReturn()}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(TotalReturn)