import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import {
  Grid,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  withStyles
} from '@material-ui/core'

import { toGermanFormat } from '../../../../../../utils/numberFormater'

import useStyles from './styles'

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#F5F6F7',
    borderBottom: 'none',
    marginBottom: -1,
    minHeight: 41,
    '&$expanded': {
      minHeight: 41,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: '0px 16px 5px',
  },
}))(MuiAccordionDetails);

function ExAnteSection(props) {

  const {
    index,
    title,
    euroValue,
    percentValue,
    children,
    footer
  } = props

  const classes = useStyles()

  return (
    <Accordion square expanded>
      <AccordionSummary aria-controls={`ex-ante-${Date.now()}`} style={{backgroundColor: footer ? '#D8DCDF' : '#F5F6F7'}}>
        <Grid container spacing={2}>
          <Grid item xs={1}> <span className={clsx(classes.label, classes.alignRight, footer && classes.textBold)}> { index } </span> </Grid>
          <Grid item xs={5}> <span className={clsx(classes.label, footer && classes.textBold)}> { title } </span> </Grid>
          <Grid item xs={2}>
            <span className={clsx(classes.label, classes.alignRight, footer && classes.textBold)}>
              { (percentValue || percentValue == 0) ? toGermanFormat(percentValue * 100, undefined, ' %') : '' }
            </span>
          </Grid>
          <Grid item xs={3}>
            <span className={clsx(classes.label, classes.alignRight, footer && classes.textBold)}>
              { (euroValue || euroValue == 0) ? toGermanFormat(euroValue, undefined, ' €') : '' }
            </span>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid style={{width: '100%'}}>
          { children }
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

ExAnteSection.propTypes = {

  /** Section index */
  index: PropTypes.string,

  /** Section title */
  title: PropTypes.string.isRequired,

  /** Value in EUR */
  euroValue: PropTypes.number.isRequired,

  /** Value in % */
  percentValue: PropTypes.number.isRequired,

  /** Flag, that indicate, if section is footer, or not */
  footer: PropTypes.bool
}

ExAnteSection.defaultProps = {
  footer: false
}


export default ExAnteSection

