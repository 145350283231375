import React from "react";

export default () => {
  return (
    <svg
      id="Gruppe_8198"
      data-name="Gruppe 8198"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_8056"
            data-name="Rechteck 8056"
            width="30"
            height="30"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_8181" data-name="Gruppe 8181" clip-path="url(#clip-path)">
        <path
          id="Pfad_7825"
          data-name="Pfad 7825"
          d="M9.043,17.56a3.58,3.58,0,0,1-.765-2.449v-.334a4.083,4.083,0,0,1,.346-1.737,2.616,2.616,0,0,1,.995-1.15,2.774,2.774,0,0,1,1.5-.406,2.913,2.913,0,0,1,1.976.647,2.558,2.558,0,0,1,.849,1.783H12.322a1.211,1.211,0,0,0-.311-.886,1.278,1.278,0,0,0-.891-.269.973.973,0,0,0-.891.454,2.926,2.926,0,0,0-.3,1.452v.478a3.235,3.235,0,0,0,.271,1.545.994.994,0,0,0,.935.464A1.251,1.251,0,0,0,12,16.888a1.122,1.122,0,0,0,.315-.84h1.619a2.467,2.467,0,0,1-.38,1.245,2.3,2.3,0,0,1-.977.837,3.321,3.321,0,0,1-1.436.3,2.652,2.652,0,0,1-2.1-.867M8.617,9.038a3.867,3.867,0,0,1-.392-1.693V6.965a4,4,0,0,1,.364-1.751A2.7,2.7,0,0,1,9.633,4.043a3.089,3.089,0,0,1,3.078,0A2.75,2.75,0,0,1,13.756,5.2a3.888,3.888,0,0,1,.378,1.728v.311a3.985,3.985,0,0,1-.366,1.749,2.71,2.71,0,0,1-1.041,1.171,2.9,2.9,0,0,1-1.537.41,2.941,2.941,0,0,1-1.531-.4A2.705,2.705,0,0,1,8.617,9.038m1.264-1.8a2.924,2.924,0,0,0,.329,1.526,1.076,1.076,0,0,0,.979.533,1.064,1.064,0,0,0,.951-.524,2.84,2.84,0,0,0,.339-1.508v-.31a2.843,2.843,0,0,0-.336-1.529,1.079,1.079,0,0,0-.963-.522q-1.225,0-1.294,1.836ZM15,28.124H8.028a3.207,3.207,0,0,1-3.2-3.2V6.314H0V3.206A3.209,3.209,0,0,1,3.206,0H20.094A3.209,3.209,0,0,1,23.3,3.206v9.955c-.264-.023-.53-.036-.8-.036s-.527.014-.786.035V3.206a1.622,1.622,0,0,0-1.62-1.62H6.411V24.92a1.621,1.621,0,0,0,3.242,0v-3.9h3.59a9.434,9.434,0,0,0-.118,1.487c0,.033,0,.066,0,.1H11.239v3.94h2.8A9.341,9.341,0,0,0,15,28.124M4.825,3.206a1.62,1.62,0,1,0-3.239,0V4.728H4.825Zm9.917,14.029a9.416,9.416,0,0,1,1.628-1.825V12.839h.547a1.289,1.289,0,0,1,1.1.5,1.862,1.862,0,0,1,.309.773,9.323,9.323,0,0,1,1.5-.592,3.265,3.265,0,0,0-.176-.382,2.857,2.857,0,0,0-1.115-1.146,3.2,3.2,0,0,0-1.61-.406H14.742ZM19.666,4.98V3.723H15v6.753h4.652V9.223H16.632V7.609h2.56V6.4h-2.56V4.98ZM30,22.5A7.5,7.5,0,1,1,22.5,15,7.5,7.5,0,0,1,30,22.5m-4.246,4.58L17.92,19.246a5.616,5.616,0,0,0,7.834,7.834m2.371-4.58a5.616,5.616,0,0,0-8.879-4.58l7.834,7.834A5.587,5.587,0,0,0,28.125,22.5"
          fill="#80858c"
        />
      </g>
    </svg>
  );
};
