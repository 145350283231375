const styles = theme => ({
  expandIconButton: {
    height: 40,
    width: 40
  },
  expandButton: {
    borderRadius: 2,
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    boxShadow: 'none',
    minWidth: 150,

    '&:focus': {
      boxShadow: 'none',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },

    [theme.breakpoints.only('xs')]: {
      minWidth: 60,
      padding: 4
    },
  },
  rotateIcon: {
    fontSize: 14,
    color: 'white',
    marginRight: 10,

    [theme.breakpoints.only('xs')]: {
      margin: 0
    },
  },
  expandButtonText: {
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    },
  }
});


export default styles;