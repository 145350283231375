import {makeStyles} from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {},
  label: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
  },
  reportSettingsButton: {
    color: theme.palette.primary.main,
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: 'auto',

    '& > span:first-child': {
      marginRight: 2,
    },
  },
  disabled: {
    filter: 'grayscale(1)',
    pointerEvents: 'none',
    cursor: 'initial'
  },
}))