import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import _ from 'lodash'

import {
  TextField,
  InputAdornment,
  Fade
} from '@material-ui/core';

import {
  Widget
} from '../index';

import useStyles from './styles';
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
/* Material-UI Icons */
import ErrorIcon from '@material-ui/icons/Error';

import Tooltip from "@material-ui/core/Tooltip";

function DropdownWidget(props) {
  const {
    children,
    ...restProps
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.dropdownWidget} {...restProps}>
      {children}
    </div>
  )
}

function DropdownInput(props) {

  const {
    value,
    placeholder,
    icon,
    iconPosition,
    dropdownContent,
    dropdownVisible,
    isOptionsLoading,
    onChange,
    onBlur,
    onValueApprove,
    label,
    labelPosition,
    style,
    disabled,
    inputStyle,
    labelStyle,
    type,
    inPopover,
    autoApprove,
    error,
    min,
    max,
    step,
    inputComponent,
    inputAdornment,
    isSearchDisabled,
    showErrorAsTooltip,
    dropdownValue
  } = props;

  const classes = useStyles();

  const node = React.useRef();

  const [isDropdownVisible, setDropdownVisibility] = React.useState(dropdownVisible || false)
  const [inputTimeout, setInputTimeout] = React.useState()

  const labelStyleCopy = labelStyle || {};

  React.useEffect(() => {
    if (!isDropdownVisible) {
      onBlur && onBlur()
    }
  }, [isDropdownVisible])

  const handleValueChange = (event) => {
    let value = event.target.value
    if (onChange) {
      onChange(value);
    }

    if (autoApprove) {
      if (inputTimeout) {
        clearTimeout(inputTimeout);
        setInputTimeout(undefined)
      }
      setInputTimeout(setTimeout(() => {
        if (onValueApprove) {
          onValueApprove(value)
        }
      }, 500))
    }
  }

  const handleKeyPress = (event) => {
    if (event.which == 13 || event.keyCode == 13) {

      if (inputTimeout) {
        clearTimeout(inputTimeout);
        setInputTimeout(undefined)
      }
      
      if (!isSearchDisabled && onValueApprove) {
        onValueApprove();
      }
      return false;
    }
    return true;
  }

  const handleClose = () => {
    if(!isOptionsLoading){
      setDropdownVisibility(false)
    }
  };

  function setWidth (data) {
    data.instance.popper.style.width = `${data.instance.reference.offsetWidth}px`;

    return data;
  }

  const getErrors = (errorMessageClassName='') => {
    return(
      <>
        {_.isArray(error) ? (
          <>
            {
              error.map(message => (
                <p className={errorMessageClassName}> {message} </p>
              ))
            }
          </>
          ) : (
            <p className={errorMessageClassName}> {error} </p>
          )
        }
    </>
    )
  }

  return (
    <>
    <ClickAwayListener onClickAway={handleClose}>
      <div className={clsx(classes.container, props.fullWidth && classes.fullWidth)} ref={node}>
        {label && labelPosition == 'left' && (
          <span className={clsx([classes.label, props.classes && props.classes.label])} style={{marginRight: 12, ...labelStyleCopy}} >{ label }</span>
        )}
        <TextField
          variant="outlined"
          value={value}
          placeholder={placeholder}
          onChange={handleValueChange}
          onClick={() => !disabled && setDropdownVisibility(true)}
          onKeyPress={handleKeyPress}
          disabled={disabled}
          type={type}
          error={!!error}

          style={{width: props.width || 'inherit', height: props.height || 'inherit'}}

          classes={{
            root: classes.root,
          }}
          className={(!_.isEmpty(dropdownValue) && !disabled) && classes.darkPlaceholder}

          inputProps={{
            className: classes.input,
            style: inputStyle || {},
            type,
            min: min || null,
            max: max || null
          }}

          InputProps={{
            endAdornment: icon && (iconPosition == 'end' || !iconPosition) ? (
              <InputAdornment>
                {inputAdornment ?
                  inputAdornment(props, classes)
                : (<> {icon} </>)
                }
              </InputAdornment>
            ) : undefined,
            startAdornment: icon && iconPosition == 'start' ? (
              <InputAdornment>
                {icon}
              </InputAdornment>
            ) : undefined,
            classes: {
              focused: !inputAdornment && classes.focused,
              disabled: classes.disabled
            },
            type,
            inputComponent: inputComponent,
            inputProps: {
              step,
              allowNegative: min !== 0,
              style: inputStyle || {}
            }
          }}
        />
         {label && labelPosition == 'right' && (
          <span className={clsx([classes.label, props.classes && props.classes.label])} style={{marginLeft: 12, ...labelStyleCopy}}>{ label }</span>
        )}
        {inPopover && (
          <Popper className={classes.dropdownPopper} open={dropdownContent && isDropdownVisible} anchorEl={node.current}
            placement={"bottom-start"}
            /*  fix overlap input field for some resolutions, where 50 its option computed height, 20 is padding */
            style={{maxHeight: (Math.floor((document.body.clientHeight / 2) / 50) * 50) + 20}}
            modifiers={{
              setPopperWidth: {
                enabled: true,
                order: 849,
                fn: setWidth,
              }
            }}
          >
            {dropdownContent}
          </Popper>
        )}
        {!inPopover && (
          <Fade in={dropdownContent && isDropdownVisible} timeout={250} style={{zIndex: 999}}>
            <DropdownWidget>
              <Widget padding={0}>
                {dropdownContent}
              </Widget>
            </DropdownWidget>
          </Fade>
        )}

        {error && showErrorAsTooltip ? (
            <Tooltip style={{marginLeft: '12px'}} arrow placement={"top"} title={getErrors(classes.errorMessageTooltip)}>
              <ErrorIcon color={"error"} ></ErrorIcon>
            </Tooltip>
        ) : (
            getErrors(classes.errorMessage)
        ) }

      </div>
    </ClickAwayListener>
    </>
  )
}

DropdownInput.propTypes = {
  /** Text field label */
  label: PropTypes.string,

  /** Label position */
  labelPosition: PropTypes.oneOf(['left', 'right']),

  /** Text field value */
  value: PropTypes.string,

  /** Value change handler */
  onChange: PropTypes.func,

  /** Value approve handler */
  onValueApprove: PropTypes.func,

  /** Text field placeholder */
  placeholder: PropTypes.string,

  /** Text field icon */
  icon: PropTypes.element,

  /** Icon position*/
  iconPosition: PropTypes.oneOf(['start', 'end']),

  /** Content, that will be displayed in dropdown */
  dropdownContent: PropTypes.element,

  /** Flag, that indicate, if dropdown is visible, or not */
  dropdownVisible: PropTypes.bool,

  /** Input width */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Full container width flag */
  fullWidth: PropTypes.bool
}

DropdownInput.defaultProps = {
  fullWidth: true,
  labelPosition: 'left',
  disabled: false,
  autoApprove: true
}

export default DropdownInput

