import React from "react";
import Info from "./components/StepContent/components/step/EsgProfile/Info";
import SettingsProfile from "./components/StepContent/components/step/EsgProfile/SettingsProfile";
import SelectProfile from "./components/StepContent/components/step/EsgProfile/SelectProfile";
import Summary from "./components/StepContent/components/step/EsgProfile/Summary";

import {
    OneAspect,
    TwoAspect,
    ThreeAspect,
    FourAspect,
    FiveAspect,
    SixAspect,
    SevenAspect,
    EightAspect,
    NineAspect,
    TenAspect,
    ElevenAspect,
    TwelveAspect,
    ThirteenAspect,
    FourteenAspect,
    FifteenAspect,
    OneAspectDark,
    TwoAspectDark,
    ThreeAspectDark,
    FourAspectDark,
    FiveAspectDark,
    SixAspectDark,
    SevenAspectDark,
    EightAspectDark,
    NineAspectDark,
    TenAspectDark,
    ElevenAspectDark,
    TwelveAspectDark,
    ThirteenAspectDark,
    FourteenAspectDark,
    FifteenAspectDark

} from "../../images"



const step1 = {
    "uid": "esg-profile-selection-step",
    "name": "Nachhaltigkeitsprofilierung",
    "custom_classes": { stepName: "text-left" },
    "behaviour": ["dynamic", "Stammdaten"],
    "step_content": SelectProfile,
    "question": [
        {
            "uid": "esg-advisor-type",
            "type": "normal",
            "behaviour": ["radio"],
            "optional": true,
            "hidden": true,
            "options": [
                {
                    "id": 1,
                    "value": "yes",
                    "label": "Ja, ich möchte ein ESG Profil anlegen"
                },
                {
                    "id": 2,
                    "value": "no",
                    "label": "Nein, ich möchte kein ESG Profil anlegen",
                }
            ],
        },
        {
            "uid": "esg-profile-radio-uid",
            "type": "normal",
            "behaviour": ["radio"],
            "custom_classes": {'labelRoot': 'bold'},
            "optional": true,
            "options": [
                {
                    "id": 1,
                    "value": "new",
                    "label": "Neues Nachhaltigkeitsprofil anlegen"
                },
                {
                    "id": 2,
                    "value": "not-interested",
                    "label": "Der Kunde wünscht keine Nachhaltigkeitsprofilierung",
                },
                {
                    "id": 3,
                    "value": "prefill",
                    "label": "Bereits durchgeführte Nachhaltigkeitsprofilierung anpassen",
                },
                {
                    "id": 4,
                    "value": "select",
                    "label": "Bereits durchgeführte Nachhaltigkeitsprofilierung auswählen",
                },
            ],
        },
        {
            "uid": "esg-profile-select-uid",
            "type": "normal",
            "behaviour": ["select"],
            "optional": true,
        },
    ],
};

const step2 = {
    "uid": "esg-info-profile",
    "name": "Nachhaltigkeitsprofilierung",
    "custom_classes": { stepName: "text-left" },
    "info":
        "In den nächsten Schritten wird angefragt welche Bereiche der Nachhaltigkeit für ihren Kunden von Relevanz sind. Ihr Kunde kann bis zu 3 Bereiche wählen und eine individuelle Gewichtung festlegen.",
    step_content: Info,
    "question": [
        {
            "uid": "esg-info",
            "answer": "answer",
            "type": "normal",
            "question_text": "Nachhaltigkeitsprofilierung",
            "behaviour": ["radio"],
            "optional": true,
            "config": {
                "choices": [
                    {
                        "id": 1,
                        "title": "1. Ökologisch Nachhaltig",
                        "text": "Nachhaltige Investition gemäss Taxonomieverordnung. Leistet u.a. einen wesentlichen Beitrag zur Verwirklichung eines oder mehrerer Umweltziele.",
                    },
                    {
                        "id": 2,
                        "title": "2. ESG",
                        "text": "Nachhaltige Investition gemäss Offenlegungsverordnung. Trägt zur Erreichung eines Umweltziels oder eines sozialen Ziels bei und investiert in Unternehmen, die Verfahrensweisen einer guten Unternehmensführung anwenden.",
                    },
                    {
                        "id": 3,
                        "title": "3. PAI",
                        "text": "Investition berücksichtigt die wichtigsten nachteiligen Auswirkungen auf Nachhaltigkeitsfaktoren. Treibhausgasemissionen, Biodiversität, Wasser, Abfall, Soziale und Arbeitnehmerbelange.",
                    },
                ],
            },
        },
    ],
};

const step3 = {
    "uid": "ecologically-sustainable",
    "name": "Nachhaltigkeitsprofilierung",
    "custom_classes": { stepName: "text-left" },
    "info": "Bitte legen Sie eine Gewichtung fest.",
    "step_content": SettingsProfile,
    "behaviour": ["dynamic", "Nachhaltigkeitsprofilierung"],
    "question": [
        {
            "uid": "cards",
            "type": "permission",
            "behaviour": ["checkbox_list"],
            "question_text": "1. Ökologisch Nachhaltig",
            "question_info":
                "Nachhaltige Investition gemäss Taxonomieverordnung. Leistet u.a. einen wesentlichen Beitrag zur Verwirklichung eines oder mehrerer Umweltziele.",
            "optional": true,
            "config": {
                "choices": [
                    {
                        "uid": "ecologically-sustainable",
                        "title": "1. Ökologisch Nachhaltig",
                        "sub_title":
                            "Nachhaltige Investition gemäss Taxonomieverordnung. Leistet u.a. einen wesentlichen Beitrag zur Verwirklichung eines oder mehrerer Umweltziele.",
                    },
                    {
                        "uid": "esg",
                        "title": "2. ESG",
                        "sub_title":
                            "Nachhaltige Investition gemäss Offenlegungsverordnung. Trägt zur Erreichung eines Umweltziels oder eines sozialen Ziels bei und investiert in Unternehmen, die Verfahrensweisen einer guten Unternehmensführung anwenden.",
                    },
                    {
                        "uid": "pai",
                        "title": "3. PAI",
                        "sub_title":
                            "Investition berücksichtigt die wichtigsten nachteiligen Auswirkungen auf Nachhaltigkeitsfaktoren. Treibhausgasemissionen, Biodiversität, Wasser, Abfall, Soziale und Arbeitnehmerbelange.",
                    },
                ],
            },
        },
        {
            "uid": "percent",
            "type": "normal",
            "behaviour": ["slider"],
            "question_text":
                "Wieviel Prozent des Kundenportfolios sollten idealerweise diese Vorgabe erfüllen",
            "optional": true,
        },
        {
            "uid": "aspects",
            "type": "permission",
            "behaviour": ["checkbox_list"],
            "question_text": "Welche Aspekte möchte ihr Kunde gezielt unterstützen?",
            "question_info":
                "Filter die hier ausgewählt sind, werden später in der Übersicht der Produktauswahl berücksichtigt und entsprechende Produkte werden gekennzeichnet.",
            "optional": true,

            "config": {
                "choices": [
                    {
                        "uid": 1,
                        "icon": <OneAspect />,
                        "icon_dark": <OneAspectDark />,
                        "text": "Klimaschutz",
                    },
                    {
                        "uid": 2,
                        "icon": <TwoAspect />,
                        "icon_dark": <TwoAspectDark />,
                        "text": "Anpassung an den Klimawandel",
                    },
                    {
                        "uid": 3,
                        "icon": <ThreeAspect />,
                        "icon_dark": <ThreeAspectDark />,
                        "text": "Nachhaltige Nutzung und Schutz von Wasser- und Meeresressourcen",
                    },
                    {
                        "uid": 4,
                        "icon": <FourAspect />,
                        "icon_dark": <FourAspectDark />,
                        "text": "Übergang zu einer Kreislaufwirtschaft",
                    },
                    {
                        "uid": 5,
                        "icon": <FiveAspect />,
                        "icon_dark": <FiveAspectDark />,
                        "text": "Vermeidung und Verminderung der Umweltverschmutzung",
                    },
                    {
                        "id": 6,
                        "uid": 6,
                        "icon": <SixAspect />,
                        "icon_dark": <SixAspectDark />,
                        "text": "Schutz und Wiederherstellung der Biodiversität und der Ökosysteme",
                    },
                ],
            },
        },
    ],
};

const step4 = {
    "uid": "esg",
    "name": "Nachhaltigkeitsprofilierung",
    "custom_classes": { stepName: "text-left" },
    "info": "Bitte legen Sie eine Gewichtung fest.",
    "behaviour": ["dynamic", "Nachhaltigkeitsprofilierung"],
    step_content: SettingsProfile,
    "question": [
        {
            "uid": "cards",
            "type": "permission",
            "behaviour": ["checkbox_list"],
            "question_text": "1. Ökologisch Nachhaltig",
            "question_info":
                "Fokus ist die Umwelt und natürlichen Ressourcen zu schonen.",
            "optional": true,
            "config": {
                "choices": [
                    {
                        "uid": "ecologically-sustainable",
                        "title": "1. Ökologisch Nachhaltig",
                        "sub_title":
                            "Nachhaltige Investition gemäss Taxonomieverordnung. Leistet u.a. einen wesentlichen Beitrag zur Verwirklichung eines oder mehrerer Umweltziele.",
                    },
                    {
                        "uid": "esg",
                        "title": "2. ESG",
                        "sub_title":
                            "Nachhaltige Investition gemäss Offenlegungsverordnung. Trägt zur Erreichung eines Umweltziels oder eines sozialen Ziels bei und investiert in Unternehmen, die Verfahrensweisen einer guten Unternehmensführung anwenden.",
                    },
                    {
                        "uid": "pai",
                        "title": "3. PAI",
                        "sub_title":
                            "Investition berücksichtigt die wichtigsten nachteiligen Auswirkungen auf Nachhaltigkeitsfaktoren. Treibhausgasemissionen, Biodiversität, Wasser, Abfall, Soziale und Arbeitnehmerbelange.",
                    },
                ],
            },
        },
        {
            "uid": "percent",
            "type": "normal",
            "behaviour": ["slider"],
            "question_text":
                "Wieviel Prozent des Kundenportfolios sollten idealerweise diese Vorgabe erfüllen",
            "question_info":
                "(Umwelt-, Sozial- und Unternehmensführung ) Fokus ist Gewissenhaftigkeit eines Unternehmens für soziale und ökologische Faktoren.",
            "optional": true,
        },
        {
            "uid": "aspects",
            "type": "permission",
            "behaviour": ["checkbox_list"],
            "question_text": "Welche Aspekte möchte ihr Kunde gezielt unterstützen?",
            "question_info":
                "Filter die hier ausgewählt sind, werden später in der Übersicht der Produktauswahl berücksichtigt und entsprechende Produkte werden gekennzeichnet.",
            "optional": true,
            "config": {
                "choices": [
                    {
                        "uid": 1,
                        "icon": <TwoAspect />,
                        "icon_dark": <TwoAspectDark />,
                        "text": "Ökologische Kriterien",
                    },
                    {
                        "uid": 2,
                        "icon": <SevenAspect />,
                        "icon_dark": <SevenAspectDark />,
                        "text": "Soziale Kriterien",
                    },
                    {
                        "uid": 3,
                        "icon": <EightAspect />,
                        "icon_dark": <EightAspectDark />,
                        "text": "Verfahrensweisen einer guten Unternehmensführung",
                    },
                ],
            },
        },
    ],
};

const step5 = {
    "uid": "pai",
    "name": "Nachhaltigkeitsprofilierung",
    "custom_classes": { stepName: "text-left" },
    "info": "Bitte legen Sie eine Gewichtung fest.",
    "behaviour": ["dynamic", "Nachhaltigkeitsprofilierung"],
    "step_content": SettingsProfile,
    "question": [
        {
            "uid": "cards",
            "type": "permission",
            "behaviour": ["checkbox_list"],
            "question_text": "1. Ökologisch Nachhaltig",
            "question_info":
                "Fokus ist die Umwelt und natürlichen Ressourcen zu schonen.",
            "optional": true,
            "config": {
                "choices": [
                    {
                        "uid": "ecologically-sustainable",
                        "title": "1. Ökologisch Nachhaltig",
                        "sub_title":
                            "Nachhaltige Investition gemäss Taxonomieverordnung. Leistet u.a. einen wesentlichen Beitrag zur Verwirklichung eines oder mehrerer Umweltziele.",
                    },
                    {
                        "uid": "esg",
                        "title": "2. ESG",
                        "sub_title":
                            "Nachhaltige Investition gemäss Offenlegungsverordnung. Trägt zur Erreichung eines Umweltziels oder eines sozialen Ziels bei und investiert in Unternehmen, die Verfahrensweisen einer guten Unternehmensführung anwenden.",
                    },
                    {
                        "uid": "pai",
                        "title": "3. PAI",
                        "sub_title":
                            "Investition berücksichtigt die wichtigsten nachteiligen Auswirkungen auf Nachhaltigkeitsfaktoren. Treibhausgasemissionen, Biodiversität, Wasser, Abfall, Soziale und Arbeitnehmerbelange.",
                    },
                ],
            },
        },
        {
            "uid": "percent",
            "type": "normal",
            "behaviour": ["slider"],
            "question_text":
                "Wieviel Prozent des Kundenportfolios sollten idealerweise diese Vorgabe erfüllen",
            "question_info":
                "(Umwelt-, Sozial- und Unternehmensführung ) Fokus ist Gewissenhaftigkeit eines Unternehmens für soziale und ökologische Faktoren.",
            "optional": true,
        },
        {
            "uid": "aspects",
            "type": "permission",
            "behaviour": ["checkbox_list"],
            "question_text": "Das Finanzinstrument soll folgende PAI-Themen berücksichtigen.",
            "question_info":
                "Filter die hier ausgewählt sind, werden später in der Übersicht der Produktauswahl berücksichtigt und entsprechende Produkte werden gekennzeichnet.",
            "optional": true,
            "config": {
                "choices": [
                    {
                        "uid": 1,
                        "icon": <NineAspect />,
                        "icon_dark": <NineAspectDark />,
                        "text": "Treibhausgas-Emissionen",
                    },
                    {
                        "uid": 2,
                        "icon": <TenAspect />,
                        "icon_dark": <TenAspectDark />,
                        "text": "Biodiversität",
                    },
                    {
                        "uid": 3,
                        "icon": <ElevenAspect />,
                        "icon_dark": <ElevenAspectDark />,
                        "text": "Wasser",
                    },
                    {
                        "uid": 4,
                        "icon": <TwelveAspect />,
                        "icon_dark": <TwelveAspectDark />,
                        "text": "Abfall",
                    },
                    {
                        "uid": 5,
                        "icon": <ThirteenAspect />,
                        "icon_dark": <ThirteenAspectDark />,
                        "text": "Soziale Themen und Arbeitnehmerbelange, Korruption und Menschenrechte",
                    },
                    {
                        "uid": 6,
                        "icon": <FourteenAspect />,
                        "icon_dark": <FourteenAspectDark />,
                        "text": "Indikatoren bei Investments in staatliche und supranationale Emittenten",
                    },
                    {
                        "uid": 7,
                        "icon": <FifteenAspect />,
                        "icon_dark": <FifteenAspectDark />,
                        "text": "Indikatoren bei Investments in Immobilienvermögen",
                    },
                ],
            },
        },
    ],
};

const step6 = {
    "uid": "summary",
    "name": "Nachhaltigkeitsprofil des Kunden",
    step_content: Summary,
    "question": [
        {
            "uid": "esg",
            "question_text":
                "Ihr Kundenportfolio soll idealerweise diese Vorgabe erfüllen",
            "question_info":
                "Diese Filter sind in der Übersicht der Produktauswahl berücksichtigt und entsprechende Produkte werden gekennzeichnet.",
            "type": "normal",
            "behaviour": ["radio"],
            "optional": true,
        },
        {
            "uid": "pai",
            "question_text":
                "Nachhaltigkeitsprofil des Kunden",
            "question_info":
                "Ihr Kunde möchte in nachhaltige Produkte investieren.",
            "type": "normal",
            "behaviour": ["radio"],
            "optional": true,
        },
        {
            "uid": "ecologically-sustainable",
            "question_text":
                "Nachhaltigkeitsprofil des Kunden",
            "question_info":
                "Ihr Kunde möchte in nachhaltige Produkte investieren.",
            "type": "normal",
            "behaviour": ["radio"],
            "optional": true,
        },
    ],
};

const congrats = {
    "uid": "congrats-step",
    "name": "Nachhaltigkeitsprofilierung",
    "help": "Das Nachhaltigkeitsprofil des Kunden wurde erfolgreich gespeichert. Sie können diese Informationen auch unter Meine Kunden ansehen und bearbeiten.",
    "behaviour": ["congrats"],
}

export const esgProfileStepsData = {
    steps: [step1, step2, step3, step4, step5, step6, congrats],
};

export const zeroProfileEsgData = {
    steps: [step1, step2, step3, step4, step5, congrats],
};