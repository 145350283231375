import React from 'react';
import PropTypes from 'prop-types';

import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  withStyles
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import useStyles from './styles'

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: '0px 3px 15px #00000033',
    borderRadius: 5,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#394352',
    borderRadius: 5,
    borderBottom: 'none',
    paddingLeft: 18,
    paddingRight: 18,
    marginBottom: -1,
    minHeight: 56,

    transition: 'all',
    transitionDelay: 100,
    transitionDuration: 500,

    '&$expanded': {
      minHeight: 56,
      borderRadius: '5px 5px 0 0',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: '10px 16px 16px',
    display: 'flex',
    flexDirection: 'column'
  },
}))(MuiAccordionDetails);

PortfolioAccordion.propTypes = {
  /** Portfolio name */
  name: PropTypes.string.isRequired,

  /** Portfolio category name */
  category: PropTypes.string,
};

function PortfolioAccordion(props) {

  const classes = useStyles()

  const { name, category, children } = props

  const [expanded, setExpanded] = React.useState(props.expanded)

  const onChange = (_, expanded) => setExpanded(expanded)

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandMore style={{color:'white'}}/>}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span className={classes.portfolioName}> { name } </span>
            { category && (
              <span className={classes.categoryName}> - { category } </span>
            ) }
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        { children }
      </AccordionDetails>
    </Accordion>
  );
}

export default PortfolioAccordion;