import React from "react";

import {
  getStepType,
  getStepComponent
} from "../../utils";

const StepContent = props => {
  const {
    step,
    loading,
    isFirst,
    isLast,
    required,
    valid,
    onNextClick,
    onPrevClick,
    onGoToStepClick,
    onResendResultClick,
    onConfirmResultClick,
    onAnswerChange,
    nextStepBtnText,
    successBody,
    dataService,
    extraRightButtons,
  } = props;

  if (!step) {
    return null;
  }

  const type = getStepType(step);
  const StepComponent = getStepComponent(type);

  return (
    <StepComponent
      uid={step.uid}
      name={step.name}
      info_text={step.info_text}
      help_text={step.info}
      risk_value={step.risk_value}
      questions={step.question}
      required={required}
      valid={valid}
      loading={loading}
      isFirst={isFirst}
      isLast={isLast}
      onNextClick={onNextClick}
      onPrevClick={onPrevClick}
      onGoToStepClick={onGoToStepClick}
      onAnswerChange={onAnswerChange}
      onResendResultClick={onResendResultClick}
      onConfirmResultClick={onConfirmResultClick}
      custom_classes={step.custom_classes}
      nextStepBtnText={nextStepBtnText}
      successBody={successBody}
      dataService={dataService}
      StepContentComponent={step.step_content}
      extraRightButtons={extraRightButtons}
    />
  );
};

export default StepContent;