const styles = theme => ({
  root: {
    minHeight: 150,
    width: '100%'
  },
  container: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#202A38'
  },
  title: {
    fontFamily: 'Roboto-Bold',
    width: '50%'
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    padding: '7px 0',
    borderBottom: 'solid 1px #D8DCDF',
    '&:first-of-type': {
      paddingTop: 0
    },
    '&:last-of-type': {
      borderBottom: 'none',
      paddingBottom: 0
    }
  },
  noLines: {
    borderBottomColor: 'transparent',
  },
  values: {
    width: '50%',
    textAlign: 'right'
  },
  subline: {
    marginTop: 10,
  },
  description: {
    margin: 0,
    fontSize: 12,
    maxWidth: 768,
    color: '#8D8D8D',
    '& > p:last-child': {
      marginBottom: 0
    }
  },
  helpIcon: {
    fontSize: '0.9rem'
  },
})

export default styles;