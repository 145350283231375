import React from "react";
import Grid from "@material-ui/core/Grid";
import useStyles from "./styles";
import clsx from "clsx";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import {SERVICE_CONCEPTS} from "../../../Trades/constants";
import {SelectListIcon} from "../../../../images";

export default function ServiceConceptSelection(props) {
  const {
    onServiceConceptChange,
    footerContent,
    customClasses,
    buttonText,
    selected,
    disabledOptions
  } = props;

  const classes = useStyles();

  return (
    <Grid className={clsx("wrapper", customClasses && customClasses.root)}>
      <h2 className={classes.title}>Investmentberatung</h2>
      <Grid container className={clsx(classes.container, classes.bgDark)}>
        <Grid item xl={3} md={3} className={classes}>
          <p className={'header'}>Anlageberatung</p>
        </Grid>
        <Grid item md={5}>
          <ul>
            <li>Empfehlung von Produkten auf Basis der Geeignetheitsprüfung des Anlegers</li>
            <li>
              Inklusive Abfrage der Anlageziele und -dauer, Kenntnisse und Erfahrungen,
              der Risikotoleranz sowie der finanziellen Verhältnisse des Kunden
            </li>
            <li>Empfehlungsverbot bei fehlender Eignung</li>
          </ul>
          <PrimaryButton
            icon={selected && selected == SERVICE_CONCEPTS.Anlageberatung && <SelectListIcon/>}
            text={ buttonText || "Los geht’s" }
            variant={'outlined'}
            customClasses={{root: classes.btnRoot}}
            onButtonClick={() => onServiceConceptChange(SERVICE_CONCEPTS.Anlageberatung)}
            iconPosition="right"
            disabled={disabledOptions && disabledOptions.includes(SERVICE_CONCEPTS.Anlageberatung)}
          />
        </Grid>
      </Grid>
      <h2 className={classes.title}>Beratungsfreies Geschäft</h2>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <div className={clsx(classes.container, classes.containerFlex)}>
            <p className={classes.header}>Anlagevermittlung mit Kenntnisse & Erfahrungen</p>
            <ul>
              <li>Vermittlung von bestimmten Produkten, bei denen der Kunde selbst entscheidet, ob das Produkt zu ihm passt</li>
              <li>Prüfung der Angemessenheit inkl. Befragung zu Kenntnissen und Erfahrungen</li>
              <li>Warnhinweis an Kunden notwendig, sofern Produkt nicht angemessen oder keine bzw. unzureichende Angaben für Prüfung</li>
            </ul>
            <div className={classes.buttonBottom}>
              <PrimaryButton
                text={ buttonText || "Los geht’s" }
                icon={selected && selected == SERVICE_CONCEPTS.Anlagevermittlung && <SelectListIcon color={"white"}/>}
                customClasses={{root: classes.btnRoot}}
                onButtonClick={() => onServiceConceptChange(SERVICE_CONCEPTS.Anlagevermittlung)}
                disabled={disabledOptions && disabledOptions.includes(SERVICE_CONCEPTS.Anlagevermittlung)}
                iconPosition={"right"}
              />
            </div>
          </div>
        </Grid>
        <Grid item md={6}>
          <div className={clsx(classes.container, classes.containerFlex)}>
            <p className={classes.header}>Reines Ausführungsgeschäft / Execution Only</p>
            <ul>
              <li>Ausschließlich auf Initiative des Kunden und nur für nicht-komplexe Finanzprodukte zulässig</li>
              <li>Keine Prüfung der Geeignetheit oder Angemessenheit für den Kunden</li>
            </ul>
            <div className={classes.buttonBottom}>
              <PrimaryButton
                text={ buttonText || "Los geht’s" }
                icon={selected && selected == SERVICE_CONCEPTS.ExecutionOnly && <SelectListIcon color={"white"}/>}
                customClasses={{root: classes.btnRoot}}
                onButtonClick={() => onServiceConceptChange(SERVICE_CONCEPTS.ExecutionOnly)}
                disabled={disabledOptions && disabledOptions.includes(SERVICE_CONCEPTS.ExecutionOnly)}
                iconPosition={"right"}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      {footerContent && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            { footerContent }
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}