import React from 'react';

import {
  Grid
} from '@material-ui/core';

import AssetItem from '../AssetItem';

import useStyles from './styles';

const Assets = props => {
  const classes = useStyles();

  const getAssetWeight = (asset) => {
    try {
      return parseFloat(asset.asset_value) / props.total * 100;
    } catch {
      return 0;
    }
  };

  const renderAssets = () => {
    return props.assets
      .sort((a, b) => {
        const value1 = parseFloat(a.value);
        const value2 = parseFloat(b.value);
        return value1 > value2 ? -1 : value1 < value2 ? 1 : 0
      })
      .map(asset => (
        <Grid item xs={12} className={classes.assetItem} key={asset.id}>
          <AssetItem
            asset={asset}
            weight={getAssetWeight(asset)}
            deleteBtnHandler={props.deleteBtnHandler}
            editBtnHandler={props.editBtnHandler}
            viewBtnHandler={props.viewBtnHandler}
          />
        </Grid>
      ))
  };

  return (
    <Grid container>
      {props.assets && (
        <>
          {renderAssets()}
        </>
      )}
    </Grid>
  );
};

export default Assets;