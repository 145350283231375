import React from 'react';
import clsx from 'clsx'
import _ from 'lodash'

import {
  Skeleton
} from '@material-ui/lab'
import {
  ErrorOutline
} from '@material-ui/icons'

/* BCA Components*/
import LoadingContainer from '../LoadingContainer';

/* BCA modules */
import useStyles from '../styles';
import {getErrorMessage, withEuroOrDash} from "../../../../utils/utils";
import Grid from "@material-ui/core/Grid";
import Tooltip from "../../../Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import ExpandButton
  , {
  ExpandIconButton
} from "../../../../containers/CustomerDashboard/components/Widgets/components/DashboardCard/components/ExpandButton";


export default (props) => {
  const classes = useStyles();

  const {
    value,
    loading,
    loadingWithTitle,
    openTransactionValue,
    openTransactionValueLoading,
    openTransactionValueError,
    withOpenTransactionValue,
    isVirtual,
    expandable,
    expanded,
    onExpanded,
    sectionHeader,
    sectionSubheader,
    withExpandButton
  } = props;

  const customClasses = props.customClasses || {}

  const getFraction = () => {
    if (openTransactionValue) {
      let fraction = 2;

      let openTransactionValueString = openTransactionValue.toString()
      let digits = openTransactionValueString.split('.')
      if (digits.length > 1) {
        let zeroNumbers = 0;
        for (let i = 0; i < digits[1].length; i++) {
          zeroNumbers++;
          if (digits[1][i] != '0') {
            break
          }
        }

        if (zeroNumbers > fraction) {
          fraction = zeroNumbers
        }
      }

      return fraction
    }

    return 2
  }
  const getHeader = () => {
    return _.isNil(sectionHeader) ? (isVirtual ? 'Ausgewählte virtl. Depots' : 'Ausgewählte Depots') : sectionHeader;
  }
  const getSubHeader = () => {
    return _.isNil(sectionSubheader) ? (isVirtual ? '' : 'Bestände exkl. sonstige Vermögenswerte') : sectionSubheader;
  }

  const Header = ({children}) => (
    <Grid container style={{justifyContent: 'space-between', marginLeft: 'auto', marginRight: 0}} className={customClasses.headerContainer}>
      <Grid item className={customClasses.headerWrapper}>
        <Grid item xs={12} className={clsx(classes.header, customClasses.header)} style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          {getHeader()}
        </Grid>
        <Grid item xs={12} className={customClasses.headerContent}>{children}</Grid>
      </Grid>
      {!loading && withExpandButton && (
        <Grid item>
          <ExpandIconButton
            expanded={expanded}
            onChanged={() => onExpanded(!expanded)}
          />
        </Grid>
      )}
    </Grid>
  );

  return (
    <div className={clsx(classes.container, customClasses.container)}>
      {loading ? (
        <>
          {loadingWithTitle && (
            <h5 className={clsx(classes.header, customClasses.header)}>{getHeader()}</h5>
          )}
          {props.loadingContent || (<LoadingContainer/>)}
        </>
      ) : (
        <Header>
          <div style={{display: !expandable || expanded ? 'inline' : 'none'}}>
            {!isVirtual && <p className={classes.subHeader}>{getSubHeader()}</p>}
            {isVirtual && <p className={classes.subHeader}><br /></p>}
            <div className={customClasses.dataWrap}>
              <p className={clsx(classes.value, customClasses.value)}>{withEuroOrDash(value || 0)}</p>
            </div>
            {withOpenTransactionValue && (
              <>
                {openTransactionValueLoading ? (
                  <div className={classes.openTransactionLoadingContainer}>
                    <Skeleton />
                  </div>
                ) : (
                  <>
                    {openTransactionValueError ? (
                      <p className={clsx([classes.subHeader, classes.errorMessage])}><ErrorOutline />{getErrorMessage(openTransactionValueError) }</p>
                    ) : (
                      <>
                        {!_.isNil(openTransactionValue) && openTransactionValue != 0 && (
                          <p className={classes.subHeader}>Offene Täusche { withEuroOrDash(openTransactionValue || 0, getFraction())}</p>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Header>
      )}
    </div>
  )
}