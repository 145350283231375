import _ from "lodash";

export const hasPortfolios = (customer) => {
  /**
  * @deprecated Function was used to define if 'Vermögensübersicht'(Asset Summary) had to be active.
  * Now customer has 'bca_show_reporting' flag, so the function needs to be changed after csv files are updated
  */

  // TODO refactor this func after csv files are updated and 'bca_show_reporting' flag is set to each customer
  if (_.isNil(customer.bca_show_reporting)){
    return parseFloat(customer.bca_current_value) > 0;
  }
  
  return customer.bca_show_reporting
};