import React from "react";
import { connect } from 'react-redux';
import {getColorByValue, openMorningStartIsin, withEuroOrDash, withPercentOrDash} from "../../../utils/utils";
import {
  DepotSubNumberCell,
  IsinCell
} from "../../../utils/commonTableColumns";

import WarningTooltip from "../../WarningTooltip";
import { getHostRelatedSetting } from '../../../utils/sharedSettings';
import { HOST_RELATED_SHARED_SETTINGS_KEYS } from '../../../components/SharedSettingsProvider/constants';
import {MorningstarCategoryCell, ProductNameCell, RowNumberCell} from "../InstrumentsAllocationTable/table-data";

const mapStateToProps = (state) => ({
  sharedSettings: state.get('sharedSettings').toJS()
});

const ReturnEuroCellContent = connect(mapStateToProps)((props) => {
  const {
    item,
    sharedSettings
  } = props;

  return (
    <span style={{color: getColorByValue(item.profit_loss_eur)}}>
      {item.error_for_orders && <WarningTooltip title={getHostRelatedSetting(sharedSettings.data, HOST_RELATED_SHARED_SETTINGS_KEYS.INSTRUMENT_TABLE_ERROR_MESSAGE_TEXT)}/>}
      {item.profit_loss_eur > 0 ? '+' : ''}{withEuroOrDash(item.profit_loss_eur)}
    </span>
  )

});

const HoldingPeriodCell = {
  header: 'Haltedauer',
  body: {
    content: (item) => (
      <span>
        {item.holding_period_days ? `${item.holding_period_days} d` : '-'}
      </span>
    )
  },
  key: 'HoldingPeriodCell'
}

const ReturnEuroCell = {
  header: 'GuV',
  body: {
    content: (item) => <ReturnEuroCellContent item={item} />
  },
  footer: {
    content: (item, options) => (
      <span style={{color: getColorByValue(options.profit_loss_eur)}}>
        {options.profit_loss_eur > 0 ? '+' : ''}{withEuroOrDash(options.profit_loss_eur)}
      </span>
    )
  },
  key: 'ReturnCellEuro'
}

const ReturnPercentageCell = {
  body: {
    content: (item) => (
      <span style={{color: getColorByValue(item.profit_loss_perc)}}>
        {item.profit_loss_perc > 0 ? '+' : ''}{withPercentOrDash(item.profit_loss_perc, true)}
      </span>
    )
  },
  footer: {
    content: (item, options) => (
      <span style={{color: getColorByValue(options.profit_loss_perc)}}>
        {options.profit_loss_perc > 0 ? '+' : ''}{withPercentOrDash(options.profit_loss_perc, true)}
      </span>
    )
  },
  key: 'ReturnCellPercentage'
}

const tableStructure = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell, DepotSubNumberCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [HoldingPeriodCell],
      align: 'right'
    },
    {
      content: [ReturnEuroCell, ReturnPercentageCell],
      align: 'right'
    }
  ],
  xs: [
    {
      content: [ProductNameCell]
    },
    {
      content: [ReturnEuroCell, ReturnPercentageCell],
      align: 'right'
    }
  ]
}

export default tableStructure;