import React from "react";
import { connect } from 'react-redux'

import {ModelportfolioList, ModelportfolioOverview} from "../../../../../../../../../../Modelportfolios";
import {
  displayErrorSnackBar,
  displayWarningSnackBar
} from "../../../../../../../../../../../components/SnackbarProvider/actions";
import {ModelPortfolioResource} from "../../../../../../../../../../../utils/api";
import {CircularProgress} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../styles";
import {getErrorMessage} from "../../../../../../../../../../../utils/utils";
import {InvestmentStrategyHandler, ModelPortfolioHandler} from "../../../../../../../../../utils";
import {SERVICE_CONCEPTS} from "../../../../../../../../../../Trades/constants";
import _ from "lodash";
import {DEFAULT_SELECT_PRODUCT_TABLE_ACTIONS_NAMES} from "../../../../../../../../../../../utils/constants";


const ModelPortfolioSelection = props => {
  const {
    classes,
    selectedItem, // selectedItem is passed if model portfolio was selected in ModelportfolioList. It becomes sours of data
    onDetailsClick,
    instrumentsHandler,
    onSelectAll,
    onInstrumentSelect,
    depot,
    title,
    action,
    isModelPortfolio,
    dataService,
    directAddingPossible,
    onSourceSelect,
    sourceSelected,
    selectedInstruments,
    forceAllAssetsSelection
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [skipDetails, setSkipDetails] = React.useState(false); // state to force ModelportfolioList render when Hinzufügen was clicked

  const modelPortfoliosHandler = React.useMemo(() => {

    if (_.isEmpty(dataService)) {
      return undefined;
    }

    // Model portfolios should be filtered
    // only for Anlageberatung service concept.
    if (dataService.serviceConcept !== SERVICE_CONCEPTS.Anlageberatung && action !== 'rebalancing') {
      return undefined
    }

    return new ModelPortfolioHandler()

  }, [depot, dataService.serviceConcept])
  /**
   * Get model portfolio details.
   * @param item Model portfolio identifier
   * @param skipDetails Flag, shows to display details screen, or not.
   * @returns {Promise<void>}
   */
  const handleDetailsClick = async (item, skipDetails) => {
    setLoading(true);
    setSkipDetails(skipDetails);
    try {
      let portfolio = await ModelPortfolioResource.getModelPortfolioDetails(item.id);

      const assets = _.get(portfolio, 'assets') || []

      if (!assets.length) {
        props.dispatch(displayWarningSnackBar('Für dieses Modellportfolio sind leider nicht alle Daten vorhanden.'))
      } else {
        forceAllAssetsSelection && onSelectAll(assets); // we call onSelectAll because for mp you can select only all assets, so the btn need-s to be on by default
        onDetailsClick(portfolio, skipDetails);
      }
    } catch (error) {
      props.dispatch(displayErrorSnackBar(getErrorMessage(error)))
    } finally {
      setLoading(false);
    }
  };

  const filteringExtraParams = (() => {
    const params = {
      is_modelportfolio: isModelPortfolio
    };
    if (!!depot) {
      params['custodian_id'] = depot.bankCompanyId
    }
    return params
  })();

  return (
    <>
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loadingIndicator}/>
        </div>
      )}
      {!loading && (
        <>
          {selectedItem && !skipDetails && (
            <ModelportfolioOverview
              viewOnly
              selectedInstruments={selectedInstruments}
              portfolio={selectedItem}
              onSelectAll={onSelectAll}
              onInstrumentSelect={onInstrumentSelect}
              onSourceSelect={onSourceSelect}
              sourceSelected={sourceSelected}
              isInstrumentsSelection
            />
          )}
          {(!selectedItem || skipDetails) &&
          <ModelportfolioList
            title={title || "Modellportfolios"}
            extraParams={filteringExtraParams}
            viewOnly
            handleEditClick={handleDetailsClick}
            modelPortfoliosHandler={modelPortfoliosHandler}
            directAddingPossible={directAddingPossible}
            actionsNames={DEFAULT_SELECT_PRODUCT_TABLE_ACTIONS_NAMES}
          />}
        </>
      )}

    </>
  )
};

ModelPortfolioSelection.defaultProps = {
  isModelPortfolio: true,
  dataService: {},
  forceAllAssetsSelection: true
}

export default connect() (withStyles(styles)(ModelPortfolioSelection));