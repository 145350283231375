import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import clsx from 'clsx';
import { CircularProgress, FormControl, withWidth } from '@material-ui/core';
import {
  CardContent,
  Grid,
  Checkbox
} from '@material-ui/core';
import ReportTypeV2 from '../CustomersList/components/ReportTypeV2';
import { REPORT_TYPES } from '../CustomersList/components/ReportTypeV2/constants';
import ReportsAreasVisibility from '../CustomersList/components/ReportsAreasVisibility';
import TimeFramePicker from '../../../CustomerDashboardV2/components/InvestmentDetailsNavigation/PortfolioTimeSelector/index';
import useStyles from './styles';
import Snackbar from '../../../../components/Snackbar';
import { DASHBOARD_SETTINGS_TYPE, DASHBOARD_TYPE } from '../../constants';
import { getDashboardType, isBenchmarksSettingsEqual } from '../../utils';
import FormLabel from '@material-ui/core/FormLabel';
import { useCustomerPortfoliosList } from '../../../../hooks/useCustomerPortfoliosList';
import PortfolioList from '../../../CustomerDashboardV2/components_v2/PortfolioList';
import { AGGREGATED_PORTFOLIO_ID, renderPortfolioDropdownName } from '../../../../utils/utils';
import portfolios
  from '../../../CustomerDashboard/components/PaymentPlanPortfoliosModal/components/Portfolios/Portfolios';
import { AGGREGATED_PORTFOLIO_NAME } from '../../../../utils/aggregated-portfolio';
import {
  CUSTOMER_RELATED_REPORTS_AREAS_LIST_V2,
  REPORTS_AREAS, REPORTS_AREAS_LIST_V2
} from '../CustomersList/components/ReportsAreasVisibility/constants';
import { PERFORMANCE_TIME_TYPE, REPORT_DATE_PERFORMANCE_TIME_TYPE_MAPPING } from '../../../../utils/constants';
import AggregatedDepotsSwitch
  from '../../../CustomerDashboardV2/components_v2/AggregatedDepotsSwitch/AggregatedDepotsSwitch';
import BenchmarkModal from '../../../CustomerDashboardV2/components/InvestmentDetailsNavigation/BenchmarkSettingsModal';
import ResetSettingsBtn from './components/ResetSettingsBtn';

const aggregatedPortfolioSelected = (portfolios) => _.some(portfolios, (portfolio) => !portfolio.depotNumber);

const settingsAggregatedPortfolioSelected = (portfolios) => portfolios.includes(AGGREGATED_PORTFOLIO_ID);

export const convertSettingsToSelectedPortfolios = (portfolios, defaultSelectedPortfolios, settings, key = 'portfolios') => {

  const settingsPortfolios = _.get(settings, `${key}.value`);

  if (!settingsPortfolios) {
    return defaultSelectedPortfolios || [];
  }

  if (_.isEmpty(portfolios)) {
    return [];
  }

  const aggregatedSelected = settingsAggregatedPortfolioSelected(settingsPortfolios);

  return _.filter(portfolios,
    (portfolio) => {

      return (aggregatedSelected || settingsPortfolios.includes(portfolio.depotNumber))
        && (settings.include_historical_portfolios || !portfolio.is_historical);
    });
};

export const filterOutPortfoliosToExclude = (portfolios, portfoliosToExclude, includeHistorical = undefined) => {

  let result = portfolios;

  if (!!portfoliosToExclude) {
    const excludePortfoliosIdentifiers = portfoliosToExclude.map((portfolio) => portfolio.depotNumber);
    result = portfolios.filter((portfolio) => !excludePortfoliosIdentifiers.includes(portfolio.depotNumber));
  }

  if (!_.isNil(includeHistorical) && !includeHistorical) {
    result = _.filter(result, (portfolio) => !portfolio.is_historical);
  }

  let notAggregatedPortfolios = _.filter(result, (portfolio) => !!portfolio.depotNumber);
  // In case only one real portfolios left - do not include Aggregated portfolio into the list
  if (notAggregatedPortfolios.length == 1) {
    return notAggregatedPortfolios;
  }

  return result;
};

const getSelectedPortfoliosOptions = (portfolios, portfoliosToExclude) => {

  let result = portfolios;

  if (!!portfoliosToExclude) {
    const excludePortfoliosIdentifiers = portfoliosToExclude.map((portfolio) => portfolio.depotNumber);
    result = portfolios.filter((portfolio) => !excludePortfoliosIdentifiers.includes(portfolio.depotNumber));
  }

  // in case not all portfolios selected - remove Aggregated selection
  if (result.length !== portfolios.length) {
    result = result.filter((portfolio) => !!portfolio.depotNumber)
  }

  return result;
};

const SELECTED_PORTFOLIOS_DROPDOWN_INFO_TEXT = 'Definieren Sie hier die in der Vermögensübersicht vorausgewählten Depots.';
const AVAILABLE_PORTFOLIOS_DROPDOWN_INFO_TEXT = 'Wählen Sie hier die Depots, welche in der Vermögensübersicht angezeigt werden sollen.';

const DashboardSettingsWindowV2 = props => {
  const classes = useStyles();
  const {
    settings,
    dateRange,
    loading,
    handleSettingUpdate,
    handleDateRangeChanged,
    snackbarSettings,
    handleSnackbarVisibility,
    benchmarks,
    showBenchmark,
    updateBenchmarkSwitchState,
    handleBenchmarkUpdate,
    customerFullName,
    settingsType, // used in save
    defaultSettingsType, // used for default dashboard
    disableCopySettings,
    title,
    resetSettings,
    customer_id,
    singleView,
  } = props;

  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false);
  const { fetch, ...portfoliosData } = useCustomerPortfoliosList(
    customer_id, true, true, true);

  /**
   * Flag, that is used for useEffect dependency to check if
   * Historical checkbox was updated manually and not by component initialization.
   * @type {React.MutableRefObject<undefined>}
   */
  const isHistoricalCheckboxMounted = React.useRef();

  const onBenchmarkConfigured = (newBenchmarks) => {
    setBenchmarkModalOpen(false);
    if(_.isNil(newBenchmarks)) return;  // cancel was clicked

    let hasBenchmarks = newBenchmarks && newBenchmarks.length > 0;
    if (!hasBenchmarks) {
      updateBenchmarkSwitchState(false);
    } else {
      // FIXME: weight should be 0.1
      newBenchmarks = newBenchmarks.map(b => ({...b, weight: b.weight * 100}))
    }
    if (!isBenchmarksSettingsEqual(newBenchmarks, benchmarks)) {
      handleBenchmarkUpdate(newBenchmarks, hasBenchmarks);
    }
  };

  const handlePortfoliosDropdownExpanded = (expanded) => {
    if (!expanded || portfoliosData.loading || !_.isEmpty(portfoliosData.portfolios)) return;

    fetch();
  };

  const getGroupAreasVisibility = () => {
    let areasVisibility = [];

    if (settings.with_payment_plans) {
      areasVisibility.push(REPORTS_AREAS.PAYMENT_PLANS.value);
    }
    if (settings.with_profit_loss) {
      areasVisibility.push(REPORTS_AREAS.PROFIT_LOSS.value);
    }
    if (settings.include_historical_portfolios) {
      areasVisibility.push(REPORTS_AREAS.INCLUDE_HISTORICAL_PORTFOLIOS.value);
    }
    if (settings.with_transaction_saldo) {
      areasVisibility.push(REPORTS_AREAS.WITH_TRANSACTION_SALDO.value);
    }
    if (settings.with_other_assets) {
      areasVisibility.push(REPORTS_AREAS.OTHER_ASSETS.value);
    }
    if (settings.with_transactions_monitor) {
      areasVisibility.push(REPORTS_AREAS.TRANSACTIONS_MONITOR.value);
    }

    return areasVisibility;
  };

  const isCopySettingsDisabled = () => {
    return disableCopySettings ||
      settingsType === DASHBOARD_SETTINGS_TYPE.BROKER_GROUP ||
      settingsType === DASHBOARD_SETTINGS_TYPE.CUSTOMER_GROUP;

  };

  const isDepotStatusReport = settings && settings.report_type == REPORT_TYPES.DEPOT_STATUS.value;
  const isBenchmarksUnavailable = settings &&
    (settings.report_type == REPORT_TYPES.DEPOT_STATUS.value || settings.report_type == REPORT_TYPES.BASIC.value);

  const getValueFromSettings = (settingName) => {
    return _.get(settings, settingName);
  };

  const getNonDepotStatusValueFromSettings = (settingName) => {
    return !isDepotStatusReport && getValueFromSettings(settingName);
  };

  const portfoliosForAvailableList = filterOutPortfoliosToExclude(
    portfoliosData.portfolios, undefined, settings.include_historical_portfolios);
  const excludedPortfolios = convertSettingsToSelectedPortfolios(
    portfoliosData.portfolios, portfoliosData.defaultExcluded, settings, 'excluded_portfolios');
  let availablePortfolios = getSelectedPortfoliosOptions(portfoliosForAvailableList, excludedPortfolios);
  const portfoliosForSelectList = filterOutPortfoliosToExclude(
    portfoliosForAvailableList, excludedPortfolios);
  const selectedPortfolios = convertSettingsToSelectedPortfolios(
    portfoliosForSelectList, [], settings);

  const _getDropdownPortfoliosValue = (_selectedPortfolios, _allPortfoliosList) => {

    if (aggregatedPortfolioSelected(_selectedPortfolios) || _selectedPortfolios.length == _allPortfoliosList.length) {
      return {
        value: [AGGREGATED_PORTFOLIO_ID],
        placeholder: AGGREGATED_PORTFOLIO_NAME
      };
    }

    return {
      value: _selectedPortfolios.map((portfolio) => portfolio.depotNumber),
      placeholder: renderPortfolioDropdownName(_selectedPortfolios, _allPortfoliosList)
    };

  };

  const handleSelectedPortfoliosChanged = (portfolios) => {
    handleSettingUpdate('portfolios')({
      portfolios: _getDropdownPortfoliosValue(portfolios, portfoliosForSelectList),
      excluded_portfolios: settings.excluded_portfolios
    });
  };

  const handleAvailablePortfoliosChanged = (portfolios) => {
    const excluded = getSelectedPortfoliosOptions(portfoliosForAvailableList, portfolios);
    handleSettingUpdate('portfolios')({
      portfolios: _getDropdownPortfoliosValue(
        filterOutPortfoliosToExclude(selectedPortfolios, excluded), filterOutPortfoliosToExclude(portfoliosForSelectList, excluded)),
      excluded_portfolios: _getDropdownPortfoliosValue(excluded, portfoliosData.portfolios)
    });
  };

  React.useEffect(() => {
    if (isHistoricalCheckboxMounted.current && customer_id && !_.isEmpty(portfoliosData.portfolios)) {
      handleAvailablePortfoliosChanged(availablePortfolios);
    } else {
      isHistoricalCheckboxMounted.current = true;
    }
  }, [settings.include_historical_portfolios]);

  // show placeholder in case no portfolio loaded - to prevent showing placeholder when all depots are excluded
  const depotsPlaceholder = _.isEmpty(portfoliosData.portfolios) ? 'Bitte klicken um Depots zu laden' : undefined;

  return (
    <>
      <Grid className={classes.container}>
        {!_.isEmpty(settings) && !loading ?
          <CardContent style={{ padding: 0 }}>
            {title &&
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.title}>
                {title}
              </Grid>
            </Grid>
            }

            <Grid container>
              <Grid item lg={6} md={6} sm={12} xs={12}
                    className={clsx(classes.dashboardSettingsSection, classes.firstSettingsSection)}>
                <Grid container>
                  <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginBottom: 8 }}>
                    <div className={clsx(classes.periodSelector, loading && 'loading-line')}>
                      <TimeFramePicker
                        calculationDates={dateRange}
                        defaultPerformanceTimeType={PERFORMANCE_TIME_TYPE[REPORT_DATE_PERFORMANCE_TIME_TYPE_MAPPING[settings.date_range]]}
                        handleCalculationDatesChanged={handleDateRangeChanged}
                        loading={loading}
                        showNoPriceOnWeekendsWarning
                        useFilterStyles
                        BenchmarkProps={{
                          enabled: true,
                          showBenchmark: showBenchmark,
                          configuration: benchmarks,
                          onValueChanged: () => updateBenchmarkSwitchState(!showBenchmark),
                          onConfigurationClick: () => setBenchmarkModalOpen(true)
                        }}
                      />
                    </div>
                  </Grid>
                  {customer_id && (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom: 8}}>
                        <div className={clsx(loading && 'loading-line')} >
                          <AggregatedDepotsSwitch
                            title={'Historische Depots'}
                            value={getValueFromSettings('include_historical_portfolios')}
                            onChange={(checked) => handleSettingUpdate('include_historical_portfolios')(checked)}
                          />
                        </div>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginBottom: 8 }}>
                        <div className={clsx(classes.periodSelector, loading && 'loading-line')}>
                          <PortfolioList
                            selectedPortfolios={availablePortfolios}
                            handleSelectedPortfolioChanged={handleAvailablePortfoliosChanged}
                            portfolios={portfoliosForAvailableList}
                            portfoliosLoadingError={portfoliosData.errors}
                            portfoliosDataLoading={portfoliosData.loading}
                            alwaysScrollableList={true}
                            loading={loading}
                            isListAutoUpdate
                            infoText={AVAILABLE_PORTFOLIOS_DROPDOWN_INFO_TEXT}
                            title={'Depot-Sichtbarkeit'}
                            onExpanded={handlePortfoliosDropdownExpanded}
                            customClasses={{ container: classes.portfoliosSelectorContainer }}
                            placeholder={depotsPlaceholder}
                            BenchmarkProps={{enabled: false}}
                          />
                        </div>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginBottom: 8 }}>
                        <div className={clsx(classes.periodSelector, loading && 'loading-line')}>
                          <PortfolioList
                            title={'Depot-Vorauswahl'}
                            selectedPortfolios={selectedPortfolios}
                            handleSelectedPortfolioChanged={handleSelectedPortfoliosChanged}
                            portfolios={portfoliosForSelectList}
                            portfoliosLoadingError={portfoliosData.errors}
                            portfoliosDataLoading={portfoliosData.loading}
                            alwaysScrollableList={true}
                            loading={loading}
                            isListAutoUpdate
                            infoText={SELECTED_PORTFOLIOS_DROPDOWN_INFO_TEXT}
                            onExpanded={handlePortfoliosDropdownExpanded}
                            customClasses={{ container: classes.portfoliosSelectorContainer }}
                            placeholder={depotsPlaceholder}
                          />
                        </div>
                      </Grid>
                    </>
                  )}

                  {/* historical chart settings */}
                  <Grid item lg={12} md={12} sm={12} xs={12} className={classes.chartSettingsSectionWrapper}
                        style={{ marginBottom: 8 }}>
                    <FormControl disabled={props.disabled} component="fieldset" style={{ width: '100%' }}>
                      <FormLabel component="legend" classes={{ root: classes.formLabel }}>Geldgewichtete
                        Rendite-Chart</FormLabel>

                      <Grid container>
                        {/* invested capital switch */}
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <div className={clsx(loading && 'loading-line')}>
                            <AggregatedDepotsSwitch
                              title={'Kapitaleinsatz'}
                              value={getNonDepotStatusValueFromSettings('with_invested_capital')}
                              onChange={(checked) => handleSettingUpdate('with_invested_capital')(checked)}
                              disabled={isDepotStatusReport}
                            />
                          </div>
                        </Grid>
                        {/* aggregate selected portfolios switch */}
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <AggregatedDepotsSwitch
                            title={'Kumuliert'}
                            value={getNonDepotStatusValueFromSettings('aggregate_historical_chart_portfolios')}
                            onChange={(checked) => handleSettingUpdate('aggregate_historical_chart_portfolios')(checked)}
                            disabled={isDepotStatusReport}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                  {/* Performance chart settings */}
                  <Grid item lg={12} md={12} sm={12} xs={12} className={classes.chartSettingsSectionWrapper}>
                    <FormControl disabled={props.disabled} component="fieldset" style={{ width: '100%' }}>
                      <FormLabel component="legend" classes={{ root: classes.formLabel }}>Zeitgewichtete
                        Rendite-Chart</FormLabel>

                      <Grid container>
                        {/* aggregate selected portfolios switch */}
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <AggregatedDepotsSwitch
                            title={'Kumuliert'}
                            value={isBenchmarksUnavailable ? false : getNonDepotStatusValueFromSettings('aggregate_performance_chart_portfolios')}
                            onChange={(checked) => handleSettingUpdate('aggregate_performance_chart_portfolios')(checked)}
                            disabled={isBenchmarksUnavailable}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>

                </Grid>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} className={classes.dashboardSettingsSection}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className={clsx(loading && 'loading-line')}>
                      <ReportTypeV2
                        defaultSettingsType={defaultSettingsType}
                        selected={settings && settings.report_type}
                        handleChanged={handleSettingUpdate('report_type')}
                        settingsType={settingsType}
                        customReportTypeSettings={settings && settings.individual_settings}
                        handleCustomReportSettingUpdate={handleSettingUpdate('individual_settings')}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className={clsx(loading && 'loading-line')}>
                      <ReportsAreasVisibility
                        label={'Zusätzliche Inhalte'}
                        options={customer_id ? CUSTOMER_RELATED_REPORTS_AREAS_LIST_V2 : REPORTS_AREAS_LIST_V2}
                        isDepotStatus={isDepotStatusReport}
                        selected={getGroupAreasVisibility()}
                        handleChanged={handleSettingUpdate('areas_visibility')}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {settingsType != DASHBOARD_SETTINGS_TYPE.GENERAL &&
              <Grid container className={classes.reportSaveSection}>
                <Grid item>
                  <div className={
                    clsx(loading && 'loading-line',
                      isCopySettingsDisabled() && classes.contentDisabled)
                  }>
                    <Checkbox
                      color="primary"
                      classes={{ root: classes.radio }}
                      checked={settings.save_settings_to_related}
                      onChange={() => handleSettingUpdate('save_settings_to_related')(!settings.save_settings_to_related)}
                    />
                    Einstellung für
                    die {getDashboardType(settingsType) === DASHBOARD_TYPE.BROKER ? 'Kundenansicht' : 'Vermittleransicht'} von <span
                    className={classes.highlightedName}>{customerFullName}</span> übernehmen
                  </div>
                </Grid>
                {!singleView && (settingsType == DASHBOARD_SETTINGS_TYPE.SINGLE_BROKER || settingsType == DASHBOARD_SETTINGS_TYPE.SINGLE_CUSTOMER) && (
                  <Grid item style={{marginLeft: 'auto', alignSelf: 'center'}}>
                    <ResetSettingsBtn
                      loading={loading}
                      settingsId={settings.id}
                      customer_id={customer_id}
                      resetSettings={resetSettings}
                      settingsType={settingsType}
                      customClasses={{label: classes.resetButtonLabel}}
                    />
                  </Grid>
                )}
              </Grid>
              }
            </Grid>
          </CardContent>
          :
          <CircularProgress/>
        }
      </Grid>
      <Snackbar open={snackbarSettings.open} variant={snackbarSettings.variant} message={snackbarSettings.message}
                handleClose={handleSnackbarVisibility}/>

      {benchmarkModalOpen && (
        <BenchmarkModal
          onBenchmarkConfigured={onBenchmarkConfigured}
          // FIXME: why we have diff fields benchmark vs benchmark_id
          benchmarks={benchmarks.map(b => ({...b, benchmark: b.benchmark_id}))}
          withoutSnackbar
        />
      )}

    </>
  );
};

export default withRouter(withWidth()(DashboardSettingsWindowV2));
