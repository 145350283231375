import React from 'react';
import _ from 'lodash'

import useStyles from './styles';
import Tooltip from "../../Tooltip";
import clsx from "clsx";


const NAME_INDEX = 0;
const OTHER_CONTROL_INDEX = 1;
const TOOLTIP_INDEX = 2;


const ChartLegendItem = (props) => {
  const {
    name,
    color
  } = props;
  const classes = useStyles();

  const _name = (() => {
    if (_.isArray(name)) {
      return name[NAME_INDEX];
    }
    return name
  })();

  const otherControls = (() => {
    if (_.isArray(name)) {
      return name[OTHER_CONTROL_INDEX];
    }
  })();

  const tooltip = (() => {
    if (_.isArray(name)) {
      return name[TOOLTIP_INDEX] || name[NAME_INDEX];
    }
  })();


  if (!_.isUndefined(_name)) {
    return (
        <span className={classes.chartLegendItem}>
          <Tooltip title={tooltip} placement={"top"}>
            <span>
              <span style={{color: color}} className={classes.chartLegendItemSymbol}>&#x25A0;</span>
              <span className={classes.chartLegendItemText}>{_name}</span>
            </span>
          </Tooltip>
          {otherControls && (
            <>
              {otherControls}
            </>
          )}
        </span>
    )
  }
  return null;
}


const ChartLegend = (props) => {
  const {
    names,
    colors,
    styles
  } = props;
  const classes = useStyles();

  if (!_.isUndefined(names) && _.isArray(names)) {
    return (
      <div className={clsx(classes.container, props.classes && props.classes.container)} style={styles ? styles : {}}>
        {
          names.map((name, index) => {

            let colorsPrepared = [...colors];
            let colorNumbers = 1;
            if (names.length > colors.length) {
              colorNumbers = Math.trunc(names.length / colors.length)
            }
            // TODO: Need to improve this part
            for(let i in [...Array(colorNumbers-1)]) {
              colorsPrepared = [...colorsPrepared, ...colors]
            }

            return <ChartLegendItem key={index} name={name} color={colorsPrepared[index]}/>
          })
        }
      </div>
    )
  }
  return null;
}

export default ChartLegend;