import React from 'react';
import _ from 'lodash';
import clsx from "clsx";

/* Material-UI Components */
import Grid from '@material-ui/core/Grid';

/* BCA Components */
import useStyles from './styles'
import Pagination from '../../../../components/CustomerList/components/Pagination';
import CustomerListItem from './components/CustomerListItem';
import { hasFilterResult } from '../../../../utils/utils';
import FilterHeader from '../../components/FilterHeader';

const CustomerList = (props) => {
  const classes = useStyles();

  const {
    customers,
    handleCustomerSettingsChanged,
    loading,
    updatedCustomers,
    dashboardType,
    filterResult,
    resetSettings,
    newDesign,
  } = props;

  const [page, setPage] = React.useState(0);

  return (
    <Grid container className={`${classes.listContainer}`} id="reporting-client-container-tour-element">
      <React.Fragment>
        {customers && (
          <React.Fragment>
            <Grid container>
             {hasFilterResult(filterResult) &&
                <FilterHeader filterResult={filterResult}/>
             }
            <Grid item xs={6}>
              {customers && customers.length > 1 && (
                <Pagination handlePageChanged={setPage} totalPageCount={customers.length} currentPage={page}/>
              )}
             </Grid>
            </Grid>
            {
              customers && customers.length > 0 && (
                <React.Fragment>
                  {
                    customers[page <= customers.length - 1 ? page : customers.length - 1].map((customer, index) => (
                      <CustomerListItem
                        key={customer.customer_id || index}
                        customer={customer}
                        updatedCustomer={_.find(updatedCustomers, updatedCustomer => updatedCustomer.customer_id == customer.customer_id)}
                        customerLoadingError={false}
                        handleCustomerSettingsChanged={handleCustomerSettingsChanged}
                        loading={loading}
                        dashboardType={dashboardType}
                        resetSettings={resetSettings}
                        newDesign={newDesign}
                      />
                    ))
                  }
                </React.Fragment>
              )
            }
            {customers && customers.length > 1 && (
              <Pagination handlePageChanged={setPage} totalPageCount={customers.length} currentPage={page}/>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </Grid>
  );
};

export default CustomerList;