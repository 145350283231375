import React  from 'react'
import _ from 'lodash'
import { connect } from 'react-redux';
import {SharedSettingsResource} from "../../../utils/api";
import queryString from 'query-string';

import { 
  withStyles,
  Grid,
  Container,
  Fade
} from '@material-ui/core';

import Link from '../../../components/Link';
import { 
  ModelPortfolioUploader,
  InstrumetnsWeightsModal,
  StickyNavigation,
  ModelPortfolioModal,
  SuccessMessageModal
} from '../components';
import {
  ModelportfoliosFilters,
} from '../index';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';

import { styles } from './styles';
import { MODELPORTFOLIO_CREATE_CONTAINER_TABS } from './constants';
import { ModelPortfolioResource } from '../../../utils/api';
import {BackIcon} from '../../../images'
import { getRouteFullPathByComponent, getInvestmentDynamicPath } from '../../InvestmentPlatform/utils'
import { MODEL_PORTFOLIO_CREATE_ERROR_MESSAGE } from '../constants'
import { displayErrorSnackBar } from '../../../components/SnackbarProvider/actions'
import BenchmarkModal from "../components/BenchmarkSettingsModal";
import {setSharedSettings} from "../../../components/SharedSettingsProvider/actions";
import {renderBenchmarkConfigBtn} from "../components/InstrumentsList/InstrumenstList";
import {portfolioTransactionAmount, transactionValueToEuro} from "../../Trades/utils";
import moment from "moment/moment";
import {ModelPortfolioBase} from "./ModelportfolioBase";


export const handleModelPortfolioCreateClick = async (mpId, data, update, assets, benchmarks, getOrders, setState, dispatch, modelPortfolioCreationStatus, onSuccess) => {
    let requestBody = {
      ...data,
      assets,
      benchmarks,
      orders: _.isFunction(getOrders) ? getOrders() : undefined
    }

    setState({
      modelPortfolioCreationStatus: {
        loading: true,
        errors: null
      }
    })

    try {
      if (update && mpId) {
        await ModelPortfolioResource.updateModelPortfolio(mpId, requestBody)
      } else {
        await ModelPortfolioResource.createModelPortfolio(requestBody)
      }

      let message = (
        <span>{update ? 'Ihre' :'Ihr neues'} Musterdepot <b>{requestBody.name}</b> wurde erfolgreich gespeichert.</span>
      )

      if(onSuccess) {onSuccess({
          successModelPortfolioCreationModal: {
            message,
            open: true
          },
          modelPortfolioCreationStatus: {
            ...modelPortfolioCreationStatus,
            loading: false,
          }
      })}

    } catch (errors) {
      setState({
        modelPortfolioCreationStatus: {
          loading: false,
          errors: _.isString(errors) ? errors : JSON.parse(errors.request.response)
        }
      })

      dispatch(displayErrorSnackBar(MODEL_PORTFOLIO_CREATE_ERROR_MESSAGE))
    }

  }


const COMBINED_TRADE_STEP_WRAPPER_ID = 'instruments-to-add-id'

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS()
});

/** Modelportfolio creation container */
class Create extends ModelPortfolioBase {

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      instrumentsWeightsModalVisible: false,
      instrumentsWeightsConfiguration: undefined,
      modelPortfolioModalVisible: false,
      modelPortfoliosSearch: {
        data: undefined,
        loading: false,
        errors: undefined,
        date: Date.now()
      },
      selectedModelPortfolio: {
        data: undefined,
        loading: false,
        errors: undefined,
        date: Date.now()
      },

      successModelPortfolioCreationModal: {
        open: false,
        message: null
      },

      benchmarkConfiguration: {
        modal_opened: false,
        benchmarks: [],
      },

      modelPortfolioCreationStatus: {
        loading: false,
        errors: null
      },

      investmentDynamicPath: getInvestmentDynamicPath(),
      isin: null
    }
  }

  fetchModelportfolio = async (id) => {
    this.setState({
      selectedModelPortfolio: {
        data: undefined,
        loading: true,
        errors: undefined,
        date: Date.now()
      }
    })

    try {
      let response = await ModelPortfolioResource.getModelPortfolioDetails(id);

      this.setState({
        selectedModelPortfolio: {
          data: response,
          loading: false,
          errors: undefined,
          date: Date.now()
        }
      })

    } catch (errors) {

      this.setState({
        selectedModelPortfolio: {
          data: undefined,
          loading: false,
          errors,
          date: Date.now()
        }
      })

    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchModelPortfolios();
    this.fetchSharedSettings();
    let id = this.props.computedMatch.params.id

    if (id) {
      this.fetchModelportfolio(id)
    }

    // BCA-4084
    let isin = queryString.parse(this.props.location.search).isin
    if(isin) {
      this.setState({isin: isin})
      this.getAssetFromFacthsheet(isin)
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedModelPortfolio.date != prevState.selectedModelPortfolio.date && this.state.selectedModelPortfolio.data) {
      const assets = this.state.selectedModelPortfolio.data.assets || [];

      this.setState({
        selectedAssets: assets,
        benchmarkConfiguration: {
          ...this.state.benchmarkConfiguration,
          benchmarks: this.state.selectedModelPortfolio.data.benchmarks
        }
      })
    }
  }

  getAssetFromFacthsheet = async(isin) => {
      this.setState({
        assetsSearch: {
          loading: true,
          directIsinAdding: true
        },
      });
      this.searchAssets({name: isin, only_favorite: false}, (data) => {
        this.handleAddAssetClick(data[0]);
        this.setState({
          assetsSearch: {
            loading: false,
            directIsinAdding: false
          },
          triggerAccordionExpansionCheck: Date.now(),
        });
      }, (errors) => {
        console.log(errors);
        this.setState({
          assetsSearch: {
            loading: false,
            directIsinAdding: false
          },
        });
        this.props.dispatch(displayErrorSnackBar('Fehler beim Hinzufügen des Vermögenswert.'))
      })
  }

  handleModelPortfolioSelected = (modelPortfolio) => {

    let modelPortfolioData = _.find(this.state.modelPortfoliosSearch.data || [], m => m.id == modelPortfolio.id)
    if (modelPortfolioData) {
      this.setState({
        selectedModelPortfolio: {
          data: modelPortfolioData,
          loading: false,
          errors: undefined,
          date: Date.now()
        },
        triggerAccordionExpansionCheck: Date.now(),
      })
      this.handleSelectedAssetsChange(modelPortfolioData.assets)
    }
  }

  fetchSharedSettings = async () => {
    try {
      const sharedSettings = await SharedSettingsResource.at('data/').get();

      this.props.dispatch(setSharedSettings(sharedSettings || {}));
    } catch {
      this.props.dispatch(setSharedSettings({}));
    }
  };

  getModelPortfoliosTree = () => {
    return [];
  }

  fetchModelPortfolios = async () => {
    this.setState({
      modelPortfoliosSearch: {
        loading: true,
        data: null,
        errors: null,
        date: Date.now()
      }
    });

    try {
      let response = await ModelPortfolioResource.getModelPortfoliosList();

      this.setState({
        modelPortfoliosSearch: {
          loading: false,
          data: response,
          errors: null,
          date: Date.now()
        }
      })

    } catch (errors) {
      this.setState({
        modelPortfoliosSearch: {
          loading: false,
          data: null,
          errors,
          date: Date.now()
        }
      })
    }
  }

  renderIfPathExist = (path, callback) => {
    if (this.props.investmentPlatform.routes) {
      let fullPath = getRouteFullPathByComponent(this.props.investmentPlatform.routes, path)

      if (fullPath) {
        return callback(fullPath)
      }
    }
  }

  handleOpenInstrumentsWeightsModalClick = () => {
    this.setState({
      instrumentsWeightsModalVisible: true
    })
  }

  handleOpenBenchmarkModalClick = (event) => {
    event.stopPropagation()
    this.setState({
      benchmarkConfiguration: {
        ...this.state.benchmarkConfiguration,
        modal_opened: true,
      }
    })
  };

  handleBenchmarksConfigured = (benchmarks) => {
    this.setState({
      benchmarkConfiguration: {
        benchmarks: benchmarks === false ? this.state.benchmarkConfiguration.benchmarks : benchmarks,
        modal_opened: false,
      }
    })
  };

  getFlatTransactions = () => {
    return Object.values(_.get(this.props.portfoliosTransactions[0], 'transactions', {})).flat()
  };

  preWeightsApprove = () => {
    if(this.props.validateTransactions(this.props.portfoliosTransactions, this.props.setPortfoliosTransactions, COMBINED_TRADE_STEP_WRAPPER_ID, true)){
      let totalAmount = portfolioTransactionAmount(this.props.portfoliosTransactions[0], 'buy') + portfolioTransactionAmount(this.props.portfoliosTransactions[0], 'savings_plan')
      let updatedMpStartDate = null;

      let assetsWithWeights = {};
      this.getFlatTransactions().map(transaction => {

        let dateToCheck = transaction.from_date || transaction.deposit_from_date

        if (!_.isNil(dateToCheck)){
          updatedMpStartDate = _.isNil(updatedMpStartDate)
            ? dateToCheck
            : moment.min([updatedMpStartDate, dateToCheck])
        }

        const transactionValue = transactionValueToEuro(transaction.transaction_value, transaction);
        const transactionWeight = transactionValue * 100 / totalAmount;

        if (transaction.data.isin in assetsWithWeights){
          // if isin is already in assetsWithWeights -> it is present in buy and sparplan process -> update its weight
          assetsWithWeights[transaction.data.isin].weight += transactionWeight
        } else {
          assetsWithWeights[transaction.data.isin] = {
            fixed: transaction.data.fixed || false,
            instrument_id: transaction.data.isin,
            weight: transactionWeight,
          }
        }
      });

      this.setState({mpStartDate: updatedMpStartDate});

      this.handleWeightsApprove(Object.values(assetsWithWeights))

    }
  };

  handleWeightsApprove = (weights) => {
    this.setState({
      instrumentsWeightsModalVisible: false,
      modelPortfolioModalVisible: true,
      instrumentsWeightsConfiguration: weights
    })
  };

  handleSuccessModelPortfolioCreationModalClose = () => {
    this.renderIfPathExist('MODELPORTFOLIO_LIST', (path) => {
      this.props.history.push(`/${this.state.investmentDynamicPath}${path}`)
    })

  };

  handleModelPortfolioModalClose = () => {
    this.setState({
      modelPortfolioModalVisible: false
    })
  };

  handleInstrumentsWeightsModalClose = () => {
    this.setState({
      instrumentsWeightsModalVisible: false
    })
  };

  renderBackLink = () => {

    if(this.state.isin) {
       return <Link
          title="Zurück zum Factsheet"
          icon={<i className="fa fa-chevron-left" aria-hidden="true" />}
          underline
          onClick={() => this.props.history.push(`/factsheets/${this.state.isin}`)}
        />
    }

    return this.renderIfPathExist('MODELPORTFOLIO_LIST', (path) => (
      <Link
        title="Zurück zu Musterdepot Übersicht"
        icon={<i className="fa fa-chevron-left" aria-hidden="true" />}
        underline
        onClick={() => this.props.history.push(`/${this.state.investmentDynamicPath}${path}`)}
      />
    ))

  };

  renderBackButton = () => {
    const { classes }  = this.props;

    if(this.state.isin) return '';

    return this.renderIfPathExist('MODELPORTFOLIO_LIST', (path) => (
      <PrimaryButton 
        customClasses={{root: classes.stickyButtonOutlinedRoot}} 
        text="Zurück" 
        variant="outlined" 
        icon={<BackIcon />}
        onButtonClick={() => this.props.history.push(`/${this.state.investmentDynamicPath}${path}`)}
      />
    ))
  }

  updateState = (newState) => {
    this.setState(newState)
  }

  render() {
    const { classes } = this.props;

    const isCreate = !this.props.computedMatch.params.id;
    
    return (
      <Container className={'app-page-container'} style={{height: '100%', position: 'relative'}}>
        {this.state.activeContent == MODELPORTFOLIO_CREATE_CONTAINER_TABS.MAIN && (
          <>
            {this.renderBackLink()}
            <h1 className={classes.header}>Neues Musterdepot erstellen</h1>
            <Grid container spacing={2}>
              {isCreate && (
                <Grid item xs={12}>
                  <ModelPortfolioUploader
                    modelportfolios={this.state.modelPortfoliosSearch.data}
                    loading={this.state.modelPortfoliosSearch.loading}
                    errors={this.state.modelPortfoliosSearch.errors}
                    selected={this.state.selectedModelPortfolio.data}
                    onSelect={this.handleModelPortfolioSelected}
                  />
                </Grid>
              )}
              <Grid item lg={12} id={COMBINED_TRADE_STEP_WRAPPER_ID}>
                <this.props.CombinedTradeStep
                  allowedPortfolioTradeOptions={['buy']}
                  defaultExpandedTrades={['transaction-buy']}
                  defaultExpandedSavingPlans={['transaction-create']}
                  isVirtual
                  isNewVirtual
                  questions={ [{answer: this.props.portfoliosTransactions}] }
                  dataService={this.props.dataService}
                  onAnswerChange={(_question_uid, portfoliosWithTransactions) => {
                    this.props.setPortfoliosTransactions([...portfoliosWithTransactions]) // destruction is used to trigger state update
                  }}

                  extraBtns={renderBenchmarkConfigBtn(this.handleOpenBenchmarkModalClick)}

                  triggerAccordionExpansionCheck={this.state.triggerAccordionExpansionCheck}
                />
              </Grid>
            </Grid>
            <BenchmarkModal
              onBenchmarkConfigured={this.handleBenchmarksConfigured}
              benchmarkConfiguration={this.state.benchmarkConfiguration}
            />
          </>
        )}
        {this.state.activeContent == MODELPORTFOLIO_CREATE_CONTAINER_TABS.FILTERS_EXTENDED && (
          <>
            <Link
              title="Schließen"
              icon={<i className="fa fa-chevron-left" aria-hidden="true" />}
              underline
              onClick={() => this.setState({activeContent: MODELPORTFOLIO_CREATE_CONTAINER_TABS.MAIN})}
            />
            <Fade in={this.state.activeContent == MODELPORTFOLIO_CREATE_CONTAINER_TABS.FILTERS_EXTENDED} timeout={500}>
              <ModelportfoliosFilters
                onAddInstruments={this.handleAddInstrumentsFilters}
                addExtraFilters={this.addExtraFilters}
                predefinedFilter={this.props.predefinedFilter}/>
            </Fade>
          </>
        )}
        <InstrumetnsWeightsModal 
          open={this.state.instrumentsWeightsModalVisible}
          instruments={this.state.selectedAssets}
          onWeightsApprove={this.handleWeightsApprove}
          onClose={this.handleInstrumentsWeightsModalClose}
          selectedModelPortfolio={this.state.selectedModelPortfolio.data}
        />
        <ModelPortfolioModal
          onModelPortfolioCreateClick={(data, update) => handleModelPortfolioCreateClick(
            _.get(this.state.selectedModelPortfolio, 'data.id'),
            data, update, this.state.instrumentsWeightsConfiguration, this.state.benchmarkConfiguration.benchmarks,
            this.props.getOrders, this.updateState, this.props.dispatch, this.state.modelPortfolioCreationStatus, this.updateState
          )}
          open={this.state.modelPortfolioModalVisible}
          onClose={this.handleModelPortfolioModalClose}
          defaultStartDate={this.state.mpStartDate}
          loading={this.state.modelPortfolioCreationStatus.loading}
          errors={this.state.modelPortfolioCreationStatus.errors}
          selectedModelPortfolio={this.state.selectedModelPortfolio.data}
          isCreate={isCreate}
        />
        {this.state.activeContent == MODELPORTFOLIO_CREATE_CONTAINER_TABS.MAIN && (
          <StickyNavigation>
            <div className={classes.stickyNavigation}>
              <div>
                {this.renderBackButton()}
              </div>
              <div>
                <PrimaryButton 
                  customClasses={{root: classes.stickyButtonRoot, disabled: classes.stickyButtonDisabled}} 
                  text="Bestätigen & fortfahren"
                  disabled={_.isEmpty(this.getFlatTransactions())}
                  onButtonClick={this.preWeightsApprove}
                />
              </div>
            </div>
          </StickyNavigation>
        )}
        <SuccessMessageModal
          message={this.state.successModelPortfolioCreationModal.message}
          open={this.state.successModelPortfolioCreationModal.open}
          onClose={this.handleSuccessModelPortfolioCreationModalClose}
        />
      </Container>
    )
  }
}

export default connect(mapStateToProps, null, null, {withRef: true})(withStyles(styles)(Create));

