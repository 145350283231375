import {makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
    wrapper: {
        height: "100%",
    },
    content: {
        display: "flex",
        gap: "80px",
        boxShadow: "0px 3px 15px #00000033"
    },
    title: {
        fontFamily: 'Roboto-Bold',
        textAlign: "left",
        fontSize: "20px",
        color: "#202A38",
    },
    description: {
        fontWeight: "normal",
        textAlign: "left",
        fontSize: "16px",
        color: "#0D2440",
    },
    optionName: {
        color: "#202A38",
        fontSize: "16px",
        fontFamily: 'Roboto-Bold',
    },
    optionPercent: {
        color: "#202A38",
        fontSize: "28px",
        fontFamily: 'Roboto-Bold',
        textAlign: "right"
    },
    cards: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row"
    },
    card: {
        borderRadius: "5px",
        border: "1px solid #D8DCDF",
        boxShadow: "0px 3px 15px #00000033",
        width: "100%",
        padding: "15px",
        maxWidth: "49%"
    },
    aspects: {
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "100%",
        gap: "10px",
    },
    item: {
        display: "flex",
        border: "1px solid #D8DCDF",
        alignItems: "center",
        padding: "15px",
        borderRadius: "5px",
        width: "100%",
        fontSize: "14px",
        color: "#4C4D4D",
        fontFamily: 'Roboto-Bold',
        wordBreak: "break-word"
    },
    icon: {
        width: "26px",
        marginRight: "15px"
    },
    chart: {
        width: "400px",
    }
}));
