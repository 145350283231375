import React from 'react'

import useStyles from './styles'
import {CircularProgress, Container, Grid} from "@material-ui/core";
import useFetchData from "../../hooks/useDataFetch";
import {PortfolioHandlerResource} from "../../utils/api";
import DashboardTable from "../../components/DashboardTable/DashboardTable";
import tableStructure, {getEventKey} from './table-data'
import _ from "lodash";
import {paginateArray} from "../../utils/utils";
import Filters from "../TransactionsMonitoring/components/Filters/Filters";
import {
  ALARM_FIELDS_TO_VALIDATE_OPTIONS,
  ALARM_TYPE_OPTIONS,
  ALL_SELECTED_OPTION_VALUE,
  getFieldsToCheckOptions
} from "./constants";
import {filterAlertEvents} from "./utils";


const DEFAULT_FILTERS_OPTIONS = {
  customer_name: '',
  product_name: '',
  event_type: [...ALARM_TYPE_OPTIONS.map((option) => option.id)],
  field_to_validate: [...ALARM_FIELDS_TO_VALIDATE_OPTIONS.map((option) => option.id)],
  dates: [null, null],
  only_active: false
}


function AlarmEvents(props) {
  const classes = useStyles()

  const [eventsData, fetchEvents, setEvents] = useFetchData(
    `${PortfolioHandlerResource.resourceUrl}customer/asset/alert-configuration/events/list/`, 'get', undefined, true)

  const [filters, setFilters] = React.useState({...DEFAULT_FILTERS_OPTIONS})

  const [filteredEvents, setFilteredEvents] = React.useState([])

  /**
   * Alerting events list data fetching
   */
  React.useEffect(() => {
    fetchEvents()
  }, [])

  React.useEffect(() => {
    if (eventsData.loading || eventsData.errors) {
      return
    }

    handleApplyFilters(filters)

  }, [eventsData.updatedAt])

  const events = React.useMemo(() => {

    return [...paginateArray(filteredEvents.map((event) => ({
      ...event,
      ...event.asset,
      key: getEventKey(event),
      tableProps: {
        isHeader: event.is_new
      }})), 10)]

  }, [filteredEvents])

  const handleEventMarkAsRead = (event_ids) => {

    const eventsCopy = _.cloneDeep(_.flatten(events))

    eventsCopy.forEach((event) => {
      if (!event_ids || event_ids.includes(getEventKey(event))) {
        event.is_new = false
      }
    })

    setEvents(eventsCopy)

  }

  const handleApplyFilters = async (newFilters) => {

    const filteredEvents = filterAlertEvents(eventsData.data, newFilters)

    setFilteredEvents(filteredEvents)
  }

  const newEventsExists = _.some(_.flatten(events), (event) => event.is_new)

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{marginBottom: 40, display: 'flex', justifyContent: 'space-between'}}>
          <h1 className={classes.header}>Ausgelöste Alarme</h1>
        </Grid>

        {eventsData.loading ? (
          <Grid item className={classes.loadingContainer} xs={12}>
            <CircularProgress color="primary" size={50}/>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} style={{marginBottom: 40}}>
              <Filters
                initValue={filters}
                defaultValue={_.cloneDeep(DEFAULT_FILTERS_OPTIONS)}
                label="Alarme filtern"
                onSubmit={handleApplyFilters}
              >
                <Filters.InputField
                  loading={eventsData.loading}
                  name="customer_name"
                  label="Kunde"
                  placeholder="Kundenname, Kundennummer"
                  onKeyDown={handleApplyFilters}
                  GridProps={{
                    md: 6,
                    xs: 12
                  }}
                />
                <Filters.InputField
                  loading={eventsData.loading}
                  name="product_name"
                  label="Produkt/ Depot"
                  placeholder="Produkt/ Depot"
                  onKeyDown={handleApplyFilters}
                  GridProps={{
                    md: 6,
                    xs: 12
                  }}
                />
                <Filters.Dropdown
                  loading={eventsData.loading}
                  name="event_type"
                  label="Alarm Typ"
                  placeholder="Wählen Sie eine Alarm Typ"
                  options={ALARM_TYPE_OPTIONS}
                  GridProps={{
                    md: 6,
                    xs: 12
                  }}
                />
                <Filters.Dropdown
                  loading={eventsData.loading}
                  name="field_to_validate"
                  label="Alarm einstellen für"
                  placeholder="Wählen Sie eine Alarm einstellen für"
                  options={ALARM_FIELDS_TO_VALIDATE_OPTIONS}
                  GridProps={{
                    md: 6,
                    xs: 12
                  }}
                />
                <Filters.SimpleDateRange
                  name="dates"
                  loading={eventsData.loading}
                  GridProps={{
                    md: 4,
                    sm: 6,
                    xs: 12
                  }}
                />
                <Filters.Switch
                  name="only_active"
                  label="Nur neu"
                  loading={eventsData.loading}
                  GridProps={{
                    sm: 2,
                    xs: 4
                  }}
                />
                <Filters.SubmitButton
                  GridProps={{
                    sm: 4,
                    xs: 8
                  }}
                  disabled={eventsData.loading}
                />
              </Filters>
            </Grid>
            <Grid item xs={12} className={classes.container}>
            <DashboardTable
              structure={tableStructure}
              dataSource={events}
              dataSourceKeyField={'key'}
              tableClasses={classes}
              expanded={true}
              withFooter={false}
              paginationOptions={{
                paginationEnabled: true,
                pageSize: 10,
                pageSizeSelectEnabled: true
              }}
              options={{
                newEventsExists,
                onEventMarkAsRead: handleEventMarkAsRead
              }}
            />
          </Grid>
          </>
        )}
      </Grid>
    </Container>
  )
}

export default AlarmEvents;