const styles = theme => ({
  cardRoot: {
    [theme.breakpoints.down('sm')]: {
      padding: 4
    }
  },
  cardContentRoot: {
    [theme.breakpoints.down('sm')]: {
      padding: '4px!important'
    }
  },
  tableContainer: {
    width: '100%',
  },
  listContainer: {},
});

export default styles;