import {makeStyles} from "@material-ui/core";

const styles = (theme) => ({
  formElementLabel: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: '#202A38 !important',
  },
  dialogActionsRoot: {
    justifyContent: 'flex-end !important',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& > button': {
        width: '100%',
        '&:last-child': {
          marginLeft: 0,
          marginTop: 10
        }
      }
    },
  }
})

export default makeStyles(styles);