export const TOKEN_KEY = 'token';
export const ORIGIN_KEY = 'origin';
export const NEW_ORIGIN_KEY = 'new_origin';
export const CUSTOMER_ID_KEY = 'customer_id';
export const USER_KEY = 'user';
export const SHARED_SETTINGS_KEY = 'shared_settings';

export const PROF_VIEW_PORTFOLIO_DATA = 'prof_view_portfolio_data'
export const PROF_VIEW_REPORT_TYPE = 'prof_view_report_type'

export const NEW_ORIGIN_MISSING_ERROR_MESSAGE = 'Fehler bei der Link Generierung zu den DIVA Rücksprüngen'

export const STORAGE_TYPE = {
  SESSION: 'SESSION',
  LOCAL: 'LOCAL'
}

export const getStorageByType = (type) => {
  if (type == STORAGE_TYPE.LOCAL) {
    return localStorage;
  }
  return sessionStorage
}

export const setInStorage = (key, value, type=STORAGE_TYPE.LOCAL) => {
    try {
      const serializedValue = JSON.stringify(value);
      let storage = getStorageByType(type);
      storage.setItem(key, serializedValue);
    } catch (err) {
      // TODO: log error?
    }
  };
  
export const getFromStorage = (key, type=STORAGE_TYPE.LOCAL) => {
  try {
    let storage = getStorageByType(type);
    const value = storage.getItem(key);
    if (value === null) {
      return undefined;
    }
    return JSON.parse(value);
  } catch (err) {
    return undefined;
  }
};
  
export const removeFromStorage = (key, type=STORAGE_TYPE.LOCAL) => {
  try {
    let storage = getStorageByType(type);
    storage.removeItem(key);
  } catch (err) {
    console.error(err);
  }
};
