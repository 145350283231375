import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    color: '#202A38',
    margin: '15px 0'
  },
  stickyNavigation: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  stickyButtonRoot: {
    borderRadius: 8,
    height: '50px !important',
    marginRight: 15
  },
  stickyButtonDisabled: {
    backgroundColor: '#99D3F5 !important',
    color: 'white !important'
  },
  stickyButtonOutlinedDisabled: {
    border: '2px solid #00000033 !important',
    backgroundColor: 'white !important',
    color: '#00000033 !important'
  },
  stickyButtonOutlinedRoot: {
    borderRadius: 8,
    height: '50px !important',
    marginRight: 15,
    backgroundColor: 'white !important'
  },
  tableCell: {
    width: 100,
    '&:first-child': {
      width: 350
    }
  },
});


export default makeStyles(styles);