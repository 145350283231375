import {makeStyles} from "@material-ui/core";

const styles = theme => ({
  totalAmount: {
    backgroundColor: '#D8DCDF',
    fontFamily: "Roboto-Bold",
    fontSize: 16,
    padding: '10px 16px',
    '& div:last-child': {
      marginLeft: 'auto',
    }
  },
  loadingIndicator: {
    color: theme.palette.primary.main
  },
  loadingContainer: {
    width: '100%',
    height: 'calc(100vh - 420px)', // 420px for header and footer
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  sectionHeader: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
  },
  accordionRoot: {
    boxShadow: 'none',
    border: '1px solid #D8DCDF',
    borderRadius: 5,
    width: '100%',
    margin: '5px 0 !important',
  },
  accordionHeader: {
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    padding: "2px 16px",
    '&.Mui-expanded': {
      minHeight: 'auto',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 'inherit',
    }
  },
  accordionDetails: {
    flexDirection: 'column',
  },
});

export default styles;