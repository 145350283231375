import React from "react";
import {Grid, withStyles} from "@material-ui/core";
import ChartPortfolioReturn from "./items/ChartPortfolioReturn";
import ChartPortfolioPerformance from "./items/ChartPortfolioPerformance";
import ChartPortfolioPerformanceForecast from "./items/ChartPortfolioPerformanceForecast";
import styles from "./styles";
import {parseResponse, VirtualPortfolioHandlerResource} from "../../../../utils/api";
import ChartPerformanceBar from "./items/ChartPerformanceBar";
import CumulativeReturnWidget from "./items/CumulativeReturnWidget";
import SinglePerformanceTable from "./items/SinglePerformanceTable";
import RankingPerformanceTable from "./items/RankingPerformanceTable";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export const getComparator = (order, orderBy)=>{
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export const stableSort = (array, comparator)=>{
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

class CommonAnalysisTab extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dataProvider: VirtualPortfolioHandlerResource,
            historicalData: null,
            historicalLoading: true,
            historicalError: null,
            cumulativeReturnData: null,
            cumulativeReturnLoading: true,
            cumulativeReturnError: null,
            performanceForecastData: null,
            performanceForecastLoading: true,
            performanceForecastError: null,
            rankingAndSinglePerformanceData: null,
            rankingAndSinglePerformanceLoading: true,
            rankingAndSinglePerformanceError: null,
        }

        this.fetchHistoricalData = this.fetchHistoricalData.bind(this);
        this.fetchCumulativeReturn = this.fetchCumulativeReturn.bind(this);
        this.fetchPerformanceForecast = this.fetchPerformanceForecast.bind(this);
        this.fetchRankingAndSinglePerformance = this.fetchRankingAndSinglePerformance.bind(this);
    }

    componentDidMount() {
        this.fetchHistoricalData()
        this.fetchCumulativeReturn()
        this.fetchPerformanceForecast()
        this.fetchRankingAndSinglePerformance()
    }

    fetchHistoricalData() {
        this.state.dataProvider.getHistoricalData(this.props.customerId, [this.props.portfolioId], undefined, undefined)
            .then(
                (data) => {
                    let processedData = data['historical'];
                    if (processedData['errors'].length > 0) {
                        this.setState({
                            historicalError: processedData['errors'][0],
                            historicalData: null,
                            historicalLoading: false
                        })
                    } else {
                        this.setState({
                            historicalData: processedData['data'][0][0],
                            historicalLoading: false,
                            historicalError: null
                        })
                    }
                })
            .catch((error) => {
                this.setState({
                    historicalError: error,
                    historicalLoading: false,
                    historicalData: false
                })
            })
    }

    fetchCumulativeReturn() {
        this.state.dataProvider.getCumulativeReturnData(this.props.customerId, this.props.portfolioId)
            .then((data) => {
                let processedData = data['cum_returns'];
                if (processedData['errors'].length > 0) {
                    this.setState({
                        cumulativeReturnError: processedData['errors'][0],
                        cumulativeReturnData: null,
                        cumulativeReturnLoading: false
                    })
                } else {
                    this.setState({
                        cumulativeReturnData: processedData['data'][0],
                        cumulativeReturnLoading: false,
                        cumulativeReturnError: null
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    cumulativeReturnData: null,
                    cumulativeReturnLoading: false,
                    cumulativeReturnError: error
                })
            }
            )
    }

    fetchRankingAndSinglePerformance() {
        this.state.dataProvider.getPortfolioExtendedAssetData(this.props.customerId, this.props.portfolioId)
            .then((data) => {
                let processedDataSingle = data['extended_asset_data'];
                if (processedDataSingle['errors'].length > 0) {
                    this.setState({
                        rankingAndSinglePerformanceError: processedDataSingle['errors'],
                        rankingAndSinglePerformanceData: null,
                        rankingAndSinglePerformanceLoading: false
                    })
                } else {
                    this.setState({
                        rankingAndSinglePerformanceData: processedDataSingle['data'],
                        rankingAndSinglePerformanceLoading: false,
                        rankingAndSinglePerformanceError: null
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    rankingAndSinglePerformanceData: null,
                    rankingAndSinglePerformanceLoading: false,
                    rankingAndSinglePerformanceError: error,
                })
            }
        )
    }

    fetchPerformanceForecast() {
        this.state.dataProvider.getPortfolioForecast(this.props.customerId, this.props.portfolioId)
            .then((response) => {
                parseResponse(response, 'performance_forecast',
                    (data) => {
                        this.setState({
                            performanceForecastData: data,
                            performanceForecastLoading: false,
                            performanceForecastError: null
                        })
                    },
                    (error) => {
                        this.setState({
                            performanceForecastError: error,
                            performanceForecastData: null,
                            performanceForecastLoading: false
                        })
                    }
                )
            })
            .catch((error) => {
                this.setState({
                    performanceForecastData: null,
                    performanceForecastLoading: false,
                    performanceForecastError: error
                })
            }
            )
    }

    render() {
        return <Grid container spacing={2}>
            <Grid item xs={12}>
                <ChartPortfolioReturn
                    customerId={this.props.customerId}
                    portfolioId={this.props.portfolioId}
                />
            </Grid>
            <Grid item xs={12}>
                <ChartPortfolioPerformance
                    data={this.state.historicalData}
                    loading={this.state.historicalLoading}
                    error={this.state.historicalError}
                />
            </Grid>
            <Grid item xs={12} sm={8}>
                <ChartPerformanceBar
                    data={this.state.historicalData}
                    loading={this.state.historicalLoading}
                    error={this.state.historicalError}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <CumulativeReturnWidget
                    data={this.state.cumulativeReturnData}
                    loading={this.state.cumulativeReturnLoading}
                    error={this.state.cumulativeReturnError}
                />
            </Grid>
            <Grid item xs={12}>
                <SinglePerformanceTable
                    data={this.state.rankingAndSinglePerformanceData}
                    loading={this.state.rankingAndSinglePerformanceLoading}
                    error={this.state.rankingAndSinglePerformanceError}
                    order='asc'
                    orderBy='weight'
                />
            </Grid>
            <Grid item xs={12}>
                <ChartPortfolioPerformanceForecast
                  data={this.state.performanceForecastData}
                  loading={this.state.performanceForecastLoading}
                  error={this.state.performanceForecastError}
                />
            </Grid>
            <Grid item xs={12}>
                <RankingPerformanceTable
                    data={this.state.rankingAndSinglePerformanceData}
                    loading={this.state.rankingAndSinglePerformanceLoading}
                    error={this.state.rankingAndSinglePerformanceError}
                    order='asc'
                    orderBy='name'
                />
            </Grid>
        </Grid>
    }
}

export default withStyles(styles)(CommonAnalysisTab);
