import React from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";


const DisclaimerText = () => {
    const classes = useStyles();

    return (
        <>
            <Typography className={classes.title}>
                Risikohinweis zur Berücksichtigung Ihrer Nachhaltigkeitspräferenzen
            </Typography>
            <Typography className={classes.text}>
              Sie sind nicht verpflichtet, sich für eine der dargestellten Nachhaltigkeitspräferenzen zu entscheiden.
              Wir als Anbieter sind bemüht, Ihnen ein Angebot unter Berücksichtigung von Nachhaltigkeitspräferenzen unterbreiten zu können.
              Bitte berücksichtigen Sie dabei, dass wir zum gegenwärtigen Zeitpunkt noch nicht auf eine
              ausreichende Datengrundlage von Unternehmen zurückgreifen können. Erst in Zukunft werden Unternehmen verpflichtet werden,
              detaillierte Angaben zum Anteil ihrer Investition und ihrer Umsätze in wirtschaftlichen Tätigkeiten,
              die z.B. der Taxonomieverordnung entsprechen, in ihrer nicht-finanziellen Berichterstattung zu machen.
              Diese Verpflichtungen werden nur größere Unternehmen betreffen, nicht aber kleinere und mittlere Unternehmen.
              Letzere werden vorraussichtlich lediglich zu einer eingeschränkten Berichterstattung verpflichtet.
              Das Gleiche gilt für staatliche oder supranationale Emittenten.
            </Typography>
            <Typography className={classes.text}>
              <b>Wir können daher keine Gewährleistung für die Erreichung Ihrer Nachhaltigkeitsziele übernehmen.</b>
            </Typography>
        </>
    );
};

export default DisclaimerText;