import React from "react";
import {connect} from "react-redux";
import {Dialog, IconButton} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DashboardTable from "../../../../../../../../components/DashboardTable/DashboardTable";
import {default as IconBtn} from "../../../../../../../../components/Buttons/IconButton";
import PlusBackgroundIcon from "../../../../../../../../images/PlusBackgroundIcon";
import Create from "../../../../../../components/TradeStep/components/Create/Create";
import PaymentPlanEditTable from "../../../PaymentPlanEditTable/PaymentPlanEditTable";
import {
  getSwitchInPlansTableStructure,
  getSwitchPlansTableStructure
} from "../../../PaymentPlanEditTable/table-data";
import {InfoIcon} from "../../../../../../../../images";
import _ from "lodash";
import {calculateSwitchTransactionSaldo} from "../../../../../../utils";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import {getPaymentPlanMaxStartDate, TRANSACTION_TYPE_VALUES} from "../../../../../../constants";

function EditTable(props) {
  const {
    portfolio,
    allInstruments,
    portfolioComponents,
    action,
    dataService,
    addPaymentPlanOption,
    removePortfolioPaymentPlanOption,
    removePaymentPlanOption
  } = props;

  const [activeItem, setActiveItem] = React.useState(undefined); // add switch ins for active switch out item

  const handleClose = () => {
    setActiveItem(undefined);
  };

  const handleAddClick = (item) => {
    if(!portfolio.isModelportfolio){
      setActiveItem(item);
    }
  };

  return (
    <PaymentPlanEditTable
      addPaymentPlanOption={addPaymentPlanOption}
      removePortfolioPaymentPlanOption={removePortfolioPaymentPlanOption}
      removePaymentPlanOption={removePaymentPlanOption}
      transactionType={TRANSACTION_TYPE_VALUES.SWITCH_PLAN}
      {...props}
    >
      {
        (EditTableProps) => (
          <>
            {(EditTableProps.indexedGroupInstruments || []).map((item) => (
              <>
                <DashboardTable
                  tableLayout={"auto"}
                  dataSource={_.isEmpty(item) ? [] : [item]}
                  structure={getSwitchPlansTableStructure(action)}
                  tableClasses={EditTableProps.classes}
                  withFooter={false}
                  expanded
                  withInputs
                  options={{
                    itemsLength: 1,
                    availableDiscounts: portfolio.availableDiscounts,
                    rotationOptions: portfolio.isModelportfolio
                      ? portfolio.availableMPSwitchPlanPeriodicPlans
                      : portfolio.availableSwitchPlanPeriodicPlans,
                    fromDateOptions: portfolio.isModelportfolio
                      ? portfolio.availableMPSwitchPlanExecutionDates
                      : portfolio.availableSwitchPlanExecutionDates,
                    handleDiscountChange: EditTableProps.handleDiscountChange,
                    handleRotationChange: EditTableProps.handleRotationChange,
                    handleDateChange: EditTableProps.handleDateChange,
                    handleAmountChange: EditTableProps.handleAmountChange,
                    onRemoveClick: EditTableProps.onRemoveClick,
                    onRemoveAllClick: EditTableProps.onRemoveAllClick,
                    handleSwitchAmountEuroChanged: EditTableProps.handleSwitchAmountEuroChanged,
                    handleSwitchPercentageChanged: EditTableProps.handleSwitchPercentageChanged,
                    isModelportfolio: portfolio.isModelportfolio,
                    tillDateEnabled: portfolio.ordering_switch_plan_till_date_enabled,
                    maxStartDate: (item) => getPaymentPlanMaxStartDate(item, portfolio, action)
                  }}
                />
                {!_.isEmpty(item) && action != 'delete' && (
                  <>
                    <div className={EditTableProps.classes.switchInfoText}>
                      <InfoIcon />
                      <p>Bitte beachten Sie, dass Anteile automatisch anhand der Anforderungen der Depotbanken gerundet werden.</p>
                    </div>
                    <DashboardTable
                      tableLayout={"auto"}
                      dataSource={item.buy || []}
                      structure={getSwitchInPlansTableStructure(EditTableProps.isAnlageberatung)}
                      tableClasses={EditTableProps.classes}
                      withFooter={false}
                      expanded
                      withInputs
                      options={{
                        action: action,
                        switchOutItem: item,
                        itemsLength: item.buy.length,
                        hasError: !!(item.errors && item.errors.buy),
                        maxQtyDecimals: portfolio.maxQtyDecimals,
                        discountsDisabled: !_.get(portfolio, 'ordering_discounts_allowed', []).includes('switch_plan'),
                        availableDiscounts: portfolio.availableDiscounts,
                        handleDiscountChange: EditTableProps.handleDiscountChange,
                        handleAmountChange: EditTableProps.handleAmountChange,
                        onRemoveClick:(instrument) => EditTableProps.onRemoveClick(instrument, item),
                        onRemoveAllClick: () => EditTableProps.onRemoveAllClick(item),
                        handleSwitchAmountEuroChanged: EditTableProps.handleSwitchAmountEuroChanged,
                        handleSwitchPercentageChanged: EditTableProps.handleSwitchPercentageChanged,
                        onDistributeQuantitiesClick: EditTableProps.handleDistributeQuantitiesClick(item),
                        isModelportfolio: portfolio.isModelportfolio,
                        transactionSaldo: calculateSwitchTransactionSaldo(item, portfolio.maxQtyDecimals),
                        withSrri: EditTableProps.isAnlageberatung,
                        clientSRRI: dataService.srri,
                      }}
                    />
                    <Grid className={EditTableProps.classes.addBtnContainer} container alignItems={"center"}>
                      <Grid item>
                        <IconBtn
                          label={'Zielfonds hinzufügen'}
                          component={PlusBackgroundIcon}
                          onClick={() => handleAddClick(item)}
                          size={36}
                        />
                      </Grid>
                      <Grid item>
                        {item.errors && item.errors.buy && (
                          <FormHelperText error={true}>{item.errors.buy}</FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            ))}
            <>
              {!!activeItem && (
                <Dialog maxWidth="lg" fullWidth disableBackdropClick={true} open={true} onClose={handleClose}>
                  <DialogTitle>
                    <div className={EditTableProps.classes.header}>
                      <IconButton aria-label="close" className={EditTableProps.classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>
                  <Create
                    portfolioInstruments={(portfolioComponents || []).filter(i => !!i.isin && i.isin !== activeItem.data.isin)}
                    instruments={[activeItem, ...activeItem.buy].map(i => i.data)}
                    instrumentsHandler={EditTableProps.instrumentsHandlers['create']}
                    extraFilters={{only_funds: true, custodian: portfolio.companyId}}
                    handleAddItems={async (assets) => {
                      await EditTableProps.handleAddItems(action, assets, activeItem);
                      handleClose()
                    }}
                    predefinedFilter={{cCustodian: [{label: portfolio.custodianName, id: portfolio.companyId, value: portfolio.companyId}]}}
                    customerId={props.customer_id}
                  />
                </Dialog>
              )}
              {EditTableProps.isEnableAdding(action) && (
                <>
                  <div className={EditTableProps.classes.addBtnContainer}>
                    <IconBtn
                      label={'Tauschplan Positionen hinzufügen'}
                      component={PlusBackgroundIcon}
                      onClick={() => EditTableProps.handleAddSavingPlanButtonClick(action)}
                      size={36}
                    />
                  </div>
                  <Dialog maxWidth="lg" fullWidth disableBackdropClick={true} open={EditTableProps.open} onClose={() => EditTableProps.setOpen(false)}>
                    <DialogTitle>
                      <div className={EditTableProps.classes.header}>
                        <IconButton aria-label="close" className={EditTableProps.classes.closeButton} onClick={() => EditTableProps.setOpen(false)}>
                          <CloseIcon/>
                        </IconButton>
                      </div>
                    </DialogTitle>
                    <Create
                      portfolioInstruments={(portfolioComponents || []).filter(i => !!i.isin && i.quantity > 0)}
                      instruments={allInstruments}
                      extraFilters={{custodian: portfolio.companyId}}
                      handleAddItems={(assets) => EditTableProps.handleAddItems(action, assets)}
                      portfolioOnly={true}
                      action={action}
                      instrumentsHandler={EditTableProps.instrumentsHandlers[action]}
                      customerId={props.customer_id}
                    />
                  </Dialog>
                </>
              )}
            </>
          </>
        )
      }
    </PaymentPlanEditTable>
  )
}

export default connect() (EditTable);