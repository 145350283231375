import React from 'react'
import _ from "lodash";
import PropTypes from "prop-types";

import useStyles from './styles'
import {toGermanFormat} from "../../../../../../../../utils/numberFormater";

const ClearingAccount = ({depot, name, amount, date, iban}) => {

  const classes = useStyles()

  return (
    <p style={{margin: 0, display: "inline"}}>
      {!!depot && (
        <span className={classes.item}>
          <b>Depot:</b>
          <span>{depot}</span>
        </span>
      )}
      {!!name && (
        <span className={classes.item}>
          <b>Kontonummer:</b>
          <span>{name}</span>
        </span>
      )}
      {!_.isNil(amount) && (
        <span className={classes.item}>
          <b>Saldo:</b>
          <span>{toGermanFormat(amount || 0, '€ ')}</span>
        </span>
      )}
      {!!date && (
        <span className={classes.item}>
          <b>Datum:</b>
          <span>{date.format('DD.MM.YYYY')}</span>
        </span>
      )}
      {iban && (
        <span className={classes.item}>
          <b>IBAN:</b>
          <span>{iban}</span>
        </span>
      )}
    </p>
  )
}

ClearingAccount.propTypes = {
  /** Name of the portfolio, that clearing account belongs to */
  depot: PropTypes.string.isRequired,
  /** Name of the clearing account */
  name: PropTypes.string.isRequired,
  /** Amount of clearing account */
  amount: PropTypes.number,
  /** Date of clearing account */
  date: PropTypes.any.isRequired,
  /** IBAN of clearing account */
  iban: PropTypes.string
}

export default React.memo(ClearingAccount)
