import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    width: '100%',
    padding: 30,
    borderRadius: 5,
    boxShadow: '0px 3px 15px #00000033'
  },
  header: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    color: '#4D505B'
  },
  text: {
    fontFamily: 'Roboto-Regular',
    color: '#4D505B',
    fontSize: 16,
    marginBottom: 0
  }
})

export default makeStyles(styles)