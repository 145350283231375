import React from "react";

import {isRequiredQuestion, isValidValue} from "../../../../../utils";
import {FIELD_REQUIRED_MSG} from "../../../../../constants";
import SelectFormElement from "../../formElement/SelectFormElement";

function isSelectQuestionValid(question, step, afterChange, service) {
  if(isRequiredQuestion(question)){
    const isValid = isValidValue(question.answer);
    question.error = isValid ? null : FIELD_REQUIRED_MSG;

    return isValid;
  }

  return true;
}

export const SelectQuestionMethods = {
  required: isRequiredQuestion,
  valid: isSelectQuestionValid
}

const SelectQuestion = props => {
  const {
    question,
    onAnswerChange
  } = props;

  const label = question.question_text;
  const required = SelectQuestionMethods.required(question);
  let options = question.config.choices || [];
  if(!required){
    options = [{id: 'empty', uid: null, text: "\u00a0"}, ...options]
  }

  options = options.map(o => {
    return {id: o.id, value: o.uid, label: o.text, info: o.info}
  });

  const handleChange = (value) => {
    question.error = null;  // clean error
    onAnswerChange(question.uid, value);
  };

  return (
    <SelectFormElement
      label={label}
      required={required}
      value={question.answer || ''}
      error={question.error}
      onChange={handleChange}
      options={options}
      custom_classes={question.custom_classes}
      disabled={question.disabled}
      tooltip={question.tooltip}
    />
  );
};

export default SelectQuestion;