import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { displayErrorSnackBar } from "../../../../../components/SnackbarProvider/actions";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

import {
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
} from "@material-ui/core";

import { Button } from "../../components";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import {
  RadioButtonsSection,
} from "../../../../../components/Inputs";

import {
  FundFiltersSection,
  SharesFiltersSection,
  BondsFiltersSection,
} from "./components";

import useStyles from "./styles";
import {
  FilterIcon,
  ArrowIcon,
  DeleteIcon,
} from "../../../../../images";
import {ASSET_CLASSES_OPTIONS} from "./constants";
import clsx from "clsx";

import StickyNavigation from "../../components/StickyNavigation/StickyNavigation";
import useAssetInfoStatic from "../../../../../hooks/useAssetInfoStatic";
import ResetButton from '../../../../TransactionsMonitoring/components/Filters/components/ResetButton/ResetButton';

export function FilterSection(props) {
  const classes = useStyles();

  const {
    title,
    flexDirection,
    onResetClick,
    sectionName,
    count,
    expandedByDefault,
  } = props;

  const [expanded, setExpanded] = React.useState(expandedByDefault);

  const handleResetClick = () => {
    if (onResetClick) {
      onResetClick(sectionName);
    }
  };

  return (
    <ExpansionPanel
      classes={{
        root: classes.filterSectionExpansionPanelRoot,
        expanded: classes.filterSectionExpansionPanelExpanded,
      }}
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
    >
      <ExpansionPanelSummary
        classes={{
          root: classes.filterSectionSummaryRoot,
          expanded: classes.filterSectionSummaryExpanded,
          content: classes.filterSectionSummaryContent,
          expandIcon: classes.filterSectionSummaryIcon,
        }}
        expandIcon={
          <ArrowIcon color={"#202A38"} style={{ transform: "rotate(90deg)" }} />
        }
      >
        <p className={classes.filterSectionSummaryTitle}>
          {title}
          {count && count > 0 ? (
            <span style={{ marginLeft: 5 }}>({count})</span>
          ) : null}
        </p>
        {count && count > 0 ? (
          <ResetButton onClick={handleResetClick} />
        ) : null}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        classes={{
          root: classes.filterSectionDetailsRoot,
        }}
      >
        <div
          className={classes.filterSectionDetailsContainer}
          style={{ flexDirection }}
        >
          {props.children}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

FilterSection.propTypes = {
  /** Filter section title */
  title: PropTypes.string.isRequired,

  /** Content direction */
  flexDirection: PropTypes.string,

  onResetClick: PropTypes.func.isRequired,

  sectionName: PropTypes.string.isRequired,

  count: PropTypes.number,
};

FilterSection.defaultProps = {
  flexDirection: "column",
};

export function FilterSubSection(props) {
  const {
    title,
    displayFlex,
    tooltip,
    onResetClick,
    resetButtonVisible,
    resetFieldsNames,
    ...restProps
  } = props;

  const classes = useStyles();

  const handleResetButtonClick = (event) => {
    if (onResetClick) {
      onResetClick(resetFieldsNames);
    }
  };

  return (
    <Grid
      item
      {...restProps}
      className={clsx(displayFlex && classes.filtersSubSectionFlex)}
    >
      <div className={classes.titleContainer}>
        {typeof title == "string" ? (
          <p className={classes.filterSubSectionTitle}>{title}</p>
        ) : (
          <>{title}</>
        )}
        {tooltip && (
          <>
            <Tooltip
              placement={"right"}
              title={tooltip}
              classes={{ tooltip: classes.tooltip }}
            >
              <InfoIcon className={classes.helpIcon} color={"primary"}/>
            </Tooltip>
          </>
        )}
        {resetButtonVisible && (
          <Button onClick={handleResetButtonClick}>
            <Button.Icon>
              <DeleteIcon />
            </Button.Icon>
          </Button>
        )}
      </div>
      <div>{props.children}</div>
    </Grid>
  );
}

FilterSubSection.propTypes = {
  title: PropTypes.string.isRequired,
};

const AssetInfoStaticContext = React.createContext();

export function useAssetInfoStaticContext() {
  const context = React.useContext(AssetInfoStaticContext);
  if (!context) {
    throw new Error(
      "Extracting context without wrapping your component with context Provider!"
    );
  }

  return context;
}

function ExtendedFilters(props) {
  const {
    onFiltersApproved,
    setDefault,
    onFiltersCountChanged,
    visible,
    useButtons,
    errors,
    predefinedFilter,
    customerId,
    disabledAssetClasses
  } = props;

  const classes = useStyles();

  const INACTIVE_INPUT_COLOR = "#202A38";

  const [assetClass, setAssetClass] = React.useState(
    ASSET_CLASSES_OPTIONS[0].value
  );
  const [assetClassRef, setAssetClassRef] = React.useState();
  const [resetFiltersSignal, setResetFiltersSignal] = React.useState();
  const [totalFiltersCount, setTotalFiltersCount] = React.useState();
  const assetInfoStatic = useAssetInfoStatic();

  React.useEffect(() => {
    setTotalFiltersCount(0);
  }, [assetClass]);

  const handleFiltersApproved = () => {
    if (onFiltersApproved) {
      let filters = {
        //esg: esgEbaled,
        asset_class: assetClass,
        //return_type: returnType
      };

      let otherFilters = assetClassRef.buildFilters();

      if (_.isUndefined(otherFilters)) {
        props.dispatch(
          displayErrorSnackBar("Die Filter sind nicht richtig ausgefüllt.")
        );
        return;
      }

      if (assetClassRef) {
        filters = {
          ...filters,
          ...otherFilters,
        };
      }

      filters = Object.fromEntries(
        Object.entries(filters).filter(([k, v]) => {
          return (
            v != null &&
            ((_.isString(v) && !_.isEmpty(v)) ||
              (_.isArray(v) && !_.isEmpty(v)) ||
              !_.isString(v))
          );
        })
      );

      onFiltersApproved(filters, totalFiltersCount);
    }
  };

  const applyAssetClassRef = (ref) => {
    setAssetClassRef(ref);
  };

  const handleResetFiltersClick = () => {
    setResetFiltersSignal(Date.now);
  };

  const button = (
    <PrimaryButton
      text={`Filter${
        totalFiltersCount ? ` (${totalFiltersCount})` : ""
      } anwenden`}
      icon={<FilterIcon color={!totalFiltersCount ? "#ADB6BD" : "#0092E5"} />}
      customClasses={{
        root: classes.applyFiltersButton,
        disabled: classes.applyFiltersButtonDisabled,
      }}
      disabled={!totalFiltersCount}
      onButtonClick={handleFiltersApproved}
    />
  );

  return (
    <AssetInfoStaticContext.Provider value={assetInfoStatic}>
      <div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FilterSubSection title="" style={{ padding: "0 0px" }}>
              <RadioButtonsSection
                options={ASSET_CLASSES_OPTIONS}
                disabledOptions={disabledAssetClasses}
                value={assetClass}
                onChange={setAssetClass}
                flexDirection="row"
              />
            </FilterSubSection>
            <Button onClick={handleResetFiltersClick}>
              <Button.Icon variant="contained">
                <DeleteIcon />
              </Button.Icon>
              <Button.Content text="Alle Filtern Zurücksetzen" />
            </Button>
          </div>
          {assetClass === ASSET_CLASSES_OPTIONS[0].value && (
            // here is were filters are called
            <FundFiltersSection
              onRef={applyAssetClassRef}
              setDefault={resetFiltersSignal}
              onFilterChanged={setTotalFiltersCount}
              errors={_.get(errors, "data")}
              onSearchFieldApprove={handleFiltersApproved}
              predefinedFilter={predefinedFilter}
              customerId={customerId}
            />
          )}
          {assetClass === ASSET_CLASSES_OPTIONS[1].value && (
            <SharesFiltersSection
              onRef={applyAssetClassRef}
              setDefault={resetFiltersSignal}
              onFilterChanged={setTotalFiltersCount}
              errors={_.get(errors, "data")}
              onSearchFieldApprove={handleFiltersApproved}
            />
          )}
          {assetClass === ASSET_CLASSES_OPTIONS[2].value && (
            <BondsFiltersSection
              onRef={applyAssetClassRef}
              setDefault={resetFiltersSignal}
              onFilterChanged={setTotalFiltersCount}
              onSearchFieldApprove={handleFiltersApproved}
            />
          )}
        </div>
        {/*<div className={classes.stickyActions}>*/}
        {/*  <WidgetActions justifyContent="flex-end" padding={4} >*/}
        {/*    <PrimaryButton*/}
        {/*      text="Filter anwenden"*/}
        {/*      icon={<FilterIcon />}*/}
        {/*      variant="outlined"*/}
        {/*      onButtonClick={handleFiltersApproved}*/}
        {/*    />*/}
        {/*  </WidgetActions>*/}
        {/*</div>*/}

        {useButtons ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {button}
          </div>
        ) : (
          <StickyNavigation>
            {visible && (
              <Container
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {button}
              </Container>
            )}
          </StickyNavigation>
        )}
      </div>
    </AssetInfoStaticContext.Provider>
  );
}

ExtendedFilters.propTypes = {};

export default connect()(ExtendedFilters);
