import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import _ from 'lodash'

import { Tooltip } from '@material-ui/core'

import useStyles from './styles'
import { CheckIcon, InfoIcon } from '../../../../images'

function FilterBudges(props) {

  const {
    title,
    items,
    margin,
    multiple,
    onChange,
    loading,
  } = props;

  const classes = useStyles()

  // selected will be set to list in useEffect for [items]
  const [selected, setSelected] = React.useState(undefined);

  React.useEffect(() => {
    let selectedItems = []

    items.forEach(item => {
      if (item.selected) {
        selectedItems.push(item)
      }
    });

    setSelected(selectedItems);
  }, [items]);

  React.useEffect(() => {
    if (typeof selected !== 'undefined' && onChange) {
      onChange(selected)
    }
  }, [selected])

  const renderItems = () => {
    if (items) {

      return items.map(item => {

        let isSelected = !!_.find(selected, i => i.id == item.id)

        return (
          <span className={clsx(classes.itemHolder, loading ? classes.itemDisabled : '')}>
            <span 
              className={clsx([classes.itemTitle, isSelected ? classes.itemActive: ''])}
              onClick={() => handleItemClick(item)}
              key={item.id}
            >
              {isSelected && (
                <CheckIcon color="white"/> 
              )}
              {item.name}
            </span>
            {item.info && (
              <Tooltip title={item.info} arrow classes={{
                tooltip: classes.tooltipPopper,
                tooltipArrow: classes.tooltipArrow
              }}>
                <span className={classes.infoIcon}>
                  <InfoIcon color={"#D8DCDF"}/>
                </span>
              </Tooltip>
            )}
          </span>
        )
      })
    }
  }

  const handleItemClick = (item) => {

    if (multiple) {
      let selectedItemsCopy = [...selected];

      if (!!_.find(selected, i => i.id == item.id)) {
        selectedItemsCopy = _.filter(selectedItemsCopy, ic => ic.id != item.id);
      } else {
        selectedItemsCopy.push(item);
      }

      setSelected(selectedItemsCopy);
    } else {
      if (!!_.find(selected, i => i.id == item.id)) {
        setSelected([]);
      } else {
        setSelected([item])
      }
    }
  }

  return (
    <div className={classes.container} style={{margin: margin || margin == 0 ? margin : 'inherite'}}>
      {title && (
        <span className={classes.title}>{title}:</span>
      )}
      <div style={{display: "flex", flexDirection: 'row', flexWrap: 'wrap'}}>
        {renderItems()}
      </div>
    </div>
  )
}

FilterBudges.propTypes = {
  /** FIlter section title */
  title: PropTypes.string,

  /** Filter section items */
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })),

  margin: PropTypes.number,

  /** Flag, that indicate, if multiple items could be selected */
  multiple: PropTypes.bool
}

FilterBudges.defaultProps = {
  multiple: true
}

export default FilterBudges

