const styles = theme => ({
  details: {
    color: '#313D44',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  name: {
    margin: 0,
    marginBottom: 5,
    fontSize: 22,
    fontFamily: 'Roboto-Bold',

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  countWrapper: {
    margin: 0,
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  count: {
    fontFamily: 'Roboto-Bold'
  }
});

export default styles;