export const REPORT_DISTRIBUTION_TYPE = {
  PDF: {
    value: 2,
    description: 'Post/ PDF'
  },
  POSTBOX: {
    value: 1,
    description: 'Elektronisches Kundenpostfach'
  }
};

export const SENDING_DATE = {
  BEGIN: {
      value: 1,
      description: 'Investmentbeginn'
  },
  ONE_Y: {
      value: 2,
      description: '1 Jahr'
  },
  THREE_Y: {
      value: 3,
      description: '3 Jahre'
  },
  FIVE_Y: {
      value: 4,
      description: '5 Jahre'
  }
};

export const REPORT_TYPES = {
  BASIC: {
      value: 1,
      description: 'Basis'
  },
  EXPERT: {
      value: 2,
      description: 'Expert'
  }
};

export const REPORT_GENERATION_TYPES = {
  SINGLE: {
      value: 1,
      description: 'Gesamtreport über alle Kunden'
  },
  SPLITTED: {
      value: 2,
      description: 'Einzelreports per Kunde'
  }
};

export const GROUP_NAME_MAX_LENGTH = 120;