export const PROOF_ID = 'proof_of_guardianship';

export const buildDataSteps = () => {
  const proof_of_guardianship = {
    "uid": PROOF_ID,
    "name": "Sorgerechtsbescheinigung",
    "behaviour": ["dynamic", "ID Verifikation"],
    "custom_classes": {"stepName": "text-left"},
    "question": [
      {
        "uid": PROOF_ID,
        "question_text": "Sorgerechtsbescheinigung",
        "question_info": "Stellen Sie bitte sicher, dass alle erforderlichen Seiten zum Dokument hinzugefügt werden.",
        "type": "legitimation_document",
        "size": 8,
        "custom_classes": {"labelRoot": "bold"},
        "required_msg": "Als alleiniger Erziehungsberechtigter benötigen Sie eine Sorgerechtsbescheinigung zur Eröffnung des Depots. " +
          "Leider können wir Ihren Depoteröffnungsantrag nicht weiter bearbeiten, solange uns die Sorgerechtsbescheinigung nicht vorliegt." +
          " Wir bitten um Ihr Verständniss.",
        "config": {
          "accept": ".pdf",
          "mapping": {
            "dante_field": PROOF_ID
          }
        },
        "optional": false,
      }
    ]
  };



  return {
    steps: [proof_of_guardianship]
  }
};

export const custodyCertificateSteps = buildDataSteps().steps.map(s => s.uid);