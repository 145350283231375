import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import clsx from "clsx";
import _ from 'lodash';
import useStyles from './styles';

FilterInput.defaultProps = {
  withDefault: true
}

export default function FilterInput({ label, handleDropdownSwitch, multiple, value, options, onChange, withDefault, ...props }) {
  const classes = useStyles();
  const customClasses = props.customclasses || {}

  const selectOptions = React.useMemo(() => {
    if (multiple && withDefault && options.length > 1) {
      return [{name: 'Alle'}, ...options];
    }
    return options;
  }, [options, multiple]);

  const [isOpen, setIsOpen] = useState(false)
  const [popupIconTitle, setPopupIconTitle] = useState('offen')

  useEffect(() => {
    setPopupIconTitle(isOpen ? 'schließen' : 'offen')
  }, [isOpen])

  const handleSwitch = () => {
    if (handleDropdownSwitch) {
      handleDropdownSwitch(isOpen)
    }
    setIsOpen(!isOpen)
  }

  const handleChange = (event, items) => {
    if (_.isArray(items) && !!_.find(items, i => i.name === 'Alle')) {
      onChange(event, options.length === value.length ? [] : options);
    } else {
      onChange(event, items)
    }
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 100,
  });

  return (
    <div classes={{ root: classes.root }}>
      {!!label && <Typography classes={{ root: classes.label }}>{label}</Typography>}
      <Autocomplete
        disableCloseOnSelect
        clearText={'löschen'}
        noOptionsText={"keine Ergebnisse"}
        filterOptions={filterOptions}
        classes={{
          root: classes.autocompleteRoot,
          inputRoot: clsx(classes.inputRoot, customClasses.autocompleteInput),
          option: clsx(classes.option, customClasses.option),
          paper: classes.paper,
          listbox: classes.listbox,
        }}
        getOptionLabel={(option) => option.name}
        popupIcon={<SearchIcon titleAccess={popupIconTitle} htmlColor="#202A38" />}
        renderInput={(params) => {
          return <TextField {...params} variant="outlined" />
        }}
        onOpen={handleSwitch}
        onClose={handleSwitch}
        onChange={handleChange}
        multiple={multiple}
        value={value}
        options={selectOptions}
        {...props}
      />
    </div>
  );
}
