import React, { Component } from 'react'

import {withStyles, CircularProgress, Container, Grid} from '@material-ui/core'

import { ESignResource } from '../../utils/api'
import { BusinessCase } from './components'

import Pagination from "../../components/Pagination/Pagination";
import PaginationInput from "../../components/Pagination/PaginationInput";
import UploadDocumentModal from "./components/UploadDocumentModal/UploadDocumentModal";
import {
  DOCUMENTS_SINGING_STATUS_FILTER_OPTIONS,
} from "../SignedDocumentsApproval/constants";
import {connect} from "react-redux";
import moment from "moment/moment";
import styles from './styles'
import Filters from "../TransactionsMonitoring/components/Filters/Filters";
import {ONBOARDING_BUSINESS_CASE_STATUS} from "./components/BusinessCase/constants";


const UploadDocumentModalContext = React.createContext({
  onOpenUploadDocumentModal: undefined
})

export function useUploadDocumentModalContext() {

  const context = React.useContext(UploadDocumentModalContext)
  if (!context) {
    throw new Error('Extracting context without wrapping your component with context Provider!')
  }

  return context

}

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
});

class LegalDocumentsSigningStatus extends Component {
  constructor(props) {
    super(props);

    // override to make ALL option value undefined - not to perform filtering on back-end when it is selected
    this.APPROVAL_STATUS_OPTIONS = [...DOCUMENTS_SINGING_STATUS_FILTER_OPTIONS]

    // building options from mapping of online status values and labels
    this.OLINE_STATUS_FILTER_OPTIONS = [...Object.entries(ONBOARDING_BUSINESS_CASE_STATUS).map(([id, value]) => {
      return {id, value};
    })];

    // setting constants
    this.defaultOptions = {
      // sort_by: 'customer_last_name',
      search_word: '',
      // bc filters
      status: [...this.APPROVAL_STATUS_OPTIONS.map(item => item.id)],
      online_status: [...this.OLINE_STATUS_FILTER_OPTIONS.map(item => item.id)],
      dates: [moment(), moment()]  // show only today cases by default
    };

    // setting sate
    this.state = {
      businessCases: {
        data: undefined,
        loading: true,
        errors: undefined
      },
      paginationOptions: {
        totalRecordsCount: 0,
        totalPagesCount: null,
        currentPage: 0,
        pageSize: 15
      },
      uploadDocumentModal: {
        open: false,
        businessCaseId: undefined,
        documentName: undefined,
        documentExternalId: undefined,
        documentEDocBoxId: undefined,
        documentFile: undefined,
        onSuccess: undefined,
        onError: undefined,
        loading: false
      },
      // for filters
      minBusinessCaseDate: undefined,
      maxBusinessCaseDate: undefined,
      filters: {...this.defaultOptions}
    }

    this.handleApplyFilters = this.handleApplyFilters.bind(this)
    this.handleResetFilters = this.handleResetFilters.bind(this)
  }

  componentDidMount() {
    this.fetchBusinessCases(this.state.filters, true);
    this.setAppBackground()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.paginationOptions.currentPage != this.state.paginationOptions.currentPage) {
      this.fetchBusinessCases(this.state.filters)
    }
  }

  handleApplyFilters(filters){
    this.fetchBusinessCases(filters)
  }

  handleResetFilters(){
    this.setState({filters: this.defaultOptions})
  }

  componentWillUnmount() {
    this.revertAppBackground()
  }

  handleOpenUploadDocumentModal = (businessCaseId,  documentName, documentEDocBoxId, documentExternalId, onSuccess, onError) => {
    this.setState({
      uploadDocumentModal: {
        open: true,
        businessCaseId,
        documentName,
        documentEDocBoxId,
        documentExternalId,
        onSuccess,
        onError,
        documentFile: undefined,
        loading: false
      }
    })
  }

  updateAppBackground = (color) => {
    let $app = document.getElementById('app-main')
    if ($app) {
      $app.style.backgroundColor = color
    }
  }

  setAppBackground = () => {
    this.updateAppBackground('white')
  }

  revertAppBackground = () => {
    this.updateAppBackground('#EEF0F2')
  }

  fetchBusinessCases = async (filters, withDateRange) => {
    this.setState({
      businessCases: {
        data: undefined,
        errors: undefined,
        loading: true
      }
    })

    try {
      let filtersToUse = {...filters}

      filtersToUse.start_date = filtersToUse.dates[0] && moment(filtersToUse.dates[0]).format('YYYY-MM-DD');
      filtersToUse.end_date = filtersToUse.dates[1] && moment(filtersToUse.dates[1]).format('YYYY-MM-DD');
      delete filtersToUse.dates;

      let response = await ESignResource.getBusinessCases({
        page: this.state.paginationOptions.currentPage + 1,
        page_size:this.state.paginationOptions.pageSize,
        with_date_range: withDateRange,  // is passed on mount to get max and min available dates
        ...filtersToUse
      })

      let newState = {
        businessCases: {
          data: response.data || [],
          loading: false,
          errors: undefined
        },
        paginationOptions: {
          ...this.state.paginationOptions,
          totalRecordsCount: response.num_records,
          totalPagesCount: response.num_pages
        },
        filters
      };

      if(withDateRange){
        let range_start = response.range_start ? moment(response.range_start) : undefined;
        let range_end = response.range_end ? moment(response.range_end) : undefined;

        newState.minBusinessCaseDate = range_start;
        newState.maxBusinessCaseDate = range_end
      }

      this.setState(newState)

    } catch (errors) {
      this.setState({
        businessCases: {
          data: undefined,
          errors,
          loading: false
        }
      })
    }
  }

  handleGetStatusButtonClick = async (case_id) => {
    let response = await ESignResource.getBusinessCaseDetails(case_id)
  }

  handlePageChanged = (selectedPage) => {
    this.setState({
      paginationOptions: {
        ...this.state.paginationOptions,
        currentPage: selectedPage
      }
    })
  }

  getStartRecordsNumber = () => {
    return this.state.paginationOptions.currentPage * this.state.paginationOptions.pageSize + 1
  }

  getTillRecordsNumber = () => {

    let currentPage = this.state.paginationOptions.currentPage;

    if ((currentPage || currentPage == 0) && this.state.businessCases.data) {

      if (this.state.businessCases.data.length < this.state.paginationOptions.pageSize) {
        return currentPage * this.state.paginationOptions.pageSize + this.state.businessCases.data.length
      } else {
        return currentPage * this.state.paginationOptions.pageSize + this.state.paginationOptions.pageSize
      }

    }
  }

  handleUploadDocumentFileChanged = (file) => {
    this.setState({
      uploadDocumentModal: {
        ...this.state.uploadDocumentModal,
        documentFile: file
      }
    })
  }

  handleUploadDocumentModalClose = () => {
    this.setState({
      uploadDocumentModal: {
        open: false,
        businessCaseId: undefined,
        documentName: undefined,
        documentEDocBoxId: undefined,
        documentExternalId: undefined,
        documentFile: undefined,
        onSuccess: undefined,
        onError: undefined,
        loading: false
      }
    })
  }

  handleUploadDocumentClick = async () => {
    try {

      this.setState({
        uploadDocumentModal: {
          ...this.state.uploadDocumentModal,
          loading: true
        }
      })

      const requestBody = new FormData()
      if (this.state.uploadDocumentModal.documentEDocBoxId) {
        requestBody.append('e_doc_box_document_id', this.state.uploadDocumentModal.documentEDocBoxId)
      }
      requestBody.append('external_document_id', this.state.uploadDocumentModal.documentExternalId)
      requestBody.append('file', this.state.uploadDocumentModal.documentFile)

      const response = await ESignResource.postUploadBusinessCaseDocuments(
        this.state.uploadDocumentModal.businessCaseId, requestBody)

      this.state.uploadDocumentModal.onSuccess && this.state.uploadDocumentModal.onSuccess(response)

      this.setState({
        uploadDocumentModal: {
          open: false,
          businessCaseId: undefined,
          documentName: undefined,
          documentEDocBoxId: undefined,
          documentExternalId: undefined,
          documentFile: undefined,
          onSuccess: undefined,
          onError: undefined,
          loading: false
        }
      })

    } catch (errors) {
      this.state.uploadDocumentModal.onError && this.state.uploadDocumentModal.onError(errors)
      this.setState({
        uploadDocumentModal: {
          ...this.state.uploadDocumentModal,
          loading: false
        }
      })
    }
  }

  render() {

    const { classes } = this.props;

    return (
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{marginBottom: 40}}>
            <h1 className={classes.header}>Kundenunterlagen E-Unterschrift</h1>
          </Grid>

          <Grid item xs={12} style={{marginBottom: 40}}>
            <Filters
              initValue={this.state.filters}
              defaultValue={this.defaultOptions}
              onSubmit={this.handleApplyFilters}
              label="Kundenunterlagen filtern"
            >
                <Filters.InputField
                  loading={this.state.businessCases.loading}
                  name="search_word"
                  label="Kunde"
                  placeholder="Kundenname, Kundennummer"
                  onKeyDown={this.handleApplyFilters}
                  GridProps={{
                    md: 4,
                    xs: 12
                  }}
                />
                <Filters.Dropdown
                  loading={this.state.businessCases.loading}
                  name="status"
                  label="E-Unterschrift-Status"
                  placeholder="Wählen Sie eine E-Unterschrift-Status"
                  options={this.APPROVAL_STATUS_OPTIONS}
                  emptyErrorMessage="Bitte wählen sie einen E-Unterschrift-Status aus."
                  GridProps={{
                    md: 4,
                    xs: 12
                  }}
                />
                <Filters.Dropdown
                  loading={this.state.businessCases.loading}
                  name="online_status"
                  label="Online-Status"
                  placeholder="Wählen Sie eine Online-Status"
                  options={this.OLINE_STATUS_FILTER_OPTIONS}
                  emptyErrorMessage="Bitte wählen sie einen Online-Status aus."
                  GridProps={{
                    md: 4,
                    xs: 12
                  }}
                />
                <Filters.SimpleDateRange
                  name="dates"
                  minDate={this.state.minBusinessCaseDate}
                  maxDate={moment()}  // as we show today cases by default set max data as today as well
                  loading={this.state.businessCases.loading}
                  GridProps={{
                    md: 4,
                    sm: 6,
                    xs: 12
                  }}
                />
                <Filters.SubmitButton disabled={this.state.businessCases.loading} />
              </Filters>
          </Grid>

          {
            this.state.businessCases.loading ? (
              <Grid item className={classes.loadingContainer} xs={12}>
                <CircularProgress color="primary" size={50}/>
              </Grid>
            ) : (
              <>
                { this.state.businessCases.data && this.state.businessCases.data.length > 0 && (
                  <UploadDocumentModalContext.Provider
                    value={{
                      onOpenUploadDocumentModal: this.handleOpenUploadDocumentModal
                  }}
                  >
                    {/* Renders all business cases */}
                    { this.state.businessCases.data && this.state.businessCases.data.map(businessCase => (
                      <Grid item xs={12}>
                        <BusinessCase businessCase={businessCase} bordered key={`${businessCase.case_id}_${businessCase.url}`}/>
                      </Grid>
                    ))}

                    {/* Renders pagination */}
                    <Grid item xs={12}>
                      <div className={classes.paginationContainer}>
                        <span className={classes.recordsOnPage}>
                          {this.getStartRecordsNumber()} - {this.getTillRecordsNumber()} von {this.state.paginationOptions.totalRecordsCount}
                        </span>
                        <Pagination
                          totalPageCount={this.state.paginationOptions.totalPagesCount}
                          currentPage={this.state.paginationOptions.currentPage}
                          handlePageChanged={this.handlePageChanged}
                        />
                        <PaginationInput
                          totalPageCount={this.state.paginationOptions.totalPagesCount}
                          currentPage={this.state.paginationOptions.currentPage}
                          label="Seite aufrufen:"
                          classes={{
                            label: classes.paginationInputLabel
                          }}
                          handlePageChanged={this.handlePageChanged}
                        />
                      </div>
                    </Grid>
                  </UploadDocumentModalContext.Provider>
                )}

                {!this.state.businessCases.errors && (!this.state.businessCases.data || !this.state.businessCases.data.length) && (
                  <Grid item className={classes.loadingContainer} xs={12}>
                    <p>Es sind keine Daten vorhanden.</p>
                  </Grid>
                )}

                {this.state.businessCases.errors && (
                  <Grid item className={classes.loadingContainer} xs={12}>
                    <p>Beim Laden der Geschäftsfalldaten trat ein Fehler auf. Bitte versuchen Sie es später noch einmal.</p>
                  </Grid>
                )}
              </>
            )
          }
        </Grid>
        <UploadDocumentModal
          open={this.state.uploadDocumentModal.open}
          loading={this.state.uploadDocumentModal.loading}
          documentName={this.state.uploadDocumentModal.documentName}
          onFileChanged={this.handleUploadDocumentFileChanged}
          onModalClose={this.handleUploadDocumentModalClose}
          onUploadDocumentClick={this.handleUploadDocumentClick}
        />
      </Container>
    )
  }
}

export default withStyles(styles)(connect(mapStateToProps)(LegalDocumentsSigningStatus))
