import React from 'react';
import _ from 'lodash'

import withTheme from '@material-ui/core/styles/withTheme';

import {PortfolioUtils} from '../../../../../../../utils/utils';
import ChartSectionBordered from "../../../../../components_v2/ChartSectionBordered/ChartSectionBordered";
import {StructureTable} from "../../../../../components_v2/StructureTable/StructureTable";
import {
  CustomerDashboardNoDataPlaceholder
} from "../../../../../../FactSheetsPage/components/NoDataPlaceholder";
import {portfolioStructurePropsAreEquals} from '../utils';

const PortfolioBroadAssetClassesStructure = React.memo((props) => {
  const {
    dashboardData,
    preferredColor,
    expanded,
    onExpanded,
  } = props;

  const portfolioStructure = dashboardData && dashboardData.portfolio_structure;

  let data = [];
  if (portfolioStructure) {
    data = PortfolioUtils.getPortfolioBroadAssetClasses(portfolioStructure)
  }
  const hasData = !_.isEmpty(data);

  return (
    <ChartSectionBordered
      title={<b>{data.length !== 1 ? 'Produktgattungen' : 'Produktgattung'}</b>}
      expanded={expanded}
      onExpanded={onExpanded}
      skipContentPadding={hasData}
      content={(
        <>
          {hasData ? (
            <StructureTable
              series={data || []}
              preferredColor={preferredColor}
            />
          ) : (
            <CustomerDashboardNoDataPlaceholder />
          )}
        </>
      )}
    />
  )
}, portfolioStructurePropsAreEquals);

export default withTheme(PortfolioBroadAssetClassesStructure)
