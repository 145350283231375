import {createSelector} from "reselect";

export const getAuth = (state) => state.get('auth')
export const getAuthSelector = createSelector(
  [getAuth],
  (auth) => auth.toJS()
)
export const getInvestmentPlatform = (state) => state.get('investmentPlatform');
export const getInvestmentPlatformSelector = createSelector(
  [getInvestmentPlatform],
  (investmentPlatform) => investmentPlatform.toJS()
);