export const DISTRIBUTION_TYPES = {
    POSTBOX: {
        value: 1,
        description: 'in elektronisches Kundenpostfach'
    },
    BRIEF: {
        value: 2,
        description: 'per Brief'
    }
};

export const DEFAULT_DISTRIBUTION_TYPE = DISTRIBUTION_TYPES.BRIEF;