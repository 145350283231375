import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import styles from "./styles";
import ExclusionCriteriaCard from "../card/index";
import _ from "lodash";
import clsx from "clsx";
import Tooltip from "../../../../../../../components/Tooltip";


export const NoDataTooltip = ({value, children, message}) => {
  if (value) {
    return children;
  }

  return (
    <Tooltip title={message}>
      <span>
        {children}
      </span>
    </Tooltip>
  )
}


class ExclusionCriteriaCards extends React.Component {
  constructor(props) {
    super(props);
  }

  itemHasData = (item) => {
    if (!_.isArray(item.dataField)) {
      return this.props.data[item.dataField];
    }

    return _.some(item.dataField, (field) => !!this.props.data[field]);
  }

  render() {
    const { classes, items, assetName } = this.props;
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        spacing={2}
        className={classes.container}
      >
        {items &&
          items.map((el) => {
            if (el.main) {
              return (
                <Grid item xs={12} className={clsx(classes.item, classes.itemMain, !this.itemHasData(el) && classes.itemInactive)}>
                  <NoDataTooltip
                    value={this.itemHasData(el)}
                    message={`${assetName} erfüllt dieses Kriterium nicht.`}
                  >
                  <ExclusionCriteriaCard item={el} listHide={this.props.hide} />
                  </NoDataTooltip>
                </Grid>
              );
            } else {
              if (this.props.hide[items[0].value] || this.props.hide["all"]) {
                return (
                  <Grid item xs={12} className={classes.item} style={{opacity: this.itemHasData(el) ? 1 : 0.5}}>
                    <NoDataTooltip
                      value={this.itemHasData(el)}
                      message={`Dieses Kriterium wird hinsichtlich ${el.text} nicht explizit berücksichtigt.`}
                    >
                      <ExclusionCriteriaCard item={el} />
                    </NoDataTooltip>
                  </Grid>
                );
              }
            }
          })}
      </Grid>
    );
  }
}

export default withStyles(styles)(ExclusionCriteriaCards);
