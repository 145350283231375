import React from "react";
import _ from "lodash";
import clsx from "clsx";
import Grid from '@material-ui/core/Grid';
import ReportSendingDate from '../../ReportSendingDate';
import ReportType from '../../../../DashboardSettings/components/CustomersList/components/ReportType';
import ReportsOptions from '../../AdditionalReportOptions';
import { REPORTS_OPTIONS } from '../../AdditionalReportOptions/constants';
import { REPORT_TYPES } from '../../../../DashboardSettings/components/CustomersList/components/ReportType/constants';
import useStyles from './styles';
import {parseResponse, PortfolioHandlerResource} from "../../../../../utils/api";
import {getErrorMessage} from "../../../../../utils/utils";
import PortfolioList from "../../../../../components/ProfileDetails/components/PortfolioList";
import SeriesReportingBenchmarkModal from '../../SeriesReportingBenchmark/benchmark';
import SeriesBenchmarkConfiguration from '../../SeriesReportingBenchmark/index';
import Switch from "../../../../../components/AssetModal/components/Switch";
import {AUTO_REPORT_PERIODICITY} from '../../../constants';
import FormLabel from "@material-ui/core/FormLabel";
import {FormControl} from "@material-ui/core";
import SeriesBenchmarksList from "../../SeriesReportingBenchmark/components/SeriesBenchmarksList";
import WarningTooltip from "../../../../../components/WarningTooltip";
import {aggregateSelectedDisabledExplanation} from "../../../../DashboardSettings/constants";
import ReportsAreasVisibility
  from '../../../../DashboardSettings/components/CustomersList/components/ReportsAreasVisibility';
import {
  REPORTS_AREAS
} from '../../../../DashboardSettings/components/CustomersList/components/ReportsAreasVisibility/constants';


const RepotSettings = props => {

  const {
    reportSettings,
    handleSettingUpdate,
    loading,
    isGroupReporting,
    benchmarks,
    handleBenchmarkUpdate,
    setNextBtnDisabled,
  } = props;
  const classes = useStyles();

  const [benchmarkConfiguration, setBenchmarkConfiguration] = React.useState({});

  // depots can be selected only for single customer report generation
  const [selectedDepots, setSelectedDepots] = React.useState([]);
  const [customerDepots, setCustomerDepots] = React.useState({
    data: undefined,
    loading: false,
    errors: undefined
  });

   React.useEffect(() => {
     if(!isGroupReporting){
       fetchCustomerDepots()
     }
   }, []);

   const fetchCustomerDepots = async () => {
    if(_.isNil(reportSettings)) { return; }

    try {
      setCustomerDepots({
        data: undefined,
        loading: true,
        errors: undefined
      });

      let response = await PortfolioHandlerResource.getCustomerDepots(reportSettings.customer_id);

      parseResponse(response, 'portfolios', (data) => {
        setCustomerDepots({
          data: data,
          loading: false,
          errors: undefined
        });

        // select portfolios from settings or all
        let selectedPortfolios = []
        if(_.get(reportSettings, 'portfolio_ids')){
          selectedPortfolios = _.filter(data, (portfolio) =>
            _.some(reportSettings.portfolio_ids, p => p==portfolio.depotNumber) )
        }

         // if customer did not pass series reporting process before - selectedPortfolios is empty
        if(_.isEmpty(selectedPortfolios)){
          selectedPortfolios = data // set available portfolios as selected
          handleSelectedPortfolioChanged(data) // update settings with automatically selected
        }

        setSelectedDepots(selectedPortfolios);

      }, (errors) => {
        setCustomerDepots({
          data: [],
          loading: false,
          errors: getErrorMessage(errors)
        });
      });
    }
    catch (errors) {
      setCustomerDepots({
        data: [],
        loading: false,
        errors: getErrorMessage(errors)
      });
    }
  }

  const handleSelectedPortfolioChanged = (justSelectedPortfolios) => {
    setSelectedDepots(justSelectedPortfolios)

    if(!isGroupReporting) {
      let selectedDepotNumbers = justSelectedPortfolios.map(p => p.depotNumber)
      handleSettingUpdate('portfolio_ids')(selectedDepotNumbers)
    }
  }

  const handleValueChanged = (fieldname) => (value) => {
    if (!_.isNil(handleSettingUpdate)) {
      handleSettingUpdate(fieldname)(value);
    }
  };
  const handleDateRangeChanged = (fieldname, value) => {
    if (!_.isNil(handleSettingUpdate)) {
      handleSettingUpdate(fieldname)(value);
    }
  }

  const getSettingsAdditionalReports = () => {
    let additionalReports = [];

    if (reportSettings.with_cost) {
      additionalReports.push(REPORTS_OPTIONS.COST.value);
    }
    if (reportSettings.with_transactions) {
      additionalReports.push(REPORTS_OPTIONS.TRANSACTIONS.value);
    }

    return additionalReports;
  };

  const getSettingsAreasVisibility = () => {
    let areasVisibility = [];

    if (reportSettings.with_payment_plans) {
      areasVisibility.push(REPORTS_AREAS.PAYMENT_PLANS.value);
    }
    if (reportSettings.with_profit_loss) {
      areasVisibility.push(REPORTS_AREAS.PROFIT_LOSS.value);
    }
    if (reportSettings.include_historical_portfolios) {
      areasVisibility.push((REPORTS_AREAS.INCLUDE_HISTORICAL_PORTFOLIOS.value))
    }
    if (reportSettings.with_transaction_saldo) {
      areasVisibility.push((REPORTS_AREAS.WITH_TRANSACTION_SALDO.value))
    }
    if (reportSettings.with_other_assets) {
      areasVisibility.push((REPORTS_AREAS.OTHER_ASSETS.value))
    }

    return areasVisibility;
  };

  const handleBenchmarkConfigured = (withChanges, benchmarks=[], enabled=false) => {
    setBenchmarkConfiguration({modal_opened: false});
    let hasBenchmarks = benchmarks && benchmarks.length > 0;
    if(!hasBenchmarks){updateBenchmarkSwitchState(false)}
    if(withChanges){
      handleSettingUpdate('benchmark_updates')({
        benchmarks: benchmarks,
        benchmark_enabled: hasBenchmarks && !!enabled,
      })
      handleBenchmarkUpdate(benchmarks);
    }
  };

  const updateBenchmarkSwitchState = (forceShow) => {
    handleSettingUpdate('benchmark_updates')({
      benchmarks: reportSettings.benchmarks,
      benchmark_enabled: !!forceShow,
    })
  };

  let isDepotStatus = reportSettings.report_type === REPORT_TYPES.DEPOT_STATUS.value;
  let isBasis = reportSettings.report_type === REPORT_TYPES.BASIC.value;
  let benchmarkSwitchDisabled = isDepotStatus || isBasis;

  const [historicalAggregateSelectedSwitchDisabled, setHistoricalAggregateSelectedSwitchDisabled] =  React.useState(undefined);
  const [performanceAggregateSelectedSwitchDisabled, setPerformanceAggregateSelectedSwitchDisabled] =  React.useState(undefined);
  let [multiplePortfoliosAreSelected, seMultiplePortfoliosAreSelected] = React.useState(undefined);

  React.useEffect(() => {
    // conditions to disable switches if group reporting
    let histSwitchDisabled = isDepotStatus
    let perfSwitchDisabled = isDepotStatus || isBasis

    // extra conditions to disable switches for single reporting
    if(!isGroupReporting && !_.isNil(reportSettings.portfolio_ids)){
      let multPortfoliosSelected = reportSettings.portfolio_ids.length > 1
      seMultiplePortfoliosAreSelected(multPortfoliosSelected)
      setHistoricalAggregateSelectedSwitchDisabled(histSwitchDisabled || !multPortfoliosSelected)
      setPerformanceAggregateSelectedSwitchDisabled(perfSwitchDisabled || !multPortfoliosSelected)

    } else if (isGroupReporting){
      seMultiplePortfoliosAreSelected(true)
      setHistoricalAggregateSelectedSwitchDisabled(histSwitchDisabled)
      setPerformanceAggregateSelectedSwitchDisabled(perfSwitchDisabled)
    }

  }, [reportSettings.report_type, reportSettings.portfolio_ids])

  // in following useEffect-s check for reportSettings to fix cases when in containers/GroupReporting/Reporting.js fetchAutoReportData finishes later and overrides settings
  React.useEffect(() => {
    // if switch is disabled, but in settings aggregate selected is set to true - update settings
    if(historicalAggregateSelectedSwitchDisabled && reportSettings.aggregate_historical_chart_portfolios){
      handleValueChanged('aggregate_historical_chart_portfolios')(false)
    }
  }, [reportSettings.aggregate_historical_chart_portfolios]);

  React.useEffect(() => {
    if(performanceAggregateSelectedSwitchDisabled && reportSettings.aggregate_performance_chart_portfolios){
      handleValueChanged('aggregate_performance_chart_portfolios')(false)
    }
  }, [reportSettings.aggregate_performance_chart_portfolios]);

  return (
    <>
      <Grid container className={classes.reportSettingsSection}>
          {/* first column */}
          <Grid item lg={6} md={6} sm={12} xs={12} id="report-settings-create-group-period-tour-element" style={{overflow: 'hidden'}}>
            <Grid container className={clsx(classes.reportSettingsSectionAdditional, classes.reportSettingsSectionAdditionalOne)}>
                <Grid item lg={12} md={12} sm={12} xs={12} id="report-settings-create-group-report_type-tour-element" style={{overflow: 'hidden'}}>
                  <div className={clsx(loading && 'loading-line')} >
                    <ReportType
                      selected={reportSettings.report_type}
                      handleChanged={handleValueChanged('report_type')}
                      customReportTypeSettings={reportSettings && reportSettings.custom_report_type_settings}
                      handleCustomReportSettingUpdate={handleSettingUpdate('custom_report_type_settings')}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} id="report-settings-create-group-period-tour-element" style={{overflow: 'hidden'}}>
                  <div className={clsx(loading && 'loading-line')} >
                    <ReportSendingDate
                      selected={reportSettings.date_range}
                      handleChanged={handleValueChanged('date_range')}
                      dateRangeStartDate={reportSettings.date_range_start_date}
                      dateRangeEndDate={reportSettings.date_range_end_date}
                      handleDateRangeChanged={handleDateRangeChanged}
                      isAutoReporting={reportSettings.is_auto_report && reportSettings.periodicity !== AUTO_REPORT_PERIODICITY.NONE.id}
                      onDatePickerError={setNextBtnDisabled}
                      showNoPriceOnWeekendsWarning
                    />
                  </div>
                </Grid>
                {/* historical chart settings */}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControl disabled={props.disabled} component="fieldset" style={{width: '100%'}}>
                    <FormLabel component="legend" classes={{root: classes.formLabel}}>Geldgewichtete Rendite-Chart</FormLabel>

                    <Grid container>
                      {/* invested capital switch */}
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <div className={clsx(loading && 'loading-line')} >
                          <span className={classes.benchmarkLabel}>Kapitaleinsatz</span>
                          <Switch
                            value={!isDepotStatus && reportSettings.with_invested_capital || false}
                            handleValueChanged={(checked) => handleSettingUpdate('with_invested_capital')(checked)}
                            disabled={isDepotStatus} // disabled if report type is depot status
                          />
                        </div>
                      </Grid>
                      {/* aggregate selected portfolios switch */}
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <span className={classes.benchmarkLabel}>Kumulierte Anzeige</span>
                        {!_.isNil(multiplePortfoliosAreSelected) && !multiplePortfoliosAreSelected &&
                          <WarningTooltip width={"100%"} title={aggregateSelectedDisabledExplanation} />
                        }
                        <Switch
                          value={!historicalAggregateSelectedSwitchDisabled && reportSettings.aggregate_historical_chart_portfolios}
                          handleValueChanged={handleValueChanged('aggregate_historical_chart_portfolios')}
                          disabled={historicalAggregateSelectedSwitchDisabled}
                          loading={_.isNil(historicalAggregateSelectedSwitchDisabled)}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
                {/* Performance chart settings */}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControl disabled={props.disabled} component="fieldset" style={{width: '100%'}}>
                    <FormLabel component="legend" classes={{root: classes.formLabel}}>Zeitgewichtete Rendite-Chart</FormLabel>

                    <Grid container>
                      {/* benchmark switch and configuration */}
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <SeriesBenchmarkConfiguration
                          benchmarks={benchmarks}
                          dispatch={setBenchmarkConfiguration}
                          showInvestment={reportSettings.benchmark_enabled}
                          updateBenchmarkSwitchState={updateBenchmarkSwitchState}
                          disabled={benchmarkSwitchDisabled}
                        />
                      </Grid>
                      {/* aggregate selected portfolios switch */}
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <span className={classes.benchmarkLabel}>Kumulierte Anzeige</span>
                        {!_.isNil(multiplePortfoliosAreSelected) && !multiplePortfoliosAreSelected &&
                          <WarningTooltip width={"100%"} title={aggregateSelectedDisabledExplanation} />
                        }
                        <Switch
                          value={!performanceAggregateSelectedSwitchDisabled && reportSettings.aggregate_performance_chart_portfolios}
                          handleValueChanged={handleValueChanged('aggregate_performance_chart_portfolios')}
                          disabled={performanceAggregateSelectedSwitchDisabled}
                          loading={_.isNil(performanceAggregateSelectedSwitchDisabled)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SeriesBenchmarksList disabled={benchmarkSwitchDisabled} benchmarks={benchmarks} />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
            </Grid>
          </Grid>

          {/* second column */}
          <Grid item lg={4} md={4} sm={12} xs={12} >
            <Grid container className={clsx(classes.reportSettingsSectionAdditional, classes.reportSettingsSectionAdditionalTwo)}>
              <Grid item lg={12} md={12} sm={6} xs={12} id="report-settings-create-group-additional-reports-tour-element" style={{overflow: 'hidden'}}>
                <div className={clsx(loading && 'loading-line')} >
                  <ReportsOptions
                    selected={getSettingsAdditionalReports()}
                    handleChanged={handleValueChanged('additional_reports')}
                  />
                </div>
              </Grid>
              <Grid item lg={12} md={12} sm={6} xs={12} id="report-settings-create-group-additional-reports-tour-element" style={{overflow: 'hidden'}}>
                <div className={clsx(loading && 'loading-line')} >
                  <ReportsAreasVisibility
                    isDepotStatus={isDepotStatus}
                    selected={getSettingsAreasVisibility()}
                    handleChanged={handleValueChanged('areas_visibility')}
                  />
                </div>
              </Grid>
              {!isGroupReporting &&
                <Grid item xs={12} sm={6} md={12}>
                  <PortfolioList
                    selectedPortfolios={selectedDepots}
                    handleSelectedPortfolioChanged={handleSelectedPortfolioChanged}

                    portfolios={customerDepots.data} // request
                    portfoliosLoadingError={customerDepots.errors}
                    portfoliosDataLoading={customerDepots.loading}
                    loading={customerDepots.loading}

                    disabled={_.isEmpty(customerDepots.data)}
                    alwaysScrollableList
                    isListAutoUpdate
                  />
                </Grid>
              }
            </Grid>
          </Grid> 
        </Grid>

      {benchmarkConfiguration && benchmarkConfiguration.modal_opened && (
        <SeriesReportingBenchmarkModal
          benchmarkConfiguration={benchmarkConfiguration}
          onBenchmarkConfigured={handleBenchmarkConfigured}
          initBenchmarks={benchmarks}
          benchmarkEnabled={reportSettings.benchmark_enabled}
          updateBenchmarkSwitchState={updateBenchmarkSwitchState}
        />
      )}
    </>
  );
};

export default RepotSettings;