const styles = theme => ({
    card: {
        padding: 20,
        position: "relative",
        background: "white",
        boxShadow: "0px 3px 15px #00000033",
        borderRadius: 5,
    },
    cardsWrapper: {
        paddingTop: 30,
        display: "flex",
        flexDirection: "column",
        gap: "90px"
    },
    title: {
        color: "#202A38",
        fontSize: 20,
        fontFamily: "Roboto-Regular",
    },
    titleWrapper: {
        display: "flex",
        alignItems: 'baseline',
        gap: "8px"
    },
    tooltip: {
        position: 'relative',
        padding: 20,
        backgroundColor: '#EFEFF2',
        color: '#4D4F5C',
        fontFamily: 'Roboto-Regular',
        fontSize: 14,
        boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.2)',
        whiteSpace: 'pre-line',
        maxWidth: 500
    },
    container: {
        '& > div:last-child > div': {
            borderRight: "none",
        }
    },
    helpIcon: {
        verticalAlign: "middle",
        fontSize: "1.1rem",
        color: "#202A38",
    },
})

export default styles;