import {makeStyles} from "@material-ui/core";
import _ from "lodash";
import {
  getCommonProductComparisonTableFirstColumnStyles,
  getCommonProductComparisonTableStyles
} from "../FondsKenzahlenTable/styles";
import {smFontSizeTable} from "../../../../utils/constants";

const getTableCellStyles = (theme) => ({
  verticalAlign: 'middle',
  borderRight: '1px solid rgba(224, 224, 224, 1)',
  '&:not(:first-child)': {
    minWidth: 75,
  },
  ...getCommonProductComparisonTableFirstColumnStyles(theme),

  [theme.breakpoints.down('sm')]: {
    fontSize: smFontSizeTable,
  }
});

export const sustainabilityMetricsTableStyles = makeStyles((theme) => ({
  ...getCommonProductComparisonTableStyles(theme),
  tableCell: getTableCellStyles(theme)
}));

export const sustainabilityMetricsMainTableStyles = makeStyles((theme) => {
  const commonStyles = {...getCommonProductComparisonTableStyles(theme)};

  // set main category header bold by overriding common styles
  _.set(commonStyles, ['tableHead', '& $tableCell', '&:nth-child(2)', 'fontFamily'], 'Roboto-Bold');

  return {
    ...commonStyles,
    tableCell: {
      ...getTableCellStyles(theme),
      '&:nth-child(2)': {
        backgroundColor: '#F0F7FD !important',
      },
    },
    '$tableHead': {
      '& $tableCell': {
        '&:nth-child(2)': {
          fontFamily: 'Roboto-Bold'
        },
      }
    }
}});