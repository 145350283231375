import React from 'react';
import _ from 'lodash';
import connect from "react-redux/es/connect/connect";

import { Container, Grid, withStyles } from '@material-ui/core';

import Filters from './components/Filters';
import CustomersList from './components/CustomersList';
import Footer from './components/Footer';

import { getSearchCustomerType, } from '../../components/FilteringPanel/components/ListSelector/constants';
import withNotification from '../../components/NotificationProvider';

import styles from './styles';
import { GroupResource } from '../../utils/api';
import {
  paginateArray,
  UserUtils
} from '../../utils/utils';
import { ACTION_TYPE } from './components/CustomersList/CustomersList';
import {REPORT_DISTRIBUTION_TYPE} from "./constants";


const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
});
class GroupCreation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group: {
        data: undefined,
        loading: true,
        error: undefined
      },
      customers: undefined,
      selectedCustomers: [],
      loading: true,
      filters: {
        customerName: undefined,
        customerType: getSearchCustomerType(props.auth),
        reportDistributionType: undefined,
        lastReportSentDate: undefined,
        productTypes: [],
        assetClasses: [],
        aggregatedRange: [undefined, undefined],
      },
      filtersErrors: undefined
    };

    this.handleFiltersUpdated = this.handleFiltersUpdated.bind(this);
    this.fetchCustomers = this.fetchCustomers.bind(this);
    this.handleAllSelected = this.handleAllSelected.bind(this);
    this.handleCustomerSelected = this.handleCustomerSelected.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.fetchGroup = this.fetchGroup.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.validateAggregatedRange = this.validateAggregatedRange.bind(this);
  }

  componentDidMount() {
    this.fetchGroup();
    this.fetchCustomers();
  }

  componentDidUpdate(_, prevState) {
    const isDistributionReportTypeUpdated = (this.state.filters.reportDistributionType && this.state.filters.reportDistributionType.value)
      !== (prevState.filters.reportDistributionType && prevState.filters.reportDistributionType.value);

    const isMinInvestedValueUpdated = (this.state.filters.aggregatedRange && this.state.filters.aggregatedRange[0]) 
      !== (prevState.filters.aggregatedRange && prevState.filters.aggregatedRange[0]);
    const isMaxInvestedValueUpdated = (this.state.filters.aggregatedRange && this.state.filters.aggregatedRange[1]) 
      !== (prevState.filters.aggregatedRange && prevState.filters.aggregatedRange[1])

    const isSearchUpdated = this.state.filters.customerName !== prevState.filters.customerName;
    const isCustomerTypeUpdated = this.state.filters.customerType.value !== prevState.filters.customerType.value;

    if (isSearchUpdated || isDistributionReportTypeUpdated || isCustomerTypeUpdated) {
      this.fetchCustomers();
    }

    if (isMinInvestedValueUpdated || isMaxInvestedValueUpdated) {
      this.validateAggregatedRange();
    }
  }

  validateAggregatedRange() {
    let [aggregatedMinValue, aggregatedMaxValue] = this.state.filters.aggregatedRange;

    this.setState({
      filtersErrors: undefined
    });

    if (aggregatedMinValue && aggregatedMaxValue && aggregatedMinValue > aggregatedMaxValue) {
      this.setState({
        filtersErrors: {
          aggregatedRange: [true, undefined]
        }
      });
    }
  }

  handleCustomerSelected(customer) {
    let selectedCustomers = [...this.state.selectedCustomers];

    let isCustomerSelected = _.find(selectedCustomers, item => item.customer_id === customer.customer_id);

    if (isCustomerSelected) {
      selectedCustomers = _.filter(selectedCustomers, item => item.customer_id !== customer.customer_id);
    } else {
      selectedCustomers.push(customer);
    }

    this.setState({
      selectedCustomers
    });
  }

  getFilters() {
    let filters = {};

    if (!_.isNil(this.state.filters.customerName) && this.state.filters.customerName.length > 0) {
      filters["customerName"] = this.state.filters.customerName;
    }

    if (!_.isNil(this.state.filters.aggregatedRange[0])) {
      filters["totalMin"] = this.state.filters.aggregatedRange[0]
    }

    if (!_.isNil(this.state.filters.aggregatedRange[1])) {
      filters["totalMax"] = this.state.filters.aggregatedRange[1]
    }

    if (!_.isNil(this.state.filters.productTypes)) {
      filters["group_identifier_code"] = this.state.filters.productTypes && this.state.filters.productTypes.map((type) => type.value);
    }

    if (!_.isNil(this.state.filters.assetClasses)) {

      const FOND_SUB_CLASS = 601;

      const assetClasses = _.filter(this.state.filters.assetClasses, (item) => item.value != FOND_SUB_CLASS);
      const assetSubClasses = _.filter(this.state.filters.assetClasses, (item) => item.value == FOND_SUB_CLASS);

      if (!_.isNil(assetClasses)) {
        filters["financial_information_classes"] = assetClasses && assetClasses.map((type) => type.value);
      }

      if (!_.isNil(assetClasses)) {
        filters["financial_information_subclass"] = assetSubClasses && assetSubClasses.map((type) => type.value);
      }
    }

    filters["distribution_type"] = this.state.filters.reportDistributionType.value;
    filters["customerType"] = this.state.filters.customerType.value;

    return filters;
  }

  handleAllSelected() {
    let allCustomers = Array.prototype.concat.apply([], this.state.customers);
    if (this.state.selectedCustomers.length === allCustomers.length) {
      this.setState({
        selectedCustomers: []
      });
    } else {
      this.setState({
        selectedCustomers: allCustomers
      });
    }
  }

  async fetchGroup() {
    let groupId = this.props.computedMatch.params.group_id;

    this.setState({
      group: {
        data: undefined,
        loading: true,
        error: undefined
      },
    });

    try {
      if (!_.isNil(groupId)) {
        let response = await GroupResource.at(`${groupId}/`).get();
        const reportDistributionType = _.find(REPORT_DISTRIBUTION_TYPE, {value: response.distribution_type});
        this.setState({
          group: {
            data: response,
            loading: false,
            error: undefined
          },
          filters: {
            ...this.state.filters,
            reportDistributionType
          }
        });
      }
    } catch (error) {
      this.setState({
        group: {
          data: undefined,
          loading: false,
          error: error
        },
      });
    }
  }

  async fetchCustomers() {
    if (!this.state.filters.reportDistributionType) {
      return ;
    }
    this.setState({
      customers: undefined,
      loading: true
    });

    try {
      let response = await GroupResource.at('customers/').get(this.getFilters());
      this.setState({
        customers: [...paginateArray(response, 12)],
        loading: false,
        selectedCustomers: this.state.selectedCustomers.filter(customer => {
          return response.some(entity => entity.customer_id === customer.customer_id);
        })
      });
    } catch (error) {
      // TODO: Add error handling
      this.setState({
        customers: undefined,
        loading: false
      });
    }
  }

  async handleAddClick() {
    const groupId = this.props.computedMatch.params.group_id;
    const count = this.state.selectedCustomers.length;
    const name = this.state.group.data.name;
    const message = `${count} Kunde${count > 1 ? 'n' : ''} wurde${count > 1 ? 'n' : ''} der Gruppe "${name}" hinzugefügt.`;
    try {
      let body = {
        customers_to_add: this.state.selectedCustomers.map(customer => customer.id)
      };
      await GroupResource.at(`${groupId}/customers/`).patch(body);
      this.props.displayNotification('success', message);
      
      this.fetchCustomers();
      this.fetchGroup();
      this.setState({
        selectedCustomers: []
      });

    } catch (err) {
      // TODO: Add error handling
    }
  }

  handleFiltersUpdated(fieldName, value) {
    if (this.state.filters.hasOwnProperty(fieldName)) {
      this.setState({
        filters: {
          ...this.state.filters,
          [fieldName]: value
        }
      });
    }
  }

  onApplyButtonClicked = () => {
    this.fetchCustomers();
  }

  render() {
    const { classes } = this.props;

    return (
      <>
      <Container className="app-page-container">
        <Grid container>
          <Grid item lg={4} md={12} sm={12}>
            <h1 style={{
              fontFamily: 'Roboto-Regular',
              color: '#626970',
              fontSize: 34,
              fontWeight: 'normal',
              margin: 0,
              marginBottom: 10
            }}>Kunden hinzufügen</h1>
            {this.state.group.data && (
              <p style={{
                fontFamily: 'Roboto-Regular',
                fontSize: 14,
                color: '#626970',
                margin: '5x 0'
              }}><b>GRUPPE: {this.state.group.data.name}</b> ({this.state.group.data.customers.length} Kunde{this.state.group.data.customers.length > 1 ? 'n' : ''})</p>
            )}
            <Filters
              filters={this.state.filters}
              handleFiltersUpdated={this.handleFiltersUpdated}
              disabledFields={['reportDistributionType']}
              isChief={UserUtils.isChief(this.props.auth)}
              handleApplyButtonClicked={this.onApplyButtonClicked}
              errors={this.state.filtersErrors}
            />
          </Grid>
          <Grid className={classes.customerListSection} item lg={8} md={12} sm={12}>
            <CustomersList 
              customers={this.state.customers}
              loading={this.state.loading}
              selected={this.state.selectedCustomers}
              handleCustomerSelected={this.handleCustomerSelected}
              handleAllSelected={this.handleAllSelected}
              actionType={ACTION_TYPE.ADD}
            />
          </Grid>
        </Grid>
      </Container>
      <Footer 
        isVisible={true}
        selectedCustomers={this.state.selectedCustomers}
        handleNextStepClicked={this.handleAddClick}
        nextButtonText={'Diese Kunden zur Gruppe hinzufügen'}
      />
      </>
    )
  }
}

export default connect(mapStateToProps)(withNotification(withStyles(styles)(GroupCreation)));