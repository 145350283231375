import _ from "lodash";
import UnrealizedProfitAndLossTable from "../../../../../../../../components/Charts/UnrealizedProfitAndLossTable";
import DashboardCard from "../../../DashboardCard";
import React from "react";
import useStyles from "../InstrumentListItem/styles";

const UnrealizedProfitAndLossItem = props => {
  const {
    portfolioTransactData,
    expanded,
    onExpandedItemsChange
  } = props;

  const classes = useStyles();

  const content = <div className={classes.tableSection}>
      <p className={classes.tableSectionHeader}>TRANSAKTIONSSALDO</p>
      <UnrealizedProfitAndLossTable
        key={portfolioTransactData.portfolio_id}
        data={portfolioTransactData}
        expanded={expanded}
      />
    </div>

  return(
    <DashboardCard
      expandable={true}
      expanded={expanded}
      onExpanded={onExpandedItemsChange}
      content={content}
      divider
      table
    />
  )
};
export default UnrealizedProfitAndLossItem;