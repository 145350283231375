import React from 'react';
import {Link, withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import clsx from "clsx";

/* BCA Components */
import LoadingIndicator from './components/LoadingContainer'

/* BCA modules*/
import useStyles from './styles';
import images from '../../../../images';
import {UserUtils, newDesignUsed} from "../../../../utils/utils";
import {buildCurrentCustomerDashboardPath, buildCustomerDashboardLink, ROUTES, buildEditGroupPath} from "../../../../routes";
import { executeIfPathExist, getInvestmentDynamicPath } from '../../../../containers/InvestmentPlatform/utils'
import ListLinksDropdown from '../../../../components/ListLinksDropdown/index';
import CustomerFullnameMenu from "../../../CustomerFullnameMenu/CustomerFullnameMenu";

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
  investmentPlatform: state.get('investmentPlatform').toJS()
});

export default connect(mapStateToProps)(withRouter((props) => {
  const classes = useStyles();

  const {
    customer,
    customerLoadingError,
    customerDataLoading,
    dashboardLink,
    auth,
    group
  } = props;

  const buildDashboardLink = () => {
    return UserUtils.isBroker(auth) ? buildCustomerDashboardLink(customer.customer_id) : buildCurrentCustomerDashboardPath();
  };

  const onLinkSelect = (group) => {

    let path = ROUTES.BROKER.GROUP_UPDATE.path.replace(':group_id', group.id)

    if (newDesignUsed()) {
      executeIfPathExist(props.investmentPlatform.routes, 'REPORT_SETTINGS', (dynamicPath) => {
        let investmentDynamicPath = getInvestmentDynamicPath()
        path = '/' + investmentDynamicPath + dynamicPath + ROUTES.BROKER.GROUP_UPDATE.newDesignPath.replace(':group_id', group.id)
      })
    }

    props.history.push(path);
  };

  const renderUserInfo = () => {
    return customer && (
      <>
        <img src={images.default_user_logo} className={classes.userLogo} alt="User Logo"/>
        <div>
          <CustomerFullnameMenu
            customer={customer}
            customerFullname={
              <h4 className={classes.userName}>
                {customer.customer_full_name}
              </h4>
            }
          />
          <p className={classes.userId}>
            Kunden-Nr: {customer.customer_id}
          </p>
          {dashboardLink && (
            <p className={clsx(classes.link, classes.dashboardLink)} >
              <Link to={buildDashboardLink()}>Kundenportfolio-Ansicht anzeigen</Link>
            </p>
          )}
          {group && customer.groups && (
            <div className={classes.group} id="reporting-client-group-tour-element">
              Gruppen: {customer.groups ?
               <ListLinksDropdown
                 groups={customer.groups}
                 onLinkSelect={onLinkSelect}
                />
                : <span>-</span>}
            </div>
          )}
        </div>
      </>
    )
  };

  const renderLoading = () => (<LoadingIndicator/>);

  const renderError = () => (<p>Error</p>);

  return (
    <div className={classes.userContainer}>
      {customerDataLoading ? renderLoading() : customerLoadingError ? renderError() : renderUserInfo()}
    </div>
  )
}))
