import { fromJS } from "immutable";

import {
  DELETE_GUIDE_TOUR_SETTINGS,
  SET_GUIDE_TOUR_SETTINGS,
} from "./constants";

const guideTourSettingsInitialState = fromJS({
  steps: {}
});

export function guideTourSettingsReducer(state = guideTourSettingsInitialState, action) {
  switch(action.type) {
    case SET_GUIDE_TOUR_SETTINGS:
      return state.set('steps', fromJS(action.steps || {}))
    case DELETE_GUIDE_TOUR_SETTINGS:
      return state.set('steps', fromJS({}))
    default:
      return state;
  }
}

