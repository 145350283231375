const styles = theme => ({
  radioGroupRoot: {
    height: 'auto'
  },
  labelRoot: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    top: '-1.5rem'
  }
});

export default styles;