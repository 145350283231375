import React from "react";

import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

import styles from "./styles";
import {isRequiredQuestion, isValidValue} from "../../../../../utils";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {FormControl, FormHelperText} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import {FIELD_REQUIRED_MSG} from "../../../../../constants";
import _ from "lodash";

function toggleQuestionAnswer(question){
  return {
    question_uid: question.uid,
    answer: [question.answer]
  }
}

function isToggleQuestionValid(question, step, afterChange, service) {
  let isValid = true
  if (isRequiredQuestion(question)) {
    if (!isValidValue(question.answer)) {
      isValid = false
      question.error = FIELD_REQUIRED_MSG
    } else {
      question.error = null
    }
  }

  return isValid;
}

function initToggleQuestionAnswer(question, answer){
  if (answer && answer.length > 0) {
    question.answer = answer[0];
  } else {
    question.answer = null;
  }
}

export const ToggleQuestionMethods = {
  required: isRequiredQuestion,
  valid: isToggleQuestionValid,
  answer: toggleQuestionAnswer,
  initAnswer: initToggleQuestionAnswer,
}

const ToggleQuestion = props => {
  const {
    classes,
    question,
    onAnswerChange,
  } = props;

  React.useEffect(() => {
    if(_.isNil(question.answer)){
      initToggleQuestionAnswer(question)
    }
}, []);

  const label = question.question_text || '';
  const required = ToggleQuestionMethods.required(question);
  const disabled = !!question.disabled;
  const answer = question.answer;

  const handleChange = () => {
    question.error = null;
    onAnswerChange(question.uid, !question.answer);
  };

  const switchPositionsClass = React.useMemo(() => {
    switch (answer) {
      case true:
        return classes.sliderOn;
      case false:
        return classes.sliderOff;
      default:
        return classes.sliderNull;
    }
  }, [answer]);

  return (
    <div className={classes.container}>
      <FormControl>
        <InputLabel
          htmlFor={question.uid}
          required={required}
          disabled={disabled}
          error={question.error}
          classes={{
            root: classes.labelRoot,
            error: classes.error,
            asterisk: classes.labelAsterisk
          }}
        >
          {label}
        </InputLabel>
        <div id={question.uid} className={clsx(classes.toggleSwitch, answer && classes.active)}>
          <span className={clsx(classes.slider, switchPositionsClass)} />
          <ToggleButton disabled={disabled} className={classes.switch} value={answer} onChange={handleChange}/>
        </div>
        <FormHelperText className={question.error && classes.error} error={question.error} >{question.error}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default withStyles(styles)(ToggleQuestion);