import React from 'react';
import useStyles from './styles';
import {
  hasResponseError,
} from '../../../../../../../utils/utils';
import _ from 'lodash';
import BaseChart from '../../../../../../FactSheetsPage/components/BaseChart';
import {getHostRelatedSetting} from "../../../../../../../utils/sharedSettings";
import {
  HOST_RELATED_SHARED_SETTINGS_KEYS,
  NOT_FOUND
} from "../../../../../../../components/SharedSettingsProvider/constants";
import {getSharedSettingsSelector} from "../../../../../../../components/DashboardDataProvider/DashboardDataProvider";
import { connect } from 'react-redux';
import WarningTooltip from "../../../../../../../components/WarningTooltip";


const mapStateToProps = (state) => ({
  sharedSettings: getSharedSettingsSelector(state),
});

export const TopTenInstrumentsTableTitle = connect(mapStateToProps) ((props) => {
  return (
    <div>
      Top 10 Instrumente
      {_.isArray(props.skippedByTopHoldings) && !_.isEmpty(props.skippedByTopHoldings) &&
        <WarningTooltip
          width={"100%"}
          size={16}
          title={getHostRelatedSetting(props.sharedSettings.data, HOST_RELATED_SHARED_SETTINGS_KEYS.ASSETS_WITHOUT_TOP_HOLDINGS_TEXT,
            {"{{skipped_by_top_holdings}}": props.skippedByTopHoldings.join(', ')}
          ) || NOT_FOUND}
        />
      }
    </div>
  )
})

const SyntheticRiskRewardChart = (props) => {
  const {
    dashboardData,
    loading
  } = props;

  const classes = useStyles();

  const portfolioStructure = dashboardData && dashboardData.portfolio_structure;

  const dataError = _.isNil(portfolioStructure) ||  hasResponseError(portfolioStructure);

  const data = portfolioStructure && portfolioStructure.by_top_holdings;

  return (
    <BaseChart
      chartType='table'
      title={<TopTenInstrumentsTableTitle skippedByTopHoldings={portfolioStructure && portfolioStructure.skipped_by_top_holdings} />}
      seriesData={ data || [] }
      loading={loading}
      error={dataError}
      classes={{
        ...classes,
        title: (!loading && !(data && data.length)) ? classes.titleWithoutData : classes.title,
      }}
    />
  );
};

export default SyntheticRiskRewardChart;
