import React from 'react';

import useStyles from './styles';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "../../Tooltip";
import {BcaDeleteIcon, BcaEditIcon} from "../../../images/BcaIcon";


export const BcaIconButtonTooltip = ({tooltip, children}) => {
  if (!tooltip) {
    return children;
  }

  return (
    <Tooltip title={tooltip} placement="top" width={600} interactive>
      <span>{children}</span>
    </Tooltip>
  )
};


function BcaIconButton({icon, tooltip, variant, color, onClick, disabled, fontSize, ...props}) {

  const classes = useStyles({fontSize: fontSize});

  return (
    <BcaIconButtonTooltip tooltip={tooltip}>
      <IconButton color={color} aria-label="close" onClick={onClick} classes={{root: classes.root, disabled: classes.disabled}} disabled={disabled}>
        {icon}
      </IconButton>
    </BcaIconButtonTooltip>
  )
}

BcaIconButton.propTypes = {};

BcaIconButton.propDefs = {};

BcaIconButton.defaultProps = {
  color: 'primary',
  fontSize: 16,
};

export function BcaDeleteIconButton({onClick, disabled, color, fontSize}) {
  return (
    <BcaIconButton tooltip={"Löschen"} disabled={disabled} icon={<BcaDeleteIcon />} onClick={onClick} color={color} fontSize={fontSize}/>
  )
}

export function BcaEditIconButton({onClick, disabled}) {
  return (
    <BcaIconButton tooltip={"Bearbeiten"} disabled={disabled} icon={<BcaEditIcon />} onClick={onClick}/>
  )
}

export default BcaIconButton;