import React from 'react';
import _ from "lodash";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";
import ErrorIcon from '@material-ui/icons/Error';

import styles from './styles';
import Switch from '../../../../../../components/AssetModal/components/Switch';
import {setBenchmarkConfiguration} from "../../../../actions";
import Tooltip from "../../../../../../components/Tooltip";



const BenchmarkWithConfiguration = (props) => {
  const {
    classes,
    // data
    portfolio,
    selectedBenchmarks,
    customerDashboard,
    // flags
    benchmarkConfigurationEnabled,
    isBenchmarkConfigurationEnabled,
    requiredDataExist,
    showInvestment,
    // func-s
    dispatch,
    onChartSettingsChange,
    updateBenchmarkSwitchState
  } = props;

  const customClasses = props.customClasses || {};

  React.useEffect(() => {
    if (showInvestment && _.isFunction(updateBenchmarkSwitchState)) {
      updateBenchmarkSwitchState();
    }
  }, [portfolio, customerDashboard]);


  const handleShowInvestmentChanged = (checked) => {
    const configuration_required = !(selectedBenchmarks && selectedBenchmarks.length);

    if (checked && configuration_required && benchmarkConfigurationEnabled) {
      openBenchmarkConfigurationModal(configuration_required);
    }
    onChartSettingsChange('performance', 'withBenchmark', checked);
  };

  const handleClickBenchmarkSettingsButton = () => {
    const configuration_required = false;
    openBenchmarkConfigurationModal(configuration_required);
  };

  const openBenchmarkConfigurationModal = (configuration_required) => {
    const portfolio_id = portfolio && portfolio.id;
    const modal_opened = true;
    return dispatch(setBenchmarkConfiguration(portfolio_id, modal_opened, configuration_required));
  };

  return (
    <div className={clsx(classes.benchmarkWrap, customClasses.benchmarkWrap)}>
      <div id="performance-chart-benchmark-checkbox" className={clsx(classes.switchWrapper, customClasses.switchWrapper)}>
        <div className={classes.benchmarkLabel}>
          <span>Benchmark</span>
          {requiredDataExist && !isBenchmarkConfigurationEnabled && (
            <Tooltip title={'Benchmark bei Auswahl mehrerer Depots nicht konfigurierbar.'} placement={"top"} >
              <ErrorIcon className={classes.helpIcon}/>
            </Tooltip>
          )}
        </div>
        <div className={clsx(requiredDataExist && !isBenchmarkConfigurationEnabled && classes.disabled)}>
          <Switch
            value={showInvestment}
            handleValueChanged={handleShowInvestmentChanged}
            disabled={!isBenchmarkConfigurationEnabled}
            customClasses={customClasses}
          />
        </div>
      </div>
      {benchmarkConfigurationEnabled && (
        <div
          id="benchmarks-configuration"
          className={clsx(classes.benchmarkSettingsButton, customClasses.benchmarkSettingsButton, !isBenchmarkConfigurationEnabled && classes.disabled)}
          onClick={handleClickBenchmarkSettingsButton}
        >
            <span>
              <i className="fa fa-cog" aria-hidden="true" />
            </span>
          <span>Konfigurieren</span>
        </div>
      )}
    </div>
  );

};

export default withStyles(styles)(BenchmarkWithConfiguration);