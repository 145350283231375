import { makeStyles } from "@material-ui/core";

const styles = theme => ({
  chart: {
    width: '100%',
    '& > div': {
      width: '100%'
    },
    '& .legend-custom': {
      width: 240,
    },
    '& .legend-custom span:nth-of-type(1)': {
        width: 185,
        display:'block',
        height: 'auto',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
    },
    '& .legend-custom span:nth-of-type(2)': {
      float: 'right',
      fontWeight: 'normal',
      position: 'absolute',
      top: 0,
      right: 0,
    },
    '&.mobile': {
      '& .legend-custom': {
         width: "calc(100svw - 135px)",
      },
      '& .legend-custom span:nth-of-type(1)': {
          width: "calc(100% - 45px)",
      },
    }
  }
})

export const useStyles = makeStyles(styles)

export default styles;