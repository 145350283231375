import { fromJS } from 'immutable';
import _ from 'lodash';

import { SET_PRODUCT_SELECTION_INVESTMENT_RECOMENDATION, SET_PRODUCT_SELECTION_SRRI } from './constants'


const investmentRecomendationMock = [
  {
     "asset_class":"Fund",
     "name":"AB - Asia Pacific Local Currency Debt Portfolio A2 USD Acc",
     "currency_code":"USD",
     "country_code":"LU",
     "asset_classification":{
        "asset_class":"Fund",
        "sub_class_1":"Open-End Funds",
        "sub_class_2":"Fixed Income",
        "sub_class_3":"Asia Bond - Local Currency"
     },
     "category":"Asia Bond - Local Currency",
     "description":"The Portfolio's investment objective is to achieve high total returns through current income and long-term capital appreciation. \nIn seeking to achieve its investment objective, under normal market conditions, the Portfolio will invest at least two-thirds of its assets in fixed income securities issued by Asia-Pacific issuers and related derivatives, or in fixed income securities denominated in Asia-Pacific currencies. These securities may be issued by governments, sovereigns, quasi-sovereigns, government agencies, government-guaranteed issuers, supra-national entities or corporations. The Portfolio may invest in a variety of fixed income securities, money market instruments, deposits and cash equivalents.",
     "esg_carbon_score":{
        "carbon_date":"2020-09-30",
        "fossil_fuel_involvement":0.98,
        "average_12_month_fossil_fuel_exposure":0.5
     },
     "esg_risk_score":{
        "sustainability_rating_date":"2020-10-31",
        "historical_sustainability_score_global_category_average":29.59275,
        "sustainability_score_global_category_average":29.12095,
        "percent_of_aum_covered_esg":19.25097
     },
     "fund_size":{
        "value":33703156.77,
        "fund_size_date":"2020-12-31",
        "reported_currency":"EUR"
     },
     "average_credit_quality":"BB",
     "full_name":"AB - Asia Pacific Local Currency Debt Portfolio A2 USD Acc",
     "fund_global_category_name":"Asia Fixed Income",
     "fund_type_id":"FO",
     "inception_date":"2012-02-08",
     "investment_company":"AllianceBernstein (Luxembourg) S.à r.l.",
     "is_bond":false,
     "is_fund":true,
     "isin":"LU0736555797",
     "product_fees":{
        "ongoing_cost_actual":1.6,
        "ongoing_cost_estimated":1.6,
        "performance_fee_actual":0,
        "transaction_fee_actual":0.23858,
        "performance_fee_estimated":0,
        "transaction_fee_estimated":0.23858,
        "management_fee_ex_dist_fee_actual":1.2,
        "management_fee_ex_dist_fee_estimated":1.2
     },
     "return_data":{
        "return_1m":1.33265,
        "return_1w":0.25355,
        "return_1y":8.50714,
        "return_2m":3.2376,
        "return_2y":7.90324,
        "return_3m":6.5194,
        "return_3y":4.64999,
        "return_4y":6.35785,
        "return_5y":6.07665,
        "return_6m":9.77235,
        "return_6y":3.86144,
        "return_7y":3.81694,
        "return_8y":2.52859,
        "return_9m":16.5684,
        "return_d1":-0.40302,
        "return_qtd":0.25355,
        "return_ytd":0.25355,
        "return_date":"07-01-2021",
        "return_inception":3.14586
     },
     "security_type":"Mutual funds",
     "short_name":"F00000NU9S",
     "sri":3,
     "srri":4,
     "srri_date":"2020-12-31",
     "target_market":{
        "srri":4,
        "srri_date":"2020-12-31",
        "time_horizon":"M",
        "expertise_basic":"Y",
        "leverage_product":"N",
        "capital_loss_none":"N",
        "expertise_advanced":"Y",
        "expertise_informed":"Y",
        "capital_loss_limited":"Neutral",
        "investor_type_retail":"Y",
        "return_profile_other":"Neutral",
        "return_profile_growth":"Y",
        "return_profile_income":"Y",
        "return_profile_hedging":"Neutral",
        "capital_loss_no_guarantee":"Y",
        "available_for_pension_plan":false,
        "dist_exec_app_test_non_adv":"Both (Retail and Professional)",
        "investor_type_professional":"Y",
        "capital_loss_beyond_initial":"Y",
        "distribution_execution_only":"Both (Retail and Professional)",
        "return_profile_preservation":"Neutral",
        "target_market_reporting_date":"2017-08-03",
        "available_for_retirement_plan":false,
        "distribution_investment_advice":"Both (Retail and Professional)",
        "usage_specific_investment_need":"N",
        "available_for_insurance_product":false,
        "available_for_regular_investment":false,
        "distribution_portfolio_management":"Both (Retail and Professional)",
        "return_profile_option_or_leveraged":"Neutral",
        "investor_type_eligible_counterparty":"Y"
     },
     "traded_currency_code":"USD",
     "valor":"19754109",
     "wkn":"A1JT2K",
     "market_cap":1780722.4515,
     "enterprise_value":1780722.4515,
     "fund_peergroup_ranking":{
        "1m":{
           "group_size":139,
           "absolute_rank":41,
           "percentile_rank":27
        },
        "1y":{
           "group_size":134,
           "absolute_rank":61,
           "percentile_rank":55
        },
        "3y":{
           "group_size":127,
           "absolute_rank":45,
           "percentile_rank":44
        },
        "5y":{
           "group_size":120,
           "absolute_rank":30,
           "percentile_rank":20
        },
        "10y":{
           "group_size":74
        },
        "15y":{
           "group_size":20
        },
        "20y":{
           "group_size":4
        },
        "ytd":{
           "group_size":134,
           "absolute_rank":61,
           "percentile_rank":55
        }
     },
     "c_isin":"LU0736555797",
     "weight":100,
     "amount":1111,
     "dirty":true,
     "accepted":true,
     "error":null
  }
]

const initialState = fromJS({
  product_selection_investment_recomendation: [],
  srri: undefined
});

function appReducer(state = initialState, action) {
  switch(action.type) {
    case SET_PRODUCT_SELECTION_INVESTMENT_RECOMENDATION:
      return state.set('product_selection_investment_recomendation', fromJS(action.investment_recomendation));
    case SET_PRODUCT_SELECTION_SRRI:
      return state.set('srri', fromJS(action.srri));
    default:
      return state;
  }
}

export default appReducer;

