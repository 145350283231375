import React from 'react';
import _ from 'lodash';
import {FETCH_DATA_INITIAL_STATE} from "../../../hooks/constants";
import {PRODUCT_TYPE} from "../constants";
import {FactSheetsHandlerResource, ModelPortfolioResource, PortfolioHandlerResource} from "../../../utils/api";
import moment from "moment";
import {splitProducts} from "./useYearlyPerformanceData";
import {
  buildProductConfigurationChangedDeps,
  getChartsSensitiveProductsData,
  getProductCustomerId, getProductIdsAsString,
  updateHookDataConfiguration
} from "../utils";
import {BENCHMARK_CHART_COLOR} from "../../../utils/constants";
import {getNextBusinessDate} from "../../../utils/utils";


const timeSeriesHasFullData = (timeSeries, startDate, onlyBusinessDays=true) => {
  if (_.isEmpty(timeSeries)) {
    return false;
  }

  const timeSeriesFirstDate = timeSeries[0]['date'];
  startDate = moment(startDate, 'YYYY-MM-DD');
  if(onlyBusinessDays){
    startDate = getNextBusinessDate(startDate);
  }
  return startDate >= moment(timeSeriesFirstDate, 'YYYY-MM-DD');
};


const handleTimeSeries = (timeSeries, startDate) => {
  if (_.isEmpty(timeSeries)) {
    return timeSeries;
  }

  timeSeries = timeSeries.map(value => ({date: value.date, value: (value.cum_ret_g || 0) * 100}));

  if (!timeSeriesHasFullData(timeSeries, startDate, false)) {
    const zeroItem = {
      date: startDate,
      value: 0.0,
      cum_ret_g: 0
    };
     timeSeries = [zeroItem].concat(timeSeries);
  }

  return timeSeries
};


const handleProductPerformanceData = (product, timeSeries, error, startDate) => {
  const result = {
    ...getChartsSensitiveProductsData(product),
    portfolio: [],
    product
  };

  const _timeSeries = timeSeries.portfolio || [];

  if (!_.isEmpty(error) || !timeSeriesHasFullData(_timeSeries, startDate)) {
    result.missingData = true;
  }

  result.portfolio = handleTimeSeries(_timeSeries, startDate);

  if (product.type != PRODUCT_TYPE.ASSET) {
    result.return = timeSeries.return;
    result.return_pa = timeSeries.return_pa;
  }

  return result
};

function _processAssetPerformanceData(product, response, startDate, error) {
  try {
    const _error = error || _.get(response, 'performance_timeseries.error');
    const timeSeries = _.get(response, 'performance_timeseries.data.timeseries') || [];

    return handleProductPerformanceData(product, {portfolio: timeSeries}, _error, startDate)

  } catch (errors) {
    throw errors;
  }
}

async function fetchAssetsPerformanceData(products, startDate, endDate) {
  const response = await FactSheetsHandlerResource.getPerformanceData(
    undefined, startDate, endDate, undefined, products.map((product) => product.data.isin));
  const error = _.get(response, 'performance_timeseries.error');
  const data = _.get(response, 'performance_timeseries.data') || {};

  return products.map((product) => _processAssetPerformanceData(product, data[product.data.isin], startDate, error))
}

async function fetchInvestmentStrategyPerformanceData(product, investmentStrategy, startDate, endDate) {

  return await fetchMusterDepotPerformanceData(
    product, investmentStrategy.related_model_portfolio, startDate, endDate, investmentStrategy.id)

}

async function fetchMusterDepotPerformanceData(product, musterdepot, startDate, endDate, investmentStrategyId=undefined) {
  try {
    const response = await ModelPortfolioResource.getTimeWeightedReturnData(
      musterdepot.id, undefined, startDate, endDate, false, undefined, investmentStrategyId)
    const error = _.get(response, 'time_weighted_return.errors');
    const timeSeries = _.get(response, 'time_weighted_return.data.0.1.timeseries') || {};

    return handleProductPerformanceData(product, timeSeries, error, startDate)

  } catch (errors) {
    throw errors;
  }
}

// TODO: We need to make improvement here to request data for multiple products (single portfolios, not aggregated),
//  that belongs to same customer, in one request
async function fetchPortfolioPerformanceData(product, portfolio, startDate, endDate) {

  const customerId = getProductCustomerId(product);

  const depotId = _.get(product, 'configuration.depot_id');

  const response = await PortfolioHandlerResource.getTimeWeightedReturnData(
    customerId, depotId, startDate, endDate, false,
    undefined);

  const error = _.get(response, 'time_weighted_return.errors');
  // We always have aggregated portfolio as a first portfolio in a list.
  // For single portfolio it duplicates data from portfolio.
  // For aggregated portfolios it will calculate aggregated data,
  // so we could always use time series from it for any portfolio product.
  const timeSeries = _.get(response, 'time_weighted_return.data.0.0.timeseries') || {};

  return handleProductPerformanceData(product, timeSeries, error, startDate)
}

export default function usePerformanceData(products, dateRange, benchmarksSwitchEnabled, selectedBenchmarks) {
  const [performanceData, setPerformanceData] = React.useState(FETCH_DATA_INITIAL_STATE)
  // TODO: refactor performance data
  const [benchmarkPerformanceData, setBenchmarkPerformanceData] = React.useState(FETCH_DATA_INITIAL_STATE)
  React.useEffect(() => {
    // useEffect to fetch benchmark performance data when it is configured

    if(!_.isEmpty(selectedBenchmarks)){
      setBenchmarkPerformanceData({
        data: null,
        loading: true,
        errors: null,
        updatedAt: +new Date()
      });

      // send request to get performance data for benchmark
      PortfolioHandlerResource.at('customer/products-comparison/benchmark-performance-timeseries/').post({
        benchmarks: selectedBenchmarks,
        start_date: dateRange.start.format('YYYY-MM-DD'),
        end_date: dateRange.end.format('YYYY-MM-DD'),
      })
        .then(benchmarkData => {

          let newBenchmarkPerformanceData = {
            ...benchmarkData,
            color: BENCHMARK_CHART_COLOR
          };

          setBenchmarkPerformanceData({
            data: newBenchmarkPerformanceData,
            loading: false,
            errors: null,
            updatedAt: +new Date()
          })

        })
        .catch(errors => {
          setBenchmarkPerformanceData({
            data: null,
            loading: false,
            errors,
            updatedAt: +new Date()
          })
        })
    } else {
      // clear old benchmarkPerformanceData if benchmarks were removed from config
      setBenchmarkPerformanceData(FETCH_DATA_INITIAL_STATE)
    }

  }, [JSON.stringify(selectedBenchmarks), dateRange]);

  React.useEffect(() => {
    if (!_.isEmpty(products) && !_.isEmpty(dateRange)) {
      fetchProductsPerformanceData();
    }
  }, [getProductIdsAsString(products), dateRange]);

  React.useEffect(() => {
    setPerformanceData((data) => updateHookDataConfiguration(data, products));
  }, [buildProductConfigurationChangedDeps(products)]);

  const fetchProductsPerformanceData = async () => {
    setPerformanceData({
      data: null,
      loading: true,
      errors: null,
      updatedAt: +new Date()
    });
    try {

      const [productsAssets, productsOther] = splitProducts(products);

      const handlers = productsOther.map((product) => {
        return {
          [PRODUCT_TYPE.MUSTERDEPOT]: fetchMusterDepotPerformanceData,
          [PRODUCT_TYPE.MODEL_PORTFOLIO]: fetchMusterDepotPerformanceData,
          [PRODUCT_TYPE.PRIVATE_INVESTMENT]: fetchInvestmentStrategyPerformanceData,
          [PRODUCT_TYPE.CUSTOMER_PORTFOLIO]: fetchPortfolioPerformanceData
        }[product.type](product, product.data, dateRange.start.format('YYYY-MM-DD'), dateRange.end.format('YYYY-MM-DD'));
      });
      productsAssets.length && handlers.push(fetchAssetsPerformanceData(productsAssets, dateRange.start.format('YYYY-MM-DD'), dateRange.end.format('YYYY-MM-DD')))

      const result = await Promise.all(handlers);
      setPerformanceData({
        data: _.flatten(result),
        loading: false,
        errors: null,
        updatedAt: +new Date()
      })
    } catch (errors) {
      setPerformanceData({
        data: null,
        loading: false,
        errors,
        updatedAt: +new Date()
      })
    }
  };

  const getPerformanceDataWithBenchmark = () => {

    if(benchmarksSwitchEnabled && !(_.isNil(benchmarkPerformanceData.data) && !benchmarkPerformanceData.loading) ) {

      return {
        data: [...(performanceData.data || []), benchmarkPerformanceData.data],
        loading: performanceData.loading || benchmarkPerformanceData.loading,
        errors: performanceData.errors || benchmarkPerformanceData.errors,
        updatedAt: +new Date()  // FIXME: new timestamp will always re-render chart
      }
    }

    return performanceData
  };

  return getPerformanceDataWithBenchmark();
}