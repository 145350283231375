const styles = theme => ({
  card: {
    margin: '-4px 10px 0',
    boxShadow: 'none'
  },
  tableContainer: {
    position: 'relative',
    overflowX: 'auto',

    '& > $table': {
      [theme.breakpoints.down('sm')]: {
        minWidth: 400,
      },
    },
    '&:not(:last-of-type)': {
      '& > $table':{
        '& $tableCell': {
          '&:last-of-type': {
            borderRight: '1px solid rgba(224, 224, 224, 1)'
          }
        }
      }
    },
    '&::after': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 1,
      backgroundColor: 'rgba(224, 224, 224, 1)'
    }
  },
  tableCell: {
    fontSize: 16,
    padding: '14px 8px',

    [theme.breakpoints.only('sm')]: {
      fontSize: 14,
      padding: 8,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: 13,
      padding: 6,
    },
  },
  rowNumberColumn: {
    color: '#ABB6BE',
    width: '5%',
  },
  nameColumn: {
    fontFamily: 'Roboto-Bold',
    width: '50%',
  },
  customerNumberColumn: {
    width: '40%',
  },
  customerStatusColumn: {
    width: '5%',
  },
  columnCenter: {
    textAlign: 'center',
  },
  paginationContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8
  },
  paginationInfo: {
    color: '#ABB6BE'
  },
});

export default styles;
