import React from 'react';

import useStyles from './styles';

const ImmobilienIcon = (props) => {
  const classes = useStyles();

  return (
    <span className={classes.iconContainer}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path className="a" d="M14.42,7.059h1.614a.706.706,0,0,0,0-1.412H14.42a.706.706,0,1,0,0,1.412Z"/>
        <path className="a" d="M7.966,15.127H9.58a.707.707,0,0,0,0-1.413H7.966a.707.707,0,0,0,0,1.413Z"/>
        <path className="a" d="M3.126,11.093H4.74a.706.706,0,0,0,0-1.412H3.126a.706.706,0,0,0,0,1.412Z"/>
        <path className="a" d="M19.26,11.093h1.614a.706.706,0,0,0,0-1.412H19.26a.706.706,0,0,0,0,1.412Z"/>
        <path className="a" d="M23.3,0a.6.6,0,0,0-.1.008L11.9,1.621a.71.71,0,0,0-.606.7v1L.606,4.848a.709.709,0,0,0-.606.7V23.294A.707.707,0,0,0,.706,24H23.294A.707.707,0,0,0,24,23.294V.707A.706.706,0,0,0,23.3,0ZM7.261,22.588H5.445V19.16H7.261Zm4.033,0H8.672V18.454a.707.707,0,0,0-.706-.707H4.74a.707.707,0,0,0-.706.707v4.134H1.412V6.159l9.882-1.412Zm7.261,0H16.739V19.16h1.816Zm4.033,0H19.966V18.454a.707.707,0,0,0-.706-.707H16.034a.707.707,0,0,0-.706.707v4.134H12.706V2.932L22.588,1.52Z"/>
      </svg>
    </span>
  );
};

export default ImmobilienIcon;