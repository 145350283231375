import React from 'react';
import _ from 'lodash'

import withTheme from '@material-ui/core/styles/withTheme';
import { Grid } from '@material-ui/core';

import {
  hasResponseError,
  PortfolioUtils,
} from '../../../../../../../utils/utils';
import BaseChart from '../../../../../../FactSheetsPage/components/BaseChart';
import useStyles from '../styles';


const PortfolioBruncheStructure = (props) => {
  const {
    dashboardData,
    loading
  } = props;

  const classes = useStyles();

  const portfolioStructure = dashboardData && dashboardData.portfolio_structure;

  const dataError = _.isNil(portfolioStructure) ||  hasResponseError(portfolioStructure);

  let data = [];
  if (portfolioStructure) {
    data = PortfolioUtils.getPortfolioSectors(portfolioStructure)
  }
  return (
    <>
    <Grid container>
      <Grid item xs={12}>
        <BaseChart
            chartType='bar'
            title={data.length !== 1 ? 'Branchen' : 'Branche'}
            seriesData={data}
            loading={loading}
            error={dataError}
            classes={{
              title: (!loading && !data.length) ? classes.titleWithoutData : classes.title,
            }}
          />
      </Grid>
    </Grid>
    </>
  );

};

export default withTheme(PortfolioBruncheStructure)
