import { SNACKBAR_OPEN, SNACKBAR_CLOSE } from './constants'

export const displayWarningSnackBar = (message) => ({
  type: SNACKBAR_OPEN,
  message,
  variant: 'warning',
});

export const displayErrorSnackBar = (message) => ({
  type: SNACKBAR_OPEN,
  message,
  variant: 'error',
});

export const displaySuccessSnackBar = (message, duration) => ({
  type: SNACKBAR_OPEN,
  message,
  variant: 'success',
  duration: duration,
});

export const closeSnackbar = () => ({
  type: SNACKBAR_CLOSE
});