import React from 'react'

import { Grid, Container } from '@material-ui/core'

import FilteringPanel from '../../components/FilteringPanel';
import SearchInputResult from '../../components/FilteringPanel/components/SearchInputResultMessage';
import {SORTING_TYPES} from "../../components/FilteringPanel/components/ListSelector/constants";
import { CustomerReportSettingResource } from '../../utils/api'
import { 
  UserUtils
} from '../../utils/utils'
import { CustomerListItem } from './components'
import Pagination from "../../components/Pagination/Pagination";
import PaginationInput from "../../components/Pagination/PaginationInput";
import useStyles from './styles'

import {getSearchCustomerType} from "../FilteringPanel/components/ListSelector/constants"
import {PRODUCT_TYPES_WITH_ALL_OPTION} from "../../containers/ReportGroupCreation/constants"


const START_PAGE_INDEX = 0;

const Customers = (props) => {

  const {
    auth,
    buttonTitle,
    onButtonClick
  } = props;

  let wordTemp;

  const classes = useStyles()

  const [customers, setCustomers] = React.useState({
    data: null,
    loading: true,
    errors: null
  })

  const [page, setPage] = React.useState(START_PAGE_INDEX);
  const [customerListFilter, setCustomerListFilter] = React.useState({
    search: '',
    sortingType: SORTING_TYPES.NAME_ASC
  });

  const [customerRequestFilter, setCustomerRequestFilter] = React.useState({
    customerType: getSearchCustomerType(auth),
    productType: PRODUCT_TYPES_WITH_ALL_OPTION.ALL
  });


  const [parameters, setParameters] = React.useState({
    customer_type: 3,
    sort_by: 'customer_last_name',
    search_word: ''

  });

  const [filterByNameResult, setFilterByNameResult] = React.useState({
    count: undefined,
    word: undefined
  });

  React.useEffect(() => {

    fetchCustomers();

  }, [])


  const handleSearchClicked = (value) => {
    let param = parameters;
    param.search_word = value;    
    setParameters(param);
    fetchCustomers();
    wordTemp = value;
    setFilterByNameResult({
      ...filterByNameResult,
      wordTemp
    });
  };

  const handleSortingTypeChanged = (type) => {

    let param = parameters;

    if (type.value === 4) {
      param.sort_by = "-customer_last_name";

    } else {
      param.sort_by = "customer_last_name";
    }

    setParameters(param);
    fetchCustomers();
  };

  const isEmptySearchResult = () => {
    return filterByNameResult && filterByNameResult.word && filterByNameResult.word.trim().length > 0;
  };

  const handleProductTypeChanged = (type) => {
    let param = parameters;
    param.product_type = type.value;

    setParameters(param);

    fetchCustomers();
  };


  const handleClientTypeChanged = (type) => {
    let param = parameters;
    param.customer_type = type.value;
    setParameters(param);
    fetchCustomers();
  };

  const handleClearSearchClicked = () => {
    handleSearchClicked('');
  };

  const fetchCustomers = async () => {

    setCustomers({
      data: null,
      loading: true,
      errros: null
    })

    setPage(START_PAGE_INDEX); // set page to start page

    try {

      let response = await CustomerReportSettingResource.at(
        'customer/settings/').get(parameters);
      
      setCustomers({
        data: response,
        loading: false,
        errors: null
      })

      setFilterByNameResult({
        word: wordTemp,
        count: response && response.reduce((sum, items) => sum + items.length, 0) || 0
      });

    } catch (errors) {
      setCustomers({
        data: null,
        loading: false,
        errors
      })
    }
  }

  const renderList = () => {

    if (customers.loading) {
      return (
        <>
          <CustomerListItem customer={{}} loading={true}/>
          <CustomerListItem customer={{}} loading={true}/>
          <CustomerListItem customer={{}} loading={true}/>
        </>
      )
    }

    if (customers.data) {
      return [...customers.data[page] || []].map(customer => {
        return (
          <CustomerListItem
            key={customer.customer_id}
            customer={customer}
            buttonTitle={buttonTitle}
            onButtonClick={onButtonClick}
          />
        )
      })
    }

    

    return []
  };

  const handleSearchChange = (value) => {
    setParameters((parameters) => ({...parameters, search_word: value}))
  }


  return (
    <Container style={{height: '100%'}}>
    <Grid className={classes.container} style={{height: '100%'}}>
      <Grid xs={12}>
        <FilteringPanel
          selectedCustomer={customerRequestFilter.customerType}
          handleClientTypeChanged={handleClientTypeChanged}
          clientTypeValue={customerRequestFilter.customerType}
          searchValue={customerListFilter.search}
          handleSearchClicked={handleSearchClicked}
          handleClearSearchClicked={handleClearSearchClicked}
          handleProductTypeChanged={handleProductTypeChanged}
          handleSortingTypeChanged={handleSortingTypeChanged}
          customerSortingEnabled={true}
          productCategoryFilterEnabled={false}
          isChief={UserUtils.isChief(auth)}
          user={auth && auth.user}
        />
      </Grid>

      {isEmptySearchResult() && (
          <Grid item xs={12}>
            <SearchInputResult
              word={filterByNameResult.word}
              count={filterByNameResult.count}
              handleSearchClicked={handleSearchClicked}
              handleClearSearchClicked={handleClearSearchClicked}
            />
          </Grid>
        )}
      
      <Grid xs={12} style={{marginTop: 15}}>
        { renderList() }
      </Grid>
      {customers.data && customers.data.length > 1 && (
        <Grid item xs={12}>
          <Grid container className={classes.paginationWrapper}>
            <Grid item className={classes.paginationItem}>
              <span className={classes.paginationInfo}>Seite {page + 1} von {customers.data.length}</span>
            </Grid>
            <Grid item className={classes.paginationItem}>
              <Pagination handlePageChanged={setPage} totalPageCount={customers.data.length} currentPage={page}/>
            </Grid>
            <Grid item className={classes.paginationItem}>
              <PaginationInput 
                handlePageChanged={setPage}
                totalPageCount={customers.data.length}
                currentPage={page}
                label={'Seite aufrufen:'}/>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
    </Container>
  )
}

export default Customers