import React from 'react';
import connect from "react-redux/es/connect/connect";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";

import {AssetsCategories} from './components'

import { withEuroOrDash } from '../../../../utils/utils';
import useStyles from './styles';
import EmptyListPlaceholder from "./components/EmptyListPlaceholder";
import {openAssetModal} from "../../../../components/AssetModal/actions";
import {ASSET_CATEGORIES, CLEARING_ACCOUNT_ASSET_TYPE} from "./components/AssetsCategories/constants";

const AssetsList = (props) => {
  const classes = useStyles();

  const {
    customerId,
    assetsData,
    dispatch,
    setActiveCategory
  } = props;

  const total_value = assetsData && assetsData.data && Number(assetsData.data.total_value) || 0;
  const assets = assetsData && assetsData.data && assetsData.data.assets || {};
  const isDataExists = Object.keys(assets).length > 0;

  const getCategories = () => {
    return [...ASSET_CATEGORIES, CLEARING_ACCOUNT_ASSET_TYPE]
      .map((category) => {
        if (assetsData.data.assets.hasOwnProperty(category.id)) {
          category.total_value = assetsData.data.assets[category.id].total_value;
          return category;
        }
        return null;
      })
      .filter(category => category)
      .sort((a, b) => a.total_value < b.total_value ? 1 : a.total_value > b.total_value ? -1 : 0);
  };

  return (
    <>
      <Card className={classes.container}>
        <CardContent className={classes.content}>
          <Grid container>
            <Grid item xs={12} md={4} className={classes.infoWrapper}>
              <Typography variant={"h1"} className={classes.header}>
                Sonstige Vermögenswerte
              </Typography>
              <Typography variant="h2" className={classes.totalAmountHeader}>
                {isDataExists && (
                  <>
                    {withEuroOrDash(parseFloat(total_value))}
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} className={classes.categoriesContainer}>
              {isDataExists && (
                <AssetsCategories
                  onAddAssetClick={() => dispatch(openAssetModal())}
                  categories={getCategories()}
                  customerId={customerId}
                  setActiveCategory={setActiveCategory}
                />
              )}
            </Grid>
          </Grid>
          {!isDataExists && (
            <EmptyListPlaceholder
              onAddAssetClick={() => dispatch(openAssetModal())}
            />
          )}
        </CardContent>
      </Card>
    </>
  )
};

export default connect()(AssetsList);