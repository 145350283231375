export const RESET_KEY = 'RESET_KEY';
export const SAVE_MODAL_KEY = 'SAVE_MODAL_KEY';
export const SELECTED_CUSTOMERS_KEY = 'SELECTED_CUSTOMERS_KEY';
export const SELECT_FAKE_CUSTOMER_KEY = 'SELECT_FAKE_CUSTOMER_KEY';
export const MARK_CUSTOMERS_KEY = 'MARK_CUSTOMERS_KEY';

export const REPORT_DISTRIBUTION_TYPE = {
  PDF: {
    value: 2,
    description: 'Post/ PDF'
  },
  POSTBOX: {
    value: 1,
    description: 'Elektronisches Kundenpostfach'
  }
};

export const LAST_REPORT_SENT_DATE = {
  Q1: {
    value: 1,
    description: 'Q1'
  },
  Q2: {
    value: 2,
    description: 'Q2'
  },
  Q3: {
    value: 3,
    description: 'Q3'
  },
  Q4: {
    value: 4,
    description: 'Q4'
  }
};
export const PRODUCT_TYPES = {
  FP: {
      value: 4,
      description: 'Depotplattformen (FP)'
  },
  INV: {
      value: 851,
      description: 'Direktlagerstellen (INV)'
  },
  PI: {
      value: 1510926,
      description: 'Private Investing (PI)'
  },
  ETF: {
      value: 2008953,
      description: 'bfV ETF Depot (ETF)'
  },
  VL: {
      value: 941,
      description: 'Riester (RS)'
  },
  LV: {
      value: 40365,
      description: 'Fondspolicen (LV)'
  }
};
export const PRODUCT_TYPES_WITH_ALL_OPTION = {
  ALL: {
    value: null,
    description: 'Alle Produkte'
  },
  ...PRODUCT_TYPES
};

export const ASSET_CLASS = {
  FUND: {
    value: 601,
    description: 'Fonds'
  },
  EQUITY: {
    value: 1,
    description: 'Aktien'
  },
  BOND: {
    value: 2,
    description: 'Anleihen'
  },
  OTHER: {
    value: 6,
    description: 'Andere'
  }
};

export const customerWithoutGroupMessage = (count) => {
  if (count === 0) {
    return '(Alle Ihrer Kunden mit Investmentdepots wurden bereits zu Gruppen hinzugefügt)';
  }
  if (count > 0) {
    return `(${count} Ihrer Kunde${count > 1 ? 'n' : ''} mit Investmentdepots wurden noch keiner Gruppen hinzugefügt)`;
  }
  return '';
};
