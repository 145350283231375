import React from 'react';
import _ from "lodash";
import connect from "react-redux/lib/connect/connect";
import clsx from "clsx";
import 'react-quill/dist/quill.snow.css';

import PromptDialog from "../../components/PromptDialog";
import {BrokerResource} from "../../utils/api";
import {Grid, InputAdornment, Select, TextField, withStyles} from "@material-ui/core";
import { displayErrorSnackBar, displaySuccessSnackBar } from '../../components/SnackbarProvider/actions'
import {isValidString} from "../../utils/utils";
import {FIELD_REQUIRED_MSG} from "../RiskProfiling/constants";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import styles from "./styles";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ReactQuill from "react-quill";
import DeleteIcon from "../../images/DeleteIcon";
import moment from "moment";
import useStyles from "../CustomDashboardTypeConfiguration/styles";

const NAME_MAX_LENGTH = 50;

const ExtendedHTMLEditor = (props) => {

  const {
    bounds,
    placeholder,
    className,
    value,
    onChange,
    onKeyDown,
    modules,
    classes,
    uid,
    useLatestTemplate,
    hideToolbar,
    addDefaultEmptyLines
  } = props;

  const dialogClasses = useStyles();

  const node = React.useRef();
  const templateButtonsRef = React.useRef();

  const [dialogSettings, setDialogSettings] = React.useState({open: false});

  const [templateOptions, setTemplateOptions] = React.useState([]);
  const [currentTemplate, setCurrentTemplate] = React.useState({});

  React.useEffect( () => {
    // load all templates on mount
    getEditorTemplates();
  }, [uid]);

  React.useEffect(() => {
    // workaround to put editor buttons inside toolbar
    const parentNode = _.get(node, 'current.editingArea.parentNode');
    let toolBar = parentNode && parentNode.querySelector('.ql-toolbar');
    if(toolBar){
      toolBar.append(templateButtonsRef.current)
    }
  }, [_.get(node, 'current.editingArea')]);

  const setContent = (value) => {
    node.current.editor.clipboard.dangerouslyPasteHTML(value, "user");
  };

  const getEditorTemplates = async () => {
    // send request to set Templates
    try {
      let response = await BrokerResource.at(`get_editor_templates/`).get({question_uid: uid});
      // Returns list of options with fields needed for rendering
      let preparedOptions = response.map(prepareOption);
      setTemplateOptions(preparedOptions)
      if (useLatestTemplate) {
        const latestTemplate = _.first(_.orderBy(_.filter(response, (template) => !template.user),
          (template) => moment(template.updated_at), 'desc'))

        if (latestTemplate) {
          setCurrentTemplate(latestTemplate)
          setContent(latestTemplate.content)
        }
      }
    } catch (error) {
      props.dispatch(displayErrorSnackBar('Fehler beim Laden der Textbaustein'))
    }
  };

  const deleteTemplate = (template) => async () => {
    try {
      await BrokerResource.at(`${template.id}/delete_editor_template/`).delete();

      // update options by filtering out deleted option
      let newOptions = [...templateOptions].filter(option => option.id !== template.id);
      setTemplateOptions(newOptions);
      // check if currentTemplate exists
      if(currentTemplate.id === template.id){
        setCurrentTemplate({});
      }
      props.dispatch(displaySuccessSnackBar('Textbaustein erfolgreich gelöscht'))
    } catch (error) {
      props.dispatch(displayErrorSnackBar('Fehler beim Löschen der Textbaustein'))
    }
    closeDialog();
  };

  const prepareOption = (template) => {
    return {
      id: template.id,
      content: template.content,
      name: template.name,
      user: template.user
    }
  };

  const activateDeleteTemplateDialog = (e, template) => {
    if(e && e.stopPropagation) e.stopPropagation();
    // change options
    setDialogSettings({
      title: 'Textbaustein löschen',
      okBtnLabel: 'Löschen',
      handleYesBtn: deleteTemplate(template),
      message: 'Sind Sie sicher?',
      open: true
    });
  };

  const closeDialog = () => {
    setDialogSettings({open: false});
  };

  const LoadTemplate = () => {
    const findTemplateById = (id) => {
      return _.find(templateOptions, o => o.id === id) || {};
    };

    const onTemplateChange = (event) => {
      const template = findTemplateById(event.target ? event.target.value : event);
      setContent(template.content || '');
      setCurrentTemplate(template)
    };

    const buildSelectRenderValue = (value) => {
      const o = findTemplateById(value);
      return o.name;
    };

    return (
      <FormControl disabled={templateOptions.length === 0} className={clsx(classes.selectFormControl, classes.templateSelectFormElement)}>
        <InputLabel classes={{root: classes.selectFormLabel}}>Textvorlage laden</InputLabel>
        <Select
          variant="outlined"
          classes={{root: classes.selectRoot}}
          onChange={onTemplateChange}
          value={currentTemplate.id || ''}
          renderValue={buildSelectRenderValue}
          label={"Textvorlage laden"}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
          {_.sortBy(templateOptions, t => _.lowerCase(t.name)).map(template => (
            <MenuItem
              key={template.id}
              value={template.id}
              className={classes.menuItem}
              onClick={template.id === currentTemplate.id ? () => onTemplateChange(currentTemplate.id) : null}
            >
              <span style={{flexGrow: 1}}>{template.name}</span>
              {template.user && (
                <IconButton size={"small"} onClick={(e) => activateDeleteTemplateDialog(e, template)}>
                  <DeleteIcon fontSize="inherit"/>
                </IconButton>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  };

  const SaveTemplate = () => {
    const [formData, setFormData] = React.useState({});
    const [isSaveDialogOpen, setIsSaveDialogOpen] = React.useState(false);

    // set form data on save dialog open / close
    React.useEffect(() => {
      setFormData(isSaveDialogOpen ? {...currentTemplate} : {});
    }, [isSaveDialogOpen]);

    const handleNameChange = (event) => {
      setFormData({...formData, name: event.target.value, error: undefined});
    };

    const closeSaveDialog = () => {
      setIsSaveDialogOpen(false);
    };

    const setSaveTemplateActionButton = () => {
      setIsSaveDialogOpen(true);
    };

    const saveTemplate = async (template_id) => {
      // send user id, content
      let template_name = formData.name;

      // validate template name
      if(!isValidString(template_name)) {
        setFormData({...formData, error: FIELD_REQUIRED_MSG});
        return;
      }

      let requestBody = {
        content: value,
        name: template_name,
        question_uid: uid
      };

      if (!!template_id){requestBody.current_template = template_id}

      try {
        let response = await BrokerResource.at(`create_editor_template/`).post(requestBody);

        // update option with created / updated
        let newOption = prepareOption(response);
        let oldOptions = [...templateOptions];
        const idx = _.findIndex(oldOptions, o => o.id === newOption.id);
        if(idx === -1){
          oldOptions.push(newOption);
        } else {
          oldOptions[idx] = newOption
        }

        setTemplateOptions(oldOptions);
        setCurrentTemplate(newOption);
        setIsSaveDialogOpen(false);

        props.dispatch(displaySuccessSnackBar('Textbaustein erfolgreich gespeichert'));
      } catch (error) {
        // TODO: change error on backend
        if(error && _.get(error, 'data.non_field_errors.0') === 'The fields name, user, question_uid must make a unique set.'){
          setFormData({...formData, error: 'Es existiert bereits ein Textbaustein mit dem gleichen Namen'});
        } else {
          props.dispatch(displayErrorSnackBar('Fehler beim Speichern der Textbaustein'))
        }
      }
    };

    return (
      <>
        <input className={classes.saveBtn} type={'button'} disabled={!isValidString(value)} onClick={setSaveTemplateActionButton} value={'Textvorlagen speichern'} />

        <Dialog fullWidth maxWidth={'md'} open={isSaveDialogOpen}>
          <DialogTitle classes={{root: dialogClasses.dialogTitleRoot}}>
            <div>Textbaustein speichern</div>
            <IconButton aria-label="close" classes={{root: dialogClasses.closeButton}} onClick={closeSaveDialog}>
                <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent classes={{root: dialogClasses.dialogContentRoot}}>
            <TextField
              autoFocus
              margin="dense"
              label="Textbausteinname"
              fullWidth
              variant="standard"
              InputProps={{
                endAdornment: <InputAdornment className={classes.inputAdornment} position="end">{formData.name && formData.name.length || 0}/{NAME_MAX_LENGTH}</InputAdornment>
              }}
              inputProps={{maxLength: NAME_MAX_LENGTH}}
              onChange={handleNameChange}
              error={!!formData.error}
              helperText={formData.error}
              value={formData.name || ''}
            />
          </DialogContent>
          <DialogActions classes={{root: dialogClasses.dialogActionsRoot}}>
            <Grid container style={{justifyContent: "space-between"}} spacing={1}>
              <Grid item xs={12} sm={2}><PrimaryButton onButtonClick={closeSaveDialog} variant="outlined" text="Abbrechen" style={{width: '100%'}} /></Grid>
              <Grid item xs={12} sm={5}><PrimaryButton onButtonClick={() => saveTemplate()} text="Neuer Textbaustein speichern" style={{width: '100%'}} /></Grid>
              {!!formData.id && !!formData.user && (
                <Grid item xs={12} sm={4}><PrimaryButton onButtonClick={() => saveTemplate(formData.id)} text="Änderung speichern" style={{width: '100%'}} /></Grid>
              )}
            </Grid>
          </DialogActions>
        </Dialog>
      </>
    )
  };

  const isQuillEmpty = (quill) => {
    if ((quill.getContents()['ops'] || []).length !== 1) { return false }
    return quill.getText().trim().length === 0
  };

  const handleChange = (content, delta, source, editor) => {
    let valueUpdated = isQuillEmpty(editor) ? null : content;
    // in case it's initial change due to set empty lines -> ignore
    if (source === 'api' && content === null) {
      return;
    }
    onChange(valueUpdated, source, editor);
  };

  // add '<p><br></p>' to initialValue for caret for new line
  const quillDefaultValue = addDefaultEmptyLines ? '<br><br><br><p><br></p>' : null;
  // set initialValue only if value is undefined or null is for empty editor
  const quillValue = value || quillDefaultValue;

  return (
    <>
      <div ref={templateButtonsRef} className={clsx('ql-formats', !hideToolbar && classes.editorWrapper)}>
        {!hideToolbar && (
          <>
            <SaveTemplate />
            <LoadTemplate />
          </>
        )}

        {/*  Dialog for delete operations*/}
        <PromptDialog
          title={dialogSettings.title}
          isOpen={dialogSettings.open}
          handleNoBtn={closeDialog}
          handleCancel={closeDialog}
          cancelBtnLabel={'Abbrechen'}
          handleYesBtn={dialogSettings.handleYesBtn}
          okBtnLabel={dialogSettings.okBtnLabel}
          dialogExtraContent={dialogSettings.dialogExtraContent}
          message={dialogSettings.message || ''}
        />
      </div>
      <ReactQuill
        ref={node}
        bounds={bounds}
        placeholder={placeholder}
        className={clsx(className, classes.editor)}
        value={quillValue !== undefined ? quillValue : null}  // ReactQuill crashes if undefined is passed to value
        onChange={handleChange}
        onKeyDown={onKeyDown}
        modules={modules}
      />
    </>
  )
};

export default connect()(withStyles(styles)(ExtendedHTMLEditor));
