const get_edit_assets_chapter_1 = (pathname) => {
  return {
    selector: '#asset-category-back-to-list-tour-element',
    key: 'other_assets_category_back',
    pathname
  };
};
const edit_assets_chapter_2 = {
  selector: '#asset-category-details-tour-element',
  key: 'other_assets_category_details'
};
const edit_assets_chapter_3 = {
  selector: '#add-other-assets-button-tour-element',
  key: 'other_assets_category_add_button'
};
const edit_assets_chapter_4 = {
  selector: '#asset-details-tour-element',
  key: 'other_assets_category_asset_details'
};
const edit_assets_chapter_5 = {
  selector: '#edit-asset-button-tour-element',
  key: 'other_assets_category_edit_button'
};
const edit_assets_chapter_6 = {
  selector: '#delete-asset-button-tour-element',
  key: 'other_assets_category_delete_button'
};

export const getEditAssetsChapterSteps = (pathname) => {
  return [
    get_edit_assets_chapter_1(pathname),
    edit_assets_chapter_2,
    edit_assets_chapter_3,
    edit_assets_chapter_4,
    edit_assets_chapter_5,
    edit_assets_chapter_6,
  ];
};
