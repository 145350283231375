import React from 'react'
import { isExpanded } from '../utils';

export function useExpandedItems(expanded, onExpandedChanged, configurationKey) {
  // FIXME: not sure that it looks like a hook
  const handleExpandedChanged = (ptf, isExpanded) => onExpandedChanged([configurationKey, ptf.depotNumber], isExpanded);

  const isPtfExpanded = (ptf) => isExpanded(expanded, [configurationKey, ptf.depotNumber]);

  return [isPtfExpanded, handleExpandedChanged]
}