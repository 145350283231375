import React from 'react';
import _ from 'lodash';

import { Container, Grid, withStyles, CircularProgress } from '@material-ui/core';

import CustomersList from './components/CustomersList';
import SaveGroupModal from './components/SaveGroupModal';
import Footer from './components/Footer';
import DeleteCustomersModal from './components/DeleteCustomersModal';
import withNotification from '../../components/NotificationProvider';

import styles from './styles';
import { GroupResource } from '../../utils/api';
import { paginateArray } from '../../utils/utils';
import { ACTION_TYPE } from './components/CustomersList/CustomersList';


class GroupCreation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group: {
        data: undefined,
        loading: true,
        error: undefined
      },
      customers: undefined,
      selected: [],
      isModalVisible: false,

      isDeleting: false
    };

    this.fetchGroup = this.fetchGroup.bind(this);
    this.handleAllSelected = this.handleAllSelected.bind(this);
    this.handleCustomerSelected = this.handleCustomerSelected.bind(this);
    this.displayModal = this.displayModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  componentDidMount() {
    this.fetchGroup();
  }

  displayModal() {
    this.setState({
      isModalVisible: true
    });
  }

  handleModalClose() {
    this.setState({
      isModalVisible: false
    });
  }

  getNotificationMessage() {
    return`${this.state.selected.length} 
    Kunde${this.state.selected.length > 1 ? 'n' : ''} 
    würde${this.state.selected.length > 1 ? 'n' : ''} 
    von der Gruppe ‘${this.state.group.data.name}’ entfernt.`
  }

  async handleDeleteClick() {
    let groupId = this.props.computedMatch.params.group_id;
    this.setState({
      isDeleting: true
    });
    try {
      let body = {
        customers_to_remove: this.state.selected.map(customer => customer.id)
      };
      await GroupResource.at(`${groupId}/customers/`).patch(body);
      this.props.displayNotification('success', this.getNotificationMessage());

      this.setState({
        isDeleting: false,
        isModalVisible: false,
        selected: []
      });

      this.fetchGroup();

    } catch (err) {
      this.setState({
        isDeleting: false
      });
    }
  }

  handleCustomerSelected(customer) {
    let selectedCustomers = [...this.state.selected];

    let isCustomerSelected = _.find(selectedCustomers, item => item.customer_id === customer.customer_id);

    if (isCustomerSelected) {
      selectedCustomers = _.filter(selectedCustomers, item => item.customer_id !== customer.customer_id);
    } else {
      selectedCustomers.push(customer);
    }

    this.setState({
      selected: selectedCustomers
    });
  }

  handleAllSelected() {
    let allCustomers = Array.prototype.concat.apply([], this.state.customers);
    if (this.state.selected.length === allCustomers.length) {
      this.setState({
        selected: []
      });
    } else {
      this.setState({
        selected: allCustomers
      });
    }
  }

  async fetchGroup() {
    let groupId = this.props.computedMatch.params.group_id;

    this.setState({
      group: {
        data: undefined,
        loading: true,
        error: undefined
      },
      customers: undefined
    });

    try {
      if (!_.isNil(groupId)) {
        let response = await GroupResource.at(`${groupId}/`).get();
        this.setState({
          group: {
            data: response,
            loading: false,
            error: undefined
          },
          customers: [...paginateArray(response.customers, 12)]
        });
      }
    } catch (error) {
      this.setState({
        group: {
          data: undefined,
          loading: false,
          error: error
        },
        customers: undefined
      });
    }
  }

  getCustomerCounterMessage() {
    return `${this.state.group.data.customers.length} Kunde${this.state.group.data.customers.length > 1 ? 'n' : ''}`
  }

  render() {
    const { classes } = this.props;

    return (
      <>
      <Container className="app-page-container">
        <Grid container>
          <Grid item lg={4} md={12} sm={12}>
            <h1 style={{
              fontFamily: 'Roboto-Regular',
              color: '#626970',
              fontSize: 34,
              fontWeight: 'normal',
              margin: 0,
              marginBottom: 10
            }}>Kunden von der Gruppe löschen</h1>
            {this.state.group.loading ? (
              <CircularProgress />
            ) : (
              <p style={{
                fontFamily: 'Roboto-Regular',
                fontSize: 14,
                color: '#626970',
                margin: '5x 0'
              }}><b>GRUPPE: {this.state.group.data.name}</b> ({this.getCustomerCounterMessage()})</p>
            )}
          </Grid>
          <Grid className={classes.customerListSection} item lg={8} md={12} sm={12}>
            <CustomersList 
              customers={this.state.customers}
              selected={this.state.selected}
              loading={this.state.group.loading}
              handleCustomerSelected={this.handleCustomerSelected}
              handleAllSelected={this.handleAllSelected}
              actionType={ACTION_TYPE.REMOVE}
            />
          </Grid>
        </Grid>
      </Container>
      <Footer 
        isVisible={true}
        selectedCustomers={this.state.selected}
        handleNextStepClicked={this.displayModal}
        nextButtonText={'Die Kunden von der Gruppe entfernen'}
      />
      <DeleteCustomersModal 
        open={this.state.isModalVisible} 
        handleClose={this.handleModalClose}
        selectedCustomersNumber={this.state.selected.length}
        handleSaveClick={this.handleDeleteClick}
        isDeleting={this.state.isDeleting}
      />
      </>
    )
  }
}

export default withNotification(withStyles(styles)(GroupCreation));