import React from 'react';


export default ({color, onClick, size}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.001" height="14.001" viewBox="0 0 20.001 14.001">
      <g transform="translate(7.999 14.001) rotate(180)">
        <path fill={color || '#0092e5'} d="M13.709.292a1,1,0,0,0-1.414,0L7,5.586,1.707.293a1.024,1.024,0,0,0-1.413,0,1,1,0,0,0,0,1.414l6,6a1,1,0,0,0,1.414,0l6-6A1,1,0,0,0,13.709.292Z" transform="translate(0 14.001) rotate(-90)"/></g>
        <rect fill={color || '#0092e5'} width="20" height="2" rx="1" transform="translate(20.001 8) rotate(180)"/></svg>
  )
}