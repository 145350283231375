import React from "react";

export default () => {
  return (
    <svg
      id="Gruppe_8190"
      data-name="Gruppe 8190"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="24.375"
      viewBox="0 0 30 24.375"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_8060"
            data-name="Rechteck 8060"
            width="30"
            height="24.375"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_8189" data-name="Gruppe 8189" clip-path="url(#clip-path)">
        <path
          id="Pfad_7830"
          data-name="Pfad 7830"
          d="M24,14.92H2.239a2.238,2.238,0,0,0-1.852,3.5l1.29,1.9a2.236,2.236,0,0,0,1.852.98H22.9a2.244,2.244,0,0,0,1.939-1.12l1.1-1.9A2.238,2.238,0,0,0,24,14.92m.468,2.508-1.1,1.9a.541.541,0,0,1-.468.27H3.53a.54.54,0,0,1-.447-.237l-1.29-1.9a.54.54,0,0,1,.447-.844H24a.54.54,0,0,1,.468.81M29.878,1.9l-.5-1.059A1.39,1.39,0,0,0,27.64.077L23.088,1.765a1.264,1.264,0,0,0-.723.693,1.278,1.278,0,0,0-.054.18L17,4.455l-.12-.251a.85.85,0,0,0-.767-.483H6.834a.849.849,0,0,0-.849.849V7.318a.849.849,0,0,0,.849.849h1V9.241H4.578a.849.849,0,0,0-.562.213L.85,12.249a.849.849,0,0,0,.562,1.485H15.127a.849.849,0,0,0,0-1.7H3.657L4.9,10.94H21.12l3.758,2.64a.849.849,0,1,0,.976-1.389L21.877,9.4a.849.849,0,0,0-.488-.155H16.515V8.167h.916a.847.847,0,0,0,.831-1.017l5.255-1.757a1.242,1.242,0,0,0,1.064.084l4.5-1.708a1.387,1.387,0,0,0,.807-.743A1.4,1.4,0,0,0,29.878,1.9M7.683,5.418h7.9l.5,1.051h-8.4Zm7.134,3.823H9.532V8.167h5.285Zm9.55-5.5-.246-.548,3.823-1.418.244.515ZM6.263,18.108a1.1,1.1,0,1,1-1.1-1.1,1.1,1.1,0,0,1,1.1,1.1m5.425,0a1.1,1.1,0,1,1-1.1-1.1,1.1,1.1,0,0,1,1.1,1.1m5.638,0a1.1,1.1,0,1,1-1.1-1.1,1.1,1.1,0,0,1,1.1,1.1m5.242,0a1.1,1.1,0,1,1-1.1-1.1,1.1,1.1,0,0,1,1.1,1.1m-2.863,5.418a.849.849,0,0,1-.849.849H2.331a.849.849,0,1,1,0-1.7H18.856a.849.849,0,0,1,.849.849m4.846,0a.849.849,0,0,1-.849.849H20.992a.849.849,0,1,1,0-1.7H23.7a.849.849,0,0,1,.849.849"
          transform="translate(0 0)"
          fill="#80858c"
        />
      </g>
    </svg>
  );
};
