import { makeStyles } from "@material-ui/core";

const styles = (theme) => ({
  chartsWrapper: {
    '& > div': {
      height: '100%',
      '& > div > div': {
        height: '100%',
      },
    },
  },
  nestedFilterSpacing: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2)
    },
  },
  icons: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 18,
    float: 'right'
  },
})

export default makeStyles(styles);
