import React from 'react';

function EeiRealEstateAssets({}) {
  return (
    <svg id="Gruppe_8242" data-name="Gruppe 8242" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rechteck_7677" data-name="Rechteck 7677" width="30" height="30" fill="#fff"/>
        </clipPath>
      </defs>
      <g id="Gruppe_7978" data-name="Gruppe 7978" clipPath="url(#clip-path)">
        <path id="Pfad_6748" data-name="Pfad 6748" d="M29,14H17a1,1,0,0,1-1-1V1a1,1,0,0,1,1-1A13.014,13.014,0,0,1,30,13a1,1,0,0,1-1,1M18,12h9.955A11.019,11.019,0,0,0,18,2.045Z" fill="#fff"/>
        <path id="Pfad_6749" data-name="Pfad 6749" d="M13,30A13,13,0,0,1,13,4a1,1,0,0,1,1,1V16H25a1,1,0,0,1,1,1A13.014,13.014,0,0,1,13,30M12,6.045A11,11,0,1,0,23.955,18H13a1,1,0,0,1-1-1Z" fill="#fff"/>
      </g>
    </svg>
  )
}

EeiRealEstateAssets.propTypes = {}

EeiRealEstateAssets.propDefs = {}

EeiRealEstateAssets.defaultProps = {}

export default EeiRealEstateAssets;