import React from "react";
import {FETCH_DATA_INITIAL_STATE, getFetchDataErrorState, getFetchDataLoadingState} from "../../../hooks/constants";
import _ from "lodash";
import {PRODUCT_TYPE} from "../constants";
import {fetchAssetsInfoData as _fetchAssetsInfoData} from "./useYearlyPerformanceData";
import {processProductsData} from "./useMetricsData";
import {
  getProductIdsAsString,
  getProductsDataListRepresentation, getProductsToUseAsDict,
  updateTabsDataWithCurrentProductConfig
} from "../utils";

function useAssetsInfoData(products){

  const [data, setData] = React.useState(FETCH_DATA_INITIAL_STATE);

  const [productsToUse, setProductsToUse] = React.useState([]);

  React.useEffect(() => {
    if (!_.isEmpty(products)) {
      // use only single assets that are funds
      setProductsToUse([...products.filter((product) => product.type === PRODUCT_TYPE.ASSET && _.get(product, 'data.is_fund'))]);
    }

  // triggered when selected products are changed
  }, [getProductIdsAsString(products)])

  React.useEffect(() => {
    // use effect to update configuration of the products in data
    updateTabsDataWithCurrentProductConfig(data, products, setData)
  }, [products])

  React.useEffect(() => {
    if (!_.isEmpty(productsToUse)) {
      fetchAssetsInfoData()
    }
  }, [productsToUse]);

  const fetchAssetsInfoData = async() => {
    setData(getFetchDataLoadingState())

    try {

      processProductsData(productsToUse, await _fetchAssetsInfoData(productsToUse), 'asset_info')

      setData({
        data: getProductsToUseAsDict(productsToUse),
        loading: false,
        errors: null,
        updatedAt: +new Date()
      })
    } catch (errors) {
      setData(getFetchDataErrorState(errors))
    }
  }

  return getProductsDataListRepresentation(data)
}

export default useAssetsInfoData