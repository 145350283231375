import React from 'react';

function WaterAndMarineResources({}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37.622"
      height="38.919"
      viewBox="0 0 37.622 38.919"
    >
      <path
        id="Pfad_7848"
        d="M20.5,6.381a1.169,1.169,0,0,0-2.182,0c-1.054,2.86-6.922,7.958-6.922,13.506,0,10.242,16.025,10.236,16.025,0,0-5.549-5.867-10.645-6.921-13.506m-1.138,18.9c-3.13,0-5.645-1.969-5.645-5.4,0-3.779,3.69-7.661,5.692-10.7,2,3.035,5.692,6.917,5.692,10.7,0,3.138-2.17,5.4-5.738,5.4M3.755,14.141,8.119,12.09a1.132,1.132,0,0,0,.546-1.521,1.169,1.169,0,0,0-1.547-.537l-1.766.829A15.875,15.875,0,0,1,27.405,4.406,1.171,1.171,0,0,0,28.994,4a1.13,1.13,0,0,0-.412-1.562A18.216,18.216,0,0,0,3.113,10.175l-.9-1.861A1.169,1.169,0,0,0,.66,7.776,1.133,1.133,0,0,0,.113,9.3L2.207,13.6a1.169,1.169,0,0,0,1.548.537M24.115,32.9l-3.989-2.69a1.173,1.173,0,0,0-1.613.3,1.129,1.129,0,0,0,.3,1.586l1.638,1.1c-8.785.572-16.838-6.383-16.739-15.564a1.161,1.161,0,0,0-2.321-.025C1.276,28.286,10.728,36.3,20.929,35.451l-1.174,1.682a1.129,1.129,0,0,0,.3,1.586,1.174,1.174,0,0,0,1.613-.3l2.746-3.935a1.129,1.129,0,0,0-.3-1.586m13.5-26.166a1.147,1.147,0,0,0-1.067-1.226l-4.841-.37a1.156,1.156,0,0,0-1.247,1.048l-.376,4.74a1.161,1.161,0,0,0,2.314.177L32.553,9.2A15.336,15.336,0,0,1,27.234,31.18a1.13,1.13,0,0,0-.432,1.556,1.171,1.171,0,0,0,1.583.425A17.587,17.587,0,0,0,34.272,7.625l2.1.161a1.155,1.155,0,0,0,1.247-1.049"
        transform="translate(0)"
        fill="#fff"
      />
    </svg>
  )
}

WaterAndMarineResources.propTypes = {}

WaterAndMarineResources.propDefs = {}

WaterAndMarineResources.defaultProps = {}

export default WaterAndMarineResources;