import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  addCustomersToStore,
  removeCustomersFromStore,
  addCustomersDataLoadingErrorToStore
} from './actions';
import {
  MOCK_CUSTOMERS_LIST
} from './constants';
import {
   paginateArray
} from '../../utils/utils';
import { GroupResource } from '../../utils/api';
import { filterCustomersData, getCustomersFilters } from './utils';
import {DASHBOARD_SETTINGS_TYPE} from "../../containers/DashboardSettings/constants";
import { getSearchCustomerType } from '../FilteringPanel/components/ListSelector/constants';
import {getGuideSelector} from "../../containers/CustomerDashboard/CustomerDashboard";
import {createSelector} from "reselect";
import {getAuthSelector} from "../../utils/redaxSelectors";


const getCustomersDataProvider = (state) => state.get('customersDataProvider')

const getCustomersDataProviderCustomers = createSelector(
  [getCustomersDataProvider],
  (customersDataProvider) => customersDataProvider.toJS().customers
)

const getCustomersDataProviderErrors = createSelector(
  [getCustomersDataProvider],
  (customersDataProvider) => customersDataProvider.toJS().errors
)

const getShowReportingCustomersDataProviderCustomers = createSelector(
  [getCustomersDataProvider],
  (customersDataProvider) => customersDataProvider.toJS().showReportingCustomers
)

const getShowReportingCustomersDataProviderErrors = createSelector(
  [getCustomersDataProvider],
  (customersDataProvider) => customersDataProvider.toJS().showReportingCustomersErrors
)

const mapStateToProps = state => ({
  customers: getCustomersDataProviderCustomers(state),
  showReportingCustomers: getShowReportingCustomersDataProviderCustomers(state),
  errors: getCustomersDataProviderErrors(state),
  showReportingCustomersErrors: getShowReportingCustomersDataProviderErrors(state),
  guide: getGuideSelector(state),
  auth: getAuthSelector(state),
});

const mapDispatchToProps = dispatch => ({
  addCustomersToStore: customers => dispatch(addCustomersToStore(customers)),
  removeCustomersFromStore: () => dispatch(removeCustomersFromStore()),
  addCustomersLoadingError: () => dispatch(addCustomersDataLoadingErrorToStore())
});

/**
 * Provide functionality for customers data retrieving/filtering
 * @param {React.Component|function} WrappedComponent 
 */
const withCustomersData = (WrappedComponent, showReporting=undefined) => connect(mapStateToProps, mapDispatchToProps)(props => {
  const $customers = React.useMemo(() => {
    const key = !!showReporting ? 'showReportingCustomers' : 'customers';
    return props[key]
  }, [props.customers, props.showReportingCustomers]);

  //#region Component State

  const [filteredCustomers, setFilteredCustomers] = React.useState({
    data: undefined,
    loading: $customers || ($customers && $customers.length == 0) ? false : true,
    errors: undefined
  });
  const [globalCustomers, setGlobalCustomers] = React.useState({
    loading: $customers || ($customers && $customers.length == 0) ? false : true,
    errors: undefined
  });

  const [filtersOptions, setFilters] = React.useState({
    filters: undefined,
    updated: false
  });

  //#endregion 

  //#region Component Hooks

  /**
   * Update filtered customers with customers from store after first data fetching.
   */
  React.useEffect(() => {

    if (!_.isNil($customers)) {
      let _filteredCustomers = getMyCustomers($customers);

      setFilteredCustomers({
        data: [...paginateArray(_filteredCustomers, 12)],
        loading: false,
        error: undefined
      });

      setGlobalCustomers({
        loading: false,
        errors: undefined
      })
    }

  }, [$customers, globalCustomers.loading]);

  function getFilteredOutCustomers(customers){
    return _.filter(customers, customer => {
      if (!_.isNil(customer.group_identifiers) && Object.keys(customer.group_identifiers).length > 0) {
        // if dashboard group process we filter out customers that are already in dashboard groups
        if(!_.isNil(props.dashboardGroupType) && !_.isEmpty(customer.dashboard_groups)) {
          let isBrokerGroup = group => group.is_broker_group
          let hasBrokerGroups = customer.dashboard_groups.some(isBrokerGroup)

          let isCustomerGroup = group => !group.is_broker_group
          let hasCustomerGroups = customer.dashboard_groups.some(isCustomerGroup)

          if( (props.dashboardGroupType == DASHBOARD_SETTINGS_TYPE.BROKER_GROUP && hasBrokerGroups) ||
              (props.dashboardGroupType == DASHBOARD_SETTINGS_TYPE.CUSTOMER_GROUP && hasCustomerGroups)){

            return false
          }
        }
        return true;
      }
      return true;
    });
  }

  React.useEffect(() => {
    const key = !!showReporting ? 'showReportingCustomersErrors' : 'errors';
    if (props[key]) {
      setGlobalCustomers({
        loading: false,
        errors: true
      });
    }
  }, [props.errors, props.showReportingCustomersErrors])

  //#endregion


  const handleFiltersUpdated = (filters) => {

    setFilters({
      filters,
      updated: !filtersOptions.updated
    });

    setFilteredCustomers({
      data: [],
      loading: true,
      errors: undefined
    });

    try {

      let _filteredCustomers = getFilteredOutCustomers($customers)
      
      let filteredCustomersData = filterCustomersData(_filteredCustomers, filters, {
        ...getFiltersOptions(),
      });

      setFilteredCustomers({
        loading: false,
        errors: undefined,
        data: [...paginateArray(filteredCustomersData, 12)]
      });

    } catch (errors) {

      setFilteredCustomers({
        data: [],
        loading: false,
        errors
      });

    }
  };

  const handleCustomerUpdate = (customer) => {

    if (!globalCustomers.loading) {

    try {

      let customers = $customers;

      if (customers && customers.length) {
  
        for (let customerIndex = 0; customerIndex < customers.length; customerIndex++) {
  
          if (customers[customerIndex].id == customer.id) {
            customers[customerIndex].group = undefined;
            customers[customerIndex].dashboard_groups = undefined;
            break;
          }
        }
      }

      addCustomersToStore(customers);

      let _filteredCustomers = getMyCustomers(customers);

      setFilteredCustomers({
        loading: false,
        errors: undefined,
        data: [...paginateArray(_filteredCustomers, 12)]
      });

    } catch {

    }
  }

  };

  const getMyCustomers = (customers) => {
    /* return filtered customers CLIENT_TYPES.MY */
    let _filteredCustomers = getFilteredOutCustomers(customers);

      let filters = filtersOptions.filters;
      if (!filters) {
        filters = {
          clientType: {
            value: getSearchCustomerType(props.auth).value
          },
          broker: props.auth
        };
        setFilters({...filtersOptions, filters: filters}) // if filter is applied when customers are get - it must be set in filtersOptions
      }
      return filterCustomersData(_filteredCustomers, filters, {
        ...getFiltersOptions(),
      });
  }

  const getFiltersOptions = () => {
    if (!_.isNil($customers) && !globalCustomers.loading) {
      let filtersToReturn = getCustomersFilters($customers)
      // if filtersOptions contains search word - pass it to children
      if (_.get(filtersOptions, 'filters.search')){
        filtersToReturn['search'] = _.get(filtersOptions, 'filters.search')
      }
      return filtersToReturn;
    }

    return {};
  };

  const setGroupToCustomers = (list, previousList) => {
    let customersToUpdate = list || []
    let previouslySelectedCustomers = previousList || []

    // from previouslySelectedCustomers filters out customers that were not deselected
    let deselectedCustomers = previouslySelectedCustomers.filter(oldCustomer => !customersToUpdate.some(newCustomer => newCustomer.id == oldCustomer.id))

    let globalCustomersData = $customers ? [...$customers] : [];

    for(let index = 0; index < globalCustomersData.length; index++) {
      // for customers that were added
      if (!_.isNil(_.find(customersToUpdate, (item => item.id == globalCustomersData[index].id)))) {

        if (!_.isNil(props.dashboardGroupType)){
          // create dummy groups for customer when new dashboard group is created to remove him from list of customers
          globalCustomersData[index].dashboard_groups = globalCustomersData[index].dashboard_groups || []
          globalCustomersData[index].dashboard_groups.push({is_broker_group:props.dashboardGroupType == DASHBOARD_SETTINGS_TYPE.BROKER_GROUP})
        } else {
          // add dummy group for customer when new group is created
          globalCustomersData[index].group = globalCustomersData[index].id;
          globalCustomersData[index].groups.push(globalCustomersData[index].id);
        }
      }

      // for customers that were removed
      if (!_.isNil(_.find(deselectedCustomers, (item => item.id == globalCustomersData[index].id)))) {
        if (!_.isNil(props.dashboardGroupType)){
          globalCustomersData[index].dashboard_groups = [] // remove group to display proper number of customers without a group
        }
      }

    }

    props.addCustomersToStore(globalCustomersData);
  }

  const getFilteredCustomersData = () => {
    if (props.guide.active) {
      return {
        data: [...paginateArray(MOCK_CUSTOMERS_LIST, 12)],
        loading: false,
        errors: undefined
      }
    }
    return filteredCustomers;
  };

  const getGlobalCustomersData = () => {
    if (props.guide.active) {
      return {
        loading: false,
        errors: undefined
      }
    }
    return globalCustomers;
  }

  return (
    <WrappedComponent
      filteredCustomersData={getFilteredCustomersData()}
      globalCustomers={getGlobalCustomersData()}
      onFiltersUpdated={handleFiltersUpdated}
      onCustomerUpdate={handleCustomerUpdate}
      filtersOptions={getFiltersOptions()}
      currentFilters={filtersOptions}
      setGroupToCustomers={setGroupToCustomers}
      {...props}
    />
  );
});


export const CustomersDataProvider = connect(mapStateToProps, mapDispatchToProps)((props) => {

  React.useEffect(() => {

    try{

      if (props.auth && props.auth.user && props.auth.user.is_broker) {

        fetchCustomers();
  
      }

    } catch {
      
    }
    
  }, []);

  /**
   * Fetch customers data and update store with it.
   */
  const fetchCustomers = async () => {

    try {
      let customers = await GroupResource.at('customers/').get({all: true});
    
      props.addCustomersToStore(customers);
    } catch {
      props.addCustomersLoadingError();
    }

  };

  return (
    <>
      {props.children}
    </>
  )
})

export default withCustomersData;