import React from 'react';
import DashboardCard from "../DashboardCard";
import {getPortfolioCategory} from "../../../../../../components/CustomersDataProvider/utils";
import InstrumentsRiskIndicatorsTable
  from "../../../../../../components/Charts/InstrumentsAllocationTable/InstrumentsRiskIndicatorsTable";
import _ from "lodash";
import AlarmButton from "../../../../../../components/Buttons/AlarmButton/AlarmButton";
import {
  useAlertConfigurationModalContext,
} from "../../../../../RiskDashboard/components/AlertConfigurationModal/AlertConfigurationModal";
import {
  ALERT_PRODUCT_TYPE,
} from "../../../../../RiskDashboard/components/AlertConfigurationModal/utils";


function InstrumentByListIndicatorList({data, riskMetricsData, expandedItemKey, expandedItems, handleExpandedItemsChange, splitByRisk}) {

  const {onSelectAsset} = useAlertConfigurationModalContext()

  const portfolios = React.useMemo(() => {

    return data.filter((portfolio) => !portfolio.is_empty && !portfolio.not_connected)

  }, [JSON.stringify(data)])

  React.useEffect(() => {
    handleExpandedItemsChange && handleExpandedItemsChange(expandedItemKey, portfolios.map((item) => item.id))
  }, [portfolios])

  const renderPortfolioTitle = (portfolio) => {

    const byRiskIndicatorData = portfolio.by_risk_indicator || {}
    let componentsCount = _.flatten(
      Object.keys(byRiskIndicatorData)
        .map((riskIndicator) => byRiskIndicatorData[riskIndicator].components || [])).length
    if (!splitByRisk) {
      componentsCount = portfolio.components.filter((component) => !!component.isin).length
    }

    const portfolioName = `${portfolio.name} (${componentsCount} ${componentsCount > 1 ? 'Positionen' : 'Position'})`
    const {categoryName} = getPortfolioCategory(portfolio)

    const handleButtonClick = () => {
      onSelectAsset && onSelectAsset(portfolio, ALERT_PRODUCT_TYPE.DEPOT, portfolio)
    }

    const alarmExists = !_.isEmpty(portfolio.alerting_configuration)

    return (
      <>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <span>{portfolioName}</span>
          <AlarmButton
            alarmExists={alarmExists}
            alarmActive={alarmExists && _.find(portfolio.alerting_configuration, (configuration) => configuration.active)}
            onClick={handleButtonClick}
          />
        </div>
        {<span>{ categoryName }</span>}
      </>
    )
  }

  return (
    <>
      {portfolios.map((portfolio, index) => (
        <DashboardCard
          id={portfolio.id}
          title={renderPortfolioTitle(portfolio)}
          expandedItemKey={expandedItemKey}
          expandedItems={expandedItems}
          handleExpandedItemsChange={handleExpandedItemsChange}
          expandable
          expandableList
          divider={index + 1 < portfolios.length}
          isLast={index + 1 === portfolios.length}
          headerAlignCenter
          hideDividerOnExpanded
          content={<InstrumentsRiskIndicatorsTable data={portfolio} riskMetricsData={riskMetricsData} splitByRisk={splitByRisk}/>}
        />
      ))}
    </>
  )

}

InstrumentByListIndicatorList.defaultProps = {
  splitByRisk: true
}

export default InstrumentByListIndicatorList
