import React from 'react';
import { connect } from 'react-redux'

import styles from '../styles'

import {CircularProgress, Grid, withStyles} from '@material-ui/core'
import {renderQuestion} from "../../../../../utils";
import {PrimaryButton} from "../../../../../../../components/Buttons";
import {DownloadIcon} from "../../../../../../../images";
import {downloadPdf} from "../../../../../../../utils/utils";
import { displayErrorSnackBar } from "../../../../../../../components/SnackbarProvider/actions";

const DEFAULT_ERROR_MESSAGE = "Fehler beim Herunterladen des Grundwissen Dokuments von www.fundsware.de";

InvestmentIntermediaryInformation.propTypes = {
  
};

function InvestmentIntermediaryInformation(props) {
  const {
    classes,
    onAnswerChange,
    dataService,
    questions,
  } = props;

  const [isDocumentLoading, setDocumentLoading] = React.useState(false);

  const handleDownloadDocumentButtonClick = async () => {
    setDocumentLoading(true);

    const handleErrors = (errors) => {
      console.error(`Error during retrieving basisinformation documents from www.fundsware.de. Error message: ${errors.data || errors}`)
      setDocumentLoading(false);

      props.dispatch(displayErrorSnackBar(DEFAULT_ERROR_MESSAGE))
    };

    try {

      downloadPdf(`/bank_settings/${dataService.customer_id}/investment-intermediary/`, undefined, 'GET')
        .then(() => setDocumentLoading(false))
        .catch((errors) => {
          handleErrors(errors)
        })

    } catch (errors) {
      handleErrors(errors)
    }
  };

  return (
    <>
      {questions && (
        <Grid container className={classes.questionsContent} spacing={2}>
          <Grid item sm={7}>
            <Grid container spacing={2}>
              {questions.map(q => renderQuestion(q, onAnswerChange, dataService))}
            </Grid>
          </Grid>
          <Grid item sm={5}>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <PrimaryButton
                icon={isDocumentLoading ? <CircularProgress size={20} style={{color: 'rgba(0, 0, 0, 0.26)'}}/> : <DownloadIcon color="white" />}
                text="Grundwissen als PDF herunterladen"
                onButtonClick={handleDownloadDocumentButtonClick}
                disabled={isDocumentLoading}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default connect()(withStyles(styles)(InvestmentIntermediaryInformation));