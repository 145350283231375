import _ from 'lodash';
import saveTourProgress from "../../utils/guide_progress_saving";

export class GuideTourStep {
  constructor(id, name, steps, page=undefined, group=undefined) {
    this.id = id;
    this.name = name;
    this.steps = steps;
    this.completedStepsNumber = 0;
    this.group = group;

    if (!_.isNil(page) && !_.isNil(group)) {
      let pageProgress = localStorage.getItem(page);
      if (!_.isNil(pageProgress)) {
        pageProgress = JSON.parse(pageProgress);
        for(let key in pageProgress) {
          if (key == group) {
            this.completedStepsNumber = pageProgress[key];
            break;
          }
        }
      }
    }
  }

  updateStepsWithSettings(settings) {
    this.steps = this.steps.map(step => (
      {...step, ...settings.steps[step.key]}
    ))
  }
}

export function buildGuideTourStep(id, chapter_name, steps, tour_key, chapter_key) {
  return new GuideTourStep(
    id,
    chapter_name,
    steps.map(step => {
      step.chapter_key = chapter_key;
      step.action = saveTourProgress(tour_key, chapter_key);
      return step;
    }),
    tour_key,
    chapter_key
  );
}