import React from 'react'

import { Card } from './components'

import useStyles from './styles'
import images from '../../images'
import { SharedSettingsResource } from '../../utils/api'
import { getSharedSettingValueByHost } from './utils'
import { getFromStorage, NEW_ORIGIN_KEY } from '../../utils/storage'

import './reset.css'
import './bootstrap.css'
import './fontawesome.css'
import './styles.css'

const Investmentsoftware = {
  header: () => <img src={images.lamding.landing_icon_investmentsoftware} alt="Investmentsoftware" class="card-img" />,
  body: () => (
    <>
      <h4>Investmentsoftware</h4>
      <p>Unsere Investmentsoftware hält für Sie neben einem umfassenden Depotreporting eine intuitiven Anlagenberatungsablauf noch viele weitere wertvolle Features bereit. Auch Ihre Kunden erhalten einen Online-Zugang zu Ihrem Gesamtvermögen.</p>
    </>
  ),
  footer: (depotreportingLink) => (
    <a href={depotreportingLink || '#'} target="_blank" class="btn btn-primary">Depotreporting<i class="far fa-long-arrow-right ml-2"></i></a>
  )
}

const InvestmentAnlegerIn = {
  header: () => <img src={images.lamding.landing_icon_investment_anlegerin} alt="Investment-AnlegerIn" class="card-img" />,
  body: () => (
    <>
      <h4>Investment-AnlegerIn</h4>
      <p>Mit Ihrem eigenen Investment Shop können Sie den Fondskauf so einfach wie Einkaufen bei amazon für Kunden und Interessenten auf Ihre Website bringen oder auch in digitalen Mailings zum Einsatz bringen. Nutzen Sie auch hierzu gerne unseren Concierge-Service, um den Shop direkt für sich verfügbar zu machen. Einen interessanten Ansatz zur Ansprache ETF-affiner Kunden finden Sie auch beispielsweise analog mit dem BfV ETF Depot.</p>
    </>
  ),
  footer: (investmentShopLink) => (
    <>
      <a href={investmentShopLink || '#'} target="_blank" class="btn btn-primary">Investment Shop<i class="far fa-long-arrow-right ml-2"></i></a>
      <div class="text-center mt-3">
        <a href="https://bfv-ag.de/b2b/fondsvermoegensverwaltung/bfv-etf-depot/" target="_blank" class="btn btn-link">BfV ETF Depot<i class="far fa-long-arrow-right ml-2"></i></a>
      </div>
    </>
  )
}

const InvestmentResearch = {
  header: () => <img src={images.lamding.landing_icon_investment_research} alt="Investment Research" class="card-img" />,
  body: () => (
    <>
      <h4>Investment Research</h4>
      <p>Orientierung an den Kapitalmärkten und in der Produktvielfalt erhalten Sie durch unseren unabhängigen Investment Research Bereich. Nutzen Sie unseren Investment Radar oder lesen Sie unseren CIO View. Als besondere Dienstleistungen bieten wir Ihnen auch einen Depotchek für Interessenten- und Kundendepots an.</p>
    </>
  ),
  footer: () => (
    <>
      <a href="https://bfv-ag.de/b2b/service/investment-research/" target="_blank" class="btn btn-primary">Investment Radar<i class="far fa-long-arrow-right ml-2"></i></a>
      <div class="text-center mt-3">
        <a href="https://bfv-ag.de/der-bfv-depotcheck/" target="_blank" class="btn btn-link">Depotcheck<i class="far fa-long-arrow-right ml-2"></i></a>
      </div>
    </>
  )
}

const PrivateInvesting = {
  header: () => <img src={images.lamding.landing_icon_private_investing} alt="Private Investing" class="card-img" />,
  body: () => (
    <>
      <h4>Private Investing</h4>
      <p>Legen Sie mit uns Ihre eigene Vermögensverwaltungsstrategie auf und nutzen die Vorteile der einmaligen Dokumentation gepaart mit professionellem Asset Management aus. Wir bieten auch eine passende Palette eigener Strategien für Ihre Kundentypen an.</p>
    </>
  ),
  footer: () => (
    <>
      <a href="https://bfv-ag.de/b2b/fondsvermoegensverwaltung/"  target="_blank" class="btn btn-primary">Fondsvermögens&shy;verwaltung<i class="far fa-long-arrow-right ml-2"></i></a>
      <a href="https://www.privateinvesting.de/" target="_blank" class="btn btn-link">Private Investing<i class="far fa-long-arrow-right ml-2"></i></a>
    </>
  )
}

const Sachwerte = {
  header: () => <img src={images.lamding.landing_icon_sachwerte} alt="Sachwerte / geschlossene AIFs" class="card-img" />,
  body: () => (
    <>
      <h4>Sachwerte / geschlossene AIFs</h4>
      <p>Ein umfassendes und qualifiziertes Sachwerte-Angebot erhalten Sie gemeinsam mit unserer Kooperationsplattform IC Consulting inkl. Produktprüfung und einem Konzept zur Haftungsfreistellung. Es umfasst Anlageklassen wie Immobilien, erneuerbare Energien, Infrastrukturprojekte sowie einem umfassenden Serviceangebot mit festen Ansprechpartnern.</p>
    </>
  ),
  footer: () => <a href="http://www.bca.de/sachwerte" target="_blank" class="btn btn-primary">Sachwerte-Angebot<i class="far fa-long-arrow-right ml-2"></i></a>
}

const InvestmentOperations = {
  header: () => <img src={images.lamding.landing_icon_investment_operations} alt="Investment Operations / Depotbanken" class="card-img" />,
  body: () => (
    <>
      <h4>Investment Operations / Depotbanken</h4>
      <p>Wir arbeiten im Investmentbereich mit einer großen Anzahl an Depotbanken zusammen. Welche Depotmodelle und Konditionen zu Ihnen und Ihren Kunden am besten passen, erfahren Sie unter den unten angegebenen Kontaktdaten und im Bereich Abrechnung.</p>
    </>
  ),
  footer: () => null
}

const Haftungsdach = {
  header: () => <img src={images.lamding.landing_icon_haftungsdach} alt="Haftungsdach" class="card-img" />,
  body: () => (
    <>
      <h4>Haftungsdach</h4>
      <p>Sie möchten Ihre Kunden auf Private Banking Standards beraten und ein Produktuniversum über Investmentfonds hinaus bieten: Im Haftungsdach der Bank für Vermögen erhalten Sie u.a. die Möglichkeit auch Aktien, Anleihen und Zertifikate zu beraten in einem monatlichen All in Paket.</p>
    </>
  ),
  footer: () => <a href="https://bfv-ag.de/b2b/haftungsdach/" target="_blank" class="btn btn-primary">Haftungsdach<i class="far fa-long-arrow-right ml-2"></i></a>
}


const DEFAULT_EMAIL = 'investment@bca.de'
const DEFAULT_PHONE = '06171 – 91 50 160'
const DEFAULT_DEPOTREPORTING_LINK = 'https://www.bca.de/digitale-beratung/depot-reporting/'
const DEFAULT_INVESTMENT_SHOP_LINK = 'https://www.bca.de/investment-shop/'

const LANDING_CONTACT_PHONE = 'LANDING_CONTACT_PHONE'
const LANDING_CONTACT_EMAIL = 'LANDING_CONTACT_EMAIL'
const LANDING_CARD_DEPOTREPORTING_LINK = 'LANDING_CARD_DEPOTREPORTING_LINK'
const LANDING_CARD_INVESTMENT_SHOP_LINK = 'LANDING_CARD_INVESTMENT_SHOP_LINK'


export default function Landing() {

  const classes = useStyles();

  const [sharedSettings, setSharedSettings] = React.useState({
    loading: false,
    data: undefined,
    errors: undefined
  })

  const [landingPageInfo, setLandingPageInfo] = React.useState({
    email: DEFAULT_EMAIL,
    phone: DEFAULT_PHONE,
    depotreportingLink: DEFAULT_DEPOTREPORTING_LINK,
    investmentShopLink: DEFAULT_INVESTMENT_SHOP_LINK
  })

  /** Fetch shared settings, after component was mounted. */
  React.useEffect(() => {

    fetchSharedSettings()

  }, [])


  /** Update landing page info wiith values from shared settings */
  React.useEffect(() => {

    if (sharedSettings.data && sharedSettings.data.host_related_settings) {

      let hostRelatedSharedSettings = sharedSettings.data.host_related_settings
      
      let host = getOrigin()

      let phone = getSharedSettingValueByHost(hostRelatedSharedSettings, LANDING_CONTACT_PHONE, host)
      let email = getSharedSettingValueByHost(hostRelatedSharedSettings, LANDING_CONTACT_EMAIL, host)
      let depotreportingLink = getSharedSettingValueByHost(hostRelatedSharedSettings, LANDING_CARD_DEPOTREPORTING_LINK, host)
      let investmentShopLink = getSharedSettingValueByHost(hostRelatedSharedSettings, LANDING_CARD_INVESTMENT_SHOP_LINK, host)

      let landingPageInfoCopy = {...landingPageInfo}
      if (phone) {
        landingPageInfoCopy.phone = phone
      }
      if (email) {
        landingPageInfoCopy.email = email
      }
      if (depotreportingLink) {
        landingPageInfoCopy.depotreportingLink = depotreportingLink
      }
      if (investmentShopLink) {
        landingPageInfoCopy.investmentShopLink = investmentShopLink
      }

      setLandingPageInfo(landingPageInfoCopy)

    }

  }, [sharedSettings])

  /** Fetch shared settings data */
  const fetchSharedSettings = async () => {

    setSharedSettings({
      data: undefined,
      loading: true,
      errors: undefined
    })

    try {

      const sharedSettings = await SharedSettingsResource.at('data/').get();

      setSharedSettings({
        data: sharedSettings,
        loading: false,
        errors: undefined
      })

    } catch (errors) {
      setSharedSettings({
        loading: false,
        data: undefined,
        errors
      })
    }
  }

  const getOrigin = () => {
    let origin = 'http://dev10.diva.dev.bcaag.de'
    let newOrigin = getFromStorage(NEW_ORIGIN_KEY)
    if (newOrigin) {
      origin = newOrigin
    } else {
      let search = window.location.search;
      search = search.substring(1)
      let searchParam = new URLSearchParams(search);

      let urlOrigin = searchParam.get('origin')
      if (urlOrigin) {
        origin = urlOrigin
      }
    }

    let url = new URL(origin)

    return url.hostname
  }

  return (
    <div className="wrapper">
      <h1>Investment</h1>
      <p className="page-subtitle">Wir halten weitere nützliche Unterstützungsleistungen für Sie bereit. Erfahren Sie mehr in dieser Rubrik.</p>
      <div class="row">
        <div class="col-sm-6 col-lg-6 col-xl-4">
          <Card
            cardHeader={Investmentsoftware.header()}
            cardBody={Investmentsoftware.body()}
            cardFooter={Investmentsoftware.footer(landingPageInfo.depotreportingLink)}
          />
        </div>
        <div class="col-sm-6 col-lg-6 col-xl-4">
          <Card
            cardHeader={InvestmentAnlegerIn.header()}
            cardBody={InvestmentAnlegerIn.body()}
            cardFooter={InvestmentAnlegerIn.footer(landingPageInfo.investmentShopLink)}
          />
        </div>
        <div class="col-sm-6 col-lg-6 col-xl-4">
          <Card
            cardHeader={InvestmentResearch.header()}
            cardBody={InvestmentResearch.body()}
            cardFooter={InvestmentResearch.footer()}
          />
        </div>
        <div class="col-sm-6 col-lg-6 col-xl-4">
          <Card
            cardHeader={PrivateInvesting.header()}
            cardBody={PrivateInvesting.body()}
            cardFooter={PrivateInvesting.footer()}
          />
        </div>
        <div class="col-sm-6 col-lg-6 col-xl-4">
          <Card
            cardHeader={Sachwerte.header()}
            cardBody={Sachwerte.body()}
            cardFooter={Sachwerte.footer()}
          />
        </div>
        <div class="col-sm-6 col-lg-6 col-xl-4">
          <Card
            cardHeader={InvestmentOperations.header()}
            cardBody={InvestmentOperations.body()}
            cardFooter={InvestmentOperations.footer()}
          />
        </div>
        <div class="col-sm-6 col-lg-6 col-xl-4">
          <Card
            cardHeader={Haftungsdach.header()}
            cardBody={Haftungsdach.body()}
            cardFooter={Haftungsdach.footer()}
          />
        </div>
        <div class="col-sm-6 col-lg-6 col-xl-4">
        <div class="card card--more-services">
          <div class="card-body">
            <h4 class="card-title">Wissenswertes</h4>
            <p class="mb-4">Weitere interessante Investment-Themen finden Sie in unserer Infothek, unseren Tutorials und Webinarkanälen.</p>
            <div class="d-flex flex-column align-items-center">
              
              <a href="https://diva.bca.de/#/Infothek/Investment" target="_blank" class="btn btn-secondary"><i class="far fa-info-circle fa-lg mr-2"></i>Infothek</a>
              <a href="https://www.bca.de/DIVA-tutorials/" target="_blank" class="btn btn-secondary mt-2"><i class="far fa-play-circle fa-lg mr-2"></i>Tutorials</a>
              <a href="https://www.gotostage.com/channel/349667e9f54c4c9495425eff2f0c38f1%20" target="_blank" class="btn btn-secondary mt-2"><i class="far fa-user-chart fa-lg mr-2"></i>Webinarkanäle</a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-6 col-xl-4">
        <div class="card card--contact">
          <div class="card-body">
            <i class="fal fa-user-headset fa-4x"></i>
            <h4 class="card-title">Kontakt</h4>
            <p class="font-weight-bold mb-4">Für Ihre Fragen stehen wir Ihnen jederzeit sehr gerne zur Verfügung.</p>
            <a href="tel:+4961719150160"><i class="far fa-phone fa-lg mr-2"></i>{ landingPageInfo.phone }</a>
           
            <a href="mailto:investment@bca.de"><i class="far fa-envelope fa-lg mr-2"></i>{ landingPageInfo.email }</a>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}
