import React from 'react';
import _ from 'lodash';

import {Grid} from '@material-ui/core';

import LostPriceChart from './LostPriceChart';
import VolatilityChart from './VolatilityChart';
import DashboardCard from "../DashboardCard";

import {priceChartTitle, volatilityChartTitle, blueTitle} from '../../../../../../utils/utils'

const RiskCharts = React.memo((props) => {
  const {
    dashboardData,
    titleType,
  } = props;

  const _dashboardData = dashboardData.data

  const volatility = _dashboardData && _dashboardData.volatility;
  const benchmarkVolatility = _dashboardData && _dashboardData.benchmark_volatility;
  const error = volatility && volatility.error;
  const minDateMessage = volatility && volatility.min_date_message;

  const renderContent = () => {
    if (volatility && !minDateMessage) {
      return (
        <div> 
          <Grid container spacing={8}>
            <Grid item xs={12} sm={6}>
              <LostPriceChart
                  title={priceChartTitle[titleType]}
                  volatility={volatility}
                  benchmarkVolatility={benchmarkVolatility}
                  error={error}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <VolatilityChart
                  title={volatilityChartTitle[titleType]}
                  volatility={volatility}
                  benchmarkVolatility={benchmarkVolatility}
                  error={error} />
            </Grid>
          </Grid>

          <Grid>
            <Grid item xs={12} sm={12}>
              <div style={{fontSize: 12}}>
                <div>
                  {!!benchmarkVolatility.benchmark_name ?
                    <>
                      <b>Rot:</b> {benchmarkVolatility.benchmark_name}
                      <br/>
                    </>
                    : ''
                  }
                  <b>Blau:</b> {blueTitle[titleType] || blueTitle.aggregate}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )
    } else {
      return null;
    }
  }

  return renderContent();
});

export default RiskCharts;