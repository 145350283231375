import { fromJS } from 'immutable';
import _ from 'lodash';

import {
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS
} from './constants';
import {
  getFromStorage,
  setInStorage,
  USER_KEY
} from '../../../utils/storage';

let user = getFromStorage(USER_KEY);
if (!_.isNil(user)) {
  user = JSON.parse(user);
}

const initialState = fromJS({
  user: user ? user : null,
  error: false
});

function appReducer(state = initialState, action) {
  switch(action.type) {
    case LOGIN_SUCCESS:
      setInStorage(USER_KEY, JSON.stringify(action.user));
      return state.set('user', fromJS(action.user));
    case LOGOUT_SUCCESS:
      return state.set('user', null);
    case AUTH_ERROR:
      return state.set('error', action.error);
    default:
      return state;
  }
}

export default appReducer;
