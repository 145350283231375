import { createStyles, makeStyles } from '@material-ui/core';

const styles = (theme) => createStyles({
  tableHead: {
    '& td': {
      verticalAlign: 'top',
      color: '#80858C',
      backgroundColor: '#eef0f2',
    },
  },
  tableCell: {
    height: 51,
    verticalAlign: 'middle',
    backgroundColor: '#fff',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    '& .extraButton': {
      display: 'inline-block',
      width: '100%',
      paddingLeft: '1rem',
      '& a': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        fontFamily: 'Roboto-Bold',
        color: `${theme.palette.primary.main} !important`,
        '&.disabled': {
          cursor: 'inherit',
          pointerEvents: 'none',
          opacity: 0.5,
        },
        '&:hover': {
          cursor: 'pointer'
        },
        '& .fa-chevron-right': {
          position: 'relative',
          top: 2,
          fontSize: '1.25rem',
        },
        '& .linkIcon':{
          width: '24px',
          display: 'flex',
          justifyContent: 'center'
        },
        '&.linkButtons': {
          marginTop: 4,
          display: 'flex',
          alignItems: "center",
          textAlign: 'left',
          justifyContent: 'space-between'
        },
      },
    },
    '&:not(:last-child) .extraButton': {
      paddingRight: '1rem',
    }
  },
});

export default makeStyles(styles)