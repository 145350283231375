import React from 'react';
import {Grid} from "@material-ui/core";
import {
  isPortfolioAvailableForPayoutPlan,
  isPortfolioAvailableForSavingsPlan,
  isPortfolioAvailableForSwitchPlan
} from "../utils";
import PaymentPlanSection from "../components_v2/PaymentPlanSection/PaymentPlanSection";
import {isSavingsPlansEnabled} from "../../../components/TradingStore/utils";

function PaymentPlansTab(props) {

  const savingsPlansEnabled = isSavingsPlansEnabled();

  const getSavingPlanTradeOptions = (savingsPlan) => {
    if(savingsPlansEnabled && (props.isVirtual || isPortfolioAvailableForSavingsPlan(savingsPlan, props.instrumentListData, props.banksMapping))){
      return {
        onAddSavingPlanOption: props.onAddSavingPlanOption,
        onAddPortfolioSavingPlanOption: props.onAddPortfolioSavingPlanOption,
        savingPlans: props.combinedTradings,
        savingsPlanSession: props.combinedTradingSession,
      }
    }
  };

  const getPayoutPlanTradeOptions = (savingsPlan) => {
    if(savingsPlansEnabled && isPortfolioAvailableForPayoutPlan(savingsPlan, props.instrumentListData, props.banksMapping, props.banksData)){
      return {
        onAddPayoutPlanOption: props.onAddPayoutPlanOption,
        onAddPortfolioPayoutPlanOption: props.onAddPortfolioPayoutPlanOption,
        payoutPlans: props.combinedTradings,
        payoutPlanSession: props.combinedTradingSession,
      }
    }
  };

  const getSwitchPlanTradeOptions = (savingsPlan) => {
    if(savingsPlansEnabled && isPortfolioAvailableForSwitchPlan(savingsPlan, props.instrumentListData, props.banksMapping, props.banksData)){
      return {
        onAddSwitchPlanOption: props.onAddSwitchPlanOption,
        onAddPortfolioSwitchPlanOption: props.onAddPortfolioSwitchPlanOption,
        switchPlans: props.combinedTradings,
        switchPlanSession: props.combinedTradingSession,
      }
    }
  };

  if (!props.dashboardSettings.with_payment_plans) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PaymentPlanSection
          dataKey={"savings_plan"}
          data={props.paymentPlansData}
          dataLoading={props.paymentPlansLoading}
          dataError={props.paymentPlansLoadingError}
          expandedItems={props.expandedItems.paymentPlansItems}
          getTradeOptions={getSavingPlanTradeOptions}
          expanded={props.expandedItems.paymentPlans}
          onExpandedChange={props.onExpandedItemsChange}
        />
      </Grid>
      <Grid item xs={12}>
        <PaymentPlanSection
          dataKey={"payout_plan"}
          data={props.payoutPlansData}
          dataLoading={props.payoutPlansLoading}
          dataError={props.payoutPlansLoadingError}
          expandedItems={props.expandedItems.payoutPlansItems}
          getTradeOptions={getPayoutPlanTradeOptions}
          expanded={props.expandedItems.payoutPlans}
          onExpandedChange={props.onExpandedItemsChange}
        />
      </Grid>
      <Grid item xs={12}>
        <PaymentPlanSection
          dataKey={"switch_plan"}
          data={props.switchPlansData}
          dataLoading={props.switchPlansLoading}
          dataError={props.switchPlansLoadingError}
          expandedItems={props.expandedItems.switchPlansItems}
          getTradeOptions={getSwitchPlanTradeOptions}
          expanded={props.expandedItems.switchPlans}
          onExpandedChange={props.onExpandedItemsChange}
        />
      </Grid>
    </Grid>
  )
}

PaymentPlansTab.propTypes = {}

PaymentPlansTab.propDefs = {}

PaymentPlansTab.defaultProps = {}

export default PaymentPlansTab;