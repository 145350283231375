import { makeStyles } from '@material-ui/core';
import {fontSize, smFontSize, xsFontSize} from "../../utils/constants";

export default makeStyles((theme) => ({
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    color: '#202A38',
    margin: 0
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,

    '& button >span > span': {
      fontSize: 14
    }
  },
  mainContentContainer: {
    backgroundColor: 'white',
    minHeight: '100%',
    padding: '24px 0'
  },
  sectionHeader: {
    fontFamily:'Roboto-Bold',
    fontSize: 20,
    color: '#202A38',
    margin: 0,
    display: 'flex',
    alignItems: 'baseline'
  },
  instrumentsNumber: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16
  },
  tableCell: {
    width: 100,
    '&:first-child': {
      width: 350
    }
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& > h1': {
      fontFamily: 'Roboto-Bold',
      fontSize: '202px',
      color: '#80858C',
      margin: 0,

      [theme.breakpoints.down('sm')]: {
        fontSize: 122
      },
    },

    '& > p': {
      fontFamily: 'Roboto-Regular',
      fontSize: 40,
      margin: 0,
      color: '#202A38',
      marginTop: -40,

      [theme.breakpoints.down('sm')]: {
        fontSize: 25,
        marginTop: -20,
      },
    }
  },
  assetsDataErrorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > p': {
      fontFamily: 'Roboto-Regular',
      fontSize: 24,
      color: '#202A38'
    }
  },
  navigation: {
    '& h2': {
      fontFamily: 'Roboto-Bold',
      fontSize: 24,
      color: '#202A38',
      margin: '15px 0'
    }
  },
  whiteBox: {
    background: 'transparent linear-gradient(180deg, transparent 0%, transparent 54.5px, #FFF 54.5px, #FFF 100%) 0% 0% no-repeat padding-box'
  },
  link: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    lineHeight: '52px',

    '& > i': {
      fontSize: 12,
      marginRight: 5
    }
  },
  backwardArrow: {
    '&:before': {
      content: "''",
      display: 'inline-block',
      width: '0.6em',
      height: '0.6em',
      border: `2px solid ${theme.palette.primary.main}`,
      borderBottom: 'none',
      borderRight: 'none',
      transform: 'rotate(-45deg)',
      marginRight: '0.3em'
    }
  },
  tabContent: {
    minHeight: '100%',
    paddingTop: 16
  },
  tab: {
    paddingLeft: 30,
    paddingRight: 30,
    fontSize: fontSize,
    color: '#202A38',
    borderRight: '1px solid #D8DCDF',
    textTransform: 'none',
    background: 'transparent linear-gradient(180deg, #FFFFFF 0%, #F5F6F7 100%) 0% 0% no-repeat',
    opacity: 1,

    [theme.breakpoints.only("sm")]: {
      fontSize: smFontSize,
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: xsFontSize,
      paddingLeft: 10,
      paddingRight: 10,
    }
  },
  selected: {
    textShadow: '.5px 0 #202A38',
    background: '#ffffff',
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    bottom: 7,
    "& > div": {
      maxWidth: 30,
      width: "100%",
      backgroundColor: "#0092E5"
    }
  },
  tabsRoot: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    boxShadow: '0px 3px 15px #39435233',
    overflow: 'hidden',

    [theme.breakpoints.only("xs")]: {
      '& .MuiTabs-scrollButtonsDesktop': {
        display: 'inline-flex !important',
        width: 30
      }
    }
  },
  tabsHeader: {
    background: 'none',
    boxShadow: 'none'
  },
  loadingLine: {
    width: '30%',
    fontSize: 24,
    lineHeight: '1.2em',
    backgroundColor: '#d5d6d7',
    backgroundImage: 'linear-gradient(to right, #d5d6d7 0%, #b8bcbd 20%, #d5d6d7 40%, #d5d6d7 100%)',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: '$placeholderShimmer',
    animationTimingFunction: 'linear',

    '& p': {
      display: 'inline-block',
      margin: 0
    },

    '&:nth-child(2n)': {
      width: '75%'
    }
  },

  '@keyframes placeholderShimmer': {
    from: {
      backgroundPosition: '-468px 0',
    },
    to: {
      backgroundPosition: '468px 0',
    }
  },
  timeFrameContainer: {
    margin: '10px 0',
    padding: 8,
    minWidth: 270,
    [theme.breakpoints.only('sm')]: {
      minWidth: 250
    },
    [theme.breakpoints.only('xs')]: {
      minWidth: 'auto'
    },

    '& #date-picker-dropdown':{
      flexWrap: 'wrap'
    },

    '&:not(:last-of-type)':{
      marginRight: 15,
    },

    [theme.breakpoints.down(568)]: {
      width: '100%',
      maxWidth: '100%',
      margin: '0!important',
      '&.with-order-btn': {
      display: 'flex',
      flexDirection: 'column-reverse',
      }
    },

    ['@media (max-height:500px)']: {
      marginBottom: '0',
      marginTop: '0'
    }
  },
  settingsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    padding: '10px 20px 5px',
    width: 'fit-content',
    boxShadow: '0px 3px 15px #00000033',
    borderRadius: 5,

  }
}))