import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import clsx from 'clsx';

import Tooltip from '@material-ui/core/Tooltip';

import {Checkbox, makeStyles} from "@material-ui/core";
import {buildDeleteOrderConfirmationMessage, getInstrName} from "../../../../../Modelportfolios/utils";
import {openMorningStartIsin} from "../../../../../../utils/utils";
import {useTransactionsMonitoringContext} from "../../../../TransactionsMonitoring";
import {getIdentifierByTypeId} from "../../../../../../components/CustomersDataProvider/utils";
import {getTransactionDetails, isTransactionSelectable} from "../../../../utils";
import {toGermanFormat} from "../../../../../../utils/numberFormater";
import {connect} from "react-redux";
import ConfirmationDialog from "../../../../../VirtualPortfolioManager/modals/ConfirmationDialog";
import {BcaDeleteIconButton, BcaEditIconButton} from "../../../../../../components/Buttons/BcaIconButton/BcaIconButton";


const INITIAL_ORDER_ANNOTATION = 'Initialkauf bei Erstellung des Musterdepots'


export const showOrderDateNote = (order, trackingStartDate) => {

  if (order.annotation != INITIAL_ORDER_ANNOTATION || !trackingStartDate) {
    return false
  }

  const date = moment(order.execution_datetime).format('DD.MM.YYYY');

  return trackingStartDate && moment(trackingStartDate).format('DD.MM.YYYY') != date

}


const DateColumn = {
  header: 'BuchDat.',
  body: {
    content: (item, options) => {
      const date = moment(item.execution_datetime).format('DD.MM.YYYY')

      // options.trackingStartDate will be provided only for non-editable model portfolios
      let asterix = showOrderDateNote(item, options.trackingStartDate) ? '*' : null;

      return [date, asterix]
    }
  },
  key: 'DateColumn'
}

const useDatePointCellStyles = makeStyles((theme) => ({
  itemColor: {
    width: 24, height: 24,
    lineHeight: '21px',
    textAlign: 'center',
    borderRadius: '50%',
    backgroundColor: 'white',
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    borderStyle: 'solid'
  }
}))

const DatePointColumn = {
  header: '',
  body: {
    content: (item) => {

      const classes = useDatePointCellStyles();

      if (item.isSubItem) {
        return null
      }

      return (
        <div
          className={classes.itemColor}
          style={{fontSize: (item.execution_datetime_point > 99 ? 10 : 14)}}
        >
          {item.execution_datetime_point}
        </div>
      )
    }
  },
  key: 'DatePointColumn'
}

const CheckboxColumn = {
  header: {
    content: (item, options) => {
      if (options.viewOnly || !options.onAllSelect || !options.totalSelectableTransactionsCount) {
        return null;
      }

      return (
        <div>
          <Checkbox
            color="primary" style={{padding:0}}
            checked={!!(options.selected.length === options.totalSelectableTransactionsCount)}
            onChange={() => options.onAllSelect()}
          />
        </div>
      )
    }
  },
  body: {
    content: (item, options) => {

      if (!options.onSelect || item.isSubItem || options.viewOnly || !isTransactionSelectable(item)) {
        return null;
      }

      return (
        <div>
          <Checkbox
            onChange={() => options.onSelect(item)}
            color="primary"
            style={{padding:0}}
            checked={!!(options.selected && options.selected.find(transaction => transaction.id === item.id))}
          />
        </div>
      )
    }
  },
  key: 'DatePointColumn'
}

const wordBreakStyle = {
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  margin: 0,
  padding: 0
}

const useTransactionTypeColumnCellStyles = makeStyles((theme) => ({
  wordBreak: wordBreakStyle
}))

const TransactionTypeColumn = {
  header: "Transaktions-/ Buchungsart",
  body: {
    content: (item) => {

      const classes = useTransactionTypeColumnCellStyles();

      const context = useTransactionsMonitoringContext();
      const transactions = item.is_clearing_account ?
        context.bookingTypes || context.bookings :
        context.transactionTypes || context.transactions;

      const itemTransactionIdentifier = getIdentifierByTypeId(item.identifiers, 30);
      if (!itemTransactionIdentifier) {
        return '-'
      }

      const transactionType = _.find(transactions, (transaction) => transaction.id == itemTransactionIdentifier.code);
      const transactionName = transactionType ? transactionType.value : itemTransactionIdentifier.code;

      return (
        <div>
          <Tooltip title={transactionName} placeholder="bottom-start">
            <p className={classes.wordBreak}>
              {transactionName}
            </p>
          </Tooltip>
        </div>
      )
    }
  },
  key: 'TransactionTypeColumn'
}

const TransactionTypeColumnMinimized = {
  ...TransactionTypeColumn,
  header: "Transaktion",
}

const TransactionOrderTypeColumn = {
  header: 'Typ',
  body: {
    content: (item) => item.order_type,
  },
  key: 'TransactionOrderTypeColumn'
}

const TransactionCancelledColumn = {
  header: '',
  body: {
    content: (item) => {
      return !!item.is_cancelled && 'STORNO';
    }
  },
  key: 'TransactionCancelledColumn'
}

const useInstrumentNameCellStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: theme.palette.primary.main,
    marginBottom: 0,

    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

const InstrumentNameColumn = {
  header: "Produktname",
  body: {
    content: (item) => {

      if (item.is_clearing_account) {
        return 'KONTO';
      }

      const classes = useInstrumentNameCellStyles()

      const financialInformation = _.get(item, 'asset_details.financial_information.asset_info_data') || {}

      const itemName = getInstrName(financialInformation);

      const handleLinkClick = () => {
        openMorningStartIsin(financialInformation.isin)
      }

      return (
        <div style={{minWidth: 0}}>
          <p className={clsx(classes.title)} onClick={handleLinkClick}>
            { itemName }
          </p>
        </div>
      )
    }
  },
  key: 'InstrumentNameColumn'
}

const InstrumentISINColumn = {
  header: 'ISIN/WKN',
  body: {
    content: (item) => {

      if (item.is_clearing_account) {
        return null;
      }

      const financialInformation = _.get(item, 'asset_details.financial_information.asset_info_data') || {};
      return (
        <>
          {financialInformation.isin} / <span>{ financialInformation.wkn }</span>
        </>
      );
    }
  },
  key: 'InstrumentISINColumn'
}

const InstrumentKategorieColumn = {
  header: 'Morningstar-Kategorie',
  body: {
    content: (item) =>_.get(item, 'asset_details.financial_information.asset_info_data.category_de') || '',
  },
  key: 'InstrumentKategorieColumn'
}

const DepotCell = {
  header: "Depot-/ Konto-Nr.",
  body: {
    content: (item) => item.depot,
  },
  key: 'DepotCell'
}

const SubDepotCell = {
  header: "Unterdepot-Nr.",
  body: {
    content: (item) => item.sub_depot,
  },
  key: 'SubDepotCell'
}

const TransactionDetailsWrapper = ({item, children}) => {
  const details = getTransactionDetails(item)

  if (!details) {
    return '-'
  }

  return children(details)
}

const QuantityColumn = {
  header: 'Anteile',
  body: {
    content: (item) => {

      return (
        <TransactionDetailsWrapper item={item}>
          {(details) => details.execution_quantity ? toGermanFormat(details.execution_quantity, '', '', 4) : '-'}
        </TransactionDetailsWrapper>
      )
    }
  },
  key: 'QuantityColumn'
}

const getPriceInTradeCurrencyColumn = (header='Kurs (EUR)',suffix=` (EUR)`) => {
  return {
    header,
    body: {
      content: (item) => {
        return (
          <TransactionDetailsWrapper item={item}>
            {(details) => {
              let price = parseFloat(details.execution_context_unit_price);

              return price ? toGermanFormat(price, '', suffix) : '-'
            }}
          </TransactionDetailsWrapper>
        )
      }
    },
    key: 'PriceInTradeCurrencyColumn'
  }
}

const PriceInForeignCurrencyColumn = {
  header: 'Kurs (HW)',
  body: {
    content: (item) => {
      return (
        <TransactionDetailsWrapper item={item}>
          {(details) => {
            const execution_currency = details.execution_currency;
            const price = details.execution_unit_price;

            if (execution_currency == 'EUR') {
              return <>&nbsp;</>
            }

            return price ? toGermanFormat(price, '', ` (${execution_currency})`) : '-'
          }}
        </TransactionDetailsWrapper>
      )
    }
  },
  key: 'PriceInForeignCurrencyColumn'
}

const TotalAmountInTradeCurrencyColumn = {
  header: 'Bruttowert (HW)',
  body: {
    content: (item) => {
      return (
        <TransactionDetailsWrapper item={item}>
          {(details) => {
            const currency = details.requested_currency;
            return currency === 'EUR' ? <>&nbsp;</> : toGermanFormat(details.requested_value, '', ` (${currency})`)
          }}
        </TransactionDetailsWrapper>
      )
    }
  },
  key: 'TotalAmountInTradeCurrencyColumn'
};

const getEuroAmount = (details, suffix=' (EUR)') => {
  const execution_value = parseFloat(details.execution_context_value);

  return toGermanFormat(execution_value, '', suffix)
};

const getRequestedEuroAmount = (details, suffix=' (EUR)') => {
  const requested_value = parseFloat(details.requested_context_value);

  return toGermanFormat(requested_value, '', suffix)
};

const TotalAmountInEurColumn = {
  header: 'Bruttowert (EUR)',
  body: {
    content: (item) => {
      return (
        <TransactionDetailsWrapper item={item}>
          {(details) => getRequestedEuroAmount(details)}
        </TransactionDetailsWrapper>
      )
    }
  },
  key: 'TotalAmountInEurColumn'
};

const NettoAmountInEurColumn = {
  header: 'Nettowert (EUR)',
  body: {
    content: (item) => {
      return (
        <TransactionDetailsWrapper item={item}>
          {(details) => getEuroAmount(details)}
        </TransactionDetailsWrapper>
      )
    }
  },
  key: 'NettoAmountInEurColumn'
}

const TotalPriceInEurMinimisedColumn = {
  header: 'Nettobetrag',
  body: {
    content: (item) => {
      return (
        <TransactionDetailsWrapper item={item}>
          {(details) => {
            return getEuroAmount(details, ' €')
          }}
        </TransactionDetailsWrapper>
      )
    }
  },
  key: 'TotalPriceInEurMinimisedColumn'
}

const GrossColumn = {
  header: 'Bruttobetrag',
  body: {
    content: (item) => {
      return (
        <TransactionDetailsWrapper item={item}>
          {(details) => {
            return getRequestedEuroAmount(details, ' €')
          }}
        </TransactionDetailsWrapper>
      )
    }
  },
  key: 'GrossColumn'
}

const FxRateColumn = {
  header: 'Fx Kurs',
  body: {
    content: (item) => {
      return (
        <TransactionDetailsWrapper item={item}>
          {(details) => {
            let fx_rate = details.fx_rate_euro || details.fx_rate;

            return toGermanFormat(fx_rate, '', '', 4, true, 4)
          }}
        </TransactionDetailsWrapper>
      )
    }
  },
  key: 'FxRateColumn'
}

const useInfoCellStyles = makeStyles(theme => ({
  infoButton: {
    // '&:hover': {
    //   cursor: 'pointer'
    // }
  },
}))

const useDeleteCellStyles = makeStyles(theme => ({
  deleteButton: {
    fontFamily: 'Roboto-Bold',
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  linkButton: {
    fontFamily: 'Roboto-Bold',
    fontWeight: 'normal',
    color: theme.palette.primary.main,
    textTransform: 'none',
    padding: 0,
    minWidth: 'auto',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline'
    }
  },
}))

const TransactionOverviewActions = connect()(({transaction, onDeleteClick, onEditClick, editInProgress, deleteInProgress}) => {

  const handleDeleteClick = async () => {
    onDeleteClick && await onDeleteClick([transaction]);
  }

  const handleEditClick = async () => {
    onEditClick && await onEditClick(transaction);
  }

  return (
    <div>
      {onEditClick && (
        <BcaEditIconButton
          onClick={handleEditClick}
          disabled={deleteInProgress || editInProgress}
        />
      )}
      {onDeleteClick && (
        <>
          <BcaDeleteIconButton
            onClick={handleDeleteClick}
            disabled={deleteInProgress || editInProgress}
          />
        </>
      )}
    </div>
  )
})


const ActionsColumn = {
  header: '',
  body: {
    content: (item, options) => {

      if (options.viewOnly || item.isSubItem) {
        return null
      }

      return (
        <TransactionOverviewActions
          transaction={item}
          onDeleteDone={options.onDeleteDone}
          onDeleteClick={item.deletable && options.onDeleteClick}
          onEditClick={item.editable && options.onEditClick}
          deleteInProgress={options.deleteInProgress}
          editInProgress={options.editInProgress}
        />
      )
    }
  },
  key: 'ActionsColumn'
}

const NoteColumn = {
  header: 'Anmerkung',
  body: {
    content: (item) => {
      return (
        <TransactionDetailsWrapper item={item}>
          {(details) => {
            return item.annotation || null
          }}
        </TransactionDetailsWrapper>
      )
    }
  },
  key: 'NoteColumn'
}

function dateOrderingFunction(item) {
  return item.execution_datetime && moment(item.execution_datetime)
}

function transactionTypeOrderingFunction(item) {
  const itemTransactionIdentifier = getIdentifierByTypeId(item.identifiers, 30)
  if (!itemTransactionIdentifier) {
    return ''
  }

  return itemTransactionIdentifier.code
}

function instrumentNameOrderingFunction(item) {
  const financialInformation = _.get(item, 'asset_details.financial_information.asset_info_data') || {}

  const itemName = getInstrName(financialInformation);

  return itemName || ''
}

export default {
  default: [{
    content: [DateColumn],
    orderingFunction: dateOrderingFunction
  }, {
    content: [TransactionTypeColumn, TransactionOrderTypeColumn, TransactionCancelledColumn],
    orderingFunction: transactionTypeOrderingFunction
  }, {
    content: [InstrumentNameColumn, InstrumentISINColumn, InstrumentKategorieColumn],
    orderingFunction: instrumentNameOrderingFunction
  }, {
    content: [DepotCell, SubDepotCell],
    orderingFunction: (item) => item.depot,
    align: 'right',
  }, {
    content: [QuantityColumn],
    align: 'right'
  }, {
    content: [getPriceInTradeCurrencyColumn(), PriceInForeignCurrencyColumn, FxRateColumn],
    align: 'right'
  }, {
    content: [TotalAmountInEurColumn, TotalAmountInTradeCurrencyColumn, NettoAmountInEurColumn],
    align: 'right'
  }]
}

export const tableStructureMinimised = {
  default: [{
    content: [CheckboxColumn]
  }, {
    content: [DatePointColumn]
  },{
    content: [DateColumn],
    orderingFunction: dateOrderingFunction
  }, {
    content: [TransactionTypeColumnMinimized],
    orderingFunction: transactionTypeOrderingFunction
  }, {
    content: [InstrumentNameColumn, InstrumentISINColumn],
    orderingFunction: instrumentNameOrderingFunction
  }, {
    content: [QuantityColumn],
    align: 'right'
  }, {
    content: [getPriceInTradeCurrencyColumn('Kurs', ' €')],
    align: 'right'
  }, {
    content: [GrossColumn, TotalPriceInEurMinimisedColumn],
    align: 'right'
  }, {
    content: [NoteColumn]
  }, {
    content: [ActionsColumn]
  }]
}