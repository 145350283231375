import React from "react";
import moment from "moment";

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import styles from "../styles";
import InputFormElement, {CurrencyNumberFormat} from "../../../formElement/InputFormElement";
import SelectFormElement from "../../../formElement/SelectFormElement";
import CalendarFormElement from "../../../formElement/CalendarFormElement";
import CheckboxQuestion from "../../../question/CheckboxQuestion";
import RadioQuestion from '../../../question/RadioQuestion';
import {isRequiredQuestion} from "../../../../../../utils";
import InputQuestion from "../../../question/InputQuestion";
import DateQuestion from '../../../question/DateQuestion';

const ClientStep = props => {
  const {
    classes,
    onAnswerChange,
    questions: [
      dataType,
      dataTypeDate
    ],
  } = props;

  const handleAnswerChange = (question, answer) => {
    onAnswerChange(question, answer);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioQuestion
            question={dataType}
            onAnswerChange={handleAnswerChange}
            replaceComponents={{
              "data_type_date": (
                <DateQuestion
                  question={dataTypeDate}
                  onAnswerChange={handleAnswerChange}
                />
              )
            }}
          />
        </Grid>
       </Grid>
    </>
  );
};

export default withStyles(styles)(ClientStep);
