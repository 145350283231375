import React from 'react';
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import useStyles from './styles';
import {
  EXPAND_ALL_SECTIONS_CHECKBOX_CONFIGURATION,
  EXPAND_ALL_SUB_DEPOTS_CHECKBOX_CONFIGURATION
} from "./constants";
import {FieldTitle} from "../index";
import WarningTooltip from "../../../WarningTooltip";
import {useDownloadPdfDialogContext} from "../../DownloadPdfModal";


function Expanded({items}) {

  const classes = useStyles();

  const {state, handleStateValueChange} = useDownloadPdfDialogContext();

  const handleChange = (fieldName) => (event) => {
    handleStateValueChange(fieldName, event.target.checked);
  }

  return (
    <div className={classes.checkboxWrap}>
      <FieldTitle>
        Aufklappen
        <WarningTooltip
          title="Hier können alle zugeklappten Bereiche ausgewählt werden, um diese im PDF-Ausdruck ebenfalls zu berücksichtigen."
        />
      </FieldTitle>
      {items.map((item) => (
        <FormControlLabel
          classes={{
            label: classes.checkboxLabel,
            root: classes.checkboxFormControlLabel
          }}
          control={
            <Checkbox
              checked={state[item.name]}
              onChange={handleChange(item.name)}
              color='primary' />
          }
          label={item.title}
        />
      ))}
    </div>
  )
};

Expanded.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }))
};

Expanded.propDefs = {
  items: {
    description: 'List of checkboxes configurations.',
    name: {
      description: 'Checkbox field name.'
    },
    title: {
      description: 'Checkbox title.'
    }
  }
}

Expanded.defaultProps = {
  items: [
    EXPAND_ALL_SECTIONS_CHECKBOX_CONFIGURATION,
    EXPAND_ALL_SUB_DEPOTS_CHECKBOX_CONFIGURATION
  ]
};

export default Expanded;