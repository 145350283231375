/* React imports*/
import React from "react";
import styles from "./styles";
import clsx from 'clsx'

/* Material UI Components*/
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from '@material-ui/core/MenuItem';
import CrossIcon from '@material-ui/icons/HighlightOff';
import Slider from "@material-ui/core/Slider";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  TextField,
  withStyles
} from "@material-ui/core";


/* BCA Components */
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SuccessDialog from "../SuccessDialog";
import ErrorDialog from "../ErrorDialog";
import ConfirmationDialog from "../ConfirmationDialog";

/* BCA modules */
import {parseResponse, VirtualPortfolioHandlerResource} from "../../../../utils/api";


class BenchmarkSelector extends React.Component {

  render() {

    let selectedBenchmark = this.props.selectedBenchmark
    let benchmarks = this.props.benchmarks
    let allSelectedBenchmarksIds = this.props.allSelectedBenchmarks.map(benchmark => benchmark.benchmarkId)

    let menuItems = []

    if (benchmarks) {

      menuItems.push(
        <MenuItem
          key={null}
          value={null}
        >
          &nbsp;
        </MenuItem>
      )

      benchmarks.forEach(benchmark => {
        menuItems.push(
          <MenuItem
            key={benchmark.id}
            value={benchmark.id}
            disabled={(selectedBenchmark ? selectedBenchmark.benchmarkId !== benchmark.id : true) && allSelectedBenchmarksIds.indexOf(benchmark.id) !== -1}>
            {benchmark.name}
          </MenuItem>
        )
      })
    }

    return (
        <Select
          disableRipple={true}
          className={this.props.classes.benchmarkSelector}
          variant={'outlined'}
          value={selectedBenchmark ? selectedBenchmark.benchmarkId : null}
          onChange={(event) => {
            this.props.handleBenchmarkSelection(this.props.entryId, event.target.value)
          }}
        >
          {menuItems}
        </Select>
    )
  }
}

class BenchmarkSettingItem extends React.Component {
  render() {
    let classes = this.props.classes;
    let value = this.props.selectedBenchmark ? this.props.selectedBenchmark.weight : 0;
    return <React.Fragment>
      <div className={classes.formItem}>
        <BenchmarkSelector
          classes={this.props.classes}
          benchmarks={this.props.benchmarks}
          allSelectedBenchmarks={this.props.allSelectedBenchmarks}
          selectedBenchmark={this.props.selectedBenchmark}
          entryId={this.props.entryId}
          handleBenchmarkSelection={this.props.handleBenchmarkSelection}
        />
        {this.props.selectedBenchmark === undefined
          ? <span className={clsx(classes.benchmarkSlider, classes.totalLabel)}>
              Gesamt
            </span>
          : <Slider
              className={classes.benchmarkSlider}
              min={1}
              step={1}
              max={100}
              value={value}
              onChange={(event, value) => {
                this.props.handleWeightChange(this.props.entryId, value)
              }}
            />
        }

        {this.props.selectedBenchmark === undefined
          ? <span className={clsx(classes.totalValueLabel, this.props.totalWeight !== 100 ? classes.totalLabelError : classes.totalLabelCorrect)}>
              {this.props.totalWeight} %
            </span>
          : <TextField
            className={classes.benchmarkWeightField}
            variant={'outlined'}
            value={value}
            error={value <= 0 || value > 100}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end"> % </InputAdornment>
              )
            }}
            onChange={(event) => {
              this.props.handleWeightChange(this.props.entryId, event.target.value)
            }}
          />
        }

        {
          this.props.selectedBenchmark !== undefined &&
          <Button
            onClick={() => {this.props.handleSettingDeleting(this.props.entryId)}}
            className={classes.deleteButton}
          >
            <CrossIcon
              color={'primary'}
            />
          </Button>
        }
      </div>
    </React.Fragment>
  }
}

class BenchmarkSettingsDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      benchmarksData: undefined,
      benchmarksLoading: false,
      benchmarksError: undefined,
      userBenchmarkSettings: undefined,
      savedBenchmarkSettings: undefined,
      benchmarkSaveLoading: false,
      showSuccessSavingDialog: false,
      showFailedSavingDialog: false,
      savingError: undefined,
      benchmarksSettingsLoading: false,
      benchmarksSettingsError: undefined,
      showConfirmation: false,
      lastDeleted: false,
    }

    this.fetchBenchmarks = this.fetchBenchmarks.bind(this);
    this.handleBenchmarkSelection = this.handleBenchmarkSelection.bind(this);
    this.handleSettingDeleting = this.handleSettingDeleting.bind(this);
    this.handleWeightChange = this.handleWeightChange.bind(this);
    this.saveBenchmark = this.saveBenchmark.bind(this);
    this.closeFailedDeletingDialog = this.closeFailedDeletingDialog.bind(this);
    this.closeSuccessDeletingDialog = this.closeSuccessDeletingDialog.bind(this);
    this.closeBenchmarksSettingsLoadingFailedDialog = this.closeBenchmarksSettingsLoadingFailedDialog.bind(this);
    this.handleClosing = this.handleClosing.bind(this);
    this.closeConfirmation = this.closeConfirmation.bind(this);
    this.handleConfirmationOk = this.handleConfirmationOk.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props && this.props.open) {
      if (!this.state.benchmarksData) {
        this.fetchBenchmarks()
      } else if (this.props.open !== prevProps.open) {
        this.fetchBenchmarksSettings()
      }
    }
  }

  fetchBenchmarks() {
    this.setState({
      benchmarksLoading: true
    })
    VirtualPortfolioHandlerResource.getBenchmarks()
      .then(data => {

        parseResponse(data, 'benchmarks',
          (result) => {

            this.setState({
              benchmarksLoading: false,
              benchmarksError: undefined,
              benchmarksData: result
            }, () => this.fetchBenchmarksSettings())
          },
          (error) => {
            this.setState({
              benchmarksLoading: false,
              benchmarksData: undefined,
              benchmarksError: error,
            })
          })
      }).catch((error) => {
      this.setState({
        loadingBenchmarks: false,
        benchmarksData: undefined,
        benchmarksError: error
      })
    })
  }

  fetchBenchmarksSettings() {
    this.setState({
      benchmarksSettingsLoading: true
    })
    VirtualPortfolioHandlerResource.getBenchmarkSettings(this.props.customerId, this.props.portfolioId)
      .then(data => {
        parseResponse(data, 'benchmark_settings',
          (result) => {
            let data = Object.values(result).map((item, index) => ({
              benchmarkId: item.benchmark,
              weight: item.percentage,
              entryId: index
            }))

            this.setState({
              benchmarksSettingsLoading: false,
              userBenchmarkSettings: data.map(item => ({...item})),
              savedBenchmarkSettings: data.map(item => ({...item})),
              benchmarksSettingsError: undefined
            })
          },
          (error) => {
            this.handleBenchmarksSettingsError(error)
          })
      }).catch((error) => {
      this.handleBenchmarksSettingsError(error)
    })
  }

  handleBenchmarksSettingsError(error) {
    this.setState({
      benchmarksSettingsLoading: false,
      benchmarksSettingsError: error,
      userBenchmarkSettings: undefined
    })

  }

  saveBenchmark() {
    const {customerId, portfolioId} = this.props

    let dataToSave = this.state.userBenchmarkSettings.map(entry => ({
      benchmark: entry.benchmarkId,
      percentage: entry.weight
    }))

    this.setState({
      benchmarkSaveLoading: true
    })

    VirtualPortfolioHandlerResource.createBenchmarkSettings(customerId, {
      portfolio: portfolioId,
      benchmarks: dataToSave
    })
      .then((response) => {
        parseResponse(response, 'benchmark_settings', data => {
          this.handleSavedBenchmark(data['benchmarks'])
        }, error => {
          this.handleFailedSaving(error)
        })
      })
      .catch(error => {
        this.handleFailedSaving(error)
      })
  }

  handleSavedBenchmark(data) {

    let parsedData = data.map(item => ({
      benchmarkId: item.benchmark,
      weight: item.percentage
    }))

    this.setState({
      benchmarkSaveLoading: false,
      showSuccessSavingDialog: true,
      userBenchmarkSettings: parsedData,
      savedBenchmarkSettings: parsedData,
      lastDeleted: false,
    })

  }

  handleFailedSaving(error) {
    this.setState({
      benchmarkSaveLoading: false,
      showFailedSavingDialog: true,
      savingError: error
    })
  }

  closeSuccessDeletingDialog() {
    this.setState({
      showSuccessSavingDialog: false
    }, () => {
      this.props.onClose()
    })
  }

  closeFailedDeletingDialog() {
    this.setState({
      showFailedSavingDialog: false
    })
  }

  closeBenchmarksSettingsLoadingFailedDialog() {
    this.setState({
      benchmarksSettingsError: undefined
    })
    this.props.onClose()
  }

  handleBenchmarkSelection(entryId, benchmarkId) {

    let settings = this.state.userBenchmarkSettings ? this.state.userBenchmarkSettings.slice() : [];

    let settingUpdated = false;

    if (settings) {
      settings.forEach(setting => {
        if (setting.entryId === entryId) {
          setting.benchmarkId = benchmarkId;
          settingUpdated = true;
        }
      })
    }
    if (!settingUpdated) {
      settings.push({
        weight: 0,
        entryId,
        benchmarkId
      })
    }

    this.setState({
      userBenchmarkSettings: settings,
      lastDeleted: false
    })
  }

  handleSettingDeleting(entryId) {

    let settings = this.state.userBenchmarkSettings ? this.state.userBenchmarkSettings.slice() : [];

    settings = settings.filter(setting => setting.entryId !== entryId)

    if(!settings.length) this.setState({lastDeleted: true});

    this.setState({
      userBenchmarkSettings: settings
    })
  }

  handleWeightChange(entryId, weight) {
    let settings = this.state.userBenchmarkSettings ? this.state.userBenchmarkSettings.slice() : [];

    if (settings) {
      settings.forEach(setting => {
        if (setting.entryId === entryId) {
          setting.weight = weight;
        }
      })
    }

    this.setState({
      userBenchmarkSettings: settings
    })
  }

  renderForm() {
    let formItems = [];
    let allSelectedBenchmarks = [];

    if (this.state.userBenchmarkSettings) {
      allSelectedBenchmarks = this.state.userBenchmarkSettings
      this.state.userBenchmarkSettings.forEach((setting, index) => {
        formItems.push(
          <BenchmarkSettingItem
            key={index}
            entryId={setting.entryId || index}
            benchmarks={this.state.benchmarksData}
            allSelectedBenchmarks={allSelectedBenchmarks}
            selectedBenchmark={setting}
            classes={this.props.classes}
            handleBenchmarkSelection={this.handleBenchmarkSelection}
            handleSettingDeleting={this.handleSettingDeleting}
            handleWeightChange={this.handleWeightChange}
          />
        )
      })
    }

    const emptyItemId = (this.state.userBenchmarkSettings && this.state.userBenchmarkSettings.length > 0)
      ? Math.max(...this.state.userBenchmarkSettings.map(o => o.entryId)) + 1
      : 0;

    formItems.push(
      <BenchmarkSettingItem
        key={emptyItemId}
        entryId={emptyItemId}
        benchmarks={this.state.benchmarksData}
        allSelectedBenchmarks={allSelectedBenchmarks}
        selectedBenchmark={undefined}
        classes={this.props.classes}
        handleBenchmarkSelection={this.handleBenchmarkSelection}
        handleSettingDeleting={this.handleSettingDeleting}
        totalWeight={this.getTotalWeight()}
      />
    )

    return formItems;
  }

  isDataCorrect() {
    if(this.state.userBenchmarkSettings && this.state.userBenchmarkSettings.length > 0) {
      return this.getTotalWeight() === 100
          && this.state.userBenchmarkSettings.map(item => item.weight).indexOf(0) === -1
          && this.noNegatives();
    } else {
      return this.state.lastDeleted;
    }
  }

  getTotalWeight() {
    let totalPercentage = 0;

    if (this.state.userBenchmarkSettings) {

      this.state.userBenchmarkSettings.forEach((item => {
        totalPercentage += Number(item.weight);
      }))
    }

    return totalPercentage;
  }

  noNegatives() {
    for(let i=0; i < (this.state.userBenchmarkSettings || []).length; i++) {
      if(this.state.userBenchmarkSettings[i].weight < 0 || this.state.userBenchmarkSettings[i].weight > 100) return false;
    }
    return true;
  }

  closeConfirmation() {
    this.setState({
      showConfirmation: false
    })
  }

  handleConfirmationOk() {
    this.setState({
      showConfirmation: false
    },
      () => this.props.onClose()
    )
  }

  handleClosing() {
    if (JSON.stringify(this.state.userBenchmarkSettings) === JSON.stringify(this.state.savedBenchmarkSettings)) {
      this.props.onClose()

    } else {
      this.setState({
        showConfirmation: true
      })
    }
  }

  render() {

    const {classes} = this.props;

    return (
      <Dialog maxWidth='md'
              fullWidth
              open={this.props.open}
              onClose={this.handleClosing}
              scroll={'body'}>
        <IconButton aria-label="close" classes={{root: classes.closeButton}} onClick={this.handleClosing}>
          <CloseIcon/>
        </IconButton>
        <DialogTitle className={classes.title}>
          Benchmark einstellen
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <div className={classes.formItem}>
            <span className={clsx(classes.benchmarkSelector, classes.headingLabel)}>Benchmark</span>
            <span className={clsx(classes.benchmarkSlider, classes.headingLabel)}>Gewichtung</span>
          </div>
          {this.state.benchmarksData && this.renderForm()}
        </DialogContent>
        <DialogActions className={classes.footer}>
          <PrimaryButton
            text={'Speichern'}
            disabled={!this.isDataCorrect()}
            onButtonClick={this.saveBenchmark}
          />
        </DialogActions>
        {(this.state.benchmarksLoading || this.state.benchmarkSaveLoading || this.state.benchmarksSettingsLoading) &&
        <div className={classes.loadWrapper}>
          <CircularProgress color={'primary'}/>
        </div>
        }
        <SuccessDialog
          open={this.state.showSuccessSavingDialog}
          onClose={this.closeSuccessDeletingDialog}
          message={
            <p>Benchmark wurde erfolgreich gespeichert.</p>
          }
        />
        <ErrorDialog
          open={this.state.showFailedSavingDialog}
          onClose={this.closeFailedDeletingDialog}
          message={
            <p>Benchmark Einstellung fehlgeschlagen.</p>
          }
        />
        <ErrorDialog
          open={this.state.benchmarksSettingsError !== undefined}
          onClose={this.closeBenchmarksSettingsLoadingFailedDialog}
          message={
            <p>Benchmark settings loading was failed</p>
          }
        />
        <ConfirmationDialog
          message={
            <p>Benchmark Einstellungen wurden nicht gespeichert. Wollen Sie trotzdem fortfahren?</p>
          }
          open={this.state.showConfirmation}
          onClose={this.closeConfirmation}
          confirm={this.handleConfirmationOk}
        />
      </Dialog>
    )
  }
}

export default withStyles(styles)(BenchmarkSettingsDialog);
