import React from "react";
import { withStyles } from "@material-ui/core";
import styles, {useStyles} from "./styles";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import clsx from "clsx";
import {
  useAddProductsToComparisonContext
} from "../../../../components/SelectForProductsComparisonModal/SelectForProductsComparisonModal";
import {PRODUCT_TYPE} from "../../../ProductComparison/constants";


const AddToComparisonActions = ({isin}) => {

  const classes = useStyles();
  const {onAddProductsToComparison} = useAddProductsToComparisonContext();

  const handleActionClick = (isNewComparison) => () => {
    onAddProductsToComparison([{data: {isin}, productType: PRODUCT_TYPE.ASSET}], isNewComparison)
  }

  return (
    <>
      <div className={classes.dropdownItem} onClick={handleActionClick(false)}>
        <span> Zu Investmentvergleich hinzufügen </span>
      </div>
      <div className={classes.dropdownItem} onClick={handleActionClick(true)}>
        <span> Neuer Investmentvergleich starten </span>
      </div>
    </>
  )
}


class AddInstrumentButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null
    }
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.openFavorite = this.openFavorite.bind(this);
    this.createFavorite = this.createFavorite.bind(this);
    this.openModelportfolio = this.openModelportfolio.bind(this);
    this.createModelportfolio = this.createModelportfolio.bind(this);
  }

  open(e) {
    this.setState({open: !this.state.open, anchorEl: e.currentTarget});
  }

  openFavorite() {
    this.props.openFavorite()
  }

  createFavorite() {
    this.props.createFavorite()
  }

  openModelportfolio() {
    this.props.openModelportfolio()
  }

  createModelportfolio() {
    this.props.createModelportfolio()
  }

  close(e) {
    this.setState({open: false, anchorEl: null});
  }

  render() {
    const { classes } = this.props;

    return <>
      <div className={classes.dropdownMenuWrapper}>
        <IconButton onClick={this.open} color="primary" classes={{root: classes.button}}>
          <AddCircleOutlineIcon className={classes.innerIcon} />
        </IconButton>
        <Popover open={this.state.open} anchorEl={this.state.anchorEl}
                 onClose={this.close}
                 classes={{paper: classes.dropdownMenu}}
                 anchorOrigin={{vertical: 55, horizontal: 'right'}}
                 transformOrigin={{vertical: 'top', horizontal: 'right'}}
        >
          <div className={classes.dropdownItem} onClick={this.openFavorite}>
            <span> Zu Favoritenliste hinzufügen </span>
          </div>
          <div className={classes.dropdownItem} onClick={this.createFavorite}>
              <span> Neue Favoritenliste erstellen </span>
          </div>
          <div className={classes.dropdownItem} onClick={this.openModelportfolio}>
              <span> Zu Musterdepot hinzufügen </span>
          </div>
          <div className={classes.dropdownItem} onClick={this.createModelportfolio}>
             <span> Neues Musterdepot erstellen </span>
          </div>
          <AddToComparisonActions isin={this.props.isin}/>
        </Popover>
      </div>
    </>
  }
}

export default withStyles(styles)(AddInstrumentButton)