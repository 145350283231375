import React from 'react'
import ReactDOM from 'react-dom'


class Modal extends React.Component {
  // there is a Bug in FF that scrollbar overlay all fixed/absolute children
  // so as a workaround we place 2nd lvl menu outside
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.style.position = "relative"

    this.modalRoot = document.getElementById('side-menu-modal');

    if (this.el) {
      this.el.style.top = `${this.props.top}px`
    }
  }

  componentDidMount() {
    if (this.modalRoot) {
      this.modalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (this.modalRoot) {
      this.modalRoot.removeChild(this.el);
    }
  }

  render() {
    return !!this.props.isMobile ? this.props.children :
      ReactDOM.createPortal(
        this.props.children,
        this.el,
      );
  }
}

export default Modal