import React from 'react';
import clsx from 'clsx';

/* Material-UI Components */
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';

/* BCA Components */
import UserInfo from './components/UserInfo';
import InvestmentAmount from '../InvestmentDetails/components/InvestmentAmount';
import LongTermReturn from '../InvestmentDetails/components/LongTermReturn';
import TotalInvestedAmount from '../InvestmentDetails/components/TotalInvestedAmount';


/* BCA modules */
import useStyles from './styles';
import OtherAssets from "../InvestmentDetails/components/OtherAssets";
import {REPORT_TYPES} from "../../containers/DashboardSettings/components/CustomersList/components/ReportType/constants";
import TransactionSaldo from "../InvestmentDetails/components/TransactionSaldo/TransactionSaldo";
import NewVirtualPortfolioButton from "../../components/Buttons/NewVirtualPortfolioButton";
import {useAppContext} from "../../containers/App";
import withProfileDetailsData from "../ProfileDetailsDataProvider";


export default withProfileDetailsData((props) => {
  const classes = useStyles();

  const {
    customer,
    customerLoadingError,
    customerDataLoading,
    investmentData,
    investmentDataLoading,
    otherAssetsVisible,
    longTermReturnVisible,
    otherAssetsLink,
    dashboardLink,
    unrealizedProfitAndLossData,
    unrealizedProfitAndLossDataLoading,
    unrealizedProfitAndLossDataLoadingError,
    isVirtual,
    reportType,
    clickHandlerNewPortfolio,
    portfoliosDataLoading,
    totalInvestedAmountDisabled,
    withOtherAssets
  } = props;

  const { onCustomerChange } = useAppContext();

  React.useEffect(() => {
    onCustomerChange && onCustomerChange(customer)
  }, [customer])

  return (
    <Card id="client-details" className={classes.profileCard}>
      <CardContent className={classes.content}>
        <Grid container>
          <Grid
            item
            className={classes.item}
            id='kfo-user-info-tour-element'
          >
            {customer &&
              <UserInfo
                customer={customer}
                customerLoadingError={customerLoadingError}
                customerDataLoading={customerDataLoading}
              />
            }
          </Grid>

          <Grid
            item
            className={classes.item}
            id='kfo-invested-amount-tour-element'
          >
            <InvestmentAmount
              value={props.investmentAmountValue}
              loading={investmentDataLoading}
              withOpenTransactionValue
              openTransactionValue={props.openTransactionValue}
              openTransactionValueLoading={unrealizedProfitAndLossDataLoading}
              openTransactionValueError={unrealizedProfitAndLossDataLoadingError}
              isVirtual={isVirtual}
              sectionHeader={props.sectionHeader}
              sectionSubheader={props.sectionSubheader}
            />
          </Grid>
          {reportType == REPORT_TYPES.DEPOT_STATUS.value ? (
            <Grid
              item
              className={classes.item}
              id='kfo-transaction-saldo-tour-element'
            >
              <TransactionSaldo
                overallInvestedAmount={props.overallInvestedAmount}
                loading={investmentDataLoading}
              />
            </Grid>

          ) : (
            <>
              {longTermReturnVisible && (
                <Grid
                  item
                  className={classes.item}
                  id='kfo-long-term-return-tour-element'
                >
                  <LongTermReturn
                    data={investmentData}
                    loading={investmentDataLoading}
                    isVirtual={isVirtual}
                  />
                </Grid>
              )}
            </>
          )}
          {otherAssetsVisible && (
            <Grid
              item
              className={classes.item}
              id="kfo-long-term-return-tour-element"
            >
              <OtherAssets
                value={props.otherAssetsValue}
                loading={investmentDataLoading}
              />
            </Grid>
          )}
          { !isVirtual &&
            <Grid
              item
              className={classes.item}
              id='kfo-total-invested-amount-tour-element'
            >
              {!totalInvestedAmountDisabled &&
                <TotalInvestedAmount
                  value={props.totalInvestmentAmountValue}
                  loading={investmentDataLoading}
                  customerId={customer && customer.customer_id}
                  otherAssetsLink={withOtherAssets ? otherAssetsLink : undefined}
                  dashboardLink={dashboardLink}
                  isVirtual={isVirtual}
                />
              }
            </Grid>
          }
          {isVirtual &&
            <Grid item className={classes.item}>
             <NewVirtualPortfolioButton
                clickHandler={clickHandlerNewPortfolio}
                disabled={portfoliosDataLoading}
                useAsLink
              />
             </Grid>
          }
        </Grid>
      </CardContent>
    </Card>
  )
})