import React from 'react';
import useStyles from './styles';
import Dialog from "@material-ui/core/Dialog/Dialog";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import images, { SuccessIcon } from "../../images";

const SuccessDialog = (props) => {
  const classes = useStyles();

  const {
    open,
    message,
    onClose,
    disableEscapeKeyDown,
    maxWidth,
    contentCssClass
  } = props;

  return (
    <Dialog
      open={open}
      disableBackdropClick={false}
      disableEscapeKeyDown={disableEscapeKeyDown || false}
      onClose={onClose}
      classes={{
        paper: classes.dialogPaper
      }}
      maxWidth={maxWidth || 'sm'}
    >
      <div className={[classes.dialogContent, contentCssClass].join(' ')}>
        <div>
          <SuccessIcon />
        </div>
        <div className={classes.message}>
          {message}
        </div>
      </div>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Dialog>
  )
};

export default SuccessDialog;