const styles = theme => ({
  dialog: {
    maxWidth: 900
  },
  title: {
    textAlign: 'center',
    '& > h2': {
      fontFamily: 'Roboto-Bold !important'
    }
  },
  modalContent: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38',
    paddingBottom: 16,
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  labelsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: 35,
    marginBottom: 10
  },
  confirmationCheckbox: {
    fontFamily: 'Roboto-Regular'
  },
  buttonsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20
  },
  button: {
    textTransform: 'none',
    fontSize: 16,
    fontFamily: 'Roboto-Bold'
  },
  loadWrapper: {
    backgroundColor: 'white',
    opacity: 0.9,
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageContent: {
    '& .no-margin': {
      margin: 0
    }
  }
})

export default styles;