import React from 'react';

import {
  Grid
} from '@material-ui/core';

import useStyle from './styles';
import images from '../../../../images';

export default (props) => {
  const classes = useStyle();

  const {
    word,
    count,
    handleClearSearchClicked
  } = props;

  return (
    <Grid container>
      <Grid input='true' className={classes.container}>
        <div className={classes.topMessage}>
          <p>
            <span>Es wurde {count === 0 || !count ? 'kein' : count} Kunden mit dem Namen</span>
            <span className={classes.word}> “{word}”</span> gefunden.
          </p>
          <p className={classes.oneLineLink} onClick={handleClearSearchClicked}>
            <img src={images.close_icon} alt="Suche löschen"/>Suche löschen
          </p>
        </div>
        {(count === 0 || !count) && (
          <React.Fragment>
            <p style={{marginTop: 25}}>Vorschläge:</p>
            <div>
              <ul style={{margin: 0, padding: 0, marginLeft: 19}}>
                <li>Achten Sie darauf, dass alle Namen richtig geschrieben sind.</li>
                <li>Versuchen Sie nur nach dem Vor- oder Nachnamen zu suchen.</li>
              </ul>
            </div>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  )
}