import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import { Grid, IconButton } from '@material-ui/core';

import ChartSectionBordered
  from '../../../../../CustomerDashboardV2/components_v2/ChartSectionBordered/ChartSectionBordered';
import { withEuroOrDash } from '../../../../../../utils/utils';
import AggregatedPortfolioReturnSection
  from '../../../../../../components/PortfolioDashboard/AggregatedPortfolioReturnSection';
import { DATE_FORMAT } from '../../../../../../utils/constants';
import ReturnHeader
  from '../../../../../../components/PortfolioDashboard/AggregatedPortfolioReturnSection/components/ReturnHeader';

import useAggregatedReturnSectionStyles
  from '../../../../../../components/PortfolioDashboard/AggregatedPortfolioReturnSection/styles';
import { avgSRRI, maxSRRI } from '../../ModelPortfolioDetails/utils';
import {default as useDashboardStyles} from '../../../../../CustomerDashboardV2/tabs/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  MODEL_PORTFOLIO_INVESTMENT_GOAL,
  MODEL_PORTFOLIO_KNOWLEDGE,
  MODEL_PORTFOLIO_TIME_HORIZON
} from '../../../../constants';
import EditIcon from '../../../../../../images/EditIcon';


const OverviewTab = (props) => {
  const {
    investmentData,
    modelPortfolio,
    calculationDates: {
      start,
      end
    },
    onEditButtonClick,
    viewOnly,
  } = props;

  const dashboardClasses = useDashboardStyles();
  const returnClasses = useAggregatedReturnSectionStyles();

  const mpData = _.get(modelPortfolio, 'data') || {};
  const mpLoading = _.get(modelPortfolio, 'loading');
  const trackingStartDate = _.get(mpData, 'tracking_start_date');
  const assets = _.get(mpData, 'assets');
  const isInvestmentStrategy = !!props.investmentStrategyId;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ChartSectionBordered
          title={<b>Portfoliowert</b>}
          expanded
          subSection
          fullHeight
          content={
            <Grid container style={{height: '100%'}} alignContent={'flex-end'}>
              <Grid item xs={12} className={returnClasses.value}>
                {investmentData.loading ? <Skeleton width={"50%"} /> : withEuroOrDash(_.get(investmentData, 'data.aum.aum_value', 0))}
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ChartSectionBordered
          title={<b>Portfoliorisiko</b>}
          expanded
          subSection
          fullHeight
          content={
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div>Durchschnitts-SRI</div>
                <div className={dashboardClasses.hugeNumber}>
                  {mpLoading ? <Skeleton width={"70%"} /> : avgSRRI(assets)}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>Höchste SRI</div>
                <div className={dashboardClasses.hugeNumber}>
                  {mpLoading ? <Skeleton width={"70%"} /> : maxSRRI(assets)}
                </div>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ChartSectionBordered
          title={
            <ReturnHeader
              title={"Rendite Zeitraum"}
              subTitle={`${moment(start || trackingStartDate).format(DATE_FORMAT)} - ${moment(end).format(DATE_FORMAT)}`}
            />
          }
          expanded
          subSection
          fullHeight
          loading={investmentData.loading}
          error={investmentData.errors}
          displayError
          content={
            <AggregatedPortfolioReturnSection
              data={investmentData}
              dataKey={'ydt'}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ChartSectionBordered
          title={
            <ReturnHeader
              title={"Rendite seit Investmentbeginn"}
              subTitle={`seit ${moment(trackingStartDate).format(DATE_FORMAT)}`}
            />
          }
          expanded
          subSection
          fullHeight
          loading={investmentData.loading}
          error={investmentData.errors}
          displayError
          content={
            <AggregatedPortfolioReturnSection
              data={investmentData}
              dataKey={'ysb'}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <ChartSectionBordered
          title={
            <>
              <b>Profil für Kunden</b>
              {!viewOnly && _.isFunction(onEditButtonClick) && (
                <>
                  &nbsp;
                  <IconButton color={'primary'} onClick={onEditButtonClick}>
                    <EditIcon />
                  </IconButton>
                </>
              )}
            </>
          }
          expanded
          subSection
          fullHeight
          content={
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <div>Kenntnisse & Erfahrungen</div>
                <div className={dashboardClasses.hugeNumber}>
                  {mpLoading ? (
                    <Skeleton width={"70%"} />
                  ) : mpData ? (
                    (isInvestmentStrategy ? mpData.expertise : MODEL_PORTFOLIO_KNOWLEDGE[mpData.knowledge_and_experience]) || '-'
                  ) : '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div>Anlagehorizont</div>
                <div className={dashboardClasses.hugeNumber}>
                  {mpLoading ? (
                    <Skeleton width={"70%"} />
                  ) : mpData ? (
                    (isInvestmentStrategy ? mpData.investment_horizon : MODEL_PORTFOLIO_TIME_HORIZON[mpData.time_horizon]) || '-'
                  ) : '-'}
                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div>Anlageziel</div>
                <div className={dashboardClasses.hugeNumber}>
                  {mpLoading ? (
                    <Skeleton width={"70%"} />
                  ) : mpData ? (
                    (isInvestmentStrategy ? mpData.investment_goal : MODEL_PORTFOLIO_INVESTMENT_GOAL[mpData.investment_goal]) || '-'
                  ) : '-'}
                </div>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <ChartSectionBordered
          title={<b>Beschreibung</b>}
          expanded
          subSection
          fullHeight
          content={
            mpLoading ? (
              <>
                <Skeleton width={"100%"} />
                <Skeleton width={"100%"} />
                <Skeleton width={"100%"} />
              </>)
              : _.get(mpData, 'description')}
        />
      </Grid>
    </Grid>
  )
};

export default OverviewTab;