import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',

    '& > $header': {
      color: '#626970',
      fontSize: 14,
      fontFamily: "Roboto-Bold",
      margin: 0
    }
  },
  inputsWrapper: {
    marginBottom: '0 !important',
    alignItems: 'flex-end',

    '& .MuiGrid-item': {
      [theme.breakpoints.down('md')]: {
        padding: '0 5px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      }
    },
    '& .MuiGrid-item:first-child': {
      paddingLeft: 0
    },
    '& .MuiGrid-item:last-child': {
      paddingRight: 0
    }
  },
  inputContainer: {
    '& .MuiInputLabel-root': {
      fontFamily: 'Roboto-Bold',
      fontSize: 12,
      marginBottom: 0,
    },
    '& .fa': {
      color: '#ADB6BD',
      fontSize: '1.25rem',
      padding: '0 0.5rem',
    }
  },
  header: {},
  detailsFilters: {

    height: 'calc(100% - 56px)',

    [theme.breakpoints.down('md')]: {
      height: 'auto'
    }
  },
  inputRoot: {
    backgroundColor: 'white',
    marginBottom: '5px !important',
    height: '44px !important',
  },
  filtersAction: {
    backgroundColor: 'white',
    padding: '9px !important',
    marginBottom: '0px !important',
    borderRadius: '0 0 2px 2px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  detailsFiltersHolder: {
    height: 'calc(100% - 63px)',
    maxHeight: 'calc(100% - 63px)',

    [theme.breakpoints.down('md')]: {
      height: 'auto'
    }
  },
  filters: {
    marginTop: 10,
    width: '100%',
    backgroundColor: '#ABB6BE',
    borderRadius: 2,
    padding: 0,
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',

    '& > div > div': {
      width: '100%',
      marginBottom: 5,
      padding: '0 9px',
    },

    '& > div:first-child': {
    },
  },
  filtersTransparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none',

    '& > div': {
      margin: '5px 0',
      padding: '0 !important',

      '& > div': {
        padding: '0 !important'
      }
    },
    '@media (max-width: 1279px)': {
      flexWrap: 'nowrap',
      gap: '10px',
    },
  },
  clearFiltersButton: {
    textTransform: 'none',
    fontFamily: 'Roboto-Medium',
    fontSize: 16
  },
  floatFilterButton: {
    position: 'fixed',
    top: 80,
    left: 20
  }
}));
