import React from "react";
import {connect} from "react-redux";
import _ from "lodash";

import DashboardTable from "../../../../../../components/DashboardTable/DashboardTable";
import {getTableStructureRebalancing} from "../../../../../../components/Charts/InstrumentsAllocationTable/table-data";
import useStyles from './styles';
import {displayErrorSnackBar} from "../../../../../../components/SnackbarProvider/actions";
import {getErrorMessage} from "../../../../../../utils/utils";
import {ModelPortfolioResource} from "../../../../../../utils/api";
import {CircularProgress} from "@material-ui/core";
import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton";
import {DownloadIcon} from "../../../../../../images";


function Rebalancing(props) {
  const {
    instrumentsHandler,
    selectedAssets,
    onInstrumentSelect,
    onSelectAll,
    portfolioData,
    modelPortfolioData,
    portfolioInstruments,
    withSrri,
    clientSRRI,
    totalMarketValue
  } = props;

  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [dataSource, setDataSource] = React.useState([]);
  const modelPortfolioInstruments = modelPortfolioData && modelPortfolioData.assets || []

  React.useEffect(() => {
    setLoading(true);
    getData().then(data => setDataSource(data))
    .catch(error => {
      props.dispatch(displayErrorSnackBar(getErrorMessage(error)))
    })
    .finally(() => setLoading(false));
  }, []);

  const getAvailableAssets = () => {
    return dataSource.filter(asset => !asset.disabledByFilter && !!asset.weightDifference);
  };

  const _updateInstrument = (instrument, assetsInfo) => {
    const instrumentData = assetsInfo.find((e) => e.isin === instrument.isin) || {};
    const instrumentDataWeight = instrumentData.weight || 0;
    let weightDifference = instrumentDataWeight - instrument.pWeight;
    instrument.transactionType = weightDifference < 0 ? 'Verkauf' : 'Kauf';
    weightDifference = Math.abs(weightDifference);
    instrument.mpWeight = instrumentDataWeight;
    instrument.weightDifference = weightDifference;
    instrument.newMarketValue = _.round(weightDifference * totalMarketValue, 2);
  };

  const _updateDataSourceWithInputValue = (instrument, newWeight, newMarketValue) => {
    setDataSource((_dataSource) => {
      _dataSource.forEach((_instrument) => {
        if (_instrument.isin != instrument.isin) return;

        let value = newWeight || newMarketValue;

        if (newWeight) {
          _instrument.weightDifference = value;
          _instrument.newMarketValue = _.round(_instrument.weightDifference * totalMarketValue, 2);
        } else {
          _instrument.newMarketValue = value;
          _instrument.weightDifference = _instrument.newMarketValue / totalMarketValue;
        }

      });

      return [...dataSource]
    })
  };

  const onWeightDifferenceChange = (instrument, value) => {
    _updateDataSourceWithInputValue(instrument, value)
  };

  const onMarketValueChange = (instrument, value) => {
    _updateDataSourceWithInputValue(instrument, undefined, value)
  };


  const getData = async () => {
    const portfolioData = (portfolioInstruments || []).filter(i => !!i.isin);
    const portfolioIsins = new Set(portfolioInstruments.map(pi => pi.isin));
    portfolioData.forEach(inst => {
      inst.pWeight = inst.weight || 0
    });
    modelPortfolioInstruments.forEach(inst => {
      inst.pWeight = 0;
      inst.market_value = 0
    });

    // merge portfolio instruments with model portfolio instruments and clone all instruments as we will modify them
    const items = _.cloneDeep([
      ...portfolioData,
      ...modelPortfolioInstruments.filter(a => !portfolioIsins.has(a.isin))]);

    const amounts = {'Verkauf': 0, 'Kauf': 0};
    let firstBuyInstr;
    items.forEach(pInstrument => {
      _updateInstrument(pInstrument, modelPortfolioInstruments);
      amounts[pInstrument.transactionType] += pInstrument.newMarketValue;
      if(!firstBuyInstr && pInstrument.transactionType === 'Kauf'){
        firstBuyInstr = pInstrument;
      }
    });

    // diff(saldo) should be 0 - but due to rounding there might be 1 cent diff - so put it into first Buy
    const diff = _.round(amounts['Verkauf'] - amounts['Kauf'], 2);
    if(diff && firstBuyInstr){
      firstBuyInstr.newMarketValue += diff;
    }

    if(instrumentsHandler && instrumentsHandler.buy && instrumentsHandler.sell){
      const res = await ModelPortfolioResource.infoAssets(items.filter(i => i.isin).map(i => i.isin));

      const buy = [];
      const sell = [];
      items.forEach((item) => {
        // extend all instruments with asset info
        const instrumentData = res.find((e) => e.isin === item.isin) || {};
        delete instrumentData.category;

        Object.assign(item, {...instrumentData});

        // split instruments into sell and buy
        if (item.transactionType === 'Verkauf') {
          sell.push(item);
        } else if (item.transactionType === 'Kauf') {
          buy.push(item);
        }
      });

      // extend instruments with custodian data and validate
      await instrumentsHandler.buy.getCustodianAndFilterInstruments(buy);
      await instrumentsHandler.sell.getCustodianAndFilterInstruments(sell);
    }

    return _.orderBy(items, ['disabledByFilter', (item) => item.name && item.name.toLowerCase()], ['desc', 'asc']);
  };

  const handleAllSelected = () => {
    if (onSelectAll){
      const selected = selectedAssets || [];
      let assets = getAvailableAssets();
      if (selected.length === assets.length) {
        assets = []; // unselect all
      }

      onSelectAll(assets)
    }
  };

  if(loading) {
    return <div className={classes.loadingContainer}>
      <CircularProgress className={classes.loadingIndicator}/>
    </div>
  }

  return (
    <>
      <div className={classes.navigationContainer}>
        <div>
          <h1 className={classes.header}>Rebalancing</h1>
          <span className={classes.subTitle}>Um die Allokation in Ihrem Depot an das ausgewählte Musterdepot anzupassen, werden folgende Änderungen empfohlen</span>
        </div>
        <PrimaryButton
          text="Als PDF exportieren"
          icon={<DownloadIcon color="white"/>}
          onButtonClick={() => null}
          disabled
        />
      </div>
      <DashboardTable
        structure={getTableStructureRebalancing(withSrri)}
        dataSource={dataSource}
        expanded={true}
        tableClasses={classes}
        withFooter={false}
        options={{
          rebalancing: true,
          onAllSelect: handleAllSelected,
          totalInstrumentsCount: getAvailableAssets().length,
          onInstrumentSelect: onInstrumentSelect,
          selected: selectedAssets || [],
          portfolioData,
          modelPortfolioData,
          withSrri,
          clientSRRI,
          onWeightDifferenceChange,
          onMarketValueChange
        }}
      />
    </>
  )
}

export default connect()(Rebalancing);
