import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";

import useStyles from './styles';
import Switch from '../../../../components/AssetModal/components/Switch';
import {setBenchmarkConfiguration} from '../../../CustomerDashboard/actions';
import styles from '../../../CustomerDashboard/components/Widgets/components/PerformanceLineChart/styles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';


const SeriesBenchmarkConfiguration = (props) => {
  const {
    benchmarks,
    classes,
    dispatch,
    showInvestment,
    updateBenchmarkSwitchState,
    disabled
  } = props;
  const internal_classes = useStyles();

  const handleShowInvestmentChanged = (checked) => {
    const configuration_required = !(benchmarks && benchmarks.length); 

    if (checked && configuration_required) {
      openBenchmarkConfigurationModal(configuration_required, true);
    }
    updateBenchmarkSwitchState(checked);
  };

  const handleClickBenchmarkSettingsButton = () => {
    const configuration_required = false;
    openBenchmarkConfigurationModal(configuration_required, false);
  };

  const openBenchmarkConfigurationModal = (configuration_required, forceShow) => {
    return dispatch(setBenchmarkConfiguration(null, true, configuration_required, forceShow));
  };

  return (
    <div className={classes.benchmarkWrap}>
      <Grid container>
        <Grid item lg={5} md={5} sm={5} xs={12} className={classes.nonPadding}>
            
          <div id="performance-chart-benchmark-checkbox" className={clsx(classes.switchWrapper, internal_classes.switchWrapper)}>
            <div className={classes.benchmarkLabel}>
              <span>Benchmark</span>
            </div>
            <div>
              <Switch
                value={disabled ? false : showInvestment}
                handleValueChanged={handleShowInvestmentChanged}
                disabled={disabled}
              />
            </div>
          </div>

          <div
            id="benchmarks-configuration"
            className={clsx(classes.benchmarkSettingsButton, internal_classes.benchmarkConfigWrapper, disabled && classes.disabled)}
            onClick={handleClickBenchmarkSettingsButton}
          >
            <span>
                <i className="fa fa-cog" aria-hidden="true" />
            </span>
            <span>Konfigurieren</span>
          </div>
        </Grid>
      </Grid>  
    </div>
  );

};

export default withStyles(styles)(SeriesBenchmarkConfiguration);