import React from 'react';
import _ from 'lodash';

import BaseDialog from '../BaseDialog/BaseDialog';
import PrimaryButton from '../Buttons/PrimaryButton';
import useStyles from './styles';

export default function PromptDialog(props) {
  const isOpen = props.isOpen;
  const title = props.title;
  const message = props.message;
  const handleNoBtn = props.handleNoBtn;
  const handleYesBtn = props.handleYesBtn;
  const handleCancel = props.handleCancel;
  const okBtnLabel = props.okBtnLabel || 'Ok';
  const cancelBtnLabel = props.cancelBtnLabel || 'Cancel';

  const classes = useStyles();

  const [open, setOpen] = React.useState(isOpen);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  function handleClickCancel() {
    setOpen(false);
    handleNoBtn();
  }

  function handleClickOk() {
    setOpen(false);
    handleYesBtn();
  }

  function handleClose() {
    setOpen(false);
    handleCancel();
  }

  return (
    <BaseDialog
      title={title}
      open={open}
      handleClose={_.isFunction(handleCancel) ? handleClose : undefined}
      maxWidth={props.maxWidth || 'sm'}
      fullWidth={false}
      customClasses={{dialogContent: classes.dialogContent, ...props.customClasses}}
      actions={
        <>
          <PrimaryButton text={cancelBtnLabel} onButtonClick={handleClickCancel} variant={'outlined'}/>
          <PrimaryButton text={okBtnLabel} onButtonClick={handleClickOk}/>
        </>
      }
    >
      {message}
    </BaseDialog>
  );
}