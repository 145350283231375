import React, { forwardRef, useImperativeHandle } from 'react';
import { Grid, Slider } from "@material-ui/core";
import { CurrencyNumberFormat } from '../../../../../Modelportfolios/Filters/Filters';
import { useFiltersContext } from '../../Filters';
import useStyles from './styles';
import useCommonStyles from '../common_styles';
import clsx from 'clsx';
import { DropdownInput } from '../../../../../Modelportfolios/components';
import { createDefaultTheme } from '../../../../../../themes/themeConfigurator';
import _ from 'lodash';
import { Skeleton } from '@material-ui/lab';


const SliderRangeSelect = forwardRef(({name, loading, label, disabled, GridProps, max, noValidation, className}, ref) => {

  const classes = useStyles();
  const theme = createDefaultTheme();
  const commonClasses = useCommonStyles();

  const { filters, setFilterValue } = useFiltersContext();

  useImperativeHandle(ref, () => ({
    isValid: () => {
      return noValidation ? true : !!filters[name];
    }
  }));

  const [minValue, maxValue] = React.useMemo(() => {
    if (!filters[name]) {
      return [null, max]
    }

    return filters[name]
  }, [filters[name], max]);

  const handleSliderChange = (event, newValue) => {
    setFilterValue(name, newValue);
  };

  const handleInputChange = (value = 0, index) => {
    if (index === 0 && !!maxValue && value > maxValue) {
      value = maxValue;
    }

    if (index === 1 && !!minValue && value < minValue) {
      value = minValue;
    }

    if (!!max && value > max) {
      value = max
    }

    const _values = _.cloneDeep([minValue, maxValue]);
    _values[index] = value;
    setFilterValue(name, _values);
  };

  const inputStyles = {
    textAlign: "right",
    marginBottom: 0,
  }

  const currencyIcon = (
    <span style={{ fontFamily: "Roboto-Bold", fontSize: 14 }}>
      €
    </span>
  )

  return (
    <Grid item {...GridProps} className={clsx(classes.grid, className)}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={4} md={3} className={commonClasses.formControl}>
          <p className={clsx(commonClasses.inputLabel, disabled && commonClasses.inputLabelDisabled)}>{label}</p>
          {loading ? (
            <Skeleton className={commonClasses.skeleton}/>
          ) : (
            <DropdownInput
              icon={currencyIcon}
              iconPosition="end"
              labelStyle={{ fontFamily: "Roboto-Medium" }}
              labelPosition="right"
              value={minValue}
              height={45}
              onChange={(event) => handleInputChange(event, 0)}
              fullWidth={true}
              inputStyle={inputStyles}
              inputComponent={CurrencyNumberFormat}
              disabled={loading}
              showErrorAsTooltip={true}
            />
          )}
        </Grid>
        <Grid item xs={4} md={6} className={commonClasses.formControl}>
          <Slider
            disabled={loading}
            max={max}
            htmlColor={theme.palette.primary.main}
            classes={{ root: classes.slider }}
            value={[minValue, maxValue]}
            onChange={handleSliderChange}
            onChangeCommitted={(_, value) => setFilterValue(name, value)}
          />
        </Grid>
        <Grid item xs={4} md={3} className={commonClasses.formControl}>
          {loading ? (
            <Skeleton className={commonClasses.skeleton}/>
          ) : (
            <DropdownInput
              icon={currencyIcon}
              iconPosition="end"
              labelStyle={{ fontFamily: "Roboto-Medium" }}
              labelPosition="right"
              value={maxValue}
              height={45}
              onChange={(event) => handleInputChange(event, 1)}
              max={max}
              fullWidth={true}
              inputStyle={inputStyles}
              inputComponent={CurrencyNumberFormat}
              disabled={loading}
              showErrorAsTooltip={true}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
});

SliderRangeSelect.defaultProps = {
  noValidation: false,
};

export default SliderRangeSelect