import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: 'space-between'
  },
  sectionHeader: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#4D4F5C',

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  sectionConclusion: {
    margin: '10px 0',
    padding: '10px 0',
    borderTop: '1px solid #E1E3E9'
  },
  totalWeight: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: 'rgb(94, 193, 78)',

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  warnTotal: {
    color: 'rgb(255, 0, 0)'
  },
  benchmarksContainer: {
    '& > div': {
      marginTop: 10
    }
  },
  section: {
    '&:nth-child(n+2)': {
    }
  },
  sectionDisabled: {
    filter: 'grayscale(1)',
    pointerEvents: 'none',
    cursor: 'initial'
  },
  modalPaper: {
    overflow: 'visible !important'
  },
  acceptButton: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    borderRadius: 2,
    boxShadow: 'none',
    textTransform: 'none',
    width: 110,
    height: 40,

    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      height: 'auto'
    }
  },
  dialogTitleRoot: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px'
    },
    '& > h2': {
      [theme.breakpoints.only('sm')]: {
        fontSize: 18
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: 16
      },
    },
  },
  dialogContentRoot: {
    overflow: 'visible',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px'
    }
  },
  benchmarkSelector: {
    display: 'flex',
    alignItems: 'center'
  },
  deleteBenchmark: {
    marginLeft: 8,
    marginRight: 14,
    color: theme.palette.primary.main,
    width: 22,
    height: 22,
    cursor: 'pointer'
  }
}));