import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  helpText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    color: '#80858C'
  },
  tooltip: {
    fontSize: 12,
  },
  footer: {
    backgroundColor: '#D8DCDF',
    fontFamily: "Roboto-Bold",
    fontSize: 16,
    padding: '10px 16px',
    '& div:last-child': {
      marginLeft: 'auto',
    }
  }
});

export default makeStyles(styles)