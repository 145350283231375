import {ALL_ID, default as BaseModelportfolioList} from "../../../Modelportfolios/List/BaseList";

export default class BaseList extends BaseModelportfolioList {
  constructor(props) {
    super(props);

    this.TABS.map(tab => tab.selected = tab.id === ALL_ID)
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    this.TABS.map(tab => tab.name = tab.label);

    return null;
  }
}

