import React from "react";
import {Grid, Typography, withStyles} from "@material-ui/core";
import styles from "./styles";
import SDGCards from "./cards/index";
import {
  SDGFirst,
  SDGSecond,
  SDGThird,
  SDGFourth,
} from "../../../../../../images";
import One from "./images/1.png";
import Two from "./images/2.png";
import Three from "./images/3.png";
import Four from "./images/4.png";
import Five from "./images/5.png";
import Six from "./images/6.png";
import Seven from "./images/7.png";
import Eight from "./images/8.png";
import Nine from "./images/9.png";
import Ten from "./images/10.png";
import Eleven from "./images/11.png";
import Twelve from "./images/12.png";
import Thirteen from "./images/13.png";
import Fourteen from "./images/14.png";
import Fifteen from "./images/15.png";
import WarningTooltip from "../../../../../../components/WarningTooltip";
import LoadingIndicator from "../../../../../../components/LoadingIndicator/LoadingIndicator";

class SDG extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes, loading, assetName } = this.props;
    const data = [
      {
        text: "Sicherung der Grundbedürfnisse",
        info: "Leisten einen Beitrag gegen Armut und Hunger, für gute Gesundheitsversorgung und Wohlbefinden sowie für sauberes Wasser und gute Hygiene.",
        value: 1,
        uid: 1,
        criterias: {
          sdg_1: One, // TODO: Has no value
          sdg_2: Two,
          sdg_3: Three,
          sdg_6: Six
        },
        icon: <SDGFirst />,
      },
      {
        text: "Menschliche Entwicklung und Wohlstand",
        info: "Leisten einen Beitrag für qualitativ gute Ausbildung, Geschlechtergleichheit, für gerechte Arbeitsverhältnisse und ökonomisches Wachstum, für gute Industrie, Innovation und Infrastruktur, für reduzierte Ungleichheit sowie für bezahlbare und saubere Energie.",
        value: 2,
        uid: 2,
        criterias: {
          sdg_4: Four,
          sdg_5: Five, // TODO: Has no value
          sdg_7: Seven,
          sdg_8: Eight, // TODO: Has no value
          sdg_9: Nine,
          sdg_10: Ten,
          sdg_11: Eleven
        },
        icon: <SDGSecond />,
      },
      {
        text: "Klimaschutz",
        info: "Leisten einen Beitrag zur Begrenzung des Ausstosses schädlicher Klimagase.",
        value: 3,
        uid: 3,
        criterias: {
          sdg_13: Thirteen
        },
        icon: <SDGThird />,
      },
      {
        text: "Intakte Ökosysteme",
        info: "Leisten einen Betrag zum Erhalt und dem Schutz des Lebens der Meere, sowie dem Erhalt und Schutz von Flora, Fauna und der Tierwelt auf dem Lande.",
        value: 4,
        uid: 4,
        criterias: {
          sdg_12: Twelve,
          sdg_14: Fourteen,
          sdg_15: Fifteen
        },
        icon: <SDGFourth />,
      },
    ];
    return (
      <div className={classes.card}>
        <div className={classes.titleWrapper}>
          {this.props.title && (
            <Typography variant="h2" className={classes.title}>
              {this.props.title}
            </Typography>
          )}
          {this.props.tooltip && (
            <WarningTooltip title={this.props.tooltip} iconClassName={classes.helpIcon}/>
          )}
        </div>
        <div className={classes.cardsWrapper}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <Grid container spacing={3} className={classes.container}>
              {data &&
                data.length > 0 &&
                data.map((el) => (
                  <Grid item xs={3} key={el.uid}>
                    <SDGCards items={el} data={this.props.data} assetName={assetName} />
                  </Grid>
                ))}
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SDG);
