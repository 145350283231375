import React from "react";
import { withStyles } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import SvgIcon from "@material-ui/core/SvgIcon";
import { createStyles } from '@material-ui/core/styles';

import { FactSheetsHandlerResource } from "../../../../../../utils/api";
import { processResponse } from "../../../../index";
import CommonAnalysisCard from "../../../../../VirtualPortfolioPage/components/AnalysisCard";
import styles from "./styles";
import {displayErrorSnackBar} from "../../../../../../components/SnackbarProvider/actions";
import {connect} from "react-redux";


const iconStyles = createStyles({
  icon: {
    marginRight: 8,
    color: '#0092E5',
    fontSize: 14,
  },
})

const DownloadIcon = withStyles(iconStyles)(({ classes }) => (
  <SvgIcon classes={{ root: classes.icon }} width="20" height="20" viewBox="0 0 20 20">
    <path 
      d="M-1000,758.222c0,.978-.68,1.778-2.111,1.778h-16.111a1.778,1.778,0,0,1-1.778-1.778v-6.444a1.782,1.782,0,0,1,1.889-1.778h1.118a.994.994,0,0,1,.993.993v.014a.994.994,0,0,1-.993.993h-.014a.994.994,0,0,0-.993.993v4.014a.994.994,0,0,0,.993.993h14.014a.994.994,0,0,0,.993-.993v-4.014a.994.994,0,0,0-.993-.993h-.014a.994.994,0,0,1-.993-.993v-.014a.994.994,0,0,1,.993-.993h1.229a1.778,1.778,0,0,1,1.778,1.778v6.444Zm-5.726-11.549,0,0a.994.994,0,0,0-1.408-.05l-1.021.957a.5.5,0,0,1-.842-.365V741a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v6.2a.5.5,0,0,1-.845.362l-.974-.929a.993.993,0,0,0-1.41.04h0a.993.993,0,0,0,.043,1.4l2.848,2.689a2,2,0,0,0,2.753-.007l2.816-2.685A1,1,0,0,0-1005.726,746.673Z"
      transform="translate(1020 -740)"
    />
  </SvgIcon>
));

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
  auth: state.get('auth').toJS(),
});

class DocumentsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataProvider: FactSheetsHandlerResource,

      documentsProspectusData: undefined,
      documentsProspectusError: undefined,
      documentsProspectusLoading: true,

      documentsKiidData: undefined,
      documentsKiidError: undefined,
      documentsKiidLoading: true,

      documentsAnnualReportData: undefined,
      documentsAnnualReportError: undefined,
      documentsAnnualReportLoading: true,

      documentsSemiAnnualReportData: undefined,
      documentsSemiAnnualReportError: undefined,
      documentsSemiAnnualReportLoading: true,

      documentsMsFactsheetsData: undefined,
      documentsMsFactsheetsError: undefined,
      documentsMsFactsheetsLoading: true,
    };

    this.docNames = {
      prospectus: 'Verkaufsprospekt',
      priip_kid: 'PRIIP BIB',
      annualreport: 'Jahresbericht',
      semiannualreport: 'Halbjahresbericht',
      factsheet: 'Original Fondsinformation',
    };

    this.fetchDocumentsData = this.fetchDocumentsData.bind(this);
  }

  componentDidMount() {
    this.fetchDocumentsData()
  }

  fetchDocumentsData() {
    processResponse(
      this.state.dataProvider.getDocumentsData(this.props.assetInfo.isin, 'priip_kid'),
      'documents', 'documentsKiid', this,
    )
    processResponse(
      this.state.dataProvider.getDocumentsData(this.props.assetInfo.isin, 'prospectus'),
      'documents', 'documentsProspectus', this,
    )
    processResponse(
      this.state.dataProvider.getDocumentsData(this.props.assetInfo.isin, 'annualreport'),
      'documents', 'documentsAnnualReport', this,
    )
    processResponse(
      this.state.dataProvider.getDocumentsData(this.props.assetInfo.isin, 'semiannualreport'),
      'documents', 'documentsSemiAnnualReport', this,
    )
    if(this.props.settings.allowMsFactsheetDocument)
      processResponse(
        this.state.dataProvider.getDocumentsData(this.props.assetInfo.isin, 'factsheet'),
        'documents', 'documentsMsFactsheets', this,
      )
  }

  setItemProcessing(requestedItem, processing, data) {
    const documentsData = data.map((item) => {
      if (requestedItem === item) {
        item.processing = processing;
      }

      return item;
    });

    this.setState({ documentsData });
  }

  downloadBase64File(contentType, base64Data, fileName) {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  downloadPdf(item, dataArray) {
    this.setItemProcessing(item, true, dataArray);

    this.state.dataProvider.getDocumentsData(this.props.assetInfo.isin, item.type, item.lang, item.market).then((data) => {
      let processedData = data['documents'];

      if (!processedData['error']) {
        this.downloadBase64File('application/pdf', processedData['data'], this.renderDocName(item) + '.pdf')
      } else this.props.dispatch(displayErrorSnackBar('Fehler beim Herunterladen des Dokuments.'))
      this.setItemProcessing(item, false, dataArray);

    }).catch((error) => {
        this.props.dispatch(displayErrorSnackBar('Fehler beim Herunterladen des Dokuments.'))
        this.setItemProcessing(item, false, dataArray);
    })
  }

  renderLink(item, data) {
    const { classes } = this.props;

    return (
      <span className={classes.textLink} onClick={this.downloadPdf.bind(this, item, data)}>
        {item.processing
          ? (
            <div className={classes.linearProgress}>
              <LinearProgress />
            </div>
          )
          : (
            <>
              <DownloadIcon />
              Dokument herunterladen
            </>
          )
        }
      </span>
    );
  }
  
  renderHeadline() {
    const { classes } = this.props;

    return (
      <li className={clsx([classes.item, classes.headline])}>
        <span className={classes.title}>Art</span>
        <span className={clsx([classes.title, classes.textToRight])}>Stand</span>
        <span className={classes.title}></span>
      </li>
    )
  }

  renderDocName(item) {
    return this.docNames[item.type];
  }

  renderDocLines(type, loading, error, data) {
    const { classes } = this.props;

    return (loading ? <li className={classes.item}><CircularProgress /></li> : error ?
      <li className={classes.item}>{'Fehler. Der Download Link konnte nicht erzeugt werden.'}</li> : data ?
      data.map((item, index) => (
        <li className={classes.item} key={index}>
          <span className={classes.title}>{this.renderDocName(item)}</span>
          <span className={classes.values}>{moment(item.date).format('DD.MM.YYYY')}</span>
          <span className={classes.values}>{this.renderLink(item, data)}</span>
        </li>
      ))
      : '')
  }

  renderData() {
    const { classes } = this.props;

    return (
      <ul className={classes.list}>
        {this.renderHeadline()}
        {this.renderDocLines('kiid',
            this.state.documentsKiidLoading, this.state.documentsKiidError, this.state.documentsKiidData
        )}
        {this.renderDocLines('prospectus',
            this.state.documentsProspectusLoading, this.state.documentsProspectusError, this.state.documentsProspectusData
        )}
        {this.renderDocLines('annualreport',
            this.state.documentsAnnualReportLoading, this.state.documentsAnnualReportError, this.state.documentsAnnualReportData
        )}
        {this.renderDocLines('semiannualreport',
            this.state.documentsSemiAnnualReportLoading, this.state.documentsSemiAnnualReportError, this.state.documentsSemiAnnualReportData
        )}
        {this.props.settings.allowMsFactsheetDocument && this.renderDocLines('semiannualreport',
            this.state.documentsMsFactsheetsLoading, this.state.documentsMsFactsheetsError, this.state.documentsMsFactsheetsData
        )}
      </ul>
    )
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
      classes={{
        card: classes.root
      }}
      title={this.props.title}
      subtitle={this.props.subtitle}
      loading={this.props.documentsLoading}
      content={
        <div className={classes.container}>
          {this.renderData()}
        </div>
      }
    />
  }
}

export default connect(mapStateToProps)(withStyles(styles)(DocumentsTable))
