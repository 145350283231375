import {makeStyles} from "@material-ui/core";
import {fontSize, smFontSize, xsFontSize} from "../../utils/constants";

export default makeStyles((theme) => ({
  text: {
    fontSize: fontSize,

    [theme.breakpoints.only('sm')]: {
      fontSize: smFontSize,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: xsFontSize,
    },
  },
}))