import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';

import theme from '../../../../themes/mainTheme';
import { AddIcon, InfoIcon } from '../../../../images';
import { paginateArray } from '../../../../utils/utils';
import Pagination from '../../../../components/Pagination/Pagination';
import { FilteringAcceptanceCriteriasModal } from '../index';
import { getInstrName } from '../../utils';
import useStyles from './styles';

function InstrumentItem({instrument, disabled, alreadySelected, onAddAssetClick, pattern}) {
  const classes = useStyles()
  const [acceptanceVisible, setAcceptanceVisibility] = React.useState(false)

  const instrumentName = getInstrName(instrument);

  const handleAddAssetClick = () => {
    if (onAddAssetClick) {
      onAddAssetClick(instrument)
    }
  }

  const handleShowAcceptanceCriteriasClick = () => {
    setAcceptanceVisibility(true)
  }

  const handleCloseAcceptanceCriterias = () => {
    setAcceptanceVisibility(false)
  }

  const acceptanceCriteriasExists = () => {
    return instrument.hasOwnProperty('disabledByAcceptanceCriterias') && !_.isEmpty(instrument.disabledByAcceptanceCriterias)
  }

  const renderInstrumentName = (name, isin, wkn) => {
    let fullName = name;
    if (isin || wkn) {
      let parts = [];
      if (isin) parts.push(isin)
      if (wkn) parts.push(wkn)
      fullName = `${fullName ? `${fullName} ` : ''}( ${parts.join(', ')} )`
    }

    if (pattern) {
      pattern = pattern.trim();
      if (fullName && fullName.toLowerCase().includes(pattern.toLowerCase())) {
        let patternRegEx = new RegExp(pattern, 'gi');
        return `${fullName.replace(patternRegEx, match => `<b>${match}</b>`)}`
      }

      return fullName
    }

    return fullName;
  }

  return (
    <div className={classes.instrumentItemContainer} key={instrument.id}>
      <p className={clsx(classes.instrumentItemName, alreadySelected || disabled ? classes.instrumentItemNameDisabled : '')} dangerouslySetInnerHTML={{__html: renderInstrumentName(instrumentName, instrument.isin, instrument.wkn)}}></p>
      {!alreadySelected ? (
        <>
          {!disabled ? (
            <span>
                <AddIcon color={theme.palette.primary.main} onClick={handleAddAssetClick} size={18}/>
              </span>
          ) : (
            <>
              {acceptanceCriteriasExists() && (
                <span>
                  <InfoIcon color="#80858c" onClick={handleShowAcceptanceCriteriasClick}/>
                </span>
              )}
            </>
          )}
        </>
      ) : (
        <span className={classes.disabledLabel}>hinzugefügt</span>
      )}
      { acceptanceCriteriasExists() && (
        <FilteringAcceptanceCriteriasModal
          open={acceptanceVisible}
          onClose={handleCloseAcceptanceCriterias}
          instrumentName={instrumentName || instrument.isin}
          criterias={instrument.disabledByAcceptanceCriterias} />
      ) }
    </div>
  )
}

function InstrumentsFilterList(props) {

  const {
    instruments,
    pattern,
    loading,
    onAddAssetClick,
    instrumentsDisabled,
  } = props;

  const classes = useStyles();

  const [pages, setPages] = React.useState([])
  const [currentPage, setCurrentPage] = React.useState(0)

  React.useEffect(() => {
    if (instruments) {

      let instrumentsPrepared = render()
      setPages([...paginateArray(instrumentsPrepared, 100)])
    }
  }, [instruments, instrumentsDisabled]);  // re render list if instruments or disabled items changed

  React.useEffect(() => {
    setCurrentPage(0); // clean current page to initial
  }, [pattern]); // clean page if search pattern changed

  const renderInstrumentName = (name, isin, wkn) => {

    let fullName = name;
    if (isin || wkn) {
      let parts = [];
      if (isin) parts.push(isin)
      if (wkn) parts.push(wkn)
      fullName = `${fullName ? `${fullName} ` : ''}( ${parts.join(', ')} )`
    }

    if (pattern) {

      if (fullName && fullName.toLowerCase().includes(pattern.toLowerCase())) {
        let patternRegEx = new RegExp(pattern, 'gi');
        return `${fullName.replace(patternRegEx, match => `<b>${match}</b>`)}`
      }

      return fullName
    }

    return fullName;
  }

  const renderLoadingIndicator = () => [...Array(4)].map(() => (
    <div className={classes.loadingIndicatorContainerItem}>
      <Skeleton />
    </div>
  ))

  const handleAddAssetClick = (asset) => {
    if (onAddAssetClick) {
      onAddAssetClick(asset)
    }
  }

  const isInstrumentDisabled = (instrument) => {
    return instrumentsDisabled && !!_.find(instrumentsDisabled, i => i.isin == instrument.isin)
  }

  const renderInstrument = (instrument, disabled=false) => {

    return (
      <InstrumentItem
        instrument={instrument}
        disabled={disabled || props.disabled}
        pattern={pattern}
        alreadySelected={isInstrumentDisabled(instrument)}
        onAddAssetClick={handleAddAssetClick}
      />
    )
  }

  const renderInstruments = (instruments, disabled=false) => {
    return instruments.map(instrument => renderInstrument(instrument, disabled))
  }


  const render = () => {

    if (_.isArray(instruments)) {
      return renderInstruments(instruments)
    }

    let instrumentsToAccept = []
    let instrumentsToExclude = []

    if (instruments.hasOwnProperty('accepted')) {
      instrumentsToAccept = instruments.accepted
    }

    if (instruments.hasOwnProperty('excluded')) {
      instrumentsToExclude = instruments.excluded
    }

    instrumentsToAccept = renderInstruments(instrumentsToAccept)
    instrumentsToExclude = renderInstruments(instrumentsToExclude, true)

    return [...instrumentsToAccept, ...instrumentsToExclude]
  }

  const renderInstrumentsList = () => {
    if (pages && pages.length && currentPage <= pages.length - 1) {
      return pages[currentPage]
    }
  }

  return (
    <div className={classes.container}>
      {loading ? (
        <>
          {renderLoadingIndicator()}
          <div className={classes.paginationContainer} />
        </>
      ) : (
        <>
          {pages && pages.length ? (
            <>
              { renderInstrumentsList() }
              <div className={classes.paginationContainer}>
                <Pagination
                  handlePageChanged={setCurrentPage}
                  totalPageCount={pages.length}
                  currentPage={currentPage}
                />
              </div>
            </>
          ) : (null)}
          
        </>
      )}
    </div>
  )
}

InstrumentsFilterList.propTypes = {
  /** List of instruments */
  instruments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,

  /** Search pattern */
  pattern: PropTypes.string,

  /** Flag, that indicate, if assets data retrieving proccess is active */
  loading: PropTypes.bool,

  /** Hanler for onAddAsset event */
  onAddAssetClick: PropTypes.func,

  /** Instruments, that could not be selected*/
  instrumentsDisabled: PropTypes.arrayOf(PropTypes.shape({}))
}

InstrumentsFilterList.defaultProps = {
  instruments: [],
  loading: false
}

export default InstrumentsFilterList

