import React from "react";
import { connect } from 'react-redux'
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import {Tooltip} from "@material-ui/core";

import styles from "../styles";
import Portfolio from "./components/Portfolio";
import { setProductSelectionInvestmentRecomendation } from '../../../../../../../actions'
import {useConfirmationModalContext} from "../../../../../../../../../components/ConfirmationModalContextProvider";
import {SERVICE_CONCEPTS, validateEinzeltitelLimit} from "../../../../../../../../Trades/constants";
import {getSRRICategory} from "../../../FinalStep/FinalStep";
import {
  calculateInstrumentsAverageSRRI,
  getRiskScoreConfirmation, instrumentsSourceSrriValidationRequired, splitEinzeltitelTradingInstruments
} from "../../../../../../../utils";
import {getInstrumentSri} from "../../../../../../../../Trades/utils";

export const RiskScoreConfirmationModalContext = React.createContext()

export function useRiskScoreConfirmationModalContext() {

  const context = React.useContext(RiskScoreConfirmationModalContext)
  if (!context) {
    throw new Error('Extracting context without wrapping your component with context Provider!')
  }

  return context

}

const InvestmentRecommendationStep = props => {
  const {
    classes,
    onAnswerChange,
    questions: [
      instrumentsQuestion,
      instrumentsSource,
    ],
    dispatch,
    dataService,
    instrumentsSources,
    instrumentsSearchDisabled,
    instrumentsHandler
  } = props;

  const [stepsData, setStepsData] = React.useState(null);

  const modalContext = useConfirmationModalContext();

  React.useEffect(() => {
    const data = dataService.buildData();
    const investmentsData = dataService.getInvestedAmountData();
    const savingsPlan = investmentsData.savings_plan;
    const singleBuyAmount = investmentsData.single_investment;
    const vlPlanEnabled = investmentsData.vl_plan_enabled;
    const isOpenOnly = dataService.isOpenOnly;

    const showFunds = vlPlanEnabled
      || investmentsData.single_investment_enabled
      || investmentsData.savings_plan_enabled
      || isOpenOnly

    setStepsData({
      custodianData: data["custodians"][0] && data["custodians"][0].depot,
      savingsPlan: savingsPlan,
      singleBuyAmount: singleBuyAmount,
      vlPlanEnabled,
      singleInvestmentEnabled: investmentsData.single_investment_enabled,
      savingsPlanEnabled: investmentsData.savings_plan_enabled,
      einzeltitelEnabled: investmentsData.einzeltitel_enabled,
    });

    if (!instrumentsQuestion.answer) {
      handleAnswerChange([]);
    } else {
      let shares = [], funds = [];
      splitEinzeltitelTradingInstruments(instrumentsQuestion.answer, {}, shares, funds);
      const result = _.flatten([showFunds ? funds : [], investmentsData.einzeltitel_enabled ? shares : []])
      handleAnswerChange(result);
    }

    instrumentsQuestion.isValid = function(){

      const hasAmountRelatedErrors = (item) => {
        return !!Object.entries(item.error || {})
          .some(([errorFieldName, errorValue]) => {
            return ['amount', 'savingsPlanAmount'].includes(errorFieldName) && !!errorValue
          })
      }

      const checkInstrumentsDisabledByFilter = (instruments, errorFieldKey='funds_error') => {
        if (_.some(instruments, (item) => item.disabledByFilter)) {
          this[errorFieldKey] = 'Die gewählten Instrumente können nicht verwendet werden';
          return false;
        }
        return true
      }

      const checkInstrumentsAmount = (instruments, errorFieldKey='funds_error') => {
        if(!!instruments.find(i => (!i.amount && !i.transaction_value) || hasAmountRelatedErrors(i))) {
          this[errorFieldKey] = 'Bitte bestätigen Sie alle Beträge für die Instrumente';
        }
      }

      const checkInstrumentRiskScoreConfirmation = (instruments, errorFieldKey='funds_error', errorFieldMessage=undefined) => {
        instruments.forEach((item) => {

          const itemSRI = getInstrumentSri(item);

          if (itemSRI && itemSRI > dataService._srri && _.isEmpty(item.risk_score_explanation)) {
            item.error = {
              ...(item.error || {}),
              risk_score_explanation: 'This field is required' // For now German translate is not required, as we are not displaying it for this case
            }
            this[errorFieldKey] = errorFieldMessage || 'Bitte erfassen Sie eine Begründung für die Order eines Produktes mit einer höheren Risikoklasse als die des Kunden, damit Sie fortfahren können.'
          }

        })
      }

      this.funds_error = this.shares_error = null;

      const items = instrumentsQuestion.answer;

      // Do not validate instruments for open only flow
      if (isOpenOnly) {
        if (_.isEmpty(items)) {
          this.funds_error = 'Bitte fügen Sie ein Instrument zur Liste hinzu';
          return false
        }
        return true
      }

      let shares = [], funds = [];
      splitEinzeltitelTradingInstruments(items, {}, shares, funds);
      const instrumentSourceAnswer = instrumentsSource.answer

      if (investmentsData.einzeltitel_enabled) {
        if (_.isArray(shares) && shares.length > 0) {

          checkInstrumentsDisabledByFilter(shares, 'shares_error')

          if (!this.shares_error) {
            checkInstrumentsAmount(shares, 'shares_error')
          }

          if (!this.shares_error) {
            checkInstrumentRiskScoreConfirmation(shares, 'shares_error')
          }

          shares.forEach((share) => {
            share.errors = {}
            if (share.order_type !== 'no order' && !share.limit ) {
              share.errors.limit = 'Die gewählte Auswahl ist nicht verfügbar.'
            }

            validateEinzeltitelLimit(share, share.errors,
              (item) => _.get(item, 'calculated.last_price_value'))

          })

        } else {
          this.shares_error = 'Bitte fügen Sie ein Instrument zur Liste hinzu';
        }
      }

      if (showFunds) {
        if(!instrumentSourceAnswer){

          if(_.isArray(funds) && funds.length > 0){

            checkInstrumentsDisabledByFilter(funds)

            if (!this.funds_error) {
              if (vlPlanEnabled && funds.length > 1) {
                this.funds_error = 'Für Vermögenswirksame Leistungen (VL) kann nur ein Investment gewählt werden';
              }
            }

            if (!this.funds_error) {
              checkInstrumentsAmount(funds)
            }

            if (!this.funds_error) {

              const savingsPlanAmount = _.round(funds.filter(i => !!i.savingsPlan).reduce((accumulator, item) => {
                return (item.amount || 0) + accumulator;
              }, 0), 2);

              // const singleBuyTotal = _.round(funds.filter(i => !i.savingsPlan).reduce((accumulator, item) => {
              //   return (item.amount || item.transaction_value || 0) + accumulator;
              // }, 0), 2);

              if(savingsPlanAmount !== savingsPlan){
                this.funds_error = 'Die Gesamtbeträge sollten gleich sein';
              }

            }

          } else {
            this.funds_error = 'Bitte fügen Sie ein Instrument zur Liste hinzu';
          }

        } else if (instrumentsQuestion.disabledByFilter) {
          this.funds_error = instrumentsQuestion.disabledByFilter
        }

        if (!this.funds_error) {
          if (!instrumentSourceAnswer || instrumentsSourceSrriValidationRequired(instrumentSourceAnswer)) {
            checkInstrumentRiskScoreConfirmation(funds)
          } else if (!!instrumentSourceAnswer && instrumentSourceAnswer.isInvestmentStrategy) {

            const confirmationRequired = instrumentSourceAnswer.risk_score_confirmation_required
              || instrumentSourceAnswer.experience_confirmation_required;

            if (confirmationRequired && _.isEmpty(instrumentSourceAnswer.risk_score_explanation)) {
              instrumentSourceAnswer.error = {
                ...(instrumentSourceAnswer.error || {}),
                risk_score_explanation: 'This field is required' // For now German translate is not required, as we are not displaying it for this case
              }

              let error = 'Bitte erfassen Sie eine Begründung für die Anlage in einer Vermögensverwaltung mit einer höheren Risikoklasse als die des Kunden, damit Sie fortfahren können.';
              if (instrumentSourceAnswer.risk_score_confirmation_required
                && instrumentSourceAnswer.experience_confirmation_required) {
                error = 'Bitte geben Sie eine Begründung für die Anlage in einer Vermögensverwaltung mit einer höheren Risikoklasse als der des Kunden sowie einem höheren Kenntnis-/Erfahrungsniveau als dem des Kunden an, um fortzufahren.';
              } else if (instrumentSourceAnswer.experience_confirmation_required) {
                error = 'Bitte geben Sie eine Begründung für die Anlage in einer Vermögensverwaltung mit einem höheren Kenntnis-/Erfahrungsniveau als dem des Kunden an, um fortzufahren.'
              }

              this.funds_error = error;
            }
          }
        }

      }

      if (!this.funds_error && !this.shares_error) {
        if (!instrumentSourceAnswer || !instrumentSourceAnswer.isInvestmentStrategy) {
          const averageInstrumentsSRRI = _.get(instrumentSourceAnswer, 'srri') || calculateInstrumentsAverageSRRI(items, (instrument) => !instrument.savingsPlan && instrument.amount)
          if (averageInstrumentsSRRI > dataService.maxRiskThreshold) {
            this[showFunds ? 'funds_error' : 'shares_error'] = `Dieses Ordervolumen bzw. die Orderstruktur ist nicht zulässig, da infolge das durchschnittliche Portfoliorisiko über der zulässigen Kunden Risikoschwelle liegen würde. Bitte ändern sie den Betrag oder die Positionen insgesamt. Das zu erwartende Portfoliorisiko auf Basis der erfassten Orders würde bei "${averageInstrumentsSRRI}" liegen.`
          }
        }
      }

      const sharesHaveErrors = _.some(shares, (share) => !_.isEmpty(share.errors))

      return !this.funds_error && !this.shares_error && !sharesHaveErrors
    };

    return () => {
      dispatch(setProductSelectionInvestmentRecomendation(instrumentsQuestion.answer))
    }

  }, []);

  const handleAnswerChange = (answer, init, cleanSource) => {
    if(!init){
      if (cleanSource) {
        onAnswerChange(instrumentsSource.uid, null);
        instrumentsQuestion.disabledByFilter =  null
      }
      instrumentsQuestion.error = null; // clean error
    }

    onAnswerChange(instrumentsQuestion.uid, answer);
  };

  const handleInstrumentsSourceAnswerChange = (answer) => {
    onAnswerChange(instrumentsSource.uid, answer);
  }

  const confirm = async (asset) => {

    let assetsCopy = _.isArray(asset) ? asset : [asset]

    const riskScoreConfirmation = getRiskScoreConfirmation(dataService, assetsCopy)

    if (riskScoreConfirmation && !await modalContext.confirm(riskScoreConfirmation, 'Bitte bestätigen Sie hier')) {
      return false
    }

    if(instrumentsSource.answer) {
      return await modalContext.confirm("Sind Sie sicher? Ihre aktuelle Auswahl wird durch die neue Auswahl ersetzt.");
    } else {
      return true;
    }
  };

  const onDeleteModelPortfolioClick = () => {
    onAnswerChange(instrumentsSource.uid,  null)
    onAnswerChange(instrumentsQuestion.uid, null)
  }

  return (
    <>
      <Grid container spacing={2} style={{marginBottom: '2rem'}}>
        {dataService._srri && (
          <Grid item xs={4}>
            <Grid container direction={"column"} style={{height: '100%'}}>
              <Grid item />
              <Grid item className={classes.stepInfoTitle}>Risikoprofil des Kunden</Grid>
              <Grid item>
                <span><b>Risikoprofil:</b> {getSRRICategory(dataService._srri)}</span>
              </Grid>
              <Grid item>
                <span><b>Risikoklasse:</b> {dataService._srri}</span>
              </Grid>
              {/* <Grid item className={classes.linkBottom}>
                <a href={null}>Risikoklasse bearbeiten</a>
              </Grid> */}
            </Grid>
          </Grid>
        )}
      </Grid>
      {stepsData && (
        <Portfolio
          onAssetsUpdate={handleAnswerChange}
          onInstrumentsSourceAnswerChange={handleInstrumentsSourceAnswerChange}
          instruments={instrumentsQuestion.answer}
          dataService={dataService}
          error={instrumentsQuestion.error}
          sharesError={instrumentsQuestion.shares_error}
          fundsError={instrumentsQuestion.funds_error}
          singleBuyAmount={stepsData.singleBuyAmount} savingsPlan={stepsData.savingsPlan}
          vlPlanEnabled={stepsData.vlPlanEnabled}
          singleInvestmentEnabled={stepsData.singleInvestmentEnabled}
          savingsPlanEnabled={stepsData.savingsPlanEnabled}
          einzeltitelEnabled={stepsData.einzeltitelEnabled}
          depot={stepsData.custodianData}
          extraFilters={{custodian: stepsData.custodianData && stepsData.custodianData.bankCompanyId}}
          instrumentsSources={instrumentsSources}
          instrumentsSource={instrumentsSource.answer}
          confirmChange={confirm}
          instrumentsSearchDisabled={instrumentsSearchDisabled}
          onDeleteModelPortfolioClick={onDeleteModelPortfolioClick}
          instrumentsHandler={instrumentsHandler}
          infoText={(stepsData.custodianData && stepsData.custodianData.is_private_investment && dataService.isOpenOnly && !instrumentsSource.answer)
            ? "Um das leere Depot zu eröffen muss die Vermögensverwaltung ausgewählt werden!"
            : null}
        />
      )}
    </>
  );
};

export default connect()(withStyles(styles)(InvestmentRecommendationStep));
