import useFetchData from "../../../hooks/useDataFetch";
import {PortfolioHandlerResource} from "../../../utils/api";
import React from "react";
import {PortfolioUtils} from "../../../utils/utils";
import moment from "moment/moment";

export function usePortfoliosData(customerId, customerData, selectedPortfolios) {

  const [portfoliosData, fetchPortfoliosData, setPortfoliosData] = useFetchData(
    `${PortfolioHandlerResource.resourceUrl}customer/${customerId}/portfolio-data/`, 'post', undefined, true)

  React.useEffect(() => {

    if (customerData.loading) {
      return
    }

    if (selectedPortfolios.length) {
      fetchPortfoliosData({
        "depot_id": PortfolioUtils.getSelectedPortfoliosNumbers(selectedPortfolios) || [0],
        "start_date": moment().startOf('year').format('YYYY-MM-DD')
      })
    } else {
      // Simulate portfolios data fetching to set loading indicator to false, as loading by default is true.
      fetchPortfoliosData(undefined, true)
    }

  }, [selectedPortfolios])

  return [portfoliosData, setPortfoliosData]

}