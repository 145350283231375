import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import {withTradesDataProvider} from "./components/TradesDataProvider";
import styles from "../RiskProfiling/styles";
import Container from "@material-ui/core/Container/Container";
import {CircularProgress, makeStyles} from "@material-ui/core";
import StepContent from "../RiskProfiling/components/StepContent";
import clsx from "clsx";
import {RenderStepLoadingMessage} from "../../utils/utils";
import EinzeltitelProcessConfirmationModalContextProvider
  from "../RiskProfiling/components/EinzeltitelsProcessConfirmationProvider/EinzeltitelsProcessConfirmationProvider";

const Trades = props => {
  const {
    classes,
    onNextClick,
    onPrevClick,
    onConfirmResultClick,
    onResendResultClick,
    onAnswerChange,
    step,
    isFirst,
    isLast,
    loading,
    dataService,
    extraRightButtons,
  } = props;

  const useClasses = makeStyles(() => ({
    container: {
      '& > div': {
        marginTop: 0 // as we do not have progress bar override margin for it
      }
    }
  }));
  const customClasses = useClasses();

  return (
    <EinzeltitelProcessConfirmationModalContextProvider>
      <Container className={clsx(classes.container, customClasses.container)}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress className={classes.loadingIndicator}/>
            {RenderStepLoadingMessage(step)}
          </div>
        ) : (
          <>
            <StepContent
              step={step}
              loading={loading}
              isFirst={isFirst}
              isLast={isLast}
              onNextClick={onNextClick}
              onPrevClick={onPrevClick}
              onConfirmResultClick={onConfirmResultClick}
              onResendResultClick={onResendResultClick}
              onAnswerChange={onAnswerChange}
              dataService={dataService}
              nextStepBtnText={dataService && dataService.next_btn}
              successBody={dataService && dataService.success_body}
              extraRightButtons={extraRightButtons}
            />
          </>
        )}
      </Container>
    </EinzeltitelProcessConfirmationModalContextProvider>
  )
};

export default withTradesDataProvider(withStyles(styles)(Trades));