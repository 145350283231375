const styles = () => ({
  sliderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 64,
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  sliderHelpText: {
    paddingTop: 56,
    width: '80%',
    textAlign: 'center'
  },
});

export default styles;