import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  header: {
    fontFamily:'Roboto-Bold',
    fontSize: 20,
    color: '#202A38',
    margin: 0
  },
  sectionHeader: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#202A38',
    margin: 0
  },
  sectionValue: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    margin: '4px 0'
  },
  section: {
    '&:first-child': {
      borderRight: '1px solid #D8DCDF',

      [theme.breakpoints.down('sm')]: {
        border: 'none',
        borderBottom: '1px solid #D8DCDF',
        paddingBottom: 20
      }
    },
    '&:last-child': {
      paddingLeft: 55,

      [theme.breakpoints.down('sm')]: {
        padding: 0,
        paddingTop: 20
      }
    }
  }
})

export default makeStyles(styles)