import { makeStyles } from "@material-ui/core";
import {smFontSize, xsFontSize} from "../../../../utils/constants";

export default makeStyles((theme) => ({
  checkboxLabel: {
    fontSize: 16,
    [theme.breakpoints.only('sm')]: {
      fontSize: smFontSize,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: xsFontSize,
    }
  },
  checkboxFormControlLabel: {
    marginTop: -10
  },
}))