import React from 'react'

import {
  TRADING_ACTION_BUY, TRADING_ACTION_SAVINGS_PLAN,
  TRADING_ACTION_SWITCH_PLAN,
  TRADING_ACTIONS
} from '../../../../../../components/Charts/InstrumentsAllocationTable/constants'
import { toGermanFormat } from '../../../../../../utils/numberFormater'
import { getAmount } from "../../../../utils";

import { useTableStyles } from './styles'
import { getInstrName } from "../../../../../Modelportfolios/utils";
import moment from "moment";
import {getModelPortfolioTransactionTypeName, SAVING_PLAN_TRANSACTION_TYPE} from "../../../../constants";
import {formatQty} from "../../../../../../utils/utils";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const  InstrumentName = ({item, options}) => {
  const classes = useTableStyles()
  const subDepotNr = item.data.depot_sub_number
  const isMpOrPi = item.data.isModelportfolio || item.data.isPrivateInvestment

  const getInstrumentDetails = () => {

    if(isMpOrPi) return item.data.mp_number || item.data.isin

    return (
      <span>
        {item.data.isin} / {item.data.wkn}
        {!_.isNil(subDepotNr) && <small><br />Unterdepotnummer: {subDepotNr}</small>}
      </span>
   )
  }

  return (
    <div>
      <div className={classes.nameContainer}>
        {item.isSwitchInItem && (
          <span style={{margin: '0 5px'}}>
            <FontAwesomeIcon style={{margin: '0 !important', transform: 'rotate(90deg)'}} icon={['fas', 'level-up']} fixedWidth={true} />
          </span>
        )}
        <p className={classes.name}> { getInstrName(item.data) || item.data.isin } </p>
      </div>
      {getInstrumentDetails()}
    </div>
  )
}

const InstrumentNameCell = {
  header: 'Produktname',
  body: {
    content: (item, options, index) => (
      <InstrumentName item={item} options={options} />
    ),
  },
  key: 'InstrumentNameCell'
}

const  TradeAction = ({item, options}) => {
  let actionDetails = TRADING_ACTIONS.find(action => action.value == item.tradingType)

  return (
    <>
      { actionDetails && actionDetails.text }
    </>
  )
}

const TradeActionCell = {
  header: 'Aktion',
  body: {
    content: (item, options, index) => (
      <TradeAction item={item} options={options} />
    ),
  },
  key: 'TradeActionCell'
}

const ContractTypeCell = Object.create(TradeActionCell)
ContractTypeCell.header = 'Vertragsart'
ContractTypeCell.key = 'ContractTypeCell'

const SavingPlanActionCell = {
  header: 'Auftragsart',
  body: {
    content: (item) => {
      return (
        <span>{ SAVING_PLAN_TRANSACTION_TYPE[item.action || _.get(item, 'switchOutItem.action')] || '-' }</span>
      )
    }
  },
  key: 'SavingPlanActionCell'
}

const  InvestmentPlan = ({item, options}) => {
  let action = null;
  if(item.data.isModelportfolio){
    action = getModelPortfolioTransactionTypeName(
      TRADING_ACTIONS.find(a => a.value == item.tradingType).code,
      options.modelPortfolioTransactionType
    );
  } else if([1,2].includes(item.tradingType)){
    action = 'Einmalanlage';
  }

  return action;
};

const Rotation= ({rotation, availableOptions}) => {

  const getRotationLabel = () => {
    for (let option of availableOptions) {
      if (option.value == rotation) {
        return option.label
      }
    }

    return rotation
  }

  return (
      <span> {getRotationLabel()} </span>
  )
}

const InvestmentPlanCell = {
  header: 'Anlageplan',
  body: {
    content: (item, options, index) => (
      <InvestmentPlan item={item} options={options} />
    ),
  },
  key: 'InvestmentPlanCell'
}

const RotationCell= {
  header: 'Turnus',
  body: {
    content: (item, options, index) => (
      <Rotation rotation={_.get(item, 'switchOutItem.rotation') || item.rotation} availableOptions={options.rotationOptions || []} />
    )
  },
  key: 'RotationCell'
}

const FromDateCell = {
  header: 'Startdatum',
  body: {
    content: (item) => {
      const fromDate = _.get(item, 'switchOutItem.from_date') || item.from_date
      return (
        <span>{fromDate ? moment(fromDate).format('DD.MM.YYYY') : '-'}</span>
      )
    }
  },
  key: 'FromDateCell'
}

const TillDateCell = {
  header: 'Letzte Ausführung',
  body: {
    content: (item) => {
      const tillDate = _.get(item, 'switchOutItem.till_date') || item.till_date
      return (
        <span>{tillDate ? moment(tillDate).format('DD.MM.YYYY') : '-'}</span>
      )
    }
  },
  key: 'TillDateCell'
}

const  Value = ({item, options}) => {
  const classes = useTableStyles()

  return (
    <>
      {
        item.transaction_type == 1 ? (
          <>
            { item.switchOutItem ? (
              <div className={classes.flexContainer}>
                <p>{ toGermanFormat(getAmount(item, null, item.switchOutItem)) }</p><p><b>EUR</b></p>
              </div>
            ) : (
              <div className={classes.flexContainer}>
                <p>{ toGermanFormat(item.transaction_value) }</p><p><b>EUR</b></p>
              </div>
            ) }
          </>
        ) : (
          <>
            <div className={classes.flexContainer}>
              <p>{ formatQty(item.transaction_value, options.maxQtyDecimals) }</p><p><b>STÜCK</b></p>
            </div>
            <div className={classes.flexContainer}>
              <p>{ toGermanFormat(getAmount(item, item.transaction_value, item.switchOutItem)) }</p><p><b>EUR</b></p>
            </div>
          </>
        )
      }
    </>
  )
}

const ValueCell = {
  header: 'Volume',
  body: {
    content: (item, options, index) => (
      <Value item={item} options={options} />
    ),
  },
  key: 'ValueCell'
}

const SavingsPlanValueCell = Object.create(ValueCell)
SavingsPlanValueCell.header = 'Betrag'
SavingsPlanValueCell.key = 'SavingsPlanValueCell'

const  Discount = ({item, options}) => {
  if(![TRADING_ACTION_BUY, TRADING_ACTION_SAVINGS_PLAN, TRADING_ACTION_SWITCH_PLAN].includes(item.tradingType)){
    return null;
  }

  const classes = useTableStyles()

  return (
    <>
      { item.discount && (
        <div className={classes.flexContainer}>
          <p>{item.discount}</p><p>%</p>
        </div>
      )}
    </>
  )
}

const DiscountCell = {
  header: 'Anmerkung',
  body: {
    content: (item, options, index) => (
      <Discount item={item} options={options} />
    ),
  },
  key: 'DiscountCell'
}

export const BrokerFeeColumn = {
  header: {
    content: (item, options) => (options.isModelportfolio ? <span>Einstiegs-<br/>entgelt</span> : <span>Ausgabe-<br/>aufschlag</span>)
  },
  body: {
    content: (item, options, index) => {
      if(item.tradingType != 1){
        return null;
      }

      const classes = useTableStyles();

      const discount = (item.discount || 0) / 100;
      const brokerFee = (item.broker_fee || 0) / 100;
      const brokerFeeInEur = getAmount(item, null, item.switchOutItem) * brokerFee;
      const brokerFeeWithDiscountEur = brokerFeeInEur - brokerFeeInEur * discount;

      return (
        <>
          {brokerFeeWithDiscountEur && (
              <div className={classes.flexContainer}>
                <p>{ toGermanFormat(brokerFeeWithDiscountEur) }</p><p><b>EUR</b></p>
              </div>
          )}
        </>
      )
    }
  },
  key: 'BrokerFeeCell'
}

export const savingsPlanTableStructure= {
  default: [
    {
      content: [InstrumentNameCell],
    }, {
      content: [ContractTypeCell],
      align: 'left'
    }, {
      content: [SavingPlanActionCell],
      align: 'left'
    }, {
      content: [SavingsPlanValueCell]
    }, {
      content: [RotationCell]
    }, {
      content: [FromDateCell]
    },  {
      content: [TillDateCell]
    }, {
      content: [DiscountCell],
      align: 'right'
    },
  ]
}

const tableStructure = {
  default: [
    {
      content: [InstrumentNameCell],
    },
    {
      content: [TradeActionCell],
      align: 'left'
    },
    {
      content: [InvestmentPlanCell],
    },
    {
      content: [ValueCell],
      align: 'right'
    },
    {
      content: [DiscountCell],
      align: 'right'
    },
    {
      content: [BrokerFeeColumn],
      align: 'right'
    },
  ],
  xs: [
    {
      content: [InstrumentNameCell]
    },
  ]
}


export default tableStructure;