import React from "react";
import {Grid, withStyles} from "@material-ui/core";
import styles from "./styles";
import ChartRollingVolatility from "./items/ChartRollingVolatility";
import ChartRollingSharpeRatio from "./items/ChartRollingSharpeRatio";
import KeyIndicatorsCard from "./items/KeyIndicators";
import EsgScoreChart from "./items/EsgScoreChart";


class KeyFiguresTab extends React.Component {

  render() {
    return <Grid container spacing={2}>
      <Grid item xs={12}>
        <KeyIndicatorsCard
          customerId={this.props.customerId}
          portfolioId={this.props.portfolioId}
          investmentData={this.props.financialData}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ChartRollingSharpeRatio
          customerId={this.props.customerId}
          portfolioId={this.props.portfolioId}
          portfolioName={this.props.portfolioName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ChartRollingVolatility
          customerId={this.props.customerId}
          portfolioId={this.props.portfolioId}
          portfolioName={this.props.portfolioName}
        />
      </Grid>
      <Grid item xs={12}>
        <EsgScoreChart
          customerId={this.props.customerId}
          portfolioId={this.props.portfolioId}
        />
      </Grid>
    </Grid>
  }
}

export default withStyles(styles)(KeyFiguresTab);
