import _ from "lodash";
import {DISTRIBUTION_TYPES} from "../DashboardSettings/components/CustomersList/components/DistributionType/constants";
import { toLocalDateTime } from '../../utils/utils';


export function getTypeNameByValue(value = 1) {
  const key = _.findKey(DISTRIBUTION_TYPES, ['value', value]);
  return DISTRIBUTION_TYPES[key].description;
}

export function getListDateFormat(date = new Date()) {
  return toLocalDateTime(date).format('DD.MM.YYYY - HH:mm');
}
