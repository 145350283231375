import React from 'react';

import useStyles from './styles'
import { DeleteIcon } from '../../../../../../images';
import PropTypes from 'prop-types';

function ResetButton({onClick}) {

  const classes = useStyles();

  const handleButtonClick = (event) => {
    event.stopPropagation()
    if (onClick) {
      onClick()
    }
  }

  return (
    <p className={classes.resetButton} onClick={handleButtonClick}>
      <span><DeleteIcon/></span>
      <span>Filter zurücksetzen</span>
    </p>
  );
}

ResetButton.propTypes = {
  onClick: PropTypes.func
};

export default ResetButton;