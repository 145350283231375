import React from 'react';

export const ESG_INFO_TEXT = 'Nachhaltige Investition gemäß Offenlegungsverordnung. Trägt zur Erreichung eines ' +
  'Umweltziels oder eines sozialen Ziels bei und investiert in Unternehmen, die Verfahrensweisen einer guten ' +
  'Unternehmensführung anwenden.';

export const OKO_INFO_TEXT = 'Nachhaltige Investition gemäß Taxonomieverordnung. ' +
  'Leistet u.a. einen wesentlichen Beitrag zur Verwirklichung eines oder mehrerer Umweltziele.';

export const PAI_INFO_TEXT = 'Investition berücksichtigt die wichtigsten nachteiligen Auswirkungen auf ' +
  'Nachhaltigkeitsfaktoren. Treibhausgasemissionen, Biodiversität, Wasser, Abfall, Soziale und Arbeitnehmerbelange.';