import React from 'react';

/* BCA Components */
import LoadingContainer from '../LoadingContainer';

/* BCA modules */
import useStyles from '../styles';
import {withEuroOrDash} from "../../../../utils/utils";


export default (props) => {
  const classes = useStyles();

  const {
    value,
    loading,
    styles
  } = props;

  return (
    <div className={classes.container}>
      { loading ? (
        <LoadingContainer/>
      ) : (
        <React.Fragment>
          <h5 className={classes.header} style={styles && styles.header ? styles.header : {}}>Sonstige Vermögenswerte</h5>
          <p className={classes.subHeader}>Immobilien, Beteiligung/ Firmenanteile etc.</p>
          <p className={classes.value}><span>{withEuroOrDash(value || 0)}</span></p>
        </React.Fragment>
      )}
    </div>
  )
}