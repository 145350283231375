import React from 'react';


export default () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <path id="Pfad_7872" data-name="Pfad 7872" d="M952.381,1968.421a.99.99,0,0,1-.746-1.639l.279-.32-.025-.015a2.084,2.084,0,0,1-1.088-1.429,1.771,1.771,0,0,1,.44-1.469l.394-.452a.989.989,0,0,1,1.492,1.3l-.279.32.026.015a2.084,2.084,0,0,1,1.088,1.43,1.768,1.768,0,0,1-.44,1.468l-.394.452a.989.989,0,0,1-.746.34Zm-4.914,0a.987.987,0,0,0,.746-.34l.394-.452a1.768,1.768,0,0,0,.441-1.468,2.083,2.083,0,0,0-1.087-1.43l-.026-.015.279-.32a.989.989,0,0,0-1.492-1.3l-.394.452a1.771,1.771,0,0,0-.44,1.469,2.084,2.084,0,0,0,1.088,1.429l.025.015-.279.32a.989.989,0,0,0,.746,1.639Zm8.6,11.057a.989.989,0,0,0,0-1.979h-.328a9.616,9.616,0,0,0,3.774-7.61.99.99,0,0,0-.99-.989h-17.2a.99.99,0,0,0-.989.989,9.616,9.616,0,0,0,3.774,7.61h-.328a.989.989,0,0,0,0,1.979Zm1.4-8.6a7.611,7.611,0,0,1-15.093,0Zm-18.135,6.6h-3.822v-19.342l9-6.428,9,6.428v2.369a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1v-.94l2.389,1.706a1.024,1.024,0,0,0,1.295-.06,1,1,0,0,0-.1-1.546l-14-10a1,1,0,0,0-1.162,0l-14,10a1,1,0,1,0,1.162,1.628l2.419-1.728v18.914a1,1,0,0,0,1,1h4.822a1,1,0,0,0,1-1h0A1,1,0,0,0,939.335,1977.477Z" transform="translate(-929.513 -1949.478)" fill="#80858c" />
        </svg>

    )
}