export const getOriginFromUrl = () => {
  let search = window.location.search;
  search = search.substring(1)
  let searchParam = new URLSearchParams(search);

  let origin = undefined

  let urlOrigin = searchParam.get('origin')
  if (urlOrigin) {
    origin = urlOrigin
  }

  return origin
}

/**
 * Retrieve list of settings from shared settings using key.
 * 
 * @param {Object} settings Shared settings object
 * @param {String} key Shared setting key
 * 
 * @returns {Array<Object>?} List of values for specific key with values for different hosts
 */
export const getHostRelatedSharedSettingsByKey = (settings, key) => {

  if (settings.hasOwnProperty(key)) {
    return settings[key]
  }

  return undefined

}


/**
 * Get value from list of values using host.
 * 
 * 
 * @param {Array<Object>} values List of shared settings values 
 * @param {String} host Host
 * 
 * @returns {String?} Value with specific host 
 */
export const getValueByHost = (values, host) => {

  let resultValue = undefined
  let defaultValue = undefined

  values.forEach((value) => {
    if (value.host == host) {
      resultValue = value.value
    }

    if (!value.host) {
      defaultValue = value.value
    }
  })

  return resultValue || defaultValue

}


/**
 * Get setting value using key and host.
 * 
 * 
 * @param {Object} settings Shared settings object
 * @param {String} key Shared setting key
 * @param {String} host Host
 * 
 * @returns {String?} Setting value
 */
export const getSharedSettingValueByHost = (settings, key, host) => {

  const setting = getHostRelatedSharedSettingsByKey(settings, key)

  if (setting) {
    const result = getValueByHost(setting, host)

    if (result) {
      return result
    }
  }

  return undefined

}