import React from "react";
import clsx from "clsx";

import useStyles from "./styles";
import {isRequiredQuestion, isValidValue} from "../../../../../utils";
import FormHelperText from "@material-ui/core/FormHelperText";
import {FIELD_REQUIRED_MSG} from "../../../../../constants";

function isListSelectQuestionValid(question, step, afterChange, service) {
  if(isRequiredQuestion(question)) {
    const isValid = isValidValue(question.answer);
    question.error = isValid ? null : FIELD_REQUIRED_MSG;

    return isValid;
  }

  return true;
}

export const ListSelectQuestionMethods = {
  required: isRequiredQuestion,
  valid: isListSelectQuestionValid
}

const ListSelectQuestion = props => {
  const {
    question,
    onAnswerChange
  } = props;

  const classes = useStyles();

  const options = question.config.choices || [];
  const value = question.answer;

  React.useEffect(() => {
    if (!!value && !options.find(o => o.uid == value)){
      handleChange(''); // clean answer as it's not in the options
    }
  }, [value, options]);

  const handleChange = (value) => {
    question.error = null;  // clean error
    onAnswerChange(question.uid, value);
  };

  return (
    <div className={classes.questionContainer}>
      { question.error && (
        <FormHelperText
          error={true}
          classes={{
            error: classes.errorContainer
          }}>{ question.error }</FormHelperText>
      )}

      {options.map(option => (
        <div
          key={option.id}
          className={clsx(
            classes.item,
            option.uid === value && classes.selected
          )}
          onClick={() => handleChange(option.uid)}
        >
          {option.text}
        </div>
      ))}
      {!!question.info && <div className={classes.helpText}>{question.info}</div>}
    </div>
  );
};

export default ListSelectQuestion;