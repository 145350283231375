import React from 'react';
import clsx from 'clsx';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';

import useStyles from './styles';


const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const SnackbarWrapper = (props) => {
  const classes = useStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      classes={{root: classes.snackbarRoot, action: classes.snackbarAction}}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
};

const DEFAULT_DURATIONS = {
  'success': 4,
};

export default (props) => {

  const {
    open,
    variant,
    message,
    handleClose,
    autoHideDuration,  // duration in seconds
  } = props;

  const duration = autoHideDuration || DEFAULT_DURATIONS[variant];

  const onClose = () => handleClose(false);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={duration ? duration * 1000 : null}
      onClose={(event, reason) => {
        if (reason !== 'clickaway') {
          onClose();
        }
      }}
      style={{zIndex: 999999}}
    >
      <SnackbarWrapper
        onClose={onClose}
        variant={variant}
        message={message}
      />
    </Snackbar>
  )
}