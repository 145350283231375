import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  icon: {
    color: props => props.color || theme.palette.primary.main,
    fontSize: props => props.size,
    margin: '0 4px',
    cursor: 'pointer',
  }
}))

export const useInputWarningTooltipStyles = makeStyles((theme) => ({
  icon: {
    position: 'relative',
    marginLeft: 5,
    top: 10,

    '& > svg': {
      position: 'absolute'
    }
  }
}))