import {buildGuideTourStep} from "../../../components/GuideTour/utils";
import {getNavigationChapterSteps} from "./navigation_chapter";
import {getOverviewChapterSteps} from "./overview_chapter";

const TOUR_STORAGE_KEY = 'documentsGuideTour';
const NAVIGATION_CHAPTER_KEY = 'navigation';
const OVERVIEW_CHAPTER_KEY = 'overviewChapter';

export const getGuideSteps = (width) => {
  return [
    buildGuideTourStep(1, 'Navigationsmenü',
      getNavigationChapterSteps(width), TOUR_STORAGE_KEY, NAVIGATION_CHAPTER_KEY),
    buildGuideTourStep(2, 'Übersicht elektronisches Kundenpostfach',
      getOverviewChapterSteps(), TOUR_STORAGE_KEY, OVERVIEW_CHAPTER_KEY)
  ];
};
