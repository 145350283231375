import { makeStyles , createStyles } from '@material-ui/core';

export const styles = theme => createStyles({
  buttonRoot: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    borderRadius: '0 4px 4px 0'
  },
  header: {
    fontSize: 24
  }
});

export default makeStyles(styles);