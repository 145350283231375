export const formatNumber = function(number, options) {
  let roundFraction = Array.apply(null, new Array(options['fraction'] || 2)).map(Number.prototype.valueOf,0);
  roundFraction = +(['1', ...roundFraction].join(''))
  const roundNumber = Math.round(number * roundFraction) / roundFraction;
  const result = (options['fraction'] ? roundNumber.toFixed(options['fraction']) : '' + roundNumber).split('.');

  const regexp = /\B(?=(\d{3})+(?!\d))/g;

  const prefix = options['prefix'];
  const suffix = options['suffix'];

  const grouping = result[0].replace(regexp, (options['grouping']));

  let decimals = result[1];

  if (decimals && options['lose_decimals']) {
    const min_decimals = options['min_decimals'] || 0;
    while(decimals.length > min_decimals && decimals.slice(-1) === '0') {
      decimals = decimals.slice(0, -1);
    }
  }

  decimals = (decimals ? options['decimal'] + decimals : '');

  return prefix + grouping + decimals + suffix;
};

/**
 * Return number in German format
 *
 * @param {float|int|string} number Number, that need should be displayed in German format
 * @param {string} prefix String, that should be before number
 * @param {string} suffix String, that should be after number
 * @param {int} fraction Number, that should be rounder to count of decimals
 * @param {boolean} lose_decimals Boolean, to remove last zeros from decimals
 * @param {int} min_decimals Number, min number of decimals left after lose_decimals
 * @return {string|float}      Number representation in German format
 */
export const toGermanFormat = (number, prefix='', suffix='', fraction=2, lose_decimals=false, min_decimals=undefined) => {
  try {
    const decimal = ',';
    const grouping = '.';
    const value = parseFloat(number);

    return formatNumber(value, {
      decimal,
      grouping,
      prefix,
      suffix,
      fraction,
      lose_decimals,
      min_decimals,
    });
  }
  catch(err) {
    return number;
  }
};

export const toShortGermanFormat = (number, prefix='', suffix='', fraction=2, lose_decimals=false) => {
  const m = ' Mio.';
  const k = ' Tsd.';

  let _suffix = '';
  let value = number;

  try{
    if (number >= 1000000 || number <= -1000000) {
      _suffix = m;
      value = number / 1000000;
    }
    if (number >= 1000 || number <= -1000) {
      _suffix = k;
      value = number / 1000;
    }

    return toGermanFormat(value, prefix, _suffix + suffix, fraction, lose_decimals);

  } catch (err) {
    return number;
  }
};
