import {makeStyles} from "@material-ui/core";

const styles = (theme) => ({
  dialogContentRoot: {
    '& .MuiContainer-root': {
      padding: 0
    },
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  header: {
    '& > h2': {
      fontSize: 20,
      color: '#4D4F5C',
      fontFamily: 'Roboto-Regular'
    },
    height: 16
  },
  subHeader: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular'
  },
  sourceItemCheckBox: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      fontFamily: 'Roboto-Bold',
      fontSize: 20,
      margin: 0,
      padding: 0
    },
    marginBottom: 5
  },
  dialogActionsRoot: {
    padding: '24px',
  },
  error: {
    color: theme.palette.error.main,
    margin: 0
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  containerCheckbox: {
    padding:0,
    marginRight: 8,
    marginLeft: 5
  },
  containerName: {
    marginLeft: '5px !important'
  },
  loadingContainer: {
    width: '100%',
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
});

export default makeStyles(styles);