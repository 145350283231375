import React from 'react';

import List from "@material-ui/core/List";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

import useStyles from './styles';
import SelectCaret from '../Icons/SelectCaret';

const AccordionList = props => {
  const {
    carrerIcon,
    renderSelectedItem,
    renderItemsList,
    selectedItem,
    expanded,
    setExpanded,
    overrideClasses
  } = props;

  const classes = useStyles();

  return (
    <>
      <ExpansionPanel TransitionProps={{
          timeout: 0,
          style: {position: 'absolute'}
        }} 
        expanded={expanded} 
        onChange={() => setExpanded(!expanded)} 
        classes={{
          root: overrideClasses.expansionPanelRoot,
          expanded: overrideClasses.expansionPanelExpanded
        }}>
        <ExpansionPanelSummary
            expandIcon={carrerIcon}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            IconButtonProps={{
              disableRipple: true
            }}
            classes={{
              root: classes.expansionPanelSummaryRoot,
              expanded: classes.expansionPanelSummaryExpanded,
              content: classes.expansionPanelSummaryContent
            }}
          >
          <div className={classes.ellipsis}>
            {renderSelectedItem(selectedItem)}
          </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails classes={{
            root: classes.expansionPanelDetailsRoot,
          }}>
          <List className={classes.portfolioList}>
            {renderItemsList()}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  )
};

AccordionList.defaultProps = {
  carrerIcon: <SelectCaret style={{color: '#0092E5'}} />
}

export default AccordionList;