import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    padding: 8,
  },
  dialogActions: {
    padding: 24
  },
  dialogContent: {

  },
  dialogTitle: {
    fontSize: 20,
    fontFamily: 'Roboto-Bold',
  }
}));