import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';
import theme from '../../../../themes/mainTheme';
import { AddIcon } from '../../../../images';
import { getInstrName } from '../../../Modelportfolios/utils';
import useStyles from './styles';

function InstrumentsFilterList(props) {
  const {
    instruments,
    pattern,
    loading,
    onAddAssetClick,
    instrumentsDisabled,
  } = props;

  const classes = useStyles();

  const renderInstrumentName = (name, isin, wkn) => {

    let fullName = name;
    if (isin || wkn) {
      let parts = [];
      if (isin) parts.push(isin)
      if (wkn) parts.push(wkn)
      fullName = `${fullName ? `${fullName} ` : ''}( ${parts.join(', ')} )`
    }

    if (pattern) {

      if (fullName.includes(pattern)) {
        let patternRegEx = new RegExp(pattern, 'gi');
        return `<span>${fullName.replace(patternRegEx, match => `<b>${match}</b>`)}</span>`
      }

      return fullName
    }

    return fullName;
  }

  const renderLoadingIndicator = () => [...Array(5)].map(() => (
    <div className={classes.loadingIndicatorContainerItem}>
      <Skeleton />
    </div>
  ))

  const handleAddAssetClick = (asset) => {
    if (onAddAssetClick) {
      onAddAssetClick(asset)
    }
  }

  const isInstrumentDisabled = (instrument) => {
    return instrumentsDisabled && !!_.find(instrumentsDisabled, i => i.financial_information.asset_info_data.isin === instrument.isin)
  }

  const renderInstrument = (instrument, disabled=false) => {
    let instrDisabled = isInstrumentDisabled(instrument);
    return (
      <div className={classes.instrumentItemContainer} key={instrument.id} onClick={() => !instrDisabled && handleAddAssetClick(instrument)}>
        <p className={clsx(classes.instrumentItemName, isInstrumentDisabled(instrument) || disabled ? classes.instrumentItemNameDisabled : '')}
            dangerouslySetInnerHTML={{__html: renderInstrumentName(getInstrName(instrument), instrument.isin, instrument.wkn)}}></p>
        {!instrDisabled ? (
          <>
            {!disabled && (
              <span>
                <AddIcon color={theme.palette.primary.main} size={18}/>
              </span>
            )}
          </>
        ) : (
          <span className={classes.disabledLabel}>hinzugefügt</span>
        )}
      </div>
    )
  }

  const renderInstruments = (instruments, disabled=false) => {
    return instruments.map(instrument => renderInstrument(instrument, disabled))
  }

  const render = () => {

    if (_.isArray(instruments)) {
      return renderInstruments(instruments)
    }

    let instrumentsToAccept = []
    let instrumentsToExclude = []

    if (instruments.hasOwnProperty('accepted')) {
      instrumentsToAccept = instruments.accepted
    }

    if (instruments.hasOwnProperty('excluded')) {
      instrumentsToExclude = instruments.excluded
    }

    instrumentsToAccept = renderInstruments(instrumentsToAccept)
    instrumentsToExclude = renderInstruments(instrumentsToExclude, true)

    return [...instrumentsToAccept, instrumentsToExclude]
  }

  return (
    <div className={classes.container}>
      {loading ? (
        <>
          {renderLoadingIndicator()}
        </>
      ) : (
        <>
          { render() }
        </>
      )}
    </div>
  )
}

InstrumentsFilterList.propTypes = {
  /** List of instruments */
  instruments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,

  /** Search pattern */
  pattern: PropTypes.string,

  /** Flag, that indicate, if assets data retrieving proccess is active */
  loading: PropTypes.bool,

  /** Hanler for onAddAsset event */
  onAddAssetClick: PropTypes.func,

  /** Instruments, that could not be selected*/
  instrumentsDisabled: PropTypes.arrayOf(PropTypes.shape({}))
}

InstrumentsFilterList.defaultProps = {
  instruments: [],
  loading: false
}

export default InstrumentsFilterList

