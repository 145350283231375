export const SENDING_DATE = {
    BEGIN: {
        value: 1,
        description: 'Investmentbeginn'
    },
    ONE_Y: {
        value: 2,
        description: '1 Jahr'
    },
    THREE_Y: {
        value: 3,
        description: '3 Jahre'
    },
    FIVE_Y: {
        value: 4,
        description: '5 Jahre'
    },
    CUSTOM: {
        value: 5,
        description: ''
    },
    LAST_PERIOD: {
        value: 6,
        description: 'Letzte abgeschlossene Periode'
    }
};

export const DEFAULT_SENDING_DATE = SENDING_DATE.ONE_Y;