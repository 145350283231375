import React from 'react';
import Grid from '@material-ui/core/Grid';
import TransactionsMonitoring from "../../TransactionsMonitoring/TransactionsMonitoring";
import useStyles from './styles';
import DownloadIcon from "../../../images/DownloadIcon";
import {IconButton} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import Tooltip from "../../../components/Tooltip";
import { ChartSectionWrapper } from '../components_v2/ChartSectionBordered/ChartSectionBordered';

function TransactionsTab(props) {

  const classes = useStyles();
  const theme = useTheme();

  const dates = {
    datesType: props.calculationDatesType,
    start: props.calculationDates.start && props.calculationDates.start.format('YYYY-MM-DD') || undefined,
    end: props.calculationDates.end && props.calculationDates.end.format('YYYY-MM-DD') || undefined
  };

  return (
    <ChartSectionWrapper
      dataId={"transactions-table"}
      expanded={props.expandedItems.transactions}
      title={(
        <b>Transaktionsübersicht</b>
      )}
      onExpanded={(newState) => props.onExpandedItemsChange('transactions', newState)}
      content={
        <TransactionsMonitoring
          selectedDepots={(props.selectedPortfolios || []).map((portfolio) => portfolio.depotNumber)}
          dates={dates}
          isMounted={props.isMounted}
          isComponent
          navigationRender={({ handleDownloadPdfClick, pdfLoading }) => (
            <Grid item xs={12} style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <h2 className={classes.sectionHeader}>
                <b>Transaktionen</b>
              </h2>
              <Tooltip title={"Transaktionsübersicht herunterladen"}>
                <span>
                  <IconButton onClick={handleDownloadPdfClick} disabled={pdfLoading} style={{ marginLeft: 5 }}>
                    <DownloadIcon size={18} color={pdfLoading ? 'rgba(0, 0, 0, 0.26)' : theme.palette.primary.main}/>
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          )}
        />
      }
    />
  )
}

TransactionsTab.propTypes = {}

TransactionsTab.propDefs = {}

TransactionsTab.defaultProps = {}

export default TransactionsTab;