import React from 'react';
import _ from 'lodash';

import BaseChart from '../../../../../../FactSheetsPage/components/BaseChart';

import withTheme from '@material-ui/core/styles/withTheme';
import { Grid } from '@material-ui/core';

import {
  hasResponseError,
  PortfolioUtils,
} from '../../../../../../../utils/utils';
import useStyles from '../styles';


const PortfolioCurrencyStructure = (props) => {
  const {
    dashboardData,
    loading
  } = props;

  const classes = useStyles();

  const portfolioStructure = dashboardData && dashboardData.portfolio_structure;

  const dataError = _.isNil(portfolioStructure) ||  hasResponseError(portfolioStructure);

  let data = [];
  if (portfolioStructure) {
    data = PortfolioUtils.getPortfolioCurrencies(portfolioStructure)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <BaseChart
          chartType='bar'
          title={data.length !== 1 ? 'Währungen' : 'Währung'}
          subtitle={loading ? '' : data.length ? '(Top 10 Instrumente innerhalb Fonds)' : ''}
          seriesData={data}
          loading={loading}
          error={dataError}
          allowFloatingMixMax
          classes={{
            title: (!loading && !data.length) ? classes.titleWithoutData : classes.title,
            subtitle: classes.subtitle
          }}
        />
      </Grid>
    </Grid>
  )
};


export default withTheme(PortfolioCurrencyStructure);
