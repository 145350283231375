import React from 'react';


export default () => {
    return (
        <svg
      id="Group_7975"
      data-name="Group 7975"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="Group_562" data-name="Group 562" transform="translate(0 0)">
        <path
          id="Path_1546"
          data-name="Path 1546"
          d="M298.3,618.195v-2.637a2.641,2.641,0,0,0-1.635-2.439,3.223,3.223,0,1,0-5.519,0,2.64,2.64,0,0,0-1.635,2.439v1.98l-1.2,1.2a6.728,6.728,0,0,0-10.026,0l-1.2-1.2v-1.98a2.641,2.641,0,0,0-1.635-2.439,3.223,3.223,0,1,0-5.519,0,2.641,2.641,0,0,0-1.635,2.439v2.637h6.96l2.018,2.018a6.728,6.728,0,0,0,1.524,8.034l-2.9,2.9a3.222,3.222,0,1,0-5.963,2.008A2.641,2.641,0,0,0,268.3,635.6v2.637h8.789V635.6a2.634,2.634,0,0,0-1.049-2.1l4.238-4.238a6.728,6.728,0,0,0,6.042,0l4.238,4.238a2.634,2.634,0,0,0-1.048,2.1v2.637H298.3V635.6a2.641,2.641,0,0,0-1.635-2.439,3.222,3.222,0,1,0-5.963-2.008l-2.9-2.9a6.728,6.728,0,0,0,1.524-8.034l2.018-2.018Zm-25.605-8.2a1.465,1.465,0,1,1-1.465,1.465A1.467,1.467,0,0,1,272.7,609.992Zm2.637,6.445h-5.273v-.879a.88.88,0,0,1,.879-.879h3.516a.88.88,0,0,1,.879.879ZM272.7,630.031a1.465,1.465,0,1,1-1.465,1.465A1.467,1.467,0,0,1,272.7,630.031Zm2.637,6.445h-5.273V635.6a.88.88,0,0,1,.879-.879h3.516a.88.88,0,0,1,.879.879Zm18.574-6.445a1.465,1.465,0,1,1-1.465,1.465A1.467,1.467,0,0,1,293.906,630.031ZM291.27,635.6a.88.88,0,0,1,.879-.879h3.516a.88.88,0,0,1,.879.879v.879H291.27ZM278.4,624.113h1.7a12.037,12.037,0,0,0,.656,3.4A4.993,4.993,0,0,1,278.4,624.113Zm1.7-1.758h-1.7a4.993,4.993,0,0,1,2.36-3.4A12.038,12.038,0,0,0,280.1,622.355Zm4.038,4.7c-.352.879-.719,1.164-.841,1.164s-.49-.284-.841-1.164a9.929,9.929,0,0,1-.595-2.938h2.872A9.929,9.929,0,0,1,284.142,627.051Zm-2.277-4.7a9.926,9.926,0,0,1,.595-2.938c.352-.879.719-1.164.841-1.164s.49.284.841,1.164a9.93,9.93,0,0,1,.595,2.938Zm3.977,5.16a12.035,12.035,0,0,0,.656-3.4h1.7A4.992,4.992,0,0,1,285.842,627.515Zm.656-5.16a12.041,12.041,0,0,0-.656-3.4,4.992,4.992,0,0,1,2.36,3.4Zm7.408-12.363a1.465,1.465,0,1,1-1.465,1.465A1.466,1.466,0,0,1,293.906,609.992Zm-2.637,5.566a.88.88,0,0,1,.879-.879h3.516a.88.88,0,0,1,.879.879v.879H291.27Z"
          transform="translate(-268.301 -608.234)"
          fill="#fff"
        />
      </g>
    </svg>
    )
}