import React from "react";
import _ from 'lodash'
import {FinalizeService} from "../../RiskProfiling/finalize_step_service";
import {savingsPlansDeleteOnly} from "../utils";
import {TRANSACTION_TYPE_VALUES} from "../constants";


export class ProtocolMenuItem extends FinalizeService {
  constructor(uid, name, customer_id, getPrevStepAnswer, handleCreateOrderClick) {
    super(uid, name, 'Zum Kundenprofil',
        <>
          Sie haben den Prozess erfolgreich abgeschlossen.
        </>,
        getPrevStepAnswer);
    this._customer_id = customer_id;
    this._is_trading = true;
    this.handleCreateOrderClick = handleCreateOrderClick;
  }

  get is_trading() {
    return this._is_trading
  }

  set is_trading(value) {
    this._is_trading = value
  }

  /**
   * Get list of depots, that were chosen for transactions.
   * @returns {?Object[]}
   */
  get investedDepots() {
    return this._getPrevStepAnswer('trade', 'trade-step', "transactions", true)
  }

  get investedInstrumentsGroupedByCustodian() {
    const portfolios = this.investedDepots;

    const result = {};

    portfolios.forEach((portfolio) => {

      const custodianId = portfolio.companyId;

      if (!result.hasOwnProperty(custodianId)) {
        result[custodianId] = {
          instruments: [],
          transactions: [],
          hasEinzeltitels: false,
          einzeltitelsSubmissionMessage: null
        }
      }

      Object.keys(portfolio.transactions).forEach((transactionType) => {
        (portfolio.transactions[transactionType] || []).forEach((transaction) => {
          if (!portfolio.isModelportfolio) {
            result[custodianId].instruments.push(transaction.data);
            if ([TRANSACTION_TYPE_VALUES.SWITCH, TRANSACTION_TYPE_VALUES.SWITCH_PLAN].includes(transactionType)) {
              result[custodianId].transactions.push(...((transaction.buy || []).map((transaction) => transaction.data)))
            }
          }
        })
      })

      result[custodianId].hasEinzeltitels = _.some(result[custodianId].instruments, (instrument) => !instrument.is_fund);
      result[custodianId].einzeltitelsSubmissionMessage = _.get(portfolio, 'depotType.configuration.einzeltitel_submission_message');
    })

    return result;
  }

  get investedInstruments() {
    let answer = this.investedDepots

    if (!!answer) {
      let instruments = []

      answer.forEach(portfolio => {
        if (!portfolio.isModelportfolio) {
          portfolio.transactions.buy && portfolio.transactions.buy.forEach(transaction => instruments.push({
            ...transaction,
            isin: transaction.data && transaction.data.isin
          }))
          portfolio.transactions.switch && portfolio.transactions.switch.forEach(transaction => {
            transaction.buy && transaction.buy.forEach(buyTransaction => instruments.push({
              ...buyTransaction,
              isin: buyTransaction.data && buyTransaction.data.isin
            }))
          })
          portfolio.transactions.savings_plan && portfolio.transactions.savings_plan.forEach(transaction => ['create', 'edit'].includes(transaction.action) && instruments.push({
            ...transaction,
            isin: transaction.data && transaction.data.isin
          }))
          portfolio.transactions.switch_plan && portfolio.transactions.switch_plan.forEach(transaction => {
            if (!['create', 'edit'].includes(transaction.action)) return;
            transaction.buy && transaction.buy.forEach(buyTransaction => instruments.push({
              ...buyTransaction,
              isin: buyTransaction.data && buyTransaction.data.isin
            }))
          })
        } else if (!_.get(portfolio, 'data.isPrivateInvestment')) {
          // TODO: NEED TO REFACTOR
          if (portfolio.transactions.buy) {
            portfolio.transactions.buy.forEach(pData => {
              pData.data && pData.data.components && pData.data.components.forEach(component => {
                instruments.push(component)
              })
            })
          }
        }
      })

      return instruments
    }

    return []
  }

  async __sendStepData() {
    // Protocol Step for Buy/Sell
    const isFinished = this._step && this._step.uid === 'finalize-documents-downloading';

    if(this._step && this._step.uid === 'finalize-conversation-details'){
      if (this._is_trading){
        await this.handleCreateOrderClick();
      }
    }

    return await super.__sendStepData(isFinished);
  }

  async __setQuestionnaire(onboardingAnswers) {
    await super.__setQuestionnaire(onboardingAnswers)

    let prevAnswers = this._getPrevStepAnswer('trade', 'trade-step', 'transactions');

    if (savingsPlansDeleteOnly(prevAnswers || []) && this.serviceConcept == 3) {
      this._questionnaire.steps = _.filter(
        this._questionnaire.steps, (step) => !['finalize-sells'].includes(step.uid))
    }

    if (this.isSellOnlyFlow) {
      this._questionnaire.steps = _.filter(
        this._questionnaire.steps, (step) => !['finalize-sells', 'finalize-risk'].includes(step.uid))
    }
  }
}
