import {CustomerResource} from "../../utils/api";
import {downloadPdf} from "../../utils/utils";

export const fetchDocuments = async (customer_id) => {
  try{
    return await CustomerResource.at(`${customer_id}/documents/`).get();
  } catch (error) {
    console.log(error);
    return [];
  }
};


export const downloadDocument = async (document_id, fileName) => {
  await downloadPdf(`/customer/document/${document_id}/`, fileName);
};