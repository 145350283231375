const styles = theme => ({
  text: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    textTransform: 'none',
    whiteSpace: 'nowrap',

    [theme.breakpoints.only('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: 12,
    },
  },
  icon: {
    marginRight: 8,
    marginLeft: 0,
    color: `${theme.palette.primary.main} !important`,
    fontSize: 16,
    position: 'relative',
    top: '0.15em',

    '& > svg': {
        fontSize: '1em'
    },
  },
  link: {
    color: `${theme.palette.primary.main} !important`,
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        cursor: 'pointer'
    }
  },
  disabled: {
    cursor: 'inherit',
    pointerEvents: 'none',
    opacity: 0.5,
  },
})

export default styles;