export default () => ({
  calendarQuestionContainer: {},
  inputRoot: {
    width: 80
  },
  labelRoot: {
    whiteSpace: 'nowrap',
    fontSize: 16
  },
  timePickerButtonRoot: {
    display: 'none'
  }
})