import { makeStyles } from '@material-ui/core';
import { FORM_FIELD_HEIGHT } from '../../../../TransactionsMonitoring/components/Filters/components/common_styles';

const styles = theme => ({
  root: {
    height: "100%",
  },
  thumb: {
    height: 24,
    width: 24,
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  inputRoot: {
    width: 95,
    height: FORM_FIELD_HEIGHT,
    marginLeft: 10
  }
});

export default styles;

export const useStyles = makeStyles(styles);