import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    padding: 30,
    background: 'white',
    boxShadow: '0px 3px 15px #39435233',
    position: 'relative',
    zIndex: 1
  }
})

export default makeStyles(styles)