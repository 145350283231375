import SingleReporting from '../SingleReporting';
import GroupReporting from '../GroupReporting';

export const SERIES_REPORTING_TAB_IDS = {
    SINGLE: 0,
    GROUP: 1,
};

export const SERIES_REPORTING_TABS = [
    {
        id: SERIES_REPORTING_TAB_IDS.SINGLE,
        component: SingleReporting,
        name: 'Einzelkundenreporting'
    },
    {
        id: SERIES_REPORTING_TAB_IDS.GROUP,
        component: GroupReporting,
        name: 'Kundengruppenreporting'
    }
];