import {makeStyles} from '@material-ui/core';

export default makeStyles(() => ({
  dialogPaper: {
    borderRadius: 10
  },
  dialogContent: {
    padding: '15px 50px 15px 40px',
    maxWidth: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > p' : {
      color: '#313D44',
      fontSize: 16,
      lineHeight: '22px'
    }
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#313D44'
  },
  checkIcon: {
    width: 32,
    height: 32
  },
  message: {
    marginLeft: 10
  }

}));