import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
  buttonProgress: {
    backgroundColor: `${theme.palette.primary.main}!important`,
    width: 212,
    height: 36,
  },
  iconProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));