const styles = theme => ({
  card: {
    height: '100%',
    background: '#F0F0F0',
    position: 'relative',
    boxShadow: '0px 3px 15px #00000033',
    borderRadius: 5,
    padding: 20,
    marginBottom: 20,
    '&:last-of-type': {
      marginBottom: 0
    }
  },
  title: {
    fontSize: 20,
    fontFamily: 'Roboto-Bold',
    color: '#80858C',
    '& sup': {
       fontSize: '10px',
    }
  },
  contentWrapper: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    font: 'Roboto-Regular',
    fontSize: 16,
    color: '#80858C',
    display: 'flex',
  }
})

export default styles;
