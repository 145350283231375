import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="24.609"
      viewBox="0 0 30 24.609"
    >
      <g id="Gruppe_7924" data-name="Gruppe 7924" clip-path="url(#clip-path)">
        <path
          id="Pfad_6688"
          data-name="Pfad 6688"
          d="M0,4.158a1.089,1.089,0,0,1,1-.664.837.837,0,0,0,.748-.781c.089-.712.369-.957,1.1-.957H26.484c0-.3,0-.566,0-.836a.879.879,0,1,1,1.755,0c.005.263,0,.526,0,.832.272,0,.531,0,.789,0A.887.887,0,0,1,30,2.722q0,2.548,0,5.1a.887.887,0,0,1-.964.968,5.655,5.655,0,0,1-.761-.006c-.294-.036-.425.092-.545.344-.4.845-.841,1.672-1.244,2.517a1.079,1.079,0,0,1-1.09.667c-3.066-.01-6.132-.005-9.2-.005H15.82v1.157c0,.469.005.938,0,1.406a2.651,2.651,0,0,1-2.733,2.709c-1.151.005-2.3,0-3.494,0-.053.4-.106.789-.157,1.181-.154,1.18-.284,2.364-.465,3.54a2.621,2.621,0,0,1-2.725,2.309q-1.626.007-3.251,0A2.634,2.634,0,0,1,.306,21.513c.467-3.323.949-6.643,1.427-9.964A.831.831,0,0,0,1,10.565,1.1,1.1,0,0,1,0,9.9ZM1.785,8.8l-.047.078a2.354,2.354,0,0,0,.256.161A2.6,2.6,0,0,1,3.5,11.512c-.265,1.962-.554,3.921-.833,5.882-.2,1.429-.414,2.857-.61,4.287-.106.776.247,1.167,1.026,1.168q1.567,0,3.134,0a.923.923,0,0,0,1.033-.884c.224-1.732.434-3.465.67-5.2a.772.772,0,0,0-.187-.633,3.283,3.283,0,0,1-.612-2.847,3.491,3.491,0,0,1,3.512-2.743q7.03-.005,14.06,0a1.339,1.339,0,0,0,.234,0c.059-.01.143-.035.166-.079.28-.542.55-1.088.838-1.663Zm7.352-1.79H28.218v-3.5H10.572a.377.377,0,0,0-.145,0c-.062.029-.146.07-.164.124-.378,1.115-.748,2.232-1.126,3.367M4.931,3.515c-.506,0-.981-.005-1.455.007-.06,0-.145.085-.174.15a2.655,2.655,0,0,1-1.4,1.39c-.059.026-.138.1-.139.152-.01.6-.006,1.2-.006,1.792a.712.712,0,0,0,.1.02l1.258,0c.647,0,.648,0,.848-.6.319-.959.638-1.919.968-2.912m3.518,0c-.5,0-.972.01-1.439-.005-.185-.006-.251.067-.3.231-.188.594-.392,1.183-.588,1.774-.165.5-.329.994-.5,1.516.52,0,1.007.007,1.494-.008a.3.3,0,0,0,.214-.166c.376-1.095.74-2.194,1.123-3.341M14.061,12.3c-.5,0-.98-.005-1.454.007a.277.277,0,0,0-.181.144,2.574,2.574,0,0,0,.631,3.1.5.5,0,0,0,.861-.167,1.044,1.044,0,0,0,.137-.468c.013-.857.006-1.713.006-2.616m-3.483,0a1.733,1.733,0,0,0-1.564.921,1.66,1.66,0,0,0,.113,1.851,1.857,1.857,0,0,0,1.86.723,4.444,4.444,0,0,1-.409-3.494"
          transform="translate(0 0)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
