const styles = theme => ({
  navigation: {
    '& h2': {
      fontFamily: 'Roboto-Bold',
      fontSize: 24,
      color: '#202A38',
      margin: '15px 0'
    }
  },
  whiteBox: {
    background: 'transparent linear-gradient(180deg, transparent 0%, transparent 54.5px, #FFF 54.5px, #FFF 100%) 0% 0% no-repeat padding-box'
  },
  link: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  backwardArrow: {
    '&:before': {
      content: "''",
      display: 'inline-block',
      width: '0.6em',
      height: '0.6em',
      border: `2px solid ${theme.palette.primary.main}`,
      borderBottom: 'none',
      borderRight: 'none',
      transform: 'rotate(-45deg)',
      marginRight: '0.3em'
    }
  },
  managerHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  managerControlsTitle: {
    fontSize: 20,
    fontFamily: 'Roboto-Bold'
  },
  filterField: {
    border: 'solid 1px red',
    width: '600px !important',
  },
  benchmarkButton: {
    marginLeft: 15,
    padding: '15px 25px'
  },
  backButton: {
    float: 'right',
    marginTop: 32
  },
  clear: {
    clear: 'both'
  },
  rightHeaderPart: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'row'
  },
  assetsCard: {
    minHeight: 200
  },
  tableAssets: {
    width: '100%',
    borderCollapse: 'collapse',
    '& td': {
      padding: 5,
      verticalAlign: 'top',
      textAlign: 'right',
      '&:first-child': {
        paddingLeft: 15
      }
    },
    '& tbody tr': {
      borderBottom: 'solid 1px #D8DCDF',
      color: '#202A38',
      '&:last-of-type': {
        borderBottom: 'none'
      },
      '& td': {
        fontSize: 14,
        fontFamily: 'Roboto-Regular',
      }
    }
  },
  assetName: {
    fontFamily: 'Roboto-Bold',
    fontWeight: 'normal',
    color: `${theme.palette.primary.main} !important`,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      background: 'none',
      textDecoration: 'underline !important'
    }
  },
  linkButton: {
    fontFamily: 'Roboto-Bold',
    fontWeight: 'normal',
    color: theme.palette.primary.main,
    textTransform: 'none',
    padding: 0,
    minWidth: 'auto',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline'
    }
  },
  disabledLink: {
    fontFamily: 'Roboto-Bold',
    fontWeight: 'normal',
    color: '#80858C',
    textTransform: 'none',
    padding: 0,
    minWidth: 'auto',
    '&:hover': {
      cursor: 'help'
    }
  },
  importedPortfolioIcon: {
    verticalAlign: 'middle',
    width: 15,
    marginLeft: 3,
    color: 'grey',
    transform: 'rotate(180deg)',
    '&:hover': {
      cursor: 'help'
    }
  },
  leftAligned: {
    textAlign: 'left !important'
  },
  tableHeader: {
    backgroundColor: '#F5F6F7',
    fontFamily: 'Roboto-Bold !important',
    fontSize: 14,
    color: '#80858C',
    '& td:first-of-type': {
      paddingLeft: 15
    },
    '& td:last-of-type': {
      paddingRight: 15
    }
  },
  emptyTableHeader: {
    color: '#ADB6BD'
  },
  headerSubtitle: {
    fontFamily: 'Roboto-Regular'
  },
  loadingLine: {
    width: '30%',
    fontSize: 24,
    lineHeight: '1.2em',
    backgroundColor: '#d5d6d7',
    backgroundImage: 'linear-gradient(to right, #d5d6d7 0%, #b8bcbd 20%, #d5d6d7 40%, #d5d6d7 100%)',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: '$placeholderShimmer',
    animationTimingFunction: 'linear',

    '& p': {
      display: 'inline-block',
      margin: 0
    },

    '&:nth-child(2n)': {
      width: '75%'
    }
  },

  '@keyframes placeholderShimmer': {
    from: {
      backgroundPosition: '-468px 0',
    },
    to: {
      backgroundPosition: '468px 0',
    }
  },

  profitPositive: {
    color:'#5EC14E'
  },
  profitNegative: {
    color: '#f00'
  },
  errorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5%'
  },
  warningText: {
    color: '#f00',
    fontSize: '1.25em',
    textAlign: 'center'
  },
  assetsSearchField: {
    width: 500
  },
  editNameButton: {
    padding: 0,
    minWidth: 'auto',
    verticalAlign: 'middle',
    marginLeft: 5,
    textTransform: 'capitalize',
    background: 'none',
    '&:hover': {
      fontFamily: 'Roboto-Bold',
      // color: theme.palette.primary.main,
      background: 'none'
    }
  },
  portfolioNameInput: {
    padding: 0,
    width: '30%',
    '& input': {
      padding: 5,
      background: 'white',
      borderRadius: 3
    }
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: 4
  },
  iconLabel: {
    verticalAlign: 'middle'
  },
  substring: {
    fontSize: 12,
    color: '#919191'
  }
})

export default styles;