import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import EsgScoreChart from "./items/EsgScoreChart";
import styles from "./styles";
import ESGChart from "./items/ESGChart";
import EcoChart from "./items/EcoChart";
import PaiChart from "./items/PaiChart";
import ExclusionCriteria from "./items/ExclusionCriteria";
import SDG from "./items/SDG";
import {ModelPortfolioResource} from "../../../../utils/api";
import useFetchData from "../../../../hooks/useDataFetch";
import _ from "lodash";
import {default as EsgScoreKeyFiguresChart} from "../KeyFiguresAndRatings/items/EsgScoreChart";
import { ESG_INFO_TEXT, OKO_INFO_TEXT, PAI_INFO_TEXT } from '../../constants';

export const getESGChartPropsFromAssetInfo = (assetInfo) => ({
  environmental: assetInfo.c_environmental_thematic,
  social: assetInfo.c_social_thematic,
  governance: assetInfo.c_governance_thematic
})

export const getEcologicalChartDataFromAssetInfo = (assetInfo) => ({
  mitigation: assetInfo.c_mitigation,
  adaptation: assetInfo.c_adaptation,
  pwm_resources: assetInfo.c_pwm_resources,
  economy: assetInfo.c_economy,
  pollution: assetInfo.c_pollution,
  prb_ecosystems: assetInfo.c_prb_ecosystems,
});

export const getPAIChartDataFromAssetInfo = (assetInfo) => ({
  ecin_l: assetInfo.c_ecin_l,
  biodiversity: assetInfo.c_biodiversity,
  water_emissions: assetInfo.c_water_emissions,
  hazardous_waste_ratio: assetInfo.c_hazardous_waste_ratio,
  contro_weapons: assetInfo.c_contro_weapons,
  perc_ocsts_violations: assetInfo.c_perc_ocsts_violations,
  eei_real_estate_assets: assetInfo.c_eei_real_estate_assets,
});

const getExclusionCriteriaChartDataFromAssetInfo = (assetInfo) => ({
  unconv_weapons_excl: assetInfo.c_unconv_weapons_excl,
  nucl_weapons_excl: assetInfo.c_nucl_weapons_excl,
  conv_weapons_excl: assetInfo.c_conv_weapons_excl,

  animal_testing_excl: assetInfo.c_animal_testing_excl,
  fur_excl: assetInfo.c_fur_excl,

  porno_ent_excl: assetInfo.c_porno_ent_excl,
  gambling_excl: assetInfo.c_gambling_excl,
  research_on_human_embryos_excl: assetInfo.c_research_on_human_embryos_excl,

  coal_excl: assetInfo.c_coal_excl,
  gas_excl: assetInfo.c_gas_excl,
  gas_new_struct_excl: assetInfo.c_gas_new_struct_excl,
  nucl_energy_excl: assetInfo.c_nucl_energy_excl,
  oil_excl: assetInfo.c_oil_excl,
  fuel_new_struct_excl: assetInfo.c_fuel_new_struct_excl,
  fuel_excl: assetInfo.c_fuel_excl,

  factory_farm_excl: assetInfo.c_factory_farm_excl,
  gen_engin_excl: assetInfo.c_gen_engin_excl,
  gmo_excl: assetInfo.c_gmo_excl,
  palm_oil_excl: assetInfo.c_palm_oil_excl,
  pesticides_excl: assetInfo.c_pesticides_excl,

  alco_excl: assetInfo.c_alco_excl,
  cannabis_excl: assetInfo.c_cannabis_excl,
  tobacco_excl: assetInfo.c_tobacco_excl,

  compact_excl: assetInfo.c_compact_excl,
  oecd_guidelines_excl: assetInfo.c_oecd_guidelines_excl,
  inter_labour_org_excl: assetInfo.c_inter_labour_org_excl,

  nucl_weapons_excl_sh: assetInfo.c_nucl_weapons_excl_sh,
  non_pt_of_nucl_weapons_ex_sh: assetInfo.c_non_pt_of_nucl_weapons_ex_sh,
  qualitative_ex_sh: assetInfo.c_qualitative_ex_sh,
  corruption_ex_sh: assetInfo.c_corruption_ex_sh,
  death_sentence_ex_sh: assetInfo.c_death_sentence_ex_sh,
  war_ex_sh: assetInfo.c_war_ex_sh,
  non_ratific_of_paris_agreement_ex_sh: assetInfo.c_non_ratific_of_paris_agreement_ex_sh
})

const SDG_KOEF = 1;

const getSDGValue = (assetInfo, index) => {
  const value = _.get(assetInfo, `c_sdg_${index}`);
  if (_.isNil(value)) {
    return undefined
  }

  return value > SDG_KOEF;
}

const getSDGChartDataFromAssetInfo = (assetInfo) => ({
  sdg_2: getSDGValue(assetInfo, 2),
  sdg_3: getSDGValue(assetInfo, 3),
  sdg_6: getSDGValue(assetInfo, 6),
  sdg_4: getSDGValue(assetInfo, 4),
  sdg_7: getSDGValue(assetInfo, 7),
  sdg_9: getSDGValue(assetInfo, 9),
  sdg_10: getSDGValue(assetInfo, 10),
  sdg_11: getSDGValue(assetInfo, 11),
  sdg_13: getSDGValue(assetInfo, 13),
  sdg_12: getSDGValue(assetInfo, 12),
  sdg_14: getSDGValue(assetInfo, 14),
  sdg_15: getSDGValue(assetInfo, 15),
});

function SustainabilityTab(props) {

  const [assetInfoData, fetchAssetInfoData] = useFetchData(
    `${ModelPortfolioResource.resourceUrl}${ModelPortfolioResource.POST_ASSET_INFO_URL}`, 'post', undefined, true);

  React.useEffect(() => {
    fetchAssetInfoData({isins: [props.data.isin]})
  }, []);

  const assetInfo = (() => {
    if (assetInfoData.loading || !!assetInfoData.errors || !assetInfoData.data || !props.data) {
      return {};
    }

    return {
      ...props.data,
      ...assetInfoData.data[0]
    };
  })();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <EsgScoreChart assetInfo={assetInfo} loading={assetInfoData.loading} />
      </Grid>
      <Grid item xs={12}>
        <ESGChart
          title="ESG Übersicht"
          tooltip={ESG_INFO_TEXT}
          loading={assetInfoData.loading}
          {...getESGChartPropsFromAssetInfo(assetInfo)}
        />
      </Grid>
      <Grid item xs={6}>
        <EcoChart
          title="Ökologisch nachhaltig"
          tooltip={OKO_INFO_TEXT}
          data={getEcologicalChartDataFromAssetInfo(assetInfo)}
          loading={assetInfoData.loading}
        />
      </Grid>
      <Grid item xs={6}>
        <PaiChart
          title="PAI – Nachhaltigkeitsfaktoren"
          tooltip={PAI_INFO_TEXT}
          loading={assetInfoData.loading}
          data={getPAIChartDataFromAssetInfo(assetInfo)}
        />
      </Grid>
      <Grid item xs={12}>
        <SDG
          title="SDG – Ziele für nachhaltige Entwicklung"
          tooltip={
            <>
              Sustainable Development Goals - In der Agenda 2030 festgehaltene
              17 Ziele für nachhaltige Entwicklung, zum Klimaschutz, zu fairen
              Standards in der Arbeitswelt und gegen Hunger und Armut
            </>
          }
          loading={assetInfoData.loading}
          data={getSDGChartDataFromAssetInfo(assetInfo)}
          assetName={assetInfo.name}
        />
      </Grid>
      <Grid item xs={12}>
        <ExclusionCriteria
          title="Ausschlusskriterien"
          tooltip={
            <>
              Ausschlusskriterien - Anlagen oder Kriterien, die gemäß
              Anlagerichtlinien ausgeschlossen sind
            </>
          }
          loading={assetInfoData.loading}
          data={getExclusionCriteriaChartDataFromAssetInfo(assetInfo)}
          assetName={assetInfo.name}
        />
      </Grid>
      <Grid item xs={12}>
        <EsgScoreKeyFiguresChart
          assetInfo={props.data}
          loading={assetInfoData.loading}
        />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(SustainabilityTab);
