import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    display: 'flex'
  },
  item: {
    boxSizing: 'border-box',
    background: 'white',
    padding: '14px 30px',
    borderRight: '1px solid #D8DCDF',
    position: 'relative',
    zIndex: 1,
    '&:hover': {
      cursor: 'pointer'
    },

    '& > span': {
      fontFamily: 'Roboto-Regular',
      fontSize: 16,
      color: '#202A38'
    }
  },

  active: {
    '& > span': {
      fontFamily: 'Roboto-Bold',
    },
    zIndex: 2,
    '&:after': {
      content: '""',
      position: 'absolute',
      background: theme.palette.primary.main,
      width: 30,
      height: 2,
      left: 'calc((100% - 30px) / 2)',
      bottom: 5
    }
  }
})

export default makeStyles(styles)