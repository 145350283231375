import { makeStyles } from "@material-ui/core";

const styles = (theme) => ({
  itemTitle: {
    display: 'flex',
    alignItems: 'center',

    '& > span': {
      fontFamily: 'Roboto-Bold',
      fontSize: 16,
      marginRight: 15
    }
  },
  inputLabelRoot: {
    fontSize: 14
  },
  selectLabelRoot: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    marginRight: 15,
  },
  error: {
    color: 'red'
  },
  riskIndicatorDropdown: {
    width: '50%',
    [theme.breakpoints.down(670)]: {
      width: '100%',
    },
    color: 'white',
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      cursor: 'pointer'
    },
    backgroundColor: 'rgb(177, 177, 177)'
  },
  riskIndicatorDropdownPrimary: {
    backgroundColor: theme.palette.primary.main
  },
  riskIndicatorContainer: {
    marginBottom: 10
  },
  tableCell: ({withProductNameColumn, withCurrentValueColumn}) => {
    return withProductNameColumn
      ? {
        '&:nth-of-type(1)': {
          width: 40,
        },
        '&:nth-of-type(3)': {
          width: 220,
        },
        '&:nth-of-type(4)': {
          width: 150,
        },
        '&:last-child': {
          width: 80,
        },
        [theme.breakpoints.down('sm')]: {
          '&:nth-of-type(2)': {
            width: 250,
          },
          '&:nth-of-type(3)': {
            width: 180,
          },
          '&:nth-of-type(4)': {
            width: 100,
          },
          '&:nth-last-of-type(3)': {
            width: 160,
          },
          '&:nth-last-of-type(2)': {
            width: 250,
          },
        }
      }
      : {
        '&:nth-of-type(1)': {
          width: 40,
        },
        '&:nth-of-type(2)': {
          width: 220,
        },
        '&:nth-of-type(3)': {
          width: 150,
        },
        '&:last-child': {
          width: 80,
        },
        [theme.breakpoints.down('sm')]: {
          '&:nth-of-type(2)': {
            width: 180,
          },
          '&:nth-of-type(3)': {
            width: 100,
          },
          '&:nth-last-of-type(3)': {
            width: 160,
          },
          '&:nth-last-of-type(2)': {
            width: 250,
          },
        }
      }
  },
  dialogActions: {
    padding: '16px 24px',

    '& > :not(:first-child)': {
      marginLeft: 16
    },

    [theme.breakpoints.down('xs')]: {
      padding: 8
    },

    [theme.breakpoints.down(490)]: {
      '& > :not(:first-child)': {
        marginLeft: 5
      },
    },
    [theme.breakpoints.down(410)]: {
      display: 'flex',
      flexDirection: 'column',
      '& > :not(:first-child)': {
        marginLeft: 0,
        marginTop: 5
      },
      '& > button': {
        width: '100%'
      }
    }
  },
  dialogCloseButton: {
    position: 'absolute',
    right: 8,
    top: 9,
    [theme.breakpoints.down('xs')]: {
      top: -2,
      right: 0
    }
  },
  dialogTitleRoot: {
    [theme.breakpoints.down('xs')]: {
      padding: 8
    },
    '& > h2': {
      display: 'block',
      width: 'calc(100% - 20px)',
      [theme.breakpoints.down('xs')]: {
        fontSize: 18
      }
    }
  },
  dialogContentRoot: {
    [theme.breakpoints.down('xs')]: {
      padding: 8
    },
  },
  riskIndicatorTable: {
    overflowX: 'auto'
  }
})

export default makeStyles(styles)