import React, { useState } from 'react';
import _ from "lodash";

/* Material-UI Components */
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {withStyles} from "@material-ui/core/styles";


/* BCA modules */
import styles from './styles';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import clsx from "clsx";
import SelectCaret from '../Icons/SelectCaret';

const ListLinksDropDown = (props) => {
  const {
    groups,
    classes,
    theme,
    loading,
    onLinkSelect,
    selectedGroupName
  } = props;

  const [expanded, setExpanded] = useState(props.expanded || false);

  const onSelectItem = (group) => {
    if(onLinkSelect){
      onLinkSelect(group)
    }

    setExpanded(false);
  };

  const renderListItem = (group) => (
    <ListItem
      key={group.id}
      className={classes.portfolioListItem}
      onClick={() => onSelectItem(group)}
    >
     <span className={classes.ellipsis}>{group.name}</span>
    </ListItem>
  );

  return (
    <ClickAwayListener onClickAway={() => setExpanded(false)}>
      <div className={classes.expansionPannelContainer}>
        <ExpansionPanel
          id="groups-list"
          TransitionProps={{timeout: 0}}
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
          classes={{
            root: classes.expansionPanelRoot,
            expanded: classes.expansionPanelExpanded
          }}
          disabled={loading}
        >
          <ExpansionPanelSummary
            expandIcon={<SelectCaret style={{color: theme.palette.primary.main}} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            IconButtonProps={{
              disableRipple: true
            }}
            classes={{
              root: classes.expansionPanelSummaryRoot,
              expanded: classes.expansionPanelSummaryExpanded,
              content: classes.expansionPanelSummaryContent
            }}
          >
            <div className={clsx(classes.ellipsis, classes.nowrap)}>
              {selectedGroupName || 'Reporting Gruppen'}
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.scrollableList} classes={{
            root: classes.expansionPanelDetailsRoot,
          }}>
            {!_.isEmpty(groups) &&
              <List className={classes.portfolioList}>
                {groups.map(renderListItem)}
              </List>
            }
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </ClickAwayListener>
  )
};

export default withStyles(styles, {withTheme: true})(ListLinksDropDown)