import React from "react";
import _ from "lodash";
import {useSharedDataContext} from "../utils";


export function useBasicPortfolioData(customerId, portfolio, dataProvider) {

  const dataContext = useSharedDataContext();

  const fetchBasicPortfolioData = () => {
    if(dataContext.isPortfolioDataLoading(portfolio.depotNumber)){
      return;
    }

    dataContext.setPortfolioDataLoading(portfolio.depotNumber, true);
    dataContext.setPortfolioData(portfolio.depotNumber,{
      basicPortfolioData: null,
      basicPortfolioLoading: true,
      basicPortfolioError: null,
      updatedAt: +new Date(),
    });

    dataProvider.getBasicPortfolioData(customerId, portfolio.depotNumberForAssets || portfolio.depotNumber)
      .then((response) => {
        response.portfolio_data.data[0].components = _.orderBy(response.portfolio_data.data[0].components, ['weight'], 'desc')
        if(!!portfolio){
          // portfolio name is changed to display name instead of id
          response.portfolio_data.data[0].name = portfolio.name;
        }
        dataContext.setPortfolioData(portfolio.depotNumber, {
          basicPortfolioData: response,
          basicPortfolioLoading: false,
          basicPortfolioError: null,
          updatedAt: +new Date()
        })
      })
      .catch((error) => {
        dataContext.setPortfolioData(portfolio.depotNumber, {
          basicPortfolioData: null,
          basicPortfolioLoading: false,
          basicPortfolioError: error,
          updatedAt: +new Date()
        })
      })
      .finally(() => {
        dataContext.setPortfolioDataLoading(portfolio.depotNumber, false);
      })
  };

  React.useEffect(() => {
    if (!!customerId && !!portfolio) {
      fetchBasicPortfolioData();
    }
  }, [customerId, _.get(portfolio, 'depotNumberForAssets'), _.get(portfolio, 'depotNumber')]);

  return {
    basicPortfolioData: dataContext.getPortfolioData(portfolio.depotNumber)
  }

}