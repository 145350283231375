import React from 'react'

import {
    CircularProgress,
    Box,
    Typography,
    Backdrop,
    Modal,
    Fade,
    DialogContent
} from '@material-ui/core';
import { SuccessIcon } from '../../../../images'

import useStyles from './styles';

export default function ModalLoading (props) {
    const classes = useStyles()
    
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            {...props}
        >
        <Fade in={props.open}>
            <DialogContent>
                <Box 
                    display="inline-flex"
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    {props.value == 0 && (
                        <>
                            <CircularProgress {...props} />
                            {props.sign && (
                                <Typography variant="caption" component="div" className={classes.signProgressText}>
                                    {props.text}
                                </Typography>
                            )}
                        </>
                    )}
                    {props.value > 0 && 
                        <>
                            {props.value < 100 && <CircularProgress variant="static" {...props} />}
                            {!!props.annotationloaded && <CircularProgress {...props} />}
                            <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography variant="caption" component="div" className={classes.progressText}>
                                    {!props.annotationloaded && props.value < 100 && `${Math.round(props.value)}%`}
                                    {!!props.annotationloaded && <SuccessIcon/>}
                                </Typography>
                            </Box>
                        </>
                    }
                </Box>
            </DialogContent>
        </Fade>
        </Modal>
    );
}