import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import _ from 'lodash';
import connect from "react-redux/es/connect/connect";

import { Container, Grid, withStyles, withWidth } from '@material-ui/core';

import Filters from './components/Filters';
import CustomersList from './components/CustomersList';
import GroupDetails from './components/GroupDetails';
import SaveGroupModal from './components/SaveGroupModal';

import styles from './styles';
import { getSearchCustomerType } from '../../components/FilteringPanel/components/ListSelector/constants';
import { GroupResource } from '../../utils/api';
import {
  paginateArray,
  UserUtils
} from '../../utils/utils';
import { ACTION_TYPE } from './components/CustomersList/CustomersList';
import {REPORT_DISTRIBUTION_TYPE} from "./constants";
import {GuideTour} from "../../components/GuideTour";
import {setSaveModal, setSelectedCustomers, setMarkedCustomers} from "./actions";
import {ROUTES} from "../../routes";
import {getReportSettingsGuideSteps} from "../DashboardSettings/guide";
import {DEFAULT_REPORT_TYPE} from "../DashboardSettings/components/CustomersList/components/ReportType/constants";
import {
  DEFAULT_SENDING_DATE
} from "../DashboardSettings/components/CustomersList/components/ReportSendingDate/constants";

const mapStateToProps = (state) => ({
  selectedCustomers: state.get('createGroup').toJS().selectedCustomers,
  markedCustomers: state.get('createGroup').toJS().markedCustomers,
  isModalVisible: state.get('createGroup').toJS().isModalVisible,
  auth: state.get('auth').toJS(),
});

const mapDispatchToProps = dispatch => ({
  setSelectedCustomers: (selectedCustomers) => dispatch(setSelectedCustomers(selectedCustomers)),
  setMarkedCustomers: (markedCustomers) => dispatch(setMarkedCustomers(markedCustomers)),
  setSaveModal: (isModalVisible) => dispatch(setSaveModal(isModalVisible)),
});

class GroupCreation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers:undefined,
      loading: true,

      filters: {
        customerName: undefined,
        customerType: getSearchCustomerType(props.auth),
        reportDistributionType: REPORT_DISTRIBUTION_TYPE.PDF,
        lastReportSentDate: undefined,
        productTypes: [],
        assetClasses: [],
        aggregatedRange: [undefined, undefined],
      },
      filtersErrors: undefined,

      groupName: undefined,
      groupReportType: DEFAULT_REPORT_TYPE.value,
      groupDateRange: DEFAULT_SENDING_DATE.value,

      isSaving: false,
      savingErrors: {}
    };

    this.handleFiltersUpdated = this.handleFiltersUpdated.bind(this);
    this.fetchCustomers = this.fetchCustomers.bind(this);
    this.handleCustomerSelected = this.handleCustomerSelected.bind(this);
    this.handleAllSelected = this.handleAllSelected.bind(this);
    this.displayModal = this.displayModal.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleGroupValueChanged = this.handleGroupValueChanged.bind(this);
    this.handleSaveModalClick = this.handleSaveModalClick.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.onApplyButtonClicked = this.onApplyButtonClicked.bind(this);
    this.validateAggregatedRange = this.validateAggregatedRange.bind(this);
    this.handleCustomersMarked = this.handleCustomersMarked.bind(this);
    this.handleCustomerUnmarked = this.handleCustomerUnmarked.bind(this);
  }

  componentDidMount() {
    this.fetchCustomers();
  }

  componentDidUpdate(_, prevState) {
    const isSearchUpdated = this.state.filters.customerName !== prevState.filters.customerName;
    const isDistributionTypeUpdated = this.state.filters.reportDistributionType.value !== prevState.filters.reportDistributionType.value;
    const isCustomerTypeUpdated = this.state.filters.customerType.value !== prevState.filters.customerType.value;

    const isMinInvestedValueUpdated = (this.state.filters.aggregatedRange && this.state.filters.aggregatedRange[0]) 
      !== (prevState.filters.aggregatedRange && prevState.filters.aggregatedRange[0]);
    const isMaxInvestedValueUpdated = (this.state.filters.aggregatedRange && this.state.filters.aggregatedRange[1]) 
      !== (prevState.filters.aggregatedRange && prevState.filters.aggregatedRange[1])


    if (isSearchUpdated || isDistributionTypeUpdated || isCustomerTypeUpdated) {
      this.fetchCustomers();
    }

    if (isMinInvestedValueUpdated || isMaxInvestedValueUpdated) {
      this.validateAggregatedRange();
    }
  }

  validateAggregatedRange() {
    let [aggregatedMinValue, aggregatedMaxValue] = this.state.filters.aggregatedRange;

    this.setState({
      filtersErrors: undefined
    });

    if (aggregatedMinValue && aggregatedMaxValue && aggregatedMinValue > aggregatedMaxValue) {
      this.setState({
        filtersErrors: {
          aggregatedRange: [true, undefined]
        }
      });
    }
  }

  handleFiltersUpdated(fieldName, value) {
    if (this.state.filters.hasOwnProperty(fieldName)) {
      this.setState({
        filters: {
          ...this.state.filters,
          [fieldName]: value
        }
      });
    }
  }

  handleCustomersMarked() {
     this.props.setMarkedCustomers(this.props.selectedCustomers);
  }

  handleCustomerSelected(customer) {
    let selectedCustomers = [...this.props.selectedCustomers];

    let isCustomerSelected = _.find(selectedCustomers, item => item.customer_id === customer.customer_id);

    if (isCustomerSelected) {
      selectedCustomers = _.filter(selectedCustomers, item => item.customer_id !== customer.customer_id);
    } else {
      selectedCustomers.push(customer);
    }

    this.props.setSelectedCustomers(selectedCustomers);
  }

  handleCustomerUnmarked(customerUnmarked) {
     let customers = [...this.props.markedCustomers];

     let filteredCustomers = _.filter(customers, customer => customer.id != customerUnmarked.id);

     this.props.setMarkedCustomers(filteredCustomers);
  }

  handleAllSelected() {
    let allCustomers = Array.prototype.concat.apply([], this.state.customers);
    if (this.props.selectedCustomers.length === allCustomers.length) {
      this.props.setSelectedCustomers([]);
    } else {
      this.props.setSelectedCustomers(allCustomers);
    }
  }

  displayModal() {
    this.props.setSaveModal(true);
  }

  handleModalClose() {
    this.props.setSaveModal(false);
  }

  handleGroupValueChanged(fieldname, value) {
    this.setState({
      [fieldname]: value
    });
  }

  async handleSaveModalClick() {
    let body = {
      report_type: this.state.groupReportType,
      distribution_type: this.state.filters.reportDistributionType.value,
      date_range: this.state.groupDateRange,
      name: this.state.groupName,
      customers: this.props.selectedCustomers.map(customer => customer.id)
    }

    this.setState({
      isSaving: true,
      savingErrors: {}
    });

    try {
      await GroupResource.at('').post(body);
      this.setState({
        savingErrors: {},
        isSaving: false
      });
      this.props.setSaveModal(false);
      window.location.href = ROUTES.BROKER.REPORT_SETTINGS.path;
    } catch (error) {
      this.setState({
        isSaving: false,
        savingErrors: error
      });
    }
  }

  getFilters() {
    let filters = {};

    if (!_.isNil(this.state.filters.customerName) && this.state.filters.customerName.length > 0) {
      filters["customerName"] = this.state.filters.customerName;
    }

    if (!_.isNil(this.state.filters.aggregatedRange[0])) {
      filters["totalMin"] = this.state.filters.aggregatedRange[0]
    }

    if (!_.isNil(this.state.filters.aggregatedRange[1])) {
      filters["totalMax"] = this.state.filters.aggregatedRange[1]
    }

    if (!_.isNil(this.state.filters.productTypes)) {
      filters["group_identifier_code"] = this.state.filters.productTypes && this.state.filters.productTypes.map((type) => type.value);
    }

    if (!_.isNil(this.state.filters.assetClasses)) {

      const FOND_SUB_CLASS = 601;

      const assetClasses = _.filter(this.state.filters.assetClasses, (item) => item.value != FOND_SUB_CLASS);
      const assetSubClasses = _.filter(this.state.filters.assetClasses, (item) => item.value == FOND_SUB_CLASS);

      if (!_.isNil(assetClasses)) {
        filters["financial_information_classes"] = assetClasses && assetClasses.map((type) => type.value);
      }

      if (!_.isNil(assetClasses)) {
        filters["financial_information_subclass"] = assetSubClasses && assetSubClasses.map((type) => type.value);
      }
    }

    filters["distribution_type"] = this.state.filters.reportDistributionType.value;
    filters["customer_type"] = this.state.filters.customerType.value;

    return filters;
  }

  async fetchCustomers() {
    this.setState({
      customers: undefined,
      loading: true
    });

    try {
      let response = await GroupResource.at('customers/').get(this.getFilters());
      this.setState({
        customers: [...paginateArray(response, 12)],
        loading: false,
      });
      const selectedCustomers = this.props.selectedCustomers.filter(customer => {
        return response.some(entity => entity.customer_id === customer.customer_id);
      });
      this.props.setSelectedCustomers(selectedCustomers);
    } catch (error) {
      this.setState({
        customers: undefined,
        loading: false
      });
    }
  }

  onApplyButtonClicked = () => {
    this.fetchCustomers();
  }

  render() {
    const {
      classes,
      width,
      history
    } = this.props;

    return (
      <>
        <Container className="app-page-container">
          <GuideTour
            steps={getReportSettingsGuideSteps(width, history)}
            title="Einstellungen Info-Tour"
          />
          <Grid container>
            <Grid item lg={3} md={12} sm={12}>
              <h1 style={{
                fontFamily: 'Roboto-Regular',
                color: '#626970',
                fontSize: 34,
                fontWeight: 'normal',
                margin: 0,
                marginBottom: 10
              }}>Gruppe erstellen</h1>
              <Filters
                filters={this.state.filters}
                handleFiltersUpdated={this.handleFiltersUpdated}
                isChief={UserUtils.isChief(this.props.auth)}
                handleApplyButtonClicked={this.onApplyButtonClicked}
                errors={this.state.filtersErrors}
              />
            </Grid>
            <Grid
              item
              lg={9} md={12} sm={12}
              className={classes.customerListSection}
              id="report-settings-create-group-client-list-tour-element"
            >
              <Link 
                to={ROUTES.BROKER.REPORT_SETTINGS.path} 
                className={classes.backLink}
              >
                <i className="chevron-icon fa fa-chevron-left" /> 
                Zurück zur Gruppenliste
              </Link>
              <Grid container>
                <Grid item lg={8}>
                  <CustomersList
                    customers={this.state.customers}
                    loading={this.state.loading}
                    selected={this.props.selectedCustomers}
                    handleCustomerSelected={this.handleCustomerSelected}
                    handleAllSelected={this.handleAllSelected}
                    actionType={ACTION_TYPE.CREATE}
                    onCustomersMark={this.handleCustomersMarked}
                  />
                </Grid>
                <Grid item lg={4}>
                  <GroupDetails
                     selectedCustomers={this.props.markedCustomers}
                     onCustomerRemove={this.handleCustomerUnmarked}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SaveGroupModal
            open={this.props.isModalVisible}
            handleClose={this.handleModalClose}
            name={this.state.groupName}
            reportType={this.state.groupReportType}
            distributionType={this.state.filters.reportDistributionType.value}
            dateRange={this.state.groupDateRange}
            handleValueChanged={this.handleGroupValueChanged}
            handleSaveClick={this.handleSaveModalClick}
            isSaving={this.state.isSaving}
            errors={this.state.savingErrors}
          />
        </Container>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(withWidth()(GroupCreation))));