import { makeStyles } from "@material-ui/core/styles";
import { fontSize, smFontSize, xsFontSize } from '../../../../utils/constants';

export default makeStyles((theme) => ({
  filtersLabel: {
    fontSize: '18px',
    fontFamily: 'Roboto-Bold',
    margin: 'auto auto auto 20px',

    [theme.breakpoints.only("sm")]: {
      margin: 'auto auto auto 10px',
    },

    [theme.breakpoints.only("xs")]: {
      margin: 'auto 0',
    }
  },

  paperRoot: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    boxShadow: '0px 3px 15px #39435233',
    // overflow: 'hidden',
    background: 'white'
  },

  accordionRoot: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    '&.inline': {
      border: '1px solid #D9DCDF',
      borderRadius: 8,
    }
  },

  accordionDetailsRoot: {
    padding: '30px !important',
    fontSize: `${fontSize} !important`,
    color: '#202A38 !important',
    textTransform: 'none !important',
    background: 'transparent !important',
    opacity: 1,

    [theme.breakpoints.only("sm")]: {
      fontSize: `${smFontSize} !important`,
      padding: '20px !important',
    },

    [theme.breakpoints.only("xs")]: {
      fontSize: `${xsFontSize} !important`,
      padding: '10px !important',
    }
  },

  accButtonIconEdgeEnd: {
    marginRight: '0 !important'
  },

  accordionSummaryRoot: {
    cursor: 'default',
    minHeight: 48,
    padding: '10px !important',

    '&:hover:not(.Mui-disabled)': {
      cursor: 'default',
    },

    [theme.breakpoints.only("xs")]: {
      '& .MuiTabs-scrollButtonsDesktop:not(.Mui-disabled)': {
        display: 'inline-flex !important',
        width: 30
      }
    }
  },

  accordionSummaryContent: {
    margin: 0,

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 48px)',
    }
  },

  accordionSummaryExpanded: {
    minHeight: '48px !important',
    margin: '0 0 0 0 !important',
  },
}))