import React from 'react';
import connect from "react-redux/lib/connect/connect";
import CustomersTabContent from "./components/CustomersTabContent";

import {
  Container,
  withWidth,
} from '@material-ui/core';
import {CLIENT_FILTERS, getSearchCustomerType} from "../../components/FilteringPanel/components/ListSelector/constants";
import {PRODUCT_TYPES_WITH_ALL_OPTION} from "../ReportGroupCreation/constants";
import {
  resetReportingStore, setEditorVariables,
  setReportingCustomers,
  setReportingCustomersLoading
} from "./actions";
import _ from "lodash";
import {CustomerReportSettingResource, SharedSettingsResource} from "../../utils/api";
import {ERROR_MODAL_MESSAGE} from "../../utils/constants";
import {Route, Switch} from "react-router";
import {ROUTES} from "../../routes";
import {executeIfPathExist, getInvestmentDynamicPath} from "../InvestmentPlatform/utils";
import ReportSettingsTabContent from "./components/ReportSettingsTabContent";
import AutoReportSettingsTabContent from "./components/AutoReportSettingsTabContent";
import ErrorModal from "../../components/ErrorModal";
import useStyles from './styles';

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
  auth: state.get('auth').toJS(),
});

export default withWidth()(connect(mapStateToProps)((props) => {
  const {
    dispatch,
    investmentPlatform,
    auth,
    parentComponent='SERIES_REPORTING'
  } = props;
  const classes = useStyles();

  const [customerRequestFilter, setCustomerRequestFilter] = React.useState({
  customerType: getSearchCustomerType(auth),
  productType: PRODUCT_TYPES_WITH_ALL_OPTION.ALL
  });
  const [isMounted, setIsMounted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(undefined);

  const [investmentDynamicPath, setInvestmentDynamicPath] = React.useState(getInvestmentDynamicPath())
  const [dynamicRoutes, setDynamicRoutes] = React.useState(null);
  React.useEffect(() => {
    if (props.investmentPlatform.routes) {
      let newPath = '/' + investmentDynamicPath

      executeIfPathExist(props.investmentPlatform.routes, parentComponent, (path) => {
        let url = newPath + path;
        setDynamicRoutes({
          customers: url,
          customerReport: url + ROUTES.BROKER.SERIES_REPORTING.children.SINGLE_CUSTOMER.children.REPORT.newDesignPath,
          customerAutoReport: url + ROUTES.BROKER.SERIES_REPORTING.children.SINGLE_CUSTOMER.children.AUTO_REPORT.newDesignPath,
        })

      })
    }
  }, [investmentPlatform.routes])


  React.useEffect(() => {
    if (isMounted){
      fetchCustomers();
    }

  }, [customerRequestFilter]);

  const getCustomerFilters = () => {
    let filters = {};

    if (!_.isNil(customerRequestFilter.customerType.value)) {
      filters['customer_type'] = customerRequestFilter.customerType.value
      if (customerRequestFilter.requestType == CLIENT_FILTERS.SUB_AGENCY) {
        filters.sub_agency = customerRequestFilter.customerType.value
        filters.customer_type = customerRequestFilter.requestType
      } else if (customerRequestFilter.requestType == CLIENT_FILTERS.SUB_BROKER) {
        filters.sub_broker = customerRequestFilter.customerType.value
        filters.customer_type = customerRequestFilter.requestType
      }
    }

    if (!_.isNil(customerRequestFilter.productType.value)) {
      filters['group_identifier_code'] = customerRequestFilter.productType.value
    }

    return filters;
  };

  React.useEffect(() => {
    onInitComponent();
    return () => {
      onDestroyComponent();
    }
  }, []);

  const onInitComponent = () => {
    fetchCustomers();
    fetchVariables();
    setIsMounted(true);
  };

  const onDestroyComponent = () => {
    dispatch(resetReportingStore());
  };

  const fetchCustomers = async () => {
      try {
        dispatch(setReportingCustomersLoading(true));
        const response = await CustomerReportSettingResource.at('customer/settings/').get(getCustomerFilters());
        dispatch(setReportingCustomers(response || []));
        dispatch(setReportingCustomersLoading(false));
        setErrorMessage(undefined);
      } catch (err) {
        dispatch(setReportingCustomersLoading(false));
        setErrorMessage(ERROR_MODAL_MESSAGE);
      }
  };

  const fetchVariables = async () => {
    try{
      const response = await SharedSettingsResource.at('editor-variables/').get();
      dispatch(setEditorVariables(response));
      window.editorVariables = response;
    } catch (e) {
      dispatch(setEditorVariables(undefined));
      window.editorVariables = undefined;
    }
  };

  const getCustomersTabContent = () => {
    return (
      <CustomersTabContent
        customerRequestFilter={customerRequestFilter}
        setCustomerRequestFilter={setCustomerRequestFilter}
        parentComponent={parentComponent}
      />
    )
  }

  const getReportSettingsTabContent = () => {
    return (
      <ReportSettingsTabContent newDesign={props.newDesign}/>
    )
  }

  const getAutoReportSettingsTabContent = () => {
    return (
      <AutoReportSettingsTabContent newDesign={props.newDesign}/>
    )
  }

  const handleErrorModalClose = () => {
    setErrorMessage(undefined);
  };

  return (
    <React.Fragment>
      <Container className={'app-page-container'}>

        <Switch>
          {dynamicRoutes && (
            <>
              <Route exact path={dynamicRoutes.customers} render={getCustomersTabContent}/>
              <Route exact path={dynamicRoutes.customerReport} render={getReportSettingsTabContent}/>
              <Route exact path={dynamicRoutes.customerAutoReport} render={getAutoReportSettingsTabContent}/>
            </>
          )}
        </Switch>
      </Container>

      <ErrorModal message={errorMessage} handleClose={handleErrorModalClose}/>
    </React.Fragment>
  )
}));