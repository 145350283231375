import React from 'react';
import _ from 'lodash'
import useStyles from './styles';
import {CircularProgress, Container, Grid} from "@material-ui/core";
import CustomersTabContent, {
  ShowReportingCustomersTabData
} from "../../../SingleReporting/components/CustomersTabContent";
import {connect} from "react-redux";
import {
  CLIENT_FILTERS,
  getSearchCustomerType
} from "../../../../components/FilteringPanel/components/ListSelector/constants";
import {PRODUCT_TYPES_WITH_ALL_OPTION} from "../../../ReportGroupCreation/constants";
import {
  resetReportingStore,
  setReportingCustomers,
  setReportingCustomersLoading
} from "../../../SingleReporting/actions";
import {BrokerResource, CustomerReportSettingResource} from "../../../../utils/api";
import {ERROR_MODAL_MESSAGE} from "../../../../utils/constants";
import DashboardTable from "../../../../components/DashboardTable/DashboardTable";
import {
  AddressColumn,
  CustomerFullnameMenuColumn,
  IconColumn
} from "../../../../components/CustomersSelectorProviderNew/CustomersNew";
import {
  ProductsComparisonPortfolioListDropDown
} from "../../../../components/PortfolioListDropdown/PortfolioListDropdown";
import {displayErrorSnackBar} from "../../../../components/SnackbarProvider/actions";
import withNotification from "../../../../components/NotificationProvider";
import FilterHeader from "../../../../components/CustomersSelectorProviderNew/components/FilterHeader";
import {default as DashboardFilterHeader} from "../../../DashboardSettings/components/FilterHeader"
import {hasFilterResult} from "../../../../utils/utils";
import {useCustomerPortfoliosList} from "../../../../hooks/useCustomerPortfoliosList";
import {getAuthSelector} from "../../../../utils/redaxSelectors";

const mapStateToProps = (state) => ({
  auth: getAuthSelector(state),
});

function CustomerDepotSelectionDropDown({customerId, onSelectedPortfolioChanged, displayNotification}) {

  const {portfolios, errors, loading, fetch} = useCustomerPortfoliosList(customerId, true, false, true);

  const handleDropDownExpanded = (expanded) => {
    if (!expanded || loading || !_.isEmpty(portfolios)) return;

    fetch();
  }

  React.useEffect(() => {
    if (_.isEmpty(errors)) {
      return
    }

    displayNotification('error', 'Fehler beim laden des Kundendepots');
  }, [errors])

  return (
    <ProductsComparisonPortfolioListDropDown
      portfolios={portfolios}
      portfoliosLoadingError={errors}
      portfoliosDataLoading={loading}
      onExpanded={handleDropDownExpanded}
      handleSelectedPortfolioChanged={onSelectedPortfolioChanged}
    />
  )
};

const CustomerDepotSelectionDropDownMemoized = React.memo(withNotification(CustomerDepotSelectionDropDown));

const DepotSelectionColumn = {
  content: [{
    body: {content: (item, options) => (
        <CustomerDepotSelectionDropDownMemoized
          customerId={item.customer_id}
          onSelectedPortfolioChanged={options.onSelectedPortfolioChanged}
        />
      )},
    header: 'Depotauswahl'
  }],
}

const tableStructure = {
  default: [
    IconColumn,
    CustomerFullnameMenuColumn,
    AddressColumn,
    DepotSelectionColumn
  ],
  sm: [
    IconColumn,
    CustomerFullnameMenuColumn,
    DepotSelectionColumn
  ],
  xs: [
    IconColumn,
    CustomerFullnameMenuColumn,
    DepotSelectionColumn
  ]
}

function CustomerDepotSelection({auth, dispatch, onConfirmProductsClick}) {
  const classes = useStyles();

  const [customerRequestFilter, setCustomerRequestFilter] = React.useState({
    customerType: getSearchCustomerType(auth),
    productType: PRODUCT_TYPES_WITH_ALL_OPTION.ALL
  });
  const [isMounted, setIsMounted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(undefined);

  React.useEffect(() => {
    if (_.isEmpty(errorMessage)) {
      return
    }

    dispatch(displayErrorSnackBar(errorMessage));
  }, [errorMessage]);

  const getCustomerFilters = () => {
    let filters = {
      show_reporting: true,
    };

    if (!_.isNil(customerRequestFilter.customerType.value)) {
      filters['customer_type'] = customerRequestFilter.customerType.value
      if (customerRequestFilter.requestType == CLIENT_FILTERS.SUB_AGENCY) {
        filters.sub_agency = customerRequestFilter.customerType.value
        filters.customer_type = customerRequestFilter.requestType
      } else if (customerRequestFilter.requestType == CLIENT_FILTERS.SUB_BROKER) {
        filters.sub_broker = customerRequestFilter.customerType.value
        filters.customer_type = customerRequestFilter.requestType
      }
    }

    if (!_.isNil(customerRequestFilter.productType.value)) {
      filters['group_identifier_code'] = customerRequestFilter.productType.value
    }

    return filters;
  };

  React.useEffect(() => {
    onInitComponent();
    return () => {
      onDestroyComponent();
    }
  }, []);

  React.useEffect(() => {
    if (isMounted){
      fetchCustomers();
    }

  }, [customerRequestFilter]);

  const onInitComponent = () => {
    fetchCustomers();
    setIsMounted(true);
  };

  const onDestroyComponent = () => {
    dispatch(resetReportingStore());
  };

  const fetchCustomers = async () => {
    try {
      dispatch(setReportingCustomersLoading(true));
      const response = await BrokerResource.at('customers/').get(getCustomerFilters());
      dispatch(setReportingCustomers(response || []));
      dispatch(setReportingCustomersLoading(false));
      setErrorMessage(undefined);
    } catch (err) {
      dispatch(setReportingCustomersLoading(false));
      setErrorMessage(ERROR_MODAL_MESSAGE);
    }
  };

  const handleSelectedPortfolioChanged = (portfolios) => {
    onConfirmProductsClick(true, portfolios)();
  };

  return (
    <Container>
      <div style={{marginBottom: 10}}>
        <h1 className={classes.header}>Kundendepots</h1>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <ShowReportingCustomersTabData
            customerRequestFilter={customerRequestFilter}
            defaultListFilter={{search: undefined, first_letter: undefined}}
            setCustomerRequestFilter={setCustomerRequestFilter}
            useFiltersOptionsLoading={false}
            customersListRender={({customersLoading, filterResult, customers, paginationBlock}) => {
              if (!hasFilterResult(filterResult)) {
                return <FilterHeader/>;
              }

              if (customersLoading) {
                return (
                  <div className={classes.loadingContainer}>
                    <CircularProgress color="primary" />
                  </div>
                )
              }
              return (
                <>
                  <DashboardFilterHeader filterResult={filterResult}/>

                  <Grid item xs={12} id="reporting-customers-list-tour-element">
                    <DashboardTable
                      dataSource={customers}
                      structure={tableStructure}
                      expanded
                      withFooter={false}
                      tableClasses={classes}
                      tableLayout={"auto"}
                      tableSizeSwitchEnabled
                      options={{
                        onSelectedPortfolioChanged: handleSelectedPortfolioChanged
                      }}
                    />
                  </Grid>
                  {paginationBlock}
                </>
              )
            }}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default connect(mapStateToProps)(CustomerDepotSelection);