import React from 'react';

import useStyles from './styles';
import {ChartSectionWrapper} from "../ChartSectionBordered/ChartSectionBordered";
import {GROUP_ACTIONS} from "../../../../components/Charts/InstrumentsAllocationTable/constants";
import Select from "../Select";

function GroupByControl({value, handleGroupByChange}) {

  const classes = useStyles();

  return (
    <div className={classes.selectSection}>
      <Select
        name={null}
        value={value}
        options={GROUP_ACTIONS.map((action) => ({id: action.code, name: action.text}))}
        onChange={handleGroupByChange}
        withDefault={false}
        fullWidth
      />
    </div>
  )
}

function InstrumentsSection(props) {

  const {
    loading,
    error,
    expanded,
    onExpandedChanged,
    content,
    instrumentsGroupBy,
    onGroupByChanged
  } = props;

  return (
    <ChartSectionWrapper
      dataId={"instrument-table"}
      title={(
        <b>Instrumente</b>
      )}
      loading={loading}
      error={error}
      displayError
      expanded={expanded}
      onExpanded={(newState) => onExpandedChanged('instruments', newState)}
      content={content}
      titleControl={instrumentsGroupBy && !loading && !!onGroupByChanged && (
        <GroupByControl
          value={instrumentsGroupBy}
          handleGroupByChange={onGroupByChanged}
        />
      )}
    />
  )
}

InstrumentsSection.propTypes = {}

InstrumentsSection.propDefs = {}

InstrumentsSection.defaultProps = {}

export default InstrumentsSection;