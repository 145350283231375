import _ from "lodash";
import {SERVICE_CONCEPTS, SERVICE_CONCEPTS_NAMES} from "../Trades/constants";
import React from "react";

export const APPROVAL_STATUS = {
  FOR_APPROVAL: {
    value: 1,
    id: 1,
    color: '#ffc000',
    name: 'Zur Freigabe'
  },
  APPROVED: {
    value: 2,
    id: 2,
    color: '#b3de85',
    name: 'Freigegeben'
  },
  REJECTED: {
    value: 3,
    id: 3,
    color: '#ff0000',
    name: 'Abgelehnt'
  },
  TRANSMITTED: {
    value: 4,
    id: 4,
    color: '#70ad47',
    name: 'Übermittelt'
  }
}

export const APPROVAL_STATUS_SELECT_OPTIONS = _.map(APPROVAL_STATUS, (status) => ({
  id: status.value,
  value: status.value,
  label: status.name,
  color: status.color,
}))

export const APPROVAL_STATUS_FILTER_OPTIONS = {
  ALL: {
    value: 0,
    id: 0,
    name: 'Alle'
  },
  ...(() => {
    const result = {}
    Object.entries(APPROVAL_STATUS).forEach(([statusKey, statusConfiguration]) => {
      result[statusKey] = {
        ...statusConfiguration
      }
    })
    return result
  })()
}


export const DOCUMENTS_SINGING_STATUS_FILTER_OPTIONS = [
  {
    id: '1',
    value: 'Warten auf E-Unterschrift', // waiting for e-signature
  },
  {
    id: '2',
    value: 'E-Unterschrift unvollständig', // not all singed
  },
  {
    id: '3',
    value: 'Vollständig unterschrieben', // all signed
  },
  {
    id: '4',
    value: 'Abgelaufen', // Expired
  }
]

export const USAGE_AREAS = {
  FORM_CENTER_USAGE_AREA_ONBOARDING: 'onboarding',
  FORM_CENTER_USAGE_AREA_ORDER: 'order',
  FORM_CENTER_USAGE_AREA_ORDER_BUY: 'order_buy',
  FORM_CENTER_USAGE_AREA_ORDER_SELL: 'order_sell',
  FORM_CENTER_USAGE_AREA_ORDER_SWITCH: 'order_switch',
  FORM_CENTER_USAGE_AREA_SP: 'savings_plan',
  FORM_CENTER_USAGE_AREA_SP_CREATE: 'sp_create',
  FORM_CENTER_USAGE_AREA_SP_EDIT: 'sp_edit',
  FORM_CENTER_USAGE_AREA_SP_DELETE: 'sp_delete',
  FORM_CENTER_USAGE_AREA_PP: 'payout_plan',
  FORM_CENTER_USAGE_AREA_PP_CREATE: 'pp_create',
  FORM_CENTER_USAGE_AREA_PP_EDIT: 'pp_edit',
  FORM_CENTER_USAGE_AREA_PP_DELETE: 'pp_delete',
  FORM_CENTER_USAGE_AREA_TP: 'switch_plan',
  FORM_CENTER_USAGE_AREA_TP_CREATE: 'swp_create',
  FORM_CENTER_USAGE_AREA_TP_EDIT: 'swp_edit',
  FORM_CENTER_USAGE_AREA_TP_DELETE: 'swp_delete',
  FORM_CENTER_USAGE_AREA_FORM_CENTER: 'form_center'
}

export const USAGE_AREAS_LABELS = {
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_ONBOARDING]: 'Depoteröffnung',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_ORDER]: 'Auftrag Bestandskunden',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_ORDER_BUY]: 'Kauf Bestandskunden',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_ORDER_SELL]: 'Verkauf Bestandskunden',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_ORDER_SWITCH]: 'Tausch Bestandskunden',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_SP]: 'Sparplan',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_SP_CREATE]: 'Einrichten Sparplan',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_SP_EDIT]: 'Ändern Sparplan',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_SP_DELETE]: 'Beenden Sparplan',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_PP]: 'Entnahmeplan',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_PP_CREATE]: 'Einrichten Entnahmeplan',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_PP_EDIT]: 'Ändern Entnahmeplan',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_PP_DELETE]: 'Beenden Entnahmeplan',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_TP]: 'Tauschplan',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_TP_CREATE]: 'Einrichten Tauschplan',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_TP_EDIT]: 'Ändern Tauschplan',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_TP_DELETE]: 'Beenden Tauschplan',
  [USAGE_AREAS.FORM_CENTER_USAGE_AREA_FORM_CENTER]: 'Formularcenter'
}

export const EVENT_TYPES_FILTER_OPTIONS = {
  ALL: {
    value: 0,
    id: 0,
    name: 'Alle'
  },
  ...(() => {
    const result = {}
    Object.entries(USAGE_AREAS).forEach(([usageAreaKey, usageAreaField]) => {
      result[usageAreaKey] = {
        value: usageAreaField,
        id: usageAreaField,
        name: USAGE_AREAS_LABELS[usageAreaField]
      }
    })
    return result
  })()
}

export const SERVICE_CONCEPT_FILTER_OPTIONS = {
  ALL: {
    value: 0,
    id: 0,
    name: 'Alle'
  },
  ...(() => {
    const result = {}
    Object.entries(SERVICE_CONCEPTS).forEach(([serviceConceptKey, serviceConceptValue]) => {
      result[serviceConceptKey] = {
        value: serviceConceptValue,
        id: serviceConceptValue,
        name: SERVICE_CONCEPTS_NAMES[serviceConceptValue]
      }
    })
    return result
  })()
}

export const FINANCIAL_INVESTMENT_FILTER_OPTIONS = {
  ALL: {
    value: 0,
    id: 0,
    name: 'Alle'
  },
  PRIVATE_INVESTMENT: {
    value: 1,
    id: 1,
    name: 'Vermögensverwaltung'
  },
  INVESTMENT_FUNDS: {
    value: 2,
    id: 2,
    name: 'Investmentfonds'
  }
}

export const MANDANT_FILTER_OPTIONS = {
  ALL: {
    value: 0,
    id: 0,
    name: 'Alle'
  },
  BCA: {
    value: '0835202b-d2bc-402f-b7a7-6c431ff27193',
    id: '0835202b-d2bc-402f-b7a7-6c431ff27193',
    name: 'BCA'
  },
  BFV: {
    value: 'f2f78495-6052-4b59-b012-a666122b5e1c',
    id: 'f2f78495-6052-4b59-b012-a666122b5e1c',
    name: 'BfV'
  },
  CARAT: {
    value: '8102a6a4-0b85-4f7b-9e8b-3df1bf2eb809',
    id: '8102a6a4-0b85-4f7b-9e8b-3df1bf2eb809',
    name: 'Carat'
  },
  VVS: {
    value: 'a35ced88-528f-41d5-91d8-551f539c1933',
    id: 'a35ced88-528f-41d5-91d8-551f539c1933',
    name: 'VVS'
  }
}

export const APPROVALS_SORTING_OPTIONS = {
  DEPOT_VOLUME_ASC: {
    value: 1,
    id: 1,
    name: 'Anlagebetrag (aufsteigend)'
  },
  DEPOT_VOLUME_DESC: {
    value: 2,
    id: 2,
    name: 'Anlagebetrag (absteigend)'
  },
  NAME_ASC: {
    value: 3,
    id: 3,
    name: 'Mitarbeiter (A bis Z)'
  },
  NAME_DESC: {
    value: 4,
    id: 4,
    name: 'Mitarbeiter (Z bis A)'
  },
  DATE_ASC: {
    value: 5,
    id: 5,
    name: 'Datum (aufsteigend)'
  },
  DATE_DESC: {
    value: 6,
    id: 6,
    name: 'Datum (absteigend)'
  },
  BROKER_ID_ASC: {
    value: 7,
    id: 7,
    name: 'Mitarbeiter-Nr (aufsteigend)'
  },
  BROKER_ID_DESC: {
    value: 8,
    id: 8,
    name: 'Mitarbeiter-Nr (absteigend)'
  },
  CUSTOMER_ID_ASC: {
    value: 9,
    id: 9,
    name: 'Kunden-Nr (aufsteigend)'
  },
  CUSTOMER_ID_DESC: {
    value: 10,
    id: 10,
    name: '(absteigend)'
  },
  CUSTODIAN_ASC: {
    value: 11,
    id: 11,
    name: 'Gesellschaft (A bis Z)'
  },
  CUSTODIAN_DESC: {
    value: 12,
    id: 12,
    name: 'Gesellschaft (Z bis A)'
  },
  STATUS_ASC: {
    value: 13,
    id: 13,
    name: 'Status (A bis Z)'
  },
  STATUS_DESC: {
    value: 14,
    id: 14,
    name: 'Status (Z bis A)'
  },
  EVENT_TYPE_ASC: {
    value: 15,
    id: 15,
    name: 'Art (A bis Z)'
  },
  EVENT_TYPE_DESC: {
    value: 16,
    id: 16,
    name: 'Art (Z bis A)'
  },
  CONTRACT_NUMBER_ASC: {
    value: 15,
    id: 15,
    name: 'Vertrags-Nr (aufsteigend)'
  },
  CONTRACT_NUMBER_DESC: {
    value: 16,
    id: 16,
    name: 'Vertrags-Nr (absteigend)'
  }
}