import React from 'react';
import clsx from 'clsx'

import useStyles from './styles'
import {isRequiredQuestion} from "../../../../../utils";
import {paginateArray} from "../../../../../../../utils/utils";
import Grid from "@material-ui/core/Grid";
import {Brightness1} from "@material-ui/icons";

export const InfoListQuestionMethods = {
  answer: (question) => ({
    question_uid: question.uid,
    answer: []
  }),
  required: () => false,
  valid: (question, step, afterChange, service) => true
}

function InfoListQuestion(props) {

  const {
    question
  } = props;

  const classes = useStyles();
  const custom_classes = question.custom_classes;

  const label = question.question_text;

  let options = question.config.choices || [];

  const renderOption = (option) => (
    <span className={classes.option}>
      <Brightness1 className={classes.icon}/>
      <span> { option.text } </span>
    </span>
  )

  const renderColumns = () => {
    if (options && options.length) {
      let columns = [[], [], []]

      let index = 0

      options.forEach(option => {
        columns[index].push(option)
        index++

        if (index > columns.length - 1) {
          index = 0
        }
      })

      return columns.map(column => (
        <Grid item lg={4} md={6} sm={12}>
          { column.map(renderOption)}
        </Grid>
      ))
    }
    return null
  }

  return (
    <div className={clsx(classes.inputContainer, (custom_classes && custom_classes.inputContainer) || '')}>
      <p className={clsx(classes.label, (custom_classes && custom_classes.label) || '')}>{ label }</p>
      <Grid container>
        { renderColumns() }
      </Grid>
    </div>
  );
}

export default InfoListQuestion;