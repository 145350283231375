import React from 'react';
import _ from 'lodash';

import {
  CircularProgress,
  ExpansionPanelDetails,
  TextField,
  Slider, Grid, withStyles
} from '@material-ui/core';

import useStyles from './styles';
import styles from '../../containers/CustomerDashboard/components/InvestmentDetailsNavigation/WeightSelector/styles'
import {BaseNumberFormat} from "../Inputs";

const RangeSlider = withStyles(styles)(Slider);


const RangeSliderFilter = ({title, errors, handleValuesChanged, disabled, loading, ...props}) => {
  const classes = useStyles();
  const [investedValues, setInvestedValue] = React.useState(props.initialValues);

  React.useEffect(() => {
    setInvestedValue(props.initialValues);
  }, [props.initialValues]);

  const onInputChange = (event, index) => {
    let value = event.target.value || 0; // if user completely removed value - set zero
    if (Number.isFinite(value)) {
      const _values = [...investedValues];
      _values[index] = value;
      setInvestedValue(_values);
    }
  };

  const handleSliderChanged = (event, value) => {
    setInvestedValue(value);
  }

  const handleSliderCommittedChanged = (event, value) => {
    handleValuesChanged(value);
  }

  const onInputBlur = () => {
    if (!_.isNil(handleValuesChanged)) {
      handleValuesChanged(investedValues);
    }
  }

  let roundedLowerBoundary = Math.round(investedValues[0] * 100) / 100
  let roundedUpperBoundary = Math.round(investedValues[1] * 100) / 100

  return (
    <div className={classes.checkboxContainer} id="aggregated-filter-tour-element">
        <ExpansionPanelDetails classes={{ root: classes.checkboxFilterDetailsRoot }}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loadingIndicator}/>
            </div>
          ) : (
            <Grid container alignItems={"center"}>
              <Grid container xs={12} direction={"row"} alignItems={"center"}>
                <Grid item xs={"auto"} alignItems={"center"}>
                  <span style={{marginRight: 5, fontSize: 12, fontFamily: "Roboto-Regular"}}>&#8364;</span>
                </Grid>
                <Grid item xs={5} alignItems={"center"}>
                  <TextField
                    placeholder={props.withMinMaxPlaceholders ? props.range[0] : ''}
                    style={{marginTop: 8, width: '100%'}}
                    error={errors && !_.isNil(errors[0])}
                    value={roundedLowerBoundary}
                    onChange={(event) => onInputChange(event, 0)}
                    onBlur={onInputBlur}
                    margin="normal"
                    InputProps={
                      {
                        inputComponent: BaseNumberFormat,
                        className: classes.input,
                        disableUnderline: true,
                        classes: {
                          root: classes.textFieldRoot,
                          underline: classes.textFieldUnderline,
                          focused: classes.textFieldUnderlineFocused,
                          error: classes.inputError
                        }
                      }
                    }
                    InputLabelProps={{
                      shrink: true,

                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={1} alignItems={"center"}>
                  <div style={{textAlign: "center"}}>
                    <span style={{marginRight: 4, marginLeft: 4, fontFamily: "Roboto-Regular"}}>&#8211;</span>
                  </div>
                </Grid>
                <Grid item xs={5} alignItems={"center"}>
                  <TextField
                    placeholder={props.withMinMaxPlaceholders ? props.range[1] : ''}
                    style={{marginTop: 8, width: '100%'}}
                    error={errors && !_.isNil(errors[1])}
                    value={roundedUpperBoundary}
                    onChange={(event) => onInputChange(event, 1)}
                    onBlur={onInputBlur}
                    margin="normal"
                    InputProps={
                      {
                        inputComponent: BaseNumberFormat,
                        className: classes.input,
                        disableUnderline: true,
                        classes: {
                          root: classes.textFieldRoot,
                          underline: classes.textFieldUnderline,
                          focused: classes.textFieldUnderlineFocused
                        }
                      }
                    }
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} style={{marginRight: 25}}>
                <RangeSlider
                  value={investedValues}
                  min={props.range[0]}
                  max={props.range[1]}
                  onChange={handleSliderChanged}
                  onChangeCommitted={handleSliderCommittedChanged}
                />
              </Grid>
            </Grid>)}
        </ExpansionPanelDetails>
    </div>
  )
}

export default RangeSliderFilter;