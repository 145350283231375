import _ from "lodash";

/**
 * Update state data and loading flag after.
 * To prevent show empty content between two loading states
 * @param {Object} data
 * @param {function} setMethod
 */
export const updateDataAndLoadingAfter = (data, setMethod) => {
  if(_.isFunction(setMethod) && _.isObject(data)){
    setMethod({...data, loading: true});
    setTimeout(() => {
      setMethod({...data, loading: false});
    }, 100);
  }
};