import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import clsx from "clsx";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';

import BenchmarkSelector from '../../../CustomerDashboard/components/InvestmentDetailsNavigation/BenchmarkSelector';
import WeightSelector from '../../../CustomerDashboard/components/InvestmentDetailsNavigation/WeightSelector';

import useStyles from './styles';
import Tooltip from "../../../../components/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton/IconButton";

const mapStateToProps = (state) => ({
  benchmark_settings: state.get('sharedSettings').toJS().data.benchmark_settings
});

export default connect(mapStateToProps)(withRouter((props) => {

  const classes = useStyles();

  const {
    onBenchmarkConfigured,
    benchmarkConfiguration,
    benchmark_settings
  } = props;


  const expectedWeight = 100;

  const [selectedBenchmarks, setSelectedBenchmarks] = React.useState([]);
  const [totalWeight, setTotalWeight] = React.useState(0);
  const [isChanged, setChanged] = React.useState(false);
  const [isPIBenchmarkSelected, setPIBenchmarkSelected] = React.useState(false);

  const [alertOpen, setAlertOpen] = React.useState(false);

  React.useEffect(() => {
    if(benchmarkConfiguration && benchmarkConfiguration.benchmarks && benchmarkConfiguration.benchmarks.length > 0){
      let portfolioBenchmarks = benchmarkConfiguration.benchmarks.map(setting => {
        const benchmark = benchmark_settings.find(item => item.id === +setting.benchmark);
        return {...benchmark, weight: +setting.percentage}
      }) || [];

      setSelectedBenchmarks(portfolioBenchmarks);
    }
  }, [benchmarkConfiguration]);

  React.useEffect(() => {
    const total = selectedBenchmarks.reduce((accumulator, benchmark) => {
      return +benchmark.weight + accumulator;
    }, 0);
    const isPISelected = selectedBenchmarks
      .some(selected => benchmark_settings.find(benchmark => benchmark.id === selected.id).is_private_investment);

    setTotalWeight(total);
    setPIBenchmarkSelected(isPISelected);
  }, [selectedBenchmarks]);

  const handleBenchmarkChanged = (index) => {
    return (benchmark) => {
      if (benchmark) {
        const benchmarks = [...selectedBenchmarks];
        benchmarks[index] = {...benchmark, weight: benchmark.is_private_investment ? 100: 0};
        setSelectedBenchmarks(benchmarks);
        setChanged(true);
      } else {
        handleBenchmarkDeleted(index);
      }
    }
  };

  const handleBenchmarkDeleted = (index) => {
    const benchmarks = [...selectedBenchmarks];
    benchmarks.splice(index, 1);
    setSelectedBenchmarks(benchmarks);
    setChanged(true);
  };

  const handleWeightChanged = (index) => {
    return (weight) => {
      let benchmarks = [...selectedBenchmarks];

      if (benchmarks[index]) {
        benchmarks[index].weight = weight;
        setSelectedBenchmarks(benchmarks);
        setChanged(true);
      }
    }
  };

  const handleSaveConfigurationClick = () => {
    const benchmarks = selectedBenchmarks.map(benchmark => (
      {benchmark: benchmark.id, percentage: benchmark.weight}
    ));

    onBenchmarkConfigured(benchmarks);
    setAlertOpen(false);
    setChanged(false);
    setSelectedBenchmarks([]);
  };

  const forceCloseSettingsModal = () => {
    onBenchmarkConfigured(false);
    setAlertOpen(false);
    setChanged(false);
    setSelectedBenchmarks([]);
  };

  const handleWarningAlertClose = () => {
    forceCloseSettingsModal();
  };

  const handleSettingsModalClose = () => {
    if (isChanged) {
      return setAlertOpen(true);
    }
    forceCloseSettingsModal();
  };

  const renderBenchmarkSelector = (benchmark, index) => {
    const key = benchmark && (`${benchmark.id}_${benchmark.weight}`) || 'empty';
    return (
      <div className={classes.benchmarkSelector} key={key}>
        <BenchmarkSelector
          selectedBenchmarks={selectedBenchmarks}
          isPIBenchmarkSelected={isPIBenchmarkSelected}
          benchmark={benchmark}
          handleSelectedBenchmarkChanged={handleBenchmarkChanged(index)}
        />
        {benchmark && <DeleteIcon className={classes.deleteBenchmark} onClick={() => handleBenchmarkDeleted(index)}/>}
      </div>
    );
  };

  const renderBenchmarkSelectors = () => {

    return (
      <>
        {selectedBenchmarks.map(renderBenchmarkSelector)}
        {!isPIBenchmarkSelected && renderBenchmarkSelector(null, selectedBenchmarks.length)}
      </>
    );
  };

  const renderWeightSelector = (benchmark, index) => {
    const key = benchmark && benchmark.id || 'empty';
    const benchmark_setting = benchmark && benchmark_settings.find(item => item.id === benchmark.id);
    const isPrivateInvestmentSelected = benchmark_setting && benchmark_setting.is_private_investment;

    return (
      <WeightSelector
        key={key}
        weight={benchmark && benchmark.weight}
        disabled={!benchmark || isPrivateInvestmentSelected}
        max={expectedWeight - totalWeight}
        handleWeightChanged={handleWeightChanged(index)}
      />
    );
  };

  const renderWeightSelectors = () => {
    return (
      <>
        {selectedBenchmarks.map(renderWeightSelector)}
        {selectedBenchmarks.length === 0 && renderWeightSelector(null, selectedBenchmarks.length)}
      </>
    );
  };

  const renderSaveButton = () => {
    const getButton = (disabled, message) => {
      const button = (
        <Button
          className={classes.acceptButton}
          onClick={handleSaveConfigurationClick}
          color="primary"
          variant={"contained"}
          disabled={disabled}
          autoFocus>Speichern</Button>
      )

      if (message) {
        return (
          <Tooltip title={message} placement={"top"}>
            <div>
              {button}
            </div>
          </Tooltip>
        )
      } else {
        return button;
      }
    }

    if (selectedBenchmarks.length) {
      const isTotalCorrect = totalWeight === expectedWeight;
      const isAllWeightsCorrect = selectedBenchmarks.every(selector => +selector.weight > 0);

      if (isPIBenchmarkSelected) {
        return getButton(false);
      }

      if (!isTotalCorrect) {
        return getButton(true, 'Die Summe aller Benchmarks \n muss 100 % ergeben.')
      }

      if (!isAllWeightsCorrect) {
        return getButton(true, 'Ein gewählter Benchmark \n muss mehr als 0% Gewichtung haben');
      }

      return getButton(false);
    } else {
      return getButton(false);
    }
  };


  return (
    <React.Fragment>
      <Dialog
        open={benchmarkConfiguration.modal_opened}
        onClose={() => isChanged && setAlertOpen(true)}
        fullWidth={true}
        maxWidth={'md'}
        classes={{
          paper: classes.modalPaper
        }}
        scroll={'body'}
      >
        <DialogTitle classes={{root: classes.dialogTitleRoot}}>
          Welche Benchmark möchten Sie für dieses Musterdepot setzen?
          <IconButton onClick={handleSettingsModalClose} className={classes.closeButton}>
            <CloseIcon className={classes.closeIcon}/>
          </IconButton>
        </DialogTitle>
        <DialogContent classes={{root: classes.dialogContentRoot}}>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={12} md={8}>
              <Grid container>
                <Grid item xs={12} sm={6} md={6}>
                  <div className={classes.section}>
                    <p className={classes.sectionHeader}>Benchmark</p>
                    <div className={classes.benchmarksContainer}>
                      {renderBenchmarkSelectors()}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className={classes.section}>
                    <p className={classes.sectionHeader}>Gewichtung</p>
                    <div className={classes.benchmarksContainer}>
                      {renderWeightSelectors()}
                    </div>
                  </div>
                  {selectedBenchmarks.length > 0 && (
                    <div className={classes.sectionConclusion}>
                      <b>Gesamt</b>
                      <span className={clsx(classes.totalWeight, !isPIBenchmarkSelected && totalWeight !== expectedWeight && classes.warnTotal)}>
                        {isPIBenchmarkSelected ? 100 : totalWeight} %
                      </span>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions classes={{root: classes.dialogActionsRoot}}>
          {renderSaveButton()}
        </DialogActions>
      </Dialog>

      <Dialog
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          classes={{root: classes.dialogTitleRoot}}
        >
          {"Wollen Sie die Konfiguration der Benchmark ohne Speichern Ihrer Änderungen verlassen?"}
        </DialogTitle>
        <DialogActions>
          <Button className={classes.acceptButton} onClick={() => setAlertOpen(false)} variant={"outlined"} color="primary">
            Nein
          </Button>
          <Button onClick={handleWarningAlertClose} color="primary" className={classes.acceptButton} variant={"contained"} autoFocus>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}));