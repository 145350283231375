const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    background: theme.palette.primary.main,
    padding: '0 8px',
    [theme.breakpoints.only('xs')]: {
      paddingBottom: 8
    }
  },
  infoPanel: {
    color: 'white',
    fontFamily: 'Roboto-Bold',
    flex: 1
  },
  refreshButtonIcon: {
    color: 'white'
  },
  documentTypeFilter: {
    minWidth: 256,
    height: 34,
    margin: '7px 0',
    padding: '0 8px',
  },
  loading: {
    color: 'white'
  }
});

export default styles;