import { makeStyles } from '@material-ui/core';

const itemBorder = '1px solid #E1E3E9';

export default makeStyles((theme) => ({
  cardItem: {
    [theme.breakpoints.only('sm')]: {
      '&:first-child::after': {
        borderBottom: itemBorder
      },
      '&:nth-of-type(2)::after': {
        borderLeft: 'none',
      },
    },

    [theme.breakpoints.only('xs')]: {
      '&::after': {
        borderLeft: 'none',
        borderBottom: itemBorder,
      },
    }
  },
  buttonWrapper: {
    alignItems: 'center',
    justifyContent: 'center !important'
  }
}));