import React from 'react';
import _ from 'lodash';

import { useCustomerESGProfileData } from '../../../hooks/useCustomerEsgProfileData';
import { getDataRetrievingParamsFromProps, isExpanded } from '../../../utils';
import SustainabilityMetricsTable from './components/SustainabilityMetricsTable';
import { isSectionVisibleByReportTypes } from '../../../../../utils/utils';
import { Grid } from '@material-ui/core';
import { REPORT_TYPES } from '../../../../DashboardSettings/components/CustomersList/components/ReportTypeV2/constants';
import LoadingIndicator from '../../../../../components/LoadingIndicator/LoadingIndicator';

// NOTE: we assume onExpanded function does not contain any data which could change during re-render
const propsAreEquals = (prevProps, nextProps) => {
  return prevProps.selectedPortfolios === nextProps.selectedPortfolios &&
    prevProps.reportType === nextProps.reportType &&
    prevProps.expandedItems === nextProps.expandedItems
};

const SustainabilityMetricsCharts = React.memo((props) => {
  const { reportType, customSettings, onExpandedItemsChange, expandedItems } = props;

  const {
    customerId,
    portfolios,
    dataProvider,
  } = getDataRetrievingParamsFromProps(props);

  const esgData = useCustomerESGProfileData(customerId, portfolios, props.aggregatedPortfolioName, dataProvider);
  const tablesStructure = _.get(esgData.data, 'table_structure') || {};
  const data = _.get(esgData.data, 'data') || {};
  const orderedTablesKeys = React.useMemo(() => {
    return _.sortBy(Object.keys(tablesStructure || {}), column => tablesStructure[column].order)
  }, [tablesStructure]);

  const getTableAvailableReportTypes = (tableKey) => ({
    'sustainability_metrics': [REPORT_TYPES.EXPERT.value, REPORT_TYPES.PRO.value]
  }[tableKey] || [REPORT_TYPES.PRO.value]);

  const handleExpanded = (sectionKey) => (expanded) =>  {
    return onExpandedItemsChange(sectionKey, expanded);
  };

  if(esgData.loading){
    return <div style={{ height: 300, display: 'flex', width: '100%' }}><LoadingIndicator /></div>;
  }

  return (
    <>
      {orderedTablesKeys.filter(tableKey => isSectionVisibleByReportTypes(tableKey, reportType, customSettings, getTableAvailableReportTypes(tableKey))).map(tableKey => (
        <Grid item xs={12} key={tableKey}>
          <SustainabilityMetricsTable
            tableKey={tableKey}
            data={data}
            columnStructure={tablesStructure[tableKey]}
            expanded={isExpanded(expandedItems, tableKey)}
            onExpanded={handleExpanded(tableKey)}
          />
        </Grid>
      ))}
    </>
  )
}, propsAreEquals);

export default SustainabilityMetricsCharts;