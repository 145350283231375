import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22.852"
      height="30"
      viewBox="0 0 22.852 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_8033"
            data-name="Rechteck 8033"
            width="22.852"
            height="30"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_8150" data-name="Gruppe 8150" transform="translate(0 0)">
        <g
          id="Gruppe_8149"
          data-name="Gruppe 8149"
          transform="translate(0 0)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Pfad_7816"
            data-name="Pfad 7816"
            d="M22.852,12.363V10.605H21.094V9.726A4.442,4.442,0,0,0,16.7,5.273h-.879V1.758h1.758V0H5.274V1.758H7.031V5.273H6.152A4.442,4.442,0,0,0,1.758,9.726v.879H0v1.758H1.758v7.032H0v1.758H1.758v.879a4.465,4.465,0,0,0,3.516,4.363V30h12.3V26.394a4.464,4.464,0,0,0,3.515-4.363v-.879h1.758V19.395H21.094V12.363ZM8.789,1.758h5.273V5.273H12.3V3.515H10.547V5.273H8.789Zm7.031,26.485H7.032V26.485h8.789Zm3.515-17.637H17.827v1.758h1.509v7.032H17.827v1.758h1.509v.879a2.7,2.7,0,0,1-2.637,2.7H6.152a2.7,2.7,0,0,1-2.637-2.7v-.879H5.025V19.395H3.516V12.363H5.025V10.605H3.516V9.726a2.7,2.7,0,0,1,2.637-2.7H16.7a2.7,2.7,0,0,1,2.637,2.7ZM11.311,22.931h0a5.943,5.943,0,0,1-5.089-3.6c-.5-1.225-.9-3.694,1.981-6.477l1.43-1.384,0,1.991a7.665,7.665,0,0,0,.215,1.833,13.917,13.917,0,0,0,.384-4.716l-.109-2,1.509,1.316a6.378,6.378,0,0,1,1.9,5.8,16.817,16.817,0,0,0,.455-1.636l.291-1.3,1.053.819a4.588,4.588,0,0,1,1.41,5.273,5.929,5.929,0,0,1-5.435,4.079M8.168,15.549A3.419,3.419,0,0,0,7.788,18.7a4.331,4.331,0,0,0,3.522,2.545h0A4.241,4.241,0,0,0,15.147,18.3a3.381,3.381,0,0,0,.047-2.191c-.544,1.692-1.2,2.6-1.956,2.709a1.377,1.377,0,0,1-1.425-.871,2.646,2.646,0,0,1-.056-1.629,9.886,9.886,0,0,0,.115-2.5,4.742,4.742,0,0,1-1.053,2.962,1.454,1.454,0,0,1-1.619.33,2.222,2.222,0,0,1-1.03-1.561"
            transform="translate(0 0)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
