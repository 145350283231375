import React from 'react';
import _ from 'lodash';
import clsx from "clsx";
import Tooltip from '../../components/Tooltip';

import { 
  FormControlLabel, 
  Checkbox, 
  ExpansionPanel, 
  ExpansionPanelSummary, 
  ExpansionPanelDetails,
  CircularProgress
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useStyles from './styles';

const CheckboxFilter = ({
  title, 
  options, 
  checked, 
  handleCheckedChanged, 
  handleSelectAll, 
  id, 
  disabled, 
  loading,
  useSingleColumn,
  ...props
}) => {

  const classes = useStyles();

  const isChecked = (item) => {
    return !_.isNil(checked) && !_.isNil(_.find(checked, checked => checked.value === item.value))
  };
  const isAllChecked = () =>  {
    let optionsToCheck = options;
    if (props.availableOptions) {
      optionsToCheck = props.availableOptions;
    }

    return checked && optionsToCheck && Object.keys(optionsToCheck).length == checked.length
  };

  const renderCheckboxItem = (key, item) => (
    <Tooltip title={item.description} placement={"top"}>
    <FormControlLabel
      key={key}
      classes={{
        label: classes.checkboxLabel,
        root: classes.checkboxFormControlLabel
      }}
      id={id}
      control={
        <Checkbox
          checked={isChecked(item)} 
          onChange={() => handleCheckedChanged(item)}
          value={item.value}
          classes={{
            root: classes.checkboxRoot,
            checked: classes.checkboxChecked
          }}
          color="primary" />
      }
      label={item.description}
    />
    </Tooltip>
  );

  const renderAllCheckedCheckbox = () => (
    <FormControlLabel
      classes={{
        label: classes.checkboxLabel,
      }}
      control={
        <Checkbox
          checked={isAllChecked()}
          onChange={() => handleSelectAll && handleSelectAll()}
          classes={{
            root: classes.checkboxRoot,
            checked: classes.checkboxChecked,
          }}
          color="primary" />
      }
      label={'Alle auswählen'}
    />
  )

  const renderOptions = (options) => {
    return Object.keys(options).map(key => {
      return renderCheckboxItem(key, options[key]);
    });
  };

  const renderFilters = () => {
    let firstColumnData = {};
    let secondColumnData = {};

    let optionsCopy = {...options};
    if (props.availableOptions) {
      Object.keys(options).forEach((key, index) => {
        if (!_.includes(props.availableOptions, options[key].value)) {
         delete optionsCopy[key];
        }
      });
    }
    
    Object.keys(optionsCopy).forEach((key, index) => {
      if (useSingleColumn || index % 2 == 0) {
        firstColumnData[key] = options[key];
      } else {
        secondColumnData[key] = options[key];
      }
    });

    return (
      <>
        <div>
          {renderOptions(firstColumnData)}
        </div>
        {!useSingleColumn && 
          <div>
            {renderOptions(secondColumnData)}
          </div>
        }
      </>
    )
  };

  const renderSelectedOptionsLabel = () => {
    if (!loading && (!options || (options && options.length == 0))) {
      return 'Keine Treffer gefunden';
    }
    if (isAllChecked()) {
      return 'alles ausgewählt';
    }

    return `${checked.length} ausgewählt`;
  }

  return (
    <div className={classes.checkboxContainer}>
      <ExpansionPanel 
        expanded={props.expanded || false}
        classes={{
          root: classes.checkboxFilterPanelRoot
        }}
        onChange={() => !disabled && props.onExpandedClick && props.onExpandedClick()}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className={classes.checkboxExpandIcon} style={{color: disabled ? '#B1B1B1' : '#0092E5'}}/>}
          classes={{
            root: classes.checkboxFilterSummaryRoot,
            content: classes.checkboxFilterSummaryContent,
            expandIcon: classes.expandIconSummary
          }}
        >
          <span className={classes.checkboxFilterLabelMain} style={{color: disabled ? '#B1B1B1' : '#636A71'}}>{title} <span style={{fontWeight: 'normal'}}>({renderSelectedOptionsLabel()})</span></span>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.checkboxFilterDetailsRoot
          }}
        >
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loadingIndicator}/>
            </div>
          ) : (
            <div className={clsx(classes.checkboxSection, !useSingleColumn && classes.checkboxDoubleColumn)}>
              <div>
                {renderAllCheckedCheckbox()}
              </div>
              <div>
                {renderFilters()}
              </div>
            </div>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
} 

export default CheckboxFilter;