import React from "react";
import {PortfolioManagerResource} from "../../../utils/api";

export function useOtherAssetsData(customerId, portfolios, startDate, endDate) {

  const [data, setData] = React.useState({
    data: null,
    loading: true,
    error: null,
    updatedAt: +new Date(),
  });

  const fetch = async () => {

    setData({
      data: null,
      loading: true,
      error: null,
      updatedAt: +new Date()
    });

    try {
      const response = await PortfolioManagerResource.at(`customer/${customerId}/other-assets/`).get({
        clearing_account: true,
        start_date: startDate,
        end_date: endDate,
        depot_id: portfolios.map(portfolio => portfolio.depotNumber)
      });

      setData({
        data: response,
        loading: false,
        error: null,
        updatedAt: +new Date()
      })

    } catch (error) {
      setData({
        data: null,
        loading: false,
        error: error,
        updatedAt: +new Date()
      })
    }
  };

  React.useEffect(() => {
    if (!!customerId) {
      fetch();
    }
  }, [customerId, JSON.stringify(portfolios), startDate, endDate]);

  return [data, fetch]

}