const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  main: {
    flex: 1,
    display: 'flex',
    height: 'calc(100% - 72px)',
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    backgroundColor: '#EEF0F2',
    height: 'calc(100svh - 72px)',
    position: 'relative'
  },
  contentWrapper: {
    paddingTop: 24,
    height: '100%'
  },
  menuModal: {
    position: 'relative',
    zIndex: 9999
  },
  stickyNavigation: {
    position: 'fixed',
    width: '100%',
    height: 100,
    background: ' linear-gradient(#F5F6F700, #F5F6F7)',
    bottom: 0,
    zIndex: 100,
    display: 'none',
    alignItems: 'center'
  }
});

export default styles;