import {fromJS} from "immutable";
import {
  RESET_KEY,
  SAVE_MODAL_KEY,
  SELECTED_CUSTOMERS_KEY,
  SELECT_FAKE_CUSTOMER_KEY,
  MARK_CUSTOMERS_KEY
} from "./constants";
import {customerForGuide} from "../DashboardSettings/constants";

const createGroupInitialState = fromJS({
  selectedCustomers: [],
  markedCustomers: [],
  isModalVisible: false
});

export function createGroupReducer(state = createGroupInitialState, action) {
  switch(action.type) {
    case RESET_KEY:
      return createGroupInitialState;
    case SAVE_MODAL_KEY:
      return state.set('isModalVisible', action.isModalVisible);
    case SELECTED_CUSTOMERS_KEY:
      return setSelectedCustomers(state, action.selectedCustomers);
    case SELECT_FAKE_CUSTOMER_KEY:
      return selectFakeCustomer(state);
    case MARK_CUSTOMERS_KEY:
      return state.set('markedCustomers', action.selectedCustomers);
    default:
      return state;
  }
}

function setSelectedCustomers(state, customers=[]) {
  // this condition added to fix issue with guide tour
  // customerForGuide will be added to list of selected customers when guide will be opened
  // but customers will be fetched and selected list will be updated when open create group page
  // customerForGuide should be in selected list
  const selectedCustomers = state.get('selectedCustomers').toJS();
  if (selectedCustomers[0] && selectedCustomers[0].customer_id === customerForGuide.customer_id) {
    customers.unshift(customerForGuide)
  }

  return state.set('selectedCustomers', fromJS(customers))
}

function selectFakeCustomer(state) {
  let selectedCustomers = state.get('selectedCustomers').toJS();

  if (!selectedCustomers.length) {
    selectedCustomers.unshift(customerForGuide);
    return state.set('selectedCustomers', fromJS(selectedCustomers));
  }

  return state;


}