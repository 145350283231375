import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Grid } from '@material-ui/core'

import { toGermanFormat } from '../../../../../../utils/numberFormater'

import useStyles from './styles'
import Tooltip from "@material-ui/core/Tooltip";

function ExAnteLine(props) {

  const {
    title,
    euroValue,
    percentValue,
    divider,
    bold,
    subTitle,
    style,
    numbersTooltip
  } = props;

  const classes = useStyles();

  return (
    <Grid item xs={12} style={{margin: bold ? '20px 0 8px' : '0'}}>
      <Grid container spacing={2}>
        <Grid item xs={1}/>
        <Grid item xs={5}><span className={clsx(classes.label, bold && classes.textBold, subTitle && classes.subTitle)} style={style}> { title } </span> </Grid>
        { !divider && (
          <>
            <Grid item xs={2}>
              <span className={clsx(classes.label, classes.alignRight, bold && classes.textBold)}>
                {numbersTooltip ? (
                  <Tooltip
                    classes={{tooltip: classes.tooltip}}
                    title={numbersTooltip}
                    placement={"top"}
                    arrow>
                    <span>{(percentValue || percentValue == 0) ? toGermanFormat(percentValue * 100, undefined, ' %') : ''}</span>
                  </Tooltip>
                ) : (
                  <>{(percentValue || percentValue == 0) ? toGermanFormat(percentValue * 100, undefined, ' %') : ''}</>
                )}
              </span>
            </Grid>
            <Grid item xs={3}>
              <span className={clsx(classes.label, classes.alignRight, bold && classes.textBold)}>
                {numbersTooltip ? (
                  <Tooltip
                    classes={{tooltip: classes.tooltip}}
                    title={numbersTooltip}
                    placement={"top"}
                    arrow>
                    <span>{(euroValue || euroValue == 0) ? toGermanFormat(euroValue, undefined, ' €') : ''}</span>
                  </Tooltip>
                ) : (
                  <>{ (euroValue || euroValue == 0) ? toGermanFormat(euroValue, undefined, ' €') : '' }</>
                )}
              </span>
            </Grid>
            <Grid item xs={1} />
          </>
        ) }
      </Grid>
    </Grid>

  )
}

ExAnteLine.propTypes = {
  /** Line title */
  title: PropTypes.string.isRequired,
};

export default ExAnteLine

