import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import Grid from '@material-ui/core/Grid'

import { Button as MUIButton } from '@material-ui/core'
import useStyles from './styles'
import {useFiltersContext} from "../../Filters";

Button.propTypes = {
  children: PropTypes.element,
  disabled: PropTypes.bool
};

function Button({ children, disabled, GridProps, className }) {

  const gridProps = {
    md: 3,
    sm: 6,
    xs: 12,
    ...GridProps,
  }

  const { onSubmit } = useFiltersContext()

  const classes = useStyles()

  return (
    <Grid item className={clsx(classes.grid, className)} {...gridProps}>
      <MUIButton
        variant="contained"
        color="primary"
        fullWidth={true}
        disabled={disabled}
        disableRipple={true}
        full
        classes={{
          root: classes.applyFiltersBtn,
          label: classes.applyFiltersBtnText,
      }}
        onClick={onSubmit}
      >
        { children ? children : "Filter übernehmen" }
      </MUIButton>
    </Grid>
  );
}

export default Button;