import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash';

import { Grid } from '@material-ui/core'

import { CollectionLoadingItem, CollectionItem } from './components'

import useStyles from './styles';

import { FormCenterHandlerResource } from '../../utils/api'
import { displayErrorSnackBar, displaySuccessSnackBar } from '../../components/SnackbarProvider/actions'
import { filterCollections } from './utils'

import Pagination from "../../components/Pagination/Pagination";
import PaginationInput from "../../components/Pagination/PaginationInput";

const defaultDataStructure = {
  data: undefined,
  loading: true,
  errors: undefined,
  date: undefined
}

const START_PAGE_INDEX = 0;

export default connect()(withRouter(function Collections({ customerId, onDetailsClick, onCollectionChange, history, dispatch, ...props }) {

  const classes = useStyles();

  const [collections, setCollections] = useState({...defaultDataStructure})
  const [filteredCollections, setFilteredCollections] = useState({})
  const [page, setPage] = useState({})

  useEffect(() => {
    setTimeout(() => {
      setCollections(props.collections)
    }, 300)
  }, [])

  useEffect(() => {
    if (!collections.loading && collections.data) {
      setFilteredCollections(filterCollections(collections.data))
      onCollectionChange(collections)
    }
  }, [collections])

  useEffect(() => {
    if (_.isEmpty(page) && !_.isEmpty(filteredCollections)) {
      let pages = {}
      Object.keys(filteredCollections).map(tab => {pages[tab] = START_PAGE_INDEX})
      setPage(pages)
    } else {
      handlePage()
    }
  }, [filteredCollections])

  const fetchCollections = async () => {

    setCollections(prevState => ({
      ...prevState,
      loading: true,
      errors: undefined,
      date: Date.now()
    }))

    try {

      let response = await FormCenterHandlerResource.getDocumentsCollections(customerId)

      setCollections({
        data: response,
        loading: false,
        errors: undefined,
        date: Date.now()
      })
      

    } catch (errors) {

      setCollections({
        data: undefined,
        loading: false,
        errors,
        date: Date.now()
      })

    }
  }

  const handlePage = (pageNumber=null) => {
    if (_.get(filteredCollections, `${props.activeTab}`, []).length < page[props.activeTab] + 1) {
      page[props.activeTab] = page[props.activeTab] > START_PAGE_INDEX ? page[props.activeTab] - 1 : START_PAGE_INDEX
      setPage({...page})
    } else if (pageNumber || pageNumber == 0) {
      page[props.activeTab] = pageNumber
      setPage({...page})
    }
  }

  const handleCollectionOverviewClick = (collectionId) => {
    let currentPath = window.location.pathname
    let targetPath = `${currentPath}?collection_id=${collectionId}`

    history.push(targetPath)

    onDetailsClick()
  }

  const handleDeleteCollectionClick = async (collectionId) => {
    try {
      await FormCenterHandlerResource.deleteCollectionContent(customerId, collectionId)

      setCollections((prevState) => ({
        ...prevState,
        data: prevState.data.filter(collection => collection.id != collectionId)
      }));

      let queryParams = props.location.search
      queryParams = new URLSearchParams(queryParams)
      let colectionIdentifier = queryParams.get('collection_id')

      if (colectionIdentifier && colectionIdentifier == collectionId) {
        history.push(window.location.pathname)
      }

      dispatch(displaySuccessSnackBar('Mappen wurde erfolgreich gelöscht.'))
    } catch (errors) {
      console.log(errors)
      dispatch(displayErrorSnackBar('Mappen konnte nicht gelöscht werden.'))
    }
  }

  const handleEditFinishCollection = async (collectionId, documents) => {

    let msg = ''
    let is_finished = {}
    documents.forEach(obj => {
      is_finished[obj.id] = obj.is_finished
    })

    let postParams = {
      customer_id: customerId,
      collection_id: collectionId,
      is_finished
    };

    try {
      let response = await FormCenterHandlerResource.saveCollection(customerId, postParams)
      fetchCollections()
      msg = getCollectionFinishSaveMsg(collections.data.documents)
      dispatch(displaySuccessSnackBar(msg))
    } catch (errors) {
      msg = getCollectionFinishSaveMsg(documents, errors)
      dispatch(displayErrorSnackBar(msg))
    }
  }

  const getCollectionFinishSaveMsg = (documents, errors=null) => {
    if (documents && documents.every(obj => obj.is_finished)) {
      if (errors) {
        return 'Die Sammlung konnte nicht angeschlossen werden. Bitte versuchen Sie es erneut.'
      }
      // collection successfully closed(all documents finished)
      return 'Sammlung erfolgreich geschlossen.'
    } else {
      if (errors) {
        return 'Die Sammlung konnte nicht aktualisiert werden. Bitte versuchen Sie es erneut.'
      }
      // collection successfully saved
      return 'Die Sammlung wurde erfolgreich aktualisiert.'
    }
  }

  const renderLoader = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}><CollectionLoadingItem /></Grid>
          <Grid item xs={12}><CollectionLoadingItem /></Grid>
          <Grid item xs={12}><CollectionLoadingItem /></Grid>
        </Grid>
      </>
    )
  }

  const renderList = () => {
      return (
        <>
          {_.get(filteredCollections, `${props.activeTab}.${page[props.activeTab]}`, []).length > 0 && (
            <Grid container spacing={2}>
              {filteredCollections[props.activeTab][page[props.activeTab]].map(collection =>
                  <Grid key={collection.id} item xs={12}>
                    <CollectionItem
                      key={collection.id}
                      collection={collection}
                      activeCollection={props.activeCollection}
                      onDetailsClick={handleCollectionOverviewClick}
                      onDeleteClick={handleDeleteCollectionClick}
                      onEditFinishClick={handleEditFinishCollection}
                    />
                  </Grid>
              )}
            </Grid>
          )}
        </>
      )}

  const renderPagination = () => {
    return (
      <>
        {_.get(filteredCollections, `${props.activeTab}`, []).length > 1 && (
          <Grid item xs={12}>
            <Grid container className={classes.paginationWrapper}>
              <Grid item className={classes.paginationItem}>
                <span className={classes.paginationInfo}>Seite {page[props.activeTab] + 1} von {filteredCollections[props.activeTab].length}</span>
              </Grid>
              <Grid item className={classes.paginationItem}>
                <Pagination handlePageChanged={handlePage} totalPageCount={filteredCollections[props.activeTab].length} currentPage={page[props.activeTab]}/>
              </Grid>
              <Grid item className={classes.paginationItem}>
                <PaginationInput
                  handlePageChanged={handlePage}
                  totalPageCount={filteredCollections[props.activeTab].length}
                  currentPage={page[props.activeTab]}
                  label={'Seite aufrufen:'}/>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    )
  }

  return (
    <div style={{width: '100%'}}>
      {collections.loading && renderLoader()}
      {!collections.loading && page && renderList()}
      {!collections.loading && page && renderPagination()}
    </div>
  )
}))
