import { makeStyles } from '@material-ui/core';
import { FORM_FIELD_HEIGHT } from '../../../../containers/TransactionsMonitoring/components/Filters/components/common_styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex'
  },
  link: {
    fontFamily: 'Roboto-Bold',
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer'
    },
    '&.disabled': {
      color: 'rgba(0, 0, 0, 0.38)',
      '&:hover': {
        cursor: 'auto'
      },
    }
  },
  button: {
    padding: 0,
    fontSize: 18,
  },
  divider: {
    margin: '0 8px'
  }
}))