import _ from "lodash";
import React from "react";
import {useTabsSharedDataContext} from "../components_v2/TabsSharedData/TabsSharedData";
import {getErrorMessage} from "../../../utils/utils";
import {parseResponse} from "../../../utils/api";
import {getHashValue, getPortfolioColor} from "../utils";

const generateQueryParamsHash = (customerId, portfolios, startDate, endDate, withHistoricalPortfolios) => {
  const portfoliosDepotNumbers = JSON.stringify(_.sortBy(
    portfolios.map((portfolio) => portfolio.depotNumber)));
  return getHashValue(customerId, portfoliosDepotNumbers, startDate, endDate, withHistoricalPortfolios)
}

export function useHistoricalData(customerId, portfolios, startDate, endDate, withHistoricalPortfolios, dataProvider, useSharedDataOnly, investmentStrategyId) {
  const [data, setData] = React.useState({
    data: null,
    loading: true,
    errors: null,
    updatedAt: undefined,
  });

  const sharedData = useTabsSharedDataContext();

  React.useEffect(() => {
    if (!!customerId && !_.isEmpty(portfolios)) {

      const sharedPortfolioData = !!sharedData
        ? sharedData.getDataValue('historical-data', generateQueryParamsHash(customerId, portfolios, startDate, endDate, withHistoricalPortfolios))
        : undefined;

      if (!!sharedPortfolioData && !sharedPortfolioData.errors) {
        // sync internal hook data with shared data.
        setData(sharedPortfolioData);
        return;
      }

      // If there is not shared data but only shared data should be used - return default data body.
      // useSharedDataOnly will be used in case two charts displayed at the same time. In that case one of them should request data and share it.
      // Another chart should use shared data useSharedDataOnly=true
      if (useSharedDataOnly) {
        setData({
          data: null,
          loading: true,
          errors: null,
          updatedAt: undefined,
        });
        return;
      }

      // fetch data if there is no shared data or shared data was loaded with error
      // check if data fetched for the first time (updatedAt is undefined) to prevent infinite data reloading
      // in case of data loading error, so data will be requested again only if tab changed
      if (!data.updatedAt || !sharedPortfolioData) {
        fetch();
      }
    }
  }, [
    customerId, JSON.stringify(portfolios),
    startDate, endDate, withHistoricalPortfolios,
    _.get(sharedData, 'updatedAt')]);

  const fetch = async () => {

    const hashKey = generateQueryParamsHash(customerId, portfolios, startDate, endDate, withHistoricalPortfolios);

    const state = {
      data: null,
      loading: true,
      errors: null,
      updatedAt: +new Date()
    }
    setData(state);
    // It is not possible to sync data with shared data in
    // hook because hook is not executed if component destroyed
    sharedData.setDataValue('historical-data', hashKey, state);

    try {

      const depotNumbers = portfolios.map((portfolio) => portfolio.depotNumber).filter((value) => !!value);

      let response = await dataProvider.getHistoricalData(
        customerId, depotNumbers, startDate, endDate, withHistoricalPortfolios,
        undefined, undefined, investmentStrategyId, false, true, true, true, undefined, undefined);

      parseResponse(response, 'historical', (data) => {
        const state = {
          data: {
            portfolios: (data || []).map(p => ({...p, color: getPortfolioColor(portfolios, p)}))
          },
          loading: false,
          errors: undefined,
          timestamp: new Date().getTime()
        };
        setData(state);
        sharedData.setDataValue('historical-data', hashKey, state);
      }, (errors) => {
        const state = {
          data: null,
          loading: false,
          errors: getErrorMessage(errors),
          updatedAt: +new Date()
        }
        setData(state)
        sharedData.setDataValue('historical-data', hashKey, state);
      });
    }
    catch(errors) {
      const state = {
        data: null,
        loading: false,
        errors: getErrorMessage(errors),
        updatedAt: +new Date()
      }
      setData(state)
      sharedData.setDataValue('historical-data', hashKey, state);
    }
  }

  return data
}