import React from 'react';
import { PrimaryButton } from '../../../../components/Buttons';
import { CustomerReportSettingResource } from '../../../../utils/api';
import moment from 'moment';
import _ from 'lodash';
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from './styles';


const SaveSettingsButton = props => {

  const {
    settings,
    dateRange,
    setSnackbarSettings,
    broker_id,
    settingsType,
    handleSettingUpdate,
    disabled,
    btnWrapperStyles
  } = props;

  const [isSavingInProgress, setIsSavingInProgress] = React.useState(false);
  const classes = useStyles();

  const handleSaveButtonClick = async() => {
    setIsSavingInProgress(true);

    try {
      let dashboard_settings = settings

      // 'customers' is list of dict objects, but we need to pass list of ids
      if (dashboard_settings.customers){
        dashboard_settings.customers = dashboard_settings.customers.map(customer => customer.id )
      }
      let body = {};
      body['data'] = [{
        ...dashboard_settings,
        'date_range_start_date': moment(dateRange.start).format("YYYY-MM-DD"),
        'date_range_end_date': moment(dateRange.end).format("YYYY-MM-DD"),
        'broker_id': broker_id,
        'dashboard_group_id': settings.dashboard_group_id,

      }];

      body['settings_type'] = settingsType;

      let response = await CustomerReportSettingResource.at('broker/save-dashboard-settings/').post(body);

      handleSettingUpdate('id')(_.get(response, '0.id'))
      setSnackbarSettings({
        open: true,
        variant: 'success',
        message: 'Einstellungen gespeichert'
      });
    }
    catch (e) {
      setSnackbarSettings({
        open: true,
        variant: 'error',
        message: 'Es ist ein Fehler aufgetreten.'
      });
    }

    setIsSavingInProgress(false);
  }

  return (
    <>
      <div style={btnWrapperStyles || {float: 'right'}}>
        <PrimaryButton
          customClasses={{root: isSavingInProgress && classes.buttonProgress}}
          text={
            isSavingInProgress ? <CircularProgress color={"inherit"} size={24} className={classes.iconProgress}/> :
            'Einstellungen speichern'
          }
          onButtonClick={handleSaveButtonClick}
          disabled={isSavingInProgress || disabled}
        />
      </div>
    </>
  )
};


export default SaveSettingsButton;
