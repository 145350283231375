import _ from "lodash";

import {CustomerTaxesStep, LegitimationStep} from "./components/StepContent/components/step/CustomerDataSteps";
import {
  CLEARING_ACCOUNT_BALANCING_QUESTION_UID, CLEARING_ACCOUNT_OPTION_QUESTION_UID,
  CLEARING_ACCOUNT_QUESTION_UID, CONGRATS_STEP_ID,
  CountryISOMapping,
  CUSTOMER_TYPES,
  DefaultCountry, EMPLOYER_STEP_ID,
  FIELD_DOES_NOT_FITT_MSG,
  FIELD_REQUIRED_MSG,
  LEGITIMATION_MAPPING,
  LEGITIMATION_MINOR_MAPPING,
  LEGITIMATION_MINOR_TYPES, LEGITIMATION_STEP_ID,
  LEGITIMATION_TYPES, REF_ACCOUNT_STEP_ID, TAX_STEP_ID,
  USER_CONFIRMATION_STEP_IDENTIFIER
} from "./constants";
import {
  countryCodeToISO,
  getLegitimationData,
  isValidBirthDate,
  isValidString,
  isValidValue,
  validateMultilineContent
} from "./utils";
import {CustomerDataMapping} from "./mock";
import ReferenceAccountStep from "./components/StepContent/components/step/CustomerDataSteps/ReferenceAccountStep";
import moment from "moment";
import {countriesChoices} from "./components/StepContent/components/question/CountrySelectQuestion/CountrySelectQuestion";
import {buildQuestionUIDForMember, buildStepUIDForMember} from "./service";
import {getMemberIndexFromStepUID} from "./services";
import EmployerInformationStep
  from "./components/StepContent/components/step/CustomerDataSteps/EmployerInformationStep";

const berufQuestions = [
  {
    "uid": "occupation",
    "question_text": "Beruf",
    "type": "normal",
    "behaviour": ["select"],
    "size": 4,
    "config": {
      "choices": _.sortBy([
        {"id": 1, "uid": "1", "text": 'Arbeitnehmer/-in'},
        {"id": 2, "uid": "2", "text": 'Selbstständige/-r'},
        {"id": 3, "uid": "3", "text": 'Beamte/-r, auf Lebenszeit'},
        {"id": 4, "uid": "4", "text": 'Rentner/-in'},
        {"id": 5, "uid": "5", "text": 'Pensionär/-in'},
        {"id": 6, "uid": "6", "text": 'Arbeitslose/-r'},
        {"id": 7, "uid": "7", "text": 'Sonstige'},
        {"id": 8, "uid": "8", "text": 'Freiberufler/-in'},
        {"id": 9, "uid": "9", "text": 'Hausmann /-frau'},
        {"id": 10, "uid": "10", "text": 'Nicht Erwerbstätige/-r'},
        {"id": 11, "uid": "11", "text": 'Person in Berufsausbildung'},
        {"id": 12, "uid": "12", "text": 'Angestellte/-r des öff. Dienstes'},
        {"id": 13, "uid": "13", "text": 'Schüler/-in'},
        {"id": 14, "uid": "14", "text": 'Student/-in'},
        {"id": 15, "uid": "15", "text": 'Soldat/-in'},
        {"id": 16, "uid": "16", "text": 'Kind'},
        {"id": 17, "uid": "17", "text": 'Arbeiter/-in'},
        {"id": 18, "uid": "18", "text": 'Beamte/-r, auf Widerruf/Anwärter/-'},
        {"id": 19, "uid": "19", "text": 'Gesellschafter/-in GF'},
        {"id": 20, "uid": "20", "text": 'Gesellschafter/-in GF mit Pensionszusage'},
        {"id": 21, "uid": "21", "text": 'Selbständiger Handwerker/-in'},
        {"id": 22, "uid": "22", "text": 'Vorstand'},
        {"id": 23, "uid": "23", "text": 'Vermögende(r) Privatkunde/-in, Privatier/-in'},
      ], (o) => _.lowerCase(o.text)),
      "mapping": {
        "dante_field": "personalInformation.occupationalGroup",
        "config": {
          "1": "1", "2": "2", "3": "3", "4": "4", "5": "5", "6": "6",
          "7": "7", "8": "8", "9": "9", "10": "10", "11": "11", "12": "12", "13": "13",
          "14": "14", "15": "15", "16": "16", "17": "17", "18": "18", "19": "19", "20": "20",
          "21": "21", "22": "22"
        }
      }
    },
    "optional": false
  },
  {
    "uid": "industry_sector",
    "question_text": "Branche",
    "type": "normal",
    "behaviour": ["select"],
    "size": 4,
    "config": {
      "choices": _.sortBy([
        {"id": 1, "uid": "1", "text": "Landwirtschaft, Jagd, Pflanzenanbau, Tierhaltung, Gemischte Landwirtschaft, Erbringung von Dienstleistungen"},
        {"id": 2, "uid": "2", "text": "Forstwirtschaft"},
        {"id": 3, "uid": "3", "text": "Fischerei und Fischzucht"},
        {"id": 4, "uid": "4", "text": "Bergbau"},
        {"id": 5, "uid": "5", "text": "Nahrungsgewerbe"},
        {"id": 6, "uid": "6", "text": "Tabakverarbeitung"},
        {"id": 7, "uid": "7", "text": "Textilgewerbe"},
        {"id": 8, "uid": "8", "text": "Ledergewerbe"},
        {"id": 9, "uid": "9", "text": "Papiergewerbe"},
        {"id": 10, "uid": "10", "text": "Verlags- und Druckgewerbe"},
        {"id": 11, "uid": "11", "text": "Herstellung von chemischen Erzeugnissen"},
        {"id": 12, "uid": "12", "text": "Maschinenbau"},
        {"id": 13, "uid": "13", "text": "Herstellung von Kraftwagen und Kraftwagenteilen"},
        {"id": 14, "uid": "14", "text": "Herstellung von Möbeln"},
        {"id": 15, "uid": "15", "text": "Energieversorgung"},
        {"id": 16, "uid": "16", "text": "Wasserversorgung"},
        {"id": 17, "uid": "17", "text": "Abwasser- und Abfallbeseitigung; Recycling"},
        {"id": 18, "uid": "18", "text": "Hochbau"},
        {"id": 19, "uid": "19", "text": "Tiefbau"},
        {"id": 20, "uid": "20", "text": "Kraftfahrzeughandel; Instandhaltung und Reparatur von Kraftfahrzeugen; Tankstellen"},
        {"id": 21, "uid": "21", "text": "Großhandel"},
        {"id": 22, "uid": "22", "text": "Einzelhandel"},
        {"id": 23, "uid": "23", "text": "Schiffsfahrt"},
        {"id": 24, "uid": "24", "text": "Luftfahrt"},
        {"id": 25, "uid": "25", "text": "Beherbergungsgewerbe (Hotels, Gasthöfe, Pensionen, … )"},
        {"id": 26, "uid": "26", "text": "Gastronomie"},
        {"id": 27, "uid": "27", "text": "Verlagswesen"},
        {"id": 28, "uid": "28", "text": "Informationsdienstleistungen, Datenverarbeitung und Datenbanken"},
        {"id": 29, "uid": "29", "text": "Forschung und Entwicklung"},
        {"id": 30, "uid": "30", "text": "Öffentliche Verwaltung und Verteidigung"},
        {"id": 31, "uid": "31", "text": "Erziehung und Unterricht"},
        {"id": 32, "uid": "32", "text": "Gesundheits-, Veterinär- und Sozialwesen (ohne Organisationen ohne Erwerbscharakter)"},
        {"id": 33, "uid": "33", "text": "Kultur, Sport und Unterhaltung"},
        {"id": 34, "uid": "34", "text": "Erbringung von sonstigen Dienstleistungen"},
        {"id": 35, "uid": "35", "text": "Immobiliengewerbe"},
        {"id": 36, "uid": "36", "text": "Erbringung von Finanzdienstleistungen (Zentralbanken und Kreditinstitute, Treuhand- und Beteiligungsgesellschaften)"},
        {"id": 37, "uid": "37", "text": "Versicherungen, Rückversicherungen und Pensionskassen"},
        {"id": 38, "uid": "38", "text": "Mit dem Kredit- und Versicherungsgewerbe verbundene Tätigkeiten"},
        {"id": 39, "uid": "39", "text": 'Rechts- und Steuerberatung'},
        {"id": 40, "uid": "40", "text": 'Wirtschaftsprüfung'},
        {"id": 41, "uid": "41", "text": 'Sonstiges'},
      ], (o) => _.lowerCase(o.text)),
      "mapping": CustomerDataMapping.J2,
    },
    "optional": true
  },
  {
    "uid": "education",
    "question_text": 'Höchster Bildungsabschluss',
    "type": "normal",
    "behaviour": ["select"],
    "size": 4,
    "config": {
      "choices": [
          {"id": 1, "uid": "1", "text": "kein Abschluss"},
          {"id": 2, "uid": "2", "text": "Hauptschulabschluss (Qualifiziert)"},
          {"id": 3, "uid": "3", "text": "sonstiger Abschluss"},
          {"id": 4, "uid": "4", "text": "Realschulabschluss / Mittlere Reife"},
          {"id": 5, "uid": "5", "text": "Hochschulreife (Allgemein)"},
          {"id": 6, "uid": "6", "text": "Hochschulreife (Fachgebunden)"},
          {"id": 7, "uid": "7", "text": "Hochschulabschluss (abgeschl. Studium)"},
      ],
      "mapping": CustomerDataMapping.J3,
    },
    "optional": true,
  },
  {
    "uid": "use_new_industry_sector",
    "type": "normal",
    "behaviour": ["hidden"],
    "optional": true,
    "isValid": function(){
      // workaround to set answer ONLY on A1 step. So old sessions have no answer
      if(!this.answer) this.answer = true;

      return true;
    }
  },
];

const individual_user_confirmation_step = {
  "uid": USER_CONFIRMATION_STEP_IDENTIFIER,
  "behaviour": ["dynamic", "Persönliche Daten"],
  "custom_classes": {"stepName": "text-left"},
  "name": "Angaben zur Person",
  "question": [
    {
      "uid": "A-gen",
      "question_text": "",
      "info_text": "Bitte geben Sie den Vor- und den Nachnamen Ihres Kunden wie im vorgelegten Identifikationsdokument an.",
      "question": [
        {
          "uid": "salutation",
          "size": 2,
          "question_text": "Anrede",
          "type": "normal",
          "behaviour": ["radio"],
          "config": {
            "choices": [
              {
                "id": 1,
                "uid": "1",
                "text": "Herr"
              },
              {
                "id": 2,
                "uid": "2",
                "text": "Frau"
              }
            ],
            "mapping": CustomerDataMapping.A2
          },
          "optional": false
        },
        {
          "uid": "title",
          "size": 3,
          "question_text": "Titel",
          "type": "normal",
          "behaviour": ["select"],
          "config": {
            "choices": [
              {
                "id": 1,
                "uid": "1",
                "text": "Dr."
              },
              {
                "id": 2,
                "uid": "2",
                "text": "Dr. Dr."
              },
              {
                "id": 3,
                "uid": "3",
                "text": "Prof. Dr."
              },
              {
                "id": 4,
                "uid": "4",
                "text": "Prof. Dr. Dr."
              },
              {
                "id": 5,
                "uid": "5",
                "text": "Prof."
              },
              {
                "id": 6,
                "uid": "6",
                "text": "Dipl. Ing."
              }
            ],
            "mapping": CustomerDataMapping.A4
          },
          "optional": true
        }
      ]
    },
    {
      "uid": "A-name",
      "question_text": "",
      "question": [
        {
          "uid": "first_name",
          "question_text": "Vorname",
          "type": "input",
          "size": 6,
          "config": {
            "input_type": "",
            "mapping": CustomerDataMapping.A5
          },
          "optional": false
        },
        {
          "uid": "last_name",
          "question_text": "Nachname",
          "size": 6,
          "type": "input",
          "config": {
            "input_type": "",
            "mapping": CustomerDataMapping.A6
          },
          "optional": false
        },
        {
          "uid": "family_status",
          "size": 6,
          "question_text": "Familienstand",
          "type": "normal",
          "behaviour": ["select"],
          "config": {
            "choices": _.sortBy([
              { "id": 1, "uid": "1", "text": 'Ledig'},
              { "id": 2, "uid": "2", "text": 'Geschieden'},
              { "id": 3, "uid": "3", "text": 'Verheiratet'},
              { "id": 4, "uid": "4", "text": 'Eingetragene Lebensgemeinschaft'},
              { "id": 5, "uid": "5", "text": 'Verwitwet'},
              { "id": 6, "uid": "6", "text": 'Getrennt lebend'},
            ], (o) => _.lowerCase(o.text)),
            "mapping": {
              "dante_field": "personalInformation.familyStatus",
              "config": {"1": "1", "2": "3", "3": "5", "4": "2", "5": "6", "6": "4"}
            }
          },
          "optional": false
        },
        {
          "uid": "nationality_1",
          "size": 6,
          "question_text": "Staatsangehörigkeit",
          "type": "normal",
          "behaviour": ["country_select"],
          "config": {
            "choices": countriesChoices,
            "mapping": {
              "dante_field": "citizenship.0.iso",
            }
          },
          "optional": false
        },
      ]
    },
    {
      "uid": "A-address",
      "question_text": "Adresse",
      "question": [
        {
          "uid": "registration_address['street']",
          "question_text": "Straße & Hausnr.",
          "type": "input",
          "size": 6,
          "isValid": function () {
            let streetParts = (this.answer || "").split(" ").filter(part => !!part.trim());
            const isValid = streetParts.length > 1 && streetParts.every(part => isValidString(part));
            this.error = isValid ? '' : 'Bitte geben Sie eine korrekte Straße mit Haus-Nr. an.';
            return isValid;
          },
          "config": {
            "input_type": "",
            "mapping": CustomerDataMapping.A7
          },
          "optional": false
        },
        {
          "uid": "registration_address['zip_code']",
          "question_text": "PLZ",
          "type": "input",
          "size": 6,
          "config": {
            "input_type": "",
            "mapping": CustomerDataMapping.A8
          },
          "optional": false
        },
        {
          "uid": "registration_address['city']",
          "question_text": "Stadt",
          "type": "input",
          "size": 6,
          "config": {
            "input_type": "",
            "mapping": CustomerDataMapping.A9
          },
          "optional": false
        },
        {
          "uid": "registration_address['country']",
          "question_text": "Land",
          "type": "input",
          "size": 6,
          "behaviour": ["country_select"],
          "config": {
            "choices": countriesChoices,
            "mapping": CustomerDataMapping.A10
          },
          "optional": false
        },
      ]
    },
    {
      "uid": "A-birth",
      "question_text": "Ihre Geburtsdaten",
      "question": [
        {
          "uid": "birth_date",
          "question_text": "Geburtstag",
          "type": "input",
          "size": 2,
          "behaviour": ["date"],
          "out_format": "YYYY-MM-DD",
          "config": {
            "mapping": CustomerDataMapping.C1
          },
          "isValid": function(step, afterChange, service){
            return isValidBirthDate(this, step.uid === 'A1' && service.customer_type);
          },
          "optional": false
        },
        {
          "uid": "birthplace",
          "question_text": "Geburtsort",
          "size": 4,
          "type": "input",
          "config": {
            "input_type": "",
            "mapping": CustomerDataMapping.C2
          },
          "optional": false
        },
        {
          "uid": "country_of_birth",
          "size": 6,
          "question_text": "Geburtsland",
          "type": "normal",
          "behaviour": ["country_select"],
          "config": {
            "choices": countriesChoices,
            "mapping": {
              "dante_field": "personalInformation.countryOfBirth",
              "config": CountryISOMapping
            }
          },
          "optional": false
        }
      ]
    },
    {
      "uid": "A-beruf",
      "question_text": "Ihr Beruf",
      "question": berufQuestions
    },
    {
      "uid": "A-contacts",
      "question_text": "Kontaktdaten",
      "question": [
        {
          "uid": "mobile_number",
          "question_text": "Telefon",
          "type": "input",
          "behaviour": ["tel"],
          "size": 6,
          "config": {
            "input_type": "tel",
            "mapping": CustomerDataMapping.B1
          },
          "optional": false
        },
        {
          "uid": "email",
          "question_text": "E-Mail",
          "type": "input",
          "behaviour": ["email"],
          "size": 6,
          "config": {
            "input_type": "email",
            "mapping": CustomerDataMapping.B2
          },
          "optional": false
        }
      ]
    },
    {
      "uid": "pep",
      "question_text": "Mein Kunde ist eine politisch exponierte Person (PeP)",
      "type": "checkbox",
      "info": "Dies sind gemäß Geldwäschegesetz (GwG) natürliche Personen, die aktuell oder vor weniger als einem Jahr, ein wichtiges öffentliches Amt bekleiden oder ausgeübt haben, sowie deren unmittelbare Familienmitglieder.",
      "optional": true,
      "config": {
        "mapping": CustomerDataMapping.A12,
      }
    },
    {
      "uid": "immediate_church_tax_retrival",
      "question_text": "Kirchensteuermerkmal sofort abrufen",
      "type": "checkbox",
      "info": "Ich möchte, dass mein Kirchensteuermerkmal sofort im Rahmen der Depoteröffnung beim Bundeszentralamt für Steuern durch die Depotbank abgerufen wird.",
      "optional": true,
      "config": {
        "mapping": {
          "dante_field": "personalInformation.churchTax"
        }
      },
    },
    {
      "uid": "Berechtigter",
      "question_text": "Mein Kunde ist wirtschaftlich Berechtigter",
      "type": "checkbox",
      "optional": false,
      "config": {
        "mapping": {
          "dante_field_finder": (q, customer) => {
            return _.get(customer, 'legitimation.0.economicBeneficiary')
          }
        }
      }
    }
  ]
};

const family_user_confirmation_step = {
  "uid": USER_CONFIRMATION_STEP_IDENTIFIER,
  "behaviour": ["dynamic", "Persönliche Daten"],
  "custom_classes": {"stepName": "text-left"},
  "name": "Angaben zur Gemeinschaft",
  "question": [
    {
      "uid": "A-name",
      "question_text": "",
      "question": [
        {
          "uid": "last_name",
          "question_text": "Nachname",
          "size": 12,
          "type": "input",
          "config": {
            "input_type": "",
            "mapping": CustomerDataMapping.A6
          },
          "optional": false
        },
        {
          "uid": "first_name_01",
          "question_text": "Vorname",
          "type": "input",
          "size": 6,
          "config": {
            "input_type": "",
            "mapping": CustomerDataMapping.A51
          },
          "optional": true,
          disabled: true
        },
        {
          "uid": "last_name_01",
          "question_text": "Nachname",
          "size": 6,
          "type": "input",
          "config": {
            "input_type": "",
            "mapping": CustomerDataMapping.A52
          },
          "optional": true,
          disabled: true
        },
        {
          "uid": "first_name_02",
          "question_text": "Vorname",
          "type": "input",
          "size": 6,
          "config": {
            "input_type": "",
            "mapping": CustomerDataMapping.A53
          },
          "optional": true,
          disabled: true
        },
        {
          "uid": "last_name_02",
          "question_text": "Nachname",
          "size": 6,
          "type": "input",
          "config": {
            "input_type": "",
            "mapping": CustomerDataMapping.A54
          },
          "optional": true,
          disabled: true
        },
      ]
    },
    {
      "uid": "A-address",
      "question_text": "Versandanschrift",
      "question": [
        {
          "uid": "registration_address['street']",
          "question_text": "Straße & Hausnr.",
          "type": "input",
          "size": 6,
          "isValid": function () {
            let streetParts = (this.answer || "").split(" ").filter(part => !!part.trim());
            const isValid = streetParts.length > 1 && streetParts.every(part => isValidString(part));
            this.error = isValid ? '' : 'Bitte geben Sie eine korrekte Straße mit Haus-Nr. an.';
            return isValid;
          },
          "config": {
            "input_type": "",
            "mapping": CustomerDataMapping.A7
          },
          "optional": false
        },
        {
          "uid": "registration_address['zip_code']",
          "question_text": "PLZ",
          "type": "input",
          "size": 6,
          "config": {
            "input_type": "",
            "mapping": CustomerDataMapping.A8
          },
          "optional": false
        },
        {
          "uid": "registration_address['city']",
          "question_text": "Stadt",
          "type": "input",
          "size": 6,
          "config": {
            "input_type": "",
            "mapping": CustomerDataMapping.A9
          },
          "optional": false
        },
        {
          "uid": "registration_address['country']",
          "question_text": "Land",
          "type": "input",
          "size": 6,
          "behaviour": ["country_select"],
          "config": {
            "choices": countriesChoices,
            "mapping": CustomerDataMapping.A10
          },
          "optional": false
        },
      ]
    },
    {
      "uid": "A-beruf",
      "question_text": "Bester Beruf",
      "question": berufQuestions
    },
    {
      "uid": "A-contacts",
      "question_text": "Kontaktdaten",
      "question": [
        {
          "uid": "mobile_number",
          "question_text": "Telefon",
          "type": "input",
          "behaviour": ["tel"],
          "size": 6,
          "config": {
            "input_type": "tel",
            "mapping": CustomerDataMapping.B1
          },
          "optional": false
        },
        {
          "uid": "email",
          "question_text": "E-Mail",
          "type": "input",
          "behaviour": ["email"],
          "size": 6,
          "config": {
            "input_type": "email",
            "mapping": CustomerDataMapping.B2
          },
          "optional": false
        }
      ]
    },
  ]
}

const tax_step = {
  "uid": TAX_STEP_ID,
  "name": "Steuerpflicht",
  "custom_classes": {"stepName": "text-left"},
  "behaviour": ["dynamic", "Steuerpflicht"],
  "help_text": "",
  "question": [
    {
      "uid": "steuerpflichtig",
      "question_text": "Wo ist Ihr Kunde steuerpflichtig?",
      "type": "normal",
      "behaviour": ["radio"],
      "config": {
        "choices": [
          {
            "id": 1,
            "uid": "1",
            "max": 1,
            "text": "Mein Kunde ist ausschließlich in Deutschland steuerpflichtig und besitzt keine weiteren steuerlichen Ansässigkeiten"
          },
          {
            "id": 2,
            "uid": "2",
            "max": 2,
            "text": "Mein Kunde ist in Deutschland und im Ausland steuerpflichtig"
          },
          {
            "id": 3,
            "uid": "3",
            "text": "Mein Kunde ist im Ausland steuerlich ansässig"
          }
        ]
      },
      "optional": false,
      "custom_classes": {"labelRoot": "bold", radioGroupRoot: 'flex-direction-column'}
    },
    {
      "uid": 'tax_identifiers',
      "type": 'input',
      "optional": false,
      config: {
        mapping: {
          // TODO: We assume, that we will receive error as a string/list of strings for whole field
          dante_error_field: 'taxInformation',
          dante_field_setter: (answers, question, answer) => {
            answers.taxInformation = [];

            (answer || []).map((taxIdItem) => {
              answers.taxInformation.push({
                taxId: taxIdItem.tax_id,
                countryISO: countryCodeToISO(_.get(taxIdItem, 'country.id')),
                description: null
              })
            })

          }
        }
      },
      isValid: function (step, afterChange, service) {

        let memberIndex = getMemberIndexFromStepUID(step.uid);
        const taxTypeAnswer = service.getStepAnswer(
          buildStepUIDForMember('taxable', memberIndex),
          buildQuestionUIDForMember('steuerpflichtig', memberIndex, service.customer_type));

        this.error = {};

        if (!this.answer || _.isEmpty(this.answer)) {
          this.error = FIELD_REQUIRED_MSG;
          return false;
        }

        this.answer.forEach((taxIdItem) => {
          if (!taxIdItem.tax_id || (_.isString(taxIdItem.tax_id) && _.isEmpty(taxIdItem.tax_id))) {
            _.set(this.error, `${taxIdItem.id}.tax_id`, FIELD_REQUIRED_MSG);
          }
          if (_.isEmpty(taxIdItem.country)) {
            _.set(this.error, `${taxIdItem.id}.country`, FIELD_REQUIRED_MSG);
          } else {
            // TODO: DefaultCOuntry constant used for now.
            //  Need to be careful with this in case default country will be changed to other than DE option.
            // For disabled input only german is allowed.
            if (taxIdItem.country_disabled) {
              if (taxIdItem.country.id != DefaultCountry) {
                _.set(this.error, `${taxIdItem.id}.country`, 'Die angebende Steueridentifikationsnummer (TIN) konnte nicht gespeichert werden. Bitte geben Sie eine deutsche Steueridentifikationsnummer (TIN) an.');
              }
            } else {
              if (taxIdItem.country.id == DefaultCountry) {
                _.set(this.error, `${taxIdItem.id}.country`, 'Die angebende Steueridentifikationsnummer (TIN) konnte nicht gespeichert werden. Das Feld ist für ausländische Steueridentifikationsnummern (TIN).');
              }
            }
          }
        });

        // Continue with general question validation only if tax identifiers are valid.
        if (!_.isEmpty(this.error)) {
          return false
        }

        // TODO: Refactor this part to use constant or function to build message.
        const minTaxIdCount = taxTypeAnswer == 2 ? 2 : 1;
        if (!this.answer.length || this.answer.length < minTaxIdCount) {
          this.error = `Bitte geben Sie ${minTaxIdCount} Steueridentifikationsnummer${minTaxIdCount > 1 ? 'n' : ''} (TIN) an.`;
        }

        return _.isEmpty(this.error);

      },
      "custom_classes": {"labelRoot": "bold", "inputRoot": "w-25"},
      "question_text": "Bitte teilen Sie uns die Steueridentifikationsnummer(n) (TIN) und das zugehörige steuerpflichtige Land Ihres Kunden mit.",
    }
  ],
  "step_content": CustomerTaxesStep,
}

const legitimation_step = {
  "uid": LEGITIMATION_STEP_ID,
  "name": "Identifikationsunterlagen",
  "behaviour": ["dynamic", "ID Verifikation"],
  "custom_classes": {"stepName": "text-left"},
  "step_content": LegitimationStep,
  "question": [
    {
      "uid": "legitimation['type']",
      "question_text": "Dokumente auswählen",
      "type": "normal",
      "behaviour": ["select"],
      "custom_classes": {"labelRoot": "bold"},
      "size": 6,
      "config": {
        "choices": LEGITIMATION_TYPES,
        "mapping": {
          "dante_field": "legitimation.0.type",
          "config": LEGITIMATION_MAPPING
        }
      },
      "optional": false
    },
    {
      "uid": "legitimation['number']",
      "question_text": "Ausweisnummer",
      "type": "input",
      "config": {
        "mapping": {
          "dante_field": "legitimation.0.identificationNumber"
        }
      },
      "custom_classes": {"labelRoot": "bold"},
      "size": 6,
      "optional": false,
      "isValid": function (step, afterChange, service) {
        const customerType = service.customer_type;
        let memberIndex = getMemberIndexFromStepUID(step.uid)

        let legitimationIsValidQuestion = _.find(
          service.question,
          (question) => question.uid == buildQuestionUIDForMember("legitimation_is_valid", memberIndex, customerType))

        // Skip field validation in case "Confirm Legitimation" checkbox is checked
        if (legitimationIsValidQuestion && legitimationIsValidQuestion.answer) {
          return true
        }

        if (!this.optional) {
          if (_.isNil(this.answer) || (_.isString(this.answer) && !this.answer.length)) {
            this.error = FIELD_REQUIRED_MSG
            return false
          }
        }

        if (!_.isNil(this.answer) && isValidValue(this.answer)){

          const legitimationTypeQuestion = _.find(
            step.question,
            (q) => q.uid == buildQuestionUIDForMember("legitimation['type']", memberIndex, customerType))

          const existingLegitimationData = getLegitimationData(step.all_legitimations, legitimationTypeQuestion.answer, this.answer, legitimationTypeQuestion.config.mapping.config)
          if (existingLegitimationData) {
            if (moment(existingLegitimationData.expirationDate, 'DD.MM.YYYY').isBefore(moment(), 'day')) {
              this.error = 'Ein Identitätsnachweis des selben Typs mit der selben Nummer existiert im CRM. Dieser ist jedoch abgelaufen.'
              return false
            }
          }
        }

        return true
      }
    },
    {
      "uid": "legitimation['issue_date']",
      "question_text": "Tag der Ausstellung",
      "type": "input",
      "behaviour": ["date"],
      "disable_future": true,
      "out_format": "YYYY-MM-DD",
      "size": 3,
      "config": {
        "mapping": {
          "dante_field": "legitimation.0.dateOfIssue"
        }
      },
      "custom_classes": {"labelRoot": "bold"},
      "optional": false
    },
    {
      "uid": "legitimation['expiry_date']",
      "question_text": "Gültig bis",
      "type": "input",
      "behaviour": ["date"],
      "out_format": "YYYY-MM-DD",
      "disable_past": true,
      "disable_past_msg": "Dieses Ausweisdokument ist abgelaufen, bitte geben Sie eine gültige ID ein.",
      "config": {
        "mapping": {
          "dante_field": "legitimation.0.expirationDate"
        }
      },
      "size": 3,
      "custom_classes": {"labelRoot": "bold"},
      "optional": false
    },
    {
      "uid": "legitimation['authority']",
      "question_text": "Ausstellende Behörde",
      "type": "input",
      "size": 6,
      "custom_classes": {"labelRoot": "bold"},
      "optional": false,
      "config": {
        "mapping": {
          "dante_field": "legitimation.0.issuingAuthority"
        }
      },
    },
    {
      "uid": "legitimation['country']",
      "question_text": "Ausstellungsland",
      "type": "input",
      "behaviour": ["country_select"],
      "answer": "DE", // Default
      "config": {
        "choices": countriesChoices,
        "mapping": {
          "config": CountryISOMapping
        }
      },
      "size": 6,
      "custom_classes": {"labelRoot": "bold"},
      "optional": false,
    },
    {
      "uid": "legitimation['original']",
      "question_text": "Dokument lag im Original vor",
      "type": "toggle",
      "custom_classes": {"labelRoot": "bold"},
      "size": 6,
      "config": {
        "mapping": {
          "dante_field": "legitimation.0.shownOriginal",
        }
      },
      "optional": false,
    },
    {
      "uid": "legitimation['front_side']",
      "question_text": "Hauptdokument",
      "question_info": "Wenn Sie nur das Hauptdokument hochladen, stellen Sie bitte sicher, dass alle erforderlichen Seiten zum Dokument hinzugefügt werden.",
      "type": "legitimation_document",
      "size": 6,
      "custom_classes": {"labelRoot": "bold"},
      "config": {
        "accept": ".pdf, .png, .jpe, .jpeg, .jpg",
        "mapping": {
          "dante_field": "legitimation.0.identificationDocument.Legitimation"
        }
      },
      "optional": false
    },
    {
      "uid": "legitimation['rear_side']",
      "question_text": "Weitere Datei (z.B. Rückseite)",
      "type": "legitimation_document",
      "size": 6,
      "custom_classes": {"labelRoot": "bold"},
      "config": {
        "mapping": {
          "dante_field": "legitimation.0.identificationDocument.LegitimationBack"
        },
        "accept": ".pdf, .png, .jpe, .jpeg, .jpg"
      },
      "optional": true
    },
    {
      "uid": "legitimation['GUID']",
      "type": "input",
      "behaviour": ["hidden"],
      "config": {
        "mapping": {
          "dante_field": "legitimation.0.GUID"
        }
      },
      "optional": true
    },
    {
      "uid": "legitimation_is_valid",
      "question_text": "Die Angaben des Kunden wurden überprüft und sind weiterhin aktuell.",
      "type": "checkbox",
      "optional": true,
      "isValid": function (step, afterChange, service) {

        let memberIndex = getMemberIndexFromStepUID(step.uid)

        const legitimationTypeQuestion = _.find(
          step.question,
          (q) => q.uid == buildQuestionUIDForMember("legitimation['type']", memberIndex, service.customer_type))

        if (!this.answer && !legitimationTypeQuestion.answer) {
          this.error = 'Bitte bestätigen Sie die Angaben des Kunden oder erfassen Sie einen gültigen Identitätsnachweis.'
          return false
        }

        this.error = null
        return true

      }
    },
  ]
};

const employer_information_step = {
  "uid": EMPLOYER_STEP_ID,
  "behaviour": ["dynamic", "Arbeitgeber Daten"],
  "custom_classes": {"stepName": "text-left"},
  "name": "Angaben zum Arbeitgeber",
  "step_content": EmployerInformationStep,
  "question": [
    {
      "uid": "employer['company_name']",
      "question_text": "Arbeitgeber / Firma ",
      "type": "input",
      "size": 6,
      "config": {
        "input_type": "",
        "mapping": {}
      },
      "optional": true
    },
    {
      "uid": "employer['phone_number']",
      "question_text": "Telefonnummer",
      "type": "input",
      "size": 6,
      "behaviour": ["tel"],
      "config": {
        "input_type": "tel",
        "mapping": {}
      },
      "optional": true
    },
    {
      "uid": "employer['street']",
      "question_text": "Straße",
      "type": "input",
      "size": 6,
      "config": {
        "input_type": "",
        "mapping": {}
      },
      "optional": true
    },
    {
      "uid": "employer['street_number']",
      "question_text": "Hausnr.",
      "type": "input",
      "size": 6,
      "config": {
        "input_type": "tel",
        "mapping": {}
      },
      "optional": true
    },
    {
      "uid": "employer['zip_code']",
      "question_text": "PLZ",
      "type": "input",
      "size": 6,
      "config": {
        "input_type": "tel",
        "mapping": {}
      },
      "optional": true
    },
    {
      "uid": "employer['city']",
      "question_text": "Ort",
      "type": "input",
      "size": 6,
      "config": {
        "input_type": "",
        "mapping": {}
      },
      "optional": true
    },
    {
      "uid": "employer['employee_id']",
      "question_text": "Personalnummer",
      "type": "input",
      "size": 6,
      "config": {
        "input_type": "",
        "mapping": {}
      },
      "optional": true
    }
  ]
};

const refAccountStep = {
  "uid": REF_ACCOUNT_STEP_ID,
  // "name": "Referenzkonto",
  "name": "Verrechnungskonto",
  "behaviour": ["dynamic", "Referenzkonto"],
  "custom_classes": {"stepName": "text-left"},
  "step_content": ReferenceAccountStep,
  "question": [
    {
      "question_text": "Verrechnungskonto auswählen:",
      "uid": CLEARING_ACCOUNT_QUESTION_UID,
      "custom_classes": {"labelRoot": "bold"},
      "optional": true,
      "question": [] // list of checkbox questions. Should be filled with questions in data service, as it depends on selected portfolios and related clearing accounts
    },
    {
      "uid": CLEARING_ACCOUNT_OPTION_QUESTION_UID,
      "optional": true,
      "question": [] // list of SELECT questions. Should be filled with questions in data service, as it depends on selected portfolios and related clearing accounts
    },
    {
      "uid": CLEARING_ACCOUNT_BALANCING_QUESTION_UID,
      "optional": true,
      "question": [] // list of checkbox questions. Should be filled with questions in data service, as it depends on selected portfolios and related clearing accounts
    },
    {
      "uid": "bank_account",
      "type": "reference-accounts",
      "question_text": "Gespeichertes Referenzkonto auswählen:",
      "custom_classes": {"labelRoot": "bold"},
      "bank_accounts": [],
      "size": 8,
      "optional": false
    },
    {
      "uid": "origin_of_assets",
      "question_text": "Herkunft der Vermögenswerte",
      "question": [
        {
          "uid": "origin_of_assets['saved_assets']",
          "type": "checkbox",
          "question_text": "Angespartes Vermögen",
        },
        {
          "uid": "origin_of_assets['wages_and_income']",
          "type": "checkbox",
          "question_text": "Lohn, Einkommen",
        },
        {
          "uid": "origin_of_assets['rental_and_leasing']",
          "type": "checkbox",
          "question_text": "Vermietung und Verpachtung",
        },
        {
          "uid": "origin_of_assets['investment_income']",
          "type": "checkbox",
          "question_text": "Kapitalerträge /gewerbl. Tätigkeit",
        },
        {
          "uid": "origin_of_assets['special_payments']",
          "type": "checkbox",
          "question_text": "Sonderzahlungen / Auszahlungen Lebensvers.",
        },
        {
          "uid": "origin_of_assets['sale_of_real_estate']",
          "type": "checkbox",
          "question_text": "Verkauf von Immobilien / Firmen",
        },
        {
          "uid": "origin_of_assets['heritage']",
          "type": "checkbox",
          "question_text": "Erbe",
        },
        {
          "uid": "origin_of_assets['others']",
          "type": "checkbox",
          "question_text": "Sonstiges",
        },
      ],
      "isValid": function(){
        if (!this.optional) {
          const isValid = this.question.some(q => q.answer);
          this.error = isValid ? null : FIELD_REQUIRED_MSG;
          return isValid;
        }
        return true
      }
    },
    {
      "uid": "origin_of_assets_evidence",
      "question_label": "Belege zur Herkunft des Vermögens liegen vor.",
      "question_info": "Bitte beachten Sie, dass ab einem Anlagevolumen von 500.000 Euro die Vorlage von Nachweisen verpflichtend ist.",
      "type": "normal",
      "behaviour": ["radio"],
      "config": {
        "choices": [
          {
            "id": 0,
            "uid": "0",
            "text": "Ja"
          },
          {
            "id": 1,
            "uid": "1",
            "text": "Nein"
          }
        ]
      },
      "optional": true,
    },
    {
      "uid": "notes_for_origin_of_assets",
      "question_text": "Anmerkungen zur Herkunft der Vermögenswerte",
      "question_info": "Sofern insbesondere risikobasiert eine Erklärung und/oder Erläuterung zur Herkunft der Vermögenswerte erfolgen soll, tragen Sie diese oder sonstige Anmerkungen bitte hier ein.",
      "type": "input",
      "config": {
        "multiline": true
      },
      "validateImmediately": true,
      "isValid": function() {

        let isValid = true
        this.error = null

        if (this.answer) {
          isValid = validateMultilineContent(this.answer, 100)
          this.error = isValid ? null : FIELD_DOES_NOT_FITT_MSG
        }

        return isValid
      }
    }
  ]
};

const congratsStep = {
  "uid": CONGRATS_STEP_ID,
  "name": "Kundendaten",
  "help": "Die Kundendaten wurde erfolgreich gespeichert. Fahren Sie fort mit Aufklärung & Protokoll um den prozess erfolgreich abschließen zu können.",
  "behaviour": ["congrats"]
};

const __buildFromStep = (customer_type, members, step, stepPreparationCallback, withUserName=false) => {
  return Array.isArray(members) && members.map((member, index) => {

    const setQuestionUID = (question) => {
      if (question.question) {
        question.question.forEach(setQuestionUID)
      } else {
        question.uid = buildQuestionUIDForMember(question.uid, index, customer_type)
      }
    }

    let stepCopy = _.cloneDeep(step);

    if (withUserName) {
      stepCopy.withUserName = true
      stepCopy.name = `${stepCopy.name} - ${_.get(member, 'personalInformation.firstName')} ${_.get(member, 'personalInformation.lastName')}`
    }

    stepPreparationCallback && stepPreparationCallback(stepCopy)
    stepCopy.uid = `${stepCopy.uid}0${index + 1}`
    stepCopy.question.forEach(setQuestionUID)

    return stepCopy
  })
}
/**
 * Build steps for members confirmation.
 * @param {Array<*>} members - Members data
 * @return {Array<*>} - List of members confirmation steps
 * */
const buildMembersConfirmationSteps = (customer_type, members) => {

  let userConfirmationSteps = __buildFromStep(customer_type, members, individual_user_confirmation_step, (step) => {
    // remove education step for family members, it should be present only for wrapper
    if (step.uid === 'A1'){
      // loop over questions to find beruf
      step.question = step.question.map(q => {
        if (q.uid === 'A-beruf'){
          // filter out education question from beruf inner question list
          q.question = q.question.filter(innerQuestion => {
            innerQuestion.size = 6
            return innerQuestion.uid !== 'education'
          })
        }
        return q
      })
    }
  });

  // map over steps to set proper name for each couple member
  userConfirmationSteps.map((step, index) => {
    step.name = `Angaben zu Depotinhaber ${index + 1}`
    return step
  })

  let taxSteps = __buildFromStep(customer_type, members, tax_step, undefined, true)
  let legitimationSteps = __buildFromStep(customer_type, members, legitimation_step, undefined, true)

  return _.flatten([userConfirmationSteps || [], taxSteps || [], legitimationSteps || []])
}

/**
 * Build steps according to provided customer data.
 * @param {*} customerData - Customer data
 * @param {*} isVlPlan - isVlPlan answers data
 * @return {*} - Prepared steps
 * */
export const buildDataSteps = (customerData, isVlPlan) => {
  const generalSteps = [refAccountStep, congratsStep]

  let confirmationStep = individual_user_confirmation_step
  let employerInformationStep = employer_information_step
  let membersSteps = []
  if (customerData.customer_type == CUSTOMER_TYPES.COUPLE) {
    confirmationStep = family_user_confirmation_step

    // add info text for couple account
    confirmationStep.question = confirmationStep.question.map(q => {
      if (q.uid === 'A-beruf'){
        q.question = q.question.map(subQuestion => {
          if(subQuestion.uid === 'education'){
            subQuestion.tooltip = 'Was ist der höchste Bildungsabschluss des ausgewählten Depotinhabers?';
          }
          return subQuestion
        });
      }
      return q
    })

    membersSteps = buildMembersConfirmationSteps(customerData.customer_type, customerData.relationships) || []
  } else if (customerData.customer_type == CUSTOMER_TYPES.MINOR) {
    const customerType = customerData.customer_type;
    const members = customerData.relationships;
    confirmationStep = _.cloneDeep(confirmationStep);
    const minorTaxStep = _.cloneDeep(tax_step);
    const minorLegitimationStep = _.cloneDeep(legitimation_step);

    confirmationStep.name = 'Angaben des Minderjährigen';
    minorTaxStep.name = 'Steuerpflicht - Minderjähriger';
    minorLegitimationStep.name = 'Identifikationsunterlagen Minderjähriger';

    const legitType = minorLegitimationStep.question.find(q => q.uid === "legitimation['type']");
    legitType.config.choices = LEGITIMATION_MINOR_TYPES;
    legitType.config.mapping.config = LEGITIMATION_MINOR_MAPPING;

    const confirmationSteps = __buildFromStep(customerType, members, individual_user_confirmation_step, (step) => {
      step.question.forEach(q => {
        if (q.uid === 'Berechtigter'){
          q.optional = true;
          q.config = {}; // clean config as it's CRM field not for minor but for self
        }
      })
    }, false)
    // map over steps to set proper name for each guardian member
    confirmationSteps.forEach((step, index) => {
      step.name = `Angaben zum Erziehungsberechtigten ${index + 1}`;
    })

    const taxSteps = __buildFromStep(customerType, members, tax_step, undefined, true);

    const legitimationSteps = __buildFromStep(customerType, members, legitimation_step, undefined, true);

    membersSteps = [...confirmationSteps, minorTaxStep, ...taxSteps, minorLegitimationStep, ...legitimationSteps];
  } else {
    membersSteps = [tax_step, legitimation_step]
  }

  const steps = [confirmationStep]

  if (isVlPlan) {
    steps.push(employerInformationStep)
  }

  return {
    steps: _.flatten([
      ...steps,
      membersSteps,
      generalSteps
    ])
  }
}
