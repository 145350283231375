import {makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
  tableSection: {
    maxWidth: '100%',
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    '& tr': {
      height: 58
    },
    minWidth: 900
  },

  tableHead: {
    backgroundColor: '#f9f9f9',

    '& tr > td': {
      color: '#80858C !important',
      verticalAlign: 'middle !important',
    }

  },
  tableCell: {
    verticalAlign: 'middle !important',
    '&:first-child': {
      width: 250,
      paddingLeft: 10
    },
  },
  error: {
    fontFamily: 'Roboto-Regular',
    padding: '10px 0',
    fontSize: 14,
    margin: 0
  }
}))