import React, { forwardRef, useImperativeHandle } from  'react';
import PropTypes from 'prop-types';

// region Third party components
import Grid from '@material-ui/core/Grid'
// endregion

import {useFiltersContext} from "../../Filters";
import useCommonStyles from '../common_styles'
import TimeFramePicker from '../../../../../CustomerDashboardV2/components/InvestmentDetailsNavigation/PortfolioTimeSelector';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';
import {formatDateShouldBeGraterThenErrorMessage} from "../../../../../../utils/utils";


const DateRange = forwardRef(({name, GridProps, loading, disabled}, ref) => {

  const commonClasses = useCommonStyles()

  const { filters, setFilterValue } = useFiltersContext();

  const datesTypeName = `${name}Type`

  useImperativeHandle(ref, () => ({
    isValid: () => {
      return !!filters[name];
    }
  }))

  const [startDate, endDate, minDate] = React.useMemo(() => {
    if (!filters[name]) {
      return [null, null]
    }

    return filters[name]
  }, [filters[name]]);

  const datesType = (() => {
    return filters[datesTypeName]
  })();


  const handleSelectedDatesChanged = (dates, datesType) => {
    setFilterValue(name, [dates.start, dates.end, minDate])
    setFilterValue(datesTypeName, datesType)
  }

  const PARSED_DATE = {
    start: startDate,
    end: endDate
  }

  return (
    <Grid item {...GridProps}>
      <p className={clsx(commonClasses.inputLabel, disabled && commonClasses.inputLabelDisabled)}>Zeitraum auswählen</p>
      {loading ? (
        <Skeleton className={commonClasses.skeleton}/>
      ) : (
        <TimeFramePicker
          customHeader={null}
          calculationDates={PARSED_DATE}
          calculationDatesType={datesType}
          startOfInvestment={minDate}
          minDateErrorMessage={!!minDate && formatDateShouldBeGraterThenErrorMessage(minDate)}
          handleCalculationDatesChanged={handleSelectedDatesChanged}
          loading={loading}
          useFilterStyles
        />
      )}
    </Grid>
  )
})

DateRange.propTypes = {
  /** Name of the field */
  name: PropTypes.string.isRequired,
  /** Field placeholder */
  placeholder: PropTypes.string,
  /** Grid options */
  GridProps: PropTypes.any
};

export default DateRange;