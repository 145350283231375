import React from 'react'

import useStyles from './styles'
import {useDocumentsPreviewSectionContext} from "../../index";
import {DocumentEditor, DocumentEditorTabs, DocumentNavigation} from "../../../DocumentCenter/components";
import {CircularProgress, Divider, Grid, Paper} from "@material-ui/core";
import _, {toInteger} from "lodash";
import {ACTIVE_PAGE, PAGE_COUNT, SCALE} from "../../../DocumentCenter/constants";
import SelectFormElement from "../../../RiskProfiling/components/StepContent/components/formElement/SelectFormElement";
import {APPROVAL_STATUS, APPROVAL_STATUS_SELECT_OPTIONS} from "../../constants";
import RichTextEditor from "../../../RiskProfiling/components/StepContent/components/formElement/RichTextEditor";
import {PrimaryButton} from "../../../../components/Buttons";
import {validateMultilineContent} from "../../../RiskProfiling/utils";
import {FIELD_DOES_NOT_FITT_MSG} from "../../../RiskProfiling/constants";


function DocumentsPreviewSection() {

  const classes = useStyles()

  const {
    documents,
    onRemoveDocumentFromPreview,
    onSaveDocumentChanges,
    statusUpdateLoading,
    statusUpdateErrors,
    editable,
    setStatusUpdateErrors
  } = useDocumentsPreviewSectionContext()

  const [selectedDocumentId, setSelectedDocumentId] = React.useState()
  const [documentsMeta, setDocumentsMeta]= React.useState({})

  React.useEffect(() => {
    const metaCopy = _.cloneDeep(documentsMeta)
    documents.forEach((document) => {
      const docMeta = metaCopy[document.id] || {}
      metaCopy[document.id] = {
        ...docMeta,
        ...document,
        activePage: docMeta.activePage || 1,
        scale: docMeta.scale || 1.0,
      }
    })
    setDocumentsMeta(metaCopy)
    setSelectedDocumentId(_.find(documents, (document) => document.id == selectedDocumentId) ? selectedDocumentId : _.get(documents, '0.id'))
  }, [documents])

  const onNextPage = () => {
    let metaCopy = _.cloneDeep(documentsMeta)
    if (metaCopy[selectedDocumentId].activePage < metaCopy[selectedDocumentId].contentPageCount) {
      metaCopy[selectedDocumentId].activePage++
      setDocumentsMeta(metaCopy)
    }
  }

  const onPrevPage = () => {
    if (documentsMeta[selectedDocumentId].activePage > 1) {
      let metaCopy = _.cloneDeep(documentsMeta)
      metaCopy[selectedDocumentId].activePage--
      setDocumentsMeta(metaCopy)
    }
  }

  const switchToPage = (page) => {
    if (0 < page && page <= getDocumentMeta(PAGE_COUNT)) {
      let metaCopy = _.cloneDeep(documentsMeta)
      metaCopy[selectedDocumentId].activePage = toInteger(page)
      setDocumentsMeta(metaCopy)
    }
  }

  const onPageScaleUp = () => {
    let metaCopy = _.cloneDeep(documentsMeta)
    metaCopy[selectedDocumentId].scale += 0.1
    setDocumentsMeta(metaCopy)
  }

  const onPageScaleDown = () => {
    let metaCopy = _.cloneDeep(documentsMeta)
    metaCopy[selectedDocumentId].scale -= 0.1
    setDocumentsMeta(metaCopy)
  }

  const handleDocumentStatusChange = (status) => {
    let metaCopy = _.cloneDeep(documentsMeta)
    metaCopy[selectedDocumentId].status = status
    setDocumentsMeta(metaCopy)
    setStatusUpdateErrors && setStatusUpdateErrors(undefined) // // after status is changed remove errors
  }

  const handleDocumentFeedbackChange = (feedback, source, editor) => {
    if (source != 'user') {
      return
    }
    let metaCopy = _.cloneDeep(documentsMeta)
    metaCopy[selectedDocumentId].feedback = !_.isEmpty(editor.getText()) && feedback
    metaCopy[selectedDocumentId].errors = {
      ...(metaCopy[selectedDocumentId].errors || {}),
      // We use 840px as page height (same as we have for PDFs)
      feedback: !validateMultilineContent(feedback, 840) && FIELD_DOES_NOT_FITT_MSG
    }
    setDocumentsMeta(metaCopy)
  }

  const getDocumentMeta = (META_TYPE) => {
    if (documentsMeta && documentsMeta[selectedDocumentId]) {
      return documentsMeta[selectedDocumentId][META_TYPE]
    }
  }

  const getDocumentError = (fieldName) => {
    return (getDocumentMeta('errors') || {})[fieldName]
  }

  const documentsPrepared = React.useMemo(() => {
    return documents.map((document) => ({
      ...document,
      name: document.document_name,
      content: document.document_path
    }))
  }, [documents])

  const handlePageRenderSuccess = (documentId, pageNumber, documentPageCounter) => {
    let metaCopy = _.cloneDeep(documentsMeta)
    for (const [documentId, pagesCount] of Object.entries(documentPageCounter)) {
      if (metaCopy[documentId]) {
        metaCopy[documentId].contentPageCount = pagesCount
      }
    }

    setDocumentsMeta(metaCopy)
  }

  const activeDocumentData = React.useMemo(() => {
    return _.find(documents, (document) => document.id == selectedDocumentId)
  }, [selectedDocumentId])

  const isSelectedDocumentPdf = React.useMemo(() => {

    return activeDocumentData && _.toLower(activeDocumentData.document_path).endsWith('.pdf')

  },  [activeDocumentData])

  const handleSaveButtonClick = () => {
    onSaveDocumentChanges && onSaveDocumentChanges(
      selectedDocumentId, getDocumentMeta('status'), getDocumentMeta('feedback'))
  }

  const handleTabChange = (documentId) => {
    !statusUpdateLoading && setSelectedDocumentId(documentId)
    setStatusUpdateErrors && setStatusUpdateErrors(undefined) // after tab is changed remove errors
  }

  const handleRemoveDocumentFromPreview = (documentId) => {
    !statusUpdateLoading && onRemoveDocumentFromPreview(documentId)
  }

  const disabledEdit = !editable || !_.get(activeDocumentData, 'approval.editable');
  const isOnline = _.get(activeDocumentData, 'isOnline', false);
  let approvalStatusSelectOptions = APPROVAL_STATUS_SELECT_OPTIONS;
  if (isOnline) {
    approvalStatusSelectOptions = approvalStatusSelectOptions
      .filter(o => [APPROVAL_STATUS.REJECTED.value,APPROVAL_STATUS.TRANSMITTED.value].includes(o.id));
  }

  return (
    <Grid container spacing={2} style={{height: 700}}>
      <Grid item xs={8}>
        <Paper square={true} classes={{ root: classes.editorWrapper }}>
            <DocumentEditorTabs
              activeDocumentId={selectedDocumentId}
              selectedDocuments={documentsPrepared}
              downloadedDocuments={[]}
              handleTabChange={handleTabChange}
              handleTabClose={handleRemoveDocumentFromPreview}
            />
          <Divider style={{ backgroundColor: '#D8DCDF' }} />
          {isSelectedDocumentPdf && (
            <DocumentNavigation
              activePage={getDocumentMeta(ACTIVE_PAGE)}
              contentPageCount={getDocumentMeta(PAGE_COUNT)}
              prevPage={onPrevPage}
              nextPage={onNextPage}
              switchToPage={switchToPage}
              scale={getDocumentMeta(SCALE)}
              scalePlus={onPageScaleUp}
              scaleMinus={onPageScaleDown}
            />
          )}
          <div style={{ position: 'relative' }}>
            {isSelectedDocumentPdf ? (
              <DocumentEditor
                scale={getDocumentMeta(SCALE)}
                onRenderSuccess={handlePageRenderSuccess}
                activePage={getDocumentMeta(ACTIVE_PAGE) ||1}
                activeDocument={selectedDocumentId}
                activeDocuments={documentsPrepared}
                documentsData={{data: documentsPrepared}}
              />
            ) : (
              <img src={activeDocumentData && activeDocumentData.document_path} style={{width: '100%'}}/>
            )}

          </div>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper square={true} className={classes.controlsWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectFormElement
                label="Status"
                value={getDocumentMeta('status')}
                withColorsIndicators
                options={approvalStatusSelectOptions}
                onChange={handleDocumentStatusChange}
                disabled={disabledEdit}
                custom_classes={{
                  labelRoot: classes.inputLabelRoot
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                label="Kommentar"
                customClasses={{
                  labelRoot: classes.inputLabelRoot
                }}
                required={getDocumentMeta('status') == APPROVAL_STATUS.REJECTED.value}
                value={getDocumentMeta('feedback') || ''}
                onChange={handleDocumentFeedbackChange}
                uid={`approval-feedback`}
                error={_.get(statusUpdateErrors, 'feedback') || getDocumentError('feedback')}
                disabled={disabledEdit}
                hideToolbar={disabledEdit}
                hideDisabledOverlay
              />
            </Grid>
            {editable && _.get(activeDocumentData, 'approval.editable') && (
              <Grid item xs={12} className={classes.buttonsWrapper}>
                <PrimaryButton
                  variant="text"
                  text={"Bearbeiten"}
                  onButtonClick={() => onRemoveDocumentFromPreview(selectedDocumentId)}
                  disabled={statusUpdateLoading}
                />
                <PrimaryButton
                  text={"Speichern"}
                  icon={statusUpdateLoading && <CircularProgress size={25} style={{color: 'rgba(0, 0, 0, 0.26)'}}/>}
                  onButtonClick={handleSaveButtonClick}
                  disabled={statusUpdateLoading || getDocumentError('feedback')}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DocumentsPreviewSection
