import React from "react";
import {withRouter} from "react-router-dom";

import DashboardTable from "../../DashboardTable/DashboardTable";
import tableStructure from "./table-data";
import useStyles from "./styles";
import {buildComponentsWithSubItemsDataSource} from "../InstrumentsAllocationTable";
import _ from "lodash";


const ProfitLossTable = (props) => {
  const classes = useStyles();

  const {
    data,
    expanded,
    match: {
      params: {
        customer_id
      }
    },
    expandedSubItems,
    onExpandedSubItems
  } = props;


  const dataSource = (() => {
    const components = data && data.components || [];
    return buildComponentsWithSubItemsDataSource(components)
  })()

  const handleExpandedSubItemsChange = (elemId) => {
    onExpandedSubItems(data.id, {
      ...expandedSubItems,
      [elemId]: _.isNil(_.get(expandedSubItems, elemId)) ? true : !_.get(expandedSubItems, elemId)})
  };

  return (
    <DashboardTable
      structure={tableStructure}
      dataSource={dataSource}
      expanded={expanded}
      tableClasses={classes}
      useInternalCollapse
      handleExpandedSubItemsChange={handleExpandedSubItemsChange}
      options={{
        customer_id,
        profit_loss_eur: data.profit_loss_eur,
        profit_loss_perc: data.profit_loss_perc,
        skipIndexReset: true
      }}
    />
  );
};


export default withRouter(ProfitLossTable);