import React from "react";
import {connect} from "react-redux";
import {Dialog, IconButton} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DashboardTable from "../../../../../../../../components/DashboardTable/DashboardTable";
import {default as IconBtn} from "../../../../../../../../components/Buttons/IconButton";
import PlusBackgroundIcon from "../../../../../../../../images/PlusBackgroundIcon";
import Create from "../../../../../../components/TradeStep/components/Create/Create";
import PaymentPlanEditTable from "../../../PaymentPlanEditTable/PaymentPlanEditTable";
import {getPayoutPlansTableStructure} from "../../../PaymentPlanEditTable/table-data";
import {getPaymentPlanMaxStartDate, TRANSACTION_TYPE_VALUES} from "../../../../../../constants";

function EditTable(props) {
  const {
    portfolio,
    allInstruments,
    portfolioComponents,
    action,
    addPaymentPlanOption,
    removePortfolioPaymentPlanOption,
    removePaymentPlanOption
  } = props;

  return (
    <PaymentPlanEditTable
      addPaymentPlanOption={addPaymentPlanOption}
      removePortfolioPaymentPlanOption={removePortfolioPaymentPlanOption}
      removePaymentPlanOption={removePaymentPlanOption}
      transactionType={TRANSACTION_TYPE_VALUES.PAYOUT_PLAN}
      {...props}
    >
      {
        (EditTableProps) => (
          <>
            <DashboardTable
              tableLayout={"auto"}
              dataSource={EditTableProps.indexedGroupInstruments || []}
              structure={getPayoutPlansTableStructure(action, portfolio.isModelportfolio)}
              tableClasses={EditTableProps.classes}
              withFooter={false}
              expanded
              withInputs
              options={{
                availableDiscounts: portfolio.availableDiscounts,
                rotationOptions: portfolio.isModelportfolio
                  ? portfolio.availableMPPayoutPlanPeriodicPlans
                  : portfolio.availablePayoutPlanPeriodicPlans,
                fromDateOptions: portfolio.isModelportfolio
                  ? portfolio.availableMPPayoutPlanExecutionDates
                  : portfolio.availablePayoutPlanExecutionDates,
                handleDiscountChange: EditTableProps.handleDiscountChange,
                handleRotationChange: EditTableProps.handleRotationChange,
                handleDateChange: EditTableProps.handleDateChange,
                handleAmountChange: EditTableProps.handleAmountChange,
                onRemoveClick: EditTableProps.onRemoveClick,
                onRemoveAllClick: EditTableProps.onRemoveAllClick,
                isModelportfolio: portfolio.isModelportfolio,
                tillDateEnabled: portfolio.ordering_payout_plan_till_date_enabled,
                maxStartDate: (item) => getPaymentPlanMaxStartDate(item, portfolio, action)
              }}
            />
            <>
              {EditTableProps.isEnableAdding(action) && (
                <div className={EditTableProps.classes.addBtnContainer}>
                  <IconBtn
                    label={'Entnahmeplan Positionen hinzufügen'}
                    component={PlusBackgroundIcon}
                    onClick={() => EditTableProps.handleAddSavingPlanButtonClick(action)}
                    size={36}
                  />
                </div>
              )}
              <Dialog maxWidth="lg" fullWidth disableBackdropClick={true} open={EditTableProps.open} onClose={() => EditTableProps.setOpen(false)}>
                <DialogTitle>
                  <div className={EditTableProps.classes.header}>
                    <IconButton aria-label="close" className={EditTableProps.classes.closeButton} onClick={() => EditTableProps.setOpen(false)}>
                      <CloseIcon/>
                    </IconButton>
                  </div>
                </DialogTitle>
                <Create
                  portfolioInstruments={(portfolioComponents || []).filter(i => !!i.isin)}
                  instruments={allInstruments}
                  extraFilters={{custodian: portfolio.companyId}}
                  handleAddItems={(assets) => EditTableProps.handleAddItems(action, assets)}
                  portfolioOnly={true}
                  action={action}
                  instrumentsHandler={EditTableProps.instrumentsHandlers[action]}
                  customerId={props.customer_id}
                />
              </Dialog>
            </>
          </>
        )
      }
    </PaymentPlanEditTable>
  )
}

export default connect() (EditTable);
