import React from 'react';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

/* BCA modules*/
import useStyles from './styles';
import WarningTooltip from '../../../../components/WarningTooltip';

import {DEFAULT_DISTRIBUTION_TYPE, DISTRIBUTION_TYPES} from '../../constants';
import _ from "lodash";

export default (props) => {
    const classes = useStyles();

    const [value, setValue] = React.useState(props.selected || DEFAULT_DISTRIBUTION_TYPE.value);

    // workaround to update inner values from outside
    React.useEffect(() => {
      if (!_.isNil(props.selected)) {
        setValue(props.selected);
      }
    }, [props.selected]);

    const handleChange = (event) => {
        setValue(+event.target.value);
        props.handleChanged(+event.target.value);
    };

    return (
        <div className={classes.container}>
          {/* todo it seems that postboxDisabled was used by old reporting. Remove if it is not used anymore */}
          <FormControl disabled={props.disabled || props.postboxDisabled} component="fieldset">
            <FormLabel component="legend" classes={{root: classes.formLabel, focused: classes.focusedLabel}}>
              <span>Zusendung</span>
            </FormLabel>
            <RadioGroup
              aria-label="Zusendung"
              name="distributionType"
              value={`${value}`}
              onChange={handleChange}
            >
              {Object.values(props.distributionTypes || DISTRIBUTION_TYPES).map(distributionType =>{
                const label = <>
                  {distributionType.description}
                  {!!distributionType.infoIconText &&
                    <WarningTooltip
                      width={400}
                      title={distributionType.infoIconText}
                    />
                  }
                </>

                return(
                  <FormControlLabel
                    value={`${distributionType.value}`}
                    label={label}
                    classes={{label: classes.formControlLabel}}
                    control={
                    <Radio color="primary" classes={{root: classes.radio}}/>
                  }/>
                )
              })}
            </RadioGroup>
          </FormControl>
        </div>
    )
}