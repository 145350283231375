import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  delimiter: {
    position: 'relative',

    '&::before': {
      content: '"-"',
      position: 'absolute',
      fontFamily: 'Roboto-Bold',
      top: 44,
      left: -3,

      [theme.breakpoints.down("sm")]: {
        left: -2,
      }
    }
  },
}))