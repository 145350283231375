import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  modalTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 20,
    color: '#202A38',
    margin: 0,
  },
  riskIndicator: {
    fontFamily: 'Roboto-Regular',
    fontSize: 20,
    color: '#202A38'
  },
  inputTextAreaRoot: {
    marginBottom: 10,
    border: '1px solid #adb6bd',
    borderRadius: 4,
    padding: 6,

    '&::before': {
      display: 'none'
    },

    '&::after': {
      display: 'none'
    },

    '& svg': {
      transition: 'all',
      transitionDuration: 250
    },
  },
  inputRoot: {
    marginBottom: 10,
    border: '1px solid #adb6bd',
    borderRadius: 4,
    height: 50,
    padding: 6,

    '&::before': {
      display: 'none'
    },

    '&::after': {
      display: 'none'
    },

    '& svg': {
      transition: 'all',
      transitionDuration: 250
    },
  },
  inputError: {
    borderColor: 'red'
  },

  inputMultilineRoot: {
    height: 'auto',
  },
  inputFocused: {
    border: `1px solid ${theme.palette.primary.main}`,

    '& svg': {
      fill: theme.palette.primary.main
    }
  },
  labelRoot: {
    top: -8,
    fontSize: 16,
    color: '#202A38',
    transform: 'none',
    position: 'absolute',
    fontFamily: 'Roboto-Bold'
  },
  labelFocused: {},
  labelAsterisk: {
    color: theme.palette.primary.main
  }, 
  stickyButtonRoot: {
    borderRadius: 8,
    height: '50px !important',
    marginLeft: 15
  },
  stickyButtonDisabled: {
    backgroundColor: '#99D3F5 !important',
    color: 'white !important'
  },
  stickyButtonOutlinedDisabled: {
    border: '2px solid #00000033 !important',
    backgroundColor: 'white !important',
    color: '#00000033 !important'
  },
  stickyButtonOutlinedRoot: {
    borderRadius: 8,
    height: '50px !important',
    marginRight: 15,
    backgroundColor: 'white !important'
  },
  closeButton: {
    float: 'right',
    position: 'relative',
    top: -20,
    right: -20
  },

  buttonContainer: {
    padding: '15px 0', 
    display: 'flex', 
    justifyContent: 'flex-end',

    [theme.breakpoints.down(710)]: {
      flexDirection: 'column',

      '& button': {
        margin: 0,
        marginTop: 15
      }
    }
  },
  typeLabel: {
    fontFamily: 'Roboto-Regular',
    fontSize: 20,
    color: '#202A38',
    margin: 0,
    marginTop: 2
  },
  radioLabel: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38'
  }
});

export default makeStyles(styles)