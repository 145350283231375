import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';

import useStyles from './styles';
import Tooltip from '../../Tooltip';
import { toGermanFormat } from '../../../utils/numberFormater';

const WEEK_BOUND = 0.4;
const STRONG_BOUND = 0.7;
const NO_DATA_SYMBOL = '*';

const CorrelationTable = ({ correlationData, productsData }) => {

  const classes = useStyles();

  const [activeRow, setActiveRow] = React.useState(undefined);
  const [activeColumn, setActiveColumn] = React.useState(undefined);

  // check if there's weight field in products
  const withWeight = _.has(Object.values(productsData), '0.weight');

  const handleHover = (event) => {
    let dataset = event.target.dataset;

    setActiveRow(Number(dataset.row));
    setActiveColumn(Number(dataset.column));

  };

  const resetActive = () => {
    setActiveRow(undefined);
    setActiveColumn(undefined);
  };

  const getCell = (key, value, counterColumns, isHighlighted) => {
    const extraClass =
      _.isNil(value) ? undefined :
      value === NO_DATA_SYMBOL ? classes.emptyValue :
      value < 0 ? classes.negative :
      (value >= 0 && value < WEEK_BOUND) ? classes.weak :
      (value >= WEEK_BOUND && value < STRONG_BOUND) ? classes.middle :
      value >= STRONG_BOUND ? classes.strong : undefined;

    // Note: css prio is from left -> right and top -> bottom
    return <td
      key={key}
      className={clsx(isHighlighted && classes.highlightedValue, classes.tableCell, extraClass)}
      data-column={counterColumns}
      data-row={counter}
      onMouseOver={handleHover}
      onMouseLeave={resetActive}
    >{_.isNumber(value) ? toGermanFormat(value) : value}</td>
  };

  let heading = [
    <td className={clsx(classes.columnName)} key={0}>
      <span className={classes.nameHeader}>Produktname</span>
      {withWeight && <span className={clsx(classes.weightColumn, classes.weightHeader)}>Anteile</span>}
    </td>,
  ];
  let counter = 1;
  let rows = [];
  let hasNoData = false;

  for (const [productId, productData] of Object.entries(productsData)) {
    const isHighlighted = productData.highlighted;

    heading.push(
      <td className={clsx(classes.tableCell, activeColumn === counter && classes.active)}>
        {counter}
      </td>
    );

    let cells = [
      <td className={clsx(classes.columnTitle, activeRow === counter && classes.active, isHighlighted && classes.highlighted)} key={counter}>
        <div className={classes.titleWrapper}>
          <span className={classes.columnNumber}>{counter}</span>
          <Tooltip title={productData.name} placement={'right'}>
            <span className={classes.assetName}>
              {isHighlighted && (
                <i className={clsx("fa fa-star fa-lg", classes.highlightIcon)}/>
              )}
              {productData.name}
            </span>
          </Tooltip>
          {withWeight && (
            <span className={classes.weightColumn}>
              {toGermanFormat(productData.weight * 100, '', ' %')}
            </span>
          )}
        </div>
      </td>,
    ];

    let counterColumns = 1;

    for (const [correlatedProductId, correlatedProductData] of Object.entries(productsData)) {

      let key = productId + '_' + correlatedProductId;

      if (correlatedProductId === productId) {
        cells.push(getCell(key, null, counterColumns));
      } else {
        const value = _.get(correlationData, [productId, correlatedProductId]) || NO_DATA_SYMBOL;
        if (value === NO_DATA_SYMBOL) hasNoData = true;
        cells.push(getCell(key, value, counterColumns, isHighlighted || correlatedProductData.highlighted));
      }

      counterColumns++;
    }

    counter++;

    rows.push(<tr key={productId}>{cells}</tr>);
  }

  return (
    <table className={classes.correlationMatrix}>
      <thead>
      <tr>
        {heading}
      </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
      {hasNoData && (
        <p style={{marginTop: 10}}>
          {NO_DATA_SYMBOL} Für dieses Instrument stehen für den gewählten Zeitraum nicht ausreichend Daten zur Verfügung.
        </p>
      )}
    </table>
  );
};

export default CorrelationTable;