import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  item: {
    marginRight: 30,

    '& > b': {
      marginRight: 5
    }
  },
}))