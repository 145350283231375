import React from "react";
import {withStyles} from "@material-ui/core";

import {parseResponse} from "../../../../../../utils/api";
import styles from "../../../../../VirtualPortfolioPage/Tabs/KeyFigures/items/ChartRollingSharpeRatio/styles";
import {ChartRollingSharpeRatio} from "../../../../../VirtualPortfolioPage/Tabs/KeyFigures/items/ChartRollingSharpeRatio";
import _ from 'lodash'


class ProViewRollingSharpeRatioChart extends ChartRollingSharpeRatio {

  componentDidUpdate(prevProps, prevState, snapshot) {
    let dateHasChanged = this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate
    let benchmarksHasChanged = JSON.stringify(this.props.benchmarks) !== JSON.stringify(prevProps.benchmarks)
    let assetsHasChanged = JSON.stringify(this.props.selectedAssets) != JSON.stringify(prevProps.selectedAssets)

    if(dateHasChanged || benchmarksHasChanged || assetsHasChanged) {
      this.fetchRollingSharpeRatioData();
    }
    if(!_.isNil(this.props.showBenchmark)  && this.state.showBenchmark !== this.props.showBenchmark) {
      this.setState({
        showBenchmark: this.props.showBenchmark
      })
    }
    if(_.get(this.props,'lastBenchmarkUpdateTime', undefined) !== _.get(prevProps, 'lastBenchmarkUpdateTime', undefined)){
      this.fetchRollingSharpeRatioData();
    }
  }

  fetchRollingSharpeRatioData() {
    this.setState({
      benchmarkDetails: undefined,
      RollingSharpeRatioBMData: undefined,
      RollingSharpeRatioBMError: undefined,
      RollingSharpeRatioBMLoading: true,
      RollingSharpeRatioData: undefined,
      RollingSharpeRatioError: undefined,
      RollingSharpeRatioLoading: true,
      showBenchmark: this.props.showBenchmark
    })

    this.props.dataProvider.getRollingSharpeData(this.props.customerId, this.props.portfolioId, this.props.startDate, this.props.endDate, !this.props.isAllAssetsSelected ? this.props.selectedAssets : [], this.props.investmentStrategyId)
      .then((response) => {
        parseResponse(response, 'rolling_sharpe_ratio',
          data => {
            this.setState({
              RollingSharpeRatioData: data['indicators'],
              RollingSharpeRatioError: undefined,
              RollingSharpeRatioLoading: false
            })
          },
          error => {
            this.setState({
              RollingSharpeRatioData: undefined,
              RollingSharpeRatioError: error,
              RollingSharpeRatioLoading: false
            })
          }
        )

      })

    if (!_.isEmpty(this.props.benchmarks)) {
      this.props.dataProvider.getRollingSharpeBMData(this.props.customerId, this.props.portfolioId,  this.props.startDate, this.props.endDate, this.props.benchmarks, this.props.investmentStrategyId)
        .then((response) => {
          parseResponse(response, 'rolling_sharpe_ratio_bm',
            data => {
              this.setState({
                RollingSharpeRatioBMData: data['indicators'],
                RollingSharpeRatioBMError: undefined,
                RollingSharpeRatioBMLoading: false
              })
            },
            error => {
              this.setState({
                RollingSharpeRatioBMData: undefined,
                RollingSharpeRatioBMError: error,
                RollingSharpeRatioBMLoading: false
              })
            }
          )

          this.setState({
            benchmarkDetails: response['benchmark_details'],
          })

        })
    }
  }
}

export default withStyles(styles)(ProViewRollingSharpeRatioChart)