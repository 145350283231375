import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {withStyles, Dialog, DialogTitle, DialogContent, IconButton, Typography} from "@material-ui/core";

import { styles } from './styles'
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import MarkIcon from "../../../FactSheetsPage/Tabs/TargetMarket/items/TargetMarketWidget/MarkIcon";

class MyComponent extends Component {

  render() {

    const {
      classes,
      open,
      onClose,
      instrumentName,
      criterias
    } = this.props

    const renderAcceptanceCriterias = () => {
      if (!criterias) return null

      let criteriasRender = []

      for (let section in criterias) {
        for (let field in criterias[section]) {
          criteriasRender.push(
            <li className={clsx([classes.item, classes.itemMark])} key={`${section}_${field}`}>
              <Typography classes={{ root: classes.title }}>{ criterias[section][field].title }</Typography>
              <span className={classes.values}>
                <MarkIcon value={ criterias[section][field].value } />
              </span>
            </li>
          )
        }
      }

      return (
        <ul className={classes.list}>
          { criteriasRender }
        </ul>
      )
    }

    return (
      <Dialog open={open} onClose={onClose} maxWidth={"sm"} >
        <DialogTitle>
          <p style={{display: "block", width: 500, whiteSpace: 'nowrap', overflow: "hidden", textOverflow: 'ellipsis', margin: 0, padding: 0}}>{ instrumentName }</p>
          {/*<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>*/}
          {/*  <CloseIcon />*/}
          {/*</IconButton>*/}
        </DialogTitle>
        <DialogContent style={{padding: 24, paddingTop: 0}}>
          { renderAcceptanceCriterias() }
        </DialogContent>
      </Dialog>
    );
  }
}

MyComponent.propTypes = {};

export default withStyles(styles)(MyComponent);
