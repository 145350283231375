import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  modalTitle: {
    borderRadius: '2px 2px 0 0',
    backgroundColor: theme.palette.primary.main,
    height: 40,
    color: 'white',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '& > h6': {
      fontFamily: 'Roboto-Bold',
      fontSize: 16,
    }
  },
  icon: {
    position: 'absolute',
    left: 7,
    top: 7
  },
  actionContainer: {
    backgroundColor: 'rgb(240, 240, 240)',
    height: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'inset 0px 2px 15px 0px rgba(0,0,0, 0.15)',
    borderRadius: '0px 0px 2px 2px'
  },
  button: {
    boxShadow: 'none!important',
    backgroundColor: 'white!important',
    width: '200px!important'
  },
  container: {
    borderRadius: 2
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 10px'
  },
  root: {
    zIndex: '100000 !important',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',

    '&  > div:first-child': {
      backgroundColor: 'transparent'
    }
  }
}));