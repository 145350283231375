import {makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
  fileContainer: {
    alignItems: 'center',
    flexWrap: 'nowrap',
    paddingRight: 0,
    color: '#0092E5',
    backgroundColor: '#ecf8fe',
    borderRadius: 4,
    position: 'relative',
    top: -22,
    borderColor: 'transparent',

    '&:last-child': {
      marginBottom: 0
    }
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  removeDocumentWrapper: {
    marginLeft: 'auto',

    '& .MuiIconButton-root': {
      color: 'inherit',
    },
  },
  customersList: {
    maxHeight: 165,
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingBottom: 10
  },
  previewLink: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  endAdornmentBtn: {
    position: 'absolute',
    fontFamily: 'Roboto-Bold',
    right: -1,
    textTransform: 'capitalize',
    height: '100%',
    borderRadius: '0 4px 4px 0',
    boxShadow: 'none',
  },
  label: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
}))