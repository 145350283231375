import React from 'react';

/* Material-UI Components */
import Grid from '@material-ui/core/Grid';

/* BCA modules*/
import useStyles from './styles';
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import ScrollMenu from "react-horizontal-scrolling-menu";
import _ from 'lodash';


export default (props) => {
  const {
    title,
    menuItems,
    currMenu,
    onDeleteProcessClick,
    isFinished,
  } = props;

  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container wrap={"nowrap"} className={classes.topContainer} alignItems={'center'}>
        <Grid item className={classes.header}>
          {title}
          {_.isFunction(onDeleteProcessClick) && !isFinished &&
            <IconButton className={classes.deleteBtn} color={"primary"} aria-label="close" onClick={onDeleteProcessClick}>
              <i className={"far fa-trash-alt"} />
            </IconButton>
          }
        </Grid>
        <Grid item className={classes.menuContainer}>
          <ScrollMenu
            alignCenter={false}
            wheel={false}
            scrollToSelected={true}
            hideSingleArrow={true}
            arrowLeft={<div>{"<"}</div>}
            arrowRight={<div>{">"}</div>}
            dragging={false}
            selected={menuItems && menuItems[currMenu] && menuItems[currMenu].uid}
            data={menuItems && menuItems.map((item, idx) => (
              <div key={item.uid} className={clsx(classes.menuItem, currMenu === idx ? 'active' : '', item.isDone ? 'done': '')}>
                {item && item.isDone && <i className={'fa fa-check-circle'} />}
                {item.name}
                {currMenu === idx && <i className={'fa fa-lg fa-angle-up'} />}
              </div>
            ))}
          />
        </Grid>
        {/*{menuItems && (currMenu !== menuItems.length - 1) && (*/}
        {/*  <Grid item className={classes.buttonContainer}>*/}
        {/*    <IconButton aria-label="close"  onClick={() => {}}>*/}
        {/*        <CloseIcon className={classes.buttonCircle}/>*/}
        {/*    </IconButton>*/}
        {/*    Schließen und später weitermachen*/}
        {/*  </Grid>*/}
        {/*)}*/}
      </Grid>
    </React.Fragment>
  )
}