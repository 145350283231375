import {makeStyles} from "@material-ui/core";

export default makeStyles(() => ({
  inputContainer: {
    display: 'flex',
    position: 'relative',
    '& .MuiInputBase-root': {
      background: 'white',
      marginTop: 0,
    },
    '& .MuiInputLabel-root': {
      fontSize: 16,
      top: 0,
    },
    '& .fa': {
      color: '#ADB6BD',
      fontSize: '1.25rem',
      padding: '0 0.5rem',
    }
  },
  searchButton: {
    width: '100%',
    height: 48,
    // fontSize: 15,
    fontFamily: 'Roboto-Bold',
    textTransform: 'none!important',
    boxShadow: 'none!important',
    borderRadius: 4,
    '& .fa': {
      marginRight: '0.5rem',
      position: 'relative',
      top: -1,
    }
  },
  filteringWrapper: {
    backgroundColor: '#B5BBC2',
    width: '100%',
    marginTop: 14,
    padding: '1rem',
    borderRadius: 5,
  },
  tooltip: {
    width: 265,
    fontSize: 14,
  },
  clearFiltersButton: {
    textTransform: 'none',
    fontFamily: 'Roboto-Medium',
    fontSize: 16
  },
  track: {
    width: '100%',
    borderRadius: 26 / 2,
    backgroundColor: '#757575',
    opacity: 1,
    transition: 'none',
  },
  tabsRoot: {
    minWidth: 312,
    background: '#B5BBC2',
    paddingRight: 14,

    '& .MuiTabs-flexContainerVertical button.MuiTab-root': {
      textAlign: 'left',
      minHeight: 44,
      maxWidth: 'none',
      marginBottom: 5,
      width: '100%',
      background: '#EEF0F2',

      '& .MuiTab-wrapper': {
        alignItems: 'flex-start'
      }
    }
  },
  checkboxFilterLabelMain: {
    fontFamily: 'Roboto-Bold',
    fontSize: 12,
    color: '#636A71',
    textTransform: 'none'
  },
  tabPanel: {
    background: '#EEF0F2',
    flexGrow: 1,
    padding: '1rem',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      width: 5
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#B0B7BC',
      outline: '1px solid slategrey'
    }
  }
}));