import { makeStyles, createStyles } from '@material-ui/core';

export const styles = theme => createStyles({
  container: {
    borderRadius: 5,
    background: 'white',
    boxShadow: '0px 3px 15px #00000033',
    height: '100%'
  }
});

export default makeStyles(styles);