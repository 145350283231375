import React from "react";
import _ from "lodash";
import {DISTRIBUTION_TYPES, REPORT_GENERATION_TYPES} from '../../../constants';
import DistributionType from '../../DistributionType';
import clsx from "clsx";
import Grid from '@material-ui/core/Grid';
import ListLinksDropdown from '../../../../../components/ListLinksDropdown';
import useListStyles from '../NavigationButtons/styles';
import ReportGenerationType from "../../ReportGenerationType";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../PeriodSelection/styles";

// Depending on the selected reporting period, the reports are generated automatically and are available in the download box as a PDF for download.
const BRIEF_ENABLED_INFO_ICON_TEXT = 'Die Reports werden, abhängig von der gewählten Reportingperiode, automatisch erzeugt und ' +
  'stehen Ihnen in der Downloadbox als PDF zum herunterladen zur Verfügung.'

//The reports are generated automatically, depending on the selected reporting period, and are sent to the customer(s) in the customer's electronic mailbox.
const POSTBOX_ENABLED_INFO_ICON_TEXT = 'Die Reports werden, abhängig von der gewählten Reportingperiode, automatisch erzeugt und werden dem/den Kunden in das elektronische Kundenpostfach gestellt.'

const POSTBOX_DISABLED_INFO_ICON_TEXT = {
  // The customer does not have an electronic mailbox. For this reason it is not possible to send this customer an automatic report. You can change this setting in the customer profile.
  CUSTOMER_HAS_NO_POSTBOX: 'Der Kunde verfügt über kein elektronisches Postfach. Aus diesem Grunde ist es nicht möglich diesem Kunden einen automatischen Report zuzustellen. Sie können diese Einstellung im Kundenprofil ändern.',
  GROUP_HAS_NO_POSTBOX: 'Automatische Reports können für diese Gruppe nicht erstellt werden, da keiner dieser Kunden über ein elektronisches Postfach verfügt. Sie können diese Einstellung in den Kundenprofilen ändern.',
  SOME_GROUP_MEMBERS_HAVE_NO_POSTBOX: 'Einige Kunden aus dieser Gruppe verfügen nicht über ein elektronisches Postfach. Diese Kunden erhalten keinen automatischen Report. Sie können diese Einstellung in den Kundenprofilen ändern.'
}

const DeliveryOptions = props => {

  const {
    reportSettings,
    handleSettingUpdate,
    loading,
    isGroupReporting,
    allSettings,
    handleSelectOtherReportSettings,
    withSettingsButton,
    isAutoReport,
    classes
  } = props;
  const list_classes = useListStyles();

  // if it is not auto report set autoReportDistributionTypes to false.
  const [autoReportDistributionTypes, setAutoReportDistributionTypes] = React.useState(!!isAutoReport && _.cloneDeep(DISTRIBUTION_TYPES))

  React.useEffect(() => {
    // Use effect to set report_generation_type to split if it is group reporting and distribution_type is postbox

    // if report is generated for group and send to postbox of customers it must be impossible to generate it in single pdf
    if(isGroupReporting && reportSettings.distribution_type === DISTRIBUTION_TYPES.POSTBOX.value){
      // report generation type is changed to generate one pdf per customer
      handleSettingUpdate('report_generation_type')(REPORT_GENERATION_TYPES.SPLITTED.value);
    }

  }, [reportSettings.distribution_type])

  const handleValueChanged = (fieldname) => (value) => {
    if (!_.isNil(handleSettingUpdate)) {
      handleSettingUpdate(fieldname)(value);
    }
  };

  const isDistributionTypeDisabled = React.useMemo(() => {

    let postbox_disabled = false;
    let updatedDistributionTypes;

    // only auto reports need to extend autoReportDistributionTypes to display info icons
    if(autoReportDistributionTypes){
      updatedDistributionTypes = {...autoReportDistributionTypes}
      // setting enabled info icon texts
      updatedDistributionTypes.BRIEF.infoIconText = BRIEF_ENABLED_INFO_ICON_TEXT
      updatedDistributionTypes.POSTBOX.infoIconText = POSTBOX_ENABLED_INFO_ICON_TEXT
    }

    if(isGroupReporting){

      let customers = _.get(reportSettings, 'customers', [])
      let customersWithPostboxesNumber =  customers.filter(customer => customer.postbox).length;
      postbox_disabled= customersWithPostboxesNumber !== customers.length

      // setting disabled info icon text
      if(postbox_disabled && autoReportDistributionTypes){
        updatedDistributionTypes.POSTBOX.infoIconText = customersWithPostboxesNumber === 0
          ? POSTBOX_DISABLED_INFO_ICON_TEXT.GROUP_HAS_NO_POSTBOX  // when no group member has postbox
          : POSTBOX_DISABLED_INFO_ICON_TEXT.SOME_GROUP_MEMBERS_HAVE_NO_POSTBOX  // when some members have
      }

    }
    else {
      postbox_disabled = !reportSettings.postbox
      // setting disabled info icon text
      if(postbox_disabled && autoReportDistributionTypes){
        updatedDistributionTypes.POSTBOX.infoIconText = POSTBOX_DISABLED_INFO_ICON_TEXT.CUSTOMER_HAS_NO_POSTBOX
      }
    }

    // if postbox is disabled but distribution type is set to POSTBOX - update it
    if(!_.isNil(reportSettings) && postbox_disabled && reportSettings.distribution_type == DISTRIBUTION_TYPES.POSTBOX.value){
      handleValueChanged('distribution_type')(DISTRIBUTION_TYPES.BRIEF.value)
    }

    setAutoReportDistributionTypes(updatedDistributionTypes)

    return postbox_disabled;

  }, [isGroupReporting, reportSettings.customers, reportSettings.postbox, reportSettings.distribution_type])

  return (
    <Grid container>
      <Grid item xs={12} className={clsx(classes.wrapper, classes.customLabel)}>
        <Grid item xs={12} sm={6} md={4} justifyContent="center">
          <h4 style={{fontSize: 'inherit'}}>Versandeinstellungen</h4>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.wrapper}>
        <Grid container justifyContent="center">
          <Grid item xs={12} style={{justifyContent: 'center', display: 'flex'}} className={clsx(classes.wrapper, classes.customLabel)}>
            <Grid item xs={12} sm={6} md={4} justifyContent="center">
              <DistributionType
                distributionTypes={autoReportDistributionTypes}
                // distribution type is disabled only if some customers do not have postbox -> BRIEF should be selected by default
                selected={reportSettings.distribution_type}
                handleChanged={handleValueChanged('distribution_type')}
                disabled={isDistributionTypeDisabled}
              />
            </Grid>
          </Grid>

          {/* Radio options to select if group report is generated in one pdf or separately */}
          {isGroupReporting &&
            <Grid item xs={12} style={{justifyContent: 'center', display: 'flex'}} className={clsx(classes.wrapper, classes.customLabel)}>
              <Grid item xs={12} sm={6} md={4} justifyContent="center">
                <ReportGenerationType
                  selected={reportSettings.report_generation_type}
                  handleChanged={handleValueChanged('report_generation_type')}
                  disabled={reportSettings.distribution_type === DISTRIBUTION_TYPES.POSTBOX.value}
                />
              </Grid>
            </Grid>
          }

          {withSettingsButton &&
            <Grid container className={[classes.wrapper, classes.settingsMenu]} style={{justifyContent: 'center'}}>
              <Grid xs={12} sm={6} md={4} justifyContent="center">
                <ListLinksDropdown
                   groups={allSettings}
                   onLinkSelect={handleSelectOtherReportSettings}
                   selectedGroupName={'Einstellungen übernehmen'}
                   classes={list_classes}
                />
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(DeliveryOptions);