import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_7615"
            data-name="Rechteck 7615"
            width="30"
            height="30"
            transform="translate(0 0)"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_8153" data-name="Gruppe 8153" transform="translate(0 0)">
        <g
          id="Gruppe_7831"
          data-name="Gruppe 7831"
          transform="translate(0 -0.001)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Pfad_6676"
            data-name="Pfad 6676"
            d="M17.584,17.634H14.021a2.637,2.637,0,0,0-.491-.863L15.363,13.6l-.763-.439a6.176,6.176,0,0,0-6.15,0l-.761.439,1.833,3.175a2.64,2.64,0,0,0-.49.863H5.456l0,.878A5.918,5.918,0,0,0,6.2,21.56a6.134,6.134,0,0,0,2.253,2.251l.761.437,1.833-3.174a2.588,2.588,0,0,0,.962,0l1.833,3.175.761-.44a6.027,6.027,0,0,0,2.981-5.3Zm-4.668-3.315-.909,1.574a2.62,2.62,0,0,0-.963,0l-.909-1.574a4.426,4.426,0,0,1,2.781,0m-4.3,7.454a4.393,4.393,0,0,1-.891-1.092,3.719,3.719,0,0,1-.444-1.289H9.051a2.6,2.6,0,0,0,.471.8Zm2.153-2.849a.879.879,0,1,1,1.2.322.879.879,0,0,1-1.2-.322m3.672,2.844L13.53,20.2A2.7,2.7,0,0,0,14,19.38h1.742a4.3,4.3,0,0,1-1.305,2.388m8.477-2.376a7.007,7.007,0,0,0-2.311.39,34.2,34.2,0,0,1,.22-9.737l.2-1.2h1.892V7.089H.007V8.846H1.9l.267,1.6A34.446,34.446,0,0,1,1.1,26.2L0,30H30V26.422a7.068,7.068,0,0,0-7.089-7.03m0,1.757a5.337,5.337,0,0,1,5.026,3.515h-10a5.281,5.281,0,0,1,4.971-3.515m-7.03,7.089H2.342l.443-1.523A36.213,36.213,0,0,0,3.9,10.161L3.68,8.847H19.24l-.151.91a35.953,35.953,0,0,0-.149,10.869,7.029,7.029,0,0,0-3.056,5.8Zm12.361,0h-10.6V26.422h10.6ZM8.853,4.394a2.639,2.639,0,0,1,2.636-2.636h7.03V0h-7.03A4.4,4.4,0,0,0,7.1,4.394V6.152H8.853Zm1.757-.879V5.273h7.909a2.639,2.639,0,0,0,2.637-2.636.879.879,0,0,1,.879-.879h4.453V0H22.034A2.64,2.64,0,0,0,19.4,2.636a.88.88,0,0,1-.879.879ZM28.244,0H30V1.757H28.244Z"
            transform="translate(0 0)"
            fill="#80858c"
          />
        </g>
      </g>
    </svg>
  );
};
