import {fromJS} from "immutable";

import {
  SET_THEME,
  SET_THEME_LOADING,
  SET_AGENCY_DATA,
  SET_AGENCY_DATA_LOADING,
} from './constants';


const initialState = fromJS({
  theme: undefined,
  theme_loading: false,
  agency_data: undefined,
  agency_data_loading: false
});


export const createReducer = (state=initialState, action) => {
  switch (action.type) {
    case SET_THEME_LOADING:
      return state.set('theme', undefined).set('theme_loading', true);
    case SET_THEME:
      return state.set('theme', fromJS(action.data)).set('theme_loading', false);
    case SET_AGENCY_DATA_LOADING:
      return state.set('agency_data', undefined).set('agency_data_loading', true);
    case SET_AGENCY_DATA:
      return state.set('agency_data', fromJS(action.data)).set('agency_data_loading', false);
    default:
      return state;
  }
};

export default createReducer;