import React from 'react';
import _ from 'lodash'

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox'

/* BCA modules*/
import useStyles from './styles';

import { REPORTS_OPTIONS } from './constants';
import { isExPostCostEnabled } from "../../../../../../utils/utils";

export default (props) => {
    const classes = useStyles();

    const [values, setValues] = React.useState(props.selected || []);

    React.useEffect(() => {
      if (!_.isNil(props.selected)) {
        setValues(props.selected);
      }
    }, [JSON.stringify(props.selected)]);

    const onValueChange = value => event => {
      let newValues = undefined;
      if (values.includes(value)) {
        newValues = _.filter(values, elem => elem != value);
      } else {
        newValues = [...values, value];
      }
      
      if (!_.isNil(newValues)) {
        setValues(newValues);
        if (props.handleChanged) {
          props.handleChanged(newValues);
        }
      }
      
    }

    return (
        <div className={classes.container}>
          <FormControl disabled={props.disabled} component="fieldset">
            <FormLabel 
              component="legend" 
              classes={{root: classes.formLabel, focused: classes.focusedLabel}}
            >
              Weitere Reports
            </FormLabel>
            <FormControlLabel
              label={REPORTS_OPTIONS.COST.description}
              classes={{label: classes.formControlLabel}}
              control={
                <Checkbox 
                  color="primary" 
                  classes={{root: classes.radio}}
                  checked={isExPostCostEnabled &&  values.includes(REPORTS_OPTIONS.COST.value)}
                  onChange={onValueChange(REPORTS_OPTIONS.COST.value)}
                  disabled={!isExPostCostEnabled}
                />
              }
            />
            <FormControlLabel
              label={REPORTS_OPTIONS.TRANSACTIONS.description}
              classes={{label: classes.formControlLabel}}
              control={
                <Checkbox 
                  color="primary" 
                  classes={{root: classes.radio}}
                  checked={values.includes(REPORTS_OPTIONS.TRANSACTIONS.value)}
                  onChange={onValueChange(REPORTS_OPTIONS.TRANSACTIONS.value)}
                />
              }
            />
          </FormControl>
        </div>
    )
}