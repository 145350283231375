import { makeStyles } from "@material-ui/core/styles";
import { autoCompleteStyles } from '../common_styles';

export default makeStyles((theme) => ({
  autocompleteInput: autoCompleteStyles,
  option: {
    backgroundColor: 'white !important',
    padding: 17,

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
  },
  textField: {
    '& input::placeholder': {
      color: 'rgba(0, 0, 0, 0.87)',
      opacity: 1,
    }
  },
}))