import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  labelRoot: {
    fontSize: 16,
    color: '#202A38',
    transform: 'none',
    fontFamily: 'Roboto-Regular',
    paddingBottom: 16,
    '&.bold': {
      fontSize: 16,
      fontFamily: 'Roboto-Bold',
    },
    '&.italic': {
      fontStyle: 'italic'
    },
    '&.no-padding': {
      paddingBottom: 0
    }
  },
  asterisk: {
    color: theme.palette.primary.main
  },
})

export default makeStyles(styles)