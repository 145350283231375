import React, {useState} from "react";
import connect from "react-redux/lib/connect/connect";
import _ from "lodash";
import {
  useChartsSettings,
  useGeneralPortfolioData, useTimeWeightedReturnData
} from '../ModelPortfolioDashboardDataProvider/ModelPortfolioDashboardDataProvider'
import {InvestmentStrategiesResource, ModelPortfolioResource} from "../../utils/api";
import useFetchData from "../../hooks/useDataFetch";
import {PERFORMANCE_TIME_TYPE} from "../../utils/constants";
import {getUpdatedTwrData} from "../DashboardDataProvider/DashboardDataProvider";

const withInvestmentStrategyDashboardData = WrappedComponent => connect()((props) => {

  let dataProvider = ModelPortfolioResource;

  const investmentStrategyId = props.investmentStrategy ? props.investmentStrategy.id : _.get(props, 'computedMatch.params.id');

  const [investmentStrategyData, fetchInvestmentStrategy, setInvestmentStrategyData] = useFetchData(
    `${InvestmentStrategiesResource.resourceUrl}${investmentStrategyId}/`, 'get');

  const [selectedDates, setSelectedDates] = React.useState(PERFORMANCE_TIME_TYPE.BEGINNING.getDateRange());
  const [selectedDatesType, setSelectedDatesType] = useState(PERFORMANCE_TIME_TYPE.BEGINNING);
  const {chartsSettings, setChartsSettings, handleShowChartSection, handleChartSettingsChange } = useChartsSettings();

  const modelPortfolioId = _.get(investmentStrategyData, 'data.related_model_portfolio.id');
  const modelPortfolioConnectId = _.get(investmentStrategyData, 'data.related_model_portfolio.connect_portfolio_id');

  React.useEffect(() => {
    if (!!props.investmentStrategy) {
      setInvestmentStrategyData(props.investmentStrategy)
    } else {
      fetchInvestmentStrategy(undefined, !!props.investmentStrategy);
    }
  }, []);

  React.useEffect(() => {
    if (investmentStrategyData.data) {
      const benchmarks = (investmentStrategyData.data.benchmark || [])
            .map((benchmark) => ({...benchmark, percentage: +benchmark.percentage}));

      setChartsSettings((currentSettings) => ({
        ...currentSettings,
        performance: {
          ...currentSettings.performance,
          withBenchmark: !_.isEmpty(benchmarks),
          currently_selected_benchmarks: benchmarks
        }
      }))
    }
  }, [investmentStrategyData.updatedAt]);

  const {
    breakdownData,
    paymentPlans,
    profitAndLoss,
    instrumentList,
    dataLoading
  } = useGeneralPortfolioData(dataProvider, modelPortfolioId, selectedDates);
  const {
    investmentData
  } = useGeneralPortfolioData(dataProvider, modelPortfolioId, selectedDates, investmentStrategyId);
  const {twrData, setTwrData} = useTimeWeightedReturnData(
    dataProvider, modelPortfolioId, selectedDates, investmentData, chartsSettings, investmentStrategyId);

  const handleSelectedDatesChanged = (dates, _datesType) => {
    setSelectedDates(dates);
    setSelectedDatesType(_datesType);
  };

  return(
    <WrappedComponent
      investmentStrategyData={investmentStrategyData}
      // data
      investmentData={investmentData}
      breakdownData={breakdownData}
      twrData={twrData}
      paymentPlans={paymentPlans}
      profitAndLoss={profitAndLoss}
      instrumentList={instrumentList}
      selectedDates={selectedDates}
      selectedDatesType={selectedDatesType}
      chartsSettings={chartsSettings}
      dataProvider={dataProvider}
      customerId={modelPortfolioId}
      investmentStrategyId={investmentStrategyId}
      investmentStrategyModelPortfolioConnectId={modelPortfolioConnectId}
      portfolioId={_.get(investmentData, 'data.portfolios.0.id')}

      // flags
      dataLoading={dataLoading}
      // functions
      onSelectedDatesChanged={handleSelectedDatesChanged}
      handleShowChartSection={handleShowChartSection}
      handleChartSettingsChange={handleChartSettingsChange}
      updateHistoricalData={
        (data=[], portfolioToUpdate=undefined, additionalPortfoliosToUpdate=[]) => {
          // get mp's portfolio on cios from investment data
          const portfolio = _.get(investmentData, 'data.portfolios.0');
          setTwrData(getUpdatedTwrData(twrData, data, portfolio, additionalPortfoliosToUpdate))
        }
      }
      // other props
      {...props}
    />
  )

});

export default withInvestmentStrategyDashboardData;
