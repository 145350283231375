import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_7854"
            data-name="Rechteck 7854"
            width="30"
            height="30"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Gruppe_8248"
        data-name="Gruppe 8248"
        transform="translate(0 0.191)"
      >
        <g
          id="Gruppe_8016"
          data-name="Gruppe 8016"
          transform="translate(0 -0.191)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Pfad_6873"
            data-name="Pfad 6873"
            d="M20.58,46.965a2.956,2.956,0,0,1,1.293-1.636L20.614,44.07c-.054.04-.112.073-.164.115v-.279l-1.757-1.757v2.04a4.745,4.745,0,0,0-1.093-.66,3.326,3.326,0,0,0,.656-1.816l-3.529-3.529a3.366,3.366,0,0,0-3.213,3.349,3.326,3.326,0,0,0,.667,2,4.748,4.748,0,0,0-1.1.669V34.536L6.688,30.144v2.807H9.323V44.167a4.737,4.737,0,0,0-1.065-.639,3.326,3.326,0,0,0,.664-2,3.375,3.375,0,0,0-6.75,0,3.327,3.327,0,0,0,.667,2A4.7,4.7,0,0,0,0,47.838v.874H25.256l-1.747-1.747ZM5.547,39.923a1.61,1.61,0,1,1-1.618,1.609,1.615,1.615,0,0,1,1.618-1.609M1.89,46.965a2.969,2.969,0,0,1,2.833-2.076h1.66a2.969,2.969,0,0,1,2.833,2.076Zm13-7.042a1.61,1.61,0,1,1-1.618,1.609,1.615,1.615,0,0,1,1.618-1.609m-3.657,7.042a2.969,2.969,0,0,1,2.832-2.076h1.661a2.969,2.969,0,0,1,2.833,2.076Z"
            transform="translate(0 -18.712)"
            fill="#fff"
          />
          <path
            id="Pfad_6874"
            data-name="Pfad 6874"
            d="M29.795,28.277l-.053-.052a4.713,4.713,0,0,0-2.795-3.613,3.325,3.325,0,0,0,.664-2,3.372,3.372,0,0,0-5.8-2.323l-1.36-1.361v-4.9h2.664V8.792H20.364A4.717,4.717,0,0,0,17.6,5.354a3.327,3.327,0,0,0,.664-2,3.375,3.375,0,0,0-6.75,0,3.328,3.328,0,0,0,.667,2A4.725,4.725,0,0,0,9.619,8.1L1.518,0,0,1.518,28.277,29.795l.007-.007.007.007h1.5v-.874a4.669,4.669,0,0,0-.042-.6Zm-5.558-7.272a1.616,1.616,0,0,1,1.618,1.61A1.6,1.6,0,0,1,25.32,23.8l-2.271-2.271a1.612,1.612,0,0,1,1.188-.526m-5.544-3.831-3.141-3.141h3.141Zm-3.8-15.427A1.61,1.61,0,1,1,13.27,3.357a1.616,1.616,0,0,1,1.618-1.609m-.824,4.966h1.66a2.968,2.968,0,0,1,2.833,2.078H11.231a2.968,2.968,0,0,1,2.833-2.078m7.292,3.825v1.747H13.8l-1.747-1.747Z"
            transform="translate(0 0.205)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
