import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { buildSelectedValue } from '../../../TransactionsMonitoring/utils';
import moment from 'moment';
import _ from 'lodash'

import FilterInput from '../FilterInput';
import FilterLoadingItem from '../FilterLoadingItem'

import useStyles from './styles';


export default function DocumentsFilter(props) {
  const {
    banksData,
    handleDocumentClick,
    handleDocumentsLoadClick,
    activeDocuments,
    selectedDocuments,
    portfolios,
    selectedPortfolio,
    onSelectedPortfolioChange,
    disabled,
    confirmButtonText
  } = props;
  const classes = useStyles();
  const [filters, setFilters] = useState({ bank: null, folder: null });
  const [banks, setBanks] = useState([]);
  const [folders, setFolders] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [showSelectedDocumentsCount, setCountVisibility] = useState(false);
  const [documentsInputValue, setDocumentsInputValue] = useState('');

  const withPortfolioDropdown = portfolios && !_.isEmpty(portfolios);
  const gridSize = {xs:12, sm: 6, md: withPortfolioDropdown ? 5 : 3};

  const getBanks = () => {
    if (banksData.data) {
      return banksData.data.map((bank) => {
        bank.availableDocumentsQty = bank.documents.length;
        return bank;
      })
    }
    return []
  };

  const getFolders = () => {
    let _banks = [];

    if (filters.bank) {
      _banks = banksData.data.filter((bankData) => bankData.id === filters.bank.id);
    }

    return _banks.reduce((acc, item) => {
      acc = [...acc, ...item.folders];
      return acc;
    }, [])
  };

  const getDocuments = () => {
    let _folders = getFolders();

    if (filters.folder) {
      _folders = _folders.filter((folderData) => folderData.id === filters.folder.id);
    } else if (filters.bank) {
      return filters.bank.documents
    }

    return _folders.reduce((acc, folder) => {
      acc = [...acc, ...folder.documents];
      return acc;
    }, []);
  };

  useEffect(() => {
    setBanks(getBanks());
    setFolders(getFolders());
    setDocuments(getDocuments());
  }, [banksData, filters]);

  useEffect(() => {
    // set the default bank if it is only one in the banks list
    if (_.get(banksData, 'data.length', 0) == 1 && !filters.bank) {
      setFilters({bank: banksData.data[0], folder: null})
    }
  }, [banksData]);

  const getPreloader = (showCount=true) => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}><FilterLoadingItem showCount={showCount}/></Grid>
          <Grid item xs={12}><FilterLoadingItem showCount={showCount}/></Grid>
        </Grid>
      </>
    )
  };

  return (
    <Grid
      container
      spacing={2}
      classes={{ root: classes.filtersRoot }}
      alignItems="flex-end"
      direction="row"
      justify={withPortfolioDropdown ? "flex-start" : "space-between"}
    >
      <Grid item {...gridSize}>
        <FilterInput
          options={banks}
          loading={banksData.loading}
          loadingText={getPreloader()}
          label="Depotbank"
          value={filters.bank}
          customclasses={{ option: classes.banksOption, autocompleteInput: classes.autocompleteInput }}
          disableClearable={!filters.bank}
          onChange={(_, value) => setFilters({ bank: value, folder: null })}
          renderOption={(option) => (
            <>
              {banksData.data && !banksData.errors && (
                <>
                  <div className={classes.bankName}>{option.name}</div>
                  <div className={classes.availableDocumentsCount}>
                    <span className={classes.documentsCountNumber}>{option.availableDocumentsQty}</span>
                  </div>
                </>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item {...gridSize}>
        <FilterInput
          options={folders}
          loading={banksData.loading}
          loadingText={getPreloader()}
          label="Kategorie"
          disableClearable={!filters.folder}
          value={filters.folder}
          customclasses={{ option: classes.banksOption, autocompleteInput: classes.autocompleteInput }}
          onInputChange={(_, value, reason) => {
            if (reason == 'reset') {
              setFilters({ ...filters, folder: null })
            }
          }}
          onChange={(_, value) => setFilters({ ...filters, folder: value })}
          renderOption={(option) => (
            <>
              {banksData.data && !banksData.errors && (
                <>
                  <div className={classes.bankName}>{option.name}</div>
                  <div className={classes.availableDocumentsCount}>
                    <span className={classes.documentsCountNumber}>{option.documents.length}</span>
                  </div>
                </>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item {...gridSize}>
        <FilterInput
          multiple
          withDefault={false}
          // differenceBy used to find documents that selected
          // but not opened to enable/disable clear icon in filter.
          // we can clear only selected and not opened documents
          disableClearable={disabled || _.differenceBy(selectedDocuments, activeDocuments, 'id').length == 0}
          value={selectedDocuments}
          options={documents}
          loading={banksData.loading}
          loadingText={getPreloader(false)}
          customclasses={{ option: classes.documentsOption, autocompleteInput: classes.autocompleteInput }}
          label="Dokumente"
          handleDropdownSwitch={setCountVisibility}
          getOptionSelected={(option, value) => option.id == value.id}
          getOptionDisabled={(option) => !option.document_exist || activeDocuments.filter(obj => obj.id == option.id).length > 0}
          onChange={(event, value) => {
            if (event.key !== "Backspace") {
              handleDocumentClick(value)}
            }
          }
          inputValue={!showSelectedDocumentsCount ? documentsInputValue : ''}
          onInputChange={(_, newInputValue, reason) => {
            if (reason == 'input') {
              setDocumentsInputValue(newInputValue);
            }
          }}
          renderTags={() => { }}
          renderInput={(params) => {
            const isAllSelected = documents.length && documents.length === selectedDocuments.length;
            let placeHolder = buildSelectedValue(isAllSelected, selectedDocuments.map(o => ({ label: o.name })));
            return (
              <TextField
                className={classes.textField}
                placeholder={placeHolder}
                variant="outlined"
                {...params}
              />
            )
          }}
          renderOption={(option, { selected }) => {
            return (
              <>
                <Checkbox
                  className={classes.documentCheckbox}
                  icon={<CheckBoxOutlineBlankIcon htmlColor="#ADB6BD" />}
                  checkedIcon={<CheckBoxIcon htmlColor="#0092E5" />}
                  checked={(activeDocuments.includes(option) || selected) ? selected : false}
                />
                <div className={classes.documentsName}>
                  <span>{option.name}</span>
                  <span style={{ fontSize: '14px' }}>(Verfügbar von {moment(option.updated_at).format('DD-MM-YYYY')})</span>
                </div>
              </>
            )
          }}
        />
      </Grid>

      {withPortfolioDropdown &&
        <Grid item {...gridSize}>
          <FilterInput
            options={portfolios}
            loading={props.portfoliosLoading}
            loadingText={getPreloader()}
            label="Produkt-/ Depotauswahl"
            disableClearable={false}
            customclasses={{autocompleteInput: classes.autocompleteInput}}
            value={selectedPortfolio}
            onChange={ (_, value) => onSelectedPortfolioChange(value) }
            renderOption={(option) => (
              <>
                <div className={classes.bankName}>{option.name}</div>
              </>
            )}
          />
        </Grid>
      }

      <Grid item xs={12} sm={6} md={3} lg={2} className={classes.selectButtonContainer}>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled || selectedDocuments.length === 0 || _.differenceBy(selectedDocuments, activeDocuments, 'id').length == 0}
          fullWidth={true}
          classes={{
            root: classes.loadDocsButton,
            label: classes.loadDocsButtonText,
          }}
          onClick={handleDocumentsLoadClick}
        >
          {confirmButtonText}
        </Button>
      </Grid>
    </Grid>
  )
}

DocumentsFilter.defaultProps = {
  banksData: {'data': []},
  selectedDocuments: [],
  confirmButtonText: 'Dokumente öffnen',
  activeDocuments: []
};
