import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    width :'100%',
    height: '100%',
    minHeight: 500,
    backgroundColor: 'white',
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',
  },
  tableHead: {
    backgroundColor: '#ABB6BE',

    '& > tr': {
      verticalAlign: 'top',
    },

    '& th': {
      color:'white',
      textTransform: 'uppercase',
      fontFamily: 'Roboto-Bold',
      fontSize: 12,
      padding: '7px 15px',
      lineHeight: '15px'
    }
  },
  customerDetailRow: {
    '& td': {
      fontFamily: 'Roboto-Regular',
      fontSize: 14,
      color: '#313D44',
      whiteSpace: 'nowrap',
      padding: '0px 15px'
    },

    '& $tableCellBold': {
      fontSize: 16
    }
  },
  tableCellBold: {
    fontFamily: 'Roboto-Bold',
  },
  controlRow: {
    '& td': {
      fontFamily: 'Roboto-Bold',
      fontSize: 12,
      color: '#ABB6BE',
      padding: '2px 15px'
    },
  },
  controlRowCheckboxLable: {
    color: "#4D4F5C",
    fontSize: 12,
    fontFamily: 'Roboto-Bold',
  },
  paginationSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& > p': {
      fontFamily: 'Roboto-Regular',
      fontSize: 12,
      color: '#ABB6BE',
      whiteSpace: 'nowrap'
    }
  },
  manualPaginationSection: {
    display: 'flex',
    alignItems: 'center',

    '& > span': {
      fontFamily: 'Roboto-Regular',
      fontSize: 16,
      color: '#313D44',
      whiteSpace: 'nowrap',
      marginRight: 10
    }
  },
  setPageButton: {
    boxShadow: 'none',
    padding: 0,
    height: 36,
    minWidth: 36,
    width: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0 2px 2px 0',
    '& > span': {
      fontSize: 5,

      '& > span': {
        position: 'relative',
        top: -2
      }
    }
  },
  manualPaginationInput: {
    width: 36,
    height: 36,
    borderRadius: '2px 0 0 2px',
    border: '1px solid #DADEE1',
    borderRight: 'none',

    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#626970',
    padding: '0 5px',

    '&::-webkit-outer-spin-button': {
      appearance: 'none',
      margin: 0
    },
    '&::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0
    },

    appearance: 'textfield'
  },
  loadingLine: {
    width: '100%',
    height: '20px',
    backgroundColor: '#f6f7f8',
    backgroundImage: 'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: '$placeholderShimmer',
    animationTimingFunction: 'linear',
  },
  '@keyframes placeholderShimmer': {
    from: {
      backgroundPosition: '-468px 0',
    },
    to: {
      backgroundPosition: '468px 0',
    }
  }
}));