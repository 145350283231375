import React from 'react';

import Select from "../Select";
import { REPORT_TYPE_OPTIONS } from '../../../DashboardSettings/components/CustomersList/components/ReportTypeV2/constants';


function DepotViewTypeSelector({reportType, setReportType, hideName, loading, isMounted}) {

  return (
    <Select
      name={hideName ? '' : 'Depotansicht'}
      value={reportType}
      options={REPORT_TYPE_OPTIONS}
      onChange={setReportType}
      withDefault={false}
      fullWidth
      selectStyles={!hideName && {marginTop: 5}}
      disabled={loading || !isMounted}
      loading={!isMounted}
    />
  )
}

DepotViewTypeSelector.propTypes = {}

DepotViewTypeSelector.propDefs = {}

DepotViewTypeSelector.defaultProps = {
  hideName: false,
  isMounted: true,
}

export default DepotViewTypeSelector;