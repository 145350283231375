import { SECTION_PADDING } from '../../../containers/CustomerDashboardV2/constants';

const styles = theme => ({
  tableCell: {
    width: '25%',
    padding: '15px 5px',
    '&:nth-of-type(1)': {
      paddingLeft: SECTION_PADDING,
    },
    '&:nth-of-type(2)': {
      paddingRight: 15,
    },
    '&:nth-of-type(3)': {
      paddingLeft: 15,
    },
    '&:nth-of-type(4)': {
      paddingRight: 10,
    },
  },
  tableHead: {
    backgroundColor: '#f9f9f9',

    '& td': {
      color: '#80858C !important'
    }

  },
  tableFooter: {
    backgroundColor: '#eef0f2',
  },
  summary: {
    backgroundColor: '#DBEDFA !important',
    padding: SECTION_PADDING,
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  ellipsisXs: {},
  hideXs: {},
  blockXs: {},
  showXs: {
    display: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    summary: {
      fontSize: 12,
      marginTop: 10,
      padding: 5
    },
  },
  [theme.breakpoints.only('xs')]: {
    tableCell: {
      width: '50%',
      padding: 5,
      '&:nth-of-type(1)': {
        paddingRight: 10,
      },
      '&:nth-of-type(2)': {
        paddingLeft: 10,
      },
    },
    hideXs: {
      display: 'none'
    },
    showXs: {
      display: 'initial'
    },
    blockXs: {
      display: 'block',
    },
    ellipsisXs: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      '&:hover': {
        textOverflow: 'initial',
        overflow: 'initial',
        whiteSpace: 'initial',
        wordBreak: 'break-all'
      }
    },
  },
});
export default styles;