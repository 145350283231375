import React from 'react';
import Link from '../../../components/Link'
import {DetailsIcon, EditIcon, InfoIcon} from '../../../images'
import { Skeleton } from '@material-ui/lab';
import _ from "lodash";
import {CircularProgress, Grid, makeStyles} from "@material-ui/core";
import clsx from "clsx";
import {acceptanceCriteriasExists} from "../../Modelportfolios/utils";
import {FilteringAcceptanceCriteriasModal} from "../../Modelportfolios/components";
import { getInstrName } from "../../Modelportfolios/utils";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from '@material-ui/icons/Star';
import {getTableOptionsActionName} from "../../../components/DashboardTable/utils";

const nameStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))


export const NameCell = {
  header: "Name",
  body: {
    content: (item, options) => {

      const classes = nameStyles()

      if (options.loading) {
        return <Skeleton />
      }

      const handleClick = () => {
        if (!item.factsheet_link) {
          return
        }

        window.open(item.factsheet_link, '_blank')

      }

      return (
        <span onClick={handleClick} className={clsx(item.factsheet_link && classes.link)}>
          {item.name}
        </span>)
    }
  },
  key: 'NameCell'
}

const IsFavoriteCell = {
  header: '',
    body: {
    content: (item, options) => {

      if(!options.isFavoriteSwitchEnabled(item)) { return }

      const classes = nameStyles()

      if (options.loading) {
        return <Skeleton />  // if all options are loading skeleton is displayed
      }

      if (item.loading) {
        return <CircularProgress size={24}/>  // if this specific item is loading the circle progress is displayed
      }

      const handleClick = () => {
        options.onAddToMyStrategies({id: item.id, is_favorite: !item.is_favorite})
      }

      return (
        item.is_favorite ? (
          <StarIcon onClick={handleClick}  className={classes.link}/>
        ) : (
          <StarBorderIcon onClick={handleClick}  className={classes.link}/>
        )
      )
    }
  },
  key: 'FavoriteCellName'
}

const TypeCell = {
  header: "Typ",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.type || '-'
    }
  },
  key: 'TypeCell'
}

const SRRICell = {
  header: {content: <>SRI</>, justifyContent: 'center'},
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.srri || '-'
    }
  },
  key: 'SRRICell'
}

const MainFocusCell = {
  header: "Thematischer Schwerpunkt",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.main_focus || '-'
    }
  },
  key: 'MainFocusCell'
}

const InvestmentGoalCell = {
  header: "Anlageziel",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.investment_goal_customer || '-'
    }
  },
  key: 'InvestmentGoalCell'
}

const InvestmentHorizonCell = {
  header: "Anlagehorizont",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.investment_horizon || '-'
    }
  },
  key: 'InvestmentHorizonCell'
}

const InstrumentsCountCell = {
  header: {content: <>Anzahl Instrumente</>, justifyContent: 'center'},
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return _.get(item, 'related_model_portfolio.instruments_count') || '-'
    }
  },
  key: 'InstrumentsCountCell'
}

const ActionsCell = {
  header: '',
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      const detailsTitle = getTableOptionsActionName(options, 'details');

      return (
        <Grid container style={{justifyContent: 'flex-start'}} spacing={1}>
          <Grid item>
            <Link
              title={detailsTitle || "Details"}
              icon={detailsTitle ? null : <DetailsIcon color={item.disabledByFilter ? '#ADB6BD' : '#0092E5'}/>}
              fontWeight={'normal'}
              onClick={() => options.onDetailsClick(item.id)}
              disabled={item.disabledByFilter}
            />
          </Grid>
          {!options.viewOnly && options.onExtend && (
            <Grid item>
              <Link
                title="Vergleich öffnen"
                fontWeight={'normal'}
                onClick={() => options.onExtend(item)}
              />
            </Grid>
          )}
          {options.viewOnly && options.directAddingPossible && (
            <Grid item>
              <Link
                title="Hinzufügen"
                fontWeight={'normal'}
                onClick={() => options.onDetailsClick(item.id, true)}
                disabled={item.disabledByFilter}
              />
            </Grid>
          )}
        </Grid>
      )
    }
  },
  key: 'ActionsCell'
}

export const StateCol = {
  header: '',
  body: {
    content: (item, options) => {

      const [acceptanceVisible, setAcceptanceVisibility] = React.useState(false);

      const handleShowAcceptanceCriteriasClick = () => {
        setAcceptanceVisibility(true)
      };

      const handleCloseAcceptanceCriterias = () => {
        setAcceptanceVisibility(false)
      };

      if (acceptanceCriteriasExists(item)) return (
        <>
          <InfoIcon color="#80858c" onClick={handleShowAcceptanceCriteriasClick}/>
          <FilteringAcceptanceCriteriasModal
            open={acceptanceVisible}
            onClose={handleCloseAcceptanceCriterias}
            instrumentName={getInstrName(item) || item.isin}
            criterias={item.disabledByAcceptanceCriterias} />
        </>
      );
    }
  }
}

export default {
  default: [
    {
      content: [StateCol]
    }, {
      content: [NameCell],
      orderingFunction: (item) => (item.name || '').toLowerCase()
    }, {
      content: [IsFavoriteCell]
    }, {
      content: [TypeCell],
      orderingFunction: (item) => item.type
    },{
      content: [SRRICell],
      align: 'center',
      orderingFunction: (item) => item.srri
    },{
      content: [MainFocusCell],
      orderingFunction: (item) => item.main_focus
    },{
      content: [InvestmentGoalCell],
      orderingFunction: (item) => item.investment_goal_customer
    },{
      content: [InvestmentHorizonCell],
      align: 'right',
      orderingFunction: (item) => item.investment_horizon
    }, {
      content: [InstrumentsCountCell],
      align: 'center',
      orderingFunction: (item) => _.get(item, 'related_model_portfolio.instruments_count') || 0
    }, {
      content: [ActionsCell]
    }
]
}