const styles = theme => ({
  root: {
    minHeight: 150,
    width: '100%'
  },
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    '& > div': {
      width: '100%'
    },
    flexDirection: 'column'
  },
  description: {
    fontSize: 12,
    width: '40%',
    marginBottom: 0,
    color: '#8D8D8D'
  },
  smallTitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold'
  },
  title: {
    fontFamily: 'Roboto-Bold'
  },
  slider: {
    width: '75%',
    float: 'left',
    borderRight: '1px solid rgba(0, 0, 0, 0.2)',
    minHeight: 100,
    padding: '0px 35px 0px 0px',
  },
  sliderBg: {
    width: '100%',
    height: 32,
    background: 'transparent linear-gradient(90deg, #AFE3BF 0%, #F5E671 34%, #F1BF7A 64%, #E6884D 100%) 0% 0% no-repeat',
    borderRadius: 32,
    marginTop: 45
  },
  sliderProgress: {
    textAlign: 'right',
    height: 32,
    position: 'relative',
    minWidth: '32px !important'
  },
  sliderMark: {
    display: 'inline-block',
    width: 32, height: 32,
    backgroundColor: '#CCE9FA',
    borderRadius: 32,
  },
  sliderExplain: {
    fontSize: 12,
    fontFamily: 'Roboto-Bold',
    position: 'absolute',
    top: '-35px',
    right: '-72px',
    textAlign: 'left',
    width: 100
  },
  table: {
    width: '25%',
    float: 'right',
    padding: '0px 35px 0px 35px',
  },
  captions: {
    marginTop: 5,
    fontSize: 12,
    lineHeight: '14px'
  },
  leftCaption: {
    display: 'inline-block',
    float: 'left',
  },
  rightCaption: {
    display: 'inline-block',
    float: 'right',
    textAlign: 'right'
  },
  list: {
    listStyle: 'none',
    margin: 0,
    marginTop: 20,
    padding: 0
  },
  subline: {
    marginTop: 20
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    padding: '2px 0',
    '&:first-of-type': {
      paddingTop: 0
    },
    '&:last-of-type': {
      paddingBottom: 0
    }
  },
  error: {
    textAlign: 'center'
  },
  topPart: {
    display: "flex",
  },
  midPart: {
    height: 15,
    borderBottom: '1px solid #D8DCDF',
  },
  bottomPart: {
    display: "flex",
  },
  helpIcon: {
    verticalAlign: 'bottom',
    fontSize: '1.1rem'
  },
  esgCharsRight:{
    marginTop: 35,
    [theme.breakpoints.down("sm")]: {
      marginTop: 15
    },
  },
  partEnd: {
    [theme.breakpoints.up("md")]: {
      borderLeft: '1px solid #D8DCDF'
    },

    [theme.breakpoints.down("sm")]: {
      margin: 'auto'
    }
  }
})

export default styles;