import _ from 'lodash'
import moment from 'moment'
import {
  APPROVAL_STATUS,
  APPROVAL_STATUS_FILTER_OPTIONS, APPROVALS_SORTING_OPTIONS, EVENT_TYPES_FILTER_OPTIONS,
  FINANCIAL_INVESTMENT_FILTER_OPTIONS,
  MANDANT_FILTER_OPTIONS,
  SERVICE_CONCEPT_FILTER_OPTIONS
} from "./constants";
import {SORTING_TYPES} from "../../components/FilteringPanel/components/SortingFilter/constants";


export const getApprovalStatus = (approval) => {
  if (_.some(approval.full_documents_list, (document) => document.status == APPROVAL_STATUS.REJECTED.value)) {
    return APPROVAL_STATUS.REJECTED
  }

  if (_.some(approval.full_documents_list, (document) => document.status == APPROVAL_STATUS.FOR_APPROVAL.value)) {
    return APPROVAL_STATUS.FOR_APPROVAL
  }

  if (_.some(approval.full_documents_list, (document) => document.status == APPROVAL_STATUS.APPROVED.value)) {
    return APPROVAL_STATUS.APPROVED
  }

  return APPROVAL_STATUS.TRANSMITTED
}

/**
 * Validate, if approval date is valid for selected dates range
 * @param {{business_case: {created_at: string}}} approval Approval data
 * @param {[moment, moment]} dates
 */
const isApprovalDateValid = (approval, dates) => {
  if (!_.isArray(dates) || !dates.length) {
    return true
  }

  const createdAt = moment(_.get(approval, 'business_case.created_at'), 'YYYY-MM-DD')

  let isStartDateValid = true
  if (dates[0]) {
    isStartDateValid = dates[0] <= createdAt
  }

  let isEndDateValid = true
  if (dates[1]) {
    isEndDateValid = dates[1] >= createdAt
  }

  return isStartDateValid && isEndDateValid
}

/**
 * Validate, if approval invested amount is valid for slected filter values
 * @param {{business_case: {total_amount: number}}} approval Approval data
 * @param {[number, number]?} values Aggregated value range
 */
const isApprovalInvestedAmountValid = (approval, values) => {

  if (!_.isArray(values) || !values.length) {
    return true
  }

  const investedAmount = parseFloat(_.get(approval, 'business_case.total_amount') || 0)

  return values[0] <= investedAmount && investedAmount <= values[1]

}

/**
 * Validate, if approval has valid type of investments
 * @param {{business_case: {is_private_investment: boolean}}} approval Approval data
 * @param {number} financialInvestment Type of investment
 * @returns {boolean|undefined|boolean}
 */
const isApprovalFinancialInvestmentValid = (approval, financialInvestment) => {
  if (financialInvestment == FINANCIAL_INVESTMENT_FILTER_OPTIONS.ALL.value) {
    return true
  }

  const isApprovalPrivateInvestment = _.get(approval, 'business_case.is_private_investment_trading')

  return financialInvestment == FINANCIAL_INVESTMENT_FILTER_OPTIONS.PRIVATE_INVESTMENT.value
    ? isApprovalPrivateInvestment
    : !isApprovalPrivateInvestment
}

/**
 * Validate, if approval (broker) mandant is valid.
 * @param {{broker: {bca_main_tenant_guid: string}}} approval Approval data
 * @param {{string}} mandant Mandant value
 */
const isApprovalMandantValid = (approval, mandant) => {
  return mandant == MANDANT_FILTER_OPTIONS.ALL.value
    || _.get(approval, 'broker.bca_main_tenant_guid') == mandant
}

/**
 * Validate, if approval service concept is valid.
 * @param {{business_case: {service_concept: number}}} approval Approval data
 * @param servicePlan
 */
const isApprovalServiceConceptValid = (approval, serviceConcept) => {
  return serviceConcept == SERVICE_CONCEPT_FILTER_OPTIONS.ALL.value
    || _.get(approval, 'business_case.service_concept') == serviceConcept
}


const isDocumentEventTypeValid = (document, eventType) => {
  return eventType == EVENT_TYPES_FILTER_OPTIONS.ALL.value
    || document.event_type == eventType
}


const isDocumentStatusValid = (document, status) => {
  return status == APPROVAL_STATUS_FILTER_OPTIONS.ALL.value
    || document.status == status
}


const isDocumentAcceptedByFilters = (document, filters) => {
  return isDocumentStatusValid(document, filters.status.value)
    && isDocumentEventTypeValid(document, filters.eventType.value)
}


/**
 * Filter documents by filter criteria
 * @param {{}[]} documents Documents data
 * @param {{}} filters Filters criteria
 */
const filterApprovalDocuments = (documents, filters) => {

  return _.filter(documents, (document) => isDocumentAcceptedByFilters(document, filters))

}

/**
 * Validate, if approval is valid for selected filters
 * @param {{}} approval Approval data
 * @param {{}} filters Object with filters to apply
 */
const isApprovalAcceptedByFilters = (approval, filters) => {

  const isApprovalValid = isApprovalServiceConceptValid(approval, filters.serviceConcept.value)
    && isApprovalMandantValid(approval, filters.mandant.value)
    && isApprovalFinancialInvestmentValid(approval, filters.financialInvestment.value)
    && isApprovalInvestedAmountValid(approval, filters.investedValuesRange)
    && isApprovalDateValid(approval, filters.datesRange)

  if (!isApprovalValid) return false;

  approval.documents = filterApprovalDocuments(approval.documents, filters)

  if (_.isEmpty(approval.documents)) return false;

  return isApprovalValid
}

/**
 * Filter Business Cases approvals by filtering criteria.
 * @param {{}[]} approvals List of approvals to filter
 * @param {{}} filters Object with filters to apply
 */
export const filterBusinessCasesApprovals = (approvals, filters) => {

  const result = []

  const approvalsCopy = _.cloneDeep(approvals)

  approvalsCopy.forEach((approval) => isApprovalAcceptedByFilters(approval, filters) && result.push(approval))

  return result

}

const _orderByTotalInvestedValue = (type) => {
  return [[(approval) => parseFloat(_.get(approval, 'business_case.total_amount' || 0))], [type]]
}

const _orderByCustodian = (type) => {
  return [[(approval) => _.get(approval, 'business_case.custodian.name' ) || ''], [type]]
}

const _orderByApprovalStatus = (type) => {
  return [[(approval) => getApprovalStatus(approval).value], [type]]
}

const _orderByApprovalContractNumber = (type) => {
  return [[(approval) => _.get(approval, 'business_case.contract_number') || []], [type]]
}


const _orderByDocumentStatus = (type) => {
  return [[(document) => document.status], [type]]
}

const _orderByDocumentEventType = (type) => {
  return [[(document) => document.event_type], [type]]
}

const _orderByBrokerLastName = (type) => {
  return [[(approval) => (_.get(approval, 'broker.broker_last_name') || '').toLowerCase()], [type]]
}

const _orderByBrokerId = (type) => {
  return [[(approval) => (_.get(approval, 'broker.broker_id') || '').toLowerCase()], [type]]
}

const _orderByCustomerId = (type) => {
  return [[(approval) => (_.get(approval, 'customer.customer_id') || '').toLowerCase()], [type]]
}

const _orderByApprovalCreatedAtDate = (type) => {
  return [[(approval) => moment(_.get(approval, 'business_case.created_at'), 'YYYY-MM-DD')], [type]]
}

export const sortApprovals = (approvals, sortType) => {
  const [iteratees, orders] = {
    [APPROVALS_SORTING_OPTIONS.DEPOT_VOLUME_DESC.value]: _orderByTotalInvestedValue('desc'),
    [APPROVALS_SORTING_OPTIONS.DEPOT_VOLUME_ASC.value]: _orderByTotalInvestedValue('asc'),
    [APPROVALS_SORTING_OPTIONS.NAME_ASC.value]: _orderByBrokerLastName('asc'),
    [APPROVALS_SORTING_OPTIONS.NAME_DESC.value]: _orderByBrokerLastName('desc'),
    [APPROVALS_SORTING_OPTIONS.DATE_ASC.value]: _orderByApprovalCreatedAtDate('asc'),
    [APPROVALS_SORTING_OPTIONS.DATE_DESC.value]: _orderByApprovalCreatedAtDate('desc'),
    [APPROVALS_SORTING_OPTIONS.BROKER_ID_ASC.value]: _orderByBrokerId('asc'),
    [APPROVALS_SORTING_OPTIONS.BROKER_ID_DESC.value]: _orderByBrokerId('desc'),
    [APPROVALS_SORTING_OPTIONS.CUSTOMER_ID_ASC.value]: _orderByCustomerId('asc'),
    [APPROVALS_SORTING_OPTIONS.CUSTOMER_ID_DESC.value]: _orderByCustomerId('desc'),
    [APPROVALS_SORTING_OPTIONS.CUSTODIAN_ASC.value]: _orderByCustodian('asc'),
    [APPROVALS_SORTING_OPTIONS.CUSTODIAN_DESC.value]: _orderByCustodian('desc'),
    [APPROVALS_SORTING_OPTIONS.STATUS_ASC.value]: _orderByApprovalStatus('asc'),
    [APPROVALS_SORTING_OPTIONS.STATUS_DESC.value]: _orderByApprovalStatus('desc'),
    [APPROVALS_SORTING_OPTIONS.CONTRACT_NUMBER_ASC.value]: _orderByApprovalContractNumber('asc'),
    [APPROVALS_SORTING_OPTIONS.CONTRACT_NUMBER_DESC.value]: _orderByApprovalContractNumber('desc'),
    [APPROVALS_SORTING_OPTIONS.EVENT_TYPE_ASC.value]: [undefined, undefined],
    [APPROVALS_SORTING_OPTIONS.EVENT_TYPE_DESC.value]: [undefined, undefined],
  }[sortType.value]

  const approvalsSorted = iteratees
    ? _.orderBy(approvals, iteratees, orders)
    : approvals

  if ([APPROVALS_SORTING_OPTIONS.STATUS_ASC.value,
    APPROVALS_SORTING_OPTIONS.STATUS_DESC.value,
    APPROVALS_SORTING_OPTIONS.EVENT_TYPE_ASC.value,
    APPROVALS_SORTING_OPTIONS.EVENT_TYPE_DESC.value].includes(sortType.value)) {
    const [documentsIteratees, documentsOrders] = {
      [APPROVALS_SORTING_OPTIONS.STATUS_ASC.value]: _orderByDocumentStatus('asc'),
      [APPROVALS_SORTING_OPTIONS.STATUS_DESC.value]: _orderByDocumentStatus('desc'),
      [APPROVALS_SORTING_OPTIONS.EVENT_TYPE_ASC.value]: _orderByDocumentEventType('asc'),
      [APPROVALS_SORTING_OPTIONS.EVENT_TYPE_DESC.value]: _orderByDocumentEventType('desc'),
    }[sortType.value]

    approvalsSorted.forEach((approval) => {
      approval.documents = _.orderBy(approval.documents, documentsIteratees, documentsOrders)
    })
  }

  return approvalsSorted
}