import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx'

import useStyles from './styles'

Icon.propTypes = {
  variant: PropTypes.oneOf(['contained', 'outlined'])
};

Icon.defaultProps = {
  variant: 'outlined'
}

function Icon(props) {

  const classes = useStyles()

  const {
    variant
  } = props

  return (
    <span className={clsx(classes.icon, variant == 'contained' ? classes.contained : classes.outlined)}>
      { props.children }
    </span>
  );
}

export default Icon;