export const ADD_CUSTOMERS_TO_STORE = 'ADD_CUSTOMERS_TO_STORE';
export const REMOVE_CUSTOMERS_FROM_STORE = 'REMOVE_CUSTOMERS_FROM_STORE';
export const ADD_CUSTOMERS_ERROR_TO_STORE = 'ADD_CUSTOMERS_ERROR_TO_STORE';
export const ADD_SHOW_REPORTING_CUSTOMERS_TO_STORE = 'ADD_SHOW_REPORTING_CUSTOMERS_TO_STORE';
export const ADD_SHOW_REPORTING_CUSTOMERS_ERROR_TO_STORE = 'ADD_SHOW_REPORTING_CUSTOMERS_ERROR_TO_STORE';
export const ADD_CUSTOMERS_WITHOUT_GROUP_TO_STORE = 'ADD_CUSTOMERS_WITHOUT_GROUP_TO_STORE';
export const UPDATE_CUSTOMER_IN_STORE = 'UPDATE_CUSTOMER_IN_STORE';

export const PORTFOLIO_BANK_IDENTIFIER = 22;
export const PORTFOLIO_TEXT_IDENTIFIER = 24;
export const PORTFOLIO_PRODUCT_CATEGORY_IDENTIFIER = 23;
export const PORTFOLIO_PI_STRATEGY_IDENTIFIER = 28;
export const PORTFOLIO_NON_PI_STRATEGY_IDENTIFIER = 29;
export const PORTFOLIO_DEPOT_TYPE_IDENTIFIER = 32;

export const MOCK_CUSTOMERS_LIST = [
  {
    "id":1,
    "broker":"4351899999",
    "agency":"43518",
    "user":"c4351810420",
    "customer_id":"123456789",
    "phone":"",
    "aggregated_portfolio_value":"10000",
    "customer_full_name":"Maximillian Mustermann",
    "risk_category":0,
    "postbox":false,
    "group":28,
  }, {
    "id":1,
    "broker":"4351899999",
    "agency":"43518",
    "user":"c4351810420",
    "customer_id":"123456789",
    "phone":"",
    "aggregated_portfolio_value":"10000",
    "customer_full_name":"Maximillian Mustermann",
    "risk_category":0,
    "postbox":false,
    "group":28,
  }, {
    "id":1,
    "broker":"4351899999",
    "agency":"43518",
    "user":"c4351810420",
    "customer_id":"123456789",
    "phone":"",
    "aggregated_portfolio_value":"10000",
    "customer_full_name":"Maximillian Mustermann",
    "risk_category":0,
    "postbox":false,
    "group":28,
  }, {
    "id":1,
    "broker":"4351899999",
    "agency":"43518",
    "user":"c4351810420",
    "customer_id":"123456789",
    "phone":"",
    "aggregated_portfolio_value":"10000",
    "customer_full_name":"Maximillian Mustermann",
    "risk_category":0,
    "postbox":false,
    "group":28,
  }
];