import { 
  regions,
  countries,
  nearEastCodes,
  latinAmericanAndCaribbeanCodes,
} from "./constants";
import {NO_DATA} from "../../../../utils/constants";

export function allocationErrors(data) {
  if (!data || !data.by_component) {
    return [];
  }
  return data['by_component'].filter((item) => {
    if (item.error) {
      return item;
    }
  });
}

function getValuesByRegion(values) {
  const validCodes = [];
  const valuesByRegion = values.reduce((acc, region) => {
    const code = region.code || 'OTHERS';
    validCodes.push(code);
    acc[code] = {
      name: region.name,
      code: region.code,
      marketValue: region.market_value,
      weight: region.weight * 100
    };
    return acc
  }, {});

  return {
    valuesByRegion,
    validCodes
  }
}

function getDataForAreas(valuesForRegions) {
  return countries.reduce((acc, country) => {
    let regionCode = 'OTHERS';
    if (country.continent === 'AF' || nearEastCodes.includes(country.code)) {
      // Africa and Near East
      regionCode = 'AF';
      acc.AF.push(country)
    } else if (country.continent === 'NA' && !latinAmericanAndCaribbeanCodes.includes(country.code) && country.code !== 'GL') {
      // North America (not Greenland)
      regionCode = 'AM';
      acc.AM.push(country)
    } else if (country.continent === 'AS' || country.continent === 'OC' || country.code === 'RU') {
      // Asia-Pacific + Rusia
      regionCode = 'AS';
      acc.AS.push(country)
    } else if (country.continent === 'SA' || latinAmericanAndCaribbeanCodes.includes(country.code)) {
      // Latin America
      regionCode = 'LA';
      acc.LA.push(country)
    } else if (country.continent === 'EU' || country.code === 'GL') {
      // Europe
      regionCode = 'EU';
      acc.EU.push(country)
    } else {
      // Others
      acc.OTHERS.push(country)
    }

    country.value = valuesForRegions[regionCode].weight;

    return acc
  }, {
    AF: [],
    AM: [],
    AS: [],
    EU: [],
    LA: [],
    OTHERS: []
  })
}

function getCountriesByRegion(valuesForRegions) {
  regions.forEach((region) => {
    const code = region.code;

    if (!valuesForRegions[code]) {
      valuesForRegions[code] = {
        name: region.name,
        marketValue: 0,
        weight: 0,
        code
      }
    }
  });

  return getDataForAreas(valuesForRegions);
}

function hexToRGB(color) {
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);

  return `rgb(${r}, ${g}, ${b})`
}


export function getSeriesFromValues(values, options) {
  const {
    hideZerosFor=['OTHERS', NO_DATA],
    colors
  } = options;

  const { valuesByRegion, validCodes } = getValuesByRegion(values);
  // get list of countries and set the correct `value` property for each country
  
  const countriesByRegion = getCountriesByRegion(valuesByRegion);

  const regionsWeights = [];
  // get a sorted array of region codes and their regionsWeights, so opacity can be
  // applied
  Object.keys(valuesByRegion).forEach((regionCode) => {
    regionsWeights.push({
      code: regionCode,
      weight: valuesByRegion[regionCode].weight
    })
  });
  regionsWeights.sort((a, b) => b.weight - a.weight);

  /**
   * Finally go through the sorted elements and add the series to the final result.
   *  - With this method, the legend will also appear sorted
   *  - If region code is in the list of `hideZerosFor`, series will be added only when the data exists
   *    for the given region code.
   */

  const series = [{
    allAreas: true,
    showInLegend: false
  }];

  regionsWeights.forEach((elem, idx) => {
    const regionCode = elem.code;
    const weight = elem.weight;
    const valueIsZero = weight === 0;
    const showCode = validCodes.includes(regionCode);

    if (!(hideZerosFor || []).includes(regionCode) || (showCode && !valueIsZero)) {
      const serie = {
        type: 'map',
        data: regionCode === NO_DATA ? [{value: weight}] : countriesByRegion[regionCode],
        joinBy: ['iso-a2', 'code'],
        name: valuesByRegion[regionCode].name,
        borderWidth: 0.1,
      };
      if (colors[idx]) {
        serie.color = hexToRGB(colors[idx]);
      }
      series.push(serie);
    }
  });

  return series
}