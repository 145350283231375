import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  headerContainer: {
    '& button > span > span': {
      fontSize: 14
    },

    '& div > span': {
      fontFamily: 'Roboto-Bold',
      fontSize: 16,
      color: '#202A38',

      display: 'inline-block'
    }
  },
  tableHead: {
    '& td': {
      color: '#80858C'
    }
  },
  tableCell: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    '&:nth-of-type(1)': {
      width: 318,
    },
    '&:nth-child(2)': {
      minWidth: 83,
      width: 83
    },
    '&:nth-child(3)': {
      width: 40
    },
    '&:last-child': {
      width: 100
    }
  }
})

export default makeStyles(styles)