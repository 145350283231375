import { makeStyles, createStyles } from '@material-ui/core'

const defaultStyles = {
  container: {
    width: '100%',
    borderRadius: 5,
    boxShadow: '0px 3px 15px #00000033',
    backgroundColor: 'white'
  },
  header: {
    padding: 15,
    borderRadius: '5px 5px 0px 0px',
    backgroundColor: '#394352',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  portfolioName: {
    fontSize: 20,
    fontFamily: 'Roboto-Medium',
    color: 'white',
    margin: 0
  },
  portfolioCategory: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    color: 'white',
  },
  content: {
    padding: '16px 10px 30px'
  },
  text: {},
  footer: {
    padding: '35px 20px 0',
    '& $text': {
      fontFamily: 'Roboto-Regular',
      color: '#4D505B',
      fontSize: 16,
      marginBottom: 0
    },

    '& > p': {
      fontFamily: 'Roboto-Medium',
      fontSize: 16,
      color: '#4D505B'
    }
  },

  tableCell: {
    padding: '8px 8px',
    verticalAlign: 'middle !important'
  },

  tableHead: {
    background: '#F5F6F7',
    '& td': {
      fontFamily: 'Roboto-Bold',
      color: '#80858C',
      padding: '15px 8px',

      '&:first-child': {
        paddingLeft: 22
      },

      '&:nth-child(4)': {
        textAlign: 'right'
      }
    }
  },
  tableRow: {
    '& td': {
      '&:first-child': {
        paddingLeft: 22,
        width: 400
      },
      '&:nth-child(2)': {
        width: 140
      },
      '&:nth-child(3)': {
        width: 140
      },
      '&:nth-child(4)': {
        width: 180
      }
    }
  },
  tableHeader: {
    fontFamily: 'Roboto-Bold',
    fontSize: 24
  },
  totalAmount: {
    backgroundColor: '#D8DCDF',
    fontFamily: "Roboto-Bold",
    fontSize: 16,
    padding: '10px 16px',
    display: 'flex',
    '& div:last-child': {
      marginLeft: 'auto',
    }
  }
}
const savingsPlanStyles = Object.create(defaultStyles)
savingsPlanStyles.tableRow = {
  '& td': {
    '&:first-child': {
      paddingLeft: 22,
      width: 400
    },
    '&:nth-child(2)': {
      width: 140
    },
    '&:nth-child(3)': {
      width: 100
    },
    '&:nth-child(4)': {
      width: 140
    }
  }
}
savingsPlanStyles.tableHead = {
  '& td': {
    fontFamily: 'Roboto-Bold',
    color: '#80858C',
    padding: '15px 8px',

    '&:first-child': {
      paddingLeft: 22
    },

    '&:nth-child(4)': {
      textAlign: 'right'
    }
  }
}

export const styles = theme => createStyles(defaultStyles)

export const useTableStyles = makeStyles(theme => createStyles({
  name: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    display: 'inline',
    margin: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '& > p': {
      width: 'auto',
      margin: 0,

      '&:first-child': {
        textAlign: 'right',
        marginRight: 3
      },
      '&:last-child': {
        textAlign: 'left',
        marginLeft: 3
      }
    }
  }
}))

export const useSavingsPlanTableStyles = makeStyles(savingsPlanStyles)

export default makeStyles(styles)