import React from 'react';
import _ from 'lodash';
import { AddressColumn, CustomerFullnameMenuColumn, IconColumn } from '../../CustomersNew';
import { hasPortfolios } from '../../utils';
import DashboardTable from '../../../DashboardTable/DashboardTable';
import useStyles from './styles';

const CustomersList = ({ customers, extraColumns }) => {
  const classes = useStyles();

  const hasPortfoliosTable = {
    content: [{
      body: {content: (item) => hasPortfolios(item) ? 'Ja' : 'Nein'},
      header:  {
        content: () => (
          <>
            <span>Investment</span><br/>
            <span>Depots</span>
          </>
        )
      }
    }]
  }

  const extraColsInSingleColumn = (() => {
    // Returns structure to display extra columns as single column to fit action btn-s on small screens

    // some columns that are passed as extraColumns are not 'action btn-s' -> there is no need to display them -> filter
    const columnsToDisplay = (extraColumns || []).filter((col) => !col.isHiddenOnSmallScreens)

    return {
      content: columnsToDisplay.map(col => {
        const cell_content = _.get(col, 'content', [])
        return !_.isEmpty(cell_content) ? cell_content[0] : col
      }),
      align: 'right'
    }
  })()

  const structure = React.useMemo(() => {
    const tableStructure = {
      default: [
        IconColumn,
        CustomerFullnameMenuColumn,
        AddressColumn,
        hasPortfoliosTable,
        ...extraColumns  // extraColumns are displayed as separate columns
      ],

      xs: [
        IconColumn,
        CustomerFullnameMenuColumn,
        extraColsInSingleColumn,
      ],

      sm: [
        IconColumn,
        CustomerFullnameMenuColumn,
        AddressColumn,
        extraColsInSingleColumn
      ],
    }

    // if more than 2 extra col-s on medium screens they must be displayed in one column to fit on screen
    if(!_.isEmpty(extraColumns || []) && extraColumns.length > 2){
      tableStructure.md = [
        IconColumn,
        CustomerFullnameMenuColumn,
        AddressColumn,
        hasPortfoliosTable,
        extraColsInSingleColumn
      ]
    }

    return tableStructure

  }, [extraColumns]);

  if (_.isEmpty(customers)) {
    return null;
  }

  return (
    <DashboardTable
      dataSource={customers}
      structure={structure}
      expanded
      withFooter={false}
      tableClasses={classes}
      tableLayout={"auto"}
      tableSizeSwitchEnabled
    />
  );
}

export default CustomersList;