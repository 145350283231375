import React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";

import styles from "./styles"


function GlobeMark({ classes, empty, dark }) {
  const wrapperClassName = clsx([classes.iconWrapper, empty && classes.iconWrapperEmpty]);
  const iconClassName = clsx([classes.icon, empty && classes.iconEmpty, dark && classes.iconDark]);

  return (
    <span className={wrapperClassName}>
      <LanguageIcon classes={{ root: iconClassName }} /> 
    </span>
  );
}

export default withStyles(styles)(GlobeMark);
