import React from "react";
import {withEuroOrDash,} from "../../../utils/utils";
const InflowNameCell = {
  header: 'Zuflüsse',
  body: {
    content: (item) => item.inflow && item.inflow.name
  },
  footer: {
    content: 'Summe',
    className: 'bold'
  },
  key: 'InflowNameCell'
}
const InflowValueCell = {
  body: {
    content: (item) => item.inflow && withEuroOrDash(item.inflow.value),
  },
  footer: {
    content: (item, options) => withEuroOrDash(options.inflows_total),
    className: 'bold'
  },
  key: 'InflowValueCell'
}
const OutflowNameCell = {
  header: 'Abflüsse',
  body: {
    content: (item) => item.outflow && item.outflow.name
  },
  footer: {
    content: 'Summe',
    className: 'bold'
  },
  key: 'OutflowNameCell'
}
const OutflowValueCell = {
  body: {
    content: (item) => item.outflow && withEuroOrDash(item.outflow.value),
  },
  footer: {
    content: (item, options) => withEuroOrDash(options.outflows_total),
    className: 'bold'
  },
  key: 'OutflowValueCell'
}
const tableStructure = {
  default: [
    {
      content: [InflowNameCell],
    },
    {
      content: [InflowValueCell],
      align: 'right'
    },
    {
      content: [OutflowNameCell],
    },
    {
      content: [OutflowValueCell],
      align: 'right'
    }
  ],
  xs: [
    {
      content: [InflowNameCell, InflowValueCell]
    },
    {
      content: [OutflowNameCell, OutflowValueCell],
    }
  ]
}
export default tableStructure;