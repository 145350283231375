import React from 'react';

import DashboardTable from "../../DashboardTable/DashboardTable";
import tableStructure from "./table-data";
import useStyles from './styles';


const ClearingAccountTable = props => {
  const classes = useStyles();
  const {
    data
  } = props;

  return (
    <DashboardTable
      structure={tableStructure}
      dataSource={data}
      expanded
      tableClasses={classes}
    />
  );
};

export default ClearingAccountTable;