import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    position: 'relative',
    width: 65,
    height: 24,
    cursor: 'pointer',
  },
  switchBase: {
    padding: '1px !important',
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
    },
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 65,
    height: '100%',
    padding: 0,
    borderRadius: 20,
    margin: 0,
    pointerEvents: 'none',
  },
  thumb: {
    position: 'relative',
    top: 1,
    left: 1,
    width: 20,
    height: 20,
  },
  track: {
    width: '100%',
    borderRadius: 26 / 2,
    backgroundColor: '#ADB6BD',
    opacity: 1,
    transition: 'none',
  },
  checked: {
    transform: 'translateX(185%) !important'
  },
  focusVisible: {},

  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
    cursor: 'initial'
  },

  /**Label styles */
  label: {
    position: 'absolute',
    width: 65,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: 'white',
    padding: '0 11px',
    userSelect: 'none'
  },
  onLabel: {
    justifyContent: 'flex-start'
  },
  offLabel: {
    justifyContent: 'flex-end'
  }
})

export default makeStyles(styles)