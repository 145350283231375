import React from "react";
import _ from "lodash";
import moment from 'moment';
import {Dialog, IconButton, DialogContent} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import CombinedTradeStep from "../../../Trades/components/TradeStep/CombinedTradeStep"
import CloseIcon from "@material-ui/icons/Close";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import {
  TRANSACTION_INSTR,
  TRANSACTION_TYPE_ORDER_TYPE_MAPPING,
  TRANSACTION_TYPE_VALUES,
  TRANSACTION_TYPE_SWITCH_OUT,
  TRANSACTION_TYPE_SWITCH_IN
} from "../../../Trades/constants";
import {
  getFee,
  MODAL_LOADER_ACTION,
  ModalLoader
} from '../ModalInstruments';
import {VirtualPortfolioHandlerResource} from "../../../../utils/api";
import {displayErrorSnackBar} from "../../../../components/SnackbarProvider/actions";
import {getErrorMessage, VIRTUAL_PORTFOLIO_QTY_DECIMALS} from "../../../../utils/utils";
import styles from './styles'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import {getDummyDataservice, validateTransactions} from "../../../VirtualPortfoliosDashboard/utils";

const ORDER_CREATION_FAILED = 'CIOS: Fehler bei der Ordergenerierung (Einmalanlage).';  // CIOS: Error in order generation (one-time investment).

const VIRTUAL_TRADE_TRANSACTIONS = [
  TRANSACTION_TYPE_VALUES.BUY,
  TRANSACTION_TYPE_VALUES.SAVINGS_PLAN,
  TRANSACTION_TYPE_VALUES.SELL,
  TRANSACTION_TYPE_VALUES.SWITCH
];

const prepareTransaction = (p, transaction, transactionType, preparedTransactions) => {

  function getAmountsAndQuantity(transactionData){
    const isSell = _.includes([TRANSACTION_TYPE_VALUES.SELL, TRANSACTION_TYPE_SWITCH_OUT], transactionType);
    // no fee for sell
    const fee = isSell ? 0 : getFee(transactionData.data, transactionData.discount || 0);
    const price = transactionData.data.price_eur;
    let quantity, assetAmount, amount;
    if(transactionData.transaction_type === TRANSACTION_INSTR.amount){
      amount = transactionData.transaction_value;
      // for Buy we have amount as Brutto (AssetAmount + Fee) while for sell - Netto (AssetAmount)
      assetAmount = isSell ? amount : amount * (1 - fee);
      quantity = assetAmount / price
    } else {
      quantity = transactionData.transaction_value;
      assetAmount = amount = quantity * price;
    }

    const feeInEUR = amount * fee;

    if (isSell){
      // for sell transaction_value should be negative for P&L calculations
      quantity = -quantity;
      assetAmount = -assetAmount;
    }

    const amountTotal = assetAmount + feeInEUR;

    return [amountTotal.toFixed(2), feeInEUR.toFixed(2), quantity.toFixed(VIRTUAL_PORTFOLIO_QTY_DECIMALS)]
  }

  let orderData = {};
  if(transactionType === TRANSACTION_TYPE_VALUES.SAVINGS_PLAN) {
    // get structure for sparplan buy
    orderData = {
      payment_plan_period: transaction.rotation,
      payment_plan_start_date: moment(transaction.from_date).format('YYYY-MM-DD'),
      payment_plan_end_date: transaction.till_date ? moment(transaction.till_date).format('YYYY-MM-DD') : undefined,
      payment_plan_value: transaction.transaction_value,
      asset_extention_id: transaction.data.asset_extention_id,
      transaction_action: transaction.action,
    }

  } else if(_.includes([TRANSACTION_TYPE_VALUES.BUY, TRANSACTION_TYPE_VALUES.SELL, TRANSACTION_TYPE_SWITCH_OUT, TRANSACTION_TYPE_SWITCH_IN], transactionType)) {
    // get structure for buy, sell, switch
    let [amount, feeInEUR, quantity] = getAmountsAndQuantity(transaction);
    orderData = {
      value: Number(amount), // invested value
      quantity: Number(quantity),
      total_fee: Number(feeInEUR),
      order_date: transaction.deposit_from_date.format('YYYY-MM-DD'),
      price: transaction.data.price_eur,
    }
  }

  // transactionType can be 'buy', 'sell' etc.; transaction.transaction_type if one of TRANSACTION_INSTR values
  orderData.order_type = TRANSACTION_TYPE_ORDER_TYPE_MAPPING[transactionType][transaction.transaction_type];
  if (!!transaction.relation_id) {
    orderData.relation_id = transaction.relation_id;
  }
  if (!!transaction.transaction_id) {
    orderData.transaction_id = transaction.transaction_id;
  }

  orderData.discount = transaction.discount || 0;

  const isin = transaction.data.isin;
  
  const result = {
    portfolio_id: p.data.id,
    asset_container_id: p.data.asset_container_id,
    transaction_type: transactionType,
    instrument_data: {
      discount: orderData.discount,
      isin: isin,
      // id - existing asset, asset_id - payment plan
      asset_id: transaction.data.asset_id || transaction.data.id ||
        _.get(_.find(p.data.components, (c) => c.isin === isin), 'id'),
    },
    order_data: orderData
  }
  if (transaction.hasOwnProperty('transaction_id')) {
    result.transaction_id = transaction.transaction_id
  }
  if (!!transaction.sub_transactions) {
    result.sub_transactions = transaction.sub_transactions;
  }

  preparedTransactions.push(result)
}

export const prepareOrdersFromTransaction = (portfoliosTransactions) => {
  // list of orders to create. Each element has structure {portfolio_id: {transaction_type1: [list_of_transactions], ... }}
  let ordersData = {
    [TRANSACTION_TYPE_VALUES.BUY]: [],
    [TRANSACTION_TYPE_VALUES.SAVINGS_PLAN]: [],
    [TRANSACTION_TYPE_VALUES.SELL]: [],

    // transactions to perform switch
    [TRANSACTION_TYPE_SWITCH_OUT]: [],  // transactions for assets you sell
    [TRANSACTION_TYPE_SWITCH_IN]: []    // transactions for assets you buy
  }

  // for each portfolio that has transaction
  portfoliosTransactions.map(p => {
    // loop over transaction types
    VIRTUAL_TRADE_TRANSACTIONS.map(transactionType => {

      let preparedTransactions = [];

      // 'switch' transaction is split to switch_out and switch_in to be sent to back-end
      // 'switch' transaction itself is what you sell (switch_out), inside it there are nested buy transactions (switch_in)
      let isSwitchTransaction = transactionType === TRANSACTION_TYPE_VALUES.SWITCH
      let ordersDataKey = isSwitchTransaction ? TRANSACTION_TYPE_SWITCH_OUT : transactionType

      // loop over portfolio's transactions of certain type to give them proper format
      p.transactions[transactionType].map(transaction => {

        if (isSwitchTransaction) {
          // Generate uniq identifier to link switch transactions between each other.
          transaction['relation_id'] = new Date().getTime();
        }

        prepareTransaction(p, transaction,  ordersDataKey, preparedTransactions)

        // as switch has nested buy transactions - prepare them too
        if (isSwitchTransaction){
          let preparedNestedBuyTransactions = [];
          transaction.buy.map(nestedBuyTransaction => {
            // for switch in - use amount from calculated
            prepareTransaction(p,
              {
                ...nestedBuyTransaction,
                transaction_type: TRANSACTION_INSTR.amount,
                transaction_value: nestedBuyTransaction.calculated.transaction_value_euro,
                relation_id: transaction.relation_id
              },
              TRANSACTION_TYPE_SWITCH_IN, preparedNestedBuyTransactions)
          })
          if (!_.isEmpty(preparedNestedBuyTransactions)){
             ordersData[TRANSACTION_TYPE_SWITCH_IN].push(...preparedNestedBuyTransactions)
          }
        }
      })

      // if portfolio had some transactions - add them
      if(!_.isEmpty(preparedTransactions)){
        ordersData[ordersDataKey].push(...preparedTransactions)
      }
    })
  })

  return ordersData
}

const getEditedTransactions = function *(transactions) {

  for (let portfolio of transactions) {
    for (let key in portfolio.transactions) {
      if (_.isEmpty(portfolio.transactions[key])) {
        continue;
      }

      yield portfolio.transactions[key];
    }
  }

}

const VirtualOrderModal = (props) => {

  const {
    open,
    onClose,
    classes,

    portfoliosTransactions,
    setPortfoliosTransactions,
    initPortfoliosTransactions,
    portfolioOwnerId,
    useUpdate,
    transactionEditing,
    handleEmptyMpUpdated
  } = props

  const [loading, setLoading] = React.useState(false);
  const [actionType, setActionType] = React.useState(MODAL_LOADER_ACTION.CREATE);

  const onCancelClick = async () => {
    try {
      if (transactionEditing && props.onCancelClick) {
        setLoading(true);
        setActionType(MODAL_LOADER_ACTION.DELETE);
        const editedTransactions = _.flatten([...getEditedTransactions(portfoliosTransactions)]);
        if (!!editedTransactions.length) {
          const prepareDeleteBody = (transaction) => ({
            id: transaction.transaction_id,
            asset_details: {
              id: transaction.data.id,
              name: transaction.data.name
            }
          });
          const cancelResult = await props.onCancelClick(editedTransactions.map((editedTransaction) => ({
            ...prepareDeleteBody(editedTransaction),
            components: !_.isEmpty(editedTransaction.buy) ? editedTransaction.buy.map(prepareDeleteBody) : []
          })), true, true);

          if (!cancelResult) {
            return;
          }
        }
      }
      initPortfoliosTransactions() // resets portfolios transactions
      onClose() // closes the modal
    } finally {
      setLoading(false)
    }

  }

  const onSaveClick = () => {
    // validate
    if (validateTransactions(portfoliosTransactions, setPortfoliosTransactions, 'virtual-order-dialog')){
      // list of orders to create. Each element has structure {portfolio_id: {transaction_type1: [list_of_transactions], ... }}
      let ordersData = prepareOrdersFromTransaction(portfoliosTransactions);

      // send request to create orders
      if(!_.isEmpty(ordersData)) {
        let _actionType = MODAL_LOADER_ACTION.CREATE;
        let methodToCall = !useUpdate
          ? 'createOrders'
          : 'updateOrders';
        let methodToCallArgs = [portfolioOwnerId, ordersData];
        if (transactionEditing) {
          methodToCall = 'updateOrder';
          _actionType = MODAL_LOADER_ACTION.EDIT;
        }
        setActionType(_actionType);
        setLoading(true);

        (props.resource || VirtualPortfolioHandlerResource)[methodToCall](...methodToCallArgs)
        .then((response) => {
          props.refresh()
          onClose();

          let internalErrors = _.get(response, 'errors');
          if (!_.isEmpty(internalErrors)) {
            props.dispatch(displayErrorSnackBar( _.join(internalErrors, '\n')));
          }

          if(!!handleEmptyMpUpdated && useUpdate) {
            // reset state after empty Mp is updated with data
            handleEmptyMpUpdated();
          }

        })
        .catch(error => {
          props.dispatch(displayErrorSnackBar(getErrorMessage(ORDER_CREATION_FAILED)))
        })
        .finally(() => {
          setLoading(false)
        })
      }
    }
  }

  return (
    <>
      {portfoliosTransactions &&
        <Dialog maxWidth='xl' fullWidth open={open} onClose={loading ? null : onClose} id={'virtual-order-dialog'}>
          {loading ? (
            <div style={{height: 200}}>
              <ModalLoader withMsg={true} action={actionType}/>
            </div>
          ) : (
            <>
              {/* close icon */}
              <DialogTitle>
                <div className={classes.header}>
                  <IconButton className={classes.closeButton} aria-label="close" onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </DialogTitle>

              {/* step */}
              <DialogContent>
                <CombinedTradeStep
                  // props with data
                  questions={ [{answer: portfoliosTransactions}] }
                  dataService={getDummyDataservice(portfolioOwnerId)}
                  resource={props.resource}
                  // flags
                  isVirtual
                  isCustomerApp={props.isCustomerApp}
                  isTransactionsEditing={transactionEditing}
                  withProcessWrapper={!transactionEditing}
                  showSectionsWithoutTransactions={!transactionEditing}
                  showTotal={!transactionEditing}
                  sectionsCollapsable={!transactionEditing}
                  showTradingActions={!transactionEditing}
                  // functions
                  onAnswerChange={(_question_uid, portfoliosWithTransactions) => {
                    setPortfoliosTransactions([...portfoliosWithTransactions]) // destruction is used to trigger state update
                  }}
                />
              </DialogContent>

              <DialogActions classes={{root: classes.dialogActions}}>
                <PrimaryButton
                  customClasses={{root: classes.deleteBtn}}
                  text={transactionEditing ? <><i className={"far fa-trash-alt"} /> Order löschen</> : "Abbrechen"}
                  onButtonClick={onCancelClick}
                />
                {/* save transactions btn */}
                <PrimaryButton
                  text={'Speichern'}
                  disabled={!portfoliosTransactions.some(p => _.values(p.transactions).some(transactionsOfType => !_.isEmpty(transactionsOfType))) }
                  onButtonClick={onSaveClick}
                />
              </DialogActions>
            </>
          )}

        </Dialog>
      }
    </>
  )
}


export default withStyles(styles)(VirtualOrderModal);