import images from '../../../../images';

const styles = (theme) => ({
  headerContainer: {
    height: 72,
    backgroundColor: '#fff',
    boxShadow: '0px 0px 6px #00000029',
    display: 'flex',
    zIndex: 20
  },
  headerRightWrapper: {
    display: 'flex',
    width: '100%',

    ['@media (max-width: 700px)']: {
      overflowX: 'auto',
      '&.menu-open': {
        position: "absolute"
      }
    },
  },
  headerSideContainer: {
    display: 'flex',
    alignItems: 'center',
    //justifyContent: 'space-between',
    //backgroundColor: '#202A38',
    backgroundColor: 'white',
    //transition: 'all',
    //transitionDuration: '.2s',
  },
  headerSideContainerOpen: {
    backgroundColor: '#202A38 !important',
    minWidth: 328,
    zIndex: 2,
  },
  headerLogo: {
    width: 114,
    height: 34,
    background: `url(${images.diva_logo}) no-repeat`,
    marginLeft: 35,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    // hide logo on small screens when menu is collapsed
    [theme.breakpoints.down('xs')]: {
      '&.collapsed': {
        display: 'none'
      }
    }
  },
  userLogoWrapper: {
    padding: '0 !important',
  },
  userLogo: {
    width: 48,
    height: 48,
    color: '#2A3545',
    fontFamily: 'Roboto-Bold',
    fontSize: 18,
    padding: '12px 8px',
    backgroundColor: '#D9DCDF',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    borderRadius: '50%',
  },
  headerSideButtonWrapper: {
    width: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#202A38',
    height: '100%'
  },
  headerSearchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    borderRight: '1px solid #d9dcdf',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-end',
    },
  },
  headerNotificationContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem',
    height: 72,

    [theme.breakpoints.down('md')]: {
      padding: 0
    },

    '& > div:last-of-type': {
      padding: '0 1rem',
      ['@media (max-width: 576px)']: {
        padding: '0 0.66rem 0 0',
      }
    },
    '& .MuiIconButton-root': {
      padding: '13px',
      '& .svg-inline--fa': {
        fontSize: 25.6
      },

      ['@media (max-width: 700px)']: {
        padding: '8px',
      },

      [theme.breakpoints.down('xs')]: {
        padding: '5px',
      },
    }
  },
  dropdownMenuWrapper: {
    top: 'auto',
    height: 'auto',
    padding: 0,
    paddingRight: 'initial',
    backgroundColor: 'transparent',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
  },
  dropdownMenu: {
    borderRadius: 0,
    fontSize: '.8125rem',
    minWidth: '15rem',
    color: '#323C42',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    boxShadow: '0px 0px 6px #00000029',
    border: '1px solid #DFE1E3',
    overflow: 'visible',

    '&::before': {
      right: '1.0625rem',
      pointerEvents: 'none',
      content: '""',
      position: 'absolute',
      top: '-.375rem',
      overflow: 'hidden',
      width: '.75rem',
      height: '.75rem',
      backgroundImage: 'linear-gradient(135deg,#fff 50%,transparent 50%)',
      border: '1px solid rgba(0,0,0,.15)',
      borderRight: 'none',
      borderBottom: 'none',
      transform: 'rotate(45deg)'
    }
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    padding: '9px .9375rem',
    transition: 'background .2s cubic-bezier(.165,.84,.44,1)',
    width: '100%',
    clear: 'both',
    fontFamily: 'Roboto-Bold',
    color: '#323C42',
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    textDecoration: 'none',
    position: 'relative',
    fontSize: 15,

    '& > i, > svg': {
      fontSize: 18,
      marginRight: '0.5rem',
      color: '#2092DE'
    },
    '&.user-info': {
      flexDirection: 'column',
      alignItems: 'start',
      cursor: 'inherit'
    }

  },
  dropDownItemBadgeRoot: {
    marginLeft: 'auto',
    marginRight: 12,
    minWidth: 20
  },
  dropDownItemBadge: {
    backgroundColor: '#e25443',
    color: 'white',
    fontWeight: '700 !important'
  },
  menuItemDivider: {
    borderBottom: 'solid 1px #D9DCDF'
  },
  fontNormal: {
    fontFamily: 'Roboto-Regular'
  },
  infoTourButton: {
    marginRight: 24,
    '@media (max-width: 1150px)': {
      marginRight: 0,
    },
  }
});

export default styles;
