import {makeStyles} from "@material-ui/core";
import {chartLegendSize, smChartLegendSize} from "../../../../utils/constants";

export default makeStyles((theme) => ({
  chartSectionHeaderInfoIcon: {
    marginLeft: 15,
    color: theme.palette.primary.main
  },
  footerExplanation: {
    fontFamily: 'Roboto-Regular',
    fontSize: chartLegendSize,
    letterSpacing: "0px",
    color: "#80858C",
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: smChartLegendSize
    }
  },
}))