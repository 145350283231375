import React from 'react';
import connect from "react-redux/lib/connect/connect";
import useStyles from './styles'

import {
  Container,
  withWidth
} from '@material-ui/core';

import Navigation from './components/Navigation';
import TabsNavigation from './components/TabsNavigation';

import GroupSettings from './GroupSettings';
import CustomerSettings from './CustomerSettings';
import {DASHBOARD_SETTINGS_TYPE, DASHBOARD_TYPE} from "./constants";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import _ from 'lodash';
import Typography from "@material-ui/core/Typography";

export default withWidth()(connect()(withRouter((props) => {
  const {
    width,
    history,
    dispatch,
    dashboardType, // to detect broker/customer dashboard settings
    newDesign,
  } = props;

  const [selectedTab, setSelectedTab] = React.useState(0);
  const classes = useStyles();
  const [preselectedCustomerId, setPreselectedCustomerId] = React.useState()

  React.useEffect(() => {
    // if customer was passed as optional param in url, switch tab to CustomerSettings and set preselected customer
    let customerId = _.get(queryString.parse(props.location.search), 'customer_id')
    // if is_from_group was passed - made Group tab be selected
    let openGroupTab = _.get(queryString.parse(props.location.search), 'is_from_group')
    if (customerId){
      setSelectedTab(0)
      setPreselectedCustomerId(customerId)
    } else if (openGroupTab){
      setSelectedTab(1)
    }
  }, [_.get(queryString.parse(props.location.search), 'customer_id')])

  return (
    <React.Fragment>
      <Container className={'app-page-container'}>

        <Typography variant={"h1"} className={classes.header}>
          {dashboardType === DASHBOARD_TYPE.BROKER ? 'Vermittleransicht' : 'Kundenansicht'} (Individuell)
        </Typography>

        <TabsNavigation
          active={selectedTab}
          onActiveChanged={setSelectedTab}
        />
        {selectedTab === 0 && (
          <CustomerSettings dashboardType={dashboardType} preselectedCustomerId={preselectedCustomerId} newDesign={newDesign}/>
        )}
        {selectedTab === 1 && (
          <GroupSettings 
           dashboardGroupType={
              dashboardType === DASHBOARD_TYPE.BROKER ? DASHBOARD_SETTINGS_TYPE.BROKER_GROUP: DASHBOARD_SETTINGS_TYPE.CUSTOMER_GROUP
            }
           dashboardType={dashboardType}
           newDesign={newDesign}
          />
        )}
      </Container>
    </React.Fragment>
  )
})));