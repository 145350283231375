import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';

import GlobeMark from '../GlobeMark';
import { toShortGermanFormat } from '../../../../utils';
import styles from './styles';
import WarningTooltip from '../../../../../../components/WarningTooltip';

class EsgScore extends React.Component {

  constructor(props) {
    super(props);

    this.min = this.props.min || 0;
    this.max = this.props.max || 50;

    this.isNull = _.isNil(this.props.score);

  }

  calcScorePercent() {
    return ((this.props.score || 0) / this.max) * 100;
  }

  renderValue() {
    return this.isNull ? 'k.A.' : toShortGermanFormat(this.props.score, '', '', 2, false);
  }

  render() {
    const { classes, title } = this.props;

    return (
      <div className={classes.slider}>
        {!!title && (
          <h3 className={classes.smallTitle}>
            {title}
            {this.props.tooltip && (<>&nbsp;
                <WarningTooltip placement={'right'} title={this.props.tooltip}
                                iconClassName={classes.helpIcon} width={500}/>
              </>
            )}
          </h3>
        )}

        <div className={classes.markSpace}>
          {this.isNull && (
            <span className={clsx([classes.emptyValue])}>
              Nachhaltigkeitsdaten werden nur für solche Produkte und Bereiche angezeigt, bei denen mindestens 67% des
              verwalteten Vermögens vom ESG-Research von Sustainalytics abgedeckt wird. Sollte kein Wert bei Unternehmen
              oder Staaten angezeigt werden, befinden sich keine derartigen Vermögenswerte im Produkt.
            </span>
          )}
        </div>

        <div className={classes.sliderBg}>

          {!this.isNull && (
            <div className={classes.sliderProgress} style={{ width: this.calcScorePercent() + '%' }}>
              <span className={classes.sliderMark}>
                <GlobeMark/>
              </span>
              <span className={classes.sliderExplain}>
                {this.renderValue()}
                <br/>
                Fonds Score
              </span>
            </div>
          )}


        </div>
        <div className={classes.captions}>
          <span className={classes.leftCaption}>
            {this.min}
            <br/>
            Bester Score
            <br/>
            Niedriges ESG-Risiko
          </span>
          <span className={classes.rightCaption}>
            {this.max}
            <br/>
            Schlechtester Score
            <br/>
            Hohes ESG-Risiko
          </span>
        </div>

        {this.props.subline && (<div className={classes.subline}>
          <p className={classes.description} style={{ paddingRight: '90px' }}>
            {this.props.subline}
          </p>
        </div>)}

      </div>
    );
  }
}

export default withStyles(styles)(EsgScore);
