import { makeStyles } from '@material-ui/core';
import { INFO_BACKGROUND } from '../../../../../../themes/themeConfigurator';

export default makeStyles(theme => ({
  infoIcon: {
    color: theme.palette.primary.main,
  },
  dialogTitle: {
    backgroundColor: INFO_BACKGROUND
  }
}))