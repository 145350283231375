import React from "react";
import clsx from "clsx";


import styles from "./styles";
import withStyles from "@material-ui/core/styles/withStyles";
import DocumentDownloadLink from "../DocumentsTable/components/DocumentDownloadLink";
import {formatDocumentDate, formatDocumentSize} from "../../utils";


const DocumentsList = ({classes, documents}) => {

  const getDocType = (document) => {
    return [document.function, document.category].filter(item => item).join(', ');
  };

  return (
    <div className={classes.listContainer}>
      {documents.map((document) => {
        return (
          <div className={classes.listItem} key={document.id}>
            <div id="download-documents-tour-element">
              <span className={clsx(classes.bold, classes.name)}>
                <DocumentDownloadLink
                  name={document.description}
                  extension={document.extension}
                  id={document.id}
                  useName
                />
              </span> - {formatDocumentSize(document.size)}
            </div>
            <div>
              {getDocType(document)}
            </div>
            <div>
              <span className={classes.label}>Erstellt am:</span> {formatDocumentDate(document.generatedAt)}
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default withStyles(styles)(DocumentsList);