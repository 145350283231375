import React from 'react';

import {Grid, Tooltip} from "@material-ui/core";
import {getAgencyFilterOptions} from "../../constants";
import { Skeleton } from '@material-ui/lab';
import Button from "@material-ui/core/Button";
import useStyles from "./styles";
import SelectFormElement
  from "../../../../containers/RiskProfiling/components/StepContent/components/formElement/SelectFormElement";
import LettersFilterPanel from "../../../LettersFilterPanel";
import InputFormElement
  from "../../../../containers/RiskProfiling/components/StepContent/components/formElement/InputFormElement";
import {CLIENT_TYPES} from "../../../FilteringPanel/components/ListSelector/constants";



const CustomerFilteringPanel = (props) => {

  const classes = useStyles();

  const {
    handleSearchByLetter,
    handleClientTypeChanged,
    customerType,
    defaultSearchValue,
    handleSearchClicked,
    isChief,
    user,
    useGroupSearchTooltip,
    additionalFiltersRender,
    clientTypesOptions,
    useNameAndKundenNrTooltip,
    loading
  } = props;

  const [value, setValue] = React.useState('');
  const [options, setOptions] = React.useState(undefined);
  const [additionalFiltersValues, setAdditionalFiltersValues] = React.useState({})

  React.useEffect(() => {
    setValue(defaultSearchValue)
  }, [defaultSearchValue]);

  React.useEffect(() => {
    setOptions(getAgencyFilterOptions(isChief, user.sub_agencies, user.agency, undefined, clientTypesOptions))
  }, []);

  const onChangeHandler = (value) => {
    setValue(value);
  };

  const onKeyDownHandler = (event) => {
    if (event.key === 'Enter') {
      handleSearchClicked(value);
    }
  };

  const handleAdditionalFiltersChanged = (fieldName) => (value) => {
    setAdditionalFiltersValues((current) => ({
      ...current,
      [fieldName]: value
    }))
  }


  return (
    <Grid container className={classes.filteringContainer} id="reporting-customer-filter-tour-element">
      <Grid item xs={12} className={classes.filteringWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LettersFilterPanel
              handleLetterClick={(letter) => handleSearchByLetter(letter, additionalFiltersValues)}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.inputContainer} id="search-filter-tour-element">
              <InputFormElement
                placeholder={"Suchbegriff"}
                label={"Suchbegriff"}
                value={value}
                onChange={onChangeHandler}
                onKeyDown={onKeyDownHandler}
                inputProps={{
                  endAdornment: (
                    <Tooltip arrow placement={"top"} classes={{tooltip: classes.tooltip}} title={
                      <React.Fragment>
                        {useGroupSearchTooltip ? 
                          'Suchen Sie hier nach Mitglieder Ihrer Gruppen' 
                          :
                          <>
                            Geben Sie hier einen Suchbegriff ein.<br/>
                            {useNameAndKundenNrTooltip ? (
                              <>Es werden die Felder <i>Name</i> und <i>Kunden-Nr</i> durchsucht.</>
                            ) : (
                              <>Es werden die Felder <i>Name, Adresse, Plz, Ort</i> und <i>Kunden-Nr</i> durchsucht.</>
                            )}
                          </>
                        }
                      </React.Fragment>
                    }>
                      <i className='far fa-info-circle' />
                    </Tooltip>
                  )
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4} id={'agency-filter-tour-element'} className={classes.inputContainer}>
            {handleClientTypeChanged && (
              <>
                {options ? 
                  <SelectFormElement
                    label='Kunden von'
                    onChange={(event) => handleClientTypeChanged(event, additionalFiltersValues)}
                    value={customerType}
                    options={options}
                    onChangeGetEvent={true}
                    />
                  :
                  <Skeleton style={{width: '100%', marginTop: 5, marginBottom: -2}}/>
                }
              </>
            )}
          </Grid>
          {additionalFiltersRender && additionalFiltersRender({
            filters: additionalFiltersValues,
            onChange: handleAdditionalFiltersChanged,
            classes
          })}
          <Grid item xs={12} md={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSearchClicked(value, additionalFiltersValues)}
              className={classes.searchButton}
              disabled={loading}
            >
              <i className={"fa fa-search"} /> Suche starten
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};

CustomerFilteringPanel.defaultProps = {
  clientTypesOptions: CLIENT_TYPES,
  useNameAndKundenNrTooltip: false
}

export default CustomerFilteringPanel;