const styles = theme => ({
  navigation: {
    '& h2': {
      fontFamily: 'Roboto-Bold',
      fontSize: 24,
      color: '#202A38',
    },
    '& > div': {
      display: 'flex',
      margin: '15px 0',
    }
  },
  whiteBox: {
    background: 'transparent linear-gradient(180deg, transparent 0%, transparent 54px, #FFF 54px, #FFF 100%) 0% 0% no-repeat',
  },
  tabsContainer: {
    minHeight: 120, position: 'relative'
  },
  loadingCircle: {
    marginTop: 20
  },
  link: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  headline: {
    maxWidth: 1100
  },
  fullName: {
    marginRight: '1rem',
  },
  msRating: {
    display: 'inline-block'
  },
  subheadline: {
    fontWeight: 700,
    fontSize: 16
  },
  backwardArrow: {
    marginLeft: 42,
    '@media print': {
      display: 'none',
    },
    '&:before': {
      content: '',
      display: 'inline-block',
      width: '0.6em',
      height: '0.6em',
      border: `2px solid ${theme.palette.primary.main}`,
      borderBottom: 'none',
      borderRight: 'none',
      transform: 'rotate(-45deg)',
      marginRight: '0.3em'
    }
  },
  tabContent: {
    backgroundColor: 'white',
    minHeight: '100%',
    padding: '30px 0 60px 0',
  },
  tabTitle: {
    // backgroundColor: 'white',
    background: 'white linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 74%, #F5F6F7 100%) 0% 0% no-repeat',
    opacity: 1,
    fontSize: 14,
    padding: 15,
    fontFamily: 'Roboto-Bold',
    color: '#202A38',
    marginLeft: 1,
    marginRight: 2,
    "&:first-child": {
      marginLeft: 0
    },
    "&:last-child": {
      marginRight: 0
    },
    '& .Mui-selected': {
      background: 'white'
    },
    '&:focus': {
      outline: 'none'
    },
    '@media print': {
      display: 'none',
    },
  },
  selected: {
    background: 'white',
    color: theme.palette.primary.main,
    '@media print': {
      display: 'block',
      color: '#202A38',
    }
  },
  indicator: {
    display: 'none',
  },
  tabsHeader: {
    background: 'none',
    boxShadow: 'none'
  },
  loadingLine: {
    width: '30%',
    fontSize: 24,
    lineHeight: '1.2em',
    backgroundColor: '#d5d6d7',
    backgroundImage: 'linear-gradient(to right, #d5d6d7 0%, #b8bcbd 20%, #d5d6d7 40%, #d5d6d7 100%)',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: '$placeholderShimmer',
    animationTimingFunction: 'linear',

    '& p': {
      display: 'inline-block',
      margin: 0
    },

    '&:nth-child(2n)': {
      width: '75%'
    }
  },

  '@keyframes placeholderShimmer': {
    from: {
      backgroundPosition: '-468px 0',
    },
    to: {
      backgroundPosition: '468px 0',
    }
  },
  hideOnPrint: {
    '@media print': {
      display: 'none',
    }
  },
  notFundMsg: {
    color: '#202A38',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 50,
    textAlign: 'center'
  },
  iconGroup: {
    marginLeft: 'auto',
    width: 160,
    minWidth: 160,
    marginTop: 10,
    height: 54
  }
})

export default styles;