const styles = theme => ({
  dropdownMenuWrapper: {
    top: -5,
    height: 'auto',
    padding: 0,
    paddingRight: 'initial',
    backgroundColor: 'transparent',
    display: 'inline-block',
    position: 'relative',
    alignItems: 'center',
  },
  dropdownMenu: {
    borderRadius: 0,
    fontSize: '.8125rem',
    minWidth: '15rem',
    color: '#323C42',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    boxShadow: '0px 0px 6px #00000029',
    border: '1px solid #DFE1E3',
    overflow: 'visible',

    '&::before': {
      right: '1.0625rem',
      pointerEvents: 'none',
      content: '""',
      position: 'absolute',
      top: '-.375rem',
      overflow: 'hidden',
      width: '.75rem',
      height: '.75rem',
      backgroundImage: 'linear-gradient(135deg,#fff 50%,transparent 50%)',
      border: '1px solid rgba(0,0,0,.15)',
      borderRight: 'none',
      borderBottom: 'none',
      transform: 'rotate(45deg)'
    }
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    padding: '9px .9375rem',
    transition: 'background .2s cubic-bezier(.165,.84,.44,1)',
    width: '100%',
    clear: 'both',
    fontFamily: 'Roboto-Regular',
    color: '#323C42',
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    textDecoration: 'none',
    position: 'relative',
    fontSize: 15,
  },
  innerIcon: {
    fontSize: '2.2rem'
  }
})

export default styles;