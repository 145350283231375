import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  modalTitle: {
    paddingLeft: 16,
    paddingRight: 16,

    '& > h2': {
      fontFamily: 'Roboto-Regular',
      color: '#313D44',
      [theme.breakpoints.only('sm')]: {
        fontSize: 18,
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: 16,
      }
    }
  },
  container: {
    borderRadius: 2
  },
  content: {
    padding: '0 16px 16px 16px',
    marginTop: -5,
    [theme.breakpoints.only('xs')]: {
      padding: 8,
    }
  },
  actions: {
    padding: '0 16px 16px 16px',
    [theme.breakpoints.only('xs')]: {
      padding: 8,
    }
  },
  root: {
    zIndex: '1000 !important',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',

    '&  > div:first-child': {
      backgroundColor: 'transparent'
    }
  }
}));