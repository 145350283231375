import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',

    '& > svg': {
      marginRight: 16
    },

    '& > p': {
      fontFamily: 'Roboto-Regular',
      fontSize: 16,
      color: '#202A38',
      margin: 0
    }
  },
  dialogActionsRoot: {
    padding: '15px 32px 32px 32px'
  },
  closeButton: {
    float: 'right',
    position: 'relative',
    top: -20,
    right: -20
  }
})

export default makeStyles(styles)