import React from 'react';
import moment from 'moment';

import {KeyboardDatePicker} from '@material-ui/pickers';
import {DATE_MUST_BE_IN_PAST} from "../../containers/RiskProfiling/constants";

require('moment/locale/de');
moment.locale('de');

const KeyboardDatePickerComponent = (props) => {
  const {
    value,
    label,
    disabled,
    minDate,
    maxDate,
    errorMessage,
    onChange,
    onOpen,
    onClose,
    onError,
    inputProps,
    inputLabelProps,
    classes,
    maxError,
    minError,
    shouldDisableDate
  } = props;

  const [datePickerError, setDatePickerError] = React.useState(null);

  const getMinDateMessage = () => {
    return minDate ? 'Das Datum muss in der Zukunft liegen.' : 'Das Datum darf nicht vor dem 01.01.1900 liegen.';
  };

  const handleError = (error) => {
    setDatePickerError(error);
    onError && onError(!!error);
  };

  return (
    <KeyboardDatePicker
      format='DD.MM.YYYY'
      placeholder='TT.MM.JJJJ'
      label={label}
      value={value}
      disabled={disabled}
      minDate={minDate}
      maxDate={maxDate}
      error={!disabled && !!(datePickerError || errorMessage)}
      helperText={!disabled && (datePickerError || errorMessage)}
      onError={handleError}
      onChange={date => onChange && onChange(date)}
      onOpen={() => onOpen && onOpen()}
      onClose={() => onClose && onClose()}
      okLabel={<span style={{textTransform: 'none'}}>Anwenden</span>}
      cancelLabel={<span style={{color: '#B1B1B1', textTransform: 'none'}}>Abbrechen</span>}
      invalidDateMessage='Falsches Datumsformat.'
      maxDateMessage={maxError || DATE_MUST_BE_IN_PAST}
      minDateMessage={minError || getMinDateMessage()}
      shouldDisableDate={shouldDisableDate}
      InputProps={inputProps}
      InputLabelProps={inputLabelProps}
      classes={classes}
    />
  )
};

export default KeyboardDatePickerComponent;
