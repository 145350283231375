import React from 'react';


export default () => {
    return (
      <svg
      id="Group_7965"
      data-name="Group 7965"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_7654"
            data-name="Rectangle 7654"
            width="30"
            height="30"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Group_7947" data-name="Group 7947" clip-path="url(#clip-path)">
        <path
          id="Path_6719"
          data-name="Path 6719"
          d="M7.09,21.1v3.622H8.848V21.152h5.273v3.574h1.758V21.152h5.273v3.574H22.91V20.978A8.767,8.767,0,0,0,21.186,3.605h-.018A8.7,8.7,0,0,0,5.995,5.59,7.893,7.893,0,0,0,7.09,21.1m-.272-13.9.506-.1.164-.488a6.952,6.952,0,0,1,12.5-1.642l.278.421.5-.028a7.018,7.018,0,1,1,.385,14.02H7.969A6.142,6.142,0,0,1,6.818,7.207"
          fill="#fff"
        />
        <rect
          id="Rectangle_7650"
          data-name="Rectangle 7650"
          width="1.758"
          height="5.273"
          transform="translate(10.605 24.727)"
          fill="#fff"
        />
        <rect
          id="Rectangle_7651"
          data-name="Rectangle 7651"
          width="1.758"
          height="5.273"
          transform="translate(3.574 24.727)"
          fill="#fff"
        />
        <rect
          id="Rectangle_7652"
          data-name="Rectangle 7652"
          width="1.758"
          height="5.273"
          transform="translate(17.637 24.727)"
          fill="#fff"
        />
        <rect
          id="Rectangle_7653"
          data-name="Rectangle 7653"
          width="1.758"
          height="5.273"
          transform="translate(24.668 24.727)"
          fill="#fff"
        />
        <path
          id="Path_6720"
          data-name="Path 6720"
          d="M154.735,102.811l.808.346,1.441-3.374c.056,0,.111.006.168.006s.112,0,.168-.006l1.441,3.374.808-.346a6.147,6.147,0,0,0,3.735-5.659,13.658,13.658,0,0,0-.148-1.609l-3.643.439a2.6,2.6,0,0,0-.168-.291l2.2-2.934-.7-.527a6.154,6.154,0,0,0-7.384,0l-.7.527,2.2,2.934a2.6,2.6,0,0,0-.168.291l-3.643-.439A13.664,13.664,0,0,0,151,97.152a6.147,6.147,0,0,0,3.735,5.659m3.3-5.659a.879.879,0,1,1-.879-.879.88.88,0,0,1,.879.879m1.621,3.616-.716-1.676a2.637,2.637,0,0,0,.788-1.364l1.807-.218a4.39,4.39,0,0,1-1.88,3.258m-2.5-8.01a4.366,4.366,0,0,1,1.881.421l-1.093,1.458a2.637,2.637,0,0,0-1.577,0l-1.093-1.458a4.366,4.366,0,0,1,1.881-.421m-2.573,4.97a2.635,2.635,0,0,0,.788,1.364l-.716,1.677a4.391,4.391,0,0,1-1.88-3.258Z"
          transform="translate(-142.152 -85.668)"
          fill="#fff"
        />
      </g>
    </svg>
    )
}