export const GA_PROJECTS = {
  BCA: {
    url: 'vermoegen.diva.bca.de',
    gaID: 'GTM-TP8WZD78',
    appName: 'DIVA',
  },
  CARAT: {
    url: 'vermoegen.diva.carat-ag.de',
    gaID: 'GTM-TP8WZD78',
    appName: 'DIVA-CARAT',
  },
  BfV: {
    url: 'vermoegen.diva.bfv-ag.de',
    gaID: 'GTM-TP8WZD78',
    appName: 'DIVA-BfV',
  },
  BCA_VVS: {
    url: 'vermoegen.diva.bca-vvs.de',
    gaID: 'GTM-TP8WZD78',
    appName: 'DIVA-VVS',
  },
  FINCITE_ACP: {
    url: 'demovermoegen.demodiva.bca.de',
    gaID: 'GTM-K33VDND8',
    appName: 'DEMO',
  },
  FINCITE_TEST: {
    url: 'testvermoegen.testdiva.bca.de',
    gaID: 'GTM-PZ7CBN2H',
    appName: 'TEST',
  },
  FINCITE_DEV: {
    url: 'dev-bca.az.fincite.net',
    gaID: 'GTM-PZ7CBN2H',
    appName: 'DEV',
  },
  LOCAL: {
    url: 'localhost',
    gaID: 'GTM-PZ7CBN2H',
    appName: 'LOCAL',
  }
};


export const TIMING_NAMES = {
  DASHBOARD_PAGE_LOAD: 'dashboard_page_load',
  DATA_FETCH: 'data_fetch'
};
