import React from "react";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

const InfoCard = ({title, text}) => {
    const classes = useStyles();

    return (
        <>
            <Card className={classes.card}>
                <CardContent className={classes.container}>
                    <Typography className={classes.title}>
                        {title}
                    </Typography>
                    <Typography className={classes.text}>{text}</Typography>
                </CardContent>
            </Card>
        </>
    );
};

export default InfoCard;
