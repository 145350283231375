import _ from 'lodash';

export default (container_identifier, anchor_identifier, target_identifier, trigger_identifier) => {
  let $anchor = document.getElementById(anchor_identifier);
  let $container = document.getElementById(container_identifier);
  let $target = document.getElementById(target_identifier);
  let $trigger = document.getElementById(trigger_identifier);

  if (!_.isUndefined($container) && !_.isUndefined($target) && !_.isUndefined($trigger) && !_.isUndefined($anchor)) {

    let targetOffset = $trigger.offsetTop;

    let handler = (event) => {

        let currentScrollPosition = $container.scrollTop;
        let anchrorScrollPosition = undefined;
        if (!_.isNull($anchor)) {
          anchrorScrollPosition = $anchor.offsetTop;
        }


        if (currentScrollPosition - 60 > targetOffset && (_.isUndefined(anchrorScrollPosition) || currentScrollPosition < anchrorScrollPosition)) {
          $target.style['display'] = 'block';
          $trigger.style['opacity'] = '0';
          $trigger.style['pointer-events'] = 'none';
        } else {
          $target.style['display'] = 'none';
          $trigger.style['opacity'] = '1';
          $trigger.style['pointer-events'] = 'auto';
        }
      }

    // Recalculate target element position after window was resized

    window.addEventListener('resize', () => {
      targetOffset = $trigger.offsetTop;
      $container.removeEventListener('scroll', handler);
      $container.addEventListener('scroll', handler);
    })

    if (!_.isNull($anchor)) {
      $container.addEventListener('scroll', handler);
    }
  }
}