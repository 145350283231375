const styles = theme => ({
  root: {
    minHeight: 150,
    height: 490,
    width: '100%'
  },
  chart: {
    width: '100%'
  },
  container: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& > div': {
      width: '100%'
    }
  },
  subline: {
    marginTop: 'auto',
  },
  description: {
    margin: 0,
    fontSize: 12,
    color: '#8D8D8D',
  },
  infoIcon: {
    verticalAlign: 'middle'
  }
})

export default styles;