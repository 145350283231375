import React from "react";
import useStyles from "./styles";
import InfoCard from "./components/Card/InfoCard";


const Info = (props) => {
  const classes = useStyles();

  const { questions } = props;

  const data = questions[0].config.choices;

  return (
    <div className={classes.container}>
      <div className={classes.cards}>
        {data.map((item) => <InfoCard {...item} /> )}
      </div>
    </div>
  );
};



export default Info;
