import React from 'react';
import clsx from "clsx";
import {
  Link,
  withRouter
} from "react-router-dom";

import useStyles from "../../styles";
import useOnClickOutside from "../../../../utils/useOnClickOutside";
import connect from "react-redux/es/connect/connect";

const DropDownButton = props => {
  const classes = useStyles();

  const {
    openAction,
    closeAction,
    name,
    open,
    items,
    location: {pathname},
    dispatch,
    withoutDispatch,
    guide
  } = props;

  const node = React.useRef();

  useOnClickOutside(node, () => {
    if (guide.open || guide.active) {
      return ;
    }
    handleClose();
  });

  const isActive = (activePaths) => {
    return activePaths.some(path => pathname.includes(path))
  };

  const getButtonName = () => {
    let activeItem = items.find(item => isActive(item.activePaths));
    return activeItem ? activeItem.name : name;
  };

  const handleOpen = event => {
    event.persist();
    event.preventDefault();
    if (!open) {
      if (!withoutDispatch) {
        dispatch(openAction());
      } else {
        openAction();
      }
    }
  };

  const handleClose = () => {
    if (open) {
      if (!withoutDispatch) {
        dispatch(closeAction());
      } else {
        closeAction()
      }
    }
  };

  return (
    <button className={classes.dropdownButton} onClick={handleOpen}>
      <span className={classes.navLink}>
        {getButtonName()} <i className="chevron-icon fa fa-chevron-down"/>
      </span>
      {open && (
        <div ref={node} className={classes.dropdownMenu}>
          {items.map(item => (
            <Link
              key={item.key}
              to={item.path}
              onClick={handleClose}
              className={clsx(classes.dropdownItem, isActive(item.activePaths) && classes.activeLink)}
              id={item.id}
            >
              {item.name}
            </Link>
          ))}
        </div>
      )}
    </button>
  );
};

const mapStateToProps = (state) => ({
  guide: state.get('guide').toJS()
});

export default connect(mapStateToProps)(withRouter(DropDownButton));