import React, {useState} from 'react';

import {Grid} from '@material-ui/core'
import {
  hasResponseError,
  PortfolioUtils,
} from '../../../../../../../utils/utils';
import _ from 'lodash';
import BaseChart from '../../../../../../FactSheetsPage/components/BaseChart';
import useStyles from '../styles';

const RiskIndicatorChart = (props) => {
  const {
    dashboardData,
    instrumentsData,
    expandedItemKey,
    handleExpandedItemsChange,
    loading
  } = props;

  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  React.useEffect(() => {
    handleExpandedItemsChange && handleExpandedItemsChange(expandedItemKey, expanded)
  }, [expanded])
  const portfolioStructure = dashboardData && dashboardData.portfolio_structure;

  const dataError = _.isNil(portfolioStructure) ||  hasResponseError(portfolioStructure);

  const portfolios = React.useMemo(() => {
    return instrumentsData.filter((portfolio) => !portfolio.is_empty && !portfolio.not_connected)
  }, [instrumentsData])

  let data = [];
  if (portfolioStructure) {
    data = PortfolioUtils.getPortfolioRiskIndicatorsColored(portfolioStructure, portfolios)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <BaseChart
          chartType='bar'
          title={'Risikoklassenallokation'}
          subtitle={
            <><span>(Durchschnitt: { portfolioStructure && portfolioStructure['by_srri_average'] })</span></>
          }
          seriesData={data}
          loading={loading}
          error={dataError}
          classes={{
            title: classes.title
          }}
          expanded={expanded}
          onExpandedClick={() => setExpanded(!expanded)}
          useColorFromSeries
        />
      </Grid>
    </Grid>
  );
};

export default RiskIndicatorChart;