import React, {useRef} from "react";
import PreviewStepContent from '../../PreviewStepContent';
import Grid from '@material-ui/core/Grid';
import {useWindowSize} from "../../../../DocumentCenter/utils";

const PreviewScreen = props => {

  const {
    reportSettings,
  } = props;

  const [documentPageSize, setDocumentPageSize] = React.useState(undefined)
  const ref = useRef(null);
  const documentSize = useWindowSize(ref);
  React.useEffect(() => {
    setDocumentPageSize(ref.current.offsetWidth)
  }, [documentSize])

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} ref={ref}>
          <PreviewStepContent reportSettings={reportSettings} documentPageSize={documentPageSize}/>
        </Grid>
      </Grid>
    </>
  );
};

export default PreviewScreen;