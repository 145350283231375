import React from "react";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import {useStyles} from "../styles";
import clsx from "clsx";
import FormHelperText from "@material-ui/core/FormHelperText";
import WarningTooltip from "../../../../../../../components/WarningTooltip";

const RadioFormElement = props => {
  const {
    value,
    label,
    required,
    options,
    onChange,
    disabled,
    custom_classes,
    error,
  } = props;

  const classes = useStyles();

  React.useEffect(() => {
    if (!!value && !options.find(o => o.value == value)){
      onChange(''); // clean answer as it's not in the options
    }
  }, [value, options]);

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl
      component="fieldset"
      required={required}
      disabled={disabled}
      error={!!error}
      help_text={error}
      classes={{
        root: classes.textFieldRoot
      }}
    >
      {label && (
        <FormLabel
          component="legend"
          classes={{
            root: clsx(classes.labelRoot, custom_classes && custom_classes.labelRoot),
            asterisk: classes.labelAsterisk
          }}
        >
          {label}
        </FormLabel>
      )}
      <RadioGroup
        row
        aria-label={label}
        name={label}
        value={value}
        onChange={handleChange}
        classes={{
          root: clsx(classes.radioGroupRoot, custom_classes && custom_classes.radioGroupRoot)
        }}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.id}
            value={option.value}
            disabled={disabled || option.disabled || false}
            control={<Radio color="primary" disabled={option.disabled || false} classes={{root: custom_classes && custom_classes.radioOption}} />}
            label={
              <>
                {option.label}&nbsp;
                {option.info_text && (
                  <WarningTooltip title={option.info_text} />
                )}
              </>
            }
            labelPlacement="end"
            classes={{
              root: custom_classes && custom_classes.formControlLabelRoot,
              label: custom_classes && custom_classes.formControlLabelLabel
            }}
          />
        ))}
      </RadioGroup>
      { error && <FormHelperText style={{marginTop: -15}}>{ error }</FormHelperText>}
    </FormControl>
  );
};

export default RadioFormElement;