import React from "react";
import {getDocumentTypeByExtension} from "../../../../utils";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./styles"
import {downloadDocument} from "../../../../services";

const DocumentDownloadLink = (props) => {
  const {
    classes,
    name,
    extension,
    id,
    useName
  } = props;

  const handleDownload = async () => {
    const fileName = extension && name && `${name}.${extension}`
    await downloadDocument(id, fileName);
  };

  const renderName = () => {
    if (useName) {
      return name
    } else {
      return getDocumentTypeByExtension(extension);
    }
  }

  return (
    <span className={classes.downloadLink} onClick={handleDownload}>
      {renderName()}
    </span>
  )
};

export default withStyles(styles)(DocumentDownloadLink);