import { makeStyles } from '@material-ui/core';
import {
  autoCompleteStyles,
  FORM_FIELD_HEIGHT
} from '../../../TransactionsMonitoring/components/Filters/components/common_styles';

export const styles = theme => ({
  autocompleteInput: autoCompleteStyles,
  filtersRoot: {
    padding: '20px 30px'
  },
  bankName: {
    width: 'fit-content',
    overflowWrap: 'break-word',
    paddingRight: '0.7rem'
  },
  banksOption: {
    alignItems: 'center',
    display: 'flex',
    padding: 17,
  },
  availableDocumentsCount: {
    height: '30px',
    minWidth: '30px',
    textAlign: 'center',
    fontFamily: 'Roboto-Bold',
    color: '#202A38',
    borderRadius: '50%',
    backgroundColor: '#CCE9FA',
    padding: '1px 1px'
  },
  selectedDocumentsCount: {
    position: 'absolute',
    padding: '6px 9px',
    top: '2px',
    bottom: '2px',
    left: '2px',
    fontSize: '16px',
    lineHeight: '2.1',
    width: '75%',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  loadDocsButton: {
    height: FORM_FIELD_HEIGHT,
    color: 'white',
    borderRadius: '8px',
    fontSize: '16px',
    fontFamily: 'Roboto-Bold',
    textTransform: 'none',
    boxShadow: '3px 6px 10px #00000033',
  },
  selectButtonContainer: {
    minWidth: 'min-content'
  },
  loadDocsButtonText: {
    padding: '5px 8px',
    whiteSpace: 'nowrap',
  },
  documentsName: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > span': {
      width: '100%'
    },
  },
  documentsOption: {
    padding: 17,
    alignItems: 'flex-start',
  },
  documentCheckbox: {
    fontSize: '20px',
    width: '20px',
    height: '20px',
    marginRight: '15px',
    marginTop: '2px',
    borderRadius: '3px',
    opacity: 1,
  },
  documentsCountNumber: {
    verticalAlign: 'text-top'
  },
  textField: {
    '& input::placeholder': {
      color: 'rgba(0, 0, 0, 0.87)',
      opacity: 1,
    }
  },
});

export default makeStyles(styles);