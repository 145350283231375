const styles = (theme) => ({
  container: {},
  listHeader: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#626970',
    marginBottom: 16
  },
  withoutGroupCount: {
    paddingLeft: 8,
    fontFamily: 'Roboto-Regular',
  },
  addGroupSection: {
    margin: '15px 0',
    padding: '15px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 2,
    border: '3px dashed #ABB6BE',

    '& > p': {
      color: '#313D44',
      fontFamily: 'Roboto-Regular',
      fontSize: 22,
      textAlign: 'center',
      padding: 16,
      margin: 0,
      [theme.breakpoints.only('sm')]: {
        fontSize: 20,
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: 18,
      },
    }
  },
  filterHeader: {
    fontFamily: 'Roboto-Bold',
    fontSize: '1.3rem',
  },
  header: {
    fontFamily: 'Roboto-Regular',
    fontSize: 34,
    color: '#626970',

    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
    }
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 34
  }
});

export default styles;
