import React  from 'react'
import { connect } from 'react-redux'

import {
  Container
} from '@material-ui/core'

import { withStyles } from '@material-ui/core';
import DashboardTable from '../../../../components/DashboardTable';
import {FactSheetsHandlerResource, FavoriteListResouce} from '../../../../utils/api';
import { styles } from './styles';
import {favoriteListTableStructure} from './table-structure';
import { paginateArray } from '../../../../utils/utils';
import { executeIfPathExist, getInvestmentDynamicPath } from '../../../InvestmentPlatform/utils'
import {FilterBudges} from "../../../Modelportfolios/components";
import Button from "@material-ui/core/Button";
import SuccessDialog from "../../../../components/SuccessDialog";
import { modelPortfolioAccessible, getInstrName } from "../../../Modelportfolios/utils";
import BaseList from "../BaseList";
import {ALL_ID} from "../../../Modelportfolios/List/BaseList";

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
  auth: state.get('auth').toJS(),
});

const FAVORITE_LIST_PAGE_SIZE = 15;

class FavoriteList extends BaseList {
  constructor(props) {
    super(props);

    this.state = {
      favoriteLists: {
        data: null,
        loading: true,
        errors: null,
        date: Date.now()
      },
      investmentDynamicPath: getInvestmentDynamicPath(),
      selected: [],
      success: false,
    };

  }

  fetchFavoriteLists = async (params) => {
    this.setState({
      favoriteLists: {
        data: null,
        errors: null,
        loading: true,
        date: Date.now()
      }
    })

    try {

      let requestParams = {}
      if (params) {
        requestParams = {...params}
      }
      let response = await FavoriteListResouce.getFavoriteLists(requestParams);
      let responsePaginated = [...paginateArray(response, FAVORITE_LIST_PAGE_SIZE)]

      this.setState({
        favoriteLists: {
          data: responsePaginated,
          loading: false,
          errors: null,
          date: Date.now()
        },
      })
    } catch (errors) {
      this.setState({
        favoriteLists: {
          data: null,
          loading: false,
          errors,
          date: Date.now()
        }
      })
    }
  }

  getFavoriteListsDataSource = () => {
    if (this.state.favoriteLists.loading) {
      return [{}, {}, {}, {}, {}]
    }

    if (this.state.favoriteLists.data) {
      return this.state.favoriteLists.data || []
    }

    return []
  }

  handleAllSelected = (e) => {
      const selected = this.state.selected || [];
      let loaded = (this.state.favoriteLists.loading ? [] : this.state.favoriteLists.data[0]) || [];
      let loadedAllowed = [];
      let auth = this.props.auth;
      loaded.forEach(function(item) {
        if(modelPortfolioAccessible(item, auth)){
          loadedAllowed.push(item)
        }
      })
      if (selected.length === loadedAllowed.length) {
        loadedAllowed = []; // unselect all
      }
      this.setState({selected: loadedAllowed})
  };

  handleSingleSelect = (item) => {
    let selected = this.state.selected;
    const idx = this.state.selected.findIndex(i => i.id === item.id);
    if (idx !== -1) {
      selected.splice(idx, 1);
    } else {
      selected.push(item)
    }
    this.setState({selected: selected})
  };

  handleAdd = () => {
    FactSheetsHandlerResource.postToFavlistInstrument(
        this.props.item.isin, {lists: this.state.selected.map(i => i.id)}).then((data) => {
          this.setState({success: true})
    })
  }

  successText = () => {
    const { classes } = this.props;

    let lists = [];
    this.state.selected.forEach((item, index) => (
        lists.push(
            <li key={index}>{item.name}</li>
        )
    ))

    return (<div className={classes.successDialogInner}>
      <div>Das Instrument wurde zum folgende Favoritenlisten erfolgreich hinzugefügt.</div>
      <ul>
        {lists}
      </ul>
      <div>
        <Button onClick={this.handleCloseDialog} className={classes.addButton} color="primary">
          {'Zum Factsheet'}
        </Button>
      </div>
    </div>)
  }

  handleCloseDialog = () => {
    this.props.finish()
  }

  handleEditClick = (favoriteList) => {
    executeIfPathExist(this.props.investmentPlatform.routes, 'FAVORITE_LIST_OVERVIEW', path => {
      let pathUpdated = path.replace(':id', favoriteList.id)

      this.props.history.push(`/${this.state.investmentDynamicPath}${pathUpdated}`)
    })
  }

  handlePortfolioTypeChanged = async (portfolioTypes) => {
    portfolioTypes = portfolioTypes.map(i => i.id).filter(id => id !== ALL_ID);
    let params;
    if(portfolioTypes.length > 0) params = {types: portfolioTypes};
    await this.fetchFavoriteLists(params);
  };

  totalItemsCount = () => {
    if(this.state.favoriteLists.loading) return 0;
    if(!this.state.favoriteLists.data || !this.state.favoriteLists.data[0]) return 0;
    let count = 0;
    let auth = this.props.auth;
    this.state.favoriteLists.data[0].forEach(function(item) {
        if(modelPortfolioAccessible(item, auth)){
          count += 1
        }
    })
    return count
  }

  render() {
    const { classes } = this.props;

    const lists = this.getFavoriteListsDataSource();

    return (
      <div className={classes.container}>
        <Container>
          <div className={classes.navigationContainer} style={{ marginBottom: 20 }}>
            <h1 className={classes.header}>Instrument zu Favoritenlisten hinzufügen</h1>
          </div>
          <FilterBudges
            onChange={this.handlePortfolioTypeChanged}
            title="Favoritenlisten filtern"
            multiple={false}
            items={this.TABS}
            loading={this.state.favoriteLists.loading}
          />
          <DashboardTable
            structure={favoriteListTableStructure}
            dataSource={lists}
            expanded
            tableClasses={classes}
            withFooter={false}
            paginationOptions={{
              paginationEnabled: true,
              pageSize: FAVORITE_LIST_PAGE_SIZE,
            }}
            options={{
              loading: this.state.favoriteLists.loading,
              onAllSelect: this.handleAllSelected,
              selected: this.state.selected,
              totalInstrumentsCount: this.totalItemsCount(),
              onInstrumentSelect: this.handleSingleSelect,
              onEdit: this.handleEditClick,
              auth: this.props.auth,
            }}
          />
          {!!this.state.selected.length && (
            <div className={classes.bottomModal}>
              <span className={classes.itemName}>INSTRUMENT: {getInstrName(this.props.item)}</span>
              <Button onClick={this.handleAdd} className={classes.addButton} color="primary">
                {'Zum Favoritenlisten('+this.state.selected.length+') hinzufügen'}
              </Button>
            </div>
          )}
        </Container>
        <SuccessDialog
          open={!!this.state.success}
          message={this.successText()}
          onClose={this.handleCloseDialog}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(FavoriteList));
