import {makeStyles} from '@material-ui/core';

export default makeStyles((theme) => ({
  header: {
    fontFamily: 'Roboto-Regular',
    fontSize: 34,
    color: '#626970',
    marginRight: 50,
    marginBottom: 20,
    
    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
    }
  }
}));