import { fromJS } from 'immutable';

import {
  ADD_COMBINED_TRADING_OPTION,
  REMOVE_COMBINED_TRADING_OPTION,
  UPDATE_PORTFOLIO_COMBINED_TRADING_OPTION,
  ADD_PORTFOLIO_COMBINED_TRADING_OPTION,
  REMOVE_PORTFOLIO_COMBINED_TRADING_OPTION,
  REMOVE_ALL_PORTFOLIOS_COMBINED_TRADING_OPTION
} from './constants';
import {
  addInstrumentToState,
  removeInstrumentFromState,
  addPortfolioToState,
  removePortfolioFromState,
  updatePortfolioInState,
  removeAllPortfoliosFromState,
} from './utils';

const initialState = fromJS({
  customers: [],
});

export function appReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_COMBINED_TRADING_OPTION:
      return state.set('customers', fromJS(addInstrumentToState(state, action.customer, action.portfolio, action.instrument, action.tradingType, action.savingPlansAction)));
    case REMOVE_COMBINED_TRADING_OPTION:
      return state.set('customers', fromJS(removeInstrumentFromState(state, action.customerId, action.portfolioId, action.instrumentId)))
    case UPDATE_PORTFOLIO_COMBINED_TRADING_OPTION:
      return state.set('customers', fromJS(updatePortfolioInState(state, action.customerId, action.portfolioId, action.tradingData, action.portfolioData)))
    case ADD_PORTFOLIO_COMBINED_TRADING_OPTION:
      return state.set('customers', fromJS(addPortfolioToState(state, action.customer, action.portfolio, action.tradingType, action.savingPlansAction, false, action.updateCustomerTradingType)));
    case REMOVE_PORTFOLIO_COMBINED_TRADING_OPTION:
      return state.set('customers', fromJS(removePortfolioFromState(state, action.customerId, action.portfolioId)))
    case REMOVE_ALL_PORTFOLIOS_COMBINED_TRADING_OPTION:
      return state.set('customers', fromJS(removeAllPortfoliosFromState(state, action.customerId,)));
    default:
      return state;
  }
}

export default appReducer;
