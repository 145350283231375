import {buildGuideTourStep} from "../../../components/GuideTour/utils";
import {getNavigationChapterSteps} from "./navigation_chapter";
import {getReportSettingsChapterSteps} from "./report_settings_chapter";

const TOUR_STORAGE_KEY = 'reportSettingsGuideTour';
const NAVIGATION_CHAPTER_KEY = 'navigation';
const REPORT_SETTINGS_CHAPTER_KEY = 'settings';

export const getReportSettingsGuideSteps = (width, history) => {
  return [
    buildGuideTourStep(1, 'Navigationsmenü',
      getNavigationChapterSteps(width), TOUR_STORAGE_KEY, NAVIGATION_CHAPTER_KEY),
    buildGuideTourStep(2, 'Erstellen von Kundengruppen zum Multireportversand',
      getReportSettingsChapterSteps(history), TOUR_STORAGE_KEY, REPORT_SETTINGS_CHAPTER_KEY)
  ];
};