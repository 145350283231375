import { makeStyles } from '@material-ui/core';
import {fontSizeTable, smFontSizeTable} from "../../../../utils/constants";


export const getCommonProductComparisonTableStyles = (theme) => ({
  helpIcon: {
    fontSize: '1.05rem',
    verticalAlign: 'middle',
    color: '#80858C'
  },
  highlightIcon: {
    color: theme.palette.primary.main,
    marginRight: 5,
    display: 'block'
  },
  table: {
    borderCollapse: 'separate'
  },
  tableRow: {
    '&.highlighted': {
      '& $tableCell': {
        fontFamily: 'Roboto-Bold',
        backgroundColor: `#F0F7FD !important`,
        '&:first-child': {
          '&>div': {
            width: 'calc(100% - 20px)'
          }
        }
      }
    }
  },
  tableHead: {
    '& $tableCell': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      background: 'rgb(249, 249, 249) !important',
      fontSize: fontSizeTable,
      color: '#202A38',
      fontFamily: 'Roboto-Regular',
      fontWeight: 'normal',
      verticalAlign: 'middle !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: smFontSizeTable,
      },

      '&:first-child': {
        borderTop: 'none',
      }
    }
  },
})

export const getCommonProductComparisonTableFirstColumnStyles = (theme) => ({
  '&:first-child': {
    width: 300,
    maxWidth: 300,
    minWidth: 300,
    position: 'sticky',
    left: 0,
    background: 'white',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      width: 140,
      maxWidth: 140,
      minWidth: 140,
      fontSize: smFontSizeTable,
    },
    [theme.breakpoints.down(600)]: {
      position: 'relative',
    }
  },
})

export default makeStyles((theme) => ({
  ...getCommonProductComparisonTableStyles(theme),
  tableCell: {
    verticalAlign: 'middle',
    textAlign: 'center',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    maxWidth: 125,
    width: 85,
    padding: '8px 2px',
    '&:nth-child(2)': {
      width: 125
    },
    '&:nth-child(3)': {
      width: 125
    },
    '&:nth-child(4)': {
      width: 121
    },
    '&:nth-child(5)': {
      width: 121
    },
    '&:nth-child(6)': {
      width: 97
    },
    '&:nth-child(7)': {
      width: 105
    },
    '&:nth-child(8)': {
      width: 100
    },
    '&:nth-child(9)': {
      width: 86
    },
    '&:nth-child(10)': {
      width: 65
    },
    '&:nth-child(11)': {
      width: 90
    },
    '&:nth-child(12)': {
      width: 65
    },
    '&:nth-child(13)': {
      width: 70
    },
    '&:nth-child(14)': {
      width: 70
    },
    ...getCommonProductComparisonTableFirstColumnStyles(theme),
  }
}))