import React from 'react';


export default ({color, size, onClick}) => {

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size || 15} height={size || 15} viewBox="0 0 15 15" onClick={handleClick}>
      <path fill={color || '#202a38'} d="M7.5,8.864,1.363,15,0,13.636,6.136,7.5,0,1.363,1.363,0,7.5,6.136,13.636,0,15,1.363,8.864,7.5,15,13.636,13.636,15Z"/></svg>
  )
}