import React from 'react';
import connect from "react-redux/es/connect/connect";
import _ from 'lodash';

import {Grid} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { MuiThemeProvider } from '@material-ui/core/styles';
import {createDefaultTheme} from '../../../../themes/themeConfigurator';

import styles from './styles';

import CoverPageDocument from "./components/CoverPageDocument";


const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1492,
    xl: 1920
  }
}
const theme = createDefaultTheme({'breakpoints': breakpoints})


const mapStateToProps = (state) => ({
  customer: state.get('reporting').toJS().customer,
});

const PreviewStepContent = (props) => {
  const {
    classes,
    reportSettings,
    customer,
  } = props;

  const [coverMsgEnabled, setCoverMsgEnabled] =  React.useState(false)

  React.useEffect(() => {
    let enabled = false;

    if (!_.isEmpty(reportSettings)) {
      enabled = !!(_.get(reportSettings, 'cover_message_enabled') && _.get(reportSettings, 'cover_message_content'));
    }
    setCoverMsgEnabled(enabled)
  }, [reportSettings]);

  return (
    <MuiThemeProvider theme={theme}>
      <Grid className={classes.container}>
        <Grid container className={classes.gridContainer} spacing={2}>
          { coverMsgEnabled &&
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id="reporting-cover-page-preview-tour-element">
              <div className={classes.itemContainer}>
                <CoverPageDocument reportSettings={reportSettings} customer={customer} pageWidth={props.documentPageSize}/>
              </div>
            </Grid>
          }
        </Grid>
      </Grid>
    </MuiThemeProvider>
  )
};

export default withStyles(styles)(
  connect(mapStateToProps)(PreviewStepContent)
);