import React from 'react';


export default ({color, onClick, size}) => {
  const _size = size || 15
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={_size} height={_size} viewBox="0 0 15 15">
      <path fill={color || '#0092e5'} d="M-1005,753.667c0,.734-.51,1.333-1.583,1.333h-12.083a1.334,1.334,0,0,1-1.333-1.333v-4.833a1.337,1.337,0,0,1,1.417-1.333h.839a.745.745,0,0,1,.745.745v.01a.745.745,0,0,1-.745.745h-.01a.745.745,0,0,0-.745.745v3.01a.745.745,0,0,0,.745.745h10.51a.745.745,0,0,0,.745-.745v-3.01a.745.745,0,0,0-.745-.745h-.01a.745.745,0,0,1-.745-.745v-.01a.745.745,0,0,1,.745-.745h.922a1.334,1.334,0,0,1,1.333,1.333v4.833Zm-4.294-8.662,0,0a.746.746,0,0,0-1.056-.038l-.766.718a.375.375,0,0,1-.631-.274V740.75a.75.75,0,0,0-.75-.75h0a.75.75,0,0,0-.75.75V745.4a.375.375,0,0,1-.634.271l-.731-.7a.744.744,0,0,0-1.057.03h0a.744.744,0,0,0,.032,1.051l2.136,2.017a1.5,1.5,0,0,0,2.065-.005l2.112-2.014A.746.746,0,0,0-1009.294,745Z" transform="translate(1020 -740)"/>
    </svg>
  )
}