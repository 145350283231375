import { makeStyles, createStyles } from '@material-ui/core'
import {smFontSize, xsFontSize} from "../../../../utils/constants";

export const styles = theme => createStyles({
  buttonRoot: {
    borderRadius: 2,
    height: 40,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: 35,
    }
  },
  btnContainer: {
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
    }
  },
  titleControl: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  selectSection: {
    display: 'flex',
    alignItems: 'center',

    '& > span': {
      fontFamily: 'Roboto-Bold',
      fontSize: 14,
      color: '#AFAFAF',
    }
  },
  selectFormControl: {
    minWidth: 140,
    marginLeft: 8,

    [theme.breakpoints.down('xs')]: {
      flex: 1,
    }
  },
  selectRoot: {
    borderRadius: 0,
    padding: 11,

    [theme.breakpoints.down('sm')]: {
      padding: 8,
    }
  },
  selectSelectMenu: {
    backgroundColor: 'red'
  },
  selectMenuPaper: {
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    border: 'none'
  },
  selectMenuItemRoot: {
    color: 'initial!important',
    fontFamily: 'Roboto-Regular!important',
    [theme.breakpoints.only('sm')]: {
      fontSize: smFontSize,
      minHeight: '2rem'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: xsFontSize,
      minHeight: '2rem'
    },
  }
})

export default makeStyles(styles)