import React from 'react'
import { Grid, withStyles } from "@material-ui/core";
import styles from './styles'
import SDGCard from '../card/index'

class SDGCards extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { classes, items, data, assetName } = this.props;

        return (
            <Grid
                container
                direction="column"
                justifyContent="center"
                spacing={2}
                className={classes.container}
                >
                <Grid item xs={12} className={classes.item}><SDGCard items={items} data={data} assetName={assetName}/></Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(SDGCards)