import PrimaryButton from "../PrimaryButton";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import clsx from 'clsx'

class NewVirtualPortfolioButton extends React.Component{
  render() {
    const {
      classes,
      disabled,
      clickHandler,
      useAsLink
    } = this.props

    return useAsLink ? (
        <div className={clsx(classes.link, disabled && classes.disabled)} onClick={() => clickHandler()}>
          <span className={classes.icon}><AddIcon/></span><span className={classes.text}>Neues virtuelles Depot erstellen</span>
        </div>
      ) : (
        <PrimaryButton
          text={'Neues virtuelles Depot erstellen'}
          disableShadow={true}
          onButtonClick={clickHandler}
          disabled={disabled}
          icon={<AddIcon/>}
        />
      )
  }
}


export default withStyles(styles)(NewVirtualPortfolioButton)