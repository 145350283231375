import React from 'react';
import connect from "react-redux/lib/connect/connect";
import {SHARED_SETTINGS_KEYS} from "../../../../../../components/SharedSettingsProvider/constants";
import {getSharedSettingsSelector} from "../../../../../../components/DashboardDataProvider/DashboardDataProvider";

const DashboardFooterMessage = props => {
  const {
    sharedSettings
  } = props;

  const message = sharedSettings && sharedSettings[SHARED_SETTINGS_KEYS.DASHBOARD_FOOTER_TEXT]

  if (!message) {
    return null;
  }

  return (<div
    dangerouslySetInnerHTML={{ __html: message }}
  />)
}

const mapStateToProps = (state) => ({
  sharedSettings: getSharedSettingsSelector(state),
});

export default connect(mapStateToProps)(DashboardFooterMessage);