import React from "react";
import _ from "lodash";

import PaymentPlansTable from "../../../../../../../../components/Charts/PaymentPlansTable";
import DashboardCard from "../../../DashboardCard";
import {Select, MenuItem, FormControl} from '@material-ui/core'
import useStyles from "../../../InstrumentList/components/InstrumentListItem/styles";
import {SAVING_PLANS_ACTIONS} from "../../../../../../../../components/Charts/InstrumentsAllocationTable/constants";
import {prepareComponentsWithContractNumber} from "../../../../../../utils"

export default (props) => {
  const {
    data,
    expanded,
    onExpanded,
    isLast,
    tradingOptions,
    paymentPlanType,
    skipMPGrouping
  } = props;

  const classes = useStyles();

  const getContent = () => {
    return (
      <div style={{width: '100%', overflowX: 'auto'}}>
        <PaymentPlansTable data={getPaymentPlansData()} total={data.total_active} expanded={expanded} tradingOptions={tradingOptions} paymentPlanType={paymentPlanType}/>
      </div>
    )
  };

  const getPaymentPlansData = () => {
    if (!data){
      return []
    }

    if (!data.is_model_portfolio || skipMPGrouping) {
      return _.flatten(data.components.map((component) => {
        const isHeader = !_.isEmpty(component.components)
        return [{
          ...component,
          isHeader,
          tableProps: {
            isHeader // Should be handled inside DashboardTable as header
          }}, ...(component.components || []).map((subComponent) => ({...subComponent, 'isSubComponent': true}))]
      }))
    }

    const result = prepareComponentsWithContractNumber(data, true)

    return _.flatten(_.flatten(
      Object.values(result).map((mpSavingPlan) => [mpSavingPlan, mpSavingPlan.components])))
  }

  const renderSideNavigationContent = () => {
    if (!(tradingOptions && tradingOptions.onAddPortfolioSavingPlanOption)) {
      return null;
    }

    let value = tradingOptions.tradeAction || "default";

    return (
      <div className={classes.selectSection}>
        <span>Plan ändern</span>
        <FormControl className={classes.selectFormControl}>
          <Select variant="outlined" classes={{
            root: classes.selectRoot,
          }}
          onChange={tradingOptions.onAddPortfolioSavingPlanOption}
          value={value}
          MenuProps={{
              classes: {
                paper: classes.selectMenuPaper
              }
            }}
          >
            <MenuItem value="default"  classes={{
              root: classes.selectMenuItemRoot
            }}>-</MenuItem>
            { SAVING_PLANS_ACTIONS.map(option => (
              <MenuItem value={option.value} classes={{
                root: classes.selectMenuItemRoot
              }}> { option.text } </MenuItem>
            )) }
          </Select>
        </FormControl>
      </div>
    )
  };

  return (
    <DashboardCard
      title={data.name}
      expanded={expanded}
      onExpanded={onExpanded}
      content={getContent()}
      expandable
      table
      divider
      sideNavigationContent={renderSideNavigationContent()}
      isLast={isLast}
    />
  );

};

