import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  assetItem: {
    padding: '14px 0',
    margin: '0 80px',
    borderBottom: '2px solid #E1E3E9',
    '&:first-child': {
      borderTop: '2px solid #E1E3E9'
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 40px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    }
  }
});

export default makeStyles(styles);