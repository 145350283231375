import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    background: 'white',
    listStyle: 'none',
    alignItems: 'center',
    padding: '5px 20px',
    height: 50,
    borderRadius: 2,
    [theme.breakpoints.down(290)]: {
      width: '100%',
      '& > li > a': {
        fontSize: 12
      }
    }
  },
  page: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    padding: '10px 15px',
    color: '#B1B1B1',
    margin: '0px 5px',
    boxSizing: 'border-box',
    outline: 'none',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#EFEFF2',
      borderRadius: 2
    }
  },
  nextButton: {
    marginLeft: 20,
    color: '#B1B1B1',
    outline: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  previousButton: {
    marginRight: 20,
    color: '#B1B1B1',
    outline: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  activeButton: {
    backgroundColor: `${theme.palette.primary.main}!important`,
    color: 'white',
    borderRadius: 2,
    outline: 'none'
  }
}))