import React from "react";
import clsx from "clsx";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";

import Grid from "@material-ui/core/Grid/Grid";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";

import UserInfo from "../../../../../../components/ProfileDetails/components/UserInfo";
import LastSendCell from "../../../LastSendCell";

import useStyles from "./styles";
import useListStyles from "../../../../../../components/CustomerList/styles";
import {PrimaryButton} from "../../../../../../components/Buttons";
import {setReportingCustomer, setReportingCustomerInGroup, setReportingGroup} from "../../../../actions";
import {ROUTES} from "../../../../../../routes";
import { executeIfPathExist, getInvestmentDynamicPath } from '../../../../../InvestmentPlatform/utils';
import {renderPortfolioDropdownName} from '../../../../../../utils/utils'
import _ from "lodash";
import AutoLastSendCell from "../../../../../GroupReporting/components/AutoLastSendCell";
import {AGGREGATED_PORTFOLIO_NAME} from '../../../../../../utils/aggregated-portfolio'

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS()
});

const CustomerListItem = (props) => {
  const classes = useStyles();
  const listClasses = useListStyles();

  const {
    customer,
    dispatch,
    history,
    loading,
    parentComponent='SERIES_REPORTING',
  } = props;

  const [dynamicReportSettingsTabLink, setDynamicReportSettingsTabLink] = React.useState(null)
  const [dynamicAutoReportSettingsTabLink, setDynamicAutoReportSettingsTabLink] = React.useState(null)

  React.useEffect(() => {

    if (props.investmentPlatform.routes) {
      executeIfPathExist(props.investmentPlatform.routes, parentComponent, (path) => {

        let newPath = '/' + getInvestmentDynamicPath()
        let url = newPath + path;

        setDynamicReportSettingsTabLink(url + ROUTES.BROKER.SERIES_REPORTING.children.SINGLE_CUSTOMER.children.REPORT.newDesignPath)
        setDynamicAutoReportSettingsTabLink(url + ROUTES.BROKER.SERIES_REPORTING.children.SINGLE_CUSTOMER.children.AUTO_REPORT.newDesignPath)
      })
    }

  }, [props.investmentPlatform.routes])

  const handleClickReportButton = () => {
    // todo check wich dispatches are actually used in new serien reporting
    dispatch(setReportingCustomer(customer));
    dispatch(setReportingGroup(null));
    return history.push(dynamicReportSettingsTabLink.replace(':customer_id', customer.customer_id))
  };

  const handleClickAutoReportButton = () => {
    dispatch(setReportingCustomer(customer));
    dispatch(setReportingGroup(null));
    return history.push(dynamicAutoReportSettingsTabLink.replace(':customer_id', customer.customer_id))
  }

  const renderSelectedDepot = (setting) => {
    let nameToRender = '';

    // if settings were set, portfolios were selected and report was generated - render selected portfolios amount like dashboard portfolio dropdown name
    if(setting && setting.id && !_.isEmpty(setting.last_report) && _.isArray(setting.portfolio_ids)) {
      nameToRender = setting.portfolio_ids.some(p_id => p_id == 0)
        ? AGGREGATED_PORTFOLIO_NAME
        : renderPortfolioDropdownName(setting.portfolio_ids)
    }

    return nameToRender
  }

  return (
    <Grid item className={listClasses.listItemContainer}>
      <Card className={listClasses.listItemCard}>
        <CardContent className={listClasses.cardContent}>
          <Grid container>
            {/* Customer info */}
            <Grid
              item
              md={4} sm={6} xs={12}
              className={classes.cardItem}
              id="reporting-client-info-tour-element"
            >
              <div className={clsx(loading && 'loading-line')}>
                <UserInfo
                  customer={customer}
                  // group
                />
              </div>
            </Grid>
            {/* Go to auto report settings button */}
            <Grid
              item
              md={3} sm={6} xs={12}
              className={classes.cardItem}
            >
              <div className={clsx(loading && 'loading-line')}>
                <AutoLastSendCell
                  classes={classes}
                  next_generation_date={_.get(customer, 'auto_report_settings.next_generation_date')}
                  periodicity={_.get(customer, 'auto_report_settings.periodicity')}
                  handleClickAutoReportButton={handleClickAutoReportButton}
                />
                <>
                  <div className={classes.autoReportWarning}>{renderSelectedDepot(customer.auto_report_settings)}</div>
                </>
              </div>
            </Grid>

            {/* Last send report */}
            <Grid
              item
              md={2} sm={6} xs={12}
              className={classes.cardItem}
              id='reporting-client-report-time-tour-element'
            >
              <div className={clsx(loading && 'loading-line')}>
                <LastSendCell
                  date={_.get(customer, 'report_settings.last_report.creation_time')}
                  type={_.get(customer, 'report_settings.last_report.report_type')}
                />
                <>
                  <div>{renderSelectedDepot(customer.report_settings)}</div>
                </>
              </div>
            </Grid>
            {/* Go to report settings button */}
            <Grid
              item
              md={3} sm={6} xs={12}
              style={{padding: 15}}
            >
              <div className={clsx(loading && 'loading-line')}>
                <div>
                  <PrimaryButton
                    id="reporting-customer-open-editor-btn-tour-element"
                    text='Einzelkundenreport einmalig senden'
                    onButtonClick={handleClickReportButton}
                    classes={classes}
                    style={{minHeight: 100, width: '100%'}}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>

  );
};

export default connect(mapStateToProps)(withRouter(CustomerListItem));