import React from "react";
import Rating from '@material-ui/lab/Rating';
import clsx from "clsx";
import { withStyles } from "@material-ui/core";
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';

import styles from "./styles";


function MsRating({ value, classes }) {
  const isNull = value === null;
  value = isNull ? 5 : value;

  return (
    <>
      <Rating
        readOnly
        value={value}
        icon={<StarRoundedIcon style={{ fill: isNull ? '#d9dde0' : '#202A38' }} />}
        emptyIcon={<StarBorderRoundedIcon style={{ fill: '#202A38' }} />}
        classes={{ root: classes.ratingWrapper }}
      />
      <span className={clsx([classes.label, isNull && classes.labelPlaceholder])}>
        &nbsp;-&nbsp;&nbsp;
        {
          isNull
            ? 'k.A.'
            : 'Morningstar Rating'
        }
      </span>
    </>
  );
}

export default withStyles(styles)(MsRating);