import React from 'react'
import {useStyles} from '../../../../VirtualPortfolioPage/Tabs/RiskAnalysis/items/CorrelationMatrix/styles.js';
import _ from 'lodash';

import {buildProductIdUniq, getProductName} from "../../../utils";
import { getInstrName } from '../../../../Modelportfolios/utils';
import CorrelationTableLegend
  from '../../../../../components/ChartsV2/CorrelationTable/components/CorrelationTableLegend';
import CorrelationTable from '../../../../../components/ChartsV2/CorrelationTable';

function CorrelationMatrix({products, data}) {

  const classes = useStyles();

  if (_.isEmpty(data)) {
    return (
      <div className={classes.container}>
        Keine Daten verfügbar.
      </div>
    )
  }

  let mapping = {};

  products.forEach((product) => {
    const name = getInstrName(product.data) || getProductName(product);
    const product_id_unique = buildProductIdUniq(product.product_id, product.type);

    mapping[product_id_unique] = {
      name: name || product.product_id,
      highlighted: product.configuration.highlighted,
    };
  });

  return (
    <div>
      <div style={{float: 'right', marginBottom: '1rem'}}>
        <CorrelationTableLegend />
      </div>
      <CorrelationTable correlationData={data} productsData={mapping} />
    </div>
  )
}

export default CorrelationMatrix;