import {useStyles} from '../PortfolioTrade/styles'
import _ from "lodash";
import moment from "moment/moment";
import {TRANSACTION_INSTR, TRANSACTION_TYPE_VALUES} from "../../../../constants";
import React from "react";
import {
  calculatePortfolioInstrumentsAverageSrri,
  instrCustodianData,
  setInstrumentBankData,
  validatePortfolio
} from '../../../../utils';
import {instrumentToStateFormat} from "../../../../../../components/TradingStore/utils";
import {extendPaymentPlanWithInstrumentData} from "../../../../../CustomerDashboard/utils";

const paymentPlanToTransactionData = (paymentPlan, action) => ({
  transaction_value: paymentPlan.rate && Math.abs(parseFloat(paymentPlan.rate)),
  rotation: paymentPlan.shifts_value,
  from_date: paymentPlan.start_date && moment(paymentPlan.start_date, 'YYYY-MM-DD').toDate(),
  till_date: action !== 'delete' && paymentPlan.end_date && moment(paymentPlan.end_date, 'YYYY-MM-DD').toDate(),
  transaction_type: TRANSACTION_INSTR.amount,
  discount: paymentPlan.discount
});

function PaymentPlanTrade(props) {

  const {
    portfolio,
    handlePortfolioChange,
    paymentPlanTransactionKey,
    defaultExpanded,
  } = props;

  const classes = useStyles()

  const handlePaymentPlanTillDate = (paymentPlan) => {

    const tillDateEnabledKey = {
      [TRANSACTION_TYPE_VALUES.SAVINGS_PLAN]: 'ordering_savings_plan_till_date_enabled',
      [TRANSACTION_TYPE_VALUES.PAYOUT_PLAN]: 'ordering_payout_plan_till_date_enabled',
      [TRANSACTION_TYPE_VALUES.SWITCH_PLAN]: 'ordering_switch_plan_till_date_enabled'
    }[paymentPlanTransactionKey]

    if (!portfolio[tillDateEnabledKey]) {
      paymentPlan.till_date = undefined
    }

  }

  const groupedTransactions = {'edit': [], 'delete': [], 'create': []};
  const transactions = portfolio.transactions[paymentPlanTransactionKey] || [];

  transactions.map(instr => {
    // set default values from instrument data
    const savingsPlanData = instr.data || {};
    _.defaults(instr, paymentPlanToTransactionData(savingsPlanData, instr.action));
    handlePaymentPlanTillDate(instr)
    if (paymentPlanTransactionKey == 'switch_plan') {
      _.defaults(instr, {
        buy: (savingsPlanData.components || []).map((instrument) => {
          let instrumentCopy = _.cloneDeep(instrument)
          if (!instrumentCopy.instrumentId) {
            instrumentCopy = instrumentToStateFormat(instrumentCopy, undefined, {action: savingsPlanData.action}, 'payment_id')
          }
          _.defaults(instrumentCopy, paymentPlanToTransactionData(instrumentCopy.data || {}, instr.action));
          handlePaymentPlanTillDate(instrumentCopy)
          setInstrumentBankData(instrumentCopy, instrCustodianData(instrumentCopy.data, portfolio.companyId), true);
          return instrumentCopy
        })
      })
    }
    groupedTransactions[instr.action].push(instr);
  });
  paymentPlanTransactionKey == 'switch_plan' && validatePortfolio(portfolio, ['switch_plan']);

  const [expanded, setExpanded] = React.useState([
    'portfolio', ...[...Object.keys(groupedTransactions)
      .filter(k => !_.isEmpty(groupedTransactions[k])).map(key => `transaction-${key}`),
      ...(defaultExpanded || [])]
  ]);

  const handleExpandedChange = (panel) => (event, isExpanded) => {
    setExpanded(prevState => {
      return isExpanded ? [...prevState, panel] : prevState.filter(i => i !== panel);
    });
  };

  React.useEffect(() => {
    if(portfolio.errors){
      let expandedDefaults = ['portfolio', ...Object.keys(portfolio.errors).map(key => `transaction-${key}`)];

      setExpanded(prevState => {
        return [...prevState, ...expandedDefaults]
      })
    }
  }, [portfolio.errors]);

  /**
   * Average SRRI value of portfolio according to existing instruments and new transactions
   * @type {unknown}
   */
  const averageSrriValue = React.useMemo(() => {
    return !props.isVirtual && calculatePortfolioInstrumentsAverageSrri(portfolio, portfolio.data.hasOwnProperty('base_components') ? 'base_components' : 'components', false)
  }, [JSON.stringify(portfolio.transactions)])


  const onInstrumentsChange = (newInstruments) => {
    portfolio.transactions[paymentPlanTransactionKey] = newInstruments;
    portfolio.errors = null; // clean portfolio error

    handlePortfolioChange && handlePortfolioChange();
  };

  const handleDepotTypeChange = (value) => {
    portfolio.depotType = _.find(portfolio.availableDepotTypes, (depotType) => depotType.value == value)
    handlePortfolioChange && handlePortfolioChange();
  }

  const handleModelPortfolioTransactionTypeChange = (value) => {
    portfolio.modelPortfolioTransactionType = value
    handlePortfolioChange && handlePortfolioChange();
  }

  const getComponents = (key) => {
    let data = portfolio && portfolio.data || {};

    const mainComponentsKey = data.hasOwnProperty('base_components')
      ? 'base_components'
      : 'components';

    const paymentPlansKey = data.hasOwnProperty(paymentPlanTransactionKey)
      ? paymentPlanTransactionKey
      : 'components';

    let components = ['edit', 'delete'].includes(key)
      ? (data[paymentPlansKey] || []).map((component) => extendPaymentPlanWithInstrumentData(component, data[mainComponentsKey] || []))
      : data[mainComponentsKey] || [];

    // In case of "create" we allow multiple plans for same isin (as they might be with diff period/day)
    if(key === 'create'){
      if(paymentPlanTransactionKey !== TRANSACTION_TYPE_VALUES.PAYOUT_PLAN){
        components = _.concat(components, _.get(data, 'soldAssets') || []);
      }
      return _.clone(components);
    }

    // Filter out existing components (by payment_id) for which we already have transactions
    const componentKey = 'payment_id';

    return components.filter(i => !transactions.map(i => i.data[componentKey]).includes(i[componentKey]))
  };

  return props.children({
    getComponents,
    handleModelPortfolioTransactionTypeChange,
    handleDepotTypeChange,
    onInstrumentsChange,
    averageSrriValue,
    handleExpandedChange,
    expanded,
    classes,
    transactions,
    groupedTransactions
  })

}

export default PaymentPlanTrade