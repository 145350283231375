import {makeStyles} from "@material-ui/core";

export default makeStyles(() => ({
  cardRootOverride: {
    boxShadow: 'none',
    padding: 0
  },
  chartSectionHeaderInfoIcon: {
    marginLeft: 15
  },
}));