import React from 'react';

import {Grid, Tooltip, Typography} from "@material-ui/core";
import {getAgencyFilterOptions} from "../../../../../../components/CustomersSelectorProviderNew/constants";
import {Skeleton} from '@material-ui/lab';
import Button from "@material-ui/core/Button";
import useStyles from "./styles";

import SelectFormElement
  from "../../../../../RiskProfiling/components/StepContent/components/formElement/SelectFormElement";
import InputFormElement
  from "../../../../../RiskProfiling/components/StepContent/components/formElement/InputFormElement";
import LettersFilterPanel from "../../../../../../components/LettersFilterPanel";
import Switch from "../../../../../../components/AssetModal/components/Switch";
import {
  FINANCIAL_INFORMATION_CLASS,
} from "../../../../../Groups/components/FiltersPanel/FiltersPanel";
import _ from "lodash";
import ErrorModal from "../../../../../../components/ErrorModal";
import {
  INVERTED_BOUNDARIES_MODAL_MESSAGE,
  LOWER_BOUNDARY_MODAL_MESSAGE,
  UPPER_BOUNDARY_MODAL_MESSAGE
} from "../../../../../../components/Filters/constants";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import TabWithCheckboxFilter from "../../../../../../components/Filters/TabWithCheckboxFilter";
import TabWithRangeFilter from "../../../../../../components/Filters/TabWithRangeFilter";

// TODO: DRY it. copied from src/containers/Groups/components/FiltersPanel/FiltersPanel.js
const CustomerFilteringPanel = (props) => {

  const classes = useStyles();

  const {
    handleSearchByLetter,
    handleClientTypeChanged,
    customerType,
    defaultSearchValue,
    handleSearchClicked,
    isChief,
    user,
    setExpertFilteringEnabled
  } = props;

  const [value, setValue] = React.useState('');
  const [options, setOptions] = React.useState(undefined);
  const [triggerFiltersReset, setTriggerFiltersReset] = React.useState(false);

  React.useEffect(() => {
    setValue(defaultSearchValue)
  }, [defaultSearchValue]);

  React.useEffect(() => {
    setOptions(getAgencyFilterOptions(isChief, user.sub_agencies, user.agency))
  }, []);

  const onChangeHandler = (value) => {
    setValue(value);
  };

  const getFilters = () => ({
    broker: props.auth,
    ...financialFilters
  });

  const checkFilters = () => {
    let filterBoundaries = props.filtersOptions.depotRange;
    let selectedFilters = financialFilters.depotRange;
    let errorMessage = undefined;
    // is selectedFilters is empty - do not set any range -> there could be no range errors
    if (props.hasCustomers && !_.isEmpty(selectedFilters)) {
      if (selectedFilters[0] < filterBoundaries[0]) {
        errorMessage = LOWER_BOUNDARY_MODAL_MESSAGE;
      } else if (selectedFilters[1] > filterBoundaries[1]) {
        errorMessage = UPPER_BOUNDARY_MODAL_MESSAGE;
      } else if (selectedFilters[1] <= selectedFilters[0]) {
        errorMessage = INVERTED_BOUNDARIES_MODAL_MESSAGE;
      }
    }

    return errorMessage;
  }

  const onSearchClicked = () => {
    if(expertFiltersEnabled && !_.isNil(props.onFiltersUpdated)){
      let error = checkFilters();
      if (!_.isNil(error)) {
        setErrorMessage(error);
      } else {
        props.onFiltersUpdated(getFilters());
      }
    }
    setTriggerFiltersReset(false)
    handleSearchClicked(value);
  }

  const onKeyDownHandler = (event) => {
    if (event.key === 'Enter') {
      onSearchClicked();
    }
  };

  // expert filter-s
  const [expertFiltersEnabled, setExpertFiltersEnabled] = React.useState(false); // useExpertSearch, setUseExpertSearch
  React.useEffect(() => {
    /** UseEffect to update parent's setExpertFilteringEnabled state */

    // if expert filter is off, and it is not first render - reset filters to default
    if(!expertFiltersEnabled && isMounted) { resetFilters() }

    setExpertFilteringEnabled(expertFiltersEnabled)

  }, [expertFiltersEnabled])

  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const handleErrorModalClose = () => {
    setErrorMessage(undefined);
  };

  const defaultFinancialFilters = {
    financialInformationClass: [],
    fundCategories: [],
    investmentCompanies: [],
    portfolioBanks: [],
    productStrategies: [],
    portfolioStrategiesPI: [],
    portfolioStrategiesNonPI: [],
    customerPostboxStatuses: [],
    permanentSuitabilityCheckStatuses: [],
    depotRange: [],
  };

  const [financialFilters, setFinancialFilters] = React.useState(defaultFinancialFilters);

  const [isMounted, setIsMounted] = React.useState(false);

  const handleSelectAll = (fieldName, options) => () => {

    if (financialFilters.hasOwnProperty(fieldName)) {

      if (financialFilters[fieldName].length == options.length) {
        setFinancialFilters({
          ...financialFilters,
          [fieldName]: []
        })
      } else {
        setFinancialFilters({
          ...financialFilters,
          [fieldName]: options.map(option => option)
        })
      }
    }
  };

  const handleCheckboxValueChanged = (fieldName) => (values) => {

    if (financialFilters.hasOwnProperty(fieldName)) {

      let selectedValues = [...financialFilters[fieldName]];

      if (_.find(selectedValues, option => option.value == values.value)) {
        selectedValues = _.filter(selectedValues, (value) => value.value != values.value);
      } else {
        selectedValues = [...selectedValues, values];
      }


      setFinancialFilters({
        ...financialFilters,
        [fieldName]: selectedValues
      });
    }
  };

  const getAssetClassesOptions = () => {
    if (!props.filtersOptions.allowedOptions) {
      return FINANCIAL_INFORMATION_CLASS
    }

    let allowedOptions = [];

    FINANCIAL_INFORMATION_CLASS.forEach(assetClass => {

      let isAssetClassPresent = false;

      assetClass.financialClasses.forEach(financialClass => {

        if (financialClass == 6) {

          if (assetClass.financialSubclasses) {
            if (!_.isNil(_.find(props.filtersOptions.allowedOptions.financialInformationClass, item => item == 6))
                && !_.isNil(_.find(props.filtersOptions.allowedOptions.financialInformationSubclass, item => item == 601))) {
              isAssetClassPresent = true;
            }

          } else {
            if (!_.isNil(_.find(props.filtersOptions.allowedOptions.financialInformationClass, item => item == 6))
                && !_.isNil(_.find(props.filtersOptions.allowedOptions.financialInformationSubclass, item => item != 601))) {
              isAssetClassPresent = true;
            }
          }

        } else {
          if (!_.isNil(_.find(props.filtersOptions.allowedOptions.financialInformationClass, item => item == financialClass))) {
            isAssetClassPresent = true;
          }
        }
      });

      if (isAssetClassPresent) {
        allowedOptions.push(assetClass);
      }

    });

    return allowedOptions;
  };

  const handleRangeValueChanged = (fieldName) => (values) => {
    if (financialFilters.hasOwnProperty(fieldName)) {
      setFinancialFilters(prevState => ({
          ...prevState,
          [fieldName]: values
        })
      )
    }
  }
  const hasStrategies = !_.isEmpty(financialFilters.productStrategies);
  const privateInvestmentChecked = () => hasStrategies && _.isNil(_.find(financialFilters.productStrategies, strategy => strategy.value == 1));
  const nonPrivateInvestmentChecked = () => hasStrategies && _.isNil(_.find(financialFilters.productStrategies, strategy => _.includes([2, 3, 4, 5], strategy.value)));

  const resetFilters = () => {
    /** Resets filters values to default */

    setValue('');  // reset search field value

    // if expert filters are enabled -> reset them to default
    if (expertFiltersEnabled){
      let filters = {...defaultFinancialFilters};
      setFinancialFilters(filters);
    }

    setTriggerFiltersReset(true); // triggers useEffect to imitate search
  }
  React.useEffect(() => {
    /** Imitates search behaviour if triggerFiltersReset is true */
    if(triggerFiltersReset){
      onSearchClicked('')
    }
  }, [triggerFiltersReset])

  // tabs section
  const [selectedTab, setSelectedTab] =  React.useState(0)
  const handleChangeSelectedTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, selectedTabId, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={selectedTabId !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {selectedTabId === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    selectedTabId: PropTypes.number.isRequired
  };

  const renderSelectedOptionsLabel = (options, checked) => {
    const allOptionsCount = options && options.length;
    if (!props.loading && (!options || allOptionsCount === 0)) {
      return 'Keine Treffer gefunden';
    }
    if (allOptionsCount === checked.length) {
      return 'alles ausgewählt';
    }

    return `${checked.length} ausgewählt`;
  }

  const renderLabel = (title, field, options, checked) => {
    options = options || _.get(props.filtersOptions, field) || [];
    checked = checked || _.get(financialFilters, field) || [];

    return <span className={classes.checkboxFilterLabelMain} style={{color: props.disabled ? '#B1B1B1' : '#636A71'}}>
      {title} <span style={{fontWeight: 'normal'}}>{!!field && <>({renderSelectedOptionsLabel(options, checked)})</>}</span>
    </span>
  }

  return (
    <Grid container className={classes.filteringContainer} id="reporting-customer-filter-tour-element">
      <Grid item xs={12} className={classes.filteringWrapper}>
        <Grid container spacing={2}>
          {/* Line with letters for search */}
          <Grid item xs={12}>
            <LettersFilterPanel
              handleLetterClick={handleSearchByLetter}
              disabled={props.loading}
            />
          </Grid>

          {/* search field */}
          <Grid item xs={12} md={4} lg={5}>
            <div className={classes.inputContainer} id="search-filter-tour-element">
              <InputFormElement
                placeholder={"Suchbegriff"}
                label={"Suchbegriff"}
                value={value}
                onChange={onChangeHandler}
                onKeyDown={onKeyDownHandler}
                inputProps={{
                  endAdornment: (
                    <Tooltip arrow placement={"top"} classes={{tooltip: classes.tooltip}} title={
                      <React.Fragment>
                        Geben Sie hier einen Suchbegriff ein.<br/>
                        Es werden die Felder <i>Name</i> und <i>Kunden-Nr</i> durchsucht.
                      </React.Fragment>
                    }>
                      <i className='far fa-info-circle' />
                    </Tooltip>
                  )
                }}
              />
            </div>
          </Grid>

          {/* client type filter */}
          <Grid item xs={12} md={4} lg={5} id={'agency-filter-tour-element'} className={classes.inputContainer}>
            {handleClientTypeChanged && (
              <>
                {options ? 
                  <SelectFormElement
                    label='Kunden von'
                    onChange={handleClientTypeChanged}
                    value={customerType}
                    options={options}
                    onChangeGetEvent={true}
                    />
                  :
                  <Skeleton style={{width: '100%', marginTop: 5, marginBottom: -2}}/>
                }
              </>
            )}
          </Grid>

          {/*expert search switch*/}

            <Grid item xs={12} md={4} lg={2}>
              <p style={{margin: 0, fontSize: 16, marginBottom: 8}}>erweiterter Filter</p>
              <Switch
                value={expertFiltersEnabled}
                handleValueChanged={setExpertFiltersEnabled}
                customClasses={classes}
                trackBackgroundColor={'#2A3545'}
              />
            </Grid>


          {/* list of filters */}
          {expertFiltersEnabled &&
            <Grid item xs={12}>
              <Box
                css={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  display: "flex",
                }}
              >
                <Tabs
                  classes={{vertical: classes.tabsRoot}}
                  orientation="vertical"
                  variant="scrollable"
                  value={selectedTab}
                  onChange={handleChangeSelectedTab}
                  TabIndicatorProps={{style: {background:'#0092E5'}}}
                >
                  <Tab disabled={props.disabled} label={renderLabel("Depotstelle", 'portfolioBanks')} {...a11yProps(0)} />
                  <Tab disabled={props.disabled} label={renderLabel("Fondsgesellschaft", 'investmentCompanies')} {...a11yProps(1)} />
                  <Tab disabled={props.disabled} label={renderLabel("Anlageklasse", 'financialInformationClass', getAssetClassesOptions())} {...a11yProps(2)} />
                  <Tab disabled={props.disabled} label={renderLabel("Fondskategorie", 'fundCategories')} {...a11yProps(3)} />
                  <Tab disabled={props.disabled} label={renderLabel("Produktkategorie", 'productStrategies', props.filtersOptions.productCategoriesOptions)} {...a11yProps(4)} />
                  <Tab disabled={privateInvestmentChecked() || props.disabled || (props.filtersOptions.portfolioStrategiesPI || []).length == 0}
                       label={renderLabel(<>Vermögensverwaltungsstrategien<br /> - Private Investing</>, 'portfolioStrategiesPI')} {...a11yProps(5)} />
                  <Tab disabled={nonPrivateInvestmentChecked() || props.disabled || (props.filtersOptions.portfolioStrategiesNonPI || []).length == 0}
                       label={renderLabel("Musterdepots", 'portfolioStrategiesNonPI')} {...a11yProps(6)} />
                  <Tab disabled={props.disabled} label={renderLabel("Versandweg", 'customerPostboxStatuses')} {...a11yProps(7)} />
                  <Tab disabled={props.disabled} label={renderLabel("Wert der ausgewählten Depots")} {...a11yProps(8)} />
                </Tabs>

                {/* Depotstelle */}
                <TabPanel selectedTabId={selectedTab} index={0} className={classes.tabPanel}>
                  <TabWithCheckboxFilter
                    options={props.filtersOptions.portfolioBanks || []}
                    checked={financialFilters.portfolioBanks}
                    handleCheckedChanged={handleCheckboxValueChanged('portfolioBanks')}
                    handleSelectAll={handleSelectAll('portfolioBanks', props.filtersOptions.portfolioBanks || [])}
                    loading={props.loading}
                  />
                </TabPanel>

                {/* Fondsgesellschaft */}
                <TabPanel selectedTabId={selectedTab} index={1} className={classes.tabPanel}>
                  <TabWithCheckboxFilter
                    options={props.filtersOptions.investmentCompanies || []}
                    checked={financialFilters.investmentCompanies}
                    handleCheckedChanged={handleCheckboxValueChanged('investmentCompanies')}
                    handleSelectAll={handleSelectAll('investmentCompanies', props.filtersOptions.investmentCompanies || [])}
                    loading={props.loading}
                  />
                </TabPanel>

                {/* Anlageklasse */}
                <TabPanel selectedTabId={selectedTab} index={2} className={classes.tabPanel}>
                  <TabWithCheckboxFilter
                  options={getAssetClassesOptions()}
                  checked={financialFilters.financialInformationClass}
                  handleCheckedChanged={handleCheckboxValueChanged('financialInformationClass')}
                  handleSelectAll={handleSelectAll('financialInformationClass', getAssetClassesOptions())}
                  loading={props.loading}
                  />
                </TabPanel>

                {/* Fondskategorie */}
                <TabPanel selectedTabId={selectedTab} index={3} className={classes.tabPanel}>
                  <TabWithCheckboxFilter
                  options={props.filtersOptions.fundCategories || []}
                  checked={financialFilters.fundCategories}
                  handleCheckedChanged={handleCheckboxValueChanged('fundCategories')}
                  handleSelectAll={handleSelectAll('fundCategories', props.filtersOptions.fundCategories || [])}
                  loading={props.loading}
                  />
                </TabPanel>

                {/* Produktkategorie */}
                <TabPanel selectedTabId={selectedTab} index={4} className={classes.tabPanel}>
                  <TabWithCheckboxFilter
                  options={props.filtersOptions.productCategoriesOptions}
                  checked={financialFilters.productStrategies}
                  handleCheckedChanged={handleCheckboxValueChanged('productStrategies')}
                  handleSelectAll={handleSelectAll('productStrategies', props.filtersOptions.productCategoriesOptions || [])}
                  loading={props.loading}
                  />
                </TabPanel>

                {/* Vermögensverwaltungsstrategien - Private Investing */}
                <TabPanel selectedTabId={selectedTab} index={5} className={classes.tabPanel}>
                  <TabWithCheckboxFilter
                    options={props.filtersOptions.portfolioStrategiesPI || []}
                    checked={financialFilters.portfolioStrategiesPI}
                    handleCheckedChanged={handleCheckboxValueChanged('portfolioStrategiesPI')}
                    handleSelectAll={handleSelectAll('portfolioStrategiesPI', props.filtersOptions.portfolioStrategiesPI || [])}
                    loading={props.loading}
                  />
                </TabPanel>

                {/* Musterdepots */}
                <TabPanel selectedTabId={selectedTab} index={6} className={classes.tabPanel}>
                  <TabWithCheckboxFilter
                    options={props.filtersOptions.portfolioStrategiesNonPI || []}
                    checked={financialFilters.portfolioStrategiesNonPI}
                    handleCheckedChanged={handleCheckboxValueChanged('portfolioStrategiesNonPI')}
                    handleSelectAll={handleSelectAll('portfolioStrategiesNonPI', props.filtersOptions.portfolioStrategiesNonPI || [])}
                    loading={props.loading}
                  />
                </TabPanel>

                {/* Versandweg */}
                <TabPanel selectedTabId={selectedTab} index={7} className={classes.tabPanel}>
                  <TabWithCheckboxFilter
                    options={props.filtersOptions.customerPostboxStatuses || []}
                    checked={financialFilters.customerPostboxStatuses}
                    handleCheckedChanged={handleCheckboxValueChanged('customerPostboxStatuses')}
                    handleSelectAll={handleSelectAll('customerPostboxStatuses', props.filtersOptions.customerPostboxStatuses || [])}
                    loading={props.loading}
                    useSingleColumn
                  />
                </TabPanel>

                {/* Wert der ausgewählten Depots */}
                <TabPanel selectedTabId={selectedTab} index={8} className={classes.tabPanel}>
                  <TabWithRangeFilter
                    handleValuesChanged={(newDepotRange) => {
                    // if both newDepotRange values are 0 -> set filter to default (as it was on load)
                    if (newDepotRange.every(val => val === 0)) {
                     newDepotRange = []
                    }
                    handleRangeValueChanged('depotRange') (newDepotRange)
                  }}
                    initialValues={!_.isEmpty(financialFilters.depotRange) ? financialFilters.depotRange : [0, 0]}
                    loading={props.loading}
                    range={props.filtersOptions.depotRange || [0, 0]}
                    withMinMaxPlaceholders={true}
                  />
                </TabPanel>
              </Box>
            </Grid>
          }

          {/* search btn */}
          <Grid item xs={12} sm={4} lg={5}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSearchClicked}
              className={classes.searchButton}
              disabled={props.loading}
            >
              <i className={"fa fa-search"} /> Suche starten
            </Button>
          </Grid>

          {/* reset expert filters to default */}
          <Grid item xs={12} sm={4} lg={2} style={{marginLeft: "auto"}}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.searchButton}
              onClick={resetFilters}
              disabled={props.loading || props.disabled}
            >
              <i className="fa fa-trash-o" aria-hidden="true" /> Zurücksetzen
            </Button>
          </Grid>

          {errorMessage && (
            <ErrorModal
              message={errorMessage}
              handleClose={handleErrorModalClose}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
};

export default CustomerFilteringPanel;