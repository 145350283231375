import { makeStyles } from '@material-ui/core';

export const getApplyFiltersBtnStyles = (theme) => {
  return {
    color: 'white',
    borderRadius: '4px',
    fontSize: '16px',
    fontFamily: 'Roboto-Bold',
    textTransform: 'none',
    boxShadow: '3px 6px 10px #00000033',

    minHeight: '45px !important',
    height: '45px !important',

    '& .fa': {
      marginRight: '0.5rem',
      position: 'relative',
      top: -1,
    },
  }
}

export const applyFiltersBtnText = {
  padding: '5px 8px',
  whiteSpace: 'nowrap',
}

export const styles = theme => ({
  filtersRoot: {
    padding: '5px 30px 20px 30px',
    // flexWrap: 'nowrap',
    justifyContent: 'space-between'
  },
  inputRoot: {
    marginBottom: 0
  },
  labelRoot: {
    fontSize: 16,
    marginBottom: 1,
    fontFamily: 'Roboto-Bold',
  },
  applyFiltersBtn: {
    ...getApplyFiltersBtnStyles(theme),
  },
  applyFiltersBtnText: {
    ...applyFiltersBtnText
  },
  textField: {

    minHeight: '45px !important',
    height: '45px !important',
    maxHeight: '45px !important',

    '& input::placeholder': {
      color: 'rgba(0, 0, 0, 0.87)',
      opacity: 1,
    }
  },
  banksOption: {
    backgroundColor: 'white !important',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
  }
});

export default makeStyles(styles);