import React from "react";
import _ from "lodash";
import moment from "moment";

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";

import styles from "../styles";
import InputFormElement from "../../../formElement/InputFormElement";
import RadioQuestion from '../../../question/RadioQuestion';
import DateQuestion from '../../../question/DateQuestion';
import RichTextEditor from "../../../formElement/RichTextEditor";
import {isRequiredQuestion} from "../../../../../../utils";
import FormLabelElement from '../../../formElement/LabelFormElement'
import TimeQuestion from '../../../question/TimeQuestion';
import {SERVICE_CONCEPTS} from '../../../../../../../Trades/constants'
import InputQuestion from "../../../question/InputQuestion";
import {INTERVIEW_PLACE} from "../../../../../../mock_finalize";

const ConversationDetailsStep = props => {
  const {
    classes,
    onAnswerChange,
    questions: [
      interviewDate,
      interviewStartTime,
      interviewEndTime,
      interviewPlace,
      interviewBrokerInput,
      interviewPlaceBrokerInput,
      interviewCustomerInput,
      interviewPlaceCustomerInput,
      interviewAccountHolder1Input,
      interviewPlaceAccountHolder1Input,
      interviewAccountHolder2Input,
      interviewPlaceAccountHolder2Input,
      interviewRepeatedDate,
      interviewInitiative,
      interviewReason,
      interviewDateType,
      permanentSuitabilityCheck,
      interviewOtherPeoplesInput
    ],
    dataService
  } = props;

  const holders = [interviewAccountHolder1Input, interviewAccountHolder2Input].filter(q => !q.hidden);
  const holdersInterviewPlaces = [interviewPlaceAccountHolder1Input, interviewPlaceAccountHolder2Input].filter(q => !q.hidden);

  const handleAnswerChange = (question, answer) => {
    onAnswerChange(question, answer);
  };

  const handleInterviewPlaceAnswerChange = (question, answer) => {
    const updateInterviewPlaceQuestions = (interviewPlace) => {
      [
        interviewPlaceBrokerInput,
        interviewPlaceCustomerInput,
        interviewPlaceAccountHolder1Input,
        interviewPlaceAccountHolder2Input
      ].forEach((q) => {
        q.error = null;
        q.answer = _.isNil(interviewPlace) ? q.defaultAnswer : interviewPlace;
      })
    }

    let interviewPlace = {
      [INTERVIEW_PLACE.BROKER]: interviewPlaceBrokerInput.defaultAnswer || '',
      [INTERVIEW_PLACE.CUSTOMER]: (dataService.useMembers && !_.isEmpty(dataService.members)
        ? interviewPlaceAccountHolder1Input.defaultAnswer // Use address of first account holder
        : interviewPlaceCustomerInput.defaultAnswer) || '',
      [INTERVIEW_PLACE.OTHER]: '', // Empty in case other option selected
      [INTERVIEW_PLACE.ONLINE]: undefined // Each participant use own address
    }[+answer];

    updateInterviewPlaceQuestions(interviewPlace);
    onAnswerChange(question, answer);
  };

  const handleStartTimeChange = (uid, answer) => {
    if(!interviewEndTime.answer && moment.isMoment(answer) && answer.isValid()){
      interviewEndTime.answer = moment(answer).add(1, 'hour');
      interviewEndTime.error = null; // clean error
    }
    handleAnswerChange(uid, answer);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item lg={4}>
              <DateQuestion
                question={interviewDate}
                onAnswerChange={handleAnswerChange}
              />
            </Grid>
            <Grid item lg={2}>
              <TimeQuestion
                question={interviewStartTime}
                onAnswerChange={handleStartTimeChange}
              />
            </Grid>
            <Grid item lg={2}>
              <TimeQuestion
                question={interviewEndTime}
                onAnswerChange={handleAnswerChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <RadioQuestion
                question={interviewPlace}
                onAnswerChange={handleInterviewPlaceAnswerChange}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <InputQuestion
                question={interviewPlaceBrokerInput}
                onAnswerChange={handleAnswerChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction={'column'}>
                {dataService.useMembers ?
                  holdersInterviewPlaces.map(q => (
                    <Grid item xs={12} md={4} lg={4} key={q.uid}>
                      <InputQuestion
                        question={q}
                        onAnswerChange={handleAnswerChange}
                      />
                    </Grid>
                  )) :
                  <Grid item xs={12} md={4} lg={4}>
                    <InputQuestion
                      question={interviewPlaceCustomerInput}
                      onAnswerChange={handleAnswerChange}
                    />
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormLabelElement label="Gesprächsteilnehmer" required={true} customClasses={{labelRoot: 'bold'}} style={{margin: 0, padding: 0, marginTop: 16}}/>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <InputQuestion
                question={interviewBrokerInput}
                onAnswerChange={handleAnswerChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction={'column'}>
                {dataService.useMembers ?
                  holders.map(q => (
                    <Grid item xs={12} md={4} lg={4} key={q.uid}>
                      <InputQuestion
                        question={q}
                        onAnswerChange={handleAnswerChange}
                      />
                    </Grid>
                  )) :
                  <Grid item xs={12} md={4} lg={4}>
                    <InputQuestion
                      question={interviewCustomerInput}
                      onAnswerChange={handleAnswerChange}
                    />
                  </Grid>
                }
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <FormLabelElement label={interviewOtherPeoplesInput.question_text} required={false} style={{marginTop: 12, padding: 0, marginBottom: 12}}/>
              <InputFormElement
                name={interviewOtherPeoplesInput.uid}
                value={interviewOtherPeoplesInput.answer || ''}
                onChange={(value) => handleAnswerChange(interviewOtherPeoplesInput.uid, value)}
                error={interviewOtherPeoplesInput.error}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} md={4} lg={4} className={classes.checkboRegularContainer}>
              <RadioQuestion
                question={interviewDateType}
                onAnswerChange={handleAnswerChange}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={3} style={{display: 'flex', alignItems: 'flex-end'}}>
              <DateQuestion
                question={interviewRepeatedDate}
                onAnswerChange={handleAnswerChange}
              />
            </Grid>
          </Grid>
        </Grid>
        {dataService.serviceConcept == SERVICE_CONCEPTS.Anlageberatung && (
          <Grid item xs={12} md={8}>
            <RadioQuestion
              question={permanentSuitabilityCheck}
              onAnswerChange={handleAnswerChange}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <RichTextEditor
            uid={interviewInitiative.uid}
            label={interviewInitiative.question_text}
            value={interviewInitiative.answer || ''}
            onChange={(value) => {
              interviewInitiative.error = null; // clean error
              handleAnswerChange(interviewInitiative.uid, value)
            }}
            rows={6}
            required={isRequiredQuestion(interviewInitiative)}  
            customClasses={interviewInitiative.custom_classes}
            error={interviewInitiative.error}
          />
        </Grid>
        {dataService.serviceConcept != SERVICE_CONCEPTS.ExecutionOnly && (
          <Grid item xs={12}>
            <RichTextEditor
              uid={interviewReason.uid}
              label={interviewReason.question_text}
              value={interviewReason.answer || ''}
              onChange={(value) => {
                interviewReason.error = null; // clean error
                handleAnswerChange(interviewReason.uid, value)
              }}
              rows={6}
              required={isRequiredQuestion(interviewReason)}  
              customClasses={interviewReason.custom_classes}
              error={interviewReason.error}
            />
          </Grid>
        )}
       </Grid>
    </>
  );
};

export default withStyles(styles)(ConversationDetailsStep);
