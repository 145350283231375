import React, { Component } from 'react'
import axios from 'axios';
import _ from "lodash";
import { connect } from 'react-redux'

import { styles } from './styles';
import {
  withStyles,
  Grid,
  CircularProgress
} from '@material-ui/core';

import { ExtendedFilters, InstrumentsList, Tabs } from './components';
import { ModelPortfolioResource, parseResponse } from '../../../utils/api'
import { paginateArray } from '../../../utils/utils'
import { displayErrorSnackBar } from '../../../components/SnackbarProvider/actions'
import SuccessDialog from "../../../components/SuccessDialog";
import {extendedSearchTableStructure} from "./components/InstrumentsList/table-data";
import {BaseNumberFormat} from "../../../components/Inputs";

export const CurrencyNumberFormat = (props) => {
  return (
    <BaseNumberFormat
      {...props}
      decimalScale={2}
      suffix=""
      placeholder={'0,00'}
    />
  );
};


const TABS = [
  {
    id: 1,
    label: 'Investments filtern'
  }, {
    id: 2,
    label: 'Suchergebnisse'
  }
]


let cancelToken;
class Filters extends Component {

  state = {
    assets: {
      data: undefined,
      loading: false,
      errors: undefined,
      totalAssetsCount: 0
    },
    selectedAssets: [],
    setDefaultTrigger: undefined,
    activeTab: TABS[0].id,
    filtersCount: 0,
    successOpen: false
  }

  handleFiltersCountChanged = (count) => {
    this.setState({
      filtersCount: count
    })
  }

  handleAddInstrumentsClick = () => {

    if (this.props.onAddInstruments) {
      this.props.onAddInstruments(this.state.selectedAssets)

      this.setState({
        selectedAssets: [],
        setDefaultTrigger: Date.now()
      })
    }

  }

  handleDefaultTriggerClick = () => {
    this.setState({
      setDefaultTrigger: Date.now()
    })
  }


  handleFiltersApproved = (filters, totalFiltersCount=undefined) => {
    if (this.props.addExtraFilters) {
      filters = this.props.addExtraFilters(filters)
    }

    this.fetchAssets(filters)

    this.setState({
      successOpen: true,
      filtersCount: totalFiltersCount
    })
  }

  handleAssetSelected = (asset) => {
    let selectedAssetsCopy = [...this.state.selectedAssets]

    if (selectedAssetsCopy.find(item => item.isin == asset.isin)) {
      selectedAssetsCopy = selectedAssetsCopy.filter(item => item.isin != asset.isin)
    } else {
      selectedAssetsCopy.push(asset)
    }

    this.setState({
      selectedAssets: selectedAssetsCopy
    })
  }

  _availableInstruments = () => {
    let assets = [].concat(...(this.state.assets.data || []));
    return assets.filter(asset => !asset.disabledByFilter);
  }

  isAllSelected = () => {
    return this.state.selectedAssets.length && this.state.selectedAssets.length === this._availableInstruments().length;
  }

  handleAllSelected = () => {
    let assets = this._availableInstruments();

    if(this.state.selectedAssets.length === assets.length){
      this.setState({
        selectedAssets: []
      })
    } else {
      this.setState({
        selectedAssets: assets
      })
    }
  }

  prepareResponse = (response) => {

    if (!this.props.instrumentsHandler) {
      return response
    }

    let [instrumentsToAccept, instrumentsToExclude] = this.props.instrumentsHandler.filterInstruments(response)

    let results = instrumentsToAccept.concat(instrumentsToExclude)

    return results

  }

  fetchAssets = async (filters) => {
    this.setState({
      assets: {
        loading: true,
        data: undefined,
        errors: undefined
      }
    })

    try {
      //Check if there are any previous pending requests
      if (typeof cancelToken != 'undefined') {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      cancelToken = axios.CancelToken.source();
      let response = await ModelPortfolioResource.searchAssets(filters, cancelToken.token)

      parseResponse(response, 'assets', (data) => {

        data = this.prepareResponse(data)
        data = _.orderBy(data, ['disabledByFilter', (item) => item.name && item.name.toLowerCase()], ['desc', 'asc']);
        this.setState({
          assets: {
            data: [...paginateArray(data, 15)],
            loading: false,
            errors: undefined,
            totalAssetsCount: data.length,

          },
          activeTab: (this.state.successOpen && this.state.activeTab == this.state.activeTab == TABS[0].id) ? TABS[1].id : TABS[0].id,
          successOpen: false
        });
      }, (errors) => {
        this.setState({
          assets: {
            data: undefined,
            loading: false,
            errors
          },
          successOpen: false
        });
        this.props.dispatch(displayErrorSnackBar('Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.'))
      });

    } catch (errors) {
      if (errors !== 'Operation canceled due to new request.') {
        this.setState({
          assets: {
            data: undefined,
            loading: false,
            errors,

          },
          successOpen: false
        })
        //
        this.props.dispatch(displayErrorSnackBar('Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.'))
      }

    }
  }

  handleTabChange = (value) => {
    this.setState({
      activeTab: value
    })
  }

  getTabs = () => {

    let tabs = TABS.map(tab => ({...tab}))

    if (this.state.activeTab == tabs[1].id && this.state.filtersCount) {
      tabs[0].label = `${tabs[0].label} (${this.state.filtersCount})`
    }

    if (this.state.activeTab == tabs[1].id && (this.state.assets.data && this.state.assets.totalAssetsCount) ) {
      tabs[1].label = `${tabs[1].label} (${this.state.assets.totalAssetsCount})`
    }

    return tabs
  }

  handleCloseModal = () => {
    if (cancelToken) {
      cancelToken.cancel('Operation canceled due to new request.')
    }

    this.setState({
      assets: {
        loading: false,
        data: undefined,
        errors: undefined
      },
      successOpen: false
    })
  }


  render() {

    const {
      classes,
      title,
      isFundSearch,
      disabledAssetClasses
    } = this.props;

    return (
      <div style={{paddingBottom: this.props.useButtons ? 0 : 100, marginTop: 20}}>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12} className={classes.section}>
            <h2 className={classes.header}>
              {title}
            </h2>
          </Grid>
          <Grid item xs={12} className={classes.section}>
            <Tabs>
              <Tabs.List
                items={this.getTabs()}
                value={this.state.activeTab}
                onValueChange={this.handleTabChange}
                withBorder={this.props.useButtons}
              />
              <Tabs.Content>
                  <div style={{marginTop: -25, display: this.state.activeTab == TABS[0].id ? 'block': "none"}}>
                    <ExtendedFilters
                      predefinedFilter={this.props.predefinedFilter}
                      onFiltersApproved={this.handleFiltersApproved}
                      setDefault={this.state.setDefaultTrigger}
                      onFiltersCountChanged={this.handleFiltersCountChanged}
                      visible={this.state.activeTab == TABS[0].id}
                      useButtons={this.props.useButtons}
                      errors={this.state.assets.errors}
                      customerId={this.props.customerId}
                      disabledAssetClasses={disabledAssetClasses}
                    />
                  </div>
                  <div style={{marginTop: -25, display: this.state.activeTab == TABS[1].id ? 'block': "none"}}>
                    <InstrumentsList
                      customTableStructure={extendedSearchTableStructure}
                      instruments={this.state.assets.data}
                      loading={this.state.assets.loading}
                      errors={this.state.assets.errors}
                      selected={this.state.selectedAssets}
                      totalInstrumentsCount={this.state.assets.totalAssetsCount}
                      onInstrumentSelect={this.handleAssetSelected}
                      onAllSelect={this.handleAllSelected}
                      allSelected={this.isAllSelected()}
                      onAddInstruments={this.handleAddInstrumentsClick}
                      visible={this.state.activeTab == TABS[1].id}
                      useButtons={this.props.useButtons}
                      isFundSearch={isFundSearch}
                      tableData={{classes: classes}}
                    />
                  </div>
              </Tabs.Content>
            </Tabs>
          </Grid>
        </Grid>
        <SuccessDialog
          open={this.state.successOpen}
          onClose={this.handleCloseModal}
          message={(
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{fontFamily: 'Roboto-Regular', fontSize: 16, color: '#202A38'}}>Der Filter wurde angewendet. Sie finden die Ergebnisse unter “Suchergebnisse”</p>
            <CircularProgress  style={{height: '100%', width: 66}}/>
            </div>
          )}
        />
      </div>
      // {/*<Grid container className={classes.container} {...this.props} spacing={2}>*/}
      // {/*  <Grid item lg={6} className={classes.section}>*/}
      // {/*    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>*/}
      // {/*      <h2>Instrumente filtern</h2>*/}
      // {/*      <Link title="Zurücksetzen" icon={<DeleteIcon />} onClick={this.handleDefaultTriggerClick}/>*/}
      // {/*    </div>*/}
      // {/*    <ExtendedFilters onFiltersApproved={this.handleFiltersApproved} setDefault={this.state.setDefaultTrigger}/>*/}
      // {/*  </Grid>*/}
      // {/*  <Grid item lg={6} className={classes.section}>*/}
      // {/*    <h2>Gefilterte Instrumente</h2>*/}

      // {/*  </Grid>*/}
      // {/*</Grid>*/}
      // {/*<InstrumentSuccessCreationModal />*/}
    )
  }
}

Filters.propTypes = {

}

Filters.defaultProps = {
  title: 'Investmentsuche',
  predefinedFilter: {}
}

export default connect()(withStyles(styles)(Filters))
