const styles = theme => ({
  dateRangeContainer: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  dateRangeItem: {
    display: 'flex',
    marginRight: 16,

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: 8
    },
  },
  itemLabel: {
    marginRight: 8,
    lineHeight: '32px',

    [theme.breakpoints.down('sm')]: {
      width: 28
    },
  },
  keyboardDatePickerRoot: {
    width: 130,

    '& input': {
      fontSize: 14
    }
  },

  textFieldUnderline: {
    '&::before': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    '&:hover::before': {
      borderBottom: `2px solid ${theme.palette.primary.main} !important`,
    }
  },
});

export default styles;