export const AGGREGATED_PORTFOLIO_NAME = 'Gesamtbestand';
export const AGGREGATED_ASSET_NAME = 'Gesamtdepot';
const AGGREGATED_PORTFOLIO_SERVER_NAME = 'Aggregated portfolio';

// added for demo presentation #https://redmine.fincite.net/issues/41657
// TODO remove after localization will be implemented on backend

export function formatPortfolioName(portfolioName) {
  if (portfolioName === AGGREGATED_PORTFOLIO_SERVER_NAME) {
    return AGGREGATED_PORTFOLIO_NAME;
  }
  return portfolioName;
}