const styles = theme => ({
  container: {
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0px 3px 15px #00000033',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      padding: 15
    }
  },
  headerContainer: {
    marginBottom: 16,
    display: 'flex',
    zIndex: 1,

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  header: {
    fontFamily: "'Roboto-Regular'",
    fontSize: 22,
    color: '#4D4F5C',
    margin: 0,
    flex: 1,

    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    }
  },
  contentContainer: {
    marginBottom: 16,
    flex: 1,
  },
  centerContent: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  emptyMessage: {
    margin: 0,
    fontSize: 22,
    color: '#b1b1b1',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  }
});

export default styles;