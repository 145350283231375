import React from 'react';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import styles from './styles';

const PageTabsNavigation = (props) => {

  const {
    classes,
    tabs,
    active,
    onActiveChanged,
    containerId,
    helpContent
  } = props;

  const renderTabButtons = () => {
    return tabs.map((buttonText, index) => {
      return (
        <Grid item
              key={index}
              className={clsx(classes.tabButton, active === index && classes.active)}
              onClick={() => onActiveChanged(index)}>
          <div>{buttonText}</div>
        </Grid>
      )
    })
  };

  return (
    <Grid className={classes.container} container spacing={2}>
      <Grid item className={classes.tabs}>
        <Grid container id={containerId}>
          {renderTabButtons()}
        </Grid>
      </Grid>

      {helpContent && (
        <Grid item>
          {helpContent}
        </Grid>
      )}
    </Grid>
  )
};


export default withStyles(styles)(PageTabsNavigation);