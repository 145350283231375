import React from 'react';
import moment from "moment";

import { Grid } from '@material-ui/core';

import useStyles from '../styles';
import images from '../../../../images';
import TextField from '../TextField';
import DateField from '../DateField';
import CurrencyField from '../CurrencyField'
import SelectField from '../SelectField';
import NonFieldErrors from "../NonFieldErrors";
import { INSURANCE_TYPES } from '../../constants';


export default (props) => {
  const classes = useStyles();

  const {
    handleFormChange, 
    errors,
    asset,
    viewOnly
  } = props;

  const [model, setModel] = React.useState({
    contract_number: '',
    company_name: '',
    type_of_insurance: undefined,
    updated: undefined,
    surrender_value: undefined,
    collateral: undefined,
    surrender_valuation_date: undefined,
    collateral_valuation_date: undefined,
    acquisition_date: undefined,
    final_duration: undefined,
  });

  React.useEffect(() => {
    if (asset) {
      const type_of_insurance = INSURANCE_TYPES.find(type => asset.type_of_insurance === type.id);
      const _model = {
        contract_number: asset.name,
        company_name: asset.company_name,
        type_of_insurance: type_of_insurance,
        updated: asset.updated && moment(asset.updated + 'Z'),
        surrender_value: +asset.value,
        collateral: +asset.collateral,
        surrender_valuation_date: asset.surrender_valuation_date && moment(asset.surrender_valuation_date),
        collateral_valuation_date: asset.collateral_valuation_date && moment(asset.collateral_valuation_date),
        acquisition_date: asset.acquisition_date && moment(asset.acquisition_date),
        final_duration: asset.final_duration && moment(asset.final_duration),
      };
      setModel(_model);
    }
  }, [asset]);


  const prepareFormData = () => {
    return {
      name: model.contract_number,
      value: model.surrender_value,
      company_name: model.company_name,
      type_of_insurance: model.type_of_insurance && model.type_of_insurance.id,
      collateral: model.collateral,
      surrender_valuation_date: model.surrender_valuation_date && model.surrender_valuation_date.format('YYYY-MM-DD'),
      collateral_valuation_date: model.collateral_valuation_date && model.collateral_valuation_date.format('YYYY-MM-DD'),
      acquisition_date: model.acquisition_date && model.acquisition_date.format('YYYY-MM-DD'),
      final_duration: model.final_duration && model.final_duration.format('YYYY-MM-DD'),
    }
  };

  React.useEffect(() => {
    handleFormChange && handleFormChange(prepareFormData());
  }, [model]);

  const handleModelChange = (field) => (event) => {
    setModel({
      ...model,
      [field]: event.target.value
    })
  };

  const handleDateChanged = (field) => (value) => {
    setModel({
      ...model,
      [field]: value
    })
  };

  const handleSelectChanged = field => value => {
    setModel({
      ...model,
      [field]: value
    })
  };

  return (
    <Grid container className={classes.formContainer}>
      <Grid item className={classes.formBody}>
        <div className={classes.formHeader}>
          <div>
            <img src={images.asset_icons.insurance_icon_white} alt="Insurance"/>
          </div>
          <p>Versicherungen</p>
        </div>
        <div>
          <TextField
            label="Vertragsnummer"
            value={model.contract_number}
            error={errors && errors.name}
            onChange={handleModelChange('contract_number')}
            disabled={viewOnly}
          />
          <TextField
            label="Gesellschaft"
            value={model.company_name}
            error={errors && errors.company_name}
            onChange={handleModelChange('company_name')}
            disabled={viewOnly}
          />
          <SelectField
            label="Versicherungsart"
            value={model.type_of_insurance}
            onChange={handleSelectChanged('type_of_insurance')}
            error={errors && errors.type_of_insurance}
            items={INSURANCE_TYPES}
            disabled={viewOnly}
          />
          <DateField
            label="Erwerbsdatum"
            value={model.acquisition_date}
            maxDate={moment()}
            error={errors && errors.acquisition_date}
            onChange={handleDateChanged('acquisition_date')}
            disabled={viewOnly}
          />
          <DateField
            label="Laufzeit bis"
            value={model.final_duration}
            minDate={moment().add(1, 'd')}
            error={errors && errors.final_duration}
            onChange={handleDateChanged('final_duration')}
            disabled={viewOnly}
          />
          <CurrencyField
            label="Rückkaufswert"
            value={model.surrender_value}
            error={errors && errors.value}
            onChange={handleModelChange('surrender_value')}
            disabled={viewOnly}
          />
          <DateField
            label="Bewertungsdatum"
            value={model.surrender_valuation_date}
            maxDate={moment()}
            error={errors && errors.surrender_valuation_date}
            onChange={handleDateChanged('surrender_valuation_date')}
            disabled={viewOnly}
          />
          <CurrencyField
            label="Garantie"
            value={model.collateral}
            error={errors && errors.collateral}
            onChange={handleModelChange('collateral')}
            disabled={viewOnly}
          />
          <DateField
            label="Bewertungsdatum"
            maxDate={moment()}
            value={model.collateral_valuation_date}
            error={errors && errors.collateral_valuation_date}
            onChange={handleDateChanged('collateral_valuation_date')}
            disabled={viewOnly}
          />
          <DateField
            label="Stand vom"
            value={model.updated || moment()}
            disabled={true}
          />
          {errors && errors['non_field_errors'] && <NonFieldErrors errors={errors['non_field_errors']}/>}
        </div>
      </Grid>
    </Grid>
  )
}