import React from 'react';


export default ({color, onClick, size}) => {
  return (
    <svg fill={color || '#0092e5'} xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
      <g transform="translate(0)">
        <path d="M19.3,9.5c-1.6.137-3.893,2.137-5.051,2.793a5.512,5.512,0,0,1-2.357.953c-.7,0-.805-.633-.66-2.027.034-.312.362-3.094-1.322-2.973-.777.059-1.991.969-5.249,4.922L5.947,9.117C6.889,6.152,4.3,3.2,1.93,5.1L.23,6.542a.732.732,0,0,0-.152.863L.611,8.46a.437.437,0,0,0,.684.191l1.8-1.519c.57-.457,1.26.281,1.013,1.059L1.063,17.784c-.211.66.084,1.715.92,1.715a.89.89,0,0,0,.7-.367c1.307-1.648,4.791-5.887,6.541-7.648a4.877,4.877,0,0,0,.638,3.273,2.361,2.361,0,0,0,2.028.988,6.678,6.678,0,0,0,3.168-1.172C16.08,14,18.124,12.128,19.344,12a.581.581,0,0,0,.471-.617V10.132C19.821,9.777,19.583,9.476,19.3,9.5Z" transform="translate(-0.002 -4.499)"/>
      </g>
    </svg>
  )
}