import React from 'react';

import { withStyles } from '@material-ui/core'

import FiltersSection from "../FiltersSection";
import {FilterSection, FilterSubSection} from "../../ExtendedFilters";
import {DropdownInput} from "../../../../../components";
import styles from '../FiltersSection/styles'
import Grid from "@material-ui/core/Grid";
import _ from 'lodash';

class MyComponent extends FiltersSection {

  DEFAULT_VALUES = {
    name: {
      value: '',
      section: 'basic'
    },
    priceToEarningsStart: {
      value: '',
      section: 'numbers'
    },
    priceToEarningsEnd: {
      value: '',
      section: 'numbers'
    },
    priceToBookStart: {
      value: '',
      section: 'numbers'
    },
    priceToBookEnd: {
      value: '',
      section: 'numbers'
    },
    dividendYieldStart: {
      value: '',
      section: 'numbers'
    },
    dividendYieldEnd: {
      value: '',
      section: 'numbers'
    },
  }

  state = {
    name: '',
    priceToEarningsStart: '',
    priceToEarningsEnd: '',
    priceToBookStart: '',
    priceToBookEnd: '',
    dividendYieldStart: '',
    dividendYieldEnd: '',
  }

  _buildFilters = () => {
    let filters = {}

    filters.name = this.state.name || null

    filters.price_to_earnings_start = this.state.priceToEarningsStart
    filters.price_to_earnings_end = this.state.priceToEarningsEnd
    filters.price_to_book_start = this.state.priceToBookStart
    filters.price_to_book_end = this.state.priceToBookEnd
    filters.dividend_yield_start = this.state.dividendYieldStart
    filters.dividend_yield_end = this.state.dividendYieldEnd

    return filters
  }

  setDefaultFilters = () => {
    this.setState({
      errors: {},
      name: '',
      priceToEarningsStart: '',
      priceToEarningsEnd: '',
      priceToBookStart: '',
      priceToBookEnd: '',
      dividendYieldStart: '',
      dividendYieldEnd: '',
    })
  }

  getChangedFiltersTotalCount = () => {
    return this.getChangedFiltersCount(['name', ['priceToEarningsStart', 'priceToEarningsEnd'], ['priceToBookStart', 'priceToBookEnd'], ['dividendYieldStart', 'dividendYieldEnd']])
  }

  render() {

    const { classes } = this.props

    return (
      <>
        <FilterSection
          title="Basisdaten"
          sectionName="basic"
          expandedByDefault={true}
          onResetClick={this.onValueReset}
          count={this.getChangedFiltersCount(['name'])}
        >
          <FilterSubSection
            title="Instrumentenname, ISIN, oder WKN suchen"
            resetButtonVisible={this.isFieldChanged('name')}
            onResetClick={this.onFieldReset}
            resetFieldsNames={'name'}
          >
            <DropdownInput
              value={this.state.name}
              error={ _.get(this.state.errors, 'name', '') }
              showErrorAsTooltip={true}
              onChange={this.onValueChange('name', this.handleNameFieldValue)}
              placeholder="Instrumentenname, ISIN, oder WKN suchen"
              autoApprove={false}
              onValueApprove={this.props.onSearchFieldApprove}
            />
          </FilterSubSection>
        </FilterSection>
        <FilterSection
          title="Kennzahlen"
          sectionName="numbers"
          onResetClick={this.onValueReset}
          count={this.getChangedFiltersCount([['priceToEarningsStart', 'priceToEarningsEnd'], ['priceToBookStart', 'priceToBookEnd'], ['dividendYieldStart', 'dividendYieldEnd']])}
        >
          <FilterSubSection
            title="KGV" lg={6}
            resetButtonVisible={this.isFieldChanged(['priceToEarningsStart', 'priceToEarningsEnd'])}
            onResetClick={this.onFieldReset}
            resetFieldsNames={['priceToEarningsStart', 'priceToEarningsEnd']}
          >
            <div className={classes.inputsContainer}>
              <DropdownInput
                label="Von"
                value={this.state.priceToEarningsStart}
                error={ _.get(this.state.errors, 'priceToEarningsStart', '') }
                showErrorAsTooltip={true}
                onChange={this.onValueChange('priceToEarningsStart')}
                width={75}
                fullWidth={false}
                type="number"
                style={{marginRight: 10}}
              />
              <DropdownInput
                value={this.state.priceToEarningsEnd}
                error={ _.get(this.state.errors, 'priceToEarningsEnd', '') }
                showErrorAsTooltip={true}
                onChange={this.onValueChange('priceToEarningsEnd')}
                label="bis"
                width={75}
                type="number"
                fullWidth={false}
              />
            </div>
          </FilterSubSection>
          <Grid container spacing={2}>
          <FilterSubSection
            title="Kurs/Buch" lg={6}
            resetButtonVisible={this.isFieldChanged(['priceToBookStart', 'priceToBookEnd'])}
            onResetClick={this.onFieldReset}
            resetFieldsNames={['priceToBookStart', 'priceToBookEnd']}
          >
            <div className={classes.inputsContainer}>
              <DropdownInput
                label="Von"
                value={this.state.priceToBookStart}
                error={ _.get(this.state.errors, 'priceToBookStart', '') }
                showErrorAsTooltip={true}
                onChange={this.onValueChange('priceToBookStart')}
                width={75}
                fullWidth={false}
                type="number"
                style={{marginRight: 10}}
              />
              <DropdownInput
                value={this.state.priceToBookEnd}
                error={ _.get(this.state.errors, 'priceToBookEnd', '') }
                showErrorAsTooltip={true}
                onChange={this.onValueChange('priceToBookEnd')}
                label="bis"
                width={75}
                type="number"
                fullWidth={false}
              />
            </div>
          </FilterSubSection>
          <FilterSubSection
            title="Dividendenrendite (%)" lg={6}
            resetButtonVisible={this.isFieldChanged(['dividendYieldStart', 'dividendYieldEnd'])}
            onResetClick={this.onFieldReset}
            resetFieldsNames={['dividendYieldStart', 'dividendYieldEnd']}
          >
            <div className={classes.inputsContainer}>
              <DropdownInput
                label="Von"
                value={this.state.dividendYieldStart}
                error={ _.get(this.state.errors, 'dividendYieldStart', '') }
                showErrorAsTooltip={true}
                onChange={this.onValueChange('dividendYieldStart')}
                width={75}
                fullWidth={false}
                type="number"
                style={{marginRight: 10}}
              />
              <DropdownInput
                value={this.state.dividendYieldEnd}
                error={ _.get(this.state.errors, 'dividendYieldEnd', '') }
                showErrorAsTooltip={true}
                onChange={this.onValueChange('dividendYieldEnd')}
                label="bis"
                width={75}
                type="number"
                fullWidth={false}
              />
            </div>
          </FilterSubSection>
          </Grid>
        </FilterSection>
      </>
    );
  }
}

export default withStyles(styles)(MyComponent);
