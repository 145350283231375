const styles = theme => ({
  container: {
    marginTop: 15,
    padding: '30px 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 2,
    border: '3px dashed #ABB6BE',

    [theme.breakpoints.only('sm')]: {
      padding: 16
    },

    [theme.breakpoints.only('xs')]: {
      padding: 8
    },
  },
  message: {
    color: '#313D44',
    fontFamily: 'Roboto-Regular',
    fontSize: 22,

    [theme.breakpoints.only('sm')]: {
      fontSize: 20
    },

    [theme.breakpoints.only('xs')]: {
      fontSize: 18
    },
  }
});

export default styles;