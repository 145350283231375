const styles = {
  container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
  },
  text: {
    color: '#80858C',
  }
}

export default (theme) => ({
  ...styles,
  container: {
    ...styles.container,
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    left: 0,
    right: 0,
  }
})

export const customerDashboardStyles = () => ({
  ...styles
})