import {fontSizeTable, smFontSizeTable} from "../../../../../../utils/constants";

const styles = theme => ({
   root: {
     minHeight: 150,
     width: '100%'
   },
   container: {
      overflowX: 'auto'
   },
   tableAll: {
    width: '100%',
    borderCollapse: 'collapse',
    '& td': {
      padding: 5,
      verticalAlign: 'top',
      textAlign: 'right',
      [theme.breakpoints.up('md')]: {
        '&:first-child': {
          paddingLeft: 15
        }
      }
    },
    '& tbody tr': {
      borderBottom: 'solid 1px #D8DCDF',
      color: '#202A38',
      '&:last-of-type': {
        borderBottom: 'none'
      },
      '& td': {
        fontSize: fontSizeTable,
        fontFamily: 'Roboto-Regular',
        [theme.breakpoints.down('sm')]: {
          fontSize: smFontSizeTable
        },
      },
      '& td div.sub': {
        fontSize: smFontSizeTable,
        fontFamily: 'Roboto-Regular',
        color: '#80858C',
        [theme.breakpoints.down('sm')]: {
          fontSize: smFontSizeTable - 2
        },
      }
    }
  },
  tableHeader: {
    '& td': {
      fontFamily: 'Roboto-Bold !important',
      fontSize: fontSizeTable,
      color: '#80858C',

      [theme.breakpoints.down('sm')]: {
        fontSize: smFontSizeTable
      },
    },
    '& td:first-of-type': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 15,
      },
      textAlign: 'left',
      width: '30%'
    },
    '& td:nth-child(3)': {
      textAlign: 'left',
    },
    '& td:last-of-type': {
      width: '9%'
    },
    '& td div.sub': {
      fontFamily: 'Roboto-Regular !important',
    }
  },
  assetName: {
    fontFamily: 'Roboto-Bold',
    color: `${theme.palette.primary.main} !important`,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      background: 'none',
      textDecoration: 'underline !important'
    }
  },
  leftAligned: {
    textAlign: 'left !important'
  },
  ratingFilled: {
    color: theme.palette.primary.main
  },
  helpIcon: {
    marginLeft: 5,
    verticalAlign: 'baseline',
    color: theme.palette.primary.main,
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  benchmarkIcon: {
    display: 'inline-block',
    marginRight: 3
  },
  containerError: {
    height: 'calc(100% - 60px)',
  },
  emptyMessage: {
    margin: 'auto',
    fontSize: 22,
    color: '#b1b1b1',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  contentWrapper: {
    paddingTop: '0!important',
    height: '100%'
  }
})

export default styles;