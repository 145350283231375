import React from "react";
import useStyles from './styles';
/* BCA Components*/
import CumulativeReturnWidget
  from "../../../VirtualPortfolioPage/Tabs/CommonAnalysis/items/CumulativeReturnWidget";
import SinglePerformanceTable from "../../../VirtualPortfolioPage/Tabs/CommonAnalysis/items/SinglePerformanceTable";
import ChartPortfolioPerformanceForecast from "../../../VirtualPortfolioPage/Tabs/CommonAnalysis/items/ChartPortfolioPerformanceForecast";
import RankingPerformanceTable from "../../../VirtualPortfolioPage/Tabs/CommonAnalysis/items/RankingPerformanceTable";
import {Container, Grid} from "@material-ui/core";
import _ from 'lodash';
import {
    HistoricalChart,
    PerformanceBarChart,
    PerformanceLineChart,
    InstrumentList,
    ProfitLossList,
    PaymentPlans,
    DashboardSection,
  } from '../../../CustomerDashboard/components/Widgets/components';
import CustomerThemeProvider from '../../../../components/CustomerThemeProvider';
import { parseResponse } from '../../../../utils/api';

import {
  isPortfolioAvailableForPayoutPlan,
  isPortfolioAvailableForSavingsPlan,
  isPortfolioAvailableForSwitchPlan,
  tradingPossible,
} from "../../../CustomerDashboard/utils";
import {isSavingsPlansEnabled, isTradingEnabled} from '../../../../components/TradingStore/utils';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import WarningTooltip, {
  PORTFOLIO_LEVEL_DATA_ONLY_INFO_TEXT
} from "../../../../components/WarningTooltip";
import {getAssetsWithInactiveIds} from "../../utils";
import ExplanationTooltip from "../../../../components/ExplanationTooltip";
import useFetchData from "../../../../hooks/useDataFetch";
import moment from "moment";
import {MODEL_PORTFOLIO_TYPES} from "../../../Modelportfolios/constants";
import {useSelectedItems} from "../../../../hooks/useSelectedItems";
import Button from "@material-ui/core/Button";
import {BcaDeleteIcon, BcaEditIcon} from "../../../../images/BcaIcon";
import {buildDeleteOrderConfirmationMessage} from "../../../Modelportfolios/utils";
import ConfirmationDialog from "../../../VirtualPortfolioManager/modals/ConfirmationDialog";

import {isTransactionSelectable} from "../../../TransactionsMonitoring/utils";

const defaultDataBody = {
  data: undefined,
  loading: true,
  options: undefined,
  errors: undefined
};


const PerformanceTab = props => {
    const {
      portfolioId,
      customerId,
      investmentStrategyId,
      investmentStrategyModelPortfolioConnectId,
      banksMappingData,
      goToTradeDetails,
      handleAddTradingOption,
      handleAddSavingPlanOption,
      handleAddPortfolioTradingOption,
      handleAddPortfolioSavingPlanOption,
      onAddPayoutPlanOption,
      onAddPortfolioPayoutPlanOption,
      onAddSwitchPlanOption,
      onAddPortfolioSwitchPlanOption,
      payoutPlansData,
      switchPlansData,
      handleChartSettingsChange,
      selectedDates,
      instrumentList,
      paymentPlans,
      profitAndLoss,
      twrData,
      isCustomerApp,
      chartsSettings,
      onExpandedItemsChange,
      expandedItems,
      dataProvider,
      banksData,
      customerData,
      customSettings,
      handleShowChartSection,
      unrealizedProfitAndLossData,
      selectedAssets,
      inactiveAssets,
      isAllAssetsSelected,
      isProfessionalViewAssetsEnabled,
      combinedTradingSession,
      combinedTradings,
      showEmptyAssetsCheckbox,
      withTransactionsOverview,
      viewOnly,
      refreshDataTrigger
    } = props;

    const requestAssets = isProfessionalViewAssetsEnabled
      ? !isAllAssetsSelected
        ? selectedAssets
        : []
      : undefined

    const withHistoricalPortfolios = chartsSettings.global.withHistoricalPortfolios
    const classes = useStyles();
    const [performanceTimeseries, setPerformanceTimeseries] = React.useState(defaultDataBody);
    const [performanceForecast, setPerformanceForecast] = React.useState(defaultDataBody);
    const [rankingAndSinglePerformance, setRankingAndSinglePerformance] = React.useState(defaultDataBody);

    const [historicalData, setHistoricalData] = React.useState({...defaultDataBody, timestamp: null});
    const [historicalChartData, setHistoricalChartData] = React.useState({...defaultDataBody, timestamp: null});
    const [transactionsData, _fetchTransactionsData] = useFetchData(
    `${dataProvider.resourceUrl}customer/${customerId}/transactions/`, 'post');

    const selectedOrders = useSelectedItems();
    const [transactionToDelete, setTransactionToDelete] = React.useState(undefined);
    const [transactionEditInProgress, setTransactionEditInProgress] = React.useState(false);
    const [transactionDeleteInProgress, setTransactionDeleteInProgress] = React.useState(false);

    const showPortfolioLevelDataInfoIcon = selectedAssets && selectedAssets.length > 0 && !isAllAssetsSelected;

    const handleOnExpandedChange = (expandedItem) => (value) => {
      onExpandedItemsChange(expandedItem, value)  // handleExpandedItemsChange
    }

    /**
      * Update state data and loading flag after.
      * To prevent show empty content between two loading states
      * @param {Object} data
      * @param {function} setMethod
      */
     const updateDataAndLoadingAfter = (data, setMethod) => {
      if(_.isFunction(setMethod) && _.isObject(data)){
        setMethod({...data, loading: true});
        setTimeout(() => {
          setMethod({...data, loading: false});
        }, 100);
      }
    }

    const fetchHistoricalData = async (dates) => {

      setHistoricalData({
        ...historicalData,
        loading: true
      });
      setHistoricalChartData({
        ...historicalData,
        loading: true
      });

      try {

        let _requestedAssets = getAssetsWithInactiveIds(
          selectedAssets, inactiveAssets, isAllAssetsSelected,
          chartsSettings.historical.withEmptyAssets);

        const inactiveAssetsIds = chartsSettings.historical.withEmptyAssets
          ? (inactiveAssets || []).map((asset) => asset.id) : undefined;

        let response = await dataProvider.getHistoricalData(
          customerId, [portfolioId], dates.start, dates.end,
          withHistoricalPortfolios, _requestedAssets,
          Boolean(isProfessionalViewAssetsEnabled), investmentStrategyId, false, true, true, true, inactiveAssetsIds);

        parseResponse(response, 'historical', (data) => {
          const _data = {
            data: {
              portfolios: data || []
            },
            loading: true,
            errors: undefined,
            timestamp: new Date().getTime()
          };
          updateDataAndLoadingAfter(_data, setHistoricalData);
          updateDataAndLoadingAfter({
            data: {
              portfolios: data
                ? isProfessionalViewAssetsEnabled
                  ? _.get(data.find((portfolio) => portfolio.id === portfolioId), 'components_history')
                  : data
                : []
            },
            loading: true,
            errors: undefined,
            timestamp: new Date().getTime()
          }, setHistoricalChartData);
        }, (errors) => {
          setHistoricalData({
            data: undefined,
            loading: false,
            errors: errors,
            timestamp: null
          });
          setHistoricalChartData({
            data: undefined,
            loading: false,
            errors: errors,
            timestamp: null
          });
        });

      }
      catch(errors) {
        if (errors.status === 403) {
          // handleUnauthorized();
        }
        setHistoricalData({
          data: undefined,
          loading: false,
          errors,
          timestamp: null
        })
        setHistoricalChartData({
          data: undefined,
          loading: false,
          errors,
          timestamp: null
        });
      }
    };

    const fetchPerfomanceData = async () => {

        await dataProvider.getPortfolioPerformance(
          customerId, portfolioId, withHistoricalPortfolios, requestAssets, investmentStrategyId).then((data) => {
          setPerformanceTimeseries({
              errors: _.isEmpty(data.error) ? null : data.error,
              data: data['cum_returns'].data,
              loading: false
            })
          })
          .catch((error) => {
            setPerformanceTimeseries({
              errors: error,
              loading: false,
              data: false
            })
          });

        let response;
        try {
          response = await dataProvider.getPortfolioExtendedAssetData(customerId, investmentStrategyModelPortfolioConnectId || portfolioId, requestAssets);
          /** Parse extended_asset_data data */
          parseResponse(response, 'extended_asset_data', (data) => {
            setRankingAndSinglePerformance({
              data: data,
              loading: false,
              errors: undefined
            });
          }, (errors) => {
            setRankingAndSinglePerformance({
              data: undefined,
              loading: false,
              errors: errors
            });
          }, false);

        } catch (errors){
          setRankingAndSinglePerformance({
            data: undefined,
            loading: false,
            errors: errors
          });
        }

        try{
          response = await dataProvider.getPortfolioForecast(customerId, portfolioId, requestAssets, investmentStrategyId);
          /** Parse extended_asset_data data */
          parseResponse(response, 'performance_forecast', (data) => {
            setPerformanceForecast({
              data: data,
              loading: false,
              errors: undefined
            });
          }, (errors) => {
            setPerformanceForecast({
              data: undefined,
              loading: false,
              errors: errors
            });
          });

        } catch (errors){
          setPerformanceForecast({
            data: undefined,
            loading: false,
            errors: errors
          });
        }
    }

    const trackingStartDate = (() => {

      if (!!props.modelPortfolio) {
        return _.get(props.modelPortfolio, 'data.tracking_start_date')
      }

      // TODO: For now it works for one portfolio only.
      //  In case it will be required to connect this functionality
      //  to multiple depots selection - this part should be updated.
      const portfolio = _.get(props.investmentData, 'data.portfolios.0');

      return portfolio && portfolio.tracking_start_date

    })();

    const fetchTransactionsData = async () => {

      selectedOrders.cleanSelected();

      let [startDate, endDate] = [selectedDates.start, selectedDates.end]

      _fetchTransactionsData({
        start_date: !!startDate ? startDate.format('YYYY-MM-DD') : undefined,
        end_date: moment(endDate).format('YYYY-MM-DD'),
        instruments: [],
        portfolios: [],
        transaction_types: [],
        instrument_types: [],
        with_transaction_types: true,
        group_related: true
      });
    }

    const fetchData = async() => {
      const dates = {
        start: selectedDates.start && selectedDates.start.format('YYYY-MM-DD') || undefined,
        end: selectedDates.end && selectedDates.end.format('YYYY-MM-DD') || undefined,
      };
      fetchPerfomanceData();
      fetchHistoricalData(dates);
      if (withTransactionsOverview) {
        fetchTransactionsData()
      }
    };

    React.useEffect(() => {
      if (!isProfessionalViewAssetsEnabled || !_.isEmpty(selectedAssets) || !!refreshDataTrigger) {
        fetchData();
      }
    }, [
      refreshDataTrigger,
      selectedDates,
      JSON.stringify(chartsSettings.global),
      JSON.stringify(chartsSettings.performance),
      JSON.stringify(chartsSettings.historical),
      JSON.stringify(selectedAssets),
      JSON.stringify(inactiveAssets)]);  // JSON.stringify prevents infinite loop

    const isPaymentPlansDataExist = () => {
      return paymentPlans.data && paymentPlans.data.length > 0;
    };
    const isProfitLossDataExist = () => {
      return profitAndLoss.data && profitAndLoss.data.length > 0;
    };
    const isInstrumentsDataExist = () => {
      return instrumentList.data && instrumentList.data.some(portfolio => {
        return (_.isArray(portfolio.components) && portfolio.components.length > 0)
          || (_.isArray(portfolio.clearing_account) && portfolio.clearing_account.length > 0);
      });
    };


    const canTradePortfolios = tradingPossible(instrumentList.data, banksMappingData);

    const getSavingPlanTradeOptions = (savingsPlan) => {
      if(isSavingsPlansEnabled() && isPortfolioAvailableForSavingsPlan(savingsPlan, instrumentList.data, banksMappingData)){
        return {
          onAddSavingPlanOption: handleAddSavingPlanOption,
          onAddPortfolioSavingPlanOption: handleAddPortfolioSavingPlanOption,
          savingPlans: combinedTradings,
          savingsPlanSession: combinedTradingSession,
        }
      }
    };

    const getPayoutPlanTradeOptions = (savingsPlan) => {
      if(isSavingsPlansEnabled() && isPortfolioAvailableForPayoutPlan(savingsPlan, instrumentList.data, banksMappingData, banksData)){
        return {
          onAddPayoutPlanOption,
          onAddPortfolioPayoutPlanOption,
          payoutPlans: combinedTradings,
          payoutPlanSession: combinedTradingSession,
        }
      }
    };

    const getSwitchPlanTradeOptions = (savingsPlan) => {
      if(isSavingsPlansEnabled() && isPortfolioAvailableForSwitchPlan(savingsPlan, instrumentList.data, banksMappingData, banksData)){
        return {
          onAddSwitchPlanOption,
          onAddPortfolioSwitchPlanOption,
          switchPlans: combinedTradings,
          switchPlanSession: combinedTradingSession,
        }
      }
    };

    const allSelectableOrders = _.filter(
      _.get(transactionsData, 'data.orders.data.0') || [], (item) => isTransactionSelectable(item));
    const allOrdersSelected = allSelectableOrders.length === Object.values(selectedOrders.selectedItems).length;

    const handleEditOrderClick = async (order) => {
      setTransactionEditInProgress(true);
      props.handleEditOrderClick && await props.handleEditOrderClick(_.isArray(order) ? order : [order]);
      setTransactionEditInProgress(false);
    }

    const handleDeleteOrderClick = async (order, useConfirmation=false) => {
      setTransactionDeleteInProgress(true);
      props.handleDeleteOrderClick && await props.handleDeleteOrderClick(
        _.isArray(order) ? order : [order], false, useConfirmation,
        allOrdersSelected);
      setTransactionDeleteInProgress(false);
      setTransactionToDelete(undefined);
    }

    const handleAddTransactionToDelete = async (order) => setTransactionToDelete(order);

    const handleSelectAllOrders = () => {
      if (allOrdersSelected) {
        selectedOrders.handleUnselectMany(allSelectableOrders);
      } else {
        selectedOrders.handleSelectMany(allSelectableOrders);
      }
    }

    const handleSelectOrder = (order) => {
      selectedOrders.handleToggleItem(order);
    }

    return (
      <Grid id="proView" container spacing={2} className={classes.dashboardCardContainer}>
        {!investmentStrategyId && handleShowChartSection(customSettings, 'historicalChart') &&
          <Grid item xs={12} id="historical-chart">
            <CustomerThemeProvider>
              <HistoricalChart
                  dashboardData={historicalChartData.data}
                  dashboardDataLoading={historicalChartData.loading}
                  dashboardDataLoadingError={historicalChartData.errors}
                  dashboardDataOptions={historicalData.options}
                  timestamp={historicalChartData.timestamp}
                  onChartSettingsChange={handleChartSettingsChange}
                  expanded={expandedItems.historicalChart}
                  onExpanded={handleOnExpandedChange("historicalChart")}
                  showInvestmentCapital={chartsSettings.historical.withInvestiertesAmount}
                  hideControl={!isProfessionalViewAssetsEnabled}
                  aggregateSelectedPortfolios={chartsSettings.historical.aggregateSelected}
                  showEmptyAssets={chartsSettings.historical.withEmptyAssets}
                  showEmptyAssetsCheckbox={showEmptyAssetsCheckbox}
                  TransactionsOverviewProps={{
                    enabled: withTransactionsOverview,
                    expanded: expandedItems.transactionsOverview,
                    onExpanded: handleOnExpandedChange("transactionsOverview"),
                    editable:viewOnly,
                    data: transactionsData,
                    validateTransactionsExecutionDate: [
                      MODEL_PORTFOLIO_TYPES.PUBLIC,
                      MODEL_PORTFOLIO_TYPES.CUSTODIAN_AGENCY].includes(
                        _.get(props.modelPortfolio, 'data.access_type')),
                    onEditClick: handleEditOrderClick,
                    onDeleteClick: handleAddTransactionToDelete,
                    onSelect: handleSelectOrder,
                    onAllSelect: handleSelectAllOrders,
                    selected: Object.values(selectedOrders.selectedItems),
                    dataProvider,
                    trackingStartDate,
                    customerId,
                    deleteInProgress: transactionDeleteInProgress,
                    editInProgress: transactionEditInProgress,
                    totalSelectableTransactionsCount: allSelectableOrders.length
                  }}
              />
              {Object.values(selectedOrders.selectedItems).length > 0 && (
                <div className={classes.footerContainer}>
                  <Container className={classes.footerContentContainer}>
                    <Grid container alignItems={"center"} justify={"flex-end"}>
                      <Button
                        variant="contained"
                        className={classes.footerButton}
                        disabled={transactionDeleteInProgress || transactionEditInProgress}
                        onClick={() => handleEditOrderClick(Object.values(selectedOrders.selectedItems))}
                      >
                        <BcaEditIcon variant={"far"}/> <span>Ausgewählte bearbeiten</span>
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.footerButton}
                        disabled={transactionDeleteInProgress || transactionEditInProgress}
                        onClick={() => setTransactionToDelete(Object.values(selectedOrders.selectedItems))}
                      >
                        <BcaDeleteIcon variant={"far"} /> <span>Ausgewählte löschen</span>
                      </Button>
                    </Grid>
                  </Container>
                </div>
              )}
              <ConfirmationDialog
                message={buildDeleteOrderConfirmationMessage(transactionToDelete || {})}
                open={!!transactionToDelete || transactionDeleteInProgress}
                onClose={() => !transactionDeleteInProgress && setTransactionToDelete(undefined)}
                loading={transactionDeleteInProgress}
                confirm={() => handleDeleteOrderClick(transactionToDelete)}
              />
            </CustomerThemeProvider>
          </Grid>
        }
        {handleShowChartSection(customSettings, 'performanceLineChart') &&
          <Grid item xs={12} id="performance-chart">
            <CustomerThemeProvider>
              <PerformanceLineChart
                dashboardData={twrData.data}
                dashboardDataLoading={twrData.loading}
                dashboardDataLoadingError={twrData.errors}
                dashboardDataOptions={twrData.options}
                calculationDates={selectedDates}
                benchmarkConfigurationEnabled
                timestamp={twrData.timestamp}
                expanded={expandedItems.performanceLineChart}
                onExpanded={handleOnExpandedChange("performanceLineChart")}
                showBenchmark={chartsSettings.performance.withBenchmark}
                onChartSettingsChange={handleChartSettingsChange}
                hideControl
                showSingleDepot
                chartsSettings={chartsSettings.performance}
                title={(
                  <span>
                    Zeitgewichtete Rendite in Prozent
                    {showPortfolioLevelDataInfoIcon && (
                      <WarningTooltip title={PORTFOLIO_LEVEL_DATA_ONLY_INFO_TEXT} iconClassName={classes.infoIcon}/>
                    )}
                  </span>
                )}
              />
            </CustomerThemeProvider>
          </Grid>
        }
        {handleShowChartSection(customSettings, 'performanceBarChart') &&
          <Grid item xs={12} id="performance-bar-chart">
            <CustomerThemeProvider>
              <PerformanceBarChart
                dashboardData={historicalData.data}
                dashboardDataLoading={historicalData.loading}
                dashboardDataLoadingError={historicalData.errors}
                timestamp={historicalData.timestamp}
                expanded={expandedItems.performanceBarChart}
                onExpanded={handleOnExpandedChange("performanceBarChart")}
                title={(
                  <span>
                    Jährliche Wertentwicklung der Depots der letzten 5 Jahre in Prozent
                    <ExplanationTooltip
                      tooltipKey={'yearly_performance_bar_chart'}
                      additionalText={showPortfolioLevelDataInfoIcon && PORTFOLIO_LEVEL_DATA_ONLY_INFO_TEXT}
                      customClasses={{helpIcon: classes.infoIcon}}
                    />
                  </span>
                )}
              />
            </CustomerThemeProvider>
          </Grid>
        }
        {handleShowChartSection(customSettings, 'cumulativeReturnChart') &&
          <Grid item xs={12} id="cumulative-return-chart">
            <CumulativeReturnWidget
              data={performanceTimeseries.data}
              loading={performanceTimeseries.loading}
              error={performanceTimeseries.errors}
              expanded={expandedItems.cumulativeReturnChart}
              onExpanded={handleOnExpandedChange("cumulativeReturnChart")}
            />
          </Grid>
        }
        {!isCustomerApp && handleShowChartSection(customSettings, 'performanceTable') &&
          <Grid item xs={12}>
            <SinglePerformanceTable
              data={rankingAndSinglePerformance.data}
              loading={rankingAndSinglePerformance.loading}
              error={rankingAndSinglePerformance.errors}
              order='asc'
              orderBy='weight'
              expanded={expandedItems.performanceTable}
              onExpanded={handleOnExpandedChange("performanceTable")}
              infoDescription={"Die folgende Tabelle zeigt die Performance der einzelnen Titel, abhängig von der Haltedauer"}
            />
          </Grid>
        }
        {handleShowChartSection(customSettings, 'instrumentsItems') &&
        <Grid item xs={12}>
          <CustomerThemeProvider>
            <DashboardSection
              title={null}
              content={
                <InstrumentList
                  data={instrumentList.data}
                  expandedItems={expandedItems.instrumentsItems}
                  onExpandedItemsChange={(items) => onExpandedItemsChange('instrumentsItems', items)}
                  expandedSubItems={expandedItems.instrumentsSubItems}
                  showCell={(cellKey) => handleShowChartSection(customSettings, `instrumentsItems.subcategories.${cellKey}`)}
                  onExpandedSubItemsChange={(items) => onExpandedItemsChange('instrumentsSubItems', items)}
                  onAddTradingOption={handleAddTradingOption}
                  onAddPortfolioTradingOption={handleAddPortfolioTradingOption}
                  tradings={combinedTradings}
                  customerData={customerData}
                  banksMapping={banksMappingData}
                  tradingSession={combinedTradingSession}
                  isCustomerApp={isCustomerApp}
                  canTradePortfolios={canTradePortfolios}
                  unrealizedProfitAndLossData={unrealizedProfitAndLossData}
                  expandedUnrealizedProfitAndLossItems={expandedItems.unrealizedProfitAndLossItems}
                  onExpandedUnrealizedProfitAndLossItemsChange={(items) =>onExpandedItemsChange('unrealizedProfitAndLossItems', items)}
                  withTransactionSaldo={chartsSettings.global.withTransactionSaldo}
                  portfolioNameRender={(portfolio) => `${portfolio.name}`}
                  showSubSectionTitle={false}
                />
              }
              loading={instrumentList.loading}
              error={instrumentList.errors}
              empty={!isInstrumentsDataExist()}
              displayError={true}
              titleControl={
                <>
                  {!isCustomerApp && isTradingEnabled() && !instrumentList.loading && (
                    <PrimaryButton
                      icon={<i className="far fa-dollar-sign fa-xs"
                               style={{color: canTradePortfolios ? 'white' : 'rgba(0, 0, 0, 0.26)'}}></i>}
                      text={!!combinedTradingSession ? "ORDER FORTSETZEN" : "ORDER"}
                      customClasses={{
                        root: classes.buttonRoot
                      }}
                      disabled={!canTradePortfolios}
                      onButtonClick={(event) => goToTradeDetails(event)}
                    />
                  )}
                </>
              }
            />
          </CustomerThemeProvider>
        </Grid>
        }

        {!isCustomerApp && handleShowChartSection(customSettings, 'forecastChart') &&
          <Grid item xs={12}>
            <ChartPortfolioPerformanceForecast
              data={performanceForecast.data}
              loading={performanceForecast.loading}
              error={performanceForecast.errors}
              expanded={expandedItems.forecastChart}
              onExpanded={handleOnExpandedChange(customSettings, "forecastChart")}
            />
          </Grid>
        }

        {chartsSettings.global.withPaymentPlans &&
          <Grid item xs={12}>
            <CustomerThemeProvider>
              <DashboardSection
                title={'Zahlpläne'}
                content={
                  <PaymentPlans
                    paymentPlans={paymentPlans.data}
                    switchPlans={switchPlansData.data}
                    payoutPlans={payoutPlansData.data}
                    paymentPlansExpandedItems={expandedItems.paymentPlansItems}
                    switchPlansExpandedItems={expandedItems.switchPlansItems}
                    payoutPlansExpandedItems={expandedItems.payoutPlansItems}
                    onExpandedItemsChange={(items, key) => onExpandedItemsChange(key, items)}
                    getSavingPlanTradeOptions={getSavingPlanTradeOptions}
                    getPayoutPlanTradeOptions={getPayoutPlanTradeOptions}
                    getSwitchPlanTradeOptions={getSwitchPlanTradeOptions}
                  />
                }
                loading={paymentPlans.loading || payoutPlansData.loading || switchPlansData.loading}
                error={paymentPlans.errors || payoutPlansData.errors || switchPlansData.errors}
                empty={false}
                displayError={true}
              />
            </CustomerThemeProvider>
          </Grid>
        }

        {chartsSettings.global.withProfitLoss &&
          <Grid item xs={12}>
            <CustomerThemeProvider>
              <DashboardSection
                title='Vollständig verkaufte Vermögenswerte'
                content={
                  <ProfitLossList
                    data={profitAndLoss}
                    expandedItems={expandedItems.profitAndLossItems}
                    onExpandedItemsChange={(items) => onExpandedItemsChange('profitAndLossItems', items)}
                    expandedSubItems={expandedItems.profitAndLossSubItems}
                    onExpandedSubItemsChange={(items) => onExpandedItemsChange('profitAndLossSubItems', items)}
                  />
                }
                loading={profitAndLoss.loading}
                error={profitAndLoss.errors}
                empty={!isProfitLossDataExist()}
                displayError={true}
              />
            </CustomerThemeProvider>
          </Grid>
        }

        {!isCustomerApp &&  handleShowChartSection(customSettings, 'singlePerformance') &&
          <Grid item xs={12}>
            <RankingPerformanceTable
              data={rankingAndSinglePerformance.data}
              loading={rankingAndSinglePerformance.loading}
              error={rankingAndSinglePerformance.errors}
              order='asc'
              orderBy='name'
              expanded={expandedItems.singlePerformance}
              onExpanded={handleOnExpandedChange(customSettings, "singlePerformance")}
            />
          </Grid>
        }
      </Grid>
    )
}

export default PerformanceTab;
