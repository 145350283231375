import React from 'react';
import _ from 'lodash';
import {Grid} from "@material-ui/core";
import {ChartSectionBordered} from "../ChartSection/ChartSection";
import useMetricsData from "../../hooks/useMetricsData";
import FondsKenzahlenTable from "../FondsKenzahlenTable/FondsKenzahlenTable";

function KeyFiguresTab(props) {

  const metricsData = useMetricsData(props.selectedProducts, props.selectedDates, props.selectedBenchmarks, props.performanceData);
  const [expandedSections, setExpandedSections] = React.useState({...props.expandedSectionsRef.current.keyFiguresTab});
  React.useEffect(() => {
    setExpandedSections({...props.expandedSectionsRef.current.keyFiguresTab})
  },[props.expandedSectionsRef.current])

  const onExpanded = (sectionKey) => () => {
    setExpandedSections((current) => {
      let newState = !current[sectionKey]
      props.expandedSectionsRef.current.keyFiguresTab[sectionKey] = newState
      return {
        ...current,
        [sectionKey]: newState
      }
    })
  }

  return (
    <>
      <Grid item xs={12}>
        <ChartSectionBordered
          title={(
            <b>Kennzahlen</b>
          )}
          loading={metricsData.loading}
          errors={metricsData.errors}
          displayError
          expanded={expandedSections.keyFiguresTable}
          onExpanded={onExpanded("keyFiguresTable")}
          content={
            (<FondsKenzahlenTable products={metricsData.data || []} options={{hasBenchmarks: !_.isEmpty(props.selectedBenchmarks)}} />)
          }
        />
      </Grid>
    </>
  )
}

export default KeyFiguresTab;