import React from "react";
import {Grid, Typography, withStyles} from "@material-ui/core";
import styles from "./styles";
import ExclusionCriteriaCards from "./cards/index";

import {
  FIrst1,
  First2,
  First2Dark,
  First3,
  First3Dark,
  First4,
  First4Dark,
  Second1,
  Second2,
  Second2Dark,
  Second3,
  Second3Dark,
  Third1,
  Third2,
  Third2Dark,
  Third3,
  Third3Dark,
  Third4,
  Third4Dark,
  Fourth1,
  Fourth2,
  Fourth2Dark,
  Fourth3,
  Fourth3Dark,
  Fourth4,
  Fourth4Dark,
  Fourth5,
  Fourth5Dark,
  Fourth6,
  Fourth6Dark,
  Fourth7,
  Fourth7Dark,
  Fourth8,
  Fourth8Dark,
} from "../../../../../../images";

import {
  First1S,
  First2S,
  First3S,
  First4S,
  First5S,
  Second1S,
  Second2S,
  Second3S,
  Second4S,
  Third1S,
  Third2S,
  Third3S,
  Third4S,
  Fourth1S,
  Fourth2S,
  Fourth3S,
  Fourth4S,
  Fourth5S,
  Fourth6S,
  Fourth7S,
} from "../../../../../../images";
import WarningTooltip from "../../../../../../components/WarningTooltip";
import LoadingIndicator from "../../../../../../components/LoadingIndicator/LoadingIndicator";
import GenModifPlants from '../../../../../../images/FactsheetSustainabilityTab/Exclusion/Second/GenModifPlants';
import NuclWeapons from '../../../../../../images/FactsheetSustainabilityTab/Exclusion/Second/NuclWeapons';


const dataFirst = [
  {
    text: "Waffen und Rüstungsproduktion",
    value: 1,
    main: true,
    dataField: ['unconv_weapons_excl', 'nucl_weapons_excl', 'conv_weapons_excl'],
    icon: <FIrst1 />,
    info: 'Dieses Kriterium umfasst die Herstellung umstrittener Waffen, die Nuklearwaffenproduktion, sowie Rüstungsgüter allgemein.'
  },
  {
    text: "Umstrittene Waffenproduktion",
    value: 2,
    main: false,
    icon: <First2 />,
    dataField: 'unconv_weapons_excl',
    iconDark: <First2Dark />,
  },
  {
    text: "Rüstungsgüterproduktion",
    value: 3,
    main: false,
    icon: <First3 />,
    dataField: 'conv_weapons_excl',
    iconDark: <First3Dark />,
  },
  {
    text: "Nuklearwaffenproduktion",
    value: 4,
    main: false,
    icon: <First4 />,
    dataField: 'nucl_weapons_excl',
    iconDark: <First4Dark />,
  },
];
const dataSecond = [
  {
    text: "Ökologisch und sozial schädliche Geschäftsmodelle",
    value: 5,
    main: true,
    icon: <Second1 />,
    dataField: ['animal_testing_excl', 'fur_excl'],
    info: 'Unter diesem Kriterium sind Institutionen, welche Tierversuche durchführen und Firmen, welche sich der Pelz- und Ledergewinnung widmen, gefasst.'
  },
  {
    text: "Tierversuche",
    value: 6,
    main: false,
    icon: <Second2 />,
    dataField: 'animal_testing_excl',
    iconDark: <Second2Dark />,
  },
  {
    text: "Pelz- und Ledergewinnung",
    value: 7,
    main: false,
    icon: <Second3 />,
    dataField: 'fur_excl',
    iconDark: <Second3Dark />,
  },
];
const dataThird = [
  {
    text: "Ethisch oder religiös anstößige Geschäftsmodelle",
    value: 8,
    main: true,
    icon: <Third1 />,
    dataField: ['porno_ent_excl', 'gambling_excl', 'research_on_human_embryos_excl'],
    info: 'Unter dieses Kriterium fallen die Anbieter von Erwachsenenunterhaltung und Glücksspielprodukten sowie Institutionen, welche an menschlichen Embryonalzellen forschen.'
  },
  {
    text: "Erwachsenenunterhaltung (Pornographie)",
    value: 9,
    main: false,
    icon: <Third2 />,
    iconDark: <Third2Dark />,
    dataField: 'porno_ent_excl',
  },
  {
    text: "Anbieter von Glücksspielprodukten",
    value: 10,
    main: false,
    icon: <Third3 />,
    iconDark: <Third3Dark />,
    dataField: 'gambling_excl',
  },
  {
    text: "Forschung an menschlichen Embryonalzellen",
    value: 11,
    main: false,
    icon: <Third4 />,
    iconDark: <Third4Dark />,
    dataField: 'research_on_human_embryos_excl',
  },
];
const dataFourth = [
  {
    text:
      "Ökologisch bedenkliche Förderung sowie Einsatz von Energieträgern",
    value: 12,
    main: true,
    icon: <Fourth1 />,
    info: 'In dieser Gruppe finden sich Produzenten von Endenergie aus der thermalen Verwertung von Kohle sowie entsprechende Energieträger und Hersteller von Anlagen, welche diesen Zwecken dienen, Produzenten in der Öl- und Gasförderung sowie Hersteller fossiler Treibstoffprodukte und Hersteller neuer Infrastruktur zur fossilen Treibstoffproduktion.',
    dataField: ['nucl_energy_excl', 'coal_excl', 'gas_excl', 'oil_excl', 'gas_new_struct_excl', 'fuel_new_struct_excl', 'fuel_excl']
  },
  {
    text: "Atomenergie",
    value: 13,
    main: false,
    icon: <Fourth2 />,
    iconDark: <Fourth2Dark />,
    dataField: 'nucl_energy_excl',
  },
  {
    text: "Einsatz von Kohle als Energieträger",
    value: 14,
    main: false,
    icon: <Fourth3 />,
    iconDark: <Fourth3Dark />,
    dataField: 'coal_excl',
  },
  {
    text: "Einsatz von Gas als Energieträger",
    value: 15,
    main: false,
    icon: <Fourth4 />,
    iconDark: <Fourth4Dark />,
    dataField: 'gas_excl',
  },
  {
    text: "Einsatz von Öl als Energieträger",
    value: 16,
    main: false,
    icon: <Fourth5 />,
    iconDark: <Fourth5Dark />,
    dataField: 'oil_excl',
  },
  {
    text: "Neue Gas Infrastruktur",
    value: 17,
    main: false,
    icon: <Fourth6 />,
    iconDark: <Fourth6Dark />,
    dataField: 'gas_new_struct_excl',
  },
  {
    text: "Neue Infrastruktur für andere fossile Treibstoffproduktion",
    value: 18,
    main: false,
    icon: <Fourth7 />,
    iconDark: <Fourth7Dark />,
    dataField: 'fuel_new_struct_excl',
  },
  {
    text: "Andere fossile Treibstoffproduktion",
    value: 19,
    main: false,
    icon: <Fourth8 />,
    iconDark: <Fourth8Dark />,
    dataField: 'fuel_excl',
  },
];

const dataFirstS = [
  {
    text: "Umweltschädigende Praktiken der Agrarproduktion",
    value: 20,
    main: true,
    icon: <First1S />,
    dataField: ['factory_farm_excl', 'gen_engin_excl', 'gmo_excl', 'palm_oil_excl', 'pesticides_excl'],
    info: 'Hierunter sind Firmen gefasst, welche in der industriellen Produktion landwirtschaftlicher Güter tätig sind, die Hersteller von Palmöl, die Hersteller und Verwender von Pestiziden sowie Institutionen, welche sich der Erzeugung und Züchtung sowie dem Einsatz genetisch modifizierter Pflanzen und Samen widmen.'
  },
  {
    text: "Industrielle Landwirtschaft",
    value: 21,
    main: false,
    icon: <First2S />,
    dataField: 'factory_farm_excl',
  },
  {
    text: "Forschung an genetisch modifizierten Pflanzen und Samen",
    value: 21,
    main: false,
    icon: <GenModifPlants />,
    dataField: 'gen_engin_excl',
  },
  {
    text: "Genetisch modifizierte Organismen",
    value: 22,
    main: false,
    icon: <First3S />,
    dataField: 'gmo_excl',
  },
  {
    text: "Palmöl",
    value: 23,
    main: false,
    icon: <First4S />,
    dataField: 'palm_oil_excl',
  },
  {
    text: "Pestizide",
    value: 24,
    main: false,
    icon: <First5S />,
    dataField: 'pesticides_excl',
  },
];

const dataSecondS = [
  {
    text: "Erzeugung von gesundheitsschädlichen Gütern",
    value: 24,
    main: true,
    icon: <Second1S />,
    dataField: ['tobacco_excl', 'alco_excl', 'cannabis_excl'],
    info: 'Als gesundheitsschädliche Güter werden Tabak und alkoholische Getränke angesehen. Firmen, welche sich mit der Herstellung und dem Angebot dieser Produkte befassen, werden mit dieser Gruppe ausgeschlossen.'
  },
  {
    text: "Tabak",
    value: 25,
    main: false,
    icon: <Second2S />,
    dataField: 'tobacco_excl',
  },
  {
    text: "Alkoholische Getränke",
    value: 26,
    main: false,
    icon: <Second3S />,
    dataField: 'alco_excl',
  },
  {
    text: "Cannabisproduktion",
    value: 27,
    main: false,
    icon: <Second4S />,
    dataField: 'cannabis_excl',
  },
];

const dataThirdS = [
  {
    text: "Normverletzungen",
    value: 28,
    main: true,
    icon: <Third1S />,
    dataField: ['compact_excl', 'oecd_guidelines_excl', 'inter_labour_org_excl'],
    info: 'Länder bzw. Firmen mit Sitz in Ländern, welche sich nicht an international kodifizierte Regeln der UNGC, OECD oder ILO halten, werden mit diesem Kriterium ausgeschlossen.'
  },
  {
    text: "Verletzung der UN Global Compact Prinzipien",
    value: 29,
    main: false,
    icon: <Third2S />,
    dataField: 'compact_excl',
  },
  {
    text: "Verletzung der OECD Richtlinien",
    value: 30,
    main: false,
    icon: <Third3S />,
    dataField: 'oecd_guidelines_excl',
  },
  {
    text: "Verletzung der ILO Kernarbeitsnormen",
    value: 31,
    main: false,
    icon: <Third4S />,
    dataField: 'inter_labour_org_excl',
  },
];

const dataFourthS = [
  {
    text: "Ausschlusskriterien auf der Ebene hoheitlicher Körperschaften",
    value: 32,
    main: true,
    icon: <Fourth1S />,
    dataField: ['nucl_weapons_excl_sh', 'non_pt_of_nucl_weapons_ex_sh', 'qualitative_ex_sh', 'corruption_ex_sh', 'death_sentence_ex_sh', 'war_ex_sh', 'non_ratific_of_paris_agreement_ex_sh'],
    info: 'Hoheitliche Körperschaften, welche eines der folgenden Merkmale erfüllen, werden ausgeschlossen: Verfügen über einsatzbereite Atomwaffen, keine Teilnahme an Abkommen zur Nichtweiterverbreitung von Atomwaffen, hohe Korruption, kein Verbot der Todesstrafe, keine Zusage Krieg als außenpolitisches Instrument auszuschließen.'
  },
  {
    text: "Im Besitz von Atomwaffen",
    value: 33,
    main: false,
    icon: <NuclWeapons />,
    dataField: 'nucl_weapons_excl_sh',
  },
  {
    text: "Nichtanerkennung der Nonprolieferation für Nuklearwaffen",
    value: 33,
    main: false,
    icon: <Fourth2S />,
    dataField: 'non_pt_of_nucl_weapons_ex_sh',
  },
  {
    text: "Qualitativer Ausschluss",
    value: 34,
    main: false,
    icon: <Fourth3S />,
    dataField: 'qualitative_ex_sh',
  },
  {
    text: "Verbreitete Korruption",
    value: 35,
    main: false,
    icon: <Fourth4S />,
    dataField: 'corruption_ex_sh',
  },
  {
    text: "Todesstrafe",
    value: 36,
    main: false,
    icon: <Fourth5S />,
    dataField: 'death_sentence_ex_sh',
  },
  {
    text: "Krieg als Mittel der Politik nicht ausgeschlossen",
    value: 37,
    main: false,
    icon: <Fourth6S />,
    dataField: 'war_ex_sh',
  },
  {
    text: "Nichtratifikation des Pariser Klimaschutzabkommens",
    value: 38,
    main: false,
    icon: <Fourth7S />,
    dataField: 'non_ratific_of_paris_agreement_ex_sh',
  },
];

const SECTIONS = [
  [dataFirst, dataSecond, dataThird, dataFourth],
  [dataFirstS, dataSecondS, dataThirdS, dataFourthS]
]


class ExclusionCriteria extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hide: false };
    this.showCard = this.showCard.bind(this);
  }
  showCard(value) {
    this.setState({
      hide: { ...this.state.hide, [value]: !this.state.hide[value] },
    });
  }
  render() {
    const { classes, data, loading, assetName } = this.props;

    return (
      <div className={classes.card}>
        <div className={classes.headerWrapper}>
          <div className={classes.titleWrapper}>
            {this.props.title && (
              <Typography variant="h2" className={classes.title}>
                {this.props.title}
              </Typography>
            )}
            {this.props.tooltip && (
              <WarningTooltip title={this.props.tooltip} iconClassName={classes.helpIcon}/>
            )}
          </div>
        </div>
        <div className={classes.cardsWrapper}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              {SECTIONS.map((section) => (
                <Grid container spacing={3} className={classes.container}>
                  {section.map((column) => (
                    <Grid item xs={3}>
                      <div onClick={() => this.showCard(column[0].value)}>
                        <ExclusionCriteriaCards
                          items={column}
                          data={data}
                          assetName={assetName}
                          hide={this.state.hide}
                        />
                      </div>
                    </Grid>
                  ))}
                </Grid>
              ))}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ExclusionCriteria);
