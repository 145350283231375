import React from 'react';
import clsx from 'clsx';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

/* BCA modules*/
import useStyles from './styles';

import {DEFAULT_REPORT_TYPE, REPORT_TYPES} from './constants';
import _ from "lodash";
import {connect} from "react-redux";
import {renderInfoIconWithLabel} from '../../../../../../utils/utils';
import {DASHBOARD_SETTINGS_TYPE, DASHBOARD_TYPE} from "../../../../constants";
import CustomDashboardTypeConfiguration from '../../../../../CustomDashboardTypeConfiguration/index'

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
  investmentPlatform: state.get('investmentPlatform').toJS(),
});

export default connect(mapStateToProps) ((props) => {
    const classes = useStyles();

    const [value, setValue] = React.useState(props.selected || DEFAULT_REPORT_TYPE.value);
    const [dashboardConfigurationOpened, setDashboardConfigurationOpened] = React.useState(false)

    const [customSettings, setCustomSettings] = React.useState(props.customReportTypeSettings || {}) ;
    //save in internal state, split on states, approach callback, mb use context

    // workaround to update inner values from outside
    React.useEffect(() => {
      if (!_.isNil(props.selected)) {
        setValue(props.selected);
      }
    }, [props.selected]);

    const handleChange = (event) => {
        setValue(+event.target.value);
        props.handleChanged(+event.target.value);
    };

    const handleCustomReportSettingsChange = (settings) => {
      setCustomSettings(settings)
      props.handleCustomReportSettingUpdate(settings);
    }

    return (
        <div className={classes.container}>
          <FormControl disabled={props.disabled} component="fieldset">
            <FormLabel
              component="legend"
              classes={{root: classes.formLabel, focused: classes.focusedLabel}}
            >
              Depotansicht
            </FormLabel>
            <RadioGroup
              aria-label="Zusendung"
              name="distributionType"
              value={`${value}`}
              onChange={handleChange}
            >

              {Object.entries(REPORT_TYPES).map(([key, reportType]) => {
                let tooltipContent = reportType.tooltipContent
                // for customer dashboard settings PRO option have different tooltip with less content
                if (reportType.value == REPORT_TYPES.PRO.value
                  && (_.includes([undefined, DASHBOARD_SETTINGS_TYPE.CUSTOMER_GROUP, DASHBOARD_SETTINGS_TYPE.SINGLE_CUSTOMER], props.settingsType)
                  || props.defaultSettingsType === DASHBOARD_TYPE.CUSTOMER)){
                  tooltipContent = reportType.customerTooltipContent
                }
                return(
                  <>
                    <FormControlLabel
                      value={`${reportType.value}`}
                      label={renderInfoIconWithLabel(tooltipContent, reportType.description, {}, '100%')}
                      classes={{label: classes.formControlLabel}}
                      control={
                        <Radio color="primary" classes={{root: classes.radio}}/>
                      }
                    />
                    {reportType.value == REPORT_TYPES.CUSTOM.value && (
                      <div
                        id="report-settings-configuration"
                        className={clsx(classes.reportSettingsButton, value != REPORT_TYPES.CUSTOM.value && classes.disabled)}
                        onClick={() => setDashboardConfigurationOpened(true)}
                      >
                          <span>
                            <i className="fa fa-cog" aria-hidden="true" />
                          </span>
                        <span>Konfigurieren</span>
                      </div>
                    )}
                  </>
                )})
              }

            </RadioGroup>
          </FormControl>
          <CustomDashboardTypeConfiguration
            customReportTypeSettings={customSettings}
            dashboardConfigurationOpened={dashboardConfigurationOpened}
            onClose={() => setDashboardConfigurationOpened(false)}
            handleCustomReportSettingsChange={handleCustomReportSettingsChange}
          />
        </div>
    )
})