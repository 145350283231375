import React from "react";

export default () => {
  return (
    <svg
      id="Gruppe_8197"
      data-name="Gruppe 8197"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_8055"
            data-name="Rechteck 8055"
            width="30"
            height="30"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_8179" data-name="Gruppe 8179" clip-path="url(#clip-path)">
        <path
          id="Pfad_7824"
          data-name="Pfad 7824"
          d="M14.037,26.539h-2.8V22.6h1.888c0-.033,0-.066,0-.1a9.434,9.434,0,0,1,.118-1.487H9.653v3.9a1.621,1.621,0,0,1-3.242,0V1.585H20.094a1.622,1.622,0,0,1,1.62,1.62V13.16c.259-.022.521-.035.786-.035s.536.014.8.036V3.206A3.209,3.209,0,0,0,20.094,0H3.206A3.209,3.209,0,0,0,0,3.206V6.314H4.826V24.92a3.207,3.207,0,0,0,3.2,3.2H15a9.341,9.341,0,0,1-.96-1.584M4.826,4.728H1.586V3.206a1.62,1.62,0,1,1,3.24,0ZM22.5,15A7.5,7.5,0,1,0,30,22.5,7.5,7.5,0,0,0,22.5,15m0,13.125a5.616,5.616,0,0,1-4.58-8.879l7.834,7.834A5.588,5.588,0,0,1,22.5,28.125m4.58-2.371L19.246,17.92a5.616,5.616,0,0,1,7.834,7.834M13.383,4.728V9.014a2.464,2.464,0,0,1-.31,1.261,2.01,2.01,0,0,1-.89.811,3.136,3.136,0,0,1-1.371.279,2.71,2.71,0,0,1-1.884-.62,2.213,2.213,0,0,1-.7-1.7V4.728H9.813V9.076q.027,1.075,1,1.075a.967.967,0,0,0,.742-.27A1.262,1.262,0,0,0,11.8,9V4.728ZM19.9,11.275H18.33L16.01,7.2v4.074H14.432V4.728H16.01L18.326,8.8V4.728H19.9Z"
          fill="#80858c"
        />
      </g>
    </svg>
  );
};
