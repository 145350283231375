import React from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import withNotification from '../../../../components/NotificationProvider';
import Comparison from './Comparison';
import ComparisonsList from './ComparisonsList';
import TabContent from './TabContent';
import {redirectToProductsComparison, redirectToProductsComparisonOverview,} from '../../utils';
import {COULD_NOT_GET_PRODUCT_DETAILS, LIST_PROCESS, PROCESS_TYPES, PRODUCT_COMPARISON_STEP} from '../../constants';
import {PortfolioHandlerResource} from '../../../../utils/api';
import {displayErrorSnackBar} from '../../../../components/SnackbarProvider/actions';
import {updateHistoryStateValue} from '../../../../utils/utils';
import useStyles from '../../styles';
import {getInvestmentPlatformSelector} from "../../../../utils/redaxSelectors";

const SavedComparisons = (props) => {

  const classes = useStyles();
  const [selectedProcess, setSelectedProcess] = React.useState(LIST_PROCESS)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {

    // if params are passed in url - perform some preparations
    const urlSearchParams = new URLSearchParams(props.location.search)

    if (urlSearchParams){
      let type = urlSearchParams.get('process_type') || PROCESS_TYPES.LIST
      let id = urlSearchParams.get('product_comparison_id')

      // fetch comparison data
      PortfolioHandlerResource.at('customer/product-comparison/list/').get({id})
        .then(response => {
          let comparison = response[0];

          if (type == PROCESS_TYPES.COPY){
            comparison.name = `Kopie: ${comparison.name}`;
            delete comparison.id // id is not set for copy to perform create instead of update
          }
          setSelectedProcess({type, comparison})
        })
        .catch(error => {
          // on error there is no need to setSelectedProcess, as it is set to LIST_PROCESS by default
          props.dispatch(displayErrorSnackBar(COULD_NOT_GET_PRODUCT_DETAILS));
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [])

  React.useEffect(() => {
    const pageTitle = selectedProcess.type != PROCESS_TYPES.LIST
      ? `Investmentvergleich - ${getTabTitle()}`
      : 'Investmentvergleich';

    updateHistoryStateValue(props.history, 'title', pageTitle);
  }, [loading])

  const changeSelectedProcess = (comparison, type) => {
    const {
      history,
      investmentPlatform,
    } = props;

    if (type == PROCESS_TYPES.OPEN) {
      redirectToProductsComparisonOverview(investmentPlatform.routes, comparison.id);
    } else {
      const params = {
        tab_id: PRODUCT_COMPARISON_STEP.COMPARISON,
        process_type: type,
        product_comparison_id: comparison.id,
      };

      redirectToProductsComparison(history, investmentPlatform.routes, params, true);
    }
  }

  const getTabTitle = () => {
    // we show edit/copy title inline
    return `Bearbeiten - ${selectedProcess.comparison.name}`
  }

  return (
     <Grid container className={classes.container}>

       {loading
         ? (
            <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 6}}>
              <CircularProgress/>
            </Grid>
         )
         : (
           <>
             {selectedProcess.type === PROCESS_TYPES.LIST ? (
               <TabContent title={'Gespeicherte Vergleiche'}>
                 <div className={classes.section}>
                  <ComparisonsList changeSelectedProcess={changeSelectedProcess}/>
                 </div>
               </TabContent>
             ) : (
               <TabContent
                 title={getTabTitle()}
               >
                 <Comparison
                   comparison={selectedProcess.comparison}
                   processType={selectedProcess.type}
                 />
               </TabContent>
             )}
           </>
         )
       }

     </Grid>
  )
}

const mapStateToProps = (state) => ({
  investmentPlatform: getInvestmentPlatformSelector(state),
});

export default connect(mapStateToProps)(withNotification(withRouter(SavedComparisons)))