import {makeStyles} from "@material-ui/core";

export const styles = theme => ({
  favouriteInstrumentsText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#80858C',
    marginRight: 10
  },
  favouriteInstrumentsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  errorContainer: {
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& > i': {
      marginBottom: 15,
      color: '#ADB6BD',
      fontSize: 20
    },

    '& > p': {
      fontFamily: 'Roboto-Regular',
      color: '#63696F',
      textAlign: 'center',
      margin: 0
    }
  },
  extraActionItem: {
    '& > button': {
      padding: '2px 8px'
    }
  },
  extraActionItemContained: {
    '& > button': {
      padding: '4px 8px'
    }
  }
})


export default makeStyles(styles);