import React, {useState} from 'react';
import clsx from 'clsx';

/*  Material-UI Components*/
import {Card, CardContent, CardHeader} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from "@material-ui/core/withWidth";

/* BCA modules*/
import styles from './styles';
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../constants";
import ExpandButton, {ExpandIconButton} from './components/ExpandButton';


const DashboardCard = (props) => {
  const {
    classes,
    error,
    loading,
    content,
    title,
    expandable,
    expandableList,
    expandedItemKey,
    expandedItems,
    handleExpandedItemsChange,
    expanded,
    chart,
    table,
    id,
    divider,
    hideDividerOnExpanded,
    headerAlignCenter,
    isLast,
    empty,
    emptyMessage,
    emptyStyle,
    style,
    onExpanded,
    sideNavigationContent,
    fullTitle,
    withoutExpandButton,
    width
  } = props;

  const [expandedListItem, setExpandedListItem] = useState(true);

  const loadingContent = props.loadingContent ||(
    <div className={classes.iconContainer}>
      <CircularProgress/>
    </div>
  );

  const errorContent = (
    <div className={classes.iconContainer}>
      <ErrorIcon/>
    </div>
  );

  const emptyContent = (
    <div
      className={classes.emptyMessage}
      style={emptyStyle || {}}
    >
      {emptyMessage || DEFAULT_EMPTY_SECTION_MESSAGE}
    </div>
  );

  const handleExpanded = (value) => {
    if (onExpanded) {
      onExpanded(value);
    }
  }

  const handleExpandedListItem = () => {
    setExpandedListItem(!expandedListItem);
    if (expandedListItem) {
      handleExpandedItemsChange(expandedItemKey, expandedItems[expandedItemKey].filter((item) => item !== id))
    } else {
      handleExpandedItemsChange(expandedItemKey, [...expandedItems[expandedItemKey], id])
    }
  }

  const shouldHideDivider = hideDividerOnExpanded && expandedListItem;
  const isMobile = ['xs', 'sm'].includes(width);

  return (
    <Card
      id={id}
      className={
        clsx(
          classes.container,
          chart && classes.chartContainer,
          table && classes.tableContainer,
          divider && !shouldHideDivider && classes.withDivider,
          isLast && classes.last
        )
      }
      style={style || {}}
    >
      <div className={clsx(classes.headerWrapper, headerAlignCenter && classes.headerAlignCenter)}>
        <CardHeader
          title={title}
          className={fullTitle ? classes.headerNoEllipsis : classes.header}
          classes={{content: classes.CardHeaderContent}}
        />
        <div style={{display: 'flex'}}>
          { !isMobile && sideNavigationContent }
          {(expandable && !withoutExpandButton)
          && <ExpandIconButton
              expanded={!!expanded || (expandableList && expandedListItem)}
              onChanged={expandableList ? handleExpandedListItem : handleExpanded}
            />}
        </div>

      </div>
      { !!isMobile && sideNavigationContent }
      <CardContent
        style={{display: !expandable || expanded || (expandableList && expandedListItem) || table ? 'flex' : 'none'}}
        classes={{root: classes.CardContentRoot}}
      >
        <div className={classes.contentWrapper}>
          {loading ? loadingContent : error ? errorContent : empty ? emptyContent : content}
        </div>
      </CardContent>
    </Card>
  );
};


export default withWidth()(withStyles(styles)(DashboardCard));
