import React from 'react';
import clsx from 'clsx';

import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import styles from './styles';
import withStyles from "@material-ui/core/styles/withStyles";


const PaginationInput = (props) => {

  const {
    classes,
    handlePageChanged,
    totalPageCount,
    currentPage,
    label
  } = props;

  const [currentValue, setCurrentValue] = React.useState(currentPage + 1);
  const [maxValue, setMaxValue] = React.useState(totalPageCount);

  React.useEffect(() => {
    setCurrentValue(currentPage + 1);
  }, [currentPage]);

  React.useEffect(() => {
    setMaxValue(totalPageCount);
  }, [totalPageCount]);

  const onInputChange = (event) => {
    let value = +(event.target.value) || '';

    if (value > maxValue) {
      value = maxValue;
    }

    setCurrentValue(value);
  };

  const onKeyDownHandler = (event) => {
    if (event.key === 'Enter') {
      onConfirmChange();
    }
  };

  const onConfirmChange = () => {
    if (currentValue) {
      handlePageChanged(currentValue - 1);
    }
  };

  return (
    <div className={classes.container}>
      {label && <div className={clsx(classes.label, (props.classes && props.classes.label) || '')}>{label}</div>}
      <input className={classes.input}
             value={currentValue && currentValue.toString()}
             onChange={(event) => onInputChange(event)}
             onKeyDown={onKeyDownHandler}
      />
      <div className={classes.buttonWrapper} onClick={onConfirmChange}>
        <NavigateNextIcon />
      </div>
    </div>
  )
};

export default withStyles(styles)(PaginationInput);