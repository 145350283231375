import React from 'react';

import useStyles from '../styles';
import KeyboardDatePicker from '../../../KeyboardDatePicker';

export default (props) => {
  const classes = useStyles();

  const {
    label,
    value,
    onChange,
    error,
    disabled,
    minDate,
    maxDate,
  } = props;

  const handleDateChanged = (date) => {
    onChange(date);
  };

  return (
    <div className={classes.textField}>
      <KeyboardDatePicker
        label={label}
        value={value || null}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        errorMessage={!disabled && error}
        onChange={date => handleDateChanged(date)}
        classes={{
          root: classes.textField
        }}
        inputProps={{
          className: classes.textField,
          classes: {
            root: classes.textFieldRoot,
            underline: classes.textFieldUnderline,
            disabled: classes.textFieldDisabled
          }
        }}
        inputLabelProps={{
          shrink: true,
          classes: {
            root: classes.labelRoot,
            focused: classes.labelFocused
          }
        }}
      />
    </div>

  )
}