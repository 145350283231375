import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import clsx from "clsx";
import styles from "./styles";

const YEARS_MAX = 7;

function RiskClassWidget({ classes, title, data }) {
  const getDisplayedValue = (digit) => {
    return digit + 1;
  };

  const isItemActive = (digit) => {
    return data === getDisplayedValue(digit);
  };

  const getYearsList = () => {
    return [...Array(YEARS_MAX).keys()];
  };

  const getLineClassName = (digit) => {
    return clsx([classes.item, isItemActive(digit) && classes.itemActive]);
  };

  const getLineComponent = (digit) => (
    <li className={getLineClassName(digit)} key={digit}>
      {getDisplayedValue(digit)}
    </li>
  );

  const renderLines = () => {
    return getYearsList().map(getLineComponent);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2" classes={{ root: classes.headline }}>
        {title}
      </Typography>
      <div className={classes.legend}>
        <div className={classes.legendItem}>Geringes Risiko<br />Geringer Ertrag</div>
        <div className={classes.legendItem}>Hohes Risiko<br />Hoher Ertrag</div>
      </div>
      <ul className={classes.rating}>
        {renderLines()}
      </ul>
    </div>
  );
}

export default withStyles(styles)(RiskClassWidget);
