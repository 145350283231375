import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  header: {
    fontFamily: 'Roboto-Regular',
    fontSize: 34,
    color: '#626970',

    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
    }
  }
})

export default makeStyles(styles);