import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import HighChartBase from "../../../../../../components/Charts/Base";


function calculateWidth(){
  var chart = this,
    totalW = chart.plotLeft + chart.xAxis[0].width + chart.marginRight;

  if (totalW !== chart.chartWidth) {
    chart.setSize(totalW, null, false);
  }
}

class PortfolioBreakdownChart extends React.Component {

    render() {

        const { classes } = this.props;

        return <div className={classes.chart}><HighChartBase options={{
          colors: [
              '#C8E8C7', '#7ED2CF', '#1FBBD5', '#0098C6', '#1971AF', '#1D2281', '#151759', '#FFBFA4', '#FD8B96', '#FA528A'
          ],
          chart: {
            marginLeft: 20,
            type: 'column',
            height: 350,
            style: {
              fontFamily: "\"Roboto-Regular\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif",
              fontSize: 14
            },
            events: {
              load: calculateWidth,
            }
          },
          credits: {
            enabled: false
          },
          title: {
            text: ''
          },
          xAxis: {
            width: 80,
            categories: [],
            tickLength: 0,
            labels: {
                enabled: false
            },
            lineColor: 'transparent',
          },
          yAxis: {
            offset: -15,
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            tickLength: 10,
            tickWidth: 1,
            min: 0, max: 100,
            tickInterval: 25,
            title: {
              enabled: false
            },
            labels: {

            },
          },
          legend: {
            useHTML: true,
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            itemMarginTop: 5,
            itemMarginBottom: (this.props.series.length > 10) ? 5 : 10,
            itemDistance: 0
          },
          tooltip: {
            enabled: false
          },
          plotOptions: {
            column: {
              stacking: 'percent',
              shadow: false,
              borderWidth: 1
            },
            tooltip: {
              enabled: false
            },
            series: {
              enableMouseTracking: true,
              pointWidth: 65
            }
          },
          series: this.props.series
        }} language={'DE'}/></div>
    }

}

export default withStyles(styles)(PortfolioBreakdownChart)
