import React from 'react';
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CardContent from "@material-ui/core/CardContent/CardContent";
import Card from "@material-ui/core/Card/Card";

import useStyles from './styles';
import { Assets } from './components';
import {withEuroOrDash} from '../../../../utils/utils';
import { PortfolioManagerResource } from '../../../../utils/api';
import ConfirmModal from "../../../../components/ConfirmModal";
import EditAssetModal from "../../../../components/AssetModal/EditAssetModal";
import ViewAssetModal from "../../../../components/AssetModal/ViewAssetModal";
import AddAssetButton from "../../components/AddAssetButton";
import {
  disableAssetModalMenu,
  openAssetModal,
  setAssetModalContentType,
} from "../../../../components/AssetModal/actions";
import {buildCurrentCustomerOtherAssetsListPath, buildOtherAssetsListLink, ROUTES} from "../../../../routes";
import { executeIfPathExist, getInvestmentDynamicPath } from '../../../InvestmentPlatform/utils'
import { newDesignUsed } from '../../../../utils/utils'
import ClearingAccountTable from "../../../../components/Charts/ClearingAccountTable";

import {
  CALL_MONEY_CATEGORY,
  CLEARING_ACCOUNT_ASSET_TYPE,
  INSURANCE_CATEGORY
} from "../AssetsList/components/AssetsCategories/constants";
import _ from 'lodash';

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS()
});

const AssetCategoryDetails = (props) => {
  const classes = useStyles();
  const {
    customerId,
    dialogHandler,
    onAssetsUpdate,
    dispatch,
    assetsData,
    loading,
    category,
    onGoBackClick
  } = props;

  const [assetToDelete, setAssetToDelete] = React.useState(null);
  const [assetDeleteRequest, setAssetDeleteRequest] = React.useState({
    loading: false,
    errors: undefined
  });

  const [assetToEdit, setAssetToEdit] = React.useState(null);
  const [assetToView, setAssetToView] = React.useState(null);

  const [investmentDynamicPath, setInvestmentDynamicPath] = React.useState(getInvestmentDynamicPath())

  React.useEffect(() => {
    const assets = assetsData && assetsData.assets && assetsData.assets || [];

    if (!loading && assets.length === 0) {
      backToList();
    }
  }, [assetsData])

  const onClickConfirmDeleteAsset = async () => {
    setAssetDeleteRequest({loading: true, errors: undefined});
    try {
      await PortfolioManagerResource.at(`customer/${customerId}/other-assets/${assetToDelete.id}/?asset_type=${category.id}`).delete();
      setAssetDeleteRequest({loading: false, errors: undefined});
      dialogHandler({open: true, message: (<span><b>{assetToDelete.name}</b> wird gelöscht.</span>)});
      setAssetToDelete(null);
      onAssetsUpdate();
    } catch (errors) {
      setAssetDeleteRequest({loading: false, errors});
    }
  };

  const handleViewModalClose = () => {
    setAssetToView(null);
  };

  const handleEditModalClose = () => {
    setAssetToEdit(null);
  };

  const handleAssetEdit = (assetName) => {
    dialogHandler({open: true, message: (<span><b>{assetName}</b> wird gespeichert.</span>)});
    setAssetToEdit(null);
    onAssetsUpdate();
  };

  const backToList = () => {

    if (onGoBackClick) {
      onGoBackClick();
      return
    }

    const customer_id = props.match.params.customer_id;
    let path = customer_id ? buildOtherAssetsListLink(customer_id): buildCurrentCustomerOtherAssetsListPath();

    if (customer_id && newDesignUsed()) {
      executeIfPathExist(props.investmentPlatform.routes, 'OTHER_ASSETS', (dynamicPath) => {

        path = `/${investmentDynamicPath}` + dynamicPath.replace(':customer_id', customer_id) + ROUTES.BROKER.OTHER_ASSETS_LIST.newDesignPath
      }, ':customer_id')
    }

    props.history.push(path);
  };

  const handleAddAssetClicked = () => {
    dispatch(setAssetModalContentType(category.id));
    dispatch(disableAssetModalMenu());
    dispatch(openAssetModal());
  };

  const getDeleteMessage = () => {
    const name = assetToDelete && assetToDelete.name ? assetToDelete.name : '';

    if (category.id === INSURANCE_CATEGORY.id) {
      return `Möchten Sie das ${category.title} mit der Vertragsnummer ${name} wirklich löschen?`;
    }

    if (category.id === CALL_MONEY_CATEGORY.id) {
      return `Möchten Sie das ${category.title} mit der Kontonummer ${name} wirklich löschen?`;
    }

    return `Möchten Sie das ${category.title} mit dem Name ${name} wirklich löschen?`;
  }


  return (
    <>
      <Card className={classes.container}>
        <CardContent className={classes.content}>
          <Grid className={classes.assetCategoryDetailsContainer}>
            <Grid>
              <p onClick={backToList} className={classes.goBackLink} id="asset-category-back-to-list-tour-element">
                <ArrowBackIosIcon style={{marginRight: -5}}/>
                Zurück zu Gesamtvermögen
              </p>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={5} lg={4} className={classes.assetCategoryDetailsWrapper}>
                <div className={classes.assetCategoryDetails} id="asset-category-details-tour-element">
                  <div className={classes.assetCategoryDetailsIcon}>
                    <img src={category.icon} />
                  </div>
                  <div>
                    <Typography variant="h2" className={classes.assetCategoryDetailsTitle}>{category.title}</Typography>
                    {(assetsData) && (
                      <Typography variant="body1" className={classes.assetCategoryDetailsTotalValue}>
                        {withEuroOrDash(parseFloat(assetsData.total_value))}
                      </Typography>
                    )}
                  </div>
                </div>
                {category.id !== CLEARING_ACCOUNT_ASSET_TYPE.id &&
                  <div className={classes.addButtonWrapper}>
                    <AddAssetButton onButtonClick={handleAddAssetClicked}/>
                  </div>
                }
              </Grid>
              <Grid item xs={12} md={7} lg={8}>
                {category.id !== CLEARING_ACCOUNT_ASSET_TYPE.id ? (
                  (assetsData) && (
                  <Assets
                    assets={assetsData.assets}
                    total={parseFloat(assetsData.total_value)}
                    deleteBtnHandler={(asset) => setAssetToDelete(asset)}
                    editBtnHandler={(asset) => setAssetToEdit(asset)}
                    viewBtnHandler={(asset) => setAssetToView(asset)}
                  />
                  )) : (
                  <div style={{margin: '0 40px'}}>
                    {assetsData && Object.entries(_.groupBy(assetsData.assets, 'portfolio_name')).map(([portfolio_name, data]) => {
                      return(
                        <div style={{marginTop: '5%'}}>
                          <p className={classes.tableSectionHeader}>{portfolio_name}</p>
                          <ClearingAccountTable data={data}/>
                        </div>
                      )
                    })}
                  </div>
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <ConfirmModal
        open={!!assetToDelete}
        handleClose={() => setAssetToDelete(null)}
        handleConfirm={onClickConfirmDeleteAsset}
        loading={assetDeleteRequest.loading}
        title={getDeleteMessage()}
        cancelBtnText={'Abbrechen'}
        confirmBtnText={'Löschen'}
      />
      <EditAssetModal
        asset={assetToEdit}
        assetType={category.id}
        onModalClose={handleEditModalClose}
        onAssetEdit={handleAssetEdit}
        customerId={customerId}
      />
      <ViewAssetModal
        asset={assetToView}
        assetType={category.id}
        onModalClose={handleViewModalClose}
        customerId={customerId}
      />
    </>
  );
};

export default connect(mapStateToProps)(withRouter(AssetCategoryDetails));
