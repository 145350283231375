import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  header: {
    fontFamily: 'Roboto-Regular',
    fontSize: 34,
    color: '#626970',
    marginRight: 10,

    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
    }
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    borderRadius: 2,
    boxShadow: 'none',
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    textTransform: 'none',
    padding: '3px 15px',

    '& img': {
      marginRight: 5
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 14,

      '& img': {
        width: 14
      }
    }
  },
  buttonContainer: {
    justifyContent: 'flex-end',

    [theme.breakpoints.down('xs')]: {
      paddingTop: 16,
      justifyContent: 'flex-start',
    },

    '& > button': {
      marginLeft: 15,
      marginBottom: 15,
      [theme.breakpoints.down(375)]: {
        marginLeft: 0,
      },
      '&:first-child': {
        marginLeft: 0
      }
    }
  },
  link: {
    margin: 0,
    position: 'relative',
    top: -10,

    '& > a': {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontFamily: 'Roboto-Bold',
      textDecoration: 'none',


      '& > span:first-child': {
        fontSize: 30,
        textDecoration: 'none',
        marginRight: 5,
        position: 'relative',
        top: 4
      },
      '& > span:last-child': {
        textDecoration: 'underline'
      }
    }
  }
}));