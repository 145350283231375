import React from 'react';

/* Material-UI Components */
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

/* BCA modules*/
import useStyles from './styles';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import WarningTooltip from "../../../../components/WarningTooltip";
import OrderButton from '../../components_v2/ActionButtons/OrderButton';
import PDFButton from '../../components_v2/ActionButtons/PDFButton';
import ComparisonButton from '../../components_v2/ActionButtons/ComparisonButton';
import AlertButton from '../../components_v2/ActionButtons/AlertButton';
import { DashboardSettingsModal } from './components/DasboardSettingsModal';
import PageTitle from '../../../../components/PageLayout/components/PageTitle';


export default (props) => {
  const classes = useStyles();

  const {
    handlePdfExportClick,
    pdfExportDisabled,
    ordersCount,
    customerHasTradings,
    onGoToTradeDetails,
    tradingSession,
    onAddToComparisonClick,
    dashboardSettingsLoaded,
    dashboardSettings,
    customerId,
    customerFullName,
    customerHasDashboardGroups,
    refresh,
    brokerId,
  } = props;

  const [settingsModalOpen, setSettingsModalOpen] = React.useState(false);
  const inGroup = dashboardSettings.hasOwnProperty('is_broker_group');
  const settingTooltip = inGroup
    ? "Eine Änderung der individuellen Einstellungen ist hier nicht möglich, da der Kunden im Einstellungsmenü einer Gruppe zugeordnet wurde. Entfernen Sie den Kunden zunächst aus der Gruppe, um individuelle Einstellungen vornehmen zu können."
    : "Allgemeine Einstellungen";

  return (
    <PageTitle
      id="portfolio-analyze-header-tour-element"
      title={
        <>
          Vermögensübersicht&nbsp;
          {/*<WarningTooltip*/}
          {/*  interactive*/}
          {/*  title={<>Klicken Sie <a href={"#"}>hier</a> um zu einem Tutorial-Video in der Infothek zu gelangen.</>}*/}
          {/*  icon={*/}
          {/*    <IconButton className={classes.questionIcon}>*/}
          {/*      <i className="far fa-question-circle" />*/}
          {/*    </IconButton>*/}
          {/*  }*/}
          {/*/>*/}
          {/*&nbsp;*/}
          <WarningTooltip
            title={settingTooltip}
            icon={
              <span>
                <IconButton color={'primary'} onClick={() => setSettingsModalOpen(true)} disabled={!dashboardSettingsLoaded || inGroup}>
                  <i className={"far fa-cog"} />
                </IconButton>
              </span>
            }
          />
          <DashboardSettingsModal
            customerId={customerId}
            customerFullName={customerFullName}
            customerHasDashboardGroups={customerHasDashboardGroups}
            dashboardSettings={dashboardSettings}
            open={settingsModalOpen}
            handleClose={() => setSettingsModalOpen(false)}
            afterUpdate={refresh}
            brokerId={brokerId}
            dashboardSettingsLoaded={dashboardSettingsLoaded}
          />
        </>
      }
      rightPanelItems={
        <>
          {/*<Grid item>*/}
          {/*  <AlertButton customClasses={{ root: classes.panelButton }}*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item>
            <ComparisonButton
              customClasses={{ root: classes.panelButton }}
              onButtonClick={onAddToComparisonClick}
              disabled={!dashboardSettingsLoaded}
            />
          </Grid>
          <Grid item>
            <PDFButton
              onButtonClick={handlePdfExportClick}
              disabled={pdfExportDisabled || !dashboardSettingsLoaded}
              customClasses={{ root: classes.panelButton }}
            />
          </Grid>
          <Grid item>
            <OrderButton
              customClasses={{ root: classes.panelButton }}
              disabled={!customerHasTradings || !dashboardSettingsLoaded}
              tradingSession={tradingSession}
              onButtonClick={onGoToTradeDetails}
              ordersCount={ordersCount}
            />
          </Grid>
        </>
      }
    />
  )
};
