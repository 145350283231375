import { makeStyles } from '@material-ui/core';

const theme = (theme) => ({
  chartLegendItemText: {
    fontSize: 15,
    fontFamily: 'Roboto-Regular',
    color: '#4D4F5C',

    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    }
  },
  chartLegendItem: {
    marginRight: 25,
    display: 'flex',
    alignItems: 'baseline'
  },
  chartLegendItemSymbol: {
    fontSize: 20,
    marginRight: 5,
    color: '#d32323'
  },
});

export default makeStyles(theme);
