import React from 'react'
import {
  NOTIFICATION_TURNED_OFF_ICON,
  NOTIFICATION_TURNED_ON_ICON
} from "../../Charts/InstrumentsAllocationTable/table-data";
import {IconButton, makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  button: {
    '& > span': {
      color: `${theme.palette.primary.main} !important`
    }
  },

  buttonInactive: {
    '& > span': {
      color: 'rgb(177, 177, 177) !important'
    }
  }
}))

function AlarmButton({alarmExists, alarmActive, onClick}) {

  const classes = useStyles()

  const handleClick = () => onClick && onClick();

  const iconClassName = alarmExists
    ? NOTIFICATION_TURNED_ON_ICON
    : NOTIFICATION_TURNED_OFF_ICON

  return (
    <IconButton
      onClick={handleClick}
      color={"primary"}
      style={{color: 'red !important'}}
      className={clsx(classes.button, alarmExists && !alarmActive && classes.buttonInactive)}
    >
      <i className={iconClassName} />
    </IconButton>
  )

}

AlarmButton.propTypes = {
  /**
   * Flag, that indicate, if alarm configuration exists
   */
  alarmExists: PropTypes.bool,
  /**
   * Flag, that indicate, if alarm configuration is active
   */
  alarmActive: PropTypes.bool,
  onClick: PropTypes.func
}

export default AlarmButton