const styles = (theme) => ({
  dropdownMenuWrapper: {
    top: 'auto',
    height: 'auto',
    width: 'fit-content',
    padding: 0,
    paddingRight: 'initial',
    backgroundColor: 'transparent',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
  },
  popper: {
    zIndex: 2000,
    '&[x-placement*="bottom"] $arrow': {
      top: 1,
      left: 0,
      marginTop: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "0 100%"
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 1,
      left: 0,
      marginBottom: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "100% 0"
      }
    },
  },
  arrow: {
    overflow: "hidden",
    position: "absolute",
    width: "1em",
    height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: "border-box",
    color: '#fff',
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      boxShadow: theme.shadows[1],
      backgroundColor: "currentColor",
      transform: "rotate(45deg)"
    }
  },
  dropdownMenu: {
    borderRadius: 0,
    fontSize: '.8125rem',
    minWidth: '15rem',
    color: '#323C42',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    boxShadow: '0px 0px 6px #00000029',
    border: '1px solid #DFE1E3',
    overflow: 'visible',
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    padding: '9px .9375rem',
    transition: 'background .2s cubic-bezier(.165,.84,.44,1)',
    width: '100%',
    clear: 'both',
    fontFamily: 'Roboto-Bold',
    color: '#323C42',
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    textDecoration: 'none',
    position: 'relative',
    fontSize: 15,

    '& > span > svg': {
      fontSize: 18,
      marginRight: '0.5rem',
      color: '#2092DE'
    },

    '&:hover': {
      color: '#2092DE'
    },

  },
  dropdownItemDisabled: {
    cursor: 'inherit',
    pointerEvents: 'none',
    opacity: 0.5,
  },
  linkTitleContainer: {
    display: 'inline-block',
    width: '100%',
  },
  linkContent: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  linkIcon: {
    position: 'relative',
    marginLeft: 'auto',
    height: '1.5rem',
    paddingLeft: 6,
  }
});

export default styles;
