import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import React from "react";

export const PortfolioWrapper = ({isVirtual, portfolio, PaymentPlanTradeProps, children}) => {
    if(isVirtual && portfolio.data.isMP) return children;

    return (
      <Accordion classes={{root: PaymentPlanTradeProps.classes.accordionPortfolioRoot}}
                 expanded={PaymentPlanTradeProps.expanded.includes('portfolio')}
                 onChange={PaymentPlanTradeProps.handleExpandedChange('portfolio')}>
        <AccordionSummary
          classes={{root: PaymentPlanTradeProps.classes.accordionPortfolioHeader}}
          expandIcon={<ExpandMoreIcon htmlColor={"#fff"} />}
        >
          <Grid container>
            <Grid item xs={12}>
              {portfolio.data.name}
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails classes={{root: PaymentPlanTradeProps.classes.accordionDetails}}>
          {children}
        </AccordionDetails>
      </Accordion>
    )
  };