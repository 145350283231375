const styles = (theme) => ({
  cardWrapper: {
    height: 80,
    backgroundColor: "#FF8000",
    borderRadius: "3px",
    padding: 20,
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  border: {
    border: "1px solid #D8DCDF",
  },
  tooltipWrapper: {
    position: "absolute",
    top: 8,
    right: 10,
    color: "#fff",
  },
  tooltip: {
    position: "relative",
    padding: 20,
    backgroundColor: "#EFEFF2",
    color: "#4D4F5C",
    fontFamily: "Roboto-Regular",
    fontSize: 14,
    boxShadow: "0px 2px 15px 0px rgba(0,0,0, 0.2)",
    whiteSpace: "pre-line",
    maxWidth: 500,
  },
  text: {
    fontFamily: "Roboto-Bold",
    fontSize: 14,
    color: "#fff",
    marginLeft: 16,
  },
  contentWrapper: {
    display: "flex",
    alignItems: "center",
    width: "90%",
  },
  arrowIcon: {
    position: "absolute",
    right: 6,
    bottom: 2,
    color: "#fff",
  },
  info: {

    position: 'absolute',
    right: 5,
    top: 12,
    '& > i': {
      color: 'white',
      fontSize: 16
    }
  }
});

export default styles;
