import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

/* Material UI Components*/
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import withWidth from "@material-ui/core/withWidth/withWidth";

/* BCA Components */
import ErrorModal from '../../components/ErrorModal';
import Navigation from './components/PortfolioNavigation';
import InvestmentDetailsNavigation, {
  DashboardPortfoliosSelectorSharedState,
  StickyNavigation
} from '../CustomerDashboard/components/InvestmentDetailsNavigation';
import withDashboardData from '../../components/DashboardDataProvider';
import ProfileDetails from '../CustomerDashboard/ProfileDetails';

/* BCA modules */
import {
  ERROR_MODAL_MESSAGE
} from "../../utils/constants";
import setSticky from '../../utils/sticky';

import useStyles from './styles';
import NewVirtualPortfolioButton
  from "../../components/Buttons/NewVirtualPortfolioButton";
import ModalPortfolio from "../VirtualPortfolioManager/modals/ModalPortfolio";
import ConfirmationDialog from "../VirtualPortfolioManager/modals/ConfirmationDialog";
import {parseResponse, VirtualPortfolioHandlerResource} from "../../utils/api";
import SuccessDialog from "../VirtualPortfolioManager/modals/SuccessDialog";
import ErrorDialog from "../VirtualPortfolioManager/modals/ErrorDialog";
import Widgets from '../CustomerDashboard/components/Widgets';
import TotalReturn from "../../components/InvestmentDetails/components/TotalReturn";
import { TRADING_ACTION_SAVINGS_PLAN } from '../../components/Charts/InstrumentsAllocationTable/constants';
import {downloadPdf, getSubSystemConfigItem} from "../../utils/utils";
import {getReportGenerationSettings} from "../../utils/utils";
import {displayErrorSnackBar} from "../../components/SnackbarProvider/actions";
import VirtualOrderModal from "../VirtualPortfolioManager/modals/VirtualOrderModal";
import {getCombinedTradingsFromTransactions, getInitVPTransactions} from '../Modelportfolios/utils';
import {buildTransactionsWithAddTradingOption} from "../Trades/utils";
import DownloadPdfDialog from "../../components/DownloadPdfModal/DownloadPdfModal";


export default connect()(withDashboardData(withWidth()((props) => {

  const {
    customer,
    portfolios,
    investmentData,
    dashboardData,
    instrumentList,
    historicalData,
    updateHistoricalData,
    updateRiskMetricsBenchmark,
    onSelectedPortfoliosChanged,
    onSelectedDatesChanged,
    selectedPortfolios,
    selectedDates,
    riskData,
    isVirtual,
    dataLoading,
    refresh,
    profitAndLoss,
    profitAndLossSummary,
    paymentPlans,
    unrealizedProfitAndLoss,
    breakdownData,
    timeWeightedReturnData,
    chartsSettings,
    handleChartSettingsChange,
    reportType,
    selectedDatesType
  } = props;

  const classes = useStyles();
  const isVirtualOrderEnabled = getSubSystemConfigItem('trading', 'extended_virtual_order_enabled')

  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const [showPortfolioWindow, setShowPortfolioWindow] = React.useState(false);
  const [portfolioToDeleteName, setPortfolioToDeleteName] = React.useState(undefined);
  const [portfolioToDeleteId, setPortfolioToDeleteId] = React.useState(undefined);
  const [showPortfolioDeleteConfirmation, setShowPortfolioDeleteConfirmation] = React.useState(false);
  const [portfolioDeletingInProgress, setPortfolioDeletingInProgress] = React.useState(false);
  const [showSuccessDeleteDialog, setShowSuccessDeleteDialog] = React.useState(false);
  const [showFailedDeleteDialog, setShowFailedDeleteDialog] = React.useState(false);
  const [deleteError, setDeleteError] = React.useState(undefined);
  const [expandedItems, setExpandedItems] = React.useState({
    historicalChart: true,
    performanceLineChart: true,
    performanceBarChart: true,
    riskChart: true,
    structureChart: true,
    unrealizedProfitAndLoss: true,
    profitAndLossItems: [],
    profitAndLossSubItems: {},
    paymentPlansItems: [],
    instrumentsItems: [],
    instrumentsSubItems: {},

    cumulativeReturnChart: true,
    forecastChart: true,
    performanceTable: true,
    singlePerformance: true,

    // charts KeyFiguresTab
    keyIndicatorsChart: true,
    rollingVolatilityChart: true,
    rollingSharpeRatioChart: true,
    esgScoreChart: true,
    // charts from RiskAnalysisTab
    riskReturnChart: true,
    stressTestChart: true,
    correlationMatrix: true
  });
  const [pdfExportLoading, setPdfExportLoading] = React.useState(false);
  const [pdfOpen, setPdfOpen] = React.useState(false);
  const [orderModalOpened, setOrderModalOpened] = React.useState(false);

  React.useEffect(() => {
    if (historicalData.errors || customer.errors || dashboardData.errors || investmentData.errors || instrumentList.errors) {
      setErrorMessage(ERROR_MODAL_MESSAGE);
    }
  }, [historicalData.errors, customer.errors, dashboardData.errors, investmentData.errors, instrumentList.errors]);

  React.useEffect(() => {
    setSticky('app-main', 'sticky-nav-stoper', 'investment-navigation-component-sticky', 'sticky-trigger')
  }, []);

  const handleErrorModalClose = () => {
    setErrorMessage(undefined);
  };

  const clickHandlerNewPortfolio = () => {
    setShowPortfolioWindow(true)
  };

  const showEmptyDashboard = () => {
    return <div className={classes.placeholder}>
      <span>Sie haben noch kein virtuelle Depots erstellt.</span>
      <NewVirtualPortfolioButton
        clickHandler={clickHandlerNewPortfolio}
      />
    </div>
  };

  const handleNewPortfolioDialogClose = () => {
    setShowPortfolioWindow(false)
  };

  const deletePortfolio = () => {
    setPortfolioDeletingInProgress(true);

    const customerId = customer.data && customer.data.customer_id;
    VirtualPortfolioHandlerResource.deletePortfolio(customerId, portfolioToDeleteId)
      .then((response) => {
        parseResponse(response, 'portfolio',
          (data) => {
            if (data) {
              refresh(portfolioToDeleteId);
              setPortfolioDeletingInProgress(false);
              setShowPortfolioDeleteConfirmation(false);
              setShowSuccessDeleteDialog(true);
            }
          },
          (error) => {
            setDeleteError(error);
            setShowFailedDeleteDialog(true);
          })
      })
      .catch((error) => {
        setDeleteError(error);
        setShowSuccessDeleteDialog(true);
      })
  };

  const handleOpenDeletingConfirmation = (portfolioId, portfolioName) => {
    setShowPortfolioDeleteConfirmation(true);
    setPortfolioToDeleteName(portfolioName);
    setPortfolioToDeleteId(portfolioId)
  };

  const handleCloseDeletingConfirmation = () => {
    setShowPortfolioDeleteConfirmation(false)
  };

  const closeSuccessDeletingDialog = () => {
    setShowSuccessDeleteDialog(false)
  };

  const closeFailedDeletingDialog = () => {
    setShowFailedDeleteDialog(false)
  };

  const fetchPdfFile = async (skip_expanded, skip_sub_depot_expanded, editorEnabled, editorValue, extraFiles) => {
    setPdfExportLoading(true);
    setPdfOpen(false);

    let formData = getReportGenerationSettings(customer.data && customer.data.customer_id, selectedPortfolios, portfolios,
      reportType, selectedDates, skip_expanded, skip_sub_depot_expanded, expandedItems, false, undefined, chartsSettings.global.withHistoricalPortfolios, [], chartsSettings);
    formData.append('customer_report', true);
    formData.append('is_virtual_report', true);
    try {
      await downloadPdf('reports/report/ad-hoc/', undefined, 'POST', undefined, formData);
    }
    catch(exc) {
      props.dispatch(displayErrorSnackBar('Fehler beim Herunterladen des Dokuments.'))
    }


    setPdfExportLoading(false)
  };

  const handleExpandedItemsChange = (field, data) => {
    setExpandedItems(prevState => { return { ...prevState, [field]: data }});
  };


  const [portfoliosTransactions, setPortfoliosTransactions] = React.useState([])
  const initPortfoliosTransactions = () => {
    setPortfoliosTransactions(getInitVPTransactions(instrumentList, profitAndLoss, paymentPlans))
  };

  React.useEffect(() => {
    // When you save new orders in modal, refresh is called to fetch new data for portfolio -> initPortfoliosTransactions again
    if(!_.isEmpty(instrumentList.data)){
      initPortfoliosTransactions()
    }
  }, [instrumentList.data]);

  const combinedTradings = React.useMemo(() => {
    // return combined tradings build from transactions

    return getCombinedTradingsFromTransactions(portfoliosTransactions)

  }, [portfoliosTransactions]);

  const handleAddTradingOption = (portfolio, instrument, tradingType) => {
    // function to update trading for virtual portfolios when it is selected via select on instrument level

    const updatedPortfoliosTransactions = buildTransactionsWithAddTradingOption(
      portfoliosTransactions, combinedTradings, portfolio, instrument, tradingType);

    setPortfoliosTransactions(updatedPortfoliosTransactions)
  };

  const handleAddSavingPlanOption = (portfolio, instrument, action) => {
    const updatedPortfoliosTransactions = buildTransactionsWithAddTradingOption(
      portfoliosTransactions, combinedTradings, portfolio, instrument, TRADING_ACTION_SAVINGS_PLAN, action);

    setPortfoliosTransactions(updatedPortfoliosTransactions);
  };

  return (
    <React.Fragment>
        <Container className={`app-page-container`}>
          <Navigation
            customerId={customer.data && customer.data.customer_id}
            handlePdfExportClick={() => {setPdfOpen(true)}}
            pdfExportDisabled={_.isEmpty(selectedPortfolios) || pdfExportLoading}
            loading={pdfExportLoading}
          />
          <ProfileDetails
            customer={customer.data}
            customerLoadingError={customer.errors}
            customerDataLoading={customer.loading}
            investmentData={investmentData.data}
            investmentDataLoading={investmentData.loading}
            investmentDataLoadingError={investmentData.errors}
            isVirtual={true}
            portfoliosDataLoading={portfolios.loading || dataLoading}
            clickHandlerNewPortfolio={clickHandlerNewPortfolio}
          />
          <DashboardPortfoliosSelectorSharedState>
            <StickyNavigation
              calculationDates={selectedDates}
              calculationDatesType={selectedDatesType}
              handleCalculationDatesChanged={onSelectedDatesChanged}
              portfolios={portfolios.data}
              portfoliosLoadingError={portfolios.errors}
              portfoliosDataLoading={portfolios.loading}
              handleSelectedPortfolioChanged={onSelectedPortfoliosChanged}
              selectedPortfolios={selectedPortfolios}
              dataLoading={dataLoading}
              investmentData={investmentData.data}
              isCustomerApp
              isVirtual={isVirtual}
            />
            { (portfolios.loading || (portfolios.data && portfolios.data.length > 0)) ? <>
              <Grid container style={{marginBottom: 20}}>
              <Grid item className={classes.investmentNavigationContainer} id="sticky-trigger">
                <InvestmentDetailsNavigation
                  calculationDates={selectedDates}
                  calculationDatesType={selectedDatesType}
                  handleCalculationDatesChanged={onSelectedDatesChanged}
                  updateHistoricalData={updateHistoricalData}
                  portfolios={portfolios.data}
                  portfoliosLoadingError={portfolios.errors}
                  portfoliosDataLoading={portfolios.loading}
                  handleSelectedPortfolioChanged={onSelectedPortfoliosChanged}
                  selectedPortfolios={selectedPortfolios}
                  isVirtual={isVirtual}
                  dataLoading={dataLoading}
                  investmentData={investmentData.data}
                  isCustomerApp
                />
              </Grid>
              <Grid item className={classes.totalReturnContainer}>
                <div className={classes.totalReturnCard}>
                  <TotalReturn
                    data={investmentData.data}
                    loading={investmentData.loading}
                  />
                </div>
              </Grid>
            </Grid>
              <Widgets
                dashboardSettings={_.get(customer, 'data.dashboard_settings', {})}
                selectedPortfolios={selectedPortfolios}
                calculationDates={selectedDates}
                dashboardData={dashboardData.data}
                dashboardDataLoading={dashboardData.loading}
                dashboardDataLoadingError={dashboardData.errors}
                instrumentListData={instrumentList.data}
                instrumentListDataLoading={instrumentList.loading}
                instrumentListDataLoadingError={instrumentList.errors}
                historicalData={historicalData}
                profitAndLossData={profitAndLoss}
                profitAndLossSummary={profitAndLossSummary.data}
                paymentPlansData={paymentPlans.data}
                paymentPlansLoading={paymentPlans.loading}
                paymentPlansLoadingError={paymentPlans.errors}
                unrealizedProfitAndLossData={unrealizedProfitAndLoss.data}
                unrealizedProfitAndLossDataLoading={unrealizedProfitAndLoss.loading}
                unrealizedProfitAndLossDataLoadingError={unrealizedProfitAndLoss.errors}
                isCustomerApp
                isVirtual={isVirtual}
                isVirtualOrderEnabled={isVirtualOrderEnabled}
                breakdownData={breakdownData}
                timeWeightedReturnData={timeWeightedReturnData}
                riskData={riskData}
                expandedItems={expandedItems}
                chartsSettings={chartsSettings}
                onExpandedItemsChange={handleExpandedItemsChange}
                onChartSettingsChange={handleChartSettingsChange}
                combinedTradings={combinedTradings}
                onAddTradingOption={handleAddTradingOption}
                onAddSavingPlanOption={handleAddSavingPlanOption}
                virtualInstrumentLink={customer.data && customer.data.user.agency.virtual_instrument_buy_template}
                triggerPortfolioDeleting={handleOpenDeletingConfirmation}
                refresh={refresh}
                reportType={reportType}
                benchmarkConfigurationEnabled
                updateHistoricalData={updateHistoricalData}
                updateRiskMetricsBenchmark={updateRiskMetricsBenchmark}
                onGoToTradeDetails={(_event) => {setOrderModalOpened(true)}}
              />
            </> : showEmptyDashboard()
            }
          </DashboardPortfoliosSelectorSharedState>
        </Container>
      <ErrorModal message={errorMessage} handleClose={handleErrorModalClose}/>
      <ModalPortfolio
        open={showPortfolioWindow}
        onClose={handleNewPortfolioDialogClose}
        customerId={customer.data && customer.data.customer_id}
        portfolios={portfolios.data}
      />
      <ConfirmationDialog
        message={
          <p>Möchten Sie wirklich <b>{portfolioToDeleteName}</b> löschen?</p>
        }
        open={showPortfolioDeleteConfirmation || portfolioDeletingInProgress}
        onClose={handleCloseDeletingConfirmation}
        loading={portfolioDeletingInProgress}
        confirm={deletePortfolio}
      />
      <SuccessDialog
        open={showSuccessDeleteDialog}
        onClose={closeSuccessDeletingDialog}
        message={
          <p>
            <b>{portfolioToDeleteName}</b> wurde erfolgreich gelöscht.
          </p>
        }
      />
      <ErrorDialog
        open={showFailedDeleteDialog}
        onClose={closeFailedDeletingDialog}
        message={
          <p>
            <b>{portfolioToDeleteName}</b> deleting was failed.
            <br/>
            {deleteError}
          </p>
        }
      />
      {isVirtualOrderEnabled && customer.data && portfoliosTransactions &&
        <VirtualOrderModal
          open={orderModalOpened}
          onClose={() => {setOrderModalOpened(false)}}

          portfoliosTransactions={portfoliosTransactions}
          setPortfoliosTransactions={setPortfoliosTransactions}
          initPortfoliosTransactions={initPortfoliosTransactions}

          portfolioOwnerId={customer.data.customer_id}
          isCustomerApp
          refresh={refresh}
          dispatch={props.dispatch}
        />
      }
      <DownloadPdfDialog
        open={pdfOpen}
        onClose={() => {setPdfOpen(false)}}
        onExport={fetchPdfFile}
        exportBtnDisabled={!selectedPortfolios || pdfExportLoading}
        title={'Virtuelle Vermögensübersicht drucken'}
      >
        <DownloadPdfDialog.Expanded />
      </DownloadPdfDialog>
    </React.Fragment>
  )
})))