import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    backgroundColor: 'white',
    borderRadius: '0px 0px 4px 4px',
    padding: '0 15px 5px 25px ',
    '&:first-child': {
      paddingTop: 20
    },
  },

  titleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 0
  },
  titleHintSection: {
    marginTop: -10
  },

  title: {
    fontFamily: 'Roboto-Bold',
    color: '#202A38',
    fontSize: 18
  },

  bankName: {
    fontFamily: 'Roboto-Medium',
    color: '#202A38',
    fontSize: 18,
    marginBottom: 15,
    marginTop: 15
  },

  documentsSection: {
    marginTop: 0,
  },

  formControlLabelDisabled: {
    color: '#202A38 !important',
    fontFamily: 'Roboto-Regular',
    fontSize: 16
  },
  formControlLabelRoot: {
    marginRight: '0 !important',
    marginBottom: '0 !important'
  },

  selectAllCheckboxSection: {
  },

  documentItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 6,
    position: 'relative',
    '& > *': {
      flex: '0 0 auto',
    },
    '& > div:first-child': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'calc(100% - 150px)',
      height: 45,
      border: '1px solid #EAEDF3',
      padding: '0 5px',
      overflowX: 'hidden',

      '& > span:first-child': {
        color: '#202A38 !important',
        fontFamily: 'Roboto-Regular',
        fontSize: 16,
        whiteSpace: 'nowrap',
        display: 'inline-block',
        width: 'calc(100% - 90px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  },

  checkboxes: {
    display: 'flex',
    width: 150,
    flexWrap: 'wrap',
    '& > div': {
      width: 75,
      flexBasis: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& > span': {
        fontFamily: 'Roboto-Bold',
        fontSize: 14
      }
    }
  },

  previewButton: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
    fontHeight: 14,
    whiteSpace: 'nowrap',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  infoIcon: {
    '& > svg > path': {
      fill: theme.palette.primary.main
    },
    marginRight: 10
  },

  errorMessage: {
    fontFamily: 'Roboto-Regular !important',
    fontSize: '12px !important',
    color: 'red',
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    marginTop: 10,
    position: 'relative',
  },

  documentsManagementButton: {
    margin: '15px 0px 13px'
  },
  tooltip: {
    fontSize: 12,
  },
  tooltipPopper: {
    top: '12px !important'
  }

});

export default makeStyles(styles)