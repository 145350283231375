import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";

import styles from './styles';
import connect from "react-redux/lib/connect/connect";
import ExtendedHTMLEditor from '../../../../../Editor/ExtendedHTMLEditor';
import { Quill }  from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import Image from './image';
import ErrorModal from "../../../../../../components/ErrorModal";
import {ERROR_MODAL_MESSAGE} from "../../../../../../utils/constants";

Quill.register('modules/imageResize', ImageResize);
Quill.register(Image);


function insertVariable(id) {
  const variable = window.editorVariables && window.editorVariables.find(item => item.id === +id)
  if (variable) {
    const cursorPosition = this.quill.getSelection().index;
    this.quill.insertText(cursorPosition, variable.snippet);
    this.quill.setSelection(cursorPosition + variable.snippet.length);
  }
}

const mapStateToProps = (state) => ({
  editorVariables: state.get('reporting').toJS().editorVariables,
});

const RichTextEditor = (props) => {
  const {
    classes,
    content,
    enabled,
    handleChange,
    editorVariables,
    uid,
    useLatestTemplate,
    useForTextOnly,
    setPureEditorValue,
    addDefaultEmptyLines
  } = props;

  const node = React.useRef();
  const [errorMessage, setErrorMessage] = React.useState(undefined);

  const handleErrorModalClose = () => {
      setErrorMessage(undefined);
  };

  React.useEffect(() => {
    if (node && node.current) {
      setErrorMessage(editorVariables ? undefined : ERROR_MODAL_MESSAGE);
      const placeholderPickerItems = Array.prototype.slice.call(
        document.querySelectorAll('.ql-variable .ql-picker-item'));

      placeholderPickerItems.forEach(item => {
        const variable = editorVariables.find(_item => _item.id === +item.dataset.value);
        item.textContent = variable && variable.name || item.dataset.value;

        // prevents bug when the first item is auto selected and can not be clicked on first time select is opened
        item.classList.remove('ql-selected');

        if (!item.textContent) {
          // hide empty variable, it was added because issue with first selected item
          item.style.display = 'none';
        }
      });
      if(!useForTextOnly){
        document.querySelector('.ql-variable .ql-picker-label').innerHTML = 'Seriendruckfelder <svg viewBox="0 0 18 18"> <polygon class="ql-stroke" points="7 11 9 13 11 11 7 11"></polygon> <polygon class="ql-stroke" points="7 7 9 5 11 7 7 7"></polygon> </svg>';
      }
    }
  }, [node, editorVariables]);

  const onChange = (value, source, editor) => {
    if (content !== value) {
      handleChange(value);
    }
    // to parse html in case pure text is needed
    if(useForTextOnly) setPureEditorValue(_.get(editor.getContents(), 'ops.0.insert'))
  };

  const getEditorConfig = () => {
    if(useForTextOnly){
      return {
        toolbar: {
          container: [],
          handlers: {
            'variable': insertVariable,
          }
        }
      }
    }

    return {
      toolbar: {
        container: [
          [{'variable': editorVariables && editorVariables.map(item => String(item.id)) || []}],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline'],
          [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'color': [] }, { 'background': [] }],
          ['link', 'image', ],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
        ],
        ],
        handlers: {
          'variable': insertVariable,
        }
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
    };
  }

  return (
    <div
      id="reporting-cover-page-editor-tour-element"
      className={clsx(classes.container, enabled && classes.enabled, useForTextOnly && classes.textOnly)}
    >
      <ExtendedHTMLEditor
        ref={node}
        bounds={'#reporting-cover-page-editor-tour-element'}
        value={content}
        addDefaultEmptyLines={addDefaultEmptyLines}
        onChange={onChange}
        modules={getEditorConfig()}
        uid={uid}
        useLatestTemplate={useLatestTemplate}
      />
      {errorMessage ? (
        <ErrorModal message={errorMessage} handleClose={handleErrorModalClose}/>
      ) : null}
    </div>
  );

};

export default connect(mapStateToProps)(withStyles(styles)(RichTextEditor));

