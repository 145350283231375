import React from 'react';
import _ from 'lodash';

import { CustomersList } from './components';


const GroupCustomers = (props) => {

  //#region Component State
  
  //#endregion

  return (
    <CustomersList
      customersData={props.customersData}
      unavailableCustomers={props.unavailableCustomers}
      actionType={props.actionType}
      onSelectCustomers={props.onSelectCustomers}
      dataLoadingFailed={props.dataLoadingFailed}
      customerListDisabled={props.disabled}
    />
  );
};

export default GroupCustomers;