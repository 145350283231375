import useStyle from "./styles";
import React from "react";

export default (props) => {
  const classes = useStyle();

  return (
    <div className={classes.topMessage}>
      <div className={classes.bold}>Keine aktive Suche.</div>
      <div>Nutzen Sie die oben stehenden Optionen um eine neue Suche zu starten.</div>
    </div>
  )
}