import React from 'react'
import {connect} from "react-redux";
import {CircularProgress, Container, Grid, IconButton} from "@material-ui/core";

import useStyles from './styles'
import useFetchData from "../../hooks/useDataFetch";
import {BrokerResource, ESignResource} from "../../utils/api";
import BusinessCaseApproval from "./components/BusinessCaseApproval/BusinessCaseApproval";
import DocumentsPreviewSection from "./components/DocumentsPreviewSection/DocumentsPreviewSection";
import Badge from "@material-ui/core/Badge";
import _ from "lodash";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {displayErrorSnackBar, displaySuccessSnackBar} from "../../components/SnackbarProvider/actions";
import {
  APPROVAL_STATUS,
  APPROVAL_STATUS_FILTER_OPTIONS, APPROVALS_SORTING_OPTIONS,
  EVENT_TYPES_FILTER_OPTIONS, FINANCIAL_INVESTMENT_FILTER_OPTIONS, MANDANT_FILTER_OPTIONS,
  SERVICE_CONCEPT_FILTER_OPTIONS
} from "./constants";
import {FIELD_REQUIRED_MSG} from "../RiskProfiling/constants";
import {getAgencyFilterOptions} from "../../components/CustomersSelectorProviderNew/constants";
import {paginateArray, UserUtils} from "../../utils/utils";
import {
  CLIENT_FILTERS,
  getSearchCustomerType
} from "../../components/FilteringPanel/components/ListSelector/constants";
import moment from "moment";
import Pagination from "../../components/CustomerList/components/Pagination";
import {filterBusinessCasesApprovals, sortApprovals} from "./utils";
import Filters from '../TransactionsMonitoring/components/Filters/Filters';

const DocumentsPreviewSectionContext = React.createContext({
  onAddDocumentToPreview: () => undefined,
  onRemoveDocumentFromPreview: () => undefined,
  documents: []
})

export function useDocumentsPreviewSectionContext() {

  const context = React.useContext(DocumentsPreviewSectionContext)
  if (!context) {
    throw new Error('Extracting context without wrapping your component with context Provider!')
  }

  return context

}

function AllowEmptyBadge({badgeContent, children, ...props}) {
  if (!badgeContent) {
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <Badge badgeContent={badgeContent} {...props}>
      {children}
    </Badge>
  )
}

const DEFAULT_DYNAMIC_FILTER_OPTIONS_STRUCTURE = {
  mandants: {
    options: [],
    default: {value: undefined}
  },
  investmentType: {
    options: [],
    default: {value: undefined}
  }
}

let previewButtonTimeout = undefined

function SignedDocumentsApproval(props) {

  const classes = useStyles()

  const {approverView, auth} = props;

  const [approvalsData, fetchApprovalsData, setApprovalsData] = useFetchData(
    `${ESignResource.resourceUrl}${ESignResource.GET_APPROVALS}?fetch_all=${!!approverView}`, 'get')

  const [previewDocuments, setPreviewDocuments] = React.useState([])
  const [previewActive, setPreviewActive] = React.useState(false)

  const [brokersDropdownOptions, fetchBrokersDropdownOptions] = useFetchData(
    `${BrokerResource.resourceUrl}brokers/dropdown/?fetch_all=${!!approverView}`, 'get')

  const [statusUpdateData, setStatusUpdateData] = React.useState({
    loading: false,
    errors: undefined
  })

  const defaultCustomerType = getSearchCustomerType(auth);

  const [customersParams, setCustomersParams] = React.useState({
    customerType: defaultCustomerType
  });

  const [filters, setFilters] = React.useState({
    status: APPROVAL_STATUS_FILTER_OPTIONS.ALL,
    eventType: EVENT_TYPES_FILTER_OPTIONS.ALL,
    serviceConcept: SERVICE_CONCEPT_FILTER_OPTIONS.ALL,
    financialInvestment: FINANCIAL_INVESTMENT_FILTER_OPTIONS.ALL,
    customerType: defaultCustomerType,
    search: '',
    mandant: MANDANT_FILTER_OPTIONS.ALL,
    investedValuesRange: undefined,
    datesRange: undefined,
    triggerFilters: false,
    sortingType: APPROVALS_SORTING_OPTIONS.DATE_DESC
  })

  const [approvalsFiltered, setApprovalsFiltered] = React.useState([])
  const [dynamicFilters, setDynamicFilters] = React.useState(DEFAULT_DYNAMIC_FILTER_OPTIONS_STRUCTURE)

  const [page, setPage] = React.useState(0);

  const previewButtonRef = React.useRef()

  React.useEffect(() => {
    fetchBrokersDropdownOptions()
    handleFetchApprovalsData()
  }, [])

  React.useEffect(() => {

    if (approvalsData.loading || approvalsData.errors || !approvalsData.data) {
      return
    }

    handleFilterBusinessCases()

  }, [approvalsData.updatedAt])

  React.useEffect(() => {

    if (filters.triggerFilters) {
      if (filters.customerType.value != customersParams.customerType.value || filters.requestType != customersParams.requestType) {
        handleFetchApprovalsData()
      } else {
        handleFilterBusinessCases()
      }
      setFilters({...filters, triggerFilters: false })
    }

  }, [filters.triggerFilters])

  React.useEffect(() => {
    handleFilterBusinessCases()
  }, [filters.sortingType])

  React.useEffect(() => {
    setStatusUpdateData({
      loading: false,
      errors: undefined
    })
  }, [previewActive])

  React.useEffect(() => {
    if (previewDocuments.length && previewButtonRef.current) {
      previewButtonTimeout && clearTimeout(previewButtonTimeout)
      previewButtonRef.current.className = `${previewButtonRef.current.className} highlight`;
      setTimeout(() => {
        previewButtonRef.current.className =  previewButtonRef.current.className.replace('highlight',  '')
      }, 250)
    } else if (!previewDocuments.length && previewActive) {
      togglePreviewSection()
    }

  }, [previewDocuments.length])

  const getFiltersQueryParams = () => {

    let _filters = {
      customer_type: _.get(filters, 'customerType.value')
    }

    if (filters.requestType == CLIENT_FILTERS.SUB_AGENCY) {
      _filters.sub_agency = filters.customerType.value
      _filters.customer_type = filters.requestType
    } else if (filters.requestType == CLIENT_FILTERS.SUB_BROKER) {
      _filters.sub_broker = filters.customerType.value
      _filters.customer_type = filters.requestType
    }

    return _filters
  };

  const handleFetchApprovalsData = () => {
    let queryParams = getFiltersQueryParams() || {};

    fetchApprovalsData(queryParams)

    setCustomersParams({
      customerType: filters.customerType,
      requestType: filters.requestType
    })
    setFilters({ ...filters, triggerFilters: false })
  }

  /**
   * List of business cases approvals.
   * @type {unknown}
   */
  const approvals = React.useMemo(() => {

    if (approvalsData.loading || approvalsData.errors || !approvalsData.data) {
      return []
    }

    const _approvals = _.get(approvalsData, 'data.result') || []

    return _approvals.map((approval) => {
      approval.full_documents_list = approval.documents
      return approval
    })

  }, [approvalsData.updatedAt])

  const _getAvailableOptions = (options, availableValues) => {
    const availableOptions = {}
    Object.entries(options).forEach(([key, config]) => {
      if (availableValues.includes(config.value)
        || (config.value === options.ALL.value && availableValues.length > 1)) {
        availableOptions[key] = config
      }
    })
    return availableOptions
  }

  const _getDefaultOption = (options) => {
    return Object.keys(options).length > 1 ? options['ALL'] : options[Object.keys(options)[0]]
  }

  React.useMemo(() => {

    if (!_.isEmpty(dynamicFilters.investmentType.options) && !_.isEmpty(dynamicFilters.mandants.options)) {
      return
    }

    const result = DEFAULT_DYNAMIC_FILTER_OPTIONS_STRUCTURE

    if (approvalsData.loading || approvalsData.errors || !approvalsData.data) {
      return result
    }

    let availableMandants = _.get(approvalsData, 'data.available_filters_options.available_mandants') || []
    let availableInvestmentTypes = _.get(approvalsData, 'data.available_filters_options.available_investment_types') || []

    if (!_.isEmpty(availableMandants)) {
      const availableOptions = _getAvailableOptions(MANDANT_FILTER_OPTIONS, availableMandants)
      result.mandants = {
        options: availableOptions,
        default: _getDefaultOption(availableOptions)
      }
    }

    if (!_.isEmpty(availableInvestmentTypes)) {
      const availableOptions = _getAvailableOptions(FINANCIAL_INVESTMENT_FILTER_OPTIONS, availableInvestmentTypes)
      result.investmentType = {
        options: availableOptions,
        default: _getDefaultOption(availableOptions)
      }
    }

    setDynamicFilters(result)

    setFilters((filters) => ({
      ...filters,
      mandant: result.mandants.default,
      financialInvestment: result.investmentType.default
    }))

  }, [approvalsData.updatedAt])

  const handleAddDocumentToPreview = (approval, document) => {

    setPreviewDocuments((selectedDocuments) => {
      const onlineDocuments = _.get(approval, 'business_case.online_documents', []);
      const isOnline = !!_.find(onlineDocuments, (d) => d.external_id == document.document_external_id);
      if(!_.find(selectedDocuments, (selectedDocument) => selectedDocument.id == document.id)) {
        return [...selectedDocuments, {...document, isOnline, approval}]
      } else {
        return _.filter(selectedDocuments, (selectedDocument) => selectedDocument.id != document.id)
      }
    })

  }

  const handleRemoveDocumentFromPreview = (documentId) => {
    setPreviewDocuments(
      (selectedDocuments) => _.filter(selectedDocuments, (selectedDocument) => selectedDocument.id != documentId))

    setStatusUpdateData({...statusUpdateData, errors: undefined})
  }

  const handleSaveDocumentChanges = async (documentId, status, feedback) => {

    if (status == APPROVAL_STATUS.REJECTED.value && (!feedback || _.isEmpty(feedback))) {
      setStatusUpdateData({
        ...statusUpdateData,
        errors: {
          feedback: FIELD_REQUIRED_MSG
        }
      })
      return
    }

    const documentData = _.find(previewDocuments, (document) => documentId == document.id)
    if (!documentData) {
      return
    }

    try {
      setStatusUpdateData({
        loading: true,
        errors: undefined
      })

      await ESignResource.postUpdateApprovalDocument(documentData.approval.id, documentData.id, status, feedback || '')

      setStatusUpdateData({
        errors: undefined,
        loading: false,
      })

      _updateDocumentWithData(documentData.approval.id, documentData.id, status, feedback || '')

      props.dispatch(displaySuccessSnackBar(`Der Status von ${documentData.document_name} wurde erfolgreich aktualisiert.`))
    } catch (errors) {
      setStatusUpdateData({
        loading: false,
        errors: errors.hasOwnProperty('data') ? errors.data : errors
      })
      props.dispatch(displayErrorSnackBar(`Beim Aktualisieren des Status für ${documentData.document_name} ist ein Fehler aufgetreten.`))
    }
  }

  const _updateDocumentWithData = (approvalId, documentId, status, feedback) => {

    const approvalsCopy = _.cloneDeep(approvals)
    const previewDocumentsCopy = _.cloneDeep(previewDocuments)

    const syncDocumentData = (document) => {
      if (document.id != documentId) {
        return
      }

      document.status = status
      document.feedback = feedback
    }

    approvalsCopy.forEach((approval) => {

      if (approval.id != approvalId) {
        return
      }

      approval.documents.forEach(syncDocumentData)
      approval.full_documents_list.forEach(syncDocumentData)
    })
    previewDocumentsCopy.forEach((document) => {
      if (document.id != documentId) {
        return
      }

      document.status = status
      document.feedback = feedback
    })

    setApprovalsData({
      ...approvalsData.data,
      result: approvalsCopy
    })
    setPreviewDocuments(previewDocumentsCopy)

  }

  const togglePreviewSection = () => setPreviewActive((isActive) => !isActive)

  const handleFilterBusinessCases = () => {
    if (Object.entries(filters).length !== 0) {
      let approvalsFiltered = [...filterBusinessCasesApprovals(approvals, filters)]

      approvalsFiltered = sortApprovals(approvalsFiltered, filters.sortingType);

      setApprovalsFiltered([...paginateArray(approvalsFiltered, 10)])
    }
  }

  const maxInvestedAmount = React.useMemo(() => {
    if (_.isEmpty(approvals)) {
      return 0
    }

    const maxInvestedAmountApproval =  _.maxBy(approvals,
      (approval) => _.get(approval, 'business_case.total_amount') || 0)

    return _.get(maxInvestedAmountApproval, 'business_case.total_amount') || 0
  }, [approvals])

  const minApprovalDate = React.useMemo(() => {
    if (_.isEmpty(approvals)) {
      return undefined
    }

    const minDateApproval =  _.minBy(approvals,
      (approval) => moment(_.get(approval, 'business_case.created_at')))

    return moment(_.get(minDateApproval, 'business_case.created_at'), 'YYYY-MM-DD')
  }, [approvals])

  const maxApprovalDate = React.useMemo(() => {
    if (_.isEmpty(approvals)) {
      return undefined
    }

    const maxDateApproval =  _.maxBy(approvals,
      (approval) => moment(_.get(approval, 'business_case.created_at')))

    return moment(_.get(maxDateApproval, 'business_case.created_at'), 'YYYY-MM-DD')
  }, [approvals])

  const approvalsPageData = approvalsFiltered[page <= approvalsFiltered.length - 1 ? page : approvalsFiltered.length - 1]

  const handleApplyFilters = (updatedFilters) => {
    setFilters({ ...updatedFilters, triggerFilters: true });
  }

  const handleClientTypeFilterSearch= (searchValue) => {
    if (searchValue) {
      fetchBrokersDropdownOptions({ search: searchValue })
    } else {
      fetchBrokersDropdownOptions()
    }
  }

  const handleSortingTypeChange = (item) => {
    setFilters({ ...filters, sortingType: item })
  }

  const RESET_FILTERS_OPTIONS = {
    status: APPROVAL_STATUS_FILTER_OPTIONS.ALL,
    eventType: EVENT_TYPES_FILTER_OPTIONS.ALL,
    serviceConcept: SERVICE_CONCEPT_FILTER_OPTIONS.ALL,
    financialInvestment: dynamicFilters.investmentType.default,
    customerType: defaultCustomerType,
    search: '',
    mandant: dynamicFilters.mandants.default,
    investedValuesRange: undefined,
    datesRange: undefined,
    sortingType: filters.sortingType,
    triggerFilters: false
  }

  const setStatusUpdateErrors = (newState) =>{
    setStatusUpdateData({...statusUpdateData, errors: newState})
  }

  const customerTypeOptions = React.useMemo(() => {
    if (auth && auth.user) {
      return getAgencyFilterOptions(UserUtils.isChief(auth), auth.user.sub_agencies, auth.user.agency, approverView && (brokersDropdownOptions.data || [])).map(a => ({
        ...a,
        id: a.value,
        name: a.description
      }));
    }
    return [];
  }, [auth, brokersDropdownOptions])

  return (
    <DocumentsPreviewSectionContext.Provider
      value={{
        documents: previewDocuments,
        onlineDocuments: previewDocuments,
        onAddDocumentToPreview: handleAddDocumentToPreview,
        onRemoveDocumentFromPreview: handleRemoveDocumentFromPreview,
        onSaveDocumentChanges: handleSaveDocumentChanges,
        statusUpdateLoading: statusUpdateData.loading,
        statusUpdateErrors: statusUpdateData.errors,
        setStatusUpdateErrors: setStatusUpdateErrors,
        editable: approverView
      }}
    >
      <Container>
        <SwitchTransition mode={"out-in"}>
          <CSSTransition
            key={!previewActive ? "list" : "preview"}
            addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
            classNames={classes.fade}
          >
            {!previewActive ? (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} style={{marginBottom: 5, display: 'flex', justifyContent: 'space-between'}}>
                    <h1 className={classes.header}>Auftragsbuch</h1>
                    <span>
                    <AllowEmptyBadge badgeContent={previewDocuments.length} color="primary">
                      <IconButton
                        ref={previewButtonRef}
                        className={classes.previewButton}
                        disabled={!previewDocuments.length}
                        onClick={togglePreviewSection}
                      >
                        <i className="far fa-eye fa-md" />
                      </IconButton>
                    </AllowEmptyBadge>
                  </span>
                  </Grid>
                  <Grid item xs={12} style={{marginBottom: 20}}>
                    <Filters
                      initValue={filters}
                      defaultValue={_.cloneDeep(RESET_FILTERS_OPTIONS)}
                      label="Aufträge filtern"
                      onSubmit={handleApplyFilters}
                    >
                      <Filters.SimpleDropdown
                        loading={approvalsData.loading}
                        name="status"
                        label="Status"
                        placeholder="Status"
                        options={APPROVAL_STATUS_FILTER_OPTIONS}
                        GridProps={{
                          md: 4,
                          sm: 6,
                          xs: 12
                        }}
                      />
                      <Filters.SimpleDropdown
                        loading={approvalsData.loading}
                        name="eventType"
                        label="Art/Bereich"
                        placeholder="Art/Bereich"
                        options={EVENT_TYPES_FILTER_OPTIONS}
                        GridProps={{
                          md: 4,
                          sm: 6,
                          xs: 12
                        }}
                      />
                      <Filters.SimpleDropdown
                        loading={approvalsData.loading}
                        name="serviceConcept"
                        label="Serviceplan"
                        placeholder="Serviceplan"
                        options={SERVICE_CONCEPT_FILTER_OPTIONS}
                        GridProps={{
                          md: 4,
                          sm: 6,
                          xs: 12
                        }}
                      />
                      <Filters.SimpleDropdown
                        loading={approvalsData.loading}
                        name="financialInvestment"
                        label="Finanzanlage"
                        placeholder="Finanzanlage"
                        options={dynamicFilters.investmentType.options}
                        GridProps={{
                          md: 4,
                          sm: 6,
                          xs: 12
                        }}
                      />
                      <Filters.SimpleDropdown
                        loading={approvalsData.loading}
                        name="mandant"
                        label="Mandant"
                        placeholder="Mandant"
                        options={dynamicFilters.mandants.options}
                        GridProps={{
                          md: 4,
                          sm: 6,
                          xs: 12
                        }}
                      />
                      {approverView ? (
                        <Filters.DropdownInput
                          loading={brokersDropdownOptions.loading}
                          name="customerType"
                          label="Mitarbeiter"
                          placeholder="Mitarbeiter"
                          options={customerTypeOptions}
                          onInputChange={handleClientTypeFilterSearch}
                          onChangeCallback={(setFilterFunction, item) => {
                            setFilterFunction('requestType', item.type)
                          }}
                          GridProps={{
                            md: 4,
                            sm: 6,
                            xs: 12
                          }}
                        />
                      ) : (
                        <Filters.SimpleDropdown
                          loading={brokersDropdownOptions.loading}
                          name="customerType"
                          label="Mitarbeiter"
                          placeholder="Mitarbeiter"
                          options={customerTypeOptions}
                          onChangeCallback={(setFilterFunction, item) => {
                            setFilterFunction('requestType', item.type)
                          }}
                          GridProps={{
                            md: 4,
                            sm: 6,
                            xs: 12
                          }}
                        />
                      )}
                      <Filters.SimpleDateRange
                        name="datesRange"
                        loading={approvalsData.loading}
                        minDate={minApprovalDate}
                        maxDate={maxApprovalDate}
                        useMinMaxDateOnInit={true}
                        noValidation={true}
                        disableDatesCallback={() => false} // Allow to select weekends
                        GridProps={{
                          md: 4,
                          sm: 6,
                          xs: 12
                        }}
                      />
                      <Filters.SliderRangeSelect
                        name="investedValuesRange"
                        label="Anlagebetrag"
                        loading={approvalsData.loading}
                        max={maxInvestedAmount}
                        noValidation={true}
                        className="customGrid-md-5"
                        GridProps={{
                          md: 5,
                          sm: 6,
                          xs: 12
                        }}
                      />
                      <Filters.SubmitButton
                        className="customGrid-md-3"
                        disabled={approvalsData.loading}
                      />
                    </Filters>
                  </Grid>
                  {approvalsData.loading ? (
                    <Grid item className={classes.loadingContainer} xs={12}>
                      <CircularProgress color="primary" size={50}/>
                    </Grid>
                  ) : (
                    <>
                      {approvalsPageData && approvalsPageData.length ? (
                        <>
                          <Filters.SortingDropdown
                            options={APPROVALS_SORTING_OPTIONS}
                            styles={{ marginBottom: 20 }}
                            value={(filters.sortingType || {}).value}
                            onChange={handleSortingTypeChange}
                          />
                          {approvalsFiltered && approvalsFiltered.length > 1 && (
                            <Pagination handlePageChanged={setPage} totalPageCount={approvalsFiltered.length} currentPage={page}/>
                          )}
                          {approvalsPageData.map((approval) => (
                            <Grid item xs={12} style={{marginBottom: 10}}>
                              <BusinessCaseApproval approval={approval} approverView={approverView} key={approval.id}/>
                            </Grid>
                          ))}
                          {approvalsFiltered && approvalsFiltered.length > 1 && (
                            <Pagination handlePageChanged={setPage} totalPageCount={approvalsFiltered.length} currentPage={page}/>
                          )}
                        </>

                      ) : (
                        <>
                          {approvalsData.errors ? (
                            <Grid item className={classes.loadingContainer} xs={12}>
                              <p>Beim Laden der Geschäftsfalldaten trat ein Fehler auf. Bitte versuchen Sie es später noch einmal.</p>
                            </Grid>
                          ) : (
                            <Grid item className={classes.loadingContainer} xs={12}>
                              <p>Es sind keine Daten vorhanden.</p>
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </>
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={12} style={{marginBottom: 40, display: 'flex', justifyContent: 'space-between'}}>
                  <h1 className={classes.header}>Auftragsbuch - Vorschau</h1>
                  <span>
                    <IconButton
                      className={classes.previewButton}
                      onClick={togglePreviewSection}
                      disabled={statusUpdateData.loading}
                    >
                      <i className="far fa-list fa-md" />
                    </IconButton>
                  </span>
                </Grid>
                <DocumentsPreviewSection />
              </Grid>
            )}
          </CSSTransition>
        </SwitchTransition>
      </Container>
    </DocumentsPreviewSectionContext.Provider>
  )
}

export default connect()(SignedDocumentsApproval)