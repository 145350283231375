import {makeStyles} from "@material-ui/core";
import { FORM_FIELD_HEIGHT } from '../../../../containers/TransactionsMonitoring/components/Filters/components/common_styles';

export default makeStyles((theme) => ({
  inputContainer: {
    display: 'flex',
    position: 'relative',
    '& .MuiInputBase-root': {
      background: 'white',
      marginTop: 0,
    },
    '& .MuiInputLabel-root': {
      fontSize: 16,
      top: 0,
    },
    '& .fa': {
      color: '#ADB6BD',
      fontSize: '1.25rem',
      padding: '0 0.5rem',
    }
  },
  searchButton: {
    width: '100%',
    height: FORM_FIELD_HEIGHT,
    // fontSize: 15,
    marginTop: 24,
    [theme.breakpoints.down('sm')]: {
        marginTop: 0,
    },
    fontFamily: 'Roboto-Bold',
    textTransform: 'none!important',
    boxShadow: 'none!important',
    borderRadius: 4,
    '& .fa': {
      marginRight: '0.5rem',
      position: 'relative',
      top: -1,
    }
  },
  clearIcon: {
    position: 'absolute',
    top: 10,
    right: 120,

    '&:hover': {
      cursor: 'pointer'
    }
  },
  filteringWrapper: {
    backgroundColor: '#B5BBC2',
    width: '100%',
    marginTop: 14,
    padding: '1rem',
    borderRadius: 5,
  },
  tooltip: {
    width: 265,
    fontSize: 14,
  }
}));