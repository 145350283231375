import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 60,
    backgroundColor: '#0092E5',
    zIndex: 3, // same as expanded dropdown
  },
  footerContentContainer: {
    maxWidth: '1368px!important'
  },
  footerButton: {
    position: 'relative',
    fontFamily: 'Roboto-Medium!important',
    backgroundColor: 'white!important',
    color: `${theme.palette.primary.main}!important`,
    fontSize: '16px!important',
    textTransform: 'none!important',
    lineHeight: '21px!important',
    boxShadow: 'none!important',
    padding: '7px 15px!important',
    width: 234,
    height: 35,

    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    }
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  successMessage: {
    marginRight: 125,
    color: 'white',
    fontFamily: 'Roboto-Medium',
    fontSize: 16
  }
}));