import React from "react";
import _ from "lodash";

import {InteractService} from "../../RiskProfiling/services";
import {QuestionnairesHandlerResource} from "../../../utils/api";
import {getUID} from "../../RiskProfiling/utils";
import {SECURITY_DOCUMENT_HANDED_STEP_UIDS} from "../../RiskProfiling/constants";


export class RiskProfileMenuItem extends InteractService {
  constructor(uid, name, customer_id, next_btn, success_text) {
    super(uid, name, next_btn, success_text);
    this._customer_id = customer_id;
  }

  get __shouldSkipSecurityStep(){
    return this.step && SECURITY_DOCUMENT_HANDED_STEP_UIDS.includes(getUID(this.step.uid)) && this.isSellOnlyFlow;
  }

  async __skipSecurityStep(){
    if (this.__shouldSkipSecurityStep) {
      this.cleanStepAnswers(this.step);

      await this.nextStep()
    }
  }

  async initQuestionnaire(defaultAnswers, initOnly, lastFinishedStep) {
    await super.initQuestionnaire(defaultAnswers, initOnly, lastFinishedStep);

    await this.__skipSecurityStep();
  }

  async __setQuestionnaire(onboardingAnswers, initOnly){
    // clean step data
    this._step = null;
    this._isFirstStep = false;
    this._isLastStep = false;
    this._progress = 0;
    this._is_finished = false;

    await super.__setQuestionnaire(onboardingAnswers, initOnly);

  }

  async confirmResult(data) {
    if (this.srri) {
      this._is_finished = true;

      // set existing questionnaire to current process
      await QuestionnairesHandlerResource.at('set_onboarding_questionnaire/').post({
        customer_id: this.customer_id,
        session_id: this._session_id,
        onboarding_uid: this.__onboarding_uid,
      })
    } else {
      await super.confirmResult(data);
    }
  }

  async __setStep(direction, index) {
    super.__setStep(direction, index);

    if(this.isSellOnlyFlow){
      const currStepUID = _.get(this, 'step.uid');
      const shownSteps = _.get(this, '_questionnaire.steps', []).filter(
        s => !SECURITY_DOCUMENT_HANDED_STEP_UIDS.includes(getUID(s.uid)));

      const currentStepIdx = _.findIndex(shownSteps, s => s.uid === currStepUID);

      if(currentStepIdx !== -1){
        const stepsLength = shownSteps.length;
        this._isFirstStep = currentStepIdx === 0;
        this._isLastStep = currentStepIdx === (stepsLength - 1);
        this._progress = Math.round(currentStepIdx / (stepsLength - 1) * 10000) / 100;
        this._curr_step_index = currentStepIdx + 1;
      }
    }
  }

  async nextStep(onlySendData=false) {
    await super.nextStep(onlySendData)
    await this.__skipSecurityStep();
  }

  async prevStep() {
    await super.prevStep()
    if (this.__shouldSkipSecurityStep) {
      await this.prevStep()
    }
  }
}