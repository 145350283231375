import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { BcaDeleteIcon, BcaEditIcon } from '../../../../../../images/BcaIcon';
import ConfirmationDialog from '../../../../../VirtualPortfolioManager/modals/ConfirmationDialog';
import { buildDeleteOrderConfirmationMessage } from '../../../../utils';
import CustomerThemeProvider from '../../../../../../components/CustomerThemeProvider/CustomerThemeProvider';
import { HistoricalChart } from '../../../../../CustomerDashboardV2/components/Widgets/components';
import { MODEL_PORTFOLIO_TYPES } from '../../../../constants';
import useFetchData from '../../../../../../hooks/useDataFetch';
import { useSelectedItems } from '../../../../../../hooks/useSelectedItems';
import useStyles from '../../../../../ProfessionalView/Tabs/PerformanceTab/styles';
import { isTransactionSelectable } from '../../../../../TransactionsMonitoring/utils';
import { getDataRetrievingParamsFromProps } from '../../../../../CustomerDashboardV2/utils';


const TransactionsTab = (props) => {
  const {
    expandedItems,
    isProfessionalViewAssetsEnabled,
    aggregatedPortfolioName,
    withTransactionsOverview,
    viewOnly,
    onExpandedItemsChange,
    selectedAssets,
    refreshDataTrigger,
  } = props;

  const {
    customerId,
    portfolios,
    dates,
    dataProvider,
    withHistorical,
    investmentStrategyId,
  } = getDataRetrievingParamsFromProps(props);

  const classes = useStyles();
  const [transactionsData, _fetchTransactionsData] = useFetchData(
    `${dataProvider.resourceUrl}customer/${customerId}/transactions/`, 'post');

  const selectedOrders = useSelectedItems();
  const [transactionToDelete, setTransactionToDelete] = React.useState(undefined);
  const [transactionEditInProgress, setTransactionEditInProgress] = React.useState(false);
  const [transactionDeleteInProgress, setTransactionDeleteInProgress] = React.useState(false);

  const handleOnExpandedChange = (expandedItem) => (value) => {
    onExpandedItemsChange(expandedItem, value)  // handleExpandedItemsChange
  };

  React.useEffect(() => {
    if (!isProfessionalViewAssetsEnabled || !_.isEmpty(selectedAssets) || !!refreshDataTrigger) {
      fetchTransactionsData();
    }
  }, [refreshDataTrigger, props.calculationDates]);

  const trackingStartDate = (() => {

    if (!!props.modelPortfolio) {
      return _.get(props.modelPortfolio, 'data.tracking_start_date')
    }

    // TODO: For now it works for one portfolio only.
    //  In case it will be required to connect this functionality
    //  to multiple depots selection - this part should be updated.
    const portfolio = _.get(props.investmentData, 'data.portfolios.0');

    return portfolio && portfolio.tracking_start_date

  })();

  const fetchTransactionsData = async () => {

    selectedOrders.cleanSelected();

    _fetchTransactionsData({
      start_date: dates.start,
      end_date: dates.end || moment().format('YYYY-MM-DD'),
      instruments: [],
      portfolios: [],
      transaction_types: [],
      instrument_types: [],
      with_transaction_types: true,
      group_related: true
    });
  };

  const allSelectableOrders = _.filter(
      _.get(transactionsData, 'data.orders.data.0') || [], (item) => isTransactionSelectable(item));
  const allOrdersSelected = allSelectableOrders.length === Object.values(selectedOrders.selectedItems).length;

  const handleEditOrderClick = async (order) => {
    setTransactionEditInProgress(true);
    props.handleEditOrderClick && await props.handleEditOrderClick(_.isArray(order) ? order : [order]);
    setTransactionEditInProgress(false);
  };

  const handleDeleteOrderClick = async (order, useConfirmation=false) => {
    setTransactionDeleteInProgress(true);
    props.handleDeleteOrderClick && await props.handleDeleteOrderClick(
      _.isArray(order) ? order : [order], false, useConfirmation,
      allOrdersSelected);
    setTransactionDeleteInProgress(false);
    setTransactionToDelete(undefined);
  };

  const handleAddTransactionToDelete = async (order) => setTransactionToDelete(order);

  const handleSelectAllOrders = () => {
    if (allOrdersSelected) {
      selectedOrders.handleUnselectMany(allSelectableOrders);
    } else {
      selectedOrders.handleSelectMany(allSelectableOrders);
    }
  };

  const handleSelectOrder = (order) => {
    selectedOrders.handleToggleItem(order);
  };


  return (
    <Grid item xs={12} id="historical-chart">
      <CustomerThemeProvider>
        <HistoricalChart
          customerId={customerId}
          startDate={dates.start}
          endDate={dates.end}
          dataProvider={dataProvider}
          withHistorical={withHistorical}
          portfolios={portfolios}
          expanded={expandedItems.historicalChart}
          onExpanded={handleOnExpandedChange("historicalChart")}
          onChartSettingsChange={props.onChartSettingsChange}
          showInvestmentCapital={props.chartsSettings.historical.withInvestiertesAmount}
          isCumulativeDisplaySwitchDisabled
          aggregateSelectedPortfolios={props.chartsSettings.historical.aggregateSelected}
          aggregatedPortfolioName={aggregatedPortfolioName}
          investmentStrategyId={investmentStrategyId}
          TransactionsOverviewProps={{
            enabled: withTransactionsOverview,
            expanded: expandedItems.transactionsOverview,
            onExpanded: handleOnExpandedChange("transactionsOverview"),
            editable:viewOnly,
            data: transactionsData,
            validateTransactionsExecutionDate: [
              MODEL_PORTFOLIO_TYPES.PUBLIC,
              MODEL_PORTFOLIO_TYPES.CUSTODIAN_AGENCY].includes(
                _.get(props.modelPortfolio, 'data.access_type')),
            onEditClick: handleEditOrderClick,
            onDeleteClick: handleAddTransactionToDelete,
            onSelect: handleSelectOrder,
            onAllSelect: handleSelectAllOrders,
            selected: Object.values(selectedOrders.selectedItems),
            dataProvider,
            trackingStartDate,
            customerId,
            deleteInProgress: transactionDeleteInProgress,
            editInProgress: transactionEditInProgress,
            totalSelectableTransactionsCount: allSelectableOrders.length
          }}
        />
        {Object.values(selectedOrders.selectedItems).length > 0 && (
          <div className={classes.footerContainer}>
            <Container className={classes.footerContentContainer}>
              <Grid container alignItems={"center"} justify={"flex-end"}>
                <Button
                  variant="contained"
                  className={classes.footerButton}
                  disabled={transactionDeleteInProgress || transactionEditInProgress}
                  onClick={() => handleEditOrderClick(Object.values(selectedOrders.selectedItems))}
                >
                  <BcaEditIcon variant={"far"}/> <span>Ausgewählte bearbeiten</span>
                </Button>
                <Button
                  variant="contained"
                  className={classes.footerButton}
                  disabled={transactionDeleteInProgress || transactionEditInProgress}
                  onClick={() => setTransactionToDelete(Object.values(selectedOrders.selectedItems))}
                >
                  <BcaDeleteIcon variant={"far"} /> <span>Ausgewählte löschen</span>
                </Button>
              </Grid>
            </Container>
          </div>
        )}
        <ConfirmationDialog
          message={buildDeleteOrderConfirmationMessage(transactionToDelete || {})}
          open={!!transactionToDelete || transactionDeleteInProgress}
          onClose={() => !transactionDeleteInProgress && setTransactionToDelete(undefined)}
          loading={transactionDeleteInProgress}
          confirm={() => handleDeleteOrderClick(transactionToDelete)}
        />
      </CustomerThemeProvider>
    </Grid>
  )
};

export default TransactionsTab;