import React from 'react';
import Link from '../../../../../../../../../../../../../components/Link';
import { openInNewTab } from '../../../../../../../../../../../../../utils/utils';

const PortfolioBuilderInfoText = () => {
  const onClick = () => openInNewTab('https://rise.articulate.com/share/laVgPv_AMJlMgnudkg6XyeKZztuh2iJa');

  return (
    <>
      Informationen zum Portfolio-Builder finden Sie&nbsp;
      <Link title={'hier'} onClick={onClick} />
    </>
  )
};

export default PortfolioBuilderInfoText;
