import CommonAnalysisCard from "../../../../components/AnalysisCard";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import {toShortGermanFormat} from "../../../../../../utils/numberFormater";
import {VirtualPortfolioHandlerResource} from "../../../../../../utils/api";
import WarningTooltip from "../../../../../../components/WarningTooltip";
import InfoIcon from "@material-ui/icons/Info";
import clsx from "clsx";
import EsgScore from "../../../../../FactSheetsPage/Tabs/KeyFiguresAndRatings/items/EsgScore";
import Grid from "@material-ui/core/Grid";

export class BaseEsgScoreChart extends React.Component {

  ESG_TOOLTIP_TEXT_GENERAL = 'Der historische Morningstar Nachhaltigkeits-Score ist ein gewichteter Durchschnitt der ' +
    'letzten 12 Monate der Morningstar Portfolio Nachhaltigkeits-Scores. Es wird nach Risiken für ' +
    'Unternehmen und Risiken für Staaten differenziert. Die Scores der beiden Unterrisiken werden ' +
    'dann zu einem Gesamtscore zusammengeführt. Hierbei werden die Scores aus der jüngeren Vergangenheit ' +
    'stärker gewichtet als ältere Scores des 12-Monats-Zeitraums. Basierend auf dem Historischen ' +
    'Morningstar Nachhaltigkeits-Score werden den Fonds absolute Kategorien und prozentuale Ränge ' +
    'innerhalb der Morningstar Global Categories zugewiesen. Das bedeutet, dass durch den Ausweis ' +
    'absoluter Zahlen ein Fonds auch über Fondskategorien bzw. Vergleichsgruppen hinweg vergleichbar wird.';

  constructor(props) {
    super(props);

    this.state = {
      dataProvider: null,
      loading: true,
      errors: null,
      data: null
    }

    this.min = 0;
    this.max = 50;

    this.fetchEsgData = this.fetchEsgData.bind(this);
  }

  componentDidMount() {
    this.fetchEsgData()
  }

  fetchEsgData(){}

  calcScorePercent() {
    return ((this.state.data.historical_sustainability_score || 0) / this.max) * 100
  }

  renderValue(value) {
    return value === null || value === undefined ? 'k.A.' : toShortGermanFormat(value, '', '', 2, false)
  }

  renderData() {
    const { classes } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <EsgScore
                  score={this.state.data.historical_corporate_sustainability_score}
                  title={<>Morningstar Historischer Sustainability Score (Unternehmen)</>}
                  tooltip={<>{this.ESG_TOOLTIP_TEXT_GENERAL}</>}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <EsgScore
                  score={this.state.data.historical_sovereign_sustainability_score}
                  title={<>Morningstar Historischer Sustainability Score (Staaten)</>}
                  tooltip={<>{this.ESG_TOOLTIP_TEXT_GENERAL}</>}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
        classes={{
          card: classes.root
        }}
        title={<span>Nachhaltigkeitsanalyse (ESG)</span>}
        loading={this.state.loading}
        content={
          <div className={classes.container}>
            {this.state.errors ? <div className={classes.error}>{this.state.errors}</div> : this.state.data ? this.renderData() : 'Keine Daten verfügbar'}
          </div>
        }
        expanded={this.props.expanded}
        onExpandedClick={this.props.onExpandedClick}
    />
  }
}

class EsgScoreChart extends BaseEsgScoreChart {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      dataProvider: VirtualPortfolioHandlerResource
    }
  }

  fetchEsgData() {
    this.state.dataProvider.getEsgScoreData(this.props.customerId, [this.props.portfolioId], undefined, undefined)
        .then(
            (data) => {
                let processedData = data['esg_score'];
                if (processedData['errors'].length > 0) {
                    this.setState({
                        errors: processedData['errors'][0],
                        data: null,
                        loading: false
                    })
                } else {
                    this.setState({
                        data: processedData['data'][0],
                        loading: false,
                        errors: null
                    })
                }
            })
        .catch((error) => {
            this.setState({
                errors: error,
                loading: false,
                data: false
            })
        })
  }
}

export default withStyles(styles)(EsgScoreChart)