import React from 'react';
import {connect} from 'react-redux';
import clsx from 'clsx';

/* BCA Components*/
import TimeFramePicker from './PortfolioTimeSelector';
import PortfolioList from '../../../../components/ProfileDetails/components/PortfolioList';

/* BCA modules */
import useStyles from './styles';
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import {isTradingEnabled} from "../../../../components/TradingStore/utils";
import Switch from "../../../../components/AssetModal/components/Switch";
import {Grid} from "@material-ui/core";
import _ from 'lodash';
import {getGuideSelector} from "../../CustomerDashboard";
import {getCustomerDashboardSelector} from "../../../../components/DashboardDataProvider/DashboardDataProvider";
import {DashboardPortfoliosSelectorContext} from "../../utils";

const mapStateToProps = (state) => ({
  customerDashboard: getCustomerDashboardSelector(state),
  guide: getGuideSelector(state)
});

const Navigation = connect(mapStateToProps)((props) => {
  const classes = useStyles();

  const {
    includeHistoricalPortfolios,
    setIncludeHistoricalPortfolios,
    calculationDates,
    handleCalculationDatesChanged,
    portfolios,
    portfoliosLoadingError,
    portfoliosDataLoading,
    handleSelectedPortfolioChanged,
    selectedPortfolios,
    investmentData,
    alwaysScrollableList,
    isVirtual,
    dataLoading,
    stickyContainer,
    onGoToTradeDetails,
    instrumentListDataLoading,
    customerHasTradings,
    tradingSession,
    dispatch,
    customerDashboard,
    isCustomerApp,
    renderCustomSwitch,
    calculationDatesType
  } = props;

  const withOrderBtn = stickyContainer && typeof onGoToTradeDetails === 'function' && isTradingEnabled() && !instrumentListDataLoading;
  {/* sm=true -> will take all available width */}
  const itemsSize = withOrderBtn ? true : (isCustomerApp ? 6 : 5);
  return (
    <div className={classes.container} id={'investment-navigation-component'}>
      <Grid container style={{width: '100%', justifyContent: 'space-between'}} spacing={1}>
        {/* sm=true -> will take all available width */}
        {!_.isNil(handleCalculationDatesChanged) &&
          <Grid item xs={12} sm={itemsSize} className={classes.timeFrameContainer}>
            <TimeFramePicker
              calculationDates={calculationDates}
              calculationDatesType={calculationDatesType}
              handleCalculationDatesChanged={handleCalculationDatesChanged}
              startOfInvestment={investmentData && investmentData.start_date}
              isVirtual={isVirtual}
              loading={dataLoading}
              stickyContainer={stickyContainer}
              dispatch={dispatch}
              timeSelectorDateChange={customerDashboard && customerDashboard.time_selector_date_change}
              showNoPriceOnWeekendsWarning
            />
          </Grid>
        }

        { handleSelectedPortfolioChanged &&
          <Grid item xs={12} sm={itemsSize} className={classes.portfoliosContainer}>
            <PortfolioList
              selectedPortfolios={selectedPortfolios}
              handleSelectedPortfolioChanged={handleSelectedPortfolioChanged}
              portfolios={portfolios}
              portfoliosLoadingError={portfoliosLoadingError}
              portfoliosDataLoading={portfoliosDataLoading}
              alwaysScrollableList={alwaysScrollableList}
              isVirtual={isVirtual}
              loading={dataLoading}
              stickyContainer={stickyContainer}
            />
          </Grid>
        }

        {withOrderBtn && (
          <Grid item xs={12} sm='auto'>
            <PrimaryButton
              icon={<i className="far fa-dollar-sign fa-xs" style={{color: customerHasTradings ? 'white' : 'rgba(0, 0, 0, 0.26)'}} />}
              text={!!tradingSession ? "ORDER FORTSETZEN" : "ORDER"}
              customClasses={{
                root: classes.buttonRoot
              }}
              disabled={!customerHasTradings}
              onButtonClick={(event) => onGoToTradeDetails(event)}
            />
          </Grid>
        )}

        {(!isCustomerApp && !_.isFunction(renderCustomSwitch)) &&
          <Grid item sm={withOrderBtn ? 'auto' : 2}>
            {/* todo hide switch if there are only historical portfolios */}
            <div id="historical-depots-checkbox" className={classes.includeHistoricalSwitch}>
              <p className={classes.includeHistoricalSwitchLabel}>historische Depots</p>
              <Switch
                value={includeHistoricalPortfolios || false}
                handleValueChanged={setIncludeHistoricalPortfolios}
                disabled={dataLoading || _.isNil(includeHistoricalPortfolios)}
              />
            </div>
          </Grid>
        }

        {_.isFunction(renderCustomSwitch) &&
          renderCustomSwitch()
        }

      </Grid>
    </div>
  )
});


export function DashboardPortfoliosSelectorSharedState({children}) {

  const [selectedPortfolios, setSelectedPortfolios] = React.useState([]);

  return (
    <DashboardPortfoliosSelectorContext.Provider value={{selectedPortfolios, onSelectedPortfoliosChanged: setSelectedPortfolios}}>
      {children}
    </DashboardPortfoliosSelectorContext.Provider>
  )
}


export const StickyNavigation = connect(mapStateToProps)((props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.stickyContainer, props.guide.active)}  id={'investment-navigation-component-sticky'}>
      <Navigation alwaysScrollableList stickyContainer {...props}/>
    </div>
  )
});


export default Navigation;



