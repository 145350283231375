import { makeStyles } from '@material-ui/core';
import {smFontSize, xsFontSize} from "../../../utils/constants";

const instrumentsListFooterClasses = (paddingRight=30) => ({
  tableFooter: {
    '& $tableCell': {
      '&:nth-of-type(2)': {
        width: 150,
      },
      '&:nth-of-type(3)': {
        width: 120,
        paddingRight: paddingRight,
      },
    }
  },
})

export default makeStyles((theme) => ({
  table: {
    minWidth: 860,
  },
  tableCell: {
    '&:nth-of-type(1)': {
      width: 40,
    },
    '&:nth-of-type(2)': {
      width: 'auto',
    },
    '&:nth-of-type(3)': {
      width: 140,
    },
    '&:nth-of-type(4)': {
      width: 50,
    },
    '&:nth-of-type(5)': {
      width: 100,
    },
    '&:nth-of-type(6)': {
      width: 110,
    },
    '&:nth-of-type(7)': {
      width: 150,
    },
    '&:nth-of-type(8)': {
      width: 120,
      paddingRight: 30,
    },
  },

  ...instrumentsListFooterClasses(),

  [theme.breakpoints.down('sm')]: {
    table: {
      minWidth: 690,
    },
    tableCell: {
      '&:nth-of-type(1)': {
        width: 35,
      },
      '&:nth-of-type(2)': {
        width: 'auto',
      },
      '&:nth-of-type(3)': {
        width: 105,
      },
      '&:nth-of-type(4)': {
        width: 30,
      },
      '&:nth-of-type(5)': {
        width: 100,
      },
      '&:nth-of-type(6))': {
        width: 85,
      },
      '&:nth-of-type(7)': {
        width: 110,
      },
      '&:nth-of-type(8)': {
        width: 110,
        paddingRight: 25
      },
    },
  },

  [theme.breakpoints.only('xs')]: {
    table: {
      minWidth: 258,
    },
    tableCell: {
      '& .percentagePA::after': {
        position: 'relative',
        left: 'auto',
      },
      '&:nth-of-type(3)': {
        paddingRight: 20
      },
    },
  },

  riskIndicatorDropdown: {
    width: '50%',
    color: 'white',
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  riskIndicatorContainer: {
    marginBottom: 10,
  },

}));

export const riskIndicatorsTableClasses = makeStyles((theme) => ({
  table: {
    minWidth: 860,
  },
  riskIndicatorTable: {
    overflowX: 'auto',
  },
  tableCell: {
    '&:nth-of-type(1)': {
      width: 40,
    },
    '&:nth-of-type(2)': {
      width: 'auto',
      [theme.breakpoints.down(1380)]: {
        width: 150
      }
    },
    '&:nth-of-type(3)': {
      width: 150,
    },
    '&:nth-of-type(4)': {
      width: 100,
    },
    '&:nth-of-type(5)': {
      width: 90,
    },
    '&:nth-of-type(6)': {
      width: 90,
    },
    '&:nth-of-type(7)': {
      width: 90,
    },
    '&:nth-of-type(8)': {
      width: 90,
    },
    '&:nth-of-type(9)': {
      width: 100,
    },
    '&:nth-of-type(10)': {
      width: 130,
    },
    '&:nth-of-type(11)': {
      width: 130,
      paddingRight: 25
    },
    '&:nth-of-type(12)': {
      width: 60,
    }
  },

  [theme.breakpoints.down('sm')]: {
    table: {
      minWidth: 690,
    },
    tableCell: {
      '&:nth-of-type(3)': {
        width: 140,
      },
      '&:nth-of-type(4)': {
        width: 90,
      },
      '&:nth-of-type(5)': {
        width: 90,
      },
      '&:nth-of-type(6)': {
        width: 90,
      },
      '&:nth-of-type(7)': {
        width: 90,
      },
      '&:nth-of-type(8)': {
        width: 90,
      },
      '&:nth-of-type(9)': {
        width: 90,
      },
      '&:nth-of-type(10)': {
        width: 90,
      },
      '&:nth-of-type(11)': {
        width: 100,
        paddingRight: 15
      },
    },

    [theme.breakpoints.down('xs')]: {
      tableCell: {
        '&:nth-of-type(3)': {
          width: 60,
          fontSize: 12,
          '& button span': {
            fontSize: 16
          }
        }
      },
    }
  },

  [theme.breakpoints.only('xs')]: {
    table: {
      minWidth: 258,
    },
    tableCell: {
      '&:nth-of-type(1)': {
        width: 'auto',
      },
      '&:nth-of-type(2)': {
        width: 100,
        paddingRight: 15,
      },

      '& .percentagePA::after': {
        position: 'relative',
        left: 'auto',
      },
    },
  },

  riskIndicatorDropdown: {
    width: '50%',
    color: 'white',
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    }
  },
  riskIndicatorContainer: {
    marginBottom: 10,
  },

}));

export const useDepotstatusClasses = makeStyles((theme) => ({
  table: {
    minWidth: 1000,
  },
  tableCell: {
    '&:nth-of-type(1)': {
      width: 40,
    },
    '&:nth-of-type(2)': {
      width: 'auto',
      minWidth: '150px !important',
    },
    '&:nth-of-type(3)': {
      width: 220,
    },
    '&:nth-of-type(4)': {
      width: 80,
    },
    '&:nth-of-type(5)': {
      width: 110,
    },
    '&:nth-of-type(6)': {
      width: 100,
    },
    '&:nth-of-type(7)': {
      width: 130,
    },
    '&:nth-of-type(8)': {
      width: 150,
    },
  },

  [theme.breakpoints.down('sm')]: {
    table: {
      minWidth: 850,
    },
    tableCell: {
      '&:nth-of-type(1)': {
        width: 35,
      },
      '&:nth-of-type(2)': {
        width: 'auto',
      },
      '&:nth-of-type(3)': {
        width: 120,
      },
      '&:nth-of-type(4)': {
        width: 70,
      },
      '&:nth-of-type(5)': {
        width: 100,
      },
      '&:nth-of-type(6)': {
        width: 90,
      },
      '&:nth-of-type(7)': {
        width: 130,
      },
      '&:nth-of-type(8)': {
        width: 150,
      },
    },
  },

  [theme.breakpoints.only('xs')]: {
    table: {
      minWidth: 258,
    }
  },

}));

export const useTradingTableClasses = makeStyles((theme) => ({
  table: {
    minWidth: 1000,
  },
  ...instrumentsListFooterClasses(20),
  tableCell: {
    '&:nth-of-type(1)': {
      width: 40,
      paddingLeft: 10,
    },
    '&:nth-of-type(2)': {
      width: 'auto',
      minWidth: '150px !important',
    },
    '&:nth-of-type(3)': {
      width: 140,
    },
    '&:nth-of-type(4)': {
      width: 50,
    },
    '&:nth-of-type(5)': {
      width: 100,
    },
    '&:nth-of-type(6)': {
      width: 90,
    },
    '&:nth-of-type(7)': {
      width: 130,
    },
    '&:nth-of-type(8)': {
      width: 115,
      paddingRight: 20,
    },
    '&:nth-of-type(9)': {
      width: 150,
      paddingRight: 10
    },
  },

  [theme.breakpoints.down('sm')]: {
    table: {
      minWidth: 850,
    },
    tableCell: {
      '&:nth-of-type(1)': {
        width: 35,
      },
      '&:nth-of-type(2)': {
        width: 'auto',
      },
      '&:nth-of-type(3)': {
        width: 120,
      },
      '&:nth-of-type(4)': {
        width: 30,
      },
      '&:nth-of-type(5)': {
        width: 100,
      },
      '&:nth-of-type(6)': {
        width: 100,
      },
      '&:nth-of-type(7)': {
        width: 110,
      },
      '&:nth-of-type(8)': {
        width: 105,
        paddingRight: 15,
      },
      '&:nth-of-type(9)': {
        width: 150,
      },
    },
  },

  [theme.breakpoints.only('xs')]: {
    table: {
      minWidth: 258,
    },
    tableCell: {
      '& .percentagePA::after': {
        position: 'relative',
        left: 'auto',
      },
    },
  },

}));

export const useTradingActionsStyles = makeStyles((theme) => ({
  selectRoot: {
    textAlign: 'center',
    '&::before': {
      borderBottom: 'none',
      display: 'none',
    },
    '&::after': {
      borderBottom: 'none',
      display: 'none',
    },
  },
  selectMenu: {
  },
  selectMenuPaper: {
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    border: 'none',
  },
  selectMenuItemRoot: {
    color: 'initial!important',
    fontFamily: 'Roboto-Regular!important',
    [theme.breakpoints.only('sm')]: {
      fontSize: smFontSize,
      minHeight: '2rem'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: xsFontSize,
      minHeight: '2rem'
    },
  },
  selectMenuIcon: {
    color: '#202A38',
  },
}));

export const useTooltipStyles = makeStyles((theme) => ({
  warningWrapper: {
    display: 'inline-flex',
    position: 'relative',
    alignItems: 'center',
  },
  warningAlignment: {
    position: 'absolute',
    right: -23,

    [theme.breakpoints.down("sm")]: {
      right: -18,
    }
  },
}));

export const useVirtualTableClasses = makeStyles((theme) => ({
  table: {
    minWidth: 1000,
  },
  tableCell: {
    '&:nth-of-type(1)': {
      width: 40,
    },
    '&:nth-of-type(2)': {
      width: 'auto',
      minWidth: '150px !important',
    },
    '&:nth-of-type(3)': {
      width: 140,
    },
    '&:nth-of-type(4)': {
      width: 40,
    },
    '&:nth-of-type(5)': {
      width: 100,
    },
    '&:nth-of-type(6)': {
      width: 90,
    },
    '&:nth-of-type(7)': {
      width: 130,
    },
    '&:nth-of-type(8)': {
      width: 110,
      paddingRight: 20,  // padding for GuV info icon
    },
    '&:nth-of-type(9)': {
      width: 150,
    },
  },

  [theme.breakpoints.down('sm')]: {
    table: {
      minWidth: 850,
    },
    tableCell: {
      '&:nth-of-type(1)': {
        width: 35,
      },
      '&:nth-of-type(2)': {
        width: 'auto',
      },
      '&:nth-of-type(3)': {
        width: 120,
      },
      '&:nth-of-type(4)': {
        width: 30,
      },
      '&:nth-of-type(5)': {
        width: 100,
      },
      '&:nth-of-type(6)': {
        width: 100,
      },
      '&:nth-of-type(7)': {
        width: 110,
      },
      '&:nth-of-type(8)': {
        width: 100,
        paddingRight: 15,  // padding for GuV info icon
      },
      '&:nth-of-type(9)': {
        width: 150,
      },
    },
  },

  [theme.breakpoints.only('xs')]: {
    table: {
      minWidth: 258,
    },
    tableCell: {

      '& .percentagePA::after': {
        position: 'relative',
        left: 'auto',
      },
    },
  },
}));

export const useVirtualTableDepotstatusClasses = makeStyles((theme) => ({
  table: {
    minWidth: 1000,
  },
  tableCell: {
    '&:nth-of-type(1)': {
      width: 40,
    },
    '&:nth-of-type(2)': {
      width: 'auto',
      minWidth: '150px !important',
    },
    '&:nth-of-type(3)': {
      width: 220,
    },
    '&:nth-of-type(4)': {
      width: 80,
    },
    '&:nth-of-type(5)': {
      width: 110,
    },
    '&:nth-of-type(6)': {
      width: 100,
    },
    '&:nth-of-type(7)': {
      width: 130,
    },
    '&:nth-of-type(8)': {
      width: 150,
    },
  },

  [theme.breakpoints.down('sm')]: {
    table: {
      minWidth: 850,
    },
    tableCell: {
      '&:nth-of-type(1)': {
        width: 35,
      },
      '&:nth-of-type(2)': {
        width: 'auto',
      },
      '&:nth-of-type(3)': {
        width: 120,
      },
      '&:nth-of-type(4)': {
        width: 70,
      },
      '&:nth-of-type(5)': {
        width: 100,
      },
      '&:nth-of-type(6)': {
        width: 90,
      },
      '&:nth-of-type(7)': {
        width: 130,
      },
      '&:nth-of-type(8)': {
        width: 150,
      },
    },
  },

  [theme.breakpoints.only('xs')]: {
    table: {
      minWidth: 258,
    },
    tableCell: {
      '&:nth-of-type(1)': {
        width: 'auto',
      },
      '&:nth-of-type(2)': {
        width: 95,
      },
    },
  },
}));

export const useVirtualTableStyles = makeStyles((theme) => ({
  linkButton: {
    marginLeft: 15,
    fontFamily: 'Roboto-Bold',
    textTransform: 'none',
    minWidth: 'auto',
    padding: 0,
    '& > span': {
      color: `${theme.palette.primary.main} !important`,
    },
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));

export const useRebalancingTableStyles = makeStyles((theme) => ({
  headerContainer: {
    position: 'relative',
  },
  dataContainer: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    top: '-500%',
    textAlign: 'left',

    '&::before, &::after': {
      content: "''",
      position: 'absolute',
      top: -10,
      height: 'calc(100% + 90px)',
      borderLeft: '1px solid rgb(224, 224, 224)'
    },
    '&::before': {
      left: -21,
    },
    '&::after': {
      right: -21,
    },
  },
  name: {
    fontFamily: 'Roboto-Bold',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));
