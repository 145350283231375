import {makeStyles} from "@material-ui/core";

const overflowEllipsis = {
  display: 'inline-block',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}

export const savedComparisonsTableClasses = makeStyles((theme) => ({
  tableHead: {
    '& $tableCell': {
      backgroundColor: 'rgb(249, 249, 249)',
      color: '#80858C',
      alignItems: 'flex-end',
      height: 40
    }
  },

  tableCell: {
    verticalAlign: 'middle',
    backgroundColor: 'white',
    alignItems: 'center',
    height: 60,
    '&:last-child': {
      minWidth: 70,
    }
  },

  container: {
    overflowX: 'auto'
  },
  overflowEllipsis
}));

export const savedComparisonsTabsClasses = makeStyles(theme => ({
  tabRoot: {
    maxWidth: 'fit-content'
  }
}));