import React from 'react';

import Rating from '@material-ui/lab/Rating';
import GlobeMark from '../../containers/FactSheetsPage/Tabs/KeyFiguresAndRatings/items/GlobeMark';
import useStyles from './styles';


const RatingGlobes = ({value, fontSize, wide=false}) => {
  const classes = useStyles();

  return (
    <Rating
      readOnly
      value={value}
      icon={<GlobeMark fontSize={fontSize} withBackground={wide} />}
      emptyIcon={<GlobeMark empty fontSize={fontSize} withBackground={wide} />}
      classes={{ root: wide && classes.ratingRoot }}
    />
  )
};

export default RatingGlobes;