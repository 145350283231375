import {REPORT_TYPES} from "../../DashboardSettings/components/CustomersList/components/ReportType/constants";

const key_figures_overview_1 = {
  selector: '#kfo-user-info-tour-element',
  key: 'customer_dashboard_customer_info'
};
const key_figures_overview_2 = {
  selector: '#kfo-invested-amount-tour-element',
  key: 'customer_dashboard_customer_invested_amount'
};
const key_figures_overview_3_depot = {
  selector: '#kfo-transaction-saldo-tour-element',
  key: 'customer_app_dashboard_customer_transaction_saldo'
};
const key_figures_overview_3 = {
  selector: '#kfo-long-term-return-tour-element',
  key: 'customer_dashboard_customer_return'
};
const key_figures_overview_4 = {
  selector: '#kfo-total-invested-amount-tour-element',
  key: 'customer_dashboard_customer_total_invested_amount'
};

export const getKeyFiguresChapterSteps = (reportType, withOtherAssets) => {
  const steps = [
    key_figures_overview_1,
    key_figures_overview_2,
    reportType === REPORT_TYPES.DEPOT_STATUS.value ? key_figures_overview_3_depot : key_figures_overview_3,
  ]
  if (withOtherAssets) {
    steps.push(key_figures_overview_4)
  }

  return steps;
};
