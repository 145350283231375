import { makeStyles } from '@material-ui/core';

export const styles = (theme) => ({
  modalTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#313D44',
    marginLeft: 16
  },
  textField: {
    fontFamily: 'Roboto-Regular',
    fontSize:18,
    color: '#4D4F5C',
  },
  controlSection: {
    '& > div': {
      padding: '0 16px'
    }
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15
  },
  actionButton: {
    boxShadow: 'none',
    borderRadius: 2,
    textTransform: 'none',
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    marginLeft: 20
  },
  inputLabelRoot: {
    color: '#313D44',
    fontFamily: 'Roboto-Bold',
    fontSize: 14
  },
  inputLabelShrink: {
    transform: 'translate(0, 1.5px) scale(1)',
    color: '#313D44 !important',
  },
  inputLabelError: {
    color: 'red !important'
  },
  inputAdornment: {
    fontSize: 12,
    color: '#ABB6BE'
  },
  formHelperText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12
  }
});

export default makeStyles(styles);