import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="28"
      viewBox="0 0 30 28"
    >
      <g id="Gruppe_8158" data-name="Gruppe 8158" transform="translate(5 -100)">
        <g id="Gruppe_8114" data-name="Gruppe 8114" transform="translate(0 0)">
          <path
            id="Pfad_6867"
            data-name="Pfad 6867"
            d="M28.553,8.105l-6.612,3.306A3,3,0,0,0,19,9h-.031a5,5,0,1,0-5.938,0H7.618a4,4,0,1,0-5.168.056A3,3,0,0,0,0,12V24a3,3,0,0,0,3,3H19a3,3,0,0,0,2.94-2.412l6.612,3.306A1,1,0,0,0,30,27V9a1,1,0,0,0-1.447-.894M13,5a3,3,0,1,1,3,3,3,3,0,0,1-3-3M5,4A2,2,0,1,1,3,6,2,2,0,0,1,5,4M20,24a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H19a1,1,0,0,1,1,1Zm8,1.382-6-3V13.618l6-3ZM6.706,20.266a.845.845,0,0,1-.679-.347l-.471-.584-.461.588a.858.858,0,0,1-.287.243.818.818,0,0,1-.382.093.854.854,0,0,1-.553-.211.747.747,0,0,1-.284-.585.835.835,0,0,1,.2-.537l.723-.884-.762-.948a.825.825,0,0,1,.078-1.123.846.846,0,0,1,1.261.1l.49.61.443-.586a.822.822,0,0,1,1.257-.117.783.783,0,0,1,.24.563.843.843,0,0,1-.2.542l-.717.873.762.947a.825.825,0,0,1-.081,1.126.84.84,0,0,1-.585.242m5.437,0a.845.845,0,0,1-.679-.347l-.471-.584-.461.588a.858.858,0,0,1-.287.243.818.818,0,0,1-.382.093.854.854,0,0,1-.553-.211.747.747,0,0,1-.284-.585.835.835,0,0,1,.2-.537l.723-.884-.762-.948a.825.825,0,0,1,.078-1.123.846.846,0,0,1,1.261.1l.49.61.443-.586a.822.822,0,0,1,1.257-.117.783.783,0,0,1,.24.563.843.843,0,0,1-.195.542l-.717.873.762.947a.825.825,0,0,1-.081,1.126.84.84,0,0,1-.585.242m5.438,0a.845.845,0,0,1-.679-.347l-.47-.584-.462.588a.858.858,0,0,1-.287.243.818.818,0,0,1-.382.093.854.854,0,0,1-.553-.211.747.747,0,0,1-.284-.585.835.835,0,0,1,.2-.537l.723-.884-.762-.948a.825.825,0,0,1,.078-1.123.846.846,0,0,1,1.261.1l.49.61.442-.586a.822.822,0,0,1,1.257-.117.783.783,0,0,1,.24.563.843.843,0,0,1-.195.542l-.717.873.762.947a.825.825,0,0,1-.081,1.126.84.84,0,0,1-.585.242"
            transform="translate(-5 100)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
