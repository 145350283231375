import { isEmpty } from 'lodash'
import ExAnteStep from "./components/StepContent/components/step/ExAnteStep";
import {aggregatePortfoliosExAnte} from "../Trades/utils";
import {BankSettingsHandlerResource} from "../../utils/api";
import {Service} from "./service";
import _ from 'lodash'


const step1 = {
  "uid": "ex-ante-step",
  "name": "Ex-Ante Kosten",
  "behaviour": ["dynamic"],
  "custom_classes": {"stepName": "text-left"},
  "step_content": ExAnteStep,
  "question": [
    {
      "uid": "ex_ante",
      "type": "input",
      "isValid": function() {
        return !isEmpty(this.answer);
      }
    }
  ]
};

const exAnteStepsData = {
  steps: [
    step1,
  ]
};

export class ExAnteService extends Service {
  constructor(uid, name, customer_id, getPrevStepService) {
    super(uid, name, null, null);
    this._questionnaire = this.__deepCopy(exAnteStepsData);
    this._customer_id = customer_id;
    this._getStepService = getPrevStepService;
    this._getPrevStepAnswer = (onboarind_step_uid, step_uid, question_uid, withOption) => {
      const menu = this._getStepService(onboarind_step_uid);
      if(menu){
        return menu.getStepAnswer(step_uid, question_uid, withOption);
      }
    };
  }

  _getExAnteRequest = async (requestBody, simulationYears) => {
    const data = {...requestBody, simulating_years: simulationYears};
    let response = await BankSettingsHandlerResource.getExAnteCalculation(data);

    return {
      portfolioId: 'onboarding',
      bankCode: data.bank,
      simulationYears,
      response
    }
  };

  getExAnteData = async () => {
    const dataService = this._getStepService('products_selection');
    const custodian = dataService.getStepAnswer('product-selection-depot', 'custodians');
    const modelPortfolio = dataService.getStepAnswer('product-selection-investment-recommendation', 'instruments_source');

    let exAnteRequest = dataService.getDataForAccount(true);
    exAnteRequest['depot_type_id'] = custodian.contract_type;
    exAnteRequest['session_id'] = dataService.__onboarding_uid;

    let instrumentSourceKey = undefined;
    let instrumentSourceId = undefined;
    if (modelPortfolio) {
      instrumentSourceId = _.get(modelPortfolio, 'id');
      if (modelPortfolio.isInvestmentStrategy) {
        instrumentSourceKey = 'investment_strategy_id'
      } else if (modelPortfolio.is_modelportfolio) {
        instrumentSourceKey = 'model_portfolio_id'
      }
    }

    if (instrumentSourceKey) {
      exAnteRequest[instrumentSourceKey] = instrumentSourceId
    }

    console.info(exAnteRequest);

    const exAnteQuestion = this._questionnaire.steps[0].question[0];
    exAnteQuestion.error = null; // clean error
    try {
      const res = await this._getExAnteRequest(exAnteRequest, 5);
      exAnteQuestion.answer = aggregatePortfoliosExAnte([res], true);
    } catch (e) {
      exAnteQuestion.answer = null;
      await this.__setStep(null, 0);

      exAnteQuestion.error = e;
    }
  };

  async __setQuestionnaire(defaultAnswers, initOnly){
    this._setDefaultAnswers(defaultAnswers);

    // get ExAnte data if not restore progress or answer is missing
    if(!initOnly || isEmpty(this._questionnaire.steps[0].question[0])){
      await this.getExAnteData()
    }
  }
}
