import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx'
import _ from 'lodash'

import Grid from '@material-ui/core/Grid';
import { 
  Typography,
  CircularProgress,
} from '@material-ui/core';

import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { SignatureIcon } from '../../../../images'
import Tooltip from "../../../../components/Tooltip";

import useStyles from './styles';
import { toLocalDateTime } from '../../../../utils/utils';


function CollectionNavigation(props) {

  const classes = useStyles();

  const {
    collections,
    disabled,
    activePage,
    activeCollection,
    closeCollectionButtonText,
    downloadCollectionButtonText,
    signDocumentsButtonText,
    saveCollectionButtonText,
    onCloseCollectionButtonClick,
    onDownloadCollectionButtonClick,
    onSaveCollectionButtonClick,
    onSignCollectionButtonClick
  } = props;

  const [collectionDate, setCollectionDate] = useState(undefined)
  const [signButtonDisabled, setSignButtonDisabled] = useState(true)

  useEffect(() => {
    setSignButtonDisabled(
      disabled || !activePage || activeCollection.loading || !activeCollection.sign_possible
    )
  }, [disabled, activeCollection, activePage])

  useEffect(() => {
    setCollectionDate(getCollectionDate())
  }, [collections, activeCollection])

  const getCollectionDate = () => {
    if (activeCollection.id) {
      let updatedAt = toLocalDateTime(_.result(_.find(_.flatten(collections.data), function(obj) {
        return obj.id == activeCollection.id;
      }), 'updated_at'));

      return (
        <p className={classes.handlingTime}>
          erstellt am <b>{ updatedAt.format('DD.MM.YYYY') }</b> um <b>{ updatedAt.format('HH:mm') } Uhr</b>
        </p>
      )
    }
  }

  const signTitle = () => {
    if (activeCollection.id) {
      return activeCollection.sign_possible ? '' : 'Die ausgewählten Dokumente unterstützen leider keine digitale Signatur'
    }
    return ''
  }

  return (
    <Grid container spacing={2} alignItems="flex-end" className={classes.navContainer}>
      {activeCollection.id && (
        <Grid item xs={3} className={classes.dateContainer}>
          {activeCollection.loading ? <CircularProgress size={22}/> : collectionDate && <Typography component={'span'}>{collectionDate}</Typography>}
        </Grid>
      )}
      <Grid item xs={9} className={classes.buttonsContainer}>
        {/* CLOSE COLLECTION BUTTON */}
        {activeCollection.id && (
          <PrimaryButton
            customClasses={{root: clsx(classes.navButton, classes.navButtonActive)}}
            text={closeCollectionButtonText}
            icon={<i className="far fa-times-circle" style={{fontSize: 18}} aria-hidden="true"></i>}
            onButtonClick={() => onCloseCollectionButtonClick(activeCollection.id)}
            disabled={disabled || activeCollection.loading || !activePage}
          />
        )}
        {/* SAVE COLLECTION BUTTON */}
        <PrimaryButton
          customClasses={{root: clsx(classes.navButton, classes.navButtonActive)}}
          text={saveCollectionButtonText}
          icon={<i className="far fa-save" style={{fontSize: 18}} aria-hidden="true"></i>}
          onButtonClick={onSaveCollectionButtonClick}
          disabled={disabled || activeCollection.loading || !activePage || (activeCollection.id && !activeCollection.edited)}
        />
        {/* SIGN COLLECTION BUTTON */}
        {activeCollection.id && (
          <Tooltip
            title={signTitle()}
            placement="top"
          >
            <div
              onClick={(e) => !signButtonDisabled && onSignCollectionButtonClick()}
              className={clsx(
                'MuiButton-root',
                classes.navButton,
                signButtonDisabled ? classes.signButtonDisabled : [classes.navButtonActive, classes.signButtonActive],
              )}
            >
              <span className={'MuiButton-label'}>
                <span className={classes.signIcon}><SignatureIcon color={"#0092e5"}/></span>
                <span>{signDocumentsButtonText}</span>
              </span>
            </div>
          </Tooltip>
        )}
        {/* DOWNLOAD COLLECTION BUTTON */}
        <PrimaryButton
          customClasses={{root: clsx(classes.navButton, classes.navButtonActive)}}
          text={downloadCollectionButtonText}
          icon={<i className="fa fa-download" style={{fontSize: 18}} aria-hidden="true"></i>}
          onButtonClick={onDownloadCollectionButtonClick}
          disabled={disabled || activeCollection.loading || !activePage}
        />
      </Grid>
    </Grid>
  );

};

CollectionNavigation.propTypes = {
  closeCollectionButtonText: PropTypes.string,
  downloadCollectionButtonText: PropTypes.string,
  saveCollectionButtonText: PropTypes.string,
  signDocumentsButtonText: PropTypes.string,
  onDownloadCollectionButtonClick: PropTypes.func.isRequired,
  onSaveCollectionButtonClick: PropTypes.func.isRequired,
  activePage: PropTypes.number
};

CollectionNavigation.defaultProps = {
  closeCollectionButtonText: 'Mappe schließen',
  downloadCollectionButtonText: 'Herunterladen',
  sendDocumentButtonText: 'An Kunden senden',
  saveCollectionButtonText: 'Speichern',
  signDocumentsButtonText: 'E-Unterschreiben'
}

export default CollectionNavigation;
