const styles = theme => ({
  container: {
    position: 'relative',
    height: 'calc(100% + 16px)',
    paddingLeft: 16,
    paddingRight: 16,
    top: -8,

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },

  opened: {
    backgroundColor: '#626970'
  },

  button: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    height: '100%'
  },
  buttonLabel: {
    fontFamily: 'Roboto-Bold',
    marginRight: 8,
    userSelect: 'none'
  },

  sideMenuItems: {
    position: 'absolute',
    top: '100%',
    right: 0,
    minWidth: 310,
    padding: 0,
    borderRadius: 0,
    zIndex: 1000,
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    boxShadow: '0 0 0 1000vmax rgba(0,0,0,.1), 0 0 2rem 0 rgba(0,0,0,.5)',
    maxHeight: 'calc(var(--app-height) - 56px)',
    overflowY: 'auto'
  },

  item: {
    borderBottom: '1px solid #E1E3E9',
    fontSize: 14,
    padding: 12,
    paddingLeft: 16,
    textTransform: 'none',
    color: '#63696f',
    display: 'block',
    textDecoration: 'none',
    lineHeight: '24px',

    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      padding: 6
    },
  },
  childItem: {
    paddingLeft: 32
  },
  sectionHeader: {
    fontFamily: 'Roboto-Bold',
  },
  actionHeader: {
    cursor: 'pointer',
  },

  section: {
    '&:last-of-type': {
      '& $item': {
        '&:last-of-type': {
          borderBottom: 'none'
        }
      }
    }
  },

  itemIcon: {
    marginRight: 8,
    fontSize: 12,
    lineHeight: '14px'
  },
  sectionHeaderIcon: {
    marginLeft: 8,
    color: '#95989a',
    fontSize: 12,
    lineHeight: '14px'
  },

  logout: {
    backgroundColor: '#F2F2F2',
    color: '#63696f',
  },
  active: {
    color: `${theme.palette.primary.main} !important`
  }

});

export default styles;