import { UserUtils } from '../../../../utils/utils';
import _ from "lodash";

/**
 * @param isChief
 * @param {{AGENCY: {}, MY: {}, SUB_AGENCY: {}}} client_types_options
 * @returns {{AGENCY: {description: string, value: number}, MY: {description: string, value: number}, SUB_AGENCY: {description: string, value: number}}|{MY: {description: string, value: number}}}
 */
export const getBrokerClientTypes = (isChief=false, client_types_options) => {

    const clientTypes = client_types_options || CLIENT_TYPES;

    if (isChief) {
        return clientTypes
    }
    return {
      MY: {...clientTypes.MY},
    }
};

export const CLIENT_TYPES = {
    MY: {
        value: 3,
        id: 3,
        description: 'Meine Kunden mit Investmentdepots',
        name: 'Meine Kunden mit Investmentdepots'
    },
    AGENCY: {
        value: 1,
        id: 1,
        description: 'Agentur',
        name: 'Agentur'
    },
    SUB_AGENCY: {
        value: 2,
        id: 2,
        description: 'Unteragentur',
        name: 'Unteragentur'
    },
};

export const OTHER_AGENCIES_CLIENT_TYPE = {
    value: 6,
    description: 'Sonstige Agentur'
}

export const OTHER_AGENCIES_CLIENT_TYPE_DROPDOWN_OPTION = {
    id: OTHER_AGENCIES_CLIENT_TYPE.value,
    depth: 1,
    description: OTHER_AGENCIES_CLIENT_TYPE.description,
    label: OTHER_AGENCIES_CLIENT_TYPE.description,
    value: OTHER_AGENCIES_CLIENT_TYPE.value
}

export const getSearchCustomerType = (auth) => {
    return UserUtils.isChief(auth) ? CLIENT_TYPES.AGENCY : CLIENT_TYPES.MY
}


export const SORTING_TYPES = {
    // AUM_DESC: {
    //     value: 1,
    //     description: 'Kunden Investiertes Vermögen (absteigend)'
    // },
    // AUM_ASC: {
    //     value: 2,
    //     description: 'Kunden Investiertes Vermögen (aufsteigend)'
    // },
    NAME_ASC: {
        value: 3,
        description: 'Name (A bis Z)'
    },
    NAME_DESC: {
        value: 4,
        description: 'Name (Z bis A)'
    }
};

export const CLIENT_FILTERS = {
    SUB_AGENCY: 4,
    SUB_BROKER: 5
}

const ENABLED_FILTER_OPTIONS = {
    ALL: {
        value: 0,
        id: 0,
        description: 'Alle',
        name: 'Alle'
    },
    ENABLED: {
        value: 1,
        id: 1,
        description: 'Ja',
        name: 'Ja'
    },
    DISABLED: {
        value: 2,
        id: 2,
        description: 'Nein',
        name: 'Nein'
    }
}

export const PERMANENT_SUITABILITY_FILTERS= {...ENABLED_FILTER_OPTIONS}

export const SRI_FILTERS = {...ENABLED_FILTER_OPTIONS}

export const processBrokerClientTypes = (types, agency, sub_agencies_list) => {
    let processedData = [];
    Object.values(types).map((i, idx) => {
        processedData.push({id: idx, description: i.description, name: i.description, label: i.description, value: i.value, depth: 1});
        if (i.value === CLIENT_TYPES.AGENCY.value) {
            processedData.push(...agency.sub_brokers.map(subBroker => ({
                id: subBroker.broker_id,
                description: subBroker.broker_full_name,
                name: subBroker.broker_full_name,
                label: subBroker.broker_full_name,
                value: subBroker.broker_id,
                type: CLIENT_FILTERS.SUB_BROKER,
                depth: 2
            })));
        } else if(i.value === CLIENT_TYPES.SUB_AGENCY.value){
            processedData.push(...processBrokerSubAgencies(sub_agencies_list))
        }
    });

    return processedData;
};

export const processBrokerSubAgencies = (agencies) => {
    let processedData = [];
    agencies.map(agency => {
        processedData.push({
            id: agency.agency_id,
            description: agency.name,
            name: agency.name,
            label: agency.name,
            value: agency.agency_id,
            type: CLIENT_FILTERS.SUB_AGENCY,
            depth: 2
        });
        if (!_.isEmpty(agency.sub_brokers)) {
            processedData.push(...agency.sub_brokers.map(subBroker => ({
                id: subBroker.broker_id,
                description: subBroker.broker_full_name,
                name: subBroker.broker_full_name,
                label: subBroker.broker_full_name,
                value: subBroker.broker_id,
                type: CLIENT_FILTERS.SUB_BROKER,
                depth: 3
            })));
        }
    });

    return processedData;
};

export const getAgencyFilterOptions = (auth) => {
    return processBrokerClientTypes(getBrokerClientTypes(UserUtils.isChief(auth)), auth.user.agency, auth.user.sub_agencies);
};
