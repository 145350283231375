const styles = (theme) => ({
  container: {
    marginTop: 35,
    flex: 1,
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: 10,
    }
  },
  editorContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  textEditorWrapper: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: 0,
    flex: 1,
    minHeight: 450,
    '& .ql-editor': {
      minHeight: 372
    }
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 8
  }
});

export default styles;