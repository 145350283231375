import moment from 'moment'
import {makeStyles} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import React from "react";


const dateToString = (date) => date.format('YYYY-MM-DD')

/**
 * Configuration for risk metrics data retrieving.
 * @type {[{endDate, id: string, startDate},{endDate, id: string, startDate},{endDate, id: string, startDate}]}
 */
export const RISK_INDICATORS_DATA_RETRIEVING_TIME_FRAMES = [
  {
    id: '1y',
    startDate: dateToString(moment().subtract(1, 'year')),
    endDate: dateToString(moment())
  }, {
    id: '3y',
    startDate: dateToString(moment().subtract(3, 'year')),
    endDate: dateToString(moment())
  }, {
    id: '5y',
    startDate: dateToString(moment().subtract(5, 'year')),
    endDate: dateToString(moment())
  }
]

export const PORTFOLIO_RISK_METRICS_MAX_TIME_FRAME_ID = 'max'

/**
 * Build Risk Metrics MAX data retrieving configuration for portfolio.
 * @param {{tracking_start_date: string}} portfolio Portfolio data.
 * @returns {{endDate, id: string, startDate: (string|*)}}
 */
export const getPortfolioRiskMetricsMaxTimeFrame = (portfolio) => ({
  id: PORTFOLIO_RISK_METRICS_MAX_TIME_FRAME_ID,
  startDate: portfolio.start_tracking_date,
  endDate: dateToString(moment())
})


const useLoadingIndicatorStyles = makeStyles(() => ({
  container: {
    '& > span': {
      height: 40
    }
  }
}))

/**
 * Loading indicator definition to be used for Risk Metrics chart loading.
 * @returns {JSX.Element}
 * @constructor
 */
export function RiskMetricsLoadingIndicator() {
  const classes = useLoadingIndicatorStyles()
  return (
    <div className={classes.container}>
      <Skeleton />
      <Skeleton style={{width: '75%'}}/>
    </div>
  )
}

/**
 * Loading indicator definition to be used for Dashboard Numbers loading.
 * @returns {JSX.Element}
 * @constructor
 */
export function DashboardNumbersLoadingIndicator() {
  const classes = useLoadingIndicatorStyles()
  return (
    <div className={classes.container}>
      <Skeleton style={{width: '75%'}} />
      <Skeleton style={{width: '45%'}} />
    </div>
  )
}

export const INFORM_CLIENTS_RISK_PDF_TYPES = [{
  value: 1,
  label: 'Kunden mit dauerhafter Geeignetheitsprüfung / keine Abweichung vom Risikoprofil'
}, {
  value: 2,
  label: 'Kunden mit dauerhafter Geeignetheitsprüfung / leichte Abweichung vom Risikoprofil'
}, {
  value: 3,
  label: 'Kunden mit dauerhafter Geeignetheitsprüfung / starke Abweichung vom Risikoprofil'
}]

export const EDIT_ALERT_ACTIONS = {
  CREATE: 1,
  CANCEL: 2,
  ACTIVATE: 3,
  DELETE: 4
}