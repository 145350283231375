const styles = theme => ({
  chart: {
    width: '100%',
    '& > div': {
      width: '100%'
    },
    '& .legend-custom': {
      width: 255
    },
    '& .legend-custom-name': {
      width: 210,
      display:'block',
      height: 'auto',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
    '& .legend-custom span:nth-of-type(2)': {
      float: 'right',
      fontWeight: 'normal'
    },
    '& .legend-custom-value': {
      position: 'absolute',
      top: 0,
      right: 0,
    }
  }
})

export default styles;