import React from 'react';
import moment from "moment";

import { Grid } from '@material-ui/core';

import useStyles from '../styles';
import images from '../../../../images';
import TextField from '../TextField';
import DateField from '../DateField';
import CurrencyField from '../CurrencyField';
import SelectField from '../SelectField';
import NonFieldErrors from "../NonFieldErrors";

import { EQUITY_CATEGORIES } from '../../constants';

export default (props) => {
  const classes = useStyles();

  const {
    handleFormChange,
    errors,
    asset,
    viewOnly
  } = props;

  const [model, setModel] = React.useState({
    name: '',
    category: '',
    acquisition_date: undefined,
    value: undefined,
    current_valuation: undefined,
    past_dividends: undefined,
    updated: undefined,
  });

  React.useEffect(() => {
    if (asset) {
      const category = EQUITY_CATEGORIES.find(category => asset.category === category.id);
      const _model = {
        name: asset.name,
        category: category,
        acquisition_date: asset.acquisition_date && moment(asset.acquisition_date),
        value: +asset.value,
        current_valuation: +asset.current_valuation,
        past_dividends: +asset.past_dividends,
        updated: asset.updated && moment(asset.updated + 'Z'),
        valuation_date: asset.valuation_date && moment(asset.valuation_date),
      };
      setModel(_model);
    }
  }, [asset]);


  const prepareFormData = () => {
    return {
      name: model.name,
      value: model.value,
      current_valuation: model.current_valuation,
      category: model.category && model.category.id,
      acquisition_date: model.acquisition_date && model.acquisition_date.format('YYYY-MM-DD'),
      past_dividends: model.past_dividends,
      valuation_date: model.valuation_date && model.valuation_date.format('YYYY-MM-DD'),
    }
  };

  React.useEffect(() => {
    handleFormChange && handleFormChange(prepareFormData());
  }, [model]);

  const handleModelChange = (field) => (event) => {
    setModel({
      ...model,
      [field]: event.target.value
    })
  };

  const handleDateChanged = (field) => (value) => {
    setModel({
      ...model,
      [field]: value
    })
  };

  const handleSelectChanged = field => value => {
    setModel({
      ...model,
      [field]: value
    })
  };

  return (
    <Grid container className={classes.formContainer}>
      <Grid item className={classes.formBody}>
        <div className={classes.formHeader}>
          <div>
            <img src={images.asset_icons.equity_icon_white} alt="Equity"/>
          </div>
          <p>Beteiligung / Firmenanteile</p>
        </div>
        <div>
          <TextField
            label="Name"
            error={errors && errors.name}
            value={model.name}
            onChange={handleModelChange('name')}
            disabled={viewOnly}
          />
          <SelectField
            label="Kategorie"
            value={model.category}
            error={errors && errors.category}
            onChange={handleSelectChanged('category')}
            items={EQUITY_CATEGORIES}
            disabled={viewOnly}
          />
          <DateField
            label="Erwerbsdatum"
            value={model.acquisition_date}
            maxDate={moment()}
            error={errors && errors.acquisition_date}
            onChange={handleDateChanged('acquisition_date')}
            disabled={viewOnly}
          />
          <CurrencyField
            label="Zeichnungssumme"
            value={model.value}
            error={errors && errors.value}
            onChange={handleModelChange('value')}
            disabled={viewOnly}
          />
          <CurrencyField
            label="Bisherige Ausschüttungen"
            value={model.past_dividends}
            error={errors && errors.past_dividends}
            onChange={handleModelChange('past_dividends')}
            disabled={viewOnly}
          />
          <CurrencyField
            label="Bewertung"
            value={model.current_valuation}
            error={errors && errors.current_valuation}
            onChange={handleModelChange('current_valuation')}
            disabled={viewOnly}
          />
          <DateField
            label="Bewertungsdatum"
            maxDate={moment()}
            value={model.valuation_date}
            error={errors && errors.valuation_date}
            onChange={handleDateChanged('valuation_date')}
            disabled={viewOnly}
          />
          <DateField
            label="Stand vom"
            value={model.updated || moment()}
            disabled={true}
          />
          {errors && errors[''] && <NonFieldErrors errors={errors['non_field_errors']}/>}
        </div>
      </Grid>
    </Grid>
  )
}