/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import {Switch, Route, withRouter, matchPath} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MomentUtils from '@date-io/moment';
import { CookiesProvider } from 'react-cookie';

import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import mainTheme from '../../themes/mainTheme';
import {PERMISSION_TYPE} from "../../utils/constants";

import CustomerLoginContainer from '../Authentication/CustomerLoginContainer';
import InvestingPlatform from '../InvestingPlatform';
import InvestmentPlatform from '../InvestmentPlatform';
import ProtectedRoute from '../../components/PrivateRoute';

import AuthenticationProvider from '../Authentication/Auth';
import {CUSTOMER_TAB_TITLES, TAB_TITLES} from "./constants";
import {ROUTES} from "../../routes";
import {CookiesBanner} from "../../components/CookiesBanner";

import GoogleAnalytics from "../../utils/GoogleAnalytics";
import SnackbarProvider from '../../components/SnackbarProvider/'
import ConfirmationModalContextProvider from "../../components/ConfirmationModalContextProvider";
import { getInvestmentDynamicPath } from '../InvestmentPlatform/utils'
import CircularProgress from "@material-ui/core/CircularProgress";


const AppContext = React.createContext()

export function useAppContext() {

  const context = React.useContext(AppContext)
  if (!context) {
    throw new Error('Extracting context without wrapping your component with context Provider!')
  }

  return context

}

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: getAppTitleByPath(''),
      dynamicInvestmentPath: '/' + getInvestmentDynamicPath(),
      activeMenuItem: null,
      currentCustomer: null,
      sharedSettingsLoading: true
    };
    this.handleInvestmentDynamicPath = this.handleInvestmentDynamicPath.bind(this)
  }

  componentDidMount() {
    this.onLocationChange(this.props.location, false);
    this.props.history.listen(
      (location) => setTimeout(() => this.onLocationChange(location, true), 0));

    const setAppHeight = () => {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    };
    window.addEventListener('resize', setAppHeight);
    window.addEventListener('orientationchange', () => {
      setTimeout(setAppHeight, 300)
    });
    setAppHeight();
  }

  handleInvestmentDynamicPath() {
    let dynamicInvestmentPath = '/' + getInvestmentDynamicPath()
    if (dynamicInvestmentPath && this.state.dynamicInvestmentPath !== dynamicInvestmentPath) {
      this.setState({dynamicInvestmentPath})
    }
    this.setState({
      sharedSettingsLoading: false
    })
  }

  onLocationChange(location, trackPage) {
    const { activeMenuItem, currentCustomer, dynamicInvestmentPath } = this.state;
    const locationState = location.state || {};
    const title = getAppTitleByMenuItem(location.pathname || '', activeMenuItem, currentCustomer, dynamicInvestmentPath, locationState.title || '');
    this.setState({...this.state, title});
    trackPage && GoogleAnalytics.trackPage(location.pathname, title);
  }

  handleMenuItemChange = (value) => {
    this.setState({...this.state, activeMenuItem: value}, () => this.onLocationChange(this.props.location, false));
  }

  handleCustomerChange = (value) => {
    this.setState({...this.state, currentCustomer: value}, () => this.onLocationChange(this.props.location, false));
  }

  render() {
    return (
      <AppContext.Provider value={{
        onMenuItemChange: this.handleMenuItemChange,
        onCustomerChange: this.handleCustomerChange,
        activeMenuItem: this.state.activeMenuItem
      }}>
        <CookiesProvider>
          <SnackbarProvider/>
          <MuiThemeProvider theme={mainTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>

              <CssBaseline/>

              <Helmet
                defaultTitle={TAB_TITLES.Default}
                title={this.state.title}
              />


              <AuthenticationProvider handleInvestmentDynamicPath={this.handleInvestmentDynamicPath} >
                {this.state.sharedSettingsLoading ? (
                  <div style={{
                    display: 'flex',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <CircularProgress/>
                  </div>
                ) : (
                  <ConfirmationModalContextProvider>
                    <Switch>
                      <Route
                        exact
                        path={ROUTES.CUSTOMER.LOGIN.path}
                        component={CustomerLoginContainer}
                      />
                      <ProtectedRoute
                        authenticationType={PERMISSION_TYPE.BROKER_ONLY}
                        path={this.state.dynamicInvestmentPath}
                        component={InvestmentPlatform}
                      />
                      <ProtectedRoute
                        authenticationType={PERMISSION_TYPE.AUTHORIZED_ONLY}
                        path=""
                        component={InvestingPlatform}
                      />
                    </Switch>
                  </ConfirmationModalContextProvider>
                )}
              </AuthenticationProvider>

            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </CookiesProvider>
      </AppContext.Provider>
    );
  }
}


function isMatchPath(path, route) {
  return matchPath(path, {
      path: route,
      exact: false,
      strict: false
    })
}

function getAppTitleByMenuItem(path, activeMenuItem, currentCustomer, dynamicInvestmentPath, componentTitle) {
  if (componentTitle) {
    return componentTitle;
  }
  if (activeMenuItem) {
    const isActiveItem = isMatchPath(path, dynamicInvestmentPath + activeMenuItem.full_path);
    if (isActiveItem) {
      if (currentCustomer && path.includes(currentCustomer.customer_id)) {
        return `${currentCustomer.customer_full_name} - ${activeMenuItem.title || activeMenuItem.name}`
      }
      return activeMenuItem.title || activeMenuItem.name
    }
  }
  const agency_id = path.split('/')[1];
  const isCustomerPath = +agency_id > 0;
  if (isCustomerPath) {
    if(isMatchPath(path, ROUTES.CUSTOMER.DASHBOARD.path)) {
      return CUSTOMER_TAB_TITLES.Dashboard;
    }
    if(isMatchPath(path, ROUTES.CUSTOMER.DASHBOARD_VIRTUAL.path)) {
      return CUSTOMER_TAB_TITLES.VirtualDepots;
    }
    if(isMatchPath(path, ROUTES.CUSTOMER.OTHER_ASSETS.path)) {
      return CUSTOMER_TAB_TITLES.OtherAssets;
    }
    if(isMatchPath(path, ROUTES.CUSTOMER.DOCUMENTS.path)) {
      return CUSTOMER_TAB_TITLES.Postbox;
    }
    if(isMatchPath(path, ROUTES.CUSTOMER.PRO_VIEW.path) || isMatchPath(path, ROUTES.CUSTOMER.VIRTUAL_PRO_VIEW.path)) {
      return CUSTOMER_TAB_TITLES.ProView;
    }
  }

  return getAppTitleByPath(path)
}


function getAppTitleByPath(path) {
  if (path.includes(ROUTES.BROKER.CUSTOMER_DASHBOARD.path.replace(':customer_id', ''))) {
    return TAB_TITLES.CustomerDashboard;
  }
  if (path.includes(ROUTES.BROKER.RISK_DASHBOARD.path)) {
    return TAB_TITLES.RiskDashboard;
  }
  if (path.includes(ROUTES.BROKER.REPORT_SETTINGS.path) || path.includes(ROUTES.BROKER.GROUP.path)) {
    return TAB_TITLES.ReportSettings;
  }
  if (path.includes(ROUTES.BROKER.REPORTING.path) || path.includes(ROUTES.BROKER.SERIES_REPORTING.path)) {
    return TAB_TITLES.Reporting;
  }

  const agency_id = path.split('/')[1];
  const isCustomerPath = +agency_id > 0;
  if (isCustomerPath) {
    return TAB_TITLES.DefaultCustomer;
  }

  return TAB_TITLES.DefaultBroker;
}

export default withRouter(App);
