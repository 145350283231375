const styles = (theme) => ({
  benchmarkWrap: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'flex-end'
  },
  switchWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: 15
  },
  benchmarkLabel: {
    fontFamily: "'Roboto-Bold'",
    fontSize: 13,
    color: '#4D4F5C',
    margin: 0
  },
  contentControl: {
    marginBottom: 4,
    marginTop: -16,
  },
  chartContainer: {
    '& .highcharts-range-selector-buttons': {
      display: 'none'
    }
  },
  disabled: {
    filter: 'grayscale(1)',
    pointerEvents: 'none',
    cursor: 'initial'
  },
  transactionsContainer: {
    boxShadow: 'none',
    padding: 0,
    paddingTop: 20
  },
  contentContainer: {
    '& > div > div > div > div': {
      overflowX: 'auto',
      overflowY: 'hidden'
    },
    '& > div > div': {
      boxShadow: 'none',
      padding: 0
    }
  },
  tableHead: {
    backgroundColor: '#EEF0F2',

    '& td': {
      color: '#80858C !important'
    }

  },
  table: {
    minWidth: 860,
  },
  tableCell: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#4D4F5C',
    '&:first-child': {
      width: 35,
    },
    '&:nth-of-type(2)': {
      width: 35,
      '& button': {
        height: 25,
        width: 25,
        marginTop: 10
      }
    },
    '&:nth-of-type(3)': {
      width: 80,
    },
    '&:nth-of-type(4)': {
      width: 140,
    },
    '&:nth-of-type(5)': {
      width: 300,
    },
    '&:nth-of-type(6)': {
      width: 70,
    },
    '&:nth-of-type(7)': {
      width: 100,
    },
    '&:nth-of-type(8)': {
      width: 100,
    },
    '&:nth-of-type(9)': {
      width: 200,
      textAlign: 'center'
    },
    '&:last-child': {
      width: 60,
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        height: '100%'
      }

    }
  }
});

export default styles;