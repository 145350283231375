import React from 'react';
import {
  Grid,
  Typography
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import styles from "./styles";

const Header = props => {
  const {
    classes
  } = props;

  return (
    <Grid container className={classes.container}>
      <Grid item className={classes.item}>
        <Typography variant={"h1"} className={classes.header}>
          Sonstige Vermögenswerte
        </Typography>
      </Grid>
    </Grid>
  )
};

export default withStyles(styles)(Header);