import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';

import styles from './styles';

function SelectCaret({classes, className, style}) {
  return <i className={clsx("fa fa-caret-down", classes.icon, className)} style={style} aria-hidden="true" />
}

export default withStyles(styles) (SelectCaret);