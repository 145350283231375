import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  tableHead: {
    backgroundColor: '#EEF0F2',

    '& td': {
      color: '#80858C !important'
    }

  },

  table: {
    minWidth: 860,
  },

  container: {
    overflowX: 'auto',

    '&::-webkit-scrollbar': {
      height: 8,
      marginRight: 5
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8DCDF',
      borderRadius: 4,

    }
  },

  tableCell: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#4D4F5C',
    '&:nth-of-type(1)': {
      width: 84,
    },
    '&:nth-of-type(2)': {
      width: 205,
    },
    '&:nth-of-type(3)': {
      minWidth: 205,
    },
    '&:nth-of-type(4)': {
      width: 150,
    },
    '&:nth-of-type(5)': {
      width: 70,
    },
    '&:nth-of-type(6)': {
      width: 115,
    },
    '&:nth-of-type(7)': {
      width: 125,
    },
  }
}))