import React from "react";
import {EventDateColumn, EventValueColumn} from "../RiskDashboard/components/AlertConfigurationModal/EventsList";
import moment from "moment";
import {connect} from 'react-redux';
import {InstrumentNameCell} from "../Modelportfolios/components/InstrumentsList/table-data";
import {CircularProgress, IconButton} from "@material-ui/core";
import Tooltip from "../../components/Tooltip";
import {displayErrorSnackBar} from "../../components/SnackbarProvider/actions";
import {PortfolioHandlerResource} from "../../utils/api";
import { ALERT_PRODUCT_TYPE } from '../RiskDashboard/components/AlertConfigurationModal/utils';
import _ from "lodash";
import CustomerFullnameMenu from "../../components/CustomerFullnameMenu";


export const getEventKey = (event) => {
  return `${event.id}_${event.alert_type_value}`
}

const AlertEventType = {
  header: {
    content: () => 'Alarm Typ'
  },
  body: {
    content: (item) => {
      return item.alert_type_label
    }
  },
  key: 'AlertEventType'
}

const EventFrequency = {
  header: {
    content: () => 'Benachrichtigung'
  },
  body: {
    content: (item) => item.frequency_label
  },
  key: 'EventFrequency'
}

const Customer = {
  header: {
    content: () => 'Kunde'
  },
  body: {
    content: (item) => (
      <>
        <CustomerFullnameMenu
          customer={item.customer}
          customerFullname={item.customer.customer_full_name}
        />
        {item.customer.customer_id}
      </>
    )
  },
  key: 'Customer'
}

const Broker = {
  header: {
    content: () => 'Mitarbeiter'
  },
  body: {
    content: (item) => (
      <>
        <b>{item.broker.broker_full_name}</b>
        <br/>
        {item.broker.broker_id}
      </>
    )
  },
  key: 'Broker'
}

const FieldToValidate = {
  header: {
    content: () => 'Alarm einstellen für'
  },
  body: {
    content: (item) => item.validated_field_label
  },
  key: 'FieldToValidate'
}

const ProductNameCell = {
  header: {
    content: () => 'Produkt/ Depot'
  },
  body: {
    content: (item, options) => {
      if (item.alert_type_value == ALERT_PRODUCT_TYPE.ASSET) {
        return InstrumentNameCell.body.content(item, options)
      }
      return (
        <b>{_.get(item, 'depot.name')}</b>
      )
    }
  }
}

const MarkEventAsRead = connect()(({event, onEventMarkAsRead, dispatch}) => {

  const [loading, setLoading] = React.useState(false)

  const handleButtonClick = async () => {

    setLoading(true)

    try {

      const event_ids = event && [event.id]
      const event_keys = event && [getEventKey(event)]

      const requestData = event && {
        event_ids,
        product_type: event && event.alert_type_value
      }

      await PortfolioHandlerResource.at('customer/asset/alert-configuration/events/list/mark-as-read/').post(requestData)

      onEventMarkAsRead && onEventMarkAsRead(event_keys)

    } catch (errors) {
      dispatch(displayErrorSnackBar('Bitte versuchen Sie es später noch einmal.'))
    }

    setLoading(false)
  }

  return (
    <Tooltip title={`${event ? 'Als' : 'Alles als'} gelesen markieren`}>
      <IconButton color={"primary"} onClick={handleButtonClick} disabled={loading} style={{height: 48, width: 48}}>
        {loading ? (<i><CircularProgress size={18}/></i>) : (<i className="fa fa-eye" aria-hidden="true"></i>)}
      </IconButton>
    </Tooltip>
  )

})

const MarkAsReadColumn = {
  header: {
    content: (item, options) => {

      if (!options.newEventsExists) {
        return <IconButton disabled={true} style={{height: 48, width: 48}} />
      }

      return (
        <MarkEventAsRead onEventMarkAsRead={options.onEventMarkAsRead}/>
      )
    }
  },
  body: {
    content: (item, options) => {

      if (!item.is_new) {
        return <IconButton disabled={true} style={{height: 48, width: 48}} />
      }

      return (
        <MarkEventAsRead event={item} onEventMarkAsRead={options.onEventMarkAsRead}/>
      )
    }
  }
}

export default {
  default: [{
    content: [MarkAsReadColumn]
  },{
    content: [EventDateColumn],
    orderingFunction: (item) => {
      return moment(item.triggered_at)
    },
  }, {
    content: [Customer],
    orderingFunction: (item) => {
      return item.customer.customer_full_name
    }
  }, {
    content: [ProductNameCell],
    orderingFunction: (item) => {
      return item.alert_type_value == ALERT_PRODUCT_TYPE.ASSET
        ? item.asset.name
        : _.get(item, 'depot.name', '')
    }
  }, {
    content: [AlertEventType],
    orderingFunction: (item) => {
      return item.alert_type_value
    }
  }, {
    content: [EventFrequency],
    orderingFunction: (item) => {
      return item.frequency
    }
  }, {
    content: [FieldToValidate],
    orderingFunction: (item) => {
      return item.validated_field_label
    }
  }, {
    content: [EventValueColumn],
  }]
}