import React from 'react';
import {connect} from 'react-redux';
import {SharedSettingsResource} from "../../utils/api";
import {setSharedSettings} from "./actions";

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
  sharedSettings: state.get('sharedSettings').toJS()
});

export const SharedSettingsProvider = props => {

  React.useEffect(() => {
    const isUserExist = !!(props.auth && props.auth.user && props.auth.user);
    const isSharedSettingsExist = props.sharedSettings.fetched;
    if (isUserExist && !isSharedSettingsExist) {
      fetchSharedSettings()
    }
  }, []);

  const fetchSharedSettings = async () => {
    /*try {
      const sharedSettings = await SharedSettingsResource.at('data/').get();

      props.dispatch(setSharedSettings(sharedSettings || {}));
    } catch {
      props.dispatch(setSharedSettings({}));
    }*/
  };

  return (
    <>
      {props.children}
    </>
  )
}

export default connect(mapStateToProps)(SharedSettingsProvider);