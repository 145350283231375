const styles = theme => ({
  root: {
    minHeight: 150,
    width: '100%'
  },
  chart: {
    width: '100%',
  },
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& > div': {
      width: '100%'
    },
    flexDirection: 'column'
  },
  chartContainer: {
    [theme.breakpoints.up("sm")]: {
      marginTop: 20
    }
  },
  buttonsPad: {
    display: 'block'
  },
  description: {
    fontSize: 12,
    marginTop: 10,
    color: '#8D8D8D',

    [theme.breakpoints.up("sm")]: {
      marginLeft: 30,
    }
  },
  errorContainer: {
    marginTop: 30,
  }
})

export default styles;