import {makeStyles} from "@material-ui/core";

const styles = theme => ({
  error: {
    color: theme.palette.error.main,
  },
  portfolioContainer: {
    marginBottom: '2rem'
  },
  totalAmountInputRoot: {
    marginBottom: 0,
    height: 40
  },
  accordionPortfolioRoot: {
    boxShadow: '0px 3px 15px #00000033',
    borderRadius: '5px 5px 0px 0px !important',
    width: '100%',
    '&.Mui-expanded': {
      margin: 0,
    }
  },
  totalAmount: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    padding: '16px 64px 16px 28px',
    background: '#D8DCDF',
    boxShadow: '0px 3px 15px #00000033',
    borderRadius: '0px 0px 5px 5px',
    zIndex: 0,
    '& > .MuiGrid-item': {
      paddingLeft: 5,
      paddingRight: 5,
      '&:last-child': {
        marginLeft: 'auto',
      }
    },
  },
  accordionRoot: {
    boxShadow: 'none',
    border: '1px solid #D8DCDF',
    borderRadius: 5,
    width: '100%',
    margin: '5px 0 !important',
  },
  accordionPortfolioHeader: {
    fontFamily: "Roboto-Medium",
    padding: "2px 33px 2px 20px",
    fontSize: 20,
    backgroundColor: "#394352",
    color: "#fff",
    '&.Mui-expanded': {
      minHeight: 'auto',
    },
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      '&.Mui-expanded': {
        margin: '12px 0',
      }
    },
  },
  accordionHeader: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    padding: "2px 16px",
    '&.Mui-expanded': {
      minHeight: 'auto',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
    '&.Mui-focused': {
      background: 'white !important'
    }
  },
  accordionDetails: {
    flexDirection: 'column',
  },
  tableCell: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    '&:nth-child(1)': {
      maxWidth: 370,
      [theme.breakpoints.down('md')]: {
        maxWidth: 300,
      }
    },
    '&.sustainability-cell': {
      minWidth: 83,
      width: 83
    },
    '&:last-child': {
      textAlign: 'center',
      width: 45,
    },
  },
  addBtnContainer: {
    display: 'flex',
    padding: '1rem 0',
    '& .Mui-error': {
      fontSize: 14,
      fontFamily: 'Roboto-Regular',
    }
  },
  header: {
    height: 16,
  },
  closeButton: {
    position: 'absolute',
    top: 3,
    right: 10
  },
  dialogActions: {
    padding: 24,
  },
  small: {
    fontSize: "80%",
  },
  textFieldRoot: {
    width: 'auto',
  },
  inputRoot: {
    height: 40,
    background: '#fff',
    marginBottom: 0,
    '&.Mui-error': {
      borderColor: '#f44336',
    }
  },

  depotTypeSection: {
    marginTop: 5
  },
  depotSelection:{
    padding: '12px 12px 12px 0'
  },
  depotSelectionInput: {
    backgroundColor: 'white',
    marginBottom: 0
  },
  depotTypeDescriptionLine: {
    margin: 0,
    padding: 0,
    fontSize: 12
  },
  depotOverall: {
    marginLeft: 'auto',
    textAlign: 'right',

    '& > p': {
      margin: 0
    }
  },
  switchInfoText: {
    borderLeft: '0.25rem solid #1a75b1',
    minHeight: 35,
    display: 'flex',
    alignItems: 'center',
    margin: '18px 0',
    paddingLeft: 5,

    '& > p': {
      fontFamily: 'Roboto-Bold',
      fontStyle: 'italic',
      fontSize: 16,
      color: '#1a75b1',
      margin: 0
    },

    '& > i': {
      color: '#1a75b1',
      fontSize: 17,
      marginRight: 5
    },
  },
  tableFooter: {
    background: 'inherit',

    '& > tr > td': {
      border: 'none',
    },

    '& .MuiTableCell-root': {
      verticalAlign: 'top',

      '&:nth-of-type(1)': {
        textAlign: 'left'
      }
    }
  }
});

const switchOutTableStyles = (theme) => ({
  tableCell: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    '&:nth-child(1)': {
      width: 300,
      maxWidth: 300,
    },
    '&.sustainability-cell': {
      minWidth: 83,
      width: 83
    },
    '&.switch-quantity-cell': {
      width: 140,
      textAlign: 'right',
      '& > div': {
        width: 140,
      }
    },
    '&.switch-amount-cell': {
      width: 200,
      textAlign: 'right',
      '& > div': {
        width: 200,
      }
    },
    '&.switch-percentage-cell': {
      width: 80,
      textAlign: 'right',
      '& > div': {
        width: 80,
      }
    },
    '&:nth-child(6)': {
      '& > div': {
        width: '100%',
      }
    },
    '&:last-child': {
      textAlign: 'center',
      width: 45,
    },
  },
})

const switchInTableStyles = (theme) => ({
  tableCell: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    '&:nth-child(1)': {
      width: 250,
      maxWidth: 250
    },
    '&.saldo-placeholder': {
      width: 50,
      verticalAlign: 'middle',
      '& > div': {
        width: 50,
      }
    },
    '&.sustainability-cell': {
      minWidth: 83,
      width: 83
    },
    '&.switch-quantity-cell': {
      width: 140,
      textAlign: 'right',
      '& > div': {
        width: 140,
      }
    },
    '&.switch-amount-cell': {
      width: 200,
      textAlign: 'right',
      '& > div': {
        width: 200,
      }
    },
    '&.switch-percentage-cell': {
      width: 80,
      textAlign: 'right',
      '& > div': {
        width: 80,
      }
    },
    '&:nth-child(5)': {
      width: 80,
      '& > div': {
        width: 80,
      }
    },
    '&:nth-child(8)': {
      width: 100,
      '& > div': {
        width: 100,
      }
    },
    '&:last-child': {
      textAlign: 'center',
      width: 45,
    },
  },
})

export const useSwitchOutTableStyles = makeStyles(switchOutTableStyles)

export const useSwitchInTableStyles = makeStyles(switchInTableStyles)

export const useStyles = makeStyles(styles)

export default styles;
