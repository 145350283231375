const styles = (theme) => ({
  container: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',

    '& .disabled > a': {
      color: '#B1B1B1'
    },
    '& > li > a': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 32,
      height: 36,
      margin: '0 5px',
      fontSize: 14,
      fontFamily: 'Roboto-Bold',
      boxSizing: 'border-box',
      outline: 'none',
      cursor: 'pointer',
    }
  },
  pageButton: {
    border: '1px solid #B1B1B1',
    borderRadius: 2,
    color: theme.palette.primary.main,
    backgroundColor: 'white'
  },
  navigationButton: {
    color: theme.palette.primary.main,
  },
  activePageButton: {
    color: '#626970',
  }
});

export default styles;