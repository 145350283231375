import {fromJS} from "immutable";
import _ from 'lodash';

import {
  ADD_CUSTOMERS_TO_STORE,
  REMOVE_CUSTOMERS_FROM_STORE,
  ADD_CUSTOMERS_ERROR_TO_STORE,
  ADD_CUSTOMERS_WITHOUT_GROUP_TO_STORE,
  UPDATE_CUSTOMER_IN_STORE, ADD_SHOW_REPORTING_CUSTOMERS_ERROR_TO_STORE, ADD_SHOW_REPORTING_CUSTOMERS_TO_STORE
} from './constants';


const initialState = fromJS({
  customers: undefined,
  customersWithoutGroup: undefined,
  errors: undefined,
  showReportingCustomers: undefined,
  showReportingCustomersErrors: undefined
});


export const createReducer = (state=initialState, action) => {
  switch (action.type) {
    case ADD_CUSTOMERS_TO_STORE:
      return updateCustomersGroups(action.customers, state)
        .set('errors', undefined)
        .set('customersWithoutGroup', undefined);
    case ADD_SHOW_REPORTING_CUSTOMERS_TO_STORE:
      return state
        .set('showReportingCustomers', action.customers)
        .set('showReportingCustomersErrors', undefined);
    case ADD_CUSTOMERS_WITHOUT_GROUP_TO_STORE:
      return updarteCustomersWithoutGroups(action.customers, state);
    case ADD_CUSTOMERS_ERROR_TO_STORE:
      return state
        .set('customers', undefined)
        .set('errors', true);
    case ADD_SHOW_REPORTING_CUSTOMERS_ERROR_TO_STORE:
      return state
        .set('showReportingCustomers', undefined)
        .set('showReportingCustomersErrors', true);
    case UPDATE_CUSTOMER_IN_STORE:
      return updateCustomer(action.customerID, action.updatedFields, state);
    case REMOVE_CUSTOMERS_FROM_STORE:
      return state.set('customers', fromJS([]));
    default:
      return state;
  }
};

export default createReducer;


const updarteCustomersWithoutGroups = (customers, state) => {

  try {

    let customersWithoutGroup = state.get('customersWithoutGroup');

    if (customersWithoutGroup) {
      customersWithoutGroup = customersWithoutGroup.toJS();
    } else {
      customersWithoutGroup = [];
    }

    customersWithoutGroup = [...customersWithoutGroup, ...customers];

    return state.set('customersWithoutGroup', fromJS(customersWithoutGroup));

  } catch (error) {
    return state
  }

}


const updateCustomersGroups = (customers, state) => {

  try {

    let customersWithoutGroup = state.get('customersWithoutGroup');

    if (customersWithoutGroup) {

      customersWithoutGroup = customersWithoutGroup.toJS();

      for (let index = 0; index < customers.length; index++) {
        customersWithoutGroup.forEach(customerData => {
          if (customers[index].id == customerData.id) {
            customers[index].group = undefined;
          }
        });
      }
    }

    return state.set('customers', fromJS(customers));

  } catch {
    return state
  }

}

const updateCustomer = (customerID, updatedFields, state) => {
  try {

    let customers = state.get('customers');

    if (customers) {
      customers = customers.toJS();
      customers.forEach(function(customer, index) {
        if (customer.customer_id === customerID) {
          this[index] = {...customer, ...updatedFields}
        }
      }, customers);
    }

    return state.set('customers', fromJS(customers));

  } catch (error) {
    return state
  }
}