import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_7632"
            data-name="Rechteck 7632"
            width="30"
            height="30"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_7861" data-name="Gruppe 7861" transform="translate(0 0)">
        <g
          id="Gruppe_7860"
          data-name="Gruppe 7860"
          transform="translate(0 0)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Pfad_6685"
            data-name="Pfad 6685"
            d="M22.329,39.932l-.227-.849-3.54.948a2.629,2.629,0,0,0-.683-.68l.948-3.54-.847-.229a6.147,6.147,0,0,0-5.945,1.593l-.619.621,2.592,2.592a2.629,2.629,0,0,0-.25.929l-3.542.949.228.849a6.179,6.179,0,0,0,4.349,4.349l.85.229.949-3.543a2.624,2.624,0,0,0,.477-.081,2.673,2.673,0,0,0,.454-.168L20.115,46.5l.621-.623a6.178,6.178,0,0,0,1.592-5.941m-7.08-2.652a4.41,4.41,0,0,1,1.4-.142L16.18,38.9a2.61,2.61,0,0,0-.93.25l-1.287-1.287a4.377,4.377,0,0,1,1.285-.579m-.825,8.173a4.427,4.427,0,0,1-1.968-1.966l1.757-.471a2.642,2.642,0,0,0,.682.68Zm2.19-3.08a.879.879,0,1,1,.621-1.077.88.88,0,0,1-.621,1.077m3.436,1.572L18.765,42.66a2.622,2.622,0,0,0,.251-.931l1.756-.47a4.427,4.427,0,0,1-.721,2.686"
            transform="translate(-6.243 -21.615)"
            fill="#80858c"
          />
          <path
            id="Pfad_6686"
            data-name="Pfad 6686"
            d="M25.289,13.41,30,8.687l-3.42-1.538,2.2-2.2L25.054,1.219l-2.2,2.2L21.313,0,16.59,4.71l1.243,3.729-.526.526-6.148-1.23L5.09,12.456A13.189,13.189,0,0,0,.081,24.319l.56,5.041,5.041.56a13.194,13.194,0,0,0,11.863-5.009l4.72-6.069-1.23-6.148.526-.526Zm1.7-4.159L24.819,11.4l-1.867-.622,2.3-2.3Zm-.689-4.3-6.215,6.215L18.839,9.92,25.054,3.7Zm-7.025,7.891.621,3.107-5.839-5.839,3.107.621ZM18.6,5.185,20.75,3.036l.768,1.718-2.3,2.3ZM1.966,25.364l2.671,2.671-2.4-.267Zm14.192-1.532a11.511,11.511,0,0,1-8.83,4.407L1.762,22.674a11.511,11.511,0,0,1,4.407-8.83l5.139-4,8.846,8.847Z"
            transform="translate(0 -0.001)"
            fill="#80858c"
          />
        </g>
      </g>
    </svg>
  );
};
