import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  track: {
    position: 'relative',
    width: '100%',
    height: 2,
    background: '#D8DCDF'
  },
  thumb: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: theme.palette.primary.main
  }
})

export default makeStyles(styles)