import React from 'react';
import {withRouter} from "react-router-dom";
import clsx from "clsx";
import { connect } from 'react-redux'
 
import useStyles from './styles';
import { withEuroOrDash } from '../../../../../../utils/utils';
import {buildCurrentCustomerOtherAssetsCategoryPath, buildOtherAssetsCategoryLink, ROUTES} from "../../../../../../routes";
import { executeIfPathExist, getInvestmentDynamicPath } from '../../../../../InvestmentPlatform/utils'
import { newDesignUsed } from '../../../../../../utils/utils'


const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS()
});


const CategoryItem = (props) => {
  const classes = useStyles();
  const {
    title,
    totalAmount,
    icon,
    url,
    read_only,
    setActiveCategory
  } = props;

  const handleOpenCategory = () => {
    if (read_only) {
      return null;
    }

    if (setActiveCategory) {
      setActiveCategory();
      return;
    }

    const customer_id = props.match.params.customer_id;
    let path = customer_id ? buildOtherAssetsCategoryLink(customer_id, url) : buildCurrentCustomerOtherAssetsCategoryPath(url);

    if (customer_id && newDesignUsed()) {
      executeIfPathExist(props.investmentPlatform.routes, 'OTHER_ASSETS', (dynamicPath) => {

        path = `/${getInvestmentDynamicPath()}` + dynamicPath.replace(':customer_id', customer_id) + ROUTES.BROKER.OTHER_ASSETS_CATEGORY.newDesignPath.replace(':category_id', url)
      }, ':customer_id')
    }

    return props.history.push(path);
  };

  return (
    <div className={clsx(classes.categoryItemContainer, read_only && classes.readOnly)} onClick={handleOpenCategory}>
      <p className={classes.categoryItemTitle}>{ title }</p>
      <p className={classes.categoryItemTotalAmount}>{ withEuroOrDash(totalAmount) }</p>
      <img src={icon} className={classes.categoryItemIcon} alt=''/>
    </div>
  );
};

export default connect(mapStateToProps)(withRouter(CategoryItem));