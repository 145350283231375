import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'

import {
  Dialog,
  DialogContent,
  IconButton,
  Grid,
  FormControlLabel,
  TextField,
  Radio,
  RadioGroup
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

import PrimaryButton from '../../../../components/Buttons/PrimaryButton'

import useStyles from './styles'
import { FAVORITE_LIST_TYPE } from '../../constants'
import { UserUtils, isValidString } from '../../../../utils/utils'

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
});

function FavoriteListModal(props) {

  const {
    onFavoriteListCreateClick,
    open,
    onClose,
    loading,
    errors,
    selectedFavoriteList
  } = props;

  if (errors) console.log(errors);

  const classes = useStyles()

  const [name,setName] = React.useState(null)
  const [description,setDescription] = React.useState(null)
  const [type, setType] = React.useState(FAVORITE_LIST_TYPE.PERSONAL.value)

  React.useEffect(() => {
    if (selectedFavoriteList) {
      setName(selectedFavoriteList.name)
      setDescription(selectedFavoriteList.description)
      setType(selectedFavoriteList.agency ? FAVORITE_LIST_TYPE.AGENCY.value : FAVORITE_LIST_TYPE.PERSONAL.value)
    }
  }, [selectedFavoriteList])

  const isDataValid = () => {
    return isValidString(name) && !loading
  }

  const handleFavoriteListCreate = (update=false) => {
    let data = {
      name,
      description,
      is_private: type == FAVORITE_LIST_TYPE.PERSONAL.value
    }

    if (onFavoriteListCreateClick) {
      onFavoriteListCreateClick(data, update)
    }
  } 

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
      <DialogContent>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <p className={classes.modalTitle} style={{marginBottom: 20}}>Favoritenliste speichern</p>
        {UserUtils.isChief(props.auth) && (
          <Grid item xs={12} style={{position: 'relative', marginBottom: 20}}>
            <Grid container>
              <Grid item>
                <p className={classes.typeLabel}>Zugriff:</p>
              </Grid>
              <Grid item style={{paddingLeft: 35}}>
                <RadioGroup value={type} onChange={(event) => setType(+event.target.value)}>
                  <FormControlLabel className={classes.radioLabel} label={FAVORITE_LIST_TYPE.PERSONAL.label} value={FAVORITE_LIST_TYPE.PERSONAL.value} control={<Radio color="primary" style={{padding: 5}}/>}/>
                  <FormControlLabel className={classes.radioLabel} label={FAVORITE_LIST_TYPE.AGENCY.label} value={FAVORITE_LIST_TYPE.AGENCY.value} control={<Radio color="primary" style={{padding: 5}}/>}/>
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.formContainer} spacing={2}>
          <Grid item xs={12} style={{position: 'relative'}}>
            <TextField
              error={errors && !_.isString(errors) && 'name' in errors}
              value={name}
              onChange={(event) => setName(event.target.value)}
              fullWidth
              label="Favoritenlistename"
              placeholder="Bitte geben Sie die Favoritenliste ein"
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                  error: classes.inputError
                }
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                  asterisk: classes.labelAsterisk
                }
              }}
              helperText={errors && !_.isString(errors) &&  errors.name}
            />
            <span className={classes.textFieldAdditionalLabel}>(max. 250 Zeichen)</span>
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={errors && !_.isString(errors) && 'description' in errors}
              helperText={errors && !_.isString(errors) && errors.description}
              multiline
              fullWidth
              rows={5}
              rowsMax={5}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              label="Beschreibung"
              placeholder="Bitte geben Sie Beschreibung ein"
              InputProps={{
                classes: {
                  root: classes.inputTextAreaRoot,
                  focused: classes.inputFocused,
                  error: classes.inputError
                }
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                  asterisk: classes.labelAsterisk
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid className={classes.buttonContainer}>
          <PrimaryButton 
            customClasses={{root: classes.stickyButtonRoot, disabled: classes.stickyButtonDisabled}} 
            text="Liste speichern" 
            disabled={!isDataValid() || loading}
            onButtonClick={() => handleFavoriteListCreate(true)}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

FavoriteListModal.propTypes = {
  /** onModelPortfolioCreateClick handler*/
  onModelPortfolioCreateClick: PropTypes.func,

  /** Flag, that indicate, is modal visible, or not */
  open: PropTypes.bool
}

export default connect(mapStateToProps)(FavoriteListModal)

