import images from "../../../../../../../images";

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  rail: {
    backgroundColor: '#80858C',
    opacity: 1
  },
  mark: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 1,
    backgroundColor: '#80858C'
  },
  markActive: {
    backgroundColor: theme.palette.primary.main,
  },
  markLabel: {
    color: 'initial',
    fontSize: 16
  },
  markLabelActive: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
    fontSize: 20,
    top: 22
  },
  valueLabel: {
    display: 'none'
  },
  thumb: {
    position: 'relative',
    backgroundColor: 'transparent',
    boxShadow: 'none!important',

    '&::after': {
      content: 'none'
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      left: -6,
      width: 24,
      bottom: 2,
      height: 40,
      backgroundImage: `url(${images.risk_profiling.slider_thumb_1})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }
  },
  thumb_risk: {
    '&::before': {
      left: -18,
      width: 48,
      height: 61,
      backgroundImage: `url(${images.risk_profiling.slider_thumb_2})`,
    }
  }
});

export default styles;