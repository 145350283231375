export const REPORTS_OPTIONS = {
    COST: {
        value: 1,
        description: 'Kostenreport'
    },
    TRANSACTIONS: {
        value: 2,
        description: 'Transaktionsreport'
    }
};
