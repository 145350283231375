import React from "react";
import { withStyles } from "@material-ui/core";

import { formatPercentage } from "../../utils";
import { PortfolioUtils } from "../../../../utils/utils";
import FundStructureChart from "../../components/FundStructureChart";
import CommonAnalysisCard from "../../../VirtualPortfolioPage/components/AnalysisCard";
import RegionChart from "../../components/RegionChart";
import NoDataPlaceholder from "../NoDataPlaceholder";
import clsx from "clsx";
import { CHART_COLORS } from '../../../../utils/constants';

import styles from "./styles";

function BaseChart({
  seriesData,
  extraData,
  error,
  chartType,
  loading,
  title,
  classes,
  height,
  subtitle,
  expanded,
  onExpandedClick,
  useColorFromSeries,
  allowFloatingMixMax
}) {

  const getSeriesPointItem = (item) => {
    const point = {
      name: `
        <div class="legend-custom">
          <span>${item.name}</span>
          <span class="barValue">${formatPercentage(item.weight, 100)}</span>
        </div>
      `,
      data: [item.weight]
    };
    if (useColorFromSeries) {
      point.color = item.color
    }
    return point
  };

  const normalizeTableValue = (value) => {
    if (value === undefined || value === null) {
      return 'k.A.';
    }

    return value;
  };

  const getComponent = () => {
    switch (chartType) {
      case 'bar':
        return <FundStructureChart
          series={seriesData.map(getSeriesPointItem)}
          allowFloatingMixMax={allowFloatingMixMax}
          useColorFromSeries={useColorFromSeries}
          useCustomLegendStyles
        />;
      case 'region':
        return (
          <RegionChart
            data={PortfolioUtils.getPortfolioRegions({'by_region': seriesData})}
            byCountry={extraData}
            colors={CHART_COLORS}
            orientation='vertical'
            height={height}
            showLegend={true}
            language='DE'
          />
        );

      case 'table':
        return (
          <ul className={classes.list}>
            {
              seriesData.map((item, index) => (
                <li className={classes.item} key={index}>
                  <span className={classes.title}>{item.name}</span>
                  <span className={classes.values}>{normalizeTableValue(formatPercentage(item.weight, 100))}</span>
                </li>
              ))
            }
          </ul>
        )
      default:
        return null;
    }
  }

  const hasData = seriesData.length > 0;
  // if (error) console.log(error);

  return (
    <CommonAnalysisCard
      classes={{
        card: clsx(!expanded ? classes.rootCollapsed : (!hasData && !loading) ? classes.rootEmpty : classes.root, subtitle && classes.subtitleCorrection),
        title: classes.title,
        subtitle: classes.subtitle
      }}
      title={title}
      subtitle={subtitle}
      loading={loading}
      expanded={expanded}
      onExpandedClick={onExpandedClick}
      content={
        <div className={classes.content}>
          {
            hasData
              ? getComponent()
              : <NoDataPlaceholder />
          }
        </div>
      }
    />
  )
}

export default withStyles(styles)(BaseChart);
