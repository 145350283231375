import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    width: '100%',
    backgroundColor: '#FBFBFD',
    borderRadius: 4,
    boxShadow: '2px 2px 10px #0000001A'
  },
  tabsContainer: {
    display: 'flex',
    height: 48,
    alignItems: 'center'
  },
  tab: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& > span': {
      fontSize: 16,
      fontFamily: 'Roboto-Regular',
      color: '#202A38'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  activeTab: {
    backgroundColor: 'white',
    height: '100%',
    position: "relative",
    width: '100%',
    '& > span': {
      fontSize: 16,
      fontFamily: 'Roboto-Bold',
      color: '#202A38'
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      height: 2,
      width: '10%',
      backgroundColor: theme.palette.primary.main,
      bottom: 10
    },

    '&:first-child': {
      borderRadius: '4px 4px 0px 0px'
    },
    '&:last-child': {
      borderRadius: '4px 4px 0px 0px'
    }
  }
})

export default makeStyles(styles)