import { makeStyles } from '@material-ui/core';
import { FORM_FIELD_HEIGHT } from '../../../TransactionsMonitoring/components/Filters/components/common_styles';

export default makeStyles((theme) => ({
  container: {
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    borderRadius: 2,
    minHeight: 105,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',
    padding: '10px 20px',

    [theme.breakpoints.down('sm')]: {
      padding: '10px 16px',
    },
  },
  stickyContainer: {
    position: 'sticky',
    top: 0,
    left: 24,
    right: 24,
    zIndex: 2,
    display: 'none',
    boxSizing: 'border-box',

    [theme.breakpoints.down('xs')]: {
      '&': {
        left: 16,
        right: 16,
      }
    },

    '& > div': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      boxSizing: 'border-box',
      flexWrap: 'wrap',
      minHeight: 105,
      maxWidth: 1320,
      margin: '0 auto',
      boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.25)',
      padding: '10px 20px',

      [theme.breakpoints.only('sm')]: {
        padding: '10px 16px',
      },

      [theme.breakpoints.only('xs')]: {
        padding: '4px 16px',
      },

      ['@media (max-height:500px)']: {
        minHeight: 'auto',
        padding: '4px 16px',
      }
    }
  },
  timeFrameContainer: {
    minWidth: 270,
    [theme.breakpoints.only('sm')]: {
      minWidth: 250
    },
    [theme.breakpoints.only('xs')]: {
      minWidth: 'auto'
    },
  },
  portfoliosContainer: {
    minWidth: 270,
    [theme.breakpoints.only('xs')]: {
      minWidth: 'auto'
    },
  },
  benchmarkButtonContainer: {
    margin: '10px 0',
  },
  benchmarkButton: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    borderRadius: 2,
    boxShadow: 'none',
    textTransform: 'none',

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
  },
  hidden: {
    visibility: 'hidden'
  },
  buttonRoot: {
    height: FORM_FIELD_HEIGHT,
    borderRadius: 2,
    top: 20,

    [theme.breakpoints.down('sm')]: {
      height: 35,
      top: 18,
    },

    [theme.breakpoints.only("xs")]: {
      top: 0,
      width: '100%'
    }
  },
  includeHistoricalSwitch:{

  },
  includeHistoricalSwitchLabel: {
    color: '#4D4F5C',
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    margin: 0,
    [theme.breakpoints.only('sm')]: {
      fontSize: 13
    },

    [theme.breakpoints.only('xs')]: {
      fontSize: 12
    },
  }
}))
