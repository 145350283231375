import React from 'react'

import useStyles from './styles'
import {useCustomerSummaryContext} from "../../CustomerSummaryCard";
import images, {MailIcon, PhoneIcon} from "../../../../../../images";
import {Skeleton} from "@material-ui/lab";
import Switch from "../../../../../../components/Inputs/Switch";
import _ from "lodash";
import CustomerFullnameMenu from "../../../../../../components/CustomerFullnameMenu/CustomerFullnameMenu";
import clsx from "clsx";

function Contacts(props) {
  const {handleChangePermanentSuitabilityCheck, withSuitabilityCheck} = props;

  const {customer, loading, error, isOverview} = useCustomerSummaryContext();
  const classes = useStyles();

  const [suitabilityCheckEnabled, setSuitabilityCheckEnabled] = React.useState(false)

  React.useEffect(() => {
    if (_.get(customer, 'last_srri.srri')) {
      setSuitabilityCheckEnabled(customer.permanent_suitability_check_enabled)
    }
  }, [customer])

  const onSuitabilityCheckChange = (value) => {
    handleChangePermanentSuitabilityCheck(value).then(updated => {
      if (updated) {
        setSuitabilityCheckEnabled(value)
      }
    })
  }

  return (
    <div className={classes.userContainer}>
      {loading ? (
        <div className={classes.loadingContainer}>
          <Skeleton style={{width: '75%'}}/>
          <Skeleton style={{width: '50%'}}/>
          <Skeleton style={{width: '60%'}}/>
        </div>
      ) : (
        <>
          {customer && (
            <>
              <img src={images.default_user_logo} className={classes.userLogo} alt="User Logo"/>
              <div>
                <CustomerFullnameMenu
                  customer={customer}
                  customerFullname={
                    <h4 className={classes.userName}>
                      {customer.customer_full_name}
                    </h4>
                  }
                />
                <p className={classes.userId}>
                  Kunden-Nr: {customer.customer_id}
                </p>
                {customer.email && (
                  <p className={classes.userId}>
                    <MailIcon />
                    {customer.email}
                  </p>
                )}
                {customer.phone && (
                  <p className={classes.userId}>
                    <PhoneIcon />
                    {customer.phone}
                  </p>
                )}
                {isOverview && withSuitabilityCheck && (
                  <p className={clsx(classes.userId, classes.sustainabilityCheckSwitch)}>
                    <span style={{marginRight: 15}}>Dauerhafte Geeignetheitsprüfung:</span>
                    <Switch checked={suitabilityCheckEnabled} onChange={onSuitabilityCheckChange} onLabel="Ja" offLabel="Nein"/>
                  </p>
                )}
              </div>
            </>
          )}
          {error && (
            <p>Error</p>
          )}
        </>
      )}
    </div>
  )

}

Contacts.propTypes = {

};

Contacts.defaultProps = {
  withSuitabilityCheck: true
}

export default Contacts;