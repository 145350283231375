import React from 'react';
import _ from "lodash";

import useStyles from './styles';

import ModalContainer from '../../../../components/ModalContainer';
import {GROUP_NAME_MAX_LENGTH} from '../../../../containers/Groups/constants'
import {isGroupNameWithErrors} from '../../../Groups/utils';

import {
  TextField,
  Button,
  InputAdornment
} from '@material-ui/core';

const EditGroupNameModal = (props) => {
  const classes = useStyles();

  const [name, setName] = React.useState("");
  const [errors, setErrors] = React.useState("");

  React.useEffect(() => {
    if (!_.isNil(props.group)) {
      setName(props.group.name);
      setErrors("");
    }
  }, [props.group]);

  const handleSaveNameClick = () => {
    if (!_.isNil(props.onSaveClick)) {

      let err = isGroupNameWithErrors(name);
      if(err){
        setErrors(err);
        return;
      }

      props.onSaveClick(props.group, name);
    }
  };

  const handleModalClose = () => {
    if (!_.isNil(props.onModalClose)) {
      props.onModalClose();
    }
  }

  return (
    <ModalContainer open={props.open} handleClose={handleModalClose}>
      <p className={classes.modalTitle}>Geben Sie einen Gruppenname für Ihre Gruppe ein:</p>
      <div style={{paddingLeft: 16}}>
        <TextField
          className={classes.textField}
          label="Gruppenname"
          margin="normal"
          error={errors && 'name' in errors}
          value={name}
          onChange={event => setName(event.target.value)}
          helperText={errors && 'name' in errors ? errors.value : ' '}
          InputProps={{
            endAdornment: <InputAdornment className={classes.inputAdornment} position="end">{name && name.length || 0}/{GROUP_NAME_MAX_LENGTH}</InputAdornment>
          }}
          inputProps={{maxLength: GROUP_NAME_MAX_LENGTH}}
          InputLabelProps={{
            classes: {
              root: classes.inputLableRoot,
              shrink: classes.inputLabelShrink
            }
          }}
        />
      </div>
      <div className={classes.modalActions}>
        <Button className={classes.actionButton} color={"primary"} onClick={handleModalClose}>
          Abbrechen
        </Button>
        <Button className={classes.actionButton} color={"primary"} variant="contained" onClick={handleSaveNameClick}>
          Speichern
        </Button>
      </div>
    </ModalContainer>
  )
};

export default EditGroupNameModal;