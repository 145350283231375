import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  formContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  formBody: {
    width: '100%',
    '& .MuiFormControl-root': {
      margin: '5px 0'
    }
  },
  stepRequired: {
    float: "right",
    marginBottom: -16,
    color: '#0092E5',
    fontSize: 16,
    fontStyle: 'italic',
  },
  searchButton: {
    height: 50,
    marginRight: '-7px',
    boxShadow: 'none',
    borderRadius: '0 5px 5px 0',
    textTransform: 'none',
    fontFamily: 'Roboto-Bold',
  },
  nonFieldErrors: {
    listStyle: 'none',
    paddingLeft: 0,
    marginBottom: 0
  },
  formHelperText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12
  },
}));