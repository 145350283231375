import React, { useEffect, useState } from 'react'
import clsx from 'clsx';

import Tooltip from '@material-ui/core/Tooltip';

import DocumentsFinishModal from '../DocumentsFinishModal'
import { DeleteIcon, DetailsIcon, EditIcon } from '../../../../images'

import useStyles from './styles'
import { toLocalDateTime } from '../../../../utils/utils';


const defaultHeaderRow = {
  'name': 'Dokumentename',
  'is_finished': false,
  'id': 'all'
}

export default function CollectionItem({ collection, onDetailsClick, onDeleteClick, onEditFinishClick, activeCollection}) {

  const classes = useStyles()
  const [disabledDelete, setDisabledDelete] = useState(false)
  const [deleteHelpTitle, setDeleteHelpTitle] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [documents, setDocuments] = useState(collection.documents)
  const [headerRow, setHeaderRow] = useState(defaultHeaderRow)

  useEffect(() => {
    setHeaderRow((prevState) => ({
      ...prevState,
      is_finished: documents.every(obj => obj.is_finished)
    }))
  }, [])

  useEffect(() => {
    setDisabledDelete(activeCollection.id == collection.id)
  }, [activeCollection])

  useEffect(() => {
    if (disabledDelete) {
      setDeleteHelpTitle('Sie können eine Mappe nicht löschen, während sie geöffnet ist. Bitte schließen Sie diese zuerst.')
    }
  }, [disabledDelete])

  const handleDetailsClick = React.useCallback(() => {
    if (onDetailsClick) {
      onDetailsClick(collection.id)
    }
  }, [collection])

  const handleDeleteClick = React.useCallback(() => {
    if (onDeleteClick) {
      onDeleteClick(collection.id)
    }
  }, [collection])

  const DeleteCollectionButton = React.forwardRef(function MyComponent(props, ref) {
    return (
      <div {...props} ref={ref}>
        <span></span>
        <span ><DeleteIcon /></span>
        <span >Verwerfen</span>
      </div>
    )
  });

  const handleModalOnChange = (documentId) => {
    if (documentId) {
      if (documentId == 'all') {
        let newDocuments = [...documents]
        newDocuments.forEach(obj => obj.is_finished = !headerRow.is_finished)
        setDocuments(newDocuments)
        setHeaderRow((prevState) => ({
          ...prevState,
          is_finished: !prevState.is_finished
        }))
      } else {
        let newDocuments = [...documents]
        newDocuments.find(obj => obj.id == documentId).is_finished ^= 1
        setDocuments(newDocuments)
        if (newDocuments.find(obj => !obj.is_finished) && headerRow.is_finished) {
          setHeaderRow((prevState) => ({
            ...prevState,
            is_finished: false
          }))
        }
      }
    }
  }

  const handleFinishSaveButton = () => {
    setModalOpen(false)
    onEditFinishClick(collection.id, documents)
  };

  const createdAt = toLocalDateTime(collection.created_at);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.data}>
          <p className={classes.customerName}>
            { collection.documents && (
              <>
                <span>{ collection.documents.map(document => document.name).join(', ') }</span>
              </>
            )}
          </p>
          <p className={classes.handlingTime}>erstellt am <b>{ createdAt.format('DD.MM.YYYY') }</b> um <b>{ createdAt.format('HH:mm') } Uhr</b></p>
        </div>
        <div className={classes.actions}>
          <span className={classes.subNavigationButton} onClick={handleDetailsClick}>
            <span></span>
            <span><DetailsIcon /></span>
            <span>Öffnen</span>
          </span>
          <span className={classes.subNavigationButton} onClick={() => setModalOpen((prevState) => !prevState)}>
            <span></span>
            <span><EditIcon /></span>
            <span>Bearbeiten</span>
          </span>
          <span
            className={clsx(
              classes.subNavigationButton,
              disabledDelete && classes.disabled
            )}
          >
            <Tooltip
              title={deleteHelpTitle}
              classes={{ tooltip: classes.deleteHelperTitle }}
              onClick={disabledDelete ? null : handleDeleteClick}
            >
              <DeleteCollectionButton className={ classes.deleteButtonContainer }/>
            </Tooltip>
          </span>
        </div>
      </div>
      <div>
        <DocumentsFinishModal
          className={classes.modalContainer}
          open={modalOpen}
          documents={documents}
          headerRow={headerRow}
          handleOnChange={handleModalOnChange}
          handleClose={() => setModalOpen((prevState) => !prevState)}
          handleSaveClick={handleFinishSaveButton}
        />
      </div>
    </>
  )
}
