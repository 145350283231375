import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30.033"
      viewBox="0 0 30 30.033"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_8007"
            data-name="Rechteck 8007"
            width="30"
            height="30.033"
            transform="translate(0 0)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_8117" data-name="Gruppe 8117" clip-path="url(#clip-path)">
        <path
          id="Pfad_7808"
          data-name="Pfad 7808"
          d="M30,10.639H27.744L22.126,3.148,19.244,6.031,15,0,10.756,6.031,7.873,3.148,2.256,10.639H0V12.4H1.919L3.458,24.76h-1.7V23H0v5.274H1.758V26.518H5.332a3.516,3.516,0,0,0,7.031,0h5.274a3.516,3.516,0,0,0,7.031,0h1.655L28.081,12.4H30ZM21.936,5.824l3.611,4.814H22.488L20.271,7.49Zm-1.6,4.814H9.662L15,3.054ZM8.064,5.824,9.729,7.49,7.512,10.639H4.453Zm.784,22.451a1.758,1.758,0,1,1,1.758-1.758,1.76,1.76,0,0,1-1.758,1.758m12.3,0a1.758,1.758,0,1,1,1.758-1.758,1.76,1.76,0,0,1-1.758,1.758m3.618-3.516h-.576a3.512,3.512,0,0,0-6.085,0H11.89a3.512,3.512,0,0,0-6.085,0H5.229L3.69,12.4H26.31Z"
          transform="translate(0 0)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
