import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  wrapper: {
    backgroundColor: '#eef0f2',
    padding: '2rem 1.5rem'
  },
  pageSubtitle: {
    fontSize: '1.125rem',
    lineHeight: '1.5em',
    marginBottom: '2rem',
  }
})

export default makeStyles(styles)