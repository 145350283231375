import React from "react";
import {matchPath, withRouter} from "react-router-dom";
import { connect } from 'react-redux';
import clsx from "clsx";

import withStyles from '@material-ui/core/styles/withStyles';

import styles from "./styles";
import MenuItem from "./components/MenuItem";
import MenuSubItem from "./components/MenuSubItem";
import {getOriginLink, DEFAULT_ORIGIN} from "../../../../utils/constants";
import { getFromStorage, NEW_ORIGIN_KEY } from '../../../../utils/storage'
import {flattenMenuItems} from "../../utils";
import {
  getAlertingNotificationsCount
} from "../../../../components/DashboardDataProvider/DashboardDataProvider";
import { openInNewTab } from '../../../../utils/utils';

const mapStateToProps = (state) => ({
  alertingNotificationsCount: getAlertingNotificationsCount(state),
});

export const getMenuItemBadge = (item, badgeValue) => {
  // Workaround for alerting notifications only.
  // Need to refactor it later
  let badge = null
  if (item.visible && item.component == 'ASSETS_ALERTING_EVENTS_OVERVIEW' && badgeValue && badgeValue > 0) {
    badge = badgeValue
  }
  return badge
}

const SideMenu = props => {
  const {
    classes,
    menuConfig,
    history,
    open,
    onRootItemClick,
    onItemSelected,
    match: {
      path
    },
    location: {
      pathname
    },
    dispatch,
    onDivaLinkClick,
    alertingNotificationsCount
  } = props;

  const [activeItem, setActiveItem] = React.useState();
  const historyRef = React.useRef();

  React.useEffect(() => {
    if (menuConfig) {
      setActiveItem(menuConfig.find((item) => isActive(item)))
    }
  }, [menuConfig]);

  const findActiveMenuItem = (pathname) => {
    const menuItems = [...flattenMenuItems(activeItem.children || [], 3)];
    let newActiveItem = (menuItems || []).find((item) => isActive(item, pathname))
    if (newActiveItem) {
      onItemSelected && onItemSelected(newActiveItem)
    }
  }

  React.useEffect(() => {
    if (activeItem) {
      findActiveMenuItem()
      if (historyRef.current) historyRef.current()
      historyRef.current = history.listen(
        (location) => {
          findActiveMenuItem(location.pathname)
        });
    }
  }, [activeItem])

  /*React.useEffect(() => {
    if (open && !activeItem) {

      let menuItems = menuConfig.filter(item => item.bottom_placed === false)

      if (menuItems && menuItems.length) {
        setActiveItem(menuItems[0])
      }
    }
  }, [open, menuConfig])*/

  const isActive = (item, location) => {
    let isItemActive = matchPath(location || pathname, {
      path: path + item.full_path,
      exact: false,
      strict: false
    });

    return item.internal && !!isItemActive
  }

  const handleItemClick = (item, root_item) => {
    if (item.internal) {
      root_item && setActiveItem(item);
      onItemSelected && onItemSelected(item);
      root_item && onRootItemClick();

      if (!isActive(item)) {
        history.push(path + item.full_path);
      }
    } else {
      if ((item.diva_id || item.diva_id == 0) && item.diva_label) {
        onDivaLinkClick && onDivaLinkClick(item)
      } else {
        let itemPath = item.path;
        if (itemPath) {
          let origin = getFromStorage(NEW_ORIGIN_KEY) || DEFAULT_ORIGIN;
          itemPath = itemPath.replace(':origin', origin);
          if(itemPath.startsWith('/')){
            window.location.href = getOriginLink(itemPath)
          } else {
            openInNewTab(itemPath)
          }
        }
      }
    }
  }

  const handleRootItemClick = (item) => {
    return handleItemClick(item, true);
  };

  const handleChildItemClick = (item) => {
    return handleItemClick(item, false);
  };

  const renderFirstLevelItems = (bottom_placed) => {
    return menuConfig
      .filter(item => item.bottom_placed === bottom_placed)
      .map(item => {
        const active = isActive(item);
        return item.visible ? (
          <MenuItem
            key={item.id}
            active={active}
            onClick={handleRootItemClick}
            item={item}
            bottomPlaced={bottom_placed}
          />
        ) : null
      })
  }

  const renderSecondLevelItems = () => {
    return activeItem && activeItem.children && activeItem.children.map(item => {

      return (
        <MenuSubItem
          key={item.id}
          item={item}
          active={isActive(item)}
          onClick={handleChildItemClick}
          badge={getMenuItemBadge(item, alertingNotificationsCount)}
        />
      )
    });
  }

  return (
    <>
      <div className={classes.sideMenuContainer} id={"side_menu"}>
        <div className={clsx(classes.level1, !open && 'collapsed')}>
          <div className={classes.mainItems}>
            {renderFirstLevelItems(false)}
          </div>
          <div className={classes.bottomPlaced}>
            {renderFirstLevelItems(true)}
          </div>
        </div>

        {open && activeItem && activeItem.visible &&  (
          <div className={classes.level2}>
            <div className={classes.subTitle}>
              {activeItem.name}
            </div>
            {renderSecondLevelItems()}
          </div>
        )}

      </div>
    </>
  )
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(SideMenu)));