import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  text: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: theme.palette.primary.main,
    margin: '0px 10px'
  }

})

export default makeStyles(styles)