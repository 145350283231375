import React from "react";
import {Typography, withStyles} from "@material-ui/core";
import styles from "./styles";
import clsx from "clsx";
import {toGermanFormat} from "../../../../../../../utils/numberFormater";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "../../../../../../../components/Tooltip";


class KeyIndicatorSlider extends React.Component {

  render() {
    const { classes, minValue, maxValue, minSelected, maxSelected, markedValue, unit } = this.props;

    let totalRange = maxValue - minValue;
    let selectedRange = maxSelected - minSelected;

    let minPositionValue = `${((minSelected - minValue) / totalRange) * 100}%`;
    let markPositionValue = 'calc(' + ((markedValue - minValue) / totalRange) * 100 + '% - 2px)';

    const relativeWidth = (selectedRange / totalRange) * 100;
    const isTooCloseToEdge = relativeWidth < 9;

    return <div className={classes.background}>
      <div className={classes.minValueLabel}>{minValue} {unit !== undefined && `${unit}`}</div>
      <div className={classes.maxValueLabel}>{maxValue} {unit !== undefined && `${unit}`}</div>
      <div className={classes.blueFill} style={{width: `${relativeWidth}%`, left: minPositionValue}}>
        {minSelected !== minValue &&
          <div className={clsx(classes.blueLabel, isTooCloseToEdge ? classes.tooCloseLeft : classes.blueLabelLeft)}>
            {toGermanFormat(minSelected, '', '', 2)} {unit !== undefined && `${unit}`}
          </div>
        }
        {maxSelected !== maxValue &&
          <div className={clsx(classes.blueLabel, isTooCloseToEdge ? classes.tooCloseRight : classes.blueLabelRight)}>
            {toGermanFormat(maxSelected, '', '', 2)} {unit !== undefined && `${unit}`}
          </div>
        }
      </div>
      {markedValue !== undefined &&
        <div className={classes.greenMark} style={{left: markPositionValue}}>
          <div className={classes.greenMarkLabelText}>
            <div>
              {toGermanFormat(markedValue, '', '', 2)} {unit !== undefined && `${unit}`}
            </div>
          </div>
        </div>
      }
    </div>
  }
}

export default withStyles(styles)(KeyIndicatorSlider);
