import React from "react";

import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import withStyles from "@material-ui/core/styles/withStyles";

import {withEuroOrDash} from "../../../../../../../../../utils/utils";
import AssetsQuestion, {AssetsQuestionMethods} from "../../../../question/AssetsQuestion/AssetsQuestion";
import styles from "./styles";
import {getQuestionType, getUID} from "../../../../../../../utils";
import {
  ASSET_DATA_UID,
  LIABILITY_CHECKBOX_UID,
  LIABILITY_DATA_UID,
  QUESTION_TYPE
} from "../../../../../../../constants";
import InputQuestion from "../../../../question/InputQuestion";
import CheckboxQuestion from "../../../../question/CheckboxQuestion";

const MainStep = props => {

  const {
    classes,
    help_text,
    uid,
    name,
    questions,
    onAddClick,
    onEditClick,
    onDeleteClick,
    onAnswerChange
  } = props;

  const calcTotal = (question) => {
    return question && !question.disabled && question.answer && question.answer.reduce((sum, item) => {
      return sum + Number(item.value);
    }, 0) || 0;
  }

  const assetsQuestion = questions.find(q => getUID(q.uid) === ASSET_DATA_UID);
  const liabilityQuestion = questions.find(q => getUID(q.uid) === LIABILITY_DATA_UID);
  const liabilityCheckbox = questions.find(q => getUID(q.uid) === LIABILITY_CHECKBOX_UID);

  // set initial flags for liability if it's optional
  if(liabilityQuestion && liabilityCheckbox
    && !liabilityQuestion.hasOwnProperty('disabled') && liabilityQuestion.optional){
    liabilityQuestion.disabled = liabilityQuestion.optional = liabilityCheckbox.answer || false;
    liabilityCheckbox.cutomLogic = true;
  }

  const totalQuestion = questions.find((question) => {
      return getQuestionType(question, false) === QUESTION_TYPE.HIDDEN;
  });

  const actives = calcTotal(assetsQuestion);
  const passives = calcTotal(liabilityQuestion);
  const total = actives - passives;

  const assetsValid = AssetsQuestionMethods.valid(assetsQuestion, undefined, true);
  const liabilityValid = AssetsQuestionMethods.valid(liabilityQuestion, undefined, true);

  const handleCheckboxChange = (uid, newVal) => {
    liabilityQuestion.disabled = liabilityQuestion.optional = newVal;
    onAnswerChange(uid, newVal);
  }

  if(totalQuestion) totalQuestion.answer = total;

  return (
    <>
      <Grid container className={classes.total} spacing={2}>
        <Grid item xs={6}>
          {help_text && (
            <div className={classes.stepHelpText} data-id={`${uid}-help`}>
              {help_text}
            </div>
          )}
        </Grid>
        <Grid item xs={6}>
          <div className={classes.stepRequired}>
            <div className={classes.checkIconContainer}>
              <CheckCircleIcon classes={{root: classes.checkIcon}}
                               htmlColor={assetsValid ? '#23D374' : assetsQuestion.error ? '#f44336' : '#80858C'} />
              Es muss mindestens ein Vermögenswert angegeben werden.
            </div>
            <div className={classes.checkIconContainer} >
              <CheckCircleIcon classes={{root: classes.checkIcon}}
                               htmlColor={liabilityValid ? '#23D374' : liabilityQuestion.error ? '#f44336' : '#80858C'} />
              <span>
                {`Für Verbindlichkeiten muss mindestens ‘${liabilityCheckbox && liabilityCheckbox.question_text || ''}’ als Option ausgewählt, oder mindestens eine
                Verbindlichkeit angegeben werden.`}
              </span>
            </div>
          </div>
        </Grid>

        <Grid item xs={6} key={totalQuestion.uid}>
          <div className={classes.stepName} data-id={`${uid}-name`}>{name}</div>
          {withEuroOrDash(total)}
        </Grid>

        {liabilityCheckbox &&
          <Grid item xs={6} key={'uid'} style={{marginTop: 'auto'}}>
            <CheckboxQuestion
              disabled={liabilityQuestion.answer && liabilityQuestion.answer.length > 0}
              question={liabilityCheckbox}
              onAnswerChange={liabilityCheckbox.cutomLogic ? handleCheckboxChange : onAnswerChange}
            />
          </Grid>
        }
      </Grid>

      <Grid container className={classes.questionsContent} spacing={2}>
        {assetsQuestion &&
          <Grid item xs={6} key={assetsQuestion.uid}>
            <AssetsQuestion
              question={assetsQuestion}
              onAddClick={onAddClick}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          </Grid>
        }
        {liabilityQuestion &&
          <Grid item xs={6} key={liabilityQuestion.uid}>
            <AssetsQuestion
              question={liabilityQuestion}
              onAddClick={onAddClick}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          </Grid>
        }
        {totalQuestion && <InputQuestion question={totalQuestion}/>}
      </Grid>
    </>
  )
}

export default withStyles(styles)(MainStep);