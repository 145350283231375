import React from "react";
import {withStyles} from "@material-ui/core";
import _ from 'lodash';

import styles from "../../../../../VirtualPortfolioPage/Tabs/KeyFigures/items/EsgScoreChart/styles";
import { BaseEsgScoreChart } from '../../../../../VirtualPortfolioPage/Tabs/KeyFigures/items/EsgScoreChart';

class ProViewEsgScoreChart extends BaseEsgScoreChart {
  constructor(props) {
      super(props);

      this.state = {
        ...this.state,
        dataProvider: this.props.dataProvider
      }
  }

   componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.portfolioId !== this.props.portfolioId || JSON.stringify(this.props.selectedAssets) != JSON.stringify(prevProps.selectedAssets)){
      this.fetchEsgData();
    }
  }

  fetchEsgData() {
    if (!this.props.portfolioId) return;

    this.setState({
      errors: undefined,
      loading: true,
      data: false
    });

    this.state.dataProvider.getEsgScoreData(this.props.customerId, this.props.portfolioId, this.props.withHistoricalPortfolios, !this.props.isAllAssetsSelected ? this.props.selectedAssets : [], this.props.investmentStrategyId)
      .then((data) => {
        this.setState({
          errors: _.isEmpty(data.error)? null : data.error,
          data: this.props.isVirtual ? data.data[0] : data.data,
          loading: false
        })
      })
      .catch((error) => {
        this.setState({
          errors: error,
          loading: false,
          data: false
        })
      })
  }
}

export default withStyles(styles)(ProViewEsgScoreChart)