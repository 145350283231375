import { makeStyles, createStyles } from '@material-ui/core'

export const styles = (theme, props) => createStyles({
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 34,
    color: '#202A38',
    margin: 0
  },
  container: {
    backgroundColor: 'white',
    width: '100%',
    minHeight: 'calc(100% + 24px)',
    marginTop: -24,
    paddingTop: 24,
    overflowX: 'auto'
  },

  tableCell: {
    '&:first-child': {
      width: 300
    },

    '&:nth-child(2)': {
      width: 150
    },

    '&:last-child': {
      width: (props) => props.viewOnly ? 100 : 280,
      '& > div > span > div > span': {
        whiteSpace: 'nowrap',
        marginRight: 15
      }
    },

    fontFamily: 'Roboto-Regular',
    color: '#202A38'
  },
  tableRow: {
    transition: 'all',
    transitionDuration: 250,
    '&:hover': {
      backgroundColor: '#EEF0F2'
    }
  },
  tableHead: {
    '& td': {
      fontFamily: 'Roboto-Bold',
      color: '#80858C'
    }
  },

  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',

    '& button >span > span': {
      fontSize: 14
    }
  },
  addNewFavoriteListButtonRoot: {
    height: 36
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 50
  },
  recordsOnPage: {
    fontSize: 16,
    fontWeight: 'Roboto-Regular',
    color: '#80858C'
  },
  paginationInputLabel: {
    fontSize: 14,
    color: '#202A38'
  }
})

export default makeStyles(styles)