import React from "react";
import { useCookies } from 'react-cookie';
import moment from "moment";

import withStyles from "@material-ui/core/styles/withStyles";

import styles from "./styles";
import {PrimaryButton} from "../Buttons";

const CONSENT_COOKIE_KEY = 'bcaCookieConsent';
const CONSENT_COOKIE_VALUE = 'OK';
const CONSENT_EXPIRES_VALUE = 2;
const CONSENT_EXPIRES_UNIT = 'y';

const CookiesBanner = props => {
  const {
    classes,
  } = props;

  const [cookies, setCookie] = useCookies([CONSENT_COOKIE_KEY]);

  const handleClick = () => {
    const options = {
      path: '/',
      expires: moment().add(CONSENT_EXPIRES_VALUE, CONSENT_EXPIRES_UNIT).toDate()
    };

    setCookie(CONSENT_COOKIE_KEY, CONSENT_COOKIE_VALUE, options)
  }

  if (cookies[CONSENT_COOKIE_KEY] === CONSENT_COOKIE_VALUE) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.messageContainer}>
        <p className={classes.message}>
          Mit der Nutzung unserer Webseite erklären Sie sich damit einverstanden, dass wir Cookies verwenden.
        </p>
      </div>
      <div className={classes.buttonContainer}>
        <PrimaryButton
          onButtonClick={handleClick}
          color={'inherit'}
          text={'Einverstanden'}
          customClasses={{
            root: classes.buttonRoot,
            colorInherit: classes.buttonColorInherit
          }}
        />
      </div>
    </div>
  )
};

export default withStyles(styles)(CookiesBanner);