import { makeStyles, createStyles } from '@material-ui/core'
import images from "../../../../../images";

export const styles = theme => createStyles({
  root: {
    '& $markActive:last-child': {
      backgroundColor: 'red !important'
    }
  },
  sliderRail: {
    color: '#80858C',
    opacity: 1
  },
  sliderTrack: {
    color: theme.palette.primary.main,
    display: 'block !important'
  },
  mark: {
    backgroundColor: '#80858C',
    height: 8,
    width: 8,
    marginTop: -3,
    marginLeft: -4,
    borderRadius: '50%'
  },
  markActive: {
    opacity: 1,
    backgroundColor: theme.palette.primary.main,
  },
  markLabe: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38'
  },
  markLabelActive: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
  },
  thumb: {
    position: 'relative',
    backgroundColor: 'transparent',
    boxShadow: 'none!important',

    '&::after': {
      content: 'none'
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      left: -18,
      bottom: 3,
      width: 48,
      height: 61,
      backgroundImage: `url(${images.risk_profiling.slider_thumb_2})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },

    '&:last-child': {
      '&::before': {
        bottom: 10,
      }
    }
  },
})

export default makeStyles(styles)