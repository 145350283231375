import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  resetButton: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '5px 10px 5px 5px',
    borderRadius: 4,
    margin: 'auto 10px auto auto',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#CCE9FA',
    },

    '& span:first-child': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 28,
      width: 28,
      borderRadius: 20,
      marginRight: 8,
      position: 'relative',

      [theme.breakpoints.only("xs")]: {
        marginRight: 0,
      },
    },

    '& span:last-child': {
      fontFamily: 'Roboto-Bold',
      fontSize: 14,
      color: theme.palette.primary.main,
      position: 'relative',

      [theme.breakpoints.only("xs")]: {
        display: 'none',
      },
    },

    [theme.breakpoints.only("xs")]: {
      margin: 'auto auto auto 0',
      padding: 5,
    },
  },
}))