import { makeStyles } from '@material-ui/core'; 

export default makeStyles(theme => ({
  container: {
    width: '100%',

    '& > $header': {
      color: '#626970',
      fontSize: 14,
      fontFamily: "Roboto-Bold",
      margin: 0
    }
  },
  header: {},
  filtersAction: {
    backgroundColor: 'white',
    padding: '9px !important',
    marginBottom: '0px !important',
    borderRadius: '0 0 2px 2px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  filters: {
    marginTop: 10,
    width: '100%',
    backgroundColor: '#ABB6BE',
    borderRadius: 2,
    padding: '9px 0',
    paddingBottom: 0,
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',

    '& > div': {
      marginBottom: 5,
      padding: '0 9px',
    },

    '& > div:first-child': {
    },
  },
  filtersTransparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none',

    '& > div': {
      margin: '5px 0',
      padding: '0'
    },
  },
  clearFiltersButton: {
    textTransform: 'none',
    fontFamily: 'Roboto-Medium',
    fontSize: 16
  }
}));