import React from 'react';
import {Grid} from "@material-ui/core";
import useStyles from "../../styles";


export default (props) => {

  const classes = useStyles();

  return (
    <Grid container>
      {/* Title section */}
      <Grid item xs={12} style={{marginTop: 20}} className={classes.section}>
        <h2 style={{fontSize: '2rem'}}>
          {props.title}
        </h2>
      </Grid>

      {/* Body section */}
      <Grid item xs={12}>
        {props.children}
      </Grid>
    </Grid>
  )
}