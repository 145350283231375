import {makeStyles} from '@material-ui/core';
import { FORM_FIELD_HEIGHT } from '../../../TransactionsMonitoring/components/Filters/components/common_styles';

export default makeStyles(theme => ({
  container: {
    marginBottom: 23,
    marginTop: -23,

    [theme.breakpoints.down('sm')]: {
      marginBottom: 16,
    }
  },
  header: {
    fontFamily: 'Roboto-Regular',
    fontSize: 34,
    color: '#626970',

    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    }
  },
  expansionPanelContainer: {
    position: 'relative',
    height: 66,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      height: 56
    },
  },
  expansionPanelRoot: {
    position: 'absolute',
    borderRadius: '2px !important',
    height: FORM_FIELD_HEIGHT,
    zIndex: 2,
    top: 'calc(100% - 45px)',
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: 'none',

    [theme.breakpoints.down('sm')]: {
      height: 35,
      top: 'calc(100% - 35px)',
    },
  },
  expansionPanelExpanded: {
    height: 'auto',
    zIndex: 1000,
    transform: 'translate3d(0,0,1px)',
    margin: '0 !important',
  },
  panelButton: {
    minHeight: 45,
    [theme.breakpoints.down('sm')]: {
      minHeight: 35
    },
  },
  label: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#4D4F5C',
    marginRight: 20,

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
  },
  btnText: {
    whiteSpace: 'normal'
  }
}));
