import React from 'react';
import clsx from 'clsx';

import withStyles from "@material-ui/core/styles/withStyles";

import styles from './styles';
import connect from "react-redux/lib/connect/connect";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import {useStyles} from "../styles";
import LabelFormElement from "../LabelFormElement"
import {FIELD_DOES_NOT_FITT_MSG} from "../../../../../constants";
import ExtendedHTMLEditor from "../../../../../../Editor/ExtendedHTMLEditor";
import { Quill }  from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import Image from '../../../../../../GroupReporting/components/EditorStepContent/components/RichTextEditor/image';

Quill.register('modules/imageResize', ImageResize);
Quill.register(Image);

const RichTextEditor = (props) => {
  const {
    classes,
    customClasses,
    label,
    required,
    value,
    disabled,
    onChange,
    error,
    placeholder,
    uid,
    hideToolbar,
    hideDisabledOverlay,
    useLatestTemplate
  } = props;

  const inputClasses = useStyles();
  // const height = (rows || 6) * 18; // 18px is height for 1 row

  const handleKeyDown = (event) => {
    // prevent new characters in case limit reached
    if(error === FIELD_DOES_NOT_FITT_MSG && !['Backspace', 'Delete'].includes(event.key)){
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const getEditorConfig = () => {
    return {
      toolbar: hideToolbar ? false : {
        container: [
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline'],
          [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'color': [] }, { 'background': [] }],
          ['link', 'image', ],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
        ],
          ['clean'],
        ]
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
    };
  }

  return (
    <>
      {!!label && (
        <LabelFormElement
          error={!!error}
          label={label}
          required={required}
          customClasses={customClasses}
        />
      )}
      <div id={uid} className={clsx(
        classes.container,
        !disabled && classes.enabled,
        hideDisabledOverlay && classes.withoutOverlay,
      )}>
        <ExtendedHTMLEditor
          bounds={'#' + uid}
          placeholder={placeholder}
          className={inputClasses.inputRoot}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          modules={getEditorConfig()}
          uid={uid}
          useLatestTemplate={useLatestTemplate}
          hideToolbar={hideToolbar}
        />
        {!!error && <FormControl error={true}><FormHelperText>{ error }</FormHelperText></FormControl>}
      </div>

    </>
  );

};

export default connect()(withStyles(styles)(RichTextEditor));

