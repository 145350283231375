import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Switch from '@material-ui/core/Switch';

import useStyles from './styles';

/** Switch input */
function SwitchInput(props) {

  const {
    checked,
    onLabel,
    offLabel,
    label,
    onChange,
    disabled,
  } = props;

  const classes = useStyles();
  const customClasses = props.classes || {};

  const handleChange = (value) => {
    if (!disabled && onChange) {
      onChange(value)
    }
  }

  return (
    <div className={clsx(classes.container, disabled && classes.disabled, customClasses && customClasses.container)} onClick={() => handleChange(!checked)}>
      {label && (
        <span className={classes.inputLabel}> { label } </span>
      )}
      <Switch
        checked={checked}
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        disabled={disabled}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
          disabled: classes.disabled,
          ...customClasses
        }}
      />
       <span className={clsx(customClasses.label, classes.label, checked ? classes.onLabel : classes.offLabel)}>
        {checked ? onLabel : offLabel}
      </span>
    </div>
  )
}

SwitchInput.propTypes = {
  /**Flag, that indicate, is swith turned on, or not */
  checked: PropTypes.bool.isRequired,

  /** Swith turned on label */
  onLabel: PropTypes.string,

  /** Switch turned of label */
  offLabel: PropTypes.string,

  /** Switch input label */
  label: PropTypes.string
}

SwitchInput.defaultProps = {
  onLabel: 'EIN',
  offLabel: 'AUS'
}

export default SwitchInput

