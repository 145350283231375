import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    width: 'auto',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },

  /** Input styles */
  root: {
    width: '100%' ,
    height: 50,

    '& > div': {
      overflow: 'hidden'
    }
  },
  input: {
    padding: '15.5px 14px',
  },
  darkPlaceholder: {
    '& input::placeholder': {
      color: 'rgba(0, 0, 0, 0.87)',
      opacity: 1,
    }
  },

  /** Dropdown widget */
  dropdownWidget: {
    position: 'absolute',
    width: '100%',
    zIndex: 10,
    top: 53
  },

  dropdownPopper: {
    background: 'white',
    boxShadow: '0px 3px 15px #00000033',
    borderRadius: 5,
    zIndex: 1300,
    maxHeight: 520,
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: 8,
      marginRight: 5
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8DCDF',
      borderRadius: 4,

    }
  },

  label: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
  },

  focused: {
    '& svg path': {
      fill: theme.palette.primary.main
    }
  },
  disabled: {
    color: '#202A38',

    '& fieldset': {
      border: '1px solid #dfdfdf73'
    }
  },

  fullWidth: {
    width: '100%'
  },

  errorMessageTooltip:{
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    lineHeight: 1.6
  },

  errorMessage: {
    fontFamily: 'Roboto-Regular',
    margin: '3px 0',
    color: 'red',
    fontSize: 14
  },

  searchButton: {
    padding: '1.1em',
    marginRight: -15,
    fontSize: 'inherit'
  }
})

export default makeStyles(styles)