import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'


import Customers from './Customers'
import { executeIfPathExist, getInvestmentDynamicPath } from '../../containers/InvestmentPlatform/utils'
import {CUSTOMER_ID_KEY, setInStorage} from "../../utils/storage";


const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
  investmentPlatform: state.get('investmentPlatform').toJS()
});


const withCustomersSelector = (componentIdentifier, buttonTitle, onButtonClick=undefined) => WrappedComponent => {
  return withRouter(connect(mapStateToProps)((props) => {

    const handleButtonClick = (id) => {

      if (onButtonClick) {
        onButtonClick(id)
        return
      }

      executeIfPathExist(props.investmentPlatform.routes, componentIdentifier, (path) => {
        setInStorage(CUSTOMER_ID_KEY, id);
        let investmentDynamicPath = getInvestmentDynamicPath()
        props.history.push(`/${investmentDynamicPath}${path.replace(':customer_id', id)}`)
      }, ':customer_id')
    }

    if (props.match.params.customer_id) {
      return (
        <WrappedComponent {...props}/>
      )
    }

    return (
      <Customers auth={props.auth} buttonTitle={buttonTitle} onButtonClick={handleButtonClick}/>
    )

  }))
}

export default withCustomersSelector