import React from "react";
import _ from "lodash";

import Skeleton from "@material-ui/lab/Skeleton";
import {FormControl, MenuItem, Select} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {openMorningStartIsin, withEuroOrDash} from "../../../utils/utils";
import {
  DepotSubNumberCell,
  IsinCell, SustainabilityCell
} from '../../../utils/commonTableColumns';
import {useTradingActionsStyles} from "../InstrumentsAllocationTable/styles";
import {SAVING_PLANS_ACTIONS} from "../InstrumentsAllocationTable/constants";
import {InstrumentNameCell} from "../../../containers/Modelportfolios/components/InstrumentsList/table-data";


const RowNumberCell = {
  header: 'NR.',
  body: {
    content: (item, options, index) => (item.isModelportfolio || item.isHeader) ? null : `${index + 1}.`,
    className: 'bold'
  },
  key: 'RowNumberCell'
}

const ProductNameCell = {
  header: 'PRODUKTNAME',
  body: {
    content: (item) => item.name,
    onClick: (item) => !item.isModelportfolio && openMorningStartIsin(item.isin),
    className: 'name bold link',
    ellipsis: true
  },
  footer: {
    content: 'Summe aktiver Sparpläne',
    className: 'bold'
  },
  key: 'ProductNameCell'
}

const PayoutPlansProductNameCell = {
  ...ProductNameCell,
  footer: {
    ...ProductNameCell.footer,
    content: 'Summe aktiver Entnahmepläne',
  },
}

const SwitchPlansProductNameCell = {
  ...ProductNameCell,
  footer: {
    ...ProductNameCell.footer,
    content: 'Summe aktiver Tauschpläne',
  },
}

const CycleCell = {
  header: 'TURNUS',
  body: {
    content: (item) => item.shifts,
  },
  key: 'CycleCell'
}

const ContractCell = {
  header: 'VERTRAGSART',
  body: {
    content: (item) => item.contract
  },
  key: 'ContractCell'
}

const RateCell = {
  header: 'RATE',
  body: {
    content: (item) => withEuroOrDash(+item.rate)
  },
  footer: {
    content: (item, options) => withEuroOrDash(+options.total)
  },
  key: 'RateCell'
}

const StatusCell = {
  header: 'STATUS',
  body: {
    content: (item) => (
      <>
        <span>{item.status_label}</span>
        {item.active_date && (
          <>
            <br className={'hide-xs'}/>
            <span className={'show-xs'}>&nbsp;</span>
            <span>(seit {item.active_date})</span>
          </>
        )}
      </>
    )
  },
  key: 'StatusCell'
}

const TradingActionsCell = {
  header: 'Plan ändern',
  body: {
    content: (item, options) => {

      let {
        isModelportfolio, tradePlans, paymentPlan, onAddSavingPlanOption, loading, tradeTransactions, disabled
      } = options;
      if(!onAddSavingPlanOption){
        return null;
      }
      if(loading){
        return <Skeleton />
      }

      const classes = useTradingActionsStyles()

      const handleTradingOptionChange = (event) => {
        onAddSavingPlanOption(paymentPlan, item, event.target.value)
      }

      const tradingOption = _.find(tradePlans, instrument => instrument.instrumentId == item.payment_id)
      // tradingType info from existing trade session
      let value = _.get(tradeTransactions, item.payment_id) || (tradingOption && tradingOption.action) || "default";

      return (
        <div className="tradingCell">
          {isModelportfolio ? (
            <span style={{fontFamily: 'Roboto-Regular', color: '#999D9F', fontSize: 14, fontStyle: 'italic'}}>Only on Portfolio level</span>
          ) : (
            <>
              { !item.isSubComponent && !!item.isin && (
                <FormControl style={{width: '100%'}}  className={classes.selectRoot}>
                  <Select
                    classes={{
                      root: classes.selectRoot,
                      selectMenu: classes.selectMenu,
                    }}
                    className={classes.selectRoot}
                    MenuProps={{
                      classes: {
                        paper: classes.selectMenuPaper,
                      }
                    }}
                    classes={{
                      icon: classes.selectMenuIcon
                    }}
                    onChange={handleTradingOptionChange}
                    value={value}
                    IconComponent={ExpandMoreIcon}
                    disabled={disabled}
                  >
                    <MenuItem
                    classes={{
                      root: classes.selectMenuItemRoot
                    }} value="default">-</MenuItem>
                    {SAVING_PLANS_ACTIONS.map(option => (
                      <MenuItem value={option.value} classes={{
                        root: classes.selectMenuItemRoot
                      }}> { option.text } </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
        </div>
      )
    }
  },
  key: 'TradingActionsCell'
}

const getProductNameCell = (paymentPlanType) => {
  return {
    'savings_plan': ProductNameCell,
    'payout_plan': PayoutPlansProductNameCell,
    'switch_plan': SwitchPlansProductNameCell
  }[paymentPlanType] || ProductNameCell
}

export const getTableStructure = (paymentPlanType) => {

  const productNameCell = getProductNameCell(paymentPlanType)

  return {
    default: [
      {
        content: [RowNumberCell],
      },
      {
        content: [productNameCell, IsinCell, DepotSubNumberCell]
      },
      {
        content: [CycleCell]
      },
      {
        content: [ContractCell]
      },
      {
        content: [RateCell],
        align: 'right'
      },
      {
        content: [StatusCell]
      }
    ],
    xs: [
      {
        content: [RowNumberCell],
      },
      {
        content: [productNameCell, StatusCell]
      },
      {
        content: [RateCell],
        align: 'right'
      },
    ]
  }

}

export const getTradingTableStructure = (paymentPlanType) => {

  const productNameCell = getProductNameCell(paymentPlanType)

  return {
    default: [
      {
        content: [RowNumberCell],
      },
      {
        content: [productNameCell, IsinCell, DepotSubNumberCell]
      },
      {
        content: [CycleCell]
      },
      {
        content: [ContractCell]
      },
      {
        content: [RateCell],
        align: 'right'
      },
      {
        content: [StatusCell]
      },
      {
        content: [TradingActionsCell],
        align: 'right'
      }
    ],
    xs: [
      {
        content: [RowNumberCell],
      },
      {
        content: [productNameCell, StatusCell]
      },
      {
        content: [RateCell, TradingActionsCell],
        align: 'right'
      }
    ]
  }

}

export const getTableStructureEditList = (withSustainability) => {
  const structure = {
    default: [
      {
        content: [InstrumentNameCell]
      },
      {
        content: [CycleCell]
      },
      {
        content: [ContractCell]
      },
      {
        content: [RateCell]
      },
      {
        content: [StatusCell]
      },
    ],
  }

  if (withSustainability) {
    structure.default.splice(1, 0, {content: [SustainabilityCell], cellClassName: 'sustainability-cell'});
  }
  return structure
}