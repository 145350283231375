import _ from 'lodash';
import { getFromStorage, SHARED_SETTINGS_KEY } from '../../utils/storage'
import {getCurrentUrlDynamicInvestmentAppPath} from "../../utils/utils";

export const getRouteFullPathByComponent = (routes, component, param=undefined) => {
  for (let index = 0; index < routes.length; index++) {
    if (routes[index].component == component) {
      if (param && routes[index].full_path.includes(param)) {
        return routes[index].full_path
      }
      if (!param) {
        return routes[index].full_path;
      }
    }

    if (routes[index].children) {
      for (let childIndex = 0; childIndex < routes[index].children.length; childIndex++) {
        let fullPath = getRouteFullPathByComponent([routes[index].children[childIndex]], component, param);
        if (fullPath) {
          return fullPath
        }
      }
    }
  }

  return null;
}

export const executeIfPathExist = (routes, path, callback, param=undefined) => {
  if (routes) {
    let fullPath = getRouteFullPathByComponent(routes, path, param);
    if (fullPath) {
      return callback(fullPath)
    }
  }
}

export const shortNumber = (value) => {
  if (!value) {
    return 0;
  }

  if (value >= 100 && value < 1000) {
    return '99+';
  }

  if (value >= 1000) {
    const Ts = parseFloat((value / Math.pow(1000, 1)).toPrecision(2));
    return Ts + 'T';
  }
  return value.toString();
}

export function getInvestmentDynamicPath() {

  let dynamicInvestmentPath = 'investment-beta'

  // TODO: Remove this part later
  let currentPath = getCurrentUrlDynamicInvestmentAppPath();
  if (currentPath === dynamicInvestmentPath) {
    return dynamicInvestmentPath
  }

  let storage = getFromStorage(SHARED_SETTINGS_KEY)
  if (storage && storage.app_settings) {
    dynamicInvestmentPath = storage.app_settings.dynamic_investment_path
  }
  return dynamicInvestmentPath
}

export function* flattenMenuItems(children, depth) {
  if (depth === undefined) {
    depth = 1;
  }

  for (const item of children) {
    if (item.children && depth > 0) {
      yield* flattenMenuItems(item.children, depth - 1);
    } else {
      yield item;
    }
  }
}