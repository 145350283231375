import React from 'react';
import _ from 'lodash';
import clsx from "clsx";

import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableFooter,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

import PrimaryButton from '../../../../../../components/Buttons/PrimaryButton';
import Pagination from '../../../../../../components/Pagination/Pagination';
import Tooltip from "../../../../../../components/Tooltip";

import useStyles from './styles';
import { withEuroOrDash, paginateArray } from '../../../../../../utils/utils';
import CustomerFullnameMenu from "../../../../../../components/CustomerFullnameMenu/CustomerFullnameMenu";

export const ACTION_TYPE = {
  CREATE: 1,
  ADD: 2,
  REMOVE: 3
};


const CustomersListHeader = () => {
  const classes = useStyles();

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell>Zur Gruppe <br /> hinzufügen</TableCell>
        <TableCell style={{maxWidth: 150}}>KUNDENNAME</TableCell>
        <TableCell>KUNDENNUMMER</TableCell>
        <TableCell>Gesamtwert<br />der Depots</TableCell>
      </TableRow>
    </TableHead>
  );
};


const CustomersListControlRow = (props) => {
  const classes = useStyles();

  const {
    actionType,
    customers,
    onCheckboxClick,
    loading,
    disabled
  } = props;

  const getInGroupMessage = () => {
    return `Sie haben ${customers.length} Kunde${customers.length > 1 ? 'n' : ''} in dieser Gruppe.`
  };

  return (
    <>
      {
        props.loading ? (
          <TableRow className={classes.controlRow}>
            <TableCell>
            </TableCell>
            <TableCell colSpan={4} style={{paddingTop: 15, paddingBottom: 15}}>
              Lade Ergebnisse, die Ihren Filter- und Suchkriterien entsprechen.
            </TableCell>
          </TableRow>
        ) : (
          <TableRow className={classes.controlRow}>
            <TableCell>
              <FormControlLabel
                classes={{
                  label: classes.controlRowCheckboxLable
                }}
                label="Alle markieren"
                disabled={disabled}
                control={
                  <Checkbox
                    checked={props.checked}
                    color="primary"
                    onChange={onCheckboxClick}/>
                }
              />
            </TableCell>
            <TableCell colSpan={3}>
              {(actionType && actionType === ACTION_TYPE.REMOVE) && (
                <>{getInGroupMessage()}</>
              )}
            </TableCell>
          </TableRow>
        )
      }
    </>
  );
};


const CustomerListItem = ({customer, handleCustomerSelected, checked, disabled}) => {
  const classes = useStyles();

  return (
    <TableRow className={clsx(classes.customerDetailRow)} key={customer.customer_id}>
      <TableCell>
        <FormControlLabel
          disabled={disabled}
          control={<Checkbox
            checked={checked}
            color="primary"
            onChange={() => handleCustomerSelected(customer)}/>}
        />

      </TableCell>
      <TableCell className={classes.tableCellBold}  style={{maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis'}}>
        <CustomerFullnameMenu
          customer={customer}
          customerFullname={customer.customer_full_name}
        />
      </TableCell>
      <TableCell>
        {customer.customer_id}
      </TableCell>
      <TableCell>{withEuroOrDash(customer.aggregated_portfolio_value && parseFloat(customer.aggregated_portfolio_value))}</TableCell>
    </TableRow>
  );
};


const CustomersListFooter = ({currentPage, totalPages, onPageChanged, onSelectCustomers, loading, isSaveDisabled}) => {
  const classes = useStyles();

  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={4}>
          <div className={classes.paginationSection}>
            {loading == false && (
              <>
                <p>Seite {currentPage + 1} von {totalPages}</p>
                <Pagination
                  currentPage={currentPage}
                  totalPageCount={totalPages}
                  handlePageChanged={(selected) => onPageChanged(selected)}
                  justifyContent="flex-start"
                />
              </>
            )}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4}>
          <PrimaryButton
            id="report-settings-create-group-select-client-button"
            text="Markierte Kunden zur Gruppe hinzufügen"
            onButtonClick={onSelectCustomers}
            disabled={isSaveDisabled}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};


const CustomersListLoadingIndicator = (props) => {
  const classes = useStyles();

  const renderCustomersLoading = () => {
    const rows = [];
    for (let i = 0; i < 8; i++) {
      rows.push((
        <TableRow key={i}>
          <TableCell />
          <TableCell>
            <div className={classes.loadingLine} />
          </TableCell>
          <TableCell>
            <div className={classes.loadingLine} />
          </TableCell>
          <TableCell>
            <div className={classes.loadingLine} />
          </TableCell>
        </TableRow>
      ))
    }
    return rows;
  };

  return (<>{renderCustomersLoading()}</>)
}


const CustomersList = (props) => {

  const classes = useStyles();

  //#region Component State

  const [selectedCustomers, setSelectedCustomers] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);

  //#endregion

  const isCustomerSelected = (customer) => {
    return (!_.isNil(_.find(selectedCustomers, customerData => customerData.id == customer.id)));
  };

  const getAvailableCustomers = () => {
    if (!_.isNil(props.unavailableCustomers) && !_.isNil(props.customersData.data)) {
      let allCustomers = props.customersData.data.reduce((customers, currentPage) => [...customers, ...currentPage], []);

      let availableCustomers = [];

      allCustomers.forEach((customer) => {
        let isUnavailable = _.find(props.unavailableCustomers, data => data.id == customer.id);
        if (!isUnavailable) {
          availableCustomers.push(customer);
        }
      });

      return [...paginateArray(availableCustomers, 12)];

    }

    return props.customersData.data
  }

  const handleCustomerSelected = (customer) => {
    const customers = [...selectedCustomers];

    if (isCustomerSelected(customer)) {
      let updatedCustomers = _.filter(customers, customerData => customerData.id != customer.id);
      setSelectedCustomers(updatedCustomers);
    } else {
      let updatedCustomers = [...selectedCustomers, customer];
      setSelectedCustomers(updatedCustomers);
    }
  };

  const getCustomersFromPages = () => {
    if (_.isNil(props.customersData.data)) {
      return [];
    } else {

      let customersFromPages = props.customersData.data.reduce((customers, currentPage) => [...customers, ...currentPage], []);

      if (props.unavailableCustomers && props.unavailableCustomers.length > 0) {
        let selectedCustomersIdentifiers = props.unavailableCustomers.map(customer => customer.id);

        customersFromPages = _.filter(customersFromPages, customer => !_.includes(selectedCustomersIdentifiers, customer.id));
      }

      return customersFromPages;
    }
  }

  const handleAllSelected = () => {

    const customersFromPage = getCustomersFromPages();

    if (selectedCustomers.length !== customersFromPage.length) {

      setSelectedCustomers(customersFromPage);
    } else {
      setSelectedCustomers([]);
    }
  };

  const renderCustomers = () => {
    const {
      data,
      loading,
      errors
    } = props.customersData;

    let customersPages = getAvailableCustomers();
    if (!_.isNil(customersPages)) {
      if (!loading && !errors && !_.isNil(data) && customersPages.length > 0) {
        if (currentPage < customersPages.length) {
          if (customersPages.length > 0) {
            if(customersPages.length > currentPage) {
              return customersPages[currentPage].map(customerData => (
                <CustomerListItem
                  key={customerData.id}
                  checked={isCustomerSelected(customerData)}
                  customer={customerData}
                  handleCustomerSelected={handleCustomerSelected}
                  disabled={props.customerListDisabled}
                />));
            } else {
              setCurrentPage(customersPages.length - 1 >= 0 ? customersPages.length - 1 : 0);
            }
          }
        } else {
          setCurrentPage(customersPages.length - 1 >= 0 ? customersPages.length - 1 : 0);
        }
      }
    }

    return [];
  }

  const handleCheckedCustomersSelected = () => {
    if (!_.isNil(props.onSelectCustomers)) {
      props.onSelectCustomers(selectedCustomers);
      setSelectedCustomers([]);
    }
  }

  const isAllCustomersChecked = () => {
    return selectedCustomers.length == getCustomersFromPages().length
  }

  return (
    <div className={classes.container} id="report-settings-create-group-client-list-tour-element">
      {props.dataLoadingFailed ? (
        <div className={classes.errorMessageContainer}>
          <p>Ein Fehler beim Laden Ihrer Kunden ist aufgetreten. Bitte versuchen Sie es erneut.</p>
        </div>
      ) : (
        <Table>
          <CustomersListHeader />
          <TableBody>
            <CustomersListControlRow
              onCheckboxClick={handleAllSelected}
              actionType={props.actionType}
              customers={getCustomersFromPages()}
              checked={isAllCustomersChecked()}
              loading={props.customersData.loading}
              disabled={props.customerListDisabled}
            />
            {
              props.customersData.loading && !props.dataLoadingFailed ? (
                <CustomersListLoadingIndicator />
              ) : (
                <>
                  {renderCustomers()}
                </>
              )
            }

          </TableBody>
          {!props.customersData.loading && props.customersData.data && (
            <CustomersListFooter
              currentPage={currentPage}
              totalPages={getAvailableCustomers().length}
              onPageChanged={setCurrentPage}
              onSelectCustomers={handleCheckedCustomersSelected}
              loading={props.customersData.loading}
              isSaveDisabled={selectedCustomers.length == 0}
            />
          )}
        </Table>
      )}

    </div>
  );
};

export default CustomersList;