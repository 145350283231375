import {
  ADD_GROUP_FOR_GUIDE_KEY,
  REMOVE_GROUP_FOR_GUIDE_KEY,
  SET_GROUPS_KEY,
  SET_LOADING_KEY,
  SET_ERRORS_KEY
} from "./constants";

export const addGroupForGuide = () => ({
  type: ADD_GROUP_FOR_GUIDE_KEY
});

export const removeGroupForGuide = () => ({
  type: REMOVE_GROUP_FOR_GUIDE_KEY
});

export const setGroupsData = (groups=[]) => ({
  type: SET_GROUPS_KEY,
  groups
});

export const setGroupsLoading = (loading) => ({
  type: SET_LOADING_KEY,
  loading
});

export const setGroupsLoadingErrors = (errors) => ({
  type: SET_ERRORS_KEY,
  errors
});
