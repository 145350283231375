import _ from "lodash";
import {MIN_DOCUMENTS_BANKS_NUMBER} from "./components/constants";

export class DepotDocumentIdentifier {

  constructor(depotId, documentId, objectType) {
    this.__depotId = depotId
    this.__documentId = documentId
    this.__objectType = objectType
  }

  get documentId() {
    return this.__documentId
  }

  get depotId() {
    return this.__depotId
  }

  get objectType() {
    return this.__objectType
  }

  equals(_documentIdentifier) {
    return _documentIdentifier instanceof DepotDocumentIdentifier
      && this.depotId === _documentIdentifier.depotId
      && this.documentId === _documentIdentifier.documentId
      && this.objectType === _documentIdentifier.objectType
  }

}


export class DepotDocumentIdentifiersList extends Array{

  /**
   * @param searchElement {DepotDocumentIdentifier}
   */
  includes(searchElement, fromIndex) {

    return !!this.find((item) => item.equals(searchElement))

  }

  /**
   * Return new list and put document identifier to the end.
   * In case document identifier already in the list - remove it from there
   * @param documentIdentifier {DepotDocumentIdentifier} Document identifier
   * @returns {any[]|T[]}
   */
  toggleDocumentIdentifier(documentIdentifier) {

    // We always should return new list, so React could track change in list
    if (!this.includes(documentIdentifier)) {
      return super.concat(documentIdentifier)
    } else {
      return this.filter((_documentIdentifier) => !_documentIdentifier.equals(documentIdentifier))
    }

  }

  remove(documentIdentifier) {
    return this.filter((_documentIdentifier) => !_documentIdentifier.equals(documentIdentifier))
  }

  /**
   * Convert list of document identifiers to json structure
   * @returns {{[depotId: Number]: Number[]}}
   */
  toJSON() {

    const jsonRepresentation = {}
    this.forEach((documentIdentifier) => {

      _.defaults(jsonRepresentation, {[documentIdentifier.objectType]: {}})

      if (!jsonRepresentation[documentIdentifier.objectType].hasOwnProperty(documentIdentifier.depotId)) {
        jsonRepresentation[documentIdentifier.objectType][documentIdentifier.depotId] = []
      }

      jsonRepresentation[documentIdentifier.objectType][documentIdentifier.depotId]
        .push(documentIdentifier.documentId)

    })

    return jsonRepresentation

  }

  /**
   * Create list of depot documents identifiers from JSON structure
   * @param documentsIdentifiersJSON {{[p: Number]: (Number|String)[]}}
   * @returns {DepotDocumentIdentifiersList}
   */
  static fromJSON(documentsIdentifiersJSON) {

    const list = new DepotDocumentIdentifiersList()

    if (Array.isArray(documentsIdentifiersJSON)) return list;

    _.forIn(documentsIdentifiersJSON, (depotsDocuments, objectType) => {
      _.forIn(depotsDocuments, (documentsIds, depotId) => {
        (documentsIds || []).forEach((documentId) => {
          list.push(new DepotDocumentIdentifier(depotId, documentId, objectType))
        })
      })
    })

    return list

  }

}

/**
 * Check, if only one depot selected.
 * @param documentsData
 */
export function singleDepotSelected(documentsData) {
  if (Object.keys(documentsData).filter((objectType) => objectType != 'general').length > MIN_DOCUMENTS_BANKS_NUMBER) {
    return false
  }

  for (let objectType in documentsData) {
    if (Object.keys(documentsData[objectType]).length > MIN_DOCUMENTS_BANKS_NUMBER) {
      return false
    }
  }

  return true
}