import {makeStyles} from "@material-ui/core";

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  }
});

export default makeStyles(styles);