import React from "react";
import {Grid, withStyles} from "@material-ui/core";
import {parseResponse, PortfolioHandlerResource} from "../../../../utils/api";
import {RiskAnalysisTab as VirtualRiskAnalysisTab} from "../../../VirtualPortfolioPage/Tabs/RiskAnalysis";
import styles from "../../../VirtualPortfolioPage/Tabs/RiskAnalysis/styles";
import ChartRiskReturn from "../../../VirtualPortfolioPage/Tabs/RiskAnalysis/items/RiskReturnChart";
import StressTestChart from "../../../VirtualPortfolioPage/Tabs/RiskAnalysis/items/StressTestChart";
import CorrelationMatrix from "../../../VirtualPortfolioPage/Tabs/RiskAnalysis/items/CorrelationMatrix";
import _ from 'lodash';

class BaseRiskAnalysisTab extends VirtualRiskAnalysisTab {

  constructor(props) {
    super(props);

    this.state = {
        ...this.state,
        dataProvider: this.props.dataProvider || PortfolioHandlerResource,
    }

    this.requestPortfolioId = this.props.investmentStrategyModelPortfolioConnectId || this.props.portfolioId
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(_.get(this.props,'lastBenchmarkUpdateTime', undefined) !== _.get(prevProps, 'lastBenchmarkUpdateTime', undefined) || JSON.stringify(prevProps.selectedAssets) !== JSON.stringify(this.props.selectedAssets)){
      this.setState({
        stressTestData: undefined,
        stressTestLoading: true,
        riskReturnData: undefined,
        riskReturnLoading: true,
        portfolioRiskReturnData: undefined,
        portfolioRiskReturnLoading: true,
        correlationData: undefined,
        correlationLoading: true
      })
      this.fetchStressTestData();
      this.fetchRiskReturnData();
    }
  }

  // investmentStrategyId,
  // investmentStrategyModelPortfolioConnectId,

  fetchStressTestData() {
      let benchmarks_data = this.props.chartsSettings.performance.currently_selected_benchmarks.map(b => {
        b.percentage = parseInt(b.percentage)
        return b
      })

    this.setState({
      stressTestError: undefined,
      stressTestData: undefined,
      stressTestLoading: true
    })

      this.props.dataProvider.getStressTestData(this.props.customerId,  this.props.portfolioId, undefined, undefined, benchmarks_data, !this.props.isAllAssetsSelected ? this.props.selectedAssets : [], this.props.investmentStrategyId)
        .then((response) => {
          parseResponse(response, 'stress_test',
            (data) => {
              this.setState({
                stressTestError: undefined,
                stressTestData: data,
                stressTestLoading: false
              })
              this.setState({
                benchmarkDetails: response['benchmark'],
              })
            },
            (error) => {
              this.setState({
                stressTestData: undefined,
                stressTestLoading: false,
                stressTestError: error
              })
            })
        })
        .catch((error) => {
          this.setState({
            stressTestData: undefined,
            stressTestLoading: false,
            stressTestError: error,
          })
        })
    }

  fetchRiskReturnData() {
    this.props.dataProvider.getRiskReturn(this.props.customerId, this.requestPortfolioId, undefined, undefined, !this.props.isAllAssetsSelected ? this.props.selectedAssets : [])
      .then((response) => {
        parseResponse(response, 'risk_return',
          (data) => {
            this.setState({
              riskReturnError: undefined,
              riskReturnData: data,
              riskReturnLoading: false
            })
          },
          (error) => {
            this.setState({
              riskReturnData: undefined,
              riskReturnLoading: false,
              riskReturnError: error
            })
          })

        parseResponse(response, 'correlation_matrix',
          (data) => {
            this.setState({
              correlationError: undefined,
              correlationData: data,
              correlationLoading: false
            })
          },
          (error) => {
            this.setState({
              correlationData: undefined,
              correlationLoading: false,
              correlationError: error
            })
          })
      })
      .catch((error) => {
        this.setState({
          riskReturnData: undefined,
          riskReturnLoading: false,
          riskReturnError: error.toString(),
          correlationData: undefined,
          correlationLoading: false,
          correlationError: error.toString()
        })
      })

      this.props.dataProvider.getPortfolioRiskReturn(this.props.customerId, this.props.portfolioId, this.props.investmentStrategyId)
      .then((response) => {
        parseResponse(response, 'pf_risk_return',
          (data) => {
            this.setState({
              portfolioRiskReturnData: data,
              portfolioRiskReturnLoading: false,
              portfolioRiskReturnError: undefined,
            })
          },
          (error) => {
            this.setState({
              portfolioRiskReturnData: undefined,
              portfolioRiskReturnLoading: false,
              portfolioRiskReturnError: error,
            })
          }
        )
      })
      .catch((error) => {
        this.setState({
          portfolioRiskReturnData: undefined,
          portfolioRiskReturnLoading: false,
          portfolioRiskReturnError: error.toString(),
        })
      })
  }

  render() {
    return <Grid container spacing={2}>
      {this.props.handleShowChartSection(this.props.customSettings, 'riskReturnChart') &&
        <Grid item xs={12}>
            <ChartRiskReturn
              portfolioData={this.props.portfolioData}
              portfolioLoading={this.props.portfolioLoading}
              portfolioError={this.props.portfolioError}
              portfolioRiskReturnData={this.state.portfolioRiskReturnData}
              portfolioRiskReturnLoading={this.state.portfolioRiskReturnLoading}
              portfolioRiskReturnError={this.state.portfolioRiskReturnError}
              data={this.state.riskReturnData}
              loading={this.state.riskReturnLoading}
              error={this.state.riskReturnError}
              expanded={this.props.expandedItems.riskReturnChart}
              onExpandedClick={ (newState) => this.props.onExpandedItemsChange('riskReturnChart', newState)}
              onRiskReturnSelectedRangeChanged={(range) => this.props.handleChartSettingsChange('risk_return_chart', 'selected_range', range)}
            />
        </Grid>
      }
      {this.props.handleShowChartSection(this.props.customSettings, 'stressTestChart') &&
        <Grid item xs={12}>
            <StressTestChart
              data={this.state.stressTestData}
              loading={this.state.stressTestLoading}
              error={this.state.stressTestError}
              benchmarkDetails={this.state.benchmarkDetails}
              portfolioName={this.props.selectedAssetsName || this.props.portfolioName}
              showBenchmark={this.props.chartsSettings.performance.withBenchmark}
              expanded={this.props.expandedItems.stressTestChart}
              onExpandedClick={ (newState) => this.props.onExpandedItemsChange('stressTestChart', newState)}
            />
        </Grid>
      }
      {this.props.handleShowChartSection(this.props.customSettings, 'correlationMatrix') &&
        <Grid item xs={12}>
          <CorrelationMatrix
            portfolioData={this.props.portfolioData}
            portfolioLoading={this.props.portfolioLoading}
            portfolioError={this.props.portfolioError}
            data={this.state.correlationData}
            loading={this.state.correlationLoading}
            error={this.state.correlationError}
            selectedAssets={this.props.selectedAssets}
            title={'Korrelationsmatrix auf Basis der Performance der letzen drei Jahre '}
            expanded={this.props.expandedItems.correlationMatrix}
            onExpandedClick={ (newState) => this.props.onExpandedItemsChange('correlationMatrix', newState)}
          />
        </Grid>
      }
      </Grid>
    }

}

class RiskAnalysisTab extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      basicPortfolioData: null,
      basicPortfolioLoading: true,
      basicPortfolioError: null
    }

    this.requestPortfolioId = this.props.investmentStrategyModelPortfolioConnectId || this.props.portfolioId

    this.fetchBasicPortfolioData = this.fetchBasicPortfolioData.bind(this);
  }

  componentDidMount() {
    this.fetchBasicPortfolioData()
  }

  fetchBasicPortfolioData(){
    this.props.dataProvider.getBasicPortfolioData(this.props.customerId, this.requestPortfolioId)
      .then((response) => {
        response.portfolio_data.data[0].components = _.orderBy(response.portfolio_data.data[0].components, ['weight'], 'desc')
        if(this.props.portfolioName){
          // portfolio name is changed to display name instead of id
          response.portfolio_data.data[0].name = this.props.portfolioName
        }
        this.setState({
          basicPortfolioData: response,
          basicPortfolioLoading: false
        })
      })
      .catch((error) => {
        this.setState({
          basicPortfolioError: error,
          basicPortfolioLoading: false
        })
      })
  }

  render() {
    return <div>
      <BaseRiskAnalysisTab
        {...this.props}
        portfolioData={this.state.basicPortfolioData}
        portfolioLoading={this.state.basicPortfolioLoading}
        portfolioError={this.state.basicPortfolioError}
      />
    </div>
  }
}

export default withStyles(styles)(RiskAnalysisTab);
