import React, {useState} from 'react';
import clsx from 'clsx';

/* Material-UI Components*/
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Fade from '@material-ui/core/Fade';
import {FormHelperText} from "@material-ui/core";

/* BCA modules */
import useStyles from './styles';
import SelectCaret from '../../../Icons/SelectCaret';


export default (props) => {
  const {
    value,
    onChange,
    items,
    label,
    error,
    disabled,
    width,
    height,
    zIndex,
    position
  } = props;

  const classes = useStyles(props);

  const [expanded, setExpanded] = useState(false);

  const node = React.useRef();

  React.useEffect(() => {
    window.addEventListener('mousedown', handleClick, false);

    return () => {
      window.removeEventListener('mousedown', handleClick, false);
    }
  }, []);

  const handleClick = (event) => {
    if (!node.current || !node.current.contains((event.target))) {
      setExpanded(false);
    }
  };

  const renderItems = () => {

    return items.map((item) => (
      <ListItem
        key={item.id}
        className={[classes.portfolioListItem, value && value.id === item.id ? classes.portfolioListItemSelected : ''].join(' ')}
        onClick={() => {
          onChange(item);
          setExpanded(false);
        }}
      >
        <span>
          {item.label}
        </span>
      </ListItem>
    ))
  };

  return (
    <React.Fragment>
      <div className={classes.container} ref={node} style={{zIndex: zIndex, position: position}}>
        <p className={classes.switchLabel} style={{color: error ? 'red' : '#B1B1B1'}}>{label}</p>
        <div className={classes.expansionPannelContainer}>
          <ExpansionPanel
            id="benchmarks-list"
            TransitionComponent={Fade}
            TransitionProps={{timeout: 0}}
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            classes={{
              root: classes.expansionPanelRoot,
              expanded: classes.expansionPanelExpanded
            }}
            disabled={disabled}
          >
            <ExpansionPanelSummary
              expandIcon={
                <SelectCaret
                  className={clsx(
                    classes.expansionPanelSummaryIcon,
                    error && classes.expansionPanelSummaryIconError,
                    disabled && classes.expansionPanelSummaryIconDisabled
                  )}
                />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              IconButtonProps={{
                disableRipple: true
              }}
              classes={{
                root: !error ? classes.expansionPanelSummaryRoot : classes.expansionPanelSummaryRootError,
                disabled: classes.expansionPanelSummaryExpandedDisabled,
                expanded: classes.expansionPanelSummaryExpanded,
                content: classes.expansionPanelSummaryContent
              }}
            >
              {value ? value.label : ''}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{
              root: classes.expansionPanelDetailsRoot,
            }} style={{
              display: expanded ? 'block' : 'none',
              position: expanded ? 'absolute' : 'relative'
            }}>
              <List className={classes.portfolioList}>
                {renderItems()}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        {error && (
          <FormHelperText className={classes.formHelperText} error={true}>
            {error}
          </FormHelperText>
        )}
      </div>
    </React.Fragment>
  )
}