import React from 'react';
import {ALL_DOCUMENT_TYPE, DEFAULT_DOCUMENTS_PER_PAGE} from "../../constants";
import {filterByDocumentType, filterBySearchString, getAllDocumentTypes, getPaginatedList} from "../../utils";
import {fetchDocuments} from "../../services";

const DocumentsDataProvider = WrappedComponent => {
  return function DataProvider(props){
    const [fetchedData, setFetchedData] = React.useState([]);
    const [paginatedData, setPaginatedData] = React.useState([]);
    const [total, setTotal] = React.useState(0);

    const [documentType, setDocumentType] = React.useState(ALL_DOCUMENT_TYPE);
    const [documentTypes, setDocumentTypes] = React.useState([ALL_DOCUMENT_TYPE]);

    const [searchString, setSearchString] = React.useState('');

    const [perPage] = React.useState(DEFAULT_DOCUMENTS_PER_PAGE);
    const [page, setPage] = React.useState(0);
    const [pages, setPages] = React.useState(0);

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
      fetchData();
    }, []);

    React.useEffect(() => {
      let filteredData = filterByDocumentType(fetchedData, documentType.value);
      filteredData = filterBySearchString(filteredData, searchString)  // filter by search result
      const paginatedData = getPaginatedList(filteredData, perPage);
      setPage(0);
      setPages(paginatedData.length);
      setTotal(filteredData.length);
      setPaginatedData(paginatedData);
    }, [fetchedData, documentType, searchString]);


    const onPageChange = (page) => {
      if (page => 0 && page <= pages - 1) {
        setPage(page);
      }
    };

    const onDocumentTypeChange = (type) => {
      if (documentType.value !== type.value) {
        setDocumentType(type);
      }
    };

    const onSearchClick = (newSearchString) => {
      if(newSearchString !== searchString){ setSearchString(newSearchString) }
    }

    const fetchData = async () => {
      setLoading(true);
      const response = await fetchDocuments(props.auth.user.customer_id);
      const documentTypes = getAllDocumentTypes(response);
      setDocumentTypes(documentTypes);
      setFetchedData(response);
      setLoading(false);
    };

    return (
      <WrappedComponent
        documents={paginatedData[page] || []}
        total={total}
        page={page}
        pages={pages}
        perPage={perPage}
        documentType={documentType}
        documentTypes={documentTypes}
        loading={loading}
        onDocumentTypeChange={onDocumentTypeChange}
        onSearchClick={onSearchClick}
        onPageChange={onPageChange}
        onRefresh={fetchData}
      />
    )
  }
};

export default DocumentsDataProvider;