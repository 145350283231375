import React from 'react';

import {Grid, Typography} from "@material-ui/core";

import useStyles from "./styles";
import PrimaryButton from "../Buttons/PrimaryButton";
import _ from "lodash";

const FilteringPanel = (props) => {

  const classes = useStyles();

  const {
    message,
    filters,
    withButtons,
    btnGridSize,
    applyFiltersButtonDisabled
  } = props;

  return (
    <Grid container className={classes.filteringContainer} id="reporting-customer-filter-tour-element">
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.filterHeader}>
          FILTER & SORTIERUNG
          {message && <span className={classes.filterHeaderMessage}>{message}</span>}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.filteringWrapper}>
        <Grid container spacing={2}>
          {filters.map((filter) => (
            <Grid item {...(filter.GridProps || {})}>
              {!_.isEmpty(filter.nestedFilters) ? (
                // renders nested filters
                <>{filter.nestedFilters.map(nestedFilter => <div className={nestedFilter.wrapperClass}> <nestedFilter.component {...nestedFilter} /> </div>)}</>
              ) : (
                <filter.component {...filter}/>
              )}

            </Grid>
          ))}
          {withButtons && (
            <Grid item xs={12} md={btnGridSize && _.inRange(btnGridSize, 1, 13) ? btnGridSize : 12} className={classes.buttonsContainer}>
              <PrimaryButton
                text={"Filter übernehmen"}
                onButtonClick={props.onApplyFilters}
                disabled={applyFiltersButtonDisabled}
              />
              <PrimaryButton
                variant={"outlined"}
                text={"Zurücksetzen"}
                onButtonClick={props.onResetFilters}
                customClasses={{
                  root: classes.resetButtonRoot
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
};

export default FilteringPanel;
