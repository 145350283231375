import React from 'react';
import connect from "react-redux/lib/connect/connect";
import _ from 'lodash';
import {withRouter} from "react-router-dom";
import {Container, withWidth} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";

import queryString from "query-string";
import PageTabsNavigation from '../../components/PageTabsNavigation';

import useStyles from './styles'
import { SERIES_REPORTING_TABS, SERIES_REPORTING_TAB_IDS } from './constants';
import { ROUTES } from '../../routes';


const groupTabIdx = SERIES_REPORTING_TABS.findIndex((tab) => tab.id === SERIES_REPORTING_TAB_IDS.GROUP);

export default withWidth()(connect()(withRouter((props) => {

  const [selectedTabIdx, setSelectedTabIdx] = React.useState(
    _.includes(props.location.pathname, ROUTES.BROKER.SERIES_REPORTING.children.GROUP.newDesignPath)
    ? groupTabIdx : 0);
  const classes = useStyles();
  
  React.useEffect(() => {
    // in case return from group reporting => set group list
    let is_from_group = JSON.parse(_.get(queryString.parse(props.location.search), 'is_from_group', false));
    if (is_from_group){
      setSelectedTabIdx(groupTabIdx)
    }
  }, [_.get(queryString.parse(props.location.search), 'is_from_group')]);

  const renderTabContent = () => {
    const Component = _.get(SERIES_REPORTING_TABS[selectedTabIdx], 'component');

    return !Component ? null : <Component newDesign={props.newDesign} parentComponent={props.parentComponent}/>
  };

  return (
    <React.Fragment>
      <Container className={'app-page-container'}>

        <Typography variant={"h1"} className={classes.header}>
          Serienreporting
        </Typography>

        <PageTabsNavigation
          containerId='serirs-reporting-tabs-tour-element'
          tabs={SERIES_REPORTING_TABS.map(tab => tab.name)}
          active={selectedTabIdx}
          onActiveChanged={setSelectedTabIdx}
        />

        {renderTabContent()}
      </Container>
    </React.Fragment>
  )
})));