import React from "react";
import moment from "moment";
import { Grid, withStyles } from "@material-ui/core";

import PerformanceChart, {MISSING_FIELD} from "../../components/PerformanceChart";
import TableWidget from "../../components/TableWidget";
import TextWidget from "../../components/TextWidget";
import DocumentsTable from "./items/DocumentsTable";
import styles from "./styles";
import { formatYesNo, formatPercentage } from "../../utils";
import _ from 'lodash';


export const FEES_TOOLTIPS = {
  product_fees_ongoing_cost_actual: 'Zu den in den PRIIP BIB angegebenen Kosten werden auch die Transaktionskosten und mögliche performanceabhängige Gebühren des Fonds dazugerechnet.',
  product_fees_performance_fee_charged: 'Die Definition der Performance Fee (Erfolgsabhängige Gebühren) ist im PRIIP BIB im Bereich der Kostenangaben zu entnehmen.',
};

export const NO_BENCHMARK_MSG = 'Instrument hat keine Benchmark'
export const NOT_ETG_MSG = 'Instrument ist kein ETF'

export const getInceptionDate = data => _.get(data, 'inception_date') ? moment(data.inception_date).format('DD.MM.YYYY') : MISSING_FIELD

export const transformFundsBenchmark = (value) => {
  return value
      .map(item => item.name + ' ('+ formatPercentage(item.weight, 100) +')')
      .join('<br/>');
}

class BasicsAndPerformanceTab extends React.Component {

  constructor(props) {
    super(props);

    const { data } = this.props;

    this.baseLines = [
      { title: 'Auflegungsdatum', value: getInceptionDate(data)},
      { title: 'Ausschüttend', value: formatYesNo(data.income_distribution) },
      { title: 'Produktgattung', value: data.category },
      { title: 'Morningstar Kategorie', value: data.sub_class },
      { title: 'Morningstar Benchmark', value: data.morningstar_index_name, defaultIfNone: NO_BENCHMARK_MSG },
      {
        title: 'Fondsbenchmark',
        value: data.primary_benchmark,
        transform: transformFundsBenchmark,
      },
      { title: 'Art der Replikation', value: data.indexing_approach, defaultIfNone: NOT_ETG_MSG },
      { title: 'SRI', value: data.sri },
      { title: 'Durchschnittsrating', value: data.average_credit_quality },
      { title: 'Fondsgesellschaft Name', value: data.investment_company },
      { title: 'Fondsgesellschaft Homepage', value: data.homepage },
      { title: 'Fondstyp', value: data.fund_type},
      { title: 'Rechtsform', value: data.fund_legal_status },
    ];

    this.feesLines = [
      { title: 'Verwaltungsvergütung', value: formatPercentage(data.product_fees_prospectus_actual_management_fee) },
      { title: 'Rücknahmeentgelt', value: formatPercentage(data.product_fees_purchase_redemption_fee) },
      { title: 'Max. Ausgabeaufschlag', value: formatPercentage(data.product_fees_purchase_front_load) },
      { title: 'Gesamte Laufende Kosten', value: formatPercentage(data.product_fees_ongoing_cost_adjusted),
        tooltip: {text: FEES_TOOLTIPS.product_fees_ongoing_cost_actual}},
      { title: 'Performance Fee', value: data.product_fees_performance_fee_charged,
        tooltip: {text: FEES_TOOLTIPS.product_fees_performance_fee_charged}},
    ];

    this.invPrinciple = data.description;
  }

  render() {
    return <Grid container spacing={2}>
      <Grid item xs={12}>
        <PerformanceChart
          assetInfo={this.props.data}
          detailsTable
          startDateCallback={this.props.callback}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TableWidget
              title={'Stammdaten'}
              data={this.baseLines}
              noLines
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TableWidget
                  title={'Gebühren'}
                  data={this.feesLines}
                />
              </Grid>
              <Grid item xs={12}>
                <DocumentsTable
                  title={'Rechtliche Dokumente'}
                  assetInfo={this.props.data}
                  settings={this.props.settings}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextWidget
          title={'Anlagegrundsatz'}
          data={this.invPrinciple}
        />
      </Grid>
    </Grid>
  }
}

export default withStyles(styles)(BasicsAndPerformanceTab);
