import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { 
  withStyles,
  Grid,
  Container,
  Fade
} from '@material-ui/core';

import Link from '../../../components/Link';
import PrimaryButton from '../../../components/Buttons/PrimaryButton'
import { StickyNavigation, SuccessMessageModal } from '../../Modelportfolios/components'
import Filters from '../../Modelportfolios/Filters'

import { styles } from './styles';
import { DetailsIcon } from '../../../images';
import { getRouteFullPathByComponent } from '../../InvestmentPlatform/utils'
import {
  InstrumentsFilter,
  InstrumentsList,
} from './components'
import { ModelPortfolioResource, parseResponse, FavoriteListResouce } from '../../../utils/api'
import { FavoriteListModal } from '../components'
import { executeIfPathExist, getInvestmentDynamicPath } from '../../InvestmentPlatform/utils'
import { FAVORITE_LIST_CREATE_ERROR_MESSAGE } from '../constants'
import { displayErrorSnackBar } from '../../../components/SnackbarProvider/actions'
import axios from "axios";
import queryString from "query-string";
import {getErrorMessage} from "../../../utils/utils";



const CONTENT_TAB = {
  MAIN: 1,
  FILTERS: 2
}

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS()
});
let cancelToken;
class Create extends Component {

  state = {
    assetsSearch: {
      data: undefined,
      loading: false,
      errors: undefined,
      date: Date.now()
    },
    selectedAssets: [],
    selectedFavoriteList: {
      data: undefined,
      loading: false,
      errors: undefined,
      date: Date.now()
    },
    favoriteListModalVisibility: false,
    favoriteListSaveStatus: {
      data: undefined,
      loading: false,
      errors: undefined
    },
    favoriteListSuccessModalState: {
      message: undefined,
      open: false
    },
    activeTab: CONTENT_TAB.MAIN,
    investmentDynamicPath: getInvestmentDynamicPath(),
    isin: null
  }

  componentDidMount() {
    let id = this.props.computedMatch.params.id

    if (id) {
      this.fetchFavoriteList(id)
    }

    // BCA-4394
    let isin = queryString.parse(this.props.location.search).isin
    if(isin) {
      this.setState({isin: isin})
      this.getAssetFromFacthsheet(isin)
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedFavoriteList.date != prevState.selectedFavoriteList.date && this.state.selectedFavoriteList.data) {
      this.setState({
        selectedAssets: this.state.selectedFavoriteList.data.assets || []
      })
    }
  }

  getAssetFromFacthsheet = async(isin) => {
    this.setState({
        assetsSearch: {
          loading: true,
          directIsinAdding: true
        },
      });
      this.searchAssets({name: isin}, (data) => {
        this.handleAddAssetClick(data[0]);
        this.setState({
          assetsSearch: {
            loading: false,
            directIsinAdding: false
          },
        });
      }, (errors) => {
        console.log(errors);
        this.setState({
          assetsSearch: {
            loading: false,
            directIsinAdding: false
          },
        });
        this.props.dispatch(displayErrorSnackBar('Fehler beim Hinzufügen des Vermögenswert.'))
      })
  }

  renderIfPathExist = (path, callback) => {
    if (this.props.investmentPlatform.routes) {
      let fullPath = getRouteFullPathByComponent(this.props.investmentPlatform.routes, path)

      if (fullPath) {
        return callback(fullPath)
      }
    }
  }

  renderBackLink = () => {

    if(this.state.isin) {
       return <Link
          title="Zurück zum Factsheet"
          icon={<i class="fa fa-chevron-left" aria-hidden="true"></i>}
          underline
          onClick={() => this.props.history.push(`/factsheets/${this.state.isin}`)}
        />
    }

    return this.renderIfPathExist('FAVORITE_LIST', (path) => (
      <Link 
        title="Zurück zu Favoritenlisten"
        icon={<i class="fa fa-chevron-left" aria-hidden="true"></i>}
        underline
        onClick={() => this.props.history.push(`/${this.state.investmentDynamicPath}${path}`)}
      />
    ))

  }

  handleSearchActivate = (value) => {
    if(value) {
      let filters = {
        "name": value
      }

      this.fetchAssets(filters)
    }  else {
      //Check if there are any previous pending requests
      if (typeof cancelToken != 'undefined') {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      this.setState({
        assetsSearch: {
          loading: false,
          errors: undefined,
          data: [],
          date: Date.now()
        }
      });
    }
  }

  handleAddAssetClick = (asset) => {
    let selectedAssetsCopy = [...this.state.selectedAssets]

    if (!!_.find(selectedAssetsCopy, a => a.isin == asset.isin)) {
      selectedAssetsCopy = _.filter(selectedAssetsCopy, a => a.isin != asset.isin)
    } else {
      selectedAssetsCopy = [...selectedAssetsCopy, asset]
    }

    this.setState({
      selectedAssets: selectedAssetsCopy
    })
  }

  handleOpenInstrumentsWeightsModalClick = () => {
    this.setState({
      instrumentsWeightsModalVisible: true
    })
  }

  searchAssets = async (filters, resolve, errors) => {
    //Check if there are any previous pending requests
    if (typeof cancelToken != 'undefined') {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    let response = {}
    try{
      response = await ModelPortfolioResource.searchAssets(filters, cancelToken.token);
    } catch (errors) {
      this.props.dispatch(displayErrorSnackBar(getErrorMessage(errors)));
    }

    parseResponse(response, 'assets', resolve, errors)
  }


  fetchAssets = async (filters) => {
    this.setState({
      assetsSearch: {
        data: undefined,
        loading: true,
        errors: undefined,
        date: Date.now()
      }
    });

    try {

      this.searchAssets(filters, (data) => {
        this.setState({
          assetsSearch: {
            data,
            loading: false,
            errors: undefined,
            date: Date.now()
          }
        });
      }, (errors) => {
        this.setState({
          assetsSearch: {
            data: undefined,
            loading: false,
            errors,
            date: Date.now()
          }
        });
      });

    } catch (errors) {
      if (errors !== 'Operation canceled due to new request.') {
        this.setState({
          assetsSearch: {
            ...this.state.assetsSearch,
            loading: false,
            errors
          }
        })
      }
    }
  }

  fetchFavoriteList = async (id) => {
    this.setState({
      selectedFavoriteList: {
        data: undefined,
        loading: true,
        errors: undefined,
        date: Date.now()
      }
    })

    try {
      let response = await FavoriteListResouce.getFavoriteListDetails(id);

      this.setState({
        selectedFavoriteList: {
          data: response,
          loading: false,
          errors: undefined,
          date: Date.now()
        }
      });

    } catch (errors) {

      this.setState({
        selectedFavoriteList: {
          data: undefined,
          loading: false,
          errors,
          date: Date.now()
        }
      })

    }
  }

  handleFavoriteListModalOpen = () => {
    this.setState({
      favoriteListModalVisibility: true
    })
  }

  handleFavoriteListModalClose = () => {
    this.setState({
      favoriteListModalVisibility: false
    })
  }

  handleSaveFavoriteList = async (data) => {
    this.setState({
      favoriteListSaveStatus: {
        data: undefined,
        loading: true,
        errors: undefined
      }
    })

    try {

      let requestBody = {
        ...data,
        assets: this.state.selectedAssets.map(asset => ({instrument_id: asset.isin}))
      }

      let response = null;
      if (!this.state.selectedFavoriteList.data) {
        response = await FavoriteListResouce.createFavoriteList(requestBody)
      } else {
        response = await FavoriteListResouce.updateFavoriteList(this.state.selectedFavoriteList.data.id, requestBody)
      }

      this.setState({
        favoriteListSaveStatus: {
          data: response,
          loading: false,
          errors: undefined
        },
        favoriteListSuccessModalState: {
          message: <p>Favoritenliste wurde erfolgreich gespeichert.</p>,
          open: true
        }
      })

    } catch (errors) {

      this.setState({
        favoriteListSaveStatus: {
          data: undefined,
          laoding: false,
          errors: errors.data || errors
        }
      })
      
      
      this.props.dispatch(displayErrorSnackBar(FAVORITE_LIST_CREATE_ERROR_MESSAGE))
       

    }
  }

  handleSuccessModalClose = () => {
    if (!this.state.selectedFavoriteList.data) {
      this.renderIfPathExist('FAVORITE_LIST', (path) => {
        this.props.history.push(`/${this.state.investmentDynamicPath}${path}`)
      })
    } else {
      executeIfPathExist(this.props.investmentPlatform.routes, 'FAVORITE_LIST_OVERVIEW', path => {
        let pathUpdated = path.replace(':id', this.state.selectedFavoriteList.data.id)
  
        this.props.history.push(`/${this.state.investmentDynamicPath}${pathUpdated}`)
      })
    }
  }

  handleExpertFiltersButtonClick = () => {
    this.setState({
      activeTab: CONTENT_TAB.FILTERS
    })
  }

  handleAddInstrumentsFilters = (instruments) => {
    let selectedAssetsCopy = [...this.state.selectedAssets]

    if (instruments && instruments.length) {
      instruments.forEach(instrument => {
        if (!selectedAssetsCopy.find(item => item.isin == instrument.isin)) {
          selectedAssetsCopy.push(instrument)
        }
      })
    }

    this.setState({
      selectedAssets: selectedAssetsCopy,
      activeTab: CONTENT_TAB.MAIN
    })
  }

  render() {

    const { classes } = this.props;

    return (
      <Container style={{height: '100%', position: 'relative'}}>
        {this.state.activeTab == CONTENT_TAB.MAIN ? (
          <>
            {this.renderBackLink()}
            <h1 className={classes.header}>Favoritenliste erstellen</h1>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InstrumentsFilter
                  instruments={this.state.assetsSearch.data}
                  instrumentsDisabled={this.state.selectedAssets}
                  loading={this.state.assetsSearch.loading}
                  errors={this.state.assetsSearch.errors}
                  onSearchActivate={this.handleSearchActivate}
                  onAddAssetClick={this.handleAddAssetClick}
                  onFiltersExtendedButtonClick={this.handleExpertFiltersButtonClick}
                  disableDropdown={this.state.assetsSearch.directIsinAdding}
                />
              </Grid>
              <Grid item xs={12}>
                <InstrumentsList
                  loading={this.state.assetsSearch.directIsinAdding}
                  instruments={this.state.selectedAssets}
                  onRemoveAssetClick={this.handleAddAssetClick}
                />
              </Grid>
            </Grid>
            <StickyNavigation>
              <div className={classes.stickyNavigation}>
                <div>
                  <PrimaryButton
                    customClasses={{root: classes.stickyButtonRoot, disabled: classes.stickyButtonDisabled}} 
                    text="Bestätigen & fortfahren" 
                    disabled={!this.state.selectedAssets.length}
                    onButtonClick={this.handleFavoriteListModalOpen}
                  />
                </div>
              </div>
            </StickyNavigation>
          </>
        ) : (
          <>
            <Link
              title="Schließen"
              icon={<i class="fa fa-chevron-left" aria-hidden="true"></i>}
              underline
              onClick={() => this.setState({activeTab: CONTENT_TAB.MAIN})}
            />
            <Fade in={this.state.activeTab == CONTENT_TAB.FILTERS} timeout={500}>
              <Filters onAddInstruments={this.handleAddInstrumentsFilters}/>
            </Fade>
          </>
        )}
        
        <FavoriteListModal
          open={this.state.favoriteListModalVisibility}
          onClose={this.handleFavoriteListModalClose}
          loading={this.state.favoriteListSaveStatus.loading}
          errors={this.state.favoriteListSaveStatus.errors}
          onFavoriteListCreateClick={this.handleSaveFavoriteList}
          selectedFavoriteList={this.state.selectedFavoriteList.data}
        />
        <SuccessMessageModal
          open={this.state.favoriteListSuccessModalState.open}
          message={this.state.favoriteListSuccessModalState.message}
          onClose={this.handleSuccessModalClose}
        />
      </Container>
    )
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Create));
