import React from 'react';

import clsx from "clsx";
import DashboardChartSection from '../../components/Widgets/components/DashboardChartSection';

// in css - last styles have higher prio -> so this styles should be after DashboardChartSection styles
import useStyles from './styles';

export function ChartSectionPortfolioName({portfolio}) {

  const classes = useStyles();

  return (
    <div className={classes.portfolioName}>
      <b>{portfolio.name}</b>
      {/*<span className="depot-number">{portfolio.number}</span>*/}
    </div>
  )
}

function ChartSectionBordered(props) {

  const classes = useStyles({
    expanded: props.expanded,
    isPortfolioSection: props.isPortfolioSection,
    borderLeftColor: props.borderLeftColor,
    skipContentPadding: props.skipContentPadding,
    fullHeight: props.fullHeight,
    subSection: props.subSection
  });

  return (
    <DashboardChartSection
      customClasses={{
        headerContainer: classes.widgetHeaderContainer,
        container: clsx(classes.widgetContainer, classes.widgetContainerBordered),
        contentContainer: clsx(classes.contentContainer, classes.contentContainerBordered),
        titleControl: classes.titleControl,
        header: classes.header
      }}
      {...props}
    />
  )
}

export function ChartSectionWrapper(props) {

  const classes = useStyles({expanded: props.expanded});

  return (
    <DashboardChartSection
      customClasses={{
        headerContainer: classes.widgetHeaderContainerWrapper,
        container: clsx(classes.widgetContainer, classes.widgetContainerWrapper),
        contentContainer: clsx(classes.contentContainer, classes.contentContainerWrapper),
        titleControl: classes.titleControl,
        header: clsx(classes.header, classes.headerWrapper)
      }}
      {...props}
    />
  )
}

ChartSectionBordered.propTypes = {}

ChartSectionBordered.propDefs = {}

ChartSectionBordered.defaultProps = {}

export default ChartSectionBordered;