import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  notFoundContainer: {
    backgroundColor: 'white',
    minHeight: '100vh',
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  notFoundStatusCode: {
    margin: 0,
    padding: 0,
    fontFamily: 'Roboto-Bold',
    fontSize: 200,
    color: '#00000033'
  },
  notFoundHelperText: {
    margin: 0,
    padding: 0,
    fontFamily: 'Roboto-Medium',
    fontSize: 20,
    color: '#4D4F5C'
  }
});

export default makeStyles(styles);