import React from 'react';

import DashboardCard from '../../DashboardCard';
import RiskChart from "../RiskChart";
import moment from "moment";

import useStyles from '../styles';
import { BENCHMARK_RISK_METRIX_ERROR, EMPTY_BENCHMARK_MESSAGE } from '../constants';
import {Skeleton} from "@material-ui/lab";

const LostPriceChart = (props) => {
  const {
    title='Maximaler Wertverlust der Gesamtdepots',
    volatility,
    benchmarkVolatility,
    error,
    expandable,
    expanded,
    onExpanded,
    withoutExpandButton,
    fullTitle,
    loading
  } = props;

  const classes = useStyles();

  const renderChart = () => {
    const isDataExist = volatility && volatility.risk_metrics;
    if (error || !isDataExist) {
      return null;
    }

    const mddValue = volatility.risk_metrics['mdd'] * 100;

    const benchmarkValue = benchmarkVolatility.risk_metrics && benchmarkVolatility.risk_metrics['maximum_drawdown'] * 100;
    const benchmarkLabel = benchmarkVolatility.name;

    const categories = ['Maximaler Wertverlust'];
    const max = 0;
    const min = Math.min(mddValue, benchmarkValue) < -10 ? -100 : Math.round(mddValue * 5);

    const mddStart = volatility.risk_metrics['mdd_date'];
    const mddEnd = volatility.risk_metrics['recovery_date'];
    const mddPickDate = volatility.risk_metrics['mdd_peak_date'];
    const recoveryDays = volatility.risk_metrics['recovery_days'];

    const getDateRange = () => {
      const start = moment(mddStart).format('DD.MM.YYYY');
      const end = moment(mddEnd).format('DD.MM.YYYY');
      return `${start} - ${end}`;
    };

    return (
      <div>
        {benchmarkVolatility.without_benchmark && (
          <p className={classes.benchmarkErrorMessage}>{EMPTY_BENCHMARK_MESSAGE}</p>
        )}
        {!benchmarkValue && !benchmarkVolatility.without_benchmark && (
          <p className={classes.benchmarkErrorMessage}>{BENCHMARK_RISK_METRIX_ERROR}</p>
        )}
        <RiskChart
          id='mdd'
          value={mddValue}
          benchmarkValue={benchmarkValue}
          benchmarkLabel={benchmarkLabel}
          categories={categories}
          max={max}
          min={min}
          reversed
        />
        <>
          {mddEnd ? (
            <div style={{fontSize: 12}}>
              <b>längste Verlustperiode:</b> {recoveryDays} Tage ({getDateRange()})
            </div>
          ) : (
            <div>
              <b>Wertaufholung:</b> Keine Wertaufholung seit {moment(mddPickDate).format('DD.MM.YYYY')}
            </div>
          )}
        </>
      </div>
    )
  };

  return (
    <DashboardCard
      id='mdd-chart'
      title={title}
      error={error}
      content={volatility && renderChart()}
      loading={loading}
      loadingContent={props.loadingContent}
      chart
      expandable={expandable}
      expanded={expanded}
      onExpanded={onExpanded}
      fullTitle={fullTitle}
      withoutExpandButton={withoutExpandButton}
      style={{height: 'auto'}}
      empty={!volatility}
      headerAlignCenter
    />
  )
};

export default LostPriceChart;