import React from "react";

import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import styles from "./styles";
import {useStyles} from "../../formElement/styles";
import SelectFormElement from "../../formElement/SelectFormElement";
import {
  radioSelectNoExperienceValue,
  radioSelectNoKnowledgeValue,
  defaultRadioValue, FIELD_REQUIRED_MSG, radioNoUID,
  radioNoValue,
  radioOptions, radioYesUID,
  radioYesValue
} from "../../../../../constants";
import {answerFromPrevStep, isRequiredQuestion, isValidValue} from "../../../../../utils";
import RadioFormElement from "../../formElement/RadioFormElement";

function isRadioSelectQuestionValid(question, step, afterChange, service) {
  const required = isRequiredQuestion(question);
  const radioValue = question.answer && question.answer.radio;
  const isValidRadio = isValidValue(radioValue);
  const isPeriodRequired = getPeriodOptions(question).length > 0;
  const isOrderRequired = getOrderOptions(question).length > 0;
  const isValidPeriod = isPeriodRequired ? isValidValue(question.answer && question.answer.period_select) : true;
  const isValidOrder = isOrderRequired ? isValidValue(question.answer && question.answer.order_select) : true;

  question.error = {};

  if(required) {
    question.error.radio = isValidRadio ? null : FIELD_REQUIRED_MSG;
    question.error.period_select = isValidPeriod ? null : FIELD_REQUIRED_MSG;
    question.error.order_select = isValidOrder ? null : FIELD_REQUIRED_MSG;

    return isValidRadio && isValidPeriod && isValidOrder;
  }

  return true;
}

function getRadioSelectQuestionAnswer(question) {
  const answer = !!question.answer ? [question.answer.period_select, question.answer.radio, question.answer.order_select] : [];
  return {
    question_uid: question.uid,
    answer: answer
  }
}

function setRadioSelectQuestionAnswer(question, answer) {
  if(answer && answer.length > 0){
    const periodOptions = getPeriodOptions(question).map(i => i.uid);
    const orderOptions = getOrderOptions(question).map(i => i.uid);

    question.answer = {
      period_select: answer.find(item => periodOptions.includes(item)),
      order_select: answer.find(item => orderOptions.includes(item)),
      radio: answer.includes(radioYesUID) ? radioYesValue : radioNoValue
    };
  }
}

function getPeriodOptions(question){
  const options = question.config.choices || [];
  return options.filter(item => ![radioYesUID, radioNoUID].includes(item.uid) && !item.uid.startsWith('order'));
}

function getOrderOptions(question){
  const options = question.config.choices || [];
  return options.filter(item => item.uid.startsWith('order'));
}

export const RadioSelectQuestionMethods = {
  required: isRequiredQuestion,
  valid: isRadioSelectQuestionValid,
  answer: getRadioSelectQuestionAnswer,
  initAnswer: setRadioSelectQuestionAnswer,
}

const RadioSelectQuestion = props => {
  const {
    classes,
    question,
    onAnswerChange,
    dataService,
  } = props;

  const inputClasses = useStyles();

  const label = question.question_text;
  if(!question.hasOwnProperty('error')){
    question.error = {};
  }
  const required = RadioSelectQuestionMethods.required(question);
  const allPeriodOptions = getPeriodOptions(question);
  const allOrderOptions = getOrderOptions(question);

  const radioLabel = allPeriodOptions.length > 0 && 'Kenntnisse' || null;
  const radioValue = question.answer && question.answer.radio || defaultRadioValue;
  const periodValue = question.answer && question.answer.period_select || '';

  const getSelectOptions = (allOptions) => {
    return allOptions.map(item => ({
      id: item.id,
      value: item.uid,
      label: item.text
    }))
  };

  const periodOptions = getSelectOptions(allPeriodOptions);
  const orderOptions = getSelectOptions(allOrderOptions);

  const defaultPeriod = periodOptions.length > 0 ? radioSelectNoKnowledgeValue : undefined;
  const defaultOrder = orderOptions.length > 0 ? radioSelectNoExperienceValue : undefined;

  React.useEffect(() => {
    if (!question.answer) {
      const answer = answerFromPrevStep(question, dataService) || {radio: defaultRadioValue, period_select: defaultPeriod, order_select: defaultOrder};
      onAnswerChange(question.uid, answer)
    } else if(!question.answer.order) { // for OLD answers without ORDER
      handleRadioValueChange(question.answer.radio);
    }
  }, []);

  const handleRadioValueChange = (newValue) => {
    if (newValue === radioNoValue) {
      onAnswerChange(question.uid, {radio: newValue, period_select: defaultPeriod, order_select: defaultOrder})
    } else {
      let periodSelect = question.answer.period_select || defaultPeriod;
      let orderSelect = question.answer.order_select || defaultOrder;
      onAnswerChange(question.uid, {radio: radioYesValue, period_select: periodSelect, order_select: orderSelect})
    }
  };

  const handlePeriodChange = (newValue) => {
    question.error.period_select = null; // clean period_select error
    const newAnswer = {...question.answer, period_select: newValue};
    // of No knowledge (period) there is no experience (order)
    if(newValue === defaultPeriod){
      newAnswer.order_select = defaultOrder;
    }
    onAnswerChange(question.uid, newAnswer)
  };

  const handleOrderChange = (newValue) => {
    question.error.order_select = null; // clean order_select error
    onAnswerChange(question.uid, {...question.answer, order_select: newValue})
  };

  const withPeriod = periodOptions.length > 0;
  const RadioComponent = withPeriod ? SelectFormElement : RadioFormElement;

  return (
    <Grid container>
      <Grid item xs={12}>
        <span className={classes.radioSelectQuestionLabel}>
          {label}
          &nbsp;
          {required && <span className={inputClasses.labelAsterisk}>*</span>}
        </span>
      </Grid>
      <Grid item container xs={12} className={classes.dropdownGroup}>
        <Grid item xs={withPeriod ? 3 : 12}>
          <RadioComponent
            value={radioValue}
            label={radioLabel}
            options={radioOptions}
            onChange={handleRadioValueChange}
            error={question.error.radio}
          />
        </Grid>
        {periodOptions.length > 0 && (
          <Grid item xs={4}>
            <SelectFormElement
              value={periodValue}
              label={'Transaktionen Pro Jahr'}
              options={periodOptions}
              onChange={handlePeriodChange}
              disabled={radioValue === radioNoValue}
              error={question.error.period_select}
            />
          </Grid>
        )}
        {orderOptions.length > 0 && (
          <Grid item xs={5} lg={4}>
            <SelectFormElement
              value={question.answer && question.answer.order_select || ''}
              label={'Ordergröße'}
              options={orderOptions}
              onChange={handleOrderChange}
              disabled={radioValue === radioNoValue || periodValue === defaultPeriod}
              error={question.error.order_select}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
};

export default withStyles(styles)(RadioSelectQuestion);