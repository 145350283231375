import React from 'react';
import connect from "react-redux/es/connect/connect";
import _ from 'lodash';
import moment from "moment-timezone";
import {Prompt, withRouter} from "react-router-dom";

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

import styles from './styles';

import EditorSettingsSwitch from "./components/EditorSettingsSwitch";
import RichTextEditor from "./components/RichTextEditor";
import LeaveTextEditDialog from "./components/LeaveTextEditDialog";
import {REPORTING_TEXTBOX_UID, SERIES_REPORT_SETTINGS_TYPE} from "../../constants";
import withNotification from "../../../../components/NotificationProvider";
import UploadAdditionalFiles from '../UploadAdditionalFiles';
import {axiosInstance, CustomerReportSettingResource} from "../../../../utils/api";
import { getFileNameFromResponse, toLocalDateTime } from '../../../../utils/utils';
import {getFromStorage, TOKEN_KEY} from "../../../../utils/storage";

const mapStateToProps = (state) => ({
  customer: state.get('reporting').toJS().customer,
  groups: state.get('reporting').toJS().groups,
  customers: state.get('reporting').toJS().customers,
  investmentPlatform: state.get('investmentPlatform').toJS()
});

const EditorStepContent = props => {
  const {
    reportSettings,
    customer,
    classes,
    history,
    handleSettingUpdate
  } = props;

  const [editorValue, setEditorValue] = React.useState(null);
  const [editorEnabled, setEditorEnabled] = React.useState(true);
  const [changed, setChanged] = React.useState(false);

  const [requestedPathOnLeavePage, setRequestedPathOnLeavePage] = React.useState(null);
  const [warningModalOpenOnLeavePage, setWarningModalOpenOnLeavePage] = React.useState(false);

  const token = getFromStorage(TOKEN_KEY);

  React.useEffect(() => {
    if (!_.isEmpty(customer)) {
      setEditorValue(customer.report_settings.cover_message_content);
      setEditorEnabled(customer.report_settings.cover_message_enabled);
    }

    if (!_.isEmpty(reportSettings)) {
      setEditorValue(reportSettings.cover_message_content);
      setEditorEnabled(reportSettings.cover_message_enabled);
    }
  }, [customer.customer_id, reportSettings.id]);

  const getLastSaveDate = () => {
    let timestamp;

    if (!_.isEmpty(customer)) {
      timestamp = customer.report_settings.cover_message_updated;
    }

    if (!_.isEmpty(reportSettings)) {
      timestamp = reportSettings.cover_message_updated;
    }

    if (timestamp) {
      const dateValue = toLocalDateTime(timestamp);
      const time = dateValue.format('HH:mm:ss');
      const date = moment().isSame(moment(timestamp), 'd') ? 'Heute' : dateValue.format('DD.MM.YYYY');
      return `Zuletzt gespeichert: ${date} - ${time}`;
    }
  };

  const getEntityCoverMessageContent = () => {
    if (!_.isEmpty(customer)) {
      return customer.report_settings.cover_message_content;
    }

    if (!_.isEmpty(reportSettings)) {
      return reportSettings.cover_message_content;
    }
  };

  const handleSettingsChange = async (value) => {
    setEditorEnabled(value);
    setChanged(true);
  };

  const handleEditorValueChange = (value) => {
    const initialValue = getEntityCoverMessageContent();
    if (value !== editorValue) {
      setEditorValue(value);
      if(value !== initialValue){
        setChanged(true);
      }
    }
  };


  React.useEffect(() => {
    handleSettingUpdate('cover_message_enabled')(editorEnabled)
    if (editorEnabled) {
      handleSettingUpdate('cover_message_content')(editorValue)
    }
  }, [editorEnabled, editorValue])

  const handleWarningModalState = (state) => {
    if (state) {
      setRequestedPathOnLeavePage(state.pathname);
      setWarningModalOpenOnLeavePage(true);
    }
    return false;
  };

  const handleStayOnPage = () => {
    setWarningModalOpenOnLeavePage(false);
  };

  const handleLeavePage = () => {
    setWarningModalOpenOnLeavePage(false);
    setChanged(false);

    if (requestedPathOnLeavePage) {
      setTimeout(() => {
        history.push(requestedPathOnLeavePage);
      }, 1000)
    }
  };

  if (_.isEmpty(reportSettings) && _.isEmpty(customer)) {
    return null;
  }

  function getTypeOfSetting() {
    let is_auto_report= reportSettings.is_auto_report
    let customer_id = props.match.params.customer_id
    let group_id = props.match.params.group_id

    if(!customer_id && !group_id){ return }

    return customer_id
      ? is_auto_report ? SERIES_REPORT_SETTINGS_TYPE.CUSTOMER_AUTO : SERIES_REPORT_SETTINGS_TYPE.CUSTOMER
      : is_auto_report ? SERIES_REPORT_SETTINGS_TYPE.GROUP_AUTO : SERIES_REPORT_SETTINGS_TYPE.GROUP

  }
  const settingsType = getTypeOfSetting()


  const getPreviouslyUploadedDocuments = async () => {
    /** Gets extra files of customer/group (ordinary and auto) from db */
    let updatedFiles = []
    for (let file of reportSettings.extra_report_files) {
      let config = {
        responseType: 'arraybuffer',
        headers:{'Authorization': `Bearer ${token}`},
        params: {
          type_of_settings: settingsType,
          settings_id: file.settings,
          extra_file_id: file.id,
        }
      };

      let response = await axiosInstance.get(`reports/broker/get-report-extra-documents/`, config)

      let formData = new FormData();
      let fileBlob = new Blob([response.data], {type: 'application/pdf'})
      formData.append('file', fileBlob, getFileNameFromResponse(response, true));

      updatedFiles.push({...file, formData})

    }

    return updatedFiles
  }


  const deleteExtraFile = async (fileToRemove) => {
    /** Removes extra file from DB and updates settings */

    let query_params = {
      type_of_settings: settingsType,
      settings_id: fileToRemove.settings,
      extra_file_id: fileToRemove.id,
    }

    await axiosInstance.delete(`reports/broker/delete-report-extra-document/`, {data: query_params, headers:{'Authorization': `Bearer ${token}`}})

    // remove deleted file from report settings
    let extraFilesWithoutDeleted = reportSettings.extra_report_files.filter(file => file.id !== fileToRemove.id)
    handleSettingUpdate('extra_report_files')(extraFilesWithoutDeleted)

  }

  const saveExtraFile = async (formData, added_file) => {
    /** Saves extra file to DB */

    formData.append('settings_id', reportSettings.id)
    formData.append('settings_type', settingsType)

    return await CustomerReportSettingResource.at(`report/upload-extra-report-files/`).post(formData);
  }

  return (
    <>
      <Grid container spacing={2}>
        {/* first column */}
        <Grid item xs={12} lg={3}>
          <Grid container spacing={2}>
            {/* Editor switch */}
            <Grid item xs={12} sm={6} md={6} lg={12}>
              <div style={{position: 'relative', top: 12}}>
                <EditorSettingsSwitch
                  enabled={editorEnabled}
                  handleValueChanged={handleSettingsChange}
                />
              </div>
            </Grid>

            {/* Extra documents */}
            {reportSettings &&
              <Grid item xs={12} sm={6} md={6} lg={12}>
                  <UploadAdditionalFiles
                    handleSettingUpdate={handleSettingUpdate}
                    getPreviouslyUploadedDocuments={getPreviouslyUploadedDocuments}
                    onExtraFileDelete={deleteExtraFile}
                    onExtraFileUpload={saveExtraFile}
                  />
              </Grid>
            }
          </Grid>
        </Grid>

        {/* second column */}
        <Grid item xs={12} lg={9}>
          <div className={classes.editorContainer}>
            <Card className={classes.textEditorWrapper}>
              <RichTextEditor
                content={editorValue}
                addDefaultEmptyLines
                handleChange={handleEditorValueChange}
                enabled={editorEnabled}
                uid={REPORTING_TEXTBOX_UID}
              />
            </Card>
            <Grid container className={classes.buttonsContainer} spacing={2}>
              <Grid item>
                <span>{getLastSaveDate()}</span>
              </Grid>
            </Grid>
          </div>
        </Grid>

      </Grid>

      <Prompt
        when={changed}
        message={handleWarningModalState}
      />

      {warningModalOpenOnLeavePage && (
        <LeaveTextEditDialog
          handleYesBtn={handleStayOnPage}
          handleNoBtn={handleLeavePage}
          handleCancel={handleStayOnPage}/>
      )}
    </>
  )
};

export default withStyles(styles)(connect(mapStateToProps)(withNotification(withRouter(EditorStepContent))));