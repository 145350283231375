import React from 'react';

/* Material-UI Components */
import WarningTooltip from '../WarningTooltip';

/* BCA modules */
import {VALUES_TOOLTIPS} from "../../utils/constants";
import InfoIcon from "@material-ui/icons/Info";
import withStyles from "@material-ui/core/styles/withStyles";

import styles from "./styles";
import clsx from "clsx";


const ExplanationTooltip = props =>  {
  const { tooltipPlacement, tooltipKey, classes, additionalText, customClasses={} } = props;

  let _text = [];
  VALUES_TOOLTIPS[tooltipKey] && _text.push(VALUES_TOOLTIPS[tooltipKey]);
  additionalText && _text.push(additionalText);

  if (!_text.length) {
    return false;
  }

  const asList = _text.length > 1;

  _text = _text.map((item) => {

    return React.createElement(asList ? 'li' : 'span', {}, (
      <>
        {item}
        <br />
      </>
    ));
  })

  _text = React.createElement(asList ? 'ul' : 'span', {style: {padding: 0, paddingLeft: asList ? 15 : 0}}, _text);

  return (
    <>&nbsp;
      <WarningTooltip
        placement={tooltipPlacement || 'right'}
        title={_text}
        width={500}
        iconClassName={clsx(classes.helpIcon, customClasses.helpIcon)}
      />
    </>
  )
}

export default withStyles(styles)(ExplanationTooltip);