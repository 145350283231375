import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  userContainer: {
    display: 'flex',
    position: 'relative',
    width: '100%'
  },

  userLogo: {
    width: 70,
    height: 70,
    marginRight: 10,
  },
  userLogoCustom: {
    fill: theme.palette.primary.main,
  },

  userName: {
    margin: 0,
    marginBottom: 0,
    // color: '#4D4F5C',
    fontSize: 21,
    fontFamily: 'Roboto-Bold',
    letterSpacing: -0.5,

    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },

  userId: {
    margin: 0,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#4D4F5C',

    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    }
  },
  link: {
    color: `${theme.palette.primary.main} !important`,
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    cursor: 'pointer',

    '& > a': {
      color: `${theme.palette.primary.main} !important`,
    }
  },

  dashboardLink: {
    marginTop: 10,
    marginBottom: 0,
  },

  group: {
    color: '#313D44',
    fontFamily: 'Roboto-Bold',
    marginTop: 10,
  }
}))