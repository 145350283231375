const styles = theme => ({
    root: {
      minHeight: 150,
      width: '100%',
      paddingRight: 5
    },
    chart: {
      width: '100%',
    },
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      '& > div': {
        width: '100%'
      },
    },
  })
  
  export default styles;