import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import CommonAnalysisCard from '../../../../components/AnalysisCard';
import Tooltip from '../../../../../../components/Tooltip';
import { getInstrName } from '../../../../../Modelportfolios/utils';
import styles from './styles';
import { DEFAULT_EMPTY_SECTION_MESSAGE } from '../../../../../CustomerDashboard/constants';

class CorrelationMatrix extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeRow: undefined,
      activeColumn: undefined,
    };

    this.handleHover = this.handleHover.bind(this);
    this.resetActive = this.resetActive.bind(this);
  }

  handleHover(event) {
    let dataset = event.target.dataset;

    this.setState({
      activeRow: Number(dataset.row),
      activeColumn: Number(dataset.column)
    })
  }

  resetActive() {
    this.setState({
      activeRow: undefined,
      activeColumn: undefined
    })
  }

  getAssetData() {
    try {
      let assets =  this.props.portfolioData['portfolio_data']['data'][0]['components'];
      if (!_.isEmpty(this.props.selectedAssets)) {
        assets = assets.filter((asset) => this.props.selectedAssets.includes(asset.id))
      }
      return assets
    } catch (e) {
      return []
    }
  }

  renderChart() {

    const { classes } = this.props;

    let rows = [];

    let assetsData = this.getAssetData()

    if (assetsData.length <= 1) {
      return <div className={classes.container}>
        Keine Daten verfügbar.
      </div>
    }

    let mapping = {}

    let heading = [
      <td className={clsx(classes.columnName)} key={0}>
        <span className={classes.nameHeader}>Instrumentenname</span>
        <span className={clsx(classes.weightColumn, classes.weightHeader)}>Anteile</span>
      </td>,
    ]

    let counter = 1;
    assetsData.forEach((item) => {
      heading.push(<td className={clsx(classes.tableCell, this.state.activeColumn === counter ? classes.highlighted : undefined)}>{counter}</td>)
      counter++;

      const assetInfo = item['financial_information']['asset_info_data'];

      const name = getInstrName(assetInfo);

      mapping[assetInfo['isin']] = {
        name: name ? name : assetInfo['isin'],
        weight: item['weight']
      };
    });

    let counterRows = 1;

    for (let [isin, data] of Object.entries(mapping)) {

      let cells = [
        <td className={clsx(classes.columnTitle, this.state.activeRow === counterRows ? classes.highlighted : undefined)} key={counterRows}>
          <div className={classes.titleWrapper}>
            <span className={classes.columnNumber}>{counterRows}</span>
            <Tooltip title={data['name']} placement={'right'} >
              <span className={classes.assetName}>{data['name']}</span>
            </Tooltip>
            <span className={classes.weightColumn}>{Number(data['weight'] * 100).toFixed(2)}%</span>
          </div>
        </td>,
      ]

      let counterColumns = 1;

      for (let [correlated_isin, data] of Object.entries(mapping)) {

        let key = isin + '_' + correlated_isin

        if (correlated_isin === isin) {
          cells.push(
            <td
              key={key}
              className={classes.tableCell}
              data-column={counterColumns}
              data-row={counterRows}
              onMouseOver={this.handleHover}
              onMouseLeave={this.resetActive}
            />
          )

        } else {
          if (this.props.data.hasOwnProperty(isin)) {
            let rowData = this.props.data[isin];

            if (rowData.hasOwnProperty(correlated_isin) && rowData[correlated_isin]) {
              const value = rowData[correlated_isin]

              cells.push(<td
                key={key}
                onMouseOver={this.handleHover}
                onMouseLeave={this.resetActive}
                data-column={counterColumns}
                data-row={counterRows}
                className={clsx(
                  classes.tableCell,
                  value < 0 ? classes.negative : undefined,
                  (value >= 0 && value < 0.4) ? classes.weak : undefined,
                  (value >= 0.4 && value < 0.7) ? classes.middle : undefined,
                  value >= 0.7 ? classes.strong : undefined
                )}
                >
                {value.toFixed(2)}
              </td>
              )
            } else {
              cells.push(
                <td
                  className={clsx(classes.tableCell, classes.emptyValue)}
                  data-column={counterColumns}
                  data-row={counterRows}
                  key={key}
                  onMouseOver={this.handleHover}
                  onMouseLeave={this.resetActive}
                >
                  -
                </td>
              )
            }

          } else {
            cells.push(
              <td
                className={clsx(classes.tableCell, classes.emptyValue)}
                key={key}
                data-column={counterColumns}
                data-row={counterRows}
                onMouseOver={this.handleHover}
                onMouseLeave={this.resetActive}
              >
                -
              </td>
            )
          }
        }

        counterColumns++;
      }

      counterRows++;

      rows.push(<tr key={isin}>
        {cells}
      </tr>)
    }

    return <div>
      <ul className={classes.legend}>
        <li className={classes.legendItem}>
          <div className={clsx(classes.bullet, classes.strong)} />
          <label>sehr hohe Korrelation</label>
        </li>
        <li className={classes.legendItem}>
          <div className={clsx(classes.bullet, classes.middle)} />
          <label>hohe Korrelation</label>
        </li>
        <li className={classes.legendItem}>
          <div className={clsx(classes.bullet, classes.weak)} />
          <label>niedrige Korrelation</label>
        </li>
        <li className={classes.legendItem}>
          <div className={clsx(classes.bullet, classes.negative)} />
          <label>negative Korrelation</label>
        </li>
      </ul>
      <table className={classes.correlationMatrix}>
        <thead>
          <tr>
          {heading}
          </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </table>
    </div>
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
        classes={{
          card: classes.root
        }}
        title={<span>{this.props.title || 'Korrelationsmatrix'}<sup>3</sup></span>}
        loading={this.props.loading || this.props.portfolioLoading}
        content={
          <div className={classes.container}>
            {this.props.error ? this.props.error : (this.props.data && this.props.portfolioData) ? this.renderChart() : DEFAULT_EMPTY_SECTION_MESSAGE}
            <p className={classes.description}>
              <sup>3</sup> Die Korrelationsmatrix zeigt, wie die aktuell im Portfolio enthaltenen Instrumente historisch miteinander korrelieren. Basis ist die Performance der Depotpositionen in den letzten drei Jahren. Die Bandbreite von Korrelationswerten liegt zwischen -1 und 1. Eine Korrelation von 1 bedeutet, dass sich zwei Instrumente in genau die gleiche Richtung bewegen, während eine Korrelation von -1 anzeigt, dass sich zwei Instrumente in exakt die entgegengesetzte Richtung bewegen. Eine Korrelation von 0 bedeutet, dass es keine Beziehung zwischen den Renditen dieser beiden Instrumente gibt. Je niedriger die Korrelation, desto höher der Diversifikationsvorteil.
            </p>
          </div>
        }
        expanded={this.props.expanded}
        onExpandedClick={this.props.onExpandedClick}
    />
  }
}

export default withStyles(styles)(CorrelationMatrix)