const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 798,
    margin: '0 auto'
  },
  name: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center',
    marginBottom: 32,
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  item: {
    width: 250,
    height: 70,
    margin: 8,
    border: '2px solid #D8DCDF',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 8,
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#0092E5',
      borderColor: '#0092E5'
    }
  },
  itemIcon: {
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  itemName: {
    fontSize: 14
  },
  more: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 8
  }
});

export default styles;