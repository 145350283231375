import React from "react";
import { connect } from 'react-redux'
import clsx from 'clsx';
import _ from 'lodash'

import withStyles from '@material-ui/core/styles/withStyles';

import styles from "./styles";
import SideMenuTriggerButton from "./components/SideMenuTriggerButton";
import NotificationItem from "./components/NotificationItem";
import ReportGenerationHandler from "../../../../components/ReportsGenerationHandler/ReportGenerationHandler";
import {executeIfPathExist, getInvestmentDynamicPath, shortNumber} from "../../utils";
import DropDownIcon from "./components/DropDownIcon";
import UserMenu from "./components/UserMenu";
import {getOriginLink} from "../../../../utils/constants";
import CustomerData from "./components/CustomerData";
import InfoTourButton from "../../../../components/Buttons/InfoTourButton";
import useFetchData from "../../../../hooks/useDataFetch";
import {PortfolioHandlerResource} from "../../../../utils/api";
import {useHistory} from "react-router-dom";
import useWindowSize from '../../../../components/WindowSizeHook';
import {
  getAlertingNotificationsCountSplitted
} from "../../../../components/DashboardDataProvider/DashboardDataProvider";

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
  alertingNotificationsCountSplitted: getAlertingNotificationsCountSplitted(state)
});


const Header = props => {
  const {
    userData,
    userLogo,
    classes,
    onTriggerMenuClick,
    open,
    handleLogoutClick,
    routes,
    alertingNotificationsCountSplitted
  } = props;

  const { width } = useWindowSize(0);
  const [adviceDropdownOpened, setAdviceDropdownOpened] = React.useState(false);
  const [userDropdownOpened, setUserDropdownOpened] = React.useState(false);
  const history = useHistory()

  const getBadge = (badgeValuefield) => {
    return shortNumber(badgeValuefield);
  };

  const getBadgeValue = (field) => {
    if (!_.isArray(field)) {
      return userData.hasOwnProperty(field) && userData[field].hasOwnProperty('anzahlChecked') ? userData[field].anzahlChecked: undefined;
    } else {
      let result = field.reduce((previousResult, fieldName) => {
        let currentValue = userData.hasOwnProperty(fieldName) && userData[fieldName].hasOwnProperty('anzahlChecked') ? userData[fieldName].anzahlChecked: 0;
        return previousResult + currentValue;
      }, 0)

      return result
    }

  };

  const getAlertNotificationValue = (field) => {

    if (!alertingNotificationsCountSplitted) {
      return 0
    }

    return alertingNotificationsCountSplitted[field]

  }

  const redirectToAlertings = () => {
    executeIfPathExist(props.investmentPlatform.routes, 'ASSETS_ALERTING_EVENTS_OVERVIEW', path => {
      history.push(`/${getInvestmentDynamicPath()}${path}`)
    })
    setAdviceDropdownOpened(false)
  }

  const handleLinkClick = (path) => {
    window.location = path.startsWith('/') ? getOriginLink(path) : path;
  };

  return (
    <>
      <div className={classes.headerContainer}>
        <div className={clsx(classes.headerSideContainer, open && classes.headerSideContainerOpen)}>
          <div className={classes.headerSideButtonWrapper}>
            <SideMenuTriggerButton onClick={onTriggerMenuClick} />
          </div>
          <div className={clsx(classes.headerLogo, !open && 'collapsed')}/>
        </div>
        <div className={clsx(classes.headerRightWrapper, open && 'menu-open')}>
        <div className={classes.headerSearchContainer}>
          <div>
            <CustomerData routes={routes}/>
          </div>
          <div className={classes.infoTourButton}>
            <InfoTourButton iconButton={width < 1150} />
          </div>
        </div>
        <div className={classes.headerNotificationContainer}>
          <div>
            <ReportGenerationHandler notificationItem />
          </div>
          <div>
           <NotificationItem faIcon={['far','file-alt']} onClick={() => handleLinkClick('/#/Organizer/Protokoll')} />
         </div>
          <div>
            <NotificationItem
              onClick={() => handleLinkClick('/#/Organizer/Postfach')}
              faIcon={['far','envelope']}
              badgeContent={getBadge(getBadgeValue('nachrichten'))}/>
          </div>
          <div>
            <NotificationItem
              onClick={() => handleLinkClick('/#/Organizer/Nachrichten/Posteingang')}
              faIcon={['far','comments']}
              badgeContent={getBadge(getBadgeValue('chat'))}/>
          </div>
          <div>
            <DropDownIcon
              openAction={() => setAdviceDropdownOpened(true)}
              closeAction={() => setAdviceDropdownOpened(false)}
              withoutDispatch={true}
              open={adviceDropdownOpened}
              faIcon={['far','bell']}
              badgeContent={
              getBadge(getBadgeValue(['geburtstage', 'legitimationen', 'beratungen', 'biprodokumente', 'gdvDateien'])
                + getAlertNotificationValue('asset_notifications_count')
                + getAlertNotificationValue('depot_notifications_count'))}
              items={[
                {key: 2, name: 'Offene Beratungen', path: '/#/Kunden/Mitteilungen/Beratungen', badgeContent: getBadge(getBadgeValue('beratungen'))},
                {key: 3, name: 'Anstehende Geburtstage', path: '/#/Kunden/Mitteilungen/Geburtstage', badgeContent: getBadge(getBadgeValue('geburtstage'))},
                {key: 1, name: 'Ablaufende Legitimationen', path: '/#/Kunden/Mitteilungen/Legitimationen', badgeContent: getBadge(getBadgeValue('legitimationen'))},
                {key: 6, name: 'Ausgelöster Produktalarm', path: redirectToAlertings, badgeContent: getBadge(getAlertNotificationValue('asset_notifications_count'))},
                {key: 7, name: 'Ausgelöster Depotalarm', path: redirectToAlertings, badgeContent: getBadge(getAlertNotificationValue('depot_notifications_count'))},
                {key: 4, name: 'Dokumente (BiPRO)', path: '/#/Organizer/BiPRO-Dokumente', badgeContent: getBadge(getBadgeValue('biprodokumente'))},
                {key: 5, name: 'GDV-Daten', path: '/#/Organizer/GDV', badgeContent: getBadge(getBadgeValue('gdvDateien'))},
              ]}
            />
          </div>
          <div>
            <UserMenu
              userData={userData}
              userLogo={userLogo}
              openAction={() => setUserDropdownOpened(true)}
              closeAction={() => setUserDropdownOpened(false)}
              handleLogoutClick={handleLogoutClick}
              open={userDropdownOpened}/>
          </div>
        </div>
        </div>
      </div>
    </>
  )
};

export default connect(mapStateToProps)(withStyles(styles)(Header));