export default theme => ({
  root: {
    marginRight: 0
  },
  label: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    letterSpacing: 0,
  },
  labelError: {
    color: '#f44336'
  },
  asterisk: {
    color: theme.palette.primary.main
  },
  radio: {
    padding: 9,
    color: 'rgba(0, 0, 0, 0.54)'
  },
  helperTextRoot: {
    marginLeft: 31
  }
})