import React, {useState} from "react";
import connect from "react-redux/lib/connect/connect";
import _ from "lodash";

import {ModelPortfolioResource, parseResponse} from "../../utils/api";
import {BEGINNING, PERFORMANCE_TIME_TYPE} from "../../utils/constants";
import {defaultDataBody, showErrors} from "../../utils/dataProviderUtils";
import {brokerLogout} from "../../containers/Authentication/Auth/utils";
import {updateDataAndLoadingAfter} from "../DashboardDataProvider/utils";
import {getUpdatedTwrData} from "../DashboardDataProvider/DashboardDataProvider";
import {buildTransactionsWithAddTradingOption} from "../../containers/Trades/utils";
import {getCombinedTradingsFromTransactions, getInitVPTransactions} from '../../containers/Modelportfolios/utils';
import { TRADING_ACTION_SAVINGS_PLAN } from '../Charts/InstrumentsAllocationTable/constants';
import {ALL_VALUE} from "../TimeRangeButtonsPad/constants";

export const defaultChartSettings = {
  historical: {
    withInvestiertesAmount: true,
    aggregateSelected: false,
  },
  performance: {
    withBenchmark: true,
    currently_selected_benchmarks: [],
    aggregateSelected: false
  },
  global: {
    withHistoricalPortfolios: true,
    // hide on PerformanceTab
    withProfitLoss: false,
    withPaymentPlans: false,
    withTransactionSaldo: false
  },
  transactionsOverview: {
    orderingColumn: undefined
  },
  risk_return_chart: {
    // BCA-8249 Used to store selected range for risk return chart
    selected_range: ALL_VALUE
  }
}

export const useGeneralPortfolioData = (dataProvider, modelPortfolioId, selectedDates, investmentStrategyId=undefined) => {
  const [investmentData, setInvestmentData] = React.useState({...defaultDataBody});
  const [breakdownData, setBreakdownData] = useState({...defaultDataBody});
  const [paymentPlans, setPaymentPlans] = React.useState({...defaultDataBody});
  const [profitAndLoss, setProfitAndLoss] = useState({...defaultDataBody});
  const [instrumentList, setInstrumentList] = useState({...defaultDataBody});
  const [dataLoading, setDataLoading] = React.useState(false);

  React.useEffect(() => {
    // use Effect that is called when modelportfolio is changed to fetch data for it
    if(modelPortfolioId){
      fetchData()
    }
  }, [modelPortfolioId, selectedDates]);

  const fetchData = () => {
    setDataLoading(true);

    fetchPortfolioData().finally(() => setDataLoading(false));
  };

  const fetchPortfolioData = async() => {
    setInvestmentData({
      ...investmentData,
      loading: true,
    });
    setInstrumentList({
      ...instrumentList,
      loading: true,
    });

    setBreakdownData({
      breakdownData,
      loading: true,
    });

    setPaymentPlans({
      ...paymentPlans,
      loading: true
    });

    setProfitAndLoss({
      ...profitAndLoss,
      loading: true
    });

    let start_date = selectedDates.start && selectedDates.start.format('YYYY-MM-DD') || undefined;
    let end_date = selectedDates.end && selectedDates.end.format('YYYY-MM-DD') || undefined;

    let response = await dataProvider.getPortfolioData(
      modelPortfolioId, undefined, start_date, end_date,
      undefined, undefined, investmentStrategyId);

    /** Parse portfolios breakdown */
    parseResponse(response, 'breakdown', (data) => {
      setBreakdownData({
        data,
        loading: false,
        errors: undefined
      });
    }, (errors) => {
      setBreakdownData({
        data: undefined,
        loading: false,
        errors: showErrors(errors)
      });
    });

    /** Parse financial plans data */
    parseResponse(response, 'financial', (data) => {
      setInvestmentData({
        data: data && data.data,
        loading: false,
        errors: undefined
      });
    }, (errors) => {
      setInvestmentData({
        data: undefined,
        loading: false,
        errors: showErrors(errors)
      });
    });

    /** Parse payment plans data */
    parseResponse(response, 'payment_plans', (data) => {
      setPaymentPlans({
        data,
        loading: false,
        errors: undefined
      });
    }, (errors) => {
      setPaymentPlans({
        data: undefined,
        loading: false,
        errors: showErrors(errors)
      });
    });

    /** Parse instruments table data */
    parseResponse(response, 'instruments_table', (data) => {
      setProfitAndLoss({
        data: data.profit_loss || [],
        loading: false,
        errors: undefined
      });
      setInstrumentList({
        data: data.instruments || [],
        loading: false,
        errors: undefined,
        timestamp: new Date().getTime()
      });
    }, (errors) => {
      setProfitAndLoss({
        data: [],
        loading: false,
        errors: showErrors(errors)
      });
      setInstrumentList({
        data: [],
        loading: false,
        errors: showErrors(errors)
      });
    });
  };

  return {investmentData, breakdownData, paymentPlans, profitAndLoss, instrumentList, dataLoading, fetchData}
};

export const useTimeWeightedReturnData = (dataProvider, modelPortfolioId, selectedDates, investmentData, chartsSettings, investmentStrategyId=undefined) => {
  const [twrData, setTwrData] = useState({...defaultDataBody, timestamp: null});

  React.useEffect(() => {
    const portfolio = _.get(investmentData, 'data.portfolios.0') || {};
    if(!investmentData.loading && portfolio) {
      fetchTwrData(portfolio)
    }
  }, [investmentData]);

  const fetchTwrData = async (portfolio) => {
    setTwrData({
      ...twrData,
      loading: true
    });

    try {
      let start_date;
      let end_date = selectedDates.end && selectedDates.end.format('YYYY-MM-DD') || undefined;

      // if one portfolio is selected, and selected time period is beginning
      if (selectedDates.value === PERFORMANCE_TIME_TYPE[BEGINNING].value) {
        start_date = portfolio.start_tracking_date
      } else {
        start_date = selectedDates.start && selectedDates.start.format('YYYY-MM-DD') || undefined;
      }

      let response = await dataProvider.getTimeWeightedReturnData(modelPortfolioId, portfolio.id, start_date, end_date,
        chartsSettings.global.withHistoricalPortfolios,
        chartsSettings.performance.currently_selected_benchmarks,
        investmentStrategyId
      );

      parseResponse(response, 'time_weighted_return', (data) => {
        const _data = {
          data: {
            portfolios: data || []
          },
          loading: false,
          errors: undefined,
          timestamp: new Date().getTime()
        };
        updateDataAndLoadingAfter(_data, setTwrData);
      }, (errors) => {
        setTwrData({
          data: undefined,
          loading: false,
          errors: showErrors(errors),
          timestamp: null
        });
      });
    }
    catch(errors) {
      if (errors.status === 403) {
        brokerLogout();
      }
      setTwrData({
        data: undefined,
        loading: false,
        errors,
        timestamp: null
      });
    }
  };

  return {twrData, setTwrData}

};

export const useChartsSettings = () => {
  const [chartsSettings, setChartsSettings] = useState(_.cloneDeep(defaultChartSettings));

  const handleShowChartSection = (settings, section) => {
    return section !== 'instrumentsItems'; // hide instruments from PerformanceTab
  };

  const handleChartSettingsChange = (chart, field, data) => {
    const updatedChartsSettings = {
      ...chartsSettings
    };
    if(!updatedChartsSettings[chart]) updatedChartsSettings[chart] = {};

    updatedChartsSettings[chart][field] = data;
    setChartsSettings(updatedChartsSettings);
  };

  return {chartsSettings, setChartsSettings, handleShowChartSection, handleChartSettingsChange}
};

// TODO: this data provide duplicate a lot of DashboardDataProvider - need refactoring
const withModelPortfolioDashboardData = WrappedComponent => connect()((props) => {
  const dataProvider = ModelPortfolioResource;
  const modelPortfolioId = props.portfolio ? props.portfolio.id : props.computedMatch.params.id;

  const [selectedDates, setSelectedDates] = React.useState(PERFORMANCE_TIME_TYPE.BEGINNING.getDateRange());
  const [selectedDatesType, setSelectedDatesType] = useState(PERFORMANCE_TIME_TYPE.BEGINNING);
  // states to store response from portfolio-data request

  const {
    investmentData,
    breakdownData,
    paymentPlans,
    profitAndLoss,
    instrumentList,
    dataLoading,
    fetchData
  } = useGeneralPortfolioData(dataProvider, modelPortfolioId, selectedDates);


  const {chartsSettings, handleShowChartSection, handleChartSettingsChange} = useChartsSettings();

  const {twrData, setTwrData} = useTimeWeightedReturnData(
    dataProvider, modelPortfolioId, selectedDates, investmentData, chartsSettings);

  const handleSelectedDatesChanged = (dates, _datesType) => {
    setSelectedDates(dates);
    setSelectedDatesType(_datesType)
  };

  const [portfoliosTransactions, setPortfoliosTransactions] = React.useState([]);
  const [combinedTradings, setCombinedTradings] = React.useState(undefined);

  const handleAddTradingOption = (portfolio, instrument, tradingType) => {
    const updatedPortfoliosTransactions = buildTransactionsWithAddTradingOption(
      portfoliosTransactions, combinedTradings, portfolio, instrument, tradingType);

    setPortfoliosTransactions(updatedPortfoliosTransactions);
  };

  const handleAddSavingPlanOption = (portfolio, instrument, action) => {
    const updatedPortfoliosTransactions = buildTransactionsWithAddTradingOption(
      portfoliosTransactions, combinedTradings, portfolio, instrument, TRADING_ACTION_SAVINGS_PLAN, action);

    setPortfoliosTransactions(updatedPortfoliosTransactions);
  };

  const initPortfoliosTransactions = () => {
    setPortfoliosTransactions(getInitVPTransactions(instrumentList, profitAndLoss, paymentPlans));
  };

  React.useEffect(() => {
    if (!_.isEmpty(instrumentList.data)) initPortfoliosTransactions();
  }, [instrumentList.timestamp]);

  React.useEffect(() => {
    setCombinedTradings(getCombinedTradingsFromTransactions(portfoliosTransactions));
  }, [portfoliosTransactions]);

  return (
    <WrappedComponent
      // data
      investmentData={investmentData}
      breakdownData={breakdownData}
      twrData={twrData}
      paymentPlans={paymentPlans}
      profitAndLoss={profitAndLoss}
      instrumentList={instrumentList}
      selectedDates={selectedDates}
      selectedDatesType={selectedDatesType}
      chartsSettings={chartsSettings}
      dataProvider={dataProvider}
      customerId={modelPortfolioId}
      portfolioId={_.get(investmentData, 'data.portfolios.0.id')}
      // flags
      dataLoading={dataLoading}
      // functions
      fetchData={fetchData}

      onSelectedDatesChanged={handleSelectedDatesChanged}
      handleShowChartSection={handleShowChartSection}
      handleChartSettingsChange={handleChartSettingsChange}
      updateHistoricalData={
        (data=[], portfolioToUpdate=undefined, additionalPortfoliosToUpdate=[]) => {
          // get mp's portfolio on cios from investment data
          const portfolio = _.get(investmentData, 'data.portfolios.0');
          setTwrData(getUpdatedTwrData(twrData, data, portfolio, additionalPortfoliosToUpdate))
        }
      }
      initPortfoliosTransactions={initPortfoliosTransactions}
      portfoliosTransactions={portfoliosTransactions}
      setPortfoliosTransactions={setPortfoliosTransactions}
      handleAddTradingOption={handleAddTradingOption}
      handleAddSavingPlanOption={handleAddSavingPlanOption}
      combinedTradings={combinedTradings}
      // other props
      {...props}
    />
  )
});

export default withModelPortfolioDashboardData;