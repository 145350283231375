import React from 'react';
import { Skeleton } from '@material-ui/lab';
import Link from '../../../components/Link';
import { DetailsIcon } from '../../../images';
import { getFavoriteListType } from '../utils'
import {getModelPortfolioOwner, modelPortfolioAccessible} from '../../Modelportfolios/utils'
import {OwnerCell} from "../../Modelportfolios/components/InstrumentsList/table-data";
import {getTableOptionsActionName} from "../../../components/DashboardTable/utils";


const NameCell = {
  header: "Favoritenlistename",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.name
    }
  },
  key: 'NameCell'
}

const TypeCell = {
  header: "Typ/Unternehmen",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return getFavoriteListType(item)
    }
  },
  key: 'TypeCell'
}

const InstrumentsCountCell = {
  header: {content: <>Anzahl Instrumente</>, justifyContent: 'center'},
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.assets && item.assets.length;
    }
  },
  key: 'InstrumentsCountCell'
}

const ActionsCell = {
  header: '',
  body: {
    content: (item, options) => {
      
      if (options.loading) {
        return <Skeleton />
      }

      const detailsTitle = getTableOptionsActionName(options, 'details');

      return (
        <div style={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%'
        }}>
          <Link
            title={detailsTitle || "Details"}
            icon={detailsTitle ? null : <DetailsIcon />}
            fontWeight={'normal'}
            onClick={() => options.onEdit(item)}
          />
          {options.onExtend && (
            <Link
              title="Vergleich öffnen"
              fontWeight={'normal'}
              onClick={() => options.onExtend(item)}
            />
          )}
          { options.onDelete && modelPortfolioAccessible(item, options.auth) && (
            <Link 
              title="Löschen" 
              onClick={() => options.onDelete(item.id, item.name)}
              fontWeight={'normal'}
            />
          )}
        </div>
      )
    }
  },
  key: 'ActionsCell'
}

export default {
  default: [{
    content: [NameCell],
    orderingFunction: (item) => (item.name || '').toLowerCase()
  }, {
    content: [TypeCell],
    orderingFunction: (item) => getFavoriteListType(item)
  }, {
    content: [OwnerCell],
    orderingFunction: (item) => getModelPortfolioOwner(item)
  }, {
    content: [InstrumentsCountCell],
    align: 'center',
    orderingFunction: (item) => item.assets && item.assets.length
  }, {
    content: [ActionsCell]
  }]
}