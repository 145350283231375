import React from "react";

export default () => {
  return (
    <svg
      id="Gruppe_7929"
      data-name="Gruppe 7929"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30.006"
      height="30"
      viewBox="0 0 30.006 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_7636"
            data-name="Rechteck 7636"
            width="30.006"
            height="30"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_7928" data-name="Gruppe 7928" clip-path="url(#clip-path)">
        <path
          id="Pfad_6690"
          data-name="Pfad 6690"
          d="M15.941,0c.559.077,1.122.131,1.676.235a14.815,14.815,0,0,1,9.917,6.52,1.171,1.171,0,1,1-1.957,1.282,12.534,12.534,0,0,0-6.619-5.059,12.647,12.647,0,1,0-6.474,24.4c5.3,1,9.617-.8,12.871-5.1a1.342,1.342,0,0,1,1.062-.676,1.108,1.108,0,0,1,1.059.609,1.133,1.133,0,0,1-.078,1.246A14.657,14.657,0,0,1,24.265,26.8a14.788,14.788,0,0,1-9.932,3.187A15,15,0,0,1,10.936.568C11.869.306,12.851.219,13.81.051c.085-.015.17-.034.255-.051ZM30.006,15a1.3,1.3,0,0,0-1.462-.943c-.483.027-.968.005-1.468.005,0-.585.009-1.131-.005-1.676a1.612,1.612,0,0,0-.111-.567,1.163,1.163,0,0,0-2.225.441c-.013.592,0,1.185,0,1.8-.616,0-1.2-.009-1.783,0a1.17,1.17,0,0,0,0,2.339c.195.007.39,0,.586,0h1.2c0,.63-.01,1.215,0,1.8a1.165,1.165,0,0,0,2.249.391,1.71,1.71,0,0,0,.087-.543c.011-.542,0-1.085,0-1.647.514,0,.992-.022,1.468.005a1.3,1.3,0,0,0,1.463-.943ZM18.9,11.926A3.07,3.07,0,0,0,16.363,9.5a3.024,3.024,0,0,0-3.272,1.379,3.076,3.076,0,0,0-.006,3.552c.091.146.108.226-.011.371a3.431,3.431,0,0,0-.772,2.842,3.515,3.515,0,1,0,6.015-3.016,3.153,3.153,0,0,0,.578-2.706m-4.3,5.125a1.171,1.171,0,1,1,1.17,1.172,1.174,1.174,0,0,1-1.17-1.172m1.186-3.516a.878.878,0,1,1,.864-.885.888.888,0,0,1-.864.885m-8.22-.174c0,2.011,0,4.023,0,6.034a1.169,1.169,0,0,0,2.337.02q.011-4.379,0-8.758A1.167,1.167,0,0,0,8.062,9.71c-.747.532-1.492,1.068-2.223,1.623a1.143,1.143,0,0,0-.224,1.6,1.16,1.16,0,0,0,1.563.312c.115-.069.223-.149.382-.256v.376"
          transform="translate(0)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
