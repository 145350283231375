import {
  removeFromStorage,
  TOKEN_KEY,
  USER_KEY,
  CUSTOMER_ID_KEY
} from "../../../utils/storage";
import {logout} from "./actions";
import {store} from "../../../index";
import {getBackToDivaCustomerLink} from "../../../utils/constants";
import {buildCurrentCustomerLoginPath} from "../../../routes";

const runLogout = (url) => {
  // clear redux in case we stay in same origin
  const sameOrigin = new URL(url).origin === window.location.origin;
  if(sameOrigin){
    store.dispatch(logout());
  }

  removeFromStorage(USER_KEY);
  removeFromStorage(TOKEN_KEY);
  removeFromStorage(CUSTOMER_ID_KEY);
  window.location.href = url;
};

export const customerLogout = (agencyId) => {
  const url = `${window.location.origin}${buildCurrentCustomerLoginPath(agencyId)}`;
  return runLogout(url);
};

export const brokerLogout = (url=undefined) => {
  url = url || getBackToDivaCustomerLink();
  return runLogout(url);
};