import React from 'react';


export default ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <g transform="translate(-1296 -627)">
        <rect fill="#23d374" width="36" height="36" rx="18" transform="translate(1296 627)"/>
        <path fill="#fff" d="M8.182,14.23,0,6.048l1.66-1.66,6.522,6.4L18.973,0l1.66,1.66Z" transform="translate(1303.999 638)"/></g></svg>
  )
}