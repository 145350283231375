import {makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
  widgetContainer: {
    padding: 0,
    paddingBottom: props => props.expanded ? 20 : 0,
    borderRadius: 8
  },
  widgetHeaderContainer: {
    borderBottom: props => props.expanded ? '2px solid rgba(0, 0, 0, 0.12)' : 'none',
    padding: '12px 22px !important',
    marginBottom: props => props.expanded ? 16 : 0,
    zIndex: 'auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  widgetHeaderContainerBordered: {
    border: 'none !important',
    padding: '8px 16px !important',
  },
  contentContainer: {
    padding: '0 20px'
  },
  widgetContainerBordered: {
    boxShadow: 'none !important',
    border: '1px solid #D9DCDF',
  },
  contentContainerBordered: {
    padding: '0 16px !important'
  }
}))