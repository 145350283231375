import React from 'react';

import GroupReporting from "../../Reporting";

const AutoReportSettingsTabContent = (props) => {
  return (
    <GroupReporting isAutoReport isGroupReporting newDesign={props.newDesign}/>
  )
}

export default AutoReportSettingsTabContent;