import React from "react";

import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from '@material-ui/core/IconButton';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import useStyles from "./styles";
import {isRequiredQuestion, isValidString, setQuestionErrorFromConfig} from "../../../../../utils";
import SelectFormElement from "../../formElement/SelectFormElement";
import {default as IconBtn} from "../../../../../../../components/Buttons/IconButton";
import PlusBackgroundIcon from "../../../../../../../images/PlusBackgroundIcon";
import {default as RefAccountForm} from "./components/RefAccountForm";
import SuccessIcon from "../../../../../../../images/SuccessIcon";
import {Grid} from "@material-ui/core";
import {QuestionnairesHandlerResource} from "../../../../../../../utils/api";
import {CUSTOMER_TYPES} from "../../../../../constants";

const isValidBankAccount = (account, danteAccount) => {
  if(account)
    return (!!danteAccount ? isValidString(account.accountHolder) : isValidString(account.account_holder))
      && isValidString(account.iban) && isValidString(account.bic)
      && (!danteAccount || isValidString(account.name));

  return false;
};

function isReferenceAccountsQuestionValid(question, step, afterChange, service) {
  if(isRequiredQuestion(question)){
    const isValid = isValidBankAccount(question.answer);
    question.error = isValid ? null : 'Das Bankkonto ist nicht korrekt';
    return isValid;
  }

  return true;
}

export const ReferenceAccountsQuestionMethods = {
  required: isRequiredQuestion,
  valid: isReferenceAccountsQuestionValid,
};

export const bankAccountToOption = (bankAccount) => {
  const option = {
    id: bankAccount.iban,
    value: bankAccount.iban,
    label: [bankAccount.iban, bankAccount.name, bankAccount.accountHolder].join(' / '),
    account_holder: bankAccount.accountHolder,
    iban: bankAccount.iban,
    bic: bankAccount.bic,
    creditInstituteName: bankAccount.name
  };

  if(bankAccount.city) {
    // as it could be empty - but CIOS can't proceed empty values
    option.creditInstituteCity = bankAccount.city;
  }

  return option
}


const ReferenceAccountsQuestion = props => {
  const {
    question,
    onAnswerChange,
    dataService,
  } = props;

  const classes = useStyles();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = React.useState(false);
  const [formDataLoading, setFormDataLoading] = React.useState(false);
  const [formData, setFormData] = React.useState(null);
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const bankAccounts = (question.bank_accounts || []).map(bankAccountToOption);

  React.useEffect(() => {
    if(question.answer) {
      // check/update answer according to existing bank accounts
      const acc = bankAccounts.find(acc => acc.value === question.answer.value);
      // in case account not in the list - clear answer
      if(!acc){
        question.error = null;  // clean error
        onAnswerChange(question.uid, null);
      }
    }
  }, []);

  React.useEffect(() => {
    if (!question.answer && bankAccounts.length > 0) {
      question.error = null;  // clean error
      onAnswerChange(question.uid, bankAccounts[0]);
    }
  });

  const handleChange = (value) => {
    const acc = bankAccounts.find(acc => acc.value === value);
    question.error = null;  // clean error
    onAnswerChange(question.uid, acc);
  };

  const handleAddClick = () => {
    setOpenDialog(true);
  };

  const handleCreateAccount = async () => {
    // validate account holder is minor or guardians
    if(dataService.customer_type === CUSTOMER_TYPES.MINOR){
      const names = [
        dataService.getMemberFullName(undefined,', ', true),
        ...dataService.members.map((m, idx) => dataService.getMemberFullName(idx, ', ', true))
      ];
      if(!names.includes(formData.accountHolder)){
        setFormDataErrors({
          ...formDataErrors,
          accountHolder: 'Sie können nur ein Konto verwenden, über welches entweder der Minderjährige oder einer der Erziehungsberechtigten verfügt.'
        })
        return;
      }
    }

    setFormDataLoading(true);
    QuestionnairesHandlerResource.at(`customer-info/${dataService.customer_id}/`).post({
      bankAccounts: [formData]
    }).then(res => {
      const account = {...formData};
      account.value = account.id = account.iban;
      account.label = [account.iban, account.name, account.accountHolder].join(' / ');

      question.bank_accounts = [...question.bank_accounts, account];
      setOpenSuccessDialog(true);
      handleModalClose();
    }).catch(errors => {
      setQuestionErrorFromConfig(question, errors.data || errors, (q) => {
        return {dante_field: 'bankAccounts[0]'}
      });
      setFormDataErrors(errors.data || errors);
    }).finally(() => setFormDataLoading(false));
  };

  const handleModalClose = () => {
    setOpenDialog(false);
    setFormDataErrors({});
    setFormData(null);
  }

  return (
    <div className={classes.questionContainer}>
      <div className={classes.addBtnContainer}>
        <IconBtn
          label={'Neues Referenzkonto hinzufügen'}
          component={PlusBackgroundIcon}
          onClick={handleAddClick}
          size={36}
          disabled={question.disabled}
          customClasses={{buttonRoot: question.disabled ? classes.buttonRootDisabled : classes.buttonRoot}}
        />
      </div>
      <SelectFormElement
          value={question.answer && question.answer.value || ''}
          required={isRequiredQuestion(question)}
          options={bankAccounts}
          label={question.question_text}
          custom_classes={question.custom_classes}
          onChange={handleChange}
          error={question.error}
          disabled={question.disabled}
      />
      <Dialog
        open={!!openDialog}
        onClose={() => handleModalClose()}
        fullWidth={true}
        scroll="body"
        maxWidth={'sm'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.container}
        classes={
          {
            root: classes.root,
            paper: classes.paper
          }
        }
      >
        <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
          <span>Neues Referenzkonto hinzufügen</span>
          <IconButton disableRipple aria-label="close" className={classes.closeButton} onClick={() => handleModalClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <RefAccountForm handleFormChange={setFormData} errors={formDataErrors} setErrors={setFormDataErrors} />
        </DialogContent>
        <DialogActions className={classes.modalAction}>
          <div>
            <Button disabled={formDataLoading || !isValidBankAccount(formData, true)} onClick={handleCreateAccount} color="primary" variant="contained" className={classes.acceptButton}>
              Speichern
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth={'sm'} open={!!openSuccessDialog} onClose={() => setOpenSuccessDialog(false)}>
        <DialogContent className={classes.dialogSuccessContent}>
          <IconButton disableRipple aria-label="close" className={classes.closeButton} onClick={() => setOpenSuccessDialog(false)}>
            <CloseIcon />
          </IconButton>
          <Grid container spacing={2} alignItems={"center"} className={classes.dialogSuccessContainer}>
            <Grid item><SuccessIcon/></Grid>
            <Grid item>Das neue Referenzkonto wurde erfolgreich hinzugefügt.</Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ReferenceAccountsQuestion;