import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import styles from "./styles";
import clsx from "clsx";

const StepFooter = props => {
  const {
    classes,
    leftItems,
    rightItems
  } = props;

  return (
    <div className={classes.footerContainer}>
      <Grid container spacing={2} className={clsx(classes.footerContent, 'app-page-container')}>
        <Grid item>
          <Grid container spacing={2}>
            {leftItems && leftItems.map((item, index) => (
              <Grid item key={index}>{item}</Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {rightItems && rightItems.map((item, index) => (
              <Grid item key={index}>{item}</Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(StepFooter);