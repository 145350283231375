import {makeStyles} from "@material-ui/core";

const styles = theme => ({
  root: {
    minHeight: 150,
  },
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& > div': {
      width: '100%'
    },
    flexDirection: 'column'
  },
});

export const useStyles = makeStyles(styles);

export default styles;