import React from 'react';

import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from "@material-ui/core/withWidth/withWidth";

import styles from './styles';

import {paginateArray} from "../../../utils/utils";
import Pagination from "../../Pagination/Pagination";
import PaginationInput from "../../Pagination/PaginationInput";
import WarningTooltip from "../../WarningTooltip";
import CustomerFullnameMenu from "../../CustomerFullnameMenu/CustomerFullnameMenu";
import {Skeleton} from '@material-ui/lab';
import _ from 'lodash';

const POSTBOX_ACTIVE_STATUS = 'ePost';

const DYNAMIC_GROUP_INFO = 'Die jetzt angezeigte Kundenauswahl gibt den derzeitigen Stand wieder. Da die Kundenselektion für diese Gruppe automatisch erfolgt, kann sich der Umfang der ausgewählten Kunden bis zum Zeitpunkt Erstellung des Reports noch verändern.'

const CustomersTable = (props) => {
  const {
    classes,
    customers,
    loading,
    width
  } = props;

  const isSmall = ['xs', 'sm'].includes(width);

  const START_PAGE_INDEX = 0;
  const CUSTOMER_COLUMNS = isSmall ? 1 : 2;
  const CUSTOMERS_PER_PAGE = isSmall ? 10 : 20;

  const [pages, setPages] = React.useState([...paginateArray(customers, CUSTOMERS_PER_PAGE)]);
  React.useEffect(() => {
    setPages([...paginateArray(customers, CUSTOMERS_PER_PAGE)])
  }, [customers])

  const [currentPage, setCurrentPage] = React.useState(START_PAGE_INDEX);

  const renderRowNumberCellValue = (index) => {
    const customerTableNumber = (index + 1) + (CUSTOMERS_PER_PAGE * (currentPage));
    const value = customerTableNumber < 10 ? `0${customerTableNumber}` : customerTableNumber;
    return <span>{value}</span>
  };

  const renderRows = (entities, startIndex) => {
    return entities.map((customer, index) => {
      return (
        <TableRow key={customer.customer_id}>
          <TableCell className={`${classes.tableCell} ${classes.rowNumberColumn}`}>
            {renderRowNumberCellValue(index + startIndex)}
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.nameColumn}`}>
            <CustomerFullnameMenu
              customer={customer}
              customerFullname={customer.customer_full_name}
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.customerNumberColumn}`}>
            <span>Kunden-Nr: {customer.customer_id}</span>
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.customerStatusColumn} ${classes.columnCenter}`}>
            {customer.postbox ? POSTBOX_ACTIVE_STATUS : (
              <WarningTooltip
                color={"#c22c12"}
                placement={"top"}
                title={"Dieser Kunde besitzt noch kein elektronisches Postfach. Er kann im Menüpunkt “Kunden Login” eingeladen werden."}
              />
            )}
          </TableCell>
        </TableRow>
      )
    })
  };

  const renderTable = (entities, startIndex) => {
    return (
      <Table className={classes.table}>
        <TableBody>
          {renderRows(entities, startIndex)}
        </TableBody>
      </Table>
    )
  };

  const renderTables = () => {
    let tables = [];
    const entities = pages[currentPage];
    let customersPerColumn = Math.round(CUSTOMERS_PER_PAGE / CUSTOMER_COLUMNS);

    if (entities.length < CUSTOMERS_PER_PAGE) {
      customersPerColumn = Math.round(entities.length / CUSTOMER_COLUMNS)
    }

    for (let i = 0; i < CUSTOMER_COLUMNS; i++) {
      const startIndex = i * customersPerColumn;
      const tableEntities = entities.slice(startIndex, startIndex + customersPerColumn);
      if (tableEntities.length) {
        const table = renderTable(tableEntities, startIndex);
        tables.push(table);
      }
    }
    return (
      <Grid container>
        {tables.map((table, index) => (
          <Grid key={index} item xs={isSmall ? 12 : 12 / CUSTOMER_COLUMNS} className={classes.tableContainer}>
            {table}
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderPagination = () => {
    return (
      <Grid container spacing={2} className={classes.paginationContainer}>
        <Grid item className={classes.paginationInfo}>
          Seite {currentPage + 1} von {pages.length}
        </Grid>
        <Grid item>
          <Pagination
            handlePageChanged={setCurrentPage}
            totalPageCount={pages.length}
            currentPage={currentPage}
          />
        </Grid>
        <Grid item>
          <PaginationInput
            handlePageChanged={setCurrentPage}
            totalPageCount={pages.length}
            currentPage={currentPage}
            label={'Seite aufrufen:'}
          />
        </Grid>
      </Grid>
    )
  };

  return (
    <Card className={classes.card}>
      {!loading
        ? (
          <CardContent className={classes.cardContent}>
            {props.isDynamicGroupProcess && <b>{DYNAMIC_GROUP_INFO}</b>}
            {pages.length > 0 && pages[currentPage].length > 0 && renderTables()}
            {pages.length > 1 && renderPagination()}
            {props.isDynamicGroupProcess && _.isEmpty(props.customers) &&
              <div style={{marginTop: 5}}>Diese dynamische Reportinggruppe enthält nach den von Ihnen gewählten Kriterien zur Zeit keine Kunden.</div>
            }
          </CardContent>
        ) : (
          <Skeleton height={55}></Skeleton>
        )
      }
    </Card>
  )
};

export default withWidth()(withStyles(styles)(CustomersTable));
