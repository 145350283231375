import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  container: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',
    backgroundColor: 'white',
    borderRadius: 2,
    padding: 10,
    overflow: 'hidden'
  },
  editorContainer: {
    minHeight: '800px'
  },
  name: {
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    color: '#4D4F5C'
  },
  loader: {
    color: '#63696f'
  },
});

export default makeStyles(styles);