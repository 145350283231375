import React from "react";
import {connect} from "react-redux";
import _ from "lodash";

import DashboardTable from "../../../../../../components/DashboardTable/DashboardTable";
import {getTableStructureSwitch} from "../../../../../../components/Charts/InstrumentsAllocationTable/table-data";
import {getTableStructureEditList} from "../../../../../../components/Charts/PaymentPlansTable/table-data";
import useStyles from './styles';
import {displayErrorSnackBar} from "../../../../../../components/SnackbarProvider/actions";
import {getErrorMessage} from "../../../../../../utils/utils";
import {ModelPortfolioResource} from "../../../../../../utils/api";
import {CircularProgress} from "@material-ui/core";


function Portfolio(props) {
  const {
    instrumentsHandler,
    selectedAssets,
    onInstrumentSelect,
    onSelectAll,
    portfolioInstruments,
    action,
    withSrri,
    clientSRRI,
  } = props;

  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [dataSource, setDataSource] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    getData().then(data => setDataSource(data))
    .catch(error => {
      props.dispatch(displayErrorSnackBar(getErrorMessage(error)))
    })
    .finally(() => setLoading(false));
  }, []);

  const getAvailableAssets = () => {
    return dataSource.filter(asset => !asset.disabledByFilter);
  };

  const getData = async () => {
    let items = _.cloneDeep(portfolioInstruments); // clone all portfolio instrument as we will modify them

    const _allPortfolioInstruments = _.flatten(items.map((pInstrument) => {
      // In case pInstrument has components - it is switch plan
      // and we need to get data for all switchIn instruments
      return [pInstrument, ...(pInstrument.components || [])]
    }));

    // In case we have instruments handler or withSrri(esg data should be displayed)
    // we should request asset info to get all required data
    if (instrumentsHandler || withSrri) {

      let res = await ModelPortfolioResource.infoAssets(_allPortfolioInstruments.filter(i => i.isin).map(i => i.isin));

      // extend all instruments (incl. switch in) with asset info
      _allPortfolioInstruments.map((pInstrument) => {
        const instrumentData = res.find((e) => e.isin === pInstrument.isin) || {};
        delete instrumentData.category;
        Object.assign(pInstrument, {...instrumentData});
      });

    }

    if(instrumentsHandler){
      // extend instruments with custodian data and validate
      await instrumentsHandler.getCustodianAndFilterInstruments(_allPortfolioInstruments);
    }

    return _.orderBy(items, ['disabledByFilter', (item) => item.name && item.name.toLowerCase()], ['desc', 'asc']);
  };

  const handleAllSelected = () => {
    if (onSelectAll){
      const selected = selectedAssets || [];
      let assets = getAvailableAssets();
      if (selected.length === assets.length) {
        assets = []; // unselect all
      }

      onSelectAll(assets)
    }
  };

  if(loading) {
    return <div className={classes.loadingContainer}>
      <CircularProgress className={classes.loadingIndicator}/>
    </div>
  }

  const isPaymentPlan = action && ['delete', 'edit'].includes(action);

  return (
    <DashboardTable
      structure={isPaymentPlan ? getTableStructureEditList(withSrri) : getTableStructureSwitch(withSrri)}
      dataSource={dataSource}
      expanded={true}
      tableClasses={classes}
      withFooter={false}
      options={{
        onAllSelect: handleAllSelected,
        totalInstrumentsCount: getAvailableAssets().length,
        onInstrumentSelect: onInstrumentSelect,
        selected: selectedAssets || [],
        withSrri,
        clientSRRI,
        isCheckedKeyField: isPaymentPlan && 'payment_id'
      }}
    />
  )
}

export default connect()(Portfolio);
