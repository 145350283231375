import { makeStyles, createStyles } from "@material-ui/core";

const styles = createStyles((theme) => ({
  dialogRoot: {
    zIndex: '1000 !important',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',

    '&  > div:first-child': {
      backgroundColor: 'transparent'
    }
  },
  dialogTitleRoot: {
    paddingLeft: 16,
    paddingRight: 16,

    [theme.breakpoints.only('xs')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },

    '& > h2': {
      fontFamily: 'Roboto-Regular',
      color: '#313D44',
      [theme.breakpoints.only('sm')]: {
        fontSize: 18,
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: 16,
      }
    }
  },


  editorSwitchContainer: {
    padding: '0 0 10px 0 !important',
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: '10px!important'
  },
  switch: {
    marginLeft: '5px'
  },
  checkboxLabel: {
    fontSize: 16,
    [theme.breakpoints.only('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: 12,
    }
  },
  checkboxFormControlLabel: {},
  checkboxWrap: {
    marginTop: 16
  },
  textEditorWrapper: {
    borderRadius: 0,
    minHeight: 440,
    '& .ql-editor': {
      minHeight: 363
    }
  },
  itemContainer: {
    height: '100%',
    marginTop: 20,

    '& #cover-page-document-preview-container': {
      width: '100%',
      minHeight: 440,
      margin: 0
    }
  },
}))

export default makeStyles(styles)