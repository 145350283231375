import {
  ADD_COMBINED_TRADING_OPTION,
  REMOVE_COMBINED_TRADING_OPTION,
  ADD_PORTFOLIO_COMBINED_TRADING_OPTION,
  UPDATE_PORTFOLIO_COMBINED_TRADING_OPTION,
  REMOVE_PORTFOLIO_COMBINED_TRADING_OPTION,
  REMOVE_ALL_PORTFOLIOS_COMBINED_TRADING_OPTION
} from './constants';

export const addCombinedTradingOption = (customer, portfolio, instrument, tradingType, savingPlansAction) => ({
  customer,
  portfolio,
  instrument,
  tradingType,
  savingPlansAction,
  type: ADD_COMBINED_TRADING_OPTION
});

export const addCombinedPaymentPlanTradingOption = (customer, portfolio, instrument, savingPlansAction, tradingType) => {
  return addCombinedTradingOption(customer, portfolio, instrument, tradingType, savingPlansAction);
};

export const removeCombinedTradingOption = (customerId, portfolioId, instrumentId) => ({
  customerId,
  portfolioId,
  instrumentId,
  type: REMOVE_COMBINED_TRADING_OPTION
});

export const addPortfolioCombinedTradingOption = (customer, portfolio, tradingType, savingPlansAction, updateCustomerTradingType) => ({
  customer,
  portfolio,
  tradingType,
  savingPlansAction,
  updateCustomerTradingType,
  type: ADD_PORTFOLIO_COMBINED_TRADING_OPTION
});

export const removePortfolioCombinedTradingOption = (customerId, portfolioId) => ({
  customerId,
  portfolioId,
  type: REMOVE_PORTFOLIO_COMBINED_TRADING_OPTION
});

export const updatePortfolioCombinedTradingOption = (customerId, portfolioId, tradingData, portfolioData) => ({
  customerId,
  portfolioId,
  tradingData,
  portfolioData,
  type: UPDATE_PORTFOLIO_COMBINED_TRADING_OPTION
});

export const removeAllCombinedTradings = (customerId) => ({
  customerId,
  type: REMOVE_ALL_PORTFOLIOS_COMBINED_TRADING_OPTION
});
