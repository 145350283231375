import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import {
  ProductStructureTableStyles
} from '../../../../../../ProductComparison/components/ProductStructureTab/styles';

import TableHeaderCard
  from '../../../../../../ProductComparison/components/ProductStructureTab/components/TableHeaderCard';
import { PRODUCT_TYPE } from '../../../../../../ProductComparison/constants';
import SustainabilityCellContent
  from '../../../../../../ProductComparison/components/SustainabilityMetricsTab/componenets/SustainabilityCellContent';
import ChartSectionBordered from '../../../../ChartSectionBordered/ChartSectionBordered';
import DashboardInfoTooltip from '../../../../DasboardInfoTooltip';
import useStyles from './styles';
import { NO_SECTION_MARK } from '../../../../../../../utils/constants';


// NOTE: we assume onExpanded function does not contain any data which could change during re-render
const propsAreEquals = (prevProps, nextProps) => {
  return prevProps.columnStructure === nextProps.columnStructure &&
    prevProps.data === nextProps.data &&
    prevProps.expanded === nextProps.expanded
};

const SustainabilityMetricsTable = React.memo(({expanded, tableKey, columnStructure, data, onExpanded}) => {
  const categories = React.useMemo(() => {
    const _categories = _.sortBy(Object.keys(columnStructure['subcategories']), subcategoryName => columnStructure['subcategories'][subcategoryName].order);
    // if there is a main category - set it as first column
    if (_.get(columnStructure, 'main_category')) {
      _categories.unshift(tableKey);
    }

    return _categories;
  }, [columnStructure]);

  const classes = ProductStructureTableStyles();
  const tableClasses = useStyles();

  const showMissingDataInfoCustomCondition = React.useMemo(() => {
    let hasMissingInfo = false;
    Object.values(data).forEach(p => {
      const portfolioMetrics = p[tableKey];

      if(_.isEmpty(portfolioMetrics) || _.some(Object.values(portfolioMetrics), (cellValue) => _.isNil(cellValue))){
        hasMissingInfo = true;
        return;
      }
    });

    return hasMissingInfo;
  }, [data, categories]);

  const SustainabilityCategory = ({category}) => {
    const isMain = category === tableKey;
    const pathToColumnStructure = isMain ? 'main_category' : `subcategories.${category}`;
    const title = _.get(columnStructure, `${pathToColumnStructure}.title`);
    const tooltip = _.get(columnStructure, `${pathToColumnStructure}.tooltip`);

    return (
      <Grid container className={clsx('tr', isMain && tableClasses.mainCategory)}>
        <Grid item xs={12} className={'td'}>
          <div className={classes.cellContentWrapper}>
            <div className={classes.headerCellContent} title={title}>
              {title} {!!tooltip && <DashboardInfoTooltip title={tooltip} />}
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }

  const getSustainabilityColumnContent = (portfolio, columnKey, pIndex, index) => {
    const isMain = columnKey === tableKey;
    const pathToColumnStructure = isMain ? 'main_category' : `subcategories.${columnKey}`;
    const fieldType = _.get(columnStructure, `${pathToColumnStructure}.field_type`);

    return (
      <Grid item xs={12} className={clsx('td', tableClasses.bodyCell, isMain && tableClasses.mainCategory)}
            key={`data_col_${pIndex}_cell_${index}`}>
        <SustainabilityCellContent
          product={{...portfolio, type: PRODUCT_TYPE.CUSTOMER_PORTFOLIO}}
          tableKey={tableKey}
          columnKey={columnKey}
          fieldType={fieldType}
        />
      </Grid>
    )
  };

  return (
    <ChartSectionBordered
      title={
        <b>
          {columnStructure.title} {columnStructure.tooltip && <DashboardInfoTooltip title={columnStructure.tooltip} />}
        </b>
      }
      expanded={expanded}
      onExpanded={onExpanded}
      content={
        <>
          <Grid container className={clsx(classes.productStructureTable, tableClasses.productStructureTable)}>
            <Grid item className={clsx(classes.firstColumn, tableClasses.firstColumn)}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container className={'thead'}>
                    <Grid container className={'tr'}>
                      <Grid item xs={12} className={'td'} style={{borderTop: '5px solid transparent'}}>
                        <div className={classes.headerCellContentWrapper}>Depot</div>
                      </Grid>
                    </Grid>
                  </Grid>
                  {categories.map((category, index) =>
                    <SustainabilityCategory key={`first_col${index}`} category={category} />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={{overflow: 'auto'}} xs={true}>
              <Grid container style={{height: '100%'}}>
                <Grid item xs={12} className={'thead'} style={{maxHeight: 56}}>
                  <Grid container className={'tr'}>
                    {Object.values(data).map((p, index) => (
                      <TableHeaderCard key={index} name={p.name} color={p.color}/>
                    ))}
                  </Grid>
                </Grid>

                <Grid item xs={12} className={'tbody'} style={{height: 'calc(100% - 56px)'}}>
                  <Grid container style={{ flexWrap: 'nowrap', height: '100%'}} className={classes.tbodyContent}>
                    {Object.values(data)
                      .map((p, pIndex) =>
                        <Grid className={'tr'} key={`data_col_${pIndex}`} item xs={2}>
                          {categories.map((category, index) => {
                            return getSustainabilityColumnContent(p, category, pIndex, index)
                          })}
                        </Grid>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {showMissingDataInfoCustomCondition && (
            <div style={{marginTop: '1.5em', paddingBottom: 10}}>
              {NO_SECTION_MARK} Für dieses Depot steht diese Statistik nicht zu Verfügung.
            </div>
          )}
        </>
      }
    />
  )
}, propsAreEquals);

export default SustainabilityMetricsTable;