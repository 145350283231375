const historical_performance_chart_1 = {
  selector: '#performance-chart',
  key: 'customer_app_dashboard_performance_chart',
  position: 'center',
};
const historical_performance_chart_2 = {
  selector: '#performance-chart-benchmark-checkbox',
  key: 'customer_app_dashboard_benchmark_switch'
};

export const getHistoricalPerformanceChartChapterSteps = () => {
  return [
    historical_performance_chart_1,
    historical_performance_chart_2,
  ];
};
