import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path
        id="Pfad_6710"
        data-name="Pfad 6710"
        d="M1.758,26.484H28.242v1.758H1.758Zm22.969-3.515h1.758v1.758H24.727ZM14.134,21.478l3.811,3.248h-7.6Zm-3.656-3.116,2.3,1.961L9.4,23.222Zm5.007,1.958,2.427-2.08,1.041,5.035Zm-3.08-2.625h3.441l-1.716,1.471Zm-.658-5.044,4.683-1.8,1.035,5.081H11.017Zm6.347-2.433L22.969,8.35v1.8l1.758,1.758v9.3H22.969v3.515H21.05ZM1.758,11.4V7.148H5.332v8.789H3.27Zm5.332-.731L22.969,4.582V6.467L7.09,12.554ZM24.727,9.421V1.758h1.758V9.421l-.879.879ZM22.969,0V2.7L7.09,8.787v-3.4H0v6.295l2,6.01H7.09V14.437l2.689-1.031L9.4,15.13l-2.133,9.6H0V30H30V24.727H28.242V21.211H26.484v-9.3l1.758-1.758V0Z"
        fill="#80858c"
      />
    </svg>
  );
};
