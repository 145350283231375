import React from 'react';

function WaterEmissions({}) {
  return (
    <svg id="Gruppe_8239" data-name="Gruppe 8239" xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rechteck_7613" data-name="Rechteck 7613" width="30" height="28" fill="#fff"/>
        </clipPath>
      </defs>
      <g id="Gruppe_7822" data-name="Gruppe 7822" clipPath="url(#clip-path)">
        <path id="Pfad_6674" data-name="Pfad 6674" d="M29.421,16.094a2.354,2.354,0,0,0-3.048-.364l-7.92,5.332H16.088a2.614,2.614,0,0,0-2.514-3.469H8.481A4.418,4.418,0,0,0,4.8,19.328H.889a.867.867,0,1,0,0,1.734H5.3a.892.892,0,0,0,.8-.479,2.534,2.534,0,0,1,2.386-1.255h5.093a.867.867,0,1,1,0,1.734H10.728a.867.867,0,1,0,0,1.734c8.681,0,8.186.063,8.507-.153l8.148-5.486a.544.544,0,0,1,.7.084.511.511,0,0,1-.047.735l-9.645,8.289H.889A.867.867,0,1,0,.889,28H18.731a.9.9,0,0,0,.588-.217l9.9-8.5a2.215,2.215,0,0,0,.205-3.184m-4.145-4.823c0-4.388-4.685-8.421-5.531-10.7a.9.9,0,0,0-1.672,0c-.844,2.274-5.531,6.316-5.531,10.7,0,8.069,12.734,8.075,12.734,0M18.908,2.718C20.5,5.133,23.5,8.238,23.5,11.271a4.226,4.226,0,0,1-4.589,4.319c-2.9-.026-4.589-1.856-4.589-4.319,0-3.033,2.995-6.137,4.589-8.554M11.379,6.835C11.379,4.261,8.74,1.973,8.3.8A.9.9,0,0,0,6.632.8C6.2,1.973,3.556,4.261,3.556,6.835c0,4.972,7.823,4.974,7.823,0M7.468,2.808C8.352,4.028,9.6,5.494,9.6,6.835A1.937,1.937,0,0,1,7.488,8.829,1.939,1.939,0,0,1,5.335,6.835c0-1.341,1.249-2.807,2.133-4.027" transform="translate(0 0)" fill="#fff"/>
      </g>
    </svg>
  )
}

WaterEmissions.propTypes = {}

WaterEmissions.propDefs = {}

WaterEmissions.defaultProps = {}

export default WaterEmissions;