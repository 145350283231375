import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';

import { withStyles } from '@material-ui/core'

import { styles } from './styles'
import {CheckboxItemsList, DropdownInput} from "../../../components";
import {FilterSubSection} from "../ExtendedFilters/ExtendedFilters";
import ErrorsContainer from "../../../components/ErrorsContainer/ErrorsContainer";
import { buildSelectedValue } from '../../../../TransactionsMonitoring/utils';

class MultipleSelectInput extends Component {

  state = {
    value: null,
    allOptionsLength: 0
  }

  onValueChange = (value) => {
    this.setState({
      value
    }, () => {
      if (this.props.onInputChange) {
        this.props.onInputChange(this.state.value)
      }
    })
  }

  onValueApprove = () => {
    if (!this.state.value) {
      return
    }
    let items = [...this.props.value]
    if (!_.find(items, i => i.value == this.state.value || i.name == this.state.value)) {
      items.push({
        value: this.state.value,
        name: this.state.value,
        label: this.state.value,
        id: uuidv4()
      })
      this.props.onChange(items)
      this.props.onInputChange('')
    }

    this.setState({
      value: ''
    })
  }

  handleItemSelect = (item, checked, all) => {
    let items = [...this.props.value]

    if (!item) {
      items = checked ? all : [];
    } else {
      if (checked) {
        items.push(item)
      } else {
        items = _.filter(items, i => i.id !== item.id)
      }
    }

    this.props.onChange(items)
  }

  renderDropdownContent = () => {
    let {
      DropdownContentProps
    } = this.props

    if (_.isEmpty(DropdownContentProps)) {
      return null
    }

    let { loading, data, errors } = DropdownContentProps

    if(!loading && errors) {
      return <ErrorsContainer errors={errors} />
    }

    if (!loading && _.isEmpty(data)) {
      return null
    }

    const options = data && _.isArray(data) && data;
    if (options.length && !this.state.allOptionsLength) {
      this.setState({ allOptionsLength: options.length });
    }

    return (
      <CheckboxItemsList
        items={options}
        loading={loading}
        onItemSelect={this.handleItemSelect}
        selectedItems={this.props.value}
      />
    )
  }

  renderPlaceholder = () => {
    let {
      value,
      placeholder
    } = this.props;

    if (Array.isArray(value) && value.length) {
      const isAllChecked = value.length === this.state.allOptionsLength;
      return buildSelectedValue(isAllChecked, value, placeholder)
    }

    return placeholder
  }

  render() {

    const {
      title,
      icon,
      customClasses,
      onResetClick,
      resetButtonVisible,
      resetFieldsNames,
      disabled,
      value
    } = this.props

    return (
      <FilterSubSection title={(
        <div className={customClasses.filterSubSectionTitleContainer}>
          <p style={{whiteSpace: 'nowrap'}}>{ title }</p>
        </div>
      )}
        onResetClick={onResetClick}
        resetButtonVisible={!disabled && resetButtonVisible}
        resetFieldsNames={resetFieldsNames}
      >
        <DropdownInput
          dropdownVisible={false}
          value={this.state.value}
          dropdownValue={value}
          onChange={this.onValueChange}
          onBlur={() => this.onValueChange([])}
          autoApprove={false}
          disabled={disabled}
          onValueApprove={this.onValueApprove}
          inPopover
          icon={icon}
          dropdownContent={this.renderDropdownContent()}
          placeholder={this.renderPlaceholder()}
        />
      </FilterSubSection>
    );
  }
}

MultipleSelectInput.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  DropdownContentProps: PropTypes.object
};

MultipleSelectInput.defaultProps = {
  options: [],
  DropdownContentProps: {}
}

export default withStyles(styles)(MultipleSelectInput);
