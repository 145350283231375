import React from "react";
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import styles from "../styles";
import InputFormElement, {CurrencyNumberFormat} from "../../../../formElement/InputFormElement";
import SelectFormElement from "../../../../formElement/SelectFormElement";
import CalendarFormElement from "../../../../formElement/CalendarFormElement";
import CheckboxQuestion from "../../../../question/CheckboxQuestion";
import {
  getListOptionsFromBankData,
  getBankData,
  isRequiredQuestion,
  isValidValue, getRiskProfilingAnswers, skipSrriValidation
} from "../../../../../../../utils";
import {withEuroOrDash} from "../../../../../../../../../utils/utils";
import FormHelperText from "@material-ui/core/FormHelperText";
import {FIELD_REQUIRED_MSG} from "../../../../../../../constants";
import {DateQuestionMethods} from "../../../../question/DateQuestion";
import moment from "moment/moment";
import Tooltip from "../../../../../../../../../components/Tooltip";
import {InfoIcon} from "../../../../../../../../../images";
import { END_DATE_SHOULD_BE_AFTER_START, SERVICE_CONCEPTS } from '../../../../../../../../Trades/constants';

const DepositsStep = props => {
  const {
    classes,
    onAnswerChange,
    dataService,
    questions: [
      openOnlyCheckbox,
      singleInvestmentCheckbox,
      singleInvestmentInput,
      savingsPlanCheckbox,
      savingsPlanInput,
      savingsPlanRotation,
      savingsPlanFrom,
      savingsPlanTill,
      vlPlanCheckbox,
      vlPlanInput,
      vlPlanRotation,
      vlPlanFrom,
      einzeltitelCheckbox
    ],
  } = props;

  const [stepError, setStepError] = React.useState(null);

  const data = dataService.buildData()
  const depotTypeConfiguration = _.get(data, 'custodians.0.depot.selected_contract_type.configuration') || {};
  const einzeltitelEnabled = depotTypeConfiguration.einzeltitel_onboarding_enabled;
  const fundsDisabled = depotTypeConfiguration.onboarding_funds_disabled;
  const depot = dataService.getDepot();
  const bankData = getBankData(dataService.banksData, depot.bankCompanyId);
  const availableAssetClasses = (depotTypeConfiguration.hasOwnProperty('available_asset_classes')
    ? depotTypeConfiguration.available_asset_classes
    : bankData.available_asset_classes) || {}
  const vlDisabled = !bankData.onboarding_vl_plan_allowed;
  const openOnlyDisabled = !bankData.onboarding_open_only_allowed;
  const orderDisabled = !bankData.onboarding_order_possible;
  const savingsPlanDisabled = !bankData.onboarding_savings_plan_possible;
  const spRotationOptions = getListOptionsFromBankData(dataService.banksData, depot.bankCompanyId);
  const vlRotationOptions = getListOptionsFromBankData(dataService.banksData, depot.bankCompanyId, 'vl_plan_periodic_plans')
  _.set(savingsPlanRotation, 'config.choices', spRotationOptions.map(o => {
    return {id: o.value, uid: o.value, ...o}
  }));
  _.set(vlPlanRotation, 'config.choices', vlRotationOptions.map(o => {
    return {id: o.value, uid: o.value, ...o}
  }));

  const brokerLicense = dataService.user && dataService.user.bca_level_id;

  /**
   * Check, if broker has valida license,
   * that configured for selected depot's available_asset_classes.
   * @type {boolean}
   */
  const hasValidEinzeltitelLicense = (() => {

    let licenseValid = false

    Object.values(availableAssetClasses).forEach((config) => {
      if (!licenseValid) {
        licenseValid = (config.broker_license || []).includes(+brokerLicense)
      }
    })

    return licenseValid

  })()


  /**
   * Check, if knowledge and customer's srri is valid for einzeltitel.
   */
  const [hasEinzeltitelKnowledge, hasValidEinzeltitelSrri, einzeltitelMinSrri] = (() => {

    const answers = getRiskProfilingAnswers(dataService, availableAssetClasses)
    if (!answers) {
      // answers are falsy for execution only. As for exec only knowledge and srri are invalid - pass them as true
      return [true, true, undefined]
    }

    let minAcceptableSrri = 8

    Object.keys(answers).forEach((asset_class) => {
      if (answers[asset_class]) {
        minAcceptableSrri = availableAssetClasses[asset_class].min_srri < minAcceptableSrri
          ? availableAssetClasses[asset_class].min_srri
          : minAcceptableSrri
      }
    })

    const srriValid = skipSrriValidation(dataService.serviceConcept) || dataService._srri >= minAcceptableSrri

    return [_.some(Object.values(answers), Boolean), srriValid, minAcceptableSrri]

  })()

  const einzeltitelDisabled = !hasEinzeltitelKnowledge || !hasValidEinzeltitelSrri || !hasValidEinzeltitelLicense || !einzeltitelEnabled

  const isAmountValid = (minAmount) => function() {
    this.error = null; // clean error
    if (isRequiredQuestion(this)) {
      if (!isValidValue(this.answer)) {
        this.error = FIELD_REQUIRED_MSG
      } else if(parseFloat(this.answer) < parseFloat(minAmount)){
        this.error = `Mindestbetrag: ${withEuroOrDash(minAmount, null, true)}`;
      }
    }
    return !this.error;
  }

  React.useEffect(() => {
    if(depot.hasOwnProperty('onboarding_min_amount_payment')){
      singleInvestmentInput.isValid = isAmountValid(depot.onboarding_min_amount_payment)
    }

    if(depot.hasOwnProperty('onboarding_savings_plan_min_amount_payment')) {
      savingsPlanInput.isValid = isAmountValid(depot.onboarding_savings_plan_min_amount_payment)
    }
    if (depot.hasOwnProperty('onboarding_vl_plan_min_amount_payment')) {
      vlPlanInput.isValid = isAmountValid(depot.onboarding_vl_plan_min_amount_payment)
    }

    const handleFromDateValidity = function(fieldKey, date) {
      const fromDateOptions = getListOptionsFromBankData(
        dataService.banksData,
        depot.bankCompanyId,
        fieldKey);

      if (!fromDateOptions || _.isEmpty(fromDateOptions)) {
        return true
      }

      date = new Date(date);

      let availableDays = fromDateOptions.map(option => option.value);

      return !availableDays.includes(date.getDate())
    };

    const isFromDateValid = function() {
      // do not validate if not required
      return !DateQuestionMethods.required(this) || DateQuestionMethods.valid(this);
    }

    savingsPlanFrom.handleDateValidity = (date) => handleFromDateValidity('savings_plan_execution_dates', date)
    savingsPlanTill.handleDateValidity =  (date) => handleFromDateValidity('savings_plan_execution_dates', date)
    vlPlanFrom.handleDateValidity = (date) => handleFromDateValidity('vl_plan_execution_dates', date)
    savingsPlanFrom.isValid = isFromDateValid
    vlPlanFrom.isValid = isFromDateValid

    savingsPlanTill.isValid = function() {

      if (!DateQuestionMethods.valid(this)) {
        return false
      }

      if (this.answer && moment(this.answer) <= moment(savingsPlanFrom.answer)) {
        this.error = END_DATE_SHOULD_BE_AFTER_START;
        return false
      }

      return true

    }

    // initial set of required fields
    handleOpenOnlyCheckBoxChange(openOnlyCheckbox.uid, openOnlyDisabled ? false : openOnlyCheckbox.answer)
    handleSingleInvestmentCheckBoxChange(singleInvestmentCheckbox.uid, orderDisabled ? false : singleInvestmentCheckbox.answer);
    handleSavingsPlanCheckBoxChange(savingsPlanCheckbox.uid, savingsPlanDisabled ? false : savingsPlanCheckbox.answer);
    handleVLPlanCheckBoxChange(vlPlanCheckbox.uid, vlDisabled ? false : vlPlanCheckbox.answer)

    singleInvestmentCheckbox.disabled = orderDisabled;
    savingsPlanCheckbox.disabled = savingsPlanDisabled;
    vlPlanCheckbox.disabled = vlDisabled;
    openOnlyCheckbox.disabled = openOnlyDisabled;


    if (savingsPlanTill.answer && !bankData.onboarding_savings_plan_till_date_enabled) {
      savingsPlanTill.answer = null
    }

  }, []);

  React.useEffect(() => {
    onAnswerChange(einzeltitelCheckbox.uid, einzeltitelDisabled ? false : einzeltitelCheckbox.answer)
    einzeltitelCheckbox.disabled = einzeltitelDisabled
  }, [einzeltitelDisabled])

  React.useEffect(() => {

    singleInvestmentCheckbox.disabled = fundsDisabled || orderDisabled;
    savingsPlanCheckbox.disabled = fundsDisabled || savingsPlanDisabled;
    vlPlanCheckbox.disabled = fundsDisabled || vlDisabled;

    handleSingleInvestmentCheckBoxChange(singleInvestmentCheckbox.uid, singleInvestmentCheckbox.disabled
      ? false : singleInvestmentCheckbox.answer);
    handleSavingsPlanCheckBoxChange(savingsPlanCheckbox.uid, savingsPlanCheckbox.disabled
      ? false : savingsPlanCheckbox.answer);
    handleVLPlanCheckBoxChange(vlPlanCheckbox.uid, vlPlanCheckbox.disabled
      ? false : vlPlanCheckbox.answer)

  }, [fundsDisabled])

  const isStepValid = () => {
    const isValid = singleInvestmentCheckbox.answer || savingsPlanCheckbox.answer || vlPlanCheckbox.answer || openOnlyCheckbox.answer || einzeltitelCheckbox.answer;
    setStepError(isValid ? null : "Bitte geben Sie mindestens einen Betrag ein");
    return isValid;
  };

  singleInvestmentCheckbox.isValid = savingsPlanCheckbox.isValid = isStepValid;

  const handleAnswerChange = (question, answer) => {
    onAnswerChange(question, answer);
    setStepError(null); // clear step error
  };

  const handleSingleInvestmentCheckBoxChange = (question, answer) => {
    singleInvestmentCheckbox.optional = singleInvestmentInput.optional = !answer;
    handleAnswerChange(question, answer);
  };

  const handleSavingsPlanCheckBoxChange = (question, answer) => {
    savingsPlanCheckbox.optional = savingsPlanInput.optional = savingsPlanRotation.optional = savingsPlanFrom.optional = !answer;
    // clean errors
    savingsPlanCheckbox.error = savingsPlanInput.error = savingsPlanRotation.error = savingsPlanFrom.error = null;
    handleAnswerChange(question, answer);
  };

  const handleVLPlanCheckBoxChange = (question, answer) => {
    vlPlanCheckbox.optional = vlPlanInput.optional = vlPlanRotation.optional = vlPlanFrom.optional = !answer;
    vlPlanCheckbox.error = vlPlanInput.error = vlPlanRotation.error = vlPlanFrom.error = null;
    handleAnswerChange(question, answer);

    if (!answer){
      return
    }

    // Set fields to disabled and optional according to bank configuration
    vlPlanRotation.disabled = vlPlanRotation.optional = _.isEmpty(bankData.vl_plan_periodic_plans)
    vlPlanFrom.disabled = vlPlanFrom.optional = _.isEmpty(bankData.vl_plan_execution_dates)

    // Clean other questions only if VL Plan
    openOnlyCheckbox.answer = null
    savingsPlanCheckbox.optional = savingsPlanInput.optional = savingsPlanRotation.optional = savingsPlanFrom.optional = true
    savingsPlanCheckbox.answer = savingsPlanInput.answer = savingsPlanRotation.answer = savingsPlanFrom.answer = savingsPlanTill.answer = null
    singleInvestmentCheckbox.optional = singleInvestmentInput.optional = true
    singleInvestmentCheckbox.answer = singleInvestmentInput.answer = null
    einzeltitelCheckbox.answer = null
    // clean errors
    savingsPlanCheckbox.error = savingsPlanInput.error = savingsPlanRotation.error = savingsPlanFrom.error = savingsPlanTill.error = null
    singleInvestmentCheckbox.error = singleInvestmentInput.error = null
    openOnlyCheckbox.error = null
  };

  const handleOpenOnlyCheckBoxChange = (question, answer) => {
    openOnlyCheckbox.answer = answer;
    openOnlyCheckbox.error = null;
    handleAnswerChange(question, answer);

     // Clean other questions if Open only
    if (!answer){
      dataService.addInstrumentsSteps()
      return
    }

    savingsPlanCheckbox.optional = savingsPlanInput.optional = savingsPlanRotation.optional = savingsPlanFrom.optional = true
    savingsPlanCheckbox.answer = savingsPlanInput.answer = savingsPlanRotation.answer = savingsPlanFrom.answer = savingsPlanTill.answer = null
    singleInvestmentCheckbox.optional = singleInvestmentInput.optional = true
    singleInvestmentCheckbox.answer = singleInvestmentInput.answer = null
    einzeltitelCheckbox.answer = null
    vlPlanCheckbox.optional = vlPlanInput.optional = vlPlanRotation.optional = vlPlanFrom.optional = true
    vlPlanCheckbox.answer = vlPlanInput.answer = vlPlanRotation.answer = vlPlanFrom.answer = null
    // clean errors
    savingsPlanCheckbox.error = savingsPlanInput.error = savingsPlanRotation.error = savingsPlanFrom.error = savingsPlanTill.error = null
    singleInvestmentCheckbox.error = singleInvestmentInput.error = null
    vlPlanCheckbox.error = vlPlanInput.error = vlPlanRotation.error = vlPlanFrom.error = null

    dataService.removeInstrumentsSteps()
  };

  const tooltipEinzeltitelMinSrri = hasEinzeltitelKnowledge ? einzeltitelMinSrri : 3

  return (
    <>
      { stepError && (
        <FormHelperText
          error={true}
          classes={{
            error: classes.errorContainer
          }}>{ stepError }</FormHelperText>
      )}
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <CheckboxQuestion
            question={openOnlyCheckbox}
            onAnswerChange={handleOpenOnlyCheckBoxChange}
            disabled={
              openOnlyCheckbox.disabled ||
              vlPlanCheckbox.answer ||
              singleInvestmentCheckbox.answer ||
              savingsPlanCheckbox.answer
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <CheckboxQuestion
            question={singleInvestmentCheckbox}
            onAnswerChange={handleSingleInvestmentCheckBoxChange}
            disabled={singleInvestmentCheckbox.disabled || vlPlanCheckbox.answer || openOnlyCheckbox.answer}
          />
          <InputFormElement
            name={singleInvestmentInput.uid}
            value={singleInvestmentInput.answer || ''}
            inputComponent={CurrencyNumberFormat}
            onChange={(value) => {
              singleInvestmentInput.error = null; // clean error
              handleAnswerChange(singleInvestmentInput.uid, value)
            }}
            error={singleInvestmentInput.error}
            disabled={!singleInvestmentCheckbox.answer || vlPlanCheckbox.answer || openOnlyCheckbox.answer}
            required={isRequiredQuestion(singleInvestmentInput)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <CheckboxQuestion
            question={savingsPlanCheckbox}
            onAnswerChange={handleSavingsPlanCheckBoxChange}
            custom_classes={{root: classes.mb_0}}
            disabled={savingsPlanCheckbox.disabled || vlPlanCheckbox.answer || openOnlyCheckbox.answer}
          />
          <InputFormElement
            name={savingsPlanInput.uid}
            value={savingsPlanInput.answer || ''}
            inputComponent={CurrencyNumberFormat}
            onChange={(value) => {
              savingsPlanInput.error = null; // clean error
              handleAnswerChange(savingsPlanInput.uid, value)
            }}
            disabled={!savingsPlanCheckbox.answer || vlPlanCheckbox.answer || openOnlyCheckbox.answer}
            required={isRequiredQuestion(savingsPlanInput)}
            error={savingsPlanInput.error}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectFormElement
            name={savingsPlanRotation.uid}
            label={savingsPlanRotation.question_text}
            value={savingsPlanRotation.answer || ''}
            disabled={!savingsPlanCheckbox.answer || vlPlanCheckbox.answer || openOnlyCheckbox.answer}
            options={savingsPlanRotation.config.choices || []}
            onChange={(value) => {
              savingsPlanRotation.error = null; // clean error
              handleAnswerChange(savingsPlanRotation.uid, value)
            }}
            required={isRequiredQuestion(savingsPlanRotation)}
            error={savingsPlanRotation.error}
            custom_classes={{
              textFieldRoot: classes.textFieldRoot,
              labelRoot: classes.labelRoot
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <CalendarFormElement
            name={savingsPlanFrom.uid}
            label={savingsPlanFrom.question_text}
            value={savingsPlanFrom.answer || null}
            disabled={!savingsPlanCheckbox.answer || vlPlanCheckbox.answer || openOnlyCheckbox.answer}
            onChange={(value) => {
              savingsPlanFrom.error = null; // clean error
              handleAnswerChange(savingsPlanFrom.uid, value)
            }}
            required={isRequiredQuestion(savingsPlanFrom)}
            minDate={savingsPlanFrom.min_date}
            disableDatesCallback={savingsPlanFrom.handleDateValidity}
            error={savingsPlanFrom.error}
            custom_classes={{
              textFieldRoot: classes.textFieldRoot,
              labelRoot: classes.labelRoot
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <CalendarFormElement
            name={savingsPlanTill.uid}
            label={(<>
                <span>{savingsPlanTill.question_text}</span>
                {!bankData.onboarding_savings_plan_till_date_enabled && (
                  <Tooltip title={"Bei dieser Depotbank nicht verfügbar"}>
                    <span className={classes.infoIcon}>
                      <InfoIcon color={"rgba(0, 0, 0, 0.2)"}/>
                    </span>
                  </Tooltip>
                )}
            </>
            )}
            value={savingsPlanTill.answer || null}
            disabled={!savingsPlanCheckbox.answer || vlPlanCheckbox.answer || openOnlyCheckbox.answer || !bankData.onboarding_savings_plan_till_date_enabled}
            onChange={(value) => {
              savingsPlanTill.error = null; // clean error
              handleAnswerChange(savingsPlanTill.uid, value)
            }}
            required={false}
            minDate={moment(savingsPlanFrom.answer).add(1,'days')}
            disableDatesCallback={savingsPlanTill.handleDateValidity}
            error={savingsPlanTill.error}
            custom_classes={{
              textFieldRoot: classes.textFieldRoot,
              labelRoot: classes.labelRoot
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <CheckboxQuestion
            question={vlPlanCheckbox}
            onAnswerChange={handleVLPlanCheckBoxChange}
            custom_classes={{root: classes.mb_0_vl}}
            disabled={vlPlanCheckbox.disabled || openOnlyCheckbox.answer}
          />
          <InputFormElement
            name={vlPlanInput.uid}
            value={vlPlanInput.answer || ''}
            inputComponent={CurrencyNumberFormat}
            onChange={(value) => {
              vlPlanInput.error = null; // clean error
              handleAnswerChange(vlPlanInput.uid, value)
            }}
            disabled={!vlPlanCheckbox.answer || openOnlyCheckbox.answer}
            required={isRequiredQuestion(vlPlanInput)}
            error={vlPlanInput.error}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectFormElement
            name={vlPlanRotation.uid}
            label={vlPlanRotation.question_text}
            value={vlPlanRotation.answer || ''}
            disabled={!vlPlanCheckbox.answer || vlPlanRotation.disabled || openOnlyCheckbox.answer}
            options={vlPlanRotation.config.choices || []}
            onChange={(value) => {
              vlPlanRotation.error = null; // clean error
              handleAnswerChange(vlPlanRotation.uid, value)
            }}
            required={isRequiredQuestion(vlPlanRotation)}
            error={vlPlanRotation.error}
            custom_classes={{
              root: classes.vlRoot,
              textFieldRoot: classes.textFieldRoot,
              labelRoot: classes.vlLabelRoot
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <CalendarFormElement
            name={vlPlanFrom.uid}
            label={vlPlanFrom.question_text}
            value={vlPlanFrom.answer || null}
            disabled={!vlPlanCheckbox.answer || vlPlanFrom.disabled || openOnlyCheckbox.answer }
            onChange={(value) => {
              vlPlanFrom.error = null; // clean error
              handleAnswerChange(vlPlanFrom.uid, value)
            }}
            required={isRequiredQuestion(vlPlanFrom)}
            minDate={vlPlanFrom.min_date}
            disableDatesCallback={vlPlanFrom.handleDateValidity}
            error={vlPlanFrom.error}
            custom_classes={{
              root: classes.vlRoot,
              textFieldRoot: classes.textFieldRoot,
              labelRoot: classes.vlLabelRoot
            }}
          />
          {_.isEmpty(bankData.vl_plan_execution_dates) && vlPlanCheckbox.answer && (
            <FormHelperText>Bei {bankData.custodian_name || depot.depot} können Sie kein Startdatum festlegen.</FormHelperText>
          )}
        </Grid>
        {vlDisabled && (
          <Grid item xs={12}>
            <FormHelperText>Die gewählte Depotbank unterstützt diese Option aktuell nicht.</FormHelperText>
          </Grid>
        )}
      </Grid>

      {hasValidEinzeltitelLicense && (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <CheckboxQuestion
              question={einzeltitelCheckbox}
              onAnswerChange={onAnswerChange}
              custom_classes={{root: classes.mb_0}}
              disabled={einzeltitelCheckbox.disabled || openOnlyCheckbox.answer}
            />
          </Grid>
          {einzeltitelDisabled && (
            <Grid item xs={12} style={{paddingTop: 0}}>
              <FormHelperText>Die Bedingungen für den Erwerb von Einzeltiteln sind nicht erfüllt. Diese können sein:  Depotbankauswahl, Wertpapierkenntnisse oder mind.{!_.isNil(tooltipEinzeltitelMinSrri) && ` Risikoklasse ${tooltipEinzeltitelMinSrri} des Kunden.`}</FormHelperText>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default withStyles(styles)(DepositsStep);
