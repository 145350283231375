import {GA_PROJECTS, TIMING_NAMES} from "./constants";
import TagManager from 'react-gtm-module';

function gaData() {
  const origin = window.location.origin;
  const projectKey = Object.keys(GA_PROJECTS).find(key => origin.includes(GA_PROJECTS[key].url));
  const project = projectKey && GA_PROJECTS[projectKey] || GA_PROJECTS.LOCAL;
  return [project.gaID, project.appName];
}

function gtag(){
  trackEvent(arguments);
}

function trackEvent(event){
  if (!window.dataLayer) {
    window.dataLayer = []
  }
  window.dataLayer.push(event);
}

class GoogleAnalyticsInstance {
  constructor() {
    const [id, name] = gaData();
    this._gaID = id;
    this._appName = name;
  }

  initialize() {
    TagManager.initialize({
      gtmId: this._gaID,
      dataLayer: {
        app_name: this._appName,
      }
    })
  }

  trackPage(path, title) {
    gtag('config', this._gaID, {
      'page_title' : title,
      'page_path': path
    });
  }

  _trackPortfolioBuilder(label, category="depot_opening") {
    trackEvent({
      "event": "link_click",
      "action_name": "Investment",
      "action_label": label,
      "action_category": category
    })
  }

  trackPortfolioBuilderOpen(category){
    this._trackPortfolioBuilder("Portfolio builder", category)
  }

  trackPortfolioBuilderResults(){
    this._trackPortfolioBuilder("Anlagevorschlag übernehmen")
  }

  trackDashboardPageLoadTime(value, is_broker, customer_id) {
    const event_category = is_broker ? 'broker_app': 'customer_app';
    this.trackTime(TIMING_NAMES.DASHBOARD_PAGE_LOAD, value, event_category, customer_id);
  }

  trackDataFetchTime(value, is_broker, path) {
    const event_category = is_broker ? 'broker_app': 'customer_app';
    this.trackTime(TIMING_NAMES.DATA_FETCH, value, event_category, path);
  }

  trackTime(name, value, event_category, event_label) {
    gtag('event', 'timing_complete', {
      name,
      value,
      event_category,
      event_label
    });
  }
}

export default new GoogleAnalyticsInstance();
