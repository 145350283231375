import React from 'react'
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';

const CustomerCard = (props) => {
  const classes = useStyles();
  const {
    customerFullName,
    customerIdentifier,
  } = props;

  return (
    <div className={classes.wrapper}>
      <Typography
        classes={{ root: classes.userName }}
        variant="h2"
        display="inline"
      >
        {customerFullName}
      </Typography>
      <Typography
        classes={{ root: classes.userId }}
        display="inline"
      >
        Kundennr.: {customerIdentifier}
      </Typography>
    </div>
  )
}

CustomerCard.defaultProps = {
  customerFullName: '',
  customerIdentifier: '',
}

CustomerCard.propTypes = {
  customerFullName: PropTypes.string,
  customerIdentifier: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};

export default CustomerCard;
