import {FactSheetsHandlerResource, ModelPortfolioResource, PortfolioHandlerResource} from "../../../utils/api";
import _ from "lodash";
import React from "react";
import {FETCH_DATA_INITIAL_STATE} from "../../../hooks/constants";
import {PRODUCT_TYPE} from "../constants";
import {splitProducts} from "./useYearlyPerformanceData";
import {
  getProductCustomerId,
  getProductIdsAsString,
  getProductsDataListRepresentation, getProductsToUseAsDict,
  updateTabsDataWithCurrentProductConfig
} from "../utils";


function parseResponse(response, responseKey, error=undefined) {
  const _error = error || _.get(response, `${responseKey}.error`);
  const data = _.get(response, `${responseKey}.data`) || {};

  return [data, _error]
}

function _processProductsData(product, response, error, responseKey) {
  const [data, _error] = parseResponse(response, responseKey, error)
  product[responseKey] = _error ? {"error": _error} : data
}

export function processProductsData(products, response, responseKey) {
  const [data, error] = parseResponse(response, responseKey)

  products.forEach((product) => {
    _processProductsData(product, data[product.data.isin], error, responseKey);
  })
}

async function _fetchAssetsProductsData(products, fetchDataCallback, dataKey) {
  try {
    const response = await fetchDataCallback();
    processProductsData(products, response, dataKey)
  } catch (error) {
    products.forEach((product) => {
      product[dataKey].error = error;
    })
  }
}

async function fetchPerformanceIndicatorsData(products, startDate, endDate, selectedBenchmarks) {

  await _fetchAssetsProductsData(products, async () => {
    return await FactSheetsHandlerResource.getAnalyseV2PerformanceIndicatorsData(
      undefined, startDate, endDate,
      products.map((product) => _.get(product, 'data.isin')),
      JSON.stringify(selectedBenchmarks)
    );
  }, 'performance')
}

async function fetchRiskMetricsHistoricalData(products, startDate, endDate) {
  await _fetchAssetsProductsData(products, async () => {
    return await FactSheetsHandlerResource.getAnalyseRiskMetricsHistoricalData(
      undefined, startDate, endDate, products.map((product) => _.get(product, 'data.isin')));
  }, 'historical')
}

async function fetchPerformanceTimeseriesReturnData(products, startDate, endDate) {
  await _fetchAssetsProductsData(products, async () => {
    return await FactSheetsHandlerResource.getAnalyseV2PerformanceTimeseriesReturn(
      undefined, startDate, endDate, products.map((product) => _.get(product, 'data.isin')));
  }, 'return')
}


async function fetchInvestmentStrategyRiskMetricsData(product, investmentStrategy, startDate, endDate) {
  return fetchMusterdepotRiskMetricsData(product, investmentStrategy.related_model_portfolio, startDate, endDate, investmentStrategy.id)
}

async function _fetchProductRiskMetricsData(product, fetchDataCallback) {
  try {
    const response = await fetchDataCallback();
    const data = _.get(response, 'risk.data.0.volatility.risk_metrics') || {};
    const error = _.get(response, 'risk.errors');

    product.historical = !_.isEmpty(error) ? {"error": error} : data
  } catch (error) {
    product.historical = {error}
  }
}

async function fetchMusterdepotRiskMetricsData(product, musterdepot, startDate, endDate, investmentStrategyId) {
  await _fetchProductRiskMetricsData(product, async () => {
    return await ModelPortfolioResource.getRiskData(
      musterdepot.id, undefined, startDate, endDate,
      undefined, undefined, undefined, investmentStrategyId);
  })
}

async function fetchPortfolioRiskMetricsData(product, portfolio, startDate, endDate) {

  const customerId = getProductCustomerId(product);

  const depotId = _.get(product, 'configuration.depot_id');

  await _fetchProductRiskMetricsData(product, async () => {
    return await PortfolioHandlerResource.getRiskData(
      customerId, depotId, startDate, endDate);
  })

}

export async function fetchMusterdepotPortfolioData(musterdepot, investmentStrategyId, startDate, endDate) {
  return await ModelPortfolioResource.getPortfolioData(
    musterdepot.id, undefined, startDate, endDate, undefined,
    undefined, investmentStrategyId)
}

/**
 * Retrieve portfolio id (id of portfolio, that we store in Connect) for musterdepot.
 */
export async function fetchMusterdepotPortfolioId(portfolioData) {
  try {

    const portfolioId = _.get(portfolioData, 'financial.data.0.data.portfolios.0.id');
    if (!portfolioId) {
      return [undefined, ['Musterdepot does not have portfolio id.']]
    }

    return [portfolioId, undefined]
  } catch (errors) {
    return [undefined, errors]
  }

}

async function fetchInvestmentStrategyKeyIndicatorsData(product, investmentStrategy, startDate, endDate, selectedBenchmarks) {
  return fetchMusterdepotKeyIndicatorsData(product, investmentStrategy.related_model_portfolio, startDate, endDate, selectedBenchmarks, investmentStrategy.id)
}

function getReturnValue(returnValue) {
  return _.isNumber(returnValue) && returnValue || undefined
}

function updatePortfolioLikeProductWithReturnData(product, portfolioData) {
  const financialDataErrors = _.get(portfolioData, 'financial.data.errors')
  if (!_.isEmpty(financialDataErrors)) {
    product.return = {"error": financialDataErrors}
  } else {
    const return_value = _.get(portfolioData, 'financial.data.0.data.ydt.percentage');
    const return_pa_value = _.get(portfolioData, 'financial.data.0.data.ydt.percentage_pa');
    product.return = {
      return: getReturnValue(return_value),
      return_pa: getReturnValue(return_pa_value),
    }
  }
}

async function updatePortfolioLikeProductWithKeyIndicatorsData(Resource, product, customerId, portfolioId, startDate, endDate, investmentStrategyId, portfolioIds, selectedBenchmarks) {

  try {
    const response = await Resource.getKeyIndicatorsData(
      customerId, portfolioId, startDate, endDate, selectedBenchmarks || [], undefined, investmentStrategyId, portfolioIds);
    const data = _.get(response, 'indicators_sharpe_ratio.data.0.indicators') || {};
    const _errors = _.get(response, 'indicators_sharpe_ratio.errors');
    product.performance = !_.isEmpty(_errors) ? {"error": _errors} : data
  } catch (error) {
    product.performance = {error}
  }

}

async function fetchMusterdepotKeyIndicatorsData(product, musterdepot, startDate, endDate, selectedBenchmarks, investmentStrategyId) {

  try {
    const portfolioData = await fetchMusterdepotPortfolioData(musterdepot, investmentStrategyId, startDate, endDate);
    let [portfolioId, errors] = await fetchMusterdepotPortfolioId(portfolioData);
    if (!errors) {
      await updatePortfolioLikeProductWithKeyIndicatorsData(
        ModelPortfolioResource, product, musterdepot.id, portfolioId, startDate, endDate, investmentStrategyId, undefined, selectedBenchmarks)
    } else {
      product.performance = {"error": errors}
    }

    updatePortfolioLikeProductWithReturnData(product, portfolioData)
  } catch (error) {
    product.return = {error}
    product.performance = {error}
  }

}

async function fetchPortfolioKeyIndicatorsData(product, portfolio, startDate, endDate, selectedBenchmarks) {

  const customerId = getProductCustomerId(product);

  const depotId = _.get(product, 'configuration.depot_id');

  await updatePortfolioLikeProductWithKeyIndicatorsData(
    PortfolioHandlerResource, product, customerId, undefined, startDate, endDate,
    undefined, product.configuration.depot_id, selectedBenchmarks)

  try {
    const portfolioData = await PortfolioHandlerResource.getPortfolioData(
      customerId, depotId, startDate, endDate)
    updatePortfolioLikeProductWithReturnData(product, portfolioData)
  } catch (error) {
    product.return = {error}
  }

}

function useMetricsData(products, dateRange, selectedBenchmarks, performanceData) {

  const [data, setData] = React.useState(FETCH_DATA_INITIAL_STATE);

  const [productsToUse, setProductsToUse] = React.useState([]);

  React.useEffect(() => {
    if (!_.isEmpty(products) && !_.isEmpty(dateRange)) {
      setProductsToUse([...products]);
    }

  // triggered when selected products or date range are changed
  }, [getProductIdsAsString(products), dateRange.start.format(), dateRange.end.format()])

  React.useEffect(() => {
    // use effect to update configuration of the products in data
    updateTabsDataWithCurrentProductConfig(data, products, setData)
  }, [products])

  React.useEffect(() => {
    if (!_.isEmpty(productsToUse)) {
      fetchKenzahlenData()
    }
  }, [productsToUse, JSON.stringify(selectedBenchmarks)]);

  React.useEffect(() => {
    if (performanceData.loading || data.loading || !data.data || !performanceData.data) {
      return
    }

    for (let [productId, product] of Object.entries(data.data)) {
      const productPerformanceData = (performanceData.data || []).find((item) => _.get(item, 'product_id') == productId) || {};
      if (!productPerformanceData.hasOwnProperty('return') && !productPerformanceData.hasOwnProperty('return_pa')) {
        continue
      }

      product.time_weighted_return = {
        return: productPerformanceData.return,
        return_pa: productPerformanceData.return_pa
      }
    }

    setData((current) => ({...current, data: data.data}))

  }, [performanceData.updatedAt, data.updatedAt])

  const fetchKenzahlenData = async () => {
    setData({
      data: null,
      loading: true,
      errors: null,
      updatedAt: +new Date()
    })
    try {

      const [productsAssets, productsOther] = splitProducts(products);
      const [startDate, endDate] = [dateRange.start.format('YYYY-MM-DD'), dateRange.end.format('YYYY-MM-DD')]
      const handlers = productsAssets.length ? [
        fetchPerformanceIndicatorsData(productsAssets, startDate,endDate, selectedBenchmarks),
        fetchRiskMetricsHistoricalData(productsAssets, startDate, endDate),
        fetchPerformanceTimeseriesReturnData(productsAssets, startDate, endDate)] : []

      productsOther.forEach((product) => {
        handlers.push({
          [PRODUCT_TYPE.PRIVATE_INVESTMENT]: fetchInvestmentStrategyRiskMetricsData,
          [PRODUCT_TYPE.MODEL_PORTFOLIO]: fetchMusterdepotRiskMetricsData,
          [PRODUCT_TYPE.MUSTERDEPOT]: fetchMusterdepotRiskMetricsData,
          [PRODUCT_TYPE.CUSTOMER_PORTFOLIO]: fetchPortfolioRiskMetricsData
        }[product.type](product, product.data, startDate,endDate));
        handlers.push({
          [PRODUCT_TYPE.PRIVATE_INVESTMENT]: fetchInvestmentStrategyKeyIndicatorsData,
          [PRODUCT_TYPE.MODEL_PORTFOLIO]: fetchMusterdepotKeyIndicatorsData,
          [PRODUCT_TYPE.MUSTERDEPOT]: fetchMusterdepotKeyIndicatorsData,
          [PRODUCT_TYPE.CUSTOMER_PORTFOLIO]: fetchPortfolioKeyIndicatorsData
        }[product.type](product, product.data, startDate,endDate, selectedBenchmarks));
      });

      let result = await Promise.all(handlers);

      setData({
        data: getProductsToUseAsDict(productsToUse),
        loading: false,
        errors: null,
        updatedAt: +new Date()
      })
    } catch (errors) {
      setData({
        data: null,
        loading: false,
        errors,
        updatedAt: +new Date()
      })
    }
  }

  return getProductsDataListRepresentation(data);
}

export default useMetricsData;