import { makeStyles } from "@material-ui/core";

const styles = (theme) => ({
  profileRiskContainer: {},
  profileRiskTitle: {
    fontSize: 14,
    margin: 0,
    fontFamily: 'Roboto-Regular',
    color: '#4D4F5C',
  },
  value: {
    margin: 0,
    marginTop: 15,
    fontFamily: 'Roboto-Bold',
    fontSize: 28,
    color: '#4D4F5C',

    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    }
  },
})

export default makeStyles(styles)