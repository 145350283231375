import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import clsx from 'clsx'
import {Select as MaterialUISelect, MenuItem, FormControl, FormControlLabel} from '@material-ui/core';
import useStyles from './styles';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";

function Select(props) {

  const {
    options,
    style,
    value,
    onChange,
    width,
    withDefault,
    disabled,
    labelId,
    postNameContent,
    name,
    loading
  } = props;

  const classes = useStyles();

  return (
    <FormControl fullWidth={props.fullWidth}>
      <FormControlLabel
        labelPlacement={"top"}
        classes={{
          root: classes.formControlLabel_root,
          label: classes.formControlLabel_label,
          disabled: classes.selectDisabled
        }}
        control={(
          <MaterialUISelect
            labelId={labelId}
            variant="outlined"
            className={classes.selectRoot}
            classes={{
              selectMenu: classes.selectMenu,
            }}
            MenuProps={{
              classes: {
                paper: classes.selectMenuPaper,
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
            IconComponent={ExpandMoreIcon}
            style={{width: props.width || '100%', ...props.selectStyles}}
            value={value}
            multiple={_.isArray(value)}
            disabled={disabled}
            onChange={(event) => onChange(event.target.value)}
            renderValue={loading ? () => <Skeleton style={{width: '75%', height: 35}} /> : undefined}
          >
            {withDefault &&
              <MenuItem
                disableRipple
                disableTouchRipple
                classes={{
                  root: classes.selectMenuItemRoot
                }} value="">-</MenuItem>
            }

            {options.map(option => (
              <MenuItem
                disableRipple
                disableTouchRipple
                key={option.id}
                value={option.id}
                disabled={option.disabled}
                classes={{
                  root: clsx(classes.selectMenuItemRoot, option.id == value && classes.selectMenuItemRoot_selected, option.depth === 1 && 'bold')
                }}
                style={{
                  paddingLeft: option.depth ? option.depth * 10 + 17 : 17,
                }}
              >
                { option.name }
                { postNameContent && postNameContent(option) }
              </MenuItem>
            ))}
          </MaterialUISelect>
        )}
        label={name}/>
    </FormControl>
  )
}

Select.propTypes = {
  /** Available options */
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired
  })),

  fullWidth: PropTypes.bool
}

Select.defaultProps = {
  options: [],
  fullWidth: false,
  withDefault: true,
  disabled: false
}

export default Select

