import _ from 'lodash';

export default (page, group) => (step) => {
  let page_progress = localStorage.getItem(page);
  if (!_.isNil(page_progress)) {
    page_progress = JSON.parse(page_progress);

    let is_updated = false;
    
    for (let key in page_progress) {
      if (key == group) {
        page_progress[key] = step > page_progress[key] ? step : page_progress[key];
        is_updated = true;
        break;
      }
    }
    if (!is_updated) {
      page_progress[group] = step;
    }

    localStorage.setItem(page, JSON.stringify(page_progress));

  } else {
    let body = {
      [group]: step
    };
    body = JSON.stringify(body);
    localStorage.setItem(page, body);
  }
}