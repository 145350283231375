import React from 'react';
import _ from 'lodash';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,
  TableFooter,
  Button
} from '@material-ui/core';

import Pagination from '../../../../components/Pagination/Pagination';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';

import useStyles from './styles';
import { withEuroOrDash } from '../../../../utils/utils';
import CustomerFullnameMenu from "../../../../components/CustomerFullnameMenu/CustomerFullnameMenu";

export const ACTION_TYPE = {
  CREATE: 1,
  ADD: 2,
  REMOVE: 3
};


const CustomersList = ({customers, loading, selected, handleCustomerSelected, handleAllSelected, actionType, onCustomersMark}) => {
  const classes = useStyles();

  const [currentPage, setCurrentPage] = React.useState(0);
  const [currentPageInput, setCurrentPageInput] = React.useState(undefined);

  const handleUpdatePageClick = () => {
    let pageNumber = currentPageInput - 1;

    if (pageNumber > customers.length - 1) {
      pageNumber = customers.length - 1;
    } else if (pageNumber < 0) {
      pageNumber = 0;
    }
    setCurrentPage(pageNumber);
    setCurrentPageInput(pageNumber + 1);
  };

  const getWithoutGroupMessage = () => {
    return `${getCustomersCount()} Kunde${getCustomersCount() > 1 ? 'n' : ''} entsprechen Ihren Suchkriterien, wurden jedoch noch keinen Gruppen hinzugefügt.`
  };

  const getInGroupMessage = () => {
    return `Sie haben ${getCustomersCount()} Kunde${getCustomersCount() > 1 ? 'n' : ''} in dieser Gruppe.`
  };

  const renderControlRow = () => {
    if (loading) {
      return (
        <TableRow className={classes.controlRow}>
          <TableCell>
          </TableCell>
          <TableCell colSpan={4} style={{paddingTop: 15, paddingBottom: 15}}>
            {(actionType && (actionType === ACTION_TYPE.CREATE || actionType === ACTION_TYPE.ADD)) && (
              "Lade Ergebnisse, die Ihren Filter- und Suchkriterien entsprechen."
            )}
          </TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow className={classes.controlRow}>
          <TableCell>
            <FormControlLabel
              classes={{
                label: classes.controlRowCheckboxLable
              }}
              label="Alle markieren"
              control={ <Checkbox checked={selected.length === getCustomersCount()}
              color="primary"
              onChange={handleAllSelected}/>}
            />
          </TableCell>
          <TableCell colSpan={3}>
            {(actionType && (actionType === ACTION_TYPE.CREATE || actionType === ACTION_TYPE.ADD)) && (
              <>{getWithoutGroupMessage()}</>
            )}
            {(actionType && actionType === ACTION_TYPE.REMOVE) && (
              <>{getInGroupMessage()}</>
            )}
          </TableCell>
        </TableRow>
      );
    }
  };

  const renderCustomersLoading = () => {
    const rows = [];
    for (let i = 0; i < 8; i++) {
      rows.push((
        <TableRow key={i}>
          <TableCell />
          <TableCell>
            <div className={classes.loadingLine} />
          </TableCell>
          <TableCell>
            <div className={classes.loadingLine} />
          </TableCell>
          <TableCell>
            <div className={classes.loadingLine} />
          </TableCell>
        </TableRow>
      ))
    }
    return rows;
  };

  const getCustomersCount = () => {
    if (!_.isNil(customers)) {
      return customers.reduce((count=0, page) => count + page.length, 0)
    }
  }

  const renderCustomers = () => {

    const isCustomerSelected = (customer) => {
      let isSelected = _.find(selected, (item) => item.customer_id === customer.customer_id);

      return !_.isNil(isSelected);
    }

    if (!_.isNil(customers)) {
      if (currentPage < customers.length) {
        return customers[currentPage].map(customer => (
          <TableRow className={classes.customerDetailRow} key={customer.customer_id}>
            <TableCell>
              <FormControlLabel
                control={<Checkbox
                  checked={isCustomerSelected(customer)}
                  color="primary"
                  onChange={() => handleCustomerSelected(customer)}/>}
              />
            </TableCell>
            <TableCell className={classes.tableCellBold}>
              <CustomerFullnameMenu
                customer={customer}
                customerFullname={customer.customer_full_name}
              />
            </TableCell>
            <TableCell>{customer.customer_id}</TableCell>
            <TableCell>{withEuroOrDash(customer.aggregated_portfolio_value && parseFloat(customer.aggregated_portfolio_value))}</TableCell>
          </TableRow>
        ))
      }
    }
    return null;
  }

  return (
    <div className={classes.container}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>Zur Gruppe <br /> hinzufügen</TableCell>
            <TableCell>KUNDENNAME</TableCell>
            <TableCell>KUNDENNUMMER</TableCell>
            <TableCell>investierten <br /> Vermögen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          renderControlRow()
        }
        { loading ? (
          <>
            {renderCustomersLoading()}
          </>
        ) : (
          <>
            {renderCustomers()}
          </>
        )
        }
        </TableBody>
        {(customers && customers.length > 1) && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4}>
                <div className={classes.paginationSection}>
                  <p>Seite {currentPage + 1} von {customers.length}</p>
                  <Pagination
                    currentPage={currentPage}
                    totalPageCount={customers.length}
                    handlePageChanged={(selected) => setCurrentPage(selected)}
                    justifyContent="flex-start"
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4}>
                <PrimaryButton
                  text="Markierte Kunden zur Gruppe hinzufügen"
                  onButtonClick={onCustomersMark}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </div>
  )
}

export default CustomersList;