export const ASSET_MODAL_OPENED = 'ASSET_MODAL_OPENED';
export const ASSET_MODAL_CLOSED = 'ASSET_MODAL_CLOSED';
export const ASSET_MODAL_CONTENT_TYPE = 'ASSET_MODAL_CONTENT_TYPE';
export const ASSET_MODAL_MENU_ENABLED = 'ASSET_MODAL_MENU_ENABLED';
export const ASSET_MODAL_MENU_DISABLED = 'ASSET_MODAL_MENU_DISABLED';

export const ASSET_MODAL_TYPE = {
  MENU: 0,
  EQUITY: 1,
  IMMOBILIEN: 4,
  INSURANCE: 2,
  CASH: 3,
  EXTERNAL: 5
};

export const EQUITY_CATEGORIES = [
  {
    id: 1,
    label: 'Direktinvestment'
  }, {
    id: 2,
    label: 'Energiefonds'
  }, {
    id: 3,
    label: 'Flugzeugfonds'
  }, {
    id: 4,
    label: 'Immobilienfonds'
  }, {
    id: 5,
    label: 'Infrastrukturfonds'
  }, {
    id: 6,
    label: 'Medienfonds'
  }, {
    id: 7,
    label: 'Mikrofinanzfonds'
  }, {
    id: 8,
    label: 'Private Equity'
  }, {
    id: 9,
    label: 'Fonds'
  }, {
    id: 10,
    label: 'Schiffsfonds'
  }, {
    id: 11,
    label: 'Sonstige Spezialfonds'
  }, {
    id: 12,
    label: 'Umweltfonds'
  }, {
    id: 13,
    label: 'Waldfonds'
  }, {
    id: 14,
    label: 'Zweitmarktfonds'
  }
];

export const INSURANCE_TYPES = [
  {
    id: 1,
    label: 'Rentenversicherung'
  }, {
    id: 2,
    label: 'Lebensversicherung'
  }
];

export const REAL_ESTATE_TYPES =[
  {
    id: 1,
    label: 'Eigentumswohnung'
  },  {
    id: 2,
    label: 'Einfamilienhaus'
  },  {
    id: 3,
    label: 'Doppelhaushälfte'
  },  {
    id: 4,
    label: 'Zweifamilienhaus'
  },  {
    id: 5,
    label: 'Reiheneckhaus'
  },  {
    id: 6,
    label: 'Reihenmittelhaus'
  },  {
    id: 7,
    label: 'Mehrfamilienhaus'
  },  {
    id: 8,
    label: 'Wohn- und Geschäftshaus'
  },  {
    id: 9,
    label: 'nur Grundstück'
  },
];

export const REAL_ESTATE_USAGE_TYPES = [
  {
    id: 1,
    label: 'Eigennutzung'
  },  {
    id: 2,
    label: 'Vermietung'
  },  {
    id: 3,
    label: 'Eigennutzung und Vermietung'
  }
];
