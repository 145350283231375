import React from "react";
import {ESGProfileService} from "../../RiskProfiling/esg_profile_service";


export class ESGMenuItem extends ESGProfileService {
  constructor(uid, name, customer_id, next_btn, success_text) {
    super(uid, name, next_btn, success_text);
    this._customer_id = customer_id;
  }

  async __setQuestionnaire(onboardingAnswers, initOnly){
    // clean step data
    this._step = null;
    this._isFirstStep = false;
    this._isLastStep = false;
    this._progress = 0;
    this._is_finished = false;

    await super.__setQuestionnaire(onboardingAnswers, initOnly);

  }
}