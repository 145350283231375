import React from 'react';
import Paginate from 'react-paginate'

import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import withStyles from "@material-ui/core/styles/withStyles";
import {withWidth} from "@material-ui/core";

import styles from './styles';


const Pagination = withWidth()((props) => {

  const {
    classes,
    handlePageChanged,
    totalPageCount,
    currentPage,
    width
  } = props;

  const pageRangeDisplayed = ['xs'].includes(width) ? 1 : 3;

  return (
    <Paginate
      containerClassName={classes.container}
      nextLabel={<NavigateNextIcon/>}
      previousLabel={<NavigateBeforeIcon/>}
      pageLinkClassName={classes.pageButton}
      activeLinkClassName={classes.activePageButton}
      nextLinkClassName={classes.navigationButton}
      previousLinkClassName={classes.navigationButton}
      pageCount={totalPageCount}
      onPageChange={(selectedItem) => handlePageChanged(selectedItem.selected)}
      forcePage={currentPage}
      pageRangeDisplayed={pageRangeDisplayed}
      marginPagesDisplayed={1}
    />
  )
});

export default withStyles(styles)(Pagination);