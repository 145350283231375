import _ from "lodash";
import React from "react";
import useStyles from "../styles";
import Grid from "@material-ui/core/Grid";


const SeriesBenchmarksList = (props) => {
  const {
    benchmarks,
    disabled
  } = props;

  if(disabled || _.isEmpty(benchmarks)) {
    return null;
  }

  const internal_classes = useStyles();

  return (
    _.orderBy(benchmarks, ['weight']).map((bm, idx) => (
      <Grid container wrap={"nowrap"} alignItems={"center"} className={internal_classes.benchmarksList} key={idx}>
        <Grid item> {bm.weight}% </Grid>
        <Grid item> {bm.name} </Grid>
      </Grid>
    )).slice(0, 5)
  )
};

export default SeriesBenchmarksList;