import React, { forwardRef, useImperativeHandle } from  'react';
import PropTypes from 'prop-types';

// region Third party components
import Grid from '@material-ui/core/Grid'
import {Skeleton} from "@material-ui/lab";
import clsx from "clsx";
// endregion

import {useFiltersContext} from "../../Filters";
import useStyles from './styles'
import useCommonStyles from '../common_styles'
import CalendarFormElement
  from "../../../../../../containers/RiskProfiling/components/StepContent/components/formElement/CalendarFormElement";
import {formatDateShouldBeGraterThenErrorMessage} from "../../../../../../utils/utils";


const SimpleDateRange = forwardRef(({name, label, GridProps, loading, disabled, minDate, maxDate, useMinMaxDateOnInit, disableDatesCallback, noValidation}, ref) => {

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { filters, setFilterValue } = useFiltersContext();

  useImperativeHandle(ref, () => ({
    isValid: () => {
      return noValidation ? true : !!filters[name];
    }
  }));

  const [startDate, endDate] = React.useMemo(() => {
    if (!filters[name]) {
      return [minDate, maxDate]
    }

    return filters[name]
  }, [filters[name], minDate, maxDate]);

  const handleDateChanged =  (index) => (date) => {
    const valueCopy = [...[startDate, endDate]];
    valueCopy[index] = date;
    setFilterValue(name, valueCopy)
  };

  let startDateMaxError;
  if(!!endDate){
    startDateMaxError = 'Startdatum muss vor dem Enddatum liegen.'
  } else if (!!maxDate) {
    startDateMaxError = `Das Datum muss vor dem ${maxDate.format('DD.MM.YYYY')} liegen.`
  }

  let endDateMinError;
  if(!!startDate){
    endDateMinError = 'Enddatum darf nicht vor dem Startdatum liegen.'
  } else if (!!minDate) {
    endDateMinError = formatDateShouldBeGraterThenErrorMessage(minDate)
  }

  return (
    <Grid item {...GridProps}>
      <Grid container spacing={2}>
        <Grid item xs={6} className={commonClasses.formControl}>
          <p className={clsx(commonClasses.inputLabel, disabled && commonClasses.inputLabelDisabled)}>{label}</p>
            { loading ? (
              <Skeleton className={commonClasses.skeleton}/>
            ) : (
              <CalendarFormElement
                minDate={minDate || undefined}  // Start of period can not be earlier than min available date
                minError={!!minDate && formatDateShouldBeGraterThenErrorMessage(minDate)}
                value={startDate || null}
                maxDate={endDate || maxDate || undefined}  // Start of period can not be later than end
                maxError={startDateMaxError}
                onChange={handleDateChanged(0)}
                custom_classes={{inputRoot: commonClasses.input}}
                disableDatesCallback={disableDatesCallback}
              />
            )}
        </Grid>
        <Grid item xs={6} className={clsx(commonClasses.formControl, classes.delimiter)}>
          <p className={clsx(commonClasses.inputLabel, disabled && commonClasses.inputLabelDisabled)}>bis</p>
          { loading ? (
            <Skeleton className={commonClasses.skeleton}/>
          ) : (
            <CalendarFormElement
              minDate={startDate || undefined}  // End of period can not be earlier than start
              minError={endDateMinError}
              value={endDate || null}
              maxDate={maxDate || undefined}  // End of period can not be later than max available date
              maxError={!!maxDate && formatDateShouldBeGraterThenErrorMessage(maxDate)}
              onChange={handleDateChanged(1)}
              custom_classes={{inputRoot: commonClasses.input}}
              disableDatesCallback={disableDatesCallback}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
});

SimpleDateRange.propTypes = {
  /** Name of the field */
  name: PropTypes.string.isRequired,
  /** Grid options */
  GridProps: PropTypes.any
};

SimpleDateRange.defaultProps = {
  noValidation: false,
  useMinMaxDateOnInit: false,
  label: "Zeitraum von"
}

export default SimpleDateRange;