export default theme => ({
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    backgroundColor: '#CCE9FA',
    borderRadius: '50%',
  },
  iconWrapperEmpty: {
    backgroundColor: '#F5F6F7',
  },
  icon: {
    fontSize: 28,
    color: '#0092E5'
  },
  iconDark : {
    fontSize: 28,
    color: '#14486A'
  },
  iconEmpty: {
    color: '#80858C',
  }
});
