import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  favouriteInstrumentsText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#80858C',
    marginRight: 10
  },
  favouriteInstrumentsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  errorContainer: {
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& > i': {
      marginBottom: 15,
      color: '#ADB6BD',
      fontSize: 20
    },

    '& > p': {
      fontFamily: 'Roboto-Regular',
      color: '#63696F',
      textAlign: 'center',
      margin: 0
    }
  },

  buttonRoot: {
    height: '36px !important',
    marginLeft: 16,

    '& span': {
      fontSize: '14px !important'
    }
  }
})

export default makeStyles(styles)