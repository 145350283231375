import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  tableCell: {
    '&:nth-of-type(1)': {
      width: 40,
    },
    '&:nth-of-type(2)': {
      width: 'auto'
    },
    '&:nth-of-type(3)': {
      width: 150
    },
  },

  [theme.breakpoints.down('sm')]: {
    tableCell: {
      '&:nth-of-type(1)': {
        width: 35,
      },
      '&:nth-of-type(3)': {
        width: 130
      },
    }
  },
}))