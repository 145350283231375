import React from 'react';
import clsx from 'clsx';

import useStyles from './styles';


const CalendarIcon = (props) => {
  const classes = useStyles();

  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
        <defs>
          <clipPath id="a"><rect className="a" width="20" height="18"/>
          </clipPath>
        </defs>
        <g className="b">
          <path fill={props.color} d="M2178.056,715.533h-3.248v-.854a.923.923,0,0,0-1.842,0v.854H2170.7v-.854a.923.923,0,0,0-1.842,0v.854h-2.27v-.854a.923.923,0,0,0-1.842,0v.854h-3.249a1.664,1.664,0,0,0-1.718,1.6v13.086a1.664,1.664,0,0,0,1.718,1.6h16.563a1.665,1.665,0,0,0,1.72-1.6V717.133A1.665,1.665,0,0,0,2178.056,715.533Zm-12.393,3.619a.893.893,0,0,0,.921-.86v-1.058h2.27v1.058a.923.923,0,0,0,1.842,0v-1.058h2.271v1.058a.923.923,0,0,0,1.842,0v-1.058h3.147V719.5H2161.6v-2.269h3.145v1.058A.893.893,0,0,0,2165.663,719.152Zm12.292,2.052v8.914H2161.6V721.2Z" transform="translate(-2159.776 -713.819)"/>
          <path fill={props.color} d="M2166.574,731.851h8.184a1.112,1.112,0,0,0,0-2.187h-8.184a1.016,1.016,0,0,0-.908,1.093,1.2,1.2,0,0,0,.267.775A.833.833,0,0,0,2166.574,731.851Z" transform="translate(-2160.668 -718.479)"/>
        </g>
      </svg>
  );
};

export default CalendarIcon;