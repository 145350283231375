import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import clsx from "clsx";

import AppBar from '@material-ui/core/AppBar';

import useStyles from './styles';
import images from '../../images'
import {
  buildCustomerAssetsRoute,
  buildCustomerDashboardRoute,
} from "../../containers/CustomerDashboard/utils";
import {UserUtils} from "../../utils/utils";
import ReportGenerationHandler from "../ReportsGenerationHandler/ReportGenerationHandler";
import {DropDownButton} from './components';
import {
  buildCurrentCustomerDashboardPath, buildCurrentCustomerDashboardVirtualPath,
  buildCurrentCustomerDocumentsPath, buildCurrentCustomerOtherAssetsListPath,
  buildCurrentCustomerOtherAssetsPath,
  ROUTES
} from "../../routes";
import {brokerLogout, customerLogout} from "../../containers/Authentication/Auth/utils";
import InfoTourButton from "../Buttons/InfoTourButton";
import SideMenu from "./components/SideMenu";
import {
  closeAnalyseDropdown,
  closeSeriesDropdown,
  openAnalyseDropdown,
  openGuideModal,
  openSeriesDropdown
} from "./actions";
import {URL_FUNDS} from "./constants";
import Button from "@material-ui/core/Button";
import {PrimaryButton} from "../Buttons";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";


const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
  seriesDropdown: state.get('seriesDropdown').toJS(),
  analyseDropdown: state.get('analyseDropdown').toJS()
});

export default connect(mapStateToProps)(withRouter((props) => {
  const {
    auth,
    location: {pathname},
    seriesDropdown,
    analyseDropdown,
    dispatch,
    showMenuItems
  } = props;

  const classes = useStyles();

  const [analyseDropdownOpened, setAnalyseDropdownOpened] = React.useState(false);
  const [reportingDropdownOpened, setReportingDropdownOpened] = React.useState(false);

  const isActive = (routes) => {
    const isSome = routes.some(route => pathname.includes(route));
    return isSome ? classes.activeLink : '';
  };

  const handleLogoClicked = () => {
    const path = buildCustomerDashboardRoute();
    props.history.push(path);
  };

  const onAnalyseMenuOpen = () => {
    setAnalyseDropdownOpened(true);
  };

  const onReportingMenuOpen = () => {
    setReportingDropdownOpened(true);
  }

  const onReportingMenuClose = () => {
    setReportingDropdownOpened(false);
    if (seriesDropdown.opened) {
      dispatch(closeSeriesDropdown());
    }
  }

  const onAnalyseMenuClose = () => {
    setAnalyseDropdownOpened(false);
    if (analyseDropdown.opened) {
      dispatch(closeAnalyseDropdown());
    }
  }

  return (
    <AppBar className={classes.header}>
      <div className={classes.pageNav}>
        <ul className={classes.nav}>
          {(
            <React.Fragment>
              {UserUtils.isBroker(auth) && (
                <>
                  <li className={clsx(classes.item, classes.divaHeaderLogo)}>
                    <a href={null} className={classes.navLink} onClick={handleLogoClicked}>
                      <img alt="DIVA.Web" src={images.diva_logo} className={classes.logo}/>
                    </a>
                  </li>
                  { showMenuItems && (
                    <>
                  <li
                    id="logout-button-tour-element"
                    className={clsx(
                      classes.item, classes.hideOnSmall,
                      classes.headerItem, classes.divaHeaderItem
                    )}
                  >
                    <a href={null} className={classes.navLink} onClick={() => brokerLogout()}>
                      <i className="chevron-icon fa fa-chevron-left" /> ZURÜCK ZUR DIVA
                    </a>
                  </li>
                  <li
                    id="portfolio-dashboard-tour-element"
                    className={clsx(
                      classes.item, classes.hideOnSmall, classes.headerItem,
                      isActive([
                        ROUTES.BROKER.CUSTOMER_DASHBOARD.path.replace('/:customer_id', ''),
                        ROUTES.BROKER.OTHER_ASSETS.path.replace('/:customer_id', '')
                      ])
                    )}
                  >
                    <DropDownButton
                      openAction={onAnalyseMenuOpen}
                      closeAction={onAnalyseMenuClose}
                      withoutDispatch={true}
                      name={'Vermögensanalyse'}
                      open={analyseDropdown.opened || analyseDropdownOpened}
                      items={[
                        {
                          key: 'customerDashboard',
                          path: buildCustomerDashboardRoute(),
                          activePaths: [ROUTES.BROKER.CUSTOMER_DASHBOARD.path.replace('/:customer_id', '')],
                          name: ROUTES.BROKER.CUSTOMER_DASHBOARD.name
                        },
                        {
                          key: 'otherAssets',
                          path: buildCustomerAssetsRoute(),
                          activePaths: [ROUTES.BROKER.OTHER_ASSETS.path.replace('/:customer_id', '')],
                          name: ROUTES.BROKER.OTHER_ASSETS.name,
                          id: 'assets-tour-element'
                        },
                        // {
                        //   key: 'BrokerSettings',
                        //   path: ROUTES.BROKER.BROKER_SETTINGS.path,
                        //   activePaths: [ROUTES.BROKER.BROKER_SETTINGS.path],
                        //   name: ROUTES.BROKER.BROKER_SETTINGS.name,
                        //   id: 'assets-tour-element'
                        // }
                      ]}
                    />
                  </li>
                  {/*<li*/}
                    {/*id="risk-dashboard-tour-element"*/}
                    {/*className={clsx(*/}
                      {/*classes.item, classes.hideOnSmall,*/}
                      {/*classes.headerItem, isActive([ROUTES.BROKER.RISK_DASHBOARD.path])*/}
                    {/*)}*/}
                  {/*>*/}
                    {/*<Link*/}
                      {/*to={ROUTES.BROKER.RISK_DASHBOARD.path}*/}
                      {/*className={classes.navLink}*/}
                    {/*>*/}
                      {/*{ROUTES.BROKER.RISK_DASHBOARD.name}*/}
                    {/*</Link>*/}
                  {/*</li>*/}
                  <li
                    id="fourth-step"
                    className={clsx(
                      classes.item, classes.hideOnSmall, classes.headerItem,
                      isActive([ROUTES.BROKER.REPORTING.path, ROUTES.BROKER.REPORT_SETTINGS.path, ROUTES.BROKER.GROUP.path])
                    )}
                  >
                    <DropDownButton
                      openAction={onReportingMenuOpen}
                      closeAction={onReportingMenuClose}
                      name={'Reporting'}
                      open={seriesDropdown.opened || reportingDropdownOpened}
                      withoutDispatch={true}
                      items={[
                        {
                          key: 'reportSettings',
                          path: ROUTES.BROKER.REPORT_SETTINGS.path,
                          activePaths: [ROUTES.BROKER.REPORT_SETTINGS.path, ROUTES.BROKER.GROUP.path],
                          name: ROUTES.BROKER.REPORT_SETTINGS.name,
                          id: 'report-settings-button'
                        },
                        {
                          key: 'reporting',
                          path: ROUTES.BROKER.REPORTING.children.GROUPS.path,
                          activePaths: [ROUTES.BROKER.REPORTING.path],
                          name: ROUTES.BROKER.REPORTING.name,
                          id: 'series-reporting-button'
                        }
                      ]}
                    />
                  </li>
                  </>)}
                </>
              )}
              {UserUtils.isCustomer(auth) && showMenuItems && (
                <React.Fragment>
                  <li
                    id="portfolio-dashboard-tour-element"
                    className={clsx(
                      classes.item, classes.hideOnSmall, classes.headerItem,
                      classes.customerHeaderFirstItem, isActive([buildCurrentCustomerDashboardPath()])
                    )}
                  >
                    <Link
                      to={buildCurrentCustomerDashboardPath()}
                      className={classes.navLink}
                    >
                      {ROUTES.CUSTOMER.DASHBOARD.name}
                    </Link>
                  </li>
                  {UserUtils.hasVirtualPortfolioAccess(auth) && <li
                    id="virtual-portfolio-dashboard-tour-element"
                    className={clsx(
                      classes.item, classes.hideOnSmall, classes.headerItem,
                      isActive([buildCurrentCustomerDashboardVirtualPath(auth.user.customer_id)])
                    )}
                  >
                    <Link
                      to={buildCurrentCustomerDashboardVirtualPath(auth.user.customer_id)}
                      className={classes.navLink}
                    >
                      {ROUTES.CUSTOMER.DASHBOARD_VIRTUAL.name}
                    </Link>
                  </li>}
                  <li
                    id="assets-tour-element"
                    className={clsx(
                      classes.item, classes.hideOnSmall,
                      classes.headerItem, isActive([buildCurrentCustomerOtherAssetsPath()])
                    )}
                  >
                    <Link
                      to={buildCurrentCustomerOtherAssetsListPath()}
                      className={classes.navLink}
                    >
                      {ROUTES.CUSTOMER.OTHER_ASSETS_LIST.name}
                    </Link>
                  </li>
                  <li
                    id="documents-tour-element"
                    className={clsx(
                      classes.item, classes.hideOnSmall,
                      classes.headerItem, isActive([buildCurrentCustomerDocumentsPath()])
                    )}
                  >
                    <Link
                      to={buildCurrentCustomerDocumentsPath()}
                      className={classes.navLink}
                    >
                      {ROUTES.CUSTOMER.DOCUMENTS.name}
                    </Link>
                  </li>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </ul>
      </div>
      {showMenuItems && auth.user && (
        <div className={classes.funcNav}>
          <ul className={classes.nav}>
            <li
              className={clsx(classes.item, classes.hideOnExtraSmall, classes.buttonItem)}
            >
              <InfoTourButton/>
            </li>
            {UserUtils.isCustomer(auth) && UserUtils.hasVirtualPortfolioAccess(auth) && UserUtils.hasAgencyWebsite(auth) &&
              <li
                className={clsx(classes.item, classes.hideOnExtraSmall, classes.buttonItem, classes.fundsButton)}
              >
              <PrimaryButton
                text='FONDS KAUFEN'
                onButtonClick={() => {
                  window.open(auth.user.agency.website, '_blank')
                }}
              />
              </li>
            }
            <li style={{flex: 1}} />
            {UserUtils.isBroker(auth) && (
              <li className={classes.item}>
                <ReportGenerationHandler />
              </li>
            )}
            {UserUtils.isCustomer(auth) && (
              <li
                id="logout-button-tour-element"
                className={clsx(
                  classes.item, classes.hideOnSmall, classes.headerItem, classes.customerLogoutHeaderItem
                )}
              >
                <a href={null} className={classes.navLink} onClick={() => customerLogout()}>
                  ABMELDEN
                </a>
              </li>
            )}
            <li>
              <SideMenu />
            </li>
          </ul>
        </div>
      )}
    </AppBar>
  )
}))