import { makeStyles } from '@material-ui/core';

const styles = (theme) => ({
  paperHeader: {
    borderRadius: 8,
    padding: 20,
    boxShadow: '0px 3px 15px #39435233',
    width: '100%',
    margin: '24px 0',
    overflow: 'visible'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    fontFamily: 'Roboto-Regular',
    color: '#202A38',
  },
  headerSection: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column"
    },
  },
  header: {
    fontSize: 34,

    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    },
  },
  subHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'end',
    fontSize: 16,
    lineHeight: '2em',
    marginLeft: 10,

    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      margin: 0
    },
  },
  activeTabIndicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent !important",
    bottom: '7px !important',
    "& > div": {
      maxWidth: 30,
      width: "100%",
      backgroundColor: "#0092E5"
    }
  },
  tab: {
    paddingLeft: '24px !important',
    paddingRight: '24px !important',
    fontSize: '16px !important',
    color: '#202A38 !important',
    borderRight: '1px solid #D8DCDF !important',
    textTransform: 'none !important',
    background: 'transparent linear-gradient(180deg, #FFFFFF 0%, #F5F6F7 100%) 0% 0% no-repeat !important',
    opacity: 1,
  },
  tabSelected: {
    textShadow: '.5px 0 #202A38 !important',
    background: '#ffffff !important',
  },
  tabPanel: {
    padding: 0,
  },
  paperRoot: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    boxShadow: '0px 3px 15px #39435233',
    overflow: 'hidden',
    width: '100%'
  },
  section: {
    '& > h3, h2': {
      margin: 0,
      padding: 0,
      fontFamily: 'Roboto-Bold',
      marginBottom: 10,
      color: '#202A38',
      fontSize: '1.75rem'
    },
  },
  sectionHeader: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#202A38',
    margin: 0
  },
  description: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    margin: '4px 0',
    whiteSpace: 'pre-line'
  },
  container: {
    '& $section': {
      padding: '6px 24px',
      '&:last-child': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 24px 30px'
      }
    }
  },
  productsListSection: {
    '& > h3 > span': {
      fontFamily: 'Roboto-Regular',
      fontSize: '1.5rem'
    }
  },
  primaryButton: {
    '& i': {
      color: theme.palette.primary.main
    }
  },
  actionsSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
    padding: '6px 0px !important',
    paddingLeft: '10px !important',
    '& > button': {
      marginRight: 15,
      minHeight: 46,
      '&:last-child': {
        marginRight: 0
      },
      [theme.breakpoints.down('md')]: {
        width: '50%',
        minHeight: 35,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginRight: 0,
        marginBottom: 15,
        '&:last-child': {
          marginBottom: 0
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      order: -1,
      paddingLeft: '0 !important',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    }
  },
  controlsContiner: {
    '& > div': {
      marginRight: 15,
      '&:last-child': {
        marginRight: 0
      }
    }
  },
  chartSectionHeaderInfoIcon: {
    marginLeft: 15
  },
  tabContentContainer: {
    '& > div': {
      marginBottom: 24
    }
  }
});

export default makeStyles(styles);

export const commonStyles = makeStyles(styles);

export const benchmarkSwitchStyles = makeStyles((theme) => ({
  benchmarkWrap: {
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      marginBottom: 12,
    },
  },

  switchWrapper: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end',
      marginRight: 0,
    },
  },

  benchmarkSettingsButton: {
    position: "absolute",
    bottom: -25,
  },

  // styles of the switch
  container: {
    [theme.breakpoints.down('sm')]: {
      height: '35px !important',
    },
  },
  thumb: {
    [theme.breakpoints.down('sm')]: {
      height: '25px !important',
      width: '25px !important',
    },
  },
}));