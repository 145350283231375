import React from 'react';
import useAssetsPeerGroupRankingData from "../../hooks/useAssetsPeerGroupRankingData";
import RankingPerformanceTable, {
  RANKING_PERFORMANCE_TABLE_INFO_TEXT
} from "../../../VirtualPortfolioPage/Tabs/CommonAnalysis/items/RankingPerformanceTable";
import {Grid} from "@material-ui/core";
import {ChartSectionBordered} from "../ChartSection/ChartSection";

import useStyles from './styles';
import _ from "lodash";
import {PerformanceBarChart, PerformanceChart, PerformanceChartLegend} from "../charts";
import useYearlyPerformanceData from "../../hooks/useYearlyPerformanceData";
import {PERFORMANCE_CHART_INFO_ICON_TEXT} from "../../constants";
import clsx from "clsx";
import WarningTooltip from "../../../../components/WarningTooltip";


function PerformanceTab(props) {

  const classes = useStyles();

  const [rankingData, rankingDataExists] = useAssetsPeerGroupRankingData(props.selectedProducts);
  const yearlyPerformanceData = useYearlyPerformanceData(props.selectedProducts);

  const [expandedSections, setExpandedSections] = React.useState({...props.expandedSectionsRef.current.performanceTab});
  React.useEffect(() => {
    setExpandedSections({...props.expandedSectionsRef.current.performanceTab})
  },[props.expandedSectionsRef.current])

  const onExpanded = (sectionKey) => () => {
    setExpandedSections((current) => {
      let newState = !current[sectionKey]
      props.expandedSectionsRef.current.performanceTab[sectionKey] = newState
      return {
        ...current,
        [sectionKey]: newState
      }
    })
  }

  return (
    <>
      <Grid item xs={12}>
        <ChartSectionBordered
          title={(
            <>
              <b>Jährliche Wertentwicklung der letzten 5 Jahre in % (annualisiert)</b>
              <WarningTooltip
                title={"Berechnet nach geldgewichteter Methode bzw. nach Modified Dietz."}
                icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)}></i>}
                width={600}
              />
            </>
          )}
          loading={yearlyPerformanceData.loading}
          error={yearlyPerformanceData.errors}
          displayError
          expanded={expandedSections.performance_bar_chart}
          onExpanded={onExpanded("performance_bar_chart")}
          content={
            <Grid container>
              <Grid item xs={12}>
                <PerformanceBarChart
                  updatedAt={yearlyPerformanceData.updatedAt}
                  loading={yearlyPerformanceData.loading}
                  errors={yearlyPerformanceData.errors}
                  data={yearlyPerformanceData.data}
                />
              </Grid>
              <Grid item xs={12}>
                {yearlyPerformanceData.data && (
                  <PerformanceChartLegend data={yearlyPerformanceData.data}  flexDirection={"row"}/>
                )}
              </Grid>
            </Grid>
          }
        />
      </Grid>
      {rankingDataExists && (
        <Grid item xs={12}>
          <ChartSectionBordered
            title={(
              <>
                <b>Peergroup-Quintilsranking</b>
                <WarningTooltip
                  title={RANKING_PERFORMANCE_TABLE_INFO_TEXT}
                  icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)}></i>}
                  width={600}
                />
              </>
            )}
            loading={rankingData.loading}
            error={rankingData.errors}
            displayError
            expanded={expandedSections.peergroup_ranking_table}
            onExpanded={onExpanded("peergroup_ranking_table")}
            content={
              <RankingPerformanceTable
                data={rankingData.data}
                loading={rankingData.loading}
                error={rankingData.errors}
                order='asc'
                orderBy='name'
                expanded={_.get(props, 'expandedSections.peergroup_ranking_table')}
                withHeader={false}
                classes={{root: classes.cardRootOverride}}
              />
            }
          />
        </Grid>
      )}
    </>
  )
}

export default PerformanceTab