import {
  openMenu
} from "../../../components/Header/actions";
import {mdScreenStepsSizes} from "../../App/constants";

const getNavigationStep1 = (width) => {
  let dispatchFn = [];
  let selector = '#logout-button-tour-element';
  if (mdScreenStepsSizes.includes(width)) {
    dispatchFn = [openMenu];
    selector = '#logout-button-tour-element-side-menu';
  }
  return {
    selector,
    key: 'other_assets_navigation_logout',
    dispatchFn,
    position: 'bottom',
  }
};

const getNavigationStep2 = (width) => {
  let dispatchFn = [];
  let selector = '#portfolio-dashboard-tour-element';
  if (mdScreenStepsSizes.includes(width)) {
    dispatchFn = [openMenu];
    selector = '#portfolio-dashboard-tour-element-side-menu';
  }
  return {
    selector,
    key: 'other_assets_navigation_dashboard',
    dispatchFn,
    position: 'bottom',
  }
};

const getNavigationStep3 = (width) => {
  let dispatchFn = [];
  let selector = '#documents-tour-element';
  if (mdScreenStepsSizes.includes(width)) {
    dispatchFn = [openMenu];
    selector = '#documents-tour-element-side-menu';
  }
  return {
    selector,
    key: 'other_assets_navigation_documents',
    dispatchFn,
    position: 'bottom',
  }
};

export const getNavigationChapterSteps = (width, isCustomerView) => {
  const steps = [
    getNavigationStep1(width),
    getNavigationStep2(width)
  ];

  if (isCustomerView) {
    steps.push(getNavigationStep3(width))
  }

  return steps;
};
