import React from 'react'
import {connect} from "react-redux";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {Grid, Container, CircularProgress} from '@material-ui/core'

import { BrokerResource } from '../../utils/api';

import PaginationWrapper from './components/PaginationWrapper';
import useStyles from './styles';
import { getErrorMessage, hasFilterResult, paginateArray, UserUtils } from '../../utils/utils';
import CustomerFilteringPanel from "./components/CustomerFilteringPanel";
import clsx from "clsx";
import {displayErrorSnackBar} from "../SnackbarProvider/actions";
import {CLIENT_FILTERS, getSearchCustomerType} from "../FilteringPanel/components/ListSelector/constants";
import {CUSTOMER_ICON} from "./constants";
import CustomerFullnameMenu from "../CustomerFullnameMenu/CustomerFullnameMenu";
import {CLIENT_TYPES} from "../FilteringPanel/components/SearchInputWithSuggestions/constants";
import FilterHeader from "./components/FilterHeader";
import CustomersList from './components/CustomersList';
import {getAuthSelector} from "../../utils/redaxSelectors";


const START_PAGE_INDEX = 0;
const CUSTOMERS_PER_PAGE = 10;

export const IconColumn = {
  content: [{
    body: {
      content: (item) => {
        const icon = _.get(CUSTOMER_ICON, item.customer_type_id);
        return !!icon && <FontAwesomeIcon icon={['fa', icon]} fixedWidth={true} color={'#838a93'} fontSize={20} />
      }
    },
    header: ''
  }],
};

export const CustomerFullnameMenuColumn = {
  content: [{
    body: {content: (item) => (
        <>
          <CustomerFullnameMenu
            customer={item}
            customerFullname={item.customer_full_name}
          />
          {item.customer_id}
        </>
      )},
    header: 'Kunde'
  }],
};

export const AddressColumn = {
  content: [{
    body: { content: (item) => (
        <>
          {item.address || ' - '}
          <br/>
          <span>{item.zipcode} {item.city ? item.city.trim() : ' - '} </span>
        </>

      )},
    header: 'Adresse'
  }]
};

const mapStateToProps = (state) => ({
  auth: getAuthSelector(state),
});

const CustomersNew = (props) => {

  const {
    auth,
    onCustomersChange,
    extraColumns,
    component,
    additionalFiltersRender,
    withHeader = true,
    excludedCustomersIDs = [],
  } = props;

  let wordTemp;

  const classes = useStyles();

  const [customersData, setCustomers] = React.useState({
    data: null,
    loading: false,
    errors: null
  });

  const [page, setPage] = React.useState(START_PAGE_INDEX);

  const [parameters, setParameters] = React.useState({
    customer_type: getSearchCustomerType(auth).value,
    sort_by: 'customer_last_name',
    search_word: '',
    search_letter: '',
    sub_agency: undefined,
    sub_broker: undefined,
    request_type: undefined
  });

  const [filterResult, setFilterResult] = React.useState({
    count: undefined,
    word: undefined
  });

  const customers = React.useMemo(() => {
    let customersList = _.flatten(customersData.data || []);

    if (!_.isEmpty(excludedCustomersIDs)) {
      customersList = customersList.filter((c) => !excludedCustomersIDs.includes(c.customer_id))
    }

    setFilterResult({
      word: wordTemp,
      count: customersList.length
    });

    const pages = [...paginateArray(customersList, CUSTOMERS_PER_PAGE)];
    const possiblePageIdx = pages.length > 0 ? pages.length - 1 : 0;
    // reset page in case number of pages is less then current page
    if (possiblePageIdx < page) {
      setPage(possiblePageIdx);
    }

    return {
      ...customersData,
      data: pages
    };
  }, [JSON.stringify(excludedCustomersIDs), JSON.stringify(customersData)]);

  const extendParamsWithAdditional = (params, additional) => {
    if (additional) {
      Object.keys(additional).forEach((key) => {
        params[key] = additional[key]
      })
    }
  }

  const handleSearchClicked = (value, additionalFilters) => {
    value = value.trim();
    let param = parameters;
    param.search_letter = '';
    param.search_word = value;
    extendParamsWithAdditional(param, additionalFilters);
    setParameters(param);
    fetchCustomers();
    wordTemp = value;
  };

  const handleSearchByLetter = (letter, additionalFilters) => {
    let param = parameters;
    param.search_letter = letter;
    param.search_word = '';
    extendParamsWithAdditional(param, additionalFilters);
    setParameters(param);
    fetchCustomers();
    wordTemp = `Anfangszeichen ${letter}`;
  }

  const handleClientTypeChanged = (event, additionalFilters) => {
    let param = parameters;
    let type = event.nativeEvent.target.getAttribute("data-type");
    param.customer_type = event.target.value;
    param.request_type = undefined;

    if (type == CLIENT_FILTERS.SUB_BROKER) {
      param.request_type = type;
    } else if (type == CLIENT_FILTERS.SUB_AGENCY) {
      param.request_type = type;
    }

    extendParamsWithAdditional(param, additionalFilters);
    setParameters(param);
    fetchCustomers();
  };

  const fetchCustomers = async () => {

    setCustomers({
      data: null,
      loading: true,
      errors: null
    });
    setPage(START_PAGE_INDEX); // set page to start page

    try {
      let _params = {...parameters}

      // validate if type in static(value: 1..3)
      if (_params.request_type == CLIENT_FILTERS.SUB_AGENCY) {
        _params.sub_agency = _params.customer_type;
        _params.customer_type = CLIENT_FILTERS.SUB_AGENCY;
      } else if (_params.request_type == CLIENT_FILTERS.SUB_BROKER) {
        _params.sub_broker = _params.customer_type;
        _params.customer_type = CLIENT_FILTERS.SUB_BROKER;
      }

      let response = await BrokerResource.at('customers/').get(_params);
      if(onCustomersChange){
        await onCustomersChange(_.flatten(response));
      }

      setCustomers({
        data: response,
        loading: false,
        errors: null
      });

    } catch (errors) {
      props.dispatch(displayErrorSnackBar(getErrorMessage(errors)));
      setCustomers({
        data: null,
        loading: false,
        errors
      })
    }
  }

  const renderList = () => {

    if (customers.loading) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress color="primary" />
        </div>
      )
    }

    if (customers.data && customers.data.length > 0) {

      if (component) {
        return <props.component dataSource={[...customers.data[page] || []]}/>
      }

      return <CustomersList extraColumns={extraColumns} customers={[...customers.data[page] || []]} />
    }

    if (!hasFilterResult(filterResult)) {
      return <FilterHeader/>;
    }

    return null;
  };

  return (
    <Container className={'app-page-container'}>
      <Grid className={classes.container}>
        {withHeader && (
          <Grid item xs={12}>
            <h3 className={classes.bold}>Kundenübersicht</h3>
          </Grid>
        )}

        <Grid item xs={12}>
          <CustomerFilteringPanel
            customerType={parameters.customer_type}
            defaultSearchValue={''}
            handleSearchByLetter={handleSearchByLetter}
            handleSearchClicked={handleSearchClicked}
            handleClientTypeChanged={handleClientTypeChanged}
            isChief={UserUtils.isChief(auth)}
            user={auth.user}
            additionalFiltersRender={additionalFiltersRender}
            clientTypesOptions={CLIENT_TYPES}
            loading={customers.loading}
          />
        </Grid>

        {!customers.loading && hasFilterResult() && (
          <Grid className={clsx(classes.topMessage, classes.bold)} item xs={12}>
            Ihre Suche nach&nbsp;<span className={classes.word}>“{filterResult.word}”</span>
            &nbsp;({filterResult.count ? filterResult.count : 'Keine'} Ergebnisse).
          </Grid>
        )}

        <Grid item xs={12}>
          { renderList() }
        </Grid>
        <PaginationWrapper customers={customers} page={page} setPage={setPage} />
      </Grid>
    </Container>
  )
}

export default connect(mapStateToProps)(CustomersNew);