import React from "react";
import Rating from "@material-ui/lab/Rating";
import {withStyles} from "@material-ui/core";

import GlobeMark from "../GlobeMark";
import styles from "./styles";
import WarningTooltip from "../../../../../../components/WarningTooltip";
import LoadingIndicator from "../../../../../../components/LoadingIndicator/LoadingIndicator";

function MsRating({ value, tooltip, classes, loading }) {
  return (
    <div className={classes.wrapper}>
      <span className={classes.labelText}>
        Morningstar Nachhaltigkeits-Rating
        {tooltip && (
          <>
            &nbsp;
            <WarningTooltip title={tooltip} iconClassName={classes.helpIcon}/>
          </>
        )}
      </span>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Rating
          readOnly
          value={value}
          icon={<GlobeMark />}
          emptyIcon={<GlobeMark empty />}
          classes={{ root: classes.ratingRoot }}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(MsRating);
