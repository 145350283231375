import React from "react";

import IconButton from "@material-ui/core/IconButton";
import withStyles from '@material-ui/core/styles/withStyles';

import styles from "./styles";

const SideMenuTriggerButton = props => {
  const {
    classes,
    onClick
  } = props;

  return (
    <IconButton onClick={onClick}>
      <span className={classes.icon}>
        <i className="fa fa-bars" aria-hidden="true" />
      </span>
    </IconButton>
  )
};

export default withStyles(styles)(SideMenuTriggerButton);