class WebSocketHandler {
    constructor(path, on_message) {
        this._path = path;
        this._connection = undefined;
        this._lastServerErrorPath = undefined;
        this._on_message = on_message;
    }

    isClosed() {
        return !this._connection || [WebSocket.CLOSED, WebSocket.CLOSING].includes(this._connection.readyState)
    }

    connect() {
        this._connection = new WebSocket(`${window.location.protocol === "https:" ? "wss:" : "ws:"}//${this._path}`);
        this._connection.onmessage = this._on_message;
        this._connection.onclose = (event) => {
            // event codes 4000 - 4999 are server custom codes
            if(event.code > 4000) {
                if(this._path === this._lastServerErrorPath){
                    console.error("Websocket connection closed by server with code: " + event.code);
                    return;
                }

                this._lastServerErrorPath = this._path;
            }

            setTimeout(() => this.connect(), 500);
        }
    }

    close() {
        this._connection.onclose = () => {}
        this._connection.close();
    }
}

export default WebSocketHandler;