const level1BackgroundColor = '#202A38';
const level2BackgroundColor = '#2A3545';
const level3BackgroundColor = '#2A3545';

const level1ActiveItemBackgroundColor = '#2A3545';

const itemsColor = '#B0B7BC';

const styles = (theme) => ({
  sideMenuContainer: {
    color: itemsColor,
    display: 'flex',
    zIndex: 10,
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      height: 'calc(100svh - 72px)'
    }
  },
  level1: {
    width: 72,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: level1BackgroundColor,
    overflowY: 'auto',
    msOverflowStyle: 'none',  /* IE and Edge */
    scrollbarWidth: 'none',  /* Firefox */
    '&::-webkit-scrollbar': {
      width: 0,
      display: 'none',
    },

    // hide lvl 1 menu on small screens
    [theme.breakpoints.down('md')]: {
      '&.collapsed': {
        display: 'none'
      }
    }
  },
  mainItems: {},
  bottomPlaced: {},
  level2: {
    width: 256,
    backgroundColor: level2BackgroundColor,
    height: '100%',
    // boxShadow: '6px 0px 6px #00000029',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: 5
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#B0B7BC',
      outline: '1px solid slategrey'
    }
  },
  subTitle: {
    color: '#838A93',
    fontFamily: 'Roboto-Bold',
    fontSize: 12,
    letterSpacing: 1.2,
    lineHeight: '48px',
    paddingLeft: 24,
    textTransform: 'uppercase'
  },
  subItem: {

  }
});

export default styles;