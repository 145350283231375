import React from 'react';


export default ({color, onClick, size}) => {

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }
  return (
    <svg onClick={handleClick} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path fill={color || '#202a38'} d="M1276.861,500.74a9,9,0,1,0,9,9A9,9,0,0,0,1276.861,500.74Zm1.3,12.931a1.514,1.514,0,0,1-.363,1.067,1.256,1.256,0,0,1-.946.406,1.22,1.22,0,0,1-.948-.423,1.545,1.545,0,0,1-.343-1.05V509.1a1.508,1.508,0,0,1,.35-1.052,1.326,1.326,0,0,1,1.883-.007,1.413,1.413,0,0,1,.367,1.012Zm-.4-7.109a1.317,1.317,0,0,1-.889.334,1.334,1.334,0,0,1-.9-.327,1.3,1.3,0,0,1,0-1.889,1.362,1.362,0,0,1,1.771-.034,1.2,1.2,0,0,1,.421.965A1.221,1.221,0,0,1,1277.758,506.562Z" transform="translate(-1267.861 -500.74)"/></svg>
  )
}