import { makeStyles } from '@material-ui/core'
export const FORM_FIELD_HEIGHT = 45;
export const FORM_FIELD_HEIGHT_SM = 35;


export const autoCompleteStyles = {
  minHeight: `${FORM_FIELD_HEIGHT}px !important`,
  height: `${FORM_FIELD_HEIGHT}px !important`,
  maxHeight: `${FORM_FIELD_HEIGHT}px !important`,

  '& .MuiAutocomplete-input': {
    padding: '6px 10px !important'
  }
};

export default makeStyles((theme) => ({
  formControl: {
    '& fieldset': {
      borderColor: '#D8DCDF !important',
      borderWidth: '1px !important'
    },

    marginBottom: 16
  },
  input: {
    height: FORM_FIELD_HEIGHT,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      height: `${FORM_FIELD_HEIGHT}px !important`,
    },
  },

  formControlError: {
    '& fieldset': {
      borderColor: '#f44336 !important',
      borderWidth: '1px !important'
    },

    marginBottom: 16
  },

  inputLabel: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    color: '#202A38',
    marginBottom: 1
  },

  placeholder: {
    color: '#D8DCDF'
  },

  inputLabelDisabled: {
    color: 'rgba(0, 0, 0, 0.26)'
  },

  inputLabelError: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    color: '#f44336',
    marginTop: 2,
    marginBottom: 0,
    position: 'absolute',
    top: 82
  },

  skeleton: {
    height: FORM_FIELD_HEIGHT,
    transform: 'none !important'
  },
}))