import { makeStyles } from '@material-ui/core';

export const styles = () => ({
  autocompleteRoot: {
    position: 'relative',
    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'none',
    },
  },
  paper: {
    border: 'none',
    borderRadius: 4,
    boxShadow: '0px 3px 15px #00000033',

    '&:hover': {
      backgroundColor: 'white !important'
    },

    '&::-webkit-scrollbar': {
      width: 8,
      marginRight: 5
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8DCDF',
      borderRadius: 4,
    }
  },
  listbox: {
    padding: 0,
  },
  option: {
    paddingTop: 4,
    paddingBottom: 4,
    minHeight: 48,
  },
  inputRoot: {
    paddingTop: '6px!important',
    paddingBottom: '6px!important',
  },
  label: {
    marginBottom: 1,
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.38)'
  },
});

export default makeStyles(styles);