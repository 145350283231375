import { makeStyles } from "@material-ui/core";
import { SECTION_PADDING } from '../../../containers/CustomerDashboardV2/constants';

export default makeStyles(theme => ({

  tableHead: {
    backgroundColor: '#f9f9f9',

    '& td': {
      color: '#80858C !important'
    }

  },

  tableCell: {
    padding: '15px 5px',
    '&:nth-of-type(1)': {
      width: 40,
      paddingLeft: SECTION_PADDING
    },
    '&:nth-of-type(2)': {
      width: 'auto'
    },
    '&:nth-of-type(3)': {
      width: 150,
      paddingRight: 10
    },
  },

  [theme.breakpoints.down('sm')]: {
    tableCell: {
      '&:nth-of-type(1)': {
        width: 35,
      },
      '&:nth-of-type(3)': {
        width: 130
      },
    }
  },
}))