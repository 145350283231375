import {
  GUIDE_TOUR_CLOSED,
  GUIDE_TOUR_OPENED,
  MENU_CLOSED,
  MENU_OPENED,
  SERIES_DROPDOWN_OPENED,
  SERIES_DROPDOWN_CLOSED,
  GUIDE_TOUR_MODAL_OPENED,
  GUIDE_TOUR_MODAL_CLOSED,
  GUIDE_TOUR_STEPS_DELETED,
  GUIDE_TOUR_STEPS_UPDATED,
  GUIDE_TOUR_LAST_STEP_INDEX,
  GUIDE_TOUR_UPDATE_TRIGGER,
  ANALYSE_DROPDOWN_OPENED,
  ANALYSE_DROPDOWN_CLOSED,
} from "./constants";

export const openGuideModal = () => ({
  type: GUIDE_TOUR_MODAL_OPENED
});

export const closeGuideModal = () => ({
  type: GUIDE_TOUR_MODAL_CLOSED
});

export const updateGuideSteps = (steps) => ({
  type: GUIDE_TOUR_STEPS_UPDATED,
  steps
});

export const deleteGuideSteps = () => ({
  type: GUIDE_TOUR_STEPS_DELETED
});

export const openGuide = () => {
  return {
    type: GUIDE_TOUR_OPENED
  }
};

export const closeGuide = () => {
  return {
    type: GUIDE_TOUR_CLOSED
  }
};

export const setLastStepIndex = (lastStepIndex) => ({
  type: GUIDE_TOUR_LAST_STEP_INDEX,
  lastStepIndex
});

export const updateGuideTourTrigger = (selector) => ({
  type: GUIDE_TOUR_UPDATE_TRIGGER,
  selector
});

export const openMenu = () => {
  return {
    type: MENU_OPENED
  }
};

export const closeMenu = () => {
  return {
    type: MENU_CLOSED
  }
};

export const openSeriesDropdown = () => {
  return {
    type: SERIES_DROPDOWN_OPENED
  }
};

export const closeSeriesDropdown = () => {
  return {
    type: SERIES_DROPDOWN_CLOSED
  }
};

export const openAnalyseDropdown = () => {
  return {
    type: ANALYSE_DROPDOWN_OPENED
  }
};

export const closeAnalyseDropdown = () => {
  return {
    type: ANALYSE_DROPDOWN_CLOSED
  }
};

