import React from 'react';
import OtherAssets from "./OtherAssets";
import withCustomersSelector from '../../components/CustomersSelectorProvider'

const OtherAssetsBrokerView = props => {
  return (
    <OtherAssets customerId={props.computedMatch.params.customer_id} />
  )
};

export default withCustomersSelector("OTHER_ASSETS", 'Sonstige Vermögenswerte')(OtherAssetsBrokerView);