
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import _ from 'lodash';

import withWidth from "@material-ui/core/withWidth/withWidth";
import { CHART_COLORS } from '../../../../../utils/constants';
import {openMorningStartIsin} from "../../../../../utils/utils";
import {
  DEFAULT_POINT_FORMAT,
  RiskReturnChart as ProViewRiskReturnChart
} from '../../../../../components/Charts/RiskReturnChart'
import { getInstrName } from '../../../../Modelportfolios/utils';
import { DEFAULT_EMPTY_SECTION_MESSAGE } from '../../../../CustomerDashboardV2/constants';
import {ALL_VALUE, YEARS_1, ALL, YEARS_3, YEARS_5} from "../../TimeRangeButtonsPad/constants";
import TimeRangesButtonsPad from "../../TimeRangeButtonsPad";
import Legend from "../../Legend/Legend";

const POINT_FORMAT = DEFAULT_POINT_FORMAT + '<br />Anteil: <b>{point.weight:.2f}%</b></div>'

class ChartRiskReturn extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      range: undefined,
      codesToShow: [ALL, YEARS_1, YEARS_3, YEARS_5]
    }

    this.onRangeChange = this.onRangeChange.bind(this);
  }

  onRangeChange(_, __, rangeCode) {
    let mapping = {
      ALL: ALL_VALUE,
      YEARS_1: '1y',
      YEARS_3: '3y',
      YEARS_5: '5y',
    }

    this.setState({
      range: mapping[rangeCode],
    });

    this.props.onRiskReturnSelectedRangeChanged && this.props.onRiskReturnSelectedRangeChanged(mapping[rangeCode])
  }

  updateButtonsState() {
    const mappingReverse = {
      [ALL_VALUE]: ALL,
      '1y': YEARS_1,
      '3y': YEARS_3,
      '5y': YEARS_5,
    }

    let codesToShow = []

    if (this.props.data) {
      for (let [code, values] of Object.entries(this.props.data)) {
        if (values) {
          codesToShow.push(mappingReverse[code]);
        }
      }

      this.setState({
        codesToShow
      })
    }
  }

  componentDidMount() {
    this.updateButtonsState()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.data && this.props.data !== prevProps.data) {
      this.updateButtonsState()
    }
  }

  renderChart() {
    const {classes, width} = this.props;

    let data = [];

    const isMobile = ["xs"].includes(width);
    // NOTE: we need to ovverride exactly the same fields as they are "cashed" each render

    let assetsData = this.props.portfolioData['portfolio_data']['data'][0]['components']

    let mapping = {}

    assetsData.forEach(item => {
      const assetInfo = item['financial_information']['asset_info_data'];

      const name = getInstrName(assetInfo);

      mapping[assetInfo['isin']] = {
        name: name ? name : assetInfo['isin'],
        weight: item['weight']
      }
    })

    mapping['portfolio'] = {
      name: this.props.portfolioData['portfolio_data']['data'][0]['name'],
      weight: 1
    }

    let counter = 0;

    let maxValue = 0;

    let sumX = 0;
    let sumY = 0;
    let seriesWithData = 0;


    if (this.state.range && this.props.data && this.props.data[this.state.range]) {

      if (this.props.portfolioRiskReturnData && this.props.portfolioRiskReturnData[this.state.range]) {
        for (let [isin, item] of Object.entries(this.props.portfolioRiskReturnData[this.state.range])) {

          let newValue = Math.abs(item['return'] * 100)

          if (maxValue < newValue) {
            maxValue = newValue;
          }

          const x = item['volatility'] * 100;
          const y = item['return'] * 100;

          if (!_.isNaN(x) && !_.isNaN(y)) {
            seriesWithData += 1;
            sumX += x;
            sumY += y;
          }

          data.push({
            name: mapping[isin]['name'],
            color: CHART_COLORS[counter % CHART_COLORS.length],
            data: [{
              x,
              y,
              weight: undefined
            }],
            shadow: true,
            marker: {
              symbol: 'triangle'
            }
          })

          counter++;
        }
      }

      for (let [isin, item] of Object.entries(this.props.data[this.state.range])) {

        let newValue = Math.abs(item['return'] * 100)

        if (maxValue < newValue) {
          maxValue = newValue;
        }

        const x = item['volatility'] * 100;
        const y = item['return'] * 100;

        if (!_.isNaN(x) && !_.isNaN(y)) {
          seriesWithData += 1;
          sumX += x;
          sumY += y;
        }

        data.push({
          name: mapping[isin]['name'],
          isin: isin,
          color: CHART_COLORS[counter % CHART_COLORS.length],
          data: [{
            x,
            y,
            weight: mapping[isin]['weight'] * 100,
            onTooltipClick: (_item) => openMorningStartIsin(isin),
          }],
          shadow: true
        })

        counter++;
      }

      data = _.orderBy(data, asset => asset.data[0].weight, ['desc'])

      let mediumX = seriesWithData ? sumX / seriesWithData : 0;
      let mediumY = seriesWithData ? sumY / seriesWithData : 0 ;

      return <div className={classes.chartContainer}>
        <ProViewRiskReturnChart
          series={data}
          maxYValue={maxValue}
          pointFormat={POINT_FORMAT}
          mediumX={mediumX}
          mediumY={mediumY}
          pointFormatPF={DEFAULT_POINT_FORMAT}
          tooltipOptions={{shape: 'square', shared: true,}}
          chartOptions={{height: isMobile ? 450 : 350,}}
          xLabelFormatter={function () {
                  return parseFloat(this.value.toFixed(3));
          }}
          mirrorYAxis
          withGridLine
          // legendOptions={legendOptions}
        />
        <Legend data={data} flexDirection={"row"} asLink/>
      </div>
    } else {
      return <p>Dieses Diagramm kann erst 30 Tage nach der ersten Transaktion gezeigt werden</p>
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <TimeRangesButtonsPad
          className={classes.buttonsPad}
          onRangeChange={this.onRangeChange}
          disabled={this.props.loading}
          codesToShow={this.state.codesToShow}
        />
        {this.props.error
          ? (<span className={classes.errorContainer}>{this.props.error}</span>)
          : (this.props.data && this.props.portfolioData) ? this.renderChart() : (
            <span className={classes.errorContainer}>{DEFAULT_EMPTY_SECTION_MESSAGE}</span>)}
      </div>
    )
  }
}

export default withStyles(styles)(withWidth()(ChartRiskReturn))
