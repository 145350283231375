import { makeStyles } from "@material-ui/core/styles";
import { FORM_FIELD_HEIGHT } from '../common_styles';

export default makeStyles((theme) => ({
  selectIcon: {
    color: '#202A38'
  },
  select: {
    height: FORM_FIELD_HEIGHT,
    [theme.breakpoints.down('sm')]: {
      height: '35px !important',
    },
  },
  selectRoot: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    padding: '15.5px 14px'
  },
  menuPaper: {
    border: 'none',
    borderRadius: 4,
    boxShadow: '0px 3px 15px #00000033',

    maxHeight: 350,

    '&:hover': {
      backgroundColor: 'white !important'
    },

    '&::-webkit-scrollbar': {
      width: 8,
      marginRight: 5
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D8DCDF',
      borderRadius: 4,
    }
  }
}))