import React from 'react';
import {Grid} from "@material-ui/core";
import {ProductsList, ProductSourceSelectionModal, ProductsSearch} from "../index";
import {
  COULD_NOT_GET_PRODUCT_DETAILS,
  EXISTING_COMPARISON_STORAGE_KEY, EXTEND_COMPARISON_WITH_PRODUCTS_STORAGE_KEY,
  NEW_COMPARISON_STORAGE_KEY, PROCESS_TYPES,
  PRODUCT_TYPE,
  PRODUCTS_TO_COMPARE_MAX_COUNT
} from "../../constants";
import clsx from "clsx";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import useStyles from "../../styles";
import _ from "lodash";
import {arrayMove} from "react-sortable-hoc";
import {executeIfPathExist, getInvestmentDynamicPath} from "../../../InvestmentPlatform/utils";
import {displayErrorSnackBar} from "../../../../components/SnackbarProvider/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import withNotification from "../../../../components/NotificationProvider";
import {buildProductIdUniq, getProductsIdsUniqList, loadComparisonProductsDetails} from "../../utils";
import {useConfirmationModalContext} from "../../../../components/ConfirmationModalContextProvider";
import {setProductComparison} from "../../actions";
import {createSelector} from "reselect";
import {getFromStorage, removeFromStorage} from "../../../../utils/storage";
import {getInvestmentPlatformSelector} from "../../../../utils/redaxSelectors";

const getProductsComparison = (state) => state.get('productsComparison');

export const getExistingProductsComparisonInstanceSelector = createSelector(
  [getProductsComparison],
  (productsComparison) => productsComparison.toJS()[EXISTING_COMPARISON_STORAGE_KEY]
);

export const getNewProductsComparisonInstanceSelector = createSelector(
  [getProductsComparison],
  (productsComparison) => productsComparison.toJS()[NEW_COMPARISON_STORAGE_KEY]
);

const mapStateToProps = (state) => ({
  investmentPlatform: getInvestmentPlatformSelector(state),
  [NEW_COMPARISON_STORAGE_KEY]: getNewProductsComparisonInstanceSelector(state),
  [EXISTING_COMPARISON_STORAGE_KEY]: getExistingProductsComparisonInstanceSelector(state)
});

const getProductId = (product) => {
  // In case product has depotNumber or product is array (aggregated portfolios) - it is customer portfolio
  if (!_.isArray(product) && !product.hasOwnProperty('depotNumber')) {
    return product.isin
  }

  if (_.isArray(product)) {
    return _.sortBy(product, (p) => p.depotNumber).map((p) => p.depotNumber).join('_');
  }

  return `${product.depotNumber}`;
}


const getExtendProductsStorageKey = (comparison) => {

  let storageKey = EXTEND_COMPARISON_WITH_PRODUCTS_STORAGE_KEY;
  const id = _.get(comparison, 'id');
  if (!!id) {
    storageKey += `_${id}`
  }

  return storageKey;
}


/**
 * Extend list of comparison's products with products from locale storage
 * (products, that should be added to comparison from other then Investment Comparison pages).
 * In case new comparison should be extended with products - id field should not be in comparison object.
 * @param {{id?: Number, products: Array<object>}} comparison Comparison data
 */
const extendComparisonWithProductsFromStorage = (comparison) => {

  const products = getFromStorage(getExtendProductsStorageKey(comparison));
  if (!products) {
    return;
  }

  // Only new products should be added to comparison.
  const existingComparisonProductsIdentifiers = getProductsIdsUniqList(comparison.products);

  comparison.products.push(...JSON.parse(products)
    .filter((product) => !existingComparisonProductsIdentifiers
      .includes(buildProductIdUniq(product.product_id, product.type)))
  );

}

export default connect(mapStateToProps)(withNotification(withRouter((props) => {

  const {
    comparison,
    processType
  } = props;

  const modalContext = useConfirmationModalContext();

  const classes = useStyles();

  const [products, setProducts] = React.useState([]);
  const [activeSource, setActiveSource] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const getStorageKey = () => {
    const id = _.get(comparison, 'id');
    return _.isNil(id) ? NEW_COMPARISON_STORAGE_KEY : EXISTING_COMPARISON_STORAGE_KEY
  };

  const setPreloadedProducts = (forceProducts) => {

    const productsToStore = (forceProducts || products).map((product, index) => {
      let productOrder = _.get(product, 'configuration.order');
      if (_.isNil(productOrder)) {
        productOrder = index;
      }
      _.set(product, 'configuration.order', productOrder);
      return product
    })

    props.dispatch(setProductComparison({...comparison, products: productsToStore}, getStorageKey()));

    return productsToStore;
  };

  const getPreloadedProducts = async() => {

    let storageComparison = props[getStorageKey()];

    // comparison is passed on edit/copy and contains products, set them to storage
    if(!_.isNil(comparison) || _.isNil(storageComparison)){
      setLoading(true);
      // fetch data for comparisons

      const _comparison = comparison || {products: []};

      try{
        if (processType == PROCESS_TYPES.EXTEND) {
          extendComparisonWithProductsFromStorage(_comparison);
        }
        if (_comparison.products.length) {
          const productsDetails = await loadComparisonProductsDetails(_comparison, modalContext, props.displayNotification);
          if (_comparison.id) {
            return setPreloadedProducts(productsDetails);
          }
          return productsDetails
        }
      } catch (e) {
        props.displayNotification('error', COULD_NOT_GET_PRODUCT_DETAILS);
        return []
      } finally {
        setLoading(false)
      }
    }

    if (!storageComparison) {
      return [];
    }

    return storageComparison.products || [];

  }

  React.useEffect(() => {
    (async () => {
      const _products = await getPreloadedProducts();
      setProducts(_.sortBy(_products, (product) => _.get(product, 'configuration.order')));
    })();

    return () => {
      removeFromStorage(getExtendProductsStorageKey(comparison))
    }
  }, [])

  const productsIdentifiers = products.map((product) => product.product_id);

  // convert product_id to string as saved productsIdentifiers are strings
  const productIdExists = (product_id) => productsIdentifiers.includes(`${product_id}`);

  const validateProductsToAdd = (productsToAdd, sourceToAdd) => {

    const _productsToAdd = _.filter((productsToAdd), (product) => !productIdExists(getProductId(product)));
    const _sourceToAdd = !!sourceToAdd && !productIdExists(`${activeSource}_${sourceToAdd.id}`)
      ? sourceToAdd : null

    if (products.length + _productsToAdd.length + (!!_sourceToAdd ? 1 : 0) > PRODUCTS_TO_COMPARE_MAX_COUNT) {
      props.dispatch(displayErrorSnackBar(`Maximale Anzahl von ${PRODUCTS_TO_COMPARE_MAX_COUNT} Produkten erreicht`));
      return false;
    }
    return true
  }

  const handleSortEnd = ({oldIndex, newIndex}) => {
    setProducts((products) => arrayMove(products, oldIndex, newIndex).map((product, index) => {
      _.set(product, 'configuration.order', index);
      return product;
    }))
  };

  const handleDeleteProduct = (product) => {
    setProducts((products) => _.filter(products,(p) => p.product_id !== product.product_id))
  }

  const handleAssetsUpdate = (products) => {
    // Filter out already existing assets. Use isin as product identifier for assets.

    const newProducts = _.filter((products), (product) => !productIdExists(getProductId(product)));

    if (!validateProductsToAdd(newProducts)) {
      return;
    }

    setProducts((existingProducts) => {
      return _.concat(existingProducts, newProducts.map((product, index) => {
        const newIndex = existingProducts.length + index;

        return {
          product_id: getProductId(product),
          type: product.hasOwnProperty('isin')
            ? PRODUCT_TYPE.ASSET : PRODUCT_TYPE.CUSTOMER_PORTFOLIO,
          data: product,
          highlighted: false,
          active: true,
        }
      }))
    })
  }

  const productsListTitle = (() => {
    const productWord = `Produkt${products.length > 1 ? 'e' : ''}`;

    return (
      <>
        Ausgewählte{products.length <= 1 && "s"} {productWord} {products.length > 0 && <span><span style={{whiteSpace: 'nowrap'}}>({products.length} von</span> maximal {PRODUCTS_TO_COMPARE_MAX_COUNT} Produkten)</span>}
      </>
    )
  })();

  const cleanActiveSource = () => setActiveSource(undefined);

  const handleSelectProducts = (selectedInstruments, selectedSource) => {

    if (!validateProductsToAdd(selectedInstruments, selectedSource)) {
      return;
    }

    if (!_.isEmpty(selectedInstruments)) {
      handleAssetsUpdate(selectedInstruments);
    }
    if (!!selectedSource) {

      const sourceId = `${selectedSource.id}`;
      const sourceExists = productsIdentifiers.includes(sourceId);

      let mpNumber = '';
      if (activeSource === 'private_investment') {
        mpNumber = _.get(selectedSource, 'portfolio_id');
      } else if (activeSource === 'modellportfolio') {
        mpNumber = _.get(selectedSource, 'agency_portfolio_id');
      }

      _.set(selectedSource, 'mp_number', mpNumber);

      if (!sourceExists) {
        setProducts((products) => {
          const newIndex = products.length;
          products.push({
            product_id: sourceId,
            type: {
              "musterdepot": PRODUCT_TYPE.MUSTERDEPOT,
              "modellportfolio": PRODUCT_TYPE.MODEL_PORTFOLIO,
              "private_investment": PRODUCT_TYPE.PRIVATE_INVESTMENT
            }[activeSource],
            data: selectedSource,
            highlighted: false,
            active: true
          });
          return products;
        })
      }
    }
    return true
  }

  const handleStartComparisonButtonClick = (event, skipSetPreloadedProducts) => {

    const comparisonId = _.get(comparison, 'id')
    const productsComparisonExists = !_.isNil(comparisonId);

    executeIfPathExist(props.investmentPlatform.routes, 'PRODUCTS_COMPARISON_NEW', path => {
      if(!skipSetPreloadedProducts) setPreloadedProducts();

      // if not creation process set process_type
      let query_params = {};
      if(productsComparisonExists && !_.isNil(processType)){
        query_params.process_type = processType;
      }
      query_params = new URLSearchParams(query_params).toString();

      props.history.push(`/${getInvestmentDynamicPath()}${path.replace(':id', comparisonId)}/?${query_params}`)
    }, productsComparisonExists && ':id')

  }

  return(

    <Grid container>

      {/* render comparison description */}
      {comparison && comparison.description &&
        <Grid item xs={12} className={classes.section} style={{marginBottom: 15}}>
          <Grid item xs={12} className={classes.sectionHeader}>Beschreibung</Grid>
          <Grid item xs={12} className={classes.description}>
            {comparison.description}
          </Grid>
        </Grid>
      }

      <Grid item xs={12} className={classes.section} style={{marginBottom: 15}}>
        <ProductsSearch
          onAssetsUpdate={handleAssetsUpdate}
          products={products}
          onActiveSourceChange={setActiveSource}
          disabled={products.length >= PRODUCTS_TO_COMPARE_MAX_COUNT}
          extraFilters={{asset_class: 0}}
        />
      </Grid>
      <Grid item xs={12} className={clsx(classes.section, classes.productsListSection)}>
        <h3>{productsListTitle}</h3>
        <ProductsList
          products={products}
          onSortEnd={handleSortEnd}
          onDeleteProduct={handleDeleteProduct}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12} className={classes.section}>
        <PrimaryButton
          text='Vergleich starten'
          onButtonClick={handleStartComparisonButtonClick}
          disabled={!products.length}
          class={classes.primaryButton}
        />
      </Grid>

      <ProductSourceSelectionModal
        source={activeSource}
        open={!!activeSource}
        onClose={cleanActiveSource}
        onSelectProducts={handleSelectProducts}
      />
    </Grid>
  )

})))