import {makeStyles} from "@material-ui/core";

const styles = (theme) => ({
  dialogContentRoot: {
    '& .MuiContainer-root': {
      padding: 0
    },
    '& table td': {
      '&:last-child': {
        width: 70,
        paddingRight: 16
      },
      '&:first-child': {
        width: 30,
        paddingLeft: 16
      },
      [theme.breakpoints.up('md')]: {
        '&:nth-child(3)': {
          width: 200
        },
        '&:nth-child(4)': {
          width: 85
        }
      },
      [theme.breakpoints.down('sm')]: {
        '&:nth-child(3)': {
          width: 200
        }
      },
      [theme.breakpoints.down('xs')]: {
        '&:nth-child(3)': {
          width: 70,
          paddingRight: 16
        }
      }
    }
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  header: {
    height: 16
  },
  dialogActionsRoot: {
    padding: '24px',
  },
  buttonRoot: {
    width: '2.5rem',
    height: '2.5rem',
    lineHeight: '2.5rem',
    padding: 0,
    margin: '0 1px',
    color: 'white',
    fontSize: '.9375rem',
    minWidth: 'auto',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  buttonRootAdd: {
    background: '#78bd5d',
    '&:hover': {
      background: '#62AB46'
    }
  },
  buttonRootRemove: {
    background: '#fd7e14',
    '&:hover': {
      background: '#F5760E'
    }
  }
});

export default makeStyles(styles);