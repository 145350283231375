import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from '@material-ui/core/Grid'
import styles from "./styles";
import _ from 'lodash'
import CustomerFullnameMenu from "../../../../../../../../components/CustomerFullnameMenu/CustomerFullnameMenu";

const CustomerInfo = (props) => {
  const {
    classes,
    customer,
    withSRRI
  } = props;

  return (
    <Grid container spacing={2} className={classes.customerInfo}>
      <Grid item>
        <div>
          <CustomerFullnameMenu
            customer={customer}
            customerFullname={<span style={{fontSize: 16}}>{customer.customer_full_name}</span>}
          />
        </div>
        <div>Kunden-Nr: <span className={classes.customerId}>{customer.customer_id}</span></div>
      </Grid>
      {withSRRI && (
        <Grid item>
          <div>Risikoklasse: <span className={classes.customerId}>{_.get(customer, 'last_srri.srri') || 'noch nicht ermittelt'}</span></div>
        </Grid>
      )}
    </Grid>
  );
};


CustomerInfo.defaultProps = {
  withSRRI: true
};

export default withStyles(styles)(CustomerInfo);