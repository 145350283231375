import React from 'react';

import {
  PortfolioUtils,
} from '../../../../../../../utils/utils';
import _ from 'lodash';
import ChartSectionBordered from "../../../../../components_v2/ChartSectionBordered/ChartSectionBordered";
import {StructureTable} from "../../../../../components_v2/StructureTable/StructureTable";
import {
  CustomerDashboardNoDataPlaceholder
} from "../../../../../../FactSheetsPage/components/NoDataPlaceholder";
import {portfolioStructurePropsAreEquals} from '../utils';

const PortfolioAssetAllocationStructure = React.memo((props) => {
  const {
    dashboardData,
    preferredColor,
    expanded,
    onExpanded,
  } = props;

  const portfolioStructure = dashboardData && dashboardData.portfolio_structure;

  let data = [];
  if (portfolioStructure) {
    data = PortfolioUtils.getPortfolioAssetAllocations(portfolioStructure)
  }
  const hasData = !_.isEmpty(data);

  return (
    <ChartSectionBordered
      title={<b>Assetallokation</b>}
      expanded={expanded}
      onExpanded={onExpanded}
      skipContentPadding={hasData}
      content={(
        <>
          {hasData ? (
            <StructureTable
              series={data || []}
              preferredColor={preferredColor}
            />
          ) : (
            <CustomerDashboardNoDataPlaceholder />
          )}
        </>
      )}
    />
  )
}, portfolioStructurePropsAreEquals);

export default PortfolioAssetAllocationStructure;
