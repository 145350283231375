import React from 'react';
import _ from 'lodash';
import {connect} from "react-redux";
import ChartSectionBordered, {ChartSectionPortfolioName} from "../ChartSectionBordered/ChartSectionBordered";
import {Checkbox, CircularProgress, IconButton, Grid, makeStyles} from "@material-ui/core";
import DashboardTable from "../../../../components/DashboardTable";
import {DepotSummaryNavigation} from "../../components/Widgets/components/InstrumentList/components/InstrumentListItem";
import {useSelectedItems} from "../../../../hooks/useSelectedItems";
import {PRODUCT_TYPE, PRODUCTS_TO_COMPARE_MAX_COUNT} from "../../../ProductComparison/constants";
import {displayErrorSnackBar} from "../../../../components/SnackbarProvider/actions";
import {getTableStructureSwitch} from "../../../../components/ChartsV2/InstrumentsAllocationTable/table-data";
import ResponsiveDialog from "../../../../components/ResponsiveDialog/ResponsiveDialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {PrimaryButton} from "../../../../components/Buttons";

import useStyles from './styles';
import clsx from "clsx";
import {
  useAddProductsToComparisonContext
} from "../../../../components/SelectForProductsComparisonModal/SelectForProductsComparisonModal";
import CloseIcon from "@material-ui/icons/Close";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../constants";

/**
 * For Dashboard V2
 */
const SelectableDepotAndInstrumentsTable = ({depot, ContainerProps, InstrumentsProps, expanded, onExpanded}) => {

  const classes = useStyles();

  const instruments = (depot.components || []).filter((component) => !!component.isin).map((component) => ({
    ...component,
    uniq_id: `${component.isin}_${depot.id}` // Create uniq identifier so we could handle case when asset exists in many depots
  }));

  return (
    <ChartSectionBordered
      dataId={`comparison-table-${depot.id}`}
      title={(
        <>
          <Checkbox
            color="primary"
            className={clsx(classes.containerCheckbox, _.get(ContainerProps, 'classes.containerCheckbox'))}
            checked={ContainerProps.checked}
            onChange={() => ContainerProps.onChange(depot)}
          />
          <ChartSectionPortfolioName portfolio={depot} />
        </>
      )}
      titleControl={(<DepotSummaryNavigation depot={depot} />)}
      showTitleControlFolded
      expanded={expanded}
      isPortfolioSection
      skipContentPadding
      borderLeftColor={depot.color}
      onExpanded={onExpanded}
      content={(
        <div style={{overflowX: 'auto', overflowY: "hidden"}}>
          <DashboardTable
            tableLayout={"auto"}
            structure={InstrumentsProps.tableStructure}
            dataSource={instruments || []}
            expanded={true}
            tableClasses={InstrumentsProps.tableClasses}
            withFooter={false}
            options={{
              onAllSelect: () => InstrumentsProps.handleSelectAll(depot, instruments),
              totalInstrumentsCount: InstrumentsProps.totalInstrumentsCount || (instruments && instruments.length),
              onInstrumentSelect: (instrument) => InstrumentsProps.handleSelect(instrument, depot),
              selected: InstrumentsProps.selected,
              ...(InstrumentsProps.tableOptions || {})
            }}
          />
        </div>
      )}
    />
  )
}

SelectableDepotAndInstrumentsTable.defaultProps = {
  InstrumentsProps: {},
  ContainerProps: {}
}


const useDepotSelectionInstrumentTableClasses = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: '#f9f9f9',

    '& td': {
      color: '#80858C !important'
    }

  },
  tableCell: {
    [theme.breakpoints.down('md')]: {
      width: 75
    },
    '&:first-child': {
      width: '350px !important',
      paddingLeft: 16
    },
    '&:last-child': {
      paddingRight: 10
    },
    '&.sustainability-cell': {
      width: 83,
      minWidth: 83
    }
  },
}));


const IDENTIFIER_KEY = 'data.uniq_id';


export const SelectCustomerDepotsForProductsComparisonModal = connect()(({customerId, instrumentsData, onClose, investmentPlatform, dispatch, open}) => {

  const classes = useStyles();
  const instrumentTableClasses = useDepotSelectionInstrumentTableClasses();
  const {onAddProductsToComparison, comparisonStartedTrigger} = useAddProductsToComparisonContext();

  const depots = React.useMemo(() => {
    if (!instrumentsData.data) {
      return [];
    }
    return instrumentsData.data.filter((depot) => !depot.is_empty && !depot.not_connected)
  }, [instrumentsData.updatedAt]);

  const expandedItems = useSelectedItems('id');

  React.useEffect(() => {
    if (_.isEmpty(depots)) return;

    expandedItems.cleanSelected();
    if (depots.length == 1) {
      //If there is only one depot - expand it by default
      expandedItems.handleToggleItem({id: _.get(depots, '0.id')});
    }

  }, [depots])

  const {selectedItems, handleToggleItem, handleSelectMany, handleUnselectMany, cleanSelected} = useSelectedItems(IDENTIFIER_KEY);

  const selectedInstruments = Object
    .values(selectedItems)
    .filter((product) => product.productType === PRODUCT_TYPE.ASSET);

  React.useEffect(() => {
    if (!!comparisonStartedTrigger) {
      onClose();
      cleanSelected();
    };
  }, [comparisonStartedTrigger]);

  const handleContainerCheckboxChange = (depot) => {
    handleToggleItem({data: {...depot, customer_id: customerId, uniq_id: depot.id}, productType: PRODUCT_TYPE.CUSTOMER_PORTFOLIO}, IDENTIFIER_KEY);
  }

  const handleInstrumentSelect = (instrument, depot) => {
    handleToggleItem({data: instrument, productType: PRODUCT_TYPE.ASSET, depotId: depot.id}, IDENTIFIER_KEY);
  }

  const handleSelectAllInstruments = (depot, depotInstruments) => {
    const depotSelectedInstruments = Object
      .values(selectedItems)
      .filter((product) => product.productType === PRODUCT_TYPE.ASSET && product.depotId == depot.id);
    const handler = depotInstruments.length === depotSelectedInstruments.length
      ? handleUnselectMany : handleSelectMany;

    handler(depotInstruments.map((instrument) => ({
      data: instrument,
      productType: PRODUCT_TYPE.ASSET,
      depotId: depot.id
    })), IDENTIFIER_KEY);
  }

  const handleAddProductsToComparison = (isNewComparison) => () => {

    const uniqProductsMap = {};
    Object
      .values(selectedItems)
      .forEach((product) => {
        const productKey = product.productType == PRODUCT_TYPE.ASSET
          ? product.data.isin
          : product.data.id;
        uniqProductsMap[productKey] = product;
      });

    const uniqProducts = Object.values(uniqProductsMap);

    if (uniqProducts.length > PRODUCTS_TO_COMPARE_MAX_COUNT) {
      dispatch(displayErrorSnackBar('Maximale Anzahl von 10 Produkten erreicht.'));
      return;
    }

    onAddProductsToComparison(uniqProducts, isNewComparison)
  }

  if (!open) {
    return null
  }

  const [
    tableStructure,
    tableClasses
  ] =  [
    getTableStructureSwitch(),
    instrumentTableClasses
  ]

  const actionsDisabled = !Object.keys(selectedItems).length;

  return (
    <ResponsiveDialog open={open} onClose={onClose}>
      <DialogTitle>
        <div className={classes.header}>
          <h2><b>Vergleich</b></h2>
          <IconButton disableRipple aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        {instrumentsData.loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={2}>
            {depots.length > 0 ? (
              <>
                <Grid item xs={12}>
                  <span className={classes.subHeader}>Wählen Sie ganze Depots oder einzelne Instrumente innerhalb der Depots, um bis zu 10 Produkte an den Investmentvergleich zu übergeben.</span>
                </Grid>
                {depots.map((depot) => (
                  <Grid item xs={12}>
                    <SelectableDepotAndInstrumentsTable
                      depot={depot}
                      expanded={depot.id in expandedItems.selectedItems}
                      onExpanded={() => expandedItems.handleToggleItem({id: depot.id})}
                      ContainerProps={{
                        checked: depot.id in selectedItems,
                        onChange: handleContainerCheckboxChange
                      }}
                      InstrumentsProps={{
                        tableStructure,
                        tableClasses,
                        handleSelectAll: handleSelectAllInstruments,
                        handleSelect: handleInstrumentSelect,
                        selected: selectedInstruments.filter((product) => product.depotId == depot.id).map((product) => product.data),
                      }}
                    />
                  </Grid>
                ))}
              </>
            ): (
              <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <p>{DEFAULT_EMPTY_SECTION_MESSAGE}</p>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      {!instrumentsData.loading && (
        <DialogActions classes={{root: classes.dialogActionsRoot}}>
          <Grid container spacing={2} alignItems={"center"} justify={"flex-end"}>
            <Grid xs={12} sm={true} item>
              <Grid container justify="flex-end" spacing={2}>
                <Grid xs={12} sm={"auto"} item>
                  <PrimaryButton
                    text='Zu Investmentvergleich hinzufügen'
                    disabled={actionsDisabled}
                    onButtonClick={handleAddProductsToComparison(false)}
                    style={{width: '100%'}}
                  />
                </Grid>
                <Grid xs={12} sm={"auto"} item>
                  <PrimaryButton
                    text='Neuer Investmentvergleich starten'
                    disabled={actionsDisabled}
                    onButtonClick={handleAddProductsToComparison(true)}
                    style={{width: '100%'}}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </ResponsiveDialog>
  )
})