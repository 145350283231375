const riskanalyse_page_1 = {
  selector: '#risk-dashboard-header-tour-element',
  key: 'risk_dashboard_header'
};

export const getRiskAnalyseChapterSteps = () => {
  return [
    riskanalyse_page_1
  ];
};
