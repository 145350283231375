import React from 'react';

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button/Button";

import styles from './styles';
import IconButton from "@material-ui/core/IconButton";
import SelectCaret from '../../../../../../../../components/Icons/SelectCaret';

const ExpandButton = (props) => {
  const {
    classes,
    expanded,
    onChanged,
    disabled
  } = props;


  return (
    <Button
      variant={"contained"}
      color={"primary"}
      className={classes.expandButton}
      onClick={() => onChanged(!expanded)}
      disableRipple={true}
      disabled={disabled}
    >
      <SelectCaret className={classes.rotateIcon} style={{transform: `rotate(${ expanded ? '180deg' : '0deg'})`, color: disabled ? 'rgba(0, 0, 0, 0.26)' : 'white'}} />
      <span className={classes.expandButtonText}>{expanded ? 'Zuklappen' : 'Aufklappen'}</span>
    </Button>
  );
};


export const ExpandIconButton = withStyles(styles)((props) => {

  const {
    classes,
    expanded,
    onChanged,
    disabled
  } = props;

  return (
    <IconButton
      onClick={() => onChanged(!expanded)}
      disableRipple={true}
      disabled={disabled}
      className={classes.expandIconButton}
    >
      {expanded ? (<i className="far fa-angle-up"></i>) : (<i className="far fa-angle-down"></i>)}
    </IconButton>
  )
});


export default withStyles(styles)(ExpandButton);