import _ from "lodash";

export const isGroupNameWithErrors = (name) => {
  if(!name.trim()) {
    return {name: 'groupNameMissing', value: 'Bitte geben Sie einen gültigen Namen ein'};
  }
  let status = name.split(/[\-\s]/g).map(el => {
    return el.length < 25;
  });
  if(!status.every(Boolean)){
    return {name: 'groupNameError', value: 'Für einen Zeilenumbruch des Reportnamens fügen Sie in diesen bitte ein ´-´oder ein Leerzeichen ein.'};
  }
  return false;
};

export const addFiltersToDynamicGroupBody = (body, isDynamicGroupProcess, currentFilters, filtersOptions) => {
  if(isDynamicGroupProcess){
    let appliedFilters = _.cloneDeep(_.get(currentFilters, 'filters', {}))
    let availableFilters = _.cloneDeep(filtersOptions || {})

    // loop over applied filters to remove those which are not applied
    Object.entries(appliedFilters).map(([key, value]) => {

      // filter is valid if it is not empty list or dict
      let isEmptyOrBool = _.isNil(value) || _.isEmpty(value) || _.isBoolean(value)

      // if filter is valid list or dict -> check if it was changed
      let isNotApplied = key in availableFilters && _.isEqual(value, availableFilters[key])

      // remove invalid filter
      if(isEmptyOrBool || isNotApplied){
        delete appliedFilters[key]
      }

    })

    body['dynamic_filters'] = appliedFilters
  } else {
    body['dynamic_filters'] = null
  }
}
