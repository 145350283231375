/* React imports*/
import React from 'react';
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import styles from "./styles";
import clsx from "clsx";
import _ from 'lodash';

/* Material UI Components*/
import {TextField, withStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import SettingsIcon from "@material-ui/icons/Settings";
import EditIcon from '@material-ui/icons/Edit';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TickIcon from '@material-ui/icons/CheckCircleOutline';
import CrossIcon from '@material-ui/icons/CancelOutlined';

/* BCA Components */
import AnalysisCard from "../VirtualPortfolioPage/components/AnalysisCard";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import InstrumentsFilter from "./components/InstrumentsFilter";
import ModalInstruments from "./modals/ModalInstruments";
import ConfirmationDialog from "./modals/ConfirmationDialog";
import SuccessDialog from "./modals/SuccessDialog";
import BenchmarkSettingsDialog from "./modals/BenchmarkSettingsDialog";

/* BCA modules */
import {toGermanFormat} from "../../utils/numberFormater";
import {
  openMorningStartIsin, 
  withPercentOrDash, 
  openFundShopInfosIsin,
} from "../../utils/utils";
import {
  parseResponse,
  VirtualPortfolioHandlerResource
} from "../../utils/api";
import {
  buildCurrentCustomerDashboardVirtualPath,
  buildCurrentCustomerVirtualPortfolioManager,
} from "../../routes";
import {isAssetImported, isPortfolioImported} from "../../utils/virtualPortfoliosUtils";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "../../components/Tooltip";
import {CONFIG} from "./constants";


class VirtualPortfolioManager extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      portfolio: {},
      portfolioId: props.match.params.portfolio_id,
      customerId: props.auth.user.customer_id,
      agencyVirtualLink: props.auth.user.agency && props.auth.user.agency.virtual_instrument_buy_template,
      portfolioError: undefined,
      portfolioName: undefined,
      portfolioDataSaved: undefined,
      portfolioDataBuffered: undefined,
      portfolioLoading: true,
      showModal: false,
      selectedAssetData: undefined,
      assetCreatingError: undefined,
      instrumentError: null,
      instrumentProcessing: false,
      showDeleteConfirmation: false,
      assetDeletingInProgress: false,
      assetDeletingError: false,
      instrumentToDeleteId: undefined,
      instrumentToDeleteName: undefined,
      showBenchmarkDialog: false,
      showSuccessDeleteDialog: false,
      selectedAssetId: undefined,
      showPortfolioNameEditField: false,
      unsavedPortfolioName: undefined,
      backButtonShown: false,
    }

    this.onAddAssetClick = this.onAddAssetClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.processInstrument = this.processInstrument.bind(this);
    this.closeInstrumentDeletingModal = this.closeInstrumentDeletingModal.bind(this);
    this.deleteInstrument = this.deleteInstrument.bind(this);
    this.closeSuccessDeleteDialog = this.closeSuccessDeleteDialog.bind(this);
    this.closeBenchmarkDialog = this.closeBenchmarkDialog.bind(this);
    this.openBenchmarkDialog = this.openBenchmarkDialog.bind(this);
    this.initInstrumentEditing = this.initInstrumentEditing.bind(this);
    this.openPortfolioEditField = this.openPortfolioEditField.bind(this);
    this.closePortfolioEditField = this.closePortfolioEditField.bind(this);
    this.handlePortfolioNameChange = this.handlePortfolioNameChange.bind(this);
    this.updatePortfolioName = this.updatePortfolioName.bind(this);
    this.backToDashboard = this.backToDashboard.bind(this);
  }

  componentDidMount() {

    const passedData = this.props.location.state

    if (passedData && passedData.portfolio && passedData.portfolioName) {
      this.setState({
        portfolioLoading: false,
        portfolioDataBuffered: passedData.portfolio['components'],
        portfolioName: passedData.portfolioName,
        unsavedPortfolioName: passedData.portfolioName
      })
    } else {
      this.fetchPortfolioData()
    }
  }

  fetchPortfolioData() {
    const {customerId, portfolioId} = this.state

    this.setState({
      portfolioLoading: true
    })

    VirtualPortfolioHandlerResource.getPortfolio(customerId, portfolioId)
        .then((response) => {
          parseResponse(response, 'portfolio',
              data => this.processPortfolioData(data),
              error => this.processError(error))
        })
        .catch(error => this.processError(error))
  }

  processInstrument(instrumentData, isUpdate, oneTimePaymentData=undefined, paymentPlanData=undefined) {

    this.setState({
      instrumentProcessing: true
    })

    let data = {
      instrument_data: instrumentData
    }

    if (paymentPlanData) {
      data['payment_plan'] = paymentPlanData;
    }

    if (oneTimePaymentData) {
      data['one_time_payment_data'] = oneTimePaymentData;
    }

    if (isUpdate) {
      this.updateInstrument(data, instrumentData['asset_id'])
    } else {
      this.createInstrument(data)
    }
  }

  isExistingPortfolio() {
    return this.state.portfolioId && this.state.portfolioId !== 'new';
  }

  createInstrument(data) {
    if (this.isExistingPortfolio()) {
      data['portfolio_id'] = this.state.portfolioId;
    } else {
      data['portfolio_name'] = this.state.portfolioName
    }

    VirtualPortfolioHandlerResource.createInstrument(this.state.customerId, [data])
      .then((response) => {
        parseResponse(response[0], 'instrument',
          (response) => {
            const portfolioId = response['group'][0]['id']
            window.history.pushState({}, null, buildCurrentCustomerVirtualPortfolioManager(portfolioId));
            this.setState({
              portfolioId: portfolioId,
              instrumentProcessing: false,
              backButtonShown: true
            }, () => this.fetchPortfolioData())
          },
          error => {
            this.processInstrumentError(error)
          }
        )
      })
      .catch(error => {
        this.processInstrumentError(error)
      })
  }

  updateInstrument(data, asset_id) {
    data['portfolio_id'] = this.state.portfolioId;

    VirtualPortfolioHandlerResource.updateInstrument(this.state.customerId, asset_id, data)
      .then((response) => {
        parseResponse(response, 'instrument',
          () => {
            this.setState({
              instrumentProcessing: false,
              backButtonShown: true
            }, () => this.fetchPortfolioData())
          },
          error => {
            this.processInstrumentError(error)
          }
        )
      })
      .catch(error => {
        this.processInstrumentError(error)
      })
  }

  deleteInstrument() {
    this.setState({
      assetDeletingInProgress: true
    })

    VirtualPortfolioHandlerResource.deleteInstrument(this.state.customerId, this.state.instrumentToDeleteId)
      .then((response) => {
        parseResponse(response, 'instrument',
          () => {
            if (this.state.portfolioDataBuffered.length > 1) {
              this.fetchPortfolioData()
            } else {
              this.setState({
                portfolioDataBuffered: [],
                showSuccessDeleteDialog: true,
                showDeleteConfirmation: false,
                assetDeletingInProgress: false
              })
            }
            this.setState({
              backButtonShown: true
            })
          },
          error => {
          this.processInstrumentDeletingError(error)
          }
        )
      })
      .catch(error => {
        this.processInstrumentDeletingError(error)
      })
  }

  processPortfolioData(data) {
    try {

      let portfolioData = data[0];
      let showSuccessMessage = this.state.showDeleteConfirmation;
      this.setState({
        portfolioError: undefined,
        portfolioName: portfolioData.name,
        unsavedPortfolioName: portfolioData.name,
        portfolioDataSaved: portfolioData.components,
        portfolioDataBuffered: [...portfolioData.components],
        portfolioLoading: false,
        showModal: false,
        assetDeletingInProgress: false,
        showDeleteConfirmation: false,
        showSuccessDeleteDialog: showSuccessMessage,
        selectedAssetId: undefined,
        portfolio: portfolioData
      })
    } catch (error) {
      this.processError(error)
    }
  }

  processError(error) {

    this.setState({
      portfolioError: error,
      portfolioName: undefined,
      portfolioDataSaved: undefined,
      portfolioDataBuffered: undefined,
      portfolioLoading: false,
      portfolio: {}
    })
  }

  renderLoading () {
    return <div className={this.props.classes.loadingLine}><p/></div>
  }

  renderPortfolioNameError() {
    return <p>Error</p>
  }

  openPortfolioEditField() {
    this.setState({
      showPortfolioNameEditField: true
    })
  }

  closePortfolioEditField() {
    this.setState({
      showPortfolioNameEditField: false
    })
  }

  handlePortfolioNameChange(event) {
    this.setState({
      unsavedPortfolioName: event.target.value
    })
  }

  updatePortfolioName() {

    if(this.isExistingPortfolio()){
      this.setState({
        portfolioLoading: true
      })

      VirtualPortfolioHandlerResource.updatePortfolio(this.state.customerId, this.state.portfolioId, {
        name: this.state.unsavedPortfolioName
      })
      .then((response) => {
        parseResponse(response, 'portfolio', (data) => {
          this.setState({
            portfolioLoading: false,
            portfolioName: data['name'],
            unsavedPortfolioName: data['name'],
            showPortfolioNameEditField: false
          })
        }, (error) => {
          this.setState({
            portfolioLoading: false,
            portfolioError: error,
            showPortfolioNameEditField: false
          })
        })
      })
      .catch((error) => {
        this.setState({
          portfolioLoading: false,
          portfolioError: error,
          showPortfolioNameEditField: false
        })})
    } else {
      this.setState({
        portfolioLoading: false,
        portfolioName: this.state.unsavedPortfolioName,
        showPortfolioNameEditField: false
      })
    }
  }

  isPortfolioNameCorrect() {
    return (
      this.state.unsavedPortfolioName
      && this.state.unsavedPortfolioName.length > 0
      && this.state.unsavedPortfolioName.length <= 30
    )
  }

  renderPortfolioName() {

    const { classes } = this.props;

    return this.state.portfolioName
      ? <div>
        {
          this.state.showPortfolioNameEditField
            ? <div>
              <TextField
                disableRipple={true}
                variant={'outlined'}
                value={this.state.unsavedPortfolioName}
                className={classes.portfolioNameInput}
                onChange={this.handlePortfolioNameChange}
                error={!this.isPortfolioNameCorrect()}
              />
              <Button
                className={classes.editNameButton}
                disableRipple={true}
                onClick={this.closePortfolioEditField}
              >
                <CrossIcon
                  className={classes.icon}
                />
                <div
                  className={classes.iconLabel}
                >
                  Abbrechen
                </div>
              </Button>
              <Button
                className={classes.editNameButton}
                disableRipple={true}
                onClick={this.updatePortfolioName}
                disabled={!this.isPortfolioNameCorrect()}
              >
                <TickIcon
                  className={classes.icon}
                />
                <div
                  className={classes.iconLabel}
                >
                  Speichern
                </div>
              </Button>
            </div>
            : <div>
              {this.state.portfolioName}
              <Button
                className={classes.editNameButton}
                disableRipple={true}
                onClick={this.openPortfolioEditField}
              >
                <EditIcon color={'primary'}/>
              </Button>
            </div>
        }
      </div>
      : ''
  }

  formatNumber(number) {
    return number || number === 0 ? toGermanFormat(number, '', '', 2) : '-'
  }

  initInstrumentDeleting(instrumentId, instrumentName) {
    this.setState({
      showDeleteConfirmation: true,
      instrumentToDeleteId: instrumentId,
      instrumentToDeleteName: instrumentName,
    })
  }

  initInstrumentEditing(instrumentId) {
    this.setState({
      selectedAssetId: instrumentId
    }, () => this.setState({
      showModal: true,
    }))
  }

  processInstrumentDeletingError(error) {
    this.setState({
      assetDeletingInProgress: false,
      assetDeletingError: error
    })
  }

  closeInstrumentDeletingModal() {
    this.setState({
      showDeleteConfirmation: false
    })
  }

  processInstrumentError(error) {
    this.setState({
      instrumentProcessing: false,
      instrumentError: error
    })
  }

  getAssetName(asset) {

    let name = undefined;

    if (asset.hasOwnProperty('financial_information')
      && asset.financial_information.hasOwnProperty('asset_info_data')) {

      const asset_info_data = asset.financial_information.asset_info_data;

      if (asset_info_data.hasOwnProperty('full_name')) {
        name = asset_info_data.full_name
      }

      if (!name && asset_info_data.hasOwnProperty('name')) {
        name = asset_info_data.name
      }

      return name;
    }
  }

  renderTable() {
    const { classes } = this.props;

    const rows = []

    let isPortfolioEmpty = false;

    const isImported = isPortfolioImported(this.state.portfolio)

    if (this.state.portfolioDataBuffered && this.state.portfolioDataBuffered.length > 0) {
      this.state.portfolioDataBuffered.forEach((asset) => {

        let name = this.getAssetName(asset);
        let isin = asset['financial_information']['asset_info_data']['isin'];
        let wkn = asset['financial_information']['asset_info_data']['wkn'] || undefined;

        rows.push(
            <tr key={isin}>
              <td className={classes.leftAligned}>
                {this.state.agencyVirtualLink?
                  <a onClick={() => {openFundShopInfosIsin(isin, this.state.agencyVirtualLink)}} target={'_blank'} className={classes.assetName}>
                    {name ? name : isin}
                  </a>
                  :
                  <a onClick={() => {openMorningStartIsin(isin, null)}} className={classes.assetName}>
                    {name ? name : isin}
                  </a>
                }
                <br />
                {isin} / {wkn ? wkn : '-'}
              </td>
              <td>
                {this.formatNumber(asset['quantity'])}
              </td>
              <td>
                {this.formatNumber(asset['financial_information']['unit_price'])}
              </td>
              <td>
                {moment(asset['price_date']).format('DD.MM.YYYY')}
              </td>
              <td>
                {this.formatNumber(asset['value'])}
              </td>
              <td>
                {this.formatNumber(asset['invested_value'])}
                <br/>
                {asset.hasOwnProperty('investment_period') &&
                  <span className={classes.substring}>{moment(asset['investment_period']['start']).format('DD.MM.YYYY')}</span>
                }
              </td>
              <td className={clsx(asset['profit_value'] >= 0 && classes.profitPositive, asset['profit_value'] < 0 && classes.profitNegative)}>
                {this.formatNumber(asset['profit_value'])}
                <br />
                {asset.hasOwnProperty('investment_period') &&
                <span
                  className={classes.substring}>{moment(asset['investment_period']['end']).format('DD.MM.YYYY')}</span>
                }
              </td>
              <td>
                {withPercentOrDash(asset['weight'])}
              </td>
              {asset.hasOwnProperty('payment_plan') && asset['payment_plan']
                ? <td>
                  {this.formatNumber(asset['payment_plan']['value'])}
                  <br />
                  <span className={classes.substring}>
                    {_.get(CONFIG[asset['payment_plan']['period']], 'title') || asset['payment_plan']['period']}
                  </span>
                </td>
                : <td>-</td>
              }
              <td>
                {isImported && isAssetImported(asset) ?
                  <div>
                  <Tooltip
                    placement={"bottom"}
                    title={'Die Orderhistorie der Wertpapiere wurde in der Vorgängerapplikation DepotStar4 erstellt. Depotstar5 unterstützt keine Mehrfachkäufe/-verkäufe. Aus diesem Grund ist die Bearbeitung für dieses Wertpapier deaktiviert. Bitte beachten Sie, dass die Analysen aufgrund der Datenverfügbarkeit abweichen können. Wir empfehlen Ihnen daher ein neues virtuelles Depot anzulegen.'}
                  >
                    <div>
                      <ErrorIcon className={classes.importedPortfolioIcon}/>
                      <span className={classes.disabledLink}> Bearbeiten </span>
                    </div>
                  </Tooltip>
                  </div>
                  :
                  <div>
                    <Button
                      className={classes.linkButton}
                      onClick={() => this.initInstrumentEditing(asset.id)}
                      disableRipple={true}
                    >
                      Bearbeiten
                    </Button>
                  </div>
                }
                <Button
                  className={classes.linkButton}
                  onClick={() => this.initInstrumentDeleting(asset.id, asset.name)}
                  disableRipple={true}
                >
                  Löschen
                </Button>
              </td>
            </tr>
        )
      })
    } else {
      isPortfolioEmpty = true;
    }

    return <><table className={classes.tableAssets}>
      <thead className={clsx(classes.tableHeader, isPortfolioEmpty && classes.emptyTableHeader )}>
        <tr>
          <td className={classes.leftAligned}>
            <span>Instrumentenname</span>
            <br />
            <span className={classes.headerSubtitle}>ISIN/WKN</span>
          </td>
          <td>
            <span>Stück</span>
          </td>
          <td>
            <span>
              Preis
              <br />
              (EUR)
            </span>
          </td>
          <td>
            <span>Preisdatum</span>
          </td>
          <td>
            <span>
              Marktwert
              <br />
              (EUR)
            </span>
          </td>
          <td>
            <span>
              Einzahlung
              <br />
              (EUR)
            </span>
          </td>
          <td>
            <span>
              Rendite
              <br />
              (EUR)
            </span>
          </td>
          <td>
            <span>Gewichtung</span>
          </td>
          <td>
            <span>
              Sparplan
              <br />
              (EUR)
            </span>
          </td>
          <td>
          </td>
        </tr>
      </thead>
      {rows &&
        <tbody>
          {rows}
        </tbody>
      }
    </table>
    {this.state.backButtonShown &&
      <PrimaryButton
        text={'Fertig und zurück'}
        disableShadow={true}
        onButtonClick={this.backToDashboard}
        classes={{button: classes.backButton}}
      />
    }
    <div className={classes.clear}></div>
    </>
  }

  onAddAssetClick(asset) {
    this.setState({
      selectedAssetData: asset,
      instrumentError: false
    }, () => {
      this.setState({
        showModal: true
      })
    })
  }

  closeModal() {
    this.setState({
      showModal: false,
      selectedAssetId: undefined,
      instrumentError: false
    })
  }

  renderError() {
    const { classes } = this.props;
    return (<div className={classes.errorWrapper}>
      <p className={classes.warningText}>
        Something went wrong. Please try again
      </p>
    </div>)
  }

  closeSuccessDeleteDialog() {
    const assetsCount = this.state.portfolioDataBuffered.length;

    if (assetsCount >= 1) {
      this.setState({
        showSuccessDeleteDialog: false
      })
    } else if (assetsCount === 0) {
      this.props.history.push(buildCurrentCustomerDashboardVirtualPath(this.state.customerId))
    }
  }

  openBenchmarkDialog() {
    this.setState({
      showBenchmarkDialog: true
    })
  }

  backToDashboard() {
    this.props.history.push(buildCurrentCustomerDashboardVirtualPath(this.state.customerId))
  }

  closeBenchmarkDialog() {
    this.setState({
      showBenchmarkDialog: false
    })
  }

  closeErrorDialog() {
    this.setState({
      showWarningDialog: false
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Container className={`app-page-container`}>
          <div className={classes.navigation}>
            <Link
              to={buildCurrentCustomerDashboardVirtualPath(this.state.customerId)}
              className={clsx(classes.link, classes.backwardArrow)}>
              Zurück zur virtuellen Depotübersicht
            </Link>
            <Typography variant="h2">
              {this.state.portfolioLoading ? this.renderLoading() : this.portfolioError ? this.renderPortfolioNameError() : this.renderPortfolioName()}
            </Typography>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AnalysisCard
                content={
                  <div className={classes.managerHeader}>
                    <span className={classes.managerControlsTitle}>Wertpapier hinzufügen</span>
                    <div className={classes.rightHeaderPart}>
                      <InstrumentsFilter
                        onAddAssetClick={this.onAddAssetClick}
                        disabled={this.state.portfolioLoading || this.state.portfolioError}
                        instrumentsDisabled={this.state.portfolioDataBuffered}
                      />
                    </div>
                  </div>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <AnalysisCard
                classes={{card: classes.assetsCard}}
                loading={this.state.portfolioLoading}
                content={this.state.portfolioError ? this.renderError() : this.renderTable()}
              />
            </Grid>
          </Grid>
        </Container>
        <ModalInstruments
          open={this.state.showModal}
          onClose={this.closeModal}
          assetData={this.state.selectedAssetData}
          assetId={this.state.selectedAssetId}
          processInstrument={this.processInstrument}
          loading={this.state.portfolioLoading}
          instrumentCreating={this.state.instrumentProcessing}
          error={this.state.instrumentError}
          customerId={this.state.customerId}
        />
        <ConfirmationDialog
          message={
            this.state.portfolioDataBuffered && this.state.portfolioDataBuffered.length === 1
            ? <p>Das virtuelle Depot muss mindestens ein Instrument enthalten. Wenn Sie dieses Instrument löschen, wird das komplette virtuelle Depot gelöscht. Sind Sie sicher, dass Sie dieses Instrument löschen möchten?</p>
            : <p>Möchten Sie wirklich <b>{this.state.instrumentToDeleteName}</b> löschen?</p>
          }
          open={this.state.showDeleteConfirmation || this.state.assetDeletingInProgress}
          onClose={this.closeInstrumentDeletingModal}
          loading={this.state.assetDeletingInProgress}
          confirm={this.deleteInstrument}
        />
        <SuccessDialog
          open={this.state.showSuccessDeleteDialog}
          onClose={this.closeSuccessDeleteDialog}
          message={
            <p>
              <b>{this.state.instrumentToDeleteName}</b> wurde erfolgreich gelöscht.
            </p>
          }
        />
        <BenchmarkSettingsDialog
          open={this.state.showBenchmarkDialog}
          onClose={this.closeBenchmarkDialog}
          portfolioId={this.state.portfolioId}
          customerId={this.state.customerId}
        />

      </React.Fragment>
    )
  }
}

export default withStyles(styles)(withRouter(VirtualPortfolioManager));
