import React from 'react';
import { connect } from 'react-redux';

import {
  Button
} from '@material-ui/core';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import useStyles from './styles';
import { openGuide, updateGuideSteps } from '../Header/actions';


const GuideTourStep = ({step, index, dispatch, is_priority}) => {
  const classes = useStyles();

  const handleStartClick = () => {
    dispatch(updateGuideSteps(step.steps));
    dispatch(openGuide());
  };

  return (
    <div className={classes.guideTourItemContainer} key={index}>
      <span style={{backgroundColor: step.completedStepsNumber >= step.steps.length ? '#7ecd71' : '#D8DCDF'}}>
        {step.completedStepsNumber >= step.steps.length ? <i className={"fa fa-check"} aria-hidden="true" /> : <span>{index}</span>}
      </span>
      <p>{step.name} ({step.completedStepsNumber >= step.steps.length ? step.steps.length : step.completedStepsNumber}/{step.steps.length})</p>
      <Button className={classes.startGuideButton} color="primary" onClick={handleStartClick} variant={is_priority ? 'contained' : 'text'}>
        <span style={{textDecoration: 'underline'}}>Starten</span>
        <ArrowForwardIcon className={classes.arrowIcon}/>
      </Button>
    </div>
  )
};

export default connect()(GuideTourStep);