import React from 'react';


function BcaIcon({icon, variant}) {
  return (
    <i className={`${variant} ${icon}`} />
  )
}

BcaIcon.defaultProps = {
  variant: "fal"
}

export function BcaEditIcon({variant}) {
  return <BcaIcon icon="fa-pencil" variant={variant} />
};

export function BcaDeleteIcon({variant}) {
  return <BcaIcon icon="fa-trash-alt" variant={variant} />
};