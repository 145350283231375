import {makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
  aggregatePortfoliosSwitchContainer: {
    height: 24,
    width: 42
  },
  aggregatePortfoliosSwitchThumb: {
    height: 18,
    width: 18,
    top: 2,
    left: 2
  },
  aggregatePortfoliosSwitchChecked: {
    transform: 'translateX(90%) !important'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    '& > span': {
      marginLeft: 10,
      fontFamily: 'Roboto-Bold'
    }
  }
}))