import {fromJS} from "immutable";

export const FETCH_DATA_INITIAL_STATE = {
  data: null,
  loading: false,
  errors: null,
  updatedAt: null
}

export const getFetchDataLoadingState = () => ({
  data: undefined,
  errors: undefined,
  loading: true,
  updatedAt: +new Date()
})

export const getFetchDataErrorState = (errors) => ({
  data: null,
  loading: false,
  errors,
  updatedAt: +new Date()
})

export const FETCH_DATA_ACTION_TYPE = {
  STARTED: 'STARTED',
  FAILED: 'FAILED',
  FINISHED: 'FINISHED'
}

export function fetchDataReducer(state, action) {
  const { type, payload } = action

  switch(type) {
    case FETCH_DATA_ACTION_TYPE.STARTED: {
      return state.set('data', null).set('errors', null).set('loading',  true).set('updatedAt', +new Date())
    }
    case FETCH_DATA_ACTION_TYPE.FAILED: {
      return state.set('data', null).set('errors', payload).set('loading',  false).set('updatedAt', +new Date())
    }
    case FETCH_DATA_ACTION_TYPE.FINISHED: {
      return state.set('data', payload).set('errors', null).set('loading',  false).set('updatedAt', +new Date())
    }
    default: {
      return state
    }
  }
}

export function startDataFetchingAction() {
  return {
    type: FETCH_DATA_ACTION_TYPE.STARTED
  }
}

export function dataFetchingFailedAction(errors) {
  return {
    type: FETCH_DATA_ACTION_TYPE.FAILED,
    payload: errors
  }
}

export function dataFetchingFinishedAction(data) {
  return {
    type: FETCH_DATA_ACTION_TYPE.FINISHED,
    payload: data
  }
}