import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    marginBottom: 16,

    [theme.breakpoints.down('sm')]: {
      marginBottom: 8,
    }
  },
  header: {
    fontFamily: 'Roboto-Regular',
    fontSize: 34,
    color: '#626970',

    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    }
  },
  buttonProgress: {
    color: '#0092E5',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));