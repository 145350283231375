import React from "react";
import _ from "lodash";

export const useSelectedItems = function (key = 'id') {
  const [selectedItems, setSelectedItems] = React.useState({});

  const _getItemKeyFieldValue = (item, _key = undefined) => {
    return _.get(item, _key || key);
  }

  const isSelected = (item, _key = undefined) => selectedItems
    .hasOwnProperty(item[_getItemKeyFieldValue(item, _key)]);

  const handleToggleItem = (item, _key = undefined) => {
    const keyValue = _getItemKeyFieldValue(item, _key);

    setSelectedItems((current) => {
      if (current.hasOwnProperty(keyValue)) {
        delete current[keyValue];
      } else {
        current[keyValue] = item;
      }
      return {...current}
    })
  }

  const handleSelectMany = (items, _key = undefined) => {
    setSelectedItems((current) => {
      items.forEach((item) => {
        current[_getItemKeyFieldValue(item, _key)] = item;
      })
      return {...current}
    })
  }

  const handleUnselectMany = (items, _key = undefined) => {
    setSelectedItems((current) => {
      items.forEach((item) => {
        const keyValue = _getItemKeyFieldValue(item, _key);
        if (current.hasOwnProperty(keyValue)) {
          delete current[keyValue];
        }
      });
      return {...current}
    })
  }

  const cleanSelected = () => setSelectedItems({})

  return {
    selectedItems,
    isSelected,
    handleToggleItem,
    handleSelectMany,
    handleUnselectMany,
    cleanSelected
  }
}