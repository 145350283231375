import {makeStyles} from "@material-ui/core";

export default makeStyles(theme => ({
  tableCell: {
    [theme.breakpoints.down('md')]: {
      width: 75
    },
    '&:first-child': {
      width: 350
    }
  }
}))