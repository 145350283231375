import {AUTO_REPORT_PERIODICITY} from "../GroupReporting/constants";
import _ from 'lodash';

export const RESET_REPORTING_STORE = 'RESET_REPORTING_STORE';
export const SET_REPORTING_GROUPS = 'SET_REPORTING_GROUPS';
export const SET_REPORTING_CUSTOMERS = 'SET_REPORTING_CUSTOMERS';
export const SET_REPORTING_CUSTOMERS_WITHOUT_GROUP = 'SET_REPORTING_CUSTOMERS_WITHOUT_GROUP';
export const SET_REPORTING_GROUP = 'SET_REPORTING_GROUP';
export const SET_REPORTING_CUSTOMER = 'SET_REPORTING_CUSTOMER';
export const SET_REPORTING_GROUPS_LOADING = 'SET_REPORTING_GROUPS_LOADING';
export const SET_REPORTING_CUSTOMERS_LOADING = 'SET_REPORTING_CUSTOMERS_LOADING';
export const SET_REPORTING_ACTIVE_PATH = 'SET_REPORTING_ACTIVE_PATH';
export const SET_REPORTING_CUSTOMER_IN_GROUP = 'SET_REPORTING_CUSTOMER_IN_GROUP';
export const SET_REPORTING_GUIDE_TOUR_CUSTOMERS = 'SET_REPORTING_GUIDE_TOUR_CUSTOMERS';
export const SET_REPORTING_GUIDE_TOUR_GROUPS = 'SET_REPORTING_GUIDE_TOUR_GROUPS';
export const SET_EDITOR_VARIABLES = 'SET_EDITOR_VARIABLES';


export const REPORTING_GUIDE_TOUR_CUSTOMERS = [
  {
    id: 'REPORTING_GUIDE_TOUR_CUSTOMERS_1',
    customer_id: '4367900374',
    customer_full_name: 'Braun, Maximilian',
    last_report: {"creation_time": "2020-03-03T21:01:28.698978", "report_type": 2},
    group: {id: 'REPORTING_GUIDE_TOUR_GROUPS_1', name: 'Neukunden Seit 2018'},
    report_settings: {
      report_type: 1,
      distribution_type: 2,
      date_range: 2,
      with_cost: false,
      with_transactions: false,
      cover_message_enabled: true,
      cover_message_content: '',
      cover_message_updated: "2020-03-12T09:40:22.872885"
    }
  }
];

export const REPORTING_GUIDE_TOUR_GROUPS = [
  {
    id: 'REPORTING_GUIDE_TOUR_GROUPS_1',
    name: 'Neukunden Seit 2018',
    last_report: {"creation_time": "2020-03-03T21:01:28.698978", "report_type": 2},
    expanded: true,
    customers: REPORTING_GUIDE_TOUR_CUSTOMERS,
  }
];

export const REPORTING_TEXTBOX_UID = 'reporting';

export const getAutoReportPeriodicityDescription = (range) => {
  let period = Object.values(AUTO_REPORT_PERIODICITY).find(x => x.id === range)
  return period && period.label
};
