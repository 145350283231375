import _ from 'lodash';

/**
 * Retrieve needed value from shared settings by key.
 * 
 * @param {Object} settings Shared settings data
 * @param {String} key Data retrieving key
 * @param {{key:String, value:String|Number}} dataToReplace Data, that should be replaced
 * 
 * @returns {String} Setting value
 */
export function getHostRelatedSetting(settings, key, dataToReplace=undefined) {
  
  let hostRelatedSettings = settings.host_related_settings;

  if (hostRelatedSettings && hostRelatedSettings.hasOwnProperty(key)) {

    let host = window.location.hostname;

    let hostSetting = _.find(hostRelatedSettings[key], (item) => item.host == host);
    let defaultSetting = _.find(hostRelatedSettings[key], (item) => _.isNil(item.host));

    let value = undefined;
  
    if (!_.isNil(hostSetting)) {
      value = hostSetting.value;
    }

    if (!value && !_.isNil(defaultSetting)) {
      value = defaultSetting.value;
    }

    if (value) {

      if (!_.isNil(dataToReplace)) {
        for (let key in dataToReplace) {
          if (dataToReplace.hasOwnProperty(key)) {
            value = value.replace(key, dataToReplace[key]);
          }
        }
      }

      return value;

    }

  };

}