import React from 'react';

import useStyles from './styles';
import {useTimeWeightedCumulativeReturnsData} from "../../hooks/useTimeWeightedCumulativeReturnsData";
import DashboardTable from "../../../../components/DashboardTable";
import {Skeleton} from "@material-ui/lab";
import {getErrorMessage, withPercentOrDash} from "../../../../utils/utils";
import _ from "lodash";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../constants";
import WarningTooltip from "../../../../components/WarningTooltip";
import moment from "moment";

const DepotNameCell = {
  header: "Depot",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return (
        <span style={{display: 'block', textOverflow: 'ellipsis', overflow: 'hidden'}}>
          <b>{ item.name }</b>
        </span>
      )
    }
  },
  key: 'DepotNameCell'
}

const MISSING_DATA_MARK = '*';

const getValueColumn = (header, valueKey, withTrackingStartDate=false) => {
  return {
    header,
    body: {
      content: (item, options) => {

        if (options.loading) {
          return <Skeleton />
        }

        if (!!item.error) {
          return MISSING_DATA_MARK;
        }

        const value = _.get(item, `data.${valueKey}`);

        const trackingStartDate = withTrackingStartDate
          ? _.get(item, `data.tracking_start_date`)
          : undefined;

        return (
          <span style={{whiteSpace: 'nowrap'}}>
            {withPercentOrDash(value)}
            {!!trackingStartDate && (
              <WarningTooltip title={`seit Auflage am ${moment(trackingStartDate).format('DD.MM.YYYY')}`} />
            )}
          </span>
        );
      }
    },
    key: header
  }
}

const tableStructure = {
  default: [
    {
      content: [DepotNameCell]
    }, {
      content: [getValueColumn('1 Monat', 'cum_ret_g_1m')],
      align: 'right',
    }, {
      content: [getValueColumn('3 Monate', 'cum_ret_g_3m')],
      align: 'right',
    }, {
      content: [getValueColumn('6 Monate', 'cum_ret_g_6m')],
      align: 'right',
    }, {
      content: [getValueColumn('lfd. Jahr', 'cum_ret_g_ytd')],
      align: 'right',
    }, {
      content: [getValueColumn('1 Jahr', 'cum_ret_g_1y')],
      align: 'right',
    }, {
      content: [getValueColumn('3 Jahre', 'cum_ret_g_3y')],
      align: 'right',
    }, {
      content: [getValueColumn('5 Jahre', 'cum_ret_g_4y')],
      align: 'right',
    }, {
      content: [getValueColumn('10 Jahre', 'cum_ret_g_5y')],
      align: 'right',
    }, {
      content: [getValueColumn('seit Aufl.', 'cum_ret_g_inception', true)],
      align: 'right',
    }
  ]
}

const TimeWeightedPerformanceTable = React.memo((props) => {
  const {
    customerId,
    portfolios,
    withHistorical,
    dataProvider,
    aggregatedPortfolioName,
    assets,
    investmentStrategyId,
  } = props;
  const classes = useStyles();

  const data = useTimeWeightedCumulativeReturnsData(customerId, portfolios, withHistorical, dataProvider, aggregatedPortfolioName, assets, investmentStrategyId);

  const depotsData = data.loading
    ? new Array((portfolios || []).length || 5).fill({})
    : data.data || [];

  const emptyDataMessage = data.errors
    ? getErrorMessage(data.errors)
    : undefined;

  const hasError = _.some(depotsData, depot => !!depot.error);

  return (
    <>
      <div className={classes.tableSection}>
        <DashboardTable
          structure={tableStructure}
          dataSource={depotsData}
          tableClasses={classes}
          expanded={true}
          useItemColorAsBorder
          withFooter={false}
          options={{
            loading: data.loading
          }}
          emptyDataMessage={emptyDataMessage}
        />
      </div>
      {hasError && (
        <p className={classes.error}>{MISSING_DATA_MARK}{DEFAULT_EMPTY_SECTION_MESSAGE}</p>
      )}
    </>

  )

}, (prevProps, nextProps) => {
  return prevProps.customerId == nextProps.customerId
    && prevProps.withHistorical == nextProps.withHistorical
    && JSON.stringify(prevProps.portfolios) == JSON.stringify(nextProps.portfolios)
})

TimeWeightedPerformanceTable.propTypes = {}

TimeWeightedPerformanceTable.propDefs = {}

TimeWeightedPerformanceTable.defaultProps = {}

export default TimeWeightedPerformanceTable;