import React from 'react';
import useStyles from './styles';
import _ from 'lodash';
import {getHostRelatedSetting} from "../../../../../../../utils/sharedSettings";
import {
  HOST_RELATED_SHARED_SETTINGS_KEYS,
  NOT_FOUND
} from "../../../../../../../components/SharedSettingsProvider/constants";
import {getSharedSettingsSelector} from "../../../../../../../components/DashboardDataProvider/DashboardDataProvider";
import { connect } from 'react-redux';
import WarningTooltip from "../../../../../../../components/WarningTooltip";
import ChartSectionBordered from "../../../../../components_v2/ChartSectionBordered/ChartSectionBordered";
import clsx from "clsx";
import {
  CustomerDashboardNoDataPlaceholder
} from "../../../../../../FactSheetsPage/components/NoDataPlaceholder";
import {formatPercentage} from "../../../../../../FactSheetsPage/utils";
import {portfolioStructurePropsAreEquals} from '../utils';


const mapStateToProps = (state) => ({
  sharedSettings: getSharedSettingsSelector(state),
});

export const TopTenInstrumentsTableTitle = connect(mapStateToProps) ((props) => {

  const classes = useStyles();

  return (
    <>
      <b>Top 10 Instrumente</b>
      {_.isArray(props.skippedByTopHoldings) && !_.isEmpty(props.skippedByTopHoldings) &&
        <WarningTooltip
          title={getHostRelatedSetting(props.sharedSettings.data, HOST_RELATED_SHARED_SETTINGS_KEYS.ASSETS_WITHOUT_TOP_HOLDINGS_TEXT,
            {"{{skipped_by_top_holdings}}": props.skippedByTopHoldings.join(', ')}
          ) || NOT_FOUND}
          icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)}/>}
          width={600}
        />
      }
    </>
  )
})

const normalizeTableValue = (value) => {
  if (value === undefined || value === null) {
    return 'k.A.';
  }

  return value;
};


const SyntheticRiskRewardChart = React.memo((props) => {
  const {
    dashboardData,
    expanded,
    onExpanded,
  } = props;

  const classes = useStyles();

  const portfolioStructure = dashboardData && dashboardData.portfolio_structure;

  const data = portfolioStructure && portfolioStructure.by_top_holdings;

  const hasData = !_.isEmpty(data);

  return (
    <ChartSectionBordered
      title={<TopTenInstrumentsTableTitle skippedByTopHoldings={portfolioStructure && portfolioStructure.skipped_by_top_holdings} />}
      expanded={expanded}
      onExpanded={onExpanded}
      skipContentPadding={hasData}
      content={(
        <>
          {hasData ? (
            <ul className={classes.list}>
              {
                data.map((item, index) => (
                  <li className={classes.listItem} key={index}>
                    <span className={classes.title}>{item.name}</span>
                    <span className={classes.listValues}>{normalizeTableValue(formatPercentage(item.weight, 100))}</span>
                  </li>
                ))
              }
            </ul>
          ) : (
            <CustomerDashboardNoDataPlaceholder />
          )}
        </>
      )}
    />
  )
}, portfolioStructurePropsAreEquals);

export default SyntheticRiskRewardChart;
