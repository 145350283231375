const styles = theme => ({
  header: {
    fontFamily: "Roboto-Bold",
  },
  headerInternal: {
    margin: '22px 0',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  switchText: {
    fontFamily: 'Roboto-Regular',
    color: '#80858C',
    marginRight: 10
  },
  dialogActions: {
    padding: 24,
  },
  tableCell: {
    '&:first-child': {
      maxWidth: 300
    },
    '&:nth-child(2)': {
      width: 83
    }
  },
  noPaddingContent: {
    '& > div > div, & > div': {
      paddingLeft: 0,
      paddingRight: 0
    }
  }
});

export default styles;