export const RESET_REPORTING_STORE = 'RESET_REPORTING_STORE';
export const DISTRIBUTION_TYPES = {
    POSTBOX: {
        value: 1,
        description: 'in elektronisches Kundenpostfach'
    },
    BRIEF: {
        value: 2,
        description: 'per Brief'
    }
};

export const DEFAULT_DISTRIBUTION_TYPE = DISTRIBUTION_TYPES.BRIEF;

export const REPORT_GENERATION_TYPES = {
    SINGLE: {
        value: 1,
        description: 'Gesamtreport über alle Kunden'
    },
    SPLITTED: {
        value: 2,
        description: 'Einzelreports per Kunde'
    }
};

export const SETTINGS_TYPE_BENCHMARK = {
  GROUP_BENCHMARK: 0,
  CUSTOMER_BENCHMARK: 1,
  GROUP_AUTO_REPORT_BENCHMARK: 2,
  CUSTOMER_AUTO_REPORT_BENCHMARK: 3,

  DEFAULT_DASHBOARD: 4,
  DASHBOARD_BROKER_GROUP: 5,
  DASHBOARD_BROKER_CUSTOMER: 6,

  DASHBOARD_CUSTOMER_GROUP: 7,
  DASHBOARD_CUSTOMER: 8
};

export const REPORT_SETTINGS_TYPE = {
  DEFAULT_SETTINGS: 0,
  AUTO_REPORT_SETTINGS: 1,
};

export const DEFAULT_REPORT_GENERATION_TYPE = REPORT_GENERATION_TYPES.SINGLE;

export const SET_REPORTING_GROUPS = 'SET_REPORTING_GROUPS';
export const SET_REPORTING_CUSTOMERS = 'SET_REPORTING_CUSTOMERS';
export const SET_REPORTING_GROUP = 'SET_REPORTING_GROUP';
export const SET_REPORTING_CUSTOMER = 'SET_REPORTING_CUSTOMER';
export const SET_REPORTING_GROUPS_LOADING = 'SET_REPORTING_GROUPS_LOADING';
export const SET_REPORTING_CUSTOMERS_LOADING = 'SET_REPORTING_CUSTOMERS_LOADING';
export const SET_REPORTING_ACTIVE_PATH = 'SET_REPORTING_ACTIVE_PATH';
export const SET_REPORTING_CUSTOMER_IN_GROUP = 'SET_REPORTING_CUSTOMER_IN_GROUP';
export const SET_REPORTING_GUIDE_TOUR_CUSTOMERS = 'SET_REPORTING_GUIDE_TOUR_CUSTOMERS';
export const SET_REPORTING_GUIDE_TOUR_GROUPS = 'SET_REPORTING_GUIDE_TOUR_GROUPS';
export const SET_EDITOR_VARIABLES = 'SET_EDITOR_VARIABLES';


export const REPORTING_GUIDE_TOUR_CUSTOMERS = [
  {
    id: 'REPORTING_GUIDE_TOUR_CUSTOMERS_1',
    customer_id: '4367900374',
    customer_full_name: 'Braun, Maximilian',
    last_report: {"creation_time": "2020-03-03T21:01:28.698978", "report_type": 2},
    group: {id: 'REPORTING_GUIDE_TOUR_GROUPS_1', name: 'Neukunden Seit 2018'},
    report_settings: {
      report_type: 1,
      distribution_type: 2,
      date_range: 2,
      with_cost: false,
      with_transactions: false,
      cover_message_enabled: true,
      cover_message_content: '',
      cover_message_updated: "2020-03-12T09:40:22.872885"
    }
  }
];

export const REPORTING_GUIDE_TOUR_GROUPS = [
  {
    id: 'REPORTING_GUIDE_TOUR_GROUPS_1',
    name: 'Neukunden Seit 2018',
    last_report: {"creation_time": "2020-03-03T21:01:28.698978", "report_type": 2},
    expanded: true,
    customers: REPORTING_GUIDE_TOUR_CUSTOMERS,
  }
];

export const REPORTING_TEXTBOX_UID = 'reporting';

export const AUTO_REPORT_PERIODICITY = {
  NONE: {label: 'Keine', id: 0},
  MONTHLY: {label: 'Monatlich', id: 1},
  QUARTERLY: {label: 'Quartalsweise', id: 2},
  HALF_YEARLY: {label: 'Halbjährlich', id: 3},
  YEARLY: {label: 'Jährlich', id: 4}
}

export const AUTO_REPORT_PERIODICITY_OPTIONS = Object.values(AUTO_REPORT_PERIODICITY)

export const SERIES_REPORT_SETTINGS_TYPE = {
  CUSTOMER: 0,
  CUSTOMER_AUTO: 1,

  GROUP: 2,
  GROUP_AUTO: 3
}
