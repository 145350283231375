import React from 'react';
import {
  Grid
} from '@material-ui/core';
import clsx from 'clsx';

import useStyle from './styles';

export default (props) => {
  const classes = useStyle();

  const {
    filterResult
  } = props;

  return (
    <Grid className={clsx(classes.topMessage, classes.bold)} item xs={6}>
      Ihre Suche nach&nbsp;<span className={classes.word}>“{filterResult.word}”</span>
      &nbsp;({filterResult.count ? filterResult.count : 'Keine'} Ergebnisse).
    </Grid>
  )
}