import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Switch from '@material-ui/core/Switch';
import clsx from "clsx";
import {Skeleton} from '@material-ui/lab';

const IOSSwitch = withStyles(theme => ({
  container: {
    position: 'relative',
    width: 88,
    height: 40,
    cursor: 'pointer',
  },
  label: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto-Bold',
    fontSize: 15,
    color: 'white',
    padding: '0 15px',
    userSelect: 'none'
  },
  onLabel: {
    justifyContent: 'flex-start'
  },
  offLabel: {
    justifyContent: 'flex-end'
  },
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: 0,
    borderRadius: 20,
    margin: 0,
    pointerEvents: 'none',
  },
  switchBase: {
    padding: '1px !important',
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
    },
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  thumb: {
    position: 'relative',
    top: 4,
    left: 4,
    width: 30,
    height: 30,
  },
  track: {
    width: '100%',
    borderRadius: 26 / 2,
    backgroundColor: props => props.trackBackgroundColor || '#b1b1b1',
    opacity: 1,
    transition: 'none',
  },
  checked: {
    transform: 'translateX(150%) !important'
  },
  focusVisible: {},
}))(({ classes, customClasses, ...props }) => {

  const {
    value,
    handleValueChanged,
    showLabel,
    onLabel,
    offLabel,
    disabled,
    loading,
    ...restProps
  } = props;


  const handleClick = () => {
    if (!disabled && handleValueChanged) {
      handleValueChanged(!value)
    }
  };

  return (
    loading ? (
      <Skeleton height={40}/>
    ) : (
      <div className={clsx(classes.container, customClasses.container)} onClick={handleClick}>
        <Switch
          focusVisibleClassName={classes.focusVisible}
          onChange={() => {}}
          disableRipple
          disabled={disabled}
          checked={value}
          classes={{
            root: clsx(classes.root, customClasses.root),
            switchBase: clsx(classes.switchBase, customClasses.switchBase),
            thumb: clsx(classes.thumb, customClasses.thumb),
            track: clsx(classes.track, customClasses.track),
            checked: clsx(classes.checked, customClasses.checked),
          }}
          {...restProps}
        />
        {showLabel && (
          <span className={clsx(classes.label, customClasses.label, value ? classes.onLabel : classes.offLabel)}>
            {value ? onLabel : offLabel}
          </span>
        )}
      </div>
    )
  );
});

IOSSwitch.defaultProps = {
  onLabel: 'EIN',
  offLabel: 'AUS',
  customClasses: {},
  showLabel: true,
};

export default IOSSwitch