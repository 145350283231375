export const MONTHLY = 'MONTHLY';
export const MONTHLY_2 = 'MONTHLY_2';
export const MONTHLY_3 = 'MONTHLY_3';
export const MONTHLY_6 = 'MONTHLY_6';
export const YEARLY = 'YEARLY';

export const CONFIG = {
  MONTHLY: {
    cios_code: undefined,
    title: 'Monatlich'
  },
  MONTHLY_2: {
    cios_code: undefined,
    title: 'Zweimonatlich'
  },
  MONTHLY_3: {
    cios_code: undefined,
    title: 'Quartalsweise'
  },
  MONTHLY_6: {
    cios_code: undefined,
    title: 'Halbjahrlich'
  },
  YEARLY: {
    cios_code: undefined,
    title: 'Jahrlich'
  }
};

export const ORDER_TYPES = {
  BUY_VALUE: 1,
  SELL_VALUE: 2,
  BUY_QUANTITY: 3,
  SELL_QUANTITY: 4,
  SWITCH_IN: 5,
  SWITCH_OUT: 6,
  SPLIT: 7
}