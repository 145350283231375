import React from 'react'
import _ from "lodash";
import moment from "moment";

import {makeStyles} from "@material-ui/core";

import InputFormElement, {
  CurrencyNumberFormat,
} from "../../../../../RiskProfiling/components/StepContent/components/formElement/InputFormElement";
import Link from "../../../../../../components/Link";
import {
  InstrumentNameCell,
} from "../../../../../Modelportfolios/components/InstrumentsList/table-data";
import SelectFormElement
  from "../../../../../RiskProfiling/components/StepContent/components/formElement/SelectFormElement";
import {getNumberValue, getTransactionItemSRRI} from "../../../../utils";
import CalendarFormElement
  from "../../../../../RiskProfiling/components/StepContent/components/formElement/CalendarFormElement";
import {
  BrokerFeeColumn,
  PortfolioNameColumn,
  SwitchInAmountEuroColumn as TradingSwitchInAmountEuroColumn,
  SwitchInAmountPercentageColumn as TradingSwitchInAmountPercentageColumn,
  SwitchOutAmountEuroColumn,
  SwitchOutAmountPercentageColumn,
  _SwitchQuantityFieldWrapper, PriceEurCol, VirtualDiscountInput, NoChangeError
} from '../PortfolioTrade/table-data';
import {
  useRiskScoreConfirmationModalContext
} from "../../../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/InvestmentRecommendationStep";
import clsx from "clsx";
import DeleteIcon from "../../../../../../images/DeleteIcon";
import {DATE_MUST_BE_IN_PAST, OPTION_IS_NOT_AVAILABLE_FOR_DEPOT_MSG} from "../../../../../RiskProfiling/constants";
import {InputWarningTooltip} from "../../../../../../components/WarningTooltip";
import { DATE_IN_PAST_ERROR, END_DATE_SHOULD_BE_AFTER_START, isNotAvailable } from '../../../../constants';
import {
  getInstrumentMinSavingPlanDate,
  getMaxBookingDate,
  MIN_SAVING_PLAN_DATE_ERROR_MSG
} from "../../../../../VirtualPortfoliosDashboard/constants";
import {SustainabilityCell} from "../../../../../../utils/commonTableColumns";

const useCellStyles = makeStyles((theme) => ({
  inputRoot: {
    margin: 0,
    height: 40,
    fontSize: 14,
    '&.Mui-error': {
      borderColor: '#f44336',
    },
    '& .MuiInputAdornment-positionEnd': {
      margin: 0,
    },
    '& .MuiButtonBase-root.MuiIconButton-root': {
      padding: 6
    }
  },
  inputRootChanged: {
    '& .MuiInputBase-input': {
      color: theme.palette.primary.main,
    }
  },
  tooltip: {
    position: 'relative',
    top: -10
  },
  dateTooltip: {
    position: 'relative',
    top: 12,
    marginLeft: -5
  },
  disabledLabel: {
    border: '1px solid #80858C',
    color: '#80858C',
    borderRadius: 2,
    fontFamily: 'Roboto-Regular',
    fontSize: 10,
    textTransform: 'uppercase',
    padding: '4px 6px'
  },
  actionCell: {
    display: 'inline-flex',
    verticalAlign: 'top',
    position: 'relative',
    zIndex: 0,
    width: '100%'
  },
  weightCell: {
    lineHeight: '40px',
  },
  subNavigationButton: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: 5,

    '&.only-icon': {
      padding: 0,
      marginLeft: 5,

      '&:first-child': {
        marginLeft: 0
      },

      '& span:nth-child(2)': {
        marginRight: 0
      }
    },

    '&.error': {
      '& span:nth-child(2)': {
        color: 'rgb(244, 70, 56)',
      },
      '& span:first-child': {
        backgroundColor: 'rgba(244, 70, 56, 0.2)',
      },
    },

    '&:hover': {
      cursor: 'pointer'
    },

    '&:hover span:first-child': {
      width: '100%',
      height: '100%',
      borderRadius: 5
    },

    '& span:first-child': {
      transition: 'all',
      transitionDuration: 150,
      height: 28,
      width: 28,
      borderRadius: 20,
      backgroundColor: '#CCE9FA',
      position: 'absolute',
    },

    '& span:nth-child(2)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 28,
      width: 28,
      borderRadius: 20,
      marginRight: 8,
      position: 'relative',
      zIndex: 99
    },

    '& span:last-child': {
      fontFamily: 'Roboto-Bold',
      fontSize: 14,
      color: theme.palette.primary.main,
      position: 'relative',
      zIndex: 99
    }
  }
}));

// NOTE: Component should return null if nothing to render
export const NameCol = {
  header: [({options}) => InstrumentNameCell.header.content(options) || null],
  body: [({item, options}) => InstrumentNameCell.body.content(item.data, options) || null],
}

export const RotationColumn = {
  header: [() => 'Turnus'],
  body: [({item, options}) => {
    const classes = useCellStyles();

    return (
      <div className={item.action === 'edit' && item.is_changed && _.get(item, 'changes.rotation') ? classes.inputRootChanged : ''}>
        <SelectFormElement
          disabled={item.action === 'delete'}
          value={item.rotation || ''}
          error={item.errors && item.errors.rotation || ''}
          options={options.rotationOptions || []}
          onChange={value => options.handleRotationChange && options.handleRotationChange(item, value)}
          custom_classes={classes}
          minimisedStyles
        />
      </div>
    )
  }],
}

const handleDateValidity = (options, item) => (date) => {
  if (!options.fromDateOptions || _.isEmpty(options.fromDateOptions)) {
    return true
  }

  return isNotAvailable(date,
    options.fromDateOptions.map(option => option.value),
    (options.maxStartDate && item) ? options.maxStartDate(item) : undefined)

}

export const DateColumn = {
  header: [() => 'Startdatum'],
  body: [({item, options}) => {
    const classes = useCellStyles();

    let minDate, maxDate, minErrorMsg, maxErrorMsg;
    if(options.isVirtual){
      // for sp buy we use price from prev day - so first SP is available next day after earliest price
      minDate = getInstrumentMinSavingPlanDate(item);
      minErrorMsg = MIN_SAVING_PLAN_DATE_ERROR_MSG;
      maxDate = getMaxBookingDate(); // for virtual portfolio trading happens in past -> max day is today
      maxErrorMsg = DATE_MUST_BE_IN_PAST;
    } else {
      minDate = moment().add(1, 'day');
      minErrorMsg = DATE_IN_PAST_ERROR;
      maxDate = undefined
    }

    return (
      <div className={item.action !== 'create' && item.is_changed && _.get(item, 'changes.from_date') ? classes.inputRootChanged : ''}>
        <CalendarFormElement
          value={item.from_date || null}
          error={item.errors && item.errors.from_date || ''}
          onChange={value => options.handleDateChange && options.handleDateChange(item, value, 'from_date')}
          custom_classes={classes}
          disabled={['delete'].includes(item.action)}
          disablePast={!options.isVirtual}
          disableDatesCallback={!options.isVirtual && handleDateValidity(options, item)}
          disableWeekends
          minDate={minDate}
          minError={minErrorMsg}
          maxDate={maxDate}
          maxError={maxErrorMsg}
          fixedWidth
          style={{display: 'inline-block', width:item.action !== 'create' ? 'calc(100% - 20px)' : '100%'}}
          minimisedStyles
        />
        {(!options.isVirtual && item.action !== 'create') && (
          // The custodian does not support a date in the future. The change will be made automatically as soon as possible
          <InputWarningTooltip title="Die Depotbank unterstützt kein in der Zukunft liegendes Datum. Die Änderung wird automatisch zum nächstmöglichen Zeitpunkt vorgenommen"/>
        )}
      </div>
    )
  }],
  cellClassName: 'date-picker-cell'
}

export const EndDateColumn = {
  header: [() => 'Letzte Ausführung'],
  body: [({item, options}) => {
    const classes = useCellStyles();

    return (
      <div className={item.action !== 'create' && item.is_changed && _.get(item, 'changes.till_date') ? classes.inputRootChanged : ''}>
        <CalendarFormElement
          value={item.till_date || null}
          error={item.errors && item.errors.till_date || ''}
          onChange={value => options.handleDateChange && options.handleDateChange(item, value, 'till_date')}
          custom_classes={classes}
          disablePast={!options.isVirtual}
          disableDatesCallback={!options.isVirtual && handleDateValidity(options)}
          minDate={moment(item.from_date).add(1, 'day')}
          minError={END_DATE_SHOULD_BE_AFTER_START}
          disabled={!options.tillDateEnabled}
          fixedWidth
          style={{display: 'inline-block', width:!options.tillDateEnabled ? 'calc(100% - 20px)' : '100%'}}
          minimisedStyles
        />
        {!options.tillDateEnabled && (
          <InputWarningTooltip title="Bei dieser Depotbank nicht verfügbar" />
        )}
      </div>
    )
  }],
  cellClassName: 'date-picker-cell'
}

const DiscountColumn = {
  header: [({options}) => (options.isVirtual ? 'Rabatt' : 'Anmerkung')],
  body: [({item, options}) => {
    const classes = useCellStyles();

    if (options.isVirtual) {
      return <VirtualDiscountInput item={item} options={options} classes={classes} />;
    }

    return (
      <div className={item.action === 'edit' && item.is_changed && _.get(item, 'changes.discount') ? classes.inputRootChanged : ''}>
        <SelectFormElement
          disabled={item.action === 'delete' || options.discountsDisabled}
          options={options.availableDiscounts || []}
          error={item.errors && item.errors.discount || ''}
          value={item.discount || ''}
          onChange={value => options.handleDiscountChange && options.handleDiscountChange(item, value)}
          custom_classes={classes}
          style={{display: 'inline-block', width: options.discountsDisabled ? 'calc(100% - 12px)' : '100%'}}
          minimisedStyles
        />
        {options.discountsDisabled && (
          <InputWarningTooltip title={OPTION_IS_NOT_AVAILABLE_FOR_DEPOT_MSG}/>
        )}
      </div>
    )
  }],
}

export const ActionsColumn = {
  header: [({options}) => {
    const classes = useCellStyles();

    return (
      <Link
        classes={{title: classes.actionCellTitle}}
        title={"Alles löschen"}
        icon={<i className={"far fa-trash-alt"} />}
        onClick={() => options.onRemoveAllClick && options.onRemoveAllClick()}
      />
    )
  }],
  body: [({item, options}) => {
    const classes = useCellStyles();

    let itemSRRI = getTransactionItemSRRI(item, options.isModelportfolio)

    const displayTextFieldButton = !['delete', 'edit'].includes(item.action) && options.clientSRRI && itemSRRI && itemSRRI > options.clientSRRI

    const { onSelectAsset } = useRiskScoreConfirmationModalContext()

    return (
      <div className={classes.actionCell}>
        <span className={clsx(classes.subNavigationButton, 'only-icon')}
              onClick={() => options.onRemoveClick && options.onRemoveClick(item)}
        >
          <span />
          <span><DeleteIcon /></span>
        </span>
        {displayTextFieldButton && (
          <span className={clsx(classes.subNavigationButton, 'only-icon', _.get(item, 'errors.risk_score_explanation') && 'error')}
                onClick={() => onSelectAsset && onSelectAsset(item)}
          >
            <span />
            <span>§</span>
          </span>
        )}
      </div>
    )
  }],
  align: 'center'
};

export const AmountColumn = {
  header: [({options}) => {
    if (options.isVirtual) {
      return 'Bruttobetrag'
    }
    return 'Sparbetrag'
  }],
  body: [({item, options}) => {
    const classes = useCellStyles();
    return (
      <div className={item.action === 'edit' && item.is_changed && _.get(item, 'changes.transaction_value') ? classes.inputRootChanged : ''}>
        <InputFormElement
          disabled={item.action === 'delete'}
          error={item.errors && item.errors.transaction_value}
          value={getNumberValue(item.transaction_value)}
          onChange={value => options.handleAmountChange && options.handleAmountChange(item, value)}
          inputComponent={CurrencyNumberFormat}
          inputProps={{inputProps: {allowNegative: false, fixedDecimalScale: true}}}
          type={'text'}
          custom_classes={classes}
        />
      </div>
    )
  }, NoChangeError],
  key: 'AmountColumn',
  align: 'right'
}

const SwitchInAmountPercentageColumn = {
  ...TradingSwitchInAmountPercentageColumn,
  header: TradingSwitchInAmountEuroColumn.header
}

const SwitchInAmountEuroColumn = {
  body: [_SwitchQuantityFieldWrapper(
    CurrencyNumberFormat,
    (item) => _.get(item, 'calculated.transaction_value_euro'),
    (_, options) => (item, value) => options.handleSwitchAmountEuroChanged && options.handleSwitchAmountEuroChanged(item, value, options.switchOutItem),
    (item) => _.get(item.errors, 'transaction_value'))],
  // header: SwitchInAmountQuantityColumn.header
  header: [_SwitchQuantityFieldWrapper(
    CurrencyNumberFormat,
    (item, options) => options.transactionSaldo,
    () => null,
    () => null,
    {suffix: '', placeholder: '', fixedDecimalScale: true, allowNegative: true},
    (item, options) => ({inputRoot: options.transactionSaldo != 0 ? 'text-red' : 'text-green'}),
    true)]
}

export const tableStructure = [
  NameCol,
  AmountColumn,
  RotationColumn,
  DateColumn,
  DiscountColumn,
  ActionsColumn
];

const PayoutAmountColumn = {
  header: [() => 'Entnahmebetrag'],
  body: AmountColumn.body,
  key: 'AmountColumn',
  align: 'right'
}

export const getSwitchPlansTableStructure = (action) => {
  const _structure = [
    NameCol,
    {...SwitchOutAmountEuroColumn, align: 'right'},
    {...SwitchOutAmountPercentageColumn, align: 'right'},
    RotationColumn,
    DateColumn,
    ActionsColumn
  ]

  if (action !== 'delete') {
    _structure.splice(5, 0, EndDateColumn)
  }

  return _structure
}

export const getSwitchInPlansTableStructure = (withSustainability) => {

  const structure = [
    NameCol,
    {...SwitchInAmountEuroColumn},
    {...SwitchInAmountPercentageColumn},
    BrokerFeeColumn,
    DiscountColumn,
    ActionsColumn
  ];
  
  if (withSustainability) {
    structure.splice(1, 0, {
      header: [SustainabilityCell.header.content],
      body: [({item, options}) => SustainabilityCell.body.content(item.data, options)],
      cellClassName: 'sustainability-cell'
    })
  }

  return structure;
}

export const getPayoutPlansTableStructure = (action, isModelPortfolio) => {
  const _structure = [
    isModelPortfolio ? PortfolioNameColumn : NameCol,
    PayoutAmountColumn,
    RotationColumn,
    DateColumn,
    ActionsColumn
  ]

  if (action !== 'delete') {
    _structure.splice(4, 0, EndDateColumn)
  }

  return _structure
}

export const getSavingsPlansTableStructure = (action, isModelPortfolio, isVirtual, withSustainability) => {
  let _structure;

  if (!isVirtual) {
    _structure = [
      isModelPortfolio ? PortfolioNameColumn : NameCol,
      AmountColumn,
      RotationColumn,
      DateColumn,
      BrokerFeeColumn,
      DiscountColumn,
      ActionsColumn
    ]

    if (action !== 'delete') {
      _structure.splice(4, 0, EndDateColumn)
    }

    if (!isModelPortfolio && withSustainability) {
      _structure.splice(1, 0, {
        header: [SustainabilityCell.header.content],
        body: [({item, options}) => SustainabilityCell.body.content(item.data, options)],
        cellClassName: 'sustainability-cell'
      })
    }

  } else{
    _structure = [
      NameCol,
      PriceEurCol,
      AmountColumn,
      RotationColumn,
      DateColumn,
      BrokerFeeColumn,
      DiscountColumn,
      ActionsColumn
    ]

    if (action === 'delete') {
      _structure.splice(1, 1);
    } else {
      _structure.splice(5, 0, EndDateColumn)
    }
  }

  return _structure
}
