import React  from 'react'
import { connect } from 'react-redux'

import {
  Container
} from '@material-ui/core'

import { withStyles } from '@material-ui/core';
import {PlusIcon} from '../../../images';
import DashboardTable from '../../../components/DashboardTable';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { getRouteFullPathByComponent } from '../../InvestmentPlatform/utils'
import { FavoriteListResouce,BankSettingsHandlerResource } from '../../../utils/api';

import { styles } from './styles';
import tableStructure from './table-structure';
import { paginateArray } from '../../../utils/utils';
import { DeleteFavoriteListModal } from '../components';
import { displayErrorSnackBar, displaySuccessSnackBar } from '../../../components/SnackbarProvider/actions';
import { FAVORITE_LIST_DELETE_ERROR_MESSAGE, FAVORITE_LIST_DELETE_SUCCESS_MESSAGE } from '../constants'
import { executeIfPathExist, getInvestmentDynamicPath } from '../../InvestmentPlatform/utils'
import TabsFilter from "../../InvestmentStrategy/List/components/TabsFilter";
import DynamicFilters from "../../InvestmentStrategy/List/components/DynamicFilters";
import _ from "lodash";
import BaseList, {ALL_ID} from "../../Modelportfolios/List/BaseList";
import {
  AddProductsToComparison,
  SelectFavoriteListForProductsComparisonModal
} from "../../../components/SelectForProductsComparisonModal/SelectForProductsComparisonModal";
import {DEFAULT_SELECT_PRODUCT_TABLE_ACTIONS_NAMES} from "../../../utils/constants";


const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
  auth: state.get('auth').toJS(),
});

const FAVORITE_LIST_PAGE_SIZE = 15

class List extends BaseList {
  constructor(props) {
    super(props);

    this.state = {
      favoriteLists: {
        data: null,
        loading: true,
        errors: null,
        date: Date.now()
      },
      deleteFavoriteListModalState: {
        visible: false,
        name: null,
        id: null
      },
      investmentDynamicPath: getInvestmentDynamicPath(),
      searchString: '',
      currentFilter: {
        type: ALL_ID,
        name: ''
      },
      extendComparisonWithId: undefined
    }
  }

  componentDidMount() {
    super.componentDidMount();

    this.fetchFavoriteLists()
  }

  fetchFavoriteLists = async (params) => {
    this.setState({
      favoriteLists: {
        data: null,
        errors: null,
        loading: true,
        date: Date.now()
      }
    })

    try {

      let requestParams = {}
      if (params) {
        requestParams = {...params}
      }
      let response = await FavoriteListResouce.getFavoriteLists(requestParams);

      if(!_.isEmpty(this.props.instrumentsToFilterOut) && !_.isEmpty(response)){
        // filter out fav lists that have only assets that must be filtered

        let filteredResponse = []
        for (let favList of response){
          favList.assets = (favList.assets || []).filter(a => !this.props.instrumentsToFilterOut.includes(a.instrument_id))
          if(!_.isEmpty(favList.assets)){
            filteredResponse.push(favList)
          }
        }

        response = filteredResponse
      }

      let responsePaginated = [...paginateArray(response, FAVORITE_LIST_PAGE_SIZE)]

      this.setState({
        favoriteLists: {
          data: responsePaginated,
          loading: false,
          errors: null,
          date: Date.now()
        },
      })
    } catch (errors) {
      this.setState({
        favoriteLists: {
          data: null,
          loading: false,
          errors,
          date: Date.now()
        }
      })
    }
  }

  renderAddNewFavoritelistButton = () => {
    const { classes } = this.props;
    if (this.props.investmentPlatform.routes) {
      let fullPath = getRouteFullPathByComponent(this.props.investmentPlatform.routes, 'FAVORITE_LIST_CREATE')

      if (fullPath) {
        return (
          <PrimaryButton
            text="Neue Favoritenliste erstellen"
            icon={<PlusIcon color="white"/>}
            onButtonClick={() => this.props.history.push(`/${this.state.investmentDynamicPath}${fullPath}`)}
            customClasses={{root: classes.addNewFavoriteListButtonRoot}}
          />
        )
      }
    }
  }

  getFavoriteListsDataSource = () => {
    if (this.state.favoriteLists.loading) {
      return [{}, {}, {}, {}, {}]
    }

    if (this.state.favoriteLists.data) {
      let dataSource = this.state.favoriteLists.data
      return dataSource || []
    }

    return []
  }

  handleDeleteModalClose = () => {
    this.setState({
      deleteFavoriteListModalState: {
        visible: false,
        name: null,
        id: null
      }
    })
  }

  handleDeleteFavoriteListModalOpen = (id, name) => {
    this.setState({
      deleteFavoriteListModalState: {
        visible: true,
        name,
        id
      }
    })
  }

  handleDeleteFavoriteList = async () => {
    if (this.state.deleteFavoriteListModalState.id) {
      try {
        
        await FavoriteListResouce.deleteFavoriteList(this.state.deleteFavoriteListModalState.id)

        this.deleteFavoriteList(this.state.deleteFavoriteListModalState.id)

        this.setState({
          deleteFavoriteListModalState: {
            visible: false,
            name: null,
            id: null
          }
        })
        // TODO: Replace with German
        this.props.dispatch(displaySuccessSnackBar(FAVORITE_LIST_DELETE_SUCCESS_MESSAGE))

      } catch (errors) {
        throw errors
        // TODO: Replace with German
        this.props.dispatch(displayErrorSnackBar(FAVORITE_LIST_DELETE_ERROR_MESSAGE))
      }
    }
  }

  deleteFavoriteList = (id) => {
    let favoriteLists = [].concat(...this.state.favoriteLists.data)
    let favoriteListsFiltered = favoriteLists.filter(item => item.id != id)
    let favoriteListsPaginated = [...paginateArray(favoriteListsFiltered, FAVORITE_LIST_PAGE_SIZE)]

    this.setState({
      favoriteLists: {
        ...this.state.favoriteLists,
        data: favoriteListsPaginated
      },
    })
  }

  handleEditClick = (favoriteList) => {
    if(this.props.handleEditClick){
      return this.props.handleEditClick(favoriteList);
    }
    executeIfPathExist(this.props.investmentPlatform.routes, 'FAVORITE_LIST_OVERVIEW', path => {
      let pathUpdated = path.replace(':id', favoriteList.id)

      this.props.history.push(`/${this.state.investmentDynamicPath}${pathUpdated}`)
    })
  }

  handleExtendClick = (favoriteList) => {
    this.setState({
      extendComparisonWithId: favoriteList.id
    })
  }

  handleExtendClose = () => {
    this.setState({
      extendComparisonWithId: undefined
    })
  }

  handlePortfolioTypeChanged = async (portfolioTypes) => {
    portfolioTypes = portfolioTypes.map(i => i.id).filter(id => id !== ALL_ID);
    let params;
    if(portfolioTypes.length > 0) params = {types: portfolioTypes};
    await this.fetchFavoriteLists(params);
  };

  /**
   * Updates currentFilter by key with provided value
   * @param filterField
   * @param filterNewValue
   */
  updateCurrentFilter = (filterField, filterNewValue) => {
    let newCurrentFilter = {...this.state.currentFilter}
    newCurrentFilter[filterField] = filterNewValue
    this.setState({currentFilter: newCurrentFilter})
  }

  /**
   * Fetches favorite list when selected tab is changed
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.currentFilter.type !== this.state.currentFilter.type) {
      this.applyFilters({name: this.state.currentFilter.name})
    }
  }

  /**
   * Fetches favorite list items with provided filters
   */
  applyFilters = (params) => {
    if(this.state.currentFilter.type !== ALL_ID){
      params.types = this.state.currentFilter.type
    }

    this.fetchFavoriteLists(params);
  }

  renderFilters = () => {
    return(
      <DynamicFilters
        searchString={this.state.searchString}
        onSearchFieldChange={(newValue) => this.setState({searchString: newValue})}
        handleApplyFilterBtnClick={() => {
          // update filter's 'name' state with current search string value
          this.updateCurrentFilter('name', this.state.searchString)
          // fetch fav list with proper name filter value
          this.applyFilters({name: this.state.searchString})
        }}
      />
    )
  }

  render() {

    const { classes, viewOnly, title } = this.props;

    return (
      <div className={classes.container} style={{overflowX: 'hidden'}}>
        <Container>
          <div className={classes.navigationContainer} style={{marginBottom: 20}}>
            <h1 className={classes.header}>Favoritenlisten</h1>
            { !viewOnly && this.renderAddNewFavoritelistButton() }
          </div>

          <TabsFilter
            selectedTab={this.state.currentFilter.type}
            onSelectedTabChange={(event, newSelectedTabIndex) => this.updateCurrentFilter('type', newSelectedTabIndex)}
            tabs={this.TABS}
            loading={this.state.favoriteLists.loading}
            renderFilters={this.renderFilters}
            preloaderHeight={188}
          />

          <DashboardTable
            tableLayout={'auto'}
            structure={tableStructure}
            dataSource={this.getFavoriteListsDataSource()}
            expanded={true}
            tableClasses={classes}
            withFooter={false}
            paginationOptions={{
              paginationEnabled: true,
              pageSize: FAVORITE_LIST_PAGE_SIZE
            }}
            options={{
              loading: this.state.favoriteLists.loading,
              onDelete: !!viewOnly ? null : this.handleDeleteFavoriteListModalOpen,
              onEdit: this.handleEditClick,
              onExtend: !viewOnly && this.handleExtendClick,
              auth: this.props.auth,
              actionsNames: this.props.actionsNames || {}
            }}
          />
        </Container>
        {this.state.extendComparisonWithId && (
          <AddProductsToComparison>
            <SelectFavoriteListForProductsComparisonModal
              favoriteListId={this.state.extendComparisonWithId}
              onClose={this.handleExtendClose}
            />
          </AddProductsToComparison>
        )}
        {!viewOnly && (
          <DeleteFavoriteListModal
            open={this.state.deleteFavoriteListModalState.visible}
            favoriteListName={this.state.deleteFavoriteListModalState.name}
            onClose={this.handleDeleteModalClose}
            onAccept={this.handleDeleteFavoriteList}
          />
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps)(withStyles(styles)(List));
