import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import clsx from "clsx";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import BenchmarkSelector from '../BenchmarkSelector';
import WeightSelector from '../WeightSelector';
import Tooltip from '../../../../../components/Tooltip';

import useStyles from './styles';

import BaseDialog from '../../../../../components/BaseDialog/BaseDialog';
import { BcaDeleteIconButton } from '../../../../../components/Buttons/BcaIconButton/BcaIconButton';
import { displayErrorSnackBar, displaySuccessSnackBar } from '../../../../../components/SnackbarProvider/actions';

const mapStateToProps = (state) => ({
  benchmark_settings: state.get('sharedSettings').toJS().data.benchmark_settings
});

export default connect(mapStateToProps)((props) => {

  const classes = useStyles();

  const {
    onBenchmarkConfigured, // function, receives benchmarks which were configured, or undefined if modal was closed
    dispatch,
    benchmark_settings,
    benchmarks,
    withoutSnackbar,
  } = props;


  const expectedWeight = 100;
  const nonePiInitialWeight = 0;

  const [selectedBenchmarks, setSelectedBenchmarks] = React.useState(
    (benchmarks || []).map(setting => {
      const benchmark = benchmark_settings.find(item => item.id === +setting.benchmark);

      return {...benchmark, weight: +setting.percentage}
    }));
  const [isSavingInProgress, setSavingProgress] = React.useState(false);
  const [totalWeight, setTotalWeight] = React.useState(0);
  const [isChanged, setChanged] = React.useState(false);
  const [isPIBenchmarkSelected, setPIBenchmarkSelected] = React.useState(false);

  const [alertOpen, setAlertOpen] = React.useState(false);

  React.useEffect(() => {
    const isPISelected = selectedBenchmarks
      .some(selected => benchmark_settings.find(benchmark => benchmark.id === selected.id).is_private_investment);

    const total = selectedBenchmarks.reduce((accumulator, benchmark) => {
      return +benchmark.weight + accumulator;
    }, 0);

    setTotalWeight(total);
    setPIBenchmarkSelected(isPISelected);
  }, [selectedBenchmarks]);


  const handleBenchmarkChanged = (index) => (benchmark) => {
    if (benchmark) {
      const benchmarks = [...selectedBenchmarks];
      benchmarks[index] = {...benchmark, weight: benchmark.is_private_investment? expectedWeight : nonePiInitialWeight};
      setSelectedBenchmarks(benchmarks);
      setChanged(true);
    } else {
      handleBenchmarkDeleted(index);
    }
  };

  const handleBenchmarkDeleted = (index) => {
    const benchmarks = [...selectedBenchmarks];
    benchmarks.splice(index, 1);
    setSelectedBenchmarks(benchmarks);
    setChanged(true);
  };

  const handleWeightChanged = (index) => (weight) => {
    let benchmarks = [...selectedBenchmarks];

    if (benchmarks[index]) {
      benchmarks[index].weight = weight;
      setSelectedBenchmarks(benchmarks);
      setChanged(true);
    }
  };

  const handleSaveConfigurationClick = async () => {
    if (!isSavingInProgress) {
      setSavingProgress(true);

      try {
        const benchmarks = selectedBenchmarks.map(benchmark => ({
          ...benchmark,
          benchmark: benchmark.id,
          percentage: benchmark.weight,
          weight: benchmark.weight / 100,
        }));

        setSavingProgress(false);
        if(!withoutSnackbar) {
          dispatch(displaySuccessSnackBar('Einstellungen gespeichert'));
        }

        setChanged(false);

        if (_.isFunction(onBenchmarkConfigured)){ onBenchmarkConfigured(benchmarks); }
      }
      catch(err) {
        setSavingProgress(false);
        if(!withoutSnackbar) {
          dispatch(displayErrorSnackBar('Es ist ein Fehler aufgetreten.'));
        }
      }
    }
  };

  const forceCloseSettingsModal = () => {
    if (_.isFunction(onBenchmarkConfigured)) { onBenchmarkConfigured(); }
    setAlertOpen(false);
    setChanged(false);
    setSelectedBenchmarks([]);
  }

  const handleWarningAlertClose = () => {
    forceCloseSettingsModal();
  };

  const handleSettingsModalClose = () => {
    if (isChanged) {
      return setAlertOpen(true);
    }
    forceCloseSettingsModal();
  };

  const renderBenchmarkSelector = (benchmark, index) => {
    const key = benchmark && (`${benchmark.id}_${benchmark.weight}`) || 'empty';
    return (
      <Grid item xs={12} key={key}>
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item xs className={classes.benchmarkSelector}>
            <BenchmarkSelector
              selectedBenchmarks={selectedBenchmarks}
              isPIBenchmarkSelected={isPIBenchmarkSelected}
              benchmark={benchmark && benchmark.id}
              handleSelectedBenchmarkChanged={handleBenchmarkChanged(index)}
            />
          </Grid>
          {benchmark && (
            <Grid item xs={'auto'}>
              <BcaDeleteIconButton onClick={() => handleBenchmarkDeleted(index)} color={"secondary"} fontSize={24}/>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderWeightSelector = (benchmark, index) => {
    const key = benchmark && benchmark.id || 'empty';
    const benchmark_setting = benchmark && benchmark_settings.find(item => item.id === benchmark.id);
    const isPrivateInvestmentSelected = benchmark_setting && benchmark_setting.is_private_investment;

    return (
      <WeightSelector
        key={key}
        weight={benchmark && benchmark.weight}
        disabled={!benchmark || isPrivateInvestmentSelected}
        max={expectedWeight - totalWeight}
        handleWeightChanged={handleWeightChanged(index)}
      />
    );
  };

  const renderSaveButton = () => {
    const getButton = (disabled, message) => {
      const button = (
        <Button
          className={classes.acceptButton}
          onClick={handleSaveConfigurationClick}
          color="primary"
          variant={"contained"}
          disabled={disabled}
          autoFocus>
          {isSavingInProgress ? (<CircularProgress style={{height: 20, width: 20, color: 'white'}} />) : ("Speichern")}
        </Button>
      )

      if (message) {
        return (
          <Tooltip title={message} placement={"top"}>
            <div>
              {button}
            </div>
          </Tooltip>
        )
      } else {
        return button;
      }
    }
    if (selectedBenchmarks.length) {
      const isTotalCorrect = totalWeight === expectedWeight;
      const isAllWeightsCorrect = selectedBenchmarks.every(selector => +selector.weight > 0);

      if (isPIBenchmarkSelected) {
        return getButton(false);
      }

      if (!isTotalCorrect) {
        return getButton(true, 'Die Summe aller Benchmarks \n muss 100 % ergeben.')
      }

      if (!isAllWeightsCorrect) {
        return getButton(true, 'Ein gewählter Benchmark \n muss mehr als 0% Gewichtung haben');
      }

      return getButton(false);
    } else {
      return getButton(false);
    }
  };



  return (
    <React.Fragment>
      <BaseDialog
        open={true}
        onClose={() => isChanged && setAlertOpen(true)}
        fullWidth={true}
        maxWidth={'md'}
        classes={{
          paper: classes.modalPaper
        }}
        scroll={'body'}
        title={"Benchmark konfigurieren"}
        handleClose={handleSettingsModalClose}
        actions={
          <>
            <Button className={classes.acceptButton} onClick={handleSettingsModalClose} color="primary" variant={"outlined"}>
              Schließen
            </Button>
            {renderSaveButton()}
          </>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.sectionHeader}>Benchmark</Grid>
              {selectedBenchmarks.map(renderBenchmarkSelector)}
              {!isPIBenchmarkSelected && renderBenchmarkSelector(null, selectedBenchmarks.length)}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.sectionHeader}>Gewichtung</Grid>
              {selectedBenchmarks.map(renderWeightSelector)}
              {selectedBenchmarks.length === 0 && renderWeightSelector(null, selectedBenchmarks.length)}
              {selectedBenchmarks.length > 0 && (
                <Grid item xs={12} className={classes.sectionConclusion}>
                  <span className={clsx(classes.totalWeight, !isPIBenchmarkSelected && totalWeight !== expectedWeight && classes.warnTotal)}>
                    Gesamt {isPIBenchmarkSelected ? 100 : totalWeight} %
                  </span>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </BaseDialog>

      <Dialog
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          classes={{root: classes.dialogTitleRoot}}
        >
          {"Wollen Sie die Konfiguration der Benchmark ohne Speichern Ihrer Änderungen verlassen?"}
        </DialogTitle>
        <DialogActions>
          <Button className={classes.acceptButton} onClick={() => setAlertOpen(false)} color="primary">
            Nein
          </Button>
          <Button onClick={handleWarningAlertClose} color="primary" className={classes.acceptButton} variant={"contained"} autoFocus>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
});
