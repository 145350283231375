import React from 'react';


export default () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
  <g id="Group_7737" data-name="Group 7737" transform="translate(-1276.496 -1254.585)">
    <path id="Path_1597" data-name="Path 1597" d="M1306.008,1274.05l-7.059-3.523a.883.883,0,0,0-1.277.788v2.1c-6.261-3.125-5.879-2.979-6.176-2.979a.884.884,0,0,0-.879.8c-.005.063,0-.2,0,4.481h-.151l-1.864-9.852a.881.881,0,0,0-.867-.717h-2.412v-1.756h2.059a5,5,0,0,0,5-4.991v-2.349a1.471,1.471,0,0,0-1.471-1.468h-2.353a5,5,0,0,1-8.235,0h-2.353a1.471,1.471,0,0,0-1.471,1.468v2.349a5,5,0,0,0,5,4.991h2.059v1.756h-2.294a.881.881,0,0,0-.865.708l-1.976,9.862h-1.042a.882.882,0,0,0-.883.881v7.105a.882.882,0,0,0,.883.881h28.235a.882.882,0,0,0,.882-.881v-8.867A.881.881,0,0,0,1306.008,1274.05Zm-17.453-17.7h2.059v2.055a3.236,3.236,0,0,1-3.235,3.229h-.811l2.023-2.019a.881.881,0,0,0-1.248-1.246l-2.024,2.02v-.809A3.236,3.236,0,0,1,1288.555,1256.347Zm-10.294,2.055v-2.055h2.059a3.236,3.236,0,0,1,3.235,3.23v.809l-2.023-2.02a.882.882,0,1,0-1.248,1.246l2.023,2.019h-.811A3.236,3.236,0,0,1,1278.26,1258.4Zm3.723,8.509H1287l1.667,8.808h-8.447Zm-3.723,10.57h12.353v5.344H1278.26Zm14.118,5.343v-10.084l5.294,2.643v7.441Zm12.353,0h-5.294v-10.084l5.294,2.643Z" transform="translate(0)" fill="#fff"/>
    <path id="Path_1598" data-name="Path 1598" d="M1388.707,1388.43a.881.881,0,0,0-.882.881v1.762a.882.882,0,0,0,1.765,0v-1.762A.881.881,0,0,0,1388.707,1388.43Z" transform="translate(-93.681 -112.712)" fill="#fff"/>
    <path id="Path_1599" data-name="Path 1599" d="M1433.237,1391.953a.881.881,0,0,0,.882-.881v-1.762a.882.882,0,0,0-1.765,0v1.762A.882.882,0,0,0,1433.237,1391.953Z" transform="translate(-131.153 -112.712)" fill="#fff"/>
  </g>
</svg>
    )
}