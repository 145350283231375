export const TIME_SELECTOR_DATE_CHANGED = 'TIME_SELECTOR_DATE_CHANGED';
export const REPORT_TYPE_CHANGED = 'REPORT_TYPE_CHANGED';

export const BENCHMARK_CONFIGURATION_MODAL_OPENED = 'BENCHMARK_CONFIGURATION_MODAL_OPENED';
export const BENCHMARK_CONFIGURATION_REQUIRED = 'BENCHMARK_CONFIGURATION_REQUIRED';
export const BENCHMARK_CONFIGURATION_PORTFOLIO_ID = 'BENCHMARK_CONFIGURATION_PORTFOLIO_ID';
export const BENCHMARK_CONFIGURATION = 'BENCHMARK_CONFIGURATION';

export const CHART_COLORS = ['#0092E5', '#448744', '#983CA8', '#FCB900', '#FF55BE', '#3CD0DE', '#E56600', '#2330D0', '#9B9B9B', '#2F2F2F'];

export const DEFAULT_EMPTY_SECTION_MESSAGE = 'Keine Daten verfügbar.';

export const ADD_PORTFOLIO_DATA = 'ADD PORTFOLIO DATA';