import CommonAnalysisCard from "../../../../components/AnalysisCard";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import {toShortGermanFormat} from "../../../../../../utils/numberFormater";
import HighChartBase from "../../../../../../components/Charts/Base";
import Tooltip from "../../../../../../components/Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
import {parseResponse, VirtualPortfolioHandlerResource} from "../../../../../../utils/api";
import clsx from "clsx";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from '../../../../../CustomerDashboard/constants';
import _ from 'lodash';

export class ChartRollingVolatility extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      RollingVolaData: null,
      RollingVolaError: null,
      RollingVolaBenchmarkData: null,
      RollingVolaBenchmarkError: null,
      benchmarkDetails: null,
      DataLoading: true,
      showBenchmark: true,
    }

    this.fetchHistoricalData = this.fetchRollingVolatilityData.bind(this);
  }

  componentDidMount() {
    this.fetchRollingVolatilityData()
  }

  fetchRollingVolatilityData() {
    VirtualPortfolioHandlerResource.getRollingVolatilityData(this.props.customerId, this.props.portfolioId)
      .then((response) => {
        parseResponse(response, 'rolling_vola_portfolio',
          data => {
            this.setState({
              RollingVolaData: data['rolling_risk_metrics'],
              RollingVolaError: undefined
            })
          },
          error => {
            this.setState({
              RollingVolaData: undefined,
              RollingVolaError: error
            })
          }
        )

        parseResponse(response, 'rolling_vola_benchmark',
          data => {
            this.setState({
              RollingVolaBenchmarkData: data['rolling_risk_metrics'],
              RollingVolaBenchmarkError: undefined
            })
          },
          error => {
            this.setState({
              RollingVolaBenchmarkData: undefined,
              RollingVolaBenchmarkError: error
            })
          }
        )

        this.setState({
          benchmarkDetails: response['benchmark_details'],
        })

        this.setState({
          DataLoading: false
        });
      })
  }


  renderBenchmarkToolTip() {
    let listItems = this.state.benchmarkDetails.map((item, index) => (
      <li key={index}>
        <span><b>{item.percentage}%</b>&nbsp;{item.benchmark_name}</span>
      </li>
    ))

    return <ul>
      {listItems}
    </ul>
  }

  isEnoughData(){
    return this.state.RollingVolaData && 
      this.state.RollingVolaData.some(value => (value.volatility_annual !== undefined))
  }

  renderChart() {
    const { classes } = this.props;
    const volaName = this.props.portfolioName;
    const portfolio_vol = this.state.RollingVolaData
      .filter(value => (value.volatility_annual !== undefined))
      .map(value => ([Date.parse(value.date), value.volatility_annual * 100]));

    const benchmarkName = 'Benchmark';
    let benchmark_vol = [];
    if(this.state.showBenchmark && this.state.RollingVolaBenchmarkData)
        benchmark_vol = this.state.RollingVolaBenchmarkData
          .filter(value => (value.volatility_annual !== undefined))
          .map(value => ([Date.parse(value.date), value.volatility_annual * 100]));

    const defaultChartColor = '#0596ce';
    const defaultBenchmarkColor = '#7ED2CF';
    const chartLegendStyle = {
      background: defaultChartColor
    };
    const benchmarkLegendStyle = {
      background: defaultBenchmarkColor
    };

    return <div className={classes.chartContainer}>
      <ul className={classes.legend}>
        <li className={classes.legendItem}>
          <div className={classes.bullet} style={chartLegendStyle} />
          <label>{volaName}</label>
        </li>

        {!_.isEmpty(this.state.benchmarkDetails) && this.state.showBenchmark &&
          <li
            className={clsx(classes.legendItem, this.state.RollingVolaBenchmarkError || !this.state.showBenchmark ? classes.disabled : undefined)}>
            <div className={classes.bullet} style={benchmarkLegendStyle}/>
            <label>Benchmark</label>
            <Tooltip
              placement={'left'}
              disableHoverListener={this.state.RollingVolaBenchmarkError !== undefined}
              disableTouchListener={this.state.RollingVolaBenchmarkError !== undefined}
              disableFocusListener={this.state.RollingVolaBenchmarkError !== undefined}
              title={
                <div>
                  {this.state.RollingVolaBenchmarkError && 'Keine Benchmark erstellt'}
                  {this.renderBenchmarkToolTip()}
                </div>
              }
            >
              <ErrorIcon
                className={clsx(classes.helpIcon, this.state.RollingVolaBenchmarkError ? classes.disabledIcon : undefined)}/>
            </Tooltip>
          </li>
        }

      </ul>
      <HighChartBase className={classes.chart} options={{
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          data: {
            dateFormat: "YYYY-mm-dd"
          },
          series: [
            {
              type: 'line',
              name: volaName,
              color: defaultChartColor,
              data: portfolio_vol
            },
            {
              type: 'line',
              name: benchmarkName,
              color: defaultBenchmarkColor,
              data: benchmark_vol
            }
          ],
          chart: {
            type: 'line',
            height: 300,
            style: {
              fontFamily: "\"Roboto-Regular\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif",
              fontSize: 14
            }
          },
          rangeSelector: {
            enabled: false,
            allButtonsEnabled: false,
            inputEnabled: false,
            verticalAlign: 'top',
            buttons: null,
          },
          navigator: {
            enabled: false
          },
          legend: {
            enabled: false,
          },
          scrollbar: {
            enabled: false
          },
          plotOptions: {
            area: {
              fillColor: undefined
            },
            series: {
              dataGrouping: {
                dateTimeLabelFormats: {
                  week: ["Woche vom %A, %b %e, %Y"],
                }
              },
              type: 'line',
              fillColor: undefined,
              compareBase: 0,
              showInNavigator: true,
            }
          },
          tooltip: {
            dateTimeLabelFormats: {
              day: "%b %e, %Y"
            },
            pointFormat: '<span><div style="display: inline-block; vertical-align: baseline; width: 10px; height: 10px; border-radius:50%; background-color: {series.color};" /><div style="display: inline; font-size: 14px; color: #80858C; margin-left: 15px;">{point.y} %</div></span><br/>',
            valueDecimals: 2,
            split: false,
            shared: true,
            backgroundColor: 'white',
            shadow: {
              color: '#00000026',
              offsetX: 0,
              offsetY: 0,
              width: 10,
              opacity: 0.15
            },
            padding: 20,
            useHTML: true,
            shape: 'square',
            borderWidth: 0,
            borderRadius: 4,
            headerFormat: '<span style="font-size: 14px; font-family: Roboto-Bold;">{point.key}</span><br/><br/>',
            style: {
              fontFamily: '"Roboto-Regular"'
            }
          },
          yAxis: {
            opposite: false,
            align: 'right',
            title: {
              enabled: false
            },
            labels: {
              enabled: true,
              x: -2,
              formatter: function() {
                return toShortGermanFormat(this.value, '', ' %', 2, true);
              },
            }
          },
          xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
              year: '%Y'
            },
            tickLength: 0,
            labels: {
              style: {
                fontSize: 14
              }
            }
          },
          responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                tooltip: {
                  positioner: function (labelWidth, labelHeight, point) {
                    return point.isHeader ? this.getPosition(labelWidth, labelHeight, point) : { x: 80, y: 30 };
                  }
                }
              }
            }]
          }
      }} language={'DE'}/>
    </div>
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
        classes={{
          card: classes.root
        }}
        title={'Rollierende 1-Jahres Volatilität-Entwicklung'}
        loading={this.state.DataLoading}
        content={
          <div className={classes.container}>
            {
              this.state.DataLoading ? 'loading' : this.state.RollingVolaError ? this.state.RollingVolaError : this.isEnoughData() ? this.renderChart() : DEFAULT_EMPTY_SECTION_MESSAGE
            }
          </div>
        }
        expanded={this.props.expanded}
        onExpandedClick={this.props.onExpandedClick}
    />
  }
}

export default withStyles(styles)(ChartRollingVolatility)
