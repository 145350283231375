import { makeStyles } from '@material-ui/core';

const styles = theme => ({
  tableCell: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    '&:nth-of-type(1)': {
      width: 250,
    },
    '&:nth-of-type(2)': {
      width: 105,
    },
    '&:nth-of-type(3)': {
      width: 115,
    },
    '&:nth-of-type(4)': {
      width: 125,
    },
    [theme.breakpoints.down('md')]: {
     '&:nth-of-type(1)':{
        width: 200,
     }
    }
  },
});

export default makeStyles(styles)
