import React from "react";

export default () => {
  return (
    <svg
      id="Gruppe_8196"
      data-name="Gruppe 8196"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_8063"
            data-name="Rechteck 8063"
            width="30"
            height="30"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_8195" data-name="Gruppe 8195" clip-path="url(#clip-path)">
        <path
          id="Pfad_7833"
          data-name="Pfad 7833"
          d="M16.594,24.537a1.455,1.455,0,1,1-1.455-1.455,1.455,1.455,0,0,1,1.455,1.455m13.08,5.236a.938.938,0,0,1-1.322-.1l-1.122-1.3v.689a.938.938,0,0,1-1.875,0V5.046H24.32V29.063a.938.938,0,0,1-1.875,0v-.036c-.011,0-.021,0-.032,0H7.866a.931.931,0,0,1-.294-.051v.084a.937.937,0,1,1-1.875,0V5.046H4.663V29.063a.938.938,0,0,1-1.875,0v-.591L1.618,29.707A.938.938,0,0,1,.256,28.418l2.531-2.675V5.046H2.16a.937.937,0,0,1-.875-.6L.062,1.275A.937.937,0,0,1,.937,0H29.062a.937.937,0,0,1,.875,1.275L28.714,4.445a.937.937,0,0,1-.875.6H27.23V25.5l2.542,2.947a.938.938,0,0,1-.1,1.323M2.3,1.875l.5,1.3H27.2l.5-1.3Zm5.268,4.8h6.491V5.046H7.572ZM22.445,8.554h-1.52V12.9a.938.938,0,0,1-.754.919l-9.7,1.94a.929.929,0,0,1-.184.018.937.937,0,0,1-.938-.937V8.554H7.572V20.418a.931.931,0,0,1,.294-.051H22.413c.011,0,.021,0,.032,0Zm-11.218.11v5.031l7.823-1.565V8.664ZM22.445,27.159v-4.92c-.011,0-.021,0-.032,0H7.866a.931.931,0,0,1-.294-.052v5.017a.921.921,0,0,1,.294-.052H22.413c.011,0,.021,0,.032,0m0-22.113H15.937V6.679h6.508Z"
          transform="translate(0)"
          fill="#80858c"
        />
      </g>
    </svg>
  );
};
