import React from 'react';
import _ from 'lodash';
import cls from 'clsx';

import { Grid, Button } from '@material-ui/core';

import {
  DropdownFilter,
  CheckboxFilter,
  RangeFilter,
  SearchFilter
} from '../../../../components/Filters';
import Tooltip from '../../../../components/Tooltip';
import {
  getBrokerClientTypes
} from '../../../../components/FilteringPanel/components/ListSelector/constants';
import { 
  REPORT_DISTRIBUTION_TYPE, 
  LAST_REPORT_SENT_DATE,
  PRODUCT_TYPES,
  ASSET_CLASS
} from '../../constants';

import useStyles from './styles';
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";


const Filters = (props) => {
  const classes = useStyles();
  const {
    filters,
    handleFiltersUpdated,
    disabledFields,
    isChief,
    handleApplyButtonClicked,
    errors,
  } = props;

  const [customerName, setCustomerName] = React.useState(undefined);
  const [expandedPanel, setExpandedPanel] = React.useState(undefined);

  /**
   * Update value of customer name filter
   * @param {String} value New value, that should be used for filtering by customer name
   */
  const handleSearchNameUpdated = (value) => {
    handleFiltersUpdated('customerName', customerName);
  };

  /**
   * Update value of filter with selected option
   * 
   * @param {*} fieldName Name of the filter option, that should be updated
   */
  const handleDropdownValueChanged = fieldName => value => {
    handleFiltersUpdated(fieldName, value);
  };

  /**
   * Update value of filter with selected options
   * 
   * @param {*} fieldName Name of the filter option, that should be updated
   */
  const handleCheckboxValueChanged = fieldName => value => {
    if (filters.hasOwnProperty(fieldName)) {
      let values = [...filters[fieldName]];

      let newValues = [];

      if (_.find(filters[fieldName], (item) => item.value === value.value)) {
        newValues = _.filter(filters[fieldName], (item) => item.value !== value.value);
      } else {
        newValues = [...values, value];
      } 

      handleFiltersUpdated(fieldName, newValues);
    }
  };

  const handleSelectAll = (fieldName, options) => () => {
    if (filters.hasOwnProperty(fieldName)) {
      let values = [...filters[fieldName]];
      let newValues = [];

      if (values.length == 0) {
        newValues = Object.keys(options).map((key) => options[key]);
      }

      handleFiltersUpdated(fieldName, newValues);
    }
  }

  /**
   * Update value of filter with selected range of values
   * 
   * @param {*} fieldName Name of the filter option, that should be updated
   */
  const handleRangeValueChanged = fieldName => (values) => {
    if (filters.hasOwnProperty(fieldName)) {
      try {
        handleFiltersUpdated(fieldName, values);
      } catch(error) {}
    }
  };

  const onApplyButtonClicked = () => {
    if (!_.isNil(handleApplyButtonClicked)) {
      handleApplyButtonClicked()
    }
  }

  const handleExpandedClicked = (value) => () => {
    let result = expandedPanel;

    if (result && result == value) {
      result = undefined;
    } else {
      result = value;
    }

    setExpandedPanel(result);
  };

  return (
    <div className={classes.container} id="create-group-filter-tour-element">
      <Grid container className={cls(classes.filters, classes.filtersTransparent)}>
        <Grid item lg={12} md={6} sm={6} xs={12} >
          <DropdownFilter 
            title="Struktur" 
            selected={filters.customerType} 
            options={getBrokerClientTypes(isChief)}
            handleSelectedChanged={handleDropdownValueChanged('customerType')}
          />
        </Grid>
        <Grid item lg={12} md={6} sm={6} xs={12}>
          <SearchFilter 
            title="Kundenname, ISIN, Instrumentenname"
            value={customerName}
            handleValueChanged={(value) => setCustomerName(value)}
            handleSearchClick={handleSearchNameUpdated}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.filters}>
        <Grid item lg={12} md={6} sm={6} xs={12} >
          <CheckboxFilter 
            title="Produktkategorie filtern"
            options={PRODUCT_TYPES}
            checked={filters.productTypes}
            handleCheckedChanged={handleCheckboxValueChanged('productTypes')}
            expanded={expandedPanel && expandedPanel == 1}
            onExpandedClick={handleExpandedClicked(1)}
            handleSelectAll={handleSelectAll('productTypes', PRODUCT_TYPES)}
          />
        </Grid> 
        <Grid item lg={12} md={6} sm={6} xs={12} >
          <CheckboxFilter 
            title="Assetklasse filtern" 
            options={ASSET_CLASS}
            checked={filters.assetClasses}
            handleCheckedChanged={handleCheckboxValueChanged('assetClasses')}
            expanded={expandedPanel && expandedPanel == 2}
            onExpandedClick={handleExpandedClicked(2)}
            handleSelectAll={handleSelectAll('assetClasses', ASSET_CLASS)}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.filtersAction}>
          {
            !_.isNil(errors) ? (
              <Tooltip title={"Minimum darf nicht größer als Maximum sein"} placement={"top"}>
                <div style={{display: 'inline-block'}}>
                  <PrimaryButton
                    text={"Filter übernehmen"}
                    onButtonClick={onApplyButtonClicked} 
                    disabled={!_.isNil(errors)}/>
                </div>
              </Tooltip>
            ) : (
              <div style={{display: 'inline-block'}}>
                <PrimaryButton
                  text={"Filter übernehmen"}
                  onButtonClick={onApplyButtonClicked} 
                  disabled={!_.isNil(errors)}/>
              </div>
            )
          }
          <Button color="primary" className={classes.clearFiltersButton}>
            Zurücksetzen
          </Button>
        </Grid>
      </Grid>
    </div>
  )
};

export default Filters;