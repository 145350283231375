import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import connect from "react-redux/es/connect/connect";

/* Material-UI Components */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

/* BCA Components */
import EquityForm from './components/EquityForm';
import InsuranceForm from './components/InsuranceForm';
import CashForm from './components/CashForm';
import ImmobilienForm from './components/ImmobilienForm';
import ExternalForm from './components/ExternalForm';
import withNotification from '../NotificationProvider';

/* BCA modules */
import useStyles from './styles';
import { ASSET_MODAL_TYPE } from './constants';
import {PortfolioManagerResource} from "../../utils/api";
import AssetModalMenu from "./components/AssetModalMenu";
import {enableAssetModalMenu, setAssetModalContentType} from "./actions";

const mapStateToProps = (state) => ({
  contentType: state.get('assetModal').toJS().contentType,
  menuDisable: state.get('assetModal').toJS().menuDisable,
});

export default connect(mapStateToProps)(withNotification(withRouter((props) => {
  const classes = useStyles();

  const {
    open,
    onModalClose,
    onAssetCreate,
    customerId,
    dispatch,
    contentType,
    menuDisable
  } = props;

  const [formData, setFormData] = React.useState(null);
  const [formDataErrors, setFormDataErrors] = React.useState([]);

  React.useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
    }
    else {
      document.body.style.overflowY = 'auto';
    }
  }, [open]);

  const handleModalClose = () => {
    if (onModalClose) {
      onModalClose();
    }
    dispatch(setAssetModalContentType(ASSET_MODAL_TYPE.MENU));
    dispatch(enableAssetModalMenu());
    setFormData(null);
    setFormDataErrors([]);
  };

  const renderContent = () => {
    switch (contentType) {
      case ASSET_MODAL_TYPE.MENU: {
        return <AssetModalMenu
          handleMenuItemClick={(type) => {
            setFormData(null);
            setFormDataErrors([]);
            dispatch(setAssetModalContentType(type))
          }}
        />
      }
      case ASSET_MODAL_TYPE.EQUITY: {
        return <EquityForm handleFormChange={setFormData} errors={formDataErrors}/>
      }
      case ASSET_MODAL_TYPE.INSURANCE: {
        return <InsuranceForm handleFormChange={setFormData} errors={formDataErrors}/>
      }
      case ASSET_MODAL_TYPE.CASH: {
        return <CashForm handleFormChange={setFormData} errors={formDataErrors}/>
      }
      case ASSET_MODAL_TYPE.IMMOBILIEN: {
        return <ImmobilienForm handleFormChange={setFormData} errors={formDataErrors}/>
      }
      case ASSET_MODAL_TYPE.EXTERNAL: {
        return <ExternalForm handleFormChange={setFormData} errors={formDataErrors} customerId={customerId}/>
      }
      default: {
        return <AssetModalMenu />
      }
    }
  };


  const handleCreateAsset = () => {
    if (customerId && contentType) {
      PortfolioManagerResource.at(`customer/${customerId}/other-assets/?asset_type=${contentType}`).post(formData)
      .then(() => {
        dispatch(setAssetModalContentType(ASSET_MODAL_TYPE.MENU));
        setFormDataErrors([]);
        if (!_.isNil(onAssetCreate)) {
          onAssetCreate(formData.name);
        }
      })
      .catch(error => {
        setFormDataErrors(error && error.data || error || []);
      });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleModalClose}
        fullWidth={true}
        scroll="body"
        maxWidth={'md'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus
        className={classes.container}
        classes={
          {
            root: classes.root,
            paper: classes.paper
          }
        }
      >
        <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
          {contentType !== ASSET_MODAL_TYPE.MENU && !menuDisable && (
            <div>
              <div className={classes.smHide}>
                <span
                  className={classes.previousStepButton}
                  onClick={() => dispatch(setAssetModalContentType(ASSET_MODAL_TYPE.MENU))}
                >
                  &#8249; Vermögenswert auswählen
                </span>
              </div>
              <div className={classes.smShow}>
                <IconButton
                  className={classes.previousIconButton}
                  disableRipple aria-label="back"
                  onClick={() => dispatch(setAssetModalContentType(ASSET_MODAL_TYPE.MENU))}
                >
                  <ArrowBackIos />
                </IconButton>
              </div>
            </div>
          )}

          {contentType !== ASSET_MODAL_TYPE.MENU ? 'Vermögenswert hinzufügen' : ' Vermögenswert & Verbindlichkeit manuell hinzufügen'}
          <IconButton disableRipple aria-label="close" className={classes.closeButton} onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          {renderContent()}
        </DialogContent>
        {contentType !== ASSET_MODAL_TYPE.MENU && (
          <DialogActions className={classes.modalAction}>
            <div>
              <Button onClick={handleModalClose} color="primary" className={classes.discardButton}>
                Abbrechen
              </Button>
              <Button onClick={handleCreateAsset} color="primary" variant="contained" className={classes.acceptButton}>
                Hinzufügen
              </Button>
            </div>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
})));