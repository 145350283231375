import React from 'react';

import { TextField } from '@material-ui/core';

import useStyles from '../styles';

export default (props) => {
  const classes = useStyles();

  const {
    label,
    value,
    onChange,
    error,
    disabled,
    endAdornment,
    helperText
  } = props;

  return (
    <TextField
      label={label}
      error={!!error}
      helperText={error || helperText}
      className={classes.textField}
      value={value}
      onChange={onChange}
      margin="normal"
      disabled={disabled}
      InputProps={
        {
          className: classes.textField,
          classes: {
            root: classes.textFieldRoot,
            underline: classes.textFieldUnderline,
            disabled: classes.textFieldDisabled,
          },
          endAdornment: endAdornment
        }
      }
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.labelRoot,
          focused: classes.labelFocused
        }
      }}
    />
  )
}