import React from 'react';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import _ from "lodash";

import {
	Card,
	CardContent
} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";

import GuideTourStep from './GuideTourStep';
import Tour from './GuideNavigation';

import useStyles from './styles';
import {
  closeGuideModal
} from '../Header/actions';
import {
  GuideTourStep as GuideStep
} from './utils';
import useOnClickOutside from "../../utils/useOnClickOutside";
import {SharedSettingsResource} from "../../utils/api";
import {deleteGuideTourSettings, setGuideTourSettings} from "./actions";


const mapStateToProps = (state) => ({
	guide: state.get('guide').toJS(),
  settings: state.get('guideTourSettings').toJS()
});

const GuideTour = (props) => {
  const classes = useStyles();
  const {
    steps,
    title,
    guide,
    dispatch,
    history,
    onGuideTourClosed,
    settings
  } = props;

  const [stepsInternal, setSteps] = React.useState([]);
  const [initialPathname, setInitialPathname] = React.useState(undefined);

  const node = React.useRef();

  React.useEffect(() => {
    if (_.isEmpty(settings.steps)) {
      fetchGuideTourSteps();
    }
    return () => {
      dispatch(deleteGuideTourSettings())
    };
  }, [])

  React.useEffect(() => {
    if (guide.open && !initialPathname) {
      setInitialPathname(history.location.pathname);
    }
  }, [guide.open]);

  React.useEffect(() => {
    if (steps) {
      const guideTourSteps = steps.map(step => {
        step.updateStepsWithSettings(settings);
        return step;
      })
      setSteps(guideTourSteps);
    }
  }, [steps]);

  useOnClickOutside(node, () => {
    if (guide.open) {
      doCloseGuideModal();
    }
  });

  const fetchGuideTourSteps = async () => {
    try {
      const steps = await SharedSettingsResource.at('guide-tour-steps/').get();
      dispatch(setGuideTourSettings(steps));
    } catch (e) {
      dispatch(setGuideTourSettings({}));
    }
  };

  const doCloseGuideModal = () => {
    dispatch(closeGuideModal());
    if (initialPathname) {
      setInitialPathname(undefined);
      history.replace(initialPathname);
    }
    if (onGuideTourClosed) {
      onGuideTourClosed();
    }
  };

 const handleStepUpdated = (step_id, progress) => {

  let stepsCopy = [...stepsInternal];

  for (let i = 0; i < stepsCopy.length; i++) {
    if (stepsCopy[i].group === step_id && stepsCopy[i].completedStepsNumber < progress) {
      stepsCopy[i] = new GuideStep(steps[i].id, steps[i].name, steps[i].steps, steps[i].page, steps[i].group);
      stepsCopy[i].completedStepsNumber = progress;
      setSteps(stepsCopy);
      break;
    }
  }
 };

 const renderGuideSteps = () => {
   let result = [];

   for (let i = 0; i < stepsInternal.length; i++) {
      result.push(
        <GuideTourStep
          key={i}
          step={stepsInternal[i]}
          index={i + 1}
          is_priority={stepsInternal[i].completedStepsNumber < stepsInternal[i].steps.length}
        />);
   }

   return result;
 };

	return (
    <>
      <Card ref={node} className={classes.guideTourCard} style={{display: guide.open ? 'block' : 'none'}}>
        <CardContent className={classes.guideTourCardContent}>
          <div className={classes.headerSection}>
            <p>{title}</p>
            <CloseIcon className={classes.closeIcon} onClick={doCloseGuideModal}/>
          </div>
          <div className={classes.contentSection}>
            {renderGuideSteps()}
          </div>
        </CardContent>
      </Card>
      <Tour onStepUpdated={handleStepUpdated}/>
    </>
	)
};

export default connect(mapStateToProps)(withRouter(GuideTour));