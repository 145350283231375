import { createStyles, makeStyles } from '@material-ui/core';

const styles = (theme) => createStyles({
  paginationWrapper: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paginationItem: {
    padding: 4
  },
});

export default makeStyles(styles)