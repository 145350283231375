import { withStyles } from "@material-ui/core";
import {ALL, CONFIG} from "./constants"
import Button from "@material-ui/core/Button";
import React from "react";
import styles from "./styles";
import clsx from 'clsx'
import moment from "moment";


class TimeRangesButtonsPad extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedCode: this.props.selected || 'ALL'
    }
    this.config = this.props.config || CONFIG
  }

  componentDidMount() {
    this.reload()
  }

  reload() {
    let dateStart, dateEnd;

    if (this.state.selectedCode === ALL) {
      dateStart = undefined;
      dateEnd = undefined;
    } else {
      const config = this.config[this.state.selectedCode];
      if(config['custom']) {
        dateStart = config['start'];
        dateEnd = config['end'] || moment();
      } else {
        dateStart = moment();
        dateEnd = moment();
        dateStart.subtract(config['number'], config['unit'])
      }
      dateStart = dateStart.format('YYYY-MM-DD')
      dateEnd = dateEnd.format('YYYY-MM-DD')
    }

    this.props.onRangeChange(dateStart, dateEnd, this.state.selectedCode)
  }

  handleClick(code) {
    this.setState({
      selectedCode: code
    }, this.reload)
  }

  getButtons() {

    const { classes } = this.props;

    const buttons = [];

    for (const [code, config] of Object.entries(this.config)) {

      if (this.props.codesToShow && this.props.codesToShow.indexOf(code) === -1) {
        continue;
      }

      buttons.push(
        <Button
          key={code}
          onClick={() => {this.handleClick(code)}}
          className={clsx(classes.button, this.state.selectedCode === code && classes.selected)}
          disableRipple={true}
          disabled={this.props.disabled}
        >
          { config['title'] }
        </Button>
      )
    }

    return buttons;
  }

  render() {
    const { classes } = this.props;

    return (
        <div className={classes.wrapper}>
          {this.getButtons()}
        </div>
    )
  }
}

export default withStyles(styles)(TimeRangesButtonsPad);