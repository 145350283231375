import React from "react";
import {Typography, withStyles} from "@material-ui/core";
import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import _ from 'lodash'
import ExpandButton, {
  ExpandIconButton
} from '../../../CustomerDashboard/components/Widgets/components/DashboardCard/components/ExpandButton'


class AnalysisCard extends React.Component {

  constructor(props) {

    super(props);

  }

  render() {
    const { classes,
      title,
      subtitle,
      content,
      sup,
      expanded = true, // flag to hide/show card content by default
      onExpandedClick // function that is a flag to render expand btn
    } = this.props;

    return <div className={clsx(classes.card, !expanded && 'collapsed')}>

      <div style={{display: 'flex'}}>
        <div className={classes.headerWrapper}>
          {title &&
            <Typography variant={"h2"} className={classes.title}>
              {title}{sup && <sup>{sup}</sup>}
            </Typography>
          }
          {subtitle &&
            <Typography variant={"h3"} className={classes.subtitle}>
              {subtitle}
            </Typography>
          }
        </div>

        {_.isFunction(onExpandedClick) &&
          <div style={{display: 'inline-block', marginLeft: 'auto', marginRight: 0}}>
            <ExpandIconButton
              expanded={expanded}
              onChanged={onExpandedClick}
            />
          </div>
        }
      </div>

      {expanded ?
        (
          this.props.loading ? (
            <div className={classes.loaderWrapper}> <CircularProgress/> </div>
          ) : (
            <div className={clsx((title || subtitle) && classes.contentWrapper)}> { content } </div>
          )
        ):(
          <></>
        )
      }
    </div>
  }
}

export default withStyles(styles)(AnalysisCard);
