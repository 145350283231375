import React from 'react';
import _ from 'lodash';

export const TabsSharedDataContext = React.createContext({})

export function useTabsSharedDataContext() {

  return React.useContext(TabsSharedDataContext)

}

export const TabsSharedData = ({children}) => {

  const [data, setData] = React.useState({
    updatedAt: undefined
  });

  const setDataValue = (key, hashKey, value) => {
    setData((current) => {
      _.set(current, `${key}.${hashKey}`, value);
      // Store timestamp when shared data was updated, so we could use it in hooks as dependency;
      current.updatedAt = +new Date();
      return {...current}
    });
  }

  /**
   * We have two keys for data cleaning.
   * key is generic data key to store cached data.
   * hashKey is uniq data key that used to get cached data and build according to latest data retrieving params
   * @example
   * getData('portfolio_data', 'jfhu734Jsjdfs8df8KJNFSD')
   * @param key
   * @param hashKey
   */
  const getDataValue = (key, hashKey) => {
    const keyData = data[key];
    if (!keyData) {
      return undefined;
    }

    if (!keyData.hasOwnProperty(hashKey)) {
      // if there is no data for hashKey - clean all cached data for specified key.
      setData((current) => {
        delete current[key];
        return {...current};
      })
      return undefined;
    }

    return keyData[hashKey]
  }

  return (
    <TabsSharedDataContext.Provider value={{setDataValue, getDataValue, updatedAt: data.updatedAt}}>
      {children}
    </TabsSharedDataContext.Provider>
  )
}