import React from "react";
import _ from "lodash";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { withStyles } from "@material-ui/core/styles";
import styles from "../PortfolioListDropdown/styles";

const RatingRangeStars = (props) => {
  const { theme, onChangeRange, fontSize, value, maxStars } = props;

  const [values, setValues] = React.useState([]);
  const [initValue, setInitValue] = React.useState(value);
  const [minValue, setMinValue] = React.useState(null);
  const [maxValue, setMaxValue] = React.useState(null);

  const setStarsValues = (start, end, callback) => {
    let vals = [];
    end = end || minValue;
    if (!end) {
      setMinValue(start);
      vals = [start, start];
      if (callback) callback(vals);
    } else if (start > end) {
      setMaxValue(start);
      vals = _.range(end, start + 1, 1);
      if (callback) callback([end, start]);
    } else if (start < end) {
      setMinValue(start);
      vals = _.range(start, end + 1, 1);
      setMaxValue(end);
      if (callback) callback([start, end]);
    } else {
      setMinValue(start);
      setMaxValue(start);
      vals = [start, start];
      if (callback) callback(vals);
    }
    setValues(vals);
  }

  React.useEffect(() => {
    if (!value[0] && !value[1]) {
      setValues([]);
      setMinValue(null);
      setMaxValue(null);
    } else {
      // default values, TODO make this nice
      // setStarsValues(value[0], value[1])
      let vals = [value[0], value[0]]
      if (value[0] > value[1]) {
        setMaxValue(value[0]);
        vals = _.range(value[1], value[0] + 1, 1);
      } if (value[0] < value[1]) {
        setMinValue(value[0]);
        vals = _.range(value[0], value[1] + 1, 1);
        setMaxValue(value[0]);
      }
      setValues(vals);
    }
  }, [value]);

  const stars = maxStars || 5;

  const clickIcon = (index) => {
    if(!props.noClickHandler){
      if (minValue && maxValue) {
        setMinValue(index);
        setMaxValue(null);
        setValues([index, index]);
        if (onChangeRange) onChangeRange([index, index]);
        return;
      }
      setStarsValues(index, minValue, onChangeRange)
    }
  };

  const buildStars = () => {
    let arr = [];
    for (let i = 1; i <= stars; i++) {
      arr.push(
        <StarIcon
          key={i}
          fontSize="inherit"
          htmlColor={values.includes(i) ? theme.palette.primary.main : "#D8DCDF"}
          onClick={() => clickIcon(i)}
        />
      );
    }
    return arr;
  };

  return (
    <div style={{ fontSize: fontSize || "inherit", cursor: props.noClickHandler ? 'default' : "pointer" }}>
      {buildStars()}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(RatingRangeStars);
