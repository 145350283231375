import {makeStyles} from '@material-ui/core';

export default makeStyles(theme => ({
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 60,
    backgroundColor: '#0092E5',
    zIndex: 1
  },
  footerContentContainer: {
    maxWidth: '1368px!important'
  },
  footerButton: {
    position: 'relative',
    fontFamily: 'Roboto-Medium',
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    fontSize: 16,
    textTransform: 'none',
    lineHeight: '21px',
    boxShadow: 'none',
    padding: '7px 15px',
    height: 35,

    '& > span > i': {
      marginLeft: 10,
      fontSize: 10,
      position: 'relative',
      top: 2
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    }
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  successMessage: {
    marginRight: 125,
    color: 'white',
    fontFamily: 'Roboto-Medium',
    fontSize: 16
  },
  text: {
    fontFamily: 'Roboto-Medium',
    color: 'white',
    fontSize: 16,
    textTransform: 'none',
    textDecoration: 'none',
  },
  backLink: {
    '&:hover': {
      textDecoration: 'underline'
    },
    '& > i': {
      marginRight: 10,
      fontSize: 10,
      position: 'relative',
      top: -1
    }
  }
}));