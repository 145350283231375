import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import clsx from "clsx";

class Article extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes, articleNO } = this.props;
    let border, bg, text, noscope;
    switch (articleNO) {
      case "article 8":
        border = "#BBE479";
        bg = classes.empty8;
        text =
          "Finanzprodukte, welche zusätzlich zu den Kriterien aus Artikel 6, explizit ökologische und soziale Merkmale fördern, vorausgesetzt, dass die Unternehmen in die investiert wird eine gute Unternehmensführung praktizieren, und für die erklärt wird wie diese Ziele erreicht werden sollen. Ein möglicherweise bestimmter Referenzindex muss mit diesem Ansatz vereinbar sein.";
        break;
      case "article 9":
        border = "#54AB5A";
        bg = classes.empty9;
        text =
          "Diese Finanzprodukte haben sich dazu erklärt neben den Merkmalen aus Artikel 6 und 8 durch nachhaltige Investitionen einen positiven Einfluss auf Gesellschaft und Umwelt auszuüben und, im Unterschied zu Artikel 8 Produkten, mindestens ein nicht finanzielles Ziel in den Mittelpunkt ihres Angebotes gestellt. Ein möglicherweise bestimmter Referenzindex muss mit diesem Ansatz vereinbar sein.";
        break;
      case "article 6":
        border = "#9DA7AF";
        bg = classes.empty6;
        text =
          "Finanzprodukte, für welche ökologische, soziale, sowie Aspekte guter Unternehmensführung hinsichtlich der Risiken bezüglich ihrer Anlagestrategie abgeklärt werden und für welche dargelegt wird, warum diese Risiken entweder als nicht relevant erachtet werden oder statt dessen Mechanismen zu ihrem Management aufgesetzt werden.";
        break;
      default:
        border = "black";
        bg = classes.empty;
        text =
          "Für dieses Finanzprodukt sind die ESG Kriterien aus unterschiedlichen Gründen nicht anwendbar.";
        noscope = true;
    }
    return (
      <div className={classes.article} style={{ borderColor: border }}>
        <div className={clsx(classes.text, bg)}>
          {noscope === true ? (
            <h4 className={classes.articleNO} style={{color: 'black'}}>Nicht verfügbar</h4>
          ) : (
            <h4 className={classes.articleNO}>{articleNO.replace('article', 'Artikel')}</h4>
          )}
        </div>
        <div style={{ padding: 10 }}>
          <p className={classes.articleTitle}>{text}</p>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Article);
