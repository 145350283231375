import { makeStyles, createStyles } from "@material-ui/core";

export const styles = (theme) =>
  createStyles({
    titleContainer: {
      "&  p": {
        fontFamily: "Roboto-Bold",
        fontSize: 16,
        color: "#202A38",
        marginTop: 10,
        marginBottom: 1,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
      },
      display: "flex",
      alignItems: "center",
      position: "relative",
    },
    filterSubSectionTitle: {
      fontFamily: "Roboto-Bold",
      fontSize: 16,
      color: "#202A38",
      marginTop: 10,
    },
    filterSectionDetailsContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    filterSectionSummaryTitle: {
      fontFamily: "Roboto-Bold",
      fontSize: 16,
      color: "#202A38",
      padding: "15px 20px",
      margin: 0,
    },

    /** Filter section */
    filterSectionSummaryRoot: {
      position: "relative",
      minHeight: "0 !important",
      padding: 0,
      background: "#F5F6F7",
      borderRadius: 4,
    },
    filterSectionSummaryExpanded: {
      margin: 0,
      borderBottom: "none",
      borderRadius: "4px 4px 0 0",
    },
    filterSectionSummaryContent: {
      border: "none !important",
      margin: "0 !important",
      minHeight: "0 !important",
      boxShadow: "none",
      display: "flex",
      justifyContent: "space-between",
    },
    filterSectionExpansionPanelRoot: {
      boxShadow: "none",
      zIndex: 1,
      borderRadius: 4,
      border: "1px solid #D8DCDF",
      marginBottom: 10,

      "&:before": {
        display: "none",
      },

      // '&:first-child $filterSectionSummaryRoot': {
      //   borderTop: '1px solid #D8DCDF',
      // },

      //'&:not($filterSectionExpansionPanelExpanded) $filterSectionSummaryRoot': {
      //  borderTop: '1px solid #D8DCDF',
      //},

      //'&:not($filterSectionExpansionPanelExpanded) $filterSectionSummaryRoot ~ &:not($filterSectionExpansionPanelExpanded) $filterSectionSummaryRoot': {
      //  borderTop: 'none',
      //}
    },
    filterSectionSummaryIcon: {
      marginRight: 8,
      boxShadow: "none !important",
      border: "none !important",
      "&:before": {
        content: "none",
      },
    },
    filterSectionExpansionPanelExpanded: {
      margin: "0 !important",
      marginBottom: "15px !important",
    },

    inputsContainer: {
      display: "flex",
      alignItems: "flex-start",

      "& > div": {
        marginLeft: 120,
      },
      "& > div:first-child": {
        marginLeft: 0,
      },
    },
    filterSectionDetailsRoot: {
      borderBottom: "1px solid #D8DCDF",
    },
    filterSubSectionTitleContainer: {
      display: "flex",
      alignItems: "baseline",

      "& > p": {
        marginRight: 15,
      },
    },
    inputTitle: {
      fontFamily: "Roboto-Regular",
      fontSize: 16,
      color: "#202A38",
    },
    controlsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 20px",

      "& > div": {
        display: "flex",
        alignItems: "center",
      },
    },
    stepsContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: 15,
    },

    filtersSubSectionFlex: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      "& > div": {
        marginLeft: 10,
      },
      "& > div:first-child": {
        marginLeft: 0,
      },
    },
    eurInputLabel: {
      fontFamily: "Roboto-Medium",
    },
    stickyActions: {
      position: "sticky",
      zIndex: 1,
      bottom: 0,
      backgroundColor: "inherit",
      borderBottom: "1px solid #D8DCDF",
      borderTop: "1px solid #D8DCDF",
      marginTop: -21,
    },
    applyFiltersButton: {
      background: "white",
      color: theme.palette.primary.main,
      padding: "15px 24px",
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 8,
      height: "50px !important",

      "&:hover": {
        background: "white !important",
      },
    },
    applyFiltersButtonDisabled: {
      background: "white !important",
      color: "#ADB6BD !important",
      border: `2px solid #ADB6BD !important`,
    },
    helpIcon: {
      verticalAlign: "bottom",
      fontSize: "1.25rem",
      marginTop: 3
    },
    tooltip: {
      position: "relative",
      padding: 20,
      backgroundColor: "#EFEFF2",
      color: "#4D4F5C",
      fontFamily: "Roboto-Regular",
      fontSize: 14,
      boxShadow: "0px 2px 15px 0px rgba(0,0,0, 0.2)",
      whiteSpace: "pre-line",
      maxWidth: 500,
    },
  });

export default makeStyles(styles);
