import React from 'react';

import InstrumentListItem from './components/InstrumentListItem';
import DashboardCard from "../DashboardCard";
import { getPortfolioTradings } from '../../../../../../components/TradingStore/utils'
import _ from "lodash";

export default (props) => {
  const {
    data,
    virtualInstrumentLink,
    expandedItems,
    expandedSubItems,
    onExpandedItemsChange,
    onExpandedSubItemsChange,
    isCustomerApp,
    onAddTradingOption,
    onAddPortfolioTradingOption,
    tradings,
    banksMapping,
    tradingSession,
    reportType,
    isVirtual,
    triggerPortfolioDeleting,
    refresh,
    customerData,
    canTradePortfolios,
    customTypeProViewEnabled,
    instrumentsGroupBy,
    unrealizedProfitAndLossData,
    expandedUnrealizedProfitAndLossItems,
    onExpandedUnrealizedProfitAndLossItemsChange,
    withTransactionSaldo,
    portfolioNameRender,
    showSubSectionTitle,
    footerText,
    addToComparisonPossible
  } = props;

  const handleExpandedItemsChange = (ids, expanded, isProfitLossItemUpdated) => {
    // if isProfitLossItemUpdated is passed - call update and extend items of unrealizedProfitAndLoss, else - portfolios
    let onExpandedItemChangeHandler, itemsToExpand;
    if (isProfitLossItemUpdated){
      onExpandedItemChangeHandler = onExpandedUnrealizedProfitAndLossItemsChange
      itemsToExpand = expandedUnrealizedProfitAndLossItems
    } else {
      onExpandedItemChangeHandler = onExpandedItemsChange
      itemsToExpand = expandedItems
    }

    if (expanded) {
      onExpandedItemChangeHandler([...itemsToExpand, ...ids]);
    } else {
      onExpandedItemChangeHandler(itemsToExpand.filter(item => !ids.includes(item)));
    }
  };

  const handleExpandedSubItemsChange = (portfolioId, items) => {
    onExpandedSubItemsChange({...expandedSubItems, [portfolioId]: {...(expandedSubItems[portfolioId] || {}), ...items}});
  };

  React.useEffect(() => {
    if (!_.isEmpty(expandedSubItems)) {
      onExpandedSubItemsChange({});
    }
  }, [reportType])

  const renderPortfolio = (portfolio, expandable, expanded, isLast) => {
    return (
      <>
        <InstrumentListItem
          key={portfolio.id}
          portfolio={portfolio}
          virtualInstrumentLink={virtualInstrumentLink}
          expandable={expandable}
          expanded={expanded}
          onExpanded={(expanded, isProfitLossItemUpdated) => handleExpandedItemsChange([portfolio.id], expanded, isProfitLossItemUpdated)}
          expandedSubItems={_.get(expandedSubItems, `${portfolio.id}`)}
          onExpandedSubItems={handleExpandedSubItemsChange}
          isLast={isLast}
          isCustomerApp={isCustomerApp}
          addToComparisonPossible={addToComparisonPossible}
          isVirtualOrderEnabled={props.isVirtualOrderEnabled}
          onAddTradingOption={onAddTradingOption}
          onAddPortfolioTradingOption={onAddPortfolioTradingOption}
          tradings={getPortfolioTradings(tradings, portfolio.id)}
          banksMapping={banksMapping}
          tradeTransactions={tradingSession}
          reportType={reportType}
          isVirtual={isVirtual}
          triggerPortfolioDeleting={triggerPortfolioDeleting}
          refresh={refresh}
          customerData={customerData}
          instrumentsGroupBy={instrumentsGroupBy}
          canTradePortfolios={canTradePortfolios}
          customTypeProViewEnabled={customTypeProViewEnabled}
          showCell={props.showCell}
          withTransactionSaldo={withTransactionSaldo}
          unrealizedProfitAndLossData={unrealizedProfitAndLossData}
          expandedUnrealizedProfitAndLossItems={expandedUnrealizedProfitAndLossItems}
          resource={props.resource}
          portfolioNameRender={portfolioNameRender}
          showSubSectionTitle={showSubSectionTitle}
          footerText={footerText}
        />
      </>
    )
  };

  const renderActivePortfolios = (portfolios, emptyExist) => {
    return portfolios.map((portfolio, index) => {
      const expandable = true;
      const expanded = expandedItems.includes(portfolio.id)
      const isLast = !emptyExist && index === data.length - 1;
      return renderPortfolio(portfolio, expandable, expanded, isLast);
    });
  };

  const renderEmptyPortfolios = (empty) => {
    const portfolios = empty
      .filter(portfolio => !portfolio.is_historical || (portfolio.clearing_account && portfolio.clearing_account.length));
    const content = portfolios.map((portfolio, index) => {
        const expandable = true;
        const expanded = expandedItems.includes(portfolio.id);
        const isLast = index === data.length - 1;
        return renderPortfolio(portfolio, expandable, expanded, isLast);
      });

    if (content.length) {
      return (
        <DashboardCard
          title={'Inaktive Einzeldepots'}
          content={content}
        />
      )
    }
  };

  const renderData = () => {
    let active = [];
    let empty = [];

    data && data.forEach(portfolio => (portfolio.is_empty ? empty : active).push(portfolio));

    const emptyExist = empty.length > 0;
    return (
      <>
        {renderActivePortfolios(active, emptyExist)}
        {emptyExist && renderEmptyPortfolios(empty)}
      </>
    );
  };

  return (
    <>
      {renderData()}
    </>
  )
}