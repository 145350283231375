import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import clsx from "clsx";

import MarkIcon from "./MarkIcon";
import styles from "./styles";

function TargetMarketWidget({ classes, title, data }) {
  return (
    <div className={classes.card}>
      <Typography variant="h2" classes={{ root: classes.headline }}>{title}</Typography>
      <ul className={classes.list}>
        {data.map((item, index) => (
          <li className={clsx([classes.item, classes.itemMark])} key={index}>
            <Typography classes={{ root: classes.title }}>{item.title}</Typography>
            <span className={classes.values}>
              <MarkIcon value={item.value} />
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default withStyles(styles)(TargetMarketWidget)