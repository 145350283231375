const styles = theme => ({
    cardWrapper: {
        height: 80,
        backgroundColor: "#F5F6F7",
        borderRadius: "3px",
        padding: 20,
        display: "flex",
        alignItems: "center",
        position: "relative"
    },
    tooltipWrapper: {
        position: "absolute",
        top: 8,
        right: 10,
        color : "rgba(0, 0, 0, 0.87)"
    },
    tooltip: {
        position: 'relative',
        padding: 20,
        backgroundColor: '#EFEFF2',
        color: '#4D4F5C',
        fontFamily: 'Roboto-Regular',
        fontSize: 14,
        boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.2)',
        whiteSpace: 'pre-line',
        maxWidth: 500
    },
    text: {
        fontFamily: "Roboto-Bold",
        fontSize: 14,
        color: "#4C4D4D",
        marginLeft: 16
    },
    img: {
        cursor: 'pointer',
        width: 66,
        opacity: 1,
        transition: 'transform .2s', /* Animation */

        '&.inactive': {
          opacity: 0.15,
        },

        '&:not(.inactive):hover': {
            transform: 'scale(1.7)'
        }
    },
    contentWrapper: {
        display: "flex",
        alignItems: "center",
        width : "95%"
    },
    info: {
        position: 'absolute',
        right: 5,
        '& > i': {
            color: '#4C4D4D',
            fontSize: 16
        }
    }
})

export default styles;