import CommonAnalysisCard from "../../../../components/AnalysisCard";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import {parseResponse, VirtualPortfolioHandlerResource} from "../../../../../../utils/api";
import {toGermanFormat} from "../../../../../../utils/numberFormater";
import {withPercentOrDash} from "../../../../../../utils/utils";
import clsx from "clsx";
import KeyIndicatorSlider from "./KeyIndicatorSlider";
import moment from "moment";
import Grid from "@material-ui/core/Grid/Grid";
import _ from 'lodash';
import ExplanationTooltip from "../../../../../../components/ExplanationTooltip";
import {PORTFOLIO_LEVEL_DATA_ONLY_INFO_TEXT} from "../../../../../../components/WarningTooltip";
import {noBenchmarkValue} from "../../../../../../utils/constants";


export class BaseKeyIndicatorsCard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      riskData: undefined,
      riskError: undefined,
      rfr_value: undefined,
      keyIndicatorsData: undefined,
      keyIndicatorsError: undefined,
      keyIndicatorsDataBM: undefined,
      keyIndicatorsErrorBM: undefined,
      keyIndicatorsLoading: true,
      benchmarkData: undefined,
      benchmarkError: undefined,
      riskLoading: true,
      showBenchmark: true,
    }

    this.handleRiskError = this.handleRiskError.bind(this);
    this.getData = this.getData.bind(this);
  }

  getData(){}

  componentDidMount() {

    this.setState({
      riskLoading: true
    })

    this.getData();

  }

  handleRiskError(error) {
    this.setState({
      riskError: error,
      riskData: undefined,
      riskLoading: false
    })
  }

  formatNumber(number) {
    return number || number === 0 ? toGermanFormat(number, '', '', 2): '-'
  }

  renderErrorMessage(error) {
    const { classes } = this.props;
    return <p className={classes.emptyMessage}>{error}</p>
  }

  renderContent() {
    const { classes } = this.props;

    let historicalValue = Math.abs(this.state.riskData['volatility']['var_hist_hp'])
    let historicalBenchmarkValue;
    let volaBenchmark;
    let benchmarkDrawdown;
    let benchmarkSettings = undefined;
    let benchmarkName;

    if (this.state.showBenchmark && this.state.riskData.hasOwnProperty('benchmarkVolatility') && this.state.riskData['benchmarkVolatility']) {
      historicalBenchmarkValue = Math.abs(this.state.riskData['benchmarkVolatility']['var_hist_hp']);
      volaBenchmark = this.state.riskData['benchmarkVolatility']['volatility_annual'];
      benchmarkDrawdown = this.state.riskData['benchmarkVolatility']['maximum_drawdown'];
      benchmarkSettings = this.state.riskData['benchmarkVolatility']['components'];
      benchmarkName = this.state.riskData['benchmarkName']
    } else {
      historicalBenchmarkValue = null;
      volaBenchmark = null;
      benchmarkDrawdown = null;
      benchmarkName = '';
    }

    let historicalRangeTop;

    if (historicalBenchmarkValue != null) {
      historicalRangeTop = Math.abs(Math.max(historicalValue, historicalBenchmarkValue) + 0.1);
    } else {
      historicalRangeTop = Math.abs(historicalValue + 0.1);
    }

    let vola = this.state.riskData['volatility']['volatility_annual'];


    let volaRangeTop;

    if (volaBenchmark !== null) {
      volaRangeTop = Math.max(vola, volaBenchmark) + 0.2;
    } else {
      volaRangeTop = vola + 0.2;
    }

    let drawdown = this.state.riskData['volatility']['maximum_drawdown'];

    let drawdownRangeBottom;

    if (benchmarkDrawdown != null) {
      drawdownRangeBottom = Math.min(drawdown, benchmarkDrawdown) - 0.1;
    } else {
      drawdownRangeBottom = drawdown - 0.1
    }

    let pf_sharpe_ratio = (this.state.keyIndicatorsData && this.state.keyIndicatorsData['sharpe_ratio_a']) || null;
    let tracking_error = (this.state.keyIndicatorsData && this.state.keyIndicatorsData['tracking_error'] * 100) || null;
    let bm_sharpe_ratio = (this.state.keyIndicatorsDataBM && this.state.keyIndicatorsDataBM['sharpe_ratio_a']) || null;
    let alpha = (this.state.keyIndicatorsData && this.state.keyIndicatorsData['alpha']) || null;
    let beta = (this.state.keyIndicatorsData && this.state.keyIndicatorsData['beta']) || null;
    let information_ratio = (this.state.keyIndicatorsData && this.state.keyIndicatorsData['information_ratio']) || null;
    let recovery_date = _.get(this.state.riskData, 'volatility.recovery_date', undefined);
    const expectedShortfall = this.state.riskData['volatility']['cvar_hist_hp'];

    const portfolioLevelOnlyInfoText = this.props.selectedAssets
      && this.props.selectedAssets.length
      && !this.props.isAllAssetsSelected
      && PORTFOLIO_LEVEL_DATA_ONLY_INFO_TEXT;

    return <Grid container className={classes.rootContainer}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={'auto'} sm={4} md={3} className={classes.flexChild}>
            <h5 className={classes.smallTitle}>
              Expected Shortfall
              <ExplanationTooltip tooltipKey={'expected_shortfall'} additionalText={portfolioLevelOnlyInfoText}/>
            </h5>
            <span className={classes.hugeNumber}>
              {
                expectedShortfall ? this.formatNumber(Math.abs(this.state.riskData['volatility']['cvar_hist_hp']) * 100) + ' %' :
                    <span className={classes.empty}>{noBenchmarkValue}</span>
              }
            </span>
          </Grid>
          <Grid item xs={'auto'} sm={4} md={3} className={classes.flexChild}>
            <h5 className={classes.smallTitle}>
              Sharpe Ratio
              <ExplanationTooltip tooltipKey={'sharpe_ratio'}/>
            </h5>
            <span className={classes.hugeNumber}>
              {
                pf_sharpe_ratio ? this.formatNumber(pf_sharpe_ratio) :
                    <span className={classes.empty}>{noBenchmarkValue}</span>
              }
            </span>
            <br />
            <span>Benchmark {this.formatNumber(bm_sharpe_ratio)}</span>
          </Grid>
          <Grid item xs={'auto'} sm={4} md={3} className={classes.flexChild}>
            <h5 className={classes.smallTitle}>
              Tracking Error
              <ExplanationTooltip tooltipKey={'tracking_error'} additionalText={portfolioLevelOnlyInfoText}/>
            </h5>
            <span className={classes.hugeNumber}>
              {
                tracking_error ? this.formatNumber(tracking_error) + ' %' :
                    <span className={classes.empty}>{noBenchmarkValue}</span>
              }
            </span>
          </Grid>
          <Grid item xs={'auto'} sm={4} md={3} className={classes.flexChild}>
            <h5 className={classes.smallTitle}>
              Information Ratio
              <ExplanationTooltip tooltipKey={'information_ratio'} additionalText={portfolioLevelOnlyInfoText}/>
            </h5>
            <span className={classes.hugeNumber}>
             {
               this.state.showBenchmark && information_ratio ? this.formatNumber(information_ratio) :
                   <span className={classes.empty}>{noBenchmarkValue}</span>
             }
            </span>
          </Grid>
          <Grid item xs={'auto'} sm={4} md={3} className={classes.flexChild}>
            <h5 className={classes.smallTitle}>
              Alpha
              <ExplanationTooltip tooltipKey={'alpha'} additionalText={portfolioLevelOnlyInfoText}/>
            </h5>
            <span className={classes.hugeNumber}>
             {
               this.state.showBenchmark && alpha ? this.formatNumber(alpha) :
                   <span className={classes.empty}>{noBenchmarkValue}</span>
             }
            </span>
          </Grid>
          <Grid item xs={'auto'} sm={4} md={3} className={classes.flexChild}>
            <h5 className={classes.smallTitle}>
              Beta
              <ExplanationTooltip tooltipKey={'beta'} additionalText={portfolioLevelOnlyInfoText}/>
            </h5>
            <span className={classes.hugeNumber}>
             {
               this.state.showBenchmark && beta ? this.formatNumber(beta) :
                   <span className={classes.empty}>{noBenchmarkValue}</span>
             }
            </span>
          </Grid>
          <Grid item xs={'auto'} sm={4} md={3} className={classes.flexChild}>
            <h5 className={classes.smallTitle}>
              Rendite
              <ExplanationTooltip additionalText={portfolioLevelOnlyInfoText} tooltipKey={'return'}/>
            </h5>
            <span className={classes.hugeNumber}>
            {withPercentOrDash(this.props.investmentData && this.props.investmentData.ydt.percentage, true)}
            </span>
          </Grid>
          <Grid item xs={'auto'} sm={4} md={3} className={classes.flexChild}>
            <h5 className={classes.smallTitle}>
              Rendite p.a.
              <ExplanationTooltip additionalText={portfolioLevelOnlyInfoText} tooltipKey={'return_pa'}/>
            </h5>
            <span className={classes.hugeNumber}>
            {withPercentOrDash(this.props.investmentData && this.props.investmentData.ydt.percentage_pa, true)}
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
       <div className={classes.dataWrapper}>
        <div className={classes.slidersWrapper}>
          <div className={classes.singleSliderWrapper}>
            <span className={clsx(classes.smallTitle, classes.sliderTitle)}>
              Historischer Value at Risk (VaR)
              <ExplanationTooltip tooltipKey={'value_at_risk'} additionalText={portfolioLevelOnlyInfoText}/>
            </span>
            <KeyIndicatorSlider
              minValue={0}
              maxValue={Math.round(historicalRangeTop * 100)}
              minSelected={0}
              maxSelected={Math.abs(historicalValue) * 100}
              markedValue={historicalBenchmarkValue ? Math.abs(historicalBenchmarkValue) * 100 : undefined}
              unit={'%'}
              benchmarkSettings={benchmarkSettings}
            />
          </div>
          <div className={classes.singleSliderWrapper}>
          <span className={clsx(classes.smallTitle, classes.sliderTitle)}>
              Volatilität
              <ExplanationTooltip tooltipKey={'volatility'} additionalText={portfolioLevelOnlyInfoText}/>
            </span>
            <KeyIndicatorSlider
              minValue={0}
              maxValue={Math.round(volaRangeTop * 100)}
              minSelected={0}
              maxSelected={vola * 100}
              markedValue={volaBenchmark ? volaBenchmark * 100 : undefined}
              unit={'%'}
              benchmarkSettings={benchmarkSettings}
            />
          </div>
          <div className={classes.singleSliderWrapper}>
            <span className={clsx(classes.smallTitle, classes.sliderTitle)}>
              Maximaler Wertverlust
              <ExplanationTooltip tooltipKey={'maximum_drawdown'} additionalText={portfolioLevelOnlyInfoText}/>
            </span>
            <KeyIndicatorSlider
              minValue={Math.round(drawdownRangeBottom * 100)}
              maxValue={0}
              minSelected={drawdown * 100}
              maxSelected={0}
              unit={'%'}
              markedValue={benchmarkDrawdown ? benchmarkDrawdown * 100 : undefined}
              benchmarkSettings={benchmarkSettings}
            />
          </div>
          <div className={classes.recoveryDays}>
            {
              this.state.riskData['volatility']['recovery_days'] ?
                `Zeitraum der Wertaufholung: ${this.state.riskData['volatility']['recovery_days']} Tage`:
                'Die Wertaufholung wurde noch nicht erreicht'
            }
          </div>
          {this.state.riskData['volatility'].hasOwnProperty('maximum_drawdown_date') &&
            <div className={classes.recoveryDays}>
              {
                `Datum des maximalen Wertverlust ${moment(this.state.riskData['volatility']['maximum_drawdown_date']).format('DD.MM.YYYY')}`
              }
            </div>
          }
          {recovery_date &&
            <div className={classes.recoveryDays}>
                {
                  `Erholungsdatum ${moment(recovery_date).format('DD.MM.YYYY')}`
                }
            </div>
          }

          <div className={classes.recoveryDays}>
            <span className={classes.portfolio}>Portfolio</span>
            <span className={classes.benchmark}>
              {benchmarkName}
              </span>
          </div>
        </div>
      </div>
      </Grid>
    </Grid>
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
        classes={{
          card: classes.root
        }}
        title={'Kennzahlen'}
        loading={this.state.riskLoading}
        content={
          <div className={classes.container}>
            {
              this.state.riskError ? this.renderErrorMessage(this.state.riskError) : this.state.riskData ? this.renderContent() : this.renderErrorMessage('Keine Daten verfügbar')
            }
          </div>
        }
        expanded={this.props.expanded}
        onExpandedClick={this.props.onExpandedClick}
    />
  }
}


class KeyIndicatorsCard extends BaseKeyIndicatorsCard {
  getData() {
    VirtualPortfolioHandlerResource.getRiskData(this.props.customerId, this.props.portfolioId, undefined, undefined)
      .then(response => {

        parseResponse(response, 'risk',
          data => {
            if (data.hasOwnProperty('volatility') && data['volatility'].hasOwnProperty('min_date_message')) {
              this.handleRiskError('Kennzahlen können erst 30 Tage nach der ersten Transaktion berechnet werden.')
            } else {
              this.setState({
                riskError: undefined,
                riskLoading: false,
                riskData: {
                  volatility: data['volatility'][this.props.portfolioId],
                  benchmarkVolatility: data['benchmark_volatility']['risk_metrics'],
                  benchmarkName: data['benchmark_volatility']['benchmark_name']
                }
              }, () => {
                console.log(this.state)
              })
            }
          },
          error => {
            this.handleRiskError(error);
          }
        )
      })
      .catch(error => {
        this.handleRiskError(error)
      })

    VirtualPortfolioHandlerResource.getKeyIndicatorsData(this.props.customerId, this.props.portfolioId)
      .then(response => {

        parseResponse(response, 'indicators_sharpe_ratio',
          data => {
            this.setState({
              keyIndicatorsData: data['indicators'],
              keyIndicatorsError: undefined,
              keyIndicatorsLoading: false,
            })
          },
          error => {
            this.setState({
              keyIndicatorsData: undefined,
              keyIndicatorsError: error,
              keyIndicatorsLoading: false,
            })
          }
        )

        parseResponse(response, 'indicators_sharpe_ratio_bm',
          data => {
            this.setState({
              keyIndicatorsDataBM: data['indicators'],
              keyIndicatorsErrorBM: undefined,
              keyIndicatorsLoading: false,
            })
          },
          error => {
            this.setState({
              keyIndicatorsDataBM: undefined,
              keyIndicatorsErrorBM: error,
              keyIndicatorsLoading: false,
            })
          }
        )

        this.setState({
          rfr_value: response['risk_free_rate_value'],
        })

      })
      .catch(error => {
        this.setState({
          keyIndicatorsData: undefined,
          keyIndicatorsError: error,
          keyIndicatorsLoading: false,
        })
      })
  }
}


export default withStyles(styles)(KeyIndicatorsCard)
