
const overview_1 = {
  selector: '#documents-list-tour-element',
  key: 'documents_list'
};
const overview_2 = {
  selector: '#documents-filter-type-tour-element',
  key: 'documents_filter'
};
const overview_3 = {
  selector: '#documents-type-tour-element',
  key: 'documents_type'
};
const overview_4 = {
  selector: '#download-documents-tour-element',
  key: 'documents_download'
};

export const getOverviewChapterSteps = () => {
  return [
    overview_1,
    overview_2,
    overview_3,
    overview_4
  ];
};