import {RISK_FILTERING_TYPE} from "../../components/FilteringPanel/components/RiskFilter/constants";
import {
  EDIT_ALERT_ACTIONS,
  PORTFOLIO_RISK_METRICS_MAX_TIME_FRAME_ID,
  RISK_INDICATORS_DATA_RETRIEVING_TIME_FRAMES
} from "./constants";
import _ from "lodash";
import {SORTING_TYPES} from "../../components/FilteringPanel/components/SortingFilter/constants";
import {
  PERMANENT_SUITABILITY_FILTERS,
  SRI_FILTERS
} from "../../components/FilteringPanel/components/ListSelector/constants";
import {toGermanFormat} from "../../utils/numberFormater";

export const HIGH_RISK_VALUE = 0.49;

export function getRiskType(customerRisk, productRisk) {
  if (!customerRisk || productRisk - customerRisk > HIGH_RISK_VALUE) {
    return RISK_FILTERING_TYPE.RED
  }

  if (productRisk - customerRisk > 0) {
    return RISK_FILTERING_TYPE.YELLOW
  }

  return RISK_FILTERING_TYPE.GREEN
}

export function getRiskColor(customerRisk, productRisk) {

  if (!customerRisk || !productRisk) {
    return "#B1B1B1"
  }

  return getRiskType(customerRisk, productRisk).color;
}

export function getEsgColor(esgProfileMatching) {
  if(!esgProfileMatching){ return 'white' }

  return Object.values(esgProfileMatching).some(category => category.missing > 0)
    ? RISK_FILTERING_TYPE.RED.color   // if at least one category is missing - return red
    : RISK_FILTERING_TYPE.GREEN.color

}

export function getRiskTooltipText(customerRisk, productRisk) {

  if (!customerRisk || !productRisk) {
    return ""
  }

  if (!customerRisk || productRisk - customerRisk > HIGH_RISK_VALUE) {
    return "Das maximale durchschnittliche Portfoliorisiko (bei mehreren Portfolios des Kunden, dasjenige mit dem höchsten Risikowert) ist wesentlich höher als die für den Kunden ermittelte Risikoklasse. Hier sollte schnellstmöglich eine Anpassung des Portfolios/ Depots vorgenommen werden."
  }

  if (productRisk - customerRisk > 0) {
    return "Das durchschnittliche Produktrisiko ist geringfügig höher als die für den Kunden ermittelte Risikoklasse. Hier sollte ggf. bald eine Anpassung des Portfolios/ Depots vorgenommen werden."
  }

  return "Das durchschnittliche Produktrisiko ist gleich bzw. geringer als die für den Kunden ermittelte Risikoklasse. Hier müssen keine Anpassungen vorgenommen werden."
}

/**
 * Prepare state body for risk metrics data retrieving.
 * @returns {{}}
 */
export const buildRiskMetricsRequestState = () => {

  const result = {}
  const dataRetrievingStructure = {
    data: undefined,
    loading: true,
    errors: undefined
  }

  RISK_INDICATORS_DATA_RETRIEVING_TIME_FRAMES.forEach((timeFrame) => {
    result[timeFrame.id] = {...dataRetrievingStructure}
  })
  result[PORTFOLIO_RISK_METRICS_MAX_TIME_FRAME_ID] = {...dataRetrievingStructure}

  return result
}

/**
 * Update aggregated portfolios risk metrics data retrieving state with loading status.
 * @param data
 * @param {{endDate, id: string, startDate}} timeFrame
 */
export const startRiskMetricsTimeFrameRequest = (data, timeFrame) => {

  _.set(data, `${timeFrame.id}.loading`, true)
  _.set(data, `${timeFrame.id}.data`, undefined)
  _.set(data, `${timeFrame.id}.errors`, undefined)

  return data

}

/**
 * Update aggregated portfolios risk metrics data retrieving state with request results for specific time frame.
 * @param {{}} data Current state
 * @param {{endDate, id: string, startDate}} timeFrame
 * @param {{}?} result Request result
 * @param {*?} errors Result error
 */
export const finishRiskMetricsTimeFrameRequest = (data, timeFrame, result=undefined, errors=undefined) => {

  _.set(data, `${timeFrame.id}.loading`, false)
  _.set(data, `${timeFrame.id}.data`, result)
  _.set(data, `${timeFrame.id}.errors`, errors)

  return data

}

/**
 * Works same as finishRiskMetricsTimeFrameRequest, but trigger for all time frames at the same time.
 * Used in case request should be finished completely.
 * @param data
 */
export const finishRiskMetricsRequest = (data) => {

  [...RISK_INDICATORS_DATA_RETRIEVING_TIME_FRAMES, {id: PORTFOLIO_RISK_METRICS_MAX_TIME_FRAME_ID}]
    .forEach((timeFrame) => finishRiskMetricsTimeFrameRequest(data, timeFrame))

  return data

}

/**
 * Update individual portfolio risk metrics data retrieving state with request results for specific time frame.
 * @param {{}} data Current state
 * @param {{endDate, id: string, startDate}} timeFrame
 * @param {{}?} result Request result
 * @param {*?} errors Result error
 * @param {{depotNumber: number}} portfolio
 */
export const finishIndividualPortfolioRiskMetricsTimeFrameRequest = (data, portfolio, timeFrame, result, errors) => {

  _.set(data, `${timeFrame.id}.errors.portfolio_${portfolio.depotNumber}`, errors)
  _.set(data, `${timeFrame.id}.data.portfolio_${portfolio.depotNumber}`, result)
  _.set(data, `${timeFrame.id}.loading.portfolio_${portfolio.depotNumber}`, false)

  return data

}

/**
 * Update individual portfolio risk metrics data retrieving state with loading status.
 * @param data
 * @param portfolio
 * @param timeFrame
 */
export const startIndividualPortfolioRiskMetricsTimeFrameRequest = (data, portfolio, timeFrame) => {

  _.set(data, `${timeFrame.id}.errors.portfolio_${portfolio.depotNumber}`, undefined)
  _.set(data, `${timeFrame.id}.data.portfolio_${portfolio.depotNumber}`, undefined)
  _.set(data, `${timeFrame.id}.loading.portfolio_${portfolio.depotNumber}`, true)

  return data

}

/**
 * Check, if aggregated portfolios risk metrics request is in progress for at least one time frame.
 * @param state
 * @returns {boolean}
 */
export const isAggregatedPortfolioRiskMetricsLoading = (state) => {
  return _.some(Object.keys(state).map((timeFrame) => state[timeFrame].loading))
}

/**
 * Check, if individual portfolio risk metrics request is in progress for at least one time frame for any portfolio.
 * @param state
 * @returns {boolean}
 */
export const isIndividualPortfolioRiskMetricsLoading = (state) => {
  return _.some(_.flatten(Object.keys(state).map(
    (timeFrame) => Object.keys(state[timeFrame].loading || {})
      .map((portfolioIdentifier) => _.get(state[timeFrame].loading, portfolioIdentifier)))))
}

/**
 * Check, if customer's risk indicator value equal to target.
 * @param {{srri?: number, product_srri?: number}} customer Customer data
 * @param {{value: number, description: string, color: string}} target Risk indicator
 * @private
 */
const _isCustomerRiskIndicatorValid = (customer, target) => {

  if (!target || target.value === RISK_FILTERING_TYPE.ALL.value) {
    return true
  }

  if (!_.get(customer, 'last_srri.srri') || !customer.product_srri) {
    return false
  }

  return getRiskColor(_.get(customer, 'last_srri.srri'), customer.product_srri) === target.color

}

/**
 * Check, if customer's aggregated value is in provided range.
 * @param {{aggregated_value: string}} customer Customer data
 * @param {[number, number]?} values Aggregated value range
 * @private
 */
const _isCustomerAggregatedValueValid = (customer, values) => {

  if (!_.isArray(values) || !values.length) {
    return true
  }

  const customerAggregatedValue = parseFloat(customer.aggregated_value)

  return values[0] < customerAggregatedValue && customerAggregatedValue < values[1]
}

/**
 * Check, if customer full name match with search value.
 * @param {{customer_first_name: string, customer_last_name: string}} customer Customer data
 * @param {string} search Value to compare with
 */
const _isCustomerFullNameValid = (customer, search) => {

  if (_.isEmpty(search)) {
    return true
  }

  search = search.toLowerCase()
  const firstName = (customer.customer_first_name || '').toLowerCase()
  const lastName = (customer.customer_last_name || '').toLowerCase()

  return firstName.includes(search)
    || lastName.includes(search)
    || `${firstName} ${lastName}`.includes(search)
    || `${lastName} ${firstName}`.includes(search)

}


/**
 * Check, if customer full name match with search value.
 * @param {{customer_full_name: string, customer_last_name: string}} customer Customer data
 * @param {string} search Value to compare with
 */
 const _isLetterFirstInCustomerName = (customer, search) => {

  if (_.isEmpty(search)) {
    return true
  }

  search = search.toLowerCase()
  const lastName = (customer.customer_last_name.charAt(0) || '').toLowerCase()
  const fullName = (customer.customer_full_name.charAt(0) || '').toLowerCase()

  return lastName === search || fullName === search
}

/**
 * Check, if customer id match with search value.
 * @param {{customer_id: string}} customer Customer data
 * @param {string} search Value to compare with
 */
const _isCustomerIdValid = (customer, search) => {

  if (_.isEmpty(search)) {
    return true
  }

  search = search.toLowerCase()
  const customerId = (customer.customer_id || '').toLowerCase()

  return customerId.includes(search)

}

/**
 * Check, if customer permanent suitability check is validfor selected value.
 * @param {{permanent_suitability_check_enabled: boolean}} customer Customer data
 * @param {{value: number, description: string}} value
 * @param {boolean} riskProfileExists Flag that shows if customer has risk profile
 */
const isCustomerPermanentSuitabilityCheckValid = (customer, value, riskProfileExists) => {
  if (!value || value.value === PERMANENT_SUITABILITY_FILTERS.ALL.value){
    return true
  }

  return value.value === PERMANENT_SUITABILITY_FILTERS.ENABLED.value
    ? customer.permanent_suitability_check_enabled && riskProfileExists
    : !customer.permanent_suitability_check_enabled || !riskProfileExists
}

/**
 * Check, if customer sri check is valid for selected value.
 * @param {{value: number, description: string}} selectedOption
 * @param {boolean} riskProfileExists Flag that shows if customer has risk profile
 */
const isCustomerSrriCheckValid = (selectedOption, riskProfileExists) => {
  if(!selectedOption || selectedOption.value === SRI_FILTERS.ALL.value) return true

  return selectedOption.value === SRI_FILTERS.ENABLED.value ? riskProfileExists : !riskProfileExists
}

/**
 * Validate, if customer is valid for provided filters.
 * @param {{}} customer Customer data
 * @param {{}} filters Filters
 */
const isCustomerAcceptedByFilters = (customer, filters) => {

  // if customer has last_srri.srri -> it has existing risk profile
  let riskProfileExists = !_.isNil(_.get(customer, 'last_srri.srri'))

  return _isCustomerRiskIndicatorValid(customer, filters.riskType)
    && _isCustomerAggregatedValueValid(customer, filters.aggregatted)
    && _isLetterFirstInCustomerName(customer, filters.first_letter)
    && (_isCustomerFullNameValid(customer, filters.search)
    || _isCustomerIdValid(customer, filters.search))
    && isCustomerPermanentSuitabilityCheckValid(customer, filters.permanentSuitabilityType, riskProfileExists)
    && isCustomerSrriCheckValid(filters.sriType, riskProfileExists)

}

/**
 * Filter customers according to the provided filters criteria.
 *
 * @param {{}[]} customers List of customers to filter
 * @param {{}} filters Filters
 */
export const filterCustomers = (customers, filters) => {

  const result = []

  customers.forEach((customer) => {

    if (isCustomerAcceptedByFilters(customer, filters)) {
      result.push(customer)
    }

  })

  return result

}

const _orderByCustomerAggregatedValue = (type) => {
  return [[(customer) => customer.aggregated_value && parseFloat(customer.aggregated_value) || 0], [type]]
}

const _orderByCustomerLastName = (type) => {
  return [[(customer) => (customer.customer_last_name || customer.customer_first_name).toLowerCase()], [type]]
}

const _orderByCustomerRiskIndicator = (type) => {
  return [[(customer) => (customer.product_srri || 0) - _.get(customer, 'last_srri.srri') || 0], [type]]
}

/**
 * Sort customers by selected sorting type.
 * @param {{}[]} customers Customers data
 * @param {{value: number}} sortType Sorting type
 */
export const sortCustomers = (customers, sortType) => {

  const [iteratees, orders] = {
    [SORTING_TYPES.DEPOT_VOLUME_DESC.value]: _orderByCustomerAggregatedValue('desc'),
    [SORTING_TYPES.DEPOT_VOLUME_ASC.value]: _orderByCustomerAggregatedValue('asc'),
    [SORTING_TYPES.NAME_ASC.value]: _orderByCustomerLastName('asc'),
    [SORTING_TYPES.NAME_DESC.value]: _orderByCustomerLastName('desc'),
    [SORTING_TYPES.RISK_TYPE_ASC.value]: _orderByCustomerRiskIndicator('asc'),
    [SORTING_TYPES.RISK_TYPE_DESC.value]: _orderByCustomerRiskIndicator('desc'),
  }[sortType.value]

  return _.orderBy(customers, iteratees, orders)

}


const GREATER_THEN_FIELD_NAMES = ['minEuro', 'minPercent']
const EURO_FIELD_NAMES = ['minEuro', 'maxEuro']


export const getAlertingEventMessage = (event, configuration) => {

  const configurationValue = _.get(configuration, `${event.configuration_field_name}`)

  const suffix = EURO_FIELD_NAMES.includes(event.configuration_field_name) ? ' €' : ' %'

  return `${toGermanFormat(event.value, '', suffix)} ${GREATER_THEN_FIELD_NAMES.includes(event.configuration_field_name) ? 'grösser' : 'kleiner'} als ${toGermanFormat(configurationValue, '', suffix)}`
}

export const handleConfiguration = (element, configuration, action) => {

  if (_.isEmpty(element.alerting_configuration)) {
    element.alerting_configuration = []
  }

  switch (action) {
    case EDIT_ALERT_ACTIONS.CREATE:
      element.alerting_configuration.push(configuration)
      break
    case EDIT_ALERT_ACTIONS.CANCEL:
    case EDIT_ALERT_ACTIONS.ACTIVATE:
      element.alerting_configuration.forEach((conf) => {
        if (conf.id !== configuration.id) {
          return
        }
        conf.active = action === EDIT_ALERT_ACTIONS.ACTIVATE
      })
      break
    case EDIT_ALERT_ACTIONS.DELETE:
      element.alerting_configuration = element.alerting_configuration.filter((conf) => {
        return conf.id !== configuration.id
      })
      break
  }
}

export const syncAssetsAlerts = (portfolio, asset, configuration, action) => {
  portfolio.components.forEach((component) => {
    if (component.id !== asset.id) return;
    handleConfiguration(component, configuration, action)
  })
}

export const syncPortfolioAlerts = (portfolio, configuration, action) => {

  handleConfiguration(portfolio, configuration, action)

}

