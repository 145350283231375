import React from 'react';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';

import { ProductStructureTableStyles } from '../../styles';


const TableHeaderCard = ({isHighlighted, name, color}) => {
  const classes = ProductStructureTableStyles();

  return (
    <Grid item xs={2} className={clsx('td', isHighlighted ? 'highlightedCell' : '')} style={color && {borderTop: `5px solid ${color}`}}>
      <div className={classes.headerCellContentWrapper}>
        {isHighlighted && (<i className={clsx("fa fa-star fa-lg", classes.starIcon)}/>)}
        <div className={classes.headerCellContent} title={name}>{name}</div>
      </div>
    </Grid>
  )
};

export default TableHeaderCard;