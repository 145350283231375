import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/Input';

import Grid from '@material-ui/core/Grid';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ZoomInSharpIcon from '@material-ui/icons/ZoomInSharp';
import ZoomOutSharpIcon from '@material-ui/icons/ZoomOutSharp';

import useStyles from './styles';

function DocumentNavigation(props) {

  const classes = useStyles();

  const {
    activePage,
    contentPageCount,
    prevPage,
    nextPage,
    switchToPage,
    scale,
    scalePlus,
    scaleMinus,
    pageRestoring
  } = props;

  const [isMinScale, setIsMinScale] = useState(false)
  const [isMaxScale, setIsMaxScale] = useState(false)
  const [isNextPage, setIsNextPage] = useState(undefined)
  const [isPrevPage, setIsPrevPage] = useState(undefined)
  const [page, setPage] = useState(activePage)
  const [pageQuery, setPageQuery] = useState(false)

  useEffect(() => {
    setPage(activePage)
  }, [activePage])

  useEffect(() => {
    calculateScaling()
    calculatePage()
  }, [scale, activePage, contentPageCount])

  useEffect(() => {
    if (pageRestoring) {
      setIsMinScale(true)
      setIsMaxScale(true)
      setIsNextPage(false)
      setIsPrevPage(false)
    } else {
      calculateScaling()
      calculatePage()
    }
  }, [pageRestoring])

  useEffect(() => {
    if (pageQuery) {
      const timeOutId = setTimeout(() => {
        switchToPage(page)
        setPageQuery(false)
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [page]);

  const calculateScaling = () => {
    if (scale) {
      setIsMinScale(scale <= 0.2 ? true : false)
      setIsMaxScale(scale >= 2 ? true : false)
    }
  }

  const calculatePage = () => {
    if (contentPageCount && activePage) {
      setIsNextPage(contentPageCount > activePage)
      setIsPrevPage(activePage > 1)
    }
  }

  const handleSwitchPage = (e) => {
    setPage(e.target.value)
    setPageQuery(true)
  }

  return (
    <Grid container spacing={2} className={classes.navigationContainer} alignItems="flex-end">
        <Grid item xs={6} className={classes.NavButtons}>
            <ExpandMoreIcon className={classes.expandIcon}
              style={{
                pointerEvents: !isNextPage ? "none" : "auto",
                opacity: !isNextPage ? 0.5 : 1}}
              onClick={nextPage}/>
            <ExpandLessIcon className={classes.expandIcon}
              style={{
                pointerEvents: !isPrevPage ? "none" : "auto",
                opacity: !isPrevPage ? 0.5 : 1}}
              onClick={prevPage}/>
          <div className={classes.expandInfo}>
              <Input
                onChange={handleSwitchPage}
                value={page ? page : ''}
                className={classes.expandActivePage}
                fullWidth={false}
                disabled={(!activePage || pageRestoring) ? true : false}
              />
              <span className={classes.expandPages}> / {contentPageCount}</span>
          </div>
            <ZoomOutSharpIcon
              className={classes.zoomIcon}
              style={{
                pointerEvents: (isMinScale || !activePage) ? "none" : "auto",
                opacity: (isMinScale || !activePage) ? 0.5 : 1}}
              onClick={scaleMinus}
            />
            <ZoomInSharpIcon
              className={classes.zoomIcon}
              style={{
                pointerEvents: (isMaxScale || !activePage) ? "none" : "auto",
                opacity: (isMaxScale || !activePage) ? 0.5 : 1}}
              onClick={scalePlus}
            />
        </Grid>
    </Grid>
  );

};

DocumentNavigation.propTypes = {
  activePage: PropTypes.number,
  contentPageCount: PropTypes.number,
  scale: PropTypes.number,
};

export default DocumentNavigation;

