import React from 'react';

import useStyles from './styles';


const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.notFoundContainer}>
      <h1 className={classes.notFoundStatusCode}>404</h1>
      <p className={classes.notFoundHelperText}>Seite nicht gefunden</p>
    </div>
  );
};


export default NotFound;