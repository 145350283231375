import React from "react";
import clsx from 'clsx'

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import styles from "../styles";

import CheckboxQuestion from "../../../question/CheckboxQuestion";
import RadioQuestion from '../../../question/RadioQuestion';
import { radioYesUID, radioNoUID } from '../../../../../../constants'
import RichTextEditor from "../../../formElement/RichTextEditor";

const SellsStep = props => {
  const {
    classes,
    onAnswerChange,
    questions: [
      mainInformationProvidedCheckbox,
      yearlyReportInformationProvidedCheckbox,
      conflicts,
      conflictsDetails,
    ],
  } = props;

  const handleAnswerChange = (question, answer) => {
    onAnswerChange(question, answer);
  };

  const handleConflictsCheckboxChange = (question, answer) => {
    if (answer == radioNoUID && conflictsDetails.error) {
      conflictsDetails.error = null
    }
    handleAnswerChange(question, answer)
  }

  const handleConflictsDetailsChange = (answer) => {
    conflictsDetails.error = null
    handleAnswerChange(conflictsDetails.uid, answer)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.checkboRegularContainer}>
          <CheckboxQuestion
            question={mainInformationProvidedCheckbox}
            onAnswerChange={handleAnswerChange}
          />
          <CheckboxQuestion
            question={yearlyReportInformationProvidedCheckbox}
            onAnswerChange={handleAnswerChange}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioQuestion
            question={conflicts}
            onAnswerChange={handleConflictsCheckboxChange}
          />
        </Grid>
        <Grid item xs={12}>
          <RichTextEditor
            uid={conflictsDetails.uid}
            label={conflictsDetails.question_text}
            value={conflictsDetails.answer || ''}
            onChange={handleConflictsDetailsChange}
            rows={6}
            customClasses={{
              labelRoot: clsx(classes.inputLabelRoot, 'bold')
            }}  
            placeholder={conflictsDetails.config && conflictsDetails.config.placeholder}
            error={conflictsDetails.error}
          />
        </Grid> 
      </Grid>
    </>
  );
};

export default withStyles(styles)(SellsStep);
