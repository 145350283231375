import React, { useImperativeHandle, forwardRef } from 'react';
import clsx from 'clsx'
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid'
import {Skeleton} from "@material-ui/lab";
import useCommonStyles, { FORM_FIELD_HEIGHT } from '../common_styles';
import { useFiltersContext } from "../../Filters";
import Select from '../../../../../../components/Inputs/Select';


/**
 * SimpleDropdown components for selecting values from list of possible options.
 */
const SimpleDropdown = forwardRef(({ name, label, options, GridProps, loading, disabled, emptyErrorMessage, withDefault, onChangeCallback, postNameContent }, ref) => {

  const commonClasses = useCommonStyles()
  const { filters, setFilterValue } = useFiltersContext();

  const selectOptions = React.useMemo(() => {

    if (_.isArray(options)) {
      return options;
    }

    return Object.entries(options || {}).map(o => ({
      ...o[1]
    }));

  }, [options]);

  const [validationError, setValidationError] = React.useState(undefined);

  useImperativeHandle(ref, () => ({
    isValid: () => {
      return !!filters[name]
    }
  }))

  const handleChange = (item) => {
    const selectedItem = _.find(selectOptions, o => o.id === item);
    setFilterValue(name, selectedItem);
    setValidationError(undefined);
    onChangeCallback && onChangeCallback(setFilterValue, selectedItem);
  }

  return (
    <Grid item {...GridProps}>
      <FormControl variant="outlined" fullWidth className={clsx(validationError ? commonClasses.formControlError : commonClasses.formControl)}>
        <p className={clsx(commonClasses.inputLabel, disabled && commonClasses.inputLabelDisabled)}>{ label }</p>
        { loading ? (
          <Skeleton className={commonClasses.skeleton}/>
        ) : (
          <Select
            options={selectOptions}
            disabled={disabled}
            value={filters[name].value}
            withDefault={withDefault}
            onChange={handleChange}
            selectStyles={{ height: FORM_FIELD_HEIGHT }}
            postNameContent={postNameContent}
          />
        )}
        {validationError && (
          <p className={commonClasses.inputLabelError}>{ validationError }</p>
        )}
      </FormControl>
    </Grid>
  );
})

SimpleDropdown.defaultProps = {
  withDefault: false,
};

export default SimpleDropdown;