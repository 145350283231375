import React from 'react'
import PropTypes from 'prop-types'

import {
  ExpansionPanel as MaterialUIExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core';

import useStyles from './styles'

function ExpansionPanel(props) {

  const {
    expansionPanelTitle,
    expansionPanelIcon,
    expansionPanelIconBack,
    expansionPanelContent,
    level
  } = props;

  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandedChange = (event, expanded) => {
    setExpanded(expanded);
  }

  const renderExpansionPanelIcon = () => {
    if (expansionPanelIconBack && expanded) {
    return expansionPanelIconBack;
    }

    return expansionPanelIcon 
  }

  return (
    <MaterialUIExpansionPanel 
      expanded={expanded} 
      onChange={handleExpandedChange}
      classes={{
        root: classes.expansionPanelRoot,
        expanded: classes.expansionPanelExpanded
      }}
    >
      <ExpansionPanelSummary
        classes={{
          root: classes.expansionPanelSummaryRoot,
          content: classes.expansionPanelSummaryContent,
          expanded: classes.expansionPanelSummaryExpanded
        }}
        style={{
          paddingLeft: level * 30
        }}
      >
        <div className={classes.expansionSummaryContainer}>
          {renderExpansionPanelIcon()}
          <span style={{marginLeft: expansionPanelIcon ? 8 : 0}}> {expansionPanelTitle} </span>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        classes={{
          root: classes.expansionPanelDetailsRoot
        }}
      >
        {expansionPanelContent}
      </ExpansionPanelDetails>
    </MaterialUIExpansionPanel>
  )
}

ExpansionPanel.propTypes = {
  /** Title of expansion panel */
  expansionPanelTitle: PropTypes.string.isRequired,

  /** Expansion panel content */
  expansionPanelContent: PropTypes.element,

  /** Icon for expansion panel */
  expansionPanelIcon: PropTypes.element,

  /** Back icon for expansion panel */
  expansionPanelIconBack: PropTypes.element,

  /** Expansion panel level (level * 40)*/
  level: PropTypes.number
}

ExpansionPanel.defaultProps = {
  level: 0
}

export default ExpansionPanel

