import React from "react";

import { withStyles } from "@material-ui/core";

import RatingGlobes from '../../../../../../components/RatingGlobes';
import styles from "./styles";
import WarningTooltip from "../../../../../../components/WarningTooltip";

function MsRating({ value, tooltip, classes }) {
  return (
    <div className={classes.wrapper} style={{alignItems: 'center'}}>
      <span className={classes.labelText} style={{whiteSpace: 'nowrap'}}>
        Morningstar Nachhaltigkeits-Rating

        {tooltip && (
          <>&nbsp;
            <WarningTooltip
              placement={'right'}
              title={tooltip}
              classes={{tooltip: classes.tooltip}}
              iconClassName={classes.helpIcon}
            />
          </>
        )}

      </span>
      <RatingGlobes value={value} wide />
    </div>
  );
}

export default withStyles(styles)(MsRating);