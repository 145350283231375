const styles = theme => ({
  root: {
    minHeight: 150,
    width: '100%'
  },
  container: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#202A38'
  },
})

export default styles;