import React from 'react';
import useStyles from './styles';
import {TabContext, TabList} from "@material-ui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  Tab
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from 'lodash';
import clsx from "clsx";

const TabsFilter = props => {
  const {
    selectedTab,
    tabs,
    onSelectedTabChange,
    loading,
    renderFilters,
    disabled,
    preloaderHeight,
  } = props;

  const classes = useStyles({preloaderHeight: preloaderHeight});
  const customClasses = props.customClasses || {};

  const [filterPanelIsOpened, setFilterPanelIsOpened] = React.useState(true);
  const handleFiltersToggle = (event, expanded) => {
    /** Updates filterPanelIsOpened to reveal or hide content */
    setFilterPanelIsOpened(!filterPanelIsOpened)
  };

  const renderTabLabel = (tab) => {
    return (
      <>
        <div style={{display: 'flex'}}>
          {tab}
        </div>
      </>
    )
  };

  const renderTabs = () => {
    return tabs.map(tab => {
      let tabId, tabLabel;

      if(_.isObject(tab)){
        tabId = _.get(tab, 'id');
        tabLabel = _.get(tab, 'label');
      } else {
        tabId = tab;
        tabLabel = tab;
      }

      return(
        <Tab
          disabled={disabled}
          key={tabId}
          id={`simple-tab-${tabId}`}
          aria-controls={`simple-tabpanel-${tabId}`}
          classes={{ root: clsx(classes.tab, customClasses.tabRoot), selected: classes.tabSelected }}
          label={renderTabLabel(tabLabel)}
          value={tabId}
        />
      )}
    )
  };

  return (
    <Container className={`app-page-container ${classes.container}`}>
      {loading ? (
        <div className={classes.preloaderContainer}>
          <CircularProgress size={66} className={classes.loadingIndicator} />
        </div>
      ) : (
        <TabContext value={selectedTab}>
          <Grid container style={{ marginBottom: '24px' }}>
            <Grid item xs={12}>
              <Paper square={true} classes={{ root: classes.paperRoot }}>

                <Accordion
                  expanded={filterPanelIsOpened}
                  onChange={undefined}
                  classes={{ root: classes.accordionRoot, expanded: classes.accordionExpanded}}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    IconButtonProps={{
                      classes: {edgeEnd: classes.accButtonIconEdgeEnd},
                      onClick: handleFiltersToggle
                    }}
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    classes={{
                      root: classes.accordionSummaryRoot,
                      expanded: classes.accordionSummaryExpanded,
                      content: classes.accordionSummaryContent,
                    }}
                  >
                    <TabList
                      onChange={onSelectedTabChange}
                      classes={{ indicator: classes.activeTabIndicator }}
                      TabIndicatorProps={{ children: <div /> }}
                      variant="scrollable"
                    >
                      {renderTabs()}
                    </TabList>
                  </AccordionSummary>

                  <Divider />

                  <AccordionDetails
                    classes={{ root: classes.accordionDetailsRoot }}
                  >
                    {!_.isNil(renderFilters) && renderFilters()}
                  </AccordionDetails>
                </Accordion>

              </Paper>
            </Grid>
          </Grid>
        </TabContext>
      )}
    </Container>
  )
};

export default TabsFilter
