export const MODEL_PORTFOLIO_DELETE_SUCCESS_MESSAGE = 'Musterdepot wurde gelöscht.';
export const MODEL_PORTFOLIO_DELETE_ERROR_MESSAGE = 'Musterdepot kann nicht gelöscht werden.';
export const MODEL_PORTFOLIO_CREATE_ERROR_MESSAGE = 'Musterdepot konnte nicht gespeichert werden.';
export const PB_NO_ASSETS_MSG = 'Das Musterdepot enthält keine Produkte um den Portfolio-Builder zu öffnen';

export const MODEL_PORTFOLIO_TYPE = {
  PERSONAL: {
    label: 'Privates Musterdepot',
    value: 1
  },
  AGENCY: {
    label: 'Öffentliches (Meine Agentur) Musterdepot',
    value: 2
  }
};

export const MODEL_PORTFOLIO_TYPES = {
  PERSONAL: 1,
  AGENCY: 2,
  PARENT_AGENCY: 3,
  SUB_AGENCY: 4,
  CUSTODIAN_AGENCY: 5,
  PUBLIC: 6
};

export const MODEL_PORTFOLIO_TYPE_LABEL = {
  [MODEL_PORTFOLIO_TYPES.PERSONAL]: 'Mein Musterdepot',
  [MODEL_PORTFOLIO_TYPES.AGENCY]: 'Meine Agentur',
  [MODEL_PORTFOLIO_TYPES.PUBLIC]: 'Öffentlich',
  [MODEL_PORTFOLIO_TYPES.CUSTODIAN_AGENCY]: 'Modellportfolio',
  [MODEL_PORTFOLIO_TYPES.PARENT_AGENCY]: 'Agentur',
  [MODEL_PORTFOLIO_TYPES.SUB_AGENCY]: 'Unteragentur'
};

export const MODEL_PORTFOLIO_KNOWLEDGE_OPTIONS = [
  {id: 1, value: 1, label: "Basiskenntnisse"},
  {id: 2, value: 2, label: "Erweiterte Kenntnisse"},
  {id: 3, value: 3, label: "Umfangreiche Kenntnisse"},
];

export const MODEL_PORTFOLIO_KNOWLEDGE = Object.assign({}, ...MODEL_PORTFOLIO_KNOWLEDGE_OPTIONS.map(option => {
  return {[option.id]: option.label}
}));

export const MODEL_PORTFOLIO_INVESTMENT_GOAL_OPTIONS = [
  {id: 1, value: 1, label: "Vermögenserhalt"},
  {id: 2, value: 2, label: "Vermögensaufbau / Vermögenswachstum"},
  {id: 3, value: 3, label: "Erzielung von Zusatzeinkommen"},
];

export const MODEL_PORTFOLIO_INVESTMENT_GOAL = Object.assign({}, ...MODEL_PORTFOLIO_INVESTMENT_GOAL_OPTIONS.map(option => {
  return {[option.id]: option.label}
}));

export const MODEL_PORTFOLIO_TIME_HORIZON_OPTIONS = [
  {id: 1, value: 1, label: "bis zu 3 Jahre"},
  {id: 2, value: 2, label: "bis zu 5 Jahre"},
  {id: 3, value: 3, label: "bis zu 10 Jahre"},
  {id: 4, value: 4, label: "mehr als 10 Jahre"},
];

export const MODEL_PORTFOLIO_TIME_HORIZON = Object.assign({}, ...MODEL_PORTFOLIO_TIME_HORIZON_OPTIONS.map(option => {
  return {[option.id]: option.label}
}));

export const BUY_TRANSACTION_IDENTIFIER_CODE = 'SAL'
