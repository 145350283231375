import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',
    backgroundColor: 'white',
    borderRadius: 2,
    padding: 10,
  },
  accordionRoot: {
    width: '100%',
    boxShadow: 'none',
    position: 'relative',

    '&:before': {
      content: 'none  '
    }
  },
  accordionDetails: {
    padding: 0,
    flexDirection: 'column'
  },
  accordionHeading: {
    fontFamily: 'Roboto-Regular'
  },
  accordionExpanded: {
    margin: '0px !important'
  },
  accordionSummaryExpaned: {
    minHeight: '48px !important',
    height: '48px !important'
  },
  accordionSummaryContent: {
    height: 100
  },

  documentAccordion: {
    transition: 'all',
    transitionDuration: 250,

    '&:hover': {
      backgroundColor: "rgba(216, 220, 223, 0.3)",
    }
  },
  documentAccordionActive: {
    backgroundColor: "rgba(216, 220, 223, 1)",

    '&:hover': {
      backgroundColor: "rgba(216, 220, 223, 1)",
    }
  },
  documentAccordionDisabled: {
    '&:hover': {
      backgroundColor: "rgba(216, 220, 223, 0.3)",
    }
  },
  documentsNumber: {
    fontFamily: 'Roboto-Bold',
    color: '#202A38',
    marginLeft: 8,
    display: 'inline-block',
    backgroundColor: '#CCE9FA',
    padding: '2px 8px',
    borderRadius: '50%'
  },
  documentDoesNotExist: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 10,
    backgroundColor: 'rgba(1, 1, 1, 0.25)',
    backdropFilter: 'blur(1px)',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    '&> i': {
      fontSize: 25,
      color: 'white'
    },

    '& > span': {
      fontFamily: 'Roboto-Regular',
      fontSize: 14,
      color: 'white',
      marginTop: 5
    }
  },
  searchInputContainer: {
    backgroundColor: 'white',
    borderRadius: 2,
    marginBottom: 10,

    '& > div > div > div': {
      borderRadius: 2
    }
  }
});

export default makeStyles(styles);