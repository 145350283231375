export const ADD_COMBINED_TRADING_OPTION = 'ADD_COMBINED_TRADING_OPTION'
export const REMOVE_COMBINED_TRADING_OPTION = 'REMOVE_COMBINED_TRADING_OPTION'
export const UPDATE_PORTFOLIO_COMBINED_TRADING_OPTION = 'UPDATE_PORTFOLIO_COMBINED_TRADING_OPTION'
export const ADD_PORTFOLIO_COMBINED_TRADING_OPTION = 'ADD_PORTFOLIO_COMBINED_TRADING_OPTION'
export const REMOVE_PORTFOLIO_COMBINED_TRADING_OPTION = 'REMOVE_PORTFOLIO_COMBINED_TRADING_OPTION'
export const REMOVE_ALL_PORTFOLIOS_COMBINED_TRADING_OPTION = 'REMOVE_ALL_PORTFOLIOS_COMBINED_TRADING_OPTION'

export const TRADING_TYPE = {
  NORMAL: 'NORMAL',
  NEW_BUY: 'NEW_BUY'
}