import React from 'react'
import _ from "lodash"
import {connect} from "react-redux";

import { Grid } from '@material-ui/core'
import PortfolioExAnte from "../../../../../../Trades/components/ExAnteStep/components/PortfolioExAnte";
import {displayErrorSnackBar} from "../../../../../../../components/SnackbarProvider/actions";
import {getErrorMessage} from "../../../../../../../utils/utils";
import PortfolioAccordion from "../../../../../../Trades/components/ExAnteStep/components/PortfolioAccordion";

export default connect() (function ExAnte(props) {

  const {
    dataService,
    questions: [
      exAnte
    ],
  } = props

  const [stepData, setStepData] = React.useState(null);

  React.useEffect(() => {
    const productsService = dataService._getStepService('products_selection');
    const investmentsData = productsService.getInvestedAmountData();
    const data = productsService.getStepAnswer('product-selection-depot', 'custodians');

    data.contract = data.depot.contract_types.find(contract => contract.value == data.contract_type);
    // 1 - Monthly, 2 - Quarterly, 3 - Bimonthly, 4 - Half year, 5 - Yearly
    const paymentsPerYear = _.get({'1': 12, '2': 4, '3': 6, '4': 2, '5': 1}, investmentsData.savings_plan_period, 0);
    setStepData({
      totalAmount: productsService.getFundsBuyTotal(),
      oneYearSavingsPlan: investmentsData.savings_plan * paymentsPerYear,
      name: data.contract.label,
      isPrivateInvestment: _.get(data, 'depot.is_private_investment')
    });
  }, []);

  React.useEffect(() => {
    if(!!exAnte.error) {
      props.dispatch(displayErrorSnackBar(getErrorMessage(exAnte.error)));
    }
  }, [exAnte.error]);


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PortfolioAccordion name={stepData && stepData.name} expanded>
          <PortfolioExAnte
            totalValue={stepData && stepData.totalAmount}
            oneYearSavingsPlan={stepData && stepData.oneYearSavingsPlan}
            exAnteData={_.get(exAnte.answer, 'onboarding.buy')}
            transactionType={'buy'}
            oneYearSavingsPlanTitle={'Sparplan'}
            isPrivateInvestment={stepData && stepData.isPrivateInvestment}
            expanded
          />
        </PortfolioAccordion>
      </Grid>
    </Grid>
  )
})
