import React from "react";

import {SelectQuestionMethods} from "../SelectQuestion/SelectQuestion";
import SelectFormElement from "../../formElement/SelectFormElement";
import {DefaultCountry} from "../../../../../constants";
import _ from "lodash";


// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase()
      .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397)) + ' '
    : '';
}


export const CountryFormElement = props => {

  let options = countries;
  if(!props.required){
    options = [{id: 'empty', value: null, label: "\u00a0"}, ...options]
  }

  return <SelectFormElement
    options={options}
    {...props}
  />
}

const CountrySelectQuestion = props => {
  const {
    question,
    onAnswerChange
  } = props;

  let options = countries;
  const required = SelectQuestionMethods.required(question);
  if(!required){
    options = [{id: 'empty', value: null, label: "\u00a0"}, ...options]
  }

  React.useEffect(() => {
    if(!question.hasOwnProperty('config')){
      question.config = {};
    }
    if(!question.config.choices){
      question.config.choices = countriesChoices;
    }

    if(!question.answer && required){
      handleChange(DefaultCountry);
    }
  }, []);

  const handleChange = (value) => {
    question.error = null;  // clean error
    onAnswerChange(question.uid, value);
  };

  return <CountryFormElement
    value={question.answer || ''}
    label={question.question_text}
    required={required}
    onChange={handleChange}
    custom_classes={question.custom_classes}
    disabled={question.disabled || false}
    error={question.error}
  />
};

export const countriesChoices = [
  {"uid": "DE", "text": "Deutschland", "id": "DE", "suggested": true},
  {"uid": "AF", "text": "Afghanistan", "id": "AF"},
  {"uid": "AL", "text": "Albanien", "id": "AL"},
  {"uid": "DZ", "text": "Algerien", "id": "DZ"},
  {"uid": "AX", "text": "Åland-Inseln", "id": "AX"},
  {"uid": "AS", "text": "Amerikanisch-Samoa", "id": "AS"},
  {"uid": "AD", "text": "Andorra", "id": "AD"},
  {"uid": "AO", "text": "Angola", "id": "AO"},
  {"uid": "AI", "text": "Anguilla", "id": "AI"},
  {"uid": "AQ", "text": "Antarktis", "id": "AQ"},
  {"uid": "AG", "text": "Antigua und Barbuda", "id": "AG"},
  {"uid": "AR", "text": "Argentinien", "id": "AR"},
  {"uid": "AM", "text": "Armenien", "id": "AM"},
  {"uid": "AW", "text": "Aruba", "id": "AW"},
  {"uid": "AU", "text": "Australien", "id": "AU", "suggested": true},
  {"uid": "AT", "text": "Österreich", "id": "AT"},
  {"uid": "AZ", "text": "Aserbaidschan", "id": "AZ"},
  {"uid": "BS", "text": "Bahamas", "id": "BS"},
  {"uid": "BH", "text": "Bahrain", "id": "BH"},
  {"uid": "BD", "text": "Bangladesch", "id": "BD"},
  {"uid": "BB", "text": "Barbados", "id": "BB"},
  {"uid": "BY", "text": "Weißrussland", "id": "BY"},
  {"uid": "BE", "text": "Belgien", "id": "BE"},
  {"uid": "BZ", "text": "Belize", "id": "BZ"},
  {"uid": "BJ", "text": "Benin", "id": "BJ"},
  {"uid": "BM", "text": "Bermuda", "id": "BM"},
  {"uid": "BT", "text": "Bhutan", "id": "BT"},
  {"uid": "BO", "text": "Bolivien", "id": "BO"},
  {"uid": "BA", "text": "Bosnien und Herzegowina", "id": "BA"},
  {"uid": "BW", "text": "Botswana", "id": "BW"},
  {"uid": "BV", "text": "Bouvetinsel", "id": "BV"},
  {"uid": "BR", "text": "Brasilien", "id": "BR"},
  {"uid": "IO", "text": "Britisches Territorium im Indischen Ozeans", "id": "IO"},
  {"uid": "VG", "text": "Britische Jungferninseln", "id": "VG"},
  {"uid": "BN", "text": "Brunei Darussalam", "id": "BN"},
  {"uid": "BG", "text": "Bulgarien", "id": "BG"},
  {"uid": "BF", "text": "Burkina Faso", "id": "BF"},
  {"uid": "BI", "text": "Burundi", "id": "BI"},
  {"uid": "KH", "text": "Kambodscha", "id": "KH"},
  {"uid": "CM", "text": "Kamerun", "id": "CM"},
  {"uid": "CA", "text": "Kanada", "id": "CA", "suggested": true},
  {"uid": "CV", "text": "Kap Verde", "id": "CV"},
  {"uid": "KY", "text": "Kaimaninseln", "id": "KY"},
  {"uid": "CF", "text": "Zentralafrikanische Republik", "id": "CF"},
  {"uid": "TD", "text": "Tschad", "id": "TD"},
  {"uid": "CL", "text": "Chile", "id": "CL"},
  {"uid": "CN", "text": "China", "id": "CN"},
  {"uid": "CX", "text": "Weihnachtsinsel", "id": "CX"},
  {"uid": "CC", "text": "Kokosinseln (Keelinginseln)", "id": "CC"},
  {"uid": "CO", "text": "Kolumbien", "id": "CO"},
  {"uid": "KM", "text": "Komoren", "id": "KM"},
  {"uid": "CD", "text": "Kongo (Demokratische Republik)", "id": "CD"},
  {"uid": "CG", "text": "Kongo", "id": "CG"},
  {"uid": "CK", "text": "Cookinseln", "id": "CK"},
  {"uid": "CR", "text": "Costa Rica", "id": "CR"},
  {"uid": "CI", "text": "Côte d'Ivoire", "id": "CI"},
  {"uid": "HR", "text": "Kroatien", "id": "HR"},
  {"uid": "CU", "text": "Kuba", "id": "CU"},
  {"uid": "CW", "text": "Curaçao", "id": "CW"},
  {"uid": "CY", "text": "Zypern", "id": "CY"},
  {"uid": "CZ", "text": "Tschechien", "id": "CZ"},
  {"uid": "DK", "text": "Dänemark", "id": "DK"},
  {"uid": "DJ", "text": "Dschibuti", "id": "DJ"},
  {"uid": "DM", "text": "Dominica", "id": "DM"},
  {"uid": "DO", "text": "Dominikanische Republik", "id": "DO"},
  {"uid": "EC", "text": "Ecuador", "id": "EC"},
  {"uid": "EG", "text": "Ägypten", "id": "EG"},
  {"uid": "SV", "text": "El Salvador", "id": "SV"},
  {"uid": "GQ", "text": "Äquatorialguinea", "id": "GQ"},
  {"uid": "ER", "text": "Eritrea", "id": "ER"},
  {"uid": "EE", "text": "Estland", "id": "EE"},
  {"uid": "ET", "text": "Äthiopien", "id": "ET"},
  {"uid": "FK", "text": "Falklandinseln (Malwinen)", "id": "FK"},
  {"uid": "FO", "text": "Faröerinseln", "id": "FO"},
  {"uid": "FJ", "text": "Fidschi", "id": "FJ"},
  {"uid": "FI", "text": "Finnland", "id": "FI"},
  {"uid": "FR", "text": "Frankreich", "id": "FR", "suggested": true},
  {"uid": "GF", "text": "Französisch Guinea", "id": "GF"},
  {"uid": "PF", "text": "Französisch-Polynesien", "id": "PF"},
  {"uid": "TF", "text": "Französische Süd- und Antarktisgebiete", "id": "TF"},
  {"uid": "GA", "text": "Gabun", "id": "GA"},
  {"uid": "GM", "text": "Gambia", "id": "GM"},
  {"uid": "GE", "text": "Georgien", "id": "GE"},
  {"uid": "GH", "text": "Ghana", "id": "GH"},
  {"uid": "GI", "text": "Gibraltar", "id": "GI"},
  {"uid": "GR", "text": "Griechenland", "id": "GR"},
  {"uid": "GL", "text": "Grönland", "id": "GL"},
  {"uid": "GD", "text": "Granada", "id": "GD"},
  {"uid": "GP", "text": "Guadeloupe", "id": "GP"},
  {"uid": "GU", "text": "Guam", "id": "GU"},
  {"uid": "GT", "text": "Guatemala", "id": "GT"},
  {"uid": "GG", "text": "Guernsey", "id": "GG"},
  {"uid": "GN", "text": "Guinea", "id": "GN"},
  {"uid": "GW", "text": "Guinea-Bissau", "id": "GW"},
  {"uid": "GY", "text": "Guyana", "id": "GY"},
  {"uid": "HT", "text": "Haiti", "id": "HT"},
  {"uid": "HM", "text": "Heard und McDonaldinseln", "id": "HM"},
  {"uid": "VA", "text": "Vatikanstadt", "id": "VA"},
  {"uid": "HN", "text": "Honduras", "id": "HN"},
  {"uid": "HK", "text": "Hong Kong", "id": "HK"},
  {"uid": "HU", "text": "Ungarn", "id": "HU"},
  {"uid": "IS", "text": "Island", "id": "IS"},
  {"uid": "IN", "text": "Indien", "id": "IN"},
  {"uid": "ID", "text": "Indonesien", "id": "ID"},
  {"uid": "IR", "text": "Iran (Islamische Republik)", "id": "IR"},
  {"uid": "IQ", "text": "Irak", "id": "IQ"},
  {"uid": "IE", "text": "Irland", "id": "IE"},
  {"uid": "IM", "text": "Isle of Man", "id": "IM"},
  {"uid": "IL", "text": "Israel", "id": "IL"},
  {"uid": "IT", "text": "Italien", "id": "IT"},
  {"uid": "JM", "text": "Jamaika", "id": "JM"},
  {"uid": "JP", "text": "Japan", "id": "JP", "suggested": true},
  {"uid": "JE", "text": "Jersey", "id": "JE"},
  {"uid": "JO", "text": "Jordanien", "id": "JO"},
  {"uid": "KZ", "text": "Kasachstan", "id": "KZ"},
  {"uid": "KE", "text": "Kenia", "id": "KE"},
  {"uid": "KI", "text": "Kirivati", "id": "KI"},
  {"uid": "KP", "text": "Korea (Demokratische Volksrepublik)", "id": "KP"},
  {"uid": "KR", "text": "Korea (Republik)", "id": "KR"},
  {"uid": "XK", "text": "Kosovo", "id": "XK"},
  {"uid": "KW", "text": "Kuwait", "id": "KW"},
  {"uid": "KG", "text": "Kirgisistan", "id": "KG"},
  {"uid": "LA", "text": "Laos, Demokratische Volksrepublik", "id": "LA"},
  {"uid": "LV", "text": "Lettland", "id": "LV"},
  {"uid": "LB", "text": "Libanon", "id": "LB"},
  {"uid": "LS", "text": "Lesotho", "id": "LS"},
  {"uid": "LR", "text": "Liberia", "id": "LR"},
  {"uid": "LY", "text": "Libyen", "id": "LY"},
  {"uid": "LI", "text": "Liechtenstein", "id": "LI"},
  {"uid": "LT", "text": "Litauen", "id": "LT"},
  {"uid": "LU", "text": "Luxemburg", "id": "LU"},
  {"uid": "MO", "text": "Macao", "id": "MO"},
  {"uid": "MK", "text": "Nordmazedonien", "id": "MK"},
  {"uid": "MG", "text": "Madagaskar", "id": "MG"},
  {"uid": "MW", "text": "Malawi", "id": "MW"},
  {"uid": "MY", "text": "Malaysia", "id": "MY"},
  {"uid": "MV", "text": "Malediven", "id": "MV"},
  {"uid": "ML", "text": "Mali", "id": "ML"},
  {"uid": "MT", "text": "Malta", "id": "MT"},
  {"uid": "MH", "text": "Marshallinseln", "id": "MH"},
  {"uid": "MQ", "text": "Martinique", "id": "MQ"},
  {"uid": "MR", "text": "Mauritanien", "id": "MR"},
  {"uid": "MU", "text": "Mauritius", "id": "MU"},
  {"uid": "YT", "text": "Mayotte", "id": "YT"},
  {"uid": "MX", "text": "Mexico", "id": "MX"},
  {"uid": "FM", "text": "Mikronesien (Föderierte Staaten von)", "id": "FM"},
  {"uid": "MD", "text": "Moldawien (Republik)", "id": "MD"},
  {"uid": "MC", "text": "Monaco", "id": "MC"},
  {"uid": "MN", "text": "Mongolei", "id": "MN"},
  {"uid": "ME", "text": "Montenegro", "id": "ME"},
  {"uid": "MS", "text": "Montserrat", "id": "MS"},
  {"uid": "MA", "text": "Marokko", "id": "MA"},
  {"uid": "MZ", "text": "Mozambique", "id": "MZ"},
  {"uid": "MM", "text": "Myanmar", "id": "MM"},
  {"uid": "NA", "text": "Namibia", "id": "NA"},
  {"uid": "NR", "text": "Nauru", "id": "NR"},
  {"uid": "NP", "text": "Nepal", "id": "NP"},
  {"uid": "NL", "text": "Niederlande", "id": "NL"},
  {"uid": "NC", "text": "Neukaledonien", "id": "NC"},
  {"uid": "NZ", "text": "Neuseeland", "id": "NZ"},
  {"uid": "NI", "text": "Nicaragua", "id": "NI"},
  {"uid": "NE", "text": "Niger", "id": "NE"},
  {"uid": "NG", "text": "Nigeria", "id": "NG"},
  {"uid": "NU", "text": "Niue", "id": "NU"},
  {"uid": "NF", "text": "Norfolkinsel", "id": "NF"},
  {"uid": "MP", "text": "Commonwealth der Nördlichen Marianen", "id": "MP"},
  {"uid": "NO", "text": "Norwegen", "id": "NO"},
  {"uid": "OM", "text": "Oman", "id": "OM"},
  {"uid": "PK", "text": "Pakistan", "id": "PK"},
  {"uid": "PW", "text": "Palau", "id": "PW"},
  {"uid": "PS", "text": "Palästina", "id": "PS"},
  {"uid": "PA", "text": "Panama", "id": "PA"},
  {"uid": "PG", "text": "Papua Neu Guinea", "id": "PG"},
  {"uid": "PY", "text": "Paraguay", "id": "PY"},
  {"uid": "PE", "text": "Peru", "id": "PE"},
  {"uid": "PH", "text": "Philippinen", "id": "PH"},
  {"uid": "PN", "text": "Pitcairn", "id": "PN"},
  {"uid": "PL", "text": "Polen", "id": "PL"},
  {"uid": "PT", "text": "Portugal", "id": "PT"},
  {"uid": "PR", "text": "Puerto Rico", "id": "PR"},
  {"uid": "QA", "text": "Katar", "id": "QA"},
  {"uid": "RE", "text": "Réunion", "id": "RE"},
  {"uid": "RO", "text": "Rumänien", "id": "RO"},
  {"uid": "RU", "text": "Russische Föderation", "id": "RU"},
  {"uid": "RW", "text": "Rwanda", "id": "RW"},
  {"uid": "BL", "text": "Saint-Barthélemy", "id": "BL"},
  {"uid": "SH", "text": "St. Helena, Ascension und Tristan da Cunha", "id": "SH"},
  {"uid": "KN", "text": "St. Kitts und Nevis", "id": "KN"},
  {"uid": "LC", "text": "St. Lucia", "id": "LC"},
  {"uid": "MF", "text": "St. Martin (französischer Teil)", "id": "MF"},
  {"uid": "PM", "text": "Saint-Pierre und Miquelon", "id": "PM"},
  {"uid": "VC", "text": "St. Vincent und die Grenadinen", "id": "VC"},
  {"uid": "WS", "text": "Samoa", "id": "WS"},
  {"uid": "SM", "text": "San Marino", "id": "SM"},
  {"uid": "ST", "text": "São Tomé und Príncipe", "id": "ST"},
  {"uid": "SA", "text": "Saudi Arabien", "id": "SA"},
  {"uid": "SN", "text": "Senegal", "id": "SN"},
  {"uid": "RS", "text": "Serbien", "id": "RS"},
  {"uid": "SC", "text": "Seychellen", "id": "SC"},
  {"uid": "SL", "text": "Sierra Leone", "id": "SL"},
  {"uid": "SG", "text": "Singapur", "id": "SG"},
  {"uid": "SX", "text": "Sint Maarten (niederländischer Teil)", "id": "SX"},
  {"uid": "SK", "text": "Slowakei", "id": "SK"},
  {"uid": "SI", "text": "Slowenien", "id": "SI"},
  {"uid": "SB", "text": "Salomonen", "id": "SB"},
  {"uid": "SO", "text": "Somalia", "id": "SO"},
  {"uid": "ZA", "text": "Südafrika", "id": "ZA"},
  {"uid": "GS", "text": "Südgeorgien und die Südlichen Sandwichinseln", "id": "GS"},
  {"uid": "SS", "text": "Südsudan", "id": "SS"},
  {"uid": "ES", "text": "Spanien", "id": "ES"},
  {"uid": "LK", "text": "Sri Lanka", "id": "LK"},
  {"uid": "SD", "text": "Sudan", "id": "SD"},
  {"uid": "SR", "text": "Surinam", "id": "SR"},
  {"uid": "SJ", "text": "Spitzbergen und Jan Mayen", "id": "SJ"},
  {"uid": "SZ", "text": "Swasiland", "id": "SZ"},
  {"uid": "SE", "text": "Schweden", "id": "SE"},
  {"uid": "CH", "text": "Schweiz", "id": "CH"},
  {"uid": "SY", "text": "Syrien, Arabische Republik", "id": "SY"},
  {"uid": "TW", "text": "Taiwan (Provinz Chinas)", "id": "TW"},
  {"uid": "TJ", "text": "Tadschikistan", "id": "TJ"},
  {"uid": "TH", "text": "Thailand", "id": "TH"},
  {"uid": "TL", "text": "Osttimor", "id": "TL"},
  {"uid": "TG", "text": "Togo", "id": "TG"},
  {"uid": "TK", "text": "Tokelau", "id": "TK"},
  {"uid": "TO", "text": "Tonga", "id": "TO"},
  {"uid": "TT", "text": "Trinidad und Tobago", "id": "TT"},
  {"uid": "TN", "text": "Tunesien", "id": "TN"},
  {"uid": "TR", "text": "Türkei", "id": "TR"},
  {"uid": "TM", "text": "Turkmenistan", "id": "TM"},
  {"uid": "TC", "text": "Turks- und Caicosinseln", "id": "TC"},
  {"uid": "TV", "text": "Tuvalu", "id": "TV"},
  {"uid": "VI", "text": "Amerikanische Jungferninseln", "id": "VI"},
  {"uid": "UG", "text": "Uganda", "id": "UG"},
  {"uid": "UA", "text": "Ukraine", "id": "UA"},
  {"uid": "AE", "text": "Vereinigte Arabische Emirate", "id": "AE"},
  {"uid": "GB", "text": "Vereinigtes Königreich Großbritannien und Nordirland", "id": "GB"},
  {"uid": "TZ", "text": "Tansania", "id": "TZ"},
  {"uid": "US", "text": "Vereinigte Staaten von Amerika", "id": "US", "suggested": true},
  {"uid": "UY", "text": "Uruguay", "id": "UY"},
  {"uid": "UZ", "text": "Usbekistan", "id": "UZ"},
  {"uid": "VU", "text": "Vanuatu", "id": "VU"},
  {"uid": "VE", "text": "Venezuela (Bolivarische Republik)", "id": "VE"},
  {"uid": "VN", "text": "Vietnam", "id": "VN"},
  {"uid": "WF", "text": "Wallis und Futuna", "id": "WF"},
  {"uid": "EH", "text": "Westsahara", "id": "EH"},
  {"uid": "YE", "text": "Jemen", "id": "YE"},
  {"uid": "ZM", "text": "Sambia", "id": "ZM"},
  {"uid": "ZW", "text": "Simbabwe", "id": "ZW"}
];

const countries = _.sortBy(countriesChoices, o => _.lowerCase(o.text)).map(
  o => ({...o, value: o.uid, label: countryToFlag(o.uid) + o.text}));

export default CountrySelectQuestion;