const styles = () => ({
  listContainer: {},
  listItem: {
    padding: 8,
    fontSize: 12,
    borderBottom: '1px solid #e8e8e8',

    '&:last-of-type':{
      borderBottom: 'none'
    }
  },
  bold: {
    fontFamily: 'Roboto-Bold'
  },
  name: {
    wordBreak: 'break-all',
    '& *': {
      textTransform: 'initial'
    }
  }
});

export default styles;