import React from 'react';
import _ from 'lodash';


import withTheme from '@material-ui/core/styles/withTheme';

import {PortfolioUtils} from '../../../../../../../utils/utils';
import useStyles from '../styles';
import ChartSectionBordered from "../../../../../components_v2/ChartSectionBordered/ChartSectionBordered";
import {StructureTable} from "../../../../../components_v2/StructureTable/StructureTable";
import {
  CustomerDashboardNoDataPlaceholder
} from "../../../../../../FactSheetsPage/components/NoDataPlaceholder";
import {portfolioStructurePropsAreEquals} from '../utils';

const PortfolioCurrencyStructure = React.memo((props) => {
  const {
    dashboardData,
    preferredColor,
    expanded,
    onExpanded,
  } = props;

  const classes = useStyles();

  const portfolioStructure = dashboardData && dashboardData.portfolio_structure;

  let data = [];
  if (portfolioStructure) {
    data = PortfolioUtils.getPortfolioCurrencies(portfolioStructure)
  }
  const hasData = !_.isEmpty(data);

  return (
    <ChartSectionBordered
      title={(
        <>
          <b>{data.length !== 1 ? 'Währungen' : 'Währung'}</b>
          {hasData && (
            <span className={classes.subtitle}>(Top 10 Instrumente innerhalb Fonds)</span>
          )}
        </>
      )}
      expanded={expanded}
      onExpanded={onExpanded}
      skipContentPadding={hasData}
      content={(
        <>
          {hasData ? (
            <StructureTable
              series={data || []}
              preferredColor={preferredColor}
            />
          ) : (
            <CustomerDashboardNoDataPlaceholder />
          )}
        </>
      )}
    />
  )
}, portfolioStructurePropsAreEquals);


export default withTheme(PortfolioCurrencyStructure);
