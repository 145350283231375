import {smFontSize, xsFontSize} from "../../../../../../utils/constants";

const styles = (theme) => ({
  benchmarkWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  switchWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: 15
  },
  benchmarkLabel: {
    fontFamily: "'Roboto-Bold'",
    fontSize: smFontSize,
    color: '#4D4F5C',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('sm')]: {
      fontSize: xsFontSize,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: 12,
    },
  },
  helpIcon: {
    color: '#E1E3E9',
    width: 20,
    height: 20,
    marginLeft: 10,
    transform: 'rotate(180deg)',

    '&:hover': {
      cursor: 'pointer'
    }
  },
  benchmarkSettingsButton: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
    fontSize: 13,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',

    '& > span:first-child': {
      marginRight: 2,
      fontSize: 20
    },
    '& > span:last-child': {
      textDecoration: 'underline'
    }
  },
  disabled: {
    filter: 'grayscale(1)',
    pointerEvents: 'none',
    cursor: 'initial'
  },
  contentControl: {
    marginTop: -16,
  },
  chartContainer: {
  },
});

export default styles;