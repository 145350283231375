import { makeStyles, createStyles } from '@material-ui/core'

const sustainabilityCellStyles = {
  width: 83,
  whiteSpace: 'normal'
}

export const styles = theme => createStyles({
  selectAllLabel: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38',
    marginLeft: -5
  },

  instrumentsCount: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38',
    marginLeft: 10
  },

  addInstrumentsButtonRoot: {
    height: '50px !important',
    borderRadius: 8
  },

  addInstrumentsButtonDisabled: {
    background: '#99D3F5 !important',
    color: 'white !important'
  },

  tableCell: {
    // width: 100,
    '&:first-child': {
      width: 40
    },
    '&:nth-child(2)': {
      // width: 350
    },
    '&:nth-child(3)': {
      ...sustainabilityCellStyles
    },
    fontFamily: 'Roboto-Regular',
    color: '#202A38',
    whiteSpace: 'nowrap',
  },
  tableHead: {

    '& td': {
      fontFamily: 'Roboto-Bold',
      fontSize: 14,
      color: '#80858C',
    }
  },
  paginationContainer: {
    marginTop: 20,
    justifyContent: 'center'
  },
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 20,
    color: '#202A38',
    margin: '30px 0 25px'
  }
})

export default makeStyles(styles)


const searchTableClasses = (theme) => ({
  
  tableCell: {
    // width: 100,
    '&:first-child': {
      // width: 350
      '& .ellipsis': {
        width: 350,
        color: theme.palette.primary.main,
      }
    },
    '&:nth-child(2)': {
      // width: 200
    },
    '&:nth-child(4)': {
      ...sustainabilityCellStyles
    },
    fontFamily: 'Roboto-Regular',
    color: '#202A38',
    whiteSpace: 'nowrap',
  },
})
export const useSearchTableClasses = makeStyles(searchTableClasses)