import React from 'react'
import _ from 'lodash';
import { connect } from 'react-redux'

import {
  Container
} from '@material-ui/core';

import { withStyles } from '@material-ui/core';

import DashboardTable from '../../../components/DashboardTable';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';

import { styles } from './styles';
import { modelportfoliosOverviewTableStructure } from '../components/InstrumentsList/table-data';
import {PlusIcon} from '../../../images';
import { ModelPortfolioResource } from '../../../utils/api';
import { getRouteFullPathByComponent } from '../../InvestmentPlatform/utils'
import { paginateArray } from '../../../utils/utils';
import { displayErrorSnackBar, displaySuccessSnackBar } from '../../../components/SnackbarProvider/actions';
import { MODEL_PORTFOLIO_DELETE_ERROR_MESSAGE, MODEL_PORTFOLIO_DELETE_SUCCESS_MESSAGE } from '../constants'
import { DeleteModelPortfolioModal } from '../components';
import { executeIfPathExist, getInvestmentDynamicPath } from '../../InvestmentPlatform/utils'
import TabsFilter from "../../InvestmentStrategy/List/components/TabsFilter";
import DynamicFilters from "../../InvestmentStrategy/List/components/DynamicFilters";
import BaseList, {ALL_ID} from "./BaseList";
import {
  AddProductsToComparison,
  SelectMusterdepotForProductsComparisonModal
} from "../../../components/SelectForProductsComparisonModal/SelectForProductsComparisonModal";


const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
  auth: state.get('auth').toJS(),
});

const MODEL_PORTFOLIO_PAGE_SIZE = 15;

class List extends BaseList {

  constructor(props) {
    super(props);

    this.state = {
      modelPortfolios: {
        data: undefined,
        loading: true,
        errors: undefined,
        date: Date.now()
      },
      deleteModelPortfolioModalState: {
        visible: false,
        name: null,
        id: null
      },
      investmentDynamicPath: getInvestmentDynamicPath(),
      searchString: '',
      currentFilter: {
        type: ALL_ID,
        name: ''
      },
      extendComparisonWithId: undefined
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.fetchModelPortfolios()
  }

  fetchModelPortfolios = async (params) => {
    this.setState({
      modelPortfolios: {
        data: null,
        loading: true,
        errors: null,
        date: Date.now()
      }
    })

    try {
      if (this.props.extraParams){
        params = {...params, ...this.props.extraParams}
      }
      let response = await ModelPortfolioResource.getModelPortfoliosList({...params, with_istruments_data: false})
      if(this.props.filterPortfolios){
        response = this.props.filterPortfolios(response);
      }

      let portfoliosToAccept = response
      let portfoliosToExclude = []
      if (this.props.modelPortfoliosHandler) {
        [portfoliosToAccept, portfoliosToExclude] = this.props.modelPortfoliosHandler.filterInstruments(portfoliosToAccept)
      }

      let responsePaginated = [...paginateArray([...portfoliosToAccept, ...portfoliosToExclude], MODEL_PORTFOLIO_PAGE_SIZE)]

      this.setState({
        modelPortfolios: {
          ...this.state.modelPortfolios,
          loading:false,
          errors: null,
          data: responsePaginated,
          date: Date.now()
        },
      })

    } catch (errors) {

      this.setState({
        modelPortfolios: {
          ...this.state.modelPortfolios,
          loading: false,
          date: Date.now(),
          errors
        }

      })
    }
  }

  getModelPortfoliosDataSource = () => {
    if (this.state.modelPortfolios.loading) {
      return [{}, {}, {}, {}, {}]
    }

    if (this.state.modelPortfolios.data) {
      let dataSource = this.state.modelPortfolios.data
      return dataSource || []
    }

    return []
  }

  renderAddNewModelPortfolioButton = () => {
    if (this.props.investmentPlatform.routes) {
      let fullPath = getRouteFullPathByComponent(this.props.investmentPlatform.routes, 'MODELPORTFOLIO_CREATE')

      if (fullPath) {
        return (
          <PrimaryButton
            text="Neues Musterdepot erstellen"
            icon={<PlusIcon color="white"/>}
            onButtonClick={() => this.props.history.push(`/${this.state.investmentDynamicPath}${fullPath}`)}
          />
        )
      }
    }
  }

  handleDeleteModalClose = () => {
    this.setState({
      deleteModelPortfolioModalState: {
        visible: false,
        name: null,
        id: null
      }
    })
  }

  handleDeleteModelPortfolioModalOpen = (id, name) => {
    this.setState({
      deleteModelPortfolioModalState: {
        visible: true,
        name,
        id
      }
    })
  }

  handleDeleteModelPortfolio = async () => {
    if (this.state.deleteModelPortfolioModalState.id) {
      try {

        await ModelPortfolioResource.deleteModelPortfolio(this.state.deleteModelPortfolioModalState.id)

        this.deleteModelPortfolio(this.state.deleteModelPortfolioModalState.id)

        this.setState({
          deleteModelPortfolioModalState: {
            visible: false,
            name: null,
            id: null
          }
        })
        this.props.dispatch(displaySuccessSnackBar(MODEL_PORTFOLIO_DELETE_SUCCESS_MESSAGE))

      } catch (errors) {
        this.props.dispatch(displayErrorSnackBar(MODEL_PORTFOLIO_DELETE_ERROR_MESSAGE))
      }
    }
  }

  deleteModelPortfolio = (id) => {
    let modelPortfolios = [].concat(...this.state.modelPortfolios.data)
    let modelPortfoliosFiltered = modelPortfolios.filter(item => item.id != id)
    let modelPortfoliosPaginated = [...paginateArray(modelPortfoliosFiltered, MODEL_PORTFOLIO_PAGE_SIZE)]

    this.setState({
      modelPortfolios: {
        ...this.state.modelPortfolios,
        data: modelPortfoliosPaginated
      },
    })
  }

  handleModelPortfolioDelete = async (modelPortfolio) => {

    try {
      await ModelPortfolioResource.deleteModelPortfolio(modelPortfolio.id);

      let modelPortfoliosCopy = this.state.modelPortfolios.data ? [...this.state.modelPortfolios.data] : []

      modelPortfoliosCopy = _.filter(modelPortfoliosCopy, m => m.id != modelPortfolio.id)

      this.setState({
        modelPortfolios: {
          ...this.state.modelPortfolios,
          data: modelPortfoliosCopy
        }
      })
    } catch (error) {

    }
  }

  handleEditClick = (favoriteList, skipPreview) => {
    if(this.props.handleEditClick){
      return this.props.handleEditClick(favoriteList, skipPreview);
    }
    executeIfPathExist(this.props.investmentPlatform.routes, this.props.detailsComponentId || 'MODELPORTFOLIO_OVERVIEW', path => {
      let pathUpdated = path.replace(':id', favoriteList.id)

      this.props.history.push(`/${this.state.investmentDynamicPath}${pathUpdated}`)
    })
  };

  /**
   * Updates currentFilter by key with provided value
   * @param filterField
   * @param filterNewValue
   */
  updateCurrentFilter = (filterField, filterNewValue) => {
    let newCurrentFilter = {...this.state.currentFilter}
    newCurrentFilter[filterField] = filterNewValue
    this.setState({currentFilter: newCurrentFilter})
  }

  /**
   * Fetches model portfolios list when selected tab is changed
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.currentFilter.type !== this.state.currentFilter.type) {
      this.applyFilters({name: this.state.currentFilter.name})
    }
  }

  /**
   * Fetches favorite list items with provided filters
   */
  applyFilters = (params) => {
    if(this.state.currentFilter.type !== ALL_ID){
      params.portfolio_types = this.state.currentFilter.type
    }

    this.fetchModelPortfolios(params);
  }

  renderFilters = () => {
    return(
      <DynamicFilters
        searchString={this.state.searchString}
        onSearchFieldChange={(newValue) => this.setState({searchString: newValue})}
        handleApplyFilterBtnClick={() => {
          // update filter's 'name' state with current search string value
          this.updateCurrentFilter('name', this.state.searchString)
          // fetch model portfolios list with proper name filter value
          this.applyFilters({name: this.state.searchString})
        }}
      />
    )
  }

  handleExtendClick = (musterdepot) => {
    this.setState({
      extendComparisonWithId: musterdepot.id
    })
  }

  handleExtendClose = () => {
    this.setState({
      extendComparisonWithId: undefined
    })
  }

  render() {
    const { classes, viewOnly, title, directAddingPossible } = this.props;

    return (
      <div className={classes.container} style={{overflowX: 'hidden'}}>
        <Container>
          <div className={classes.navigationContainer} style={{marginBottom: 20}}>
            <h1 className={classes.header}>{title}</h1>
            {!viewOnly && this.renderAddNewModelPortfolioButton()}
          </div>

          <TabsFilter
            selectedTab={this.state.currentFilter.type}
            onSelectedTabChange={(event, newSelectedTabIndex) => this.updateCurrentFilter('type', newSelectedTabIndex)}
            tabs={this.TABS}
            loading={this.state.modelPortfolios.loading}
            renderFilters={this.renderFilters}
            preloaderHeight={188}
          />

          <DashboardTable
            tableLayout={'auto'}
            structure={modelportfoliosOverviewTableStructure}
            dataSource={this.getModelPortfoliosDataSource()}
            expanded={true}
            tableClasses={classes}
            withFooter={false}
            paginationOptions={{
              paginationEnabled: true,
              pageSize: MODEL_PORTFOLIO_PAGE_SIZE
            }}
            options={{
              loading: this.state.modelPortfolios.loading,
              onDelete: !!viewOnly ? null : this.handleDeleteModelPortfolioModalOpen,
              onEdit: this.handleEditClick,
              onExtend: this.handleExtendClick,
              withPortfolioBuilder: !this.props.viewOnly,
              auth: this.props.auth,
              viewOnly: this.props.viewOnly,
              directAddingPossible,
              actionsNames: this.props.actionsNames
            }}
          />
        </Container>
        {this.state.extendComparisonWithId && (
          <AddProductsToComparison>
            <SelectMusterdepotForProductsComparisonModal
              musterdepotId={this.state.extendComparisonWithId}
              onClose={this.handleExtendClose}
            />
          </AddProductsToComparison>
        )}
        {!viewOnly && (
          <DeleteModelPortfolioModal
            open={this.state.deleteModelPortfolioModalState.visible}
            modelPortfolioName={this.state.deleteModelPortfolioModalState.name}
            onClose={this.handleDeleteModalClose}
            onAccept={this.handleDeleteModelPortfolio}
          />
        )}
      </div>
    )
  }
}

List.defaultProps = {
  viewOnly: false,
  title: "Musterdepots",
  directAddingPossible: true
}

export default connect(mapStateToProps)(withStyles(styles)(List));
