import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  topMessage: {
    margin: '1.5rem 0 1rem 0',
    fontSize: 16,
  },
  bold: {
    fontFamily: 'Roboto-Bold'
  }
});

export default makeStyles(styles)
