import React from 'react';
import _ from 'lodash';

import {
  TextField,
  Button,
  InputAdornment,
  CircularProgress
} from '@material-ui/core';

import useStyles from './styles';


const Form = ({username, onUsernameChange, password, onPasswordChange, isLoading, onFormSubmit, errors}) => {
  const classes = useStyles();

  const [isPassword, setIsPassword] = React.useState(true);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onFormSubmit();
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <div className={classes.formContainer}>
        <p>Anmeldung</p>
        <TextField
          label="E-Mail / Kundennummer"
          value={username}
          error={!_.isNil(errors)}
          onChange={onUsernameChange}
          disabled={isLoading}
          classes={{
            root: classes.textFieldRoot,
          }}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: classes.inputLabelRoot,
              shrink: classes.inputLabelShrink
            }
          }}
        />
        <TextField
          label="Passwort"
          value={password}
          onChange={onPasswordChange}
          type={isPassword ? "password" : "text"}
          error={!_.isNil(errors)}
          disabled={isLoading}
          classes={{
            root: classes.textFieldRoot,
          }}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: classes.inputLabelRoot,
              shrink: classes.inputLabelShrink
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment className={classes.inputAdornment} position="end">
                <i
                  onClick={() => setIsPassword(!isPassword)} 
                  className={ isPassword ? "fa fa-eye-slash" : "fa fa-eye"} 
                  aria-hidden="true"></i>
              </InputAdornment>)
          }}
        />
        <div className={classes.submitButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.submitButton}
            disabled={isLoading}
            type="submit"
          >
            {isLoading ? (
              <CircularProgress color="primary" size={15} />
            ) : (
              "Anmelden"
            )}
          </Button>
        </div>
      </div>
    </form>
  )
};

export default Form;