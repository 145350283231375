import React from "react";
import PrimaryButton from '../PrimaryButton'
import { InputAdornment } from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const SearchButton = (props) => {
  const {
    classes,
    icon,
    text,
    onValueApprove,
    isSearchDisabled,

    hasDropdownOptions,
  } = props;

  return (
    <InputAdornment>
      {hasDropdownOptions &&
        <KeyboardArrowDownIcon style={{marginRight: '5%'}} />
      }

      <PrimaryButton
        icon={icon}
        text={text}
        classes={{
          icon: classes && classes.icon,
          button: classes && classes.searchButton
        }}
        onButtonClick={onValueApprove}
        disabled={!!isSearchDisabled}
      >
      </PrimaryButton>
    </InputAdornment>
    )
};

SearchButton.defaultProps = {
    text: 'Suche'
}

export default SearchButton;