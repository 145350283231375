import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./styles";
import AddAssetButton from "../../../AddAssetButton";

const EmptyListPlaceholder = props => {
  const {
    classes,
    onAddAssetClick
  } = props;

  return (
    <div className={classes.container}>
      <div>
        <p className={classes.message}>Sie haben noch keine sonstigen Vermögenswerte hinzugefügt.</p>
      </div>
      <div>
        <AddAssetButton
          onButtonClick={onAddAssetClick}
          text={'Sonstige Vermögenswerte hinzufügen'}
        />
      </div>
    </div>
  )
};

export default withStyles(styles)(EmptyListPlaceholder);