import { makeStyles } from '@material-ui/core';

export const styles = (theme) => ({
  container: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  icon: {
    fontSize: 20,
    color: 'red'
  },
  iconVariant: {
    opacity: 0.9,
      marginRight: theme.spacing(1),
  },
  message: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    display: 'flex',
    alignItems: 'center',
    color: '#313D44',

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
});

export default makeStyles(styles);