import { makeStyles} from "@material-ui/core";

export default makeStyles(theme => ({
  primaryColorText: {
    color: theme.palette.primary.main
  },

  outdatedInfoText: {
    fontSize: '0.875rem',
    lineHeight: 1.66,
    fontFamily: 'Roboto-Regular',
    color: 'black'
  },

  mismatchWarningMessage: {
    color: theme.palette.primary.main
  },

  confirmButton: {
    marginLeft: 15
  },

  legitimationHelpText: {
    paddingBottom: 0,
    marginBottom: 0,
    lineHeight: '1.66em',
    fontSize: 16,
  }
}))
