import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import React from "react";
import {executeIfPathExist, getInvestmentDynamicPath} from "../../../InvestmentPlatform/utils";
import {CUSTOMER_ID_KEY, setInStorage} from "../../../../utils/storage";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import {PortfolioHandlerResource} from "../../../../utils/api";
import {CustomerAlertsListItem} from "./components/CustomerAlertsListItem";
import {getInvestmentPlatformSelector} from "../../../../utils/redaxSelectors";

export let alerts = {};

const mapStateToProps = (state) => ({
  investmentPlatform: getInvestmentPlatformSelector(state)
});

export const CustomersAlertsList = connect(mapStateToProps)(({dataSource, investmentPlatform}) => {

  const history = useHistory();

  // Used to trigger rerender of component to sync with latest alerts data
  const [rerender, setRerender] = React.useState(true);

  const onDetailsClick = (id) => {
    executeIfPathExist(investmentPlatform.routes, 'RISK_INDICATOR_ALL_CUSTOMERS_OVERVIEW', (path) => {
      setInStorage(CUSTOMER_ID_KEY, id);
      let investmentDynamicPath = getInvestmentDynamicPath();
      let url = `/${investmentDynamicPath}${path.replace(':customer_id', id)}`;

      window.open(url)

    }, ':customer_id')
  }

  // TODO: Check performance. Maybe will be better to use useMemo
  const customers = (() => {
    return dataSource.map((customer) => {
      customer.alertConfigurations = alerts[customer.customer_id] || {};
      return customer;
    })
  })();

  const onAlertConfigurationSaved = () => {
    setRerender(current => !current)
  }

  return (
    <Grid container>
      {customers.map((customer) => {
        return (
          <CustomerAlertsListItem key={customer.customer_id} customer={customer} onDetailsClick={onDetailsClick} onAlertConfigurationSaved={onAlertConfigurationSaved}/>
        )
      })}
    </Grid>
  )
})

export const onCustomersChange = async (customers) => {
  if (_.isEmpty(customers)) {
    return;
  }

  try {
    const customer_id = customers.map((customer) => customer.customer_id);
    alerts = await PortfolioHandlerResource
      .at('customer/asset/alert-configuration/list/')
      .post({customer_id});
  } catch (error) {
    console.error(error);
  }
}