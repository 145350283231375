import React from 'react'
import {useRiskScoreConfirmationModalContext} from "../../InvestmentRecommendationStep";
import DialogTitle from "@material-ui/core/DialogTitle";

import useStyles from './styles'
import {Dialog, DialogContent, Grid} from "@material-ui/core";
import {PrimaryButton} from "../../../../../../../../../../../components/Buttons";
import DialogActions from "@material-ui/core/DialogActions";
import _ from "lodash";
import {
  RISK_SCORE_INVESTMENT_STRATEGY_EXPERIENCE_AND_SCORE_EXPLANATION_MODAL_UID,
  RISK_SCORE_INVESTMENT_STRATEGY_EXPERIENCE_EXPLANATION_MODAL_UID,
  RISK_SCORE_INVESTMENT_STRATEGY_SCORE_EXPLANATION_MODAL_UID,
  RISK_SCORE_SAVINGS_PLAN_EXPLANATION_MODAL_UID,
  RISK_SCORE_SINGLE_INVESTMENT_EXPLANATION_MODAL_UID
} from "../../../../../../../../../constants";
import RichTextEditor from "../../../../../../formElement/RichTextEditor";

function RiskScoreConfirmationModal() {

  const {open, activeAsset, onSave, singleInvestmentEnabled} = useRiskScoreConfirmationModalContext()

  const classes = useStyles()

  const [value, setValue] = React.useState(activeAsset && activeAsset.risk_score_explanation)

  React.useEffect(() => {
    setValue(activeAsset && activeAsset.risk_score_explanation)
  }, [activeAsset])

  const handleSave = () => onSave && onSave(activeAsset, value)

  const handleEditorValueChange = (newValue, source, editor) => {
    if (source != 'user') {
      return
    }

    setValue(!_.isEmpty(editor.getText()) && newValue)
  }

  if (!activeAsset) {
    return null
  }

  const isInvestmentStrategy = !!activeAsset.isInvestmentStrategy || !!_.get(activeAsset, 'data.isPrivateInvestment');

  const label = (() => {
    if (!isInvestmentStrategy) {
      return 'Begründung für die Order eines Produktes, das die Risikoklasse des Kunden übersteigt';
    }

    if (activeAsset.risk_score_confirmation_required && activeAsset.experience_confirmation_required) {
      return 'Begründung für die Anlage in eine Vermögensverwaltung mit einer höheren Risikoklasse und einem höheren Anforderungsniveau an Kenntnissen und Erfahrungen'
    }

    return activeAsset.experience_confirmation_required
      ? 'Begründung für die Anlage in eine Vermögensverwaltung mit einem höheren Anforderungsniveau an Kenntnissen und Erfahrungen'
      : 'Begründung für die Anlage in einer Vermögensverwaltung mit einer höheren Risikoklasse als die des Kunden'
  })()

  const textEditorUid = (() => {
    if (isInvestmentStrategy) {

      if (activeAsset.risk_score_confirmation_required && activeAsset.experience_confirmation_required) {
        return RISK_SCORE_INVESTMENT_STRATEGY_EXPERIENCE_AND_SCORE_EXPLANATION_MODAL_UID;
      }

      return activeAsset.experience_confirmation_required
        ? RISK_SCORE_INVESTMENT_STRATEGY_EXPERIENCE_EXPLANATION_MODAL_UID
        : RISK_SCORE_INVESTMENT_STRATEGY_SCORE_EXPLANATION_MODAL_UID
    }

    return singleInvestmentEnabled
      ? RISK_SCORE_SINGLE_INVESTMENT_EXPLANATION_MODAL_UID
      : RISK_SCORE_SAVINGS_PLAN_EXPLANATION_MODAL_UID
  })()

  return (
    <Dialog
      open={open}
      classes={{
        root: classes.dialogRoot
      }}
      maxWidth="lg"
      PaperProps={{
        style: {
          width: 1000
        }
      }}
    >
      <DialogTitle
        classes={{
          root: classes.dialogTitleRoot
        }}
      >
        {_.get(activeAsset, 'data.name') || activeAsset.name}
      </DialogTitle>
      <DialogContent classes={{root: classes.dialogContentRoot}}>
        <Grid container style={{width: '100%', display: 'block'}}>
          <Grid item className={classes.textEditorWrapper}>
            <RichTextEditor
              label={label}
              required
              value={value || ''}
              onChange={handleEditorValueChange}
              customClasses={{
                labelRoot: classes.inputLabelRoot
              }}
              useLatestTemplate={!value}
              uid={textEditorUid}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <PrimaryButton
          text='Speichern & fortfahren'
          onButtonClick={handleSave}
          disabled={_.isEmpty(value)}
        />
      </DialogActions>
    </Dialog>
  )
}

export default RiskScoreConfirmationModal