import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { MuiThemeProvider } from '@material-ui/core/styles';

import { AgencyResource } from '../../utils/api';
import {
  createDefaultTheme
} from '../../themes/themeConfigurator';
import {
  loadAgencyDataForStore,
  loadThemeForStore,
  setAgencyDataToStore,
  setThemeToStore
} from './actions';
import {createSelector} from "reselect";
import {getAuthSelector} from "../../utils/redaxSelectors";

const getCustomerTheme = (state) => state.get('customerThemeProvider')

export const getCustomerThemeSelector = createSelector(
  [getCustomerTheme],
  (customerTheme) => customerTheme.toJS()
)

const mapStateToProps = (state) => ({
  auth: getAuthSelector(state),
  customerThemeProvider: getCustomerThemeSelector(state)
});

const mapDispatchToProps = dispatch => ({
  loadThemeForStore: () => dispatch(loadThemeForStore()),
  loadAgencyDataForStore: () => dispatch(loadAgencyDataForStore()),
  setAgencyDataToStore: data => dispatch(setAgencyDataToStore(data)),
  setThemeToStore: data => dispatch(setThemeToStore(data))
});


const CustomerThemeProvider = ({ 
  auth, 
  children, 
  customerThemeProvider, 
  loadTheme,
  loadThemeForStore,
  loadAgencyDataForStore,
  setAgencyDataToStore,
  setThemeToStore,
}) => {

  React.useEffect(() => {
    const is_customer = !_.isNil(auth.user) && auth.user.is_customer;
    const customer_agency_id = auth.user && +auth.user.agency.agency_id;
    const theme_agency_id = customerThemeProvider.theme && +customerThemeProvider.theme.agency_id;
    const agency_data_agency_id = customerThemeProvider.agency_data && +customerThemeProvider.agency_data.agency_id;

    if (is_customer && loadTheme && (theme_agency_id !== customer_agency_id) && !customerThemeProvider.theme_loading) {
      fetchAgencyTheme(customer_agency_id);
    }

    const load_agency_data = is_customer
      && loadTheme
      && (agency_data_agency_id !== customer_agency_id)
      && !customerThemeProvider.agency_data_loading;
    if (load_agency_data) {
      fetchAgencyData(customer_agency_id);
    }

    return () => {};
  }, [JSON.stringify(auth.user)]);
  
  const fetchAgencyTheme = async (agency_id) => {
    loadThemeForStore();
    try {
      let response = await AgencyResource.at(`${agency_id}/theme/`).get();

      setThemeToStore({...response, agency_id});

    } catch (errors) {
      setThemeToStore({errors});
    }
  };

  const fetchAgencyData = async (agency_id) => {
    loadAgencyDataForStore();
    try {
      let response = await AgencyResource.at(`${agency_id}/`).get();
      setAgencyDataToStore({...response, agency_id});

    } catch (errors) {
      setAgencyDataToStore({});
    }
  };

  const theme = React.useMemo(() => {
    const color = customerThemeProvider.theme && customerThemeProvider.theme.color;
    return color ? createDefaultTheme({}, color) : createDefaultTheme();
  }, [customerThemeProvider.theme])

  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerThemeProvider);