import React from 'react';
import {_PerformanceLineChart} from "../../../../CustomerDashboard/components/Widgets/components/PerformanceLineChart";
import {connect} from "react-redux";
import {setProductsComparisonTimeSelectorDateChange} from "../../../actions";


const PerformanceChart = connect()(function ({data, errors, startDate, endDate, updatedAt, dispatch}) {
  let timeout = React.useRef(null);
  const onDatesChange = (dates) => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      dispatch(setProductsComparisonTimeSelectorDateChange(dates))
    }, 100)
  }

  if (!data) {
    return null
  }

  return (
    <_PerformanceLineChart
      chartData={{
        data: data.map((product) => {
          return {
            name: product.name,
            portfolio: product.portfolio,
            color: product.color
          }
        }),
        timestamp: updatedAt,
        error: errors
      }}
      onDatesChange={onDatesChange}
      dispatch={dispatch}
      startDate={startDate}
      endDate={endDate}
      classes={{}}
      withLegend={false}
    />
  )
})

export default PerformanceChart;