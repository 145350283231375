import {fontSizeTable, smFontSizeTable} from "../../../../../../utils/constants";

const styles = theme => ({
   root: {
     minHeight: 150,
     width: '100%'
   },
  tableCellVerticalAlignMiddle: {
     verticalAlign: 'middle !important'
  },
   tableAll: {
    width: '100%',
    borderCollapse: 'collapse',
    '& td': {
      padding: 5,
      verticalAlign: 'top',
      [theme.breakpoints.up('md')]: {
        '&:first-child': {
          paddingLeft: 15
        },
      },
      '& .indicator': {
        margin: '0 auto',
        width: 80, height: 18,
        borderTop: 'solid 1px #D8DCDF',
        borderBottom: 'solid 1px #D8DCDF',
      },
      '& .rank': {
        textAlign: 'center',
        marginTop: 5,
        fontSize: 11
      },
      '& .indicator div': {
        width: 16, height: 16,
        float: 'left',
        borderRight: 'solid 1px #D8DCDF'
      },
      '& .indicator div:first-child': {
        borderLeft: 'solid 1px #D8DCDF'
      },
      '& .indicator div.active:nth-child(1)': {
        backgroundColor: '#23D374',
      },
      '& .indicator div.active:nth-child(2)': {
        backgroundColor: '#7ED2CF',
      },
      '& .indicator div.active:nth-child(3)': {
        backgroundColor: '#FFED67',
      },
      '& .indicator div.active:nth-child(4)': {
        backgroundColor: '#FCAF3C',
      },
      '& .indicator div.active:nth-child(5)': {
        backgroundColor: '#F66418',
      },
    },
    '& tbody tr': {
      borderBottom: 'solid 1px #D8DCDF',
      color: '#202A38',
      '&:last-of-type': {
        borderBottom: 'none'
      },
      '& td': {
        fontSize: fontSizeTable,
        fontFamily: 'Roboto-Regular',
        borderRight: 'solid 1px #D8DCDF',
        [theme.breakpoints.down('sm')]: {
          fontSize: smFontSizeTable
        },
        '&:first-child': {
          maxWidth: 300,
          [theme.breakpoints.down('sm')]: {
            maxWidth: 150,
          }
        }
      },
      '& td:last-of-type': {
        borderRight: 'none',
      },
      '& td:first-of-type': {
        borderRight: 'none',
      },
      '& td div.sub': {
        fontSize: smFontSizeTable,
        fontFamily: 'Roboto-Regular',
        color: '#80858C',
        [theme.breakpoints.down('sm')]: {
          fontSize: smFontSizeTable - 2
        },
      }
    }
  },
  tableHeader: {
    '& td': {
      fontFamily: 'Roboto-Bold !important',
      fontSize: fontSizeTable,
      color: '#80858C',
      backgroundColor: '#F5F6F7',
      width: '12%',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: smFontSizeTable
      },
    },
    '& td:first-of-type': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 15,
      },
      textAlign: 'left',
      width: '25%'
    },
    '& td:nth-child(2)': {
      width: '15%',
      textAlign: 'left',
      paddingLeft: 0
    },
    '& td div.sub': {
      fontFamily: 'Roboto-Regular !important',
    }
  },
  assetName: {
    fontFamily: 'Roboto-Bold',
    color: `${theme.palette.primary.main} !important`,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      background: 'none',
      textDecoration: 'underline !important'
    },
    display: 'inline-block',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  leftAligned: {
    textAlign: 'left !important'
  },
  supText: {
     color: '#8D8D8D',
     fontSize: '12px',
     width: '100%',
     marginTop: 10,
    '& sup': {
       fontSize: 8,
    }
  },
  containerError: {
    height: 'calc(100% - 60px)',
  },
  emptyMessage: {
    margin: 'auto',
    fontSize: 22,
    color: '#b1b1b1',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  contentWrapper: {
    paddingTop: '0!important',
    height: '100%'
  }
})

export default styles;
