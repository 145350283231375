import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { Checkbox } from '@material-ui/core'

import {withPercentOrDash} from '../../../../../utils/utils'
import _ from "lodash";
import {InfoIcon} from "../../../../../images";
import {FilteringAcceptanceCriteriasModal} from "../../../components";
import {
  MDDCell,
  SharpeRatioCell,
  ReturnAuflage,
  ESGColumnU,
  ESGColumnS
} from "../../../components/InstrumentsList/table-data";
import {
  ALLOCATION_REGIONS,
} from "../../../../../utils/constants";
import {SRRIColumn, VaRCell, ESGColumn, PriceColumn, ReturnJahr, Return1J, Return3J, Return5J } from '../../../components/InstrumentsList/table-data'
import { getInstrName } from "../../../utils";
import {acceptanceCriteriasExists} from "../../../utils";
import {IsinCell, InstrumentNameCell, SustainabilityCell} from '../../../../../utils/commonTableColumns';


const CheckboxCell = {
  header: {
    content: (item, options) => {

      return (
        <div style={{position: 'relative', top: 5}}>
          <Checkbox
            onChange={(event, checked) => options.onAllSelect()}
            color="primary"
            style={{padding:0, marginRight: 8,}}
            checked={options.allSelected}
            disabled={options.loading}
          />
        </div>
      )
    }
  },
  body: {
    content: (item, options) => {

      const [acceptanceVisible, setAcceptanceVisibility] = React.useState(false)

      const handleShowAcceptanceCriteriasClick = () => {
        setAcceptanceVisibility(true)
      }

      const handleCloseAcceptanceCriterias = () => {
        setAcceptanceVisibility(false)
      }

      if (options.loading) {
        return <Skeleton />
      }

      if (!item.disabledByFilter) return (
        <div style={{position: 'relative', top: 5}}>
          <Checkbox onChange={(event, checked) => options.onInstrumentSelect(item)} color="primary" style={{padding:0, marginRight: 8,}} checked={options.selected && options.selected.find(asset => asset.isin == item.isin)}/>
        </div>
      )

      const instrumentName = getInstrName(item);

      if (acceptanceCriteriasExists(item)) return (
        <>
        <span style={{marginRight: 10, marginLeft: 4, position: 'relative', top: 2}}>
          <InfoIcon color="#80858c" onClick={handleShowAcceptanceCriteriasClick}/>
        </span>
          <FilteringAcceptanceCriteriasModal
            open={acceptanceVisible}
            onClose={handleCloseAcceptanceCriterias}
            instrumentName={instrumentName || item.isin}
            criterias={item.disabledByAcceptanceCriterias} />
        </>
      )
    }
  }
}

const OneYearReturnCell = {
  header: {
    content: () => (
      <>
        <span>Rendite</span> <br />
        <span>1Jahr</span>
      </>
    )
  },
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return withPercentOrDash(item.return_data && item.return_data.return_1y, false)
    }
  },
  key: 'OneYearReturnCell'
}

const ThreeYearReturnCell = {
  header: {
    content: () => (
      <>
        <span>Rendite</span> <br />
        <span>3Jahr</span>
      </>
    )
  },
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return withPercentOrDash(item.return_data && item.return_data.return_3y, false)
    }
  },
  key: 'ThreeYearReturnCell'
}

const VolatilityCell = {
  header: "Volatilität",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return withPercentOrDash(item.calculated && item.calculated.vola_3y)
    }
  },
  key: 'VolatilityCell'
}

export const ProductCategory = {
  header: 'Morningstar-Kategorie',
  headerCellClass: 'cell-extended',
  body: {
    className: 'normal-whitespace',
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      return (item.category_de || item.category) || '-'
    }
  },
  key: 'Produktgattung'
}

export const RegionCell = {
  header: 'Region',
  headerCellClass: 'cell-extended',
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }

      let region = ALLOCATION_REGIONS[item.largest_region_long_name];

      return (region ? region.name : item.largest_region_long_name) || '-'
    }
  },
  key: 'Region'
}

export const tableStructure = {
  default: [{
    content: [CheckboxCell]
  }, {
    content: [InstrumentNameCell, IsinCell]
  }, {
    content: [SustainabilityCell]
  }, {
    content: [SRRIColumn],
    align: 'right'
  }, {
    content: [VaRCell],
    align: 'right'
  }, {
    content: [ESGColumnU],
    align: 'right',
  }, {
    content: [ESGColumnS],
    align: 'right',
  }, {
    content: [PriceColumn],
    align: 'right'
  }, {
    content: [ReturnJahr],
    align: 'right'
  }, {
    content: [ReturnAuflage],
    align: 'right'
  }, {
    content: [Return1J],
    align: 'right'
  }, {
    content: [Return3J],
    align: 'right'
  }, {
    content: [Return5J],
    align: 'right'
  }, {
    content: [SharpeRatioCell],
    align: 'right'
  }, {
    content: [MDDCell],
    align: 'right'
  }]
}

export default tableStructure


export const searchTableStructure = {
  default: [{
    content: [InstrumentNameCell, IsinCell]
  }, {
    content: [ProductCategory],
    align: 'right'
  }, {
    content: [RegionCell],
    align: 'right'
  }, {
    content: [SustainabilityCell]
  }, {
    content: [SRRIColumn],
    align: 'right'
  }, {
    content: [ReturnJahr],
    align: 'right'
  }, {
    content: [Return1J],
    align: 'right'
  }, {
    content: [Return3J],
    align: 'right'
  }, {
    content: [Return5J],
    align: 'right'
  }, {
    content: [ReturnAuflage],
    align: 'right'
  }, {
    content: [VolatilityCell],
    align: 'right'
  }, {
    content: [MDDCell],
    align: 'right'
  }, {
    content: [SharpeRatioCell],
    align: 'right'
  }]
}

export const extendedSearchTableStructure = {
  default: [{
    content: [CheckboxCell]
  }, {
    content: [InstrumentNameCell, IsinCell],
    orderingFunction: (item) => (getInstrName(item) || '').toLowerCase()
  }, {
    content: [SustainabilityCell]
  }, {
    content: [SRRIColumn],
    align: 'right',
    orderingFunction: (item) => item.sri || 0
  }, {
    content: [VaRCell],
    align: 'right',
    orderingFunction: (item) => (item.calculated && item.calculated.var_95_3y) || 0
  }, {
    content: [ESGColumnU],
    align: 'right',
    orderingFunction: (item) => (item.esg_risk_score && item.esg_risk_score.historical_corporate_sustainability_score) || 0
  }, {
    content: [ESGColumnS],
    align: 'right',
    orderingFunction: (item) => (item.esg_risk_score && item.esg_risk_score.historical_sovereign_sustainability_score) || 0
  }, {
    content: [PriceColumn],
    align: 'right',
    orderingFunction: (item) => (item.calculated && item.calculated.last_price_value) || 0
  }, {
    content: [ReturnJahr],
    align: 'right',
    orderingFunction: (item) => (item.return_data && item.return_data.return_ytd) || 0
  }, {
    content: [ReturnAuflage],
    align: 'right',
    orderingFunction: (item) => (item.return_data && item.return_data.return_inception) || 0
  }, {
    content: [Return1J],
    align: 'right',
    orderingFunction: (item) => (item.return_data && item.return_data.return_1y) || 0
  }, {
    content: [Return3J],
    align: 'right',
    orderingFunction: (item) => (item.return_data && item.return_data.return_3y) || 0
  }, {
    content: [Return5J],
    align: 'right',
    orderingFunction: (item) => (item.return_data && item.return_data.return_5y) || 0
  }, {
    content: [SharpeRatioCell],
    align: 'right',
    orderingFunction: (item) => (item.calculated && item.calculated.sharpe_ratio_a_3y) || 0
  }, {
    content: [MDDCell],
    align: 'right',
    orderingFunction: (item) => (item.calculated && item.calculated.mdd_3y) || 0
  }]
}
