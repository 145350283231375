import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  startButton: {
    fontFamily: 'Roboto-Bold',
    color: 'white',
    textTransform: 'none',
    padding: '9px 16px',
    boxShadow: 'none',
    borderRadius: 0,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      height: 35,
    },
  },
})

export default makeStyles(styles)