const styles = theme => ({
  root: {
    minHeight: 150,
    width: '100%'
  },
  chart: {
    width: '100%',
  },
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& > div': {
      width: '100%'
    },
    flexDirection: 'column'
  },
  chartContainer: {
    marginTop: 20
  },
  buttonsPad: {
    display: 'block'
  },
  description: {
    fontSize: 12,
    width: '40%',
    marginLeft: 30,
    marginTop: 10,
    color: '#8D8D8D'
  }
})

export default styles;