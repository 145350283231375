export const SLIDER_MARKS = [
  {
    value: 1,
    label: '1'
  }, {
    value: 2,
    label: '2'
  }, {
    value: 3,
    label: '3'
  }, {
    value: 4,
    label: '4'
  }, {
    value: 5,
    label: '5'
  }, {
    value: 6,
    label: '6'
  }, {
    value: 7,
    label: '7'
  }
]

export const SELECT_OPTIONS = [
  {
    id: 0,
    name: '-'
  },
  {
    id: 1,
    name: 'SRRI'
  },
  /*{
    id: 2,
    name: 'VaR95 (%)'
  }*/
]


export const ASSET_CLASSES_OPTIONS_VALUES = {
  FONDS: 0,
  AKTIEN: 1,
  ANLEIHEN: 2
}

export const ASSET_CLASSES_OPTIONS = [
  {
    value: ASSET_CLASSES_OPTIONS_VALUES.FONDS,
    name: 'Fonds'
  }, {
    value: ASSET_CLASSES_OPTIONS_VALUES.AKTIEN,
    name: 'Aktien'
  }, {
    value: ASSET_CLASSES_OPTIONS_VALUES.ANLEIHEN,
    name: 'Anleihen'
  }, /*{
    value: 3,
    name: 'Zertifikate'
  }*/
]

export const CLIENT_TYPES = [
  {
    value: 0,
    name: 'Privat'
  }, {
    value: 1,
    name: 'Professionelle'
  }, {
    value: 2,
    name: 'Geeignete Gegenpartei'
  }, {
    value: 3,
    name: 'Alle'
  }
]

export const EXPERIENCE_TYPES = [
  {
    value: 0,
    name: 'Basis'
  }, {
    value: 1,
    name: 'Umfangreiche'
  }, {
    value: 2,
    name: 'Spezielle'
  }, {
    value: 3,
    name: 'Alle'
  }
]

export const LOSS_CAPACITY_TYPES = [
  {
    value: 0,
    name: 'Niedrig'
  }, {
    value: 1,
    name: 'Mittel'
  }, {
    value: 2,
    name: 'Hoch'
  }, {
    value: 3,
    name: 'Alle'
  }
]

export const INVESTMENT_HORIZON_TYPES = [
  {
    value: 0,
    name: 'Kurzfristig'
  }, {
    value: 1,
    name: 'Mittelfristing'
  }, {
    value: 2,
    name: 'Langfristig'
  }, {
    value: 3,
    name: 'Alle'
  }
]

export const TIME_DURATION_TYPES = [
  {
    id: 0,
    value: 0,
    name: 'YTD'
  }, {
    id: 1,
    value: 1,
    name: 'Verlauf über 1 Jahr'
  }, {
    id: 2,
    value: 2,
    name: 'Verlauf über 3 Jahre'
  }, {
    id: 3,
    value: 3,
    name: 'Verlauf über 5 Jahre'
  }, {
    id: 4,
    value: 4,
    name: 'Verlauf über 10 Jahre'
  }
]

export const INCOME_APPROPRIATION_OPTIONS = [
  {
    value: 1,
    name: 'Thesaurierend'
  },{
    value: 2,
    name:'Ausschüttend'
  },
  // BCA-5358
  // {
  //   value: 3,
  //   name:'Egal'
  // }
];

export const COMPATIBLE_ESG_OPTIONS = [
  {
    value: 1,
    id: 1,
    name: '1: Ja'
  },{
    value: 3,
    id: 3,
    name:'3: ESG basierend'
  },
  {
    value: 4,
    id: 4,
    name:'4: Nachhaltigkeit'
  }
];

export const ASSET_SEARCH_PLACEHOLDER = 'Bitte geben Sie mindestens drei Zeichen ein'