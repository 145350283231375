import React from 'react';
import clsx from "clsx";
/* BCA Components */
import PortfolioListDropdown from '../PortfolioListDropdown';

/* BCA modules */
import useStyles from './styles';
import WarningTooltip from "../../../../components/WarningTooltip";



export default (props) => {
  const classes = useStyles();

  const {
    portfolios,
    portfoliosLoadingError,
    portfoliosDataLoading,
    selectedPortfolios,
    handleSelectedPortfolioChanged,
    alwaysScrollableList,
    isVirtual,
    loading,
    stickyContainer,
    isListAutoUpdate,
    customClasses,
    disabled,
    onExpanded,
    placeholder,
    title,
    infoText,
    includeHistoricalPortfolios,
  } = props;

  const _disabled = disabled || loading || portfoliosDataLoading;

  return (
    <div 
      className={clsx(customClasses && customClasses.container || classes.container, disabled && classes.disabled)}
      id={stickyContainer ? "portfolio-list-dropdown-sticky" : "portfolio-list-dropdown"}
    >
      {!stickyContainer &&
        <div className={classes.labelContainer}>
          <span className={clsx(customClasses && customClasses.label || classes.label, _disabled && classes.labelDisabled)}>
            {title || 'Depotauswahl'}
            {infoText && (
              <WarningTooltip title={infoText}/>
            )}
          </span>
        </div>
      }

      <div className={
        customClasses && customClasses.expansionPannelContainer || classes.expansionPannelContainer
      }>
        <PortfolioListDropdown
          portfolios={portfolios}
          portfoliosLoadingError={portfoliosLoadingError}
          portfoliosDataLoading={portfoliosDataLoading}
          selectedPortfolios={selectedPortfolios}
          handleSelectedPortfolioChanged={handleSelectedPortfolioChanged}
          alwaysScrollableList={alwaysScrollableList}
          isVirtual={isVirtual}
          loading={loading}
          isListAutoUpdate={isListAutoUpdate}
          onExpanded={onExpanded}
          placeholder={placeholder}
          classes={{
            expansionPanelSummaryRoot: customClasses && customClasses.expansionPanelSummaryRoot,
            expansionPanelSummaryExpanded: customClasses && customClasses.expansionPanelSummaryExpanded,
            scrollableList: customClasses && customClasses.scrollableList,
          }}
          includeHistoricalPortfolios={includeHistoricalPortfolios}
        />
      </div>
    </div>
  )
}