import { smFontSize, xsFontSize, fontSize } from '../../../utils/constants';

const styles = theme => ({
  button: {
    borderRadius: 4,
    paddingTop: 4,
    paddingBottom: 4,

    [theme.breakpoints.only('sm')]: {
      paddingLeft: 8,
      paddingRight: 8
    },
  },
  noShadow: {
    boxShadow: 'none',

    '&:focus': {
      boxShadow: 'none',
    },
  },
  label: {
    fontSize: fontSize,
    [theme.breakpoints.only('sm')]: {
      fontSize: smFontSize,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: xsFontSize,
    },
  },
  text: {
    fontFamily: 'Roboto-Medium',
    textTransform: 'none',
    whiteSpace: 'nowrap',
  },
  icon: {
    '&:first-child': {
      marginRight: 8,
    },
    '&:last-child': {
      marginLeft: 8,
    },
    '&:not(.icon-text)': {
      fontSize: '1.25em',
      lineHeight: '1em',
    },

    '& > svg': {
      fontSize: '1em'
    },

    [theme.breakpoints.down('sm')]: {
      '&:first-child': {
        marginRight: 6,
      },
      '&:last-child': {
        marginLeft: 6,
      },
    },
  },

  hiddenText: {
    '& $text': {
      display: 'none'
    },
    '& $icon': {
      marginRight: 0
    }
  },
  outlined: {
    boxSizing: 'border-box',
    paddingTop: 3,  // as usual buttons do not have border
    paddingBottom: 3, // make smaller padding to have same height
  },
  outlinedPrimary: {
    borderColor: theme.palette.primary.main,
  }
});

export default styles;