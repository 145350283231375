import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    display: 'flex',
    '& button > span > span': {
      fontSize: '14px !important'
    }
  }
})

export default makeStyles(styles)