import React from "react";
import clsx from "clsx";
import {withRouter} from "react-router-dom";

import withStyles from '@material-ui/core/styles/withStyles';
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import SideMenuModal from '../../../SideMenuModal'

import styles from "./styles";

import useWindowSize from '../../../../../../components/WindowSizeHook'
import {Chip, makeStyles} from "@material-ui/core";
import {getAlertingNotificationsCount} from "../../../../../../components/DashboardDataProvider/DashboardDataProvider";
import {connect} from "react-redux";
import {getMenuItemBadge} from "../../SideMenu";


const useBadgeStyles = makeStyles(() => ({
  root: {
    background: 'white',
    color: '#202a38',
    fontSize: '.75rem',
    padding: '0 .375rem',
    height: 'auto',
    lineHeight: '1.25rem',
    maxHeight: '1.3125rem',
    fontWeight: '700!important',
    '& > span': {
      padding: 0
    }
  }
}))

export const Badge = ({value, style}) => {
  const classes = useBadgeStyles()

  return (
    <Chip label={value} className={classes.root} style={style || {}}/>
  )
}

const MenuSubItemChildItem = withStyles(styles)(props => {
  const {
    classes,
    active,
    item,
    onClick,
    badge
  } = props;

  const handleClick = (event) => {
    event.stopPropagation();
    return !active && onClick(item)
  }

  return (
    <div
      className={clsx(classes.childItem, active && classes.childItemActive)}
      key={item.id}
      onClick={handleClick}
      style={{justifyContent: 'space-between'}}
    >
      <span>{item.name}</span>
      {!!badge && (
        <Badge value={badge} style={{position: 'relative', left: 15}}/>
      )}
    </div>
  )
});

const mapStateToProps = (state) => ({
  alertingNotificationsCount: getAlertingNotificationsCount(state),
});

const MenuSubItemContent = connect(mapStateToProps)(withRouter(withStyles(styles)(props => {
  const {
    classes,
    items,
    onClick,
    match: {
      path
    },
    location: {
      pathname
    },
    parentTop,
    alertingNotificationsCount
  } = props;

  const isActive = (item) => {
    return item.internal && pathname.startsWith(path + item.full_path);
  }

  const calcSubItemsHeight = (items) => {
    const windowSize = useWindowSize(0);
    const itemHeight = 48;
    const styles = {};
    // set height value to max available,
    // closer to window bottom, according to items count
    if (itemHeight*items.length + parentTop >= windowSize.height) {
      const visibleChildren = parseInt((windowSize.height - parentTop) / itemHeight) - 1;
      if (visibleChildren){
        styles.height = `${itemHeight*visibleChildren}px`;
      }
    }

    return styles;
  }

  return (
    <div className={clsx(classes.childItemsContainer)} style={items && calcSubItemsHeight(items)}>
      {items && items.map(item => {

        if (item.visible) {
          return (
            <MenuSubItemChildItem
              key={item.id}
              onClick={onClick}
              active={isActive(item)}
              item={item}
              badge={getMenuItemBadge(item, alertingNotificationsCount)}
            />
          )}})
        }
    </div>
  )
})))

const MenuSubItem = props => {
  const {
    classes,
    item,
    active,
    onClick,
    badge
  } = props;

  const [visible, setVisible] = React.useState(false);

  const menuSubItemRef = React.useRef(null);
  const menuSubItemRefRect = menuSubItemRef.current && menuSubItemRef.current.getBoundingClientRect();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (event) => {
    event.stopPropagation();
    if(isMobile) setVisible(!visible); // set visibility onClick for mobile
    return !active && onClick(item);
  }

  const handleChildClick = (childItem) => {
    setVisible(false);
    return onClick(childItem);
  };

  const handleMouseEnter = () => {
    if(isMobile) return; // set visibility onClick for mobile
    setVisible(true);
  };
  const handleMouseLeave = () => setVisible(false);

  const top = menuSubItemRefRect && menuSubItemRefRect.top - menuSubItemRefRect.height - 23

  return item.visible ? (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div
        className={clsx(classes.container, active && classes.active, visible && classes.childrenVisible)}
        onClick={handleClick}
        ref={menuSubItemRef}
      >
        <span className={classes.name}>
          {item.name}
          {item.highlighted && (
            <Badge value={"NEU"} style={{color: '#fff', backgroundColor: '#e25443', marginLeft: 5, position: 'relative', top: -7}} />
          )}
        </span>

        {badge && (
          <Badge value={badge} />
        )}
        {item.children && (
          <span className={classes.icon}>
            <i className="fa fa-angle-right" aria-hidden="true" />
          </span>
        )}
      </div>
      {visible && (
        <SideMenuModal top={top} isMobile={isMobile}>
          <MenuSubItemContent parentTop={top} items={item.children} onClick={handleChildClick} />
        </SideMenuModal>
      )}
    </div>
  ) : null
};

export default withStyles(styles)(MenuSubItem);