import React from 'react';

function PrbEcosystem({}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.924"
      height="38.919"
      viewBox="0 0 38.924 38.919"
    >
      <path
        id="Pfad_7846"
        data-name="Pfad 7846"
        d="M37.783,9.27a5.708,5.708,0,0,0-5.7,5.7V20.95a5.713,5.713,0,0,0-6.312,1.2l-5.092,5.091v11.68H29.8V34.91l9.123-9.123V9.27Zm-1.14,15.572L27.52,33.965v2.673H22.959V28.184l4.423-4.423a3.425,3.425,0,0,1,3.894-.669l-4.186,4.186L28.7,28.889l5.935-5.935-.276-.276V14.972a3.427,3.427,0,0,1,2.281-3.226ZM9.123,20.478a5.692,5.692,0,0,0-2.281.473V14.972a5.708,5.708,0,0,0-5.7-5.7H0V25.787L9.123,34.91v4.009h9.123V27.239l-5.092-5.091a5.665,5.665,0,0,0-4.031-1.67m6.842,16.16H11.4V33.965L2.281,24.842v-13.1a3.427,3.427,0,0,1,2.281,3.226v7.706l-.276.276,5.935,5.935,1.613-1.612L7.648,23.091a3.424,3.424,0,0,1,3.894.669l4.424,4.423Zm3.5-14.792a1.188,1.188,0,0,1-.479-.1H18.97l-.053-.022a12.29,12.29,0,0,1-7.142-10.794,11.249,11.249,0,0,1,1.548-5.632l0,.005a.135.135,0,0,1,.025-.065l.018-.029.053-.058a13.3,13.3,0,0,1,5.5-5.016L18.955.11l.026,0A1.174,1.174,0,0,1,19.462,0a1.192,1.192,0,0,1,.479.1l.018,0,.058.027A12.751,12.751,0,0,1,26.8,8.218a.436.436,0,0,1,.022.09,10.784,10.784,0,0,1,.327,2.614,12.29,12.29,0,0,1-7.142,10.793l-.038.019-.025,0a1.177,1.177,0,0,1-.482.106m1.215-3.4a9.543,9.543,0,0,0,4.043-7.523c0-.179-.012-.357-.029-.538L20.677,12.7Zm-5.917-4.521a10.184,10.184,0,0,0,3.488,4.521V15.939Zm3.488-.792V9.467l-3.34-1.929a8.591,8.591,0,0,0-.7,3.261ZM20.677,9.9l3.465-2a10.049,10.049,0,0,0-3.465-4.5ZM18.247,6.661V3.4a10.718,10.718,0,0,0-2.09,2.054Z"
        fill="#fff"
      />
    </svg>
  )
}

PrbEcosystem.propTypes = {}

PrbEcosystem.propDefs = {}

PrbEcosystem.defaultProps = {}

export default PrbEcosystem;