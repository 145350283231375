import React from 'react';

import Snackbar from '../Snackbar';

import useStyles from './styles';

const withNotification = WrappedComponent => props => {
  const [isVisible, setVisibility] = React.useState(false);
  const [variant, setVariant] = React.useState('success');
  const [message, setMessage] = React.useState('sss');

  const displayNotification = (variant, message, onClose) => {
    setVariant(variant);
    setMessage(message);
    setVisibility(true);

    setTimeout(() => {
      handleNotificationClose();
      onClose && onClose();
    }, 4000)
  };

  const handleNotificationClose = () => {
    setVisibility(false);
  }

  return (
    <>
      <Snackbar
        open={isVisible}
        variant={variant}
        message={message}
        handleClose={handleNotificationClose}
      />
      <WrappedComponent displayNotification={displayNotification} {...props}/>
    </>
  )
}

export default withNotification;