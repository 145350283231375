import React from "react";
import {Grid, withStyles} from "@material-ui/core";
import styles from "./styles";
import ChartRiskReturn from "./items/RiskReturnChart";
import StressTestChart from "./items/StressTestChart";
import {parseResponse, VirtualPortfolioHandlerResource} from "../../../../utils/api";
import CorrelationMatrix from "./items/CorrelationMatrix";


export class RiskAnalysisTab extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            portfolioData: props.portfolioData,

            riskReturnData: undefined,
            riskReturnLoading: true,
            riskReturnError: null,

            portfolioRiskReturnData: undefined,
            portfolioRiskReturnLoading: true,
            portfolioRiskReturnError: null,

            correlationData: undefined,
            correlationLoading: true,
            correlationError: null,

            stressTestError: null,
            stressTestData: undefined,
            stressTestLoading: true,

            benchmarkDetails: [],
        }
        this.fetchRiskReturnData = this.fetchRiskReturnData.bind(this);
        this.fetchStressTestData = this.fetchStressTestData.bind(this);
    }

    componentDidMount() {
        this.fetchRiskReturnData()
        this.fetchStressTestData()
    }

    fetchStressTestData() {
      VirtualPortfolioHandlerResource.getStressTestData(this.props.customerId, this.props.portfolioId)
        .then((response) => {
          parseResponse(response, 'stress_test',
            (data) => {
              this.setState({
                stressTestError: undefined,
                stressTestData: data,
                stressTestLoading: false
              })
              this.setState({
                benchmarkDetails: response['benchmark'],
              })
            },
            (error) => {
              this.setState({
                stressTestData: undefined,
                stressTestLoading: false,
                stressTestError: error
              })
            })
        })
        .catch((error) => {
          this.setState({
            stressTestData: undefined,
            stressTestLoading: false,
            stressTestError: error,
          })
        })
    }

    fetchRiskReturnData() {
      VirtualPortfolioHandlerResource.getRiskReturn(this.props.customerId, this.props.portfolioId)
        .then((response) => {
          parseResponse(response, 'risk_return',
            (data) => {
              this.setState({
                riskReturnError: undefined,
                riskReturnData: data,
                riskReturnLoading: false
              })
            },
            (error) => {
              this.setState({
                riskReturnData: undefined,
                riskReturnLoading: false,
                riskReturnError: error
              })
            })

          parseResponse(response, 'correlation_matrix',
            (data) => {
              this.setState({
                correlationError: undefined,
                correlationData: data,
                correlationLoading: false
              })
            },
            (error) => {
              this.setState({
                correlationData: undefined,
                correlationLoading: false,
                correlationError: error
              })
            })
        })
        .catch((error) => {
          this.setState({
            riskReturnData: undefined,
            riskReturnLoading: false,
            riskReturnError: error,
            correlationData: undefined,
            correlationLoading: false,
            correlationError: error
          })
        })

      VirtualPortfolioHandlerResource.getPortfolioRiskReturn(this.props.customerId, this.props.portfolioId)
        .then((response) => {
          parseResponse(response, 'pf_risk_return',
            (data) => {
              this.setState({
                portfolioRiskReturnData: data,
                portfolioRiskReturnLoading: false,
                portfolioRiskReturnError: undefined,
              })
            },
            (error) => {
              this.setState({
                portfolioRiskReturnData: undefined,
                portfolioRiskReturnLoading: false,
                portfolioRiskReturnError: error,
              })
            }
          )
        })
        .catch((error) => {
          this.setState({
            portfolioRiskReturnData: undefined,
            portfolioRiskReturnLoading: false,
            portfolioRiskReturnError: error,
          })
        })
    }

    render() {
        return <Grid container spacing={2}>
            <Grid item xs={12}>
               <ChartRiskReturn
                  portfolioData={this.props.portfolioData}
                  portfolioLoading={this.props.portfolioLoading}
                  portfolioError={this.props.portfolioError}
                  portfolioRiskReturnData={this.state.portfolioRiskReturnData}
                  portfolioRiskReturnLoading={this.state.portfolioRiskReturnLoading}
                  portfolioRiskReturnError={this.state.portfolioRiskReturnError}
                  data={this.state.riskReturnData}
                  loading={this.state.riskReturnLoading}
                  error={this.state.riskReturnError}
                />
            </Grid>
            <Grid item xs={12}>
               <StressTestChart
                  data={this.state.stressTestData}
                  loading={this.state.stressTestLoading}
                  error={this.state.stressTestError}
                  benchmarkDetails={this.state.benchmarkDetails}
                  portfolioName={this.props.portfolioName}
                />
            </Grid>
            <Grid item xs={12}>
              <CorrelationMatrix
                portfolioData={this.props.portfolioData}
                portfolioLoading={this.props.portfolioLoading}
                portfolioError={this.props.portfolioError}
                data={this.state.correlationData}
                loading={this.state.correlationLoading}
                error={this.state.correlationError}
                title={this.props.correlationMatrixTitle}
              />
            </Grid>
        </Grid>
    }
}

export default withStyles(styles)(RiskAnalysisTab);
