import React from "react";
import clsx from "clsx";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";

import Grid from "@material-ui/core/Grid/Grid";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";

import UserInfo from "../../../../components/ProfileDetails/components/UserInfo";

import useStyles from "./styles";
import useListStyles from "../../../../components/CustomerList/styles";
import {PrimaryButton} from "../../../../components/Buttons";

const CustomerListItem = (props) => {
  const classes = useStyles();
  const listClasses = useListStyles();

  const {
    customer,
    dispatch,
    history,
    loading,
    buttonTitle,
    onButtonClick
  } = props;

  return (
    <Grid item className={listClasses.listItemContainer}>
      <Card className={listClasses.listItemCard}>
        <CardContent className={listClasses.cardContent}>
          <Grid container>
            <Grid
              item
              md={8} sm={12} xs={12}
              className={clsx(listClasses.cardItem, classes.cardItem)}
              id="reporting-client-info-tour-element"
            >
              <div className={clsx(loading && 'loading-line')}>
                <UserInfo
                  customer={customer}
                  group
                />
              </div>
            </Grid>
            <Grid
              item
              md={4} sm={6} xs={12}
              className={clsx(listClasses.cardItem, classes.cardItem, classes.buttonWrapper)}
            >
              <div className={clsx(loading && 'loading-line')}>
                <PrimaryButton
                  id="reporting-customer-open-editor-btn-tour-element"
                  text={buttonTitle}
                  onButtonClick={() => onButtonClick && onButtonClick(customer.customer_id)}
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>

  );
};

export default connect()(withRouter(CustomerListItem));