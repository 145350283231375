import {makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    '& label': {
      top: -24
    },
    '& [class*="SingleFileUploadInput-fileContainer"]': {
      top: -24
    }
  },
}))