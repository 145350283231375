import { makeStyles } from '@material-ui/core';


export default makeStyles(theme => ({
  chartContainer: {
    height: 400,
    display: 'flex',
    alignItems: 'center',

    '& div[data-highcharts-chart]':{
      maxHeight: '100%!important',
    },

    [theme.breakpoints.only('md')]: {
      height: 300
    },

    [theme.breakpoints.only('sm')]: {
      height: 500
    },

    [theme.breakpoints.only('xs')]: {
      height: 280
    },
  },
  title: {
    color: '#4D4F5C',
    fontSize: 20,
    fontFamily: 'Roboto-Regular',
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    }
  },
  titleWithoutData: {
    color: '#4D4F5C',
    fontSize: 20,
    fontFamily: 'Roboto-Regular',
    marginBottom: 50,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    }
  },
  subtitle: {
    color: '#8D8D8D!important',
    fontSize: '14px!important',
    fontFamily: 'Roboto-Regular!important',
    marginTop: '0.5em',
    position: "absolute",
    [theme.breakpoints.down('md')]: {
      fontSize: '12px!important',
    }
  }

}))
