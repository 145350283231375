import React from 'react';

const MinusBackgroundIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <rect style={{fill: '#cce9fa'}} width="36" height="36" rx="18"/>
      <g transform="translate(17.607 7) rotate(45)">
        <path style={{fill:'#0092e5'}} d="M0,13.636,13.636,0,15,1.364,1.364,15Z" transform="translate(0 0)"/>
      </g>
    </svg>
  );
};

export default MinusBackgroundIcon;