import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  wrapper: {
    boxShadow: "0px 3px 15px #00000033",
    borderRadius: "5px",
    padding: "50px",
  },
  container: {
    width: "100%",
    padding: "0px",
  },
  title: {
    fontFamily: 'Roboto-Bold',
    fontSize: "18px",
    color: "#202A38",
  },
  description: {
    fontSize: "16px",
    marginBottom: 30,
    color: "#0D2440",
  },
  aspects: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "100%",
    gap: "10px",
    marginTop: "30px",
  },
  item: {
    maxWidth: "49%",
    width: "100%",
    display: "flex",
    border: "1px solid #D8DCDF",
    alignItems: "center",
    padding: "15px",
    borderRadius: "5px",
    cursor: "pointer",
    flex: "1",
    fontSize: "14px",
    color: "#4C4D4D",
    fontFamily: 'Roboto-Bold',
    minWidth: "48%",
  },
  selected: {
    backgroundColor: "#0092E5",
    color: "#fff",
  },
  icon: {
    width: "26px",
    marginRight: "15px"
  },
  errorContainer: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16
  },
}));
