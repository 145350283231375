import React from "react";
import _ from "lodash";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { withStyles, Typography, Button } from "@material-ui/core";

import { CurrencyNumberFormat } from "../../../../Filters";
import FiltersSection from "../FiltersSection";
import {
  FilterSection,
  FilterSubSection,
  useAssetInfoStaticContext,
} from "../../ExtendedFilters";
import { DropdownInput } from "../../../../../components";
import styles from "../FiltersSection/styles";
import Grid from "@material-ui/core/Grid";
import {
  RadioButtonsSection,
  Select,
} from "../../../../../../../components/Inputs";
import {
  INCOME_APPROPRIATION_OPTIONS,
  SLIDER_MARKS,
  TIME_DURATION_TYPES,
} from "../../constants";
import SwitchInput from "../../../../../../../components/Inputs/Switch";
import Steps from "../../../Steps";
import MultipleSelectInput from "../../../MultipleSelectInput";
import { ASSET_SEARCH_PLACEHOLDER } from "../../constants";
import RatingRangeStars from "../../../../../../../components/RatingRangeStars";
import SliderRangeSelect from "../../../../../../../components/SliderRangeSelect";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import clsx from "clsx";
import {getFromStorage, SHARED_SETTINGS_KEY} from "../../../../../../../utils/storage";
import { SearchIcon } from '../../../../../../../images';
import { buildSelectOptions } from '../../../../../../TransactionsMonitoring/utils';

const MIN_SEARCH_INPUT_LENGTH = 3;
const MIN_SELECT_OPTIONS_LENGTH = 20;

const boxesData = [
  {
    category: "is_ecologically_sustainable",
    title: "Ökologisch nachhaltig",
    values: "is_ecologically_sustainable_values",
    boxes: [
      {
        title: "Klimaschutz",
        value: 'c_mitigation',
        uid: 1
      },
      {
        title: "Anpassung an den Klimawandel",
        value: 'c_adaptation',
        uid: 2
      },
      {
        title:
          "Nachhaltige Nutzung und Schutz von Wasser- und Meeresressourcen",
        value: 'c_pwm_resources',
        uid: 3,
      },
      {
        title: "Übergang zu einer Kreislaufwirtschaft",
        value: 'c_economy',
        uid: 4
      },
      {
        title: "Vermeidung und Verminderung der Umweltverschmutzung",
        value: 'c_pollution',
        uid: 5,
      },
      {
        title:
          "Schutz und Wiederherstellung der Biodiversität und der Ökosysteme",
        value: 'c_prb_ecosystems',
        uid: 6,
      },
    ],
  },
  {
    category: "is_esg",
    title: "ESG",
    values: "is_esg_values",
    boxes: [
      {
        title: "Ökologische Kriterien",
        value: 'c_environmental_thematic',
        uid: 1,
        tooltip:
          "E= Investition in eine wirtschaftliche Tätigkeit, die zur Erreichung eines Umweltziels beiträgt, gemessen beispielsweise an Schlüsselindikatoren für Ressourceneffizienz bei der Nutzung von Energie, erneuerbarer Energie, Rohstoffen, Wasser und Boden, für die Abfallerzeugung, und Treibhausgasemissionen oder für die Auswirkungen auf die biologische Vielfalt und die Kreislaufwirtschaft",
      },
      {
        title: "Soziale Kriterien",
        value: 'c_social_thematic',
        uid: 2,
        tooltip:
          "S= Investition in eine wirtschaftliche Tätigkeit, die zur Erreichung eines sozialen Ziels beiträgt, insbesondere eine Investition, die zur Bekämpfung von Ungleichheiten beiträgt oder den sozialen Zusammenhalt, die soziale Integration und die Arbeitsbeziehungen fördert",
      },
      {
        title: "Verfahrensweisen einer guten Unternehmensführung",
        value: 'c_governance_thematic',
        uid: 3,
        tooltip:
          "G= Investition in Humankapital oder zugunsten wirtschaftlich oder sozial benachteiligter Bevölkerungsgruppen, vorausgesetzt, dass diese Investitionen keines dieser Ziele erheblich beeinträchtigen und die Unternehmen, in die investiert wird, Verfahrensweisen einer guten Unternehmensführung anwenden, insbesondere bei soliden Managementstrukturen, den Beziehungen zu den Arbeitnehmern, der Vergütung von Mitarbeitern sowie der Einhaltung der Steuervorschriften",
      },
    ],
  },
  {
    category: "is_pai",
    title: "PAI",
    values: "is_pai_values",
    boxes: [
      {
        title: "Treibhausgas-Emissionen",
        value: 'c_ecin_l',
        uid: 1,
      },
      {
        title: "Biodiversität",
        value: 'c_biodiversity',
        uid: 2,
      },
      {
        title: "Wasser",
        value: 'c_water_emissions',
        uid: 3,
      },
      {
        title: "Abfall",
        value: 'c_hazardous_waste_ratio',
        uid: 4,
      },
      {
        title:
          "Soziale Themen und Arbeitnehmerbelange, Korruption und Menschenrechte",
        value: 'c_contro_weapons',
        uid: 5,
      },
      {
        title:
          "Indikatoren bei Investments in staatliche und supranationale Emittenten",
        value: 'c_perc_ocsts_violations',
        uid: 6,
      },
      {
        title: "Indikatoren bei Investments in Immobilienvermögen",
        value: 'c_eei_real_estate_assets',
        uid: 7,
      },
    ],
  },
  {
    category: "is_sdg",
    title: "SDG",
    values: "is_sdg_values",
    boxes: [
      {
        title: "Sicherung der Grundbedürfnisse",
        value: 'c_sdg_sec_basic_needs',
        uid: 1,
        tooltip:
          "Leisten einen Beitrag gegen Armut und Hunger, für gute Gesundheitsversorgung und Wohlbefinden sowie für sauberes Wasser und gute Hygiene.",
      },
      {
        title: "Menschliche Entwicklung",
        value: 'c_sdg_dev_prosperity',
        uid: 2,
        tooltip:
          "Leisten einen Beitrag für qualitativ gute Ausbildung, Geschlechtergleichheit, für gerechte Arbeitsverhältnisse und ökonomisches Wachstum, für gute Industrie, Innovation und Infrastruktur, für reduzierte Ungleichheit sowie für bezahlbare und saubere Energie.",
      },
      {
        title: "Klimaschutz",
        value: 'c_sdg_climate_protection',
        uid: 3,
        tooltip:
          "Leisten einen Beitrag zur Begrenzung des Ausstosses schädlicher Klimagase.",
      },
      {
        title: "Intakte Ökosysteme",
        value: 'c_sdg_eco',
        uid: 4,
        tooltip:
          "Leisten einen Betrag zum Erhalt und dem Schutz des Lebens der Meere, sowie dem Erhalt und Schutz von Flora, Fauna und der Tierwelt auf dem Lande.",
      },
      // {
      //   title: "Sicherung natürlicher Ressourcen",
      //   value: 5,
      //   uid: 5,
      //   tooltip:
      //     "Leisten einen Beitrag für verantwortlichen Konsum und nachhaltige Produktion.",
      // },
      // {
      //   title: "Gute Regierung und Zusammenarbeit",
      //   value: 6,
      //   uid: 6,
      //   tooltip:
      //     "Leisten einen Beitrag für nachhaltige Städte und sozial und ökologisch einträgliches Zusammenleben in Frieden, Gerechtigkeit mit starken Institutionen und treten gemeinsam partnerschaftlich für diese Ziele ein.",
      // },
    ],
  },
  {
    category: "is_exclusion_criteria",
    title: "Ausschlusskriterien",
    values: "is_exclusion_criteria_values",
    invert: true,
    boxes: [
      {
        title: "Waffen und Rüstungsproduktion",
        value: 'c_weapons_excl',
        uid: 1,
        tooltip:
          "Dieses Kriterium umfasst im einzelnen die Herstellung umstrittener Waffen, von Rüstungsgütern allgemein, von militärischen und zivilen Kleinwaffen, sowie die Produktion von Gütern zur Aufstandsbekämpfung.",
      },
      {
        title: "Ökologisch und sozial schädliche Geschäftsmodelle",
        value: 'c_eco_soc_harmful_excl',
        uid: 2,
        tooltip:
          "Unter dieses Kriterium sind Anbieter sittenwidriger Kreditgeschäfte, Institutionen welche Tierversuche durchführen, Firmen welche sich der Pelz- und Ledergewinnung widmen, sowie Produzenten und Anbietern von Walfleisch gefasst.",
      },
      {
        title: "Ethisch oder religiös anstößige Geschäftsmodelle",
        value: 'c_ethic_religious_excl',
        uid: 3,
        tooltip:
          "Unter diese Gruppe sind die Untergruppen Erwachsenenunterhaltung, Erzeugung und Verarbeitung von Schweinefleisch, sowie Anbieter von Glücksspielprodukten gefasst.",
      },
      {
        title:
          "Ökologisch bedenkliche Förderung sowie Einsatz von Energieträgern",
        value: 'c_energy_sources_excl',
        uid: 4,
        tooltip:
          "In dieser Gruppe finden sich Hersteller von Atomenergieanlagen und Produzenten von Atomenergie, Produzenten von Endenergie oder Energieträgern aus der thermalen Verwertung von Kohle und Herstellern von Anlagen welche diesen Zwecken dienen, Produzenten der Öl- und Gasförderung und Herstellern von Öl- und Gasförderanlagen, sowie Transportsystemen für Öl und Gas, Firmen welche sich der Förderung und Abscheidung von Öl aus Ölsand widmen, Firmen welche in der arktischen Rohstoffexploration tätig sind sowie Produzenten Schiefergas (Fracking).",
      },
      {
        title: "Umweltschädigende Praktiken der Agrarproduktion",
        value: 'c_harm_agro_excl',
        uid: 5,
        tooltip:
          "Hierunter sind Firmen und Institutionen gefasst welche sich der Erzeugung und Züchtung sowie dem Einsatz genetisch modifizierter Pflanzen und Samen widmen und Hersteller von Palmöl, sowie Hersteller und Verwender von Pestiziden.",
      },
      {
        title: "Erzeugung von gesundheitsschädlichen Gütern",
        value: 'c_harm_goods_excl',
        uid: 6,
        tooltip:
          "Als gesundheitsschädliche Güter werden Tabak, alkoholische Getränke, Schwangerschaftsabbrüche und Cannabisprodukte angesehen. Firmen welche sich mit der Herstellung und dem Angebot dieser Produkte befassen werden mit dieser Gruppe ausgeschlossen.",
      },
      {
        title: "Normverletzungen",
        value: 'c_norm_violations_excl',
        uid: 7,
        tooltip:
          "Länder bzw. Firmen mit Sitz in Ländern, welche sich nicht an international kodifizierte Regeln der UNGC, OECD oder ILO halten, werden mit diesem Kriterium ausgeschlossen.",
      },
      {
        title: "Ausschlusskriterien auf der Ebene hoheitlicher Körperschaften",
        value: 'c_sovereign_bodies_excl',
        uid: 8,
        tooltip:
          "Hoheitliche Körperschaften, welche eines der folgenden Merkmale erfüllen, werden ausgeschlossen: Verfügen über einsatzbereite Atomwaffen, keine Teilnahme an Abkommen zur Nichtweiterverbreitung von Atomwaffen, hohe Korruption, kein Verbot der Todesstrafe, keine Zusage Krieg als außenpolitisches Instrument auszuschließen.",
      },
    ],
  },
];

export const AssetInfoStaticMultilineSelectInput = ({
  assetInfoFieldName,
  onChange,
  ...props
}) => {
  const [inputText, setInputText] = React.useState("");
  const setInputTextDelayed = _.debounce((value) => setInputText(value), 500);
  const assetInfoStatic = useAssetInfoStaticContext();

  const letMinLength = props.inputMinLength || MIN_SEARCH_INPUT_LENGTH;

  // TODO: Refactor functionality to logic with triggering change after input length > 3
  const handleInputChange = (value) => {
    if (value && (value.length >= letMinLength || !value.length)) {
      setInputTextDelayed(value);
    } else if (value.length < letMinLength) {
      setInputTextDelayed("");
    }
  };

  const _optionValid = React.useCallback((option, searchText) => {
    if (props.searchFn) {
      return props.searchFn(option, searchText);
    } else {
      return (option.german || option.value || "")
        .toLowerCase()
        .includes(searchText.toLowerCase());
    }
  }, []);

  const optionsAll = _.get(assetInfoStatic.data, assetInfoFieldName) || [];

  const assetInfoData = React.useMemo(() => {
    if (assetInfoStatic.loading || assetInfoStatic.errors) {
      return {
        loading: assetInfoStatic.loading,
        errors: assetInfoStatic.errors,
        options: [],
      };
    }

    const inputSearchTriggered = inputText && inputText.length >= letMinLength;

    let optionsPrepared =
      (optionsAll.length <= MIN_SELECT_OPTIONS_LENGTH &&
        !inputSearchTriggered &&
        optionsAll) ||
      (inputSearchTriggered &&
        _.filter(optionsAll, (option) => _optionValid(option, inputText))) ||
      [];
    optionsPrepared = _.sortBy(
      optionsPrepared,
      (option) => option.order || option.german || option.value || ""
    );

    return {
      loading: assetInfoStatic.loading,
      errors: assetInfoStatic.errors,
      options: buildSelectOptions(optionsPrepared),
    };
  }, [assetInfoStatic.updatedAt, inputText]);

  const onChangeProxy = (value) => {
    let values = [];
    value.forEach(function(v) {
      if (optionsAll.find((o) => o.value === v.value)) {
        values.push(v);
      }
    });

    return onChange(values);
  };

  return (
    <MultipleSelectInput
      onInputChange={handleInputChange}
      DropdownContentProps={{
        loading: assetInfoData.loading,
        errors: assetInfoData.errors,
        data: assetInfoData.options,
      }}
      onChange={onChangeProxy}
      {...props}
    />
  );
};

class MyComponent extends FiltersSection {
  componentDidMount() {
    super.componentDidMount();

    let sharedStorage = getFromStorage(SHARED_SETTINGS_KEY) || {};
    this.isESGFilterEnabled = !!(_.get(sharedStorage, 'sub_systems.esg.config.expert_filter_enabled'));

    if(!this.esgFiltersGetFromStorage()) {
      this.setState({
        esgRestoreBtnDisabled: true,
        esgCleanBtnDisabled: true,
      })
    }
  }

  VALIDATION_RULES = {
    category: {
      minLength: 3,
    },
  };

  DEFAULT_VALUES = {
    name: {
      value: "",
      section: "basic",
    },

    managementFeeStart: {
      value: "",
      section: "cost",
    },
    managementFeeEnd: {
      value: "",
      section: "cost",
    },
    managementEntryCostStart: {
      value: "",
      section: "",
    },
    managementEntryCostEnd: {
      value: "",
      section: "",
    },
    incomeAppropriation: {
      value: "",
      section: "basic",
    },
    sustainable: {
      value: false,
      section: "",
    },
    return_profile_compatible_esg: {
      value: "",
      section: "basic",
    },
    etf: {
      value: false,
      section: "basic",
    },
    is_institutional_share_class: {
      value: false,
      section: "basic",
    },
    cSavingPlanPossible: {
      value: false,
      section: "cost",
    },
    cPortfolioPossible: {
      value: false,
      section: "cost",
    },
    cVlPossible: {
      value: false,
      section: "cost",
    },
    cMinAmountSavingPlan: {
      value: "",
      section: "cost",
    },
    cMinAmountSinglePayment: {
      value: "",
      section: "cost",
    },
    srriStart: {
      value: 1,
      section: "basic",
    },
    srriEnd: {
      value: 7,
      section: "basic",
    },
    currency: {
      value: [],
      section: "structure",
    },
    cCustodian: {
      value: [],
      section: "cost",
    },
    investmentCompany: {
      value: [],
      section: "basic",
    },
    category: {
      value: "",
      section: "structure",
    },
    ms_category: {
      value: [],
      section: "structure",
    },
    broad_asset_class: {
      value: [],
      section: "structure",
    },
    region: {
      value: [],
      section: "structure",
    },
    branch: {
      value: [],
      section: "structure",
    },

    fundStarRatingOveralStart: {
      value: "",
      section: "basic",
    },
    fundStarRatingOveralEnd: {
      value: "",
      section: "basic",
    },
    productFeesPurchaseFrontLoadMin: {
      value: "",
      section: "cost",
    },
    productFeesPurchaseFrontLoadMax: {
      value: "",
      section: "cost",
    },
    productFeesOngoingCostActualMin: {
      value: "",
      section: "cost",
    },
    productFeesOngoingCostActualMax: {
      value: "",
      section: "cost",
    },
    cumulativeReturnType: {
      value: '',
      section: 'numbers'
    },
    cumulativeReturnStart: {
      value: "",
      section: "numbers",
    },
    cumulativeReturnEnd: {
      value: "",
      section: "numbers",
    },
    sharpeRatioType:{
      value: '',
      section: 'numbers'
    },
    sharpeRatioStart: {
      value: "",
      section: "numbers",
    },
    sharpeRatioEnd: {
      value: "",
      section: "numbers",
    },
    mddType:{
      value: '',
      section: 'numbers'
    },
    mddStart: {
      value: "",
      section: "numbers",
    },
    mddEnd: {
      value: "",
      section: "numbers",
    },
    volaType: {
      value: '',
      section: 'numbers'
    },
    volaStart: {
      value: "",
      section: "numbers",
    },
    volaEnd: {
      value: "",
      section: "numbers",
    },
    dividendYieldStart: {
      value: "",
      section: "numbers",
    },
    dividendYieldEnd: {
      value: "",
      section: "numbers",
    },
    var95Type:{
      value: '',
      section: 'numbers'
    },
    var95Start: {
      value: "",
      section: "numbers",
    },
    var95End: {
      value: "",
      section: "numbers",
    },
    is_ecologically_sustainable: { value: false, section: "sustainability" },
    is_esg: { value: false, section: "sustainability" },
    is_pai: { value: false, section: "sustainability" },
    is_sdg: { value: false, section: "sustainability" },
    is_exclusion_criteria: { value: false, section: "sustainability" },

    is_ecologically_sustainable_values: { value: [], section: "sustainability" },
    is_esg_values: { value: [], section: "sustainability" },
    is_pai_values: { value: [], section: "sustainability" },
    is_sdg_values: { value: [], section: "sustainability" },
    is_exclusion_criteria_values: { value: [], section: "sustainability" },

    is_artikel_8: { value: false, section: "sustainability" },
    is_artikel_9: { value: false, section: "sustainability" },
    is_apply_criteria_together: { value: false, section: "sustainability" },
    esgRatingStart: { value: "", section: "sustainability" },
    esgRatingEnd: { value: "", section: "sustainability" },
  };

  state = {
    name: "",

    managementFeeStart: "",
    managementFeeEnd: "",
    managementEntryCostStart: "",
    managementEntryCostEnd: "",
    incomeAppropriation: "",
    sustainable: true,
    return_profile_compatible_esg: "",
    etf: false,
    is_institutional_share_class: false,
    cSavingPlanPossible: false,
    cPortfolioPossible: false,
    cVlPossible: false,
    cMinAmountSavingPlan: "",
    cMinAmountSinglePayment: "",
    srriStart: 1,
    srriEnd: 7,
    currency: [],
    cCustodian: [],
    investmentCompany: [],
    category: "",
    ms_category: [],
    broad_asset_class: [],
    region: [],
    branch: [],

    fundStarRatingOveralStart: '',
    fundStarRatingOveralEnd: '',
    productFeesPurchaseFrontLoadMin: '',
    productFeesPurchaseFrontLoadMax: '',
    productFeesOngoingCostActualMin: '',
    productFeesOngoingCostActualMax: '',
    cumulativeReturnType: '',
    cumulativeReturnStart: '',
    cumulativeReturnEnd: '',
    sharpeRatioType: '',
    sharpeRatioStart: '',
    sharpeRatioEnd: '',
    mddType:'',
    mddStart: '',
    mddEnd: '',
    volaType:'',
    volaStart: '',
    volaEnd: '',
    dividendYieldStart: '',
    dividendYieldEnd: '',
    var95Type:'',
    var95Start: '',
    var95End: '',
    predefinedFields: [],
    is_ecologically_sustainable: false,
    is_esg: false,
    is_pai: false,
    is_sdg: false,
    is_exclusion_criteria: false,
    is_artikel_8: false,
    is_artikel_9: false,
    is_apply_criteria_together: false,
    esgRatingStart: "",
    esgRatingEnd: "",
    is_ecologically_sustainable_values: [],
    is_esg_values: [],
    is_pai_values: [],
    is_sdg_values: [],
    is_exclusion_criteria_values: [],

    esgSaveBtnActive: false,

    esgSaveBtnDisabled: true,
    esgRestoreBtnDisabled: false,
    esgCleanBtnDisabled: false,
  };

  valuesToSave = [
      'is_ecologically_sustainable', 'is_esg', 'is_pai', 'is_sdg', 'is_exclusion_criteria',
      'is_artikel_8', 'is_artikel_9',
      'esgRatingStart','esgRatingEnd',
      'is_ecologically_sustainable_values', 'is_esg_values', 'is_pai_values', 'is_sdg_values',
      'is_exclusion_criteria_values', 'is_apply_criteria_together'
  ];

  _buildFilters = () => {
    let filters = {};

    filters.name = this.state.name || null;
    filters.srri_start = this.state.srriStart || null;
    filters.srri_end = this.state.srriEnd || null;

    filters.fund_star_rating_start = this.state.fundStarRatingOveralStart;
    filters.fund_star_rating_end = this.state.fundStarRatingOveralEnd;
    filters.product_fees_purchase_front_load_min = this.state.productFeesPurchaseFrontLoadMin;
    filters.product_fees_purchase_front_load_max = this.state.productFeesPurchaseFrontLoadMax;
    filters.product_fees_ongoing_cost_actual_min = this.state.productFeesOngoingCostActualMin;
    filters.product_fees_ongoing_cost_actual_max = this.state.productFeesOngoingCostActualMax;
    filters.management_fee_start = this.state.managementFeeStart;
    filters.management_fee_end = this.state.managementFeeEnd;
    // filters.entry_cost_start = this.state.managementEntryCostStart
    // filters.entry_cost_end = this.state.managementEntryCostEnd
    filters.category = this.state.category;
    filters.ms_category = this.state.ms_category.map(
      (category) => category.value
    );
    filters.broad_asset_class = this.state.broad_asset_class.map((group) => group.value);
    filters.region = this.state.region.map((region) => region.value);
    filters.branch = this.state.branch.map((branch) => branch.value);
    filters.currency = this.state.currency.map((currency) => currency.value);

    filters.c_portfolio_possible = this.state.cPortfolioPossible;
    filters.c_vl_possible = this.state.cVlPossible;
    filters.c_savings_plan_possible = this.state.cSavingPlanPossible;
    filters.c_min_amount_single_payment = this.state.cMinAmountSinglePayment;
    filters.c_min_amount_savings_plan = this.state.cMinAmountSavingPlan;
    filters.etf = this.state.etf;
    filters.is_institutional_share_class = this.state.is_institutional_share_class;
    filters.return_profile_compatible_esg = this.state.return_profile_compatible_esg;
    filters.income_distribution = this.state.incomeAppropriation;
    filters.c_company_id = this.state.cCustodian.map(
      (custodian) => custodian.value
    );
    filters.investment_company = this.state.investmentCompany.map(
      (iCompany) => iCompany.value
    );

    filters.cumulative_return_type = this.state.cumulativeReturnType;
    filters.cumulative_return_start = this.state.cumulativeReturnStart;
    filters.cumulative_return_end = this.state.cumulativeReturnEnd;

    filters.sharpe_ratio_type = this.state.sharpeRatioType;
    filters.sharpe_ratio_start = this.state.sharpeRatioStart;
    filters.sharpe_ratio_end = this.state.sharpeRatioEnd;

    filters.mdd_type = this.state.mddType;
    filters.mdd_start = this.state.mddStart;
    filters.mdd_end = this.state.mddEnd;

    filters.vola_type = this.state.volaType;
    filters.vola_start = this.state.volaStart;
    filters.vola_end = this.state.volaEnd;

    filters.var_95_type = this.state.var95Type;
    filters.var_95_start = this.state.var95Start;
    filters.var_95_end = this.state.var95End;

    filters.is_ecologically_sustainable = this.state.is_ecologically_sustainable;
    filters.is_esg = this.state.is_esg;
    filters.is_pai = this.state.is_pai;
    filters.is_sdg = this.state.is_sdg;
    filters.is_exclusion_criteria = this.state.is_exclusion_criteria;

    filters.is_ecologically_sustainable_values = this.state.is_ecologically_sustainable_values;
    filters.is_esg_values = this.state.is_esg_values;
    filters.is_pai_values = this.state.is_pai_values;
    filters.is_sdg_values = this.state.is_sdg_values;
    filters.is_exclusion_criteria_values = this.state.is_exclusion_criteria_values;

    filters.is_artikel_8 = this.state.is_artikel_8;
    filters.is_artikel_9 = this.state.is_artikel_9;
    filters.is_apply_criteria_together = this.state.is_apply_criteria_together;
    filters.esg_rating_start = this.state.esgRatingStart;
    filters.esg_rating_end = this.state.esgRatingEnd;

    return filters;
  };

  setDefaultFilters = () => {
    this.setState(
      {
        errors: {},
        name: "",

        managementFeeStart: "",
        managementFeeEnd: "",
        managementEntryCostStart: "",
        managementEntryCostEnd: "",
        incomeAppropriation: "",
        sustainable: false,
        return_profile_compatible_esg: "",
        etf: false,
        is_institutional_share_class: false,
        cSavingPlanPossible: false,
        cPortfolioPossible: false,
        cVlPossible: false,
        cMinAmountSavingPlan: "",
        cMinAmountSinglePayment: "",
        srriStart: 1,
        srriEnd: 7,
        currency: [],
        cCustodian: [],
        investmentCompany: [],
        category: "",
        ms_category: [],
        broad_asset_class: [],
        region: [],
        branch: [],

        is_ecologically_sustainable: false,
        is_esg: false,
        is_pai: false,
        is_sdg: false,
        is_exclusion_criteria: false,

        is_ecologically_sustainable_values: [],
        is_esg_values: [],
        is_pai_values: [],
        is_sdg_values: [],
        is_exclusion_criteria_values: [],

        is_artikel_8: false,
        is_artikel_9: false,
        is_apply_criteria_together: false,
        esgRatingStart: "",
        esgRatingEnd: "",

        fundStarRatingOveralStart: '',
        fundStarRatingOveralEnd: '',
        productFeesPurchaseFrontLoadMin: '',
        productFeesPurchaseFrontLoadMax: '',
        productFeesOngoingCostActualMin: '',
        productFeesOngoingCostActualMax: '',
        cumulativeReturnType: '',
        cumulativeReturnStart: '',
        cumulativeReturnEnd: '',
        sharpeRatioType:'',
        sharpeRatioStart: '',
        sharpeRatioEnd: '',
        mddType:'',
        mddStart: '',
        mddEnd: '',
        volaType:'',
        volaStart: '',
        volaEnd: '',
        dividendYieldStart: '',
        dividendYieldEnd: '',
        var95Type:'',
        var95Start: '',
        var95End: ''
    }, () => {
      if (this.props.onFilterChanged) {
        this.props.onFilterChanged(this.getChangedFiltersTotalCount())
      }
    })
  }

  getSRRISliderValues = () => {
    if (!this.state.srriStart && !this.state.srriEnd) {
      return [];
    }

    if (this.state.srriStart && this.state.srriEnd) {
      if (this.state.srriStart === this.state.srriEnd) {
        return [this.state.srriStart];
      }

      return _.range(+this.state.srriStart, +this.state.srriEnd + 1, 1);
    }

    if (this.state.srriStart && !this.state.srriEnd) {
      return _.range(+this.state.srriStart, 8, 1);
    }

    return _.range(1, +this.state.srriEnd + 1, 1);
  };

  getChangedFiltersTotalCount = () => {
    return this.getChangedFiltersCount([
      "name",
      ["srriStart", "srriEnd"],
      "return_profile_compatible_esg",
      "etf",
      "is_institutional_share_class",
      "is_ecologically_sustainable_values",
      "is_esg_values",
      "is_pai_values",
      "is_sdg_values",
      "is_exclusion_criteria_values",
      "is_artikel_8",
      "is_artikel_9",
      // "is_apply_criteria_together",
      ["esgRatingStart", "esgRatingEnd"],
      "cPortfolioPossible",
      "investmentCompany",
      "cCustodian",
      "incomeAppropriation",
      "ms_category",
      "broad_asset_class",
      "cVlPossible",
      "region",
      "branch",
      "currency",
      ["managementFeeStart", "managementFeeEnd"],
      "cMinAmountSinglePayment",
      "cSavingPlanPossible",
      "cMinAmountSavingPlan",
      ["fundStarRatingOveralStart", "fundStarRatingOveralEnd"],
      ["cumulativeReturnStart", "cumulativeReturnEnd"],
      ["sharpeRatioStart", "sharpeRatioEnd"],
      ["productFeesPurchaseFrontLoadMin", "productFeesPurchaseFrontLoadMax"],
      ["productFeesOngoingCostActualMin", "productFeesOngoingCostActualMax"],
      ["volaStart", "volaEnd"],
      ["mddStart", "mddEnd"],
      ["var95Start", "var95End"],
    ]);
  };

  oncCustodianReset = (field) => {
    this.onValueReset("cost");
  };

  onMsRatingChange = (minmaxArr) => {
    if (!minmaxArr[0] && !minmaxArr[1])
      minmaxArr = [
        this.DEFAULT_VALUES.fundStarRatingOveralStart.value,
        this.DEFAULT_VALUES.fundStarRatingOveralEnd.value,
      ];

    this.setState(
      {
        ...this.state,
        fundStarRatingOveralStart: minmaxArr[0],
        fundStarRatingOveralEnd: minmaxArr[1],
      },
      () => {
        if (this.props.onFilterChanged) {
          this.props.onFilterChanged(this.getChangedFiltersTotalCount());
        }
      }
    );
  };

  onSrriChange = (newValue) => {
    this.setState({
      ...this.state,
      srriStart: _.first(newValue),
      srriEnd: _.last(newValue),
    }, () => {
      if (this.props.onFilterChanged) {
        this.props.onFilterChanged(this.getChangedFiltersTotalCount());
      }
    });
  };

  onESGRatingChange = (minmaxArr) => {
    if (!minmaxArr[0] && !minmaxArr[1])
      minmaxArr = [
        this.DEFAULT_VALUES.esgRatingStart.value,
        this.DEFAULT_VALUES.esgRatingEnd.value,
      ];

    this.setState(
      {
        ...this.state,
        esgRatingStart: minmaxArr[0],
        esgRatingEnd: minmaxArr[1],
      },
      () => {
        this.calculateEsgSaveBtnClass()
        if (this.props.onFilterChanged) {
          this.props.onFilterChanged(this.getChangedFiltersTotalCount());
        }
      }
    );
  };

  onProductFeesPurchaseFrontLoadChange = (minmaxArr) => {
    this.setState(
      {
        ...this.state,
        productFeesPurchaseFrontLoadMin: minmaxArr[0],
        productFeesPurchaseFrontLoadMax: minmaxArr[1],
      },
      () => {
        if (this.props.onFilterChanged) {
          this.props.onFilterChanged(this.getChangedFiltersTotalCount());
        }
      }
    );
  };

  onProductFeesOngoingCostActualChange = (minmaxArr) => {
    this.setState(
      {
        ...this.state,
        productFeesOngoingCostActualMin: minmaxArr[0],
        productFeesOngoingCostActualMax: minmaxArr[1],
      },
      () => {
        if (this.props.onFilterChanged) {
          this.props.onFilterChanged(this.getChangedFiltersTotalCount());
        }
      }
    );
  };

  currencySearchFn = (option, searchText) => {
    if ((option.value || "").toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    }
    return (option.german || "")
      .toLowerCase()
      .includes(searchText.toLowerCase());
  };

  esgBoxActiveClass = (el, value, classesList) => {
    if(!this.state[el.values].includes(value)) return;

    return el.invert ? classesList[1] : classesList[0]
  }

  selectedBoxes = (value, values) => {
    if (!this.state[values].includes(value)) {
      this.setState({
        ...this.state,
        [values]: [...this.state[values], value],
      }, () => {
        this.calculateEsgSaveBtnClass()
        if (this.props.onFilterChanged) {
          this.props.onFilterChanged(this.getChangedFiltersTotalCount());
        }
      });
    } else {
      this.setState({
        ...this.state,
        [values]: this.state[values].filter((val) => val !== value),
      }, () => {
        this.calculateEsgSaveBtnClass()
        if (this.props.onFilterChanged) {
          this.props.onFilterChanged(this.getChangedFiltersTotalCount());
        }
      });
    }
  };

  checkSwitchState = (fieldName, fieldValue) => {
    if(!fieldValue)
      this.setState({
        [fieldName + '_values']: []
      }, () => {
        this.calculateEsgSaveBtnClass()
        if (this.props.onFilterChanged) {
          this.props.onFilterChanged(this.getChangedFiltersTotalCount());
        }
      })
  }

  esgFiltersChanged = () => (
      this.getChangedFiltersCount([
        ["esgRatingStart", "esgRatingEnd"],
        "is_ecologically_sustainable_values",
        "is_esg_values",
        "is_pai_values",
        "is_sdg_values",
        "is_exclusion_criteria_values",
        "is_artikel_8",
        "is_artikel_9"
      ])
  )

  esgFiltersGetFromStorage = () => (
      JSON.parse(localStorage.getItem('esg_filters_stored'))
  )

  saveESGFilters = () => {

      if(this.esgFiltersChanged() > 0) {

        let egsFiltersStored = {};

        this.valuesToSave.forEach(value => {
          egsFiltersStored[value] = this.state[value];
        })

        localStorage.setItem('esg_filters_stored', JSON.stringify(egsFiltersStored))

        this.setState({
          esgSaveBtnActive: false,
          esgRestoreBtnDisabled: false,
          esgCleanBtnDisabled: false,
        })
      }

  }

  restoreESGFilters = () => {
    this.setState(this.esgFiltersGetFromStorage(), () => {
      if (this.props.onFilterChanged) {
        this.props.onFilterChanged(this.getChangedFiltersTotalCount());
      }
    })

    this.setState({
      esgSaveBtnActive: false,
      esgSaveBtnDisabled: false,
      esgCleanBtnDisabled: false,
    })
  }

  cleanESGFilters = () => {
    localStorage.removeItem('esg_filters_stored')

    this.onValueReset('sustainability')

    this.setState({
      esgSaveBtnActive: false,
      esgSaveBtnDisabled: true,
      esgRestoreBtnDisabled: true,
      esgCleanBtnDisabled: true,
    })

  }

  loadClientESGFilters = () => {
    if(this.props.customerId) {
      //
    }
  }

  calculateEsgSaveBtnClass = () => {
    let egsFiltersStored = JSON.parse(localStorage.getItem('esg_filters_stored'))
    let activateBtn = false;
    if(egsFiltersStored) {
      for(let key in egsFiltersStored) {
        if(Array.isArray(egsFiltersStored[key])) {
          if(!_.isEqual(egsFiltersStored[key].sort(), this.state[key].sort())) {
            activateBtn = true;
            break;
          }
        } else {
          if(egsFiltersStored[key] !== this.state[key]) {
            activateBtn = true;
            break;
          }
        }
      }
      this.setState({
        esgSaveBtnActive: activateBtn
      })
    }
    this.setState({
      esgSaveBtnDisabled: this.esgFiltersChanged() === 0
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <FilterSection
          title="Basisdaten"
          sectionName="basic"
          expandedByDefault={true}
          onResetClick={this.onValueReset}
          count={this.getChangedFiltersCount([
            "name",
            ["srriStart", "srriEnd"],
            ["fundStarRatingOveralStart", "fundStarRatingOveralEnd"],
            "return_profile_compatible_esg",
            "etf",
            "is_institutional_share_class",
            "investmentCompany",
            "incomeAppropriation",
          ])}
        >
          <Grid container spacing={3} style={{ paddingBottom: 16 }}>
            <Grid item xs={12} md={6}>
              <FilterSubSection
                title="Instrumentenname, ISIN, oder WKN suchen"
                resetButtonVisible={this.isFieldChanged("name")}
                onResetClick={this.onFieldReset}
                resetFieldsNames={"name"}
              >
                <DropdownInput
                  value={this.state.name}
                  error={_.get(this.state.errors, "name", "")}
                  onChange={this.onValueChange(
                    "name",
                    this.handleNameFieldValue
                  )}
                  placeholder="Instrumentenname, ISIN, oder WKN suchen"
                  showErrorAsTooltip={true}
                  autoApprove={false}
                  onValueApprove={this.props.onSearchFieldApprove}
                />
              </FilterSubSection>
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingRight: 40 }}>
              <FilterSubSection
                title="SRI"
                xs={12}
                resetButtonVisible={this.isFieldChanged([
                  "srriStart",
                  "srriEnd",
                ])}
                onResetClick={this.onFieldReset}
                resetFieldsNames={["srriStart", "srriEnd"]}
              >
                <div
                  className={classes.inputsContainer}
                  style={{ width: "100%", marginTop: 42 }}
                >
                  <Steps
                    marks={SLIDER_MARKS}
                    handleChange={(event, newValue) => this.onSrriChange(newValue)}
                    value={[this.state.srriStart || 1, this.state.srriEnd || 7]}
                    style={{ width: "100%", marginLeft: 15, marginTop: 10 }}
                  />
                </div>
              </FilterSubSection>
            </Grid>

            <Grid item xs={12} md={6}>
              <AssetInfoStaticMultilineSelectInput
                title="Fondsgesellschaft"
                value={this.state.investmentCompany}
                onChange={this.onValueChange("investmentCompany")}
                customClasses={classes}
                resetButtonVisible={this.isFieldChanged("investmentCompany")}
                onResetClick={this.onFieldReset}
                resetFieldsNames={"investmentCompany"}
                withContainer={false}
                assetInfoFieldName="investment_companies"
                placeholder={ASSET_SEARCH_PLACEHOLDER}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FilterSubSection
                title="Morningstar-Rating"
                xs={12}
                resetButtonVisible={this.isFieldChanged([
                  "fundStarRatingOveralStart",
                  "fundStarRatingOveralEnd",
                ])}
                onResetClick={this.onFieldReset}
                resetFieldsNames={[
                  "fundStarRatingOveralStart",
                  "fundStarRatingOveralEnd",
                ]}
              >
                <RatingRangeStars
                  onChangeRange={this.onMsRatingChange}
                  value={[
                    this.state.fundStarRatingOveralStart,
                    this.state.fundStarRatingOveralEnd,
                  ]}
                  fontSize={48}
                />
              </FilterSubSection>
            </Grid>

            <Grid item xs={12} md={6}>
              <FilterSubSection
                title="Ertragsverwendung"
                resetButtonVisible={this.isFieldChanged("incomeAppropriation")}
                onResetClick={this.onFieldReset}
                resetFieldsNames={"incomeAppropriation"}
              >
                <RadioButtonsSection
                  options={INCOME_APPROPRIATION_OPTIONS}
                  value={this.state.incomeAppropriation}
                  onChange={this.onValueChange("incomeAppropriation")}
                  flexDirection="row"
                />
              </FilterSubSection>
            </Grid>

            <Grid item xs={6} md={3}>
              <FilterSubSection title="nur Institutionelle">
                <SwitchInput
                  checked={this.state.is_institutional_share_class}
                  onChange={this.onValueChange("is_institutional_share_class")}
                />
              </FilterSubSection>
            </Grid>
            <Grid item xs={6} md={3}>
              <FilterSubSection title="nur ETF">
                <SwitchInput
                  checked={this.state.etf}
                  onChange={this.onValueChange("etf")}
                />
              </FilterSubSection>
            </Grid>
          </Grid>
          <Grid container spacing={2}></Grid>
        </FilterSection>
        <FilterSection
          title="Kategorie, Währung, Region, Branche"
          sectionName="structure"
          onResetClick={this.onValueReset}
          count={this.getChangedFiltersCount([
            "ms_category",
            "broad_asset_class",
            "region",
            "branch",
          ])}
        >
          <Grid container spacing={3}>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 1280: 2 }}
              style={{ width: "100%" }}
            >
              <Masonry>
                <Grid item style={{ width: "100%", padding: 12 }}>
                  <AssetInfoStaticMultilineSelectInput
                    title="Produktgattung"
                    value={this.state.broad_asset_class}
                    onChange={this.onValueChange("broad_asset_class")}
                    customClasses={classes}
                    resetButtonVisible={this.isFieldChanged("broad_asset_class")}
                    onResetClick={this.onFieldReset}
                    resetFieldsNames={"broad_asset_class"}
                    icon={<SearchIcon color={this.INACTIVE_INPUT_COLOR} />}
                    withContainer={false}
                    assetInfoFieldName="broad_asset_classes"
                    placeholder={ASSET_SEARCH_PLACEHOLDER}
                  />
                </Grid>
                <Grid item style={{ width: "100%", padding: 12 }}>
                  <AssetInfoStaticMultilineSelectInput
                    title="Morningstar-Kategorie"
                    value={this.state.ms_category}
                    onChange={this.onValueChange("ms_category")}
                    customClasses={classes}
                    resetButtonVisible={this.isFieldChanged("ms_category")}
                    onResetClick={this.onFieldReset}
                    resetFieldsNames={"ms_category"}
                    withContainer={false}
                    assetInfoFieldName="categories"
                    placeholder={ASSET_SEARCH_PLACEHOLDER}
                  />
                </Grid>
                <Grid item style={{ width: "100%", padding: 12 }}>
                  <AssetInfoStaticMultilineSelectInput
                    title="Region"
                    value={this.state.region}
                    onChange={this.onValueChange("region")}
                    customClasses={classes}
                    resetButtonVisible={this.isFieldChanged("region")}
                    onResetClick={this.onFieldReset}
                    resetFieldsNames={"region"}
                    withContainer={false}
                    assetInfoFieldName="regions"
                    placeholder={ASSET_SEARCH_PLACEHOLDER}
                  />
                </Grid>
                <Grid item style={{ width: "100%", padding: 12 }}>
                  <AssetInfoStaticMultilineSelectInput
                    title="Branche"
                    value={this.state.branch}
                    onChange={this.onValueChange("branch")}
                    customClasses={classes}
                    resetButtonVisible={this.isFieldChanged("branch")}
                    onResetClick={this.onFieldReset}
                    resetFieldsNames={"branch"}
                    withContainer={false}
                    assetInfoFieldName="branches"
                    placeholder={ASSET_SEARCH_PLACEHOLDER}
                  />
                </Grid>
                <Grid item style={{ width: "100%", padding: 12 }}>
                  <AssetInfoStaticMultilineSelectInput
                    title="Fondswährung"
                    value={this.state.currency}
                    onChange={this.onValueChange("currency")}
                    customClasses={classes}
                    resetButtonVisible={this.isFieldChanged("currency")}
                    onResetClick={this.onFieldReset}
                    resetFieldsNames={"currency"}
                    withContainer={false}
                    assetInfoFieldName="currencies"
                    inputMinLength={1}
                    nameOption={(o) => o.value + " " + o.german}
                    budgeOption={(o) => o.value}
                    placeholder={ASSET_SEARCH_PLACEHOLDER}
                    searchFn={this.currencySearchFn}
                  />
                </Grid>
              </Masonry>
            </ResponsiveMasonry>
          </Grid>
        </FilterSection>
        {this.isESGFilterEnabled && (
          <FilterSection
            title="Nachhaltigkeit"
            sectionName="sustainability"
            onResetClick={this.onValueReset}
            count={this.getChangedFiltersCount([
              ["esgRatingStart", "esgRatingEnd"],
              "is_ecologically_sustainable_values",
              "is_esg_values",
              "is_pai_values",
              "is_sdg_values",
              "is_exclusion_criteria_values",
              "is_artikel_8",
              "is_artikel_9",
            ])}
          >
          <Grid container xs={12} alignItems="center">
            <Grid item xs={4}>
              <Typography className={classes.sustainabilitySectionTitle}>
                Diese Kriterien werden in der Produktübersicht berücksichtigt
              </Typography>
            </Grid>
            <Grid container xs={8} justify="flex-end">
              <Grid item>
                <Button color="primary" className={clsx([classes.acceptButton, this.state.esgSaveBtnActive && classes.boxActiveInverted, this.state.esgSaveBtnDisabled && classes.saveFiltersButtonDisabled])} onClick={this.saveESGFilters}>
                  ESG Filter speichern
                </Button>
              </Grid>
              <Grid item>
                <Button color="primary" className={clsx([classes.acceptButton, this.state.esgRestoreBtnDisabled && classes.saveFiltersButtonDisabled])} onClick={this.restoreESGFilters}>
                  ESG Filter anwenden
                </Button>
              </Grid>
              <Grid item>
                <Button color="primary" className={clsx([classes.acceptButton, this.state.esgCleanBtnDisabled && classes.saveFiltersButtonDisabled])} onClick={this.cleanESGFilters}>
                  ESG Filter zurücksetzen
                </Button>
              </Grid>
              <Grid item>
                {/*this is part of BCA-6323*/}
                {/*{this.props.customerId && (*/}
                {/*  <Button color="primary" className={clsx([classes.acceptButton, classes.acceptButtonDisabled])} onClick={this.loadClientESGFilters}>*/}
                {/*    ESG Profil übernehmen*/}
                {/*  </Button>*/}
                {/*)}*/}
              </Grid>
            </Grid>
          </Grid>
            <Grid
              container
              xs={12}
              justify="space-between"
              className={classes.switchItemWrapper}
            >
              <Grid item lg={'auto'} sm={2} className={classes.switchItem}>
                <FilterSubSection
                  title="Öko"
                  tooltip={
                    <>
                      Nachhaltige Investition gem. Taxonomieverordnung – Leistet
                      u.a. einen wesentlichen Beitrag zur Verwirklichung eines
                      oder mehrerer Umweltziele
                    </>
                  }
                >
                  <SwitchInput
                    checked={this.state.is_ecologically_sustainable}
                    onChange={this.onValueChange("is_ecologically_sustainable", undefined, this.checkSwitchState)}
                  />
                </FilterSubSection>
              </Grid>
              <Grid item lg={'auto'} sm={2} className={classes.switchItem}>
                <FilterSubSection
                  title="ESG"
                  tooltip={
                    <>
                      Nachhaltige Investition gem. Offenlegungsverordnung – Trägt
                      zur Erreichung eines Umweltziels oder eines sozialen Ziels
                      bei und investiert in Unternehmen, die Verfahrensweisen
                      einer guten Unternehmensführung anwenden
                    </>
                  }
                >
                  <SwitchInput
                    checked={this.state.is_esg}
                    onChange={this.onValueChange("is_esg", undefined, this.checkSwitchState)}
                  />
                </FilterSubSection>
              </Grid>
              <Grid item lg={'auto'} sm={2} className={classes.switchItem}>
                <FilterSubSection
                  title="PAI"
                  tooltip={
                    <>
                      Investition berücksichtigt die wichtigsten nachteiligen
                      Auswirkungen auf Nachhaltigkeitsfaktoren -
                      Treibhausgasemissionen, Biodiversität, Wasser, Abfall,
                      Soziale und Arbeitnehmerbelange
                    </>
                  }
                >
                  <SwitchInput
                    checked={this.state.is_pai}
                    onChange={this.onValueChange("is_pai", undefined, this.checkSwitchState)}
                  />
                </FilterSubSection>
              </Grid>
              <Grid item lg={'auto'} sm={2} className={classes.switchItem}>
                <FilterSubSection
                  title="SDG"
                  tooltip={
                    <>
                      Sustainable Development Goals - In der Agenda 2030
                      festgehaltene 17 Ziele für nachhaltige Entwicklung, zum
                      Klimaschutz, zu fairen Standards in der Arbeitswelt und
                      gegen Hunger und Armut
                    </>
                  }
                >
                  <SwitchInput
                    checked={this.state.is_sdg}
                    onChange={this.onValueChange("is_sdg", undefined, this.checkSwitchState)}
                  />
                </FilterSubSection>
              </Grid>
              <Grid item lg={'auto'} sm={2} className={classes.switchItem}>
                <FilterSubSection
                  title="Ausschl. Krit."
                  tooltip={
                    <>
                      Ausschlusskriterien - Anlagen oder Kriterien, die gemäß
                      Anlagerichtlinien ausgeschlossen sind
                    </>
                  }
                >
                  <SwitchInput
                    checked={this.state.is_exclusion_criteria}
                    onChange={this.onValueChange("is_exclusion_criteria", undefined, this.checkSwitchState)}
                  />
                </FilterSubSection>
              </Grid>
              <Grid item lg={'auto'} sm={2} className={classes.switchItem}>
                <FilterSubSection
                  title="Artikel 8 Produkte"
                  tooltip={
                    <>
                      Fonds, die ökologische oder soziale Merkmale bewerben bzw.
                      bei der Auswahl ihrer Anlageinstrumente berücksichtigen.
                    </>
                  }
                >
                  <SwitchInput
                    checked={this.state.is_artikel_8}
                    onChange={this.onValueChange("is_artikel_8", undefined, this.calculateEsgSaveBtnClass)}
                  />
                </FilterSubSection>
              </Grid>
              <Grid item lg={'auto'} sm={2} className={classes.switchItem}>
                <FilterSubSection
                  title="Artikel 9 Produkte"
                  tooltip={
                    <>
                      Fonds, die nicht nur bestimmte Nachhaltigkeitskriterien
                      definiert, sondern sich zu einem konkreten,
                      nachhaltigkeitsbezogenen Anlageziel verpflichtet haben.
                    </>
                  }
                >
                  <SwitchInput
                    checked={this.state.is_artikel_9}
                    onChange={this.onValueChange("is_artikel_9", undefined, this.calculateEsgSaveBtnClass)}
                  />
                </FilterSubSection>
              </Grid>
              <Grid item lg={'auto'} sm={2} className={classes.switchItem}>
                <FilterSubSection
                  title="MS ESG Rating"
                  style={{minWidth: 140}}  // width of title + "resetButton"
                  resetButtonVisible={this.isFieldChanged([
                    "esgRatingStart",
                    "esgRatingEnd",
                  ])}
                  onResetClick={this.onFieldReset}
                  resetFieldsNames={["esgRatingStart", "esgRatingEnd"]}
                >
                  <RatingRangeStars
                    onChangeRange={this.onESGRatingChange}
                    value={[this.state.esgRatingStart, this.state.esgRatingEnd]}
                    fontSize={24}
                  />
                </FilterSubSection>
              </Grid>
              <Grid item lg={'auto'} sm={2} className={classes.switchItem}>
                <FilterSubSection
                  title="Suchkrit. gemeinsam"
                  tooltip={
                    <>
                      Mit dieser Einstellung können sie festlegen, ob alle
                      Kriterien gleichzeitig erfüllt sein müssen, damit ein
                      Instrument berücksichtigt wird (Einstellung on), oder ob ein
                      erfülltes Teikriterium genügt um ein Instrument auszuwählen
                      (Einstellung off). Im ersten Fall erhalten Sie je nach
                      Kriterienzusammenstellung eventuell nur wenige Instrumente
                      zur Auswahl vorgeschlagen. Die Beachtung der von ihnen
                      bestimmten Ausschlusskriterien bleibt dabei in beiden
                      Einstellungen gewährleistet.
                    </>
                  }
                >
                  <SwitchInput
                    checked={this.state.is_apply_criteria_together}
                    onChange={this.onValueChange("is_apply_criteria_together")}
                  />
                </FilterSubSection>
              </Grid>
            </Grid>
            {boxesData.map(
              (el) =>
                this.state[el.category] && (
                  <div key={el.category} className={classes.boxesWrapper}>
                    <p className={classes.boxesMainTitle}>{el.title}</p>
                    <Grid container className={classes.boxContainer}>
                      {el.boxes.map((subEl) => (
                        <Grid item xs={3} key={subEl.uid} className={classes.box}>
                          <div
                            className={clsx(
                              classes.boxWrapper,
                              this.esgBoxActiveClass(el, subEl.value, [classes.boxActive, classes.boxActiveInverted])
                            )}
                            onClick={() => this.selectedBoxes(subEl.value, el.values)}
                          >
                            <p>{subEl.title}</p>
                            {subEl.tooltip && (
                              <>
                                &nbsp;
                                <Tooltip
                                  placement={"right"}
                                  title={subEl.tooltip}
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <InfoIcon
                                    color={"primary"}
                                    className={clsx(
                                      classes.helpIcon,
                                      this.esgBoxActiveClass(el, subEl.value, [classes.helpIconActive, classes.helpIconActiveInverted])
                                    )}
                                  />
                                </Tooltip>
                              </>
                            )}
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )
            )}
          </FilterSection>
        )}
        <FilterSection
          title="Handelsdaten und Gebühren"
          sectionName="cost"
          onResetClick={this.onValueReset}
          count={this.getChangedFiltersCount([
            "cCustodian",
            ["managementFeeStart", "managementFeeEnd"],
            "cMinAmountSinglePayment",
            "cSavingPlanPossible",
            "cMinAmountSavingPlan",
            "cPortfolioPossible",
            "cVlPossible",
            [
              "productFeesPurchaseFrontLoadMin",
              "productFeesPurchaseFrontLoadMax",
            ],
            [
              "productFeesOngoingCostActualMin",
              "productFeesOngoingCostActualMax",
            ],
          ])}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <h4>Bitte zunächst Depotbank auswählen</h4>

              <AssetInfoStaticMultilineSelectInput
                title="Handelbar bei"
                value={this.state.cCustodian}
                onChange={this.onValueChange("cCustodian")}
                customClasses={classes}
                resetButtonVisible={
                  !this.state.predefinedFields.includes("cCustodian") &&
                  this.isFieldChanged("cCustodian")
                }
                onResetClick={this.oncCustodianReset}
                icon={<SearchIcon color={this.INACTIVE_INPUT_COLOR} />}
                resetFieldsNames={"cCustodian"}
                withContainer={false}
                assetInfoFieldName="custodians"
                disabledTextInput={true}
                placeholder={ASSET_SEARCH_PLACEHOLDER}
                disabled={this.state.predefinedFields.includes("cCustodian")}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <FilterSubSection
                title="Ausgabeaufschlag in %"
                resetButtonVisible={this.isFieldChanged([
                  "productFeesPurchaseFrontLoadMin",
                  "productFeesPurchaseFrontLoadMax",
                ])}
                onResetClick={this.onFieldReset}
                resetFieldsNames={[
                  "productFeesPurchaseFrontLoadMin",
                  "productFeesPurchaseFrontLoadMax",
                ]}
              >
                <SliderRangeSelect
                  onChangeRange={this.onProductFeesPurchaseFrontLoadChange}
                  min={this.state.productFeesPurchaseFrontLoadMin}
                  max={this.state.productFeesPurchaseFrontLoadMax}
                  disabled={_.isEmpty(this.state.cCustodian)}
                ></SliderRangeSelect>
              </FilterSubSection>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <FilterSubSection
                title="Laufende Kosten in %"
                resetButtonVisible={this.isFieldChanged([
                  "productFeesOngoingCostActualMin",
                  "productFeesOngoingCostActualMax",
                ])}
                onResetClick={this.onFieldReset}
                resetFieldsNames={[
                  "productFeesOngoingCostActualMin",
                  "productFeesOngoingCostActualMax",
                ]}
              >
                <SliderRangeSelect
                  onChangeRange={this.onProductFeesOngoingCostActualChange}
                  min={this.state.productFeesOngoingCostActualMin}
                  max={this.state.productFeesOngoingCostActualMax}
                  disabled={_.isEmpty(this.state.cCustodian)}
                ></SliderRangeSelect>
              </FilterSubSection>
            </Grid>

            <Grid item md={4}>
              <FilterSubSection title="Portfoliofähig">
                <SwitchInput
                  checked={this.state.cPortfolioPossible}
                  onChange={this.onValueChange("cPortfolioPossible")}
                  disabled={_.isEmpty(this.state.cCustodian)}
                />
              </FilterSubSection>
            </Grid>

            <Grid item md={4} lg={4}>
              <FilterSubSection title="VL-fähig">
                <SwitchInput
                  checked={this.state.cVlPossible}
                  onChange={this.onValueChange("cVlPossible")}
                  disabled={_.isEmpty(this.state.cCustodian)}
                />
              </FilterSubSection>
            </Grid>

            <Grid item md={4} lg={4}>
              <FilterSubSection title="Sparplanfähig">
                <SwitchInput
                  checked={this.state.cSavingPlanPossible}
                  onChange={this.onValueChange("cSavingPlanPossible")}
                  disabled={_.isEmpty(this.state.cCustodian)}
                />
              </FilterSubSection>
            </Grid>

            <Grid item xs={12} lg={6}>
              <FilterSubSection
                title="Mindestanlage Einmalanlage"
                resetButtonVisible={this.isFieldChanged(
                  "cMinAmountSinglePayment"
                )}
                onResetClick={this.onFieldReset}
                resetFieldsNames={"cMinAmountSinglePayment"}
              >
                <DropdownInput
                  icon={
                    <span style={{ fontFamily: "Roboto-Bold", fontSize: 14 }}>
                      €
                    </span>
                  }
                  iconPosition="end"
                  labelStyle={{ fontFamily: "Roboto-Medium" }}
                  labelPosition="right"
                  value={this.state.cMinAmountSinglePayment}
                  error={_.get(
                    this.state.errors,
                    "cMinAmountSinglePayment",
                    ""
                  )}
                  onChange={this.onValueChange("cMinAmountSinglePayment")}
                  width={500}
                  fullWidth={false}
                  style={{ marginRight: 10 }}
                  inputStyle={{ textAlign: "right" }}
                  inputComponent={CurrencyNumberFormat}
                  disabled={_.isEmpty(this.state.cCustodian)}
                  showErrorAsTooltip={true}
                />
              </FilterSubSection>
            </Grid>

            <Grid item xs={12} lg={6}>
              <FilterSubSection
                title="Mindestanlage Sparplan"
                resetButtonVisible={this.isFieldChanged("cMinAmountSavingPlan")}
                onResetClick={this.onFieldReset}
                resetFieldsNames={"cMinAmountSavingPlan"}
              >
                <DropdownInput
                  icon={
                    <span style={{ fontFamily: "Roboto-Bold", fontSize: 14 }}>
                      €
                    </span>
                  }
                  iconPosition="end"
                  labelStyle={{ fontFamily: "Roboto-Medium" }}
                  labelPosition="right"
                  value={this.state.cMinAmountSavingPlan}
                  error={_.get(this.state.errors, "cMinAmountSavingPlan", "")}
                  onChange={this.onValueChange("cMinAmountSavingPlan")}
                  width={500}
                  fullWidth={false}
                  style={{ marginRight: 10 }}
                  inputStyle={{ textAlign: "right" }}
                  inputComponent={CurrencyNumberFormat}
                  disabled={_.isEmpty(this.state.cCustodian)}
                  showErrorAsTooltip={true}
                />
              </FilterSubSection>
            </Grid>
          </Grid>
        </FilterSection>
        <FilterSection
          title="Kennzahlen"
          sectionName="numbers"
          onResetClick={this.onValueReset}
          count={this.getChangedFiltersCount([
            ["cumulativeReturnStart", "cumulativeReturnEnd"],
            ["sharpeRatioStart", "sharpeRatioEnd"],
            ["volaStart", "volaEnd"],
            ["mddStart", "mddEnd"],
            ["var95Start", "var95End"],
          ])}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <FilterSubSection
                title="Performance (%)"
                resetButtonVisible={this.isFieldChanged([
                  "cumulativeReturnType",
                  "cumulativeReturnStart",
                  "cumulativeReturnEnd",
                ])}
                onResetClick={this.onFieldReset}
                resetFieldsNames={[
                  "cumulativeReturnType",
                  "cumulativeReturnStart",
                  "cumulativeReturnEnd",
                ]}
              >
                <div className={classes.inputsContainer}>
                  <Select
                    options={TIME_DURATION_TYPES}
                    width={175}
                    value={this.state.cumulativeReturnType}
                    onChange={this.onValueChange("cumulativeReturnType")}
                    selectStyles={{ height: 50 }}
                  />
                  <DropdownInput
                    label="Von"
                    value={this.state.cumulativeReturnStart}
                    error={_.get(
                      this.state.errors,
                      "cumulativeReturnStart",
                      ""
                    )}
                    onChange={this.onValueChange("cumulativeReturnStart")}
                    width={75}
                    fullWidth={false}
                    type="number"
                    style={{ marginRight: 10 }}
                    placeholder={0}
                    showErrorAsTooltip={true}
                    disabled={this.state.cumulativeReturnType === ''}
                  />
                  <DropdownInput
                    value={this.state.cumulativeReturnEnd}
                    error={_.get(this.state.errors, "cumulativeReturnEnd", "")}
                    onChange={this.onValueChange("cumulativeReturnEnd")}
                    label="bis"
                    width={75}
                    type="number"
                    fullWidth={false}
                    placeholder={100}
                    showErrorAsTooltip={true}
                    disabled={this.state.cumulativeReturnType === ''}
                  />
                </div>
              </FilterSubSection>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FilterSubSection
                title="Sharpe Ratio"
                resetButtonVisible={this.isFieldChanged([
                  "sharpeRatioType",
                  "sharpeRatioStart",
                  "sharpeRatioEnd",
                ])}
                onResetClick={this.onFieldReset}
                resetFieldsNames={[
                  "sharpeRatioType",
                  "sharpeRatioStart",
                  "sharpeRatioEnd",
                ]}
              >
                <div className={classes.inputsContainer}>
                  <Select
                    options={TIME_DURATION_TYPES}
                    width={175}
                    value={this.state.sharpeRatioType}
                    onChange={this.onValueChange("sharpeRatioType")}
                    selectStyles={{ height: 50 }}
                  />
                  <DropdownInput
                    label="Von"
                    value={this.state.sharpeRatioStart}
                    error={_.get(this.state.errors, "sharpeRatioStart", "")}
                    onChange={this.onValueChange("sharpeRatioStart")}
                    width={75}
                    fullWidth={false}
                    type="number"
                    style={{ marginRight: 10 }}
                    showErrorAsTooltip={true}
                    disabled={this.state.sharpeRatioType === ''}
                  />
                  <DropdownInput
                    value={this.state.sharpeRatioEnd}
                    error={_.get(this.state.errors, "sharpeRatioEnd", "")}
                    onChange={this.onValueChange("sharpeRatioEnd")}
                    label="bis"
                    width={75}
                    type="number"
                    fullWidth={false}
                    showErrorAsTooltip={true}
                    disabled={this.state.sharpeRatioType === ''}
                  />
                </div>
              </FilterSubSection>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FilterSubSection
                title="Volatilität (%)"
                resetButtonVisible={this.isFieldChanged([
                  "volaType",
                  "volaStart",
                  "volaEnd",
                ])}
                onResetClick={this.onFieldReset}
                resetFieldsNames={["volaType", "volaStart", "volaEnd"]}
              >
                <div className={classes.inputsContainer}>
                  <Select
                    options={TIME_DURATION_TYPES}
                    width={175}
                    value={this.state.volaType}
                    onChange={this.onValueChange("volaType")}
                    selectStyles={{ height: 50 }}
                  />
                  <DropdownInput
                    label="Von"
                    value={this.state.volaStart}
                    error={_.get(this.state.errors, "volaStart", "")}
                    onChange={this.onValueChange("volaStart")}
                    width={75}
                    fullWidth={false}
                    type="number"
                    style={{ marginRight: 10 }}
                    placeholder={0}
                    showErrorAsTooltip={true}
                    disabled={this.state.volaType === ''}
                  />
                  <DropdownInput
                    value={this.state.volaEnd}
                    error={_.get(this.state.errors, "volaEnd", "")}
                    onChange={this.onValueChange("volaEnd")}
                    label="bis"
                    width={75}
                    type="number"
                    fullWidth={false}
                    placeholder={100}
                    showErrorAsTooltip={true}
                    disabled={this.state.volaType === ''}
                  />
                </div>
              </FilterSubSection>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FilterSubSection
                title="Maximum Drawdown"
                resetButtonVisible={this.isFieldChanged([
                  "mddType",
                  "mddStart",
                  "mddEnd",
                ])}
                onResetClick={this.onFieldReset}
                resetFieldsNames={["mddType", "mddStart", "mddEnd"]}
              >
                <div className={classes.inputsContainer}>
                  <Select
                    options={TIME_DURATION_TYPES}
                    width={175}
                    value={this.state.mddType}
                    onChange={this.onValueChange("mddType")}
                    selectStyles={{ height: 50 }}
                  />
                  <DropdownInput
                    label="Von"
                    value={this.state.mddStart}
                    error={_.get(this.state.errors, "mddStart", "")}
                    onChange={this.onValueChange("mddStart")}
                    width={75}
                    fullWidth={false}
                    type="number"
                    style={{ marginRight: 10 }}
                    showErrorAsTooltip={true}
                    disabled={this.state.mddType === ''}
                  />
                  <DropdownInput
                    value={this.state.mddEnd}
                    error={_.get(this.state.errors, "mddEnd", "")}
                    onChange={this.onValueChange("mddEnd")}
                    label="bis"
                    width={75}
                    type="number"
                    fullWidth={false}
                    showErrorAsTooltip={true}
                    disabled={this.state.mddType === ''}
                  />
                </div>
              </FilterSubSection>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FilterSubSection
                title="Value at Risk 95"
                resetButtonVisible={this.isFieldChanged([
                  "var95Type",
                  "var95Start",
                  "var95End",
                ])}
                onResetClick={this.onFieldReset}
                resetFieldsNames={["var95Type", "var95Start", "var95End"]}
              >
                <div className={classes.inputsContainer}>
                  <Select
                    options={TIME_DURATION_TYPES}
                    width={175}
                    value={this.state.var95Type}
                    onChange={this.onValueChange("var95Type")}
                    selectStyles={{ height: 50 }}
                  />
                  <DropdownInput
                    label="Von"
                    value={this.state.var95Start}
                    error={_.get(this.state.errors, "var95Start", "")}
                    onChange={this.onValueChange("var95Start")}
                    width={75}
                    fullWidth={false}
                    type="number"
                    style={{ marginRight: 10 }}
                    showErrorAsTooltip={true}
                    disabled={this.state.var95Type === ''}
                  />
                  <DropdownInput
                    value={this.state.var95End}
                    error={_.get(this.state.errors, "var95End", "")}
                    onChange={this.onValueChange("var95End")}
                    label="bis"
                    width={75}
                    type="number"
                    fullWidth={false}
                    showErrorAsTooltip={true}
                    disabled={this.state.var95Type === ''}
                  />
                </div>
              </FilterSubSection>
            </Grid>
          </Grid>
        </FilterSection>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MyComponent);
