import React from 'react'
import PropTypes from 'prop-types'

import {
  Slider
} from '@material-ui/core';

import useStyles from './styles'

function Steps(props) {

  const { value, handleChange } = props;

  const classes = useStyles();

  return (
    <Slider
      marks={props.marks}
      min={1}
      max={7}
      step={null}
      value={value}
      onChange={handleChange}
      style={props.style}
      classes={{
        rail: classes.sliderRail,
        track: classes.sliderTrack,
        mark: classes.mark,
        markActive: classes.markActive,
        root: classes.root,
        markLabelActive: classes.markLabelActive,
        markLabel: classes.markLabe,
        thumb: classes.thumb
      }}
    />
  )
}

Steps.propTypes = {

}

export default Steps

