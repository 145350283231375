import React from 'react'
import PropTypes from 'prop-types'

import {
  Widget,
  WidgetHeader,
  WidgetBody,
  WidgetActions,
  DropdownInput,
  ListTree
} from '../index'
import PrimaryButton from '../../../../components/Buttons/PrimaryButton'

import useStyles from './styles'
import theme from '../../../../themes/mainTheme';
import {
  ImportIcon,
  SearchIcon
} from '../../../../images';
import ErrorsContainer from "../ErrorsContainer/ErrorsContainer";

const INACTIVE_INPUT_COLOR = '#202A38'

function ModelPortfolioUploader(props) {

  const {
    modelportfolios,
    selected,
    loading,
    onSelect,
    errors,
  } = props;

  const classes = useStyles();

  const [search, setSearch] = React.useState('')
  

  const getModelPortfolios = () => {
    if (!search) {
      return modelportfolios
    }

    if (modelportfolios) {
      let result = []

      modelportfolios.forEach(mPortfolio => {
        if (mPortfolio.name.toLowerCase().includes(search.toLowerCase())) {
          result.push(mPortfolio)
        }
      })

      return result
      
    }
  }

  return (
    <Widget>
      <WidgetHeader>
        <h2 className={classes.header}>Bestehendes Musterdepot als Vorlage laden</h2>
      </WidgetHeader>
      <WidgetBody>
        <div style={{display: 'flex '}}>
          <DropdownInput
            value={search }
            placeholder="Musterdepot suchen"
            icon={<SearchIcon color={INACTIVE_INPUT_COLOR}/>}
            onChange={setSearch}
            dropdownContent={(
              <>
                {errors ? (
                  <ErrorsContainer errors={errors} />
                ) : (
                  <ListTree
                    tree_items={getModelPortfolios()}
                    selected={selected}
                    loading={loading}
                    onSelect={onSelect}
                    title={'Musterdepots'}
                  />
                )}
              </>
            )}
            dropdownVisible={false}
          />
          {selected && (
            <PrimaryButton text="Details ansehen" customClasses={{root: classes.buttonRoot}}/>
          )}
        </div>
      </WidgetBody>
      {/* <WidgetActions>
        <PrimaryButton 
          variant="outlined"
          text="Portfolio importieren (.csv /.xls)"
          icon={<ImportIcon color={theme.palette.primary.main}/>}
        />
      </WidgetActions> */}
    </Widget>
  )
}

ModelPortfolioUploader.propTypes = {
  /** List of modelportfolios */
  modelportfolios: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

  /** Flag, that indicate, if model portfolios data is loading, or not */
  loading: PropTypes.bool
}

export default ModelPortfolioUploader

