import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.389"
      height="30.389"
      viewBox="0 0 30.389 30.389"
    >
      <g id="Gruppe_8166" data-name="Gruppe 8166" transform="translate(4)">
        <g id="Gruppe_8273" data-name="Gruppe 8273">
          <path
            id="Pfad_6872"
            data-name="Pfad 6872"
            d="M15.194,0a15.194,15.194,0,0,0,0,30.389,15.091,15.091,0,0,0,8.562-2.648,15.452,15.452,0,0,0,3.016-2.72c.194-.224.38-.456.555-.691A15.186,15.186,0,0,0,15.194,0m0,28.364A13.151,13.151,0,0,1,4.93,6.962l.169.169L23.427,25.459a13.512,13.512,0,0,1-1.133.823,13.075,13.075,0,0,1-7.1,2.082M25.88,22.883A13.73,13.73,0,0,1,25,23.964L6.594,5.554l-.169-.169a13.73,13.73,0,0,1,1.081-.875A13.162,13.162,0,0,1,25.88,22.883M13.458,17.447,15.407,19.4l-1.926,2.2a1.373,1.373,0,0,1-2.135-.08l-4.125-5.5a1.374,1.374,0,0,1,2.2-1.649l3.107,4.144Zm9.519-8.786a1.375,1.375,0,0,1,.129,1.937l-4.441,5.071-1.945-1.945L21.037,8.79a1.373,1.373,0,0,1,1.941-.129"
            transform="translate(-4)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
