import React from 'react';
import {isSectionVisible} from "../../../utils/utils";
import {Grid} from "@material-ui/core";
import {InstrumentList} from "../components/Widgets/components";
import {REPORT_TYPES} from "../../DashboardSettings/components/CustomersList/components/ReportType/constants";
import {
  getDataRetrievingParamsFromProps,
  tradingPossible
} from "../utils";
import InstrumentsSection from "../components_v2/InstrumentsSection/InstrumentsSection";
import {useUnrealizedProfitAndLossData} from "../hooks/useUnrealizedProfitAndLoss";

function InstrumentsTab(props) {
  const {customSettings} = props;

  const canTradePortfolios = props.isVirtualOrderEnabled || tradingPossible(props.instrumentListData, props.banksMapping);

  const {
    customerId,
    portfolios,
    dates,
    dataProvider,
    withHistorical
  } = getDataRetrievingParamsFromProps(props);

  const unrealizedProfitAndLoss = useUnrealizedProfitAndLossData(
    customerId, portfolios, dates.start, dates.end, withHistorical, dataProvider);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InstrumentsSection
          loading={props.instrumentListDataLoading}
          error={props.instrumentListDataLoadingError}
          expanded={props.expandedItems.instruments}
          onExpandedChanged={props.onExpandedItemsChange}
          instrumentsGroupBy={props.instrumentsGroupBy}
          onGroupByChanged={props.onInstrumentsGroupByChange}
          content={(
            <InstrumentList
              data={props.instrumentListData}
              expandedItems={props.expandedItems.instrumentsTab}
              onExpandedItemsChange={props.onExpandedItemsChange}
              showAssetsOverview={isSectionVisible(customSettings, 'instrumentsItems', props.reportType)}
              showCell={(columnKey) => {
                // returns true if column is in expanded items of the table, or it is not custom report
                return isSectionVisible(customSettings, `instrumentsItems.subcategories.${columnKey}`, props.reportType)
              }}
              isCustomerApp={props.isCustomerApp}
              isVirtualOrderEnabled={props.isVirtualOrderEnabled}
              onAddTradingOption={props.onAddTradingOption}
              onAddPortfolioTradingOption={props.onAddPortfolioTradingOption}
              banksMapping={props.banksMapping}
              tradings={props.combinedTradings}
              tradingSession={props.combinedTradingSession}
              virtualInstrumentLink={props.virtualInstrumentLink}
              reportType={props.reportType}
              isVirtual={props.isVirtual}
              triggerPortfolioDeleting={props.triggerPortfolioDeleting}
              refresh={props.refresh}
              customerData={props.customerData}
              instrumentsGroupBy={props.instrumentsGroupBy}
              canTradePortfolios={canTradePortfolios}
              unrealizedProfitAndLossData={unrealizedProfitAndLoss.data}
              withTransactionSaldo={props.chartsSettings.global.withTransactionSaldo}
              withCompletelySoldAssets={props.dashboardSettings.with_profit_loss && props.reportType && props.reportType != REPORT_TYPES.DEPOT_STATUS.value}
              completelySoldAssetsData={props.profitAndLossData}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

InstrumentsTab.propTypes = {}

InstrumentsTab.propDefs = {}

InstrumentsTab.defaultProps = {}

export default InstrumentsTab;