import React from "react";
import _ from "lodash";

import {Select, MenuItem, FormControl} from '@material-ui/core'
import useStyles from "../../../InstrumentList/components/InstrumentListItem/styles";
import {SAVING_PLANS_ACTIONS} from "../../../../../../../../components/Charts/InstrumentsAllocationTable/constants";
import {prepareComponentsWithContractNumber} from "../../../../../../utils"
import PaymentPlansTable from "../../../../../../../../components/ChartsV2/PaymentPlansTable";

export default (props) => {
  const {
    data,
    tradingOptions,
    paymentPlanType,
    skipMPGrouping
  } = props;

  const getContent = () => {
    return (
      <div style={{width: '100%', overflowX: 'auto'}}>
        <PaymentPlansTable
          data={getPaymentPlansData()}
          expanded={true}
          tradingOptions={tradingOptions}
          paymentPlanType={paymentPlanType}
        />
      </div>
    )
  };

  const getPaymentPlansData = () => {

    if (!data){
      return []
    }

    if (!data.is_model_portfolio || skipMPGrouping) {
      return _.flatten(data.components.map((component) => {
        const isHeader = !_.isEmpty(component.components)
        return [{
          ...component,
          isHeader,
          tableProps: {
            isHeader // Should be handled inside DashboardTable as header
          }}, ...(component.components || []).map((subComponent) => ({...subComponent, 'isSubComponent': true}))]
      }))
    }

    const result = prepareComponentsWithContractNumber(data, true)

    return _.flatten(_.flatten(
      Object.values(result).map((mpSavingPlan) => [mpSavingPlan, mpSavingPlan.components])))
  }

  return getContent();
};

