const styles = theme => ({
  container: {
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0px 3px 15px #00000033',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      padding: 15
    }
  },
  headerContainer: {
    marginBottom: 16,
    display: 'flex',
    zIndex: 1,
    overflow: 'hidden',
  },
  header: {
    fontFamily: "'Roboto-Regular'",
    fontSize: 22,
    color: '#4D4F5C',
    margin: 0,
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&:hover': {
      overflow: 'initial',
      wordBreak: 'break-all',
      whiteSpace: 'initial',
      textOverflow: 'initial'
    },

    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    }
  },
  titleControl: {
    paddingLeft: 8,
    paddingRight: 16,

    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingBottom: 8,
      paddingTop: 8,
    }
  },
  contentContainer: {
    '&:not(.hidden)': {
      marginBottom: 16,
    },
    flex: 1,
  },
  centerContent: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  chartExplanation: {
    marginTop: '1.5em',
  }
});

export default styles;