import {makeStyles} from '@material-ui/core';
import {smFontSize, xsFontSize, fontSize} from "../../utils/constants";

export default makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: 10,
    margin: 4
  },
  dialogContent: {
    padding: '15px 90px 90px 90px',
    maxWidth: '720px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#313D44',
    fontSize: fontSize,

    [theme.breakpoints.down("sm")]: {
      padding: '10px 50px 50px 50px',
      fontSize: smFontSize,
    },
    [theme.breakpoints.down("xs")]: {
      padding: '10px 25px 25px 25px',
      fontSize: xsFontSize,
    }
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#313D44',
  },
  title: {
    textAlign: 'center',
    paddingTop: '35px',
  },
  message: {
    marginLeft: 10,
    textAlign: 'justify',
  }

}));
