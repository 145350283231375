import React from 'react';

import {Grid} from '@material-ui/core'
import {
  hasResponseError,
  PortfolioUtils,
} from '../../../../../../../utils/utils';
import _ from 'lodash';
import BaseChart from '../../../../../../FactSheetsPage/components/BaseChart';
import useStyles from '../styles';

const SyntheticRiskRewardChart = (props) => {
  const {
    dashboardData,
    loading
  } = props;

  const classes = useStyles();

  const portfolioStructure = dashboardData && dashboardData.portfolio_structure;

  const dataError = _.isNil(portfolioStructure) ||  hasResponseError(portfolioStructure);

  let data = [];
  if (portfolioStructure) {
    data = PortfolioUtils.getPortfolioRiskIndicators(portfolioStructure)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <BaseChart
          chartType='bar'
          title={'Risikoverteilung'}
          subtitle={
            loading ? '' : data.length ? <><span>(Durchschnitt: { portfolioStructure && portfolioStructure['by_srri_average'] })</span></> : ''
          }
          seriesData={data}
          loading={loading}
          error={dataError}
          classes={{
            title: (!loading && !data.length) ? classes.titleWithoutData : classes.title,
            subtitle: classes.subtitle
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SyntheticRiskRewardChart;
