import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {Checkbox, FormControlLabel, Tooltip} from '@material-ui/core'

import useStyles from './styles'
import clsx from "clsx";
import BanksHint from '../../BanksHint';
import PrimaryButton from '../../../../../../../../../../components/Buttons/PrimaryButton';
import { DeleteIcon } from '../../../../../../../../../../images';
import IconButton from '@material-ui/core/IconButton';
import {DepotDocumentIdentifier} from "../../utils";


function DocumentsSection(props) {

  const {
    objectType,
    bankId,
    bankName,
    disabled,
    documents,
    allOptional,
    optionalDocuments,
    downloadDocuments,
    eSignDocuments,
    onDistributionSettingsChange,
    onPreviewDocumentSelect,
    errors,
    tableHeaderVisible,
    isOnline,
    onlineDocuments,
    bankNameVisible,
    singleBankSelected,
    isGeneralBank,
    onDocumentFilterModalSwitch,
    onDeleteOptionalDocument,
    additionalBankText
  } = props;

  const classes = useStyles();

  const handlePreviewDocumentSelect = (document) => (event) => {
    onPreviewDocumentSelect && onPreviewDocumentSelect(document, bankId, objectType)
  };

  const handleDistributionSettingsChange = (distributionType, documentId) => (event) => {
    event.stopPropagation();
    onDistributionSettingsChange && onDistributionSettingsChange(documentId, distributionType, event.target.checked, bankId, objectType)
  };

  const renderDocument = ({document, isOnlineDocument=false}) => {
    const docId = isOnlineDocument ? document.external_id : document.id;

    let SignCheckboxControl = isOnlineDocument ? (
        <Tooltip
          classes={{tooltip: classes.tooltip, popper: classes.tooltipPopper}}
          title={"Pflichtdokumente können nicht abgewält werden"}
          placement={"top"}
          arrow
        >
          <span>
            <Checkbox color="primary" checked={true} disabled={true} />
          </span>
        </Tooltip>
      ) : (
        <Checkbox color="primary" disabled={disabled}
          checked={eSignDocuments.includes(new DepotDocumentIdentifier(bankId, docId, objectType))}
          onChange={handleDistributionSettingsChange('eSign', docId)} />
      );

    const docErrors = _.get(errors, `${objectType}.${bankId}.${docId}`);

    return (
      <div className={classes.documentItem} key={docId}>
        <div>
          <span title={document.name}>{ document.name }</span>
          {!isOnlineDocument && (
            <>
              {optionalDocuments.includes(document) && (
                <span style={{marginRight: 10}}>
                  <IconButton onClick={() => onDeleteOptionalDocument(bankId, docId, objectType)}>
                    <DeleteIcon />
                  </IconButton>
                </span>
              )}
            </>
          )}
          <span className={classes.previewButton} onClick={handlePreviewDocumentSelect(document)}>
            <i className="far fa-eye fa-lg" style={{marginRight: 8}} />Vorschau
          </span>
        </div>
        <div className={classes.checkboxes}>
          <div>
            <FormControlLabel
              control={
                <Checkbox color="primary"
                  checked={downloadDocuments.includes(new DepotDocumentIdentifier(bankId, docId, objectType))}
                  disabled={disabled}
                  onChange={handleDistributionSettingsChange('download', docId)}
                />
              }
              classes={{
                root: classes.formControlLabelRoot,
                disabled: classes.formControlLabelDisabled
              }}
            />
          </div>
          <div>
            {document.sign_possible && (
              <FormControlLabel
                control={SignCheckboxControl}
                classes={{
                  root: classes.formControlLabelRoot,
                  disabled: classes.formControlLabelDisabled
                }}
              />
            )}
          </div>
          {!!docErrors && (
            <div style={{flexBasis: '100%'}}>
              <span className={clsx(classes.errorMessage, 'document-distribution-item-error')}>
                { docErrors }
              </span>
            </div>
          )}
        </div>
      </div>
    )
  };

  return (
    <div className={classes.container} key={bankName} style={{paddingBottom: bankNameVisible ? 5 : 0}}>

      <div className={classes.titleSection}>
        {
          bankName && bankNameVisible && <span className={classes.bankName}> { bankName }{!isGeneralBank && <>-Formulare</>} </span>
        }
      </div>
      {
        ! isGeneralBank && bankName && bankNameVisible && !singleBankSelected &&
        <div className={classes.titleHintSection}>
          <BanksHint bankName={bankName} isBankOnline={isOnline} additionalBankText={additionalBankText}></BanksHint>
        </div>
      }

      {tableHeaderVisible && (
        <div className={classes.documentsSection}>
          <div className={classes.documentItem}>
            <div style={{border: 'none', height: 0}}>
              <span></span>
            </div>
            <div className={classes.checkboxes}>
              <div>
                <span>Download</span>
              </div>
              <div>
                <span>E-Sign</span>
              </div>
            </div>

          </div>
        </div>
      )}
      <div className={classes.documentsSection}>
        {isOnline && (onlineDocuments || []).map(doc => renderDocument({document:doc, isOnlineDocument:true}))}
        {(documents || []).map(doc => renderDocument({document:doc}))}
        {!_.isEmpty(allOptional || []) && (
          <>
            <div className={classes.titleSection}>
              <span className={classes.bankName} style={{margin: '15px 0 13px'}}> Zusätzliche Dokumente </span>
            </div>
            {optionalDocuments.map(doc => renderDocument({document:doc}))}
          </>
        )}
      </div>
      {!isGeneralBank && bankId && (
        <div className={classes.documentsManagementButton}>
          <PrimaryButton
            text={'Dokument(e) hinzufügen'}
            onButtonClick={() => onDocumentFilterModalSwitch(bankId, objectType)}
          />
        </div>
      )}
    </div>
  )
}

DocumentsSection.propTypes = {
  /**Bank name, that will be displayed inside section title */
  bankName: PropTypes.string,

  /**Flag, that indicate, is documents section interaction disabled, or not */
  disabled: PropTypes.bool,

  /**List of available documents */
  documents: PropTypes.arrayOf(PropTypes.object),

  /**List of selected documents */
  selectedDocuments: PropTypes.arrayOf(PropTypes.object),

  /**Selected documents change handler */
  onSelectedDocumentsChange: PropTypes.func,

  /**Preview document select handler */
  onPreviewDocumentSelect: PropTypes.func,

  /**Flag, that indicate, is bank in General constants  */
  isGeneralBank: PropTypes.bool,

  /**Optional documents modal open handler */
  onDocumentFilterModalSwitch: PropTypes.func
};

DocumentsSection.defaultProps = {
  disabled: false,
  documents: [],
  selectedDocuments: []
};

export default DocumentsSection

