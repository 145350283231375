import React from 'react'
import PropTypes from 'prop-types'
import _  from 'lodash'


import { toGermanFormat } from '../../../../../../utils/numberFormater'
import DashboardTable from '../../../../../../components/DashboardTable'
import tableStructure, { savingsPlanTableStructure } from './table-data'

import useStyles, { useSavingsPlanTableStyles } from './styles'
import {getTransactionsTotalAmount} from "../../../../utils";
import {TRADING_OBJECT_TYPE_TITLES, TRANSACTION_TYPE_VALUES} from "../../../../constants";
import {
  TRADING_ACTION_BUY,
  TRADING_ACTION_PAYOUT_PLAN, TRADING_ACTION_SAVINGS_PLAN, TRADING_ACTION_SELL,
  TRADING_ACTION_SWITCH_PLAN
} from "../../../../../../components/Charts/InstrumentsAllocationTable/constants";
import {OBJECT_TYPES} from "../../../../../RiskProfiling/constants";

function PortfolioItem(props) {

  const {
    title,
    category,
    portfolio,
    transactions,
    rotationOptions,
    maxQtyDecimals,
    splitTransactionsByTradingType,
    isModelportfolio,
    modelPortfolioTransactionType,
  } = props;

  const isSavingsPlans = transactions.hasOwnProperty(TRANSACTION_TYPE_VALUES.SAVINGS_PLAN)
    && transactions[TRANSACTION_TYPE_VALUES.SAVINGS_PLAN].length;
  const isPayoutPlans = transactions.hasOwnProperty(TRANSACTION_TYPE_VALUES.PAYOUT_PLAN)
    && transactions[TRANSACTION_TYPE_VALUES.PAYOUT_PLAN].length;
  const isSwitchPlans = transactions.hasOwnProperty(TRANSACTION_TYPE_VALUES.SWITCH_PLAN)
    && transactions[TRANSACTION_TYPE_VALUES.SWITCH_PLAN].length;
  const isPaymentPlans = isSavingsPlans || isPayoutPlans || isSwitchPlans;

  const classes = useStyles();
  const savingsPlanClasses = useSavingsPlanTableStyles();

  const setTradingType = (transactions, type) => {
    for (let i = 0; i < transactions.length; i++) {
      if (!transactions[i].tradingType) {
        transactions[i].tradingType = type
      }
    }
  };

  const prepareSwitchInstruments = (instruments, transactions) => {
    return _.flatten((instruments || []).map(item => {
      if ((transactions.includes(item.data.isin) || transactions.includes(item.data.name)) && item.buy) {
        return [item, ...item.buy.map(switchInItem => ({
          ...switchInItem,
          tradingType: TRADING_ACTION_BUY,
          isSwitchInItem: true,
          switchOutItem: item
        }))];
      }
      return item
    }))
  };

  const getRotationOptions = (objectType) => {
    if (portfolio.isModelportfolio) {
      return {
        [OBJECT_TYPES.SAVINGS_PLAN]: portfolio.availableMPSavingsPlanPeriodicPlans,
        [OBJECT_TYPES.SWITCH_PLAN]: portfolio.availableMPSwitchPlanPeriodicPlans
      }[objectType] || portfolio.availableMPPayoutPlanPeriodicPlans
    }

    return {
      [OBJECT_TYPES.SAVINGS_PLAN]: portfolio.availableSavingsPlanPeriodicPlans,
      [OBJECT_TYPES.SWITCH_PLAN]: portfolio.availableSwitchPlanPeriodicPlans
    }[objectType] || portfolio.availablePayoutPlanPeriodicPlans
  };

  const transactionsList = React.useMemo(() => {

    let sell = transactions.sell || [];
    let buy = transactions.buy || [];
    let savings_plan = (transactions[TRANSACTION_TYPE_VALUES.SAVINGS_PLAN] || []).filter(i => i.is_changed);
    let payout_plan = (transactions[TRANSACTION_TYPE_VALUES.PAYOUT_PLAN] || []).filter(i => i.is_changed);

    setTradingType(sell, TRADING_ACTION_SELL);
    setTradingType(buy, TRADING_ACTION_BUY);
    setTradingType(savings_plan, TRADING_ACTION_SAVINGS_PLAN);
    setTradingType(payout_plan, TRADING_ACTION_PAYOUT_PLAN);

    let instruments = sell.concat(buy);
    instruments = instruments.concat(savings_plan);
    instruments = instruments.concat(payout_plan);

    const switch_items = [...(transactions.switch || []), ...(transactions[TRANSACTION_TYPE_VALUES.SWITCH_PLAN] || [])];

    instruments = instruments.concat(switch_items.map(i => ({...i, tradingType: TRADING_ACTION_SELL})))

    _.sortBy(instruments, instrument => instrument.data.name || instrument.data.isin).reverse()

    instruments = prepareSwitchInstruments(instruments, switch_items.map(i => i.data.isin || i.data.name))

    return instruments

  }, [JSON.stringify(transactions)]);

  const _transactionsListConfiguration = React.useMemo(() => {
    function orderInstruments(instruments) {
      return _.sortBy(instruments, instrument => instrument.data.name || instrument.data.isin).reverse()
    }

    return {
      trading: {
        title: TRADING_OBJECT_TYPE_TITLES[OBJECT_TYPES.TRADING],
        tableStructure: tableStructure,
        classes: classes,
        transactions: (() => {
          let sell = transactions.sell || []
          let buy = transactions.buy || []

          setTradingType(sell, TRADING_ACTION_SELL)
          setTradingType(buy, TRADING_ACTION_BUY)

          let instruments = sell.concat(buy)

          const switch_items = [...(transactions.switch || [])];

          instruments = instruments.concat(switch_items.map(i => ({...i, tradingType: TRADING_ACTION_SELL})))

          _.sortBy(instruments, instrument => instrument.data.name || instrument.data.isin).reverse()

          instruments = prepareSwitchInstruments(instruments, switch_items.map(i => i.data.isin || i.data.name))
          return instruments
        })()
      },
      savingsPlan: {
        title: TRADING_OBJECT_TYPE_TITLES[OBJECT_TYPES.SAVINGS_PLAN],
        tableStructure: savingsPlanTableStructure,
        classes: savingsPlanClasses,
        rotationOptions: getRotationOptions(OBJECT_TYPES.SAVINGS_PLAN),
        transactions: (() => {
          let savings_plan = (transactions[TRANSACTION_TYPE_VALUES.SAVINGS_PLAN] || []).filter(i => i.is_changed);
          setTradingType(savings_plan, TRADING_ACTION_SAVINGS_PLAN)
          return orderInstruments(savings_plan)
        })()
      },
      payoutPlan: {
        title: TRADING_OBJECT_TYPE_TITLES[OBJECT_TYPES.PAYOUT_PLAN],
        tableStructure: savingsPlanTableStructure,
        classes: savingsPlanClasses,
        rotationOptions: getRotationOptions(OBJECT_TYPES.PAYOUT_PLAN),
        transactions: (() => {
          let payout_plan = (transactions[TRANSACTION_TYPE_VALUES.PAYOUT_PLAN] || []).filter(i => i.is_changed);
          setTradingType(payout_plan, TRADING_ACTION_PAYOUT_PLAN)
          return orderInstruments(payout_plan)
        })()
      },
      switchPlan: {
        title: TRADING_OBJECT_TYPE_TITLES[OBJECT_TYPES.SWITCH_PLAN],
        tableStructure: savingsPlanTableStructure,
        classes: savingsPlanClasses,
        rotationOptions: getRotationOptions(OBJECT_TYPES.SWITCH_PLAN),
        transactions: (() => {

          let instruments = []

          const switch_items = [...(transactions[TRANSACTION_TYPE_VALUES.SWITCH_PLAN] || [])];

          instruments = instruments.concat(switch_items.map(i => ({...i, tradingType: TRADING_ACTION_SELL})))

          _.sortBy(instruments, instrument => instrument.data.name || instrument.data.isin).reverse()

          instruments = prepareSwitchInstruments(instruments, switch_items.map(i => i.data.isin || i.data.name))
          return instruments
        })()
      }
    }
  }, [JSON.stringify(transactions)])

  const totalAmount = React.useMemo(() => {

    return getTransactionsTotalAmount(transactions)

  }, [JSON.stringify(transactions)]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <p className={classes.portfolioName}>
          { title }
          {category && (
            <span className={classes.portfolioCategory}> - { category } </span>
          )}
        </p>
      </div>
      <div className={classes.content}>
        {!splitTransactionsByTradingType ? (
          <div>
            <DashboardTable
              tableLayout={"auto"}
              structure={isPaymentPlans? savingsPlanTableStructure : tableStructure}
              dataSource={transactionsList}
              withFooter={false}
              tableClasses={isPaymentPlans ? savingsPlanClasses : classes}
              expanded={true}
              options={{
                rotationOptions: rotationOptions || [],
                maxQtyDecimals: maxQtyDecimals,
                isModelportfolio,
                modelPortfolioTransactionType
              }}
            />
          </div>
        ) : (
          <>
            {Object.entries(_transactionsListConfiguration).map(([key, configuration]) => {

              if (!configuration.transactions.length) {
                return null
              }

              return (
                <div style={{marginBottom: 10}} key={key}>
                  <p className={classes.tableHeader}>{configuration.title}</p>
                  <DashboardTable
                    tableLayout={"auto"}
                    structure={configuration.tableStructure}
                    dataSource={configuration.transactions}
                    withFooter={false}
                    tableClasses={configuration.classes}
                    expanded={true}
                    options={{
                      rotationOptions: configuration.rotationOptions || [],
                      maxQtyDecimals: maxQtyDecimals,
                      isModelportfolio,
                      modelPortfolioTransactionType
                    }}
                  />
                </div>
              )
            })}
          </>
        )}
      </div>
      <div className={classes.totalAmount}>
        <div>Gesamt : { toGermanFormat(totalAmount) } €</div>
      </div>
    </div>
  )
}

PortfolioItem.propTypes = {
  /** Portfolio title */
  title: PropTypes.string.isRequired,

  /** Portfolio category */
  category: PropTypes.string,

  /** Transactions */
  transactions: PropTypes.shape({
    sell: PropTypes.arrayOf(PropTypes.object),
    buy: PropTypes.arrayOf(PropTypes.object),
    savings_plan: PropTypes.arrayOf(PropTypes.object),
    payout_plan: PropTypes.arrayOf(PropTypes.object)
  })
};

export default PortfolioItem

