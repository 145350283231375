import React from 'react';
import _ from 'lodash';
import { Grid } from '@material-ui/core';

import Pagination from '../../../Pagination/Pagination';
import PaginationInput from '../../../Pagination/PaginationInput';

import useStyles from './styles';

const PaginationWrapper = ({ customers, page, setPage }) => {
  const classes = useStyles();

  if (_.isEmpty(customers.data)) {
    return null;
  }

  const length = customers.data.length;

  return (
    <Grid item xs={12}>
      <Grid container className={classes.paginationWrapper}>
        <Grid item className={classes.paginationItem}>
          <span className={classes.paginationInfo}>Seite {page + 1} von {length}</span>
        </Grid>
        <Grid item className={classes.paginationItem}>
          <Pagination handlePageChanged={setPage} totalPageCount={length} currentPage={page}/>
        </Grid>
        <Grid item className={classes.paginationItem}>
          <PaginationInput
            handlePageChanged={setPage}
            totalPageCount={length}
            currentPage={page}
            label={'Seite aufrufen:'}/>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PaginationWrapper;