const styles = (theme) => ({
  loadingContainer: {
    width: '100%',
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    color: '#202A38',
    margin: 0,
    padding: 0
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 50
  },
  recordsOnPage: {
    fontSize: 16,
    fontWeight: 'Roboto-Regular',
    color: '#80858C'
  },
  paginationInputLabel: {
    fontSize: 14,
    color: '#202A38'
  },
})

export default styles