import React from 'react'
import {useFiltersContext} from "../../Filters";
import SwitchInput from "../../../../../../components/Inputs/Switch";
import {FormControl, Grid} from "@material-ui/core";
import useStyles from "./styles";
import useCommonStyles from "../common_styles";
import clsx from "clsx";
import {Skeleton} from "@material-ui/lab";

function Switch({name, label, loading, disabled, GridProps, style}) {
  const { filters, setFilterValue } = useFiltersContext()

  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const handleChange = (value) => setFilterValue(name, value)

  return (
    <Grid item {...GridProps}>
      <FormControl variant="outlined" fullWidth className={commonClasses.formControl} style={style}>
        <p className={clsx(commonClasses.inputLabel, disabled && commonClasses.inputLabelDisabled)}>{ label }</p>
        { loading ? (
          <Skeleton style={{minWidth: 88}} className={commonClasses.skeleton}/>
        ) : (
          <SwitchInput
            checked={filters[name]}
            disabled={disabled}
            onChange={handleChange}
            classes={classes}
          />
        )}
      </FormControl>
    </Grid>
  )
}

export default Switch