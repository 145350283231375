import { fromJS } from 'immutable';

import {
  ASSET_MODAL_OPENED,
  ASSET_MODAL_CLOSED,
  ASSET_MODAL_CONTENT_TYPE,
  ASSET_MODAL_TYPE,
  ASSET_MODAL_MENU_ENABLED,
  ASSET_MODAL_MENU_DISABLED
} from "./constants";

const assetModalInitialState = fromJS({
  open: false,
  contentType: ASSET_MODAL_TYPE.MENU,
  menuDisable: false
});

export function assetModalReducer(state = assetModalInitialState, action) {
  switch(action.type) {
    case ASSET_MODAL_OPENED:
      return state.set('open', true);
    case ASSET_MODAL_CLOSED:
      return state.set('open', false);
    case ASSET_MODAL_MENU_ENABLED:
      return state.set('menuDisable', false);
    case ASSET_MODAL_MENU_DISABLED:
      return state.set('menuDisable', true);
    case ASSET_MODAL_CONTENT_TYPE:
      return state.set('contentType', action.contentType);
    default:
      return state;
  }
}
