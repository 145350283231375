import React from 'react';


export default ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <path fill={color || '#0092e5'} d="M2240.21,893.535h-3.329a3.388,3.388,0,0,0-6.51,0h-3.451a.835.835,0,1,0,0,1.669h.849v8.875a2.035,2.035,0,0,0,2.057,2.007h7.6a2.035,2.035,0,0,0,2.057-2.007V895.2h.727a.835.835,0,1,0,0-1.669Zm-6.584-.8a1.7,1.7,0,0,1,1.442.8h-2.885A1.7,1.7,0,0,1,2233.626,892.732Zm3.8,11.708h-7.6a.366.366,0,0,1-.37-.361V895.2h8.339v8.875A.365.365,0,0,1,2237.426,904.44Z" transform="translate(-2226.065 -891.086)"/>
      <path fill={color || '#0092e5'} d="M2231.885,896.789a.846.846,0,0,0-.855.835v4.4a.855.855,0,0,0,1.71,0v-4.4A.846.846,0,0,0,2231.885,896.789Z" transform="translate(-2226.065 -891.086)"/>
      <path fill={color || '#0092e5'} d="M2235.367,896.789a.847.847,0,0,0-.856.835v4.4a.856.856,0,0,0,1.711,0v-4.4A.847.847,0,0,0,2235.367,896.789Z" transform="translate(-2226.065 -891.086)"/>
    </svg>
  )
}