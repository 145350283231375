import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles'

function StickyNavigation(props) {

  const classes = useStyles()

  return (
    <div className={classes.container}>
      {props.children}
    </div>
  )
}

StickyNavigation.propTypes = {

}

export default StickyNavigation

