import React from "react";
import {connect} from "react-redux";
import _ from 'lodash'

import {withStyles} from "@material-ui/core/styles";

import styles from "./styles";
import CheckboxFormElement from "../../formElement/CheckboxFormElement";
import {getUID, isRequiredQuestion} from "../../../../../utils";
import {DownloadIcon} from "../../../../../../../images";
import PrimaryButton from "../../../../../../../components/Buttons/PrimaryButton";
import Grid from "@material-ui/core/Grid";
import {executeIfPathExist, getInvestmentDynamicPath} from "../../../../../../InvestmentPlatform/utils";
import {FIELD_REQUIRED_MSG} from "../../../../../constants";

function isCheckboxQuestionValid(question, step, afterChange, service) {
  let isValid = true
  if (isRequiredQuestion(question)) {
    if (question.answer !== true) {
      isValid = false
      question.error = FIELD_REQUIRED_MSG;
    } else {
      question.error = null
    }
  }
  return isValid
}

function checkboxQuestionAnswer(question){
  return {
    question_uid: question.uid,
    answer: [question.answer || false] // if no answer provided - set as false
  }
}

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS()
});

export const CheckboxQuestionMethods = {
  required: isRequiredQuestion,
  valid: isCheckboxQuestionValid,
  answer: checkboxQuestionAnswer,
}

const CheckboxQuestion = props => {
  const {
    classes,
    question,
    onAnswerChange,
    disabled,
    custom_classes,
    dataService,
  } = props;

  const label = question.question_text || '';
  const helpText = question.info;
  const required = CheckboxQuestionMethods.required(question);
  const hasBtn = false;// ['A14', 'A1401', 'A1402'].includes(getUID(question.uid));

  const handleChange = (newValue) => {
    question.error = null;  // clean error
    onAnswerChange(question.uid, newValue);
  };

  const goToFormCenter = () => {

    const redirect = () => {
      if (props.investmentPlatform.routes && dataService && dataService.customer_id) {
        let newPath = '/' + getInvestmentDynamicPath()

        executeIfPathExist(props.investmentPlatform.routes, 'LEGAL_DOCUMENTS', (path) => {
          let url = newPath + path;
          window.open(url.replace(':customer_id', dataService.customer_id), '_blank');
        },':customer_id');
      }
    }

    let { onButtonClick } = question;

    if (!_.isNil(onButtonClick)) {

      onButtonClick().then(isValid => {
        if (isValid) {
          redirect()
        }
      })
    } else {
      redirect()
    }

  };

  const CheckboxEl = () => (
    <>
      <CheckboxFormElement
        disabled={disabled || false}
        value={question.answer || false}
        label={label}
        name={question.uid}
        onChange={handleChange}
        required={required}
        custom_classes={custom_classes}
        error={question.error}
        helpTextUsed={!!helpText}
      />
      {helpText && <div className={classes.helpText}>{helpText}</div>}
    </>
  );

  return (
    <>
      {!hasBtn && (
        <div className={classes.checkboxQuestionContainer}>
          <CheckboxEl />
        </div>
      )}
      {hasBtn && (
        <Grid container>
          <Grid item xs={8} className={classes.checkboxQuestionContainer}>
            <CheckboxEl />
          </Grid>
          <Grid item xs={12} style={{marginTop: 40, paddingLeft: 30}}>
            <PrimaryButton
              variant="outlined"
              text="Dokumente anzeigen"
              onButtonClick={goToFormCenter}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default connect(mapStateToProps)(withStyles(styles)(CheckboxQuestion));