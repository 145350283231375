import React from "react";
import clsx from "clsx";

import useStyles from "./styles";
import images from "../../../../../../../images";
import {isRequiredQuestion, isValidValue} from "../../../../../utils";
import {FIELD_REQUIRED_MSG} from "../../../../../constants";
import FormHelperText from "@material-ui/core/FormHelperText";

function isChartQuestionValid(question, step, afterChange, service) {
  if (isRequiredQuestion(question)) {
    const isValid = isValidValue(question.answer);
    question.error = isValid ? null : FIELD_REQUIRED_MSG;

    return isValid;
  }

  return true;
}

export const ChartSelectQuestionMethods = {
  required: isRequiredQuestion,
  valid: isChartQuestionValid
}

const ChartSelectQuestion = props => {
  const {
    question,
    onAnswerChange
  } = props;

  const classes = useStyles();

  const options = question.config.choices || [];
  const value = question.answer;

  const handleChange = (value) => {
    question.error = null;  // clean error
    onAnswerChange(question.uid, value);
  };

  const chartImages = [
    images.risk_profiling.chart_defensiv,
    images.risk_profiling.chart_ausgeglichen,
    images.risk_profiling.chart_offensiv
  ];

  return (
    <>
      {question.error && (
        <FormHelperText
          error={true}
          classes={{
            error: classes.errorContainer
          }}>{ question.error }</FormHelperText>
      )}
      <div className={classes.questionContainer}>
        {options.map((option, index) => (
          <div
            key={option.uid}
            className={clsx(
              classes.item,
              option.uid === value && classes.selected
            )}
            onClick={() => handleChange(option.uid)}
          >
            <div className={classes.title}>
              <span className={classes.name}>
                {option.text}
              </span>
            </div>
            <div
              className={classes.chartImage}
              style={{
                backgroundImage: `url(${chartImages[index]})`
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ChartSelectQuestion;