import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    // zIndex: '100000 !important',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',

    '&  > div:first-child': {
      backgroundColor: 'transparent'
    },
    overflowY: 'visible'
  },
  paper: {
    borderRadius: 10,
    overflowY: 'visible'
  },
  container: {
    overflowY: 'auto'
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  previousIconButton: {
    position: 'absolute',
    top: 10,
    left: 10,
    color: theme.palette.primary.main
  },
  modalTitle: {
    borderBottom: '2px solid #E1E3E9',
    textAlign: 'center',
    color: '#4D4F5C',
    paddingRight: 55,
    paddingLeft: 55,
    paddingTop: 24,
    '& > h2': {
      fontFamily: 'Roboto-Regular',
      fontSize: 22,
      lineHeight: 1.2,
      [theme.breakpoints.only('sm')]: {
        fontSize: 18
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: 16
      },
    }
  },
  assetMenu: {
    width: '100%',

    '& > p': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      fontFamily: 'Roboto-Bold',
      fontSize: 14,
      padding: '6px 25px',
      width: '100%',
      [theme.breakpoints.only('xs')]: {
        padding: '6px 14px',
      },
    },

    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 5,
      flexWrap: 'wrap'
    }
  },
  assetMenuItem: {
    display: 'flex',
    height: 70,
    flex: 1,
    margin: 2,
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#4D4F5C',
    borderRadius: 3,
    backgroundColor: '#F9F9F9',
    boxSizing: 'border-box',
    padding: 20,
    alignItems: 'center',
    transition: 'all',
    transitionDuration: '0.3s',

    '& > p': {
      marginLeft: 10,

      [theme.breakpoints.only('xs')]: {
        marginLeft: 6,
      },
    },

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#E1E3E9'
    },

    [theme.breakpoints.only('xs')]: {
      padding: 8,
      height: 'auto',
      fontSize: 13,
    },
  },
  previousStepButton: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 25,
    left: 10,

    '&:hover': {
      cursor: 'pointer'
    }
  },
  smHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  smShow: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  content: {
    overflowY: 'visible !important'
  },
  modalAction: {
    marginTop: 50,
    height: 102,
    borderTop: '2px solid #E1E3E9',
    display: 'flex',
    justifyContent: 'center',

    '& > div': {
      width: '100%',
      maxWidth: 512,
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  discardButton: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    textTransform: 'none',
    marginRight: 10,

    [theme.breakpoints.only('sm')]: {
      fontSize: 16
    },

    [theme.breakpoints.only('xs')]: {
      fontSize: 14
    }
  },
  acceptButton: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    textTransform: 'none',
    color: 'white',
    boxShadow: 'none',
    borderRadius: 2,

    [theme.breakpoints.only('sm')]: {
      fontSize: 16
    },

    [theme.breakpoints.only('xs')]: {
      fontSize: 14
    }
  },
}));