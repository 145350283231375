import React, { useImperativeHandle, forwardRef } from 'react';
import clsx from 'clsx'
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid'
import {Skeleton} from "@material-ui/lab";
import useStyles from './styles'
import useCommonStyles from '../common_styles'
import { useFiltersContext } from "../../Filters";
import TextField from '@material-ui/core/TextField';
import FilterInput from '../../../../../DocumentCenter/components/FilterInput';
import SearchIcon from '@material-ui/icons/Search';


/**
 * DropdownInput components for searching or selecting values from list of possible options.
 */
const DropdownInput = forwardRef(({ name, label, options, onInputChange, onChangeCallback, GridProps, loading, disabled, emptyErrorMessage }, ref) => {

  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const { filters, setFilterValue } = useFiltersContext();

  const selectOptions = React.useMemo(() => {

    if (_.isArray(options)) {
      return options;
    }

    return Object.entries(options || {}).map(o => ({
      ...o[1],
    }));

  }, [options]);

  const [inputValue, setInputValue] = React.useState('');
  const [validationError, setValidationError] = React.useState(undefined);

  useImperativeHandle(ref, () => ({
    isValid: () => {
      return !!filters[name]
    }
  }))

  const handleChange = (e, item) => {
    const selectedItem = _.find(selectOptions, o => o.id === (item || {}).id) || {};
    setFilterValue(name, selectedItem);
    setValidationError(undefined);
    onChangeCallback && onChangeCallback(setFilterValue, selectedItem);
  }

  const handleInputChange = (value) => {
    setInputValue(value);
  }

  return (
    <Grid item {...GridProps}>
      <FormControl variant="outlined" fullWidth className={clsx(validationError ? commonClasses.formControlError : commonClasses.formControl)}>
        <p className={clsx(commonClasses.inputLabel, disabled && commonClasses.inputLabelDisabled)}>{ label }</p>
        { loading ? (
          <Skeleton className={commonClasses.skeleton}/>
        ) : (
          <FilterInput
            multiple={false}
            options={selectOptions}
            loading={loading}
            value={filters[name]}
            customclasses={{ option: classes.option, autocompleteInput: classes.autocompleteInput}}
            onChange={(e, value) => handleChange(e, value)}
            onInputChange={(_, value) => handleInputChange(value)}
            popupIcon={<SearchIcon onClick={() => onInputChange && onInputChange(inputValue)} />}
            renderOption={(option) => (
              <div style={{
                paddingLeft: option.depth && option.depth * 10 || 0,
                fontFamily: option.depth === 1 ? 'Roboto-Bold' : null
              }}>
                <span>{option.name}</span>
              </div>
            )}
            renderInput={(params) => {
              return (
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  {...params}
                />
              )
            } }
            disabled={disabled}
          />
        )}
        {validationError && (
          <p className={commonClasses.inputLabelError}>{ validationError }</p>
        )}
      </FormControl>
    </Grid>
  );
})

export default DropdownInput;