import React from "react";
import _ from "lodash";

export function useSelectedPortfolios(customerData) {

  const [selectedPortfolios, setSelectedPortfolios] = React.useState([])

  React.useEffect(() => {

    if (customerData.errors || customerData.loading) {
      return
    }

    const portfolios = _.get(customerData, 'data.portfolios.portfolios') || []
    setSelectedPortfolios(portfolios)

  }, [customerData.updatedAt])

  return [selectedPortfolios, setSelectedPortfolios]
}