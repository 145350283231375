import React from 'react';
import { connect } from 'react-redux';
import cls from 'clsx';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';

import {
  Grid,
  Button, Tooltip,
} from '@material-ui/core';

import {
  CheckboxFilter,
  RangeSliderFilter,
} from '../../../../components/Filters';
import {
  LOWER_BOUNDARY_MODAL_MESSAGE,
  UPPER_BOUNDARY_MODAL_MESSAGE,
  INVERTED_BOUNDARIES_MODAL_MESSAGE,
} from "../../../../components/Filters/constants";
import ErrorModal from '../../../../components/ErrorModal';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import {getAgencyFilterOptions} from '../../../../components/FilteringPanel/components/ListSelector/constants';
import { getSearchCustomerType } from '../../../../components/FilteringPanel/components/ListSelector/constants';

import useStyles from './styles';
import SelectFormElement from '../../../RiskProfiling/components/StepContent/components/formElement/SelectFormElement';
import InputFormElement from '../../../RiskProfiling/components/StepContent/components/formElement/InputFormElement';
import {GroupTypeSelector} from "../index";
import {GROUP_TYPES} from "../GroupTypeSelector/GroupTypeSelector";

const mapStateToProps = (state => ({
  auth: state.get('auth').toJS()
}));

export const FINANCIAL_INFORMATION_CLASS = [
  {
    value: 1,
    description: 'Aktie',
    financialClasses: [1],
  }, {
    value: 2,
    description: 'Anleihe',
    financialClasses: [2],
  }, {
    value: 3,
    description: 'Fonds',
    financialClasses: [7],
    //financialSubclasses: [601]
  }, {
    value: 4,
    description: 'Andere',
    financialClasses: [3, 4, 5, 6],
  }
];

export const PRODUCT_STRATEGIES = [
  {
    value: 1,
    description: 'Vermögensverwaltung (Private Investing)',
    strategies: ['PI']
  }, {
    value: 2,
    description: 'BfV ETF Depot',
    strategies: ['ETF']
  }, {
    value: 3,
    description: 'Riester',
    strategies: ['RS']
  }, {
    value: 4,
    description: 'Fondspolicen',
    strategies: ['LV']
  }, {
    value: 5,
    description: 'Depotplattform',
    strategies: ['FP', 'INV']
  }
]

export const POSTBOX_STATUSES = [
  {
    value: true,
    description: 'Kunde mit elektronischem Postfach', // with postbox
  }, {
    value: false,
    description: 'Kunde ohne elektronisches Postfach',
  },
];

export const PERMANENT_SUITABILITY_CHECK_STATUSES = [
  {
    value: true,
    description: 'mit dauerhafter Geeignetheitsprüfung', // with permanent suitability check
  }, {
    value: false,
    description: 'ohne dauerhafter Geeignetheitsprüfung',
  },
];

const FiltersPanel = (props) => {
  const classes = useStyles();
  const [clientType, setClientType] = React.useState(getSearchCustomerType(props.auth))
  const [searchFilter, setSearchFilter] = React.useState({
    value: '',
    reload: false
  });

  const defaultFinancialFilters = {
    financialInformationClass: [],
    fundCategories: [],
    investmentCompanies: [],
    portfolioBanks: [],
    productStrategies: [],
    portfolioStrategiesPI: [],
    portfolioStrategiesNonPI: [],
    customerPostboxStatuses: [],
    permanentSuitabilityCheckStatuses: [],
    depotRange: [],
    withReload: false
  };

  const [financialFilters, setFinancialFilters] = React.useState(defaultFinancialFilters);
  const [expandedPanel, setExpandedPanel] = React.useState(undefined);
  const [isMounted, setIsMounted] = React.useState(false);
  const [isClientTypeMounted, setClientTypeMountStatus] = React.useState(false);
  const [agencyOptions, setAgencyOptions] = React.useState(undefined);
  const [errorMessage, setErrorMessage] = React.useState(undefined);

  React.useEffect(() => {
    if (searchFilter.reload) {
      handleSearchClick();
      setSearchFilter({
        ...searchFilter,
        reload: false
      })
    }
  }, [searchFilter.reload]);

  React.useEffect(() => {
    setAgencyOptions(getAgencyFilterOptions(props.auth))
  }, []);

  React.useEffect(() => {
    if (isClientTypeMounted) {
      handleSearchClick();
    }
    setClientTypeMountStatus(true);
  }, [clientType]);

  React.useEffect(() => {
    if (financialFilters.withReload) {
      handleSearchClick();
    }
  }, [financialFilters.withReload]);

  const getFilters = () => ({
    search: searchFilter.value.trim(),
    clientType,
    broker: props.auth,
    ...financialFilters
  });

  const checkFilters = () => {
    let filterBoundaries = props.filtersOptions.depotRange;
    let selectedFilters = financialFilters.depotRange;
    let errorMessage = undefined;
    // is selectedFilters is empty - do not set any range -> there could be no range errors
    if (props.hasCustomers && !_.isEmpty(selectedFilters)) {
      if (selectedFilters[0] < filterBoundaries[0]) {
        errorMessage = LOWER_BOUNDARY_MODAL_MESSAGE;
      } else if (selectedFilters[1] > filterBoundaries[1]) {
        errorMessage = UPPER_BOUNDARY_MODAL_MESSAGE;
      } else if (selectedFilters[1] <= selectedFilters[0]) {
        errorMessage = INVERTED_BOUNDARIES_MODAL_MESSAGE;
      }
    }

    return errorMessage;
  }

  const handleSearchClick = () => {
    if (!_.isNil(props.onFiltersUpdated)) {
      let error = checkFilters();
      if (!_.isNil(error)) {
        setErrorMessage(error);
      } else {
        props.onFiltersUpdated(getFilters());
        setFinancialFilters({
          ...financialFilters,
          withReload: false
        })
      }
    }
  };

  const handleExpandedClicked = (value) => () => {
    let result = expandedPanel;

    if (result && result == value) {
      result = undefined;
    } else {
      result = value;
    }

    setExpandedPanel(result);
  };

  const handleCheckboxValueChanged = (fieldName) => (values) => {

    if (financialFilters.hasOwnProperty(fieldName)) {

      let selectedValues = [...financialFilters[fieldName]];

      if (_.find(selectedValues, option => option.value == values.value)) {
        selectedValues = _.filter(selectedValues, (value) => value.value != values.value);
      } else {
        selectedValues = [...selectedValues, values];
      }


      setFinancialFilters({
        ...financialFilters,
        [fieldName]: selectedValues
      });
    }
  };

  const handleSelectAll = (fieldName, options) => () => {

    if (financialFilters.hasOwnProperty(fieldName)) {

      if (financialFilters[fieldName].length == options.length) {
        setFinancialFilters({
          ...financialFilters,
          [fieldName]: []
        })
      } else {
        setFinancialFilters({
          ...financialFilters,
          [fieldName]: options.map(option => option)
        })
      }
    }
  };

  const handleRangeValueChanged = (fieldName) => (values) => {
    if (financialFilters.hasOwnProperty(fieldName)) {
      setFinancialFilters(prevState => ({
          ...prevState,
          [fieldName]: values
        })
      )
    }
  }

  const handleErrorModalClose = () => {
    setErrorMessage(undefined);
  };

  const resetFilters = () => {
    let filters = {...defaultFinancialFilters};
    setFinancialFilters(filters);
    setSearchFilter({value: '', reload: true});
  }

  const hasStrategies = !_.isEmpty(financialFilters.productStrategies);

  const PIDisabled = () => hasStrategies && _.isNil(_.find(financialFilters.productStrategies, strategy => strategy.value == 1));

  const nonPIDisabled = () => hasStrategies && _.isNil(_.find(financialFilters.productStrategies, strategy => _.includes([2, 3, 4, 5], strategy.value)));

  const getAssetClassesOptions = () => {
    if (!props.filtersOptions.allowedOptions) {
      return FINANCIAL_INFORMATION_CLASS
    }

    let allowedOptions = [];

    FINANCIAL_INFORMATION_CLASS.forEach(assetClass => {

      let isAssetClassPresent = false;

      assetClass.financialClasses.forEach(financialClass => {

        if (financialClass == 6) {

          if (assetClass.financialSubclasses) {

            if (!_.isNil(_.find(props.filtersOptions.allowedOptions.financialInformationClass, item => item == 6))
                && !_.isNil(_.find(props.filtersOptions.allowedOptions.financialInformationSubclass, item => item == 601))) {

              isAssetClassPresent = true;

            }

          } else {

            if (!_.isNil(_.find(props.filtersOptions.allowedOptions.financialInformationClass, item => item == 6))
                && !_.isNil(_.find(props.filtersOptions.allowedOptions.financialInformationSubclass, item => item != 601))) {

              isAssetClassPresent = true;

            }

          }

        } else {

          if (!_.isNil(_.find(props.filtersOptions.allowedOptions.financialInformationClass, item => item == financialClass))) {
            isAssetClassPresent = true;
          }
        }
      });

      if (isAssetClassPresent) {
        allowedOptions.push(assetClass);
      }

    });

    return allowedOptions;
  }

  const onKeyDownHandler = (event) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  const handleClientTypeChanged = (event) => {
    setClientType({
      value: event.target.value,
      // data attr is str - convert back to int for "===" works properly
      type: parseInt(event.nativeEvent.target.getAttribute('data-type'))
    })
  };

  const [selectedGroupType, setSelectedGroupType] = React.useState(GROUP_TYPES.STATIC.value)
  React.useEffect(() => {
    if(!isMounted) {
      // if dynamic group is edited it already has filters
      let preloadedFilters = _.get(props.groupData, 'data.dynamic_filters')

      let hasPreloadedFilters = !_.isNil(preloadedFilters)
      // if there are preloaded filters -> automatically select them
      if (hasPreloadedFilters && !_.isEmpty(preloadedFilters)) {
        props.onFiltersUpdated(preloadedFilters);
        setFinancialFilters({
          ...financialFilters,
          ...preloadedFilters,
          withReload: false
        })
      }

      props.setIsDynamicGroupProcess(hasPreloadedFilters)
      // if groupData has dynamic_filters -> it is dynamic group else static
      setSelectedGroupType(hasPreloadedFilters ? GROUP_TYPES.DYNAMIC.value : GROUP_TYPES.STATIC.value)
      setIsMounted(true)
    }

  }, [props.filtersOptions])

  const changeSelectedGroupType = (newGroupType) => {
    setSelectedGroupType(newGroupType)
    if(newGroupType === GROUP_TYPES.DYNAMIC.value){
      // If group is dynamic search filter should be disabled
      setSearchFilter({value: '', reload: false})
      props.setIsDynamicGroupProcess(true)
    } else {
      props.setIsDynamicGroupProcess(false)
    }
  }

  const renderGroupTypeSelection = () => {
    // Function to render group type selector
    return(
      <GroupTypeSelector
        selectedGroupType={selectedGroupType}
        onSelectedGroupTypeChange={changeSelectedGroupType}
        loading={props.loading}
      />
    )
  }

  return (
    <>
      <div className={classes.container} id="create-group-filter-tour-element">
        {props.renderPageTitle && _.isFunction(props.renderPageTitle)
          ? ( props.renderPageTitle() )
          : (
            <h1 style={{
              fontFamily: 'Roboto-Regular',
              color: '#626970',
              fontSize: 34,
              fontWeight: 'normal',
              margin: 0,
              marginBottom: 10
            }}>Gruppe erstellen</h1>
          )
        }

        <Grid className={cls(classes.filters, classes.detailsFilters)}>
          <Grid className={classes.detailsFiltersHolder}>
            <Grid container className={classes.inputsWrapper}>
              <Grid item lg={12} md={6} sm={6} xs={12} className={classes.inputContainer}>
                {agencyOptions ?
                  <SelectFormElement
                    label='Kunden von'
                    disabled={props.disabled || props.loading}
                    onChange={handleClientTypeChanged}
                    value={clientType.value}
                    options={agencyOptions}
                    onChangeGetEvent={true}
                    custom_classes={{
                      inputRoot: classes.inputRoot,
                    }}
                  />
                  :
                  <Skeleton style={{width: '100%', height: 34}}/>
                }

              </Grid>
              <Grid item lg={12} md={6} sm={6} xs={12} className={classes.inputContainer}>
                <InputFormElement
                  placeholder={props.searchFilterTitle || "Suchbegriff"}
                  label={""}
                  value={searchFilter.value}
                  onChange={(value) => setSearchFilter({value, reload: false})}
                  disabled={props.disabled || props.loading  || selectedGroupType === GROUP_TYPES.DYNAMIC.value}
                  onKeyDown={onKeyDownHandler}
                  inputProps={{
                    endAdornment: (
                      <Tooltip arrow placement={"top"} classes={{tooltip: classes.tooltip}} title={
                        <> Geben Sie hier einen Suchbegriff ein.<br/>
                          Es werden die Felder <i>Kundenname, ISIN, Instrumentenname</i> und <i>Kunden-Nr</i> durchsucht.</>
                      }>
                        <i className='far fa-info-circle' />
                      </Tooltip>
                    )
                  }}
                  custom_classes={{
                    inputRoot: classes.inputRoot,
                  }}
                />
              </Grid>
            </Grid>
          {props.withGroupTypeRadio && renderGroupTypeSelection()}
          <Grid>
            <CheckboxFilter
              title="Depotstelle"
              options={props.filtersOptions.portfolioBanks || []}
              checked={financialFilters.portfolioBanks}
              handleCheckedChanged={handleCheckboxValueChanged('portfolioBanks')}
              expanded={expandedPanel && expandedPanel == 3 && !props.disabled}
              onExpandedClick={handleExpandedClicked(3)}
              handleSelectAll={handleSelectAll('portfolioBanks', props.filtersOptions.portfolioBanks || [])}
              loading={props.loading}
              disabled={props.disabled}
            />
          </Grid>
          <Grid>
            <CheckboxFilter
              title="Fondsgesellschaft"
              options={props.filtersOptions.investmentCompanies || []}
              checked={financialFilters.investmentCompanies}
              handleCheckedChanged={handleCheckboxValueChanged('investmentCompanies')}
              expanded={expandedPanel && expandedPanel == 7 && !props.disabled}
              onExpandedClick={handleExpandedClicked(7)}
              handleSelectAll={handleSelectAll('investmentCompanies', props.filtersOptions.investmentCompanies || [])}
              loading={props.loading}
              disabled={props.disabled}
            />
          </Grid>
          <Grid>
            <CheckboxFilter
              title="Anlageklasse"
              options={getAssetClassesOptions()}
              checked={financialFilters.financialInformationClass}
              handleCheckedChanged={handleCheckboxValueChanged('financialInformationClass')}
              expanded={expandedPanel && expandedPanel == 1 && !props.disabled}
              onExpandedClick={handleExpandedClicked(1)}
              handleSelectAll={handleSelectAll('financialInformationClass', getAssetClassesOptions())}
              disabled={props.disabled}
              loading={props.loading}
            />
          </Grid>
          <Grid>
            <CheckboxFilter
              title="Fondskategorie"
              options={props.filtersOptions.fundCategories || []}
              checked={financialFilters.fundCategories}
              handleCheckedChanged={handleCheckboxValueChanged('fundCategories')}
              expanded={expandedPanel && expandedPanel == 2 && !props.disabled}
              onExpandedClick={handleExpandedClicked(2)}
              handleSelectAll={handleSelectAll('fundCategories', props.filtersOptions.fundCategories || [])}
              loading={props.loading}
              disabled={props.disabled}
            />
          </Grid>
          <Grid>
            <CheckboxFilter
              title="Produktkategorie"
              options={props.filtersOptions.productCategoriesOptions}
              checked={financialFilters.productStrategies}
              handleCheckedChanged={handleCheckboxValueChanged('productStrategies')}
              expanded={expandedPanel && expandedPanel == 4 && !props.disabled}
              onExpandedClick={handleExpandedClicked(4)}
              handleSelectAll={handleSelectAll('productStrategies', props.filtersOptions.productCategoriesOptions || [])}
              disabled={props.disabled}
              loading={props.loading}
            />
          </Grid>
          <Grid>
            <CheckboxFilter
              title={<span>Vermögensverwaltungsstrategien  <br /> - Private Investing</span>}
              options={props.filtersOptions.portfolioStrategiesPI || []}
              checked={financialFilters.portfolioStrategiesPI}
              handleCheckedChanged={handleCheckboxValueChanged('portfolioStrategiesPI')}
              expanded={expandedPanel && expandedPanel == 5 && !props.disabled}
              onExpandedClick={handleExpandedClicked(5)}
              handleSelectAll={handleSelectAll('portfolioStrategiesPI', props.filtersOptions.portfolioStrategiesPI || [])}
              disabled={PIDisabled() || props.disabled || (props.filtersOptions.portfolioStrategiesPI || []).length == 0}
              loading={props.loading}
            />
          </Grid>
          <Grid>
            <CheckboxFilter
              title="Musterdepots"
              options={props.filtersOptions.portfolioStrategiesNonPI || []}
              checked={financialFilters.portfolioStrategiesNonPI}
              handleCheckedChanged={handleCheckboxValueChanged('portfolioStrategiesNonPI')}
              expanded={expandedPanel && expandedPanel == 6 && !props.disabled}
              onExpandedClick={handleExpandedClicked(6)}
              handleSelectAll={handleSelectAll('portfolioStrategiesNonPI', props.filtersOptions.portfolioStrategiesNonPI || [])}
              disabled={nonPIDisabled() || props.disabled || (props.filtersOptions.portfolioStrategiesNonPI || []).length == 0}
              loading={props.loading}
            />
          </Grid>
          <Grid>
            <CheckboxFilter
              title="Versandweg"
              options={props.filtersOptions.customerPostboxStatuses || []}
              checked={financialFilters.customerPostboxStatuses}
              handleCheckedChanged={handleCheckboxValueChanged('customerPostboxStatuses')}
              expanded={expandedPanel && expandedPanel == 8 && !props.disabled}
              onExpandedClick={handleExpandedClicked(8)}
              handleSelectAll={handleSelectAll('customerPostboxStatuses', props.filtersOptions.customerPostboxStatuses || [])}
              disabled={props.disabled}
              loading={props.loading}
              useSingleColumn
            />
          </Grid>
          <Grid>
            <RangeSliderFilter
              title="Wert der ausgewählten Depots"
              expanded={expandedPanel && expandedPanel == 9 && !props.disabled}
              onExpandedClick={handleExpandedClicked(9)}
              handleValuesChanged={(newDepotRange) => {
                // if both newDepotRange values are 0 -> set filter to default (as it was on load)
                if (newDepotRange.every(val => val === 0)) {
                 newDepotRange = []
                }
                handleRangeValueChanged('depotRange') (newDepotRange)
              }}
              initialValues={!_.isEmpty(financialFilters.depotRange) ? financialFilters.depotRange : [0, 0]}
              disabled={props.disabled}
              loading={props.loading}
              range={props.filtersOptions.depotRange || [0, 0]}
              withMinMaxPlaceholders={true}
              useSingleColumn
            />
          </Grid>
          <Grid>
            <CheckboxFilter
              title="Dauerhafte Geeignetheitsprüfung"
              options={props.filtersOptions.permanentSuitabilityCheckStatuses || []}
              checked={financialFilters.permanentSuitabilityCheckStatuses}
              handleCheckedChanged={handleCheckboxValueChanged('permanentSuitabilityCheckStatuses')}
              expanded={expandedPanel && expandedPanel == 10 && !props.disabled}
              onExpandedClick={handleExpandedClicked(10)}
              handleSelectAll={handleSelectAll('permanentSuitabilityCheckStatuses', props.filtersOptions.permanentSuitabilityCheckStatuses || [])}
              disabled={props.disabled}
              loading={props.loading}
              useSingleColumn
            />
          </Grid>
          </Grid>
          <Grid className={classes.filtersAction}>
            <PrimaryButton
              text={"Filter übernehmen"}
              onButtonClick={handleSearchClick}
              disabled={props.loading || props.disabled}
            />
            <Button
              color="primary"
              className={classes.clearFiltersButton}
              onClick={resetFilters}
              disabled={props.loading || props.disabled}
            >
              Zurücksetzen
            </Button>
          </Grid>
        </Grid>
      </div>
      {errorMessage && (
        <ErrorModal
          message={errorMessage}
          handleClose={handleErrorModalClose}
        />
      )}
    </>
  )
};

export default connect(mapStateToProps)(FiltersPanel);
