import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  section: {
    '& h2': {
      fontFamily: 'Roboto-Bold',
      fontSize: 24,
      color: '#0D2440',
      fontWeight: 'normal'
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  tableCell: {
    width: 100,
    '&:first-child': {
      width: 350
    }
  },
})

export default makeStyles(styles)