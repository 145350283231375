const styles = (theme) => ({
  cardsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "13px",
  },
  container: {
    borderRight: "1px solid #DCDFE6",
  },
  item: {
    paddingRight: "15px!important",
  },
});

export default styles;
