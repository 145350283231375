import React from "react";
import _ from "lodash";
import {getFetchDataLoadingState} from "../../../hooks/constants";
import {splitProducts} from "./useYearlyPerformanceData";
import {PortfolioHandlerResource} from "../../../utils/api";
import {getErrorMessage} from "../../../utils/utils";
import {buildProductIdUniq, getProductCustomerId, getProductIdsAsString} from "../utils";
import {PRODUCT_TYPE} from "../constants";

// for 'container like' products, product_id is (mp, invest strat, etc.) id in our DB -> use type for uniq key
const getProductDataIdType = (p) => {
  if (p.type === PRODUCT_TYPE.PRIVATE_INVESTMENT){
    return [_.get(p, 'data.related_model_portfolio.id'), PRODUCT_TYPE.MODEL_PORTFOLIO];
  } else {
    return [p.product_id, p.type]
  }
}
const getProductUniqKey = p => {
  const [productId, productType] = getProductDataIdType(p);

  return buildProductIdUniq(productId, productType);
};

function useSustainabilityMetricsData(products, displayNotification) {

  const updateStateWithFetchedProductsData = (response, productsToFetchDataFor) => {
    setProductsToUse((current) => {
      let updatedProductsToUse = {...current}

      productsToFetchDataFor.map(product => {
        const productKey = getProductUniqKey(product)
        updatedProductsToUse[productKey] = {
          ...updatedProductsToUse[productKey],
          ..._.get(response, `data.${productKey}`, {}),
          loading: false,
          error: undefined,
          updatedAt: +new Date()
        }
      })

      return updatedProductsToUse

    })

    setTableStructure(response.table_structure)
  }

  const updateFetchedProductWithErrors = (error, productsToSetErrorsFor) => {
    setProductsToUse((current) => {
      let updatedProductsToUse = {...current}
      productsToSetErrorsFor.map(product => {
        const productKey = getProductUniqKey(product)
        updatedProductsToUse[productKey] = {
          ...updatedProductsToUse[productKey],
            loading: false,
            error,
            updatedAt: +new Date()
        }
      })

      return updatedProductsToUse

    })
  }

  const fetchAssetsSustainabilityMetricsData = async(singleAssets) => {
    const requestData = {
      isins: singleAssets.map(product => product.product_id)
    }

    try{
      let response = await PortfolioHandlerResource.at(`customer/product-comparisons/get_assets_sustainability_metrics/`).get(requestData)
      updateStateWithFetchedProductsData(response, singleAssets)
    } catch (e) {
      updateFetchedProductWithErrors(e, singleAssets)
    }

  }

  const fetchContainersSustainabilityMetricsData = async(containersProducts) => {
    const requestData = containersProducts.map(product => {
      const [productId, productType] = getProductDataIdType(product);
      return {
        id: productId,
        type: productType,
        depot_id: _.get(product, 'configuration.depot_id'),
        customer_id: getProductCustomerId(product, false),
      }
    });

    try{
      let response = await PortfolioHandlerResource.at(`customer/product-comparisons/get_sustainability_metrics/`).post(requestData);
      updateStateWithFetchedProductsData(response, containersProducts)
    } catch (e) {
      updateFetchedProductWithErrors(e, containersProducts)
    }

  }

  const [productsToUse, setProductsToUse] = React.useState({});
  const [tableStructure, setTableStructure] = React.useState({});

  React.useEffect(() => {
    if (!_.isEmpty(products)) {
      let updatedProductsToUse = {}

      products.map((product, index) => {
        const productKey = getProductUniqKey(product)
        // if data for product was not fetched or there was no data - add it to fetch data again
        if (!(productKey in productsToUse) || _.isNil(productsToUse[productKey].data)){
          updatedProductsToUse[productKey] = {
            ...getFetchDataLoadingState(), // set loading data for portfolios that will be fetched
            ...product,
            order: index // allProducts are sorted, use index in allProducts to sort later
          }
        }
      })

      if(!_.isEmpty(updatedProductsToUse)){
        // extend productsToUse with new ones
        setProductsToUse({
          ...productsToUse,
          ...updatedProductsToUse
        })
        fetchSustainabilityMetricsData(updatedProductsToUse)
      }
    }

  // triggered when selected products are changed
  }, [getProductIdsAsString(products)])

  React.useEffect(() => {
    // use effect to update configuration of the product in data
    if(!_.isEmpty(productsToUse)){
      products.map(p => {
        let uniqId = getProductUniqKey(p);
        if(uniqId in productsToUse){
          setProductsToUse(currentProductsToUse => ({
            ...currentProductsToUse,
            [uniqId]: {...currentProductsToUse[uniqId], order: p.configuration.order, configuration: p.configuration}
          }))
        }
      })
    }
  }, [products])

  const fetchSustainabilityMetricsData = async (productsToFetchDataFor) => {
    try {
      const [assetsProducts, containersProducts] = splitProducts(Object.values(productsToFetchDataFor));
      !_.isEmpty(assetsProducts) && fetchAssetsSustainabilityMetricsData(assetsProducts)
      !_.isEmpty(containersProducts) && fetchContainersSustainabilityMetricsData(containersProducts)

    } catch (errors) {
      displayNotification('error', getErrorMessage(errors))
    }
  }

  console.log(productsToUse)

  // returns dict with data for currently selected products and table structure
  return [_.pickBy(productsToUse, product => product.configuration.active), tableStructure]


}

export default useSustainabilityMetricsData;