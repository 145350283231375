import {makeStyles} from "@material-ui/core/styles";
import images from "../../../../../../../images";

const useStyles = makeStyles(theme => ({
  questionContainer: {
    maxWidth: 768,
    margin: '0 auto',
  },
  errorContainer: {
    fontFamily: 'Roboto-Bold',
    padding: '0 1rem',
    fontSize: 16,
    margin: '0 1rem',
  },
  item: {
    height: 70,
    border: '1px solid #D8DCDF',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    paddingRight: 60,
    position: 'relative',
    fontSize: 16,
    margin: 16,
    cursor: 'pointer',
  },
  selected: {
    fontFamily: 'Roboto-Bold',
    border: 'none',
    boxShadow: '0px 3px 15px #00000033',

    '&::after': {
      position: 'absolute',
      right: 0,
      content: '""',
      width: 32,
      height: 32,
      top: '50%',
      transform: 'translate(-50%, -16px)',
      backgroundImage: `url(${images.select_list_icon})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain'
    }
  },
}));

export default useStyles;