import React from 'react';
import _ from 'lodash';
import clsx from "clsx";
import Tooltip from '../../components/Tooltip';

import {
  FormControlLabel,
  Checkbox,
  ExpansionPanelDetails,
  CircularProgress
} from '@material-ui/core';

import useStyles from './styles';
import Grid from "@material-ui/core/Grid";

const TabWithCheckboxFilter = ({
  title,
  options,
  checked,
  handleCheckedChanged,
  handleSelectAll,
  id,
  disabled,
  loading,
  useSingleColumn,
  ...props
}) => {

  const classes = useStyles();

  const isChecked = (item) => {
    return !_.isNil(checked) && !_.isNil(_.find(checked, checked => checked.value === item.value))
  };
  const isAllChecked = () =>  {
    let optionsToCheck = options;
    if (props.availableOptions) {
      optionsToCheck = props.availableOptions;
    }

    return checked && optionsToCheck && Object.keys(optionsToCheck).length == checked.length
  };

  const renderCheckboxItem = (key, item) => (
    <Grid item xs={useSingleColumn ? 12 : 4} lg={useSingleColumn ? 12 : 3}>
      <Tooltip title={item.description} placement={"top"}>
    <FormControlLabel
      key={key}
      classes={{
        label: classes.checkboxLabel,
        root: classes.checkboxFormControlLabel
      }}
      id={id}
      control={
        <Checkbox
          checked={isChecked(item)}
          onChange={() => handleCheckedChanged(item)}
          value={item.value}
          classes={{
            root: classes.checkboxRoot,
            checked: classes.checkboxChecked
          }}
          color="primary" />
      }
      label={item.description}
    />
    </Tooltip>
    </Grid>
  );

  const renderAllCheckedCheckbox = () => (
    <FormControlLabel
      classes={{
        label: classes.checkboxLabel,
      }}
      control={
        <Checkbox
          checked={isAllChecked()}
          onChange={() => handleSelectAll && handleSelectAll()}
          classes={{
            root: classes.checkboxRoot,
            checked: classes.checkboxChecked,
          }}
          color="primary" />
      }
      label={'Alle auswählen'}
    />
  )

  const renderOptions = (options) => {
    return Object.keys(options).map(key => {
      return renderCheckboxItem(key, options[key]);
    });
  };

  const renderFilters = () => {
    let optionsCopy = {...options};
    if (props.availableOptions) {
      Object.keys(options).forEach((key, index) => {
        if (!_.includes(props.availableOptions, options[key].value)) {
         delete optionsCopy[key];
        }
      });
    }

    return (
      <Grid container spacing={1} direction={options && options.length > 12 ? "row": "column"}>
        {renderOptions(optionsCopy)}
      </Grid>
    )
  };

  return (
    <div className={classes.checkboxContainer}>
        <ExpansionPanelDetails
          classes={{
            root: classes.checkboxFilterDetailsRoot
          }}
        >
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loadingIndicator}/>
            </div>
          ) : (
            <div className={clsx(classes.checkboxSection)}>
              <div>
                {renderAllCheckedCheckbox()}
              </div>
              <div>
                {renderFilters()}
              </div>
            </div>
          )}
        </ExpansionPanelDetails>
    </div>
  )
}

export default TabWithCheckboxFilter;