import React from 'react';
import moment from "moment";
import connect from "react-redux/es/connect/connect";
import { Grid } from '@material-ui/core';
import _ from 'lodash';

import useStyles from '../styles';
import images from '../../../../images';
import TextField from '../TextField';
import DateField from '../DateField';
import CurrencyField from '../CurrencyField';
import QuantityField from '../QuantityField';
import SearchField from '../SearchField';
import NonFieldErrors from '../NonFieldErrors';

import {PortfolioManagerResource} from "../../../../utils/api";
import SearchButton from '../../../Buttons/SearchButton/index';
import {displayErrorSnackBar} from "../../../../components/SnackbarProvider/actions"


export default connect()((props) => {
  const classes = useStyles();

  const {
    handleFormChange,
    asset,
    errors,
    customerId,
    dispatch,
    setInfoUpdated
  } = props;

  const [model, setModel] = React.useState({
    isin: undefined,
    name: undefined,
    quantity: undefined,
    value: undefined,
    value_date: undefined,
    buy_price: undefined,
    buy_date: undefined,
    exist_in_system: false,
    updated: undefined,
  });

  React.useEffect(() => {
    if (asset) {
      const _model = {
        isin: asset.isin,
        name: asset.name,
        quantity: asset.quantity,
        value: asset.value,
        value_date: asset.value_date && moment(asset.value_date),
        buy_price: asset.buy_price,
        buy_date: asset.buy_date && moment(asset.buy_date),
        updated: asset.updated && moment(asset.updated + 'Z'),
        exist_in_system: asset.exist_in_system
      };
      setModel(_model);
    }
  }, [asset]);


  const prepareFormData = () => {
    return {
      isin: model.isin,
      name: model.name,
      quantity: model.quantity,
      value: model.value,
      value_date: model.value_date && moment(model.value_date).format('YYYY-MM-DD'),
      buy_price: model.buy_price,
      buy_date: model.buy_date && model.buy_date.format('YYYY-MM-DD'),
      exist_in_system: model.exist_in_system
    }
  };

  React.useEffect(() => {
    handleFormChange && handleFormChange(prepareFormData());
  }, [model]);

  const handleModelChange = (field) => (event) => {
    // we need store value in local variable cause event.target might be changed when setModel callback will be executed
    const value = event.target.value;

    if(field === 'isin'){
      setInfoUpdated && setInfoUpdated(false);
    }

    setModel((prevState) => ({
      ...prevState,
      [field]: value
    }))
  };

  const handleDateChanged = (field) => (value) => {
    setModel((prevState) => ({
      ...prevState,
      [field]: value
    }))
  };

  const handleGetAssetInfo = () => {
    if (customerId && model && model.isin) {
      PortfolioManagerResource.at(`customer/${customerId}/asset-info/?isin=${model.isin}`).get()
      .then((response) => {
        setInfoUpdated && setInfoUpdated(true);
        if(Object.values(response).map(key => _.isNil(key)).every(i => i === false)){
          setModel((prevState) => ({
            ...prevState,
            ...response,
            exist_in_system: true,
          }));
        }
        else {
          setModel((prevState) => ({
            ...prevState,
            exist_in_system: false,
          }));
        }
      })
      .catch(error => {
        dispatch(displayErrorSnackBar('Die Wertpapierinformationen konnten nicht abgeglichen werden.'))
      });
    }
  };


  return (
    <Grid container className={classes.formContainer} id='asset-form-modal-tour-element'>
      <Grid item className={classes.formBody}>
        <div className={classes.formHeader}>
          <div>
          <img src={images.asset_icons.external_icon_white} alt="External"/>
          </div>
          <p>Wertpapiere</p>
        </div>

        <div>
          <SearchField
            label="ISIN"
            error={errors && errors.isin}
            helperText={model.isin && !model.exist_in_system && 'Das Wertpapier wurde noch nicht abgeglichen.'}
            value={model.isin}
            onChange={handleModelChange('isin')}
            endAdornment={
              <SearchButton
                classes={{
                  searchButton: classes.labelButton
                }}
                icon={<i className={"fa fa-search"} />}
                text="Suche starten"
                onValueApprove={handleGetAssetInfo}
                isSearchDisabled={!model.isin || model.isin.length < 3} //as cios need at least 3 characters
              />
            }
          />
          <TextField
            label="Name"
            error={errors && errors.name}
            value={model.name}
            onChange={handleModelChange('name')}
            disabled={model.isin && model.exist_in_system}
          />
          <QuantityField
            label="Anteile"
            error={errors && errors.quantity}
            value={model.quantity}
            onChange={handleModelChange('quantity')}
          />
          <CurrencyField
            label="Wert"
            error={errors && errors.value}
            value={model.value}
            onChange={handleModelChange('value')}
            disabled={model.isin && model.exist_in_system}
          />
          <DateField
            label="Wertdatum"
            maxDate={moment()}
            value={model.value_date}
            error={errors && errors.value_date}
            onChange={handleDateChanged('value_date')}
            disabled={model.isin && model.exist_in_system}
          />
          <CurrencyField
            label="Einstandswert"
            value={model.buy_price}
            error={errors && errors.buy_price}
            onChange={handleModelChange('buy_price')}
          />
          <DateField
            label="Einstandsdatum"
            maxDate={moment()}
            value={model.buy_date}
            error={errors && errors.buy_date}
            onChange={handleDateChanged('buy_date')}
          />
          <DateField
            label="Stand vom"
            value={model.updated || moment()}
            disabled={true}
          />
          {errors && errors['non_field_errors'] && <NonFieldErrors errors={errors['non_field_errors']}/>}
        </div>
      </Grid>
    </Grid>
  )
})