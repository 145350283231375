import React from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Grid,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'

import {
  Close
} from '@material-ui/icons'

import PrimaryButton from '../../../../../components/Buttons/PrimaryButton'

import useStyles from './styles'
import {
  SuccessIcon
} from '../../../../../images'

function InstrumentSuccessCreationModal(props) {

  const classes = useStyles();

  return (
    <Dialog maxWidth="sm" fullWidth>
      <DialogContent>
        <Grid className={classes.container}>
          <SuccessIcon />
          <p>Instrument(e) wurde erfolgreich hinzugefügt. Möchten Sie die Expertenfilter verlassen?</p>
        </Grid>
      </DialogContent>
      <DialogActions classes={{root: classes.dialogActionsRoot}}>
        <PrimaryButton text="Nein, weiter filtern" variant="outlined"/>
        <PrimaryButton text="Ja, verlassen"/>
      </DialogActions>
    </Dialog>
  )
}

InstrumentSuccessCreationModal.propTypes = {

}

export default InstrumentSuccessCreationModal

