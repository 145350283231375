export const GUIDE_TOUR_OPENED = 'GUIDE_TOUR_OPENED';
export const GUIDE_TOUR_CLOSED = 'GUIDE_TOUR_CLOSED';
export const GUIDE_TOUR_MODAL_OPENED = 'GUIDE_TOUR_MODAL_OPENED';
export const GUIDE_TOUR_MODAL_CLOSED = 'GUIDE_TOUR_MODAL_CLOSED';
export const GUIDE_TOUR_STEPS_UPDATED = 'GUIDE_TOUR_STEPS_UPDATED';
export const GUIDE_TOUR_STEPS_DELETED = 'GUIDE_TOUR_STEPS_DELETED';
export const GUIDE_TOUR_LAST_STEP_INDEX = 'GUIDE_TOUR_LAST_STEP_INDEX';
export const GUIDE_TOUR_UPDATE_TRIGGER = 'GUIDE_TOUR_UPDATE_TRIGGER';

export const MENU_OPENED = 'MENU_OPENED';
export const MENU_CLOSED = 'MENU_CLOSED';

export const SERIES_DROPDOWN_OPENED = 'SERIES_DROPDOWN_OPENED';
export const SERIES_DROPDOWN_CLOSED = 'SERIES_DROPDOWN_CLOSED';

export const ANALYSE_DROPDOWN_OPENED = 'ANALYSE_DROPDOWN_OPENED';
export const ANALYSE_DROPDOWN_CLOSED = 'ANALYSE_DROPDOWN_CLOSED';
