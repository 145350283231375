import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  progressText: {
    color: 'white',
    fontFamily: 'Roboto-Bold',
  },
  signProgressText: {
    color: 'white',
    marginLeft: '15px',
    fontSize: '16px'
  }
});

export default makeStyles(styles);