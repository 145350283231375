import images, { EquityIcon, ImmobilienIcon, InsuranceIcon, CallMoneyIcon, FileInvoiceDollarIcon } from '../../../../../../images';
import React from 'react';

export const PARTICIPATION_CATEGORY = {
    id: 1,
    title: 'Beteiligung / Firmenanteile',
    icon: images.asset_icons.equity_icon_white,
    reactIcon: <EquityIcon/>,
    url: 'participation',
  };

export const INSURANCE_CATEGORY = {
    id: 2,
    title: 'Versicherungen',
    icon: images.asset_icons.insurance_icon_white,
    reactIcon: <InsuranceIcon />,
    url: 'insurance',
  };

export const CALL_MONEY_CATEGORY = {
    id: 3,
    title: 'Fest- und Tagesgeld',
    icon: images.asset_icons.save_cash_icon_white,
    reactIcon: <CallMoneyIcon />,
    url: 'call-money',
  };

export const REAL_ESTATE_CATEGORY = {
    id: 4,
    title: 'Immobilien',
    icon: images.asset_icons.immobilien_icon_white,
    reactIcon: <ImmobilienIcon/>,
    url: 'real-estate',
  };

export const EXTERNAL_CATEGORY = {
  id: 5,
  title: 'Wertpapiere',
  icon:  images.asset_icons.external_icon_white,
  reactIcon: <FileInvoiceDollarIcon />,
  url: 'external',
};

export const ASSET_CATEGORIES = [
  REAL_ESTATE_CATEGORY, PARTICIPATION_CATEGORY, INSURANCE_CATEGORY, CALL_MONEY_CATEGORY, EXTERNAL_CATEGORY
];

export const CLEARING_ACCOUNT_ASSET_TYPE = {
  id: 'CLEARING_ACCOUNT_ASSET',
  title: 'Verrechnungskonten',
  icon: images.asset_icons.save_cash_icon_white,
  reactIcon: <i className="far fa-hand-holding-usd" />,
  url: 'clearing-accounts',
};

export const ASSET_CATEGORIES_WITH_CLEARING_ACCOUNTS = [
  ...ASSET_CATEGORIES, CLEARING_ACCOUNT_ASSET_TYPE
]

export const ASSET_CATEGORIES_WITH_CLEARING_ACCOUNTS_AS_DICT = {
  [PARTICIPATION_CATEGORY.id]: PARTICIPATION_CATEGORY,
  [INSURANCE_CATEGORY.id]: INSURANCE_CATEGORY,
  [CALL_MONEY_CATEGORY.id]: CALL_MONEY_CATEGORY,
  [REAL_ESTATE_CATEGORY.id]: REAL_ESTATE_CATEGORY,
  [EXTERNAL_CATEGORY.id]: EXTERNAL_CATEGORY,
  [CLEARING_ACCOUNT_ASSET_TYPE.id]: CLEARING_ACCOUNT_ASSET_TYPE,
};

export const ASSET_CATEGORIES_COLORS = ['#F2CD5C', '#88C9F1', '#7F8EBC', '#E28686', '#F2A8C3', '#EFA963'];
