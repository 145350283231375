import React from 'react';

import useStyles from './styles';


export default () => {
  const classes = useStyles();

  return (
    <div className={classes.loadingContainer}>
      <div className={classes.loadingLine}></div>
      <div className={classes.loadingLine}></div>
      <div className={classes.loadingLine}></div>
    </div>
  )
}