/* Common React imports */
import React from 'react';
import { Link, withRouter } from "react-router-dom";
import styles from "./styles";
import clsx from "clsx";

/* Material UI Components*/
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import { FactSheetsHandlerResource } from "../../utils/api";
import FondInformationTab from "./Tabs/FondInformationTab";
import FundStructureTab from "./Tabs/FundStructure";
import KeyFiguresAndRatingsTab from "./Tabs/KeyFiguresAndRatings";
import PerformanceTab from "./Tabs/Performance";
import CommercialInfoTab from "./Tabs/CommercialInfo";
import TargetMarketTab from "./Tabs/TargetMarket";
import CircularProgress from "@material-ui/core/CircularProgress";
import MsRatingStar from "./components/MsRatingStar";
import GetPdfButton from './components/GetPdfButton';
import AddInstrumentButton from './components/AddInstrumentButton';
import FavoriteList from "./components/FavoriteList";
import ModelPortfolioList from "./components/ModelPortfolioList";
import ErrorModal from "../../components/ErrorModal";
import {PERMISSION_ERROR_MODAL_MESSAGE} from "../../utils/constants";
import {brokerLogout, customerLogout} from "../Authentication/Auth/utils";
import {getInvestmentDynamicPath} from "../InvestmentPlatform/utils";
import {connect} from "react-redux";
import Tooltip from "../../components/Tooltip";
import SustainabilityTab from './Tabs/SustainabilityTab';
import moment from "moment";
import { getInstrName } from '../Modelportfolios/utils';
import {
  AddProductsToComparison
} from "../../components/SelectForProductsComparisonModal/SelectForProductsComparisonModal";
import {getSubSystem} from "../../utils/utils";
import _ from "lodash";

const createState = function (dataKey, data, error) {
  let stateObject = {};
  stateObject[dataKey + 'Data'] = data;
  stateObject[dataKey + 'Error'] = error;
  stateObject[dataKey + 'Loading'] = false;
  return stateObject;
}

export const processResponse = function (xhrFunc, responseKey, dataKey, dataHolder, callback) {
  xhrFunc
    .then((data) => {
      let processedData = data[responseKey];

      if (processedData['error']) {
        dataHolder.setState(createState(dataKey, null, processedData['error']))
      } else {
        dataHolder.setState(createState(dataKey, processedData['data'], null))
      }

      if (callback) callback(processedData);
    })
    .catch((error) => {
      if(error.status === 403) {
         if(dataHolder.props.permErrorHandler) {
             dataHolder.props.permErrorHandler()
         }
      } else dataHolder.setState(createState(dataKey, null, error))
    })
}

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
  auth: state.get('auth').toJS(),
});

class FactSheetsPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isin: props.match.params.isin,
      selectedTabId: 0,
      dataProvider: FactSheetsHandlerResource,
      assetInfoData: null,
      assetInfoError: null,
      assetInfoLoading: true,

      showFavlist: false,
      showMpList: false,

      permissionError: null,
      startDate: {date: null, title: null},

      investmentDynamicPath: getInvestmentDynamicPath(),
    }

    this.isCustomer = props.auth.user.is_customer;

    this.handleChange = this.handleChange.bind(this);
    this.isFund = this.isFund.bind(this);
    this.startDateCallback = this.startDateCallback.bind(this);
    this.clickOpenFavorite = this.clickOpenFavorite.bind(this);
    this.clickCreateFavorite = this.clickCreateFavorite.bind(this);
    this.clickOpenModelportfolio = this.clickOpenModelportfolio.bind(this);
    this.clickCreateModelportfolio = this.clickCreateModelportfolio.bind(this);
    this.finishFavList = this.finishFavList.bind(this);
    this.finishMpList = this.finishMpList.bind(this);
    this.hideFactsheet = this.hideFactsheet.bind(this);
    this.handleErrorOnChange = this.handleErrorOnChange.bind(this);

    this.tabs = [
      { name: 'Fondsinformation', fund: true, component: FondInformationTab, callback: this.startDateCallback},
      { name: 'Fondsstruktur', fund: true, component: FundStructureTab, settingName: 'fondsstruktur_visible'},
      { name: 'Kennzahlen & Ratings', fund: true, component: KeyFiguresAndRatingsTab, settingName: 'kennzahlenratings_visible'},
      { name: 'Wertentwicklung', fund: true, component: PerformanceTab, callback: this.startDateCallback, settingName: 'wertentwicklung_visible'},
      { name: 'Handelsinformationen', fund: true, component: CommercialInfoTab, hideForCustomer: true, settingName: 'handelsinformationen_visible'},
      { name: 'Zielmarkt', fund: true, component: TargetMarketTab, hideForCustomer: true, settingName: 'zielmarkt_visible'},
    ];
    this.esgEnabled = _.get(getSubSystem('esg'), 'config.factsheets_enabled');
    if (this.esgEnabled) {
      this.tabs.splice(4, 0, { name: 'Nachhaltigkeit', fund: true, component: SustainabilityTab, settingName: 'kennzahlenratings_visible'})
    }

  }

  startDateCallback(data) {
    this.setState({
      startDate: {
        date: data.date, title: data.title
      },
    })
  }

  componentDidMount() {
    this.fetchIsinData();

    document.body.classList.add('factsheets-page');
  }

  handleChange(event, newValue) {
    if (newValue !== undefined) {
      this.setState({
        selectedTabId: newValue,
        startDate: {
          date: this.state.assetInfoData.inception_date, title: 'INCEPTION'
        },
      })
    }
  }

  handleErrorOnChange() {
    this.setState({
      permissionError: PERMISSION_ERROR_MODAL_MESSAGE
    })
  }

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  fetchIsinData() {
    processResponse(
      this.state.dataProvider.getDataV1AssetInfo(this.state.isin),
      'asset_info', 'assetInfo', this
    )
  }

  isFund() {
    let ad = this.state.assetInfoData;
    if(!this.state.assetInfoError && ad) {
        return ad.is_fund || ad.is_bond || ad.is_equity;
    }
    return false
  }

  renderLoading() {
    return <div className={this.props.classes.loadingLine}><p /></div>
  }

  renderError() {
    const { classes } = this.props;
    return <Typography className={classes.notFundMsg} variant="h4">
              Die Factsheets können leider nicht angezeigt werden, da die Datenquelle nicht erreichbar ist.<br/>
            </Typography>
  }

  showTab(tab) {
      let hideTab = (tab.hideForCustomer && this.isCustomer) || !this.isFund();
      return !hideTab;
  }

  renderInstrumentName() {
    const { classes } = this.props;
    return this.state.assetInfoData && (<>
      <Typography variant="h2" display="inline" className={classes.headline}>
        <span className={classes.fullName}>{getInstrName(this.state.assetInfoData)}</span>
        <span className={classes.msRating}><MsRatingStar value={this.state.assetInfoData.fund_star_rating_overall} /></span>
        <br/>
        <Typography variant="h3" display="inline" className={classes.subheadline}>
          {this.state.assetInfoData.isin}
        </Typography>
      </Typography>
      </>
    );
  }

  renderTabCaption(tab, index, disabled) {
    const { classes } = this.props;

    let tabElem = <Tab label={tab.name} {...this.a11yProps(index)}
        className={clsx(classes.tabTitle, this.state.selectedTabId === 0 && classes.tabTitleSelected)}
        classes={{ selected: classes.selected }} disabled={disabled}
        disableRipple={true} key={'tab' + index}
    />
    if(disabled) {
      tabElem = <Tooltip title={'Durch aktuell fehlende Fondsdaten kann dieser Bereich nicht angezeigt werden.'} placement={"bottom"}>
        <span>{tabElem}</span>
      </Tooltip>
    }
    return tabElem
  }

  renderTabsCaptions() {
    const { classes } = this.props;
    let self = this;
    let tabs = [];
    self.tabs.forEach((tab, index) => {
      (self.showTab(tab)) && tabs.push(
          self.renderTabCaption(tab, index, !this.allowShowSetting(tab.settingName))
      )
     })
    if ( this.isFund() ) {
      return tabs;
    } else {
      return <Typography className={classes.notFundMsg} variant="h4" display="inline">
              Zur Zeit sind nur Fonds-Factsheets verfügbar
            </Typography>
    }
  }

  renderTabsPanels() {
    const { classes } = this.props;
    let self = this;
    let tabs = [];

    let tabsSettings = {
        Fondsinformation: {allowMsFactsheetDocument: !this.allowShowSetting('fincitefactsheet_visible')},
    }

    self.tabs.forEach((tab, index) => (
      (self.showTab(tab)) && tabs.push(
        <TabPanel value={self.state.selectedTabId} index={index} className={classes.tabContent} key={'tab' + index}>
          <tab.component data={self.state.assetInfoData}
                         settings={tabsSettings[tab.name] || {}}
                         permErrorHandler={this.handleErrorOnChange}
                         esgEnabled={this.esgEnabled}
                         callback={tab.callback}/>
        </TabPanel>
      )
    ))
    return tabs;
  }

  handleErrorModalClose() {
    if (this.props.auth.user && this.props.auth.user.is_customer) {
      return customerLogout();
    }

    if (this.props.auth.user && this.props.auth.user.is_broker) {
      return brokerLogout();
    }
  };

  clickOpenFavorite() {
    document.body.classList.add('add-instr-opened')
    this.setState({showFavlist: !this.state.showFavlist})
  }

  clickCreateFavorite() {
    let url = `/create_favlist?isin=${ this.state.isin}`;
    this.props.history.push(`/${this.state.investmentDynamicPath}${url}`)
  }

  clickOpenModelportfolio() {
    document.body.classList.add('add-instr-opened')
    this.setState({showMpList: !this.state.showMpList})
  }

  clickCreateModelportfolio() {
    let url = `/createmusterdepot?isin=${ this.state.isin}`;
    this.props.history.push(`/${this.state.investmentDynamicPath}${url}`)
  }

  finishFavList() {
    document.body.classList.remove('add-instr-opened')
    this.setState({showFavlist: false})
  }

  finishMpList() {
    document.body.classList.remove('add-instr-opened')
    this.setState({showMpList: false})
  }

  hideFactsheet() {
    return this.state.showFavlist || this.state.showMpList;
  }

  renderAddButton() {
    if(!this.isFund() || this.isCustomer) return '';

    return (
      <AddProductsToComparison>
        <AddInstrumentButton
          isin={this.state.assetInfoData.isin}
          openFavorite={this.clickOpenFavorite}
          createFavorite={this.clickCreateFavorite}
          openModelportfolio={this.clickOpenModelportfolio}
          createModelportfolio={this.clickCreateModelportfolio}
        />
      </AddProductsToComparison>
    )
  }

  allowShowSetting(name) {
    return !this.state.assetInfoData.settings.hasOwnProperty(name) || this.state.assetInfoData.settings[name] === true;
  }

  render() {
    const { classes } = this.props;

    let showFs = !this.hideFactsheet();

    if(this.state.assetInfoError) {
      return this.renderError();
    }

    return (
      <React.Fragment>
        {showFs && <div className={classes.hideOnPrint}>
          <Container className={`app-page-container`}>
          {this.isFund() && (
            <div className={classes.navigation}>
              <div>
                {this.state.assetInfoLoading ? this.renderLoading() : this.renderInstrumentName()}
                {this.state.assetInfoData &&
                  (
                     <div className={classes.iconGroup} >
                       {this.state.assetInfoData.calculated.last_date &&
                           <span>Stand vom {moment(this.state.assetInfoData.calculated.last_date).format('DD.MM.YYYY')}</span>
                       }
                       {this.renderAddButton()}
                       <GetPdfButton isin={this.state.assetInfoData.isin} date={this.state.startDate}
                                     disabled={!this.allowShowSetting("fincitefactsheet_visible")}/>
                     </div>
                  )
                }
              </div>
            </div>
          )}
          </Container>
        </div>}

        {showFs && <div className={clsx([this.isFund() ? classes.whiteBox : null, classes.hideOnPrint])}>
          <Container className={`app-page-container ${classes.tabsContainer}`}>
            <AppBar position="static" className={classes.tabsHeader}>
              <Tabs value={this.state.selectedTabId} onChange={this.handleChange} aria-label="simple tabs example"
                classes={{ indicator: classes.indicator }}
              >
                {this.state.assetInfoLoading ? '' : this.renderTabsCaptions()}
              </Tabs>
            </AppBar>
            {this.state.assetInfoLoading ? <div className={classes.loadingCircle}><CircularProgress /></div> : this.renderTabsPanels()}
          </Container>
        </div>}

        {this.state.showFavlist && <div className={clsx([classes.whiteBox, classes.favoriteList])}>
          <Container className={`app-page-container`}>
            <Link
                onClick={this.finishFavList} to={'#'}
                className={clsx([classes.link, classes.backwardArrow])}>
                <i className="chevron-icon fa fa-chevron-left" /> Zurück zum Factsheet
            </Link>
            <TabPanel className={classes.tabContent}>
                <FavoriteList item={this.state.assetInfoData} finish={this.finishFavList}/>
            </TabPanel>
          </Container>
        </div>}

        {this.state.showMpList && <div className={clsx([classes.whiteBox, classes.favoriteList])}>
          <Container className={`app-page-container`}>
            <Link
                onClick={this.finishMpList} to={'#'}
                className={clsx([classes.link, classes.backwardArrow])}>
                <i className="chevron-icon fa fa-chevron-left" /> Zurück zum Factsheet
            </Link>
            <TabPanel className={classes.tabContent}>
                <ModelPortfolioList item={this.state.assetInfoData} finish={this.finishMpList}/>
            </TabPanel>
          </Container>
        </div>}
        <ErrorModal message={this.permissionError} handleClose={this.handleErrorModalClose.bind(this)}/>
      </React.Fragment>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(FactSheetsPage)));
