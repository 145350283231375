const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '3rem',
    borderLeftWidth: 4,
    borderRightWidth: 4,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderLeftStyle: 'solid',
    borderRightStyle: 'solid',

    transition: 'all',
    transitionDuration: '.2s',

    '&:hover': {
      borderLeftColor: theme.palette.primary.main,
      backgroundColor: '#2A3545',

      '& $icon': {
        color: 'white'
      }
    },

    ['@media (min-width: 576px)']: {
      height: '4rem',
    }
  },
  icon: {
    fontSize: 24,
    color: '#B0B7BC',
  },
  active: {
    borderLeftColor: theme.palette.primary.main,
    backgroundColor: '#2A3545',

    '& $icon': {
      color: 'white'
    }
  },
  bottomPlaced: {
    height: 48,
    '& $icon': {
      fontSize: '20px !important'
    }
  }
});

export default styles;