import {setLastStepIndex} from "../../../components/Header/actions";
import {
  selectFakeCustomer
} from "../../ReportGroupCreation/actions";
import {
  addGroupForGuide
} from "../actions";
import {ROUTES} from "../../../routes";
import {setReportingGuideTourCustomers, setReportingGuideTourGroups} from "../../GroupReporting/actions";
import {REPORTING_GUIDE_TOUR_CUSTOMERS, REPORTING_GUIDE_TOUR_GROUPS} from "../../GroupReporting/constants";

const setFakeGroups = () => {
  return setReportingGuideTourGroups(REPORTING_GUIDE_TOUR_GROUPS);
};
const setFakeCustomers = () => {
  return setReportingGuideTourCustomers(REPORTING_GUIDE_TOUR_CUSTOMERS);
};

const get_router_fn = (history, url) => {
  return () => {
    if (history.location.pathname !== url) {
      history.push(url);
    }
  }
};

const get_report_settings_header = (routeFn) => {
  return {
    selector: '#report-settings-header-tour-element',
    key: 'report_settings_header',
    routeFn,
    dispatchFn: [addGroupForGuide, selectFakeCustomer]
  }
};
const report_settings_tabs = {
  selector: '#report-settings-tabs-tour-element',
  key: 'report_settings_tabs'
};
const get_report_settings_redirect_to_series_step = (routeFn) => {
  return {
    selector: '#report-settings-redirect-to-series',
    key: 'report_settings_redirect_to_reporting',
    dispatchFn: [() => {
      return setLastStepIndex(3)
    }],
    routeFn
  }
};
const report_settings_create_group_button = {
  selector: '#report-settings-add-group-btn-tour-element',
  key: 'report_settings_add_group_button',
};
const get_add_group_filter_step = (routeFn) => {
  return {
    selector: '#create-group-filter-tour-element',
    key: 'report_settings_group_filter',
    dispatchFn: [() => {
      return setLastStepIndex(4)
    }],
    routeFn
  }
};
const report_settings_create_group_client_list = {
  selector: '#report-settings-create-group-client-list-tour-element',
  key: 'report_settings_create_group_client_list'
};
const report_settings_create_group_select_client_button = {
  selector: '#report-settings-create-group-select-client-button',
  key: 'report_settings_create_group_select_client'
};
const report_settings_create_group_group_customers_list = {
  selector: '#report-settings-create-group-group-customers-list',
  key: 'report_settings_create_group_group_customers'
};
const get_report_settings_create_group_back_button_step = (routeFn) => ({
  selector: '#report-settings-create-group-back-button',
  key: 'report_settings_create_group_back_button',
  dispatchFn: [() => {
    return setLastStepIndex(8)
  }],
  routeFn
});
const get_report_settings_group_item_details_step = (routeFn) => {
  return {
    selector: '#report-settings-group-details-tour-element',
    key: 'report_settings_group_details',
    dispatchFn: [() => {
      return setLastStepIndex(9)
    }, setFakeGroups, setFakeCustomers],
    routeFn
  }
};
const report_settings_create_group_period = {
  selector: '#report-settings-create-group-period-tour-element',
  key: 'report_settings_group_report_time'
};
const report_settings_create_group_report_type = {
  selector: '#report-settings-create-group-report_type-tour-element',
  key: 'report_settings_group_report_type'
};
const report_settings_create_group_send_type = {
  selector: '#report-settings-create-group-send-type-tour-element',
  key: 'report_settings_group_send_type'
};
const report_settings_create_group_additional_reports = {
  selector: '#report-settings-create-group-additional-reports-tour-element',
  key: 'report_settings_group_additional'
};
const report_settings_group_actions = {
  selector: '#report-settings-group-actions-tour-element',
  key: 'report_settings_group_actions'
};
const report_settings_create_group_redirect_to_series_button = {
  selector: '#report-settings-create-group-redirect-to-series-button-tour-element',
  key: 'report_settings_group_redirect_to_reporting'
};
const report_settings_group_customer_list = {
  selector: '#report-settings-group-customer-list-tour-element',
  key: 'report_settings_group_customer_list'
};
const report_settings_customers_without_group = {
  selector: '#report-settings-customers-without-group-tour-element',
  key: 'report_settings_customers_without_group'
};

export const getReportSettingsChapterSteps = (history) => {
  const reportSettingsRouterFn = get_router_fn(history, ROUTES.BROKER.REPORT_SETTINGS.path);
  const groupCreateRouterFn = get_router_fn(history, ROUTES.BROKER.GROUP_CREATE.path);
  return [
    get_report_settings_header(reportSettingsRouterFn),
    report_settings_tabs,
    report_settings_create_group_button,
    get_report_settings_redirect_to_series_step(reportSettingsRouterFn),
    get_add_group_filter_step(groupCreateRouterFn),
    report_settings_create_group_client_list,
    report_settings_create_group_select_client_button,
    report_settings_create_group_group_customers_list,
    get_report_settings_create_group_back_button_step(groupCreateRouterFn),
    get_report_settings_group_item_details_step(reportSettingsRouterFn),
    report_settings_group_customer_list,
    report_settings_create_group_period,
    report_settings_create_group_report_type,
    report_settings_create_group_send_type,
    report_settings_create_group_additional_reports,
    report_settings_group_actions,
    report_settings_create_group_redirect_to_series_button,
    report_settings_customers_without_group,
  ];
};
