import React, { useState } from 'react';
import moment from 'moment';
import clsx from "clsx";
import _ from "lodash";

/* Material-UI Components */
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

/* BCA modules */
import {
  PERFORMANCE_TIME_TYPE,
  DEFAULT_PERFORMANCE_TIME_TYPE,
  CUSTOM_PERFORMANCE_TIME_TYPE_VALUE, CUSTOM
} from '../../../../../utils/constants';
import DateRange from "../../../../../components/DateRange/DateRange";
import useOnClickOutside from "../../../../../utils/useOnClickOutside";

import {setTimeSelectorDateChange} from "../../../actions";
import {useStyles, useFilterClasses} from "./styles";
import WarningTooltip from "../../../../../components/WarningTooltip";
import {formatDateShouldBeGraterThenErrorMessage, isWeekend} from "../../../../../utils/utils";
import SelectCaret from '../../../../../components/Icons/SelectCaret';
import {NoPriceOnWeekendsWarning} from "../../../../CustomerDashboardV2/constants";


require('moment/locale/de');
moment.locale('de');


const getCalculationDatesType = (calculationDatesType) => _.isString(calculationDatesType)
  ? PERFORMANCE_TIME_TYPE[calculationDatesType]
  : calculationDatesType;


const PortfolioTimeSelector = React.memo((props) => {
  const {
    dispatch,
    calculationDates,
    calculationDatesType,
    handleCalculationDatesChanged,
    useFilterStyles,
    startOfInvestment,
    loading,
    stickyContainer,
    iconComponent,
    minDateErrorMessage,
    timeSelectorDateChange,
    customHeader,
    disabledOptions,
    showNoPriceOnWeekendsWarning,
    optionsInfo
  } = props;

  const classes = useFilterStyles ? useFilterClasses() : useStyles();
  const _DEFAULT_PERFORMANCE_TIME_TYPE = props.defaultPerformanceTimeType || DEFAULT_PERFORMANCE_TIME_TYPE;

  const timeSelectorChangeCallback = (value={}) => {
    dispatch && dispatch(setTimeSelectorDateChange(value))
  };

  React.useEffect(() => {
    timeSelectorChangeCallback();
  }, []);

  const [selectedDates, setSelectedDates] = useState(calculationDates);
  const [expanded, setExpanded] = useState(false);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [performanceTimeType, setPerformanceTimeType] = useState(_DEFAULT_PERFORMANCE_TIME_TYPE);

  const node = React.useRef();

  useOnClickOutside(node, () => {
    if (expanded && !datePickerOpened) {
      setExpanded(false)
    }
  });

  /**
   * Data Validation. For now it only print in console. Could be extended later
   */
  React.useEffect(() => {

    const dateFormatted = (date) => date && date.format('YYYY-MM-DD');

    const _calculationDatesType = getCalculationDatesType(calculationDatesType);

    if (!calculationDates.start || !calculationDates.end || _calculationDatesType.value == CUSTOM_PERFORMANCE_TIME_TYPE_VALUE) {
      return
    }

    const datesTypeDates = _calculationDatesType.getDateRange();

    if (dateFormatted(datesTypeDates.start) != dateFormatted(calculationDates.start) || dateFormatted(datesTypeDates.end) != dateFormatted(calculationDates.end)) {
      console.warn('Calculation dates and calculation dates type mismatch!')
    }

  }, [calculationDates, calculationDatesType]);

  React.useEffect(() => {
    setSelectedDates(calculationDates)
  }, [calculationDates]);

  React.useEffect(() => {

    if (!calculationDatesType) {
      return
    }

    const _calculationDatesType = getCalculationDatesType(calculationDatesType);

    setPerformanceTimeType(_calculationDatesType)
  }, [calculationDatesType]);

  React.useEffect(() => {
    if(timeSelectorDateChange) {
      if (timeSelectorDateChange.startOfBenchmarkChanged) {
        setDates(timeSelectorDateChange, CUSTOM)
      } else if (timeSelectorDateChange.start && timeSelectorDateChange.end) {
        handleClickListItem(CUSTOM);
        setSelectedDates(timeSelectorDateChange);
        setExpanded(true)
      }
    } else {
      setExpanded(false)
    }
  }, [timeSelectorDateChange]);

  const handleClickListItem = (key) => {
    // if clicked value is changed
    if (performanceTimeType.value !== PERFORMANCE_TIME_TYPE[key].value) {
      setPerformanceTimeType(PERFORMANCE_TIME_TYPE[key]);

      if (PERFORMANCE_TIME_TYPE[key].value !== PERFORMANCE_TIME_TYPE.CUSTOM.value) {
        setExpanded(false);
        setDates(PERFORMANCE_TIME_TYPE[key].getDateRange(), key);
      }
    }
  };

  const setDates = (dates, key) => {
    timeSelectorChangeCallback();
    handleCalculationDatesChanged(dates, PERFORMANCE_TIME_TYPE[key]);
  };

  const [startOfInvestmentMoment, setStartOfInvestmentMoment] = useState(undefined)
  React.useEffect(() => {
    setStartOfInvestmentMoment(startOfInvestment && moment(startOfInvestment))
  }, [startOfInvestment]);


  const renderPerformanceTimeTypesList = () => {
    const startDate = startOfInvestmentMoment;
    const selectedDateRange = performanceTimeType.getDateRange();
    const selectedStartDateRange = selectedDateRange && selectedDateRange.start;

    // if start of investment of portfolio is younger than currently selected date range
    if (startDate && selectedStartDateRange && startDate.isAfter(selectedStartDateRange)) {
      return handleClickListItem('BEGINNING'); // automatically select investment beginning
    }

    return Object.keys(PERFORMANCE_TIME_TYPE)
    .filter(key => {
      if (startDate) {
        const dateRange = PERFORMANCE_TIME_TYPE[key].getDateRange();
        const startDateRange = dateRange && dateRange.start || startDate;

        return startDateRange.isSameOrAfter(startDate);
      }
      return true;
    })
    .map(key => {
      if (disabledOptions.includes(PERFORMANCE_TIME_TYPE[key].value)) {
        return null
      }
      const isSelected = performanceTimeType.value === PERFORMANCE_TIME_TYPE[key].value;
      return (
        <ListItem
          key={PERFORMANCE_TIME_TYPE[key].value}
          className={clsx(classes.portfolioListItem, isSelected && classes.portfolioListItemSelected)}
          onClick={() => handleClickListItem(key)}
        >
          <span>{PERFORMANCE_TIME_TYPE[key].description}</span>
          {optionsInfo[key] && (
            <WarningTooltip title={optionsInfo[key]} />
          )}
        </ListItem>
      );
    });
  };

  const getMinDateErrorMessage = () => {
    if (minDateErrorMessage) return minDateErrorMessage;
    if (!!startOfInvestmentMoment) {
      return formatDateShouldBeGraterThenErrorMessage(startOfInvestmentMoment);
    }
  };

  const hasDatesData = !!calculationDates.start && !!calculationDates.end;

  const weekendsUsed = () => {
    return hasDatesData && (isWeekend(calculationDates.start) || isWeekend(calculationDates.end))
  };

  return (
    <div className={classes.container} ref={node} id={stickyContainer ? "date-picker-dropdown-sticky" : "date-picker-dropdown"}>
      <span className={classes.label}>{customHeader}</span>
      <div className={classes.expansionPannelContainer}>
        <Accordion id="date-picker" TransitionProps={{timeout: 0}} expanded={expanded} onChange={() => setExpanded(!expanded)} classes={{
          root: classes.expansionPanelRoot,
          expanded: classes.expansionPanelExpanded
        }} disabled={loading}>
          <AccordionSummary
            expandIcon={
              <span className={classes.expandIcon}>
                {iconComponent || <SelectCaret />}
              </span>
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            IconButtonProps={{
              disableRipple: true
            }}
            classes={{
              root: classes.expansionPanelSummaryRoot,
              expanded: classes.expansionPanelSummaryExpanded,
              content: classes.expansionPanelSummaryContent
            }}
          >
            <CalendarTodayIcon className={classes.calendarIcon} />
            <span className={classes.ellipsis}>
              {hasDatesData ? (
                `${calculationDates.start.format('DD.MM.YYYY')} - ${calculationDates.end.format('DD.MM.YYYY')}`
              ) : (
                'Seit Investmentbeginn'
              )}
            </span>
            {showNoPriceOnWeekendsWarning && weekendsUsed() && (
              <WarningTooltip title={NoPriceOnWeekendsWarning}/>
            )}
          </AccordionSummary>
          <AccordionDetails classes={{root: classes.expansionPanelDetailsRoot}}>
            <div className={classes.portfolioListWrap}>
              <List className={classes.portfolioList}>
                {renderPerformanceTimeTypesList()}
              </List>
              {performanceTimeType.value === PERFORMANCE_TIME_TYPE.CUSTOM.value && (
                <div className={classes.timePickerContainer}>
                  <DateRange
                    start={(selectedDates && selectedDates.start) || startOfInvestmentMoment}
                    end={(selectedDates && selectedDates.end)}
                    minDate={startOfInvestmentMoment}
                    onChange={(dateRange) => {
                      setExpanded(false);
                      setDates(dateRange, CUSTOM);
                    }}
                    minDateErrorMessage={getMinDateErrorMessage()}
                    onOpen={() => setDatePickerOpened(true)}
                    onClose={() => setDatePickerOpened(false)}
                    classes={{
                      textFieldUnderline: classes.textFieldUnderline
                    }}
                  />
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
});

PortfolioTimeSelector.defaultProps = {
  customHeader: 'Zeitraum auswählen:',
  disabledOptions: [],
  optionsInfo: {}
};

export default PortfolioTimeSelector;