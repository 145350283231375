import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="30"
      viewBox="0 0 28 30"
    >
      <path
        id="Pfad_1422"
        data-name="Pfad 1422"
        d="M1075.991,311.7l-4.013,4.033v4.879h-1.75v5.277h24.5v-5.277h-3.5v-3.635h2.625a4.391,4.391,0,0,0,4.375-4.4V307.3a2.64,2.64,0,0,0-2.037-2.571l-1.262-2.708,3.213-1.175-1.8-4.959-8.222,3.008,1.8,4.959,3.359-1.229,1.093,2.346a2.643,2.643,0,0,0-1.393,2.329v4.4h-7v-.908a4.708,4.708,0,0,0,1.75-3.678v-7.727h-8.75V307.3a4.4,4.4,0,0,0,1.75,3.517v.881Zm14.376-11.751,4.933-1.805.6,1.653-4.933,1.805Zm-16.638,16.513,2.987-3h.807l3.01,7.153h-3.305v-3.635h-1.75v3.635h-1.75Zm19.25,7.668h-21v-1.759h21Zm3.5-15.948h-1.75v-.88a.875.875,0,1,1,1.75,0Zm-1.75,5.277v-3.518h1.75v2.639a2.635,2.635,0,0,1-2.625,2.638h-4.375v5.394h-3.3l3.01-7.153Zm-7.445,0-3.01,7.153h-1.84l-3.01-7.153h1.818l.875.879-1.132,1.137,1.238,1.244,1.131-1.137,1.131,1.137,1.237-1.244-1.131-1.137.875-.879Zm-3.969-3.518a2.632,2.632,0,0,1-2.585-2.638v-3.854l2.625,1.319,2.625-1.319v3.666A2.711,2.711,0,0,1,1083.313,309.941Zm2.665-8.8v.336l-2.625,1.319-2.625-1.319v-.336Zm-2.617,10.553a4.351,4.351,0,0,0,.867-.086v.6l-.875.879-.875-.879v-.606A4.413,4.413,0,0,0,1083.361,311.7Z"
        transform="translate(-1070.228 -295.889)"
        fill="#80858c"
      />
    </svg>
  );
};
