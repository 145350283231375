import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  headerContainer: {
    '& h1, h2, h3, h4, h5, h6': {
      margin: 0,
      padding: 0,
      fontFamily: 'Roboto-Bold',
      //fontSize: 22,
      color: '#202A38'
    }
  }
})

export default makeStyles(styles)