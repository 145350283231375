const styles = theme => ({
  header: {
    fontFamily: 'Roboto-Regular',
    fontSize: 34,
    color: '#626970',

    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    }
  },
});

export default styles;