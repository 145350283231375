import _ from 'lodash';
import moment from 'moment';
import { isCustomerNameValid, isNameValid } from '../../components/CustomersDataProvider/utils';
import { ALERT_PRODUCT_TYPE } from '../RiskDashboard/components/AlertConfigurationModal/utils';

function productTypeValid(event, productTypes) {
  if (_.isEmpty(productTypes)) {
    return false
  }

  return productTypes.includes(event.alert_type_value)
}

function fieldToValidateValid(event, fieldsToValidate) {
  if (_.isEmpty(fieldsToValidate)) {
    return false
  }

  return fieldsToValidate.includes(event.validated_field_value)
}

function hideSeenValid(event, hideSeen) {
  if (!hideSeen) {
    return true
  }

  return event.is_new
}

function dateRangeValid(event, dates) {

  try {
    const startDate = dates[0] && moment(moment(dates[0]).format('YYYY-MM-DD'))
    const endDate = dates[1] && moment(moment(dates[1]).format('YYYY-MM-DD'))

    if (!startDate && !endDate) {
      return true
    }

    const eventTriggeredAtDate = moment(event.triggered_at, 'YYYY-MM-DD')

    return (!startDate || startDate <= eventTriggeredAtDate) && (!endDate || endDate >= eventTriggeredAtDate)
  } catch {
    return false
  }

}

function productNameValid(event, productName) {
  try {

    if (event.alert_type_value === ALERT_PRODUCT_TYPE.DEPOT) {
      return isNameValid(_.get(event, 'depot.name'), productName)
    } else {
      return isNameValid(_.get(event, 'asset.name'), productName)
        || isNameValid(_.get(event, 'asset.wkn'), productName)
        || isNameValid(_.get(event, 'asset.isin'), productName)
    }

  } catch {
    return false
  }
}

export function filterAlertEvents(events, filters) {
  return events.filter((event) => {
    return productTypeValid(event, filters.event_type)
      && fieldToValidateValid(event, filters.field_to_validate)
      && hideSeenValid(event, filters.only_active)
      && isCustomerNameValid(event.customer, filters.customer_name)
      && dateRangeValid(event, filters.dates)
      && productNameValid(event, filters.product_name)
  })
}