const styles = (theme) => ({
  loadingIndicator: {
    color: theme.palette.primary.main
  },
  loadingContainer: {
    width: '100%',
    height: 'calc(100vh - 420px)', // 420px for header and footer
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default styles;