import React from "react";
import {withStyles} from "@material-ui/core";
import styles, { useStyles } from "./styles";
import HighChartBase from "../../../../components/Charts/Base";
import { CHART_COLORS } from '../../../../utils/constants';
import clsx from "clsx";


function calculateWidth(){
  var chart = this,
    totalW = chart.plotLeft + chart.xAxis[0].width + chart.marginRight;

  if (totalW !== chart.chartWidth) {
    chart.setSize(totalW, null, false);
  }
}

const useContainerWidth = (container) => {
  const [width, setWidth] = React.useState(undefined)
  const [rect, setRect] = React.useState(undefined)
  const ref = React.useCallback(node => {
    setRect(node)
  }, [])

  const onWindowResize = React.useCallback(() => {
    if (rect) {
      setWidth(rect.offsetWidth)
    }
  }, [rect])

  React.useEffect(() => {

    window.addEventListener('resize', onWindowResize)

    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  })

  React.useEffect(() => {
    if (rect) {
      setWidth(rect.offsetWidth)
    }
  }, [rect])

  return [width, ref]
}

const FundStructureChart = (props) => {

  const [width, containerRef] = useContainerWidth()

  const classes = useStyles()

  let minY = props.min || 0;
  let maxY = props.max || 100;

  let series = props.series;
  let stacking = 'percent';
  let plotLines = null;

  let isNegative = series.find(function(item) { return item.data[0] < 0 })

  const useNegativeSettings = props.allowFloatingMixMax && isNegative !== undefined;

  if(useNegativeSettings) {

    // if chart contain negative values, we display it different way

    minY = null;
    maxY = null;
    let tmp_series = [];
    series.forEach(function(item) {
      tmp_series.push({name: item.name, data: [item.data[0] * 100]})
    })
    series = tmp_series;
    stacking = 'normal'
    plotLines = [{value: 0, width: 2, color: '#000000', zIndex: 10}];
  }

  const isMobile = width <= 374;

  let legendAlignOptions = {
    layout: 'vertical',
    align: 'left',
    verticalAlign: 'top',
    x: 90,
    fontSize: '14px',
    symbolPadding: 5
  }

  if(isMobile) {
    // #BCA-6887 -> caused by issue in Highcharts https://github.com/highcharts/highcharts/issues/12282#issuecomment-547446102:
    // "then I suggest to disable symbolHeight and itemMarginTop"

    legendAlignOptions = {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'bottom',
      x: 0,
      fontSize: '12px',
      symbolPadding: 0
    }
  }

  return (
    <div className={clsx(classes.chart, isMobile && 'mobile')} ref={containerRef}>
      <HighChartBase options={{
        colors: props.useColorFromSeries ? props.series.map(item => item.color) : CHART_COLORS,
        chart: {
          marginLeft: 20,
          type: 'column',
          height: 350,
          style: {
            fontFamily: "\"Roboto-Regular\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif",
            fontSize: 14
          },
          events: {
            load: calculateWidth,
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          width: 80,
          categories: [],
          tickLength: 0,
          labels: {
              enabled: false
          },
          lineColor: 'transparent',
        },
        yAxis: {
          offset: -15,
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          tickLength: 10,
          tickWidth: 1,
          min: minY,
          max: maxY,
          tickInterval: 25,
          title: {
            enabled: false
          },
          plotLines: plotLines,
          tickPositioner: function () {

            // this is all about avoiding a lot of blank space on bottom and top

            if(!useNegativeSettings) return undefined;
            let positions = [];

            let minBound = Math.floor(this.dataMin / 25) * 25;
            let maxBound = Math.ceil(this.dataMax / 25) * 25;

            for(let i = minBound; i <= maxBound; i += 25) {
              positions.push(i)
            }
            if(Math.abs(this.dataMin) < 5) {
              positions[0] = -10 // bottom distance
            }
            if(maxBound - this.dataMax > 20) {
              positions[positions.length - 1] = maxBound - 15 // top distance
            }
            return positions;
          }
        },
        legend: {
          useHTML: true,
          layout: legendAlignOptions.layout,
          align: legendAlignOptions.align,
          verticalAlign: legendAlignOptions.verticalAlign,
          x: legendAlignOptions.x,
          itemMarginBottom: 5,
          itemDistance: 0,
          itemStyle: {
            fontSize: legendAlignOptions.fontSize,
          },
          labelFormatter: function () {
            return props.useCustomLegendStyles ? '<div class="legend-custom">' + this.name + '</div>' : this.name;
          },
          squareSymbol: true,
          symbolPadding: legendAlignOptions.symbolPadding
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          column: {
            stacking: stacking,
            shadow: false,
            borderWidth: 1,
          },
          tooltip: {
            enabled: false
          },
          series: {
            enableMouseTracking: true,
            pointWidth: 65
          }
        },
        series: series
      }} language={'DE'}/>
    </div>
  )
}

export default withStyles(styles)(FundStructureChart)
