import React from "react";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import {Slider} from "@material-ui/core";


const PercentProductCard = ({question, onAnswerChange}) => {

    const [percent, setPercent] = React.useState(0);

    const classes = useStyles();

    const options = [0, 20, 40, 60, 80, 100];

    const step = 100 / (options.length - 1);

    const label = (
        <div className={classes.label}>
            <span>Bis Zu</span>
            <strong>{percent}%</strong>
        </div>
    );

    const marks = options.map((option, index) => {
        return {
            value: step * index,
            label: option === percent ? label : option,
            uid: option,
        };
    });

    const handleChange = (event, newValue) => {
        setPercent(newValue);
        onAnswerChange(question.uid, newValue);
    };

    React.useEffect(() => {
        if (question.answer) {
            setPercent(question.answer);
        }

  }, [percent]);

    return (
        <Card className={classes.card}>
            <CardContent className={classes.container}>
                <Typography className={classes.title}>
                    {question.question_text}
                </Typography>
                {question.error && (
                    <FormHelperText
                        error={true}
                        classes={{
                            error: classes.errorContainer
                        }}>{question.error}</FormHelperText>
                )}

                <Slider
                    disabled={false}
                    min={0}
                    max={100}
                    step={1}
                    marks={marks}
                    value={percent}
                    valueLabelDisplay="auto"
                    aria-labelledby="discrete-slider-medium-steps"
                    onChange={(event, value) => {
                        question.error = null; // clean error
                        handleChange(event, value)
                    }}
                    classes={{ root: classes.slider}}
                />

            </CardContent>
        </Card>
    );
};

export default PercentProductCard;