import React from 'react';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ProblemIcon from '@material-ui/icons/ReportProblem';

import useStyles from './styles';
import {ERROR_MODAL_MESSAGE} from "../../utils/constants";

export default (props) => {
  const classes = useStyles();

  const {
    message,
    handleClose
  } = props;

  React.useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  const [open, setOpen] = React.useState(false);

  const handleModalClose = () => {
    setOpen(false);
    if (handleClose) {
      handleClose();
    }
  }

  const getErrorMessage = () => {
    if (message) {
      if (!_.isNil(message)) {

        if (_.isObject(message)) {
          if (message.hasOwnProperty('status') && message.hasOwnProperty('statusText')) {
            return message.statusText;
          }
        } else if (_.isString(message)) {
          return message
        }
      }
    }

    return ERROR_MODAL_MESSAGE;
  }

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.container}
      classes={
        {
          root: classes.root
        }
      }
    >
      <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
        <ProblemIcon className={classes.icon}/>
        {"Fehlermeldung"}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText id="alert-dialog-description" style={{textAlign: 'center', margin: 0}}>
          {getErrorMessage()}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        <Button variant="contained" onClick={handleModalClose} className={classes.button}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}