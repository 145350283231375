import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  editorSwitchContainer: {
    padding: '0 0 10px 0 !important',
  },
  headerWrapper: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
  },
  switch: {
    // marginLeft: '5px'
  },
  itemContainer: {
    height: '100%',

    '& #cover-page-document-preview-container': {
      width: '100%',
      minHeight: 440,
      margin: 0
    }
  },
}));