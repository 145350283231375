import React from 'react';

import useStyles from './styles';
import moment from "moment/moment";
import _ from "lodash";
import {getDataWarningIsins} from "../../../hooks/useStressTestData";
import StressTestChartBase from '../../../../../components/ChartsV2/StressTestChartBase';

function StressTestChart({data, type='drawdown',  updatedAt}) {

  const classes = useStyles();

  const productsWithData = Object.values(data).filter((portfolio) => !_.isEmpty(portfolio.data));

  const warnings = getDataWarningIsins(productsWithData, 'warnings');

  const [categories, series, yAxisTitle, yAxisMax, yAxisUnits] = (() => {

    let _categories = [];
    let _series = [];
    let _max = 0;
    let _title = type == 'drawdown' ? 'Maximaler Wertverlust' : 'Zeitraum der Wertaufholung (Tage)';
    let _unit = type == 'drawdown' ? ' %' : '';
    let multiplier = type == 'drawdown' ? 100 : 1;
    let valueName = type == 'drawdown' ? 'maximum_drawdown' : 'recovery_days'

    if (productsWithData.length) {
      // We could get categories from first element in the list, as all of them has same categories.
      _categories = productsWithData[0].data.map((item) => ({
        name: item.name,
        dates: moment(item.start_date).format('DD.MM.YYYY') + ' - ' + moment(item.end_date).format('DD.MM.YYYY')
      }));

      productsWithData.forEach((product) => {
        const productSeries = {
          name: product.name, data: [], stacked: 's', color: product.color
        };
        productSeries.data = product.data.map((item) => {
          let ptfValue = item.portfolio[valueName]
            ? (item.portfolio[valueName] * multiplier)
            : item.portfolio[valueName];
          if(Math.abs(ptfValue) > _max) {
            _max = Math.abs(ptfValue);
          }
          return ptfValue || null;
        });

        _series.push(productSeries);

      })
    }

    return [_categories, _series, _title, _max, _unit]
  })();

  return (
    <div className={classes.chartContainer}>
      <StressTestChartBase
        categories={categories}
        series={series}
        yAxisTitle={yAxisTitle}
        yAxisMax={yAxisMax}
        yAxisUnits={yAxisUnits}
        type={type}
      />
      {warnings.length > 0 && (
        <p className={classes.warning}>
          Instrumente, die nicht über genügend historische Daten verfügen: {warnings.join(', ')}
        </p>
      )}
    </div>
  )
};

export default React.memo(StressTestChart, (prevProps, nextProps) => prevProps.updatedAt == nextProps.updatedAt);