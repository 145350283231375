import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    width: '100%',
  },
  accordionRoot: {
    boxShadow: 'none',
    border: 'none',
    borderTop: 'none'
  },
  heading: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#202A38'
  },
  heading_help: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
  },
  loadingContainer: {
    width: '100%',
    minHeight: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  accordionSummaryExpanded: {
    borderBottom: 'none',
    backgroundColor: '#F7F7F7',
    height: '52px !important',
    minHeight: '52px !important'
  },
  status: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    // styles to hide too long text
    maxWidth: '95%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    // styles to show full name on hoover
    '&:hover': {
      textOverflow: 'initial',
      overflow: 'initial',
      whiteSpace: 'initial',
      wordBreak: 'break-all'
    }
  },
  buttonsContainer: {
    '& button': {
      marginLeft: 10
    },
    '& span': {
      marginLeft: 10
    },

    '& button:first-child': {
      marginLeft: 0
    },
    display: 'flex',
    justifyContent: 'flex-end'
  },
  downloadButtonsContainer: {
    width: 395.5,
    justifyContent: 'flex-end',
    display: 'flex'
  },
  buttonRoot: {
    backgroundColor: 'white',
    height: 36,
    paddingLeft: 7,
    paddingRight: 7,

    '& span': {
      fontSize: '14px !important',
    },

    '& > span > span:first-child': {
      marginRight: 8,
      marginLeft: 0
    }
  },
  buttonRootSign: {
    minWidth: 40,
    '& > span > span:first-child': {
      marginRight: 0,
      marginLeft: 0
    }
  },
  tooltipStatusList: {
    paddingLeft: '20px',
    margin: 0
  },

  bordered: {
    border: '1px solid #D9DCDF',
  },
  accordionDetailsSkipPadding: {
    padding: 15,
    paddingRight:0,
    paddingTop: 10
  },
  downloadIcon: {
    color: theme.palette.primary.main,
    fontSize: '20px !important'
  },
  textItemContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  shareButtonHolder: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      padding: 0,
      margin: 0,
      marginRight: 10
    },
    '& > button': {
      marginRight: 30
    }
  },
  customerMenuItem: {
    fontSize: 14
  },
  previewButton: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
    fontHeight: 14,
    whiteSpace: 'nowrap',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  infoIcon: {
    '& > svg > path': {
      fill: theme.palette.primary.main
    },
    marginLeft: 10,
    marginRight: 10,
    display: 'inline-block',
    height: 32,
    position: 'relative',
    top: 4
  },

  uploadDocumentIcon: {
    position: 'relative',
    top: -6
  }
})

export default makeStyles(styles)