import React from 'react';
import PromptDialog from "../../../../../../components/PromptDialog";

const LeaveTextEditDialog = (props) => {

  const {
    handleYesBtn,
    handleNoBtn,
    handleCancel
  } = props;

  const message = <>
    <p>Sie haben Änderungen vorgenommen, diese aber nicht gespeichert.
    Wenn Sie die Seite vor Speicherung verlassen, gehen Ihre Änderungen verloren.
    </p>
    <p>Sind Sie sicher, dass Sie die Seite verlassen möchten?</p>
  </>;
  const okBtnLabel = 'Auf Seite bleiben';
  const cancelBtnLabel = 'Seite verlassen';

  return (
    <PromptDialog isOpen={true}
                  message={message}
                  okBtnLabel={okBtnLabel}
                  cancelBtnLabel={cancelBtnLabel}
                  handleYesBtn={handleYesBtn}
                  handleNoBtn={handleNoBtn}
                  handleCancel={handleCancel}
    />
  )
};

export default LeaveTextEditDialog;