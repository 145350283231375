import React from "react";

import {withStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText'

import styles from "./styles";
import clsx from "clsx";

const CheckboxFormElement = props => {
  const {
    name,
    label,
    value,
    required,
    onChange,
    classes,
    custom_classes,
    disabled,
    error,
    helpTextUsed
  } = props;


  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <FormControl error={!!error}>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={handleChange}
            name={name}
            color="primary"
            required={required}
            disabled={disabled || false}
            classes={{root: classes.radio}}
          />
        }
        label={(<span>{label}{required ? <span className={classes.asterisk}>&nbsp;*</span> : ''}</span>)}
        classes={{
          label: clsx(classes.label, custom_classes && custom_classes.label, !!error && classes.labelError),
          root: clsx(classes.root, custom_classes && custom_classes.root)
        }}
      />
      { error && (
        <FormHelperText
          classes={{
            root: classes.helperTextRoot
          }}
          style={{marginTop: helpTextUsed ? -15 : 0}}
        >{ error }</FormHelperText>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(CheckboxFormElement);