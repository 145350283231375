import React from 'react';
import _ from 'lodash';
import { Grid } from '@material-ui/core';

import EsgScore from '../../../../FactSheetsPage/Tabs/KeyFiguresAndRatings/items/EsgScore';
import ChartSectionBordered from '../../ChartSectionBordered/ChartSectionBordered';
import { useEsgScoreData } from '../../../hooks/useSustainabilityScore';
import { getDataRetrievingParamsFromProps } from '../../../utils';
import DashboardInfoTooltip from '../../DasboardInfoTooltip';
import { isExpertSectionVisible } from '../../../../../utils/utils';

const ESG_TOOLTIP_TEXT_GENERAL = 'Der historische Morningstar Nachhaltigkeits-Score ist ein gewichteter Durchschnitt der ' +
  'letzten 12 Monate der Morningstar Portfolio Nachhaltigkeits-Scores. Es wird nach Risiken für ' +
  'Unternehmen und Risiken für Staaten differenziert. Die Scores der beiden Unterrisiken werden ' +
  'dann zu einem Gesamtscore zusammengeführt. Hierbei werden die Scores aus der jüngeren Vergangenheit ' +
  'stärker gewichtet als ältere Scores des 12-Monats-Zeitraums. Basierend auf dem Historischen ' +
  'Morningstar Nachhaltigkeits-Score werden den Fonds absolute Kategorien und prozentuale Ränge ' +
  'innerhalb der Morningstar Global Categories zugewiesen. Das bedeutet, dass durch den Ausweis ' +
  'absoluter Zahlen ein Fonds auch über Fondskategorien bzw. Vergleichsgruppen hinweg vergleichbar wird.';

const EsgScoreCharts =  (props) => {
  const { reportType, customSettings } = props;
  const { customerId, dataProvider, portfolios, withHistorical } = getDataRetrievingParamsFromProps(props);

  const isEsgCorporateVisible = isExpertSectionVisible('esgScoreCorporate', reportType, customSettings);
  const isEsgSovereignVisible = isExpertSectionVisible('esgScoreSovereign', reportType, customSettings);
  const isSectionVisible = isEsgCorporateVisible || isEsgSovereignVisible;

  const [esgScoreData, fetch] = useEsgScoreData(customerId, portfolios, withHistorical, dataProvider);

  React.useEffect(() => {
    if (isSectionVisible && !_.isEmpty(props.selectedPortfolios) && !!customerId) {
      fetch();
    }
  }, [customerId, props.selectedPortfolios, withHistorical, isSectionVisible]);

  return (
    <Grid container spacing={2}>
      {isEsgCorporateVisible && (
        <Grid item xs={12} md={isEsgSovereignVisible ? 6 : 12}>
          <ChartSectionBordered
            title={<b>Morningstar Historischer Sustainability Score - Unternehmen <DashboardInfoTooltip title={ESG_TOOLTIP_TEXT_GENERAL} /></b>}
            expanded={props.expandedItems.esgScoreCorporate}
            onExpanded={(newState) => props.onExpandedItemsChange('esgScoreCorporate', newState)}
            error={esgScoreData.errors}
            displayError
            isPortfolioSection
            loading={esgScoreData.loading}
            content={
              <EsgScore score={_.get(esgScoreData.data, 'historical_corporate_sustainability_score')} />
            }
          />
        </Grid>
      )}
      {isEsgSovereignVisible && (
        <Grid item xs={12} md={isEsgCorporateVisible ? 6 : 12}>
          <ChartSectionBordered
            title={<b>Morningstar Historischer Sustainability Score - Staaten <DashboardInfoTooltip title={ESG_TOOLTIP_TEXT_GENERAL} /></b>}
            expanded={props.expandedItems.esgScoreSovereign}
            onExpanded={(newState) => props.onExpandedItemsChange('esgScoreSovereign', newState)}
            error={esgScoreData.errors}
            displayError
            isPortfolioSection
            loading={esgScoreData.loading}
            content={
              <EsgScore score={_.get(esgScoreData.data, 'historical_sovereign_sustainability_score')} />
            }
          />
        </Grid>
      )}
    </Grid>
  )
};

export default EsgScoreCharts;