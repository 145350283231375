import React from 'react';
import clsx from 'clsx';

import { getErrorMessage } from '../../../../../../utils/utils';
import useStyles from './styles';


const ErrorMessage = (props) => {
  const { error, withPadding } = props;

  const classes = useStyles();

  return (
    <p className={clsx(classes.message, withPadding && 'withPadding')}>
      {getErrorMessage(error)}
    </p>
  )
};

export default ErrorMessage;