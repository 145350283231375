import React from 'react';

import {Grid} from '@material-ui/core';

import PortfolioCurrencyStructure from './PortfolioCurrencyStructure';
import PortfolioBruncheStructure from './PortfolioBruncheStructure';
import PortfolioAssetStructure from './PortfolioAssetStructure';
import PortfolioRegionStructure from './PortfolioRegionStructure';
import TotalAssetVolume from './TotalAssetVolume';
import SyntheticRiskRewardChart from './SyntheticRiskRewardChart';
import AssetAmountStructure from './AssetAmountStructure';
import PropTypes from "prop-types";
import { isSectionVisibleByReportTypes } from '../../../../../../utils/utils';
import PortfolioMorningstarCategoryStructure from "./PortfolioMorningstarCategoryStructure";
import PortfolioAssetAllocationStructure from "./PortfolioAssetAllocationStructure";
import PortfolioRemainingTermsStructure from "./PortfolioRemainingTermsStructure";
import PortfolioCreditQualityStructure from "./PortfolioCreditQualityStructure";
import PortfolioIssuerStructure from "./PortfolioIssuerStructure";
import {REPORT_TYPES} from "../../../../../DashboardSettings/components/CustomersList/components/ReportType/constants";
import { isExpanded } from '../../../../utils';

// NOTE: we assume onExpanded function does not contain any data which could change during re-render
const portfolioStructurePropsAreEquals = (prevProps, nextProps) => {
  return prevProps.dashboardData.timestamp === nextProps.dashboardData.timestamp &&
    prevProps.reportType === nextProps.reportType &&
    prevProps.expandedItems === nextProps.expandedItems
};

const PortfolioStructure = React.memo((props) => {
  const {dashboardData, customTypeSettings, reportType, preferredColor, expandedItems, onExpanded, portfolios} = props;

  const proAvailable = [REPORT_TYPES.PRO.value];
  const expertAvailable = proAvailable + [REPORT_TYPES.EXPERT.value];
  const basicAvailable = expertAvailable + [REPORT_TYPES.BASIC.value];
  const allAvailable = basicAvailable + [REPORT_TYPES.DEPOT_STATUS.value];

  let chartsToBuild = [
    {md: 12, sm: 12, sectionKey: 'portfolioRegionChart',     component: PortfolioRegionStructure, availableReportTypes: basicAvailable},
    {md: 12, sm: 12, sectionKey: 'totalAssetVolumeChart',    component: TotalAssetVolume, availableReportTypes: allAvailable},
    {md: 12, sm: 12, sectionKey: 'assetAllocationChart',     component: PortfolioAssetAllocationStructure, availableReportTypes: expertAvailable},
    {md: 12, sm: 12, sectionKey: 'currencyChart',            component: PortfolioCurrencyStructure, availableReportTypes: expertAvailable},
    {md: 12, sm: 12, sectionKey: 'productTypeChart',         component: PortfolioAssetStructure, availableReportTypes: expertAvailable},
    {md: 12, sm: 12, sectionKey: 'portfolioBruncheChart',    component: PortfolioBruncheStructure, availableReportTypes: expertAvailable},

    {md: 12, sm: 12, sectionKey: 'syntheticRiskRewardChart', component: SyntheticRiskRewardChart, availableReportTypes: basicAvailable},
    {md: 12, sm: 12, sectionKey: 'assetCategoriesChart',     component: PortfolioMorningstarCategoryStructure, availableReportTypes: expertAvailable},
    {md: 12, sm: 12, sectionKey: 'assetAmountChart',         component: AssetAmountStructure, availableReportTypes: expertAvailable},

    {md: 12, sm: 12, sectionKey: 'maturityChart',            component: PortfolioRemainingTermsStructure, availableReportTypes: proAvailable},
    {md: 12, sm: 12, sectionKey: 'creditQualityChart',       component: PortfolioCreditQualityStructure, availableReportTypes: proAvailable},
    {md: 12, sm: 12, sectionKey: 'issuerStructureChart',     component: PortfolioIssuerStructure, availableReportTypes: proAvailable},
  ];

  const handleExpanded = (sectionKey) => (expanded) =>  {
    return onExpanded(sectionKey, expanded);
  };

  return (
    <Grid container spacing={2}>
      {chartsToBuild.filter(chart => isSectionVisibleByReportTypes(chart.sectionKey, reportType, customTypeSettings, chart.availableReportTypes)).map(chart => {
        return(
          <Grid item xs={12} sm={chart.sm || 12} md={chart.md}>
            <chart.component
              dashboardData={dashboardData.data}
              portfolios={portfolios}
              loading={dashboardData.loading}
              preferredColor={preferredColor}
              expanded={isExpanded(expandedItems, chart.sectionKey)}
              onExpanded={handleExpanded(chart.sectionKey)}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}, portfolioStructurePropsAreEquals);


PortfolioStructure.propTypes = {
  dashboardData: PropTypes.shape({
    data: PropTypes.object,
    loading: PropTypes.bool,
    errors: PropTypes.any,
    timestamp: PropTypes.number
  })
};


export default PortfolioStructure;