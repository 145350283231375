import { makeStyles } from '@material-ui/core';

export const styles = () => ({
  customerLoginContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column'
  },
  scrollWrapper: {
    flex: 1,
    overflow: 'auto'
  },
  contentWrapper: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 16,
    paddingBottom: 16,
  },

});

export default makeStyles(styles);