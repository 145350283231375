import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import styles from "./styles";
import {PrimaryButton} from "../../../../components/Buttons";

const PaginationPanel = ({classes, page, pages, onPageChange}) => {
  const isPrevBtnDisabled = () => {
    return page <= 0;
  };

  const isNextBtnDisabled = () => {
    return page >= pages - 1;
  };

  const handlePrevBtnClick = () => {
    onPageChange(page - 1)
  };

  const handleNextBtnClick = () => {
    onPageChange(page + 1);
  };

  return (
    <div className={classes.container}>
      <PrimaryButton
        text='&lt; VORHERIGE SEITE'
        onButtonClick={handlePrevBtnClick}
        disabled={isPrevBtnDisabled()}
        variant='text'
      />
      <PrimaryButton
        text='NÄCHSTE SEITE &gt;'
        onButtonClick={handleNextBtnClick}
        disabled={isNextBtnDisabled()}
        variant='text'
      />
    </div>
  )
};

export default withStyles(styles)(PaginationPanel);