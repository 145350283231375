import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import SvgIcon from "@material-ui/core/SvgIcon";

import styles from "./styles";
import InputFormElement, {CurrencyNumberFormat} from "../../../../formElement/InputFormElement";
import MultilineInputFormElement from "../../../../formElement/MultilineInputFormElement";

const FromStep = props => {
  const {
    classes,
    activeAsset,
    onInfoChange,
    onValueChange,
    infoRequired,
    valueRequired
  } = props;


  const handleInfoChange = (value) => {
    onInfoChange(value);
  };

  const handleValueChange = (value) => {
    onValueChange(value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span className={classes.icon}>
          <SvgIcon component={activeAsset.icon} />
        </span>
        <span className={classes.name}>
          {activeAsset.name}
        </span>
      </div>
      <div>
        <MultilineInputFormElement
          label={'Bezeichnung'}
          value={activeAsset.info || ''}
          onChange={handleInfoChange}
          rows={6}
          required={infoRequired}
        />
      </div>
      <div>
        <InputFormElement
          label={'Wert'}
          inputComponentProps={{
            isAllowed: (values) => {
              const {floatValue} = values;
              return floatValue <= 999999999.99;
            }
          }}
          inputComponent={CurrencyNumberFormat}
          value={activeAsset.value || ''}
          onChange={handleValueChange}
          required={valueRequired}
        />
      </div>
    </div>
  )
}

export default withStyles(styles)(FromStep);