import {fromJS} from 'immutable';

import {
  RESET_REPORTING_STORE,
  SET_REPORTING_GROUPS,
  SET_REPORTING_CUSTOMERS,
  SET_REPORTING_GROUP,
  SET_REPORTING_CUSTOMER,
  SET_REPORTING_ACTIVE_PATH,
  SET_REPORTING_CUSTOMER_IN_GROUP,
  SET_REPORTING_GUIDE_TOUR_CUSTOMERS,
  SET_REPORTING_GUIDE_TOUR_GROUPS,
  SET_REPORTING_GROUPS_LOADING,
  SET_REPORTING_CUSTOMERS_LOADING,
  SET_EDITOR_VARIABLES
} from "./constants";


const initialState = fromJS({
  groups: [],
  customers: [],
  groupsLoading: true,
  customersLoading: true,
  group: {},
  customer: {},
  activePath: undefined,
  customerInGroup: null,
  guideTourCustomers: [],
  guideTourGroups: [],
  editorVariables: []
});


export function reportingReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_REPORTING_STORE:
      return initialState;
    case SET_REPORTING_GROUPS:
      return state.set('groups', fromJS(action.groups));
    case SET_REPORTING_CUSTOMERS:
      return state.set('customers', fromJS(action.customers));
    case SET_REPORTING_GROUP:
      return state.set('group', fromJS(action.group || {}));
    case SET_REPORTING_CUSTOMER:
      return state.set('customer', fromJS(action.customer || {}));
    case SET_REPORTING_ACTIVE_PATH:
      return state.set('activePath', action.activePath);
    case SET_REPORTING_CUSTOMER_IN_GROUP:
      return state.set('customerInGroup', fromJS(action.customer));
    case SET_REPORTING_GUIDE_TOUR_CUSTOMERS:
      return state.set('guideTourCustomers', fromJS(action.customers));
    case SET_REPORTING_GUIDE_TOUR_GROUPS:
      return state.set('guideTourGroups', fromJS(action.groups));
    case SET_REPORTING_GROUPS_LOADING:
      return state.set('groupsLoading', action.loading);
    case SET_REPORTING_CUSTOMERS_LOADING:
      return state.set('customersLoading', action.loading);
    case SET_EDITOR_VARIABLES:
      return state.set('editorVariables', action.variables);
    default:
      return state;
  }
}
