import {makeStyles} from "@material-ui/core";

export default makeStyles(theme => ({
  tableCell: {
    [theme.breakpoints.down('md')]: {
      width: 75
    },
    '&:first-child': {
      width: '350px !important'
    },
    '&.sustainability-cell': {
      width: 83,
      minWidth: 83
    }
  },
  loadingIndicator: {
    color: theme.palette.primary.main
  },
  loadingContainer: {
    width: '100%',
    height: 560,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))