import React from 'react'

import useStyles from '../styles'
import {withEuroOrDash} from "../../../../utils/utils";
import LoadingContainer from "../LoadingContainer";

function TransactionSaldo(props) {

  const {
    overallInvestedAmount,
    loading
  } = props;

  const classes = useStyles();
  return (
    <div className={classes.container}>
      {loading ? (
        <LoadingContainer/>
      ) : (
        <React.Fragment>
          <h5 className={classes.header}>
            Transaktionssaldo
          </h5>
          <div className={classes.value}>
            {withEuroOrDash(overallInvestedAmount)}
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default TransactionSaldo