import { makeStyles } from '@material-ui/core';

export const styles = (theme) => ({
  container: {
    width: '100%',
  },
  itemWrapper: {
    padding: 8,
    height: 116,

    [theme.breakpoints.only('xs')]: {
      height: 'auto!important',
      padding: '8px 0'
    }
  },
  firstItem: {
    height: 140
  },
});

export default makeStyles(styles);