import React from 'react';
import WarningTooltip from "../../../../components/WarningTooltip";
import RadioButtonsSection from "../../../../components/Inputs/RadioButtonsSection";
import {filterStyles} from "../../../../components/Filters/styles";
import useStyles from "../../containers/styles";
import {CircularProgress} from "@material-ui/core";


const DYNAMIC_GROUP_INFO_ICON_TEXT = 'Mit dieser Auswahl können Sie festlegen, ob der Umfang Ihrer Kundengruppe jeweils bei jeder Ausführung des Reportings neu aktualisiert oder ob die fest ausgewählten Kunden ohne Aktualisierung für das Reporting übernommen werden sollen.\n' +
  'Wählen Sie ‘dynamisch’ werden neue Kunden, die seit Einrichtung der Gruppe neu hinzugekommen sind und den eingestellten Kriterien entsprechen, automatisch dem Reporting hinzugefügt. Bei dieser Option ist es nicht möglich die Kundenauswahl manuell zu überarbeiten. Ausserdem ist die Filterung nach freien Kriterien nicht möglich.\n' +
  'Für die Einstellung ‘statisch’ besteht die Möglichkeit, die bei Einrichtung der Gruppe nach Ihren Kriterien vorgeschlagenen Kunden nachträglich manuell zu verändern. Diese werden dann für jedes Reporting unverändert übernommen, ohne dass neue Kunden automatisch hinzugefügt werden. Um neue Kunden bei dieser Einstellung zu übernehmen, bedarf es bei dieser Option der manuellen Überarbeitung Ihrer Kundenauswahl.\n' +
  'Ist die Option ‘dynamisch’ ausgewählt und sind keine einschränkenden Kriterien ausgewählt worden, werden alle Ihre Kunden, welche über ein Portfolio verfügen, automatisch dem Reporting hinzugefügt.'

export const GROUP_TYPES = {
  STATIC: {
    value: 1,
    name: 'statisch'
  },
  DYNAMIC: {
    value: 2,
    name: 'dynamisch'
  }
}

const GroupTypeSelector = (props) => {
  const {
    selectedGroupType,
    onSelectedGroupTypeChange,
    loading
  } = props
  const classes = useStyles();

  const filterClasses = filterStyles();
  return (
    <div>
      <div className={filterClasses.checkboxFilterPanelRoot}>
        <div className={filterClasses.checkboxFilterLabelMain}>
          Kundengruppenaktualisierungsart
          <WarningTooltip width={400} title={DYNAMIC_GROUP_INFO_ICON_TEXT} />
        </div>
        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress className={classes.loadingIndicator}/>
          </div>
        ) : (
          <RadioButtonsSection
            options={Object.values(GROUP_TYPES)}
            value={selectedGroupType}
            customClasses={{
              radioGroup: classes.radioGroup,
              formControlLabel: filterClasses.checkboxFilterLabelMain,
              formControlLabelRoot: classes.zeroMarginBottom
            }}
            onChange={onSelectedGroupTypeChange}
          />
        ) }
      </div>
      </div>
  )
}

export default GroupTypeSelector