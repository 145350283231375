import React from 'react';
import { TextField } from '@material-ui/core';

import useStyles from '../styles';
import {BaseNumberFormat} from "../../../Inputs";

const CurrencyNumberFormat = (props) => {
  return (
    <BaseNumberFormat
      {...props}
      decimalScale={2}
      suffix=" €"
    />
  );
};

export default (props) => {
  const classes = useStyles();

  const {
    label,
    value,
    onChange,
    error,
    disabled
  } = props;

  return (
    <TextField
      label={label}
      error={!!error}
      helperText={error}
      className={classes.textField}
      value={value}
      onChange={onChange}
      margin="normal"
      placeholder={'€ '}
      disabled={disabled}
      InputProps={
        {
          inputComponent: CurrencyNumberFormat,
          className: classes.textField,
          classes: {
            root: classes.textFieldRoot,
            underline: classes.textFieldUnderline,
            disabled: classes.textFieldDisabled
          }
        }
      }
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.labelRoot,
          focused: classes.labelFocused
        }
      }}
    />
  )
}