import React from 'react';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  Grid
} from '@material-ui/core';

import {
  Form,
  Tooltip
} from './components';

import useStyles from './styles';
import { AuthResource } from '../../../utils/api';
import { login } from '../Auth/actions';
import {setInStorage, TOKEN_KEY} from '../../../utils/storage';
import {saveCustomerIdByUserName} from "../../CustomerDashboard/utils";
import {buildCurrentCustomerDashboardPath} from "../../../routes";
import {getCustomerPasswordRecoveryLink} from "../../../utils/constants";
import { openGuideModal } from '../../../components/Header/actions'


const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
});


const CustomerLogin = (props) => {
  const classes = useStyles();

  const [username, setUsername] = React.useState(undefined);
  const [password, setPassword] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoginTriggered, setLoginTrigger] = React.useState(false);
  const [errors, setErrors] = React.useState(undefined);
  const [tooltipProps, setTooltipProps] = React.useState({
    visible: false,
    variant: 'error',
    message: 'Falsche E-Mail-Adresse oder falsches Passwort!'
  });

  React.useEffect(() => {

    let __email = Cookies.get('__email');
    let __username = Cookies.get('__username');
    let __agency_id = Cookies.get('__agency_id');
    let __is_customer = Cookies.get('__is_customer');
    let __token = Cookies.get('__token');
    let __virtual_portfolio = Cookies.get('__virtual_portfolio');

    if (__username && __token && __agency_id && __is_customer) {

      let __user = {
        email: __email, 
        is_customer: JSON.parse(__is_customer), 
        username: __username,
        virtual_portfolios_enabled: JSON.parse(__virtual_portfolio),
        agency: {
          agency_id: __agency_id
        }
      }

      props.dispatch(login({
        ...__user,
        broker_id: __username.slice(1),
        customer_id: __username.slice(1)
      }));
      setInStorage(TOKEN_KEY, __token);
      saveCustomerIdByUserName(__username);
      setLoginTrigger(true);

    } else {
      let __errors = Cookies.get('__errors');

      if (__errors != undefined) {
        __errors = true;

        setTooltipProps({
          ...tooltipProps,
          visible: __errors
        })

      } else {
        __errors = false;
      }
    }

    if (!_.isNil(props.auth.user) && isLoginTriggered) {
      Cookies.remove('__email');
      Cookies.remove('__username');
      Cookies.remove('__agency_id');
      Cookies.remove('__is_customer');
      Cookies.remove('__token');
      Cookies.remove('__virtual_portfolio');
      props.history.push(buildCurrentCustomerDashboardPath());
    }
    
    return () => {
    };

  }, [props.auth.user, isLoginTriggered])

  const handleFormSubmitted = async () => {
    sessionStorage.clear();
    setIsLoading(true);
    setErrors(undefined);

    try {
      let requestBody = {
        "username": username,
        "password": password,
        "agency_id": props.agency_id,
        "uuid": uuidv4()
      };

      let response = await AuthResource
        .at('customer/token/', false)
        .post(requestBody);

      props.dispatch(login({
        ...response.user,
        broker_id: response.user.username.slice(1),
        customer_id: response.user.username.slice(1)
      }));

      let first_login = response.user.first_login;
      if (first_login) {
        props.dispatch(openGuideModal())
      }

      setLoginTrigger(true);

      setInStorage(TOKEN_KEY, response.token);
      saveCustomerIdByUserName(response.user.username);

      setIsLoading(false);
      setErrors(undefined);

    } catch (errors) {
      setIsLoading(false);
      setErrors(errors);
      setTooltipProps({
        ...tooltipProps,
        visible: true
      })
    }
  }

  const handleTooltipClose = async() => {

    setTooltipProps({...tooltipProps, visible: false})
    Cookies.remove('__errors');
  }

  return (
    <Grid container className={classes.customerLoginContainer}>
      <Tooltip 
        open={tooltipProps.visible} 
        variant={tooltipProps.variant}
        message={tooltipProps.message}
        handleClose={handleTooltipClose}
      />
      <div className={classes.logoContainer}>
        {props.logo && (
          <img src={`data:image/png;base64, ${props.logo}`} />
        )}
      </div>
      <Form 
        username={username}
        onUsernameChange={(event) => setUsername(event.target.value)}
        password={password}
        onPasswordChange={(event) => setPassword(event.target.value)}
        onFormSubmit={handleFormSubmitted}
        isLoading={isLoading}
        errors={errors}
      />
      <div className={classes.forgotPasswordLink}>
        <a href={getCustomerPasswordRecoveryLink(props.agency_id)}>Passwort vergessen?</a>
      </div>
    </Grid>
  )
};

export default connect(mapStateToProps)(withRouter(CustomerLogin));