import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  card: {
    minHeight: "330px",
    height: '100%',
    boxShadow: "0px 3px 15px #00000033",
    borderRadius: "5px",
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "50px",
  },
  title: {
    fontFamily: 'Roboto-Bold',
    fontSize: "18px",
  },
  errorContainer: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16
  },
  slider: {
    marginTop: "60px",
  },
  label: {
    color: "#0092E5",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    fontSize: "16px"
  }
}));
