import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="27.467"
      height="30"
      viewBox="0 0 27.467 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_7614"
            data-name="Rechteck 7614"
            width="27.467"
            height="30"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_7833" data-name="Gruppe 7833" transform="translate(0 0)">
        <g
          id="Gruppe_7829"
          data-name="Gruppe 7829"
          transform="translate(0 0)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Pfad_6675"
            data-name="Pfad 6675"
            d="M27.466,8.85a3.519,3.519,0,0,0-3.515-3.516H21.793A16.193,16.193,0,0,0,18.409.589L17.8,0l-.61.589a16.193,16.193,0,0,0-3.384,4.745H11.647a3.5,3.5,0,0,0-2.6,1.144L7.457,4.885,8.7,3.643,7.457,2.4,4.972,4.885,3.729,3.643,4.972,2.4,3.729,1.157,0,4.885,1.243,6.128,2.486,4.885,3.728,6.128,1.243,8.614,2.486,9.857,3.728,8.614l6.309,6.309a11.49,11.49,0,0,0-.148,1.836,10.826,10.826,0,0,0,2.252,6.764,7.562,7.562,0,0,0,4.048,2.706A4.374,4.374,0,0,1,8.132,23.79V20.274a2.637,2.637,0,1,0-5.273,0v4.394H4.616V20.274a.879.879,0,1,1,1.758,0V23.79a6.136,6.136,0,0,0,11.715,2.629,7.273,7.273,0,0,0,5.368-2.9,10.826,10.826,0,0,0,2.252-6.764,11.163,11.163,0,0,0-.929-4.492A3.521,3.521,0,0,0,27.466,8.85M6.214,6.128l6.214,6.214-1.243,1.243L4.972,7.371Zm17.737,4.479H21.822l.811,1.335a9.258,9.258,0,0,1,1.317,4.816c0,4.361-2.76,7.91-6.152,7.91s-6.152-3.548-6.152-7.91a9.7,9.7,0,0,1,.077-1.226l.7-.7,2.486,2.486,1.243-1.243-2.486-2.486,1.243-1.243L10.3,7.724a1.748,1.748,0,0,1,1.351-.632H16.92V5.334H15.732A14.541,14.541,0,0,1,17.8,2.493a14.553,14.553,0,0,1,2.068,2.841H18.678V7.092h5.273a1.758,1.758,0,0,1,0,3.515"
            transform="translate(0 0)"
            fill="#80858c"
          />
        </g>
      </g>
    </svg>
  );
};
