import React from "react";
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import SvgIcon from "@material-ui/core/SvgIcon";

import styles from "./styles";
import {withEuroOrDash} from "../../../../../../../utils/utils";
import IconButton from "../../../../../../../components/Buttons/IconButton";
import {getAssetsListData, isRequiredQuestion, isValidValue} from "../../../../../utils";
import PlusBackgroundIcon from "../../../../../../../images/PlusBackgroundIcon";
import {DeleteIcon, EditIcon} from "../../../../../../../images";
import {FIELD_REQUIRED_MSG} from "../../../../../constants";

function getTotal(items) {
  return items.reduce((sum, item) => {
    return sum + Number(item.value);
  }, 0);
}

function isAssetsQuestionValid(question, step, afterChange, service) {
  if(isRequiredQuestion(question)){
    if(!_.isEmpty(question.answer)){
      const isValid = question.answer.every(answer => {
        return isQuestionAnswerValid(question, answer);
      });

      if(!afterChange){
        question.error = isValid ? null : FIELD_REQUIRED_MSG;
      }

      return isValid;
    } else {
      if(!afterChange){
        question.error = FIELD_REQUIRED_MSG;
      }
      return false;
    }
  } else {
    return true;
  }
}

function isQuestionAnswerValid(question, answer){
  if(answer) {
    const answerConfig = question.config.answer_format_config;
    const isValidName = answerConfig[0].required ? isValidValue(answer.name) : true;
    const isValidInfo = answerConfig[1].required ? isValidValue(answer.info) : true;
    const isValidVal = answerConfig[2].required ? isValidValue(answer.value) : true;

    return isValidName && isValidInfo && isValidVal;
  } else {
    return false;
  }
}

function getAssetsQuestionAnswer(question) {
    const answerConfig = question.config.answer_format_config;
    return {
      question_uid: question.uid,
      answer: (!question.disabled && question.answer || []).map(item => {
        return {
          [answerConfig[0].key]: item.name,
          [answerConfig[1].key]: item.info,
          [answerConfig[2].key]: Number(item.value)
        }
      })
  }
}

function setAssetsQuestionAnswer(question, answer) {
  if(answer && answer.length > 0){
    const answerConfig = question.config.answer_format_config;
    const assetData = getAssetsListData(question.uid);

    question.answer = answer.map(item => {
      const _asset = assetData && assetData.items.find(data => data.name === item[answerConfig[0].key]) || {};
      return {
        ..._asset,
        name: item[answerConfig[0].key],
        info: item[answerConfig[1].key],
        value: item[answerConfig[2].key]
      }
    });
  }
}

export const AssetsQuestionMethods = {
  required: isRequiredQuestion,
  valid: isAssetsQuestionValid,
  answer: getAssetsQuestionAnswer,
  validAnswer: isQuestionAnswerValid,
  initAnswer: setAssetsQuestionAnswer,
}

const AssetsQuestion = props => {
  const {
    question,
    onAddClick,
    onEditClick,
    onDeleteClick,
    classes
  } = props;

  const items = question.answer || [];
  const total = getTotal(items);

  const handleAddClick = () => {
    onAddClick(question);
  }

  const handleEditClick = (item, index) => {
    onEditClick(question, item, index);
  }

  const handleDeleteClick = (item, index) => {
    onDeleteClick(question, item, index);
  }

  return (
    <div className={classes.assetsQuestionContainer} data-disabled={question.disabled}>
      <div className={classes.header}>
        <div className={classes.name}>{question.question_text}</div>
        <div className={classes.total}>{withEuroOrDash(total)}</div>
      </div>
      {items.length > 0 && (
        <div className={classes.content}>
          {items.map((item, index) => (
            <div key={index} className={classes.itemContainer}>
              <div className={classes.itemRow}>
                <div className={classes.itemIconCell}>
                  <SvgIcon component={item.icon} />
                </div>
                <div><span className={classes.itemHeader}>{item.name}</span></div>
              </div>
              <div className={classes.itemRow}>
                <div className={classes.itemIconCell} />
                <div><span className={classes.itemHeader}>{item.info}</span></div>
                <div style={{borderRight: 'solid 2px #D8DCDF'}}>
                  <IconButton component={EditIcon} onClick={() => handleEditClick(item, index)} />
                </div>
                <div><IconButton component={DeleteIcon} onClick={() => handleDeleteClick(item, index)} /></div>
              </div>
              <div className={classes.itemRow}>
                <div className={classes.itemIconCell} />
                <div><span className={classes.itemValue}>{withEuroOrDash(Number(item.value))}</span></div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className={classes.footer}>
        <IconButton
          label={'Hinzufügen'}
          component={PlusBackgroundIcon}
          onClick={handleAddClick}
          size={36}
        />
      </div>
    </div>
  )
};

export default withStyles(styles)(AssetsQuestion);