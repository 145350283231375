import React from 'react';
import {SharedSettingsResource} from "../../../utils/api";
import {setEditorVariables} from "../../GroupReporting/actions";

// TODO: Need to use this hook to request editor variables. Remove duplicates and use this hook instead
function useEditorVariables(dispatch) {

  React.useEffect(() => {
    fetchVariables();
  }, []);

  const fetchVariables = async () => {
    try {
      const response = await SharedSettingsResource.at('editor-variables/').get();
      dispatch(setEditorVariables(response));
      window.editorVariables = response;
    } catch (e) {
      dispatch(setEditorVariables(undefined));
      window.editorVariables = undefined;
    }
  };

};

export default useEditorVariables