import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import clsx from "clsx";
import connect from "react-redux/lib/connect/connect";
import { withWidth } from '@material-ui/core';

import {
  Card,
  CardContent,
  Grid,
  CircularProgress
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import GroupDetails from '../../../../components/Group/GroupDetails';
import CustomersTable from '../../../../components/Group/CustomersTable';
import DeleteGroupModal from "../../../ReportGroupCreation/components/DeleteGroupModal/DeleteGroupModal";

import useStyles from './styles';
import {ROUTES} from "../../../../routes";
import {newDesignUsed} from '../../../../utils/utils';
import { executeIfPathExist, getInvestmentDynamicPath } from '../../../InvestmentPlatform/utils';
import DashboardSettingsWindow from '../DashboardSettingsWindow';
import DashboardSettingsWindowV2 from '../DasboardSettingWindowV2';
import moment from 'moment';
import {PERFORMANCE_TIME_TYPE} from '../../../../utils/constants';
import { CustomerReportSettingResource } from '../../../../utils/api';
import {DASHBOARD_GROUP_TYPE_MENU_ITEM_MAPPING, DASHBOARD_SETTINGS_TYPE, DASHBOARD_TYPE} from '../../constants';
import {getDashboardSettingsType} from "../../utils";


const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
  auth: state.get('auth').toJS(),
});

const DashboardGroupSettings = props => {
  const classes = useStyles();
  const {
    group,
    handleGroupUpdated,
    onGroupDeleted,
    onEditNameClick,
    loading,
    updatedGroup,
    dashboardType
  } = props;

  let groupSettings = updatedGroup || group;
  const DashboardSettingsWindowComponent = props.newDesign ? DashboardSettingsWindowV2 : DashboardSettingsWindow;

  const [expanded, setExpanded] = React.useState(false);
  const [isDeleting, setDeletingStatus] = React.useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState(false);


  React.useEffect(() => {
    if(group){
      prepareDates(group)
    }
  }, [])

  const deleteGroup = async () => {
    setDeletingStatus(true);
    try {
      await CustomerReportSettingResource.at(`broker/${group.id}/delete-dashboard-group/`).delete();
      if (!_.isNil(onGroupDeleted)) {
        onGroupDeleted();
      }
    } catch (error) {
      //TODO: Add error handling
    }
    setIsDeleteModalVisible(false);
    setDeletingStatus(false);
  }

  const handleClickExpandButton = () => {
    return setExpanded(!expanded);
  };

  const handleEditGroupButtonClick = () => {
    let path = ROUTES.BROKER.DASHBOARD_GROUP_UPDATE.path.replace(':group_id', group.id)
    let investmentDynamicPath = getInvestmentDynamicPath()

    let menu_item = !_.isNil(props.dashboardGroupType)
        ? DASHBOARD_GROUP_TYPE_MENU_ITEM_MAPPING[_.findKey(DASHBOARD_SETTINGS_TYPE, (value) => value===props.dashboardGroupType)]
        : 'REPORT_SETTINGS'
    if (newDesignUsed()) {
      executeIfPathExist(props.investmentPlatform.routes, menu_item, (dynamicPath) => {
        path = '/' + investmentDynamicPath + dynamicPath + ROUTES.BROKER.DASHBOARD_GROUP_UPDATE.newDesignPath.replace(':group_id', group.id)
      })
    }

    props.history.push(path)
  }

  const [dateRange, setDateRange] = React.useState({
    start: undefined,
    end: undefined
  });

  const [snackbarSettings, setSnackbarSettings] = React.useState({
    open: false,
    variant: 'success',
    message: ''
  });
  const handleSnackbarVisibility = (state) => {
    setSnackbarSettings({
      ...snackbarSettings,
      open: state
    })
  };

  const handleDateRangeChanged = (dates, datesType) => {
    handleGroupUpdated('date_updates')({
      date_range: datesType && datesType.value,
      date_range_start_date: moment(dates.start).format('YYYY-MM-DD'),
      date_range_end_date: moment(dates.end).format('YYYY-MM-DD'),
    })

    let newRange;
    if(datesType.value === PERFORMANCE_TIME_TYPE.CUSTOM.value){
      newRange = {
        start: moment(dates.start),
        end: moment(dates.end)
      };
    } else {
      newRange = dates;
    }
    setDateRange(newRange)
    return newRange
  }
  const prepareDates = (response) => {
    let init_data;
    if(response && response.date_range === PERFORMANCE_TIME_TYPE.CUSTOM.value.toString()) {
       init_data = {
        start: moment(response.date_range_start_date),
        end: moment(response.date_range_end_date),
      }
    }
    else if (response && response.date_range) {
      const performanceTimeType = Object.values(PERFORMANCE_TIME_TYPE).find(item => item.value == response.date_range);
      init_data = performanceTimeType.getDateRange();
    }
    else {
      init_data = {
        start: undefined,
        end: undefined
      }
    }
    setDateRange(init_data)
  }

  const updateBenchmarkSwitchState = (forceShow) => {
    handleGroupUpdated('benchmark_updates')({
      benchmarks: groupSettings.benchmarks,
      benchmark_enabled: !!forceShow
    })
  };
  const handleBenchmarkUpdate = (benchmarks, enabled) => {
    const preparedBenchmarksData = benchmarks.map(benchmark => ({
      ...benchmark,
      settings_id: group.id,
      benchmark_id: benchmark.id,
      percentage: benchmark.weight
      }
    ));

    handleGroupUpdated('benchmark_updates')({
      benchmarks: preparedBenchmarksData,
      benchmark_enabled: enabled
    })
  };
  
  return (
    <>
      <Card className={classes.container} id="report-settings-group-details-tour-element">
        <CardContent style={{padding: 0}}>
          <Grid container>
            <Grid className={classes.groupDetailsContainer} item lg={3} md={12} sm={12} xs={12}>
              <div className={clsx(loading && 'loading-line', classes.groupDetailSection)}>
                <GroupDetails group={group} loading={loading} />
                <div className={clsx(classes.expandButtonWrapper, loading && 'loading-line')}>
                  <PrimaryButton
                    text={expanded ? 'Zuklappen' : 'Ausklappen'}
                    onButtonClick={handleClickExpandButton}
                    icon={expanded ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                  />
                </div>
              </div>
            </Grid>
            <Grid item lg={9} md={12} sm={12}>
              <DashboardSettingsWindowComponent
                settings={groupSettings}
                handleSettingUpdate={handleGroupUpdated}
                handleDateRangeChanged={handleDateRangeChanged}
                dateRange={dateRange}
                loading={loading}
                snackbarSettings={snackbarSettings}
                handleSnackbarVisibility={handleSnackbarVisibility}
                benchmarks={groupSettings.benchmarks}
                showBenchmark={groupSettings.benchmark_enabled}
                updateBenchmarkSwitchState={updateBenchmarkSwitchState}
                handleBenchmarkUpdate={handleBenchmarkUpdate}
                settingsType={ // TODO
                  getDashboardSettingsType(dashboardType, true)
                }
              />
            </Grid>
            <Grid item xs={12} className={classes.groupItemActionsWrapper} id="report-settings-group-actions-tour-element">
              <div className={clsx(classes.groupItemActions, loading && 'loading-line')}>
                <span onClick={handleEditGroupButtonClick}>
                  <i className="fa fa-plus" aria-hidden="true" /><span>Kunden hinzufügen/löschen</span>
                </span>
                <span onClick={() => onEditNameClick(group)} >
                  <i className="fa fa-pencil" aria-hidden="true" /><span>Gruppe umbenennen</span>
                </span>
                <span onClick={() => setIsDeleteModalVisible(true)}>
                  {isDeleting ? (
                    <CircularProgress size={15}/>
                  ) : (
                    <i className="fa fa-trash-o" aria-hidden="true" />
                  )}
                  <span>Gruppe löschen</span>
              </span>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {(group.expanded || expanded) && (
        <div id='report-settings-group-customer-list-tour-element'>
          <CustomersTable customers={group && group.customers || []}/>
        </div>
      )}
      <DeleteGroupModal
        open={isDeleteModalVisible}
        handleClose={() => setIsDeleteModalVisible(false)}
        handleDeleteClick={() => deleteGroup()}
        isDeleting={isDeleting}
        groupName={group.name}
      />
    </>
  )
};


export default connect(mapStateToProps)(withRouter(withWidth()(DashboardGroupSettings)));

