import React from "react";
import useStyles from "./styles";

const AspectCard = ({icon, text, classNames, onClick}) => {
    const classes = useStyles();

    return (
        <div
            className={classNames}
            onClick={onClick}
        >
            <div className={classes.icon}>
                {icon}
            </div>
            {text}
        </div>
    );
};


export default AspectCard;
