import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles'

Pagination.propTypes = {

};

function Pagination(props) {

  const classes = useStyles()

  return (
    <div></div>
  );
}

export default Pagination;