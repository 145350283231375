import React, {useState} from 'react'
import { connect } from 'react-redux';
import {Container, isWidthUp} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Navigation from "./components/Navigation";
import {executeIfPathExist, getInvestmentDynamicPath} from "../InvestmentPlatform/utils";
import {useHistory, useRouteMatch} from "react-router-dom";
import Link from "../../components/Link";
import {
  CustomerReportSettingResource,
  CustomerResource,
  parseResponse,
  PortfolioHandlerResource
} from "../../utils/api";
import CustomerSummaryCard from "./components/CustomerSummaryCard/CustomerSummaryCard";
import _ from "lodash";
import {
  AGGREGATED_PORTFOLIO_ID,
  getRiskChartTitleType,
  PortfolioUtils,
  priceChartTitle,
  volatilityChartTitle
} from "../../utils/utils";
import {DashboardSection} from "../CustomerDashboard/components/Widgets/components";
import RiskIndicatorChart
  from "../CustomerDashboard/components/Widgets/components/PortfolioStructure/RiskIndicatorsChart";
import InstrumentByListIndicatorList
  from "../CustomerDashboard/components/Widgets/components/InstrumentByRiskIndicatorList/InstrumentByListIndicatorList";
import InvestmentAmount from "../../components/InvestmentDetails/components/InvestmentAmount";
import TotalInvestedAmount from "../../components/InvestmentDetails/components/TotalInvestedAmount";
import {
  DashboardNumbersLoadingIndicator,
  PORTFOLIO_RISK_METRICS_MAX_TIME_FRAME_ID,
  RISK_INDICATORS_DATA_RETRIEVING_TIME_FRAMES, RiskMetricsLoadingIndicator
} from "./constants";
import LostPriceChart from "../CustomerDashboard/components/Widgets/components/RiskCharts/LostPriceChart";
import VolatilityChart from "../CustomerDashboard/components/Widgets/components/RiskCharts/VolatilityChart";
import {
  buildRiskMetricsRequestState,
  finishRiskMetricsRequest,
  finishRiskMetricsTimeFrameRequest,
  isAggregatedPortfolioRiskMetricsLoading, isIndividualPortfolioRiskMetricsLoading,
  startRiskMetricsTimeFrameRequest
} from "./utils";
import {displayErrorSnackBar, displaySuccessSnackBar} from "../../components/SnackbarProvider/actions";
import AlertConfigurationWrapper from "./components/AlertConfigurationModal/AlertConfigurationModal";
import {updateCustomerInStore} from "../../components/CustomersDataProvider/actions";
import useStyles from './styles';
import {useCustomerAppData} from "./hooks/useCustomerAppData";
import {useSelectedPortfolios} from "./hooks/useSelectedPortfolios";
import {usePortfoliosData} from "./hooks/usePortfoliosData";
import {useInstrumentsTableData} from "./hooks/useInstrumentsTableData";
import {useIndividualPortfoliosRiskMetricsData} from "./hooks/useIndividualPortfoliosRiskMetricsData";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {getInvestmentPlatformSelector} from "../../utils/redaxSelectors";

const mapStateToProps = (state) => ({
  investmentPlatform: getInvestmentPlatformSelector(state)
});

function RiskDashboardDetails(props) {

  const {width} = props;
  const routeMatch = useRouteMatch()
  // region Data Fetching

  const customerData = useCustomerAppData(routeMatch.params.customer_id, {
    with_last_srri: true,
    with_historical_portfolios: false,
  })
  const [selectedPortfolios, setSelectedPortfolios] = useSelectedPortfolios(customerData)
  const [portfoliosData, setPortfoliosData] = usePortfoliosData(
    routeMatch.params.customer_id, customerData, selectedPortfolios)
  const {instrumentsTableData, handleAlertConfigurationSaved} = useInstrumentsTableData(
    portfoliosData, setPortfoliosData)
  const [individualPortfoliosRiskMetricsData] = useIndividualPortfoliosRiskMetricsData(
    routeMatch.params.customer_id, customerData, selectedPortfolios)

  const [aggregatedPortfolioRiskMetricsData, setAggregatedPortfolioRiskMetricsData] = React.useState(buildRiskMetricsRequestState())

  // endregion

  React.useEffect(() => {

    if (customerData.loading) {
      return
    }

    if (selectedPortfolios.length) {
      fetchAggregatedPortfolioRiskMetrics()
    } else {
      setAggregatedPortfolioRiskMetricsData((data) => finishRiskMetricsRequest({...data}))
    }

  }, [selectedPortfolios])

  /**
   * Fetch aggregated portfolios risk metrics data for specified time frame.
   * @param timeFrame
   * @returns {Promise<void>}
   */
  const fetchAggregatedPortfolioTimeFrameRiskMetrics = async (timeFrame) => {

    setAggregatedPortfolioRiskMetricsData((data) => startRiskMetricsTimeFrameRequest({...data}, timeFrame))

    try {

      const result = await PortfolioHandlerResource.getRiskData(
        routeMatch.params.customer_id,
        PortfolioUtils.getSelectedPortfoliosNumbers(selectedPortfolios) || [0],
        timeFrame.startDate, timeFrame.endDate, false, getBenchmarksData())

      parseResponse(result, 'risk', (result) => {
        setAggregatedPortfolioRiskMetricsData((data) => finishRiskMetricsTimeFrameRequest({...data}, timeFrame, result))
      }, (errors) => {
        setAggregatedPortfolioRiskMetricsData((data) => finishRiskMetricsTimeFrameRequest({...data}, timeFrame, undefined, errors))
      })

    } catch (errors) {
        setAggregatedPortfolioRiskMetricsData((data) => finishRiskMetricsTimeFrameRequest({...data}, timeFrame, undefined, errors))
    }

  }

  /**
   * Fetch aggregated portfolio risk metrics for all required time frames.
   */
  const fetchAggregatedPortfolioRiskMetrics = () => {
    RISK_INDICATORS_DATA_RETRIEVING_TIME_FRAMES.forEach((timeFrame) => {
      fetchAggregatedPortfolioTimeFrameRiskMetrics(timeFrame)
    })
    fetchAggregatedPortfolioTimeFrameRiskMetrics({id: PORTFOLIO_RISK_METRICS_MAX_TIME_FRAME_ID})
  }

  /**
   * Customer financial summary information (Aggregated values and risk indicators).
   * @type {Object}
   */
  const financialData = React.useMemo(() => {

    if (portfoliosData.loading) {
      return [null, null]
    }

    let data = null
    let errors = null

    if (portfoliosData.errors) {
      errors = portfoliosData.errors
    } else {
      parseResponse(portfoliosData.data, 'financial',
        (parsedData) => data = parsedData.data,
        (parsedErrors) => errors = parsedErrors)
    }

    return [data, errors]
  }, [portfoliosData.updatedAt])

  /**
   * Max portfolios srri value.
   * @type {number?}
   */
  const customerSummaryProductRisk = React.useMemo(() => {

    const [data, errors] = financialData

    if (errors) {
      return
    }

    return data && data.sri

  }, [financialData])

  /**
   * Selected portfolios breakdown data.
   */
  const breakdownData = React.useMemo(() => {

    if (portfoliosData.loading) {
      return [null, null]
    }

    let data = null
    let errors = null

    if (portfoliosData.errors) {
      errors = portfoliosData.errors
    } else {
      parseResponse(portfoliosData.data, 'breakdown',
        (parsedData) => data = parsedData,
        (parsedErrors) => errors = parsedErrors)
    }

    return [data, errors]

  },[portfoliosData.updatedAt])


  /**
   * Data for InvestmentAmount chart
   * @type {unknown}
   */
  const investmentAmountData = React.useMemo(() => {

    const [data, errors] = financialData

    if (errors) {
      return {}
    }

    return {
      value: data && +data.aum.aum_value || 0,
    }

  }, [financialData])

  /**
   * Data for TotalInvestmentAmount chart
   * @type {unknown}
   */
  const totalInvestmentAmountData = React.useMemo(() => {

    const [data, errors] = financialData

    if (errors) {
      return {}
    }

    return {
      value: data && +data.total.value || 0 || 0,
    }
  }, [financialData])


  const LeftNavigationContent = React.useCallback(() => {

    const history = useHistory();

    const handleBackLinkClick = () => {
      executeIfPathExist(props.investmentPlatform.routes, 'RISK_INDICATOR', path => {
        history.push(`/${getInvestmentDynamicPath()}${path}`)
      })
    }

    return (
      <Link
        title="Zur Kundenliste"
        icon={<i class="fa fa-chevron-left" aria-hidden="true"></i>}
        underline
        onClick={handleBackLinkClick}
      />
    )

  }, [props.investmentPlatform]);

  const handlePortfoliosChange = (portfolios) => setSelectedPortfolios(portfolios)

  const getAggregatedPortfoliosRiskMetricsData = (data) => {
    const volatility = data && data.volatility;
    const benchmarkVolatility = data && data.benchmark_volatility;
    const error = volatility && volatility.error;
    const minDateMessage = volatility && volatility.min_date_message;

    return {
      volatility, benchmarkVolatility, error, minDateMessage
    }
  }

  const getAggregatedPortfoliosRiskMetricsChart = (riskMetricsData, ChartComponent, title, expandable, expanded, setExpanded, withoutExpandButton) => {
    const {loading, data, errors} = riskMetricsData;

    if (loading) {
      return (
        <ChartComponent title={title} fullTitle loading loadingContent={<RiskMetricsLoadingIndicator />}/>
      )
    }

    const riskData = getAggregatedPortfoliosRiskMetricsData(data)
    return (
      <ChartComponent
        title={title}
        expandable={expandable}
        expanded={expanded}
        onExpanded={setExpanded}
        fullTitle
        withoutExpandButton={withoutExpandButton}
        {...{...riskData, errors: errors || riskMetricsData.erros}}
      />
    )
  }

  const getBenchmarksData = () => {
    return _.get(customerData, 'data.dashboard_settings.benchmarks', []).map(b => {
      return { ...b, percentage: parseInt(b.percentage)}
    })
  }

  const [expandedItems, setExpandedItems] = React.useState({
    riskIndicatorChart: true,
    investmentAmount: true,
    totalInvestedAmount: true,
    'max': true,
    'y1': true,
    'y3': true,
    'y5': true,
    instrumentByListIndicator: [],
  });

  const handleExpandedItemsChange = (expandedItem, value) => {
    setExpandedItems(prevState => ({
        ...prevState,
        [expandedItem]: value
    }));
  };

  const handleDownloadPdfClick = async () => {

    const requestBody = {
      "depots": selectedPortfolios
        .filter((portfolio) => portfolio.depotNumber != AGGREGATED_PORTFOLIO_ID)
        .map((portfolio) => portfolio.depotNumber),
      'benchmarks': getBenchmarksData(),
      'expanded_items': expandedItems
    }

    try {

      await CustomerReportSettingResource.at(
        `report/risk-indicators/${routeMatch.params.customer_id}/details/`).post(requestBody)

      props.dispatch(displaySuccessSnackBar('Die Risikoübersicht des Kunden wird erstellt.'))

    } catch (errors) {
      props.dispatch(displayErrorSnackBar('Die Risikoübersicht für den Kunden konnte nicht erstellt werden. Bitte versuchen Sie es es später noch einmal.'))
    }

  }

  const handleChangePermanentSuitabilityCheck = async (permanentSuitabilityCheck) => {

    const requestBody = {
      "permanent_suitability_check": permanentSuitabilityCheck,
    }
    let updated = false;

    try {
      await CustomerResource.at(
        `permanent-suitability-check/${routeMatch.params.customer_id}/`).post(requestBody)

      props.dispatch(displaySuccessSnackBar('Ihre Änderung wurde erfolgreich gespeichert.'))
      props.dispatch(updateCustomerInStore(routeMatch.params.customer_id, {
        permanent_suitability_check_enabled: permanentSuitabilityCheck
      }))
      updated = true
    } catch (errors) {
      props.dispatch(displayErrorSnackBar('Ihre Änderung wurde leider nicht gespeichert. Bitte versuchen Sie es später nochmal.'))
    }
    return updated;
  }

  const [expandedRiskMetricsFirstSection, setExpandedRiskMetricsFirstSection] = useState(true);
  const [expandedRiskMetricsSecondSection, setExpandedRiskMetricsSecondSection] = useState(true);
  const [expandedRiskMetricsThirdSection, setExpandedRiskMetricsThirdSection] = useState(true);

  React.useEffect(() => {
    handleExpandedItemsChange('investmentAmount', expandedRiskMetricsFirstSection)
    handleExpandedItemsChange('totalInvestedAmount', expandedRiskMetricsFirstSection)
  }, [expandedRiskMetricsFirstSection])

  React.useEffect(() => {
    handleExpandedItemsChange('max', expandedRiskMetricsSecondSection)
    handleExpandedItemsChange('y1', expandedRiskMetricsSecondSection)
  }, [expandedRiskMetricsSecondSection])

  React.useEffect(() => {
    handleExpandedItemsChange('y3', expandedRiskMetricsThirdSection)
    handleExpandedItemsChange('y5', expandedRiskMetricsThirdSection)
  }, [expandedRiskMetricsThirdSection])

  const classes = useStyles();

  const riskChartExpandButtonRightPosition = !isWidthUp('lg', width);
  const investedAmountChartExpandButtonRightPosition = isWidthUp('md', width);

  return (
    <AlertConfigurationWrapper
      customer_id={routeMatch.params.customer_id}
      onAlertConfigurationSaved={handleAlertConfigurationSaved}
      instrumentsRiskMetricsData={individualPortfoliosRiskMetricsData}
    >
    <Container className={'app-page-container'}>
      <Navigation
        title={"Kundenportfolio Risikoklassenanalyse"}
        leftNavigationContent={<LeftNavigationContent />}
        onDownloadPdfClick={handleDownloadPdfClick}
        downloadButtonDisabled={customerData.loading || portfoliosData.loading}
      />
        <Grid container spacing={3} style={{marginTop: 20}}>
          <Grid item xs={12}>
            <CustomerSummaryCard
              customer={_.get(customerData, 'data')}
              errors={_.get(customerData, 'errors')}
              loading={customerData.loading}
              isOverview
              productRiskValue={customerSummaryProductRisk}
              productRiskLoading={portfoliosData.loading}
              productRiskErrors={financialData[1]}
            >
              <CustomerSummaryCard.Contacts
                handleChangePermanentSuitabilityCheck={handleChangePermanentSuitabilityCheck}
                GridProps={{md: 5, xs: 12}}
              />
              <CustomerSummaryCard.DepotsSelection
                GridProps={{md: 3, xs: 12, style: {alignItems: 'center'}}}
                selectedPortfolios={selectedPortfolios}
                disabled={portfoliosData.loading || isAggregatedPortfolioRiskMetricsLoading(aggregatedPortfolioRiskMetricsData) || isIndividualPortfolioRiskMetricsLoading(individualPortfoliosRiskMetricsData)}
                onPortfoliosChange={handlePortfoliosChange}
              />
              <CustomerSummaryCard.RiskIndicators
                GridProps={{md: 4, xs: 12}}
                withDashboardButton
                indicatorClassName={'riskIndicator'}
              />
            </CustomerSummaryCard>
          </Grid>
          <Grid item xs={12}>
            <DashboardSection
              style={{padding: '0 20px', overflowX: 'auto'}}
              minWidth={290}
              content={(
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <InvestmentAmount
                      loading={customerData.loading || portfoliosData.loading}
                      loadingContent={<DashboardNumbersLoadingIndicator />}
                      loadingWithTitle
                      expandable
                      expanded={expandedRiskMetricsFirstSection}
                      onExpanded={setExpandedRiskMetricsFirstSection}
                      withExpandButton={!investedAmountChartExpandButtonRightPosition}
                      {...investmentAmountData}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TotalInvestedAmount
                      loading={customerData.loading || portfoliosData.loading}
                      loadingContent={<DashboardNumbersLoadingIndicator />}
                      loadingWithTitle
                      expandable
                      expanded={expandedRiskMetricsFirstSection}
                      onExpanded={setExpandedRiskMetricsFirstSection}
                      withExpandButton={investedAmountChartExpandButtonRightPosition}
                      {...totalInvestmentAmountData}
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12} className={classes.chartsWrapper}>
                <RiskIndicatorChart
                  expandedItemKey={'riskIndicatorChart'}
                  handleExpandedItemsChange={handleExpandedItemsChange}
                  dashboardData={breakdownData[0]}
                  instrumentsData={instrumentsTableData[0] || []}
                  loading={customerData.loading || portfoliosData.loading}
                />
              </Grid>
              <Grid container item md={8} sm={12}>
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                  <DashboardSection
                    minHeight={"100%"}
                    style={{padding: '0 20px', overflowX: 'auto'}}
                    minWidth={212}
                    content={(
                      <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                          {getAggregatedPortfoliosRiskMetricsChart(
                            aggregatedPortfolioRiskMetricsData['max'],
                            LostPriceChart,
                            priceChartTitle[getRiskChartTitleType(selectedPortfolios)],
                            true,
                            expandedRiskMetricsSecondSection,
                            setExpandedRiskMetricsSecondSection,
                            !riskChartExpandButtonRightPosition
                          )}
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          {getAggregatedPortfoliosRiskMetricsChart(
                            aggregatedPortfolioRiskMetricsData['1y'],
                            VolatilityChart,
                            `${volatilityChartTitle[getRiskChartTitleType(selectedPortfolios)]} 1 Jahr`,
                            true,
                            expandedRiskMetricsSecondSection,
                            setExpandedRiskMetricsSecondSection,
                            riskChartExpandButtonRightPosition
                          )}
                        </Grid>
                      </Grid>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DashboardSection
                    minHeight={"100%"}
                    style={{padding: '0 20px', overflowX: 'auto'}}
                    minWidth={212}
                    content={(
                      <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                          {getAggregatedPortfoliosRiskMetricsChart(
                            aggregatedPortfolioRiskMetricsData['3y'],
                            VolatilityChart,
                            `${volatilityChartTitle[getRiskChartTitleType(selectedPortfolios)]} 3 Jahre`,
                            true,
                            expandedRiskMetricsThirdSection,
                            setExpandedRiskMetricsThirdSection,
                            !riskChartExpandButtonRightPosition
                          )}
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          {getAggregatedPortfoliosRiskMetricsChart(
                            aggregatedPortfolioRiskMetricsData['5y'], VolatilityChart,
                            `${volatilityChartTitle[getRiskChartTitleType(selectedPortfolios)]} 5 Jahre`,
                            true,
                            expandedRiskMetricsThirdSection,
                            setExpandedRiskMetricsThirdSection,
                            riskChartExpandButtonRightPosition
                          )}
                        </Grid>
                      </Grid>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DashboardSection
              title='Einteilung der Instrumente in Risikoklassen'
              loading={customerData.loading || portfoliosData.loading}
              error={customerData.errors || portfoliosData.errors || instrumentsTableData[1]}
              content={(
                <InstrumentByListIndicatorList
                  expandedItemKey={'instrumentByListIndicator'}
                  expandedItems={expandedItems}
                  handleExpandedItemsChange={handleExpandedItemsChange}
                  data={instrumentsTableData[0] || []}
                  riskMetricsData={individualPortfoliosRiskMetricsData}
                />
              )}
            />
          </Grid>
        </Grid>
    </Container>
    </AlertConfigurationWrapper>
  )

}

export default connect(mapStateToProps)(withWidth()(RiskDashboardDetails));
