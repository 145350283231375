import React from 'react';
import {Dialog, DialogContent, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import useStyles from './styles'

// region BCA-7408 Remove later
function FixModelPortfolioModal(props) {
  const classes = useStyles();
  return (
    <Dialog maxWidth='sm' fullWidth open={props.open} onClose={props.onClose}>
      <DialogContent className={classes.modalContent}>
        <div className={classes.messageContent}>
          Das ausgewählte Musterdepot kann nicht dargestellt werden,  da für mindestens einem der darin enthaltenen Produkte keine Preisdaten vorliegen. Ein Grund hierfür kann sein, dass dieses Produkt nicht mehr existiert.
        </div>
        <div className={classes.buttonsRow}>
          <Button
            className={clsx(classes.buttonCancel, classes.button)}
            disableRipple={true}
            onClick={props.onClose}
          >
            Abbrechen
          </Button>
          <PrimaryButton
            text={'Musterdepot löschen'}
            classes={{button: classes.button}}
            onButtonClick={props.onDelete}
          />
          <PrimaryButton
            text={'Produkte austauschen'}
            classes={{button: classes.button}}
            onButtonClick={props.onEdit}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}
// endregion

export default FixModelPortfolioModal;