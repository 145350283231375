import React from 'react';
import _ from 'lodash'
import moment from 'moment';

import {getIdentifierByTypeId, getIdentifierCodeByTypeId} from "../../components/CustomersDataProvider/utils";
import {getSortedObjects} from '../../utils/utils'
import {TRANSACTION_ORDER_TYPE} from "./constants";

/**
 * Get list of dropdown options for list of portfolios.
 *
 * @param {Array<*>} portfolios List of portfolios
 * @return {Array<{id: String, value: String}>} List of dropdown options
 */
export const getCustomerPortfoliosOptions = (portfolios) => {
  return getSortedObjects(portfolios.reduce((result, portfolio) => {
    if (portfolio.depotNumber) {
      result.push({
        id: _.toString(portfolio.depotNumber),
        value: portfolio.name
      })
    }
    return result
  }, []))
}

/**
 * Get list of dropdown options for list of clearing accounts.
 *
 * @param {Array<*>} portfolios List of clearing accounts
 * @return {Array<{id: String, value: String}>} List of dropdown options
 */
export const getClearingAccountsOptions = (clearingAccounts) => {
  return getSortedObjects(clearingAccounts.reduce((result, cAccount) => {
    const depotNumber = getIdentifierCodeByTypeId(cAccount.identifiers, 3);
    if (depotNumber) {
      result.push({
        id: _.toString(depotNumber),
        value: depotNumber
      })
    }
    return result
  }, []))
}


/**
 * Get list of dropdown options for list of transaction types.
 *
 * @param {Array<*>} transactions List of transaction types
 * @return {Array<{id: String, value: String}>} List of dropdown options
 */
export const getTransactionTypesOptions = (transactions) => {

  const [_transactions, _bookings] = transactions.reduce(([_t, _b], transaction) => {

    const source = transaction.order_type == TRANSACTION_ORDER_TYPE.TRANSACTION ? _t : _b;

    source.push({
      id: transaction.code,
      value: transaction.name
    });

    return [_t, _b];
  }, [[], []])

  return [getSortedObjects(_transactions), getSortedObjects(_bookings)];

}

/**
 * Get list of dropdown options for instruments from portfolios.
 *
 * @param {Array<*>} portfolios List of portfolios
 * @return {Array<{id: String, value: String}>} List of dropdown options
 */
export const getInstrumentsOptions = (portfolios) => {
  let instrumentsAdded = []

  const instruments = portfolios.reduce((result, portfolio) => {

    if (portfolio.depotNumber === 0) {
      return result
    }

    (portfolio.components || []).forEach((component) => {
      if (!instrumentsAdded.includes(component.isin_code)) {
        instrumentsAdded.push(component.isin_code)
        result.push({
          id: component.isin_code,
          value: component.name
        })
      }
    })
    return result
  }, [])

  return getSortedObjects(instruments)

}

/**
 * Get list of dropdown options for list of instrument types.
 *
 * @param {Array<*>} broadAssetClasses List of asset classes
 * @return {Array<{id: String, value: String}>} List of dropdown options
 */
export const getTransactionInstrumentTypesOptions = (broadAssetClasses) => {
  const result = (broadAssetClasses || []).map((assetClass) => {
    return {
      id: assetClass,
      value: assetClass
    }
  });

  return getSortedObjects(result)

};

/**
 * Get transaction details from transaction data.
 *
 * @param transaction Transaction data
 */
export const getTransactionDetails = (transaction) => {

  const details = transaction.details;

  return Array.isArray(details) && details.length ? details[0] : details

}


export const getTrackingDate = (portfolioTrackingDate) => {

  const today = moment()

  if (!portfolioTrackingDate) {
    return moment(`${today.year() - 1}-01-01`)
  }

  return moment.min([moment(`${today.year() - 1}-01-01`), moment(portfolioTrackingDate)])
}

export const buildSelectOptions = (options) => (options || []).map(o => ({
  ...o,
  label: o.german || o.name || o.value
}));

export const buildSelectedValue = (isAllChecked, selected, placeholder) => {
  if(selected.length === 1){
    return selected[0].label
  } else if(isAllChecked){
    return 'Alle'
  } else if(selected.length) {
    return `${selected.length} ausgewählt`
  } else {
    return placeholder || '';
  }
};

const getSubSecurityAccount = (transaction, assetContainerKey='asset_details') => {
  const assetContainer = _.get(transaction, assetContainerKey) || {};
  let subSecurityAccount = getIdentifierByTypeId(assetContainer.identifiers, 26);
  subSecurityAccount = _.get(subSecurityAccount, 'code');

  return [subSecurityAccount || '', assetContainer];
}

export const getTransactionDepotNumber = (transaction) => {
  if (!transaction.id) {
    return undefined
  }
  const [subSecurityAccount, assetContainer] = getSubSecurityAccount(transaction, 'asset_details.asset_container');
  const identifier = getIdentifierByTypeId(assetContainer.identifiers, 27)
  if (!identifier) {
    return undefined
  }

  return identifier.code + subSecurityAccount
}

export const getTransactionSubDepotNumber = (transaction) => {
  if (!transaction.id) {
    return ''
  }
  const [subSecurityAccount, ] = getSubSecurityAccount(transaction);

  return subSecurityAccount ? subSecurityAccount : ''
}


export const updateTransactionDepot = (transaction, depots) => {
  const assetGroup = _.get(transaction, 'asset_details.group.0') || {};
  if (!assetGroup) {
    return '-';
  }

  const depot = depots.find(depot => depot.id == assetGroup.id);

  transaction.depot = depot ? depot.value : getTransactionDepotNumber(transaction);
  transaction.sub_depot = getTransactionSubDepotNumber(transaction);
}

export const isTransactionSelectable = (transaction) => transaction.editable && transaction.deletable;