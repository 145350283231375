import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38'
  },
  menuItem: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38',
    backgroundColor: 'white !important',
    height: 54,
    paddingLeft: 17,
    paddingRight: 17,

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },

    '& .MuiListItemText-root span': {
      fontFamily: 'Roboto-Regular',
      fontSize: 16,
      color: '#202A38',
    },
  },
})

export default makeStyles(styles)