import React from 'react';


export default () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <g id="Group_7971" data-name="Group 7971" transform="translate(-1009.25 -626.875)">
                <g id="Group_474" data-name="Group 474" transform="translate(1009.25 626.875)">
                    <path id="Path_1448" data-name="Path 1448" d="M1035.505,630.46h-8.77a2.665,2.665,0,0,0-1.607-1.617v-1.968h-1.758v1.968a2.666,2.666,0,0,0-1.607,1.617h-8.77l-3.745,11.273v.142a4.394,4.394,0,1,0,8.789,0v-.142l-3.168-9.526h6.894a2.649,2.649,0,0,0,1.607,1.607v10.7h-1.758v7.224l-5.273.879v4.26h15.82v-4.26l-5.273-.879v-7.224h-1.758v-10.7a2.649,2.649,0,0,0,1.607-1.607h6.894l-3.168,9.526v.142a4.395,4.395,0,1,0,8.789,0v-.142Zm-21.861,14.052a2.646,2.646,0,0,1-2.486-1.764h4.972A2.647,2.647,0,0,1,1013.644,644.512Zm2.3-3.516h-4.594l2.3-6.907Zm7.43-9.668a.879.879,0,0,1,1.758,0,.879.879,0,0,1-1.758,0Zm0,14.941h1.758V651.6h-1.758Zm2.564,7.09,4.467.744v1.013h-12.3V654.1l4.468-.744Zm8.921-19.27,2.3,6.907h-4.594Zm0,10.422a2.646,2.646,0,0,1-2.486-1.764h4.972A2.647,2.647,0,0,1,1034.855,644.512Z" transform="translate(-1009.25 -626.875)" fill="#fff" />
                </g>
            </g>
        </svg>
    )
}