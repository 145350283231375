import React from "react";
import {connect} from "react-redux";

import DashboardTable from "../../../../../../components/DashboardTable/DashboardTable";
import {getTableStructureSwitch} from "../../../../../../components/Charts/InstrumentsAllocationTable/table-data";
import useStyles from './styles';


function SwitchOutList(props) {
  const {
    selectedAssets,
    onInstrumentSelect,
    onSelectAll,
    transactions,
    withSrri,
    clientSRRI,
    initialSwitchOutInstrument
  } = props;

  const classes = useStyles();
  const [dataSource, setDataSource] = React.useState([]);

  React.useEffect(() => {
    setDataSource((transactions || []).map(item => item.data))
  }, [transactions]);

  const handleAllSelected = () => {
    if (onSelectAll){
      const selected = selectedAssets || [];
      let assets = dataSource;
      if (selected.length === assets.length) {
        assets = []; // unselect all
      }

      onSelectAll(assets)
    }
  };

  return (
    <DashboardTable
      structure={getTableStructureSwitch()}
      dataSource={dataSource}
      expanded={true}
      tableClasses={classes}
      withFooter={false}
      options={{
        initialSwitchOutInstrument,
        onAllSelect: handleAllSelected,
        totalInstrumentsCount: dataSource.length,
        onInstrumentSelect: onInstrumentSelect,
        selected: selectedAssets || [],
        withSrri,
        clientSRRI,
      }}
    />
  )
}

export default connect()(SwitchOutList);
