import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Grid } from '@material-ui/core'

import { ExAnteSection, ExAnteLine } from '../../components'

import useStyles from './styles'
import {useStyles as useAccordionStyles} from '../../../TradeStep/components/PortfolioTrade/styles'
import {
  aggregateExAnteYearlyCostValue,
  getBreakDown,
  getExAnteExplanations,
  getExAnteSimulationYearData,
  getExAnteSpecificYearData
} from "../../../../utils";
import Tooltip from "@material-ui/core/Tooltip";
import {TRANSACTION_TYPE, TRANSACTION_TYPE_VALUES} from "../../../../constants";
import ExAnteAverageLine from "../ExAnteLine/ExAnteAverageLine";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import {toGermanFormat} from "../../../../../../utils/numberFormater";

function PortfolioExAnte(props) {

  const {
    totalValue,
    exAnteData,
    transactionType,
    oneYearSavingsPlan,
    oneYearSavingsPlanTitle,
    expanded,
    isPrivateInvestment,
    simulationYears
  } = props

  if (!exAnteData) {
    return null
  }

  const _simulationYears = simulationYears || 5;

  const classes = useStyles();
  const accordionClasses = useAccordionStyles();

  const onlySell = React.useMemo(() => {
    return transactionType === 'sell';
  }, [transactionType]);

  const yearData = React.useMemo(() => {
    return getExAnteSpecificYearData(exAnteData, _simulationYears, 1)
  }, [JSON.stringify(exAnteData)])

  const twoYearData = React.useMemo(() => {
    return getExAnteSpecificYearData(exAnteData, _simulationYears, 2)
  }, [JSON.stringify(exAnteData)])

  const threeYearData = React.useMemo(() => {
    return getExAnteSpecificYearData(exAnteData, _simulationYears, 3)
  }, [JSON.stringify(exAnteData)])

  const fourYearData = React.useMemo(() => {
    return getExAnteSpecificYearData(exAnteData, _simulationYears, 4)
  }, [JSON.stringify(exAnteData)])

  const fiverYearData = React.useMemo(() => {
    return getExAnteSpecificYearData(exAnteData, _simulationYears, 5)
  }, [JSON.stringify(exAnteData)])

  const fiveYearValue = React.useMemo(() => {
    return aggregateExAnteYearlyCostValue(exAnteData, _simulationYears)
  }, [JSON.stringify(exAnteData)])

  const fiveYearSimulationExAnte = React.useMemo(() => {
    return getExAnteSimulationYearData(exAnteData, _simulationYears || 5)
  }, [JSON.stringify(exAnteData)])


  const getPercentage = (value, total_amount) => {
    return value / total_amount
  }

  const fiveYearPercentage = React.useMemo(() => {
    return getPercentage(fiveYearValue, parseFloat(get(fiveYearSimulationExAnte, 'invested_amount', 0)))
  }, [fiveYearValue, fiveYearSimulationExAnte])

  const averageCostPercentage = React.useMemo(() => {
    return getPercentage(fiveYearValue / _simulationYears, get(exAnteData, `${_simulationYears}.ongoing.cost_base_value`, 0))
  }, [fiveYearValue, fiveYearSimulationExAnte])

  const provisionData = React.useMemo(() => {
    const entry_cost = getBreakDown(get(fiveYearSimulationExAnte, 'initial.service', {}), 'entry_cost');
    return getBreakDown(entry_cost, 'provision');
  }, [fiveYearSimulationExAnte]);

  const followUpProvisionData = React.useMemo(() => {
    const ongoing_cost = getBreakDown(get(fiveYearSimulationExAnte, 'ongoing.product', {}), 'instrument_ongoing_product_fee');
    return getBreakDown(ongoing_cost, 'followup_provision');
  }, [fiveYearSimulationExAnte]);

  const isDisplayProvisionKickbackData = React.useMemo(() => {
    const ongoing_product_fee = getBreakDown(get(fiveYearSimulationExAnte, 'ongoing.product', {}), 'instrument_ongoing_product_fee');
    return getBreakDown(ongoing_product_fee, 'followup_provision_kickback');
  }, [fiveYearSimulationExAnte]);

  const followUpProvisionKickbackData = React.useMemo(() => {
    const ongoing_cost = getBreakDown(get(fiveYearSimulationExAnte, 'ongoing.product', {}), 'instrument_ongoing_product_fee');
    return getBreakDown(ongoing_cost, 'followup_provision_kickback');
  }, [fiveYearSimulationExAnte]);

  const ongoingServiceData = React.useMemo(() => {

    if(!isPrivateInvestment) {
      const service_fee = getBreakDown(get(fiveYearSimulationExAnte, 'ongoing.service', {}), 'Servicegebühr')
      const brokerServiceFee = getBreakDown(service_fee, 'Vermittler');
      return {
        ...brokerServiceFee,
        title: 'davon an den Vermittler ausgekehrtes laufendes Serviceentgelt',
      }
    }

    const investment_strategy = getBreakDown(get(fiveYearSimulationExAnte, 'ongoing.service', {}), 'investment_strategy')

    return {
      ...investment_strategy,
      title: 'Laufende Gesamtvergütung p.a.',
      breakdown: [{
        ...getBreakDown(investment_strategy, 'investment_strategy_bfv'),
        title: 'hiervon entfallen auf den Vermögensverwalter'
      }, {
        ...getBreakDown(investment_strategy, 'investment_strategy_asset_manager'),
        title: 'hiervon entfallen auf den Anlageberater'
      }, {
        ...getBreakDown(investment_strategy, 'investment_strategy_broker'),
        title: 'hiervon entfallen auf den Vertrieb'
      }]
    }

  }, [isPrivateInvestment, fiveYearSimulationExAnte])

  const explanations = getExAnteExplanations();

  const dieKostenReduzierenDieRenditeDesInvestmentsWieFolgtTooltip = (
    <>
      In dem Berechnungsmodell wird sich an einigen Depotbanken orientiert, indem im gesamten Zeitraum von einer 0%
      Rendite ausgegangen wird, da über den Verlauf keine sicheren Annahmen getroffen werden können. Daher ist der Bestand
      in der Berechnung kontinuierlich sinkend (nur bei Einmalanlage).
      <br/>
      <br/>
      Beim Sparplan steigt der Durchschnittsbestand aufgrund der regelmäßigen Einzahlungen. Hier wird auch von einer 0%
      Rendite ausgegangen.
    </>
  );

  const isBuy = transactionType === TRANSACTION_TYPE_VALUES.BUY;

  const infoIcon = <i className={"fa fa-info-circle"} />

  return (
    <>
      { exAnteData && (
        <Accordion classes={{root: accordionClasses.accordionRoot}} defaultExpanded={expanded}>
          <AccordionSummary
            classes={{root: accordionClasses.accordionHeader}}
            expandIcon={<ExpandMoreIcon />}
          >
            <div>
              {TRANSACTION_TYPE[transactionType]}
              {(fiveYearValue || parseFloat(fiveYearValue) === 0) && (
                <>&nbsp;Ex-Ante Kosten: {toGermanFormat(fiveYearValue)} €</>
              ) }
            </div>
            <div style={{marginLeft: 'auto'}}>
              <span className={accordionClasses.totalValue}>
                {isBuy ? 'Anlagebetrag' : 'Veräußerungsbetrag'} : { toGermanFormat(totalValue) } €
                {isBuy && " (Einmalanlage)"}
                {!!oneYearSavingsPlan && (
                  <>
                    <br/>
                    <span style={{float: 'right'}}>
                      { toGermanFormat(oneYearSavingsPlan) } € ({oneYearSavingsPlanTitle} jährlich)
                    </span>
                  </>
                )}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails classes={{root: accordionClasses.accordionDetails}}>
            <Grid item xs={12} style={{padding: '0 16px', marginBottom: 20, marginTop: 10}}>
              <Grid container>
                <Grid item xs={1} />
                <Grid item xs={5} />
                <Grid item xs={2} style={{textAlign: 'right'}}> <span className={classes.helpText}>In %</span></Grid>
                <Grid item xs={3} style={{textAlign: 'right'}}> <span className={classes.helpText}>In €</span></Grid>
                <Grid item xs={1} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ExAnteSection
                index="I."
                title={
                  <span>
                    Erwerbskosten / Einstiegsentgelt (einmalig)&nbsp;
                    <Tooltip arrow placement={"top"} classes={{tooltip: classes.tooltip}} title={explanations.ERWERBSKOSTEN}>
                      {infoIcon}
                    </Tooltip>
                  </span>
                }
                euroValue={get(fiveYearSimulationExAnte, 'initial.cost_value', 0)}
                percentValue={get(fiveYearSimulationExAnte, 'initial.cost_percentage', 0)}
              >
                <ExAnteLine
                  title={
                    <span>
                      Summe Produktkosten&nbsp;
                      <Tooltip arrow placement={"top"} classes={{tooltip: classes.tooltip}} title={explanations.ERWERBSKOSTEN_PRODUKTKOSTEN}>
                        {infoIcon}
                      </Tooltip>
                    </span>
                  }
                  euroValue={get(fiveYearSimulationExAnte, 'initial.product.cost_value', 0)}
                  percentValue={get(fiveYearSimulationExAnte, 'initial.product.cost_percentage', 0)} />
                <ExAnteLine
                  title={
                    <span>
                      Summe Dienstleistungskosten&nbsp;
                      <Tooltip arrow placement={"top"} classes={{tooltip: classes.tooltip}} title={explanations.ERWERBSKOSTEN_DIENSTLEISTUNGSKOSTEN}>
                        {infoIcon}
                      </Tooltip>
                    </span>
                  }
                  numbersTooltip={"Die jeweiligen Ausgabeaufschläge (s. Factsheet) vom Anlagebetrag, Produktkosten und Transaktionsgebühren für Kauf/Verkauf/Sparplan werden hier zusammengerechnet. Hinweis: Ausgabeaufschlag kann rabattiert werden."}
                  euroValue={get(fiveYearSimulationExAnte, 'initial.service.cost_value', 0)}
                  percentValue={get(fiveYearSimulationExAnte, 'initial.service.cost_percentage', 0)} />
                <ExAnteLine
                  title="davon an den Vermittler ausgekehrt"
                  subTitle={true}
                  numbersTooltip={"Hier wird die Provisionsauskehr für Sie ermittelt. Die Provision wird nach der Nettomethode berechnet  z.B. (100-(100*100/(100+ Ausgabeaufschlag)))."}
                  euroValue={get(provisionData, 'cost_value', 0)}
                  percentValue={get(provisionData, 'cost_percentage', 0)} />
              </ExAnteSection>
              <ExAnteSection
                index="II."
                title={
                  <span>
                    Laufende Kosten (p.a.)&nbsp;
                    <Tooltip
                      classes={{tooltip: classes.tooltip}}
                      arrow placement={"top"}
                      title={explanations.LAUFENDE_KOSTEN}>
                      {infoIcon}
                    </Tooltip>
                  </span>
                }
                euroValue={get(exAnteData, `${_simulationYears}.ongoing.cost_value`, 0)}
                percentValue={get(exAnteData, `${_simulationYears}.ongoing.cost_percentage`, 0)}
                >
                <ExAnteLine
                  title={
                    <span>
                      Summe Produktkosten&nbsp;
                      <Tooltip arrow placement={"top"} classes={{tooltip: classes.tooltip}}
                               title={explanations.LAUFENDE_KOSTEN_PRODUKTKOSTEN}>
                      {infoIcon}
                    </Tooltip>
                  </span>
                  }
                  numbersTooltip={"Hier werden Verwaltungsgebühren und laufende Vertriebsprovisionen zusammengefasst."}
                  euroValue={get(fiveYearSimulationExAnte, 'ongoing.product.cost_value', 0)}
                  percentValue={get(fiveYearSimulationExAnte, 'ongoing.product.cost_percentage', 0)}/>
                <ExAnteLine
                  title={<span>davon laufende Provision&nbsp;
                    <Tooltip title={explanations.LAUFENDE_KOSTEN_PRODUKTKOSTEN_DAVON_LAUFENDE_PROVISION}
                             classes={{tooltip: classes.tooltip}}
                             placement={"top"} arrow>
                      {infoIcon}
                    </Tooltip>
                  </span>}
                  subTitle={true}
                  euroValue={get(followUpProvisionData, 'cost_value', 0)}
                  percentValue={get(followUpProvisionData, 'cost_percentage', 0)}/>
                {!!isDisplayProvisionKickbackData ? (
                  <ExAnteLine
                    title="davon an den Anleger zurückerstattet"
                    subTitle={true}
                    euroValue={get(followUpProvisionKickbackData, 'cost_value', 0)}
                    percentValue={get(followUpProvisionKickbackData, 'cost_percentage', 0)}/>
                ) : null}
                <ExAnteLine
                  title={
                    <span>
                      Summe Dienstleistungskosten&nbsp;
                      <Tooltip arrow placement={"top"} classes={{tooltip: classes.tooltip}}
                               title={explanations.LAUFENDE_KOSTEN_DIENSTLEISTUNGSKOSTEN}>
                      {infoIcon}
                    </Tooltip>
                  </span>
                  }
                  numbersTooltip={"Hier werden Depotgebühren (bei Neudepots), Transaktionskosten und Bearbeitungsgebühren zusammengerechnet."}
                  euroValue={get(fiveYearSimulationExAnte, 'ongoing.service.cost_value', 0)}
                  percentValue={get(fiveYearSimulationExAnte, 'ongoing.service.cost_percentage', 0)}/>
                {!!ongoingServiceData && (
                  <>
                    <ExAnteLine
                      title={ongoingServiceData.title}
                      subTitle={true}
                      euroValue={get(ongoingServiceData, 'cost_value', 0)}
                      percentValue={get(ongoingServiceData, 'cost_percentage', 0)}
                    />
                    {ongoingServiceData.breakdown && ongoingServiceData.breakdown.map((item) => (
                      <ExAnteLine
                        title={item.title}
                        subTitle={true}
                        style={{marginLeft: 40}}
                        euroValue={get(item, 'cost_value', 0)}
                        percentValue={get(item, 'cost_percentage', 0)}
                      />
                    ))}
                  </>
                )}
              </ExAnteSection>
              <ExAnteSection
                index="III."
                title={(
                  <span>Veräußerungskosten (einmalig)&nbsp;
                    <Tooltip title={explanations.VERAUSSERUNGSKOSTEN} classes={{tooltip: classes.tooltip}}
                                   placement={"top"} arrow >
                     {infoIcon}
                    </Tooltip>
                  </span>
                )}
                euroValue={get(fiveYearSimulationExAnte, 'exit.cost_value', 0)}
                percentValue={get(fiveYearSimulationExAnte, 'exit.cost_percentage', 0)}
              >
                <ExAnteLine
                  title={(<span>Summe Produktkosten&nbsp;
                    <Tooltip title={explanations.VERAUSSERUNGSKOSTEN_PRODUKTKOSTEN} classes={{tooltip: classes.tooltip}}
                                   placement={"top"} arrow >
                      {infoIcon}
                    </Tooltip>
                  </span>)}
                  euroValue={get(fiveYearSimulationExAnte, 'exit.product.cost_value', 0)}
                  percentValue={get(fiveYearSimulationExAnte, 'exit.product.cost_percentage', 0)} />
                <ExAnteLine
                  title="Summe Dienstleistungskosten"
                  numbersTooltip={"Hier werden die Transaktionskosten berechnet, welche bei Produktverkäufen anfallen. Die Summe variiert von Depot-Art/ Depotbank."}
                  euroValue={get(fiveYearSimulationExAnte, 'exit.service.cost_value', 0)}
                  percentValue={get(fiveYearSimulationExAnte, 'exit.service.cost_percentage', 0)} />
                {!onlySell && (
                  <>
                    <ExAnteLine
                      title={
                      <span>GESAMTKOSTEN PROGNOSE&nbsp;
                        <Tooltip title={explanations.GESAMTKOSTEN_PROGNOSE} classes={{tooltip: classes.tooltip}}
                                       placement={"top"} arrow >
                          {infoIcon}
                        </Tooltip>
                      </span>}
                      divider
                      bold
                    />
                    <ExAnteLine
                      title="Gesamtkosten der Anlage bei einer Haltedauer von 5 Jahren"
                      numbersTooltip={"Die Summe der Gesamtprognose errechnet sich aus der Summe der unten aufgelisteten jährlichen Kosten. Dies wird im Verhältnis zur Anlagesumme x wiedergegeben."}
                      euroValue={fiveYearValue}
                      percentValue={fiveYearPercentage} />
                    <ExAnteLine
                      title={"Durchschnittliche Kosten p.a."}
                      numbersTooltip={"Die durchschnittlichen Kosten p.a. berechnen sich an dem Gesamtdurchschnitt. Allgemein: Wenn es sich um jährliche Kosten handelt, werden diese immer im Verhältnis zum Gesamtdurchschnitt errechnet."}
                      euroValue={fiveYearValue / _simulationYears}
                      percentValue={averageCostPercentage} />
                    {!!isDisplayProvisionKickbackData ? (
                      <ExAnteLine
                        title="Rückerstattung der laufenden Provisionen an den Kunden (abhängig vom Volumen)"
                        euroValue={get(followUpProvisionKickbackData, 'cost_value', 0)}
                        percentValue={get(followUpProvisionKickbackData, 'cost_percentage', 0)} />
                    ) : null}
                    <ExAnteLine
                      title={
                        <span>
                          DIE KOSTEN REDUZIEREN DIE RENDITE DES INVESTMENTS WIE FOLGT&nbsp;
                          <Tooltip title={explanations.DIE_KOSTEN_REDUZIEREN_DIE_RENDITE_DES_INVESTMENTS_WIE_FOLGT}
                                   classes={{tooltip: classes.tooltip}}
                                   placement={"top"} arrow >
                            {infoIcon}
                          </Tooltip>
                        </span>
                      }
                      divider bold/>
                    <Grid item xs={12} style={{padding: '0 16px', marginBottom: 20, marginTop: 10}}>
                      <Grid container>
                        <Grid item xs={1} />
                        <Grid item xs={2} />
                        <Grid item xs={2} style={{textAlign: 'right'}}> <span className={classes.helpText}>In %</span></Grid>
                        <Grid item xs={3} style={{textAlign: 'right'}}> <span className={classes.helpText}>In €</span></Grid>
                        <Grid item xs={3} style={{textAlign: 'right'}}> <span className={classes.helpText}>Durchschnittsbestand</span></Grid>
                        <Grid item xs={1} />
                      </Grid>
                    </Grid>
                    <ExAnteAverageLine
                      title="Im ersten Jahr"
                      numbersTooltip={dieKostenReduzierenDieRenditeDesInvestmentsWieFolgtTooltip}
                      euroValue={get(yearData, 'cost.cost_value', 0)}
                      averageValue={get(yearData, 'cost.cost_base_value', 0)}
                      percentValue={get(yearData, 'cost.cost_percentage', 0)} />
                    <ExAnteAverageLine
                      title="Im zweiten Jahr"
                      numbersTooltip={dieKostenReduzierenDieRenditeDesInvestmentsWieFolgtTooltip}
                      euroValue={get(twoYearData, 'cost.cost_value', 0)}
                      averageValue={get(twoYearData, 'cost.cost_base_value', 0)}
                      percentValue={get(twoYearData, 'cost.cost_percentage', 0)} />
                    <ExAnteAverageLine
                      title="Im dritten Jahr"
                      numbersTooltip={dieKostenReduzierenDieRenditeDesInvestmentsWieFolgtTooltip}
                      euroValue={get(threeYearData, 'cost.cost_value', 0)}
                      averageValue={get(threeYearData, 'cost.cost_base_value', 0)}
                      percentValue={get(threeYearData, 'cost.cost_percentage', 0)} />
                    <ExAnteAverageLine
                      title="Im vierten Jahr"
                      numbersTooltip={dieKostenReduzierenDieRenditeDesInvestmentsWieFolgtTooltip}
                      euroValue={get(fourYearData, 'cost.cost_value', 0)}
                      averageValue={get(fourYearData, 'cost.cost_base_value', 0)}
                      percentValue={get(fourYearData, 'cost.cost_percentage', 0)} />
                    <ExAnteAverageLine
                      title="Im fünften Jahr"
                      numbersTooltip={dieKostenReduzierenDieRenditeDesInvestmentsWieFolgtTooltip}
                      euroValue={get(fiverYearData, 'cost.cost_value', 0)}
                      averageValue={get(fiverYearData, 'cost.cost_base_value', 0)}
                      percentValue={get(fiverYearData, 'cost.cost_percentage', 0)} />
                    <ExAnteAverageLine
                      euroValue={null}
                      percentValue={null}
                      title="Gesamtdurchschnitt"
                      numbersTooltip={dieKostenReduzierenDieRenditeDesInvestmentsWieFolgtTooltip}
                      averageValue={get(exAnteData, `${_simulationYears}.ongoing.cost_base_value`, 0)}/>
                  </>
                )}

              </ExAnteSection>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  )
}

PortfolioExAnte.propTypes = {

  totalValue: PropTypes.number.isRequired,

  exAnteData: PropTypes.object.isRequired
};

export default PortfolioExAnte

