import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles'

function WeightsLine(props) {

  const {
    value,
    width
  } = props;

  const classes = useStyles()

  return (
    <div className={classes.track} style={{width: width || '100%'}}>
      <div className={classes.thumb} style={{width: `${value ? value <= 100 ? value : 100 : 0}%`}}></div>
    </div>
  )
}

WeightsLine.propTypes = {

}

export default WeightsLine

