import React from 'react';
import connect from "react-redux/lib/connect/connect";

/* Material-UI Components*/

/* BCA modules*/
import Select from '../../../components_v2/Select';

const mapStateToProps = (state) => ({
  benchmark_settings: state.get('sharedSettings').toJS().data.benchmark_settings
});

export default connect(mapStateToProps)((props) => {

  const {
    selectedBenchmarks,
    isPIBenchmarkSelected,
    benchmark,
    handleSelectedBenchmarkChanged,
    benchmark_settings
  } = props;

  const options = (() => {
    const selected = selectedBenchmarks.map(item => item.id);

    return benchmark_settings.map(item => {
      const isSameSelected = selected.includes(item.id);
      const isSameType = isPIBenchmarkSelected === item.is_private_investment;
      const isDisabled = selected.length > 0 && (isSameSelected || !isSameType);

      return {id: item.id, name: item.name, disabled: isDisabled};
    })
  })();

  const onChange = (id) => {
    handleSelectedBenchmarkChanged(benchmark_settings.find(b => b.id === id));
  };

  return (
    <Select
      name={''}
      value={benchmark}
      options={options}
      onChange={onChange}
      fullWidth
    />
  )
});
