import React from 'react'
import {Grid} from "@material-ui/core";
import useCommonStyles, { FORM_FIELD_HEIGHT } from '../common_styles';
import {Skeleton} from "@material-ui/lab";
import Select from '../../../../../../components/Inputs/Select';
import _ from 'lodash';

function SortingDropdown({value, options, onChange, loading, disabled, styles}) {
  const commonClasses = useCommonStyles();

  const selectOptions = React.useMemo(() => {

    if (_.isArray(options)) {
      return options;
    }

    return Object.entries(options || {}).map(o => ({
      ...o[1]
    }));

  }, [options]);

  const handleChange = (value) => {
    const selectedItem = _.find(selectOptions, o => o.id === value);
    onChange && onChange(selectedItem);
  }

  return (
    <Grid container xs={12}>
      <Grid item xs={0} sm={6} md={8} />
      <Grid item xs={12} sm={6} md={4}>
        {loading ? (
          <Skeleton className={commonClasses.skeleton}/>
        ) : (
          <Select
            options={selectOptions}
            value={value}
            withDefault={false}
            selectStyles={{ height: FORM_FIELD_HEIGHT, backgroundColor: 'white', ...styles }}
            onChange={handleChange}
          />
        )}
      </Grid>
    </Grid>
  )
}

SortingDropdown.defaultProps = {
  styles: {},
}

export default SortingDropdown;