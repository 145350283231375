export default () => ({
  assetsQuestionContainer: {
    minHeight: 200,
    padding: 32,
    border: '2px solid #D8DCDF',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&[data-disabled="true"]': {
      opacity: 0.5,
      pointerEvents: 'none'
    }
  },
  header: {
    fontSize: 20
  },
  name: {

  },
  total: {
    fontFamily: 'Roboto-Bold'
  },
  list: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  itemContainer: {
    borderTop: '2px solid #D8DCDF',
    paddingTop: 16,
    paddingBottom: 12,
    marginTop: 16
  },
  itemRow: {
    display: 'flex',
    alignItems: 'center',
    '&:not:(:last-of-type)':{
      marginBottom: 4
    }
  },
  itemIconCell: {
    width: 50,
  },
  itemHeader: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold'
  },
  itemValue: {

  },
  footer: {
    paddingTop: 16,
    display: 'flex',
    justifyContent: 'flex-end'
  }
})