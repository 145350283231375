import React from "react";
import { withStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import styles, {customerDashboardStyles} from "./styles";

const NoDataPlaceholder = ({ classes }) => {
  return (
    <div className={classes.container}>
      <CloseIcon fontSize="default" htmlColor="#80858C" />
      <span className={classes.text}>Kein Datei verfügbar</span>
    </div>
  );
}

export default withStyles(styles)(NoDataPlaceholder);

export const CustomerDashboardNoDataPlaceholder = withStyles(
  customerDashboardStyles)(NoDataPlaceholder);
