import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  listItemContainer: {
    marginBottom: 15,
    width: '100%'
  },
  profileCard: {
    width: '100%',
    height: '100%',
    borderRadius: 2,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)'
  },
  content: {
    padding: '0px !important',
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%'
  },
  userInfoContainer: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderLeft: '10px solid transparent',

    [theme.breakpoints.only('sm')]: {
      height: '50%',
    },
  },
  userInfoChangedContainer: {
    borderLeftColor: theme.palette.primary.main,
  },

  reportSettingsSection: {
    '& > div': {
      borderRight: '1px solid #DADEE1',
      padding: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        '&': {
          borderBottom: '1px solid #DADEE1'
        }
      },

      [theme.breakpoints.down('sm')]: {
        '&': {
          borderBottom: '1px solid #DADEE1'
        },
        '&:last-child': {
          borderBottom: 'none'
        }
      }
    },
    '& > div:last-child': {
      borderRight: 'none'
    },

    [theme.breakpoints.down('md')]: {
      borderTop: '1px solid #DADEE1'
    }
  },
  groupItemActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderTop: '1px solid #DADEE1',
  },
  groupDetails: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#313D44',
    margin: 0,
    width: '100%',

    '& > $groupName': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      marginLeft: 10,

      '&:hover': {
        cursor: 'pointer'
      }
    },

    '& > $groupHelpText': {
      fontFamily: 'Roboto-Regular',
      float: 'right',
      marginLeft: 'auto'
    }
  },
  groupName: {},
  groupHelpText: {},
  nonPadding: {
    padding: '0px !important'
  },

  reportSettingsSectionAdditional: {
    '& > div': {
      padding: theme.spacing(2),
    },
  },

  reportSettingsSectionAdditionalOne: {
    '& > div:first-child': {
      [theme.breakpoints.down('xs')]: {
        '&': {
          borderBottom: '1px solid #DADEE1'
        }
      },
    },
  },

  reportSettingsSectionAdditionalTwo: {
    '& > div:first-child': {
      [theme.breakpoints.down('md')]: {
        '&': {
          borderRight: '1px solid #DADEE1'
        }
      },
      [theme.breakpoints.down('xs')]: {
        '&': {
          borderBottom: '1px solid #DADEE1'
        }
      },
    },
  }
}));