import { GuideTourStep } from '../../components/GuideTour/utils';
import saveTourProgress from '../../utils/guide_progress_saving';
import { openMenu } from '../../components/Header/actions';

export const DEFAULT_DOCUMENTS_PER_PAGE = 10;

export const ALL_DOCUMENT_TYPE = {
  value: 'Alle',
  description: 'Alle'
};

export const OTHER_DOCUMENT_TYPE = 'Dokument';

export const DOCUMENT_TYPE_BY_EXTENSION = {
  'pdf': 'PDF-Dokument',
  'jpg': 'Bild',
  'jpeg': 'Bild',
  'jpe': 'Bild',
  'png': 'Bild',
  'svg': 'Bild',
  'gif': 'Bild',
  'zip': 'ZIP',
  'doc': 'Word-Dokument',
  'dot': 'Word-Dokument',
  'docx': 'Word-Dokument',
  'xls': 'Dokument',
  'xla': 'Dokument',
  'pptx': 'Dokument',
  'xlsx': 'Dokument',
  'csv': 'Dokument',
  'txt': 'Dokument',
};
