import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="26"
      height="30"
      viewBox="0 0 26 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_7629"
            data-name="Rechteck 7629"
            width="26"
            height="30"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_7855" data-name="Gruppe 7855" transform="translate(0 0)">
        <g
          id="Gruppe_7854"
          data-name="Gruppe 7854"
          transform="translate(0 0)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Pfad_6681"
            data-name="Pfad 6681"
            d="M6.067,26.426H10.4V30H26V8.847H22.534V5.332H19.067V1.758h3.467V0H13.867V1.758h3.467V5.332H13.867V8.847H10.4v15.82H6.067A2.607,2.607,0,0,1,3.616,22.91H5.2V7.09H3.467V4.248L1.644.491.089,1.267,1.733,4.657V7.09H0V22.91H1.821a4.356,4.356,0,0,0,4.246,3.516M15.6,7.09h5.2V8.847H15.6Zm-3.467,3.515H24.267V28.242H12.133ZM1.733,8.847H3.466v12.3H1.733ZM18.2,14.121a4.395,4.395,0,1,0,4.333,4.395A4.369,4.369,0,0,0,18.2,14.121m0,1.758a2.556,2.556,0,0,1,1.12.258l-3.466,3.515a2.652,2.652,0,0,1-.254-1.136,2.622,2.622,0,0,1,2.6-2.637m0,5.273a2.556,2.556,0,0,1-1.12-.258l3.466-3.515a2.653,2.653,0,0,1,.254,1.136,2.621,2.621,0,0,1-2.6,2.637"
            transform="translate(0 0)"
            fill="#80858c"
          />
        </g>
      </g>
    </svg>
  );
};
