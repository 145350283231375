import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  container: {
    borderRadius: 2,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',
  },
  content: {
    padding: 25,
    [theme.breakpoints.down('md')]: {
      padding: 20
    },
    [theme.breakpoints.down('sm')]: {
      padding: 15
    }
  },
  assetCategoryDetailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  addButtonWrapper: {
    height: 70,
    width: '100%',
    marginTop: 100,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      marginTop: 30,
      marginBottom: 20
    }
  },
  assetCategoryDetailsContainer: {
    minHeight: '100%'
  },
  assetCategoryDetails: {
    display: 'flex',
    alignItems: 'center'
  },
  assetCategoryDetailsTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 22,
    color: '#4D4F5C',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    }
  },
  assetCategoryDetailsTotalValue: {
    fontFamily: 'Roboto-Bold',
    fontSize: 22,
    color: '#4D4F5C',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    }
  },
  assetCategoryDetailsIcon: {
    height: 70,
    width: 70,
    marginRight: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      height: 50,
      width: 50,
    }
  },
  goBackLink: {
    position: 'relative',
    fontFamily: 'Roboto-Bold',
    fontSize: 22,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
    marginTop: 0,
    textDecoration: 'underline',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      marginBottom: 15,
    }
  },
  tableSectionHeader: {
    fontSize: 12,
    fontFamily: 'Roboto-Bold',
    letterSpacing: 0,
    color: '#4D4F5C',
    marginTop: 0
  },
});

export default makeStyles(styles);