import React from "react";
import moment from 'moment'


import {useStyles} from "../styles";
import {KeyboardDatePicker} from "@material-ui/pickers";
import clsx from "clsx";
import {WEEKENDS_IS_NOT_AVAILABLE_MSG} from "../../../../../constants";

const CalendarFormElement = props => {
  const {
    label,
    required,
    value,
    disabled,
    onChange,
    custom_classes,
    disablePast,
    disableFuture,
    minDate,
    minError,
    maxDate,
    maxError,
    error,
    fixedWidth,
    disableDatesCallback,
    disableWeekends,
    minimisedStyles
  } = props;

  const classes = useStyles();

  // In case we do not format moment before send to server moment.toJSON will convert it to UTC (like in Trading) #65622
  // to prevent that keep current timezone by override toJSON method
  const overrideDateToJson = (date) => {
    if(moment.isMoment(date)){
      date.toJSON = function() { return this.format(); };
    }
  };

  overrideDateToJson(value);

  const handleChange = (newValue) => {
    overrideDateToJson(newValue);
    onChange(newValue)
  };

  const filterWeekends = (date) => {
    // Return false if Saturday or Sunday
    date = new Date(date);
    return date.getDay() === 0 || date.getDay() === 6;
  };

  const handleDateValidity = date => {
    if (disableDatesCallback) {
      return disableDatesCallback(date)
    }

    return disableWeekends ? filterWeekends(date) : false;
  };

  const [datePickerError, setDatePickerError] = React.useState(undefined);
  const dateValidityError = React.useMemo(() => {
    return value && handleDateValidity(value) ? (
      disableDatesCallback ? "Das Datum nicht verfügbar" : WEEKENDS_IS_NOT_AVAILABLE_MSG
    ) : undefined;
  }, [value]);

  const handleError = (error) => {
    setDatePickerError(error);
  };

  const helperText = error || datePickerError || dateValidityError;

  return (
    <KeyboardDatePicker
      views={["year", "month", "date"]}
      format="DD.MM.YYYY"
      placeholder="TT.MM.JJJJ"
      label={label}
      value={value}
      disabled={disabled}
      required={required}
      onChange={handleChange}
      disablePast={Boolean(disablePast) || false}
      disableFuture={Boolean(disableFuture) || false}
      shouldDisableDate={handleDateValidity}
      minDate={minDate}
      maxDate={maxDate}
      invalidDateMessage={'Falsches Datumsformat.'}
      autoOk={true}
      okLabel={<span style={{textTransform: 'none'}}>Anwenden</span>}
      cancelLabel={<span style={{color: '#B1B1B1', textTransform: 'none'}}>Abbrechen</span>}
      onError={handleError}
      minDateMessage={minError}
      maxDateMessage={maxError}
      error={!!helperText}
      helperText={helperText}
      style={props.style || {}}
      classes={{
        root: clsx(
          classes.textFieldRoot,
          custom_classes && custom_classes.textFieldRoot,
          fixedWidth && classes.fixedMaxWidthDateField,
          minimisedStyles && classes.minimised
        ),
      }}
      InputProps={{
        classes: {
          root: clsx(classes.inputRoot, custom_classes && custom_classes.inputRoot),
          focused: classes.inputFocused,
        }
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: clsx(classes.labelRoot, custom_classes && custom_classes.labelRoot),
          focused: classes.labelFocused,
          asterisk: classes.labelAsterisk
        }
      }}
    />
  )
};

CalendarFormElement.defaultProps = {
  disableWeekends: false,
};

export default CalendarFormElement;
