import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import useStyles from './styles'

function TabsContainer(props) {

  const {
    tabs,
    onTabChange,
    active,
    titles
  } = props;

  const classes = useStyles()

  const handleTabChange = (tab) => () => {
    onTabChange && onTabChange(tab)
  }

  return (
    <div className={classes.container}>
      <div className={classes.tabsContainer}>
        {tabs.map((tab, index) => (
          <div className={clsx(classes.tab, active.id == tab.id && classes.activeTab)} key={tab.id} onClick={handleTabChange(tab)}>
            <span>{ titles[index] }</span>
          </div>
        ))}
      </div>
      <div style={{ boxShadow: '0px -6px 10px -8px #0000001A'}}>
        {props.children}
      </div>
    </div>
  )
}

TabsContainer.propTypes = {
  /** List of tabs */
  tabs: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.number, title: PropTypes.string})),

  /** Tab change event handler */
  onTabChange: PropTypes.func,

  /** Active tab */
  active: PropTypes.shape({id: PropTypes.number, title: PropTypes.string})
}

export default TabsContainer

