import React, { forwardRef } from  'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid'

import {useFiltersContext} from "../../Filters";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import FormControl from "@material-ui/core/FormControl";
import useStyles from "./styles";
import useCommonStyles from '../common_styles'
import {Skeleton} from "@material-ui/lab";


const InputField = forwardRef(({name, label, placeholder, loading, GridProps, disabled, inputProps, onKeyDown }, ref) => {

  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const { filters, setFilterValue } = useFiltersContext()

  const handleInputChanged = (searchWord) => {
    setFilterValue(name, searchWord || '')
  }

  const onKeyDownHandler = (event) => {
    if (event.key === 'Enter' && onKeyDown) {
      onKeyDown(filters);
    }
  }

  const inputProperties = {
    ...inputProps,
    ...{
      classes: {
        root: classes.inputRoot,
        focused: classes.inputFocused,
      }
    }
  };

  return (
    <Grid item {...GridProps}>
      <FormControl variant="outlined" fullWidth className={commonClasses.formControl}>
        <p className={clsx(commonClasses.inputLabel, disabled && commonClasses.inputLabelDisabled)}>{ label }</p>
        { loading ? (
          <Skeleton className={commonClasses.skeleton}/>
        ) : (
          <TextField
            disabled={disabled}
            value={filters[name] || ''}
            onChange={(e) => handleInputChanged(e.target.value)}
            onKeyDown={onKeyDownHandler}
            placeholder={placeholder || ''}
            classes={{
              root: clsx(classes.textFieldRoot)
            }}
            InputProps={inputProperties}
          />
        )}
      </FormControl>
    </Grid>
  )
})

InputField.propTypes = {
  /** Name of the field */
  name: PropTypes.string.isRequired,
  /** Field placeholder */
  placeholder: PropTypes.string,
  /** Grid options */
  GridProps: PropTypes.any
};

export default InputField;