import React from "react";

import {
  Card,
  CardContent,
  Container,
  withStyles,
  withWidth
} from "@material-ui/core";

import styles from "./styles";

import withDocumentsData from "./components/DocumentsDataProvider";
import DocumentsTable from "./components/DocumentsTable/DocumentsTable";
import PaginationPanel from "./components/PaginationPanel/PaginationPanel";
import FilterPanel from "./components/FilterPanel/FilterPanel";
import { GuideTour } from '../../components/GuideTour';
import {getGuideSteps} from "./guide";
import DocumentsList from "./components/DocumentsList";

const Documents = ({classes, ...props}) => {

  const isList = props.width.includes('xs');

  const renderTable = () => {
    return (
      <div className={classes.tableContainer}>
        <DocumentsTable documents={props.documents} />
      </div>
    )
  }

  const renderList = () => {
    return (
      <div className={classes.listContainer}>
        <DocumentsList documents={props.documents} />
      </div>
    )
  }

  const renderData = () => {
    return isList ? renderList() : renderTable();
  }

  return (
    <Container className={'app-page-container'}>
      <GuideTour
        steps={getGuideSteps(props.width)}
        title="Dokumente Info-Tour"
      />

      <Card classes={{
        root: classes.cardRoot
      }}>
        <CardContent classes={{
          root: classes.cardContentRoot
        }}>
          <FilterPanel
            page={props.page}
            perPage={props.perPage}
            total={props.total}
            documentType={props.documentType}
            documentTypes={props.documentTypes}
            onDocumentTypeChange={props.onDocumentTypeChange}
            onSearchClick={props.onSearchClick}
            onRefresh={props.onRefresh}
            loading={props.loading}
          />
          {renderData()}
          {props.pages > 1 && (
            <PaginationPanel
              page={props.page}
              pages={props.pages}
              onPageChange={props.onPageChange}
            />
          )}
        </CardContent>
      </Card>
    </Container>
  )
};

export default  withDocumentsData(withStyles(styles)(withWidth()(Documents)));
