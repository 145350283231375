const styles = (theme) => ({
  cardsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "13px",
  },
  container: {
    borderLeft: "1px solid #DCDFE6",
  },
  item: {
    paddingLeft: "15px!important",
  },
  itemInactive: {
    opacity: 0.5
  },
  itemMain: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

export default styles;
