import React from 'react';
import ReactDOMServer from 'react-dom/server'
import _ from "lodash";

export const getPieChartOptions = (data, items, colors, className) => ({
  chart: {
    type: "pie",
    backgroundColor: "rgba(0,0,0,0)",
    y: 100,
    className,
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    title: {
      text: "",
    },
  },
  plotOptions: {
    pie: {
      shadow: false,
      borderWidth: 3,
      showInLegend: false,
      size: "70%",
      innerSize: "20%",
      states: {
        hover: {
          enabled: false,
          halo: {
            // disable slice halo
            size: 0
          }
        }
      }
    },
  },
  tooltip: {
    enabled: true,
    animation: false,
    hideDelay: 0,
    padding: 0,
    useHTML: true,
    formatter: function () {
      if (this.point.showTooltip && !this.point.value) {
        return '<div class="highcharts-tooltip-custom">Das Instrument erfüllt dieses Kriterium nicht.</div>'
      }
      return false;
    },
    states: {
      hover: {
        enabled: false
      }
    }
  },
  series: [
    {
      type: "pie",
      name: "Browser share",

      dataLabels: {
        softConnector: false,
        connectorWidth: 0,
        verticalAlign: "top",
        distance: 40,
        useHTML: true,
        style: {
          fontFamily: "Roboto-Bold",
          textAlign: "left",
          overflow: 'visible'
        },
        formatter: function() {
          if (this.percentage != 0) {
            return `<span style="color: ${this.point.value ? '#4C4D4D' : '#E6E6E6'}">${ReactDOMServer.renderToString(this.point.name)}</span>`
          };
        },
      },
      data: Object.keys(items).map((key) => {
        items[key].color = 'white';
        items[key].value = data[key];
        return items[key]
      }),
    },
    {
      type: "pie",
      name: "Browser share",

      dataLabels: {
        connectorWidth: 0,
        color: "white",
        distance: -30,
        useHTML: true,
        formatter: function() {
          if (this.point.icon) {
            return ReactDOMServer.renderToString(this.point.icon); //icon
          }
        },
      },
      data: Object.keys(items).map((key) => ({
        ...items[key],
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: data[key] ? colors.active : colors.inactive,
        },
        value: data[key],
        showTooltip: true
      })),
    },
  ],
});