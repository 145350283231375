import React, { useState } from 'react';

import clsx from 'clsx';

/* Material-UI Components */
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core/styles";
import {setInStorage, removeFromStorage, getFromStorage} from "../../../../utils/storage";


/* BCA modules */
import styles from './styles';
import {
  AGGREGATED_PORTFOLIO_NAME,
  formatPortfolioName
} from "../../../../utils/aggregated-portfolio";
import {getPortfolioMinStartOfInvestment} from "../../../../utils/utils";
import {renderPortfolioDropdownName} from "../../../../utils/utils";
import {Dialog, DialogContent, makeStyles, Menu, MenuItem} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {useDashboardPortfoliosSelectorContext} from "../../utils";
import {filterOutPortfoliosToExclude} from "../../../DashboardSettings/components/DashboardSettingsWindow";
import Skeleton from "@material-ui/lab/Skeleton";


export const ListDropdown = withStyles(styles, {withTheme: true})((props) => {

  const {
    disabled,
    classes,
    theme,
    formatSelected,
    alwaysScrollableList,
    renderListItem,
    ButtonProps={},
    id,
    asDialog,
    dialogTitle
  } = props;

  const [expanded, setExpanded] = useState(props.expanded || false);

  React.useEffect(() => {
    props.onExpanded && props.onExpanded(expanded);
  }, [expanded]);

  let node = React.useRef();

  React.useEffect(() => {
    window.addEventListener('mousedown', handleClick, false);

    return () => {
      window.removeEventListener('mousedown', handleClick, false);
    }
  }, []);

  const handleClick = (event) => {

    if (asDialog) {
      return
    }

    // Problem: Assets menu rendered in portal, so it is out of main dropdown.
    // That is why click away listener always triggered when we manipulate with assets menu.
    // Solution: To fix this we check if event.target is under assets menu or it is assets
    // menu itself (check if it has parent with class name assets-menu)
    if (event.target.closest('.assets-menu')) return;

    if (!node.current || !node.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  const onButtonClick = () => {
    ButtonProps.onClick && ButtonProps.onClick();
    setExpanded(false);
  };

  return (
    <>
      <ExpansionPanel
        ref={node}
        id={id}
        TransitionProps={{timeout: 0}}
        expanded={!asDialog && expanded}
        onChange={() => setExpanded(!expanded)}
        classes={{
          root: classes.expansionPanelRoot,
          expanded: classes.expansionPanelExpanded
        }}
        disabled={disabled}
      >
        <ExpansionPanelSummary
          expandIcon={<KeyboardArrowDownIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          IconButtonProps={{
            disableRipple: true
          }}
          classes={{
            root: classes.expansionPanelSummaryRoot,
            expanded: classes.expansionPanelSummaryExpanded,
            content: classes.expansionPanelSummaryContent
          }}
        >
          <div className={classes.ellipsis}>
            {formatSelected()}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.scrollableList} classes={{
          root: classes.expansionPanelDetailsRoot,
        }}>
          {props.listControlsRender && props.listControlsRender()}
          <List style={{maxHeight: props.maxHeight || 'auto'}} className={clsx(classes.portfolioList, alwaysScrollableList && classes.alwaysScrollableList)}>
            {renderListItem()}
          </List>
          {ButtonProps.enabled &&
            <Button
              variant="contained"
              color="primary"
              className={classes.startButton}
              onClick={onButtonClick}
              disabled={ButtonProps.disabled}
            >
              {ButtonProps.title || 'Start'}
            </Button>
          }
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {asDialog && (
        <Dialog
          open={expanded}
          onClose={() => setExpanded(false)}
          maxWidth={"xs"}
          fullWidth={true}
          classes={{
            paperFullWidth: classes.dialogFullWidth
          }}
        >
          <DialogTitle className={classes.dialogTitle}>
            <span>{dialogTitle}</span>
            <IconButton disableRipple aria-label="close" className={classes.closeButton} onClick={() => setExpanded(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {props.listControlsRender && props.listControlsRender()}
            <List style={{maxHeight: props.maxHeight || 'auto'}} className={clsx(classes.portfolioList, classes.portfolioListDialog, alwaysScrollableList && classes.alwaysScrollableList)}>
              {renderListItem()}
            </List>
          </DialogContent>
          <DialogActions>
            {ButtonProps.enabled &&
              <Button
                variant="contained"
                color="primary"
                className={classes.startButton}
                onClick={onButtonClick}
                disabled={ButtonProps.disabled}
              >
                {ButtonProps.title || 'Start'}
              </Button>
            }
          </DialogActions>
        </Dialog>
      )}
    </>
  )
});

const componentsArrayToDict = (components) => (components || []).reduce((result, component) => {
  result[component.id] = component;
  return result;
}, {});

/**
 * Init portfolio with selected assets in case it does not have it.
 * @param portfolios
 */
const handlePortfoliosAssets = (portfolios) => {
  if (!portfolios) return portfolios;

  return (portfolios || []).map((portfolio) => {
    if (!portfolio.hasOwnProperty('selectedComponents')) {
      portfolio.selectedComponents = componentsArrayToDict(portfolio.components || []);
    }
    return portfolio;
  });
};

const PortfolioComponentsMenu = withStyles(styles, {withTheme: true})((props) => {
  const {
    portfolio,
    selectedComponents,
    anchorEl,
    open,
    onClose,
    classes,
    onSelectedComponentsChanged
  } = props;

  const components = portfolio.components || [];
  const withAggregated = components.length > 1;
  const aggregatedSelected = Object.keys(selectedComponents).length == portfolio.components.length;

  const handleSelectComponent = (component=undefined) => {

    if (!onSelectedComponentsChanged) return;

    let _selected = {...selectedComponents}

    if (!component) {
      _selected = aggregatedSelected ? {} : componentsArrayToDict(portfolio.components || []);
    } else {
      if (component.id in _selected) {
        delete _selected[component.id];
      } else {
        _selected[component.id] = component;
      }
    }

    onSelectedComponentsChanged(_selected);
  }

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={onClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClick={event => event.stopPropagation()}
      classes={{
        paper: classes.menuPaper
      }}
      PopoverClasses={{
        paper: 'assets-menu',
        root: 'assets-menu',
      }}
    >
      {withAggregated && (
        <MenuItem className={clsx(classes.portfolioListItem, aggregatedSelected && classes.portfolioListItemSelected)} onClick={() => handleSelectComponent()}>
          <Checkbox
            checked={aggregatedSelected}
            value="checkedB"
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
            classes={{
              checked: classes.portfolioListCheckboxChecked,
              root: classes.portfolioListCheckbox
            }}
            disableRipple
            disableFocusRipple
            className={classes.portfolioListCheckbox}
          />
          <span>{AGGREGATED_PORTFOLIO_NAME}</span>
        </MenuItem>
      )}
      {components.map((component) => (
        <MenuItem
          className={clsx(classes.portfolioListItem, component.id in selectedComponents && classes.portfolioListItemSelected)}
          key={component.id}
          onClick={() => handleSelectComponent(component)}
        >
          <Checkbox
            checked={component.id in selectedComponents}
            value="checkedB"
            inputProps={{
              'aria-label': 'secondary checkbox',
            }}
            classes={{
              checked: classes.portfolioListCheckboxChecked,
              root: classes.portfolioListCheckbox
            }}
            disableRipple
            disableFocusRipple
            className={classes.portfolioListCheckbox}
          />
          <span>{component.name}</span>
        </MenuItem>
      ))}
    </Menu>
  )
});

const PortfolioMenuItem = withStyles(styles, {withTheme: true})((props) => {

  const {
    portfolio,
    selectedComponents,
    selected,
    onSelect,
    classes,
    onSelectedComponentsChanged
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // TODO: For now assets selection disabled
  // const withAssets = !!portfolio.depotNumber && !_.isEmpty(portfolio.components);
  const withAssets = false;
  const aggregatedSelected = !withAssets || Object.keys(selectedComponents).length == portfolio.components.length;

  const handleSelectedComponentsChanged = (selectedComponents) => {
    onSelectedComponentsChanged && onSelectedComponentsChanged(portfolio, selectedComponents);
  };

  return (
    <ListItem
      className={clsx(classes.portfolioListItem, selected && classes.portfolioListItemSelected, withAssets && classes.portfolioListItemWithAssets, open && classes.portfolioListItemFocused)}
      onClick={() => onSelect(portfolio)}
    >
      <Checkbox
        checked={selected}
        indeterminate={selected && !aggregatedSelected}
        value="checkedB"
        inputProps={{
          'aria-label': 'secondary checkbox',
        }}
        classes={{
          checked: classes.portfolioListCheckboxChecked,
          root: classes.portfolioListCheckbox
        }}
        disableRipple
        disableFocusRipple
        className={classes.portfolioListCheckbox}
      />
      <span>
        {formatPortfolioName(portfolio.name)}
        {selected && !aggregatedSelected && (
          <span className={classes.selectedAssetsWarning}><i className="far fa-exclamation-circle"></i>Modifiziert ({Object.keys(selectedComponents).length}/{portfolio.components.length})</span>
        )}
      </span>
      {withAssets && (
        <>
          <IconButton onClick={handleClick} className={classes.assetsArrowButton}>
            <KeyboardArrowRightIcon />
          </IconButton>
          <PortfolioComponentsMenu
            portfolio={portfolio}
            selectedComponents={selectedComponents}
            anchorEl={anchorEl}
            onClose={handleClose}
            open={open}
            onSelectedComponentsChanged={handleSelectedComponentsChanged}
          />
        </>
      )}
    </ListItem>
  )
});

const PortfolioListDropDown = (props) => {
  const {
    portfolios,
    portfoliosLoadingError,
    portfoliosDataLoading,
    selectedPortfolios,
    handleSelectedPortfolioChanged,
    classes,
    alwaysScrollableList,
    isVirtual,
    loading,
    isListAutoUpdate,
    onExpanded,
    placeholder,
    includeHistoricalPortfolios
  } = props;

  const shared = useDashboardPortfoliosSelectorContext();

  const [selectedPortfoliosInternal, setSelectedPortfoliosInternal] = React.useState([]);
  const [selectedComponentsInternal, setSelectedComponentsInternal] = React.useState({});

  const _portfolios = React.useMemo(() => {
    return filterOutPortfoliosToExclude(portfolios, undefined, includeHistoricalPortfolios)
  }, [includeHistoricalPortfolios, JSON.stringify(portfolios)]);

  // TODO: Validate if we need this session storage functionality
  let sessionCustomerId = getFromStorage('customer_id');

  let selectedPortfoliosPrefix = 'selected_portfolios_';
  let selectedTrackingDatePrefix = 'selected_tracking_date_';

  if (isVirtual) {
    selectedPortfoliosPrefix = 'selected_virtual_portfolios_'
  }

  let sessionSelectedPortfoliosKey = selectedPortfoliosPrefix.concat(sessionCustomerId);
  let sessionSelectedTrackingDateKey = selectedTrackingDatePrefix.concat(sessionCustomerId);

  React.useEffect(() => {
    const _selectedPortfolios = !!selectedPortfolios ? selectedPortfolios : [];
    const _selectedComponents = _selectedPortfolios.reduce((result, portfolio) => {
      result[portfolio.depotNumber] = componentsArrayToDict(portfolio.components || []);
      return result;
    }, {});
    setSelectedPortfoliosInternal(_selectedPortfolios);
    setSelectedComponentsInternal(_selectedComponents);
    shared.onSelectedPortfoliosChanged && shared.onSelectedPortfoliosChanged(_selectedPortfolios);
    shared.onSelectedComponentsChanged && shared.onSelectedComponentsChanged(_selectedComponents);
  }, [selectedPortfolios]);

  React.useEffect(() => {
    const _selectedPortfolios = !!shared.selectedPortfolios ? shared.selectedPortfolios : [];
    const _selectedComponents = !!shared.selectedComponents
      ? shared.selectedComponents
      : _selectedPortfolios.reduce((result, portfolio) => {
        result[portfolio.depotNumber] = componentsArrayToDict(portfolio.components || []);
        return result;
      }, {})
    setSelectedPortfoliosInternal(_selectedPortfolios);
    setSelectedComponentsInternal(_selectedComponents);
  }, [shared.selectedPortfolios, shared.selectedComponents]);

  const renderPlaceholder = () => {
    let array = [];

    for (let i = 0; i < 5; i++) {
      array.push((
        <ListItem key={i} className={classes.portfolioListItemPlaceholder}>
          <div className={classes.itemPlaceholder}>
          </div>
        </ListItem>))
    }
    return array;
  };

  const handlePortfolioSelected = (clickedPortfolio, syncSelectedComponents=false) => {
    const isAggregatedClicked = !clickedPortfolio.depotNumber;
    const isSelected = selectedPortfoliosInternal
      .findIndex(portfolio => portfolio.id === clickedPortfolio.id) === -1;

    if (isAggregatedClicked) {
      return handlePortfolioChanged(isSelected ? [..._portfolios] : [], syncSelectedComponents);
    }

    if (isSelected) {
      // portfolios.length - 2 = portfolios - aggregated portfolio - currently selected portfolio that not in a selectedPortfoliosInternal yet.
      // validate if last not aggregated portfolio selected so we could select all portfolios (together with aggregated)
      if (selectedPortfoliosInternal.length === _portfolios.length - 2) {
        return handlePortfolioChanged([..._portfolios], syncSelectedComponents);
      } else {
        return handlePortfolioChanged([clickedPortfolio, ...selectedPortfoliosInternal], syncSelectedComponents);
      }
    } else {
      return handlePortfolioChanged(selectedPortfoliosInternal.filter(portfolio => {
        return portfolio.id !== clickedPortfolio.id && portfolio.depotNumber;
      }), syncSelectedComponents);
    }
  };

  const handlePortfolioChanged = (portfolios, syncSelectedComponents) => {
    if(isListAutoUpdate && handleSelectedPortfolioChanged)
    {
      handleSelectedPortfolioChanged(portfolios)
    }
    setSelectedPortfoliosInternal(portfolios);
    if (syncSelectedComponents) {
      const aggregatedSelected = !!portfolios.find((portfolio) => !portfolio.depotNumber);
      setSelectedComponentsInternal((current) => {

        [...Object.keys(current), ...portfolios.map((p) => p.depotNumber)].forEach((depotNumber) => {
          const portfolioData = portfolios
            .find((portfolio) => portfolio.depotNumber == depotNumber);

          if (aggregatedSelected || (!!portfolioData && !Object.keys(current[depotNumber]).length)) {
            current[depotNumber] = componentsArrayToDict(portfolioData.components || []);
          } else if (!portfolioData) {
            current[depotNumber] = {};
          }
        });

        shared.onSelectedComponentsChanged && shared.onSelectedComponentsChanged({...current});

        return {...current}
      })
    }

    shared.onSelectedPortfoliosChanged && shared.onSelectedPortfoliosChanged(portfolios);
  };

  const handleStartClick = () => {
    if (handleSelectedPortfolioChanged) {
      handleSelectedPortfolioChanged(selectedPortfoliosInternal);
      if (!selectedPortfoliosInternal.map(portfolio => portfolio.depotNumber).includes(0)) {
        let minTrackingDate = getPortfolioMinStartOfInvestment(selectedPortfoliosInternal);
        setInStorage(sessionSelectedPortfoliosKey, selectedPortfoliosInternal);
        setInStorage(sessionSelectedTrackingDateKey, minTrackingDate.format('YYYY-MM-DD'))

      } else {
        removeFromStorage(sessionSelectedPortfoliosKey);
        removeFromStorage(sessionSelectedTrackingDateKey);
      }
    }
  };

  const handleSelectedComponentsChanged = (portfolio, selectedComponents) => {
    const isPortfolioSelected = !!selectedPortfoliosInternal
      .find((sPortfolio) => sPortfolio.depotNumber == portfolio.depotNumber);

    // Should never happen, but ...:)
    if (!isPortfolioSelected && !Object.keys(selectedComponents).length) {
      return;
    }

    if (!isPortfolioSelected || !Object.keys(selectedComponents).length) {
      handlePortfolioSelected(portfolio, false)
    }

    setSelectedComponentsInternal((current) => {
      current[portfolio.depotNumber] = selectedComponents;
      shared.onSelectedComponentsChanged && shared.onSelectedComponentsChanged({...current});
      return {...current}
    })
  };

  const renderListItem = (portfolio) => {
    const isSelected = selectedPortfoliosInternal
      .map(portfolio => portfolio.depotNumber)
      .includes(portfolio.depotNumber);

    return (
      <PortfolioMenuItem
        portfolio={portfolio}
        selectedComponents={selectedComponentsInternal[portfolio.depotNumber] || {}}
        selected={isSelected}
        onSelect={handlePortfolioSelected}
        onSelectedComponentsChanged={handleSelectedComponentsChanged}
      />
    )
  };

  const formatSelected = () => {
    if (portfoliosDataLoading) {
      return <Skeleton style={{width: '75%', height: 35}}/>
    }

    return renderPortfolioDropdownName(selectedPortfoliosInternal, _portfolios, isVirtual, placeholder);
  };

  return (
    <ListDropdown
      id="portfolio-list"
      formatSelected={formatSelected}
      renderListItem={() => {
        return portfoliosDataLoading
          ? renderPlaceholder()
          : _portfolios && !portfoliosLoadingError ? _portfolios.map(renderListItem) : <p>Error</p>
      }}
      disabled={loading || portfoliosDataLoading}
      onExpanded={onExpanded}
      alwaysScrollableList={alwaysScrollableList}
      ButtonProps={{
        enabled: !isListAutoUpdate,
        onClick: handleStartClick,
        disabled: loading || selectedPortfoliosInternal.length === 0
      }}
    />
  )
};

export default withStyles(styles, {withTheme: true})(PortfolioListDropDown)