import {Component} from "react";
import _ from "lodash";

import {MODEL_PORTFOLIO_TYPE_LABEL, MODEL_PORTFOLIO_TYPES} from "../constants";
import {BankSettingsHandlerResource} from "../../../utils/api";

//#region MOCK
export const ALL_ID = -1;

//#endregion

class BaseList extends Component {
  constructor(props) {
    super(props);

    this.TABS = [
      {
        id: ALL_ID,
        label: 'Alle',
      }, {
        id: MODEL_PORTFOLIO_TYPES.PUBLIC,
        label: 'Öffentlich (:mandant)',
      },
      {
        id: MODEL_PORTFOLIO_TYPES.AGENCY,
        label: 'Öffentlich (Meine Agentur)',
      }, {
        id: MODEL_PORTFOLIO_TYPES.PERSONAL,
        label: 'Privat (Meine)',
      }
    ];

    if(!_.isEmpty(_.get(props.auth, 'user.sub_agencies'))){
      this.TABS.splice(3, 0, {
        id: MODEL_PORTFOLIO_TYPES.SUB_AGENCY,
        label: `Öffentlich (${MODEL_PORTFOLIO_TYPE_LABEL[MODEL_PORTFOLIO_TYPES.SUB_AGENCY]})`,
      });
    }
    if(!!_.get(props.auth, 'user.agency.parent_agency_id')){
      this.TABS.splice(2, 0, {
        id: MODEL_PORTFOLIO_TYPES.PARENT_AGENCY,
        label: `Öffentlich (${MODEL_PORTFOLIO_TYPE_LABEL[MODEL_PORTFOLIO_TYPES.PARENT_AGENCY]})`,
      });
    }
  }

  fetchMandantData = async () => {

    try {

      let response = await BankSettingsHandlerResource.getMandantDetails();

      if (response) {
        this.TABS[1].label = this.TABS[1].label.replace(':mandant', response.name)
      }

      this.forceUpdate(); // force re-render

    } catch (errors) {

      this.setState({
        loading: false,
        data: undefined,
        updated: Date.now(),
        errors
      });

    }

  };

  componentDidMount() {
    this.fetchMandantData();
  }
}

export default BaseList