import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  button: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  iconButton: {
    padding: 8,
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  icon: {
    color: '#63696f'
  },
  buttonDisabled: {
    '& button span': {
      color: 'grey'
    },
    "& svg": {
      color: 'grey'
    }
  },
  text: {
    marginLeft: 5
  }
}));