export default (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
  },
  toggleSwitch: {
    position: 'relative',
    display: 'flex',
    width: 85,
    height: 40,
    borderRadius: 35,
    backgroundColor: '#b0b1b1',
  },
  active: {
    backgroundColor: '#457be9',
  },
  switch: {
    position: 'absolute',
    opacity: 0,
    width: 85,
    height: '100%',
  },
  slider: {
    position: 'relative',
    display: 'block',
    height: 30,
    width: 30,
    borderRadius: '50%',
    backgroundColor: 'white',
    transition: '.4s',
  },
  sliderNull: {
    margin: 'auto',
  },
  sliderOn: {
    margin: 'auto 5px auto auto',
    '&::before': {
      content: "'ja'",
      position: 'absolute',
      left: -35,
      top: 5,
      textTransform: 'uppercase',
      width: '100%',
      height: '100%',
      fontSize: 16,
      fontFamily: 'Roboto-Bold',
      color: 'white'
    },
  },
  sliderOff: {
    margin: 'auto auto auto 5px',
    '&::before': {
      content: "'nein'",
      position: 'absolute',
      left: 35,
      top: 5,
      textTransform: 'uppercase',
      width: '100%',
      height: '100%',
      fontSize: 16,
      fontFamily: 'Roboto-Bold',
      color: 'white'
    },
  },
  labelRoot: {
    color: '#202A38',
    transform: 'none',
    position: 'relative',
    fontSize: 16,
    marginBottom: 14,
    fontFamily: 'Roboto-Bold',
  },
  labelAsterisk: {
    color: theme.palette.primary.main
  },
  error: {
    marginTop: 14,
  }
})