import React from 'react';

import PageTabsNavigation from '../../../../components/PageTabsNavigation';

const TabsContainer = props => {
  const {
    active,
    onActiveChanged
  } = props;

  return (
    <PageTabsNavigation
      containerId='report-settings-tabs-tour-element'
      tabs={['Einzelne Kunden auswählen', 'Kundengruppe auswählen']}
      active={active}
      onActiveChanged={onActiveChanged}
    />
  );
};

export default TabsContainer;
