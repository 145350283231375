import React from "react";
import { withStyles } from "@material-ui/core";

import CommonAnalysisCard from "../../../../../VirtualPortfolioPage/components/AnalysisCard";
import { toShortGermanFormat } from "../../../../utils";
import styles from "./styles";
import { DEFAULT_EMPTY_SECTION_MESSAGE } from '../../../../../CustomerDashboard/constants';

class MonthlyPerformanceTable extends React.Component {

  constructor(props) {
    super(props);

    this.months = ['Jan.', 'Feb.', 'Mrz.', 'Apr.', 'Mai.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dez.'];
  }

  renderValue(item, monthIndex) {
    let monthData = item.months[monthIndex] || {};

    return monthData.calc === undefined || monthData.calc === null 
      ? 'k.A.'
      : toShortGermanFormat(monthData.calc, '', ' %', 2, false);
  }

  renderTotal(item) {
    let classes = [this.props.classes.values];
    let totalIsNull = item.calc === undefined || item.calc === null;

    if (!totalIsNull) {
      classes.push(item.calc >= 0 ? this.props.classes.green : this.props.classes.red)
    }

    return <span className={classes.join(' ')} title={item.calc}>
      {!totalIsNull ? toShortGermanFormat(item.calc, '', ' %', 2, false) : 'k.A.'}
    </span>
  }

  renderHeadline() {
    let lines = [];
    this.months.forEach((monthName, index) => (
      lines.push(
        <span className={this.props.classes.values} key={index}>{monthName}</span>
      )
    ))
    lines.push(<span className={this.props.classes.values} key={'total'}>Total</span>)
    return lines;
  }

  renderMonthData(year) {
    let lines = [];
    this.months.forEach((monthName, index) => (
      lines.push(
        <span className={this.props.classes.values} key={index}
        title={year.months[index + 1] ? year.months[index + 1].calc : 'k.A.'}>{this.renderValue(year, index + 1)}</span>
      )
    ))
    return lines;
  }

  renderLines() {
    const { classes } = this.props;

    let lines = [];

    this.props.data.forEach((item, index) => (
      lines.push(
        <li className={classes.item} key={index}>
          <span className={classes.title}>{item.year}</span>
          {this.renderMonthData(item.data)}
          {this.renderTotal(item.data)}
        </li>
      )
    ))
    return lines;
  }

  renderFootnote() {
    const { classes } = this.props;

    if(!this.props.endDate) return '';

    return <div className={classes.subline}>
      <p className={classes.description}>
        Daten ab: {this.props.endDate}
      </p>
    </div>
  }

  renderData() {
    const { classes } = this.props;

    return <>
      <ul className={classes.list}>
        <li className={[classes.item, classes.headline].join(' ')}>
          <span className={classes.title}></span>
          {this.renderHeadline()}
        </li>
        {this.renderLines()}
      </ul>
      {this.renderFootnote()}
    </>
  }

  renderError() {
    return <div>{'Fehler. Die Tabelle "Wertentwicklung in %" konnte nicht geladen werden.'}</div>;
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
      classes={{
        card: classes.root
      }}
      title={this.props.title}
      subtitle={this.props.subtitle}
      loading={this.props.loading}
      content={
        <div className={classes.container}>
          {this.props.error ? this.renderError() : this.props.data ? this.renderData() : DEFAULT_EMPTY_SECTION_MESSAGE}
        </div>
      }
    />
  }
}

export default withStyles(styles)(MonthlyPerformanceTable)
