import React from 'react';
import _ from 'lodash';
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";

import CategoryItem from '../CategoryItem';
import AddAssetButton from "../../../../components/AddAssetButton";

import useStyles from './styles';


const AssetsCategories = (props) => {
  const classes = useStyles();

  const {
    categories,
    setActiveCategory
  } = props;


  const handleAddAssetClicked = () => {
    if (!_.isNil(props.onAddAssetClick)) {
      props.onAddAssetClick();
    }
  };

  const renderAssetCategories = () => {
    return categories.map((category, index) => renderAssetCategoryItem(category, index, categories.length));
  };

  const renderAssetCategoryItem = (category, index, total) => {
    const size = getCategorySizeByIndex(index, total);
    return (
      <Grid
        key={category.id}
        item
        xs={12}
        md={size}
        className={clsx(classes.itemWrapper, index === 0 ? classes.firstItem : '')}
      >
        <CategoryItem
          title={category.title}
          totalAmount={category.total_value}
          icon={category.icon}
          url={category.url}
          read_only={category.read_only}
          setActiveCategory={!_.isNil(setActiveCategory) ? () => setActiveCategory(category.id) : undefined}
        />
      </Grid>
    );
  };

  const getCategorySizeByIndex = (index, total) => {
    if (index === 0) {
      return 12;
    }

    if (index === 1 && (total === 2 || total === 4)) {
      return 12
    }

    return 6;
  };

  return (
    <>
      <Grid container className={classes.container}>
        {renderAssetCategories()}
      </Grid>
      <div className={classes.itemWrapper}>
        <AddAssetButton onButtonClick={handleAddAssetClicked} />
      </div>
    </>
  )
};

export default AssetsCategories;