import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  headerContainer: {
    '& button > span > span': {
      fontSize: 14
    },

    '& div > span': {
      fontFamily: 'Roboto-Bold',
      fontSize: 16,
      color: '#202A38',

      display: 'inline-block'
    }
  },
  tableCell: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    '&:nth-of-type(1)': {
      width: 390,
    },
    '&:last-child': {
      width: 100
    }
  }
})

export default makeStyles(styles)