import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  listItemContainer: {
    marginBottom: 16,
    width: '100%'
  },
  card: {
    width: '100%',
    height: '100%',
    borderRadius: 2,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.1)',
    transform: 'translateZ(1px)'
  },
  content: {
    padding: '0px !important',
    height: '100%'
  },
  item: {
    position: 'relative',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: '1px solid #DADEE1',

    '&:nth-of-type(1)': {
      flex: 1,
      justifyContent: 'flex-start',
    },
    '&:nth-of-type(2)': {
      flexDirection: 'column',
      flex: 1,
      padding: 10,

      '& > div:first-child': {
        paddingBottom: 7
      }
    },
    '&:nth-of-type(3)': {
      width: 200,
    },
    '&:nth-of-type(4)': {
      width: 200,

      '&::after': {
        borderRightWidth: 0
      }
    },

    [theme.breakpoints.down('sm')]: {
      width: '50%!important',
      flex: 'auto!important',

      '&:nth-of-type(1)': {
        '&::after': {
          borderBottomWidth: 1
        }
      },

      '&:nth-of-type(2)': {
        '&::after': {
          borderRightWidth: 0,
          borderBottomWidth: 1
        }
      },

      '&:nth-of-type(3)': {
        justifyContent: 'flex-start'
      },
    },

    [theme.breakpoints.only('xs')]: {
      width: '100%!important',
      flex: 'auto!important',

      '&::after': {
        borderRightWidth: 0,
        borderBottomWidth: 1,
      },
    }
  },
  text: {
    whiteSpace: 'normal'
  },
  autoReportWarning: {
    paddingTop: 5,
    fontSize: '10pt',
    color: '#4D4F5C',
    textAlign: 'center'
  },

  groupItemActionsWrapper: {
    padding: theme.spacing(2),
    borderTop: '1px solid #DADEE1',
    borderLeft: '1px solid #DADEE1',
  },
  expandButtonWrapper: {
    marginTop: 20
  },
  actionItem: {
    textAlign: 'center',
    '& > span': {
      color: theme.palette.primary.main,
      fontFamily: 'Roboto-Medium',
      fontSize: 14,

      '&:hover': {
        '& > span': {
          cursor: 'pointer',
          textDecoration: 'underline'
        }
      },
      '& > i': {
        marginRight: 5
      },
      '& > div': {
        marginRight: 5
      }
    }
  },
  upperRowWrapper: {
    [theme.breakpoints.down('sm')]: {
      borderTop: '1px solid #DADEE1',
    }
  }
}));
