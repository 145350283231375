import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close';

import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import {
  WeightsLine,
} from './components'
import {
  DropdownInput,
  FilterBudges
} from '../index'
import {
  displayErrorSnackBar
} from '../../../../components/SnackbarProvider/actions'

import { ModelPortfolioResource, parseResponse } from '../../../../utils/api'


import useStyles from './styles'


const RISK_OPTIONS = [
  {
    id: 1,
    name: 'Manuell',
    selected: true
  }, {
    id: 3,
    name: 'Gleichgewichtet',
    info: 'Jedes Produkt im Portfolio erhält prozentual die selbe Gewichtung'
  }, {
    id: 2,
    name: 'Risikoparitätisch',
    info: 'Bei risikoparitätischen Ansätzen wird das Vermögen auf verschiedene, nicht miteinander korrelierende Asset-Klassen unter Risikogesichtspunkten verteilt.'
  }
]

function InstrumentsWeightModal(props) {

  const {
    open,
    instruments,
    onWeightsApprove,
    onClose,
    selectedModelPortfolio,
    upDialogTitle
  } = props;

  const classes = useStyles();

  const [instrumentsConfiguration, setInstrumentsConfiguration] = React.useState({})
  const [weightsConfigurationLoading, setWeightsConfigurationLoading] = React.useState(false)

  React.useEffect(() => {
    for (let key in instrumentsConfiguration) {
      if (!_.find(instruments, i => i.isin == key)) {
        delete instrumentsConfiguration[key]
      }
    }
  }, [instruments]);

  React.useEffect(() => {

    let instrumentsConfigurationNew = {}

    if (selectedModelPortfolio && selectedModelPortfolio.assets) {
      selectedModelPortfolio.assets.forEach(a => {
        let weight = +a.weight
        instrumentsConfigurationNew[a.isin] = {
          weight: parseFloat(weight.toFixed(2)),
          fixed: a.fixed
        }
      })
    }

    setInstrumentsConfiguration(instrumentsConfigurationNew)

  },[selectedModelPortfolio])

  const handleInstrumentWeightChange = (instrument, fieldName, value) => {

    if (value > 100 || value < 0) return

    if (instrumentsConfiguration.hasOwnProperty(instrument.isin)) {
      setInstrumentsConfiguration({
        ...instrumentsConfiguration,
        [instrument.isin]: {
          ...instrumentsConfiguration[instrument.isin],
          [fieldName]: value
        }
      })
    } else {
      setInstrumentsConfiguration({
        ...instrumentsConfiguration,
        [instrument.isin]: {
          [fieldName]: value
        }
      })
    }
  }

  const getTotalWeight = () => {
    let totalWeight = 0;

    for (let isin in instrumentsConfiguration) {
      totalWeight += parseFloat(instrumentsConfiguration[isin].weight) || 0;
    }

    if (totalWeight) {
      totalWeight = parseFloat(totalWeight.toFixed(2))
    }

    return totalWeight
  }

  const handleWeightsApprove = () => {
    let result =  instruments.map(instrument => {

      let instrumentData = {
        "instrument_id": instrument.isin,
        "weight": 0,
        "fixed": false,
        "price": _.get(instrument, 'calculated.last_price_value')
      }

      let instrumentConfiguration = instrumentsConfiguration[instrument.isin];
      if (instrumentConfiguration) {
        instrumentData['weight'] = instrumentConfiguration['weight']
        instrumentData['fixed'] = !!instrumentConfiguration['fixed']
      }

      return instrumentData
    })

    if (onWeightsApprove) {
      onWeightsApprove(result)
    }
  }

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const renderInstrument = (instrument) => {

    let weight = instrumentsConfiguration[instrument.isin] && instrumentsConfiguration[instrument.isin].weight;
    let name = instrument.name || instrument.isin;

    return <Grid item xs={12} style={{marginBottom: 5}}>
      <Grid container>
        <Grid item xs={12} sm={5}>
        <p className={classes.instrumentName}>{ instrument.marked ? <strong>{name}</strong> : name }</p>
        </Grid>
        <Grid item xs={12} sm={7} className={classes.inputContainer}>
          <WeightsLine value={weight}/>
          <div style={{margin: '0 15px'}}>
          <DropdownInput
            min={0}
            max={100}
            step={instrumentsConfiguration[instrument.isin] && instrumentsConfiguration[instrument.isin].weight % 1 !== 0 ? 0.01 : 1}
            width={95}
            label="%"
            type="number"
            inputStyle={{textAlign: "right"}}
            labelPosition="right"
            value={weight}
            onChange={(value) => handleInstrumentWeightChange(instrument, 'weight', value)}
          />
          </div>
          {/*<FormControlLabel */}
          {/*  label="Fixieren" */}
          {/*  control={*/}
          {/*    <Checkbox */}
          {/*      color="primary" */}
          {/*      checked={instrumentsConfiguration[instrument.isin] && instrumentsConfiguration[instrument.isin].fixed}*/}
          {/*      onChange={(event) => handleInstrumentWeightChange(instrument, 'fixed', event.target.checked)}/>} */}
          {/*  className={classes.fiexedLabel}*/}
          {/*/>*/}
        </Grid>
      </Grid>
    </Grid>
  }

  const getEqualWeightsConfiguration = () => {
    let instrumentsConfigurationNew = {}

    if (instruments) {

      let weightItem = 100 / instruments.length
      let weightTotal = 0


      instruments.forEach((a, index) => {

        if (index != instruments.length - 1) {
          weightTotal += Math.floor(weightItem)
        }

        let oldConfiguration = instrumentsConfiguration[a.isin]

        instrumentsConfigurationNew[a.isin] = {
          weight: (index == instruments.length - 1) ? 100 - weightTotal : Math.floor(weightItem),
          fixed: oldConfiguration && oldConfiguration.fixed
        }
      })
    }

    setInstrumentsConfiguration(instrumentsConfigurationNew)
  }

  const getRiskParityWeightsConfiguration = async () => {
    let instrumentsConfigurationNew = {}

    if (instruments) {

      try {

        setWeightsConfigurationLoading(true)

        let requestBody = {
          "instruments": instruments.map(instrument => instrument.isin)
        }

        let response = await ModelPortfolioResource.getRiskParityWeights(requestBody)

        parseResponse(response, 'weights', (data) => {

          let components = data.components

          if (components) {
            components.forEach(component => {

              let oldConfiguration = instrumentsConfiguration[component.isin]

              instrumentsConfigurationNew[component.isin] = {
                weight: parseFloat((component.weight * 100).toFixed(5)),
                fixed: oldConfiguration && oldConfiguration.fixed
              }

            })

            setInstrumentsConfiguration(instrumentsConfigurationNew)
          }

        }, (errors) => {
          setWeightsConfigurationLoading(false)
          props.dispatch(displayErrorSnackBar('Gewichtung konnte nicht berechnet werden.'))
        });

        setWeightsConfigurationLoading(false)

      } catch (error) {
        setWeightsConfigurationLoading(false)
        props.dispatch(displayErrorSnackBar('Gewichtung konnte nicht berechnet werden.'))
      }

    }
  }

  const handleWeightOptionsChange = (options) => {
    if (options && options.length) {
      let option = options[0];

      if (option.id == 3) {
        getEqualWeightsConfiguration()
      } else if (option.id == 2) {
        getRiskParityWeightsConfiguration()
      }
    }
  }

  const isConfigurationValid = () => {
    let isValid = true

    instruments.forEach(instrument => {
      // 0 - is not valid weight
      if (!instrumentsConfiguration.hasOwnProperty(instrument.isin) || !parseFloat(instrumentsConfiguration[instrument.isin].weight)) {
        isValid = false
      }
    })

    return isValid
  }

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        {upDialogTitle && upDialogTitle()}
        <Grid container>
          <Grid item sm={5}>
            <p className={classes.gridItemTitle}>Instrument</p>
          </Grid>
          <Grid item sm={7}>
            <p className={classes.gridItemTitle}>Gewichtung</p>
            <FilterBudges items={RISK_OPTIONS} margin={0} multiple={false} onChange={handleWeightOptionsChange}/>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {weightsConfigurationLoading && (
          <div className={classes.weightsLoadingContainer}>
            <CircularProgress size={40}/>
          </div>
        )}
        <Grid container>
          <Grid container className={classes.assetsContainer}>
            {instruments && (
              <>
                {
                  instruments.map(instrument => renderInstrument(instrument))
                }
              </>
            )}
          </Grid>
          <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
            <span className={classes.totalWeightLabel}>Insgesamt</span>
            <div style={{margin: '0 15px'}}>
              <DropdownInput
                width={95}
                label="%"
                labelPosition="right"
                value={getTotalWeight()}
                disabled
                type="number"
                inputStyle={{
                  textAlign: "right",
                  color: getTotalWeight() != 100 ? 'red' : '#23D374'
                }}
                labelStyle={{
                  color: getTotalWeight() != 100 ? 'red' : '#23D374'
                }}
              />
            </div>
            {/*<FormControlLabel style={{visibility: 'hidden'}} label="Fixieren" control={<Checkbox color="primary"/>} className={classes.fiexedLabel}/>*/}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <PrimaryButton text="Abbrechen" variant="outlined" onButtonClick={handleClose}/>
        <PrimaryButton text="Speichern" disabled={getTotalWeight() != 100 || !isConfigurationValid()} onButtonClick={handleWeightsApprove}/>
      </DialogActions>
    </Dialog>
  )
}

InstrumentsWeightModal.propTypes = {
  /** Flag, that indicate if modal is visible, or not */
  open: PropTypes.bool,

  /** List of instruments */
  instruments: PropTypes.arrayOf(PropTypes.shape({})),

  /** OnWeightsApprove event handler */
  onWeightsApprove: PropTypes.func
}

InstrumentsWeightModal.defaultProps = {
  instruments: []
}

export default connect()(InstrumentsWeightModal)

