import React from 'react';
import Paginate from 'react-paginate'

import {withWidth} from "@material-ui/core";

import useStyles from './styles';


export default withWidth()((props) => {
  const classes = useStyles();

  const {
    handlePageChanged,
    totalPageCount,
    currentPage,
    justifyContent,
    width
  } = props;

  const pageRangeDisplayed = ['xs'].includes(width) ? 1 : 3;

  return (
    <div style={{
      display: 'flex',
      justifyContent: justifyContent || 'flex-end',
      width: '100%'
    }}>
      <Paginate
        containerClassName={classes.container}
        nextLabel={<span style={{transform: 'scale(2)'}}>&#x276F; </span>}
        previousLabel={<span style={{transform: 'scale(2)'}}>&#x276E;</span>}
        pageLinkClassName={classes.page}
        nextLinkClassName={classes.nextButton}
        previousLinkClassName={classes.previousButton}
        activeLinkClassName={classes.activeButton}
        pageCount={totalPageCount}
        onPageChange={(selectedItem) => handlePageChanged(selectedItem.selected)}
        forcePage={currentPage}
        pageRangeDisplayed={pageRangeDisplayed}
        marginPagesDisplayed={1}
      />
    </div>
  )
})