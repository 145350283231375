const styles = () => ({
  editor:{
    flex: 1,
  },
  container: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',

    '& .ql-editor': {
      fontFamily: 'Roboto-Regular'
    },

    '& .ql-toolbar': {
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none'
    },

    '& .ql-container': {
      border: 'none',
      overflow: 'auto',
      display: 'none'
    },

    '& .ql-tooltip input': {
      outline: 'none'
    },

    '& .ql-snow .ql-tooltip.ql-editing a.ql-action::after': {
      content: "'Speichern'"
    },

    '& .ql-snow .ql-tooltip[data-mode=link]::before': {
      content: "'Link eingeben:'"
    },
    '& .ql-picker.ql-insertCustomTags .ql-picker-label::before, & .ql-picker.ql-insertCustomTags .ql-picker-item::before': {
      content: 'Custom'
    },

    '& .ql-picker.ql-variable .ql-picker-label': {
      paddingRight: 18
    },

    '& .ql-picker.ql-variable .ql-picker-options': {
      overflowY: 'auto',
      maxHeight: '60vh'
    },

    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: 'rgba(0,0,0,.1)'
    },
    pointerEvents: 'none'
  },
  enabled: {
    pointerEvents: 'auto',
    '& .ql-container': {
      display: 'block'
    },
    '&::after': {
      content: 'none'
    }
  },
  textOnly: {
    '& .quill': {
      '& .ql-toolbar': {
        '& .ql-formats:first-child': {
          marginRight: 0
        }
      },
    },
  }
});

export default styles;