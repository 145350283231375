import React from 'react';
import {
  SortableHandle
} from 'react-sortable-hoc';
import _ from 'lodash';

import useStyles from './styles';
import DashboardTable from "../../../../components/DashboardTable";
import MenuIcon from "@material-ui/icons/Menu";
import {makeStyles} from "@material-ui/core";
import {PRODUCT_TYPE} from "../../constants";
import {InstrumentNameCell} from "../../../Modelportfolios/components/InstrumentsList/table-data";
import {PortfolioNameColumn} from "../../../Trades/components/TradeStep/components/PortfolioTrade/table-data";
import {Skeleton} from "@material-ui/lab";

import {getProductName} from "../../utils";

const ProductNameColumn = {
  header: "Produktname",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return (<Skeleton />)
      }

      const classes = useStyles();

      if ([PRODUCT_TYPE.MUSTERDEPOT, PRODUCT_TYPE.CUSTOMER_PORTFOLIO].includes(item.type) || !item.data) {
        return <b className={classes.overflowEllipsis}>{getProductName(item)}</b>
      }

      if (item.type === PRODUCT_TYPE.ASSET) {
        return InstrumentNameCell.body.content(item.data, options)
      } else {
        return PortfolioNameColumn.body[0]({item, options});
      }
    }
  }
}

const DragHandle = SortableHandle(() => <MenuIcon style={{color: '#c3c3c3', cursor: 'move'}}/>)

const SortColumn = {
  header: 'Sort.',
  body: {
    content: (item, options) => {
      return options.loading ? <Skeleton /> : <DragHandle />
    }
  }
}

const useDeleteColumnStyles = makeStyles((theme) => ({
  action: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    },
  }
}))

const DeleteProductColumn = {
  header: '',
  body: {
    content: (item, options) => {

      if (options.loading) {
        return (<Skeleton />)
      }

      const classes = useDeleteColumnStyles()
      return (
        <div onClick={() => options.onDeleteClick && options.onDeleteClick(item)} className={classes.action}>
          <i className={'fal fa-trash-alt'}></i>
          <span>Entfernen</span>
        </div>
      )
    }
  }
}

const tableStructure = {
  default: [{
    content: [SortColumn]
  },{
   content: [ProductNameColumn]
  }, {
    content: [DeleteProductColumn]
  }]
}

function ProductsList({products, onSortEnd, onDeleteProduct, loading}) {

  const classes = useStyles({empty: !products.length});

  return (
    <div className={classes.container}>
      <DashboardTable
        dataSourceKeyField={'product_id'}
        structure={tableStructure}
        dataSource={loading ? new Array(5).fill({}) : products}
        expanded
        tableClasses={classes}
        withFooter={false}
        dragable
        onSortEnd={onSortEnd}
        dragableHelperContainer={"product-comparison-list-container"}
        options={{
          onDeleteClick: onDeleteProduct,
          loading: loading
        }}
      />
    </div>
  )
};

export default ProductsList;