import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  title: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    marginBottom: 5
  }
}));