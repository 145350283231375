import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  topMessage: {
    margin: '1rem 0 1rem 0',
    fontSize: 16,
    fontFamily: 'Roboto-Bold'
  },
    word: {
    color: theme.palette.primary.main,
  },
})

export default makeStyles(styles);