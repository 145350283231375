import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    marginTop: 25,
    padding: '15px 27px',
    backgroundColor: 'white',
    borderRadius: 2,
    width: '100%',

    '& > p': {
      padding: 0,
      margin: 0,
      color: '#313D44',
      fontFamily: 'Roboto-Regular',
      fontSize: 16,
      display: 'flex',
    }
  },
  oneLineLink: {
    fontFamily: 'Roboto-Bold',
    color: theme.palette.primary.main,
    fontSize: 14,
    textDecoration: 'underline',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 15,
    whiteSpace: 'nowrap',

    '&:hover': {
      cursor: 'pointer',
    }
  },
  word: {
    fontFamily: 'Roboto-Bold'
  },
  topMessage: {
    display: 'flex',
    alignItems: 'start'
  }
}));