import React, { useState } from 'react';
import moment from 'moment';
import clsx from "clsx";
import _ from "lodash";

/* Material-UI Components */
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Skeleton from "@material-ui/lab/Skeleton";

/* BCA modules */
import {
  PERFORMANCE_TIME_TYPE,
  DEFAULT_PERFORMANCE_TIME_TYPE,
  CUSTOM_PERFORMANCE_TIME_TYPE_VALUE, CUSTOM
} from '../../../../../utils/constants';
import DateRange from "../../../../../components/DateRange/DateRange";
import {setTimeSelectorDateChange} from "../../../actions";
import {useStyles, useFilterClasses} from "./styles";
import WarningTooltip from "../../../../../components/WarningTooltip";
import {formatDateShouldBeGraterThenErrorMessage, isWeekend} from "../../../../../utils/utils";
import { FORM_FIELD_HEIGHT } from '../../../../TransactionsMonitoring/components/Filters/components/common_styles';
import Benchmark from '../../../components_v2/Benchmark/Benchmark';
import {NoPriceOnWeekendsWarning} from "../../../constants";


require('moment/locale/de');
moment.locale('de');


const getCalculationDatesType = (options, calculationDatesType) => _.isString(calculationDatesType)
  ? options[calculationDatesType]
  : calculationDatesType;


const PortfolioTimeSelector = React.memo((props) => {
  const {
    dispatch,
    calculationDates,
    calculationDatesType,
    handleCalculationDatesChanged,
    useFilterStyles,
    startOfInvestment,
    loading,
    stickyContainer,
    iconComponent,
    minDateErrorMessage,
    timeSelectorDateChange,
    customHeader,
    disabledOptions,
    showNoPriceOnWeekendsWarning,
    optionsInfo,
    isMounted={isMounted},
    BenchmarkProps,
    options
  } = props;

  const classes = useFilterStyles ? useFilterClasses() : useStyles();
  const _DEFAULT_PERFORMANCE_TIME_TYPE = props.defaultPerformanceTimeType || DEFAULT_PERFORMANCE_TIME_TYPE;
  const customOptionValue = _.get(options, 'CUSTOM.value', CUSTOM_PERFORMANCE_TIME_TYPE_VALUE);

  const timeSelectorChangeCallback = (value={}) => {
    dispatch && dispatch(setTimeSelectorDateChange(value))
  };

  React.useEffect(() => {
    timeSelectorChangeCallback();
  }, []);

  const buttonRef = React.useRef();
  const containerRef = React.useRef();
  const [selectedDates, setSelectedDates] = useState(calculationDates);
  const [expanded, setExpanded] = useState(false);
  const [performanceTimeType, setPerformanceTimeType] = useState(_DEFAULT_PERFORMANCE_TIME_TYPE);

  /**
   * Data Validation. For now it only print in console. Could be extended later
   */
  React.useEffect(() => {

    if (!calculationDatesType) {
      return
    }

    const dateFormatted = (date) => date && date.format('YYYY-MM-DD');

    const _calculationDatesType = getCalculationDatesType(options, calculationDatesType);

    if (!calculationDates.start || !calculationDates.end || _calculationDatesType.value == customOptionValue) {
      return
    }

    const datesTypeDates = _calculationDatesType.getDateRange();

    if (dateFormatted(datesTypeDates.start) != dateFormatted(calculationDates.start) || dateFormatted(datesTypeDates.end) != dateFormatted(calculationDates.end)) {
      console.warn('Calculation dates and calculation dates type mismatch!')
    }

  }, [calculationDates, calculationDatesType]);

  React.useEffect(() => {
    setSelectedDates(calculationDates)
  }, [calculationDates]);

  React.useEffect(() => {

    if (!calculationDatesType) {
      return
    }

    const _calculationDatesType = getCalculationDatesType(options, calculationDatesType);

    setPerformanceTimeType(_calculationDatesType)
  }, [calculationDatesType]);

  React.useEffect(() => {
    // as we have two navigations (static and sticky) we should expand only currently visible one
    // offsetParent returns null in case The element or any ancestor has the display=none for sticky
    // check opacity for static container.
    const staticNavContainer = containerRef.current.closest('#sticky-trigger');
    if(!containerRef.current.offsetParent || (staticNavContainer && staticNavContainer.style.opacity === '0')) return;

    if(timeSelectorDateChange) {
      if (timeSelectorDateChange.startOfBenchmarkChanged) {
        setDates(timeSelectorDateChange, CUSTOM)
      } else if (timeSelectorDateChange.start && timeSelectorDateChange.end) {
        handleClickListItem(CUSTOM);
        setSelectedDates(timeSelectorDateChange);
        setExpanded(true)
      }
    } else {
      setExpanded(false)
    }
  }, [timeSelectorDateChange]);

  const handleClickListItem = (key) => {
    // if clicked value is changed
    if (performanceTimeType.value !== options[key].value) {
      setPerformanceTimeType(options[key]);

      if (options[key].value !== customOptionValue) {
        setExpanded(false);
        setDates(options[key].getDateRange(), key);
      }
    }
  };

  const setDates = (dates, key) => {
    timeSelectorChangeCallback();
    handleCalculationDatesChanged(dates, options[key]);
  };

  const [startOfInvestmentMoment, setStartOfInvestmentMoment] = useState(undefined);
  React.useEffect(() => {
    setStartOfInvestmentMoment(startOfInvestment && moment(startOfInvestment))
  }, [startOfInvestment]);


  const renderPerformanceTimeTypesList = () => {
    const startDate = startOfInvestmentMoment;
    const selectedDateRange = performanceTimeType.getDateRange();
    const selectedStartDateRange = selectedDateRange && selectedDateRange.start;

    // if start of investment of portfolio is younger than currently selected date range
    if (startDate && selectedStartDateRange && startDate.isAfter(selectedStartDateRange)) {
      return handleClickListItem('BEGINNING'); // automatically select investment beginning
    }

    return Object.keys(options)
    .filter(key => {
      if (startDate) {
        const dateRange = options[key].getDateRange();
        const startDateRange = dateRange && dateRange.start || startDate;

        return startDateRange.isSameOrAfter(startDate);
      }
      return true;
    })
    .map(key => {
      if (disabledOptions.includes(options[key].value)) {
        return null
      }
      const isSelected = performanceTimeType.value === options[key].value;
      return (
        <ListItem
          key={options[key].value}
          className={clsx(classes.portfolioListItem, isSelected && classes.portfolioListItemSelected)}
          onClick={() => handleClickListItem(key)}
        >
          <span>{options[key].description}</span>
          {optionsInfo[key] && (
            <WarningTooltip title={optionsInfo[key]} />
          )}
        </ListItem>
      );
    });
  };

  const getMinDateErrorMessage = () => {
    if (minDateErrorMessage) return minDateErrorMessage;
    if (!!startOfInvestmentMoment) {
      return formatDateShouldBeGraterThenErrorMessage(startOfInvestmentMoment);
    }
  };

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const hasDatesData = !!calculationDates.start && !!calculationDates.end;

  const weekendsUsed = () => {
    return hasDatesData && (isWeekend(calculationDates.start) || isWeekend(calculationDates.end))
  };

  const formatSelected = () => {

    if (!isMounted) {
      return <Skeleton style={{width: '50%', height: 35}}/>
    }

    return hasDatesData
      ? `${calculationDates.start.format('DD.MM.YYYY')} - ${calculationDates.end.format('DD.MM.YYYY')}`
      : performanceTimeType.placeholder || 'Seit Investmentbeginn';

  };

  const disabled = loading || !isMounted;
  const benchmarkEnabled = _.get(BenchmarkProps, 'enabled');

  return (
    <div id={stickyContainer ? "date-picker-dropdown-sticky" : "date-picker-dropdown"} ref={containerRef}>
      {!stickyContainer && (!!customHeader || benchmarkEnabled) && (
        <FormLabel className={clsx(classes.label, disabled && 'disabled')}>
          {customHeader}
          {benchmarkEnabled && (
            <Benchmark
              disabled={disabled}
              configuration={BenchmarkProps.configuration}
              value={BenchmarkProps.showBenchmark}
              onValueChanged={BenchmarkProps.onValueChanged}
              onConfigurationClick={BenchmarkProps.onConfigurationClick}
            />
          )}
        </FormLabel>
      )}
      <Button
        ref={buttonRef}
        id="date-picker"
        onClick={handleClick}
        className={classes.expansionPanelSummaryRoot}
        disabled={disabled}
        classes={{disabled: classes.disabled}}
        disableRipple
        fullWidth
      >
        <span className={classes.ellipsis}>
          {formatSelected()}
        </span>
        {showNoPriceOnWeekendsWarning && weekendsUsed() && (
          <WarningTooltip title={NoPriceOnWeekendsWarning}/>
        )}
        <span style={{marginLeft: 'auto'}}>
          {iconComponent || <CalendarTodayIcon className={classes.calendarIcon} />}
        </span>
      </Button>
      <Popover open={expanded} anchorEl={buttonRef.current}
        onClose={handleClick}
        classes={{paper: classes.expansionPanelDetailsPaper }}
        anchorOrigin={{vertical: FORM_FIELD_HEIGHT, horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        elevation={0}
      >
        <div className={classes.portfolioListWrap}>
          <List className={classes.portfolioList}>
            {renderPerformanceTimeTypesList()}
          </List>
          {performanceTimeType.value === customOptionValue && (
            <div className={classes.timePickerContainer}>
              <DateRange
                start={(selectedDates && selectedDates.start) || startOfInvestmentMoment}
                end={(selectedDates && selectedDates.end)}
                minDate={startOfInvestmentMoment}
                onChange={(dateRange) => {
                  handleClick();
                  setDates(dateRange, CUSTOM);
                }}
                minDateErrorMessage={getMinDateErrorMessage()}
                classes={{
                  textFieldUnderline: classes.textFieldUnderline
                }}
              />
            </div>
          )}
        </div>
      </Popover>
    </div>
  )
});

PortfolioTimeSelector.defaultProps = {
  customHeader: 'Zeitraum',
  disabledOptions: [],
  optionsInfo: {},
  options: PERFORMANCE_TIME_TYPE,
};

export default PortfolioTimeSelector;