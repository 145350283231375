import React from 'react'
import { connect } from 'react-redux'

import useStyles from './styles'
import {useCustomerSummaryContext} from "../../CustomerSummaryCard";
import PropTypes from "prop-types";
import {Grid} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {PrimaryButton} from "../../../../../../components/Buttons";
import {executeIfPathExist, getInvestmentDynamicPath} from "../../../../../InvestmentPlatform/utils";
import _ from "lodash";
import {getRiskColor, getRiskTooltipText, HIGH_RISK_VALUE} from "../../../../utils";
import Tooltip from "@material-ui/core/Tooltip";
import {toGermanFormat} from "../../../../../../utils/numberFormater";

function RiskIndicatorItem({value, maxValue, color, title, tooltipText}) {

  const classes = useStyles()

  return (
    <div className={classes.profileRiskContainer}>
      <p className={classes.profileRiskTitle}>{title}</p>
      <p className={classes.profileRiskNumber}>
        <span className={classes.profileRiskValue} style={{color}}>
          {value > 0 ? toGermanFormat(value, undefined, undefined, 2, true) : '-'}
        </span>
        <span style={{position: 'relative'}}>
          {tooltipText ? (
          <span className={classes.infoText}>
            <Tooltip classes={{tooltip: classes.tooltip}} arrow placement={"top"} title={tooltipText}>
              <i className='far fa-info-circle' />
            </Tooltip>
          </span>
        ) : null}
          /&nbsp;
        </span>
        {maxValue}
      </p>
    </div>
  )

}

RiskIndicatorItem.propTypes = {
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number,
  color: PropTypes.string.isRequired,
  title: PropTypes.string
}

RiskIndicatorItem.defaultProps = {
  maxValue: 7,
  title: <span>Risikoklasse Kunde</span>
}

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS()
});

function RiskIndicators(props) {
  const {customer, loading, error, productRiskValue, productRiskLoading, productRiskErrors, customerRisk} = useCustomerSummaryContext();
  const {} = props;
  const classes = useStyles();

  const handleDashboardButtonClick = () => {
    executeIfPathExist(props.investmentPlatform.routes, 'DASHBOARD', (dynamicPath) => {
      window.open('/' + getInvestmentDynamicPath() + dynamicPath.replace(':customer_id', customer.customer_id), '_blank')
    }, ':customer_id')
  }

  if (loading) {
    return (
      <Grid container>
        <Grid item xs={6}>
          <div className={classes.loadingContainer}>
            <Skeleton style={{width: '85%'}}/>
            <Skeleton style={{width: '55%'}}/>
            <Skeleton style={{width: '50%', height: 40}}/>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.loadingContainer}>
            <Skeleton style={{width: '85%'}}/>
            <Skeleton style={{width: '55%'}}/>
            <Skeleton style={{width: '50%', height: 40}}/>
          </div>
        </Grid>
      </Grid>
    )
  }

  if (customer) {
    return (
      <div>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <RiskIndicatorItem
              color={getRiskColor(customerRisk, productRiskValue)}
              value={customerRisk}
              tooltipText={`Der Grenzwert für das maximal zulässige durchschnittliche Portfolio Risiko eines Kunden beträgt (Kunden-Risikoklasse + ${HIGH_RISK_VALUE}). Es wird lediglich das gewichtete Portfoliorisiko der Einzelfondsdepots angezeigt. Vermögensverwaltungsstrategien sind hier nicht enthalten.`}
            />
          </Grid>
          <Grid item xs={12}>
            {productRiskLoading ? (
              <div className={classes.loadingContainer}>
                <Skeleton style={{width: '85%'}}/>
                <Skeleton style={{width: '55%'}}/>
                <Skeleton style={{width: '50%', height: 60}}/>
              </div>
            ) : (
              <RiskIndicatorItem
                color={getRiskColor(customerRisk, productRiskValue)}
                value={productRiskValue}
                tooltipText={getRiskTooltipText(customerRisk, productRiskValue)}
                title={<span>Maximales durchschnittliches<br/>Portfoliorisiko</span>}
              />
            )}
          </Grid>
        </Grid>
        {props.withDashboardButton && customer && (
          <PrimaryButton
            text={"Portfolioentwicklung anzeigen"}
            style={{width: '100%', marginTop: '20px'}}
            onButtonClick={handleDashboardButtonClick}
          />
        )}
      </div>
    )
  }

  return null
}

export default connect(mapStateToProps)(RiskIndicators)