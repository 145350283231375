import React from 'react';
import _ from 'lodash';
import {FETCH_DATA_INITIAL_STATE, getFetchDataLoadingState, getFetchDataErrorState} from "../../../hooks/constants";
import {PRODUCT_TYPE} from "../constants";
import {
  FactSheetsHandlerResource,
  ModelPortfolioResource,
  parseResponse,
  PortfolioHandlerResource
} from "../../../utils/api";
import {translateBarChartLabels} from "../../FactSheetsPage/Tabs/FundStructure";
import {getProductCustomerId} from "../utils";


async function fetchAssetPortfolioData (productToFetch, _start_date, _end_date, resolve, reject) {
  let response = await FactSheetsHandlerResource.getAllocationData(productToFetch.product_id, true, true, true)
  parseResponse(response, 'allocation', resolve, reject)
}

async function _fetchMusterDepotPortfolioData(id, start_date, end_date, resolve, reject) {
  let response = await ModelPortfolioResource.getPortfolioData(id, undefined, start_date, end_date)
  parseResponse(response, 'breakdown', resolve, reject)
}

async function fetchMusterDepotPortfolioData (productToFetch, start_date, end_date, resolve, reject) {
  return await _fetchMusterDepotPortfolioData(productToFetch.product_id, start_date, end_date, resolve, reject)

}

async function fetchInvestmentStrategyPortfolioData (productToFetch, start_date, end_date, resolve, reject) {
  return await _fetchMusterDepotPortfolioData(productToFetch.data.related_model_portfolio.id, start_date, end_date, resolve, reject)
}

async function fetchCustomerPortfolioData (productToFetch, start_date, end_date, resolve, reject){
  let response = await PortfolioHandlerResource.getPortfolioData(getProductCustomerId(productToFetch), productToFetch.configuration.depot_id, start_date, end_date,);
  parseResponse(response, 'breakdown', resolve, reject)
}


const getDictWithTranslatedKeys = (data) => {
  let dictWithTranslatedKeys = {}

  translateBarChartLabels(data).map(arrayElem => {
    dictWithTranslatedKeys[arrayElem.name] = arrayElem
  })

  return dictWithTranslatedKeys
}

export default function usePortfolioData(allProducts, selectedProducts, selectedDates, tableDataKeys) {

  // initialize state with FETCH_DATA_INITIAL_STATE data for each product
  let allProductsData = {};
  allProducts.map(
    (p, index) =>
      allProductsData[p.product_id] = {
      ...FETCH_DATA_INITIAL_STATE,
        productData: p,
        order: index // allProducts are sorted, use index in allProducts to sort later
    }
  );
  const [portfolioData, setPortfolioData] = React.useState(allProductsData)
  const [isMounted, setIsMounted] = React.useState(false)

  React.useEffect(() => {
    // use effect to update configuration of the product in data
    if (!_.isEmpty(selectedProducts)) {
      selectedProducts.map(p => {
        // if data for selected product is missing - fetch it
        if (_.isNil(portfolioData[p.product_id].data)){
          fetchProductPortfolioData(p);
        } else {
          setPortfolioData( (currentPortfolioData) => ({
            ...currentPortfolioData,
            [p.product_id]: {
              ...currentPortfolioData[p.product_id],
              order: p.configuration.order,
              productData: {...p}
            },
          }))
        }
      })
      setIsMounted(true);
    }
  }, [selectedProducts]);

  React.useEffect(() => {
    // is Mounted used to prevent duplicated fetch on first render
    if (isMounted) {
      selectedProducts.map(p => {
        // if selectedDates were changed fetch data for all none asset products and for assets that has no data
        if(p.type !== PRODUCT_TYPE.ASSET || _.isNil(portfolioData[p.product_id].data)){
          fetchProductPortfolioData(p);
        }
      })
    }
  }, [selectedDates])

  const fetchProductPortfolioData = async(productToFetch) => {

    setPortfolioData( (currentPortfolioData) => ({
      ...currentPortfolioData,
      [productToFetch.product_id]: {
        ...currentPortfolioData[productToFetch.product_id],
        ...getFetchDataLoadingState(),
      },
    }))

    try {
      // depending on product type fetch data
      await {
        [PRODUCT_TYPE.ASSET]: fetchAssetPortfolioData,
        [PRODUCT_TYPE.MUSTERDEPOT]: fetchMusterDepotPortfolioData,
        [PRODUCT_TYPE.MODEL_PORTFOLIO]: fetchMusterDepotPortfolioData,
        [PRODUCT_TYPE.PRIVATE_INVESTMENT]: fetchInvestmentStrategyPortfolioData,
        [PRODUCT_TYPE.CUSTOMER_PORTFOLIO]: fetchCustomerPortfolioData,
      }[productToFetch.type](
        productToFetch, selectedDates.start.format('YYYY-MM-DD'), selectedDates.end.format('YYYY-MM-DD'),
        // resolve
        (data => {

          if ('portfolio_structure' in data) {
            data = data.portfolio_structure // for 'containers' products get proper data
          }

          setPortfolioData((currentPortfolioData) => {

            let translatedData = {}
            // loop over categories and their data in response
            for(const [categoryKey, categoryData] of Object.entries(data)){
              let dataKey = categoryKey

              // if data is fetched for single asset - replace following keys
              if (categoryKey === 'by_subtype'){
                dataKey = 'by_fixed_income_sub_type'
              }

              // if current key is not in mapping - skip it
              if(!tableDataKeys.includes(dataKey)) continue;

              translatedData[dataKey] = getDictWithTranslatedKeys(categoryData)
            }

            return {
              ...currentPortfolioData,
              [productToFetch.product_id]: {
                ...currentPortfolioData[productToFetch.product_id],
                data: _.isEmpty(translatedData) ? undefined : translatedData,
                loading: false,
                error: undefined
              },
            }
          })
        }),
        // reject
        (errors => {
          setPortfolioData((currentPortfolioData) => ({
            ...currentPortfolioData,
            [productToFetch.product_id]: {
              ...currentPortfolioData[productToFetch.product_id],
              ...getFetchDataErrorState(errors),
            },
          }))
        })
      )
    } catch (errors){
      setPortfolioData((currentPortfolioData) => ({
        ...currentPortfolioData,
        [productToFetch.product_id]: {
          ...currentPortfolioData[productToFetch.product_id],
          ...getFetchDataErrorState(errors),
        },
      }))
    }
  }

  // returns dict with data for currently selected products
  return _.pickBy(portfolioData, data => data.productData.configuration.active);

}