import _ from "lodash";
import {toGermanFormat} from "../../../../../utils/numberFormater";

export function maxSRRI(assets) {
  const maxItem = assets && _.maxBy(assets, i => i.sri);
  return maxItem && maxItem.sri || '-';
}

export function avgSRIValue(assets) {
  const assetsWithSRRI = assets && assets.filter(i => !!i.sri) || [];
  if(assetsWithSRRI.length > 0){
    let totalWeight = 0, totalWeightedSRRI = 0;
    assetsWithSRRI.forEach(i => {
      const weight = parseFloat(i.weight) / 100;
      totalWeightedSRRI += i.sri * weight;
      totalWeight += weight;
    });

    return totalWeightedSRRI / totalWeight;
  } else {
    return null;
  }
}

export function avgSRRI(assets) {
  const avgValue = avgSRIValue(assets);
  if(!_.isNull(avgValue)){
    return toGermanFormat(avgValue);
  } else {
    return '-';
  }
}