import useListStyles from "../../../../../../components/CustomerList/styles";
import React from "react";
import AlertConfigurationWrapper from "../../../AlertConfigurationModal/AlertConfigurationModal";
import { ALERT_PRODUCT_TYPE } from '../../../AlertConfigurationModal/utils';
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import {CircularProgress} from "@material-ui/core";
import ExpandButton
  from "../../../../../CustomerDashboard/components/Widgets/components/DashboardCard/components/ExpandButton";
import useFetchData from "../../../../../../hooks/useDataFetch";
import {parseResponse, PortfolioHandlerResource} from "../../../../../../utils/api";
import {EDIT_ALERT_ACTIONS} from "../../../../constants";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import UserInfo from "../../../../../../components/ProfileDetails/components/UserInfo";
import Link from "../../../../../../components/Link";
import {hasPortfolios} from "../../../../../../components/CustomersSelectorProviderNew/utils";
import {useCustomerAlertsListItemStyles} from "./styles";
import {alerts} from "../../index";
import EventsList from "../../../AlertConfigurationModal/EventsList";
import moment from "moment";
import {useIndividualPortfoliosRiskMetricsData} from "../../../../hooks/useIndividualPortfoliosRiskMetricsData";
import {connect} from "react-redux";
import {displayErrorSnackBar} from "../../../../../../components/SnackbarProvider/actions";
import ErrorIcon from "@material-ui/icons/Error";

const parseInstrumentTable = (response) => {
  let data = [];
  parseResponse(response, 'instruments_table',
    (parsedData) => data = parsedData.instruments || [],
    (parsedErrors) => {
      console.error('Error during parsing instrument table data.');
      console.error(parsedErrors);
    });

  return data.reduce((result, portfolio) => {
    portfolio.components = portfolio.components.reduce((componentsResult, component) => {
      componentsResult[component.id] = component;
      return componentsResult
    }, {});
    result[portfolio.id] = portfolio;
    return result;
  }, {});
}

export const CustomerAlertsListItem = connect()(({customer, onDetailsClick, onAlertConfigurationSaved, dispatch}) => {
  const classes = useCustomerAlertsListItemStyles();
  const listClasses = useListStyles();

  const selectedPortfolios = Object.keys(customer.alertConfigurations);

  const [expanded, setExpanded] = React.useState(false);

  const [customerData, fetchCustomerData] = useFetchData(
    `${PortfolioHandlerResource.resourceUrl}customer/${customer.customer_id}/customer-app-data/`,
    'get', undefined, false);

  const depots = React.useMemo(() => {

    const _depots = Object.values(customer.alertConfigurations);

    if (customerData.loading || customerData.errors || !customerData.data) {
      return _depots;
    }

    const portfoliosData = _.get(customerData, 'data.customer.data.0.portfolios.portfolios');
    if (!portfoliosData) {
      return _depots;
    }

    const result = []

    Object.keys(customer.alertConfigurations).forEach((groupId) => {
      const depotData = _.find(portfoliosData, (p) => p.depotNumber == groupId) || {};
      if (_.isEmpty(customer.alertConfigurations[groupId].depot_configurations) && _.isEmpty(customer.alertConfigurations[groupId].asset_configurations)) {
        return;
      }
      result.push({
        ...depotData,
        ...customer.alertConfigurations[groupId],
        id: groupId,
        alerting_configuration: customer.alertConfigurations[groupId].depot_configurations
      });
    });

    return result

  }, [customerData.updatedAt, JSON.stringify(customer.alertConfigurations)]);

  const [portfoliosData, fetchPortfoliosData] = useFetchData(
    `${PortfolioHandlerResource.resourceUrl}customer/${customer.customer_id}/portfolio-data/`, 'post', parseInstrumentTable, true)
  const [individualPortfoliosRiskMetricsData, fetchIndividualPortfolioRiskMetricsData] = useIndividualPortfoliosRiskMetricsData(
    customer.customer_id, customerData, depots, false);

  const riskMetricsLoadingTriggered = React.useRef(false);

  React.useEffect(() => {
    // Check if depots contains information from customers data
    if (_.some(depots, (depot) => !!depot.portfolioId && !riskMetricsLoadingTriggered.current)) {
      fetchIndividualPortfolioRiskMetricsData();
      riskMetricsLoadingTriggered.current = true;
    }

  }, [depots])

  React.useEffect(() => {
    if (customerData.errors) {
      dispatch(displayErrorSnackBar('Fehler beim Laden der Produktalarme für Kunden.'))
    }
  }, [customerData.updatedAt])

  React.useEffect(() => {
    if (expanded && !customerData.loading) {
      fetchCustomerData({with_portfolios_data: true});
      fetchPortfoliosData({
        depot_id: selectedPortfolios,
        "start_date": moment().startOf('year').format('YYYY-MM-DD')
      })
    }
  }, [expanded]);

  const toggleExpanded = () => {
    setExpanded((current) => !current)
  }

  const [activeAlerts, inactiveAlerts] = React.useMemo(() => {

    let list = _.map(Object.values(customer.alertConfigurations), (depot) => {
      return [...(depot.depot_configurations || []), ...(depot.asset_configurations || [])];
    });
    list = _.flatten(list);

    const [active, inactive] = list.reduce(([_active, _inactive], item) => {
      if (item.active) {
        _active.push(item);
      } else {
        _inactive.push(item);
      }

      return [_active, _inactive]
    }, [[], []]);

    if (_.isEmpty(list) || customerData.loading || customerData.errors || !customerData.data) {
      return [[], []];
    }

    let portfoliosData = _.get(customerData, 'data.customer.data.0.portfolios.portfolios');
    if (!portfoliosData) {
      return [active, inactive];
    }
    portfoliosData = portfoliosData.reduce((portfoliosDataDict, portfolio) => ({
      ...portfoliosDataDict,
      [portfolio.depotNumber]: portfolio
    }), {});

    const _updateAlertWithPortfolioData = (alert) => ({
      ...alert,
      portfolio_data: portfoliosData[alert.group_id] || {}
    });

    return [active.map(_updateAlertWithPortfolioData), inactive.map(_updateAlertWithPortfolioData)]

  }, [customerData.updatedAt, JSON.stringify(customer.alertConfigurations)])

  const [depotAlertsCount, assetAlertsCount] = (() => {
    if (_.isEmpty(depots)) {
      return [0, 0];
    }

    return depots.reduce(([dCount, aCount], depot) => {
      dCount += (depot.depot_configurations || []).length;
      aCount += (depot.asset_configurations || []).length;

      return [dCount, aCount];
    }, [0, 0])
  })();

  const handleAlertConfigurationSaved = (asset, configuration, productType, action) => {

    const configurationKey = productType == ALERT_PRODUCT_TYPE.ASSET
      ? 'asset_configurations'
      : 'depot_configurations'

    if (action == EDIT_ALERT_ACTIONS.DELETE) {
      alerts[customer.customer_id][configuration.group_id][configurationKey] = _.filter(
        alerts[customer.customer_id][configuration.group_id][configurationKey], (conf) => conf.id != configuration.id)
    } else {
      alerts[customer.customer_id][configuration.group_id][configurationKey].forEach((conf) => {
        if (conf.id !== configuration.id) {
          return
        }
        conf.active = action === EDIT_ALERT_ACTIONS.ACTIVATE
      })
    }

    onAlertConfigurationSaved && onAlertConfigurationSaved();
  }

  const handleDeleteButtonClick = (configuration, onSave) => onSave && onSave(configuration, EDIT_ALERT_ACTIONS.DELETE, false, true)
  const handleActivateButtonClick = (configuration, onSave) => onSave && onSave(configuration, EDIT_ALERT_ACTIONS.ACTIVATE, false, true)
  const handleCancelButtonClick = (configuration, onSave) => onSave && onSave(configuration, EDIT_ALERT_ACTIONS.CANCEL, false, true)

  const eventsListTableProps = {
    withProductNameColumn: true,
    withCurrentValueColumn: true,
    options: {
      currentValues: {
        instrumentTable: portfoliosData,
        individualPortfoliosRiskMetrics: individualPortfoliosRiskMetricsData
      }
    }
  }

  return (
    <Grid item xs={12} className={listClasses.listItemContainer}>
      <Card className={listClasses.listItemCard}>
        <CardContent className={listClasses.cardContent}>
          <Grid container>
            <Grid
              item
              md={6} xs={12}
              className={clsx(listClasses.cardItem, classes.cardItem)}
            >
              <div>
                <UserInfo
                  customer={customer}
                />
              </div>
            </Grid>
            <Grid
              item
              md={3} sm={6} xs={10}
              className={clsx(listClasses.cardItem, classes.cardItem)}
            >
              <div >
                <p className={classes.alertCount}>Depotalarme: {depotAlertsCount}</p>
                <p className={classes.alertCount}>Produktalarme: {assetAlertsCount}</p>
                <Link
                  title="Alarme einstellen"
                  icon={<i class="fa fa-chevron-right" aria-hidden="true"></i>}
                  iconPosition="right"
                  underline
                  disabled={!hasPortfolios(customer)}
                  onClick={() => onDetailsClick(customer.customer_id)}
                />
              </div>
            </Grid>
            <Grid
              item
              md={3} sm={6} xs={2}
              className={clsx(listClasses.cardItem, classes.cardItem, classes.buttonWrapper)}
              style={{justifyContent: 'flex-end', alignItems: 'center'}}
            >
              <div >
                <ExpandButton
                  expanded={expanded}
                  onChanged={toggleExpanded}
                  disabled={!depotAlertsCount && !assetAlertsCount}
                />
              </div>
            </Grid>
          </Grid>
          {expanded && (
            <Grid container className={classes.cardItemExpansionPanel}>
              {customerData.loading || customerData.errors ? (
                <div style={{width: '100%', height: 75, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  {customerData.errors ? <ErrorIcon /> : <CircularProgress />}
                </div>
              ) : (
                <AlertConfigurationWrapper
                  customer_id={customer.customer_id}
                  allowCreateNew={false}
                  onAlertConfigurationSaved={handleAlertConfigurationSaved}
                >
                  {
                    ({onSave}) => (
                      <>
                        {!_.isEmpty(activeAlerts) && (
                          <Grid item xs={12}>
                            <EventsList
                              events={activeAlerts || []}
                              title={activeAlerts.length > 1 ? 'Aktivierte Alarme' : 'Aktivierter Alarm'}
                              primary
                              onDeleteClick={(configuration) => handleDeleteButtonClick(configuration, onSave)}
                              onCancelClick={(configuration) => handleCancelButtonClick(configuration, onSave)}
                              expanded={true}
                              TableProps={eventsListTableProps}
                            />
                          </Grid>
                        )}
                        {!_.isEmpty(inactiveAlerts) && (
                          <Grid item xs={12}>
                            <EventsList
                              events={inactiveAlerts}
                              title={inactiveAlerts.length > 1 ? 'Deaktivierte Alarme' : 'Deaktivierter Alarm'}
                              onActivateClick={(configuration) => handleActivateButtonClick(configuration, onSave)}
                              onDeleteClick={(configuration) => handleDeleteButtonClick(configuration, onSave)}
                              expanded={true}
                              TableProps={eventsListTableProps}
                            />
                          </Grid>
                        )}
                      </>
                    )
                  }
                </AlertConfigurationWrapper>
              )}
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  )
})
