import {
  SET_THEME,
  SET_THEME_LOADING,
  SET_AGENCY_DATA,
  SET_AGENCY_DATA_LOADING,
} from './constants';


export const loadThemeForStore = () => ({
  type: SET_THEME_LOADING
})

export const setThemeToStore = (data) => ({
  type: SET_THEME,
  data
})

export const loadAgencyDataForStore = () => ({
  type: SET_AGENCY_DATA_LOADING
})

export const setAgencyDataToStore = (data) => ({
  type: SET_AGENCY_DATA,
  data
})