import { makeStyles } from '@material-ui/core';
import {smFontSize, xsFontSize} from "../../../../../../../../utils/constants";

export default makeStyles(theme => ({
  tableSection: {
    maxWidth: '100%',
    width: '100%',
    overflowX: 'auto',

    '&:not(:last-of-type)': {
      marginBottom: 20,

      [theme.breakpoints.down('sm')]: {
        marginBottom: 15,
      }
    }
  },
  tableSectionHeader: {
    fontSize: 12,
    fontFamily: 'Roboto-Bold',
    letterSpacing: 0,
    color: '#4D4F5C',
    margin: 0
  },
  selectSection: {
    display: 'flex',
    alignItems: 'center',

    '& > span': {
      fontFamily: 'Roboto-Bold',
      fontSize: 14,
      color: '#AFAFAF',
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    }
  },
  selectFormControl: {
    minWidth: 140,
    marginLeft: 8,
    [theme.breakpoints.up('sm')]: {
      marginRight: 8,
    },
  },
  selectRoot: {
    borderRadius: 0,
    padding: 11,

    [theme.breakpoints.down('sm')]: {
      padding: 8,
    }
  },
  helpIcon: {
    color: '#E1E3E9',
    width: 23,
    height: 23,
    marginLeft: 10,
    transform: 'rotate(180deg)',

    '&:hover': {
      cursor: 'pointer'
    },
  },
  selectSelectMenu: {
    backgroundColor: 'red'
  },
  selectMenuPaper: {
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    border: 'none'
  },
  selectMenuItemRoot: {
    color: 'initial!important',
    fontFamily: 'Roboto-Regular!important',
    [theme.breakpoints.only('sm')]: {
      fontSize: smFontSize,
      minHeight: '2rem'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: xsFontSize,
      minHeight: '2rem'
    },
  },
  linkButton: {
    fontFamily: 'Roboto-Bold',
    textTransform: 'none',
    minWidth: 'auto',
    padding: 0,
    '& > span': {
      color: `${theme.palette.primary.main} !important`,
      fontSize: 18,
      fontFamily: 'Roboto-Bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: smFontSize,
      },
    },
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
      color: `${theme.palette.primary.main} !important`,
    }
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    }
  },
  proLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    "&:hover": {
      color: theme.palette.primary.main,
    }
  },
  buttonRoot: {
    borderRadius: 2,
    height: 40,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: 35,
    }
  },
}));