import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  stickyButtonRoot: {
    borderRadius: 8,
    height: '50px !important',
    marginLeft: 15
  },
  text: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38'
  },
  closeButton: {
    float: 'right',
    position: 'relative',
    top: -20,
    right: -20
  },
})

export default makeStyles(styles)