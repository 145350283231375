export default (theme) => ({
  customerListSection: {
    paddingLeft: 15,
    paddingTop: 0,

    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingTop: 15
    }
  },
  backLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    lineHeight: '72px',

    '& > i': {
      fontSize: 12,
      marginRight: 5
    }
  }
})