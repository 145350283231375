import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    display: 'inline-flex',
    position: 'relative',
    verticalAlign: 'middle',
    width: '100%',
    marginBottom: 10,
  },
  item: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    outline: 'none',
    cursor: 'pointer',
    borderRadius: 0,
    padding: 0,
    margin: 1,
    border: 'none',
    lineHeight: '2rem',
    background: 'white',
    color: '#838A93',
    display: 'inline-block !important',
    flex: '1 1 1.875rem',
    '&:hover': {
      backgroundColor: '#CFE2EF',
      color: '#2a3545',
    },
    [theme.breakpoints.only('md')]: {
      flexBasis: '5%',
    },
    [theme.breakpoints.only('sm')]: {
      flexBasis: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: '4rem',
    }
  },
  itemDisabled: {
    cursor: 'initial',
    pointerEvents: 'none',
    color: "#acb5be",
    backgroundColor: "#d4d8dd",
  }
}))