import React from 'react';
import _ from 'lodash';

import { Skeleton } from '@material-ui/lab';
import { NO_SECTION_MARK } from '../../../../../../utils/constants';
import { PRODUCT_TYPE } from '../../../../constants';
import { toGermanFormat } from '../../../../../../utils/numberFormater';
import RatingGlobes from '../../../../../../components/RatingGlobes';

const ESG_CATEGORY_FILED_TYPE = {
  PERCENT: 0,
  BOOL_OR_NONE: 1,
  STAR_RATING: 2
};

const ICON_FONT_SIZE = 24;

const SustainabilityCellContent = ({product, columnKey, tableKey, fieldType}) => {

  if (product.loading) {
    return <Skeleton />
  }

  if (product.error || !(tableKey in (product || {})) || _.isNil(_.get(product[tableKey], columnKey))){
    return NO_SECTION_MARK
  }

  const productFieldValue = product[tableKey][columnKey];
  let Component = <></>

  if( _.isNil(productFieldValue)){
    Component = <i className="fal fa-minus" style={{color: '#707070', fontSize: ICON_FONT_SIZE}}/>
  } else {
    const boolOrNone = (field) => field ?
      <i className="fal fa-check-circle" style={{color: '#23D374', fontSize: ICON_FONT_SIZE}} /> :
      <i className="fal fa-times-circle" style={{color: '#E50000', fontSize: ICON_FONT_SIZE}} />

    switch (fieldType) {
      case ESG_CATEGORY_FILED_TYPE.BOOL_OR_NONE:
        Component = boolOrNone(productFieldValue)
        break;
      case ESG_CATEGORY_FILED_TYPE.PERCENT:
        // for single assets or portfolios with main category 0 boolOrNone logic is used
        Component = (_.isBoolean(productFieldValue) || product.type == PRODUCT_TYPE.ASSET) ? boolOrNone(productFieldValue) : toGermanFormat(productFieldValue, '', ' %');
        break;
      case ESG_CATEGORY_FILED_TYPE.STAR_RATING:
        Component = <RatingGlobes fontSize={ICON_FONT_SIZE} value={Math.round(productFieldValue)} />
        break;
    }
  }

  return (
    <>
      {Component}
    </>

  )

};

export default SustainabilityCellContent;