import {fontSizeTable, smFontSizeTable} from "../../../../../../utils/constants";
import {makeStyles} from "@material-ui/core";

const styles = theme => ({
  root: {
    minHeight: 150,
  },
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& > div': {
      width: '100%'
    },
    flexDirection: 'column'
  },
  negative: {
    backgroundColor: '#1FBBD5'
  },
  weak: {
    backgroundColor: '#C8E8C7'
  },
  middle: {
    backgroundColor: '#FFD44A'
  },
  strong: {
    backgroundColor: '#FA8B2A'
  },
  emptyValue: {
    backgroundColor: '#F0F0F0'
  },
  correlationMatrix: {
    borderCollapse: 'collapse',
    width: '100%',

    overflowX: 'scroll',
    position: 'relative',
    display: 'block',

    '& thead td': {
      backgroundColor: '#F5F6F7',
      paddingTop: 5,
      paddingBottom: 5,
      border: 'none',
      fontFamily: 'Roboto-Bold',
      color: '#80858C',
      fontSize: fontSizeTable,
      '&:first-child': {
        position: 'sticky',
        left: 0,
        zIndex: 999,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: smFontSizeTable,
      }
    },
    '& tbody td': {
      '&:first-child': {
        position: 'sticky',
        left: 0,
        zIndex: 999,
        backgroundColor: 'white',
        border: 'none',
        padding: 0,
        height: 42,

        [theme.breakpoints.down("sm")]: {
          height: 30
        }
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: smFontSizeTable,
      }
    }
  },
  tableCell: {
    textAlign: 'center',
    minWidth: 32,
    [theme.breakpoints.up("sm")]: {
      minWidth: 65,
    },
    borderBottom: 'solid 1px #D8DCDF',
    borderRight: 'solid 1px #D8DCDF'
  },
  columnName: {
    textAlign: 'left important!',
    width: '100%'
  },
  columnNumber: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
      width: 50,
    },
    textAlign: 'right',
    paddingRight: 2,
    width: 24,
    display: 'inline-block'
  },
  assetName: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
    // minWidth: 300,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    width: 'calc(100% - 135px)',
    verticalAlign: 'middle',
    [theme.breakpoints.only("xs")]: {
      width: 'calc(100% - 70px)',
    }
  },
  columnTitle: {
    borderBottom: 'solid 1px #D8DCDF',
    backgroundColor: 'white',
    maxWidth: "50svw" // 50% of visible window width
  },
  weightColumn: {
    width: 85,
    textAlign: 'right',
    display: 'inline-block',
    paddingRight: 10,
    paddingLeft: 5,
    [theme.breakpoints.only("xs")]: {
      width: 45,
      paddingRight: 2,
      paddingLeft: 2,
    }
  },
  
  nameHeader: {
    display: 'inline-block',
    // minWidth: 350,
    textAlign: 'left',
    paddingLeft: 15,
    width: 'calc(100% - 85px)',
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 0,
      width: 'calc(100% - 45px)',
    }
  },
  weightHeader: {
    display: 'inline-block',
    textAlign: 'right'
  },
  description: {
    fontSize: 12,
    marginTop: 10,
    color: '#8D8D8D',
    display: 'inline-block',
    verticalAlign: 'top',

    [theme.breakpoints.up("sm")]: {
      marginLeft: 30,
    },
  },
  legend: {
    style: 'none',
    float: 'right',
    listStyle: 'none',
    marginTop: 0,

    [theme.breakpoints.only("xs")]: {
      padding: 0
    }
  },
  legendItem: {
    display: 'inline-block',
    marginRight: 30,
    '&:last-of-type': {
      marginRight: 0
    },

    [theme.breakpoints.only("xs")]: {
      marginRight: 10,
    }
  },
  bullet: {
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 8
  },
  highlighted: {
    backgroundColor: '#626262 !important',
    color: '#fff !important',

    '& *': {
      color: '#fff'
    }
  },
  titleWrapper: {
    height: '100%',
    display: 'block',
    borderBottom: 'solid 1px #D8DCDF',
    borderRight: 'solid 1px #D8DCDF',
    paddingTop: 10,
    paddingBottom: 10,

    [theme.breakpoints.only("xs")]: {
      paddingTop: 6,
      paddingBottom: 6,
    }
  }
});

export const useStyles = makeStyles(styles);

export default styles;