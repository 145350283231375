import React from 'react';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import InfoIcon from '@material-ui/icons/Info';

/* BCA modules*/
import useStyles from './styles';
import WarningTooltip from '../../../../../../components/WarningTooltip';

import {DEFAULT_DISTRIBUTION_TYPE, DISTRIBUTION_TYPES} from './constants';
import _ from "lodash";

export default (props) => {
    const classes = useStyles();

    const [value, setValue] = React.useState(props.selected || DEFAULT_DISTRIBUTION_TYPE.value);

    // workaround to update inner values from outside
    React.useEffect(() => {
      if (!_.isNil(props.selected)) {
        setValue(props.selected);
      }
    }, [props.selected]);

    const handleChange = (event) => {
        setValue(+event.target.value);
        props.handleChanged(+event.target.value);
    };

    return (
        <div className={classes.container}>
          <FormControl disabled={props.disabled || props.postboxDisabled} component="fieldset">
            <FormLabel component="legend" classes={{root: classes.formLabel, focused: classes.focusedLabel}}>
              <span>Zusendung</span>
              {props.postboxDisabled && (
                <WarningTooltip width={400}
                                title={'Zusendung des Reports in das elektronische Postfach der Kunden nicht möglich,' +
                                  ' da mindestens einer der Kunden in dieser Gruppe nicht für die Nutzung des elektronischen' +
                                  ' Postfachs freigeschaltet ist.'}/>
              )}
            </FormLabel>
            <RadioGroup
              aria-label="Zusendung"
              name="distributionType"
              value={`${value}`}
              onChange={handleChange}
            >
              <FormControlLabel
                value={`${DISTRIBUTION_TYPES.POSTBOX.value}`}
                label={DISTRIBUTION_TYPES.POSTBOX.description}
                classes={{label: classes.formControlLabel}}
                control={
                  <Radio color="primary" classes={{root: classes.radio}}/>
                }/>
              <FormControlLabel
                value={`${DISTRIBUTION_TYPES.BRIEF.value}`}
                label={DISTRIBUTION_TYPES.BRIEF.description}
                classes={{label: classes.formControlLabel}}
                control={
                  <Radio color="primary" classes={{root: classes.radio}}/>
                }/>
            </RadioGroup>
          </FormControl>
        </div>
    )
}