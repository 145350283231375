import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  blocked: {
    pointerEvents: 'none'
  },
  groupHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 35,

    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column'
    },

    '& > p:first-child': {
      fontFamily: 'Roboto-Bold',
      fontSize: 14,
      color: '#626970',
      margin: 0
    },

    '& > p:nth-of-type(2)': {
      fontFamily: 'Roboto-Regular',
      fontSize: 14,
      color: '#313D44',
      margin: 0,
      marginLeft: 20,


      [theme.breakpoints.only('xs')]: {
        marginLeft: 0,
      },
    }
  },
  groupContent: {
    marginTop :15
  },
  addGroupButton: {
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    borderRadius: 2,

    '& i': {
      marginRight: 10
    }
  },
  addGroupSection: {
    marginTop: 15,
    padding: '15px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 2,
    border: '3px dashed #ABB6BE',

    '& > p': {
      color: '#313D44',
      fontFamily: 'Roboto-Regular',
      fontSize: 22,
      textAlign: 'center',
      padding: 16,
      margin: 0,
      [theme.breakpoints.only('sm')]: {
        fontSize: 20,
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: 18,
      },
    }
  },
  header: {
    fontFamily: 'Roboto-Regular',
    fontSize: 34,
    color: '#626970',

    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
    }
  }
})

export default makeStyles(styles);