import React from 'react';
import {
  Redirect,
  Route,
  withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import { PERMISSION_TYPE } from '../../utils/constants';
import {UserUtils} from "../../utils/utils";
import UnAuthorized from "../UnAuthorized";

import {getAuthSelector} from "../../utils/redaxSelectors";

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: props.location.pathname + props.location.search
    };
  }

  renderComponent = () => {
    const {
      component: DefaultComponent,
      auth,
      authenticationType,
      computedMatch: {
        params: {
          agency_id
        }
      },
      beta
    } = this.props;

    let renderComponent = <DefaultComponent {...this.props} />;
    const unAuthorizedComponent = <UnAuthorized {...this.props}/>;

    if (beta && !UserUtils.isBetaUser(auth)) {
      return <Redirect to="not-fount" />
    }

    switch(authenticationType) {
      case PERMISSION_TYPE.ANONYMOUS_ONLY:
        if (UserUtils.isAuthorized(auth)) {
          renderComponent = <Redirect to="not-fount" />
        }
        break;
      case PERMISSION_TYPE.AUTHORIZED_ONLY:
        if (!UserUtils.isAuthorized(auth)) {
          return unAuthorizedComponent;
        }
        break;
      case PERMISSION_TYPE.BROKER_ONLY:
        if (!UserUtils.isBroker(auth)) {
          return unAuthorizedComponent;
        }
        break;
      case PERMISSION_TYPE.CUSTOMER_ONLY:
        if (!UserUtils.isCustomer(auth)) {
          return unAuthorizedComponent;
        }
        if (!UserUtils.isAgencyValid(auth, agency_id)){
          return unAuthorizedComponent;
        }
        break;
      default:
        break;
    }

    return renderComponent;
  };

  render () {
    const { component, auth, ...others } = this.props;
    return (
      <Route {...others} render={this.renderComponent} />
    )
  }
}

PrivateRoute.propTypes = {
  /** Indicate, if route available only for users with 'beta' access */
  beta: PropTypes.bool
}

PrivateRoute.defaultProps = {
  beta: false
}

const mapStateToProps = (state) => ({
  auth: getAuthSelector(state),
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));