import React from 'react'
import {riskIndicatorsTableClasses} from "./styles";
import DashboardTable from "../../DashboardTable/DashboardTable";
import {
  riskIndicatorsTableStructure
} from "./table-data";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Collapse} from "@material-ui/core";


function RiskIndicatorInstrumentsTable({data, title, headerData, color, riskMetricsData, portfolio}) {
  const classes = riskIndicatorsTableClasses()
  const [expanded, setExpanded] = React.useState(true)

  const handleExpandChange = () => {
    setExpanded((currentValue) => !currentValue)
  }

  return (
    <div className={classes.riskIndicatorContainer}>
      {title && (
        <div className={classes.riskIndicatorDropdown} style={{backgroundColor: color}} onClick={handleExpandChange}>
          {title} {data && data.length && `(${data.length} ${data.length > 1 ? 'Positionen' : 'Position'})`}
          <ExpandMoreIcon color={"white"} style={{transform: `rotate(${expanded ? 180 : 0}deg)`}}/>
        </div>
      )}
      <Collapse in={expanded} timeout={300}>
        <div className={classes.riskIndicatorTable}>
          <DashboardTable
            structure={riskIndicatorsTableStructure}
            dataSource={data}
            tableClasses={classes}
            expanded={true}
            withFooter={false}
            options={{
              portfolio,
              headerData,
              riskMetricsData
            }}
          />
        </div>
      </Collapse>
    </div>
  )
}


function InstrumentsRiskIndicatorsTable({data, riskMetricsData, splitByRisk}) {

  const headerData = {
    trans_saldo_explanation: data.trans_saldo_explanation
  }

  const byRiskIndicator = React.useMemo(() => {
    return data.by_risk_indicator || {}
  }, [data])

  if (!splitByRisk) {
    return (
      <RiskIndicatorInstrumentsTable
        portfolio={data}
        data={data.components.filter((component) => !!component.isin)}
        headerData={headerData}
        riskMetricsData={riskMetricsData}
      />
    )
  }

  return Object.keys(byRiskIndicator).filter((riskIndicatorTitle) => {
    return byRiskIndicator[riskIndicatorTitle].components.length
  }).map((riskIndicatorTitle) => {
    return (
      <RiskIndicatorInstrumentsTable
        portfolio={data}
        data={byRiskIndicator[riskIndicatorTitle].components}
        color={byRiskIndicator[riskIndicatorTitle].color}
        headerData={headerData}
        title={riskIndicatorTitle}
        riskMetricsData={riskMetricsData}
      />
    )
  })
}

export default InstrumentsRiskIndicatorsTable