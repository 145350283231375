import { fromJS } from 'immutable';

import {
  GUIDE_TOUR_OPENED,
  GUIDE_TOUR_CLOSED,
  MENU_OPENED,
  MENU_CLOSED,
  SERIES_DROPDOWN_OPENED,
  SERIES_DROPDOWN_CLOSED,
  GUIDE_TOUR_MODAL_OPENED,
  GUIDE_TOUR_MODAL_CLOSED,
  GUIDE_TOUR_STEPS_DELETED,
  GUIDE_TOUR_STEPS_UPDATED,
  GUIDE_TOUR_LAST_STEP_INDEX,
  GUIDE_TOUR_UPDATE_TRIGGER,
  ANALYSE_DROPDOWN_OPENED,
  ANALYSE_DROPDOWN_CLOSED
} from "./constants";

const guideInitialState = fromJS({
  steps: [],
  open: false,
  active: false,
  lastStepIndex: 0,
  updateTrigger: ''
});

export function guideTourReducer(state = guideInitialState, action) {
  switch(action.type) {
    case GUIDE_TOUR_OPENED:
      return state.set('active', true).set('open', false);
    case GUIDE_TOUR_CLOSED:
      return state.set('active', false).set('open', true);
    case GUIDE_TOUR_MODAL_OPENED:
      return state.set('open', true);
    case GUIDE_TOUR_MODAL_CLOSED:
      return state.set('open', false);
    case GUIDE_TOUR_STEPS_UPDATED:
        return state.set('steps', fromJS(action.steps));
    case GUIDE_TOUR_STEPS_DELETED:
        return state.set('steps', fromJS([]));
    case GUIDE_TOUR_LAST_STEP_INDEX:
        return state.set('lastStepIndex', action.lastStepIndex);
    case GUIDE_TOUR_UPDATE_TRIGGER:
        return state.set('updateTrigger', action.selector);
    default:
      return state;
  }
}

const sideMenuInitialState = fromJS({
  opened: false
});

export function sideMenuReducer(state = sideMenuInitialState, action) {
  switch(action.type) {
    case MENU_OPENED:
      return state.set('opened', true);
    case MENU_CLOSED:
      return state.set('opened', false);
    default:
      return state;
  }
}

const seriesDropdownState = fromJS({
  opened: false
});

export function seriesDropdownReducer(state = seriesDropdownState, action) {
  switch(action.type) {
    case SERIES_DROPDOWN_OPENED:
      return state.set('opened', true);
    case SERIES_DROPDOWN_CLOSED:
      return state.set('opened', false);
    default:
      return state;
  }
}

const analyseDropdownState = fromJS({
  opened: false
});

export function analyseDropdownReducer(state = analyseDropdownState, action) {
  switch(action.type) {
    case ANALYSE_DROPDOWN_OPENED:
      return state.set('opened', true);
    case ANALYSE_DROPDOWN_CLOSED:
      return state.set('opened', false);
    default:
      return state;
  }
}
