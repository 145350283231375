export const MODELPORTFOLIO_CREATE_CONTAINER_TABS = {
  MAIN: 1,
  FILTERS_EXTENDED: 2,
  LIST: 3,
  REBALANCING: 4
}


export const MODEL_PORTFOLIO_WEIGHT = {
  AUTO: 1,
  MANUAL: 2,
  EQUAL: 3,
  RISK_BASED: 4
}


export const MODEL_PORTFOLIO_WEIGHT_OPTIONS = [
  {id: MODEL_PORTFOLIO_WEIGHT.AUTO, value: MODEL_PORTFOLIO_WEIGHT.AUTO, label: "Automatisch"},
  {id: MODEL_PORTFOLIO_WEIGHT.MANUAL, value: MODEL_PORTFOLIO_WEIGHT.MANUAL, label: "Manuell"},
  {id: MODEL_PORTFOLIO_WEIGHT.EQUAL, value: MODEL_PORTFOLIO_WEIGHT.EQUAL, label: "Gleichgewichtet"},
  {id: MODEL_PORTFOLIO_WEIGHT.RISK_BASED, value: MODEL_PORTFOLIO_WEIGHT.RISK_BASED, label: "Risikoparitätisch"},
];