import { createStyles } from '@material-ui/core'

export const styles = (theme) => createStyles({
  loadingContainer: {
    padding: '34px 54px 34px 24px',
    width: 500,
    minHeight: 128,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadingIndicator: {
    color: theme.palette.primary.main,
    marginRight: 10,
  },
});