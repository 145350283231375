import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  scBackButtonRoot: {
    backgroundColor: "white",
    height: 48,
    borderRadius: 8
  }
})

export default makeStyles(styles)