import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import FormLabel from '@material-ui/core/FormLabel'

import useStyles from './styles'

function LabelFormElement(props) {
  const {
    children,
    required,
    label,
    customClasses,
    error,
    style
  } = props;

  const classes = useStyles()

  return (
    <>
      <FormLabel required={required} error={!!error} classes={{
        root: clsx(classes.labelRoot, customClasses && customClasses.labelRoot),
        asterisk: classes.asterisk
      }} style={style || {}}>
        {label}
      </FormLabel>
      { children }
    </>
  )
}

LabelFormElement.propTypes = {
  children: PropTypes.node,

  /**Flag, that specifi, is field required, or not */
  required: PropTypes.bool,

  /** Form label */
  label: PropTypes.oneOfType(PropTypes.string, PropTypes.node).isRequired
}

export default LabelFormElement

