const styles = theme => ({
  background: {
    height: 3,
    width: 400,
    display: 'inline-block',
    backgroundColor: '#ADB6BD',
    verticalAlign: 'middle',
    position: 'relative',

    [theme.breakpoints.only('xs')]: {
      width: '100%'
    }
  },
  blueFill: {
    backgroundColor: '#0092E5',
    position: 'absolute',
    height: 5,
    top: -1,
    borderRadius: 2
  },
  greenMark: {
    width: 8,
    height: 17,
    position: 'absolute',
    backgroundColor: '#23D374',
    top: -7,
    borderRadius: 5
  },
  minValueLabel: {
    color: '#80858C',
    display: 'block',
    position: 'absolute',
    top: 5,
    left: 0
  },
  maxValueLabel: {
    color: '#80858C',
    display: 'block',
    position: 'absolute',
    top: 5,
    right: 0
  },
  greenMarkLabelText: {
    color: '#23D374',
    top: -20,
    position: 'absolute',
    left: -10,
    display: 'flex',
    whiteSpace: 'nowrap'
  },
  greenMarkLabelValue: {
    bottom: -20,
    fontFamily: 'Roboto-Bold',
    position: 'absolute',
    left: -20
  },
  blueLabel: {
    color: '#0092E5',
    fontFamily: 'Roboto-Bold',
    top: 20,
    display: 'block',
    position: 'absolute',
    whiteSpace: 'nowrap'
  },
  blueLabelLeft: {
    left: 0,
    transform: 'translateX(-50%)'
  },
  tooCloseLeft: {
    right: 0
  },
  blueLabelRight: {
    right: 0,
    transform: 'translateX(50%)'
  },
  tooCloseRight: {
    left: 0
  },
  helpIcon: {
    color: '#202a38',
    width: 18,
    height: 18,
    marginLeft: 3,
    transform: 'rotate(180deg)',
    verticalAlign: 'top',

    '&:hover': {
      cursor: 'pointer'
    }
  },
  benchmarkIcon: {
    display: 'inline-block',
    marginRight: 3
  }
})

export default styles;
