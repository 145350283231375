import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    padding: 20,
    background: 'white',
    borderRadius: 4,
    boxShadow: '0px 3px 15px #39435233',

    '&.container-inline': {
      padding: 0,
      boxShadow: 'none'
    }
    // alignItems: 'flex-start',

    // minHeight: 640,
  },

  warningMessageContainer: {
    alignItems: "center",
    justifyContent: "center",
  },

  warningMessage: {
    fontFamily: 'Roboto-Regular',
    color: '#202A38',
    fontSize: 20,
    margin: 0
  }
}))