import {
  RESET_KEY,
  SAVE_MODAL_KEY,
  SELECTED_CUSTOMERS_KEY,
  SELECT_FAKE_CUSTOMER_KEY,
  MARK_CUSTOMERS_KEY
} from "./constants";

export const resetCreateGroup = () => ({
  type: RESET_KEY
});

export const setSaveModal = (isModalVisible) => ({
  type: SAVE_MODAL_KEY,
  isModalVisible
});

export const setSelectedCustomers = (selectedCustomers) => ({
  type: SELECTED_CUSTOMERS_KEY,
  selectedCustomers
});

export const setMarkedCustomers = (selectedCustomers) => ({
  type: MARK_CUSTOMERS_KEY,
  selectedCustomers
});

export const selectFakeCustomer = () => ({
  type: SELECT_FAKE_CUSTOMER_KEY
});

export const openSaveModal = () => setSaveModal(true);

export const closeSaveModal = () => setSaveModal(false);

export const resetSelectedCustomers = () => setSelectedCustomers([]);