import {Dialog, DialogContent, DialogTitle, IconButton, TextField, withStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import styles from "./styles";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router";
import { buildCurrentCustomerVirtualPortfolioManager } from "../../../../routes";


class ModalPortfolio extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      portfolioTitle: '',
      redirect: null,
      portfolioNameError: undefined
    }

    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.createPortfolio = this.createPortfolio.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({
        portfolioTitle: '',
        portfolioNameError: undefined,
        loading: false,
        redirect: null,
        error: null
      })
    }
  }

  handleTitleChange(event) {
    const name = event.target.value

    const isNameExist = this.isPortfolioNameExist(name)

    this.setState({
      portfolioTitle: name,
      portfolioNameError: name.length === 0 || name.length > 30 || isNameExist,
      portfolioNameErrorMessage: isNameExist ? 'Der Depotname ist bereits vergeben.' : undefined
    })
  }

  isPortfolioNameExist(name) {
    let names = this.props.portfolios.map(portfolio => portfolio.name);
    return names.indexOf(name) !== -1;
  }

  createPortfolio() {
    this.setState({
      redirect: buildCurrentCustomerVirtualPortfolioManager('new')
    })
  }

  renderRedirect() {
    return <Redirect
      to={{
        pathname: this.state.redirect,
        state: {
          portfolioName: this.state.portfolioTitle,
          portfolio: {}
        }
      }}
    />
  }

  render() {

    const { classes } = this.props;
    if (this.state.redirect) {
      return (this.renderRedirect())
    } else {
      return (
        <Dialog maxWidth='md' fullWidth open={this.props.open} onClose={this.props.onClose}>
          <IconButton aria-label="close" classes={{root: classes.closeButton}} onClick={this.props.onClose}>
            <CloseIcon/>
          </IconButton>
          <DialogTitle className={classes.title}>
            Neues Virtuelles Depot erstellen
          </DialogTitle>
          <DialogContent className={classes.modalContent}>
            <div className={classes.labelsRow}>
              <label className={classes.inputLabel}>Name des virtuellen Depots</label>
              <span className={classes.helpText}>(max. 30 Zeichen)</span>
            </div>
            <TextField
              variant={'outlined'}
              onChange={this.handleTitleChange}
              error={this.state.portfolioNameError}
              helperText={this.state.portfolioNameErrorMessage}
            />
            <div className={classes.buttonsRow}>
              <Button
                className={clsx(classes.buttonCancel, classes.button)}
                disableRipple={true}
                onClick={this.props.onClose}
              >
                Abbrechen
              </Button>
              <PrimaryButton
                disabled={this.state.portfolioTitle.length === 0 || this.state.portfolioNameError}
                text={'Weiter'}
                classes={{button: classes.button}}
                onButtonClick={this.createPortfolio}
              />
            </div>
          </DialogContent>
          {this.state.loading &&
          <div className={classes.loadWrapper}>
            <CircularProgress color={'primary'}/>
          </div>}
        </Dialog>
      )
    }
  }
}

export default withStyles(styles)(ModalPortfolio);
