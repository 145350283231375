import React, { useEffect, useState, useRef }from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Document, Page, pdfjs } from 'react-pdf';
import {CircularProgress} from '@material-ui/core';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import useStyles from './styles';
import { useWindowSize } from '../../utils'

pdfjs.GlobalWorkerOptions.workerSrc = "/static/pdf.worker.js";

function DocumentEditor(props) {

  const classes = useStyles();

  const {
    saveDownloadAction,
    activeDocument,
    activeDocuments,
    documentsData,
    onAnnotationsLoaded,
    onRenderSuccess,
    activePage,
    scale
  } = props;

  const ref = useRef(null);
  const documentSize = useWindowSize(ref);

  const [contentPageCount, setContentPageCount] = useState({});
  const [redneredDocumentsCount, setRenderedDocumentsCount] = useState(0);
  const [documentWidth, setDocumentWidth] = useState(documentSize.width)
  const [documentsRendered, setDocumentsRendered] = useState({})

  useEffect(() => {
    activeDocuments.forEach(doc => documentsRendered[doc.id] = false)
    setDocumentsRendered(documentsRendered)
  }, [])

  useEffect(() => {
    // delete documentId from list of already rendered documents because he was closed
    _.keys(documentsRendered).map(id => !_.find(activeDocuments, {'id': id})? delete documentsRendered[id] : null)
    // add documentId to already rendered list with false flag that was changed on true after download/save trigger
    activeDocuments.map(doc => !_.get(documentsRendered, `${doc.id}`) ? documentsRendered[doc.id] = false : null)
    setDocumentsRendered(documentsRendered)
  }, [activeDocuments])

  useEffect(() => {
    setDocumentWidth(ref.current.offsetWidth)
  }, [documentSize]);

  const onDocumentLoadSuccess = ({numPages, document}) => {
    contentPageCount[document.id] = numPages.numPages
    setContentPageCount(contentPageCount);
    onRenderSuccess(activeDocument || document.id, undefined, contentPageCount, redneredDocumentsCount)
  };

  const handleAnnotationsLoaded = (documentId, annotations, pageNumber) => {
    if (saveDownloadAction && pageNumber == contentPageCount[documentId]) {
      documentsRendered[documentId] = true
      setDocumentsRendered(documentsRendered)
      if (redneredDocumentsCount < activeDocuments.length) {
        setRenderedDocumentsCount(redneredDocumentsCount => redneredDocumentsCount+1);
      }
    }

    if (onAnnotationsLoaded) {
      onAnnotationsLoaded(documentId, annotations, pageNumber, documentsRendered);
    }
  }

  const renderLoader = () => {
    return (<CircularProgress size={66} className={classes.loader}/>)
  }

  const renderPage = (number, documentId, activeDocument=undefined) => {
    return <Page
      key={number}
      renderMode="canvas"
      loading={null}
      pageNumber={number}
      renderTextLayer={true}
      onGetAnnotationsSuccess={annotations => handleAnnotationsLoaded(documentId, annotations, number)}
      onRenderSuccess={() => onRenderSuccess(activeDocument || documentId, number, contentPageCount, redneredDocumentsCount)}
      renderForms={true}
      renderAnnotationLayer={true}
      width={documentWidth}
      scale={scale}
    />
  }

  const renderDocumentPages = (documentId) => {
    if (!_.isNil(contentPageCount[documentId])) {
      let pages = [];
      for (let pageNumber = 1; pageNumber <= contentPageCount[documentId]; pageNumber++) {
        pages.push(
          <div key={`${documentId}_${pageNumber}`} style={{
              display: 'none',
              marginBottom: 20,
              boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.15)'
            }}
          >
            {renderPage(pageNumber, documentId)}
          </div>
        )
      }
      return pages;
    }
    return [];
  };

  const renderDocuments = (virtual=false) => {
    if (documentsData.data) {
      let documents = [];
      for (const [index, document] of Object.entries(activeDocuments)) {
        documents.push(
          <div
            className={classes.container}
            id={document.id}
            key={document.id}
            style={{
              display: activeDocument==document.id ? 'block' : 'none',
              overflowX: activeDocument==document.id && scale > 1 ? 'scroll' : 'hidden',
              display: activeDocument==document.id && scale > 1 ? 'block' : activeDocument==document.id ? 'flex' : 'none'
            }}>
            {documentsData.data[index] && documentsData.data[index].content && (
              <Document
                renderMode="svg"
                error="Die PDF-Datei konnten nicht geladen werden."
                loading={renderLoader}
                onLoadSuccess={(numPages) => { onDocumentLoadSuccess({numPages, document}) }}
                file={documentsData.data[index].content}
                options={{
                  cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                  cMapPacked: true,
                }}
              >
                {/* render page only for an active document to increase the performance */}
                {!virtual && document.id == activeDocument && renderPage(activePage, document.id, activeDocument)}
                {/* render pages for all documents to get their annotation */}
                {virtual && renderDocumentPages(document.id)}
              </Document>
            )}
          </div>
        )
      }
      return documents
    }
    return []
  }

  return (
    <>
      <div className={classes.editorContainer} ref={ref}>
        {renderDocuments()}
        {saveDownloadAction &&
          !Object.values(documentsRendered).every(isRendered => isRendered) &&
          <div style={{ display: 'none' }}>{renderDocuments(true)}</div>
        }
      </div>
    </>
  )
};

DocumentEditor.propTypes = {
  documentsData: PropTypes.any.isRequired,
  onAnnotationsLoaded: PropTypes.func,
  onRenderSuccess: PropTypes.func
};

export default React.memo(DocumentEditor);

