import {makeStyles} from "@material-ui/core";

const styles = theme => ({
  dialog: {
    maxWidth: 900
  },
  closeButton: {
    position: 'absolute',
    right: 0
  },
  modalContent: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38',
    paddingBottom: 16,
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  buttonsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20
  },
  button: {
    paddingTop: 4,
    paddingBottom: 4,
    textTransform: 'none',
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    marginLeft: 10,
    '&:first-child': {
      marginLeft: 0
    }
  },
  buttonCancel: {
    color: theme.palette.primary.main,
  },
  loadWrapper: {
    backgroundColor: 'white',
    opacity: 0.9,
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageContent: {
    '& .no-margin': {
      margin: 0
    }
  }
})

export default makeStyles(styles);