import React from "react";
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import styles from "../styles";  // common classes
import useStyles from "./styles"; // styles for depotStep table
import DashboardTable from "../../../../../../../../../components/DashboardTable/DashboardTable";
import {depotsTableStructure} from "./table-data";
import FormHelperText from "@material-ui/core/FormHelperText";

const DepotStep = props => {
  const {
    classes,
    onAnswerChange,
    questions: [
      depotsQuestion
    ],
    dataService
  } = props;

  const tableClasses = useStyles();

  const summaryStep = React.useMemo(() => {
    return dataService.getStep("product-selection-summary")
  }, []);

  const selectedDepotId = depotsQuestion.answer && depotsQuestion.answer.depot && depotsQuestion.answer.depot.id;
  const depotsOptions = depotsQuestion.options || [];

  const findDepot = (depotId) => {
    return depotsOptions.find(o => o.id == depotId);
  };

  depotsQuestion.isValid = () => {
    const answer = depotsQuestion.answer;
    if (_.isObject(answer.depot) && !!findDepot(answer.depot.id)){
      if(!!answer.depot.contract_types.find(contract => contract.value === answer.contract_type)){
        depotsQuestion.error = null;
        return true;
      } else {
        depotsQuestion.error = 'Bitte wählen Sie eine Vertragsart'
      }
    } else {
      depotsQuestion.error = 'Bitte wählen Sie eine Depot'
    }

    return false;
  };

  const onContractChange = (value) => {
    depotsQuestion.error = null; // clean error
    onAnswerChange(depotsQuestion.uid, {...depotsQuestion.answer, contract_type: value});
  };

  const onDepotChange = (event) => {
    handleDepotChange(event.target.value)
  };

  const handleDepotChange = (value) => {
    depotsQuestion.error = null; // clean error
    const depot = findDepot(value);
    onAnswerChange(depotsQuestion.uid, {depot: depot, contract_type: ''});

    // clean summary answers
    summaryStep && summaryStep.question.forEach(q => q.answer = null)
  };

  React.useEffect(() => {
    if(!depotsQuestion.answer){
      onAnswerChange(depotsQuestion.uid, {depot: null, contract_type: ''})
    }
  });

  React.useEffect(() => {
    if (!!selectedDepotId && !findDepot(selectedDepotId)){
      handleDepotChange(''); // clean answer as it's not in the options
    }
  }, []);

  return (
    <>
      {depotsQuestion.error && (
        <FormHelperText
          error={true}
          classes={{
            error: classes.errorContainer
          }}>{ depotsQuestion.error }</FormHelperText>
      )}
      <Grid container spacing={2}>
        <DashboardTable
          structure={depotsTableStructure}
          dataSource={depotsOptions}
          expanded={true}
          tableClasses={tableClasses}
          withFooter={false}
          options={{
            onDepotChange: onDepotChange,
            onContractChange: onContractChange,
            selectedDepotId: selectedDepotId,
            selectedContract: depotsQuestion.answer && depotsQuestion.answer.contract_type,
            selectedServiceConcept: dataService.serviceConcept,
            banksDataGrouped: dataService.banksDataGrouped
          }}
        />
      </Grid>
    </>
  );
};

export default withStyles(styles)(DepotStep);
