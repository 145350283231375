import React from 'react';
import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ResponsiveDialog from '../ResponsiveDialog/ResponsiveDialog';
import { ModalLoader } from '../../containers/VirtualPortfolioManager/modals/ModalInstruments';
import useStyles from './styles';


const BaseDialog = (props) => {
  const {
    title,
    handleClose,
    actions,
    customClasses,
    modalLoading,
    ...dialogProps
  } = props;

  const _classes = useStyles();


  return (
    <ResponsiveDialog {...dialogProps}>
      {!!title && (
        <DialogTitle className={clsx(_classes.dialogTitle, customClasses.dialogTitle)} disableTypography>
          {title}
          {!!handleClose && (
            <IconButton disableRipple aria-label="close" className={_classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent className={clsx(_classes.dialogContent, customClasses.dialogContent)}>
        {props.children}
      </DialogContent>
      {!!actions && (
        <DialogActions className={_classes.dialogActions}>
          {actions}
        </DialogActions>
      )}
      {modalLoading &&
        <ModalLoader withMsg={false}/>
      }
    </ResponsiveDialog>
  )
};


BaseDialog.defaultProps = {
  customClasses: {}
};

export default BaseDialog