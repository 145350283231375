import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles' 
import { CloseIcon } from '../../../../../images';
import theme from '../../../../../themes/mainTheme';
import clsx from 'clsx'

function Budges(props) {

  const {
    items,
    onDelete,
    withContainer,
    disabled
  } = props;

  const classes = useStyles()

  const handleDelete = (item) => () => {
    if (onDelete) {
      onDelete(item)
    }
  }

  const renderItems = () => {
    return items.map(item => (
      <span
        key={item.id}
        className={clsx(classes.budgeItem, disabled && classes.disabled)}
      >
          {item.budgeName || item.name}
          {!disabled && (
            <CloseIcon
              onClick={handleDelete(item)}
              color={theme.palette.primary.main}
              size={10}/>
          )}
      </span>
    ))
  }

  return withContainer ? (
    <div className={classes.container}>
      { renderItems() }
    </div>
  ) : renderItems()
}

Budges.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string
  }))
}

Budges.defaultProps = {
  withContainer: true
}

export default Budges

