import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  itemName: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38',
    margin: 0,
    padding: '5px 0',

    '&:hover': {
      backgroundColor: '#EEF0F2',
      cursor: 'pointer'
    }
  },

  loading: {
    padding: '2px 0',

    '&:first-child': {
      paddingTop: 20
    }
  },

  active: {
    color: '#202A38',
    fontFamily: 'Roboto-Bold',
    backgroundColor: '#D8DCDF'
  },

  container: {
    padding: '0px 20px 20px'
  },

  itemCountLabel: {
    fontFamily: 'Roboto-Bold',
    color: '#202A38',
    marginLeft: 8,
    display: 'inline-block',
    backgroundColor: '#CCE9FA',
    padding: '2px 8px',
    borderRadius: '50%'
  }
})

export default makeStyles(styles)