import React from "react";
import Table from "@material-ui/core/Table/Table";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableBody from "@material-ui/core/TableBody/TableBody";
import DocumentsTableHeaderRow from "./components/DocumentsTableHeaderRow";
import DocumentsTableRow from "./components/DocumentsTableRow";

const DocumentsTable = ({documents}) => {
  return (
    <>
      <Table>
        <TableHead>
          <DocumentsTableHeaderRow/>
        </TableHead>
        <TableBody>
          {documents.map(document => <DocumentsTableRow key={document.id} document={document}/>)}
        </TableBody>
      </Table>
    </>
  )
};

export default DocumentsTable;