import {makeStyles} from "@material-ui/core";

const styles = (theme) => ({
  container: {

  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& > span': {
      height: 30
    }
  }
})

export default makeStyles(styles);