const styles = theme => ({
  container: {},
  paginationWrapper: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paginationItem: {
    padding: 4
  },
  topMessage: {
    margin: '1rem 0 1rem 10px',
    fontSize: 16,
    fontFamily: 'Roboto-Bold'
  },
  word: {
    color: theme.palette.primary.main,
  },
  filterHeader: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#626970'
  },
});

export default styles;
