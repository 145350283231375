const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    color: '#313D44',
    fontSize: 16,
    paddingRight: 8
  },
  input: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: '#626970',
    width: 40,
    height: 36,
    padding: 0,
    margin: 0,
    outline: 'none',
    border: '1px solid #B1B1B1',
    borderRight: 'none',
    borderBottomLeftRadius: 2,
    borderTopLeftRadius: 2,
    boxSizing: 'border-box',
    textAlign: 'center',
    backgroundColor: 'white'
  },
  buttonWrapper: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: 'none',
    borderBottomRightRadius: 2,
    borderTopRightRadius: 2,
    boxSizing: 'border-box',
    width: 34,
    height: 36,
    border: `1px solid ${theme.palette.primary.main}`
  }
});

export default styles;