import React from 'react';

import useStyles from './styles';


const GroupDetails = ({group}) => {
  const classes = useStyles();

  const getCustomersInGroupMessage = () => {
    const count = group && group.customers && group.customers.length || 0;
    return `${count} Kunde${count > 1 ? 'n' : ''}`;
  };

  return (
    <div>
      <p className={classes.groupName}>{group.name}</p>
      <p className={classes.groupDetails}><b>{getCustomersInGroupMessage()}</b> in dieser Gruppe</p>
    </div>
  )
};

export default GroupDetails;