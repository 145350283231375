import React from "react";
import {Grid} from "@material-ui/core";
import DetailsLine from "./components/DetailsLine/DetailsLine";
import _ from 'lodash';
import useStyles from "./styles";


const backEndKeysWithLinesTitlesMapping = {
  eco_sustainability: 'Öko',
  esg: 'ESG',
  pai: 'PAI'
}

const EsgProfileMatchingDetails = (props) => {

  const {
    data
  } = props;

  const classes = useStyles(props);

  const renderCategoryLine = (categoryKey) => {

    let categoryData = data[categoryKey]

    // get current value
    let currentValue = categoryData.target_without_missing + categoryData.overweight
    // using proportion  'target - 100%, currentValue - x' we get current value in %
    let filled = 100 * currentValue / categoryData.target

    return (
      <DetailsLine
        // data
        topLeftLabel={backEndKeysWithLinesTitlesMapping[categoryKey]}
        topRightLabel={`${categoryData.target}%`}
        unitOfMeasure={'%'}
        filledPercentage={categoryData.overweight > 0 || (currentValue === 0 && categoryData.target === 0) ? 100 : filled} // if target is 0 and you have 0 -> line should be 100 % filled
        currentValue={currentValue}
        // flags
        isOverweighted={categoryData.overweight > 0}
        isUnderweighted={categoryData.missing > 0}
        // props that change styling
        backgroundHeight={20}
        borderRadius={10}
        customClasses={classes}
      />
    )
  }

  const renderNoEsgProfile = () => {
    return(
      <div className={classes.emptyDetails}>
        <div style={{marginBottom: 10}}> Nachhaltigkeitsprofil. </div>
        <div> Dieser Kunde verfügt über kein Nachhaltigkeitsprofil. </div> {/* Translated msg: This customer does not have a sustainability profile */}
      </div>
    )
  }

  return (
    <>
      {_.isNil(data) ? (
        <>{renderNoEsgProfile()}</>
        ) : (
          <Grid container style={{alignItems: 'center'}} className={classes.container}>
            {Object.keys(data).map(categoryName => <Grid item xs={12}>{renderCategoryLine(categoryName)}</Grid>)}
          </Grid>
        )

      }

    </>
  )
}

export default (EsgProfileMatchingDetails);
