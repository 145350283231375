
import { makeStyles } from '@material-ui/core';

export const styles = (theme) => ({
  benchmarksList: {
    '& .MuiGrid-item:first-child': {
      minWidth: 36,
      marginRight: 5,
      textAlign: 'right',
    }
  },
  switchWrapper: {
    alignItems: 'flex-start !important'
  },
  benchmarkConfigWrapper: {
    justifyContent: 'flex-start !important'
  },
  disabled: {
    filter: 'grayscale(1)',
    pointerEvents: 'none',
    cursor: 'initial'
  },
});

export default makeStyles(styles);