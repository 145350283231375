import React from 'react';
import useStyles from './styles';
import Dialog from "@material-ui/core/Dialog/Dialog";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const SuccessSendDialog = (props) => {
  const classes = useStyles();

  const {
    open,
    message,
    handleClose
  } = props;

  return (
    <Dialog open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">

      <div className={classes.dialogContent}>
        <div>
          <CheckCircleIcon className={classes.checkIcon} />
        </div>
        <div className={classes.message}>
          {message}
        </div>
      </div>

      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>

    </Dialog>
  )
};

export default SuccessSendDialog;