import { SET_PRODUCT_SELECTION_INVESTMENT_RECOMENDATION, SET_PRODUCT_SELECTION_SRRI } from './constants'

export const setProductSelectionInvestmentRecomendation = (investment_recomendation) => ({
  type: SET_PRODUCT_SELECTION_INVESTMENT_RECOMENDATION,
  investment_recomendation
})

export const setProductSelectionSRRI = (srri) => ({
  type: SET_PRODUCT_SELECTION_SRRI,
  srri
})