import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  label: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    color: '#202A38'
  },
  option: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38',
    position: 'relative',
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 20
  },
  icon: {
    height: 8,
    width: 8,
    color: '#202A38',
    marginRight: 15,
    position: 'relative',
    top: -2},
  inputContainer: {
    marginLeft: 32
  }
})

export default makeStyles(styles)