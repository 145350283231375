import _ from 'lodash';
import {
  InvestmentStrategiesResource,
  ModelPortfolioResource,
  PortfolioHandlerResource,
} from '../../utils/api';
import {
  executeIfPathExist,
  getInvestmentDynamicPath,
} from '../InvestmentPlatform/utils';
import { CHART_COLORS } from '../CustomerDashboard/constants';
import { getInstrName } from '../Modelportfolios/utils';
import {
  COULD_NOT_GET_PRODUCT_DETAILS,
  PRODUCT_TYPE,
} from './constants';
import moment from "moment";
import {getPortfolioMinStartOfInvestment} from "../../utils/utils";

export const redirectToProductsComparison = (history, routes, params, openInNewTab) => {
  const queryParams = new URLSearchParams(params).toString();
  executeIfPathExist(routes, 'PRODUCTS_COMPARISON', (path) => {
    const url = `/${getInvestmentDynamicPath()}${path}/?${queryParams}`;
    openInNewTab ? window.open(url) : history.push(url);
  });
};

export const redirectToProductsComparisonOverview = (routes, comparisonId) => {
  executeIfPathExist(routes, 'PRODUCTS_COMPARISON_NEW', path => {
    // Using magic number 3 due to importing issues.
    // 3 = PROCESS_TYPE.OPEN. We should always send process_Type in query params
    // cause it is required for "step back" functionality
    const queryParams = new URLSearchParams({process_type: 3}).toString();
    window.open(`/${getInvestmentDynamicPath()}${path.replace(':id', comparisonId)}/?${queryParams}`);
  }, ':id');
};

export const getProductName = (product) => {

  let name;

  if (_.isNil(product.data)) {
    name = _.get(product, 'configuration.name');
  } else {
    name = _.isArray(product.data)
      ? product.data.map((data) => getInstrName(data)).join(', ')
      : getInstrName(product.data);
  }

  return name || product.product_id;
};

export const getChartsSensitiveProductsData = (product) => ({
  name: getProductName(product),
  color: product.configuration.color,
  highlighted: product.configuration.highlighted,
  order: product.configuration.order,
  product_id: product.product_id,
});

const getCustomerPortfolios = async (customerId) => {

  const response = await PortfolioHandlerResource.getCustomerAppData(
    customerId, false, false, false,
    false, false, true, true);
  const errors = _.get(response, 'customer.errors');
  if (!_.isEmpty(errors)) {
    throw new Error(errors);
  }

  return _.get(response, 'customer.data.0.portfolios.portfolios') || [];
};

const findPortfolioProductDataFromResponse = (response, depotNumber) => {
  return _.find(response, (portfolio) => portfolio.depotNumber == depotNumber);
};

const handleModelPortfolioProductError = (product, error) => {
  product.dataLoadingError = {
    error,
  };
  if (typeof error === 'object' && error.status === 404) {
    product.dataLoadingError.notFound = true;
  }
};

const mapPortfolioProductsWithPortfolioData = (products, portfolios) => {

  const handleProductData = (product, depotId, valueSetterCallback) => {
    const data = findPortfolioProductDataFromResponse(portfolios, depotId);
    if (data) {
      valueSetterCallback(product, data);
    } else {
      product.dataLoadingError = {
        error: 'Unable to fing customer portfolio',
        notFound: true,
      };
    }
  };

  products.forEach((product) => {
    try {
      const depotIds = _.get(product, 'configuration.depot_id') || [];
      if (depotIds.length > 1) {
        product.data = [];
        depotIds.forEach((depotId) => {
          handleProductData(product, depotId, (_product, _data) => _product.data.push(_data));
        });
      } else {
        handleProductData(product, depotIds[0], (_product, _data) => {
          _product.data = _data;
        });
      }
    } catch (error) {
      product.dataLoadingError = {
        error,
      };
    }
  });
};

export const getPortfolioProductData = async (customerId, products) => {

  const productsCopy = _.cloneDeep(products);

  try {
    const portfolios = await getCustomerPortfolios(customerId);
    mapPortfolioProductsWithPortfolioData(productsCopy, portfolios);
  } catch (error) {
    productsCopy.forEach((product) => handleModelPortfolioProductError(product, error));
  }

  return productsCopy;
};

export const getProductCustomerId = (product, raiseException = true) => {
  const customerId = _.get(product, 'configuration.customer_id');
  if (raiseException && !customerId) {
    throw (`Product ${product.product_id} does not have valid customer id.`);
  }

  return customerId;
};

export const getPortfolioProductPortfolioIds = (product) => {
  if (!_.isArray(product.data)) {
    return [product.data.portfolioId];
  }

  return product.data.map((portfolio) => portfolio.portfolioId);
};

const getModelPortfolioProductDetails = async (product) => {

  const productCopy = { ...product };

  try {
    productCopy.data = {
      ...product.configuration,
      ...await ModelPortfolioResource.getModelPortfolioDetails(product.product_id),
    };
  } catch (error) {
    handleModelPortfolioProductError(productCopy, error)
  }

  return productCopy;
};

const getInvestmentStrategyProductDetails = async (product) => {

  const productCopy = { ...product };

  try {
    productCopy.data = {...product.configuration, ...await InvestmentStrategiesResource.at(`${product.product_id}/`).get()};
  } catch (error) {
    handleModelPortfolioProductError(productCopy, error)
  }

  return productCopy
}
export const getModelPortfolioLikeProductDetails = async (product) => {
  if (product.type === PRODUCT_TYPE.PRIVATE_INVESTMENT) {
    return getInvestmentStrategyProductDetails(product);
  }
  return getModelPortfolioProductDetails(product);
}

const mapAssetProductWithAssetData = (product, assetsInfo) => {
  try {
    // We use isin as a product id.
    // Asset info end point always return data for requested isin (even if it does not exist),
    // but isin will not be in response.
    // It means we can use search by isin to detect if end point gives us valid data for asset product.
    const data = _.find(assetsInfo, (info) => info.isin === product.product_id);
    if (data) {
      product.data = data;
    } else {
      product.dataLoadingError = {
        error: 'Unable to find asset info',
        notFound: true
      }
    }
  } catch (error) {
    product.dataLoadingError = {
      error
    }
  }
}

export const getAssetsProductsDetails = async (products) => {

  const productsCopy = _.cloneDeep(products);

  try {
    const isins = products.map((product) => product.product_id);
    const response = await ModelPortfolioResource.infoAssets(isins);

    productsCopy.forEach((product) => mapAssetProductWithAssetData(product, response));

  } catch (error) {
    productsCopy.forEach((product) => handleModelPortfolioProductError(product, error))
  }

  return productsCopy;

}

export const productExists = (product) => {
  return !_.get(product, 'dataLoadingError.notFound')
}

export const productDataLoadingFailed = (product) => {
  return !_.isNil(_.get(product, 'dataLoadingError.error')) && !product.dataLoadingError.hasOwnProperty('notFound')
}

const getProductNameQuoted = (product) => `"${getProductName(product)}"`;

export const buildNotFoundProductsDeleteConfirmationMessage = (notFoundProducts) => {
  return `Produkt${notFoundProducts.length > 1 ? 'e' : ''} 
      ${notFoundProducts.map(getProductNameQuoted).join(', ')} ${notFoundProducts.length > 1 ? 'sind' : 'ist'} nicht mehr verfügbar. 
      Möchten Sie ${notFoundProducts.length > 1 ? 'diese' : 'es'} vom Vergleich entfernen?`;
}

export const getProductIdsAsString = (listOfProducts, idPath='product_id', defaultId='', separator=',') => {
  return (listOfProducts || []).map(p => _.get(p, idPath, defaultId)).sort().join(separator)
}

export const updateTabsDataWithCurrentProductConfig = (data, products, setData) => {
    if(!_.isNil(data.data)) {
      let updatedData = {...data}
      products.map(p => {
        if(p.product_id in data.data){
          // updating data with current configuration
          updatedData.data[p.product_id] = {...updatedData.data[p.product_id], configuration: p.configuration}
        }
      })
      setData(updatedData)
  }
}

export const getProductsToUseAsDict = (productsToUse) => {
  return Object.fromEntries(productsToUse.map(p => [p.product_id, p]))
}
export const getProductsDataListRepresentation = (data) => {
  return {...data, data: _.orderBy(_.isObject(data.data) ? Object.values(data.data) : data.data, p => _.get(p, 'configuration.order'))}
}

// TODO: This function works fine right now, as maximum number of products = length of CHART_COLORS.
//  In case there will be more products - infinite loop will be caused. Need to pay attention on this
export const getProductColor = (index, disabledColors, colors=CHART_COLORS) => {

  let color = index >= colors.length - 1
    ? colors[index % colors.length]
    : colors[index];
  if (disabledColors && disabledColors.includes(color) && disabledColors.length !== colors.length) {
    color = getProductColor(index + 1, disabledColors);
  }

  return color
}


export const loadPortfolioProductsDetailsPromises = (products) => {

  const portfoliosProductsMapping = {};

  products.forEach((product) => {
    if (!portfoliosProductsMapping.hasOwnProperty(product.configuration.customer_id)) {
      portfoliosProductsMapping[product.configuration.customer_id] = []
    }
    portfoliosProductsMapping[product.configuration.customer_id].push(product);
  });

  return Object.keys(portfoliosProductsMapping).map((customerId) => {
    return getPortfolioProductData(customerId, portfoliosProductsMapping[customerId] || [])
  });

}

export const loadModelPortfolioProductsDetailsPromises = (products) => {
  return products.map(getModelPortfolioLikeProductDetails)
}


export const loadComparisonProductsDetails = async (comparison, modalContext, displayNotification) => {
  let promises = [];

  const [assetProducts, depotProducts, modelPortfolioLikeProducts] = comparison.products.reduce(([_assetP, _portfolioP, _modelPortfolioP], product) => {
    if (product.type === PRODUCT_TYPE.ASSET) {
      _assetP.push(product)
    } else if (product.type === PRODUCT_TYPE.CUSTOMER_PORTFOLIO) {
      _portfolioP.push(product)
    } else {
      _modelPortfolioP.push(product)
    }

    return [_assetP, _portfolioP, _modelPortfolioP]
  }, [[], [], []])

  if (assetProducts.length) {
    promises.push(getAssetsProductsDetails(assetProducts));
  }

  if (depotProducts.length) {
    promises = promises.concat(loadPortfolioProductsDetailsPromises(depotProducts));
  }

  if (modelPortfolioLikeProducts.length) {
    promises = promises.concat(loadModelPortfolioProductsDetailsPromises(modelPortfolioLikeProducts));
  }

  try {
    let allPromisesResults = await Promise.all(promises);
    let productsResult = _.flatten(allPromisesResults);

    if (_.some(productsResult, productDataLoadingFailed)) {
      throw COULD_NOT_GET_PRODUCT_DETAILS;
    }

    const notFoundProducts = _.filter(
      productsResult, (product) => !productExists(product));
    if (notFoundProducts.length && comparison.id) {
      if (await modalContext.confirm(buildNotFoundProductsDeleteConfirmationMessage(notFoundProducts), undefined, 'Nein', 'Fehler beim Laden der zu vergleichenden Produkte')) {
        try {
          const notFoundProductsIdentifiers = notFoundProducts.map((product) => product.id);
          await PortfolioHandlerResource.deleteComparisonProducts(comparison.id, notFoundProductsIdentifiers);
          productsResult = _.filter(productsResult, (product) => !notFoundProductsIdentifiers.includes(product.id));
          displayNotification('success', 'Produkt wurde erfolgreich vom Vergleich entfernt');
        } catch (error) {
          displayNotification('error', 'Fehler beim entfernen der Produkte vom Investmentvergleich');
        }
      }
    }

    return _.flatten(productsResult).map((product) => {
      if (!productExists(product)) {
        _.set(product, 'configuration.active', false);
      }
      return product
    })

  } catch (e) {
    displayNotification('error', COULD_NOT_GET_PRODUCT_DETAILS);
    return []
  }
}

export const buildProductIdUniq = (productId, productType) => [productId, productType].join('_');

export const hookDataSorted = (data) => data && _.sortBy(data, ['order'])

export const buildProductConfigurationChangedDeps = (products) => {
  return _.flatten(_.sortBy(products || [], (product) => product.configuration.order)
    .map((product) => {
      return [product.product_id, product.configuration.highlighted, product.configuration.order]
    })).join(',')
}

export const updateHookDataConfiguration = (data, products) => {

  if (_.isEmpty(products) || _.isEmpty(data.data)) {
    return data;
  }

  const dataCopy = _.cloneDeep(data.data);
  (dataCopy || []).forEach((item) => {
    const itemData = products.find((product) => product.product_id == item.product_id);
    if (!itemData) {
      return
    }
    item.highlighted = itemData.configuration.highlighted;
    item.order = itemData.configuration.order;
  });

  return {...data, data: dataCopy, updatedAt: +new Date()}
}

export const getProductsIdsUniqList = (products) => products
  .map((product) => buildProductIdUniq(product.product_id, product.type))

export const getProductBeginningDate = (product) => {

  if ([PRODUCT_TYPE.MUSTERDEPOT, PRODUCT_TYPE.MODEL_PORTFOLIO, PRODUCT_TYPE.PRIVATE_INVESTMENT].includes(product.type)) {
    return moment(product.data.tracking_start_date);
  }

  if (product.type === PRODUCT_TYPE.ASSET) {
    return moment(product.data.inception_date);
  }

  const data = _.isArray(product.data) ? product.data : [product.data];
  return getPortfolioMinStartOfInvestment(data);

}

export const getProductsBeginningDate = (products) => moment.min(products.map(getProductBeginningDate));