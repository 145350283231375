import React from 'react'

import {Skeleton} from '@material-ui/lab'

import useStyles from './styles'


export default function CollectionLoadingItem() {

  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.data}>
        <Skeleton />
        <Skeleton />
      </div>
      <div className={classes.actions}>
        <Skeleton />
        <Skeleton />
      </div>
    </div>
  )
}
