import React, { useState, useEffect } from "react";
import _ from 'lodash';
import moment from 'moment'
import {
  OPEN_MAPPEN,
  CLOSED_MAPPEN,
  EXPIRED_MAPPEN
} from './constants'

export const handleDocumentFields = (annotations, mappings, documentId, callback) => {

  let $documentContainer = document.getElementById(documentId);
  if (!$documentContainer) {
    return
  }

  annotations.forEach(annotation => {
    let inputSection = $documentContainer.querySelector(`section[data-annotation-id="${annotation.id}"]`);
    if (inputSection) {
      let input = inputSection.querySelector('input');
      if (!input) {
        input = inputSection.querySelector('textarea');
      }
      if (!input) {
        input = inputSection.querySelector('select')
      }

      if (input) {
          mappings.forEach(mapping => {
            if (mapping.document_field_name == annotation.fieldName) {
              callback(input, annotation, mapping);
            }
          });
      }
    }
  });

}

export const annotationForEach = ($documentContainer, annotations, documentId, callback) => {


  annotations.forEach(annotation => {
    let inputSection = $documentContainer.querySelector(`section[data-annotation-id="${annotation.id}"]`);
    if (inputSection) {
      let input = inputSection.querySelector('input');
      if (!input) {
        input = inputSection.querySelector('textarea');
      }
      if (!input) {
        input = inputSection.querySelector('select');
      }

      if (input) {
          
        callback(input, annotation);
      }
    }
  });
}

export const parseSelectFieldValue = (annot, value) => {
  if (Array.isArray(annot.fieldValue)) {
    if (!annot.fieldValue.every(obj => obj)) {
      value = undefined
    } else if (annot.fieldValue.filter(obj => ['þÿ', 'u0000'].includes(obj))) {
      value = annot.fieldValue.map(obj => obj.replace(/(þÿ)|[\u0000]+/ig, ""))[0]
    }
  }
  return value
}

export const parseRadioButtonFieldValue = (annotations, annot, value, saved, form_fields) => {
  // Helper function for checkboxes with radio behavior
  // to replace his fieldValue with the
  // exportValue of the selected one,
  // if there is exist, otherwise null.
  // This is necessary to work correctly as radio,
  // since some documents have a non-null('/Off',) fieldValue.
  if (annot.exportValue && _.get(form_fields, `export_values`, []).length > 0) {
    let selected = _.find(annotations, function (item) {
      return annot.fieldType === 'Btn' && annot.fieldName === item.fieldName && item.exportValue === annot.fieldValue
    })
    value = selected && selected.exportValue ? selected.exportValue : saved[annot.fieldName] || null
  }
  return value
}

export const getDocumentFieldValues = (activeDocuments, selectedDocumentsAnnotations, inputRestoreValues, documentsMeta) => {
  let fields = {}
  let mappings = [selectedDocumentsAnnotations, inputRestoreValues]
  mappings.forEach(obj => {
    if (obj) {
      for (const [documentId, pages] of Object.entries(obj)) {
        if (activeDocuments.filter(obj => obj.id == documentId)) {
          let document_form_fields = documentsMeta[documentId]
          if (!fields[documentId]) fields[documentId] = {}
          for (const [page, annotations] of Object.entries(pages)) {
            if (Array.isArray(annotations)) {
              for (const [i, annot] of Object.entries(annotations)) {
                let fieldName = annot.fieldName
                let value = annot.fieldValue
                if (annot.fieldType == 'Ch') {
                  value = parseSelectFieldValue(annot, value)
                } else if (annot.fieldType === 'Btn') {
                  value = parseRadioButtonFieldValue(annotations, annot, value, fields[documentId], document_form_fields.form_fields[annot.fieldName])
                }
                fields[documentId][fieldName] = value
              }
            } else {
              fields[documentId] = {...fields[documentId], ...annotations}
            }
          }
        }
      }
    }
  })
  return fields
}

export const disableCheckboxes = ($documentContainer, annotationsWithName) => {
  annotationsWithName.forEach(aWithName => {

    let $inputSection = $documentContainer.querySelector(`section[data-annotation-id="${aWithName.id}"]`);
    let $input = $inputSection.querySelector('input[type="checkbox"]')

    if ($input) {
      $input.classList.add('hide-pseudo')
      $input.checked = false
    }
  })
}

export const handleRadioButtons = (
    $documentContainer,
    annotation,
    annotations,
    saveValue=null,
    savedAnnotations=null
  ) => {

  const { fieldName } = annotation;
  const annotationsWithName = _.filter(annotations, a => a.fieldName == fieldName);

  if (annotationsWithName && annotationsWithName.length > 1) {

    annotationsWithName.forEach(aWithName => {
      let $inputSection = $documentContainer.querySelector(`section[data-annotation-id="${aWithName.id}"]`);
      let $input = $inputSection.querySelector('input[type="checkbox"]')

      if (!$input) {
        $input = $inputSection.querySelector('input[type="radio"]')
      }

      if ($input) {

        if (aWithName.hasOwnProperty('exportValue')) {
          $input.checked = savedAnnotations[aWithName.fieldName] == aWithName.exportValue
        } else if (aWithName.hasOwnProperty('buttonValue')) {
          $input.checked = savedAnnotations[aWithName.fieldName] == aWithName.buttonValue
        }

        $input.addEventListener('change', event => {

          let checked = event.target.checked

          disableCheckboxes($documentContainer, annotationsWithName)

          if (checked) {
            $input.classList.remove('hide-pseudo')
            $input.checked = checked
            annotations.map(a => {
              if (a.fieldName == aWithName.fieldName) {
                a.fieldValue = aWithName.exportValue || aWithName.buttonValue
              }
              return a
            })
          } else {
            annotations.map(a => {
              if (a.fieldName == aWithName.fieldName) {
                a.fieldValue = null
              }
              return a
            })
          }

          if (saveValue) {
            saveValue()
          }
        })
      }
    })

  }
}

export const useWindowSize = (refComponent) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: refComponent.current.offsetWidth,
        height: refComponent.current.offsetHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export const paginate = (array, page_size=10) => {
  let results = []
  for ( let page_number = 1; _.flatten(results).length < array.length; page_number++ ) {
    results.push(array.slice((page_number - 1) * page_size, page_number * page_size))
  }
  return results
}

export const filterCollections = (collections) => {
  let result = {}

  result[OPEN_MAPPEN] = paginate(collections.filter(obj => {
    return moment().diff(moment(obj.updated_at), 'days') <= 30 && obj.is_open
  }))
  result[CLOSED_MAPPEN] = paginate(collections.filter(obj => {
    return !obj.is_open
  }))
  result[EXPIRED_MAPPEN] = paginate(collections.filter(obj => {
    return moment().diff(moment(obj.updated_at), 'days') >= 30 && obj.is_open
  }))

  return result
}
