import React from 'react';
import PropTypes from 'prop-types';

import PortfolioItem from './components/PortfolioItem/PortfolioItem'

import useStyles from './styles'
import Button from "@material-ui/core/Button";

Portfolios.propTypes = {
  /* List of portfolios. */
  portfolios: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,

  /* Handler, that should be triggered, when selected portfolios submited */
  onSubmit: PropTypes.func
};

function Portfolios(props) {

  const {
    portfolios,
    onSubmit
  } = props

  const classes = useStyles()

  const [selectedPortfolios, setSelectedPortfolios] = React.useState([])

  const handleSubmit = () => {
    onSubmit && onSubmit(selectedPortfolios)
  }

  const handlePortfolioSelected = (portfolioId) => {
    let selectedPortfoliosCopy = [...selectedPortfolios]

    if (selectedPortfoliosCopy.includes(portfolioId)) {
      setSelectedPortfolios(selectedPortfoliosCopy.filter(id => id != portfolioId))
    } else {
      setSelectedPortfolios([...selectedPortfoliosCopy, portfolioId])
    }
  }

  return (
    <div className={classes.root}>
      { portfolios && portfolios.map(portfolio => (
        <PortfolioItem name={portfolio.name} portfolioId={portfolio.id} onSelect={handlePortfolioSelected}/>
      )) }
      <Button
        variant="contained"
        color="primary"
        className={classes.startButton}
        onClick={handleSubmit}
        disabled={selectedPortfolios.length === 0}
      >
        Fortfahren
      </Button>
    </div>
  );
}

export default Portfolios;