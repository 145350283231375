import React from "react";
import {brokerLogout, customerLogout} from "../../containers/Authentication/Auth/utils";

const UnAuthorized = props => {
  const {
    location: {
      pathname
    },
    auth: {
      user
    }
  } = props;

  const getAgencyId = () => {
    // todo need to find better solution, for now all customer routes start from agency_id
    // return Object.keys(ROUTES.CUSTOMER).some(key => ROUTES.CUSTOMER[key].path === path)
    const agency_id = pathname.split('/')[1];
    return typeof +agency_id === 'number' && +agency_id > 0 ? agency_id : null;

  };
  const redirectToLogin = () => {
    const agency_id = getAgencyId();
    if (agency_id) {
      customerLogout(agency_id)
    } else {
      brokerLogout()
    }
  };

  if (!user) {
    redirectToLogin();
  }

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center'
    }}>
      <h1>Bitte Login erneut durchführen</h1>
    </div>
  )
};

export default UnAuthorized;