import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  budgeItem: {
    display: 'inline-block',
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    padding: '5px 16px',
    margin: '4px 4px',

    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 20,

    transition: 'all',
    transitionDuration: 200,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(204, 233, 250, 0.3)'
    },

    '& > svg': {
      marginLeft: 5,
    }
  },
  disabled: {
    pointerEvents: 'none'
  }
})

export default makeStyles(styles)