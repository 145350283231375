import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  success: {
    backgroundColor: '#5ec14e',
  },
  error: {
    backgroundColor: '#f00',
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: '#e3a600',
  },
  icon: {
    // fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-line',
  },
  snackbarRoot: {
    flexWrap: 'nowrap',
    // alignItems: 'start',  // align close button top
  },
  snackbarAction: {
    paddingLeft: '2em',

    '& .MuiButtonBase-root': {
      padding: 2,
      border: '2px solid'
    }
  }
}));