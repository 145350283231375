import {makeStyles} from '@material-ui/core';

export default makeStyles(() => ({
  container: {},
  formLabel: {
    color: '#313D44',
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    marginBottom: 8
  },
  focusedLabel: {
    color: '#313D44 !important'
  },
  formControlLabel: {
    fontSize: 14
  },
  radio: {
    padding: 5,
    marginLeft: 4
  },
  keyboardDatePickerRoot: {
    width: 130,
    
    '& input': {
      fontSize: 14
    }
  },
  datePickerLabelItem: {
    marginLeft: -11,
    display: 'flex',
    alignItems: 'center'
  },
  datePickerLabel: {
    width: 36,
    paddingRight: 8,
  },
  disabledDatePickerLabel: {
    color: 'rgba(0, 0, 0, 0.38)'
  },
  helpIcon: {
    color: '#0092E5',
    width: 16,
    height: 16,
    marginLeft: -5,
    transform: 'rotate(180deg)',
    verticalAlign: 'middle',
    '&:hover': {
      cursor: 'pointer'
    }
  },
}))