const styles = theme => ({
  controlsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  container: {
    display: 'flex',
  },
  componentContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  chartContainer: {
    flexGrow: 1,
    width: '70%',
  },
  chartDataContainer: {
    display: 'flex',
    width: '30%',
    alignItems: 'start',
    paddingLeft: 10,
  },
  helpIcon: {
    color: '#202a38',
    width: 22,
    height: 22,
    marginLeft: 3,
    transform: 'rotate(180deg)',
    verticalAlign: 'middle',

    '&:hover': {
      cursor: 'pointer'
    }
  },
  legend: {
    listStyle: 'none',
    margin: 0,
    textAlign: 'right',
  },
  legendItem: {
    display: 'inline-block',
    marginRight: 30,
    '&:last-of-type': {
      marginRight: 10
    }
  },
  bullet: {
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 8
  },
  
  benchmarkWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  switchWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  benchmarkLabel: {
    fontSize: 14,
    color: '#4D4F5C',
    margin: 0
  },
  switchContainer: {
    marginTop: 5,
    width: 65,
    height: 24
  },
  thumb: {
    position: 'relative',
    top: 1,
    left: 1,
    width: 20,
    height: 20,
  },
  track: {
    width: '100%',
    borderRadius: 24 / 2,
    backgroundColor: '#b1b1b1',
    opacity: 1,
    transition: 'none',
  },
  checked: {
    transform: 'translateX(185%) !important'
  },
  labelSwitch: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: 'white',
    padding: '0 10px',
    userSelect: 'none'
  },
  list: {
    listStyle: 'none',
    width: '100%',
    margin: 0,
    padding: 0,
  },
  values: {
    width: '40%',
    textAlign: 'right'
  },
  greenLine: {
    color: 'green'
  },
  redLine: {
    color: 'red'
  },
  title: {
    fontFamily: 'Roboto-Bold',
    width: '60%'
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    padding: '7px 0',
    borderBottom: 'solid 1px #D8DCDF',
  },
  dividendsTable: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  dividendsTableHeader: {
    color: '#202A38',
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    marginTop: 10,
    width: '100%'
  },
  dividendsTableItem: {
    width: '100%',
    display: 'flex',
    marginTop: 0,
    marginRight: '2.2rem'
  },
  dividendsTableColumns: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  dividendsTableItemColor: {
    width: 24, height: 24,
    lineHeight: '21px',
    textAlign: 'center',
    marginTop: 10,
    borderRadius: '50%',
    backgroundColor: '#fff',
    borderWidth: 2,
    borderStyle: 'solid'
  },
  dividendsTableItemText: {
    height: 24, marginTop: 12, marginLeft: 10
  },
  dividendsTableItemDate: {
    display: 'inline-block',
    verticalAlign: 'top'
  },
  dividendsTableItemValues: {
    display: 'inline-block',
  },
  subline: {
    marginTop: 5,
  },
  description: {
    margin: 0,
    fontSize: 12,
    color: '#8D8D8D',
  },
})

export default styles;