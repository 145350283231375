const styles = theme => ({
  dialog: {
    maxWidth: 900
  },
  closeButton: {
    position: 'absolute',
    right: 0
  },
  title: {
    '& h2': {
      fontFamily: 'Roboto-Bold',
      fontSize: 20,
      color: '#202A38',
      paddingTop: 24
    }
  },
  modalContent: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38',
    paddingTop: 0,
    paddingBottom: 24
  },
  inputField: {
    '& input': {
      padding: 15,
    }
  },
  inputFieldSelect: {
    width: '100%',
    '& > div': {
      paddingTop: 15,
      paddingBottom: 15
    }
  },
  discountField: {
    '& input': {
      position: 'relative',
      '&:before': {
        content: '"%"',
        display: 'block',
        position: 'absolute'
      }
    }
  },
  radio: {
    paddingLeft: 0,
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main
    }
  },
  paddedValue: {
    paddingTop: 15,
    paddingBottom: 15,
    display: 'block'
  },
  textBold: {
    fontFamily: 'Roboto-Bold',
    paddingTop: 10,
    paddingBottom: 10,
    display: 'block'
  },
  paddedLabel: {
    paddingTop: 10,
    paddingBottom: 10,
    display: 'block'
  },
  explanation: {
    fontSize: 12,
    marginTop: 0,
    paddingTop: '0 !important',
    marginBottom: 10
  },
  unitsLabel: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold'
  },
  instrumentName: {
    margin: 0,
    fontFamily: 'Roboto-Bold',
    fontWeight: 'normal',
    marginBottom: 5
  },
  instrumentCodes: {
    margin: 0,
    fontWeight: 'normal',
    marginBottom: 10
  },
  inceptionDate: {
    margin: 0,
    fontFamily: 'Roboto-Bold',
    fontWeight: 'normal',
    marginBottom: 25
  },
  disabledLabel: {
    opacity: 0.5
  },
  modalFooter: {
    borderTop: '1px solid #ADB6BD',
    paddingTop: '20px !important',
    marginTop: 20
  },
  buttonSave: {
    position: 'relative',
    float: 'right'
  },
  loadWrapper: {
    backgroundColor: 'white',
    opacity: 0.9,
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0
  },
  errorWrapper: {
    color: '#f00',
    display: 'inline-block'
  },
  footer: {
    padding: 24,
    paddingTop: 0
  },
  benchmarkSlider: {
    display: 'inline-block',
    width: 'calc(25% - 20px)',
    marginRight: 20,
    verticalAlign: 'middle'
  },
  formItem: {
    marginBottom: 10,
    '&:last-of-type': {
      marginBottom: 0
    }
  },
  benchmarkSelector: {
    display: 'inline-block',
    width: 'calc(60% - 20px)',
    marginRight: 20,
    verticalAlign: 'middle',
    '& > *': {
      width: 'auto'
    }
  },
  benchmarkSelectorInput: {
    width: 'auto'
  },
  benchmarkWeightField: {
    display: 'inline-block',
    marginRight: 20,
    width: 'calc(15% - 44px)',
    "& input": {
      textAlign: 'right'
    }
  },
  totalLabel: {
    display: 'inline-block',
    marginRight: 20,
    width: 'calc(25% - 20px)',
    textAlign: 'right',
    fontSize: 14,
    fontFamily: 'Roboto-Bold'
  },
  totalValueLabel: {
    display: 'inline-block',
    marginRight: 20,
    width: 'calc(15% - 44px)',
    textAlign: 'right',
    paddingRight: 15
  },
  deleteButton: {
    verticalAlign: 'middle',
    minWidth: 'auto',
    padding: 0
  },
  headingLabel: {
    fontFamily: 'Roboto-Bold'
  },
  totalLabelError: {
    color: '#f00',
  },
  totalLabelCorrect: {
    color:'#5EC14E'
  },
})

export default styles;