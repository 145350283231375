import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_8034"
            data-name="Rechteck 8034"
            width="30"
            height="30"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_8152" data-name="Gruppe 8152" transform="translate(0 0)">
        <g
          id="Gruppe_8151"
          data-name="Gruppe 8151"
          transform="translate(0 0.001)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Pfad_7817"
            data-name="Pfad 7817"
            d="M30,7.091V0H17.7v7.09h1.758V28.243H17.231L14.813,24.57a7.9,7.9,0,0,0,1.067-11.019V7.091a3.516,3.516,0,0,0-7.032,0H7.09v3.968A7.906,7.906,0,0,0,4.64,24.57L2.222,28.243H0V30H30V28.243H28.242V7.091ZM4.169,21.153a6.18,6.18,0,0,1-.531-1.77H15.816a6.18,6.18,0,0,1-.531,1.77Zm9.859,1.758a6.141,6.141,0,0,1-8.6,0ZM12.363,5.333a1.76,1.76,0,0,1,1.758,1.758v4.852a7.868,7.868,0,0,0-1.758-.884V7.091H10.606a1.76,1.76,0,0,1,1.758-1.758M8.848,8.849h1.758v1.807a7.854,7.854,0,0,0-1.758,0Zm.879,3.516a6.161,6.161,0,0,1,6.089,5.274H3.637a6.161,6.161,0,0,1,6.089-5.274M6.1,25.546a7.9,7.9,0,0,0,7.249,0l1.776,2.7H4.326ZM19.453,1.759h8.789V5.333H19.453Zm7.031,10.606H22.969v1.758h3.516V15.88H22.969v1.758h3.516V19.4H22.969v1.758h3.516v3.516H21.211V7.091h5.274V8.849H22.969v1.758h3.516ZM21.211,26.427h5.274v1.817H21.211Z"
            transform="translate(0 -0.001)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
