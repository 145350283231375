import React from 'react'
import _ from 'lodash'

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import useStyles from './styles'

import PrimaryButton from '../../../../../../../../../../components/Buttons/PrimaryButton';
import DocumentsFilter from "../../../../../../../../../DocumentCenter/components/DocumentsFilter";


function DocumentsFilterModal(props) {
  const {
    objectType,
    bankId,
    banksData,
    open,
    activeDocuments,
    handleSwitch,
    handleConfirm
  } = props

  const classes = useStyles()

  const [selectedDocuments, setSelectedDocuments] = React.useState([])
  const [selectedActiveDocuments, setSelectedActiveDocuments] = React.useState([])

  React.useEffect(() => {
    setSelectedDocuments(activeDocuments || [])
  }, [activeDocuments])

  const onDocumentClick = (documents) => {
    let activeDocs = [...activeDocuments, ...selectedActiveDocuments]
    if (documents.length) {
      setSelectedDocuments(prevState => ([
        ...activeDocs,
        ...documents.filter(doc => !_.find(activeDocs, doc))
      ]))
    } else {
      setSelectedDocuments(activeDocs)
    }
  }
  
  const onDocumentsLoadClick = () => {
    setSelectedActiveDocuments(selectedDocuments.filter(doc => !_.find(activeDocuments, doc)))
  }

  const onDeleteDocument = (documentId) => {
    let updatedDocuments = selectedActiveDocuments.filter(doc => doc.id !== documentId)
    setSelectedActiveDocuments(updatedDocuments)
    setSelectedDocuments([...activeDocuments, ...updatedDocuments])
  }

  const onConfirmSelected = (bankId, documents) => {
    handleConfirm(bankId, documents, objectType)
    handleSwitch(bankId, objectType)
    setSelectedDocuments([])
    setSelectedActiveDocuments([])
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleSwitch(bankId)}
        fullWidth={true}
        scroll="body"
        maxWidth={'lg'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus
        className={classes.container}
        classes={{
          root: classes.root,
          paper: classes.paper
        }}
      >
        <DialogTitle id="alert-dialog-title" className={classes.modalTitle}>
          Dokument(e) hinzufügen
          <IconButton
            disableRipple aria-label="close"
            className={classes.closeButton}
            onClick={() => handleSwitch(bankId)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
            <>
              <DocumentsFilter
                  banksData={banksData}
                  handleDocumentClick={onDocumentClick}
                  handleDocumentsLoadClick={onDocumentsLoadClick}
                  activeDocuments={[...activeDocuments, ...selectedActiveDocuments]}
                  selectedDocuments={selectedDocuments}
                  confirmButtonText={'Hinzufügen'}
              />
              <div className={classes.filtersRoot}>
                {selectedActiveDocuments.length > 0 && selectedActiveDocuments.map(doc => {
                  return (
                    <div className={classes.documentItem} key={doc.id}>
                      <div>
                        <span title={doc.name}>{ doc.name }</span>
                      </div>
                      <div style={{marginLeft: "auto"}}>
                        <PrimaryButton
                          text={'Entfernen'}
                          onButtonClick={() => onDeleteDocument(doc.id)}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
          <DialogActions className={classes.filtersRoot}>
            <div className={classes.cofirmButton}>
              <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                classes={{
                  root: classes.loadDocsButton,
                  label: classes.loadDocsButtonText,
                }}
                onClick={() => onConfirmSelected(bankId, selectedActiveDocuments.map(doc => doc.id))}
              >
                Speichern
              </Button>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default DocumentsFilterModal
