const styles = theme => ({
  button: {
    height: '100%',
    width: '100%',
    borderRadius: 2,
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: 'Roboto-Bold',
    fontSize: 14,

    '& i': {
      marginRight: 5
    }
  }
});

export default styles;