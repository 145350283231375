import React from "react";
import {connect} from 'react-redux'

import Grid from "@material-ui/core/Grid/Grid";

import {executeIfPathExist, getInvestmentDynamicPath} from "../../../../../InvestmentPlatform/utils";
import CustomerSummaryCard from "../../../CustomerSummaryCard/CustomerSummaryCard";
import _ from 'lodash';


const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS()
});

const CustomerListItem = (props) => {
  const {
    customer,
    customerLoadingError,
    isOverview
  } = props;

  const handleCustomerClick = () => {
    executeIfPathExist(props.investmentPlatform.routes, 'RISK_INDICATOR_OVERVIEW', path => {
      let pathUpdated = path.replace(':customer_id', customer.customer_id)
      window.open(`/${getInvestmentDynamicPath()}${pathUpdated}`, '_blank')
    })
  }

  return (
    <Grid item xs={12}>
      <CustomerSummaryCard
        customer={customer}
        errors={customerLoadingError}
        productRiskValue={customer.product_srri}
        onClick={handleCustomerClick}
      >
        <CustomerSummaryCard.Contacts GridProps={{md: 4, xs: 12}}/>
        <CustomerSummaryCard.AggregatedValue GridProps={{md: 3, xs: 12}} onClick={handleCustomerClick}/>
        <CustomerSummaryCard.RiskIndicators
          GridProps={{md: 3, xs: 12}}
          indicatorClassName={'riskIndicator'}
        />
        <CustomerSummaryCard.EsgProfileMatchingDetails
          GridProps={{md: 2, xs: 12}}
          data={_.get(customer, 'esg_profile_matching')}
          indicatorClassName={'esgIndicator'}
        />
      </CustomerSummaryCard>
    </Grid>
  );
};

export default connect(mapStateToProps)(CustomerListItem);