const styles = theme => ({
  container: {
    marginTop: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  chartContainer: {
    width: '100%',
    marginBottom: 15
  },
  helpIcon: {
    color: '#202a38',
    width: 18,
    height: 18,
    marginLeft: 3,
    transform: 'rotate(180deg)',
    verticalAlign: 'top',

    '&:hover': {
      cursor: 'pointer'
    }
  },
  legend: {
    style: 'none',
    float: 'right',
    listStyle: 'none',
    marginTop: 0
  },
  legendItem: {
    display: 'inline-block',
    marginRight: 30,
    '&:last-of-type': {
      marginRight: 0
    }
  },
  bullet: {
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 8
  },
  controlsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'end',
    marginTop: -20
  },
  benchmarkWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  switchWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  benchmarkLabel: {
    fontSize: 14,
    color: '#4D4F5C',
    margin: 0
  },
  switchContainer: {
    marginTop: 5,
    width: 65,
    height: 24
  },
  thumb: {
    position: 'relative',
    top: 1,
    left: 1,
    width: 20,
    height: 20,
  },
  track: {
    width: '100%',
    borderRadius: 24 / 2,
    backgroundColor: '#b1b1b1',
    opacity: 1,
    transition: 'none',
  },
  checked: {
    transform: 'translateX(185%) !important'
  },
  labelSwitch: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: 'white',
    padding: '0 10px',
    userSelect: 'none'
  },
})

export default styles;