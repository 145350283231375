import React from "react";
import { withRouter } from "react-router";

import ProfitLossListItem from "./components/ProfitLossListItem";
import _ from "lodash";

const profitLossListPropsAreEquals = (prevProps, nextProps) => {
  return prevProps.data.timestamp === nextProps.data.timestamp
    && prevProps.expandedItems === nextProps.expandedItems
    && prevProps.expandedSubItems === nextProps.expandedSubItems
}


export default withRouter(React.memo((props) => {

  const {
    data,
    expandedItems,
    expandedSubItems,
    onExpandedItemsChange,
    onExpandedSubItemsChange
  } = props;

  const handleExpandedItemsChange = (id, expanded) => {
    if (expanded) {
      onExpandedItemsChange([...expandedItems, id]);
    } else {
      onExpandedItemsChange(expandedItems.filter(item => item !== id));
    }
  };

  const handleExpandedSubItemsChange = (portfolioId, items) => {
    onExpandedSubItemsChange({...expandedSubItems, [portfolioId]: {...(expandedSubItems[portfolioId] || {}), ...items}});
  };

  const renderPortfolios = () => {

    return data.data.map((item, index) => (
      <ProfitLossListItem
        key={item.id}
        data={item}
        isLast={index === data.data.length - 1}
        expanded={expandedItems.includes(item.id)}
        onExpanded={(expanded) => handleExpandedItemsChange(item.id, expanded)}
        expandedSubItems={_.get(expandedSubItems, `${item.id}`)}
        onExpandedSubItems={handleExpandedSubItemsChange}
      />
    ));
  };

  return (
    <>
      {renderPortfolios()}
    </>
  );
}, profitLossListPropsAreEquals))