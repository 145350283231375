import React from "react";
import clsx from "clsx";

import TextField from "@material-ui/core/TextField";

import {useStyles} from "../styles";

const MultilineInputFormElement = props => {
  const {
    label,
    required,
    value,
    disabled,
    onChange,
    rows,
    customClasses,
    placeholder,
    error,
  } = props;

  const classes = useStyles();

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <TextField
      label={label}
      required={required}
      disabled={disabled}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      error={!!error}
      helperText={error}
      rows={rows || 1}
      multiline
      classes={{
        root: classes.textFieldRoot
      }}
      InputProps={{
        classes: {
          root: clsx(classes.inputRoot, classes.inputMultilineRoot),
          focused: classes.inputFocused,
        }
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: clsx(classes.labelRoot, customClasses && customClasses.labelRoot),
          focused: classes.labelFocused,
          asterisk: classes.labelAsterisk
        }
      }}
    />
  )
};

export default MultilineInputFormElement;