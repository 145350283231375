const styles = theme => ({
  title: {
    fontFamily: 'Roboto-Bold',
    width: '75%',
    fontSize: '14px',
  },
  card: {
    marginTop: 40
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  item: {
    display: 'flex',
    padding: '.3rem 0',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    borderBottom: 'solid 1px #D8DCDF',
  },
  itemMark: {
    alignItems: 'center'
  },
  headline: {
    paddingBottom: 20,
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
  },
  values: {
    paddingRight: '.5rem',
    width: '25%',
    lineHeight: 1,
    textAlign: 'right',
  },
})

export default styles;
