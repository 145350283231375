const filter_panel_1 = {
  selector: '#agency-filter-tour-element',
  key: 'risk_dashboard_agency_filter'
};
const filter_panel_2 = {
  selector: '#risk-sort-tour-element',
  key: 'risk_dashboard_risk_sort'
};
const filter_panel_3 = {
  selector: '#risk-filter-tour-element',
  key: 'risk_dashboard_risk_filter'
};
const filter_panel_4 = {
  selector: '#search-filter-tour-element',
  key: 'risk_dashboard_search'
};

export const getFilterPanelChapterSteps = () => {
  return [
    filter_panel_1,
    filter_panel_2,
    filter_panel_3,
    filter_panel_4,
  ];
};
