import React from "react"
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "./styles";

const AddAssetButton = props => {
  const {
    classes,
    onButtonClick,
    text
  } = props;

  const default_text = 'Hinzufügen';

  const handleClick = event => {
    if (onButtonClick) {
      onButtonClick(event)
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.button}
      onClick={handleClick}
      id="add-other-assets-button-tour-element"
    >
      <i className={`fa fa-plus`} aria-hidden="true" />
      {text || default_text}
    </Button>
  )
};

export default withStyles(styles)(AddAssetButton);