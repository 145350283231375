const styles = (theme) => ({
  article: {
    borderWidth: 2,
    borderStyle: "solid",
    width: "100%",
    borderRadius: 5,
    display: "flex",
  },
  text: {
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
  },
  articleNO: {
    marginBottom: 0,
    fontSize: 18,
    fontFamily: "Roboto-Bold",
    lineHeight: "18px",
    color: "white",
    whiteSpace: "nowrap",
  },
  articleTitle: {
    margin: 0,
    color: "#4D4F5C",
    fontFamily: "Roboto-Bold",
  },
  empty: {
    borderRadius: 5,
    width: "51%",
  },
  empty6: {
    background:
      "transparent linear-gradient(180deg, #ADB6BD 0%, #727D88 100%) 0% 0% no-repeat padding-box;",
  },
  empty8: {
    background:
      "transparent linear-gradient(180deg, #BFE77E 0%, #8DBC40 100%) 0% 0% no-repeat padding-box;",
  },
  empty9: {
    background:
      "transparent linear-gradient(180deg, #57B25E 0%, #305F33 100%) 0% 0% no-repeat padding-box;",
  },
  tooltip: {
    position: "relative",
    padding: 20,
    backgroundColor: "#EFEFF2",
    color: "#4D4F5C",
    fontFamily: "Roboto-Regular",
    fontSize: 14,
    boxShadow: "0px 2px 15px 0px rgba(0,0,0, 0.2)",
    whiteSpace: "pre-line",
    maxWidth: 500,
  },
});

export default styles;
