import { Service } from "./service";
import { esgProfileStepsData , zeroProfileEsgData} from "./mock_esg_profile";
import _ from "lodash";
import { QuestionnairesHandlerResource } from "../../utils/api";
import {
  getStepAnswer
} from "./utils";
import {getOriginLink} from "../../utils/constants";
import React from "react";

export class ESGProfileService extends Service {
  constructor(
    uid,
    name,
    next_btn,
    success_text,
    getPrevStepAnswer
  ) {
    super(
      uid,
      name,
      next_btn,
      success_text,
      getPrevStepAnswer
    );
    this._esgProfiles = [];
    this.skipStepsIfNotInterested = [
      "esg-info-profile",
      "ecologically-sustainable",
      "esg",
      "pai",
      "summary"
    ]
  }

  get isInterestedInESG(){
    const menu = 'esg_profile';
    const step = 'esg-profile-selection-step';
    const q = "esg-profile-radio-uid";

    return (this.uid === menu ? this.getStepAnswer(step, q) : this._getPrevStepAnswer(menu, step, q));
  }

  get advisorChoice() {
    const menu = 'esg_profile';
    const step = 'esg-profile-selection-step';
    const q = "esg-advisor-type";

    return (this.uid === menu ? this.getStepAnswer(step, q) : this._getPrevStepAnswer(menu, step, q));
  }

  get esgProfiles() {
    return this._esgProfiles;
  }

  async __sendStepData(isFinished, step = undefined) {
    let isInterestedInESG = this.isInterestedInESG;
    let advisorChoice = this.advisorChoice;

    let stepToSend = step;
    if (!stepToSend) {
      stepToSend = this._step;
    }

    if (advisorChoice === 'no') {
      this.removeESGSteps()
    } else {
      if (isInterestedInESG === 'not-interested') {
        this.removeESGSteps()
      } else if (_.get(this._questionnaire, 'steps')) {
        this.addESGSteps()
      }
    }

    const {esg, pai, eco} = this.esgCardAnswer;

    if(!eco && !esg && !pai){
      this._questionnaire.steps = this._questionnaire.steps.filter(s=>s.uid !== "summary")
    }

    if (this.__onboarding_uid) {
      const stepAnswer = getStepAnswer(stepToSend, this.__isCustomAnswers);
      if (!!isFinished) {
        stepAnswer.finished = true;
      }

      if (this._step.uid === 'summary') {
        await QuestionnairesHandlerResource.at('set_esg_profile/').post({
          onboarding_uid: this.__onboarding_uid,
        });
      }

      stepAnswer.customer_id = this._customer_id;
      stepAnswer.onboarding_step_uid = this.__onboarding_step_uid;
      stepAnswer.onboarding_uid = this.__onboarding_uid;

      return await QuestionnairesHandlerResource.post(stepAnswer);
    }
  }

  removeESGSteps() {
    this._questionnaire.steps = this._questionnaire.steps.filter(s => {
      return !this.skipStepsIfNotInterested.includes(s.uid)
    })
  }

  addESGSteps() {
    let stepsUIDs = this._questionnaire.steps && this._questionnaire.steps.map(item => item && item.uid);
    let indexForInsert = stepsUIDs.indexOf('esg-profile-selection-step') + 1;
    this.skipStepsIfNotInterested.forEach(step => {
      if (!stepsUIDs.includes(step))
      this._questionnaire.steps.splice(indexForInsert, 0, this.__deepCopy(esgProfileStepsData).steps.find(s => s.uid === step));
      indexForInsert += 1
    })
  }

  get esgCardAnswer(){
    const esg = this.getStepAnswer('esg','cards');
    const pai = this.getStepAnswer('pai','cards');
    const eco = this.getStepAnswer('ecologically-sustainable','cards');

    return {esg, pai, eco}
  }

  async __setQuestionnaire(onboardingAnswers) {
    this._questionnaire = this.__deepCopy(esgProfileStepsData);

    // Add Profiles Selection for New process
    try {
      this._esgProfiles = await QuestionnairesHandlerResource.at('get-esg-profiles/').get({customer_id: this._customer_id}) || [];
    } catch (e) {
      console.error(e);
    }

    const {esg, pai, eco} = this.esgCardAnswer;
    if(!eco && !esg && !pai){
      this._questionnaire = this.__deepCopy(zeroProfileEsgData);
    }

    _.get(this._questionnaire, 'steps', []).map(step => {
      const stepUID = this._getStepUID(step.uid);

      // logic for 34f brokers
      if (_.get(this.user, 'bca_level_id') === '0' && stepUID === 'esg-profile-selection-step') {
        const esgProfileRadioQuestion = this.getQuestion(stepUID, 'esg-profile-radio-uid');
        if (esgProfileRadioQuestion) {
          esgProfileRadioQuestion.question_text = "Profilauswahl";
        }

      }
    });

    // FIXME: find better way set answers from db for the 1st step
    this._setDefaultAnswers(onboardingAnswers);
    // -> we need 1st step answers to check prefill from existing EsgProfile
    await this.prefillFromExistingProfile();

    // set answers from db
    this._setDefaultAnswers(onboardingAnswers);
  }

  async prefillFromExistingProfile(useExistingCallback) {
    if(this.advisorChoice !== "no"){
        const esgRadio = this.isInterestedInESG;
        if (["prefill", "select"].includes(esgRadio)){
          // get origin session
          const esgProfileGUID = this.getStepAnswer("esg-profile-selection-step", "esg-profile-select-uid");
          if(esgProfileGUID) {
            const answers = await QuestionnairesHandlerResource.at('get-esg-profile-details/').get({
              customer_id: this.customer_id, token: esgProfileGUID
            });
            if (!_.isEmpty(answers)) {
              // set ESG profile answers
              this._questionnaire.steps.map((step) => {
                // do NOT fill profile selection step
                if (step.uid !== 'esg-profile-selection-step') {
                  const stepAnswers = answers[step.uid] || [];
                  this._setDefaultQuestionsAnswers(step, stepAnswers);
                }
              })
            }

            if (esgRadio === "select" && useExistingCallback) {
              await useExistingCallback();
            }
          }
        }
      }
  }

  async nextStep(onlySendData=false) {
    const isEsgSelectionStep = this.step.uid === "esg-profile-selection-step";

    const res = await super.nextStep();

    if(isEsgSelectionStep){
      await this.prefillFromExistingProfile(async () => {
        // FIXME: quick solution - jump to summary. We should skip steps instead
        // go to "summary" step
        await this.goToStep("summary");
      })
    }

    return res;
  }

  get success_body() {
    return (
      <>
        Das Nachhaltigkeitsprofil {this.is_couple ? 'der Familie' : 'des Kunden'}  wurde erfolgreich gespeichert. Sie können diese
        Informationen auch unter <a href={getOriginLink(`/#/Kunden/${this._customer_id}/Dokumente`)} target="_blank">Dokumentenablage</a> ansehen.
      </>
    )
  }

  async confirmResult(data) {
    this._is_finished = true;
  }

}