import React, { Component } from 'react'
import { connect } from 'react-redux';
import _ from "lodash";

import {
  withStyles,
  Container,
  Grid
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { getRouteFullPathByComponent } from '../../InvestmentPlatform/utils'
import { styles } from './styles';
import Link from '../../../components/Link';
import { BackIcon, DownloadIcon, EditIcon, DeleteIcon } from '../../../images';

import {
  FavoriteListDetails
} from './components'
import {
  Widget,
  WidgetHeader,
  WidgetBody
} from '../../Modelportfolios/components'
import { FavoriteListResouce } from '../../../utils/api';
import { favoriteListDetailsTableStructure } from '../../Modelportfolios/components/InstrumentsList/table-data';
import DashboardTable from '../../../components/DashboardTable'
import { DeleteFavoriteListModal } from '../components'
import { displayErrorSnackBar, displaySuccessSnackBar } from '../../../components/SnackbarProvider/actions';
import { FAVORITE_LIST_DELETE_ERROR_MESSAGE, FAVORITE_LIST_DELETE_SUCCESS_MESSAGE } from '../constants'
import { executeIfPathExist, getInvestmentDynamicPath } from '../../InvestmentPlatform/utils'
import { modelPortfolioAccessible } from '../../Modelportfolios/utils'
import {downloadPdf} from "../../../utils/utils";
import {
  SelectableInstrumentsTable
} from "../../../components/SelectForProductsComparisonModal/SelectForProductsComparisonModal";
 
const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
  auth: state.get('auth').toJS(),
});


export default connect(mapStateToProps)(withStyles(styles)(class Overview extends Component {

  state = {
    favoriteList: {
      data: null,
      loading: true,
      errors: null,
      date: Date.now()
    },
    deleteFavoriteListModal: {
      open: false
    },
    investmentDynamicPath: getInvestmentDynamicPath(),
    pdfDownloading: {
      loading: false,
      errors: null
    },
    orderingColumn: null
  }

  componentDidMount() {
    this.fetchFavoriteListDetails();
  }

  fetchFavoriteListDetails = async () => {
    this.setState({
      favoriteList: {
        loading: true,
        data: null,
        errors: null,
        date: Date.now()
      }
    })
    
    try {
      let response;
      if (this.props.favoriteList){
        response = this.props.favoriteList;
      } else {
        let id = this.props.computedMatch.params.id;
        response = await FavoriteListResouce.getFavoriteListDetails(id)
      }

      // filters out assets if instrumentsToFilterOut were passed and response contains assets
      if(!_.isEmpty(this.props.instrumentsToFilterOut) && !_.isEmpty(_.get(response, 'assets', []))){
        response.assets = response.assets.filter(a => !this.props.instrumentsToFilterOut.includes(a.isin))
      }

      this.setState({
        favoriteList: {
          loading: false,
          data: response,
          errors: null,
          date: Date.now()
        }
      })
    } catch (errors) {
      this.setState({
        favoriteList: {
          loading: false,
          data: null,
          errors,
          date: Date.now()
        }
      })
    }
    
  }

  renderIfPathExist = (path, callback) => {
    if (this.props.investmentPlatform.routes) {
      let fullPath = getRouteFullPathByComponent(this.props.investmentPlatform.routes, path)

      if (fullPath) {
        return callback(fullPath)
      }
    }
  }

  renderBackLink = () => {

    return this.renderIfPathExist('FAVORITE_LIST', (path) => (
      <Link 
        title="Zurück zu Favoritenlisten"
        icon={<i class="fa fa-chevron-left" aria-hidden="true"></i>}
        underline
        onClick={() => this.props.history.push(`/${this.state.investmentDynamicPath}${path}`)}
      />
    ))

  }

  getAssetsDataSource = () => {
    if (this.state.favoriteList.loading) {
      return [{}, {}, {}, {}, {}, {}]
    }

    if (this.state.favoriteList.data && this.state.favoriteList.data.assets) {
      return _.orderBy(this.state.favoriteList.data.assets, ['disabledByFilter', (item) => item.name && item.name.toLowerCase()], ['desc', 'asc']);
    }

    return []
  }

  getAvailableAssets = () => {
    let assets = [].concat(...this.getAssetsDataSource());
    return assets.filter(asset => !asset.disabledByFilter);
  };

  handleDeleteButtonClick = () => {
    this.setState({
      deleteFavoriteListModal: {
        open: true
      }
    })
  }

  handleDeleteModalClose = () => {
    this.setState({
      deleteFavoriteListModal: {
        open: false
      }
    })
  }

  handleDeleteFavoriteList = async () => {
    try {
      let id = this.props.computedMatch.params.id;

      await FavoriteListResouce.deleteFavoriteList(id)

      this.renderIfPathExist('FAVORITE_LIST', (path) => (
        this.props.history.push(`/${this.state.investmentDynamicPath}${path}`)
      ))

      this.props.dispatch(displaySuccessSnackBar(FAVORITE_LIST_DELETE_SUCCESS_MESSAGE))

    } catch (errors) {
      this.props.dispatch(displayErrorSnackBar(FAVORITE_LIST_DELETE_ERROR_MESSAGE))
    }
  }

  renderNotFound = () => {
    const { classes } = this.props;

    return (
      <div className={classes.errorContainer}>
        <h1>404</h1>
        <p>Not found</p>
      </div>
    )
  }

  handleEditButtonClick = () => {
    executeIfPathExist(this.props.investmentPlatform.routes, 'FAVORITE_LIST_EDIT', path => {
      let pathUpdated = path.replace(':id', this.state.favoriteList.data.id)
      this.props.history.push(`/${this.state.investmentDynamicPath}${pathUpdated}`)
    })
  }

  handleAllSelected = () => {
    if (this.props.onSelectAll){
      const selected = this.props.selectedInstruments || [];
      let assets = this.getAvailableAssets();
      if (selected.length === assets.length) {
        assets = []; // unselect all
      }

      this.props.onSelectAll(assets)
    }
  };

  handleDownloadClick = async () => {
    let path = `${FavoriteListResouce.resourceUrl}${FavoriteListResouce.GET_FAVORITE_LIST_PDF.replace(':id', this.state.favoriteList.data.id)}`

    try {
      this.setState({
        pdfDownloading: {
          loading: true
        }
      })

      await downloadPdf(path, undefined, 'POST', undefined, {
        ordering_ascending: _.get(this.state.orderingColumn, 'ascending'),
        ordering_column_index: _.get(this.state.orderingColumn, 'index')
      })
    } catch (errors) {
      this.props.dispatch(displayErrorSnackBar('Fehler beim Herunterladen des Dokuments.'))
    } finally {
      this.setState({
        pdfDownloading: false
      })
    }
  }

  handleOrderingColumnChange = (orderingColumn) => {
    this.setState({orderingColumn})
  }


  render() {
    const { classes, viewOnly, isInstrumentsSelection } = this.props;

    const assets = this.getAssetsDataSource();

    // TODO: It is placed there due to some disabledByFilter handling. Need to place it out of component
    if (isInstrumentsSelection) {
      return (
        <Container style={{paddingBottom: 16}}>
          <SelectableInstrumentsTable
            container={this.state.favoriteList.data || {}}
            ContainerProps={{
              enabled: true,
              selectable: false,
              path: 'FAVORITE_LIST_OVERVIEW',
              checked: false,
              classes: {
                containerName: classes.containerName
              }
            }}
            instruments={assets}
            InstrumentsProps={{
              enabled: true,
              selected: this.props.selectedInstruments || [],
              handleSelectAll: !!this.props.onSelectAll ? this.handleAllSelected : null,
              handleSelect: this.props.onInstrumentSelect,
              tableStructure: favoriteListDetailsTableStructure,
              tableClasses: classes
            }}
          />
        </Container>
      )
    }

    return (
      <>
        <Container style={{paddingBottom: 16}}>
          { !viewOnly && this.renderBackLink() }
          <div className={classes.navigationContainer} style={{marginBottom: 10}}>
            {this.state.favoriteList.loading ? (
              <>
                <Skeleton style={{width: 300, height: 40}}/>
                <Skeleton style={{width: 250, height: 40}}/>
              </>
            ) : (
              <>
                {this.state.favoriteList.data && (
                  <>
                    <h1 className={classes.header}>{ this.state.favoriteList.data.name }</h1>
                    <PrimaryButton
                      text="Liste als PDF herunterladen"
                      icon={<DownloadIcon color="white"/>}
                      onButtonClick={this.handleDownloadClick}
                      disabled={this.state.pdfDownloading.loading}
                    />
                  </>
                )}
              </>
            )}
          </div>
          {!this.state.favoriteList.errors && (
            <div className={classes.subNavigationContainer}>
              {this.state.favoriteList.loading ? (
                <>
                  <Skeleton style={{width: 100, marginRight: 15}}/>
                  <Skeleton style={{width: 100}}/>
                </>
              ) : (
                <>
                  {
                    !viewOnly && modelPortfolioAccessible(this.state.favoriteList.data, this.props.auth) && (
                      <>
                        <span className={classes.subNavigationButton} onClick={this.handleEditButtonClick}>
                        <span></span>
                        <span><EditIcon /></span>
                        <span>Bearbeiten</span>
                      </span>
                      <span className={classes.subNavigationButton} onClick={this.handleDeleteButtonClick}>
                        <span></span>
                        <span><DeleteIcon /></span>
                        <span>Löschen</span>
                      </span>
                      </>
                    )
                }
                </>
              )}
            </div>
          )}
        </Container>
        <div className={classes.mainContentContainer}>
          <Container>
            {!this.state.favoriteList.errors && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FavoriteListDetails
                    loading={this.state.favoriteList.loading}
                    favoriteList={this.state.favoriteList.data}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Widget>
                    <WidgetHeader classes={classes.widgetHeader}>
                      <p className={classes.sectionHeader}>
                        Instrumente
                        {this.state.favoriteList.loading ? (
                          <Skeleton style={{width: 100, marginLeft: 10}}/>
                        ) : (
                          <>
                            {
                              this.state.favoriteList.data && this.state.favoriteList.data.assets && (
                                <span className={classes.instrumentsNumber} style={{marginLeft: 10}}> ( {this.state.favoriteList.data.assets.length} Instrumente)</span>
                              )
                            }
                          </>    
                        )}
                      </p>
                    </WidgetHeader>
                    <WidgetBody classes={classes.dashboardTableWithStickyHeader}>
                      {
                        (this.state.favoriteList.loading || (this.state.favoriteList.data && this.state.favoriteList.data.assets)) ? (
                          <DashboardTable
                            structure={favoriteListDetailsTableStructure}
                            dataSource={assets}
                            expanded={true}
                            tableClasses={classes}
                            withFooter={false}
                            onOrderingChange={this.handleOrderingColumnChange}
                            options={{
                              onAllSelect: !!this.props.onSelectAll ? this.handleAllSelected : null,
                              totalInstrumentsCount: this.getAvailableAssets().length,
                              onInstrumentSelect: this.props.onInstrumentSelect,
                              selected: this.props.selectedInstruments || [],
                              loading: this.state.favoriteList.loading,
                              //onDelete: this.handleModelPortfolioDelete
                            }}
                            stickyHeader={true}
                          />
                        ) : (
                          <div className={classes.assetsDataErrorContainer}>
                            <p>Produkt Stammdaten konnten nicht geladen werden.</p>
                          </div>
                        )
                      }
                    </WidgetBody>
                  </Widget>
                </Grid>
              </Grid>
            )}
            {this.state.favoriteList.errors && (
              <>
                {this.state.favoriteList.errors.status && this.state.favoriteList.errors.status == 404 && (
                  <>
                    { this.renderNotFound() }
                  </>
                )}
              </>
            )}
          </Container>
        </div>
        {!viewOnly && (
          <DeleteFavoriteListModal
            open={this.state.deleteFavoriteListModal.open}
            favoriteListName={this.state.favoriteList.data && this.state.favoriteList.data.name}
            onClose={this.handleDeleteModalClose}
            onAccept={this.handleDeleteFavoriteList}
          />
        )}
      </>
    )
  }
}))
