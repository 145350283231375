import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import clsx from "clsx";
import WarningTooltip from "../../../../../../../components/WarningTooltip";

class ExclusionCriteriaCard extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes, item } = this.props;
    return (
      <div
        className={clsx(classes.cardWrapper, !item.main && classes.border)}
        style={{ backgroundColor: !item.main && "#fff" }}
      >
        <div className={classes.contentWrapper}>
          <span>{item.icon}</span>
          <span
            className={classes.text}
            style={{ color: !item.main && "#4D4F5C" }}
          >
            {item.text}
          </span>
        </div>
        {item.info && (
          <span className={classes.info}>
            <WarningTooltip title={item.info} />
          </span>
        )}
        {item.main && (
          <div className={classes.arrowIcon}>
            {!this.props.listHide[item.value] ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ExclusionCriteriaCard);
