import React from "react";
import { withStyles } from "@material-ui/core";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CloseIcon from '@material-ui/icons/Close';

import styles from "./styles"

function MarkIcon({ value }) {
  return value
    ? <CheckCircleRoundedIcon htmlColor="#23d374" />
    : <CloseIcon htmlColor="#80858c" />;
}

export default withStyles(styles)(MarkIcon);
