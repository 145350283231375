import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import styles from "./styles";
import CommonAnalysisCard from "../../../VirtualPortfolioPage/components/AnalysisCard";
import TargetMarketWidget from "./items/TargetMarketWidget";
import RiskClassWidget from "./items/RiskClassWidget";


class TargetMarketTab extends React.Component {

  constructor(props) {
    super(props);
  }

  lines() {
    let data = {};
    let state = this.props.data.target_market || {};
    data.anlagertypen = [
      { 'title': 'Private Anleger', value: state.it_retail },
      { 'title': 'Professionelle Anleger', value: state.it_professional },
      { 'title': 'Geeignete Gegenpartei', value: state.it_eligible_counterparty },
    ]
    data.baseKnowledge = [
      { 'title': 'Basiskenntnisse', value: state.ex_basic },
      { 'title': 'Erweiterte Kenntnisse', value: state.ex_informed },
      { 'title': 'Umfangreiche Kenntnisse', value: state.ex_advanced },
    ]
    data.verlusttragfahighkeit = [
      { 'title': 'Keine Verluste', value: state.cl_none },
      { 'title': 'Verlust bis zum eingesetzten Kapital', value: state.cl_limited },
      { 'title': 'Verlust übersteigt das eingesetzte Kapital', value: state.cl_beyond_initial },
    ]
    data.consultingBusiness = [
      { 'title': 'Private Anleger', value: state.di_advice_retail },
      { 'title': 'Professionelle Anleger', value: state.di_advice_pro },
    ]
    data.investmentObjectives = [
      { 'title': 'Allgemeine Vermögensbildung/ -Optimierung', value: state.investment_objectives },
      { 'title': 'Überproportionale Teilnahme an Kursveränderungen', value: state.rp_growth },
    ]
    data.investmentHorizon = [
      { 'title': 'Sehr kurzfristig <1 Jahr', value: state.time_horizon <= 0 },
      { 'title': 'Kurzfristig 1-3 Jahre', value: state.time_horizon <= 1 },
      { 'title': 'Mittelfristig 3-5 Jahre', value: state.time_horizon <= 2 },
      { 'title': 'Langfristig >5 Jahre', value: state.time_horizon <= 3 },
    ]
    data.executionOnly = [
      { 'title': 'Private Anleger', value: state.de_only_retail },
      { 'title': 'Professionelle Anleger', value: state.de_only_pro },
    ]
    data.sri = state.sri;
    return data;
  }

  renderWidgets() {
    const data = this.lines();

    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <RiskClassWidget data={data.sri} title='Risikoklasse' />
          </Grid>
        </Grid>

        <Grid container spacing={4}>

          <Grid item xs={4} sm={4}>
            <TargetMarketWidget data={data.anlagertypen} title='Anlegertypen' />
            <TargetMarketWidget data={data.consultingBusiness} title='Beratungsgeschäft' />
            <TargetMarketWidget data={data.executionOnly} title='Execution Only' />
          </Grid>

          <Grid item xs={4}>
            <TargetMarketWidget data={data.baseKnowledge} title='Kenntnisse' />
            <TargetMarketWidget data={data.investmentHorizon} title='Anlagehorizont' />
          </Grid>

          <Grid item xs={4}>
            <TargetMarketWidget data={data.verlusttragfahighkeit} title='Verlusttragfähigkeit' />
            <TargetMarketWidget data={data.investmentObjectives} title='Anlageziele' />
          </Grid>

        </Grid>
      </>
    );
  }

  renderContent() {
    const { classes } = this.props;
    return <CommonAnalysisCard
      classes={{
        card: classes.root
      }}
      title={'Zielmarkt'}
      content={
        <div className={classes.container}>
          {this.renderWidgets()}
        </div>
      }
    />
  }

  render() {
    return <Grid container spacing={2}>
      <Grid item xs={12}>
        {this.renderContent()}
      </Grid>
    </Grid>
  }
}

export default withStyles(styles)(TargetMarketTab);
