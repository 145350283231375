import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="22.852"
      viewBox="0 0 30 22.852"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_7627"
            data-name="Rechteck 7627"
            width="30"
            height="22.852"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_7851" data-name="Gruppe 7851" transform="translate(0 0)">
        <g
          id="Gruppe_7850"
          data-name="Gruppe 7850"
          transform="translate(0 0)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Pfad_6679"
            data-name="Pfad 6679"
            d="M27.363,8.789A2.637,2.637,0,1,0,30,11.426a2.64,2.64,0,0,0-2.637-2.637m0,3.515a.879.879,0,1,1,.879-.879.88.88,0,0,1-.879.879M14.121,10.547a3.466,3.466,0,0,0,1.586-.383,5.115,5.115,0,0,0,1.93.383,5.473,5.473,0,0,0,1.985-.384,3.468,3.468,0,0,0,1.589.384,3.508,3.508,0,0,0,1.562-6.653,5.275,5.275,0,0,0-1.7-2.638A5.352,5.352,0,0,0,17.637,0a5.25,5.25,0,0,0-5.079,3.895,3.508,3.508,0,0,0,1.563,6.652M13.586,5.37l.507-.163.089-.525a3.5,3.5,0,0,1,3.454-2.923,3.578,3.578,0,0,1,3.512,2.923l.089.525.507.163a1.75,1.75,0,1,1-1.552,3.088l-.435-.311-.476.243a3.741,3.741,0,0,1-1.646.4,3.467,3.467,0,0,1-1.587-.4l-.476-.243-.435.311a1.74,1.74,0,0,1-1.017.331,1.75,1.75,0,0,1-.535-3.42M0,22.852H30V15.82H0Zm10.606-5.273H24.727v3.516H10.606Zm17.636,3.516H26.484V17.578h1.758ZM1.758,17.578h7.09v3.516H1.758Z"
            transform="translate(0 0)"
            fill="#80858c"
          />
        </g>
      </g>
    </svg>
  );
};
