import {PRODUCT_COMPARISON_SET_COMPARISON, PRODUCT_SELECTION_TIME_SELECTOR_DATE_CHANGED} from "./constants";

export const setProductsComparisonTimeSelectorDateChange = (change) => ({
  type: PRODUCT_SELECTION_TIME_SELECTOR_DATE_CHANGED,
  change
});

export const setProductComparison = (comparison, key) => ({
  type: PRODUCT_COMPARISON_SET_COMPARISON,
  key,
  comparison
})