import {
  ASSET_MODAL_OPENED,
  ASSET_MODAL_CLOSED,
  ASSET_MODAL_CONTENT_TYPE,
  ASSET_MODAL_MENU_DISABLED,
  ASSET_MODAL_MENU_ENABLED, ASSET_MODAL_TYPE
} from "./constants";

export const openAssetModal = () => ({
  type: ASSET_MODAL_OPENED
});

export const closeAssetModal = () => ({
  type: ASSET_MODAL_CLOSED
});

export const setAssetModalContentType = (contentType) => ({
  type: ASSET_MODAL_CONTENT_TYPE,
  contentType
});

export const setAssetModalContentTypeForTour = () => ({
  type: ASSET_MODAL_CONTENT_TYPE,
  contentType: ASSET_MODAL_TYPE.IMMOBILIEN
});

export const setAssetModalMenuTypeForTour = () => ({
  type: ASSET_MODAL_CONTENT_TYPE,
  contentType: ASSET_MODAL_TYPE.MENU
});

export const disableAssetModalMenu = () => ({
  type: ASSET_MODAL_MENU_DISABLED
});

export const enableAssetModalMenu = () => ({
  type: ASSET_MODAL_MENU_ENABLED
});

