import {Dialog} from "@material-ui/core";
import React from "react";
import withWidth, {isWidthDown} from "@material-ui/core/withWidth";


const ResponsiveDialog = withWidth()((props) => {
  const {width, children, ...dialogProps} = props;

  return (
    <Dialog fullScreen={isWidthDown('sm', width)} {...dialogProps}>
      {children}
    </Dialog>
  )
});

ResponsiveDialog.defaultProps = {
  maxWidth: 'lg',
  fullWidth: true,
};

export default ResponsiveDialog;