import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    color: '#202A38',
  },
  stickyNavigation: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  stickyButtonRoot: {
    borderRadius: 8,
    height: '50px !important',
    marginRight: 15,

    '&:last-child': {
      marginRight: 0
    }
  },
  stickyButtonDisabled: {
    backgroundColor: '#99D3F5 !important',
    color: 'white !important'
  },
  stickyButtonOutlinedDisabled: {
    border: '2px solid #00000033 !important',
    backgroundColor: 'white !important',
    color: '#00000033 !important'
  },
  stickyButtonOutlinedRoot: {
    borderRadius: 8,
    height: '50px !important',
    marginRight: 15,
    backgroundColor: 'white !important'
  }
})

export default makeStyles(styles)