const styles = (theme) => ({
  container: {
    position: 'relative',
    marginTop: 34,
    marginBottom: 34,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap-reverse',
    },

    '&::before': {
      content: "''",
      position: 'absolute',
      bottom: theme.spacing(1),
      left: theme.spacing(1),
      right: theme.spacing(1),
      height: 2,
      background: '#ABB6BE',
    }
  },
  tabs: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  tabButton: {
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 44,
    position: 'relative',
    background: '#ABB6BE',
    color: '#FFF',
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    textAlign: 'center',
    userSelect: 'none',
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      padding: '0 10px',
      fontSize: 16
    },

    [theme.breakpoints.down('xs')]: {
      padding: '0 4px',
      fontSize: 14,
      width: '50%'
    }
  },
  active: {
    background: '#FFF',
    color: theme.palette.primary.main,

    '& ::before': {
      content: "''",
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 4,
      backgroundColor: theme.palette.primary.main
    },

    '& ::after': {
      content: "''",
      position: 'absolute',
      top: '100%',
      left: '50%',
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: `10px solid ${theme.palette.primary.main}`,
      transform: 'translateX(-50%)'
    }
  }
});

export default styles;
