import React from 'react';

import useStyles from './styles';
import {CircularProgress} from "@material-ui/core";

function LoadingIndicator({}) {

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CircularProgress />
    </div>
  )
}

LoadingIndicator.propTypes = {}

LoadingIndicator.propDefs = {}

LoadingIndicator.defaultProps = {}

export default LoadingIndicator;