import React, {Component} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx'

import { withStyles } from '@material-ui/core'

import { styles } from './styles'

class List extends Component {

  handleItemClick = (event) => {
    if (this.props.onValueChange) {
      this.props.onValueChange(event.currentTarget.getAttribute('data-id'))
    }
  }

  render() {

    const {
      items,
      classes,
      value,
      withBorder
    } = this.props

    let borderStyle = {
      borderRight: '1px solid #D8DCDF',
      borderBottom: 'none'
    }

    if (withBorder) {
      borderStyle = {
        border: '1px solid #D8DCDF',
        borderBottom: 'none'
      }
    }

    return (
      <div className={classes.container}>
        {items.map(item => (
          <div className={clsx(classes.item, item.id == value && classes.active)} style={{...borderStyle}} key={item.id} data-id={item.id} onClick={this.handleItemClick}>
            <span>{ item.label }</span>
          </div>
        ))}
      </div>
    );
  }
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.number.isRequired,
  onValueChange: PropTypes.func
};

export default withStyles(styles)(List);
