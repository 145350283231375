import React from "react";
import _ from 'lodash';

import withStyles from "@material-ui/core/styles/withStyles";

import PrimaryButton from "../../../../../../../components/Buttons/PrimaryButton";
import styles from "../styles";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import useFetchData from "../../../../../../../hooks/useDataFetch";
import {ESignResource} from "../../../../../../../utils/api";
import {CircularProgress, Container} from "@material-ui/core";
import {BusinessCase} from "../../../../../../LegalDocumentsSigningStatus/components";

const CongratsStep = props => {
  const {
    classes,
    uid,
    name,
    help_text,
    questions,
    loading,
    onPrevClick,
    onResendResultClick,
    onConfirmResultClick,
    onAnswerChange,
    nextStepBtnText,
    successBody,
    dataService
  } = props;

  const [businessCaseData, fetchBusinessCases] = useFetchData(
    `${ESignResource.resourceUrl}${ESignResource.GET_ONBOARDING_BUSINESS_CASES.replace(
      ':onboarding_id', dataService.onboarding_uid)}`, 'get')

  React.useEffect(() => {

    fetchBusinessCases()

  }, [])

  return (
    <>
      <div className={classes.congratsContent}>
        <Grid container direction={"column"} spacing={3}>
          <Grid item>
            <span className={clsx('fa', 'fa-check-circle', classes.congratsCheck)} />
            <span className={classes.congratsHeader} data-id={`${uid}-name`}>{name}</span>
          </Grid>
          <Grid item className={classes.congratsBody}>
            {successBody}
          </Grid>
          <Grid item>
            {businessCaseData.loading ? (
              <div className={classes.loadingContainer} style={{height: '100%'}}>
                <CircularProgress className={classes.loadingIndicator}/>
              </div>
            ) : (
              <PrimaryButton
                text={nextStepBtnText}
                onButtonClick={onConfirmResultClick}
              />
            )}
          </Grid>
        </Grid>
      </div>

      {dataService.uid === 'protocol' && businessCaseData.data && !_.isEmpty(businessCaseData.data) && (
        <Container className={classes.businessCasesContainer}>
          <Grid container spacing={3}>
            { businessCaseData.data.map((businessCase) => (
              <Grid item xs={12}>
                <BusinessCase businessCase={businessCase} bordered/>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}

    </>
  );
};

export default withStyles(styles)(CongratsStep);
