export default () => ({
  radioSelectQuestionContainer: {},
  radioSelectQuestionLabel: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold'
  },
  dropdownGroup: {
    '& .MuiInput-root': {
      borderRadius: 0,
    },
    '& .MuiGrid-item:first-child': {
      '& .MuiInput-root': {
        borderRadius: '4px 0 0 4px',
      }
    },
    '& .MuiGrid-item:last-child': {
      '& .MuiInput-root': {
        borderRadius: '0 4px 4px 0',
      }
    },
  }
})