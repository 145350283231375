import {makeStyles} from '@material-ui/core'

const styles = (theme) => ({
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    color: '#202A38',
    margin: 0,
    padding: 0
  },
  loadingContainer: {
    width: '100%',
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  previewButton: {
    color: theme.palette.primary.main,
    transition: 'all',
    transitionDuration: 250,

    '&.highlight': {
      backgroundColor: '#CCE9FA'
    }
  },

  fade: {
    '&-enter': {
      opacity: 0,
      transform: 'translateX(100%)'
    },
    '&-exit': {
      opacity: 1,
      transform: 'translateX(0%)'
    },
    '&-enter-active': {
      opacity: 1,
      transform: 'translateX(0%)',
      transition: 'opacity 500ms, transform 500ms'
    },
    '&-exit-active': {
      opacity: 0,
      transform: 'translateX(-100%)',
      transition: 'opacity 500ms, transform 500ms'
    },
  }
})

export default makeStyles(styles)