import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Grid } from '@material-ui/core'

import { toGermanFormat } from '../../../../../../utils/numberFormater'

import useStyles from './styles'
import Tooltip from "@material-ui/core/Tooltip";

function ExAnteAverageLine(props) {

  const {
    title,
    numbersTooltip,
    euroValue,
    percentValue,
    averageValue,
    divider,
    bold
  } = props

  const classes = useStyles()

  return (
    <Grid item xs={12} style={{margin: bold ? '20px 0 8px' : '0'}}>
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}> <span className={clsx(classes.label, bold && classes.textBold)}> { title } </span> </Grid>
        { !divider && (
          <>
            <Grid item xs={2}> <span className={clsx(classes.label, classes.alignRight, bold && classes.textBold)}> { (percentValue || percentValue == 0) ? toGermanFormat(percentValue * 100, undefined, ' %') : '' } </span> </Grid>
            <Grid item xs={3}> <span className={clsx(classes.label, classes.alignRight, bold && classes.textBold)}> { (euroValue || euroValue == 0) ? toGermanFormat(euroValue, undefined, ' €') : '' } </span> </Grid>
            <Grid item xs={3}>
              <span className={clsx(classes.label, classes.alignRight, bold && classes.textBold)}>
                {numbersTooltip ? (
                  <Tooltip
                    classes={{tooltip: classes.tooltip}}
                    title={numbersTooltip}
                    placement={"top"}
                    arrow>
                    <span>{ (averageValue || averageValue == 0) ? toGermanFormat(averageValue, undefined, ' €') : '' }</span>
                  </Tooltip>
                ) : (
                  <>{ (averageValue || averageValue == 0) ? toGermanFormat(averageValue, undefined, ' €') : '' }</>
                )}
              </span>
            </Grid>
            <Grid item xs={1}></Grid>
          </>
        ) }
      </Grid>
    </Grid>

  )
}

ExAnteAverageLine.propTypes = {
  /** Line title */
  title: PropTypes.string.isRequired,

  /** Value in EUR */
  euroValue: PropTypes.number.isRequired,

  /** AVG Value in EUR */
  averageValue: PropTypes.number.isRequired,

  /** Value in % */
  percentValue: PropTypes.number.isRequired
}

export default ExAnteAverageLine

