import React from "react";
import {TradeDetailsMenuItem} from "../Trades/components/TradeDetailsMenuItem";
import {combinedTradeStepsData} from "../Trades/trade_constants";
import {
  END_DATE_SHOULD_BE_AFTER_START,
  isWeekend,
  SWITCH_SALDO_NEGATIVE,
  SWITCH_SALDO_POSITIVE,
  TRANSACTION_TYPE_VALUES,
  TRANSACTION_VALUE_NOT_SELECTED, validateDate
} from '../Trades/constants';
import _ from "lodash";
import {
  DATE_MUST_BE_IN_PAST,
  FIELD_REQUIRED_MSG,
  WEEKENDS_IS_NOT_AVAILABLE_MSG,
  WRONG_DATE_FORMAT_MSG
} from "../RiskProfiling/constants";
import moment from "moment/moment";
import {scrollToFirstElementOfClass} from "../RiskProfiling/utils";
import {getInstrumentMinDate, getInstrumentMinSavingPlanDate, getMaxBookingDate, MIN_DATE_ERROR_MSG} from "./constants";
import {portfolioTransactionAmount} from "../Trades/utils";
import {MODEL_PORTFOLIO_WEIGHT} from "../Modelportfolios/Create/constants";
import {VIRTUAL_PORTFOLIO_QTY_DECIMALS} from "../../utils/utils";


export const getDummyDataservice = (customerId) => {
  return new TradeDetailsMenuItem('trade', 'Handeln details', customerId, combinedTradeStepsData, false);
}

const NO_PRICE_DATA_FOR_SELECTED_DATE = 'Keine Preisdaten zum gewählten Buchungdatum verfügbar';  // no price data is available
const NO_PRICE_DATA = 'Keine Preisdaten';  // no price data is available

const validateTransaction = (transactionToValidate, transactionType) => {
  // Function to validate transactions that are not payment plan

  let isValid = true;
  // validate value/amount
  if (!_.get(transactionToValidate,'transaction_value')){
    transactionToValidate.errors = {...transactionToValidate.errors, transaction_value: TRANSACTION_VALUE_NOT_SELECTED};
    isValid = false
  }



  const isSavingsPlan = transactionType === TRANSACTION_TYPE_VALUES.SAVINGS_PLAN;
  let minDate, fromDateField, noPriceMSG;
  if (isSavingsPlan) {
    minDate = getInstrumentMinSavingPlanDate(transactionToValidate);
    fromDateField = 'from_date';
    noPriceMSG = NO_PRICE_DATA;

    // check selected periodicity
    if (!_.get(transactionToValidate, 'rotation')){
      transactionToValidate.errors = {...transactionToValidate.errors, rotation: FIELD_REQUIRED_MSG};
      isValid = false
    }
  } else {
    minDate = moment(getInstrumentMinDate(transactionToValidate, transactionType));
    fromDateField = 'deposit_from_date';
    noPriceMSG = NO_PRICE_DATA_FOR_SELECTED_DATE;
  }

  // validate if price is available at selected date
  if (!_.get(transactionToValidate, 'data.price_eur')){
    transactionToValidate.errors = {...transactionToValidate.errors, asset_price: noPriceMSG};
    isValid = false
  }

  // validate from date
  let fromDate = _.get(transactionToValidate, fromDateField);

  let errorMessage = validateDate(fromDate, [{
    func: (date) => !date,
    args: [],
    error: FIELD_REQUIRED_MSG
  }, {
    func: (date) => !date instanceof moment || !date.isValid(),
    args: [],
    error: WRONG_DATE_FORMAT_MSG
  },{
    func: (date) => date.isBefore(minDate, 'day'),
    args: [],
    error: MIN_DATE_ERROR_MSG
  }, {
    func: (date) => date.isAfter(getMaxBookingDate(), 'day'),
    args: [],
    error: DATE_MUST_BE_IN_PAST
  }, {
    func: isWeekend,
    args: [],
    error: WEEKENDS_IS_NOT_AVAILABLE_MSG
  }]);

  if (!!errorMessage) {
    transactionToValidate.errors = {...transactionToValidate.errors, [fromDateField]: errorMessage};
    isValid = false
  }

  if (isSavingsPlan && transactionToValidate.till_date){
    errorMessage = validateDate(transactionToValidate.till_date, [{
      func: (date) => !date instanceof moment || !date.isValid(),
      args: [],
      error: WRONG_DATE_FORMAT_MSG
    }, {
      func: (date) => !date.isAfter(fromDate, 'day'),
      args: [],
      error: END_DATE_SHOULD_BE_AFTER_START
    }]);

    if (!!errorMessage) {
      transactionToValidate.errors = {...transactionToValidate.errors, till_date: errorMessage};
      isValid = false
    }
  }

  // SWITCH transaction contains list of BUY transactions inside -> validate them too
  if (transactionType === TRANSACTION_TYPE_VALUES.SWITCH){

    let nestedBuyTransactionsCombinedValue = 0;
    transactionToValidate.buy.forEach(nestedBuyTransaction => {
      // nested transactions happen at the same day as parent
      nestedBuyTransaction.deposit_from_date = transactionToValidate.deposit_from_date;
      // if at least one nested buy transaction is not valid - set isValid false
      if (!validateTransaction(nestedBuyTransaction, TRANSACTION_TYPE_VALUES.BUY)){
        isValid = false
      }

      nestedBuyTransactionsCombinedValue += (nestedBuyTransaction.transaction_value || 0 )
    });
    nestedBuyTransactionsCombinedValue = _.round(nestedBuyTransactionsCombinedValue, VIRTUAL_PORTFOLIO_QTY_DECIMALS)

    // validation for saldo (check that the amount you sell is equal to the amount you buy)
    if (transactionToValidate.transaction_value > nestedBuyTransactionsCombinedValue){
      transactionToValidate.errors = {...transactionToValidate.errors, buy: SWITCH_SALDO_POSITIVE}  // did not buy enough
      isValid = false
    } else if (transactionToValidate.transaction_value < nestedBuyTransactionsCombinedValue) {
      transactionToValidate.errors = {...transactionToValidate.errors, buy: SWITCH_SALDO_NEGATIVE}  // bought too much
      isValid = false
    }
    else {
      // clears error msg
      transactionToValidate.errors = {...transactionToValidate.errors, buy: null}
    }
  }

  return isValid
};

export const validateTransactions = (portfoliosTransactions, updatePortfoliosTransactions, containerId, isNewVirtual=false) => {
  let isValid = true;
  let portfolios = [...portfoliosTransactions];

  portfolios.forEach(portfolio => {

    portfolio.trading_errors = {};

    // map over transactions keys to check transactions of each portfolio
    [TRANSACTION_TYPE_VALUES.BUY, TRANSACTION_TYPE_VALUES.SELL, TRANSACTION_TYPE_VALUES.SWITCH, TRANSACTION_TYPE_VALUES.SAVINGS_PLAN].forEach(transactionsKey => {
      _.values(portfolio.transactions[transactionsKey] || []).forEach(transaction => { 
        if(transaction.action !== 'delete' && !validateTransaction(transaction, transactionsKey)) {
          isValid = false;
        }
      });

      const totalAmountValidationRequired = isNewVirtual
        && transactionsKey == TRANSACTION_TYPE_VALUES.BUY
        && !_.isEmpty(portfolio.transactions[transactionsKey])
        && portfolio.transactionsWeightType != MODEL_PORTFOLIO_WEIGHT.AUTO;
      if (!totalAmountValidationRequired) return;

      const transactionsValueTotal = _.round(portfolioTransactionAmount(portfolio, transactionsKey), 2);

      if (portfolio.portfolioValueTotal != transactionsValueTotal) {
        _.set(portfolio, 'trading_errors.portfolio', 'Angegebene Anlagesumme stimmt nicht mit Musterdepotwert überein.');
        isValid = false;
      }

    });
  });

  updatePortfoliosTransactions(portfolios);

  !isValid && scrollToFirstElementOfClass(containerId) // scrolls to first element with error

  return isValid
}