import React, {useState} from "react";
import connect from "react-redux/lib/connect/connect";
import _ from "lodash";
import {
  useChartsSettings,
  useGeneralPortfolioData
} from '../ModelPortfolioDashboardDataProvider/ModelPortfolioDashboardDataProviderV2'
import {InvestmentStrategiesResource, ModelPortfolioResource} from "../../utils/api";
import useFetchData from "../../hooks/useDataFetch";
import {PERFORMANCE_TIME_TYPE} from "../../utils/constants";

const withInvestmentStrategyDashboardData = WrappedComponent => connect()((props) => {

  let dataProvider = ModelPortfolioResource;

  const investmentStrategyId = props.investmentStrategy ? props.investmentStrategy.id : _.get(props, 'computedMatch.params.id');

  const [investmentStrategyData, fetchInvestmentStrategy, setInvestmentStrategyData] = useFetchData(
    `${InvestmentStrategiesResource.resourceUrl}${investmentStrategyId}/`, 'get');

  const [selectedDates, setSelectedDates] = React.useState(PERFORMANCE_TIME_TYPE.BEGINNING.getDateRange());
  const [selectedDatesType, setSelectedDatesType] = useState(PERFORMANCE_TIME_TYPE.BEGINNING);
  const {chartsSettings, setChartsSettings, handleShowChartSection, handleChartSettingsChange } = useChartsSettings();

  const modelPortfolioId = _.get(investmentStrategyData, 'data.related_model_portfolio.id');
  const modelPortfolioConnectId = _.get(investmentStrategyData, 'data.related_model_portfolio.connect_portfolio_id');

  React.useEffect(() => {
    if (!!props.investmentStrategy) {
      setInvestmentStrategyData(props.investmentStrategy)
    } else {
      fetchInvestmentStrategy(undefined, !!props.investmentStrategy);
    }
  }, []);

  React.useEffect(() => {
    if (investmentStrategyData.data) {
      const benchmarks = (investmentStrategyData.data.benchmark || [])
            .map((benchmark) => ({...benchmark, percentage: +benchmark.percentage}));

      setChartsSettings((currentSettings) => ({
        ...currentSettings,
        performance: {
          ...currentSettings.performance,
          withBenchmark: !_.isEmpty(benchmarks),
          currently_selected_benchmarks: benchmarks
        }
      }))
    }
  }, [investmentStrategyData.updatedAt]);

  const {
    breakdownData,
    profitAndLoss,
    instrumentList,
    dataLoading
  } = useGeneralPortfolioData(dataProvider, modelPortfolioId, selectedDates);
  const {
    investmentData
  } = useGeneralPortfolioData(dataProvider, modelPortfolioId, selectedDates, investmentStrategyId);

  const handleSelectedDatesChanged = (dates, _datesType) => {
    setSelectedDates(dates);
    setSelectedDatesType(_datesType);
  };

  return(
    <WrappedComponent
      investmentStrategyId={investmentStrategyId}
      investmentStrategyData={investmentStrategyData}
      // data
      investmentData={investmentData}
      breakdownData={breakdownData}
      profitAndLoss={profitAndLoss}
      instrumentList={instrumentList}
      isMounted={!investmentData.loading}
      calculationDates={selectedDates}
      chartsSettings={chartsSettings}
      dataProvider={dataProvider}
      customerData={{customer_id: modelPortfolioId}}
      // simulate structure form customer-app-data
      selectedPortfolios={
        modelPortfolioConnectId ? (_.get(investmentData, 'data.portfolios') || []).map(ptf => (
          {
            ...ptf,
            depotNumber: ptf.id,
            depotNumberForAssets: modelPortfolioConnectId
          }
        )) : []
      }

      // flags
      dataLoading={dataLoading}
      // functions
      onSelectedDatesChanged={handleSelectedDatesChanged}
      handleShowChartSection={handleShowChartSection}
      onChartSettingsChange={handleChartSettingsChange}
      // other props
      {...props}
    />
  )

});

export default withInvestmentStrategyDashboardData;
