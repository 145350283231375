import React from 'react';

function Economy({}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.027"
      height="38.919"
      viewBox="0 0 35.027 38.919"
    >
      <path
        id="Pfad_7850"
        data-name="Pfad 7850"
        d="M13.264,38.919a1.192,1.192,0,0,1-1.181-1.2V34.728L8.4,35.085q-.2.019-.4.019a4.294,4.294,0,0,1-2.923-1.147A4.451,4.451,0,0,1,3.653,30.7V28.269l-2-.869a2.748,2.748,0,0,1-1.5-1.632,2.806,2.806,0,0,1,.179-2.224l3.324-6.219v-.958C3.653,7.6,10.212.418,18.584.017Q18.961,0,19.336,0A15.487,15.487,0,0,1,30.167,4.407a16.142,16.142,0,0,1,.607,22.464V37.719a1.192,1.192,0,0,1-1.181,1.2Zm15.148-2.4V26.385a1.22,1.22,0,0,1,.347-.849,13.708,13.708,0,0,0-.222-19.39,13.156,13.156,0,0,0-9.2-3.744c-.213,0-.428.005-.642.015-7.11.34-12.68,6.468-12.68,13.951V17.63a1.213,1.213,0,0,1-.144.574L2.4,24.691a.359.359,0,0,0-.023.289.353.353,0,0,0,.194.211L5.3,26.372a1.2,1.2,0,0,1,.717,1.1V30.7a2,2,0,0,0,.646,1.484,1.932,1.932,0,0,0,1.514.513l4.977-.482c.038,0,.074-.005.11-.005a1.171,1.171,0,0,1,.8.313,1.213,1.213,0,0,1,.386.888v3.111Zm-9.953-9.131a1.142,1.142,0,0,1-.467-.1H17.98l-.05-.022a11.075,11.075,0,0,1-6.322-9.722,10.271,10.271,0,0,1,1.372-5.076s0,.007-.006.007a.131.131,0,0,1,.024-.06l.017-.029.05-.053a11.826,11.826,0,0,1,4.865-4.511l.037-.019.024,0a1.1,1.1,0,0,1,.935,0h.012l.051.022a11.432,11.432,0,0,1,6.019,7.308l.016.071a9.834,9.834,0,0,1,.287,2.343,11.076,11.076,0,0,1-6.322,9.722l-.037.019-.025,0a1.135,1.135,0,0,1-.469.105m-3.88-6.9a8.831,8.831,0,0,0,2.7,3.517V22.075Zm5.061,3.517a8.289,8.289,0,0,0,3.309-6.471c0-.094-.005-.187-.013-.28l-3.3,1.935ZM17.278,19.3V16.309L14.53,14.7a7.647,7.647,0,0,0-.556,2.666Zm2.362-2.883,2.773-1.628a8.823,8.823,0,0,0-2.773-3.722Zm-2.362-2.883V11.069a9.378,9.378,0,0,0-1.541,1.563Z"
        transform="translate(0)"
        fill="#fff"
      />
    </svg>
  )
}

Economy.propTypes = {}

Economy.propDefs = {}

Economy.defaultProps = {}

export default Economy;