import {
  ALERT_PRODUCT_TYPE,
  ALERT_PRODUCT_TYPE_TEXT,
  FIELDS_TO_CHECK_OPTIONS,
} from '../RiskDashboard/components/AlertConfigurationModal/utils';

export const ALL_SELECTED_OPTION_VALUE = 0;
export const ALL_SELECTED_OPTION_LABEL = 'Alle';

export const ALARM_TYPE_OPTIONS = [{
  id: ALERT_PRODUCT_TYPE.DEPOT,
  value: ALERT_PRODUCT_TYPE_TEXT[ALERT_PRODUCT_TYPE.DEPOT],
}, {
  id: ALERT_PRODUCT_TYPE.ASSET,
  value: ALERT_PRODUCT_TYPE_TEXT[ALERT_PRODUCT_TYPE.ASSET],
}];

export const getFieldsToCheckOptions = () => {
  const options = FIELDS_TO_CHECK_OPTIONS.filter((option) => option.value);
  return options.map((option) => ({
    id: option.value,
    value: option.label,
  }));
};

export const ALARM_FIELDS_TO_VALIDATE_OPTIONS = getFieldsToCheckOptions();
