import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  legend: {
    style: 'none',
    listStyle: 'none',
    marginBottom: 0,
    marginTop: 15,
    padding: 0,

    [theme.breakpoints.only("xs")]: {
      padding: 0
    }
  },
  legendItem: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: 30,
    '&:last-of-type': {
      marginRight: 0
    },

    [theme.breakpoints.only("xs")]: {
      marginRight: 10,
    },
    '& label': {
      marginBottom: 0
    }
  },
  bullet: {
    display: 'inline-block',
    width: 10,
    height: 10,
    whiteSpace: 'nowrap',
    marginRight: 10,

    [theme.breakpoints.down('sm')]: {
      width: 8,
      height: 8,
      whiteSpace: 'normal',
    }
  },
}))