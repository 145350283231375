import React from 'react';
import moment from "moment-timezone";

import {PrimaryButton} from "../../../../components/Buttons";
import {getAutoReportPeriodicityDescription} from "../../../SingleReporting/constants";

export default (props) => {
  const {
    classes,
    periodicity,
    next_generation_date,
    handleClickAutoReportButton,
    btnDisabled,
  } = props;


  return (
    <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
      <PrimaryButton
        text='Einstellungen für automatische Serienreports'
        onButtonClick={handleClickAutoReportButton}
        classes={classes}
        disabled={btnDisabled}
        style={{minHeight: 100}}
      />
      <div style={{marginTop: 8, textAlign: 'center'}}>
        {next_generation_date
          ? <><span>{getAutoReportPeriodicityDescription(periodicity)}</span> <br/>
              <span>nächster Report: {moment(next_generation_date).format('DD.MM.YYYY')}</span></>
          : 'Keine Einstellungen vorgenommen'
        }&nbsp;
      </div>
    </div>
  );
}