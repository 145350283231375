import Grid from "@material-ui/core/Grid";
import React from "react";
import clsx from 'clsx'
import _ from 'lodash'

import {
  clearingAccountOptionUIDToConfirmation,
  clearingAccountUIDToConfirmation,
  clearingAccountUIDToOption,
  isRequiredQuestion,
  renderQuestion
} from "../../../../../utils";
import FormLabelElement from "../../formElement/LabelFormElement/LabelFormElement";
import CheckboxQuestion from "../../question/CheckboxQuestion/CheckboxQuestion";
import RichTextEditor from "../../formElement/RichTextEditor";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../styles";
import RadioQuestion from "../../question/RadioQuestion";
import FormHelperText from "@material-ui/core/FormHelperText";
import {NOT_ALLOWED_BANKS} from "./constants"
import SelectQuestion from "../../question/SelectQuestion";

const ReferenceAccountStep = props => {
  const {
    classes,
    onAnswerChange,
    questions: [
      clearing_account,
      clearing_account_option,
      clearing_account_balancing,
      bank_account,
      origin_of_assets,
      origin_of_assets_evidence,
      notes_for_origin_of_assets,
    ],
    dataService,
  } = props;

  const isQuestionRequired = (question) => question.hasOwnProperty('optional') ? !question.optional : true
  const [warningMessage, setWarningMessage] = React.useState();

  React.useEffect(() => {
    if(dataService.is_trading) {
      let notAllowedBank = dataService.portfolios.find(portfolio => _.has(NOT_ALLOWED_BANKS, portfolio.bankCode));
      if (!_.isNil(notAllowedBank)) {
        setWarningMessage(NOT_ALLOWED_BANKS[notAllowedBank.bankCode]);
      }
    }
  }, []);

  const visibilityStyles = {
    visibility: dataService.isSwitchSellFlow ? 'hidden' : 'visible'
  }

  const onClearingAccountAnswerChange = (uid, value) => {
    onAnswerChange(uid, value);

    let confirmationQuestionUID = clearingAccountUIDToConfirmation(uid)
    let confirmationQuestion = _.find(clearing_account_balancing.question,
      (question) => question.uid === confirmationQuestionUID)
    if (!!confirmationQuestion) {
      confirmationQuestion.optional = !value
      onAnswerChange(confirmationQuestion.uid, null)
    }

    let optionQuestionUID = clearingAccountUIDToOption(uid)
    let optionQuestion = _.find(clearing_account_option.question,
      (question) => question.uid === optionQuestionUID);
    if (!!optionQuestion) {
      optionQuestion.optional = !value;
    }

    const allPortfoliosWithClearingAccount = clearing_account.question.length == dataService.portfolios.length
    const allClearingAccountsChecked = !_.find(clearing_account.question, (q) => !q.answer)

    bank_account.disabled = allPortfoliosWithClearingAccount && allClearingAccountsChecked
    bank_account.optional = bank_account.disabled
  }

  const onClearingAccountOptionAnswerChange = (uid, value) => {
    onAnswerChange(uid, value);
    let confirmationQuestionUID = clearingAccountOptionUIDToConfirmation(uid);
    let confirmationQuestion = _.find(clearing_account_balancing.question,
      (question) => question.uid === confirmationQuestionUID);

    if (!!confirmationQuestion) {

      let optionQuestion = _.find(clearing_account_option.question,
        (question) => question.uid === uid);
      const choice = optionQuestion.answer
        ? optionQuestion.config.choices.find((c) => c.id == optionQuestion.answer)
        : {}
      // Choice (selected clearing account) has info only in case it has not enough money.
      // So we make balancing checkbox required if clearing account checkbox selected
      // and selected clearing account choice has info
      confirmationQuestion.optional = !value || !choice.info;
      confirmationQuestion.error = null;

      onAnswerChange(confirmationQuestion.uid, null);
    }
  };

  const renderClearingAccountConfirmation = (clearingAccountQuestionUID) => {
    let confirmationQuestionUID = clearingAccountUIDToConfirmation(clearingAccountQuestionUID);
    let confirmationQuestion = _.find(clearing_account_balancing.question,
      (question) => question.uid === confirmationQuestionUID);

    return confirmationQuestion && (
      <Grid item xs={12} className={classes.checkboxRegularContainer}>
        <CheckboxQuestion
          question={confirmationQuestion}
          onAnswerChange={onAnswerChange}
        />
      </Grid>
    )
  };

  const renderClearingAccountQuestion = (question) => {

    let optionQuestionUID = clearingAccountUIDToOption(question.uid);
    let optionQuestion = _.find(clearing_account_option.question,
      (question) => question.uid === optionQuestionUID);

    if (!optionQuestion) return null;
    optionQuestion.disabled = optionQuestion.optional = !question.answer;

    const choice = optionQuestion.answer
      ? optionQuestion.config.choices.find((c) => c.id == optionQuestion.answer)
      : {};

    return (
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12} style={{display: 'flex'}} className={clsx(classes.checkboxRegularContainer, classes.clearingAccountLine)}>
          <CheckboxQuestion
            question={question}
            onAnswerChange={onClearingAccountAnswerChange}
          />
          <SelectQuestion
            question={optionQuestion}
            onAnswerChange={onClearingAccountOptionAnswerChange}
          />
        </Grid>
        {choice.info && (
          <>
            <Grid item xs={12}><span className={classes.clearingAccountWarning}>{choice.info}</span></Grid>
            {question.answer && renderClearingAccountConfirmation(question.uid)}
          </>
        )}
      </Grid>
    )
  };

  const clearingAccountsExist = dataService.is_trading && clearing_account.question.length

  return (
    <Grid container spacing={3} style={{paddingTop: !clearingAccountsExist ? 17 : 0}}>
      {/* region Clearing account field*/}
      { clearingAccountsExist ? (
        <>
          <Grid item xs={12}>
            <FormLabelElement customClasses={{labelRoot: 'bold'}} label={clearing_account.question_text} required={isQuestionRequired(clearing_account)} />
            <Grid container spacing={2}>
              { clearing_account.question.map(question => renderClearingAccountQuestion(question))}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{paddingBottom: 24, paddingTop: 24}}>
            <FormLabelElement
              customClasses={{labelRoot: clsx('italic')}}
              style={{lineHeight: 1.5, paddingBottom: 20}}
              label={"Bitte beachten Sie, dass es sich bei diesem Wert um den aktuellen Kontostand handelt. " +
              "Dispositionen der Depotbank können hier nicht mit berücksichtigt werden und ggf. " +
              "zu einem anderen Ausweis auf der Depotbank führen."} />
          </Grid>
        </>
      ) : null}
      {/* endregion */}
      {renderQuestion(bank_account, onAnswerChange, dataService)}
          {warningMessage && (
            <Grid item xs={12} md={9}>
              <FormLabelElement
                customClasses={{labelRoot: 'bold'}}
                label={warningMessage}
              />
            </Grid>
          )}
          <Grid item xs={12} md={9} style={visibilityStyles}>
            <FormLabelElement
              customClasses={{labelRoot: 'bold'}}
              label={"Nachstehende Angaben dienen der Vermeidung von Geldwäsche. Sollte der Anlagebetrag aus mehreren Quellen stammen" +
              " (z.B. aus einer Erbschaft und aus Berufseinkünften), sind diese vollständig zu erfassen."} />
          </Grid>

          <Grid item xs={12} md={8} style={visibilityStyles}>
            <FormLabelElement error={origin_of_assets.error} label={origin_of_assets.question_text} required={isQuestionRequired(origin_of_assets)} customClasses={{labelRoot: 'bold'}} />
            {origin_of_assets.error && (
              <FormHelperText error={true}>{origin_of_assets.error}</FormHelperText>
            )}
            <Grid container spacing={1}>
              {origin_of_assets.question.map(q => (
                <Grid key={q.uid} item xs={12} md={6} lg={6} className={classes.checkboxRegularContainer}>
                  <CheckboxQuestion
                    question={q}
                    onAnswerChange={(uid, value) => {
                      origin_of_assets.error = null; // clean error
                      onAnswerChange(uid, value);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.inlineRadio} style={visibilityStyles}>
            <FormLabelElement error={origin_of_assets_evidence.error} label={origin_of_assets_evidence.question_label} required={isQuestionRequired(origin_of_assets_evidence)} customClasses={{labelRoot: 'bold'}} />
            <div>
              {origin_of_assets_evidence.question_info}
              <RadioQuestion
                question={origin_of_assets_evidence}
                onAnswerChange={onAnswerChange}
              />
            </div>
          </Grid>

          <Grid item xs={12} style={visibilityStyles}>
            <FormLabelElement error={notes_for_origin_of_assets.error} label={notes_for_origin_of_assets.question_text} required={false} customClasses={{labelRoot: 'bold'}} />
            <p>{notes_for_origin_of_assets.question_info}</p>
            <RichTextEditor
              uid={notes_for_origin_of_assets.uid}
              value={notes_for_origin_of_assets.answer || ''}
              onChange={(value) => onAnswerChange(notes_for_origin_of_assets.uid, value)}
              rows={6}
              required={isRequiredQuestion(notes_for_origin_of_assets)}
              error={notes_for_origin_of_assets.error}
            />
          </Grid>
    </Grid>
  )
};

export default withStyles(styles)(ReferenceAccountStep);