import React from "react";
import {parseResponse} from "../../../utils/api";
import _ from "lodash";
import {ALERT_PRODUCT_TYPE} from "../components/AlertConfigurationModal/utils";
import {EDIT_ALERT_ACTIONS} from "../constants";
import {syncAssetsAlerts, syncPortfolioAlerts} from "../utils";

export function useInstrumentsTableData(portfoliosData, setPortfoliosData, riskIndicatorGroupingUsed=true) {

  const instrumentsTableData = React.useMemo(() => {

    if (portfoliosData.loading) {
      return [null, null]
    }

    let data = null
    let errors = null

    if (portfoliosData.errors) {
      errors = portfoliosData.errors
    } else {
      parseResponse(portfoliosData.data, 'instruments_table',
        (parsedData) => data = parsedData.instruments || [],
        (parsedErrors) => errors = parsedErrors)
    }

    return [data, errors]

  },[portfoliosData.updatedAt])

  const _syncPortfolioAssetsAlerts = (portfolio, asset, configuration, action) => {

    if (!riskIndicatorGroupingUsed) {
      syncAssetsAlerts(portfolio, asset, configuration, action)
      return
    }

    Object.entries(portfolio.by_risk_indicator).forEach(([riskIndicatorKey, riskIndicatorData]) => {
      syncAssetsAlerts(riskIndicatorData, asset, configuration, action)
    })
  }

  const _syncPortfoliosAlerts = (portfolio, portfolioToCheck, configuration, action) => {
    if (portfolio.id !== portfolioToCheck.id) {
      return
    }

    syncPortfolioAlerts(portfolio, configuration, action)

  }

  const handleAlertConfigurationSaved = (asset, configuration, productType, action) => {

    const instrumentsDataCopy = _.cloneDeep(instrumentsTableData[0])

    instrumentsDataCopy.forEach((portfolio) => productType == ALERT_PRODUCT_TYPE.ASSET
      ? _syncPortfolioAssetsAlerts(portfolio, asset, configuration, action)
      : _syncPortfoliosAlerts(portfolio, asset, configuration, action))

    const _portfoliosData = _.cloneDeep(portfoliosData.data)
    _.set(_portfoliosData, 'instruments_table.data.0.instruments', instrumentsDataCopy)

    setPortfoliosData(_portfoliosData)
  }

  return {instrumentsTableData, handleAlertConfigurationSaved}


}