import React, { Children } from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles';

function Widget(props) {

  const classes = useStyles();

  const PADDING_MULTIPLIER = 5;

  return (
    <div 
      className={classes.container}
      style={props.style || {
        padding: props.padding * PADDING_MULTIPLIER,
        height: props.height || '100%'
      }}
    >
      {props.children}
    </div>
  )
}

Widget.propTypes = {
  /** Container paddings (5 * padding)*/
  padding: PropTypes.number,

  height: PropTypes.oneOfType(PropTypes.string, PropTypes.number)
}

Widget.defaultProps = {
  padding: 4
}

export default Widget

