import CommonAnalysisCard from "../../../VirtualPortfolioPage/components/AnalysisCard";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";

export class TextWidget extends React.Component {

    renderValue(value) {
      if (
        value === undefined ||
        value === null ||
        value === 'N/A'
      ) {
        return 'k.A.';
      }
      return value
    }

    renderData() {
        return (
            <div>{this.renderValue(this.props.data)}</div>
        )
    }

    renderError() {
        return <div>{'Fehler. Die ' + this.props.title + ' konnte nicht geladen werden.'}</div>
    }

    render() {
        const { classes } = this.props;

        return <CommonAnalysisCard
            classes={{
              card: classes.root
            }}
            title={this.props.title}
            subtitle={this.props.subtitle}
            loading={this.props.loading}
            content={
              <div className={classes.container}>
                {this.props.error ? this.renderError() : this.props.data ? this.renderData() : 'k.A.'}
              </div>
          }
        />
      }
}

export default withStyles(styles)(TextWidget)