import React from 'react';
import _ from 'lodash';

import FormHelperText from '@material-ui/core/FormHelperText';

import useStyles from '../styles';

export default (props) => {
  const classes = useStyles();

  const {
    errors
  } = props;


  const renderNonFieldError = (error, index) => {
    return (
      <li key={index}>
        <FormHelperText className={classes.formHelperText} error={true}>
          {error}
        </FormHelperText>
      </li>
    )
  };

  const renderNonFieldErrors = () => {
    if (_.isArray(errors)) {
      return (
        <ul className={classes.nonFieldErrors}>
          {errors.map(renderNonFieldError)}
        </ul>
      );
    }

    return null;
  };


  return (
    <>
      {renderNonFieldErrors()}
    </>
  )
}