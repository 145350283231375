const client_details_1 = {
  selector: '#portfolio-analyze-header-tour-element',
  key: 'customer_app_dashboard_header'
};
const client_details_2 = {
  selector: '#client-details',
  key: 'customer_app_dashboard_header_client'
};
const client_details_3 = {
  selector: '#date-picker-dropdown',
  key: 'customer_app_dashboard_header_date_picker'
};
const client_details_4 = {
  selector: '#portfolio-list-dropdown',
  key: 'customer_app_dashboard_header_portfolio_picker'
};

export const getClientDetailsChapterSteps = () => {
  return [
    client_details_1,
    client_details_2,
    client_details_3,
    client_details_4,
  ];
};
