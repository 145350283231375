import React from 'react';
import _ from 'lodash';
import { HistoricalValueChart } from 'fincite-react-charts';
import { connect } from 'react-redux';

import styles from './styles';
import {INVESTMENT_CHART_COLOR} from "../../../../../../utils/constants";
import {hasResponseError, paginateArray} from "../../../../../../utils/utils";
import {formatPortfolioName} from "../../../../../../utils/aggregated-portfolio";
import withStyles from "@material-ui/core/styles/withStyles";
import ChartLegend from '../../../../../../components/Charts/Legend';
import Switch from '../../../../../../components/AssetModal/components/Switch';
import withWidth from "@material-ui/core/withWidth/withWidth";
import {toShortGermanFormat} from "../../../../../../utils/numberFormater";
import WarningTooltip from "../../../../../../components/WarningTooltip";
import { getHostRelatedSetting } from '../../../../../../utils/sharedSettings';
import { HOST_RELATED_SHARED_SETTINGS_KEYS} from '../../../../../../components/SharedSettingsProvider/constants';
import {ChartTooltipSettings, setDatesChanged} from "../../../../utils";
import {DashboardChartSection} from "../index";
import {Grid} from "@material-ui/core";
import moment from "moment";
import {setTimeSelectorDateChange} from "../../../../actions";
import clsx from "clsx";
import {aggregateSelectedDisabledExplanation} from "../../../../../DashboardSettings/constants";
import {getSharedSettingsSelector} from "../../../../../../components/DashboardDataProvider/DashboardDataProvider";
import {parseResponse} from "../../../../../../utils/api";
import {displayErrorSnackBar} from "../../../../../../components/SnackbarProvider/actions";
import {TRANSACTIONS_DATA_LOADING_ERROR} from "../../../../../TransactionsMonitoring/constants";
import Transactions from "../../../../../TransactionsMonitoring/components/Transactions/Transactions";
import {TransactionsMonitoringContext} from "../../../../../TransactionsMonitoring/TransactionsMonitoring";
import {
  showOrderDateNote,
  tableStructureMinimised
} from "../../../../../TransactionsMonitoring/components/Transactions/components/List/table-data";
import {buildComponentsWithSubItemsDataSource} from "../../../../../../components/Charts/InstrumentsAllocationTable";


const mapStateToProps = (state) => ({
  sharedSettings:getSharedSettingsSelector(state)
});


const _historicalPerformanceChartPropsAreEquals = (prevProps, nextProps) => {
  return prevProps.chartData.timestamp === nextProps.chartData.timestamp
    && prevProps.startDate === nextProps.startDate
    && prevProps.endDate === nextProps.endDate
}

export const getColorsFromChartData = (data) => data.map((portfolio) => portfolio.color)

const _HistoricalPerformanceChart = React.memo(({control, onDatesChange, startDate, endDate, chartData, classes}) => {

  let chartOptionSeries = [];
  if (chartData.transactionsTimeSeries ) {
    chartOptionSeries = [
      ...chartData.data.map(() => ({})), // Generate empty object for each time series
      {}, // Generate empty object for dummy line
      chartData.transactionsTimeSeries ? chartData.transactionsTimeSeries : {}]
  }

  const data = [...chartData.data, {name: '', portfolio: []}]; // Ghost element should be in array to use 'line' chart

  return (
    <div className={classes.chartContainer}>
      {control}
      <ChartLegend
        names={chartData.data.map(portfolio => portfolio.name)}
        colors={chartData.data.map(portfolio => portfolio.color)}
      />
      <HistoricalValueChart
        colors={getColorsFromChartData(data)}
        stockName="Portfolio X"
        title={''}
        data={data}
        chartOptions={{
          rangeSelector: {
            allButtonsEnabled: false,
            inputStyle: {
              visibility: 'hidden',
              opacity: 0,
            },
            labelStyle:{
              visibility: 'hidden',
              opacity: 0,
            },
            verticalAlign: 'top',
            buttons: []
          },
          chart: {
            ...ChartTooltipSettings.chart,
            height: 350,
            marginTop: 0,
            animation: false
          },
          boost: {
            enabled: true,
            useGPUTranslations: true,
            seriesThreshold: 1,
            debug: {
              timeRendering: true
            }
          },
          tooltip: {
            ...ChartTooltipSettings.tooltip,
            padding: ChartTooltipSettings.getTooltipPadding(data.length)
          },
          legend: {
            enabled: false,
          },
          xAxis: {
            min: Date.parse(startDate) || undefined,
            max: Date.parse(endDate) || undefined,
            events: {
              setExtremes: function (event) {
                const self = event.target.chart;
                if(self.rangeSelector.minInput.value && self.rangeSelector.maxInput.value) {
                  onDatesChange({start: moment(self.rangeSelector.minInput.value), end: moment(self.rangeSelector.maxInput.value)})
                }
              }
            }
          },
          yAxis: {
            opposite: false,
            align: 'right',
            labels: {
              x: -2,
              y: 4,
              formatter: function() {
                return toShortGermanFormat(this.value, '', ' €', 2, true);
              }
            }
          },
          plotOptions: {
            series: {
              events: {
                legendItemClick: function legendItemClick() {
                  return true;
                }
              },
              connectNulls: true,
              dataGrouping: {
                dateTimeLabelFormats: {
                  week: ["Woche vom %A, %b %e, %Y"],
                }
              },
              showInNavigator: true,
            }
          },
          series: chartOptionSeries
        }}
      />
    </div>
  )
}, _historicalPerformanceChartPropsAreEquals)


const groupOrdersByExecutionDates = (orders) => {
  return  _.flatten(orders || []).reduce((result, order) => {
    const executionDateTime = moment(order.execution_datetime).format('YYYY-MM-DD');
    result[executionDateTime] = order.execution_datetime_point

    return result
  }, {})
}

const prepareOrdersDataPoints = (timeSeries, orders, dataPointsColor) => {

  const ordersGroupedByDate = groupOrdersByExecutionDates(orders);

  return (timeSeries || []).reduce((result, item) => {
    if (item.date in ordersGroupedByDate) {
      result.data.push({
        x: Date.parse(item.date),
        y: item.value,
        marker: {
          enabled: true,
          radius: 4,
          symbol: 'circle',
          fillColor: dataPointsColor
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            return ordersGroupedByDate[item.date]
          }
        },
      })
    }

    return result
  }, {data: [], color: 'transparent', enableMouseTracking: false});

}


const HistoricalPerformanceChart = (props) => {
  const {
    dispatch,
    dashboardData,
    dashboardDataLoading,
    dashboardDataLoadingError,
    dashboardDataOptions,
    classes,
    theme,
    width,
    timestamp,
    sharedSettings,
    onChartSettingsChange,
    onExpanded,
    expanded,
    hideControl,
    showInvestmentCapital,
    aggregateSelectedPortfolios,
    isCumulativeDisplaySwitchDisabled,
    showEmptyAssetsCheckbox,
    showEmptyAssets,
    TransactionsOverviewProps
  } = props;

  const [reloadTrigger, setReloadTrigger] = React.useState(false);
  const [chartData, setChartData] = React.useState({
    data: [],
    error: false
  });

  const [transactions, transactionTypes, showExecutionDateNote] = React.useMemo(() => {

    if (!TransactionsOverviewProps.data) {
      return [{}, [], false]
    }

    let transactions = _.cloneDeep(TransactionsOverviewProps.data);
    let _transactionTypes = [];
    let _showExecutionDateNote = false;

    if (!transactions.loading && !transactions.errors && transactions.updatedAt) {

      _transactionTypes = _.get(transactions, 'data.transaction_types') || [];
      _transactionTypes = _transactionTypes.map((type) => ({...type, id: type.code, value: type.name}))

      parseResponse(TransactionsOverviewProps.data.data, 'orders', (data) => {
        _showExecutionDateNote = _.some(data, (order) => showOrderDateNote(order, TransactionsOverviewProps.validateTransactionsExecutionDate && TransactionsOverviewProps.trackingStartDate))
        data = buildComponentsWithSubItemsDataSource(data, false, 'components');
        transactions.data = [...paginateArray(data, 10)]
        transactions.errors = undefined
      }, (errors) => {
        console.error(errors)
        transactions.errors = errors
        transactions.data = undefined
      });
    }

    if (!!transactions.errors) {
      props.dispatch(displayErrorSnackBar(TRANSACTIONS_DATA_LOADING_ERROR))
    }

    return [transactions, _transactionTypes, _showExecutionDateNote]

  }, [_.get(TransactionsOverviewProps.data, 'updatedAt')])

  React.useEffect(() => {
    if (dashboardData) {

      if (!requiredDataExist(dashboardData)) {
        return setChartData({...chartData, error: true});
      }

      let data = prepareFromDashboardData();

      if (TransactionsOverviewProps.enabled && !transactions.loading && !transactions.errors && transactions.data && transactions.updatedAt) {

        const portfolioTimeSeries = _.get(data, 'preparedData.0') || {};

        data.transactionsTimeSeries = prepareOrdersDataPoints(
          portfolioTimeSeries.portfolio || [], transactions.data || [], portfolioTimeSeries.color)
      }

      setReloadTrigger(true);
       setTimeout(() => {
         setChartData({
           data: data.preparedData,
           transactionsTimeSeries: data.transactionsTimeSeries,
           error: data.hasError,
           timestamp: new Date().getTime()
         });
         setTimeout(() => {
           setReloadTrigger(false);
         }, 100);
       }, 100);
    }
  }, [dashboardData, showInvestmentCapital, aggregateSelectedPortfolios, timestamp, transactions.updatedAt]);

  function prepareFromDashboardData() {
    // Returns chart data with or without invest capital

    let preparedData = [];

    // invest capital timeseries are attached to aggregated portfolio, which must be the first one
    let portfolioWithInvestCapital = dashboardData.portfolios.find((portfolio) => !portfolio.id);
    let investment_timeseries = portfolioWithInvestCapital && portfolioWithInvestCapital.investment_timeseries || [];

    const portfolios = dashboardData.portfolios.filter(portfolio => aggregateSelectedPortfolios
      ? portfolio.id === 0
      : portfolio.id !== 0);

    let hasError = portfolios.some((portfolio) => hasResponseError(portfolio.historical));

    if (!hasError) {
      preparedData = portfolios
      .map(portfolio => ({
        name: formatPortfolioName(portfolio.name),
        color: portfolio.color,
        ...portfolio.historical
      }));

      // add invest capital if it has timeseries
      if (investment_timeseries && investment_timeseries.length && showInvestmentCapital) { // showInvestment
        let investmentData = investment_timeseries.map(value => ({
          date: value.transaction_date,
          value: value.invested_amount
        }));

        preparedData.push({
          name: 'Kapitaleinsatz',
          color: INVESTMENT_CHART_COLOR,
          portfolio: investmentData
        });
      }

    }

    return {
      hasError,
      preparedData,
    }
  }

  function requiredDataExist(dashboardData) {
    return _.isArray(dashboardData.portfolios)
      && dashboardData.portfolios.every(portfolio => !!portfolio.historical);
  }

  const isInvestCapitalEnabled = () => {
    return !!dashboardData;
  };

  const handleShowInvestmentChange = (checked) => {
    onChartSettingsChange('historical', 'withInvestiertesAmount', checked);
  }

  const handleAggregateSelectedChange = (checked) => {
    onChartSettingsChange('historical', 'aggregateSelected', checked);
  }

  const handleShowEmptyAssetsChange = (checked) => {
    onChartSettingsChange('historical', 'withEmptyAssets', checked);
  }

  const contentControl = (
    <>
      <Grid container spacing={1} className={classes.contentControl} justify={"space-between"} alignItems={'flex-end'}>
        {!hideControl &&
          <Grid item id="historical-chart-aggregate-selected-checkbox">
            <p className={classes.benchmarkLabel}>
              Kumulierte Anzeige
              {!dashboardDataLoading && isCumulativeDisplaySwitchDisabled &&
              <WarningTooltip width={"100%"} title={aggregateSelectedDisabledExplanation} size={13}/>}
            </p>
            <Switch
              value={aggregateSelectedPortfolios}
              handleValueChanged={handleAggregateSelectedChange}
              disabled={isCumulativeDisplaySwitchDisabled}
            />
          </Grid>
        }
        {/* display invest capital switch */}
        <Grid item>
          <Grid container spacing={2} alignItems={'flex-end'}>
            <Grid item id="historical-chart-benchmark-checkbox">
              <p className={classes.benchmarkLabel}>Kapitaleinsatz</p>
              <Switch
                value={showInvestmentCapital}
                handleValueChanged={handleShowInvestmentChange}
                disabled={!isInvestCapitalEnabled()}
              />
            </Grid>
            {showEmptyAssetsCheckbox && (
              <Grid item>
                <p className={classes.benchmarkLabel}>vollst. verkaufte <br/> Vermögenswerte
                <WarningTooltip title={"Setzen Sie diesen Schalter auf “AUS”, bleiben alle, außerhalb des gewählten Zeitraums vollständig verkauften Produkte, für die Berechnung des invest. Kapitals unberücksichtigt."}/>
                </p>
                <Switch
                  value={showEmptyAssets}
                  handleValueChanged={handleShowEmptyAssetsChange}
                  disabled={!isInvestCapitalEnabled()}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  let timeout = React.useRef(null);
  const onDatesChange = (dates) => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      dispatch(setTimeSelectorDateChange(dates))
    }, 100)
  }

  const minHeight = ['xs', 'sm', 'md'].includes(width) ? 520 : 486;

  const empty = chartData.data && chartData.data.every(item => item.portfolio.length === 0);

  const orders_error = dashboardData && dashboardData.portfolios.some(portfolio => portfolio.orders_error)|| false;
  const market_value_error = dashboardData && dashboardData.portfolios.some(portfolio => portfolio.market_value_error) || false;

  const getWarningMessage = () => {
    const order_error_message = getHostRelatedSetting(
      sharedSettings.data, HOST_RELATED_SHARED_SETTINGS_KEYS.HISTORICAL_CHART_ORDER_ERROR_MESSAGE_TEXT);

    const portfolio_names = dashboardData.portfolios
      .filter(portfolio => portfolio.market_value_error).reduce((names, portfolio) => {
        return `${names} \n- ${portfolio.name}`
      }, '\n');
    const market_value_error_message = getHostRelatedSetting(
      sharedSettings.data, HOST_RELATED_SHARED_SETTINGS_KEYS.HISTORICAL_CHART_MARKET_VALUE_ERROR_MESSAGE_TEXT, {
        "{{portfolios}}": portfolio_names
      });

    if (order_error_message || market_value_error_message) {
      return (
        <div>
          {orders_error && <p>{order_error_message}</p>}
          {market_value_error && <p>{market_value_error_message}</p>}
        </div>
      )
    }
  };

  const title = (
    <>
      Historische Entwicklung und Kapitaleinsatz in Euro
      {(orders_error || market_value_error) && <WarningTooltip width={"100%"} title={getWarningMessage()} size={16}/>}
    </>
  );

  const handleOrderingChange = (orderingColumn) => {
    onChartSettingsChange('transactionsOverview', 'orderingColumn', orderingColumn);
  }

  return (
    <DashboardChartSection
      title={title}
      contentControl={contentControl}
      content={dashboardData && (
        <_HistoricalPerformanceChart
          chartData={chartData}
          onDatesChange={onDatesChange}
          startDate={dashboardDataOptions && dashboardDataOptions.start_date}
          endDate={dashboardDataOptions && dashboardDataOptions.end_date}
          classes={classes}
        />
      )}
      extraContent={TransactionsOverviewProps.enabled && (
        <DashboardChartSection
          title={"Transaktionsübersicht"}
          loading={transactions.loading}
          error={transactions.errors}
          displayError={true}
          expanded={TransactionsOverviewProps.expanded}
          onExpanded={TransactionsOverviewProps.onExpanded}
          content={(
            <TransactionsMonitoringContext.Provider value={{
              onOrderingChange: handleOrderingChange,
              dataProvider: TransactionsOverviewProps.dataProvider,
              transactions: transactionTypes,
              customerId: TransactionsOverviewProps.customerId
            }}>
              <Transactions loading={transactions.loading} transactions={transactions.data || []} errors={transactions.errors}>
                <Transactions.List
                  isGrouped
                  tableStructure={tableStructureMinimised}
                  tableClasses={classes}
                  tableOptions={{
                    viewOnly: TransactionsOverviewProps.editable,
                    onDeleteClick: TransactionsOverviewProps.onDeleteClick,
                    onEditClick: TransactionsOverviewProps.onEditClick,
                    deleteInProgress: TransactionsOverviewProps.deleteInProgress,
                    editInProgress: TransactionsOverviewProps.editInProgress,
                    onAllSelect: TransactionsOverviewProps.onAllSelect,
                    onSelect: TransactionsOverviewProps.onSelect,
                    selected: TransactionsOverviewProps.selected,
                    totalSelectableTransactionsCount: TransactionsOverviewProps.totalSelectableTransactionsCount,
                    trackingStartDate: TransactionsOverviewProps.validateTransactionsExecutionDate && TransactionsOverviewProps.trackingStartDate
                  }}
                />
              </Transactions>
              {showExecutionDateNote && (
                <p style={{fontSize: 16, marginTop: 15}}>*An diesem Datum lagen erstmals Preisdaten für das Produkt vor.</p>
              )}
            </TransactionsMonitoringContext.Provider>
          )}
          customClasses={{
            container: classes.transactionsContainer,
            contentContainer: classes.contentContainer
          }}
        />
      )}
      loading={dashboardDataLoading || reloadTrigger}
      error={dashboardDataLoadingError || chartData.error}
      minHeight={minHeight}
      empty={empty}
      displayError={true}
      expanded={expanded}
      onExpanded={onExpanded}
      sharedSettingKey={HOST_RELATED_SHARED_SETTINGS_KEYS.DASHBOARD_HISTORICAL_CHART_EXPLANATION}
    />
  );
};

HistoricalPerformanceChart.defaultProps = {
  TransactionsOverviewProps: {}
}

export default connect(mapStateToProps)(
  withWidth()(
    withStyles(styles, {withTheme: true})(HistoricalPerformanceChart)));
