import React from "react";
import { connect } from 'react-redux'

import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../styles";
import {getErrorMessage} from "../../../../../../../../../../../utils/utils";
import {CircularProgress} from "@material-ui/core";
import {InvestmentStrategiesResource} from "../../../../../../../../../../../utils/api";
import {
  displayErrorSnackBar,
  displayWarningSnackBar
} from "../../../../../../../../../../../components/SnackbarProvider/actions";
import _ from "lodash";
import InvestmentStrategyOverview from '../../../../../../../../../../InvestmentStrategy/Overview/Overview'
import InvestmentStrategiesList from '../../../../../../../../../../InvestmentStrategy/List/List'
import {InvestmentStrategyHandler} from "../../../../../../../../../utils";
import {DEFAULT_SELECT_PRODUCT_TABLE_ACTIONS_NAMES} from "../../../../../../../../../../../utils/constants";


function InvestmentStrategySelection(props) {
  const {
    classes,
    selectedItem,
    onDetailsClick,
    onSelectAll,
    onInstrumentSelect,
    depot,
    dataService,
    selectedInstruments,
    forceAllAssetsSelection,
    onSourceSelect,
    sourceSelected,
    directAddingPossible
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [skipDetails, setSkipDetails] = React.useState(false)

  const strategiesHandler = React.useMemo(() => {
    if(!dataService) {
      return undefined;
    }
    return new InvestmentStrategyHandler(undefined, dataService._target_market)
  }, [depot, dataService])

  /**
   * Get investment strategy details.
   * @param investmentStrategyId Investment strategy identifier
   * @param skipDetails Should we display details screen, or not.
   * @returns {Promise<void>}
   */
  const handleDetailsClick = async (investmentStrategyId, skipDetails) => {
    setLoading(true);
    setSkipDetails(skipDetails);
    try {
      let investmentStrategy = await InvestmentStrategiesResource.at(`${investmentStrategyId}/`).get();

      const assets = _.get(investmentStrategy, 'related_model_portfolio.assets') || []

      if (!assets.length) {
        props.dispatch(displayWarningSnackBar('Für dieses Portfolio sind leider nicht alle Daten vorhanden.'))
      } else {
        forceAllAssetsSelection && onSelectAll(assets);
        onDetailsClick({
          ...investmentStrategy,
          assets,
          isInvestmentStrategy: true
        }, skipDetails);
      }
    } catch (error) {
      props.dispatch(displayErrorSnackBar(getErrorMessage(error)))
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loadingIndicator}/>
        </div>
      )}
      {!loading && (
        <>
          {selectedItem && !skipDetails && (
            <InvestmentStrategyOverview
              viewOnly
              investmentStrategy={selectedItem}
              onInstrumentSelect={onInstrumentSelect}
              onSelectAll={onSelectAll}
              selectedInstruments={selectedInstruments}
              onSourceSelect={onSourceSelect}
              sourceSelected={sourceSelected}
              isInstrumentsSelection
            />
          )}
          {(!selectedItem || skipDetails) &&
            <InvestmentStrategiesList
              extraParams={depot && {custodian_id: depot.bankCompanyId}}
              viewOnly
              handleEditClick={handleDetailsClick}
              strategiesHandler={strategiesHandler}
              directAddingPossible={directAddingPossible}
              actionsNames={DEFAULT_SELECT_PRODUCT_TABLE_ACTIONS_NAMES}
            />}
        </>
      )}
    </>
  )
}

InvestmentStrategySelection.defaultProps = {
  forceAllAssetsSelection: true
}

export default connect()(withStyles(styles)(InvestmentStrategySelection));