import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import CustodianWidget from "./items/CustodianWidget";
import styles from "./styles";


class CommercialInfoTab extends React.Component {

  constructor(props) {
    super(props);

    this.renderWidgets = this.renderWidgets.bind(this);
  }

  renderError() {
    return <p>{'Fehler. Die "Handelsinformationen" konnte nicht geladen werden.'}</p>
  }

  renderWidgets() {
    return this.props.data.custodians.map((data, index) => (
      (data.c_factsheet_visible && (
        <CustodianWidget
          data={data}
          asset_info={this.props.data}
          currency={this.props.data.currency_code}
          key={index}
          expanded={index === 0}
        />
      ))
    ));
  }

  render() {
    return <Grid container spacing={2}>
      <Grid item xs={12}>
        {
          this.props.data.custodians
            ? this.renderWidgets()
            : this.renderError()
        }
      </Grid>
    </Grid>
  }
}

export default withStyles(styles)(CommercialInfoTab);
