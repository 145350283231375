import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';

/** Material-UI Components */
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DoneIcon from '@material-ui/icons/Done';
import DownloadIcon from '@material-ui/icons/ArrowDownward';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from "@material-ui/core/Button/Button";
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import IconButton from "@material-ui/core/IconButton";
import Badge from '@material-ui/core/Badge';

/** BCA Utils */
import WebSocketHandler from '../../utils/websocket';
import { makeStyle as useStyles } from './styles';
import styles from './styles';
import {CustomerReportSettingResource} from '../../utils/api';
import {downloadPdf} from '../../utils/utils';
import {getFromStorage, TOKEN_KEY} from '../../utils/storage';
import withWidth from "@material-ui/core/withWidth/withWidth";
import withNotification from "../NotificationProvider";
import NotificationItem
  from "../../containers/InvestmentPlatform/components/Header/components/NotificationItem/NotificationItem";

const REPORT_STATUS = {
  UNKNOWN: 0,
  IN_QUEUE: 1,
  IN_PROGRESS: 2,
  FINISHED: 3,
  FAILED: 4,
  NOTIFICATION: 5
}

const TASK_MESSAGE_TYPE = {
  UNKNOWN: 0,
  SUCCESS: 1,
  WARNING: 2,
  ERROR: 3
}

const TaskNotifications = (props) => {
  const classes = useStyles();

  const {
    messages
  } = props;

  const getMessageColor = (message) => {
    switch(message.message_type) {
      case TASK_MESSAGE_TYPE.SUCCESS:
        return '#cbebc6';
      case TASK_MESSAGE_TYPE.ERROR:
        return '#ffb3b3';
      default:
        return '#d9d9d9'
    }
  }

  const renderMessage = (message, key) => {
    return <p
      key={key}
      style={{backgroundColor: getMessageColor(message)}}
      className={classes.taskNotificationItem}
    >
      {message.text}
    </p>
  }

  return (
    <div>
      {messages && (
        <>
          {messages.map((message, index) => { return renderMessage(message, index) })}
        </>
      )}
    </div>
  )
}

const handleDeleteClick = async (task_id, actionHandler) => {
  try {
    let response = await CustomerReportSettingResource.at(`customer/tasks/${task_id}/`).delete()

    if (actionHandler) {
      actionHandler(task_id)
    }
  } catch (exception) {
    // TODO: Handle exception here
  }
}

const CloseIconButton = (props) => {
  const classes = useStyles();

  const {
    task,
    actionHandler
  } = props;

  return (
    <CloseIcon
      className={classes.additionalTaskActionButton}
      onClick={() => handleDeleteClick(task.task_id, actionHandler)}
      style={{fontSize: 18, top:2, color: '#FF0000'}}
    />
  )
}

const InProgressItem = (props) => {
  const classes = useStyles();

  const {
    task,
    actionHandler
  } = props;

  return (
    <div className={classes.taskItemContainer}>
      <div
        style={
          {
            display: 'flex',
          }
        }
      >
        <div className={classes.taskItemIconContainer}>
          <PictureAsPdfIcon style={{color: '#ABB6BE'}}/>
        </div>
        <div style={{width: 'calc(100% - 35px)'}}>
          <p className={classes.reportItemTitle}>{task.task_name}</p>
          <div className={classes.progressBarContainer}>
            <LinearProgress variant="determinate" value={task.progress ? parseInt(task.progress) : 0} classes={{
              root: classes.progressBarRoot,
              bar: classes.progressBarBar
            }}/>

            <span className={classes.progressBarValue}>{task.progress ? parseInt(task.progress) : 0}%</span>

            <CloseIconButton
             task={task}
             actionHandler={actionHandler}
            />
          </div>
        </div>
      </div>
        <TaskNotifications messages={task.messages}/>
    </div>
  )
}

const InQueueItem = (props) => {
  const classes = useStyles();

  const {
    task,
    actionHandler
  } = props;

  return (
    <div className={classes.taskItemContainer}>
      <div
        style={
          {
            display: 'flex'
          }
        }
      >
        <div className={classes.taskItemIconContainer}>
          <PictureAsPdfIcon style={{color: '#ABB6BE'}}/>
        </div>
        <div style={{width: 'calc(100% - 35px)'}}>
          <p className={classes.reportItemTitle}>{task.task_name}</p>
          <div className={classes.taskItemAdditionalInfo}>
            <span>Wird gerade erstellt</span>

            <CloseIconButton
             task={task}
             actionHandler={actionHandler}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const FinishedItem = withNotification((props) => {
  const classes = useStyles();

  const {
    task,
    actionHandler,
    displayNotification
  } = props;

  const [loading, setLoading] = React.useState(false);

  const handleDownloadClick = async (e) => {
    if (loading) {
      return ;
    }

    setLoading(true);

    if ((task.storage_type || task.storage_type == 0) && (task.storage_type == 0 || task.storage_type == 1)) {
      e.preventDefault();

      let a = document.createElement('a')

      let report_path = task.report_path
      if (task.storage_type == 1) {
        report_path = `${window.location.origin}/media/${report_path.split('/').pop()}`
      }
      a.href = report_path
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      try {
        let response = await CustomerReportSettingResource.at(`customer/tasks/${task.task_id}/set-downloaded/`).get()
        if (actionHandler) {
          setLoading(false);
          actionHandler(task.task_id)
        }
      } catch (error) {
        const message = error && error.detail;
        setLoading(false);
        if (message) {
          displayNotification('error', message, () => {
            if (error && error.status === 404) {
              if (actionHandler) {
                actionHandler(task.task_id)
              }
            }
          });
        }
      }
      return true;

    } else {
      downloadPdf(`/reports/customer/tasks/${task.task_id}/file/`)
      .then(() => {
        if (actionHandler) {
          setLoading(false);
          actionHandler(task.task_id)
        }
      })
      .catch(error => {
          const message = error && error.detail;
          setLoading(false);
          if (message) {
            displayNotification('error', message, () => {
              if (error && error.status === 404) {
                if (actionHandler) {
                  actionHandler(task.task_id)
                }
              }
            });
          }
      });
    }
  }

  return (
    <div className={classes.taskItemContainer}>
      <div
        style={
          {
            display: 'flex'
          }
        }
      >
        <div className={classes.taskItemIconContainer}>
          <PictureAsPdfIcon color='primary'/>
        </div>
        <div style={{width: 'calc(100% - 35px)'}}>
          <p className={classes.reportItemTitle}>{task.task_name}</p>
          <div className={classes.taskItemAdditionalInfo}>
            <span>Bereits erstellt</span>

            {!(task.parameters && task.parameters.is_file_unavailable) ? (
              <div className={clsx(classes.additionalTaskActionButton, loading && classes.disabled)} onClick={handleDownloadClick}>
                <DownloadIcon className={classes.downloadIcon}/>
                <span className={classes.downloadTaskButtonContainer}>
                  Herunterladen
                </span>
              </div>
              )
              : (
                <CloseIconButton
                  task={task}
                  actionHandler={actionHandler}
               />
              )
            }

          </div>
        </div>
      </div>
        <TaskNotifications messages={task.messages}/>
    </div>
  )
})

const FailedItem = (props) => {
  const classes = useStyles();

  const {
    task,
    actionHandler
  } = props;

  return (
    <div className={classes.taskItemContainer}>
      <div
        style={
          {
            display: 'flex'
          }
        }
      >
        <div className={classes.taskItemIconContainer}>
          <PictureAsPdfIcon style={{color: '#FF0000'}}/>
        </div>
        <div style={{width: 'calc(100% - 35px)'}}>
          <p className={classes.reportItemTitle}>{task.task_name}</p>
          <div className={classes.taskItemAdditionalInfo}>
            <span style={{color: '#FF0000'}}>Erstellung fehlgeschlagen.</span>
            <CloseIconButton
              task={task}
              actionHandler={actionHandler}
            />
          </div>
        </div>
      </div>
        <TaskNotifications messages={task.messages}/>
    </div>
  )
}



const ReportTaskItem = (props) => {
  const classes = useStyles();

  const {
    task,
    actionHandler
  } = props;

  const getTaskStatus = () => {
    if (task.status === REPORT_STATUS.IN_PROGRESS) {
      return task.progress ? task.progress : 0
    } else if (task.status === REPORT_STATUS.FINISHED) {
      return <DoneIcon />
    } else if (task.status === REPORT_STATUS.FAILED) {
      return 'failed'
    } else {
      return 'in queue'
    }
  }

  const getTaskColor = () => {
    if (task.status === REPORT_STATUS.IN_PROGRESS) {
      return 'rgb(0, 146, 229)'
    } else if (task.status === REPORT_STATUS.FINISHED) {
      return '#5EC14E'
    } else if (task.status === REPORT_STATUS.FAILED) {
      return 'red'
    } else {
      return '#63696f'
    }
  }

  const getItemTemplate = () => {
    if (task.status === REPORT_STATUS.IN_PROGRESS) {
      return <InProgressItem task={task} actionHandler={actionHandler} />
    } else if (task.status === REPORT_STATUS.FINISHED) {
      return <FinishedItem task={task} actionHandler={actionHandler} />
    } else if (task.status === REPORT_STATUS.FAILED) {
      return <FailedItem task={task} actionHandler={actionHandler} />
    } else {
      return <InQueueItem task={task} actionHandler={actionHandler} />
    }
  }

  return (
    <>
      {getItemTemplate()}
    </>
  )
}

class ReportGenerationHandler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      socketConnection: undefined,
      tasks: [],
      detailsWindowOpened: false,
      token: getFromStorage(TOKEN_KEY),
      ws_path: this.get_ws_path(getFromStorage(TOKEN_KEY))
    }
  }

  updateTaskStatus = (task_id, status, storage_type=undefined, report_path=undefined, parameters=undefined) => {
    let tasks_local = [...this.state.tasks];

    for (let i = 0; i < tasks_local.length; i++) {
      if (tasks_local[i].task_id == task_id) {
        tasks_local[i].status = status;
        if (storage_type || storage_type == 0) {
          tasks_local[i].storage_type = storage_type
        }
        if (report_path) {
          tasks_local[i].report_path = report_path
        }
        if (parameters) {
          tasks_local[i].parameters = parameters
        }
        break;
      }
    }

    this.setState({
      tasks: tasks_local
    })
  }

  updateTaskProgress = (task_id, progress) => {
    let tasks_local = [...this.state.tasks];

    for (let i = 0; i < tasks_local.length; i++) {
      if (tasks_local[i].task_id == task_id) {
        tasks_local[i].status = REPORT_STATUS.IN_PROGRESS;
        tasks_local[i].progress = progress ? +progress : 0;
        break;
      }
    }

    this.setState({
      tasks: tasks_local
    })
  }

  updateTaskNotification = (notification) => {
    if (notification) {
      let tasks_local = [...this.state.tasks];

      for (let i = 0; i < tasks_local.length; i++) {
        if (tasks_local[i].task_id == notification.task_id) {
          if (!tasks_local[i].messages) {
            tasks_local[i].messages = []
          }
          tasks_local[i].messages = [notification, ...tasks_local[i].messages]
          break;
        }
      }

      this.setState({
        tasks: tasks_local
      })
    }
  }

  get_ws_path = (token) => {
    return `${window.location.host}/websocket/${token ? `?token=${token}` : ''}`
  }

  handleSocketMessage = (message) => {
    const data = JSON.parse(message.data);

    switch(data.event_type) {
      case REPORT_STATUS.IN_QUEUE:
        this.setState({
          tasks: [data.message, ...this.state.tasks]
        })
        break;
      case REPORT_STATUS.IN_PROGRESS:
        this.updateTaskProgress(data.task_id, data.progress)
        break;
      case REPORT_STATUS.FAILED:
        this.updateTaskStatus(data.task_id, REPORT_STATUS.FAILED);
        break;
      case REPORT_STATUS.FINISHED:
        this.updateTaskStatus(data.task_id, REPORT_STATUS.FINISHED, data.storage_type, data.report_path, data.parameters);
        break;
      case REPORT_STATUS.NOTIFICATION:
        this.updateTaskNotification(data.message)
        break;
    }
  }

  componentDidMount() {
    let connection = new WebSocketHandler(this.state.ws_path, (message) => {
      this.handleSocketMessage(message);
    });
    connection.connect();

    this.setState({
      socketConnection: connection
    });

    this.fetchTasks()
  }

  componentWillUnmount() {
    this.state.socketConnection && this.state.socketConnection.close();
    this.setState({
      socketConnection: undefined
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const prevTasksLength = prevState.tasks && prevState.tasks.length || 0;
    const currentTasksLength = this.state.tasks && this.state.tasks.length || 0;
    const new_token = getFromStorage(TOKEN_KEY)
    let currentWSConnection = prevState.socketConnection
    if (prevState.token !== new_token) {
      const new_ws_path = this.get_ws_path(new_token);
      currentWSConnection._path = new_ws_path;
      if(currentWSConnection.isClosed()){
        currentWSConnection.connect();
      }
      this.setState({
        ...this.state,
        token: new_token,
        ws_path: new_ws_path,
        socketConnection: currentWSConnection
      })
    }
    if (currentTasksLength === 0 && prevTasksLength > 0) {
      this.setState({
        ...this.state,
        detailsWindowOpened: false
      })
    }
  }

  fetchTasks = async () => {
    try {
      let response = await CustomerReportSettingResource.at('customer/tasks/').get();

      this.setState({
        tasks: response || []
      })
    } catch (exception) {
      // TODO: Handle exception here
    }
  }

  taskItemActionHandler = (task_id) => {
    if (task_id) {
      let filtered_tasks = _.filter(this.state.tasks, (task) => task.task_id !== task_id)

      this.setState({
        tasks: filtered_tasks
      })
    }
  }

  render() {
    const {
      classes,
      width,
      notificationItem
    } = this.props;

    const isIconVisible = ['xs', 'sm', 'md'].includes(width);

    const notificationItemButton = (
      <NotificationItem faIcon={['fas', 'download']} badgeContent={this.state.tasks.length}/>
    );

    const defaultIconButton = (
      <IconButton classes={{root: classes.iconButton}} aria-label="downloads" >
        <Badge
          badgeContent={this.state.tasks.length}
          classes={{
            badge: classes.badge,
          }}
        >
          <SaveAltIcon />
        </Badge>
      </IconButton>
    );

    const defaultButton = (
      <>
        {this.state.tasks.length > 0 && (
          <div className={classes.counter}>
            <span>{this.state.tasks.length}</span>
          </div>
        )}
        <Button className={clsx(classes.triggerStateButton, this.state.detailsWindowOpened && classes.activeTriggerStateButton)}>
          <span>Downloads</span>
          {this.state.detailsWindowOpened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </>
    )

    return (
      <>
        <Fade in={this.state.detailsWindowOpened}>
          <div className={classes.backdrop} onClick={() => this.setState({detailsWindowOpened: false})}/>
        </Fade>
        <div
          className={clsx(
            classes.triggerStateButtonWrapper,
            this.state.detailsWindowOpened && classes.opened,
            notificationItem && classes.notificationItemButtonWrap
          )}
        >
          <Fade in={this.state.detailsWindowOpened}>
            <Card className={classes.detailsCard}>
              <CardContent className={classes.detailsCardContent}>
                <div className={classes.detailsCardHeader}>
                  <p className={classes.detailsCardTitle}>Reports</p>
                  <CloseIcon className={classes.closeIcon} onClick={() => this.setState({detailsWindowOpened: false})}/>
                </div>
                <>
                  {this.state.tasks && this.state.tasks.length > 0 ? (
                    this.state.tasks.map(task => (
                      <ReportTaskItem
                        key={task.task_id}
                        task={task}
                        actionHandler={this.taskItemActionHandler}
                      />
                    ))
                  ) : (
                    <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      Sie finden hier Ihre gedruckten Reports zum Herunterladen
                    </div>
                  )}
                </>
              </CardContent>
            </Card>
          </Fade>

          <div
            className={clsx(classes.buttonWrapper)}
            onClick={() => this.setState({detailsWindowOpened: !this.state.detailsWindowOpened})}
          >
            {notificationItem ? notificationItemButton : (isIconVisible ? defaultIconButton : defaultButton)}
          </div>

        </div>
        {this.props.children}
      </>
    )
  }

}

export default withStyles(styles)(withWidth()(ReportGenerationHandler));
