import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/* Material-UI Components */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

/* BCA modules*/
import useStyles from './styles';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';

import ReportTypeSelector from './ReportTypeSelector'
import _ from 'lodash';
import {REPORT_TYPES} from "../../../DashboardSettings/components/CustomersList/components/ReportType/constants";


const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
  investmentPlatform: state.get('investmentPlatform').toJS(),
});


export default connect(mapStateToProps)(withRouter((props) => {
  const classes = useStyles();


  const {
    handlePdfExportClick,
    pdfExportDisabled,
    customerId,
    auth,
    investmentPlatform,
    reportType,
    setReportType
  } = props;


  const renderReportTypeSelector = () => {
    return (
      <div style={{width: '200px'}}>
        <ReportTypeSelector
          selectedReportType={_.find(REPORT_TYPES, option => option.value === reportType)}
          allReportTypes={REPORT_TYPES}
          handleSelectedReportTypeChanged={(selectedOption) => {setReportType(selectedOption.value)}}
        />
      </div>
    )
  };

  return (
    <Grid container
          alignItems="flex-end"
          justify="space-between"
          className={classes.container}
          spacing={2}
    >
      <Grid item>
        <Typography variant="h1" className={classes.header} id="portfolio-analyze-header-tour-element">Vermögensübersicht</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={2} style={{margin: '-7px'}} alignItems="flex-end">
          <Grid item>
            {renderReportTypeSelector()}
          </Grid>

          <Grid item>
            <PrimaryButton
              id='export-buttons'
              text='Vermögensübersicht drucken'
              onButtonClick={handlePdfExportClick}
              icon={<ArrowDownwardIcon />}
              disabled={pdfExportDisabled}
              customClasses={{ root: classes.panelButton }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}));
