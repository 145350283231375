import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";

import styles from './styles';
import connect from "react-redux/lib/connect/connect";

const InputArrows = (props) => {
  const {
    classes,
    topArrowClick,
    bottomArrowClick,
  } = props;


  return (
    <div className={classes.arrowsComponent}>
      <div className={classes.arrows}>
        <button className={classes.arrowsButton} onClick={topArrowClick}>
          <div className={classes.arrowTop} />
        </button>
        <button className={classes.arrowsButton} onClick={bottomArrowClick}>
          <div className={classes.arrowBottom} />
        </button>
      </div>
    </div>
  );

};

export default connect()(withStyles(styles)(InputArrows));
