import {makeStyles} from '@material-ui/core';

export default makeStyles(() => ({
  dialogContent: {
    padding: '34px 54px 34px 24px',
    maxWidth: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > p' : {
      color: '#313D44',
      fontSize: 16,
      lineHeight: '22px'
    }
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#313D44'
  },
  checkIcon: {
    color: '#5ec14e',
    width: 36,
    height: 36
  },
  message: {
    marginLeft: 10,
    whiteSpace: 'pre-line'
  }

}));