const styles = theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 48,
    borderLeftWidth: 4,
    borderLeftColor: 'transparent',
    borderLeftStyle: 'solid',
    paddingRight: 22,
    paddingLeft: 18,
    cursor: 'pointer',
    zIndex: 1,

    transition: 'all',
    transitionDuration: '.2s',

    '&:hover': {
      borderLeftColor: theme.palette.primary.main,
      backgroundColor: '#3a4352',

      '& $name': {
        color: 'white'
      }
    }
  },
  name: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: '#B0B7BC'
  },
  icon: {
    fontSize: 16,
    color: 'white'
  },
  active: {
    borderLeftColor: theme.palette.primary.main,
    backgroundColor: '#3a4352',

    '& $name': {
      color: 'white'
    }
  },
  childrenVisible: {
    [theme.breakpoints.down('md')]: {
      "& i": {
        transform: 'rotate(90deg)'
      }
    },
  },
  childItemsContainer: {
    backgroundColor: '#2A3545',
    position: 'absolute',
    left: '100%',
    top: 0,
    zIndex: 1,
    marginLeft: -12, // to overflow scrollbar
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: 5
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#B0B7BC',
      outline: '1px solid slategrey'
    },

    '&::-webkit-scrollbar-track': {
      margin: '1px 0 1px 0'
    },

    [theme.breakpoints.down('md')]: {
      position: 'static',
      overflowY: 'visible',
      height: 'auto !important',
      backgroundColor: '#202A38 !important',
    },
  },
  childItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: '#B0B7BC',
    padding: '0 24px',
    height: 48,
    cursor: 'pointer',

    [theme.breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
    },
  },
  childItemActive: {
    backgroundColor: '#3a4352',
    color: 'white',
  }
});

export default styles;