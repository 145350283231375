import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.82"
      height="30"
      viewBox="0 0 15.82 30"
    >
      <g
        id="Gruppe_7848"
        data-name="Gruppe 7848"
        transform="translate(-941.254 -312.889)"
      >
        <g
          id="Gruppe_468"
          data-name="Gruppe 468"
          transform="translate(941.254 312.889)"
        >
          <path
            id="Pfad_1423"
            data-name="Pfad 1423"
            d="M957.074,312.889H951.8v1.816h-5.273v-1.816h-5.273v9.726a7.878,7.878,0,0,0,2.02,5.274,7.911,7.911,0,0,0-2.02,5.273v9.727h5.273v-1.816H951.8v1.816h5.273v-9.727a7.879,7.879,0,0,0-2.02-5.273,7.911,7.911,0,0,0,2.02-5.274Zm-10.547,3.574H951.8v3.516h-5.273Zm0,5.273H951.8v.879a2.637,2.637,0,1,1-5.273,0Zm-1.758,11.426v7.969h-1.758v-7.969a6.152,6.152,0,0,1,1.572-4.1,7.9,7.9,0,0,0,1.6.88A4.383,4.383,0,0,0,944.77,333.162Zm7.031,6.152h-5.273V335.8H951.8Zm0-5.273h-5.273v-.879a2.637,2.637,0,1,1,5.273,0Zm3.516-.879v7.969h-1.758v-7.969a4.4,4.4,0,0,0-4.395-4.395,6.159,6.159,0,0,1-6.152-6.152v-7.969h1.758v7.969a4.4,4.4,0,0,0,4.394,4.395A6.159,6.159,0,0,1,955.316,333.162Zm0-10.547a6.152,6.152,0,0,1-1.572,4.1,7.907,7.907,0,0,0-1.6-.88,4.383,4.383,0,0,0,1.41-3.222v-7.969h1.758Z"
            transform="translate(-941.254 -312.889)"
            fill="#80858c"
          />
        </g>
      </g>
    </svg>
  );
};
