import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid'

import useStyles from './styles'

Navigation.propTypes = {

};

function Navigation({ children }) {

  const classes = useStyles()

  return (
    <Grid item xs={12} className={classes.navigationContainer}>
      { children }
    </Grid>
  );
}

export default Navigation;