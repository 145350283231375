const styles = theme => ({
  smallTitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: '#4D4F5C',
    minHeight: 40,
    marginBottom: 0
  },
  markSpace: {
    height: 72,
    lineHeight: 0
  },
  slider: {
    display: 'flex',
    flexDirection: 'column',
  },
  sliderBg: {
    width: '100%',
    height: 32,
    background: 'transparent linear-gradient(90deg, #AFE3BF 0%, #F5E671 34%, #F1BF7A 64%, #E6884D 100%) 0% 0% no-repeat',
    '-webkit-print-color-adjust': 'exact',
    colorAdjust: 'exact',
    borderRadius: 32,
  },
  sliderProgress: {
    textAlign: 'right',
    height: 32,
    position: 'relative',
    minWidth: '32px !important'
  },
  sliderMark: {
    display: 'inline-block',
    width: 32, height: 32,
    backgroundColor: '#CCE9FA',
    borderRadius: '50%',
  },
  sliderExplain: {
    position: 'absolute',
    width: 100,
    top: '-45px',
    right: '-6px',
    transform: 'translateX(30%)',
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Roboto-Bold',
    color: '#4D4F5C',
  },
  emptyValue: {
    color: '#8D8D8D',
    width: '100%',
    fontSize: 10,
    lineHeight: 1.2,
    height: 48
  },
  description: {
    margin: 0,
    fontSize: 12,
    color: '#8D8D8D',
  },
  captions: {
    marginTop: 5,
    fontSize: 12,
    lineHeight: '14px'
  },
  leftCaption: {
    display: 'inline-block',
    float: 'left',
  },
  rightCaption: {
    display: 'inline-block',
    float: 'right',
    textAlign: 'right',
  },
  subline: {
    marginTop: 'auto',
  },
  helpIcon: {
    verticalAlign: 'bottom',
    fontSize: '1.1rem'
  },
  tooltip: {
    position: 'relative',
    padding: 20,
    backgroundColor: '#EFEFF2',
    color: '#4D4F5C',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.2)',
    whiteSpace: 'pre-line',
    maxWidth: 500
  }
})

export default styles;