import clsx from 'clsx';
import React from 'react';

import useStyles from './styles';
import {default as useTableStyles} from '../../styles';

const CorrelationTableLegend = () => {
  const classes = useStyles();
  const tableClasses = useTableStyles();

  return (
    <ul className={classes.legend}>
      <li className={classes.legendItem}>
        <div className={clsx(classes.bullet, tableClasses.strong)}></div>
        <label>sehr hohe Korrelation</label>
      </li>
      <li className={classes.legendItem}>
        <div className={clsx(classes.bullet, tableClasses.middle)}></div>
        <label>hohe Korrelation</label>
      </li>
      <li className={classes.legendItem}>
        <div className={clsx(classes.bullet, tableClasses.weak)}></div>
        <label>niedrige Korrelation</label>
      </li>
      <li className={classes.legendItem}>
        <div className={clsx(classes.bullet, tableClasses.negative)}></div>
        <label>negative Korrelation</label>
      </li>
    </ul>
  )
};

export default CorrelationTableLegend;