import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'

import useStyles from './styles'
import {DocumentsSection, TabsContainer} from "../index";
import {Grid} from "@material-ui/core";
import {GENERAL_DOCUMENTS_BANK_ID, GENERAL_DOCUMENTS_BANK_NAME} from "../../DocumentsDownload";
import {MIN_DOCUMENTS_BANKS_NUMBER} from '../constants';
import {OBJECT_TYPES} from "../../../../../../../../constants";
import {singleDepotSelected} from "../../utils";
import {TRADING_OBJECT_TYPE_TITLES} from "../../../../../../../../../Trades/constants";

BankDocuments.propTypes = {
  bank: PropTypes.object.isRequired,

  requiredDocuments: PropTypes.array.isRequired,

  optionalDocuments: PropTypes.array.isRequired,

  selectedRequiredDocuments: PropTypes.array.isRequired,

  selectedOptionalDocuments: PropTypes.array.isRequired,

  setPreviewDocument: PropTypes.func.isRequired,

  onDocumentFilterModalSwitch: PropTypes.func.isRequired,

  onDeleteOptionalDocument: PropTypes.func.isRequired,

  setSelectedOptionalDocuments: PropTypes.func
};

BankDocuments.defaultProps = {
  selectedOptionalDocuments: [],
  selectedRequiredDocuments: [],
  requiredDocuments: [],
  optionalDocuments: []
}

function BankDocuments(props) {

  const {
    banks,
    setPreviewDocument,
    downloadDocuments,
    eSignDocuments,
    onDistributionSettingsChange,
    onDocumentFilterModalSwitch,
    onDeleteOptionalDocument,
    errors
  } = props

  const classes = useStyles()

  const onSetPreviewDocument = (document, bankId, objectType) => {
    if (setPreviewDocument) {
      setPreviewDocument(document, bankId, objectType)
    }
  }

  const renderBankDocuments = (bank, bankId=undefined, objectType=undefined) => {
    const { required, general, optional, allOptional } = bank

    const isGeneralBank = bank.name == GENERAL_DOCUMENTS_BANK_NAME
    const singleBankSelected = singleDepotSelected(banks)

    return (
      <DocumentsSection
        objectType={objectType}
        bankId={bankId}
        bankName={bank && bank.name}
        isGeneralBank={isGeneralBank}
        bankNameVisible={!singleBankSelected}
        singleBankSelected={singleBankSelected}
        additionalBankText={bank.additionalBankText}
        tableHeaderVisible={true}
        isOnline={bank.isOnline}
        onlineDocuments={bank.onlineDocuments}
        documents={general.concat(required)}
        allOptional={allOptional}
        optionalDocuments={optional}
        downloadDocuments={downloadDocuments}
        eSignDocuments={eSignDocuments}
        onDistributionSettingsChange={onDistributionSettingsChange}
        disabled={false}
        onPreviewDocumentSelect={onSetPreviewDocument}
        onDocumentFilterModalSwitch={onDocumentFilterModalSwitch}
        onDeleteOptionalDocument={onDeleteOptionalDocument}
        errors={errors}
      />
    )
  }

  const generalDocumentsData = _.filter(Object.keys(banks), (objectType) => objectType == GENERAL_DOCUMENTS_BANK_ID)
  const depotsDocumentsData = _.filter(Object.keys(banks), (objectType) => objectType != GENERAL_DOCUMENTS_BANK_ID)

  return (
    <Grid item xs={12} className={classes.container}>
      {banks && !_.isEmpty(banks) && (
        <>
          {generalDocumentsData.map((objectType) => {
            return Object.keys(banks[objectType]).map((bank_id) => {
              return renderBankDocuments(banks[objectType][bank_id], bank_id, objectType)
            })
          })}
          {depotsDocumentsData.map((objectType) => {
            return (
              <>
                <p className={classes.title}>{TRADING_OBJECT_TYPE_TITLES[objectType]}</p>
                {Object.keys(banks[objectType]).map((bank_id) => {
                  return renderBankDocuments(banks[objectType][bank_id], bank_id, objectType)
                })}
              </>
            )
          })}
        </>
      )}
    </Grid>
  );
}

export default BankDocuments;