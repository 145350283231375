import React from 'react';
import _ from 'lodash';
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import usePortfolioData from "../../hooks/usePortfolioData";
import {ChartSectionBordered} from "../ChartSection/ChartSection";
import {CELL_MIN_WIDTH, ProductStructureTableStyles} from "./styles";
import DetailsLine
  from "../../../RiskDashboard/components/CustomerSummaryCard/components/EsgProfileMatchingDetails/components/DetailsLine/DetailsLine";
import {CHART_COLORS_WITH_FONTS} from "../../../../utils/constants";
import {getProductName} from "../../utils";
import TableHeaderCard from './components/TableHeaderCard';


const PRODUCT_STRUCTURE_TABLES = [
  {
    dataKey: 'by_type',
    title: 'Assetallokationen'
  },
  {
    dataKey: 'by_broad_asset_class',
    expandKey: 'by_product',
    title: 'Produktgattungen'
  },

  {
    dataKey: 'by_ms_category',
    expandKey: 'by_asset_categories',
    title: 'Morningstar-Kategorien'
  },

  {
    dataKey: 'by_currency',
    title: 'Währungen'
  },
  {
    dataKey: 'by_sector',
    title: 'Branchen'
  },
  {
    dataKey: 'by_region',
    title: 'Regionen'
  },

  {
    dataKey: 'by_srri',
    title: 'Risikostruktur'
  },

  {
    dataKey: 'by_maturity',
    title: 'Restlaufzeiten',
    orderBy: (list) => _.orderBy(list, elem => {
      let firstNumberInString = elem.split('-')[0]
      return _.isNaN(+firstNumberInString) ? Number.MAX_VALUE : +firstNumberInString
    }),
  },
  {
    dataKey: 'by_fixed_income_sub_type',
    title: 'Emittentenstruktur'
  },

  {
    dataKey: 'by_credit_quality',
    title: 'Kreditqualitäten',
    orderBy: (list) => list.sort((a, b) => {
      if(a === 'Below B' && b[0] === 'B'){
        return 1
      } else if (a[0] === 'B' && b === 'Below B'){
        return -1
      }

      return a[0].localeCompare(b[0]) || b.length - a.length
    }),
  },
]

const DUMMY_COLUMNS_LENGTH = 5

const ProductStructureTable = (props) => {

  const {
    categories,
    products,
    dataKey,
  } = props;

  const classes = ProductStructureTableStyles({rowsNumber: categories.length || DUMMY_COLUMNS_LENGTH});
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const productsAreLoading = products.some(p => p.loading);

  const renderLoadingColumns = (numberOfRows=DUMMY_COLUMNS_LENGTH) => {
    return (
      <>
        {Array(numberOfRows).fill("").map((_emptyValue, index) => {
          return(
            <Grid container className={'tr loadingContainer'} key={`loading_col_${index}`}>
              <Grid item xs={12} className={'td'}>
                {productsAreLoading && <Skeleton height={24} width={'100%'}/>}
              </Grid>
            </Grid>
          )
        })}
      </>
    )
  }

  const renderFirstColumn = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          {/* for header first cell is empty, because under it categories will render */}
          <Grid container className={'thead'}>
            <Grid container className={'tr'}>
              <Grid item xs={12} className={'td'} key={'first_col_empty_sell'}>
                <div className={classes.headerCellContentWrapper} />
              </Grid>
            </Grid>
          </Grid>
          {_.isEmpty(categories)
            ? (renderLoadingColumns())
            : categories.map((category, index) => {
              return(
                <Grid container className={'tr'} key={`first_col${index}`}>
                  <Grid item xs={12} className={'td'}>
                    <div className={classes.cellContentWrapper}>
                      <div className={classes.cellContent} title={category}>
                        {category}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )
            })}
        </Grid>
      </Grid>
    )
  }

  const renderTableHeader = () => {
    return (
      <Grid container className={'tr'}>
        {products.map(p => {
          const isHighlighted = p.productData.configuration.highlighted;
          const name = getProductName(p.productData);
          return <TableHeaderCard name={name} isHighlighted={isHighlighted} key={p.productData.product_id} />
        })}
      </Grid>
    )
  }


  const renderDataColumns = () => {
    let colorsList = Object.keys(CHART_COLORS_WITH_FONTS)
    return(
      <Grid container style={{flexWrap: 'nowrap', height: '100%'}} className={classes.tbodyContent}>
        {products.map((p, pIndex) => {
          // displaying info that data was not loaded
          if(!_.isNil(p.errors) || (!p.loading && _.isEmpty(_.get(p, `data.${dataKey}`, {})))) {
            return (
              <Grid className={'tr'} key={`no_data_col_${pIndex}${p.productData.product_id}`} item xs={2}>
                <Grid item xs={12} className={classes.noData}>
                  <div className={classes.headerCellContentWrapper} style={{height: 'auto'}}>
                    <div className={classes.dynamicEllipsis} title={'Für dieses Instrument steht diese Statistik nicht zu Verfügung.'}>
                      {/* These statistics are not available for this instrument. */}
                      Für dieses Instrument steht diese Statistik nicht zu Verfügung.
                    </div>
                  </div>
                </Grid>
              </Grid>
            )
          }

          // if categories are not loaded yet, return dummy structure with preloaders
          if(_.isEmpty(categories)){
            return <Grid item xs={2} style={{minWidth: CELL_MIN_WIDTH}} className={'loadingCol'}>{renderLoadingColumns()}</Grid>
          }

          return(
            <Grid className={'tr'} key={`data_col_${pIndex}${p.productData.product_id}_${dataKey}`} item xs={2}>
              {categories.map((category, index) => {
                // % is used to cover case when index is bigger than colorsList length. It will start getting colors from beggining
                const fillColor = colorsList[index % colorsList.length]
                let weightInPercentage = !p.loading && (_.get(p.data[dataKey][category], 'weight', 0)*100).toFixed(2);

                const displayProgressLabelOutside = Math.abs(weightInPercentage) < (isBelowMd ? 45 : 43);
                return (
                  <Grid item xs={12} className={clsx('td', p.productData.configuration.highlighted ? 'highlightedCell' : '')} key={`data_col_${pIndex}_cell_${index}`}>
                    {p.loading
                      ? ( <Skeleton height={24} width={'100%'}/> )
                      : (
                        <DetailsLine
                          currentValue={weightInPercentage}
                          filledPercentage={weightInPercentage}
                          backgroundHeight={24}
                          isUnderweighted={true} // passed to display percentage inside the line
                          withoutOverweightIcon={true}
                          unitOfMeasure={' %'}
                          filledPercentageColor={displayProgressLabelOutside ? 'black' : CHART_COLORS_WITH_FONTS[fillColor]}
                          customClasses={classes}
                          fillColor={fillColor}
                          displayProgressLabelOutside={displayProgressLabelOutside}
                          useInvertedLine={weightInPercentage < 0}
                        />
                      )}
                  </Grid>
                )
              })}
            </Grid>
          )
        })}
      </Grid>
    )
  }

  return (
    <Grid container className={classes.productStructureTable}>
      <Grid item className={classes.firstColumn}>
        {renderFirstColumn()}
      </Grid>

      <Grid item style={{overflow: 'auto'}} xs={true}>
        <Grid container style={{height: '100%'}}>
          <Grid item xs={12} className={'thead'} style={{maxHeight: 51}}>
            {renderTableHeader()}
          </Grid>

          <Grid item xs={12} className={'tbody'} style={{height: 'calc(100% - 51px)'}}>
            {renderDataColumns()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const ProductStructureTableContainer = (props) => {

  const {
    title,
    dataKey,
    expandKey,
    productsPortfolioData,
    expandedSectionsRef,

  } = props;

  const [expanded, setExpanded] = React.useState(expandedSectionsRef.current.structureTab[expandKey])
  React.useEffect(() => {
    setExpanded(expandedSectionsRef.current.structureTab[expandKey])
  },[expandedSectionsRef.current])

  const onExpanded = (newExpandedState) => {
    setExpanded(newExpandedState)
    expandedSectionsRef.current.structureTab[expandKey] = newExpandedState
  }

  let categories = []
  let products = _.sortBy(Object.values(productsPortfolioData), p => p.order)

  let isLoading = false
  products.map(productPortfolioData => {
    categories.push(...Object.keys(_.get(productPortfolioData, `data.${dataKey}`, {})))}
  )

  // leaving only uniq categories
  categories = _.uniq(categories);
  if (PRODUCT_STRUCTURE_TABLES[props.tableIndex].orderBy) {
    categories = PRODUCT_STRUCTURE_TABLES[props.tableIndex].orderBy(categories)
  }


  return (
    <Grid item xs={12}>
      <ChartSectionBordered
        title={<b>{title}</b>}  // add config icon when config func is ready <span style={{fontSize: 16}} className={classes.cogIcon}><i className="fal fa-cog"></i></span>
        expanded={expanded}
        onExpanded={onExpanded}
        content={
          <Grid container>
            <Grid item xs={12}>
              <ProductStructureTable
                categories={categories}
                products={products}
                dataKey={dataKey}
              />
            </Grid>
          </Grid>
        }
      />
    </Grid>
  )
}


function ProductStructureTab(props) {
  const portfolioData = usePortfolioData(props.allProducts, props.selectedProducts, props.selectedDates, PRODUCT_STRUCTURE_TABLES.map( t => t.dataKey));
  return (
    <Grid container spacing={2}>
      {PRODUCT_STRUCTURE_TABLES.map((t, index) =>
        <ProductStructureTableContainer
          key={`${t.dataKey}${index}`}
          tableIndex={index}
          title={t.title}
          dataKey={t.dataKey}
          expandKey={t.expandKey || t.dataKey}
          productsPortfolioData={portfolioData}
          expandedSectionsRef={props.expandedSectionsRef}
        />)
      }
    </Grid>
  )
}

export default ProductStructureTab