import React from 'react';

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/core/styles";

import {isRequiredQuestion} from "../../../../../utils";
import {FIELD_REQUIRED_MSG} from "../../../../../constants";
import styles from "./styles";
import {useStyles} from "../../formElement/styles"
import FormHelperText from "@material-ui/core/FormHelperText";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import {CircularProgress, DialogTitle, IconButton} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ProtocolPreview from "../../../../ProtocolPreview/ProtocolPreview";
import {QuestionnairesHandlerResource} from "../../../../../../../utils/api";
import {getErrorMessage, getFileNameFromResponse} from "../../../../../../../utils/utils";
import Grid from "@material-ui/core/Grid";


function isFileQuestionValid (question, step, afterChange, service) {
  let isValid = true
  if (isRequiredQuestion(question)) {
    if (!question.answer || !(question.answer.guid || question.answer.formData)) {
      isValid = false
      question.error = question.required_msg || FIELD_REQUIRED_MSG
    } else {
      question.error = null
    }
  }

  return isValid
}

function getFileQuestionAnswer(question) {
  let answer = [];
  if (question.answer && question.answer.guid) {
    answer.push(question.answer && question.answer.guid)
  }

  return {
    question_uid: question.uid,
    answer: answer
  };
}

function setFileQuestionAnswer(question, answer) {
  if (answer && answer.length > 0 && typeof answer[0] === "string") {
    question.answer = {guid: answer[0]}
  }
}

export const LegitimationDocumentsQuestionMethods = {
  required: isRequiredQuestion,
  valid: isFileQuestionValid,
  answer: getFileQuestionAnswer,
  initAnswer: setFileQuestionAnswer
}

const LegitimationDocumentsQuestion = props => {
  const {
    classes,
    question,
    onAnswerChange
  } = props;

  const inputClasses = useStyles();

  const [openPreviewDialog, setOpenPreviewDialog] = React.useState(false);
  const [previewData, setPreviewData] = React.useState({
    data: null,
    loading: true,
    errors: undefined,
    date: Date.now()
  });

  React.useEffect(() => {
    if(!question.answer){
      question.answer = {guid: null, formData: null};
      setPreviewData({...previewData, data: null})
    } else if (question.answer.guid){
      fetchPreviewContent();
    } else if (question.answer.formData){
      initPreviewFromFile(question.answer.formData.get('file'));
    }
  }, [question.answer]);

  const fetchPreviewContent = async () => {
    if(previewData.data) {
      return; // preview already exists
    }

    try {
      QuestionnairesHandlerResource.getDocument(question.answer.guid).then(response => {

        !question.disabled && setPreviewData({
          data: {
            content: response.data,
            name: getFileNameFromResponse(response, true) || ""
          },
          loading: false,
          errors: undefined,
          date: Date.now()
        })
        let formData = new FormData();
        let fileBlob = new Blob([response.data], {type: response.headers['content-type']})
        formData.append('file', fileBlob, getFileNameFromResponse(response, true));
        question.fileFormData = formData
        onAnswerChange(question.uid, question.answer)

      }).catch(error => {
        const errors = error.detail || error.message || error;
        setPreviewData({
          ...previewData,
          loading: false,
          errors,
          date: Date.now()
        })
      });
    } catch (errors) {
      setPreviewData({
        ...previewData,
        loading: false,
        errors,
        date: Date.now()
      })
    }
  };

  const label = question.question_text;
  const required = LegitimationDocumentsQuestionMethods.required(question);
  const existingFileGUID = question.answer && question.answer.guid;
  const previewMode = !!(previewData.data || existingFileGUID);

  const handleChange = (event) => {
    question.error = null;  // clean error
    let formData;
    const f = event.target.files[0];
    if(f){
      formData = new FormData();
      formData.append('file', f, f.name);
      initPreviewFromFile(f);
    }
    onAnswerChange(question.uid, {...question.answer, formData: formData});
  };

  const initPreviewFromFile = (f) => {
    const reader = new FileReader();

      reader.onload = function(e) {
        !question.disabled && setPreviewData({
          data: {
            content: reader.result,
            name: f.name
          },
          loading: false,
          errors: undefined,
          date: Date.now()
        })
      };

      reader.readAsArrayBuffer(f);
  }

  const isPDF = (fileName) => {
    let pdfExtensionRegExp = new RegExp('(\.pdf)$', 'gmi')

    return fileName.match(pdfExtensionRegExp)
  }

  const handleRemoveAnswer = () => {
    setPreviewData({
      data: null,
      loading: true,
      errors: undefined,
      date: Date.now()
    });
    onAnswerChange(question.uid, {formData: null, guid: null});
  }

  const openPreview = () => {
    fetchPreviewContent();
    setOpenPreviewDialog(true);
  }

  const ImagePreview = () => {
    const file = new File([previewData.data.content], previewData.data.name);
    return <img alt={file.name} src={URL.createObjectURL(file)} style={{maxWidth: 380}} />
  }

  return (
    <FormControl error={!!question.error} classes={{root: inputClasses.textFieldRoot}} disabled={question.disabled}>
      <InputLabel
        shrink={true}
        required={required}
        classes={{
          root: clsx(inputClasses.labelRoot, question.custom_classes && question.custom_classes.labelRoot),
          focused: inputClasses.labelFocused,
          asterisk: inputClasses.labelAsterisk
        }}>
        {label}
      </InputLabel>
      {!previewMode && (
        <>
          <TextField
            id={question.uid}
            inputProps={{
              accept: question.config && question.config.accept || '*'
            }}
            className={classes.input}
            type="file"
            required={required}
            onChange={handleChange}
            disabled={question.disabled}
          />
          <InputLabel htmlFor={question.uid} classes={{root: clsx(inputClasses.inputRoot, classes.label)}}>
              Dokument auswählen
              <Button variant="contained" color="primary" component="span" classes={{root: classes.endAdornmentBtn}} disabled={question.disabled}>
                Durchsuchen
              </Button>
        </InputLabel>
        </>
      )}

      {previewMode && (
        <Grid container className={clsx(inputClasses.inputRoot, classes.fileContainer, 'MuiInputLabel-formControl')}>
          <Grid item className={classes.previewLink} onClick={openPreview}>
            {previewData.data ? previewData.data.name : existingFileGUID}
          </Grid>
          <Grid item className={classes.removeDocumentWrapper}>
            <IconButton disableRipple aria-label="close" onClick={handleRemoveAnswer}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <Dialog fullWidth maxWidth={'xs'} open={!!openPreviewDialog} onClose={() => setOpenPreviewDialog(false)}>
        <DialogTitle>
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpenPreviewDialog(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.previewContent}>
          {!previewData.loading && !previewData.errors && !!previewData.data && (
            isPDF(previewData.data.name) ?
              <ProtocolPreview content={previewData.data.content} width={380} /> : <ImagePreview />
          )}
          {previewData.loading && (
            <div>
               <CircularProgress color="primary" size={50}/>
            </div>
          )}
          {previewData.errors && (
            <p>
              {getErrorMessage(previewData.errors)}
            </p>
          )}
        </DialogContent>
      </Dialog>
      {question.question_info && (
        <FormHelperText margin={'dense'} style={{marginTop: 15, color: 'black', fontSize: '0.875rem'}}>{question.question_info}</FormHelperText>
      )}
      {question.error && (
        <FormHelperText error={true} margin={'dense'}>{question.error}</FormHelperText>
      )}
    </FormControl>
  )
}

export default withStyles(styles)(LegitimationDocumentsQuestion);