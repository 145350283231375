export default theme => ({
  rootCollapsed: {
    minHeight: 'auto',
    position: 'relative',
  },
  root: {
    minHeight: '444px',
    position: 'relative',
    '& [class*="contentWrapper"]': {
      paddingTop: 10,
    }
  },
  rootEmpty: {
    minHeight: '444px',
    position: 'relative',
    backgroundColor: '#F0F0F0',
    '& h2': {
      color: '#80858C',
    }
  },
  content: {
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    padding: '.6rem 1rem .2rem 1rem',
    borderBottom: 'solid 1px #D8DCDF',
  },
  values: {
    width: '20%',
    textAlign: 'right'
  },
  title: {
  },
  subtitleCorrection: {
    padding: '20px',
    [theme.breakpoints.down("sm")]: {
      padding: 15
    }
  }
});
