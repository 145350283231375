import {makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
  selectSection: {
    display: 'flex',
    alignItems: 'center',

    '& > span': {
      fontFamily: 'Roboto-Bold',
      fontSize: 14,
      color: '#AFAFAF',
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    }
  },
  selectFormControl: {
    minWidth: 140,
    marginLeft: 8,
    [theme.breakpoints.up('sm')]: {
      marginRight: 8,
    },
  },
  headerValuesContainer: {
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'right',

      '& > .title': {
        fontFamily: 'Roboto-Regular'
      },
      '& > .value': {
        fontFamily: 'Roboto-Bold'
      },

      minWidth: 105,
    }
  }
}))