import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',
    backgroundColor: 'white',
    borderRadius: 2,
    padding: '10px 10px',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    },
  },
  actions: {
    display: 'flex'
  },
  data: {
    '& > span:first-child': {
      width: 200,
      height: 40
    },
    '& > span:last-child': {
      width: 300,
    }
  },
  customerName: {
    color: '#2A3545',
    fontSize: 20,
    fontFamily: 'Roboto-Regular',
    margin: 0
  },
  handlingTime: {
    fontFamily: 'Roboto-Regular',
    fontSize: 13,
    color: '#323C42',
    margin: 0
  },
  subNavigationButton: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 24,
    position: 'relative',
    padding: 5,

    '&:hover': {
      cursor: 'pointer'
    },

    '&:hover span:first-child': {
      width: '100%',
      height: '100%',
      borderRadius: 5
    },

    '& span:first-child': {
      transition: 'all',
      transitionDuration: 150,
      height: 28,
      width: 28,
      borderRadius: 20,
      backgroundColor: '#CCE9FA',
      position: 'absolute',
    },

    '& span:nth-child(2)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 28,
      width: 28,
      borderRadius: 20,
      marginRight: 8,
      position: 'relative',
      zIndex: 99
    },

    '& span:last-child': {
      fontFamily: 'Roboto-Bold',
      fontSize: 14,
      color: theme.palette.primary.main,
      position: 'relative',
      zIndex: 99
    }
  },
  disabled: {
    '& span:first-child': {
      backgroundColor: '#dddddd;',
    },
    opacity: 0.5,
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  deleteButtonContainer: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  deleteHelperTitle: {
    fontSize: '13px',
    textAlign: 'center'
  },
  modalContainer: {
    width: '952px',
  }
})

export default makeStyles(styles)