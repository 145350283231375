import { toGermanFormat } from "../../utils/numberFormater";
import moment from 'moment/moment';
import {NO_DATA} from "../../utils/constants";

export const formatPercentage = (number, multiplier = 1) => {
  return (number || number === 0)
    ? toGermanFormat(number * multiplier, '', ' %', 2)
    : 'k.A.';
};

export const formatYesNo = (bool) => {
  return (bool === true) ? 'Ja' : (bool === false) ? 'Nein' : 'k.A.';
};

export const toShortGermanFormat = (number, prefix='', suffix='', fraction=2, lose_decimals=false) => {
  let _suffix = '';
  let value = number;

  try {
    if (number >= 1000 || number <= -1000) {
      _suffix = ' Tsd.';
      value = number / 1000;
    }
    if (number >= 1000000 || number <= -1000000) {
      _suffix = ' Mio.';
      value = number / 1000000;
    }

    return toGermanFormat(value, prefix, _suffix + suffix, fraction, lose_decimals);

  } catch (err) {
    return number;
  }
}

/**
 * Function to sort passed array by customSortFunction. If customSortFunction not passed - sorts by weight
 * @param array to sort
 * @param calculateNoData flag that indicates if "No data" should be added if it is not in array
 * @param skipOrdering flog to skip ordering by weight
 * @returns {*|*[]}
 */
export const sortByWeight = (array, calculateNoData=true, skipOrdering) => {
  let noDataNames = [NO_DATA];
  // check if "no data" is passed in array
  let no_data = array.find(obj => (noDataNames.includes(obj.name)));
  // get array without "No data"
  let sortedArr = array.filter(obj => (!noDataNames.includes(obj.name)));

  if (calculateNoData) { // calculate no_data as amount left up to 100%
    let noDataWeight = array.reduce(function(minuend, item) {
      return minuend - item.weight;
    }, 1);
    if(no_data) noDataWeight += no_data.weight;
    if(noDataWeight * 100 > 0.009) { // checking if "no_data" have sense to display, and we not show 0.00
      sortedArr.push({name: NO_DATA, weight: noDataWeight})
    }
  } else if (no_data) {
    sortedArr.push({name: NO_DATA, weight: no_data.weight})
  }

  // sorting array without order by weight
  if(!skipOrdering){
    sortedArr = sortedArr.sort((a,b) => b.weight - a.weight)
  }

  return array.length ? sortedArr : [];
};

export const isDateDifferenceEqualOrBiggerThanYear = (date) => {
  return moment().diff(moment(date), 'years') >= 1
};

export const calculateSwitchPlanPossible = (custodian_data) => {
  const dws_company_ids = ['10953', '10000', '10965'];
  const ebase_company_id = '10967';
  const ffb_company_ids = ['10962', '10986'];
  const fodb_company_id = '10968';
  const banks_for_calc = [...dws_company_ids, ebase_company_id, ...ffb_company_ids, fodb_company_id];

  return banks_for_calc.includes(String(custodian_data.c_company_id)) &&
    custodian_data.c_hard_close_subscription === false &&
    custodian_data.c_hard_close_redemption === false &&
    custodian_data.c_savings_plan_possible === true &&
    custodian_data.c_payout_plan_possible === true
};
