import { makeStyles, createStyles } from '@material-ui/core'
import {smFontSize, xsFontSize} from "../../../utils/constants";

export const styles = theme => createStyles({
  selectRoot: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    padding: 5,

    '&::before': {
      borderBottom: 'none',
      display: 'none'
    },
    '&::after': {
      borderBottom: 'none',
      display: 'none'
    }
  },
  selectMenuPaper: {
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    border: 'none',
    maxHeight: 450,
  },
  selectMenuItemRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'initial !important',
    fontFamily: 'Roboto-Regular !important',
    fontSize: 16,
    backgroundColor: 'white !important',
    padding: 17,
    minHeight: '2rem',

    '&.bold': {
      fontFamily: 'Roboto-Bold !important',
    },

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },

    '& .MuiListItemText-root span': {
      fontFamily: 'Roboto-Regular',
      fontSize: 16,
      color: '#202A38',
    },

    [theme.breakpoints.only('sm')]: {
      fontSize: smFontSize,
    },

    [theme.breakpoints.only('xs')]: {
      fontSize: xsFontSize,
    },
  },
  selectMenuIcon: {
    color: '#202A38'
  },
  selectMenu: {
    background: 'transparent !important',
  },
  selectOutlined: {
    width: '100%'
  }
})

export default makeStyles(styles)