import React from "react";
import {withRouter} from "react-router-dom";
import _ from 'lodash';

import DashboardTable from "../../DashboardTable/DashboardTable";
import {getTableStructure, getTradingTableStructure} from "./table-data";
import useStyles, {useTradingTableClasses} from "./styles";

const PaymentPlansTable = (props) => {
  const classes = useStyles();
  const tradingClasses = useTradingTableClasses();

  const {
    data,
    expanded,
    match: {
      params: {
        customer_id
      }
    },
    tradingOptions,
  } = props;

  return (
    <DashboardTable
      dataSourceKeyField={'payment_id'}
      structure={!!tradingOptions
        ? getTradingTableStructure(props.paymentPlanType)
        : getTableStructure(props.paymentPlanType)}
      dataSource={data}
      expanded={expanded}
      tableClasses={!!tradingOptions ? tradingClasses : classes}
      options={{
        customer_id,
        ...tradingOptions
      }}
      withFooter={false}
      useInternalCollapse={data.some(item => _.get(item, 'tableProps.isHeader'))}
    />
  )
};


export default withRouter(PaymentPlansTable);