import React from 'react';

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/core/styles";

import styles from "./styles";
import {useStyles} from "../../../../../RiskProfiling/components/StepContent/components/formElement/styles"
import FormHelperText from "@material-ui/core/FormHelperText";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import {CircularProgress, DialogTitle, IconButton} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import ProtocolPreview from "../../../../../RiskProfiling/components/ProtocolPreview/ProtocolPreview";
import {getErrorMessage} from "../../../../../../utils/utils";
import Dialog from "@material-ui/core/Dialog";



const SingleFileUploadInput = props => {
  const {
    classes,
    file, // additionalFile
    onFileUpload,
    onFileRemove,
    filesToAccept,

    title
  } = props;

  const inputClasses = useStyles();

  const [openPreviewDialog, setOpenPreviewDialog] = React.useState(false);
  const [previewData, setPreviewData] = React.useState({
    data: null,
    loading: true,
    errors: undefined,
    date: Date.now()
  });

  React.useEffect(() => {
    if(!file.formData){
      setPreviewData({...previewData, data: null})
    } else if (file.formData){
      initPreviewFromFile(file.formData.get('file'));
    }
  }, [file]);


  const existingFileGUID = file && file.guid;
  const previewMode = !!(previewData.data || existingFileGUID);

  const handleChange = (event) => {
    let formData;
    const f = event.target.files[0];
    if(f){
      formData = new FormData();
      formData.append('file', f, f.name);
      initPreviewFromFile(f);
    }
    onFileUpload(formData, f)
  };

  const openPreview = () => {
    setOpenPreviewDialog(true);
  }

  const initPreviewFromFile = (f) => {
    const reader = new FileReader();

      reader.onload = function(e) {
        setPreviewData({
          data: {
            content: reader.result,
            name: f.name
          },
          loading: false,
          errors: undefined,
          date: Date.now()
        })
      };

      reader.readAsArrayBuffer(f);
  }

  const handleRemoveFile = () => {
    onFileRemove(file)
  }

  const renderUploadDocument = () => {
    return (
      <>
        <TextField
          id={`${file.id}`}
          inputProps={{
            accept: file.config && file.config.accept || filesToAccept || '*'
          }}
          className={classes.input} // classes.input
          type="file"
          // required={required}
          onChange={handleChange}
        />
        {/* to make input work as document upload, htmlFor and id of TextField should have the same value */}
        <InputLabel htmlFor={file.id} classes={{root: clsx(inputClasses.inputRoot, classes.label)}}>
            Dokument auswählen
            <Button variant="contained" color="primary" component="span" classes={{root: classes.endAdornmentBtn}} >
              Durchsuchen
            </Button>
        </InputLabel>
        {props.withExplanationText && (
          <div style={{marginTop: 20}}>
            {props.explanationText || 'Hier haben Sie die Möglichkeit weitere Dokumente Ihrem Report hinzuzufügen.'}
          </div>
        )}
      </>
    )
  }

  const renderPreview = () => {
    return (
      <>
        <Grid container className={clsx(inputClasses.inputRoot, classes.fileContainer, 'MuiInputLabel-formControl')}>
          <Grid onClick={openPreview} item className={classes.previewLink}>
            {previewData.data ? previewData.data.name : existingFileGUID}
          </Grid>
          <Grid item className={classes.removeDocumentWrapper}>
            <IconButton onClick={handleRemoveFile} disableRipple aria-label="close" >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <FormControl error={!!file.error} classes={{root: inputClasses.textFieldRoot}} disabled={file.disabled}>
      {!!title && (
        <InputLabel
          shrink={true}
          classes={{
            root: clsx(inputClasses.labelRoot, file.custom_classes && file.custom_classes.labelRoot),
            focused: inputClasses.labelFocused,
            asterisk: inputClasses.labelAsterisk
          }}>
          {title}
        </InputLabel>
      )}
      {file && file.id === 0 && !previewMode ? (
        <> { renderUploadDocument() } </>
      ) : (
        <> { renderPreview() } </>
      )}

      <Dialog fullWidth maxWidth={'xs'} open={!!openPreviewDialog} onClose={() => setOpenPreviewDialog(false)}>
        <DialogTitle>
          <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpenPreviewDialog(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.previewContent}>
          {!previewData.loading && !previewData.errors && !!previewData.data && (
              <ProtocolPreview content={previewData.data.content} width={380} />
          )}
          {previewData.loading && (
            <div>
               <CircularProgress color="primary" size={50}/>
            </div>
          )}
          {previewData.errors && (
            <p>
              {getErrorMessage(previewData.errors)}
            </p>
          )}
        </DialogContent>
      </Dialog>

      {file.error && (
        <FormHelperText error={true} margin={'dense'}>{file.error}</FormHelperText>
      )}
    </FormControl>
  )
}

SingleFileUploadInput.defaultProps = {
  title: 'Zusätzliches Dokument hochladen',
  withExplanationText: true
}

export default withStyles(styles)(SingleFileUploadInput);