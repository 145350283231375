import {parseResponse, PortfolioHandlerResource} from "../../../utils/api";
import useFetchData from "../../../hooks/useDataFetch";
import React from "react";

function parseCustomerData(response) {

  let customerData = null
  let customerErrors = null

  parseResponse(response, 'customer', (data) => customerData = data, (errors) => customerErrors = errors)

  if (customerErrors) {
    throw customerErrors
  }

  return customerData
}

export function useCustomerAppData(customerId, params) {

  const [customerData, fetchCustomerData] = useFetchData(
    `${PortfolioHandlerResource.resourceUrl}customer/${customerId}/customer-app-data/`, 'get', parseCustomerData, true)

  React.useEffect(() => {
    fetchCustomerData(params)
  },  [])

  return customerData

}