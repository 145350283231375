import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';

/* BCA modules*/
import useStyles from './styles';

import { DEFAULT_REPORT_TYPE, REPORT_TYPE_OPTIONS, REPORT_TYPES } from './constants';
import CustomDashboardTypeConfiguration from '../../../../../CustomDashboardTypeConfiguration/index';
import Select from '../../../../../CustomerDashboardV2/components_v2/Select';

export default (props) => {
  const classes = useStyles();

  const [value, setValue] = React.useState(props.selected || DEFAULT_REPORT_TYPE.value);
  const [dashboardConfigurationOpened, setDashboardConfigurationOpened] = React.useState(false);
  //save in internal state, split on states, approach callback, mb use context

  // workaround to update inner values from outside
  React.useEffect(() => {
    if (!_.isNil(props.selected)) {
      setValue(props.selected);
    }
  }, [props.selected]);

  const handleChange = (newVal) => {
    setValue(newVal);
    props.handleChanged(newVal);
  };

  const handleCustomReportSettingsChange = (settings) => {
    props.handleCustomReportSettingUpdate(settings);
  };


  return (
    <div className={classes.container}>
      <Select
        value={value}
        onChange={handleChange}
        disabled={props.disabled}
        fullWidth
        withDefault={false}
        name={
          <span className={classes.label}>
            <span>Depotansicht</span>
            <span
              id="report-settings-configuration"
              className={clsx(classes.reportSettingsButton, value != REPORT_TYPES.CUSTOM.value && classes.disabled)}
              onClick={() => setDashboardConfigurationOpened(true)}
            >
              <span>
                <i className="fa fa-cog" aria-hidden="true"/>
              </span>
              <span>Konfigurieren</span>
            </span>
          </span>
        }
        options={REPORT_TYPE_OPTIONS}
      />
      <CustomDashboardTypeConfiguration
        customReportTypeSettings={props.customReportTypeSettings || {}}
        dashboardConfigurationOpened={dashboardConfigurationOpened}
        onClose={() => setDashboardConfigurationOpened(false)}
        handleCustomReportSettingsChange={handleCustomReportSettingsChange}
      />
    </div>
  );
}