import CommonAnalysisCard from "../../../../components/AnalysisCard";
import TimeRangesButtonsPad from "../../../../../../components/TimeRangeButtonsPad";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import {parseResponse, VirtualPortfolioHandlerResource} from "../../../../../../utils/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import {toShortGermanFormat} from "../../../../../../utils/numberFormater";
import {HistoricalValueChart} from "fincite-react-charts";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from '../../../../../../components/Tooltip'
import moment from "moment";
import clsx from "clsx";
import { DEFAULT_EMPTY_SECTION_MESSAGE } from '../../../../../CustomerDashboard/constants';

class ChartPortfolioReturn extends React.Component {

  //This component is filled with data when time ranges button pad is initialized

  constructor(props) {
    super(props);

    const { customerId, portfolioId } = this.props

    this.state = {
      dataProvider: VirtualPortfolioHandlerResource,
      customerId: customerId,
      portfolioId: portfolioId,
      dateStart: undefined,
      dateEnd: undefined,
      loading: true,
      data: undefined,
      error: undefined,
      benchmarkData: undefined,
      benchmarkError: undefined
    }

    this.onRangeChange = this.onRangeChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.reloadData = this.reloadData.bind(this);

  }

  onRangeChange(dateStart, dateEnd, _) {
    this.setState({
      dateStart: dateStart,
      dateEnd: dateEnd,

    }, this.reloadData)
  }

  renderBenchmarkToolTip(){
    let listItems = this.state.benchmarkData['benchmark_details'].map((item, index) => (
      <li key={index}>
        <span><b>{item.percentage}%</b>&nbsp;{item.benchmark_name}</span>
      </li>
    ))

    return <ul>
      {listItems}
    </ul>
  }

  renderChart() {

    const { classes } = this.props;
    const portfolio = this.state.data;
    const benchmark = this.state.benchmarkData;

    const timeSeries = portfolio['timeseries']['portfolio'].map(value => ({date: value.date, value: (value.cum_ret_g || 0) * 100}));

    let benchmarkTimeSeries;
    if (benchmark) {
      benchmarkTimeSeries = benchmark['time_series']['portfolio'].map(value => ({date: value.date, value: (value.cum_ret_g || 0) * 100}));

    } else {
      benchmarkTimeSeries = []
    }

    let warnings = []

    if (benchmark && benchmark.hasOwnProperty('warning_data') && benchmark['warning_data']) {
      for (let [index, entry] of benchmark['warning_data'].entries()) {
        warnings.push(<li key={index}>
          Zeitreihen für <b>{entry["name"]}</b> verfügbar ab {moment(entry['first_available_date']).format('DD.MM.YYYY')}
        </li>)
      }
    }

    const defaultChartColor = '#0596CE';
    const defaultBenchmarkColor = '#7ED2CF';
    const chartLegendStyle = {
      background: defaultChartColor
    };
    // needed for future functionality

    const benchmarkLegendStyle = {
      background: defaultBenchmarkColor
    };

    return <div className={classes.chartContainer}>
      <ul className={classes.legend}>
        <li className={classes.legendItem}>
          <div className={classes.bullet} style={chartLegendStyle} />
          <label>{portfolio.name}</label>
        </li>

        {/*needed for future functionality*/}

        <li className={clsx(classes.legendItem, this.state.benchmarkError ? classes.disabled : undefined)}>
          <div className={classes.bullet} style={benchmarkLegendStyle} />
          <label>Benchmark</label>
          <Tooltip
            placement={'left'}
            disableHoverListener={this.state.benchmarkError !== undefined}
            disableTouchListener={this.state.benchmarkError !== undefined}
            disableFocusListener={this.state.benchmarkError !== undefined}
            title={
              <div>
                {this.state.benchmarkError && 'Keine Benchmark erstellt'}
                {this.state.benchmarkData && this.renderBenchmarkToolTip()}
              </div>
            }
          >
            <ErrorIcon className={clsx(classes.helpIcon, this.state.benchmarkError ? classes.disabledIcon : undefined)}/>
          </Tooltip>
        </li>
      </ul>
      <HistoricalValueChart
        title={''}
        colors={[defaultChartColor, defaultBenchmarkColor]}
        data={[{
          name: portfolio['name'],
          color: defaultChartColor,
          portfolio: timeSeries
        }, {
          // This empty data added to make chart appearance as line instead of
          // areaspline
          name: 'Benchmark',
          color: defaultBenchmarkColor,
          portfolio: benchmarkTimeSeries
        }]}
        chartOptions={{
          chart: {
            type: 'line',
            height: 300,
            style: {
              fontFamily: "\"Roboto-Regular\", \"Lucida Sans Unicode\", Verdana, Arial, Helvetica, sans-serif",
              fontSize: 14
            }
          },
          rangeSelector: {
            enabled: false,
            allButtonsEnabled: false,
            inputEnabled: false,
            verticalAlign: 'top',
            buttons: null,
          },
          navigator: {
            enabled: false
          },
          legend: {
            enabled: false,
          },
          scrollbar: {
            enabled: false
          },
          plotOptions: {
            area: {
              fillColor: undefined
            },
            series: {
              type: 'line',
              fillColor: undefined,
              compareBase: 0,
              showInNavigator: true,
              dataGrouping: {
                dateTimeLabelFormats: {
                  week: ["Woche vom %A, %b %e, %Y"],
                }
              }
            }
          },
          tooltip: {
            dateTimeLabelFormats: {
              day: "%b %e, %Y"
            },
            pointFormat: '<span><div style="display: inline-block; vertical-align: baseline; width: 10px; height: 10px; border-radius:50%; background-color: {series.color};" /><div style="display: inline; font-size: 14px; color: #80858C; margin-left: 15px;">{point.y} %</div></span><br/>',
            valueDecimals: 2,
            split: false,
            shared: true,
            backgroundColor: 'white',
            shadow: {
              color: '#00000026',
              offsetX: 0,
              offsetY: 0,
              width: 10,
              opacity: 0.15
            },
            padding: 20,
            useHTML: true,
            shape: 'square',
            borderWidth: 0,
            borderRadius: 4,
            headerFormat: '<span style="font-size: 14px; font-family: Roboto-Bold;">{point.key}</span><br/><br/>',
            style: {
              fontFamily: '"Roboto-Regular"'
            }
          },
          yAxis: {
            opposite: false,
            align: 'right',
            labels: {
              enabled: true,
              align: 'right',
              formatter: function() {
                return toShortGermanFormat(this.value, '', ' %', 2, true);
              },
              style: {
                fontSize: 14
              }
            }
          },
          xAxis: {
            tickLength: 0,
            labels: {
              style: {
                fontSize: 14
              }
            }
          },
          responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                tooltip: {
                  positioner: function (labelWidth, labelHeight, point) {
                    return point.isHeader ? this.getPosition(labelWidth, labelHeight, point) : { x: 80, y: 30 };
                  }
                }
              }
            }]
          }
        }}
      />
      {warnings && <ul className={classes.description}>
        {warnings}
      </ul>}
    </div>
  }

  fetchData() {

    this.state.dataProvider.getTimeWeightedReturnData(this.state.customerId, [this.state.portfolioId], this.state.dateStart, this.state.dateEnd)
        .then((response) => {
          parseResponse(response, 'time_weighted_return',
            data => {
              this.setState({
                data: data[0],
                loading: false,
                error: undefined
              })
            },
            error => {
              this.setState({
                data: undefined,
                loading: false,
                error: error
              })
            }
          )

          parseResponse(response, 'benchmark_timeseries',
            data => {
              this.setState({
                benchmarkData: data,
                benchmarkError: undefined
              })
            },
            error => {
              this.setState({
                benchmarkData: undefined,
                benchmarkError: error
              })
            }
          )

        })
        .catch((error) => {
        this.setState({
          data: undefined,
          error: error,
          benchmarkData: undefined,
          benchmarkError: error,
          loading: false
        })
      })
  }

  reloadData() {
    this.setState({loading: true}, this.fetchData)
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
      title={'Zeitgewichtete Rendite in %'}
      classes={{
        card: classes.root
      }}
      content={
        <>
          <TimeRangesButtonsPad
            onRangeChange={this.onRangeChange}
            disabled={this.state.loading}
          />
          <div className={classes.container}>
            {this.state.loading ? <CircularProgress/> : this.state.error ? this.state.error : this.state.data ? this.renderChart() : DEFAULT_EMPTY_SECTION_MESSAGE}
          </div>
        </>}
    />
  }
}

export default withStyles(styles)(ChartPortfolioReturn)