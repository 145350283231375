import {
  SET_GUIDE_TOUR_SETTINGS,
  DELETE_GUIDE_TOUR_SETTINGS
} from "./constants";

export const setGuideTourSettings = (steps, success) => ({
  type: SET_GUIDE_TOUR_SETTINGS,
  steps,
  success
});

export const deleteGuideTourSettings = () => ({
  type: DELETE_GUIDE_TOUR_SETTINGS
});

