import React from "react";

import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import {
  getQuestionAnswers,
  getQuestionComponent,
  getQuestionSize,
  getQuestionType,
  getUID,
  isQuestionValid,
  isRequiredQuestion
} from "../../../../../utils";
import styles from "./styles";

function isGroupQuestionRequired(question) {
  return question.question.some(isRequiredQuestion);
}

function isGroupQuestionValid(question, step, afterChange, service) {
  let isValid = true
  question.question.forEach(q => {
    if (!isQuestionValid(q, step, afterChange, service)) {
      isValid = false
    }
  })
  return isValid
}

function getGroupQuestionAnswer(question, withOption) {
  return question.question.map(q => getQuestionAnswers(q, withOption))
}

export const GroupQuestionMethods = {
  required: isGroupQuestionRequired,
  valid: isGroupQuestionValid,
  answer: getGroupQuestionAnswer
}

const GroupQuestion = props => {
  const {
    classes,
    question,
    onAnswerChange,
    dataService,
  } = props;

  const groupQuestionName = question.question_text || '';
  const groupQuestionInfoText = question.info_text || '';
  let childQuestions = question.question || [], extraChildQuestions = null;

  if (question.uid === 'A-general') {
    childQuestions = [];
    extraChildQuestions = [];
    (question.question || []).forEach(q => {
      if(['A2','A4', 'A201', 'A401', 'A202','A402',].includes(getUID(q.uid))) {
        childQuestions.push(q)
      } else{
        extraChildQuestions.push(q)
      }
    })
  }

  const renderQuestion = (question) => {
    const type = getQuestionType(question);
    const QuestionComponent = getQuestionComponent(type);

    return (
      <Grid item key={question.uid} data-id={question.uid} sm={getQuestionSize(question)}>
        {QuestionComponent && (
          <QuestionComponent
            question={question}
            onAnswerChange={onAnswerChange}
            dataService={dataService}
          />
        )}
      </Grid>
    )
  };

  return (
    <div className={classes.groupQuestionContainer}>
      {groupQuestionName && <div className={classes.groupQuestionName}>{groupQuestionName}</div>}
      <Grid container className={classes.questionsContent} spacing={2}>
        {childQuestions.map(renderQuestion)}
        {groupQuestionInfoText && (
          <Grid
            item
            sm={getQuestionSize()}
            style={{marginLeft: 'auto', fontFamily: 'Roboto-Bold', fontSize: 14}}
          >
            <span className='far fa-info-circle' />
            &nbsp;
            {groupQuestionInfoText}
          </Grid>
          )}
        {extraChildQuestions && extraChildQuestions.map(renderQuestion)}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(GroupQuestion);
