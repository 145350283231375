const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 424,
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    textAlign: 'center',
    marginBottom: 32,
    color: theme.palette.primary.main
  },
  icon: {
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  name: {}
});

export default styles;