import React from 'react';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

/* BCA modules*/
import useStyles from './styles';

import {DEFAULT_REPORT_GENERATION_TYPE, REPORT_GENERATION_TYPES} from '../../constants';

export default (props) => {
    const classes = useStyles();

    const handleChange = (event) => {
        props.handleChanged(+event.target.value);
    };

    return (
        <div className={classes.container}>
          <FormControl disabled={props.disabled} component="fieldset">
            <FormLabel 
              component="legend" 
              classes={{root: classes.formLabel, focused: classes.focusedLabel}}
            >
              Report Aufteilung
            </FormLabel>
            <RadioGroup
              aria-label="Schaffung"
              name="distributionType"
              value={`${props.selected}`}
              onChange={handleChange}
            >
              <FormControlLabel
                value={`${REPORT_GENERATION_TYPES.SINGLE.value}`}
                label={REPORT_GENERATION_TYPES.SINGLE.description}
                classes={{label: classes.formControlLabel}}
                control={
                  <Radio color="primary" classes={{root: classes.radio}}/>
                }/>
              <FormControlLabel
                value={`${REPORT_GENERATION_TYPES.SPLITTED.value}`}
                label={REPORT_GENERATION_TYPES.SPLITTED.description}
                classes={{label: classes.formControlLabel}}
                control={
                  <Radio color="primary" classes={{root: classes.radio}}/>
                }/>
            </RadioGroup>
          </FormControl>
        </div>
    )
}