import React from 'react';

import {
  Dialog,
  DialogContent
} from '@material-ui/core';

import useStyles from './styles';

const ModalContainer = (props) => {
  const classes = useStyles();

  const {
    open,
    handleClose,
    ...rest
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      {...rest}
    >
      <DialogContent>
        {rest.children}
      </DialogContent>
    </Dialog>
  )
};

export default ModalContainer;