import DepositsStep from "./components/StepContent/components/step/ProductsSelectionSteps/components/DepositsStep";
import DepotStep from "./components/StepContent/components/step/ProductsSelectionSteps/components/DepotStep";
import SummaryStep from "./components/StepContent/components/step/ProductsSelectionSteps/components/SummaryStep";
import moment from "moment";
import {SERVICE_CONCEPTS} from "../Trades/constants";
import _ from 'lodash'
import {FIELD_REQUIRED_MSG} from "./constants";

const step1 = {
  "uid": "product-selection-investment-recommendation",
  "name": "Anlageempfehlung erstellen",
  "behaviour": ["instruments", "Anlageempfehlung"],
  "custom_classes": {"stepName": "text-left"},
  "question": [
    {
      "uid": "instruments",
      "type": "input",
    },
    {
      "uid": "instruments_source", // model portfolio or investment strategie
      "type": "input",
    },
    {
      "uid": "portfolio_builder_case",
      "type": "input",
    }
  ]
};

const step2 = {
  "uid": "product-selection-deposits",
  "name": "Einzahlungen",
  "custom_classes": {"stepName": "text-left"},
  "behaviour": ["dynamic", "Anlageplan"],
  "step_content": DepositsStep,
  "question": [
    {
      "uid": "open_only['checkbox']",
      "type": "checkbox",
      "question_text": "Ohne Einzahlungen fortfahren"
    },
    {
      "uid": "single_investment['checkbox']",
      "type": "checkbox",
      "question_text": "Einmalanlage"
    },
    {
      "uid": "single_investment['input']",
      "type": "input",
    },
    {
      "uid": "savings_plan['checkbox']",
      "type": "checkbox",
      "question_text": "Sparplan"
    },
    {
      "uid": "savings_plan['input']",
      "type": "input",
    },
    {
      "uid": "savings_plan['rotation']",
      "type": "select",
      "question_text": "Turnus",
      "config": {
        "choices": []
      }
    },
    {
      "uid": "savings_plan['from_date']",
      "type": "date",
      "question_text": "Einzahlung ab",
      "min_date": moment().add(1,'days'),
    },
    {
      "uid": "savings_plan['till_date']",
      "type": "date",
      "question_text": "Letzte Ausführung",
      "min_date": moment().add(1,'days'),
    },
    {
      "uid": "vl_plan['checkbox']",
      "type": "checkbox",
      "question_text": "Vermögenswirksame Leistungen"
    },
    {
      "uid": "vl_plan['input']",
      "type": "input",
      //field to show warning
      "connect_uid": "vl_plan_details.amount_eur",
    },
    {
      "uid": "vl_plan['rotation']",
      "type": "select",
      "question_text": "Turnus",
      "config": {
        "choices": []
      }
    },
    {
      "uid": "vl_plan['from_date']",
      "type": "date",
      "question_text": "Einzahlung ab",
      "min_date": moment().add(1,'days'),
    },
    {
      "uid": "einzeltitel['checkbox']",
      "type": 'checkbox',
      "question_text": 'Wertpapiere/Einzeltitel'
    }
  ]
};

const step3 = {
  "uid": "product-selection-depot",
  "name": "Depot- und Vertragsauswahl",
  "behaviour": ["dynamic", "Depot- & Vertragsauswahl"],
  "custom_classes": {"stepName": "text-left"},
  "step_content": DepotStep,
  "question": [
    {
      "uid": "custodians",
      "type": "input",
      "options": [
        {
          "id": 1,
          "bank": "dws_de",
          "depot": "DWS FP Lux: X0666667-1-1",
          "contract_types": [
            {'id': 1, 'value': '1', 'label': 'Test'}
          ]
        },
        {
          "id": 2,
          "bank": "ffb",
          "depot": "FFB: 1004006847-0 DE",
          "deposit_fee": "50 EUR p.a",
          "contract_types": [
            {'id': 1, 'value': '1', 'label': 'Test'}
          ]
        }
      ]
    }
  ]
};

const summaryStep = {
  "uid": "product-selection-summary",
  "name": "",
  "behaviour": ["dynamic", "Zusammenfassung"],
  "custom_classes": {"stepName": "text-left"},
  "step_content": SummaryStep,
  "question": [
    {
      "uid": "discounts",
      "type": "input",
      "isValid": function () {
        return true
      },
      "config": {
        "choices": []
      }
    },
    {
      "uid": "kickback",
      "type": "select",
      "optional": true,
      "config": {
        "choices": []
      }
    },
    {
      "uid": "serviceFee",
      "type": "input",
      "validateImmediately": true,
      "question_text": "Serviceentgelt p.a. in % (zzgl. MwSt.)",
      "isValid": function (step, afterChange) {
        // TODO: Seems onAnswerChange triggered again after clicking Speichern button.
        //  As we have validateImmediately on this question - FIELD_REQUIRED_MSG cleaned right after assigning.
        //  A workaround for this case implemented to check if onAnswerChange triggered with empty answer.
        //  In case empty answer and FIELD_REQUIRED_MSG already set - do nothing and return false;
        if (this.error == FIELD_REQUIRED_MSG && afterChange && !this.answer) {
          return false
        }

        this.error = this.answer > this.maxValue ? `Gebührenobergrenze ${this.maxValue}%` : ''
        if (_.isEmpty(this.error) && this.answer < this.minValue) {
          this.error = `Gebührenuntergrenze ${this.minValue}%`
        }

        if (!afterChange && _.isEmpty(this.error) && !this.optional && !this.answer) {
          this.error = FIELD_REQUIRED_MSG;
        }
        return _.isEmpty(this.error) // if error is not set, the answer is valid
      }
    },
    {
      "uid": "serviceFeeVat",
      "type": "hidden",
      "question_text": "Serviceentgelt p.a. in % (inkl. MwSt.)",
      "optional": true
    }
  ]
};

const congratsStep = {
  "uid": "congrats-step",
  "name": "Produktauswahl",
  "behaviour": ["congrats"]
};

export const productsStepsData = {
  steps: [
    step3,
    step2,
    step1,
    summaryStep,
    congratsStep
  ]
};

// used for discounts
export const SAVINGS_PLANS_KEY = 'savingsPlans';
export const SINGLE_INVESTMENTS_KEY = 'singleInvestments';
export const INSTRUMENTS_KEY = 'instruments';

export const instrumentDiscountPath = (item) => [...instrumentDataPath(item), 'discount'];

export const instrumentDataPath = (item) => [
  INSTRUMENTS_KEY, item.savingsPlan ? SAVINGS_PLANS_KEY : SINGLE_INVESTMENTS_KEY, item.isin
];

export const getProductSelectionStepData = (serviceConcept) => {
  if (serviceConcept === SERVICE_CONCEPTS.Anlageberatung) {
    return {
      "name": "Anlageempfehlung erstellen",
      "behaviour": ["instruments", "Anlageempfehlung"]
    }
  } else {
    return {
      "name": "Produktauswahl",
      "behaviour": ["instruments", "Produktauswahl"]
    }
  }
};