import React, {useCallback} from 'react'

import useStyles from './styles'
import _ from "lodash";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Dialog, DialogContent, Grid, IconButton} from "@material-ui/core";
import { CloudUpload } from '@material-ui/icons'
import {useDropzone} from "react-dropzone";
import {PrimaryButton} from "../../../../components/Buttons";
import DialogActions from "@material-ui/core/DialogActions";
import Badge from "@material-ui/core/Badge";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import {Skeleton} from "@material-ui/lab";

function UploadDocumentModal({open, loading, onModalClose, documentName, onFileChanged, onUploadDocumentClick}) {

  const classes = useStyles()
  const [file, setFile] = React.useState(undefined)

  const onDrop = useCallback(setFile, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "application/pdf": ['.pdf'],
    }})

  React.useEffect(() => {

    if (!_.isEmpty(file) && onFileChanged) {
      onFileChanged(file[0])
    }

  }, [file])

  React.useEffect(() => {
    setFile(undefined)
  },[open])

  const handleDeleteFile = () => {
    setFile(undefined)
    onFileChanged(undefined)
  }

  const handleModalClose = () => {
    !loading && onModalClose && onModalClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      classes={{
        root: classes.dialogRoot
      }}
      maxWidth="md"
      PaperProps={{
        style: {
          width: 800
        }
      }}
    >
      <DialogTitle
        classes={{
          root: classes.dialogTitleRoot
        }}
      >
        Eine Unterschrieben Version von {documentName} hochladen
      </DialogTitle>
      <DialogContent classes={{root: classes.dialogContentRoot}}>
        <Grid container style={{width: '100%'}}>
          <Grid item xs={12}>
            {loading ? (
              <div className={classes.dragNDropContainer} style={{flexDirection: 'column'}}>
                <p>Hochladen läuft</p>
                <Skeleton style={{width: '75%', height: 35}}/>
              </div>
            ) : (
              <>
                {_.isEmpty(file) ? (
                  <div {...getRootProps()} className={clsx(classes.dragNDropContainer, isDragActive && classes.dragNDropContainerActive)}>
                    <input {...getInputProps()} />
                    <div>
                      <CloudUpload color={"primary"} fontSize={"large"}/>
                      {
                        isDragActive ?
                          <p>Ziehen Dateien hier hineinziehen</p> :
                          <p> Ziehen Sie die Dateien hier hinein oder klicken sie darauf, um diese hochzuladen</p>
                      }
                    </div>
                  </div>
                ) : (
                  <div className={classes.dragNDropContainer}>
                    <p>{file[0].name}</p>
                    <IconButton onClick={handleDeleteFile} disabled={loading}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                )}
              </>
            )}
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions style={{padding: '8px 24px'}}>
        <PrimaryButton
          text='Abbrechen'
          variant='text'
          disabled={loading}
          onButtonClick={handleModalClose}
        />
        <PrimaryButton
          text='Datei hochladen'
          onButtonClick={onUploadDocumentClick}
          disabled={_.isEmpty(file) || loading}
        />
      </DialogActions>
    </Dialog>
  )

}

export default UploadDocumentModal