import React from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Grid,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

import {
  Close
} from '@material-ui/icons'


import useStyles from './styles'
import {
  SuccessIcon
} from '../../../../images'

function SuccessMessageModal(props) {

  const {
    message,
    open,
    onClose
  } = props;

  const classes = useStyles();

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogContent>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Grid className={classes.container}>
          <SuccessIcon />
          { message }
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

SuccessMessageModal.propTypes = {
  /** Modal message */
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /** Flag, that indicate, if message is open, or not */
  open: PropTypes.bool,

  /** OnClose event handler */
  onClose: PropTypes.func
}

export default SuccessMessageModal

