/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import clsx from 'clsx';
import { Collapse, makeStyles } from '@material-ui/core';
import { Cancel, Replay } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import ErrorIcon from '@material-ui/icons/Error';
import DashboardTable from '../../../../components/DashboardTable/DashboardTable';
import Tooltip from '../../../../components/Tooltip';
import { InstrumentNameCell } from '../../../Modelportfolios/components/InstrumentsList/table-data';
import { withEuroOrDash, withPercentOrDash } from '../../../../utils/utils';
import { getRiskMetricsValue } from '../../../../components/Charts/InstrumentsAllocationTable/utlis';
import {
  ALERT_PRODUCT_TYPE,
  ALERT_PRODUCT_TYPE_TEXT,
  DEFAULT_CURRENCY_RANGE_STATE,
  DEFAULT_PERCENTAGE_RANGE_STATE,
  DEFAULT_VALUE_RANGE_STATE,
  FIELDS_TO_CHECK_OPTIONS,
  FREQUENCY_OPTIONS,
  PRICE_IN_EUR_VALUE,
  PROFIT_LOSS_VALUE,
  SRI_VALUE,
  VOLATILITY_1Y_VALUE,
  VOLATILITY_3Y_VALUE,
  VOLATILITY_5Y_VALUE,
  WEIGHT_VALUE,
} from './utils';
import useStyles from './styles';

export const RowNumberCell = {
  header: 'NR.',
  body: {
    content: (item, options, index) => `${index + 1}.`,
    className: 'bold',
  },
  key: 'RowNumberCell',
};

export const EventDateColumn = {
  header: {
    content: () => 'Datum',
  },
  body: {
    content: (item, options) => moment(item.triggered_at).format('DD.MM.YYYY')
  },
  key: 'EventDateColumn',
};

export const EventValueColumn = {
  header: {
    content: (item, options) => ALERT_PRODUCT_TYPE_TEXT[options.productType] || 'Produkt- /Depotalarm',
  },
  body: {
    content: (item, options) => item.alert_message,
  },
  key: 'EventValueColumn',
};

export const ConfigurationFieldToValidate = {
  header: 'Alarm einstellen für',
  body: {
    content: (item) => {
      const option = FIELDS_TO_CHECK_OPTIONS.find((o) => o.value == item.field_to_validate);
      return (option && option.label) || '';
    },
  },
};

export const ConfigurationFrequency = {
  header: 'Benachrichtigung',
  body: {
    content: (item) => {
      const option = FREQUENCY_OPTIONS.find((o) => o.value == item.frequency);
      return (option && option.label) || '';
    },
  },
};

export const ConfigurationValues = {
  header: 'Konfigurierung',
  body: {
    content: (item) => {
      if (_.isEmpty(item.configuration)) {
        return '';
      }

      const options = [DEFAULT_CURRENCY_RANGE_STATE, DEFAULT_PERCENTAGE_RANGE_STATE, DEFAULT_VALUE_RANGE_STATE];
      return options.reduce((result, option) => {
        Object.keys(option).forEach((fieldName) => {
          if (item.configuration.hasOwnProperty(fieldName) && _.isNumber(item.configuration[fieldName])) {
            const value = item.configuration[fieldName];
            result += `${result.length ? ' oder ' : ''} ${option[fieldName].label} ${option[fieldName].formatValue ? option[fieldName].formatValue(value) : value}`;
          }
        });
        return result;
      }, '');
    },
  },
};

const useActionColumnStyles = makeStyles(() => ({
  button: {
    padding: 4,
  },
}));

export const ConfigurationActionsColumn = {
  header: '',
  body: {
    content: (item, options) => {
      const classes = useActionColumnStyles();
      return (
        <div>
          {item.active ? (
            <Tooltip title="Deaktivieren" placement="top">
              <IconButton
                onClick={() => options.onCancelClick && options.onCancelClick(item)}
                classes={{ root: classes.button }}
              >
                <Cancel />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Wiederherstellen" placement="top">
              <IconButton
                onClick={() => options.onActivateClick && options.onActivateClick(item)}
                classes={{ root: classes.button }}
              >
                <Replay />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Löschen" placement="top">
            <IconButton
              onClick={() => options.onDeleteClick && options.onDeleteClick(item)}
              classes={{ root: classes.button }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
  },
};

const ProductNameCell = {
  header: {
    content: () => 'Produkt/ Depot',
  },
  body: {
    content: (item, options) => {
      if (item.alert_type_value == ALERT_PRODUCT_TYPE.ASSET) {
        const depotName = _.get(item, 'portfolio_data.name') || '';
        return (
          <>
            <b>{depotName}</b>
            {InstrumentNameCell.body.content(item.asset, options)}
          </>
        );
      }
      return (
        <b>{_.get(item, 'depot.name')}</b>
      );
    },
  },
};

const getConfigurationCurrentValueData = (currentValues, configuration) => {
  if ([PRICE_IN_EUR_VALUE, WEIGHT_VALUE, PROFIT_LOSS_VALUE, SRI_VALUE].includes(configuration.field_to_validate)) {
    return _.get(currentValues, 'instrumentTable');
  }

  return _.get(currentValues, 'individualPortfoliosRiskMetrics');
};

const getPortfolioCurrentValueConfiguration = (data, configuration) => {
  switch (configuration.field_to_validate) {
    case SRI_VALUE: {
      const value = _.get(data, `${configuration.group_id}.sri`);
      return value || '-';
    }
    case WEIGHT_VALUE: {
      const marketValue = _.get(data, `${configuration.group_id}.market_value`);
      const weight = _.get(data, `${configuration.group_id}.weight`);

      return (
        <>
          {withEuroOrDash(marketValue)} / {withPercentOrDash(weight, true)}
        </>
      );
    }
    case PROFIT_LOSS_VALUE: {
      const value = _.get(data, `${configuration.group_id}.profit_loss_perc`);
      return withPercentOrDash(value, true);
    }
    default: {
      return '-';
    }
  }
};

const getAssetCurrentValueConfiguration = (data, configuration) => {
  switch (configuration.field_to_validate) {
    case PRICE_IN_EUR_VALUE: {
      const price = _.get(data, `${configuration.group_id}.components.${configuration.asset_id}.price_eur`);
      const startPrice = _.get(configuration, 'configuration.instrument_price_eur');
      const price_diff = price && startPrice && (price * 100) / startPrice - 100;
      return (
        <>
          {withEuroOrDash(price)} / {withPercentOrDash(price_diff, false)}
        </>
      );
    }
    case WEIGHT_VALUE: {
      const marketValue = _.get(data, `${configuration.group_id}.components.${configuration.asset_id}.market_value`);
      const weight = _.get(data, `${configuration.group_id}.components.${configuration.asset_id}.weight`);

      return (
        <>
          {withEuroOrDash(marketValue)} / {withPercentOrDash(weight, true)}
        </>
      );
    }
    case PROFIT_LOSS_VALUE: {
      const value = _.get(data, `${configuration.group_id}.components.${configuration.asset_id}.profit_loss_perc`);
      return withPercentOrDash(value, true);
    }
    default: {
      return '-';
    }
  }
};

const getConfigurationCurrentValue = (data, configuration) => {
  if ([VOLATILITY_1Y_VALUE, VOLATILITY_3Y_VALUE, VOLATILITY_5Y_VALUE].includes(configuration.field_to_validate)) {
    const timeFrameKey = { [VOLATILITY_1Y_VALUE]: '1y', [VOLATILITY_3Y_VALUE]: '3y', [VOLATILITY_5Y_VALUE]: '5y' }[configuration.field_to_validate];

    return getRiskMetricsValue(
      configuration, {
        portfolio: { id: configuration.group_id },
        riskMetricsData: data,
      },
      timeFrameKey, 'volatility_annual',
      true,
      configuration.alert_type_value == ALERT_PRODUCT_TYPE.ASSET,
    );
  }

  if (configuration.alert_type_value == ALERT_PRODUCT_TYPE.ASSET) {
    return getAssetCurrentValueConfiguration(data, configuration);
  }
  return getPortfolioCurrentValueConfiguration(data, configuration);
};

const CurrentValueCell = {
  header: {
    content: () => 'Aktuell',
  },
  body: {
    content: (item, options) => {
      const data = getConfigurationCurrentValueData(options.currentValues || {}, item);

      if (!data) {
        return '-';
      }

      if (data.hasOwnProperty('loading') && data.loading) {
        return <Skeleton />;
      }

      if (data.hasOwnProperty('errors') && data.errors) {
        return <ErrorIcon />;
      }

      return getConfigurationCurrentValue(data.hasOwnProperty('data') ? data.data : data, item);
    },
  },
};

export const getAlertingConfigurationsTableStructure = (withProductNameColumn, withCurrentValueColumn) => {
  const structure = {
    default: [{
      content: [RowNumberCell],
    }, {
      content: [ConfigurationFieldToValidate],
    }, {
      content: [ConfigurationFrequency],
    }, {
      content: [ConfigurationValues],
    }, {
      content: [ConfigurationActionsColumn],
    }],
  };

  if (withProductNameColumn) {
    structure.default.splice(1, 0, {
      content: [ProductNameCell],
    });
  }

  if (withCurrentValueColumn) {
    structure.default.splice(structure.default.length - 2, 0, {
      content: [CurrentValueCell],
    });
  }

  return structure;
};

function EventsList({
  events,
  productType,
  title,
  primary,
  onActivateClick,
  onCancelClick,
  onDeleteClick,
  TableProps,
  withProductNameColumn,
  ...props
}) {
  const classes = useStyles({
    withProductNameColumn: TableProps.withProductNameColumn,
    withCurrentValueColumn: TableProps.withCurrentValueColumn,
  });

  const [expanded, setExpanded] = React.useState(!!props.expanded);
  const [tableStructure] = React.useState(
    getAlertingConfigurationsTableStructure(TableProps.withProductNameColumn, TableProps.withCurrentValueColumn),
  );

  const handleExpandChange = () => {
    setExpanded((currentValue) => !currentValue);
  };

  return (
    <div className={classes.riskIndicatorContainer}>
      <div
        className={clsx(classes.riskIndicatorDropdown, primary && classes.riskIndicatorDropdownPrimary)}
        onClick={handleExpandChange}
      >
        {title}
        {' '}
        {events && events.length > 0 && `(${events.length} Position${events.length > 1 ? 'en' : ''})`}
        <ExpandMoreIcon color="white" style={{ transform: `rotate(${expanded ? 180 : 0}deg)` }} />
      </div>
      <Collapse in={expanded} timeout={300}>
        <div className={classes.riskIndicatorTable}>
          <DashboardTable
            structure={tableStructure}
            dataSource={events}
            tableClasses={classes}
            withFooter={false}
            expanded
            paginationOptions={{
              paginationEnabled: true,
              pageSize: 10,
              pageSizeSelectEnabled: true,
            }}
            options={{
              productType,
              onCancelClick,
              onDeleteClick,
              onActivateClick,
              ...(TableProps.options || {}),
            }}
          />
        </div>
      </Collapse>
    </div>
  );
}

EventsList.defaultProps = {
  TableProps: {},
};

export default EventsList;