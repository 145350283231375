import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import {
  InstrumentNameCell,
  SRRIColumn,
} from '../../../../../../../../Modelportfolios/components/InstrumentsList/table-data';
import { withEuroOrDash, withPercentOrDash } from '../../../../../../../../../utils/utils';
import { filterAvailableDiscounts } from '../../../../../../../../Trades/utils';
import SelectFormElement from '../../../../formElement/SelectFormElement';
import {
  instrumentDataPath,
  SAVINGS_PLANS_KEY,
  SINGLE_INVESTMENTS_KEY,
} from '../../../../../../../mock_produktauswahl';
import WarningTooltip from '../../../../../../../../../components/WarningTooltip';
import { OPTION_IS_NOT_AVAILABLE_FOR_DEPOT_MSG } from '../../../../../../../constants';
import {
  PortfolioNameCell,
  PortfolioNameColumn as MPNameColumn,
} from '../../../../../../../../Trades/components/TradeStep/components/PortfolioTrade/table-data';
import {SustainabilityCell} from "../../../../../../../../../utils/commonTableColumns";

const useCellStyles = makeStyles(() => ({
  topCell: {
    marginLeft: '-1rem',
    position: 'absolute',
    padding: '0.5rem 1rem',
    top: -38,
    width: 114,
  },
  discountTitle: {
    color: '#0092E5',
    fontSize: 10
  },
  discountTitleDisabled: {
    color: 'rgb(177, 177, 177)'
  },
  amountWithDiscount: {
    color: '#0092E5',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  }
}));

const filterOptionsByMin = (options, minValue) => {
  // o.value is undefined indicates that None (Keine) discount is used. If discount is not set - it is the same as 0
  return options.filter(o => parseInt(o.value || '0') >= (minValue || 0));
};

const getItemDiscountInfo = (item, options, overallField) => {
  const instrumentData = _.get(options.discounts, instrumentDataPath(item), {});
  const maxDiscount = instrumentData.max_discount || 0;
  const overallValue = options.discounts[overallField];

  let availableDiscounts = filterOptionsByMin(options.availableDiscounts, Math.min(overallValue, maxDiscount));

  availableDiscounts = filterAvailableDiscounts(availableDiscounts, maxDiscount);

  const { discount } = instrumentData;

  if ((typeof discount !== 'undefined' && discount !== '') || overallValue !== '') {
    if (availableDiscounts.length > 0) {
      if (!availableDiscounts.find(a => a.value == discount)) {
        options.onItemDiscountChange(item, availableDiscounts[0].value);
      }
    } else if (discount !== '') {
      options.onItemDiscountChange(item, '');
    }
  }

  return { availableDiscounts, discount };
};

const SingleAmountColumn = {
  header: [() => "Einmalanlage"],
  body: [({item, options}) => {
    if (options.loading) {
      return <Skeleton />
    } else if (!item.singleInvestment) {
      return ' - ';
    }
    item = item.singleInvestment;
    return withEuroOrDash(item.amount)
  }],
  align: 'right'
}

const SingleAmountDiscountColumn = {
  header: [() => (
    <>
      <span>Ausgabeaufschlag</span><br />
      <span>(Einmalanlage)</span>
    </>
  )],
  body: [({item, options}) => {
    if (options.loading) {
      return <Skeleton />
    } else if (!item.singleInvestment) {
      return ' - ';
    }
    item = item.singleInvestment;

    const classes = useCellStyles();

    const instrumentData = _.get(options.discounts, instrumentDataPath(item), {});
    const discount = (options.discounts.discountsEnabled && instrumentData.discount || 0) / 100;
    const brokerFee = instrumentData.broker_fee / 100;
    const brokerFeeInEur = item.amount * brokerFee;

    return (
      <span className={classes.nowrap}>
        <span>{withPercentOrDash(instrumentData.broker_fee, false)}</span><br />
        <span className={discount > 0 ? classes.amountWithDiscount : ''}>
          {withEuroOrDash(brokerFeeInEur - brokerFeeInEur * discount)}
        </span>
      </span>
    )
  }],
  align: 'right'
}

const OneTimeDiscountColumn = {
  header: [({options}) => {
    if (options.loading || !options.discounts.discountsEnabled || options.isInvestmentStrategy || options.isModelportfolio) {
      return null;
    }

    const classes = useCellStyles();

    const disabled = !options.allowedDiscountTypes.includes('single_buy')
      || (options.einzeltitelsOnly && !options.allowedEinzeltitelDiscountTypes.includes('single_buy'));

    return (
      <>
        <div style={{position: 'relative'}}>
          <div className={classes.topCell}>
            <span className={clsx(classes.discountTitle, classes.nowrap, disabled && classes.discountTitleDisabled)}>
              Einmalrabatt&nbsp;
              <WarningTooltip color={disabled && 'color={"rgb(177, 177, 177)"}'} title={disabled ? OPTION_IS_NOT_AVAILABLE_FOR_DEPOT_MSG : "Hier können Sie den Rabatt für alle Produkte auswählen"}/>
            </span>
            <SelectFormElement
              value={options.discounts[SINGLE_INVESTMENTS_KEY]}
              options={filterOptionsByMin(options.availableOverAllDiscounts, options.discounts.overall)}
              onChange={(val) => options.onDiscountChange(SINGLE_INVESTMENTS_KEY, val)}
              disabled={disabled}
            />
          </div>
        </div>
      </>
    );
  }],
  body: [({item, options}) => {
    if (options.loading || !options.discounts.discountsEnabled || !item.singleInvestment || options.isInvestmentStrategy || options.isModelportfolio) {
      return null;
    }
    item = item.singleInvestment;

    const discountInfo = getItemDiscountInfo(item, options, SINGLE_INVESTMENTS_KEY);

    const disabled = !options.allowedDiscountTypes.includes('single_buy')
      || (!item.is_fund && !options.allowedEinzeltitelDiscountTypes.includes('single_buy'))

    return (
      <SelectFormElement
        value={discountInfo.discount || ''}
        options={discountInfo.availableDiscounts}
        onChange={val => options.onItemDiscountChange(item, val)}
        disabled={disabled}
      />
    )
  }],
  align: 'center'
}

const SparplanColumn = {
  header: [() => "Sparplan"],
  body: [({item, options}) => {
    if (options.loading) {
      return <Skeleton />
    } else if (!item.savingsPlanItem) {
      return ' - ';
    }
    item = item.savingsPlanItem;
    return withEuroOrDash(item.amount)
  }],
  align: 'right'
}

const SparplanDiscountColumn = {
  header: [() => (
    <>
      <span>Ausgabeaufschlag</span><br />
      <span>(Sparplan)</span>
    </>
  )],
  body: [({item, options}) => {
    if (options.loading) {
      return <Skeleton />
    } else if (!item.savingsPlanItem) {
      return ' - ';
    }
    const classes = useCellStyles();
    item = item.savingsPlanItem;

    const instrumentData = _.get(options.discounts, instrumentDataPath(item), {});
    const discount = (options.discounts.discountsEnabled && instrumentData.discount || 0) / 100;
    const brokerFee = instrumentData.broker_fee / 100;
    const brokerFeeInEur = item.amount * brokerFee;

    return (
      <span className={classes.nowrap}>
        <span>{withPercentOrDash(instrumentData.broker_fee, false)}</span><br />
        <span className={discount > 0 ? classes.amountWithDiscount : ''}>
          {withEuroOrDash(brokerFeeInEur - brokerFeeInEur * discount)}
        </span>
      </span>
    )
  }],
  align: 'right'
}

const SparplanOneTimeDiscountColumn = {
  header: [({options}) => {
    if (options.loading || !options.discounts.discountsEnabled || options.isInvestmentStrategy || options.isModelportfolio) {
      return null;
    }

    const classes = useCellStyles();

    const disabled = !options.allowedDiscountTypes.includes('payment_plan')
      || (options.einzeltitelsOnly && !options.allowedEinzeltitelDiscountTypes.includes('payment_plan'));

    return (
      <>
        <div style={{position: 'relative'}}>
          <div className={classes.topCell}>
            <span className={clsx(classes.discountTitle, classes.nowrap, disabled && classes.discountTitleDisabled)}>
              Sparplanrabatt&nbsp;
              <WarningTooltip color={disabled && 'color={"rgb(177, 177, 177)"}'} title={disabled ? OPTION_IS_NOT_AVAILABLE_FOR_DEPOT_MSG : "Hier können Sie den Rabatt für alle Produkte auswählen"}/>
            </span>
            <SelectFormElement
              value={options.discounts[SAVINGS_PLANS_KEY]}
              options={filterOptionsByMin(options.availableOverAllDiscounts, options.discounts.overall)}
              onChange={(val) => options.onDiscountChange(SAVINGS_PLANS_KEY, val)}
              disabled={disabled}
            />
          </div>
        </div>
      </>
    );
  }],
  body: [({item, options}) => {
    if (options.loading || !options.discounts.discountsEnabled || !item.savingsPlanItem || options.isInvestmentStrategy || options.isModelportfolio) {
      return null;
    }
    item = item.savingsPlanItem;
    const discountInfo = getItemDiscountInfo(item, options, SAVINGS_PLANS_KEY);

    const disabled = !options.allowedDiscountTypes.includes('payment_plan')

    return (
      <SelectFormElement
        value={discountInfo.discount || ''}
        options={discountInfo.availableDiscounts}
        onChange={val => options.onItemDiscountChange(item, val)}
        disabled={disabled}
      />
    )
  }],
  align: 'center'
}

const NameCol = {
  header: [({options}) => InstrumentNameCell.header.content(options) || null],
  body: [({item, options}) => InstrumentNameCell.body.content(item.singleInvestment || item.savingsPlanItem, options) || null]
}

const SRRICol = {
  header: [() => SRRIColumn.header || null],
  body: [({item, options}) => SRRIColumn.body.content(item.singleInvestment || item.savingsPlanItem, options) || null],
  align: 'right'
}

const SustainabilityCol = {
  header: [SustainabilityCell.header.content],
  body: [({item, options}) => SustainabilityCell.body.content(item.singleInvestment || item.savingsPlanItem, options)]
}

export const tableStructure = [
  NameCol,
  SustainabilityCol,
  SRRICol,
  SingleAmountColumn,
  SingleAmountDiscountColumn,
  OneTimeDiscountColumn,
  SparplanColumn,
  SparplanDiscountColumn,
  SparplanOneTimeDiscountColumn
];

const PortfolioNameColumn = {
  header: MPNameColumn.header,
  body: [({item}) => (
    <PortfolioNameCell
      portfolio={{...item, mp_number: item.isInvestmentStrategy ? item.portfolio_id : item.agency_portfolio_id}}
    />
  )]
};

const PortfolioSRRICol = {
  header: [() => SRRIColumn.header || null],
  body: [({item, options}) => SRRIColumn.body.content(item, options) || null],
  align: 'right'
};

const PortfolioSingleAmountColumn = {
  header: [() => "Einmalanlage"],
  body: [({item, options}) => {
    if (options.loading) {
      return <Skeleton />
    }
    return withEuroOrDash(options.singleInvestmentAmount)
  }],
  align: 'right'
}

const PortfolioSingleAmountDiscountColumn = {
  header: [() => (<><span>Einstiegsentgelt</span><br/><span>(Einmalanlage)</span></>)],
  body: [({item, options}) => {
    if (options.loading) {
      return <Skeleton />
    }

    const classes = useCellStyles();

    const discount = (options.discounts.discountsEnabled && options.discounts[SINGLE_INVESTMENTS_KEY] || 0) / 100;
    const brokerFee = (item.fee_brutto || item.cost_brutto || 0) / 100;
    const brokerFeeInEur = options.singleInvestmentAmount * brokerFee;

    return (
      <span className={classes.nowrap}>
        <span>
          {options.entryFeeInfoText &&
            <WarningTooltip title={options.entryFeeInfoText}/>
          }
          {withPercentOrDash(brokerFee)}
        </span><br />
        <span className={discount > 0 ? classes.amountWithDiscount : ''}>
          {withEuroOrDash(brokerFeeInEur - brokerFeeInEur * discount)}
        </span>
      </span>
    )
  }],
  align: 'right'
}

const PortfolioOneTimeDiscountColumn = {
  header: [({options}) => {
    if (options.loading || !options.discounts.discountsEnabled) {
      return null;
    }

    const classes = useCellStyles();

    return (
        <span className={classes.discountTitle}>Einmalrabatt</span>
    );
  }],
  body: [({item, options}) => {
    if (options.loading || !options.discounts.discountsEnabled) {
      return null;
    }

    return (
      <SelectFormElement
        value={options.discounts[SINGLE_INVESTMENTS_KEY]}
        options={options.availableOverAllDiscounts}
        onChange={(val) => options.onDiscountChange(SINGLE_INVESTMENTS_KEY, val)}
      />
    )
  }],
  align: 'center'
}

const PortfolioSparplanColumn = {
  header: [() => "Sparplan"],
  body: [({item, options}) => {
    if (options.loading) {
      return <Skeleton />
    }
    return withEuroOrDash(options.savingsPlanAmount)
  }],
  align: 'right'
}

const PortfolioSparplanDiscountColumn = {
  header: [() => (
    <>
      <span>Einstiegsentgelt</span><br />
      <span>(Sparplan)</span>
    </>
  )],
  body: [({item, options}) => {
    if (options.loading) {
      return <Skeleton />
    }
    const classes = useCellStyles();

    const discount = (options.discounts.discountsEnabled && options.discounts[SAVINGS_PLANS_KEY] || 0) / 100;
    const brokerFee = (item.fee_brutto || item.cost_brutto || 0) / 100;
    const brokerFeeInEur = options.savingsPlanAmount * brokerFee;

    return (
      <span className={classes.nowrap}>
        <span>
          {options.entryFeeInfoText &&
            <WarningTooltip title={options.entryFeeInfoText}/>
          }
          {withPercentOrDash(brokerFee)}
        </span><br />
        <span className={discount > 0 ? classes.amountWithDiscount : ''}>
          {withEuroOrDash(brokerFeeInEur - brokerFeeInEur * discount)}
        </span>
      </span>
    )
  }],
  align: 'right'
}

const PortfolioSparplanOneTimeDiscountColumn = {
  header: [({options}) => {
    if (options.loading || !options.discounts.discountsEnabled) {
      return null;
    }

    const classes = useCellStyles();

    return (
        <span className={classes.discountTitle}>Sparplanrabatt</span>
    );
  }],
  body: [({item, options}) => {
    if (options.loading || !options.discounts.discountsEnabled) {
      return null;
    }

    return (
      <SelectFormElement
        value={options.discounts[SAVINGS_PLANS_KEY]}
        options={options.availableOverAllDiscounts}
        onChange={(val) => options.onDiscountChange(SAVINGS_PLANS_KEY, val)}
      />
    )
  }],
  align: 'center'
}


/**
 * Dummy tabel column to have aligned styles
 * @type {{header: (function(): null)[], body: (function(): null)[]}}
 */
const PortfolioSustainabilityCol = {
  header: [() => null],
  body: [() => null]
}

export const tableInvestmentStrategyStructure = [
  PortfolioNameColumn,
  PortfolioSustainabilityCol,
  PortfolioSRRICol,
  PortfolioSingleAmountColumn,
  PortfolioSingleAmountDiscountColumn,
  PortfolioOneTimeDiscountColumn,
  PortfolioSparplanColumn,
  PortfolioSparplanDiscountColumn,
  PortfolioSparplanOneTimeDiscountColumn
]