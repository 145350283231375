import React from "react";
import {withStyles} from "@material-ui/core";

import {parseResponse} from "../../../../../../utils/api";
import styles from "../../../../../VirtualPortfolioPage/Tabs/KeyFigures/items/ChartRollingVolatility/styles";
import {ChartRollingVolatility} from "../../../../../VirtualPortfolioPage/Tabs/KeyFigures/items/ChartRollingVolatility";
import _ from 'lodash';


class ProViewRollingVolatilityChart extends ChartRollingVolatility{

  componentDidUpdate(prevProps, prevState, snapshot) {
    let dateHasChanged = this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate
    let benchmarksHasChanged = JSON.stringify(this.props.benchmarks) !== JSON.stringify(prevProps.benchmarks)
    let assetsHasChanged = JSON.stringify(this.props.selectedAssets) != JSON.stringify(prevProps.selectedAssets)

    if(dateHasChanged || benchmarksHasChanged || assetsHasChanged) {
      this.fetchRollingVolatilityData();
    }
    if(!_.isNil(this.props.showBenchmark)  && this.state.showBenchmark !== this.props.showBenchmark) {
      this.setState({
        showBenchmark: this.props.showBenchmark
      })
    }
    if(_.get(this.props,'lastBenchmarkUpdateTime', undefined) !== _.get(prevProps, 'lastBenchmarkUpdateTime', undefined)){
      this.fetchRollingVolatilityData();
    }
  }
  fetchRollingVolatilityData() {
    this.setState({
      RollingVolaData: null,
      RollingVolaError: null,
      RollingVolaBenchmarkData: null,
      RollingVolaBenchmarkError: null,
      benchmarkDetails: null,
      DataLoading: true,
      showBenchmark: this.props.showBenchmark
    })

    this.props.dataProvider.getRollingVolatilityData(
      this.props.customerId, this.props.portfolioId, this.props.startDate, this.props.endDate,
      this.props.benchmarks, !this.props.isAllAssetsSelected ? this.props.selectedAssets : [],
      this.props.investmentStrategyId)
      .then((response) => {
        parseResponse(response, 'rolling_vola_portfolio',
          data => {
            this.setState({
              RollingVolaData: data['rolling_risk_metrics'],
              RollingVolaError: undefined
            })
          },
          error => {
            this.setState({
              RollingVolaData: undefined,
              RollingVolaError: error
            })
          }
        )

        parseResponse(response, 'rolling_vola_benchmark',
          data => {
            this.setState({
              RollingVolaBenchmarkData: data['rolling_risk_metrics'],
              RollingVolaBenchmarkError: undefined
            })
          },
          error => {
            this.setState({
              RollingVolaBenchmarkData: undefined,
              RollingVolaBenchmarkError: error
            })
          }
        )

        this.setState({
          benchmarkDetails: response['benchmark_details'],
        })

        this.setState({
          DataLoading: false
        });
      })
  }
}

export default withStyles(styles)(ProViewRollingVolatilityChart)