import React from 'react';
import Grid from '@material-ui/core/Grid';

// import useStyles from './styles';
import ChartSectionBordered from "../ChartSectionBordered/ChartSectionBordered";
import {useStressTestData} from "../../hooks/useStressTestData";
import StressTestChart from "../charts/StressTestChart/StressTestChart";
import Legend from "../Legend/Legend";
import _ from "lodash";
import { isProSectionVisible } from '../../../../utils/utils';

function StressTestChartComponent(props) {
  const {
    customerId, portfolios, dataProvider, benchmarks, expanded, onExpanded, selectedPortfoliosName, assets,
    investmentStrategyId, customSettings, reportType,
  } = props;

  const stressTestData = useStressTestData(customerId, portfolios, dataProvider, benchmarks, selectedPortfoliosName, assets, investmentStrategyId);

  const hasData = !_.isEmpty(stressTestData.data);

  return (
    <Grid container spacing={2}>
      {isProSectionVisible('drawdown', reportType, customSettings) && (
        <Grid item xs={12}>
          <ChartSectionBordered
            dataId={`risk-analyse-stress-test`}
            title={(
              <b>Maximaler Wertverlust</b>
            )}
            loading={stressTestData.loading}
            error={stressTestData.errors}
            empty={!hasData}
            displayError
            expanded={expanded.stressTestMaximumLossChart}
            isPortfolioSection
            onExpanded={(newState) => onExpanded('stressTestMaximumLossChart', newState)}
            content={(
              <>
                <StressTestChart
                  data={stressTestData.data}
                  updatedAt={stressTestData.updatedAt}
                />
                <Legend data={stressTestData.data} flexDirection={"row"}/>
              </>
            )}
          />
        </Grid>
      )}
      {isProSectionVisible('maximumDrawdown', reportType, customSettings) && (
        <Grid item xs={12}>
          <ChartSectionBordered
            dataId={`risk-analyse-stress-test`}
            title={(
              <b>Erholungszeitraum</b>
            )}
            loading={stressTestData.loading}
            error={stressTestData.errors}
            empty={!hasData}
            displayError
            expanded={expanded.stressTestRecoveryPeriodChart}
            isPortfolioSection
            onExpanded={(newState) => onExpanded('stressTestRecoveryPeriodChart', newState)}
            content={(
              <>
                <StressTestChart
                  data={stressTestData.data}
                  updatedAt={stressTestData.updatedAt}
                  type={"maximum_drawdown"}
                />
                <Legend data={stressTestData.data} flexDirection={"row"}/>
              </>
            )}
          />
        </Grid>
      )}
    </Grid>
  )
}

StressTestChartComponent.propTypes = {}

StressTestChartComponent.propDefs = {}

StressTestChartComponent.defaultProps = {}

export default StressTestChartComponent;