import React from 'react'
import useStyles from './styles'
import {useCustomerSummaryContext} from "../../CustomerSummaryCard";
import {Skeleton} from "@material-ui/lab";
import PortfolioList from "../../../../../../components/ProfileDetails/components/PortfolioList";
import _ from "lodash";
import {withEuroOrDash} from "../../../../../../utils/utils";
import Link from "../../../../../../components/Link";
import Tooltip from "@material-ui/core/Tooltip";

function AggregatedValue(props) {

  const classes = useStyles()
  const {customer, loading, error, customerRisk} = useCustomerSummaryContext();
  let linkDisabled = _.isNil(customerRisk)

  return (
    <div>
      {loading ? (
        <div className={classes.loadingContainer}>
          <Skeleton style={{width: '75%'}}/>
          <Skeleton style={{width: '100%', height: 60}}/>
        </div>
      ) : (
        <>
          {customer && (
            <div className={classes.profileRiskContainer}>
              <p className={classes.profileRiskTitle}>Aggregiertes Depot</p>
              <p className={classes.value}>{withEuroOrDash(customer.aggregated_value || 0)}</p>
              <Link
                title="Kundenportfolio-Ansicht anzeigen"
                onClick={props.onClick}
                disabled={linkDisabled}
              />
              {linkDisabled &&
                <span style={{marginLeft: 5, color: '#B1B1B1'}}>
                  <Tooltip classes={{tooltip: classes.tooltip}} arrow placement={"top"} title={'Der Kunde hat noch keine Risikoklasse, daher ist die Detailansicht leider nicht verfügbar.'}>
                    <i className='far fa-info-circle' />
                  </Tooltip>
                </span>
              }
            </div>
          )}
        </>
      )}
    </div>
  )

}

export default AggregatedValue;