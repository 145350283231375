import React from 'react';
import connect from "react-redux/lib/connect/connect";
import GroupsTabContent from "./components/GroupsTabContent";

import {
  Container,
  withWidth
} from '@material-ui/core';
import {
  resetReportingStore, setEditorVariables,
  setReportingGroups, setReportingGroupsLoading
} from "./actions";
import {
  GroupResource as CustomerGroupsResource,
  SharedSettingsResource
} from "../../utils/api";
import {ERROR_MODAL_MESSAGE} from "../../utils/constants";
import {Route, Switch} from "react-router";
import {ROUTES} from "../../routes";
import {executeIfPathExist, getInvestmentDynamicPath} from "../InvestmentPlatform/utils";
import ReportSettingsTabContent from "./components/ReportSettingsTabContent";
import AutoReportSettingsTabContent from "./components/AutoReportSettingsTabContent";
import ErrorModal from "../../components/ErrorModal";

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS()
});

export default withWidth()(connect(mapStateToProps)((props) => {
  const {
    dispatch,
    investmentPlatform,
    parentComponent='SERIES_REPORTING',
  } = props;

  const [errorMessage, setErrorMessage] = React.useState(undefined);

  const [investmentDynamicPath, setInvestmentDynamicPath] = React.useState(getInvestmentDynamicPath())
  const [dynamicRoutes, setDynamicRoutes] = React.useState(null);
  React.useEffect(() => {
    if (props.investmentPlatform.routes) {
      let newPath = '/' + investmentDynamicPath

      executeIfPathExist(props.investmentPlatform.routes, parentComponent, (path) => {
        let url = newPath + path;
        setDynamicRoutes({
          groups: url,
          groupReport: url + ROUTES.BROKER.SERIES_REPORTING.children.GROUP.children.REPORT.newDesignPath,
          groupAutoReport: url + ROUTES.BROKER.SERIES_REPORTING.children.GROUP.children.AUTO_REPORT.newDesignPath,
        })

      })
    }
  }, [investmentPlatform.routes])

  const getGroupsTabContent = () => {
    return (
      <GroupsTabContent parentComponent={parentComponent}/>
    )
  }

  const getReportSettingsTabContent = () => {
    return (
      <ReportSettingsTabContent newDesign={props.newDesign} />
    )
  }

  const getAutoReportSettingsTabContent = () => {
    return (
      <AutoReportSettingsTabContent newDesign={props.newDesign} />
    )
  }

  React.useEffect(() => {
    onInitComponent();
    return () => {
      onDestroyComponent();
    }
  }, []);

  const onInitComponent = () => {
    fetchGroups();
    fetchVariables();
  };

  const onDestroyComponent = () => {
    dispatch(resetReportingStore());
  };

  const fetchGroups = async () => {
    try {
      dispatch(setReportingGroupsLoading(true));
      const response = await CustomerGroupsResource.at('').get();
      const groups = response && response['groups'] || [];
      dispatch(setReportingGroupsLoading(false));
      dispatch(setReportingGroups(groups));
    } catch (err) {
      dispatch(setReportingGroupsLoading(false));
      setErrorMessage(ERROR_MODAL_MESSAGE);
    }
  };

  const fetchVariables = async () => {
    try{
      const response = await SharedSettingsResource.at('editor-variables/').get();
      dispatch(setEditorVariables(response));
      window.editorVariables = response;
    } catch (e) {
      dispatch(setEditorVariables(undefined));
      window.editorVariables = undefined;
    }
  }

  const handleErrorModalClose = () => {
      setErrorMessage(undefined);
  };

  return (
    <React.Fragment>
      <Container className={'app-page-container'}>
        <Switch>
          {dynamicRoutes && (
            <>
              <Route exact path={dynamicRoutes.groups} render={getGroupsTabContent}/>
              <Route exact path={dynamicRoutes.groupReport} render={getReportSettingsTabContent}/>
              <Route exact path={dynamicRoutes.groupAutoReport} render={getAutoReportSettingsTabContent}/>
            </>
          )}
        </Switch>
      </Container>

      <ErrorModal message={errorMessage} handleClose={handleErrorModalClose}/>
    </React.Fragment>
  )
}));