import {makeStyles} from "@material-ui/core";

export const useCustomerAlertsListItemStyles = makeStyles((theme) => ({
  alertCount: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    margin: 0
  },
  cardItem: {
    [theme.breakpoints.down('sm')]: {
      '&:first-child::after': {
        borderBottom: "1px solid #E1E3E9",
      },
      '&::after': {
        borderLeft: 'none'
      },
      '&:last-child::after': {
        borderLeft: "1px solid #E1E3E9"
      }
    },
    [theme.breakpoints.down('xs')]: {
      '&:last-child::after': {
        borderLeft: 'none'
      }
    }
  },
  cardItemExpansionPanel: {
    borderTop: "1px solid #E1E3E9",
    margin: 0,
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 15
    }
  }
}));

export const useDepotAlertsStyles = makeStyles((theme) => ({
  header: {
    padding: 0,
    overflow: 'hidden',
    zIndex: 1,

    '& span': {
      fontFamily: "'Roboto-Regular'",
      fontSize: 18,
      color: '#4D4F5C',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingRight: 4,

      '&:hover': {
        overflow: 'initial',
        wordBreak: 'break-all',
        whiteSpace: 'initial',
        textOverflow: 'initial'
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      }
    }
  },
  CardHeaderContent: {
    width: '100%'
  },
  tableCell: {
    '&:nth-of-type(1)': {
      width: 40,
    },
    '&:nth-of-type(3)': {
      width: 60,
    }
  },
  emptyAfter: {
    '&::after': {
      display: 'none'
    }
  }
}));