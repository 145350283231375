import React from 'react';

/* Material-UI Components */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import CircularProgress from '@material-ui/core/CircularProgress';

/* BCA modules */
import useStyles from './styles';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';


export default (props) => {
  const classes = useStyles();
  const {
    handlePdfExportClick,
    pdfExportDisabled,
    loading
  } = props;

  return (
    <Grid
      container
      alignItems={"center"}
      justify={"space-between"}
      className={classes.container}
      spacing={2}
    >
      <Grid item>
        <Typography variant={"h1"} className={classes.header} id="portfolio-analyze-header-tour-element">
          Vermögensübersicht
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={2} style={{margin: '-7px'}}>
          <Grid item>
            <PrimaryButton
              id='export-buttons'
              text='Vermögensübersicht drucken'
              onButtonClick={handlePdfExportClick}
              icon={loading ? <CircularProgress style={{height: 20, width: 20, color: 'white'}} /> : <ArrowDownwardIcon />}
              disabled={pdfExportDisabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}