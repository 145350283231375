import {makeStyles} from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: 88,
    height: 40,
    cursor: 'pointer',
  },
  label: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto-Bold',
    fontSize: 15,
    color: 'white',
    padding: '0 15px',
    userSelect: 'none'
  },
  onLabel: {
    justifyContent: 'flex-start'
  },
  offLabel: {
    justifyContent: 'flex-end'
  },
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: 0,
    borderRadius: 20,
    margin: 0,
    pointerEvents: 'none',
  },
  switchBase: {
    padding: '1px !important',
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
    },
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  thumb: {
    position: 'relative',
    top: 4,
    left: 4,
    width: 30,
    height: 30,
  },
  track: {
    width: '100%',
    borderRadius: 26 / 2,
    backgroundColor: props => props.trackBackgroundColor || '#b1b1b1',
    opacity: 1,
    transition: 'none',
  },
  checked: {
    transform: 'translateX(150%) !important'
  },
  focusVisible: {},
}))