import { withStyles } from "@material-ui/core";
import React from "react";
import styles from "./styles";
import ErrorIcon from "@material-ui/icons/Error";
import MessageDialog from "../MessageDialog";


class ErrorDialog extends React.Component {

  render() {

    const { classes } = this.props;
    return (
      <MessageDialog
        icon={<ErrorIcon className={classes.errorIcon}/>}
        message={this.props.message}
        open={this.props.open}
        onClose={this.props.onClose}
      />
    )
  }
}

export default withStyles(styles)(ErrorDialog);
