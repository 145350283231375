import React from 'react'

import Resource from "../utils/api";
import axios from "axios";
import {
  dataFetchingFailedAction,
  dataFetchingFinishedAction,
  FETCH_DATA_INITIAL_STATE,
  fetchDataReducer,
  startDataFetchingAction
} from "./constants";
import {fromJS} from "immutable";


/**
 * Custom hook, that encapsulate logic of data fetching.
 *
 * @param {String} path - Path to request
 * @param {'get' | 'post' | 'delete' | 'patch' } method Request method
 * @param {function?} parseResponseCallback Callback, that should be used to parse response
 * in case it should be preprocessed before saving
 */
function useFetchData(path, method, parseResponseCallback, loadingDefaultValue=false) {

  const [state, dispatch] = React.useReducer(
    fetchDataReducer, fromJS({...FETCH_DATA_INITIAL_STATE, loading: loadingDefaultValue}))

  const cancelToken = React.useRef(undefined)

  /**
   * Make request using provided parameters.
   *
   * **Note:** In case request will be simulated, loading indicator will be handled as usual.
   *
   * @param {boolean} simulateRequest Flag, that indicate, if real data fetching should be triggered
   *
   * @return {Promise<void>}
   */
  const makeRequest = async (params, simulateRequest=false) => {

    //Check if there are any previous pending requests
    if (typeof cancelToken.current != 'undefined') {
      cancelToken.current.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken.current = axios.CancelToken.source();

    dispatch(startDataFetchingAction())

    if (simulateRequest) {
      dispatch(dataFetchingFinishedAction({}))
      return
    }

    const resource = new Resource('')

    try {
      const response = await resource.at(path)[method](params, cancelToken.current.token)

      let responseProcessed = parseResponseCallback && parseResponseCallback(response) || null

      dispatch(dataFetchingFinishedAction(responseProcessed ||response))

      return responseProcessed || response
    } catch (errors) {
      if (errors !== 'Operation canceled due to new request.') {
        dispatch(dataFetchingFailedAction(errors))
      }
    }

  }

  const setData = (data) => {
    dispatch(dataFetchingFinishedAction(data))
  }

  return [state.toJS(), makeRequest, setData]

}




export default useFetchData