import React from "react";

import clsx from "clsx";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";

const LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ#'.split('');

const LettersFilterPanel = (props) => {

  const {
    disabled,
    handleLetterClick
  } = props;

  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      {LETTERS.map(letter => (
        <button
          key={letter}
          onClick={() => handleLetterClick(letter)} disabled={disabled} className={clsx(classes.item, disabled && classes.itemDisabled)}>
          {letter}
        </button>
      ))}
    </Grid>
  )
}

LettersFilterPanel.defaultProps = {
  disabled: false
}

export default LettersFilterPanel;