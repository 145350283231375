import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles'

function WidgetActions(props) {

  const classes = useStyles();

  const PADDING_MULTIPLIER = 5;

  return (
    <div className={classes.container} style={
      {
        justifyContent: props.justifyContent ? props.justifyContent : 'normal',
        padding: props.padding * PADDING_MULTIPLIER
      }
    }>
      {props.children}
    </div>
  )
}

WidgetActions.propTypes = {
  justifyContent: PropTypes.oneOf(['start', 'end', 'flex-start', 'flex-end', 'center', 'left', 'right', 'baseline', 'space-between', 'space-around']),

  /** Container paddings (5 * padding)*/
  padding: PropTypes.number
}

WidgetActions.defaultProps = {
  padding: 0
}

export default WidgetActions

