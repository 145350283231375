import React from 'react'

export default ()=>{
    return (
        <svg
      id="Group_7977"
      data-name="Group 7977"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_7676"
            data-name="Rectangle 7676"
            width="30"
            height="30"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Group_7976" data-name="Group 7976" clip-path="url(#clip-path)">
        <path
          id="Path_6746"
          data-name="Path 6746"
          d="M29,28H26V9a1,1,0,0,0-1-1H21a1,1,0,0,0-1,1V28H18V17a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1V28H10V21a1,1,0,0,0-1-1H5a1,1,0,0,0-1,1v7H2V1A1,1,0,0,0,0,1V29a1,1,0,0,0,1,1H29a1,1,0,0,0,0-2M6,22H8v6H6Zm8-4h2V28H14Zm8-8h2V28H22Z"
          fill="#fff"
        />
        <path
          id="Path_6747"
          data-name="Path 6747"
          d="M7.707,17.707,22,3.414V5a1,1,0,0,0,2,0V1a1,1,0,0,0-1-1H19a1,1,0,0,0,0,2h1.586L6.293,16.293a1,1,0,0,0,1.414,1.414"
          fill="#fff"
        />
      </g>
    </svg>
    )
}