import {
  ADD_CUSTOMERS_TO_STORE,
  REMOVE_CUSTOMERS_FROM_STORE,
  ADD_CUSTOMERS_ERROR_TO_STORE,
  ADD_CUSTOMERS_WITHOUT_GROUP_TO_STORE,
  UPDATE_CUSTOMER_IN_STORE, ADD_SHOW_REPORTING_CUSTOMERS_TO_STORE, ADD_SHOW_REPORTING_CUSTOMERS_ERROR_TO_STORE
} from './constants';

/**
 * Add list of customers to global store.
 *
 * @param {Object[]} customers
 */
export const addCustomersToStore = (customers) => ({
  type: ADD_CUSTOMERS_TO_STORE,
  customers
});

export const addShowReportingCustomersToStore = (customers) => ({
  type: ADD_SHOW_REPORTING_CUSTOMERS_TO_STORE,
  customers
});

/**
 * Remove customers from store.
 */
export const removeCustomersFromStore = () => ({
  type: REMOVE_CUSTOMERS_FROM_STORE
});

export const addCustomersDataLoadingErrorToStore = () => ({
  type: ADD_CUSTOMERS_ERROR_TO_STORE
});

export const addShowReportingCustomersDataLoadingErrorToStore = () => ({
  type: ADD_SHOW_REPORTING_CUSTOMERS_ERROR_TO_STORE
});

export const addCustomersWithoutGroupToStore = (customers) => ({
  type: ADD_CUSTOMERS_WITHOUT_GROUP_TO_STORE,
  customers
})

export const updateCustomerInStore = (customerID, updatedFields) => ({
  type: UPDATE_CUSTOMER_IN_STORE,
  customerID,
  updatedFields
})