import { makeStyles } from '@material-ui/core';
import {chartLegendSize, smChartLegendSize} from "../../../utils/constants";

export default makeStyles(theme => ({
  chartLegendItemText: {
    fontSize: chartLegendSize,
    fontFamily: 'Roboto-Regular',
    whiteSpace: 'nowrap',
    color: '#4D4F5C',

    [theme.breakpoints.down('sm')]: {
      fontSize: smChartLegendSize,
      whiteSpace: 'normal',
    }
  },
  chartLegendItem: {
    marginRight: 25,
    maxWidth: '100%',
    zIndex: 1,
    display: 'inline-flex',
    alignItems: 'baseline',
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      // width: '100%'
    }
  },
  chartLegendItemSymbol: {
    fontSize: 20,
    marginRight: 5
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}))