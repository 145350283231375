import React from 'react';

import _ from 'lodash';

import Grid from "@material-ui/core/Grid";
import SingleFileUploadInput from "./components/SingleFileUploadInput";
import {CircularProgress} from "@material-ui/core";
import withNotification from "../../../../components/NotificationProvider";
import {getErrorMessage} from "../../../../utils/utils";


const UploadAdditionalFiles = props => {
  const {
    handleSettingUpdate,

    getPreviouslyUploadedDocuments,
    onExtraFileDelete,
    onExtraFileUpload,

    title
  } = props;

  const EMPTY_FILE_FIELD_ID = 0

  const EMPTY_FILE_FIELD = {
    id: EMPTY_FILE_FIELD_ID,
    formData: null
  }

  const [additionalFiles, setAdditionalFiles] = React.useState([EMPTY_FILE_FIELD]) // [{}]
  const [extraDocumentsLoading, setExtraDocumentsLoading] = React.useState(true)

  React.useEffect(() => {
    // Use effect to get previously uploaded files on mount
    if (_.isFunction(getPreviouslyUploadedDocuments)){
      fetchExtraDocuments()
    } else {setExtraDocumentsLoading(false)}
  }, [])

  const getErrorMsg = (errors) => {
    let file_errors_arr = _.get(errors, 'data.file')
    if(_.isArray(file_errors_arr)){
      return file_errors_arr.join('\n')
    }
    return getErrorMessage(errors);
  }

  const fetchExtraDocuments = async() => {
    /** Get previously uploaded extra documents */
    try{
      let previouslyUploadedDocuments = await getPreviouslyUploadedDocuments()
      setAdditionalFiles([...previouslyUploadedDocuments, EMPTY_FILE_FIELD])

    } catch (errors) {
      props.displayNotification('error', getErrorMsg(errors));
    }

    setExtraDocumentsLoading(false)
  }

  const deleteExtraFile = async(fileToRemove) => {
    /** Delete extra file */
    setExtraDocumentsLoading(true)

    try {
      if(_.isFunction(onExtraFileDelete)) {
        await onExtraFileDelete(fileToRemove)
      }

      // remove deleted file from list of files
      let newAdditionalFiles = additionalFiles.filter(file => file.id !== fileToRemove.id)
      setAdditionalFiles(newAdditionalFiles)

    } catch (errors){
      props.displayNotification('error', getErrorMsg(errors));
    }

    setExtraDocumentsLoading(false)
  }

  const uploadExtraFile = async (formData, added_file) => {
    /** Uploads extra file */

    setExtraDocumentsLoading(true)

    try {

      let response = {}
      if(_.isFunction(onExtraFileUpload)){
        response = await onExtraFileUpload(formData, added_file) || {}
      }

      // find empty file and fill it with formData
      let newAdditionalFiles = additionalFiles.map(file => {
        if(file.id === EMPTY_FILE_FIELD_ID){
          if (_.isEmpty(response)) {
            response = {id: added_file.lastModified}  // if response is empty - set id so element delete would work correctly
          }

          file = {...response, formData: formData}
        }
        return file
      })

      // update list of files with just uploaded file and new empty file
      setAdditionalFiles([...newAdditionalFiles, EMPTY_FILE_FIELD])

      if(handleSettingUpdate){
        handleSettingUpdate('extra_report_files')(newAdditionalFiles)  // add new file to report settings
      }
    
    } catch (errors) {
      props.displayNotification('error', getErrorMsg(errors));
    }

    setExtraDocumentsLoading(false)
  }


  return (
    <Grid container>

      {extraDocumentsLoading
        ? (<CircularProgress/>)
        : (
          <>
            {/* List of documents */}
            {additionalFiles.map((file, index) => {
              return(
                <SingleFileUploadInput
                  id={file.id || index}
                  file={file}
                  key={file.id || index}
                  onFileUpload={uploadExtraFile}
                  onFileRemove={deleteExtraFile}
                  filesToAccept={'application/pdf'}
                  explanationText={props.explanationText}
                  withExplanationText={props.withExplanationText}
                  title={title}
                />
              )
            })}
          </>
        )
      }
    </Grid>
  )
}

UploadAdditionalFiles.defaultProps = {
  title: 'Zusätzliches Dokument hochladen'
}

export default withNotification(UploadAdditionalFiles);