import React from 'react';
import { connect } from 'react-redux'
import _ from "lodash";
import {AppBar, Container, Grid} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import {DownloadIcon} from "../../../images";
import useStyles from '../../Modelportfolios/Overview/styles';
import Link from "../../../components/Link";
import {getInvestmentDynamicPath, getRouteFullPathByComponent} from "../../InvestmentPlatform/utils";
import {
  musterPortfolioInstrumentsTableStructure
} from "../../Modelportfolios/components/InstrumentsList/table-data";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {defaultExpandedItems, TabPanel} from "../../Modelportfolios/Overview/Overview";
import RiskAnalysisTab from "../../ProfessionalView/Tabs/RiskAnalysisTab";
import withInvestmentStrategyDashboardData from "../../../components/InvestmentStrategyDashboardDataProvider";

import {default as ProPerformanceTab} from "../../ProfessionalView/Tabs/PerformanceTab";
import {default as ProPortfolioStructureTab} from "../../ProfessionalView/Tabs/PortfolioStructureTab";
import {default as ProKeyFiguresTab} from "../../ProfessionalView/Tabs/KeyFiguresTab";
import InstrumentsTab from "../../Modelportfolios/Overview/components/ExtendedTabs/Instruments";
import {ModelPortfolioDetails} from "../../Modelportfolios/Overview/components";
import InvestmentDetailsNavigation from "../../CustomerDashboard/components/InvestmentDetailsNavigation";
import {
  SelectableInstrumentsTable
} from "../../../components/SelectForProductsComparisonModal/SelectForProductsComparisonModal";


const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
});

const tabsConfiguration = [
  { name: 'Instrumente', component: InstrumentsTab },
  { name: 'Wertentwicklung', component: ProPerformanceTab },
  { name: 'Portfoliostruktur', component: ProPortfolioStructureTab },
  { name: 'Kennzahlen', component: ProKeyFiguresTab },
  { name: 'Risikoanalyse', component: RiskAnalysisTab }
];

function Overview(props) {

  const classes = useStyles();

  const investmentStrategyData = props.investmentStrategyData;

  const [selectedTabId, setSelectedTabId] = React.useState(0);

  const [expandedItems, setExpandedItems] = React.useState(defaultExpandedItems);

  const investmentStrategy = (() => {

    if (props.investmentStrategy) {
      return props.investmentStrategy
    }

    if (investmentStrategyData.loading || investmentStrategyData.errors || !investmentStrategyData.data) {
      return {}
    }

    return investmentStrategyData.data

  })();

  /**
   * Investment strategy assets..
   * @type {Object[]}
   */
  const assets = _.get(investmentStrategy, 'related_model_portfolio.assets') || [];

  /**
   * Investment strategy, converted to that model portfolio format,
   * that is required for CIOS data tabs.
   * @type {Object}
   */
  const investmentStrategyAsModelPortfolio = !investmentStrategy.related_model_portfolio ? undefined : {
    data: {
      ...investmentStrategy,
      id: investmentStrategy.related_model_portfolio.id,
      assets: assets,
      by_top_holdings: investmentStrategy.related_model_portfolio.by_top_holdings,
      connect_portfolio_id: investmentStrategy.related_model_portfolio.connect_portfolio_id
    }
  };

  /**
   * Open Cleversoft link for Investment Strategy.
   */
  const handleDownloadClick = () => {
    window.open(investmentStrategy.factsheet_link,  '_blank')
  };

  const handleTabChange = (event, newValue) => {
    if (newValue !== undefined) {
      setSelectedTabId(newValue)
    }
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  };

  // region Internal variables-components

  const GoBackLink = () => {
    if (props.investmentPlatform.routes) {
      let fullPath = getRouteFullPathByComponent(
        props.investmentPlatform.routes, 'INVESTMENT_STRATEGIES_LIST');

      if (!fullPath) {
        return null
      }

      return (
        <Link
          title="Zurück zu Vermögensverwaltung"
          icon={<i class="fa fa-chevron-left" aria-hidden="true"></i>}
          underline
          onClick={() => props.history.push(`/${getInvestmentDynamicPath()}${fullPath}`)}
        />
      )

    }

    return null;
  };

  const virtualisedDataExists = props.portfolioId && props.investmentStrategyModelPortfolioConnectId;

  const NotFound = () => {
    return (
      <div className={classes.errorContainer}>
        <h1>404</h1>
        <p>Not found</p>
      </div>
    )
  };
  // endregion

  const handleExpandedItemsChange = (field, data) => {
    setExpandedItems(prevState => {
      return {...prevState, [field]: data}
    });
  };

  // TODO: It is placed there due to some disabledByFilter handling. Need to place it out of component
  if (props.isInstrumentsSelection) {
    /**
    * List of assets, that will be used for investment strategy assets table.
    * @type {Object[]}
    */
    const assetsTableDataSource = investmentStrategyData.loading ? new Array(5).fill({}) : assets;

    const getAvailableAssets = () => {
      return [].concat(assetsTableDataSource).filter(asset => !asset.disabledByFilter);
    };

    const handleAllSelected = () => {
      if (props.onSelectAll){
        const selected = props.selectedInstruments || [];
        let assets = getAvailableAssets();
        if (selected.length === assets.length) {
          assets = []; // unselect all
        }

        props.onSelectAll(assets)
      }
    };

    return (
      <Container style={{paddingBottom: 16}}>
        <SelectableInstrumentsTable
          container={investmentStrategy || {}}
          ContainerProps={{
            enabled: true,
            selectable: !!props.onSourceSelect,
            path: 'INVESTMENT_STRATEGIES_OVERVIEW',
            checked: props.sourceSelected,
            onChange: props.onSourceSelect,
            classes: {
              containerName: classes.containerName,
              containerCheckbox: classes.containerCheckbox
            }
          }}
          instruments={assetsTableDataSource}
          InstrumentsProps={{
            enabled: true,
            selected:  props.selectedInstruments || [],
            handleSelectAll: !!props.onSelectAll ? handleAllSelected : null,
            handleSelect: props.onInstrumentSelect,
            tableStructure: musterPortfolioInstrumentsTableStructure,
            totalInstrumentsCount: getAvailableAssets().length,
            tableClasses: classes
          }}
        />
      </Container>
    )
  }

  return (
    <>
      <Container style={{paddingBottom: 16}} className={`app-page-container`}>
        { !props.viewOnly && <GoBackLink /> }
        <div className={classes.navigationContainer} style={{marginBottom: 10}}>
          {investmentStrategyData.loading ? (
            <>
              <Skeleton style={{width: 300, height: 40}}/>
              <Skeleton style={{width: 250, height: 40}}/>
            </>
          ) : (
            <>
              {investmentStrategy && (
                <Grid container>
                  <Grid item xs={12} sm={true}><h1 className={classes.header}>{ investmentStrategy.name }</h1></Grid>

                  {investmentStrategy.factsheet_link && (
                    <Grid item xs={12} sm={'auto'}>
                      <PrimaryButton
                        text="Factsheet als PDF herunterladen"
                        icon={<DownloadIcon color="white"/>}
                        onButtonClick={handleDownloadClick}
                        style={{width: '100%'}}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </>
          )}
        </div>
        <Grid item xs={12}>
          <ModelPortfolioDetails
            isInvestmentStrategy
            loading={investmentStrategyData.loading}
            modelPortfolio={investmentStrategyAsModelPortfolio && investmentStrategyAsModelPortfolio.data || {}}
            investmentData={props.investmentData.data}
            investmentDataLoading={props.investmentData.loading}
            investmentDataLoadingError={props.investmentData.errors}
            sectionHeader={'Depotwert'}
            sectionSubheader={''}
          />
        </Grid>
        <Grid item xs={12} style={{marginTop: 15}}>
          <Grid container style={{marginBottom: 20}} id="sticky-trigger">
            <Grid item className={classes.investmentNavigationContainer} xs={12}>
              <InvestmentDetailsNavigation
                // options for time selection
                calculationDates={props.selectedDates}
                calculationDatesType={props.selectedDatesType}
                handleCalculationDatesChanged={props.onSelectedDatesChanged}
                investmentData={props.investmentData.data}

                // flags for components rendering
                dataLoading={props.dataLoading}
                // func to render benchmark component
                renderCustomSwitch={() => false}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.whiteBox}>
        <Container className={`app-page-container`}>
          {investmentStrategyData.errors ? (
            <>
              {investmentStrategyData.errors.status && investmentStrategyData.errors.status == 404 && (
                <NotFound />
              )}
            </>
          ) : (
            <>
              <AppBar position="static" className={classes.tabsHeader}>
                <Grid container>
                  <Tabs value={selectedTabId} onChange={handleTabChange} aria-label="simple tabs example"
                        classes={{indicator: classes.indicator}} variant="scrollable"
                  >
                    {tabsConfiguration.map((tab, index) => (
                      <Tab label={tab.name} {...a11yProps(index)}
                           className={classes.tabTitle}
                           classes={{ selected: classes.selected }}
                           disableRipple={true} key={'tab' + index}
                           disabled={(!investmentStrategyAsModelPortfolio || !virtualisedDataExists) && index != 0}
                      />
                    ))}
                  </Tabs>
                </Grid>
              </AppBar>
              {tabsConfiguration.map((tab, index) => (
                <TabPanel value={selectedTabId} index={index} className={classes.tabContent} key={'tab' + index}>
                  <tab.component
                    investmentStrategy={investmentStrategy}
                    modelPortfolio={investmentStrategyAsModelPortfolio}
                    portfolioName={_.get(investmentStrategyAsModelPortfolio, 'data.name')}
                    expandedItems={expandedItems}
                    onExpandedItemsChange={handleExpandedItemsChange}
                    passedClasses={classes}
                    {...props}
                  />
                </TabPanel>
              ))}
            </>
          )}
        </Container>
      </div>
    </>
  )
}

export default connect(mapStateToProps)(withInvestmentStrategyDashboardData(Overview));