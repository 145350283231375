import React from 'react';
import {
  Container,
  withWidth
} from '@material-ui/core';

import SettingsList from './settingsTabs';
import {DASHBOARD_TYPE} from './constants'

export default withWidth()((props) => {

  return (
    <React.Fragment>
      <Container className={'app-page-container'}>
        <SettingsList dashboardType={DASHBOARD_TYPE.CUSTOMER} newDesign={props.newDesign}/>
      </Container>
    </React.Fragment>
  )
});