import React from 'react';
import clsx from "clsx";
import _ from "lodash";
import styles from "../../styles";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import {getOriginLink} from "../../../../../../utils/constants";
import useOnClickOutside from "../../../../../../utils/useOnClickOutside";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Popover from "@material-ui/core/Popover";

const UserMenu = props => {

  const {
    classes,
    openAction,
    closeAction,
    open,
    userData,
    userLogo,
    handleLogoutClick,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const firstName = _.get(userData, 'broker.first_name', userData.first_name);
  const lastName = _.get(userData, 'broker.last_name', userData.last_name);

  const handleClick = (event) => {
    if(open) {
      closeAction()
    } else {
      setAnchorEl(event.currentTarget);
      openAction();
    }
  };

  const handleClose = () => {
    if (open) {
      closeAction();
      setAnchorEl(null);
    }
  };

  const handleInitials = () => {
    if (firstName && lastName) {
      return [firstName, lastName].map(
        i => i[0].toUpperCase()).join('');
    } else {
      return [lastName].map(
        i => i[0].toUpperCase()).join('');
    }
  }

  return (
    <>
      <div className={classes.dropdownMenuWrapper}>
        <IconButton classes={{root: classes.userLogoWrapper}} onClick={handleClick}>
          <div className={classes.userLogo} style={userLogo ? {backgroundImage: `url(${userLogo})`} : {}}>
            {userLogo ? '' : handleInitials()}
          </div>
        </IconButton>
        <Popover open={open} anchorEl={anchorEl}
                 onClose={handleClose}
                 classes={{paper: classes.dropdownMenu}}
                 anchorOrigin={{vertical: 55, horizontal: 'right'}}
                 transformOrigin={{vertical: 'top', horizontal: 'right'}}
        >
          <div className={clsx(classes.dropdownItem, 'user-info')}>
            <div>{firstName} {lastName}</div>
            <div className={classes.fontNormal}>Benutzernummer: {userData.broker_id}</div>
          </div>
          <div className={classes.menuItemDivider} />
          <a href={getOriginLink('/#/Benutzer/Profil')} className={classes.dropdownItem}>
            <FontAwesomeIcon icon={['far', 'user']} fixedWidth={true} />
            Profil verwalten
          </a>
          <a href={getOriginLink("/#/Benutzer/Einstellungen")} className={classes.dropdownItem}>
            <FontAwesomeIcon icon={['far', 'cog']} fixedWidth={true} />
            Einstellungen
          </a>
          <div className={classes.menuItemDivider} />
          <a href={null} className={classes.dropdownItem} onClick={handleLogoutClick}>
            <FontAwesomeIcon icon={['far', 'sign-out']} fixedWidth={true} />
            Abmelden
          </a>
        </Popover>
      </div>
    </>
  );
};

export default withStyles(styles)(UserMenu);