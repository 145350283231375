import {fromJS} from "immutable";
import {
  EXISTING_COMPARISON_STORAGE_KEY,
  NEW_COMPARISON_STORAGE_KEY,
  PRODUCT_COMPARISON_SET_COMPARISON,
  PRODUCT_SELECTION_TIME_SELECTOR_DATE_CHANGED
} from "./constants";

const initialState = fromJS({
  time_selector_date_change: {},
  [NEW_COMPARISON_STORAGE_KEY]: null,
  [EXISTING_COMPARISON_STORAGE_KEY]: null
});

function appReducer(state = initialState, action) {
  switch(action.type) {
    case PRODUCT_SELECTION_TIME_SELECTOR_DATE_CHANGED:
      return state.set('time_selector_date_change', fromJS(action.change));
    case PRODUCT_COMPARISON_SET_COMPARISON:
      return state.set(action.key, fromJS(action.comparison));
    default:
      return state;
  }
}

export default appReducer;