import {useState} from "react";
import _ from 'lodash';

import { PortfolioHandlerResource } from '../../../utils/api';

export function useEsgScoreData(customerId, portfolios, withHistoriacalPortfolios, dataProvider=PortfolioHandlerResource) {
  const [data, setData] = useState({
    data: null,
    errors: null,
    loading: false,
    updatedAt: +new Date()
  });

  async function fetch() {

    setData({
      data: null,
      errors: null,
      loading: true,
      updatedAt: +new Date()
    });

    try {

      const data = await dataProvider.getEsgScoreData(
        customerId, portfolios.map(ptf => ptf.depotNumberForAssets || ptf.depotNumber),
        withHistoriacalPortfolios);

      setData({
        data: data.data,
        errors: _.isEmpty(data.error)? null : data.error,
        loading: false,
        updatedAt: +new Date()
      })

    } catch (errors) {
      setData({
        data: null,
        errors: errors,
        loading: false,
        updatedAt: +new Date()
      });
    }
  }

  return [data, fetch];
}