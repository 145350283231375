import React from 'react';
import {
  Container,
  withWidth
} from '@material-ui/core';

import SettingsList from './settingsTabs';
import {DASHBOARD_TYPE} from './constants'

export default withWidth()((props) => {

  return (
    <React.Fragment>
      <Container className={'app-page-container'}>
        {/* TODO: newDesign flag as a workaround for now*/}
        <SettingsList dashboardType={DASHBOARD_TYPE.BROKER} newDesign={props.newDesign} />
      </Container>
    </React.Fragment>
  )
});