const styles = () => ({
  arrowsComponent: {
    display: 'flex',
  },

  arrows: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  arrowsButton: {
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    cursor: 'pointer'
  },

  arrowTop: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 3.5px 7px 3.5px',
    borderColor: 'transparent transparent #0092E5 transparent',
  },

  arrowBottom: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '7px 3.5px 0 3.5px',
    borderColor: '#0092E5 transparent transparent transparent',
  }
});

export default styles;
