import React from 'react';
import useAssetsInfoData from "../../hooks/useAssetsInfoData";
import {Grid} from "@material-ui/core";
import {ChartSectionBordered} from "../ChartSection/ChartSection";
import FondsKenzahlenTable, {
  getColumnHeader,
  handleValue,
  ProductNameColumn
} from "../FondsKenzahlenTable/FondsKenzahlenTable";
import {getDayChangeValue, getFundVolume, MISSING_FIELD} from "../../../FactSheetsPage/components/PerformanceChart";
import {
  FEES_TOOLTIPS,
  getInceptionDate,
  NO_BENCHMARK_MSG, NOT_ETG_MSG,
  transformFundsBenchmark
} from "../../../FactSheetsPage/Tabs/FondInformationTab";
import {formatPercentage, formatYesNo, toShortGermanFormat} from "../../../FactSheetsPage/utils";
import WarningTooltip from "../../../../components/WarningTooltip";
import clsx from "clsx";
import {commonStyles} from "../../styles";
import {FondsInformationTableStyles} from "./styles";
import {toGermanFormat} from "../../../../utils/numberFormater";
import _ from "lodash";


const getFootnoteOrValue = (product, formatter) => {
  return (!product.hasOwnProperty('asset_info') || product.asset_info.error) ? '*' : formatter(product.asset_info)
}

const getValueWithCurrency = (product, valueField, currencyField) => {
  const value = _.get(product, valueField);
  const currency = _.get(product, currencyField)

  if (!value || !currency) {
    return MISSING_FIELD
  }

  return toGermanFormat(value, '', ` ${currency}`, 2)
}

// following columns are taken from factsheets -> Fondsinformation tab tables
// Wertentwicklung
const FundCurrency = {
  header: {
    content: getColumnHeader('Fonds-währung', )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'currency_code')
    }
  }
}

const Course = {
  header: {
    content: getColumnHeader('Kurs')
  },
  body: {
    content: (product, options) => {
      return getValueWithCurrency(product, 'asset_info.performance.NAV', 'asset_info.currency_code')
    }
  }
}


const PreviousDayChange = {
  header: {
    content: getColumnHeader('Veränderung zum Vortag')
  },
  body: {
    content: (product, options) => {

      const performance = _.get(product, 'asset_info.performance') || {};
      const priceCurrency = _.get(product, 'asset_info.currency_code');

      return getDayChangeValue(performance, priceCurrency)

    }
  }
}

const FundVolume = {
  header: {
    content: getColumnHeader('Fondsvolumen', )
  },
  body: {
    content: (product, options) => {
      return getFootnoteOrValue(product, getFundVolume)
    }
  }
}

const MorningstarCategory = {
  header: {
    content: getColumnHeader('Morningstar Kategorie', )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'sub_class')
    }
  }
};

const CategoryCell = {
  header: {
    content: getColumnHeader('Produktgattungen', )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'category')
    }
  }
};

// Stammdaten
const InceptionDate = {
  header: {
    content: getColumnHeader('Auflegungs-datum', )
  },
  body: {
    content: (product, options) => {
      return getFootnoteOrValue(product, getInceptionDate)
    }
  }
}

const IsDistributed = {
  header: {
    content: getColumnHeader('Aus-schüttend', )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'income_distribution', formatYesNo)
    }
  }
}

const MorningstarBenchmark = {
  header: {
    content: getColumnHeader('Morningstar Benchmark', )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'morningstar_index_name', undefined, NO_BENCHMARK_MSG)
    }
  }
}

const FundBenchmark = {
  header: {
    content: getColumnHeader('Fondsbenchmark', )
  },
  body: {
    content: (product, options) => {
      return <span dangerouslySetInnerHTML={{__html: handleValue(product, 'asset_info', 'primary_benchmark', transformFundsBenchmark)}}></span>
    }
  }
}

const TypeOfReplication = {
  header: {
    content: getColumnHeader('Art der Replikation', )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'indexing_approach', undefined, NOT_ETG_MSG)
    }
  }
}

const SRI = {
  header: {
    content: getColumnHeader('SRI', )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'sri')
    }
  }
}

const AverageRating = {
  header: {
    content: getColumnHeader('ø Rating', )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'average_credit_quality')
    }
  }
}

const FundCompanyName = {
  header: {
    content: getColumnHeader('Fondsgesellschaft', )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'investment_company')
    }
  }
}

const FundType = {
  header: {
    content: getColumnHeader('Fondstyp', )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'fund_type')
    }
  }
}

const LegalForm = {
  header: {
    content: getColumnHeader('Rechtsform', )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'fund_legal_status')
    }
  }
}

// Gebühren
const ManagementFee = {
  header: {
    content: getColumnHeader('Verwaltungsvergütung', )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'product_fees_prospectus_actual_management_fee', formatPercentage)
    }
  }
}

const RedemptionFee = {
  header: {
    content: getColumnHeader('Rücknahmeentgelt', )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'product_fees_purchase_redemption_fee', formatPercentage)
    }
  }
}

const MaxEntryCharge = {
  header: {
    content: getColumnHeader('Max. Ausgabeaufschlag', )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'product_fees_purchase_front_load', formatPercentage)
    }
  }
}

const TotalRunningCharges = {
  header: {
    content: getColumnHeader('Gesamte Laufende Kosten', undefined, FEES_TOOLTIPS.product_fees_ongoing_cost_actual )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'product_fees_ongoing_cost_adjusted', formatPercentage)
    }
  }
}

const PerformanceFee = {
  header: {
    content: getColumnHeader('Performance Fee', undefined, FEES_TOOLTIPS.product_fees_performance_fee_charged )
  },
  body: {
    content: (product, options) => {
      return handleValue(product, 'asset_info', 'product_fees_performance_fee_charged')
    }
  }
}

const feesTableStructure = {
  default: [
    {content: [ProductNameColumn]},

    {content: [ManagementFee],       align: 'center'},
    {content: [RedemptionFee],       align: 'center'},
    {content: [MaxEntryCharge],      align: 'center'},
    {content: [TotalRunningCharges], align: 'center'},
    {content: [PerformanceFee],      align: 'center'},
  ]
}

const masterTableStrucutre = {
  default: [
    {content: [ProductNameColumn]},

    {content: [FundCurrency],        align: 'center'},
    {content: [Course],              align: 'center'},
    {content: [PreviousDayChange],   align: 'center'},
    {content: [FundVolume],          align: 'center'},

    {content: [InceptionDate],       align: 'center'},
    {content: [IsDistributed],       align: 'center'},
    {content: [CategoryCell],        align: 'center'},
    {content: [MorningstarCategory], align: 'center'},
    {content: [MorningstarBenchmark],align: 'center'},
    {content: [FundBenchmark],       align: 'center'},
    {content: [TypeOfReplication],   align: 'center'},
    {content: [SRI],                 align: 'center'},
    {content: [AverageRating],       align: 'center'},
    {content: [FundCompanyName],     align: 'center'},
    {content: [FundType],            align: 'center'},
    {content: [LegalForm],           align: 'center'},
  ]
}

function FondsInformationTab(props) {

  const classes = commonStyles();
  const [expandedSections, setExpandedSections] = React.useState({...props.expandedSectionsRef.current.fondsInformationTab});
  React.useEffect(() => {
    setExpandedSections({...props.expandedSectionsRef.current.fondsInformationTab})
  },[props.expandedSectionsRef.current])

  const onExpanded = (sectionKey) => () => {
    setExpandedSections((current) => {
      let newState = !current[sectionKey]
      props.expandedSectionsRef.current.fondsInformationTab[sectionKey] = newState
      return {
        ...current,
        [sectionKey]: newState
      }
    })
  }

  const assetsInfoData = useAssetsInfoData(props.selectedProducts)

  return (
    <>
      <Grid item xs={12}>
        <ChartSectionBordered
          title={(
            <>
              <b>Stammdaten</b>
              <WarningTooltip
                // Since no historical data is available for the fund information, it always refers to the current day.
                title={'Da zu den Fondsinformationen keine historischen Daten vorliegen, beziehen sich diese immer auf den aktuellen Tag.'}
                icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)}></i>}
                width={600}
              />
            </>
          )}
          loading={assetsInfoData.loading}
          errors={assetsInfoData.errors}
          displayError
          expanded={expandedSections.fondsKeyFiguresMasterTable}
          onExpanded={onExpanded('fondsKeyFiguresMasterTable')}
          content={
            (
              <FondsKenzahlenTable
                products={assetsInfoData.data || []}
                productKeysToCheck={['asset_info']}
                tableStructure={masterTableStrucutre}
                tableLayout={"auto"}
                customClasses={FondsInformationTableStyles()}
              />
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <ChartSectionBordered
          title={(
            <>
              <b>Gebühren</b>
              <WarningTooltip
                // Since no historical data is available for the fund information, it always refers to the current day.
                title={'Da zu den Fondsinformationen keine historischen Daten vorliegen, beziehen sich diese immer auf den aktuellen Tag.'}
                icon={<i className={clsx("far fa-info-circle", classes.chartSectionHeaderInfoIcon)}></i>}
                width={600}
              />
            </>
          )}
          loading={assetsInfoData.loading}
          errors={assetsInfoData.errors}
          displayError
          expanded={expandedSections.fondsKeyFiguresFeesTable}
          onExpanded={onExpanded('fondsKeyFiguresFeesTable')}
          content={
            (
              <FondsKenzahlenTable
                products={assetsInfoData.data || []}
                productKeysToCheck={['asset_info']}
                tableStructure={feesTableStructure}
                tableLayout={"auto"}
                customClasses={FondsInformationTableStyles()}
              />
            )
          }
        />
      </Grid>
    </>
  )
}

export default FondsInformationTab