import React from "react";
import {withEuroOrDash} from "../../../utils/utils";
import {getClearingAccountName} from './utils'

const RowNumberCell = {
  header: 'NR.',
  body: {
    content: (item, options, index) => `${index + 1}.`,
    className: 'bold'
  },
  key: 'RowNumberCell'
}

const NameCell = {
  header: 'KONTO',
  body: {
    content: (item) => getClearingAccountName(item),
    className: 'name bold',
    ellipsis: true
  },
  key: 'NameCell'
}

const ValueCell = {
  header: 'SALDO',
  body: {
    content: (item) => withEuroOrDash(item.ctx_currency_value),
  },
  key: 'ValueCell'
}

const tableStructure = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [NameCell]
    },
    {
      content: [ValueCell],
      align: 'right'
    }
  ]
}

export default tableStructure;