import React from "react";
import {NEED_TO_REFRESH_PAGE} from "./constants";

export const showErrors = (errors) => {
  // show a message (in German) telling the user to reload the page
  // to get data because the server received an error

  return errors ? NEED_TO_REFRESH_PAGE : undefined
}

export const defaultDataBody = {
  data: undefined,
  loading: true,
  options: undefined,
  errors: undefined
};
