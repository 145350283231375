import React from "react";
import clsx from 'clsx'

import withStyles from "@material-ui/core/styles/withStyles";
import SvgIcon from "@material-ui/core/SvgIcon";

import { BcaIconButtonTooltip } from '../BcaIconButton/BcaIconButton';
import styles from "./styles";

const IconButton = props => {
  const {
    classes,
    label,
    onClick,
    component,
    style
  } = props;

  let customClasses = props.customClasses || {}

  return (
    <BcaIconButtonTooltip tooltip={props.tooltip}>
      <button
        style={style || {}}
        className={clsx(classes.button, props.disabled && classes.disabledButton, customClasses.buttonRoot)}
        onClick={onClick}
        disabled={props.disabled}
      >
        <SvgIcon disabled={props.disabled} component={component} classes={{root: clsx(props.disabled && classes.iconDisabled, customClasses.iconRoot)}}/>
        {label && (
          <span className={clsx(classes.label, props.disabled && classes.disabledLabel)}>
            {label}
          </span>
        )}
      </button>
    </BcaIconButtonTooltip>
  )
};

export default withStyles(styles)(IconButton);