import React from "react";

export default () => {
  return (
    <svg
      id="Gruppe_8253"
      data-name="Gruppe 8253"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_8076"
            data-name="Rechteck 8076"
            width="30"
            height="30"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_8252" data-name="Gruppe 8252" clip-path="url(#clip-path)">
        <path
          id="Pfad_7868"
          data-name="Pfad 7868"
          d="M16.875,30H15.167a8.593,8.593,0,0,1-5.329-1.838l-.082-.072L.911,19.36a3.1,3.1,0,1,1,4.381-4.389l1.2,1.2V5.264a3.094,3.094,0,0,1,4.4-2.814A3.1,3.1,0,0,1,16.6,1.557a3.1,3.1,0,0,1,4.743,2.626v.4a3.1,3.1,0,0,1,4.327,2.847v6.248a9.342,9.342,0,0,0-1.875-.46V7.428a1.226,1.226,0,1,0-2.452,0v5.408H19.469V4.182a1.226,1.226,0,0,0-2.452,0v7.572H15.142V3.1a1.226,1.226,0,0,0-2.452,0v9.735H10.815V5.264a1.226,1.226,0,0,0-2.452,0V20.693l-4.4-4.4a1.254,1.254,0,0,0-1.733,0,1.226,1.226,0,0,0,0,1.733l8.8,8.686a6.723,6.723,0,0,0,3.962,1.4A9.424,9.424,0,0,0,16.875,30M30,22.5A7.5,7.5,0,1,1,22.5,15,7.5,7.5,0,0,1,30,22.5m-7.5-5.625a5.587,5.587,0,0,0-3.254,1.045L22.5,21.174l3.254-3.254A5.587,5.587,0,0,0,22.5,16.875m-4.58,8.879L21.175,22.5,17.92,19.246a5.589,5.589,0,0,0,0,6.508m4.58,2.371a5.587,5.587,0,0,0,3.254-1.045L22.5,23.826,19.246,27.08A5.587,5.587,0,0,0,22.5,28.125M28.125,22.5a5.592,5.592,0,0,0-1.045-3.254L23.826,22.5l3.254,3.254A5.589,5.589,0,0,0,28.125,22.5"
          transform="translate(0)"
          fill="#80858c"
        />
      </g>
    </svg>
  );
};
