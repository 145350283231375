import React from 'react'

import Resource from "../utils/api";
import axios from "axios";
import {
  dataFetchingFailedAction,
  dataFetchingFinishedAction,
  FETCH_DATA_INITIAL_STATE,
  fetchDataReducer,
  startDataFetchingAction
} from "./constants";
import {downloadPdf} from "../utils/utils";
import {fromJS} from "immutable";


/**
 * Custom hook, that encapsulate logic of data fetching.
 *
 * @param {String} path - Path to request
 * @param {'get' | 'post' | 'delete' | 'patch' } method Request method
 */
function useFetchDocument(path, method) {

  const [state, dispatch] = React.useReducer(fetchDataReducer, fromJS(FETCH_DATA_INITIAL_STATE))

  const cancelToken = React.useRef(undefined)

  /**
   * Make request using provided parameters.
   *
   * @return {Promise<void>}
   */
  const makeRequest = async (params) => {

    //Check if there are any previous pending requests
    if (typeof cancelToken.current != 'undefined') {
      cancelToken.current.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken.current = axios.CancelToken.source();

    dispatch(startDataFetchingAction())

    try {

      await downloadPdf(path, undefined, method.toUpperCase(),
        method === 'get' ? params : undefined,
        method !== 'get' ? params : undefined)

      // Put Success flag to the response, cause document was downloaded.
      dispatch(dataFetchingFinishedAction(true))

    } catch (errors) {
      if (errors !== 'Operation canceled due to new request.') {
        dispatch(dataFetchingFailedAction(errors))
      }
    }

  }

  return [state.toJS(), makeRequest]

}




export default useFetchDocument