import React from 'react';
import { connect } from 'react-redux';
import Tour from 'reactour';
import clsx from "clsx";
import {withRouter} from "react-router-dom";

import Button from "@material-ui/core/Button/Button";
import withWidth from '@material-ui/core/withWidth';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import useStyles from './styles';
import {ASSET_MODAL_TYPE} from "../AssetModal/constants";
import {
  closeGuide,
  closeMenu,
  closeSeriesDropdown,
  closeAnalyseDropdown,
  setLastStepIndex,
  updateGuideTourTrigger
} from '../Header/actions';
import {closeAssetModal, setAssetModalContentType} from "../AssetModal/actions";
import {resetCreateGroup} from "../../containers/ReportGroupCreation/actions";
import {removeGroupForGuide} from "../../containers/DashboardSettings/actions";


const mapStateToProps = (state) => ({
	guide: state.get('guide').toJS()
});


const TourContentComponent = ({title, description}) => {
  const classes = useStyles();

  return (
    <div>
      {title && (
        <p className={classes.tourContentComponentHeader}>{title}</p>
      )}
      <p className={classes.tourContentComponentBody} dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  )
};


const TourComponent = (props) => {
  const {
    dispatch,
    width,
    guide,
    onStepUpdated,
    history,
    location: {
      pathname
    }
  } = props;

  const classes = useStyles();

  const [currentStep, setCurrentStep] = React.useState(guide.lastStepIndex || 0);

  const steps = guide.steps.map((step, index) => ({
      selector: step.selector,
      content: <TourContentComponent title={step.title} description={step.content} />,
      position: step.position,
      action: (node) => {
        if (step.dispatchFn) {
          step.dispatchFn.forEach((func) => func && dispatch(func()));
          if (guide.updateTrigger !== step.selector) {
            dispatch(updateGuideTourTrigger(step.selector))
          }
        }
        if (step.action) {
          step.action(index + 1);
          if (onStepUpdated) {
            onStepUpdated(step.chapter_key, index + 1);
          }
        }
        if (step.routeFn) {
          step.routeFn();
        }
        if (step.pathname) {
          history.replace(step.pathname);
        }
        node && node.focus();
      }
  }));

  const isLastStep = () => {
    return currentStep === steps.length - 1;
  };

  const handleSkipClick = () => {
    if (onStepUpdated) {
      steps.forEach((step, index) => {
        if (step.action) {
          step.action();
        }
        onStepUpdated(step.chapter_key, index + 1);
      });
    }
    return handleRequestClose();
  };

  const handlePrevStep = () => {
    return setCurrentStep(currentStep - 1);
  };

  const handleNextStep = () => {
    if (isLastStep()) {
      return handleRequestClose();
    }

    return setCurrentStep(currentStep + 1)
  };

  const handleRequestClose = () => {
    dispatch(closeGuide());
    dispatch(closeMenu());
    dispatch(closeAnalyseDropdown());
    dispatch(closeSeriesDropdown());
    dispatch(closeAssetModal());
    dispatch(setAssetModalContentType(ASSET_MODAL_TYPE.MENU));
    dispatch(setLastStepIndex(0));
    dispatch(resetCreateGroup());
    dispatch(removeGroupForGuide());
  };

  const handleAfterOpen = () => {
    document.body.style.overflowY = 'hidden';
  };

  const handleBeforeClose = () => {
    document.body.style.overflowY = 'auto';
    setCurrentStep(0);
  };

  const childrenContent = (
    <div className={classes.navigationWrapper}>
      <Button
        color="primary"
        disableRipple={true}
        disableFocusRipple={true}
        className={classes.tourSkipButton}
        onClick={handleSkipClick}
      >
        Tour überspringen
      </Button>
        <div className={classes.nextPrevButtonsWrapper}>
          <Button
            color="primary"
            variant="contained"
            disabled={currentStep === 0}
            className={clsx(classes.tourNavigationButton, classes.prevBtn)}
            onClick={handlePrevStep}
          >
            <ArrowBackIcon className={classes.arrowIcon}/> Zurück
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.tourNavigationButton}
            onClick={handleNextStep}
          >
            {isLastStep() ? 'Schließen' : 'Weiter'}
            <ArrowForwardIcon className={classes.arrowIcon}/>
          </Button>
        </div>
    </div>
  );

  return <Tour
    startAt={guide.lastStepIndex}
    isOpen={guide.active}
    accentColor="#0092E5"
    steps={steps}
    className={classes.helper}
    onRequestClose={handleRequestClose}
    onAfterOpen={handleAfterOpen}
    onBeforeClose={handleBeforeClose}
    disableInteraction
    highlightedMaskClassName={classes.highlightedMaskClassName}
    showCloseButton={false}
    showNavigation={false}
    children={childrenContent}
    getCurrentStep={setCurrentStep}
    goToStep={currentStep}
    nextStep={handleNextStep}
    update={`${guide.updateTrigger} ${pathname}`}
    updateDelay={100}
    inViewThreshold={60} // if element is 60px close to edge of screenView it will be centered
  />
};

export default withWidth()(connect(mapStateToProps)(withRouter(TourComponent)));