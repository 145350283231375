const SIDEBAR_CONF_MOCK = [
  {
     "id":0,
     "typ":0,
     "label":"Beratung",
     "titel":null,
     "url":null,
     "sortierPosition":100,
     "callbackStatus":0,
     "infoLinkText":null,
     "infoLinkUri":null,
     "deepLink":null,
     "sortiereEintraege":false,
     "eintraege":[
        {
           "id":0,
           "typ":2,
           "label":"Investment",
           "titel":null,
           "url":null,
           "sortierPosition":70,
           "callbackStatus":0,
           "infoLinkText":null,
           "infoLinkUri":null,
           "deepLink":null,
           "sortiereEintraege":false,
           "eintraege":[
              {
                 "id":0,
                 "typ":0,
                 "label":"Serienreporting",
                 "titel":"Sie werden weitergeleitet",
                 "url":"https://dev10.diva.dev.bcaag.de/redirect-to-vermoegen/reporting",
                 "sortierPosition":2,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":15,
                 "typ":2,
                 "label":"Allgemeiner Einstieg",
                 "titel":"DIVA Investment",
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":16,
                 "typ":2,
                 "label":"Kundendepot",
                 "titel":"DIVA Investment",
                 "url":null,
                 "sortierPosition":110,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":2,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":17,
                 "typ":2,
                 "label":"Kundendetails",
                 "titel":"DIVA Investment",
                 "url":null,
                 "sortierPosition":120,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":2,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":2,
                 "typ":2,
                 "label":"BfV ETF DEPOT",
                 "titel":"BfV ETF DEPOT",
                 "url":null,
                 "sortierPosition":130,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":0,
                 "typ":0,
                 "label":"Investment Shop",
                 "titel":"Sie werden weitergeleitet",
                 "url":"http://test-rtg-portal.bca.de:8081/?agent=4330399999",
                 "sortierPosition":131,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":21,
                 "typ":2,
                 "label":"Online Vermögensverwaltung",
                 "titel":"Online Vermögensverwaltung",
                 "url":null,
                 "sortierPosition":140,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":25,
                 "typ":2,
                 "label":"Investment Infothek",
                 "titel":"Investment Infothek",
                 "url":"https://www.bfv-ag.de/investment-infothek/",
                 "sortierPosition":150,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":24,
                 "typ":2,
                 "label":"Fondsresearch",
                 "titel":"Fondsresearch",
                 "url":null,
                 "sortierPosition":160,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              }
           ],
           "postData":null,
           "postHeader":null,
           "postEncoding":null,
           "kundenId":null,
           "toolDaten":null,
           "istPdf":0,
           "kundendatenAnfordern":0,
           "disclaimer":null,
           "internal":false
        },
        {
           "id":0,
           "typ":2,
           "label":"Versicherung",
           "titel":null,
           "url":null,
           "sortierPosition":80,
           "callbackStatus":0,
           "infoLinkText":null,
           "infoLinkUri":null,
           "deepLink":null,
           "sortiereEintraege":false,
           "eintraege":[
              {
                 "id":-1,
                 "typ":0,
                 "label":"Vergleichsrechner",
                 "titel":"Beratung starten",
                 "url":null,
                 "sortierPosition":99,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":2,
                 "disclaimer":null,
                 "internal":true
              },
              {
                 "id":26,
                 "typ":2,
                 "label":"softfair",
                 "titel":"softfair",
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":1,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":20,
                 "typ":2,
                 "label":"NAFI",
                 "titel":"NAFI",
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":2,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":4,
                 "typ":2,
                 "label":"Gewerberechner",
                 "titel":"Gewerbeplattform",
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":1,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":6,
                 "typ":2,
                 "label":"myLife",
                 "titel":"myLife",
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":"Aktuelle Informationen zu Honorarkonzept",
                 "infoLinkUri":"https://www.honorarkonzept.de/index.php/mylife-invest-ber.html",
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":1,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":3,
                 "typ":2,
                 "label":"Covomo",
                 "titel":"Covomo",
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":1,
                 "disclaimer":"<Root><MessageBox Header=\"Covomo\" MessageBoxButton=\"YesNoCancel\">HINWEIS: Sie betreten jetzt das Portal des Spezialmaklers Covomo. \r\n\r\nEine Weitergabe personenbezogener Daten des Kunden durch Sie an Dritte darf nur erfolgen, wenn der Kunde Ihnen zuvor eine entsprechende datenschutzrechtliche Einwilligungserklärung erteilt hat. \r\n\r\nEine Datenschutzrechtliche Einwilligungserklärung zur Weitergabe personenbezogener Kundendaten an Dritte liegt vor?</MessageBox></Root>",
                 "internal":false
              },
              {
                 "id":22,
                 "typ":2,
                 "label":"Care Concept",
                 "titel":"Care Concept",
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":"<Root><MessageBox Header=\"Information\" MessageBoxButton=\"YesNoCancel\">HINWEIS: Sie betreten jetzt das Portal des Mehrfachagenten Care Concept AG. \r\n\r\nEine Weitergabe personenbezogener Daten des Kunden durch Sie an Dritte darf nur erfolgen, wenn der Kunde Ihnen zuvor eine entsprechende datenschutzrechtliche Einwilligungserklärung erteilt hat. \r\n\r\nEine Datenschutzrechtliche Einwilligungserklärung zur Weitergabe personenbezogener Kundendaten an Dritte liegt vor?</MessageBox></Root>",
                 "internal":false
              },
              {
                 "id":52,
                 "typ":2,
                 "label":"DIN77230",
                 "titel":"DIN77230",
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              }
           ],
           "postData":null,
           "postHeader":null,
           "postEncoding":null,
           "kundenId":null,
           "toolDaten":null,
           "istPdf":0,
           "kundendatenAnfordern":0,
           "disclaimer":null,
           "internal":false
        },
        {
           "id":9,
           "typ":2,
           "label":"Weltsparen",
           "titel":"Weltsparen",
           "url":null,
           "sortierPosition":110,
           "callbackStatus":0,
           "infoLinkText":null,
           "infoLinkUri":null,
           "deepLink":null,
           "sortiereEintraege":false,
           "eintraege":[
              
           ],
           "postData":null,
           "postHeader":null,
           "postEncoding":null,
           "kundenId":null,
           "toolDaten":null,
           "istPdf":0,
           "kundendatenAnfordern":0,
           "disclaimer":null,
           "internal":false
        },
        {
           "id":8,
           "typ":2,
           "label":"Procheck24",
           "titel":"Procheck24",
           "url":null,
           "sortierPosition":120,
           "callbackStatus":0,
           "infoLinkText":null,
           "infoLinkUri":null,
           "deepLink":null,
           "sortiereEintraege":false,
           "eintraege":[
              
           ],
           "postData":null,
           "postHeader":null,
           "postEncoding":null,
           "kundenId":null,
           "toolDaten":null,
           "istPdf":0,
           "kundendatenAnfordern":1,
           "disclaimer":null,
           "internal":false
        },
        {
           "id":48,
           "typ":2,
           "label":"Honorarkonzept",
           "titel":"Honorarkonzept",
           "url":"https://www.bca.de/honorarkonzept",
           "sortierPosition":130,
           "callbackStatus":0,
           "infoLinkText":null,
           "infoLinkUri":null,
           "deepLink":null,
           "sortiereEintraege":false,
           "eintraege":[
              
           ],
           "postData":null,
           "postHeader":null,
           "postEncoding":null,
           "kundenId":null,
           "toolDaten":null,
           "istPdf":0,
           "kundendatenAnfordern":0,
           "disclaimer":null,
           "internal":false
        }
     ],
     "postData":null,
     "postHeader":null,
     "postEncoding":null,
     "kundenId":null,
     "toolDaten":null,
     "istPdf":0,
     "kundendatenAnfordern":0,
     "disclaimer":null,
     "internal":false
  },
  {
     "id":0,
     "typ":0,
     "label":"Vertriebshilfen",
     "titel":null,
     "url":null,
     "sortierPosition":100,
     "callbackStatus":0,
     "infoLinkText":null,
     "infoLinkUri":null,
     "deepLink":null,
     "sortiereEintraege":false,
     "eintraege":[
        {
           "id":0,
           "typ":2,
           "label":"Tarifrechner",
           "titel":null,
           "url":null,
           "sortierPosition":98,
           "callbackStatus":0,
           "infoLinkText":null,
           "infoLinkUri":null,
           "deepLink":null,
           "sortiereEintraege":true,
           "eintraege":[
              {
                 "id":0,
                 "typ":2,
                 "label":"Komposit",
                 "titel":null,
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":true,
                 "eintraege":[
                    {
                       "id":0,
                       "typ":1,
                       "label":"Continentale",
                       "titel":"Continentale",
                       "url":"41028OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Deurag",
                       "titel":"Deurag",
                       "url":"43101OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"die Haftpflichtkasse",
                       "titel":"die Haftpflichtkasse",
                       "url":null,
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          {
                             "id":0,
                             "typ":1,
                             "label":"Bauherren-Haftpflichtversicherung",
                             "titel":"Bauherren-Haftpflichtversicherung",
                             "url":"41108OpenTarifBAU",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"Betrieb - Gesundheitsfachberufe",
                             "titel":"Betrieb - Gesundheitsfachberufe",
                             "url":"41108OpenTarifBHVHeil",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"Betriebs - Handel / Gewerbe",
                             "titel":"Betriebs - Handel / Gewerbe",
                             "url":"41108OpenTarifBHVHandel",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"Betriebs - Hotel / Gastronomie",
                             "titel":"Betriebs - Hotel / Gastronomie",
                             "url":"41108OpenTarifBHVHotel",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"Gewässerschaden",
                             "titel":"Gewässerschaden",
                             "url":"41108OpenTarifGWH",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"Haus- und Grundbesitzer",
                             "titel":"Haus- und Grundbesitzer",
                             "url":"41108OpenTarifHUG",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"Hausrat für Erstwohnungen",
                             "titel":"Hausrat für Erstwohnungen",
                             "url":"41108OpenTarifHRV",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"Privat-Haftpflichtversicherung",
                             "titel":"Privat-Haftpflichtversicherung",
                             "url":"41108OpenTarifPHV",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"Tierhalter-Haftpflichtversicherung",
                             "titel":"Tierhalter-Haftpflichtversicherung",
                             "url":"41108OpenTarifTHV",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"Unfallversicherung Einfach",
                             "titel":"Unfallversicherung Einfach",
                             "url":"41108OpenTarifUV",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          }
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"HDI",
                       "titel":"HDI",
                       "url":"41224OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Hiscox",
                       "titel":"Hiscox",
                       "url":"40019OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Inter",
                       "titel":"Inter",
                       "url":"43200OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"InterRisk",
                       "titel":"InterRisk",
                       "url":"41036OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"KS Auxilia",
                       "titel":"KS Auxilia",
                       "url":"43111OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Markel",
                       "titel":"Markel",
                       "url":"41139OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Nürnberger",
                       "titel":"Nürnberger",
                       "url":"41113OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":2,
                       "label":"Rhion Webrechner",
                       "titel":null,
                       "url":null,
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          {
                             "id":42,
                             "typ":2,
                             "label":"Haftpflichtversicherung",
                             "titel":"Haftpflichtversicherung",
                             "url":null,
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":false,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":43,
                             "typ":2,
                             "label":"Hausratversicherung",
                             "titel":"Hausratversicherung",
                             "url":null,
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":false,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":44,
                             "typ":2,
                             "label":"KFZ",
                             "titel":"KFZ",
                             "url":null,
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":false,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":45,
                             "typ":2,
                             "label":"Privat Schutzbrief",
                             "titel":"Privat Schutzbrief",
                             "url":null,
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":false,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":46,
                             "typ":2,
                             "label":"Unfallversicherung",
                             "titel":"Unfallversicherung",
                             "url":null,
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":false,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":47,
                             "typ":2,
                             "label":"Wohngebäudeversicherung",
                             "titel":"Wohngebäudeversicherung",
                             "url":null,
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":false,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          }
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"uniVersa",
                       "titel":"uniVersa",
                       "url":"41135OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    }
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":0,
                 "typ":2,
                 "label":"Kranken",
                 "titel":null,
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":true,
                 "eintraege":[
                    {
                       "id":0,
                       "typ":1,
                       "label":"Allianz",
                       "titel":"Allianz",
                       "url":"44119OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Alte Oldenburger",
                       "titel":"Alte Oldenburger",
                       "url":"44217OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"AXA / DBV",
                       "titel":"AXA / DBV",
                       "url":"44155OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Care Concept",
                       "titel":"Care Concept",
                       "url":"44150OpenTarifBCA",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Continentale",
                       "titel":"Continentale",
                       "url":"44117OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":41,
                       "typ":2,
                       "label":"Deutsche Familienversicherung",
                       "titel":"Deutsche Familienversicherung",
                       "url":null,
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":false,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":2,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"DKV",
                       "titel":"DKV",
                       "url":"44032OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Gothaer",
                       "titel":"Gothaer",
                       "url":"44121OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Hallesche",
                       "titel":"Hallesche",
                       "url":"44162OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Hanse Merkur",
                       "titel":"Hanse Merkur",
                       "url":null,
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          {
                             "id":0,
                             "typ":1,
                             "label":"AG365 (KV) + AG365 (RS)",
                             "titel":"AG365 (KV) + AG365 (RS)",
                             "url":"44322OpenTarif77",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"AGG (KV) + AGG (RS)",
                             "titel":"AGG (KV) + AGG (RS)",
                             "url":"44322OpenTarif79",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"AGL (KV) + AGL (RS)",
                             "titel":"AGL (KV) + AGL (RS)",
                             "url":"44322OpenTarif78",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"AGV (KV) + AGV (RS)",
                             "titel":"AGV (KV) + AGV (RS)",
                             "url":"44322OpenTarif73",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"AP08 (KV) + AP08 (RS)",
                             "titel":"AP08 (KV) + AP08 (RS)",
                             "url":"44322OpenTarif72",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"Jahres-RRV+RAB",
                             "titel":"Jahres-RRV+RAB",
                             "url":"44322OpenTarif86",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"RK365",
                             "titel":"RK365",
                             "url":"44322OpenTarif90",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"RKJ 09",
                             "titel":"RKJ 09",
                             "url":"44322OpenTarif89",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"RKL",
                             "titel":"RKL",
                             "url":"44322OpenTarif75",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"S (KV) + S (RS)",
                             "titel":"S (KV) + S (RS)",
                             "url":"44322OpenTarif76",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          }
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Münchner Verein",
                       "titel":"Münchner Verein",
                       "url":null,
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          {
                             "id":0,
                             "typ":1,
                             "label":"Krankenhaustagegeld",
                             "titel":"Krankenhaustagegeld",
                             "url":"44164OpenTarifKTG",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"Pflege",
                             "titel":"Pflege",
                             "url":"44164OpenTarifPflege",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          },
                          {
                             "id":0,
                             "typ":1,
                             "label":"Zahn",
                             "titel":"Zahn",
                             "url":"44164OpenTarifZahn",
                             "sortierPosition":100,
                             "callbackStatus":0,
                             "infoLinkText":null,
                             "infoLinkUri":null,
                             "deepLink":null,
                             "sortiereEintraege":true,
                             "eintraege":[
                                
                             ],
                             "postData":null,
                             "postHeader":null,
                             "postEncoding":null,
                             "kundenId":null,
                             "toolDaten":null,
                             "istPdf":0,
                             "kundendatenAnfordern":0,
                             "disclaimer":null,
                             "internal":false
                          }
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Stuttgarter",
                       "titel":"Stuttgarter",
                       "url":"40037OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Süddeutsche",
                       "titel":"Süddeutsche",
                       "url":"44123OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    }
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":0,
                 "typ":1,
                 "label":"Vorsorge",
                 "titel":"Vorsorge",
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":true,
                 "eintraege":[
                    {
                       "id":0,
                       "typ":1,
                       "label":"Allianz",
                       "titel":"Allianz",
                       "url":"30013OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Alte Leipziger",
                       "titel":"Alte Leipziger",
                       "url":"30130OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"AXA / DBV",
                       "titel":"AXA / DBV",
                       "url":"30017OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Barmenia",
                       "titel":"Barmenia",
                       "url":"30105OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Basler",
                       "titel":"Basler",
                       "url":"30131OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Canada Life",
                       "titel":"Canada Life",
                       "url":"30146OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Condor",
                       "titel":"Condor",
                       "url":"30054OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Continentale",
                       "titel":"Continentale",
                       "url":"30024OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"DB Vita Premium / Premium Select",
                       "titel":"DB Vita Premium / Premium Select",
                       "url":"31141OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Dialog",
                       "titel":"Dialog",
                       "url":"30029OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"die Bayerische",
                       "titel":"die Bayerische",
                       "url":"40105OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"die Dortmunder",
                       "titel":"die Dortmunder",
                       "url":"30133OpenTarifDortLV",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Ergo",
                       "titel":"Ergo",
                       "url":"30065OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Gothaer",
                       "titel":"Gothaer",
                       "url":"31021OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Hannoversche Leben",
                       "titel":"Hannoversche Leben",
                       "url":"30044OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"HanseMerkur",
                       "titel":"HanseMerkur",
                       "url":"44422OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"HDI",
                       "titel":"HDI",
                       "url":"30026OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Helvetia",
                       "titel":"Helvetia",
                       "url":"30071OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Ideal",
                       "titel":"Ideal",
                       "url":"30115OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"LV 1871",
                       "titel":"LV 1871",
                       "url":"30138OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"neue Leben",
                       "titel":"neue Leben",
                       "url":"30016OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Nürnberger",
                       "titel":"Nürnberger",
                       "url":"30136OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Standard Life",
                       "titel":"Standard Life",
                       "url":"30134OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Stuttgarter",
                       "titel":"Stuttgarter",
                       "url":"30037OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Swiss Life",
                       "titel":"Swiss Life",
                       "url":"30015OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"uniVersa",
                       "titel":"uniVersa",
                       "url":"30096OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Volkswohl Bund",
                       "titel":"Volkswohl Bund",
                       "url":"30133OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Württembergische",
                       "titel":"Württembergische",
                       "url":"30103OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"WWK",
                       "titel":"WWK",
                       "url":"30021OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Zurich",
                       "titel":"Zurich",
                       "url":"30143OpenTarif",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Zurich Eagle Star",
                       "titel":"Zurich Eagle Star",
                       "url":"30111Zurichlife",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Zurich Eagle Star Basic",
                       "titel":"Zurich Eagle Star Basic",
                       "url":"30111ZurichESB",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Zurich Eagle Star KSB",
                       "titel":"Zurich Eagle Star KSB",
                       "url":"30111ZurichESKSB",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    }
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              }
           ],
           "postData":null,
           "postHeader":null,
           "postEncoding":null,
           "kundenId":null,
           "toolDaten":null,
           "istPdf":0,
           "kundendatenAnfordern":0,
           "disclaimer":null,
           "internal":false
        },
        {
           "id":0,
           "typ":1,
           "label":"Maklerportale",
           "titel":"Maklerportale",
           "url":null,
           "sortierPosition":99,
           "callbackStatus":0,
           "infoLinkText":null,
           "infoLinkUri":null,
           "deepLink":null,
           "sortiereEintraege":true,
           "eintraege":[
              {
                 "id":0,
                 "typ":1,
                 "label":"Komposit",
                 "titel":"Komposit",
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":true,
                 "eintraege":[
                    {
                       "id":0,
                       "typ":1,
                       "label":"Adcuri",
                       "titel":"Adcuri",
                       "url":"42016OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Allianz",
                       "titel":"Allianz",
                       "url":"41112OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Alte Leipziger",
                       "titel":"Alte Leipziger",
                       "url":"42118OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Ammerländer",
                       "titel":"Ammerländer",
                       "url":"40050OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"ARAG",
                       "titel":"ARAG",
                       "url":"40150OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"AXA / DBV",
                       "titel":"AXA / DBV",
                       "url":"42115OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Barmenia",
                       "titel":"Barmenia",
                       "url":"41106OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Basler",
                       "titel":"Basler",
                       "url":"41035OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Continentale",
                       "titel":"Continentale",
                       "url":"41028OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"D.A.S",
                       "titel":"D.A.S",
                       "url":"40073OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Deurag",
                       "titel":"Deurag",
                       "url":"43101OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"die Bayerische",
                       "titel":"die Bayerische",
                       "url":"40105OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"die Haftpflichtkasse",
                       "titel":"die Haftpflichtkasse",
                       "url":"41108OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Domcura",
                       "titel":"Domcura",
                       "url":"41201OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Gothaer",
                       "titel":"Gothaer",
                       "url":"41123OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"HanseMerkur",
                       "titel":"HanseMerkur",
                       "url":"40090OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"HDI",
                       "titel":"HDI",
                       "url":"41224OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Helvetia",
                       "titel":"Helvetia",
                       "url":"40092OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Ideal",
                       "titel":"Ideal",
                       "url":"40418OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Inter",
                       "titel":"Inter",
                       "url":"43200OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Janitos",
                       "titel":"Janitos",
                       "url":"41118OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"K&M",
                       "titel":"K&M",
                       "url":"45022OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"KS Auxilia",
                       "titel":"KS Auxilia",
                       "url":"43111OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"NRV",
                       "titel":"NRV",
                       "url":"40119OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Nürnberger",
                       "titel":"Nürnberger",
                       "url":"41113OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Ostangler",
                       "titel":"Ostangler",
                       "url":"40151OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Prokundo",
                       "titel":"Prokundo",
                       "url":"40035OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"R+V",
                       "titel":"R+V",
                       "url":"41215OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Rhion",
                       "titel":"Rhion",
                       "url":"40138OpenPortal2",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Signal Iduna",
                       "titel":"Signal Iduna",
                       "url":"40228OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Stuttgarter",
                       "titel":"Stuttgarter",
                       "url":"40037OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"uniVersa",
                       "titel":"uniVersa",
                       "url":"41135OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"VHV",
                       "titel":"VHV",
                       "url":"41025OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Volkswohl Bund",
                       "titel":"Volkswohl Bund",
                       "url":"30133OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Württembergische",
                       "titel":"Württembergische",
                       "url":"40142OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Zurich Life",
                       "titel":"Zurich Life",
                       "url":"42018OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    }
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":0,
                 "typ":1,
                 "label":"Kranken",
                 "titel":"Kranken",
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":true,
                 "eintraege":[
                    {
                       "id":0,
                       "typ":1,
                       "label":"AXA / DBV",
                       "titel":"AXA / DBV",
                       "url":"44155OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Barmenia",
                       "titel":"Barmenia",
                       "url":"44118OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Continentale",
                       "titel":"Continentale",
                       "url":"41028OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Deutscher Ring",
                       "titel":"Deutscher Ring",
                       "url":"44161OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"die Bayerische",
                       "titel":"die Bayerische",
                       "url":"40105OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"DKV",
                       "titel":"DKV",
                       "url":"44032OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Gothaer",
                       "titel":"Gothaer",
                       "url":"44121OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Hallesche",
                       "titel":"Hallesche",
                       "url":"44162OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"HanseMerkur",
                       "titel":"HanseMerkur",
                       "url":"44322OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Janitos",
                       "titel":"Janitos",
                       "url":"44124OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Münchener Verein",
                       "titel":"Münchener Verein",
                       "url":"44164OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Nürnberger",
                       "titel":"Nürnberger",
                       "url":"44170OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Signal Iduna",
                       "titel":"Signal Iduna",
                       "url":"44165OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"uniVersa",
                       "titel":"uniVersa",
                       "url":"44167OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Württembergische",
                       "titel":"Württembergische",
                       "url":"40142OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    }
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":0,
                 "typ":1,
                 "label":"Vorsorge",
                 "titel":"Vorsorge",
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":true,
                 "eintraege":[
                    {
                       "id":0,
                       "typ":1,
                       "label":"Allianz",
                       "titel":"Allianz",
                       "url":"30013OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Alte Leipziger",
                       "titel":"Alte Leipziger",
                       "url":"30130OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"AXA / DBV",
                       "titel":"AXA / DBV",
                       "url":"30017OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Barmenia",
                       "titel":"Barmenia",
                       "url":"30105OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Basler",
                       "titel":"Basler",
                       "url":"30131OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Canada Life",
                       "titel":"Canada Life",
                       "url":"30146OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Continentale",
                       "titel":"Continentale",
                       "url":"41028OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"die Bayerische",
                       "titel":"die Bayerische",
                       "url":"40105OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"die Dortmunder",
                       "titel":"die Dortmunder",
                       "url":"30133OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Gothaer",
                       "titel":"Gothaer",
                       "url":"31021OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"HanseMerkur",
                       "titel":"HanseMerkur",
                       "url":"44422OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"HDI",
                       "titel":"HDI",
                       "url":"30026OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Helvetia",
                       "titel":"Helvetia",
                       "url":"30071OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"InterRisk",
                       "titel":"InterRisk",
                       "url":"31115OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"LV 1871",
                       "titel":"LV 1871",
                       "url":"30138OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"neue Leben",
                       "titel":"neue Leben",
                       "url":"30016OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Nürnberger",
                       "titel":"Nürnberger",
                       "url":"30136OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Signal Iduna",
                       "titel":"Signal Iduna",
                       "url":"30023OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Standard Life",
                       "titel":"Standard Life",
                       "url":"30134OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Stuttgarter",
                       "titel":"Stuttgarter",
                       "url":"30037OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Swiss Life",
                       "titel":"Swiss Life",
                       "url":"30015OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Volkswohl Bund",
                       "titel":"Volkswohl Bund",
                       "url":"30133OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Württembergische",
                       "titel":"Württembergische",
                       "url":"30103OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"WWK",
                       "titel":"WWK",
                       "url":"30021OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":1,
                       "label":"Zurich Life",
                       "titel":"Zurich Life",
                       "url":"30143OpenPortal",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":true,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    }
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              }
           ],
           "postData":null,
           "postHeader":null,
           "postEncoding":null,
           "kundenId":null,
           "toolDaten":null,
           "istPdf":0,
           "kundendatenAnfordern":0,
           "disclaimer":null,
           "internal":false
        },
        {
           "id":0,
           "typ":2,
           "label":"Bedarfsrechner",
           "titel":null,
           "url":null,
           "sortierPosition":110,
           "callbackStatus":0,
           "infoLinkText":null,
           "infoLinkUri":null,
           "deepLink":null,
           "sortiereEintraege":false,
           "eintraege":[
              {
                 "id":27,
                 "typ":2,
                 "label":"BU",
                 "titel":"Externer Service",
                 "url":"https://www.vertriebstools.de/webservices2/bu/toolmakler/bu.php?id=343123",
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":30,
                 "typ":2,
                 "label":"Kreditabsicherungsrechner",
                 "titel":"Externer Service",
                 "url":"https://www.vertriebstools.de/webservices2/finanz/toolmakler/finanz.php?id=343123",
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":31,
                 "typ":2,
                 "label":"Pflege",
                 "titel":"Externer Service",
                 "url":"https://www.ideal-versicherung.de/Onlineservice/Analyse/IPA?vermittler=11310_Allgemein&extern=&variante=default",
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":29,
                 "typ":2,
                 "label":"Rentenabsicherungsrechner",
                 "titel":"Externer Service",
                 "url":"https://www.vertriebstools.de/webservices2/airbagneu/toolmakler/air.php?id=343123",
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":28,
                 "typ":2,
                 "label":"RLV Rechner",
                 "titel":"Externer Service",
                 "url":"https://www.vertriebstools.de/webservices2/hr/toolmakler/hr.php?id=343123",
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              }
           ],
           "postData":null,
           "postHeader":null,
           "postEncoding":null,
           "kundenId":null,
           "toolDaten":null,
           "istPdf":0,
           "kundendatenAnfordern":0,
           "disclaimer":null,
           "internal":false
        },
        {
           "id":0,
           "typ":2,
           "label":"Weitere Services",
           "titel":null,
           "url":null,
           "sortierPosition":120,
           "callbackStatus":0,
           "infoLinkText":null,
           "infoLinkUri":null,
           "deepLink":null,
           "sortiereEintraege":true,
           "eintraege":[
              {
                 "id":10,
                 "typ":2,
                 "label":"BCA-Generationenvertrag",
                 "titel":"Externer Service",
                 "url":"https://www.resultate-institut.de/bca-generationenvertrag-makler-nachfolge-in-perfektion/",
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":11,
                 "typ":2,
                 "label":"Bestandsübertragung Handbuch [PDF]",
                 "titel":"Bestandsübertragung Handbuch",
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":1,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":53,
                 "typ":2,
                 "label":"Gebäudewertermittlung Skendata",
                 "titel":"Gebäudewertermittlung Skendata",
                 "url":"https://www.wert14.de/e0a4122e-092e-49a7-9fb2-fbe38440e6b0 ",
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":18,
                 "typ":2,
                 "label":"Infinma [PDF]",
                 "titel":"Infinma",
                 "url":"https://www.mybca.de/sites/bca_neu/files/media/Businessplus/Bestellschein_infinma_Online-Tools_BCA.pdf",
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":2,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":5,
                 "typ":2,
                 "label":"Juvato [PDF]",
                 "titel":"Juvato",
                 "url":"https://www.mybca.de/sites/bca_neu/files/JUVATO.pdf",
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":2,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":14,
                 "typ":2,
                 "label":"Marketing plus",
                 "titel":"Marketing plus",
                 "url":"",
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":12,
                 "typ":2,
                 "label":"myScore",
                 "titel":"Externer Service",
                 "url":"http://auswahltool.stuttgarter.de",
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":49,
                 "typ":2,
                 "label":"Telefonaufzeichnung",
                 "titel":"Telefonaufzeichnung",
                 "url":"https://www.bca.de/telefonaufzeichnung/",
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":51,
                 "typ":2,
                 "label":"Versicherung Infothek",
                 "titel":null,
                 "url":null,
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    {
                       "id":0,
                       "typ":2,
                       "label":"Gewerbe-Matrix",
                       "titel":"Gewerbe-Matrix",
                       "url":"https://www.bca.de/produktmatrix-gewerbe/",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":false,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":2,
                       "label":"Kfz-Matrix",
                       "titel":"Kfz-Matrix",
                       "url":"https://www.bca.de/produktmatrix-kfz/",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":false,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    },
                    {
                       "id":0,
                       "typ":2,
                       "label":"SHU-Research",
                       "titel":"SHU-Research",
                       "url":"https://www.bca.de/produktmatrix-sach/",
                       "sortierPosition":100,
                       "callbackStatus":0,
                       "infoLinkText":null,
                       "infoLinkUri":null,
                       "deepLink":null,
                       "sortiereEintraege":false,
                       "eintraege":[
                          
                       ],
                       "postData":null,
                       "postHeader":null,
                       "postEncoding":null,
                       "kundenId":null,
                       "toolDaten":null,
                       "istPdf":0,
                       "kundendatenAnfordern":0,
                       "disclaimer":null,
                       "internal":false
                    }
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":0,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              },
              {
                 "id":13,
                 "typ":2,
                 "label":"VSH-Rechner [PDF]",
                 "titel":"VSH-Rechner",
                 "url":"https://www.mybca.de/sites/bca_neu/files/media/Downloads/VSH/BCA-VSH_Selbstrechnender_Antrag.PDF",
                 "sortierPosition":100,
                 "callbackStatus":0,
                 "infoLinkText":null,
                 "infoLinkUri":null,
                 "deepLink":null,
                 "sortiereEintraege":false,
                 "eintraege":[
                    
                 ],
                 "postData":null,
                 "postHeader":null,
                 "postEncoding":null,
                 "kundenId":null,
                 "toolDaten":null,
                 "istPdf":2,
                 "kundendatenAnfordern":0,
                 "disclaimer":null,
                 "internal":false
              }
           ],
           "postData":null,
           "postHeader":null,
           "postEncoding":null,
           "kundenId":null,
           "toolDaten":null,
           "istPdf":0,
           "kundendatenAnfordern":0,
           "disclaimer":null,
           "internal":false
        }
     ],
     "postData":null,
     "postHeader":null,
     "postEncoding":null,
     "kundenId":null,
     "toolDaten":null,
     "istPdf":0,
     "kundendatenAnfordern":0,
     "disclaimer":null,
     "internal":false
  }
]

const GET_URL_MOCK = {
   "id":15,
   "typ":2,
   "label":"Allgemeiner Einstieg",
   "titel":"DIVA Investment",
   //"url":"https://bca.depotplattform.de/app.php/redirect/vertriebspartner_index?SSOTOKEN=eaa901e8-88c4-4d07-9066-b60d25029602-WC518395",
   "sortierPosition":100,
   "message": 'essfsfsdf sdf sdfs fsdf s',
   "callbackStatus":0,
   "infoLinkText":null,
   "infoLinkUri":null,
   "deepLink":null,
   "sortiereEintraege":false,
   "eintraege":[
      
   ],
   "postData":null,
   "postHeader":null,
   "postEncoding":null,
   "kundenId":null,
   "toolDaten":null,
   "istPdf":0,
   "kundendatenAnfordern":0,
   "disclaimer":null,
   "internal":false
}

function getCookie(name) {

  var matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ))
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export class DivaManager {

  constructor(origin) {
    this.origin = origin;
    this.GET_SIDEBAR_CONFIGURATION_URL = '/Sidebar/Laden';
    this.POST_SIDEBAR_PREPARING_URL = '/Sidebar/Starten';
    this.LOGOUT_URL = '/#/Logout'
  }

  buildUrl(url) {
    return `${this.origin}${url}`
  }

  async getSidebarConfiguration() {

    
   let url = this.buildUrl(this.GET_SIDEBAR_CONFIGURATION_URL);
   try {
      let response = await fetch(url, {credentials: 'include'})

      return response.json()
   } catch (error) {
      console.error(error)
   }

   return []

    

   //return SIDEBAR_CONF_MOCK
  }

  async getRouteUrl(route) {

    let url = this.buildUrl(this.POST_SIDEBAR_PREPARING_URL)

    return this._sendPost(url, JSON.stringify(route))
  }

 buildLogoutUrl() {
    return this.buildUrl(this.LOGOUT_URL);
  }

  async _sendPost(url, body) {
     let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': getCookie('XSRF-TOKEN')
    }

    let response = await fetch(url, {
      method: 'POST',
      headers,
      credentials: 'include',
      body: body
    })

    return response.json()
  }

   /*let response = GET_URL_MOCK

      return response
   }*/

}


export class DivaHelper {

  constructor() {
    this.routeChildrenKey = 'eintraege'
  }

  /**
   * Build array of routes from tree
   * 
   * @param {object[]} divaRoutesTree 
   */
  prepareListOfRoutes(divaRoutesTree) {

    let routes = []

    divaRoutesTree.forEach(route => {
      routes.push(route)

      if (route.hasOwnProperty(this.routeChildrenKey) && route[this.routeChildrenKey]) {
        routes = routes.concat(this.prepareListOfRoutes(route[this.routeChildrenKey]))
      }
    })

    return routes

  }

}