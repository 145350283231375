import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  Select,
  MenuItem,
  FormControl,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  getColorByValue,
  openMorningStartIsin,
  withEuroOrDash,
  withPercentOrDash,
  openFundShopInfosIsin,
  formatQty,
  formatDateOrDash,
} from '../../../utils/utils';
import {
  DepotSubNumberCell,
  IsinCell, SustainabilityCell,
} from '../../../utils/commonTableColumns';
import { toGermanFormat } from '../../../utils/numberFormater';
import WarningTooltip from '../../WarningTooltip';
import { getHostRelatedSetting } from '../../../utils/sharedSettings';
import { HOST_RELATED_SHARED_SETTINGS_KEYS, NOT_FOUND } from '../../SharedSettingsProvider/constants';
import { InstrumentNameCell, SRRIColumn } from '../../../containers/Modelportfolios/components/InstrumentsList/table-data';
import {
  useAlertConfigurationModalContext,
} from '../../../containers/RiskDashboard/components/AlertConfigurationModal/AlertConfigurationModal';
import {
  ALERT_PRODUCT_TYPE,
} from '../../../containers/RiskDashboard/components/AlertConfigurationModal/utils';
import AlarmButton from '../../Buttons/AlarmButton/AlarmButton';
import VirtualPortfolioActionsManager from '../../../containers/VirtualPortfolioManager/ActionButtons';
import { getDisabledTradingOptions } from '../../../containers/RiskProfiling/utils';
import InputFormElement, {
  CurrencyNumberFormat,
  PercentageNumberFormat,
} from '../../../containers/RiskProfiling/components/StepContent/components/formElement/InputFormElement/InputFormElement';
import { getAssetInternalId } from '../../../containers/CustomerDashboard/utils';
import { getRiskMetricsValue } from './utlis';
import {
  TRADING_ACTION_SELL,
  TRADING_ACTION_SWITCH,
  TRADING_ACTIONS,
} from './constants';
import {
  useTradingActionsStyles,
  useTooltipStyles,
  useVirtualTableStyles,
  useRebalancingTableStyles,
} from './styles';

const getRebalancingWeightHeader = (data) => {
  const classes = useRebalancingTableStyles()
  return (
    <div className={classes.headerContainer}>
      <div className={classes.dataContainer}>
        <span className={classes.name}>{data.name}</span>
        <span>{data.categoryName}</span>
      </div>
      <span>Gewichtung</span>
    </div>
  )
}

const RebalancingDifferenceComponent = (props) => {
  const {
    item,
    value,
    displayTransactionType,
    renderInput
  } = props

  let style = {};
  if (item.transactionType === 'Verkauf') {
    style.color = '#F44638';
    style.fontFamily = 'Roboto-Bold';
  } else if (item.transactionType === 'Kauf') {
    style.color = '#5EC14E';
    style.fontFamily = 'Roboto-Bold';
  }
  return (
    <div style={style}>
      {renderInput ? renderInput() : (
        <span>{item.transactionType === 'Verkauf' && '-'}{value}</span>
      )}
      {displayTransactionType && (
        <>
          <br/>
          <span>{item.transactionType || ''}</span>
        </>
      )}
    </div>
  )
}

const getAssetQuantity = (quantity) => {
  try {

    let quantityStr = quantity && quantity.toString();
    let quanityParts = quantityStr.split('.');

    if (quanityParts.length > 1) {
      let decimals = quanityParts[1];

      let decimalsWithoutZero = decimals.replace(/^0+/, '');

      if (decimalsWithoutZero.length !== decimals.length) {

        let decimalPlaces = decimals.length - decimalsWithoutZero.length + 1;

        return toGermanFormat(quantity, '', '', decimalPlaces);
      }
    }
  } catch (err) {
    return toGermanFormat(quantity);
  }
  return toGermanFormat(quantity);
}

const TradingActions = (props) => {
  const {
    isModelportfolio,
    item,
    onChange,
    tradings,
    availableAssetClasses,
    custodiansInstrumentsData,
    tradeTransactions,
    disabled,
    brokerLicense,
    isVirtual
  } = props

  const classes = useTradingActionsStyles()

  const handleTradingOptionChange = (event) => {
    if (onChange) {
      onChange(item, event.target.value)
    }
  }

  const tradingOption = _.find(tradings, instrument => instrument.instrumentId == getAssetInternalId(item))
  // tradingType info from existing trade session
  let value = _.get((tradeTransactions || {}), getAssetInternalId(item)) || (tradingOption && tradingOption.tradingType) || "default";
  let disabledTradingOptions, getFilteredTradingActions;
  if (isVirtual){
    // for virtual portfolios banks are not selected -> no banks data -> there are no disabled options
    disabledTradingOptions = [];
    // switch out/sell allowed ONLY if qty > 0
    if(item.quantity <= 0) {
      disabledTradingOptions.push(TRADING_ACTION_SWITCH);
      disabledTradingOptions.push(TRADING_ACTION_SELL);
    }
    getFilteredTradingActions = (option) => option.isTrading;
  } else {
    disabledTradingOptions = getDisabledTradingOptions(item, custodiansInstrumentsData, availableAssetClasses, brokerLicense);
    getFilteredTradingActions = (option) => option.isTrading;
  }

  return (
    <div className="tradingCell">
      {isModelportfolio ? (
        <span style={{fontFamily: 'Roboto-Regular', color: '#999D9F', fontSize: 14, fontStyle: 'italic'}}>Nur für das Portfolio</span>
      ) : (
        <>
          {!!item.isin && (
            <FormControl style={{width: '100%'}}  className={classes.selectRoot}>
              <Select
                classes={{
                  root: classes.selectRoot,
                  selectMenu: classes.selectMenu,
                  icon: classes.selectMenuIcon
                }}
                className={classes.selectRoot}
                MenuProps={{
                  classes: {
                    paper: classes.selectMenuPaper,
                  }
                }}
                onChange={handleTradingOptionChange}
                value={value}
                IconComponent={ExpandMoreIcon}
                disabled={disabled || item.wholeDepotOrder || false}
              >
                <MenuItem
                classes={{
                  root: classes.selectMenuItemRoot
                }} value="default">-</MenuItem>
                {TRADING_ACTIONS.filter(option => getFilteredTradingActions(option)).map(option => (
                  <MenuItem key={option.value} disabled={disabledTradingOptions.includes(option.value)} value={option.value} classes={{
                    root: classes.selectMenuItemRoot
                  }}> { option.text } </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      )}
    </div>
  )
}

export const RowNumberCell = {
  header: 'Nr.',
  body: {
    content: (item, options, index) => item.isSubItem ? '' : index + 1 ? `${index + 1}.` : '',
    className: 'bold'
  },
  key: 'RowNumberCell'
};


const RowNumberCellWithFooterText = {
  ...RowNumberCell,
  footer: {
    content: (item, options) => {
      const footerText = _.get(options, 'footerData.text');
      if (!footerText) {
        return false;
      }

      if (['xs'].includes(options.width)) {
        return <WarningTooltip title={footerText}/>
      }

      return footerText
    }
  },
  colSpan: {footer: {default: 6, xs: 1}},
};

export const ProductNameCell = {
  header: 'Produktname',
  body: {
    content: (item) => item.isSubItem ? item.depot_sub_number : item.name,
    onClick: (item, options, link) => item.isSubItem ? null : (!item.name || (item.name && item.name != 'Vollständig verkaufte Vermögenswerte')) && openInstrumentInfo(item.isin, link),
    className: 'name bold link',
    ellipsis: true
  },
  key: 'ProductNameCell'
}

const openInstrumentInfo = (isin, link) => {
  if(link){
    openFundShopInfosIsin(isin, link)
  } else {
    openMorningStartIsin(isin)
  }
};

export const MorningstarCategoryCell = {
  header: 'Morningstar-Kategorie',
  body: {
    content: (item) => item.isSubItem ? '' : item.ms_category
  },
  key: 'CategoryCell'
}

const getSharedSettings = (state) => state.get('sharedSettings')

const getSharedSettingsSelector = createSelector(
  [getSharedSettings],
  (sharedSettings) => sharedSettings.toJS()
)


const mapStateToProps = (state) => ({
  sharedSettings: getSharedSettingsSelector(state)
});

const QuantityCellContent = connect(mapStateToProps)((props) => {
  const {
    item,
    sharedSettings
  } = props;

  return (
    <>
      {item.error && <WarningTooltip title={getHostRelatedSetting(sharedSettings.data, HOST_RELATED_SHARED_SETTINGS_KEYS.INSTRUMENT_TABLE_ERROR_MESSAGE_TEXT)}/>}
      {item.quantity || item.quantity === 0 ? getAssetQuantity(item.quantity) : '-'}
    </>
  )
});

const QuantityCell = {
  header: 'Stückzahl',
  body: {
    content: (item) => <QuantityCellContent item={item}/>
  },
  key: 'QuantityCell'
}

const PriceCell = {
  header: 'Kurs',
  body: {
    content: (item) => withEuroOrDash(item.price_eur)
  },
  key: 'PriceCell'
}

const DateCell = {
  header: 'Standdatum',
  body: {
    content: (item) => formatDateOrDash(item.price_date)
  },
  key: 'DateCell'
}

const ReturnPACellContent = connect(mapStateToProps)((props) => {
  const {
    item,
    sharedSettings
  } = props;

  const classes = useTooltipStyles();
  if (item.pa_warning) {
    return (
      <span className={classes.warningWrapper}>
        -
        <div className={classes.warningAlignment}>
        <WarningTooltip title={getHostRelatedSetting(sharedSettings.data, item.pa_warning)}/>
        </div>
      </span>
    )
  }

  if (item.profit_loss_percentage_pa || item.profit_loss_percentage_pa == 0) {
    return withPercentOrDash(item.profit_loss_percentage_pa, true);
  }

  if (!item.isin) {
    return (
      <span style={{padding: '8px 0 8px 16px'}}>
        -
      </span>
    )
  }

  return null

});

const HoldingPeriodCell = {
  header: 'Haltedauer',
  body: {
    content: (item) => (
      <span>
        {item.holding_period_days ? `${item.holding_period_days} d` : '-'}
      </span>
    )
  },
  key: 'HoldingPeriodCell'
}

const ReturnPACell = {

  header: 'p.a.',
  body: {
    content: (item) => {
      return (
        <span style={{color: getColorByValue(item.profit_loss_percentage_pa)}}>
          <ReturnPACellContent item={item} />
        </span>
      )
    }
  },
  key: 'ReturnPACell'
}

const MarketValueCell = {
  header: 'Wert',
  body: {
    content: (item) => withEuroOrDash(item.market_value)
  },
  footer: {
    content: (item, options) => withEuroOrDash(options.footerData.market_value)
  },
  key: 'MarketValueCell'
}

const MarketValueDifferenceCell = {
  header: '',
  body: {
    content: (item, options) => {
      const classes = useRebalancingInputsClasses()

      const itemChecked = !!(options.selected && options.selected.find(asset => asset.isin == item.isin))

      const valueInternal = React.useRef(item.newMarketValue)

      const onChange = (value) => {
        valueInternal.current = Math.abs(value)
      }

      const onBlur = () => options.onMarketValueChange && options.onMarketValueChange(item, valueInternal.current)

      return (
        <RebalancingDifferenceComponent
          item={item}
          value={withEuroOrDash(item.newMarketValue)}
          displayTransactionType
          renderInput={itemChecked && (() => (
            <InputFormElement
              value={valueInternal.current}
              inputComponent={CurrencyNumberFormat}
              inputProps={{inputProps: {allowNegative: false, prefix: item.transactionType === 'Verkauf' ? '-' : ''}}}
              custom_classes={classes}
              onChange={onChange}
              onBlur={onBlur}
            />
          ))}
        />
      )
    }
  },
  key: 'MarketValueDifferenceCell'
}

const StartValueCellHeader = connect(mapStateToProps)((props) => {
  const classes = useTooltipStyles()
  const {
    sharedSettings
  } = props;

  return(
      <span className={classes.warningWrapper}>
        {'Anfangsbestand'}
        <div className={classes.warningAlignment}>
          <WarningTooltip title={getHostRelatedSetting(sharedSettings.data, HOST_RELATED_SHARED_SETTINGS_KEYS.START_VALUE_EXPLANATION) || NOT_FOUND}/>
        </div>
      </span>
    )
});

const BeginningValueCell = {
  header: {content: <StartValueCellHeader />},
  body: {
    content: (item) => withEuroOrDash(item.value_start)
  },
  footer: {
    content: (item, options) => {
      return withEuroOrDash(options.footerData.profit_value_start)
    }
  },
  key: 'BeginningValueCell'
}

const ReturnInvestedAmountCellHeader = connect(mapStateToProps)((props) => {
  const classes = useTooltipStyles()
  const {
    sharedSettings,
    options
  } = props;

  const tooltipKey = _.get(options, 'trans_saldo_explanation');

  return(
    <span className={classes.warningWrapper}>
      {'Kapitaleinsatz'}
      {!!tooltipKey &&
        <div className={classes.warningAlignment}>
          <WarningTooltip
            color={tooltipKey === 'TRANS_SALDO_WITH_BOOKINGS_EXPLANATION' ? '#c22c12': null}
            title={getHostRelatedSetting(sharedSettings.data,
            HOST_RELATED_SHARED_SETTINGS_KEYS[tooltipKey]) || NOT_FOUND}
          />
        </div>
      }
    </span>
  )
});

const InvestedAmountCell = {
  header: {
    content: (item, options) => {
      return <ReturnInvestedAmountCellHeader options={options.headerData} />
    }
  },
  body: {
    content: (item) => withEuroOrDash(item.invested_amount)
  },
  footer: {
    content: (item, options) => withEuroOrDash(options.footerData.invested_amount)
  },
  key: 'InvestedAmountCell'
}

const ReturnEuroCellExplanation = connect(mapStateToProps)((props) => {
  const {
    sharedSettings,
    valueToReplace,
    item,
  } = props;
  return (
    <WarningTooltip
      color={item && item.guv_bank_sell_some_quantity_explanation ? '#c22c12': null}
      title={
        <>
          {getHostRelatedSetting(sharedSettings.data, HOST_RELATED_SHARED_SETTINGS_KEYS.GuV_VALUE_EXPLANATION,{
            "{{start_value}}": valueToReplace
          }) || NOT_FOUND}

          {item && item.guv_bank_sell_some_quantity_explanation &&
            <>
              <br/>
              {getHostRelatedSetting(sharedSettings.data,
                HOST_RELATED_SHARED_SETTINGS_KEYS[item.guv_bank_sell_some_quantity_explanation] || NOT_FOUND)}
            </>
          }
        </>
      }
    />
  )
});


const ReturnEuroCell = {
  header: 'GuV (€)',
  body: {
    content: (item) => (
      <span style={{color: getColorByValue(item.profit_loss_eur)}}>
        {item.profit_loss_eur > 0 ? '+' : ''}{withEuroOrDash(item.profit_loss_eur)}
      </span>
    )
  },
  footer: {
    content: (item, options) => (
      <span style={{color: getColorByValue(options.footerData.profit_loss_eur)}}>
        {options.footerData.profit_loss_eur > 0 ? '+' : ''}{withEuroOrDash(options.footerData.profit_loss_eur)}
      </span>
    )
  },
  key: 'ReturnCellEuro'
}

const ReturnPercentageCell = {
  header: 'GuV (%)',
  body: {
    content: (item) => {
      const classes = useTooltipStyles()
      let value = formatQty(item.profit_loss_eur / item.profit_loss_perc, 2);

      return (
        <span style={{color: getColorByValue(item.profit_loss_perc)}} className={classes.warningWrapper}>
            {item.profit_loss_perc > 0 ? '+' : ''}{withPercentOrDash(item.profit_loss_perc, true)}
          <div className={classes.warningAlignment}>
            <ReturnEuroCellExplanation item={item} valueToReplace={value}/>
          </div>
        </span>
    )}
  },
  footer: {
    content: (item, options) => {
      const classes = useTooltipStyles()
      let value = formatQty(options.footerData.profit_loss_eur / options.footerData.profit_loss_perc, 2);

      return (
        <span  style={{color: getColorByValue(options.footerData.profit_loss_perc)}} className={classes.warningWrapper}>
          {options.footerData.profit_loss_perc > 0 ? '+' : ''}{withPercentOrDash(options.footerData.profit_loss_perc, true)}
          <div className={classes.warningAlignment}>
            <ReturnEuroCellExplanation valueToReplace={value}/>
          </div>
        </span>
    )}
  },
  key: 'ReturnCellPercentage'
}

const ReturnPercentagePACellContent = connect(mapStateToProps)((props) => {
  const {
    item,
    options,
    sharedSettings
  } = props;

  const classes = useTooltipStyles();
  return(
    <>
      {options.footerData.portfolio_pa_warning
        ? (
          <span className={classes.warningWrapper}>
            -
            <div className={classes.warningAlignment}>
              <WarningTooltip title={getHostRelatedSetting(sharedSettings.data, options.footerData.portfolio_pa_warning) || NOT_FOUND}/>
            </div>
          </span>
        )
        : <span style={{color: getColorByValue(options.footerData.profit_loss_percentage_pa)}} className={'percentagePA'}>
            {options.footerData.profit_loss_percentage_pa > 0 ? '+' : ''}{withPercentOrDash(options.footerData.profit_loss_percentage_pa || 0, true)}
          </span>
      }
    </>
  )
})

const ReturnPercentagePACell = {
  footer: {
    content: (item, options) => <ReturnPercentagePACellContent item={item} options={options} />
  },
  key: 'ReturnCellPercentagePA',
  expandedItemKey: 'ReturnPACell' // key to show/hide cell depending on custom report config
}

const WeightCell = {
  header: 'Gewichtung',
  body: {
    content: (item) => withPercentOrDash(item.weight, true)
  },
  key: 'WeightCell'
}

const PortfolioWeightCell = {
  header: {
    content: (item, options) => getRebalancingWeightHeader(options.portfolioData)
  },
  body: {
    content: (item) => withPercentOrDash(item.pWeight, true)
  },
  key: 'PortfolioWeightCell'
}

const ModelPortfolioWeightCell = {
  header: {
    content: (item, options) => getRebalancingWeightHeader(options.modelPortfolioData)
  },
  body: {
    content: (item) => withPercentOrDash(item.mpWeight, true)
  },
  key: 'ModelPortfolioWeightCell'
}

const useRebalancingInputsClasses = makeStyles(() => ({
  inputRoot: {
    height: 25,
    marginBottom: 5,
    fontSize: '0.875rem'
  }
}))

const WeightDifferenceCell = {
  header: 'Differenz',
  body: {
    content: (item, options) => {

      const classes = useRebalancingInputsClasses()

      const itemChecked = !!(options.selected && options.selected.find(asset => asset.isin == item.isin))

      const valueInternal = React.useRef((item.weightDifference || 0) * 100)

      const onChange = (value) => {
        valueInternal.current = Math.abs(value)
      }

      const onBlur = () => options.onWeightDifferenceChange && options.onWeightDifferenceChange(item, valueInternal.current && valueInternal.current / 100)

      return (
        <RebalancingDifferenceComponent
          item={item}
          value={withPercentOrDash(item.weightDifference)}
          renderInput={itemChecked && (() => (
            <InputFormElement
              value={valueInternal.current}
              inputComponent={PercentageNumberFormat}
              inputProps={{inputProps: {allowNegative: false, prefix: item.transactionType === 'Verkauf' ? '-' : ''}}}
              custom_classes={classes}
              onChange={onChange}
              onBlur={onBlur}
            />
          ))}
        />
      )
    }
  },
  key: 'WeightDifferenceCell'
}

const TradingActionsCell = {
  header: 'Order',
  body: {
    content: (item, options) => {

      let { trading: {
        subDepotTradeEnabled,
        isModelportfolio,
        onAddTradingOption,
        portfolio,
        custodiansInstrumentsData,
        loading,
        tradeTransactions,
        disabled,
        bankMapping,
        brokerLicense,
        customerSrri
      }} = options;

      let isDisabled = disabled;
      if (!subDepotTradeEnabled) {
        // if sub depot trading is not enabled - do not render trading select for sub depots
        if (item.isSubItem){ return null; }
      } else if (!_.isEmpty(item.component_sub_depots || [])){
        // if sub depot trading is enabled - do not render select for item with sub depots
        return null;
      }

      if(loading){
        return <Skeleton />
      }

      return (
        <TradingActions
          isModelportfolio={isModelportfolio}
          item={item}
          onChange={onAddTradingOption}
          value={undefined}
          custodiansInstrumentsData={custodiansInstrumentsData}
          tradings={portfolio ? portfolio.instruments : []}
          availableAssetClasses={bankMapping ? bankMapping.available_asset_classes : {}}
          tradeTransactions={tradeTransactions}
          disabled={isDisabled}
          brokerLicense={brokerLicense}
          customerSrri={customerSrri}
          isVirtual={options.isVirtual}
        />
      )
    }
  },
  key: 'TradingActionsCell'
}

const Volatility1y = {
  header: {
    content: () => (<span style={{whiteSpace: 'nowrap'}}>Volatilität<br />1 Jahr</span>)
  },
  body: {
    content: (item, options) => getRiskMetricsValue(item, options, '1y')
  }
}

const Volatility3y = {
  header: {
    content: () => (<span>Volatilität<br />3 Jahre</span>)
  },
  body: {
    content: (item, options) => getRiskMetricsValue(item, options, '3y')
  }
}

const Volatility5y = {
  header: {
    content: () => (<span>Volatilität<br />5 Jahre</span>)
  },
  body: {
    content: (item, options) => getRiskMetricsValue(item, options, '5y')
  }
}

const MWVColumn = {
  header: {
    content: () => (<span>Maximaler Wertverlust</span>)
  },
  body: {
    content: (item, options) => getRiskMetricsValue(item, options, 'max', 'maximum_drawdown')
  }
}

const ActionsColumn = {
  header: {
    content: (item, options) => (_.get(options, 'trading.isVirtualOrderEnabled') ? 'Order' : '')
  },
  body: {
    content: (item, options) => {
      const classes = useVirtualTableStyles();
      return (
        <VirtualPortfolioActionsManager
          resource={options.resource}
          asset={item}
          classes={classes}
          portfolio_id={options.virtual.portfolioId}
          customer_id={options.customer_id}
          refresh={options.virtual.refresh}
          numberOfAssets={options.virtual.numberOfAssets}
          tradingActionsComponent={_.get(options, 'trading.isVirtualOrderEnabled') && TradingActionsCell.body.content(item, {...options, isVirtual: true})}
        />
      )}
  },
  key: 'ActionsColumn'
}

export const NOTIFICATION_TURNED_ON_ICON = 'fa fa-bell'
export const NOTIFICATION_TURNED_OFF_ICON = 'fa fa-bell-slash-o'


const AlarmColumn = {
  header: {
    content: () => (<span>ALARM</span>)
  },
  body: {
    content: (item, options) => {

      const {onSelectAsset} = useAlertConfigurationModalContext()

      const handleButtonClick = () => {
        onSelectAsset && onSelectAsset(
          {...item, depot_number: options.portfolio.number}, ALERT_PRODUCT_TYPE.ASSET, options.portfolio)
      }

      const alarmExists = !_.isEmpty(item.alerting_configuration)

      return (
        <AlarmButton
          alarmExists={alarmExists}
          alarmActive={alarmExists && _.find(item.alerting_configuration, (configuration) => configuration.active)}
          onClick={handleButtonClick}
        />
      )
    }
  }
}

export const alertsCustomersOverviewTableStructure = {
  default: [
    {
      content: [RowNumberCell]
    },
    {
      content: [ProductNameCell, IsinCell]
    },
    {
      content: [AlarmColumn]
    }
  ]
}

const tableStructure = {
  default: [
    {
      content: [RowNumberCellWithFooterText],
    },
    {
      content: [ProductNameCell, IsinCell, DepotSubNumberCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell, WeightCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell, HoldingPeriodCell],
      align: 'right'
    },
    {
      content: [MarketValueCell, InvestedAmountCell, BeginningValueCell],
      align: 'right'
    },
    {
      content: [ReturnPercentageCell, ReturnEuroCell, ReturnPACell, ReturnPercentagePACell],
      align: 'right'
    },
  ],
  xs: [
    {
      content: [RowNumberCellWithFooterText],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    },
    {
      content: [ReturnEuroCell, ReturnPercentageCell, ReturnPACell, ReturnPercentagePACell],
      align: 'right'
    },
  ]
}

export const instrumentsTableStructure = tableStructure;

export const riskIndicatorsTableStructure = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [Volatility1y]
    },
    {
      content: [Volatility3y]
    },
    {
      content: [Volatility5y]
    },
    {
      content: [MWVColumn]
    },
    {
      content: [QuantityCell, WeightCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell, HoldingPeriodCell],
      align: 'right'
    },
    {
      content: [MarketValueCell, InvestedAmountCell, BeginningValueCell],
      align: 'right'
    },
    {
      content: [ReturnPercentageCell, ReturnEuroCell],
      align: 'right'
    },
    {
      content: [AlarmColumn]
    }
  ],
  xs: [
    {
      content: [ProductNameCell, MarketValueCell]
    },
    {
      content: [ReturnEuroCell, ReturnPercentageCell],
      align: 'right'
    },
    {
      content: [AlarmColumn]
    }
  ]
}

export const tableStructureDepotstatus = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell, DepotSubNumberCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell, WeightCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell, HoldingPeriodCell],
      align: 'right'
    },
    {
      content: [MarketValueCell],
      align: 'right'
    },
  ],
  xs: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    },
  ]
}

export const tradingTableStructureDepotstatus = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell, DepotSubNumberCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell, WeightCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell, HoldingPeriodCell],
      align: 'right'
    },
    {
      content: [MarketValueCell],
      align: 'right'
    },
    {
      content: [TradingActionsCell],
      align: 'right'
    }
  ],
  xs: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    },
    {
      content: [TradingActionsCell],
      align: 'right'
    }
  ]
}

export const tableStructureTrading = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell, DepotSubNumberCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell, WeightCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell, HoldingPeriodCell],
      align: 'right'
    },
    {
      content: [MarketValueCell, InvestedAmountCell, BeginningValueCell],
      align: 'right'
    },
    {
      content: [ReturnPercentageCell, ReturnEuroCell, ReturnPACell, ReturnPercentagePACell],
      align: 'right'
    },
    {
      content: [TradingActionsCell],
      align: 'right'
    }
  ],
  xs: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    },
    {
      content: [TradingActionsCell],
      align: 'right'
    },
  ]
}

export const getTableStructureSwitch = (withSustainability) => {
  const structure = {
    default: [
      {
        content: [InstrumentNameCell]
      },
      {
        content: [MorningstarCategoryCell]
      },
      {
        content: [QuantityCell, PriceCell, DateCell],
        align: 'right'
      },
      {
        content: [MarketValueCell, InvestedAmountCell],
        align: 'right'
      },
      {
        content: [ReturnEuroCell, ReturnPercentageCell, ReturnPACell, ReturnPercentagePACell],
        align: 'right'
      },
      {
        content: [WeightCell],
        align: 'right'
      }
    ],
      xs: [
      {
        content: [InstrumentNameCell, MarketValueCell]
      },
      {
        content: [ReturnEuroCell, ReturnPercentageCell, ReturnPercentagePACell],
        align: 'right'
      },
    ]
  };

  if (withSustainability) {
    structure.default.splice(1, 0, {content: [SustainabilityCell], cellClassName: 'sustainability-cell'})
  }

  return structure;
};


export const tableStructureVirtual = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell, WeightCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell, HoldingPeriodCell],
      align: 'right'
    },
    {
      content: [MarketValueCell, InvestedAmountCell, BeginningValueCell],
      align: 'right'
    },
    {
      content: [ReturnPercentageCell, ReturnEuroCell, ReturnPACell, ReturnPercentagePACell],
      align: 'right'
    },
    {
      content: [ActionsColumn],
      align: 'right'
    },
  ],
  xs: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    },
    {
      content: [ActionsColumn],
      align: 'right'
    },
  ]
}

export const virtualTableStructureDepotstatus = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell, WeightCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell, HoldingPeriodCell],
      align: 'right'
    },
    {
      content: [MarketValueCell],
      align: 'right'
    },
    {
      content: [ActionsColumn],
      align: 'right'
    },
  ],
  xs: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    },
    {
      content: [ActionsColumn],
      align: 'right'
    }
  ]
}

export const getTableStructureRebalancing = (withSustainability) =>  {

  const sustainabilityCellConfig =  {
    content: [SustainabilityCell],
    cellClassName: 'sustainability-cell'
  };

  const structure = {
    default: [
      {
        content: [InstrumentNameCell]
      },
      {
        content: [MorningstarCategoryCell]
      },
      sustainabilityCellConfig,
      {
        content: [SRRIColumn],
        align: 'right'
      },
      {
        content: [PortfolioWeightCell, MarketValueCell],
        align: 'right',
        cellClassName: 'portfolio-weight'
      },
      {
        content: [ModelPortfolioWeightCell],
        align: 'right',
        cellClassName: 'mp-weight'
      },
      {
        content: [WeightDifferenceCell, MarketValueDifferenceCell],
        align: 'right',
        cellClassName: 'difference'
      }
    ],
      md: [
      {
        content: [InstrumentNameCell]
      },
      {
        content: [PortfolioWeightCell, MarketValueCell],
        align: 'right',
        cellClassName: 'portfolio-weight'
      },
      {
        content: [ModelPortfolioWeightCell],
        align: 'right',
        cellClassName: 'mp-weight'
      },
      {
        content: [WeightDifferenceCell, MarketValueDifferenceCell],
        align: 'right',
        cellClassName: 'difference'
      }
    ],
      sm: [
      {
        content: [InstrumentNameCell]
      },
      {
        content: [PortfolioWeightCell, MarketValueCell],
        align: 'right',
        cellClassName: 'portfolio-weight'
      },
      {
        content: [ModelPortfolioWeightCell],
        align: 'right',
        cellClassName: 'mp-weight'
      },
      {
        content: [WeightDifferenceCell, MarketValueDifferenceCell],
        align: 'right',
        cellClassName: 'difference'
      }
    ],
      xs: [
      {
        content: [RowNumberCell],
        cellClassName: 'row-number'
      },
      {
        content: [InstrumentNameCell, SRRIColumn]
      },
      sustainabilityCellConfig,
      {
        content: [WeightDifferenceCell, MarketValueDifferenceCell],
        align: 'right',
        cellClassName: 'difference'
      },
    ]
  }

  if (withSustainability) {
    structure.md.splice(1, 0, sustainabilityCellConfig);
    structure.sm.splice(1, 0, sustainabilityCellConfig);
  }

  return structure;
};

export const tableStructureGrouping = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell, DepotSubNumberCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell],
      align: 'right'
    },
    {
      content: [MarketValueCell],
      align: 'right'
    }
  ],
  xs: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    }
  ],
}

export const tableStructureGroupingByProduct = {
  default: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, IsinCell, DepotSubNumberCell]
    },
    {
      content: [MorningstarCategoryCell]
    },
    {
      content: [SRRIColumn],
      align: 'right'
    },
    {
      content: [QuantityCell],
      align: 'right'
    },
    {
      content: [PriceCell, DateCell],
      align: 'right'
    },
    {
      content: [MarketValueCell, InvestedAmountCell, BeginningValueCell],
      align: 'right'
    },
    {
      content: [ReturnPercentageCell, ReturnEuroCell, ReturnPACell, ReturnPercentagePACell],
      align: 'right'
    }
  ],
  xs: [
    {
      content: [RowNumberCell],
    },
    {
      content: [ProductNameCell, MarketValueCell]
    }
  ],
}

export default tableStructure;
