import { makeStyles, createStyles } from '@material-ui/core'
import images from "../../../../images";

export const styles = theme => createStyles({
  title: {
    fontSize: 20,
    fontFamily: "Roboto-Bold",
    marginBottom: '1em',
    '&:not(:first-child)': {
      marginTop: '2em',
    },
  },
  container: {
    fontFamily: "Roboto-Regular",
    borderRadius: 5,
    background: 'white',
    fontSize: 16,
    border: '1px solid #D8DCDF',
    padding: '35px 32px',
    height: '100%',
    '& ul': {
      listStyle: 'none',
      paddingLeft: 24,
      marginBottom: '1em',
      '& li:not(:last-child)': {
        marginBottom: 10,
      },
      '& li::before': {
        content: `"\\2022"`,
        color: '#ADB6BD',
        fontWeight: 'bold',
        display: 'inline-block',
        fontSize: 24,
        width: '1em',
        marginLeft: '-1em',
        lineHeight: '17px',
        verticalAlign: 'middle',
      }
    }
  },
  containerFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  bgDark: {
    position: 'relative',
    '&::before': {
      content: '""',
      width: 37,
      height: 42,
      position: 'absolute',
      top: -5,
      left: 40,
      backgroundImage: `url(${images.service_concept.service_concept_icon_medal})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    background: "#242D3C",
    color: "#FFFFFF",
    '& .header': {
      color: "#E3A600",
      fontSize: 24,
      fontFamily: "Roboto-Bold",
    },
    border: 'none',
    '& ul li::before': {
      color: '#E3A600',
    },
    '& .MuiButton-root': {
      boxShadow: '0px 0px 20px #0092E5',
      border: 'none',
      backgroundColor: '#fff',
    },
    '& .MuiButton-root:hover': {
      backgroundColor: '#e1f1f7'
    }
  },
  header: {
    fontFamily: "Roboto-Bold",
  },
  buttonBottom: {
    marginTop: 'auto'
  },
  btnRoot: {
    width: 216,
    height: 50,
    borderRadius: 8,
  }
});

export default makeStyles(styles)