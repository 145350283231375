import React from 'react';
import clsx from 'clsx';

import useStyles from './styles';


const PhoneIcon = (props) => {
  const classes = useStyles();

  return (
    <span className={clsx(classes.iconContainer, props.classes && props.classes.root)}>
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
        <defs>
          <clipPath id="a">
            <rect className="b" width="13" height="13"/>
          </clipPath>
          <clipPath id="b">
            <rect className="a" width="13" height="13"/>
          </clipPath>
        </defs>
        <g className="c">
          <g className="d" transform="translate(0)">
            <path className="a" d="M589.556,588.1l-1.983-1.977a1.036,1.036,0,0,0-1.458.028l-1,1-.2-.109a9.337,9.337,0,0,1-4.145-4.132c-.038-.066-.073-.13-.107-.192l.67-.667.33-.329a1.027,1.027,0,0,0,.027-1.453l-1.984-1.977a1.035,1.035,0,0,0-1.458.027l-.558.56.014.015a3.227,3.227,0,0,0-.46.809,3.321,3.321,0,0,0-.2.821c-.263,2.164.73,4.141,3.422,6.824,3.722,3.708,6.722,3.429,6.852,3.414a3.357,3.357,0,0,0,.826-.206,3.2,3.2,0,0,0,.809-.457l.012.011.567-.553a1.03,1.03,0,0,0,.026-1.454Zm0,0" transform="translate(-577.001 -578)"/>
          </g>
        </g>
      </svg>
    </span>
  );
};

export default PhoneIcon;