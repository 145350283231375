import React from 'react';
import _ from "lodash";
import Grid from '@material-ui/core/Grid';

import ChartSectionBordered, {ChartSectionPortfolioName} from "../ChartSectionBordered/ChartSectionBordered";
import {useRiskReturnAndCorrelationMatrixData} from "../../hooks/useRiskReturnAndCorrelationMatrixData";
import ChartRiskReturn from "../charts/RiskReturnChart";
import {useBasicPortfolioData} from "../../hooks/useBasicPortfolioData";
import {useExpandedItems} from "../../hooks/useExpandedItems";


const PortfolioRiskReturnChart = React.memo(({customerId, portfolio, dataProvider, onChartSettingsChange, expanded, onExpandedItemsChange, investmentStrategyId}) => {
  const {
    riskReturn,
    portfolioRiskReturn,
  } = useRiskReturnAndCorrelationMatrixData(customerId, portfolio, dataProvider, true, undefined, undefined, investmentStrategyId);
  const {
    basicPortfolioData,
  } = useBasicPortfolioData(customerId, portfolio, dataProvider);

  return (
    <Grid item xs={12}>
      <ChartSectionBordered
        dataId={`risk-analyse-risk-return-${portfolio.depotNumber}`}
        title={(
          <ChartSectionPortfolioName portfolio={portfolio} />
        )}
        loading={basicPortfolioData.basicPortfolioLoading || portfolioRiskReturn.portfolioRiskReturnLoading || riskReturn.riskReturnLoading}
        error={basicPortfolioData.basicPortfolioError || portfolioRiskReturn.portfolioRiskReturnError || riskReturn.riskReturnError}
        displayError
        expanded={expanded}
        isPortfolioSection
        borderLeftColor={portfolio.color}
        onExpanded={(newState) => onExpandedItemsChange(portfolio, newState)}
        content={(
          <ChartRiskReturn
            portfolioData={basicPortfolioData.basicPortfolioData}
            portfolioLoading={basicPortfolioData.basicPortfolioLoading}
            portfolioError={basicPortfolioData.basicPortfolioError}
            portfolioRiskReturnData={portfolioRiskReturn.portfolioRiskReturnData}
            portfolioRiskReturnLoading={portfolioRiskReturn.portfolioRiskReturnLoading}
            portfolioRiskReturnError={portfolioRiskReturn.portfolioRiskReturnError}
            data={riskReturn.riskReturnData}
            loading={riskReturn.riskReturnLoading}
            error={riskReturn.riskReturnError}
            onRiskReturnSelectedRangeChanged={(range) => onChartSettingsChange('risk_return_chart', `selected_range_${portfolio.depotNumber}`, range)}
          />
        )}
      />
    </Grid>
  )
}, (prevProps, nextProps) => {
  return prevProps.customerId == nextProps.customerId
    && _.get(prevProps.portfolio, 'depotNumber') == _.get(nextProps.portfolio, 'depotNumber')
    && prevProps.investmentStrategyId == nextProps.investmentStrategyId
    && prevProps.expanded == nextProps.expanded
});

function RiskReturnChart({portfolios, customerId, dataProvider, onChartSettingsChange, expandedItems, onExpandedItemsChange, investmentStrategyId}) {

  const [isPtfExpanded, handleExpandedChanged] = useExpandedItems(expandedItems, onExpandedItemsChange, 'riskReturnChartItems');

  return (
    <Grid container spacing={2}>
      {portfolios.map((portfolio) => (
        <PortfolioRiskReturnChart
          key={portfolio.depotNumber}
          portfolio={portfolio}
          customerId={customerId}
          dataProvider={dataProvider}
          onChartSettingsChange={onChartSettingsChange}
          expanded={isPtfExpanded(portfolio)}
          onExpandedItemsChange={handleExpandedChanged}
          investmentStrategyId={investmentStrategyId}
        />
      ))}
    </Grid>
  )
}

RiskReturnChart.propTypes = {}

RiskReturnChart.propDefs = {}

RiskReturnChart.defaultProps = {}

export default RiskReturnChart;