const styles = theme => ({
  container: {
    boxShadow: 'none',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  withDivider: {
    paddingBottom: 20,
    marginBottom: 20,
    borderBottom: '1px solid #E1E3E9',
    borderRadius: 0,

    [theme.breakpoints.down('sm')]: {
      paddingTop: 15,
      marginBottom: 15,
    }
  },
  last: {
    paddingBottom: '0!important',
    marginBottom: '0!important',
    borderBottom: 'none!important'
  },
  chartContainer: {
    height: 470
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'self-start'
  },
  headerAlignCenter: {
    alignItems: 'center',
  },
  header: {
    padding: 0,
    overflow: 'hidden',
    zIndex: 1,

    '& span': {
      fontFamily: "'Roboto-Regular'",
      fontSize: 18,
      color: '#4D4F5C',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingRight: 4,

      '&:hover': {
        overflow: 'initial',
        wordBreak: 'break-all',
        whiteSpace: 'initial',
        textOverflow: 'initial'
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      }
    }
  },
  headerNoEllipsis: {
    padding: 0,
    zIndex: 1,

    [theme.breakpoints.down('md')]: {
        overflow: 'hidden',
      },

    '& span': {
      fontFamily: "'Roboto-Regular'",
      fontSize: 18,
      color: '#4D4F5C',
      paddingRight: 4,

      [theme.breakpoints.down('md')]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        '&:hover': {
          overflow: 'initial',
          wordBreak: 'break-all',
          whiteSpace: 'initial',
          textOverflow: 'initial'
        },
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      }
    }
  },
  CardHeaderContent: {
    width: '100%'
  },
  CardContentRoot: {
    padding: '0!important',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentWrapper: {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    maxHeight: '100%',
    paddingTop: 20,

    [theme.breakpoints.down('sm')]: {
      paddingTop: 15
    }
  },
  iconContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableContainer: {
    '& $header': {
      '& span': {
        padding: 0
      }
    }
  },
  emptyMessage: {
    margin: 0,
    fontSize: 16,
    color: '#b1b1b1',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  }
});


export default styles;
