import { makeStyles } from '@material-ui/core';
import { smFontSize, xsFontSize, fontSize } from '../../../../../../utils/constants';
import { SECTION_PADDING } from '../../../../constants';

export default makeStyles((theme) => ({
  message: {
    margin: 0,
    fontSize: fontSize,
    color: '#80858C',
    textAlign: 'center',
    width: '100%',

    '&.withPadding': {
      margin: 'auto',
      padding: SECTION_PADDING,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: smFontSize,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: xsFontSize,
    },
  }
}))