import { makeStyles } from '@material-ui/core';

export const styles = (theme) => ({
  modalTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#313D44',
    marginRight: 16
  },
  textField: {
    fontFamily: 'Roboto-Regular',
    fontSize:18,
    color: '#4D4F5C',
  },
  controlSection: {
    '& > div': {
      padding: '0 16px'
    }
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionButton: {
    boxShadow: 'none',
    borderRadius: 2,
    textTransform: 'none',
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    marginLeft: 20
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: '#313D44'
  },
});

export default makeStyles(styles);