import React from 'react';
import moment from "moment";

import { Grid } from '@material-ui/core';

import useStyles from '../styles';
import images from '../../../../images';
import TextField from '../TextField';
import DateField from '../DateField';
import CurrencyField from '../CurrencyField';
import PercentageField from '../PercentageField';
import NonFieldErrors from "../NonFieldErrors";


export default (props) => {
  const classes = useStyles();

  const {
    handleFormChange,
    errors,
    asset,
    viewOnly
  } = props;

  const [model, setModel] = React.useState({
    contract_number: '',
    bank_name: '',
    investment_amount: undefined,
    final_duration: undefined,
    duration_in_days: undefined,
    interests: undefined,
    acquisition_date: undefined,
    updated: undefined,
  });

  React.useEffect(() => {
    if (asset) {
      const _model = {
        contract_number: asset.name,
        investment_amount: +asset.value,
        bank_name: asset.bank_name,
        final_duration: asset.final_duration && moment(asset.final_duration),
        interests: +asset.interests,
        duration_in_days: asset.duration_in_days,
        acquisition_date: asset.acquisition_date && moment(asset.acquisition_date),
        updated: asset.updated && moment(asset.updated + 'Z'),
      };
      setModel(_model);
    }
  }, [asset]);


  const prepareFormData = () => {
    return {
      name: model.contract_number,
      value: model.investment_amount,
      bank_name: model.bank_name,
      final_duration: model.final_duration && model.final_duration.format('YYYY-MM-DD'),
      interests: model.interests,
      acquisition_date: model.acquisition_date && model.acquisition_date.format('YYYY-MM-DD')
    }
  };

  React.useEffect(() => {
    handleFormChange && handleFormChange(prepareFormData());
  }, [model]);

  const handleModelChange = (field) => (event) => {
    setModel({
      ...model,
      [field]: event.target.value
    })
  };

  const handleDateChanged = (field) => (value) => {
    setModel({
      ...model,
      [field]: value
    })
  };

  const handleDurationChanged = (value) => {
    const diff = value && value.startOf('d').diff(moment().startOf('d'), 'd') || 0;
    const duration_in_days = value && diff > 0 ? diff : 0;
    setModel({
      ...model,
      final_duration: value,
      duration_in_days
    });
  };

  return (
    <Grid container className={classes.formContainer}>
      <Grid item className={classes.formBody}>
        <div className={classes.formHeader}>
          <div>
            <img src={images.asset_icons.save_cash_icon_white} alt="Cash"/>
          </div>
          <p>Fest- und Tagesgeld {+model.duration_in_days <= 0 && (<span>(Laufzeitende erreicht)</span>)}</p>
        </div>
        <div className={classes.fieldsContainer}>
           <TextField
            label="Kontonummer"
            value={model.contract_number}
            error={errors && errors.name}
            onChange={handleModelChange('contract_number')}
            disabled={viewOnly}
          />
          <TextField
            label="Gesellschaft"
            value={model.bank_name}
            onChange={handleModelChange('bank_name')}
            error={errors && errors.bank_name}
            disabled={viewOnly}
          />
          <DateField
            label="Erwerbsdatum"
            maxDate={moment()}
            value={model.acquisition_date}
            error={errors && errors.acquisition_date}
            onChange={handleDateChanged('acquisition_date')}
            disabled={viewOnly}
          />
          <CurrencyField
            label="Anlagesumme"
            value={model.investment_amount}
            error={errors && errors.value}
            onChange={handleModelChange('investment_amount')}
            disabled={viewOnly}
          />
          <DateField
            label="Laufzeit bis"
            value={model.final_duration}
            minDate={moment().add(1, 'd')}
            error={errors && errors.final_duration}
            onChange={handleDurationChanged}
            disabled={viewOnly}
          />
          <TextField
            label="Laufzeit in Tagen"
            value={model.duration_in_days}
            disabled={true}
          />
           <PercentageField
            label="Zinssatz"
            value={model.interests}
            onChange={handleModelChange('interests')}
            error={errors && errors.interests}
            disabled={viewOnly}
          />
          <DateField
            label="Stand vom"
            value={model.updated || moment()}
            disabled={true}
          />
          {errors && errors['non_field_errors'] && <NonFieldErrors errors={errors['non_field_errors']}/>}
        </div>
      </Grid>
    </Grid>
  )
}