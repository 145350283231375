import React from 'react';

import styles from "../../styles";
import NotificationItem from "../NotificationItem";
import withStyles from "@material-ui/core/styles/withStyles";
import Badge from "@material-ui/core/Badge";
import {getOriginLink} from "../../../../../../utils/constants";
import useOnClickOutside from "../../../../../../utils/useOnClickOutside";
import Popover from "@material-ui/core/Popover";
import _ from "lodash";

const DropDownIcon = props => {

  const {
    classes,
    openAction,
    closeAction,
    open,
    faIcon,
    badgeContent,
    items
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if(open) {
      closeAction()
    } else {
      setAnchorEl(event.currentTarget);
      openAction();
    }
  };

  const handleClose = () => {
    if (open) {
      closeAction();
      setAnchorEl(null);
    }
  };

  const hasItems = items.find(i => i.badgeContent) !== undefined;

  const getItemAttr = (item) => {
    return _.isString(item.path) ? {href: getOriginLink(item.path)} : {onClick: item.path}
  }

  return (
    <>
      <div className={classes.dropdownMenuWrapper}>
        <NotificationItem faIcon={faIcon} badgeContent={badgeContent} onClick={handleClick} />
        {hasItems && (
          <Popover open={open} anchorEl={anchorEl} onClose={handleClose}
                   classes={{paper: classes.dropdownMenu}}
                   anchorOrigin={{vertical: 55, horizontal: 'right'}}
                   transformOrigin={{vertical: 'top', horizontal: 'right'}}
          >
            {items.map(item => {
              if (!item.badgeContent) {
                return
              }
              return (
                <a key={item.key} className={classes.dropdownItem} {...getItemAttr(item)}>
                  {item.name}
                  <Badge
                    badgeContent={item.badgeContent}
                    classes={{
                      root: classes.dropDownItemBadgeRoot,
                      badge: classes.dropDownItemBadge
                    }}
                  />
                </a>
              )
            })}
          </Popover>
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(DropDownIcon);