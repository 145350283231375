import React from 'react';
import _ from 'lodash';

import useStyles from './styles';
import {DialogContent, Grid} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import MusterDepot
  from "../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/components/MusterDepot/MusterDepot";
import FavoriteListSelection
  from "../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/components/FavoriteListSelection/FavoriteListSelection";
import ModelPortfolioSelection
  from "../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/components/ModelPortfolioSelection/ModelPortfolioSelection";
import InvestmentStrategySelection
  from "../../../RiskProfiling/components/StepContent/components/step/ProductsSelectionSteps/components/InvestmentRecommendationStep/components/InvestmentStrategySelection/InvestmentStrategySelection";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {ArrowBack} from "@material-ui/icons";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import ResponsiveDialog from "../../../../components/ResponsiveDialog/ResponsiveDialog";
import {CustomerDepotSelection} from "../index";

function toggleInstrument(instruments, instrument) {
  const instrumentExists = _.find(instruments, (i) => i.isin === instrument.isin);
  if (instrumentExists) {
    return _.filter(instruments, (i) => i.isin !== instrument.isin);
  } else {
    return [...instruments, instrument];
  }
}

function ProductSourceSelectionModal({open, source, onClose, onSelectProducts}) {
  const classes = useStyles();

  const [activeSourceItem, setActiveSourceItem] = React.useState(null);
  const [selectedInstruments, setSelectedInstruments] = React.useState([]);
  const [selectedSource, setSelectedSource] = React.useState(null);

  const cleanSelectedData = () => {
    setActiveSourceItem(null);
    setSelectedInstruments([]);
    setSelectedSource(null);
  }

  React.useEffect(() => {
    if (!source) {
      cleanSelectedData();
    }
  }, [source])

  const SourceComponent = (() => {
    if (!source) {
      return null;
    }

    return {
      "musterdepot": MusterDepot,
      "favoritelist": FavoriteListSelection,
      "modellportfolio": ModelPortfolioSelection,
      "private_investment": InvestmentStrategySelection,
      "customer_portfolio": CustomerDepotSelection
    }[source];
  })();

  const handleDetailsClick = (item, skipDetails, hasWarning) => {
    setActiveSourceItem(item);

    if (skipDetails) {
      handleConfirmProductsClick(true, [], item)();
    }
  };

  if (!SourceComponent) {
    return null;
  }

  const handleInstrumentSelect = (instrument) => {
    setSelectedInstruments((instruments) => toggleInstrument(instruments, instrument));
  }

  const handleAllInstrumentsSelect = (instruments) => {
    setSelectedInstruments(() => {

      if (_.isEmpty(instruments)) {
        return [];
      }

      return instruments;

    })
  }

  const handleSourceSelect = () => {
    setSelectedSource((_source) => !!_source ? null : activeSourceItem)
  }

  const handleBackClick = () => {
    if (!activeSourceItem) {
      onClose();
    } else {
      cleanSelectedData();
    }
  }

  const handleConfirmProductsClick = (closeModal, externalSelectedInstruments, sourceItem=undefined) => () => {
    // sourceItem is used for direct add MP or PI

    if (!onSelectProducts) {
      return;
    }

    const success = onSelectProducts(externalSelectedInstruments || selectedInstruments, sourceItem || selectedSource);
    if (!success) {
      return;
    }

    if (closeModal) {
      onClose();
    } else {
      handleBackClick();
    }
  }

  const getAdditionalNextButtonMessage = () => {
    const sourceName = {
      "musterdepot": 'Musterdepots',
      "favoritelist": 'Favoritenlisten',
      "modellportfolio": 'Modellportfolios',
      "private_investment": 'Vermögensverwaltung',
      "customer_portfolio": 'Kundendepot'
    }[source];

    return `Hinzufügen und zurück zu ${sourceName}`;
  }

  return (
    <ResponsiveDialog open={open} onClose={onClose}>

      <DialogTitle>
        <div className={classes.header}>
          <IconButton disableRipple aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent classes={{root: classes.dialogContentRoot}}>
        <SourceComponent
          onDetailsClick={handleDetailsClick}
          selectedItem={activeSourceItem}
          directAddingPossible
          onInstrumentSelect={handleInstrumentSelect}
          onSourceSelect={handleSourceSelect}
          sourceSelected={!!selectedSource}
          onSelectAll={handleAllInstrumentsSelect}
          selectedInstruments={selectedInstruments}
          forceAllAssetsSelection={false}
          onConfirmProductsClick={handleConfirmProductsClick}
        />
      </DialogContent>
      <DialogActions classes={{root: classes.dialogActionsRoot}}>
        <Grid container spacing={2} justify={"space-between"}>
          <Grid xs={12} sm={"auto"} item>
            <PrimaryButton
              text={"Zurück"}
              icon={<ArrowBack color={'primary'}/>}
              variant={"outlined"}
              style={{width: '100%'}}
              onButtonClick={handleBackClick}
            />
          </Grid>
          {activeSourceItem && (
            <Grid xs={12} sm={true} item>
              <Grid container justify="flex-end" spacing={2}>
                <Grid xs={12} sm={"auto"} item>
                  <PrimaryButton
                    text={getAdditionalNextButtonMessage()}
                    onButtonClick={handleConfirmProductsClick()}
                    disabled={!selectedSource && !selectedInstruments.length}
                    style={{width: '100%'}}
                  />
                </Grid>
                <Grid xs={12} sm={"auto"} item>
                  <PrimaryButton
                    text='Hinzufügen und zurück zur Produktauswahl'
                    onButtonClick={handleConfirmProductsClick(true)}
                    disabled={!selectedSource && !selectedInstruments.length}
                    style={{width: '100%'}}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
       </DialogActions>
    </ResponsiveDialog>
  )
}

export default ProductSourceSelectionModal;