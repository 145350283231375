export default (theme) => ({
  editorWrapper: {
    padding: 4,
    width: '100%'
  },
  editor: {
    '& .ql-editor': {
      '& p br': {
        content: '""'
      },
      '& p br::after': {
        content: '"\\204B"',
      }
    },
    // do NOT apply when empty and placeholder is shown
    '& .ql-editor.ql-blank[data-placeholder]': {
      '& p br': {
        content: 'none'
      },
      '& p br::after': {
        content: 'none',
      }
    }
  },
  saveBtn: {
    marginRight: 5,
    lineHeight: '1.1876em',
    padding: 4,
    marginBottom: 10
  },
  selectFormControl: {
    minWidth: 160,
    '& .MuiInputLabel-formControl': {
      fontSize: 'inherit',
      transform: 'translate(4px, 6px)'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(12px, -5.5px) scale(0.75)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#202A38',
    }
  },
  templateSelectFormElement: {
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 0
    }
  },
  selectFormLabel: {
    color: '#202A38',
  },
  selectRoot: {
    fontFamily: 'Roboto-Regular',
    color: '#202A38',
    padding: 4,
  },
  menuItem: {
    fontSize: 'inherit',
    whiteSpace: 'normal'
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  dialogActions: {
    padding: '8px 24px'
  },
  inputAdornment: {
    fontSize: 12,
    color: '#ABB6BE'
  },
})