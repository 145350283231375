import CommonAnalysisCard from "../../../../components/AnalysisCard";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import {formatNumber, toShortGermanFormat} from "../../../../../../utils/numberFormater";
import HighChartBase from "../../../../../../components/Charts/Base";
import InfoDialog from "../../../../../../components/InfoDialog";
import clsx from "clsx";
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../../../../CustomerDashboard/constants";
import ErrorMessage
  from '../../../../../CustomerDashboardV2/components_v2/ChartSectionBordered/components/ErrorMessage';

require('moment/locale/de');


class ChartPortfolioPerformanceForecast extends React.Component {
  ChartColorBest = '#7ED2CF';
  ChartColorMiddle = '#0098C6';
  ChartColorWorst = '#1F4998';
  forecast = {
    'good': {
      'name': 'Starke',
      'style': {background: this.ChartColorBest},
      'tooltipText': '10%',
      },
    'mid': {
      'name': 'Durchschnittliche',
      'style': {background: this.ChartColorMiddle},
      'tooltipText': '50%',
      },
    'bad': {
      'name': 'Schlechte',
      'style': {background: this.ChartColorWorst},
      'tooltipText': '90%',
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      infoWindowOpened: false,
    };
  }

  renderLegend(){
    const { classes } = this.props;

    let numOptions = {
      fraction: 2,
      decimal: ',',
      suffix: '',
      prefix: '',
    }

    let listItems = Object.keys(this.forecast).map(key => (
      <li className={classes.legendItem} key={key}>
        <div className={classes.bullet} style={this.forecast[key].style} />
        <span>
          {this.forecast[key].name} Wertentwicklung:&nbsp;
          <b>{toShortGermanFormat(this.props.data[key + '_return_last'])} EUR</b>
          &nbsp;({formatNumber(this.props.data[key + '_return_pa']*100, numOptions)} % p.a.)
        </span>
      </li>
    ))

    return <>
      <span>Insgesamt eingezahlt <b>{toShortGermanFormat(this.props.data['start_value'])} EUR</b></span>
      <ul className={classes.legend}>{listItems}</ul>
      <span className={classes.legendBottom}>Depot- und Transaktionskosten (wie z.B. Ausgabeaufschlag) sind in der Berechnung nicht berücksichtigt</span>
    </>
  }

  renderChart() {

    const { classes } = this.props;

    const goodReturnTS = this.props.data['good_return']
      .map(value => ([Date.parse(value.date), value.return]));
    const midReturnTS = this.props.data['mid_return'].map(value => ([Date.parse(value.date), value.return]));
    const badReturnTS = this.props.data['bad_return'].map(value => ([Date.parse(value.date), value.return]));
    const fillAreaTS = this.props.data['good_return']
      .map((value, i) => ([Date.parse(value.date), value.return, this.props.data['bad_return'][i].return]));

    const start_value = toShortGermanFormat(this.props.data['start_value']);
    const start_date = Date.parse(this.props.data['start_date']);

    return <div className={classes.chartContainer}>
      {this.renderLegend()}
      <HighChartBase options={{
        chart: {
          height: 370,
          marginTop: 30,
        },
        plotOptions: {
          series: {
            dataGrouping: {
              dateTimeLabelFormats: {
                week: ["Woche vom %A, %b %e, %Y"],
              }
            },
            type: 'line',
            fillColor: undefined,
            compareBase: 0,
            showInNavigator: true,
          },
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          crosshair: true,
        },
        yAxis: {
          title: {
            enabled: false
          },
          labels: {
            useHTML: true,
            enabled: true,
            x: -2,
            formatter: function () {
              return toShortGermanFormat(this.value, '', ' &euro;', 2, true);
            },
          }
        },
        legend: {
          enabled: false,
       },
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: '<span style="display: block; padding: 2px 0px; font-size: 14px; font-family: Roboto-Bold;">{point.key}</span>',
          pointFormat: '<span style="display: block; padding: 6px 0px;"><div style="display: inline-block; width: 10px; height: 10px; border-radius:50%; background-color: {series.color};"></div><div style="display: inline; font-size: 14px; color: #80858C; margin-left: 5px;">{series.name} {point.y}</div></span>',
          formatter: function (tooltip) {
            let defaultTooltip = tooltip.defaultFormatter.call(this, tooltip);
            // defaultTooltip is an array where 1st item is the formatted date according to headerFormat
            const daysDiff = Math.floor((this.points[0].x - start_date) / (1000 * 60 * 60 * 24));

            if (daysDiff > 365) {
              const yearsDiff = Math.floor(daysDiff / 365);
              defaultTooltip.splice(1, 0, `<span style="display: block; padding: 6px 0px; font-size: 14px; font-family: Roboto-Bold; color: #80858C">Investition ${start_value} &euro; <br>Chancen nach ${yearsDiff} Jahren</span>`);
            }

            return defaultTooltip
          },
          valueDecimals: 2,
          backgroundColor: 'white',
          shadow: {
            color: '#00000026',
            offsetX: 0,
            offsetY: 0,
            width: 10,
            opacity: 0.15
          },
          padding: 20,
          shape: 'square',
          borderWidth: 0,
          borderRadius: 4,
          valueSuffix: '&euro;',
          style: {
            fontFamily: '"Roboto-Regular"',
          }
        },
        series: [
          {
            type: 'arearange',
            name: 'fill_',
            showInLegend: false,
            enableMouseTracking: false,
            fillColor: '#F2F8F2',
            data: fillAreaTS,
            zIndex: 1,
          },
          {
            type: 'line',
            name: `${this.forecast['good'].tooltipText} auf min.`,
            data: goodReturnTS,
            zIndex: 2,
            marker: {
              symbol: 'circle',
              lineColor: this.ChartColorBest
            },
            color: this.ChartColorBest,
          }, {
            type: 'line',
            name: `${this.forecast['mid'].tooltipText} auf min.`,
            data: midReturnTS,
            zIndex: 2,
            marker: {
              symbol: 'circle',
              lineColor: this.ChartColorMiddle
            },
            color: this.ChartColorMiddle,
          }, {
            type: 'line',
            name: `${this.forecast['bad'].tooltipText} auf min.`,
            data: badReturnTS,
            zIndex: 2,
            marker: {
              symbol: 'circle',
              lineColor: this.ChartColorWorst
            },
            color: this.ChartColorWorst,
          }
        ]
      }} language={'DE'}/>

      <InfoDialog
        open={this.state.infoWindowOpened}
        onClose={() => this.setState({infoWindowOpened: false})}
        title={'Wie wird diese berechnet?'}
        message={
          'Mit der Bootstrapping-Methode werden 1000 Szenarien für zukünftige Renditen erzeugt. Dabei werden zukünftige Renditen durch zufällige Auswahl aus wöchentlichen, historischen Renditen der letzten 10 Jahre simuliert. Die gute, durchschnittliche und schlechte Wertentwicklung entspricht dem 90., 50. und 10. Perzentil der Renditeverteilung aus 1000 Szenarien.'
        }
      >

      </InfoDialog>
      <div className={classes.infoLinkText} onClick={() => this.setState({infoWindowOpened: true})}>Wie wird diese berechnet?</div>
    </div>
  }

  renderErrorMessage(error) {
    return <ErrorMessage error={error} />
  }

  render() {
    const { classes } = this.props;
    return <CommonAnalysisCard
      classes={{
        contentWrapper: classes.contentWrapper
      }}
      title={'Mögliche Wertentwicklung 15 Jahre'}
      content={
        <>
          <div className={clsx(classes.container, this.props.error ? classes.containerError : this.props.data ? '' : classes.containerError)}>
            {this.props.loading
              ? <CircularProgress/>
              : this.props.error
                ? this.renderErrorMessage(this.props.error)
                : this.props.data
                  ? this.renderChart()
                  : this.renderErrorMessage(DEFAULT_EMPTY_SECTION_MESSAGE)}
          </div>
        </>}
      expanded={this.props.expanded}
      onExpandedClick={this.props.onExpanded}
    />
  }
}

export default withStyles(styles)(ChartPortfolioPerformanceForecast)
