import React from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton
} from '@material-ui/core';

import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { CloseIcon } from '../../../../images'

import useStyles from './styles';

function DeleteModelPortfolioModal(props) {

  const {
    open,
    onClose,
    modelPortfolioName,
    onAccept
  } = props;

  const classes = useStyles();

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const handleAccept = () => {
    if (onAccept) {
      onAccept()
    }
  }

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogContent>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <p className={classes.text}>Sind Sie sicher, dass sie das Musterdepot mit dem Namen <b>{ modelPortfolioName }</b> löschen möchten?</p>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          text="Schließen"
          variant="outlined"
          customClasses={{root: classes.stickyButtonRoot}} 
          onButtonClick={handleClose}
        />
        <PrimaryButton
          text="Löschen"
          customClasses={{root: classes.stickyButtonRoot}}
          onButtonClick={handleAccept}
        />
      </DialogActions>
    </Dialog>
  )
}

DeleteModelPortfolioModal.propTypes = {
  /** Flag, that indicate, is modal visible, or not */
  open: PropTypes.bool.isRequired,

  /** onClose event handler */
  onClose: PropTypes.func.isRequired,

  /** Name of the model portfolio*/
  modelPortfolioName: PropTypes.string.isRequired,

  /** onAccept event handler */
  onAccept: PropTypes.func
}

export default DeleteModelPortfolioModal

