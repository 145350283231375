import { makeStyles } from '@material-ui/core';


export default makeStyles(theme => ({
  chartContainer: {
    height: 400,
    display: 'flex',
    alignItems: 'center',

    '& div[data-highcharts-chart]':{
      maxHeight: '100%!important',
    },

    [theme.breakpoints.only('md')]: {
      height: 300
    },

    [theme.breakpoints.only('sm')]: {
      height: 500
    },

    [theme.breakpoints.only('xs')]: {
      height: 280
    },
  },
  titleContainer: {
    '& [class^="MuiTypography-root"]': {
        color: '#202A38',
        fontSize: 20,
        fontFamily: 'Roboto-Bold'
    }
  },
}))