import React from 'react';


export default () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="28.493" height="30" viewBox="0 0 28.493 30">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_7673" data-name="Rectangle 7673" width="28.493" height="30" fill="#fff"/>
    </clipPath>
  </defs>
  <g id="Group_7968" data-name="Group 7968" transform="translate(0 0)">
    <g id="Group_7967" data-name="Group 7967" transform="translate(0 0)" clip-path="url(#clip-path)">
      <path id="Path_6743" data-name="Path 6743" d="M2.844,10.9,6.149,9.32a.879.879,0,0,0-.758-1.587l-1.337.64A11.918,11.918,0,0,1,20.755,3.4a.879.879,0,0,0,.891-1.516A13.68,13.68,0,0,0,2.357,7.844L1.672,6.409a.879.879,0,1,0-1.586.758l1.586,3.319a.879.879,0,0,0,1.172.414m15.42,14.462-3.021-2.074a.879.879,0,0,0-.995,1.45l1.241.852a11.959,11.959,0,0,1-12.677-12,.879.879,0,1,0-1.758-.019,13.719,13.719,0,0,0,14.8,13.753l-.889,1.3a.879.879,0,1,0,1.45,1l2.08-3.033a.88.88,0,0,0-.228-1.223M28.49,5.193a.879.879,0,0,0-.808-.944l-3.666-.286a.88.88,0,0,0-.944.809l-.285,3.654a.879.879,0,1,0,1.753.137l.114-1.472a11.936,11.936,0,0,1-4.028,16.944.879.879,0,0,0,.872,1.527A13.69,13.69,0,0,0,25.956,5.878L27.545,6a.879.879,0,0,0,.944-.809M13.9,21.657c.007,0,.015,0,.022.005a.726.726,0,0,0,.653,0c.007,0,.015,0,.022-.005A9.639,9.639,0,0,0,20.2,13.2a8.524,8.524,0,0,0-.26-2.069c0-.016-.01-.03-.012-.047a10,10,0,0,0-5.334-6.346c-.007,0-.015,0-.022-.005a.729.729,0,0,0-.653,0c-.007,0-.015,0-.022.005A10.486,10.486,0,0,0,9.563,8.691a.619.619,0,0,0-.044.049c-.009.015-.008.032-.016.047A8.758,8.758,0,0,0,8.291,13.2,9.64,9.64,0,0,0,13.9,21.657M13.47,15.3,9.846,13.21c0-.005,0-.01,0-.015a7.111,7.111,0,0,1,.668-2.985l2.957,1.708Zm-3.334-.13L13.47,17.1v2.5a8.258,8.258,0,0,1-3.334-4.422m4.888,4.422V14.506l3.569-2.06a6.885,6.885,0,0,1,.056.749,7.855,7.855,0,0,1-3.625,6.4m3.206-8.732-3.206,1.851V6.8a8.323,8.323,0,0,1,3.206,4.064M13.47,6.8v3.326L11.315,8.879A8.82,8.82,0,0,1,13.47,6.8" transform="translate(0 0)" fill="#fff"/>
    </g>
  </g>
</svg>
    )
}