import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  emptyDetails: {
    color: '#4D4F5C',
    fontSize: 14,
    fontFamily: 'Roboto-Regular'
  },
  container: {
    '& > div': {
      marginTop: 30,
    }
  },
  filledPercentage: {
    minWidth: 30
  },
}))

