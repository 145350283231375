import React from 'react';

function getReportTypeTooltipMsg(listOfCharts, title){
    return (
      <>
        {title}
        <ul style={{
          margin: 0,
        }}
        >
            {listOfCharts.map((chartName, index) => {
                return (<li key={index}>{chartName}</li>)
            })}
        </ul>
      </>
    )
}

const DEPOT_STATUS_TOOLTIP_CONTENT = ['Aktuelle Depotübersicht inkl. lfd. Zahlpläne', 'Ohne Performancedaten'];

const BASIC_REPORT_TOOLTIP_CONTENT = ['Historische Entwicklung des investierten Kapitals',
                                      'Jährliche Wertentwicklung der Depots der letzten 5 Jahre in %',
                                      'Performanceangaben pro Instrument',
                                      "Sonstige Vermögenswerte"];

const EXPERT_REPORT_TOOLTIP_CONTENT = ['Zeitgewichtete Rendite Chart',
                                        'Jährliche Wertentwicklung der Depots der letzten 5 Jahre in %',
                                        'Detaillierte Depotstruktur'];

const PRO_REPORT_TOOLTIP_CONTENT = ['Peergroup-Quintilsranking',
                                    'Projektion - Mögliche Wertentwicklung 15 Jahre',
                                    'Detaillierte Investmentkennzahlen',
                                    'Rendite-Risiko-Diagramm', 'Stresstests', 'Korrelationsmatrix'];

const CUSTOMER_PRO_REPORT_TOOLTIP_CONTENT = ['Detaillierte Investmentkennzahlen',
                                            'Rendite-Risiko-Diagramm',
                                            'Stresstests', 'Korrelationsmatrix'];

export const REPORT_TYPES = {
    DEPOT_STATUS: {
        value: 0,
        description: 'Depotstatus',
        tooltipContent: getReportTypeTooltipMsg(DEPOT_STATUS_TOOLTIP_CONTENT)
    },
    BASIC: {
        value: 1,
        description: 'Basis',
        tooltipContent: getReportTypeTooltipMsg(BASIC_REPORT_TOOLTIP_CONTENT, 'Zusätzlich zu Depotstatus:')
    },
    EXPERT: {
        value: 2,
        description: 'Experte',
        tooltipContent: getReportTypeTooltipMsg(EXPERT_REPORT_TOOLTIP_CONTENT, 'Zusätzlich zu Basis:')
    },
    PRO: {
        value: 3,
        description: 'Professionell',
        tooltipContent: getReportTypeTooltipMsg(PRO_REPORT_TOOLTIP_CONTENT, 'Zusätzlich zu Experte:'),
        customerTooltipContent: getReportTypeTooltipMsg(CUSTOMER_PRO_REPORT_TOOLTIP_CONTENT, 'Zusätzlich zu Experte:')
    },
    CUSTOM: {
        value: 4,
        description: 'Individuell',
        tooltipContent: 'Konfigurieren Sie selbst, welche Informationen in der Vermögensübersicht angezeigt werden sollen.'
    }
};

export const DEFAULT_REPORT_TYPE = REPORT_TYPES.BASIC;


