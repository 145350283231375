import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    color: '#202A38',
    margin: 0
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,

    '& button >span > span': {
      fontSize: 14
    }
  },
  subNavigationButton: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 24,
    position: 'relative',
    padding: 5,

    '&:hover': {
      cursor: 'pointer'
    },

    '&:hover span:first-child': {
      width: '100%',
      height: '100%',
      borderRadius: 5
    },

    '& span:first-child': {
      transition: 'all',
      transitionDuration: 150,
      height: 28,
      width: 28,
      borderRadius: 20,
      backgroundColor: '#CCE9FA',
      position: 'absolute',
    },

    '& span:nth-child(2)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 28,
      width: 28,
      borderRadius: 20,
      marginRight: 8,
      position: 'relative',
      zIndex: 99
    },

    '& span:last-child': {
      fontFamily: 'Roboto-Bold',
      fontSize: 14,
      color: theme.palette.primary.main,
      position: 'relative',
      zIndex: 99
    }
  },
  subNavigationContainer: {
    display: 'flex'
  },
  mainContentContainer: {
    backgroundColor: 'white',
    minHeight: '100%',
    padding: '24px 0'
  },
  sectionHeader: {
    fontFamily:'Roboto-Bold',
    fontSize: 20,
    color: '#202A38',
    margin: 0,
    display: 'flex',
    alignItems: 'baseline'
  },
  widgetHeader: {
    paddingBottom: 20
  },
  dashboardTableWithStickyHeader: {
    overflow: "auto",
    maxHeight: 1016,
    paddingTop: 0
  },
  dashboardTableXScrollable: {
    overflowX: "auto"
  },
  instrumentsNumber: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16
  },
  tableCell: {
    width: 100,
    '&:first-child': {
      width: 350
    },
    '&:nth-child(2)': {
      width: 83,
      whiteSpace: 'normal'
    },
    '&:nth-child(3)': {
      width: 50
    },
    whiteSpace: 'nowrap'
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& > h1': {
      fontFamily: 'Roboto-Bold',
      fontSize: '202px',
      color: '#80858C',
      margin: 0,

      [theme.breakpoints.down('sm')]: {
        fontSize: 122
      },
    },

    '& > p': {
      fontFamily: 'Roboto-Regular',
      fontSize: 40,
      margin: 0,
      color: '#202A38',
      marginTop: -40,

      [theme.breakpoints.down('sm')]: {
        fontSize: 25,
        marginTop: -20,
      },
    }
  },
  assetsDataErrorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > p': {
      fontFamily: 'Roboto-Regular',
      fontSize: 24,
      color: '#202A38'
    }
  },
  containerName: {
    marginLeft: '9px !important'
  }
})

export default makeStyles(styles)