import React from 'react';

import { Grid } from '@material-ui/core';

import useStyles from './styles';
import {
  getImprintLink,
  getPrivacyPolicyLink
} from "../../../../../utils/constants";


const Footer = ({ agency_id }) => {
  const classes = useStyles();

  return (
    <div className={classes.footerContainer}>
      <Grid container className={classes.footerContainerContent}>
        <Grid item lg={8} xs={12}>
          <p className={classes.footerText}>
            © {new Date().getFullYear()} - Alle Rechte vorbehalten. Es wird keine Gewährleistung für die Vollständigkeit, Richtigkeit und Aktualität der Daten übernommen.
          </p>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Grid container className={classes.footerLinkContainer}>
            <Grid item className={classes.footerLinkItem}>
              <a href={`#`} className={classes.footerText}>Startseite</a>
            </Grid>
            <Grid item className={classes.footerLinkItem}>
              <a 
                href={getPrivacyPolicyLink(false, agency_id)}
                target="_blank"
                className={classes.footerText}
              >Datenschutz</a>
            </Grid>
            <Grid item className={classes.footerLinkItem}>
              <a 
                href={getImprintLink(false, agency_id)}
                target="_blank"
                className={classes.footerText}
              >Impressum</a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;