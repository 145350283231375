import React from "react";

export default () => {
  return (
    <svg
      id="Gruppe_8192"
      data-name="Gruppe 8192"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_8061"
            data-name="Rechteck 8061"
            width="30"
            height="30"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Gruppe_8191" data-name="Gruppe 8191" clip-path="url(#clip-path)">
        <path
          id="Pfad_7831"
          data-name="Pfad 7831"
          d="M13.394,18.781V17.325h1.289a9.426,9.426,0,0,1,.962-1.217,1.445,1.445,0,0,0-.8-.239H13.394a1.458,1.458,0,0,0-1.456,1.456v.728H10.386a22.224,22.224,0,0,0,1.693-3.64h4.087c.109.294.224.587.342.878a9.354,9.354,0,0,1,1.229-.865l-.005-.013h.028a9.324,9.324,0,0,1,2.187-.933v-.523H17.2q-.352-1.041-.661-2.184h1.222V9.317H16.174c-.224-.957-.425-1.951-.6-2.972V5.677a.726.726,0,0,0-.077-.325L14.774,3.9a.758.758,0,0,0-1.3,0l-.728,1.456a.731.731,0,0,0-.076.325v.668c-.171,1.021-.372,2.015-.6,2.972H10.482v1.456H11.7q-.309,1.141-.661,2.184H8.3v1.456h2.214a17.165,17.165,0,0,1-2.069,3.932.728.728,0,0,0,.582,1.164h3.64a.728.728,0,0,0,.728-.728M14.122,6.4c0,.3.489,2.6.56,2.912h-1.12c.072-.316.56-2.611.56-2.912m-.913,4.368h1.826c.183.7.393,1.44.63,2.184H12.579c.237-.744.447-1.479.63-2.184m.828,15.767h-2.8V22.6h1.888c0-.033,0-.066,0-.1a9.43,9.43,0,0,1,.118-1.487H9.653v3.9a1.621,1.621,0,0,1-3.242,0V1.585H20.094a1.622,1.622,0,0,1,1.62,1.62v9.954c.259-.021.521-.035.786-.035s.536.014.8.036V3.205A3.209,3.209,0,0,0,20.094,0H3.206A3.209,3.209,0,0,0,0,3.205V6.314H4.826V24.92a3.206,3.206,0,0,0,3.2,3.2H15a9.352,9.352,0,0,1-.96-1.584M4.826,4.728H1.586V3.205a1.62,1.62,0,1,1,3.24,0ZM22.5,15A7.5,7.5,0,1,0,30,22.5,7.5,7.5,0,0,0,22.5,15m0,13.125a5.616,5.616,0,0,1-4.58-8.879l7.834,7.834A5.588,5.588,0,0,1,22.5,28.125m4.58-2.371L19.246,17.92a5.616,5.616,0,0,1,7.834,7.834"
          fill="#80858c"
        />
      </g>
    </svg>
  );
};
