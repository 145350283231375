import _ from "lodash";
import { getKeyFieldValue, preparePortfoliosForCombinedTrading } from '../CustomerDashboard/utils';
import { MODEL_PORTFOLIO_TYPES, MODEL_PORTFOLIO_TYPE_LABEL} from './constants'
import {UserUtils, VIRTUAL_PORTFOLIO_QTY_DECIMALS} from '../../utils/utils'
import {CONFIG} from "../VirtualPortfolioManager/constants";
import {TRADING_ACTIONS} from "../../components/Charts/InstrumentsAllocationTable/constants";
import React from "react";
import {MODEL_PORTFOLIO_WEIGHT} from "./Create/constants";

export const getInstrName = (item) => item && (item.full_name || item.name);

export const getModelPortfolioType = (modelPortfolio) => {
  return MODEL_PORTFOLIO_TYPE_LABEL[modelPortfolio.access_type] || null;
};

export const getModelPortfolioOwner = (modelPortfolio) => {
  const agency = _.get(modelPortfolio, 'agency_summary');

  return !!agency ? `${agency.name} (${agency.agency_id})` : null;
};

export class InstrumentsHandler {

  filterInstruments(instruments) {
    throw new Error('Method "filterInstruments" should be implemented')
  }
}

export const checkCriterias = (instrument, acceptanceCriterias, instrumentsToAccept, instrumentsToExclude) => {
  const isValid = isAcceptanceCriteriasValid(acceptanceCriterias);

  if (isValid) {
    instrumentsToAccept.push(instrument)
  } else {
    instrument.disabledByFilter = true;
    instrument.disabledByAcceptanceCriterias = acceptanceCriterias;
    instrumentsToExclude.push(instrument);
  }

  return isValid;
};

export const isAcceptanceCriteriasValid = (criterias) => {

  for (let key in criterias) {
    for (let property in criterias[key]) {
      if (!criterias[key][property].value) {
        return false
      }
    }
  }

  return true
};


export const modelPortfolioAccessible = (modelPortfolio, auth) => {

  switch (modelPortfolio.access_type) {

    case MODEL_PORTFOLIO_TYPES.PUBLIC:
    case MODEL_PORTFOLIO_TYPES.CUSTODIAN_AGENCY:
    case MODEL_PORTFOLIO_TYPES.PARENT_AGENCY:
      return false;

    case MODEL_PORTFOLIO_TYPES.PERSONAL:
      return true;

    case MODEL_PORTFOLIO_TYPES.AGENCY:
    case MODEL_PORTFOLIO_TYPES.SUB_AGENCY:
      return UserUtils.isChief(auth);
    
    default:
      return false
  }

};

export const acceptanceCriteriasExists = (instrument) => {
  return instrument.hasOwnProperty('disabledByAcceptanceCriterias') && !_.isEmpty(instrument.disabledByAcceptanceCriterias)
};


export const getInstrumentId = (data) => {
  // we dont need assetInternalId as VP dont have sub depots
  const keyField = data.payment_id ? 'payment_id' : 'isin';
  return getKeyFieldValue(data, keyField);
};

export const getDummyPortfolioTransactions = (portfolio) => {

  let p = portfolio || {};

  return {
    // to enable trading
    data: {...p, trading_enabled: true, saving_plan_trading_enabled: true},
    // to enable fields
    availableOrderingBuyFields: ['amount_eur', 'quantity'],
    availableOrderingSellFields: ['amount_eur', 'quantity'],
    availableOrderingSwitchFields: ['quantity'],
    // list with all transactions that are available in PortfolioTrade
    transactions: {buy:[], savings_plan: [], sell: [], switch: []},  // payout_plan: [], switch_plan: []
    transactionsWeightType: MODEL_PORTFOLIO_WEIGHT.AUTO,
    portfolioValueTotal: 0,
    // to enable discounts
    ordering_discounts_allowed: ['trading', 'payment_plan', 'switch_plan'],
    // period select options for sparplan
    availableSavingsPlanPeriodicPlans: Object.entries(CONFIG).map(([code, config]) => {
      return {id: code, value: code, label: config['title']}
    }),
    ordering_savings_plan_till_date_enabled: true,
    // precision for rounding transactions values
    maxQtyDecimals: VIRTUAL_PORTFOLIO_QTY_DECIMALS
  }
}

export const getCombinedTradingsFromTransactions = (portfoliosTransactions) => {
  let updCombinedTradingSession = [];

  portfoliosTransactions.map(portfolioTransaction => {
    let instruments = [];

    // loop over transaction types
    ['buy', 'sell', 'switch', 'savings_plan'].map(transactionKey => {
      portfolioTransaction.transactions[transactionKey].forEach(
        transactionData => {
          const instrumentId = transactionData.instrumentId || getInstrumentId(transactionData.data);
          if(instrumentId) {
            instruments.push({
              ...transactionData,
              instrumentId: instrumentId,
            })
          }
        }
      )
    });

    if(!_.isEmpty(instruments)) {
      updCombinedTradingSession.push({
        data: portfolioTransaction.data,
        instruments: instruments,
        portfolioId: portfolioTransaction.data.id,
      })
    }
  });

  return _.isEmpty(updCombinedTradingSession) ? undefined : updCombinedTradingSession
}


export const buildModelPortfoliosTransactionsFromAssets = (assets, depositFromDate, investedAmount=10000) => {

  return assets.map(a => {

    const weight = parseFloat(a.weight || '0') / 100;

    return {
      data: {
        ...a,
      },
      deposit_from_date: depositFromDate,
      deposit_from_type: '2', // TRANSACTION_START_DATE.date. Hardcoded value used due to importing issue.
      transaction_value: _.round(investedAmount * weight, 2)
    }
  });

}

export const buildDeleteOrderConfirmationMessage = (orders, allSelected) => {

  let message = `Möchten Sie die ${orders.length} ausgewählten Transaktionen wirklich löschen?`;

  if (allSelected) {
    message = 'Möchten Sie wirklich alle Transaktionen löschen?'
  } else if (orders.length === 1) {
    message = <>Möchten Sie wirklich die Transaktion <b>{_.get(orders, '0.asset_details.name')}</b> löschen?</>
  }

  return (
    <p>{message}</p>
  )

}

export const getInitVPTransactions = (instrumentList, profitAndLoss, paymentPlans) => {
    // get payment plans portfolios
    const portfoliosUpdated = preparePortfoliosForCombinedTrading({
      instrumentListPortfolios: instrumentList.data,
      profitAndLossPortfolios: profitAndLoss.data,
      savingPlansPortfolios: paymentPlans.data,
      banksMappingData: {},  // TODO: create virtual bank and put all config there
      isVirtual: true
    });

    // extends portfolios with transactions and enables trading
    return (portfoliosUpdated || []).map(p => getDummyPortfolioTransactions(p));
};