import { makeStyles } from '@material-ui/core';
import { FORM_FIELD_HEIGHT } from '../../../../containers/TransactionsMonitoring/components/Filters/components/common_styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
  label: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#4D4F5C',
    marginRight: 20,
    whiteSpace: 'nowrap',

    [theme.breakpoints.only('sm')]: {
      fontSize: 13
    },

    [theme.breakpoints.only('xs')]: {
      fontSize: 12
    },
  },
  expansionPannelContainer: {
    position: 'relative',
    height: FORM_FIELD_HEIGHT,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      height: 35,
    },
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.4,
  }
}))