import React from "react";
import moment from 'moment';

import {
  CustomerResource,
  PortfolioManagerResource,
  PortfolioHandlerResource,
  parseResponse
} from "../../../../utils/api";

import {
  getFromStorage
} from "../../../../utils/storage";

const defaultDataBody = {
  data: undefined,
  loading: true,
  errors: undefined
};

const OtherAssetsDataProvider = WrappedComponent => {
  return function DataProvider(props) {
    const {
      customerId
    } = props;

    const [customer, setCustomer] = React.useState(defaultDataBody);
    const [assetsData, setAssetsData] = React.useState(defaultDataBody);
    const [investmentData, setInvestmentData] = React.useState(defaultDataBody);
    const [unrealizedProfitLossData, setUnrealizedProfitLossData] = React.useState(defaultDataBody)

    let sessionCustomerId = getFromStorage('customer_id');
    let sessionSelectedStartDateKey = 'selected_start_date_'.concat(sessionCustomerId);
    let sessionSelectedEndDateKey = 'selected_end_date_'.concat(sessionCustomerId);
    let sessionSelectedPortfoliosKey = 'selected_portfolios_'.concat(sessionCustomerId);

    React.useEffect(() => {
      fetchData();
    }, []);

    const fetchData = () => {
      fetchCustomer();
      fetchInvestmentData();
      fetchAssets();
      fetchUnrealizedProfitLossData();
    };

    const getQueryParams = () => {
      let queryParams = {},
        startDate = getFromStorage(sessionSelectedStartDateKey),
        endDate = getFromStorage(sessionSelectedEndDateKey),
        depots = getFromStorage(sessionSelectedPortfoliosKey);

      if (startDate && endDate) {
        queryParams.start_date = moment(startDate).format('YYYY-MM-DD');
        queryParams.end_date = moment(endDate).format('YYYY-MM-DD');
      }

      if (depots) {
        queryParams.depot_id = depots.map(portfolio => portfolio.depotNumber);
      }

      return queryParams;
    };

    const fetchCustomer = async () => {
      setCustomer({
        ...customer,
        loading: true
      });
      try {
        let customer = await CustomerResource.at(`${customerId}/`).get();
        setCustomer({
          data: customer,
          loading: false,
          errors: undefined
        });

      } catch (errors) {
        setCustomer({
          data: undefined,
          loading: false,
          errors
        });
      }
    };

    const fetchAssets = async () => {
      setAssetsData({
        ...assetsData,
        loading: true
      });

      try {

        let response = await PortfolioManagerResource.at(`customer/${customerId}/other-assets/`).get({
          clearing_account: true,
          ...getQueryParams()
        });

        setAssetsData({
          data: response,
          loading: false
        });

      } catch (errors) {
        setAssetsData({
          data: undefined,
          loading: false,
          errors
        })
      }
    };

    const fetchInvestmentData = async () => {
      setInvestmentData({
        ...investmentData,
        loading: true
      });

      try {

        let response = await PortfolioManagerResource.at(
          `customer/${customerId}/dashboard/`).get(getQueryParams());

        setInvestmentData({
          data: response.data,
          loading: false,
          errors: undefined
        });
      }
      catch(errors) {
        setInvestmentData({
          data: undefined,
          loading: false,
          errors
        })
      }
    };

    const fetchUnrealizedProfitLossData = async () => {
      setUnrealizedProfitLossData({
        ...unrealizedProfitLossData,
        loading: true
      });

      try {

        const queryParams = getQueryParams();

        const response = await PortfolioHandlerResource.getTransactionsData(customerId, queryParams.depot_id, queryParams.start_date, queryParams.end_date)

        parseResponse(response, 'transactions', (data) => {
          setUnrealizedProfitLossData({
            data,
            loading: false,
            errors: undefined
          });
        }, (errors) => {
          setUnrealizedProfitLossData({
            data: undefined,
            loading: false,
            errors
          });
        });

      }
      catch(errors) {

        setUnrealizedProfitLossData({
          data: undefined,
          loading: false,
          errors
        });
      }
    };

    return (
      <WrappedComponent
        customerId={customerId}
        customer={customer}
        assetsData={assetsData}
        investmentData={investmentData}
        unrealizedProfitAndLoss={unrealizedProfitLossData}
        onUpdate={fetchData}
      />
    )
  }
};

export default OtherAssetsDataProvider;