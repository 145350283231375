import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import {
  Grid,
  CircularProgress, IconButton
} from '@material-ui/core'

import {
  FormCenterHandlerResource,
  OnboardingProtocolHandlerResource,
  QuestionnairesHandlerResource
} from '../../../../../../../../utils/api'
import { displayErrorSnackBar } from '../../../../../../../../components/SnackbarProvider/actions'
import ProtocolPreview from '../../../../../ProtocolPreview'

import {BankDocuments} from './components'
import {DocumentsFilterModal} from './components'

import useStyles from './styles'
import FormLabelElement from "../../../formElement/LabelFormElement/LabelFormElement";
import FormHelperText from "@material-ui/core/FormHelperText";
import CheckboxQuestion from "../../../question/CheckboxQuestion/CheckboxQuestion";
import {SERVICE_CONCEPTS} from "../../../../../../../Trades/constants";
import {getErrorMessage, RenderStepLoadingMessage,} from "../../../../../../../../utils/utils";
import {prepareDocumentsFilterData} from '../../../../../../utils'
import {ArrowDropDownCircle} from "@material-ui/icons";
import BanksHint from './BanksHint';
import {DepotDocumentIdentifier, DepotDocumentIdentifiersList, singleDepotSelected} from "./utils";
import {OBJECT_TYPES} from "../../../../../../constants";
import {
  useEinzeltitelProcessConfirmationModalContext
} from "../../../../../EinzeltitelsProcessConfirmationProvider/EinzeltitelsProcessConfirmationProvider";
import {getFromStorage, setInStorage, STORAGE_TYPE} from "../../../../../../../../utils/storage";


export const GENERAL_DOCUMENTS_BANK_NAME = 'Allgemeine Dokumente'
export const GENERAL_DOCUMENTS_BANK_ID = 'general'

export const ONBOARDING_DEPOT_ID = 'onboarding' // We need predefined depot id for onboarding process, as we do not have correct depot at this point yet

export const QUESTIONNAIRE_FILE_NAME = {
  [SERVICE_CONCEPTS.Anlageberatung]: 'Risikoprofilierung',
  [SERVICE_CONCEPTS.Anlagevermittlung]: 'Kundenprofil'
}

const GENERAL_DOCUMENTS_DEPOT = {
  portfolioId: GENERAL_DOCUMENTS_BANK_ID,
  data: {
    id: GENERAL_DOCUMENTS_BANK_ID
  },
  name: GENERAL_DOCUMENTS_BANK_NAME
}

export default connect()(function DocumentsDownload(props) {

  const {
    questions: [
      selectedDocuments,
      waysOfDistribution
    ],
    dataService,
    onAnswerChange,
    dispatch
  } = props;

  const classes = useStyles()
  const modalContext = useEinzeltitelProcessConfirmationModalContext();

  const [documentsData, setDocumentsData] = React.useState({
    data: null,
    loading: false,
    errors: null
  })
  const [previewDocument, setPreviewDocument] = React.useState(undefined)
  const [previewIsVisible, setPreviewIsVisible] = React.useState(false)
  const [previewDocumentContent, setPreviewDocumentContent] = React.useState({
    content: undefined,
    error: undefined,
    loading: false
  })

  const [documentsToDownload, setDocumentsToDownload] = React.useState(
    DepotDocumentIdentifiersList.fromJSON(_.get(selectedDocuments.answer, 'download') || {}));
  const [documentsToSign, setDocumentsToSign] = React.useState(
    DepotDocumentIdentifiersList.fromJSON(_.get(selectedDocuments.answer, 'eSign') || {}));
  const [documentsToStore, setDocumentsToStore] = React.useState(
    DepotDocumentIdentifiersList.fromJSON(_.get(selectedDocuments.answer, 'store') || {}));

  const SETTERS = {
    download: setDocumentsToDownload,
    eSign: setDocumentsToSign,
    store: setDocumentsToStore
  }

  const [documentsFilterModal, setDocumentsFilterModal] = React.useState({
    open: false,
    bankId: undefined,
    objectType: undefined,
    data: {'data': []},
    selectedDocuments: []
  })

  const changePreviewVisibility = () => {
    const isContentExists = !!previewDocument && !!previewDocumentContent.content;
    // if loading - show; if no content - hide; otherwise - switch visibility
    setPreviewIsVisible(previewDocumentContent.loading || (isContentExists && !previewIsVisible));
  };

  React.useEffect(() => {
    fetchBankDocuments()
  }, [])

  React.useEffect(() => {
    if (previewDocument) {
      fetchPreviewDocumentContent()
    }
  }, [previewDocument])

  React.useEffect(() => {
    // if something is wrong with the document content preview must hide
    if(_.isNil(previewDocumentContent.content) && !previewDocumentContent.loading){
      setPreviewIsVisible(false)
    }
  }, [previewDocumentContent])

  React.useEffect(() => {
    onAnswerChange(selectedDocuments.uid, {
      download: documentsToDownload.toJSON(),
      eSign: documentsToSign.toJSON(),
      store: documentsToStore.toJSON()
    })
  }, [documentsToSign, documentsToDownload, documentsToStore]);

  React.useEffect(() => {

    if (!documentsData.data) {
      return
    }

    if (
      // at first component render,
      // all this list are empty.
      // check it to prevent changes
      // checkboxes values after
      // adding new documents from
      // documents filter.
      documentsToDownload.length +
      documentsToSign.length +
      documentsToStore.length == 0
    ) {

      const docToStore = new DepotDocumentIdentifiersList(); // all selected optional documents
      const docToDownload = new DepotDocumentIdentifiersList();
      const docToSign = new DepotDocumentIdentifiersList();

      for (let [objectType, depotDocuments] of Object.entries(documentsData.data)) {
        for (let [bankId, documents] of Object.entries(depotDocuments)) {
          ((documents.required || []).concat(documents.general || [])).forEach(document => {
            if (document.is_mandatory || document.is_default_checked) {
              docToDownload.push(new DepotDocumentIdentifier(bankId, document.id, objectType))
            }
            if (document.sign_possible) {
              docToSign.push(new DepotDocumentIdentifier(bankId, document.id, objectType))
            }
          });
          (documents.defaultOptionalDocuments || [])
            .forEach(d => docToStore.push(new DepotDocumentIdentifier(bankId, d.id, objectType)));

          // all online documents goes to E-Sign
          (documents.onlineDocuments || [])
            .forEach(d => docToSign.push(new DepotDocumentIdentifier(bankId, d.external_id, objectType)));
        }
      }


      setDocumentsToDownload(docToDownload);
      setDocumentsToSign(docToSign);
      setDocumentsToStore(docToStore);
    }
  }, [documentsData]);

  React.useEffect(() => {

    (async () => {
      if (!documentsData.data) {
        return
      }

      const data = dataService.investedInstrumentsGroupedByCustodian;

      if (_.isEmpty(data)) {
        return;
      }

      const skipConfirmationsConfigurationStorageKey = `${dataService.user.broker_id}_einzeltitels_confirmations_configuration`;
      const skipConfirmationsConfiguration = getFromStorage(skipConfirmationsConfigurationStorageKey, STORAGE_TYPE.LOCAL) || {};

      for (let [custodianId, custodianData] of Object.entries(data)) {
        if (custodianData.hasEinzeltitels && custodianData.einzeltitelsSubmissionMessage && !skipConfirmationsConfiguration[custodianId]) {
          skipConfirmationsConfiguration[custodianId] = await modalContext.confirm(custodianData.einzeltitelsSubmissionMessage);
        }
      }

      setInStorage(skipConfirmationsConfigurationStorageKey, skipConfirmationsConfiguration)

    })()

  }, [documentsData])

  /**
   * Fetch documents for specific depot.
   * @param depot {{data: {id: Number}}} Depot data
   * @returns {Promise<void>}
   * @private
   */
  const _fetchDepotDocuments = async (depot, objectType=GENERAL_DOCUMENTS_BANK_ID, withGeneralDocuments=true, generalDocumentsOnly=false) => {

    const depotId = _.get(depot, 'data.id')
    if (!depotId) {
      return undefined
    }

    const response = await FormCenterHandlerResource
      .getTradingDocuments(dataService.onboarding_uid, depotId, objectType, withGeneralDocuments, generalDocumentsOnly)

    return {
      documents: response,
      depot,
      objectType
    }
  }

  const _parseDepotDocuments = async (response) => {

    const _documents = {}

    await Promise.all(response.map(async ({depot, documents, objectType}) => {

      _.defaults(_documents, {[objectType]: {}})

      const bankData = _.find(_.isArray(dataService.bank)
        ? dataService.bank
        : Array(dataService.bank),
        (b) => b.custodian_id == depot.companyId) || {}

      const isOnline = documents.is_online;

      let requiredDocuments = documents.form_center.filter(d => d.is_mandatory)
      let optionalDocuments = documents.form_center.filter(d => !d.is_mandatory)
      let defaultOptionalDocuments = documents.form_center.filter(d => d.to_optional_list)

      if (isOnline) {
        selectedDocuments.documents = {
          ...selectedDocuments.documents,
          [depot.portfolioId]: [...requiredDocuments]
        }
      } else {
        requiredDocuments = [
          ...requiredDocuments,
          ...documents.legitimations,
          ...documents.proof_of_guardianship]
      }

      selectedDocuments.documents = {
        ...selectedDocuments.documents,
        [depot.portfolioId]: [...requiredDocuments]
      }

      let selectedOptional = optionalDocuments
        .filter(d => documentsToStore.includes(new DepotDocumentIdentifier(depot.portfolioId, d.id, objectType)))

      _documents[objectType][depot.portfolioId] = {
        required: requiredDocuments,
        allOptional: optionalDocuments,
        optional: !_.isEmpty(selectedOptional) ? selectedOptional : defaultOptionalDocuments,
        defaultOptionalDocuments: defaultOptionalDocuments,
        name: depot && depot.name || depot.data.name,
        onlineDocuments: documents.online || [],
        isOnline: isOnline,
        filterData: prepareDocumentsFilterData(optionalDocuments),
        additionalBankText: bankData.document_selection_additional_info,
        general: documents.general,
        documentsToValidate: _.flatten([requiredDocuments, documents.general || []]) // list of all documents, that should be used for answer validation
      }

    }))

    return _documents

  }

  const _processTransactionsExists = (depot, processTransactionsKeys) => {
    return _.some(processTransactionsKeys, (transactionKey) => !_.isEmpty(depot.transactions[transactionKey]))
  }

  const fetchDocuments = async () => {
    if (dataService.is_trading) {
      if (dataService.objectType === OBJECT_TYPES.COMBINED) {
        const processes = {
          [OBJECT_TYPES.TRADING]: ['buy', 'sell', 'switch'],
          [OBJECT_TYPES.SAVINGS_PLAN]: ['savings_plan'],
          [OBJECT_TYPES.PAYOUT_PLAN]: ['payout_plan'],
          [OBJECT_TYPES.SWITCH_PLAN]: ['switch_plan'],
        }

        const singleDepotSelected = dataService.investedDepots.length === 1
        const singleProcessSelected = (() => {

          for (let depot of dataService.investedDepots) {

            const processTransactionsExistBooleans = Object.entries(processes).map(([objectType, transactionsKeys]) => {
              return _processTransactionsExists(depot, transactionsKeys)
            })

            if (_.filter(processTransactionsExistBooleans, (value) => value).length > 1) {
              return false
            }
          }

          return true
        })()

        let _promises = []
        // In case multiple business cases will be created - separate business
        // case for general documents should be created also
        if (!singleDepotSelected || !singleProcessSelected) {
          _promises.push(_fetchDepotDocuments(GENERAL_DOCUMENTS_DEPOT, undefined, true, true))
        }

        _promises = _promises.concat(_.flatten(Object.entries(processes).map(([objectType, transactionsKeys]) => {

          return _.flatten(dataService.investedDepots.map((depot) => {
            // In case depot does not have any transaction from specific
            // object type - don`t retrieve any document
            if (_.every(transactionsKeys, (transactionKey) => _.isEmpty(depot.transactions[transactionKey]))) {
              return []
            }

            return _fetchDepotDocuments(depot, objectType, singleDepotSelected && singleProcessSelected)

          }))
        })))

        return await Promise.all(_promises)
      }
      return await Promise.all(dataService.investedDepots.map((depot) => _fetchDepotDocuments(depot, undefined)))
    }

    return [{
      depot: {...dataService.bank, portfolioId: ONBOARDING_DEPOT_ID, companyId: dataService.bank.custodian_id}, // We do not have depot in case of onboarding, so use bank instead
      documents: await FormCenterHandlerResource.getOnboardingDocuments(dataService.onboarding_uid),
      objectType: dataService.objectType || 'general'
    }];
  }

  const isQuestionRequired = (question) => question.hasOwnProperty('optional') ? !question.optional : true

  const fetchBankDocuments = async () => {

    if (!dataService.bank) {
      return
    }

    try {

      setDocumentsData({
        data: null,
        loading: true,
        errors: null
      })

      let response = await fetchDocuments()
      const documents = await _parseDepotDocuments(response)
      selectedDocuments.documents = {
        ...documents
      }
      setDocumentsData({
        data: {...documents},
        loading: false,
        errors: null
      })

    } catch (error) {
      throw error
      setDocumentsData({
        data: null,
        loading: false,
        errors: error
      })
      dispatch(displayErrorSnackBar('Beim Laden der Bankdokumente ist leider ein Fehler aufgetreten'))
    }
  }

  const fetchPreview = async () => {

    // Form Center documents has id as a number
    if (_.isNumber(previewDocument.id)) {
      return await FormCenterHandlerResource.getBankDocumentContent(
        previewDocument.id, dataService.onboarding_uid,
        previewDocument.depotId != ONBOARDING_DEPOT_ID && previewDocument.depotId,
        previewDocument.depotId != ONBOARDING_DEPOT_ID && previewDocument.objectType)
    }

    switch(previewDocument.id) {
      case 'protocol': {
        let protocolParams = {
          'customer_id': dataService.customer_id,
          'onboarding_uid': dataService.onboarding_uid,
        }
        return await OnboardingProtocolHandlerResource.getProtocolContent(protocolParams)
      }
      case 'risk_profile': {
        let questionnaireParams = {
          'customer_id': dataService.customer_id,
          'session_id': dataService.onboarding_uid
        }
        return await OnboardingProtocolHandlerResource.getQuestionnaireContent(questionnaireParams)
      }
      case 'product_information': {
        let questionnaireParams = {
          'session_id': dataService.onboarding_uid,
        }
        return await OnboardingProtocolHandlerResource.getProductInformationContent(questionnaireParams)
      }
      case 'esg': {
        let esgParams = {
          'customer_id': dataService.customer_id,
          'session_id': dataService.onboarding_uid
        }
        return await OnboardingProtocolHandlerResource.getEsgDocumentContent(esgParams)
      }
      case 'online': {
        return await OnboardingProtocolHandlerResource.getBankOnlineDocumentContent(
          dataService.onboarding_uid, previewDocument.external_id, previewDocument.objectType !== GENERAL_DOCUMENTS_BANK_ID
            ? previewDocument.objectType
            : undefined)
      }
      case 'proof_of_guardianship': {
        return await QuestionnairesHandlerResource.getDocument(previewDocument.documents[0])
      }
      default: {
        let legitimationParams = {
          'customer_id': dataService.customer_id,
          'filename': previewDocument.name,
          'documents': previewDocument.documents
        }
        return await OnboardingProtocolHandlerResource.getLegitimationDocumentsContent(legitimationParams)
      }
    }

  }

  const fetchPreviewDocumentContent = async () => {
    try {

      setPreviewDocumentContent({
        loading: true,
        content: undefined,
        error: undefined
      })

      let response = await fetchPreview()

      setPreviewDocumentContent({
        content: response,
        loading: false,
        error: undefined
      })

    } catch (error) {
      setPreviewDocumentContent({
        loading: false,
        content: undefined,
        error
      })
      setPreviewDocument(undefined)
      let errorMsg = getErrorMessage(error) || '';
      dispatch(displayErrorSnackBar(`Beim Laden der Vorschau für "${previewDocument.name}" ist ein Fehler aufgetreten. ${errorMsg}`))
    }
  }

  const onDistributionSettingsChange = (documentId, distributionType, checked, bankId, objectType) => {

    const documentIdentifier = new DepotDocumentIdentifier(bankId, documentId, objectType)

    if (selectedDocuments.errors && _.get(selectedDocuments.errors, `${objectType}.${bankId}.${documentId}`, false)){
      delete selectedDocuments.errors[objectType][bankId][documentId]
    }

    SETTERS[distributionType]((prevDocumentIdentifiers) => prevDocumentIdentifiers.toggleDocumentIdentifier(documentIdentifier))

  }

  const onSetDocumentPreview = (document, depotId, objectType) => {
    setPreviewDocument({...document, depotId, objectType});
    setPreviewIsVisible(true) // when new document is selected preview should be shown
  };

  const getSelectedOptionalDocuments = (bankId, objectType) => {
    let selectedDocs = _.get(documentsData.data, `${objectType}.${bankId}.optional`, []).map(doc => doc.id)
    return _.get(documentsData.data, `${objectType}.${bankId}.filterData.data.[0].documents`, [])
      .filter(doc => selectedDocs.includes(doc.id) || documentsToStore.includes(new DepotDocumentIdentifier(bankId, doc.id, objectType)))
  }

  const onDocumentFilterModalSwitch = (bankId, objectType) => {
    if (bankId !== undefined) {
      setDocumentsFilterModal(prevState => ({
        open: !prevState.open,
        bankId,
        objectType,
        data: _.get(documentsData.data, `${objectType}.${bankId}.filterData`, {'data': []}),
        selectedDocuments: getSelectedOptionalDocuments(bankId, objectType)
      }))
    } else {
      setDocumentsFilterModal({
        open: false,
        bankId: undefined,
        objectType: undefined,
        data: {'data': []},
        selectedDocuments: []
      })
    }
  }

  const onConfirmDocumentsFilter = (bankId, documents, objectType) => {
    let bankData = documentsData.data[objectType][bankId]

    bankData.optional = [
      ...bankData.optional,
      ...bankData.allOptional.filter(doc => documents.includes(doc.id))
    ]

    documents.forEach(docId => {
      SETTERS['store']((prevDocumentIdentifiers) => {
        return prevDocumentIdentifiers.toggleDocumentIdentifier(new DepotDocumentIdentifier(bankId, docId, objectType))
      })
    })

    setDocumentsData(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [objectType]: {
          ...prevState.data[objectType],
          [bankId]: bankData
        }
      }
    }))
  }

  const onDeleteOptionalDocument = (bankId, documentId, objectType) => {
    documentsData.data[objectType][bankId].optional = _.get(documentsData.data, `${objectType}.${bankId}.optional`)
      .filter(doc => doc.id !== documentId)

    for (let distributionType of Object.keys(SETTERS)) {
      SETTERS[distributionType]((prevDocumentIdentifiers) => prevDocumentIdentifiers.remove(new DepotDocumentIdentifier(bankId, documentId, objectType)))
    }

    setDocumentsData(prevState => ({
      ...prevState,
      ...documentsData
    }))
  }

  const renderDocuments = () => {
    if (!documentsData.data) {
      return
    }

    return (
      <BankDocuments
        banks={documentsData.data}
        setPreviewDocument={onSetDocumentPreview}
        onDocumentFilterModalSwitch={onDocumentFilterModalSwitch}
        onDeleteOptionalDocument={onDeleteOptionalDocument}
        downloadDocuments={documentsToDownload || []}
        eSignDocuments={documentsToSign || []}
        onDistributionSettingsChange={onDistributionSettingsChange}
        errors={selectedDocuments.errors}
      />
    )
  }

  // in case we have more banks than 1, it will be rendered for each bank separately
  const renderBanksHint = () => {
    if(!documentsData.data)
      return

    if (!singleDepotSelected(documentsData.data)) {
      return
    }

    return Object.keys(documentsData.data).map((objectType) => {
      return _.filter(Object.keys(documentsData.data[objectType]), (bank_id) => bank_id != GENERAL_DOCUMENTS_BANK_ID)
        .map((bank_id) => (
          <BanksHint
            bankName={documentsData.data[objectType][bank_id].name}
            isBankOnline={documentsData.data[objectType][bank_id].isOnline}
            additionalBankText={documentsData.data[objectType][bank_id].additionalBankText}
          />
        ))
    })
  }

  if(documentsData.loading){
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress className={classes.loadingIndicator}/>
        {RenderStepLoadingMessage(_.get(dataService, 'step'))}
      </div>
    );
  }

  return (
    <div>
      <Grid item xs={12} style={{marginTop: 15}}>
        <FormLabelElement
          error={waysOfDistribution.error}
          label={waysOfDistribution.question_text}
          required={isQuestionRequired(waysOfDistribution)}
          customClasses={{labelRoot: 'bold'}}
        />
        {waysOfDistribution.error && (
          <FormHelperText error={true}>{waysOfDistribution.error}</FormHelperText>
        )}
        {waysOfDistribution.question.map(q => (
          <Grid key={q.uid} item xs={12} className={classes.checkboxRegularContainer}>
            <CheckboxQuestion
              question={q}
              onAnswerChange={(uid, value) => {
                waysOfDistribution.error = null; // clean error
                onAnswerChange(uid, value);
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} style={{marginTop: 15}}>
        <FormLabelElement
          label={`Zusätzlich zu den gewählten Zustelloptionen werden alle Dokumente in Ihre Postbox eingestellt.`}
          customClasses={{
            labelRoot: classes.helpText
          }}
        />
      </Grid>
      {renderBanksHint()}

      <Grid container style={{marginTop: 30}}>

        <Grid item xs={12} md={previewIsVisible ? 6 : 12} className={classes.collapsedTransition}>
          <Grid container>
            { renderDocuments() }
          </Grid>
        </Grid>
        <div className={classes.collapsBtnWrapper}>
          <IconButton className={classes.collapsBtn} onClick={changePreviewVisibility}>
            <ArrowDropDownCircle fontSize={"large"} className={classes.collapsedTransition}
                                 color={previewDocumentContent.content || previewDocumentContent.loading ? "primary" : 'disabled'}
                                 style={previewIsVisible? {transform: 'rotate(-90deg)'} : {transform: 'rotate(90deg)'}}/>
          </IconButton>
        </div>
        {/* maxWidth need to be animated */}
        <Grid item xs={12} md={6} style={previewIsVisible ? {display: 'flex', justifyContent: 'flex-end'} : {maxWidth: 0, overflow: "hidden"}} className={classes.collapsedTransition}>
          {previewDocumentContent.content && !previewDocumentContent.loading && !previewDocumentContent.error && (
            <ProtocolPreview content={previewDocumentContent.content}  width="100%" height={575}/>
          )}
          {previewDocumentContent.loading && (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 464, width: 420}}>
              <CircularProgress color="primary" size={45}/>
            </div>
          )}
        </Grid>

      </Grid>
      <DocumentsFilterModal
        objectType={documentsFilterModal.objectType}
        bankId={documentsFilterModal.bankId}
        banksData={documentsFilterModal.data}
        open={documentsFilterModal.open}
        handleConfirm={onConfirmDocumentsFilter}
        handleSwitch={onDocumentFilterModalSwitch}
        activeDocuments={documentsFilterModal.selectedDocuments}
      />
    </div>
  )
})
