import React from 'react';
import _ from 'lodash';

import withTheme from '@material-ui/core/styles/withTheme';
import BaseChart from '../../../../../../FactSheetsPage/components/BaseChart';

import {
  hasResponseError,
} from '../../../../../../../utils/utils';
import {CHART_COLORS} from '../../../../../constants';
import useStyles from '../styles';

const PortfolioRegionStructure = (props) => {
  const {
    dashboardData,
    loading
  } = props;

  const classes = useStyles();

  const portfolioStructure = dashboardData && dashboardData.portfolio_structure;

  const dataError = _.isNil(portfolioStructure) || hasResponseError(portfolioStructure);

  let data = [], byCountry = [];
  if (portfolioStructure) {
    data = portfolioStructure.by_region;
    byCountry = portfolioStructure.by_country;
  }

  return (
    <BaseChart
      chartType='region'
      title={data.length !== 1 ? 'Regionen' : 'Region'}
      seriesData={data}
      extraData={byCountry}
      loading={loading}
      colors={CHART_COLORS}
      error={dataError}
      classes={{
        title: (!loading && !data.length) ? classes.titleWithoutData : classes.title,
      }}
      convertToGerman={false}
      height={400}
    />
  );

};

export default withTheme(PortfolioRegionStructure);
