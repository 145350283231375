import {makeStyles} from "@material-ui/core/styles";
import images from "../../../../../../../images";

const useStyles = makeStyles(theme => ({
  questionContainer: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between'
  },
  errorContainer: {
    fontFamily: 'Roboto-Bold',
    padding: '0 1rem',
    fontSize: 16,
    margin: '0 1rem',
  },
  item: {
    width: 390,
    height: 340,
    border: '1px solid #D8DCDF',
    borderRadius: 4,
    padding: 16,
    margin: 16,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: 16,
    marginBottom: 16,
    paddingRight: 16
  },
  name: {
    position: 'relative',
  },
  selected: {
    fontFamily: 'Roboto-Bold',
    border: 'none',
    boxShadow: '0px 3px 15px #00000033',

    '& $name::after': {
      position: 'absolute',
      content: '""',
      width: 30,
      height: 30,
      top: '50%',
      left: '100%',
      transform: 'translate(15px, -15px)',
      backgroundImage: `url(${images.select_list_icon})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain'
    }
  },
  chartImage: {
    width: '100%',
    flex: 1,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center'
  },
}));

export default useStyles;