import React from "react";
import {Grid} from "@material-ui/core";


// items
import ProViewKeyIndicatorsCard from './items/ProViewKeyIndicatorsCard';
import ProViewRollingSharpeRatioChart from './items/ProViewRollingSharpeRatioChart';
import ProViewRollingVolatilityChart from './items/ProViewRollingVolatilityChart';
import ProViewEsgScoreChart from './items/ProViewEsgScoreChart';

const KeyFiguresTab = props =>{

  const {
    customerId,
    portfolioId,
    investmentStrategyId,
    investmentStrategyModelPortfolioConnectId,
    portfolioName,
    selectedAssetsName,
    expandedItems,
    onExpandedItemsChange,
    selectedDates,
    investmentData,
    lastBenchmarkUpdateTime,
    chartsSettings,
    dataProvider,
    isVirtual,
    customSettings,
    handleShowChartSection,
    selectedAssets,
    isAllAssetsSelected
  } = props;

  const showInvestment = chartsSettings.performance.withBenchmark
  const benchmarks = chartsSettings.performance.currently_selected_benchmarks.map(b => {
    b.percentage = parseInt(b.percentage)
    return b
  })

  const dates = {
    start: selectedDates.start && selectedDates.start.format('YYYY-MM-DD') || undefined,
    end: selectedDates.end && selectedDates.end.format('YYYY-MM-DD') || undefined,
  };

  return (
    <Grid container spacing={2}>
      {handleShowChartSection(customSettings, 'keyIndicatorsChart') &&
        <Grid item xs={12}>
          <ProViewKeyIndicatorsCard
            customerId={customerId}
            investmentStrategyId={investmentStrategyId}
            portfolioId={portfolioId}
            startDate={dates.start}
            endDate={dates.end}
            expanded={expandedItems.keyIndicatorsChart}
            onExpandedClick={ (newState) => onExpandedItemsChange('keyIndicatorsChart', newState)}
            showBenchmark={showInvestment}
            investmentData={investmentData.data}
            riskBenchmark={lastBenchmarkUpdateTime}
            withHistoricalPortfolios={chartsSettings.global.withHistoricalPortfolios}
            benchmarks={benchmarks}
            dataProvider={dataProvider}
            selectedAssets={selectedAssets}
            isAllAssetsSelected={isAllAssetsSelected}
          />
        </Grid>
      }
      {handleShowChartSection(customSettings, 'rollingVolatilityChart') &&
        <Grid item xs={12} sm={6}>
          <ProViewRollingVolatilityChart
            customerId={customerId}
            investmentStrategyId={investmentStrategyId}
            portfolioId={portfolioId}
            portfolioName={selectedAssetsName || portfolioName}
            startDate={dates.start}
            endDate={dates.end}
            expanded={expandedItems.rollingVolatilityChart}
            onExpandedClick={ (newState) => onExpandedItemsChange('rollingVolatilityChart', newState)}
            showBenchmark={showInvestment}
            riskBenchmark={lastBenchmarkUpdateTime}
            benchmarks={benchmarks}
            dataProvider={dataProvider}
            selectedAssets={selectedAssets}
            isAllAssetsSelected={isAllAssetsSelected}
          />
        </Grid>
      }
      {handleShowChartSection(customSettings, 'rollingSharpeRatioChart') &&
        <Grid item xs={12} sm={6}>
          <ProViewRollingSharpeRatioChart
            customerId={customerId}
            investmentStrategyId={investmentStrategyId}
            portfolioId={portfolioId}
            portfolioName={selectedAssetsName || portfolioName}
            startDate={dates.start}
            endDate={dates.end}
            expanded={expandedItems.rollingSharpeRatioChart}
            onExpandedClick={ (newState) => onExpandedItemsChange('rollingSharpeRatioChart', newState)}
            showBenchmark={showInvestment}
            riskBenchmark={lastBenchmarkUpdateTime}
            benchmarks={benchmarks}
            dataProvider={dataProvider}
            selectedAssets={selectedAssets}
            isAllAssetsSelected={isAllAssetsSelected}
          />
        </Grid>
      }
      {handleShowChartSection(customSettings, 'esgScoreChart') &&
        <Grid item xs={12}>
          <ProViewEsgScoreChart
            customerId={customerId}
            // TODO: Check if esg should be retrieved for IS or MP
            portfolioId={investmentStrategyModelPortfolioConnectId || portfolioId}
            expanded={expandedItems.esgScoreChart}
            onExpandedClick={ (newState) => onExpandedItemsChange('esgScoreChart', newState)}
            withHistoricalPortfolios={chartsSettings.global.withHistoricalPortfolios}
            dataProvider={dataProvider}
            isVirtual={isVirtual}
            selectedAssets={selectedAssets}
            isAllAssetsSelected={isAllAssetsSelected}
          />
        </Grid>
      }
    </Grid>
  )
}

export default KeyFiguresTab;
