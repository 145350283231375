import React from "react";
import { Grid, withStyles } from "@material-ui/core";

import KeyFiguresTable from './items/KeyFiguresTable'
import RollingVolatilityChart from "./items/RollingVolatilityChart";
import RollingSharpeRatioChart from "./items/RollingSharpeRatioChart";
import EsgScoreChart from "./items/EsgScoreChart";
import styles from "./styles";
import RiskReturnChart from "./items/RiskReturnChart";


class KeyFiguresAndRatingsTab extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      keyFiguresTable: false,
      rollingVolatilityChart: false,
      rollingSharpeRatioChart: false,
    };
  }

  render() {
    return <Grid container spacing={2}>
      <Grid item xs={12}>
        <KeyFiguresTable
          title={'Kennzahlen'}
          data={this.props.data}
        />
      </Grid>
      <Grid item xs={12}>
        <RiskReturnChart
          data={this.props.data}
        />
      </Grid>
      {/*Show esg score chart in old place in case new tab is disabled */}
      {!this.props.esgEnabled && (
        <Grid item xs={12}>
          <EsgScoreChart
            assetInfo={this.props.data}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <RollingVolatilityChart
          assetInfo={this.props.data}
        />
      </Grid>
      <Grid item xs={6}>
        <RollingSharpeRatioChart
          assetInfo={this.props.data}
        />
      </Grid>
    </Grid>
  }
}

export default withStyles(styles)(KeyFiguresAndRatingsTab);
