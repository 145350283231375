import React from 'react';

import DashboardCard from "../../DashboardCard";
import RiskChart from "../RiskChart";

import { BENCHMARK_RISK_METRIX_ERROR, EMPTY_BENCHMARK_MESSAGE } from '../constants';
import useStyles from '../styles';

const VolatilityChartComponent = (props) => {
  const {
    title='Schwankungsbreite der Gesamtdepots',
    volatility,
    benchmarkVolatility,
    expandable,
    expanded,
    onExpanded,
    withoutExpandButton,
    fullTitle,
    error,
    loading
  } = props;

  const classes = useStyles();

  const renderChart = () => {
    const isDataExist = volatility && volatility.risk_metrics;
    if (error || !isDataExist) {
      return null;
    }
    const value = volatility.risk_metrics['volatility_annual'] * 100;

    const benchmarkValue = benchmarkVolatility.risk_metrics && benchmarkVolatility.risk_metrics['volatility_annual'] * 100;
    const benchmarkLabel = benchmarkVolatility.name;

    const categories = ['Schwankungs-breite'];
    const max = Math.max(benchmarkValue ? Math.round(benchmarkValue) : 0, value) * 2;

    return (
      <div>
        {benchmarkVolatility.without_benchmark && (
          <p className={classes.benchmarkErrorMessage}>{EMPTY_BENCHMARK_MESSAGE}</p>
        )}
        {!benchmarkValue && !benchmarkVolatility.without_benchmark && (
          <p className={classes.benchmarkErrorMessage}>{BENCHMARK_RISK_METRIX_ERROR}</p>
        )}
        <RiskChart
          id='volatility-chart'
          value={value}
          benchmarkValue={benchmarkValue}
          benchmarkLabel={benchmarkLabel}
          categories={categories}
          max={max}
          min={0}
        />
      </div>
    )
  };

  return (
    <DashboardCard
      id='volatility-chart'
      title={title}
      error={error}
      content={renderChart()}
      style={{height: 'auto'}}
      loading={loading}
      expandable={expandable}
      expanded={expanded}
      onExpanded={onExpanded}
      fullTitle={fullTitle}
      withoutExpandButton={withoutExpandButton}
      loadingContent={props.loadingContent}
      chart
      empty={!volatility}
      headerAlignCenter
    />
  );

};

export default VolatilityChartComponent;
