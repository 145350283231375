import { makeStyles } from '@material-ui/core'

export const styles = theme => ({
  link: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    '&:hover': {
      cursor: 'pointer',
    },

    '&:hover $title': {
      fontFamily: 'Roboto-Bold !important'
    }
  },
  linkDisabled: {
    color: '#ADB6BD',
    textDecoration: 'none',
    cursor: 'initial',
    pointerEvents: 'none'
  },
});

export default makeStyles(styles);