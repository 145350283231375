import React from 'react'
import PropTypes from 'prop-types'

import {
  Grid
} from '@material-ui/core'

import {
  Widget,
  WidgetBody,
  WidgetHeader,
  DropdownInput,
  InstrumentsFilterList
} from '../../../../Modelportfolios/components'
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import SearchButton from '../../../../../components/Buttons/SearchButton';

import useStyles from './styles'
import { FilterIcon, SearchIcon } from '../../../../../images';
import _ from 'lodash';

const DEFAULT_INPUT_ICON_COLOR = 'white'

function InstrumentsFilter(props) {

  const { 
    instruments, 
    loading,
    onFiltersExtendedButtonClick,
    onSearchActivate,
    onAddAssetClick,
    instrumentsDisabled,
    errors,
    disableDropdown
  } = props;

  const classes = useStyles();

  const [searchValue, setSearchValue] = React.useState();

  const handleSearchActivate = (value) => {
    if (onSearchActivate) {
      onSearchActivate(value)
    }
  }

  const getErrorContent = () => {
    console.log(errors)
    if (errors.status) {
      return errors.statusText
    } 
    return errors
  }

  const hasDropdownOptions = () => {
    return errors || !_.isEmpty(instruments)
  }

  return (
    <Widget>
      <WidgetHeader>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <h2>Investment hinzufügen</h2>
          </Grid>
        </Grid>
      </WidgetHeader>
      <WidgetBody>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <DropdownInput
            width={480}
            fullWidth={false}
            value={searchValue}
            placeholder="Instrumentenname, ISIN, oder WKN suchen"
            icon={<SearchIcon color={DEFAULT_INPUT_ICON_COLOR} />}
            dropdownContent={(
              <>
                {errors ? (
                  <div className={classes.errorContainer}>
                    <i class="fa fa-exclamation" aria-hidden="true"></i>
                    <p>{ getErrorContent() }</p>
                  </div>
                ) : (
                  <InstrumentsFilterList 
                    instruments={instruments} 
                    pattern={searchValue} 
                    loading={loading && !disableDropdown}
                    onAddAssetClick={onAddAssetClick}
                    instrumentsDisabled={instrumentsDisabled}
                  />
                )}
              </>
            )}
            dropdownVisible={(instruments || loading) && !disableDropdown}
            isOptionsLoading={!!loading}
            onChange={setSearchValue}
            onValueApprove={(value) => {handleSearchActivate(typeof value === 'string' ? value.trim() : searchValue)}}
            autoApprove={false}
            isSearchDisabled={loading}
            inputAdornment={(props, classes) => {
              return (
                <SearchButton
                  {...props}
                  classes={classes}
                  hasDropdownOptions={hasDropdownOptions()}
              >
              </SearchButton>
              )
            }}
          />
          <PrimaryButton 
            text="Expertenfilter öffnen"
            icon={<FilterIcon color="white"/>}
            onButtonClick={onFiltersExtendedButtonClick}
            customClasses={{root: classes.buttonRoot}}
          />
          {/* <PrimaryButton 
            variant="outlined"
            text="Portfolio importieren (.csv /.xls)"
            icon={<ImportIcon color={theme.palette.primary.main}/>}
            customClasses={{root: classes.buttonRoot}}
          /> */}
        </div>
      </WidgetBody>
    </Widget>
  )
}

InstrumentsFilter.propTypes = {
  /** List of instruments */
  instruments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,

  /** Flag, that indicate, if instruments data is loading at the moment, or not */
  loading: PropTypes.bool,

  /** Flag, for showing or not dropdown even if loading state */
  disableDropdown: PropTypes.bool,

  /** Handler for 'Expert Filters' button click event */
  onFiltersExtendedButtonClick: PropTypes.func.isRequired,

  /** Handler for search input approved */
  onSearchActivate: PropTypes.func.isRequired,
  
  /** Hanler for onAddAsset event */
  onAddAssetClick: PropTypes.func,

  /** Instruments, that could not be selected */
  instrumentsDisabled: PropTypes.arrayOf(PropTypes.shape({}))
}

InstrumentsFilter.defaultProps = {
  instruments: [],
  loading: false,
  disableDropdown: false,
}

export default InstrumentsFilter

