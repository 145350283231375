import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  gridItemTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 20,
    color: '#202A38',
    marginBottom: 10
  },
  instrumentName: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38'
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  fiexedLabel: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38'
  },
  totalWeightLabel: {
    fontSize: 14,
    color: '#202A38',
    fontFamily: 'Roboto-Bold'
  },
  assetsContainer: {
    maxHeight: '100%',
    overflowY: 'auto'
  },
  closeButton: {
    float: 'right',
    position: 'absolute',
    top: 0,
    right: 0
  },
  weightsLoadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9,

    '& > div': {
      width: '80px !important',
      height: '80px !important',

      '& svg': {
        color: 'white'
      }
    }
  }
})

export default makeStyles(styles)