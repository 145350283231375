import React from 'react';

import PrimaryButton from '../../../../components/Buttons/PrimaryButton';


export default (props) => {

  const buttonText = !!props.tradingSession ? "Order Fortsetzen" : "Neue Order";

  return (
    <PrimaryButton
      text={<>{buttonText} {!!props.ordersCount && `(${props.ordersCount})`}</>}
      icon={<i className={"far fa-shopping-cart fa-xs"} style={{color: !props.disabled ? 'white' : 'rgba(0, 0, 0, 0.26)'}}/>}
      customClasses={props.customClasses}
      onButtonClick={props.onButtonClick}
      disabled={props.disabled}
    />
  )
}