import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
    portfolioName: {
        fontFamily: 'Roboto-Medium',
        fontSize: 20,
        color: 'white'
    },
    categoryName: {
        fontFamily: 'Roboto-Medium',
        fontSize: 16,
        color: 'white',
        marginLeft: 10
    },
    totalValue: {
        fontFamily: 'Roboto-Bold',
        fontSize: 16,
        color: 'white',
        marginRight: 80,
        whiteSpace: 'nowrap',
    },
    footer: {
        background: '#D8DCDF',
        borderRadius: '0px 0px 5px 5px',
        height: 55,
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 50px',

        '& > p': {
            margin: 0,
            fontFamily: 'Roboto-Bold',
            fontSize: 16,
            color: '#202A38'
        }
    }
})

export default makeStyles(styles)