import React from 'react';


export default () => {
    return (
        <svg
      id="Group_7979"
      data-name="Group 7979"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_7677"
            data-name="Rectangle 7677"
            width="30"
            height="30"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g id="Group_7978" data-name="Group 7978" clip-path="url(#clip-path)">
        <path
          id="Path_6748"
          data-name="Path 6748"
          d="M29,14H17a1,1,0,0,1-1-1V1a1,1,0,0,1,1-1A13.014,13.014,0,0,1,30,13a1,1,0,0,1-1,1M18,12h9.955A11.019,11.019,0,0,0,18,2.045Z"
          fill="#80858c"
        />
        <path
          id="Path_6749"
          data-name="Path 6749"
          d="M13,30A13,13,0,0,1,13,4a1,1,0,0,1,1,1V16H25a1,1,0,0,1,1,1A13.014,13.014,0,0,1,13,30M12,6.045A11,11,0,1,0,23.955,18H13a1,1,0,0,1-1-1Z"
          fill="#80858c"
        />
      </g>
    </svg>
    )
}