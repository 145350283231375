const styles = (theme) => ({
  card: {
    padding: 20,
    position: "relative",
    background: "white",
    boxShadow: "0px 3px 15px #00000033",
    borderRadius: 5,
  },
  cardsWrapper: {
    paddingTop: 30,
    display: "flex",
    flexDirection: "column",
    gap: "25px",
  },
  title: {
    color: "#202A38",
    fontSize: 20,
    fontFamily: "Roboto-Regular",
  },
  titleWrapper: {
    display: "flex",
    alignItems: 'baseline',
    gap: "8px",
  },
  tooltip: {
    position: "relative",
    padding: 20,
    backgroundColor: "#EFEFF2",
    color: "#4D4F5C",
    fontFamily: "Roboto-Regular",
    fontSize: 14,
    boxShadow: "0px 2px 15px 0px rgba(0,0,0, 0.2)",
    whiteSpace: "pre-line",
    maxWidth: 500,
  },
  container: {
    "& > div:first-child > div > div": {
      borderLeft: "none",
    },
  },
  acceptButton: {
    fontSize: 18,
    fontFamily: "Roboto-Bold",
    textTransform: "uppercase",
    color: "white",
    boxShadow: "none",
    borderRadius: 2,

    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },

    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrowIcon: {
    height: 24,
  },
  helpIcon: {
    verticalAlign: "middle",
    fontSize: "1.1rem",
    color: "#202A38",
  },
});

export default styles;
