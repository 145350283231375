import React from 'react';

import IconButton from '@material-ui/core/IconButton';

import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import WarningTooltip from "../../../../components/WarningTooltip";


const AlertButton = (props) => {
  const Icon = () => <i className={"far fa-bell"} />;

  return props.variant === 'icon'
    ?
      <WarningTooltip
        title={props.text}
        icon={
          <IconButton color={'primary'} onButtonClick={props.onButtonClick} disabled={props.disabled}>
            <Icon/>
          </IconButton>
        }
      />
    :
      <PrimaryButton
        id={props.id}
        variant={props.variant}
        text={props.text}
        onButtonClick={props.onButtonClick}
        icon={<Icon/>}
        disabled={props.disabled}
        customClasses={props.customClasses}
      />
};

AlertButton.defaultProps = {
  variant: 'text',
  text: 'Alarm'
};

export default AlertButton;