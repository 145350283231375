import {Component} from "react";
import {MODELPORTFOLIO_CREATE_CONTAINER_TABS} from "./constants";
import axios from "axios";
import {ModelPortfolioResource, parseResponse} from "../../../utils/api";
import {displayErrorSnackBar} from "../../../components/SnackbarProvider/actions";
import {getErrorMessage} from "../../../utils/utils";
import {getAssetInternalId, getKeyFieldValue} from "../../CustomerDashboard/utils";
import _ from "lodash";

let cancelToken;
export class ModelPortfolioBase extends Component {

  constructor(props) {
    super(props)

    this.state = {
      activeContent: MODELPORTFOLIO_CREATE_CONTAINER_TABS.MAIN,
      assetsSearch: {
        data: undefined,
        loading: false,
        errors: undefined,
        date: Date.now()
      },
      selectedAssets: [],
      instrumentsHandler: undefined,
      mpStartDate: null,
      triggerAccordionExpansionCheck: undefined,
    }

    this.__instruments_handler = undefined
  }

  componentDidMount() {}

  handleSelectedAssetsChange = (selectedAssets) => {
    return this.props.onAssetsUpdate && this.props.onAssetsUpdate(selectedAssets);
  }

  handleFiltersExtendedButtonClick = () => {
    this.setState({
      activeContent: MODELPORTFOLIO_CREATE_CONTAINER_TABS.FILTERS_EXTENDED
    });
  }

  addExtraFilters = (filters) => {
    if(this.props.extraFilters){
      filters = {...filters, ...this.props.extraFilters};
    }

    return filters;
  };

  handleSearchActivate = (value, onlyFavorite) => {

    if (value) {
      let filters = {
        "name": value,
        "only_favorite": onlyFavorite
      }

      filters = this.addExtraFilters(filters);

      this.fetchAssets(filters)
    } else {
      //Check if there are any previous pending requests
      if (typeof cancelToken != 'undefined') {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      this.setState({
        assetsSearch: {
          loading: false,
          errors: undefined,
          data: [],
          date: Date.now()
        }
      })
    }
  }

  handleAddAssetClick = (asset) => {
    let selectedAssetsCopy = [...this.state.selectedAssets]

    const idx = this.findAssetIndex(selectedAssetsCopy, asset);
    if (idx !== -1) {
      selectedAssetsCopy.splice(idx, 1);
    } else {
      selectedAssetsCopy = [...selectedAssetsCopy, asset]
    }

    this.setState({
      selectedAssets: selectedAssetsCopy
    })
    this.handleSelectedAssetsChange(selectedAssetsCopy)
  }

  searchAssets = async (filters, resolve, errors) => {
    //Check if there are any previous pending requests
    if (typeof cancelToken != 'undefined') {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    let response = {}
    try{
      response = await ModelPortfolioResource.searchAssets(filters, cancelToken.token);
    } catch (errors) {
      this.props.dispatch(displayErrorSnackBar(getErrorMessage(errors)));
    }
    parseResponse(response, 'assets', resolve, errors)
  }


  fetchAssets = async (filters) => {
    this.setState({
      assetsSearch: {
        data: undefined,
        loading: true,
        errors: undefined,
        date: Date.now()
      }
    });

    try {

      this.searchAssets(filters, (data) => {
        this.setState({
          assetsSearch: {
            data,
            loading: false,
            errors: undefined,
            date: Date.now()
          }
        });
      }, (errors) => {
        this.setState({
          assetsSearch: {
            data: undefined,
            loading: false,
            errors,
            date: Date.now()
          }
        });
      });

    } catch (errors) {
      if (errors !== 'Operation canceled due to new request.'){
        this.setState({
        assetsSearch: {
          ...this.state.assetsSearch,
          loading: false,
          errors
        }
      })
      }
    }
  }

  findAssetIndex = (assets, asset, key='isin') => {

    let getFieldValue = key === 'isin' ? getAssetInternalId : getKeyFieldValue

    return _.findIndex(assets, a => getFieldValue(a, key) == getFieldValue(asset, key));
  }

  handleAddInstrumentsFilters = (instruments) => {
    let selectedAssetsCopy = [...this.state.selectedAssets];

    if (instruments && instruments.length) {
      instruments.forEach(instrument => {
        if (this.findAssetIndex(selectedAssetsCopy, instrument) === -1) {
          selectedAssetsCopy.push(instrument)
        }
      })
    }

    this.setState({
      selectedAssets: selectedAssetsCopy,
      activeContent: MODELPORTFOLIO_CREATE_CONTAINER_TABS.MAIN
    })
    return this.handleSelectedAssetsChange(selectedAssetsCopy)
  }

  getFilterInstruments = () => {

    if (this.state.assetsSearch.loading || this.state.assetsSearch.errors || !this.state.assetsSearch.data) {
      return undefined
    }

    if (!this.state.instrumentsHandler) {
      return this.state.assetsSearch.data
    }

    let [instrumentsToAccept, instrumentsToExclude] = this.state.instrumentsHandler.filterInstruments(this.state.assetsSearch.data)

    return {
      "accepted": instrumentsToAccept,
      "excluded": instrumentsToExclude
    }

  }
}