import React from 'react'

import {Skeleton} from '@material-ui/lab'
import { Grid } from '@material-ui/core'

import useStyles from './styles'


export default function FilterLoadingItem({showCount}) {

  const classes = useStyles()

  return (
    <div className={classes.container}>

      <Grid container xs spacing={2}>
          <Grid className={classes.data} item xs>
            <Skeleton />
          </Grid>
          {showCount && 
            <Grid className={classes.count} item xs={3}>
              <Skeleton />
            </Grid>
          }
      </Grid>
    </div>
  )
}
