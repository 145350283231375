import {makeStyles} from "@material-ui/core";

const styles = (theme) => ({
  dialogContentRoot: {
    overflowX: 'hidden',
    position: 'relative',
    '& > div': {
      paddingLeft: 0,
      paddingRight: 0,
      '& > div': {
        padding: 0
      }
    },

    '& .app-page-container': {
      padding: 0
    }
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  header: {
    height: 16
  },
  dialogActionsRoot: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    '& > div > button': {
      '&:last-child': {
        marginLeft: 15
      }
    }
  }
});

export default makeStyles(styles);