import React from 'react';
import { Link } from 'react-router-dom';

/* Material-UI Components */
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

/* BCA modules*/
import useStyles from './styles';
import {ROUTES} from "../../../../routes";


export default (props) => {
  const classes = useStyles();
  const {
    isLoading,
    isSettingsSuccessUpload,
    handleSaveClicked,
    isVisible,
    selectedCustomers,
    handleNextStepClicked,
    nextButtonText
  } = props;

  const getSelectedInfoText = () => {
    return `Sie haben ${selectedCustomers.length} Kunde${selectedCustomers.length > 1 ? 'n' : ''} ausgewählt.`
  };

  return (
    isVisible ? (
      <div className={classes.footerContainer}>
        <Container className={classes.footerContentContainer}>
          <Grid container alignItems={"center"} justify={"space-between"}>
            <Grid item>
              <Link
                to={ROUTES.BROKER.REPORT_SETTINGS.path}
                className={[classes.backLink, classes.text].join(' ')}
                id="report-settings-create-group-back-btn-tour-element"
              >
                <i className="chevron-icon fa fa-chevron-left" /> 
                Zurück zur Gruppenliste
              </Link>
            </Grid>
            {(!isSettingsSuccessUpload && selectedCustomers && selectedCustomers.length > 0) &&
              <>
                <Grid item>
                  <p className={classes.text}>{getSelectedInfoText()}</p>
                </Grid>
                <Grid item>
                  <Button 
                    variant="contained"
                    className={classes.footerButton}
                    id="report-settings-create-group-save-btn-tour-element"
                    disabled={isLoading}
                    onClick={handleNextStepClicked}
                  >
                    {isLoading ? (
                      <CircularProgress size={24} className={classes.buttonProgress}/>
                    ) : (
                      <>
                        <span>{nextButtonText}</span>
                        <i className="chevron-icon fa fa-chevron-right" /> 
                      </>
                    )}
                  </Button>
                </Grid>
              </>
            }

            {isSettingsSuccessUpload &&
            <Grid item>
              <div className={classes.successMessage}>Ihre Einstellungen wurden erfolgreich gespeichert</div>
            </Grid>
            }
          </Grid>
        </Container>
      </div>
    ) : null
  )
}