import React, {useMemo} from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  InputAdornment,
  CircularProgress
} from '@material-ui/core';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton'

import useStyles from './styles';
import images from '../../../../images';
import {GROUP_NAME_MAX_LENGTH} from '../../constants';
import {ROUTES} from "../../../../routes";
import {executeIfPathExist, getInvestmentDynamicPath} from "../../../InvestmentPlatform/utils";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import CustomerFullnameMenu from "../../../../components/CustomerFullnameMenu/CustomerFullnameMenu";


const GroupCustomersList = ({
  customers,
  onCustomerRemove
}) => {
  const classes = useStyles();

  const handleCustomerRemove = customer => () => {
    if (!_.isNil(onCustomerRemove)) {
      onCustomerRemove(customer);
    }
  }

  const renderCustomersList = () => {
    if (!_.isNil(customers)) {
      return customers.map(customer => (
        <TableRow className={classes.customersListRow}>
          <TableCell>
            <CustomerFullnameMenu
              customer={customer}
              customerFullname={customer.customer_full_name}
            />
          </TableCell>
          <TableCell style={{width: 130}} className={classes.textAlignRight}>{customer.customer_id}</TableCell>
          <TableCell>
            <img
              src={images.close_icon}
              className={classes.deleteCustomerIcon}
              onClick={handleCustomerRemove(customer)}
            />
          </TableCell>
        </TableRow>
      ))
    }
  };

  return (
    <Table>
      <TableHead className={classes.customersListHead}>
        <TableRow>
          <TableCell style={{width: 150}}>KUNDENNAME</TableCell>
          <TableCell style={{width: 130}} className={classes.textAlignRight}>KUNDENNUMMER</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={classes.customersListBody}>
        {renderCustomersList()}
      </TableBody>
    </Table>
  )
}


const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
});

const GroupDetails = (props) => {
  const classes = useStyles();

  const {
    selectedCustomers,
    onSaveGroupClick,
    saveDisabled,
    isSaving,
    errors,
    groupName,
    editMode,
    redirectMenuItem, // passed if dashboard settings group creation process. Used in redirect if single customer is selected
  } = props;

  const [name, setName] = React.useState(groupName);

   // when set to true - create group btn redirects to selected customer dashboard settings
   const redirectFunctionalityOn = useMemo( () => {
     return redirectMenuItem && selectedCustomers.length === 1
   }, [selectedCustomers])

  React.useEffect(() => {
    setName(groupName)
  }, [groupName]);


  const handleCustomerDeselect = (customer) => {

    if (!_.isNil(props.onDeselectCustomer)) {
      props.onDeselectCustomer(customer);
    }

  };

  const handleSaveGroupClick = () => {

    if (!_.isNil(onSaveGroupClick)) {
      onSaveGroupClick(name);
    }

  }

  const redirectToSingleCustomer = () => {
    let path = ROUTES.BROKER.GROUP.path

    if (redirectMenuItem) {
      executeIfPathExist(props.investmentPlatform.routes, redirectMenuItem, (dynamicPath) => {
        path = '/' + getInvestmentDynamicPath() + dynamicPath + `/?customer_id=${selectedCustomers[0].customer_id}`
      })
    }

    props.history.push(path)
  }

  const isSaveDisabled = () => {
    return _.isNil(name) || name.length == 0 || name.length > GROUP_NAME_MAX_LENGTH || saveDisabled || isSaving
  }

  const getErrorMsg = () => {
    if(_.isObject(errors)){
      // duplicated group name error
      if('non_field_errors' in errors ) { return 'Eine Kundengruppe unter diesem Namen existiert bereits. Bitte verwenden Sie einen anderen Namen.' }
      // too long word in group name error
      if('name' in errors ) { return errors.value }
    }
    return ''
  }

  return (
    <div className={classes.container} id="report-settings-create-group-group-customers-list">
      <div className={classes.summary}>
        <p className={classes.summaryText}>
          <span
            className={classes.summaryNumber}
            style={{
              backgroundColor: (selectedCustomers && selectedCustomers.length) ? '#E3A600' : '#ABB6BE'
            }}
          >{selectedCustomers && selectedCustomers.length || 0}</span>
          <span>Kunde{selectedCustomers.length > 1 ? 'n' : ''} hinzugefügt</span></p>
      </div>
      <div className={classes.inputContainer}>
        <TextField
          className={classes.textField}
          error={errors && 'name' in errors}
          value={name}
          label="Gruppenname eingeben:"
          margin="normal"
          onChange={(event) => setName(event.target.value)}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: classes.inputLabelRoot,
              shrink: classes.inputLabelShrink,
              error: classes.inputLabelError
            }
          }}
          helperText={getErrorMsg()}
          InputProps={{
            endAdornment: <InputAdornment className={classes.inputAdornment} position="end">{name && name.length || 0}/{GROUP_NAME_MAX_LENGTH}</InputAdornment>
          }}
          inputProps={{maxLength: GROUP_NAME_MAX_LENGTH}}
        />
          <PrimaryButton
            text={
              <>
                {!isSaving ? (
                  <span>
                    <i className="fa fa-plus" style={{marginRight: 5}}/>
                      {redirectFunctionalityOn
                        ? 'Einzelkundeneinstellungen ändern'
                        : editMode ? 'Gruppe aktualisieren' : 'Gruppe erstellen'}
                    </span>
                ) : (
                  <CircularProgress size={15}/>
                )}
              </>

            }
            onButtonClick={redirectFunctionalityOn ? redirectToSingleCustomer : handleSaveGroupClick}
            disabled={!redirectFunctionalityOn && isSaveDisabled()}
          />
      </div>
      <GroupCustomersList
        customers={selectedCustomers}
        onCustomerRemove={handleCustomerDeselect}
      />
    </div>
  );
};

export default connect(mapStateToProps)(withRouter(GroupDetails));