import React, {useImperativeHandle} from 'react';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid'

import Dropdown from './components/Dropdown/Dropdown'
import Button from './components/Button/Button'
import DateRange from './components/DateRange/DateRange'
import InputField from "./components/InputField/InputField";

import useStyles from './styles'
import Switch from "./components/Switch/Switch";
import SimpleDateRange from "./components/SimpleDateRange/SimpleDateRange";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Divider, Paper } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ResetButton from './components/ResetButton/ResetButton';
import SimpleDropdown from './components/SimpleDropdown/SimpleDropdown';
import SliderRangeSelect from './components/SliderRangeSelect/SliderRangeSelect';
import DropdownInput from './components/DropdownInput/DropdownInput';
import SortingDropdown from './components/SortingDropdown/SortingDropdown';

const FiltersContext = React.createContext();

export function useFiltersContext() {

  const context = React.useContext(FiltersContext);
  if (!context) {
    throw new Error('Extracting context without wrapping your component with context Provider!')
  }

  return context

}

const Filters = React.forwardRef(({ children, label, initValue, defaultValue, onSubmit, isInline }, ref) => {

  const classes = useStyles();
  const [filters, setFilters] = React.useState(initValue || {});

  const fieldsRefs = React.useRef({});

  const [filterPanelIsOpened, setFilterPanelIsOpened] = React.useState(true);
  const handleFiltersToggle = () => {
    setFilterPanelIsOpened(!filterPanelIsOpened)
  };

  useImperativeHandle(ref, () => ({
    setFilter: (name, value, autoApprove=true) => {
      setFilters((filters) => {
        filters = {...filters, [name]: value, autoApprove};
        // autoApprove && handleFiltersApproved(filters);
        return filters;
      })
    }
  }));

  React.useEffect(() => {
    if (filters.autoApprove) {
      handleFiltersApproved();
      setFilters((current) => {
        delete filters.autoApprove;
        return {...filters};
      })
    }
  }, [filters.autoApprove]);

  const setFilterValue = React.useCallback((name, value) => {
    setFilters((filters) => ({...filters, [name]: value}))
  }, []);

  React.useEffect(() => {
    setFilters(initValue)
  }, [initValue]);

  const handleFiltersReset = () => {
    setFilters(defaultValue);
  }

  const handleFiltersApproved = () => {
    if (validateFilters()) {
      onSubmit && onSubmit(filters)
    }
  };

  const setFieldRef = (node, name) => {
    fieldsRefs.current[name] = node
  };

  const validateFilters = () => {
    const validationStatuses = [];

    Object.values(fieldsRefs.current).filter((field) => !!field).forEach((field) => {
      validationStatuses.push(field.isValid())
    });

    return validationStatuses.every((status) => status)
  };

  const ContainerComponent = isInline ? React.Fragment : Paper;

  return (
    <FiltersContext.Provider value={{ filters, setFilterValue, onSubmit: handleFiltersApproved, errors: {}, filtersRefs: fieldsRefs.current }}>
      <ContainerComponent square={true} classes={{ root: classes.paperRoot }}>
        <Accordion
          expanded={filterPanelIsOpened}
          onChange={undefined}
          classes={{ root: clsx(classes.accordionRoot, isInline && 'inline'), expanded: classes.accordionExpanded }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            IconButtonProps={{
              classes: { edgeEnd: classes.accButtonIconEdgeEnd },
              onClick: handleFiltersToggle
            }}
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.accordionSummaryRoot,
              expanded: classes.accordionSummaryExpanded,
              content: classes.accordionSummaryContent,
            }}
          >
            <p className={classes.filtersLabel}>{label}</p>
            <ResetButton onClick={handleFiltersReset} />
          </AccordionSummary>

          <Divider/>

          <AccordionDetails
            classes={{ root: classes.accordionDetailsRoot }}
          >
            <Grid container spacing={2}>
              {React.Children.map(children, (child, index) => {
                return React.cloneElement(child, {
                  ref: (node) => setFieldRef(node, child.props.name || index)
                })
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>

      </ContainerComponent>
    </FiltersContext.Provider>
  )
})

Filters.Dropdown = Dropdown;
Filters.SimpleDropdown = SimpleDropdown;
Filters.SubmitButton = Button;
Filters.DateRange = DateRange;
Filters.InputField = InputField;
Filters.SimpleDateRange = SimpleDateRange;
Filters.Switch = Switch;
Filters.SliderRangeSelect = SliderRangeSelect;
Filters.DropdownInput = DropdownInput;
Filters.SortingDropdown = SortingDropdown;

export default Filters;