import {makeStyles} from "@material-ui/core";

export default makeStyles(theme => ({
  table: {
    minWidth: 900,
  },
  tableCell: {
    '&:nth-of-type(4)': {
      width: 50,
      paddingLeft: 12,
      paddingRight: 12,
    },
    '&.row-number': {
      width: 20,
      maxWidth: 20,
    },
    '&.sustainability-cell': {
      width: 83,
      maxWidth: 83
    },
    '&.portfolio-weight, &.mp-weight, &.difference': {
      borderLeft: '1px solid rgb(224, 224, 224)',
      position: 'relative',
      paddingLeft: 20,
      paddingRight: 20,
      width: 200,
    },
    '&.difference': {
      paddingLeft: 12,
      paddingRight: 12,
      width: 150,
    },
  },
  ['@media (max-width:1279px)']: {
    tableCell: {
      '&.portfolio-weight, &.mp-weight': {
        borderLeft: '1px solid rgb(224, 224, 224)',
        paddingLeft: 20,
        paddingRight: 20,
        width: 200,
      },
      '&.difference': {
        paddingLeft: 12,
        paddingRight: 12,
        width: 150,
      },
    },
  },
  loadingIndicator: {
    color: theme.palette.primary.main
  },
  loadingContainer: {
    width: '100%',
    height: 560,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    color: '#202A38',
    margin: 0
  },
  subTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38',
    margin: 0
  },
  navigationContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: 0,
    marginBottom: 120,

    '& button >span > span': {
      fontSize: 14
    }
  },
}))