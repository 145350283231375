import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment';

import {
  Grid
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab'

import useStyles from './styles';

import {
  Widget,
  WidgetHeader,
  WidgetBody
} from '../../../../Modelportfolios/components';

function FavoriteListDetails(props) {

  const {
    loading,
    favoriteList
  } = props;

  const classes = useStyles();

  return (
    <Widget>
      <WidgetHeader>
        <p className={classes.header}>Basisinformationen</p>
      </WidgetHeader>
      <WidgetBody>
        <Grid container>
          <Grid item xs={12} md={3} className={classes.section}>
            {loading ? (
              <>
                <Skeleton style={{width: 130, height: 30}}/>
                <Skeleton style={{width: 200}}/>
              </>
            ) : (
              <>
                <p className={classes.sectionHeader}>Erstellt am</p>
                <p className={classes.sectionValue}>
                  {favoriteList && favoriteList.creation_date && moment(favoriteList.creation_date).format('DD.MM.YYYY')}
                </p>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={9} className={classes.section}>
            {loading ? (
              <>
                <Skeleton style={{width: 130, height: 30}}/>
                <Skeleton style={{width: '100%'}}/>
                <Skeleton style={{width: '75%'}}/>
                <Skeleton style={{width: '90%'}}/>
                <Skeleton style={{width: '30%'}}/>
              </>
            ) : (
              <>
                <p className={classes.sectionHeader}>Beschreibung</p>
                <p className={classes.sectionValue} style={{whiteSpace: 'pre-line'}}>
                  { favoriteList && favoriteList.description }
                </p>
              </>
            )}
            
          </Grid>
        </Grid>
      </WidgetBody>
    </Widget>
  )
}

FavoriteListDetails.propTypes = {

}

export default FavoriteListDetails

