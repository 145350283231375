const styles = () => ({
  customerInfo: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    '@media (max-width: 1330px)': {
      '& > div': {
        padding: '0 !important',
      },
    },
  },
  customerId: {
    fontFamily: 'Roboto-Regular'
  }
});

export default styles;
