import React from "react";
import {
  buildRiskMetricsRequestState,
  finishIndividualPortfolioRiskMetricsTimeFrameRequest,
  startIndividualPortfolioRiskMetricsTimeFrameRequest
} from "../utils";
import {parseResponse, PortfolioHandlerResource} from "../../../utils/api";
import _ from "lodash";
import {getPortfolioRiskMetricsMaxTimeFrame, RISK_INDICATORS_DATA_RETRIEVING_TIME_FRAMES} from "../constants";
import {PortfolioUtils} from "../../../utils/utils";
import moment from "moment";

export function useIndividualPortfoliosRiskMetricsData(customerId, customerData, selectedPortfolios, fetchOnMount=true) {

  const [individualPortfoliosRiskMetricsData, setIndividualPortfoliosRiskMetricsData] = React.useState(buildRiskMetricsRequestState())

  React.useEffect(() => {

    if (!fetchOnMount) {
      return
    }

    if (customerData.loading) {
      return
    }

    if (selectedPortfolios.length) {
      fetchIndividualPortfoliosRiskMetrics()
    }

  }, [selectedPortfolios])

  /**
   * Fetch individual portfolio risk metrics data.
   * @param portfolio
   * @param timeFrame
   * @returns {Promise<void>}
   */
  const fetchIndividualPortfolioTimeFrameRiskMetrics = async (portfolio, timeFrame) => {

    setIndividualPortfoliosRiskMetricsData(
      (data) => startIndividualPortfolioRiskMetricsTimeFrameRequest({...data}, portfolio, timeFrame))

    try {

      const result = await PortfolioHandlerResource.getPortfolioRiskData(
        customerId,
        portfolio.portfolioId,
        timeFrame.startDate, timeFrame.endDate)

      parseResponse(result, 'risk',
        (result) => {
          setIndividualPortfoliosRiskMetricsData(
            (data) => finishIndividualPortfolioRiskMetricsTimeFrameRequest({...data}, portfolio, timeFrame, result))
        }, (errors) => {
          setIndividualPortfoliosRiskMetricsData(
            (data) => finishIndividualPortfolioRiskMetricsTimeFrameRequest({...data}, portfolio, timeFrame, undefined, errors))
        })

    } catch (errors) {
      setIndividualPortfoliosRiskMetricsData(
        (data) => finishIndividualPortfolioRiskMetricsTimeFrameRequest({...data}, portfolio, timeFrame, undefined, errors))
    }
  }

  /**
   * Fetch risk metrics for each selected portfolios separately.
   */
  const fetchIndividualPortfoliosRiskMetrics = () => {

    const portfolios = _.filter(selectedPortfolios, (portfolio) => portfolio.depotNumber != 0 && !portfolio.is_empty)

    portfolios.forEach((portfolio) => {
      RISK_INDICATORS_DATA_RETRIEVING_TIME_FRAMES.forEach((timeFrame) => {
        fetchIndividualPortfolioTimeFrameRiskMetrics(portfolio, timeFrame)
      })
      fetchIndividualPortfolioTimeFrameRiskMetrics(portfolio, getPortfolioRiskMetricsMaxTimeFrame(portfolio))
    })
  }

  return [individualPortfoliosRiskMetricsData, fetchIndividualPortfoliosRiskMetrics];

}