const styles = theme => ({
  dialog: {
    maxWidth: 900
  },
  closeButton: {
    position: 'absolute',
    right: 0
  },
  title: {
    '& h2': {
      fontFamily: 'Roboto-Bold',
      fontSize: 20,
      color: '#202A38',
      paddingTop: 50
    }
  },
  modalContent: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38',
    paddingBottom: 16,
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  labelsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: 35,
    marginBottom: 10
  },
  buttonsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20
  },
  button: {
    padding: '15px 55px',
    textTransform: 'none',
    fontSize: 16,
    fontFamily: 'Roboto-Bold'
  },
  buttonCancel: {
    color: theme.palette.primary.main,
    marginRight: 10
  },
  inputField: {
    '& input': {
      padding: 15,
    }
  },
  inputFieldSelect: {
    width: '100%',
    '& > div': {
      paddingTop: 15,
      paddingBottom: 15
    }
  },
  inputLabel: {
    fontFamily: 'Roboto-Bold'
  },
  helpText: {
    color: '#80858C',
    fontSize: 10,
    textTransform: 'uppercase'
  },
  loadWrapper: {
    backgroundColor: 'white',
    opacity: 0.9,
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageContent: {
    '& .no-margin': {
      margin: 0
    }
  }
})

export default styles;