import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    overflowX: 'hidden'
  },
  word: {
    color: theme.palette.primary.main,
  },
  loadingContainer: {
    width: '100%',
    height: '25vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  preloaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 70,
    backgroundColor: "white"
  },
  loadingIndicator: {
    color: theme.palette.primary.main
  },
  deleteBtn: {
    padding: 5,
    fontSize: '1.125rem',
  },
  backdrop: {
    zIndex: 100
  },
});

export default makeStyles(styles)
