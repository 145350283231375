import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  expansionSummaryContainer: {
    display: 'flex',
    alignItems: 'baseline'
  },

  expansionPanelRoot: {
    boxShadow: 'none',
    padding: 0,
    minHeight: 0,
    margin: 0,

    '&::before': {
      backgroundColor: 'rgba(0, 0, 0, 0)'
    }
  },
  expansionPanelExpanded: {
    minHeight: '0 !important',
    margin: '0px !important'
  },

  expansionPanelSummaryRoot: {
    padding: '12px 0 0',
    minHeight: 0,
    fontFamily: 'Roboto-Regular',
    color: '#202A38',
    fontSize: 16
  },
  expansionPanelSummaryContent: {
    margin: 0
  },
  expansionPanelSummaryExpanded: {
    margin: '0px !important',
    minHeight: '0px !important'
  },

  expansionPanelDetailsRoot: {
    padding: 0,
    flexDirection: 'column'
  }
})

export default makeStyles(styles)