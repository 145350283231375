import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22.851"
      height="30"
      viewBox="0 0 22.851 30"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_8031"
            data-name="Rechteck 8031"
            width="22.851"
            height="30"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Gruppe_8148"
        data-name="Gruppe 8148"
        transform="translate(0 0.001)"
      >
        <g
          id="Gruppe_8145"
          data-name="Gruppe 8145"
          transform="translate(0 -0.001)"
        >
          <g
            id="Gruppe_8144"
            data-name="Gruppe 8144"
            clip-path="url(#clip-path)"
          >
            <path
              id="Pfad_7813"
              data-name="Pfad 7813"
              d="M1.758,0V24.727H0V30H21.093V24.727H19.336V22.91h.879a2.64,2.64,0,0,0,2.636-2.637V6.152a2.64,2.64,0,0,0-2.636-2.636h-.879V0ZM19.336,8.789h1.758v3.574H19.336ZM17.578,24.727H3.516V1.758H17.578Zm1.758,3.515H1.758V26.484H19.336Zm.879-7.09h-.879V14.121h1.758v6.152a.88.88,0,0,1-.879.879m0-15.879a.88.88,0,0,1,.879.879v.879H19.336V5.273Z"
              transform="translate(0 0)"
              fill="#fff"
            />
          </g>
        </g>
        <path
          id="Pfad_7814"
          data-name="Pfad 7814"
          d="M21.325,7.18H10.771v8.853H21.325Zm-1.759,7.094H12.53V8.939h7.036Z"
          transform="translate(-5.5 -3.663)"
          fill="#fff"
        />
        <g id="Gruppe_8147" data-name="Gruppe 8147" transform="translate(0 0)">
          <g
            id="Gruppe_8146"
            data-name="Gruppe 8146"
            clip-path="url(#clip-path)"
          >
            <path
              id="Pfad_7815"
              data-name="Pfad 7815"
              d="M18.531,28.678l-.654-.729-.654.729c-.951,1.06-2.862,3.456-2.862,4.982a3.516,3.516,0,0,0,7.032,0c0-1.519-1.918-3.93-2.862-4.982m-.654,6.739a1.759,1.759,0,0,1-1.758-1.758,7.879,7.879,0,0,1,1.757-3.033,8.009,8.009,0,0,1,1.758,3.033,1.759,1.759,0,0,1-1.758,1.758"
              transform="translate(-7.33 -14.265)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
