import { makeStyles } from '@material-ui/core';
import {
  getCommonProductComparisonTableFirstColumnStyles,
  getCommonProductComparisonTableStyles
} from "../FondsKenzahlenTable/styles";
import {smFontSizeTable} from "../../../../utils/constants";

export const FondsInformationTableStyles = makeStyles((theme) => ({
  ...getCommonProductComparisonTableStyles(theme),
  tableCell: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    verticalAlign: 'middle',
    '&:nth-child(2)': {
      padding: '8px 2px',
    },
    '&:nth-child(3)': {
      minWidth: 110,
      [theme.breakpoints.down('sm')]: {
        minWidth: 100,
      }
    },
    '&:nth-child(4)': {
      padding: '8px 2px',
      minWidth: 160,
    },
    '&:nth-child(5)': {
      padding: '8px 2px',
      minWidth: 150
    },
    '&:nth-child(9)': {
      minWidth: 200
    },
    '&:nth-child(10)': {
      minWidth: 200
    },
    '&:nth-child(11)': {
      minWidth: 150,
      [theme.breakpoints.down('sm')]: {
        minWidth: 130,
      },
    },
    '&:nth-child(12)': {
      minWidth: 150,
      [theme.breakpoints.down('sm')]: {
        minWidth: 130,
      },
    },
    '&:nth-child(13)': {
      minWidth: 35,
      [theme.breakpoints.down('sm')]: {
        minWidth: 30,
      }
    },
    '&:nth-child(14)': {
      minWidth: 105,
      [theme.breakpoints.down('sm')]: {
        minWidth: 95,
      }
    },
    '&:nth-child(15)': {
      minWidth: 200
    },
    '&:nth-child(16)': {
      minWidth: 70
    },
    '&:nth-child(17)': {
      minWidth: 150
    },
    '&:nth-child(18)': {
      minWidth: 106,
      [theme.breakpoints.down('sm')]: {
        minWidth: 96,
      }
    },
    '&:nth-child(19)': {
      minWidth: 131,
      [theme.breakpoints.down('sm')]: {
        minWidth: 130,
      }
    },
    '&:nth-child(20)': {
      minWidth: 100,
      [theme.breakpoints.down('sm')]: {
        minWidth: 80,
      }
    },
    ...getCommonProductComparisonTableFirstColumnStyles(theme),

    [theme.breakpoints.down('sm')]: {
      fontSize: smFontSizeTable,
    },
  }
}));