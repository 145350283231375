import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import React from "react";
import {toGermanFormat} from "../../../../../../utils/numberFormater";

export const PortfolioWrapper = ({isVirtual, portfolio, classes, expanded, handleExpandedChange, children, sectionsCollapsable}) => {
  if(isVirtual && portfolio.data.isMP) return children;

  const getTitle = () => {
    let title;
    if (isVirtual){
      title = portfolio.data.name  // if it is virtual portfolio trade - use portfolio name as title
    } else{
      title = portfolio.data.not_connected ? portfolio.data.name : `${portfolio.data.name} (${toGermanFormat(portfolio.data.weight * 100, '', ' %')})`
      if (portfolio.categoryName){
        return <>{title} -&nbsp;<span className={classes.small}>{portfolio.categoryName}</span></>;
      }
    }
    return title;
  };

  return (
    <Accordion classes={{root: classes.accordionPortfolioRoot}}
               expanded={expanded.includes('portfolio')}
               onChange={handleExpandedChange('portfolio')}>

        {/* accordion header */}
        <AccordionSummary
          classes={{root: classes.accordionPortfolioHeader}}
          expandIcon={sectionsCollapsable && <ExpandMoreIcon htmlColor={"#fff"} />}
        >
          <Grid container>
            <Grid item xs={12}>
              {getTitle()}
            </Grid>
          </Grid>
        </AccordionSummary>

        {/* accordion body */}
        <AccordionDetails classes={{root: classes.accordionDetails}}>
          {children}
        </AccordionDetails>
      </Accordion>
  )
};

PortfolioWrapper.defaultProps = {
  sectionsCollapsable: true
}