import React from 'react';

import { withStyles } from '@material-ui/core'

import FiltersSection from "../FiltersSection";
import {FilterSection, FilterSubSection} from "../../ExtendedFilters";
import { DropdownInput } from "../../../../../components";
import { SearchIcon } from "../../../../../../../images";
import styles from '../FiltersSection/styles'
import {AssetInfoStaticMultilineSelectInput} from "../FundFiltersSection/FundFiltersSection";

class MyComponent extends FiltersSection {

  DEFAULT_VALUES = {
    name: {
      value: '',
      section: 'basic'
    },
    type: {
      value: [],
      section: 'basic'
    },
    couponStart: {
      value: '',
      section: 'numbers'
    },
    couponEnd: {
      value: '',
      section: 'numbers'
    },
    durationStart: {
      value: '',
      section: 'numbers'
    },
    durationEnd: {
      value: '',
      section: 'numbers'
    }
  }

  state = {
    name: '',
    type: [],
    couponStart: '',
    couponEnd: '',
    durationStart: '',
    durationEnd: ''
  }

  setDefaultFilters = () => {
    this.setState({
      errors: {},
      name: '',
      type: [],
      couponStart: '',
      couponEnd: '',
      durationStart: '',
      durationEnd: ''
    })
  }

  _buildFilters = () => {
    let filters = {}

    filters.name = this.state.name || null
    filters.type = this.state.type.map(t => t.value)

    // filters.coupon_start = this.state.couponStart
    // filters.coupon_end = this.state.couponEnd
    // filters.duration_start = this.state.durationStart
    // filters.duration_end = this.state.durationEnd

    return filters
  }

  getChangedFiltersTotalCount = () => {
    return this.getChangedFiltersCount(['name', 'type'])
  }

  render() {

    const { classes } = this.props

    return (
      <>
        <FilterSection
          title="Basisdaten"
          sectionName="basic"
          expandedByDefault={true}
          onResetClick={this.onValueReset}
          count={this.getChangedFiltersCount(['name', 'type'])}
        >
          <FilterSubSection
            title="Instrumentenname, ISIN, oder WKN suchen"
            resetButtonVisible={this.isFieldChanged('name')}
            onResetClick={this.onFieldReset}
            resetFieldsNames={'name'}
          >
            <DropdownInput
              value={this.state.name}
              onChange={this.onValueChange('name', this.handleNameFieldValue)}
              placeholder="Instrumentenname, ISIN, oder WKN suchen"
              autoApprove={false}
              onValueApprove={this.props.onSearchFieldApprove}
            />
          </FilterSubSection>
          <AssetInfoStaticMultilineSelectInput
            title="Art"
            value={this.state.type}
            onChange={this.onValueChange('type')}
            customClasses={classes}
            resetButtonVisible={this.isFieldChanged('type')}
            onResetClick={this.onFieldReset}
            icon={<SearchIcon color={this.INACTIVE_INPUT_COLOR} />}
            resetFieldsNames={'type'}
            withContainer={false}
            assetInfoFieldName="types"
          />
        </FilterSection>
      </>
    );
  }
}

export default withStyles(styles)(MyComponent);
