import React from "react";

import ProfitLossTable from "../../../../../../../../components/Charts/ProfitLossTable";
import DashboardCard from "../../../DashboardCard";
import _ from "lodash";

export default (props) => {
  const {
    data,
    expanded,
    onExpanded,
    isLast,
    expandedSubItems,
    onExpandedSubItems
  } = props;

  return (
    <DashboardCard
      title={data.name}
      content={<ProfitLossTable
        data={data}
        expanded={expanded}
        expandedSubItems={expandedSubItems}
        onExpandedSubItems={onExpandedSubItems}
      />}
      expandable
      expanded={expanded}
      onExpanded={onExpanded}
      table
      divider
      isLast={isLast}
    />
  );
}
