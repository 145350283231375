import React from "react";

export default () => {
  return (
    <svg
      id="Gruppe_8156"
      data-name="Gruppe 8156"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="30"
      height="26.668"
      viewBox="0 0 30 26.668"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_8036"
            data-name="Rechteck 8036"
            width="30"
            height="26.668"
            fill="#80858c"
          />
        </clipPath>
      </defs>
      <g
        id="Gruppe_8155"
        data-name="Gruppe 8155"
        transform="translate(0 0)"
        clip-path="url(#clip-path)"
      >
        <path
          id="Pfad_7819"
          data-name="Pfad 7819"
          d="M30,16.121H22.91V14.3a6.162,6.162,0,0,0,5.274-6.09V7.332H27.3A6.134,6.134,0,0,0,22.91,9.183V8.575A6.057,6.057,0,0,0,24.668,4.7a6.348,6.348,0,0,0-2.05-4.17L22.031,0l-.586.525a6.348,6.348,0,0,0-2.05,4.17,6.057,6.057,0,0,0,1.758,3.879v.609a6.134,6.134,0,0,0-4.395-1.851h-.879v.879a6.162,6.162,0,0,0,5.274,6.09v1.82H8.848V14.3a6.162,6.162,0,0,0,5.274-6.09V7.332h-.879A6.134,6.134,0,0,0,8.848,9.183V8.575A6.06,6.06,0,0,0,10.605,4.7,6.348,6.348,0,0,0,8.555.525L7.969,0,7.383.525A6.348,6.348,0,0,0,5.332,4.7,6.06,6.06,0,0,0,7.09,8.575v.608A6.134,6.134,0,0,0,2.7,7.332H1.816v.879A6.162,6.162,0,0,0,7.09,14.3v1.82H0V26.668H30ZM26.313,9.2a4.409,4.409,0,0,1-3.29,3.29,4.41,4.41,0,0,1,3.29-3.29m-8.564,0a4.41,4.41,0,0,1,3.29,3.29,4.409,4.409,0,0,1-3.29-3.29m-5.5,0a4.409,4.409,0,0,1-3.29,3.29,4.41,4.41,0,0,1,3.29-3.29m-8.564,0a4.41,4.41,0,0,1,3.29,3.29A4.409,4.409,0,0,1,3.687,9.2M22.031,2.494a3.826,3.826,0,0,1,.879,2.2,3.826,3.826,0,0,1-.879,2.2,3.827,3.827,0,0,1-.879-2.2,3.826,3.826,0,0,1,.879-2.2m-14.062,0a3.826,3.826,0,0,1,.879,2.2,3.826,3.826,0,0,1-.879,2.2A3.827,3.827,0,0,1,7.09,4.7a3.826,3.826,0,0,1,.879-2.2m20.274,18.9H22.91v1.758h5.332V24.91H1.758V21.394H7.09V19.637H1.758V17.879H28.242Zm-19.395,0h5.274v1.758H8.848Zm7.031-1.758h5.274v1.758H15.879Z"
          transform="translate(0 0)"
          fill="#80858c"
        />
      </g>
    </svg>
  );
};
