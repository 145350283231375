import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import clsx from 'clsx'
import { Select as MaterialUISelect, MenuItem } from '@material-ui/core';
import useStyles from './styles';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function Select(props) {

  const {
    options,
    style,
    value,
    onChange,
    width,
    withDefault,
    disabled,
    labelId,
    postNameContent
  } = props;

  const classes = useStyles();

  return (
    <div style={{...style, width: props.fullWidth ? '100%' : 'auto'}}>
      <MaterialUISelect
        labelId={labelId}
        variant="outlined"
        className={classes.selectRoot}
        classes={{
          selectMenu: classes.selectMenu,
        }}
        MenuProps={{
          classes: {
            paper: classes.selectMenuPaper,
          }
        }}
        IconComponent={ExpandMoreIcon}
        style={{width: props.width || '100%', ...props.selectStyles}}
        value={value}
        multiple={_.isArray(value)}
        disabled={disabled}
        onChange={(event) => onChange(event.target.value)}
      >
        {withDefault &&
        <MenuItem
          disableRipple
          disableTouchRipple
          classes={{
            root: classes.selectMenuItemRoot
          }} value="">-</MenuItem>
        }

        {options.map(option => (
          <MenuItem
            disableRipple
            disableTouchRipple
            key={option.id}
            value={option.id}
            classes={{
              root: clsx(classes.selectMenuItemRoot, option.depth === 1 && 'bold')
            }}
            style={{
              paddingLeft: option.depth ? option.depth * 10 + 17 : 17,
            }}
          >
            { option.name }
            { postNameContent && postNameContent(option) }
          </MenuItem>
        ))}
      </MaterialUISelect>
    </div>
  )
}

Select.propTypes = {
  /** Available options */
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired
  })),

  fullWidth: PropTypes.bool
}

Select.defaultProps = {
  options: [],
  fullWidth: false,
  withDefault: true,
  disabled: false
}

export default Select

