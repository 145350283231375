import {makeStyles} from '@material-ui/core';

export default makeStyles(() => ({
  container: {},
  formLabel: {
    color: '#313D44',
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    marginBottom: 8
  },
  focusedLabel: {
    color: '#313D44 !important'
  },
  formControlLabel: {
    fontSize: 14
  },
  radio: {
    padding: 5,
    marginLeft: 4
  }
}))