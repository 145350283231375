import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 34,
    color: '#202A38',
    margin: 0
  },
  loadingContainer: {
    width: '100%',
    height: '25vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableHead: {
    '& td': {
      color: '#80858C',
    },
  },
  tableCell: {
    height: 51,
    verticalAlign: 'middle',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    '&:first-child': {
      width: 24
    },
    '&:last-child': {
      verticalAlign: 'baseline',
      width: 370,
      '& > div': {
        position: 'relative',
        width: '100%'
      }
    }
  },
}));