import React from "react";
import { withRouter } from "react-router";
import _ from "lodash";

import PortfolioPaymentPlans from "./components/PortfolioPaymentPlans";
import {getPortfolioTradings} from "../../../../../../components/TradingStore/utils";
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: "'Roboto-Regular'",
    fontSize: 20,
    color: '#4D4F5C',
    margin: 0,
    marginTop: 5,
    marginBottom: 5,
    flex: 1,

    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  emptyMessage: {
    margin: 0,
    fontSize: 22,
    color: '#b1b1b1',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  headerContainer: {
    marginBottom: 16,
    display: 'flex',
    zIndex: 1,
    marginTop: 20,

    '&:first-child': {
      marginTop: 0
    },

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
}))


const PaymentPlans = (props) => {

  const {
    payoutPlans,
    payoutPlansExpandedItems,
    onExpandedItemsChange,
    getSavingPlanTradeOptions,
    getPayoutPlanTradeOptions,
    getSwitchPlanTradeOptions,
    paymentPlans,
    paymentPlansExpandedItems,
    switchPlansExpandedItems,
    switchPlans,
  } = props;

  const classes = useStyles()

  const _handleExpandedItemsChange = (items, key, id, expanded) => {
    if (expanded) {
      onExpandedItemsChange([...items, id], key);
    } else {
      onExpandedItemsChange(items.filter(item => item !== id), key);
    }
  }

  const handleExpandedItemsChange = (id, expanded) => {
    _handleExpandedItemsChange(paymentPlansExpandedItems, 'paymentPlansItems', id, expanded)
  };

  const handlePayoutExpandedItemsChange = (id, expanded) => {
    _handleExpandedItemsChange(payoutPlansExpandedItems, 'payoutPlansItems', id, expanded)
  };

  const handleSwitchExpandedItemsChange = (id, expanded) => {
    _handleExpandedItemsChange(switchPlansExpandedItems, 'switchPlansItems', id, expanded)
  };

  const getTradingOptions = (item) => {
    const tradingOptions = getSavingPlanTradeOptions && getSavingPlanTradeOptions(item);
    if(!!tradingOptions){
      const tradeData = getPortfolioTradings(tradingOptions.savingPlans, item.id);
      return {
        paymentPlan: item,
        tradeAction: tradeData && tradeData.action,
        tradePlans: tradeData ? tradeData.instruments : [],
        tradeTransactions: _.get(tradingOptions.savingsPlanSession, item.id),
        onAddSavingPlanOption: tradingOptions.onAddSavingPlanOption,
        disabled: !!tradingOptions.savingsPlanSession,  // disabled in case there are Session transactions
      }
    }
  };

  const getPayoutTradingOptions = (item) => {
    const tradingOptions = getPayoutPlanTradeOptions && getPayoutPlanTradeOptions(item);
    if(!!tradingOptions){
      const tradeData = getPortfolioTradings(tradingOptions.payoutPlans, item.id);
      return {
        paymentPlan: item,
        tradeAction: tradeData && tradeData.action,
        tradePlans: tradeData ? tradeData.instruments : [],
        tradeTransactions: _.get(tradingOptions.payoutPlanSession, item.id),
        onAddSavingPlanOption: tradingOptions.onAddPayoutPlanOption,
        disabled: !!tradingOptions.payoutPlanSession,  // disabled in case there are Session transactions
      }
    }
  };

  const getSwitchTradingOptions = (item) => {
    const tradingOptions = getSwitchPlanTradeOptions && getSwitchPlanTradeOptions(item);
    if(!!tradingOptions){
      const tradeData = getPortfolioTradings(tradingOptions.switchPlans, item.id);
      return {
        paymentPlan: item,
        tradeAction: tradeData && tradeData.action,
        tradePlans: tradeData ? tradeData.instruments : [],
        tradeTransactions: _.get(tradingOptions.switchPlanSession, item.id),
        onAddSavingPlanOption: tradingOptions.onAddSwitchPlanOption,
        disabled: !!tradingOptions.switchPlanSession,  // disabled in case there are Session transactions
      }
    }
  };

  const renderData = () => {
    return paymentPlans && paymentPlans.map((item, index) => (
      <PortfolioPaymentPlans
        key={item.id}
        data={item}
        isLast={index === paymentPlans.length - 1}
        expanded={paymentPlansExpandedItems.includes(item.id)}
        onExpanded={(expanded) => handleExpandedItemsChange(item.id, expanded)}
        tradingOptions={getTradingOptions(item)}
        paymentPlanType="savings_plan"
      />
    ))
  };

  const renderPayoutPlansData = () => {
    return payoutPlans && payoutPlans.map((item, index) => (
      <PortfolioPaymentPlans
        key={item.id}
        data={item}
        isLast={index === payoutPlans.length - 1}
        expanded={payoutPlansExpandedItems.includes(item.id)}
        onExpanded={(expanded) => handlePayoutExpandedItemsChange(item.id, expanded)}
        tradingOptions={getPayoutTradingOptions(item)}
        paymentPlanType="payout_plan"
      />
    ))
  }

  const renderSwitchPlansData = () => {
    return switchPlans && switchPlans.map((item, index) => (
      <PortfolioPaymentPlans
        key={item.id}
        data={item}
        isLast={index === switchPlans.length - 1}
        expanded={switchPlansExpandedItems.includes(item.id)}
        onExpanded={(expanded) => handleSwitchExpandedItemsChange(item.id, expanded)}
        tradingOptions={getSwitchTradingOptions(item)}
        paymentPlanType="switch_plan"
        skipMPGrouping
      />
    ))
  }

  return (
    <>
      <div className={classes.headerContainer}>
        <p className={classes.header}>Sparpläne</p>
      </div>
      {paymentPlans && paymentPlans.length > 0 ? (
        <>
          {renderData()}
        </>
      ) : (
        <p className={classes.emptyMessage}>Keine Daten verfügbar.</p>
      )}

      <div className={classes.headerContainer}>
        <p className={classes.header}>Entnahmepläne</p>
      </div>
      {payoutPlans && payoutPlans.length > 0 ? (
        <>
          {renderPayoutPlansData()}
        </>
      ) : (
        <p className={classes.emptyMessage}>Keine Daten verfügbar.</p>
      )}

      <div className={classes.headerContainer}>
        <p className={classes.header}>Tauschpläne</p>
      </div>
      {switchPlans && switchPlans.length > 0 ? (
        <>
          {renderSwitchPlansData()}
        </>
      ) : (
        <p className={classes.emptyMessage}>Keine Daten verfügbar.</p>
      )}
    </>
  );

};

export default withRouter(PaymentPlans);
