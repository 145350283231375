import { withStyles } from "@material-ui/core";
import React from "react";
import styles from "./styles";
import CheckCircle from "@material-ui/icons/CheckCircle";
import MessageDialog from "../MessageDialog";


class SuccessDialog extends React.Component {

  render() {

    const { classes } = this.props;
    return (
      <MessageDialog
        icon={<CheckCircle className={classes.checkCircle}/>}
        message={this.props.message}
        open={this.props.open}
        onClose={this.props.onClose}
      />
    )
  }
}

export default withStyles(styles)(SuccessDialog);
