import {makeStyles} from '@material-ui/core';
import { FORM_FIELD_HEIGHT } from '../../../TransactionsMonitoring/components/Filters/components/common_styles';

export default makeStyles(theme => ({
  expansionPanelContainer: {
    position: 'relative',
    height: 66,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      height: 56
    },
  },
  expansionPanelRoot: {
    position: 'absolute',
    borderRadius: '2px !important',
    height: FORM_FIELD_HEIGHT,
    zIndex: 2,
    top: 'calc(100% - 45px)',
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: 'none',

    [theme.breakpoints.down('sm')]: {
      height: 35,
      top: 'calc(100% - 35px)',
    },
  },
  expansionPanelExpanded: {
    height: 'auto',
    zIndex: 1000,
    transform: 'translate3d(0,0,1px)',
    margin: '0 !important',
  },
  panelButton: {
    minHeight: 40,
    [theme.breakpoints.down('sm')]: {
      minHeight: 35
    },
  },
  questionIcon: {
    color: '#23D374',
    background: 'rgba(35,211,116,0.12)',
    '&:hover': {
      background: 'rgba(35,211,116,0.2)'
    }
  },
  label: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#4D4F5C',
    marginRight: 20,

    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
  },
  btnText: {
    whiteSpace: 'normal'
  }
}));
