import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  grid: {
    [theme.breakpoints.up('md')]: {
      '&.customGrid-md-5': {
        flexBasis: '46% !important',
        maxWidth: '46% !important'
      },

      '&.customGrid-md-5 .MuiGrid-grid-md-6': {
        flexBasis: '44.8% !important',
        maxWidth: '44.8% !important'
      },

      '&.customGrid-md-5 .MuiGrid-grid-md-3': {
        flexBasis: '27.6% !important',
        maxWidth: '27.6% !important'
      }
    },

    '@media ((min-width: 1100px) and (max-width: 1279px)) or (min-width: 1400px)': {
      '&.customGrid-md-5': {
        flexBasis: '48% !important',
        maxWidth: '48% !important'
      },

      '&.customGrid-md-5 .MuiGrid-grid-md-6': {
        flexBasis: '38.8% !important',
        maxWidth: '38.8% !important'
      },

      '&.customGrid-md-5 .MuiGrid-grid-md-3': {
        flexBasis: '30.6% !important',
        maxWidth: '30.6% !important'
      }
    }
  },
  slider: {
    marginBottom: 3
  }
}));