import React from "react";

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="Gruppe_8165" data-name="Gruppe 8165" transform="translate(-2 0)">
        <g id="Gruppe_8274" data-name="Gruppe 8274" transform="translate(0 0)">
          <g id="Gruppe_8275" data-name="Gruppe 8275" transform="translate(2)">
            <path
              id="Pfad_7809"
              data-name="Pfad 7809"
              d="M2.637,22.969a2.637,2.637,0,0,0-.766,5.16,2.637,2.637,0,0,0,5.092-1.363L9,24.727H21l2.04,2.039a2.637,2.637,0,0,0,5.091,1.363,2.637,2.637,0,0,0-1.363-5.091L23.026,19.3l1.7-.851v-7.02a9.625,9.625,0,0,0-.248-2.175l2.288-2.288a2.637,2.637,0,0,0,1.363-5.091,2.637,2.637,0,0,0-5.091,1.363L21.769,4.5a9.721,9.721,0,0,0-13.539,0L6.963,3.234A2.637,2.637,0,0,0,1.871,1.871,2.637,2.637,0,0,0,3.234,6.963L5.522,9.251a9.625,9.625,0,0,0-.248,2.175v7.02l1.7.851L3.234,23.038a2.63,2.63,0,0,0-.6-.069m23.74,2.166.574-.306a.871.871,0,0,1,.412-.1.879.879,0,0,1,0,1.758h-.879v.879a.879.879,0,0,1-1.758,0,.864.864,0,0,1,.1-.412l.306-.574-3.923-3.923V20.2l.157-.079ZM25.134,3.623c-.238-.491-.408-.642-.408-.986a.879.879,0,0,1,1.758,0v.879h.879a.879.879,0,0,1,0,1.758c-.341,0-.484-.164-.987-.408L23.841,7.4a9.578,9.578,0,0,0-.918-1.568ZM15,3.516a7.949,7.949,0,0,1,7.969,7.91V17.36l-3.516,1.758v3.851H17.7V19.453H15.938v3.516H14.062V19.453H12.3v3.516H10.547V19.118L7.031,17.36V11.426A7.949,7.949,0,0,1,15,3.516M3.623,4.866c-.492.239-.643.408-.987.408a.879.879,0,1,1,0-1.758h.879V2.637a.879.879,0,0,1,1.758,0c0,.343-.169.494-.408.986L7.076,5.834A9.61,9.61,0,0,0,6.159,7.4Zm5.009,15.26.157.079v2.25L4.866,26.377c.238.491.408.642.408.986a.879.879,0,0,1-1.758,0v-.879H2.637a.879.879,0,0,1,0-1.758c.341,0,.484.164.987.408Z"
              fill="#fff"
            />
            <path
              id="Pfad_7810"
              data-name="Pfad 7810"
              d="M23.225,21.541H17.951v5.274h5.274Zm-1.758,3.516H19.709V23.3h1.758Z"
              transform="translate(-9.162 -10.995)"
              fill="#fff"
            />
            <path
              id="Pfad_7811"
              data-name="Pfad 7811"
              d="M37.826,21.541H32.552v5.274h5.274Zm-1.758,3.516H34.31V23.3h1.758Z"
              transform="translate(-16.614 -10.995)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
