import React from 'react';
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";

import useStyles from './styles';
import {useStyles as useInputStyles} from '../../../../containers/RiskProfiling/components/StepContent/components/formElement/styles'
import CustomersSelectionModal
  from "../../../../containers/ProductComparison/components/CustomerSelectionModal/ProductSourceSelectionModal";
import {useDownloadPdfDialogContext} from "../../DownloadPdfModal";
import {FieldTitle} from "../index";
import WarningTooltip from "../../../WarningTooltip";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import PlusIcon from "../../../../images/PlusIcon";


function Customers() {

  const classes = useStyles();
  const inputClasses = useInputStyles();

  const {state, handleStateValueChange} = useDownloadPdfDialogContext();

  const [customerSelectionModalOpen, setCustomerSelectionModalOpen] = React.useState(false);

  const closeModal = () => setCustomerSelectionModalOpen(false);

  const customers = state.customers || [];

  const unselectCustomer = (customer) => () => {
    handleStateValueChange('customers', _.filter(customers, (c) => c.customer_id != customer.customer_id))
  }

  const handleSelect = (customers) => {
    handleStateValueChange('customers', customers)
    closeModal();
  }

  if (!state.editorEnabled) {
    return null;
  }

  return (
    <div className={classes.container}>
      <FieldTitle>
        Kundenauswahl
        <WarningTooltip
          title="Hier können Sie die Kunden auswählen, für welche das Anschreiben generiert werden soll."
        />
      </FieldTitle>
      {customers.length > 0 && (
        <div className={classes.customersList}>
          {customers.map((customer) => (
            <Grid container className={clsx(inputClasses.inputRoot, classes.fileContainer, 'MuiInputLabel-formControl')}>
              <Grid item className={classes.previewLink}>
                {customer.customer_full_name}
              </Grid>
              <Grid item className={classes.removeDocumentWrapper}>
                <IconButton onClick={unselectCustomer(customer)} disableRipple aria-label="close" >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </div>
      )}
      <InputLabel onClick={() => setCustomerSelectionModalOpen(true)} classes={{root: clsx(inputClasses.inputRoot, classes.label)}}>
        Kunden hinzufügen
        <Button variant="contained" color="primary" component="span" classes={{root: classes.endAdornmentBtn}} >
          Durchsuchen
        </Button>
      </InputLabel>
      <CustomersSelectionModal
        open={customerSelectionModalOpen}
        selectedCustomersIds={customers.map((customer) => customer.customer_id)}
        onClose={closeModal}
        onSelect={handleSelect}
      />
    </div>
  )
}

Customers.propTypes = {}

Customers.propDefs = {}

Customers.defaultProps = {}

export default Customers;