const styles = theme => ({
  root: {
    minHeight: 150,
    width: '100%'
  },
  chart: {
    width: '100%'
  },
  container: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& > div': {
      width: '100%'
    }
  },
  subline: {
    marginTop: 'auto',
  },
  description: {
    margin: 0,
    fontSize: 12,
    color: '#8D8D8D',
  },
   title: {
    fontFamily: 'Roboto-Bold',
    width: '50%',
    textAlign: 'left'
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    width: '25%'
  },
  table: {
    display: 'flex',
    '& > ul': {
      marginLeft: 15,
    }
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    padding: '7px',
    borderBottom: 'solid 1px #D8DCDF',
    '&:last-of-type': {
      marginBottom: 6,
    }
  },
  headline: {
    fontFamily: 'Roboto-Bold',
    textAlign: 'right',
    backgroundColor: '#F5F6F7',
    minHeight: 30,
    padding: '4px 7px 0 7px',
    borderBottom: 'none',
    '& > span': {
      color: '#80858C',
      fontFamily: 'Roboto-Bold',
    }
  },
  values: {
    width: '50%',
    textAlign: 'right'
  },
});

export default styles;