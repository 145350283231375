import React from "react";
import {Grid, withStyles} from "@material-ui/core";
import styles from "./styles";
import {VirtualPortfolioHandlerResource} from "../../../../utils/api";
import ChartAssetClassBreakdown from "../PortfolioStructure/items/ChartAssetClassBreakdown";
import ChartCurrencyBreakdown from "../PortfolioStructure/items/ChartCurrencyBreakdown";
import ChartSectorBreakdown from "../PortfolioStructure/items/ChartSectorBreakdown";
import ChartRegionBreakdown from "../PortfolioStructure/items/ChartRegionBreakdown";
import {toGermanFormat} from "../../../../utils/numberFormater";
import ChartMaturityBreakdown from "./items/ChartMaturityBreakdown";
import ChartCreditQualityBreakdown from "./items/ChartCreditQualityBreakdown";
import ChartFixedIncomeSubTypeBreakdown from "./items/ChartFixedIncomeSubTypeBreakdown";
import ChartAssetCategoryBreakdown from "./items/ChartAssetCategoryBreakdown";


export const formatPercentage = (number, multiplier)=> {
    multiplier = multiplier || 1;
    return number || number === 0 ? toGermanFormat(number * multiplier, '', '', 2) + '%' : '-'
}


class PortfolioStructureTab extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dataProvider: VirtualPortfolioHandlerResource,

            portfolioBreakdownData: null,
            portfolioBreakdownLoading: true,
            portfolioBreakdownError: null,
            hasSecondaryBreakdown: false,
        }
        this.fetchPortfolioBreakdownData = this.fetchPortfolioBreakdownData.bind(this);
    }

    componentDidMount() {
        this.fetchPortfolioBreakdownData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.portfolioBreakdownData !== prevState.portfolioBreakdownData) {
            this.setState({
                hasSecondaryBreakdawn: this.hasSecondaryBreakdawn()
            });
        }
    }

    fetchPortfolioBreakdownData() {
        this.state.dataProvider.getPortfolioBreakdownData(this.props.customerId, [this.props.portfolioId], undefined, undefined)
            .then(
                (data) => {
                    let processedData = data['breakdown'];
                    if (processedData['errors'].length > 0) {
                        this.setState({
                            portfolioBreakdownError: processedData['errors'][0],
                            portfolioBreakdownData: null,
                            portfolioBreakdownLoading: false
                        })
                    } else {
                        this.setState({
                            portfolioBreakdownData: processedData['data'][0],
                            portfolioBreakdownLoading: false,
                            portfolioBreakdownError: null
                        })
                    }
                })
            .catch((error) => {
                this.setState({
                    portfolioBreakdownError: error,
                    portfolioBreakdownLoading: false,
                    portfolioBreakdownData: false
                })
            })
    }

    hasSecondaryBreakdawn() {
        let data = this.state.portfolioBreakdownData.portfolio_structure;

        return (
          (data.by_maturity && data.by_maturity.length > 0) |
          (data.by_credit_quality && data.by_credit_quality.length > 0) |
          (data.by_fixed_income_sub_type && data.by_fixed_income_sub_type.length > 0)
        )
    }

    render() {
        return <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
               <ChartAssetClassBreakdown
                    data={this.state.portfolioBreakdownData}
                    loading={this.state.portfolioBreakdownLoading}
                    error={this.state.portfolioBreakdownError}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <ChartCurrencyBreakdown
                    data={this.state.portfolioBreakdownData}
                    loading={this.state.portfolioBreakdownLoading}
                    error={this.state.portfolioBreakdownError}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <ChartAssetCategoryBreakdown
                    data={this.state.portfolioBreakdownData}
                    loading={this.state.portfolioBreakdownLoading}
                    error={this.state.portfolioBreakdownError}
                />
            </Grid>
            <Grid item xs={12} md={12} lg={8}>
               <ChartRegionBreakdown
                    data={this.state.portfolioBreakdownData}
                    loading={this.state.portfolioBreakdownLoading}
                    error={this.state.portfolioBreakdownError}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <ChartSectorBreakdown
                    data={this.state.portfolioBreakdownData}
                    loading={this.state.portfolioBreakdownLoading}
                    error={this.state.portfolioBreakdownError}
                />
            </Grid>
            {this.state.hasSecondaryBreakdawn
              ? <Grid item xs={12} sm={6} md={4}>
                  <ChartMaturityBreakdown
                    data={this.state.portfolioBreakdownData}
                    loading={this.state.portfolioBreakdownLoading}
                    error={this.state.portfolioBreakdownError}
                  />
              </Grid>
              : ''
            }
            {this.state.hasSecondaryBreakdawn
              ? <Grid item xs={12} sm={6} md={4}>
                  <ChartCreditQualityBreakdown
                    data={this.state.portfolioBreakdownData}
                    loading={this.state.portfolioBreakdownLoading}
                    error={this.state.portfolioBreakdownError}
                  />
              </Grid>
              : ''
            }
            {this.state.hasSecondaryBreakdawn
            ? <Grid item xs={12} sm={6} md={4}>
                <ChartFixedIncomeSubTypeBreakdown
                    data={this.state.portfolioBreakdownData}
                    loading={this.state.portfolioBreakdownLoading}
                    error={this.state.portfolioBreakdownError}
                />
            </Grid>
            : ''
            }
        </Grid>
    }
}

export default withStyles(styles)(PortfolioStructureTab);
