import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  title: {
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
  },
  tableCell: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38',
    '&:nth-of-type(1)': {
      maxWidth: 370,
      [theme.breakpoints.down('md')]: {
        maxWidth: 300,
      }
    },
    '&.sustainability-cell': {
      width: 83,
      minWidth: 83
    },
    '&.date-picker-cell': {
      '& > div': {
        display: 'flex',
        flexWrap: 'nowrap'
      },
      '& > div > span > svg': {
        position: 'relative'
      }
    },
    '&:last-child': {
      width: 65,
      verticalAlign: 'inherit',
    }
  },
  instrumentsTable: {
    border: '2px solid #e8eaec',
    borderRadius: 8,
    padding: 15,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  dialogPaper: {
    padding: '24px 24px 16px',
  },
  addBtnContainer: {
    padding: '1rem 0',
    '& .Mui-error': {
      fontSize: 14,
      fontFamily: 'Roboto-Regular',
    }
  },
  switchInfoText: {
    borderLeft: '0.25rem solid #1a75b1',
    minHeight: 35,
    display: 'flex',
    alignItems: 'center',
    margin: '18px 0',
    paddingLeft: 5,

    '& > p': {
      fontFamily: 'Roboto-Bold',
      fontStyle: 'italic',
      fontSize: 16,
      color: '#1a75b1',
      margin: 0
    },

    '& > svg': {
      height: 16,
      marginRight: 5,

      '& > path': {
        fill: '#1a75b1',
      }
    }
  }
});

export default makeStyles(styles)