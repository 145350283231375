import { ERROR_BACKGROUND, ERROR_COLOR, SUCCESS_BACKGROUND, SUCCESS_COLOR } from '../../../../themes/themeConfigurator';
import { makeStyles } from '@material-ui/core';

export const sliderStyles = theme => ({
  root: {
    height: 12,
    padding: 0,
  },
  thumb: {
    display: 'none'
  },
  track: {
    height: 8,
    borderRadius: 4,
    '&.red': {
      color: ERROR_COLOR
    },
    '&.green': {
      color: SUCCESS_COLOR
    }
  },
  rail: {
    height: 8,
    borderRadius: 4,
    '&.red': {
      color: ERROR_BACKGROUND
    },
    '&.green': {
      color: SUCCESS_BACKGROUND
    }
  }
});

export default makeStyles((theme) => ({
  values: {
    textAlign: 'right',
    fontFamily: 'Roboto-Bold',
  }
}));