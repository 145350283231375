import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { PrimaryButton } from '../../../../components/Buttons';
import InputFormElement from '../../../RiskProfiling/components/StepContent/components/formElement/InputFormElement';
import SelectFormElement from '../../../RiskProfiling/components/StepContent/components/formElement/SelectFormElement';
import { PortfolioHandlerResource } from '../../../../utils/api';
import {
  displayErrorSnackBar,
  displaySuccessSnackBar,
} from '../../../../components/SnackbarProvider/actions';
import { EDIT_ALERT_ACTIONS } from '../../constants';
import {
  handleConfiguration,
  syncPortfolioAlerts,
} from '../../utils';
import EventsList from './EventsList';
import {
  ALERT_PRODUCT_TYPE,
  ALERT_PRODUCT_TYPE_TEXT,
  FIELDS_TO_CHECK_OPTIONS,
  FREQUENCY_OPTIONS,
  PROFIT_LOSS_VALUE,
  NOTHING_SELECTED_VALUE,
  getCalculationPeriodOptions,
} from './utils';
import useStyles from './styles';

const AlertConfigurationModalContext = React.createContext({
});

export function useAlertConfigurationModalContext() {

  const context = React.useContext(AlertConfigurationModalContext)
  if (!context) {
    throw new Error('Extracting context without wrapping your component with context Provider!')
  }

  return context

}

function getFieldToValidateConfiguration(value, product, productType) {
  const configuration =  _.cloneDeep(FIELDS_TO_CHECK_OPTIONS.find((option) => option.value == value))
  if (_.isFunction(configuration.getValueCallback)) {
    const actualValue = configuration.getValueCallback(product, productType)
    for (let fieldName in configuration.state) {
      configuration.state[fieldName].actualValue = actualValue
      configuration.state[fieldName].value = actualValue[fieldName] || actualValue.value
    }
  }

  return configuration
}


function AlertConfigurationModal({activeConfiguration, productType, open, onClose, name, onSave, configurations, loading, actionDisabled, trackingStartDate, allowCreateNew}) {
  const classes = useStyles()

  const [alertConfig, setAlertConfig] = React.useState({
    calculationPeriod: 1,
    frequency: 1,
    fieldToValidate: NOTHING_SELECTED_VALUE,
  })

  const [validationErrors, setValidationErrors] = React.useState({})

  // Clean configuration after modal closed
  React.useEffect(() => {
    if (open) {
      setAlertConfig({
        calculationPeriod: 1,
        frequency: 1,
        fieldToValidate: NOTHING_SELECTED_VALUE
      })
      setValidationErrors({})
    }

  }, [open])

  React.useEffect(() => {
    if (alertConfig.skipInit) return;

    if (alertConfig.fieldToValidate == NOTHING_SELECTED_VALUE || _.isUndefined(alertConfig.fieldToValidate)) {
      setAlertConfig((alertConfig) => ({
        calculationPeriod: alertConfig.calculationPeriod,
        frequency: alertConfig.frequency,
        fieldToValidate: alertConfig.fieldToValidate || NOTHING_SELECTED_VALUE
      }))
    } else {
      setAlertConfig((alertConfig) => ({
        ...alertConfig,
        fields: {...getFieldToValidateConfiguration(alertConfig.fieldToValidate, activeConfiguration, productType).state}
      }))
    }
    setValidationErrors({})
  }, [alertConfig.fieldToValidate])

  const handleMinMaxInputChange = (fieldName) => (value) => {
    setAlertConfig((alertConfiguration) => ({
      ...alertConfiguration,
      skipInit: false,
      fields: {
        ...alertConfiguration.fields,
        [fieldName]: {
          ...alertConfiguration.fields[fieldName],
          value
        }
      }
    }))
    setValidationErrors({})
  }

  const handleSelectValueChange = (fieldName) => (value) => {
    setAlertConfig((alertConfiguration) => ({
      ...alertConfiguration,
      skipInit: false,
      [fieldName]: value
    }))
  }

  const validateAlertConfig  = () => {
    const errors = Object
      .entries(alertConfig.fields || {})
      .reduce((errors, [fieldName, fieldConfig]) => {
        if (typeof fieldConfig.value != 'number') {
          errors[fieldName] = true
        }
        return errors
      }, {})

    const errorExists = alertConfig.fieldToValidate !== NOTHING_SELECTED_VALUE && Object.keys(errors).length == Object.keys(alertConfig.fields || {}).length

    setValidationErrors(errorExists ? errors : {})
    return !errorExists
  }

  const handleSaveButtonClick = (event, closeModal=false) => {

    if (!onSave || !validateAlertConfig()) return;

    const _alertConfig = {
      field_to_validate: alertConfig.fieldToValidate
    }

    if (alertConfig.fieldToValidate != NOTHING_SELECTED_VALUE) {
      _alertConfig['calculation_period'] = alertConfig.calculationPeriod
      _alertConfig['frequency'] = alertConfig.frequency
      _alertConfig['configuration'] = {}
      Object.entries(alertConfig.fields).forEach(([fieldName, fieldConfig]) => {
        _alertConfig.configuration[fieldName] = fieldConfig.value
      })
    }

    onSave(_alertConfig, EDIT_ALERT_ACTIONS.CREATE, closeModal)

  }

  const handleSaveAndCloseButtonClick = () => handleSaveButtonClick(undefined, true)

  const handleDeleteButtonClick = (configuration) => onSave && onSave(configuration, EDIT_ALERT_ACTIONS.DELETE)
  const handleActivateButtonClick = (configuration) => onSave && onSave(configuration, EDIT_ALERT_ACTIONS.ACTIVATE)
  const handleCancelButtonClick = (configuration) => onSave && onSave(configuration, EDIT_ALERT_ACTIONS.CANCEL)

  const fieldToValidateOptions = React.useMemo(() => {
    if (!productType) {
      return []
    }
    return FIELDS_TO_CHECK_OPTIONS.filter((option) => option.enabledFor.includes(productType) )
  }, [productType])

  const [activeConfigurations, inactiveConfigurations] = (() => {

    if (_.isEmpty(configurations)) {
      return [[], []]
    }

    return [_.filter(configurations, (configuration) => configuration.active),
      _.filter(configurations, (configuration) => !configuration.active)]

  })()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        root: classes.dialogRoot
      }}
      maxWidth="lg"
      PaperProps={{
        style: {
          width: 900
        }
      }}
    >
      <DialogTitle
        classes={{
          root: classes.dialogTitleRoot
        }}
      >
        {ALERT_PRODUCT_TYPE_TEXT[productType]} - {name}
        <IconButton
          onClick={onClose}
          className={classes.dialogCloseButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{root: classes.dialogContentRoot}}>
        <Grid container spacing={1}>
          {allowCreateNew && (
            <>
              <Grid item sm={6} xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={10}>
                    {loading ? (
                      <>
                        <div className={classes.itemTitle}>
                          <span style={{marginBottom: 0}}>Alarm einstellen für</span>
                        </div>
                        <Skeleton style={{height: 83.34}}/>
                      </>
                    ) : (
                      <SelectFormElement
                        value={alertConfig.fieldToValidate}
                        onChange={handleSelectValueChange('fieldToValidate')}
                        label={"Alarm einstellen für"}
                        options={fieldToValidateOptions}
                        custom_classes={{
                          labelRoot: classes.selectLabelRoot
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6} xs={12} >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={10}>
                    {loading ? (
                      <>
                        <div className={classes.itemTitle}>
                          <span style={{marginBottom: 0}}>Benachrichtigung</span>
                        </div>
                        <Skeleton style={{height: 83.34}}/>
                      </>
                    ) : (
                      <SelectFormElement
                        value={alertConfig.frequency}
                        onChange={handleSelectValueChange('frequency')}
                        label={"Benachrichtigung"}
                        options={FREQUENCY_OPTIONS}
                        custom_classes={{
                          labelRoot: classes.selectLabelRoot
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {alertConfig.fieldToValidate != NOTHING_SELECTED_VALUE && alertConfig.hasOwnProperty('fields') && (
                <>
                  <Grid item sm={6} xs={12}>
                    <Grid container spacing={1}>
                      {Object.entries(alertConfig.fields).map(([fieldName, fieldConfigs]) => (
                        <Grid item xs={12} md={5} sm={6}>
                          <InputFormElement
                            label={fieldConfigs.label}
                            value={alertConfig.fields[fieldName].value || 0}
                            onChange={handleMinMaxInputChange(fieldName)}
                            inputComponent={fieldConfigs.inputComponent[0]}
                            inputComponentProps={fieldConfigs.inputComponent[1] || {}}
                            error={fieldName in validationErrors}
                            custom_classes={{
                              labelRoot: classes.inputLabelRoot
                            }}
                          />
                          {fieldConfigs.formatValue && fieldConfigs.withActualHint && (fieldConfigs.actualValue[fieldName] || fieldConfigs.actualValue.value) && (
                            <b>Aktuell: {fieldConfigs.formatValue(fieldConfigs.actualValue[fieldName] || fieldConfigs.actualValue.value)}</b>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item sm={6} xs={12}></Grid>
                  {alertConfig.fieldToValidate == PROFIT_LOSS_VALUE && (
                    <Grid item sm={6} xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={10}>
                          {loading ? (
                            <>
                              <div className={classes.itemTitle}>
                                <span style={{marginBottom: 0}}>ab dem Berechnungszeitraum</span>
                              </div>
                              <Skeleton style={{height: 83.34}}/>
                            </>
                          ) : (
                            <SelectFormElement
                              value={alertConfig.calculationPeriod}
                              onChange={handleSelectValueChange('calculationPeriod')}
                              label={"ab dem Berechnungszeitraum"}
                              options={getCalculationPeriodOptions(trackingStartDate)}
                              custom_classes={{
                                labelRoot: classes.selectLabelRoot
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {!_.isEmpty(validationErrors) && (
                <Grid item xs={12}>
                  <p className={classes.error}>Mindestens eines der Felder muss gefüllt sein</p>
                </Grid>
              )}
            </>
          )}

          {!_.isEmpty(activeConfigurations) && (
            <Grid item xs={12}>
              <EventsList
                events={activeConfigurations || []}
                productType={productType}
                title={activeConfigurations.length > 1 ? 'Aktivierte Alarme' : 'Aktivierter Alarm'}
                primary
                onDeleteClick={handleDeleteButtonClick}
                onCancelClick={handleCancelButtonClick}
                expanded={!allowCreateNew}
              />
            </Grid>
          )}
          {!_.isEmpty(inactiveConfigurations) && (
            <Grid item xs={12}>
              <EventsList
                events={inactiveConfigurations}
                productType={productType}
                title={inactiveConfigurations.length > 1 ? 'Deaktivierte Alarme' : 'Deaktivierter Alarm'}
                onActivateClick={handleActivateButtonClick}
                onDeleteClick={handleDeleteButtonClick}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {allowCreateNew && (
        <DialogActions className={classes.dialogActions}>
          <PrimaryButton
            text='Abbrechen'
            variant='text'
            onButtonClick={onClose}
            disabled={actionDisabled}
          />
          <PrimaryButton
            text='Speichern'
            onButtonClick={handleSaveButtonClick}
            disabled={actionDisabled || alertConfig.fieldToValidate == NOTHING_SELECTED_VALUE}
          />
          <PrimaryButton
            text='Speichern & schließen'
            onButtonClick={handleSaveAndCloseButtonClick}
            disabled={actionDisabled || alertConfig.fieldToValidate == NOTHING_SELECTED_VALUE}
          />
        </DialogActions>
      )}

    </Dialog>
  )
}

function AlertConfigurationWrapper({children, customer_id, onAlertConfigurationSaved, dispatch, instrumentsRiskMetricsData, allowCreateNew=true}) {

  const [activeAsset, setActiveAsset] = React.useState(undefined)
  const [activeDepot, setActiveDepot] = React.useState(undefined)

  const [modalOpen, setModalOpen] = React.useState(false)

  const [configurationSavingStatus, setConfigurationSavingStatus] = React.useState({
    data: undefined,
    loading: false,
    errors: undefined
  })

  const productType = activeAsset ? ALERT_PRODUCT_TYPE.ASSET : activeDepot && ALERT_PRODUCT_TYPE.DEPOT;

  React.useEffect(() => {
    if (activeAsset || activeDepot) {
      setModalOpen(true)
    } else {
      setConfigurationSavingStatus({
        data: undefined,
        loading: false,
        errors: undefined
      })
    }
  }, [activeAsset, activeDepot])

  React.useEffect(() => {
    if (!modalOpen) {
      setActiveAsset(undefined)
      setActiveDepot(undefined)
    }
  }, [modalOpen])

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const getActiveFlagValue = (action, configuration) => {
    const value = {
      [EDIT_ALERT_ACTIONS.CREATE]: true,
      [EDIT_ALERT_ACTIONS.ACTIVATE]: true,
      [EDIT_ALERT_ACTIONS.CANCEL]: false
    }[action]

    return !_.isUndefined(value) ? value : configuration.active
  }

  const syncAssetConfigurations = (configuration, action) => {
    setActiveAsset((asset) => {
      handleConfiguration(asset, configuration, action)
      return {...asset}
    })
  }

  const syncDepotConfigurations = (configuration, action) => {
    setActiveDepot((depot) => {
      syncPortfolioAlerts(depot, configuration, action)
      return {...depot}
    })
  }

  const handleSave = async (configuration, action, closeModal, useConfiguration=false) => {

    try {

      setConfigurationSavingStatus({
        data: undefined,
        loading: true,
        errors: undefined
      })

      let product_type = null;
      if (useConfiguration) {
        product_type = configuration.alert_type_value;
      } else {
        product_type = activeAsset
          ? ALERT_PRODUCT_TYPE.ASSET
          : ALERT_PRODUCT_TYPE.DEPOT
      }

      const requestBody = {
        id: configuration.id,
        active: getActiveFlagValue(action, configuration),
        calculation_period: configuration.calculation_period,
        configuration: configuration.configuration,
        field_to_validate: configuration.field_to_validate,
        frequency: configuration.frequency,
        deleted: action === EDIT_ALERT_ACTIONS.DELETE ? true : configuration.deleted,
        product_type: product_type
      }

      if (useConfiguration) {
        requestBody.group_id = configuration.group_id;
        if (product_type == ALERT_PRODUCT_TYPE.ASSET) {
          requestBody.asset_id =  configuration.asset_id;
          requestBody.isin = configuration.isin;
        }
      } else {
        if (activeAsset) {
          requestBody.asset_id =  activeAsset.id
          requestBody.group_id = activeAsset.group_id
          requestBody.isin = activeAsset.isin
          _.set(requestBody, 'configuration.depot_number', activeAsset.depot_number)
          _.set(requestBody, 'configuration.instrument_name', activeAsset.name)
          _.set(requestBody, 'configuration.instrument_wkn', activeAsset.wkn)
          // We need to store price at the moment configuration was created, so we could know the difference in prices
          _.set(requestBody, 'configuration.instrument_price_eur', activeAsset.price_eur)
        } else {
          requestBody.group_id = activeDepot.id
          _.set(requestBody, 'configuration.depot_number', activeDepot.number)
          _.set(requestBody, 'configuration.depot_name', activeDepot.name)
        }
      }

      const response = await PortfolioHandlerResource
        .at(`customer/${customer_id}/asset/alert-configuration/`)
        .post(requestBody)

      setConfigurationSavingStatus({
        data: response,
        loading: false,
        errors: undefined
      })

      const actionMessage = {
        [EDIT_ALERT_ACTIONS.CREATE]: 'gespeichert',
        [EDIT_ALERT_ACTIONS.ACTIVATE]: 'aktiviert',
        [EDIT_ALERT_ACTIONS.CANCEL]: 'deaktiviert',
        [EDIT_ALERT_ACTIONS.DELETE]: 'gelöscht'
      }

      dispatch(displaySuccessSnackBar(!!response
        ? `Konfiguration des ${ALERT_PRODUCT_TYPE_TEXT[product_type]}s ${actionMessage[action]}.`
        : `Konfiguration des ${ALERT_PRODUCT_TYPE_TEXT[product_type]}s entfernt.`))

      onAlertConfigurationSaved && onAlertConfigurationSaved(
        useConfiguration ? configuration : activeAsset || activeDepot, response, requestBody.product_type, action)
      if (!useConfiguration) {
        activeDepot ? syncDepotConfigurations(response, action) : syncAssetConfigurations(response, action)
      }
      closeModal && handleModalClose()

    } catch (errors) {
      setConfigurationSavingStatus({
        data: undefined,
        loading: false,
        errors
      })
      dispatch(displayErrorSnackBar('Es is ein Fehler während des Speicherns der Konfiguration des Produktarlams aufgetreten.'))
    }
  }

  const handleSelectProduct = (product, productType=ALERT_PRODUCT_TYPE.ASSET, portfolio) => {
    const setProductFunction = productType == ALERT_PRODUCT_TYPE.ASSET
      ? setActiveAsset
      : setActiveDepot
    setProductFunction({...product, instrumentsRiskMetricsData: {riskMetricsData: instrumentsRiskMetricsData, portfolio }})
  }

  const getProductName = () => {
    if (activeAsset) {
      return activeAsset.isin
    }

    if (activeDepot) {
      return activeDepot.name
    }
  }

  const getProductTrackingStartDate = () => {
    if (activeAsset) {
      return activeAsset.tracking_start_date
    }

    if (activeDepot) {
      return activeDepot.tracking_start_date
    }
  }

  const activeDepotConfigurations = activeDepot && activeDepot.alerting_configuration || []

  const activeAssetConfigurations = activeAsset && activeAsset.alerting_configuration || []

  return (
    <AlertConfigurationModalContext.Provider value={{onSelectAsset: handleSelectProduct}}>
      {_.isFunction(children) ? children({onSave: handleSave}) : children}
      <AlertConfigurationModal
        activeConfiguration={activeDepot && activeDepot || activeAsset}
        open={modalOpen}
        onClose={handleModalClose}
        name={getProductName()}
        trackingStartDate={getProductTrackingStartDate()}
        onSave={handleSave}
        configuration={{}}
        configurations={activeDepot ? activeDepotConfigurations : activeAssetConfigurations}
        actionDisabled={configurationSavingStatus.loading }
        error={null}
        productType={productType}
        allowCreateNew={allowCreateNew}
      />
    </AlertConfigurationModalContext.Provider>
  )
}

export default connect()(AlertConfigurationWrapper)
