export default () => ({
    input: {
      display: 'none'
    },
    label: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center'
    },
    withError: {
      borderColor: 'red'
    },
    endAdornmentBtn: {
      position: 'absolute',
      fontFamily: 'Roboto-Bold',
      right: -1,
      textTransform: 'capitalize',
      height: '100%',
      borderRadius: '0 4px 4px 0',
      boxShadow: 'none',
    },
    fileContainer: {
      alignItems: 'center',
      flexWrap: 'nowrap',
      paddingRight: 0,
      color: '#0092E5',
      backgroundColor: '#ecf8fe',
      borderRadius: 4,
      position: 'relative',
      top: -8,
      borderColor: 'transparent',
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0
    },
    removeDocumentWrapper: {
      marginLeft: 'auto',
  
      '& .MuiIconButton-root': {
        color: 'inherit',
      },
    },
    previewLink: {
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    previewContent: {
      textAlign: 'center',
      maxHeight: '80vh',
    }
  });