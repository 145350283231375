export const INVESTMENT_STRATEGY_TYPES = {
  PERSONAL: 'Meine Strategien',
  WHITE_LABEL: 'White Label',
  DEFAULT: 'Standard',
  INSTITUTIONAL: 'Institutionell'
}

export const ALL_STRATEGIES_TAB= 'Alle';

export const INVESTMENT_STRATEGY_TABS = [
  ALL_STRATEGIES_TAB,
  ...Object.values(INVESTMENT_STRATEGY_TYPES)
];