import React, {useState} from 'react';
import clsx from 'clsx';
import connect from "react-redux/lib/connect/connect";

/* Material-UI Components*/
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

/* BCA modules*/
import useStyles from './styles';
import useOnClickOutside from "../../../../../utils/useOnClickOutside";
import SelectCaret from '../../../../../components/Icons/SelectCaret';

const mapStateToProps = (state) => ({
  benchmark_settings: state.get('sharedSettings').toJS().data.benchmark_settings
});

export default connect(mapStateToProps)((props) => {
  const classes = useStyles();

  const {
    selectedBenchmarks,
    isPIBenchmarkSelected,
    benchmark,
    handleSelectedBenchmarkChanged,
    benchmark_settings
  } = props;

  const [expanded, setExpanded] = useState(false);

  const node = React.useRef();

  useOnClickOutside(node, () => {
    setExpanded(false);
  });

  const renderBenchmarks = () => {
    const selected = selectedBenchmarks
      .map(item => item.id);

    return benchmark_settings.map(item => {
      const isSameSelected = selected.includes(item.id);
      const isSameType = isPIBenchmarkSelected === item.is_private_investment;
      const isCurrent = benchmark && benchmark.id === item.id;
      const isDisabled = selected.length > 0 && (isSameSelected || !isSameType);

      return (
        <ListItem
          key={item.id}
          className={clsx(classes.portfolioListItem, isCurrent && classes.portfolioListItemSelected)}
          onClick={() => handleBenchmarkChanged(item, isDisabled)}
          disabled={isDisabled}
        >
          <span>
            {item.name}
          </span>
        </ListItem>
      )
    })
  };

  const handleBenchmarkChanged = (newBenchmark, disabled) => {
    if (disabled) {
      return ;
    }
    handleSelectedBenchmarkChanged(newBenchmark);
    setExpanded(false);
  };

  return (
    <div className={classes.container} ref={node}>
      <div className={classes.expansionPannelContainer}>
        <ExpansionPanel TransitionProps={{timeout: 0}} expanded={expanded} onChange={() => setExpanded(!expanded)} classes={{
          root: classes.expansionPanelRoot,
          expanded: classes.expansionPanelExpanded
        }}>
          <ExpansionPanelSummary
            expandIcon={<SelectCaret className={classes.expandArrow} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            IconButtonProps={{
              disableRipple: true
            }}
            classes={{
              root: classes.expansionPanelSummaryRoot,
              expanded: classes.expansionPanelSummaryExpanded,
              content: classes.expansionPanelSummaryContent
            }}
          >
            <div className={classes.ellipsis}>
              {benchmark && benchmark.name}
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails classes={{
            root: classes.expansionPanelDetailsRoot,

          }}>
            <List className={classes.portfolioList}>
              <ListItem
                className={classes.portfolioListItem}
                onClick={() => handleBenchmarkChanged(undefined)}
              >
                <span>
                  &#x2015;
                </span>
              </ListItem>
              {renderBenchmarks()}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </div>
  )
});
