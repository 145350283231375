import React from 'react';
import PerformanceFixedColumnsBarChart from "../../../../../components/Charts/PerformanceFixedColumnBarChart";
import {
  getPerformanceBarChartData
} from "../../../../CustomerDashboard/components/Widgets/components/PerformanceBarChart";


function PerformanceBarChart({data}) {

  const chartData = (() => {

    if (!data) {
      return {}
    }

    let [_data, yearsMap] = getPerformanceBarChartData(data);

    return {
      years: Object.keys(yearsMap).sort(),
      data: _data,
    }

  })();

  if (!data) {
    return null
  }

  return (
    <PerformanceFixedColumnsBarChart categories={chartData.years} data={chartData.data}/>
  )
}

export default React.memo(PerformanceBarChart,
  (prevProps, nextProps) => prevProps.updatedAt === nextProps.updatedAt);