import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    width: '100%',
    borderRadius: 4,
    boxShadow: '2px 2px 10px #0000001A',
    height: 620,
    overflowY: 'auto',
    paddingRight: 5,
    '&::-webkit-scrollbar': {
      width: 3,
      height: 3
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#C9C9C9',
    }
  },
  title: {
    fontFamily: 'Roboto-Bold',
    color: '#202A38',
    fontSize: 24,
    paddingLeft: 25,
    '&:first-child': {
      marginTop: 15
    }
  },
})

export default makeStyles(styles)