import React from 'react';

import {
  Button,
  CircularProgress
} from '@material-ui/core';

import ModalContainer from '../../../../components/ModalContainer';

import useStyles from './styles';

const DeleteGroupModal = (props) => {
  const classes = useStyles();

  const {
    handleDeleteClick,
    isDeleting,
    groupName
  } = props;

  const getTitleMessage = () => {
    return `Sind Sie sicher, dass Sie Gruppe "${groupName}" löschen möchten?`
  };

  return (
    <ModalContainer open={props.open} handleClose={props.handleClose}>
      <p className={classes.modalTitle}>{getTitleMessage()}</p>
        <div className={classes.modalActions}>
          <Button className={classes.actionButton} color={"primary"} onClick={props.handleClose}>
            Abbrechen
          </Button>
          <Button 
            style={{minWidth: 160}}
            disabled={isDeleting}
            className={classes.actionButton} 
            color={"primary"} 
            variant="contained" 
            onClick={handleDeleteClick}
          >
            {isDeleting ? (
              <CircularProgress size={15}/>
            ) : (
              'Gruppe löschen'
            )} 
          </Button>
        </div>
    </ModalContainer>
  )
};

export default DeleteGroupModal;