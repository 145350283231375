import React from 'react';
import _ from "lodash";

import withWidth from "@material-ui/core/withWidth/withWidth";

import useStyles from './styles';
import ChartLegend from '../../../../../../components/Charts/Legend';
import PerformanceFixedColumnsBarChart from '../../../../../../components/Charts/PerformanceFixedColumnBarChart';
import {formatPortfolioName} from "../../../../../../utils/aggregated-portfolio";
import {DashboardChartSection} from "../index";
import WarningTooltip from "../../../../../../components/WarningTooltip";


const _performanceBarChartPropsAreEquals = (prevProps, nextProps) => {
  return prevProps.chartData.timestamp === nextProps.chartData.timestamp
}

const _PerformanceBarChart = React.memo(({chartData, classes}) => {
  return (
    <div className={classes.chartContainer}>
      <ChartLegend
        names={chartData.data.map(item => item.portfolio.name)}
        colors={chartData.data.map(item => item.portfolio.color)}
      />
      <PerformanceFixedColumnsBarChart categories={chartData.years} data={chartData.data}/>
    </div>
  )
}, _performanceBarChartPropsAreEquals)


const getYearsToShowAggregated = (data) => {
  // return years with count of depots with data
  let valid_years = {};
  data.forEach(portfolio => {
    if(portfolio.pk != 0) {
      portfolio.year_performance.forEach(year => {
        if(year.performance_eur !== 0){
          let val = _.get(valid_years, year.year, 0)
          _.set(valid_years, `${year.year}`, val+1)
        }
      })
    }
  })

  return valid_years
}

export const getPerformanceBarChartData = (portfolios) => {
  let data = [];
  let yearsMap = {};
  let aggregated_years = getYearsToShowAggregated(portfolios)

  portfolios.forEach(portfolio => {
    const portfolio_data = [];
    const portfolio_data_exist = portfolio.year_performance.some(item => item.performance != 0);

    if (portfolio_data_exist) {
      portfolio.year_performance.forEach(item => {
        let showBar = portfolio.pk != 0 || aggregated_years[item.year] > 1;

        let yPlotItem = item.performance ? parseFloat((item.performance * 100).toFixed(2)) : null
        portfolio_data.push({
          y: showBar ? yPlotItem : null,
          inEur: showBar ? parseFloat((item.performance_eur).toFixed(2)) : null,
        });
        yearsMap[item.year] = true;

      });
      if (portfolio_data.some(p => p.y !== null)) { //in case portfolio has no points on chart => skip it
        data.push({
          portfolio: {
            name: formatPortfolioName(formatPortfolioName(portfolio.name)),
            data: portfolio_data,
            color: portfolio.color
          }
        });
      }
    }
  });
  return [data, yearsMap]
}

export default withWidth()((props) => {
  const classes = useStyles();

  const {
    dashboardData,
    dashboardDataLoading,
    dashboardDataLoadingError,
    width,
    timestamp,
    onExpanded,
    expanded,
  } = props;

  const [reloadTrigger, setReloadTrigger] = React.useState(false);
  const [chartData, setChartData] = React.useState({
    data: [],
    years: [],
    error: false
  });


  React.useEffect(() => {
    setReloadTrigger(true);
    updateChartData();
    setTimeout(() => {
      setReloadTrigger(false);
    }, 100);
  }, [dashboardData, timestamp]);

  const updateChartData = () => {
    if (_.isArray(dashboardData && dashboardData.portfolios)){

      let [data, yearsMap] = getPerformanceBarChartData(dashboardData.portfolios);

      setChartData({
        error: false,
        years: Object.keys(yearsMap).sort(),
        data,
        timestamp: new Date().getTime()
      });
    } else {
      setChartData({
        error: true,
        data: [],
        years: []
      })
    }
  };

  return (
    <DashboardChartSection
      title={props.title || (
        <>
          <span>Jährliche Wertentwicklung der Depots der letzten 5 Jahre in Prozent</span>
            <WarningTooltip
              className={classes.benchmarkIcon}
              placement={'left'}
              title={"Berechnet nach geldgewichteter Methode bzw. nach Modified Dietz."}
              iconClassName={classes.helpIcon}
              size={16}
            />
        </>
      )}
      content={dashboardData && (
        <_PerformanceBarChart chartData={chartData} classes={classes}/>
      )}
      loading={dashboardDataLoading || reloadTrigger}
      error={dashboardDataLoadingError || chartData.error}
      displayError={true}
      minHeight={['xs', 'sm', 'md'].includes(width) ? 520 : 486}
      empty={chartData.years.length === 0}
      expanded={expanded}
      onExpanded={onExpanded}
    />
  );
});