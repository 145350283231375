import {parseResponse, PortfolioHandlerResource} from "../../../utils/api";
import React, {useState} from "react";
import _ from "lodash";

export function usePerformanceForecastData(customerId, portfolios, dataProvider=PortfolioHandlerResource, assets, investmentStrategyId) {
  const [data, setData] = useState({
    data: null,
    errors: null,
    loading: false,
    updatedAt: +new Date()
  });

  React.useEffect(() => {
    if (!!customerId && !_.isEmpty(portfolios)) {
      fetch()
    }
  }, [customerId, JSON.stringify(portfolios)])

  async function fetch() {
    setData({
      data: null,
      errors: null,
      loading: true,
      updatedAt: +new Date()
    });

    try {

      const portfoliosIds = portfolios.filter(portfolio => !!portfolio.depotNumber).map(portfolio => portfolio.depotNumber);

      const response = await dataProvider.getPortfolioForecast(customerId, portfoliosIds, assets, investmentStrategyId);
      /** Parse extended_asset_data data */
      parseResponse(response, 'performance_forecast', (data) => {
        setData({
          data: data,
          errors: null,
          loading: false,
          updatedAt: +new Date()
        });
      }, (errors) => {
        setData({
          data: null,
          errors: errors,
          loading: false,
          updatedAt: +new Date()
        });
      });

    } catch (errors){
      setData({
        data: null,
        errors: errors,
        loading: false,
        updatedAt: +new Date()
      });
    }
  }

  return data;
}