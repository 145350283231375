export const footerHeight = 150;

const styles = () => ({
  footerContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 28%, rgba(255,255,255,1) 100%)',
    padding: 32,
    height: footerHeight,
    zIndex: 1,
  },
  footerContent: {
    justifyContent: 'space-between',
    width: '100%',
    margin: '0 auto'
  }
});

export default styles;