import React from 'react';
import {connect} from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import withWidth from "@material-ui/core/withWidth/withWidth";

import useStyles from './styles';
import {openGuideModal} from "../../Header/actions";
import {PrimaryButton} from "../index";
import clsx from "clsx";
import _ from "lodash";

const mapStateToProps = (state) => ({
  settings: state.get('guideTourSettings').toJS()
});

const InfoTourButton = props => {
  const {
    dispatch,
    width,
    iconButton,
    settings,
  } = props;

  const classes = useStyles();
  const isIconButton = iconButton || ['xs'].includes(width);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(_.isEmpty(settings.steps));

  React.useEffect(() => {
    setIsButtonDisabled(_.isEmpty(settings.steps))
  }, [settings.steps]);

  const renderIconButton = () => {
    return (
      <IconButton
        onClick={() => dispatch(openGuideModal())}
        className={classes.iconButton}
        disabled={isButtonDisabled}
      >
        <HelpOutlineIcon className={classes.icon} />
      </IconButton>
    )
  };

  const renderButton = () => {
    return (
      <PrimaryButton
        text='Info-Tour'
        onButtonClick={() => dispatch(openGuideModal())}
        icon={<HelpOutlineIcon/>}
        disabled={isButtonDisabled}
        customClasses={{
          root: classes.button,
        }}
      />
    )
  };

  return (
    <div className={clsx(isButtonDisabled && classes.buttonDisabled)}>
      {isIconButton ? renderIconButton() : renderButton()}
    </div>
  )
};

export default connect(mapStateToProps)(withWidth()(InfoTourButton))