import moment from 'moment';
import {
  PercentageNumberFormat,
  CurrencyNumberFormat,
} from '../../../RiskProfiling/components/StepContent/components/formElement/InputFormElement/InputFormElement';
import { getRiskMetricsValue } from '../../../../components/Charts/InstrumentsAllocationTable/utlis';
import {
  withEuroOrDash,
  withPercentOrDash,
} from '../../../../utils/utils';
import { toGermanFormat } from '../../../../utils/numberFormater';

export const ALERT_PRODUCT_TYPE = {
  ASSET: 1,
  DEPOT: 2,
};

export const ALERT_PRODUCT_TYPE_TEXT = {
  [ALERT_PRODUCT_TYPE.ASSET]: 'Produktalarm',
  [ALERT_PRODUCT_TYPE.DEPOT]: 'Depotalarm',
};

const PROFIT_LOSS_INVESTMENT_BEGIN = 1;

const CALCULATION_PERIOD_OPTIONS = [
  {
    id: PROFIT_LOSS_INVESTMENT_BEGIN,
    value: PROFIT_LOSS_INVESTMENT_BEGIN,
    label: 'Investmentbeginn',
  },
  {
    id: 2,
    value: 2,
    label: '1 Jahr',
    date: () => moment().subtract(1, 'year'),
  },
  {
    id: 3,
    value: 3,
    label: '3 Jahre',
    date: () => moment().subtract(3, 'year'),
  },
  {
    id: 4,
    value: 4,
    label: '5 Jahre',
    date: () => moment().subtract(5, 'year'),
  },
];

export const getCalculationPeriodOptions = (dateToCompareWith) => {
  return CALCULATION_PERIOD_OPTIONS.map((option) => {
    const disabled = option.id != PROFIT_LOSS_INVESTMENT_BEGIN
      && moment(dateToCompareWith, 'YYYY-MM-DD') > option.date();
    return {
      ...option,
      disabled,
      info:
        disabled
        && 'Die Option kann leider nicht gewählt werden, da die entsprechende Haltedauer noch nicht erreicht wurde.',
    };
  });
};

export const FREQUENCY_OPTIONS = [
  {
    id: 1,
    value: 1,
    label: 'einmalig',
  },
  {
    id: 2,
    value: 2,
    label: 'täglich ',
  },
];

export const DEFAULT_CURRENCY_RANGE_STATE = {
  maxEuro: {
    value: '',
    label: 'niedriger als',
    dimension: '€',
    inputComponent: [CurrencyNumberFormat],
    formatValue: (value) => withEuroOrDash(value),
    withActualHint: true,
  },
  minEuro: {
    value: '',
    label: 'höher als',
    dimension: '€',
    inputComponent: [CurrencyNumberFormat],
    formatValue: (value) => withEuroOrDash(value),
  },
};

export const DEFAULT_PERCENTAGE_RANGE_STATE = {
  maxPercent: {
    value: '',
    label: 'niedriger als',
    dimension: '%',
    inputComponent: [PercentageNumberFormat],
    formatValue: (value) => withPercentOrDash(value, false),
    withActualHint: true,
  },
  minPercent: {
    value: '',
    label: 'höher als',
    dimension: '%',
    inputComponent: [PercentageNumberFormat],
    formatValue: (value) => withPercentOrDash(value, false),
  },
};

export const DEFAULT_VALUE_RANGE_STATE = {
  maxValue: {
    value: '',
    label: 'niedriger als',
    inputComponent: [
      CurrencyNumberFormat,
      { withSuffix: false, withPlaceholder: false },
    ],
    formatValue: (value) => toGermanFormat(value, '', '', 2),
    withActualHint: true,
  },
  minValue: {
    value: '',
    label: 'höher als',
    inputComponent: [
      CurrencyNumberFormat,
      { withSuffix: false, withPlaceholder: false },
    ],
    formatValue: (value) => toGermanFormat(value, '', '', 2),
  },
};

export const NOTHING_SELECTED_VALUE = 0;
export const PROFIT_LOSS_VALUE = 6;
export const PRICE_IN_EUR_VALUE = 1;
export const SRI_VALUE = 7;
export const WEIGHT_VALUE = 2;
export const VOLATILITY_1Y_VALUE = 3;
export const VOLATILITY_3Y_VALUE = 4;
export const VOLATILITY_5Y_VALUE = 5;

export const FIELDS_TO_CHECK_OPTIONS = [
  {
    id: NOTHING_SELECTED_VALUE,
    value: NOTHING_SELECTED_VALUE,
    label: '---',
    enabledFor: [ALERT_PRODUCT_TYPE.ASSET, ALERT_PRODUCT_TYPE.DEPOT],
  },
  {
    id: 1,
    value: PRICE_IN_EUR_VALUE,
    label: 'Kurs in Euro/Prozent',
    state: {
      ...DEFAULT_CURRENCY_RANGE_STATE,
      ...DEFAULT_PERCENTAGE_RANGE_STATE,
    },
    enabledFor: [ALERT_PRODUCT_TYPE.ASSET],
    getValueCallback: (product) => ({
      minEuro: product.price_eur,
      maxEuro: product.price_eur,
    }),
  },
  {
    id: 7,
    value: SRI_VALUE,
    label: 'Risikoprofil',
    state: { ...DEFAULT_VALUE_RANGE_STATE },
    enabledFor: [ALERT_PRODUCT_TYPE.DEPOT],
    getValueCallback: (product) => ({ value: product.sri }),
  },
  {
    id: 2,
    value: WEIGHT_VALUE,
    label: 'Wert in Euro/Prozent',
    state: {
      ...DEFAULT_CURRENCY_RANGE_STATE,
      ...DEFAULT_PERCENTAGE_RANGE_STATE,
    },
    enabledFor: [ALERT_PRODUCT_TYPE.ASSET, ALERT_PRODUCT_TYPE.DEPOT],
    getValueCallback: (product, productType) => {
      const marketValue = product.market_value;
      const weight = product.weight && product.weight * 100;

      return {
        minEuro: marketValue,
        maxEuro: marketValue,
        minPercent: weight,
        maxPercent: weight,
      };
    },
  },
  {
    id: 3,
    value: VOLATILITY_1Y_VALUE,
    label: 'Volatilität 1 Jahr in Prozent',
    state: { ...DEFAULT_PERCENTAGE_RANGE_STATE },
    enabledFor: [ALERT_PRODUCT_TYPE.ASSET, ALERT_PRODUCT_TYPE.DEPOT],
    getValueCallback: (product, productType) => {
      const riskValue = getRiskMetricsValue(
        product,
        product.instrumentsRiskMetricsData,
        '1y',
        'volatility_annual',
        false,
        productType === ALERT_PRODUCT_TYPE.ASSET,
      );
      return { value: riskValue && riskValue * 100 };
    },
  },
  {
    id: 4,
    value: VOLATILITY_3Y_VALUE,
    label: 'Volatilität 3 Jahre in Prozent',
    state: { ...DEFAULT_PERCENTAGE_RANGE_STATE },
    enabledFor: [ALERT_PRODUCT_TYPE.ASSET, ALERT_PRODUCT_TYPE.DEPOT],
    getValueCallback: (product, productType) => {
      const riskValue = getRiskMetricsValue(
        product,
        product.instrumentsRiskMetricsData,
        '3y',
        'volatility_annual',
        false,
        productType === ALERT_PRODUCT_TYPE.ASSET,
      );
      return { value: riskValue && riskValue * 100 };
    },
  },
  {
    id: 5,
    value: VOLATILITY_5Y_VALUE,
    label: 'Volatilität 5 Jahre in Prozent',
    state: { ...DEFAULT_PERCENTAGE_RANGE_STATE },
    enabledFor: [ALERT_PRODUCT_TYPE.ASSET, ALERT_PRODUCT_TYPE.DEPOT],
    getValueCallback: (product, productType) => {
      const riskValue = getRiskMetricsValue(
        product,
        product.instrumentsRiskMetricsData,
        '5y',
        'volatility_annual',
        false,
        productType === ALERT_PRODUCT_TYPE.ASSET,
      );
      return { value: riskValue && riskValue * 100 };
    },
  },
  {
    id: PROFIT_LOSS_VALUE,
    value: PROFIT_LOSS_VALUE,
    label: 'GuV in Prozent',
    state: { ...DEFAULT_PERCENTAGE_RANGE_STATE },
    enabledFor: [ALERT_PRODUCT_TYPE.ASSET, ALERT_PRODUCT_TYPE.DEPOT],
    getValueCallback: (product, productType) => {
      const value = product.profit_loss_perc;
      return { value: value && value * 100 };
    },
  },
];
