import React from 'react';
import useStyles from './styles';
import {Grid} from "@material-ui/core";
import FilterInput from "../../../../DocumentCenter/components/FilterInput";
import InputFormElement from "../../../../RiskProfiling/components/StepContent/components/formElement/InputFormElement";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import { buildSelectedValue } from '../../../../TransactionsMonitoring/utils';

const DynamicFilters = props => {
  const {
    filtersStructureList,
    onSearchFieldChange,
    searchString,
    handleApplyFilterBtnClick,
    disabled,
    currentFilterSettings,
  } = props;

  const classes = useStyles();
  const customClasses = props.customClasses || {};

  const isOptionsIncludeOption = (selectedOptions, option) => {
    if (_.isArray(selectedOptions)) {
      return selectedOptions.includes(option.name);
    }
    return selectedOptions.name === option.name;
  }

  return (
    <Grid
      container
      spacing={2}
      classes={{ root: classes.filtersRoot }}
      alignItems="flex-end"
      direction="row"
    >

      <Grid item xs={12} sm={6} md={4}>
        <InputFormElement
          label={'Name'}
          InputLabelProps={{
          }}
          value={searchString}
          onChange={onSearchFieldChange}
          custom_classes={{inputRoot: classes.inputRoot, labelRoot: classes.labelRoot}}

          disabled={disabled}
        />
      </Grid>

      {_.isArray(filtersStructureList) && filtersStructureList.map(filter => {
        let selectedOptions = _.get(currentFilterSettings, filter.field, []);
        const selectedValues = filter.options.filter(o => isOptionsIncludeOption(selectedOptions, o));
        const isAllSelected = filter.options.length && filter.options.length === selectedOptions.length;

        return (
          <Grid item {..._.get(filter, 'gridSize', {})} key={filter.id}>
            <FilterInput
              multiple={filter.multiple}
              options={filter.options}
              loading={filter.loading}
              loadingText={filter.loadingText}
              label={filter.label}
              value={filter.multiple ? selectedValues : selectedOptions}
              customclasses={{ option: classes.banksOption, autocompleteInput: customClasses.autocompleteInput }}
              onChange={(_, items) => filter.onSelectedOptionsChange(items)}
              renderOption={(o) => filter.renderOption(o, isAllSelected || isOptionsIncludeOption(selectedOptions, o))}
              renderInput={(params) => {
                // the selectedValues include only names
                let placeHolder = buildSelectedValue(isAllSelected, selectedValues.map(o => ({ label: o.name })));
                return (
                  <TextField
                    className={classes.textField}
                    placeholder={placeHolder}
                    variant="outlined"
                    {...params}
                  />
                )
              }}
              renderTags={filter.renderTags}

              disabled={disabled}
            />
          </Grid>
        )
      })}

      <Grid item xs={12} sm={6} md={4}>
        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          onClick={handleApplyFilterBtnClick}
          classes={{
            root: classes.applyFiltersBtn,
            label: classes.applyFiltersBtnText,
          }}
          disabled={disabled}
        >
          <i className={"fa fa-search"} />
          {_.isArray(filtersStructureList) && filtersStructureList.length > 1 ? "Filter übernehmen" : "Suche starten"}
        </Button>
      </Grid>

    </Grid>
  )
};

export default DynamicFilters
