import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  label: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    color: '#4D505B',
    display: 'inline-block',
    width: '100%',
  },
  alignRight: {
    textAlign: 'right'
  },
  textBold: {
    fontFamily: 'Roboto-Bold'
  }
})

export default makeStyles(styles)