import React from 'react';
import _ from 'lodash';

import PrimaryButton from '../../../../../../components/Buttons/PrimaryButton';
import ConfirmationDialog from '../../../../../VirtualPortfolioManager/modals/ConfirmationDialog';

const ResetSettingsBtn = (props) => {
  const {
    loading,
    settingsId,
    customer_id,
    resetSettings,
    settingsType,
    customClasses,
  } = props;

  const [showDeleteSettingModal, setShowDeleteSettingModal] = React.useState(false);

  const handleDeleteModalClose = () => {
    setShowDeleteSettingModal(false);
  };

  const handleResetSettings = () => {
    resetSettings(settingsType, settingsId, customer_id);
    handleDeleteModalClose();
  };

  return (
    <>
      <PrimaryButton
        text={"Auf Standardeinstellungen zurücksetzen"}
        icon={<i className="far fa-undo" aria-hidden="true"/>}
        variant="outlined"
        onButtonClick={() => setShowDeleteSettingModal(true)}
        disabled={loading || _.isNil(settingsId)}
        customClasses={customClasses}
      />
      <ConfirmationDialog
        message={'Sind Sie sicher, dass Sie die Einstellungen für die Vermögensübersicht auf die Grundeinstellungen zurücksetzen möchten?'}
        open={showDeleteSettingModal}
        onClose={handleDeleteModalClose}
        confirm={handleResetSettings}
      />
    </>
  )
};

export default ResetSettingsBtn;