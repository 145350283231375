import React from 'react';
import { connect } from 'react-redux'
import _ from "lodash";
import {Container, Grid} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import useStyles from '../../Modelportfolios/Overview/styles';
import Link from "../../../components/Link";
import {getInvestmentDynamicPath, getRouteFullPathByComponent} from "../../InvestmentPlatform/utils";
import {
  musterPortfolioInstrumentsTableStructure
} from "../../Modelportfolios/components/InstrumentsList/table-data";
import {defaultExpandedItems} from "../../Modelportfolios/Overview/OverviewV2";
import withInvestmentStrategyDashboardData from "../../../components/InvestmentStrategyDashboardDataProvider/InvestmentStrategyDashboardDataProviderV2";
import InstrumentsTab from "../../Modelportfolios/Overview/components/ExtendedTabs/InstrumentsV2";
import InvestmentDetailsNavigation, { StickyNavigation } from '../../CustomerDashboardV2/components/InvestmentDetailsNavigation';
import {
  SelectableInstrumentsTable
} from "../../../components/SelectForProductsComparisonModal/SelectForProductsComparisonModal";
import Header from './components/Header';
import OverviewTab from '../../Modelportfolios/Overview/components/ExtendedTabs/Overview';
import TabsContainer from '../../../components/TabsContainer';
import setSticky from '../../../utils/sticky';
import StructureTab from '../../CustomerDashboardV2/tabs/Structure';
import { REPORT_TYPES } from '../../DashboardSettings/components/CustomersList/components/ReportType/constants';
import KeyFiguresTab from '../../CustomerDashboardV2/tabs/KeyFigures';
import RiskTab from '../../CustomerDashboardV2/tabs/Risk';
import SustainabilityTab from '../../CustomerDashboardV2/tabs/Sustainability';
import { TabsSharedData } from '../../CustomerDashboardV2/components_v2/TabsSharedData/TabsSharedData';
import PerformanceTab from '../../CustomerDashboardV2/tabs/Performance';


const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
});

const tabsConfiguration = [
  { name: 'Basisinformationen', component: OverviewTab },
  { name: 'Wertentwicklung', component: PerformanceTab, settings: {historicalChart: {checked: false}}},
  { name: 'Instrumente', component: InstrumentsTab },
  { name: 'Portfoliostruktur', component: StructureTab, settings: {totalAssetVolumeChart: {checked: false}}},
  { name: 'Kennzahlen', component: KeyFiguresTab },
  { name: 'Risikoanalyse', component: RiskTab },
  { name: 'Nachhaltigkeit', component: SustainabilityTab }
];

const NotFound = ({classes}) => (
  <div className={classes.errorContainer}>
    <h1>404</h1>
    <p>Not found</p>
  </div>
);

function Overview(props) {

  const classes = useStyles();

  const investmentStrategyData = props.investmentStrategyData;

  const [selectedTabId, setSelectedTabId] = React.useState('Basisinformationen');

  const [expandedItems, setExpandedItems] = React.useState(defaultExpandedItems);

  React.useEffect(() => {
    setSticky('app-main', 'sticky-stoper', 'investment-navigation-component-sticky', 'sticky-trigger');
  }, []);

  const investmentStrategy = (() => {

    if (props.investmentStrategy) {
      return props.investmentStrategy
    }

    if (investmentStrategyData.loading || investmentStrategyData.errors || !investmentStrategyData.data) {
      return {}
    }

    return investmentStrategyData.data

  })();

  /**
   * Investment strategy assets..
   * @type {Object[]}
   */
  const assets = _.get(investmentStrategy, 'related_model_portfolio.assets') || [];

  /**
   * Investment strategy, converted to that model portfolio format,
   * that is required for CIOS data tabs.
   * @type {Object}
   */
  const investmentStrategyAsModelPortfolio = {
    data: !investmentStrategy.related_model_portfolio ? undefined : {
      ...investmentStrategy,
      id: investmentStrategy.related_model_portfolio.id,
      assets: assets,
      by_top_holdings: investmentStrategy.related_model_portfolio.by_top_holdings,
      connect_portfolio_id: investmentStrategy.related_model_portfolio.connect_portfolio_id
    },
    loading: investmentStrategyData.loading,
  };

  /**
   * Open Cleversoft link for Investment Strategy.
   */
  const handleDownloadClick = investmentStrategy.factsheet_link ? () => {
    window.open(investmentStrategy.factsheet_link,  '_blank')
  } : undefined;

  const handleTabChange = (event, newValue) => {
    if (newValue !== undefined) {
      setSelectedTabId(newValue)
    }
  };

  // region Internal variables-components

  const GoBackLink = () => {
    if (props.investmentPlatform.routes) {
      let fullPath = getRouteFullPathByComponent(
        props.investmentPlatform.routes, 'INVESTMENT_STRATEGIES_LIST');

      if (!fullPath) {
        return null
      }

      return (
        <Grid item xs={12}>
          <Link
            title="Zurück zu Vermögensverwaltung"
            icon={<i class="fa fa-chevron-left" aria-hidden="true"></i>}
            underline
            onClick={() => props.history.push(`/${getInvestmentDynamicPath()}${fullPath}`)}
          />
        </Grid>
      )

    }

    return null;
  };
  // endregion

  const handleExpandedItemsChange = (path, expanded) => {
    setExpandedItems(prevState => {
      const expandedItems = _.cloneDeep(prevState);
      _.set(expandedItems, path, expanded);

      return expandedItems;
    });
  };

  // TODO: It is placed there due to some disabledByFilter handling. Need to place it out of component
  if (props.isInstrumentsSelection) {
    /**
    * List of assets, that will be used for investment strategy assets table.
    * @type {Object[]}
    */
    const assetsTableDataSource = investmentStrategyData.loading ? new Array(5).fill({}) : assets;

    const getAvailableAssets = () => {
      return [].concat(assetsTableDataSource).filter(asset => !asset.disabledByFilter);
    };

    const handleAllSelected = () => {
      if (props.onSelectAll){
        const selected = props.selectedInstruments || [];
        let assets = getAvailableAssets();
        if (selected.length === assets.length) {
          assets = []; // unselect all
        }

        props.onSelectAll(assets)
      }
    };

    return (
      <Container style={{paddingBottom: 16}}>
        <SelectableInstrumentsTable
          container={investmentStrategy || {}}
          ContainerProps={{
            enabled: true,
            selectable: !!props.onSourceSelect,
            path: 'INVESTMENT_STRATEGIES_OVERVIEW',
            checked: props.sourceSelected,
            onChange: props.onSourceSelect,
            classes: {
              containerName: classes.containerName,
              containerCheckbox: classes.containerCheckbox
            }
          }}
          instruments={assetsTableDataSource}
          InstrumentsProps={{
            enabled: true,
            selected:  props.selectedInstruments || [],
            handleSelectAll: !!props.onSelectAll ? handleAllSelected : null,
            handleSelect: props.onInstrumentSelect,
            tableStructure: musterPortfolioInstrumentsTableStructure,
            totalInstrumentsCount: getAvailableAssets().length,
            tableClasses: classes
          }}
        />
      </Container>
    )
  }

  const getTabComponent = () => {
    const tab = _.find(tabsConfiguration, (tab) => tab.name === selectedTabId);

    return (
      <TabsSharedData>
        <tab.component
          investmentStrategy={investmentStrategy}
          modelPortfolio={investmentStrategyAsModelPortfolio}
          aggregatedPortfolioName={_.get(investmentStrategyAsModelPortfolio, 'data.name')}
          expandedItems={expandedItems}
          onExpandedItemsChange={handleExpandedItemsChange}
          reportType={REPORT_TYPES.CUSTOM.value}
          customSettings={tab.settings}
          {...props}
        />
      </TabsSharedData>
    )
  };

  const navigationProps = {
    chartsSettings: props.chartsSettings,
    calculationDates: props.calculationDates,
    calculationDatesType: props.selectedDatesType,
    handleCalculationDatesChanged: props.onSelectedDatesChanged,
    investmentData: props.investmentData.data,
    dataLoading: props.dataLoading,
  };

  return (
    <>
      <Container className={`app-page-container`}>
        <Grid container spacing={3}>
          { !props.viewOnly && <GoBackLink /> }
          <Grid item xs={12}>
            {investmentStrategyData.loading ? (
              <Skeleton style={{width: "100%", height: 40}}/>
            ) : (
              <>
                {investmentStrategy && (
                  <Header name={investmentStrategy.name} handlePdfExportClick={handleDownloadClick} />
                )}
              </>
            )}
          </Grid>
          <StickyNavigation
            handlePdfExportClick={handleDownloadClick}
            // onAddToComparisonClick={() => {}}
            {...navigationProps}
          />
          <Grid item xs={12} id="sticky-trigger">
            <InvestmentDetailsNavigation {...navigationProps} />
          </Grid>
          {investmentStrategyData.errors ? (
            <>
              {investmentStrategyData.errors.status && investmentStrategyData.errors.status == 404 && (
                <NotFound classes={classes}/>
              )}
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <TabsContainer
                  value={selectedTabId}
                  onChange={handleTabChange}
                  loading={investmentStrategyData.loading}
                  disabled={(!investmentStrategyAsModelPortfolio || _.isEmpty(props.selectedPortfolios))}
                  tabs={tabsConfiguration.map((tab) => ({id: tab.name, label: tab.name}))}
                  tabContent={getTabComponent()}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} id={'sticky-stoper'} />
        </Grid>
      </Container>
    </>
  )
}

export default connect(mapStateToProps)(withInvestmentStrategyDashboardData(Overview));