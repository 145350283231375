import React from 'react';

import useStyles from './styles'
import {DashboardChartSection} from "../../../CustomerDashboard/components/Widgets/components";
import clsx from "clsx";

function ChartSection(props) {
  const classes = useStyles({expanded: props.expanded});

  return (
    <DashboardChartSection
      customClasses={{
        headerContainer: classes.widgetHeaderContainer,
        container: classes.widgetContainer,
        contentContainer: classes.contentContainer,
        header: classes.header
      }}
      {...props}
    />
  )
}

// TODO: Prepare better idea of reusing ChartSection component
export function ChartSectionBordered(props) {
  const classes = useStyles();

  return (
    <DashboardChartSection
      customClasses={{
        headerContainer: clsx(classes.widgetHeaderContainer, classes.widgetHeaderContainerBordered),
        container: clsx(classes.widgetContainer, classes.widgetContainerBordered),
        contentContainer: clsx(classes.contentContainer, classes.contentContainerBordered),
        header: classes.header
      }}
      {...props}
    />
  )
}

export default ChartSection;