import {makeStyles} from '@material-ui/core';

export const styles = theme => ({
  errorContainer: {
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& > i': {
      marginBottom: 15,
      color: '#ADB6BD',
      fontSize: 20
    },

    '& > p': {
      fontFamily: 'Roboto-Regular',
      color: '#63696F',
      textAlign: 'center',
      margin: 0
    }
  }
});

export default makeStyles(styles);