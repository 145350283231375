import React from 'react';

const withProfileDetailsData = WrappedComponent => props => {

  const {
    investmentData,
    unrealizedProfitAndLossData
  } = props;

  const investmentAmountValue = investmentData && +investmentData.aum.aum_value || 0;
  const otherAssetsValue = investmentData && +investmentData.other_assets.value || 0;
  const totalInvestmentAmountValue = investmentData && +investmentData.total.value || 0;
  const overallInvestedAmount = investmentData && investmentData.overall_invested_amount;

  let openTransactionValue;

  if (unrealizedProfitAndLossData) {
    openTransactionValue = unrealizedProfitAndLossData.pending_switches
  }

  return(
    <WrappedComponent
      investmentAmountValue={investmentAmountValue}
      otherAssetsValue={otherAssetsValue}
      totalInvestmentAmountValue={totalInvestmentAmountValue}
      overallInvestedAmount={overallInvestedAmount}
      openTransactionValue={openTransactionValue}

      {...props}
    />
  )

}

export default withProfileDetailsData;