import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'

const VALIDATORS = {
  minLength: (value, minLength) => {
    if (!value) return undefined;
    return value.length < minLength ? `Bitte geben Sie mindestens ${minLength} Zeichen ein` : undefined
  }
};

class FiltersSection extends Component {

  state = {};

  INACTIVE_INPUT_COLOR = '#202A38';

  VALIDATION_RULES = {};

  DEFAULT_VALUES = {};

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
      if (this.props.predefinedFilter) {
        _.map(this.props.predefinedFilter, this.prefillFilter)
      }
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if all custodians were removed the cost should be reset
    if(!_.isEmpty(prevState.cCustodian) && _.isEmpty(this.state.cCustodian)){
      this.onValueReset('cost')
    }
    if (prevProps.setDefault !== this.props.setDefault) {
      this.setDefaultFilters()
    }
    if(prevProps.errors !== this.props.errors){
      this.setState({
        errors: {...this.state.errors, ..._.mapKeys(this.props.errors, (v, k)=> _.camelCase(k))}
      })
    }
  }

  prefillFilter = (value, fieldName) => {
    if (_.get(this.DEFAULT_VALUES, fieldName)) {
      this.onValueChange(fieldName)(value)
    } else {
      console.error(fieldName, '- not exist');
      return
    }
    if (_.isArray(value)) {
      if (!_.isEmpty(value)) {
        value = value.map(i => (_.isObject(i) ? i : {'id': i, 'value': i, 'name': i}))
      } else {return}
    }
    this.setState(prevState => ({
      ...prevState,
      [fieldName]: value,
      predefinedFields: [...prevState.predefinedFields, fieldName]
    }))
  };

  isFieldChanged = (fieldName) => {
    if (!Array.isArray(fieldName)) {
      if (this.DEFAULT_VALUES.hasOwnProperty(fieldName)) {
        if (Array.isArray(this.DEFAULT_VALUES[fieldName].value) && Array.isArray(this.state[fieldName])) {
          if (this.state[fieldName].length !== this.DEFAULT_VALUES[fieldName].value.length
            || this.state[fieldName].some(function(value, index) { return value !== this.DEFAULT_VALUES[fieldName].value[index]})) {
            return true
          }
        } else {
          if (this.state[fieldName] !== this.DEFAULT_VALUES[fieldName].value && this.state[fieldName]) {
            return true
          }
        }
      }
    } else {
      let isValueChanged = false;
      fieldName.forEach(f => {
        if (this.DEFAULT_VALUES.hasOwnProperty(f) && this.state[f] !== this.DEFAULT_VALUES[f].value) {
          isValueChanged = true
        }
      });

      if (isValueChanged) {
        return true
      }
    }
    return false
  };

  getChangedFiltersCount = (fields) => {

    return fields.reduce((count, field) => {

      if (this.isFieldChanged(field)) {
        count++
      }

      return count
    }, 0)

  };

  getChangedFiltersTotalCount = () => {
    return undefined
  };

  onValueChange = (fieldName, fieldHandler=undefined, innerCallback=undefined) => (fieldValue) => {
    // clear field error if value changed
    const errorsCopy = {...this.state.errors};
    _.unset(errorsCopy, fieldName);

    this.setState({
      ...this.state,
      [fieldName]: fieldHandler ? fieldHandler(fieldValue) : fieldValue,
      errors: errorsCopy
    }, () => {
      if(innerCallback) innerCallback(fieldName, this.state[fieldName])
      if (this.props.onFilterChanged) {
        this.props.onFilterChanged(this.getChangedFiltersTotalCount())
      }
    })
  };

  onFieldReset = (fieldName) => {
    let stateValues = {};

    if (Array.isArray(fieldName)) {
      fieldName.forEach(f => {
        if (this.DEFAULT_VALUES.hasOwnProperty(f)) {
          stateValues[f] = this.DEFAULT_VALUES[f].value
        }
      })
    } else {
      if (this.DEFAULT_VALUES.hasOwnProperty(fieldName)) {
        stateValues[fieldName] = this.DEFAULT_VALUES[fieldName].value
      }
    }

    let errorsCopy = {...this.state.errors};
    if (errorsCopy.hasOwnProperty(fieldName)) {
      delete errorsCopy[fieldName]
    }

    this.setState({
      ...this.state,
      ...stateValues,
      errors: errorsCopy
    }, () => {
      if (this.props.onFilterChanged) {
        this.props.onFilterChanged(this.getChangedFiltersTotalCount())
      }
    })
  };

  onValueReset = (sectionName) => {

    let stateValues = {};

    let errorsCopy = {...this.state.errors};

    for (let fieldName in this.DEFAULT_VALUES) {
      if (this.state.hasOwnProperty(fieldName) && this.DEFAULT_VALUES[fieldName].section == sectionName && !(this.state.predefinedFields || []).includes(fieldName)) {
        stateValues[fieldName] = this.DEFAULT_VALUES[fieldName].value;

        if (errorsCopy.hasOwnProperty(fieldName)) {
          delete errorsCopy[fieldName]
        }
      }
    }

    this.setState({
      ...this.state,
      ...stateValues,
      errors: errorsCopy
    }, () => {
      if (this.props.onFilterChanged) {
        this.props.onFilterChanged(this.getChangedFiltersTotalCount())
      }
    })
  };

  handleNameFieldValue = value => {
    return (value && value.replace(/ /g, "").length) ? value : ''
  };

  _buildFilters = () => {
    return {}
  };

  validateFilters = () => {
    let errors = {};

    for (let fieldName in this.state) {
      if (this.state.hasOwnProperty(fieldName) && this.VALIDATION_RULES.hasOwnProperty(fieldName)) {
        let validators = this.VALIDATION_RULES[fieldName];

        for (let validator in validators) {
          if (VALIDATORS.hasOwnProperty(validator)) {
            let errorMessage = VALIDATORS[validator](this.state[fieldName], validators[validator]);
            if (errorMessage) {
              if (!errors.hasOwnProperty(fieldName)) {
                errors[fieldName] = []
              }

              errors[fieldName].push(errorMessage)
            }
          }
        }
      }
    }

    return errors
  };

  setDefaultFilters = () => {
    this.setState({
      errors: {}
    })
  };

  buildFilters = () => {

    let errors = this.validateFilters();

    if (!_.isEmpty(errors)) {
      this.setState({
        errors
      });

      return
    }

    this.setState({
      errors: {}
    });

    return this._buildFilters()
  };

  render() {
    return null
  }
}

FiltersSection.propTypes = {
  onFiltersApprove: PropTypes.func.isRequired,

  setDefault: PropTypes.number,

  onRef: PropTypes.func
};

export default FiltersSection;
