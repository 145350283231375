const styles = theme => ({
  root: {
    minHeight: 150,
    width: '100%'
  },
  container: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#4D4F5C'
  },
  title: {
    fontFamily: 'Roboto-Bold',
    width: '14%',
    '& > span': {
      color: '#80858C',
      fontFamily: 'Roboto-Bold',
    }
  },
  list: {
    listStyle: 'none',
    marginTop: 60,
    padding: 0,
    '&:first-of-type': {
      marginTop: 10
    },
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    padding: '10px 7px',
    borderBottom: 'solid 1px #D8DCDF',
    '&:last-of-type': {
      borderBottom: 'none',
      paddingBottom: 0
    }
  },
  headline: {
    fontFamily: 'Roboto-Bold',
    textAlign: 'right',
    backgroundColor: '#F5F6F7',
    minHeight: 46,
    padding: '4px 7px 0 7px',
    borderBottom: 'none',
  },
  values: {
    width: '14%',
    textAlign: 'right'
  },
  linearProgress: {
    width: 50,
    float: 'right',
    marginTop: 7
  }
})

export default styles;