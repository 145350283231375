import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  formContainer: {
    width: '100%',

    '& > p': {
      fontFamily: 'Roboto-Bold',
      fontSize: 22,
      color: '#4D4F5C',
      margin: 0,
      marginBottom: 45
    }
  },

  textFieldRoot: {
    width: '100%',
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#313D44',
    marginBottom: 50,

    [theme.breakpoints.down('sm')]: {
      marginBottom: 40,
      fontSize: 15,
    },

    [theme.breakpoints.down('xs')]: {
      marginBottom: 30,
      fontSize: 14,
    },
  },

  inputLabelRoot: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: '#ABB6BE'
  },
  inputLabelShrink: {
    transform: 'translate(0, -5px)'
  },
  submitButton: {
    boxShadow: 'none',
    borderRadius: 2,
    textTransform: 'none',
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    margin: '0 auto',
    minHeight: 40,
    minWidth: 110
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  inputAdornment: {
    fontSize: 25,
    color: '#b1b1b1',

    '&:hover': {
      cursor: 'pointer'
    }
  }
});

export default makeStyles(styles);