import React from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import styles from "./styles";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {matchPath} from "react-router";
import _ from "lodash";
import {parseResponse, PortfolioHandlerResource} from "../../../../../../utils/api";
import Grid from "@material-ui/core/Grid";
import {setHeaderInfo} from "../../../../actions";
import {ROUTES} from "../../../../../../routes";
import {useAppContext} from "../../../../../App";
import {setSharedSettings} from "../../../../../../components/SharedSettingsProvider/actions";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import images from "../../../../../../images";
import CustomerInfo
  from "../../../../../Reporting/components/PreviewStepContent/components/CustomerConfiguration/components/CustomerInfo";


const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS()
});

const CustomerData = props => {
  const {
    classes,
    match: {
      path,
    },
    investmentPlatform: {
      header_info,
      refresh_header_trigger
    },
    location,
    routes,
  } = props;

  const [customerData, setCustomerData] = React.useState(null);

  const { onCustomerChange } = useAppContext();

  React.useEffect(() => {
    let customer_id;

    const matchRoutePath = (route, additionalPath=null) => {
      return matchPath(location.pathname, {
        path: path + route.full_path + (additionalPath || ''),
        strict: true,
        sensitive: true
      });
    }
    (routes || []).find(route => {
      const match = matchRoutePath(route)
      customer_id = _.get(match, 'params.customer_id');

      // Workaround to get customer id from url, in case REPORTING is displayed,
      // cause part of url for this case hardcoded in frontend constants
      if (!customer_id && route.component === 'REPORTING') {
        [ROUTES.BROKER.REPORTING.children.CUSTOMERS.children.COVER.newDesignPath, ROUTES.BROKER.REPORTING.children.CUSTOMERS.children.PREVIEW.newDesignPath]
          .find((reportingPath) => {

            const match = matchRoutePath(route, reportingPath);
            customer_id = _.get(match, 'params.customer_id');

            return !!customer_id
          })
      }

      return !!customer_id;
    });

    if(customer_id){
      PortfolioHandlerResource.getCustomerAppData(customer_id, true, false, false, false, true, undefined, false).then(res => {
        const customer = _.get(res, 'customer.data.0');
        parseResponse(res, 'shared_settings', (data) => {
          props.dispatch(setSharedSettings(data || {}));
        });
        setCustomerData(customer);
        onCustomerChange && onCustomerChange(customer)
      });
    } else {
      setCustomerData(null);
    }

    // clean header info on routes or location change
    props.dispatch(setHeaderInfo(null));
  }, [routes, location, refresh_header_trigger]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const MobileCustomerEl = () => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const handleClick = (event) => {
      if (open) {
        setOpen(false)
      } else {
        setAnchorEl(event.currentTarget);
        setOpen(true)
      }
    };

    const handleClose = () => {
      if (open) {
        setOpen(false);
        setAnchorEl(null);
      }
    };

    return (
      <div className={classes.dropdownMenuWrapper}>
        <IconButton classes={{root: classes.userLogoWrapper}} onClick={handleClick}>
          <img src={images.default_user_logo} className={classes.userLogo} alt="User Logo"/>
        </IconButton>
        <Popover open={open} anchorEl={anchorEl}
           onClose={handleClose}
           classes={{paper: classes.dropdownMenu}}
           anchorOrigin={{vertical: 55, horizontal: 'left'}}
           transformOrigin={{vertical: 'top', horizontal: 'left'}}
        >
          <CustomerDataEl />
        </Popover>
      </div>
    )
  };

  const CustomerDataEl = () => {
    return (
      <Grid container spacing={2} className={classes.customerDataContainer}>
        {!!customerData && (
          <Grid item>
            <CustomerInfo customer={customerData} />
          </Grid>
        )}
        {!!header_info && <Grid item>{header_info}</Grid>}
      </Grid>
    )
  };

  return (
    <>
    {(!!customerData || !!header_info) && (
      isMobile ? <MobileCustomerEl/> : <CustomerDataEl />
    )}
    </>
  )
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(CustomerData)));