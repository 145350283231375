export const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 25
  },
  item: {
    marginBottom: 4
  },
  header: {
    fontFamily: 'Roboto-Regular',
    fontSize: 34,
    color: '#626970',
    marginRight: 10,

    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    }
  }
});

export default styles;