import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  navigationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& > button': {
      marginLeft: 16
    }
  },
  loadingIndicator: {
    color: theme.palette.primary.main
  },
  loadingContainer: {
    width: '100%',
    height: 'calc(100vh - 420px)', // 420px for header and footer
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  checkboxRegularContainer: {
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Roboto-Regular !important'
    },
    '& .MuiFormControlLabel-root': {
      marginBottom: 0
    }
  },
  helpText: {
    paddingBottom: 0,
    marginBottom: 0
  },
  collapsedTransition: {
    transitionProperty: 'all',
    transitionTimingFunction: 'ease-in-out',
    transitionDuration: '1s', // if you want to change duration, you need to change WindowSizeHook delay
  },
  collapsBtnWrapper: {
    position: 'relative',
    width: 0,
    top: 7,
    right: -7,
    zIndex: 1,

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  collapsBtn: {
    padding: 1,
  },
  italicText: {
    fontStyle: 'italic'
  },
  bankInfo: {
    fontSize: 16,
    color: '#f44336',
    paddingTop: 10,
    paddingBottom: 10
  },
});

export default makeStyles(styles)