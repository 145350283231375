const styles = theme => ({
  container: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    bottom: 5,
    left: '50%',
    padding: 7,
    backgroundColor: '#fff',
    borderRadius: 3,
    textAlign: 'center',
    transform: 'translateX(-50%)',
    boxShadow: '0 0 15px 2px rgba(0,0,0,.5)',
    zIndex: 10000,

    [theme.breakpoints.down('sm')]: {
      width: '95%'
    },

    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },

  },
  messageContainer: {
    margin: '0 10px',
  },
  message: {
    textAlign: 'left',
    margin: 0,
    color: '#63696f',
    fontSize: 13,

    [theme.breakpoints.only('xs')]: {
      marginBottom: 5
    }
  },
  buttonContainer: {},
  buttonRoot: {
    color: '#fff',
    '& span': {
      fontSize: 13
    },

    [theme.breakpoints.only('xs')]: {
      width: '100%'
    }
  },
  buttonColorInherit: {
    backgroundColor: '#78bd5d',
    '&:hover':{
      backgroundColor: '#78bd5d',
    }
  }
});

export default styles;