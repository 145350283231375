import React from 'react'

import {Container} from '@material-ui/core'

import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import DashboardTable from '../../../../../components/DashboardTable';
import Pagination from '../../../../../components/Pagination/Pagination';

import {tableStructure, searchTableStructure} from './table-data';
import useStyles, { useSearchTableClasses } from "./styles";
import StickyNavigation from "../StickyNavigation/StickyNavigation";
import {WidgetBody} from "../../../components";
import _ from "lodash";
import {
  AddProductsToComparison, SelectForProductsComparisonModal
} from "../../../../../components/SelectForProductsComparisonModal/SelectForProductsComparisonModal";

export function AddToProductComparisonButton({instruments}) {

  const [selectedInstruments, setSelectedInstruments] = React.useState();

  return (
    <>
      <PrimaryButton
        text="Vergleich öffnen"
        onButtonClick={() => setSelectedInstruments(instruments)}
        disabled={_.isEmpty(instruments)}
      />
      <SelectForProductsComparisonModal
        container={undefined}
        instruments={selectedInstruments}
        onClose={() => setSelectedInstruments(undefined)}
      />
    </>

  )
}

function InstrumentsList(props) {

  const {
    instruments,
    loading,
    errors,
    selected,
    totalInstrumentsCount,
    onInstrumentSelect,
    onAllSelect,
    onAddInstruments,
    visible,
    allSelected,
    isFundSearch,
    customTableStructure
  } = props;

  const classes = useStyles();
  const searchTableClasses = useSearchTableClasses();

  const [totalPagesCount, setTotalPagesCount] = React.useState(0)
  const [currentPage, setCurrentPage] = React.useState(0)

  React.useEffect(() => {
    if (instruments) {
      setTotalPagesCount(instruments.length)
      setCurrentPage(0)
    }
  }, [instruments])

  const getDataSource = () => {
    if (loading) {
      return [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
    }

    if (instruments && instruments.length) {
      return instruments[currentPage]
    }

    return []
  }

  const button = (
    <PrimaryButton
      text={`Instrumente${(selected && selected.length) ? ` (${selected.length})` : ''} hinzufügen`}
      disabled={!selected || !selected.length}
      customClasses={{
        root: classes.addInstrumentsButtonRoot,
        disabled: classes.addInstrumentsButtonDisabled
      }}
      onButtonClick={onAddInstruments}
    />
  )

  const structure = (
       isFundSearch ? searchTableStructure: (customTableStructure || tableStructure)
  )
  
  return (
    <div>
      {/*<WidgetHeader>*/}
      {/*  <FormControlLabel */}
      {/*    control={*/}
      {/*      <Checkbox color="primary" disabled={!totalInstrumentsCount} checked={selected.length == totalInstrumentsCount && totalInstrumentsCount} onChange={() => onAllSelect()}/>} */}
      {/*    label="Alles markieren" className={classes.selectAllLabel}*/}
      {/*  />*/}
      {/*</WidgetHeader>*/}
      <div>
        <div style={{display: 'flex', alignItems: "center", justifyContent: 'space-between'}}>
          <h2 className={classes.header}>Suchergebnisse
            {(instruments && totalInstrumentsCount) ? (
              <span className={classes.instrumentsCount}>({totalInstrumentsCount} Instrumente)</span>
            ) : null}

          </h2>
          {isFundSearch && (
            <AddProductsToComparison>
              <AddToProductComparisonButton instruments={_.flatten(instruments || [])} />
            </AddProductsToComparison>
          )}
        </div>

        <WidgetBody style={{overflowX: 'auto'}}>
          <DashboardTable
            structure={structure}
            dataSource={getDataSource()}
            expanded={true}
            tableClasses={isFundSearch ? searchTableClasses: classes}
            tableLayout={'auto'}
            options={{
              loading,
              selected,
              onInstrumentSelect,
              onAllSelect,
              allSelected
            }}
            withFooter={false}
          />
        </WidgetBody>
        {instruments && instruments.length ? (
          <Pagination
            totalPageCount={totalPagesCount}
            currentPage={currentPage}
            handlePageChanged={setCurrentPage}
            classes={{container: classes.paginationContainer}}
          />
        ) : <span></span>}
      </div>
      {/*<WidgetActions justifyContent="flex-end">*/}
      {/*  <PrimaryButton */}
      {/*    text="Instrument(e) hinzufügen"*/}
      {/*    icon={<PlusIcon color="white" />}*/}
      {/*    onButtonClick={onAddInstruments}*/}
      {/*  />*/}
      {/*</WidgetActions>*/}
      {props.useButtons ? (
          <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 15}}>
            {button}
          </div>
        ) :(
        <StickyNavigation>
          {visible && !isFundSearch && (
          <Container style={{display: 'flex', justifyContent: 'flex-end'}}>
            {
              button
            }
          </Container> )}
        </StickyNavigation>
      )}
    </div>
  )
}

InstrumentsList.propTypes = {

}

export default InstrumentsList
