import { makeStyles } from "@material-ui/core";

const styles = (theme) => ({
  userContainer: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down(300)]: {
      flexDirection: 'column'
    }
  },

  userLogo: {
    width: 70,
    height: 70,
    marginRight: 10,
  },

  userName: {
    margin: 0,
    // color: '#4D4F5C',
    fontSize: 21,
    fontFamily: 'Roboto-Bold',
    letterSpacing: -0.5,

    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },

  userId: {
    margin: '0 0 5px',
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#4D4F5C',

    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },

    '& > span': {
      marginRight: 7
    }
  },
  link: {
    color: `${theme.palette.primary.main} !important`,
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    cursor: 'pointer',

    '& > a': {
      color: `${theme.palette.primary.main} !important`,
    }
  },

  dashboardLink: {
    marginTop: 10,
    marginBottom: 0,
  },

  group: {
    color: '#313D44',
    fontFamily: 'Roboto-Bold',
    marginTop: 10,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& > span': {
      height: 30
    }
  },
  sustainabilityCheckSwitch: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down(380)]: {
      display: 'block'
    },
  }
})

export default makeStyles(styles)