import { makeStyles } from '@material-ui/core';

const itemBorder = '1px solid #E1E3E9';

export default makeStyles((theme) => ({
  listItemContainer: {
    marginBottom: 15,
    width: '100%'
  },
  listItemCard: {
    width: '100%',
    height: '100%',
    borderRadius: 2,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)'
  },
  cardContent: {
    padding: '0px !important',
    height: '100%'
  },
  cardItem: {
    padding: 20,
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',

    [theme.breakpoints.down('sm')]: {
      padding: 15,
    },

    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderLeft: itemBorder,
      pointerEvents: 'none'
    },

    '&:first-child::after': {
      borderLeft: 'none',
    }
  }

}));