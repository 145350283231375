import {makeStyles} from '@material-ui/core';

export default makeStyles(() => ({
  container: {},
  formLabel: {
    color: '#313D44',
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  focusedLabel: {
    color: '#313D44 !important'
  },
  formControlLabel: {
    fontSize: 14
  },
  radio: {
    padding: 5,
    marginLeft: 4
  },
  helpIcon: {
    color: '#E1E3E9',
    width: 19,
    height: 19,
    marginLeft: 10,
    cursor: 'pointer'
  },
}))