import React from 'react';

import useStyles from './styles';

const InsuranceIcon = () => {
  const classes = useStyles();

  return (
    <span className={classes.iconContainer}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24.003" height="24" viewBox="0 0 24.003 24">
        <g transform="translate(0)">
          <path className="a" d="M-13.33-27.95a11.839,11.839,0,0,0-8.01-3.68v-1.7A.662.662,0,0,0-22-34a.67.67,0,0,0-.67.67v1.7a11.836,11.836,0,0,0-8,3.68,11.9,11.9,0,0,0-3.32,8.82.7.7,0,0,0,.68.63.664.664,0,0,0,.65-.66c.01-.22.11-1.31,1.79-1.31A2.192,2.192,0,0,1-28.56-19a.663.663,0,0,0,.64.5h.01a.663.663,0,0,0,.64-.49,2.2,2.2,0,0,1,2.31-1.48,2.2,2.2,0,0,1,2.29,1.4v7.3a1.7,1.7,0,0,0,.64,1.35,1.974,1.974,0,0,0,1.26.42,1.8,1.8,0,0,0,1.9-1.77.662.662,0,0,0-.67-.66.653.653,0,0,0-.66.66c0,.42-.48.45-.57.45s-.57-.03-.57-.45v-7.21a2.883,2.883,0,0,1,2.3-1.49,3,3,0,0,1,2.36,1.61.66.66,0,0,0,.59.36.664.664,0,0,0,.6-.36,2.963,2.963,0,0,1,2.36-1.61A1.912,1.912,0,0,1-11.32-19a.653.653,0,0,0,.64.5h.07a.674.674,0,0,0,.6-.63A11.9,11.9,0,0,0-13.33-27.95Zm.2,6.15a3.918,3.918,0,0,0-2.96,1.47,3.878,3.878,0,0,0-2.95-1.47,3.84,3.84,0,0,0-2.88,1.38,3.688,3.688,0,0,0-3.04-1.38,3.7,3.7,0,0,0-2.95,1.28,3.724,3.724,0,0,0-2.96-1.28,3.792,3.792,0,0,0-1.65.34,10.561,10.561,0,0,1,2.81-5.57A10.585,10.585,0,0,1-22-30.32a10.585,10.585,0,0,1,7.71,3.29,10.575,10.575,0,0,1,2.83,5.71A2.97,2.97,0,0,0-13.13-21.8Z" transform="translate(34.001 34)"/>
        </g>
      </svg>
    </span>
  );
};

export default InsuranceIcon;