export const REPORT_GENERATION_TYPES = {
    SINGLE: {
        value: 1,
        description: 'Gesamtreport über alle Kunden'
    },
    SPLITTED: {
        value: 2,
        description: 'Einzelreports per Kunde'
    }
};

export const DEFAULT_REPORT_GENERATION_TYPE = REPORT_GENERATION_TYPES.SINGLE;