import React from 'react';
import { Skeleton } from '@material-ui/lab';
import {Checkbox} from "@material-ui/core";
import Link from '../../../../components/Link'
import { DetailsIcon, EditIcon } from '../../../../images'
import { getFavoriteListType } from '../../../../containers/FavoriteList/utils'
import {getModelPortfolioOwner, modelPortfolioAccessible} from "../../../Modelportfolios/utils";
import {OwnerCell} from "../../../Modelportfolios/components/InstrumentsList/table-data";


const FavListNameCell = {
  header: {
    content: (item, options) => (
      <div style={{display: 'flex', alignItems: 'center'}}>
        {options && options.onAllSelect && (
          <div>
            <Checkbox
              color="primary" style={{padding:0, marginRight: 8,}}
              checked={options.selected.length === options.totalInstrumentsCount && options.totalInstrumentsCount}
              onChange={(e) => options.onAllSelect(e)}
            />
          </div>
        )}
        <div>
          Favoritenlistename
        </div>
      </div>
    )
  },
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      let allowed = modelPortfolioAccessible(item, options.auth);
      return (
        <div style={{display: 'flex', alignItems: 'center'}}>
          {options.onInstrumentSelect && (
              <div>
              {allowed && (
                <Checkbox
                  onChange={() => options.onInstrumentSelect(item)}
                  color="primary" style={{padding:0, marginRight: 8,}}
                  checked={!!(options.selected && options.selected.find(list => list.id == item.id))}
                />
              )}
              </div>
          )}
          {/* set min-width to truncate works as expected */}
          <div style={{minWidth: 0, marginLeft: allowed ? 0 : 32}}>
            {item.name}
          </div>
        </div>
      )
    }
  },
  key: 'FavListNameCell'
}

  const TypeCell = {
  header: "Typ/Unternehmen",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return getFavoriteListType(item)
    }
  },
  key: 'TypeCell'
}

const InstrumentsCountCell = {
  header: 'Anzahl Instrumente',
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.assets && item.assets.length;
    }
  },
  key: 'InstrumentsCountCell'
}

const ActionsCell = {
  header: '',
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return (
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}>
          <Link
            title="Details"
            icon={<DetailsIcon />}
            fontWeight={'normal'}
            onClick={() => options.onEdit(item)}
          />
        </div>
      )
    }
  },
  key: 'ActionsCell'
}

export const favoriteListTableStructure = {
  default: [{
    content: [FavListNameCell],
    orderingFunction: (item) => (item.name || '').toLowerCase()
  }, {
    content: [TypeCell],
    orderingFunction: (item) => getFavoriteListType(item)
  }, {
    content: [OwnerCell],
    orderingFunction: (item) => getModelPortfolioOwner(item)
  }, {
    content: [InstrumentsCountCell],
    align: 'right',
    orderingFunction: (item) => item.assets && item.assets.length
  },
  // {
  //   content: [ActionsCell]
  // }
  ]
}
