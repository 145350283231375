import React from 'react';

import useStyles from './styles';

const EquityIcon = () => {
  const classes = useStyles();

  return (
    <span className={classes.iconContainer}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path className="a" d="M20,12.91V4.67A.67.67,0,0,0,19.33,4h-4V2a2,2,0,0,0-2-2H6.67a2,2,0,0,0-2,2V4h-4A.67.67,0,0,0,0,4.67V15.33a2,2,0,0,0,2,2H12.76a4.712,4.712,0,0,0-.1,1A5.67,5.67,0,1,0,20,12.91ZM6,2a.67.67,0,0,1,.67-.67h6.66A.67.67,0,0,1,14,2V4H6ZM18.67,5.33a5.352,5.352,0,0,1-5.34,5.34H6.67A5.352,5.352,0,0,1,1.33,5.33ZM2,16a.67.67,0,0,1-.67-.67V9.32A6.671,6.671,0,0,0,6.67,12h6.66a6.671,6.671,0,0,0,5.34-2.68v3.36a1.884,1.884,0,0,0-.34-.02A5.646,5.646,0,0,0,13.17,16Zm16.33,6.67a4.349,4.349,0,0,1-4.34-4.34A4.315,4.315,0,0,1,14.68,16a4.324,4.324,0,0,1,3.65-2.01,1.94,1.94,0,0,1,.34.02,4.236,4.236,0,0,1,1.33.31,4.343,4.343,0,0,1-1.67,8.35Z"/>
      <path className="a" d="M12.767,9.655H10.813a.665.665,0,1,0,0,1.33h1.954a.665.665,0,0,0,0-1.33Z" transform="translate(6.502 8.105)"/>
    </svg>
    </span>
  );
};

export default EquityIcon;