import React from "react";
import _ from "lodash";
import clsx from "clsx";
import {
  SortableContainer,
  SortableElement
} from 'react-sortable-hoc';

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import withWidth from "@material-ui/core/withWidth";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CircularProgress from "@material-ui/core/CircularProgress";

import useStyles from "./styles";
import { isSubDepotItem, paginateSubItemsArray } from '../../utils/utils';
import Pagination from "../Pagination/Pagination";
import PaginationInput from "../Pagination/PaginationInput";
import SelectFormElement from "../../containers/RiskProfiling/components/StepContent/components/formElement/SelectFormElement";
import IconButton from '@material-ui/core/IconButton';
import {PAGE_SIZE_SELECT_OPTIONS} from "../../utils/constants"
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import SelectCaret from '../Icons/SelectCaret';
import {DEFAULT_EMPTY_SECTION_MESSAGE} from "../../containers/CustomerDashboardV2/constants";

function isPaginatedArray(array) {
  return array && array.length && Array.isArray(array[0])
}

const SortableItem = SortableElement(({children}) => (
  <TableRow>
    {children}
  </TableRow>
));

const SortableWrapper = SortableContainer(({children, id}) => {
  return <TableBody id={id}>{children}</TableBody>;
});

const getTotalRecordsCount = (records, useCollapse) => {
  return !useCollapse ? records.length : records.filter((record) => !record.isSubItem).length;
}

const DashboardTable = props => {
  const classes = useStyles();

  const {
    structure,
    dataSource,
    dataSourceKeyField,
    width,
    expanded,
    options,
    virtualInstrumentLink,
    tableClasses,
    withFooter,
    withHeader,
    tableLayout,
    withInputs,
    paginationOptions: {
      paginationEnabled,
      pageSize,
      pageSizeSelectEnabled = false,
      keepCurrentPageOnDataSourceChange, // pass if current page must not be reset to first when dataSource is changed
    },
    onOrderingChange,
    initOrdering,
    loadingIndicator,
    handleExpandedSubItemsChange,
    useInternalCollapse,
    useItemColorAsBorder,
    internalCollapseColumnIndex,
    showCell,
    dragable,
    onSortEnd,
    dragableHelperContainer,
    tableSizeSwitchEnabled, // if passed - button to make table scrollable is displayed
    emptyDataMessage
  } = props;

  const [isTableScrollable, setIsTableScrollable] = React.useState(false)

  const [currentPageSize, setCurrentPageSize] = React.useState(pageSize)

  const [orderColumn, setOrderColumn] = React.useState(initOrdering)
  const [paginationOptions, setPaginationOptions] = React.useState({
    totalRecordsCount: 0,
    totalPagesCount: null,
    currentPage: null
  })
  React.useEffect(() => {
    if (isPaginatedArray(dataSource) && paginationEnabled) {
      setPaginationOptions({
        totalRecordsCount: getTotalRecordsCount(dataSource.flat(), useInternalCollapse),
        totalPagesCount: dataSource.length,
        currentPage: keepCurrentPageOnDataSourceChange && !_.isNil(paginationOptions.currentPage)
          ? paginationOptions.currentPage
          : 0
      })
    }
  },  [dataSource]);

  const [TBody, TRow] = React.useMemo(() => {
    if (!dragable) {
      return [TableBody, TableRow]
    }
    return [SortableWrapper, SortableItem]
  }, [dragable]);

  // list of expanded items
  const [internalExpanded, setInternalExpanded] = React.useState([]);
  const updateExpandedItems = (elemId) => {
    // Updates or removes elem from internalExpanded

    setInternalExpanded(_.xor([...internalExpanded], [elemId])) // xor - returns array containing uniq elements of two lists

    // Updates expanded items sent to pdf
    if (handleExpandedSubItemsChange) {
      handleExpandedSubItemsChange(elemId);
    }
  }

  const dataSourceLocal = React.useMemo(() => {

    let dataSourceCopy = []
    if (dataSource && !_.isEmpty(dataSource)) {
      dataSourceCopy = [...dataSource]
    }

    if (isPaginatedArray(dataSource) && paginationEnabled) {
      dataSourceCopy = dataSourceCopy.flat()
    }

    let lastHeaderElement;
    if(useInternalCollapse){
      dataSourceCopy = dataSourceCopy.map(el => {
        // check if element is without header and should be always shown
        if (!!_.get(el, 'tableProps.withoutHeader')) {
          return el
        }
        // check if element is header
        let isHeader = !!_.get(el, 'tableProps.isHeader')
        if(isHeader){
          lastHeaderElement = el;
        } else {
          // if element is not header - check if its header element is expanded and set hideRows value
          el.hideRows = !!lastHeaderElement && !_.includes(internalExpanded, lastHeaderElement[dataSourceKeyField]);
        }

        return el
      })
    }

    if (orderColumn && !!orderColumn.orderingFunction) {
      dataSourceCopy = _.orderBy(dataSourceCopy, [orderColumn.orderingFunction], [orderColumn.ascending ? 'asc' : 'desc'])
    }

    if (isPaginatedArray(dataSource) && paginationEnabled) {
      dataSourceCopy = [...paginateSubItemsArray(dataSourceCopy, currentPageSize)]

      let currentOptionsCopy = {}

      if (paginationOptions.currentPage > dataSourceCopy.length-1) {
        currentOptionsCopy.currentPage =  paginationOptions.currentPage - 1
      }

      if (paginationOptions.totalPagesCount != dataSourceCopy.length){
        currentOptionsCopy.totalPagesCount = dataSourceCopy.length
      }

      if (!_.isEmpty(currentOptionsCopy)){
        setPaginationOptions((currentOptions)=>({
          ...currentOptions,
          ...currentOptionsCopy,
        }))
      }

      return dataSourceCopy[paginationOptions.currentPage > dataSourceCopy.length-1 ? dataSourceCopy.length-1 : paginationOptions.currentPage || 0]
    }

    return dataSourceCopy
  }, [JSON.stringify(dataSource), orderColumn, paginationOptions, currentPageSize, internalExpanded])

  const tableStructure = React.useMemo(() => {
    // useMemo to set proper table structure

    // structure.default is the biggest size => if table is scrollable or no other structure[width] - make table full size
    return (isTableScrollable || !structure[width]) ? structure.default : structure[width];
  }, [isTableScrollable, width, structure]);

  const handlePageSizeChange = (newPageSize) => {
    if(newPageSize) {
      setCurrentPageSize(newPageSize)
    }
  }

  const handleOrderColumnChange = (index, orderingFunction) => {

    let orderColumnParams = orderColumn && {...orderColumn}

    if (!orderColumnParams || orderColumnParams.index != index) {

      orderColumnParams = {
        ascending: true,
        index,
        orderingFunction
      }

    }

    orderColumnParams.ascending = !orderColumnParams.ascending

    setOrderColumn(orderColumnParams)
    onOrderingChange && onOrderingChange(orderColumnParams)
  }

  const handlePageChanged = (selectedPage) => {
    setPaginationOptions({
      ...paginationOptions,
      currentPage: selectedPage
    })
  }

  const getCellContent = (cell, data, bodyIndex) => {

    if (typeof cell === 'string') {
      return cell;
    }

    if (typeof cell.content === 'function') {
      return cell.content(data, {...(options || {}), width, virtualInstrumentLink}, bodyIndex);
    }

    return cell.content;
  }

  const CellContent = ({cell, data, bodyIndex, OrderingProps, cellKey}) => {
    if (_.isUndefined(cell)) {
      return null;
    }
    const content = getCellContent(cell, data, bodyIndex);
    const {
      orderingEnabled,
      onOrderChange,
      orderColumn,
      orderingFunction,
      index,
      collapseEnabled
    } = OrderingProps

    const handleOrderClick = (e) => {
      let el = e.target;
      if(el.tagName && ['input', 'button'].indexOf(el.tagName.toLowerCase()) !== -1) {
        return false
      }
      if (onOrderChange && orderingEnabled) {
        onOrderChange(index, orderingFunction)
      }
    }

    const orderingHeaderStyles = {
      display: 'flex',
      justifyContent: cell.justifyContent
    }

    const useCollapse = useInternalCollapse && collapseEnabled;
    const displayClass = _.isFunction(showCell) && !showCell(cellKey) ? classes.displayNone : ''

    return (
      <div
      className={clsx(cell.ellipsis ? 'ellipsis' : '', useCollapse && classes.arrowContainer, displayClass)}
      style={orderingEnabled ? {...orderingHeaderStyles} : {}}
      onClick={handleOrderClick}>
        <span className={data && isSubDepotItem(data) ? '' : cell.className || ''} onClick={() => cell.onClick && cell.onClick(data, options, virtualInstrumentLink)}>
          {content}
        </span>
        {useCollapse && (
          <IconButton
            className={clsx(classes.rotateIcon, tableClasses.rotateIcon)}
            disableRipple
            aria-label="back"
            onClick={() => updateExpandedItems(data[dataSourceKeyField])}
          >
            {_.find(internalExpanded, itemId => itemId == data[dataSourceKeyField]) ?
            <i className="far fa-angle-up" aria-hidden="true" style={{marginTop:-2}} />
            : <i className="far fa-angle-down" aria-hidden="true" />
            }
          </IconButton>
        )}
        {orderingEnabled && (
          <>
            {(!orderColumn || (orderColumn && orderColumn.index != index)) && (
              <ArrowDownwardIcon className={classes.orderingIcon} />
            )}
            {orderColumn && orderColumn.index == index && !orderColumn.ascending && (
              <ArrowDownwardIcon className={classes.orderingIcon} color="primary"/>
            )}
            {orderColumn && orderColumn.index == index  && orderColumn.ascending && (
              <ArrowUpwardIcon className={classes.orderingIcon} color="primary"/>
            )}
          </>
        )}
      </div>
    )
  };

  const generateBorderColor = (colors) => {

    const BORDER_SIZE = 100;

    const stepSize = BORDER_SIZE / colors.length;

    let configuration = []

    colors.reduce((currentStep, color) => {
      configuration.push(`${color} ${currentStep}%`);
      currentStep += stepSize;
      configuration.push(`${color} ${currentStep}%`);
      return currentStep;
    }, 0);

    return `linear-gradient(0deg, ${configuration.join(", ")})`

  }

  const renderRow = (context, data, bodyRowIndex) => {
    const isHeader = context == 'header';

    let currentColSpan = 0;
    return (
      tableStructure.map((cell, cellIndex) => {

        const hasContextContent = _.some(cell.content, (item) => !!item[context]);
        if (currentColSpan > 0) {
          currentColSpan -= 1;
        }

        if (!hasContextContent && currentColSpan > 0) {
          return null
        }

        const colSpan = _.max(cell.content.map(item => _.get(item, `colSpan.${context}.${width}`) || _.get(item, `colSpan.${context}.default`) || 1));
        if (colSpan > 1) {
          currentColSpan = colSpan
        }

        const collapseEnabled =  _.get(data, 'tableProps.isHeader') && cellIndex === (!_.isNil(internalCollapseColumnIndex) ? internalCollapseColumnIndex : 0)

        return (
          <TableCell
            key={cell.key || cellIndex}
            className={clsx(classes.tableCell, tableClasses.tableCell || '', !!cell.orderingFunction && isHeader && classes.tableCellHeaderOrdering,
            collapseEnabled && classes.alignCellTop, cell.cellClassName
            )}
            align={cell.align || 'left'}
            colSpan={colSpan}
            style={useItemColorAsBorder && cellIndex == 0 && context == 'body' && !_.isEmpty(data.color) ? {backgroundImage: generateBorderColor(data.color), backgroundSize: '5px 100%', backgroundRepeat: 'no-repeat'} : {}}
          >            {cell.content.map((item, itemIndex) => (
              <CellContent
                key={item.key || itemIndex}
                cellKey={item.expandedItemKey || item.key}
                cell={item[context]}
                data={data}
                bodyIndex={bodyRowIndex}
                OrderingProps={{
                  orderingEnabled: !!cell.orderingFunction && isHeader && itemIndex === 0,
                  onOrderChange: handleOrderColumnChange,
                  orderColumn,
                  orderingFunction: cell.orderingFunction,
                  index: cellIndex,
                  collapseEnabled: collapseEnabled
                }}
              />
            ))}
          </TableCell>
        )
      })
    )
  }


  const getStartRecordsNumber = () => {
    return paginationOptions.currentPage * currentPageSize + 1
  }

  const getTillRecordsNumber = () => {

    let currentPage = paginationOptions.currentPage;

    if ((currentPage || currentPage == 0) && dataSourceLocal) {

      const totalRecordsCount = getTotalRecordsCount(dataSourceLocal, useInternalCollapse);

      if (totalRecordsCount < currentPageSize) {
        return currentPage * currentPageSize + totalRecordsCount;
      } else {
        return currentPage * currentPageSize + currentPageSize;
      }

    }
  }

  const renderDataBody = (dataSource) => {
    // We need to use external index to have reset index logic
    let index = 0

    return dataSource.map((data) => {

      const rowIsHeader = _.get(data, 'tableProps.isHeader')
      const skipIndexReset = !!_.get(options, 'skipIndexReset');
      const isSubItem = !!_.get(data, 'isSubItem')
      const hideRows = !!_.get(data, 'hideRows')
      const highlighted = !!_.get(data, 'configuration.highlighted')

      const tableRow = (
        <>
          <TRow key={data[dataSourceKeyField] || index} index={index} className={clsx(tableClasses.tableRow || '', rowIsHeader && classes.tableRowAsHead, highlighted && 'highlighted')}>
            {withInputs && (
              structure.map((cell, cell_index) => (
                <TableCell key={cell.key || cell_index}
                           className={clsx(tableClasses.tableCell || '', classes.tableCell, cell.cellClassName)}
                           align={cell.align || 'left'}>
                  {cell.body.map((ColEl, cell_item_idx) => (
                    <ColEl key={cell_item_idx} item={data} options={options}/>
                  ))}
                </TableCell>
              ))
            )}
            {!withInputs && !hideRows && renderRow('body', data, index)}
          </TRow>
        </>
      )

      if (!isSubItem) {
        index = rowIsHeader && !skipIndexReset ? 0 : index + 1
      }

      return tableRow
    })
  }

  const getDragableHelperContainer = () => {
    if (!dragableHelperContainer) {
      return document.body
    }

    return document.getElementById(dragableHelperContainer) || document.body;
  }

  return (
    <>
      <div className={tableClasses.container} style={isTableScrollable ? {overflowX: 'scroll'} :{}}>
        <Table className={clsx(classes.table, tableClasses.table || '')} style={{tableLayout: tableLayout || 'fixed'}}>
          <TableHead className={clsx([classes.tableHead, tableClasses.tableHead || '', props.stickyHeader ? classes.stickyHeader : ''])}>
            <TableRow>
              {withInputs && (
                structure.map((cell, cell_index) => (
                  <TableCell key={cell.key || cell_index}
                             className={withHeader ? clsx(tableClasses.tableCell || '', classes.tableCell, cell.cellClassName) : classes.emptyCell}
                             align={cell.align || 'left'}>
                    {withHeader && cell.header.map((ColEl, cell_item_idx) => (
                      <ColEl key={cell_item_idx} options={options}/>
                    ))}
                  </TableCell>
                ))
              )}
              {!withInputs && renderRow('header')}
            </TableRow>
          </TableHead>
          {expanded && (
            <TBody
              useDragHandle
              lockAxis="y"
              helperContainer={getDragableHelperContainer}
              transitionDuration={200}
              onSortEnd={onSortEnd}
              hideSortableGhost
              lockToContainerEdges
              lockOffset={["-80%", "0%"]}
              id={dragableHelperContainer}
            >
              {/* Button that allows table to become scrollable */}
              {/* If structure[width] is not found - it is set to default => all cols are displayed -> no need to show btn */}
              {structure[width] && tableSizeSwitchEnabled &&
                <TableRow><TableCell colSpan={tableStructure.length} align={'center'} style={{padding: 0}}>
                  <Button variant="contained"
                        color="primary"
                        onClick={() => setIsTableScrollable(!isTableScrollable)}
                        className={classes.searchButton} >
                    {isTableScrollable
                      ? (
                        <><i className='fa fa-compress' style={{transform: 'rotate(45deg)'}} /> Kompaktansicht anzeigen </>
                      ) : (
                        <><i className='fa fa-arrows-h' /> Gesamte Tabelle anzeigen </>
                      )

                    }
                  </Button>
                </TableCell></TableRow>
              }

              {renderDataBody(dataSourceLocal)}
              {!withInputs && dataSourceLocal && dataSourceLocal.length === 0 && tableStructure && (
                <TableRow><TableCell colSpan={tableStructure.length} align={'center'}>{emptyDataMessage || DEFAULT_EMPTY_SECTION_MESSAGE}</TableCell></TableRow>
              )}
            </TBody>
          )}

          {loadingIndicator && (<TableBody>
            <TableRow>
              <TableCell colSpan={tableStructure.length}><CircularProgress/></TableCell>
            </TableRow>
          </TableBody>)}

          {withFooter && (
            <TableFooter className={clsx(classes.tableFooter, tableClasses.tableFooter || '')}>
              <TableRow>
                {withInputs && (
                  structure.map((cell, cell_index) => {
                    return (!!cell.footer && (
                      <TableCell key={cell.key || cell_index}
                                 colSpan={cell.colSpan && cell.colSpan.footer || 1}
                                 className={clsx(tableClasses.tableCell || '', classes.tableCell)}
                                 align={cell.align || 'left'}>
                        {cell.footer.map((ColEl, cell_item_idx) => (
                          <ColEl key={cell_item_idx} options={options}/>
                        ))}
                      </TableCell>
                    ))
                  }
                ))}
                {!withInputs && renderRow('footer')}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </div>

      { (paginationEnabled && isPaginatedArray(dataSource)) ? (
        <Grid container className={classes.paginationContainer}>
          <Grid item className={classes.paginationItem}>
            <span className={classes.recordsOnPage}>
              {getStartRecordsNumber()} - {getTillRecordsNumber()} von {paginationOptions.totalRecordsCount}
            </span>
          </Grid>

          {pageSizeSelectEnabled &&
            <Grid item className={classes.paginationItem}>
              <SelectFormElement
                value = {currentPageSize}
                options = {PAGE_SIZE_SELECT_OPTIONS}
                onChange = {handlePageSizeChange}
                custom_classes = {classes}
              />
            </Grid>
          }

          <Grid item className={classes.paginationItem}>
            <Pagination
              totalPageCount={paginationOptions.totalPagesCount}
              currentPage={paginationOptions.currentPage}
              handlePageChanged={handlePageChanged}
            />
          </Grid>

          <Grid item >
            <PaginationInput
              totalPageCount={paginationOptions.totalPagesCount}
              currentPage={paginationOptions.currentPage}
              label="Seite aufrufen:"
              classes={{
                label: classes.paginationInputLabel
              }}
              handlePageChanged={handlePageChanged}
            />
          </Grid>
        </Grid>
      ) : (null)}
    </>
  )
}

DashboardTable.defaultProps = {
  withFooter: true,
  withHeader: true,
  expandedItems: {},
  paginationOptions: {},
  dataSourceKeyField: 'id' // We assume that almost everytime dataSource items will have id field with uniq values
}

export default withWidth()(DashboardTable);