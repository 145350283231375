import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {createSelector} from "reselect";
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Widget, WidgetBody, WidgetHeader } from '../../../../components';
import DashboardTable from '../../../../../../components/DashboardTable/DashboardTable';
import { musterPortfolioInstrumentsTableStructure } from '../../../../components/InstrumentsList/table-data';
import CustomerThemeProvider from '../../../../../../components/CustomerThemeProvider/CustomerThemeProvider';
import {
  DashboardSection,
  InstrumentList,
  PaymentPlans,
  ProfitLossList,
} from '../../../../../CustomerDashboard/components/Widgets/components';
import { automaticallyUncollapsePaymentPlans } from '../../../../../CustomerDashboard/utils';
import { isInstrumentsDataExist, getErrorMessage } from '../../../../../../utils/utils';
import { isTradingEnabled, isSavingsPlansEnabled } from '../../../../../../components/TradingStore/utils';
import { PrimaryButton } from '../../../../../../components/Buttons';
import VirtualOrderModal from '../../../../../VirtualPortfolioManager/modals/VirtualOrderModal';
import { ModelPortfolioResource, parseResponse } from '../../../../../../utils/api';
import { SHARED_SETTINGS_KEYS } from '../../../../../../components/SharedSettingsProvider/constants';
import { displayErrorSnackBar } from '../../../../../../components/SnackbarProvider/actions';
import { setEarliestPriceDates } from '../../../../../Trades/utils';


const getSharedSettings = (state) => state.get('sharedSettings');

const getSharedSettingsSelector = createSelector(
  [getSharedSettings],
  (sharedSettings) => sharedSettings.toJS()
);

const mapStateToProps = (state) => ({
  sharedSettings: getSharedSettingsSelector(state)
});

class InstrumentsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderModalOpened: false,
      assetLoaded: false,
      outsideTrigger: !!this.getSearchIsin(),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // Used as useEffect to uncollapse paymentPlansItems they were fetched
    if(prevProps.instrumentList.data != this.props.instrumentList.data || prevProps.paymentPlans.data != this.props.paymentPlans.data) {
      automaticallyUncollapsePaymentPlans(this.props.instrumentList.data, this.props.onExpandedItemsChange, {
        'paymentPlansItems': this.props.paymentPlans.data
      })
    }

    if (prevProps.portfoliosTransactions != this.props.portfoliosTransactions && !_.isEmpty(this.props.portfoliosTransactions) && !this.state.assetLoaded) {
      this.fillTransactionWithAssetData();
    }
  }

  getSearchIsin = () => {
    const searchParams = _.get(this.props, 'location.search');
    const queryParams = new URLSearchParams(searchParams);
    return queryParams.get('isin') || undefined;
  }

  fillTransactionWithAssetData = () => {
    const isin = this.getSearchIsin();

    if (this.getTradingEnabled() && isin) {
      this.getAssetData(isin);
      this.setState({ orderModalOpened: true });
      this.props.history && this.props.history.replace({
        search: '',
      });
    }
  }

  searchAssets = async (filters, resolve, errors) => {
    let response = {};

    try {
      response = await ModelPortfolioResource.searchAssets(filters);
    } catch (errors) {
      this.props.dispatch(displayErrorSnackBar(getErrorMessage(errors)));
    }
    parseResponse(response, 'assets', resolve, errors);
  }

  getAssetData = async (isin) => {
    await this.searchAssets({ name: isin }, async (data) => {
      await this.updateTransactionsWithSelectedAssets(data);
      this.setState({ assetLoaded: true });
    }, (e) => {
      this.setState({ assetLoaded: true });
      this.props.dispatch(displayErrorSnackBar('Fehler beim Hinzufügen des Vermögenswert.'));
    });
  }

  updateTransactionsWithSelectedAssets = async (selectedAssets) => {
    const updatedTransactions = _.cloneDeep(this.props.portfoliosTransactions);
    await setEarliestPriceDates(selectedAssets, this.props.dispatch, 'isin');

    updatedTransactions[0].transactions.buy = selectedAssets.map((a) => {
      return {
        data: {
          ...a,
          price_eur: a.calculated.last_price_value,
        },
        earliestPrice: a.earliestPrice,
      };
    });

    this.props.setPortfoliosTransactions(updatedTransactions);
  }

  initPortfoliosTransactions = () => {
    this.props.initPortfoliosTransactions && this.props.initPortfoliosTransactions();
  };

  getAssetsDataSource = () => {
    if (this.props.modelPortfolio.loading) {
      return [{}, {}, {}, {}, {}, {}]
    }

    if (this.props.modelPortfolio.data && this.props.modelPortfolio.data.assets) {
      return this.props.modelPortfolio.data.assets;
    }

    return []
  };

  getAvailableAssets = () => {
    let assets = [].concat(...this.getAssetsDataSource());
    return assets.filter(asset => !asset.disabledByFilter);
  };

  getTradingEnabled = () => {
    const isNotPI = !this.props.investmentStrategyId;
    // disable trading for PI/ViewOnly
    return !this.props.viewOnly && isNotPI && isTradingEnabled();
  }

  isDataExists = () => {
    return this.state.outsideTrigger ? this.state.assetLoaded : !_.isEmpty(this.props.portfoliosTransactions);
  }

  getInstrumentsFooterText = () => {
    const message = _.get(this.props.sharedSettings, `data.${SHARED_SETTINGS_KEYS.PI_INSTRUMENTS_LIST_FOOTER_TEXT}`);
    return <div dangerouslySetInnerHTML={{ __html: message }} />
  }

  handleAllSelected = () => {
    if (this.props.onSelectAll) {
      const selected = this.props.selectedInstruments || [];
      let assets = this.getAvailableAssets();

      if (selected.length === assets.length) {
        assets = [];
      }

      this.props.onSelectAll(assets)
    }
  };

  getSavingPlanTradeOptions = () => {
    if(isSavingsPlansEnabled() && this.getTradingEnabled()) {
      return {
        onAddSavingPlanOption: this.props.handleAddSavingPlanOption,
        onAddPortfolioSavingPlanOption: undefined,
        savingPlans: this.props.combinedTradings,
        savingsPlanSession: undefined,
      }
    }
  };

  render() {
    const {
      passedClasses,
      modelPortfolio,
      instrumentList,
      unrealizedProfitAndLossData,
      paymentPlans,
      profitAndLoss,
      portfoliosTransactions,
      setPortfoliosTransactions,
    } = this.props;

    const isNotPI = !this.props.investmentStrategyId;
    const tradingEnabled = this.getTradingEnabled();

    return (
      <Grid container spacing={2}>
        {!_.isNil(this.props.onInstrumentSelect) &&
          <Grid item xs={12}>
            <Widget>
              <WidgetHeader>
                <p className={passedClasses.sectionHeader}>
                  Instrumente
                  {modelPortfolio.loading ? (
                    <Skeleton style={{width: 100, marginLeft: 10}}/>
                  ) : (
                    <>
                      {modelPortfolio.data && modelPortfolio.data.assets && (
                          <span className={passedClasses.instrumentsNumber}
                                style={{marginLeft: 10}}> ( {modelPortfolio.data.assets.length} Instrumente)</span>
                        )
                      }
                    </>
                  )}
                </p>
              </WidgetHeader>
              <WidgetBody style={{overflowX: 'auto'}}>
                {/* Instruments table */}
                {(modelPortfolio.loading || (modelPortfolio.data && modelPortfolio.data.assets))
                  ? (
                    <>
                      <DashboardTable
                        structure={musterPortfolioInstrumentsTableStructure}
                        dataSource={this.getAssetsDataSource()}
                        expanded={true}
                        tableClasses={passedClasses}
                        withFooter={false}
                        options={{
                          onAllSelect: !!this.props.onSelectAll ? this.handleAllSelected : null,
                          totalInstrumentsCount: this.getAvailableAssets().length,
                          onInstrumentSelect: this.props.onInstrumentSelect,
                          selected: this.props.selectedInstruments || [],
                          loading: modelPortfolio.loading,
                          //onDelete: this.handleModelPortfolioDelete
                        }}
                      />
                    </>

                  ) : (
                    <div className={passedClasses.assetsDataErrorContainer}>
                      <p>Produkt Stammdaten konnten nicht geladen werden.</p>
                    </div>
                  )
                }
              </WidgetBody>
            </Widget>
          </Grid>
        }

        <Grid item xs={12}>
          <CustomerThemeProvider>
            <DashboardSection
              title={null}
              content={
                <InstrumentList
                  data={instrumentList.data}
                  expandedItems={this.props.expandedItems.instrumentsItems}
                  onExpandedItemsChange={(items) => this.props.onExpandedItemsChange('instrumentsItems', items)}
                  expandedSubItems={this.props.expandedItems.instrumentsSubItems}
                  showCell={() => true}
                  onExpandedSubItemsChange={(items) => this.props.onExpandedItemsChange('instrumentsSubItems', items)}
                  unrealizedProfitAndLossData={unrealizedProfitAndLossData}
                  expandedUnrealizedProfitAndLossItems={this.props.expandedItems.unrealizedProfitAndLossItems}
                  onExpandedUnrealizedProfitAndLossItemsChange={(items) => this.props.onExpandedItemsChange('unrealizedProfitAndLossItems', items)}
                  withTransactionSaldo={true}
                  portfolioNameRender={(portfolio) => `${portfolio.name}`}
                  showSubSectionTitle={false}
                  footerText={!isNotPI && this.getInstrumentsFooterText()}
                  addToComparisonPossible={false}

                  // trading props
                  isVirtualOrderEnabled={tradingEnabled}
                  isVirtual={tradingEnabled}
                  resource={ModelPortfolioResource}
                  tradings={this.props.combinedTradings}
                  onAddTradingOption={this.props.handleAddTradingOption}
                  refresh={this.props.postVirtualOrdersCreated}
                />
              }
              loading={instrumentList.loading}
              error={instrumentList.errors}
              empty={!isInstrumentsDataExist(instrumentList)}
              displayError={true}
              titleControl={
                <>
                  {tradingEnabled && !instrumentList.loading && (
                    <PrimaryButton
                      icon={<i className="far fa-dollar-sign fa-xs" style={{color: 'white'}} />}
                      text={!!this.props.combinedTradings ? "ORDER FORTSETZEN" : "ORDER"}
                      onButtonClick={(_event) => {this.setState({orderModalOpened: true})}}
                    />
                  )}
                </>
              }
            />
          </CustomerThemeProvider>
        </Grid>

        {isNotPI && (
          <>
            {/* Payment plans */}
            <Grid item xs={12}>
              <CustomerThemeProvider>
                <DashboardSection
                  title={'Zahlpläne'}
                  content={
                    <PaymentPlans
                      paymentPlans={paymentPlans.data}

                      paymentPlansExpandedItems={this.props.expandedItems.paymentPlansItems}
                      onExpandedItemsChange={(items, key) => this.props.onExpandedItemsChange(key, items)}
                      getSavingPlanTradeOptions={this.getSavingPlanTradeOptions}
                    />
                  }
                  loading={paymentPlans.loading}
                  error={paymentPlans.errors}
                  empty={false}
                  displayError={true}
                />
              </CustomerThemeProvider>
            </Grid>

            {/* profitAndLossItems (completely sold assets) */}
            <Grid item xs={12}>
              <CustomerThemeProvider>
                <DashboardSection
                  title='Vollständig verkaufte Vermögenswerte'
                  content={
                    <ProfitLossList
                      data={profitAndLoss}
                      expandedItems={this.props.expandedItems.profitAndLossItems}
                      onExpandedItemsChange={(items) => this.props.onExpandedItemsChange('profitAndLossItems', items)}
                      expandedSubItems={this.props.expandedItems.profitAndLossSubItems}
                      onExpandedSubItemsChange={(items) => this.props.onExpandedItemsChange('profitAndLossSubItems', items)}
                    />
                  }
                  loading={profitAndLoss.loading}
                  error={profitAndLoss.errors}
                  empty={!_.get(profitAndLoss, 'data', []).length > 0} // !isProfitLossDataExist() todo move to common func with virtual dashboard
                  displayError={true}
                />
              </CustomerThemeProvider>
            </Grid>
          </>
        )}

        {this.isDataExists() && (
          <VirtualOrderModal
            open={this.state.orderModalOpened}
            onClose={() => {this.setState({orderModalOpened: false})}}

            portfoliosTransactions={portfoliosTransactions}
            setPortfoliosTransactions={setPortfoliosTransactions}
            initPortfoliosTransactions={this.initPortfoliosTransactions}

            portfolioOwnerId={_.get(modelPortfolio, 'data.id')}
            refresh={this.props.postVirtualOrdersCreated}
            dispatch={this.props.dispatch}
            resource={ModelPortfolioResource}

            useUpdate={this.props.useUpdate}
            handleEmptyMpUpdated={this.props.handleEmptyMpUpdated}
          />
        )}
      </Grid>
    )
  }
}

export default connect(mapStateToProps)(InstrumentsTab);