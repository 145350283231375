export const ROTATE_ICON_STYLES = {
  rotateIcon: {
    backgroundColor: 'transparent !important',
    color: 'rgba(0, 0, 0, 0.54)',
    borderRadius: '50% !important',
    position: 'relative',
    right: 8,

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    }
  }
};