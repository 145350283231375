import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    width: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',

    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',
    backgroundColor: 'white',
    borderRadius: 2,
    padding: '0px 10px',

  },
  data: {
    '& > span': {
      height: 40
    }
  },
  count: {
    '& > span': {
      width: 25,
      height: 40,
      borderRadius: '50%',
      marginLeft: 'auto'
    }
  }
})

export default makeStyles(styles)