import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  InputAdornment
} from '@material-ui/core';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton'

import useStyles from './styles';
import images from '../../../../images';
import CustomerFullnameMenu from "../../../../components/CustomerFullnameMenu/CustomerFullnameMenu";


const GroupCustomersList = ({
  customers,
  onCustomerRemove
}) => {
  const classes = useStyles();

  const handleCustomerRemove = customer => () => {
    if (!_.isNil(onCustomerRemove)) {
      onCustomerRemove(customer);
    }
  }

  const renderCustomersList = () => {
    if (!_.isNil(customers)) {
      return customers.map(customer => (
        <TableRow className={classes.customersListRow}>
          <TableCell>
            <CustomerFullnameMenu
              customer={customer}
              customerFullname={customer.customer_full_name}
            />
          </TableCell>
          <TableCell className={classes.textAlignRight}>{customer.customer_id}</TableCell>
          <TableCell>
            <img
              src={images.close_icon}
              className={classes.deleteCustomerIcon}
              onClick={handleCustomerRemove(customer)}
            />
          </TableCell>
        </TableRow>
      ))
    }
  };

  return (
    <Table>
      <TableHead className={classes.customersListHead}>
        <TableRow>
          <TableCell>KUNDENNAME</TableCell>
          <TableCell className={classes.textAlignRight}>KUNDENNUMMER</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {renderCustomersList()}
      </TableBody>
    </Table>
  )
}


const GroupDetails = ({
  selectedCustomers,
  errors,
  name,
  handleValueChanged,
  onCustomerRemove
}) => {
  const classes = useStyles();

  const handleGroupNameChanged = (event) => {

    if (!_.isNil(handleValueChanged)) {
      handleValueChanged('groupName', event.target.value);
    }

  }

  return (
    <div className={classes.container}>
      <div className={classes.summary}>
        <p className={classes.summaryText}>
          <span
            className={classes.summaryNumber}
            style={{
              backgroundColor: (selectedCustomers && selectedCustomers.length) ? '#E3A600' : '#ABB6BE'
            }}
          >{selectedCustomers && selectedCustomers.length || 0}</span>
          <span>Kunden hinzugefügt</span></p>
      </div>
      <div className={classes.inputContainer}>
        <TextField
          className={classes.textField}
          error={errors && 'name' in errors}
          value={name}
          label="Gruppenname eingeben:"
          margin="normal"
          onChange={handleGroupNameChanged}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: classes.inputLabelRoot,
              shrink: classes.inputLabelShrink,
              error: classes.inputLabelError
            }
          }}
          InputProps={{
            endAdornment: <InputAdornment className={classes.inputAdornment} position="end">{name && name.length || 0}/25</InputAdornment>
          }}
          inputProps={{maxLength: 25}}
        />
          <PrimaryButton
            text={
              <span>
                <i className="fa fa-plus" style={{marginRight: 5}}/>
                Gruppe erstellen
              </span>
            }
          />
      </div>
      <GroupCustomersList
        customers={selectedCustomers}
        onCustomerRemove={onCustomerRemove}
      />
    </div>
  )
};

export default GroupDetails;