import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',
    backgroundColor: 'white',
    borderRadius: 2,
    padding: '0px 10px',

  },
  actions: {
    '& > span': {
      width: 150
    }
  },
  data: {
    '& > span:first-child': {
      width: 200,
      height: 40
    },
    '& > span:last-child': {
      width: 300,
    }
  }
})

export default makeStyles(styles)