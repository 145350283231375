import React from 'react';
import _ from 'lodash'

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox'

/* BCA modules*/
import useStyles from './styles';
import { REPORTS_AREAS_LIST } from "./constants";

export default (props) => {
    const classes = useStyles();

    const [values, setValues] = React.useState(props.selected || []);

    React.useEffect(() => {
      // if updateDisabled passed - update setting only in case customer in group and settings disabled
      if (!_.isNil(props.selected) && (!props.updateDisabled || props.disabled)) {
        setValues(props.selected);
      }
    }, [JSON.stringify(props.selected)]);

    const onValueChange = value => event => {
      let newValues = undefined;
      if (values.includes(value)) {
        newValues = _.filter(values, elem => elem != value);
      } else {
        newValues = [...values, value];
      }
      
      if (!_.isNil(newValues)) {
        setValues(newValues);
        if (props.handleChanged) {
          props.handleChanged(newValues);
        }
      }
      
    }

    return (
        <div className={classes.container}>
          <FormControl disabled={props.disabled} component="fieldset">
            <FormLabel 
              component="legend" 
              classes={{root: classes.formLabel, focused: classes.focusedLabel}}
            >
              {props.label || 'Inhalte'}
            </FormLabel>
            {(props.options || REPORTS_AREAS_LIST).map((item) => (
              <FormControlLabel
                label={item.description}
                classes={{label: classes.formControlLabel}}
                control={
                  <Checkbox
                    color="primary"
                    disabled={props.isDepotStatus && item.isDisabledForDepotStatus}
                    classes={{root: classes.radio}}
                    checked={values.includes(item.value) && !(props.isDepotStatus && item.isDisabledForDepotStatus)}
                    onChange={onValueChange(item.value)}
                  />
                }
              />
            ))}
          </FormControl>
        </div>
    )
}