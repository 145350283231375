import {openMenu} from "../../../components/Header/actions";
import {mdScreenStepsSizes} from "../../App/constants";

const navigation_1 = {
  selector: '#logout-button-tour-element',
  key: 'documents_navigation_logout',
  position: 'bottom',
};
const navigation_1_mobile = {
  ...navigation_1,
  selector: '#logout-button-tour-element-side-menu',
  dispatchFn: [openMenu],
};
const navigation_2 = {
  selector: '#portfolio-dashboard-tour-element',
  key: 'documents_navigation_dashboard',
  position: 'bottom',
};
const navigation_2_mobile = {
  ...navigation_2,
  selector: '#portfolio-dashboard-tour-element-side-menu',
  dispatchFn: [openMenu],
};
const navigation_3 = {
  selector: '#assets-tour-element',
  key: 'documents_navigation_other_assets',
  position: 'bottom',
};
const navigation_3_mobile = {
  ...navigation_3,
  selector: '#assets-tour-element-side-menu',
  dispatchFn: [openMenu],
};

export const getNavigationChapterSteps = (width) => {
  return mdScreenStepsSizes.includes(width) ? [
    navigation_1_mobile,
    navigation_2_mobile,
    navigation_3_mobile
  ] : [
    navigation_1,
    navigation_2,
    navigation_3
  ];
};