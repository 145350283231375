import React from 'react';

/* BCA Components */
import ProfileDetails from '../../../components/ProfileDetails';


export default (props) => {

  const {
    customer,
    customerLoadingError,
    customerDataLoading,
    investmentData,
    investmentDataLoading,
    investmentDataLoadingError,
    unrealizedProfitAndLossData,
    unrealizedProfitAndLossDataLoading,
    unrealizedProfitAndLossDataLoadingError,
    isVirtual,
    clickHandlerNewPortfolio,
    portfoliosDataLoading,
    reportType,
    sectionHeader,
    sectionSubheader,
    withOtherAssets
  } = props;

  return (
    <ProfileDetails
      customer={customer}
      customerLoadingError={customerLoadingError}
      customerDataLoading={customerDataLoading}
      investmentData={investmentData}
      investmentDataLoading={investmentDataLoading}
      investmentDataLoadingError={investmentDataLoadingError}
      unrealizedProfitAndLossData={unrealizedProfitAndLossData}
      unrealizedProfitAndLossDataLoading={unrealizedProfitAndLossDataLoading}
      unrealizedProfitAndLossDataLoadingError={unrealizedProfitAndLossDataLoadingError}
      isVirtual={isVirtual}
      longTermReturnVisible
      otherAssetsLink
      reportType={reportType}
      clickHandlerNewPortfolio={clickHandlerNewPortfolio}
      portfoliosDataLoading={portfoliosDataLoading}
      totalInvestedAmountDisabled={props.totalInvestedAmountDisabled}
      sectionHeader={sectionHeader}
      sectionSubheader={sectionSubheader}
      withOtherAssets={withOtherAssets}
    />
  )
}