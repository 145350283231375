import { makeStyles } from '@material-ui/core';

const itemBorder = '1px solid #E1E3E9';

export default makeStyles((theme) => ({
  cardItem: {
    padding: 20,
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    borderRight: itemBorder,

    [theme.breakpoints.only('sm')]: {
      '&:first-child': {
        borderBottom: itemBorder
      },
      '&:nth-of-type(2)': {
        borderRight: 'none',
        borderBottom: itemBorder
      },
    },

    [theme.breakpoints.only('xs')]: {
      borderRight: 'none',
      borderBottom: itemBorder,
    }
  },
  buttonWrapper: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  text: {
    whiteSpace: 'normal'
  },
  autoReportWarning: {
    paddingTop: 5,
    fontSize: '10pt',
    color: '#4D4F5C',
    textAlign: 'center'
  },
  autoReportDetails: {
    paddingTop: 5,
    textAlign: 'center',
    fontSize: '10pt',
    color: '#4D4F5C',
  }
}));