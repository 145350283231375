import {fontSizeTable, smFontSizeTable} from "../../../../../../utils/constants";

const styles = theme => ({
  root: {
    minHeight: 490,
    width: '100%'
  },
  container: {
    fontSize: fontSizeTable,
    fontFamily: 'Roboto-Regular',
    color: '#202A38',

    [theme.breakpoints.only('xs')]: {
      fontSize: smFontSizeTable
    }
  },
  containerError: {
    display: 'flex',
    marginTop: 100
  },
  title: {
    fontFamily: 'Roboto-Bold',
    width: '50%',
    [theme.breakpoints.only('xs')]: {
      width: '40%',
    }
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    padding: '7px 0',
    borderBottom: 'solid 1px #D8DCDF',
    '&:first-of-type': {
      paddingTop: 0
    },
    '&:last-of-type': {
      borderBottom: 'none',
      paddingBottom: 0
    }
  },
  headline: {
    fontFamily: 'Roboto-Bold',
    textAlign: 'right',
    backgroundColor: '#F5F6F7',
    minHeight: 46,
    padding: '4px 7px 0 7px',
    borderBottom: 'none',
    '& > span': {
      color: '#80858C',
      fontFamily: 'Roboto-Bold',
      paddingTop: 12
    }
  },
  values: {
    width: '25%',
    textAlign: 'right'
  },
  emptyMessage: {
    margin: 'auto',
    fontSize: 22,
    color: '#b1b1b1',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  helpIconMiddle: {
    verticalAlign: 'middle',
    color: theme.palette.primary.main
  }
})

export default styles;