import {makeStyles} from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1
  },
  label: {
    color: '#313D44',
    fontFamily: 'Roboto-Bold',
    marginBottom: 8,
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  hideLabel: {
    display: 'none'
  },
  value: {
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  }
}))