import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
    values: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'self-end',
        whiteSpace: 'nowrap'
    },
    root: {
        '& h2': {
            color: '#4D4F5C',
            overflow: 'hidden',
            fontSize: '20px',
            fontFamily: 'Roboto-Regular',
        }
    },
    item: {
        padding: '.6rem 1rem .2rem 1rem',
        color: '#4D4F5C',
        fontFamily: 'Roboto-Regular',
    },
    titleWithoutData: {
        marginBottom: 50,
    },
}));
