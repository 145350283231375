import React from 'react'

import useStyles from './styles'
import clsx from "clsx";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {Grid, IconButton} from "@material-ui/core";
import _ from "lodash";
import {APPROVAL_STATUS, USAGE_AREAS_LABELS} from "../../constants";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {ArrowIcon, DownloadIcon, PrintIcon} from "../../../../images";
import {target} from "react-paginate/demo/webpack.config";
import moment from "moment";
import {useDocumentsPreviewSectionContext} from "../../index";
import {toGermanFormat} from "../../../../utils/numberFormater";
import {getApprovalStatus} from "../../utils";


function BusinessCaseApproval({approval, approverView}) {

  const classes = useStyles()

  const {onAddDocumentToPreview, ...contextValues} = useDocumentsPreviewSectionContext()

  const [expanded, setExpanded] = React.useState(true)

  const toggleExpanded = () => {
    setExpanded((value) => !value)
  }

  const approvalStatus = React.useMemo(() => {

    return getApprovalStatus(approval)

  }, [approval.documents])

  const getDocumentStatus = (document) => {
    return _.find(APPROVAL_STATUS, (status) => status.value == document.status)
  }

  const handlePrintButtonClick = (document) => {
    window.open(document.document_path, "_blank")
  }

  const isDocumentOnPreview = (document) => {
    return !!_.find(contextValues.documents || [], (previewDocument) => previewDocument.id == document.id)
  }

  /**
   * Render header columns depending on approvalView prop.
   */
  const renderHeaderSectionColumns = () => {
    return approverView ? (
      <>
        <Grid item xs={2}><b>{_.get(approval, 'business_case.name')}</b></Grid>
        <Grid item xs={2} style={{textAlign: 'right'}}><b>Vertrags-Nr:</b><br />{(_.get(approval, 'business_case.contract_number') || []).join(', ')}</Grid>
        <Grid item xs={2}><b>Datum:</b><br />{moment(_.get(approval, 'business_case.created_at')).format('DD.MM.YYYY')}</Grid>
        <Grid item xs={2}><b>Gesellschaft:</b><br />{_.get(approval, 'business_case.custodian.name')}</Grid>
        <Grid item xs={2}><b>Vermittler-Nr:</b><br />{_.get(approval, 'broker.broker_id')}</Grid>
        <Grid item xs={2}><b>Status:</b><br />{approvalStatus.name}</Grid>
      </>
    ) : (
      <>
        <Grid item xs={2}><b>{_.get(approval, 'business_case.name')}</b></Grid>
        <Grid item xs={1} style={{textAlign: 'right'}}><b>Vertrags-Nr:</b><br />{(_.get(approval, 'business_case.contract_number') || []).join(', ')}</Grid>
        <Grid item xs={1} style={{textAlign: 'right'}}><b>Anlagebetrag:</b><br />{toGermanFormat(_.get(approval, 'business_case.total_amount', 0), '', ' €')}</Grid>
        <Grid item xs={1}><b>Datum:</b><br />{moment(_.get(approval, 'business_case.created_at')).format('DD.MM.YYYY')}</Grid>
        <Grid item xs={2}><b>Gesellschaft:</b><br />{_.get(approval, 'business_case.custodian.name')}</Grid>
        <Grid item xs={2}><b>Mitarbeiter:</b><br />{_.get(approval, 'broker.broker_full_name')}</Grid>
        <Grid item xs={1}><b>Mitarbeiter-Nr:</b><br />{_.get(approval, 'broker.broker_id')}</Grid>
        <Grid item xs={1}><b>Kunden-Nr:</b><br />{_.get(approval, 'customer.customer_id')}</Grid>
        <Grid item xs={1}><b>Status:</b><br />{approvalStatus.name}</Grid>
      </>
    )
  }

  return (
    <Accordion
      onChange={toggleExpanded}
      expanded={expanded}
      classes={{
        root: classes.accordionRoot
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{
          root: classes.accordionSummaryExpanded
        }}
        style={{
          borderColor: approvalStatus.color
        }}
      >
        <Grid container spacing={2} style={{fontSize: 11}}>
          { renderHeaderSectionColumns() }
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetailsSkipPadding}>
        <Grid container spacing={2} style={{padding: '22px 0 0'}}>
          {approval.documents.map((document) => (
            <Grid item xs={12} className={classes.documentLine} key={document.id}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={4}>
                  <p className={classes.status}>{ document.document_name }</p>
                </Grid>
                <Grid item lg={3} md={4}>
                  <p className={classes.status}>{USAGE_AREAS_LABELS[document.event_type] || ''}</p>
                </Grid>
                <Grid item lg={3} md={4}>
                  <span>
                    <p className={classes.statusButton} style={{borderColor: getDocumentStatus(document).color, color: getDocumentStatus(document).color}}>{getDocumentStatus(document).name}</p>
                    <IconButton onClick={() => handlePrintButtonClick(document)} style={{marginLeft:15}}>
                      <DownloadIcon />
                    </IconButton>
                    <IconButton onClick={() => onAddDocumentToPreview(approval, document)} style={{marginLeft:15}}>
                      <i className="far fa-eye fa-sm" style={{color: isDocumentOnPreview(document) ? '#0092E5' : 'grey'}}/>
                    </IconButton>
                  </span>
                </Grid>
              </Grid>

            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default BusinessCaseApproval