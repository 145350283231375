import React from 'react';

import useStyles from './styles';
import Switch from "../../../../components/AssetModal/components/Switch";
import WarningTooltip from "../../../../components/WarningTooltip";

function AggregatedDepotsSwitch({value, onChange, title, disabled, tooltip}) {

  const classes = useStyles();

  return (
    <p className={classes.container}>
      <Switch
        value={value}
        handleValueChanged={onChange}
        disabled={disabled}
        showLabel={false}
        customClasses={{
          container: classes.aggregatePortfoliosSwitchContainer,
          thumb: classes.aggregatePortfoliosSwitchThumb,
          checked: classes.aggregatePortfoliosSwitchChecked,
        }}
      />
      <span>{title || "kumuliert"}</span>
      {tooltip && (
        <WarningTooltip title={tooltip} />
      )}
    </p>
  )
}

AggregatedDepotsSwitch.propTypes = {}

AggregatedDepotsSwitch.propDefs = {}

AggregatedDepotsSwitch.defaultProps = {}

export default AggregatedDepotsSwitch;