import React from 'react';

import ProfileDetails from '../../../../components/ProfileDetails';

export default (props) => {
  const {
    customer,
    customerLoading,
    customerLoadingError,
    investmentData,
    investmentDataLoading,
    investmentDataLoadingError,
    unrealizedProfitAndLossData,
    unrealizedProfitAndLossDataLoading,
    unrealizedProfitAndLossDataLoadingError,
  } = props;

  return (
    <div style={{marginBottom: 30}}>
      <ProfileDetails
        customer={customer}
        customerLoadingError={customerLoadingError}
        customerDataLoading={customerLoading}
        investmentData={investmentData}
        investmentDataLoading={investmentDataLoading}
        investmentDataLoadingError={investmentDataLoadingError}
        unrealizedProfitAndLossData={unrealizedProfitAndLossData}
        unrealizedProfitAndLossDataLoading={unrealizedProfitAndLossDataLoading}
        unrealizedProfitAndLossDataLoadingError={unrealizedProfitAndLossDataLoadingError}
        otherAssetsVisible
        withOtherAssets
        dashboardLink
      />
    </div>
  )
};
