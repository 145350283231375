import React, { useState } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'


import { executeIfPathExist, getInvestmentDynamicPath } from '../../containers/InvestmentPlatform/utils'
import CustomersNew from "./CustomersNew";
import {CUSTOMER_ID_KEY, setInStorage} from "../../utils/storage";
import {getErrorMessage, objectToQuery} from "../../utils/utils"
import _ from 'lodash';
import useStyles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import {displayErrorSnackBar} from "../SnackbarProvider/actions";
import {CONFIRM_REMOVE_MSG} from "../../containers/RiskProfiling/constants";
import ConfirmationDialog from "../../containers/VirtualPortfolioManager/modals/ConfirmationDialog";
import IconButton from "@material-ui/core/IconButton";
import {getAuthSelector, getInvestmentPlatformSelector} from "../../utils/redaxSelectors";

const mapStateToProps = (state) => ({
  auth: getAuthSelector(state),
  investmentPlatform: getInvestmentPlatformSelector(state),
});


const withCustomersSelectorNew = (componentIdentifier, getButtonAttributes, onCustomersChange, additionalColumns=[], component, additionalFiltersRender) => WrappedComponent => {
  if(!_.isArray(getButtonAttributes)){
    getButtonAttributes = [getButtonAttributes]
  }
  return withRouter(connect(mapStateToProps)((props) => {

    const[loading, setLoading] = React.useState(false);
    const classes = useStyles();

    const handleButtonClick = async (id, buttonAttributes) => {
      setLoading(true);

      try {
        if (buttonAttributes.customOnClickHandler) {
          await buttonAttributes.customOnClickHandler(props)

        } else {
          // get path from button or take it from prop
          let destinationComponentIdentifier = buttonAttributes.componentIdentifier || componentIdentifier;

          executeIfPathExist(props.investmentPlatform.routes, destinationComponentIdentifier, (path) => {
            setInStorage(CUSTOMER_ID_KEY, id);
            let investmentDynamicPath = getInvestmentDynamicPath();
            let url = `/${investmentDynamicPath}${path.replace(':customer_id', id)}`;

            // extend url with params
            if (buttonAttributes.urlParams) {
              let queryStr = objectToQuery(buttonAttributes.urlParams);
              url = `${url}?${queryStr}`;
            }

            // open options
            if (buttonAttributes.openInNewTab) {
              const win = window.open(url, '_blank');
              if (win) win.focus();
            } else {
              props.history.push(url)
            }

          }, ':customer_id')
        }
      } finally {
        setLoading(false)
      }
    };

    if (props.match.params.customer_id) {
      return (
        <WrappedComponent {...props}/>
      )
    }

    const extraButtonColumn = (btnAttr, index) =>{
      return {
        content: [{
          header: '',
          body: {
            content: (customer) => {

              const [dialogOpen, setDialogOpen] = useState(false);
              const [buttonAttributes, setButtonAttributes] = React.useState({});
              React.useEffect(() => {
                // component did mount to set button attributes
                updateButtonAttributes()
              }, []);

              const updateButtonAttributes = () =>{
                // function to update button attributes when something is changed

                let _buttonAttributes = typeof btnAttr === "function" && btnAttr(customer) || {};
                _buttonAttributes = {title: "Vermögensübersicht", ..._buttonAttributes};
                setButtonAttributes(_buttonAttributes)
              };

              const onDeletePopupOpen = (event) => {
                event.preventDefault();
                event.stopPropagation();
                setDialogOpen(true);
              };
              const onDeleteClickHandler = async () =>{
                try {
                  await buttonAttributes.customOnDeleteClickHandler(props);
                  updateButtonAttributes()
                } catch (e) {
                  props.dispatch(displayErrorSnackBar(getErrorMessage(e)));
                } finally {
                  setDialogOpen(false);
                }
              };

              return (
                <div>
                  <a key={index} href={null} className={clsx(buttonAttributes.disabled ? 'disabled':'', 'linkButtons')} onClick={() => handleButtonClick(customer.customer_id, buttonAttributes)}>
                    <span>{buttonAttributes.title}</span>
                    {_.isFunction(buttonAttributes.customOnDeleteClickHandler)
                      ?
                        <IconButton className={classes.deleteBtn} onClick={onDeletePopupOpen} title={buttonAttributes.deleteBtnTitle || 'Löschen'} color={"primary"}>
                          <i className={"far fa-trash-alt"} />
                        </IconButton>
                      :
                        <span className={'linkIcon'}><i className={"far fa-chevron-right "} /></span>
                    }
                  </a>
                  <ConfirmationDialog
                    message={CONFIRM_REMOVE_MSG}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    confirm={onDeleteClickHandler}
                  />
                </div>
              )
            },
            className: 'extraButton',
          }
        }]
      }
    };

    const extraColumns = [
      ...additionalColumns,
      ...getButtonAttributes.map((element, index) => {
        return extraButtonColumn(element, index)
      })
    ];

    return (
      <>
        {loading &&
          <div className={classes.preloaderContainer}>
            <CircularProgress className={classes.loadingIndicator}/>
          </div>
        }
        {/* use style instead of conditional rendering to prevent component unmount. If error occurs and loader hides, the list will no change */}
        <div style={loading ? {display: "none"} : {}}>
          <CustomersNew auth={props.auth} onCustomersChange={onCustomersChange} extraColumns={extraColumns} component={component} additionalFiltersRender={additionalFiltersRender}/>
        </div>
      </>
    )

  }))
};

export default withCustomersSelectorNew
