import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  formControlLabel: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#202A38'
  },
  radioGroup: {
    display: 'flex'
  }
})

export default makeStyles(styles)