import {fromJS} from "immutable";

import {
  SET_SHARED_SETTINGS
} from './constants';
import {getFromStorage, setInStorage, SHARED_SETTINGS_KEY} from "../../utils/storage";


const initialState = fromJS({
  data: getFromStorage(SHARED_SETTINGS_KEY) || {},
  fetched: false
});


export const createReducer = (state=initialState, action) => {
  switch (action.type) {
    case SET_SHARED_SETTINGS:
      setInStorage(SHARED_SETTINGS_KEY, action.sharedSettings)
      return state
        .set('data', fromJS(action.sharedSettings))
        .set('fetched', true)
    default:
      return state;
  }
};

export default createReducer;
