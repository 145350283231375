import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  divider: {
    height: 10
  },
  externalContentContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    '& > i': {
      color: 'red',
      fontSize: 45 
    }
  },
  disabledArea: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 99
  },
  pageLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  loader: {
    color: '#63696f'
  },
  activeTabIndicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent !important",
    bottom: '7px !important',
    "& > div": {
      maxWidth: 30,
      width: "100%",
      backgroundColor: "#0092E5"
    }
  },
  tab: {
    paddingLeft: '30px !important',
    paddingRight: '30px !important',
    fontSize: '16px !important',
    color: '#202A38 !important',
    borderRight: '1px solid #D8DCDF !important',
    textTransform: 'none !important',
    background: 'transparent linear-gradient(180deg, #FFFFFF 0%, #F5F6F7 100%) 0% 0% no-repeat !important',
    opacity: 1,
  },
  tabSelected: {
    textShadow: '.5px 0 #202A38 !important',
    background: '#ffffff !important',
  },
  tabPanel: {
    padding: 0,
  },
  paperRoot: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    boxShadow: '0px 3px 15px #39435233',
    overflow: 'hidden',
  },
  editorWrapper: {
    boxShadow: '0px 3px 15px #39435233',
  },
  collectionWrapper : {
    boxShadow: '0px 3px 15px #39435233',
    marginBottom: '30px'
  },
  editorPlaceholder: {
    width: '100%',
    minHeight: '1327px',
    display: 'flex',
    justifyContent: 'center',

    '& > p': {
      paddingTop: 100,
      fontFamily: 'Roboto-Regular',
      fontSize: 20,
      color: '#80858C',
    },
  },
  collectionNavRoot: {
    padding: '20px 30px',
    flexWrap: 'nowrap'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  progressText: {
    color: 'white',
    textWeight: 'bold'
  },
  paginationWrapper: {
    marginTop: '15px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paginationItem: {
    padding: 4
  },
  paginationInfo: {
    color: '#ABB6BE'
  },
  accordionRoot: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  accordionSummaryRoot: {
    backgroundColor: 'white',
    minHeight: 48,
    padding: '0 0 0 0 !important',
  },
  accordionSummaryContent: {
    margin: 0,
  },
  accordionSummaryExpanded: {
    minHeight: '48px !important',
    margin: '0 0 0 0 !important',
  },
  accordionDetailsRoot: {
    padding: '0 !important'
  },
  accButtonIconEdgeEnd: {
    marginRight: '0 !important'
  }
});

export default makeStyles(styles);
