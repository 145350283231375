import moment from 'moment';
import _ from "lodash";
import {MODEL_PORTFOLIO_TYPE_LABEL, MODEL_PORTFOLIO_TYPES} from '../../constants'

// NOTE: used for both model portfolio and favorite lists
export const buildTree = (items, title, itemsTypes, auth) => {
  if (!itemsTypes) {
    itemsTypes = MODEL_PORTFOLIO_TYPES;
  }

  let BCAItems = {
    id: 1,
    name: 'BCA',
    children: [],
    items: []
  };

  let agencyItems = {
    id: 2,
    name: 'Agentur',
    children: [{
      id: 3,
      name: 'Meine Agentur',
      items: []
    }],
    items: []
  };

  let subAgenciesItems = {};
  let parentAgencyItems = {};

  let userItems = [{
    id: 2,
    name: 'Privat',
    items: []
  }];

  function addToAgenciesItems(agenciesItems, itemData, agency) {
    const agencyID = `agency-${agency.agency_id}`;

    _.defaults(agenciesItems, {
      [agencyID]: {
        id: agencyID,
        name: `${agency.name} (${agency.agency_id})`,
        items: []
      }
    });

    agenciesItems[agencyID].items.push(itemData);
  }

  if (items && items.length) {
    items.forEach(item => {

      let mPortfolioData = {
        id: item.id,
        name: item.name
      };

      const item_type = item.access_type;

      if ([itemsTypes.AGENCY, itemsTypes.CUSTODIAN_AGENCY].includes(item_type)) {
        if (item.group) {
          let groupData = {
            id: `group-${item.group.id}`,
            name: item.group.name,
            items: [mPortfolioData]
          };
          agencyItems.children.push(groupData);
        } else {
          agencyItems.children[0].items.push(mPortfolioData)
        }
      } else if (item_type == itemsTypes.PERSONAL) {
        if (item.group) {
          let groupData = {
            id: item.group.id,
            name: item.group.name,
            items: [mPortfolioData]
          };
          userItems.push(groupData);
        } else {
          userItems[0].items.push(mPortfolioData)
        }
      } else if (item_type == itemsTypes.PUBLIC) {
        BCAItems.items.push(mPortfolioData)
      } else if (item_type == itemsTypes.PARENT_AGENCY) {
        addToAgenciesItems(parentAgencyItems, mPortfolioData, item.agency)
      } else if (item_type == itemsTypes.SUB_AGENCY) {
        addToAgenciesItems(subAgenciesItems, mPortfolioData, item.agency)
      }
    });

    if(!!_.get(auth, 'user.agency.parent_agency_id')){
      agencyItems.children.push({
        id: 'main-agency',
        name: MODEL_PORTFOLIO_TYPE_LABEL[MODEL_PORTFOLIO_TYPES.PARENT_AGENCY],
        children: _.flatten(Object.values(parentAgencyItems))
      })
    }

    if(!_.isEmpty(auth.user.sub_agencies)){
      agencyItems.children.push({
        id: 'sub-agencies',
        name: MODEL_PORTFOLIO_TYPE_LABEL[MODEL_PORTFOLIO_TYPES.SUB_AGENCY],
        children: _.flatten(Object.values(subAgenciesItems))
      });
    }

    return [{
      id: moment().millisecond,
      name: 'Öffentliche ' + title,
      children: [BCAItems, agencyItems]
    }, {
      id: moment().millisecond,
      name: 'Meine ' + title,
      children: userItems
    }]
  }

  return [];
};