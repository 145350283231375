import React from 'react';
import { withStyles } from "@material-ui/core";

import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import styles, { useStyles } from './styles';

import Grid from '@material-ui/core/Grid';

const WeightSlider = withStyles(styles)(Slider);


const WeightSelector = (props) => {

  const {
    weight,
    disabled,
    handleWeightChanged,
    max
  } = props;

  const classes = useStyles();

  const maxValue = max + weight;
  const [value, setValue] = React.useState(weight || 0);

  const handleChange = (value) => {
    if (value <= maxValue) {
      setValue(value);
    }
  };

  const handleChangeCommitted = (sliderValue) => {

    const resultValue = sliderValue <= maxValue ? sliderValue : maxValue;
    setValue(resultValue);
    handleWeightChanged(resultValue);
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={1} alignItems={'center'} style={{
        pointerEvents: disabled ? 'none' : 'auto',
        opacity: disabled ? .7 : 1
      }}>
        <Grid item xs>
          <WeightSlider
            value={value}
            step={1}
            onChange={(event, value) => handleChange(+value)}
            onChangeCommitted={(event, value) => handleChangeCommitted(+value)}
          />
        </Grid>
        <Grid item xs={'auto'}>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            inputProps={{
              min: 0
            }}
            InputProps={{
              classes: {
                root: classes.inputRoot
              },
              endAdornment: <InputAdornment position="end">&#x25;</InputAdornment>
            }}
            value={value}
            onChange={(event) => {
              handleChangeCommitted(+event.target.value); // is called on change to set new value each time the arrow is clicked
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )

};

export default WeightSelector;