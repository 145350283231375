import React from "react";
import useStyles from "./styles";
import {getErrorMessage} from "../../../../utils/utils";


function ErrorsContainer(props) {
  const { errors } = props;

  const classes = useStyles();

  return (
    <div className={classes.errorContainer}>
      <i className="fa fa-exclamation" aria-hidden="true" />
      <p>{getErrorMessage(errors)}</p>
    </div>
  )
}

export default ErrorsContainer;
