import { makeStyles, createStyles } from '@material-ui/core'

export const styles = theme => createStyles({
  label: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    color: '#202A38'
  },
  option: {
    marginBottom: 0,
  },
  optionLabel: {
    fontFamily: 'Roboto-Regular',
  },
  asterisk: {
    color: theme.palette.primary.main
  },
});

export default makeStyles(styles)