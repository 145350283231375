import React from 'react'
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import Link from '../../../../components/Link';
import { DetailsIcon } from '../../../../images';
import {
  getModelPortfolioOwner,
  getModelPortfolioType,
  modelPortfolioAccessible,
  getInstrName,
} from '../../../Modelportfolios/utils';
import { OwnerCell } from '../../../Modelportfolios/components/InstrumentsList/table-data';

const BlueRadio = withStyles({
  root: {
    color: '#0092E5',
    padding: 4,
    '&$checked': {
      color: '#0092E5',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const ModelportfolioNameCell = {
  header: "Name",
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }

      let allowed = modelPortfolioAccessible(item, options.auth)

      return (
        <div style={{display: 'flex', alignItems: 'center'}}>
          {options.onInstrumentSelect && (
            <div>
              {allowed && (
                <BlueRadio
                  checked={!!(options.selected && (options.selected.id == item.id))}
                  onChange={() => options.onInstrumentSelect(item)}
                  value={item.id}
                  name="list-radios"
                  inputProps={{ 'aria-label': 'A' }}
                />)}
            </div>
          )}
          {/* set min-width to truncate works as expected */}
          <div style={{minWidth: 0, marginLeft: allowed ? 0 : 32}}>
            {item.name}
          </div>
        </div>
      )
    }
  },
  key: 'ModelportfolioNameCell'
}

const TypeCell = {
  header: "Typ/Unternehmen",
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      return getModelPortfolioType(item)
    }
  },
  key: 'TypeCell'
}

const InstrumentsCountCell = {
  header: 'Anzahl Instrumente',
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.assets && item.assets.length;
    }
  },
  key: 'InstrumentsCountCell'
}

const ModelportfolioOverviewActionColumn = {
  header: "",
  body: {
    content: (item, options) => {
      if (options.loading) {
        return <Skeleton />
      }
      const itemName = getInstrName(item);
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}>
          <Link
            title="Details"
            icon={<DetailsIcon />}
            onClick={() => options.onEdit(item)}
          />
        </div>
      )
    }
  },
  key: 'ModelportfolioOverviewActionColumn'
}

export const modelportfoliosOverviewTableStructure = {
  default: [{
    content: [ModelportfolioNameCell],
    orderingFunction: (item) => (getInstrName(item) || '').toLowerCase()
  }, {
    content: [TypeCell],
    orderingFunction: (item) => getModelPortfolioType(item)
  }, {
    content: [OwnerCell],
    orderingFunction: (item) => getModelPortfolioOwner(item)
  }, {
    content: [InstrumentsCountCell],
    align: 'right',
    orderingFunction: (item) => item.assets && item.assets.length
  },
  // {
  //   content: [ModelportfolioOverviewActionColumn]
  // }
  ]
}