import { makeStyles } from '@material-ui/core';

export const styles = theme => ({
  container: {
    borderRadius: 2,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 15px 0px rgba(0,0,0, 0.05)',
  },
  content: {
    padding: 25,
    [theme.breakpoints.down('md')]: {
      padding: 20
    },
    [theme.breakpoints.down('sm')]: {
      padding: 15
    }
  },
  infoWrapper: {
    paddingRight: 15,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  header: {
    fontFamily: 'Roboto-Regular',
    fontSize: 22,
    color: '#4D4F5C'
  },
  totalAmountHeader: {
    fontFamily: 'Roboto-Bold',
    fontSize: 34,
    margin: '20px 0',
    color: '#4D4F5C',
    [theme.breakpoints.only('sm')]: {
      fontSize: 26,
      margin: '10px 0',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: 22,
      margin: '8px 0',
    }
  },
  categoriesContainer: {
    marginTop: 37,
    [theme.breakpoints.only('xs')]: {
      marginTop: 8
    }
  }
});

export default makeStyles(styles);