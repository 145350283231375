export const MONTHS_3 = 'MONTHS_3';
export const MONTHS_6 = 'MONTHS_6';
export const YEARS_1 = 'YEARS_1';
export const YEARS_3 = 'YEARS_3';
export const YEARS_5 = 'YEARS_5';
export const ALL = 'ALL';

export const ALL_VALUE = 'all';

export const CONFIG = {
  MONTHS_3: {
    number: 3,
    unit: 'month',
    title: '3 Monate'
  },
  MONTHS_6: {
    number: 6,
    unit: 'month',
    title: '6 Monate'
  },
  YEARS_1: {
    number: 1,
    unit: 'year',
    title: '1 Jahr'
  },
  YEARS_3: {
    number: 3,
    unit: 'year',
    title: '3 Jahre'
  },
  YEARS_5: {
    number: 5,
    unit: 'year',
    title: '5 Jahre'
  },
  ALL: {
    title: 'Gesamt'
  },
  // 'SOME_CUSTOM_DATES': {
  //   custom: true,
  //   start: moment(),
  //   end: moment().startOf('year'),
  //   title: 'Gesamt'
  // }
};