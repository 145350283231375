import React, {Component} from 'react';
import PropTypes from 'prop-types';

import List from './components/List/List'
import Content from './components/Content/Content'

class Tabs extends Component {
  render() {
    return (
      <div>
        { this.props.children }
      </div>
    );
  }
}

Tabs.propTypes = {};

Tabs.List = List
Tabs.Content = Content

export default Tabs;
