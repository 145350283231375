import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import _ from 'lodash';
import Loader from 'react-loader-spinner';
import connect from "react-redux/lib/connect/connect";

import { MuiThemeProvider } from '@material-ui/core/styles';

import { Footer, NotFound } from './components';
import CustomerLogin from '../CustomerLogin';

import useStyles from './styles';
import { AgencyResource } from '../../../utils/api';
import {createDefaultTheme} from '../../../themes/themeConfigurator';
import {loadThemeForStore, setThemeToStore} from "../../../components/CustomerThemeProvider/actions";

const mapStateToProps = (state) => ({
  customerThemeProvider: state.get('customerThemeProvider').toJS()
})
const CustomerLoginContainer = (props) => {
  const classes = useStyles();

  const {
    customerThemeProvider,
    dispatch
  } = props;

  React.useEffect(() => {
    const { agency_id } = props.match.params;

    if ((customerThemeProvider.theme && +customerThemeProvider.theme.agency_id) !== +agency_id) {
      fetchAgencyData();
    }

    return () => {};
  }, [])

  const fetchAgencyData = async () => {
    const { agency_id } = props.match.params;
    dispatch(loadThemeForStore());
    if (!_.isNil(agency_id)) {
      try {
        let response = await AgencyResource.at(`${agency_id}/theme/`, false).get();

        dispatch(setThemeToStore({...response, agency_id}))
      } catch (errors) {
        dispatch(setThemeToStore({errors}))
      }
    }
  }

  const getTheme = () => {
    const color = customerThemeProvider.theme && customerThemeProvider.theme.color;
    return color ? createDefaultTheme({}, color) : createDefaultTheme();
  }

  return (
    <div className={classes.customerLoginContainer}>
      {customerThemeProvider.theme_loading ? (
        <div className={classes.scrollWrapper}>
          <div className={classes.contentWrapper}>
            <Loader
              type="MutatingDots"
              color="#BCBCBC"
              height={100}
              width={100}
            />
          </div>
        </div>
      ) : (
        <>
          {customerThemeProvider.theme && customerThemeProvider.theme.errors ? (
            <NotFound />
          ) : (
            <MuiThemeProvider theme={getTheme()}>
              <div className={classes.scrollWrapper}>
                <div className={classes.contentWrapper}>
                  <Switch>
                    <Route path="/" render={(props) => (
                      <CustomerLogin
                        agency_id={customerThemeProvider.theme && customerThemeProvider.theme.agency_id}
                        logo={customerThemeProvider.theme && customerThemeProvider.theme.logo}
                        {...props}
                      />)}
                    />
                  </Switch>
                </div>
              </div>
              <Footer agency_id={customerThemeProvider.theme && customerThemeProvider.theme.agency_id} />
            </MuiThemeProvider>
          )}
        </>
      )}
      
    </div>
  )
};

export default connect(mapStateToProps)(withRouter(CustomerLoginContainer));