import React from "react";
import Grid from '@material-ui/core/Grid';
import CardComponent from "../CardComponent";
import PercentProductCard from "../PercentProductCard";
import SelectAspects from "../SeletAspects";
import {FIELD_REQUIRED_MSG} from "../../../../../../constants";
import {isRequiredQuestion} from "../../../../../../utils";


const SettingsProfile = (props) => {

  const {questions, onAnswerChange, dataService} = props;

  const [cards, percent, aspects] = questions;

  const current = props.dataService.step;

  const isSelected = cards.answer && cards.answer.includes(current.uid) || false;

  React.useEffect(() => {
    if (!cards.answer) {
      cards.answer = [];
    }

    if (!percent.answer) {
      percent.answer = 0
    }

  }, [cards, percent]);

  if (isSelected) {
    aspects.optional = false;
    percent.optional = false;
  } else {
    percent.optional = true;
    aspects.optional = true;
    percent.answer = 0;
    aspects.answer = [];
  }

  const isValid = function () {
    this.error = null; // clean error
    if (isRequiredQuestion(this)) {
      if (this.answer === 0 || !this.answer || this.answer.length === 0) {
        this.error = FIELD_REQUIRED_MSG
      }
    }

    return !this.error;
  };

  percent.isValid = isValid;
  aspects.isValid = isValid;


  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {cards.config.choices.map((option) => {
            return (
              <Grid item xs={12} md={4}>
                <CardComponent
                  key={option.uid}
                  onAnswerChange={onAnswerChange}
                  option={option}
                  question={cards}
                  current={current}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      {cards.answer && cards.answer.length > 0 ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <PercentProductCard
                onAnswerChange={onAnswerChange}
                question={percent}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectAspects
                aspects={aspects.config.choices}
                onAnswerChange={onAnswerChange}
                question={aspects}
                dataService={dataService}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};


export default SettingsProfile;
