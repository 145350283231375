import React from "react";
import {SearchIcon} from "../../../../images";
import {DropdownInput} from "../../../Modelportfolios/components";
import InstrumentsFilterList from "../InstrumentsFilterList";
import {ModelPortfolioResource, parseResponse} from "../../../../utils/api";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import {withRouter} from "react-router-dom";

class InstrumentsFilter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            assetFilterValue: '',
            data: [],
            loading: false,
            errors: undefined,
        }

        this.setSearchValue = this.setSearchValue.bind(this);
    }

    handleSearchActivate(value) {

        let filters = {
            "name": value,
            "only_funds": true
        }

        this.fetchAssets(filters)
    }

    fetchAssets(filters) {
        this.setState({
            data: [],
            loading: true,
            errors: undefined,
        });

        ModelPortfolioResource.searchAssets(filters)
            .then((response) => {
                parseResponse(response, 'assets', data => this.processData(data), errors => this.processErrors(errors))
            })
            .catch(errors => this.processErrors(errors))
    }

    processData(data) {
        this.setState({
            data: data,
            loading: false,
            errors: undefined,
        });
    }

    processErrors(errors) {
        this.setState({
            data: [],
            loading: false,
            errors: errors
        })
    }

    setSearchValue(value) {
        this.setState({
            assetFilterValue: value
        })
    }

    render() {

        const { classes } = this.props;

        return <DropdownInput
            fullWidth={false}
            value={this.state.assetFilterValue}
            placeholder="Fondsname oder ISIN suchen"
            icon={<SearchIcon />}
            dropdownContent={(
                <InstrumentsFilterList
                    instruments={this.state.data}
                    pattern={this.state.assetFilterValue}
                    loading={this.state.loading}
                    onAddAssetClick={this.props.onAddAssetClick}
                    instrumentsDisabled={this.props.instrumentsDisabled}
                />
            )}
            dropdownVisible={this.state.data.length > 0 || this.state.loading}
            onChange={this.setSearchValue}
            onValueApprove={(value) => this.handleSearchActivate(typeof value === 'string' ? value.trim() : this.state.assetFilterValue)}
            width={600}
            disabled={this.props.disabled}
        />
    }
}

export default withStyles(styles)(withRouter(InstrumentsFilter));
