import React from "react";
import {Typography, withStyles} from "@material-ui/core";
import styles from "./styles";
import Chart, {ESG_CRITERIAS_COLORS, getCriteriaOpacity} from "./chart";
import WarningTooltip from "../../../../../../components/WarningTooltip";
import LoadingIndicator from "../../../../../../components/LoadingIndicator/LoadingIndicator";

class ESGChart extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes, environmental, social, governance, loading } = this.props;
    return (
      <div className={classes.card}>
        <div className={classes.titleWrapper}>
          {this.props.title && (
            <Typography variant="h2" className={classes.title}>
              {this.props.title}
            </Typography>
          )}
          {this.props.tooltip && (
            <WarningTooltip title={this.props.tooltip} iconClassName={classes.helpIcon}/>
          )}
        </div>
        <div className={classes.wrapper}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <div className={classes.textWrapper} style={{opacity: getCriteriaOpacity(environmental), alignItems: 'center'}}>
                <div className={classes.text} style={{maxWidth: 320}}>
                  <h4 className={classes.textTitle} style={{ color: ESG_CRITERIAS_COLORS.ECO, marginTop: 40 }}>
                    Ökologisch
                  </h4>
                  <p className={classes.textDescription}>
                    Investition in eine wirtschaftliche Tätigkeit, die zur Erreichung eines Umweltziels beiträgt.
                  </p>
                </div>
              </div>
              <div className={classes.chart}>
                <Chart
                  environmental={environmental}
                  social={social}
                  governance={governance}
                />
              </div>
              <div className={classes.textWrapper}>
                <div className={classes.text} style={{opacity: getCriteriaOpacity(social), maxWidth: 320}}>
                  <h4 className={classes.textTitle} style={{ color: ESG_CRITERIAS_COLORS.SOC }}>
                    Sozial
                  </h4>
                  <p className={classes.textDescription}>
                    Investition in eine wirtschaftliche Tätigkeit, die zur Erreichung eines sozialen Ziels beiträgt.
                  </p>
                </div>
                <div className={classes.text} style={{opacity: getCriteriaOpacity(governance)}}>
                  <h4 className={classes.textTitle} style={{ color: ESG_CRITERIAS_COLORS.GOV }}>
                    Governance
                  </h4>
                  <p className={classes.textDescription}>
                    Investition in Humankapital oder zugunsten wirtschaftlich oder sozial benachteiligter Bevölkerungsgruppen, vorausgesetzt, dass diese Investitionen keines dieser Ziele erheblich beeinträchtigen und die Unternehmen, in die investiert wird, Verfahrensweisen einer guten Unternehmensführung anwenden.
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ESGChart);
