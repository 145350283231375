import React from "react";
import useStyles from "./styles";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography";
import CheckboxFormElement from "../../../formElement/CheckboxFormElement";
import clsx from "clsx";
import _ from "lodash";

const CardComponent = ({onAnswerChange, question, option, current}) => {

    const classes = useStyles();

    const answers = question.answer || [];

    let isChecked = !!(answers && answers.includes(option.uid));

    let isActive = current.uid === option.uid;

    const handleCheckboxChanged = (option, checked) => {
        const answer = checked ? [...answers, option.uid] : _.filter(answers, i => i !== option.uid);
        onAnswerChange(question.uid, answer)
    };


    return (
        <Card className={classes.card}>
            <CardContent className={clsx(
                classes.container,
                isChecked && classes.selected,
                isActive && classes.activeStep
            )}>
                <Typography variant={"subtitle1"} className={classes.title}>
                    {option.title}
                </Typography>
                <Typography variant={"subtitle2"}>{option.sub_title}</Typography>
                {isActive && (
                    <div className={classes.checkbox}>
                        <CheckboxFormElement
                            disabled={!isActive}
                            name={option.uid}
                            label='Ist meinem Kunden wichtig'
                            value={isChecked || false}
                            onChange={(checked) => handleCheckboxChanged(option, checked)}
                            custom_classes={{label: classes.label}}
                        />
                    </div>
                )}

            </CardContent>
        </Card>
    );
};

export default CardComponent;
