import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import {
  Container, Grid, withStyles, Button,
} from '@material-ui/core';
import {
  FilterBudges, InstrumetnsWeightsModal,
} from '../../../Modelportfolios/components';
import DashboardTable from '../../../../components/DashboardTable';

import { styles } from './styles';
import { FactSheetsHandlerResource, ModelPortfolioResource } from '../../../../utils/api';
import { paginateArray } from '../../../../utils/utils';
import { executeIfPathExist, getInvestmentDynamicPath } from '../../../InvestmentPlatform/utils';
import { modelportfoliosOverviewTableStructure } from './table-data';
import SuccessDialog from '../../../../components/SuccessDialog';
import { ALL_ID } from '../../../Modelportfolios/List/BaseList';
import BaseList from '../BaseList';
import { getInstrName } from '../../../Modelportfolios/utils';

const mapStateToProps = (state) => ({
  investmentPlatform: state.get('investmentPlatform').toJS(),
  auth: state.get('auth').toJS(),
});

const MODEL_PORTFOLIO_PAGE_SIZE = 15;

class List extends BaseList {
  constructor(props){
    super(props);

    this.state = {
      modelPortfolios: {
        data: undefined,
        loading: true,
        errors: undefined,
        date: Date.now()
      },
      investmentDynamicPath: getInvestmentDynamicPath(),
      selected: null,
      selectedAssets: [],
      selectedMp: null,
      instrumentsWeightsModalVisible: false,
      success: false
    };
  }

  selectAssets = (assets) => {
    let mpAssets = [];
    assets.forEach(function(i) {
      mpAssets.push({fixed: i.fixed, isin: i.isin, weight: i.weight, instrument_id: i.isin, name: i.name || i.isin})
    })
    return mpAssets.sort((a, b) => a.name.localeCompare(b.name))
  }

  fetchModelPortfolios = async (params) => {
    this.setState({
      modelPortfolios: {
        data: null,
        loading: true,
        errors: null,
        date: Date.now()
      }
    })

    try {
      if (this.props.extraParams){
        params = {...params, ...this.props.extraParams}
      }
      let response = await ModelPortfolioResource.getModelPortfoliosList({...params, with_istruments_data: true})
      if(this.props.filterPortfolios){
        response = this.props.filterPortfolios(response);
      }
      let responsePaginated = [...paginateArray(response, MODEL_PORTFOLIO_PAGE_SIZE)]

      this.setState({
        modelPortfolios: {
          ...this.state.modelPortfolios,
          loading:false,
          errors: null,
          data: responsePaginated,
          date: Date.now()
        },
      })

    } catch (errors) {

      this.setState({
        modelPortfolios: {
          ...this.state.modelPortfolios,
          loading: false,
          date: Date.now(),
          errors
        }

      })
    }
  }

  getModelPortfoliosDataSource = () => {
    if (this.state.modelPortfolios.loading) {
      return [{}, {}, {}, {}, {}]
    }

    if (this.state.modelPortfolios.data) {
      let dataSource = this.state.modelPortfolios.data
      return dataSource || []
    }

    return []
  }

  handleMPOverviewClick = (pfId, params = null) => {
    executeIfPathExist(this.props.investmentPlatform.routes, 'MODELPORTFOLIO_OVERVIEW', path => {
      const pathUpdated = path.replace(':id', pfId);
      let url = `/${this.state.investmentDynamicPath}${pathUpdated}`;

      if (params) {
        url += params;
      }

      this.props.history.push(url);
    });
  };

  handleEditClick = (favoriteList) => {
    this.handleMPOverviewClick(favoriteList.id);
  };

  handlePortfolioTypeChanged = async (portfolioTypes) => {
    portfolioTypes = portfolioTypes.map(i => i.id).filter(id => id !== ALL_ID);
    let params;
    if(portfolioTypes.length > 0) params = {portfolio_types: portfolioTypes};
    await this.fetchModelPortfolios(params);
  };

  handleSingleSelect = (item) => {
    item.assets = this.selectAssets(item.assets);
    this.setState({selected: item})
  };

  handleAdd = () => {
    const params = `?isin=${_.get(this.props, 'item.isin')}`;
    const pfId = _.get(this.state.selected, 'id');
    this.handleMPOverviewClick(pfId, params);
  };

  handleWeightsApprove = (items) => {
    FactSheetsHandlerResource.postToMpInstrument(
        this.props.item.isin, {items: items, mp_id: this.state.selectedMp.id}).then((data) => {
          this.setState({instrumentsWeightsModalVisible: false, success: true})
    })
  }

  handleInstrumentsWeightsModalClose = () => {
    this.setState({instrumentsWeightsModalVisible: false})
  }

  handleCloseDialog = () => {
    this.props.finish()
  }

  handleReturnToList = () => {
    this.setState({instrumentsWeightsModalVisible: false, success: false, selectedMp: null, selected: null})
  }

  successText = () => {
    const { classes } = this.props;

    let mpName = this.state.selectedMp && this.state.selectedMp.name;

    return (<div className={classes.successDialogInner}>
      <div>Das Instrument wurde zum folgende Musterdepot erfolgreich hinzugefügt.</div>
      <div className={classes.mpName}>{ mpName }</div>
      <div>
        <Button onClick={this.handleCloseDialog} className={classes.addButton} color="primary">
          {'Zum Factsheet'}
        </Button>
         <Button onClick={this.handleReturnToList} className={classes.addButton} color="primary">
          {'Einem weiteren Musterdepot hinzufügen'}
        </Button>
      </div>
    </div>)
  }

  renderUpDialogTitle = () => {

    const { classes } = this.props;

    return <Grid container className={classes.grid}>
      <Grid item sm={5}>
        <p className={classes.gridItemTitle}>Musterdepot</p>
        <p className={classes.gridName}>{this.state.selectedMp && this.state.selectedMp.name}</p>
      </Grid>
      <Grid item sm={7}>
        <p className={classes.gridItemTitle}>Hinzugefügt Instrument</p>
        <p className={classes.gridName}>{getInstrName(this.props.item)}</p>
      </Grid>
    </Grid>
  }

  render() {
    const { classes, title } = this.props;

    return (
      <div className={classes.container}>
        <Container>
          <div className={classes.navigationContainer} style={{ marginBottom: 20 }}>
            <h1 className={classes.header}>{title}</h1>
          </div>
          <FilterBudges
            onChange={this.handlePortfolioTypeChanged}
            title="Musterdepots filtern"
            multiple={false}
            items={this.TABS}
            loading={this.state.modelPortfolios.loading}
          />
          <DashboardTable
            structure={modelportfoliosOverviewTableStructure}
            dataSource={this.getModelPortfoliosDataSource()}
            expanded
            tableClasses={classes}
            withFooter={false}
            paginationOptions={{
              paginationEnabled: true,
              pageSize: MODEL_PORTFOLIO_PAGE_SIZE,
            }}
            options={{
              loading: this.state.modelPortfolios.loading,
              selected: this.state.selected,
              onInstrumentSelect: this.handleSingleSelect,
              onEdit: this.handleEditClick,
              auth: this.props.auth,
            }}
          />
          {this.state.selected && (
            <div className={classes.bottomModal}>
              <span className={classes.itemName}>INSTRUMENT: {getInstrName(this.props.item)}</span>
              <Button onClick={this.handleAdd} className={classes.addButton} color="primary">
                Zum Musterdepot hinzufügen
              </Button>
            </div>
          )}
          <InstrumetnsWeightsModal
            open={this.state.instrumentsWeightsModalVisible}
            instruments={this.state.selectedAssets}
            onWeightsApprove={this.handleWeightsApprove}
            onClose={this.handleInstrumentsWeightsModalClose}
            selectedModelPortfolio={this.state.selectedMp}
            upDialogTitle={this.renderUpDialogTitle}
          />
        </Container>
        <SuccessDialog
          contentCssClass={classes.dialogSuccess}
          maxWidth="xl"
          open={!!this.state.success}
          message={this.successText()}
          onClose={this.handleCloseDialog}
        />
      </div>
    );
  }
}

List.defaultProps = {
  viewOnly: false,
  title: 'Instrument zu Musterdepot hinzufügen',
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(List)));
