import CommonAnalysisCard from "../../../../../VirtualPortfolioPage/components/AnalysisCard";
import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";

import {RiskReturnChart as FactsheetsRiskReturnChart} from '../../../../../ProductComparison/components/charts/RiskReturnChart/RiskReturnChart'
import withWidth from "@material-ui/core/withWidth/withWidth";
import {FactSheetsHandlerResource} from "../../../../../../utils/api";
import {processResponse} from "../../../../index";
import {openMorningStartIsin} from "../../../../../../utils/utils";
import { DEFAULT_EMPTY_SECTION_MESSAGE } from '../../../../../CustomerDashboard/constants';

class RiskReturnChart extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      range: undefined,
      dataProvider: FactSheetsHandlerResource,

      riskReturnData: undefined,
      riskReturnError: undefined,
      riskReturnLoading: true,

    }

    this.years = {
      YTD:{
        title: 'YTD'
      },
      YEARS_3: {
        number: 3,
        unit: 'year',
        title: '3 Jahre'
      },
      YEARS_5: {
          number: 5,
          unit: 'year',
          title: '5 Jahre'
      },
      YEARS_10: {
          number: 10,
          unit: 'year',
          title: '10 Jahre'
      },
    }
    this.fetchRiskReturnData = this.fetchRiskReturnData.bind(this);
    this.isChartLoaded = this.isChartLoaded.bind(this);
  }

  fetchRiskReturnData(callback) {
      processResponse(
          this.state.dataProvider.getRiskReturnData(this.props.data.isin, this.props.data.ms_category),
          'risk_return', 'riskReturn', this, callback
      )
  }

  componentDidMount() {
    this.fetchRiskReturnData()
  }

  renderChart() {
    const {classes, width} = this.props;

    const calculatedIsin = this.props.data.calculated || [];
    const calculatedCategory = this.state.riskReturnData || [];

    let assetsData = [...calculatedCategory].concat(calculatedIsin);
    assetsData = assetsData.map((asset) => ({
      ...asset,
      onTooltipClick: () => {
        asset.isin && openMorningStartIsin(asset.isin)
      }
    }))

    return <>
      <FactsheetsRiskReturnChart
        products={assetsData}
        config={this.years}
        getSeriesXY={
          (item, range) => {
            return [item.volatility[range] * 100, item.performance[range] * 100]
          }
        }
        getSeriesColor={(item) => item.isin === this.props.data.isin ? '#1D2281' : '#1FBBD5'}
        btnsPadDisabled={!this.isChartLoaded()}

        // two params present before BCA-7643 but not used. todo check if they are needed
        maxValue={0}
        isLegendNarrow={!['lg', 'xl'].includes(width)}
      />
    </>
  }

  isChartLoaded() {
    return (this.props.data && this.props.data.calculated) && !this.state.riskReturnLoading
  }

  renderError() {
    return <div>{'Fehler. Die Graphik "Rendite-Risiko-Diagramm" konnte nicht geladen werden.'}</div>
  }

  render() {
    const { classes } = this.props;

    return <CommonAnalysisCard
        classes={{
          card: classes.root
        }}
        title={<span>Rendite-Risiko-Diagramm</span>}
        loading={this.state.riskReturnLoading}
        content={
          <div className={classes.container}>
            {this.state.riskReturnError ? this.renderError() : this.isChartLoaded() ? this.renderChart() : DEFAULT_EMPTY_SECTION_MESSAGE}
          </div>
      }
    />
  }
}

export default withStyles(styles)(withWidth()(RiskReturnChart))
