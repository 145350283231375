import React from "react";
import _ from 'lodash';

import {parseResponse} from "../../../utils/api";
import {useSharedDataContext} from "../utils";

export function useExtendedAssetsData(customerId, portfolio, dataProvider) {

  const dataContext = useSharedDataContext();

  const fetch = async () => {
    if(dataContext.isPortfolioDataLoading(portfolio.depotNumber)){
      return;
    }
    dataContext.setPortfolioDataLoading(portfolio.depotNumber, true);
    dataContext.setPortfolioData(portfolio.depotNumber, {
      data: null,
      loading: true,
      errors: null,
      updatedAt: +new Date()
    });

    try {
      const response = await dataProvider.getPortfolioExtendedAssetData(customerId, portfolio.depotNumberForAssets || portfolio.depotNumber);
      parseResponse(response, 'extended_asset_data', (data) => {
        dataContext.setPortfolioData(portfolio.depotNumber, {
          data: data,
          loading: false,
          errors: null,
          updatedAt: +new Date()
        })
      }, (errors) => {
        dataContext.setPortfolioData(portfolio.depotNumber, {
          data: null,
          loading: false,
          errors: errors,
          updatedAt: +new Date()
        })
      }, false)
    } catch (errors) {
      dataContext.setPortfolioData(portfolio.depotNumber, {
        data: null,
        loading: false,
        errors: errors,
        updatedAt: +new Date()
      })
    }

    dataContext.setPortfolioDataLoading(portfolio.depotNumber, false);
  };

  React.useEffect(() => {
    if (!!customerId && !!portfolio) {
      fetch();
    }
  }, [customerId, _.get(portfolio, 'depotNumberForAssets'), _.get(portfolio, 'depotNumber')]);

  return dataContext.getPortfolioData(portfolio.depotNumber)
}