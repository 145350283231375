import { makeStyles } from "@material-ui/core/styles";
import {getApplyFiltersBtnStyles, applyFiltersBtnText} from "../../../../../InvestmentStrategy/List/components/DynamicFilters/styles";

export default makeStyles((theme) => ({
  grid: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '24px !important',
    marginLeft: 'auto',

    [theme.breakpoints.up('md')]: {
      '&.customGrid-md-3': {
        flexBasis: '20.666666% !important',
        maxWidth: '20.666666% !important'
      }
    },

    '@media ((min-width: 1100px) and (max-width: 1279px)) or (min-width: 1400px)': {
      '&.customGrid-md-3': {
        flexBasis: '18.666666% !important',
        maxWidth: '18.666666% !important'
      }
    }
  },

  applyFiltersBtn: {
    ...getApplyFiltersBtnStyles(theme),
    width: 'fit-content !important',
    marginLeft: 'auto',

    [theme.breakpoints.down('sm')]: {
      minHeight: '45px !important',
      height: '45px !important',
    },

    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
    },
  },

  applyFiltersBtnText: {
    ...applyFiltersBtnText
  },
}))