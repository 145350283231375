import React from 'react';

import withStyles from "@material-ui/core/styles/withStyles";

import styles from './styles';
import Switch from "../../../../../../components/AssetModal/components/Switch";

const EditorSettingsSwitch = (props) => {
  const {
    classes,
    enabled,
    handleValueChanged,
    label,
    description
  } = props;

  return (
    <div className={classes.container} id="reporting-cover-page-setting-tour-element">
      <div className={classes.headerWrapper}>
        <div className={classes.header}>
          {label}
        </div>

        <div className={classes.switch}>
          <Switch
            value={enabled}
            handleValueChanged={(checked) => handleValueChanged(checked)}
          />
        </div>
      </div>

      <div className={classes.message}>
        {description}
      </div>
    </div>
  )
};

EditorSettingsSwitch.defaultProps = {
  label: 'Anschreiben',
  description: 'Blenden Sie diese Funktion aus, um das Reporting ohne Anschreiben zu senden.'
};

export default withStyles(styles)(EditorSettingsSwitch)