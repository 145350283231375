const styles = (theme) => ({
  container: {
    flex: 1,
    marginTop: 20,
  },
  gridContainer: {
    height: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      height: 'auto',
      overflow: 'auto',
    }
  },
  itemContainer: {
    height: '100%',
  }
});

export default styles;