import React from 'react'

import {
  Grid,
  Container
} from '@material-ui/core';

import Filters from '../Modelportfolios/Filters';

function AssetSearch() {

  return (
    <Container className={'app-page-container'} style={{height: '100%', position: 'relative'}}>
      {/* <h1 className={classes.header}>Fonds-Suche</h1> */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
            <Filters
              title={'Investmentsuche'}
              isFundSearch
            >
            </Filters>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AssetSearch
