import React from 'react';

import useStyles from './styles';
import ChartLegend from "../../../../../components/Charts/Legend";
import {PERFORMANCE_CHART_MISSING_DATA_INFO_TEXT, PRODUCT_TYPE} from "../../../constants";
import WarningTooltip from "../../../../../components/WarningTooltip";
import _ from "lodash";
import clsx from "clsx";
import {
  getPortfolioLink, openPortfolioLink,
} from "../../../../Trades/components/TradeStep/components/PortfolioTrade/table-data";
import {openMorningStartIsin} from "../../../../../utils/utils";


function Legend({data, flexDirection, asLink=false}) {
  const classes = useStyles({flexDirection});

  const dataSorted = _.sortBy(data, (item) => item.order);

  const handleItemClick = (item) => {
    if ([PRODUCT_TYPE.MUSTERDEPOT, PRODUCT_TYPE.CUSTOMER_PORTFOLIO].includes(item.type) || !item.data) {
      return;
    }

    if (item.type === PRODUCT_TYPE.ASSET) {
      return openMorningStartIsin(item.data.isin);
    } else {
      return openPortfolioLink(item.data);
    }
  };

  const names = (() => {
    return dataSorted.map((timeSeries) => {

      const hasLink = asLink
        && !!timeSeries.product
        && (timeSeries.product.type === PRODUCT_TYPE.ASSET || getPortfolioLink(timeSeries.product.data));

      const Name = <span className={!!hasLink && classes.link} onClick={() => hasLink && handleItemClick(timeSeries.product)}>{timeSeries.name}</span>

      const name = timeSeries.highlighted
        ? <b className={classes.highlightedLegendItem}>{Name}</b>
        : Name;

      const otherControls = [];

      if (timeSeries.highlighted) {
        otherControls.push(<i className={clsx("fa fa-star fa-lg", classes.highlightIcon)}/>)
      }
      if (timeSeries.missingData) {
        otherControls.push((
          <span style={{display: 'inline-block', width: 40}}>
            <WarningTooltip
              title={timeSeries.missingDataText || PERFORMANCE_CHART_MISSING_DATA_INFO_TEXT}
            />
          </span>
        ))
      }
      return [name, otherControls, timeSeries.name];
    })
  })();

  return (
    <ChartLegend
      names={names}
      colors={dataSorted.map((timeSeries) => timeSeries.color)}
      classes={{
        container: classes.chartLegendContainer
      }}
    />
  )
}

export default Legend;
